import React, {Component} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import {ArrowBack, Description, Restore, Search} from '@material-ui/icons';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {DATA_NULL, IDENTITY_TYPES, IDENTITY_VERIFICATION_STATUS, PERMISSION_ACTIONS, ROUTE} from '../../../common/constant';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getAllCountryCode} from '../../../stores/common/actions';
import {searchIdentityVerifications} from '../../../stores/member/action';
import {changeUrlParams, clearBlankValue, getUrlParams, onChangeSelect, onChangeTextField} from '../../../utils/common';
import {convertDatetimeUTC, convertStartEndTime, displayDate, displayDateTime} from '../../../utils/datetime';

/**
 * IdentityVerificationSearch
 */
class IdentityVerificationSearch extends Component {
  /**
   * Component constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      countryId: '',
      userCode: '',
      dateFrom: null,
      dateTo: null,
      identityType: '',
      identityNumber: '',
      applicationStatus: [],

      validating: false,
      searchResult: null,
    };
  };

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    await this.props.getAllCountryCode();
    if (this.props.location.search && this.props.actions.includes(PERMISSION_ACTIONS.SEARCH)) {
      const initialState = getUrlParams(this.props.location.search);
      this.setState({
        ...initialState,
        dateFrom: initialState.dateFrom ? new Date(initialState.dateFrom) : null,
        dateTo: initialState.dateTo ? new Date(initialState.dateTo) : null,
      }, this.handleSearch);
    }
  }

  /**
   * handleSearch
   */
  handleSearch = async () => {
    if (
      this.state.dateFrom && this.state.dateTo &&
      !(this.checkDatesWithin30Days() && this.checkDateFromBeforeDateTo())
    ) return;
    if (!this.state.userCode && this.state.applicationStatus?.length === 0) return;
    const {
      countryId,
      userCode,
      dateFrom,
      dateTo,
      identityType,
      identityNumber,
      applicationStatus,
    } = this.state;
    const timeFrom = dateFrom ? new Date(convertStartEndTime(dateFrom, true)).toISOString() : '';
    const timeTo = dateTo ? new Date(convertStartEndTime(dateTo, false)).toISOString() : '';

    const queryParamsToChange = clearBlankValue({
      countryId,
      userCode: userCode.trim(),
      dateFrom: timeFrom ? displayDate(timeFrom) : '',
      dateTo: timeTo ? displayDate(timeTo) : '',
      identityType,
      identityNumber: identityNumber.trim(),
      applicationStatus,
    });

    const payload = clearBlankValue({
      country_id: countryId,
      member_id: userCode.trim(),
      from_time: timeFrom,
      to_time: timeTo,
      image_type: identityType,
      number: identityNumber.trim(),
      status: applicationStatus,
    });

    await this.props.searchIdentityVerifications({page: 0, size: 10}, payload)
      .then(
        (response) => {
          if (response) {
            this.setState({searchResult: response}, () => changeUrlParams(queryParamsToChange));
          }
        },
      );
  };

  /**
   * resetSearchConditions
   */
  resetSearchConditions = () => {
    this.setState({
      countryId: '',
      userCode: '',
      dateFrom: null,
      dateTo: null,
      identityType: '',
      identityNumber: '',
      applicationStatus: [],
    });
  };

  /**
   * handleChangeApplicationStatus
   * @param {Event} e
   * @param {String} status
   */
  handleChangeApplicationStatus = (e, status) => {
    const applicationStatusCopy = [...this.state.applicationStatus];
    if (e.target.checked) {
      applicationStatusCopy.push(status);
    } else {
      const index = applicationStatusCopy.indexOf(status);
      if (index >= 0) applicationStatusCopy.splice(index, 1);
    }
    this.setState({applicationStatus: applicationStatusCopy});
  };

  /**
   * checkDatesWithin30Days
   * @return {Boolean}
   */
  checkDatesWithin30Days = () => {
    const difference = new Date(displayDate(this.state.dateTo.toISOString(), true)).getTime() - new Date(displayDate(this.state.dateFrom.toISOString(), true)).getTime();
    const daysDifference = Math.ceil(difference / (1000 * 3600 * 24));
    return daysDifference <= 30;
  }

  /**
   * checkDateFromBeforeDateTo
   * @return {Boolean}
   */
  checkDateFromBeforeDateTo = () => {
    const difference = new Date(displayDate(this.state.dateTo.toISOString(), true)).getTime() - new Date(displayDate(this.state.dateFrom.toISOString(), true)).getTime();
    const daysDifference = Math.ceil(difference / (1000 * 3600 * 24));
    return daysDifference >= 0;
  }

  /**
   * Render component
   * @return {JSX}
   */
  render() {
    const {t, common, isLoading, actions} = this.props;
    const {
      countryId,
      userCode,
      dateFrom,
      dateTo,
      identityType,
      identityNumber,
      applicationStatus,
      validating,
      searchResult,
    } = this.state;
    const permisions = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canDetail: actions.includes(PERMISSION_ACTIONS.EDIT),
    };

    return (
      <Container maxWidth="xl">
        <Grid container alignItems="center" justifyContent="space-between" className="page_header">
          <Grid item>
            <h3>{t('memberManagement.identityVerificationManagement')}</h3>
          </Grid>
          <Grid item>
            <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
              <Button color="primary" className="button_margin button_color" variant="contained" endIcon={<ArrowBack />}>
                {t('common.btnReturn')}
              </Button>
            </Link>
          </Grid>
        </Grid>
        <br />
        {/* -------------------- Search conditions -------------------- */}
        <Card raised>
          <Container maxWidth="xl">
            {permisions.canSearch && (
              <>
                <br />
                <Grid className="search_table">
                  <Grid container className="row_form_item">
                    <Grid container alignItems="center" className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('facility.searchTitle')}
                    </Grid>
                  </Grid>
                </Grid>

                {/* Country */}
                <Grid container spacing={1} alignItems="center" className="row_form_item table_background_color_aliceblue">
                  <Grid item xs={6} lg={2} className="grid_title_padding">
                    {t('common.country')}
                  </Grid>
                  <Grid item xs={6} lg={4}>
                    <FormControl margin="dense" variant="outlined" className="field_size_20 field_min_size_350">
                      <Select
                        displayEmpty
                        renderValue={countryId ? undefined :
                          () => (
                            <div className="font-12 color-disabled">
                              {t('placeholder.required_select', {field: t('common.country')})}
                            </div>
                          )
                        }
                        name='countryId'
                        value={countryId}
                        onChange={(e) => onChangeSelect(this, e)}
                      >
                        {common?.country_code?.map(({id, country_code}) => (
                          <MenuItem key={id} value={id}>
                            {t(country_code)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                {/* User code */}
                <Grid container spacing={1} alignItems="center" className="row_form_item table_background_color_aliceblue">
                  <Grid item xs={6} lg={2} className="grid_title_padding">
                    {t('memberManagement.userCode')}
                  </Grid>
                  <Grid item xs={6} lg={4}>
                    <TextField
                      className="field_size_20 field_min_size_350"
                      margin="dense"
                      variant="outlined"
                      placeholder={t('placeholder.required', {field: t('memberManagement.userCode')})}
                      name='userCode'
                      value={userCode}
                      onChange={(e) => onChangeTextField(this, e)}
                    />
                    {validating && !userCode && applicationStatus?.length === 0 && (
                      <div>
                        <FormHelperText error id='user_code_required'>
                          {t('memberManagement.selectUserCodeOrStatus')}
                        </FormHelperText>
                      </div>
                    )}
                  </Grid>
                </Grid>

                {/* Registration period */}
                <Grid container spacing={1} alignItems="center" className="row_form_item table_background_color_aliceblue">
                  <Grid item xs={6} lg={2} className="grid_title_padding">
                    {t('memberManagement.registrationPeriod')}
                  </Grid>
                  <Grid item xs={6} lg={10} container spacing={1} alignItems='center'>
                    <Grid item>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          autoOk
                          disableFuture
                          fullWidth
                          margin="dense"
                          variant="inline"
                          inputVariant="outlined"
                          label={<div className="font-12 color-disabled">{t('memberManagement.registrationFrom')}</div>}
                          format="yyyy/MM/dd"
                          invalidDateMessage={t('errorFields.invalidDateMessage')}
                          name='dateFrom'
                          value={dateFrom}
                          onChange={(time) => {
                            this.setState({dateFrom: time !== 'Invalid Date' ? time : null});
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          autoOk
                          disableFuture
                          fullWidth
                          margin="dense"
                          variant="inline"
                          inputVariant="outlined"
                          label={<div className="font-12 color-disabled">{t('memberManagement.registrationTo')}</div>}
                          format="yyyy/MM/dd"
                          invalidDateMessage={t('errorFields.invalidDateMessage')}
                          name='dateTo'
                          value={dateTo}
                          onChange={(time) => {
                            this.setState({dateTo: time !== 'Invalid Date' ? time : null});
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    {dateFrom && dateTo && !this.checkDatesWithin30Days() && (
                      <Grid item xs={12}>
                        <FormHelperText error id='registration_period_30_days'>
                          {t('memberManagement.registrationPeriod.within30days')}
                        </FormHelperText>
                      </Grid>
                    )}
                    {dateFrom && dateTo && !this.checkDateFromBeforeDateTo() && (
                      <Grid item xs={12}>
                        <FormHelperText error id='invalid_registration_period'>
                          {t('validation.invalid.timeRange')}
                        </FormHelperText>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                {/* Application documents */}
                <Grid container spacing={1} alignItems="center" className="row_form_item table_background_color_aliceblue">
                  <Grid item xs={6} lg={2} className="grid_title_padding">
                    {t('memberManagement.applicationDocument')}
                  </Grid>
                  <Grid item xs={6} lg={4}>
                    <FormControl margin="dense" variant="outlined" className="field_size_20 field_min_size_350">
                      <Select
                        displayEmpty
                        renderValue={identityType ? undefined :
                          () => (
                            <div className="font-12 color-disabled">
                              {t('placeholder.required_select', {field: t('memberManagement.applicationDocument')})}
                            </div>
                          )
                        }
                        name='identityType'
                        value={identityType}
                        onChange={(e) => onChangeSelect(this, e)}
                      >
                        {IDENTITY_TYPES.map(({id, i18n}) => (
                          <MenuItem key={id} value={id}>
                            {t(i18n)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                {/* NRIC/Passport Number */}
                <Grid container spacing={1} alignItems="center" className="row_form_item table_background_color_aliceblue">
                  <Grid item xs={6} lg={2} className="grid_title_padding">
                    {t('memberManagement.identityNumber')}
                  </Grid>
                  <Grid item xs={6} lg={4}>
                    <TextField
                      className="field_size_20 field_min_size_350"
                      margin="dense"
                      variant="outlined"
                      placeholder={t('placeholder.required', {field: t('memberManagement.identityNumber')})}
                      name='identityNumber'
                      value={identityNumber}
                      onChange={(e) => onChangeTextField(this, e)}
                    />
                  </Grid>
                </Grid>

                {/* Application status */}
                <Grid container spacing={1} alignItems="center" className="row_form_item table_background_color_aliceblue">
                  <Grid item xs={6} lg={2} className="grid_title_padding">
                    {t('memberManagement.applicationStatus')}
                  </Grid>
                  <Grid item xs={6} lg={10}>
                    <Box my={1}>
                      <FormGroup row>
                        {IDENTITY_VERIFICATION_STATUS.map(({id, i18n}) => {
                          const checked = applicationStatus.includes(id);
                          return (
                            <FormControlLabel
                              key={id}
                              labelPlacement='end'
                              label={t(i18n)}
                              name={`applicationStatus.${id}`}
                              control={(
                                <Checkbox
                                  className='checkbox_radio'
                                  checked={checked}
                                  onClick={(e) => this.handleChangeApplicationStatus(e, id)}
                                />
                              )}
                            />
                          );
                        })}
                      </FormGroup>
                      {validating && !userCode && applicationStatus?.length === 0 && (
                        <FormHelperText error id='application_status_required'>
                          {t('memberManagement.selectUserCodeOrStatus')}
                        </FormHelperText>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </>
            )}

            {/* -------------------- Action buttons -------------------- */}
            {permisions.canSearch && (
              <>
                <br />
                <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
                  <Grid item>
                    {/* Reset search params button */}
                    <Button className="button_margin button_color" color="primary" variant="contained" endIcon={<Restore />} onClick={this.resetSearchConditions}>
                      {t('common.btnResetSearch')}
                    </Button>
                  </Grid>
                    <Grid item>
                      {/* Search button */}
                      <Button
                        className="button_margin"
                        color="primary"
                        variant="contained"
                        endIcon={<Search />}
                        onClick={() => {
                          this.setState({validating: true});
                          this.handleSearch();
                        }}
                      >
                        {t('common.btnSearch')}
                      </Button>
                    </Grid>
                </Grid>
                <br />
              </>
            )}
          </Container>
        </Card>
        <br />

        {/* -------------------- Search result -------------------- */}
        {searchResult && (
          <>
            <Card raised>
              <Container maxWidth="xl">
                <br />
                <LoadingOverlay active={isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                  <h3>
                    {t('common.searchResult')} {searchResult.totalSize} {t('common.case')}
                  </h3>
                  {(searchResult.content.length > 0) && (
                    <>
                      <br />
                      <div className='scroll_area_700'>
                        <Table size='small'>
                          <TableHead>
                            <TableRow>
                              <TableCell>{t('common.country')}</TableCell>
                              <TableCell>{t('memberManagement.userCode')}</TableCell>
                              <TableCell>{t('memberManagement.registrationTime')}</TableCell>
                              <TableCell>{t('memberManagement.applicationDocument')}</TableCell>
                              <TableCell>{t('memberManagement.identityNumber')}</TableCell>
                              <TableCell>{t('memberManagement.applicationStatus')}</TableCell>
                              {permisions.canDetail && (
                                <TableCell>{t('common.action')}</TableCell>
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {searchResult.content.map((row) => {
                              const {nric_id, country_id, member_id, registration_date, image_type, number, status} = row;
                              const country_code = common.country_code?.find(({id}) => country_id === id)?.country_code || '';
                              const identity_type = IDENTITY_TYPES.find(({id}) => image_type === id)?.i18n;
                              const identity_verification_status = IDENTITY_VERIFICATION_STATUS.find(({id}) => id === status)?.i18n;

                              return (
                                <TableRow key={nric_id}>
                                  <TableCell>{country_code ? t(country_code) : DATA_NULL}</TableCell>
                                  <TableCell>{member_id || DATA_NULL}</TableCell>
                                  <TableCell>{registration_date ? displayDateTime(new Date(convertDatetimeUTC(registration_date, country_code))) : DATA_NULL}</TableCell>
                                  <TableCell>{identity_type ? t(identity_type) : DATA_NULL}</TableCell>
                                  <TableCell>{number || DATA_NULL}</TableCell>
                                  <TableCell>{identity_verification_status ? t(identity_verification_status) : DATA_NULL}</TableCell>
                                  {permisions.canDetail && (
                                    <TableCell>
                                      <Link
                                        style={{textDecoration: 'none'}}
                                        to={{pathname: ROUTE.LAYOUT + ROUTE.IDENTITY_VERIFICATION_DETAIL + `/${nric_id}`}}
                                      >
                                        <Button className="button_margin" color="primary" variant="contained" endIcon={<Description />}>
                                          {t('common.btnEdit')}
                                        </Button>
                                      </Link>
                                    </TableCell>
                                  )}
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                        <br />
                      </div>
                    </>
                  )}
                </LoadingOverlay>
              </Container>
              <br />
            </Card>
            <br />
          </>
        )}
      </Container>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    common: state.common,
    isLoading: state.member.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    searchIdentityVerifications: (params, payload) => dispatch(searchIdentityVerifications(params, payload)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(IdentityVerificationSearch)));
