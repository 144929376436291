import React, {Component} from 'react';

import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Card,
  Button,
  Container,
  CardContent,
  TextField,
  Dialog,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {ROUTE} from '../../../common/constant';
import SelectModal from '../../../components/selectModal';
import {getMail, updateMail} from '../../../stores/mail/action';
import {onChangeTextField} from '../../../utils/common';
import withPermissionData from '../../../withPermissionData/hoc';

const languages = ['en', 'vi', 'ja'];

/**
 * Mail management view
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      templateType: '',
      language: '',
      title: '',
      content: '',
      flagSubmitModal: false,
    };
  }

  /**
   * handleSelectTemplateType
   * @param {*} templateType
   */
  handleSelectTemplateType = (templateType) => {
    const {mailsList} = this.props.mail;
    const mail = mailsList[templateType][0];
    this.setState({
      ...this.state,
      templateType,
      language: mail.id.lang,
      title: mail.title,
      content: mail.content,
    });
  }

  /**
   * handleSelectLanguage
   * @param {*} language
   */
  handleSelectLanguage = (language) => {
    const {mailsList} = this.props.mail;
    const {templateType} = this.state;
    const mailsOfTemplateType = mailsList[templateType];
    const mail = mailsOfTemplateType.find((mail) => mail.id.lang === language);
    this.setState({
      ...this.state,
      language,
      title: mail?.title,
      content: mail?.content,
    });
  }

  /**
   * handleSubmit
   */
  handleSubmit = () => {
    this.setState({
      ...this.state,
      flagSubmitModal: true,
    });
  }

  /**
   * handleClickOk
   */
  handleClickOk = () => {
    this.setState({
      ...this.state,
      flagSubmitModal: false,
    });
  }

  /**
   * handleClickCancel
   */
  handleClickCancel = () => {
    this.setState({
      ...this.state,
      flagSubmitModal: false,
    });
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getMail();
    const {mailsList} = this.props.mail;
    const templateType = Object.keys(mailsList)[0];
    const mail = mailsList[templateType][0];
    this.setState({
      ...this.state,
      templateType,
      language: mail.id.lang,
      title: mail.title,
      content: mail.content,
    });
  };

  /**
   * render
   * @return {component}
   */
  render() {
    const {t, mail} = this.props;
    const {mailsList, isLoading} = mail;
    const {
      templateType,
      language,
      title,
      content,
      flagSubmitModal,
    } = this.state;

    return (
      <LoadingOverlay active={isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>Mail management</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}>
                <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.DATA_MANAGEMENT}}>
                  <Button
                    color="primary"
                    variant="contained"
                    className="button_margin button_color"
                    endIcon={<ArrowBackIcon />}
                  >
                    {t('common.btnReturn')}
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <br/>
            <Card raised>
              <CardContent>
                <Grid container>
                    <Grid item xs={4} lg={3}>
                      <h3>Template Type:</h3>
                    </Grid>
                    <Grid container item xs={8} lg={9} justifyContent="center">
                      <h3>{templateType}</h3>
                    </Grid>
                </Grid>
                <br/>
                <Grid container>
                  <Grid item container xs={4} lg={3}>
                    <Card raised style={{maxHeight: 590, overflow: 'auto'}}>
                      <List>
                        {Object.keys(mailsList).map((type, index) => {
                          const selected = type === templateType;
                          return (
                              <ListItem
                                button
                                key={index}
                                selected={selected}
                                onClick={() => this.handleSelectTemplateType(type)}
                              >
                                <ListItemText primary={type}/>
                              </ListItem>
                          );
                        },
                      )}
                      </List>
                    </Card>
                  </Grid>
                  <Grid item container xs={8} lg={9} direction="column">
                    <Card raised>
                      <CardContent>
                        <Grid item container justifyContent="center">
                          {languages.map((lang, index) => {
                            const selected = lang === language;
                            return (
                              <Button
                                key={index}
                                disabled={selected}
                                onClick={() => this.handleSelectLanguage(lang)}
                              >
                                {lang}
                              </Button>
                            );
                          })}
                        </Grid>
                        <br/>
                        <Grid item>
                          <Grid container spacing={2}>
                            <Grid item container>
                              <Grid item xs={2}>
                                Title:
                              </Grid>
                              <Grid item xs={10}>
                                <Grid container>
                                  <TextField
                                    name="title"
                                    value={title}
                                    multiline
                                    fullWidth
                                    rows="10"
                                    onChange={(e) => onChangeTextField(this, e)}
                                    variant="outlined"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item container>
                              <Grid item xs={2}>
                                Content:
                              </Grid>
                              <Grid item xs={10}>
                                <Grid container>
                                  <TextField
                                    name="content"
                                    value={content}
                                    multiline
                                    fullWidth
                                    rows="10"
                                    onChange={(e) => onChangeTextField(this, e)}
                                    variant="outlined"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item container justifyContent="center">
                              <Button
                                color="primary"
                                variant="contained"
                                className="button_margin button_color"
                                onClick={this.handleSubmit}
                              >
                                Submit
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <br/>
          </Container>
        </Card>
        <Dialog
          open={flagSubmitModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <SelectModal onClickOk={this.handleClickOk} onClickCancel={this.handleClickCancel} message='Submit your changes?' />
        </Dialog>
      </LoadingOverlay>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    mail: state.mail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMail: () => dispatch(getMail()),
    updateMail: (payload) => dispatch(updateMail(payload)),
  };
};

export default withPermissionData(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
