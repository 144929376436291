import React, {Component} from 'react';

import {
  Card,
  Container,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Box,
  TextField,
  TableHead,
  TableCell,
  TableRow,
  Table,
  TableBody,
  AppBar,
  Tab,
  Tabs,
} from '@material-ui/core';
import {ArrowBack as ArrowBackIcon} from '@material-ui/icons';
import {Autocomplete} from '@material-ui/lab';
import {GoogleApiWrapper} from 'google-maps-react';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';
import 'date-fns';

import {GROUP_TYPES_BOOKING_JIT, ROUTE, ROWS_PER_PAGE_OPTIONS, PAYMENT_METHODS} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import MapBookingJit from '../../../components/map/MapBookingJit';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {searchAreaGeofence} from '../../../stores/area_geofence/action';
import {getAllCountryCode, getListGeofence} from '../../../stores/common/actions';
import {getWaypointStops, getJitRouteDetail} from '../../../stores/jit_setting/action';
import {getListFavoriteWaypoint} from '../../../stores/member/action';
import {updateBooking, searchBookingUpdate, getBookingHistory, searchJitByAreaId, getJitRoutePolyLine} from '../../../stores/reservation/actions';
import {backForwardRouter, redirectRouter} from '../../../utils/common';
import './style.css';
import {isRoleBusiness, isRoleGlobal} from '../../../utils/role';

/**
 * Booking location Component
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      positionFrom: null,
      positionTo: null,
      selectedGeofence: '',
      passenger: '',
      currency_code: '',
      maxPassenger: 4,
      listArea: [],
      listGeofence: [],

      tab: 0,
      selectHistoryId: '',
      isSubmitForm: false,
      currentPage: 0,
      favoritePickUpId: '',
      favoriteDropOffId: '',
      location_tag: '',
      location_name: '',

      area_id: null,
      route_id: null,
      country_id: !isRoleGlobal() ? this.props.principal.country_id : '',
      route_code: null,

      pickUpLocation: [],
      dropOffLocation: [],
      polyline: '',
      selectPickUpLocation: {},
      selectDropOffLocation: {},
      routeList: [],
      dataMapJit: {},
      simulationId: null,
      zone_id: '',
      infantNumber: 0,
      infantNummberList: [],
    };
    this.isChange = false;
    this.validator = new SimpleReactValidator({autoForceUpdate: this});
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    const {searchBooking} = this.props;
    await this.props.getAllCountryCode();
    if (_.isEmpty(this.props.bookingInfo)) this.props.history.push(ROUTE.LAYOUT + ROUTE.RESERVATION_CREATE);
    await this.props.getListGeofence({targetScreen: 'ENABLE_FOR_ADMIN_ALL'}).then((response) => {
      if (response?.length > 0) {
        this.setState({listGeofence: response});
      }
    });
    if (!_.isEmpty(this.props.searchBooking)) {
      if (this.state.country_id || searchBooking?.country_id) {
        await this.props.searchAreaGeofence({
          countryId: (this.state.country_id || searchBooking?.country_id),
          targetScreen: 'ENABLE_FOR_ADMIN_ALL',
        }).then((response) => {
          this.setState({listArea: isRoleBusiness() ?
            response?.content.filter((item) => item.geofences.filter((geofence) => this.props.principal.geofence_ids.includes(geofence)).length > 0) :
            response?.content});
        });
      }
      if ( searchBooking?.area_id && searchBooking.route_id) {
        this.props.searchJitByAreaId({geofenceAreaId: searchBooking?.area_id, enable: true}).then((response) => {
          this.setState({routeList: response?.content});
          const route = response?.content.find((route) => route.route_id === this.state.route_id);
          this.props.getJitRoutePolyLine(route.route_code).then((response) => {
            const dataMapJit = response?.routes.find((route) => route.route_id === this.state.route_id);
            const simulationId = dataMapJit.simulation_id;
            const pickUpLocation = dataMapJit.turn_locations.filter((location) => dataMapJit.pickup_stop_ids.includes(location.from_stop_id));
            const dropOffLocation = dataMapJit.turn_locations.filter((location) => dataMapJit.dropoff_stop_ids.includes(location.to_stop_id));
            const polyline = dataMapJit.polyline;
            this.setState({
              pickUpLocation: pickUpLocation,
              dropOffLocation: dropOffLocation,
              polyline: polyline,
              simulationId,
            });
          });
        });
      }
      this.renderInfantNumberList(searchBooking?.demand?.passenger, searchBooking?.maxPassenger);
      this.setState({
        service_type: searchBooking?.service_type,
        positionFrom: searchBooking?.positionFrom,
        positionTo: searchBooking?.positionTo,
        selectedGeofence: searchBooking?.selectedGeofence,
        passenger: searchBooking?.passenger,
        maxPassenger: searchBooking?.maxPassenger,
        mobi_type: searchBooking?.mobi_type,
        country_id: searchBooking?.country_id || (!isRoleGlobal() ? this.props.principal.country_id : null),
        area_id: searchBooking?.area_id || null,
        route_id: searchBooking?.route_id || null,
        selectPickUpLocation: searchBooking?.selectPickUpLocation || {},
        selectDropOffLocation: searchBooking?.selectDropOffLocation || {},
        zone_id: searchBooking?.zone_id,
        infantNumber: searchBooking?.infantNumber,
      });
    }
    this.getBookingHistoryData(this.state.currentPage, ROWS_PER_PAGE_OPTIONS[0]);
  }

  /**
   * getBookingHistoryData
   * @param {Number} page
   * @param {Number} size
   */
  getBookingHistoryData = async (page, size) => {
    const services = [];
    services.push(this.props.searchBooking?.mobi_type);
    const queryParams = {
      page: page,
      size: size,
    };
    services.forEach((e) => (queryParams.service_types = e));
    await this.props.getBookingHistory(this.props.bookingInfo.member_id, queryParams);
    this.setState({currentPage: page, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
  };

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage});
    if (this.state.tab === 0) {
      this.getBookingHistoryData(null, currentPage, rowsPerPage);
    }
  };

  /**
   * a11yProps
   * @param {*} index
   * @return {*}
   */
  a11yProps(index) {
    return {
      'id': `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
  }

  /**
   * chooseHistory
   * @param {Object} item
   */
  chooseHistory = (item) => {
    if (!_.isEmpty(item)) {
      if (item?.country_id && item?.route_id) {
        this.props.searchAreaGeofence({countryId: item?.country_id, targetScreen: 'ENABLE_FOR_ADMIN_ALL'}).then((response) => {
          this.setState({listArea: response?.content});
        });
        this.props.getJitRoutePolyLine(item.route_code).then((response) => {
          if (response) {
            const route = response.routes.find((route) => route.route_id === item.route_id);
            const geofence = this.state.listGeofence.find((geofence) => geofence.geofence_id === route.geofence_id);
            const maxPassenger = route?.group_type === GROUP_TYPES_BOOKING_JIT[0] ? geofence?.jit_w2h_config?.booking_user_limit : geofence?.jit_h2w_config?.booking_user_limit;
            const dataMapJit = response?.routes.find((route) => route.route_id === item.route_id);
            const simulationId = dataMapJit.simulation_id;
            const pickUpLocation = dataMapJit.turn_locations.filter((location) => dataMapJit.pickup_stop_ids.includes(location.from_stop_id));
            const dropOffLocation = dataMapJit.turn_locations.filter((location) => dataMapJit.dropoff_stop_ids.includes(location.to_stop_id));
            const selectPickUpLocation = pickUpLocation.find((pickUp) => pickUp.from.swat_transit_stop_id === item.pickup_stop_id);
            const selectDropOffLocation = dropOffLocation.find((dropOff) => dropOff.to.swat_transit_stop_id === item.dropoff_stop_id);
            const polyline = dataMapJit.polyline;
            this.setState({
              pickUpLocation: pickUpLocation,
              dropOffLocation: dropOffLocation,
              polyline: polyline,
              simulationId,
              routeList: response.routes,
              route_id: item.route_id,
              country_id: item.country_id,
              area_id: dataMapJit.area_id,
              selectPickUpLocation,
              selectDropOffLocation,
              maxPassenger,
              route_code: item.route_code,
            });
          } else {
            this.setState({
              pickUpLocation: [],
              dropOffLocation: [],
              polyline: null,
              simulationId: null,
              routeList: [],
              route_id: null,
              country_id: null,
              area_id: null,
              selectPickUpLocation: {},
              selectDropOffPoint: {},
              passenger: '',
              route_code: null,
            });
            return;
          }
        });
      }
    }
  };

  /**
   * tabPanel
   * @param {*} data
   * @param {*} value
   * @param {*} index
   * @param  {...any} other
   * @return {*}
   */
  tabPanel = (data, value, index, ...other) => {
    const {t} = this.props;

    return (
      <div role="tabpanel" hidden={value !== index} id={`scrollable-force-tabpanel-${index}`} aria-labelledby={`scrollable-force-tab-${index}`} {...other}>
        {value === index && (
          <Box p={1}>
            <Grid container alignItems="flex-start">
              {value === 0 && (
                <div>
                  <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                    <TableHead>
                      <TableRow>
                        <TableCell className="width_100p">{t('reservationManagement.reservation_number')}</TableCell>
                        <TableCell className="width_100p">{t('reservationManagement.pickup_location')}</TableCell>
                        <TableCell className="width_100p">{t('reservationManagement.dropoff_location')}</TableCell>
                        <TableCell className="width_150p">{t('reservationManagement.passenger')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data &&
                        data?.content?.map((item, index) => (
                          <TableRow key={index} className={Number(item.id) === Number(this.state.selectHistoryId) ? 'background-color-secondary cursor_pointer' : 'cursor_pointer'}>
                            <TableCell onClick={() => this.chooseHistory(item)}>{item.reservation_code}</TableCell>
                            <TableCell onClick={() => this.chooseHistory(item)}>{item.offer_pickup_display_name}</TableCell>
                            <TableCell onClick={() => this.chooseHistory(item)}>{item.offer_dropoff_display_name}</TableCell>
                            <TableCell onClick={() => this.chooseHistory(item)}>{item.demand?.passenger}</TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  {data && <CustomPagination onChange={this.onChangePagination} rows={data?.totalSize} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />}
                </div>
              )}
            </Grid>
          </Box>
        )}
      </div>
    );
  };

  /**
   * onChangeSelectCountry
   * @param {event} event
   */
  onChangeSelectCountry = async (event) => {
    const zone_id = this.props.common?.country_code.find((country) => country.id === event.target.value)?.zone_id || 'Asia/Tokyo';
    this.setState({country_id: event.target.value, zone_id});
    this.props.searchAreaGeofence({countryId: event.target.value, targetScreen: 'ENABLE_FOR_ADMIN_ALL'}).then((response) => {
      this.setState({listArea: response?.content});
    });
    this.setState({
      route_id: null,
      area_id: null,
      selectPickUpLocation: {},
      selectDropOffLocation: {},
      routeList: [],
      pickUpLocation: [],
      dropOffLocation: [],
      selectedGeofence: '',
      infantNummberList: [],
      infantNumber: 0,
      passenger: '',
    });
  };

  /**
   * onChangeSelectArea
   * @param {event} event
   */
  onChangeSelectArea = async (event) => {
    this.setState({area_id: event.target.value});
    this.props.searchJitByAreaId({geofenceAreaId: event.target.value, enable: true}).then((response) => {
      this.setState({routeList: response?.content});
    });
    this.setState({
      route_id: null,
      selectPickUpLocation: {},
      selectDropOffLocation: {},
      pickUpLocation: [],
      dropOffLocation: [],
      selectedGeofence: '',
      infantNummberList: [],
      infantNumber: 0,
      passenger: '',
    });
  };

  /**
   * onChangeSelectArea
   * @param {event} event
   */
  onChangeSelectRoute = async (event) => {
    if (event) this.setState({route_id: event.target.value});
    const route = this.state.routeList.find((route) => route.route_id === event.target.value);
    const geofence = this.state.listGeofence.find((geofence) => geofence.geofence_id === route.geofence_id);
    const maxPassenger = route?.group_type === GROUP_TYPES_BOOKING_JIT[0] ? geofence?.jit_w2h_config?.booking_user_limit : geofence?.jit_h2w_config?.booking_user_limit;
    this.props.getJitRoutePolyLine(route.route_code).then((response) => {
      const dataMapJit = response?.routes.find((route) => route.route_id === event.target.value);
      const simulationId = dataMapJit.simulation_id;
      const pickUpLocation = dataMapJit.turn_locations.filter((location) => dataMapJit.pickup_stop_ids.includes(location.from_stop_id));
      const dropOffLocation = dataMapJit.turn_locations.filter((location) => dataMapJit.dropoff_stop_ids.includes(location.to_stop_id));
      const polyline = dataMapJit.polyline;
      this.setState({
        selectedGeofence: geofence,
        pickUpLocation: pickUpLocation,
        dropOffLocation: dropOffLocation,
        polyline: polyline,
        simulationId,
        maxPassenger,
        selectPickUpLocation: {},
        selectDropOffLocation: {},
        infantNummberList: [],
        infantNumber: 0,
        passenger: '',
      });
    });
  };

  /**
   * onChangeSelectPassenger
   * @param {event} event
   * @param {boolean} allow_infants
   */
  onChangeSelectPassenger = async (event, allow_infants) => {
    if (event) {
      const passenger = event.target.value;
      if (allow_infants) {
        this.renderInfantNumberList(passenger, this.state.maxPassenger);
      }
      this.setState({passenger, infantNumber: 0});
    }
  };

  /**
   * renderInfantNumberList
   * @param {number} passenger
   * @param {number} maxPassenger
   */
  renderInfantNumberList = async (passenger, maxPassenger) => {
    let maxInfantNumber = maxPassenger - passenger;
    const infantNumberAdultCan = passenger * 2;
    maxInfantNumber = maxInfantNumber >= infantNumberAdultCan ? infantNumberAdultCan : maxInfantNumber;
    const infantNummberList = [];
    for (let i = 1; i <= maxInfantNumber; i++) {
      infantNummberList.push(i);
    }
    this.setState({infantNummberList});
  };

  /**
   * handleChangePickup
   * @param {event} event
   * @param {object} value
   */
  handleChangePickup = async (event, value) => {
    if (event) {
      const selectPickUpLocation = this.state.pickUpLocation.find((location) => location?.from_stop_id === value?.from_stop_id);
      this.setState({selectPickUpLocation});
    }
  };

  /**
   * handleChangeDropOff
   * @param {event} event
   * @param {object} value
   */
  handleChangeDropOff = async (event, value) => {
    if (event) {
      const selectDropOffLocation = this.state.dropOffLocation.find((location) => location?.to_stop_id === value?.to_stop_id);
      this.setState({selectDropOffLocation});
    }
  };

  /**
   * go to search routes booking
   */
  toPageSearchBooking = () => {
    const {service_type, passenger} = this.state;
    const data = {
      service_type: service_type,
      maxPassenger: this.state.maxPassenger,
      passenger: passenger,
      demand: {
        passenger: passenger,
      },
    };
    let bookingInfor = Object.assign({}, this.props.bookingInfo);
    let searchBooking = Object.assign({}, this.props.searchBooking);
    const route = this.state.routeList.find((route) => route.route_id === this.state.route_id);
    const geofence = this.state.listGeofence.find((geofence) => geofence.geofence_id === route.geofence_id);
    const payment_methods = PAYMENT_METHODS.filter((payment) =>
      route.group_type === GROUP_TYPES_BOOKING_JIT[0] ?
        geofence?.jit_w2h_config?.payment_method.includes(payment.id) :
        geofence?.jit_h2w_config?.payment_method.includes(payment.id),
    );
    bookingInfor = {...bookingInfor, ...data};
    if (route?.estimate_option === 'MANUAL') {
      const indexSelectPickUp = route.turn_locations.findIndex((location) => location.to_stop_id === this.state.selectPickUpLocation.from_stop_id);
      const indexSelectDropOff = route.turn_locations.findIndex((location) => location.to_stop_id === this.state.selectDropOffLocation.to_stop_id);
      let estimateTimePickUp = 0;
      let estimateTimeDropOff = 0;
      // caculate estimate time manual by group type
      if (route.group_type === GROUP_TYPES_BOOKING_JIT[0]) {
        for (let i = 0; i <= indexSelectDropOff; i++) {
          if (indexSelectPickUp >= i) {
            estimateTimePickUp += route.turn_locations[i]?.estimate_driving_time;
          }
          estimateTimeDropOff += route.turn_locations[i]?.estimate_driving_time;
        }
      } else {
        for (let i = route.turn_locations?.length - 1; i > indexSelectPickUp; i--) {
          if (i > indexSelectDropOff) {
            estimateTimeDropOff += route.turn_locations[i]?.estimate_driving_time;
          }
          estimateTimePickUp += route.turn_locations[i]?.estimate_driving_time;
        }
      }
      bookingInfor.estimate_time_pick_up = estimateTimePickUp;
      bookingInfor.estimate_time_drop_off = estimateTimeDropOff;
    }
    bookingInfor.member_id = searchBooking.search_member.member_id;
    bookingInfor.geofence_id = route.geofence_id;
    bookingInfor.pickup_location_lat = this.state.selectPickUpLocation?.from.latitude;
    bookingInfor.pickup_location_lon = this.state.selectPickUpLocation?.from.longitude;
    bookingInfor.pickup_location_name = this.state.selectPickUpLocation?.from?.name_translations[localStorage.getItem('i18nextLng')] || this.state.selectPickUpLocation?.from?.name;
    bookingInfor.preferred_pickup_stop_id = this.state.selectPickUpLocation?.from.swat_transit_stop_id;
    bookingInfor.dropoff_location_lat = this.state.selectDropOffLocation?.to.latitude;
    bookingInfor.dropoff_location_lon = this.state.selectDropOffLocation?.to.longitude;
    bookingInfor.dropoff_location_name = this.state.selectDropOffLocation?.to?.name_translations[localStorage.getItem('i18nextLng')] || this.state.selectDropOffLocation?.to?.name;
    bookingInfor.preferred_dropoff_stop_id = this.state.selectDropOffLocation?.to.swat_transit_stop_id;
    bookingInfor.demand.passenger = this.state.passenger;
    bookingInfor.demand.no_infants = this.state.infantNumber;
    bookingInfor.currency_code = route.group_type === GROUP_TYPES_BOOKING_JIT[0] ? geofence?.jit_w2h_config?.currency_code : geofence.jit_h2w_config?.currency_code;
    bookingInfor.simulation_id = this.state.simulationId;
    bookingInfor.transportation_type = 'SHUTTLE_BUS';
    bookingInfor.estimate_option = route?.estimate_option || 'SWAT';
    this.props.onUpdateBooking(bookingInfor);

    searchBooking = {...searchBooking, ...data};
    searchBooking.simulationId = this.state.simulationId;
    searchBooking.selectPickUpLocation = this.state.selectPickUpLocation;
    searchBooking.selectDropOffLocation = this.state.selectDropOffLocation;
    searchBooking.country_id = this.state.country_id;
    searchBooking.area_id = this.state.area_id;
    searchBooking.route_id = this.state.route_id;
    searchBooking.route = route;
    searchBooking.route_code = this.state.route_code || route.route_code;
    searchBooking.pickUpLocation = this.state.pickUpLocation;
    searchBooking.dropOffLocation = this.state.dropOffLocation;
    searchBooking.allowInfants = this.state.selectedGeofence?.allow_infants;
    searchBooking.service_config = {
      adult_price: route.group_type === GROUP_TYPES_BOOKING_JIT[0] ? geofence?.jit_w2h_config?.adult_price : geofence?.jit_h2w_config?.adult_price,
      child_price: route.group_type === GROUP_TYPES_BOOKING_JIT[0] ? geofence?.jit_w2h_config?.child_price : geofence.jit_h2w_config?.child_price,
      currency_code: route.group_type === GROUP_TYPES_BOOKING_JIT[0] ? geofence?.jit_w2h_config?.currency_code : geofence.jit_h2w_config?.currency_code,
    };
    searchBooking.mapbox_path_segment = geofence?.mapbox_path_segment;
    searchBooking.vehicle_modes = geofence?.vehicle_modes;
    searchBooking.country_code = geofence?.country?.country_code;
    searchBooking.payment_methods = payment_methods || [];
    searchBooking.zone_id = this.state.zone_id;
    searchBooking.infantNumber = this.state.infantNumber;
    searchBooking.selectedGeofence = this.state.selectedGeofence || '';
    this.props.searchBookingUpdate(searchBooking);
    this.setState({
      isSubmitForm: true,
    });
    redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.RESERVATION_BOOKING_JIT_FLIGHT);
  };

  /**
   * renderPassenger
   * @return {*}
   */
  renderPassenger = () => {
    const listPass = [];
    for (let i = 1; i <= this.state.maxPassenger; i++) {
      listPass.push(i);
    }
    return listPass;
  };

  /**
   * reset
   */
  reset = async () => {
    this.setState({
      positionFrom: null,
      positionTo: null,
      selectedGeofence: '',
      passenger: '',
      currency_code: '',
      maxPassenger: 4,
      tab: 0,
      selectHistoryId: '',
      isSubmitForm: false,
      favoritePickUpId: '',
      favoriteDropOffId: '',
      location_tag: '',
      country_id: !isRoleGlobal() ? this.props.principal.country_id : '',
      area_id: null,
      route_id: null,
      selectPickUpLocation: {},
      selectDropOffLocation: {},
    });
  };

  /**
   * handleMarker
   * @param {*} point
   * @param {*} type
   */
  handleMarker(point, type) {
    if (type === 'PICK_UP') {
      const selectPickUpLocation = point;
      this.setState({selectPickUpLocation});
    } else {
      const selectDropOffLocation = point;
      this.setState({selectDropOffLocation});
    }
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t, common, booking_history, searchBooking} = this.props;
    const allow_infants = this.state.selectedGeofence?.allow_infants;
    this.validator.purgeFields();
    return (
      <div>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('reservationManagement.create_reservation')}</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}>
                <Button
                  color="primary"
                  variant="contained"
                  className="button_margin button_color"
                  endIcon={<ArrowBackIcon />}
                  onClick={() => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.RESERVATION_CREATE)}
                >
                  {t('common.btnReturn')}
                </Button>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth="xl">
            <Card raised>
              <Box p={1}>
                <Container maxWidth="xl">
                  <Grid container spacing={1} alignItems="flex-start">
                    <Grid item xs={12}>
                      <h1 className="product_entry_table_header_color font_color_white font_size_mid title">{t('reservationManagement.search_route')}</h1>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} alignItems="flex-start">
                    {/* Location booking data */}
                    <Grid item xs={3}>
                      <Grid container spacing={1} alignItems="flex-start">
                        <Grid item xs={12}>
                          <FormControl variant="outlined" className="field_size_20 field_min_size_250" margin="dense">
                            <InputLabel
                              htmlFor={
                                <span>
                                  {t('common.country')}
                                  <span className="font_color_red">＊</span>
                                </span>
                              }
                            >
                              {t('common.country')}
                              <span className="font_color_red">＊</span>
                            </InputLabel>
                            <Select
                              margin="dense"
                              label={
                                <span>
                                  {t('common.country')}
                                  <span className="font_color_red">＊</span>
                                </span>
                              }
                              inputProps={{
                                name: 'country_id',
                              }}
                              value={this.state.country_id || ''}
                              onChange={(event) => this.onChangeSelectCountry(event)}
                              disabled={!isRoleGlobal()}
                            >
                              {common?.country_code?.map((item, idx) => (
                                <MenuItem value={item.id} key={idx}>
                                  {t(`${item.country_code}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl variant="outlined" className="field_size_20 field_min_size_250" margin="dense">
                            <InputLabel
                              htmlFor={
                                <span>
                                  {t('common.area')}
                                  <span className="font_color_red">＊</span>
                                </span>
                              }
                            >
                              {t('common.area')}
                              <span className="font_color_red">＊</span>
                            </InputLabel>
                            <Select
                              margin="dense"
                              label={
                                <span>
                                  {t('common.area')}
                                  <span className="font_color_red">＊</span>
                                </span>
                              }
                              inputProps={{
                                name: 'area_id',
                              }}
                              value={this.state.area_id || ''}
                              onChange={(event) => this.onChangeSelectArea(event)}
                            >
                              {this.state.listArea?.map((item, idx) => (
                                <MenuItem value={item?.id} key={idx}>
                                  {t(`${item?.name}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl variant="outlined" className="field_size_20 field_min_size_250" margin="dense">
                            <InputLabel
                              htmlFor={
                                <span>
                                  {t('common.route')}
                                  <span className="font_color_red">＊</span>
                                </span>
                              }
                            >
                              {t('common.route')}
                              <span className="font_color_red">＊</span>
                            </InputLabel>
                            <Select
                              margin="dense"
                              label={
                                <span>
                                  {t('common.route')}
                                  <span className="font_color_red">＊</span>
                                </span>
                              }
                              inputProps={{
                                name: 'route_id',
                              }}
                              value={this.state.route_id || ''}
                              onChange={(event) => this.onChangeSelectRoute(event)}
                            >
                              {this.state.routeList?.map((item, idx) => (
                                <MenuItem value={item.route_id} key={idx}>
                                  {t(`${item.display_name}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        {this.state.service_type !== 'DELIVERY' && (
                          <>
                            {allow_infants && (
                              <>
                                <Grid className="font_size_small font_bold" item xs={12}>
                                  {t('reservationManagement.infantsCanBeAdd')}
                                </Grid>
                                <Grid className="font_size_small font_bold" item xs={12}>
                                  {t('reservationManagement.maxNumberOfPassenger', {maxPassenger: this.state.maxPassenger})}
                                </Grid>
                               </>
                            )}
                            <Grid item xs={12}>
                              <FormControl variant="outlined" className="field_size_20 field_min_size_250" margin="dense">
                                <InputLabel>
                                  <span>
                                    {allow_infants ? t('reservationManagement.adult') + '/' + t('reservationManagement.child') : t('reservationManagement.passenger')}
                                    <span className="font_color_red">＊</span>
                                  </span>
                                </InputLabel>
                                <Select
                                  margin="dense"
                                  inputProps={{
                                    name: 'passenger',
                                  }}
                                  label={
                                    <span>
                                      {allow_infants ? t('reservationManagement.adult') + '/' + t('reservationManagement.child') : t('reservationManagement.passenger')}
                                      <span className="font_color_red">＊</span>
                                    </span>
                                  }
                                  value={this.state.passenger || ''}
                                  onChange={(event) => this.onChangeSelectPassenger(event, allow_infants)}
                                >
                                  {this.renderPassenger().map((item, idx) => (
                                    <MenuItem value={item} key={idx}>
                                      {item}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              {this.validator.message('passenger', this.state.passenger, `integer|numeric|min:1,num|max:${this.state.maxPassenger},num`)}
                              {this.state.isSubmitForm && !this.validator.check(this.state.passenger, `integer|numeric|min:1,num|max:${this.state.maxPassenger},num`) && (
                                <p className="error">{t('validation.max', {value: this.state.maxPassenger})}</p>
                              )}
                            </Grid>
                            {allow_infants && (
                              <Grid item xs={12}>
                                <FormControl variant="outlined" className="field_size_20 field_min_size_250" margin="dense">
                                  <InputLabel>{t('reservationManagement.infant')}</InputLabel>
                                  <Select
                                    margin="dense"
                                    inputProps={{
                                      name: 'infants',
                                    }}
                                    label={<span>{t('reservationManagement.infant')}</span>}
                                    value={this.state.infantNumber || ''}
                                    onChange={(event) => this.setState({infantNumber: event.target.value})}
                                  >
                                    {this.state.infantNummberList.map((item, idx) => (
                                      <MenuItem value={item} key={idx}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                            )}
                          </>
                        )}
                      </Grid>
                    </Grid>
                    {/* History and Favorite */}
                    <Grid item xs={9}>
                      {searchBooking.search_member?.member_type === 'MEMBER' && (
                        <Grid item xs={12}>
                          <AppBar position="static" color="default">
                            <Tabs value={this.state.tab} indicatorColor="primary" textColor="primary" variant="fullWidth" aria-label="full width tabs example">
                              <Tab label="History" {...this.a11yProps(0)} />
                            </Tabs>
                          </AppBar>
                          {this.tabPanel(booking_history, this.state.tab, 0)}
                        </Grid>
                      )}
                    </Grid>
                    <Grid xs={12} container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={6}>
                        <Grid xs={12}>
                          {t('reservationManagement.pickup_location_booking_jit')} <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid xs={12}>
                          <Autocomplete
                            margin="dense"
                            className="field_min_size_350"
                            options={this.state.pickUpLocation}
                            value={this.state.selectPickUpLocation}
                            getOptionLabel={(option) => option?.from?.name_translations[localStorage.getItem('i18nextLng')] || option?.from?.name || ''}
                            freeSolo={true}
                            renderValue={
                              this.state.country_id ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('reservationManagement.pickup_location_booking_jit'),
                                      })}
                                    </div>
                                  )
                            }
                            onChange={(event, value) => {
                              this.handleChangePickup(event, value);
                            }}
                            renderInput={(params) => <TextField {...params} name={'pickUpLocation'} fullWidth variant="outlined" margin="dense" />}
                          />
                        </Grid>
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={6}>
                        <Grid xs={12}>
                          {t('reservationManagement.dropoff_location_booking_jit')} <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid xs={12}>
                          <Autocomplete
                            margin="dense"
                            className="field_min_size_350"
                            options={this.state.dropOffLocation}
                            value={this.state.selectDropOffLocation}
                            getOptionLabel={(option) => option?.to?.name_translations[localStorage.getItem('i18nextLng')] || option?.to?.name || ''}
                            freeSolo={true}
                            renderValue={
                              <div className="font-12 color-disabled">
                                {t('placeholder.required_select', {
                                  field: t('reservationManagement.dropoff_location_booking_jit'),
                                })}
                              </div>
                            }
                            onChange={(event, value) => {
                              this.handleChangeDropOff(event, value);
                            }}
                            renderInput={(params) => <TextField {...params} name={'dropOffLocation'} fullWidth variant="outlined" margin="dense" />}
                          />{' '}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className="row_form_item">
                      {this.state.service_type !== 'DELIVERY' &&
                        this.state.polyline &&
                        this.state.country_id &&
                        this.state.area_id &&
                        this.state.route_id &&
                        this.props.isLoading === false && (
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <LoadingOverlay active={this.props.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                                <MapBookingJit
                                  dataPolyline={{
                                    polyline: this.state.polyline,
                                    type: 'tracking',
                                    pickUpPoint: this.state.pickUpLocation,
                                    dropOffPoint: this.state.dropOffLocation,
                                    selectPickUpPoint: this.state.selectPickUpLocation,
                                    selectDropOffPoint: this.state.selectDropOffLocation,
                                    center_point: {
                                      lat: this.state.listArea.find((area) => area.id === this.state.area_id)?.center_point_lat,
                                      lng: this.state.listArea.find((area) => area.id === this.state.area_id)?.center_point_lon,
                                    },
                                    routeId: this.state.route_id,
                                  }}
                                  selectMarker={(point, type) => this.handleMarker(point, type)}
                                />
                              </LoadingOverlay>
                            </Grid>
                          </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12} className="text_center">
                      <Button
                        color="primary"
                        variant="contained"
                        className="button_margin"
                        onClick={() => this.toPageSearchBooking()}
                        disabled={
                          !this.state.country_id ||
                          !this.state.area_id ||
                          !this.state.route_id ||
                          !this.state.passenger ||
                          _.isEmpty(this.state.selectPickUpLocation) ||
                          _.isEmpty(this.state.selectDropOffLocation)
                        }
                      >
                        {t('common.btnSearch')}
                      </Button>
                      <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.reset()}>
                        {t('common.btnReset')}
                      </Button>
                    </Grid>
                  </Grid>
                </Container>
              </Box>
            </Card>
          </Container>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    searchBooking: state.reservation.searchBooking,
    bookingInfo: state.reservation.bookingInfo,
    booking_history: state.reservation.booking_history,
    geofence: state.common.geofence_list,
    isLoading: state.reservation.isLoading,
    waypointList: state.member.waypointList,
    common: state.common,
    reservation: state.reservation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateBooking: (bookingData) => {
      dispatch(updateBooking(bookingData));
    },
    getListGeofence: (payload) => dispatch(getListGeofence(payload)),
    searchBookingUpdate: (data) => dispatch(searchBookingUpdate(data)),
    getBookingHistory: (userId, queryParams) => dispatch(getBookingHistory(userId, queryParams)),
    getListFavoriteWaypoint: (user_id, geofence_id, location_tag, location_name, page, size) =>
      dispatch(getListFavoriteWaypoint(user_id, geofence_id, location_tag, location_name, page, size)),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getWaypointStops: (payload) => dispatch(getWaypointStops(payload)),
    getJitRouteDetail: (id) => dispatch(getJitRouteDetail(id)),
    searchAreaGeofence: (payload) => dispatch(searchAreaGeofence(payload)),
    searchJitByAreaId: (params) => dispatch(searchJitByAreaId(params)),
    getJitRoutePolyLine: (routeCode) => dispatch(getJitRoutePolyLine(routeCode)),
  };
};

export default withPermissionGateway(connect(mapStateToProps, mapDispatchToProps)(
  withTranslation('translations')(Index),
  // eslint-disable-next-line new-cap
  GoogleApiWrapper((props) => ({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || 'AIzaSyACyapw83diO1bi_xiXbZRLLoano6eTwd0',
    language: localStorage.getItem('i18nextLng') === 'ja' ? 'ja' : 'en',
  })),
));
