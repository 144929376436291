import React, {Component} from 'react';

import {
  Grid,
  Button,
  TextField,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Paper,
  Card,
  Container,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  Dialog,
  IconButton,
  Modal,
  Backdrop,
  Box,
  FormHelperText,
  Switch,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {GoogleApiWrapper} from 'google-maps-react';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import Carousel from 'react-material-ui-carousel';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {ROUTE, BUSINESS_TYPE, BUSINESS_STATUS, LANGUAGE, PERMISSION_ACTIONS} from '../../../common/constant';
import ImageUpload from '../../../components/imageSelect';
import Memo from '../../../components/memo';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getListSupplier, getListGeofence} from '../../../stores/common/actions';
import {getDetailFacility, updateFacility, createFacility} from '../../../stores/facility/action';
import {onChangeSelect, onChangeTextField, onChangeListData, backForwardRouter, getListAllGeofenceDetail, preventInvalidChars} from '../../../utils/common';
import {isRoleBusiness, isRoleGlobal} from '../../../utils/role';

/**
 * Sale Stores/ Offices From Component
 */
class SaleOfficeForm extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      supplierId: '',
      status: null,
      type: null,
      supplierName: '',
      facilityId: '',
      facilityName: '',
      country: !isRoleGlobal() ? props.principal.country_id : null,
      list_area_id: [],
      listGeofences: [],
      listSupplier: [],
      address: '',
      mobile: '',
      mobile_operator: '',
      personCharge: '',
      language: null,
      email: null,
      holiday: '',
      fee: {},
      list_image_id: [],
      memos: [],
      note: '',
      latitude: null,
      longitude: null,
      open_time: null,
      close_time: null,
      open_time_optional: null,
      close_time_optional: null,
      facility_email_second: '',
      facility_email_third: '',
      home_page: null,

      flgUpdate: false,
      isOpenModal: false,
      message: '',
      fee_type: '',
      checkDetail: false,
      isSubmitForm: false,
      latLng: {},
      place_name: '',
      isDisableButtonCreate: false,
      dataDetail: [],
      countdown_time: null,
      enable_select_driver_name: false,
    };
    this.handleButtonUpdateOk = this.handleButtonUpdateOk.bind(this);
    this.handleButtonUpdateCancel = this.handleButtonUpdateCancel.bind(this);
    this.validator = new SimpleReactValidator();
  }

  /**
   * handleCreate
   */
  handleCreate = () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid() && !this.checkTimeRange() && !this.checkTimeRangeOption()) {
      this.setState({
        flgUpdate: true,
        message: 'facility.question.confirm.create',
      });
    }
  };

  /**
   * change business
   * @param {string} value
   */
  changeBusiness = (value) => {
    const supplierId = value;
    this.setState({supplierId: supplierId});
    const listGeofences = value?.geofence_ids!== null ? this.props.common.geofence_list.filter((item) => value?.geofence_ids.includes(item.geofence_id)) : [];
    this.setState({listGeofences});
  };

  /**
   * handleUpdate
   */
  handleUpdate = () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid() && !this.checkTimeRange() && !this.checkTimeRangeOption()) {
      this.setState({
        flgUpdate: true,
        message: 'facility.question.confirm.update',
      });
    }
  };

  /**
   * handleButtonOk
   */
  handleButtonUpdateOk() {
    this.setState({
      flgUpdate: false,
      isDisableButtonCreate: true,
    });
    if (this.state.checkDetail) {
      const payload = {
        id: this.state.id,
        supplier_id: this.state.supplierId.id,
        facility_type: this.state.type,
        facility_status: this.state.status,
        facility_name: this.state.facilityName.trim(),
        geofence_ids: this.state.list_area_id,
        facility_address: this.state.address.trim(),
        facility_tel: this.state.mobile.trim(),
        phone_number_operator: this.state.mobile_operator.trim(),
        person_in_charge: this.state.personCharge.trim(),
        website: this.state.home_page?.trim(),
        facility_email: this.state.email.trim(),
        facility_language: this.state.language,
        facility_id: this.state.facilityId,
        facility_holiday: this.state.holiday.trim(),
        list_image_id: this.state.list_image_id ?
          this.state.list_image_id.map((img) => {
            return img.id;
          }) :
          [],
        memos: this.state.memos,
        note: this.state.note,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        open_time: this.state.open_time,
        close_time: this.state.close_time,
        open_time_optional: this.state.open_time_optional,
        close_time_optional: this.state.close_time_optional,
        facility_email_second: this.state.facility_email_second,
        facility_email_third: this.state.facility_email_third,
        countdown_time: this.state.countdown_time,
        enable_select_driver_name: this.state.enable_select_driver_name,
      };
      this.props.updateFacility(payload, this.props).then(() => {
        this.setState({
          isDisableButtonCreate: false,
        });
        this.props.getDetailFacility(this.props.match.params.id).then((result) => {
          if (result) {
            this.setState({
              memos: result.memos,
              note: '',
            });
          }
        });
      });
    } else {
      const payload = {
        facility_email_second: this.state.facility_email_second,
        facility_email_third: this.state.facility_email_third,
        open_time: this.state.open_time,
        close_time: this.state.close_time,
        open_time_optional: this.state.open_time_optional,
        close_time_optional: this.state.close_time_optional,
        latitude: this.state.latitude,
        longitude: this.state.longitude,
        supplier_id: this.state.supplierId.id,
        person_in_charge: this.state.personCharge.trim(),
        website: this.state.home_page?.trim(),
        facility_status: this.state.status,
        facility_name: this.state.facilityName.trim(),
        facility_tel: this.state.mobile.trim(),
        phone_number_operator: this.state.mobile_operator.trim(),
        facility_email: this.state.email.trim(),
        facility_holiday: this.state.holiday.trim(),
        facility_address: this.state.address.trim(),
        facility_type: this.state.type,
        facility_language: this.state.language,
        geofence_ids: this.state.list_area_id,
        list_image_id: this.state.list_image_id ?
          this.state.list_image_id.map((img) => {
            return img.id;
          }) :
          [],
        countdown_time: this.state.countdown_time,
        enable_select_driver_name: this.state.enable_select_driver_name,
      };
      this.props.createFacility(payload, this.props).then(() => {
        this.setState({isDisableButtonCreate: false});
      });
    }
  }

  /**
   * checkTimeRange
   * @return {bool}
   */
  checkTimeRange() {
    if (this.state.open_time && this.state.close_time) {
      const totalSeconds1 = parseInt(this.state.open_time[0] * 3600 + this.state.open_time[1] * 60 + this.state.open_time[0]);
      const totalSeconds2 = parseInt(this.state.close_time[0] * 3600 + this.state.close_time[1] * 60 + this.state.close_time[0]);
      if (totalSeconds1 > totalSeconds2) {
        return true;
      }
    }
    return false;
  }

  /**
   * checkTimeRangeOption
   * @return {*}
   */
  checkTimeRangeOption() {
    if (this.state.open_time_optional && this.state.close_time_optional) {
      const totalSeconds1 = parseInt(this.state.open_time_optional[0] * 3600 + this.state.open_time_optional[1] * 60 + this.state.open_time_optional[0]);
      const totalSeconds2 = parseInt(this.state.close_time_optional[0] * 3600 + this.state.close_time_optional[1] * 60 + this.state.close_time_optional[0]);
      if (totalSeconds1 > totalSeconds2) {
        return true;
      }
    }
  }

  /**
   * handleButtonCancel
   */
  handleButtonUpdateCancel() {
    this.setState({
      flgUpdate: false,
    });
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getListSupplier();
    await this.props.getListGeofence();
    if (this.props.match.url !== ROUTE.LAYOUT + ROUTE.FACILITY_ADD) {
      this.props.getDetailFacility(this.props.match.params.id, this.props).then((result) => {
        if (result) {
          this.setState({
            id: result.id,
            supplierId: {
              id: result.supplier.id,
              supplier_name: result.supplier.supplier_name,
            },
            status: result.facility_status,
            type: result.facility_type,
            supplierName: result.supplier.supplier_name,
            facilityId: result.facility_id,
            home_page: result.website,
            facilityName: result.facility_name,
            country: result.supplier.country,
            list_area_id: result.geofence_ids ? result.geofence_ids : [],
            address: result.facility_address,
            mobile: result.facility_tel,
            mobile_operator: result.phone_number_operator,
            personCharge: result.person_in_charge,
            language: result.facility_language,
            email: result.facility_email,
            holiday: result.facility_holiday,
            list_image_id: result.images,
            memos: result.memos,
            checkDetail: true,
            latitude: result.latitude,
            longitude: result.longitude,
            open_time: result.open_time?.substr(0, 5),
            open_time_optional: result.open_time_optional?.substr(0, 5),
            close_time: result.close_time?.substr(0, 5),
            close_time_optional: result.close_time_optional?.substr(0, 5),
            facility_email_second: result?.facility_email_second,
            facility_email_third: result?.facility_email_third,
            dataDetail: result,
            countdown_time: result?.countdown_time,
            enable_select_driver_name: result.enable_select_driver_name,
          });
          const listGeofences = result.supplier.geofence_ids!== null ?
            this.props.common.geofence_list.filter((item) => result?.supplier?.geofence_ids.includes(item.geofence_id)) : [];
          this.setState({listGeofences});
        } else {
          backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.FACILITY_INFORMATION);
        }
      }, () => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.FACILITY_INFORMATION));
    }
    const {google, common} = this.props;
    this.setState({
      listSupplier: common?.supplier_list.filter((item) => item.business_role_type === 'BUSINESS'),
      listFacility: common?.facility_list_all.filter((item) => item.business_role_type === 'BUSINESS'),
      });
    if (!google) return;

    const autocomplete = new google.maps.places.Autocomplete(this.autocomplete);
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      this.setState({
        latLng: place.geometry.location,
        address: place.formatted_address,
        latitude: parseFloat(place.geometry.location.lat()),
        longitude: parseFloat(place.geometry.location.lng()),
      });
    });
  }

  /**
   * handleButtonCloseModal
   */
  handleButtonCloseModal = () => {
    this.setState({
      isOpenModal: false,
    });
  };

  /**
   * handleButtonCloseModal
   * @param {string} event
   */
  handleChange(event) {
    this.setState({
      address: event.target.value,
      latLng: {},
      latitude: null,
      longitude: null,
    });
  }

  /**
   * changeAreaBy
   * @param {*} value
   * @param {*} item
   */
  changeAreaBy = (value, item) => {
    const list_area_id_copy = this.state.list_area_id;
    const index = list_area_id_copy?.indexOf(item);
    value ? list_area_id_copy.push(item) : list_area_id_copy.splice(index, 1);
    this.setState({
      list_area_id: list_area_id_copy,
    });
  };

  /**
   * onChangeDriverName
   * @param {*} check
   */
  onChangeDriverName = (check) => {
    this.setState({enable_select_driver_name: check});
  }

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {facility, t, common, actions} = this.props;
    const listGeofences = this.props.match.params.id ? getListAllGeofenceDetail(this.state.listGeofences, this.state.dataDetail.geofence_config_infos) : this.state.listGeofences;
    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
    };

    return (
      <LoadingOverlay active={facility.isLoading || common.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                {this.state.checkDetail ? <h3>{t('route.facility.detail')}</h3> : <h3>{t('route.facility.add')}</h3>}
              </Grid>
            </Grid>
          </Container>
          <br></br>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('facility.title.detail')}
                    </Grid>
                  </Grid>
                  {this.state.checkDetail && (
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('facility.id')}
                        <span className="font_color_red">＊</span>
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <TextField
                          name="facilityId"
                          className="field_size_10 field_min_size_150"
                          margin="dense"
                          variant="outlined"
                          value={this.state.facilityId || ''}
                          onChange={(event) => onChangeTextField(this, event)}
                          inputProps={{
                            readOnly: this.state.checkDetail,
                          }}
                          disabled={true}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {/* business name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('facility.business.name')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl>
                        <Autocomplete
                          margin="dense"
                          className="field_size_10 field_min_size_300"
                          options={this.state.listSupplier}
                          value={this.state.supplierId || ''}
                          placeholder={t('placeholder.required', {field: t('facility.business.name')})}
                          getOptionLabel={(option) => option.supplier_name || ''}
                          onChange={(event, business) => this.changeBusiness(business)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={'businessName'}
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              placeholder={t('placeholder.required', {field: t('businessInformation.name')})}
                            />
                          )}
                          disabled={this.props.match.params.id}
                        />
                        {this.validator.message('supplierId', this.state.supplierId, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.supplierId, 'required') && (
                          <FormHelperText id="supplierId" error>
                            {t('validation.required.choose', {field: t('facility.business.name')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Sales office / store name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('facility.name')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl>
                        <div className="box-group-input">
                          <TextField
                            name="facilityName"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('facility.name')})}
                            variant="outlined"
                            value={this.state.facilityName || ''}
                            inputProps={{maxLength: 255}}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 255})}</div>
                        </div>
                        {this.validator.message('facilityName', this.state.facilityName, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.facilityName, 'required') && (
                          <FormHelperText id="facilityName" error>
                            {t('validation.required', {field: t('facility.name')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Contract status */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('facility.status')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'status',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.status ?
                              undefined :
                              () => (
                                <div className="font-12 color-disabled">
                                  {t('placeholder.required_select', {
                                    field: t('facility.status'),
                                  })}
                                </div>
                              )
                          }
                          value={this.state.status || ''}
                          onChange={(event) => onChangeSelect(this, event)}
                          disabled={isRoleBusiness()}
                        >
                          {BUSINESS_STATUS.map((item, idx) => {
                            return (
                              <MenuItem value={item.id} key={idx}>
                                {t(`${item.i18n}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('status', this.state.status, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.status, 'required') && (
                          <FormHelperText id="status" error>
                            {t('validation.required.choose', {field: t('facility.status')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* service type */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('facility.type')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'type',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.type ?
                              undefined :
                              () => (
                                <div className="font-12 color-disabled">
                                  {t('placeholder.required_select', {
                                    field: t('facility.type'),
                                  })}
                                </div>
                              )
                          }
                          value={this.state.type || ''}
                          onChange={(event) => onChangeSelect(this, event)}
                        >
                          {BUSINESS_TYPE.map((item, idx) => {
                            return (
                              <MenuItem value={item.id} key={idx}>
                                {t(`${item.i18n}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('type', this.state.type, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.type, 'required') && (
                          <FormHelperText id="type" error>
                            {t('validation.required.choose', {field: t('facility.type')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* geofence */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.geofence')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={6}>
                      <FormControl error>
                        <FormGroup row>
                          {this.state.supplierId !== '' &&
                            listGeofences.map((item, index) => {
                              const checked = this.state.list_area_id?.indexOf(item?.geofence_id) > -1;
                              return (
                                <FormControlLabel
                                  key={index}
                                  labelPlacement="end"
                                  control={
                                    <Checkbox
                                      disabled={item?.enable === false || item?.enable_for_admin === false}
                                      checked={checked}
                                      onClick={(event) => this.changeAreaBy(event.target.checked, item?.geofence_id)}
                                      className="checkbox_radio"
                                    />
                                  }
                                  label={item?.name}
                                  disabled={isRoleBusiness()}
                                />
                              );
                            })
                        }
                        </FormGroup>
                        {this.validator.message('geofence', this.state.list_area_id, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.list_area_id, 'required') && (
                          <FormHelperText id="list_area_id" error>
                            {t('validation.required.choose', {field: t('common.geofence')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* address */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.address')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl>
                        <TextField
                          name="address"
                          className="field_size_20 field_min_size_600"
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('common.address')})}
                          variant="outlined"
                          value={this.state.address || ''}
                          onChange={(event) => this.handleChange(event)}
                          inputRef={(ref) => (this.autocomplete = ref)}
                        />
                        {this.validator.message('address', this.state.address, 'required')}
                        {this.validator.message('latitude', this.state.latitude, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.address, 'required') && (
                          <FormHelperText id="address" error>
                            {t('validation.required', {field: t('common.address')})}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm && this.validator.check(this.state.address, 'required') && !this.validator.check(this.state.latitude, 'required') && (
                          <FormHelperText id="address" error>
                            {t('validation.invalid.field', {field: t('common.address')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* phone number */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.phoneNumber')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl>
                        <div className="box-group-input">
                          <TextField
                            name="mobile"
                            className="field_size_20 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('common.phoneNumber')})}
                            variant="outlined"
                            value={this.state.mobile || ''}
                            onChange={(event) => onChangeTextField(this, event)}
                            inputProps={{maxLength: 15}}
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 15})}</div>
                        </div>
                        {this.validator.message('mobile', this.state.mobile, 'required|numeric')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.mobile, 'required') && (
                          <FormHelperText id="mobile" error>
                            {t('validation.required', {field: t('common.phoneNumber')})}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm && this.validator.check(this.state.mobile, 'required') && !this.validator.check(this.state.mobile, 'numeric') && (
                          <FormHelperText id="mobile" error>
                            {t('validation.phone', {field: t('common.phoneNumber')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* phone number operator */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('facility.mobile_operator')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl>
                        <div className="box-group-input">
                          <TextField
                            name="mobile_operator"
                            className="field_size_20 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('facility.mobile_operator')})}
                            variant="outlined"
                            value={this.state.mobile_operator || ''}
                            onChange={(event) => onChangeTextField(this, event)}
                            inputProps={{maxLength: 15}}
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 15})}</div>
                        </div>
                        {this.validator.message('mobile_operator', this.state.mobile_operator, 'required|numeric')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.mobile_operator, 'required') && (
                          <FormHelperText id="mobile_operator" error>
                            {t('validation.required', {field: t('facility.mobile_operator')})}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm &&
                        this.validator.check(this.state.mobile_operator, 'required') &&
                        !this.validator.check(this.state.mobile_operator, 'numeric') && (
                          <FormHelperText id="mobile_operator" error>
                            {t('validation.phone', {field: t('facility.mobile_operator')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('facility.home_page')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <div className="box-group-input">
                        <TextField
                          name="home_page"
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('facility.home_page')})}
                          variant="outlined"
                          inputProps={{maxLength: 255}}
                          value={this.state.home_page || ''}
                          onChange={(event) => onChangeTextField(this, event)}
                        />
                        <div className="max-length-label">{t('validation.max.label', {value: 255})}</div>
                      </div>
                    </Grid>
                  </Grid>

                  {/* Person in charge */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('facility.person_charge')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl>
                        <div className="box-group-input">
                          <TextField
                            name="personCharge"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('facility.person_charge')})}
                            variant="outlined"
                            inputProps={{maxLength: 255}}
                            value={this.state.personCharge || ''}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 255})}</div>
                        </div>
                        {this.validator.message('personCharge', this.state.personCharge, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.personCharge, 'required') && (
                          <FormHelperText id="personCharge" error>
                            {t('validation.required', {field: t('facility.person_charge')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Language */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('facility.language')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'language',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.language ?
                              undefined :
                              () => (
                                <div className="font-12 color-disabled">
                                  {t('placeholder.required_select', {
                                    field: t('facility.language'),
                                  })}
                                </div>
                              )
                          }
                          value={this.state.language || ''}
                          onChange={(event) => onChangeSelect(this, event)}
                        >
                          {LANGUAGE.map(({language, i18n}, idx) => {
                            return (
                              <MenuItem value={language} key={idx}>
                                {t(`${i18n}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('language', this.state.language, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.language, 'required') && (
                          <FormHelperText id="language" error>
                            {t('validation.required.choose', {field: t('facility.language')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Email */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('facility.email')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl>
                        <div className="box-group-input">
                          <TextField
                            name="email"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('facility.email')})}
                            variant="outlined"
                            value={this.state.email || ''}
                            inputProps={{maxLength: 64}}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 64})}</div>
                        </div>
                        {this.validator.message('email', this.state.email, `required|email|not_in:${this.state.facility_email_third},${this.state.facility_email_second}`)}
                        {this.state.isSubmitForm && !this.validator.check(this.state.email, 'required') && (
                          <FormHelperText id="email" error>
                            {t('validation.required', {field: t('facility.email')})}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm && this.validator.check(this.state.email, 'required') && !this.validator.check(this.state.email, 'email') && (
                          <FormHelperText id="email" error>
                            {t('validation.email', {field: t('facility.email')})}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm &&
                          this.validator.check(this.state.email, 'email') &&
                          this.validator.check(this.state.email, 'required') &&
                          !this.validator.check(this.state.email, `not_in:${this.state.facility_email_third},${this.state.facility_email_second}`) && (
                          <FormHelperText id="email" error>
                            {t('validation.duplicate', {field: t('facility.email')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Mail address 2 */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('facility.email_2')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl>
                        <div className="box-group-input">
                          <TextField
                            name="facility_email_second"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('facility.email_2')})}
                            variant="outlined"
                            inputProps={{maxLength: 64}}
                            value={this.state.facility_email_second || ''}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 64})}</div>
                        </div>
                        {this.validator.message('facility_email_second', this.state.facility_email_second, `email|not_in:${this.state.email},${this.state.facility_email_third}`)}
                        {this.state.isSubmitForm && !this.validator.check(this.state.facility_email_second, 'email') && (
                          <FormHelperText id="facility_email_second" error>
                            {t('validation.email', {field: t('facility.email_2')})}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm &&
                          this.validator.check(this.state.facility_email_second, 'email') &&
                          !this.validator.check(this.state.facility_email_second, `not_in:${this.state.email},${this.state.facility_email_third}`) && (
                          <FormHelperText id="facility_email_second" error>
                            {t('validation.duplicate', {field: t('facility.email_2')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Mail address 3 */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('facility.email_3')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl>
                        <div className="box-group-input">
                          <TextField
                            name="facility_email_third"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('facility.email_3')})}
                            variant="outlined"
                            inputProps={{maxLength: 64}}
                            value={this.state.facility_email_third || ''}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 64})}</div>
                        </div>
                        {this.validator.message('facility_email_third', this.state.facility_email_third, `email|not_in:${this.state.email},${this.state.facility_email_second}`)}
                        {this.state.isSubmitForm && !this.validator.check(this.state.facility_email_third, 'email') && (
                          <FormHelperText id="facility_email_third" error>
                            {t('validation.email', {field: t('facility.email_3')})}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm &&
                          this.validator.check(this.state.facility_email_third, 'email') &&
                          !this.validator.check(this.state.facility_email_third, `not_in:${this.state.email},${this.state.facility_email_second}`) && (
                          <FormHelperText id="facility_email_third" error>
                            {t('validation.duplicate', {field: t('facility.email_3')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Holiday */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('facility.holiday')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl>
                        <div className="box-group-input">
                          <TextField
                            name="holiday"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('facility.holiday')})}
                            variant="outlined"
                            inputProps={{maxLength: 255}}
                            value={this.state.holiday || ''}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 255})}</div>
                        </div>
                        {this.validator.message('holiday', this.state.holiday, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.holiday, 'required') && (
                          <FormHelperText id="holiday" error>
                            {t('validation.required', {field: t('facility.holiday')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* 営業時間①(Business hours ①) */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('facility.business_hours_1')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="space-between" flex={1}>
                        <Box>
                          <TextField
                            label={t('vehicles.from')}
                            className="field_size_20 field_min_size_150"
                            id="time"
                            type="time"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300,
                            }}
                            name="open_time"
                            value={this.state.open_time || ''}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                          {this.validator.message('open_time', this.state.open_time, 'required')}
                        </Box>
                        <Box>
                          <TextField
                            label={t('vehicles.to')}
                            className="field_size_20 field_min_size_150"
                            id="time"
                            type="time"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300,
                            }}
                            name="close_time"
                            value={this.state.close_time || ''}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                          {this.validator.message('close_time', this.state.close_time, 'required')}
                        </Box>
                      </Box>
                      <Grid container spacing={2}>
                        <Grid container item alignItems="center">
                          {this.state.isSubmitForm &&
                            ((!this.validator.check(this.state.open_time, 'required') && (
                              <FormHelperText id="time" style={{color: 'red'}}>
                                {t('validation.required', {field: t('vehicles.from')})}
                              </FormHelperText>
                            )) ||
                              (!this.validator.check(this.state.close_time, 'required') && (
                                <FormHelperText id="time" style={{color: 'red'}}>
                                  {t('validation.required', {field: t('vehicles.to')})}
                                </FormHelperText>
                              )) ||
                              (this.checkTimeRange() && (
                                <FormHelperText id="time" style={{color: 'red'}}>
                                  {t('validation.invalid.timeRange')}
                                </FormHelperText>
                              )))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* 営業時間②(Business hours ②) */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('facility.business_hours_2')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="space-between" flex={1}>
                        <Box>
                          <TextField
                            label={t('vehicles.from')}
                            className="field_size_20 field_min_size_150"
                            id="time"
                            type="time"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300,
                            }}
                            name="open_time_optional"
                            value={this.state.open_time_optional || ''}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                        </Box>
                        <Box>
                          <TextField
                            label={t('vehicles.to')}
                            className="field_size_20 field_min_size_150"
                            id="time"
                            type="time"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 300,
                            }}
                            name="close_time_optional"
                            value={this.state.close_time_optional || ''}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                        </Box>
                      </Box>
                      <Grid container spacing={2}>
                        <Grid container item alignItems="center">
                          {this.state.isSubmitForm && this.checkTimeRangeOption() && (
                            <FormHelperText id="time" style={{color: 'red'}}>
                              {t('validation.invalid.timeRange')}
                            </FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Select Driver name (On Driver App) */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('facility.select_driver_name')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <Switch
                        checked={this.state.enable_select_driver_name}
                        name="enable_select_driver_name"
                        inputProps={{'aria-label': 'secondary checkbox'}}
                        onChange={(event) => this.onChangeDriverName(event.target.checked)}
                      />
                    </Grid>
                  </Grid>

                  {/* Countdown Time */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('facility.countDownTime')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl>
                        <div className="box-group-input">
                          <TextField
                            name="countdown_time"
                            className="field_size_10 field_min_size_225"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('facility.countDownTime2')})}
                            variant="outlined"
                            type="number"
                            inputProps={{min: 60, max: 3600}}
                            onKeyDown={preventInvalidChars}
                            value={this.state.countdown_time || ''}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                        </div>
                        {this.validator.message('countDownTime', +this.state.countdown_time, 'required|numeric|min:60,num|max:3600,num')}
                        {this.state.isSubmitForm && !this.validator.check(+this.state.countdown_time, 'required') && (
                          <FormHelperText id="countdown_time" error>
                            {t('validation.required', {field: t('facility.countDownTime2')})}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm && this.validator.check(+this.state.countdown_time, 'required') &&
                          !this.validator.check(+this.state.countdown_time, 'numeric|min:60,num|max:3600,num') && (
                          <FormHelperText id="countdown_time" error>
                            {t('validation.range', {field: t('facility.countDownTime2'), min: 60, max: 3600})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Fee */}
                  <Grid container spacing={1} className="row_form_item" style={{display: 'none'}}>
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('facility.fee')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <Grid container alignItems="center" item>
                        <Grid container alignItems="center" item xs={4}>
                          <RadioGroup aria-label="gender" name="gender1" value={this.state.fee_type}>
                            <FormControlLabel
                              value="RATE"
                              control={
                                <Radio
                                  onChange={() => {
                                    this.setState({
                                      fee_type: 'RATE',
                                    });
                                  }}
                                />
                              }
                              label={t('facility.rate')}
                            />
                            <FormControlLabel
                              value="OTHER"
                              control={
                                <Radio
                                  onChange={() => {
                                    this.setState({
                                      fee_type: 'OTHER',
                                    });
                                  }}
                                />
                              }
                              label={t('facility.other')}
                            />
                          </RadioGroup>
                        </Grid>
                        <Grid container alignItems="center" item xs={6}>
                          <TextField
                            className="field_size_10 field_min_size_100"
                            margin="dense"
                            variant="outlined"
                            value={this.state.fee || ''}
                            onChange={(event) => {
                              this.setState({
                                fee: event.target.value,
                              });
                            }}
                            InputProps={{
                              type: 'number',
                              min: 0,
                              max: 100,
                            }}
                          />
                        </Grid>
                        <Grid container alignItems="center" justify="flex-start" item xs={2}>
                          %
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Photo */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <Button disabled={this.state.checkDetail && !permission.canUpdate} variant="contained" color="primary"
                        component="span" onClick={() => this.setState({isOpenModal: true})}>
                        {t('common.photo')}
                      </Button>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      {this.state.list_image_id?.length > 0 ? (
                        <Carousel autoPlay={false} animation="slide" indicators={true} timeout="500">
                          {this.state.list_image_id?.map((item, index) => (
                            <div className="box_image" style={{width: '100%', height: '200px'}}>
                              <img className="cropped_image" style={{width: 'inherit', height: 'inherit'}} src={item.url} alt="cropped" />
                            </div>
                          ))}
                        </Carousel>
                      ) : (
                        <label htmlFor="icon-button-file">
                          <IconButton color="primary" aria-label="upload picture" component="span">
                            <PhotoCamera />
                          </IconButton>
                        </label>
                      )}
                    </Grid>
                  </Grid>
                  {this.state.checkDetail ? (
                    <Grid container alignItems="center" item xs={12} lg={9}>
                      <Memo
                        disabled={!permission.canUpdate}
                        memos={this.state.memos}
                        onChangeContent={(value) => onChangeListData(this, this.state.memos, value.field, value.index, value.newValue)}
                        note={this.state.note}
                        onChangeNote={(value) => this.setState({note: value})}
                        parentType="medium"
                        countryCode={this.state.country?.country_code}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Paper>
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {!this.state.checkDetail ? (
                      <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={() => this.handleCreate()}>
                        {t('common.btnRegister')}
                      </Button>
                    ) : permission.canUpdate && (
                      <Button color="primary" variant="contained" className="button_margin" onClick={this.handleUpdate} endIcon={<CloudUploadIcon />}>
                        {t('common.btnUpdate')}
                      </Button>
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      className="button_magin button_color"
                      style={{marginLeft: 5}}
                      onClick={this.props.history.goBack}
                    >
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                </Grid>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={this.state.isOpenModal}
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1000',
                  }}
                >
                  <>
                    <ImageUpload
                      onClickOk={this.handleButtonCloseModal}
                      onClickClose={this.handleButtonCloseModal}
                      multipleImage={(value) => this.setState({list_image_id: value.data})}
                      parent_selected={this.state.list_image_id}
                      multipleSelect
                    ></ImageUpload>
                  </>
                </Modal>
                <Dialog
                  open={this.state.flgUpdate}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    okButtonText={t('common.btnOk')}
                    onClickOk={this.handleButtonUpdateOk}
                    onClickCancel={this.handleButtonUpdateCancel}
                    message={this.state.message}
                    isDisableCreate={this.state.isDisableButtonCreate}
                  ></SelectModal>
                </Dialog>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>
          <br></br>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    facility: state.facility,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListSupplier: () => dispatch(getListSupplier()),
    getDetailFacility: (params, props) => dispatch(getDetailFacility(params, props)),
    createFacility: (params, props) => dispatch(createFacility(params, props)),
    updateFacility: (params, props) => dispatch(updateFacility(params, props)),
    getListGeofence: () => dispatch(getListGeofence()),
  };
};

export default withPermissionGateway(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(
    // eslint-disable-next-line new-cap
        GoogleApiWrapper({
          apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || 'AIzaSyACyapw83diO1bi_xiXbZRLLoano6eTwd0',
          language: localStorage.getItem('i18nextLng') === 'ja' ? 'ja' : 'en',
        })(withTranslation('translations')(SaleOfficeForm)),
    ),
);
