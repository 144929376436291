import React, {Component} from 'react';

import {Button, Card, CardContent, Container, Grid, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {ArrowBack as ArrowBackIcon} from '@material-ui/icons';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';

import {getPaymentDetailApi} from '../../../../services/paymentService';
import {convertDatetimeUTC, displayDateTime} from '../../../../utils/datetime';

import './style.css';
import withPermissionData from '../../../../withPermissionData/hoc';

const parseKey = (key) => {
  if (typeof key === 'string') {
    const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
    return capitalizedKey.replaceAll('_', ' ');
  }
  return key;
};

const parseValue = (key_value) => {
  const [key, value] = key_value;
  // Null or empty string
  if (value === null || value === '') return '---';
  // Object
  if (typeof value === 'object') return (<pre>{JSON.stringify(value, null, 4)}</pre>);
  // Time
  if (key.includes?.('time') || key.includes?.('date')) {
    return formatDate(value);
  }

  return value;
};

const formatDate = (time) => {
  if (typeof time === 'string') {
    if (time.length === 12) {
      return '20' + time.slice(4, 6) + '-' + time.slice(2, 4) + '-' + time.slice(0, 2) + ' ' +
        time.slice(6, 8) + ':' + time.slice(8, 10) + ':' + time.slice(10, 12);
    }
    if (time.length === 14) {
      return time.slice(0, 4) + '-' + time.slice(4, 6) + '-' + time.slice(6, 8) + ' ' +
        time.slice(8, 10) + ':' + time.slice(10, 12) + ':' + time.slice(12, 14);
    }
  }
};

/**
 * Index
 */
class Index extends Component {
  /**
   * constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      payment: [],
      isLoading: true,
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount = () => {
    const {id} = this.props.match.params;
    getPaymentDetailApi(id).then(
      (response) => {
        if (response.status === 200) {
          this.setState({
            payment: response.result,
            isLoading: false,
          });
        }
      },
    );
  }

  /**
   * Render Component
   * @return {Component}
   */
  render() {
    const {t} = this.props;
    const {isLoading, payment} = this.state;
    const {
      id,
      order_id,
      user_id,
      payment_at,
      payment_code,
      amount,
      currency,
      status,
      payment_method,
      vendor_request,
      vendor_response,
    } = payment;

    return (
      <LoadingOverlay active={isLoading} bgColor='#f1f1f1' spinnerColor='#9ee5f8' textColor='#676767' spinner>
        <div className="payment">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container item xs={6} alignItems="center">
                <h3>Payment Detail</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.props.history.goBack}
                  className="button_margin button_color"
                  endIcon={<ArrowBackIcon />}
                >
                  {t('common.btnReturn')}
                </Button>
              </Grid>
            </Grid>
          </Container>
          <br />
          <Container maxWidth="xl">
            <Card>
              <Container maxWidth="xl">
                <CardContent>
                  <Table size="medium">
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2}>Payment Detail</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>{id}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Order ID</TableCell>
                        <TableCell>{order_id}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>User ID</TableCell>
                        <TableCell>{user_id}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Payment at</TableCell>
                        <TableCell>{displayDateTime(convertDatetimeUTC(payment_at))}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Payment code</TableCell>
                        <TableCell>{payment_code}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Payment method</TableCell>
                        <TableCell>{payment_method}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Amount</TableCell>
                        <TableCell>{amount}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Currency</TableCell>
                        <TableCell>{currency}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell>{status}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Table size="medium">
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2}>Vendor Request</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {vendor_request && Object.entries(vendor_request).map((key_value, index) => (
                        <TableRow key={index}>
                          <TableCell>{parseKey(key_value[0])}</TableCell>
                          <TableCell>{parseValue(key_value)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Table size="medium">
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2}>Vendor Response</TableCell>
                      </TableRow>
                    </TableHead>
                      <TableBody>
                        {vendor_response && Object.entries(vendor_response).map((key_value, index) => (
                          <TableRow key={index}>
                            <TableCell>{parseKey(key_value[0])}</TableCell>
                            <TableCell>{parseValue(key_value)}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                  </Table>
                </CardContent>
              </Container>
            </Card>
          </Container>
          <br />
        </div>
      </LoadingOverlay>
    );
  }
}

export default withPermissionData(withTranslation('translations')(Index));
