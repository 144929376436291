import React, {useEffect, useState} from 'react';

import {GoogleApiWrapper, Map} from 'google-maps-react';
import PropTypes from 'prop-types';

const JAPAN = {lat: 36.2048, lng: 138.2529};

/**
 * @param {*} props
 * @return {*}
 */
function DisplayMap({border_color, color, geometry, opacity, stroke_opacity, height, google, onError, centroid, polylines}) {
  const [mapRef, setMapRef] = useState(null);

  useEffect(() => {
    const clearData = () => {
      if (!mapRef) return;
      mapRef.map.data.forEach((feature) => {
        mapRef.map.data.remove(feature);
      });
    };
    const loadGeofence = (geofence) => {
      if (!mapRef) return;
      clearData();
      // add new geojson (polygon and multi polygon)
      if (geofence.type === 'Polygon') {
        const jsonData = {
          type: 'Feature',
          geometry: {
            type: geofence.type,
            coordinates: geofence.coordinates,
          },
        };
        mapRef.map.data.addGeoJson(jsonData);
      } else if (geofence.type === 'MultiPolygon') {
        geofence.coordinates.forEach((value, i) => {
          const jsonData = {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: value,
            },
          };
          mapRef.map.data.addGeoJson(jsonData);
        });
      } else if (geofence.type === 'GeometryCollection') {
        geofence.geometries.forEach(loadGeofence);
      } else {
        throw new Error('Invalid type');
      }

      // focus to center geofence
      const bounds = new google.maps.LatLngBounds();
      mapRef.map.data.forEach((feature) => {
        feature.getGeometry().forEachLatLng(function(latlng) {
          bounds.extend(latlng);
        });
      });
      geofence.type && mapRef.map.fitBounds(bounds);
    };
    clearData();
    try {
      loadGeofence(geometry);
    } catch (error) {
      if (typeof onError === 'function' && !!geometry) {
        onError(error);
      }
    }
  }, [geometry, google, mapRef, onError]);

  useEffect(() => {
    if (!google.maps.geometry) {
      return;
    }
    let currentInfoWindow;
    // eslint-disable-next-line no-unused-expressions
    polylines?.forEach((p) => {
      const decodedPath = google.maps.geometry.encoding.decodePath(p.polyline);
      const polyline = new google.maps.Polyline({
        path: decodedPath,
        geodesic: true,
        strokeColor: p.stroke_color.replace('#ff', '#'),
        strokeOpacity: 1.0,
        strokeWeight: p.width * 1.5,
      });
      polyline.setMap(mapRef.map);

      const infoWindow = new google.maps.InfoWindow({
        content: p.name,
      });

      google.maps.event.addListener(polyline, 'click', function(event) {
        infoWindow.setPosition(event.latLng);
        infoWindow.open(mapRef.map);
        if (currentInfoWindow) {
          currentInfoWindow.close();
        }
        currentInfoWindow = infoWindow;
      });
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polylines, google.maps.geometry]);

  useEffect(() => {
    const setBgColor = (border_color, color, opacity, stroke_opacity) => {
      if (!mapRef) return;
      mapRef.map.data.setStyle((feature) => {
        return {
          strokeColor: border_color,
          fillColor: color,
          fillOpacity: opacity / 100,
          strokeWeight: 1.5,
          strokeOpacity: stroke_opacity / 100,
        };
      });
    };
    setBgColor(border_color, color, opacity, stroke_opacity);
  }, [border_color, color, opacity, stroke_opacity, mapRef]);

  useEffect(() => {
    if (mapRef && centroid) {
      const lat = centroid.coordinates[1];
      const lng = centroid.coordinates[0];
      const infoWindow = new google.maps.InfoWindow();
      const centerMarker = new google.maps.Marker({
        position: {lat, lng},
        title: `Geofence's centroid: (${lat}, ${lng})`,
        map: mapRef.map,
        optimzied: false,
      });
      centerMarker.addListener('click', () => {
        infoWindow.close();
        infoWindow.setContent(centerMarker.getTitle());
        infoWindow.open(centerMarker.getMap(), centerMarker);
      });
    }
  });

  return (
    <Map
      google={google}
      ref={setMapRef}
      initialCenter={JAPAN}
      zoom={5}
      centerAroundCurrentLocation={false}
      containerStyle={{
        height: height || 500,
        position: 'relative',
        minWidth: '600px',
      }}
    ></Map>
  );
}

DisplayMap.propTypes = {
  color: PropTypes.string,
  geometry: PropTypes.object,
  height: PropTypes.number,
};

// eslint-disable-next-line new-cap
export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || 'AIzaSyACyapw83diO1bi_xiXbZRLLoano6eTwd0',
  language: localStorage.getItem('i18nextLng') === 'ja' ? 'ja' : 'en',
})(DisplayMap);
