import React, {Component} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  Card,
  Container,
  Grid,
  Paper,
  Table,
  TableCell,
  TableRow,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  TableHead,
  TableBody,
  Dialog,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DetailIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

import {
  ROUTE,
  ROWS_PER_PAGE_OPTIONS,
  DATA_NULL,
  HAS_FAMILY_OPTIONS,
  DATE_TIME_FORMAT,
  PAYMENT_METHOD_TRANSFER_SUB,
  PAYMENT_TRANSFER_STATUS,
  EXPORT_LIMIT_NUMBER_ROWS,
  AUTO_RENEW_OPTIONS,
  CANCEL_BY_OPTIONS,
  PERMISSION_ACTIONS,
  RESERVATION_MOBI_TYPE,
  LANGUAGE,
  PLAN_CATEGORIES,
  TICKET_AMOUNT_TYPE,
  DATE_FORMAT,
  MEMBER_GENDER,
  HAS_ONE_TIME_DISCOUNT,
  CURRENCY,
} from '../../../common/constant';
import CSVExporter from '../../../components/CSVExporter/NewCSVExporter';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {searchListSubScription, getCsvExportSubscription} from '../../../stores/business/action';
import {getAllFacilityList, getAllCountryCode, getAllSubscriptionGeofence, getServiceTypes, getCsvExport} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {
  changeUrlParams,
  getQueryStringFromObject,
  getUrlParams,
  onChangeSelect,
  onChangeTextField,
  customDisplayCurrency,
} from '../../../utils/common';
import {convertToDateWithTimeNow, formatUtc} from '../../../utils/date_time_utils';
import {convertDatetimeUTC, displayDate, displayDateTime} from '../../../utils/datetime';
import {modalObj} from '../../../utils/modal';
import {isRoleGlobal} from '../../../utils/role';

/**
 * User Subscription Search Component
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      flgDelete: false,
      listCheckbox: [],
      checkbox: [],
      listRowDelete: [],
      flag: false,
      message: '',
      result: [],
      isSearch: false,
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,
      flagExport: false,
      confirmedExport: false,
      latestSearchParams: null,
      listGeofenceAll: [],
      auto_renewable: '',
      canceled_by: '',
      serviceTypeIds: [],
      user_name: '',
      country_id: '',
      geofence_ids: [],
      phone_number: '',
      user_email: '',
      plan_code: '',
      plan_name: '',
      user_code: '',
      payment_method: '',
      has_family_options: '',
      has_one_time_discount: '',
      payment_id: '',
      subscription_id: '',
      plan_cost: '',
      changeStatus: {active: null, id: null},
      dataGeofence: null,
      start_date_from: null,
      start_date_to: null,
      create_date_from: null,
      create_date_to: null,
      end_date_from: null,
      end_date_to: null,
      next_update_date_from: null,
      next_update_date_to: null,
      transfer_deadline_from: null,
      transfer_deadline_to: null,
      payment_transfer_status: null,
      is_active: '',

      plan_category: '',
      ticket_price_type: '',
      ticket_amount: '',
      currency_code: '',
    };
    this.exportCSVRef = React.createRef();
    this.exportHeaders = [];
    this.validator = new SimpleReactValidator();
  }

  /**
   * reset
   */
  reset = () => {
    this.setState({
      user_name: '',
      auto_renewable: '',
      canceled_by: '',
      country_id: isRoleGlobal() ? '' : this.state.country_id,
      geofence_ids: [],
      phone_number: '',
      user_email: '',
      plan_code: '',
      plan_name: '',
      user_code: '',
      payment_method: '',
      has_family_options: '',
      has_one_time_discount: '',
      payment_id: '',
      plan_cost: '',
      subscription_id: '',
      is_active: '',
      serviceTypeIds: [],
      start_date_from: null,
      start_date_to: null,
      create_date_from: null,
      create_date_to: null,
      end_date_from: null,
      end_date_to: null,
      next_update_date_from: null,
      next_update_date_to: null,
      transfer_deadline_from: null,
      transfer_deadline_to: null,
      payment_transfer_status: null,
      listGeofenceAll: this.props.common?.all_subscription_geofence,

      plan_category: '',
      ticket_price_type: '',
      ticket_amount: '',
      currency_code: '',
    });
  };

  /**
   * changeFacility
   * @param {event} value
   */
  changeFacilityName = (value) => {
    this.setState({facilityName: value});
  };

  /**
   * changeBusiness
   * @param {event} value
   */
  changeBusiness = (value) => {
    this.setState({supplierName: value});
  };

  /**
   * handleSearch
   * @param {bool} reset
   */
  handleSearch = (reset) => {
    const {
      user_name,
      country_id,
      geofence_ids,
      phone_number,
      user_email,
      plan_code,
      plan_name,
      user_code,
      payment_method,
      has_family_options,
      has_one_time_discount,
      start_date_from,
      start_date_to,
      create_date_from,
      create_date_to,
      end_date_from,
      end_date_to,
      next_update_date_from,
      next_update_date_to,
      payment_id,
      plan_cost,
      subscription_id,
      transfer_deadline_from,
      transfer_deadline_to,
      payment_transfer_status,
      auto_renewable,
      canceled_by,
      is_active,
      serviceTypeIds,
      plan_category,
      ticket_price_type,
      ticket_amount,
    } = this.state;
    const payLoad = {
      user_name: user_name.trim(),
      country_id,
      geofence_ids,
      zone_id: Intl.DateTimeFormat().resolvedOptions().timeZone,
      phone_number: phone_number.trim(),
      user_email: user_email.trim(),
      plan_code: plan_code.trim(),
      plan_name: plan_name.trim(),
      user_code: user_code.trim(),
      payment_method: payment_method || null,
      start_date_from: start_date_from ? convertToDateWithTimeNow(start_date_from) : null,
      start_date_to: start_date_to ? convertToDateWithTimeNow(start_date_to) : null,
      create_date_from: create_date_from ? convertToDateWithTimeNow(create_date_from) : null,
      create_date_to: create_date_to ? convertToDateWithTimeNow(create_date_to) : null,
      end_date_from: end_date_from ? convertToDateWithTimeNow(end_date_from) : null,
      end_date_to: end_date_to ? convertToDateWithTimeNow(end_date_to) : null,
      payment_id,
      subscription_id: subscription_id.trim(),
      transfer_deadline_from: transfer_deadline_from ? convertToDateWithTimeNow(transfer_deadline_from) : null,
      transfer_deadline_to: transfer_deadline_to ? convertToDateWithTimeNow(transfer_deadline_to) : null,
      payment_transfer_status,
      is_active: is_active !== '' ? is_active : null,
      service_type_ids: serviceTypeIds,
      plan_type: plan_category || null,

      auto_renewable: auto_renewable || null,
      canceled_by: canceled_by || null,
      has_family_options: has_family_options || null,
      has_one_time_discount: has_one_time_discount || null,
      next_update_date_from: next_update_date_from ? convertToDateWithTimeNow(next_update_date_from) : null,
      next_update_date_to: next_update_date_to ? convertToDateWithTimeNow(next_update_date_to) : null,
      plan_cost,

      ticket_type: ticket_price_type || null,
      ticket_amount: ticket_amount || null,
    };

    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const {currentPage, rowsPerPage} = this.state;
    const queryParams = reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage};
    this.props.searchListSubscription(payLoad, queryParams, this.props.history).then((response) => {
      if (!response) return;
      this.setState(
        {
          result: response?.content || [],
          isSearch: true,
          totalRows: (response.totalSize !== 0 ? response.totalSize : 0),
          latestSearchParams: payLoad,
        },
        () => {
          const {currentPage, rowsPerPage} = this.state;
          // Apply changed params into url
          const queryParamsToChange = {
            ...payLoad,
            currentPage: String(currentPage),
            rowsPerPage,
          };
          changeUrlParams(queryParamsToChange);
          const newSearchParams = getQueryStringFromObject(queryParamsToChange);
          // Save search params into state in order to pass to next page
          this.setState({searchParams: newSearchParams});
        },
      );
    });
  };

  /**
   * resetSearchResult
   */
  resetSearchResult = () => {
    this.setState({
      result: [],
      isSearch: false,
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      latestSearchParams: null,

      // Reset different field
      plan_code: '',
      plan_name: '',
      subscription_id: '',
      serviceTypeIds: [],
    });
    changeUrlParams({});
  };

  /**
   * changePlanType
   * @param {*} event
   * @param {*} id
   */
  changePlanType = (event, id) => {
    let planTypeIds = this.state.serviceTypeIds;
    if (event.target.checked) {
      planTypeIds.push(id);
    } else {
      planTypeIds = planTypeIds.filter((item) => item !== id);
    }
    this.setState({
      serviceTypeIds: planTypeIds,
    });
  };

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearch);
  };

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllCountryCode();
    await this.props.getServiceTypes();
    await this.props.getAllSubscriptionGeofence({targetScreen: 'ENABLE_FOR_ADMIN_HISTORY'})
      .then(() => this.setState({listGeofenceAll: this.props.common?.all_subscription_geofence}));
    if (!isRoleGlobal()) {
      this.setState({
        country_id: this.props.common.principal?.country_id,
        currency_code: CURRENCY.find((c) => c.countryId === this.state.country_id)?.id || '',
      });
    };

    if (this.props.location.search) {
      const urlParams = getUrlParams(this.props.location.search);
      urlParams.plan_category = urlParams.plan_type;
      delete urlParams.plan_type;
      this.setState(urlParams, () => {
        this.state.country_id && isRoleGlobal() &&
          this.setState({
            currency_code: CURRENCY.find((c) => c.countryId === this.state.country_id)?.id || '',
            listGeofenceAll: this.props.common?.all_subscription_geofence?.filter((e) => e.country_id === Number(this.state.country_id)),
          });
        this.handleSearch();
      });
    }
  }

  /**
   * handleOnChangeCountry
   * @param {*} value
   */
  handleOnChangeCountry = (value) => {
    this.setState({
      country_id: value,
      geofence_ids: [],
      listGeofenceAll: this.props.common?.all_subscription_geofence?.filter((e) => e.country_id === value),
      currency_code: CURRENCY.find((c) => c.countryCode === value)?.id || '',
      ticket_amount: '',
      plan_cost: '',
    });
  };

  /**
   * Fetch data to export
   * @param {String} uuid
   */
  fetchDataExport = async (uuid) => {
    this.initiateCSVHeaders();
    const {common, t} = this.props;
    const response = await this.props.getCsvExport(uuid);
    const messageCode = response?.message_code;
    if (messageCode === 'error.csv.export' || messageCode === 'resource.notfound') {
      return {messageCode};
    };
    const dataExport = response?.result;
    const convertedData = dataExport.map((item) => {
      const country = common?.country_code?.find((country_code) => country_code.id === item.country_id);
      const country_code = country?.country_code;
      const zone_id = country?.zone_id;
      const gender = item.gender ? MEMBER_GENDER.find((sex) => sex.name === item.gender) : null;

      item.country_name = country_code ? t(country_code) : (item.country_name || null);
      item.name_jp = item.subscription_languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.name;
      item.name_vi = item.subscription_languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.name;
      item.name_en = item.subscription_languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.name;
      item.created_date = displayDateTime(convertDatetimeUTC(item.created_date, country_code));
      item.trial_time_start = displayDate(formatUtc(item.trial_time_start, DATE_FORMAT, zone_id), true);
      item.trial_time_end = displayDate(formatUtc(item.trial_time_end, DATE_FORMAT, zone_id), true);
      item.effective_start_time = displayDate(formatUtc(item.effective_start_time, DATE_FORMAT, zone_id), true);
      item.effective_end_time = displayDate(formatUtc(item.effective_end_time, DATE_FORMAT, zone_id), true);
      item.payment_time = displayDateTime(convertDatetimeUTC(item.payment_time, country_code));
      item.canceled_time = displayDateTime(convertDatetimeUTC(item.canceled_time, country_code));
      item.update_off_date = displayDateTime(convertDatetimeUTC(item.update_off_date, country_code));
      item.birthday = displayDate(formatUtc(item.birthday, DATE_FORMAT, zone_id), true);
      item.auto_renewable = item.auto_renewable ? t('common.enable') : t('common.disable');
      item.is_active = item.is_active ? t('common.enable') : t('common.disable');
      for (let i = 0; i < item.child_users?.length; i++) {
        item[`family_member_${i+1}`] = item.child_users[i].member_code;
      }
      if (!item.geofence_name && item.geofence_ids && item.geofence_ids.length > 0) {
        item.geofence_name = common?.all_geofence.find((geofence) => geofence.geofence_id === item.geofence_ids[0])?.name;
      }
      item.payment_method = item.payment_method ? t(`${PAYMENT_METHOD_TRANSFER_SUB.find((row) => row.id === item.payment_method)?.i18n}`) : null;
      item.gender = gender ? t(gender.i18n) : null;
      return item;
    });
    this.setState({confirmedExport: false});
    return {convertedData, messageCode};
  };

  /**
   * Initiate csv headers
   */
  initiateCSVHeaders = () => {
    const {t} = this.props;
    this.exportHeaders = [
      {label: t('business_plan.user_code'), key: 'user_code'},
      {label: t('common.country'), key: 'country_name'},
      {label: t('common.geofence'), key: 'geofence_name'},
      {label: t('reservationManagement.subscription_id'), key: 'subscription_code'},
      {label: t('business.planCode'), key: 'plan_code'},
      {label: t('reservationManagement.plan_type'), key: 'plan_type'},
      {label: t('reservationManagement.plan_status'), key: 'is_active'},
      {label: t('reservationManagement.enrollment_date'), key: 'created_date'},
      {label: t('reservationManagement.free_period_start_date'), key: 'trial_time_start'},
      {label: t('reservationManagement.free_period_end_date'), key: 'trial_time_end'},
      {label: t('businessVehicle.start_date'), key: 'effective_start_time'},
      {label: t('businessVehicle.end_date'), key: 'effective_end_time'},
      {label: t('reservationManagement.payment_date_end_time'), key: 'payment_time'},
      {label: t('common.cancellationDateTime'), key: 'canceled_time'},
      {label: t('reservationManagement.update_off_date'), key: 'update_off_date'},
      {label: t('reservationManagement.automatic_updating'), key: 'auto_renewable'},
      {label: `${t('business_plan.package_name')} (JP)`, key: 'name_jp'},
      {label: `${t('business_plan.package_name')} (VN)`, key: 'name_vi'},
      {label: `${t('business_plan.package_name')} (EN)`, key: 'name_en'},
      {label: t('common.paymentMethod'), key: 'payment_method'},
      {label: t('reservationManagement.payment_status'), key: 'payment_status'},
      {label: t('reservationManagement.payment_code'), key: 'payment_code'},
      {label: t('oneTimePrice.currency'), key: 'currency_code'},
      {label: t('sub.plan_fee'), key: 'plan_cost'},
      {label: t('sub.price_family_option'), key: 'extra_fee'},
      {label: t('sub.total_price'), key: 'total_amount'},
      {label: t('business_plan.registered_user'), key: 'number_of_max_user'},
      {label: t('business_plan.user_added'), key: 'number_of_current_user'},
      {label: t('reservationManagement.family_member_1'), key: 'family_member_1'},
      {label: t('reservationManagement.family_member_2'), key: 'family_member_2'},
      {label: t('reservationManagement.family_member_3'), key: 'family_member_3'},
      {label: t('reservationManagement.family_member_4'), key: 'family_member_4'},
      {label: t('reservationManagement.family_member_5'), key: 'family_member_5'},
      {label: t('reservationManagement.family_member_6'), key: 'family_member_6'},
      {label: t('reservationManagement.for_coupon_tickets'), key: 'ticket_type'},
      {label: t('reservationManagement.number_of_tickets'), key: 'amount_tickets'},
      {label: t('reservationManagement.remaining_number_of_coupon_tickets'), key: 'remain_tickets'},
      {label: t('reservationManagement.lastName_csv'), key: 'last_name'},
      {label: t('reservationManagement.firstName_csv'), key: 'first_name'},
      {label: t('reservationManagement.email_address'), key: 'email'},
      {label: t('common.phoneNumber'), key: 'mobile'},
      {label: t('reservationManagement.sex'), key: 'gender'},
      {label: t('memberManagement.birthDay'), key: 'birthday'},
      {label: t('reservationManagement.age'), key: 'age'},
      {label: t('common.address'), key: 'address'},
      {label: t('reservationManagement.created_by'), key: 'created_by'},
      {label: t('reservationManagement.canceled_by'), key: 'canceled_by'},
    ];
  };

  /**
   * alertExportCSV
   */
  alertExportCSV = () => {
    const {t} = this.props;
    if (this.state.totalRows > EXPORT_LIMIT_NUMBER_ROWS) {
      this.props.setMessageModal(modalObj(true, t('messageCode.exportAlertLimit')));
    } else {
      const message = t('messageCode.exportAlert');
      this.setState({flagExport: true, message});
    }
  };

  /**
   * handleButtonExportOk
   */
  handleButtonExportOk = () => {
    this.setState({flagExport: false, confirmedExport: true}, () => {
      this.exportCSVRef.current.onClickExport();
    });
  };

  /**
   * convertPlanType
   * @param {*} list
   * @return {*}
   */
  convertPlanType = (list) => {
    const {t} = this.props;
    return list?.map((item, index) => {
      return item ? (
        <div key={index}>{item === RESERVATION_MOBI_TYPE[0].id ? t(RESERVATION_MOBI_TYPE[0].i18n) : t(RESERVATION_MOBI_TYPE[1].i18n)}</div>
      ) : (
        <strike key={index}>{item === RESERVATION_MOBI_TYPE[0].id ? t(RESERVATION_MOBI_TYPE[0].i18n) : t(RESERVATION_MOBI_TYPE[1].i18n)}</strike>
      );
    });
  };

  /**
   * handleButtonExportCancel
   */
  handleButtonExportCancel = () => {
    this.setState({flagExport: false});
  };

  /**
   * handleButtonModalCancel
   */
  handleButtonModalCancel = () => {
    this.setState({flag: false});
  };

  /**
   * handleButtonModalOk
   */
  handleButtonModalOk = async () => {
    this.setState({flag: false});
  };

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {t, common, business, i18n, actions} = this.props;
    const {serviceTypeIds} = this.state;
    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canCSVOutput: actions.includes(PERMISSION_ACTIONS.CSV_OUTPUT),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.business_subscription')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          <LoadingOverlay active={business.isLoading || common.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                {permission.canSearch && (
                  <>
                    <br></br>
                    <Paper className="search_table">
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                          {t('facility.searchTitle')}
                        </Grid>
                      </Grid>

                      {/* Country */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.country')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_250">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'country_id',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.country_id ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('common.country'),
                                        })}
                                      </div>
                                    )
                              }
                              disabled={!isRoleGlobal()}
                              value={this.state.country_id}
                              onChange={(event) => this.handleOnChangeCountry(event.target.value)}
                            >
                              {common?.country_code?.map((item, idx) => (
                                <MenuItem value={item.id} key={idx}>
                                  {t(`${item.country_code}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Geofence */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('business_plan.geofence')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_250">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'geofence_ids',
                              }}
                              multiple
                              displayEmpty
                              renderValue={
                                this.state.geofence_ids.length !== 0 ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('business_plan.geofence'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.geofence_ids}
                              onChange={(e) => onChangeSelect(this, e)}
                            >
                              {this.state.listGeofenceAll?.map((item, idx) => {
                                return (
                                  <MenuItem value={item.geofence_id} key={idx} className={!item.enable ? 'disable-option-custom' : ''}>
                                    {t(`${item.name}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Plan Category */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('business_plan.category')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={6}>
                          <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_250">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'plan_category',
                              }}
                              displayEmpty
                              value={this.state.plan_category || ''}
                              onChange={(event) => {
                                onChangeSelect(this, event);
                                this.resetSearchResult();
                              }}
                              renderValue={
                                this.state.plan_category ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('business_plan.category'),
                                        })}
                                      </div>
                                    )
                              }
                            >
                              {PLAN_CATEGORIES.map((item, index) => (
                                <MenuItem value={item.value} key={index}>
                                  {t(`${item.i18n}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* User Code - user name */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        {/* User Code */}
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('memberManagement.memberId')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" className="field_size_20 field_min_size_250">
                            <TextField
                              name="user_code"
                              className="field_size_20 field_min_size_250"
                              margin="dense"
                              placeholder={t('placeholder.required', {field: t('memberManagement.memberId')})}
                              inputProps={{maxLength: 256}}
                              value={this.state.user_code}
                              onChange={(event) => onChangeTextField(this, event)}
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                        {/* Name User */}
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('business_plan.username')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" className="field_size_20 field_min_size_250">
                            <TextField
                              name="user_name"
                              className="field_size_20 field_min_size_250"
                              margin="dense"
                              placeholder={i18n.language === 'ja' ? t('reservationManagement.name_info') : t('placeholder.required', {field: t('business_plan.username')})}
                              inputProps={{maxLength: 256}}
                              value={this.state.user_name}
                              onChange={(event) => onChangeTextField(this, event)}
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Phone number - email */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        {/* Phone number */}
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.phoneNumber')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" className="field_size_20 field_min_size_250">
                            <TextField
                              name="phone_number"
                              className="field_size_20 field_min_size_250"
                              margin="dense"
                              placeholder={t('placeholder.required', {field: t('common.phoneNumber')})}
                              inputProps={{maxLength: 256}}
                              value={this.state.phone_number}
                              onChange={(event) => onChangeTextField(this, event)}
                              variant="outlined"
                            />
                            {this.validator.message('phone_number', this.state.phone_number, 'numeric')}
                            {this.state.phone_number && !this.validator.check(this.state.phone_number.trim(), 'numeric') && (
                              <FormHelperText id="phone_number" error>
                                {t('validation.number')}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        {/* Mail */}
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('business_plan.mail')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" className="field_size_20 field_min_size_250">
                            <TextField
                              name="user_email"
                              className="field_size_20 field_min_size_250"
                              margin="dense"
                              placeholder={t('placeholder.required', {field: t('business_plan.mail')})}
                              inputProps={{maxLength: 256}}
                              value={this.state.user_email}
                              onChange={(event) => onChangeTextField(this, event)}
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Plan Code - Plan name */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        {/* Plan Code */}
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('business_plan.code')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl>
                            <TextField
                              name="plan_code"
                              className="field_size_20 field_min_size_250"
                              margin="dense"
                              placeholder={t('placeholder.required', {field: t('business_plan.code')})}
                              inputProps={{maxLength: 10}}
                              value={this.state.plan_code}
                              onChange={(event) => onChangeTextField(this, event)}
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                        {/* Plan Name */}
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('business_plan.package_name')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" className="field_size_20 field_min_size_250">
                            <TextField
                              name="plan_name"
                              className="field_size_20 field_min_size_250"
                              margin="dense"
                              placeholder={t('placeholder.required', {field: t('business_plan.package_name')})}
                              inputProps={{maxLength: 256}}
                              value={this.state.plan_name}
                              onChange={(event) => onChangeTextField(this, event)}
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Plan Type */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.planType')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl error className="item_display_box">
                            {common?.planTypes?.map(
                              (planType, index) =>
                                (planType.service_type === RESERVATION_MOBI_TYPE[0].id || planType.service_type === RESERVATION_MOBI_TYPE[1].id) && (
                                  <FormControlLabel
                                    labelPlacement="end"
                                    key={index}
                                    control={
                                      <Checkbox
                                        checked={serviceTypeIds?.indexOf(planType.id) > -1}
                                        onChange={(event) => this.changePlanType(event, planType.id)}
                                        className="checkbox_radio"
                                      />
                                    }
                                    label={planType.service_type === RESERVATION_MOBI_TYPE[0].id ? t(RESERVATION_MOBI_TYPE[0].i18n) : t(RESERVATION_MOBI_TYPE[1].i18n)}
                                  />
                                ),
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Amount type */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('ticket.amount_type')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={10}>
                          <FormControl className="field_min_size_250">
                            <Select
                              name="ticket_price_type"
                              renderValue={
                                this.state.ticket_price_type ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('ticket.amount_type'),
                                        })}
                                      </div>
                                    )
                              }
                              variant="outlined"
                              margin="dense"
                              fullWidth
                              displayEmpty
                              value={this.state.ticket_price_type}
                              onChange={(event) => onChangeSelect(this, event)}
                            >
                              {TICKET_AMOUNT_TYPE.map((item, index) => (
                                <MenuItem value={item.value} key={index}>
                                  {t(item.i18n)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      {/* Ticket amount */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('business_plan.price')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" className="field_size_20 field_min_size_250">
                            <TextField
                              name="ticket_amount"
                              className="field_size_20 field_min_size_250"
                              margin="dense"
                              type='number'
                              inputProps={{
                                min: 0,
                                maxLength: 256,
                              }}
                              placeholder={t('placeholder.required', {field: t('business_plan.price')})}
                              value={this.state.ticket_amount}
                              onChange={(event) => onChangeTextField(this, event)}
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Payment Method */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.paymentMethod')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_250">
                            <Select
                              variant="outlined"
                              className="field_size_20 field_min_size_250"
                              margin="dense"
                              inputProps={{
                                name: 'payment_method',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.payment_method ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('common.paymentMethod'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.payment_method}
                              onChange={(event) => onChangeSelect(this, event)}
                            >
                              {PAYMENT_METHOD_TRANSFER_SUB.map(
                                (item, idx) =>
                                  item.id !== 'CASH' && (
                                    <MenuItem value={item.id} key={idx}>
                                      {t(item.i18n)}
                                    </MenuItem>
                                  ),
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {this.state.payment_method === 'TRANSFER' && (
                        <>
                          {/* Payment Status Transfer */}
                          <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                            <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                              {t('businessPartner.payment_transfer_status')}
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={4}>
                              <FormControl>
                                <Select
                                  variant="outlined"
                                  className="field_size_20 field_min_size_250"
                                  margin="dense"
                                  inputProps={{
                                    name: 'payment_transfer_status',
                                  }}
                                  displayEmpty
                                  renderValue={
                                    this.state.payment_transfer_status ?
                                      undefined :
                                      () => (
                                          <div className="font-12 color-disabled">
                                            {t('placeholder.required_select', {
                                              field: t('businessPartner.payment_transfer_status'),
                                            })}
                                          </div>
                                        )
                                  }
                                  value={this.state.payment_transfer_status}
                                  onChange={(event) => onChangeSelect(this, event)}
                                >
                                  {PAYMENT_TRANSFER_STATUS.map((item, idx) => {
                                    return (
                                      <MenuItem value={item.id} key={idx}>
                                        {t(item.i18n)}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </>
                      )}

                      {/* Has Family Option */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('sub.has_family_options')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_250">
                            <Select
                              name="has_family_options"
                              renderValue={
                                this.state.has_family_options ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('sub.has_family_options'),
                                        })}
                                      </div>
                                    )
                              }
                              variant="outlined"
                              margin="dense"
                              style={{
                                minWidth: '510px',
                              }}
                              className="field_size_20"
                              displayEmpty
                              value={this.state.has_family_options}
                              onChange={(event) => onChangeSelect(this, event)}
                            >
                              {HAS_FAMILY_OPTIONS.map(({id, i18n}) => (
                                <MenuItem value={id} key={id}>
                                  {t(i18n)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Has One Time Discount */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('business_plan.one_time_discount')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_250">
                            <Select
                              name="has_one_time_discount"
                              renderValue={
                                this.state.has_one_time_discount ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('business_plan.one_time_discount'),
                                        })}
                                      </div>
                                    )
                              }
                              variant="outlined"
                              margin="dense"
                              style={{
                                minWidth: '510px',
                              }}
                              className="field_size_20"
                              displayEmpty
                              value={this.state.has_one_time_discount}
                              onChange={(event) => onChangeSelect(this, event)}
                            >
                              {HAS_ONE_TIME_DISCOUNT.map(({id, i18n}) => (
                                <MenuItem value={id} key={id}>
                                  {t(i18n)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Subscription application date */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('sub.effective_date')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={6}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className="field_size_20 field_min_size_250"
                              margin="dense"
                              autoOk
                              disableToolbar
                              variant="inline"
                              inputVariant="outlined"
                              minDate="2000-01-01"
                              placeholder={t('sub.effective_date_from')}
                              format="yyyy/MM/dd"
                              value={this.state.create_date_from}
                              invalidDateMessage={t('errorFields.invalidDateMessage')}
                              maxDateMessage={t('errorFields.maxDateMessage')}
                              minDateMessage={t('errorFields.minDateMessage')}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              onChange={(time) =>
                                this.setState({
                                  create_date_from: time,
                                })
                              }
                            />
                            <span style={{padding: '5px'}}></span>
                            <KeyboardDatePicker
                              className="field_size_20 field_min_size_250"
                              margin="dense"
                              autoOk
                              disableToolbar
                              variant="inline"
                              placeholder={t('sub.effective_date_to')}
                              inputVariant="outlined"
                              minDate="2000-01-01"
                              format="yyyy/MM/dd"
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              value={this.state.create_date_to}
                              onChange={(time) => this.setState({create_date_to: time})}
                              invalidDateMessage={t('errorFields.invalidDateMessage')}
                              maxDateMessage={t('errorFields.maxDateMessage')}
                              minDateMessage={t('errorFields.minDateMessage')}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Grid>

                      {/* Subscription start date */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('sub.start_date')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={6}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className="field_size_20 field_min_size_250"
                              margin="dense"
                              autoOk
                              disableToolbar
                              variant="inline"
                              inputVariant="outlined"
                              minDate="2000-01-01"
                              placeholder={t('sub.effective_date_from')}
                              format="yyyy/MM/dd"
                              value={this.state.start_date_from}
                              invalidDateMessage={t('errorFields.invalidDateMessage')}
                              maxDateMessage={t('errorFields.maxDateMessage')}
                              minDateMessage={t('errorFields.minDateMessage')}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              onChange={(time) =>
                                this.setState({
                                  start_date_from: time,
                                })
                              }
                            />
                            <span style={{padding: '5px'}}></span>
                            <KeyboardDatePicker
                              className="field_size_20 field_min_size_250"
                              margin="dense"
                              autoOk
                              disableToolbar
                              variant="inline"
                              placeholder={t('sub.effective_date_to')}
                              inputVariant="outlined"
                              minDate="2000-01-01"
                              format="yyyy/MM/dd"
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              value={this.state.start_date_to}
                              onChange={(time) => this.setState({start_date_to: time})}
                              invalidDateMessage={t('errorFields.invalidDateMessage')}
                              maxDateMessage={t('errorFields.maxDateMessage')}
                              minDateMessage={t('errorFields.minDateMessage')}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Grid>

                      {/* Subscription next update date */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('sub.next_update_date')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={6}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className="field_size_20 field_min_size_250"
                              margin="dense"
                              autoOk
                              disableToolbar
                              variant="inline"
                              inputVariant="outlined"
                              minDate="2000-01-01"
                              placeholder={t('sub.effective_date_from')}
                              format="yyyy/MM/dd"
                              value={this.state.next_update_date_from}
                              invalidDateMessage={t('errorFields.invalidDateMessage')}
                              maxDateMessage={t('errorFields.maxDateMessage')}
                              minDateMessage={t('errorFields.minDateMessage')}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              onChange={(time) =>
                                this.setState({
                                  next_update_date_from: time,
                                })
                              }
                            />
                            <span style={{padding: '5px'}}></span>
                            <KeyboardDatePicker
                              className="field_size_20 field_min_size_250"
                              margin="dense"
                              autoOk
                              disableToolbar
                              variant="inline"
                              placeholder={t('sub.effective_date_to')}
                              inputVariant="outlined"
                              minDate="2000-01-01"
                              format="yyyy/MM/dd"
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              value={this.state.next_update_date_to}
                              onChange={(time) => this.setState({next_update_date_to: time})}
                              invalidDateMessage={t('errorFields.invalidDateMessage')}
                              maxDateMessage={t('errorFields.maxDateMessage')}
                              minDateMessage={t('errorFields.minDateMessage')}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Grid>

                      {/* Subscription end date */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('sub.end_date')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={6}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className="field_size_20 field_min_size_250"
                              margin="dense"
                              autoOk
                              disableToolbar
                              variant="inline"
                              inputVariant="outlined"
                              minDate="2000-01-01"
                              placeholder={t('sub.effective_date_from')}
                              format="yyyy/MM/dd"
                              value={this.state.end_date_from}
                              invalidDateMessage={t('errorFields.invalidDateMessage')}
                              maxDateMessage={t('errorFields.maxDateMessage')}
                              minDateMessage={t('errorFields.minDateMessage')}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              onChange={(time) =>
                                this.setState({
                                  end_date_from: time,
                                })
                              }
                            />
                            <span style={{padding: '5px'}}></span>
                            <KeyboardDatePicker
                              className="field_size_20 field_min_size_250"
                              margin="dense"
                              autoOk
                              disableToolbar
                              variant="inline"
                              placeholder={t('sub.effective_date_to')}
                              inputVariant="outlined"
                              minDate="2000-01-01"
                              format="yyyy/MM/dd"
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              value={this.state.end_date_to}
                              onChange={(time) => this.setState({end_date_to: time})}
                              invalidDateMessage={t('errorFields.invalidDateMessage')}
                              maxDateMessage={t('errorFields.maxDateMessage')}
                              minDateMessage={t('errorFields.minDateMessage')}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Grid>

                      {this.state.payment_method === 'TRANSFER' && (
                        <>
                          <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                            <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                              {t('businessPartner.transfer_deadline_day')}
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={6}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  className="field_size_20 field_min_size_250"
                                  margin="dense"
                                  autoOk
                                  disableToolbar
                                  variant="inline"
                                  inputVariant="outlined"
                                  minDate="2000-01-01"
                                  placeholder={t('sub.effective_date_from')}
                                  format="yyyy/MM/dd"
                                  value={this.state.transfer_deadline_from}
                                  minDateMessage="無効な日付です"
                                  maxDateMessage="無効な日付です"
                                  KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                  }}
                                  onChange={(time) =>
                                    this.setState({
                                      transfer_deadline_from: time,
                                    })
                                  }
                                />
                                <span style={{padding: '5px'}}></span>
                                <KeyboardDatePicker
                                  className="field_size_20 field_min_size_250"
                                  margin="dense"
                                  autoOk
                                  disableToolbar
                                  variant="inline"
                                  placeholder={t('sub.effective_date_to')}
                                  inputVariant="outlined"
                                  minDate="2000-01-01"
                                  format="yyyy/MM/dd"
                                  KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                  }}
                                  value={this.state.transfer_deadline_to}
                                  onChange={(time) => this.setState({transfer_deadline_to: time})}
                                />
                              </MuiPickersUtilsProvider>
                            </Grid>
                          </Grid>
                        </>
                      )}

                      {/* Payment Id */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('sub.payment_id')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" className="field_size_20 field_min_size_250">
                            <TextField
                              name="payment_id"
                              className="field_size_20 field_min_size_250"
                              margin="dense"
                              placeholder={t('placeholder.required', {field: t('sub.payment_id')})}
                              inputProps={{maxLength: 256}}
                              value={this.state.payment_id}
                              onChange={(event) => onChangeTextField(this, event)}
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Plan cost */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('sub.plan_cost')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" className="field_size_20 field_min_size_250">
                            <TextField
                              name="plan_cost"
                              className="field_size_20 field_min_size_250"
                              margin="dense"
                              type='number'
                              inputProps={{
                                min: 0,
                                maxLength: 256,
                              }}
                              placeholder={t('placeholder.required', {field: t('sub.plan_cost')})}
                              value={this.state.plan_cost}
                              onChange={(event) => onChangeTextField(this, event)}
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Subscription ID */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('sub.subscription_id')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" className="field_size_20 field_min_size_250">
                            <TextField
                              name="subscription_id"
                              className="field_size_20 field_min_size_250"
                              margin="dense"
                              placeholder={t('placeholder.required', {field: t('sub.subscription_id')})}
                              inputProps={{maxLength: 256}}
                              value={this.state.subscription_id}
                              onChange={(event) => onChangeTextField(this, event)}
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Cancel by */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('sub.canceled_by')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_250">
                            <Select
                              name="canceled_by"
                              renderValue={
                                this.state.canceled_by ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('sub.canceled_by'),
                                        })}
                                      </div>
                                    )
                              }
                              variant="outlined"
                              margin="dense"
                              className="field_size_20 field_min_size_250"
                              displayEmpty
                              value={this.state.canceled_by}
                              onChange={(event) => onChangeSelect(this, event)}
                            >
                              {CANCEL_BY_OPTIONS.map(({id, i18n}) => (
                                <MenuItem value={id} key={id}>
                                  {t(i18n)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Automatic update */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('sub.automatic_update')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_250">
                            <Select
                              name="auto_renewable"
                              renderValue={
                                this.state.auto_renewable ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('sub.automatic_update'),
                                        })}
                                      </div>
                                    )
                              }
                              variant="outlined"
                              margin="dense"
                              className="field_size_20 field_min_size_250"
                              displayEmpty
                              value={this.state.auto_renewable}
                              onChange={(event) => onChangeSelect(this, event)}
                            >
                              {AUTO_RENEW_OPTIONS.map(({id, i18n}) => (
                                <MenuItem value={id} key={id}>
                                  {t(i18n)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Status */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.status')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_250">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'is_active',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.is_active || this.state.is_active === false ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('common.status'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.is_active}
                              onChange={(e) => onChangeSelect(this, e)}
                            >
                              <MenuItem value={true}>{t('common.enable')}</MenuItem>
                              <MenuItem value={false}>{t('common.disable')}</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Paper>
                  </>
                )}
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_magin button_color" onClick={() => this.reset()} endIcon={<RotateLeftIcon />}>
                        {t('common.btnResetSearch')}
                      </Button>
                    )}
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {permission.canCSVOutput && permission.canSearch && (
                      this.state.totalRows > EXPORT_LIMIT_NUMBER_ROWS ? (
                        <Button
                          onClick={() => this.props.setMessageModal(modalObj(true, 'messageCode.exportAlertLimit'))}
                          color="primary"
                          variant="contained"
                          className="button_margin"
                          endIcon={<GetAppIcon className="csv-exporter-icon" />}
                        >
                          {t('calendar.csv_output')}
                        </Button>
                      ) : (
                        <CSVExporter
                          url={'admin/csv-export/subscription'}
                          params={this.state.latestSearchParams}
                          disabled={this.state.totalRows === 0}
                        />
                      )
                    )}
                    {permission.canSearch && (
                      <Button
                        color="primary"
                        variant="contained"
                        className="button_magin"
                        onClick={() => this.handleSearch(true)}
                        endIcon={<SearchIcon />}
                      >
                        {t('common.btnSearch')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <br></br>
              </Container>
            </Card>
          </LoadingOverlay>
          <br></br>
          {this.state.isSearch ? (
            <Card raised>
              <Container maxWidth="xl">
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={6}>
                    <h3>
                      {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                    </h3>
                  </Grid>
                </Grid>
                <LoadingOverlay active={business.isLoading || common.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                  {/* SEARCH */}
                  {this.state.result?.length > 0 ? (
                    <div className="scroll_area_700">
                      <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                        <TableHead>
                          <TableRow>
                            <TableCell className="width_100p ant-table-cell-fix-left">{t('sub.subscription_id')}</TableCell>
                            <TableCell className="width_100p">{t('business_plan.category')}</TableCell>
                            <TableCell className="width_100p">{t('memberManagement.memberId')}</TableCell>
                            <TableCell className="width_150p">{t('sub.family_member_code')}</TableCell>
                            <TableCell className="width_150p">{t('business_plan.one_time_discount')}</TableCell>
                            <TableCell className="width_150p">{t('business_plan.username')}</TableCell>
                            <TableCell className="width_100p">{t('common.country')}</TableCell>
                            <TableCell className="width_250p">{t('business_plan.geofence')}</TableCell>
                            <TableCell className="width_200p">{t('common.paymentMethod')}</TableCell>
                            <TableCell className="width_150p">{t('sub.payment_id')}</TableCell>
                            <TableCell className="width_150p">{t('business_plan.code')}</TableCell>
                            <TableCell className="width_150p">{t('business_plan.package_name')} (JP)</TableCell>
                            <TableCell className="width_150p">{t('business_plan.package_name')} (VN)</TableCell>
                            <TableCell className="width_150p">{t('business_plan.package_name')} (EN)</TableCell>
                            <TableCell className="width_250p">{t('common.planType')}</TableCell>
                            <TableCell className="width_100p">{t('business_plan.unit')}</TableCell>
                            <TableCell className="width_100p">{t('ticket.amount_type')}</TableCell>
                            <TableCell className="width_100p">{t('sub.total_price')}</TableCell>
                            <TableCell className="width_150p">{t('sub.price_sub')}</TableCell>
                            <TableCell className="width_150p">{t('sub.price_family_option')}</TableCell>
                            <TableCell className="width_200p">{t('sub.number_family')}</TableCell>
                            <TableCell className="width_200p">{t('sub.number_family_added')}</TableCell>
                            <TableCell className="width_150p">{t('business_plan.mail')}</TableCell>
                            <TableCell className="width_100p">{t('common.phoneNumber')}</TableCell>
                            <TableCell className="width_150p">{t('business_plan.start_date')}</TableCell>
                            <TableCell className="width_150p">{t('business_plan.end_date')}</TableCell>
                            <TableCell className="width_150p">{t('common.paymentStatus')}</TableCell>
                            <TableCell className="width_150p">{t('businessPartner.transfer_deadline_day')}</TableCell>
                            <TableCell className="width_200p">{t('sub.free_trial_start')}</TableCell>
                            <TableCell className="width_200p">{t('sub.free_trial_end')}</TableCell>
                            <TableCell className="width_200p">{t('sub.active_status')}</TableCell>
                            <TableCell className="width_150p">{t('memberManagement.enrollmentDate')}</TableCell>
                            {permission.canEdit && (
                              <TableCell className="width_125p" style={{position: 'sticky', right: '0'}}>
                                {t('common.action')}
                              </TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.result?.map((row, index) => {
                            const country = common?.country_code?.find((item) => row.country_id === item.id);
                            const first_name = row.first_name ? row.first_name : '';
                            const last_name = row.last_name ? row.last_name : '';
                            const timeZone = common?.country_code?.find((country_code) => country_code.id === row.country_id)?.zone_id;
                            const serviceTypes = row?.service_types?.length > 0 ? this.convertPlanType(row?.service_types) : DATA_NULL;
                            return (
                              <TableRow key={index} hover className="cursor_pointer">
                                <TableCell scope="row" className="cell_fixed_left">
                                  {row.subscription_code || DATA_NULL}
                                </TableCell>
                                <TableCell>{row.plan_type ? t(PLAN_CATEGORIES.find((item) => item.value === row.plan_type)?.i18n) : DATA_NULL}</TableCell>
                                <TableCell>{row.user_code || DATA_NULL}</TableCell>
                                <TableCell>
                                  {row?.child_users?.length > 0 ? row?.child_users?.map((item, index) => (
                                    <p key={index} style={{margin: 0, color: item.member_code === this.state.user_code ? '#3f51b5' : '000000DE'}}>
                                      {item.member_code}
                                    </p>
                                  )) : DATA_NULL}
                                </TableCell>
                                <TableCell>{row?.is_one_time_discount ? t('common.enable') : t('common.disable')}</TableCell>
                                <TableCell>{(last_name && last_name) + (first_name && last_name && ' ') + (first_name && first_name) || DATA_NULL}</TableCell>
                                <TableCell>{row?.country_id ? t(country?.country_code) : DATA_NULL}</TableCell>
                                <TableCell> {row.geofence_infos.length > 0 ? row.geofence_infos.map((item) => <div>{item.name}</div>) : DATA_NULL} </TableCell>
                                <TableCell>{row.payment_method ? t(`${PAYMENT_METHOD_TRANSFER_SUB.find((item) => item.id === row.payment_method)?.i18n}`) : DATA_NULL}</TableCell>
                                <TableCell>{row.payment_codes || DATA_NULL}</TableCell>
                                <TableCell>{row.plan_code || DATA_NULL}</TableCell>
                                <TableCell>
                                  {row?.subscription_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.name || DATA_NULL}
                                </TableCell>
                                <TableCell>
                                  {row?.subscription_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.name || DATA_NULL}
                                </TableCell>
                                <TableCell>
                                  {row?.subscription_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.name || DATA_NULL}
                                </TableCell>
                                <TableCell>{serviceTypes || DATA_NULL}</TableCell>
                                <TableCell>{row?.currency_code || DATA_NULL}</TableCell>
                                <TableCell>
                                  {row?.ticket_type ?
                                  t(TICKET_AMOUNT_TYPE.find((item) => item.value === row.ticket_type)?.i18n) :
                                  DATA_NULL}
                                </TableCell>
                                <TableCell>{row?.total_amount || row?.total_amount === 0 ? customDisplayCurrency(row?.total_amount, row?.currency_code) : DATA_NULL}</TableCell>
                                <TableCell>{row?.plan_cost || row?.plan_cost === 0 ? customDisplayCurrency(row?.plan_cost, row?.currency_code) : DATA_NULL}</TableCell>
                                <TableCell>{row?.extra_fee || row?.extra_fee === 0 ? customDisplayCurrency(row?.extra_fee, row?.currency_code) : DATA_NULL}</TableCell>
                                <TableCell>{row?.number_of_max_user || DATA_NULL}</TableCell>
                                <TableCell>{row?.number_of_current_user || DATA_NULL}</TableCell>
                                <TableCell>{row.email || DATA_NULL}</TableCell>
                                <TableCell>{row.mobile || DATA_NULL}</TableCell>
                                <TableCell>
                                  {row?.effective_start_time ? displayDate(formatUtc(row?.effective_start_time, DATE_TIME_FORMAT, timeZone), true) : DATA_NULL}
                                </TableCell>
                                <TableCell>
                                  {row?.effective_end_time ? displayDate(formatUtc(row?.effective_end_time, DATE_TIME_FORMAT, timeZone), true) : DATA_NULL}
                                </TableCell>
                                <TableCell>
                                  {row?.payment_transfer_status ? t(PAYMENT_TRANSFER_STATUS.find((item) => item.id === row?.payment_transfer_status)?.i18n) : DATA_NULL}
                                </TableCell>
                                <TableCell>{row?.transfer_deadline || row?.transfer_deadline === 0 ? row?.transfer_deadline : DATA_NULL}</TableCell>
                                <TableCell>
                                  {row.effective_start_time ? displayDate(formatUtc(row?.effective_start_time, DATE_TIME_FORMAT, timeZone), true) || DATA_NULL : DATA_NULL}
                                </TableCell>
                                <TableCell>
                                  {row?.trial_time_end ? displayDate(formatUtc(row?.trial_time_end, DATE_TIME_FORMAT, timeZone), true) : DATA_NULL}
                                </TableCell>
                                <TableCell>{row.is_active ? t('common.enable') : t('common.disable')}</TableCell>
                                <TableCell scope="row">{row.created_date ? displayDate(formatUtc(row.created_date, DATE_TIME_FORMAT, null), true) : DATA_NULL}</TableCell>
                                {permission.canEdit && (
                                  <TableCell className="width_200p cell_fixed_right">
                                    <Link
                                      style={{textDecoration: 'none'}}
                                      to={{
                                        pathname: ROUTE.LAYOUT + ROUTE.RESERVATION_SUBSCRIPTION_DETAILS + '/' + row.id,
                                        state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                      }}
                                    >
                                      <Button color="primary" variant="contained" className="button_magin" endIcon={<DetailIcon />}>
                                        {t('common.btnEdit')}
                                      </Button>
                                    </Link>
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </div>
                  ) : (
                    false
                  )}
                  {this.state.result?.length > 0 ? (
                    <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                  ) : (
                    <></>
                  )}
                </LoadingOverlay>
                <br></br>

                <Dialog
                  open={this.state.flag}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    okButtonText={`${t('common.btnSend')}`}
                    cancelButtonText={`${t('common.btnReturn')}`}
                    onClickOk={this.handleButtonModalOk}
                    onClickCancel={this.handleButtonModalCancel}
                    message={this.state.message}
                  ></SelectModal>
                </Dialog>
                {/* Export alert modal */}
                <Dialog
                  open={this.state.flagExport}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    onClickOk={this.handleButtonExportOk}
                    onClickCancel={this.handleButtonExportCancel}
                    okButtonText={t('common.btnYes')}
                    cancelButtonText={t('common.btnNo')}
                    message={this.state.message}
                  ></SelectModal>
                </Dialog>
              </Container>
              <br></br>
            </Card>
          ) : (
            false
          )}
          <br></br>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    business: state.business,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllFacilityList: () => dispatch(getAllFacilityList()),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    searchListSubscription: (payload, queryParams, browserHistory) => dispatch(searchListSubScription(payload, queryParams, browserHistory)),
    getAllSubscriptionGeofence: (payload) => dispatch(getAllSubscriptionGeofence(payload)),
    getCsvExportSubscription: (payload) => dispatch(getCsvExportSubscription(payload)),
    getServiceTypes: () => dispatch(getServiceTypes()),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    getCsvExport: (uuid) => dispatch(getCsvExport(uuid)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
