/* eslint-disable max-len */
import React, {Component} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  Card,
  Container,
  Grid,
  FormGroup,
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Backdrop,
  Dialog,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import GetAppIcon from '@material-ui/icons/GetApp';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import MemberCreate from './create';
import MemberUpdate from './update';
import {
  ROUTE,
  MEMBER_STATUS_NEW,
  MEMBER_GENDER,
  ROWS_PER_PAGE_OPTIONS,
  EXPORT_LIMIT_NUMBER_ROWS,
  DATA_NULL,
  PERMISSION_ACTIONS,
  DATE_TIME_FORMAT,
  SIGN_UP_TYPE,
  VERIFY_MOBILE_STATUS,
  UPDATE_INFO_STATUS,
  VERIFY_EMAIL_STATUS,
  REGISTRATION_PLATFORM,
} from '../../common/constant';
import CSVExporter from '../../components/CSVExporter/NewCSVExporter';
import CustomPagination from '../../components/CustomPagination';
import SelectModal from '../../components/selectModal';
import withPermissionGateway from '../../hoc/withPermissionGateway';
import {getCsvExport} from '../../stores/common/actions';
import {searchMemberList, failSearchMemberAction, getCsvExportMemberUuid} from '../../stores/member/action';
import {setMessageModal} from '../../stores/modal/actions';
import {onChangeTextField, changeUrlParams, getUrlParams, getQueryStringFromObject, displayPhoneNumber, nricNumberFormat} from '../../utils/common';
import {formatUtc} from '../../utils/date_time_utils';
import {displayDate, displayDateTime, getClientTimezone} from '../../utils/datetime';
import {modalObj} from '../../utils/modal';

/**
 * Member Management view
 */
class MemberManagement extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      listDeleteMemberId: [],
      detailId: '',
      statusList: [MEMBER_STATUS_NEW[0].name, MEMBER_STATUS_NEW[1].name],
      signUpTypeList: [],
      verifyMobileStatusList: [],
      updateInfoStatusList: [],
      verifyEmailStatusList: [],
      registrationPlatformList: [],
      memberId: '',
      email: '',
      mobile: '',
      created_from: null,
      created_to: null,
      memberName: '',

      flagDelete: false,
      flagExport: false,
      flag: false,
      isCreateMemberModal: false,
      isSearch: false,
      openModal: false,
      confirmedExport: false,
      searchParams: this.props.location.search,
      latestSearchParams: null,
      result: [],
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
    };
    this.handleButtonOk = this.handleButtonOk.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);

    this.exportCSVRef = React.createRef();
    this.exportHeaders = [];
  }

  /**
   * componentWillUnmount
   */
  componentWillUnmount() {
    this.props.resetMemberList();
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    if (this.props.location.search) {
      this.setState(getUrlParams(this.props.location.search));
    }
  }

  /**
   * componentDidUpdate
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.props.actions !== prevProps.actions) {
      const canSearch = this.props.actions.includes(PERMISSION_ACTIONS.SEARCH);
      if (canSearch && this.props.location.search) {
        this.setState(getUrlParams(this.props.location.search), this.searchMember);
      }
    }
  }
  /**
   * handle detail
   * @param {int} id
   */
  handleDetail = (id) => {
    this.setState({
      detailId: id,
      openModal: true,
    });
  };

  /**
   * handleButtonOk
   */
  handleButtonOk() {
    this.setState({
      flag: true,
    });
    this.props.onClickClose();
  }

  /**
   * handleClickClose
   */
  handleClickClose() {
    this.setState({
      flag: false,
    });
  }

  /**
   * handleButtonClose
   */
  handleButtonClose = () => {
    this.setState({
      openModal: false,
      isCreateMemberModal: false,
    });
  };

  /**
   * reset
   */
  reset = () => {
    this.setState({
      statusList: [],
      signUpTypeList: [],
      verifyMobileStatusList: [],
      updateInfoStatusList: [],
      verifyEmailStatusList: [],
      registrationPlatformList: [],
      memberId: '',
      email: '',
      mobile: '',
      memberName: '',
      created_from: null,
      created_to: null,
    });
  };

  /**
   *
   * @param {*} value
   * @param {*} item
   */
  selectMemberStatus = (value, item) => {
    const statusListCopy = [...this.state.statusList];
    const index = statusListCopy.indexOf(item);
    value ? statusListCopy.push(item) : statusListCopy.splice(index, 1);
    const checkStatus = statusListCopy.includes(MEMBER_STATUS_NEW[1].name);
    this.setState({
      statusList: statusListCopy,
    });
    !checkStatus && this.setState({
      verifyMobileStatusList: [],
      updateInfoStatusList: [],
      verifyEmailStatusList: [],
    });
  };

  /**
   *
   * @param {*} value
   * @param {*} item
   */
  selectVerifyMobileStatus = (value, item) => {
    const verifyMobileStatusListCopy = [...this.state.verifyMobileStatusList];
    const index = verifyMobileStatusListCopy.indexOf(item);
    value ? verifyMobileStatusListCopy.push(item) : verifyMobileStatusListCopy.splice(index, 1);
    this.setState({
      verifyMobileStatusList: verifyMobileStatusListCopy,
    });
  };

  /**
   *
   * @param {*} value
   * @param {*} item
   */
  selectUpdateInfoStatus = (value, item) => {
    const updateInfoStatusListCopy = [...this.state.updateInfoStatusList];
    const index = updateInfoStatusListCopy.indexOf(item);
    value ? updateInfoStatusListCopy.push(item) : updateInfoStatusListCopy.splice(index, 1);
    this.setState({
      updateInfoStatusList: updateInfoStatusListCopy,
    });
  };

  /**
   *
   * @param {*} value
   * @param {*} item
   */
  selectVerifyEmailStatus = (value, item) => {
    const verifyEmailStatusListCopy = [...this.state.verifyEmailStatusList];
    const index = verifyEmailStatusListCopy.indexOf(item);
    value ? verifyEmailStatusListCopy.push(item) : verifyEmailStatusListCopy.splice(index, 1);
    this.setState({
      verifyEmailStatusList: verifyEmailStatusListCopy,
    });
  };

  /**
   *
   * @param {*} value
   * @param {*} item
   */
  selectRegistrationPlatform = (value, item) => {
    const registrationPlatformListCopy = [...this.state.registrationPlatformList];
    const index = registrationPlatformListCopy.indexOf(item);
    value ? registrationPlatformListCopy.push(item) : registrationPlatformListCopy.splice(index, 1);
    this.setState({
      registrationPlatformList: registrationPlatformListCopy,
    });
  };

  /**
   *
   * @param {*} value
   * @param {*} item
   */
  selectSignupType = (value, item) => {
    const signUpTypeListCopy = [...this.state.signUpTypeList];
    const index = signUpTypeListCopy.indexOf(item);
    value ? signUpTypeListCopy.push(item) : signUpTypeListCopy.splice(index, 1);
    this.setState({
      signUpTypeList: signUpTypeListCopy,
    });
  };

  /**
   * getDateTimeSearch
   * @param {*} value
   * @return {*}
   */
  getDateTimeSearch = (value) => {
    return moment(value).format('yyyy-MM-DD');
  };

  /**
   * searchMember
   * @param {bool} reset
   */
  searchMember = (reset) => {
    const canSearch = this.props.actions.includes(PERMISSION_ACTIONS.SEARCH);
    if (!canSearch) return;
    const {verifyMobileStatusList, updateInfoStatusList, verifyEmailStatusList, statusList, registrationPlatformList, memberId, email, mobile, memberName, created_from, created_to, signUpTypeList} = this.state;
    const statuses = statusList.filter((status) => status !== undefined);
    const signup_types = signUpTypeList.filter((item) => item !== undefined);
    let payload = {
      statuses,
      signup_types,
      created_from: created_from ? this.getDateTimeSearch(created_from) : null,
      created_to: created_to ? this.getDateTimeSearch(created_to) : null,
      member_id: memberId ? memberId.trim() : null,
      email: email ? email.trim() : null,
      mobile: mobile ? mobile.trim() : null,
      member_name: memberName ? memberName.trim() : null,
      zone_id: getClientTimezone(),
      registration_platforms: registrationPlatformList,
    };
    if (statusList.includes(MEMBER_STATUS_NEW[1].name)) {
      payload = {
        ...payload,
        mobile_statuses: verifyMobileStatusList,
        info_statuses: updateInfoStatusList,
        email_statuses: verifyEmailStatusList,
      };
    }
    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const {currentPage, rowsPerPage} = this.state;
    const queryParams = reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage};

    this.props.searchMemberList(payload, queryParams, this.props).then((response) => {
      if (!response) {
        response = [];
      }
      this.setState(
        {
          result: response?.content,
          totalRows: response?.totalSize,
          isSearch: true,
          latestSearchParams: payload,
        },
        () => {
          const {verifyMobileStatusList, updateInfoStatusList, verifyEmailStatusList, registrationPlatformList, statusList, member_id, email, mobile, member_name, created_from, created_to, currentPage, rowsPerPage, signUpTypeList} = this.state;
          // Apply changed params into url
          let queryParamsToChange = {
            statusList,
            signUpTypeList,
            registrationPlatformList,
            member_id,
            email,
            mobile,
            member_name,
            created_from: created_from ? this.getDateTimeSearch(created_from) : null,
            created_to: created_to ? this.getDateTimeSearch(created_to) : null,
            currentPage: String(currentPage),
            rowsPerPage,
          };
          if (statusList.includes(MEMBER_STATUS_NEW[1].name)) {
            queryParamsToChange = {
              ...queryParamsToChange,
              verifyMobileStatusList,
              updateInfoStatusList,
              verifyEmailStatusList,
            };
          }
          changeUrlParams(queryParamsToChange);
          const newSearchParams = getQueryStringFromObject(queryParamsToChange);
          // Save search params into state in order to pass to next page
          this.setState({searchParams: newSearchParams});
        },
      );
    });
  };

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.searchMember);
  };

  /**
   * Fetch data to export
   * @param {String} csvUuid
   */
  fetchDataExport = async (csvUuid) => {
    const {t} = this.props;
    this.exportHeaders = [
      {label: t('memberManagement.memberId'), key: 'member_id'},
      {label: t('memberManagement.membershipStatus'), key: 'member_status'},
      {label: t('memberManagement.membershipType'), key: 'account_type'},
      {label: t('accountManagement.verify_mobile_status'), key: 'mobile_status'},
      {label: t('accountManagement.update_info_status'), key: 'update_status'},
      {label: t('accountManagement.verify_email_status'), key: 'email_status'},
      {label: t('accountManagement.signUpType'), key: 'signup_types'},
      {label: t('accountManagement.registration_platform'), key: 'registration_platform'},
      {label: t('accountManagement.app_version'), key: 'app_version'},
      {label: t('memberManagement.enrollmentDateUTC'), key: 'created_date'},
      {label: t('common.cancellationDateTime'), key: 'cancellation_date'},
      {label: t('memberManagement.memberEmail'), key: 'email'},
      {label: t('updateMember.lastName'), key: 'last_name'},
      {label: t('updateMember.firstName'), key: 'first_name'},
      {label: t('memberManagement.sex'), key: 'gender'},
      {label: t('memberManagement.birthDay'), key: 'birthday'},
      {label: t('common.address'), key: 'address'},
      {label: t('common.phoneNumber'), key: 'mobile'},
      {label: t('businessPartner.referrer_code'), key: 'referral_code'},
      {label: t('memberManagement.referrer_code_name'), key: 'business_name'},
      {label: t('memberManagement.nricNumber'), key: 'nric_number'},
      {label: t('memberManagement.passportNumber'), key: 'passport'},
    ];
    const response = await this.props.getCsvExport(csvUuid);
    const messageCode = response?.message_code;
    if (messageCode === 'error.csv.export' || messageCode === 'resource.notfound') {
      return {messageCode};
    }
    const dataExport = response?.result;
    const convertedData = dataExport.map((row, index) => {
      const signupTypes = row?.signup_types.map((item) => (
        SIGN_UP_TYPE.filter(({id}) => id === item)[0]?.name
      ));
      row.member_status = t(`${MEMBER_STATUS_NEW.filter(({name}) => row?.member_status === name)[0].i18n}`);
      row.mobile_status = row.mobile_status ? t(`${VERIFY_MOBILE_STATUS.filter(({name}) => row.mobile_status === name)[0]?.i18n}`) : '';
      row.update_status = row.info_status ? t(`${UPDATE_INFO_STATUS.filter(({name}) => row.info_status === name)[0]?.i18n}`) : '';
      row.email_status = row.email_status ? t(`${VERIFY_EMAIL_STATUS.filter(({name}) => row.email_status === name)[0]?.i18n}`) : '';
      row.signup_types = row.signup_types ? signupTypes.join(', ') : '';
      row.registration_platform = row.registration_platform ? t(`${REGISTRATION_PLATFORM.filter(({name}) => row.registration_platform === name)[0]?.i18n}`) : '';
      row.created_date = displayDateTime(row.created_date);
      row.cancellation_date = displayDateTime(row.cancellation_date);
      row.gender = t(`${MEMBER_GENDER.filter(({name}) => row.gender === name)[0]?.i18n}`);
      row.birthday = displayDate(row.birthday);
      row.mobile = row.mobile && '\'' + row.mobile;
      row.nric_number = row.nric_number ? nricNumberFormat(row.nric_number, 6, 8) : '';
      row.app_version = (row.os && row.app_version) ? `${row.app_version} ${row.os}` : '';
      return row;
    });
    this.setState({confirmedExport: false});
    return {convertedData, messageCode};
  };

  /**
   * alertExportCSV
   */
  alertExportCSV = () => {
    const {t} = this.props;
    if (this.state.totalRows > EXPORT_LIMIT_NUMBER_ROWS) {
      this.props.setMessageModal(modalObj(true, t('messageCode.exportAlertLimit')));
    } else {
      const message = t('messageCode.exportAlert');
      this.setState({flagExport: true, message});
    }
  };

  /**
   * handleButtonExportOk
   */
  handleButtonExportOk = () => {
    this.setState({flagExport: false, confirmedExport: true}, () => {
      this.exportCSVRef.current.onClickExport();
    });
  };

  /**
   * handleButtonExportCancel
   */
  handleButtonExportCancel = () => {
    this.setState({flagExport: false});
  };

  /**
   * handleRegisterMemberModal
   */
  handleRegisterMemberModal = () => {
    this.setState({
      isCreateMemberModal: true,
    });
  };

  /**
   * Render component
   * @return {object}
   */
  render() {
    const {t, member, actions} = this.props;
    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canCSVOutput: actions.includes(PERMISSION_ACTIONS.CSV_OUTPUT),
      canRegister: actions.includes(PERMISSION_ACTIONS.REGISTER),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
      canUnsubscribe: actions.includes(PERMISSION_ACTIONS.UNSUBCRIBE),
      canConfirmCreditCard: actions.includes(PERMISSION_ACTIONS.CONFIRM_CREDIT_CARD),
      canContinueFreeTrial: actions.includes(PERMISSION_ACTIONS.CONTINUE_FREE_TRIAL),
      canUserSubscriptionInformation: actions.includes(PERMISSION_ACTIONS.USER_SUBSCRIPTION_INFORMATION),
      canRegisterSubscription: actions.includes(PERMISSION_ACTIONS.REGISTER_SUBSCRIPTION),
      canForceLogout: actions.includes(PERMISSION_ACTIONS.FORCE_LOGOUT),
      canAccessWaypointFavorite: actions.includes(PERMISSION_ACTIONS.WAYPOINT_FAVORITE),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.memberManagement')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          <LoadingOverlay active={member.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                {permission.canSearch && (
                  <>
                    <br></br>
                    <Paper>
                      <form autoComplete="off">
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell component="th" className="product_entry_table_header_color font_color_white font_size_mid" colSpan={3}>
                                {t('accountManagement.titleSearch')}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {/* member status*/}
                            <TableRow>
                              <TableCell className="table_background_color_aliceblue field_size_15 field_min_size_125">{t('accountManagement.member_status')}</TableCell>
                              <TableCell colSpan={2} className="table_background_color_aliceblue">
                                <FormGroup row>
                                  {MEMBER_STATUS_NEW.map(({name, i18n}, index) => {
                                    const checked = this.state.statusList.indexOf(name) > -1;
                                    return (
                                      <FormControlLabel
                                        key={index}
                                        labelPlacement="end"
                                        control={<Checkbox className="checkbox_radio" checked={checked} onClick={(event) => this.selectMemberStatus(event.target.checked, name)} />}
                                        label={t(i18n)}
                                      />
                                    );
                                  })}
                                </FormGroup>
                              </TableCell>
                            </TableRow>
                            {this.state.statusList.includes(MEMBER_STATUS_NEW[1].name) &&
                              <>
                                {/* verify mobile status*/}
                                <TableRow>
                                  <TableCell className="table_background_color_aliceblue field_size_15 field_min_size_125">{t('accountManagement.verify_mobile_status')}</TableCell>
                                  <TableCell colSpan={2} className="table_background_color_aliceblue">
                                    <FormGroup row>
                                      {VERIFY_MOBILE_STATUS.map(({name, i18n}, index) => {
                                        const checked = this.state.verifyMobileStatusList.indexOf(name) > -1;
                                        return (
                                          <FormControlLabel
                                            key={index}
                                            labelPlacement="end"
                                            control={<Checkbox
                                              className="checkbox_radio" checked={checked}
                                              onClick={(event) => this.selectVerifyMobileStatus(event.target.checked, name)}
                                            />}
                                            label={t(i18n)}
                                          />
                                        );
                                      })}
                                    </FormGroup>
                                  </TableCell>
                                </TableRow>
                                {/* update info status*/}
                                <TableRow>
                                  <TableCell className="table_background_color_aliceblue field_size_15 field_min_size_125">{t('accountManagement.update_info_status')}</TableCell>
                                  <TableCell colSpan={2} className="table_background_color_aliceblue">
                                    <FormGroup row>
                                      {UPDATE_INFO_STATUS.map(({name, i18n}, index) => {
                                        const checked = this.state.updateInfoStatusList.indexOf(name) > -1;
                                        return (
                                          <FormControlLabel
                                            key={index}
                                            labelPlacement="end"
                                            control={<Checkbox
                                              className="checkbox_radio" checked={checked}
                                              onClick={(event) => this.selectUpdateInfoStatus(event.target.checked, name)}
                                            />}
                                            label={t(i18n)}
                                          />
                                        );
                                      })}
                                    </FormGroup>
                                  </TableCell>
                                </TableRow>
                                {/* verify email status*/}
                                <TableRow>
                                  <TableCell className="table_background_color_aliceblue field_size_15 field_min_size_125">{t('accountManagement.verify_email_status')}</TableCell>
                                  <TableCell colSpan={2} className="table_background_color_aliceblue">
                                    <FormGroup row>
                                      {VERIFY_EMAIL_STATUS.map(({name, i18n}, index) => {
                                        const checked = this.state.verifyEmailStatusList.indexOf(name) > -1;
                                        return (
                                          <FormControlLabel
                                            key={index}
                                            labelPlacement="end"
                                            control={<Checkbox
                                              className="checkbox_radio" checked={checked}
                                              onClick={(event) => this.selectVerifyEmailStatus(event.target.checked, name)}
                                            />}
                                            label={t(i18n)}
                                          />
                                        );
                                      })}
                                    </FormGroup>
                                  </TableCell>
                                </TableRow>
                              </>
                            }
                            {/* signUpType*/}
                            <TableRow>
                              <TableCell className="table_background_color_aliceblue field_size_15 field_min_size_125">{t('accountManagement.signUpType')}</TableCell>
                              <TableCell colSpan={2} className="table_background_color_aliceblue">
                                <FormGroup row>
                                  {SIGN_UP_TYPE.map((item, index) => {
                                    const checked = this.state.signUpTypeList.indexOf(item.id) > -1;
                                    return (
                                      <FormControlLabel
                                        key={index}
                                        labelPlacement="end"
                                        control={
                                          <Checkbox
                                            className="checkbox_radio"
                                            checked={checked}
                                            onClick={(event) => this.selectSignupType(event.target.checked, item.id)} />}
                                            label={item?.name}
                                          />
                                    );
                                  })}
                                </FormGroup>
                              </TableCell>
                            </TableRow>
                            {/* registration platform */}
                            <TableRow>
                              <TableCell className="table_background_color_aliceblue field_size_15 field_min_size_125">{t('accountManagement.registration_platform')}</TableCell>
                              <TableCell colSpan={2} className="table_background_color_aliceblue">
                                <FormGroup row>
                                  {REGISTRATION_PLATFORM.map(({name, i18n}, index) => {
                                    const checked = this.state.registrationPlatformList.indexOf(name) > -1;
                                    return (
                                      <FormControlLabel
                                        key={index}
                                        labelPlacement="end"
                                        control={<Checkbox
                                          className="checkbox_radio" checked={checked}
                                          onClick={(event) => this.selectRegistrationPlatform(event.target.checked, name)}
                                        />}
                                        label={t(i18n)}
                                      />
                                    );
                                  })}
                                </FormGroup>
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell className="table_background_color_aliceblue field_size_15 field_min_size_125">{t('memberManagement.memberRegistrationDate')}</TableCell>
                              <TableCell className="table_background_color_aliceblue">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    className="field_size_20 field_min_size_300"
                                    margin="dense"
                                    autoOk
                                    disableToolbar
                                    variant="inline"
                                    inputVariant="outlined"
                                    minDate="2000-01-01"
                                    format="yyyy/MM/dd"
                                    value={this.state.created_from}
                                    label={t('businessVehicle.start_date')}
                                    minDateMessage="無効な日付です"
                                    maxDateMessage="無効な日付です"
                                    KeyboardButtonProps={{
                                      'aria-label': 'change time',
                                    }}
                                    onChange={(time) =>
                                      this.setState({
                                        created_from: time,
                                      })
                                    }
                                  />
                                </MuiPickersUtilsProvider>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardDatePicker
                                    className="field_size_20 field_min_size_300 ml-20-custom"
                                    margin="dense"
                                    autoOk
                                    disableToolbar
                                    variant="inline"
                                    inputVariant="outlined"
                                    minDate="2000-01-01"
                                    format="yyyy/MM/dd"
                                    label={t('businessVehicle.end_date')}
                                    value={this.state.created_to}
                                    minDateMessage="無効な日付です"
                                    maxDateMessage="無効な日付です"
                                    KeyboardButtonProps={{
                                      'aria-label': 'change time',
                                    }}
                                    onChange={(time) =>
                                      this.setState({
                                        created_to: time,
                                      })
                                    }
                                  />
                                </MuiPickersUtilsProvider>
                              </TableCell>
                            </TableRow>
                            {/* memberId */}
                            <TableRow>
                              <TableCell className="table_background_color_aliceblue field_size_15 field_min_size_125">{t('memberManagement.memberId')}</TableCell>
                              <TableCell className="table_background_color_aliceblue" colSpan={3}>
                                <TextField
                                  name="memberId"
                                  className="field_size_15 field_min_size_350"
                                  placeholder={t('memberManagement.memberId')}
                                  margin="dense"
                                  variant="outlined"
                                  onChange={(event) => onChangeTextField(this, event)}
                                  value={this.state.memberId}
                                />
                              </TableCell>
                            </TableRow>
                            {/* memberEmail */}
                            <TableRow>
                              <TableCell className="table_background_color_aliceblue field_size_15 field_min_size_125">{t('memberManagement.memberEmail')}</TableCell>
                              <TableCell className="table_background_color_aliceblue" colSpan={3}>
                                <TextField
                                  name="email"
                                  placeholder={t('memberManagement.memberEmail')}
                                  className="field_size_15 field_min_size_350"
                                  margin="dense"
                                  variant="outlined"
                                  onChange={(event) => onChangeTextField(this, event)}
                                  value={this.state.email}
                                />
                              </TableCell>
                            </TableRow>
                            {/* memberName */}
                            <TableRow>
                              <TableCell className="table_background_color_aliceblue field_size_15 field_min_size_125">{t('memberManagement.memberName')}</TableCell>
                              <TableCell className="table_background_color_aliceblue" colSpan={3}>
                                <TextField
                                  name="memberName"
                                  placeholder={t('memberManagement.memberName')}
                                  className="field_size_15 field_min_size_350"
                                  margin="dense"
                                  variant="outlined"
                                  onChange={(event) => onChangeTextField(this, event)}
                                  value={this.state.memberName}
                                />
                              </TableCell>
                            </TableRow>
                            {/* memberPhone */}
                            <TableRow>
                              <TableCell className="table_background_color_aliceblue field_size_15 field_min_size_125">{t('memberManagement.memberPhone')}</TableCell>
                              <TableCell className="table_background_color_aliceblue" colSpan={3}>
                                <TextField
                                  className="field_size_15 field_min_size_350"
                                  placeholder={t('memberManagement.memberPhone')}
                                  margin="dense"
                                  variant="outlined"
                                  name="mobile"
                                  value={this.state.mobile}
                                  onChange={(event) => onChangeTextField(this, event)}
                                />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </form>
                    </Paper>
                  </>
                )}
                <br></br>

                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<RotateLeftIcon />} onClick={this.reset}>
                        {t('common.btnResetSearch')}
                      </Button>
                    )}
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {permission.canRegister && (
                      <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={this.handleRegisterMemberModal}>
                        {t('common.btnRegister')}
                      </Button>
                    )}
                    {permission.canCSVOutput &&
                      permission.canSearch &&
                      (this.state.totalRows > EXPORT_LIMIT_NUMBER_ROWS ? (
                        <Button
                          onClick={() => this.props.setMessageModal(modalObj(true, 'messageCode.exportAlertLimit'))}
                          disabled={this.state.totalRows <= 0}
                          color="primary"
                          variant="contained"
                          className="button_margin"
                          endIcon={<GetAppIcon className="csv-exporter-icon" />}
                        >
                          {t('calendar.csv_output')}
                        </Button>
                      ) : (
                        <CSVExporter
                          url={'admin/csv-export/member'}
                          params={this.state.latestSearchParams}
                          disabled={this.state.totalRows === 0}
                        />
                      ))}
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" onClick={() => this.searchMember(true)} className="button_margin" endIcon={<SearchIcon />}>
                        {t('common.btnSearch')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Container>
              <br></br>
            </Card>
          </LoadingOverlay>
          <br></br>
          {this.state.isSearch ? (
            <Card raised>
              <Container maxWidth="xl">
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={12}>
                    <h3>
                      {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                    </h3>
                  </Grid>
                </Grid>
                <LoadingOverlay active={member.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                  {this.state.result.length > 0 && (
                    <div className="scroll_area_700">
                      <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                        <TableHead>
                          <TableRow>
                            <TableCell className="width_125p" style={{position: 'sticky', left: '0', right: '0', zIndex: 3}}>
                              {t('memberManagement.memberId')}
                            </TableCell>
                            <TableCell className="width_200p">{t('accountManagement.member_status')}</TableCell>
                            <TableCell className="width_200p">{t('accountManagement.verify_mobile_status')}</TableCell>
                            <TableCell className="width_200p">{t('accountManagement.update_info_status')}</TableCell>
                            <TableCell className="width_200p">{t('accountManagement.verify_email_status')}</TableCell>
                            <TableCell className="width_200p">{t('accountManagement.signUpType')}</TableCell>
                            <TableCell className="width_150p">{t('accountManagement.registration_platform')}</TableCell>
                            <TableCell className="width_200p">{t('accountManagement.app_version')}</TableCell>
                            <TableCell className="width_150p">{t('memberManagement.enrollmentDateUTC')}</TableCell>
                            <TableCell className="width_200p">{t('memberManagement.memberEmail')}</TableCell>
                            <TableCell className="width_150p">{t('updateMember.lastName')}</TableCell>
                            <TableCell className="width_150p">{t('updateMember.firstName')}</TableCell>
                            <TableCell className="width_150p">{t('updateMember.lastName_furigana')}</TableCell>
                            <TableCell className="width_150p">{t('updateMember.firstName_furigana')}</TableCell>
                            <TableCell className="width_100p">{t('memberManagement.sex')}</TableCell>
                            <TableCell className="width_150p">{t('memberManagement.birthDay')}</TableCell>
                            <TableCell className="width_200p">{t('common.address')}</TableCell>
                            <TableCell className="width_150p">{t('common.phoneNumber')}</TableCell>
                            <TableCell className="width_150p">{t('memberManagement.nricNumber')}</TableCell>
                            <TableCell className="width_150p">{t('memberManagement.passportNumber')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.result.map((row, index) => {
                            const signupTypes = row?.signup_types.map((item) => (
                              SIGN_UP_TYPE.filter(({id}) => id === item)[0]?.name
                            ));
                            return (
                              <TableRow hover className="cursor_pointer" key={index} onClick={() => permission.canEdit && this.handleDetail(row.id)}>
                                <TableCell scope="row" className="cell_fixed_left">
                                  {row.member_id}
                                </TableCell>
                                <TableCell scope="row">{row.member_status ? MEMBER_STATUS_NEW.map(({name, i18n}) => (row.member_status === name ? t(i18n) : '')): DATA_NULL}</TableCell>
                                <TableCell scope="row">{row.mobile_status ? VERIFY_MOBILE_STATUS.map(({name, i18n}) => (row.mobile_status === name ? t(i18n) : '')) : DATA_NULL}</TableCell>
                                <TableCell scope="row">{row.info_status ? UPDATE_INFO_STATUS.map(({name, i18n}) => (row.info_status === name ? t(i18n) : '')) : DATA_NULL}</TableCell>
                                <TableCell scope="row">{row.email_status ? VERIFY_EMAIL_STATUS.map(({name, i18n}) => (row.email_status === name ? t(i18n) : '')) : DATA_NULL}</TableCell>
                                <TableCell scope='row'>{row.signup_types ? signupTypes.join(', ') : DATA_NULL}</TableCell>
                                <TableCell scope="row">{row.registration_platform ? REGISTRATION_PLATFORM.map(({name, i18n}) => (row.registration_platform === name ? t(i18n) : '')) : DATA_NULL}</TableCell>
                                <TableCell scope="row">{(row.app_version && row.os) ? `${row.app_version} ${row.os}` : DATA_NULL}</TableCell>
                                <TableCell scope="row">{row.created_date ? displayDate(row.created_date) : DATA_NULL}</TableCell>
                                <TableCell scope="row">{row.email || DATA_NULL}</TableCell>
                                <TableCell scope="row">{row.last_name || DATA_NULL}</TableCell>
                                <TableCell scope="row">{row.first_name || DATA_NULL}</TableCell>
                                <TableCell scope="row">{row.last_name_furigana || DATA_NULL}</TableCell>
                                <TableCell scope="row">{row.first_name_furigana || DATA_NULL}</TableCell>
                                <TableCell scope="row">{row.gender ? MEMBER_GENDER.map(({name, i18n}) => (row.gender === name ? t(i18n) : '')) : DATA_NULL}</TableCell>
                                <TableCell scope="row">{row.birthday ? displayDate(formatUtc(row.birthday, DATE_TIME_FORMAT, null), true) : DATA_NULL}</TableCell>
                                <TableCell scope="row">{row.address || DATA_NULL}</TableCell>
                                <TableCell scope="row">{displayPhoneNumber(row.mobile, row.raw_mobile) || DATA_NULL}</TableCell>
                                <TableCell scope="row">{row.nric_number ? nricNumberFormat(row.nric_number, 6, 8) : DATA_NULL}</TableCell>
                                <TableCell scope="row">{row?.passport || DATA_NULL}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </div>
                  )}
                  <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                </LoadingOverlay>

                <br></br>
              </Container>
            </Card>
          ) : (
            false
          )}
          <br></br>
        </Container>

        <Dialog
          open={this.state.flag}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <>
            <SelectModal onClickOk={this.handleButtonOk} onClickCancel={this.handleClickClose} message={this.state.message}></SelectModal>
          </>
        </Dialog>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.openModal}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1000',
          }}
        >
          <>
            <MemberUpdate permission={permission} onClickClose={this.handleButtonClose} detailId={this.state.detailId} onUpdated={this.searchMember}></MemberUpdate>
          </>
        </Modal>

        {/* Open Modal Register Member */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.isCreateMemberModal}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1000',
          }}
        >
          <>
            <MemberCreate onClickClose={this.handleButtonClose}></MemberCreate>
          </>
        </Modal>
        {/* Export alert modal */}
        <Dialog
          open={this.state.flagExport}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <>
            <SelectModal
              onClickOk={this.handleButtonExportOk}
              onClickCancel={this.handleButtonExportCancel}
              okButtonText={t('common.btnYes')}
              cancelButtonText={t('common.btnNo')}
              message={this.state.message}
            ></SelectModal>
          </>
        </Dialog>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    member: state.member,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchMemberList: (payload, queryParams, props) => dispatch(searchMemberList(payload, queryParams, props)),
    getCsvExportMemberUuid: (payload) => dispatch(getCsvExportMemberUuid(payload)),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    resetMemberList: () => dispatch(failSearchMemberAction()),
    getCsvExport: (uuid) => dispatch(getCsvExport(uuid)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(MemberManagement)));
