import {api} from '../services/api';

const stopMasterUrl = '/mass/v1/wstop';
const stopSetUrl = '/mass/v1/stopset';

export const searchStopMasterApi = (queryParams, payload) => {
  return api.search(stopMasterUrl + '/search', queryParams, payload);
};

export const updateStopMasterApi = (payload) => {
  return api.post(stopMasterUrl, payload);
};

export const getStopMasterApi = (id) => {
  return api.get(stopMasterUrl + '/' + id);
};

export const deleteStopMasterApi = (id) => {
  return api.delete(stopMasterUrl + '/' + id);
};

export const syncWillerStopApi = (id) => {
  return api.put(stopMasterUrl + '/sync?swatStopSetId=' + id, {});
};

export const syncWaypointApi = (id) => {
  return api.put(stopMasterUrl + '/sync/' + id, {});
};

export const getListStopSetApi = () => {
  return api.get(stopSetUrl + `?size=100&page=0`);
};
