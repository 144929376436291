import React, {Component} from 'react';

import {Grid, TextField, Button} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import ReplayIcon from '@material-ui/icons/Replay';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';

import SelectMultiLang from '../../../components/SelectMultiLang/index';
import {verifyReissuePassword, newPasswordReissue} from '../../../stores/authen/actions';
import {validateInputEmpty, passwordLengthCheck, onChangeTextField} from '../../../utils/common';

/**
 * Re-issue password page
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      new_password: '',
      re_password: '',
      reissue_token: '',
      errorFields: {
        newPasswordRequired: false,
        newPasswordMin: false,
        rePasswordRequired: false,
        rePasswordMin: false,
        rePasswordMatch: false,
      },
      flag: false,
      message: '',
    };
  }
  /**
   * componentDidMount
   */
  componentDidMount() {
    const reissueToken = this.props.match.params.reissueToken;
    this.props.verifyReissuePassword(reissueToken, this.props).then((response) => {
      if (response) {
        this.setState({reissue_token: response});
      }
    });
  }
  /**
   * newPassword
   */
  newPassword = () => {
    if (!this.validate()) {
      const payload = {
        new_password: this.state.new_password,
        reissue_token: this.state.reissue_token,
      };
      this.props.newPasswordReissue(payload, this.props);
    }
  };

  /**
   * validate field
   * @return {boolean}
   */
  validate = () => {
    let flg = false;
    const errorFields = {
      newPasswordRequired: false,
      newPasswordMin: false,
      rePasswordRequired: false,
      rePasswordMin: false,
      rePasswordMatch: false,
    };
    if (validateInputEmpty(this.state.new_password)) {
      flg = true;
      errorFields.newPasswordRequired = true;
    } else if (passwordLengthCheck(this.state.new_password)) {
      flg = true;
      errorFields.newPasswordMin = true;
    }
    if (validateInputEmpty(this.state.re_password)) {
      flg = true;
      errorFields.rePasswordRequired = true;
    } else if (passwordLengthCheck(this.state.re_password)) {
      flg = true;
      errorFields.rePasswordMin = true;
    } else if (!this.validateConfirmPassword()) {
      flg = true;
      errorFields.rePasswordMatch = true;
    }
    this.setState({...this.state, errorFields: errorFields});
    return flg;
  };

  /**
   * Confirm password
   * @return {boolean}
   */
  validateConfirmPassword = () => {
    return this.state.new_password === this.state.re_password;
  };

  /**
   * Render view
   * @return {object}
   */
  render() {
    const {t, authen} = this.props;
    return (
      <div>
        <LoadingOverlay
          active={authen.isLoadingNewPasswordReissue || authen.isLoadingVerifyPasswordReissue}
          bgColor="#f1f1f1"
          spinnerColor="#9ee5f8"
          textColor="#676767"
          spinner
        >
          <Container maxWidth="md">
            <Grid container spacing={2}>
              <Grid item xs={11} justify="center" alignItems="center" container>
                <br></br>
                <br></br>
              </Grid>
              <Grid item xs={1} justify="flex-end" alignItems="center" container>
                <SelectMultiLang />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item container xs={12} alignItems="center" justify="center">
                <img src={`${process.env.PUBLIC_URL}/logoheader.png`} alt="Logo"></img>
              </Grid>

              <Grid item container xs={12} alignItems="center" justify="center" className="border_bottom">
                <h3>{t('passwordReissueConfirm.title')}</h3>
              </Grid>

              <Grid item container xs={12} alignItems="center" justify="center">
                <br></br>
              </Grid>

              <Grid item container xs={12} alignItems="center" justify="center">
                <TextField
                  label={
                    <span>
                      {t('passwordReissueConfirm.new_password')}
                      <span className="font_color_red">＊</span>
                    </span>
                  }
                  error={this.state.errorFields.newPasswordRequired || this.state.errorFields.newPasswordMin}
                  helperText={
                    this.state.errorFields.newPasswordRequired ?
                      t('errorFields.newPasswordRequired') :
                      this.state.errorFields.newPasswordMin ?
                      t('errorFields.passwordMin') :
                      ''
                  }
                  margin="dense"
                  variant="outlined"
                  name="new_password"
                  value={this.state.new_password}
                  onChange={(event) => onChangeTextField(this, event)}
                  inputProps={{maxLength: 256}}
                  className="field_min_size_500"
                  type="password"
                />
              </Grid>
              <Grid item container xs={12} alignItems="center" justify="center">
                <TextField
                  label={
                    <span>
                      {t('passwordReissueConfirm.re_password')}
                      <span className="font_color_red">＊</span>
                    </span>
                  }
                  error={
                    this.state.errorFields.rePasswordRequired ||
                    this.state.errorFields.rePasswordMin ||
                    this.state.errorFields.rePasswordMatch
                  }
                  helperText={
                    this.state.errorFields.rePasswordRequired ?
                      t('errorFields.rePasswordRequired') :
                      this.state.errorFields.rePasswordMin ?
                      t('errorFields.passwordMin') :
                      this.state.errorFields.rePasswordMatch ?
                      t('errorFields.rePasswordMatch') :
                      ''
                  }
                  inputProps={{maxLength: 256}}
                  margin="dense"
                  variant="outlined"
                  value={this.state.re_password}
                  name="re_password"
                  onChange={(event) => onChangeTextField(this, event)}
                  className="field_min_size_500"
                  type="password"
                />
              </Grid>

              <br></br>
              <Grid item container xs={12} alignItems="center" justify="center">
                <Button variant="contained" color="primary" onClick={() => this.newPassword()} endIcon={<ReplayIcon />}>
                  {t('common.btnUpdate')}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </LoadingOverlay>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authen: state.authen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    verifyReissuePassword: (email, props) => dispatch(verifyReissuePassword(email, props)),
    newPasswordReissue: (newPassword, reissueToken, props) =>
      dispatch(newPasswordReissue(newPassword, reissueToken, props)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
