import React, {Component} from 'react';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  Card,
  Container,
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  Table,
  TableCell,
  TableRow,
  Paper,
  TableBody,
  TableHead,
  Dialog,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TableContainer,
  Modal,
  Backdrop,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import DetailIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {
  ROUTE,
  DELIVERY_STATUS,
  LANGUAGE,
  ROWS_PER_PAGE_OPTIONS,
  ARRANGEMENT_METHOD,
  MAX_SIZE_OUTPUT,
  EXPORT_LIMIT_NUMBER_ROWS,
  EXPORT_ALERT_NUMBER_ROWS,
  PERMISSION_ACTIONS,
} from '../../../common/constant';
import CSVExporter from '../../../components/CSVExporter';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getListSupplier, getAllFacilityList, getAllCountryCode} from '../../../stores/common/actions';
import {searchListDelivery, deleteDelivery, getPaymentMethod, exportDelivery} from '../../../stores/delivery/action';
import {setMessageModal} from '../../../stores/modal/actions';
import {changeUrlParams, getUrlParams, getQueryStringFromObject, onChangeSelect, onChangeTextField} from '../../../utils/common';
import {displayDateTime} from '../../../utils/datetime';
import {modalObj} from '../../../utils/modal';
import SettingTimeProduct from './setting_product';

/**
 * Search Delivery Management View
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      sale_status: '',
      country: null,
      language: '',
      supplier_name: '',
      supplier_facility_name: '',
      payment_method_ids: [],
      payment_method: '',
      arrangement_methods: [],
      sale_time_from: null,
      sale_time_to: null,
      product_name: '',
      product_code: '',
      id: '',

      listContent: [],
      open_setting_product: false,
      time_setting_product: [],
      flgDelete: false,
      flag: false,
      isSearch: false,
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,
      flagExport: false,
      confirmedExport: false,
      latestSearchParams: null,
    };
    this.handleButtonOk = this.handleButtonOk.bind(this);
    this.handleButtonCancel = this.handleButtonCancel.bind(this);

    this.exportCSVRef = React.createRef();
    const {t} = this.props;
    this.exportHeaders = [
      {label: t('businessInformation.id'), key: 'supplier_id'},
      {label: t('common.email'), key: 'supplier_name'},
      {label: t('delivery.storeCode'), key: 'supplier_facility_id'},
      {label: t('delivery.storeName'), key: 'supplier_facility_name'},
      {label: t('delivery.product_id'), key: 'product_id'},
      {label: t('delivery.product_name'), key: 'product_name'},
      {label: t('delivery.sale_status'), key: 'sale_status'},
      {label: t('delivery.sale_period'), key: 'sale_period'},
    ];
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    this.props.getAllCountryCode();
    this.props.getAllFacilityList();
    this.props.getListSupplier();
    this.props.getPaymentMethod();
    if (this.props.location.search) {
      const params = getUrlParams(this.props.location.search);
      if (params.sale_time_from) {
        params.sale_time_from = new Date(params.sale_time_from);
      }
      if (params.sale_time_to) {
        params.sale_time_to = new Date(params.sale_time_to);
      }
      this.setState(params, this.handleSearchDelivery);
    }
  }
  /**
   * handleDelete
   * @param {Object} id
   */
  handleDelete(id) {
    this.setState({
      id: id,
      flag: true,
      message: 'messageCode.deleteConfirmProduct',
    });
  }

  /**
   * handleSubmit
   * @param {object} props
   */
  handleSubmit = () => {
    this.setState({
      sale_status: '',
      country: null,
      language: '',
      supplier_name: '',
      supplier_facility_name: '',
      payment_method_ids: [],
      arrangement_methods: [],
      sale_time_from: null,
      sale_time_to: null,
      product_name: '',
      product_code: '',
      flgDelete: false,
      flag: false,
      id: '',
    });
  };
  /**
   * handleButtonOk
   */
  handleButtonOk() {
    this.setState({
      flag: false,
    });
    this.props.deleteDelivery(this.state.id, this.props).then(() => this.handleSearchDelivery());
  }
  /**
   * handleButtonCancel
   */
  handleButtonCancel() {
    this.setState({
      flag: false,
    });
  }
  /**
   * handle search vehicles
   * @param {bool} reset
   */
  handleSearchDelivery = async (reset) => {
    const canSearch = this.props.actions.includes(PERMISSION_ACTIONS.SEARCH);
    if (!canSearch) return;
    const arrangement_methodsCopy = this.state.arrangement_methods.filter((e) => e !== undefined);
    const payload = {
      sale_status: this.state.sale_status ? this.state.sale_status : null,
      country_id: this.state.country ? this.state.country : null,
      language: this.state.language ? this.state.language : null,
      supplier_name: this.state.supplier_name ? this.state.supplier_name.supplier_name : null,
      facility_name: this.state.supplier_facility_name ? this.state.supplier_facility_name.facility_name : null,
      payment_method_ids: this.state.payment_method_ids,
      product_name: this.state.product_name ? this.state.product_name?.trim() : null,
      product_code: this.state.product_code ? this.state.product_code?.trim() : null,
      sale_time_from: this.state.sale_time_from ? this.state.sale_time_from?.toISOString().substr(0, 10) : null,
      sale_time_to: this.state.sale_time_to ? this.state.sale_time_to?.toISOString().substr(0, 10) : null,
      arrangement_methods: arrangement_methodsCopy,
      // eslint-disable-next-line new-cap
      zone_id: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const {currentPage, rowsPerPage} = this.state;
    const queryParams = reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage};

    this.props.searchListDelivery(payload, queryParams).then((response) => {
      this.setState(
        {
          listContent: response?.content,
          isSearch: true,
          totalRows: response?.totalSize,
          latestSearchParams: payload,
        },
        () => {
          const {
            sale_status,
            country,
            language,
            supplier_name,
            supplier_facility_name,
            payment_method_ids,
            payment_method,
            arrangement_methods,
            sale_time_from,
            sale_time_to,
            product_name,
            product_code,
            currentPage,
            rowsPerPage,
          } = this.state;
          // Apply changed params into url
          const queryParamsToChange = {
            sale_status,
            country,
            language,
            supplier_name: supplier_name && JSON.stringify(supplier_name),
            supplier_facility_name: supplier_facility_name && JSON.stringify(supplier_facility_name),
            payment_method_ids,
            payment_method,
            arrangement_methods,
            sale_time_from,
            sale_time_to,
            product_name,
            product_code,
            currentPage: String(currentPage),
            rowsPerPage,
          };
          changeUrlParams(queryParamsToChange);
          const newSearchParams = getQueryStringFromObject(queryParamsToChange);
          // Save search params into state in order to pass to next page
          this.setState({searchParams: newSearchParams});
        },
      );
    });
  };
  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearchDelivery);
  };

  /**
   * changeBusiness
   * @param {event} value
   */
  changeBusiness = (value) => {
    this.setState({supplier_name: value});
  };

  /**
   * changeFacility
   * @param {event} value
   */
  changeFacilityName = (value) => {
    this.setState({supplier_facility_name: value});
  };

  /**
   *
   * @param {*} event
   */
  onChangeSelectPayment = (event) => {
    this.setState({
      payment_method: event.target.value,
    });
    const paymentMethod = this.state.payment_method_ids;
    if (event.target.value) {
      paymentMethod.push(parseInt(event.target.value));
    } else {
      delete paymentMethod[event.target.value];
    }
    this.setState({
      payment_method_ids: paymentMethod,
    });
  };

  /**
   * changeArrangementMethod
   * @param {*} value
   * @param {*} item
   */
  changeArrangementMethod = (value, item) => {
    const arrangement_methods_copy = this.state.arrangement_methods;
    const index = arrangement_methods_copy.indexOf(item);
    value ? arrangement_methods_copy.push(item) : arrangement_methods_copy.splice(index, 1);

    this.setState({
      arrangement_methods: arrangement_methods_copy,
    });
  };

  /**
   * changeTimeSetting
   * @param {*} value
   * @param {*} item
   */
  changeTimeSetting = (value, item) => {
    const time_setting_product_copy = this.state.time_setting_product;

    const index = time_setting_product_copy.indexOf(item);
    value ? time_setting_product_copy.push(item) : time_setting_product_copy.splice(index, 1);

    this.setState({
      time_setting_product: time_setting_product_copy,
    });
  };

  /**
   * handleSettingProduct
   */
  handleSettingProduct = () => {
    this.setState({
      open_setting_product: true,
    });
  };

  /**
   * handleButtonClose
   */
  handleButtonClose = () => {
    this.setState({
      open_setting_product: false,
    });
  };

  /**
   * getCheckedProduct
   * @return {*}
   */
  getCheckedProduct = () => {
    const {listContent, time_setting_product} = this.state;
    const listSetting = [];
    for (let i = 0; i < time_setting_product.length; i++) {
      const setting = listContent.find((item) => item.id === time_setting_product[i]);
      listSetting.push(Object.assign({}, setting));
    }
    return listSetting;
  };

  /**
   * Fetch data to export
   */
  fetchDataExport = async () => {
    const {t} = this.props;
    const queryParams = {page: 0, size: MAX_SIZE_OUTPUT};
    const dataExport = await this.props.exportDelivery(this.state.latestSearchParams, queryParams);
    const convertedData = dataExport.map((row, index) => {
      const saleStatusIndex = DELIVERY_STATUS.findIndex((value) => value.id === row.sale_status);
      row.sale_status = saleStatusIndex > -1 && t(`${DELIVERY_STATUS[saleStatusIndex].i18n}`);
      row.sale_period = displayDateTime(row.sale_time_from) + '\n~\n' + displayDateTime(row.sale_time_to);
      return row;
    });
    this.setState({confirmedExport: false});
    return convertedData;
  };

  /**
   * alertExportCSV
   */
  alertExportCSV = () => {
    const {t} = this.props;
    if (this.state.totalRows > EXPORT_LIMIT_NUMBER_ROWS) {
      this.props.setMessageModal(modalObj(true, t('messageCode.exportAlertLimit')));
    } else {
      const message = t('messageCode.exportAlert');
      this.setState({flagExport: true, message});
    }
  };

  /**
   * handleButtonExportOk
   */
  handleButtonExportOk = () => {
    this.setState({flagExport: false, confirmedExport: true}, () => {
      this.exportCSVRef.current.onClickExport();
    });
  };

  /**
   * handleButtonExportCancel
   */
  handleButtonExportCancel = () => {
    this.setState({flagExport: false});
  };

  /**
   * Render component
   * @return {object}
   */
  render() {
    const {t, delivery, common, actions} = this.props;

    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canCSVOutput: actions.includes(PERMISSION_ACTIONS.CSV_OUTPUT),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
      canDelete: actions.includes(PERMISSION_ACTIONS.DELETE),
      canRegister: actions.includes(PERMISSION_ACTIONS.REGISTER),
      canManage: actions.includes(PERMISSION_ACTIONS.MANAGE),
    };
    const {time_setting_product} = this.state;

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('delivery.title')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Container>
        <br></br>
        <LoadingOverlay active={delivery.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                {permission.canSearch && (
                  <>
                    <br></br>
                    <Paper>
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                          {t('accountManagement.titleSearch')}
                        </Grid>
                      </Grid>

                      {/* Sales status */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('delivery.sale_status')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'sale_status',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.sale_status ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('delivery.sale_status'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.sale_status}
                              onChange={(event) => onChangeSelect(this, event)}
                            >
                              {DELIVERY_STATUS.map((item, idx) => {
                                return (
                                  <MenuItem value={item.id} key={idx}>
                                    {t(`${item.i18n}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Country */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.country')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'country',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.country ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('common.country'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.country}
                              onChange={(event) => onChangeSelect(this, event)}
                            >
                              {common?.country_code?.map((item, idx) => (
                                <MenuItem value={item.id} key={idx}>
                                  {t(`${item.country_code}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Language */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('delivery.language')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'language',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.language ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('delivery.language'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.language}
                              onChange={(event) => onChangeSelect(this, event)}
                            >
                              {LANGUAGE.map(({language, i18n}, idx) => {
                                return (
                                  <MenuItem value={language} key={idx}>
                                    {t(`${i18n}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Business name */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('delivery.business_person')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <Autocomplete
                            margin="dense"
                            className="field_size_10 field_min_size_300"
                            options={common?.supplier_list}
                            value={this.state.supplier_name}
                            getOptionLabel={(option) => option.supplier_name || ''}
                            onChange={(event, business) => this.changeBusiness(business)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name={'supplierName'}
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                placeholder={t('placeholder.required', {field: t('delivery.business_person')})}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>

                      {/* Sales office/Store name*/}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('delivery.sales_store')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <Autocomplete
                            margin="dense"
                            className="field_size_20 field_min_size_300"
                            options={common?.facility_list_all}
                            value={this.state.supplier_facility_name}
                            getOptionLabel={(option) => option.facility_name || ''}
                            onChange={(event, facility) => this.changeFacilityName(facility)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name={'businessName'}
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                placeholder={t('placeholder.required', {field: t('delivery.sales_store')})}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>

                      {/* Arrangement method */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('delivery.arrangement_method')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormGroup row>
                            {ARRANGEMENT_METHOD.map((item, index) => {
                              const checked = this.state.arrangement_methods.indexOf(item.id) > -1;
                              return (
                                <FormControlLabel
                                  key={index}
                                  labelPlacement="end"
                                  control={
                                    <Checkbox
                                      key={index}
                                      checked={checked}
                                      onChange={(event) => this.changeArrangementMethod(event.target.checked, item.id)}
                                      className="checkbox_radio"
                                    />
                                  }
                                  label={item.i18n}
                                />
                              );
                            })}
                          </FormGroup>
                        </Grid>
                      </Grid>

                      {/* Payment method */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.paymentMethod')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'payment_method',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.payment_method ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('common.paymentMethod'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.payment_method}
                              onChange={(event) => this.onChangeSelectPayment(event)}
                            >
                              {delivery.listPayment?.map(({id, code}) => {
                                return (
                                  <MenuItem value={id} key={id}>
                                    {t(`${code}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Product sale period */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('delivery.sale_period')}
                        </Grid>
                        <Grid container item xs={6} lg={6}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              autoOk
                              disableToolbar
                              variant="inline"
                              inputVariant="outlined"
                              minDate="2000-01-01"
                              placeholder={t('delivery.sale_period_start')}
                              format="yyyy/MM/dd"
                              value={this.state.sale_time_from}
                              minDateMessage="無効な日付です"
                              maxDateMessage="無効な日付です"
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              onChange={(time) =>
                                this.setState({
                                  sale_time_from: time,
                                })
                              }
                            />
                            <span style={{padding: '5px'}}></span>
                            <KeyboardDatePicker
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              autoOk
                              disableToolbar
                              variant="inline"
                              placeholder={t('delivery.sale_period_end')}
                              inputVariant="outlined"
                              minDate="2000-01-01"
                              format="yyyy/MM/dd"
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              value={this.state.sale_time_to}
                              onChange={(time) => this.setState({sale_time_to: time})}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Grid>

                      {/* Product name */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('delivery.product_name')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            className="field_size_20 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('delivery.product_name')})}
                            variant="outlined"
                            name="product_name"
                            onChange={(event) => onChangeTextField(this, event)}
                            value={this.state.product_name}
                          />
                        </Grid>
                      </Grid>

                      {/* Product ID */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('delivery.product_id')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            className="field_size_20 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('delivery.product_id')})}
                            variant="outlined"
                            name="product_code"
                            onChange={(event) => onChangeTextField(this, event)}
                            value={this.state.product_code}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </>
                )}
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.handleSubmit()} endIcon={<RotateLeftIcon />}>
                        {t('common.btnResetSearch')}
                      </Button>
                    )}
                    {time_setting_product.length > 0 && permission.canManage && (
                      <Button color="primary" variant="contained" className="button_margin" onClick={() => this.handleSettingProduct()} endIcon={<RotateLeftIcon />}>
                        {t('common.btnManage')}
                      </Button>
                    )}
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {permission.canRegister && (
                      <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.PRODUCT_DELIVERY_ADD}}>
                        <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />}>
                          {t('common.btnRegister')}
                        </Button>
                      </Link>
                    )}
                    {permission.canCSVOutput && permission.canSearch && (
                      <>
                        <div className={this.state.totalRows <= EXPORT_ALERT_NUMBER_ROWS || this.state.confirmedExport ? '' : 'hidden'}>
                          <CSVExporter
                            ref={this.exportCSVRef}
                            disabled={this.state.totalRows <= 0 || !this.state.latestSearchParams}
                            headers={this.exportHeaders}
                            fetchAction={this.fetchDataExport}
                            isFetchAsync={true}
                            screenName={t('delivery.title')}
                          />
                        </div>
                        {this.state.totalRows >= EXPORT_ALERT_NUMBER_ROWS && !this.state.confirmedExport && (
                          <Button
                            onClick={this.alertExportCSV}
                            disabled={this.state.totalRows <= 0}
                            color="primary"
                            variant="contained"
                            className="button_margin"
                            endIcon={<GetAppIcon className="csv-exporter-icon" />}
                          >
                            {t('calendar.csv_output')}
                          </Button>
                        )}
                      </>
                    )}
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin" endIcon={<SearchIcon />} onClick={() => this.handleSearchDelivery(true)}>
                        {t('common.btnSearch')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>
          {this.state.isSearch && (
            <Container maxWidth="xl">
              <Card raised>
                <Container maxWidth="xl">
                  <Grid container spacing={1}>
                    <Grid container alignItems="center" item xs={6}>
                      <h3>
                        {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                      </h3>
                    </Grid>
                  </Grid>
                  <LoadingOverlay active={delivery.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                    {delivery?.listDelivery?.content?.length > 0 && (
                      <Paper>
                        <TableContainer className="scroll_area_500">
                          <Table size="small" aria-label="sticky table" stickyHeader>
                            <TableHead>
                              <TableRow>
                                <TableCell className="width_10">{t('delivery.listTimeSettingProduct')}</TableCell>
                                <TableCell className="width_10">{t('businessInformation.id')}</TableCell>
                                <TableCell className="width_10">{t('businessInformation.name')}</TableCell>
                                <TableCell className="width_10">{t('delivery.storeCode')}</TableCell>
                                <TableCell className="width_10">{t('delivery.storeName')}</TableCell>
                                <TableCell className="width_10">{t('delivery.product_id')}</TableCell>
                                <TableCell className="width_15">{t('delivery.product_name')}</TableCell>
                                <TableCell className="width_10">{t('delivery.sale_status')}</TableCell>
                                <TableCell className="width_15">{t('delivery.sale_period')}</TableCell>
                                {(permission.canEdit || permission.canDelete) && (
                                  <TableCell className="width_20" align="center">
                                    {t('common.action')}
                                  </TableCell>
                                )}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.listContent.map((row, index) => {
                                const saleStatusIndex = DELIVERY_STATUS.findIndex((value) => value.id === row.sale_status);
                                return (
                                  <TableRow key={index} hover className="cursor_pointer">
                                    <TableCell className="width_10">
                                      <Checkbox key={index} onChange={(event) => this.changeTimeSetting(event.target.checked, row.id)} className="checkbox_radio" />
                                    </TableCell>
                                    <TableCell>{row.supplier_id}</TableCell>
                                    <TableCell>{row.supplier_name}</TableCell>
                                    <TableCell>{row.supplier_facility_id}</TableCell>
                                    <TableCell>{row.supplier_facility_name}</TableCell>
                                    <TableCell>{row.product_id}</TableCell>
                                    <TableCell>{row.product_name}</TableCell>
                                    <TableCell>{saleStatusIndex > -1 && t(`${DELIVERY_STATUS[saleStatusIndex].i18n}`)}</TableCell>
                                    <TableCell>
                                      {displayDateTime(row.sale_time_from)}
                                      <div>~</div>
                                      {displayDateTime(row.sale_time_to)}
                                    </TableCell>
                                    {(permission.canEdit || permission.canDelete) && (
                                      <TableCell align="flex-start">
                                        {permission.canEdit && (
                                          <Link
                                            style={{textDecoration: 'none'}}
                                            to={{
                                              pathname: ROUTE.LAYOUT + ROUTE.PRODUCT_DELIVERY_DETAIL + '/' + row.id,
                                              state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                            }}
                                          >
                                            <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                              {t('common.btnEdit')}
                                            </Button>
                                          </Link>
                                        )}
                                        {permission.canDelete && (
                                          <Button
                                            color="secondary"
                                            variant="contained"
                                            className="button_margin"
                                            endIcon={<DeleteIcon />}
                                            onClick={() => this.handleDelete(row.id)}
                                          >
                                            {t('common.btnDelete')}
                                          </Button>
                                        )}
                                      </TableCell>
                                    )}
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    )}
                    <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                  </LoadingOverlay>

                  <Dialog
                    open={this.state.flag}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      zIndex: '1020',
                    }}
                  >
                    <SelectModal onClickOk={this.handleButtonOk} onClickCancel={this.handleButtonCancel} message={this.state.message}></SelectModal>
                  </Dialog>
                  {/* Export alert modal */}
                  <Dialog
                    open={this.state.flagExport}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      zIndex: '1020',
                    }}
                  >
                    <SelectModal
                      onClickOk={this.handleButtonExportOk}
                      onClickCancel={this.handleButtonExportCancel}
                      okButtonText={t('common.btnYes')}
                      cancelButtonText={t('common.btnNo')}
                      message={this.state.message}
                    ></SelectModal>
                  </Dialog>

                  {/* setting product */}
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.open_setting_product}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      zIndex: '1000',
                    }}
                  >
                    <SettingTimeProduct
                      onClickClose={this.handleButtonClose}
                      listCheckedProduct={this.getCheckedProduct()}
                      handleSearchDelivery={this.handleSearchDelivery}
                    ></SettingTimeProduct>
                  </Modal>
                </Container>
              </Card>
            </Container>
          )}
        </LoadingOverlay>
        <br></br>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    delivery: state.delivery,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchListDelivery: (params, queryParams) => dispatch(searchListDelivery(params, queryParams)),
    exportDelivery: (payload, queryParams, props) => dispatch(exportDelivery(payload, queryParams)),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    getListSupplier: () => dispatch(getListSupplier()),
    deleteDelivery: (id, props) => dispatch(deleteDelivery(id, props)),
    getPaymentMethod: () => dispatch(getPaymentMethod()),
    getAllFacilityList: () => dispatch(getAllFacilityList()),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
