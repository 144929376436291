import React, {Component} from 'react';

import {Card, Container, Grid, Button, CardActionArea, CardContent, Typography, Box} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import _ from 'lodash';
import queryString from 'query-string';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import 'react-tabs/style/react-tabs.css';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';

import {ROUTE, TIME_FORMAT} from '../../../../common/constant';
import {getVehicleInfo} from '../../../../services/mapServices';
import {updateBooking, searchRoutesBooking} from '../../../../stores/reservation/actions';
import {redirectRouter, dynamicSort, getSubTime, customDisplayCurrency} from '../../../../utils/common';
import {roundedAndConvertTimeByZoneId} from '../../../../utils/datetime';
import '../style.css';

/**
 * Reservation management view
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      ...queryString.parse(this.props.location.search),
      activeTab: 'TIME',
      errorWaypointNotAssociate: false,
    };
  }

  /**
   * onActive
   * @param {*} tab
   */
  onActive(tab) {
    this.setState({
      activeTab: tab,
    });
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    if (_.isEmpty(this.props.bookingInfo) || !this.props.location.search) this.props.history.push(ROUTE.LAYOUT + ROUTE.RESERVATION_CREATE);
    this.searchRoutes();
  }

  /**
   * searchRoutes
   */
  async searchRoutes() {
    const data = Object.assign({}, this.props.bookingInfo);
    const {searchBooking} = this.props;
    const no_adults = Number(this.state.passenger) || 0;
    const no_infants = searchBooking?.infantNumber || 0;
    const payload = {
      requested_departure_time: this.state.requested_departure_time,
      pickup_location_lat: searchBooking?.pickup_location_lat,
      pickup_location_lon: searchBooking?.pickup_location_lon,
      pickup_location_name: searchBooking?.pickup_location_name,
      dropoff_location_lat: searchBooking?.dropoff_location_lat,
      dropoff_location_lon: searchBooking?.dropoff_location_lon,
      dropoff_location_name: searchBooking?.dropoff_location_name,
      preferred_pickup_stop_id: searchBooking?.positionFrom?.transit_stop_swat_id,
      preferred_dropoff_stop_id: searchBooking?.positionTo?.transit_stop_swat_id,
      demand: {
        normal_passengers: 0,
        passenger: no_adults + (no_infants || 0),
        special_category: 0,
        // no_adults: 0,
        // no_children: 0,
        no_infants,
      },
      service_type: this.state.service_type,
      transportation_type: this.state.service_type?.includes('SHUTTLE') ? 'SHUTTLE_BUS' : 'TAXI',
      service_group_id: this.state.service_group_id,
    };

    const response = await this.props.searchRoutesBooking(payload);
    if (response?.message_code === 'mass.waypoint.not.associate') {
      this.setState({
        errorWaypointNotAssociate: true,
      });
    } else {
      if (response?.result && response?.result?.length > 0) {
        // update to booking info
        const data_routes = response?.result?.find((e) => e.routes?.find((e) => e.type === 'SHUTTLE_BUS'))?.routes;
        const partner_coupon = data_routes?.find((e) => e.type === 'SHUTTLE_BUS')?.partner_coupon;
        payload.geofence_id = this.state.geofence_id;
        payload.currency_code = data_routes?.find((e) => e.type === 'SHUTTLE_BUS')?.currency_code;
        if (partner_coupon) {
          payload.partner_coupon = {discount: partner_coupon.discount, discount_type: partner_coupon.discount_type};
        }
        payload.demand.passenger = no_adults;
        await this.props.onUpdateBooking({...data, ...payload});
      }
    }
  }

  /**
   * getRouteTop
   * @return {*}
   */
  getRouteTop() {
    const data = JSON.parse(JSON.stringify(this.props.listRoutes));
    const type = this.state.service_type?.includes('SHUTTLE') ? 'SHUTTLE_BUS' : 'TAXI';

    let list = [];
    if (data.length > 0) {
      if (this.state.activeTab === 'TIME') {
        list = data.sort(dynamicSort('estimate_start_time'));
      } else if (this.state.activeTab === 'COST') {
        list = data.sort(dynamicSort('estimate_total_cost'));
      } else {
        list = data.sort(dynamicSort('transfer_count'));
      }
      const shuttle = this.getTopBy(list, type);
      return shuttle;
    }
    return null;
  }

  /**
   * toPageDetail
   * @param {int} id
   */
  toPageDetail = (id) => {
    const path = ROUTE.LAYOUT + ROUTE.RESERVATION_CREATE_TAXI_DETAIL + '/' + id;
    redirectRouter(this.props, path);
  };

  /**
   * linkGoogleMap
   */
  linkGoogleMap = async () => {
    const payload = {
      vehicle_uuid: 'string',
      external_vehicle_id: this.props.listRoutes[0]?.routes?.find((e) => e.type === 'SHUTTLE_BUS')?.estimate_vehicle_id,
      transportation_type: 'SHUTTLE_BUS',
    };
    const data = await getVehicleInfo(payload);
    if (data && data.status === 200) window.open(`https://maps.google.com/?q=${data?.result?.lat},${data?.result?.lon}`, '_blank');
  };

  /**
   * getTopBy
   * @param {*} list
   * @param {*} check
   * @return {boolean}
   */
  getTopBy(list, check) {
    for (let i = 0; i < list.length; i++) {
      for (let j = 0; j < list[i].routes.length; j++) {
        if (list[i].routes[j].type === check) {
          list[i].type = check;
          return list[i];
        }
      }
    }
    return false;
  }

  /**
   * getRouteShuttleBusDetail
   * @param {*} item
   * @return {HTML}
   */
  getRouteShuttleBusDetail(item) {
    const {t} = this.props;
    return (
      <Grid container spacing={3} alignItems="flex-start" className="border_bottom">
        <Grid className="space_M">
          <div className="item_black height_80_p border_black_2p relative">
            <div className="left">
              <h3>発</h3>
            </div>
            <div className="right">
              <h3>{roundedAndConvertTimeByZoneId(item.estimate_start_time, item?.zone_id, TIME_FORMAT)}</h3>
            </div>
            <div className="middle_line_right"></div>
          </div>
        </Grid>
        {item.routes.map((route, index) => {
          if (route.type.includes('WALKING')) {
            return (
              <Grid className="space_M" key={index}>
                <div className="item_taxi_circle relative">
                  <div className="circle background_black">
                    <img alt="" src={process.env.PUBLIC_URL + '/images/reservation/walking.svg'} />
                  </div>
                  <div className="right"></div>
                  <div className="middle_line_right"></div>
                </div>
                <div className="text_center">
                  <h3>
                    {getSubTime(route.estimate_end_time, route.estimate_start_time)}
                    {t('common.minute')}
                  </h3>
                </div>
              </Grid>
            );
          }
          if (route.type.includes('SHUTTLE_BUS')) {
            return (
              <Grid className="space_M" key={index}>
                <div className="item_orange height_80_p border_orange_2p relative">
                  <div className="middle_line_left"></div>
                  <div className="left">
                    <img alt="" src={process.env.PUBLIC_URL + '/images/reservation/shuttle_bus.svg'} />
                  </div>
                  <div className="right">
                    <h3>Mobi</h3>
                  </div>
                  <div className="middle_line_right"></div>
                </div>
                <div className="text_center font_color_orange">
                  <h3>
                    {getSubTime(route.estimate_end_time, route.estimate_start_time)}
                    {t('common.minute')}
                  </h3>
                </div>
              </Grid>
            );
          }
          return route;
        })}
        <Grid className="space_M">
          <div className="item_black height_80_p border_black_2p relative">
            <div className="middle_line_left"></div>
            <div className="left">
              <h3>着</h3>
            </div>
            <div className="right">
              <h3>{roundedAndConvertTimeByZoneId(item.estimate_arrival_time, item?.zone_id, TIME_FORMAT)}</h3>
            </div>
          </div>
        </Grid>
      </Grid>
    );
  }

  /**
   * getRouteTaxiDetail
   * @param {*} item
   * @return {HTML}
   */
  getRouteTaxiDetail(item) {
    const {t} = this.props;
    return (
      <>
        <Grid container spacing={3} alignItems="flex-start" className="border_bottom">
          <Grid className="space_M">
            <div className="item_taxi_circle relative">
              <div className="circle">
                <h3>待</h3>
              </div>
              <div className="right"></div>
              <div className="middle_line_right"></div>
            </div>
            <div className="text_center">
              <h3>
                {item.routes.length > 0 ? getSubTime(item.routes[0].estimate_end_time, item.routes[0].estimate_start_time) : ''}
                {t('common.minute')}
              </h3>
            </div>
          </Grid>
          <Grid className="space_M">
            <div className="item_black height_80_p border_black_2p relative">
              <div className="left">
                <h3>発</h3>
              </div>
              <div className="right">
                <h3>{roundedAndConvertTimeByZoneId(item.estimate_start_time, item?.zone_id, TIME_FORMAT)}</h3>
              </div>
              <div className="middle_line_right"></div>
            </div>
          </Grid>
          {item.routes.map(
              (route, index) =>
                route.type === 'TAXI' && (
                  <Grid className="space_M" key={index}>
                    <div className="item_cyan height_80_p border_cyan_2p relative">
                      <div className="middle_line_left"></div>
                      <div className="left">
                        <img alt="" src={process.env.PUBLIC_URL + '/images/reservation/taxi.svg'} />
                      </div>
                      <div className="right">
                        <h3>{this.props.t('reservationManagement.taxi')}</h3>
                      </div>
                      <div className="middle_line_right"></div>
                    </div>
                    <div className="text_center color_cyan">
                      <h3>
                        {getSubTime(route.estimate_end_time, route.estimate_start_time)}
                        {t('common.minute')}
                      </h3>
                    </div>
                  </Grid>
                ),
          )}
          <Grid className="space_M">
            <div className="item_black height_80_p border_black_2p relative">
              <div className="middle_line_left"></div>
              <div className="left">
                <h3>着</h3>
              </div>
              <div className="right">
                <h3>{roundedAndConvertTimeByZoneId(item.estimate_arrival_time, item?.zone_id, TIME_FORMAT)}</h3>
              </div>
            </div>
          </Grid>
        </Grid>
      </>
    );
  }

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t, bookingInfo, searchBooking, isLoading} = this.props;

    return (
      <LoadingOverlay active={isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('reservationManagement.taxi_on_demand')}</h3>
              </Grid>
              <Grid container alignItems="center" justifyContent="flex-end" item xs={6}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<RotateLeftIcon />} onClick={() => this.searchRoutes()}>
                  {t('common.btnReSearch')}
                </Button>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />} onClick={this.props.history.goBack}>
                  {t('common.btnReturn')}
                </Button>
              </Grid>
            </Grid>
          </Container>

          {/* step 2 */}
          <Container maxWidth="xl">
            <Box p={2} m={2}>
              <Card raised>
                <Container maxWidth="xl">
                  <Box p={1} m={2}>
                    <Grid container spacing={3} alignItems="flex-start">
                      <Grid item xs={12} className="bg_black">
                        {t('reservationManagement.number_passenger')}: {Number(this.state.passenger) + (searchBooking?.infantNumber || 0)} {t('reservationManagement.people')}
                      </Grid>
                      <Grid item xs={12}>
                        <Tabs>
                          <TabList>
                            <Tab
                              onClick={() => {
                                this.onActive('TIME');
                              }}
                            >
                              <img className="tab_img" src={process.env.PUBLIC_URL + '/images/reservation/1372.svg'} alt="" />
                              <div>{t('reservationManagement.early_order')}</div>
                            </Tab>
                            <Tab
                              onClick={() => {
                                this.onActive('COST');
                              }}
                            >
                              <img className="tab_img" src={process.env.PUBLIC_URL + '/images/reservation/1371.svg'} alt="" />
                              <div>{t('reservationManagement.cheapest')}</div>
                            </Tab>
                            <Tab
                              onClick={() => {
                                this.onActive('TRANSFER');
                              }}
                            >
                              <img className="tab_img" src={process.env.PUBLIC_URL + '/images/reservation/1375.svg'} alt="" />
                              <div>{t('reservationManagement.easy_order')}</div>
                            </Tab>
                          </TabList>

                          <TabPanel>
                            <Box mb={2} pt={2} pb={2} mt={2}>
                              <Grid container spacing={3} alignItems="flex-start">
                                {/* top 1 */}
                                {this.getRouteTop() && this.getRouteTop().type.includes('SHUTTLE_BUS') && (
                                  <Grid item xs={3} style={{display: 'grid'}}>
                                    <Card onClick={() => this.toPageDetail(this.getRouteTop().id)}>
                                      <CardActionArea>
                                        <CardContent>
                                          <img alt="" src={process.env.PUBLIC_URL + '/images/reservation/w-mobi-i.svg'} />
                                          <Typography variant="h6" component="h6">
                                            {t('reservationManagement.estimated_required_time')}:
                                            {getSubTime(this.getRouteTop().estimate_arrival_time, this.getRouteTop().estimate_start_time)}
                                            {t('common.minute')}
                                          </Typography>
                                          <Typography variant="h6" component="h6">
                                            {t('reservationManagement.fee')}:{customDisplayCurrency(this.getRouteTop().estimate_total_cost, bookingInfo?.currency_code)}
                                          </Typography>
                                          <img alt="" className="content_media_img" src={process.env.PUBLIC_URL + '/images/reservation/car-l.svg'} />
                                        </CardContent>
                                      </CardActionArea>
                                    </Card>
                                    <Button target="_blank" variant="outlined" color="primary" href="" onClick={() => this.linkGoogleMap()}>
                                      {t('newReservation.linkMap1')}
                                    </Button>
                                  </Grid>
                                )}

                                {/* top 2 taxi */}
                                {this.getRouteTop() && this.getRouteTop().type.includes('TAXI') && (
                                  <Grid item xs={3} style={{display: 'grid'}}>
                                    <Card onClick={() => this.toPageDetail(this.getRouteTop().id)}>
                                      <CardActionArea>
                                        <CardContent>
                                          <Typography variant="h6" component="h6">
                                            {t('reservationManagement.taxi')}
                                          </Typography>
                                          <Typography variant="h6" component="h6">
                                            {t('reservationManagement.estimated_required_time')}:
                                            {getSubTime(this.getRouteTop().estimate_arrival_time, this.getRouteTop().estimate_start_time)}
                                            {t('common.minute')}
                                          </Typography>
                                          <Typography variant="h6" component="h6">
                                            {t('reservationManagement.charge_forecast')}:{customDisplayCurrency(this.getRouteTop().estimate_total_cost, bookingInfo?.currency_code)}
                                          </Typography>
                                          <img alt="" className="content_media_img" src={process.env.PUBLIC_URL + '/images/reservation/car-taxi.png'} />
                                        </CardContent>
                                      </CardActionArea>
                                    </Card>
                                    <Button target="_blank" variant="outlined" color="primary" href="" onClick={() => this.linkGoogleMap()}>
                                      {t('newReservation.linkMap1')}
                                    </Button>
                                  </Grid>
                                )}
                              </Grid>
                            </Box>
                            {!this.state.errorWaypointNotAssociate && !this.props.isLoading && this.props.listRoutes.length === 0 && (
                              <Grid container spacing={3} alignItems="flex-start">
                                <Grid item xs={12}>
                                  {t('common.noData')}
                                </Grid>
                              </Grid>
                            )}
                            {this.props.listRoutes.sort(dynamicSort('estimate_start_time')).map((item, index) => {
                              return (
                                <div className="cursor_pointer" onClick={() => this.toPageDetail(item.id)} key={index}>
                                  <Grid className="border_bottom"></Grid>
                                  <Box mb={2} pt={2} pb={2} mt={2}>
                                    <Grid container spacing={3} alignItems="flex-start">
                                      <Grid item xs={12}>
                                        <Typography variant="h6" component="h6">
                                          {t('reservationManagement.time_required')}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Typography display="inline" variant="h3" component="h3">
                                          {getSubTime(item.estimate_arrival_time, item.estimate_start_time)}
                                          {t('common.minute')}&nbsp;&nbsp;&nbsp;
                                        </Typography>
                                        <Typography display="inline" variant="h6" component="h6">
                                          {t('reservationManagement.charge_forecast')}:{customDisplayCurrency(item.estimate_total_cost, bookingInfo?.currency_code)}
                                          &nbsp;&nbsp;&nbsp; {t('reservationManagement.number_transfer')}：{item.transfer_count}
                                          {t('reservationManagement.counts')}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    {this.state.service_type === 'TAXI' ? this.getRouteTaxiDetail(item) : this.getRouteShuttleBusDetail(item)}
                                  </Box>
                                </div>
                              );
                            })}

                            <Grid>
                              {this.state.errorWaypointNotAssociate ? (
                                <Typography variant="h6" component="h6">
                                  {t('reservation.error_waypoint_not_associate')}
                                </Typography>
                              ) : (
                                <Typography variant="h6" component="h6">
                                  {t('reservation.please_select_router')}
                                </Typography>
                              )}
                            </Grid>
                          </TabPanel>

                          <TabPanel>
                            <Box mb={2} pt={2} pb={2} mt={2}>
                              <Grid container spacing={3} alignItems="flex-start">
                                {/* top 1 */}
                                {this.getRouteTop() && this.getRouteTop().type.includes('SHUTTLE') && (
                                  <Grid item xs={3} style={{display: 'grid'}}>
                                    <Card onClick={() => this.toPageDetail(this.getRouteTop().id)}>
                                      <CardActionArea>
                                        <CardContent>
                                          <img alt="" src={process.env.PUBLIC_URL + '/images/reservation/w-mobi-i.svg'} />
                                          <Typography variant="h6" component="h6">
                                            {t('reservationManagement.charge_forecast')}:{customDisplayCurrency(this.getRouteTop().estimate_total_cost, bookingInfo?.currency_code)}
                                          </Typography>
                                          <Typography variant="h6" component="h6">
                                            {t('reservationManagement.estimated_required_time')}:
                                            {getSubTime(this.getRouteTop().estimate_arrival_time, this.getRouteTop().estimate_start_time)}
                                            {t('common.minute')}
                                          </Typography>
                                          <img alt="" className="content_media_img" src={process.env.PUBLIC_URL + '/images/reservation/car-l.svg'} />
                                        </CardContent>
                                      </CardActionArea>
                                    </Card>
                                    <Button target="_blank" variant="outlined" color="primary" href="" onClick={() => this.linkGoogleMap()}>
                                      {t('newReservation.linkMap1')}
                                    </Button>
                                  </Grid>
                                )}

                                {/* top 2 taxi */}
                                {this.getRouteTop() && this.getRouteTop().type.includes('TAXI') && (
                                  <Grid item xs={3} style={{display: 'grid'}}>
                                    <Card onClick={() => this.toPageDetail(this.getRouteTop().id)}>
                                      <CardActionArea>
                                        <CardContent>
                                          <Typography variant="h6" component="h6">
                                            {t('reservationManagement.taxi')}
                                          </Typography>
                                          <Typography variant="h6" component="h6">
                                            {t('reservationManagement.charge_forecast')}:{customDisplayCurrency(this.getRouteTop().estimate_total_cost, bookingInfo?.currency_code)}
                                          </Typography>
                                          <Typography variant="h6" component="h6">
                                            {t('reservationManagement.estimated_required_time')}:
                                            {getSubTime(this.getRouteTop().estimate_arrival_time, this.getRouteTop().estimate_start_time)}
                                            {t('common.minute')}
                                          </Typography>
                                          <img alt="" className="content_media_img" src={process.env.PUBLIC_URL + '/images/reservation/car-taxi.png'} />
                                        </CardContent>
                                        <Button target="_blank" variant="outlined" color="primary" href="" onClick={() => this.linkGoogleMap()}>
                                          {t('newReservation.linkMap1')}
                                        </Button>
                                      </CardActionArea>
                                    </Card>
                                  </Grid>
                                )}
                              </Grid>
                            </Box>
                            {!this.props.isLoading && this.props.listRoutes.length === 0 && (
                              <Grid container spacing={3} alignItems="flex-start">
                                <Grid item xs={12}>
                                  {t('common.noData')}
                                </Grid>
                              </Grid>
                            )}
                            {this.props.listRoutes.sort(dynamicSort('estimate_total_cost')).map((item, index) => {
                              return (
                                <div key={index}>
                                  <Grid className="border_bottom"></Grid>
                                  <Box mb={2} pt={2} pb={2} mt={2}>
                                    <Grid container spacing={3} alignItems="flex-start">
                                      <Grid item xs={12}>
                                        <Typography variant="h6" component="h6">
                                          {t('reservationManagement.charge_forecast')}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Typography display="inline" variant="h3" component="h3">
                                          {customDisplayCurrency(this.getRouteTop().estimate_total_cost, bookingInfo?.currency_code)}&nbsp;&nbsp;&nbsp;
                                        </Typography>
                                        <Typography display="inline" variant="h6" component="h6">
                                          {t('reservationManagement.time_required')}:{getSubTime(item.estimate_arrival_time, item.estimate_start_time)}
                                          {t('common.minute')} &nbsp;&nbsp;&nbsp; {t('reservationManagement.number_transfer')}：{item.transfer_count}
                                          {t('reservationManagement.counts')}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    {this.state.service_type === 'TAXI' ? this.getRouteTaxiDetail(item) : this.getRouteShuttleBusDetail(item)}
                                  </Box>
                                </div>
                              );
                            })}

                            <Grid>
                              {this.state.errorWaypointNotAssociate ? (
                                <Typography variant="h6" component="h6">
                                  {t('reservation.error_waypoint_not_associate')}
                                </Typography>
                              ) : (
                                <Typography variant="h6" component="h6">
                                  {t('reservation.please_select_router')}
                                </Typography>
                              )}
                            </Grid>
                          </TabPanel>

                          <TabPanel>
                            <Box mb={2} pt={2} pb={2} mt={2}>
                              <Grid container spacing={3} alignItems="flex-start">
                                {/* top 1 */}
                                {this.getRouteTop() && this.getRouteTop().type.includes('SHUTTLE') && (
                                  <Grid item xs={3} style={{display: 'grid'}}>
                                    <Card onClick={() => this.toPageDetail(this.getRouteTop().id)}>
                                      <CardActionArea>
                                        <CardContent>
                                          <img alt="" src={process.env.PUBLIC_URL + '/images/reservation/w-mobi-i.svg'} />
                                          <Typography variant="h6" component="h6">
                                            {t('reservationManagement.number_transfer')}:{this.getRouteTop().transfer_count}
                                            {t('reservationManagement.counts')}
                                          </Typography>
                                          <Typography variant="h6" component="h6">
                                            {t('reservationManagement.fee')}:{customDisplayCurrency(this.getRouteTop().estimate_total_cost, bookingInfo?.currency_code)}
                                          </Typography>
                                          <img alt="" className="content_media_img" src={process.env.PUBLIC_URL + '/images/reservation/car-l.svg'} />
                                        </CardContent>
                                      </CardActionArea>
                                    </Card>
                                    <Button target="_blank" variant="outlined" color="primary" href="" onClick={() => this.linkGoogleMap()}>
                                      {t('newReservation.linkMap1')}
                                    </Button>
                                  </Grid>
                                )}

                                {/* top 2 taxi */}
                                {this.getRouteTop() && this.getRouteTop().type.includes('TAXI') && (
                                  <Grid item xs={3} style={{display: 'grid'}}>
                                    <Card onClick={() => this.toPageDetail(this.getRouteTop().id)}>
                                      <CardActionArea>
                                        <CardContent>
                                          <Typography variant="h6" component="h6">
                                            {t('reservationManagement.taxi')}
                                          </Typography>
                                          <Typography variant="h6" component="h6">
                                            {t('reservationManagement.number_transfer')}:{this.getRouteTop().transfer_count}
                                            {t('reservationManagement.counts')}
                                          </Typography>
                                          <Typography variant="h6" component="h6">
                                            {t('reservationManagement.fee')}:{customDisplayCurrency(this.getRouteTop().estimate_total_cost, bookingInfo?.currency_code)}
                                          </Typography>
                                          <img alt="" className="content_media_img" src={process.env.PUBLIC_URL + '/images/reservation/car-taxi.png'} />
                                        </CardContent>
                                      </CardActionArea>
                                    </Card>
                                    <Button target="_blank" variant="outlined" color="primary" href="" onClick={() => this.linkGoogleMap()}>
                                      {t('newReservation.linkMap1')}
                                    </Button>
                                  </Grid>
                                )}
                              </Grid>
                            </Box>
                            {!this.props.isLoading && this.props.listRoutes.length === 0 && (
                              <Grid container spacing={3} alignItems="flex-start">
                                <Grid item xs={12}>
                                  {t('common.noData')}
                                </Grid>
                              </Grid>
                            )}
                            {this.props.listRoutes.sort(dynamicSort('transfer_count')).map((item, index) => {
                              return (
                                <div key={index}>
                                  <Grid className="border_bottom"></Grid>
                                  <Box mb={2} pt={2} pb={2} mt={2}>
                                    <Grid container spacing={3} alignItems="flex-start">
                                      <Grid item xs={12}>
                                        <Typography variant="h6" component="h6">
                                          {t('reservationManagement.number_transfer')}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Typography display="inline" variant="h3" component="h3">
                                          {item.transfer_count}
                                          {t('reservationManagement.counts')} &nbsp;&nbsp;&nbsp;
                                        </Typography>
                                        <Typography display="inline" variant="h6" component="h6">
                                          {t('reservationManagement.charge_forecast')}:{customDisplayCurrency(this.getRouteTop().estimate_total_cost, bookingInfo?.currency_code)}
                                          &nbsp;&nbsp;&nbsp; {t('reservationManagement.time_required')}:{getSubTime(item.estimate_arrival_time, item.estimate_start_time)}
                                          {t('common.minute')}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    {this.state.service_type === 'TAXI' ? this.getRouteTaxiDetail(item) : this.getRouteShuttleBusDetail(item)}
                                  </Box>
                                </div>
                              );
                            })}

                            <Grid>
                              {this.state.errorWaypointNotAssociate ? (
                                <Typography variant="h6" component="h6">
                                  {t('reservation.error_waypoint_not_associate')}
                                </Typography>
                              ) : (
                                <Typography variant="h6" component="h6">
                                  {t('reservation.please_select_router')}
                                </Typography>
                              )}
                            </Grid>
                          </TabPanel>
                        </Tabs>
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              </Card>
            </Box>
          </Container>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const params = queryString?.parse(ownProps.location.search);
  const type = params?.service_type?.includes('SHUTTLE') ? 'SHUTTLE_BUS' : 'TAXI';
  const routeFilter = [];
  state.reservation.listRoutes.forEach((route) => {
    const data = route.routes.filter((item) => item.type.includes(type));
    if (data && data.length > 0) {
      routeFilter.push(route);
    }
  });
  return {
    isLoading: state.reservation.isLoading,
    listRoutes: routeFilter,
    bookingInfo: state.reservation.bookingInfo,
    searchBooking: state.reservation.searchBooking,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchRoutesBooking: (params, props) => dispatch(searchRoutesBooking(params, props)),
    onUpdateBooking: (bookingData) => {
      dispatch(updateBooking(bookingData));
    },
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
