import {BUSINESS_ROLE} from '../common/constant';
import store from '../stores/configureStore';

/**
 * Check if the business role of current logged in account is GLOBAL
 * @return {Boolean}
 */
export const isRoleGlobal = () => {
  return store.getState().common.principal.business_role_type === BUSINESS_ROLE[0].id;
};

/**
 * Check if the business role of current logged in account is COUNTRY
 * @return {Boolean}
 */
export const isRoleCountry = () => {
  return store.getState().common.principal.business_role_type === BUSINESS_ROLE[1].id;
};

/**
 * Check if the business role of current logged in account is BUSINESS
 * @return {Boolean}
 */
export const isRoleBusiness = () => {
  return store.getState().common.principal.business_role_type === BUSINESS_ROLE[2].id;
};
