import React, {Component} from 'react';

import {
  Card,
  Container,
  Grid,
  Paper,
  Table,
  TableCell,
  TableRow,
  Button,
  Select,
  TextField,
  MenuItem,
  FormControl,
  TableHead,
  TableBody,
  Dialog,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import DetailIcon from '@material-ui/icons/Description';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {ROUTE, ROWS_PER_PAGE_OPTIONS, DATA_NULL, MOBI_TYPES, GEOFENCE_STATUS, PERMISSION_ACTIONS, ROUTE_TYPE} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {searchAreaGeofence} from '../../../stores/area_geofence/action';
import {getAllCountryCode} from '../../../stores/common/actions';
import {getListGeofence} from '../../../stores/common/actions';
import {searchJitRoute, deleteJitRoute, getWaypointStops} from '../../../stores/jit_setting/action';
import {backForwardRouter, onChangeSelect, onChangeTextField} from '../../../utils/common';
import {changeUrlParams, getQueryStringFromObject, getUrlParams} from '../../../utils/common';
import './style.css';
import {isRoleBusiness, isRoleCountry, isRoleGlobal} from '../../../utils/role';

/**
 * Search JIT Routes
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      routeId: '',
      countryId: !isRoleGlobal() ? props.principal.country_id : '',
      areaId: '',
      geofenceId: '',
      type: '',
      pickupLocationId: '',
      dropoffLocationId: '',
      name: '',
      enable: null,
      routeDeleteCode: '',
      routeDeleteId: '',

      listTransitStop: [],
      result: [],
      listArea: [],
      listGeofence: [],
      isSearch: false,
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,
      openConfirmDeleteModal: false,
      isLoading: false,
      only_app: false,
      routeType: null,
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    this.props.getAllCountryCode();
    this.props.getListGeofence().then((response) => this.setState({listGeofence: response}));
    this.state.countryId && this.handleListArea(this.state.countryId);
    if (this.props.location.search) {
      const queryParamsRaw = getUrlParams(this.props.location.search);
      if (isRoleGlobal() ? true : Number(queryParamsRaw?.country_id) === Number(this.props.principal.country_id)) {
        const queryParams = {
        ...queryParamsRaw,
        countryId: Number(queryParamsRaw.country_id) || '',
        areaId: queryParamsRaw.geofence_area_id || '',
        geofenceId: queryParamsRaw.geofence_id || '',
        pickupLocationId: queryParamsRaw.from_stop_id || '',
        dropoffLocationId: queryParamsRaw.to_stop_id || '',
        routeId: queryParamsRaw.route_code || '',
        routeType: queryParamsRaw.routeType || null,
        only_app: queryParamsRaw.only_app || false,
      };
      this.setState(queryParams, () => {
        this.state.countryId && this.handleListArea(this.state.countryId);
        this.handleSearch();
      });
      } else {
        backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.HOME);
      }
    }
  }

  /**
   * handleSearch
   * @param {Boolean} reset
   */
  handleSearch = async (reset) => {
    // if (!this.props.actions.includes(PERMISSION_ACTIONS.SEARCH)) return;
    reset && (await this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]}));
    const {only_app, routeType, routeId, countryId, areaId, geofenceId, type, pickupLocationId, dropoffLocationId, name, enable, currentPage, rowsPerPage} = this.state;
    let payLoad = {
      country_id: countryId,
      geofence_area_id: areaId,
      geofence_id: geofenceId !== '' ? geofenceId : null,
      enable: enable,
      from_stop_id: pickupLocationId || null,
      to_stop_id: dropoffLocationId || null,
      route_code: routeId.trim() || null,
      only_old_app: only_app,
      jit_route_type: routeType,
    };
    const queryParams = {
      page: currentPage,
      size: rowsPerPage,
      sort: 'last_modified_at,desc',
    };
    type !== '' && (payLoad = {...payLoad, group_type: type});
    name.trim() !== '' && (payLoad = {...payLoad, name: name.trim()});
    this.props.searchJitRoute(payLoad, queryParams).then((response) => {
      this.setState(
        {
          result: response?.content?.map((item) => {
            return {
              ...item,
              pickupDisplayName: !_.isEmpty(item.from_stop_name_translations) ? item.from_stop_name_translations[this.props.i18n.language] : item.from_stop_name || '',
              dropoffDisplayName: !_.isEmpty(item.to_stop_name_translations) ? item.to_stop_name_translations[this.props.i18n.language] : item.to_stop_name || '',
            };
          }),
          totalRows: response?.totalSize || 0,
          isSearch: true,
        },
        () => {
          const queryParamsToChange = {
            ...payLoad,
            ...queryParams,
            currentPage: String(currentPage),
          };
          changeUrlParams(queryParamsToChange);
          const newSearchParams = getQueryStringFromObject(queryParamsToChange);
          this.setState({searchParams: newSearchParams});
        },
      );
    });
  };

  /**
   * reset
   */
  reset = () => {
    this.setState({
      countryId: !isRoleGlobal() ? this.props.principal.country_id : '',
      areaId: '',
      geofenceId: '',
      type: '',
      pickupLocationId: '',
      dropoffLocationId: '',
      routeId: '',
      name: '',
      enable: null,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      listTransitStop: [],
      routeType: null,
      only_app: false,
    });
  };

  /**
   * onChangePagination
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearch);
  };

  /**
   * onChangeCountry
   * @param {*} e
   */
  onChangeCountry = (e) => {
    this.props.searchAreaGeofence({countryId: e.target.value}).then((response) => {
      this.setState({listArea: response?.content});
    });
    this.setState({
      countryId: e.target.value,
      geofenceId: '',
      areaId: '',
      pickupLocationId: '',
      dropoffLocationId: '',
      listTransitStop: [],
    });
  };

  /**
   * getListTransitStops
   */
  getListTransitStops = () => {
    if (!this.state.geofenceId) return;
    const geofence = this.state.listGeofence.find((item) => item.geofence_id === this.state.geofenceId);
    if (geofence.jit_home_to_work_sim_id === null && geofence.jit_work_to_home_sim_id === null) return;
    let simulationId = this.state.type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? geofence.jit_home_to_work_sim_id : geofence.jit_work_to_home_sim_id;
    if (!this.state.type) simulationId = null;
    this.props
      .getWaypointStops({
        serviceGroupIds: geofence?.service_group_id || null,
        simulationIds: simulationId,
        groupTypes: this.state.type,
        size: 100,
      })
      .then((response) => {
        let listTransitStopData = response?.transit_stops;
        if (simulationId) listTransitStopData = listTransitStopData?.filter((item) => item.point.coordinates[0] && item.point.coordinates[1]);
        const listTransitStop = listTransitStopData?.map((item) => {
          return {
            ...item,
            displayName: item.name_translations[this.props.i18n.language] || item.display_name || item.street_translations[this.props.i18n.language] || item.name,
          };
        });
        this.setState({listTransitStop});
      });
  };

  /**
   * deleteRoute
   */
  deleteRoute = async () => {
    this.closeConfirmDeleteModal();
    this.props.deleteJitRoute(this.state.routeDeleteCode, this.state.routeDeleteId).then(() => {
      this.handleSearch();
    });
  };

  /**
   * closeConfirmDeleteModal
   */
  closeConfirmDeleteModal = () => {
    this.setState({openConfirmDeleteModal: false});
  };

  /**
   * handleListArea
   * @param {*} countryId
   */
  handleListArea = (countryId) => {
    this.props.searchAreaGeofence({countryId: countryId}).then((response) => {
      isRoleCountry() && this.setState({listArea: response?.content});
      if (isRoleBusiness()) {
        const listGeofences = response?.content.filter((item1) => {
          const geofence = item1.geofences.filter((item2) => this.props.principal.geofence_ids.includes(item2));
          return geofence.length > 0;
        });
        this.setState({listArea: listGeofences});
      }
    });
  };

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {t, common, actions, principal} = this.props;
    const listGeofence = this.state.areaId ?
      this.state.listGeofence?.filter((item) => this.state.listArea.find((item) => item.id === Number(this.state.areaId))?.geofences?.includes(item.geofence_id)) :
      this.state.countryId ?
      this.state.listGeofence.filter((item) => item.country?.id === this.state.countryId) :
      this.state.listGeofence;
    const listType = MOBI_TYPES.filter((item) => item.id !== 'SHUTTLE_BUS_ON_DEMAND')?.map((item) => {
      return {
        id: item.id,
        i18n: item.i18n,
        enable:
          this.state.geofenceId &&
          (item.id === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
            !this.state.listGeofence.find((item) => item.geofence_id === this.state.geofenceId)?.jit_home_to_work_sim_id :
            !this.state.listGeofence.find((item) => item.geofence_id === this.state.geofenceId)?.jit_work_to_home_sim_id) ?
            false :
            true,
      };
    });
    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canRegister: actions.includes(PERMISSION_ACTIONS.REGISTER),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
      canDelete: actions.includes(PERMISSION_ACTIONS.DELETE),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container spacing={1} className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.jit_routes')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.JIT_SETTING}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          <LoadingOverlay active={this.state.isLoading || common?.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                {permission.canSearch && (
                  <>
                    <br></br>
                    <Paper className="search_table">
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                          {t('facility.searchTitle')}
                        </Grid>
                      </Grid>

                      {/* Country */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.country')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_200">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'countryId',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.countryId ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('common.country'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.countryId || ''}
                              onChange={this.onChangeCountry}
                              disabled={!isRoleGlobal()}
                            >
                              {common.country_code?.map((item, idx) => {
                                return (
                                  <MenuItem value={item.id} key={idx}>
                                    {t(item.country_code)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Area */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.area')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'areaId',
                              }}
                              disabled={!this.state.countryId}
                              displayEmpty
                              renderValue={
                                this.state.areaId ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('common.area'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.areaId || ''}
                              onChange={(event) => {
                                onChangeSelect(this, event);
                                this.setState({
                                  geofenceId: '',
                                  pickupLocationId: '',
                                  dropoffLocationId: '',
                                  listTransitStop: [],
                                });
                              }}
                            >
                              {this.state.listArea?.map((item, idx) => {
                                return (
                                  <MenuItem value={item.id} key={idx} className={!item.enable ? 'disable-option-custom' : ''}>
                                    {t(`${item.name}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Geofence */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('oneTimePrice.geofence')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'geofenceId',
                              }}
                              disabled={!this.state.areaId}
                              displayEmpty
                              renderValue={
                                this.state.geofenceId ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('oneTimePrice.geofence'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.geofenceId || ''}
                              onChange={async (e) => {
                                await onChangeSelect(this, e);
                                await this.setState({
                                  pickupLocationId: '',
                                  dropoffLocationId: '',
                                  listTransitStop: [],
                                });
                                this.getListTransitStops();
                              }}
                            >
                              {(isRoleBusiness() ?
                                listGeofence.filter((item1) => principal.geofence_ids.includes(item1.geofence_id)) :
                                listGeofence)?.map((item, idx) => {
                                return (
                                  <MenuItem value={item.geofence_id} key={idx} className={!item.enable ? 'disable-option-custom' : ''}>
                                    {t(`${item.name}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Type */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('business_plan.type')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_350">
                            {listType.map((item, index) => (
                              <FormControlLabel
                                key={index}
                                labelPlacement="end"
                                style={{marginRight: 20}}
                                disabled={!item.enable}
                                control={
                                  <Checkbox
                                    key={index}
                                    checked={this.state.type === item.id}
                                    onChange={async () => {
                                      await this.setState({
                                        type: this.state.type === item.id ? '' : item.id,
                                        pickupLocationId: '',
                                        dropoffLocationId: '',
                                        listTransitStop: [],
                                      });
                                      this.getListTransitStops();
                                    }}
                                    className="checkbox_radio"
                                  />
                                }
                                label={t(`${item.i18n}`)}
                              />
                            ))}
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* only old app */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('jit.onlyForOldApp')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={10}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_350">
                            <FormControlLabel
                              key={''}
                              labelPlacement="end"
                              style={{marginRight: 20}}
                              control={
                                <Checkbox
                                  name="only_app"
                                  value={this.state.only_app}
                                  checked={this.state.only_app}
                                  onChange={(event) => this.setState({only_app: event.target.checked})}
                                  className="checkbox_radio"
                                />
                              }
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Route Type */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('jit.routeType')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={10}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'routeType',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.routeType ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('jit.routeType'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.routeType || ''}
                              onChange={(event) => {
                                onChangeSelect(this, event);
                              }}
                            >
                              {ROUTE_TYPE.map((item, idx) => {
                                return (
                                  <MenuItem value={item.id} key={idx}>
                                    {t(`${item.i18n}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Pick up location */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('jit.pickup_location_name')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'pickupLocationId',
                              }}
                              disabled={!this.state.geofenceId}
                              displayEmpty
                              renderValue={
                                this.state.pickupLocationId ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('jit.pickup_location_name'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.pickupLocationId || ''}
                              onChange={(event) => {
                                onChangeSelect(this, event);
                              }}
                            >
                              {this.state.listTransitStop?.map((item, idx) => {
                                return (
                                  <MenuItem value={item.willer_stop_id} key={idx}>
                                    {t(`${item.name}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Drop off location */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('jit.dropoff_location_name')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'dropoffLocationId',
                              }}
                              disabled={!this.state.geofenceId}
                              displayEmpty
                              renderValue={
                                this.state.dropoffLocationId ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('jit.dropoff_location_name'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.dropoffLocationId || ''}
                              onChange={(event) => {
                                onChangeSelect(this, event);
                              }}
                            >
                              {this.state.listTransitStop?.map((item, idx) => {
                                return (
                                  <MenuItem value={item.willer_stop_id} key={idx}>
                                    {t(`${item.name}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Route Id */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('jit.route_id')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl>
                            <TextField
                              name="routeId"
                              className="field_size_20 field_min_size_200"
                              margin="dense"
                              placeholder={t('placeholder.required', {field: t('jit.route_id')})}
                              inputProps={{maxLength: 256}}
                              value={this.state.routeId || ''}
                              onChange={(event) => onChangeTextField(this, event)}
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Route name */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('jit.route_name')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl>
                            <TextField
                              name="name"
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              placeholder={t('placeholder.required', {field: t('jit.route_name')})}
                              inputProps={{maxLength: 256}}
                              value={this.state.name || ''}
                              onChange={(event) => onChangeTextField(this, event)}
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Enable */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.status')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'enable',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.enable !== null ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('common.status'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.enable !== null ? this.state.enable : ''}
                              onChange={(event) => onChangeSelect(this, event)}
                            >
                              {GEOFENCE_STATUS?.map((item, idx) => {
                                return (
                                  <MenuItem value={item.id} key={idx}>
                                    {t(`${item.i18n}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Paper>
                  </>
                )}
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_magin button_color" onClick={() => this.reset()} endIcon={<RotateLeftIcon />}>
                        {t('common.btnResetSearch')}
                      </Button>
                    )}
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {permission.canRegister && (
                      <Link
                        style={{textDecoration: 'none', marginRight: 5}}
                        to={{
                          pathname: ROUTE.LAYOUT + ROUTE.JIT_ROUTES_REGISTER,
                          state: {
                            from: this.props.location.pathname + '?' + this.state.searchParams,
                            from2:
                              this.props.location.pathname +
                              '?' +
                              getQueryStringFromObject({
                                page: 0,
                                size: 10,
                                sort: 'last_modified_at,desc',
                              }),
                          },
                        }}
                      >
                        <Button color="primary" variant="contained" className="button_magin button_color_green" endIcon={<AddIcon />}>
                          {t('common.btnRegister')}
                        </Button>
                      </Link>
                    )}
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_magin" onClick={() => this.handleSearch(true)} endIcon={<SearchIcon />}>
                        {t('common.btnSearch')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <br></br>
              </Container>
            </Card>
          </LoadingOverlay>
          <br></br>
          {this.state.isSearch && (
            <Card raised>
              <Container maxWidth="xl">
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={6}>
                    <h3>
                      {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                    </h3>
                  </Grid>
                </Grid>
                <LoadingOverlay active={this.state.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                  {this.state.result?.length > 0 && (
                    <>
                      <div className="scroll_area_700">
                        <Table size="small" aria-label="sticky table" stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell className="width_125p ant-table-cell-fix-left">{t('common.id')}</TableCell>
                              <TableCell className="width_75p">{t('common.country')}</TableCell>
                              <TableCell className="width_50p">{t('common.area')}</TableCell>
                              <TableCell className="width_150p">{t('oneTimePrice.geofence')}</TableCell>
                              <TableCell className="width_150p">{t('jit.route_name')}</TableCell>
                              <TableCell className="width_75p">{t('business_plan.type')}</TableCell>
                              <TableCell>{t('jit.pickup_location_name')}</TableCell>
                              <TableCell>{t('reservationManagement.dropoff_location')}</TableCell>
                              <TableCell className="width_75p">{t('common.status')}</TableCell>
                              {(permission.canEdit || permission.canDelete) && (
                                <TableCell className="width_125p" colSpan={4}>
                                  {t('common.action')}
                                </TableCell>
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.result?.map((row, index) => {
                              return (
                                <TableRow key={index} hover className="cursor_pointer">
                                  <TableCell scope="row">{row.route_code}</TableCell>
                                  <TableCell>{t(common.country_code?.find((country) => country.id === row.country_id).country_code)}</TableCell>
                                  <TableCell>{row.area_name || DATA_NULL}</TableCell>
                                  <TableCell>{row.geofence_name || DATA_NULL}</TableCell>
                                  <TableCell className="break_line">{row.name || DATA_NULL}</TableCell>
                                  <TableCell>{row.group_type !== null ? (row.group_type === 'SHUTTLE_BUS_JIT_WORK_TO_HOME' ? 'WTH' : 'HTW') : DATA_NULL}</TableCell>
                                  <TableCell>{row.pickupDisplayName || DATA_NULL}</TableCell>
                                  <TableCell>{row.dropoffDisplayName || DATA_NULL}</TableCell>
                                  <TableCell>{t(GEOFENCE_STATUS.find((item) => item.id === row.enable)?.i18n) || DATA_NULL}</TableCell>
                                  {(permission.canEdit || permission.canDelete) && (
                                    <TableCell colSpan={2}>
                                      <Grid container spacing={1}>
                                        {permission.canEdit && (
                                          <Grid container alignItems="center" item xs={12}>
                                            <Link
                                              style={{textDecoration: 'none'}}
                                              to={{
                                                pathname: ROUTE.LAYOUT + ROUTE.JIT_ROUTES_DETAIL + '/' + row.route_code,
                                                state: {
                                                  from: this.props.location.pathname + '?' + this.state.searchParams,
                                                  from2:
                                                    this.props.location.pathname +
                                                    '?' +
                                                    getQueryStringFromObject({
                                                      page: 0,
                                                      size: 10,
                                                      sort: 'last_modified_at,desc',
                                                    }),
                                                },
                                              }}
                                            >
                                              <Button color="primary" variant="contained" className="button_magin" endIcon={<DetailIcon />}>
                                                {t('common.btnEdit')}
                                              </Button>
                                            </Link>
                                          </Grid>
                                        )}
                                        {permission.canDelete && (
                                          <Grid container alignItems="center" item xs={12}>
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="button_magin button_color_red"
                                              endIcon={<DeleteIcon />}
                                              onClick={() =>
                                                this.setState({
                                                  routeDeleteCode: row.route_code,
                                                  routeDeleteId: row.route_id,
                                                  openConfirmDeleteModal: true,
                                                })
                                              }
                                            >
                                              {t('common.btnDelete')}
                                            </Button>
                                          </Grid>
                                        )}
                                      </Grid>
                                    </TableCell>
                                  )}
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </div>
                      <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                    </>
                  )}
                </LoadingOverlay>
                <br></br>
              </Container>
              <br></br>
            </Card>
          )}
          <br></br>
          {/* Confirm delete modal */}
          <Dialog
            open={this.state.openConfirmDeleteModal}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal
              onClickOk={this.deleteRoute}
              onClickCancel={this.closeConfirmDeleteModal}
              okButtonText={t('common.btnYes')}
              cancelButtonText={t('common.btnNo')}
              message={t('jit.confirm_delete_route')}
            ></SelectModal>
          </Dialog>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    deleteJitRoute: (code, id) => dispatch(deleteJitRoute(code, id)),
    searchJitRoute: (payload, queryParams) => dispatch(searchJitRoute(payload, queryParams)),
    getListGeofence: () => dispatch(getListGeofence()),
    getWaypointStops: (payload) => dispatch(getWaypointStops(payload)),
    searchAreaGeofence: (payload) => dispatch(searchAreaGeofence(payload)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
