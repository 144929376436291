import React, {Component} from 'react';

import {Card, Container, Grid, FormControl, TextField, Button, Table, TableCell, TableRow, Paper, TableBody, TableHead, Dialog, TableContainer} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import DetailIcon from '@material-ui/icons/Description';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {ROUTE, ROWS_PER_PAGE_OPTIONS} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {searchListCancellationFee, getCancellationFeeDetail} from '../../../stores/cancellation_fee/action';
import {onChangeTextField} from '../../../utils/common';

/**
 * Cancellation Fee Component
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      feeId: '',
      feeName: '',

      result: [],
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      isSearch: false,
      mockResult: [
        {
          id: 1,
          name: 'Cancel Fee 01',
          fee_from: '8',
          timeType_from: 'day',
          fee_to: '7',
          timeType_to: 'day',
          feeType: '%',
          cancelNumber: '0',
        },
        {
          id: 2,
          name: 'Cancel Fee 02',
          fee_from: '6',
          timeType_from: 'day',
          fee_to: '2',
          timeType_to: 'day',
          feeType: 'Yen',
          cancelNumber: '50',
        },
        {
          id: 3,
          name: 'Cancel Fee 03',
          fee_from: '1',
          timeType_from: 'day',
          fee_to: '0',
          timeType_to: 'day',
          feeType: '%',
          cancelNumber: '100',
        },
      ],
    };
  }

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearch);
  };

  /**
   * Handle search button
   * @param {boolean} reset
   */
  handleSearch(reset) {
    const {feeId, feeName} = this.state;
    const payload = {
      feeId: feeId,
      feeName: feeName,
    };
    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const {currentPage, rowsPerPage} = this.state;
    const queryParams = reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage};
    this.props.searchListCancellationFee(payload, queryParams, this.props).then((response) => {
      this.setState({
        result: response,
        totalRows: response?.totalSize,
        isSearch: true,
      });
    });
  }

  /**
   * handle Delete button
   * @param {int} id
   * @param {object} index
   */
  handleDelete(id, index) {}

  /**
   * Handle search reset button
   */
  reset = () => {
    this.setState({
      feeId: '',
      feeName: '',
    });
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t} = this.props;
    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('cancellation_fee.title')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Container>
        <br></br>
        <LoadingOverlay active={false} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper>
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('cancellation_fee.titleSearch')}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('cancellation_fee.id')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <TextField
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          name="feeId"
                          placeholder={t('cancellation_fee.id')}
                          variant="outlined"
                          onChange={(event) => onChangeTextField(this, event)}
                          value={this.state.feeId}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('cancellation_fee.name')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <TextField
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          name="feeName"
                          placeholder={t('cancellation_fee.name')}
                          variant="outlined"
                          onChange={(event) => onChangeTextField(this, event)}
                          value={this.state.feeName}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Paper>
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.reset()} endIcon={<RotateLeftIcon />}>
                      {t('common.btnResetSearch')}
                    </Button>
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    <Link
                      style={{textDecoration: 'none'}}
                      to={{
                        pathname: ROUTE.LAYOUT + ROUTE.PRODUCT_CANCELLATION_FEE_CREATE,
                      }}
                    >
                      <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />}>
                        {t('common.btnRegister')}
                      </Button>
                    </Link>
                    <Button color="primary" variant="contained" className="button_margin" endIcon={<SearchIcon />} onClick={() => this.handleSearch(true)}>
                      {t('common.btnSearch')}
                    </Button>
                  </Grid>
                </Grid>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>
          {this.state.isSearch ? (
            <Container maxWidth="xl">
              <Card raised>
                <Container maxWidth="xl">
                  <Grid container spacing={1}>
                    <Grid container alignItems="center" item xs={6}>
                      <h3>
                        {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                      </h3>
                    </Grid>
                  </Grid>
                  <LoadingOverlay active={false} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                    {this.state.mockResult.length > 0 ? (
                      <Paper>
                        <TableContainer className="scroll_area_500">
                          <Table size="small" aria-label="sticky table" stickyHeader>
                            <TableHead>
                              <TableRow>
                                <TableCell className="width_10">{t('cancellation_fee.id')}</TableCell>
                                <TableCell className="width_10">{t('cancellation_fee.name')}</TableCell>
                                <TableCell className="width_20" align="center"></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.mockResult.map((row, index) => (
                                <TableRow key={index} hover className="cursor_pointer">
                                  <TableCell>{row.id}</TableCell>
                                  <TableCell>{row.name}</TableCell>
                                  <TableCell align="center">
                                    <Link
                                      style={{textDecoration: 'none'}}
                                      to={{
                                        pathname: ROUTE.LAYOUT + ROUTE.PRODUCT_CANCELLATION_FEE_CHANGE + '/' + row.id,
                                      }}
                                    >
                                      <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                        {t('common.btnEdit')}
                                      </Button>
                                    </Link>
                                    <Button
                                      color="secondary"
                                      variant="contained"
                                      className="button_margin"
                                      endIcon={<DeleteIcon />}
                                      onClick={() => this.handleDelete(row.id, index)}
                                    >
                                      {t('common.btnDelete')}
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    ) : (
                      <></>
                    )}
                    <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                  </LoadingOverlay>
                  <Dialog
                    open={this.state.flag}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      zIndex: '1020',
                    }}
                  >
                    <SelectModal
                    //   onClickOk={this.handleButtonOk}
                    //   onClickCancel={this.handleButtonCancel}
                    //   message={this.state.message}
                    ></SelectModal>
                  </Dialog>
                </Container>
              </Card>
            </Container>
          ) : (
            <></>
          )}
        </LoadingOverlay>
        <br></br>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cancellation_fee: state.cancellation_fee,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchListCancellationFee: (payload, querryParams) => dispatch(searchListCancellationFee(payload, querryParams)),
    getCancellationFeeDetail: (params) => dispatch(getCancellationFeeDetail(params)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
