import React, {Component} from 'react';

import {Button, Card, Checkbox, Container, Dialog, FormControl, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import DetailIcon from '@material-ui/icons/Description';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {ROUTE, PERMISSION_ACTIONS} from '../../../common/constant';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {deleteQrPayment, getErrorQrPayment, searchErrorQrPayment} from '../../../stores/qr_payment/action';
import {convertDatetimeUTC, displayDateTime} from '../../../utils/datetime';

const noData = (t) => (
  <TableRow>
    <TableCell colSpan={8}>
      <div style={{textAlign: 'center', margin: 8}}>
        <svg width="80" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
          <g transform="translate(0 1)" fill="none" fillRule="evenodd">
            <ellipse fill="#f5f5f5" cx="32" cy="33" rx="32" ry="7"></ellipse>
            <g stroke="#d9d9d9" fillRule="nonzero">
              <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
              <path
                // eslint-disable-next-line max-len
                d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                fill="#fafafa"
              ></path>
            </g>
          </g>
        </svg>
        <p style={{color: 'rgba(0, 0, 0, 0.25)', margin: 5}}>{t('qrPayment.nodata')}</p>
      </div>
    </TableCell>
  </TableRow>
);

/**
 *  List error QrPayment view
 */
class QrPayment extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      errorQrPaymentSet: new Set(),
      selectedSet: new Set(),
      showDialog: false,
      searchPayload: {
        user_uuid: '',
        user_email: '',
        qr_payg_id: '',
      },
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    await this.props.getErrorQrPayment();
    this.setState({
      ...this.props.qr_payment,
    });
  };

  /**
   * @param {any} item of selected set
   * @return {boolean}
   */
  isSelected = (item) => this.state.selectedSet.has(item);

  /**
   * handleChecked
   * @param {boolean} checked
   * @param {any} item
   */
  handleChecked = (checked, item) => {
    if (checked) {
      this.state.selectedSet.add(item);
    } else {
      this.state.selectedSet.delete(item);
    }
    this.setState((state) => state);
  };

  /**
   * @param {Event} evt
   */
  onSelectAllClick = (evt) => {
    const checked = evt.target.checked;
    this.setState((state) => ({
      ...state,
      selectedSet: checked ? new Set([...this.state.errorQrPaymentSet]) : new Set(),
    }));
  };

  /**
   * handle delete click
   */
  handleDeleteClick = () => {
    this.setState((state) => ({
      ...state,
      showDialog: true,
    }));
  };

  /**
   * Handle delete request
   */
  handleDelete = async () => {
    const status = await this.props.deleteQrPayment([...this.state.selectedSet].map((item) => item.id));
    if (status === 200) {
      this.state.selectedSet.forEach((item) => this.state.errorQrPaymentSet.delete(item));
      this.state.selectedSet.clear();
    }
    this.closeDialog();
  };

  /**
   * close dialog
   */
  closeDialog = () => {
    this.setState((state) => ({
      ...state,
      showDialog: false,
    }));
  };

  /**
   * on change text field
   * @param {*} event
   */
  onChangeTextField = (event) => {
    const key = event.currentTarget.name;
    const value = event.currentTarget.value;
    this.setState((state) => ({
      ...state,
      searchPayload: {
        ...state.searchPayload,
        [key]: value,
      },
    }));
  };

  /**
   * handle search
   */
  handleSearch = async () => {
    await this.props.searchErrorQrPayment(this.state.searchPayload);
    this.setState({
      ...this.props.qr_payment,
    });
  };

  /**
   * resetSearchCondition
   */
  resetSearchCondition = () => {
    this.setState({
      searchPayload: {
        user_uuid: '',
        user_email: '',
        qr_payg_id: '',
      },
    });
  };

  /**
   * render component
   * @return {HTML}
   */
  render() {
    const {t, qr_payment, actions} = this.props;
    const {errorQrPaymentSet} = this.state;
    const numSelected = this.state.selectedSet.size;
    const rowCount = this.state.errorQrPaymentSet?.size;
    const dialogMsg = numSelected > 1 ? t('qr_payment.confirm_delete_items', {numSelected}) : t('qr_payment.confirm_delete_item');
    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('qrPayment.list_error_qr_payment')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Container>
        <br></br>
        <br></br>
        <Container maxWidth="xl">
          <Card raised>
            <Container maxWidth="xl">
              {permission.canSearch && (
                <>
                  <br></br>
                  <Paper className="search_table">
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                        {t('businessVehicle.searchTitle')}
                      </Grid>
                    </Grid>

                    {/* Payg ID*/}
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('qrPayment.payg_id')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl className="field_size_20 field_min_size_250">
                          <TextField
                            name="qr_payg_id"
                            className="field_size_20 field_min_size_250"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('qrPayment.payg_id')})}
                            inputProps={{maxLength: 256}}
                            value={this.state.searchPayload.qr_payg_id}
                            onChange={this.onChangeTextField}
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* User UUID */}
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('qrPayment.user_uuid')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl className="field_size_20 field_min_size_250">
                          <TextField
                            name="user_uuid"
                            className="field_size_20 field_min_size_250"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('qrPayment.user_uuid')})}
                            inputProps={{maxLength: 256}}
                            value={this.state.searchPayload.user_uuid}
                            onChange={this.onChangeTextField}
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* QR Payment user email */}
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('qrPayment.user_email')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl className="field_size_20 field_min_size_250">
                          <TextField
                            name="user_email"
                            className="field_size_20 field_min_size_250"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('qrPayment.user_email')})}
                            inputProps={{maxLength: 256}}
                            value={this.state.searchPayload.user_email}
                            onChange={this.onChangeTextField}
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Paper>
                </>
              )}

              <Grid container spacing={1}>
                <Grid container alignItems="center" justify="flex-start" item xs={3}>
                  {permission.canSearch && (
                    <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.resetSearchCondition} endIcon={<RotateLeftIcon />}>
                      {t('common.btnResetSearch')}
                    </Button>
                  )}
                </Grid>
                <Grid container alignItems="center" justify="flex-end" item xs={9}>
                  {permission.canSearch && (
                    <Button color="primary" variant="contained" className="button_margin" onClick={this.handleSearch} endIcon={<SearchIcon />}>
                      {t('common.btnSearch')}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Container>
          </Card>
        </Container>
        <br></br>
        <br></br>
        <LoadingOverlay active={qr_payment.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <div className="scroll_area_700">
                  <Table size="small" aria-label="sticky table" stickyHeader>
                    <TableHead>
                      <TableRow role="checkbox">
                        <TableCell>
                          {rowCount ? (
                            <Checkbox
                              indeterminate={numSelected > 0 && numSelected < rowCount}
                              checked={rowCount > 0 && numSelected === rowCount}
                              onChange={this.onSelectAllClick}
                            />
                          ) : (
                            <></>
                          )}
                        </TableCell>
                        <TableCell>{t('qrPayment.qr_pair_key')}</TableCell>
                        <TableCell>{t('qrPayment.payg_id')}</TableCell>
                        <TableCell>{t('qrPayment.user_email')}</TableCell>
                        <TableCell>{t('qrPayment.user_name')}</TableCell>
                        <TableCell>{t('qrPayment.use_date')}</TableCell>
                        <TableCell>{t('qrPayment.stop_name')}</TableCell>
                        <TableCell>{t('qrPayment.on_of_lag')}</TableCell>
                        {permission.canEdit && (
                          <TableCell>{t('common.action')}</TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowCount ?
                        [...errorQrPaymentSet].map((row, index) => {
                          const isItemSelected = this.isSelected(row);

                          return (
                            <TableRow
                              role="checkbox"
                              key={index}
                              hover
                              onClick={() => this.handleChecked(!isItemSelected, row)}
                              className="cursor_pointer"
                              selected={isItemSelected}
                            >
                              <TableCell scope="row" aria-checked={isItemSelected}>
                                <Checkbox checked={isItemSelected} onChange={(evt) => this.handleChecked(evt.target.checked, row)} />
                              </TableCell>
                              <TableCell scope="row">{row.get_onoff_pair_key}</TableCell>
                              <TableCell scope="row">{row.qr_payg_id}</TableCell>
                              <TableCell scope="row">{row.user_email || '---'}</TableCell>
                              <TableCell scope="row">{row.user_full_name || '---'}</TableCell>
                              <TableCell scope="row">{displayDateTime(convertDatetimeUTC(row.get_onoff_use_date))}</TableCell>
                              <TableCell scope="row">{row.stop_name || '---'}</TableCell>
                              <TableCell scope="row">{row.get_onoff_flag || '---'}</TableCell>
                              {permission.canEdit && (
                                <TableCell scope="row">
                                  <Link style={{textDecoration: 'none'}} to={{pathname: `/maas/error-qr-payment/${row.id}`}} >
                                    <Button endIcon={<DetailIcon />} variant="contained" className="button_margin" color="primary" onClick={this.handleDeleteClick}>
                                      {t('common.btnEdit')}
                                    </Button>
                                  </Link>
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        }) :
                        noData(t)}
                    </TableBody>
                  </Table>
                </div>
                <br></br>
                {rowCount ? (
                  <Button
                    endIcon={<DeleteIcon />}
                    variant="contained"
                    className={`button_margin ${numSelected ? 'button_color_red' : ''}`}
                    disabled={!numSelected}
                    color="primary"
                    onClick={this.handleDeleteClick}
                  >
                    Delete
                  </Button>
                ) : (
                  <></>
                )}
              </Container>
              <br></br>
            </Card>
          </Container>
        </LoadingOverlay>
        <br></br>

        <Dialog
          open={this.state.showDialog}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <SelectModal onClickOk={this.handleDelete} onClickCancel={this.closeDialog} message={dialogMsg}></SelectModal>
        </Dialog>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  qr_payment: state.qr_payment,
});

const mapDispatchToProps = (dispatch) => ({
  getErrorQrPayment: () => dispatch(getErrorQrPayment()),
  deleteQrPayment: (data) => dispatch(deleteQrPayment(data)),
  searchErrorQrPayment: (payload) => dispatch(searchErrorQrPayment(payload)),
});

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(QrPayment)));
