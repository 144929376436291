import {api} from '../services/api';

const getListJitOrderlUrl = `admin/jit-orders/search`;
const crudJitOrderUrl = `admin/jit-orders`;
const getJitShiftUrl = `admin/jit-orders/jit-shift/`;
const getJitAddresstUrl = `admin/jit-orders/jit-address/`;
const importJitCSVUrl = `admin/jit-orders/csv-import`;
const getAllJitAddressByGeofenceIdUrl = `admin/jit-orders/{geofenceId}/jit-all-address`;
const bookingJitOrdersUrl = `admin/jit-orders/multiple-booking`;
const getListSupplierByCountryUrl = `admin/suppliers/country`;

const getWaypointStopUrl = 'mass/v1/wstop/all';
const getGeofenceDetailUrl = 'mass/v1/geofences';
const searchJitRouteUrl = 'admin/jit/route';
const jitRouteUrl = 'mass/jit/route/config';
const searchJitRouteForAdminUrl = 'admin/jit/route/admin';

const searchJitByAreaIdUrl = 'mass/jit/route';

// jit setting api
export const searchListJitOrderApi = (payload) => {
  return api.put(getListJitOrderlUrl + `?size=${payload.size}&page=${payload.page}`, payload);
};

export const updateJitOrderApi = (payload) => {
  return api.put(crudJitOrderUrl, payload);
};

export const createJitOrderApi = (payload) => {
  return api.post(crudJitOrderUrl, payload);
};

export const getJitOrderDetailApi = (id) => {
  return api.get(crudJitOrderUrl + '/' + id);
};

export const deleteJitOrderlApi = (id) => {
  return api.delete(crudJitOrderUrl + '/' + id);
};

export const getJitShiftApi = (payload) => {
  return api.get(getJitShiftUrl, payload);
};

export const getJitAddresstApi = (payload) => {
  return api.get(getJitAddresstUrl, payload);
};

export const importJitCSVApi = (payload) => {
  return api.postMultiplePart(importJitCSVUrl, payload);
};

export const getAllJitAddressByGeofenceIdApi = (id) => {
  return api.get(getAllJitAddressByGeofenceIdUrl.replace('{geofenceId}', id));
};

export const bookingJitOrdersApi = (payload) => {
  return api.post(bookingJitOrdersUrl, payload);
};

export const getListSupplierByCountryApi = (payload) => {
  return api.get(getListSupplierByCountryUrl, payload);
};

export const searchJitRouteApi = (payload, queryParams) => {
  return api.search(searchJitRouteForAdminUrl, queryParams, payload);
};

export const getJitRouteDetailApi = (id) => {
  return api.get(`${searchJitRouteUrl}/${id}`);
};

export const deleteJitRouteApi = (code, id) => {
  return api.delete(`${searchJitRouteUrl}/${code}/${id}`);
};

export const createJitRouteApi = (payload) => {
  return api.post(jitRouteUrl, payload);
};

export const getWaypointStopApi = (payload) => {
  return api.get(getWaypointStopUrl, payload);
};

export const getGeofenceDetailApi = (uuid) => {
  return api.get(`${getGeofenceDetailUrl}/${uuid}`);
};

export const searchJitByAreaIdApi = (params) => {
  return api.get(searchJitByAreaIdUrl, params);
};

