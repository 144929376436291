import React, {Component} from 'react';

import {
  Container,
  Grid,
  Checkbox,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  FormControlLabel,
  Fade,
  Paper,
  Typography,
  IconButton,
  Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {DATA_NULL, DATE_FORMAT, ROUTE, ROWS_PER_PAGE_OPTIONS} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import {getMembers} from '../../../stores/business/action';
import {customDisplayCurrency} from '../../../utils/common';
import {formatUtc} from '../../../utils/date_time_utils';

/**
 * Account Type Management
 */
class ListMembersModal extends Component {
  /**
   * construtor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      activeChecked: true,
      inactiveChecked: true,
      distribution_status: ['SUCCESS', 'FAIL'],

      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount = () => {
    this.getListMembers();
  }

  /**
   * getListMembers
   * @param {Number} isReset
   */
  getListMembers = async (isReset = false) => {
    const {
      activeChecked,
      inactiveChecked,
      distribution_status,
      currentPage,
      rowsPerPage,
    } = this.state;
    if ((!activeChecked && !inactiveChecked) || distribution_status.length === 0) return;
    this.setState({
      currentPage: isReset ? 0 : currentPage,
      rowsPerPage: isReset ? ROWS_PER_PAGE_OPTIONS[0] : rowsPerPage,
    });
    const pageable = {
      page: isReset ? 0 : currentPage,
      size: isReset ? ROWS_PER_PAGE_OPTIONS[0] : rowsPerPage,
    };
    const params = (activeChecked && inactiveChecked) ?
      {
        ...pageable,
        distribution_status,
       } : {
      ...pageable,
      distribution_status,
      active: activeChecked ? true : false,
    };
    await this.props.getMembers(this.props.referrerId, params);
  };

  /**
   * handleChangeActive
   * @param {Event} event
   */
  handleChangeActive = (event) => {
    this.setState({activeChecked: event.target.checked}, () => this.getListMembers(true));
  }

  /**
   * handleChangeInactive
   * @param {Event} event
   */
  handleChangeInactive = (event) => {
    this.setState({inactiveChecked: event.target.checked}, () => this.getListMembers(true));
  }

  /**
   * handleCheckDistributionStatus
   * @param {Boolean} checked
   * @param {String} status
   */
  handleCheckDistributionStatus = (checked, status) => {
    const {distribution_status} = this.state;
    const idx = distribution_status.findIndex((stt) => stt === status);
    const distribution_status_copy = [...distribution_status];
    checked ? distribution_status_copy.push(status) : distribution_status_copy.splice(idx, 1);
    this.setState({distribution_status: distribution_status_copy}, () => this.getListMembers(true));
  }

  /**
   * handleChangePagination
   * @param {Number} currentPage
   * @param {Number} rowsPerPage
   */
  handleChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.getListMembers);
  }

  /**
   * Render distribution status from distribution status code
   * @param {String} statusCode
   * @param {Object} member
   * @return {String}
   */
  renderDistributionStatus = (statusCode, member) => {
    const {t} = this.props;
    if (statusCode === 'reason.distribution.fail.because.same.geofence.but.difference.discount') {
      return (
        <>
          {t('reason.distribution.fail.because.same.geofence.but.difference.discount.header')}
          <br />
          {member.note_data?.subscription_discounts?.map((sub) => (
            <div key={sub?.subscriptionId}>
              {t('reason.distribution.fail.because.same.geofence.but.difference.discount.content',
                {
                  geofenceName: sub?.geofenceName,
                  subCode: sub?.subscriptionCode,
                  adultAmount: customDisplayCurrency(sub?.adultAmount, sub?.currencyCode),
                  childAmount: customDisplayCurrency(sub?.childAmount, sub?.currencyCode),
                },
              )}
            </div>
          ))}
        </>
      );
    }
    return t(statusCode);
  };

  /**
   * @return {HTMLElement}
   */
  render() {
    const {activeChecked, inactiveChecked, distribution_status, rowsPerPage, currentPage} = this.state;
    const {t, members, isLoading, zoneId} = this.props;
    const {content, totalSize} = members;

    return (
      <Fade in={true}>
        <Paper className='modal_size_big'>
          <Container maxWidth='xl'>
            <br />
            <Grid container alignItems='center' className='page_header'>
              <Grid container item xs={6} alignItems='center'>
                <Typography variant='h6' gutterBottom>{t('businessPartner.list_associated_disassociated_members')}</Typography>
              </Grid>
              <Grid container item xs={6} alignItems='center' justifyContent='flex-end'>
                <IconButton onClick={this.props.onCloseModal}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Container maxWidth='xl'>
              <Grid container alignItems='center' spacing={2} className='row_form_item'>
                <Grid container item xs={6} lg={3} alignItems='center'>
                  <Typography>{t('businessPartner.status')}:</Typography>
                </Grid>
                <Grid container item xs={6} lg={6} alignItems='center'>
                  <Grid item xs={4}>
                    <FormControlLabel
                      label={t('businessPartner.active')}
                      control={
                        <Checkbox
                          name="activeChecked"
                          color='primary'
                          checked={activeChecked}
                          onChange={(event) => this.handleChangeActive(event)}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControlLabel
                      label={t('businessPartner.inActive')}
                      control={
                        <Checkbox
                          name='inactiveChecked'
                          color='primary'
                          checked={inactiveChecked}
                          onChange={(event) => this.handleChangeInactive(event)}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container alignItems='center' className='row_form_item' spacing={2}>
                <Grid container item xs={6} lg={3} alignItems='center'>
                  <Typography>{t('businessPartner.distribution_status')}:</Typography>
                </Grid>
                <Grid container item xs={6} lg={6}>
                  <Grid container alignItems='center'>
                    <Grid item xs={4}>
                      <FormControlLabel
                        label={t('common.success')}
                        labelPlacement='end'
                        control={
                          <Checkbox
                            color='primary'
                            checked={distribution_status.includes('SUCCESS')}
                            onClick={(event) => this.handleCheckDistributionStatus(event.target.checked, 'SUCCESS')}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControlLabel
                        label={t('common.fail')}
                        labelPlacement='end'
                        control={
                          <Checkbox
                            color='primary'
                            checked={distribution_status.includes('FAIL')}
                            onClick={(event) => this.handleCheckDistributionStatus(event.target.checked, 'FAIL')}
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <br />
              <LoadingOverlay active={isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                {((!activeChecked && !inactiveChecked) || distribution_status.length === 0) ? (
                  <Box pl={1}>
                    <Typography paragraph>
                      {t('common.searchResult')} {0} {t('common.case')}
                    </Typography>
                  </Box>
                ) : (
                  <>
                    <Box pl={1}>
                      <Typography paragraph>
                        {t('common.searchResult')} {totalSize} {t('common.case')}
                      </Typography>
                    </Box>
                    {totalSize > 0 && (
                      <Paper>
                        <div className='scroll_area_400'>
                          <Table stickyHeader className='layoutfix'>
                            <TableHead>
                              <TableRow>
                                <TableCell className='width_100p ant-table-cell-fix-left'>{t('business.memberCode')}</TableCell>
                                <TableCell className='width_150p'>{t('businessPartner.status')}</TableCell>
                                <TableCell className='width_250p'>{t('business.activatedDateTime')}</TableCell>
                                <TableCell className='width_250p'>{t('business.inactivatedDateTime')}</TableCell>
                                <TableCell className='width_150p'>{t('business.planCode')}</TableCell>
                                <TableCell className='width_150p'>{t('businessPartner.distribution_status')}</TableCell>
                                <TableCell className='width_800p'>{t('businessPartner.distribution_fail_reason')}</TableCell>
                                <TableCell className='width_400p'>{t('businessPartner.inactive_reason')}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {content.map((member, index) => {
                                const distributionFailReasons = member.notes.filter((note) => note.indexOf('reason.distribution.fail') > -1);
                                const inactiveReasons = member.notes.filter((note) => note.indexOf('reason.inactive') > -1);
                                return (
                                  <TableRow key={index}>
                                    <TableCell className='cell_fixed_left'>{member.member_code}</TableCell>
                                    <TableCell>{member.is_active ? t('businessPartner.active') : t('businessPartner.inActive')}</TableCell>
                                    <TableCell>{formatUtc(member.created_date, DATE_FORMAT, zoneId) || DATA_NULL}</TableCell>
                                    <TableCell>{formatUtc(member.cancel_date, DATE_FORMAT, zoneId) || DATA_NULL}</TableCell>
                                    <TableCell>
                                      {member.plan_code ? (
                                        <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.BUSINESS_PLAN_DETAILS + `/${member.plan_id}`}}>
                                          {member.plan_code}
                                        </Link>
                                      ) : DATA_NULL}
                                    </TableCell>
                                    <TableCell>{member.distribution_status ? t(`common.${member.distribution_status.toLowerCase()}`) : DATA_NULL}</TableCell>
                                    <TableCell>
                                      {
                                        distributionFailReasons.length > 0 ? (
                                          distributionFailReasons.map((reason, idx) => (
                                            <div key={idx}>{this.renderDistributionStatus(reason, member)}</div>
                                          ))
                                        ) : DATA_NULL
                                      }
                                    </TableCell>
                                    <TableCell>
                                      {
                                        inactiveReasons.length > 0 ? (
                                          inactiveReasons.map((reason) => (
                                            <div>{t(reason)}</div>
                                          ))
                                        ) : DATA_NULL
                                      }
                                    </TableCell>
                                  </TableRow>
                                );
                                })}
                            </TableBody>
                          </Table>
                        </div>
                        <Box pl={2} pt={1} pb={1}>
                          <CustomPagination onChange={this.handleChangePagination} rows={totalSize} rowsPerPage={rowsPerPage} currentPage={currentPage} />
                        </Box>
                      </Paper>
                    )}
                  </>
                )}
              </LoadingOverlay>
            </Container>
            <br />
          </Container>
        </Paper>
      </Fade>
    );
  }
}

const mapStateToProps = (state) => ({
  members: state.business.listMembers,
  isLoading: state.business.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getMembers: (referrerId, params) => dispatch(getMembers(referrerId, params)),
});

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(ListMembersModal));
