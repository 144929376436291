import React, {Component} from 'react';

import {Button, Container, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import LoadingOverlay from 'react-loading-overlay';

import {CURRENCY} from '../../../../common/constant';
import {historyPaymentApi} from '../../../../services/businessServices';
import {convertDatetimeUTC, displayDateTime} from '../../../../utils/datetime';

/**
 * History
 */
export default class extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      listHistory: [],

      loading: false,
    };
  }

  /**
   * componentDidMount Call Api
   */
  componentDidMount() {
    this.setState({
      loading: true,
    });
    historyPaymentApi(this.props.props.match.params.id)
        .then((response) => {
          this.setState({
            listHistory: response.result,
            loading: false,
          });
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
        });
  }

  /**
   * Render
   * @return {*}
   */
  render() {
    const {listHistory, loading} = this.state;
    const {t} = this.props.props;

    return (
      <LoadingOverlay active={loading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner fullWidth>
        <Paper className="field_min_size_700">
          <Container maxWidth="xl">
            <Grid container spacing={3}>
              <Grid container alignItems="center" justify="flex-end" item xs={2}>
                <HelpIcon className="errorinfo" fontSize="large" />
              </Grid>
              <Grid container alignItems="center" justify="flex-start" item xs={2}>
                <span className="font_size_small_regular" style={{whiteSpace: 'pre-line'}}>
                  {t('common.historyPayment')}
                </span>
              </Grid>
              <br></br>
              <Container>
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="right">{t('history.payment_code')}</TableCell>
                        <TableCell align="right">{t('business_plan.price')}</TableCell>
                        <TableCell align="right">{t('history.state')}</TableCell>
                        <TableCell align="right">{t('history.created_date')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listHistory?.map((item, idx) => {
                        const currency = CURRENCY.find((currency) => currency.id === this.props.currency);
                        return (
                          <TableRow>
                            <TableCell align="right" key={idx}>
                              {item.payment_code}
                            </TableCell>
                            <TableCell align="right">{currency.getLabel(item.amount)}</TableCell>
                            <TableCell align="right">{item.state}</TableCell>
                            <TableCell align="right">{displayDateTime(convertDatetimeUTC(item.created_date, item?.country_id))}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>
              <Grid container alignItems="center" justify="center" item xs={12}>
                <Button variant="contained" color="primary" onClick={() => this.props.onClickClose()}>
                  {t('common.btnOk')}
                </Button>
              </Grid>
            </Grid>
          </Container>
          <br />
        </Paper>
      </LoadingOverlay>
    );
  }
}
