/* eslint-disable no-unused-expressions */
import React, {Component} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {Card, Container, Grid, TextField, Button, FormControl, FormControlLabel, RadioGroup, Radio} from '@material-ui/core';
import ForwardIcon from '@material-ui/icons/Forward';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {ROUTE, ALL_DAY_WEEK_BOOKING_JIT, GROUP_TYPES_BOOKING_JIT, DATA_NULL} from '../../../common/constant';
import {searchBookingJIT, updateBooking, searchBookingUpdate, searchDuration} from '../../../stores/reservation/actions';
import './style.css';
import {redirectRouter} from '../../../utils/common';

/**
 * Search Flight Component
 */
class SearchFlightComponent extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      start_time: new Date(),
      end_time: new Date(),
      tejimai_time: new Date(),
      only_active_shifts: true,
      route_id: '',
      userId: '',
      isChecked: true,
      indexListTime: 0,
      listTime: [],
      duration_start_to_pickup: 0,
      duration_dropoff_to_end: 0,
      durationList: [],
      estimate_option: '',
      estimate_time_pick_up: 0,
      estimate_time_drop_off: 0,
      isLoading: false,
      seatNumberPicked: 0,
      infantNumber: 0,
      zone_id: '',
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    const {bookingInfo, searchBooking} = this.props;
    let date = new Date();
    if (bookingInfo?.requested_departure_time) {
      date = bookingInfo?.requested_departure_time;
      this.setState({date});
    }
    if (searchBooking?.demand.passenger && searchBooking?.infantNumber) {
      const passenger = bookingInfo?.demand.passenger;
      const infantNumber = searchBooking.infantNumber;
      const seatNumberPicked = passenger + Math.ceil(infantNumber * 2/3);
      this.setState({seatNumberPicked, infantNumber: searchBooking?.infantNumber});
    }
    this.handleSearchBookingJIT(date);
  }

  /**
   * convertdayOfWeek
   * @param {*} dayOfWeek
   * @returns
   */
  convertdayOfWeek = (dayOfWeek) => {
    const {t} = this.props;
    let dayOfWeekFormat = null;
    // eslint-disable-next-line array-callback-return
    ALL_DAY_WEEK_BOOKING_JIT.map((item, index) => {
      if (item.day === dayOfWeek) {
        const a = item.i18n;
        dayOfWeekFormat = t(a);
      }
    });
    return dayOfWeekFormat;
  };

  /**
   * convertTime
   * @param {*} time
   * @returns
   */
  convertTime = (time) => {
    const a = moment.tz(new Date(time), this.state.zone_id);
    const timeFormat = a?.format('HH:mm');
    let dateFormat = a?.format('MM/DD');
    const dayOfWeekFormat = this.convertdayOfWeek(a.day());
    let dateTimeFormat = null;
    if (localStorage.getItem('i18nextLng') === 'ja') {
      dateTimeFormat = ' ' + dateFormat + ' (' + dayOfWeekFormat + ') ' + timeFormat;
    } else {
      dateFormat = a?.format('MM/DD');
      dateTimeFormat = ' ' + dateFormat + ' (' + dayOfWeekFormat + ') ' + timeFormat;
    }
    return dateTimeFormat;
  };

  /**
   * findDuration
   */
  findDuration = async () => {
    const {searchBooking} = this.props;
    let total_time = 0;
    searchBooking.route?.turn_locations?.map((item) => (total_time += item.estimate_driving_time));
    let duration_start_to_pickup = 0;
    let duration_start_to_dropoff = 0;
    let duration_pickup_to_dropoff = 0;
    let duration_dropoff_to_end = 0;
    searchBooking.route?.turn_locations.every((item) => {
      if (item.from_stop_id !== searchBooking.selectPickUpLocation.from.id) {
        duration_start_to_pickup += item.estimate_driving_time;
        return true;
      } else return false;
    });
    searchBooking.route?.turn_locations.every((item) => {
      if (item.from_stop_id !== searchBooking.selectDropOffLocation.to.id) {
        duration_start_to_dropoff += item.estimate_driving_time;
        return true;
      } else return false;
    });
    duration_pickup_to_dropoff = duration_start_to_dropoff - duration_start_to_pickup;
    duration_dropoff_to_end = total_time - duration_start_to_dropoff;
    await this.setState({
      duration_start_to_pickup: duration_start_to_pickup,
      duration_pickup_to_dropoff: duration_pickup_to_dropoff,
      duration_dropoff_to_end: duration_dropoff_to_end,
    });
  };

  /**
   * Handle search bookingInfo JIT
   * @param {*} date
   */
  handleSearchBookingJIT = async (date) => {
    const {only_active_shifts, route_id, userId} = this.state;
    const {searchBooking, bookingInfo} = this.props;
    const payload = {
      date: moment(date).format('yyyy-MM-DD'),
      only_active_shifts: only_active_shifts || null,
      route_id: route_id ? route_id.trim() : null,
      simulation_id: searchBooking.simulationId,
      userId: userId ? userId.trim() : null,
    };
    this.setState({isLoading: true});
    await this.props.searchBookingJIT(userId, payload).then((response) => {
      if (response) {
        const listBooking = response;
        let listTime = listBooking[0]?.shift_status;
        listTime = listTime.filter((item) => new Date(item?.tejimai_time) > new Date());
        let index = listTime?.findIndex((item) => item?.total_seats - item?.taken_seats >= (this.state.seatNumberPicked || searchBooking?.passenger));
        if (bookingInfo?.tejimai_time) {
          const indexCache = listTime?.findIndex(
            (item) =>
              item?.total_seats - item?.taken_seats >= (this.state.seatNumberPicked || searchBooking?.passenger) &&
              item.start_time === bookingInfo?.estimate_start_time &&
              item.end_time === bookingInfo?.estimate_arrival_time &&
              item.tejimai_time === bookingInfo?.tejimai_time,
          );
          index = indexCache === -1 ? index : indexCache;
        }
        this.setState({
          durationList: [],
          zone_id: response[0]?.zone_id,
        });
        // eslint-disable-next-line
        if (bookingInfo.estimate_option !== 'MANUAL') {
          Promise.all(listTime.map((item) => this.handleChange(item.start_time))).then((durationList) => {
            this.setState({
              durationList: durationList,
              isLoading: false,
            });
          });
        } else {
          this.setState({isLoading: false});
        }
        this.setState({
          indexListTime: index,
          listTime,
          estimate_time_pick_up: bookingInfo?.estimate_time_pick_up || 0,
          estimate_time_drop_off: bookingInfo?.estimate_time_drop_off || 0,
          estimate_option: bookingInfo?.estimate_option || 'SWAT',
        });
        if (index === -1) {
          this.setState({isChecked: false});
        } else {
          this.setState({
            start_time: listTime[index].start_time,
            end_time: listTime[index].end_time,
            tejimai_time: listTime[index].tejimai_time,
            isChecked: true,
          });
          this.findDuration();
        }
      }
    });
  };

  /**
   * Select bookingInfo JIT
   */
  selectBookingJIT = () => {
    let bookingInfo = Object.assign({}, this.props.bookingInfo);
    bookingInfo = {...bookingInfo};
    bookingInfo.estimate_start_time = this.state.start_time;
    bookingInfo.estimate_arrival_time = this.state.end_time;
    bookingInfo.requested_departure_time = this.state.date;
    bookingInfo.group_type = this.props?.listBooking[0]?.group_type;
    bookingInfo.tejimai_time = this.state.tejimai_time;
    this.props.onUpdateBooking(bookingInfo);

    let searchBooking = Object.assign({}, this.props.searchBooking);
    searchBooking = {...searchBooking};
    searchBooking.indexListTime = this.state.indexListTime;
    this.props.searchBookingUpdate(searchBooking);
    redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.RESERVATION_MANAGEMENT_PASSENGER_DETAIL);
  };

  /**
   * handleSearchReservation
   * @param {object} event
   */
  handleChangeRadioGroup = async (event) => {
    const index = Number(event.target?.value);
    await this.setState({
      indexListTime: index,
    });
  };

  /**
   *
   * @param {*} time1
   * @param {*} time2
   * @returns
   */
  resultTime = (timePoint, duration) => {
    const time_2 = moment.tz(new Date(timePoint).getTime() + duration * 1000, this.state.zone_id)?.format('HH:mm');
    return !isNaN(duration) ? time_2 : '';
  };

  /**
   * handleChange
   * @param {*} start_time
   * @returns
   */
  handleChange = (start_time) => {
    start_time = new Date(start_time).toISOString();
    const {searchBooking} = this.props;
    const route_mode = searchBooking?.mapbox_path_segment;
    const driving_mode = searchBooking?.vehicle_modes;
    let queryString = '';
    const idxFrom = searchBooking.route.turn_locations.findIndex((item) => item.from_stop_id === searchBooking.selectPickUpLocation.from_stop_id);
    const idxTo = searchBooking.route.turn_locations.findIndex((item) => item.to_stop_id === searchBooking.selectDropOffLocation.to_stop_id);
    const newRoutes = searchBooking.route.turn_locations.slice(idxFrom, idxTo + 1);
    // eslint-disable-next-line
    newRoutes.map((item) => {
      queryString += (item?.from_stop_lon || item?.from?.longitude) + ',' + (item?.from_stop_lat || item?.from?.latitude) + ';';
    });
    queryString =
      queryString + (newRoutes[newRoutes.length - 1]?.to_stop_lon ||
      newRoutes[newRoutes.length - 1]?.to?.longitude) + ',' + (newRoutes[newRoutes.length - 1]?.to_stop_lat ||
      newRoutes[newRoutes.length - 1]?.to?.latitude);
    queryString = queryString + `?geometries=polyline&overview=full&steps=true&approaches=`;
    // eslint-disable-next-line
    newRoutes.map((item) => {
      queryString += `curb;`;
    });
    queryString += `curb&start_time=${start_time}&access_token=dmVyeSBzZWNyZXQga2V5`;
    return this.props.searchDuration(route_mode, driving_mode, queryString).then((response) => response[0].duration);
  };

  /**
   *
   * @param {*} pick_up_time
   * @param {*} drop_off_time
   * @param {*} tejimai_time
   * @param {*} available_seats
   * @param {*} group_type
   * @returns
   */
  listResult = (pick_up_time, drop_off_time, tejimai_time, available_seats, group_type, duration_pickup_to_dropoff) => {
    const {t, searchBooking} = this.props;
    const {duration_start_to_pickup, duration_dropoff_to_end} = this.state;
    const tejimai = new Date(tejimai_time);
    const time_pick_up = moment.tz(new Date(pick_up_time), this.state.zone_id)?.format('HH:mm');
    const time_drop_off = moment.tz(new Date(drop_off_time), this.state.zone_id)?.format('HH:mm');
    return (
      <Grid container spacing={1} className="" item xs={12} display="flex" style={{width: '1900px'}}>
        <Grid container alignItems="center" item style={{maxWidth: '10%'}}>
          {group_type === GROUP_TYPES_BOOKING_JIT[0] ? (
            searchBooking?.route?.pickup_stop_ids?.length > 1 ? (
              <Grid> {t('reservationManagement.pickup_date_scheduled')}</Grid>
            ) : (
              <Grid>{t('reservationManagement.booking_flight_pickup_time')}</Grid>
            )
          ) : (
            <Grid>{t('reservationManagement.pickup_date_scheduled')}</Grid>
          )}
        </Grid>
        <Grid container alignItems="center" item style={{maxWidth: '5%'}}>
          <h4 textalign="center">
            {this.state.estimate_option === 'MANUAL' ?
              this.resultTime(
                  group_type === GROUP_TYPES_BOOKING_JIT[0] ? pick_up_time : drop_off_time,
                  group_type === GROUP_TYPES_BOOKING_JIT[0] ? this.state.estimate_time_pick_up : -this.state.estimate_time_pick_up,
                ) :
              group_type === GROUP_TYPES_BOOKING_JIT[0] ?
              this.resultTime(pick_up_time, searchBooking?.route?.pickup_stop_ids?.length > 1 ? duration_start_to_pickup : 0) :
              this.resultTime(
                  drop_off_time,
                  searchBooking?.route?.dropoff_stop_ids?.length > 1 ? -(duration_pickup_to_dropoff + duration_dropoff_to_end) : -duration_pickup_to_dropoff,
                )}
          </h4>
        </Grid>

        <Grid container justify="center" alignItems="center" item xs={1}>
          <ForwardIcon></ForwardIcon>
        </Grid>
        <Grid container alignItems="center" item style={{maxWidth: '10%'}}>
          {group_type === GROUP_TYPES_BOOKING_JIT[0] ? (
            <Grid>{t('reservationManagement.dropoff_date_scheduled')}</Grid>
          ) : searchBooking?.route?.dropoff_stop_ids?.length > 1 ? (
            <Grid>{t('reservationManagement.dropoff_date_scheduled')}</Grid>
          ) : (
            <Grid>{t('reservationManagement.booking_flight_dropoff_time')}</Grid>
          )}
        </Grid>
        <Grid container alignItems="center" item style={{maxWidth: '8%'}}>
          <h4 textalign="center">
            {this.state.estimate_option === 'MANUAL' ?
              this.resultTime(
                  group_type === GROUP_TYPES_BOOKING_JIT[0] ? pick_up_time : drop_off_time,
                  group_type === GROUP_TYPES_BOOKING_JIT[0] ? this.state.estimate_time_drop_off : -this.state.estimate_time_drop_off,
                ) :
              group_type === GROUP_TYPES_BOOKING_JIT[0] ?
              this.resultTime(
                  pick_up_time,
                  searchBooking?.route?.pickup_stop_ids?.length > 1 ? duration_start_to_pickup + duration_pickup_to_dropoff : duration_pickup_to_dropoff,
                ) :
              this.resultTime(drop_off_time, searchBooking?.route?.dropoff_stop_ids?.length > 1 ? -duration_dropoff_to_end : 0)}
          </h4>
        </Grid>
        <Grid container display="flex" alignItems="center" item xs={5} lg={6}>
          (
          <Grid style={{marginRight: '5px'}}>
            {t('reservationManagement.booking_deadline')}
            {this.convertTime(tejimai)}{' '}
          </Grid>
          <Grid style={{marginRight: '5px'}}>
            {t('reservationManagement.available_seats')}: {available_seats})
          </Grid>
          <Grid style={{marginRight: '5px'}}>
            {group_type === GROUP_TYPES_BOOKING_JIT[0] ? (
              <Grid>{searchBooking?.pickUpLocation[0]?.from?.name || DATA_NULL}</Grid>
            ) : (
              <Grid>{searchBooking?.dropOffLocation[searchBooking?.dropOffLocation?.length - 1]?.to?.name || DATA_NULL}</Grid>
            )}
          </Grid>
          <Grid style={{marginRight: '5px'}}>{group_type === GROUP_TYPES_BOOKING_JIT[0] ? <Grid>{time_pick_up}</Grid> : <Grid>{time_drop_off}</Grid>}</Grid>
          <Grid style={{marginRight: '5px'}}>
            {group_type === GROUP_TYPES_BOOKING_JIT[0] ? <Grid>{t('reservation.departure')}</Grid> : <Grid>{t('reservation.arrival')}</Grid>}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t, listBooking, bookingInfo, searchBooking} = this.props;
    const {listTime, isLoading, seatNumberPicked} = this.state;
    return (
    <LoadingOverlay active={isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('reservationManagement.create_reservation')}</h3>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="xl">
          <Card raised>
            <Container maxWidth="xl">
              <Grid container alignItems="flex-start">
                <Grid item xs={12}>
                  <h1 className="product_entry_table_header_color font_color_white font_size_mid title">{t('reservationManagement.select_flight')}</h1>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container maxWidth="xl">
                        <h4>
                          {t('ticket.route')} 1: {searchBooking?.route?.name}
                        </h4>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <h4 style={{marginBottom: '2px'}}>{t('reservationManagement.pickup_location_booking_jit')}</h4>
                          <TextField
                            className="width_100"
                            name="search_member_id"
                            margin="dense"
                            variant="outlined"
                            value={bookingInfo?.pickup_location_name}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <h4 style={{marginBottom: '2px'}}>{t('reservationManagement.dropoff_location_booking_jit')}</h4>
                          <TextField
                            className="width_100"
                            name="search_member_email"
                            margin="dense"
                            variant="outlined"
                            value={bookingInfo?.dropoff_location_name}
                            disabled
                          />
                        </Grid>
                      </Grid>
                      <br></br>
                      <div>
                        <h1 className="title_select_ride">{t('reservationManagement.select_the_ride')}</h1>
                      </div>
                      <Grid container alignItems="center" item xs={8} lg={12} spacing={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container item xs={4} lg={2}>
                            <h4>
                              {t('jit.dateTime')}
                              <span className="font_color_red">＊</span>
                            </h4>
                          </Grid>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            className="field_size_20 field_min_size_300"
                            margin="dense"
                            autoOk
                            inputVariant="outlined"
                            minDate={new Date()}
                            format="yyyy/MM/dd"
                            value={this.state.date}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            inputProps={{
                              readOnly: true,
                            }}
                            onChange={(time) => {
                              this.setState({
                                date: time,
                              });
                              this.handleSearchBookingJIT(time);
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <br></br>
                      <br></br>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
            <Container maxWidth="xl">
              <Grid container maxWidth="xl" style={{marginLeft: '15px'}}>
                {listTime?.length > 0 ? (
                  <FormControl maxWidth="xl">
                    <RadioGroup
                      onChange={(event) => this.handleChangeRadioGroup(event)}
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={
                        listTime[this.state.indexListTime]?.total_seats - listTime[this.state.indexListTime]?.taken_seats >= (seatNumberPicked || bookingInfo?.passenger) ?
                          this.state.indexListTime :
                          null
                      }
                      name="radio-buttons-group"
                    >
                      {isLoading === false && listTime?.map((item, index) => {
                        return (
                          <Grid>
                            <FormControlLabel
                              width="1200px"
                              value={index}
                              key={index}
                              control={<Radio className="checkbox_radio" />}
                              onChange={() => {
                                this.setState({
                                  start_time: item.start_time,
                                  end_time: item.end_time,
                                  tejimai_time: item.tejimai_time,
                                });
                              }}
                              checked={index === this.state.indexListTime}
                              label={this.listResult(
                                item.start_time,
                                item.end_time,
                                item.tejimai_time,
                                item.total_seats - item.taken_seats,
                                listBooking[0].group_type,
                                this.state.durationList[index],
                              )}
                              disabled={item.total_seats - item.taken_seats < (seatNumberPicked || bookingInfo?.passenger)}
                            />
                            <Grid className="message_not_enough_seats_left">
                              {item.total_seats - item.taken_seats < (seatNumberPicked || bookingInfo?.passenger) && (
                                <Grid>
                                  <span className="font_color_red">＊</span>
                                  {t('reservationManagement.message_not_enough_seats_left')}
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                ) : (
                  <div style={{margin: 'auto'}}>
                    <h4>{t('reservation.booking_jit_nodata')}</h4>
                  </div>
                )}
              </Grid>
            </Container>
            <br></br>
            <Grid item xs={12} container alignItems="center">
              <Grid container justify="flex-end" item xs={6}>
                <Button color="primary" variant="contained" disabled={listTime?.length > 0 && this.state.isChecked ? false : true} onClick={() => this.selectBookingJIT()}>
                  {t('common.next')}
                </Button>
              </Grid>
              <Grid container justify="flex-start" item xs={6}>
                <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.RESERVATION_CREATE_INFO_JIT}}>
                  <Button color="primary" variant="contained" className="button_margin button_color">
                    {t('common.btnReturn')}
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <br></br>
          </Card>
          <br />
          <br></br>
        </Container>
        <br></br>
      </Card>
    </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listBooking: state.reservation.listBookingJIT,
    searchBooking: state.reservation.searchBooking,
    bookingInfo: state.reservation.bookingInfo,
    listDuration: state.reservation.listDuration,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateBooking: (bookingData) => {
      dispatch(updateBooking(bookingData));
    },
    searchBookingJIT: (userId, payload) => dispatch(searchBookingJIT(userId, payload)),
    searchBookingUpdate: (data) => dispatch(searchBookingUpdate(data)),
    searchDuration: (route_type, driving_mode, routes) => dispatch(searchDuration(route_type, driving_mode, routes)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(SearchFlightComponent));
