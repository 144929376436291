export const ACTION_TYPES = {
  LIST_PRICE_CALENDAR_START: 'LIST_PRICE_CALENDAR_START',
  LIST_PRICE_CALENDAR_SUCCESS: 'LIST_PRICE_CALENDAR_SUCCESS',
  LIST_PRICE_CALENDAR_FAIL: 'LIST_PRICE_CALENDAR_FAIL',

  CREATE_PRICE_CALENDAR_START: 'CREATE_PRICE_CALENDAR_START',
  CREATE_PRICE_CALENDAR_SUCCESS: 'CREATE_PRICE_CALENDAR_SUCCESS',
  CREATE_PRICE_CALENDAR_FAIL: 'CREATE_PRICE_CALENDAR_FAIL',
};

const initialState = {
  isLoading: false,
  list_price_calendar: {},
};

/**
 * function reduce list price calendar
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.LIST_PRICE_CALENDAR_START:
    case ACTION_TYPES.CREATE_PRICE_CALENDAR_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.LIST_PRICE_CALENDAR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list_price_calendar: action.listPriceCalendar,
      };
    case ACTION_TYPES.CREATE_PRICE_CALENDAR_SUCCESS:
    case ACTION_TYPES.CREATE_PRICE_CALENDAR_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.LIST_PRICE_CALENDAR_FAIL:
      return {
        isLoading: false,
        error: action.error,
        list_price_calendar: {},
      };
    default:
      return state;
  }
}
