import React, {Component} from 'react';

import {Grid, TextField, Button, FormControl, FormHelperText} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {COUNTRY, ROUTE} from '../../common/constant';
import MuiPhoneNumberInput from '../../components/mui-phone-number-input';
import SelectMultiLang from '../../components/SelectMultiLang/index';
import {userDetail, registration} from '../../stores/authen/actions';
import {onChangeTextField} from '../../utils/common';

/**
 * Registration view
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      inviteEmail: '',
      personCharge: '',
      mobile: '',
      password: '',
      rePassword: '',

      isSubmitForm: false,
      errorField: {
        personChargeMsg: false,
        passwordMsg: false,
        rePasswordMsg: false,
        phoneNumberMsg: false,
        matchPassword: false,
      },
    };
    this.handleClickRegister = this.handleClickRegister.bind(this);
    this.validator = new SimpleReactValidator();
  }

  /**
   * handleChangeInput
   * @param {event} event
   */
  handleChangeInput = (event) => {
    this.setState({...this.state, [event.currentTarget.name]: event.target.value});
  };

  /**
   * handleClickRegister
   *@param {object} event
   */
  handleClickRegister(event) {
    event.preventDefault();
    this.setState({
      isSubmitForm: true,
    });
    const payload = {
      person_charge: this.state.personCharge,
      email: this.state.inviteEmail,
      password: this.state.password,
      mobile: this.state.mobile.replace(/\s/g, ''),
      country: '+' + this.refs.mobile.state.selectedCountry.dialCode,
    };
    if (this.validator.allValid()) {
      this.props.registration(payload, this.props);
    }
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    localStorage.clear();
    let urlParamStr = window.location.search;

    if (urlParamStr) {
      urlParamStr = urlParamStr?.substring(1);
    }

    const uUId = urlParamStr.split('&')[0].split('=')[1];
    if (uUId) {
      this.props.userDetail(uUId).then((response) => {
        if (response) {
          this.setState({
            inviteEmail: response.email,
            personCharge: response.person_charge,
          });
        }
      });
    } else {
    }
  }

  /**
   * Render component
   * @return {Component}
   */
  render() {
    const {authen, t} = this.props;

    return (
      <div
        style={{
          margin: 15,
        }}
      >
        <LoadingOverlay active={authen.isLoading || authen.isLoadingRegister} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
          <Container maxWidth="md">
            <form autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={11} justify="center" alignItems="center" container>
                  <br></br>
                </Grid>
                <Grid item xs={1} justify="flex-end" alignItems="center" container>
                  <SelectMultiLang />
                </Grid>
                <Grid item container xs={12} alignItems="center" justify="center">
                  <img src={`${process.env.PUBLIC_URL}/logo-title.png`} alt="Logo" className="logo_header"></img>
                </Grid>

                <Grid item container xs={12} alignItems="center" justify="center" className="border_bottom">
                  <h3>{t('registration.title')}</h3>
                </Grid>

                {authen?.userDetail ? (
                  <>
                    {/* account type */}
                    <Grid item container xs={12} alignItems="center" justify="center">
                      <TextField
                        InputLabelProps={{shrink: true}}
                        label={t('registration.accountType')}
                        margin="dense"
                        className="field_min_size_500"
                        value={authen?.userDetail?.account_type || ''}
                        InputProps={{
                          readOnly: true,
                        }}
                        helperText={t('errorFields.cannotChange')}
                      />
                    </Grid>

                    {/* supplier name */}
                    <Grid item container xs={12} alignItems="center" justify="center">
                      <TextField
                        InputLabelProps={{shrink: true}}
                        label={t('registration.businessName')}
                        margin="dense"
                        value={authen?.userDetail?.supplier_name || ''}
                        className="field_min_size_500"
                        InputProps={{
                          readOnly: true,
                        }}
                        helperText={t('errorFields.cannotChange')}
                      />
                    </Grid>

                    {/* email */}
                    <Grid item container xs={12} alignItems="center" justify="center">
                      <TextField
                        InputLabelProps={{shrink: true}}
                        label={t('common.email')}
                        margin="dense"
                        value={authen?.userDetail?.email || ''}
                        className="field_min_size_500"
                        InputProps={{
                          readOnly: true,
                        }}
                        helperText={t('errorFields.cannotChange')}
                      />
                    </Grid>

                    {/* user name */}
                    <Grid item container xs={12} alignItems="center" justify="center">
                      <FormControl>
                        <TextField
                          label={
                            <span>
                              {t('registration.contactName')}
                              <span className="font_color_red">＊</span>
                            </span>
                          }
                          inputProps={{maxLength: 128}}
                          margin="dense"
                          variant="outlined"
                          name="personCharge"
                          value={this.state.personCharge}
                          onChange={(event) => onChangeTextField(this, event)}
                          className="field_min_size_500"
                        />
                        {this.validator.message('personCharge', this.state.personCharge, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.personCharge.trim(), 'required') && (
                          <FormHelperText id="personCharge" error>
                            {t('validation.required', {field: t('registration.contactName')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    {/* password */}
                    <Grid item container xs={12} alignItems="center" justify="center">
                      <FormControl>
                        <TextField
                          margin="dense"
                          label={
                            <span>
                              {t('common.password')}
                              <span className="font_color_red">＊</span>
                            </span>
                          }
                          inputProps={{maxLength: 128}}
                          variant="outlined"
                          name="password"
                          value={this.state.password}
                          onChange={(event) => onChangeTextField(this, event)}
                          className="field_min_size_500"
                          type="password"
                        />
                        {this.validator.message('password', this.state.password, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.password.trim(), 'required') && (
                          <FormHelperText id="password" error>
                            {t('validation.required', {field: t('common.password')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    {/* re-password */}
                    <Grid item container xs={12} alignItems="center" justify="center">
                      <FormControl>
                        <TextField
                          margin="dense"
                          label={
                            <span>
                              {t('registration.rePassword')}
                              <span className="font_color_red">＊</span>
                            </span>
                          }
                          inputProps={{maxLength: 128}}
                          variant="outlined"
                          name="rePassword"
                          value={this.state.rePassword}
                          onChange={(event) => onChangeTextField(this, event)}
                          className="field_min_size_500"
                          type="password"
                        />
                        {this.validator.message('rePassword', this.state.rePassword, `required|in:${this.state.password}`)}
                        {this.state.isSubmitForm && !this.validator.check(this.state.rePassword.trim(), 'required') && (
                          <FormHelperText id="rePassword" error>
                            {t('validation.required', {field: t('registration.rePassword')})}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm &&
                          this.validator.check(this.state.rePassword.trim(), 'required') &&
                          !this.validator.check(this.state.rePassword, `in:${this.state.password}`) && (
                          <FormHelperText id="rePassword" error>
                            {t('errorFields.confirmPasswordMatch')}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    {/* mobile */}
                    <Grid item container xs={12} alignItems="center" justify="center">
                      <FormControl>
                        <MuiPhoneNumberInput
                          ref="mobile"
                          defaultCountry={'jp'}
                          onlyCountries={COUNTRY.map((c) => c.countryCode)}
                          label={
                            <span>
                              {t('common.phoneNumber')}
                              <span className="font_color_red">＊</span>
                            </span>
                          }
                          countryCodeEditable={false}
                          enableLongNumbers={true}
                          autoFormat={false}
                          margin="dense"
                          variant="outlined"
                          className="field_min_size_500"
                          name="mobile"
                          value={this.state.mobile}
                          onChange={(value, country) => {
                            this.setState({mobile: value, dataCountry: country});
                          }}
                        />
                        {this.validator.message('mobile', this.state.mobile, 'required')}
                        {this.state.isSubmitForm &&
                          !this.validator.check(
                            this.state.mobile?.trim() ? this.state.mobile.slice(this.state.dataCountry.dialCode.length + 1)?.trim() : this.state.mobile?.trim(),
                            'required',
                          ) && (
                          <FormHelperText id="mobile" error>
                            {t('validation.required', {field: t('common.phoneNumber')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <br></br>
                    <Grid item container xs={12} alignItems="center" justify="center">
                      <Button variant="contained" color="primary" onClick={this.handleClickRegister} endIcon={<CloudUploadIcon />}>
                        {t('common.btnRegister')}
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item container xs={12} alignItems="center" justify="center">
                      <span>{t(`${authen.error}`)}</span>
                    </Grid>
                    <br></br>
                    <br></br>
                    <Grid item container xs={12} alignItems="center" justify="center">
                      <Button variant="contained" color="primary" onClick={() => this.props.history.push(ROUTE.LAYOUT + ROUTE.LOGIN)} endIcon={<CloudUploadIcon />}>
                        {t('common.btnCancel')}
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </form>
          </Container>
        </LoadingOverlay>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authen: state.authen,
    modal: state.modal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userDetail: (uUId) => dispatch(userDetail(uUId)),
    registration: (payload, props) => dispatch(registration(payload, props)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
