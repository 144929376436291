import React, {Component} from 'react';

import {Container, Card, CardActionArea, CardContent, Typography, Grid, Button} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

import {ROUTE} from '../../common/constant';
import {redirectRouter} from '../../utils/common';
import withPermissionData from '../../withPermissionData/hoc';

/**
 * Data Management
 */
class Index extends Component {
  /**
   * Render Component
   * @return {component}
   */
  render() {
    const {t} = this.props;

    return (
      <div>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>Data Management</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}>
                <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                  <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                    {t('common.btnReturn')}
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <br />
            <Card raised>
              <Container maxWidth="xl">
                <br />
                <Grid container>
                  <Grid container alignItems="center" item xs={12}>
                    <div className="font_size_mid font_bold title">Configure Mobile App</div>
                  </Grid>
                  <Grid container spacing={1} className="card_header_item">
                    <Grid container alignItems="center" item xs={12} md={4} lg={2}>
                      <Card raised className="data_card_size">
                        <CardActionArea onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.DATA_MENU_APP)}>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="h3">
                              Menu App
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                              Menu App
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    <Grid container alignItems="center" item xs={12} md={4} lg={2}>
                      <Card raised className="data_card_size">
                        <CardActionArea onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.DATA_MOBILE_FUNCTION)}>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="h3">
                              Mobile Function
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                              Mobile Function
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    <Grid container alignItems="center" item xs={12} md={4} lg={2}>
                      <Card raised className="data_card_size">
                        <CardActionArea onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.WILLER_SERVICES_MANAGEMENT)}>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="h3">
                              Willer Services
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                              Willer Services
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
                <br />
                <Grid container justifyContent="center">
                  <Grid container alignItems="center" item xs={12}>
                    <div className="font_size_mid font_bold title">Configure System</div>
                  </Grid>
                  <Grid container spacing={1} className="card_header_item">
                    <Grid container alignItems="center" item xs={12} md={4} lg={2}>
                      <Card raised className="data_card_size">
                        <CardActionArea onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.SERVICE_GROUP)}>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="h3">
                              Service Groups
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                              Service Groups
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    <Grid container alignItems="center" item xs={12} md={4} lg={2}>
                      <Card raised className="data_card_size">
                        <CardActionArea onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.GEOFENCE_MANAGEMENT)}>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="h3">
                              Geofences
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                              Geofences
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    <Grid container alignItems="center" item xs={12} md={4} lg={2}>
                      <Card raised className="data_card_size">
                        <CardActionArea onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.SIMULATION_MANAGEMENT)}>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="h3">
                              Simulations
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                              Simulations
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
                <br />
                <Grid container>
                  <Grid container alignItems="center" item xs={12}>
                    <div className="font_size_mid font_bold title">Others</div>
                  </Grid>
                  <Grid container spacing={1} className="card_header_item">
                    <Grid container alignItems="center" item xs={12} md={4} lg={2}>
                      <Card raised className="data_card_size">
                        <CardActionArea onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.DATA_CACHE)}>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="h3">
                              Cache Management
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                              Cache Management
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    <Grid container alignItems="center" item xs={12} md={4} lg={2}>
                      <Card raised className="data_card_size">
                        <CardActionArea onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.VEHICLE_CONFIG)}>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="h3">
                              Vehicle Config
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                              Vehicle Config
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    <Grid container alignItems="center" item xs={12} md={4} lg={2}>
                      <Card raised className="data_card_size">
                        <CardActionArea onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.DATA_STOP_MASTER_MANAGEMENT)}>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="h3">
                              Stop Master
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                              Stop Master
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    <Grid container alignItems="center" item xs={12} md={4} lg={2}>
                      <Card raised className="data_card_size">
                        <CardActionArea onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.MAIL_MANAGEMENT)}>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="h3">
                              Mail Management
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                              Mail Management
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    <Grid container alignItems="center" item xs={12} md={4} lg={2}>
                      <Card raised className="data_card_size">
                        <CardActionArea onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.CHECK_BOOKING_MANAGEMENT)}>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="h3">
                              Booking Management
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                              Booking Management
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    <Grid container alignItems="center" item xs={12} md={4} lg={2}>
                      <Card raised className="data_card_size">
                        <CardActionArea onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.FILE_UPLOAD)}>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="h3">
                              File Upload
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                              File Upload
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    <Grid container alignItems="center" item xs={12} md={4} lg={2}>
                      <Card raised className="data_card_size">
                        <CardActionArea onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.PAYMENT_MANAGEMENT)}>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="h3">
                              Payment
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                              Payment
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                    <Grid container alignItems="center" item xs={12} md={4} lg={2}>
                      <Card raised className="data_card_size">
                        <CardActionArea onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.SUB_COST)}>
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="h3">
                              Sub Cost
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                              Sub Cost
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
                <br />
              </Container>
            </Card>
          </Container>
          <br />
        </Card>
      </div>
    );
  }
}

export default withPermissionData(withTranslation('translations')(Index));
