import {SERVICE_TYPE} from '../common/constant';
import {api} from '../services/api';

const searchListReservationUrl = 'admin/reservation/search';
const searchBookingJITUrl = 'mass/v1/booking/jit/shift-status';
const reservationSummaryUrl = 'admin/reservation-summary';
const detailReservationUrl = 'admin/reservation/';
const updateReservationUrl = 'admin/reservation/update';
const getMemberSubscriptionUrl = 'admin/subscriptions/by-user/';
const estimateCostForUserUrl = 'admin/one-time-price/estimate-cost';
const chatHistoryUrl = 'chat/messages?topic=chat/UD-';
const reservationGpsUrl = 'admin/reservation/gps/';
const polylineForBookingUrl = 'delivery/driver-assigment/booking/';
const polylineTrackingUrl = 'delivery/tracking-polyline/booking/';
const bookingHistoryUrl = 'admin/reservation/booking-history/';
const changeNodeStatusUrl = 'admin/reservation/change-node-status';
const removeSlotUrl = 'subscription/plan-subscription/delete-slot';
const changePaymentMethodUrl = 'admin/subscriptions/payment_method';
const getMessageTemplateUrl = 'chat/messages-template';
const getReservationIdTracking = 'delivery/driver-assigment/booking';
const checkingWayPointSuspensionUrl = 'mass/waypoint/check-waypoint-restrict';
const getJitRoutePolylineUrl = 'mass/jit/route/';
const getCsvExportReservationUuidUrl = '/admin/csv-export/reservation';
const getJitPlanSubscriptionUrl = 'subscription/plan-subscription/users/{userId}/geofence/{geofenceId}/list';
const adminChangePaymentMethodUrl = 'mass/v1/booking/payment-method';
const changePaymentStatusUrl = 'admin/reservation/change-payment-status';
const retryPaymentCreditCardUrl = 'admin/payment/retry-payment';
const getCsvExportRatingUuidUrl = 'admin/csv-export/reservation-rate';
const getAdvanceBookingOffersUrl = 'admin/reservation/booking/advance/search';
const getRestrictedWaypointIdsUrl = 'mass/waypoint/restrict/geofence';
const bookingHistoryAdvanceUrl = 'delivery/booking-history/booking-adv/';
const getPartnerAndOneTimeInfoUrl = 'mass/v1/costestimate/one-time-config-for-admin';
const getWaypointRestrictUrl = 'mass/waypoint/restrict/geofence/{{geofenceId}}';
const searchBusReservationUrl = 'admin/auto-bus/search';
const getBusReservationDetailUrl = '/admin/auto-bus/{id}';
const getStatusBookingUrl = 'delivery/booking/{id}/status';
const getContextInfantUrl = 'auth/geofence/{geofenceId}/messages';
const getContentTextPassengerUrl = 'auth/geofence/{geofenceId}/messages/no-infant';

// Reservation booking
export const getTransitStopNearestApi = (payload) => {
  return api.get('mass/v1/wstop/nearest', payload);
};

export const searchBookingJITApi = (userId, payload) => {
  return api.post(`${searchBookingJITUrl}/${userId}`, payload);
};

export const findDurationApi = (route_type, driving_mode, routes) => {
  return fetch(`https://mapbox-osrm-proxy.d.gcprod.swatrider.com/${route_type}/v1/${driving_mode}/${routes}`);
};

export const searchRoutesBookingApi = (payload) => {
  return api.post('mass/public/search-by-type', payload);
};

export const searchMember = (payload) => {
  return api.put(`admin/members/search-reservation?size=${payload.size}&page=${payload.page}`, payload);
};

export const nonMember = (payload) => {
  return api.post(`admin/reservation/non-member`, payload);
};

export const getMQTTInfo = (user_id) => {
  return api.get(`/notification/mqtt/account?userId=${user_id}&userRole=USER`);
};

export const listUserOrder = (user_id) => {
  return api.get(`admin/delivery/user-order?userId=${user_id}`);
};

export const booking = (payload) => {
  return api.post('admin/reservation/booking', payload);
};

export const advanceBooking = (payload) => {
  return api.post('admin/reservation/booking/advance', payload);
};

export const manageBooking = (payload) => {
  return api.post('admin/reservation/booking/management', payload);
};

export const cancelBooking = (payload) => {
  return api.post('admin/reservation/booking/cancel', payload);
};

export const sendMailBooking = (payload) => {
  return api.post('admin/reservation/send-email', payload);
};

export const getPercentShuttlePassenger = (vehicleId, params) => {
  return api.get(`mass/public/user-in-shuttle/${vehicleId}`, params);
};

export const listReservationSearchApi = (payload, queryParams) => {
  return api.search(searchListReservationUrl, queryParams, payload);
};

export const searchReservationSummaryApi = (payload) => {
  return api.get(reservationSummaryUrl, payload);
};

export const getReservationDetailSummaryApi = (bookingId) => {
  return api.get(`${reservationSummaryUrl}/${bookingId}`);
};

export const detailReservationApi = (id, service_type) => {
  if (service_type === SERVICE_TYPE[3].id) {
    return api.get(detailReservationUrl + `qr/${id}`);
  }
  return api.get(detailReservationUrl + id);
};

export const updateReservationApi = (payload) => {
  return api.put(updateReservationUrl, payload);
};

export const changeNodeStatusApi = (payload) => {
  return api.post(changeNodeStatusUrl, payload);
};

// delivery
export const getSuppliers = () => {
  return api.get(`admin/suppliers`);
};

export const getListSupplierFacility = (payload) => {
  return api.get('order-service/supplier-facilities', payload);
};

export const getSupplierFacilityById = (id) => {
  return api.get('order-service/supplier-facilities/' + id);
};

export const getProductByStoreId = (id) => {
  return api.get(`order-service/products?supplierFacilityIds=${id}`);
};

export const getProduct = (product_id) => {
  return api.get(`order-service/products/${product_id}`);
};

export const addToCart = (cart) => {
  return api.post(`admin/carts/cart-items`, cart);
};

export const deliveryAddress = (address) => {
  return api.post(`auth/user/delivery-address`, address);
};

export const bookingDelivery = (payload) => {
  return api.post(`admin/orders`, payload);
};

// subscriptions
export const getMemberSubscriptionApi = (user_id, geofence_id) => {
  return api.get(getMemberSubscriptionUrl + user_id, {geofence_id: geofence_id});
};

export const estimateCostForUserApi = (payload) => {
  return api.put(estimateCostForUserUrl, payload);
};

// Chat history
export const chatHistoryApi = (bookingId) => {
  return api.get(chatHistoryUrl + `${bookingId}`);
};

// Reservation Gps
export const reservationGpsApi = (bookingId) => {
  return api.get(reservationGpsUrl + `${bookingId}`);
};

// Polyline for booking
export const polylineForBookingApi = (bookingId) => {
  return api.get(polylineForBookingUrl + bookingId);
};

// Polyline tracking
export const polylineTrackingApi = (bookingId, payload) => {
  return api.get(polylineTrackingUrl + bookingId, payload);
};

export const getReservationIdTrackingApi = (bookingIds) => {
  return api.put(getReservationIdTracking, bookingIds);
};

// Booking history
export const bookingHistoryApi = (userId, queryParams) => {
  return api.get(bookingHistoryUrl + userId, queryParams);
};

export const removeSlotApi = (payload) => {
  return api.put(removeSlotUrl, payload);
};

export const changePaymentMethodApi = (payload) => {
  return api.put(changePaymentMethodUrl, payload);
};

export const getMessageTemplateApi = () => {
  return api.get(getMessageTemplateUrl);
};

export const getJitRoutePolylineApi = (routeCode) => {
  return api.get(getJitRoutePolylineUrl + routeCode + '/enable');
};

export const bookingJitApi = (body) => {
  return api.post('admin/reservation/booking', body);
};

export const estimateCostJitForUserApi = (payload) => {
  return api.post('mass/v1/costestimate/one-time', payload);
};

export const getCsvExportReservationUuidApi = (payload, queryParams) => {
  return api.search(getCsvExportReservationUuidUrl, queryParams, payload);
};

export const getJitPlanSubscriptionApi = (params, queryParams) => {
  return api.get(getJitPlanSubscriptionUrl.replace('{geofenceId}', params.geofence_id).replace('{userId}', params.user_id), queryParams);
};

export const checkingWayPointSuspensionApi = (body) => {
  return api.post(checkingWayPointSuspensionUrl, body);
};

export const checkRestrictedWaypointsByExternalIdsApi = (body) => {
  return api.post('mass/waypoint/check-waypoint-restrict/by-externalId', body);
};

export const adminChangePaymentMethodApi = (payload) => {
  return api.post(adminChangePaymentMethodUrl, payload);
};

export const changePaymentStatusApi = (payload) => {
  return api.post(changePaymentStatusUrl, payload);
};

export const retryPaymentCreditCardApi = (payload) => {
  return api.post(retryPaymentCreditCardUrl, payload);
};

export const getCsvExportRatingUuidApi = (payload) => {
  return api.search(getCsvExportRatingUuidUrl, {}, payload);
};

export const getAdvanceBookingOffersApi = (userId, payload) => {
  return api.post(`${getAdvanceBookingOffersUrl}/${userId}`, payload);
};

export const getRestrictedWaypointIdsApi = (geofenceId, params) => {
  return api.get(`${getRestrictedWaypointIdsUrl}/${geofenceId}`, params);
};

export const bookingHistoryAdvance = (booking_id) => {
  return api.get(bookingHistoryAdvanceUrl + booking_id);
};

export const getPartnerAndOneTimeInfoApi = (payload) => {
  return api.put(getPartnerAndOneTimeInfoUrl, payload);
};

export const getWaypointRestrictApi = (payload) => {
  return api.get(getWaypointRestrictUrl.replace('{{geofenceId}}', payload?.geofenceId), payload);
};

export const searchBusReservationApi = (payload, queryParams) => {
  return api.search(searchBusReservationUrl, queryParams, payload);
};

export const getBusReservationDetailApi = (id) => {
  return api.get(getBusReservationDetailUrl.replace('{id}', id));
};

export const changeBusReserevationStatusApi = (id, payload) => {
  return api.put(getBusReservationDetailUrl.replace('{id}', id), payload);
};

export const getStatusBookingApi = (id) => {
  return api.get(getStatusBookingUrl.replace('{id}', id));
}

export const getContentTextInfantApi = (geofence_id) => {
  return api.get(getContextInfantUrl.replace('{geofenceId}', geofence_id));
};

export const getContentTextPassengerApi = (geofence_id) => {
  return api.get(getContentTextPassengerUrl.replace('{geofenceId}', geofence_id));
};
