import React, {Component} from 'react';

import {Grid, Button, TextField, Paper, Card, Container, Modal, Backdrop, FormHelperText, Dialog, Box} from '@material-ui/core';
import {FormControl} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import MoveUp from '@material-ui/icons/ExpandLess';
import MoveDown from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {ROUTE, PERMISSION_ACTIONS, LANGUAGE} from '../../../common/constant';
import ImageSelect from '../../../components/imageSelect';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getDetailMenuAppByGeofenceApi, updateMenuAppByGeofenceApi} from '../../../services/commonSettingServices';
import {getAllCountryCode} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {onChangeListData, backForwardRouter} from '../../../utils/common';
import {modalObj} from '../../../utils/modal';
import './styles.css';

/**
 * Menu App Form Component
 */
class Index extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      country: {},
      images: [],
      menuData: [],

      isOpenModal: false,
      isSubmitForm: false,
      isLoading: false,
      flg: false,
      indexItem: null,
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllCountryCode();
    const countryId = this.props.match.params.id;
    this.setState({
      country: this.props.common?.country_code?.find((element) => Number(element.id) === Number(countryId)),
    });
    if (countryId) {
      const res = await getDetailMenuAppByGeofenceApi({countryId});
      if (res && res.status === 200) {
        if (res.result.slides?.length > 0) {
          this.setState({menuData: res.result.slides, images: res.result.slides.map((e) => ({id: e.image_master_id, url: e.image_master_url}))});
        } else {
          this.setState({
            menuData: [
              {
                country_id: countryId,
                id: null,
                image_master_id: null,
                image_master_url: '',
                languages: [
                  {language_id: 3, title: ''},
                  {language_id: 1, title: ''},
                  {language_id: 2, title: ''},
                ],
                link: '',
                order_number: 1,
                title: '',
              },
            ],
          });
        }
      } else {
        this.props.setMessageModal(modalObj(true, res.message));
        if (res.message === 'permission.denied') {
          backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.MENU_APP_MANAGEMENT);
        }
      }
    }
  }

  /**
   * openImageModal
   * @param {Number} index;
   */
  openImageModal = (index) => {
    this.setState({
      isOpenModal: true,
      indexItem: index,
    });
  };

  /**
   * handleButtonOkImageModal
   */
  handleButtonOkImageModal = () => {
    this.setState({
      isOpenModal: false,
    });
  };

  /**
   * handleButtonCloseImageModal
   */
  handleButtonCloseImageModal = () => {
    this.setState({
      isOpenModal: false,
    });
  };

  /**
   * compareSelectedImageData
   * @param {Object} value;
   */
  compareSelectedImageData = (value) => {
    if (value.isChange === true) {
      // check removed image from select then remove from current list
      const removeImage = this.state.menuData.filter((x) => !value.data?.find((e) => e.id === x.image_master_id));
      const currentData = this.state.menuData.filter((x) => !removeImage.find((e) => e.image_master_id === x.image_master_id));

      // check added new image from select then add to current list
      const newImage = value.data
        ?.filter((x) => !this.state.menuData.find((e) => e.image_master_id === x.id))
        .map((e, i) => ({
          country_id: this.props.match.params.id,
          id: !e.image_master_id ? '' : e.id,
          image_master_id: e.image_master_id ? e.image_master_id : e.id,
          image_master_url: e.image_master_url ? e.image_master_url : e.url,
          languages: [
            {language_id: 3, title: ''},
            {language_id: 1, title: ''},
            {language_id: 2, title: ''},
          ],
          link: e.link ? e.link : '',
          order_number: i + 1,
          title: e.title ? e.title : '',
        }));
      newImage.forEach((element) => currentData.push(element));

      // sort new order number
      currentData.forEach((e, index) => (e.order_number = index + 1));

      this.setState({
        images: value.data.map((e) => ({id: e.image_master_id ? e.image_master_id : e.id, url: e.image_master_url ? e.image_master_url : e.url})),
        menuData: currentData,
      });
    } else {
      this.setState({
        images: this.state.menuData.map((e) => ({id: e.image_master_id, url: e.image_master_url})),
      });
    }
  };

  /**
   * onClickMoveUpAndDownData
   * @param {Object} item
   * @param {Number} index
   * @param {String} type
   */
  onClickMoveUpAndDownData = (item, index, type) => {
    const data = this.state.menuData;
    const diff = type === 'UP' ? -1 : 1;
    const replaceItem = data.find((e) => e.order_number === item.order_number + diff);
    data[index].order_number = item.order_number + diff;
    data[index + diff].order_number = replaceItem.order_number - diff;
    this.setState({
      menuData: _.orderBy(data, 'order_number', 'asc'),
    });
  };

  /**
   * handleAddItem
   */
  handleAddItem = () => {
    const order_number = this.state.menuData?.length + 1;
    this.setState({
      menuData: [
        ...this.state.menuData,
        {
          country_id: this.props.match.params.id,
          id: null,
          image_master_id: null,
          image_master_url: '',
          languages: [
            {language_id: 3, title: ''},
            {language_id: 1, title: ''},
            {language_id: 2, title: ''},
          ],
          link: '',
          order_number: order_number,
          title: '',
        },
      ],
    });
  };

  /**
   * onClickMoveUpAndDownData
   * @param {Object} value
   * @param {Number} index
   */
  handleSelectImage = (value, index) => {
    const data = this.state.menuData;
    data[index].image_master_id = value.id;
    data[index].image_master_url = value.url;
    this.setState({
      menuData: data,
    });
  };

  /**
   * onClickMoveUpAndDownData
   * @param {Number} index
   */
  handleDeleteItem = (index) => {
    const data = [...this.state.menuData];
    data.splice(index, 1);
    this.setState({
      menuData: data,
    });
  };

  /**
   * handleButtonUpdate
   */
  handleButtonUpdate() {
    this.setState({isSubmitForm: true});
    if (this.validator.allValid()) {
      this.setState({
        flg: true,
        message: 'messageCode.updateMenuApp',
      });
    }
  }

  /**
   * handleButtonModalOk
   */
  handleButtonModalOk() {
    this.setState({
      flg: false,
    });
    const payload = {
      country_id: this.state.country?.id,
      slide_requests: this.state.menuData.map((e) => ({id: e.id ? e.id : null, languages: e.languages, image_master_id: e.image_master_id, link: e.link.trim()})),
      displayed: true,
    };
    this.setState({isLoading: true});
    updateMenuAppByGeofenceApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          this.notifyResponse(response.message_code);
          this.forceUpdate();
        } else this.notifyResponse(response.message_code);
        this.setState({isLoading: false});
      },
      () => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.MENU_APP_MANAGEMENT),
    );
  }

  /**
   * handleButtonModalCancel
   */
  handleButtonModalCancel() {
    this.setState({
      flg: false,
    });
  }

  /**
   * notifyResponse
   * @param {string} message
   */
  notifyResponse = (message) => {
    this.props.setMessageModal(modalObj(true, message));
  };

  /**
   * onChangeTitle
   * @param {event} event
   * @param {String} language
   * @param {Number} index
   * @param {Component} thisComponent
   */
  onChangeTitle = (event, language, index, thisComponent) => {
    const languages = [...this.state.menuData][index].languages;
    const languageId = LANGUAGE.find((item) => item.code === language)?.id;
    const thisTitle = languages.find((item) => item.language_id === languageId);
    if (thisTitle) {
      const indexOfThisTitle = languages.indexOf(thisTitle);
      if (event.target.value !== '') {
        languages[indexOfThisTitle] = {
          language_id: languageId,
          title: event.target.value,
        };
      } else languages.splice(indexOfThisTitle, 1);
    } else {
      languages.push({
        language_id: languageId,
        title: event.target.value,
      });
    }
    onChangeListData(thisComponent, this.state.menuData, event.currentTarget.name, index, languages);
  };

  /**
   * render component
   * @return {HTML}
   */
  render() {
    const {t, common, actions} = this.props;
    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
    };

    return (
      <LoadingOverlay active={common.isLoading || this.state.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size menu_app_form">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('route.menuAppDetail')}</h3>
              </Grid>
            </Grid>
          </Container>
          <br></br>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={this.state.isOpenModal}
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1000',
                  }}
                >
                  <>
                    <ImageSelect
                      onClickOk={this.handleButtonOkImageModal}
                      onClickClose={this.handleButtonCloseImageModal}
                      selectImage={(value) => this.handleSelectImage(value, this.state.indexItem)}
                    ></ImageSelect>
                  </>
                </Modal>
              </Container>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('menu_app.titleForm')}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    {/* Country */}
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.country')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <TextField
                        className="field_size_20 field_min_size_250"
                        margin="dense"
                        placeholder={t('common.country')}
                        variant="outlined"
                        value={t(this.state.country.country_code) || ''}
                        disabled={true}
                      />
                    </Grid>
                  </Grid>

                  {/* Photo */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={12} lg={12}>
                      <Grid container alignItems="stretch" className="row_form_item table_border" item xs={12}>
                        <Grid container alignItems="center" justify="center" className="product_entry_table_header_color font_color_white" item xs={3}>
                          {t('menu_app.title')}
                        </Grid>
                        <Grid container alignItems="center" justify="center" className="product_entry_table_header_color font_color_white" item xs={3}>
                          {t('menu_app.link')}
                        </Grid>
                        <Grid container alignItems="center" justify="center" className="product_entry_table_header_color font_color_white" item xs={4}>
                          {t('common.photo')}
                        </Grid>
                        <Grid container alignItems="center" justify="center" className="product_entry_table_header_color font_color_white" item xs={1}>
                          {t('menu_app.order')}
                        </Grid>
                        <Grid container alignItems="center" justify="center" className="product_entry_table_header_color font_color_white" item xs={1}>
                          {t('menu_app.action')}
                        </Grid>
                      </Grid>
                      <Grid container alignItems="center" className="table_border" item xs={12}>
                        {this.state.menuData?.map((item, index) => (
                          <Grid key={index} container alignItems="center" item xs={12}>
                            <Grid item xs={3} container alignItems="center" justify="center">
                              <FormControl variant="outlined" margin="dense" className="width_90">
                                <Box display="flex" flexDirection="column">
                                  <Box>
                                    <TextField
                                      label={<span>({t('common.ja')})</span>}
                                      name="languages"
                                      className="width_100"
                                      margin="dense"
                                      inputProps={{maxLength: 50}}
                                      placeholder={t('placeholder.required', {field: t('menu_app.title')})}
                                      variant="outlined"
                                      value={item.languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.title || ''}
                                      onChange={(event) => this.onChangeTitle(event, 'ja', index, this)}
                                    />
                                    {this.validator.message(
                                      `title_jp_${index}`,
                                      item.languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.title.trim(),
                                      'required',
                                    )}
                                    {this.state.isSubmitForm &&
                                      !this.validator.check(
                                        item.languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.title.trim(),
                                        'required',
                                      ) && (
                                        <FormHelperText id={`title_jp_${index}`} error>
                                          {t('validation.required', {field: `${t('menu_app.title')} (${t('common.ja')})`})}
                                        </FormHelperText>
                                      )}
                                  </Box>
                                  <Box>
                                    <TextField
                                      label={<span>({t('common.vi')})</span>}
                                      name="languages"
                                      className="width_100"
                                      margin="dense"
                                      inputProps={{maxLength: 50}}
                                      placeholder={t('placeholder.required', {field: t('menu_app.title')})}
                                      variant="outlined"
                                      value={item.languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.title || ''}
                                      onChange={(event) => this.onChangeTitle(event, 'vi', index, this)}
                                    />
                                    {this.validator.message(
                                      `title_vi_${index}`,
                                      item.languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.title.trim(),
                                      'required',
                                    )}
                                    {this.state.isSubmitForm &&
                                      !this.validator.check(
                                        item.languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.title.trim(),
                                        'required',
                                      ) && (
                                        <FormHelperText id={`title_vi_${index}`} error>
                                          {t('validation.required', {field: `${t('menu_app.title')} (${t('common.vi')})`})}
                                        </FormHelperText>
                                      )}
                                  </Box>
                                  <Box>
                                    <TextField
                                      label={<span>({t('common.en')})</span>}
                                      name="languages"
                                      className="width_100"
                                      margin="dense"
                                      inputProps={{maxLength: 50}}
                                      placeholder={t('placeholder.required', {field: t('menu_app.title')})}
                                      variant="outlined"
                                      value={item.languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.title || ''}
                                      onChange={(event) => this.onChangeTitle(event, 'en', index, this)}
                                    />
                                    {this.validator.message(
                                      `title_en_${index}`,
                                      item.languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.title.trim(),
                                      'required',
                                    )}
                                    {this.state.isSubmitForm &&
                                      !this.validator.check(
                                        item.languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.title.trim(),
                                        'required',
                                      ) && (
                                        <FormHelperText id={`title_en_${index}`} error>
                                          {t('validation.required', {field: `${t('menu_app.title')} (${t('common.en')})`})}
                                        </FormHelperText>
                                      )}
                                  </Box>
                                </Box>
                              </FormControl>
                            </Grid>
                            <Grid item xs={3} container alignItems="center" justify="center">
                              <FormControl variant="outlined" margin="dense" className="width_90">
                                <TextField
                                  name="link"
                                  className="width_100"
                                  margin="dense"
                                  placeholder={t('placeholder.required', {field: t('menu_app.link')})}
                                  variant="outlined"
                                  value={item.link}
                                  onChange={(event) => onChangeListData(this, this.state.menuData, event.currentTarget.name, index, event.target.value)}
                                />
                                {this.validator.message('link', item.link, 'required')}
                                {this.state.isSubmitForm && !this.validator.check(item.link, 'required') && (
                                  <FormHelperText id="link" error>
                                    {t('validation.required', {field: t('menu_app.link')})}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                            <Grid item xs={4} container alignItems="center" justify="center">
                              {item.image_master_url && (
                                <div className="box_image" style={{width: '70%', height: '150px'}}>
                                  <img className="cropped_image" style={{width: 'inherit', height: 'inherit'}} src={item.image_master_url} alt="cropped" />
                                </div>
                              )}
                              <span>
                                <Button variant="contained" disabled={!permission.canUpdate} color="primary" component="span" onClick={() => this.openImageModal(index)}>
                                  {t('common.photo')}
                                </Button>
                              </span>
                              {this.validator.message('image_master_url', item.image_master_url, 'required')}
                              {this.state.isSubmitForm && !this.validator.check(item.image_master_url, 'required') && (
                                <FormHelperText id="image_master_url" error>
                                  {t('validation.required.choose', {field: t('menu_app.image_master_url')})}
                                </FormHelperText>
                              )}
                            </Grid>
                            <Grid item xs={1} container alignItems="center" justify="center" className="group_button">
                              {index !== 0 && (
                                <Button
                                  color="primary"
                                  variant="contained"
                                  className="button_margin"
                                  endIcon={<MoveUp />}
                                  disabled={!permission.canUpdate}
                                  onClick={() => this.onClickMoveUpAndDownData(item, index, 'UP')}
                                />
                              )}
                              {this.state.menuData[index + 1] && (
                                <Button
                                  color="primary"
                                  variant="contained"
                                  className="button_margin"
                                  endIcon={<MoveDown />}
                                  disabled={!permission.canUpdate}
                                  onClick={() => this.onClickMoveUpAndDownData(item, index, 'DOWN')}
                                />
                              )}
                            </Grid>
                            <Grid item xs={1} container alignItems="center" justify="center" className="group_button">
                              <Button
                                color="primary"
                                variant="contained"
                                className="button_margin button_color_red"
                                endIcon={<DeleteIcon />}
                                onClick={() => this.handleDeleteItem(index)}
                              />
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <Button
                        variant="contained"
                        disabled={!permission.canUpdate || this.state.menuData?.length >= 5}
                        color="primary"
                        component="span"
                        onClick={this.handleAddItem}
                      >
                        {t('common.btnAdd')}
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
                <br></br>

                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {permission.canUpdate && (
                      <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={() => this.handleButtonUpdate()}>
                        {t('common.btnUpdate')}
                      </Button>
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      className="button_margin button_color"
                      onClick={() => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.MENU_APP_MANAGEMENT)}
                    >
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                </Grid>
                <Dialog
                  open={this.state.flg}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal onClickOk={this.handleButtonModalOk.bind(this)} onClickCancel={this.handleButtonModalCancel.bind(this)} message={this.state.message}></SelectModal>
                </Dialog>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>
          <br></br>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
