import React, {Component} from 'react';

import {Card, Container, Grid, Button, Dialog, Paper, TextField, FormControl, Select, MenuItem, TableHead, Table, TableRow, TableCell, TableBody} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import DetailIcon from '@material-ui/icons/Description';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {ROUTE, ROWS_PER_PAGE_OPTIONS, RECOMMEND_TYPE, DATA_NULL, PERMISSION_ACTIONS, RESERVATION_MOBI_TYPE, LANGUAGE} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {popupRecommendDeleteApi, popupRecommendSearchApi} from '../../../services/commonSettingServices';
import {getListGeofence, getListGeofenceByCountry, getAllCountryCode, getAllGeofence} from '../../../stores/common/actions';
import {onChangeTextField, changeUrlParams, getQueryStringFromObject, onChangeSelect, clearBlankValue, getUrlParams} from '../../../utils/common';
import './style.css';

/**
 * Search Recommend
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      country_id: '',
      geofence_id: '',
      name: '',
      title: '',
      description: '',
      service_type: '',
      recommend_type: '',
      created_date_from: '',
      created_date_to: '',
      listGeofenceAll: null,
      id: '',
      index: null,

      isLoading: false,
      isSearch: false,
      flagDelete: false,
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,
      flagExport: false,
      confirmedExport: false,
      latestSearchParams: null,
    };
  }

  /**
   * handleButtonOk
   */
  handleButtonOk = () => {
    this.setState({
      flagDelete: false,
      isLoading: true,
    });
    popupRecommendDeleteApi(this.state.id).then(() => {
      const result = this.state.result;
      result.splice(this.state.index, 1);
      this.setState({
        isLoading: false,
      });
    });
  };

  /**
   * onChangeCountry
   * @param {*} e
   */
  onChangeCountry = (e) => {
    this.setState({
      country_id: e.target.value,
      listGeofenceAll: this.props.common?.all_geofence?.filter((item) => item.country_id === e.target.value),
      geofence_id: '',
    });
  };

  /**
   * handleButtonCancel
   */
  handleButtonCancel = () => {
    this.setState({
      flagDelete: false,
    });
  };

  /**
   * reset
   */
  reset = () => {
    this.setState({
      country_id: '',
      geofence_id: '',
      name: '',
      title: '',
      description: '',
      service_type: '',
      recommend_type: '',
      listGeofenceAll: this.props.common?.all_geofence,
    });
  };

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllGeofence().then(() => this.setState({listGeofenceAll: this.props.common?.all_geofence}));
    await this.props.getListGeofence();
    await this.props.getAllCountryCode();
    if (this.props.location.search) {
      const params = getUrlParams(this.props.location.search);
      this.setState(params, this.handleSearch);
      if (params.country_id) {
        this.setState({
          listGeofenceAll: this.props.common?.all_geofence?.filter((e) => e.country_id === Number(params.country_id)),
        });
      }
    }
  }

  /**
   * handleSearch
   * @param {bool} reset
   *
   */
  handleSearch(reset) {
    if (!this.props.actions.includes(PERMISSION_ACTIONS.SEARCH)) return;
    this.setState({
      isLoading: true,
    });
    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const {currentPage, rowsPerPage, country_id, name, title, service_type, recommend_type, geofence_id} = this.state;
    const queryParams = {
      country_id,
      name: name.trim(),
      title: title.trim(),
      service_type,
      recommend_type,
      geofence_id,
      ...(reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage}),
    };
    popupRecommendSearchApi(clearBlankValue(queryParams)).then((response) => {
      this.setState(
        {
          result: this.handleSearchResult(response?.result?.content || []),
          totalRows: response?.result?.totalSize,
          isSearch: true,
          latestSearchParams: queryParams,
          isLoading: false,
        },
        () => {
          const {geofence_id, service_type, recommend_type, title, name, country_id, currentPage, rowsPerPage} = this.state;
          // Apply changed params into url
          const queryParamsToChange = {
            country_id,
            name: name,
            title,
            service_type,
            recommend_type,
            geofence_id,
            currentPage: String(currentPage),
            rowsPerPage,
          };
          changeUrlParams(queryParamsToChange);
          const newSearchParams = getQueryStringFromObject(queryParamsToChange);
          // Save search params into state in order to pass to next page
          this.setState({searchParams: newSearchParams});
        },
      );
    });
  }

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearch);
  };

  /**
   * convertGeofenceIdToName
   * @param {*} geofence
   * @return {*}
   */
  convertGeofenceIdToName = (geofence) => {
    return geofence.enable ? <div>{geofence?.name}</div> : <strike>{geofence?.name}</strike>;
  };

  /**
   * handleSearchResult
   * @param {*} searchData
   * @return {*}
   */
  handleSearchResult = (searchData) => {
    return searchData.map((data) => ({
      ...data,
      geofencesName: this.getGeofencesNameFromIds(data?.geofence_ids ? data?.geofence_ids[0] : null),
    }));
  };

  /**
   * getGeofencesNameFromIds
   * @param {*} geofence_ids
   * @return {*}
   */
  getGeofencesNameFromIds = (geofence_ids) => {
    if (geofence_ids) {
      const {listGeofenceAll} = this.state;
      const geofences_details = listGeofenceAll?.find((item) => item.geofence_id === geofence_ids);
      return {name: geofences_details?.name, enable: geofences_details?.enable};
    } else {
      return {name: null, enable: null};
    }
  };

  /**
   * handleDelete
   * @param {*} id
   * @param {*} index
   */
  handleDelete = (id, index) => {
    this.setState({
      flagDelete: true,
      message: 'recommend.question.confirm.delete',
      id,
      index,
    });
  };

  /**
   * renderListGeofence
   * @param {Array} geofences
   * @return {*}
   */
  renderListGeofence(geofences) {
    const {common} = this.props;
    return geofences?.map((item, index) => <li key={index}>{common?.all_geofence?.find((e) => e.geofence_id === item)?.name}</li>);
  }

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t, common, actions} = this.props;
    const {isLoading} = this.state;
    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canRegister: actions.includes(PERMISSION_ACTIONS.REGISTER),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
      canDelete: actions.includes(PERMISSION_ACTIONS.DELETE),
    };

    return (
      <Card className="main_card_min_size popup_recommend">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.recommend')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
        </Container>
        <br></br>
        <LoadingOverlay active={isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                {permission.canSearch && (
                  <>
                    <br></br>
                    <Paper className="search_table">
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                          {t('businessVehicle.searchTitle')}
                        </Grid>
                      </Grid>
                      {/* Country */}
                      <Grid container spacing={1} className="row_form_item ">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.country')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'country_id',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.country_id ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('common.country'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.country_id}
                              onChange={this.onChangeCountry}
                            >
                              {common?.country_code?.map((item, idx) => (
                                <MenuItem value={item.id} key={idx}>
                                  {t(`${item.country_code}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Geofence */}
                      <Grid container spacing={1} className="row_form_item ">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.geofence')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'geofence_id',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.geofence_id ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('common.geofence'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.geofence_id}
                              onChange={(e) => onChangeSelect(this, e)}
                            >
                              {this.state.listGeofenceAll?.map((item, idx) => (
                                <MenuItem value={item.geofence_id} key={idx} className={!item.enable ? 'disable-option-custom' : ''}>
                                  {t(`${item.name}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Service Type */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('popup_recommend.service_type')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'service_type',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.service_type ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('popup_recommend.service_type'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.service_type}
                              onChange={(e) => onChangeSelect(this, e)}
                            >
                              {RESERVATION_MOBI_TYPE.map((item, idx) => {
                                return (
                                  <MenuItem value={item.id} key={idx}>
                                    {t(`${item.i18n}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Recommend Type */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('popup_recommend.recommend_type')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'recommend_type',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.recommend_type ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('popup_recommend.recommend_type'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.recommend_type}
                              onChange={(e) => onChangeSelect(this, e)}
                            >
                              {RECOMMEND_TYPE.map((item, idx) => {
                                return (
                                  <MenuItem value={item.id} key={idx}>
                                    {t(`${item.i18n}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Header */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('popup_recommend.header')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="name"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('validation.required', {field: t('popup_recommend.header')})}
                            value={this.state.name}
                            onChange={(event) => onChangeTextField(this, event)}
                            inputProps={{maxLength: 256}}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>

                      {/* title */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('popup_recommend.title')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="title"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('validation.required', {field: t('popup_recommend.title')})}
                            value={this.state.title}
                            onChange={(event) => onChangeTextField(this, event)}
                            inputProps={{maxLength: 256}}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </>
                )}
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.reset} endIcon={<RotateLeftIcon />}>
                        {t('common.btnResetSearch')}
                      </Button>
                    )}
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {permission.canRegister && (
                      <Link
                        style={{textDecoration: 'none'}}
                        to={{pathname: ROUTE.LAYOUT + ROUTE.RECOMMEND_CREATE, state: {from: this.props.location.pathname + '?' + this.state.searchParams}}}
                      >
                        <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />}>
                          {t('common.btnRegister')}
                        </Button>
                      </Link>
                    )}
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin" endIcon={<SearchIcon />} onClick={() => this.handleSearch(true)}>
                        {t('common.btnSearch')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>
          <Container maxWidth="xl">
            {this.state.isSearch && (
              <Card raised>
                <Container maxWidth="xl">
                  <br></br>
                  <Grid container spacing={1}>
                    <Grid container alignItems="center" justify="flex-start" item xs={3}>
                      <h3>
                        {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                      </h3>
                    </Grid>
                  </Grid>

                  <div className="scroll_area_700">
                    {this.state.result?.length > 0 && (
                      <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                        <TableHead>
                          <TableRow>
                            <TableCell className="width_100p">{t('popup_recommend.recommend_id')}</TableCell>
                            <TableCell className="width_100p">{t('common.country')}</TableCell>
                            <TableCell className="width_150p">{t('common.geofence')}</TableCell>
                            <TableCell className="width_150p">{t('popup_recommend.header')} (JP)</TableCell>
                            <TableCell className="width_150p">{t('popup_recommend.header')} (VN)</TableCell>
                            <TableCell className="width_150p">{t('popup_recommend.header')} (EN)</TableCell>
                            <TableCell className="width_150p">{t('popup_recommend.title')} (JP)</TableCell>
                            <TableCell className="width_150p">{t('popup_recommend.title')} (VN)</TableCell>
                            <TableCell className="width_150p">{t('popup_recommend.title')} (EN)</TableCell>
                            <TableCell className="width_150p">{t('popup_recommend.service_type')}</TableCell>
                            <TableCell className="width_125p">{t('popup_recommend.recommend_type')}</TableCell>
                            {(permission.canEdit || permission.canDelete) && (
                              <TableCell className="width_150p" style={{position: 'sticky', right: '0'}}>{t('common.action')}</TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.result?.map((row, index) => {
                            const service_type = RESERVATION_MOBI_TYPE.find((item) => item.id === row?.service_types[0]);
                            const recommend_type = RECOMMEND_TYPE.find((item) => item.id === row?.recommend_type);
                            const country = this.props.common?.country_code?.find((item) => item.id === row.country_id);
                            return (
                              <TableRow key={index} hover className="cursor_pointer">
                                <TableCell>{row?.id}</TableCell>
                                <TableCell>{t(country?.country_code) || DATA_NULL}</TableCell>
                                <TableCell>
                                  <ul>
                                    {row?.geofence_config_infos?.length > 0 ?
                                      row?.geofence_config_infos.map((item) => <div>{item.name}</div>) :
                                      this.renderListGeofence(row?.geofence_ids) || DATA_NULL}
                                  </ul>
                                </TableCell>
                                <TableCell>{row?.languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.name || DATA_NULL}</TableCell>
                                <TableCell>{row?.languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.name || DATA_NULL}</TableCell>
                                <TableCell>{row?.languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.name || DATA_NULL}</TableCell>
                                <TableCell>{row?.languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.title || DATA_NULL}</TableCell>
                                <TableCell>{row?.languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.title || DATA_NULL}</TableCell>
                                <TableCell>{row?.languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.title || DATA_NULL}</TableCell>
                                <TableCell>{t(service_type?.i18n)}</TableCell>
                                <TableCell>{t(recommend_type?.i18n)}</TableCell>
                                {(permission.canEdit || permission.canDelete) && (
                                  <TableCell className="cell_fixed_right">
                                    {permission.canEdit && (
                                      <Link
                                        style={{textDecoration: 'none'}}
                                        to={{
                                          pathname: ROUTE.LAYOUT + ROUTE.RECOMMEND_DETAILS + '/' + row.id,
                                          state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                        }}
                                      >
                                        <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                          {t('common.btnEdit')}
                                        </Button>
                                      </Link>
                                    )}
                                    {permission.canDelete && (
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        className="button_margin button_color_red"
                                        endIcon={<DeleteIcon />}
                                        onClick={() => this.handleDelete(row.id, index)}
                                      >
                                        {t('common.btnDelete')}
                                      </Button>
                                    )}
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    )}
                  </div>
                  <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                </Container>
                <br></br>
                <Dialog
                  open={this.state.flagDelete}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    onClickOk={this.handleButtonOk}
                    onClickCancel={this.handleButtonCancel}
                    message={this.state.message}
                    okButtonText={t('common.btnYes')}
                    cancelButtonText={t('common.btnNo')}
                  ></SelectModal>
                </Dialog>
                {/* Export alert modal */}
                <Dialog
                  open={this.state.flagExport}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    onClickOk={this.handleButtonExportOk}
                    onClickCancel={this.handleButtonExportCancel}
                    okButtonText={t('common.btnYes')}
                    cancelButtonText={t('common.btnNo')}
                    message={this.state.message}
                  ></SelectModal>
                </Dialog>
              </Card>
            )}
            <br></br>
          </Container>
        </LoadingOverlay>
        <br></br>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListGeofence: () => dispatch(getListGeofence()),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getListGeofenceByCountry: (id) => dispatch(getListGeofenceByCountry(id)),
    getAllGeofence: () => dispatch(getAllGeofence()),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
