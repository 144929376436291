import React, {Component} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {Container, Paper, Grid, Button, FormControl, Select, MenuItem, TextField, Card} from '@material-ui/core';
import ForwardIcon from '@material-ui/icons/TrendingFlat';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {COUNTRY, ROUTE, TICKET_TYPE} from '../../../common/constant';
import MuiPhoneNumberInput from '../../../components/mui-phone-number-input';
import {checkOwnerCard} from '../../../stores/business/action';
import {getAllCountryCode} from '../../../stores/common/actions';
import {buyTicket, getMemberDetail, updateDetailTicket} from '../../../stores/member/action';
import {onChangeTextField, onChangeSelect, redirectRouter, customDisplayCurrency} from '../../../utils/common';

/**
 * Confirm Ticket
 */
class ConfirmTicket extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      firstName: '',
      lastName: '',
      last_name_furigana: '',
      first_name_furigana: '',
      mobile: '',
      email: '',
      birthday: null,

      country_id: '',
      supplier_id: '',
      arrival_station: '',
      departure_station: '',
      ticket_type: '',
      ticket_use_type: '',
      payment_method: true,

      station: {},
      dataCountry: {
        countryCode: 'jp',
        dialCode: '81',
        name: 'Japan',
      },
      cardInfo: [],
      isSearch: false,
      ticketInfor: {},
      body: {},
      cacheData: {},
      routeData: [],
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    const data = this.props.history?.location?.state;
    if (data) {
      this.setState({
        routeData: data?.lines,
        body: data?.body,
        ticketInfor: data?.ticketInfor,
        id: data.ticketInfor?.user_id,
        cacheData: data?.cacheData,
        dataCountry: data?.ticketInfor?.dataCountry,
        mobile: data?.ticketInfor?.mobile,
      });
      this.confirmCreditCard(data.ticketInfor?.user_id);
    } else {
      this.props.history.push({
        pathname: ROUTE.LAYOUT + ROUTE.MEMBER_MANAGEMENT,
      });
    }
    await this.props.getAllCountryCode();
  }

  /**
   * confirmCreditCard
   * @param {*} userId
   */
  async confirmCreditCard(userId) {
    this.props.checkOwnerCard(userId).then((response) => {
      const cardInfo = [];
      for (const card in response) {
        if (response[card].length > 0) {
          const cardData = response[card]?.find((item) => Number(item.default_card) === 1);
          if (cardData && card === 'veritrans_cards') {
            cardInfo.push({
              ...cardData,
              cardType: card === 'veritrans_cards' ? 'Veritrans Card' : '2c2p Card',
            });
          }
        }
      }
      this.setState({
        cardInfo,
      });
    });
  }

  /**
   * buyTicket
   */
  buyTicket = () => {
    this.props.buyTicket(this.state.id, this.state.body).then((response) => {
      if (response) {
        redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.RESERVATION_MANAGEMENT_QR_TICKET_DETAIL + `/${response}`);
      }
    });
  };

  /**
   * getTraferStation
   * @return {*}
   */
  getTraferStation = () => {
    if (this.state.body) {
      let transferStation = '';
      // eslint-disable-next-line no-unused-expressions
      this.state.body.paths_response?.details.forEach((detail, index) => {
        const lineName =
          detail.route_detail.name + ((index < this.state.body.paths_response?.details?.length - 1) ?
            ('・' + this.state.body.paths_response?.stations.find((station) => station.id === detail.stations[1])?.name + '・') :
            '');
        transferStation += lineName;
      });
      return transferStation.trim();
    }
  };

  /**
   * handleBackPage
   */
  handleBackPage = () => {
    this.props.history.push({
      pathname: ROUTE.LAYOUT + ROUTE.MEMBER_ROUTE_LIST + `/${this.props.match.params.userId}`,
      state: {
        body: this.state.body,
        ticketInfor: this.state.ticketInfor,
        cacheData: this.state.cacheData,
        lines: this.state.routeData,
      },
    });
  };

  /**
   * Render component
   * @return {object}
   */
  render() {
    const {t, common, isLoading} = this.props;

    return (
      <LoadingOverlay active={isLoading || common.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size plan_form">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('route.register_railway_ticket')}</h3>
              </Grid>
            </Grid>
          </Container>
          <br></br>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('ticket.confirm')}
                    </Grid>
                  </Grid>
                  {/* Country */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('common.country')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'country',
                          }}
                          disabled
                          value={this.state.ticketInfor?.countryCode || 'JP'}
                          onChange={(event) => onChangeSelect(this, event)}
                        >
                          {common?.country_code?.map((item, idx) => (
                            <MenuItem value={item.country_code} key={idx}>
                              {t(`${item.country_code}`)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Business Name */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('driver.supplier.name')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <TextField margin="dense" variant="outlined" fullWidth value={this.state.ticketInfor?.businessName || ''} name="businessName" disabled />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* last name - first name */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('updateMember.lastName')}
                    </Grid>
                    <Grid container alignItems="center" item xs={9} lg={3} className="grid_title_padding">
                      <FormControl className="field_size_10 field_min_size_300">
                        <TextField margin="dense" variant="outlined" fullWidth value={this.state.ticketInfor?.lastName || ''} name="lastName" disabled />
                      </FormControl>
                    </Grid>
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('updateMember.firstName')}
                    </Grid>
                    <Grid container alignItems="center" item xs={9} lg={3} className="grid_title_padding">
                      <FormControl className="field_size_10 field_min_size_300">
                        <TextField margin="dense" variant="outlined" fullWidth value={this.state.ticketInfor?.firstName || ''} name="firstName" disabled />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* last name furigana - first name furigana */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('updateMember.lastName_furigana')}
                    </Grid>
                    <Grid container alignItems="center" item xs={9} lg={3} className="grid_title_padding">
                      <FormControl className="field_size_10 field_min_size_300">
                        <TextField margin="dense" variant="outlined" fullWidth value={this.state.ticketInfor?.lastNameFurigana || ''} name="last_name_furigana" disabled />
                      </FormControl>
                    </Grid>
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('updateMember.firstName_furigana')}
                    </Grid>
                    <Grid container alignItems="center" item xs={9} lg={3} className="grid_title_padding">
                      <FormControl className="field_size_10 field_min_size_300">
                        <TextField margin="dense" variant="outlined" fullWidth value={this.state.ticketInfor?.firstNameFurigana || ''} name="first_name_furigana" disabled />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* birthday - mobile */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('memberManagement.birthDay')}
                    </Grid>
                    <Grid container alignItems="center" item xs={9} lg={3} className="grid_title_padding">
                      <FormControl className="field_size_10 field_min_size_300">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            variant="inline"
                            views={['year', 'month', 'date']}
                            margin="dense"
                            autoOk
                            inputVariant="outlined"
                            minDate="1900-01-01"
                            maxDate={new Date()}
                            format="yyyy-MM-dd"
                            value={this.state.ticketInfor?.birthday || null}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            disabled
                            invalidDateMessage={t('errorFields.invalidDateMessage')}
                            maxDateMessage={t('errorFields.maxDateMessage')}
                            minDateMessage={t('errorFields.minDateMessage')}
                          />
                        </MuiPickersUtilsProvider>
                      </FormControl>
                    </Grid>
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('common.phoneNumber')}
                    </Grid>
                    <Grid container alignItems="center" item xs={9} lg={3} className="grid_title_padding">
                      <FormControl className="field_size_10 field_min_size_300">
                        <MuiPhoneNumberInput
                          ref="mobile"
                          defaultCountry={this.state.dataCountry?.countryCode}
                          onlyCountries={COUNTRY.map((c) => c.countryCode)}
                          countryCodeEditable={false}
                          enableLongNumbers={true}
                          autoFormat={false}
                          margin="dense"
                          variant="outlined"
                          name="mobile"
                          value={this.state.mobile}
                          disabled
                          onChange={(value, country) => {
                            this.setState({dataCountry: country}, () => {
                              this.setState({mobile: value});
                            });
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* email */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('common.email')}
                    </Grid>
                    <Grid container alignItems="flex-start" item xs={6} lg={2} className="grid_title_padding">
                      <FormControl className="field_size_10 field_min_size_300">
                        <TextField margin="dense" variant="outlined" fullWidth name="email" inputProps={{maxLength: 64}} value={this.state.ticketInfor?.email || ''} disabled />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Departure Station */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('ticket.departure_station')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <TextField margin="dense" variant="outlined" fullWidth name="departureStationName" value={this.state.ticketInfor?.departureStationName || ''} disabled />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Arrival Station */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('ticket.arrival_station')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <TextField margin="dense" variant="outlined" fullWidth name="arrivalStationName" value={this.state.ticketInfor?.arrivalStationName || ''} disabled />
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Transfer Station */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('ticket.transfer_station')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <TextField margin="dense" variant="outlined" fullWidth name="arrivalStationName" value={this.getTraferStation()} disabled />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Ticket type */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('ticket.ticket_type')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <TextField
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          name="ticketTypeName"
                          value={this.state.ticketInfor?.ticketTypeName === TICKET_TYPE[0].id ? t(TICKET_TYPE[0].i18n) : t(TICKET_TYPE[1].i18n)}
                          disabled
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Commuter pass type - Coupon ticket type */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {this.state.ticketInfor?.ticketTypeName === TICKET_TYPE[0].id ? t('ticket.commuter_pass_type') : t('ticket.coupon_ticket_type')}{' '}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <TextField margin="dense" variant="outlined" fullWidth name="ticketUseTypeName" value={this.state.ticketInfor?.ticketUseTypeName || ''} disabled />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* start_date */}
                  {this.state.ticketInfor?.ticketTypeName === TICKET_TYPE[0].id && (
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                        {t('ticket.start_date_of_use')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4} className="grid_title_padding">
                        <FormControl>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              autoOk
                              disableToolbar
                              variant="inline"
                              placeholder={t('ticket.start_date_of_use')}
                              inputVariant="outlined"
                              minDate="2000-01-01"
                              format="yyyy/MM/dd"
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              disabled
                              value={this.state.ticketInfor?.startDate}
                              onChange={(time) => this.setState({start_date: time})}
                            />
                          </MuiPickersUtilsProvider>
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}

                  {/* end_date */}
                  {this.state.ticketInfor?.ticketTypeName === TICKET_TYPE[0].id && (
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                        {t('ticket.end_date_of_use')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4} className="grid_title_padding">
                        <FormControl>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              autoOk
                              disableToolbar
                              variant="inline"
                              placeholder={t('ticket.end_date_of_use')}
                              inputVariant="outlined"
                              minDate="2000-01-01"
                              format="yyyy/MM/dd"
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              disabled
                              value={
                                new Date(this.state.ticketInfor?.startDate).setMonth(
                                  new Date(this.state.ticketInfor?.startDate).getMonth() + this.state.ticketInfor?.validityPeriod,
                                ) -
                                60 * 60 * 24 * 1000
                              }
                            />
                          </MuiPickersUtilsProvider>
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}

                  {/* Payment method */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('ticket.payment_method')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <TextField
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          value={t(this.state.ticketInfor?.paymenMethod)}
                          name="payment_method"
                          disabled
                          onChange={(event) => onChangeTextField(this, event)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Amount */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('ticket.amount')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <TextField
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          value={`${customDisplayCurrency(this.state.body?.paths_response?.total_amount)}`}
                          name="amount"
                          disabled
                          onChange={(event) => onChangeTextField(this, event)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Credit card information */}
                  {this.state.payment_method && (
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding"></Grid>
                      <Grid container alignItems="center" item xs={6} lg={6} className="grid_title_padding">
                        {this.state.cardInfo.length > 0 ? (
                          this.state.cardInfo?.map((item, index) => (
                            <React.Fragment key={index}>
                              <Grid container justify="flex-start" alignItems="flex-start" item xs={2}>
                                {'JP'}
                              </Grid>
                              <Grid container justify="flex-start" alignItems="flex-start" item xs={1}>
                                <ForwardIcon
                                  style={{
                                    position: 'relative',
                                    fontSize: 30,
                                    top: -6,
                                    color: '#3f51b5',
                                  }}
                                ></ForwardIcon>
                              </Grid>
                              <Grid container justify="flex-start" alignItems="flex-start" item xs={4}>
                                {t('memberManagement.cardNumber')}:{' '}
                                <span className="font_color_blue" style={{marginLeft: '10px'}}>
                                  {item?.card_number}
                                </span>
                              </Grid>
                              <Grid container justify="flex-start" alignItems="flex-start" item xs={4}>
                                {t('memberManagement.cardExpire')}:{' '}
                                <span className="font_color_blue" style={{marginLeft: '10px'}}>
                                  {item?.card_expire}
                                </span>
                              </Grid>
                            </React.Fragment>
                          ))
                        ) : (
                          <>
                            <Grid container justify="flex-start" alignItems="flex-start" item xs={12}>
                              <span className="font_color_red">{t('memberManagement.cantGetCardInfo')}</span>
                            </Grid>
                            <Grid container justify="flex-start" alignItems="flex-start" item xs={12}></Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Paper>
                <br></br>

                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="center" item xs={12}>
                    <Button color="primary" variant="contained" className="button_magin" onClick={this.buyTicket}>
                      {t('ticket.buy_ticket')}
                    </Button>
                    <Button color="primary" variant="contained" className="button_magin button_color" style={{marginLeft: 5}} onClick={this.handleBackPage}>
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                </Grid>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>
          <br></br>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    business: state.business,
    isLoading: state.member.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getMemberDetail: (id) => dispatch(getMemberDetail(id)),
    updateDetailTicket: (payload) => dispatch(updateDetailTicket(payload)),
    checkOwnerCard: (userId) => dispatch(checkOwnerCard(userId)),
    buyTicket: (userId, payload) => dispatch(buyTicket(userId, payload)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(ConfirmTicket));
