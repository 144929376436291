import {applyMiddleware, createStore} from 'redux';
import logger from 'redux-logger';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/es/storage';
import thunk from 'redux-thunk';

import Config from '../common/config';
import reducers from './reducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['home', 'reservation', 'carts'], // whitelist redux
  blacklist: ['authen', 'account', 'modal', 'password', 'vehicles', 'delivery', 'facility', 'image'],
  // blacklist redux
};
const rootReduces = persistReducer(persistConfig, reducers);

const configureStore = () => {
  if (Config.DEBUG) {
    return {
      ...createStore(rootReduces, applyMiddleware(thunk, logger)),
    };
  }
  return {
    ...createStore(rootReduces, applyMiddleware(thunk)),
  };
};

const store = configureStore();
export const persistor = persistStore(store);
export default store;
