export const ACTION_TYPES = {
  SEARCH_LIST_CANCELATTION_FEE_START: 'SEARCH_LIST_CANCELATTION_FEE_START',
  SEARCH_LIST_CANCELATTION_FEE_SUCCESS: 'SEARCH_LIST_CANCELATTION_FEE_SUCCESS',
  SEARCH_LIST_CANCELATTION_FEE_FAIL: 'SEARCH_LIST_CANCELATTION_FEE_FAIL',

  CREATE_CANCELLATION_FEE_START: 'CREATE_CANCELLATION_FEE_START',
  CREATE_CANCELLATION_FEE_SUCCESS: 'CREATE_CANCELLATION_FEE_SUCCESS',
  CREATE_CANCELLATION_FEE_FAIL: 'CREATE_CANCELLATION_FEE_FAIL',

  GET_CANCELLATION_FEE_DETAIL_START: 'GET_CANCELLATION_FEE_DETAIL_START',
  GET_CANCELLATION_FEE_DETAIL_SUCCESS: 'GET_CANCELLATION_FEE_DETAIL_SUCCESS',
  GET_CANCELLATION_FEE_DETAIL_FAIL: 'GET_CANCELLATION_FEE_DETAIL_FAIL',

  UPDATE_CANCELLATION_FEE_DETAIL_START: 'UPDATE_CANCELLATION_FEE_DETAIL_START',
  UPDATE_CANCELLATION_FEE_DETAIL_SUCCESS: 'UPDATE_CANCELLATION_FEE_DETAIL_SUCCESS',
  UPDATE_CANCELLATION_FEE_DETAIL_FAIL: 'UPDATE_CANCELLATION_FEE_DETAIL_FAIL',

  DELETE_CANCELLATION_FEE_DETAIL_START: 'DELETE_CANCELLATION_FEE_DETAIL_START',
  DELETE_CANCELLATION_FEE_DETAIL_SUCCESS: 'DELETE_CANCELLATION_FEE_DETAIL_SUCCESS',
  DELETE_CANCELLATION_FEE_DETAIL_FAIL: 'DELETE_CANCELLATION_FEE_DETAIL_FAIL',
};

const initialState = {
  isLoading: false,
  list: [],
  cancelFee: {},
};

/**
 * function reduce
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.SEARCH_LIST_CANCELATTION_FEE_START:
    case ACTION_TYPES.CREATE_CANCELLATION_FEE_START:
    case ACTION_TYPES.GET_CANCELLATION_FEE_DETAIL_START:
    case ACTION_TYPES.UPDATE_CANCELLATION_FEE_DETAIL_START:
    case ACTION_TYPES.DELETE_CANCELLATION_FEE_DETAIL_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.SEARCH_LIST_CANCELATTION_FEE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.list,
      };
    case ACTION_TYPES.SEARCH_LIST_CANCELATTION_FEE_FAIL:
      return {
        ...state,
        isLoading: false,
        list: [],
      };
    case ACTION_TYPES.GET_CANCELLATION_FEE_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cancelFee: action.cancelFee,
      };
    case ACTION_TYPES.GET_CANCELLATION_FEE_DETAIL_FAIL:
      return {
        ...state,
        isLoading: false,
        cancelFee: {},
      };
    default:
      return state;
  }
}

