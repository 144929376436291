import {api} from './api';

const getPaymentsUrl = '/admin/payment';
const getPaymentDetailUrl = '/admin/payment/{id}';

export const getPaymentsApi = (params) => {
  return api.get(getPaymentsUrl, params);
};

export const getPaymentDetailApi = (id) => {
  return api.get(getPaymentDetailUrl.replace('{id}', id));
};
