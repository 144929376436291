import React, {Component} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Card,
  Container,
  FormControl,
  FormGroup,
  Grid,
  Paper,
  TextField,
  Dialog,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {OWNER_TYPE, QR_CODE_CATEGORY, DISCOUNT_TYPE, COMMON_STATUS, TIME_FORMAT} from '../../../common/constant';
import CustomTimePicker from '../../../components/CustomTimePicker';
import SelectModal from '../../../components/selectModal';
import {getAllCountryCode, getAllGeofence} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {onChangeTextField, onChangeSelect} from '../../../utils/common';
import withPermissionData from '../../../withPermissionData/hoc';
// import {modalObj} from '../../../utils/modal';

/**
 * QR Code Details
 */
class QRForm extends Component {
  /**
   * constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      country_id: '',
      geofence_id: '',
      owner: '',
      partner: '',
      listGeofenceAll: [],
      status: '',
      start_date: null,
      end_date: null,
      waypoints: [],
      listOwner: [],
      listWaypoint: [],
      list_member: [],
      category: '',
      description: '',
      operation_time: [
        {
          from: '',
          to: '',
        },
      ],
      all_member: false,
      members: [],
      quantity: '',
      usage_limit: '',
      discount_type: '',
      discount_amount: '',

      isSubmitForm: false,
      showModal: false,
      modalMessage: '',
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * onChangeCountry
   * @param {*} value
   */
  onChangeCountry = (value) => {
    this.setState({
      country_id: value,
      geofence_id: '',
      listGeofenceAll: this.props.common?.all_geofence?.filter((e) => e.country_id + '' === value + ''),
    });
  };

  /**
   * handleUpdate
   */
  handleUpdate = async () => {};

  /**
   * openModal
   */
  openModal = () => {
    this.setState({isSubmitForm: true});
    if (!this.validator.allValid()) return;
    this.setState({showModal: true});
  };

  /**
   * closeModal
   */
  closeModal = () => {
    this.setState({showModal: false});
  };

  /**
   * addItem
   */
  addItem = async () => {
    this.setState({
      operation_time: [...this.state.operation_time, {}],
    });
  };

  /**
   * deleteRows
   * @param {Number} index
   */
  deleteRows = (index) => {
    const operation_time = this.state.operation_time;
    operation_time.splice(index, 1);
    this.setState({operation_time});
  };

  /**
   * changeOperatingHour
   * @param {*} index
   * @param {*} value
   * @param {*} isStart
   */
  changeOperatingHour = async (index, value, isStart) => {
    const operation_time = this.state.operation_time;
    isStart ? (operation_time[index].start_time = value || '') : (operation_time[index].end_time = value || '');
    this.setState({
      operation_time,
    });
  };

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllCountryCode();
    await this.props.getAllGeofence();
    if (this.props.match.params.id) {
    }
  }

  /**
   * changeWaypoint
   * @param {*} value
   * @param {*} item
   */
  changeWaypoint = (value, item) => {};

  /**
   * Render component
   * @return {*}
   */
  render() {
    const {t} = this.props;
    const isEdit = this.props.match.params.id;

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container spacing={1} className="page_header">
            <Grid container alignItems="center" item xs={6}>
              {isEdit ? <h3>{t('qr_code.detailTitle')}</h3> : <h3>{t('qr_code.createTitle')}</h3>}
            </Grid>
          </Grid>
        </Container>
        <br></br>
        <Container maxWidth="xl">
          <Card raised>
            <Container maxWidth="xl">
              <br></br>
              <Paper className="search_table">
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                    {t('qr_code.titleForm')}
                  </Grid>
                </Grid>
                {/* Country */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('common.country')}
                    <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl error>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'country',
                          }}
                          displayEmpty
                          value={this.state.country_id || ''}
                          onChange={(event) => this.onChangeCountry(event.target.value)}
                          renderValue={
                            this.state.country_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.country'),
                                    })}
                                  </div>
                                )
                          }
                        >
                          {this.props.common?.country_code?.map((item, idx) => (
                            <MenuItem value={item.id} key={idx}>
                              {t(`${item.country_code}`)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </FormControl>
                  </Grid>
                </Grid>

                {/* geofence */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('common.geofence')}
                    <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                      <Select
                        margin="dense"
                        inputProps={{
                          name: 'geofence_id',
                        }}
                        displayEmpty
                        renderValue={
                          this.state.geofence_id ?
                            undefined :
                            () => (
                                <div className="font-12 color-disabled">
                                  {t('placeholder.required_select', {
                                    field: t('common.geofence'),
                                  })}
                                </div>
                              )
                        }
                        value={this.state.geofence_id || ''}
                        onChange={(e) => onChangeSelect(this, e)}
                      >
                        {this.state.listGeofenceAll.map((item, idx) => {
                          return (
                            <MenuItem value={item.geofence_id} key={idx}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                {/* Owner */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('qr_code.owner')}
                    <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                      <Select
                        margin="dense"
                        inputProps={{
                          name: 'owner',
                        }}
                        displayEmpty
                        renderValue={
                          this.state.owner ?
                            undefined :
                            () => (
                                <div className="font-12 color-disabled">
                                  {t('placeholder.required_select', {
                                    field: t('qr_code.owner'),
                                  })}
                                </div>
                              )
                        }
                        value={this.state.owner || ''}
                        onChange={(e) => onChangeSelect(this, e)}
                      >
                        {OWNER_TYPE.map((item, idx) => {
                          return (
                            <MenuItem value={item.id} key={idx}>
                              {t(item.i18n)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                {/* partner/company name */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('qr_code.partner')}
                    <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                      <Select
                        margin="dense"
                        inputProps={{
                          name: 'partner',
                        }}
                        displayEmpty
                        renderValue={
                          this.state.partner ?
                            undefined :
                            () => (
                                <div className="font-12 color-disabled">
                                  {t('placeholder.required_select', {
                                    field: t('qr_code.partner'),
                                  })}
                                </div>
                              )
                        }
                        value={this.state.partner || ''}
                        onChange={(e) => onChangeSelect(this, e)}
                      >
                        {this.state.listOwner.map((item, idx) => {
                          return (
                            <MenuItem value={item.id} key={idx}>
                              {item.value}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                {/* waypoint */}
                {this.state.owner !== 'Company' && this.state.owner !== '' && (
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.waypoint')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined">
                        <FormGroup row>
                          {this.state.listWaypoint.map((item, index) => {
                            const checked = this.state.waypoints.indexOf(item) > -1;
                            return (
                              <FormControlLabel
                                key={index}
                                labelPlacement="end"
                                control={
                                  <Checkbox
                                    key={index}
                                    checked={checked}
                                    onChange={(event) => this.changeWaypoint(event.target.checked, item.geofence_id)}
                                    className="checkbox_radio"
                                  />
                                }
                                label={item.name}
                              />
                            );
                          })}
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}

                {/* Category */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('qr_code.category')}
                    <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                      <Select
                        margin="dense"
                        inputProps={{
                          name: 'category',
                        }}
                        displayEmpty
                        renderValue={
                          this.state.category ?
                            undefined :
                            () => (
                                <div className="font-12 color-disabled">
                                  {t('placeholder.required_select', {
                                    field: t('qr_code.category'),
                                  })}
                                </div>
                              )
                        }
                        value={this.state.category || ''}
                        onChange={(e) => onChangeSelect(this, e)}
                      >
                        {QR_CODE_CATEGORY.map((item, idx) => {
                          return (
                            <MenuItem value={item} key={idx}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                {/* qr name */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('common.name')}
                    <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl variant="outlined" margin="dense" fullWidth>
                      <TextField
                        name="name"
                        className="field_size_10 field_min_size_300"
                        margin="dense"
                        placeholder={t('validation.required', {field: t('common.name')})}
                        value={this.state.name}
                        onChange={(event) => onChangeTextField(this, event)}
                        inputProps={{maxLength: 256}}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                {/* description */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('stop_master.description')}
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl variant="outlined" margin="dense" fullWidth>
                      <TextField
                        name="description"
                        multiline
                        rows="3"
                        className="field_size_10 field_min_size_300"
                        margin="dense"
                        placeholder={t('validation.required', {field: t('stop_master.description')})}
                        value={this.state.description}
                        onChange={(event) => onChangeTextField(this, event)}
                        inputProps={{maxLength: 256}}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                {/* usage start/end */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('qr_code.usage_date')}
                    <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={8}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className="field_size_20 field_min_size_300"
                        margin="dense"
                        autoOk
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format="yyyy/MM/dd"
                        value={this.state.start_date}
                        label={t('businessVehicle.start_date')}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                        minDate={new Date()}
                        onChange={(time) =>
                          this.setState({
                            start_date: time,
                          })
                        }
                      />
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className="field_size_20 field_min_size_300 ml-20-custom"
                        margin="dense"
                        autoOk
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format="yyyy/MM/dd"
                        label={t('businessVehicle.end_date')}
                        value={this.state.end_date}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                        minDate={new Date()}
                        onChange={(time) =>
                          this.setState({
                            end_date: time,
                          })
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>

                {/* Operating hours */}
                <Grid container spacing={1} className="row_form_item ">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('business_plan.operation_time')} <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4} className="grid_title_padding">
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('common.from')}</TableCell>
                            <TableCell>{t('common.to')}</TableCell>
                            <TableCell align="right">
                              <Button color="primary" variant="contained" onClick={() => this.addItem()}>
                                <AddIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.operation_time?.map((item, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell align="left">
                                  <FormControl>
                                    <CustomTimePicker
                                      name="start_time"
                                      className="field_min_size_70"
                                      value={item.start_time || null}
                                      showSecond={false}
                                      allowEmpty
                                      onChange={(value) => this.changeOperatingHour(index, value, true)}
                                      format={TIME_FORMAT}
                                      use12Hours={false}
                                      autoComplete="off"
                                    />
                                  </FormControl>
                                </TableCell>
                                <TableCell align="left">
                                  <FormControl>
                                    <CustomTimePicker
                                      name="end_time"
                                      className="field_min_size_70"
                                      value={item.end_time || null}
                                      showSecond={false}
                                      allowEmpty
                                      onChange={(value) => this.changeOperatingHour(index, value, false)}
                                      format={TIME_FORMAT}
                                      use12Hours={false}
                                      autoComplete="off"
                                    />
                                  </FormControl>
                                </TableCell>
                                <TableCell align="right">
                                  <Button color="secondary" variant="contained" onClick={() => this.deleteRows(index)} disabled={this.state.operation_time?.length === 1}>
                                    <CancelIcon />
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </Grid>

                {/* member list */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('qr_code.member_list')}
                    <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                      <Select
                        margin="dense"
                        inputProps={{
                          name: 'members',
                        }}
                        multiple
                        displayEmpty
                        renderValue={
                          this.state.members.length !== 0 ?
                            undefined :
                            () => (
                                <div className="font-12 color-disabled">
                                  {t('placeholder.required_select', {
                                    field: t('qr_code.member_list'),
                                  })}
                                </div>
                              )
                        }
                        value={this.state.member_list}
                        onChange={(e) => onChangeSelect(this, e)}
                      >
                        {this.state.list_member?.map((item, idx) => {
                          return (
                            <MenuItem value={item} key={idx}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormControlLabel
                      labelPlacement="start"
                      control={<Checkbox checked={this.state.all_member} onClick={(event) => this.setState({all_member: event.target.checked})} className="checkbox_radio" />}
                      label={t('qr_code.all_member')}
                    />
                  </Grid>
                </Grid>

                {/* quantity */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('reservationManagement.quantity')}
                    <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl variant="outlined" margin="dense" fullWidth>
                      <TextField
                        name="quantity"
                        type="number"
                        className="field_size_10 field_min_size_300"
                        margin="dense"
                        placeholder={t('validation.required', {field: t('reservationManagement.quantity')})}
                        value={this.state.quantity}
                        onChange={(event) => onChangeTextField(this, event)}
                        inputProps={{maxLength: 256}}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                {/* usage limit */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('qr_code.usage_limit')}
                    <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl variant="outlined" margin="dense" fullWidth>
                      <TextField
                        name="usage_limit"
                        className="field_size_10 field_min_size_300"
                        margin="dense"
                        type="number"
                        placeholder={t('validation.required', {field: t('qr_code.usage_limit')})}
                        value={this.state.usage_limit}
                        onChange={(event) => onChangeTextField(this, event)}
                        inputProps={{maxLength: 256}}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                {/* Discount type */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('businessPartner.discount_type')}
                    <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                      <Select
                        margin="dense"
                        inputProps={{
                          name: 'discount_type',
                        }}
                        displayEmpty
                        renderValue={
                          this.state.discount_type ?
                            undefined :
                            () => (
                                <div className="font-12 color-disabled">
                                  {t('placeholder.required_select', {
                                    field: t('businessPartner.discount_type'),
                                  })}
                                </div>
                              )
                        }
                        value={this.state.discount_type}
                        onChange={(e) => onChangeSelect(this, e)}
                      >
                        {DISCOUNT_TYPE.map(({id, i18n}, idx) => (
                          <MenuItem value={id} key={idx}>
                            {t(`${i18n}`)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                {/* discount amount */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('reservationManagement.discount_price')}
                    <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl variant="outlined" margin="dense" fullWidth>
                      <TextField
                        name="discount_amount"
                        className="field_size_10 field_min_size_300"
                        margin="dense"
                        type="number"
                        placeholder={t('validation.required', {field: t('reservationManagement.discount_price')})}
                        value={this.state.discount_amount}
                        onChange={(event) => onChangeTextField(this, event)}
                        inputProps={{maxLength: 256}}
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                </Grid>

                {/* Status */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('common.status')}
                    <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                      <Select
                        margin="dense"
                        inputProps={{
                          name: 'status',
                        }}
                        displayEmpty
                        renderValue={
                          this.state.status !== '' ?
                            undefined :
                            () => (
                                <div className="font-12 color-disabled">
                                  {t('placeholder.required_select', {
                                    field: t('common.status'),
                                  })}
                                </div>
                              )
                        }
                        value={this.state.status}
                        onChange={(e) => onChangeSelect(this, e)}
                      >
                        {COMMON_STATUS.map((item, idx) => {
                          return (
                            <MenuItem value={item.id} key={idx}>
                              {t(item.i18n)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
              <Grid container spacing={1}>
                <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                <Grid container alignItems="center" justify="flex-end" item xs={9} style={{margin: '10px 0px'}}>
                  {isEdit ? (
                    <Button
                      color="primary"
                      variant="contained"
                      className="button_margin"
                      endIcon={<CloudUploadIcon />}
                      onClick={() => {
                        this.setState({modalMessage: t('qr_code.question.confirm.update')});
                        this.openModal();
                      }}
                    >
                      {t('common.btnUpdate')}
                    </Button>
                  ) : (
                    <>
                      <Button
                        color="primary"
                        variant="contained"
                        className="button_margin button_color_green"
                        endIcon={<AddIcon />}
                        onClick={() => {
                          this.setState({modalMessage: t('qr_code.question.confirm.create')});
                          this.openModal();
                        }}
                      >
                        {t('common.btnRegister')}
                      </Button>
                    </>
                  )}
                  <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.props.history.goBack}>
                    {t('common.btnCancel')}
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Card>
          <br></br>
        </Container>
        <Dialog
          open={this.state.showModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <SelectModal onClickOk={this.handleUpdate} onClickCancel={this.closeModal} message={this.state.modalMessage}></SelectModal>
        </Dialog>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getAllGeofence: () => dispatch(getAllGeofence()),
  };
};

export default withPermissionData(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(QRForm)));
