export const ACTION_TYPES = {
  GET_SUB_COSTS_START: 'GET_SUB_COSTS_START',
  GET_SUB_COSTS_SUCCESS: 'GET_SUB_COSTS_SUCCESS',
  GET_SUB_COSTS_FAIL: 'GET_SUB_COSTS_FAIL',

  GET_DETAIL_SUB_COST_START: 'GET_DETAIL_SUB_COST_START',
  GET_DETAIL_SUB_COST_SUCCESS: 'GET_DETAIL_SUB_COST_SUCCESS',
  GET_DETAIL_SUB_COST_FAIL: 'GET_DETAIL_SUB_COST_FAIL',
};

const initialState = {
  isLoading: false,
  subCostList: [],
  subCost: {},
};

/**
 * function reduce
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.GET_SUB_COSTS_START:
    case ACTION_TYPES.GET_DETAIL_SUB_COST_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.GET_SUB_COSTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        subCostList: action.subCostList,
      };
    case ACTION_TYPES.GET_DETAIL_SUB_COST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        subCost: action.subCost,
      };
    case ACTION_TYPES.GET_SUB_COSTS_FAIL:
      return {
        ...state,
        isLoading: false,
        subCostList: [],
      };
    case ACTION_TYPES.GET_DETAIL_SUB_COST_FAIL:
      return {
        ...state,
        isLoading: false,
        subCost: {},
      };
    default:
      return state;
  }
}
