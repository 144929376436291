import React, {Component} from 'react';

import {Card, Container, Grid, Button, Table, TableCell, TableRow, TableBody, CardHeader, Typography, Box, Select, MenuItem, Dialog, Paper, Checkbox} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InfoIcon from '@material-ui/icons/Info';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';

import {ROUTE, DATA_NULL, PERMISSION_ACTIONS, PAYMENT_METHOD_SIMULATION_RESERVATION, PAYMENT_METHODS_ONETIME_PRICE, BUS_RESERVATION_STATUS} from '../../../../common/constant';
import Memo from '../../../../components/memo';
import SelectModal from '../../../../components/selectModal';
import withPermissionGateway from '../../../../hoc/withPermissionGateway';
import {changeBusReserevationStatusApi} from '../../../../services/reservationServices';
import {checkOwnerCard} from '../../../../stores/business/action';
import {getListGeofence} from '../../../../stores/common/actions';
import {searchListDriverVehicle} from '../../../../stores/driver_vehicle_association/action';
import {setMessageModal} from '../../../../stores/modal/actions';
import {
  changeNodeStatus,
  changeStatusPayment,
  retryPaymentCreditCard,
  updateReservation,
  adminChangePaymentMethod,
  getBusReservationDetail,
} from '../../../../stores/reservation/actions';
import {onChangeSelect, convertStringNull, backForwardRouter, customDisplayCurrency, onChangeListData} from '../../../../utils/common';
import {displayDateTime, displayDate, convertDatetimeUTC} from '../../../../utils/datetime';
import {modalObj} from '../../../../utils/modal';

/**
 * Reservation management detail view
 */
class BusDetail extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      booking_status_basic: '',
      deposit_status_basic: '',
      delivery_status: '',
      basic_information_response: {
        reservation_number: null,
        jit_order_id: '',
        jit_order_number: '',
        geofence_id: null,
        service_type: '',
        reservation_time: '',
        cancel_time: '',
        deposit_amount: null,
        city_code: '',
        trip_booking_status: '',
        driver_rating: {},
        requested_departure_time: '',
      },
      booking_response: {
        booking_status: '',
        drop_off_address: '',
        drop_off_time: null,
        id: null,
        number_of_user: null,
        pickup_time: null,
        pickup_address: '',
        price: 0,
        product_name: null,
        scheduled_drop_off_time: '',
        scheduled_pickup_time: '',
        service_type: '',
        supplier_name: '',
        driver_code: '',
        user_subscription: [],
      },
      delivery_responses: [],
      payment_info: {},
      member_information_response: {
        birthday: '',
        current_age: 0,
        email: '',
        gender: '',
        id: null,
        member_name: '',
        member_ship_member: '',
        member_type: '',
        mobile: '',
      },
      memos: [],
      note: '',
      data_response: null,
      listArea: [],
      confirmFlag: false,
      isUpdate: true,
      countryCode: '',
      payment_method: '',
      store_payment_method: '',
      confirmPaymentFlag: false,
      notification_booking_status_complete: true,
      temp_payment_method: '',
      temp_notification_booking_status_complete: true,
      selectPaymentFlag: false,
      confirmNotificationFlag: false,
      deposit_status: {},
      cardInfo: [],
      paymentRetry: '',
      visionRetryCreditPayment: false,
      paymentMethod: [],
    };
    this.handleButtonUpdateOk = this.handleButtonUpdateOk.bind(this);
    this.handleButtonUpdateCancel = this.handleButtonUpdateCancel.bind(this);
  }

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    window.scrollTo(0, 0);
    await this.props.getListGeofence({targetScreen: 'ENABLE_FOR_ADMIN_ALL'}).then((res) => {
      this.setState({
        listArea: res,
      });
    });
    this.getDetailReservation();
  };

  /**
   * handleUpdate
   */
  handleUpdate = () => {
    this.setState({
      confirmFlag: true,
      isUpdate: true,
      message: 'business.question.confirm.update',
    });
  };

  /**
   * findAreaById
   * @param {event} id
   * @return {string} name
   */
  findAreaById = (id) => {
    const objArea = this.state.listArea?.find((e) => e.geofence_id === id);
    return objArea?.name;
  };

  /**
   * handleButtonCancel
   */
  handleButtonUpdateCancel() {
    this.setState({
      confirmFlag: false,
      paymentRetry: '',
    });
  }

  /**
   * handleButtonUpdateOk
   */
  handleButtonUpdateOk = async () => {
    this.setState({
      confirmFlag: false,
      confirmNotificationFlag: false,
      selectPaymentFlag: false,
    });
    this.setState({
      notification_booking_status_complete: this.state.temp_notification_booking_status_complete,
      payment_method: this.state.temp_payment_method,
    });
    if (this.state.isUpdate) {
      await changeBusReserevationStatusApi(this.props.match.params.id, {status: this.state.booking_status_basic, memos: this.state.memos || []}).then((res) => {
        if (res?.status === 200) {
          this.getDetailReservation();
          this.props.setMessageModal(modalObj(true, 'api.update.reservation.success'));
        } else {
          if (res?.result && res?.result === 'OPERATOR_CANCEL_OR_COMPLETED') {
            this.props.setMessageModal(modalObj(true, 'update.reservation.request.invalid.to.complete.or.operator'));
          } else if (res?.result && res?.result === 'OPERATOR_CANCEL') {
            this.props.setMessageModal(modalObj(true, 'update.reservation.request.invalid.to.complete.operator'));
          }
          res?.status === 500 && this.state.payment_method === 'CREDIT_CARD' && this.setState({confirmPaymentFlag: true});
        }
      });
    }
  };

  /**
   * getDetailReservation
   */
  getDetailReservation = async () => {
    if (this.props.match.path === ROUTE.LAYOUT + ROUTE.BUS_RESERVATION_DETAIL + '/:id') {
      await this.props.getBusReservationDetail(this.props.match.params.id).then(
        (response) => {
          const booking_status = response.basic_information_response?.status;
          if (response?.member_information_response?.member_type === 'NON_MEMBER') {
            const paymentMethodSim = response?.booking_response?.payment_method_support;
            const paymentMethod = PAYMENT_METHODS_ONETIME_PRICE.filter((payment) => paymentMethodSim?.includes(payment.id) && payment.id !== 'CREDIT_CARD');
            this.setState({paymentMethod: paymentMethod || []});
          } else {
            const paymentMethodSim = response?.booking_response?.payment_method_support;
            const paymentMethod = PAYMENT_METHODS_ONETIME_PRICE.filter((payment) => paymentMethodSim?.includes(payment.id));
            this.setState({paymentMethod: paymentMethod || []});
            this.confirmCreditCard(response.member_information_response?.id);
          }
          this.setState({
            booking_status_basic: booking_status,
            notification_booking_status_complete: response?.booking_response?.enable_notification || true,
            temp_notification_booking_status_complete: response?.booking_response?.enable_notification || true,
            deposit_status_basic: response.basic_information_response?.deposit_status,
            basic_information_response: response.basic_information_response,
            booking_response: response.booking_response,
            payment_method: response.booking_response.payment_method,
            store_payment_method: response.booking_response.payment_method,
            temp_payment_method: response.booking_response.payment_method,
            payment_info: response.booking_response?.payment_info,
            delivery_responses: response.delivery_responses,
            member_information_response: {
              birthday: response.member_information_response?.birthday,
              current_age: response.member_information_response?.current_age,
              email: response.member_information_response?.email,
              gender: response.member_information_response?.gender,
              id: response.member_information_response?.id,
              member_name: response.member_information_response?.member_name,
              member_ship_member: response.member_information_response?.member_ship_member,
              member_type: response.member_information_response?.member_type,
              mobile: response.member_information_response?.mobile,
              member_id: response.member_information_response?.member_id,
            },
            memos: response.memos,
            data_response: response,
            deposit_status: response.booking_response?.payment_info?.payment_status,
          });
        },
        () => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.RESERVATION_MANAGEMENT_SEARCH),
      );
      const objArea = this.state.listArea?.find((e) => e.geofence_id === this.state.basic_information_response.geofence_id);
      if (objArea) this.setState({countryCode: objArea.country.country_code});
    }
  };

  /**
   * linkGoogleMap
   * @param {Number} lat
   * @param {Number} lng
   * @return {String}
   */
  linkGoogleMap = (lat, lng) => {
    return `https://maps.google.com/?q=${lat},${lng}`;
  };

  /**
   * convertBookingStatus
   * @return {*}
   */
  convertBookingStatus = () => {
    if (this.state.data_response?.basic_information_response.service_type === 'SHUTTLE_BUS_ON_DEMAND' && this.state.booking_status_basic === 'NEW_ORDER') {
      return 'TEMPORARY_ORDER_JIT_ONLY';
    }
    if (this.state.data_response?.basic_information_response.service_type === 'SHUTTLE_BUS_ON_DEMAND' && this.state.booking_status_basic === 'RESERVATION_NOT_ESTABLISHED') {
      return 'SYSTEM_CANCEL';
    }
    if (this.state.booking_status_basic === 'RESERVATION_NOT_ESTABLISHED') {
      return 'SYSTEM_CANCEL';
    }
    if (this.state.booking_status_basic === 'NEW_ORDER') {
      return 'TEMPORARY_ORDER_JIT_ONLY';
    }
    if (this.state.booking_status_basic === 'USER_ORDER_NOT_PICK_UP' || this.state.booking_status_basic === 'USER_ORDER_PICK_UP') {
      return 'USER_ORDER';
    }
    if (this.state.booking_status_basic === 'OPERATOR_ORDER_NOT_PICK_UP' || this.state.booking_status_basic === 'OPERATOR_ORDER_PICK_UP') {
      return 'OPERATOR_ORDER';
    }
    return this.state.booking_status_basic;
  };

  /**
   * confirmCreditCard
   * @param {Number} id
   */
  confirmCreditCard = async (id) => {
    this.props.checkOwnerCard(id).then((response) => {
      const cardInfo = [];
      for (const card in response) {
        if (response[card].length > 0) {
          const cardData = response[card]?.find((item) => Number(item.default_card) === 1);
          if (cardData) {
            cardInfo.push({
              ...cardData,
              cardType: card === 'veritrans_cards' ? 'VERITRAINS' : card === '2c2p_cards' ? '2C2P' : 'ALEPAY',
            });
          }
        }
      }
      this.setState({
        cardInfo,
      });
      if (
        (response['2c2p_cards']?.length > 0 && this.state.countryCode === 'SG') ||
        (response['veritrans_cards']?.length > 0 && this.state.countryCode === 'JP') ||
        (response['alepay_cards']?.length > 0 && this.state.countryCode === 'VN') ||
        (response['2c2p_my_cards']?.length > 0 && this.state.countryCode === 'MY')
      ) {
        const paymentMethod = this.state.paymentMethod;
        this.setState({
          visionRetryCreditPayment: true,
          paymentMethod,
        });
      } else {
        const paymentMethod = this.state.paymentMethod.filter((payment) => payment.id !== 'CREDIT_CARD');
        this.setState({
          paymentMethod,
        });
      }
    });
  };

  /**
   * Render component
   * @return {object}
   */
  render() {
    const {t, reservation, actions, association} = this.props;
    const {data_response, basic_information_response, booking_status_basic, booking_response, countryCode, member_information_response} = this.state;
    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
    };
    const number_of_users =
      data_response?.booking_response?.number_of_adults + data_response?.booking_response?.number_of_children + data_response?.booking_response?.number_of_infants;
    const total_price = data_response?.booking_response?.price || 0;
    const currency_code = data_response?.booking_response?.currency_code || 'JPY';
    return (
      <LoadingOverlay active={reservation.isLoading || association.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('route.busReservationDetail')}</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}></Grid>
            </Grid>
          </Container>

          <Container maxWidth="xl">
            <Box m={2} p={2}>
              <Card raised>
                <Container maxWidth="xl">
                  <CardHeader
                    title={
                      <Typography gutterBottom variant="h5" component="h5">
                        {t('reservationManagement.basic_information')}
                      </Typography>
                    }
                  />
                  <Box pt={3} pb={3}>
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell className="width_30">{t('reservationManagement.reservation_number_search')}</TableCell>
                          <TableCell>{basic_information_response.reservation_number}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('common.geofence')}</TableCell>
                          <TableCell>{this.findAreaById(basic_information_response.geofence_id)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('reservationManagement.booking_status')}</TableCell>
                          <TableCell>
                            <Select
                              inputProps={{
                                name: 'booking_status_basic',
                              }}
                              onChange={(event) => onChangeSelect(this, event)}
                              margin="dense"
                              labelWidth={60}
                              value={booking_status_basic}
                              disabled={basic_information_response?.status !== BUS_RESERVATION_STATUS[0].id}
                            >
                              {BUS_RESERVATION_STATUS.map(({id, i18n}) => (
                                <MenuItem value={id} key={id} disabled={id === BUS_RESERVATION_STATUS[1].id || id === BUS_RESERVATION_STATUS[4].id}>{`${t(i18n)}`}</MenuItem>
                              ))}
                            </Select>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Container>
              </Card>
            </Box>
          </Container>

          <Container maxWidth="xl">
            <Box m={2} p={2}>
              <Card raised>
                <Container maxWidth="xl">
                  <CardHeader
                    title={
                      <Typography gutterBottom variant="h5" component="h5">
                        {t('reservationManagement.member_information')}
                      </Typography>
                    }
                  />
                  <Box pt={3} pb={3}>
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell className="width_30">{t('memberManagement.memberId')}</TableCell>
                          <TableCell>{member_information_response.member_id || DATA_NULL}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('reservationManagement.memberName')}</TableCell>
                          <TableCell>{convertStringNull(member_information_response.member_name)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('reservationManagement.member_type')}</TableCell>
                          <TableCell>{member_information_response.member_type}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('reservationManagement.sex')}</TableCell>
                          <TableCell>{!!member_information_response.gender ? member_information_response.gender : DATA_NULL}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('reservationManagement.birthday')}</TableCell>
                          <TableCell>{!!member_information_response.birthday ? displayDate(member_information_response.birthday) : DATA_NULL}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('reservationManagement.current_age')}</TableCell>
                          <TableCell>{!!member_information_response.current_age ? member_information_response.current_age : DATA_NULL}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('reservationManagement.change_mail_address')}</TableCell>
                          <TableCell>{!!member_information_response.email ? member_information_response.email : DATA_NULL}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('common.phoneNumber')}</TableCell>
                          <TableCell>{!!member_information_response.mobile ? member_information_response.mobile : DATA_NULL}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Container>
              </Card>
            </Box>
          </Container>

          {/* Reservation Information */}
          <Container maxWidth="xl">
            <Box m={2} p={2}>
              <Card raised>
                <Container maxWidth="xl">
                  <CardHeader
                    title={
                      <Typography gutterBottom variant="h5" component="h5">
                        {t('reservationManagement.reservationInformation')}
                      </Typography>
                    }
                  />
                  <Box pt={3} pb={3}>
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell className="width_30">{t('reservationManagement.service_type')}</TableCell>
                          <TableCell>{data_response?.basic_information_response?.service_type}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('reservationManagement.number_of_users')}</TableCell>
                          <TableCell>
                            {number_of_users || DATA_NULL} ({data_response?.booking_response?.number_of_adults} {t('reservationManagement.adult')}
                            {', '}
                            {data_response?.booking_response?.number_of_children} {t('reservationManagement.child')}
                            {', '} {data_response?.booking_response?.number_of_infants} {t('reservationManagement.infant')})
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('reservationManagement.operationCompany')}</TableCell>
                          <TableCell>{data_response?.basic_information_response?.company_name}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('reservationManagement.busRoute')}</TableCell>
                          <TableCell>{data_response?.basic_information_response?.route_name}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('reservationManagement.busReservationDateTime')}</TableCell>
                          <TableCell>
                            {!!data_response?.booking_response?.reservation_time ?
                              displayDateTime(convertDatetimeUTC(data_response?.booking_response?.reservation_time, countryCode)) :
                              DATA_NULL}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('reservationManagement.cancellation_date_and_time')}</TableCell>
                          <TableCell>
                            {!!basic_information_response.cancel_time ? displayDateTime(convertDatetimeUTC(basic_information_response.cancel_time, countryCode)) : DATA_NULL}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('reservationManagement.pickup_address')}</TableCell>
                          <TableCell>
                            {booking_response?.pickup_stop?.name_translations && booking_response?.pickup_stop?.name_translations[this.props.i18n.language] ?
                              booking_response?.pickup_stop?.name_translations[this.props.i18n.language] :
                              booking_response.pickup_address || DATA_NULL}
                            <Button
                              style={{marginLeft: 10}}
                              target="_blank"
                              variant="outlined"
                              color="primary"
                              href={this.linkGoogleMap(data_response?.booking_response?.pickup_point?.lat, data_response?.booking_response?.pickup_point?.lng)}
                            >
                              {t('newReservation.linkMap2')}
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('reservationManagement.drop_off_address')}</TableCell>
                          <TableCell>
                            {booking_response?.dropoff_stop?.name_translations && booking_response?.dropoff_stop?.name_translations[this.props.i18n.language] ?
                              booking_response?.dropoff_stop?.name_translations[this.props.i18n.language] :
                              booking_response.drop_off_address || DATA_NULL}
                            <Button
                              style={{marginLeft: 10}}
                              target="_blank"
                              variant="outlined"
                              color="primary"
                              href={this.linkGoogleMap(data_response?.booking_response?.drop_off_point?.lat, data_response?.booking_response?.drop_off_point?.lng)}
                            >
                              {t('newReservation.linkMap2')}
                            </Button>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('reservationManagement.pickupTime')}</TableCell>
                          <TableCell>{!!booking_response.pickup_time ? displayDateTime(convertDatetimeUTC(booking_response.pickup_time, countryCode)) : DATA_NULL}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('reservationManagement.dropOffTime')}</TableCell>
                          <TableCell>{!!booking_response.drop_off_time ? displayDateTime(convertDatetimeUTC(booking_response.drop_off_time, countryCode)) : DATA_NULL}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('business_plan.price')}</TableCell>
                          <TableCell>{customDisplayCurrency(total_price, currency_code)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('common.paymentMethod')}</TableCell>
                          <TableCell>
                            {this.state.data_response?.booking_response?.payment_method ?
                              t(PAYMENT_METHOD_SIMULATION_RESERVATION.filter((item) => item.id === this.state.data_response?.booking_response?.payment_method)[0]?.i18n) :
                              DATA_NULL}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Container>
              </Card>
            </Box>
          </Container>
          {/* Cancellation information/rules */}
          <Container maxWidth="xl">
            <Box m={2} p={2}>
              <Card raised>
                <Container maxWidth="xl">
                  <Grid container spacing={2} style={{paddingTop: '15px', paddingBottom: '15px'}} className="memo_reservation">
                    <Memo
                      memos={this.state.memos}
                      onChangeContent={(value) => onChangeListData(this, this.state.memos, value.field, value.index, value.newValue)}
                      note={this.state.note}
                      onChangeNote={(value) => this.setState({note: value})}
                      parentType="medium"
                      countryCode={this.state.countryCode}
                    />
                  </Grid>
                </Container>
              </Card>
            </Box>
          </Container>
          <Container maxWidth="xl">
            <Box p={1}>
              <Grid container spacing={2}>
                <Grid container alignItems="center" justifyContent="flex-end" item>
                  <Box p={2}>
                    {permission.canUpdate && (
                      <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={() => this.handleUpdate()}>
                        {t('common.btnUpdate')}
                      </Button>
                    )}
                    <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.props.history.goBack}>
                      {t('common.btnReturn')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
          {/* Confirm Dialog */}
          <Dialog
            open={this.state.confirmFlag}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal onClickOk={this.handleButtonUpdateOk} onClickCancel={this.handleButtonUpdateCancel} message={this.state.message}></SelectModal>
          </Dialog>

          {/* Complete Reservation */}
          <Dialog
            open={this.state.selectPaymentFlag}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <Paper className="modal_size_mid">
              <Container maxWidth="md">
                <Grid container alignItems="center">
                  <Grid container alignItems="center" justifyContent="center" item xs={12}>
                    <span className="font_size_big" style={{whiteSpace: 'pre-line'}}>
                      {t('reservationManagement.completeReservation')}
                    </span>
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid container alignItems="center" justifyContent="center" item xs={2}>
                    <InfoIcon className="errorinfo" fontSize="large" />
                  </Grid>
                  <Grid item xs={10}>
                    <p style={{whiteSpace: 'pre-line'}}>{t('reservationManagement.completeReservation.title')}</p>
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item xs={2}></Grid>
                  <Grid item xs={3}>
                    {t('common.paymentMethod')}
                  </Grid>
                  <Grid item xs={7}>
                    <Select
                      inputProps={{
                        name: 'temp_payment_method',
                      }}
                      margin="dense"
                      labelWidth={60}
                      value={this.state.temp_payment_method}
                      onChange={(e) => onChangeSelect(this, e)}
                    >
                      {this.state.paymentMethod?.map(({id, i18n}) => {
                        return (
                          <MenuItem key={id} value={id}>
                            {t(i18n)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                </Grid>
                <br />
                {this.state.paymentMethod?.length === 0 && (
                  <Grid container alignItems="center">
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10} className="error">
                      {t('reservationManagement.completeReservation.errorMessage')}
                    </Grid>
                  </Grid>
                )}
                <br />
                <Grid container>
                  <Grid container justifyContent="center" item xs={2}>
                    <Checkbox
                      checked={this.state.temp_notification_booking_status_complete}
                      onChange={() => this.setState({temp_notification_booking_status_complete: !this.state.temp_notification_booking_status_complete})}
                      inputProps={{'aria-label': 'primary'}}
                      disabled={this.state.paymentMethod?.length === 0}
                    />
                  </Grid>
                  <Grid container alignItems="center" justifyContent="flex-start" item xs={10}>
                    <p>{t('reservationManagement.notificationMethod.subTitle')}</p>
                  </Grid>
                </Grid>
                <br />
                <Grid container>
                  <Grid container justifyContent="center" alignItems="flex-start" item xs={6}>
                    <Button color="primary" variant="contained" className="button_margin" onClick={this.handleButtonUpdateOk} disabled={this.state.paymentMethod?.length === 0}>
                      {t('common.btnConfirm')}
                    </Button>
                  </Grid>
                  <Grid container justifyContent="center" alignItems="flex-start" item xs={6}>
                    <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.setState({selectPaymentFlag: false})}>
                      {t('common.btnCancel')}
                    </Button>
                  </Grid>
                </Grid>
                <br />
              </Container>
            </Paper>
          </Dialog>

          {/* Confirm Send Notification */}

          <Dialog
            open={this.state.confirmNotificationFlag}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <Paper className="field_min_size_400">
              <Container maxWidth="xl">
                <br />
                <Grid container alignItems="center">
                  <Grid container alignItems="center" justifyContent="center" item xs={12}>
                    <span className="font_size_big" style={{whiteSpace: 'pre-line'}}>
                      {t('reservationManagement.completeReservation')}
                    </span>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid container alignItems="center" justifyContent="flex-end" item xs={2}>
                    <InfoIcon className="errorinfo" fontSize="large" />
                  </Grid>
                  <Grid container alignItems="center" justifyContent="flex-start" item xs={10}>
                    <p>{t('reservationManagement.notificationMethod.operatorCancel')}</p>
                  </Grid>
                  <Grid container justifyContent="flex-end" item xs={2}>
                    <Checkbox
                      checked={this.state.temp_notification_booking_status_complete}
                      onChange={() => this.setState({temp_notification_booking_status_complete: !this.state.temp_notification_booking_status_complete})}
                      inputProps={{'aria-label': 'primary'}}
                    />
                  </Grid>
                  <Grid container alignItems="center" justifyContent="flex-start" item xs={10}>
                    <p>{t('reservationManagement.notificationMethod.subTitle')}</p>
                  </Grid>
                  <br></br>
                  <Grid container alignItems="center" justifyContent="center" item xs={6}>
                    <Button fullWidth variant="contained" color="primary" onClick={this.handleButtonUpdateOk}>
                      {t('common.btnOk')}
                    </Button>
                  </Grid>
                  <Grid container alignItems="center" justifyContent="center" item xs={6}>
                    <Button fullWidth variant="contained" className="button_color" color="primary" onClick={() => this.setState({confirmNotificationFlag: false})}>
                      {t('common.btnCancel')}
                    </Button>
                  </Grid>
                </Grid>
              </Container>
              <br />
            </Paper>
          </Dialog>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reservation: state.reservation,
    association: state.driverVehicleAssociation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBusReservationDetail: (id) => dispatch(getBusReservationDetail(id)),
    getListGeofence: (payload) => dispatch(getListGeofence(payload)),
    updateReservation: (payload, props) => dispatch(updateReservation(payload, props)),
    changeNodeStatus: (payload) => dispatch(changeNodeStatus(payload)),
    searchListDriverVehicle: (payload) => dispatch(searchListDriverVehicle(payload)),
    setMessageModal: (params) => dispatch(setMessageModal(params)),
    adminChangePaymentMethod: (payload) => dispatch(adminChangePaymentMethod(payload)),
    checkOwnerCard: (userId) => dispatch(checkOwnerCard(userId)),
    retryPaymentCreditCard: (bookingId) => dispatch(retryPaymentCreditCard(bookingId)),
    changeStatusPayment: (bookingId) => dispatch(changeStatusPayment(bookingId)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(BusDetail)));
