/* eslint-disable max-len */
import React, {Component} from 'react';

import {Card, Container, Grid, Paper, Table, TableCell, TableRow, TextField, Button, Select, MenuItem, FormControl, TableHead, TableBody, Dialog} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import DetailIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {BUSINESS_ROLE, ROUTE, BUSINESS_STATUS, BUSINESS_TYPE_OLD, ROWS_PER_PAGE_OPTIONS, MAX_SIZE_OUTPUT, EXPORT_LIMIT_NUMBER_ROWS, PERMISSION_ACTIONS} from '../../../common/constant';
import CSVExporter from '../../../components/CSVExporter';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {searchListBusiness, failSearchListBusiness, deleteBusiness, exportBusiness} from '../../../stores/business/action';
import {getListSupplierBusiness, getAllCountryCode, getAllGeofence, getListGeofenceByCountry} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {onChangeSelect, onChangeTextField, changeUrlParams, getUrlParams, getQueryStringFromObject, redirectRouter} from '../../../utils/common';
import {modalObj} from '../../../utils/modal';
import {isRoleBusiness, isRoleCountry, isRoleGlobal} from '../../../utils/role';

/**
 * Business information
 */
class BusinessInformation extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      listCheckbox: [],
      id: null,
      flagDelete: false,
      message: '',
      flgDelete: false,
      status: '',
      type: '',
      supplierCode: '',
      supplierName: '',
      country: !isRoleGlobal() ? props.principal.country_id : '',
      mobile: '',
      result: [],
      isSearch: false,
      index: '',
      businessRoleType: !isRoleBusiness() ? '' : props.principal.business_role_type,
      geofenceId: '',
      listGeofenceAll: [],

      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,
      flagExport: false,
      confirmedExport: false,
      latestSearchParams: null,
    };
    this.handleButtonOk = this.handleButtonOk.bind(this);
    this.handleButtonCancel = this.handleButtonCancel.bind(this);
    this.exportCSVRef = React.createRef();
  }

  /**
   * componentWillUnmount
   */
  componentWillUnmount() {
    this.props.resetListBusiness();
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getListSupplierBusiness().then((response) => {
      if (isRoleBusiness()) {
        this.setState({
          supplierName: response[0],
        });
      }
    });
    await this.props.getAllCountryCode();
    await this.props.getAllGeofence().then(() => {
      if (this.state.country) {
        this.props.getListGeofenceByCountry(this.state.country);
        this.setState({
          listGeofenceAll: this.props.common?.all_geofence?.filter((e) => e.country_id === Number(this.state.country)),
        });
      } else {
        this.setState({listGeofenceAll: this.props.common?.all_geofence});
      }
    });
    if (this.props.location.search) {
      this.setState(getUrlParams(this.props.location.search));
    }
  }
  /**
   * componentDidUpdate
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.props.actions !== prevProps.actions) {
      const canSearch = this.props.actions.includes(PERMISSION_ACTIONS.SEARCH);
      if (canSearch && this.props.location.search) {
        this.setState(getUrlParams(this.props.location.search), this.handleSearch);
      }
    }
  }
  /**
   * handleButtonOk
   */
  handleButtonOk() {
    this.setState({
      flagDelete: false,
    });
    this.props.deleteBusiness(this.state.id, this.props).then((response) => {
      if (response && response.status === 200) {
        const result = this.state.result;
        result.splice(this.state.index, 1);
        this.setState({
          result: result,
          totalRows: this.state.totalRows - 1,
        });
      };
    });
  }

  /**
   * handleButtonCancel
   */
  handleButtonCancel() {
    this.setState({
      flagDelete: false,
    });
  }

  /**
   * handleDelete
   * @param {int} id
   * @param {object} index
   */
  handleDelete = (id, index) => {
    this.setState({
      flagDelete: true,
      message: 'messageCode.deleteConfirmSupplier',
      id,
      index,
    });
  };

  /**
   * changeBusiness
   * @param {string} value
   */
  changeBusiness = (value) => {
    this.setState({supplierName: value});
  };

  /**
   * handleSearch
   * @param {bool} reset
   */
  handleSearch = (reset) => {
    const canSearch = this.props.actions.includes(PERMISSION_ACTIONS.SEARCH);
    if (!canSearch) return;
    const {status, type, supplierCode, supplierName, country, mobile, geofenceId, businessRoleType} = this.state;
    const geofence_ids = [];
    geofenceId && geofence_ids.push(geofenceId);
    const payload = {
      supplier_status: status,
      business_role_type: businessRoleType ? businessRoleType : null,
      country_id: businessRoleType !== 'GLOBAL' ? country : '',
      geofence_ids: businessRoleType === 'BUSINESS' ? geofence_ids : [],
      supplier_type: type,
      supplier_id: supplierCode ? supplierCode.trim() : '',
      supplier_name: supplierName ? supplierName.supplier_name.trim() : '',
      supplier_tel: mobile ? mobile.trim() : '',
    };
    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const {currentPage, rowsPerPage} = this.state;
    const queryParams = reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage};
    this.props.searchListBusiness(payload, queryParams, this.props).then((response) => {
      this.setState(
          {
            result: response?.content,
            totalRows: response?.totalSize,
            isSearch: true,
            latestSearchParams: payload,
          },
          () => {
            const {status, type, supplierCode, supplierName, country, geofenceId, businessRoleType, mobile, currentPage, rowsPerPage} = this.state;
            // Apply changed params into url
            const queryParamsToChange = {
              status,
              type,
              supplierCode,
              supplierName: supplierName && JSON.stringify(supplierName),
              country,
              geofenceId,
              businessRoleType,
              mobile,
              currentPage: String(currentPage),
              rowsPerPage,
            };
            changeUrlParams(queryParamsToChange);
            const newSearchParams = getQueryStringFromObject(queryParamsToChange);
            // Save search params into state in order to pass to next page
            this.setState({searchParams: newSearchParams});
          },
      );
    });
  };

  /**
   * onChangeCountry
   * @param {*} e
   */
  onChangeCountry = (e) => {
    this.setState({
      country: e.target.value,
      listGeofenceAll: this.props.common?.all_geofence?.filter((item) => item.country_id === e.target.value),
      geofenceId: '',
      list_area_id: [],
    });
  };

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearch);
  };

  /**
   * reset
   */
  reset = () => {
    const {common, principal} = this.props;
    this.setState({
      status: '',
      type: '',
      supplierCode: '',
      supplierName: isRoleBusiness() ? common?.supplier_business_list[0] : '',
      country: !isRoleGlobal() ? principal.country_id : '',
      businessRoleType: isRoleBusiness() ? principal.business_role_type : '',
      geofenceId: '',
      listGeofenceAll: common?.all_geofence,
      mobile: '',
      id: '',
      index: '',
    });
  };
  /**
   * Fetch data to export
   */
  fetchDataExport = async () => {
    const {t} = this.props;
    const queryParams = {page: 0, size: MAX_SIZE_OUTPUT};
    this.exportHeaders = [
      {label: t('businessInformation.id'), key: 'supplier_id'},
      {label: t('businessInformation.status'), key: 'supplier_status'},
      {label: t('businessInformation.type'), key: 'supplier_type'},
      {label: t('businessInformation.name'), key: 'supplier_name'},
      {label: t('businessInformation.role'), key: 'business_role_type'},
    ];
    const dataExport = await this.props.exportBusiness(this.state.latestSearchParams, queryParams);
    const convertedData = dataExport.map((row, index) => {
      row.supplier_status = t(`${BUSINESS_STATUS.filter(({id}) => row.supplier_status === id)[0]?.i18n}`);
      row.type = BUSINESS_TYPE_OLD.map(({id, i18n}) => (row.supplier_type === id ? t(`${i18n}`) : ''));
      row.business_role_type = t(`${BUSINESS_ROLE.filter((item) => row.business_role_type === item.id)[0]?.i18n}`);
      return row;
    });
    this.setState({confirmedExport: false});
    return convertedData;
  };

  /**
   * alertExportCSV
   */
  alertExportCSV = () => {
    const {t} = this.props;
    if (this.state.totalRows > EXPORT_LIMIT_NUMBER_ROWS) {
      this.props.setMessageModal(modalObj(true, t('messageCode.exportAlertLimit')));
    } else {
      const message = t('messageCode.exportAlert');
      this.setState({flagExport: true, message});
    }
  };

  /**
   * handleButtonExportOk
   */
  handleButtonExportOk = () => {
    this.setState({flagExport: false, confirmedExport: true}, () => {
      this.exportCSVRef.current.onClickExport();
    });
  };

  /**
   * handleButtonExportCancel
   */
  handleButtonExportCancel = () => {
    this.setState({flagExport: false});
  };

  /**
   * onClickRegister
   */
  onClickRegister = () => {
    redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.BUSINESS_ADD);
  }

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {business, t, common, actions} = this.props;
    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canCSVOutput: actions.includes(PERMISSION_ACTIONS.CSV_OUTPUT),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
      canDelete: actions.includes(PERMISSION_ACTIONS.DELETE),
      canRegister: actions.includes(PERMISSION_ACTIONS.REGISTER),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('businessInformation.title')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          <Card raised>
            <Container maxWidth="xl">
              {permission.canSearch && (
                <>
                  <br></br>
                  <Paper className="search_table">
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                        {t('businessInformation.searchTitle')}
                      </Grid>
                    </Grid>

                    {/* Business ID */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('businessInformation.id')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <TextField
                          name="supplierCode"
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('businessInformation.id')})}
                          variant="outlined"
                          value={this.state.supplierCode}
                          onChange={(event) => onChangeTextField(this, event)}
                        />
                      </Grid>
                    </Grid>

                    {/* Type */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('businessInformation.type')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'type',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.type ?
                                undefined :
                                () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('businessInformation.type'),
                                    })}
                                  </div>
                                )
                            }
                            value={this.state.type}
                            onChange={(event) => onChangeSelect(this, event)}
                          >
                            {BUSINESS_TYPE_OLD.map((item, idx) => {
                              return (
                                <MenuItem value={item.id} key={idx}>
                                  {t(`${item.i18n}`)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* Status */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('businessInformation.status')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'status',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.status ?
                                undefined :
                                () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('businessInformation.status'),
                                    })}
                                  </div>
                                )
                            }
                            value={this.state.status}
                            onChange={(event) => onChangeSelect(this, event)}
                          >
                            {BUSINESS_STATUS.map((item, idx) => {
                              return (
                                <MenuItem value={item.id} key={idx}>
                                  {t(`${item.i18n}`)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* Business Role */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('businessInformation.role')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'businessRoleType',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.businessRoleType ?
                                undefined :
                                () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('businessInformation.role'),
                                    })}
                                  </div>
                                )
                            }
                            value={this.state.businessRoleType}
                            onChange={(event) => onChangeSelect(this, event)}
                            disabled={isRoleBusiness()}
                          >
                            {(isRoleCountry() ? BUSINESS_ROLE.filter((item) => item.id !== 'GLOBAL') : BUSINESS_ROLE).map((item, idx) => {
                              return (
                                <MenuItem value={item.id} key={idx}>
                                  {t(item.i18n)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* Country */}
                    {(this.state.businessRoleType === 'BUSINESS' || this.state.businessRoleType === 'COUNTRY') &&
                    <>
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.country')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'country',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.country ?
                                  undefined :
                                  () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('common.country'),
                                      })}
                                    </div>
                                  )
                              }
                              value={this.state.country}
                              onChange={(e) => this.onChangeCountry(e)}
                              disabled={!isRoleGlobal()}
                            >
                              {common.country_code?.map((item, idx) => {
                                return (
                                  <MenuItem value={item.id} key={idx}>
                                    {t(`${item.country_code}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </>}

                    {/* Geofence */}
                    {this.state.businessRoleType === 'BUSINESS' &&
                    <>
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.geofence')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'geofenceId',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.geofenceId ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('common.geofence'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.geofenceId}
                              onChange={(e) => onChangeSelect(this, e)}
                            >
                              {this.state.listGeofenceAll?.map((item, idx) => {
                                return (
                                  <MenuItem value={item.geofence_id} key={idx} className={!item.enable ? 'disable-option-custom' : ''}>
                                    {t(item.name)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </>}

                    {/* Business Name */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('businessInformation.name')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <Autocomplete
                          margin="dense"
                          className="field_size_10 field_min_size_300"
                          options={common?.supplier_business_list}
                          value={this.state.supplierName}
                          getOptionLabel={(option) => option ? option.supplier_name : ''}
                          onChange={(_event, business) => this.changeBusiness(business)}
                          disabled={isRoleBusiness()}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={'businessName'}
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              placeholder={t('placeholder.required', {field: t('businessInformation.name')})}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('common.phoneNumber')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <TextField
                          name="mobile"
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('common.phoneNumber')})}
                          inputProps={{maxLength: 256}}
                          variant="outlined"
                          value={this.state.mobile}
                          onChange={(event) => onChangeTextField(this, event)}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </>
              )}
              <br></br>
              <Grid container spacing={1}>
                <Grid container alignItems="center" justify="flex-start" item xs={3}>
                  {permission.canSearch && (
                    <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.reset()} endIcon={<RotateLeftIcon />}>
                      {t('common.btnResetSearch')}
                    </Button>
                  )}
                </Grid>
                <Grid container alignItems="center" justify="flex-end" item xs={9}>
                  {permission.canRegister && !isRoleBusiness() && (
                    <Button style={{textDecoration: 'none'}} color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />}
                     onClick={() => this.onClickRegister()}
                     >
                      {t('common.btnRegister')}
                    </Button>
                  )}
                  {permission.canCSVOutput && permission.canSearch && (
                    <>
                      <div className={this.state.totalRows <= 1000 || this.state.confirmedExport ? '' : 'hidden'}>
                        <CSVExporter
                          ref={this.exportCSVRef}
                          disabled={this.state.totalRows <= 0 || !this.state.latestSearchParams}
                          headers={this.exportHeaders}
                          fetchAction={this.fetchDataExport}
                          isFetchAsync={true}
                          screenName={t('businessInformation.searchTitle')}
                        />
                      </div>
                      {this.state.totalRows >= 1000 && !this.state.confirmedExport && (
                        <Button
                          onClick={this.alertExportCSV}
                          disabled={this.state.totalRows <= 0}
                          color="primary"
                          variant="contained"
                          className="button_margin"
                          endIcon={<GetAppIcon className="csv-exporter-icon" />}
                        >
                          {t('calendar.csv_output')}
                        </Button>
                      )}
                    </>
                  )}

                  {permission.canSearch && (
                    <Button color="primary" variant="contained" className="button_margin" onClick={() => this.handleSearch(true)} endIcon={<SearchIcon />}>
                      {t('common.btnSearch')}
                    </Button>
                  )}
                </Grid>
              </Grid>

              <br></br>
            </Container>
          </Card>
          <br></br>
          {this.state.isSearch ? (
            <Card raised>
              <Container maxWidth="xl">
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={6}>
                    <h3>
                      {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                    </h3>
                  </Grid>
                  <LoadingOverlay active={business.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                    {this.state.result?.length > 0 ? (
                      <div className="scroll_area_700">
                        <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                          <TableHead>
                            <TableRow>
                              <TableCell>{t('businessInformation.id')}</TableCell>
                              <TableCell>{t('businessInformation.type')}</TableCell>
                              <TableCell>{t('businessInformation.status')}</TableCell>
                              <TableCell>{t('businessInformation.name')}</TableCell>
                              <TableCell>{t('businessInformation.role')}</TableCell>
                              {(permission.canEdit || permission.canDelete) && (
                                <TableCell>{t('common.action')}</TableCell>
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.result.map((row, index) => (
                              <TableRow key={index} hover className="cursor_pointer">
                                <TableCell scope="row">{row.supplier_id}</TableCell>
                                <TableCell>{BUSINESS_TYPE_OLD.map(({id, i18n}) => (row.supplier_type === id ? t(`${i18n}`) : ''))}</TableCell>
                                <TableCell>{BUSINESS_STATUS.map(({id, i18n}) => (row.supplier_status === id ? t(`${i18n}`) : ''))}</TableCell>
                                <TableCell>{row.supplier_name}</TableCell>
                                <TableCell>{BUSINESS_ROLE.map(({id, i18n}) => (row.business_role_type === id ? t(`${i18n}`) : ''))}</TableCell>
                                {(permission.canEdit || permission.canDelete) && (
                                  <TableCell>
                                    {permission.canEdit && (
                                      <Link
                                        style={{textDecoration: 'none'}}
                                        to={{
                                          pathname: ROUTE.LAYOUT + ROUTE.BUSINESS_DETAIL + '/' + row.id,
                                          state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                        }}
                                      >
                                        <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                          {t('common.btnEdit')}
                                        </Button>
                                      </Link>
                                    )}
                                    {permission.canDelete && !(isRoleBusiness() || (isRoleCountry() && row.business_role_type === 'COUNTRY')) &&(
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        className="button_margin button_color_red"
                                        endIcon={<DeleteIcon />}
                                        onClick={() => this.handleDelete(row.id, index)}
                                        disabled={isRoleBusiness() || (isRoleCountry() && row.business_role_type === 'COUNTRY')}
                                      >
                                        {t('common.btnDelete')}
                                      </Button>
                                    )}
                                  </TableCell>
                                )}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    ) : (
                      false
                    )}
                    <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                  </LoadingOverlay>
                </Grid>
                <br></br>
              </Container>
              <br></br>
            </Card>
          ) : (
            false
          )}
          <br></br>
          <Dialog
            open={this.state.flagDelete}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal onClickOk={this.handleButtonOk} onClickCancel={this.handleButtonCancel} message={this.state.message}></SelectModal>
          </Dialog>

          {/* Export alert modal */}
          <Dialog
            open={this.state.flagExport}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal
              onClickOk={this.handleButtonExportOk}
              onClickCancel={this.handleButtonExportCancel}
              okButtonText={t('common.btnYes')}
              cancelButtonText={t('common.btnNo')}
              message={this.state.message}
            ></SelectModal>
          </Dialog>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    business: state.business,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchListBusiness: (payload, queryParams) => dispatch(searchListBusiness(payload, queryParams)),
    exportBusiness: (payload, queryParams) => dispatch(exportBusiness(payload, queryParams)),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    resetListBusiness: () => dispatch(failSearchListBusiness()),
    getListSupplierBusiness: () => dispatch(getListSupplierBusiness()),
    deleteBusiness: (id, props) => dispatch(deleteBusiness(id, props)),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getAllGeofence: () => dispatch(getAllGeofence()),
    getListGeofenceByCountry: (id) => dispatch(getListGeofenceByCountry(id)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(BusinessInformation)));
