import React, {Component} from 'react';

import {Box, Button, Card, CardHeader, Container, Grid, Table, TableBody, TableCell, TableRow, Typography} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';

import {DATA_NULL} from '../../../../common/constant';
import {getSubCostDetail} from '../../../../stores/sub_cost/actions';
import {displayDateTime, convertDatetimeUTC} from '../../../../utils/datetime';
import withPermissionData from '../../../../withPermissionData/hoc';

/**
 * Sub Cost Detail
 */
class Index extends Component {
  /**
   * Render component
   * @return {component}
   */

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    const bookingId = this.props.match.params.id;
    await this.props.getSubCostDetail(bookingId);
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t, subCost, isLoading} = this.props;
    return (
      <LoadingOverlay active={isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('Sub Cost Detail')}</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}></Grid>
            </Grid>
          </Container>
          <Container maxWidth="xl" style={{paddingTop: 25}}>
            <Card raised>
              <Container maxWidth="xl">
                <CardHeader
                  title={
                    <Typography gutterBottom variant="h5" component="h5">
                      {t('Information')}
                    </Typography>
                  }
                />
                <Box pb={3}>
                  <div className="scroll_area_500">
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell className="width_50p ant-table-cell-fix-left">{t('geofence_id')}</TableCell>
                          <TableCell> {subCost?.geofence_id}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_75p">booking_id</TableCell>
                          <TableCell> {subCost?.booking_id}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_75p">{t('simulation_id')}</TableCell>
                          <TableCell>{subCost?.simulation_id}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_75p">{t('user_id')}</TableCell>
                          <TableCell>{subCost?.user_id}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_75p">{t('cost')}</TableCell>
                          <TableCell> {subCost?.cost}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_75p">{t('sub_id')}</TableCell>
                          <TableCell>
                            {' '}
                            {subCost?.sub_ids && subCost?.sub_ids.length > 0 ? (
                              subCost?.sub_ids.map((sub, index) => (
                                <span>
                                  {sub}
                                  {index < subCost?.sub_ids.length - 1 && <span>,&nbsp;</span>}
                                </span>
                              ))
                            ) : (
                              <div>{DATA_NULL}</div>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_75p">{t('mobi_infor')}</TableCell>
                          <TableCell>
                            {' '}
                            {subCost?.mobile_info && subCost?.mobile_info.length > 0 ? (
                              subCost?.mobile_info.map((infor, index) => (
                                <span>
                                  {infor}
                                  {index < subCost?.mobile_info.length - 1 && <span>,&nbsp;</span>}
                                </span>
                              ))
                            ) : (
                              <div>{DATA_NULL}</div>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_75p">{t('created_at')}</TableCell>
                          <TableCell>{displayDateTime(convertDatetimeUTC(subCost?.created_at))}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </Box>
              </Container>
            </Card>
          </Container>
          <Container maxWidth="xl">
            <Box p={1}>
              <Grid container spacing={2}>
                <Grid container alignItems="center" justify="flex-end" item xs={12}>
                  <Button endIcon={<ArrowBackIcon />} color="primary" variant="contained" className="button_margin button_color" onClick={this.props.history.goBack}>
                    {t('common.btnReturn')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    subCost: state.subCost.subCost,
    isLoading: state.subCost.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSubCostDetail: (payload, queryParams) => dispatch(getSubCostDetail(payload, queryParams)),
  };
};

export default withPermissionData(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
