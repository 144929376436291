export const logout = (force) => {
  localStorage.removeItem('TOKEN_KEY');
  if (force === true) {
    window.location.replace(`/maas/login?callbackUrl=${btoa(window.location.pathname + window.location.search)}`);
  } else {
    localStorage.removeItem('persist:root');
    window.location.replace('/maas/login');
  }
};

export const checkAuth = () => {
  if (!localStorage.getItem('TOKEN_KEY')) {
    window.location.replace(`/maas/login?callbackUrl=${btoa(window.location.pathname + window.location.search)}`);
  }
};

export const isAuth = () => {
  return localStorage.getItem('TOKEN_KEY');
};
