export const ACTION_TYPES = {
  UPDATE_BOOKING: 'UPDATE_BOOKING',
  GET_MEMBER_SUBSCRIPTION: 'GET_MEMBER_SUBSCRIPTION',

  GET_ROUTES_BOOKING_START: 'GET_ROUTES_BOOKING_START',
  GET_ROUTES_BOOKING_SUCCESS: 'GET_ROUTES_BOOKING_SUCCESS',
  GET_ROUTES_BOOKING_FAIL: 'GET_ROUTES_BOOKING_FAIL',

  SEARCH_RESERVATION_START: 'SEARCH_RESERVATION_START',
  SEARCH_RESERVATION_SUCCESS: 'SEARCH_RESERVATION_SUCCESS',
  SEARCH_RESERVATION_FAIL: 'SEARCH_RESERVATION_FAIL',

  DETAIL_RESERVATION_START: 'DETAIL_RESERVATION_START',
  DETAIL_RESERVATION_SUCCESS: 'DETAIL_RESERVATION_SUCCESS',
  DETAIL_RESERVATION_FAIL: 'DETAIL_RESERVATION_FAIL',

  FIND_DURATION_START: 'FIND_DURATION_START',
  FIND_DURATION_SUCCESS: 'FIND_DURATION_SUCCESS',
  FIND_DURATION_FAIL: 'FIND_DURATION_FAIL',

  UPDATE_RESERVATION_START: 'UPDATE_RESERVATION_START',
  UPDATE_RESERVATION_SUCCESS: 'UPDATE_RESERVATION_SUCCESS',
  UPDATE_RESERVATION_FAIL: 'UPDATE_RESERVATION_FAIL',

  GET_BOOKING_HISTORY_START: 'GET_BOOKING_HISTORY_START',
  GET_BOOKING_HISTORY_SUCCESS: 'GET_BOOKING_HISTORY_SUCCESS',
  GET_BOOKING_HISTORY_FAIL: 'GET_BOOKING_HISTORY_FAIL',

  CHANGE_NODE_STATUS_START: 'CHANGE_NODE_STATUS_START',
  CHANGE_NODE_STATUS_SUCCESS: 'CHANGE_NODE_STATUS_SUCCESS',
  CHANGE_NODE_STATUS_FAIL: 'CHANGE_NODE_STATUS_FAIL',

  SEARCH_BOOKING_UPDATE: 'SEARCH_BOOKING_UPDATE',
  SEARCH_BOOKING_RESET: 'SEARCH_BOOKING_RESET',

  MAP_GET_DATA_START: 'MAP_GET_DATA_START',
  MAP_GET_DATA_DONE: 'MAP_GET_DATA_DONE',

  REMOVE_SLOT_START: 'REMOVE_SLOT_START',
  REMOVE_SLOT_SUCCESS: 'REMOVE_SLOT_SUCCESS',
  REMOVE_SLOT_FAIL: 'REMOVE_SLOT_FAIL',

  CHANGE_PAYMENT_METHOD_START: 'CHANGE_PAYMENT_METHOD_START',
  CHANGE_PAYMENT_METHOD_SUCCESS: 'CHANGE_PAYMENT_METHOD_SUCCESS',
  CHANGE_PAYMENT_METHOD_FAIL: 'CHANGE_PAYMENT_METHOD_FAIL',

  ADMIN_CHANGE_PAYMENT_METHOD_START: 'ADMIN_CHANGE_PAYMENT_METHOD_START',
  ADMIN_CHANGE_PAYMENT_METHOD_SUCCESS: 'ADMIN_CHANGE_PAYMENT_METHOD_SUCCESS',
  ADMIN_CHANGE_PAYMENT_METHOD_FAIL: 'ADMIN_CHANGE_PAYMENT_METHOD_FAIL',

  GET_CHAT_HISTORY_START: 'GET_CHAT_HISTORY_START',
  GET_CHAT_HISTORY_SUCCESS: 'GET_CHAT_HISTORY_SUCCESS',
  GET_CHAT_HISTORY_FAIL: 'GET_CHAT_HISTORY_FAIL',

  GET_MESSAGE_TEMPLATE_START: 'GET_MESSAGE_TEMPLATE_START',
  GET_MESSAGE_TEMPLATE_SUCCESS: 'GET_MESSAGE_TEMPLATE_SUCCESS',
  GET_MESSAGE_TEMPLATE_FAIL: 'GET_MESSAGE_TEMPLATE_FAIL',

  SEARCH_BOOKING_JIT_START: 'SEARCH_BOOKING_JIT_START',
  SEARCH_BOOKING_JIT_SUCCESS: 'SEARCH_BOOKING_JIT_SUCCESS',
  SEARCH_BOOKING_JIT_FAIL: 'SEARCH_BOOKING_JIT_FAIL',

  GET_ROUTES_BOOKING_JIT_START: 'GET_ROUTES_BOOKING_JIT_START',
  GET_ROUTES_BOOKING_JIT_SUCCESS: 'GET_ROUTES_BOOKING_JIT_SUCCESS',
  GET_ROUTES_BOOKING_JIT_FAIL: 'GET_ROUTES_BOOKING_JIT_FAIL',

  GET_JIT_ROUTE_POLYLINE_START: 'GET_JIT_ROUTE_POLYLINE_START',
  GET_JIT_ROUTE_POLYLINE_SUCCESS: 'GET_JIT_ROUTE_POLYLINE_SUCCESS',
  GET_JIT_ROUTE_POLYLINE_FAIL: 'GET_JIT_ROUTE_POLYLINE_FAIL',

  BOOKING_JIT_START: 'BOOKING_JIT_START',
  BOOKING_JIT_SUCCESS: 'BOOKING_JIT_SUCCESS',
  BOOKING_JIT_FAIL: 'BOOKING_JIT_FAIL',

  CHANGE_PAYMENT_STATUS_START: 'CHANGE_PAYMENT_STATUS_START',
  CHANGE_PAYMENT_STATUS_SUCCESS: 'CHANGE_PAYMENT_STATUS_SUCCESS',
  CHANGE_PAYMENT_STATUS_FAIL: 'CHANGE_PAYMENT_STATUS_FAIL',

  RETRY_PAYMENT_CREDIT_CARD_START: 'RETRY_PAYMENT_CREDIT_CARD_START',
  RETRY_PAYMENT_CREDIT_CARD_START_SUCCESS: 'RETRY_PAYMENT_CREDIT_CARD_START_SUCCESS',
  RETRY_PAYMENT_CREDIT_CARD_START_FAIL: 'RETRY_PAYMENT_CREDIT_CARD_START_FAIL',

  WAYPOINT_ASSCOCIATION: 'WAYPOINT_ASSCOCIATION',

  GET_ADVANCE_BOOKING_OFFERS_START: 'GET_ADVANCE_BOOKING_OFFERS_START',
  GET_ADVANCE_BOOKING_OFFERS_SUCCESS: 'GET_ADVANCE_BOOKING_OFFERS_SUCCESS',
  GET_ADVANCE_BOOKING_OFFERS_FAIL: 'GET_ADVANCE_BOOKING_OFFERS_FAIL',

  GET_PARTNER_AND_ONE_TIME_CONFIG_START: 'GET_PARTNER_AND_ONE_TIME_CONFIG_START',
  GET_PARTNER_AND_ONE_TIME_CONFIG_SUCCESS: 'GET_PARTNER_AND_ONE_TIME_CONFIG_SUCCESS',
  GET_PARTNER_AND_ONE_TIME_CONFIG_FAIL: 'GET_PARTNER_AND_ONE_TIME_CONFIG_FAIL',

  SEARCH_BUS_RESERVATION_START: 'SEARCH_BUS_RESERVATION_START',
  SEARCH_BUS_RESERVATION_SUCCESS: 'SEARCH_BUS_RESERVATION_SUCCESS',
  SEARCH_BUS_RESERVATION_FAIL: 'SEARCH_BUS_RESERVATION_FAIL',

  GET_BUS_RESERVATION_DETAIL_START: 'GET_BUS_RESERVATION_DETAIL_START',
  GET_BUS_RESERVATION_DETAIL_SUCCESS: 'GET_BUS_RESERVATION_DETAIL_SUCCESS',
  GET_BUS_RESERVATION_DETAIL_FAIL: 'GET_BUS_RESERVATION_DETAIL_FAIL',
};

const initialState = {
  isLoading: false,
  listReservation: [],
  detailReservation: {},
  listSubscription: [],
  listRoutes: [],
  bookingInfo: {},
  searchBooking: {},
  booking_history: [],
  chatHistory: [],
  listBookingJIT: [],
  listJitRoutes: [],
  routePolyline: {},
  jitBookingInfor: {},
  listDuration: [],
  partner_and_one_time_info: {},
  waypointAssociation: {},
  listBusReservation: [],
  busReservationDetail: {},
};

/**
 * function reservation
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.SEARCH_RESERVATION_START:
    case ACTION_TYPES.SEARCH_BOOKING_JIT_START:
    case ACTION_TYPES.DETAIL_RESERVATION_START:
    case ACTION_TYPES.UPDATE_RESERVATION_START:
    case ACTION_TYPES.GET_BOOKING_HISTORY_START:
    case ACTION_TYPES.CHANGE_NODE_STATUS_START:
    case ACTION_TYPES.MAP_GET_DATA_START:
    case ACTION_TYPES.REMOVE_SLOT_START:
    case ACTION_TYPES.CHANGE_PAYMENT_METHOD_START:
    case ACTION_TYPES.GET_MESSAGE_TEMPLATE_START:
    case ACTION_TYPES.GET_CHAT_HISTORY_START:
    case ACTION_TYPES.BOOKING_JIT_START:
    case ACTION_TYPES.FIND_DURATION_START:
    case ACTION_TYPES.GET_JIT_ROUTE_POLYLINE_START:
    case ACTION_TYPES.ADMIN_CHANGE_PAYMENT_METHOD_START:
    case ACTION_TYPES.CHANGE_PAYMENT_STATUS_START:
    case ACTION_TYPES.RETRY_PAYMENT_CREDIT_CARD_START:
    case ACTION_TYPES.GET_ADVANCE_BOOKING_OFFERS_START:
    case ACTION_TYPES.GET_PARTNER_AND_ONE_TIME_CONFIG_START:
    case ACTION_TYPES.SEARCH_BUS_RESERVATION_START:
    case ACTION_TYPES.GET_BUS_RESERVATION_DETAIL_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.GET_ROUTES_BOOKING_START:
      return {
        ...state,
        isLoading: true,
        listRoutes: [],
      };
    case ACTION_TYPES.GET_ROUTES_BOOKING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listRoutes: action.listRoutes,
      };
    case ACTION_TYPES.GET_ROUTES_BOOKING_FAIL:
      return {
        ...state,
        isLoading: false,
        listRoutes: [],
      };
    case ACTION_TYPES.SEARCH_RESERVATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listReservation: action.listReservation,
      };
    case ACTION_TYPES.SEARCH_BUS_RESERVATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listBusReservation: action.listBusReservation,
      };
    case ACTION_TYPES.SEARCH_BOOKING_JIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listBookingJIT: action.listBookingJIT,
      };
    case ACTION_TYPES.FIND_DURATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listDuration: action.listDuration,
      };
    case ACTION_TYPES.DETAIL_RESERVATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        detailReservation: action.detailReservation,
      };
    case ACTION_TYPES.UPDATE_BOOKING:
      return {
        ...state,
        bookingInfo: action.bookingInfo,
      };
    case ACTION_TYPES.WAYPOINT_ASSCOCIATION:
      return {
        ...state,
        waypointAssociation: action.waypointAssociation,
      };
    case ACTION_TYPES.SEARCH_RESERVATION_FAIL:
      return {
        ...state,
        isLoading: false,
        listReservation: [],
      };
    case ACTION_TYPES.SEARCH_BOOKING_JIT_FAIL:
      return {
        ...state,
        isLoading: false,
        listBookingJIT: [],
      };
    case ACTION_TYPES.GET_CHAT_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        chatHistory: action.chatHistory,
      };
    case ACTION_TYPES.BOOKING_JIT_SUCCESS:
      return {
        ...state,
        jitBookingInfor: action.jitBookingInfor,
      };
    case ACTION_TYPES.RETRY_PAYMENT_CREDIT_CARD_START_FAIL:
    case ACTION_TYPES.RETRY_PAYMENT_CREDIT_CARD_START_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.DETAIL_RESERVATION_FAIL:
    case ACTION_TYPES.UPDATE_RESERVATION_SUCCESS:
    case ACTION_TYPES.UPDATE_RESERVATION_FAIL:
    case ACTION_TYPES.CHANGE_NODE_STATUS_SUCCESS:
    case ACTION_TYPES.CHANGE_NODE_STATUS_FAIL:
    case ACTION_TYPES.MAP_GET_DATA_DONE:
    case ACTION_TYPES.REMOVE_SLOT_SUCCESS:
    case ACTION_TYPES.REMOVE_SLOT_FAIL:
    case ACTION_TYPES.CHANGE_PAYMENT_METHOD_SUCCESS:
    case ACTION_TYPES.CHANGE_PAYMENT_METHOD_FAIL:
    case ACTION_TYPES.GET_MESSAGE_TEMPLATE_SUCCESS:
    case ACTION_TYPES.GET_MESSAGE_TEMPLATE_FAIL:
    case ACTION_TYPES.FIND_DURATION_FAIL:
      return {
        ...state,
        isLoading: false,
        listDuration: [],
      };
    case ACTION_TYPES.GET_CHAT_HISTORY_FAIL:
    case ACTION_TYPES.CHANGE_PAYMENT_STATUS_SUCCESS:
    case ACTION_TYPES.CHANGE_PAYMENT_STATUS_FAIL:
      return {
        ...state,
        isLoading: false,
        chatHistory: [],
      };
    case ACTION_TYPES.GET_MEMBER_SUBSCRIPTION:
      return {
        ...state,
        isLoading: false,
        listSubscription: action.listSubscription,
      };
    case ACTION_TYPES.SEARCH_BOOKING_UPDATE:
      return {
        ...state,
        isLoading: false,
        searchBooking: action.searchBooking,
      };
    case ACTION_TYPES.SEARCH_BOOKING_RESET:
      return {
        ...state,
        isLoading: false,
        searchBooking: {},
      };
    case ACTION_TYPES.GET_BOOKING_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        booking_history: action.booking_history,
      };
    case ACTION_TYPES.GET_BOOKING_HISTORY_FAIL:
      return {
        ...state,
        isLoading: false,
        booking_history: [],
      };
    case ACTION_TYPES.BOOKING_JIT_FAIL:
    case ACTION_TYPES.GET_JIT_ROUTE_POLYLINE_SUCCESS:
    case ACTION_TYPES.GET_JIT_ROUTE_POLYLINE_FAIL:
    case ACTION_TYPES.ADMIN_CHANGE_PAYMENT_METHOD_SUCCESS:
    case ACTION_TYPES.ADMIN_CHANGE_PAYMENT_METHOD_FAIL:
    case ACTION_TYPES.GET_ADVANCE_BOOKING_OFFERS_SUCCESS:
    case ACTION_TYPES.GET_ADVANCE_BOOKING_OFFERS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.GET_PARTNER_AND_ONE_TIME_CONFIG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        partner_and_one_time_info: action.partner_and_one_time_info,
      };
    case ACTION_TYPES.GET_PARTNER_AND_ONE_TIME_CONFIG_FAIL:
      return {
        ...state,
        isLoading: false,
        partner_and_one_time_info: [],
      };
    case ACTION_TYPES.SEARCH_BUS_RESERVATION_FAIL:
      return {
        ...state,
        isLoading: false,
        listBusReservation: [],
      };
    case ACTION_TYPES.GET_BUS_RESERVATION_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        busReservationDetail: action.busReservationDetail,
      };
    case ACTION_TYPES.GET_BUS_RESERVATION_DETAIL_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
