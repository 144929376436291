import {listPriceCalendarAPI, createPriceCalendarApi} from '../../services/productServices';
import {modalObj} from '../../utils/modal.js';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

/**
 * listPriceCalendar
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const listPriceCalendar = (params, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.LIST_PRICE_CALENDAR_START});
    return listPriceCalendarAPI(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.LIST_PRICE_CALENDAR_SUCCESS,
              listPriceCalendar: response.result,
            });
            return response.result;
          } else {
            dispatch({
              type: ACTION_TYPES.LIST_PRICE_CALENDAR_FAIL,
              error: response.message,
            });
            dispatch(setMessageModal(modalObj(true, response.message_code)));
          }
        },
        () => dispatch({
          type: ACTION_TYPES.LIST_PRICE_CALENDAR_FAIL,
          error: 'Internal Server Error',
        }),
    );
  };
};

/**
 * createPriceCalendar
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const createPriceCalendar = (params, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.CREATE_PRICE_CALENDAR_START});
    return createPriceCalendarApi(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.CREATE_PRICE_CALENDAR_SUCCESS});
            return response.result;
          } else {
            dispatch({type: ACTION_TYPES.CREATE_PRICE_CALENDAR_FAIL});
            dispatch(setMessageModal(modalObj(true, response.message_code)));
          }
        },
        () => dispatch({type: ACTION_TYPES.CREATE_PRICE_CALENDAR_FAIL}),
    );
  };
};
