import React, {Component} from 'react';

import {Button, Card, Container, FormControl, FormHelperText, Grid, Paper, TextField, Dialog} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {PERMISSION_ACTIONS} from '../../../common/constant';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getBank, createBank, updateBank} from '../../../stores/bank/action';
import {setMessageModal} from '../../../stores/modal/actions';
import {onChangeTextField} from '../../../utils/common';
import {modalObj} from '../../../utils/modal';

/**
 * Bank Form View
 */
class Index extends Component {
  /**
   * constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      code: '',
      bankName: '',
      branchName: '',
      branchCode: '',
      accountType: '',
      accountNumber: '',
      accountName: '',
      transferContent: '',
      isSubmitForm: false,
      showUpdateModal: false,
      showCreateModal: false,
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * handleCreate
   */
  handleCreate = async () => {
    this.closeCreateModal();
    const {bankName, branchName, branchCode, accountType, accountNumber, accountName, transferContent} = this.state;
    const payLoad = {
      bank_name: bankName,
      branch_name: branchName,
      branch_code: branchCode,
      account_type: accountType,
      account_number: accountNumber,
      account_name: accountName,
      transfer_content: transferContent,
    };
    this.props.createBank(payLoad, this.props);
  };

  /**
   * handleUpdate
   */
  handleUpdate = async () => {
    this.closeUpdateModal();
    const {id, bankName, branchName, branchCode, accountType, accountNumber, accountName, transferContent} = this.state;
    const payLoad = {
      id: id,
      bank_name: bankName,
      branch_name: branchName,
      branch_code: branchCode,
      account_type: accountType,
      account_number: accountNumber,
      account_name: accountName,
      transfer_content: transferContent,
    };
    this.props.updateBank(payLoad, this.props);
    this.props.getBank(this.props.match.params.id);
  };

  /**
   * openUpdateModal
   */
  openUpdateModal = () => {
    this.setState({isSubmitForm: true});
    if (!this.validator.allValid()) return;
    this.setState({showUpdateModal: true});
  };

  /**
   * closeUpdateModal
   */
  closeUpdateModal = () => {
    this.setState({isSubmitForm: false});
    this.setState({showUpdateModal: false});
  };

  /**
   * openCreateModal
   */
  openCreateModal = () => {
    this.setState({isSubmitForm: true});
    if (!this.validator.allValid()) return;
    this.setState({showCreateModal: true});
  };

  /**
   * closeCreateModal
   */
  closeCreateModal = () => {
    this.setState({isSubmitForm: false});
    this.setState({showCreateModal: false});
  };

  /**
   * notifyResponse
   * @param {string} message
   */
  notifyResponse = (message) => {
    this.props.setMessageModal(modalObj(true, message));
  };

  /**
   * componentDidMount
   */
  async componentDidMount() {
    if (this.props.match.params.id) {
      const bankId = this.props.match.params.id;
      this.props.getBank(bankId).then((response) => {
        this.setState({
          id: bankId,
          code: response.code,
          bankName: response.bank_name,
          branchName: response.branch_name,
          branchCode: response.branch_code,
          accountType: response.account_type,
          accountNumber: response.account_number,
          accountName: response.account_name,
          transferContent: response.transfer_content,
        });
      }, () => this.props.history.goBack);
    }
  }

  /**
   * Render component
   * @return {*}
   */
  render() {
    const {t, actions} = this.props;
    const isEditForm = this.props.match.params.id;
    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              {isEditForm ? <h3>{t('route.bankUpdate')}</h3> : <h3>{t('route.bankAdd')}</h3>}
            </Grid>
          </Grid>
        </Container>
        <br></br>
        <Container maxWidth="xl">
          <Card raised>
            <Container maxWidth="xl">
              <br></br>
              <Paper className="search_table">
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                    {isEditForm ? t('route.bankUpdate') : t('route.bankAdd')}
                  </Grid>
                </Grid>
                {/* Bank code */}
                {isEditForm && (
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('bankManagement.code')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl fullWidth>
                        <TextField name="code" className="field_size_10 field_min_size_300" margin="dense" value={this.state.code} variant="outlined" disabled={true} />
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
                {/* Bank name */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('bankManagement.bankName')} <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl fullWidth>
                      <div className="box-group-input">
                        <TextField
                          name="bankName"
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          placeholder={t('validation.required', {field: t('bankManagement.bankName')})}
                          value={this.state.bankName}
                          onChange={(event) => onChangeTextField(this, event)}
                          inputProps={{maxLength: 256}}
                          variant="outlined"
                        />
                        <div className="max-length-label">{t('validation.max.label', {value: 256})}</div>
                      </div>
                      {this.validator.message('bankName', this.state.bankName, 'required')}
                      {this.state.isSubmitForm && !this.validator.check(this.state.bankName, 'required') && (
                        <FormHelperText id="bankName" error>
                          {t('validation.required', {field: t('bankManagement.bankName')})}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                {/* Branch name */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('bankManagement.branchName')} <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl fullWidth>
                      <div className="box-group-input">
                        <TextField
                          name="branchName"
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          placeholder={t('validation.required', {field: t('bankManagement.branchName')})}
                          value={this.state.branchName}
                          onChange={(event) => onChangeTextField(this, event)}
                          inputProps={{maxLength: 256}}
                          variant="outlined"
                        />
                        <div className="max-length-label">{t('validation.max.label', {value: 256})}</div>
                      </div>
                      {this.validator.message('branchName', this.state.branchName, 'required')}
                      {this.state.isSubmitForm && !this.validator.check(this.state.branchName, 'required') && (
                        <FormHelperText id="branchName" error>
                          {t('validation.required', {field: t('bankManagement.branchName')})}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                {/* Branch code */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('bankManagement.branchCode')} <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl fullWidth>
                      <div className="box-group-input">
                        <TextField
                          name="branchCode"
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          placeholder={t('validation.required', {field: t('bankManagement.branchCode')})}
                          value={this.state.branchCode}
                          onChange={(event) => onChangeTextField(this, event)}
                          inputProps={{maxLength: 256}}
                          variant="outlined"
                        />
                        <div className="max-length-label">{t('validation.max.label', {value: 256})}</div>
                      </div>
                      {this.validator.message('branchCode', this.state.branchCode, 'required')}
                      {this.state.isSubmitForm && !this.validator.check(this.state.branchCode, 'required') && (
                        <FormHelperText id="branchCode" error>
                          {t('validation.required', {field: t('bankManagement.branchCode')})}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                {/* Account type */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('bankManagement.accountType')} <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl fullWidth>
                      <div className="box-group-input">
                        <TextField
                          name="accountType"
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          placeholder={t('validation.required', {field: t('bankManagement.accountType')})}
                          value={this.state.accountType}
                          onChange={(event) => onChangeTextField(this, event)}
                          inputProps={{maxLength: 256}}
                          variant="outlined"
                        />
                        <div className="max-length-label">{t('validation.max.label', {value: 256})}</div>
                      </div>
                      {this.validator.message('accountType', this.state.accountType, 'required')}
                      {this.state.isSubmitForm && !this.validator.check(this.state.accountType, 'required') && (
                        <FormHelperText id="accountType" error>
                          {t('validation.required', {field: t('bankManagement.accountType')})}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                {/* Account number */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('bankManagement.accountNumber')} <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl fullWidth>
                      <div className="box-group-input">
                        <TextField
                          name="accountNumber"
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          placeholder={t('validation.required', {field: t('bankManagement.accountNumber')})}
                          value={this.state.accountNumber}
                          onChange={(event) => onChangeTextField(this, event)}
                          inputProps={{maxLength: 256}}
                          variant="outlined"
                        />
                        <div className="max-length-label">{t('validation.max.label', {value: 256})}</div>
                      </div>
                      {this.validator.message('accountNumber', this.state.accountNumber, 'required')}
                      {this.state.isSubmitForm && !this.validator.check(this.state.accountNumber, 'required') && (
                        <FormHelperText id="accountNumber" error>
                          {t('validation.required', {field: t('bankManagement.accountNumber')})}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                {/* Account name */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('bankManagement.accountName')} <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl fullWidth>
                      <div className="box-group-input">
                        <TextField
                          name="accountName"
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          placeholder={t('validation.required', {field: t('bankManagement.accountName')})}
                          value={this.state.accountName}
                          onChange={(event) => onChangeTextField(this, event)}
                          inputProps={{maxLength: 256}}
                          variant="outlined"
                        />
                        <div className="max-length-label">{t('validation.max.label', {value: 256})}</div>
                      </div>
                      {this.validator.message('accountName', this.state.accountName, 'required')}
                      {this.state.isSubmitForm && !this.validator.check(this.state.accountName, 'required') && (
                        <FormHelperText id="accountName" error>
                          {t('validation.required', {field: t('bankManagement.accountName')})}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                {/* Transfer content */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('bankManagement.transferContent')} <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl variant="outlined" margin="dense" fullWidth>
                      <div className="box-group-input">
                        <TextField
                          name="transferContent"
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          placeholder={t('validation.required', {field: t('bankManagement.transferContent')})}
                          value={this.state.transferContent}
                          onChange={(event) => onChangeTextField(this, event)}
                          inputProps={{maxLength: 256}}
                          variant="outlined"
                        />
                        <div className="max-length-label">{t('validation.max.label', {value: 256})}</div>
                      </div>
                      {this.validator.message('transferContent', this.state.transferContent, 'required')}
                      {this.state.isSubmitForm && !this.validator.check(this.state.transferContent, 'required') && (
                        <FormHelperText id="transferContent" error>
                          {t('validation.required', {field: t('bankManagement.transferContent')})}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
              <Grid container spacing={1}>
                <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                <Grid container alignItems="center" justify="flex-end" item xs={9}>
                  {isEditForm ? permission.canUpdate && (
                    <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={() => this.openUpdateModal()}>
                      {t('common.btnUpdate')}
                    </Button>
                  ) : (
                    <>
                      <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={() => this.openCreateModal()}>
                        {t('common.btnRegister')}
                      </Button>
                    </>
                  )}
                  <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.props.history.goBack}>
                    {t('common.btnReturn')}
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Card>
          <br></br>
        </Container>
        <Dialog
          open={this.state.showUpdateModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <SelectModal onClickOk={this.handleUpdate} onClickCancel={this.closeUpdateModal} message={t('bankManagement.confirm_update')}></SelectModal>
        </Dialog>
        <Dialog
          open={this.state.showCreateModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <SelectModal onClickOk={this.handleCreate} onClickCancel={this.closeCreateModal} message={t('bankManagement.confirm_create')}></SelectModal>
        </Dialog>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    bank: state.bank,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    getBank: (id) => dispatch(getBank(id)),
    createBank: (payload, props) => dispatch(createBank(payload, props)),
    updateBank: (payload, props) => dispatch(updateBank(payload, props)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
