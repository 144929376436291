import {ROUTE} from '../../common/constant';
import {getVehicleListByGeofenceApi, getListVehicleOnGoingApi, getVehicleLocationApi} from '../../services/businessVehicleServices';
import {getVehicleFrequencyHistoryApi} from '../../services/mapServices';
import {searchListVehiclesApi, getVehicleApi, searchVehicleConfigApi, updateVehicleConfigApi, detailsVehicleConfigApi} from '../../services/productServices';
import {modalObj} from '../../utils/modal.js';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

/**
 * change password
 * @param {object} params
 * @return {object}
 */
export const searchListVehicles = (params) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.SEARCH_LIST_PRODUCT_START});
    return searchListVehiclesApi(params).then(
      (data) => {
        if (data && data.status === 200) {
          dispatch({
            type: ACTION_TYPES.SEARCH_LIST_PRODUCT_SUCCESS,
            listVehicles: data.result,
          });
          return data.result;
        } else {
          dispatch(setMessageModal(modalObj(true, data.message)));
          dispatch({
            type: ACTION_TYPES.SEARCH_LIST_PRODUCT_FAIL,
            error: data.message,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.SEARCH_LIST_PRODUCT_FAIL,
          error: 'Internal Server Error',
        }),
    );
  };
};

/**
 * change password
 * @param {object} params
 * @return {object}
 */
export const getVehicle = (params) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_VEHICLE_START});
    return getVehicleApi(params).then(
      (data) => {
        if (data && data.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_VEHICLE_SUCCESS,
            vehicle: data.result,
          });
          return data.result;
        } else {
          dispatch(setMessageModal(modalObj(true, data.message)));
          dispatch({
            type: ACTION_TYPES.GET_VEHICLE_FAIL,
            error: data.message,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_VEHICLE_FAIL,
          error: 'Internal Server Error',
        }),
    );
  };
};

/**
 * searchVehicleConfig
 * @param {*} payload
 * @param {*} queryParams
 * @return {*}
 */
export const searchVehicleConfig = (payload, queryParams) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.SEARCH_LIST_PRODUCT_START});
    return searchVehicleConfigApi(payload, queryParams).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.SEARCH_LIST_VEHICLE_CONFIG_SUCCESS,
            listVehicleConfig: response.result.content,
          });
          return response.result;
        }
      },
      () => dispatch({type: ACTION_TYPES.SEARCH_LIST_VEHICLE_CONFIG_FAIL}),
    );
  };
};

/**
 * detailVehicleConfig
 * @param {*} params
 * @return {*}
 */
export const detailVehicleConfig = (params) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.DETAILS_VEHICLE_CONFIG_START});
    return detailsVehicleConfigApi(params).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.DETAILS_VEHICLE_CONFIG_SUCCESS,
            detailsVehicleConfig: response.result,
          });
          return response.result;
        } else {
          dispatch({type: ACTION_TYPES.DETAILS_VEHICLE_CONFIG_FAIL});
        }
      },
      () => dispatch({type: ACTION_TYPES.DETAILS_VEHICLE_CONFIG_FAIL}),
    );
  };
};

/**
 * updateVehicleConfig
 * @param {*} payload
 * @param {*} props
 * @return  {*}
 */
export const updateVehicleConfig = (payload, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.UPDATE_VEHICLE_CONFIG_START});
    return updateVehicleConfigApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({type: ACTION_TYPES.UPDATE_VEHICLE_CONFIG_FAIL});
          const prevPath = props.location?.state?.from ? props.location?.state?.from : ROUTE.LAYOUT + ROUTE.VEHICLE_CONFIG;
          props.history.push(prevPath);
        } else {
          dispatch(setMessageModal(modalObj(true, response?.message_code)));
          dispatch({type: ACTION_TYPES.UPDATE_VEHICLE_CONFIG_FAIL});
        }
      },
      () => dispatch({type: ACTION_TYPES.UPDATE_VEHICLE_CONFIG_FAIL}),
    );
  };
};

/**
 * getVehicleFrequencyHistory
 * @param {*} payload
 * @param {*} props
 * @return  {*}
 */
export const getVehicleFrequencyHistory = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_VEHICLE_FREQUENCY_HISTORY_START});
    return getVehicleFrequencyHistoryApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_VEHICLE_FREQUENCY_HISTORY_SUCCESS,
            mapData: response.result.length > 0 ? response.result[0].data : [],
          });
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response?.message_code)));
          dispatch({type: ACTION_TYPES.GET_VEHICLE_FREQUENCY_HISTORY_FAIL});
        }
      },
      () => dispatch({type: ACTION_TYPES.GET_VEHICLE_FREQUENCY_HISTORY_FAIL}),
    );
  };
};

/**
 * getVehicleListByGeofence
 * @param {object} params
 * @return {object}
 */
 export const getVehicleListByGeofence = (params) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_VEHICLE_LIST_BY_GEOFENCE_START});
    return getVehicleListByGeofenceApi(params).then(
      (data) => {
        if (data && data.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_VEHICLE_LIST_BY_GEOFENCE_SUCCESS,
            vehicleListByGeofence: data.result,
          });
          return data.result;
        } else {
          dispatch(setMessageModal(modalObj(true, data.message)));
          dispatch({
            type: ACTION_TYPES.GET_VEHICLE_LiST_BY_GEOFENCE_FAIL,
            error: data.message,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_VEHICLE_LiST_BY_GEOFENCE_FAIL,
          error: 'Internal Server Error',
        }),
    );
  };
};

/**
 * getListVehicleOnGoing
 * @param {object} payload
 * @return {object}
 */
export const getListVehicleOnGoing = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_LIST_VEHICLE_ON_GOING_START});
    return getListVehicleOnGoingApi(payload).then(
      (data) => {
        if (data && data.status === 200) {
          dispatch({type: ACTION_TYPES.GET_LIST_VEHICLE_ON_GOING_SUCCESS});
          return data.result;
        } else {
          dispatch(setMessageModal(modalObj(true, data.message_code)));
          dispatch({
            type: ACTION_TYPES.GET_LIST_VEHICLE_ON_GOING_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_LIST_VEHICLE_ON_GOING_FAIL,
          error: 'Internal Server Error',
        }),
    );
  };
};

/**
 * getVehicleLocation
 * @param {object} payload
 * @return {object}
 */
export const getVehicleLocation = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_VEHICLE_LOCATION_START});
    return getVehicleLocationApi(payload).then(
      (data) => {
        if (data && data.status === 200) {
          dispatch({type: ACTION_TYPES.GET_VEHICLE_LOCATION_SUCCESS});
          return data.result;
        } else {
          dispatch(setMessageModal(modalObj(true, data.message_code)));
          dispatch({
            type: ACTION_TYPES.GET_VEHICLE_LOCATION_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_VEHICLE_LOCATION_FAIL,
          error: 'Internal Server Error',
        }),
    );
  };
};
