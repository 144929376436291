import React, {Component} from 'react';

import {Button, Container, Fade, Grid, Paper, Box} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import CancelCallIcon from '@material-ui/icons/PhoneDisabled';
import EndCallIcon from '@material-ui/icons/PhoneEnabled';
import MissCallIcon from '@material-ui/icons/PhoneMissed';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';

import {getMessageTemplate, getChatHistory} from '../../../../../stores/reservation/actions';
import {displayDate, convertDatetimeUTC} from '../../../../../utils/datetime';
import './style.css';

/**
 * Reservation Chat History
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
    };
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getChatHistory(this.props.bookingId);
    if (!localStorage.getItem('messageTemplate')) await this.props.getMessageTemplate().then((response) => localStorage.setItem('messageTemplate', JSON.stringify(response)));
    this.setState({isLoaded: true});
  }

  /**
   * handleClickClose
   */
  handleClickClose = () => {
    this.props.onClickClose();
  };

  /**
   * displayTime
   * @param {String} time
   * @return {String}
   */
  displayTime = (time) => {
    return convertDatetimeUTC(time, this.props.countryCode).substring(11, 16);
  };

  /**
   * displayCallTime
   * @param {String} startTime
   * @param {String} endTime
   * @return {String}
   */
  displayCallTime = (startTime, endTime) => {
    if (!startTime || !endTime) return '';
    const startTimeMoment = moment(`${new Date(startTime).getHours()}:${new Date(startTime).getMinutes()}:${new Date(startTime).getSeconds()}`, 'HH:mm:ss');
    const endTimeMoment = moment(`${new Date(endTime).getHours()}:${new Date(endTime).getMinutes()}:${new Date(endTime).getSeconds()}`, 'HH:mm:ss');
    const duration = moment.duration(endTimeMoment.diff(startTimeMoment));
    const minutes = parseInt(duration.asMinutes()) % 60;
    const seconds = parseInt(duration.asSeconds()) % 60;
    return `${minutes !== 0 ? minutes + ' ' + this.props.t('common.minute') + ' ' : ''}${seconds} ${this.props.t('common.second')}`;
  };

  /**
   * Render
   * @return {object}
   */
  render() {
    const {t, reservation, countryCode} = this.props;
    const {isLoading, chatHistory} = reservation;
    const messageTemplate = JSON.parse(localStorage.getItem('messageTemplate')) || {};
    const language = this.props.i18n.language;

    return (
      <Fade in={true}>
        <Paper className="modal_size_small">
          <LoadingOverlay active={isLoading || !this.state.isLoaded} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <br></br>
            <Container maxWidth="xl">
              <Grid container spacing={1}>
                <Grid container alignItems="flex-start" className="page_header" item xs={8}>
                  <img src={`${process.env.PUBLIC_URL}/favicon.ico`} alt="Logo" width="35" height="33"></img>
                  <span className="font_bold font_size_big header_title">{t('reservationManagement.chat_history')}</span>
                </Grid>
                <Grid container alignItems="flex-start" justify="flex-end" className="page_header" item xs={4}>
                  <CancelIcon onClick={this.handleClickClose} className="cursor_pointer" />
                </Grid>
              </Grid>
            </Container>
            <br />

            <Container maxWidth="xl" className="scroll_area_500">
              <Grid container spacing={1}>
                {isLoading || !this.state.isLoaded ? (
                  <span className="text-roboto">{t('reservationManagement.loading')}</span>
                ) : chatHistory && chatHistory.length > 0 ? (
                  chatHistory.map((item, index) => {
                    const displayThisDate =
                      index === 0 ||
                      displayDate(convertDatetimeUTC(item.created_at, countryCode)) !==
                        displayDate(convertDatetimeUTC(chatHistory[index - 1].created_at, countryCode));
                    const displayThisTime =
                      index === 0 ||
                      this.displayTime(item.created_at, countryCode) !== this.displayTime(chatHistory[index - 1].created_at, countryCode);
                    const displayNextDate =
                      index < chatHistory.length - 1 &&
                      displayDate(convertDatetimeUTC(item.created_at, countryCode)) !==
                        displayDate(convertDatetimeUTC(chatHistory[index + 1].created_at, countryCode));
                    const displayNextTime =
                      index < chatHistory.length - 1 && this.displayTime(item.created_at) !== this.displayTime(chatHistory[index + 1].created_at);
                    const textRole = item.sender_role === 'USER' ? ' text-right' : ' text-left';
                    let textPosition = '';
                    if (
                      (displayThisDate || displayThisTime || (index > 0 && item.sender_role !== chatHistory[index - 1].sender_role)) &&
                      (displayNextDate ||
                        displayNextTime ||
                        index === chatHistory.length - 1 ||
                        (index < chatHistory.length - 1 && item.sender_role !== chatHistory[index + 1].sender_role))
                    ) {
                      textPosition = 'text-once';
                    } else if (displayThisDate || displayThisTime || (index > 0 && item.sender_role !== chatHistory[index - 1].sender_role)) {
                      textPosition = 'text-first';
                    } else if (
                      displayNextDate ||
                      displayNextTime ||
                      (index < chatHistory.length - 1 && item.sender_role !== chatHistory[index + 1].sender_role) ||
                      index === chatHistory.length - 1
                    ) {
                      textPosition = 'text-last';
                    }
                    return (
                      <React.Fragment key={index}>
                        {displayThisDate && (
                          <Grid container alignItems="center" justify="center" item xs={12}>
                            <p className="text-datetime text-roboto">{displayDate(convertDatetimeUTC(item.created_at, countryCode))}</p>
                          </Grid>
                        )}
                        {displayThisTime && (
                          <Grid container alignItems="center" justify="center" item xs={12}>
                            <p className="text-datetime text-roboto font-14">{this.displayTime(item.created_at, countryCode)}</p>
                          </Grid>
                        )}
                        <Grid container alignItems="center" justify={item.sender_role === 'USER' ? 'flex-end' : 'flex-start'} style={{padding: 0, paddingBottom: 2}} item xs={12}>
                          {(item.message_type === 'TEXT' || item.message_type === 'TEMPLATE') && (
                            <p className={`message-text ${textRole} ${textPosition}`}>
                              <p className="text-role">{item.sender_role === 'DRIVER' ? t('businessVehicle.driverSeat') : t('common.user')}</p>
                              {item.message_type === 'TEXT' ?
                                item.origin_content :
                                (messageTemplate[`${item.sender_role}_${item.origin_content}_${language}`]?.content || item.origin_content)}
                            </p>
                          )}
                          {item.message_type === 'IMAGE' && (
                            <div className={`image-container ${textRole} ${textPosition}`}>
                              <img src={item.origin_content} alt={t('businessVehicle.driverSeat')}></img>
                              <p className="text-role">{item.sender_role === 'DRIVER' ? t('businessVehicle.driverSeat') : t('common.user')}</p>
                            </div>
                          )}
                          {item.message_type === 'CALL' && (
                            <div className={`call-container ${textRole} ${textPosition}`}>
                              <Box display="flex" flexDirection="row">
                                <Box>
                                  {(item.call_status === 'END_CALL' && <EndCallIcon className="icon-call end-call" />) ||
                                    (item.call_status === 'CANCEL' && <CancelCallIcon className="icon-call" />) ||
                                    (item.call_status === 'MISS' && <MissCallIcon className="icon-call" />)}
                                </Box>
                                <Box>
                                  <Box display="flex" flexDirection="column" style={{marginLeft: 10}}>
                                    <Box>
                                      <span className="font_bold text-roboto font_size_small_regular">{t('reservationManagement.voice_chat')}</span>
                                    </Box>
                                    <Box style={{marginTop: 3}}>
                                      <span className="text-roboto font_size_small">
                                        {(item.call_status === 'END_CALL' && this.displayCallTime(item.start_call_at, item.end_call_at)) ||
                                          (item.call_status === 'CANCEL' && t('reservationManagement.cancel_call')) ||
                                          (item.call_status === 'MISS' && t('reservationManagement.miss_call'))}
                                      </span>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                              <p className="text-role">{item.sender_role === 'DRIVER' ? t('businessVehicle.driverSeat') : t('common.user')}</p>
                            </div>
                          )}
                        </Grid>
                      </React.Fragment>
                    );
                  })
                ) : (
                  <span className="text-roboto">{t('reservationManagement.no_message_yet')}</span>
                )}
              </Grid>
            </Container>

            <br />
            <Container maxWidth="xl">
              <Grid container>
                <Grid container justify="flex-start" alignItems="flex-start" item xs={4}></Grid>
                <Grid container justify="flex-end" alignItems="flex-start" item xs={8}>
                  <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.handleClickClose} endIcon={<CloseIcon />}>
                    {t('common.btnClose')}
                  </Button>
                </Grid>
              </Grid>

              <br></br>
            </Container>
          </LoadingOverlay>
        </Paper>
      </Fade>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reservation: state.reservation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMessageTemplate: () => dispatch(getMessageTemplate()),
    getChatHistory: (bookingId) => dispatch(getChatHistory(bookingId)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
