/* eslint-disable new-cap */

import {format} from 'date-fns';
import moment from 'moment';

import {DATE_TIME_FORMAT} from '../common/constant';

/**
 * display DateTime Japanese
 * @param {Date} stringISO
 * @param {Boolean} withSecond
 * @return {DateTime}
 */
export const displayDateTime = (stringISO, withSecond = true) => {
  if (stringISO) {
    const datetime = (typeof stringISO !== 'string' ? new Date(stringISO).toISOString() : stringISO)
      ?.split('.')[0]
      .replace(/T|Z|\.\d{3}/g, ' ')
      .trim();
    return withSecond ? datetime : datetime.substring(0, 16);
  } else {
    return null;
  }
};

/**
 * display Date only
 * @param {String} string
 * @param {Boolean} isReplace
 * @return {String}
 */
export const displayDate = (string, isReplace = false) => {
  let date = string?.substr(0, 10);
  if (isReplace) {
    date = date.replace(/\//g, '-');
  }
  return date;
};

/**
 * display Hour only
 * @param {String} string
 * @param {Boolean} isReplace
 * @return {String}
 */
export const displayTime = (string) => {
  const hour = `${string?.substr(11, 2)}:${string?.substr(14, 2)}`;
  return hour;
};

/**
 * check date type
 * @param {*} date
 * @return {Boolean}
 */
export const isDate = (date) => {
  return new Date(date) !== 'Invalid Date' && !isNaN(new Date(date));
};

/**
 * convertHMSToHM
 * @param {*} time
 * @return {*}
 */
export const convertHMSToHM = (time) => {
  if (!time) return '--:--';
  return time.split(':')[0] + ':' + time.split(':')[1];
};

/**
 * convertMinuteToTime
 * @param {string} minute
 * @return {string}
 */
export const convertMinuteToTime = (minute) => {
  return ('0' + parseInt(minute / 60)).slice(-2) + ':' + ('0' + parseInt(minute % 60)).slice(-2);
};

export const getCurrentTimeZone = () => {
  return new Date().getTimezoneOffset() / -60;
};

/**
 * 1tTimeFromCalendarJapan
 * @param {string} time
 * @return {string}
 */
export const convertTimeUTCToCalendarJapan = (time) => {
  if (!time) return null;
  const timeOffset = new Date().getTimezoneOffset() + 540;
  const dateTime = new Date(time);
  dateTime.setHours(dateTime.getHours() + timeOffset / 60);
  return ('0' + dateTime.getHours()).slice(-2) + ':' + ('0' + dateTime.getMinutes()).slice(-2);
};

/**
 * time Local to UTC
 * @param {string} time
 * @return {datetime}
 */
export const convertTimeLocalToUTC = (time) => {
  if (!time) return null;
  // 540 is time offset in japan
  const timeOffset = new Date().getTimezoneOffset() + 9 * 60;
  const dateTime = new Date(time);
  dateTime.setHours(dateTime.getHours() - timeOffset / 60);
  return new Date(dateTime).toISOString();
};

/**
 * convert Date to format: yyyy-MM-dd hh:mm:ss
 * @param {Date} dateTime
 * @return {String}
 */
export const convertDateTimeToDate = (dateTime) => {
  if (!dateTime) return null;
  return dateTime.getFullYear() + '-' + ('0' + (dateTime.getMonth() + 1)).slice(-2) + '-' + ('0' + dateTime.getDate()).slice(-2);
};

/**
 * convert Date to format: yyyy-MM-dd 00:00:00
 * @param {Date} time
 * @return {String}
 */
export const convertDateToHourZero = (time) => {
  if (!time) return null;
  return new Date(time.getFullYear() + '-' + (time.getMonth() + 1) + '-' + time.getDate() + ' 00:00:00');
};

/**
 * toSeconds
 * @param {*} t
 * @return {*}
 */
export const toSeconds = (t) => {
  const bits = t.split(':');
  return bits[0] * 3600 + bits[1] * 60 + (bits[2] !== undefined ? bits[2] * 1 : 0);
};

/**
 * days_between
 * @param {*} date1
 * @param {*} date2
 * @return {*}
 */
export const days_between = (date1, date2) => {
  // The number of milliseconds in one day
  const ONE_DAY = 1000 * 60 * 60 * 24;

  // Calculate the difference in milliseconds
  const differenceMs = Math.abs(date1 - date2);

  // Convert back to days and return
  return Math.round(differenceMs / ONE_DAY);
};

/**
 * addDays
 * @param {*} theDate
 * @param {*} days
 * @return {*}
 */
export const addDays = (theDate, days) => {
  if (!theDate) return '';
  return new Date(theDate).getTime() + days * 24 * 60 * 60 * 1000;
};

/**
 * Convert display date time
 * @param {String} stringISO
 * @param {String} country_code
 * @return {String}
 */
export const convertDatetimeUTC = (stringISO, country_code) => {
  let result = '';
  if (stringISO) {
    const datetime = new Date(stringISO);
    // display timezone by client
    if (!country_code) {
      result = datetime.setHours(datetime.getHours() + getGMTTime());
    } else {
      switch (country_code) {
        case 'JP':
        case 2:
        case 'Asia/Tokyo':
          result = datetime.setHours(datetime.getHours() + 9);
          break;
        case 'SG':
        case 4:
        case 'Asia/Singapore':
        case 'MY':
        case 5:
        case 'Asia/Kuala_Lumpur':
          result = datetime.setHours(datetime.getHours() + 8);
          break;
        case 'VN':
        case 1:
        case 'Asia/Saigon':
          result = datetime.setHours(datetime.getHours() + 7);
          break;
        case 'GU':
        case 6:
        case 'Pacific/Guam':
          result = datetime.setHours(datetime.getHours() + 10);
          break;
        default:
          result = datetime;
      }
    }
    return new Date(result).toISOString();
  }
  return null;
};

/**
 * getGMTTime
 * @return {Number}
 */
export const getGMTTime = () => {
  return new Date().getTimezoneOffset() / -60;
};

/**
 * get client timezone
 * @return {String}
 */
export const getClientTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

/**
 * convertJSTToUTC
 * @param {Date} time
 * @return {String}
 */
export const convertJSTToUTC = (time) => {
  if (!time) return null;
  // 540 is time offset in japan
  const timeOffset = new Date().getTimezoneOffset() + 540;
  const dateTime = new Date(time);
  dateTime.setHours(dateTime.getHours() - timeOffset / 60);
  return new Date(dateTime).toISOString();
};

export const convertPrefixTime = (input) => {
  if (input >= 10) {
    return input;
  } else {
    return '0' + input;
  }
};

// sub = 1000 => to ignore millisecond
export const isOverlapDate = (first_time_start, first_time_end, second_time_start, second_time_end) => {
  const sub = 1000;
  if (!first_time_start || !first_time_end || !second_time_start || !second_time_end) {
    return false;
  }
  return (
    Math.floor(first_time_start.getTime() / sub) <= Math.floor(second_time_end.getTime() / sub) &&
    Math.floor(second_time_start.getTime() / sub) <= Math.floor(first_time_end.getTime() / sub)
  );
};

export const isOverlapDateRelatively = (first_time_start, first_time_end, second_time_start, second_time_end) => {
  if (!first_time_start || !first_time_end || !second_time_start || !second_time_end) {
    return false;
  }
  return first_time_start < second_time_end && second_time_start < first_time_end;
};

/**
 * convert Start/End Time
 * @param {String} time (ISOString require)
 * @param {Boolean} isStartTime
 * @return {String}
 */
export const convertStartEndTime = (time, isStartTime) => {
  if (!time) return;
  const date = new Date(time);
  return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2) + (isStartTime ? 'T00:00:00Z' : 'T23:59:59Z');
};

/**
 * Convert Date to default UTC +0
 * @param {*} time
 * @return {String}
 */
export const convertToUTCDefault = (time) => {
  if (!time) return;
  const date = new Date(time);
  return new Date(date.setHours(date.getHours() - getGMTTime()));
};

/**
 * getMaxDate
 * @param {Date} date
 * @return {String}
 */
export const getMaxDate = (date) => {
  if (!date) {
    return undefined;
  }
  date = new Date(date);
  return new Date(date.getFullYear(), date.getMonth() + 4, 0);
};

/**
 * compareDateTimeBetween
 * @param {Date} first_date
 * @param {Date} second_date
 * @param {Boolean} isEqualTo
 * @return {Boolean}
 */
export const compareDateTimeRange = (first_date, second_date, isEqualTo) => {
  if (!first_date || !second_date) return true;
  const startTime = new Date(first_date);
  const endTime = new Date(second_date);
  const totalMilliseconds1 = parseInt(startTime.getTime());
  const totalMilliseconds2 = parseInt(endTime.getTime());
  return startTime && endTime && (isEqualTo ? totalMilliseconds2 >= totalMilliseconds1 : totalMilliseconds2 > totalMilliseconds1);
};

/**
 * time Local to UTC
 * @param {string} time
 * @return {datetime}
 */
export const convertDateTimeToUTC = (time) => {
  if (!time) return null;
  const getTimezoneOffset = new Date().getTimezoneOffset();
  const dateTime = new Date(time);
  new Date(dateTime + getTimezoneOffset * 60000);
  return new Date(dateTime).toISOString();
};

/**
 * Get geofence time ISO String
 * @param {ISOString} time
 * @return {ISOString}
 */
export const getLocaleTimeISOString = (time) => {
  const timeObj = new Date(time);
  timeObj.setUTCHours(timeObj.getUTCHours() + getGMTTime());
  return timeObj.toISOString().substring(0, 19);
};

/**
 * getMinutes
 * @param {String} time
 * @return {Number}
 */
export const getMinutes = (time) => {
  return time ? Number(time.substring(0, 2)) * 60 + Number(time.substring(3, 5)) : null;
};

/** Compare two date
 * @param {*} startDate
 * @param {*} endDate
 * @return {Boolean}
 */
export const compareDateRange = (startDate, endDate) => {
  if (!startDate || !endDate || !isDate(startDate) || !isDate(endDate)) return true;
  return format(new Date(startDate), 'yyyy-MM-dd') <= format(new Date(endDate), 'yyyy-MM-dd');
};

/**
 * displayTextErrorTimeInvalid
 * @param {*} startTime HH:mm:ss
 * @param {*} endTime HH:mm:ss
 * @return {String} (from) HH:mm ~ (to) HH:mm
 */
export const displayTextErrorTimeInvalid = (startTime, endTime) => {
  const formatHHmmss = 'HH:mm:ss';
  const formatHHmm = 'HH:mm';
  return moment(startTime, formatHHmmss).format(formatHHmm) + ' ~ ' + moment(endTime, formatHHmmss).format(formatHHmm);
};

// eslint-disable-next-line valid-jsdoc
/**
 * roundedAndConvertTime
 * @param {*} isoDateTime
 * @param {*} country_code
 * @param {*} second
 * @returns
 */
export const roundedAndConvertTime = (isoDateTime, country_code, second = true) => {
  if (!isoDateTime) return null;
  const dateTimeUTC = convertDatetimeUTC(isoDateTime, country_code);
  const date = new Date(dateTimeUTC.replace('T', ' ').replace('Z', ''));
  const milliseconds = Math.round(date.getMilliseconds() / 1000) * 1000;
  date.setMilliseconds(milliseconds);
  return second ? format(date, 'yyyy-MM-dd HH:mm:ss') : format(date, DATE_TIME_FORMAT);
};

// eslint-disable-next-line valid-jsdoc
/**
 * roundedAndConvertTimeByZoneId
 * @param {*} isoDateTime
 * @param {*} zone_id
 * @param {*} format
 * @returns
 */
export const roundedAndConvertTimeByZoneId = (isoDateTime, zone_id, format) => {
  if (!isoDateTime) return null;
  const date = new Date(isoDateTime);
  const milliseconds = Math.round(date.getMilliseconds() / 1000) * 1000;
  date.setMilliseconds(milliseconds);
  return moment(date).tz(zone_id).format(format);
};


