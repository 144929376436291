import React, {Component} from 'react';

import {Card, Container, Grid, Button, CardContent, Typography, Box} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';

import {DATE_TIME_FORMAT_MOMENT, ODM_BOOKING_TIME_TYPES, ROUTE} from '../../../../common/constant';
import {getPercentShuttlePassenger} from '../../../../services/reservationServices';
import {updateBooking} from '../../../../stores/reservation/actions';
import {getSubTime, customDisplayCurrency} from '../../../../utils/common';
import {roundedAndConvertTimeByZoneId} from '../../../../utils/datetime';

/**
 * Reservation management view
 */
class OfferDetail extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    const {odm_booking_time_type, advance_booking_offer} = props.searchBooking;
    const isBookingAdvance = odm_booking_time_type === ODM_BOOKING_TIME_TYPES[1].id && advance_booking_offer?.booking_type === ODM_BOOKING_TIME_TYPES[1].id;
    this.state = {
      shuttle_bus_estimate_vehicle_id: ' ',
      percent_estimate: '',
      number_of_user: '',
      seats: '',
      isBookingAdvance,
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    if (_.isEmpty(this.props.bookingInfo)) this.props.history.push(ROUTE.LAYOUT + ROUTE.RESERVATION_CREATE);
    const route = this.props.searchBooking.advance_booking_offer?.travel_solution?.find(({type}) => type === 'SHUTTLE_BUS');
    const params = {
      pickUpTime: this.props.bookingInfo.requested_departure_time,
    };
    this.getPercentShuttlePassenger(route?.estimated_vehicle_id, params);
  }

  /**
   * getPercentShuttlePassenger
   * @param {*} vehicleId
   * @param {*} params
   */
  async getPercentShuttlePassenger(vehicleId, params) {
    const response = await getPercentShuttlePassenger(vehicleId, params);
    if (response.status === 200) {
      const {seats, number_of_user} = response.result;
      const percent = ((seats - number_of_user) / seats) * 100;
      this.setState({
        percent_estimate: percent.toFixed(2),
        number_of_user: number_of_user,
        seats: seats,
      });
    }
  }

  /**
   * toPageInfo
   */
  toPageInfo = () => {
    const {searchBooking, bookingInfo} = this.props;
    const departureTransitStop = searchBooking.advance_booking_offer?.travel_solution?.find((item) => (item.type === 'SHUTTLE_BUS' || item.type === 'TAXI'));

    if (departureTransitStop) {
      this.props.onUpdateBooking({
        ...bookingInfo,
        requested_departure_time: departureTransitStop?.estimated_pickup_time,
        simulation_id: departureTransitStop?.service?.external_simulation_id || '',
        estimate_start_time: departureTransitStop?.estimated_pickup_time,
        estimate_arrival_time: departureTransitStop?.estimated_dropoff_time,
      });
      this.props.history.push(ROUTE.LAYOUT + ROUTE.RESERVATION_MANAGEMENT_PASSENGER_DETAIL);
    }
  };

  /**
   * linkGoogleMap
   * @param {number} latitude
   * @param {number} longitude
   */
  linkGoogleMap = (latitude, longitude) => {
    window.open(`https://maps.google.com/?q=${latitude},${longitude}`, '_blank');
  };

  /**
   * calcStartLocationTime
   * @param {ISOString} pickupTime
   * @param {Number} walkingDuration
   * @return {ISOString}
   */
  calcStartLocationTime = (pickupTime, walkingDuration) => {
    const time = new Date(pickupTime);
    time.setMinutes(time.getMinutes() - walkingDuration);
    return time.toISOString();
  }

  /**
   * calcEndLocationTime
   * @param {ISOString} dropoffTime
   * @param {Number} walkingDuration
   * @return {ISOString}
   */
  calcEndLocationTime = (dropoffTime, walkingDuration) => {
    const time = new Date(dropoffTime);
    time.setMinutes(time.getMinutes() + walkingDuration);
    return time.toISOString();
  }

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t, searchBooking} = this.props;
    const {advance_booking_offer: offer, positionFrom, positionTo, zone_id, service_config} = searchBooking;
    const additional_adult_price = service_config?.advance_booking_prices?.[0]?.additional_adult_price;
    const additional_child_price = service_config?.advance_booking_prices?.[0]?.additional_child_price;
    const route = offer.travel_solution.find(({type}) => type === 'SHUTTLE_BUS');
    const {
      estimated_pickup_time,
      estimated_pickup_transit_display_name,
      estimated_pickup_location_lat,
      estimated_pickup_location_lon,
      estimated_pickup_walking_time,
      estimated_dropoff_time,
      estimated_dropoff_location_lat,
      estimated_dropoff_location_lon,
      estimated_dropoff_transit_display_name,
      estimated_dropoff_walking_time,
    } = route;

    const pickup_walking_duration = Math.round(estimated_pickup_walking_time / 60);
    const dropoff_walking_duration = Math.round(estimated_dropoff_walking_time / 60);
    const start_location_time = pickup_walking_duration > 0 ? this.calcStartLocationTime(estimated_pickup_time, pickup_walking_duration) : null;
    const end_location_time = dropoff_walking_duration > 0 ? this.calcEndLocationTime(estimated_dropoff_time, dropoff_walking_duration) : null;
    const total_time = pickup_walking_duration + getSubTime(estimated_dropoff_time, estimated_pickup_time) + dropoff_walking_duration;

    return (
      <div>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('reservationManagement.route')}</h3>
              </Grid>
              <Grid container alignItems="center" justifyContent="flex-end" item xs={6}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />} onClick={this.props.history.goBack}>
                  {t('common.btnReturn')}
                </Button>
              </Grid>
            </Grid>
          </Container>

          {/* step 3 */}
          {route && (
            <Container maxWidth="xl">
              <Box p={2} m={2}>
                <Grid container spacing={3} alignItems="flex-start">
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Box mt={2} mb={4}>
                          <Grid container spacing={3} alignItems="flex-start">
                            <Grid item xs={12}>
                              <Typography display="inline" variant="h3" component="h3">
                                {total_time}{' '}
                              </Typography>
                              <Typography display="inline" variant="h6" component="h6">
                                {t('common.minute')}&nbsp;&nbsp;
                              </Typography>
                              <Typography display="inline" variant="h6" component="h6">
                                {t('reservationManagement.number_transfer')}：{offer.transfer_count || 0} {t('reservationManagement.counts')}
                              </Typography>
                            </Grid>
                          </Grid>
                          {this.state.isBookingAdvance && searchBooking?.advance_without_partner && (additional_adult_price !== 0 || additional_child_price !== 0) && (
                            <Grid item xs={12}>
                              <p>
                                <Box pl={3}>
                                  <b>
                                    <i>
                                      {t('reservationManagement.advance.one_time_fee_adult_child', {
                                        fee_adult: customDisplayCurrency(additional_adult_price, service_config?.currency_code),
                                        fee_child: customDisplayCurrency(additional_child_price, service_config?.currency_code),
                                      })}
                                    </i>
                                  </b>
                                </Box>
                              </p>
                            </Grid>
                          )}
                        </Box>

                        {pickup_walking_duration > 0 && (
                          <>
                            <Grid container spacing={3} alignItems="flex-start">
                              <Grid item xs={2}>
                                <Typography gutterBottom variant="h4" component="h4">
                                  <div className="item_booking item_booking_bg_black">発</div>
                                </Typography>
                              </Grid>
                              <Grid item xs={7}>
                                <Typography gutterBottom variant="h4" component="h4">
                                  {positionFrom.place_name}
                                </Typography>
                                <Button
                                  target="_blank"
                                  variant="outlined"
                                  color="primary"
                                  href=""
                                  onClick={() => this.linkGoogleMap(positionFrom?.latLng?.lat(), positionFrom?.latLng?.lng())}
                                >
                                  {t('newReservation.linkMap2')}
                                </Button>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography gutterBottom variant="h4" component="h4" className="text_right text_black">
                                  {roundedAndConvertTimeByZoneId(start_location_time, zone_id, DATE_TIME_FORMAT_MOMENT)}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container spacing={3} alignItems="flex-start">
                              <Grid item className="width_5 break_bottom_icon">
                                <MoreVertIcon />
                              </Grid>
                              <Grid item className="width_80 break_bottom_line"></Grid>
                              <Grid item className="width_15 break_bottom_text text_right text_grey">
                                {t('reservationManagement.waiting_time')}：{pickup_walking_duration} {t('common.minute')}
                              </Grid>
                            </Grid>

                            <Grid container spacing={3} alignItems="flex-start">
                              <Grid item xs={2}>
                                <div className="item_booking item_booking_bg_grey">
                                  <img className="width_100" src={process.env.PUBLIC_URL + '/images/reservation/walking.svg'} alt="" />
                                </div>
                              </Grid>
                              <Grid item xs={7}>
                                <Typography gutterBottom variant="h4" component="h4">
                                  {estimated_pickup_transit_display_name}
                                </Typography>
                                <Button
                                  target="_blank"
                                  variant="outlined"
                                  color="primary"
                                  href=""
                                  onClick={() => this.linkGoogleMap(estimated_pickup_location_lat, estimated_pickup_location_lon)}
                                >
                                  {t('newReservation.linkMap2')}
                                </Button>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography gutterBottom variant="h4" component="h4" className="text_right">
                                  {t('reservationManagement.arrival_time')}
                                </Typography>
                                <Typography gutterBottom variant="h4" component="h4" className="text_right text_black">
                                  {roundedAndConvertTimeByZoneId(estimated_pickup_time, zone_id, DATE_TIME_FORMAT_MOMENT)}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container spacing={3} alignItems="flex-start">
                              <Grid item className="width_5 break_bottom_icon">
                                <MoreVertIcon />
                              </Grid>
                              <Grid item className="width_80 break_bottom_line"></Grid>
                              <Grid item className="width_15 break_bottom_text text_right text_grey">
                                {t('reservationManagement.waiting_time')}：{0} {t('common.minute')}
                              </Grid>
                            </Grid>
                          </>
                        )}

                        {pickup_walking_duration === 0 && (
                          <>
                            <Grid container spacing={3} alignItems="flex-start">
                              <Grid item xs={2}>
                                <Typography gutterBottom variant="h4" component="h4">
                                  <div className="item_booking item_booking_bg_black">発</div>
                                </Typography>
                              </Grid>
                              <Grid item xs={7}>
                                <Typography gutterBottom variant="h4" component="h4">
                                  {estimated_pickup_transit_display_name}
                                </Typography>
                                <Button
                                  target="_blank"
                                  variant="outlined"
                                  color="primary"
                                  href=""
                                  onClick={() => this.linkGoogleMap(estimated_pickup_location_lat, estimated_pickup_location_lon)}
                                >
                                  {t('newReservation.linkMap2')}
                                </Button>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography gutterBottom variant="h4" component="h4" className="text_right text_black">
                                  {roundedAndConvertTimeByZoneId(estimated_pickup_time, zone_id, DATE_TIME_FORMAT_MOMENT)}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container spacing={3} alignItems="flex-start">
                              <Grid item className="width_5 break_bottom_icon">
                                <MoreVertIcon />
                              </Grid>
                              <Grid item className="width_80 break_bottom_line"></Grid>
                              <Grid item className="width_15 break_bottom_text text_right text_grey">
                                {t('reservationManagement.waiting_time')}：{0} {t('common.minute')}
                              </Grid>
                            </Grid>
                          </>
                        )}

                        <Grid container spacing={3} alignItems="flex-start">
                          <Grid item xs={2}>
                            <div className="item_booking item_booking_bg_orange">
                              <img className="width_100" src={process.env.PUBLIC_URL + '/images/reservation/taxi.svg'} alt="" />
                            </div>
                          </Grid>
                          <Grid item xs={7}>
                            <Typography gutterBottom variant="h4" component="h4">
                              <img alt="" className="width_15" src={process.env.PUBLIC_URL + '/images/reservation/mobi.svg'} />
                            </Typography>
                            {this.state.percent_estimate && (
                              <Typography gutterBottom variant="h5" component="h5" className="font_color_orange">
                                {t('reservationManagement.estimate_on_board')}: {this.state.number_of_user} {t('reservationManagement.seats')} / {this.state.seats}
                                {t('reservationManagement.seats')}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item xs={3}>
                            <Typography gutterBottom variant="h4" component="h4" className="text_right">
                              {t('reservationManagement.pick_up_time')}
                            </Typography>
                            <Typography gutterBottom variant="h4" component="h4" className="text_right text_black">
                              {roundedAndConvertTimeByZoneId(estimated_pickup_time, zone_id, DATE_TIME_FORMAT_MOMENT)}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid container spacing={3} alignItems="flex-start">
                          <Grid item className="width_5 break_bottom_icon">
                            <MoreVertIcon className="font_color_red" />
                          </Grid>
                          <Grid item className="width_80 break_bottom_line"></Grid>
                          <Grid item className="width_15 break_bottom_text text_right text_grey">
                            {t('reservationManagement.waiting_time')}：{getSubTime(estimated_dropoff_time, estimated_pickup_time)} {t('common.minute')}
                          </Grid>
                        </Grid>

                        {dropoff_walking_duration > 0 && (
                          <>
                            <Grid container spacing={3} alignItems="flex-start">
                              <Grid item xs={2}>
                                <div className="item_booking item_booking_bg_grey">
                                  <img className="width_100" src={process.env.PUBLIC_URL + '/images/reservation/walking.svg'} alt="" />
                                </div>
                              </Grid>
                              <Grid item xs={7}>
                                <Typography gutterBottom variant="h4" component="h4">
                                  {estimated_dropoff_transit_display_name}
                                </Typography>
                                <Button
                                  target="_blank"
                                  variant="outlined"
                                  color="primary"
                                  href=""
                                  onClick={() => this.linkGoogleMap(estimated_dropoff_location_lat, estimated_dropoff_location_lon)}
                                >
                                  {t('newReservation.linkMap2')}
                                </Button>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography gutterBottom variant="h4" component="h4" className="text_right">
                                  {t('reservationManagement.drop_off_time')}
                                </Typography>
                                <Typography gutterBottom variant="h4" component="h4" className="text_right text_black">
                                  {roundedAndConvertTimeByZoneId(estimated_dropoff_time, zone_id, DATE_TIME_FORMAT_MOMENT)}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid container spacing={3} alignItems="flex-start">
                              <Grid item className="width_5 break_bottom_icon">
                                <MoreVertIcon className="font_color_red" />
                              </Grid>
                              <Grid item className="width_80 break_bottom_line"></Grid>
                              <Grid item className="width_15 break_bottom_text text_right text_grey">
                                {t('reservationManagement.waiting_time')}：{dropoff_walking_duration} {t('common.minute')}
                              </Grid>
                            </Grid>

                            <Grid container spacing={3} alignItems="flex-start">
                              <Grid item xs={2}>
                                <Typography gutterBottom variant="h4" component="h4">
                                  <div className="item_booking item_booking_bg_black">着</div>
                                </Typography>
                              </Grid>
                              <Grid item xs={7}>
                                <Typography gutterBottom variant="h4" component="h4">
                                  {positionTo?.place_name}
                                </Typography>
                                <Button
                                  target="_blank"
                                  variant="outlined"
                                  color="primary"
                                  href=""
                                  onClick={() => this.linkGoogleMap(positionTo?.latLng?.lat(), positionTo?.latLn?.lng())}
                                >
                                  {t('newReservation.linkMap2')}
                                </Button>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography gutterBottom variant="h4" component="h4" className="text_right">
                                  {t('reservationManagement.arrival_time')}
                                </Typography>
                                <Typography gutterBottom variant="h4" component="h4" className="text_right text_black">
                                  {roundedAndConvertTimeByZoneId(end_location_time, zone_id, DATE_TIME_FORMAT_MOMENT)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </>
                        )}

                        {dropoff_walking_duration === 0 && (
                          <Grid container spacing={3} alignItems="flex-start">
                            <Grid item xs={2}>
                              <Typography gutterBottom variant="h4" component="h4">
                                <div className="item_booking item_booking_bg_black">着</div>
                              </Typography>
                            </Grid>
                            <Grid item xs={7}>
                              <Typography gutterBottom variant="h4" component="h4">
                                {estimated_dropoff_transit_display_name}
                              </Typography>
                              <Button
                                target="_blank"
                                variant="outlined"
                                color="primary"
                                href=""
                                onClick={() => this.linkGoogleMap(estimated_dropoff_location_lat, estimated_dropoff_location_lon)}
                              >
                                {t('newReservation.linkMap2')}
                              </Button>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography gutterBottom variant="h4" component="h4" className="text_right">
                                {t('reservationManagement.drop_off_time')}
                              </Typography>
                              <Typography gutterBottom variant="h4" component="h4" className="text_right text_black">
                                {roundedAndConvertTimeByZoneId(estimated_dropoff_time, zone_id, DATE_TIME_FORMAT_MOMENT)}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}

                        <Box mt={2} mb={2}>
                          <Grid container spacing={3} alignItems="flex-start">
                            <Grid item xs={12} className="item_center_div">
                              <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.toPageInfo()}>
                                {t('reservationManagement.next')}
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          )}
        </Card>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateBooking: (bookingData) => {
      dispatch(updateBooking(bookingData));
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  const itemId = ownProps.match.params.id;
  const data = state.reservation.listRoutes.find((item) => item.id === itemId);
  return {
    routeItem: data,
    booking: state.reservation.bookingData,
    bookingInfo: state.reservation.bookingInfo,
    searchBooking: state.reservation.searchBooking,
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(OfferDetail));
