import {ROUTE} from "../../common/constant";
import {
  getNewsTemplatesApi,
  getNewsTemplateByIdApi,
  upsertNewsTemplateApi,
  deleteNewsTemplateApi,
  getDetailNewsApi,
  searchNewsApi,
  createNewsApi,
  deleteNewsApi,
} from "../../services/commonSettingServices";
import {modalObj} from "../../utils/modal";
import {setMessageModal} from "../modal/actions";
import {ACTION_TYPES} from "./reducer"

/**
 * Get all news templates
 * @return {Promise}
 */
export const getNewsTemplates = () => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_NEWS_TEMPLATES_START});
    return getNewsTemplatesApi().then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_NEWS_TEMPLATES_SUCCESS,
            templates: response.result,
          });
          return response.result;
        } else {
          dispatch({type: ACTION_TYPES.GET_NEWS_TEMPLATES_FAIL});
          dispatch(setMessageModal(modalObj(true, response.message_code)));
        };
      },
      () => {
        dispatch({type: ACTION_TYPES.GET_NEWS_TEMPLATES_FAIL});
        dispatch(setMessageModal(modalObj(true, 'Internal Server Error!')));
      }
    )
  }
}

/**
 * Get news template by template Id
 * @param {Number} newsTemplateId
 * @return {Promise}
 */
export const getNewsTemplateById = (newsTemplateId) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_NEWS_TEMPLATE_BY_ID_START});
    return getNewsTemplateByIdApi(newsTemplateId).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_NEWS_TEMPLATE_BY_ID_SUCCESS,
            templateDetail: response.result,
          });
          return response.result;
        } else {
          dispatch({type: ACTION_TYPES.GET_NEWS_TEMPLATE_BY_ID_FAIL});
          dispatch(setMessageModal(modalObj(true, response.message_code)));
        };
      },
      () => {
        dispatch({type: ACTION_TYPES.GET_NEWS_TEMPLATE_BY_ID_FAIL});
        dispatch(setMessageModal(modalObj(true, 'Internal Server Error!')));
      }
    )
  }
}

/**
 * Update news template
 * @param {Object} payload
 * @return {Promise}
 */
export const upsertNewsTemplate = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.UPSERT_NEWS_TEMPLATE_START});
    return upsertNewsTemplateApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({type: ACTION_TYPES.UPSERT_NEWS_TEMPLATE_SUCCESS});
          dispatch(setMessageModal(modalObj(true, 'Api.success')));
          return response;
        } else {
          dispatch({type: ACTION_TYPES.UPSERT_NEWS_TEMPLATE_FAIL});
          dispatch(setMessageModal(modalObj(true, response.message_code)));
        };
      },
      () => {
        dispatch({type: ACTION_TYPES.UPSERT_NEWS_TEMPLATE_FAIL});
        dispatch(setMessageModal(modalObj(true, 'Internal Server Error!')))
      }
    )
  }
}

/**
 * Delete news template
 * @param {Number} newsTemplateId 
 * @return {Promise}
 */
export const deleteNewsTemplate = (newsTemplateId) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.DELETE_NEWS_TEMPLATE_START});
    return deleteNewsTemplateApi(newsTemplateId).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({type: ACTION_TYPES.DELETE_NEWS_TEMPLATE_SUCCESS});
          dispatch(setMessageModal(modalObj(true, 'Api.success')))
          return response;
        } else {
          dispatch({type: ACTION_TYPES.DELETE_NEWS_TEMPLATE_FAIL});
          dispatch(setMessageModal(modalObj(true, response.message_code)));
        };
      },
      () => {
        dispatch({type: ACTION_TYPES.DELETE_NEWS_TEMPLATE_FAIL});
        dispatch(setMessageModal(modalObj(true, 'Internal Server Error!')));
      }
    )
  }
}

/**
 * Search news
 * @param {Object} payload
 * @param {Object} params
 * @param {Object} browserHistory
 * @return {Promise}
 */
export const searchNews = (payload, params, browserHistory) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.SEARCH_NEWS_START});
    return searchNewsApi(payload, params).then(
      (response) => {
        if (response.status === 200) {
          dispatch({type: ACTION_TYPES.SEARCH_NEWS_SUCCESS});
          return response;
        } else {
          if (response.status === 403) {
            dispatch(setMessageModal(modalObj(true, 'error.403')));
            browserHistory.replace(ROUTE.LAYOUT + ROUTE.HOME);
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
          }
        }
      },
      () => {
        dispatch(setMessageModal(modalObj(true, 'error.500')));
      },
    );
  };
};

/**
 * Get news detail by id
 * @param {Number} id
 * @param {Object} browserHistory
 * @return {Promise}
 */
export const getNewsDetail = (id, browserHistory) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_NEWS_DETAIL_START});
    return getDetailNewsApi(id).then(
      (response) => {
        if (response?.status === 200) {
          dispatch({type: ACTION_TYPES.GET_NEWS_DETAIL_SUCCESS});
          return response;
        } else {
          dispatch({type: ACTION_TYPES.GET_NEWS_DETAIL_FAIL});
          if (response?.status === 403) {
            dispatch(setMessageModal(modalObj(true, 'error.403')));
            browserHistory.replace(ROUTE.LAYOUT + ROUTE.NEWS_MANAGEMENT);
          } else {
            dispatch(setMessageModal(modalObj(true, 'error.500')));
          }
        }
      },
      () => {
        dispatch({type: ACTION_TYPES.GET_NEWS_DETAIL_FAIL});
        dispatch(setMessageModal(modalObj(true, 'error.500')));
      },
    );
  };
};

/**
 * Create news
 * @param {Object} payload
 * @return {Promise}
 */
export const createNews = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.CREATE_NEWS_START});
    return createNewsApi(payload).then(
      (response) => {
        if (response?.status === 200) {
          dispatch({type: ACTION_TYPES.CREATE_NEWS_SUCCESS});
          return response;
        } else {
          dispatch({type: ACTION_TYPES.CREATE_NEWS_FAIL});
          dispatch(setMessageModal(modalObj(true, response?.message_code)));
        }
      },
      () => {
        dispatch(setMessageModal(modalObj(true, 'error.500')));
      },
    );
  };
};

/**
 * Delete news
 * @param {Number} id
 * @return {Promise}
 */
export const deleteNews = (id) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.DELETE_NEWS_START});
    return deleteNewsApi(id).then(
      (response) => {
        if (response?.status === 200) {
          dispatch({type: ACTION_TYPES.DELETE_NEWS_SUCCESS});
          return response;
        } else {
          dispatch({type: ACTION_TYPES.DELETE_NEWS_FAIL});
          dispatch(setMessageModal(modalObj(true, response?.message_code)));
        }
      },
      () => {
        dispatch(setMessageModal(modalObj(true, 'error.500')));
      },
    );
  };
};
