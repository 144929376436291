import React, {Component} from 'react';

import {Paper, Grid, TextField} from '@material-ui/core';
import {withTranslation} from 'react-i18next';

import {displayDateTime, convertDatetimeUTC} from '../../utils/datetime';
import './style.css';

/**
 * new memo component
 */
class Index extends Component {
  /**
   * onChange content field
   * @param {object} value
   * object value include:
   * - index: content's index
   * - field: field name
   * - newValue
   */
  handleChange = (value) => {
    this.props.onChangeContent(value);
  };

  /**
   * onChange note field
   * @param {string} value
   */
  handleNewNote = (value) => {
    this.props.onChangeNote(value.target.value);
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    /**
     * Props for parent component
     * memos: value memos
     * onChangeContent: update value while onChange TextField's value
     * note: value new note
     * on
     * parentType:
     * - medium: wrapper width < 1200px
     * - large: wrapper width >= 1200px
     */
    const {t, memos, parentType, note, disabled, countryCode, title, isScroll} = this.props;
    return (
      <Paper className={isScroll ? 'memo_table_scroll' : 'memo_table'}>
        <Grid container className="row_form_item memo_header font_bold">
          <Grid container alignItems="center" item xs={12} lg={12}>
            {title ? t(title) : t('updateAccount.memo')}
          </Grid>
        </Grid>
        <Grid container className="row_form_item">
          <Grid container alignItems="center" item xs={6} lg={parentType === 'medium' ? 6 : 4} className="memo_item">
            <div style={{display: 'contents'}}>
              ____-__-__&nbsp;__:__:__&nbsp;&nbsp;<h4>{localStorage.getItem('USER_NAME')}</h4>
            </div>
          </Grid>
          <Grid container alignItems="center" item xs={6} lg={parentType === 'medium' ? 6 : 8} className="memo_item">
            <TextField name="note" margin="dense" multiline fullWidth value={note} onChange={this.handleNewNote} disabled={disabled} />
          </Grid>
        </Grid>
        {memos?.map((key, index) => (
          <Grid container className="row_form_item" key={index}>
            <Grid container alignItems="center" item xs={6} lg={parentType === 'medium' ? 6 : 4} className="memo_item">
              <div style={{display: 'contents'}}>
              {countryCode ? displayDateTime(convertDatetimeUTC(key.created_date, countryCode)) : `${displayDateTime(key.created_date)} (UTC)`}&nbsp;
                <h4>{key.update_by_user}</h4>
              </div>
            </Grid>
            <Grid container alignItems="center" item xs={6} lg={parentType === 'medium' ? 6 : 8} className="memo_item">
              <TextField
                disabled={disabled}
                name="content"
                margin="dense"
                multiline
                fullWidth
                value={key.content}
                onChange={(event) => this.handleChange({index: index, field: event.target.name, newValue: event.target.value})}
              />
            </Grid>
          </Grid>
        ))}
      </Paper>
    );
  }
}

export default withTranslation('translations')(Index);
