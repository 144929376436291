import React, {Component} from 'react';

import {
  Card,
  Container,
  Grid,
  FormControl,
  Select,
  TextField,
  InputLabel,
  Button,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  FormHelperText,
  MenuItem,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {ROUTE, DATA_NULL, SERVICE_TYPE, BOOKING_MOBI_TYPES, ODM_TYPES} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {searchMember, nonMember} from '../../../services/reservationServices';
import {setMessageModal} from '../../../stores/modal/actions';
import {updateBooking, getMemberSubscription, searchBookingUpdate} from '../../../stores/reservation/actions';
import {redirectRouter, onChangeTextField} from '../../../utils/common';
import {modalObj} from '../../../utils/modal.js';
import './style.css';

/**
 * Passenger Information page
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      listSubscription: [],
      member_type: 'MEMBER',
      listMembers: [],

      // search member's condition
      page: 0,
      total_size: 0,
      rowsPerPage: 10,
      no_result: false,
      search_member_id: '',
      search_member_email: '',
      search_member_name: '',
      search_member_mobile: '',

      // choose member
      member_id: '',
      first_name: '',
      last_name: '',
      first_name_furigana: '',
      last_name_furigana: '',
      email: '',
      mobile: '',
      payment_method: '',
      service_type: SERVICE_TYPE[0].id,
      mobi_type: BOOKING_MOBI_TYPES[0].id,
      odm_type: ODM_TYPES[0].id,
      member_code: '',
      isLoading: false,
      isSubmitForm: false,
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    const search_member = this.props.searchBooking.search_member;
    if (!_.isEmpty(search_member)) {
      await this.setState({
        member_type: search_member.member_type,
        mobi_type: this.props.searchBooking.mobi_type || BOOKING_MOBI_TYPES[0].id,
        search_member_id: search_member.search_member_id,
        search_member_email: search_member.search_member_email,
        search_member_name: search_member.search_member_name,
        search_member_mobile: search_member.search_member_mobile,
        member_id: search_member.member_id,
        first_name: search_member.first_name,
        last_name: search_member.last_name,
        first_name_furigana: search_member.first_name_furigana,
        last_name_furigana: search_member.last_name_furigana,
        email: search_member.email,
        mobile: search_member.mobile,
      });
      if (search_member?.listMembers?.length > 0) {
        await this.searchMemberInfo(search_member.page, search_member.rowsPerPage);
      }
      if (search_member.member_type === 'MEMBER') {
        await this.chooseMember(this.state.listMembers?.find((item) => item.id === search_member.member_id));
        await this.setState({
          mobile: search_member.mobile,
        });
      }
    }
  }

  /**
   * handleChangeMemberType
   * @param {event} event
   */
  handleChangeMemberType = (event) => {
    this.setState({
      member_type: event.target.value,
      isSubmitForm: false,
      member_id: '',
      first_name: '',
      last_name: '',
      first_name_furigana: '',
      last_name_furigana: '',
      email: '',
      mobile: '',
      payment_method: '',

      search_member_id: '',
      search_member_email: '',
      search_member_name: '',
      search_member_mobile: '',
    });
  };

  /**
   * handleChangeServiceType
   * @param {event} event
   */
  handleChangeServiceType = (event) => {
    this.setState({
      service_type: event.target.value,
    });
    if (event.target.value !== 'MOBI') {
      this.setState({mobi_type: BOOKING_MOBI_TYPES[0].id});
    }
  };

  /**
   * handleChangeMobiType
   * @param {event} event
   */
  handleChangeMobiType = (event) => {
    this.setState({
      mobi_type: event.target.value,
    });
  };

  /**
   * handleChanegODMType
   * @param {event} event
   */
  handleChanegODMType = (event) => {
    this.setState({
      odm_type: event.target.value,
    });
  };

  /**
   * searchMemberInfo
   * @param {object} page
   * @param {*} rowsPerPage
   */
  async searchMemberInfo(page, rowsPerPage) {
    const {search_member_id, search_member_name, search_member_email, search_member_mobile} = this.state;
    this.setState({isLoading: true});
    const members = await searchMember({
      member_id: search_member_id ? search_member_id.trim() : null,
      member_name: search_member_name ? search_member_name.trim() : null,
      email: search_member_email ? search_member_email.trim() : null,
      mobile: search_member_mobile ? search_member_mobile.trim() : null,
      page: page,
      size: rowsPerPage,
    });
    if (members.status === 200) {
      this.setState({
        listMembers: JSON.parse(JSON.stringify(members.result.content)),
        no_result: members.result.content.length <= 0,
        total_size: members.result.totalSize,
        page: page,
      });
    }
    this.setState({isLoading: false});
  }

  /**
   * handleChangePage
   * @param {String} currentPage
   * @param {*} rowsPerPage
   */
  handleChangePage = (currentPage, rowsPerPage) => {
    this.setState({listMembers: [], page: currentPage, rowsPerPage: rowsPerPage});
    this.searchMemberInfo(currentPage, rowsPerPage);
  };

  /**
   * chooseMember
   * @param {object} item
   */
  async chooseMember(item) {
    this.setState({
      member_id: item.id,
      first_name: item.first_name,
      last_name: item.last_name,
      first_name_furigana: item.first_name_furigana,
      last_name_furigana: item.last_name_furigana,
      mobile: item.mobile ? item.mobile : '',
      email: item.email ? item.email : '',
      member_code: item.member_id || '',
    });
    const data = Object.assign({}, this.props.booking);
    data.member_type = 'MEMBER';
    const payload = {...data};
    this.props.onUpdateBooking(payload);
  }

  /**
   * toPageCreateBooking
   */
  toPageCreateBooking = async () => {
    let path = ROUTE.LAYOUT + ROUTE.RESERVATION_CREATE_INFO;
    const searchBooking = Object.assign({}, this.props.searchBooking);
    const search_member = Object.assign({}, this.state);
    this.setState({
      isSubmitForm: true,
    });

    if (this.validator.allValid()) {
      if (this.state.member_type === 'NON_MEMBER') {
        const member_info = {
          first_name: this.state.first_name || this.state.first_name_furigana,
          last_name: this.state.last_name || this.state.last_name_furigana,
          first_name_furigana: this.state.first_name_furigana,
          last_name_furigana: this.state.last_name_furigana,
          first_name_romaji: this.state.first_name || this.state.first_name_furigana,
          last_name_romaji: this.state.last_name || this.state.last_name_furigana,
          email: this.state.email ? this.state.email.trim() : '',
          mobile: this.state.mobile,
          payment_method: '',
          member_code: '',
        };
        this.setState({isLoading: true});
        const create = await nonMember(member_info);
        if (create.status === 200) {
          // update member info
          this.setState({isLoading: false});
          member_info.member_type = 'NON MEMBER';
          member_info.member_id = create.result;
          const data = Object.assign({}, this.props.booking);
          const payload = {...data, ...member_info};
          this.props.onUpdateBooking(payload);
          // update search booking reservation
          searchBooking.search_member = search_member;
          searchBooking.search_member.member_id = create.result;
          searchBooking.mobi_type = this.state.mobi_type;
          searchBooking.service_type = this.state.service_type;
          this.props.searchBookingUpdate(searchBooking);
          if (this.state.mobi_type === 'SHUTTLE_BUS_JIT') {
            path = ROUTE.LAYOUT + ROUTE.RESERVATION_CREATE_INFO_JIT;
          }
          redirectRouter(this.props, path);
        } else {
          this.setState({isLoading: false});
          this.props.setMessageModal(modalObj(true, create.message_code === 'error.email.exist' ? this.props.t('error.email.exist') : this.props.t('booking_failed')));
        }
      } else {
        const data = Object.assign({}, this.props.booking);
        const member_info = {
          member_id: this.state.member_id,
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          first_name_furigana: this.state.first_name_furigana,
          last_name_furigana: this.state.last_name_furigana,
          email: this.state.email ? this.state.email.trim() : '',
          mobile: this.state.mobile,
          payment_method: '',
          member_code: this.state.member_code,
        };
        const payload = {...data, ...member_info};
        this.props.onUpdateBooking(payload);
        // update search booking reservation
        searchBooking.search_member = search_member;
        searchBooking.mobi_type = this.state.mobi_type;
        searchBooking.service_type = this.state.service_type;
        this.props.searchBookingUpdate(searchBooking);
        if (this.state.mobi_type === 'SHUTTLE_BUS_JIT') {
          path = ROUTE.LAYOUT + ROUTE.RESERVATION_CREATE_INFO_JIT;
        }
        redirectRouter(this.props, path);
      }
    }
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t} = this.props;

    return (
    <LoadingOverlay active={this.state.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('reservationManagement.create_reservation')}</h3>
            </Grid>
            <Grid container alignItems="center" justifyContent="flex-end" item xs={6}>
              <Button
                color="primary"
                variant="contained"
                className="button_margin button_color"
                endIcon={<ArrowBackIcon />}
                onClick={() => this.props.history.push(ROUTE.LAYOUT + ROUTE.RESERVATION_MANAGEMENT_SEARCH)}
              >
                {t('common.btnReturn')}
              </Button>
            </Grid>
          </Grid>
        </Container>
        {/* Step 1 */}
        <Container maxWidth="xl">
          <br />
          <Card raised>
            <Container maxWidth="xl">
              <Grid container alignItems="flex-start">
                <Grid item xs={12}>
                  <h1 className="product_entry_table_header_color font_color_white font_size_mid title">{t('reservationManagement.customer_info')}</h1>
                  <Grid container alignItems="flex-start">
                    <Grid item xs={4}>
                      <Grid container alignItems="flex-start">
                        {/* Service Type */}
                        <Grid item xs={12} container spacing={1}>
                          <Grid item xs={12} md={6}>
                            <FormControl variant="outlined" className="width_100" margin="dense">
                              <InputLabel htmlFor="service_type">{t('reservationManagement.service_type')}</InputLabel>
                              <Select
                                margin="dense"
                                label={t('reservationManagement.service_type')}
                                inputProps={{
                                  name: 'service_type',
                                }}
                                value={this.state.service_type}
                                onChange={this.handleChangeServiceType}
                              >
                                {SERVICE_TYPE.map(({id, i18n}) => (
                                  <MenuItem value={id} key={id}>
                                    {t(`${i18n}`)}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>

                        {/* Mobi Type + On-demand type*/}
                        {this.state.service_type === SERVICE_TYPE[0].id && (
                          <Grid item xs={12} container spacing={1}>
                            <Grid item xs={12} md={6}>
                              <FormControl variant="outlined" className="width_100" margin="dense">
                                <InputLabel htmlFor="mobi_type">{t('reservationManagement.mobi_type')}</InputLabel>
                                <Select
                                  margin="dense"
                                  label={t('reservationManagement.mobi_type')}
                                  inputProps={{
                                    name: 'mobi_type',
                                  }}
                                  value={this.state.mobi_type}
                                  onChange={this.handleChangeMobiType}
                                >
                                  {BOOKING_MOBI_TYPES.map((type) => (
                                    <MenuItem key={type.id} value={type.id}>{t(`${type.i18n}`)}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            {/* {this.state.mobi_type === BOOKING_MOBI_TYPES[0].id && (
                              <Grid item xs={12} md={6}>
                                <FormControl variant="outlined" className="width_100" margin="dense">
                                  <InputLabel htmlFor="odm_type">{t('On-demand type')}</InputLabel>
                                  <Select
                                    margin="dense"
                                    label={t('On-demand type')}
                                    inputProps={{
                                      name: 'odm_type',
                                    }}
                                    value={this.state.odm_type}
                                    onChange={this.handleChanegODMType}
                                  >
                                    {ODM_TYPES
                                      .filter(({enabled}) => enabled)
                                      .map(({id, i18n}) => (
                                      <MenuItem key={id} value={id}>{t(`${i18n}`)}</MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Grid>
                            )} */}
                          </Grid>
                        )}

                        {/* Type */}
                        <Grid item xs={12} container spacing={1}>
                          <Grid item xs={12} md={6}>
                            <FormControl variant="outlined" className="width_100" margin="dense">
                              <InputLabel htmlFor="member_type">{t('reservationManagement.member_type')}</InputLabel>
                              <Select
                                margin="dense"
                                native
                                label={t('reservationManagement.member_type')}
                                inputProps={{
                                  name: 'member_type',
                                  id: 'member_type',
                                }}
                                value={this.state.member_type}
                                onChange={this.handleChangeMemberType}
                              >
                                <option value="MEMBER">Member</option>
                                <option value="NON_MEMBER">Non-member</option>
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>

                        {/* Name */}
                        <Grid item xs={12} container spacing={1}>
                          <Grid item xs={6}>
                            <FormControl className="width_100">
                              <TextField
                                className="width_100"
                                value={this.state.last_name}
                                margin="dense"
                                label={t('sub.last_name')}
                                onChange={(event) => onChangeTextField(this, event)}
                                variant="outlined"
                                disabled={this.state.member_type === 'MEMBER'}
                                InputLabelProps={{shrink: true}}
                                name="last_name"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl className="width_100">
                              <TextField
                                className="width_100"
                                value={this.state.first_name}
                                margin="dense"
                                label={t('sub.first_name')}
                                onChange={(event) => onChangeTextField(this, event)}
                                variant="outlined"
                                disabled={this.state.member_type === 'MEMBER'}
                                InputLabelProps={{shrink: true}}
                                name="first_name"
                              />
                            </FormControl>
                          </Grid>
                        </Grid>

                        {/* Furigana Name */}
                        <Grid item xs={12} container spacing={1}>
                          <Grid item xs={6}>
                            <FormControl className="width_100">
                              <TextField
                                className="width_100"
                                value={this.state.last_name_furigana}
                                margin="dense"
                                label={
                                  <span>
                                    {t('sub.lastname_furigana')}
                                    <span className="font_color_red">＊</span>
                                  </span>
                                }
                                variant="outlined"
                                disabled={this.state.member_type === 'MEMBER'}
                                InputLabelProps={{shrink: true}}
                                onChange={(event) => onChangeTextField(this, event)}
                                name="last_name_furigana"
                              />
                              {this.validator.message('last_name_furigana', this.state.last_name_furigana, 'required')}
                              {this.state.isSubmitForm && !this.validator.check(this.state.last_name_furigana, 'required') && (
                                <FormHelperText error id='last_name_furigana'>
                                  {t('placeholder.required', {field: t('sub.lastname_furigana')})}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl className="width_100">
                              <TextField
                                className="width_100"
                                value={this.state.first_name_furigana}
                                margin="dense"
                                label={
                                  <span>
                                    {t('sub.firstname_furigana')}
                                    <span className="font_color_red">＊</span>
                                  </span>
                                }
                                variant="outlined"
                                disabled={this.state.member_type === 'MEMBER'}
                                InputLabelProps={{shrink: true}}
                                onChange={(event) => onChangeTextField(this, event)}
                                name="first_name_furigana"
                              />
                              {this.validator.message('first_name_furigana', this.state.first_name_furigana, 'required')}
                              {this.state.isSubmitForm && !this.validator.check(this.state.first_name_furigana, 'required') && (
                                <FormHelperText error id='first_name_furigana'>
                                  {t('placeholder.required', {field: t('sub.firstname_furigana')})}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>

                        {/* Email */}
                        <Grid item xs={12} container spacing={1}>
                          <Grid item xs={12} md={6}>
                            <FormControl className="width_100">
                              <TextField
                                className="width_100"
                                value={this.state.email}
                                margin="dense"
                                label={
                                  <span>
                                    {t('common.email')}
                                    {this.state.mobi_type === 'SHUTTLE_BUS_JIT' ? <span className="font_color_red">＊</span> : ''}
                                  </span>
                                }
                                variant="outlined"
                                disabled={this.state.member_type === 'MEMBER'}
                                InputLabelProps={{shrink: true}}
                                onChange={(event) => onChangeTextField(this, event)}
                                name="email"
                              />
                              {this.validator.message('email', this.state.email.trim(), this.state.mobi_type === 'SHUTTLE_BUS_JIT' ? 'required|email' : 'email')}
                              {this.state.isSubmitForm && !this.validator.check(this.state.email.trim(), 'email') && (
                                <FormHelperText id="email" error>
                                  {t('validation.email', {field: t('common.email')})}
                                </FormHelperText>
                              )}
                              {this.state.isSubmitForm && this.state.mobi_type === 'SHUTTLE_BUS_JIT' && !this.validator.check(this.state.email.trim(), 'required') && (
                                <FormHelperText id="email" error>
                                  {t('validation.required', {
                                    field: t('common.email'),
                                  })}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>

                        {/* Mobile phone */}
                        <Grid item xs={12} container spacing={1}>
                          <Grid item xs={12} md={6}>
                            <FormControl className="width_100">
                              <TextField
                                className="width_100"
                                value={this.state.mobile}
                                margin="dense"
                                label={
                                  <span>
                                    {t('common.phoneNumber')}
                                    <span className="font_color_red">＊</span>
                                  </span>
                                }
                                variant="outlined"
                                InputLabelProps={{shrink: true}}
                                onChange={(event) => onChangeTextField(this, event)}
                                name="mobile"
                              />
                              {this.validator.message('tel', this.state.mobile, 'required|phone')}
                              {this.state.isSubmitForm &&
                                !this.validator.check(this.state.mobile, 'required|phone') && (
                                  <p className="error">{t('validation.required', {field: t('common.phoneNumber')})}</p>
                                ) && (
                                  <FormHelperText id="tel" error>
                                    {t('validation.phone')}
                                  </FormHelperText>
                                )}
                            </FormControl>
                          </Grid>
                        </Grid>
                        <br />
                        <Grid item xs={12} className="padding-item-12">
                          <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.toPageCreateBooking()}>
                            {t('common.btnConfirm')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    {this.state.member_type === 'MEMBER' && (
                      <Grid item xs={8} className="padding-left-24" container spacing={1} alignItems="flex-start">
                          <Grid item xs={2}>
                            <TextField
                              className="width_100"
                              name="search_member_id"
                              margin="dense"
                              label={t('memberManagement.memberId')}
                              variant="outlined"
                              value={this.state.search_member_id}
                              onChange={(event) => onChangeTextField(this, event)}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <TextField
                              className="width_100"
                              name="search_member_email"
                              margin="dense"
                              label={
                                this.state.service_type === 'SHUTTLE_BUS_JIT' ? (
                                  <span>
                                    {t('common.email')}
                                    <span className="font_color_red">＊</span>
                                  </span>
                                ) : (
                                  t('common.email')
                                )
                              }
                              variant="outlined"
                              value={this.state.search_member_email}
                              onChange={(event) => onChangeTextField(this, event)}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <TextField
                              className="width_100"
                              name="search_member_name"
                              margin="dense"
                              label={t('reservationManagement.name_info')}
                              variant="outlined"
                              value={this.state.search_member_name}
                              onChange={(event) => onChangeTextField(this, event)}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <TextField
                              className="width_100"
                              name="search_member_mobile"
                              margin="dense"
                              label={t('common.phoneNumber')}
                              variant="outlined"
                              value={this.state.search_member_mobile}
                              onChange={(event) => onChangeTextField(this, event)}
                            />
                          </Grid>
                          <Grid item xs={2}>
                            <Button
                              color="primary"
                              variant="contained"
                              className="button_margin button_color"
                              endIcon={<SearchIcon />}
                              onClick={() => this.searchMemberInfo(0, this.state.rowsPerPage)}
                            >
                              {t('common.btnSearch')}
                            </Button>
                        </Grid>
                        {this.state.listMembers.length > 0 && (
                          <Grid container alignItems="flex-start">
                            <div>
                              <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                                <TableHead>
                                  <TableRow>
                                    <TableCell className="width_75p">{t('memberManagement.memberId')}</TableCell>
                                    <TableCell className="width_100p">{t('common.email')}</TableCell>
                                    <TableCell className="width_150p">{t('common.name')}</TableCell>
                                    <TableCell className="width_100p">{t('common.phoneNumber')}</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {this.state.listMembers.map((item, index) => (
                                    <TableRow className="cursor_pointer" key={index} hover>
                                      <TableCell onClick={() => this.chooseMember(item)}>{item.member_id}</TableCell>
                                      <TableCell onClick={() => this.chooseMember(item)}>{item.email ? item.email : DATA_NULL}</TableCell>
                                      <TableCell onClick={() => this.chooseMember(item)}>{item.last_name + ' ' + item.first_name}</TableCell>
                                      <TableCell onClick={() => this.chooseMember(item)}>{item.mobile ? item.mobile : DATA_NULL}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </div>
                          </Grid>
                        )}
                        {this.state.listMembers.length > 0 && (
                          <CustomPagination
                            isDisabledRowPerPage={false}
                            onChange={this.handleChangePage}
                            rows={this.state.total_size}
                            rowsPerPage={this.state.rowsPerPage}
                            currentPage={this.state.page}
                          />
                        )}
                        {this.state.no_result && <h5 className="text_grey">{t('reservationManagement.no_result')}</h5>}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Card>
          <br />
        </Container>
      </Card>
    </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    booking: state.reservation.bookingInfo,
    listSubscription: state.reservation.listSubscription,
    searchBooking: state.reservation.searchBooking,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onUpdateBooking: (bookingData) => {
      dispatch(updateBooking(bookingData));
    },
    getMemberSubscription: (user_id, geofence_id) => dispatch(getMemberSubscription(user_id, geofence_id)),
    searchBookingUpdate: (data) => dispatch(searchBookingUpdate(data)),
    setMessageModal: (message) => dispatch(setMessageModal(message)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
