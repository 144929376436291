import React, {Component} from 'react';

import {Paper, Container, Grid, Button, Table, TableHead, TableBody, TableRow, TableCell} from '@material-ui/core';
import {withTranslation} from 'react-i18next';

import './style.css';
import {DATA_NULL, DISCOUNT_TYPE, ODM_BOOKING_TIME_TYPES, SPECIAL_PERSON_TYPE} from '../../../../../common/constant';
import {customDisplayCurrency} from '../../../../../utils/common';

/**
 * Dialog Subscription detail
 */
class Index extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    const {odm_booking_time_type} = props.detailCost;
    const isBookingAdvance = odm_booking_time_type === ODM_BOOKING_TIME_TYPES[1].id;
    this.state = {
      openModal: false,
      isBookingAdvance,
    };
    this.handleButtonConFirm = this.handleButtonConFirm.bind(this);
  }

  /**
   * handleButtonConFirm
   */
  handleButtonConFirm() {
    this.props.onClickConfirm();
  }

  /**
   * Calculate shop partner discount amount
   * @param {Number} price
   * @param {Number} percent
   * @param {String} currencyCode
   * @return {Number}
   */
  calculateShopPartnerPercentDiscount = (price, percent, currencyCode) => {
    if (!currencyCode) return 0;
    let discount = (price * percent) / 100;
    if (currencyCode === 'SGD' || currencyCode === 'MYR') {
      if (discount < 0) {
        discount = (Math.round(-discount * 100) / 100).toFixed(2);
        return customDisplayCurrency(-discount, currencyCode);
      }
      return customDisplayCurrency(discount, currencyCode);
    } else {
      discount < 0 ? discount = -Math.round(-discount) : Math.round(discount);
      return customDisplayCurrency(discount, currencyCode);
    }
  };

  /**
   * render component
   * @return {HTML}
   */
  render() {
    const {t, detailCost} = this.props;
    const currencyCode = detailCost?.currency_code;
    const isBookingAdvanceWithoutPartner = detailCost?.isDetailBooking ?
      detailCost?.isBookingAdvanceWithoutPartner :
      this.state.isBookingAdvance && detailCost?.advance_without_partner;
    // number of discount
    const onetimeDiscountAdults = detailCost?.one_time_discount?.adultNumber || 0;
    const onetimeDiscountChildren = detailCost?.one_time_discount?.childNumber || 0;
    // number of usage
    const onetimeAdults = detailCost?.no_adults - onetimeDiscountAdults || 0;
    const onetimeChildren = detailCost?.no_children - onetimeDiscountChildren || 0;
    // price of usage
    const onetimeAdultPrice = detailCost?.origin_adult_price || detailCost?.oneTimeSurcharge?.origin_adult_price || 0;
    const onetimeChildPrice = detailCost?.origin_child_price || detailCost?.oneTimeSurcharge?.origin_child_price || 0;
    // price of discount
    const onetimeDiscountAdultPrice = detailCost?.one_time_discount?.adultAmount || 0;
    const onetimeDiscountChildPrice = detailCost?.one_time_discount?.childAmount || 0;
    // shop partner discount
    const shopPartnerDiscountType = detailCost?.partner_coupon?.discount_type;
    const shopPartnerDiscount = detailCost?.partner_coupon?.discount || 0;
    // caculate price of special person
    const used_special_price = detailCost?.used_special_price || [];
    // price advance
    const advanceBookingAdultPrice = detailCost?.advance_booking_prices?.[0]?.additional_adult_price || 0;
    const advanceBookingChildPrice = detailCost?.advance_booking_prices?.[0]?.additional_child_price || 0;
    // surcharge price
    const displayColumnSurcharge = detailCost?.oneTimeSurcharge?.frame_times?.length > 0;
    const adultSurcharge = detailCost?.oneTimeSurcharge?.frame_adult_price;
    const childSurcharge = detailCost?.oneTimeSurcharge?.frame_child_price;

    let total_other_special_price = 0;
    let total_no_other_special_price = 0;
    if (used_special_price?.length > 0) {
      used_special_price.forEach((special_price) => {
        if (special_price?.type === SPECIAL_PERSON_TYPE[2].value) {
          total_other_special_price += special_price?.price * special_price?.number;
        } else {
          let amount = 0;
          // calculate amount and discount by percent for every special person
          if (isBookingAdvanceWithoutPartner) {
            if (special_price.type === SPECIAL_PERSON_TYPE[0].value) {
              amount = (special_price?.origin_price + advanceBookingAdultPrice + (displayColumnSurcharge ? adultSurcharge : 0)) * special_price?.number;
            } else {
              amount = (special_price?.origin_price + advanceBookingChildPrice + (displayColumnSurcharge ? childSurcharge : 0)) * special_price?.number;
            }
          } else {
            if (special_price.type === SPECIAL_PERSON_TYPE[0].value) {
              amount = (special_price?.origin_price + (displayColumnSurcharge ? adultSurcharge : 0)) * special_price?.number;
            } else {
              amount = (special_price?.origin_price + (displayColumnSurcharge ? childSurcharge : 0)) * special_price?.number;
            }
          }
          total_no_other_special_price += amount;
        }
      });
    }

    let onetimeAdultPriceDiscount;
    let onetimeChildPriceDiscount;
    let onetimeDiscountAdultPriceDiscount;
    let onetimeDiscountChildPriceDiscount;

    if (shopPartnerDiscountType === DISCOUNT_TYPE[0].id) {
      onetimeAdultPriceDiscount = this.props.calculateDiscount(onetimeAdultPrice, shopPartnerDiscount, currencyCode);
      onetimeChildPriceDiscount = this.props.calculateDiscount(onetimeChildPrice, shopPartnerDiscount, currencyCode);
      onetimeDiscountAdultPriceDiscount = this.props.calculateDiscount(onetimeDiscountAdultPrice, shopPartnerDiscount, currencyCode);
      onetimeDiscountChildPriceDiscount = this.props.calculateDiscount(onetimeDiscountChildPrice, shopPartnerDiscount, currencyCode);
    } else if (shopPartnerDiscountType === DISCOUNT_TYPE[1].id) {
      onetimeAdultPriceDiscount = onetimeAdultPrice > shopPartnerDiscount ? shopPartnerDiscount : onetimeAdultPrice;
      onetimeChildPriceDiscount = onetimeChildPrice > shopPartnerDiscount ? shopPartnerDiscount : onetimeChildPrice;
      onetimeDiscountAdultPriceDiscount = onetimeDiscountAdultPrice > shopPartnerDiscount ? shopPartnerDiscount : onetimeDiscountAdultPrice;
      onetimeDiscountChildPriceDiscount = onetimeDiscountChildPrice > shopPartnerDiscount ? shopPartnerDiscount : onetimeDiscountChildPrice;
    } else {
      onetimeAdultPriceDiscount = 0;
      onetimeChildPriceDiscount = 0;
      onetimeDiscountAdultPriceDiscount = 0;
      onetimeDiscountChildPriceDiscount = 0;
    }

    const onetimeAdultsAmount = ((isBookingAdvanceWithoutPartner ? advanceBookingAdultPrice : 0) + onetimeAdultPrice) * (onetimeAdults > 0 ? onetimeAdults : 0);
    const onetimeChildrenAmount = ((isBookingAdvanceWithoutPartner ? advanceBookingChildPrice : 0) + onetimeChildPrice) * (onetimeChildren > 0 ? onetimeChildren : 0);

    const onetimeDiscountAdultsAmount = onetimeDiscountAdultPrice * onetimeDiscountAdults;
    const onetimeDiscountChildrenAmount = onetimeDiscountChildPrice * onetimeDiscountChildren;

    const onetimeAdultsAmountDiscount = onetimeAdultPriceDiscount * onetimeAdults;
    const onetimeChildrenAmountDiscount = onetimeChildPriceDiscount * onetimeChildren;
    const onetimeDiscountAdultsAmountDiscount = onetimeDiscountAdultPriceDiscount * onetimeDiscountAdults;
    const onetimeDiscountChildrenAmountDiscount = onetimeDiscountChildPriceDiscount * onetimeDiscountChildren;

    const total = onetimeAdultsAmount + onetimeChildrenAmount + onetimeDiscountAdultsAmount + onetimeDiscountChildrenAmount;
    const totalDiscount = onetimeAdultsAmountDiscount + onetimeChildrenAmountDiscount + onetimeDiscountAdultsAmountDiscount + onetimeDiscountChildrenAmountDiscount;

    const dataTicket = {adult: 0, child: 0};
    for (const sub of detailCost.ticket_usage) {
      if (sub.subscription?.ticket_data?.ticket_type === 'ADULT' || sub?.ticket_type === 'ADULT') dataTicket.adult = dataTicket.adult + sub.number_of_use;
      else if (sub.subscription?.ticket_data?.ticket_type === 'CHILDREN' || sub?.ticket_type === 'CHILDREN') dataTicket.child = dataTicket.child + sub.number_of_use;
    }
    // calculator range surcharge
    let adultSurchargeDiscount;
    let childSurchargeDiscount;
    if (shopPartnerDiscountType === DISCOUNT_TYPE[0].id) {
      adultSurchargeDiscount = this.props.calculateDiscount(adultSurcharge, shopPartnerDiscount, currencyCode);
      childSurchargeDiscount = this.props.calculateDiscount(childSurcharge, shopPartnerDiscount, currencyCode);
    } else if (shopPartnerDiscountType === DISCOUNT_TYPE[1].id) {
      adultSurchargeDiscount =
        shopPartnerDiscount >= onetimeAdultPrice ? (shopPartnerDiscount - onetimeAdultPrice >= adultSurcharge ? adultSurcharge : shopPartnerDiscount - onetimeAdultPrice) : 0;
      childSurchargeDiscount =
        shopPartnerDiscount >= onetimeChildPrice ? (shopPartnerDiscount - onetimeChildPrice >= childSurcharge ? childSurcharge : shopPartnerDiscount - onetimeChildPrice) : 0;
    } else {
      adultSurchargeDiscount = 0;
      childSurchargeDiscount = 0;
    }
    const totalSurcharge =
      (adultSurcharge > 0 ? adultSurcharge * (detailCost.subscribed_member + dataTicket.adult) : 0) +
      (childSurcharge > 0 ? childSurcharge * dataTicket.child : 0) +
      (adultSurcharge * onetimeAdults + childSurcharge * onetimeChildren);
    const language = localStorage?.getItem('i18nextLng') || 'ja';
    return (
      <Paper className="field_min_size_600 subscription-detail">
        <Container maxWidth="xl">
          <br />
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={6} className="font_bold font_size_big fee_breakdown_header">
                  {t('reservationManagement.fee_breakdown')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="width_400p fee_breakdown_table_head">{t('common.description')}</TableCell>
                <TableCell align="right" className="width_200p fee_breakdown_table_head">
                  {t('reservationManagement.unitCost')}
                </TableCell>
                {displayColumnSurcharge && (
                  <TableCell align="right" className="width_400p fee_breakdown_table_head white_space_nowrap">
                    {t('oneTimePrice.time_range_surcharge_discount')}
                  </TableCell>
                )}
                {isBookingAdvanceWithoutPartner && (
                  <TableCell align="right" className="width_250p fee_breakdown_table_head white_space_nowrap">
                    {t('reservationManagement.advance.booking_cost')}
                  </TableCell>
                )}
                <TableCell align="right" className="width_150p fee_breakdown_table_head">
                  {t('reservationManagement.number')}
                </TableCell>
                <TableCell align="right" className="width_200p fee_breakdown_table_head">
                  {t('reservationManagement.peopleTypeAmount')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Unlimited rides subscription */}
              {detailCost.subscribed_member > 0 && (
                <TableRow className="fee_breakdown_table_row">
                  <TableCell className="fee_breakdown_table_description">{t('reservationManagement.unlimitedRides')}</TableCell>
                  <TableCell align="right">{customDisplayCurrency(0, currencyCode)}</TableCell>
                  {displayColumnSurcharge && <TableCell align="right">{adultSurcharge > 0 ? customDisplayCurrency(adultSurcharge, currencyCode) : DATA_NULL}</TableCell>}
                  {isBookingAdvanceWithoutPartner && <TableCell align="right">{DATA_NULL}</TableCell>}
                  <TableCell align="right">{detailCost.subscribed_member}</TableCell>
                  <TableCell align="right">{adultSurcharge > 0 ? customDisplayCurrency(detailCost.subscribed_member * adultSurcharge, currencyCode) : 0}</TableCell>
                </TableRow>
              )}
              {/* Rides ticket subscription (adult) */}
              {dataTicket.adult > 0 && (
                <TableRow className="fee_breakdown_table_row">
                  <TableCell className="fee_breakdown_table_description">{t('ticket.adult_ticket')}</TableCell>
                  <TableCell align="right">{customDisplayCurrency(0, currencyCode)}</TableCell>
                  {displayColumnSurcharge && <TableCell align="right">{adultSurcharge > 0 ? customDisplayCurrency(adultSurcharge, currencyCode) : DATA_NULL}</TableCell>}
                  {isBookingAdvanceWithoutPartner && <TableCell align="right">{DATA_NULL}</TableCell>}
                  <TableCell align="right">{dataTicket.adult}</TableCell>
                  <TableCell align="right">{adultSurcharge > 0 ? customDisplayCurrency(dataTicket.adult * adultSurcharge, currencyCode) : 0}</TableCell>
                </TableRow>
              )}
              {/* Rides ticket subscription (child) */}
              {dataTicket.child > 0 && (
                <TableRow className="fee_breakdown_table_row">
                  <TableCell className="fee_breakdown_table_description">{t('ticket.child_ticket')}</TableCell>
                  <TableCell align="right">{customDisplayCurrency(0, currencyCode)}</TableCell>
                  {displayColumnSurcharge && <TableCell align="right">{childSurcharge > 0 ? customDisplayCurrency(childSurcharge, currencyCode) : DATA_NULL}</TableCell>}
                  {isBookingAdvanceWithoutPartner && <TableCell align="right">{DATA_NULL}</TableCell>}
                  <TableCell align="right">{dataTicket.child}</TableCell>
                  <TableCell align="right">{childSurcharge > 0 ? customDisplayCurrency(dataTicket.child * childSurcharge, currencyCode) : 0}</TableCell>
                </TableRow>
              )}
              {/* One-time adult */}
              {onetimeAdults > 0 && (
                <TableRow className="fee_breakdown_table_row">
                  <TableCell className="fee_breakdown_table_description">{t('ticket.adult')}</TableCell>
                  <TableCell align="right">{customDisplayCurrency(onetimeAdultPrice, currencyCode)}</TableCell>
                  {displayColumnSurcharge && <TableCell align="right">{adultSurcharge ? customDisplayCurrency(adultSurcharge, currencyCode) : DATA_NULL}</TableCell>}
                  {isBookingAdvanceWithoutPartner && (
                    <TableCell align="right">{advanceBookingAdultPrice ? customDisplayCurrency(advanceBookingAdultPrice, currencyCode) : DATA_NULL}</TableCell>
                  )}
                  <TableCell align="right">{onetimeAdults}</TableCell>
                  <TableCell align="right">{customDisplayCurrency(onetimeAdultsAmount + adultSurcharge * onetimeAdults, currencyCode)}</TableCell>
                </TableRow>
              )}
              {/* One-time child */}
              {onetimeChildren > 0 && (
                <TableRow className="fee_breakdown_table_row">
                  <TableCell className="fee_breakdown_table_description">{t('ticket.child')}</TableCell>
                  <TableCell align="right">{customDisplayCurrency(onetimeChildPrice, currencyCode)}</TableCell>
                  {displayColumnSurcharge && <TableCell align="right">{childSurcharge ? customDisplayCurrency(childSurcharge, currencyCode) : DATA_NULL}</TableCell>}
                  {isBookingAdvanceWithoutPartner && (
                    <TableCell align="right">{advanceBookingChildPrice ? customDisplayCurrency(advanceBookingChildPrice, currencyCode) : DATA_NULL}</TableCell>
                  )}
                  <TableCell align="right">{onetimeChildren}</TableCell>
                  <TableCell align="right">{customDisplayCurrency(onetimeChildrenAmount + childSurcharge * onetimeChildren, currencyCode)}</TableCell>
                </TableRow>
              )}
              {used_special_price?.length > 0 &&
                used_special_price.map((special_price) => {
                  const surcharge = special_price.type === SPECIAL_PERSON_TYPE[2].value ? 0 : special_price.type === SPECIAL_PERSON_TYPE[0].value ? adultSurcharge : childSurcharge;
                  let amount = 0;
                  if (isBookingAdvanceWithoutPartner) {
                    if (special_price.type === SPECIAL_PERSON_TYPE[2].value) {
                      amount = special_price?.price * special_price?.number;
                    } else if (special_price.type === SPECIAL_PERSON_TYPE[0].value) {
                      amount = (special_price?.origin_price + advanceBookingAdultPrice + adultSurcharge) * special_price?.number;
                    } else {
                      amount = (special_price?.origin_price + advanceBookingChildPrice + childSurcharge) * special_price?.number;
                    }
                  } else {
                    if (special_price.type === SPECIAL_PERSON_TYPE[2].value) {
                      amount = special_price?.price * special_price?.number;
                    } else if (special_price.type === SPECIAL_PERSON_TYPE[0].value) {
                      amount = (special_price?.origin_price + adultSurcharge) * special_price?.number;
                    } else {
                      amount = (special_price?.origin_price + childSurcharge) * special_price?.number;
                    }
                  }
                  return (
                    <TableRow className="fee_breakdown_table_row">
                      <TableCell className="fee_breakdown_table_description">{special_price?.name[language]}</TableCell>
                      <TableCell align="right">{customDisplayCurrency(special_price?.origin_price, currencyCode)}</TableCell>
                      {displayColumnSurcharge && <TableCell align="right">{surcharge ? customDisplayCurrency(surcharge, currencyCode) : DATA_NULL}</TableCell>}
                      {isBookingAdvanceWithoutPartner && (
                        <TableCell align="right">
                          {special_price.type === SPECIAL_PERSON_TYPE[2].value ?
                            DATA_NULL :
                            special_price.type === SPECIAL_PERSON_TYPE[0].value ?
                            advanceBookingAdultPrice ?
                              customDisplayCurrency(advanceBookingAdultPrice, currencyCode) :
                              DATA_NULL :
                            advanceBookingChildPrice ?
                            customDisplayCurrency(advanceBookingChildPrice, currencyCode) :
                            DATA_NULL}
                        </TableCell>
                      )}
                      <TableCell align="right">{special_price?.number}</TableCell>
                      <TableCell align="right">{customDisplayCurrency(amount, currencyCode)}</TableCell>
                    </TableRow>
                  );
                })}
              {/* One-time discount adult */}
              {onetimeDiscountAdults > 0 && (
                <TableRow className="fee_breakdown_table_row">
                  <TableCell className="fee_breakdown_table_description">{t('reservationManagement.adult_one_time_discount')}</TableCell>
                  <TableCell align="right">{customDisplayCurrency(onetimeDiscountAdultPrice, currencyCode)}</TableCell>
                  {displayColumnSurcharge && <TableCell align="right">{DATA_NULL}</TableCell>}
                  {isBookingAdvanceWithoutPartner && <TableCell align="right">{DATA_NULL}</TableCell>}
                  <TableCell align="right">{onetimeDiscountAdults}</TableCell>
                  <TableCell align="right">{customDisplayCurrency(onetimeDiscountAdultsAmount, currencyCode)}</TableCell>
                </TableRow>
              )}
              {/* One-time discount child */}
              {onetimeDiscountChildren > 0 && (
                <TableRow className="fee_breakdown_table_row">
                  <TableCell className="fee_breakdown_table_description">{t('reservationManagement.child_one_time_discount')}</TableCell>
                  <TableCell align="right">{customDisplayCurrency(onetimeDiscountChildPrice, currencyCode)}</TableCell>
                  {displayColumnSurcharge && <TableCell align="right">{DATA_NULL}</TableCell>}
                  {isBookingAdvanceWithoutPartner && <TableCell align="right">{DATA_NULL}</TableCell>}
                  <TableCell align="right">{onetimeDiscountChildren}</TableCell>
                  <TableCell align="right">{customDisplayCurrency(onetimeDiscountChildrenAmount, currencyCode)}</TableCell>
                </TableRow>
              )}
              {/* Infant Price */}
              {detailCost.infants > 0 && (
                <TableRow className="fee_breakdown_table_row">
                  <TableCell className="fee_breakdown_table_description">{t('reservationManagement.infant')}</TableCell>
                  <TableCell align="right">{customDisplayCurrency(0, currencyCode)}</TableCell>
                  {displayColumnSurcharge && <TableCell align="right">{DATA_NULL}</TableCell>}
                  {isBookingAdvanceWithoutPartner && <TableCell align="right">{DATA_NULL}</TableCell>}
                  <TableCell align="right">{detailCost.infants}</TableCell>
                  <TableCell align="right">{customDisplayCurrency(0, currencyCode)}</TableCell>
                </TableRow>
              )}
              {/* Shop partner discount */}
              {detailCost?.partner_coupon && (
                <>
                  <TableRow>
                    <TableCell colSpan={6} className="fee_breakdown_table_head line_hight_header">
                      {t('reservationManagement.partnerDiscount')}
                    </TableCell>
                  </TableRow>
                  {/* Partner discount: One-time adult */}
                  {onetimeAdults > 0 && (
                    <TableRow className="fee_breakdown_table_row">
                      <TableCell className="width_400p fee_breakdown_table_description">
                        {t('ticket.adult')} {shopPartnerDiscountType === DISCOUNT_TYPE[0].id && `(-${shopPartnerDiscount}%)`}
                      </TableCell>
                      <TableCell align="right" className="width_200p">
                        {onetimeAdultPriceDiscount !== 0}
                        {customDisplayCurrency(-onetimeAdultPriceDiscount, currencyCode)}
                      </TableCell>
                      {displayColumnSurcharge && (
                        <TableCell align="right" className="width_200p">
                          {adultSurchargeDiscount !== 0 ?
                            `${
                                adultSurchargeDiscount < 0 ?
                                  customDisplayCurrency(adultSurchargeDiscount, currencyCode) :
                                  customDisplayCurrency(-adultSurchargeDiscount, currencyCode)
                              }` :
                            DATA_NULL}
                        </TableCell>
                      )}
                      {isBookingAdvanceWithoutPartner && (
                        <TableCell align="right" className="width_250p">
                          {DATA_NULL}
                        </TableCell>
                      )}
                      <TableCell align="right" className="width_150p">
                        {onetimeAdults}
                      </TableCell>
                      <TableCell align="right" className="width_200p">
                        {onetimeAdultsAmountDiscount !== 0}
                        {customDisplayCurrency(-(onetimeAdultsAmountDiscount + onetimeAdults * adultSurchargeDiscount), currencyCode)}
                      </TableCell>
                    </TableRow>
                  )}
                  {/* Partner discount: One-time child */}
                  {onetimeChildren > 0 && (
                    <TableRow className="fee_breakdown_table_row">
                      <TableCell className="width_400p fee_breakdown_table_description">
                        {t('ticket.child')} {shopPartnerDiscountType === DISCOUNT_TYPE[0].id && `(-${shopPartnerDiscount}%)`}
                      </TableCell>
                      <TableCell align="right" className="width_200p">
                        {onetimeChildPriceDiscount !== 0}
                        {customDisplayCurrency(-onetimeChildPriceDiscount, currencyCode)}
                      </TableCell>
                      {displayColumnSurcharge && (
                        <TableCell align="right" className="width_200p">
                          {childSurchargeDiscount !== 0 ?
                            `${
                                childSurchargeDiscount < 0 ?
                                  customDisplayCurrency(childSurchargeDiscount, currencyCode) :
                                  customDisplayCurrency(-childSurchargeDiscount, currencyCode)
                              }` :
                            DATA_NULL}
                        </TableCell>
                      )}
                      {isBookingAdvanceWithoutPartner && (
                        <TableCell align="right" className="width_250p">
                          {DATA_NULL}
                        </TableCell>
                      )}
                      <TableCell align="right" className="width_150p">
                        {onetimeChildren}
                      </TableCell>
                      <TableCell align="right" className="width_200p">
                        {onetimeChildrenAmountDiscount !== 0}
                        {customDisplayCurrency(-(onetimeChildrenAmountDiscount + onetimeChildren * childSurchargeDiscount), currencyCode)}
                      </TableCell>
                    </TableRow>
                  )}
                  {/* Partner discount: One-time discount adult */}
                  {onetimeDiscountAdults > 0 && (
                    <TableRow className="fee_breakdown_table_row">
                      <TableCell className="width_400p fee_breakdown_table_description">
                        {t('reservationManagement.adult_one_time_discount')} {shopPartnerDiscountType === DISCOUNT_TYPE[0].id && `(-${shopPartnerDiscount}%)`}
                      </TableCell>
                      <TableCell align="right" className="width_200p">
                        {onetimeDiscountAdultPriceDiscount !== 0}
                        {customDisplayCurrency(-onetimeDiscountAdultPriceDiscount, currencyCode)}
                      </TableCell>
                      {displayColumnSurcharge && (
                        <TableCell align="right" className="width_200p">
                          {DATA_NULL}
                        </TableCell>
                      )}
                      {isBookingAdvanceWithoutPartner && (
                        <TableCell align="right" className="width_250p">
                          {DATA_NULL}
                        </TableCell>
                      )}
                      <TableCell align="right" className="width_150p">
                        {onetimeDiscountAdults}
                      </TableCell>
                      <TableCell align="right" className="width_200p">
                        {onetimeDiscountAdultsAmountDiscount !== 0}
                        {customDisplayCurrency(-onetimeDiscountAdultsAmountDiscount, currencyCode)}
                      </TableCell>
                    </TableRow>
                  )}
                  {/* Partner discount: One-time discount child */}
                  {onetimeDiscountChildren > 0 && (
                    <TableRow className="fee_breakdown_table_row">
                      <TableCell className="width_400p fee_breakdown_table_description">
                        {t('reservationManagement.child_one_time_discount')} {shopPartnerDiscountType === DISCOUNT_TYPE[0].id && `(-${shopPartnerDiscount}%)`}
                      </TableCell>
                      <TableCell align="right" className="width_200p">
                        {onetimeDiscountChildPriceDiscount !== 0}
                        {customDisplayCurrency(-onetimeDiscountChildPriceDiscount, currencyCode)}
                      </TableCell>
                      {displayColumnSurcharge && (
                        <TableCell align="right" className="width_200p">
                          {DATA_NULL}
                        </TableCell>
                      )}
                      {isBookingAdvanceWithoutPartner && (
                        <TableCell align="right" className="width_250p">
                          {DATA_NULL}
                        </TableCell>
                      )}
                      <TableCell align="right" className="width_150p">
                        {onetimeDiscountChildren}
                      </TableCell>
                      <TableCell align="right" className="width_200p">
                        {onetimeDiscountChildrenAmountDiscount !== 0}
                        {customDisplayCurrency(-onetimeDiscountChildrenAmountDiscount, currencyCode)}
                      </TableCell>
                    </TableRow>
                  )}
                  {/* Partner discount: One-time discount special person */}
                  {used_special_price?.length > 0 &&
                    used_special_price.map((special_price) => {
                      let discountSurcharge =
                        special_price.type === SPECIAL_PERSON_TYPE[2].value ? 0 : special_price.type === SPECIAL_PERSON_TYPE[0].value ? adultSurcharge : childSurcharge;
                      let amount = 0;
                      let amountDiscount = 0;
                      const surcharge = discountSurcharge;
                      if (isBookingAdvanceWithoutPartner) {
                        if (special_price.type === SPECIAL_PERSON_TYPE[2].value) {
                          amount = special_price?.price * special_price?.number;
                        } else if (special_price.type === SPECIAL_PERSON_TYPE[0].value) {
                          amount = (special_price?.price + advanceBookingAdultPrice + adultSurcharge) * special_price?.number;
                        } else {
                          amount = (special_price?.price + advanceBookingChildPrice + childSurcharge) * special_price?.number;
                        }
                      } else {
                        if (special_price.type === SPECIAL_PERSON_TYPE[2].value) {
                          amount = special_price?.price * special_price?.number;
                        } else if (special_price.type === SPECIAL_PERSON_TYPE[0].value) {
                          amount = (special_price?.origin_price + adultSurcharge) * special_price?.number;
                        } else {
                          amount = (special_price?.origin_price + childSurcharge) * special_price?.number;
                        }
                      }
                      if (shopPartnerDiscountType === DISCOUNT_TYPE[1].id && special_price.type !== SPECIAL_PERSON_TYPE[2].value) {
                        const discountRemaining = shopPartnerDiscount - special_price?.origin_price;
                        if (special_price.type === SPECIAL_PERSON_TYPE[0].value) {
                          discountSurcharge = discountRemaining >= 0 ? (discountRemaining - adultSurcharge >= 0 ? adultSurcharge : discountRemaining) : 0;
                        } else {
                          discountSurcharge = discountRemaining >= 0 ? (discountRemaining - childSurcharge >= 0 ? childSurcharge : discountRemaining) : 0;
                        }
                        const unitDiscount = shopPartnerDiscount > special_price?.origin_price ? -special_price?.origin_price : -shopPartnerDiscount;
                        amountDiscount = (unitDiscount - (displayColumnSurcharge ? discountSurcharge : 0)) * special_price?.number;
                      }
                      if (special_price?.type !== SPECIAL_PERSON_TYPE[2].value) {
                        return (
                          <TableRow className="fee_breakdown_table_row">
                            <TableCell className="fee_breakdown_table_description">
                              {special_price?.name[language]} {shopPartnerDiscountType === DISCOUNT_TYPE[0].id && `(-${shopPartnerDiscount}%)`}
                            </TableCell>
                            <TableCell align="right">
                              {shopPartnerDiscountType === DISCOUNT_TYPE[0].id ?
                                this.calculateShopPartnerPercentDiscount(-special_price?.origin_price, shopPartnerDiscount, currencyCode) :
                                `${customDisplayCurrency(shopPartnerDiscount > special_price?.origin_price ? -special_price?.origin_price : -shopPartnerDiscount, currencyCode)}`}
                            </TableCell>
                            {displayColumnSurcharge && (
                              <TableCell align="right">
                                {shopPartnerDiscount === 0 ? DATA_NULL : (shopPartnerDiscountType === DISCOUNT_TYPE[0].id ?
                                    this.calculateShopPartnerPercentDiscount(discountSurcharge < 0 ? discountSurcharge : -discountSurcharge, shopPartnerDiscount, currencyCode) ||
                                      DATA_NULL :
                                  surcharge < 0 ?
                                  `${customDisplayCurrency(surcharge, currencyCode)}` :
                                  discountSurcharge > 0 ?
                                  `${customDisplayCurrency(-discountSurcharge, currencyCode)}` :
                                  DATA_NULL)}
                              </TableCell>
                            )}
                            <TableCell align="right">{special_price?.number}</TableCell>
                            <TableCell align="right">
                              {shopPartnerDiscountType === DISCOUNT_TYPE[0].id ?
                                this.calculateShopPartnerPercentDiscount(-amount, shopPartnerDiscount, currencyCode) :
                                `${customDisplayCurrency(amountDiscount, currencyCode)}`}
                            </TableCell>
                          </TableRow>
                        );
                      } else {
                        return <></>;
                      }
                    })}
                </>
              )}
            </TableBody>
          </Table>
          <br />
          <Grid container alignItems="center" justifyContent="flex-end">
            <Grid item>
              <Table size="small">
                <TableBody>
                  <TableRow className="fee_breakdown_table_row">
                    <TableCell className="font_color_pink font_bold">{t('reservationManagement.total')}</TableCell>
                    <TableCell align="right">{customDisplayCurrency(total + totalSurcharge + total_other_special_price + total_no_other_special_price, currencyCode)}</TableCell>
                  </TableRow>
                  {detailCost?.partner_coupon && (
                    <>
                      <TableRow className="fee_breakdown_table_row">
                        <TableCell className="font_color_pink font_bold">{t('reservationManagement.totalDiscount')}</TableCell>
                        <TableCell align="right">
                          {totalDiscount !== 0}
                          {customDisplayCurrency(-((total + totalSurcharge + total_other_special_price + total_no_other_special_price) - this.props.totalCost), currencyCode)}
                        </TableCell>
                      </TableRow>
                      <TableRow className="fee_breakdown_table_row">
                        <TableCell className="font_color_pink font_bold">{t('reservationManagement.finalTotal')}</TableCell>
                        <TableCell align="right">
                          {customDisplayCurrency(this.props.totalCost, currencyCode)}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
          <br />
          <Grid container item xs={12} alignItems="center" justifyContent="center">
            <Button variant="contained" className="button_color" color="primary" onClick={this.handleButtonConFirm}>
              {t('common.btnClose')}
            </Button>
          </Grid>
        </Container>
        <br />
      </Paper>
    );
  }
}

export default withTranslation('translations')(Index);
