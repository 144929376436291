/* eslint-disable max-len */
import React, {Component} from 'react';

import 'date-fns';
import {
  Card,
  Container,
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  Paper,
  Dialog,
  FormHelperText,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Modal,
  Backdrop,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Switch,
  Box,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ForwardIcon from '@material-ui/icons/Forward';
import RenderIcon from '@material-ui/icons/Loop';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {ROUTE, MOBI_TYPES, DATA_NULL, PERMISSION_ACTIONS, JIT_ESTIMATE_OPTION} from '../../../common/constant';
import MapSettingRoute from '../../../components/map/MapSettingRoute';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getGeofenceDetailApi} from '../../../services/jitOrderServices';
import {searchAreaGeofence, getAreaGeofenceDetail} from '../../../stores/area_geofence/action';
import {getListGeofence} from '../../../stores/common/actions';
import {getAllCountryCode} from '../../../stores/common/actions';
import {configJitRoute, getJitRouteDetail, getWaypointStops} from '../../../stores/jit_setting/action';
import {setMessageModal} from '../../../stores/modal/actions';
import {backForwardRouter, preventInvalidChars, redirectRouter} from '../../../utils/common';
import {modalObj} from '../../../utils/modal';
import {isRoleBusiness, isRoleCountry, isRoleGlobal} from '../../../utils/role';
import MapSettingRoutePopup from './MapSettingRoutePopup';

const axios = require('axios');

/**
 * Form JIT Routes
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      countryId: !isRoleGlobal() ? props.principal.country_id : '',
      areaId: '',
      geofence: {},
      geofenceId: '',
      listArea: [],
      listGeofence: [],
      vehicleMode: 'driving',

      routeName: [],
      pickupLocation: [],
      dropoffLocation: [],
      type: [],
      listTransitStops: [],
      listTransitStopsForRoute: [],
      pickup_stop_ids: [],
      dropoff_stop_ids: [],
      enable: [],

      masterDataRoute: [],
      totalDuration: [],
      pointDrawingRoute: [],
      polylineDrawingRoute: '',
      openPopupMapSettingRoute: false,
      openPopupConfirmConfig: false,
      isLoading: false,
      isSubmitForm: false,
      canUpdate: true,
      callDirection: false,
      messageConfig: '',
      only_app: false,
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    this.props.getAllCountryCode();
    !this.props.match.params.id && this.props.getListGeofence().then((response) => this.setState({listGeofence: response}));
    this.state.countryId && this.props.searchAreaGeofence({countryId: this.state.countryId}).then((response) => {
      isRoleCountry() && this.setState({listArea: response?.content});
      if (isRoleBusiness()) {
        const listGeofences = response?.content.filter((item1) => {
          const geofence = item1.geofences.filter((item2) => this.props.principal.geofence_ids.includes(item2));
          return geofence.length > 0;
        });
        this.setState({listArea: listGeofences});
      }
    });
    if (this.props.match.params.id) {
      this.props.getJitRouteDetail(this.props.match.params.id).then(async (response) => {
        if (isRoleGlobal() ? true : Number(response?.country_id) === Number(this.props.principal.country_id)) {
          this.setState({isLoading: true});
          const routes = response?.routes;
          const countryId = response?.country_id;
          const areaId = response?.area_id;
          const geofenceId = response?.geofence_id;
          const only_app = response?.only_old_app;
          let vehicleMode = response?.vehicle_mode || 'driving';
          let geofence = {};
          let listGeofence = [];
          let listArea = [];
          // Get area
          await this.props.getAreaGeofenceDetail(areaId).then((response) => {
            listArea = [response];
          });
          // Get geofence
          const getGeofenceDetail = await getGeofenceDetailApi(geofenceId);
          if (getGeofenceDetail.status === 200) {
            geofence = getGeofenceDetail.result;
            listGeofence = [geofence];
            if (geofence.vehicle_modes.length > 0 && !geofence.vehicle_modes.includes(vehicleMode)) vehicleMode = geofence.vehicle_modes[0];
          } else {
            this.setState({isLoading: false});
            return;
          }
          if (routes.length === 0) {
            this.setState({isLoading: false});
            return;
          }
          const type = [];
          const routeName = [];
          const pickupLocation = [];
          const dropoffLocation = [];
          const listTransitStops = [];
          const listTransitStopsForRoute = [];
          const pickup_stop_ids = [];
          const dropoff_stop_ids = [];
          const masterDataRoute = [];
          const totalDuration = [];
          const enable = [];
          for (const route of routes) {
            type.push(route.group_type);
            routeName.push(route.name);
            enable.push(route.enable);
            let listTransitStopsData = [];
            await this.props
              .getWaypointStops({
                serviceGroupIds: geofence?.service_group_id || null,
                simulationIds: route.simulation_id,
                groupTypes: route.group_type,
                size: 300,
              })
              .then((res) => {
                const listTransitStopsResult = res?.transit_stops?.filter((item) => item.point.coordinates[0] && item.point.coordinates[1])?.sort((a, b) => (a?.id > b?.id) ? 1 : -1);
                listTransitStopsData = listTransitStopsResult?.map((item) => {
                  return {
                    ...item,
                    displayName: item.name_translations[this.props.i18n.language] || item.display_name || item.street_translations[this.props.i18n.language] || item.name,
                  };
                });
              });
            const turnLocations = route.turn_locations.sort(this.compare);
            // Get all waypoint from route
            const listAllWaypointFromRoute = [];
            for (const [index, eachRoute] of Object.entries(turnLocations)) {
              listAllWaypointFromRoute.push({
                ...eachRoute.from,
                willer_stop_id: eachRoute.from.id,
                point: {
                  coordinates: [eachRoute.from.longitude, eachRoute.from.latitude],
                },
              });
              if (Number(index) === turnLocations.length - 1) {
                listAllWaypointFromRoute.push({
                  ...eachRoute.to,
                  willer_stop_id: eachRoute.to.id,
                  point: {
                    coordinates: [eachRoute.to.longitude, eachRoute.to.latitude],
                  },
                });
              }
            }
            // Can't update if no waypoint
            if (listTransitStopsData.length === 0) {
              this.setState({canUpdate: false});
              listTransitStopsData = listAllWaypointFromRoute;
              pickup_stop_ids.push(route.pickup_stop_ids);
              dropoff_stop_ids.push(route.dropoff_stop_ids);
            } else {
              if (route?.pickup_stop_ids?.length > 0) pickup_stop_ids.push(route.pickup_stop_ids);
              else {
                const listAllWaypointOnRoute = listTransitStopsData.filter((item) => listAllWaypointFromRoute?.map((item2) => item2.willer_stop_id).includes(item.willer_stop_id));
                pickup_stop_ids.push(listAllWaypointOnRoute.filter((item) => route.group_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? !item.is_work : item.is_work).map((item) => item.willer_stop_id));
              }
              if (route?.dropoff_stop_ids?.length > 0) dropoff_stop_ids.push(route.dropoff_stop_ids);
              else {
                const listAllWaypointOnRoute = listTransitStopsData.filter((item) => listAllWaypointFromRoute?.map((item2) => item2.willer_stop_id).includes(item.willer_stop_id));
                dropoff_stop_ids.push(listAllWaypointOnRoute.filter((item) => route.group_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? item.is_work : !item.is_work).map((item) => item.willer_stop_id));
              }
            }
            pickupLocation.push(listTransitStopsData.find((item) => item.willer_stop_id === turnLocations[0].from_stop_id));
            dropoffLocation.push(listTransitStopsData.find((item) => item.willer_stop_id === turnLocations[turnLocations.length - 1].to_stop_id));
            listTransitStops.push(listTransitStopsData);
            const listTransitStopsForRouteId = [...turnLocations].splice(1, turnLocations.length - 1).map((item) => item.from_stop_id);
            listTransitStopsForRoute.push(listTransitStopsData.filter((item) => listTransitStopsForRouteId.includes(item.willer_stop_id)));
            totalDuration.push({
              swat: route.estimate_driving_time || 0,
              google: 0,
            });
            // Process master data
            const routes = turnLocations.map((item) => {
              return {
                startPoint: listTransitStopsData.find((item2) => item2.willer_stop_id === item.from_stop_id),
                endPoint: listTransitStopsData.find((item2) => item2.willer_stop_id === item.to_stop_id),
                distance: item.estimate_distance,
                duration: item.estimate_driving_time,
                polyline: item.polyline,
                estimate_time: route.estimate_option === 'MANUAL' ? Math.floor((item.estimate_driving_time/60)): null,
              };
            });
            masterDataRoute.push({
              polyline: route.polyline,
              distance: route.estimate_distance,
              routes: routes,
              estimate_option: route.estimate_option === 'MANUAL' ? 'MANUAL' : 'SWAT',
            });
          }
          await this.setState({
            countryId,
            areaId,
            geofenceId,
            geofence,
            vehicleMode,
            type,
            routeName,
            pickupLocation,
            dropoffLocation,
            listTransitStops,
            listTransitStopsForRoute,
            pickup_stop_ids,
            dropoff_stop_ids,
            masterDataRoute,
            totalDuration,
            enable,
            listGeofence,
            listArea,
            callDirection: true,
            isLoading: false,
            only_app,
          });
          this.checkErrorData();
        } else {
          this.props.setMessageModal(modalObj(true, 'permission.denied'));
          backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.HOME);
        }
      });
    }
  }

  /**
   * checkErrorData
   */
  checkErrorData = async () => {
    let errorData = false;
    const {pickupLocation, dropoffLocation, listTransitStopsForRoute} = this.state;
    if (pickupLocation.indexOf(undefined) !== -1) {
      errorData = true;
      const index = pickupLocation.indexOf(undefined);
      pickupLocation[index] = {};
    }
    if (dropoffLocation.indexOf(undefined) !== -1) {
      errorData = true;
      const index = dropoffLocation.indexOf(undefined);
      dropoffLocation[index] = {};
    }
    const listIndexEmptyPoint = [];
    for (const [index, listPoints] of Object.entries(listTransitStopsForRoute)) {
      listIndexEmptyPoint.push([]);
      for (const [index2, point] of Object.entries(listPoints)) {
        if (point === {}) {
          errorData = true;
          listIndexEmptyPoint[index].push(index2);
        }
      }
    }
    for (const [index, listIndexPoints] of Object.entries(listIndexEmptyPoint)) {
      for (const idx of listIndexPoints.reverse()) {
        listTransitStopsForRoute[index].splice(idx, 1);
      }
    }
    this.setState({pickupLocation, dropoffLocation, listTransitStopsForRoute});
    errorData && this.props.setMessageModal(modalObj(true, this.props.t('jit.missing_point')));
  };

  /**
   * compare
   * @param {Object} item1
   * @param {Object} item2
   * @return {Number}
   */
  compare = (item1, item2) => {
    if (item1.order_number < item2.order_number) return -1;
    if (item1.order_number > item2.order_number) return 1;
    return 0;
  };

  /**
   * resetRoute
   */
  resetRoute = () => {
    this.setState({
      routeName: [],
      pickupLocation: [],
      dropoffLocation: [],
      type: [],
      listTransitStops: [],
      listTransitStopsForRoute: [],
      pickup_stop_ids: [],
      dropoff_stop_ids: [],
      masterDataRoute: [],
      totalDuration: [],
      enable: [],
    });
  };

  /**
   * changeCountry
   * @param {*} event
   */
  changeCountry = (event) => {
    this.setState({
      countryId: event.target.value,
      areaId: '',
      geofenceId: '',
      geofence: {},
      vehicleMode: 'driving',
    });
    this.props.searchAreaGeofence({countryId: event.target.value}).then((response) => {
      this.setState({listArea: response?.content});
    });
    this.resetRoute();
  };

  /**
   * changeArea
   * @param {*} event
   */
  changeArea = (event) => {
    this.setState({
      areaId: event.target.value,
      geofenceId: '',
      geofence: {},
      vehicleMode: 'driving',
    });
    this.resetRoute();
  };

  /**
   * changeGeofence
   * @param {*} event
   */
  changeGeofence = async (event) => {
    this.setState({
      geofenceId: event.target.value,
      geofence: this.state.listGeofence.find((item) => item.geofence_id === event.target.value),
      vehicleMode: this.state.listGeofence.find((item) => item.geofence_id === event.target.value)?.vehicle_modes[0] || 'driving',
    });
    this.resetRoute();
  };

  /**
   * changeType
   * @param {*} event
   * @param {Object} item
   */
  changeType = (event, item) => {
    this.setState({isLoading: true});
    const eventChecked = event.target.checked;
    const {geofence} = this.state;
    this.props
      .getWaypointStops({
        serviceGroupIds: geofence?.service_group_id || null,
        simulationIds: item.id === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? geofence.jit_home_to_work_sim_id : geofence.jit_work_to_home_sim_id,
        groupTypes: item.id,
        size: 300,
      })
      .then((response) => {
        const {listTransitStops, listTransitStopsForRoute, routeName, pickupLocation, dropoffLocation,
          masterDataRoute, type, totalDuration, enable, pickup_stop_ids, dropoff_stop_ids} = this.state;
        const listTransitStopsResult = response?.transit_stops
          ?.filter((item) => item.point.coordinates[0] && item.point.coordinates[1]);
        let listTransitStopsData = listTransitStopsResult?.map((item) => {
        return {
            ...item,
            displayName: item.name_translations[this.props.i18n.language] || item.display_name || item.street_translations[this.props.i18n.language] || item.name,
          };
        });
        if (geofence.jit_home_to_work_sim_id === null && geofence.jit_work_to_home_sim_id === null) listTransitStopsData = [];
        if (eventChecked) {
          type.push(item.id);
          listTransitStops.push(listTransitStopsData);
          listTransitStopsForRoute.push([]);
          routeName.push('');
          pickupLocation.push({});
          dropoffLocation.push({});
          masterDataRoute.push({});
          totalDuration.push({
            swat: 0,
            google: 0,
          });
          enable.push(true);
          pickup_stop_ids.push([]);
          dropoff_stop_ids.push([]);
        } else {
          const index = type.indexOf(item.id);
          type.splice(index, 1);
          listTransitStops.splice(index, 1);
          listTransitStopsForRoute.splice(index, 1);
          routeName.splice(index, 1);
          pickupLocation.splice(index, 1);
          dropoffLocation.splice(index, 1);
          masterDataRoute.splice(index, 1);
          totalDuration.splice(index, 1);
          enable.splice(index, 1);
          pickup_stop_ids.splice(index, 1);
          dropoff_stop_ids.splice(index, 1);
        }
        this.setState({listTransitStops, listTransitStopsForRoute, routeName, pickupLocation, dropoffLocation,
          masterDataRoute, type, totalDuration, enable, pickup_stop_ids, dropoff_stop_ids, isLoading: false});
      })
      .catch(() => this.setState({isLoading: false}));
  };

  /**
   * chooseWaypoint
   * @param {Object} transitStop
   * @param {Object} index
   */
  chooseWaypoint = async (transitStop, index) => {
    this.setState({isLoading: true});
    const {listTransitStopsForRoute, totalDuration, pickup_stop_ids, dropoff_stop_ids, type} = this.state;
    if (listTransitStopsForRoute[index].includes(transitStop)) {
      const indexOfPoint = listTransitStopsForRoute[index].indexOf(transitStop);
      listTransitStopsForRoute[index].splice(indexOfPoint, 1);
      if (type[index] === 'SHUTTLE_BUS_JIT_HOME_TO_WORK') {
        const indexOfPoint = transitStop.is_work ? dropoff_stop_ids[index].indexOf(transitStop.willer_stop_id) : pickup_stop_ids[index].indexOf(transitStop.willer_stop_id);
        if (indexOfPoint > -1) transitStop.is_work ? dropoff_stop_ids[index].splice(indexOfPoint, 1) : pickup_stop_ids[index].splice(indexOfPoint, 1);
      } else {
        const indexOfPoint = transitStop.is_work ? pickup_stop_ids[index].indexOf(transitStop.willer_stop_id) : dropoff_stop_ids[index].indexOf(transitStop.willer_stop_id);
        if (indexOfPoint > -1) transitStop.is_work ? pickup_stop_ids[index].splice(indexOfPoint, 1) : dropoff_stop_ids[index].splice(indexOfPoint, 1);
      }
    } else {
      !listTransitStopsForRoute[index].includes(transitStop) && listTransitStopsForRoute[index].push(transitStop);
      if (type[index] === 'SHUTTLE_BUS_JIT_HOME_TO_WORK') {
        transitStop.is_work ? dropoff_stop_ids[index].push(transitStop.willer_stop_id) : pickup_stop_ids[index].push(transitStop.willer_stop_id);
      } else {
        transitStop.is_work ? pickup_stop_ids[index].push(transitStop.willer_stop_id) : dropoff_stop_ids[index].push(transitStop.willer_stop_id);
      }
    }
    // Đẩy các điểm work hoặc home về đầu hoặc cuối
    listTransitStopsForRoute[index] = type[index] === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
    [
      ...listTransitStopsForRoute[index].filter((item) => !item.is_work),
      ...listTransitStopsForRoute[index].filter((item) => item.is_work),
    ] : [
      ...listTransitStopsForRoute[index].filter((item) => item.is_work),
      ...listTransitStopsForRoute[index].filter((item) => !item.is_work),
    ];
    totalDuration[index].google = 0;
    await this.setState({listTransitStopsForRoute, totalDuration, pickup_stop_ids, dropoff_stop_ids, isLoading: false});
    this.processListPointOnRoute(index);
  };

  /**
   * processListPointOnRoute
   * @param {Number} index
   */
  processListPointOnRoute = (index) => {
    const {listTransitStopsForRoute, masterDataRoute, totalDuration, pickupLocation, dropoffLocation, pickup_stop_ids, dropoff_stop_ids} = this.state;
    if (Object.keys(pickupLocation[index]).length === 0 || Object.keys(dropoffLocation[index]).length === 0) {
      masterDataRoute[index] = {estimate_option: this.state.masterDataRoute[index]?.estimate_option ? this.state.masterDataRoute[index]?.estimate_option : 'SWAT'};
    } else {
      const listPointOnRoute = [[...pickupLocation][index], [...dropoffLocation][index]];
      const new_pickup_stop_ids = pickup_stop_ids[index]?.filter((item) => item !== [...pickupLocation][index]?.id);
      const new_dropoff_stop_ids = dropoff_stop_ids[index]?.filter((item) => item !== [...dropoffLocation][index]?.id);
      const new_pickup_dropoff_ids = new_pickup_stop_ids?.concat(new_dropoff_stop_ids);
      const newListTransitStopsForRoute = new_pickup_dropoff_ids?.map((item) => listTransitStopsForRoute[index]?.find((i) => i.id === item));
      for (const transitStop of newListTransitStopsForRoute) {
        listPointOnRoute.splice(listPointOnRoute.length - 1, 0, transitStop);
      }
      const newMasterDataRoute = [...this.state.masterDataRoute];
      const routes = listPointOnRoute
        .map((item, idx) => {
          const data = newMasterDataRoute[index]?.routes?.find((route) => route.startPoint.id === item.id && route.endPoint.id === listPointOnRoute[idx + 1]?.id);
          return {
            startPoint: item,
            endPoint: listPointOnRoute[idx + 1] || null,
            distance: null,
            duration: 0,
            estimate_time: data ? data?.estimate_time : null,
          };
        })
        .filter((item) => item.endPoint !== null);
      masterDataRoute[index] = {
        polyline: '',
        distance: 0,
        routes: routes,
        estimate_option: newMasterDataRoute[index]?.estimate_option ? newMasterDataRoute[index]?.estimate_option : 'SWAT',
      };
    }
    totalDuration[index].swat = 0;
    this.setState({masterDataRoute, totalDuration});
  };

  /**
   * getSwatMasterDataRoute
   * @param {Number} index
   */
  getSwatMasterDataRoute = async (index) => {
    this.setState({callDirection: true});
    const swatApi = `${process.env.REACT_APP_SWAT_MAPBOX_API}/route/v1/${this.state.vehicleMode}`;
    const swatQueryString = `geometries=polyline&overview=full&steps=true&access_token=${process.env.REACT_APP_SWAT_TOKEN}`;
    let queryPointString = '';
    if (this.state.masterDataRoute[index].routes.length === 0) return;
    for (const [idx, route] of Object.entries(this.state.masterDataRoute[index].routes)) {
      queryPointString += `${route.startPoint.point.coordinates[0] || route.startPoint.point.coordinates.lng},${
        route.startPoint.point.coordinates[1] || route.startPoint.point.coordinates.lat
      };`;
      if (Number(idx) === this.state.masterDataRoute[index].routes.length - 1) {
        queryPointString += `${route.endPoint.point.coordinates[0] || route.endPoint.point.coordinates.lng},${
          route.endPoint.point.coordinates[1] || route.endPoint.point.coordinates.lat
        }`;
      }
    }
    await axios
      .get(`${swatApi}/${queryPointString}?${swatQueryString}`)
      .then(async (response) => {
        await this.setState({isLoading: true});
        if (response.status === 200) {
          const route = response?.data?.routes[0];
          const {totalDuration, masterDataRoute, geofence, type} = this.state;
          const max_additional_journey_time_percent = (type[index] === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
            geofence?.jit_h2w_config?.max_additional_journey_time_percent :
            geofence?.jit_w2h_config?.max_additional_journey_time_percent) || 0;
          for (let [idx, eachRoute] of Object.entries(masterDataRoute[index].routes)) {
            const service_time = (type[index] === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
              (eachRoute.endPoint?.is_work ? geofence?.jit_h2w_config?.dropoff_service_time : geofence?.jit_h2w_config?.pickup_service_time) :
              (eachRoute.endPoint?.is_work ? geofence?.jit_w2h_config?.pickup_service_time : geofence?.jit_w2h_config?.dropoff_service_time)) || 0;
            await this.getSwatDataRoute(eachRoute.startPoint, eachRoute.endPoint).then((res) => {
              eachRoute = {
                ...eachRoute,
                distance: route.legs[idx].distance,
                duration: route.legs[idx].duration * (max_additional_journey_time_percent / 100 + 1) + service_time,
                polyline: res,
              };
              masterDataRoute[index].routes[idx] = eachRoute;
            });
          }
          totalDuration[index].swat = masterDataRoute[index].routes.map((item) => item.duration).reduce((a, b) => a + b, 0);
          masterDataRoute[index] = {
            ...masterDataRoute[index],
            polyline: route.geometry,
            distance: route.distance,
          };
          await this.setState({totalDuration, masterDataRoute});
        } else this.props.setMessageModal(modalObj(true, response.statusText));
        this.setState({isLoading: false});
      })
      .catch(() => {
        this.setState({isLoading: false});
        this.props.setMessageModal(modalObj(true, 'jit.generate_route_fail'));
      });
  };

  /**
   * getSwatDataRoute
   * @param {Object} startPoint
   * @param {Object} endPoint
   * @return {String}
   */
  getSwatDataRoute = async (startPoint, endPoint) => {
    const swatApi = `${process.env.REACT_APP_SWAT_MAPBOX_API}/route/v1/${this.state.vehicleMode}`;
    const swatQueryString = `geometries=polyline&overview=full&steps=true&access_token=${process.env.REACT_APP_SWAT_TOKEN}`;
    const queryPointString = `${startPoint.point.coordinates[0] || startPoint.point.coordinates.lng},${startPoint.point.coordinates[1] || startPoint.point.coordinates.lat};${
      endPoint.point.coordinates[0] || endPoint.point.coordinates.lng
    },${endPoint.point.coordinates[1] || endPoint.point.coordinates.lat}`;
    return await axios
      .get(`${swatApi}/${queryPointString}?${swatQueryString}`)
      .then((response) => {
        this.setState({isLoading: true});
        if (response.status === 200) {
          return response?.data?.routes[0].geometry;
        } else this.props.setMessageModal(modalObj(true, response.statusText));
        this.setState({isLoading: false});
        return '';
      })
      .catch(() => {
        this.props.setMessageModal(modalObj(true, 'jit.generate_route_fail'));
        this.setState({isLoading: false});
        return '';
      });
  };

  /**
   * getGoogleDuration
   * @param {Number} googleTimeDuration
   * @param {Number} index
   */
  getGoogleDuration = async (googleTimeDuration, index) => {
    this.setState({isLoading: true, callDirection: false});
    const {totalDuration, geofence, type, masterDataRoute} = this.state;
    const service_pickup_time = (type[index] === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
      geofence?.jit_h2w_config?.pickup_service_time :
      geofence?.jit_w2h_config?.pickup_service_time) || 0;
    const service_dropoff_time = (type[index] === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
      geofence?.jit_h2w_config?.dropoff_service_time :
      geofence?.jit_w2h_config?.dropoff_service_time) || 0;
    const max_additional_journey_time_percent = (type[index] === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
      geofence?.jit_h2w_config?.max_additional_journey_time_percent :
      geofence?.jit_w2h_config?.max_additional_journey_time_percent) || 0;
    const countPickupPoint = masterDataRoute[index]?.routes.map((item) => item.endPoint).filter((item) => type[index] === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? !item.is_work : item.is_work).length;
    const countDropoffPoint = masterDataRoute[index]?.routes.map((item) => item.endPoint).filter((item) => type[index] === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? item.is_work : !item.is_work).length;
    totalDuration[index].google = googleTimeDuration * (max_additional_journey_time_percent / 100 + 1) + service_pickup_time * countPickupPoint + service_dropoff_time * countDropoffPoint;
    this.setState({totalDuration, isLoading: false});
  };

  /**
   * processDuration
   * @param {Number} durationSecs
   * @return {String}
   */
  processDuration = (durationSecs) => {
    let result = '';
    const durationMins = Math.round(durationSecs / 60);
    if (durationMins < 60) result = `${durationMins} ${this.props.t('common.minute')}`;
    else {
      if (durationMins % 60 === 0) result = `${durationMins / 60} ${this.props.t('common.hour')}`;
      else {
        const durationHours = Math.ceil(durationMins / 60) - 1;
        result = `${durationHours} ${this.props.t('common.hour')} ${durationMins - durationHours * 60} ${this.props.t('common.minute')}`;
      }
    }
    return result;
  };

  /**
   * confirmConfig
   * @param {String} action
   */
  confirmConfig = async (action) => {
    let messageConfig = action === 'create' ? 'jit.confirm_create_route' : 'jit.confirm_update_route';
    if (this.checkMasterDurationDifferent()) messageConfig = 'jit.20_different';
    const {type, pickupLocation, dropoffLocation, masterDataRoute} = this.state;
    this.setState({isSubmitForm: true});
    for (const [index] of Object.entries(type)) {
      if (Object.keys(pickupLocation[index]).length === 0) return;
      if (Object.keys(dropoffLocation[index]).length === 0) return;
      if (masterDataRoute[index].estimate_option === 'SWAT' && masterDataRoute[index].polyline === '') return;
    }
    if (!this.validator.allValid()) return;
    this.setState({messageConfig, openPopupConfirmConfig: true});
  };

  /**
   * configRoute
   */
  configRoute = async () => {
    this.closeConfirmConfigPopup();
    for (const [index] of Object.entries(this.state.type)) {
      this.state.masterDataRoute[index].polyline === '' && await this.getSwatMasterDataRoute(index);
    }
    const {only_app, countryId, areaId, geofenceId, geofence, type, routeName, pickupLocation, dropoffLocation,
      masterDataRoute, totalDuration, enable, vehicleMode, pickup_stop_ids, dropoff_stop_ids} = this.state;
    const routes = type.map((item, index) => {
      return {
        country_id: countryId,
        area_id: areaId,
        geofence_id: geofenceId,
        simulation_id: item === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? geofence.jit_home_to_work_sim_id : geofence.jit_work_to_home_sim_id,
        group_type: item,
        name: routeName[index].trim(),
        enable: enable[index],
        from_stop_id: pickupLocation[index].willer_stop_id,
        to_stop_id: dropoffLocation[index].willer_stop_id,
        pickup_stop_ids: pickup_stop_ids[index],
        dropoff_stop_ids: dropoff_stop_ids[index],
        estimate_driving_time: totalDuration[index].swat,
        estimate_distance: masterDataRoute[index]?.distance,
        polyline: masterDataRoute[index]?.polyline?.split('/').join('//'),
        estimate_option: masterDataRoute[index].estimate_option,
        turn_locations: masterDataRoute[index]?.routes?.map((itemRoute, idx) => {
          return {
            order_number: idx + 1,
            name: `${itemRoute.startPoint.name}  --->  ${itemRoute.endPoint.name}`,
            polyline: itemRoute.polyline?.split('/').join('//'),
            from_stop_id: itemRoute.startPoint.willer_stop_id,
            to_stop_id: itemRoute.endPoint.willer_stop_id,
            estimate_driving_time: masterDataRoute[index].estimate_option === 'SWAT' ? itemRoute.duration : (Number(itemRoute.estimate_time)*60),
            estimate_distance: itemRoute.distance,
          };
        }),
      };
    });
    const payload = {
      code: this.props.match.params.id || '',
      geofence_id: geofenceId,
      country_id: countryId,
      area_id: areaId,
      vehicle_mode: vehicleMode,
      routes: routes,
      only_old_app: only_app,
    };
    await this.props.configJitRoute(payload, this.props);
    if (this.props.match.params.id) {
      this.props.getJitRouteDetail(this.props.match.params.id);
    } else redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.JIT_ROUTES_MANAGEMENT);
  };

  /**
   * checkDurationDifferent
   * @param {Number} index
   * @return {Boolean}
   */
  checkDurationDifferent = (index) => {
    const {totalDuration} = this.state;
    return Math.abs(totalDuration[index].google - totalDuration[index].swat) >= totalDuration[index].swat / 5;
  };

  /**
   * checkMasterDurationDifferent
   * @return {Boolean}
   */
  checkMasterDurationDifferent = () => {
    const {totalDuration} = this.state;
    for (const duration of totalDuration) {
      if (Math.abs(duration.google - duration.swat) >= duration.swat / 5) return true;
    }
    return false;
  };

  /**
   * closeMapSettingRoutePopup
   */
  closeMapSettingRoutePopup = () => {
    this.setState({openPopupMapSettingRoute: false});
  };

  /**
   * closeConfirmConfigPopup
   */
  closeConfirmConfigPopup = () => {
    this.setState({openPopupConfirmConfig: false});
  };

  /**
   * processRouteAfterChangeVehicleMode
   */
  processRouteAfterChangeVehicleMode = () => {
    const {masterDataRoute, totalDuration} = this.state;
    for (const [index, route] of Object.entries(masterDataRoute)) {
      if (route.routes) {
        route.distance = 0;
        route.polyline = '';
        for (const [index2, eachRoute] of Object.entries(route.routes)) {
          eachRoute.distance = 0;
          eachRoute.duration = 0;
          eachRoute.polyline = '';
          route.routes[index2] = eachRoute;
        }
        masterDataRoute[index] = route;
      } else {
        masterDataRoute[index] = {};
      }
      totalDuration[index] = {
        swat: 0,
        google: 0,
      };
    }
    this.setState({masterDataRoute, totalDuration});
  }

  /**
   * onChangeListData
   */
  onChangeListData = (component, data, fieldName, index, value, min = 0) => {
    if (value?.includes('-') || value?.includes('e') || value?.includes('+') || value?.includes('E') || value?.includes('.')) return;
    if (value) {
      const invalidChars = ['-', 'e', '+', 'E', '.'];
      // eslint-disable-next-line array-callback-return
      invalidChars.map((item) => {
        value = value.replaceAll(item, '');
      });
      value = value.replace(/[^\d.-]/g, '');
      if (min > 0) {
        while (value.length > 0 && value[0] === '0') {
          value = value.substr(1);
        }
      }
    }
    const copyData = data;
    copyData[index][fieldName] = value;
    component.setState({data: copyData});
  };

  /**
   * totalTimeEstimateDuration
   * @param {*} masterDataRoute
   * @return {*}
   */
  totalTimeEstimateDuration = (masterDataRoute) => {
    const value = masterDataRoute?.map((item) => Number(item.estimate_time)).reduce((a, b) => a+ b, 0);
    return value*60;
  }

  /**
   * onChangeEstimateOption
   * @param {*} component
   * @param {*} data
   * @param {*} fieldName
   * @param {*} index
   * @param {*} value
   */
  onChangeEstimateOption = (component, data, fieldName, index, value) => {
    const {masterDataRoute, totalDuration} = this.state;
    if (value === 'SWAT') {
      const newMasterDataRoute = [...this.state.masterDataRoute];
      const routes = newMasterDataRoute[index]?.routes
        ?.map((item) => {
          return {
            ...item,
            distance: null,
            duration: 0,
            polyline: '',
          };
        });
      masterDataRoute[index] = {
        polyline: '',
        distance: 0,
        estimate_option: 'SWAT',
        routes: routes,
      };
      totalDuration[index].swat = 0;
      totalDuration[index].google = 0;
    }
    const copyData = data;
    copyData[fieldName] = value;
    component.setState({data: copyData});
  }

  /**
   * Render
   * @return {HTMLElement}
   */
  render() {
    const {t, common, actions, principal} = this.props;
    const isEditForm = !!this.props.match.params.id;
    const currentLanguage = localStorage.getItem('i18nextLng');
    const listGeofence =
      this.state.areaId && !isEditForm ?
        this.state.listGeofence?.filter((item) => this.state.listArea.find((item) => item.id === Number(this.state.areaId))?.geofences?.includes(item.geofence_id)) :
        this.state.listGeofence;
    // Type phụ thuộc vao simulations
    const listType = MOBI_TYPES.filter((item) => item.id !== 'SHUTTLE_BUS_ON_DEMAND')?.map((item) => {
      return {
        id: item.id,
        i18n: item.i18n,
        enable:
          this.state.geofenceId &&
          (item.id === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
            !!this.state.listGeofence?.find((item) => item.geofence_id === this.state.geofenceId)?.jit_home_to_work_sim_id :
            !!this.state.listGeofence?.find((item) => item.geofence_id === this.state.geofenceId)?.jit_work_to_home_sim_id),
      };
    });
    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE) && this.state.canUpdate,
    };
    this.validator.purgeFields();

    return (
      <LoadingOverlay active={this.state.isLoading || common?.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size plan_form">
          <Container maxWidth="xl">
            <Grid container spacing={1} className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{isEditForm ? t('route.jit_route_detail') : t('route.jit_route_register')}</h3>
              </Grid>
            </Grid>
          </Container>
          <br></br>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {isEditForm ? t('route.jit_route_detail') : t('route.jit_route_register')}
                    </Grid>
                  </Grid>
                  {/* Country */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.country')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={10} className="grid_title_padding">
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_200">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'countryId',
                          }}
                          displayEmpty
                          disabled={!!isEditForm || !isRoleGlobal()}
                          value={this.state.countryId || ''}
                          onChange={this.changeCountry}
                          renderValue={
                            this.state.countryId ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.country'),
                                    })}
                                  </div>
                                )
                          }
                        >
                          {common?.country_code?.map((item, idx) => (
                            <MenuItem value={item.id} key={idx}>
                              {t(item.country_code)}
                            </MenuItem>
                          ))}
                        </Select>
                        {this.validator.message('countryId', this.state.countryId, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.countryId, 'required') && (
                          <FormHelperText id="countryId" error>
                            {t('validation.required.choose', {field: t('common.country')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Area */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.area')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={10} className="grid_title_padding">
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'areaId',
                          }}
                          disabled={!this.state.countryId || !!isEditForm}
                          displayEmpty
                          renderValue={
                            this.state.areaId ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.area'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.areaId || ''}
                          onChange={(event) => this.changeArea(event)}
                        >
                          {this.state.listArea?.map((item, idx) => {
                            return (
                              <MenuItem value={item?.id} key={idx} className={!item?.enable ? 'disable-option-custom' : ''}>
                                {t(`${item?.name}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('areaId', this.state.areaId, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.areaId, 'required') && (
                          <FormHelperText id="areaId" error>
                            {t('validation.required.choose', {field: t('common.area')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Geofence */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('oneTimePrice.geofence')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={10} className="grid_title_padding">
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'geofenceId',
                          }}
                          disabled={!this.state.areaId || !!isEditForm}
                          displayEmpty
                          renderValue={
                            this.state.geofenceId ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('oneTimePrice.geofence'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.geofenceId}
                          onChange={(event) => this.changeGeofence(event)}
                        >
                          {(!this.props.match.params.id && isRoleBusiness() ?
                            listGeofence.filter((item1) => principal.geofence_ids.includes(item1.geofence_id)) :
                            listGeofence)?.map((item, idx) => {
                            return (
                              <MenuItem value={item.geofence_id} key={idx} className={!item.enable ? 'disable-option-custom' : ''}>
                                {t(`${item.name}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.state.geofenceId && listType.filter((item) => !item.enable).length === 2 && (
                          <span style={{color: '#3f51b5', paddingTop: 10, fontSize: 14}}>{t('jit.geofence_no_mode')}</span>
                        )}
                        {this.validator.message('geofenceId', this.state.geofenceId, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.geofenceId, 'required') && (
                          <FormHelperText id="geofenceId" error>
                            {t('validation.required.choose', {field: t('oneTimePrice.geofence')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Vehicle mode */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessVehicle.vehicle_mode')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={10} className="grid_title_padding">
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                        <RadioGroup
                          aria-label="vehicle_mode"
                          row
                          value={this.state.vehicleMode}
                          onChange={(event) => this.setState({vehicleMode: event.target.value}, this.processRouteAfterChangeVehicleMode())}
                        >
                          {this.state.geofence && this.state.geofence?.vehicle_modes?.length > 0 ? (
                            this.state.geofence?.vehicle_modes?.map((item, index) =>
                              <FormControlLabel disabled={!!isEditForm} key={index} value={item} control={<Radio />} label={item} />,
                            )
                          ) : (
                            <FormControlLabel disabled={!!isEditForm} value="driving" control={<Radio />} label="driving" />
                          )}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Type */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.type')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={10} className="grid_title_padding">
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_350">
                        {listType.map((item, index) => (
                          <FormControlLabel
                            key={index}
                            labelPlacement="end"
                            style={{marginRight: 20}}
                            disabled={!item.enable || (isEditForm && !permission.canUpdate)}
                            control={
                              <Checkbox key={index} checked={this.state.type.includes(item.id)} onChange={(event) => this.changeType(event, item)} className="checkbox_radio" />
                            }
                            label={t(`${item.i18n}`)}
                          />
                        ))}
                        {this.validator.message('type', this.state.type, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.type, 'required') && (
                          <FormHelperText id="type" error>
                            {t('validation.required.choose', {field: t('business_plan.type')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* only old app */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="grid_title_padding font_color_red pre_line">
                      {t('jit.noteSetting')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('jit.onlyForOldApp')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={10} className="grid_title_padding">
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_350">
                        <FormControlLabel
                          key={''}
                          labelPlacement="end"
                          style={{marginRight: 20}}
                          disabled={isEditForm && !permission.canUpdate}
                          control={
                            <Checkbox
                              name="only_app"
                              value={this.state.only_app}
                              checked={this.state.only_app}
                              onChange={(event) => this.setState({only_app: event.target.checked})}
                              className="checkbox_radio"
                            />
                          }
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Manual setting route */}
                  {this.state.type.map((item, index) => (
                    <React.Fragment key={index}>
                      <Grid container spacing={1} className="row_form_item">
                        <Grid
                          container
                          alignItems="center"
                          item
                          xs={12}
                          lg={12}
                          className="product_group_table_header_green font_color_white"
                          style={{
                            fontSize: 15,
                            paddingTop: 12,
                            paddingBottom: 12,
                            paddingLeft: 20,
                            paddingRight: 20,
                          }}
                        >
                          {t(`${MOBI_TYPES.find((type) => type.id === item)?.i18n}`)}
                        </Grid>
                      </Grid>

                      {/* Pickup/Dropoff Location */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                          {t('jit.pickup')} / {t('jit.dropoff')} <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={9} style={{marginLeft: -100}} className="grid_title_padding">
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Autocomplete
                              name="pickupLocation"
                              margin="dense"
                              disabled={!this.state.listTransitStops[index] || (isEditForm && !permission.canUpdate)}
                              options={
                                (this.state.type[index] === 'SHUTTLE_BUS_JIT_WORK_TO_HOME' ?
                                  this.state.listTransitStops[index].filter((item) => item.is_work) :
                                  this.state.listTransitStops[index].filter((item) => !item.is_work)
                                ).filter((item) => item !== this.state.dropoffLocation[index]) || []
                              }
                              getOptionLabel={(option) => {
                                return option.hasOwnProperty('displayName') ? option.displayName : '';
                              }}
                              value={this.state.pickupLocation[index] || {}}
                              onChange={async (event, location) => {
                                const {pickupLocation, dropoffLocation, listTransitStopsForRoute, pickup_stop_ids, dropoff_stop_ids} = this.state;
                                listTransitStopsForRoute[index] = [];
                                pickupLocation[index] = location ? location : {};
                                pickup_stop_ids[index] = location ? [location.willer_stop_id] : [];
                                if (Object.keys(dropoffLocation[index]).length !== 0) dropoff_stop_ids[index] = [dropoffLocation[index].willer_stop_id];
                                await this.setState({pickupLocation, listTransitStopsForRoute, pickup_stop_ids, dropoff_stop_ids});
                                this.processListPointOnRoute(index);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  margin="dense"
                                  placeholder={t('placeholder.required', {field: t('reservationManagement.pickup_location')})}
                                />
                              )}
                            />
                            {this.state.isSubmitForm && (!this.state.pickupLocation[index] || Object.keys(this.state.pickupLocation[index]).length === 0) && (
                              <FormHelperText id="pickupLocation" error>
                                {t('validation.required', {field: t('reservationManagement.pickup_location')})}
                              </FormHelperText>
                            )}
                          </FormControl>
                          <ForwardIcon style={{fontSize: 35, marginLeft: 15, marginRight: 15}}></ForwardIcon>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Autocomplete
                              name="dropoffLocation"
                              margin="dense"
                              disabled={!this.state.listTransitStops[index] || (isEditForm && !permission.canUpdate)}
                              options={
                                (this.state.type[index] === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ?
                                  this.state.listTransitStops[index].filter((item) => item.is_work) :
                                  this.state.listTransitStops[index].filter((item) => !item.is_work)
                                ).filter((item) => item !== this.state.pickupLocation[index]) || []
                              }
                              getOptionLabel={(option) => {
                                return option.hasOwnProperty('displayName') ? option.displayName : '';
                              }}
                              value={this.state.dropoffLocation[index] || {}}
                              onChange={async (event, location) => {
                                const {pickupLocation, dropoffLocation, listTransitStopsForRoute, pickup_stop_ids, dropoff_stop_ids} = this.state;
                                listTransitStopsForRoute[index] = [];
                                dropoffLocation[index] = location ? location : {};
                                dropoff_stop_ids[index] = location ? [location.willer_stop_id] : [];
                                if (Object.keys(pickupLocation[index]).length !== 0) pickup_stop_ids[index] = [pickupLocation[index].willer_stop_id];
                                this.setState({dropoffLocation, listTransitStopsForRoute, pickup_stop_ids, dropoff_stop_ids});
                                this.processListPointOnRoute(index);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  margin="dense"
                                  placeholder={t('placeholder.required', {field: t('reservationManagement.dropoff_location')})}
                                />
                              )}
                            />
                            {this.state.isSubmitForm && (!this.state.dropoffLocation[index] || Object.keys(this.state.dropoffLocation[index]).length === 0) && (
                              <FormHelperText id="dropoffLocation" error>
                                {t('validation.required', {field: t('reservationManagement.dropoff_location')})}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Route Name */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                          {t('jit.route_name')} <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={9} style={{marginLeft: -100}} className="grid_title_padding">
                          <FormControl error>
                            <div className="box-group-input">
                              <TextField
                                className="field_size_10 field_min_size_350"
                                margin="dense"
                                inputProps={{
                                  name: 'routeName',
                                  maxLength: 255,
                                }}
                                disabled={isEditForm && !permission.canUpdate}
                                placeholder={t('placeholder.required', {field: t('jit.route_name')})}
                                variant="outlined"
                                onChange={(event) => {
                                  const routeName = [...this.state.routeName];
                                  routeName[index] = event.target.value;
                                  this.setState({routeName});
                                }}
                                value={this.state.routeName[index]}
                              />
                              <div className="max-length-label">{t('validation.max.label', {value: 255})}</div>
                            </div>
                            {this.validator.message(`routeName${index}`, this.state.routeName[index].trim(), 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.routeName[index].trim(), 'required') && (
                              <FormHelperText id={`routeName${index}`} error>
                                {t('validation.required', {field: t('jit.route_name')})}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Status */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                          {t('common.status')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={9} style={{marginLeft: -100}} className="grid_title_padding">
                          <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_200">
                            <Switch
                              checked={this.state.enable[index]}
                              disabled={isEditForm && !permission.canUpdate}
                              onChange={(event) => {
                                const {enable} = this.state;
                                enable[index] = event.target.checked;
                                this.setState({enable});
                              }}
                              name="checkedB"
                              color="primary"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* List pick up points */}
                      <Grid container spacing={1} className="row_form_item" style={{paddingTop: 6, paddingBottom: 6}}>
                        <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                          {t('jit.list_pickup_point')}
                        </Grid>
                        {this.state.listTransitStops[index].length > 0 && (
                          <Grid container alignItems="center" item xs={6} lg={9} style={{marginLeft: -75}}>
                            <FormGroup variant="outlined" margin="dense">
                              <Grid container alignItems="center" item xs={12}>
                                {this.state.listTransitStops[index]
                                  .filter((transitStop) =>
                                    transitStop !== this.state.pickupLocation[index] &&
                                    transitStop !== this.state.dropoffLocation[index] &&
                                    (this.state.type[index] === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' ? !transitStop.is_work : transitStop.is_work),
                                  )
                                  .map((transitStop, idx) => {
                                    const disabled =
                                      !this.state.pickupLocation[index] ||
                                      !this.state.dropoffLocation[index] ||
                                      Object.keys(this.state.pickupLocation[index]).length === 0 ||
                                      Object.keys(this.state.dropoffLocation[index]).length === 0 ||
                                      transitStop.name === this.state.pickupLocation[index].name ||
                                      transitStop.name === this.state.dropoffLocation[index].name;
                                    return (
                                      <FormControlLabel
                                        key={idx}
                                        labelPlacement="end"
                                        style={{marginRight: 20}}
                                        control={
                                          <Checkbox
                                            key={idx}
                                            color="primary"
                                            disabled={disabled || (isEditForm && !permission.canUpdate)}
                                            checked={this.state.listTransitStopsForRoute[index].includes(transitStop)}
                                            onChange={() => this.chooseWaypoint(transitStop, index)}
                                            className="checkbox_radio"
                                          />
                                        }
                                        label={transitStop.name_translations[currentLanguage] || transitStop.display_name || transitStop.street_translations[currentLanguage] || transitStop.name}
                                      />
                                    );
                                  })}
                              </Grid>
                            </FormGroup>
                          </Grid>
                        )}
                      </Grid>

                      {/* List drop off points */}
                      <Grid container spacing={1} className="row_form_item" style={{paddingTop: 6, paddingBottom: 6}}>
                        <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                          {t('jit.list_dropoff_point')}
                        </Grid>
                        {this.state.listTransitStops[index].length > 0 && (
                          <Grid container alignItems="center" item xs={6} lg={9} style={{marginLeft: -75}}>
                            <FormGroup variant="outlined" margin="dense">
                              <Grid container alignItems="center" item xs={12}>
                                {this.state.listTransitStops[index]
                                  .filter((transitStop) =>
                                    transitStop !== this.state.pickupLocation[index] &&
                                    transitStop !== this.state.dropoffLocation[index] &&
                                    (this.state.type[index] === 'SHUTTLE_BUS_JIT_WORK_TO_HOME' ? !transitStop.is_work : transitStop.is_work),
                                  )
                                  .map((transitStop, idx) => {
                                    const disabled =
                                      !this.state.pickupLocation[index] ||
                                      !this.state.dropoffLocation[index] ||
                                      Object.keys(this.state.pickupLocation[index]).length === 0 ||
                                      Object.keys(this.state.dropoffLocation[index]).length === 0 ||
                                      transitStop.name === this.state.pickupLocation[index].name ||
                                      transitStop.name === this.state.dropoffLocation[index].name;
                                    return (
                                      <FormControlLabel
                                        key={idx}
                                        labelPlacement="end"
                                        style={{marginRight: 20}}
                                        control={
                                          <Checkbox
                                            key={idx}
                                            color="primary"
                                            disabled={disabled || (isEditForm && !permission.canUpdate)}
                                            checked={this.state.listTransitStopsForRoute[index].includes(transitStop)}
                                            onChange={() => this.chooseWaypoint(transitStop, index)}
                                            className="checkbox_radio"
                                          />
                                        }
                                        label={transitStop.name_translations[currentLanguage] || transitStop.display_name || transitStop.street_translations[currentLanguage] || transitStop.name}
                                      />
                                    );
                                  })}
                              </Grid>
                            </FormGroup>
                          </Grid>
                        )}
                      </Grid>

                      {/* Setting Route */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="flex-start" item xs={12} className="grid_title_padding" style={{paddingTop: 15, paddingBottom: 20}}>
                          <span style={{height: 36, lineHeight: '36px', marginRight: 15}}>{t('jit.setting_route')}</span>
                          {this.state.masterDataRoute[index] && Object.keys(this.state.masterDataRoute[index]).length !== 0 && (
                            <Box display="flex" flexDirection="column">
                              <Button
                                color="primary"
                                variant="contained"
                                disabled={isEditForm && !permission.canUpdate}
                                className={isEditForm && !permission.canUpdate ? 'button_magin' : 'button_magin button_color_red'}
                                endIcon={<RenderIcon />}
                                onClick={() => this.getSwatMasterDataRoute(index)}
                                style={{marginLeft: 10}}
                              >
                                {t('jit.generate_direction')}
                              </Button>
                              {this.state.isSubmitForm && this.state.masterDataRoute[index].estimate_option === 'SWAT' && this.state.masterDataRoute[index].polyline === '' && (
                                <Box style={{paddingLeft: 10, marginTop: 8}}>
                                  <FormHelperText error>{t('jit.no_direction')}</FormHelperText>
                                </Box>
                              )}
                            </Box>
                          )}
                        </Grid>
                        <Grid container spacing={1} className="row_form_item" style={{paddingTop: 6, paddingBottom: 6}}>
                          <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                            {t('jit.estimate_option')}
                          </Grid>
                          {this.state.masterDataRoute[index] && Object.keys(this.state.masterDataRoute[index]).length !== 0 && (
                            <Grid container alignItems="center" item xs={6} lg={9} style={{marginLeft: -75}}>
                              <RadioGroup row name="estimate_option">
                                {JIT_ESTIMATE_OPTION?.map(({value, i18n}, idx) => {
                                  return (
                                    <FormControlLabel
                                      value={value}
                                      key={idx}
                                      onChange={(event) => this.onChangeEstimateOption(this, this.state.masterDataRoute[index], 'estimate_option', index, event.target.value)}
                                      control={
                                        <Radio
                                          className="checkbox_radio"
                                          checked={this.state.masterDataRoute[index]?.estimate_option === value}
                                        />
                                      }
                                      label={<span className="font_size_small_regular">{t(`${i18n}`)}</span>}
                                    />
                                  );
                                })}
                              </RadioGroup>
                            </Grid>
                          )}
                        </Grid>
                        {this.state.masterDataRoute[index] && Object.keys(this.state.masterDataRoute[index]).length !== 0 && (
                          <>
                            <Grid container alignItems="flex-start" justify="flex-start" item xs={5}>
                              <TableContainer style={{paddingLeft: '20px'}}>
                                <Table style={{width: '100%'}}>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className="width_100p">{t('common.stt')}</TableCell>
                                      <TableCell style={{width: '20vw'}}>{t('jit.route_name')}</TableCell>
                                      <TableCell className="width_100p">{t('jit.distance')}</TableCell>
                                      <TableCell className="width_100p">{t('common.action')}</TableCell>
                                      {this.state.masterDataRoute[index]?.estimate_option === 'MANUAL' && <TableCell className="width_200p">{t('jit.estimate_time_title')} <span className="font_color_red">＊</span></TableCell>}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {this.state.masterDataRoute[index]?.routes?.length > 0 &&
                                      this.state.masterDataRoute[index]?.routes?.map((item, idx) => (
                                        <TableRow key={idx}>
                                          <TableCell>{idx + 1}</TableCell>
                                          <TableCell className={this.state.pickup_stop_ids[index]?.length - 1 === idx ? 'font_color_red': ''}>{`${item.startPoint?.displayName}  --->  ${item.endPoint?.displayName}`}</TableCell>
                                          <TableCell className={this.state.pickup_stop_ids[index]?.length - 1 === idx ? 'font_color_red': ''}>{item.distance ? `${Math.round(item.distance / 100) / 10} km` : DATA_NULL}</TableCell>
                                          <TableCell>
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="button_color button_color_info"
                                              disabled={!item.polyline}
                                              onClick={async () =>
                                                this.setState({
                                                  openPopupMapSettingRoute: true,
                                                  pointDrawingRoute: [item.startPoint, item.endPoint],
                                                  polylineDrawingRoute: item.polyline,
                                                })
                                              }
                                            >
                                              {t('common.btnDetail')}
                                            </Button>
                                          </TableCell>
                                          { this.state.masterDataRoute[index]?.estimate_option === 'MANUAL' && (
                                            <TableCell>
                                              <TextField
                                                name="estimate_time"
                                                className="width_100p"
                                                variant="outlined"
                                                placeholder={t('placeholder.required', {field: t('jit.estimate_time')})}
                                                onKeyDown={preventInvalidChars}
                                                onChange={(event) => this.onChangeListData(this, this.state.masterDataRoute[index].routes, 'estimate_time', idx, event.target.value)}
                                                value={item.estimate_time || item.estimate_time === 0 ? item.estimate_time : ''}
                                              />
                                              <div>
                                                {this.validator.message(`master${[index]}.${item.estimate_time}.${idx}`, item.estimate_time, 'required')}
                                                {this.state.isSubmitForm && !this.validator.check(item.estimate_time, 'required') && (
                                                  <FormHelperText id="estimate_time" error>
                                                    {t('validation.required', {field: t('jit.estimate_time')})}
                                                  </FormHelperText>
                                                )}
                                              </div>
                                              {this.state.pickup_stop_ids[index]?.length - 1 === idx && (
                                                <div>
                                                  {this.validator.message(`min.master${[index]}.${item.estimate_time}.${idx}`, item.estimate_time, 'min:1,num')}
                                                  {this.state.isSubmitForm && this.validator.check(item.estimate_time, 'required') && !this.validator.check(item.estimate_time, 'min:1,num') && (
                                                    <FormHelperText id="estimate_time" error>
                                                      {t('jit.estimate_time.min.workToHome')}
                                                    </FormHelperText>
                                                  )}
                                                </div>
                                              )}
                                            </TableCell>
                                          )}
                                        </TableRow>
                                      ))}
                                  </TableBody>
                                </Table>
                                <p>
                                  GOOGLE {t('jit.duration')}: {this.state.totalDuration[index].google ? this.processDuration(this.state.totalDuration[index].google) : DATA_NULL}
                                </p>
                                <p>
                                  SWAT {t('jit.duration')}: {this.state.totalDuration[index].swat ? this.processDuration(this.state.totalDuration[index].swat) : DATA_NULL}
                                </p>
                                {this.state.masterDataRoute[index]?.estimate_option === 'MANUAL' && (
                                  <p>{t('jit.estimate_time.duration')}: {this.totalTimeEstimateDuration(this.state.masterDataRoute[index].routes) ? this.processDuration(this.totalTimeEstimateDuration(this.state.masterDataRoute[index].routes)) : DATA_NULL}</p>
                                )}
                                {this.state.totalDuration[index].swat > 0 && this.checkDurationDifferent(index) && <p style={{color: '#3f51b5'}}>{t('jit.20_different')}</p>}
                              </TableContainer>
                            </Grid>
                            <Grid container alignItems="flex-start" justify="flex-start" item xs={7}>
                              <MapSettingRoute
                                getGoogleDuration={(googleDuration) => this.getGoogleDuration(googleDuration, index)}
                                dataRoute={{
                                  type: 'baseMap',
                                  pickupLocation: this.state.pickupLocation[index],
                                  dropoffLocation: this.state.dropoffLocation[index],
                                  listTransitStopsForRoute: [...this.state.listTransitStopsForRoute][index],
                                  geofence: this.state.geofence,
                                  mode: this.state.type[index],
                                  routePolyline: this.state.masterDataRoute[index].polyline,
                                  callDirection: this.state.callDirection,
                                }}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </React.Fragment>
                  ))}
                </Paper>
                <br></br>

                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    {!this.state.canUpdate && (
                      <Alert severity="error" style={{fontWeight: 700}}>
                        {t('jit.no_service_notify')}
                      </Alert>
                    )}
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {!isEditForm ? (
                      <Button color="primary" variant="contained" className="button_magin button_color_green" endIcon={<AddIcon />} onClick={() => this.confirmConfig('create')}>
                        {t('common.btnRegister')}
                      </Button>
                    ) : (
                      permission.canUpdate && (
                        <Button color="primary" variant="contained" className="button_magin" endIcon={<CloudUploadIcon />} onClick={() => this.confirmConfig('update')}>
                          {t('common.btnUpdate')}
                        </Button>
                      )
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      className="button_magin button_color"
                      style={{marginLeft: 5}}
                      onClick={this.props.history.goBack}
                    >
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                </Grid>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>
          <br></br>
          {/* Modal Map Setting Route */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.openPopupMapSettingRoute}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1000',
            }}
          >
            <>
              <MapSettingRoutePopup
                dataRoute={{
                  type: 'drawRouteMap',
                  pickupLocation: {},
                  dropoffLocation: {},
                  listTransitStopsForRoute: [...this.state.pointDrawingRoute],
                  geofence: this.state.geofence,
                  routePolyline: this.state.polylineDrawingRoute,
                }}
                onClickClose={this.closeMapSettingRoutePopup}
              ></MapSettingRoutePopup>
            </>
          </Modal>
          {/* Confirm config route modal */}
          <Dialog
            open={this.state.openPopupConfirmConfig}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal
              onClickOk={this.configRoute}
              onClickCancel={this.closeConfirmConfigPopup}
              okButtonText={t('common.btnYes')}
              cancelButtonText={t('common.btnNo')}
              message={t(this.state.messageConfig)}
            ></SelectModal>
          </Dialog>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    configJitRoute: (payload, props) => dispatch(configJitRoute(payload, props)),
    getJitRouteDetail: (id) => dispatch(getJitRouteDetail(id)),
    searchAreaGeofence: (payload) => dispatch(searchAreaGeofence(payload)),
    getListGeofence: () => dispatch(getListGeofence()),
    getAreaGeofenceDetail: (uuid) => dispatch(getAreaGeofenceDetail(uuid)),
    getWaypointStops: (payload) => dispatch(getWaypointStops(payload)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
