import {api} from '../services/api';

const GET_QR_PAYMENT_ERROR_URL = 'admin/qr-payment/errors';
const GET_DETAIL_QR_PAYMENT_ERROR_URL = 'admin/qr-payment/';
const DELETE_QR_PAYMENT_ERROR_URL = 'admin/qr-payment';

export const getErrorQrPaymentApi = (payload) => {
  return api.get(GET_QR_PAYMENT_ERROR_URL, payload);
};

export const deleteErrorQrPaymentApi = (payload) => {
  return api.delete(DELETE_QR_PAYMENT_ERROR_URL, payload);
};

export const detailErrorQrPaymentApi = (id) => {
  return api.get(GET_DETAIL_QR_PAYMENT_ERROR_URL + id);
};

/**
 * searchErrorQrPaymentApi
 * @param {Object} payload
 * @return {put}
 */
export function searchErrorQrPaymentApi(payload) {
  return api.put(GET_QR_PAYMENT_ERROR_URL, payload);
}
