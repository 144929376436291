import React from 'react';

import {TextField} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {withTranslation} from 'react-i18next';

/**
 * Cart component
 */
export class AutocompleteCommon extends React.Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      value: [],
    };
  }

  /**
   * setValue
   * @param {*} val
   */
  setValue(val) {
    this.props.listIntervalBreak(val);
  }


  /**
   * shouldComponentUpdate
   * @param {*} nextProps
   * @param {*} nextState
   * @return {Boolean}
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.from === this.props.from &&
      nextProps.to === this.props.to &&
      nextProps.interval === this.props.interval &&
      JSON.stringify(nextProps.breakValue) === JSON.stringify(this.props.breakValue)
    ) {
      return false;
    }
    return true;
  }

  /**
   * convertMinuteToTime
   * @param {string} minute
   * @return {string}
   */
  convertMinuteToTime(minute) {
    return ('0' + parseInt(minute / 60)).slice(-2) + ':' + ('0' + parseInt(minute % 60)).slice(-2);
  }

  /**
   * convertTimeToMinute
   * @param {string} time
   * @return {string}
   */
  convertTimeToMinute(time) {
    return parseInt(time.split(':')[0]) * 60 + parseInt(time.split(':')[1]);
  }

  /**
   * makeTimeIntervals
   * @param {*} startTime
   * @param {*} endTime
   * @param {*} increment
   * @param {*} type
   * @return {*}
   */
  makeTimeIntervals(startTime, endTime, increment, type) {
    if (!startTime || !endTime || !increment) {
      return [];
    }
    const result = [];
    let convertStart = this.convertTimeToMinute(startTime) - (type === 'ARRIVAL' ? increment : 0);
    const convertEnd = this.convertTimeToMinute(endTime) + (type === 'DEPARTURE' ? increment : 0);
    while (convertStart <= convertEnd) {
      const temp = convertStart + increment;
      if (temp <= convertEnd) {
        result.push(this.convertMinuteToTime(convertStart) + ' - ' + this.convertMinuteToTime(temp));
      }
      convertStart = temp;
    }
    return result;
  }

  /**
   * render component
   * @return {component}
   */
  render() {
    const isDisable = this.props.disabled !== undefined ? this.props.disabled : false;
    return (
      <Autocomplete
        disabled={isDisable}
        multiple
        className="field_min_size_150"
        options={this.makeTimeIntervals(this.props.from, this.props.to, this.props.interval, this.props.type)}
        value={this.props.breakValue}
        getOptionLabel={(option) => option}
        onChange={(event, value) => this.setValue(value)}
        getOptionDisabled={(option) => this.props.breakValue.length === 5}
        renderInput={(params) => <TextField {...params} variant="standard" margin="normal" multiline={true} />}
      />
    );
  }
}

export default withTranslation('translations')(AutocompleteCommon);
