import React, {Component} from 'react';

import {Button, Card, Container, FormControl, Grid, MenuItem, Paper, Select, FormHelperText, TextField} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import {Autocomplete} from '@material-ui/lab';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import MapTrackingGPS from '../../../components/map/MapTrackingGPS';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getDetailVehicle} from '../../../stores/business_vehicles/action';
import {getAllCountryCode} from '../../../stores/common/actions';
import {getAllGeofences} from '../../../stores/geofence/action';
import {getVehicleListByGeofence, getListVehicleOnGoing, getVehicleLocation} from '../../../stores/vehicles/action';
import {isRoleCountry, isRoleGlobal} from '../../../utils/role';

/**
 * Vehicle Tracking GPS Component
 */
class Index extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      vehicleSelected: null,
      trackingOneVehicle: null,
      countryId: '',
      geofenceId: '',
      listGeofenceAll: [],
      vehicleList: [],
      onGoingSelectedVehicleList: [],
      isSubmitForm: false,
    };
    this.validator = new SimpleReactValidator();
    this.checkOnGoingVehicleTimeReload = 10 * 1000 * 60; // 10 phút
  }

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    await this.props.getAllCountryCode();
    await this.props.getAllGeofences({enable: true, page: 0, size: 100}).then(() => this.setState({listGeofenceAll: this.props.geofence?.listGeofence?.content}));
    const roleCountryId = this.props.common.principal?.country_id;
    const allGeofences = this.props.geofence?.listGeofence?.content;
    if (isRoleGlobal()) {
      this.setState({countryId: '', listGeofenceAll: allGeofences});
    } else if (isRoleCountry()) {
      this.setState({
        countryId: roleCountryId,
        listGeofenceAll: allGeofences?.filter((geo) => geo.country_id === roleCountryId),
      });
    } else {
      const roleGeofenceIds = this.props.common.principal?.geofence_ids;
      this.setState({
        countryId: roleCountryId,
        listGeofenceAll: allGeofences?.filter((geo) => roleGeofenceIds?.includes(geo.geofence_id)),
      });
    }
  }

  /**
   * componentWillUnmount
   */
  componentWillUnmount = () => {
    clearInterval(this.reCheckOnGoingVehicle);
  };

  /**
   * onChangeCountry
   * @param {*} e
   */
  onChangeCountry = (e) => {
    this.setState({
      countryId: e.target.value,
      listGeofenceAll: this.props.geofence?.listGeofence?.content.filter((item) => item.country_id === e.target.value),
      geofenceId: '',
      vehicleList: [],
      onGoingSelectedVehicleList: [],
      vehicleSelected: '',
    });
    clearInterval(this.reCheckOnGoingVehicle);
  };

  /**
   * onChangeGeofence
   * @param {*} event
   */
  onChangeGeofence = async (event) => {
    this.setState({geofenceId: event.target.value, onGoingSelectedVehicleList: []});
    this.props.getVehicleListByGeofence(event.target.value).then((response) => {
      this.setState({
        vehicleList: response,
        vehicleSelected: null,
      });
    });
    clearInterval(this.reCheckOnGoingVehicle);
  };

  /**
   * handleClickSearch
   */
  handleClickSearch = async () => {
    this.setState({isSubmitForm: true});
    if (this.validator.allValid()) {
      this.getTrackingVehicle();
      this.reCheckOnGoingVehicle = setInterval(this.getTrackingVehicle, this.checkOnGoingVehicleTimeReload);
    }
  };

  /**
   * getTrackingVehicle
   */
  getTrackingVehicle = () => {
    const {vehicleSelected, listGeofenceAll, geofenceId, vehicleList} = this.state;
    const geofenceSelected = listGeofenceAll.find((item) => item.geofence_id === geofenceId);
    const simulationIds = [
      geofenceSelected.jit_home_to_work_sim_id,
      geofenceSelected.jit_work_to_home_sim_id,
      geofenceSelected.taxi_sim_cfg_id,
      geofenceSelected.on_demand_sim_id,
    ].filter((item) => !!item) || [];
    const listVehicleSelected = (vehicleSelected ? [vehicleSelected] : vehicleList).map((item) => {
      return {
        id: item.id,
        vehicle_id: item.vehicle_id,
        registration_number: item.registration_number,
      };
    });
    this.props.getListVehicleOnGoing({status: 'ON_GOING', simulationIds}).then((response) => {
      const onGoingSelectedVehicleList = listVehicleSelected.filter((item) => response.filter((itm) => itm.vehicle_id === item.id).length > 0).map((item) => {
        return {
          ...item,
          vehicle_agent_id: response.find((itm) => itm.vehicle_id === item.id)?.vehicle_agent_id,
          coordinates: {
            lat: response.find((itm) => itm.vehicle_id === item.id)?.latitude,
            lng: response.find((itm) => itm.vehicle_id === item.id)?.longitude,
          },
        };
      });
      const vehicle_uuids = onGoingSelectedVehicleList.map((item) => item.vehicle_agent_id);
      if (vehicle_uuids.length > 0) {
        this.props.getVehicleLocation({
          vehicle_uuids: onGoingSelectedVehicleList.map((item) => item.vehicle_agent_id),
        }).then((res) => {
          const onGoingOnlineSelectedVehicleList = onGoingSelectedVehicleList.filter(
            (item) => res.filter((itm) => itm.vehicle_id === item.id,
          ).length > 0).map((item) => {
            return {
              ...item,
              coordinates: {
                lat: res.find((itm) => itm.vehicle_id === item.id)?.latitude,
                lng: res.find((itm) => itm.vehicle_id === item.id)?.longitude,
              },
            };
          });
          this.setState({onGoingSelectedVehicleList: onGoingOnlineSelectedVehicleList, trackingOneVehicle: vehicleSelected});
        });
      } else this.setState({onGoingSelectedVehicleList: onGoingSelectedVehicleList, trackingOneVehicle: vehicleSelected});
    });
  }

  /**
   * render component
   * @return {HTML}
   */
  render() {
    const {t, common, geofence, vehicles} = this.props;
    const {vehicleList} = this.state;

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('businessVehicle.trackingGPSTitle')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />} onClick={this.props.history.goBack}>
                {t('common.btnReturn')}
              </Button>
            </Grid>
          </Grid>
          <br></br>
          <LoadingOverlay active={common?.isLoading || geofence?.isLoading || vehicles?.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper>
                  <Grid container spacing={1}>
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('businessVehicle.searchTitle')}
                    </Grid>
                  </Grid>

                  {/* Driver code */}
                  <Grid container spacing={1} className="table_background_color_aliceblue">
                    <Grid container alignItems="flex-start" item xs={4}>
                      <Grid container spacing={1}>
                        {/* Country */}
                        <Grid container spacing={1}>
                          <Grid container alignItems="center" item xs={4} className="grid_title_padding">
                            {t('common.country')} <span className="font_color_red">*</span>
                          </Grid>
                          <Grid container alignItems="center" item xs={8}>
                            <FormControl fullWidth variant="outlined" margin="dense">
                              <Select
                                margin="dense"
                                inputProps={{
                                  name: 'countryId',
                                }}
                                displayEmpty
                                renderValue={
                                  this.state.countryId ?
                                    undefined :
                                    () => (
                                        <div className="font-12 color-disabled">
                                          {t('placeholder.required_select', {
                                            field: t('common.country'),
                                          })}
                                        </div>
                                      )
                                }
                                value={this.state.countryId}
                                onChange={this.onChangeCountry}
                                disabled={!isRoleGlobal()}
                              >
                                {common.country_code?.map((item, idx) => {
                                  return (
                                    <MenuItem value={item.id} key={idx}>
                                      {t(`${item.country_code}`)}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                              {this.validator.message('country', this.state.countryId, 'required')}
                              {this.state.isSubmitForm && !this.validator.check(this.state.countryId, 'required') && (
                                <FormHelperText id="country" error>
                                  {t('validation.required.choose', {field: t('common.country')})}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>

                        {/* Geofence */}
                        <Grid container spacing={1}>
                          <Grid container alignItems="center" item xs={4} className="grid_title_padding">
                            {t('business_plan.geofence')} <span className="font_color_red">*</span>
                          </Grid>
                          <Grid container alignItems="center" item xs={8}>
                            <FormControl fullWidth variant="outlined" margin="dense">
                              <Select
                                margin="dense"
                                inputProps={{
                                  name: 'geofenceId',
                                }}
                                displayEmpty
                                disabled={!this.state.countryId}
                                renderValue={
                                  this.state.geofenceId ?
                                    undefined :
                                    () => (
                                        <div className="font-12 color-disabled">
                                          {t('placeholder.required_select', {
                                            field: t('common.geofence'),
                                          })}
                                        </div>
                                      )
                                }
                                value={this.state.geofenceId}
                                onChange={this.onChangeGeofence}
                              >
                                {this.state.listGeofenceAll?.map((item, idx) => {
                                  return (
                                    <MenuItem value={item.geofence_id} key={idx}>
                                      {t(`${item.name}`)}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                              {this.validator.message('geofenceId', this.state.geofenceId, 'required')}
                              {this.state.isSubmitForm && !this.validator.check(this.state.geofenceId, 'required') && (
                                <FormHelperText id="geofenceId" error>
                                  {t('validation.required.choose', {field: t('business_plan.geofence')})}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>

                        {/* Vehicle ID */}
                        <Grid container spacing={1}>
                          <Grid container alignItems="center" item xs={4} className="grid_title_padding">
                            {t('businessVehicle.code_id')}
                          </Grid>
                          <Grid container alignItems="center" item xs={8}>
                            <FormControl variant="outlined" margin="dense" fullWidth>
                              <Autocomplete
                                name="pickupLocation"
                                margin="dense"
                                options={vehicleList}
                                getOptionLabel={(option) => {
                                  return option.hasOwnProperty('vehicle_id') && option.hasOwnProperty('registration_number') ?
                                    `${option.vehicle_id} - ${option.registration_number}` :
                                    '';
                                }}
                                value={this.state.vehicleSelected || {}}
                                onChange={async (event, vehicle) => {
                                  this.setState({vehicleSelected: vehicle || null});
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="outlined"
                                    margin="dense"
                                    placeholder={t('placeholder.required_select', {field: t('businessVehicle.code_id')})}
                                  />
                                )}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>

                        <Grid container alignItems="center" justify="flex-end" item xs={12} className="grid_title_padding">
                          <Button color="primary" variant="contained" className="button_margin" endIcon={<SearchIcon />} onClick={this.handleClickSearch}>
                            {t('common.btnSearch')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* Real time location */}
                    <Grid className="map_display_inline" item sm={12} xs={8} xl={8} lg={8}>
                      <MapTrackingGPS
                        trackingVehicleList={this.state.onGoingSelectedVehicleList}
                        geofenceSelected={this.state.listGeofenceAll.find((item) => item.geofence_id === this.state.geofenceId)}
                        trackingOneVehicle={this.state.trackingOneVehicle}
                        vehicleList={vehicleList}
                      />
                    </Grid>
                  </Grid>
                </Paper>
                <br></br>
              </Container>
            </Card>
          </LoadingOverlay>
          <br />
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    geofence: state.geofence,
    vehicles: state.vehicles,
    vehicleList: state.vehicles.vehicleListByGeofence,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getAllGeofences: (payload) => dispatch(getAllGeofences(payload)),
    getVehicleListByGeofence: (params, props) => dispatch(getVehicleListByGeofence(params, props)),
    getDetailVehicle: (params, props) => dispatch(getDetailVehicle(params, props)),
    getListVehicleOnGoing: (payload) => dispatch(getListVehicleOnGoing(payload)),
    getVehicleLocation: (payload) => dispatch(getVehicleLocation(payload)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
