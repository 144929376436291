import React, {Component} from 'react';

import {Paper, Container, Button, Grid, Checkbox} from '@material-ui/core';
import BlockIcon from '@material-ui/icons/Block';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/ErrorOutlined';
import HelpIcon from '@material-ui/icons/Help';
import {withTranslation} from 'react-i18next';
/**
 * PreUnsubscribe Modal
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      checkedSendMail: false,
      canUnsubscribe: false,
    };
  }

  /**
   * handleChangeCheckbox
   */
  handleChangeCheckbox = () => {
    this.setState({checkedSendMail: !this.state.checkedSendMail});
  };

  /**
   * handleClickOk
   */
  handleClickOk = () => {
    this.props.confirmCancelMember(this.state.checkedSendMail);
  }

  /**
   * handleClickCancel
   */
  handleClickCancel = () => {
    this.props.onClickCancel();
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    const preUnsubscribeStatus = this.props.preUnsubscribeStatus;
    if (!preUnsubscribeStatus.current_booking && preUnsubscribeStatus.current_subscriptions.length === 0) this.setState({canUnsubscribe: true});
  }

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {t, preUnsubscribeStatus} = this.props;

    return (
      <Paper>
        <Container maxWidth='xs'>
          <br />
          <Grid container spacing={3}>
            <Grid container alignItems="center" justify="flex-end" item xs={2}>
              {this.state.canUnsubscribe ? (<HelpIcon className="errorinfo" fontSize="large" />) : (<ErrorIcon className="errorinfo" fontSize="large" />)}
            </Grid>
            <Grid container alignItems="center" justify="flex-start" item xs={10}>
              <span className="font_size_small_regular" style={{whiteSpace: 'pre-line'}}>
                {this.state.canUnsubscribe ? t('updateMember.cancelModal') : t('memberManagement.can_not_cancel')}
              </span>
            </Grid>
            {this.state.canUnsubscribe && (
              <>
                <Grid container alignItems="center" justify="flex-start" item style={{paddingBottom: 0, marginLeft: 5}} xs={12}>
                  <Checkbox checked={this.state.checkedSendMail} onChange={this.handleChangeCheckbox} color="primary" id="checkedSendMail" inputProps={{'aria-label': 'primary'}} />
                  <label>{t('reservationManagement.send_mail')}</label>
                </Grid>
              </>
            )}
            {preUnsubscribeStatus.current_booking && (
              <Grid container alignItems="center" justify="flex-start" item xs={12}>
                <span className="font_size_small_regular" style={{whiteSpace: 'pre-line'}}>
                  {t('memberManagement.on_booking')}: <span style={{color: '#3f51b5'}}>{preUnsubscribeStatus.current_booking?.reservation_number}</span>
                </span>
              </Grid>
            )}
            {preUnsubscribeStatus.current_subscriptions.length > 0 && (
              <>
                <Grid container alignItems="center" justify="flex-start" item xs={12}>
                  <span className="font_size_small_regular" style={{whiteSpace: 'pre-line'}}>
                    {t('memberManagement.on_subscription')}:
                  </span>
                </Grid>
                {preUnsubscribeStatus.current_subscriptions.map((item, index) => (
                  <>
                    <Grid container item xs={1}>
                      <span>{index + 1}.</span>
                    </Grid>
                    <Grid container item xs={1}></Grid>
                    <Grid container alignItems="center" justify="flex-start" item xs={10}>
                      <Grid alignItems="center" item xs={12}>
                        {t('business_plan.package_name')}: <span style={{color: '#3f51b5'}}>{item.plan_name}</span>
                      </Grid>
                      <Grid alignItems="center" item xs={12}>
                        {t('businessPartner.code')}: <span style={{color: '#3f51b5'}}>{item.code}</span>
                      </Grid>
                      <Grid alignItems="center" item xs={12}>
                        {t('reservationManagement.subscriptions_main_user')}: <span style={{color: '#3f51b5'}}>{item.main_user ? t('common.true') : t('common.false')}</span>
                      </Grid>
                    </Grid>
                  </>
                ))}
              </>
            )}
            <Grid container alignItems="center" justify="center" item xs={6}>
              {this.state.canUnsubscribe && (
                <Button variant="contained" className="button_color button_color_red" color="primary" onClick={this.handleClickOk} endIcon={<BlockIcon />}>
                  {t('common.btnUnsubscribe')}
                </Button>
              )}
            </Grid>
            <Grid container alignItems="center" justify={this.state.canUnsubscribe ? 'center' : 'flex-end'} item xs={6}>
              <Button variant="contained" className="button_color" color="primary" onClick={this.handleClickCancel} endIcon={<CloseIcon />}>
                {t('common.btnCancel')}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <br />
      </Paper>
    );
  }
}

export default withTranslation('translations')(Index);
