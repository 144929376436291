import {api} from '../services/api';

const searchPartnerUrl = 'admin/partner/search';
const createPartnerUrl = 'admin/partner';
const getPartnerDetailsUrl = 'admin/partner/';
const updatePartnerUrl = `admin/partner`;
const waypointInPartnerUrl = 'admin/waypoint/select';
const searchNearestWaypointUrl = 'admin/waypoint/select/nearest';


export const searchPartnerApi = (queryParams) => {
  return api.get(searchPartnerUrl, queryParams);
};

export const createPartnerApi = (payload) => {
  return api.post(createPartnerUrl, payload);
};

export const updatePartnerApi = (payload) => {
  return api.put(updatePartnerUrl, payload);
};

export const getPartnerDetailsApi = (id) => {
  return api.get(getPartnerDetailsUrl + id);
};

export const getWaypointInPartnerApi = (payload) => {
  return api.get(waypointInPartnerUrl, payload);
};

export const getNearestWaypointInPartnerApi = (payload) => {
  return api.get(searchNearestWaypointUrl, payload);
};

export const getBankAccount = (id) => {
  if (id) {
    return api.get('admin/bank-accounts/' + id);
  }
  return api.get('admin/bank-accounts');
};
