import React, {Component} from 'react';

import {
  Card,
  Container,
  Grid,
  Button,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  TableFooter,
  TextField,
  Paper,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormHelperText,
  Select,
  Dialog,
  MenuItem,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CancelIcon from '@material-ui/icons/Cancel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {ROUTE, MONTH_NAMES, MONTH, PERMISSION_ACTIONS} from '../../../common/constant';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getDeliverySetting, getListArea, updateDeliverySetting} from '../../../stores/delivery_setting/action';
import {onChangeTextField, onChangeSelect, redirectRouter} from '../../../utils/common';
import {compareDateTimeRange} from '../../../utils/datetime';

const StyledTableRow = {
  padding: '0px!important',
  border: '1px solid rgb(121 104 104)',
  lineHeight: 1,
};
const styleTable = {
  backgroundColor: 'white',
  boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
};

/**
 * Delivery time setting
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      geo_fence_id: '',
      user_name: '',
      cMonth: new Date().getMonth() + 1,
      cYear: new Date().getFullYear(),
      delivery_calendar: [],
      acceptance_time_from: '',
      acceptance_time_to: '',
      btnRejectRow: [],
      firstDay: 0,
      flg: false,
      message: '',
      totalDay: 0,
      checkAll: true,
      isValidateCalendar: true,
      isRegister: false,
    };
    this.handleRegister = this.handleRegister.bind(this);
    this.validator = new SimpleReactValidator();
  }

  /**
   * onChangeArea
   * @param {event} event
   */
  async onChangeArea(event) {
    onChangeSelect(this, event);
    const id = event.target.value;
    this.props.history.push(ROUTE.LAYOUT + ROUTE.DELIVERY_TIME_SETTING_INFORMATION + '/' + id);
    this.initAcceptTime(id);
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    this.initAcceptTime(this.props.match.params.id);
  }

  /**
   * isValidateTime
   * @param {string} time
   * @return {boolean}
   */
  isValidateTime(time) {
    return /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(time);
  }
  /**
   * initAcceptTime
   * @param {int} geo_fence_id
   */
  initAcceptTime(geo_fence_id) {
    this.props.getListArea().then((res) => {
      if (res) {
        const current_area = res.filter((e) => e.id === geo_fence_id);
        if (current_area.length > 0 && current_area[0].delivery_accept_time) {
          const accept_time = current_area[0].delivery_accept_time.split('-');
          if (this.isValidateTime(accept_time[0]) && this.isValidateTime(accept_time[1])) {
            this.setState({
              acceptance_time_from: accept_time[0],
              acceptance_time_to: accept_time[1],
            });
          }
        }
      }
    });
    this.initCalendar(geo_fence_id, this.state.cMonth, this.state.cYear);
  }

  /**
   * checkValidCalendar
   * @return {boolean}
   */
  checkValidCalendar() {
    const {delivery_calendar, totalDay} = this.state;
    for (let i = 0; i < totalDay; i++) {
      for (let j = 0; j < delivery_calendar[i].shift_times.length; j++) {
        if (!delivery_calendar[i].shift_times[j].valid) {
          return false;
        }
      }
    }
    return true;
  }
  /**
   * handleRegisterSubmit
   */
  handleRegisterSubmit() {
    if (this.checkValidCalendar()) {
      this.setState({
        flg: true,
        message: 'messageCode.updateConfirmDeliveryTime',
      });
    }
  }

  /**
   * handleButtonOk
   * @param {object} formData
   */
  handleButtonUpdateOk = () => {
    this.setState({
      flg: false,
    });
    const {delivery_calendar, totalDay} = this.state;
    const calendar = [];
    for (let i = 0; i < totalDay; i++) {
      const shifts = [];
      for (let j = 0; j < delivery_calendar[i].shift_times.length; j++) {
        const current_day = delivery_calendar[i].day.split(' ');
        const from = current_day[0] + ' ' + delivery_calendar[i].shift_times[j].date_time_from;
        const to = current_day[0] + ' ' + delivery_calendar[i].shift_times[j].date_time_to;
        shifts.push({
          date_time_from: this.convertToTimeInJapan(from),
          date_time_to: this.convertToTimeInJapan(to),
          shift_number: delivery_calendar[i].shift_times[j].shift_number,
        });
      }
      calendar[i] = {
        id: delivery_calendar[i]['id'],
        is_active: delivery_calendar[i]['is_active'],
        shift_times: shifts,
      };
    }
    const payload = {
      delivery_day_settings: calendar,
      geofence_id: this.state.geo_fence_id,
    };
    this.props.updateDeliverySetting(payload, this.props).then(() => {
      if (this.props.match.params.id) {
        this.initAcceptTime(this.props.match.params.id);
      } else redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.DELIVERY_TIME_SETTING);
    });
  };

  /**
   * handleButtonCancel
   */
  handleButtonUpdateCancel() {
    this.setState({
      flg: false,
    });
  }

  /**
   * initCalendar
   * @param {int} id
   * @param {int} month
   * @param {int} year
   */
  initCalendar(id, month, year) {
    const payload = {
      geo_fence_id: id,
      month: month,
      year: year,
    };
    this.props.getDeliverySetting(payload, this.props).then((res) => {
      this.setState({
        cMonth: month,
        cYear: year,
        geo_fence_id: id,
      });
      this.handleInitCalendar(res || [], month, year);
    });
  }
  /**
   * handleInitCalendar
   * @param {object} deliveryCalendar
   *  @param {object} cMonth
   *  @param {object} cYear
   */
  handleInitCalendar = (deliveryCalendar, cMonth, cYear) => {
    const btnRejectRow = [];
    const calendar = [];
    const totalDay = new Date(cYear, cMonth, 0).getDate();
    const firstDay = new Date(cMonth + '-1-' + cYear).getDay() ? new Date(cMonth + '-1-' + cYear).getDay() - 1 : 6;
    if (deliveryCalendar.length > 0) {
      deliveryCalendar.forEach((day) => {
        // convert time from Japan
        const timeInJapan = new Date(day.shift_times[0].date_from);
        const utcTime = timeInJapan.toLocaleString('en-US', {timeZone: 'Asia/Tokyo'});
        day['day'] = new Date(utcTime).getDate();
      });
    }
    if (firstDay === 1 && totalDay === 28) {
      for (let i = 0; i <= 4; i++) {
        btnRejectRow[i] = [];
      }
    } else {
      for (let i = 0; i <= 5; i++) {
        btnRejectRow[i] = [];
      }
    }
    for (let i = 0; i < totalDay; i++) {
      const check = deliveryCalendar.filter((e) => e['day'] === i + 1);
      const current_day = new Date(cMonth + '/' + (i + 1) + '/' + cYear);
      const week = parseInt((i + 2 - firstDay) / 7);
      if (check.length > 0) {
        const shift = [];
        check[0].shift_times.forEach((st) => {
          shift.push({
            date_time_from: this.convertTimeFromJapan(st.date_from),
            date_time_to: this.convertTimeFromJapan(st.date_to),
            valid: true,
            shift_number: st.shift_number,
          });
          if ((current_day.getDay() === 1 || i === 0) && st.shift_number > 0) {
            btnRejectRow[week].push(st.shift_number);
          }
        });
        shift.sort((a, b) => a.shift_number - b.shift_number);
        calendar.push({
          is_active: check[0].is_active,
          day: current_day.toLocaleString('ja-JP'),
          id: check[0].id,
          shift_times: shift,
        });
      } else {
        calendar.push({
          day: current_day.toLocaleString('ja-JP'),
          shift_times: [
            {
              date_time_from: '00:00',
              date_time_to: '00:00',
              shift_number: 0,
              valid: false,
            },
          ],
          is_active: true,
        });
      }
    }
    this.setState({
      delivery_calendar: calendar,
      firstDay: firstDay,
      btnRejectRow: btnRejectRow,
      totalDay: totalDay,
    });
  };
  /**
   * convertTimeFromJapan
   * @param {string} time
   * @return {string}
   */
  convertTimeFromJapan(time) {
    const timeOffset = new Date().getTimezoneOffset() + 540;
    const dateTime = new Date(time);
    dateTime.setHours(dateTime.getHours() + timeOffset / 60);
    return ('0' + dateTime.getHours()).slice(-2) + ':' + ('0' + dateTime.getMinutes()).slice(-2);
  }

  /**
   * convertToTimeInJapan
   * @param {string} time
   * @return {datetime}
   */
  convertToTimeInJapan(time) {
    // 540 is time offset in japan
    const timeOffset = new Date().getTimezoneOffset() + 540;
    const dateTime = new Date(time);
    dateTime.setHours(dateTime.getHours() - timeOffset / 60);
    return new Date(dateTime).toISOString();
  }

  /**
   * changeMonth
   * @param {Object} value
   */
  changeMonth = (value) => {
    this.setState({
      cMonth: value,
    });
    this.initCalendar(this.state.geo_fence_id, value, this.state.cYear);
  };

  /**
   * changeYear
   * @param {Object} value
   */
  changeYear = (value) => {
    this.setState({
      cYear: value,
    });
    // validate year
    if (/^\d{4}$/.test(value)) {
      this.initCalendar(this.state.geo_fence_id, this.state.cMonth, value);
    }
  };
  /**
   *   handleChangeMonth
   *   @param {string} type
   */
  handleChangeMonth(type) {
    let cMonth = this.state.cMonth;
    let cYear = this.state.cYear;
    if (type === 'next') {
      if (cMonth === 12) {
        cMonth = 1;
        cYear = cYear + 1;
      } else {
        cMonth = cMonth + 1;
      }
    } else {
      if (cMonth === 1) {
        cMonth = 12;
        cYear = cYear - 1;
      } else {
        cMonth = cMonth - 1;
      }
    }
    this.initCalendar(this.state.geo_fence_id, cMonth, cYear);
  }
  /**
   *   handleRegister
   */
  handleRegister() {
    this.setState({
      isRegister: true,
    });
    if (compareDateTimeRange(this.state.acceptance_time_from, this.state.acceptance_time_to, false)) {
      const {totalDay, delivery_calendar, acceptance_time_from, acceptance_time_to} = this.state;
      for (let i = 0; i < totalDay; i++) {
        for (let j = 0; j < delivery_calendar[i].shift_times.length; j++) {
          delivery_calendar[i].shift_times[j].date_time_from = acceptance_time_from;
          delivery_calendar[i].shift_times[j].date_time_to = acceptance_time_to;
          delivery_calendar[i].shift_times[j].valid = true;
        }
      }
      this.setState({
        delivery_calendar: delivery_calendar,
      });
    }
  }
  /**
   *  handleAddClick
   * @param {Object} index
   */
  handleAddClick = (index) => {
    const {firstDay, totalDay, delivery_calendar, btnRejectRow} = this.state;
    if (index === 0) {
      const lengthItemRow = btnRejectRow[0].length;
      btnRejectRow[0].push(lengthItemRow + 1);
      for (let j = firstDay; j < 7; j++) {
        delivery_calendar[j - firstDay].shift_times.push({
          date_time_from: '00:00',
          date_time_to: '00:00',
          shift_number: delivery_calendar[j].shift_times.length,
          valid: false,
        });
      }
    } else {
      const fistDayWeek = 7 - firstDay - 1 + 7 * (index - 1) + 1;
      const lengthItemRow = btnRejectRow[index].length;
      btnRejectRow[index].push(lengthItemRow + 1);
      for (let j = fistDayWeek; j < fistDayWeek + 7; j++) {
        if (j < totalDay) {
          delivery_calendar[j].shift_times.push({
            date_time_from: '00:00',
            date_time_to: '00:00',
            shift_number: delivery_calendar[j].shift_times.length,
            valid: false,
          });
        }
      }
    }
    this.setState({
      delivery_calendar: delivery_calendar,
    });
  };
  /**
   *  handleClearClick
   * @param {Object} row
   * @param {Object} index
   */
  handleClearClick = (row, index) => {
    const {firstDay, totalDay, delivery_calendar, btnRejectRow} = this.state;
    const clear = btnRejectRow[row].indexOf(index);
    if (row === 0) {
      btnRejectRow[0].splice(clear, 1);
      for (let j = firstDay; j < 7; j++) {
        delivery_calendar[j - firstDay].shift_times.splice(clear + 1, 1);
      }
    } else {
      const fistDayWeek = 7 - firstDay - 1 + 7 * (row - 1) + 1;
      btnRejectRow[row].splice(clear, 1);
      for (let j = fistDayWeek; j < fistDayWeek + 7; j++) {
        if (j < totalDay) {
          delivery_calendar[j].shift_times.splice(clear + 1, 1);
        }
      }
    }
    this.setState({
      delivery_calendar: delivery_calendar,
    });
  };

  /**
   * onChangeCheckbox
   * @param {object} index
   */
  onChangeCheckbox = (index) => {
    const delivery_calendar = this.state.delivery_calendar;
    delivery_calendar[index].is_active = !this.state.delivery_calendar[index].is_active;
    this.setState({
      delivery_calendar: delivery_calendar,
    });
  };

  /**
   * changeTimeShiftFrom
   * @param {object} row
   * @param {object} index
   * @param {object} event
   */
  changeTimeShiftFrom = (row, index, event) => {
    const delivery_calendar = this.state.delivery_calendar;
    const check = this.isValidateTime(event.target.value);
    const to = delivery_calendar[row].shift_times[index].date_time_to;
    if (to > event.target.value && check) {
      delivery_calendar[row].shift_times[index].valid = true;
    } else {
      delivery_calendar[row].shift_times[index].valid = false;
    }

    delivery_calendar[row].shift_times[index].date_time_from = event.target.value;
    this.setState({
      delivery_calendar: delivery_calendar,
    });
  };

  /**
   * changeTimeShiftTo
   * @param {object} row
   * @param {object} index
   * @param {object} event
   */
  changeTimeShiftTo = (row, index, event) => {
    const delivery_calendar = this.state.delivery_calendar;
    const check = this.isValidateTime(event.target.value);
    const from = delivery_calendar[row].shift_times[index].date_time_from;
    if (from < event.target.value && check) {
      delivery_calendar[row].shift_times[index].valid = true;
    } else {
      delivery_calendar[row].shift_times[index].valid = false;
    }

    delivery_calendar[row].shift_times[index].date_time_to = event.target.value;
    this.setState({
      delivery_calendar: delivery_calendar,
    });
  };
  /**
   * render component
   * @return {component}
   */
  render() {
    const {deliverySetting, t, actions} = this.props;
    const {firstDay, delivery_calendar, totalDay, btnRejectRow} = this.state;
    const row1 = [];
    const row = [];
    if (delivery_calendar) {
      // total number of days a week 1
      const totalRow1 = 7 - firstDay;
      // total week
      const totalRow = (totalDay - totalRow1) / 7;
      const clear = btnRejectRow.length > 0 ? btnRejectRow[0] : [];
      row1.push(
          <TableCell>
            <br></br>
            <br></br>
            <AddIcon className="cursor_pointer" onClick={() => this.handleAddClick(0)} />
            <br></br>
            <br></br>
            {clear.map((index) => (
              <>
                <CancelIcon className="cursor_pointer" onClick={() => this.handleClearClick(0, index)} />
                <br></br>
                <br></br>
              </>
            ))}
          </TableCell>,
      );
      for (let i = 0; i < this.state.firstDay; i++) {
        row1.push(<TableCell align="center" style={StyledTableRow} key={i}></TableCell>);
      }
      let i = 0;
      for (let j = firstDay; j < 7; j++) {
        const shifts = delivery_calendar[j - firstDay] ? delivery_calendar[j - firstDay].shift_times : [];
        row1.push(
            <TableCell align="center" style={StyledTableRow} key={j}>
              <strong>{i + 1}</strong>
              <br></br>
              <br></br>
              {shifts.map((shift, index) => (
                <>
                  <TextField
                    label={t('vehicles.from')}
                    className="field_size_20 field_min_size_50"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300,
                    }}
                    value={shift.date_time_from.trim() === '' ? '00:00' : shift.date_time_from}
                    onChange={(event) => this.changeTimeShiftFrom(j - firstDay, index, event)}
                  />
                  <TextField
                    label={t('vehicles.to')}
                    className="field_size_20 field_min_size_50"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300,
                    }}
                    value={shift.date_time_to.trim() === '' ? '00:00' : shift.date_time_to}
                    onChange={(event) => this.changeTimeShiftTo(j - firstDay, index, event)}
                  />
                  <br></br>
                  {!shift.valid && (
                    <FormHelperText id="time" style={{color: 'red', textAlign: 'center'}}>
                      {t('validation.invalid.timeRange')}
                    </FormHelperText>
                  )}
                </>
              ))}
              <br></br>
              <FormControlLabel
                labelPlacement="end"
                control={<Checkbox checked={delivery_calendar[j - firstDay] ? delivery_calendar[j - firstDay].is_active : false} color="primary" />}
                onChange={() => {
                  this.onChangeCheckbox(j - firstDay);
                }}
                label={delivery_calendar[j - firstDay] ? delivery_calendar[j - firstDay].is_active + '' : false}
              />
            </TableCell>,
        );
        i++;
      }
      for (let j = 0; j < totalRow; j++) {
        row[j] = [];
        const clear = btnRejectRow.length > 0 ? btnRejectRow[j + 1] : [];
        row[j].push(
            <TableCell>
              <br></br>
              <br></br>
              <AddIcon className="cursor_pointer" onClick={() => this.handleAddClick(j + 1)} />
              <br></br>
              <br></br>
              {clear.map((index) => (
                <>
                  <CancelIcon className="cursor_pointer" onClick={() => this.handleClearClick(j + 1, index)} />
                  <br></br>
                  <br></br>
                </>
              ))}
            </TableCell>,
        );
        for (let d = 0; d < 7; d++) {
          if (i >= totalDay) {
            row[j].push(<TableCell align="center" style={StyledTableRow} key={i}></TableCell>);
          } else {
            const dayStartRow = totalRow1 + 7 * j + d;
            const shifts = delivery_calendar[dayStartRow] ? delivery_calendar[dayStartRow].shift_times : [];
            row[j].push(
                <TableCell align="center" style={StyledTableRow} key={i}>
                  <strong>{i + 1}</strong>
                  <br></br>
                  <br></br>
                  {shifts.map((shift, index) => (
                    <>
                      <TextField
                        label={t('vehicles.from')}
                        className="field_size_10 field_min_size_50"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300,
                        }}
                        value={shift.date_time_from.trim() === '' ? '00:00' : shift.date_time_from}
                        onChange={(event) => this.changeTimeShiftFrom(dayStartRow, index, event)}
                      />
                      <TextField
                        label={t('vehicles.to')}
                        className="field_size_10 field_min_size_50"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300,
                        }}
                        value={shift.date_time_to.trim() === '' ? '00:00' : shift.date_time_to}
                        onChange={(event) => this.changeTimeShiftTo(dayStartRow, index, event)}
                      />
                      <br></br>
                      {!shift.valid && (
                        <FormHelperText id="time" style={{color: 'red', textAlign: 'center'}}>
                          {t('validation.invalid.timeRange')}
                        </FormHelperText>
                      )}
                    </>
                  ))}
                  <br></br>
                  <FormControlLabel
                    labelPlacement="end"
                    control={<Checkbox checked={delivery_calendar[dayStartRow] ? delivery_calendar[dayStartRow].is_active : false} color="primary" />}
                    onChange={() => {
                      this.onChangeCheckbox(dayStartRow);
                    }}
                    label={delivery_calendar[dayStartRow] ? delivery_calendar[dayStartRow].is_active + '' : false}
                  />
                </TableCell>,
            );
            i++;
          }
        }
      }
    }
    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.deliverySettingInformation')}</h3>
            </Grid>
          </Grid>
        </Container>
        <br></br>
        <LoadingOverlay active={deliverySetting.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
          {permission.canUpdate && (
            <Container maxWidth="xl">
              <Card raised>
                <Container maxWidth="xl">
                  <br></br>
                  <Paper className="search_table">
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                        {t('deliverySetting.collect_setting')}
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('common.geofence')}
                      </Grid>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'geo_fence_id',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.geo_fence_id ?
                              undefined :
                              () => (
                                <div className="font-12 color-disabled">
                                  {t('placeholder.required_select', {
                                    field: t('common.geofence'),
                                  })}
                                </div>
                              )
                          }
                          value={this.state.geo_fence_id}
                          disabled={this.state.checkDetail ? true : false}
                          onChange={(event) => this.onChangeArea(event)}
                        >
                          {deliverySetting?.listDeliveryArea?.map((row, index) => {
                            return (
                              <MenuItem value={row.geofence_id} key={index}>
                                {row.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid container spacing={2} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('deliverySetting.acceptance_time')}
                      </Grid>
                      <Grid container alignItems="center" item xs={4} lg={1}>
                        <TextField
                          label={t('vehicles.from')}
                          className="field_size_20 field_min_size_150"
                          id="time"
                          type="time"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 300,
                          }}
                          name="acceptance_time_from"
                          value={this.state.acceptance_time_from}
                          onChange={(event) => onChangeTextField(this, event)}
                        />
                        {/* add rule to validate */}
                        {this.validator.message('acceptance_time_from', this.state.acceptance_time_from, 'required')}
                      </Grid>
                      <Grid container alignItems="center" justify="center" item xs={2} lg={1}>
                        ~
                      </Grid>
                      <Grid container alignItems="center" item xs={4} lg={1}>
                        <TextField
                          label={t('vehicles.to')}
                          className="field_size_20 field_min_size_150"
                          id="time"
                          type="time"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 300,
                          }}
                          name="acceptance_time_to"
                          value={this.state.acceptance_time_to}
                          onChange={(event) => onChangeTextField(this, event)}
                        />
                        {/* add rule to validate */}
                        {this.validator.message('acceptance_time_to', this.state.acceptance_time_to, 'required')}
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid container item xs={6} lg={2} />
                      <Grid container item xs={6} lg={2} alignItems="center">
                        {this.state.isRegister &&
                          ((!this.validator.check(this.state.acceptance_time_from, 'required') && (
                            <FormHelperText id="time" style={{color: 'red'}}>
                              {t('validation.required', {field: t('vehicles.from')})}
                            </FormHelperText>
                          )) ||
                            (!this.validator.check(this.state.acceptance_time_to, 'required') && (
                              <FormHelperText id="time" style={{color: 'red'}}>
                                {t('validation.required', {field: t('vehicles.to')})}
                              </FormHelperText>
                            )) ||
                            (!compareDateTimeRange(this.state.acceptance_time_from, this.state.acceptance_time_to, false) && (
                              <FormHelperText id="time" style={{color: 'red'}}>
                                {t('validation.invalid.timeRange')}
                              </FormHelperText>
                            )))}
                      </Grid>
                    </Grid>
                  </Paper>
                  <br></br>
                  <Grid container spacing={1}>
                    <Grid container alignItems="center" justify="flex-start" item xs={3}>
                      {' '}
                    </Grid>
                    <Grid container alignItems="center" justify="flex-end" item xs={9}>
                      <Button color="secondary" variant="contained" className="button_margin" endIcon={<AutorenewIcon />} onClick={() => this.handleRegister()}>
                        {t('common.btnRegister')}
                      </Button>
                    </Grid>
                  </Grid>
                </Container>
                <br></br>
              </Card>
              <br></br>
            </Container>
          )}
          <br></br>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                    {t('deliverySetting.calendar_detail_setting')}
                  </Grid>
                </Grid>
                <br></br>
                <Grid container spacing={3}>
                  <Grid item xs={4} align="right">
                    <br></br>
                    <ArrowBackIosIcon className="cursor_pointer" onClick={() => this.handleChangeMonth('previous')} />
                  </Grid>
                  <Grid item xs={4} align="center">
                    <Grid container spacing={3} className="font_color_white">
                      <Grid item xs={6} align="right">
                        <Autocomplete
                          margin="dense"
                          className="field_size_10 field_min_size_150"
                          options={MONTH}
                          value={this.state.cMonth}
                          getOptionLabel={(option) => t(`${MONTH_NAMES[option - 1]}`)}
                          onChange={(event, month) => this.changeMonth(month)}
                          renderInput={(params) => <TextField {...params} name={'cMonth'} fullWidth margin="dense" label={t('calendar.month')} />}
                        />
                      </Grid>
                      <Grid item xs={6} align="left">
                        <TextField
                          className="field_size_10 field_min_size_150"
                          margin="dense"
                          value={this.state.cYear}
                          onChange={(event) => this.changeYear(event.target.value)}
                          label={t('calendar.years')}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4} align="left">
                    <br></br>
                    <ArrowForwardIosIcon className="cursor_pointer" onClick={() => this.handleChangeMonth('next')} />
                  </Grid>
                </Grid>
                <br></br>
                <LoadingOverlay active={false} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                  <br></br>
                  <div align="center">
                    <Table size="small" aria-label="a dense table" style={styleTable} className="">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" style={StyledTableRow}></TableCell>
                          <TableCell align="center" style={StyledTableRow}>
                            {t('calendar.Monday')}
                          </TableCell>
                          <TableCell align="center" style={StyledTableRow}>
                            {t('calendar.Tuesday')}
                          </TableCell>
                          <TableCell align="center" style={StyledTableRow}>
                            {t('calendar.Wednesday')}
                          </TableCell>
                          <TableCell align="center" style={StyledTableRow}>
                            {t('calendar.Thursday')}
                          </TableCell>
                          <TableCell align="center" style={StyledTableRow}>
                            {t('calendar.Friday')}
                          </TableCell>
                          <TableCell align="center" style={StyledTableRow}>
                            {t('calendar.Saturday')}
                          </TableCell>
                          <TableCell align="center" style={StyledTableRow}>
                            {t('calendar.Sunday')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>{row1}</TableRow>
                        {row.map((rows, index) => (
                          <TableRow>
                            {row[index].map((r, id) => (
                              <>{row[index][id]}</>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter></TableFooter>
                    </Table>
                  </div>
                </LoadingOverlay>
                <br></br>
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-end" item xs={12}>
                    {permission.canUpdate && (
                      <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={() => this.handleRegisterSubmit()}>
                        {t('common.btnUpdate')}
                      </Button>
                    )}
                    {/* <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.DELIVERY_TIME_SETTING}}> */}
                      <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.props.history.goBack}>
                        {t('common.btnReturn')}
                      </Button>
                    {/* </Link> */}
                  </Grid>
                </Grid>
                <Dialog
                  open={this.state.flg}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal onClickOk={this.handleButtonUpdateOk.bind(this)} onClickCancel={this.handleButtonUpdateCancel.bind(this)} message={this.state.message}></SelectModal>
                </Dialog>
                <br></br>
              </Container>
            </Card>
            <br></br>
          </Container>
        </LoadingOverlay>
        <br></br>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    deliverySetting: state.deliverySetting,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDeliverySetting: (payload, props) => dispatch(getDeliverySetting(payload, props)),
    updateDeliverySetting: (payload, props) => dispatch(updateDeliverySetting(payload, props)),
    getListArea: () => dispatch(getListArea()),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
