import React, {Component} from 'react';

import {Button, Card, Container, FormControl, FormHelperText, Grid, Paper, TextField, Dialog, Select, MenuItem, FormControlLabel, Checkbox} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SyncIcon from '@material-ui/icons/Sync';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {ROUTE, STOP_MASTER_TYPE, ROWS_PER_PAGE_OPTIONS} from '../../../common/constant';
import SelectModal from '../../../components/selectModal';
import {getStopMasterApi, syncWaypointApi, updateStopMasterApi} from '../../../services/stopMasterServices';
import {setMessageModal} from '../../../stores/modal/actions';
import {onChangeTextField, onChangeSelect, onChangeTelField} from '../../../utils/common';
import {modalObj} from '../../../utils/modal';
import withPermissionData from '../../../withPermissionData/hoc';

/**
 * Stop Master Details
 */
class StopMasterForm extends Component {
  /**
   * constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      is_work: true,
      ref_link: '',
      hotline: '',
      description: '',
      type: STOP_MASTER_TYPE[0],
      name: '',
      latitude: '',
      longitude: '',
      swat_transit_stop_id: '',
      name_translations: '',
      street_translations: '',
      result: {},

      isSubmitForm: false,
      showModal: false,
      modalMessage: '',
      isLoading: false,
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * handleUpdate
   */
  handleUpdate = async () => {
    const {is_work, ref_link, hotline, description, type, name, street_name, latitude, longitude, result} = this.state;
    const payLoad = {
      ...result,
      description: description?.trim(),
      type,
      name: name?.trim(),
      street_name: street_name?.trim(),
      is_work,
      ref_link: ref_link?.trim(),
      hotline: hotline?.trim(),
      display_name: name?.trim(),
      latitude,
      longitude,
    };
    if (this.props.match.params.id) {
      payLoad.id = this.props.match.params.id;
    }
    updateStopMasterApi(payLoad).then((res) => {
      if (res.status !== 200) {
        this.props.setMessageModal(modalObj(true, 'error.500'));
        return;
      }
      if (this.props.match.params.id) {
        this.props.setMessageModal(modalObj(true, 'api.update.stop_master.success'));
      } else {
        this.props.setMessageModal(modalObj(true, 'api.create.stop_master.success'));
      }
      this.props.history.push(ROUTE.LAYOUT + ROUTE.DATA_STOP_MASTER_MANAGEMENT + `?currentPage=0&rowsPerPage=${ROWS_PER_PAGE_OPTIONS[0]}`);
    });
    this.closeModal();
  };

  /**
   * openModal
   */
  openModal = () => {
    this.setState({isSubmitForm: true});
    if (!this.validator.allValid()) return;
    this.setState({showModal: true});
  };

  /**
   * closeModal
   */
  closeModal = () => {
    this.setState({showModal: false});
  };

  handleSync = () => {
    this.setState({isLoading: true});
    syncWaypointApi(this.state.swat_transit_stop_id)
      .then((res) => {
        this.getData();
        this.props.setMessageModal(modalObj(true, 'api.sync.stop_master.success'));
      })
      .then(() => {
        this.setState({isLoading: false});
      });
  };

  /**
   * componentDidMount
   */
  async componentDidMount() {
    if (this.props.match.params.id) {
      this.getData();
    }
  }

  getData = () => {
    getStopMasterApi(this.props.match.params.id).then((res) => {
      this.setState({
        result: res.result,
        code: res.result.code,
        is_work: res.result.is_work,
        ref_link: res.result.ref_link || '',
        hotline: res.result.hotline || '',
        description: res.result.description || '',
        type: res.result.type,
        name: res.result.name,
        id: res.result.id,
        latitude: res.result.latitude,
        longitude: res.result.longitude,
        street_name: res.result.street_name,
        swat_transit_stop_id: res.result.swat_transit_stop_id,
        name_translations: JSON.stringify(res.result?.name_translations),
        street_translations: JSON.stringify(res.result?.street_translations),
      });
    });
  };

  /**
   * Render component
   * @return {*}
   */
  render() {
    const {t} = this.props;
    const isEdit = this.props.match.params.id;

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container spacing={1} className="page_header">
            <Grid container alignItems="center" item xs={6}>
              {isEdit ? <h3>{t('stop_master.detailTitle')}</h3> : <h3>{t('stop_master.createTitle')}</h3>}
            </Grid>
          </Grid>
        </Container>
        <br></br>
        <LoadingOverlay active={this.state.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('stop_master.titleForm')}
                    </Grid>
                  </Grid>
                  {/* Code */}
                  {isEdit && (
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('stop_master.code')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl variant="outlined" margin="dense" fullWidth>
                          <TextField name="code" className="field_size_10 field_min_size_300" margin="dense" value={this.state.code} variant="outlined" disabled={true} />
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}

                  {/* name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.name')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" fullWidth>
                        <TextField
                          name="name"
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          disabled={isEdit && this.state.swat_transit_stop_id}
                          placeholder={t('validation.required', {field: t('common.name')})}
                          value={this.state.name}
                          onChange={(event) => onChangeTextField(this, event)}
                          inputProps={{maxLength: 255}}
                          variant="outlined"
                        />
                        {this.validator.message('name', this.state.name, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.name, 'required') && (
                          <FormHelperText id="name" error>
                            {t('validation.required', {field: t('common.name')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* description */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('stop_master.description')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" fullWidth>
                        <TextField
                          multiline
                          rows="3"
                          name="description"
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          placeholder={t('validation.required', {field: t('stop_master.description')})}
                          value={this.state.description}
                          onChange={(event) => onChangeTextField(this, event)}
                          inputProps={{maxLength: 255}}
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Hotline */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('stop_master.hotline')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" fullWidth>
                        <TextField
                          name="hotline"
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          onChange={(event) => onChangeTelField(this, event)}
                          placeholder={t('validation.required', {field: t('stop_master.hotline')})}
                          value={this.state.hotline}
                          inputProps={{maxLength: 255}}
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Ref Link */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('stop_master.ref_link')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" fullWidth>
                        <TextField
                          name="ref_link"
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          placeholder={t('validation.required', {field: t('stop_master.ref_link')})}
                          value={this.state.ref_link}
                          onChange={(event) => onChangeTextField(this, event)}
                          inputProps={{maxLength: 255}}
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Street Name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('stop_master.street_name')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" fullWidth>
                        <TextField
                          name="street_name"
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          disabled={isEdit && this.state.swat_transit_stop_id}
                          placeholder={t('validation.required', {field: t('stop_master.street_name')})}
                          value={this.state.street_name}
                          onChange={(event) => onChangeTextField(this, event)}
                          inputProps={{maxLength: 255}}
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Type */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('stop_master.type')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_200">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'type',
                          }}
                          disabled={isEdit && this.state.swat_transit_stop_id}
                          value={this.state.type}
                          onChange={(e) => onChangeSelect(this, e)}
                        >
                          {STOP_MASTER_TYPE.map((item, idx) => {
                            return (
                              <MenuItem value={item} key={idx}>
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('type', this.state.type, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.type, 'required') && (
                          <FormHelperText id="type" error>
                            {t('validation.required.choose', {field: t('stop_master.type')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Work */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('stop_master.work')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControlLabel
                        style={{marginRight: '30px', marginLeft: '-6px'}}
                        labelPlacement="end"
                        control={<Checkbox checked={this.state.is_work} onChange={() => this.setState({is_work: true})} className="checkbox_radio" />}
                        label={t('common.true')}
                      />
                      <FormControlLabel
                        labelPlacement="end"
                        control={<Checkbox checked={!this.state.is_work} onChange={() => this.setState({is_work: false})} className="checkbox_radio" />}
                        label={t('common.false')}
                      />
                      {this.validator.message('is_work', this.state.is_work, 'required')}
                      {this.state.isSubmitForm && !this.validator.check(this.state.is_work, 'required') && (
                        <FormHelperText id="is_work" error>
                          {t('validation.required.choose', {field: t('common.work')})}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>

                  {/* latitude */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('waypoint.latitude')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl fullWidth>
                        <TextField
                          disabled={isEdit && this.state.swat_transit_stop_id}
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          type="number"
                          inputProps={{
                            name: 'latitude',
                            maxLength: 255,
                          }}
                          placeholder={t('validation.required', {field: t('waypoint.latitude')})}
                          variant="outlined"
                          onChange={(event) => onChangeTextField(this, event)}
                          value={this.state.latitude}
                        />
                        {this.validator.message('latitude', this.state.latitude, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.latitude, 'required') && (
                          <FormHelperText id="latitude" error>
                            {t('validation.required', {field: t('waypoint.latitude')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* longitude */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('waypoint.longitude')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl fullWidth>
                        <TextField
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          type="number"
                          inputProps={{
                            name: 'longitude',
                            maxLength: 255,
                          }}
                          disabled={isEdit && this.state.swat_transit_stop_id}
                          placeholder={t('validation.required', {field: t('waypoint.longitude')})}
                          value={this.state.longitude}
                          onChange={(event) => onChangeTextField(this, event)}
                          variant="outlined"
                        />
                        {this.validator.message('longitude', this.state.longitude, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.longitude, 'required') && (
                          <FormHelperText id="longitude" error>
                            {t('validation.required', {field: t('waypoint.longitude')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {isEdit && (
                    <>
                      {/* Name translation */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('stop_master.name_translations')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" fullWidth>
                            <TextField
                              name="name"
                              className="field_size_10 field_min_size_300"
                              margin="dense"
                              disabled={true}
                              placeholder={t('validation.required', {field: t('stop_master.name_translations')})}
                              value={this.state.name_translations || ''}
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      {/* Street translation */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('stop_master.street_translations')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" fullWidth>
                            <TextField
                              name="name"
                              className="field_size_10 field_min_size_300"
                              margin="dense"
                              disabled={true}
                              placeholder={t('validation.required', {field: t('stop_master.street_translations')})}
                              value={this.state.street_translations || ''}
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Paper>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {isEdit && this.state.swat_transit_stop_id && (
                      <Button color="primary" variant="contained" className="button_margin" endIcon={<SyncIcon />} onClick={this.handleSync}>
                        {t('common.btnSync')}
                      </Button>
                    )}
                    {isEdit && (
                      <Button
                        color="primary"
                        variant="contained"
                        className="button_margin"
                        endIcon={<CloudUploadIcon />}
                        onClick={() => {
                          this.setState({modalMessage: 'stop_master.question.confirm.update'});
                          this.openModal();
                        }}
                      >
                        {t('common.btnUpdate')}
                      </Button>
                    )}
                    {!isEdit && (
                      <Button
                        color="primary"
                        variant="contained"
                        className="button_margin button_color_green"
                        endIcon={<AddIcon />}
                        onClick={() => {
                          this.setState({modalMessage: 'stop_master.question.confirm.create'});
                          this.openModal();
                        }}
                      >
                        {t('common.btnRegister')}
                      </Button>
                    )}
                    <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.props.history.goBack}>
                      {t('common.btnCancel')}
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            </Card>
            <br></br>
          </Container>
        </LoadingOverlay>
        <Dialog
          open={this.state.showModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <SelectModal onClickOk={this.handleUpdate} onClickCancel={this.closeModal} message={this.state.modalMessage}></SelectModal>
        </Dialog>
      </Card>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
  };
};

export default withPermissionData(withTranslation('translations')(connect(null, mapDispatchToProps)(StopMasterForm)));
