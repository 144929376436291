import React, {Component} from 'react';

import {Button} from '@material-ui/core';
import {GetApp as GetAppIcon} from '@material-ui/icons';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';

import {getCSVExportPermissionApi} from '../../services/commonServices';
import {setMessageModal} from '../../stores/modal/actions';
import {modalObj} from '../../utils/modal';

const SAFE_CLICK_TIME = 3000;
const COOKIE_MAX_AGE = 24 * 60 * 60 * 1000;

/**
 *
 */
class CSVExporter extends Component {
  /**
   * Component constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      disabledSafeClick: false,
    };
  }

  /**
 * handleSubmitExportCSV
 * @param {Event} event
 */
  handleSubmitExportCSV = async (event) => {
  // Prevent submit in order to set cookie
  event.preventDefault();
  const target = event.target;

  // Enable safe click
  this.setState({disabledSafeClick: true}, () => {
    setTimeout(() => {
      this.setState({disabledSafeClick: false});
    }, SAFE_CLICK_TIME);
  });

  // Check if can export csv at the moment (not if there are more than 5 export requests now)
  const res = await getCSVExportPermissionApi();
  if (res?.status !== 200) {
    this.props.setMessageModal(modalObj(true, 'reservationManagement.exportAlert.limitRequest'));
    return;
  }

  // Set cookie
  const access_token = localStorage.getItem('TOKEN_KEY');
  const domain = process.env.REACT_APP_HOST.replace('https://api', '').replace('/', '');
  const expiryDate = new Date();
  expiryDate.setTime(expiryDate.getTime() + COOKIE_MAX_AGE);
  document.cookie = `authorization=${access_token}; expires=${expiryDate}; domain=${domain}; path=/; SameSite=None; Secure`;

  // Submit export request
  target.submit();
}

/**
 * getSearchParamsForExportingCSV
 * @param {Object} params
 * @return {JSON}
 */
getSearchParamsForExportingCSV = (params) => {
  if (params && (typeof params === 'object')) {
    Object.entries(params).forEach(([key, value]) => {
      if (!value) delete params[key];
    });
    return JSON.stringify(params);
  }
  return '';
}

  /**
   * Render component
   * @return {JSX}
   */
  render() {
    const {url, params, disabled, buttonText, t} = this.props;
    const actionUrl = `${process.env.REACT_APP_HOST}api/${url}`;

    return (
      <form
        className='button_margin'
        method='POST'
        target='_blank'
        action={actionUrl}
        onSubmit={this.handleSubmitExportCSV}
      >
        <input type='hidden' name='payload' value={this.getSearchParamsForExportingCSV(params)} />
        <input type='hidden' name='language' value={localStorage.getItem('i18nextLng')} />
        <Button
          type='submit'
          color='primary'
          variant='contained'
          className='button-margin'
          disabled={disabled || this.state.disabledSafeClick}
          endIcon={<GetAppIcon className="csv-exporter-icon" />}
        >
          {buttonText ? t(buttonText) : t('calendar.csv_output')}
        </Button>
      </form>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setMessageModal: (payload) => dispatch(setMessageModal(payload)),
});

export default withTranslation('translations')(connect(null, mapDispatchToProps)(CSVExporter));
