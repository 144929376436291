export const ACTION_TYPES = {
  GET_ALL_BANKS_START: 'GET_ALL_BANKS_START',
  GET_ALL_BANKS_SUCCESS: 'GET_ALL_BANKS_SUCCESS',
  GET_ALL_BANKS_FAIL: 'GET_ALL_BANKS_FAIL',

  GET_BANK_START: 'GET_BANK_START',
  GET_BANK_SUCCESS: 'GET_BANK_SUCCESS',
  GET_BANK_FAIL: 'GET_BANK_FAIL',

  CREATE_BANK_START: 'CREATE_BANK_START',
  CREATE_BANK_SUCCESS: 'CREATE_BANK_SUCCESS',
  CREATE_BANK_FAIL: 'CREATE_BANK_FAIL',

  UPDATE_BANK_START: 'UPDATE_BANK_START',
  UPDATE_BANK_SUCCESS: 'UPDATE_BANK_SUCCESS',
  UPDATE_BANK_FAIL: 'UPDATE_BANK_FAIL',
};

const initialState = {
  isLoading: false,
  allBanks: [],
  bank: {},
};

/**
 * function reduce
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.GET_ALL_BANKS_START:
    case ACTION_TYPES.GET_BANK_START:
    case ACTION_TYPES.CREATE_BANK_START:
    case ACTION_TYPES.UPDATE_BANK_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.GET_ALL_BANKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allBanks: action.allBanks,
      };
    case ACTION_TYPES.GET_BANK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        bank: action.bank,
      };
    case ACTION_TYPES.CREATE_BANK_SUCCESS:
      case ACTION_TYPES.UPDATE_BANK_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.GET_ALL_BANKS_FAIL:
      return {
        ...state,
        isLoading: false,
        allBanks: [],
      };
    case ACTION_TYPES.GET_BANK_FAIL:
      return {
        ...state,
        isLoading: false,
        bank: {},
      };
    case ACTION_TYPES.CREATE_BANK_FAIL:
      case ACTION_TYPES.UPDATE_BANK_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
