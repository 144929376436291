import React, {Component} from 'react';

import {Button, Checkbox, Container, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, FormControl, TextField, Radio, FormHelperText} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import SearchIcon from '@material-ui/icons/Search';
import LoadingOverlay from 'react-loading-overlay';

import {ROWS_PER_PAGE_OPTIONS} from '../../../../common/constant';
import CustomPagination from '../../../../components/CustomPagination';
import {getWaypointInPartnerApi, getNearestWaypointInPartnerApi} from '../../../../services/partnerServices';
import {clearBlankValue, onChangeTextField} from '../../../../utils/common';

/**
 * Select Modal Waypoint Component
 */
export default class extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      listWaypoint: [],
      listDataView: [],
      listWaypointId: [],
      dataOld: [],
      latitude: '',
      longitude: '',
      country: '',
      geofence_id: '',
      loading: true,
      information: '',
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchByName: true,
    };
  }

  /**
   * componentDidMount Call Api
   */
  async componentDidMount() {
    this.setState({
      loading: false,
      dataOld: this.props.props.listWaypointView,
    });
  }

  /**
   * handleChecked
   * @param {*} e
   * @param {*} value
   */
  handleChecked = (e, value) => {
    const listWaypointCop = this.state.listWaypointId;
    const listDataViewCop = this.state.listDataView;
    const index = listWaypointCop.indexOf(value.id);
    value.latitude = value.lat;
    value.longitude = value.lon;
    e ? listWaypointCop.push(value.id) && listDataViewCop.push(value) : listWaypointCop.splice(index, 1) && listDataViewCop.splice(index, 1);

    this.setState({
      listWaypointId: listWaypointCop,
      listDataView: listDataViewCop,
    });
  };

  /**
   * handleSearchChecked
   * @param {event} e
   */
  handleSearchChecked = (e) => {
    if (e.target.value === 'searchByName') this.setState({searchByName: true});
    else this.setState({searchByName: false});
  }

  /**
   * preventInvalidChars
   * @param {event} e
   */
  preventInvalidChars = (e) => {
    const invalidChars = ['e', '+', ','];
    if (invalidChars.includes(e.key)) e.preventDefault();
  }

  /**
   * handleSearch
   */
  async handleSearch() {
    this.setState({loading: true});
    const payload = {
      partnerId: this.props.props.match.params.id || '',
      geofenceId: this.props.props.geofenceId,
      page: this.state.currentPage,
      size: this.state.rowsPerPage,
    };
    if (this.state.searchByName) {
      payload['searchString'] = this.state.information.trim();
      await getWaypointInPartnerApi(clearBlankValue(payload)).then((response) => {
        this.setState({
          totalRows: response?.result?.totalSize,
          listWaypoint: response?.result?.content.length > 0 ? response.result.content : [],
          listWaypointId: response?.result?.content.filter((item) => item.checked === true).map((item) => item.id),
          loading: false,
        });
      });
    } else {
      payload['latitude'] = this.state.latitude.trim();
      payload['longitude'] = this.state.longitude.trim();
      await getNearestWaypointInPartnerApi(clearBlankValue(payload)).then((response) => {
        this.setState({
          totalRows: response?.result?.totalSize,
          listWaypoint: response?.result?.content.length > 0 ? response.result.content : [],
          listWaypointId: response?.result?.content.filter((item) => item.checked === true).map((item) => item.id),
          loading: false,
        });
      });
    }
  }

  /**
   * changeCountry
   * @param {*} event
   */
  changeCountry = (event) => {
    this.setState(
        {
          country_code: this.props.props.business?.listAreaCountry?.find((item) => item.id === event.target.value)?.country_code,
          country: event.target.value,
          geofence_id: '',
        },
        () => this.props.props.getListGeofenceByCountry(event.target.value),
    );
  };

  /**
   * convertDataWaypoint
   */
  convertDataWaypoint = () => {
    const dataNew = this.covertDataNewResponse();
    this.props.onClickOk(this.state.listWaypointId, dataNew);
  }

  /**
   * covertDataNewResponse
   * @return {*}
   */
  covertDataNewResponse = () => {
    const waypointAll = [...this.state.listDataView, ...this.state.dataOld];
    const filteredArr = waypointAll?.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    return filteredArr;
  }

  /**
   * onChangePagination
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearch);
  };

  /**
   * Render
   * @return {*}
   */
  render() {
    const {loading} = this.state;
    const {t} = this.props.props;

    return (
      <LoadingOverlay active={loading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Paper className="field_min_size_700">
          <Container maxWidth="xl">
            <br />
            <Grid container spacing={3}>
              <Grid container alignItems="center" justify="flex-end" item xs={1}>
                <HelpIcon className="errorinfo" fontSize="large" />
              </Grid>
              <Grid container alignItems="center" justify="flex-start" item xs={2}>
                <span className="font_size_small_regular" style={{whiteSpace: 'pre-line'}}>
                  {t('waypoint.select')}
                </span>
              </Grid>
              <Grid container alignItems="center" item xs={5}>
                <Grid container>
                  <Grid container justify="flex-end" alignItems="center" item xs={2}>
                    <Radio checked={this.state.searchByName} value="searchByName" onChange={this.handleSearchChecked}/>
                  </Grid>
                  <Grid container alignItems="center" item xs={8}>
                    <TextField
                      fullWidth
                      margin="dense"
                      placeholder={t('businessPartner.information')}
                      inputProps={{maxLength: 500}}
                      name="information"
                      variant="outlined"
                      onChange={(event) => onChangeTextField(this, event)}
                      value={this.state.information}
                      disabled={!this.state.searchByName}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid container justify="flex-end" alignItems="center" item xs={2}>
                    <Radio checked={!this.state.searchByName} value="searchByLocation" onChange={this.handleSearchChecked}/>
                  </Grid>
                  <Grid container alignItems="center" item xs={8}>
                    <Grid container spacing={3}>
                      <Grid container justify="flex-start" alignItems="center" item xs={6}>
                        <FormControl>
                          <TextField
                            fullWidth
                            margin="dense"
                            placeholder={t('waypoint.latitude')}
                            name="latitude"
                            variant="outlined"
                            type="number"
                            onChange={(event) => onChangeTextField(this, event)}
                            onKeyDown={this.preventInvalidChars}
                            value={this.state.latitude}
                            disabled={this.state.searchByName}
                          />
                        </FormControl>
                      </Grid>
                      <Grid container justify="flex-end" alignItems="center" item xs={6}>
                        <FormControl>
                          <TextField
                            fullWidth
                            margin="dense"
                            placeholder={t('waypoint.longitude')}
                            name="longitude"
                            variant="outlined"
                            type="number"
                            onChange={(event) => onChangeTextField(this, event)}
                            onKeyDown={this.preventInvalidChars}
                            value={this.state.longitude}
                            disabled={this.state.searchByName}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    {(Math.abs(this.state.latitude) > 90 || Math.abs(this.state.longitude) > 180) && (
                      <FormHelperText id="latitude" error>
                        {t('waypoint.choose_lat_long')}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container alignItems="center" item xs={3}>
                <Button
                  color="primary"
                  variant="contained"
                  className="button_margin"
                  endIcon={<SearchIcon />}
                  disabled={
                    this.state.searchByName ?
                      !this.state.information.trim() :
                      !this.state.latitude.trim() ||
                        !this.state.longitude.trim() ||
                        (isFinite(this.state.latitude) && Math.abs(this.state.latitude) > 90) ||
                        (isFinite(this.state.longitude) && Math.abs(this.state.longitude) > 180)
                  }
                  onClick={() => this.handleSearch()}
                >
                  {t('common.btnSearch')}
                </Button>
              </Grid>
              <br></br>
              {this.state.totalRows > 0 && (
                <>
                  <Container>
                    <TableContainer component={Paper}>
                      <div className="scroll_area_500">
                        <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                          <TableHead>
                            <TableRow>
                              <TableCell align="right"></TableCell>
                              <TableCell align="right">{t('waypoint.id')}</TableCell>
                              <TableCell align="right">{t('common.name')}</TableCell>
                              <TableCell align="right">{t('waypoint.display_name')}</TableCell>
                              <TableCell align="right">{t('waypoint.street_name')}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.listWaypoint?.map((waypoint, index) => {
                              const checked = (this.state.listWaypointId.indexOf(waypoint.id) > -1) ||
                                (this.props.props.listWaypointView.map((item) => item.id).includes(waypoint.id));
                              return (
                                <TableRow key={index}>
                                  <TableCell align="right">
                                    <Checkbox
                                      checked={checked}
                                      color="primary"
                                      disabled={!waypoint.active}
                                      onChange={(e) => this.handleChecked(e.target.checked, waypoint)}
                                      inputProps={{'aria-label': 'secondary checkbox'}}
                                    />
                                  </TableCell>
                                  <TableCell align="right">{waypoint?.id}</TableCell>
                                  <TableCell align="right">{waypoint?.name}</TableCell>
                                  <TableCell align="right">{waypoint?.display_name}</TableCell>
                                  <TableCell align="right">{waypoint?.street}</TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </div>
                    </TableContainer>
                    {this.state.totalRows > 0 ? (
                      <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                    ) : (
                      <></>
                    )}
                  </Container>
                  <Grid container alignItems="center" justify="center" item xs={6}>
                    <div style={{width: '200px'}}>
                      <Button
                        fullWidth
                        variant="contained"
                        className=""
                        color="primary"
                        onClick={() => this.convertDataWaypoint()}
                      >
                        {t('common.btnOk')}
                      </Button>
                    </div>
                  </Grid>
                </>
              )}
              <Grid container alignItems="center" justify="center" item xs={this.state.totalRows > 0 ? 6 : 12}>
                <div style={{width: '200px'}}>
                  <Button fullWidth variant="contained" className="button_color" color="primary" onClick={() => this.props.onClickClose()}>
                    {t('common.btnCancel')}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Container>
          <br />
        </Paper>
      </LoadingOverlay>
    );
  }
}
