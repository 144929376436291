export const ACTION_TYPES = {
  GET_NEWS_TEMPLATES_START: 'GET_NEWS_TEMPLATES_START',
  GET_NEWS_TEMPLATES_SUCCESS: 'GET_NEWS_TEMPLATES_SUCCESS',
  GET_NEWS_TEMPLATES_FAIL: 'GET_NEWS_TEMPLATES_FAIL',

  GET_NEWS_TEMPLATE_BY_ID_START: 'GET_NEWS_TEMPLATE_BY_ID_START',
  GET_NEWS_TEMPLATE_BY_ID_SUCCESS: 'GET_NEWS_TEMPLATE_BY_ID_SUCCESS',
  GET_NEWS_TEMPLATE_BY_ID_FAIL: 'GET_NEWS_TEMPLATE_BY_ID_FAIL',

  UPSERT_NEWS_TEMPLATE_START: 'UPSERT_NEWS_TEMPLATE_START',
  UPSERT_NEWS_TEMPLATE_SUCCESS: 'UPSERT_NEWS_TEMPLATE_SUCCESS',
  UPSERT_NEWS_TEMPLATE_FAIL: 'UPSERT_NEWS_TEMPLATE_FAIL',

  DELETE_NEWS_TEMPLATE_START: 'DELETE_NEWS_TEMPLATE_START',
  DELETE_NEWS_TEMPLATE_SUCCESS: 'DELETE_NEWS_TEMPLATE_SUCCESS',
  DELETE_NEWS_TEMPLATE_FAIL: 'DELETE_NEWS_TEMPLATE_FAIL',

  SEARCH_NEWS_START: 'SEARCH_NEWS_START',
  SEARCH_NEWS_SUCCESS: 'SEARCH_NEWS_SUCCESS',
  SEARCH_NEWS_FAIL: 'SEARCH_NEWS_FAIL',

  GET_NEWS_DETAIL_START: 'GET_NEWS_DETAIL_START',
  GET_NEWS_DETAIL_SUCCESS: 'GET_NEWS_DETAIL_SUCCESS',
  GET_NEWS_DETAIL_FAIL: 'GET_NEWS_DETAIL_FAIL',

  CREATE_NEWS_START: 'CREATE_NEWS_START',
  CREATE_NEWS_SUCCESS: 'CREATE_NEWS_SUCCESS',
  CREATE_NEWS_FAIL: 'CREATE_NEWS_FAIL',

  DELETE_NEWS_START: 'DELETE_NEWS_START',
  DELETE_NEWS_SUCCESS: 'DELETE_NEWS_SUCCESS',
  DELETE_NEWS_FAIL: 'DELETE_NEWS_FAIL',
};

const initialState = {
  templates: [],
  templateDetail: {},
  isLoading: false,
  isCreateDisabled: false,
};

/**
 * News-template reducer
 * @param {Object} state
 * @param {Object} action
 * @return {Promise}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.GET_NEWS_TEMPLATES_START:
    case ACTION_TYPES.GET_NEWS_TEMPLATE_BY_ID_START:
    case ACTION_TYPES.DELETE_NEWS_TEMPLATE_START:
    case ACTION_TYPES.SEARCH_NEWS_START:
    case ACTION_TYPES.GET_NEWS_DETAIL_START:
    case ACTION_TYPES.CREATE_NEWS_START:
    case ACTION_TYPES.DELETE_NEWS_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.UPSERT_NEWS_TEMPLATE_START:
      return {
        ...state,
        isLoading: true,
        isCreateDisabled: true,
      };
    case ACTION_TYPES.GET_NEWS_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: action.templates,
        isLoading: false,
      };
    case ACTION_TYPES.GET_NEWS_TEMPLATE_BY_ID_SUCCESS:
      return {
        ...state,
        templateDetail: action.templates,
        isLoading: false,
      };
    case ACTION_TYPES.DELETE_NEWS_TEMPLATE_SUCCESS:
    case ACTION_TYPES.SEARCH_NEWS_SUCCESS:
    case ACTION_TYPES.GET_NEWS_DETAIL_SUCCESS:
    case ACTION_TYPES.CREATE_NEWS_SUCCESS:
    case ACTION_TYPES.DELETE_NEWS_SUCCESS:
    case ACTION_TYPES.GET_NEWS_TEMPLATES_FAIL:
    case ACTION_TYPES.GET_NEWS_TEMPLATE_BY_ID_FAIL:
    case ACTION_TYPES.DELETE_NEWS_TEMPLATE_FAIL:
    case ACTION_TYPES.SEARCH_NEWS_FAIL:
    case ACTION_TYPES.GET_NEWS_DETAIL_FAIL:
    case ACTION_TYPES.CREATE_NEWS_FAIL:
    case ACTION_TYPES.DELETE_NEWS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.UPSERT_NEWS_TEMPLATE_SUCCESS:
    case ACTION_TYPES.UPSERT_NEWS_TEMPLATE_FAIL:
      return {
        ...state,
        isLoading: false,
        isCreateDisabled: false,
      };
    default:
      return state;
  }
}
