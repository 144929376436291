import React, {Component} from 'react';

import {
  Backdrop,
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Dialog,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import {RotateLeft as ResetIcon, Search as SearchIcon} from '@material-ui/icons';
import CompareArrowsIcon from '@material-ui/icons/SwapHoriz';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';

import {DATA_NULL, ISSUED_TICKET_DISTRIBUTION_HISTORY, LANGUAGE, PERMISSION_ACTIONS, PLAN_CATEGORIES, ROWS_PER_PAGE_OPTIONS} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {deleteTicketDistributionHistory, getDataMemberTicket, getTicketDistributionHistory, searchListPlan} from '../../../stores/business/action';
import {getAllCountryCode, getAllSubscriptionGeofence} from '../../../stores/common/actions';
import {changeUrlParams, customDisplayCurrency, getQueryStringFromObject, getUrlParams, onChangeSelect, onChangeTextField} from '../../../utils/common';
import {convertDatetimeUTC, displayDateTime} from '../../../utils/datetime';
import {isRoleGlobal} from '../../../utils/role';

/** Ticket Distribution Component */
export class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      countryId: '',
      geofenceId: '',
      planId: '',
      status: '',
      searchParams: this.props.location.search,
      isOpenModal: false,
      isOpenModalCancel: false,
      listGeofences: [],
      // list data member
      listMember: [],
      checkedMemberList: [],
      selectedStatus: null,
      listTicketDistributionHistory: [],
      trackingId: null,
      userIds: [],
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      isSearch: false,
      searchMember: '',
      // list all id member in one ticket
      listUser: [],
      errorMembers: [],
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    await this.props.getAllCountryCode();
    await this.props.getAllSubscriptionGeofence();
    await this.getListPlan();
    this.setState({
      countryId: isRoleGlobal() ? '' : this.props.common.principal?.country_id,
      listGeofences: this.props.common?.all_subscription_geofence,
    });
    if (this.props.location.search) {
      const search = getUrlParams(this.props.location.search);
      if (search.countryId) {
        const listGeofences = isRoleGlobal() ?
          this.props.common?.all_subscription_geofence.filter((geofence) => geofence.country_id === Number(search.countryId)) : this.state.listGeofences;
        this.setState({...search, listGeofences}, this.getDataTicketDistributionHistory);
        if (search.geofenceId) {
          this.getListPlan(search.countryId, search.geofenceId);
        }
      }
    }
  }

  /**
   * getListPlan
   * @param {*} countryId
   * @param {*} geofenceId
   */
  getListPlan = (countryId, geofenceId) => {
    const queryParams = {
      size: 99999,
    };
    this.props
      .searchListPlan({
        plan_type: PLAN_CATEGORIES[1].value,
        country_id: countryId,
        geofence_id: geofenceId,
      }, queryParams)
      .then((response) => this.setState({listPlan: response?.content?.filter((item) => item.is_gift)}));
  }

  /**
   * onChangeCountry
   * @param {event} event
   */
  onChangeCountry = (event) => {
    this.setState({
      countryId: event.target.value,
      geofenceId: '',
      planId: '',
      listGeofences: this.props.common?.all_subscription_geofence.filter((geofence) => geofence.country_id === event.target.value),
    });
    this.getListPlan(event.target.value, null);
  }

  /**
   * onChangeGeofence
   * @param {event} event
   */
  onChangeGeofence = (event) => {
    this.setState({
      geofenceId: event.target.value,
      planId: '',
    });
    this.getListPlan(this.state.countryId, event.target.value);
  }

  /**
   * onChangePlan
   * @param {event} event
   */
  onChangePlan = (event) => {
    this.setState({planId: event.target.value});
  }

  /**
   * resetSearchCondition
   */
  resetSearchCondition = () => {
    this.setState({
      countryId: isRoleGlobal() ? '' : this.props.common?.principal?.country_id,
      geofenceId: '',
      planId: '',
      status: '',
    });
    this.setState({
      listGeofences: this.props.common?.all_subscription_geofence,
    });
    this.getListPlan();
  }

  /**
   * handleCancel
   * @param {*} id
   * @param {*} user
   * @param {*} type
   */
  handleCancel = (id, user, type) => {
    if (type === 'SELECT') {
      const count = this.state.checkedMemberList?.length;
      this.setState({
        isOpenModalCancel: true,
        message: 'ticket.history.messageConfirm',
        sub_message: this.props.t('ticket.history.messageConfirm.totalRecord', {count: count}),
      });
    } else {
      const count = user?.length;
      this.setState({
        isOpenModalCancel: true,
        message: 'ticket.history.messageConfirm',
        sub_message: this.props.t('ticket.history.messageConfirm.totalRecord', {count: count}),
        trackingId: id,
        userIds: user,
      });
    }
  }

  /**
   * handleCheckItem
   * @param {object} event
   */
  handleCheckItem = (event) => {
    const checkedMemberList = this.state.checkedMemberList;
    if (event.target.checked) {
      checkedMemberList.push(Number(event.target.value));
    } else {
      const index = checkedMemberList.indexOf(Number(event.target.value));
      if (index > -1) {
        checkedMemberList.splice(index, 1);
      }
    }
    this.setState({userIds: checkedMemberList, checkedMemberList});
  }

  /**
   * handleCheckAll
   * @param {object} event
   */
  handleCheckAll = (event) => {
    let checkedMemberList = this.state.checkedMemberList;
    if (event.target.checked) {
      checkedMemberList = this.state.listMember.map((member) => member.id);
    } else {
      checkedMemberList = [];
    }
    this.setState({userIds: checkedMemberList, checkedMemberList});
  }

  /**
   * getDataTicketDistributionHistory
   * @param {boolean} reset
   */
  getDataTicketDistributionHistory = async (reset) => {
    await reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const {
      currentPage,
      rowsPerPage,
      countryId,
      geofenceId,
      planId,
      status,
    } = this.state;
    const param = {
      page: currentPage,
      size: rowsPerPage,
      country_id: countryId || null,
      geofence_id: geofenceId || null,
      plan_id: planId || null,
      is_distributed: status ? (status === 'DISTRIBUTED' || status === 'DISTRIBUTED_WITH_ERROR' ? true : false) : null,
      distributed_with_error: status === 'DISTRIBUTED_WITH_ERROR' ? '' : null,
    };
    Object.keys(param).forEach((key) => {
      if (param[key] === null) {
        delete param[key];
      }
    });
    await this.props.getTicketDistributionHistory(param).then((res) => {
      this.setState({
        isSearch: true,
        listTicketDistributionHistory: res?.result?.content,
        totalRows: res?.result?.totalSize,
      });
    });

    const queryParamsToChange = {
      countryId: this.state.countryId,
      geofenceId: this.state.geofenceId,
      planId: this.state.planId,
      status: this.state.status,
      currentPage: this.state.currentPage,
      rowsPerPage: this.state.rowsPerPage,
    };
    changeUrlParams(queryParamsToChange);
    const searchParams = getQueryStringFromObject(queryParamsToChange);
    this.setState({
      searchParams,
    });
  }

  /**
   * deleteTicketDistributionHistory
   * @param {*} id
   * @param {Array} userIds
   */
  deleteTicketDistributionHistory = async () => {
    await this.props
      .deleteTicketDistributionHistory({
        tracking_id: this.state.trackingId,
        user_ids: this.state.userIds,
      })
      .then((res) => {
        if (res.status === 200) {
          this.setState({
            isOpenModalCancel: false,
          });
          this.handleCloseModalMember();
          this.getDataTicketDistributionHistory();
        }
      });
  }

  /**
   * handle change current page & rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  handleChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.getDataTicketDistributionHistory);
  }

  /**
   * handleCloseModalMember
   */
  handleCloseModalMember = () => {
    this.setState({
      isOpenModal: false,
      checkedMemberList: [],
      listMember: [],
      searchMember: '',
      listUser: [],
    });
  }

  /**
   * isOpenModalListMember
   * @param {boolean} isOpenModal
   * @param {Object} item ticket distribution history
   */
  isOpenModalListMember = (isOpenModal, {is_distributed, user_ids, id, error_members}) => {
    this.setState({
      isOpenModal,
      selectedStatus: is_distributed ? (error_members?.length > 0 ? 'DISTRIBUTED_WITH_ERROR' : 'DISTRIBUTED') : 'UNDISTRIBUTED',
      trackingId: id,
      listUser: user_ids,
      errorMembers: error_members,
    });
    if (user_ids) {
      this.props.getDataMemberTicket({user_ids}).then((res) => {
        const listMember = res?.result?.map((member) => ({
          ...member,
          error_data: error_members?.length > 0 ? error_members[0]?.filter((mem) => mem.member_code === member.member_id) : null,
        }));
        this.setState({
          listMember,
        });
      });
    }
  }

  /**
   * handleSearchMember
   */
  handleSearchMember = () => {
    const {errorMembers, listUser, searchMember} = this.state;
    this.props.getDataMemberTicket({user_ids: listUser, keyword: searchMember?.trim()}).then((res) => {
      const listMember = res?.result?.map((member) => ({
        ...member,
        error_data: errorMembers?.length > 0 ? errorMembers[0]?.filter((mem) => mem.member_code === member.member_id) : null,
      }));
      this.setState({
        listMember,
      });
    });
  }

  /**
   * Render fail ticket distribution reason
   * @param {Object} errorData
   * @return {String}
   */
  renderDistributionFailReason = (errorData) => {
    const {t} = this.props;
    if (!!!errorData || errorData?.length === 0) return DATA_NULL;
    if (errorData.every((item) => item.reason === 'reason.distribution.fail.geofence.price.duplicate')) {
      return (
        <>
          {t('ticket.different_discount_amount_header')}
          <br />
          {errorData.map(({data}) => (
            <Box my={1}>
              {t('ticket.different_discount_amount_end', {
                Geofence: data.geofence_name,
                Subscription_ID: data.subscription_code,
                Adult: customDisplayCurrency(data.adult_amount, data.currency_code),
                Child: customDisplayCurrency(data.child_amount, data.currency_code),
              })}
            </Box>
          ))}
        </>
      );
    }
  }

  /**
   * render
   * @return {HTMLElement}
   */
  render() {
    const {t, common, business, actions} = this.props;
    const {listTicketDistributionHistory} = this.state;
    const {isLoading} = business;
    const permissions = {
      canSeeListMember: actions.includes(PERMISSION_ACTIONS.LIST_MEMBER),
      canDelete: actions.includes(PERMISSION_ACTIONS.DELETE),
    };

    return (
      <Container maxWidth="xl">
        <Grid container className="page_header">
          <Grid container item xs={6} alignItems="center">
            <h3>{t('route.ticket_distribution_history')}</h3>
          </Grid>
        </Grid>
        <br></br>
        <Card>
          <Container maxWidth="xl">
            <br />
            <Paper className="search_table">
              <Grid container className="row_form_item product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                {t('qrTicket.searchCondition')}
              </Grid>
              {/* Country */}
              <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                  {t('common.country')}
                </Grid>
                <Grid container alignItems="center" item xs={6} lg={4}>
                  <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                    <Select
                      margin="dense"
                      inputProps={{
                        name: 'countryId',
                      }}
                      displayEmpty
                      value={this.state.countryId || ''}
                      onChange={this.onChangeCountry}
                      renderValue={
                        this.state.countryId ?
                          undefined :
                          () => (
                              <div className="font-12 color-disabled">
                                {t('placeholder.required_select', {
                                  field: t('common.country'),
                                })}
                              </div>
                            )
                      }
                      disabled={!isRoleGlobal()}
                    >
                      {common?.country_code?.map((item, index) => (
                        <MenuItem value={item.id} key={index}>
                          {t(`${item.country_code}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              {/* Geofence */}
              <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                  {t('business_plan.geofence')}
                </Grid>
                <Grid container alignItems="center" item xs={6} lg={4}>
                  <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                    <Select
                      margin="dense"
                      inputProps={{
                        name: 'geofenceId',
                      }}
                      displayEmpty
                      value={this.state.geofenceId || ''}
                      onChange={this.onChangeGeofence}
                      renderValue={
                        this.state.geofenceId ?
                          undefined :
                          () => (
                              <div className="font-12 color-disabled">
                                {t('placeholder.required_select', {
                                  field: t('business_plan.geofence'),
                                })}
                              </div>
                            )
                      }
                    >
                      {this.state.listGeofences.map((item, index) => (
                        <MenuItem value={item.geofence_id} key={index} className={!item.enable ? 'disable-option-custom' : ''}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              {/* Ticket for gifts name */}
              <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                  {t('ticket.ticket_name_gift')}
                </Grid>
                <Grid container alignItems="center" item xs={6} lg={2}>
                  <FormControl variant="outlined" margin="dense" fullWidth>
                    <Box display="flex" flexDirection="column">
                      <div style={{fontSize: 13, marginBottom: 8, color: '#3f51b5'}}>({t('common.ja')})</div>
                      <Select
                        margin="dense"
                        inputProps={{
                          name: 'planId',
                        }}
                        displayEmpty
                        value={this.state.planId || ''}
                        onChange={this.onChangePlan}
                        renderValue={
                          this.state.planId ?
                            undefined :
                            () => (
                                <div className="font-12 color-disabled">
                                  {t('placeholder.required_select', {
                                    field: t('ticket.name'),
                                  })}
                                </div>
                              )
                        }
                      >
                        {this.state.listPlan?.map((item, index) => (
                          <MenuItem value={item.id} key={index}>
                            {item?.plan_languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </FormControl>
                </Grid>
                <Grid container justify="center" alignItems="center" item xs={1}>
                  <CompareArrowsIcon className="compare_arrow_icon" style={{top: 10}}></CompareArrowsIcon>
                </Grid>
                <Grid container alignItems="center" item xs={6} lg={2}>
                  <FormControl variant="outlined" margin="dense" fullWidth>
                    <Box display="flex" flexDirection="column">
                      <div style={{fontSize: 13, marginBottom: 8, color: '#3f51b5'}}>({t('common.vi')})</div>
                      <Select
                        margin="dense"
                        inputProps={{
                          name: 'planId',
                        }}
                        displayEmpty
                        value={this.state.planId || ''}
                        onChange={this.onChangePlan}
                        renderValue={
                          this.state.planId ?
                            undefined :
                            () => (
                                <div className="font-12 color-disabled">
                                  {t('placeholder.required_select', {
                                    field: t('ticket.name'),
                                  })}
                                </div>
                              )
                        }
                      >
                        {this.state.listPlan?.map((item, index) => (
                          <MenuItem value={item.id} key={index}>
                            {item?.plan_languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </FormControl>
                </Grid>
                <Grid container justify="center" alignItems="center" item xs={1}>
                  <CompareArrowsIcon className="compare_arrow_icon" style={{top: 10}}></CompareArrowsIcon>
                </Grid>
                <Grid container alignItems="center" item xs={6} lg={2}>
                  <FormControl variant="outlined" margin="dense" fullWidth>
                    <Box display="flex" flexDirection="column">
                      <div style={{fontSize: 13, marginBottom: 8, color: '#3f51b5'}}>({t('common.en')})</div>
                      <Select
                        margin="dense"
                        inputProps={{
                          name: 'planId',
                        }}
                        displayEmpty
                        value={this.state.planId || ''}
                        onChange={this.onChangePlan}
                        renderValue={
                          this.state.planId ?
                            undefined :
                            () => (
                                <div className="font-12 color-disabled">
                                  {t('placeholder.required_select', {
                                    field: t('ticket.name'),
                                  })}
                                </div>
                              )
                        }
                      >
                        {this.state.listPlan?.map((item, index) => (
                          <MenuItem value={item.id} key={index}>
                            {item?.plan_languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </FormControl>
                </Grid>
              </Grid>

              {/* Status */}
              <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                  {t('common.status')}
                </Grid>
                <Grid container alignItems="center" item xs={6} lg={4}>
                  <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                    <Select
                      margin="dense"
                      inputProps={{
                        name: 'status',
                      }}
                      displayEmpty
                      value={this.state.status || ''}
                      onChange={(event) => onChangeSelect(this, event)}
                      renderValue={
                        this.state.status ?
                          undefined :
                          () => (
                              <div className="font-12 color-disabled">
                                {t('placeholder.required_select', {
                                  field: t('common.status'),
                                })}
                              </div>
                            )
                      }
                    >
                      {ISSUED_TICKET_DISTRIBUTION_HISTORY.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                          {t(item.i18n)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
            <br />
            {/* Action buttons */}
            <Grid container spacing={1}>
              <Grid container item xs={6} alignItems="center">
                <Button className="button_margin button_color" variant="contained" color="primary" onClick={this.resetSearchCondition} endIcon={<ResetIcon />}>
                  {t('common.btnResetSearch')}
                </Button>
              </Grid>
              <Grid container item xs={6} alignItems="center" justify="flex-end">
                <Button className="button_margin" variant="contained" color="primary" onClick={() => this.getDataTicketDistributionHistory(true)} endIcon={<SearchIcon />}>
                  {t('common.btnSearch')}
                </Button>
              </Grid>
            </Grid>
            <br />
          </Container>
        </Card>
        <br />
        {/* Table Search Result */}
        {this.state.isSearch && (
          <Card>
            <Container maxWidth="xl">
              <Grid container alignItems="center">
                <h3>
                  {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                </h3>
              </Grid>
              {listTicketDistributionHistory?.length > 0 && (
                <>
                  <LoadingOverlay active={isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                    <div className="scroll_area_500">
                      <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('ticket.distribution_date')}</TableCell>
                            <TableCell>{t('ticket.start_date_time')}</TableCell>
                            <TableCell>{t('business_plan.package_name')} (JP)</TableCell>
                            <TableCell>{t('business_plan.package_name')} (VN)</TableCell>
                            <TableCell>{t('business_plan.package_name')} (EN)</TableCell>
                            <TableCell className="width_300p">{t('ticket.history.totalMember')}</TableCell>
                            <TableCell>{t('common.status')}</TableCell>
                            <TableCell>{t('common.action')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {listTicketDistributionHistory?.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell>{displayDateTime(convertDatetimeUTC(item?.distribute_time, item?.country_id))}</TableCell>
                              <TableCell>{displayDateTime(convertDatetimeUTC(item?.start_date_of_use, item?.country_id))}</TableCell>
                              {/* plan info ja */}
                              <TableCell>{item?.plan_info?.find((item) => item.language_id === 1)?.plan_name || DATA_NULL}</TableCell>
                              {/* plan info vi */}
                              <TableCell>{item?.plan_info?.find((item) => item.language_id === 3)?.plan_name || DATA_NULL}</TableCell>
                              {/* plan info en */}
                              <TableCell>{item?.plan_info?.find((item) => item.language_id === 2)?.plan_name || DATA_NULL}</TableCell>
                              <TableCell>
                                <Grid container>
                                  <Grid container item xs={6} alignItems="center">
                                    {item?.user_ids?.length} {t('reservationManagement.member')}
                                  </Grid>
                                  {permissions.canSeeListMember && (
                                    <Grid container item xs={6} alignItems="center" justify="flex-end">
                                      <Button
                                        className="button_margin"
                                        variant="contained"
                                        color="primary"
                                        onClick={() => this.isOpenModalListMember(true, item)}
                                        disabled={item.user_ids?.length === 0}
                                      >
                                        {t('ticket.history.listMember')}
                                      </Button>
                                    </Grid>
                                  )}
                                </Grid>
                              </TableCell>
                              <TableCell>
                                {item.is_distributed ?
                                  (item.error_members?.length > 0 ? t('ticket.history.distributed.with.error') : t('ticket.history.distributed')) :
                                  t('ticket.history.undistributed')
                                }
                              </TableCell>
                              <TableCell>
                                {(!item.is_distributed && permissions.canDelete) && (
                                  <Button
                                    className="button_margin button_color_red"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.handleCancel(item.id, item.user_ids, 'TABLE_SELECT')}
                                  >
                                    {t('common.btnDelete')}
                                  </Button>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  </LoadingOverlay>
                  <CustomPagination onChange={this.handleChangePagination} rows={this.state.totalRows} currentPage={this.state.currentPage} rowsPerPage={this.state.rowsPerPage} />
                </>
              )}
            </Container>
          </Card>
        )}

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.isOpenModal}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1000',
          }}
        >
          <Container>
            <Card>
              <Container>
                <Grid container justifyContent='center' alignItems='center' spacing={2} className="row_form_item">
                  <Grid item>
                    <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_350">
                      <TextField
                        className="field_size_10 field_min_size_350"
                        variant="outlined"
                        margin="dense"
                        placeholder={t('placeholder.required', {
                          field: t('ticket.history.searchMember'),
                        })}
                        name="searchMember"
                        value={this.state.searchMember}
                        onChange={(e) => onChangeTextField(this, e)}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <Button className="button_margin" variant="contained" color="primary" onClick={this.handleSearchMember} endIcon={<SearchIcon />}>
                      {t('common.btnSearch')}
                    </Button>
                  </Grid>
                </Grid>
                <br />
                <LoadingOverlay active={isLoading} bgColor="#f1f1f1">
                  <div className="scroll_area_350">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className='width_150p'>{t('memberManagement.memberId')}</TableCell>
                          <TableCell className='width_150p'>{t('memberManagement.memberName')}</TableCell>
                          {(this.state.selectedStatus === 'DISTRIBUTED' || this.state.selectedStatus === 'DISTRIBUTED_WITH_ERROR') && (
                            <>
                              <TableCell className='width_150p'>{t('ticket.history.distributionStatus')}</TableCell>
                              {(this.state.selectedStatus === 'DISTRIBUTED_WITH_ERROR') && (
                                <TableCell>{t('ticket.history.distributionFailReason')}</TableCell>
                              )}
                            </>
                          )}
                          {this.state.selectedStatus === 'UNDISTRIBUTED' && (
                            <TableCell className="width_150p ant-table-cell-fix-left">
                              <Checkbox
                                checked={this.state.checkedMemberList?.length === this.state.listMember?.length && this.state.listMember.length > 0}
                                onChange={(event) => this.handleCheckAll(event)}
                                inputProps={{'aria-label': 'primary'}}
                              />
                            </TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.listMember?.length > 0 &&
                          this.state.listMember.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell>{item.member_id}</TableCell>
                              <TableCell>
                                {item.last_name || ''} {item.first_name || ''}
                              </TableCell>
                              {(this.state.selectedStatus === 'DISTRIBUTED' || this.state.selectedStatus === 'DISTRIBUTED_WITH_ERROR') && (
                                <>
                                  <TableCell>{item.error_data?.length > 0 ? t('ticket.history.fail') : t('ticket.history.success')}</TableCell>
                                  {(this.state.selectedStatus === 'DISTRIBUTED_WITH_ERROR') && (
                                    <TableCell>{this.renderDistributionFailReason(item.error_data)}</TableCell>
                                  )}
                                </>
                              )}
                              {this.state.selectedStatus === 'UNDISTRIBUTED' && (
                                <TableCell className="cell_fixed_left">
                                  <Checkbox
                                    checked={this.state.checkedMemberList.includes(item.id)}
                                    value={item.id}
                                    onChange={(event) => this.handleCheckItem(event)}
                                    inputProps={{'aria-label': 'primary'}}
                                  />
                                </TableCell>
                              )}
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </div>
                </LoadingOverlay>
                <br />
                <Grid container item xs={12} alignItems="center" justifyContent="center">
                  {this.state.selectedStatus === 'UNDISTRIBUTED' && permissions.canDelete && (
                    <Button className="button_margin button_color_red" variant="contained" color="primary" onClick={() => this.handleCancel(null, null, 'SELECT')}>
                      {t('common.btnDelete')}
                    </Button>
                  )}
                  <Button className="button_margin button_color" variant="contained" color="primary" onClick={() => this.handleCloseModalMember()}>
                    {t('common.btnClose')}
                  </Button>
                </Grid>
                <br />
              </Container>
            </Card>
          </Container>
        </Modal>
        <Dialog
          open={this.state.isOpenModalCancel}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <SelectModal
            onClickOk={this.deleteTicketDistributionHistory}
            onClickCancel={() => this.setState({isOpenModalCancel: false})}
            okButtonText={t('common.btnDelete')}
            cancelButtonText={t('common.btnClose')}
            sub_message={this.state.sub_message}
            message={this.state.message}
          ></SelectModal>
        </Dialog>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common,
  business: state.business,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCountryCode: () => dispatch(getAllCountryCode()),
  getAllSubscriptionGeofence: () => dispatch(getAllSubscriptionGeofence()),
  searchListPlan: (payload, queryParams) => dispatch(searchListPlan(payload, queryParams)),
  getTicketDistributionHistory: (payload) => dispatch(getTicketDistributionHistory(payload)),
  getDataMemberTicket: (payload) => dispatch(getDataMemberTicket(payload)),
  deleteTicketDistributionHistory: (payload) => dispatch(deleteTicketDistributionHistory(payload)),
});

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
