import React, {Component} from 'react';

import {Card, Container, Grid, TextField, Button, Table, TableBody, TableCell, TableHead, TableRow, Paper, Select, FormControl, MenuItem} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DetailIcon from '@material-ui/icons/Description';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {DATA_NULL, PERMISSION_ACTIONS, ROUTE, ROWS_PER_PAGE_OPTIONS} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {searchReferrers} from '../../../stores/business/action';
import {getAllCountryCode} from '../../../stores/common/actions';
import {backForwardRouter, changeUrlParams, clearBlankValue, getQueryStringFromObject, onChangeSelect, redirectRouter} from '../../../utils/common';
import {getUrlParams, onChangeTextField} from '../../../utils/common';
import {isRoleGlobal} from '../../../utils/role';

/**
 * Account Type Management
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      country_id: !isRoleGlobal() ? props.principal.country_id : '',
      code: '',
      name: '',
      referral_code: '',
      status: 'Enable',

      isSearching: false,
      queryString: '',

      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    await this.props.getAllCountryCode();
    if (this.props.location.search) {
      const urlParams = getUrlParams(this.props.location.search);
      if (isRoleGlobal() ? true : Number(urlParams?.country_id) === Number(this.props.principal.country_id)) {
        this.setState(urlParams, this.handleSearchBusinessPartner);
      } else backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.HOME);
    }
  }

  /**
   * handleSearchBusinessPartner
   * @param {Boolean} isReset reset page and size of seaching
   */
  handleSearchBusinessPartner = async (isReset = false) => {
    this.setState({isSearching: true});
    const {
      country_id,
      code,
      name,
      referral_code,
      status,
      currentPage,
      rowsPerPage,
    } = this.state;
    const searchParams = {
      country_id: country_id || '',
      code: code ? code.trim() : '',
      name: name ? name.trim() : '',
      referral_code: referral_code ? referral_code.trim() : '',
      is_active: status ? (status === 'Enable' ? true : false) : '',
      page: isReset ? 0 : currentPage,
      size: isReset ? ROWS_PER_PAGE_OPTIONS[0] : rowsPerPage,
    };

    isReset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    await this.props.searchReferrers(clearBlankValue(searchParams));
    const queryParamsToChange = {
      country_id,
      code,
      name,
      referral_code,
      status,
      currentPage: isReset ? 0 : currentPage,
      rowsPerPage: isReset ? ROWS_PER_PAGE_OPTIONS[0] : rowsPerPage,
    };
    changeUrlParams(queryParamsToChange);
    const newQueryString = getQueryStringFromObject(queryParamsToChange);
    this.setState({queryString: newQueryString});
  }

  /**
   * Reset search condition
   */
  handleResetSearchCondition = () => {
    this.setState({
      country_id: !isRoleGlobal() ? this.props.principal.country_id : '',
      code: '',
      name: '',
      referral_code: '',
      status: 'Enable',

      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
    });
  };

  /**
   * onChangePagination
   * @param {Number} currentPage
   * @param {Number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({
      currentPage,
      rowsPerPage,
    }, this.handleSearchBusinessPartner);
  }

  /**
   * @return {HTMLElement}
   */
  render() {
    const {t, listReferrers, isLoading, countries} = this.props;
    const {content, totalSize} = listReferrers;
    const actions = {
      canSearch: this.props.actions.includes(PERMISSION_ACTIONS.SEARCH),
      canRegister: this.props.actions.includes(PERMISSION_ACTIONS.REGISTER),
      canEdit: this.props.actions.includes(PERMISSION_ACTIONS.EDIT),
    };

    return (
      <Card className="SearchAccountType main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('businessPartner.referrer_setting')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          <Card raised>
            <Container maxWidth="xl">
              <>
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('businessPartner.referrer_setting')}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className='row_form_item table_background_color_aliceblue'>
                    <Grid container item xs={6} lg={2} alignItems='center' className='grid_title_padding'>
                      {t('common.country')}
                    </Grid>
                    <Grid container alignItems='center' item xs={6} lg={4}>
                      <FormControl margin='dense' className='field_size_20 field_min_size_350'>
                        <Select
                          margin='dense'
                          variant='outlined'
                          displayEmpty
                          renderValue={
                            this.state.country_id ?
                            undefined :
                            () => (
                              <div className='font-12 color-disabled'>
                                {t('placeholder.required_select', {field: t('common.country')})}
                              </div>
                            )
                          }
                          inputProps={{
                            name: 'country_id',
                          }}
                          value={this.state.country_id}
                          onChange={(event) => onChangeSelect(this, event)}
                          disabled={!isRoleGlobal()}
                        >
                          {countries?.map((country) => (
                            <MenuItem key={country.country_code} value={country.id}>
                              {t(country.country_code)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.referrer_id')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <TextField
                        className="field_size_20 field_min_size_350"
                        margin="dense"
                        placeholder={t('validation.required', {
                          field: t('businessPartner.referrer_id'),
                        })}
                        name="code"
                        inputProps={{maxLength: 256}}
                        variant="outlined"
                        value={this.state.code}
                        onChange={(event) => onChangeTextField(this, event)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.referrer_name')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <TextField
                        margin='dense'
                        variant='outlined'
                        className='field_size_20 field_min_size_350'
                        placeholder={t('validation.required', {
                          field: t('businessPartner.referrer_name'),
                        })}
                        name='name'
                        value={this.state.name}
                        onChange={(event) => onChangeTextField(this, event)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.referrer_code')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <TextField
                        className="field_size_20 field_min_size_350"
                        margin="dense"
                        placeholder={t('validation.required', {
                          field: t('businessPartner.referrer_code'),
                        })}
                        name="referral_code"
                        inputProps={{maxLength: 256}}
                        variant="outlined"
                        value={this.state.referral_code}
                        onChange={(event) => onChangeTextField(this, event)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className='row_form_item table_background_color_aliceblue'>
                    <Grid container alignItems='center' item xs={6} lg={2} className='grid_title_padding'>
                      {t('common.status')}
                    </Grid>
                    <Grid container alignItems='center' item xs={6} lg={4}>
                      <FormControl margin='dense' variant='outlined' className='field_size_20 field_min_size_350'>
                        <Select
                          margin='dense'
                          variant='outlined'
                          displayEmpty
                          renderValue={
                            this.state.status ?
                            undefined :
                            () => (
                              <div className='font-12 color-disabled'>
                                {t('placeholder.required_select', {
                                  field: t('common.status'),
                                })}
                              </div>
                            )
                          }
                          inputProps={{
                            name: 'status',
                          }}
                          value={this.state.status}
                          onChange={(event) => onChangeSelect(this, event)}
                        >
                          <MenuItem value='Enable'>{t('common.enable')}</MenuItem>
                          <MenuItem value='Disable'>{t('common.disable')}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Paper>
              </>
              <br></br>
              <Grid container spacing={1}>
                <Grid container alignItems="center" justify="flex-start" item xs={3}>
                  <Button
                    color="primary"
                    variant="contained"
                    className="button_margin button_color"
                    onClick={this.handleResetSearchCondition}
                    endIcon={<RotateLeftIcon />}
                  >
                    {t('common.btnResetSearch')}
                  </Button>
                </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {actions.canRegister && (
                      <Button
                        color="primary"
                        variant="contained"
                        className="button_margin button_color_green"
                        endIcon={<AddIcon />}
                        onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.BUSINESS_PARTNER_ADD)}
                      >
                        {t('common.btnRegister')}
                      </Button>
                    )}
                    {actions.canSearch && (
                      <Button
                        color="primary"
                        variant="contained"
                        className="button_margin"
                        onClick={() => this.handleSearchBusinessPartner(true)}
                        endIcon={<SearchIcon />}
                      >
                        {t('common.btnSearch')}
                      </Button>
                    )}
                  </Grid>
              </Grid>

              <br></br>
            </Container>
          </Card>
          <br></br>
          {actions.canSearch && this.state.isSearching && (
            <>
              <LoadingOverlay active={isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                <Card raised>
                  <Container maxWidth="xl">
                    <Grid container spacing={1}>
                      <Grid container alignItems="center" item xs={6}>
                        <h3>
                          {t('common.searchResult')} {totalSize} {t('common.case')}
                        </h3>
                      </Grid>
                    </Grid>

                    {totalSize > 0 && (
                      <div className="scroll_area_700">
                        <Table aria-label="table" stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell className="width_7">{t('businessPartner.referrer_id')}</TableCell>
                              <TableCell className='width_10' align='center'>{t('common.country')}</TableCell>
                              {['JP', 'VN', 'EN'].map((lang, idx) => (
                                <TableCell key={idx} align='center' className='width_15'>
                                  <Grid container alignItems='center'>
                                    <Grid container item justifyContent='center' style={{lineHeight: '1.2rem'}}>
                                      {t('businessPartner.referrer_name')}
                                    </Grid>
                                    <Grid container item justifyContent='center' style={{lineHeight: '1.2rem'}}>
                                      ({lang})
                                    </Grid>
                                  </Grid>
                                </TableCell>
                              ))}
                              <TableCell align='center' className="width_15">{t('businessPartner.referrer_code')}</TableCell>
                              <TableCell align='center' className='width_10'>{t('common.status')}</TableCell>
                              <TableCell align='center'>{t('common.action')}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {content?.map((referrer, index) => (
                              <TableRow key={index} hover>
                                <TableCell>{referrer.code}</TableCell>
                                <TableCell align='center'>
                                  {referrer.country_id ? t(`${countries?.find((country) => country.id === referrer.country_id).country_code}`) : DATA_NULL}
                                </TableCell>
                                <TableCell align='center'>{referrer.names.ja}</TableCell>
                                <TableCell align='center'>{referrer.names.vi}</TableCell>
                                <TableCell align='center'>{referrer.names.en}</TableCell>
                                <TableCell align='center'>{referrer.referral_code}</TableCell>
                                <TableCell align='center'>{referrer.is_active ? t('common.enable') : t('common.disable')}</TableCell>
                                <TableCell align='center'>
                                  {actions.canEdit && (
                                    <Link
                                      style={{textDecoration: 'none'}}
                                      to={{
                                        pathname: ROUTE.LAYOUT + ROUTE.BUSINESS_PARTNER_DETAIL + `/${referrer.id}`,
                                        state: {from: this.props.location.pathname + '?' + this.state.queryString},
                                      }}
                                    >
                                      <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                        {t('common.btnDetail')}
                                      </Button>
                                    </Link>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    )}
                    <CustomPagination
                      onChange={this.onChangePagination}
                      rows={totalSize}
                      rowsPerPage={this.state.rowsPerPage}
                      currentPage={this.state.currentPage}
                    />
                  </Container>
                </Card>
              </LoadingOverlay>
              <br></br>
            </>
          )}
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  listReferrers: state.business.listReferrers,
  isLoading: state.business.isLoading,
  countries: state.common.country_code,
});

const mapDispatchToProps = (dispatch) => ({
  searchReferrers: (params) => dispatch(searchReferrers(params)),
  getAllCountryCode: () => dispatch(getAllCountryCode()),
});

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
