import React, {Component} from 'react';

import {
  Backdrop,
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SyncIcon from '@material-ui/icons/Sync';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

import {DATA_NULL, GEOFENCE_PREVIEWS, ROUTE, TIME_FORMAT} from '../../../common/constant';
import CancelBookingSuccessModal from '../../../components/cancelBookingSuccessModal';
import CustomTimePicker from '../../../components/CustomTimePicker';
import DisplayMap from '../../../components/map/DisplayMap';
import SelectModal from '../../../components/selectModal';
import {checkGeofenceTimeBookingAffectApi} from '../../../services/geofences';
import {getListVehicleMode} from '../../../stores/business_vehicles/action';
import {getAllCountryCode} from '../../../stores/common/actions';
import {checkGeofenceTime, getDetailGeofence, getEnableSimulation, getProject, syncGeofence, updateGeofence} from '../../../stores/geofence/action';
import {setMessageModal} from '../../../stores/modal/actions';
import {getAllServiceGroups} from '../../../stores/service_group/actions';
import {isUUID, jsonToString} from '../../../utils/common';
import {convertHourMinuteToDate, getTimeAgo, getTimeDefault} from '../../../utils/date_time_utils';
import {modalObj} from '../../../utils/modal';
import withPermissionData from '../../../withPermissionData/hoc';
import OnDemandSettingModal from './OnDemandSettingModal';
import SearchServiceGroupModal from './SearchServiceGroupModal';

import './style.css';

const DEFAULT_BG_COLOR = '#ff0000';
const DEFAULT_MY_PAGE_BG_COLOR = '#ffffff';
const DEFAULT_MY_PAGE_BORDER_COLOR = '#FD6BA3';

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(12),
  },
}))(Tooltip);

/**
 * GeofenceDetail
 * @return {*}
 */
class GeofenceDetail extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      geofenceDetail: {
        enable: false,
        enable_for_admin: false,
        enable_for_app: false,
        display_for_app: [],
        in_hole: false,
        bg_color: DEFAULT_BG_COLOR,
        zone_id: '',
        rawOpacity: 0,
        vehicle_mode: '',
        border_color: DEFAULT_BG_COLOR,
        rawOpacityBorder: 0,
        operating_start_time: null,
        operating_end_time: null,
        min_walking_distance_show: '',
        max_walking_distance_show: '',
        mapbox_path_segment: '',
        service_group_id: '',
        mypage_bg_color: DEFAULT_MY_PAGE_BG_COLOR,
        mypage_bg_opacity: 0,
        mypage_border_color: DEFAULT_MY_PAGE_BORDER_COLOR,
        mypage_border_opacity: 100,
        project_id: null,
        allow_infants: false,
        offer_estimation_timeout: 4,
        enableForAdminHistory: false,
        enableForAdminSetting: true,
        enableForAdminInfosPrevious: {},
        enable_frame_time_price: false,
        enable_waypoint_association: false,
        enable_last_order_time: false,
        last_order_time: null,
      },
      geofence_preview: GEOFENCE_PREVIEWS[0].id,
      showDialog: false,
      showSyncDialog: false,
      showWarningDialog: false,
      jsonErr: false,
      isSubmitForm: false,
      isSearchServiceGroupModalOpened: false,
      selectedServiceGroup: null,
      listProject: [],
      isOnVehicleAdditional: false,
      odmSettingModalOpen: false,
      bookingCancelNumber: null,
      bookingCancelIds: [],
      bookingCancelDialog: false,
      isSuccessBookingCancel: false,
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    await this.props.getAllCountryCode();
    await this.props.getListVehicleMode();
    await this.props.getAllServiceGroups();
    await this.props.getProject().then((response) => this.setState({listProject: response?.result?.content}));
    this.props.match.params.id && this.getDataDetailGeofence(this.props.match.params.id);
  };

  /**
   * getDetailGeofence
   * @param {*} geofenceId
   */
  getDataDetailGeofence = (geofenceId) => {
    this.props.getDetailGeofence(geofenceId).then(
      (geofenceDetail) => {
        if (geofenceDetail) {
          geofenceDetail['country_id'] = geofenceDetail.country?.id;
          this.setState({isOnVehicleAdditional: geofenceDetail?.show_vehicle_additional_info});
          if (geofenceDetail.bg_color?.length >= 9) {
            const opacityHex = geofenceDetail.bg_color.slice(1, 3);
            const opacityHexBorder = geofenceDetail.border_color.slice(1, 3);
            geofenceDetail.rawOpacity = Math.round((parseInt(opacityHex, 16) / 255) * 100) || 0;
            geofenceDetail.rawOpacityBorder = Math.round((parseInt(opacityHexBorder, 16) / 255) * 100) || 0;
          } else {
            geofenceDetail.rawOpacity = 0;
            geofenceDetail.rawOpacityBorder = 0;
          }
          if (geofenceDetail.mypage_bg_color?.length >= 9) {
            const opacityHex = geofenceDetail.mypage_bg_color.slice(1, 3);
            const opacityHexBorder = geofenceDetail.mypage_border_color.slice(1, 3);
            geofenceDetail.mypage_bg_opacity = Math.round((parseInt(opacityHex, 16) / 255) * 100) || 0;
            geofenceDetail.mypage_border_opacity = Math.round((parseInt(opacityHexBorder, 16) / 255) * 100) || 0;
          } else {
            geofenceDetail.mypage_bg_opacity = 0;
            geofenceDetail.mypage_border_opacity = 100;
          }
          geofenceDetail.bg_color = geofenceDetail.bg_color ? `#${geofenceDetail.bg_color?.slice(-6)}` : DEFAULT_BG_COLOR;
          geofenceDetail.border_color = geofenceDetail.border_color ? `#${geofenceDetail.border_color?.slice(-6)}` : DEFAULT_BG_COLOR;
          geofenceDetail.mypage_bg_color = geofenceDetail.mypage_bg_color ? `#${geofenceDetail.mypage_bg_color.slice(-6)}` : DEFAULT_MY_PAGE_BG_COLOR;
          geofenceDetail.mypage_border_color = geofenceDetail.mypage_border_color ? `#${geofenceDetail.mypage_border_color.slice(-6)}` : DEFAULT_MY_PAGE_BORDER_COLOR;
          geofenceDetail.operating_start_time = geofenceDetail?.operating_start_time && convertHourMinuteToDate(geofenceDetail?.operating_start_time);
          geofenceDetail.operating_end_time = geofenceDetail?.operating_end_time && convertHourMinuteToDate(geofenceDetail?.operating_end_time);
          geofenceDetail.mapbox_path_segment = geofenceDetail?.mapbox_path_segment || '';
          geofenceDetail.project_id = geofenceDetail?.project_id || null;
          geofenceDetail.offer_estimation_timeout = geofenceDetail.offer_estimation_timeout || '';
          geofenceDetail.display_for_app = geofenceDetail.display_for_app || [];
          geofenceDetail.vehicle_mode = geofenceDetail.vehicle_modes?.length > 0 ? geofenceDetail.vehicle_modes[0] : '';
          geofenceDetail.allow_infants = geofenceDetail?.allow_infants;
          geofenceDetail.enable_frame_time_price = !!geofenceDetail.enable_frame_time_price;
          geofenceDetail.enable_waypoint_association = !!geofenceDetail.enable_waypoint_association;
          geofenceDetail.enableForAdminSetting = geofenceDetail.ad_setting_screen_display;
          geofenceDetail.enableForAdminHistory = geofenceDetail.ad_history_screen_display;
          geofenceDetail.enableForAdminInfosPrevious = {
            enableForAdminSettingPrev: geofenceDetail.ad_setting_screen_display,
            enableForAdminHistoryPrev: geofenceDetail.ad_history_screen_display,
            checkEnableOrForAdminPrev: geofenceDetail.enable && geofenceDetail.enable_for_admin,
          };
          geofenceDetail.last_order_time = geofenceDetail?.last_order_time && convertHourMinuteToDate(geofenceDetail?.last_order_time);
          const selectedServiceGroup = geofenceDetail.service_group_id && this.props.allServiceGroups?.find((item) => item.id === geofenceDetail.service_group_id);
          this.setState({geofenceDetail, selectedServiceGroup});
          this.props.getEnableSimulation({geofenceId: geofenceDetail.geofence_id, enable: true, active: true});
        } else {
          this.props.history.replace(ROUTE.LAYOUT + ROUTE.GEOFENCE_MANAGEMENT);
        }
      },
      () => this.props.history.goBack(),
    );
  };

  /**
   * closeSyncDialog
   */
  closeSyncDialog = () => {
    this.setState({
      showSyncDialog: false,
    });
  };

  /**
   * openSyncDialog
   */
  openSyncDialog = () => {
    this.setState({
      showSyncDialog: true,
    });
  };

  /**
   * checkGeofenceTime
   */
  checkGeofenceTime = async () => {
    this.closeDialog();
    const payload = {
      geofence_id: this.state.geofenceDetail.geofence_id,
      local_start_time: getTimeDefault(this.state.geofenceDetail?.operating_start_time),
      local_end_time: getTimeDefault(this.state.geofenceDetail?.operating_end_time),
    };
    const response = await this.props.checkGeofenceTime(payload);
    if (response) {
      if (response.length === 0) {
        this.checkGeofenceTimeBookingAffect();
      } else {
        this.setState({showWarningDialog: true});
      }
    }
  };

  /**
   * checkGeofenceTimeBookingAffect
   * @param {object} payload
   */
  checkGeofenceTimeBookingAffect = async () => {
    const payload = {
      geofence_id: this.state.geofenceDetail.geofence_id,
      local_start_time: getTimeDefault(this.state.geofenceDetail?.operating_start_time),
      local_end_time: getTimeDefault(this.state.geofenceDetail?.operating_end_time),
    };
    checkGeofenceTimeBookingAffectApi(payload).then((res) => {
      if (res) {
        if (res?.result?.length === 0) {
          this.setState({bookingCancelDialog: false, bookingCancelNumber: null, bookingCancelIds: []}, () => this.handleUpdateGeofence());
        } else {
          const bookingCancelIds = res?.result;
          this.setState({bookingCancelNumber: res?.result?.length, bookingCancelIds, bookingCancelDialog: true});
        }
      } else {
        this.handleUpdateGeofence();
      }
    });
  };

  /**
   * sync geofence swat
   */
  handleSyncGeofence = () => {
    this.closeSyncDialog();
    const {geofence_id} = this.state.geofenceDetail;
    this.props.syncGeofence(geofence_id).then((rs) => {
      rs &&
        this.setState({
          geofenceDetail: {
            ...rs,
            operating_start_time: rs.operating_start_time && convertHourMinuteToDate(rs.operating_start_time),
            operating_end_time: rs.operating_end_time && convertHourMinuteToDate(rs.operating_end_time),
          },
        });
    });
  };

  /**
   * on change text field
   * @param {Event} event
   */
  handleFieldChange = (event) => {
    let zoneId = '';
    const key = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    if (event.target.name === 'country_id') {
      zoneId = this.props.country_code?.find((e) => e.id === event.target.value)?.zone_id;
      this.setState({isOnVehicleAdditional: Number(value) === 5});
    }
    this.setState((state) => ({
      ...state,
      geofenceDetail: {
        ...state.geofenceDetail,
        [key]: value,
        zone_id: zoneId ? zoneId : this.state.geofenceDetail.zone_id,
      },
    }));
  };

  /**
   * Handle check enable for app
   * @param {Event} event
   */
  handleCheckEnableForApp = (event) => {
    const {checked} = event.target;
    this.setState({
      geofenceDetail: {
        ...this.state.geofenceDetail,
        enable_for_app: checked,
        display_for_app: checked ? [GEOFENCE_PREVIEWS[0].id] : [],
      },
    });
  };

  /**
   * Handle check display in app: Top map/My page
   * @param {Event} event
   */
  handleCheckDisplayForApp = (event) => {
    const {checked, value} = event.target;
    const display_for_app = [...this.state.geofenceDetail.display_for_app];
    if (checked) {
      display_for_app.push(value);
    } else {
      const idx = display_for_app.findIndex((item) => item === value);
      display_for_app.splice(idx, 1);
    }
    this.setState({geofenceDetail: {...this.state.geofenceDetail, display_for_app}});
  };

  /**
   * handleCheckEnableForAdmin
   * @param {Event} event
   * @param {String} fieldName
   */
  handleTickCheckbox = (event) => {
    const fieldName = event.target.name;
    const {checked} = event.target;
    this.setState({
      geofenceDetail: {
        ...this.state.geofenceDetail,
        [fieldName]: checked,
      },
    });
    if (this.props.match.params.id && (fieldName === 'enable' || fieldName === 'enable_for_admin')) {
      const {enableForAdminSettingPrev, enableForAdminHistoryPrev, checkEnableOrForAdminPrev} = this.state.geofenceDetail.enableForAdminInfosPrevious;
      this.setState({
        geofenceDetail: {
          ...this.state.geofenceDetail,
          [fieldName]: checked,
          enableForAdminSetting: checkEnableOrForAdminPrev ? enableForAdminSettingPrev : true,
          enableForAdminHistory: checkEnableOrForAdminPrev ? enableForAdminHistoryPrev : false,
        },
      });
    }
  };

  /**
   * validateEnableForAdmin
   * @return {Boolean}
   */
  validateEnableForAdmin = () => {
    const {enable, enable_for_admin, enableForAdminSetting, enableForAdminHistory} = this.state.geofenceDetail;
    return enable && enable_for_admin ? enableForAdminSetting || enableForAdminHistory : true;
  };

  /**
   * closeDialog
   */
  closeDialog = () => {
    this.setState((state) => ({
      ...state,
      showDialog: false,
    }));
  };

  /**
   * openDialog
   */
  openDialog = () => {
    this.setState({isSubmitForm: true});
    const geofenceDetail = this.state.geofenceDetail;
    const checkOrderTimeValid =
      (this.state.geofenceDetail.enable_last_order_time && this.state.geofenceDetail.last_order_time) || this.state.geofenceDetail.enable_last_order_time === false;
    if (
      !checkOrderTimeValid ||
      !this.validator.allValid() ||
      !this.checkValidTimeRange(geofenceDetail.operating_start_time, geofenceDetail.operating_end_time) ||
      !this.validateEnableForAdmin()
    ) {
      return;
    }
    !this.state.jsonErr &&
      this.setState((state) => ({
        ...state,
        showDialog: true,
      }));
  };

  /**
   * all api update geofence
   */
  handleUpdateGeofence = async () => {
    this.setState({showWarningDialog: false, bookingCancelDialog: false});
    this.closeDialog();
    let opacityHex = Math.round((Number(this.state.geofenceDetail?.rawOpacity) / 100) * 255)
      .toString(16)
      .slice(0, 2);
    let opacityHexBorder = Math.round((Number(this.state.geofenceDetail?.rawOpacityBorder) / 100) * 255)
      .toString(16)
      .slice(0, 2);
    let opacityHexMyPage = Math.round((Number(this.state.geofenceDetail?.mypage_bg_opacity) / 100) * 255)
      .toString(16)
      .slice(0, 2);
    let opacityHexBorderMyPage = Math.round((Number(this.state.geofenceDetail?.mypage_border_opacity) / 100) * 255)
      .toString(16)
      .slice(0, 2);
    if (opacityHex.length === 1) opacityHex = `0${opacityHex}`;
    if (opacityHexBorder.length === 1) opacityHexBorder = `0${opacityHexBorder}`;
    if (opacityHexMyPage.length === 1) opacityHexMyPage = `0${opacityHexMyPage}`;
    if (opacityHexBorderMyPage.length === 1) opacityHexBorderMyPage = `0${opacityHexBorderMyPage}`;
    const {isOnVehicleAdditional, geofenceDetail, bookingCancelNumber} = this.state;
    const anyAssignedBookings = bookingCancelNumber ? true : false;
    const isTickedEnableOrForAdmin = geofenceDetail.enable && geofenceDetail.enable_for_admin;
    const updatePayload = {
      ...this.state.geofenceDetail,
      bg_color: `#${opacityHex}` + geofenceDetail?.bg_color?.slice(-6),
      border_color: `#${opacityHexBorder}` + geofenceDetail?.border_color?.slice(-6),
      mypage_bg_color: `#${opacityHexMyPage}` + geofenceDetail?.mypage_bg_color?.slice(-6),
      mypage_border_color: `#${opacityHexBorderMyPage}` + geofenceDetail?.mypage_border_color?.slice(-6),
      operating_start_time: getTimeDefault(geofenceDetail?.operating_start_time),
      operating_end_time: getTimeDefault(geofenceDetail?.operating_end_time),
      opacity: geofenceDetail.rawOpacity,
      project_id: geofenceDetail.project_id,
      // to be updated
      vehicle_modes: [geofenceDetail.vehicle_mode],
      offer_estimation_timeout: Number(geofenceDetail.offer_estimation_timeout),
      show_vehicle_additional_info: isOnVehicleAdditional,
      ad_setting_screen_display: isTickedEnableOrForAdmin ? geofenceDetail.enableForAdminSetting : false,
      ad_history_screen_display: isTickedEnableOrForAdmin ? geofenceDetail.enableForAdminHistory : false,
      any_assigned_bookings: anyAssignedBookings,
      last_order_time: geofenceDetail?.last_order_time ? getTimeDefault(geofenceDetail?.last_order_time) : null,
    };
    delete updatePayload.rawOpacity;
    delete updatePayload.rawOpacityBorder;
    delete updatePayload.mypage_bg_opacity;
    delete updatePayload.mypage_border_opacity;
    delete updatePayload.country;
    delete updatePayload.vehicle_mode;
    delete updatePayload.enableForAdminSetting;
    delete updatePayload.enableForAdminHistory;

    await this.props.updateGeofence(updatePayload).then((response) => {
      if (response) {
        // check display dialog success booking cancel
        if (this.state.bookingCancelIds?.length > 0) {
          this.setState({isSuccessBookingCancel: true});
        } else {
          this.props.setMessageModal(modalObj(true, 'Api.success'));
        }
        this.props.match.params.id ? this.getDataDetailGeofence(this.props.match.params.id) : this.props.history.push(ROUTE.LAYOUT + ROUTE.GEOFENCE_MANAGEMENT);
      }
    });
  };

  /**
   * handleChangeGeometry
   * @param {Event} evt
   */
  handleChangeGeometry = (evt) => {
    const geometry = evt.target.value;
    try {
      const val = JSON.parse(geometry);
      this.setState((state) => ({
        ...state,
        jsonErr: false,
        geofenceDetail: {
          ...state.geofenceDetail,
          geometry: val,
        },
      }));
    } catch (error) {
      this.setState({jsonErr: true});
    }
  };

  /**
   * onChangeEnableLastOrderTime
   * @param {Event} event
   */
  onChangeEnableLastOrderTime = (event) => {
    if (!event.target.checked) {
      this.setState({geofenceDetail: {...this.state.geofenceDetail, last_order_time: null, enable_last_order_time: event.target.checked}});
    } else {
      this.setState({geofenceDetail: {...this.state.geofenceDetail, enable_last_order_time: event.target.checked}});
    }
  };

  /**
   * handleGeometryError
   * @param {Error} err
   */
  handleGeometryError = (err) => {
    this.setState({jsonErr: true});
  };

  /**
   * Check valid time range
   * @param {Date} startTime
   * @param {Date} endTime
   * @return {*}
   */
  checkValidTimeRange = (startTime, endTime) => {
    return (
      startTime &&
      endTime &&
      (endTime > startTime || startTime.getHours() < endTime.getHours() || (startTime.getHours() === endTime.getHours() && startTime.getMinutes() < endTime.getMinutes()))
    );
  };

  /**
   * Render simulation
   * @param {string} serviceSimId
   * @param {string} i18n
   * @param {boolean} isLastCell
   * @return {JSX}
   */
  renderSimulation = (serviceSimId, i18n, isLastCell = false) => {
    const {t, geofence} = this.props;
    const {geofenceDetail} = this.state;
    const cellStyle = isLastCell ? {borderBottom: 'none'} : {};

    if (geofenceDetail[serviceSimId]) {
      const simulation = geofence?.availableSimulation?.content?.find((sim) => sim.simulation_id === geofenceDetail[serviceSimId]);

      return (
        <>
          <TableCell style={cellStyle}>{t(i18n)}</TableCell>
          <TableCell style={cellStyle}>
            <Link style={{textDecoration: 'none'}} to={{pathname: `${ROUTE.LAYOUT}${ROUTE.SIMULATION_MANAGEMENT_DETAIL}/${simulation?.id}`}} target="_blank">
              <CustomTooltip title={simulation?.description}>
                <Button variant="contained" color="primary" className="btn_margin button_color_red">
                  {'Simulation'}
                </Button>
              </CustomTooltip>
            </Link>
            {serviceSimId === 'on_demand_sim_id' && (
              <Button style={{marginLeft: 8}} variant="contained" color="primary" className="btn_margin btn_color" onClick={() => this.setState({odmSettingModalOpen: true})}>
                {'Setting'}
              </Button>
            )}
          </TableCell>
        </>
      );
    } else {
      return (
        <>
          <TableCell style={cellStyle}>{t(i18n)}</TableCell>
          <TableCell style={cellStyle}>{DATA_NULL}</TableCell>
        </>
      );
    }
  };

  /**
   * render component
   * @return {HTML}
   */
  render() {
    const {t, geofence, country_code, businessVehicle} = this.props;
    const {geofenceDetail, listProject} = this.state;
    const {isLoading, isSyncing} = geofence;
    const isCreate = !this.props.match.params.id;
    const isTopMap = this.state.geofence_preview === GEOFENCE_PREVIEWS[0].id;
    const country = country_code.find((country) => geofenceDetail.country_id === country.id);

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{isCreate ? t('geofence.create_title') : t('geofence.detail_title')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Button color="primary" variant="contained" onClick={() => this.props.history.goBack()} className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                {t('common.btnReturn')}
              </Button>
            </Grid>
          </Grid>
          <br></br>
          <LoadingOverlay active={isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                <Grid container spacing={3} style={{padding: '24px 0'}}>
                  <Grid item xs={6}>
                    <Table size="small">
                      <TableBody>
                        {/* swat geofence id */}
                        {!isCreate && (
                          <>
                            <TableRow>
                              <TableCell className="width_300p">{t('geofence.geofence_id')}</TableCell>
                              <TableCell>
                                <TextField
                                  disabled={!isCreate}
                                  name="geofence_id"
                                  value={geofenceDetail.geofence_id || ''}
                                  className="field_size_20 field_min_size_400"
                                  margin="dense"
                                  onChange={this.handleFieldChange}
                                  variant="outlined"
                                />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>{t('geofence.swat_geofence_id')}</TableCell>
                              <TableCell>
                                <TextField
                                  disabled={!isCreate}
                                  name="swat_geofence_id"
                                  value={geofenceDetail.swat_geofence_id || ''}
                                  className="field_size_20 field_min_size_400"
                                  margin="dense"
                                  onChange={this.handleFieldChange}
                                  variant="outlined"
                                />
                              </TableCell>
                            </TableRow>
                            {/* Code */}
                            <TableRow>
                              <TableCell>{t('jit.code')}</TableCell>
                              <TableCell>
                                <TextField disabled={true} value={geofenceDetail.code || ''} className="field_size_20 field_min_size_400" margin="dense" variant="outlined" />
                              </TableCell>
                            </TableRow>
                          </>
                        )}

                        {/* swat geofence key */}
                        <TableRow>
                          <TableCell>{t('geofence.swat_geofence_key')}</TableCell>
                          <TableCell>
                            <TextField
                              name="swat_geofence_key"
                              value={geofenceDetail.swat_geofence_key || ''}
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              onChange={(event) => {
                                this.handleFieldChange(event);
                                this.setState({jsonErr: false});
                              }}
                              variant="outlined"
                              placeholder={t('placeholder.required', {field: t('geofence.swat_geofence_key')})}
                            />
                            <Grid container spacing={1}>
                              <Grid container alignItems="center" item>
                                {this.state.isSubmitForm && !this.validator.check(geofenceDetail.swat_geofence_key, 'min:0,num') && (
                                  <FormHelperText id="swat_geofence_key" error>
                                    {t('validation.invalid.field', {field: t('geofence.swat_geofence_key')})}
                                  </FormHelperText>
                                )}
                                {this.state.isSubmitForm && !geofenceDetail.geometry && !this.validator.check(geofenceDetail.swat_geofence_key, 'required') && (
                                  <FormHelperText id="swat_geofence_key" error>
                                    {t('validation.required', {field: t('geofence.swat_geofence_key')})}
                                  </FormHelperText>
                                )}
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>

                        {/* enable */}
                        <TableRow>
                          <TableCell>{t('common.enable')}</TableCell>
                          <TableCell>
                            <FormControlLabel
                              label={t('common.enable')}
                              control={
                                <Checkbox
                                  inputProps={{'aria-label': 'primary checkbox'}}
                                  checked={geofenceDetail.enable}
                                  name="enable"
                                  onChange={(event) => this.handleTickCheckbox(event)}
                                />
                              }
                            />
                            <FormControlLabel
                              label={t('geofence.enable_for_admin')}
                              control={
                                <Checkbox
                                  inputProps={{'aria-label': 'primary checkbox'}}
                                  checked={geofenceDetail.enable_for_admin}
                                  disabled={!geofenceDetail.enable}
                                  name="enable_for_admin"
                                  onChange={(event) => this.handleTickCheckbox(event)}
                                />
                              }
                            />
                            <FormControlLabel
                              label={t('geofence.enable_for_app')}
                              control={
                                <Checkbox
                                  inputProps={{'aria-label': 'primary checkbox'}}
                                  checked={geofenceDetail.enable_for_app}
                                  disabled={!geofenceDetail.enable}
                                  name="enable_for_app"
                                  onChange={(event) => this.handleCheckEnableForApp(event)}
                                />
                              }
                            />
                          </TableCell>
                        </TableRow>

                        {/* Enable (For Admin) */}
                        {this.state.geofenceDetail.enable && this.state.geofenceDetail.enable_for_admin && (
                          <TableRow>
                            <TableCell>
                              {t('geofence.enableForAdmin')}
                              <span className="font_color_red">＊</span>
                            </TableCell>
                            <TableCell>
                              <FormGroup>
                                <FormControlLabel
                                  label={t('geofence.settingScreenDisplay')}
                                  control={
                                    <Checkbox checked={geofenceDetail.enableForAdminSetting} name="enableForAdminSetting" onChange={(event) => this.handleTickCheckbox(event)} />
                                  }
                                />
                                <FormControlLabel
                                  label={t('geofence.historyScreenDisplay')}
                                  control={
                                    <Checkbox checked={geofenceDetail.enableForAdminHistory} name="enableForAdminHistory" onChange={(event) => this.handleTickCheckbox(event)} />
                                  }
                                />
                              </FormGroup>
                              <Grid container spacing={1}>
                                <Grid container alignItems="center" item>
                                  {this.state.isSubmitForm && !(geofenceDetail.enableForAdminSetting || geofenceDetail.enableForAdminHistory) && (
                                    <FormHelperText id="enableForAdminHistory" error>
                                      {t('validation.required', {field: t('geofence.enableForAdmin')})}
                                    </FormHelperText>
                                  )}
                                </Grid>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        )}

                        {this.state.geofenceDetail.enable_for_app && (
                          <TableRow>
                            <TableCell>{t('geofence.display_for_app')}</TableCell>
                            <TableCell>
                              <FormGroup row>
                                <FormControlLabel
                                  label={t(GEOFENCE_PREVIEWS[1].i18n)}
                                  control={
                                    <Checkbox
                                      checked={geofenceDetail.display_for_app?.includes(GEOFENCE_PREVIEWS[1].id)}
                                      value={GEOFENCE_PREVIEWS[1].id}
                                      onChange={(event) => this.handleCheckDisplayForApp(event)}
                                    />
                                  }
                                />
                              </FormGroup>
                            </TableCell>
                          </TableRow>
                        )}

                        {/* name */}
                        <TableRow>
                          <TableCell>
                            {t('geofence.name')} <span className="font_color_red">＊</span>
                          </TableCell>
                          <TableCell>
                            <TextField
                              name="name"
                              value={geofenceDetail.name || ''}
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              onChange={this.handleFieldChange}
                              variant="outlined"
                              placeholder={t('placeholder.required', {field: t('geofence.name')})}
                            />
                            {this.validator.message('name', geofenceDetail.name, 'required')}
                            <Grid container spacing={1}>
                              <Grid container alignItems="center" item>
                                {this.state.isSubmitForm && !this.validator.check(geofenceDetail.name, 'required') && (
                                  <FormHelperText id="name" error>
                                    {t('validation.required', {field: t('geofence.name')})}
                                  </FormHelperText>
                                )}
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>

                        {/* Geometry */}
                        <TableRow>
                          <TableCell>{t('geofence.geometry')}</TableCell>
                          <TableCell>
                            <TextField
                              name="geometry"
                              helperText={this.state.jsonErr && 'Invalid JSON'}
                              error={this.state.jsonErr}
                              multiline
                              rows={10}
                              rowsMax={10}
                              defaultValue={jsonToString(geofenceDetail.geometry) || ''}
                              fullWidth
                              InputProps={{style: {fontSize: 12, fontFamily: 'consolas'}}}
                              margin="dense"
                              onChange={this.handleChangeGeometry}
                              variant="outlined"
                              placeholder={t('placeholder.required', {field: t('geofence.geometry')})}
                            />
                            <Grid container spacing={1}>
                              <Grid container alignItems="center" item>
                                {this.state.isSubmitForm && !geofenceDetail.swat_geofence_key && !this.validator.check(geofenceDetail.geometry, 'required') && (
                                  <FormHelperText id="geometry" error>
                                    {t('validation.required', {field: t('geofence.geometry')})}
                                  </FormHelperText>
                                )}
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>

                        {/* service group id */}
                        <TableRow>
                          <TableCell>
                            {t('geofence.service_group')} <span className="font_color_red">＊</span>
                          </TableCell>
                          <TableCell>
                            <TextField
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              variant="outlined"
                              placeholder={t('placeholder.required', {field: t('geofence.service_group')})}
                              value={this.state.selectedServiceGroup?.name || ''}
                              onClick={() => this.setState({isSearchServiceGroupModalOpened: true})}
                            />
                            {this.validator.message('service_group_id', geofenceDetail.service_group_id, 'required')}
                            <Grid container spacing={1}>
                              <Grid container alignItems="center" item>
                                {this.state.isSubmitForm && !this.validator.check(geofenceDetail.service_group_id, 'required') && (
                                  <FormHelperText id="service_group_id" error>
                                    {t('validation.required', {field: t('geofence.service_group')})}
                                  </FormHelperText>
                                )}
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>

                        {/* Project ID */}
                        <TableRow>
                          <TableCell>{t('serviceGroup.project_id')}</TableCell>
                          <TableCell>
                            <Select
                              variant="outlined"
                              value={geofenceDetail?.project_id}
                              name="project_id"
                              onChange={this.handleFieldChange}
                              className="field_size_20 field_min_size_300"
                              displayEmpty
                              renderValue={
                                geofenceDetail.project_id ?
                                  undefined :
                                  () => <div className="font-12 color-disabled">{t('placeholder.required_select', {field: t('serviceGroup.project_id')})}</div>
                              }
                            >
                              {listProject?.map((project, index) => (
                                <MenuItem value={project?.id} key={index}>
                                  {project?.id}
                                </MenuItem>
                              ))}
                            </Select>
                          </TableCell>
                        </TableRow>

                        {/* sim */}
                        {!isCreate && (
                          <TableRow>
                            <TableCell>{t('geofence.simulation_id')}</TableCell>
                            <TableCell>
                              <Table>
                                <TableBody>
                                  <TableRow>{this.renderSimulation('simulation_taxi', 'Taxi')}</TableRow>
                                  <TableRow>{this.renderSimulation('on_demand_sim_id', 'ON_DEMAND')}</TableRow>
                                  <TableRow>{this.renderSimulation('jit_home_to_work_sim_id', 'HTW')}</TableRow>
                                  <TableRow>{this.renderSimulation('jit_work_to_home_sim_id', 'WTH')}</TableRow>
                                  <TableRow>{this.renderSimulation('unitrand_sim_id', 'Unitrand', true)}</TableRow>
                                </TableBody>
                              </Table>
                            </TableCell>
                          </TableRow>
                        )}

                        {/* in hole */}
                        <TableRow>
                          <TableCell>{t('geofence.in_hole')}</TableCell>
                          <TableCell>
                            <Checkbox name="in_hole" checked={geofenceDetail.in_hole} onChange={this.handleFieldChange} />
                          </TableCell>
                        </TableRow>

                        {/* country id */}
                        <TableRow>
                          <TableCell>
                            {t('common.country')} <span className="font_color_red">＊</span>
                          </TableCell>
                          <TableCell>
                            <Select
                              variant="outlined"
                              value={String(geofenceDetail.country_id || geofenceDetail.country?.id)}
                              name="country_id"
                              onChange={this.handleFieldChange}
                              className="field_size_20 field_min_size_200"
                              displayEmpty
                              renderValue={
                                geofenceDetail.country_id ?
                                  undefined :
                                  () => <div className="font-12 color-disabled">{t('placeholder.required_select', {field: t('common.country')})}</div>
                              }
                            >
                              {country_code?.map((country, index) => (
                                <MenuItem value={country.id} key={index}>
                                  {country.country_name}
                                </MenuItem>
                              ))}
                            </Select>
                            {this.validator.message('country_id', geofenceDetail.country_id, 'required|min:0,num')}
                            <Grid container spacing={1}>
                              <Grid container alignItems="center" item>
                                {this.state.isSubmitForm && !this.validator.check(geofenceDetail.country_id, 'required') && (
                                  <FormHelperText id="country_id" error>
                                    {t('validation.required', {field: t('common.country')})}
                                  </FormHelperText>
                                )}
                                {this.state.isSubmitForm && !this.validator.check(geofenceDetail.country_id, 'min:0,num') && (
                                  <FormHelperText id="country_id" error>
                                    {t('validation.invalid.field', {field: t('common.country')})}
                                  </FormHelperText>
                                )}
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>

                        {/* Operating hours */}
                        <TableRow>
                          <TableCell>
                            {t('business_plan.operation_time')}
                            <span className="font_color_red">＊</span>
                          </TableCell>
                          <TableCell align="left">
                            <FormControl>
                              <CustomTimePicker
                                format={TIME_FORMAT}
                                allowEmpty
                                showSecond={false}
                                use12Hours={false}
                                autoComplete="off"
                                placeholder="From"
                                name="operating_start_time"
                                className="field_size_20 field_min_size_200"
                                value={geofenceDetail.operating_start_time || null}
                                onChange={(value) => {
                                  this.setState({geofenceDetail: {...geofenceDetail, operating_start_time: value}});
                                }}
                              />
                              {this.validator.message('operating_start_time', geofenceDetail.operating_start_time, 'required')}
                              {this.state.isSubmitForm &&
                                ((!this.validator.check(geofenceDetail.operating_start_time, 'required') && (
                                  <FormHelperText id="operating_start_time" error>
                                    {t('validation.required', {field: t('geofence.operating_start_time')})}
                                  </FormHelperText>
                                )) ||
                                  (!this.checkValidTimeRange(geofenceDetail.operating_start_time, geofenceDetail.operating_end_time) && (
                                    <FormHelperText id="time_range" error>
                                      {t('validation.invalid.timeRange')}
                                    </FormHelperText>
                                  )))}
                            </FormControl>
                            <FormControl>
                              <CustomTimePicker
                                format={TIME_FORMAT}
                                allowEmpty
                                showSecond={false}
                                use12Hours={false}
                                autoComplete="off"
                                placeholder="To"
                                name="operating_end_time"
                                className="field_size_20 field_min_size_200"
                                value={geofenceDetail.operating_end_time || null}
                                onChange={(value) => this.setState({geofenceDetail: {...geofenceDetail, operating_end_time: value}})}
                              />
                              {this.validator.message('operating_end_time', geofenceDetail.operating_end_time, 'required')}
                              {this.state.isSubmitForm &&
                                ((!this.validator.check(geofenceDetail.operating_end_time, 'required') && (
                                  <FormHelperText id="operating_end_time" error>
                                    {t('validation.required', {field: t('geofence.operating_end_time')})}
                                  </FormHelperText>
                                )) ||
                                  (!this.checkValidTimeRange(this.state.geofenceDetail.operating_start_time, this.state.geofenceDetail.operating_end_time) && (
                                    <FormHelperText id="time_range" error>
                                      {t('validation.invalid.timeRange')}
                                    </FormHelperText>
                                  )))}
                            </FormControl>
                          </TableCell>
                        </TableRow>

                        {/* Color in Top Map */}
                        <TableRow>
                          <TableCell colspan={2}>
                            <Box my={1}>
                              <b>{t('geofence.color_in_top_map')}</b>
                            </Box>
                          </TableCell>
                        </TableRow>

                        {/* bg color in top map*/}
                        <TableRow>
                          <TableCell>
                            <Box ml={3}>{t('geofence.bg_color')}</Box>
                          </TableCell>
                          <TableCell>
                            <TextField
                              type="color"
                              name="bg_color"
                              value={geofenceDetail.bg_color}
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              onChange={this.handleFieldChange}
                              variant="outlined"
                            />
                          </TableCell>
                        </TableRow>

                        {/* bg color opacity in top map */}
                        <TableRow>
                          <TableCell>
                            <Box ml={3}>{t('geofence.opacity')}</Box>
                          </TableCell>
                          <TableCell>
                            <Slider
                              valueLabelDisplay="auto"
                              step={5}
                              marks
                              style={{width: 300}}
                              value={Number(geofenceDetail.rawOpacity) || 0}
                              onChange={(event, newValue) => {
                                const {geofenceDetail} = this.state;
                                geofenceDetail.rawOpacity = newValue;
                                this.setState({geofenceDetail});
                              }}
                            />
                            <span style={{color: '#3f51b5', fontSize: 16, marginLeft: 15, position: 'relative', bottom: 8}}>{geofenceDetail.rawOpacity}</span>
                          </TableCell>
                        </TableRow>

                        {/* border-color in top map*/}
                        <TableRow>
                          <TableCell>
                            <Box ml={3}>{t('geofence.border_color')}</Box>
                          </TableCell>
                          <TableCell>
                            <TextField
                              type="color"
                              name="border_color"
                              value={geofenceDetail.border_color}
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              onChange={this.handleFieldChange}
                              variant="outlined"
                            />
                          </TableCell>
                        </TableRow>

                        {/* border-color opacity in top map */}
                        <TableRow>
                          <TableCell>
                            <Box ml={3}>{t('geofence.opacity_border')}</Box>
                          </TableCell>
                          <TableCell>
                            <Slider
                              valueLabelDisplay="auto"
                              step={5}
                              marks
                              style={{width: 300}}
                              value={Number(geofenceDetail.rawOpacityBorder) || 0}
                              onChange={(event, newValue) => {
                                const {geofenceDetail} = this.state;
                                geofenceDetail.rawOpacityBorder = newValue;
                                this.setState({geofenceDetail});
                              }}
                            />
                            <span style={{color: '#3f51b5', fontSize: 16, marginLeft: 15, position: 'relative', bottom: 8}}>{geofenceDetail.rawOpacityBorder}</span>
                          </TableCell>
                        </TableRow>

                        {/* Color in My Page */}
                        <TableRow>
                          <TableCell colspan={2}>
                            <Box my={1}>
                              <b>{t('geofence.color_in_my_page')}</b>
                            </Box>
                          </TableCell>
                        </TableRow>

                        {/* bg color in my page */}
                        <TableRow>
                          <TableCell>
                            <Box ml={3}>{t('geofence.bg_color')}</Box>
                          </TableCell>
                          <TableCell>
                            <TextField
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              variant="outlined"
                              type="color"
                              name="mypage_bg_color"
                              value={geofenceDetail.mypage_bg_color}
                              onChange={this.handleFieldChange}
                            />
                          </TableCell>
                        </TableRow>

                        {/* bg color opacity in my page */}
                        <TableRow>
                          <TableCell>
                            <Box ml={3}>{t('geofence.opacity')}</Box>
                          </TableCell>
                          <TableCell>
                            <Slider
                              valueLabelDisplay="auto"
                              step={5}
                              marks
                              style={{width: 300}}
                              value={Number(geofenceDetail.mypage_bg_opacity) || 0}
                              onChange={(event, value) => {
                                this.setState({
                                  geofenceDetail: {
                                    ...this.state.geofenceDetail,
                                    mypage_bg_opacity: value,
                                  },
                                });
                              }}
                            />
                            <span style={{color: '#3f51b5', fontSize: 16, marginLeft: 15, position: 'relative', bottom: 8}}>{geofenceDetail.mypage_bg_opacity}</span>
                          </TableCell>
                        </TableRow>

                        {/* border color in my page */}
                        <TableRow>
                          <TableCell>
                            <Box ml={3}>{t('geofence.border_color')}</Box>
                          </TableCell>
                          <TableCell>
                            <TextField
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              variant="outlined"
                              type="color"
                              name="mypage_border_color"
                              value={geofenceDetail.mypage_border_color}
                              onChange={this.handleFieldChange}
                            />
                          </TableCell>
                        </TableRow>

                        {/* border color opacity in my page */}
                        <TableRow>
                          <TableCell>
                            <Box ml={3}>{t('geofence.opacity_border')}</Box>
                          </TableCell>
                          <TableCell>
                            <Slider
                              valueLabelDisplay="auto"
                              step={5}
                              marks
                              style={{width: 300}}
                              value={Number(geofenceDetail.mypage_border_opacity) || 0}
                              onChange={(event, value) => {
                                this.setState({
                                  geofenceDetail: {
                                    ...this.state.geofenceDetail,
                                    mypage_border_opacity: value,
                                  },
                                });
                              }}
                            />
                            <span style={{color: '#3f51b5', fontSize: 16, marginLeft: 15, position: 'relative', bottom: 8}}>{geofenceDetail.mypage_border_opacity}</span>
                          </TableCell>
                        </TableRow>

                        {/* Geofence preview: In top map or in my page*/}
                        <TableRow>
                          <TableCell>{t('geofence.geofence_preview')}</TableCell>
                          <TableCell>
                            <FormControl>
                              <RadioGroup row aria-label="geofence_preview" name="geofence_preview">
                                {GEOFENCE_PREVIEWS.map((item) => (
                                  <FormControlLabel
                                    key={item.id}
                                    control={
                                      <Radio
                                        value={item.id}
                                        color="primary"
                                        checked={this.state.geofence_preview === item.id}
                                        onChange={(event) => this.setState({geofence_preview: event.target.value})}
                                      />
                                    }
                                    label={t(item.i18n)}
                                  />
                                ))}
                              </RadioGroup>
                            </FormControl>
                          </TableCell>
                        </TableRow>

                        {/* description */}
                        <TableRow>
                          <TableCell>{t('geofence.description')}</TableCell>
                          <TableCell>
                            <TextField
                              multiline
                              name="description"
                              value={geofenceDetail.description || ''}
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              onChange={this.handleFieldChange}
                              variant="outlined"
                              placeholder={t('placeholder.required', {field: t('geofence.description')})}
                            />
                          </TableCell>
                        </TableRow>

                        {/* vehicle modes */}
                        <TableRow>
                          <TableCell>
                            {t('businessVehicle.vehicle_mode')}
                            <span className="font_color_red">＊</span>
                          </TableCell>
                          <TableCell>
                            <FormControl margin="dense" variant="outlined" className="field_size_20 field_min_size_300">
                              <Select
                                margin="dense"
                                variant="outlined"
                                name="vehicle_mode"
                                displayEmpty
                                renderValue={
                                  geofenceDetail.vehicle_mode ?
                                    undefined :
                                    () => <div className="font-12 color-disabled">{t('placeholder.required_select', {field: t('businessVehicle.vehicle_mode')})}</div>
                                }
                                value={geofenceDetail.vehicle_mode}
                                onChange={(event) => this.handleFieldChange(event)}
                              >
                                {businessVehicle?.listVehicleMode?.map((item, idx) => (
                                  <MenuItem key={idx} value={item.mode}>
                                    {item.mode}
                                  </MenuItem>
                                ))}
                              </Select>
                              {this.validator.message('vehicle_mode', geofenceDetail.vehicle_mode, 'required')}
                              {this.state.isSubmitForm && !this.validator.check(geofenceDetail.vehicle_mode, 'required') && (
                                <FormHelperText id="vehicle_mode_required" error>
                                  {t('placeholder.required_select', {field: t('businessVehicle.vehicle_mode')})}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </TableCell>
                        </TableRow>

                        {/* Min walking distance show */}
                        <TableRow>
                          <TableCell>{t('geofence.min_walking_distance_show')}</TableCell>
                          <TableCell>
                            <TextField
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              variant="outlined"
                              type="number"
                              placeholder={t('placeholder.required', {field: t('geofence.min_walking_distance_show')})}
                              name="min_walking_distance_show"
                              value={geofenceDetail.min_walking_distance_show}
                              onChange={(event) => this.handleFieldChange(event)}
                            />
                          </TableCell>
                        </TableRow>

                        {/* Max walking distance show */}
                        <TableRow>
                          <TableCell>{t('geofence.max_walking_distance_show')}</TableCell>
                          <TableCell>
                            <TextField
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              variant="outlined"
                              type="number"
                              placeholder={t('placeholder.required', {field: t('geofence.max_walking_distance_show')})}
                              name="max_walking_distance_show"
                              value={geofenceDetail.max_walking_distance_show}
                              onChange={(event) => this.handleFieldChange(event)}
                            />
                          </TableCell>
                        </TableRow>

                        {/* Mapbox path segment */}
                        <TableRow>
                          <TableCell>{t('geofence.mapbox_path_segment')}</TableCell>
                          <TableCell>
                            <FormControl variant="outlined" className="field_size_20 field_min_size_300">
                              <Select
                                margin="dense"
                                variant="outlined"
                                name="mapbox_path_segment"
                                value={geofenceDetail.mapbox_path_segment}
                                onChange={(event) => this.handleFieldChange(event)}
                                displayEmpty
                                renderValue={
                                  geofenceDetail.mapbox_path_segment ?
                                    undefined :
                                    () => <div className="font-12 color-disabled">{t('placeholder.required_select', {field: t('geofence.mapbox_path_segment')})}</div>
                                }
                              >
                                <MenuItem value="">
                                  <em className="color-disabled">{t('geofence.none')}</em>
                                </MenuItem>
                                {['tdroute', 'route'].map((item, idx) => (
                                  <MenuItem key={idx} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </TableCell>
                        </TableRow>

                        {/* Allow Infants */}
                        {country && country?.country_code.toUpperCase() === 'JP' && (
                          <TableRow>
                            <TableCell>{t('geofence.allow_infant')}</TableCell>
                            <TableCell>
                              <Switch
                                checked={geofenceDetail.allow_infants}
                                onChange={(event) => this.setState({geofenceDetail: {...geofenceDetail, allow_infants: event.target.checked}})}
                                name="allow_infants"
                                inputProps={{'aria-label': 'secondary checkbox'}}
                              />
                            </TableCell>
                          </TableRow>
                        )}
                        {/* Time to display re-loading popup for user booking (minutes) */}
                        <TableRow>
                          <TableCell>
                            {t('geofence.offer_estimation_timeout')}
                            <span className="font_color_red">＊</span>
                          </TableCell>
                          <TableCell>
                            <TextField
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              variant="outlined"
                              inputProps={{
                                name: 'offer_estimation_timeout',
                              }}
                              placeholder={t('placeholder.required', {field: t('geofence.offer_estimation_timeout')})}
                              value={this.state.geofenceDetail.offer_estimation_timeout}
                              onChange={(e) => this.handleFieldChange(e)}
                            />
                            {this.validator.message('offer_estimation_timeout', this.state.geofenceDetail.offer_estimation_timeout, 'required|integer|min:0,num')}
                            {this.state.isSubmitForm &&
                              (this.validator.check(this.state.geofenceDetail.offer_estimation_timeout, 'required') ? (
                                !this.validator.check(this.state.geofenceDetail.offer_estimation_timeout, 'integer|min:0,num') && (
                                  <FormHelperText id="offer_estimation_timeout_invalid" error className="display_block">
                                    {t('geofence.offer_estimation_timeout_invalid')}
                                  </FormHelperText>
                                )
                              ) : (
                                <FormHelperText id="offer_estimation_timeout_required" error className="display_block">
                                  {t('placeholder.required', {field: t('geofence.offer_estimation_timeout')})}
                                </FormHelperText>
                              ))}
                          </TableCell>
                        </TableRow>

                        {/* Vehicle Additional Information */}
                        <TableRow>
                          <TableCell>{t('businessVehicle.vehicleAddition')}</TableCell>
                          <TableCell>
                            <Switch
                              checked={this.state.isOnVehicleAdditional}
                              onChange={(event) => this.setState({isOnVehicleAdditional: event.target.checked})}
                              name="country_id"
                              inputProps={{'aria-label': 'secondary checkbox'}}
                            />
                          </TableCell>
                        </TableRow>

                        {/* Enable waypoint assiciation */}
                        <TableRow>
                          <TableCell>Enable waypoint association</TableCell>
                          <TableCell>
                            <Switch
                              checked={geofenceDetail.enable_waypoint_association}
                              onChange={(event) => this.setState({geofenceDetail: {...geofenceDetail, enable_waypoint_association: event.target.checked}})}
                              name="country_id"
                              inputProps={{'aria-label': 'secondary checkbox'}}
                            />
                          </TableCell>
                        </TableRow>
                        {/* Frame time price config */}
                        <TableRow>
                          <TableCell>Enable surcharge by frame time</TableCell>
                          <TableCell>
                            <Switch
                              checked={geofenceDetail.enable_frame_time_price}
                              onChange={(event) => this.setState({geofenceDetail: {...geofenceDetail, enable_frame_time_price: event.target.checked}})}
                              name="country_id"
                              inputProps={{'aria-label': 'secondary checkbox'}}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{t('geofence.settingLastOrderTime')}</TableCell>
                          <TableCell>
                            <Switch
                              checked={geofenceDetail.enable_last_order_time}
                              onChange={(event) => this.onChangeEnableLastOrderTime(event)}
                              name="enable_last_order_time"
                              inputProps={{'aria-label': 'secondary checkbox'}}
                            />
                          </TableCell>
                        </TableRow>
                        {geofenceDetail.enable_last_order_time && (
                          <TableRow>
                            <TableCell>{t('geofence.lastOrderTime')}</TableCell>
                            <TableCell>
                              <CustomTimePicker
                                name="last_order_time"
                                className="field_size_10 field_min_size_250"
                                value={geofenceDetail.last_order_time || null}
                                showSecond={false}
                                placeholder={TIME_FORMAT}
                                allowEmpty
                                format={TIME_FORMAT}
                                use12Hours={false}
                                autoComplete="off"
                                onChange={(value) => this.setState({geofenceDetail: {...geofenceDetail, last_order_time: value}})}
                              />
                              <Grid>
                                {this.state.isSubmitForm && !this.validator.check(geofenceDetail.last_order_time, 'required') && geofenceDetail.enable_last_order_time && (
                                  <FormHelperText id="last_order_time" error>
                                    {t('validation.required', {field: t('geofence.lastOrderTime')})}
                                  </FormHelperText>
                                )}
                              </Grid>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                    <br></br>
                    <Grid container alignItems="flex-end">
                      {isCreate ? (
                        <>
                          <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={this.openDialog}>
                            {t('common.btnRegister')}
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button color="primary" variant="contained" endIcon={<CloudUploadIcon />} onClick={this.openDialog}>
                            {t('common.btnUpdate')}
                          </Button>
                          {isUUID(geofenceDetail.geofence_id) ? (
                            <Button
                              variant="contained"
                              disabled={isSyncing}
                              style={{margin: '0 10px'}}
                              className={!isSyncing ? 'button_color_green' : ''}
                              startIcon={<SyncIcon className={isSyncing ? 'sync spinner' : ''} />}
                              color="primary"
                              onClick={this.openSyncDialog}
                            >
                              {isSyncing ? t('geofence.syncing') : t('geofence.sync_swat')}
                            </Button>
                          ) : (
                            <></>
                          )}
                          {geofenceDetail.last_synched_at && (
                            <Tooltip title={`At ${new Date(geofenceDetail.last_synched_at).toLocaleString()}`} placement="top" arrow>
                              <span>{t('geofence.geofence_last_sync', {last_sync: getTimeAgo(geofenceDetail.last_synched_at)})}</span>
                            </Tooltip>
                          )}
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={6}>
                    <DisplayMap
                      geometry={geofenceDetail?.geometry}
                      color={isTopMap ? geofenceDetail?.bg_color : geofenceDetail?.mypage_bg_color}
                      border_color={isTopMap ? geofenceDetail?.border_color : geofenceDetail?.mypage_border_color}
                      opacity={isTopMap ? Number(geofenceDetail?.rawOpacity) : Number(geofenceDetail?.mypage_bg_opacity)}
                      height={700}
                      stroke_opacity={isTopMap ? Number(geofenceDetail.rawOpacityBorder) : Number(geofenceDetail?.mypage_border_opacity)}
                      onError={this.handleGeometryError}
                      centroid={geofenceDetail.centroid}
                      polylines={geofenceDetail.map_setting?.polylines || []}
                    ></DisplayMap>
                  </Grid>
                </Grid>
              </Container>
            </Card>
          </LoadingOverlay>
          <br></br>
        </Container>
        <Dialog
          open={this.state.showDialog}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <SelectModal
            onClickOk={isCreate ? this.handleUpdateGeofence : this.checkGeofenceTime}
            onClickCancel={this.closeDialog}
            message={isCreate ? t('geofence.confirm_register') : t('geofence.confirm_update')}
          ></SelectModal>
        </Dialog>
        <Dialog
          open={this.state.showWarningDialog}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <SelectModal
            onClickOk={this.checkGeofenceTimeBookingAffect}
            onClickCancel={() => this.setState({showWarningDialog: false})}
            message={t('geofence.warning.set_overlapped_plan_time')}
          ></SelectModal>
        </Dialog>
        <Dialog
          open={this.state.bookingCancelDialog}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <SelectModal
            onClickOk={this.handleUpdateGeofence}
            onClickCancel={() => this.setState({bookingCancelDialog: false})}
            message={'businessVehicle.cancel_all_booking'}
            fields={{field: this.state.bookingCancelNumber}}
            listBookingCancel={this.state.bookingCancelIds}
            isSuccessBookingCancel={this.state.isSuccessBookingCancel}
          ></SelectModal>
        </Dialog>
        <Dialog
          open={this.state.showSyncDialog}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <SelectModal onClickOk={this.handleSyncGeofence} onClickCancel={this.closeSyncDialog} message={t('geofence.confirm_sync')}></SelectModal>
        </Dialog>
        <Dialog
          open={this.state.odmSettingModalOpen}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
          maxWidth="xl"
        >
          <OnDemandSettingModal onCloseModal={() => this.setState({odmSettingModalOpen: false})} geofenceId={geofenceDetail.geofence_id} />
        </Dialog>
        {/* modal success cancel booking */}
        <Dialog
          open={this.state.isSuccessBookingCancel}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <CancelBookingSuccessModal
            message={'Api.success'}
            closeMessage={() => this.setState({isSuccessBookingCancel: false, bookingCancelIds: []})}
            listBookingCancel={this.state.bookingCancelIds}
          ></CancelBookingSuccessModal>
        </Dialog>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.isSearchServiceGroupModalOpened}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <SearchServiceGroupModal
            parentData={this.state.selectedServiceGroup}
            onClickOk={() => this.setState({isSearchServiceGroupModalOpened: false})}
            onClickClose={() => this.setState({isSearchServiceGroupModalOpened: false})}
            onSelect={(value) =>
              this.setState({
                selectedServiceGroup: value,
                geofenceDetail: {
                  ...geofenceDetail,
                  service_group_id: value.id,
                },
              })
            }
          />
        </Modal>
      </Card>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    geofence: state.geofence,
    country_code: state.common.country_code,
    businessVehicle: state.businessVehicle,
    allServiceGroups: state.serviceGroup.allServiceGroups.content,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDetailGeofence: (id) => dispatch(getDetailGeofence(id)),
    updateGeofence: (updatePayload) => dispatch(updateGeofence(updatePayload)),
    syncGeofence: (uuid) => dispatch(syncGeofence(uuid)),
    getEnableSimulation: (payload) => dispatch(getEnableSimulation(payload)),
    getAllServiceGroups: () => dispatch(getAllServiceGroups({page: 0, size: 99999, enable: true})),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getListVehicleMode: () => dispatch(getListVehicleMode()),
    checkGeofenceTime: (payload) => dispatch(checkGeofenceTime(payload)),
    getProject: () => dispatch(getProject()),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
  };
};

export default withPermissionData(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(GeofenceDetail)));
