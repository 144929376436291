export const ACTION_TYPES = {
  SEARCH_LIST_PRODUCT_START: 'SEARCH_LIST_PRODUCT_START',
  SEARCH_LIST_PRODUCT_SUCCESS: 'SEARCH_LIST_PRODUCT_SUCCESS',
  SEARCH_LIST_PRODUCT_FAIL: 'SEARCH_LIST_PRODUCT_FAIL',
  GET_VEHICLE_START: 'GET_VEHICLE_START',
  GET_VEHICLE_SUCCESS: 'GET_VEHICLE_SUCCESS',
  GET_VEHICLE_FAIL: 'GET_VEHICLE_FAIL',

  SEARCH_LIST_VEHICLE_CONFIG_START: 'SEARCH_LIST_VEHICLE_CONFIG_START',
  SEARCH_LIST_VEHICLE_CONFIG_SUCCESS: 'SEARCH_LIST_VEHICLE_CONFIG_SUCCESS',
  SEARCH_LIST_VEHICLE_CONFIG_FAIL: 'SEARCH_LIST_VEHICLE_CONFIG_FAIL',

  DETAILS_VEHICLE_CONFIG_START: 'DETAILS_VEHICLE_CONFIG_START',
  DETAILS_VEHICLE_CONFIG_SUCCESS: 'DETAILS_VEHICLE_CONFIG_SUCCESS',
  DETAILS_VEHICLE_CONFIG_FAIL: 'DETAILS_VEHICLE_CONFIG_FAIL',

  UPDATE_VEHICLE_CONFIG_START: 'UPDATE_VEHICLE_CONFIG_START',
  UPDATE_VEHICLE_CONFIG_SUCCESS: 'UPDATE_VEHICLE_CONFIG_SUCCESS',
  UPDATE_VEHICLE_CONFIG_FAIL: 'UPDATE_VEHICLE_CONFIG_FAIL',

  GET_VEHICLE_FREQUENCY_HISTORY_START: 'GET_VEHICLE_FREQUENCY_HISTORY_START',
  GET_VEHICLE_FREQUENCY_HISTORY_SUCCESS: 'GET_VEHICLE_FREQUENCY_HISTORY_SUCCESS',
  GET_VEHICLE_FREQUENCY_HISTORY_FAIL: 'GET_VEHICLE_FREQUENCY_HISTORY_FAIL',

  GET_VEHICLE_LIST_BY_GEOFENCE_START: 'GET_VEHICLE_LIST_BY_GEOFENCE_START',
  GET_VEHICLE_LIST_BY_GEOFENCE_SUCCESS: 'GET_VEHICLE_LIST_BY_GEOFENCE_SUCCESS',
  GET_VEHICLE_LiST_BY_GEOFENCE_FAIL: 'GET_VEHICLE_LiST_BY_GEOFENCE_FAIL',

  GET_LIST_VEHICLE_ON_GOING_START: 'GET_LIST_VEHICLE_ON_GOING_START',
  GET_LIST_VEHICLE_ON_GOING_SUCCESS: 'GET_LIST_VEHICLE_ON_GOING_SUCCESS',
  GET_LIST_VEHICLE_ON_GOING_FAIL: 'GET_LIST_VEHICLE_ON_GOING_FAIL',

  GET_VEHICLE_LOCATION_START: 'GET_VEHICLE_LOCATION_START',
  GET_VEHICLE_LOCATION_SUCCESS: 'GET_VEHICLE_LOCATION_SUCCESS',
  GET_VEHICLE_LOCATION_FAIL: 'GET_VEHICLE_LOCATION_FAIL',
};

const initialState = {
  isLoading: false,
  listVehicles: [],
  vehicle: {},
  error: '',
  listVehicleConfig: [],
  detailsVehicleConfig: {},
  mapData: [],
  vehicleListByGeofence: [],
};

/**
 * function reduce change password
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_VEHICLE_CONFIG_START:
    case ACTION_TYPES.SEARCH_LIST_PRODUCT_START:
    case ACTION_TYPES.GET_VEHICLE_START:
    case ACTION_TYPES.SEARCH_LIST_VEHICLE_CONFIG_START:
    case ACTION_TYPES.DETAILS_VEHICLE_CONFIG_START:
    case ACTION_TYPES.GET_VEHICLE_LIST_BY_GEOFENCE_START:
    case ACTION_TYPES.GET_VEHICLE_FREQUENCY_HISTORY_START:
    case ACTION_TYPES.GET_LIST_VEHICLE_ON_GOING_START:
    case ACTION_TYPES.GET_VEHICLE_LOCATION_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.SEARCH_LIST_VEHICLE_CONFIG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listVehicleConfig: action.listVehicleConfig,
      };
    case ACTION_TYPES.DETAILS_VEHICLE_CONFIG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        detailsVehicleConfig: action.detailsVehicleConfig,
      };
    case ACTION_TYPES.UPDATE_VEHICLE_CONFIG_SUCCESS:
    case ACTION_TYPES.UPDATE_VEHICLE_CONFIG_FAIL:
    case ACTION_TYPES.DETAILS_VEHICLE_CONFIG_FAIL:
    case ACTION_TYPES.SEARCH_LIST_VEHICLE_CONFIG_FAIL:
    case ACTION_TYPES.GET_LIST_VEHICLE_ON_GOING_SUCCESS:
    case ACTION_TYPES.GET_LIST_VEHICLE_ON_GOING_FAIL:
    case ACTION_TYPES.GET_VEHICLE_LOCATION_SUCCESS:
    case ACTION_TYPES.GET_VEHICLE_LOCATION_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.GET_VEHICLE_LIST_BY_GEOFENCE_SUCCESS:
      return {
        isLoading: false,
        vehicleListByGeofence: action.vehicleListByGeofence,
        error: '',
      };
    case ACTION_TYPES.SEARCH_LIST_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listVehicles: action.listVehicles,
        error: '',
      };
    case ACTION_TYPES.GET_VEHICLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        vehicle: action.vehicle,
        error: '',
      };
    case ACTION_TYPES.GET_VEHICLE_FREQUENCY_HISTORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        mapData: action.mapData,
        error: '',
      };
    case ACTION_TYPES.SEARCH_LIST_PRODUCT_FAIL:
      return {
        isLoading: false,
        error: action.error,
        listProduct: [],
      };
    case ACTION_TYPES.GET_VEHICLE_FAIL:
      return {
        ...state,
        isLoading: false,
        vehicle: {},
        error: '',
      };
    case ACTION_TYPES.GET_VEHICLE_FREQUENCY_HISTORY_FAIL:
      return {
        ...state,
        isLoading: false,
        mapData: [],
        error: '',
      };
    case ACTION_TYPES.GET_VEHICLE_LiST_BY_GEOFENCE_FAIL:
      return {
        ...state,
        isLoading: false,
        listVehicles: [],
        error: '',
      };
    default:
      return state;
  }
}
