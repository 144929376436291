import React, {Component} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {Button, Card, Container, Dialog, FormControl, Grid, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DetailIcon from '@material-ui/icons/Description';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import HistoryIcon from '@material-ui/icons/History';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {OWNER_TYPE, COMMON_STATUS, ROUTE, ROWS_PER_PAGE_OPTIONS} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import {getAllCountryCode, getAllGeofence} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {onChangeTextField, onChangeSelect, getUrlParams} from '../../../utils/common';
import withPermissionData from '../../../withPermissionData/hoc';
// import {modalObj} from '../../../utils/modal';

/**
 * QRManagement component
 */
class QRManagement extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      name: '',
      country_id: '',
      geofence_id: '',
      owner: '',
      partner: '',
      listGeofenceAll: [],
      status: '',
      start_date: null,
      end_date: null,
      result: [],
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,

      flagDelete: false,
      message: '',
      id: null,
      index: null,
      isLoading: false,
    };
  }

  /**
   * initial state
   */
  componentDidMount = async () => {
    await this.props.getAllCountryCode();
    await this.props.getAllGeofence().then(() => this.setState({listGeofenceAll: this.props.common?.all_geofence}));
    if (this.props.location.search) {
      this.state.country_id &&
        this.setState({
          listGeofenceAll: this.props.common?.all_geofence?.filter((e) => e.country_id === Number(this.state.country_id)),
        });
      const params = getUrlParams(this.props.location.search);
      this.setState(params, () => {
        this.handleSearch();
      });
    }
  };

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  handlePageChange = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearch);
  };

  /**
   * handleSearch
   * @param {*} reset
   */
  handleSearch = (reset) => {};

  /**
   * handleDelete
   * @param {int} id
   * @param {object} index
   */
  handleDelete = (id, index) => {
    this.setState({
      flagDelete: true,
      message: 'stop_master.question.confirm.delete',
      id,
      index,
    });
  };

  /**
   * handleButtonOk
   */
  handleButtonOk = () => {
    this.setState({
      flagDelete: false,
    });
  };

  /**
   * reset search condition
   */
  resetSearchCondition = () => {
    this.setState({
      name: '',
      country_id: '',
      geofence_id: '',
      owner: '',
      partner: '',
      listGeofenceAll: this.props.common?.all_geofence,
      status: '',
      start_date: null,
      end_date: null,
    });
  };

  /**
   * onChangeCountry
   * @param {*} value
   */
  onChangeCountry = (value) => {
    this.setState({
      country_id: value,
      geofence_id: '',
      listGeofenceAll: this.props.common?.all_geofence?.filter((e) => e.country_id + '' === value + ''),
    });
  };

  /**
   * render component
   * @return {HTML}
   */
  render() {
    const {t} = this.props;

    return (
      <>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container spacing={1} className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('qr_code.managementTitle')}</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}>
                <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.DATA_MANAGEMENT}}>
                  <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                    {t('common.btnReturn')}
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <br></br>
            <LoadingOverlay active={this.state.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
              {/* --------------------- SEARCH -------------------- */}
              <Card raised>
                <Container maxWidth="xl">
                  <br></br>
                  <Paper className="search_table">
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                        {t('businessVehicle.searchTitle')}
                      </Grid>
                    </Grid>

                    {/* Country */}
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('common.country')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl error>
                          <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'country',
                              }}
                              displayEmpty
                              value={this.state.country_id || ''}
                              onChange={(event) => this.onChangeCountry(event.target.value)}
                              renderValue={
                                this.state.country_id ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('common.country'),
                                        })}
                                      </div>
                                    )
                              }
                            >
                              {this.props.common?.country_code?.map((item, idx) => (
                                <MenuItem value={item.id} key={idx}>
                                  {t(`${item.country_code}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* geofence */}
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('common.geofence')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'geofence_id',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.geofence_id ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('common.geofence'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.geofence_id || ''}
                            onChange={(e) => onChangeSelect(this, e)}
                          >
                            {this.state.listGeofenceAll.map((item, idx) => {
                              return (
                                <MenuItem value={item.geofence_id} key={idx}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* Owner */}
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('qr_code.owner')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'owner',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.owner ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('qr_code.owner'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.owner || ''}
                            onChange={(e) => onChangeSelect(this, e)}
                          >
                            {OWNER_TYPE.map((item, idx) => {
                              return (
                                <MenuItem value={item.id} key={idx}>
                                  {t(item.i18n)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* partner/company name */}
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('qr_code.partner')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl variant="outlined" margin="dense" fullWidth>
                          <TextField
                            name="partner"
                            onChange={(event) => onChangeTextField(this, event)}
                            placeholder={t('validation.required', {field: t('qr_code.partner')})}
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            value={this.state.partner}
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* qr name */}
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('qr_code.name')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl variant="outlined" margin="dense" fullWidth>
                          <TextField
                            name="name"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('validation.required', {field: t('qr_code.name')})}
                            value={this.state.name}
                            onChange={(event) => onChangeTextField(this, event)}
                            inputProps={{maxLength: 256}}
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* usage start/end */}
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('qr_code.usage_date')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={8}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            className="field_size_20 field_min_size_300"
                            margin="dense"
                            autoOk
                            disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            format="yyyy/MM/dd"
                            value={this.state.start_date}
                            label={t('businessVehicle.start_date')}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            onChange={(time) =>
                              this.setState({
                                start_date: time,
                              })
                            }
                          />
                        </MuiPickersUtilsProvider>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            className="field_size_20 field_min_size_300 ml-20-custom"
                            margin="dense"
                            autoOk
                            disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            format="yyyy/MM/dd"
                            label={t('businessVehicle.end_date')}
                            value={this.state.end_date}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            onChange={(time) =>
                              this.setState({
                                end_date: time,
                              })
                            }
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>

                    {/* Status */}
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('common.status')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'status',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.status !== '' ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('common.status'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.status}
                            onChange={(e) => onChangeSelect(this, e)}
                          >
                            {COMMON_STATUS.map((item, idx) => {
                              return (
                                <MenuItem value={item.id} key={idx}>
                                  {t(item.i18n)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Paper>

                  <Grid container spacing={1} style={{padding: '15px 0px'}}>
                    <Grid container alignItems="center" justify="flex-start" item xs={3}>
                      <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.resetSearchCondition} endIcon={<RotateLeftIcon />}>
                        {t('common.btnResetSearch')}
                      </Button>
                    </Grid>
                    <Grid container alignItems="center" justify="flex-end" item xs={9}>
                      <Link to={ROUTE.LAYOUT + ROUTE.QR_MANAGEMENT_CREATE} style={{textDecoration: 'none'}}>
                        <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={this.openDialog}>
                          {t('common.btnRegister')}
                        </Button>
                      </Link>
                      <Button color="primary" variant="contained" className="button_margin" type="submit" endIcon={<SearchIcon />} onClick={this.handleSearch}>
                        {t('common.btnSearch')}
                      </Button>
                    </Grid>
                  </Grid>
                </Container>
              </Card>
              {/* --------------------- END SEARCH -------------------- */}

              {this.state.result?.length > 0 && (
                <>
                  <br></br>
                  <Card raised>
                    <Container maxWidth="xl">
                      <Grid container spacing={1}>
                        <Grid container alignItems="center" item xs={12}>
                          <h3>
                            {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                          </h3>
                        </Grid>
                      </Grid>
                      <div className="scroll_area_700">
                        <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                          <TableHead>
                            <TableRow>
                              <TableCell className="width_150p">{t('common.no')}</TableCell>
                              <TableCell className="width_200p">{t('common.country')}</TableCell>
                              <TableCell className="width_200p">{t('common.geofence')}</TableCell>
                              <TableCell className="width_200p">{t('qr_code.owner')}</TableCell>
                              <TableCell className="width_200p">{t('qr_code.partner')}</TableCell>
                              <TableCell className="width_200p">{t('common.name')}</TableCell>
                              <TableCell className="width_200p">{t('qr_code.usage_start')}</TableCell>
                              <TableCell className="width_200p">{t('qr_code.usage_end')}</TableCell>
                              <TableCell className="width_200p">{t('common.status')}</TableCell>
                              <TableCell style={{position: 'sticky', right: '0', width: '350px'}}>{t('common.action')}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.result?.map((row, index) => {
                              return (
                                <TableRow key={index} hover className="cursor_pointer">
                                  <TableCell>{index + 1}</TableCell>
                                  <TableCell>{row.country}</TableCell>
                                  <TableCell>{row.geofence}</TableCell>
                                  <TableCell>{row.owner}</TableCell>
                                  <TableCell>{row.partner}</TableCell>
                                  <TableCell>{row.name}</TableCell>
                                  <TableCell>{row.usage_start}</TableCell>
                                  <TableCell>{row.usage_end}</TableCell>
                                  <TableCell>{row.status}</TableCell>
                                  <TableCell className="cell_fixed_right">
                                    <Link
                                      style={{textDecoration: 'none'}}
                                      to={{
                                        pathname: ROUTE.LAYOUT + ROUTE.QR_MANAGEMENT_DETAIL + '/' + row.id,
                                        state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                      }}
                                    >
                                      <Button color="primary" variant="contained" className="button_magin" endIcon={<DetailIcon />}>
                                        {t('common.btnEdit')}
                                      </Button>
                                    </Link>
                                    &nbsp; &nbsp;
                                    <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<FileCopyIcon />}>
                                      {t('common.btnClone')}
                                    </Button>
                                    &nbsp; &nbsp;
                                    <Link
                                      style={{textDecoration: 'none'}}
                                      to={{
                                        pathname: ROUTE.LAYOUT + ROUTE.QR_MANAGEMENT_HISTORY + '/' + row.id,
                                        state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                      }}
                                    >
                                      <Button color="primary" variant="contained" className="button_margin" endIcon={<HistoryIcon />}>
                                        {t('common.btnHistory')}
                                      </Button>
                                    </Link>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </div>
                      <CustomPagination onChange={this.handlePageChange} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                    </Container>
                  </Card>
                </>
              )}
              <br />
            </LoadingOverlay>
          </Container>
        </Card>
        <Dialog
          open={this.state.flagDelete}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <SelectModal onClickOk={this.handleButtonOk} onClickCancel={() => this.setState({flagDelete: false})} message={this.state.message}></SelectModal>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getAllGeofence: () => dispatch(getAllGeofence()),
  };
};

export default withPermissionData(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(QRManagement)));
