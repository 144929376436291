/* eslint-disable max-len */
import React, {Component} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  Card,
  Container,
  Grid,
  TextField,
  Button,
  Paper,
  RadioGroup,
  Radio,
  Checkbox,
  FormControlLabel,
  FormControl,
  Select,
  FormHelperText,
  Modal,
  Backdrop,
  Dialog,
  MenuItem,
  FormGroup,
  Box,
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import {Add as AddIcon, Cancel as CancelIcon, ArrowBack as ArrowBackIcon, CloudUpload as CloudUploadIcon} from '@material-ui/icons';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import CompareArrowsIcon from '@material-ui/icons/SwapHoriz';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {format} from 'date-fns';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import ListMembersModal from './ListMembersModal';
import {PERMISSION_ACTIONS} from '../../../common/constant';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {
  searchReferrers,
  deleteBusinessPartner,
  checkReferrerCode,
  getListPlanCode,
  createBusinessPartner,
  updateBusinessPartner,
  getDetailBusinessPartner,
} from '../../../stores/business/action';
import {getAllCountryCode, getListGeofenceByCountry, getListGeofenceDetailsByIds} from '../../../stores/common/actions';
import {validateInput, isValidEmail, getListAllGeofenceDetail} from '../../../utils/common';
import {compareDateTimeRange} from '../../../utils/datetime';
import {isRoleBusiness, isRoleGlobal} from '../../../utils/role';

/**
 * BusinessPartnerSettingComponent
 */
class BusinessPartnerSettingComponent extends Component {
  /**
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      isTick: true,
      isSubmitForm: false,
      flag: false,
      isChecked: false,
      checkPlanSetting: true,
      checkReferrerCodeExist: false,
      isListMembersOpened: false,
      zone_id: '',
      message: '',
      messageDisable: '',
      referral_name_ja: '',
      referral_name_vi: '',
      referral_name_en: '',
      referral_code: '',
      referral_description_ja: '',
      referral_description_vi: '',
      referral_description_en: '',
      country_id: !isRoleGlobal() ? props.principal.country_id : '',
      list_area_id: [],
      listPlan: [{plan_id: null, plan_code: '', start_use_time: null, end_use_time: null, zone_id: null, invalidUseTime: true, checkPlanCodeExist: false}],
      listPlanCode: [],
      plan_referrer_requests: [],
      total_members: 0,
      total_active_members: 0,
      email: '',
      category_mail_templates: ['ref_code_success_admin', 'ref_code_cancel_admin'],
      mail_notification: ['ref_code_success_admin', 'ref_code_cancel_admin'],
      status: true,
      isDisable: false,
      isEnable: false,
      isUpdateDisabled: false,
      isUpdateEnable: false,
      referrer_code_detail: '',
      isDisableButtonCreate: false,
      listGeofences: [],
      checkDisableRoleBusiness: false,
      listGeofenceByCountry: [],
      dataDetail: [],
    };
    this.validator = new SimpleReactValidator();
    this.type = this.props.match.url.includes('detail') ? 'UPDATE' : 'CREATE';
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllCountryCode();
    this.state.country_id && await this.props.getListGeofenceByCountry(this.state.country_id);
    if (this.type === 'UPDATE') {
      const {id} = this.props.match.params;
      await this.props.getDetailBusinessPartner(id, this.props).then((response) => {
        if (response) {
          this.setState({
            isTick: Boolean(response.geofence_ids.length > 0),
            referral_name_ja: response.names.ja,
            referral_name_vi: response.names.vi,
            referral_name_en: response.names.en,
            country_id: response.country_id,
            email: response.emails[0] ? response.emails[0] : '',
            referral_code: response.referral_code.slice(2, 8),
            referrer_code_detail: response.referral_code,
            referral_description_ja: response.descriptions.ja,
            referral_description_vi: response.descriptions.vi,
            referral_description_en: response.descriptions.en,
            category_mail_templates: response.category_mail_templates,
            total_members: response.total_members,
            total_active_members: response.total_active_members,
            list_area_id: response.geofence_ids,
            plan_referrer_requests: response.plan_referrer_responses,
            zone_id: this.props.common?.country_code?.find((item) => item.id === response.country_id)?.zone_id,
            status: response.is_active,
            isUpdateDisabled: response.is_active,
            isUpdateEnable: response.is_active,
            dataDetail: response,
          });
          if (isRoleBusiness()) {
            const {principal} = this.props;
            // handle hidden/show button update
            const setGeofences = new Set();
            response.geofence_ids.forEach((item) => {
              this.props.principal.geofence_ids.includes((item)) && setGeofences.add(item);
            });
            this.setState({checkDisableRoleBusiness: !(setGeofences.size === response.geofence_ids.length)});

            // handle logic geofence
            const listGeofences = [...new Set([...principal.geofence_ids, ...response.geofence_ids])];
            this.props.getListGeofenceDetailsByIds({geofenceIds: listGeofences}).then((result) => {
              this.setState({listGeofenceByCountry: result || []});
            });
          }
        }
      });
      if (this.state.list_area_id !== null) {
        const listGeofencesId = this.state.list_area_id.join(',');
        await this.props.getListPlanCode(id, listGeofencesId).then((response) => {
          if (response) {
            this.setState({
              listPlanCode: response,
            });
          }
        });
      }
      this.setState({listPlan: []});
      const listPlanCopy = this.state.listPlan;
      this.state.plan_referrer_requests.forEach((item) => {
        const listPlanCopyOjb = {
          plan_id: item.plan_id,
          plan_code: item.plan_code,
          start_use_time: item.start_use_time,
          end_use_time: item.end_use_time,
          zone_id: item.zone_id,
          invalidUseTime: true,
          checkPlanCodeExist: true,
        };
        listPlanCopy.push(listPlanCopyOjb);
      });
      await this.setState({listPlan: listPlanCopy});
    }
  }

  /**
   * componentDidUpdate
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.type === 'UPDATE') {
      if (prevState.country_id !== this.state.country_id && this.state.country_id !== null) {
        this.props.getListGeofenceByCountry(this.state.country_id);
      }
    }
  }
  /**
   * checkOverlapped
   */
  checkOverlapped = () => {
    const listPlan = [...this.state.listPlan];
    listPlan.length > 1 &&
      listPlan.forEach((item, index) => {
        if (item.plan_id !== null) {
          const listTimeFilter = listPlan.filter((itemListTime, indexListTime) => indexListTime !== index);
          listTimeFilter.every((itemlistTimeFilter, indexListTimeFilter) => {
            if (
              (new Date(item.start_use_time) >= new Date(itemlistTimeFilter.start_use_time) && new Date(item.start_use_time) <= new Date(itemlistTimeFilter.end_use_time)) ||
              (new Date(item.end_use_time) >= new Date(itemlistTimeFilter.start_use_time) && new Date(item.end_use_time) <= new Date(itemlistTimeFilter.end_use_time)) ||
              (new Date(item.start_use_time) <= new Date(itemlistTimeFilter.start_use_time) && new Date(item.end_use_time) >= new Date(itemlistTimeFilter.end_use_time))
            ) {
              listPlan[index]['invalidUseTime'] = false;
              return false;
            } else {
              listPlan[index]['invalidUseTime'] = true;
              return true;
            }
          });
        }
      });
    if (listPlan.length === 1) listPlan[0]['invalidUseTime'] = true;
    this.setState({listPlan: listPlan});
  };

  /**
   * onChangeListPlanData
   * @param {*} component
   * @param {*} fieldName
   * @param {*} index
   * @param {*} value
   */
  onChangeListPlanData = async (component, fieldName, index, value) => {
    const listPlan = [...component.state.listPlan];
    if (fieldName === 'plan_code' && value) {
      listPlan[index][fieldName] = value.plan_code.includes(' - ') ? value.plan_code.slice(0, value.plan_code.indexOf(' ')) : value.plan_code;
      listPlan[index]['start_use_time'] = value?.start_use_time;
      listPlan[index]['end_use_time'] = value?.end_use_time;
      listPlan[index]['plan_id'] = value?.plan_id;
      listPlan[index]['zone_id'] = value?.zone_id;
    } else listPlan[index][fieldName] = value;
    component.setState({listPlan: listPlan}, () => component.checkOverlapped());
  };

  /**
   * checkPlanCode
   * @param {*} component
   * @param {*} index
   * @param {*} value
   */
  checkPlanCode = (component, index, value) => {
    const listPlan = [...this.state.listPlan];
    const language = localStorage.getItem('i18nextLng');
    const listPlanCode = [...this.state.listPlanCode];
    const listPlanCodeSelected = listPlanCode.map((item) => item.plan_code + ' - ' + item?.names[language]);
    listPlan[index].plan_code = value.includes(' - ') ? value.slice(0, value.indexOf(' ')) : value;
    if (listPlanCodeSelected.includes(value)) {
      listPlan[index]['checkPlanCodeExist'] = true;
    } else listPlan[index]['checkPlanCodeExist'] = false;
    component.setState({listPlan: listPlan});
  };

  /**
   * addBoxPlan
   */
  addBoxPlan = () => {
    const listPlanCopy = [...this.state.listPlan];
    const ojbPlan = {
      plan_id: null,
      plan_code: '',
      start_use_time: null,
      end_use_time: null,
      zone_id: null,
      invalidUseTime: true,
      checkPlanCodeExist: false,
    };
    listPlanCopy.push(ojbPlan);
    this.setState({
      listPlan: listPlanCopy,
    });
  };

  /**
   * handleCreate
   */
  handleCreate = () => {
    this.setState({
      isSubmitForm: true,
    });

    let checkPlanCode;
    let checkTimeOverlapped;
    let checkUseTimeRange;
    let checkUseTimeOfPlan;
    if (this.state.isTick) {
      const listPlanCode = this.state.listPlanCode;
      const listPlan = this.state.listPlan;
      checkPlanCode = listPlan.findIndex((item) => item.checkPlanCodeExist === false);
      checkTimeOverlapped = listPlan.findIndex((item) => item.invalidUseTime === false);
      checkUseTimeRange = listPlan.findIndex((item) => new Date(item.start_use_time) > new Date(item.end_use_time));
      checkUseTimeOfPlan = listPlan.findIndex((item) => {
        const plan = listPlanCode.filter((itemPlanCode) => itemPlanCode.plan_id === item.plan_id)[0];
        if (!(new Date(item?.start_use_time) >= new Date(plan?.start_use_time) && new Date(item?.end_use_time) <= new Date(plan?.end_use_time))) {
          return true;
        } else return false;
      });
    }
    if (
      ((this.state.isTick &&
        this.validator.allValid() &&
        checkPlanCode === -1 &&
        checkTimeOverlapped === -1 &&
        checkUseTimeRange === -1 &&
        checkUseTimeOfPlan === -1) ||
        (!this.state.isTick && this.state.referral_code && this.state.referral_name_ja && this.state.referral_name_vi && this.state.referral_name_en && this.state.country_id)) &&
      validateInput(this.state.referral_code.trim()) &&
      !this.state.checkReferrerCodeExist
    ) {
      if (this.state.email) {
        isValidEmail(this.state.email.trim()) &&
          this.setState({
            flag: true,
            message: 'businessPartner.question.confirm.create',
          });
      } else {
        this.setState({
          flag: true,
          message: 'businessPartner.question.confirm.create',
        });
      }
    }
  };

  /**
   * handleUpdate
   */
  handleUpdate = () => {
    this.setState({
      isSubmitForm: true,
    });
    let checkPlanCode;
    let checkTimeOverlapped;
    let checkUseTimeRange;
    let checkUseTimeOfPlan;

    if (this.state.isTick) {
      const listPlanCode = this.state.listPlanCode;
      const listPlan = this.state.listPlan;
      checkPlanCode = listPlan.findIndex((item) => item.checkPlanCodeExist === false);
      checkTimeOverlapped = listPlan.findIndex((item) => item.invalidUseTime === false);
      checkUseTimeRange = listPlan.findIndex((item) => new Date(item.start_use_time) > new Date(item.end_use_time));
      checkUseTimeOfPlan = listPlan.findIndex((item) => {
        const plan = listPlanCode.filter((itemPlanCode) => itemPlanCode.plan_id === item.plan_id)[0];
        if (!(new Date(item?.start_use_time) >= new Date(plan?.start_use_time) && new Date(item?.end_use_time) <= new Date(plan?.end_use_time))) {
          return true;
        } else return false;
      });
    }
    if (
      ((this.state.isTick &&
        this.validator.allValid() &&
        checkPlanCode === -1 &&
        checkTimeOverlapped === -1 &&
        checkUseTimeRange === -1 &&
        checkUseTimeOfPlan === -1) ||
        (!this.state.isTick && this.state.referral_code && this.state.referral_name_ja && this.state.referral_name_vi && this.state.referral_name_en && this.state.country_id)) &&
      validateInput(this.state.referral_code.trim()) &&
      !this.state.checkReferrerCodeExist
    ) {
      if (this.state.email) {
        isValidEmail(this.state.email) &&
          this.setState({
            flag: true,
            message: 'businessPartner.question.confirm.update',
          });
      } else {
        this.setState({
          flag: true,
          message: 'businessPartner.question.confirm.update',
        });
      }
    }
  };

  /**
   * handleButtonCancel
   */
  handleButtonCancel = () => {
    this.setState({
      flag: false,
    });
  };

  /**
   * disableReferrer
   */
  disableReferrer = async () => {
    const {id} = this.props.match.params;
    await this.props.deleteBusinessPartner(id);
    this.setState({isDisable: false, status: false, isUpdateDisabled: false, isEnable: false});
    await this.props.getDetailBusinessPartner(id, this.props);
  };

  /**
   * handleButtonCreateOk
   */
  handleButtonCreateOk = async () => {
    const listCountry = this.props.common?.country_code;
    await this.setState({plan_referrer_requests: [], isDisableButtonCreate: true});
    const plan_referrer_requests = this.state.plan_referrer_requests;
    this.state.listPlan.forEach((item, index) => {
      const ojb_plan_referrer_requests = {
        plan_id: item.plan_id,
        start_use_time: format(new Date(item.start_use_time), 'yyyy-MM-dd'),
        end_use_time: format(new Date(item.end_use_time), 'yyyy-MM-dd'),
        zone_id: item.zone_id,
      };
      plan_referrer_requests.push(ojb_plan_referrer_requests);
    });
    this.setState({plan_referrer_requests: plan_referrer_requests});
    const refferal_code = listCountry.filter((item) => item.id === this.state.country_id)[0]?.country_code + this.state.referral_code.trim();
    const descriptions = {
      ja: this.state.referral_description_ja.trim(),
      vi: this.state.referral_description_ja.trim(),
      en: this.state.referral_description_ja.trim(),
    };
    const names = {
      ja: this.state.referral_name_ja.trim(),
      vi: this.state.referral_name_vi.trim(),
      en: this.state.referral_name_en.trim(),
    };
    const payload = {
      id: null,
      names: names,
      referral_code: refferal_code,
      descriptions: descriptions,
      plan_referrer_requests: this.state.isTick ? this.state.plan_referrer_requests : [],
      country_id: this.state.country_id,
      geofence_ids: this.state.isTick ? this.state.list_area_id : [],
      emails: this.state.email,
      category_mail_templates: this.state.category_mail_templates,
      is_active: true,
    };
    await this.props.createBusinessPartner(payload, this.props).then((response) => {
      if (response && response === 200) {
        this.setState({
          isDisableButtonCreate: false,
        });
      } else {
        this.setState({
          isDisableButtonCreate: false,
        });
      }
    });
  };

  /**
   * onChangeReferrerCode
   * @param {*} component
   * @param {*} referrerCode
   */
  onChangeReferrerCode = async (component, referrerCode) => {
    component.setState({referral_code: referrerCode.trim()});
    const listCountry = this.props.common?.country_code;
    const country_code = listCountry.filter((item) => item.id === this.state.country_id)[0]?.country_code;
    if (referrerCode.length === 6 && (this.type === 'CREATE' || (this.type === 'UPDATE' && this.state.referrer_code_detail !== referrerCode))) {
      const params = {
        referral_code: referrerCode ? country_code + referrerCode : null,
      };
      if (validateInput(referrerCode.trim())) {
        await this.props.searchReferrers(params).then((res) => {
          if (res.content.length === 0) {
            component.setState({
              checkReferrerCodeExist: false,
            });
          } else {
            component.setState({
              checkReferrerCodeExist: true,
            });
          }
        });
      }
    }
    if (this.type === 'UPDATE' && this.state.referrer_code_detail === country_code + referrerCode) {
      component.setState({
        checkReferrerCodeExist: false,
      });
    }
  };

  /**
   * onChangeEmail
   * @param {*} component
   * @param {*} email
   */
  onChangeEmail = (component, email) => {
    component.setState({email: email.trim()});
  };

  /**
   * handleButtonUpdateOk
   */
  handleButtonUpdateOk = async () => {
    const listCountry = this.props.common?.country_code;
    await this.setState({plan_referrer_requests: [], isDisableButtonCreate: true});
    const plan_referrer_requests = [...this.state.plan_referrer_requests];
    this.state.listPlan.forEach((item, index) => {
      const ojb_plan_referrer_requests = {
        plan_id: item.plan_id,
        start_use_time: format(new Date(item.start_use_time), 'yyyy-MM-dd'),
        end_use_time: format(new Date(item.end_use_time), 'yyyy-MM-dd'),
        zone_id: item.zone_id,
      };
      plan_referrer_requests.push(ojb_plan_referrer_requests);
    });
    this.setState({plan_referrer_requests: plan_referrer_requests});
    const {id} = this.props.match.params;
    const refferal_code = listCountry.filter((item) => item.id === this.state.country_id)[0]?.country_code + this.state.referral_code.trim();
    const descriptions = {
      ja: this.state.referral_description_ja,
      vi: this.state.referral_description_vi,
      en: this.state.referral_description_en,
    };
    const names = {
      ja: this.state.referral_name_ja.trim(),
      vi: this.state.referral_name_vi.trim(),
      en: this.state.referral_name_en.trim(),
    };
    const payload = {
      id: id,
      names: names,
      referral_code: refferal_code,
      descriptions: descriptions,
      plan_referrer_requests: this.state.isTick ? this.state.plan_referrer_requests : [],
      country_id: this.state.country_id,
      geofence_ids: this.state.isTick ? this.state.list_area_id : [],
      emails: this.state.email ? this.state.email : [],
      category_mail_templates: this.state.category_mail_templates,
      is_active: this.state.isUpdateEnable,
    };
    await this.props.updateBusinessPartner(payload, this.props).then((response) => {
      if (response && response === 200) {
        this.setState({
          isDisableButtonCreate: false,
        });
      } else {
        this.setState({
          isDisableButtonCreate: false,
        });
      }
    });
    this.setState({flag: false});
    await this.props.getDetailBusinessPartner(id, this.props).then((res) => {
      this.setState({isUpdateDisabled: true});
    });
  };

  /**
   * deleteBoxPlan
   * @param {*} index
   */
  deleteBoxPlan = (index) => {
    const listPlanCopy = [...this.state.listPlan];
    listPlanCopy.splice(index, 1);
    this.setState({listPlan: listPlanCopy}, () => this.checkOverlapped());
  };

  /**
   * onChangePlanSettingYes
   */
  onChangePlanSettingYes = () => {
    this.setState({isTick: true});
    if (this.type === 'UPDATE') {
      if (this.state.list_area_id.length === 0) {
        this.setState({listPlan: [{plan_id: null, plan_code: '', start_use_time: null, end_use_time: null, zone_id: null, invalidUseTime: true, checkPlanCodeExist: false}]});
      }
    }
  };

  /**
   * onChangePlanSettingNo
   */
  onChangePlanSettingNo = async () => {
    const listPlanCopy = [{plan_id: null, plan_code: '', start_use_time: null, end_use_time: null, zone_id: null, invalidUseTime: true, checkPlanCodeExist: false}];
    if (this.type === 'CREATE') {
      this.setState({
        isTick: false,
        list_area_id: [],
        listPlan: listPlanCopy,
      });
    } else {
      const {id} = this.props.match.params;
      await this.props.getDetailBusinessPartner(id, this.props).then((response) => {
        if (response) {
          this.setState({
            isTick: false,
            list_area_id: response.geofence_ids,
            plan_referrer_requests: response.plan_referrer_responses,
            zone_id: this.props.common?.country_code?.find((item) => item.id === response.country_id)?.zone_id,
          });
        }
      });
      if (this.state.list_area_id !== null) {
        const listGeofencesId = this.state.list_area_id.join(',');
        await this.props.getListPlanCode(id, listGeofencesId).then((response) => {
          if (response) {
            this.setState({
              listPlanCode: response,
            });
          }
        });
      }
      this.setState({listPlan: []});
      const listPlanCopy = this.state.listPlan;
      this.state.plan_referrer_requests.forEach((item) => {
        const listPlanCopyOjb = {
          plan_id: item.plan_id,
          plan_code: item.plan_code,
          start_use_time: item.start_use_time,
          end_use_time: item.end_use_time,
          zone_id: item.zone_id,
          invalidUseTime: true,
          checkPlanCodeExist: true,
        };
        listPlanCopy.push(listPlanCopyOjb);
      });
      this.setState({listPlan: listPlanCopy});
    }
  };

  /**
   * sendEmail
   * @param {*} value
   * @param {*} item
   */
  sendEmail = (value, item) => {
    const set_category_mail_templates = new Set(this.state.category_mail_templates);
    value ? set_category_mail_templates.add(item) : set_category_mail_templates.delete(item);
    this.setState({
      category_mail_templates: [...set_category_mail_templates],
    });
  };

  /**
   * onChangeCountry
   * @param {*} value
   */
  onChangeCountry = async (value) => {
    const listPlanCopy = [{plan_id: null, plan_code: '', start_use_time: null, end_use_time: null, zone_id: null, invalidUseTime: true, checkPlanCodeExist: false}];
    this.setState({
      country_id: value,
      zone_id: this.props.common?.country_code?.find((item) => item.id === value)?.zone_id,
      list_area_id: [],
      listPlanCode: [],
    });
    if (value !== null) {
      await this.props.getListGeofenceByCountry(value);
      if (this.state.list_area_id.length === 0) {
        this.setState({listPlan: [...listPlanCopy]});
      }
    }
    if (this.type === 'CREATE') {
      const listCountry = this.props.common?.country_code;
      const country_code = listCountry.filter((item) => item.id === value)[0]?.country_code;
      const params = {
        referral_code: this.state.referral_code ? country_code + this.state.referral_code : null,
      };
      if (validateInput(this.state.referral_code.trim())) {
        await this.props.searchReferrers(params).then((res) => {
          if (res.content.length === 0) {
            this.setState({
              checkReferrerCodeExist: false,
            });
          } else {
            this.setState({
              checkReferrerCodeExist: true,
            });
          }
        });
      }
    }
  };

  /**
   * changeAreaBy
   * @param {*} value
   * @param {*} item
   */
  changeAreaBy = async (value, item) => {
    const {id} = this.props.match.params;
    const listPlanFilter = this.state.listPlan;
    const set_area_id_copy = new Set(this.state.list_area_id);
    value ? set_area_id_copy.add(item) : set_area_id_copy.delete(item);
    this.setState({
      list_area_id: [...set_area_id_copy],
    });
    const listGeofencesId = [...set_area_id_copy].join(',');
    const listPlanCode = listGeofencesId ? await this.props.getListPlanCode(id, listGeofencesId) : [];
    this.setState({
      listPlanCode: listPlanCode?.length > 0 ? listPlanCode : [],
    });
    const newPlanCode = listPlanCode?.length > 0 ? listPlanCode.map((p) => p.plan_code) : [];
    const newListPLan = listPlanFilter.length > 0 ? listPlanFilter.map((item) => {
      if (newPlanCode.length <= 0 || (newPlanCode.length > 0 && !newPlanCode.includes(item.plan_code))) {
        return {plan_id: null, plan_code: '', start_use_time: null, end_use_time: null, zone_id: null, invalidUseTime: true, checkPlanCodeExist: false};
      }
      return item;
    }) : [];
    this.setState({listPlan: newListPLan});
  };

  /**
   * Check plan code for role business
   * @param {*} listGeofencePlan
   * @return {*}
   */
  checkPlanCodeRoleBusiness = (listGeofencePlan) => {
    const listGeofence = new Set();
    listGeofencePlan.forEach((item) => {
      this.props.principal.geofence_ids.includes((item)) && listGeofence.add(item);
    });
    return listGeofence.size === listGeofencePlan.length;
  }

  /**
   * boxPlan
   * @param {*} item
   * @param {*} index
   * @param {*} plan_referrer_requests
   * @return {Promise}
   */
  boxPlan = (item, index, plan_referrer_requests) => {
    const {t} = this.props;
    const language = localStorage.getItem('i18nextLng');
    const listPlanCode = isRoleBusiness() ? this.state.listPlanCode.filter((item) => this.checkPlanCodeRoleBusiness(item.geofence_ids)) : this.state.listPlanCode;
    return (
      <Grid container style={{border: '1px solid black', width: '100%'}} item lg={12}>
        &nbsp;
        <Grid container style={{display: 'flex'}} item lg={12} xs={6}>
          <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
            {t('businessPartner.plan_code')} <span className="font_color_red">＊</span>
          </Grid>
          <Grid container alignItems="center" item xs={6} lg={9} style={{display: 'block'}}>
            <div>
              <Autocomplete
                margin="dense"
                freeSolo
                name="plan_code"
                className="field_size_20 field_min_size_400"
                options={listPlanCode.filter(
                  (item) =>
                    new Date(item.start_use_time) >= new Date(format(new Date(), 'yyyy-MM-dd')) ||
                    (new Date(item.start_use_time) <= new Date(format(new Date(), 'yyyy-MM-dd')) && new Date(item.end_use_time) >= new Date(format(new Date(), 'yyyy-MM-dd'))),
                )}
                value={plan_referrer_requests[index]}
                getOptionLabel={(option) => {
                  const plan_name = this.state.listPlanCode.filter((itemListPlanCode) => itemListPlanCode.plan_code === option.plan_code)[0]?.names[language];
                  return option.plan_code && option.plan_code + ' - ' + plan_name;
                }}
                onChange={(event, newInputValue) => {
                  this.onChangeListPlanData(this, 'plan_code', index, newInputValue);
                }}
                onInputChange={(event, newInputValue) => {
                  this.checkPlanCode(this, index, newInputValue);
                }}
                renderInput={(params) => (
                  <Grid>
                    <TextField
                      {...params}
                      name={String(index)}
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      placeholder={t('validation.required', {field: t('business.planCode')})}
                    />
                  </Grid>
                )}
                disabled={!this.state.status}
              />
            </div>
            {this.state.isTick && this.validator.message('plan_code', item.plan_code, 'required')}
            {this.state.isTick && this.state.isSubmitForm && !this.validator.check(item.plan_code, 'required') && (
              <FormHelperText id="plan_code" error>
                {t('business.checkPlanCodeRequired')}
              </FormHelperText>
            )}
            {this.state.isTick && this.state.isSubmitForm && this.validator.check(item.plan_code, 'required') && item.checkPlanCodeExist === false && (
              <FormHelperText id="plan_code" error>
                <div className="font_color_red">{t('business.checkPlanCodeExist')}</div>
              </FormHelperText>
            )}
          </Grid>
        </Grid>
        <Grid container style={{display: 'flex'}} item lg={12}>
          <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
            {t('businessPartner.distribution_period')} <span className="font_color_red">＊</span>
          </Grid>
          <Grid container item xs={6} lg={9} style={{display: 'flex'}}>
            <Grid container alignItems="center" item lg={1}>
              {t('common.from')}
            </Grid>
            <Grid container item xs={3} lg={4}>
              <FormControl>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      margin="dense"
                      autoOk
                      inputVariant="outlined"
                      minDate={this.state.listPlanCode.filter((item) => item.plan_code === this.state.listPlan[index].plan_code).map((item) => item.start_use_time)}
                      maxDate={this.state.listPlanCode.filter((item) => item.plan_code === this.state.listPlan[index].plan_code).map((item) => item.end_use_time)}
                      maxDateMessage={t('businessPartner.errorUseTime')}
                      minDateMessage={t('businessPartner.errorUseTime')}
                      format="yyyy/MM/dd"
                      value={item.start_use_time ? item.start_use_time : null}
                      KeyboardButtonProps={{
                        'aria-label': 'change time',
                      }}
                      inputProps={{
                        readOnly: true,
                      }}
                      onChange={(event, newInputValue) => {
                        const date = format(new Date(newInputValue), 'yyyy-MM-dd');
                        this.onChangeListPlanData(this, 'start_use_time', index, date);
                      }}
                      disabled={!this.state.status}
                    />
                  </>
                </MuiPickersUtilsProvider>
              </FormControl>
            </Grid>
            <Grid container item lg={1} style={{justifyContent: 'flex-end'}}>
              <Grid style={{margin: 'auto'}}>{t('common.to')}</Grid>
            </Grid>
            <Grid container item xs={3} lg={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    margin="dense"
                    autoOk
                    inputVariant="outlined"
                    minDate={this.state.listPlanCode.filter((item) => item.plan_code === this.state.listPlan[index].plan_code).map((item) => item.start_use_time)}
                    maxDate={this.state.listPlanCode.filter((item) => item.plan_code === this.state.listPlan[index].plan_code).map((item) => item.end_use_time)}
                    maxDateMessage={t('businessPartner.errorUseTime')}
                    minDateMessage={t('businessPartner.errorUseTime')}
                    format="yyyy/MM/dd"
                    value={item.end_use_time ? item.end_use_time : null}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                    inputProps={{
                      readOnly: true,
                    }}
                    onChange={(event, newInputValue) => {
                      const date = format(new Date(newInputValue), 'yyyy-MM-dd');
                      this.onChangeListPlanData(this, 'end_use_time', index, date);
                    }}
                    disabled={!this.state.status}
                  />
                </>
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item lg={12}>
          <Grid container item lg={3} className="grid_title_padding"></Grid>
          <Grid container item lg={9}>
            <Grid container item lg={1}></Grid>
            {/* Check Start Time */}
            <Grid container item lg={4}>
              {this.state.isTick && this.validator.message('start_use_time', item.start_use_time, 'required')}
              {this.state.isTick && this.state.isSubmitForm && !item.start_use_time && (
                <FormHelperText id="start_use_time" error>
                  {t('businessPartner.distributionPeriodRequired')}
                </FormHelperText>
              )}
              {this.state.isTick &&
                this.state.isSubmitForm &&
                item.start_use_time &&
                !!!compareDateTimeRange(item.start_use_time, item.end_use_time, true) && (
                  <FormHelperText id="start_use_time" style={{color: 'red'}}>
                    {t('validation.invalid.timeRange')}
                  </FormHelperText>
                )}
            </Grid>
            <Grid container item lg={1}></Grid>
            {/* Check End Time */}
            <Grid container item lg={4}>
              {this.state.isTick && this.validator.message('end_use_time', item.end_use_time, 'required')}
              {this.state.isTick && this.state.isSubmitForm && !item.end_use_time && (
                <FormHelperText id="end_use_time" error>
                  {t('businessPartner.distributionPeriodRequired')}
                </FormHelperText>
              )}
              {this.state.isTick &&
                this.state.isSubmitForm &&
                item.end_use_time &&
                !!!compareDateTimeRange(item.start_use_time, item.end_use_time, true) && (
                  <FormHelperText id="end_use_time" style={{color: 'red'}}>
                    {t('validation.invalid.timeRange')}
                  </FormHelperText>
                )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container alignItems="center" item xs={6} lg={4} className="grid_title_padding">
          <FormHelperText id="end_use_time" error>
            {this.state.listPlan[index].invalidUseTime === false && <span className="font_color_red">{t('business.invalidUseTime')}</span>}
          </FormHelperText>
        </Grid>
        &nbsp;
      </Grid>
    );
  };

  /**
   * getListAllGeofence
   * @return {Array}
   */
  getListAllGeofence = () => {
    const {common} = this.props;
    const listGeofences = (isRoleBusiness() && this.type === 'UPDATE') ? this.state.listGeofenceByCountry : common?.geofence_by_country;
    return this.props.match.params.id ? getListAllGeofenceDetail(listGeofences, this.state.dataDetail?.geofence_config_infos) : listGeofences;
  }

  /**
   * @return {HTMLElement}
   */
  render() {
    const {t, common, actions} = this.props;
    const listCountry = common?.country_code;
    const permission = {
      canDisable: actions.includes(PERMISSION_ACTIONS.DISABLE),
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
      canEditAndEnable: actions.includes(PERMISSION_ACTIONS.EDIT_AND_ENABLE),
    };
    return (
      <Card className="SearchAccountType main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('businessPartner.referrer_setting')}</h3>
            </Grid>
          </Grid>
          <br></br>
          <Card raised>
            <Container maxWidth="xl">
              <>
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('businessPartner.referrer_setting')}
                    </Grid>
                  </Grid>
                  {/* Referral Name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.referrer_name')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.ja')})</span>}
                            fullWidth
                            margin="dense"
                            placeholder={t('businessPartner.referrerNameRequired')}
                            multiline
                            name="referral_name_ja"
                            variant="outlined"
                            inputProps={{maxLength: 50}}
                            onChange={(event) => !event.target.value.includes('\n') && this.setState({referral_name_ja: event.target.value})}
                            value={this.state.referral_name_ja || ''}
                            disabled={!this.state.status}
                          />
                          <div style={{marginBottom: 5}}>{t('validation.max.label', {value: 50})}</div>
                          {this.validator.message('referral_name_ja', this.state.referral_name_ja, 'required|max:50')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.referral_name_ja, 'required') && (
                            <FormHelperText id="referral_name_ja" error>
                              {t('businessPartner.referrerNameRequired')}
                            </FormHelperText>
                          )}
                          {this.state.isSubmitForm &&
                            this.validator.check(this.state.referral_name_ja, 'required') &&
                            !this.validator.check(this.state.referral_name_ja, 'max:50') && (
                              <FormHelperText id="referral_name_ja" error>
                                {t('validation.maxString', {value: 50})}
                              </FormHelperText>
                            )}
                        </Box>
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.vi')})</span>}
                            fullWidth
                            name="referral_name_vi"
                            margin="dense"
                            placeholder={t('businessPartner.referrerNameRequired')}
                            multiline
                            variant="outlined"
                            inputProps={{maxLength: 50}}
                            onChange={(event) => !event.target.value.includes('\n') && this.setState({referral_name_vi: event.target.value})}
                            value={this.state.referral_name_vi || ''}
                            disabled={!this.state.status}
                          />
                          <div style={{marginBottom: 5}}>{t('validation.max.label', {value: 50})}</div>
                          {this.validator.message('referral_name_vi', this.state.referral_name_vi, 'required|max:50')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.referral_name_vi, 'required') && (
                            <FormHelperText id="referral_name_vi" error>
                              {t('businessPartner.referrerNameRequired')}
                            </FormHelperText>
                          )}
                          {this.state.isSubmitForm &&
                            this.validator.check(this.state.referral_name_vi, 'required') &&
                            !this.validator.check(this.state.referral_name_vi, 'max:50') && (
                              <FormHelperText id="referral_name_vi" error>
                                {t('validation.maxString', {value: 50})}
                              </FormHelperText>
                            )}
                        </Box>
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.en')})</span>}
                            fullWidth
                            margin="dense"
                            name="referral_name_en"
                            placeholder={t('businessPartner.referrerNameRequired')}
                            multiline
                            variant="outlined"
                            inputProps={{maxLength: 50}}
                            onChange={(event) => !event.target.value.includes('\n') && this.setState({referral_name_en: event.target.value})}
                            value={this.state.referral_name_en || ''}
                            disabled={!this.state.status}
                          />
                          <div style={{marginBottom: 5}}>{t('validation.max.label', {value: 50})}</div>
                        </Box>
                        {this.validator.message('referral_name_en', this.state.referral_name_en, 'required|max:50')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.referral_name_en, 'required') && (
                          <FormHelperText id="referral_name_en" error>
                            {t('businessPartner.referrerNameRequired')}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm && this.validator.check(this.state.referral_name_en, 'required') && !this.validator.check(this.state.referral_name_en, 'max:50') && (
                          <FormHelperText id="referral_name_en" error>
                            {t('validation.maxString', {value: 50})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Country */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.country')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_400">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'country',
                          }}
                          displayEmpty
                          value={this.state.country_id}
                          onChange={(event) => this.onChangeCountry(event.target.value)}
                          renderValue={
                            this.state.country_id ?
                              undefined : () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.country'),
                                    })}
                                  </div>
                                )
                          }
                          disabled={this.type === 'UPDATE' || !isRoleGlobal()}
                        >
                          {common?.country_code?.map((item, idx) => (
                            <MenuItem value={item.id} key={idx}>
                              {t(`${item.country_code}`)}
                            </MenuItem>
                          ))}
                        </Select>
                        {this.validator.message('country', this.state.country_id, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.country_id, 'required') && (
                          <FormHelperText id="country" error>
                            {t('validation.required.choose', {field: t('common.country')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Email */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.email')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl>
                        <div className="box-group-input">
                          <TextField
                            name="email"
                            className="field_size_10 field_min_size_400"
                            margin="dense"
                            placeholder={t('businessPartner.referrerMailRequired')}
                            variant="outlined"
                            inputProps={{maxLength: 64}}
                            value={this.state.email}
                            onChange={(event) => this.onChangeEmail(this, event.target.value)}
                            disabled={!this.state.status}
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 64})}</div>
                        </div>
                        {this.state.isSubmitForm && this.state.email && !isValidEmail(this.state.email) && (
                          <FormHelperText id="email" error>
                            {t('errorFields.emailFormat')}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Referral Code */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.referrer_code')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={6} style={{display: 'block'}}>
                      <div className="box-group-input">
                        <TextField
                          className="field_size_20 field_min_size_400"
                          margin="dense"
                          placeholder={t('businessPartner.referrerCodeRequired')}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {this.state.country_id && listCountry.filter((item) => item.id === this.state.country_id)[0]?.country_code}
                              </InputAdornment>
                            ),
                            inputProps: {maxLength: 6},
                          }}
                          name="referral_code"
                          variant="outlined"
                          value={this.state.referral_code}
                          onChange={(event) => this.onChangeReferrerCode(this, event.target.value)}
                          disabled={this.state.total_active_members > 0 || !this.state.status}
                        />
                        <div className="max-length-label"><b>{t('businessPartner.eg')}</b></div>
                      </div>
                      <div>
                        <FormHelperText id="referral_code" style={{color: 'black', fontSize: '14px'}}>
                          <b><i>{t('business.referralCodeFormat')}</i></b>
                        </FormHelperText>
                      </div>
                      {this.validator.message('referral_code', this.state.referral_code, 'required')}
                      {this.state.isSubmitForm && !this.validator.check(this.state.referral_code, 'required') && (
                        <FormHelperText id="referral_code" error>
                          {t('businessPartner.referrerCodeRequired')}
                        </FormHelperText>
                      )}
                      {this.state.isSubmitForm && !validateInput(this.state.referral_code.trim()) && this.validator.check(this.state.referral_code, 'required') && (
                        <FormHelperText id="referral_code" error>
                          {t('business.referralCodeFormatIncorrect')}
                        </FormHelperText>
                      )}
                      {this.state.isSubmitForm &&
                        this.validator.check(this.state.referral_code, 'required') &&
                        validateInput(this.state.referral_code.trim()) &&
                        this.state.checkReferrerCodeExist && (
                          <FormHelperText id="referral_code" error>
                            {t('business.checkReferrerCodeExist')}
                          </FormHelperText>
                        )}
                    </Grid>
                  </Grid>
                  {/* Referral Description */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.referrer_description')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.ja')})</span>}
                            fullWidth
                            rows={2}
                            margin="dense"
                            placeholder={t('businessPartner.referrerDescriptionPlaceHolder')}
                            multiline
                            variant="outlined"
                            inputProps={{maxLength: 200}}
                            onChange={(event) => !event.target.value.includes('\n') && this.setState({referral_description_ja: event.target.value})}
                            value={this.state.referral_description_ja || ''}
                            disabled={!this.state.status}
                          />
                          <div style={{marginBottom: 5}}>{t('validation.max.label', {value: 200})}</div>
                        </Box>
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.vi')})</span>}
                            fullWidth
                            margin="dense"
                            rows={2}
                            placeholder={t('businessPartner.referrerDescriptionPlaceHolder')}
                            multiline
                            variant="outlined"
                            inputProps={{maxLength: 200}}
                            onChange={(event) => !event.target.value.includes('\n') && this.setState({referral_description_vi: event.target.value})}
                            value={this.state.referral_description_vi || ''}
                            disabled={!this.state.status}
                          />
                          <div style={{marginBottom: 5}}>{t('validation.max.label', {value: 200})}</div>
                        </Box>
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.en')})</span>}
                            fullWidth
                            margin="dense"
                            rows={2}
                            placeholder={t('businessPartner.referrerDescriptionPlaceHolder')}
                            multiline
                            variant="outlined"
                            inputProps={{maxLength: 200}}
                            onChange={(event) => !event.target.value.includes('\n') && this.setState({referral_description_en: event.target.value})}
                            value={this.state.referral_description_en || ''}
                            disabled={!this.state.status}
                          />
                          <div style={{marginBottom: 5}}>{t('validation.max.label', {value: 200})}</div>
                        </Box>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Mail Notification */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.mail_notification')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      {this.state.mail_notification.map((item, index) => {
                        const checked = this.state.category_mail_templates?.indexOf(item) > -1;
                        return (
                          <FormControlLabel
                            key={index}
                            labelPlacement="end"
                            label={item === 'ref_code_success_admin' ? t('businessPartner.userAuthenCompleted') : t('businessPartner.userAuthenCanceled')}
                            control={<Checkbox name="activeChecked" color="primary" checked={checked} onChange={(event) => this.sendEmail(event.target.checked, item)} />}
                            disabled={!this.state.status}
                          />
                        );
                      })}
                    </Grid>
                  </Grid>
                  {/* Total Associated Member Amount */}
                  {this.type === 'UPDATE' && (
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('businessPartner.total_associated_memmber_amount')}
                      </Grid>
                      <Grid container alignItems="center" item lg={1} style={{color: 'gray'}}>
                        {this.state.total_members}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <Button color="primary" variant="contained" className="button_margin" onClick={() => this.setState({isListMembersOpened: true})}>
                          {t('businessPartner.list_member')}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                  {/* Plan Setting */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.plan_setting')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <RadioGroup row value={this.state.isTick ? 'Yes' : 'No'}>
                        <FormControlLabel
                          value="Yes"
                          control={<Radio className="checkbox_radio" />}
                          label={t('businessPartner.planSettingYes')}
                          onChange={() => this.onChangePlanSettingYes()}
                          disabled={!this.state.status}
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio className="checkbox_radio" />}
                          label={t('businessPartner.planSettingNo')}
                          onChange={() => this.onChangePlanSettingNo()}
                          disabled={(this.type === 'UPDATE' && this.state.total_active_members > 0) || !this.state.status}
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>
                  <br></br>
                  {this.state.isTick === true && (
                    <Grid>
                      {/* Geofence */}
                      <Grid container spacing={1} className="row_form_item" style={{paddingBottom: 12}}>
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('business_plan.geofence')} <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={6}>
                          <FormControl error>
                            <FormGroup row>
                              {this.state.country_id !== '' &&
                                this.getListAllGeofence().map((item, index) => {
                                  const checked = this.state.list_area_id?.indexOf(item.geofence_id) > -1;
                                  return (
                                    <FormControlLabel
                                      key={index}
                                      labelPlacement="end"
                                      control={
                                        <Checkbox
                                          color="primary"
                                          disabled={item.enable === false || item.enable_for_admin === false || !this.state.status ||
                                            (isRoleBusiness() && !this.props.principal.geofence_ids.includes(item.geofence_id))}
                                          checked={checked}
                                          onClick={(event) => this.changeAreaBy(event.target.checked, item.geofence_id)}
                                        />
                                      }
                                      label={item.name}
                                    />
                                  );
                                })}
                            </FormGroup>
                            {this.state.isTick && this.validator.message('list_area_id', this.state.list_area_id, 'required')}
                            {this.state.isTick && this.state.isSubmitForm && !this.validator.check(this.state.list_area_id, 'required') && (
                              <FormHelperText id="list_area_id" error>
                                {t('validation.required.choose', {field: t('business_plan.geofence')})}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                      <br></br>
                      <Grid style={{marginLeft: '17%'}}>
                        {this.state.listPlan?.map((item, index) => {
                          const plan_referrer_requests_copy = {...this.state.listPlan};
                          plan_referrer_requests_copy.length > 1 &&
                            plan_referrer_requests_copy.map((item) => {
                              item.names = this.state.listPlanCode.filter((itemListPlanCode) => itemListPlanCode.plan_code === item.plan_code).names;
                              delete item.checkPlanCodeExist;
                              delete item.invalidUseTime;
                              return item;
                            });
                          return (
                            <Grid key={index} container style={{marginBottom: '30px'}}>
                              <Grid container alignItems="center" item lg={8}>
                                {this.boxPlan(item, index, plan_referrer_requests_copy)}
                              </Grid>
                              <Grid container alignItems="center" item lg={1} style={{marginLeft: '20px'}}>
                                <Button
                                  color="secondary"
                                  variant="contained"
                                  onClick={() => this.deleteBoxPlan(index)}
                                  disabled={this.state.listPlan.length === 1 || !this.state.status}
                                >
                                  <CancelIcon />
                                </Button>
                              </Grid>
                            </Grid>
                          );
                        })}
                        <Button color="primary" variant="contained" onClick={() => this.addBoxPlan()} disabled={!this.state.status}>
                          <AddIcon />
                        </Button>
                        <br></br>
                      </Grid>
                    </Grid>
                  )}
                  <br></br>
                </Paper>
              </>
              <br></br>
              <Grid container spacing={1} alignItems="center" justifyContent="flex-end">
                <Grid item>
                  {this.type === 'CREATE' ? (
                    <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={() => this.handleCreate()}>
                      {t('common.btnRegister')}
                    </Button>
                  ) : (
                    !this.state.checkDisableRoleBusiness &&
                      <>
                        {this.state.isUpdateDisabled ? (
                          <>
                            {permission.canDisable &&
                              <Button color="secondary" variant="contained" endIcon={<NotInterestedIcon />} onClick={() => this.setState({isDisable: true})}>
                                {t('common.disable')}
                              </Button>
                            }
                            {permission.canUpdate &&
                              <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={() => this.handleUpdate()}>
                                {t('common.btnUpdate')}
                              </Button>
                            }
                          </>
                        ) : (
                          permission.canEditAndEnable &&
                            <>
                              {!this.state.isEnable ? (
                                <Button color="secondary" variant="contained" onClick={() => this.setState({status: true, isEnable: true})}>
                                  {t('businessPartner.editAndEnable')}
                                </Button>
                              ) : (
                                <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={() => this.setState({isUpdateEnable: true}, () => this.handleUpdate())}>
                                  {t('businessPartner.confirm')}
                                </Button>
                              )}
                            </>
                        )}
                      </>
                  )}
                  <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />} onClick={this.props.history.goBack}>
                    {t('common.btnReturn')}
                  </Button>
                </Grid>
                <Dialog
                  open={this.state.flag}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    onClickOk={this.type === 'CREATE' ? this.handleButtonCreateOk : this.handleButtonUpdateOk}
                    onClickCancel={() => this.handleButtonCancel()}
                    message={this.state.message}
                    isDisableCreate={this.state.isDisableButtonCreate}
                  ></SelectModal>
                </Dialog>
                <Dialog
                  open={this.state.isDisable}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    onClickOk={() => this.disableReferrer()}
                    onClickCancel={() => this.setState({isDisable: false})}
                    message={t('businessPartner.question.confirm.disabled', {count: this.state.total_active_members})}
                  ></SelectModal>
                </Dialog>
              </Grid>

              <br></br>
            </Container>
          </Card>
          <br></br>
          <br></br>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.isListMembersOpened}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1000,
            }}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <>
              <ListMembersModal referrerId={this.props.match.params.id} zoneId={this.state.zone_id} onCloseModal={() => this.setState({isListMembersOpened: false})} />
            </>
          </Modal>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listPlanCode: state.business.listPlanCode,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListPlanCode: (id, geofence_ids) => dispatch(getListPlanCode(id, geofence_ids)),
    createBusinessPartner: (payload, props) => dispatch(createBusinessPartner(payload, props)),
    updateBusinessPartner: (payload, props) => dispatch(updateBusinessPartner(payload, props)),
    getDetailBusinessPartner: (id, props) => dispatch(getDetailBusinessPartner(id, props)),
    getListGeofenceByCountry: (id) => dispatch(getListGeofenceByCountry(id)),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    checkReferrerCode: (id) => dispatch(checkReferrerCode(id)),
    deleteBusinessPartner: (id) => dispatch(deleteBusinessPartner(id)),
    searchReferrers: (params) => dispatch(searchReferrers(params)),
    getListGeofenceDetailsByIds: (params) => dispatch(getListGeofenceDetailsByIds(params)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(BusinessPartnerSettingComponent)));
