import React, {Component} from 'react';

import {Fade, Paper, Container, Grid, Button, TextField} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';

import {searchListCaution} from '../../stores/notice/actions';
import {onChangeTextField} from '../../utils/common';

/**
 * Note selection component
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      caution_id: '',
      name: '',
      content: '',

      selected_content: '',
    };
    this.handleClickClose = this.handleClickClose.bind(this);
    this.handleClickOk = this.handleClickOk.bind(this);
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    const payload = {
      caution_id: this.state.caution_id,
      name: this.state.name,
      content: this.state.content,
    };
    this.props.searchListCaution(payload);
  }

  /**
   * handelChangeCaution
   * @param {object} item
   */
  handelChangeCaution = (item) => {
    this.setState({
      selected_content: item,
    });
  };

  /**
   * handleClickOk
   */
  handleClickOk() {
    this.props.onClickOk();
    this.props.caution(this.state.selected_content);
  }

  /**
   * handleClickClose
   */
  handleClickClose() {
    this.props.onClickClose();
    this.props.caution({});
  }

  /**
   * handleSearchCaution
   */
  handleSearchCaution() {}

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t, notice} = this.props;

    return (
      <Fade in={true} style={{overflow: 'auto'}}>
        <Paper className="modal_size_mid">
          <Container maxWidth="xl">
            <br></br>
            <Grid container spacing={1}>
              <Grid container alignItems="center" className="page_header" item xs={12}>
                <span className="font_bold font_size_big">{t('delivery.caution_title')}</span>
              </Grid>
              <Grid container alignItems="center" item xs={12}>
                <Grid item xs={2}>
                  <TextField
                    name="caution_id"
                    className="width_100"
                    margin="dense"
                    label={t('caution.id')}
                    value={this.state.caution_id}
                    onChange={(event) => onChangeTextField(this, event)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    name="caution_name"
                    className="width_100"
                    margin="dense"
                    label={t('caution.name')}
                    value={this.state.name}
                    onChange={(event) => onChangeTextField(this, event)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    name="caution_content"
                    className="width_100"
                    margin="dense"
                    label={t('caution.content')}
                    value={this.state.content}
                    onChange={(event) => onChangeTextField(this, event)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    className="button_margin button_color"
                    endIcon={<SearchIcon />}
                    onClick={this.handleSearchCaution}
                  >
                    {t('common.btnSearch')}
                  </Button>
                </Grid>
              </Grid>
              <Grid container alignItems="center" item xs={12}></Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid container alignItems="center" className="row_form_item table_border" item xs={12}>
                <Grid container alignItems="center" justify="center" className="font_bold" item xs={2}>
                  {t('caution.id')}
                </Grid>
                <Grid container alignItems="center" justify="center" className="item_border_left font_bold" item xs={3}>
                  {t('caution.name')}
                </Grid>
                <Grid container alignItems="center" justify="center" className="item_border_left font_bold" item xs={7}>
                  {t('caution.content')}
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={1} className="scroll_area_400 table_border">
              {notice.noticeList.length > 0 ? (
                notice.noticeList.map((item, index) => (
                  <Grid
                    id={index}
                    container
                    alignItems="center"
                    className={
                      this.state.selected_content.id === item.id ?
                        'row_form_item select_modal_item' :
                        'row_form_item table_border'
                    }
                    item
                    xs={12}
                    onClick={() => this.handelChangeCaution(item)}
                  >
                    <Grid item xs={2}>
                      {item.caution_id}
                    </Grid>
                    <Grid item xs={3}>
                      {item.caution_name}
                    </Grid>
                    <Grid item xs={7}>
                      {item.caution_content}
                    </Grid>
                  </Grid>
                ))
              ) : (
                <Grid container alignItems="center" justify="center" className="row_form_item" item xs={12}>
                  {t('error.no.record')}
                </Grid>
              )}
            </Grid>
            <Grid container spacing={1}>
              <Grid container justify="flex-end" alignItems="center" item xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  className="button_margin"
                  onClick={this.handleClickOk}
                  endIcon={<CheckIcon />}
                >
                  {t('common.btnSelect')}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  className="button_margin button_color"
                  onClick={this.handleClickClose}
                  endIcon={<CloseIcon />}
                >
                  {t('common.btnCancel')}
                </Button>
              </Grid>
            </Grid>
            <br></br>
          </Container>
        </Paper>
      </Fade>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notice: state.notice,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchListCaution: (payload, queryParams) => dispatch(searchListCaution(payload, queryParams)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
