/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-spread */
const Config = require('../common/config');

/* implement */
const self = (function() {
  const LOG_LEVEL_NONE = 1;
  const LOG_LEVEL_ERROR = 2;
  const LOG_LEVEL_WARN = 3;
  const LOG_LEVEL_INFO = 4;
  const LOG_LEVEL_DEBUG = 5;
  const LOG_LEVEL_LOG = 6;
  const LOG_LEVEL_TRACE = 7;

  let logLevel = LOG_LEVEL_TRACE;

  const none = function() {
    // do nothing
  };

  const error = function() {
    Config.DEBUG && (logLevel < LOG_LEVEL_ERROR ? none : console.error).apply(null, arguments);
  };

  const warn = function() {
    Config.DEBUG && (logLevel < LOG_LEVEL_WARN ? none : console.warn).apply(null, arguments);
  };

  const info = function() {
    Config.DEBUG && (logLevel < LOG_LEVEL_INFO ? none : console.info).apply(null, arguments);
  };

  const debug = function() {
    Config.DEBUG && (logLevel < LOG_LEVEL_DEBUG ? none : console.debug).apply(null, arguments);
  };

  const log = function() {
    Config.DEBUG && (logLevel < LOG_LEVEL_LOG ? none : console.log).apply(null, arguments);
  };

  const trace = function() {
    Config.DEBUG && (logLevel < LOG_LEVEL_TRACE ? none : console.trace).apply(null, arguments);
  };

  const getLogLevel = function() {
    return logLevel;
  };

  const setLogLevel = function(customLogLevel) {
    if (Number.isInteger(customLogLevel)) {
      logLevel = customLogLevel;
    }
    return self;
  };

  return {
    LOG_LEVEL_NONE: LOG_LEVEL_NONE,
    LOG_LEVEL_ERROR: LOG_LEVEL_ERROR,
    LOG_LEVEL_WARN: LOG_LEVEL_WARN,
    LOG_LEVEL_INFO: LOG_LEVEL_INFO,
    LOG_LEVEL_DEBUG: LOG_LEVEL_DEBUG,
    LOG_LEVEL_LOG: LOG_LEVEL_LOG,
    LOG_LEVEL_TRACE: LOG_LEVEL_TRACE,
    getLogLevel: getLogLevel,
    setLogLevel: setLogLevel,
    error: error,
    warn: warn,
    info: info,
    debug: debug,
    log: log,
    trace: trace,
  };
})();

/* export */
module.exports = self;
