export const ACTION_TYPES = {
  LOADING: 'LOADING',
  LOADING_FAIL: 'LOADING_FAIL',
  GET_LIST_GEOFENCE_SUCCESS: 'GET_LIST_ALLGEOFENCE_SUCCESS',
  GET_GEOFENCE_SUCCESS: 'GET_GEOFENCE_SUCCESS',
  GET_SERVICE_GEOFENCE_SUCCESS: 'GET_SERVICE_GEOFENCE_SUCCESS',
  DETAIL_SERVICE_GEOFENCE_SUCCESS: 'DETAIL_SERVICE_GEOFENCE_SUCCESS',
  AVAILABLE_SIMULATION_SUCCESS: 'AVAILABLE_SIMULATION_SUCCESS',

  SYNC_START: 'SYNC_START',
  SYNC_SUCCESS: 'SYNC_SUCCESS',
  SYNC_FAIL: 'SYNC_FAIL',
  CHECK_GEOFENCE_TIME_START: 'CHECK_GEOFENCE_TIME_START',
  CHECK_GEOFENCE_TIME_SUCCESS: 'CHECK_GEOFENCE_TIME_SUCCESS',
  CHECK_GEOFENCE_TIME_FAIL: 'CHECK_GEOFENCE_TIME_FAIL',

  GET_PROJECT_START: 'GET_PROJECT_START',
  GET_PROJECT_SUCCESS: 'GET_PROJECT_SUCCESS',
  GET_PROJECT_FAIL: 'GET_PROJECT_FAIL',
};

const initialState = {
  isLoading: false,
  listGeofence: [],
  isSyncFail: false,
  geofenceDetail: {},
  isSyncing: false,
  listService: [],
  serviceDetail: {},
  availableSimulation: {},
};

/**
 * function geofence management
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.LOADING:
    case ACTION_TYPES.CHECK_GEOFENCE_TIME_START:
    case ACTION_TYPES.GET_PROJECT_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.LOADING_FAIL:
    case ACTION_TYPES.CHECK_GEOFENCE_TIME_SUCCESS:
    case ACTION_TYPES.CHECK_GEOFENCE_TIME_FAIL:
    case ACTION_TYPES.GET_PROJECT_SUCCESS:
    case ACTION_TYPES.GET_PROJECT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.GET_LIST_GEOFENCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listGeofence: action.listGeofence,
      };
    case ACTION_TYPES.GET_GEOFENCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        geofenceDetail: action.geofenceDetail,
      };
    case ACTION_TYPES.SYNC_START:
      return {
        ...state,
        isSyncing: true,
        isLoading: true,
      };
    case ACTION_TYPES.AVAILABLE_SIMULATION_SUCCESS:
      return {
        ...state,
        availableSimulation: action.simulation,
      };
    case ACTION_TYPES.SYNC_SUCCESS:
      return {
        ...state,
        isSyncing: false,
        isSyncFail: false,
        isLoading: false,
        // geofenceDetail: action.geofenceDetail,
      };
    case ACTION_TYPES.GET_SERVICE_GEOFENCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listService: action.listService,
      };
    case ACTION_TYPES.DETAIL_SERVICE_GEOFENCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        serviceDetail: action.serviceDetail,
      };
    case ACTION_TYPES.SYNC_FAIL:
      return {
        ...state,
        isSyncing: false,
        isLoading: false,
        isSyncFail: true,
      };
    default:
      return {...state};
  }
}
