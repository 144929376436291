import React, {Component} from 'react';

import {Box, Button, Checkbox, Container, Fade, FormControlLabel, FormGroup, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography} from '@material-ui/core';
import {withTranslation} from 'react-i18next';

import {DATA_NULL, DATE_TIME_FORMAT, ROWS_PER_PAGE_OPTIONS} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import {getMemberReferrerList} from '../../../services/memberServices';
import {formatUtc} from '../../../utils/date_time_utils';

/**
 * ReferrerListModal
 */
class ReferrerListModal extends Component {
  /**
   * Component constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      status: [true, false],
      referrers: [],

      totalSize: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
    };
  }

  /**
   * Component Did Mount
   */
  componentDidMount = () => {
    this.getReferrers();
  }

  /**
   * Get referrers list
   * @param {Boolean} isReset
   */
  getReferrers = async (isReset = false) => {
    const {currentPage, rowsPerPage, status} = this.state;

    if (status.length === 0) {
      this.setState({referrers: [], totalSize: 0});
      return;
    }

    this.setState({
      currentPage: isReset ? 0 : currentPage,
      rowsPerPage: isReset ? ROWS_PER_PAGE_OPTIONS[0] : rowsPerPage,
    });

    const pageable = {
      page: isReset ? 0 : currentPage,
      size: isReset ? ROWS_PER_PAGE_OPTIONS[0] : rowsPerPage,
    };
    const params = status.length === 2 ? {
      ...pageable,
    } : {
      ...pageable,
      active: status[0],
    };

    await getMemberReferrerList(this.props.memberId, params).then(
      (response) => {
        if (response && response.status === 200) {
          this.setState({
            referrers: response.result.content,
            totalSize: response.result.totalSize,
          });
        };
      },
    );
  }

  /**
   * Handle check/uncheck active status
   * @param {Boolean} checked
   * @param {Boolean} statusName
   */
  handleCheckStatus = (checked, statusName) => {
    const {status} = this.state;
    const idx = status.indexOf(statusName);
    const statusCopy = [...status];
    checked ? statusCopy.push(statusName) : statusCopy.splice(idx, 1);

    this.setState({status: statusCopy}, () => this.getReferrers(true));
  };

  /**
   * Handle change curent page and rows per page
   * @param {Number} page
   * @param {Number} size
   */
  handleChangePagination = (page, size) => {
    this.setState({
      currentPage: page,
      rowsPerPage: size,
    }, this.getReferrers);
  };

  /**
   * Render Inactive Reason
   * @param {Object} referrer
   * @return {String}
   */
  renderInactiveReason = (referrer) => {
    const {t} = this.props;
    const {notes, created_date} = referrer;
    if (!notes || notes.length === 0) return DATA_NULL;
    return notes.map((note, idx) => {
      if (note === 'reason.inactive.because.that.member.withdrew') {
        return (<div key={idx}>{t(note, {memberCode: this.props.memberCode})}</div>);
      }
      if (note === 'reason.reactivate.because.this.member.recover.account') {
        return (<div key={idx}>{t(note, {datetime: formatUtc(created_date, DATE_TIME_FORMAT)})}</div>);
      }
      return (<div key={idx}>{t(note)}</div>);
    });
  }

  /**
   * Render component
   * @return {*}
   */
  render() {
    const {t} = this.props;
    const {referrers, status, currentPage, rowsPerPage, totalSize} = this.state;

    return (
      <Fade in={true}>
        <Paper className='modal_size_big'>
          <br />
          <Container maxWidth='xl'>
            <Grid container className='row_form_item'>
              <Grid container item spacing={1} alignItems='center'>
                <Grid item>
                  <img src={`${process.env.PUBLIC_URL}/favicon.ico`} alt='Logo' width='35' height='33' />
                </Grid>
                <Grid item>
                  <span className='font_bold font_size_big'>{t('route.memberManagementReferrer')}</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid container alignItems='center' className='row_form_item'>
              <Grid container item xs={6} lg={2} alignItems='center' className='grid_title_padding'>
                <Box p={2}>
                  <Typography>{t('common.status')}</Typography>
                </Box>
              </Grid>
              <Grid container item xs={6} lg={6} alignItems='center'>
                <FormGroup row>
                  <FormControlLabel
                    label={t('memberReferrer.active')}
                    labelPlacement='end'
                    control={
                      <Checkbox
                        className='checkbox_radio'
                        color='primary'
                        checked={status.includes(true)}
                        onClick={(event) => this.handleCheckStatus(event.target.checked, true)}
                      />
                    }
                  />
                  <FormControlLabel
                    label={t('memberReferrer.inactive')}
                    labelPlacement='end'
                    control={
                      <Checkbox
                        className='checkbox_radio'
                        color='primary'
                        checked={status.includes(false)}
                        onClick={(event) => this.handleCheckStatus(event.target.checked, false)}
                      />
                    }
                  />
                </FormGroup>
              </Grid>
            </Grid>
            <br />
            <Grid container alignItems='center' className='grid_title_padding'>
              <Box pl={2}>
                <Typography>{t('common.searchResult')} {totalSize} {t('common.case')}</Typography>
              </Box>
            </Grid>
            <br />
            {referrers.length > 0 && (
              <>
                <Paper>
                  <div className='scroll_area_400'>
                    <Table stickyHeader className='layoutfix'>
                      <TableHead>
                        <TableRow>
                          <TableCell className='width_150p ant-table-cell-fix-left'>{t('memberManagement.memberId')}</TableCell>
                          <TableCell className='width_150p'>{t('updateMember.lastName')}</TableCell>
                          <TableCell className='width_150p'>{t('updateMember.firstName')}</TableCell>
                          <TableCell className='width_300p'>{t('common.email')}</TableCell>
                          <TableCell className='width_150p'>{t('common.phoneNumber')}</TableCell>
                          <TableCell className='width_150p'>{t('common.status')}</TableCell>
                          <TableCell className='width_250p'>{t('memberReferrer.completedDateTime')}</TableCell>
                          <TableCell className='width_250p'>{t('memberReferrer.canceledDateTime')}</TableCell>
                          <TableCell className='width_300p'>{t('memberReferrer.inactiveReason')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {referrers?.map((referrer, idx) => (
                          <TableRow key={idx}>
                            <TableCell className='cell_fixed_left'>{referrer.member_code || DATA_NULL}</TableCell>
                            <TableCell>{referrer.last_name || DATA_NULL}</TableCell>
                            <TableCell>{referrer.first_name || DATA_NULL}</TableCell>
                            <TableCell>{referrer.email || DATA_NULL}</TableCell>
                            <TableCell>{referrer.phone_number || DATA_NULL}</TableCell>
                            <TableCell>{referrer.is_active ? t('memberReferrer.active') : t('memberReferrer.inactive')}</TableCell>
                            <TableCell>{referrer.created_date ? formatUtc(referrer.created_date, DATE_TIME_FORMAT) : DATA_NULL}</TableCell>
                            <TableCell>{referrer.cancel_date ? formatUtc(referrer.cancel_date, DATE_TIME_FORMAT) : DATA_NULL}</TableCell>
                            <TableCell>{this.renderInactiveReason(referrer)}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                  <Box py={1} px={2}>
                    <CustomPagination onChange={this.handleChangePagination} rows={totalSize} rowsPerPage={rowsPerPage} currentPage={currentPage} />
                  </Box>
                </Paper>
                <br />
              </>
            )}
            <Grid container alignItems='center' justifyContent='center'>
              <Button variant='contained' color='primary' className='button_margin button_color' onClick={this.props.handleClose}>
                {t('common.btnClose')}
              </Button>
            </Grid>
            <br />
          </Container>
        </Paper>
      </Fade>
    );
  }
}

export default withTranslation('translations')(ReferrerListModal);
