import React, {Component} from 'react';

import {
  Card,
  Container,
  Grid,
  Button,
  Table,
  TableCell,
  TableRow,
  TableBody,
  CardHeader,
  Typography,
  Box,
  TableHead,
} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';

import {
  DATA_NULL,
} from '../../../common/constant';
import {getReservationDetailSummary} from '../../../stores/reservation/actions';
import {displayDateTime, convertDatetimeUTC} from '../../../utils/datetime';
import withPermissionData from '../../../withPermissionData/hoc';

/**
 * Reservation management detail view
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    !!this.props.match.params.id && this.props.getReservationDetailSummary(this.props.match.params.id);
  }

  /**
   * Render component
   * @return {object}
   */
  render() {
    const {t, reservation} = this.props;

    return (
      <LoadingOverlay active={reservation.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('route.checking_booking_detail')}</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}></Grid>
            </Grid>
          </Container>
          <Container maxWidth="xl" style={{paddingTop: 25}}>
            <Card raised>
              <Container maxWidth="xl">
                <CardHeader
                  title={
                    <Typography gutterBottom variant="h5" component="h5">
                      {t('route.checking_booking_detail')}
                    </Typography>
                  }
                />
                <Box pb={3}>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell className="width_30">{t('reservationManagement.reservation_number')}</TableCell>
                        <TableCell>{reservation?.detailReservation?.reservation_code || DATA_NULL}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="width_30">Booking ID</TableCell>
                        <TableCell>{reservation?.detailReservation?.booking_id || DATA_NULL}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="width_30">External Booking ID</TableCell>
                        <TableCell>{reservation?.detailReservation?.external_booking_id || DATA_NULL}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="width_30">{t('driver.code')}</TableCell>
                        <TableCell>{reservation?.detailReservation?.driver_code || DATA_NULL}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="width_30">{t('driver.id')}</TableCell>
                        <TableCell>{reservation?.detailReservation?.driver_id || DATA_NULL}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="width_30">Driver Name</TableCell>
                        <TableCell>{reservation?.detailReservation?.driver_name || DATA_NULL}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="width_30">{t('common.email')}</TableCell>
                        <TableCell>{reservation?.detailReservation?.email || DATA_NULL}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="width_30">{t('businessVehicle.code')}</TableCell>
                        <TableCell>{reservation?.detailReservation?.vehicle_id || DATA_NULL}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="width_30">Vehicle Uuid</TableCell>
                        <TableCell>{reservation?.detailReservation?.vehicle_uuid || DATA_NULL}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="width_30">Pickup time</TableCell>
                        <TableCell>
                          {reservation?.detailReservation?.pickup_time ? displayDateTime(convertDatetimeUTC(reservation?.detailReservation?.pickup_time)) : DATA_NULL}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="width_30">Dropoff time</TableCell>
                        <TableCell>
                          {reservation?.detailReservation?.dropoff_time ? displayDateTime(convertDatetimeUTC(reservation?.detailReservation?.dropoff_time)) : DATA_NULL}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="width_30">Estimated pickup time</TableCell>
                        <TableCell>
                          {reservation?.detailReservation?.estimated_pickup_time ?
                            displayDateTime(convertDatetimeUTC(reservation?.detailReservation?.estimated_pickup_time)) :
                            DATA_NULL}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className="width_30">Estimated dropoff time</TableCell>
                        <TableCell>
                          {reservation?.detailReservation?.estimated_dropoff_time ?
                            displayDateTime(convertDatetimeUTC(reservation?.detailReservation?.estimated_dropoff_time)) :
                            DATA_NULL}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Container>
            </Card>
          </Container>

          <Container maxWidth="xl" style={{marginTop: 25}}>
            <Card raised>
              <Container maxWidth="xl">
                <CardHeader
                  title={
                    <Typography gutterBottom variant="h5" component="h5">
                      Estimate (eta)
                    </Typography>
                  }
                />
                <Box pb={3}>
                  <div className="scroll_area_700">
                    <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                      <TableHead>
                        <TableRow>
                          <TableCell className="width_50p ant-table-cell-fix-left">{t('common.id')}</TableCell>
                          <TableCell className="width_75p">Booking ID</TableCell>
                          <TableCell className="width_75p">Booking UID</TableCell>
                          <TableCell className="width_75p">Node ID</TableCell>
                          <TableCell className="width_125p">Node UID</TableCell>
                          <TableCell className="width_50p">Vehicle ID</TableCell>
                          <TableCell className="width_75p">Vehicle Agent ID</TableCell>
                          <TableCell className="width_150p">Estimated earliest arrival ts</TableCell>
                          <TableCell className="width_150p">Estimated scheduled ts</TableCell>
                          <TableCell className="width_150p">Scheduled ts</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {reservation?.detailReservation?.etas?.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell className="ant-table-cell-fix-left">
                              {item?.id || DATA_NULL}
                            </TableCell>
                            <TableCell>
                              {item?.booking_id || DATA_NULL}
                            </TableCell>
                            <TableCell>
                              {item?.booking_uid || DATA_NULL}
                            </TableCell>
                            <TableCell>
                              {item?.node_id || DATA_NULL}
                            </TableCell>
                            <TableCell>
                              {item?.node_uid || DATA_NULL}
                            </TableCell>
                            <TableCell>
                              {item?.vehicle_id || DATA_NULL}
                            </TableCell>
                            <TableCell>
                              {item?.vehicle_agent_id || DATA_NULL}
                            </TableCell>
                            <TableCell>
                              {item?.estimated_earliest_arrival_ts ?
                                (`
                                  ${displayDateTime(item?.estimated_earliest_arrival_ts)} (UTC)
                                  ${displayDateTime(convertDatetimeUTC(item?.estimated_earliest_arrival_ts, 'VN'))} (+7)
                                  ${displayDateTime(convertDatetimeUTC(item?.estimated_earliest_arrival_ts, 'JP'))} (+9)
                                `) :
                                DATA_NULL}
                            </TableCell>
                            <TableCell>
                              {item?.estimated_scheduled_ts ?
                                (`
                                  ${displayDateTime(item?.estimated_scheduled_ts)} (UTC)
                                  ${displayDateTime(convertDatetimeUTC(item?.estimated_scheduled_ts, 'VN'))} (+7)
                                  ${displayDateTime(convertDatetimeUTC(item?.estimated_scheduled_ts, 'JP'))} (+9)
                                `) :
                                DATA_NULL}
                            </TableCell>
                            <TableCell>
                              {item?.scheduled_ts ?
                                (`
                                  ${displayDateTime(item?.scheduled_ts)} (UTC)
                                  ${displayDateTime(convertDatetimeUTC(item?.scheduled_ts, 'VN'))} (+7)
                                  ${displayDateTime(convertDatetimeUTC(item?.scheduled_ts, 'JP'))} (+9)
                                `) :
                                DATA_NULL}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </Box>
              </Container>
            </Card>
          </Container>

          <Container maxWidth="xl" style={{marginTop: 25}}>
            <Card raised>
              <Container maxWidth="xl">
                <CardHeader
                  title={
                    <Typography gutterBottom variant='h5' component='h5'>
                        ETA Booking History
                    </Typography>
                  }
                />
                <Box pb={3}>
                  <div className="scroll_area_700">
                    <Table size='small' aria-label='sticky table' stickyHeader className='layoutfix'>
                      <TableHead style={{zIndex: '100 !important'}}>
                        <TableRow>
                          <TableCell
                            className='width_75p ant-table-cell-fix-left'
                            style={{zIndex: '5 !important'}}
                          >
                            {t('common.id')}
                          </TableCell>
                          <TableCell className='width_75p'>Booking ID</TableCell>
                          <TableCell className='width_100p'>Booking UID</TableCell>
                          <TableCell className='width_200p'>Estimated earliest arrival ts</TableCell>
                          <TableCell className='width_200p'>Estimated scheduled ts</TableCell>
                          <TableCell className='width_75p'>Node ID</TableCell>
                          <TableCell className='width_200p'>Node UID</TableCell>
                          <TableCell className='width_400p'>Polyline</TableCell>
                          <TableCell className='width_200p'>Scheduled ts</TableCell>
                          <TableCell className='width_200p'>Vehicle agent ID</TableCell>
                          <TableCell className='width_75p'>Vehicle ID</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {reservation?.detailReservation?.eta_booking_histories?.map((eta_booking, index) => (
                          <TableRow key={index}>
                            <TableCell className='ant-table-cell-fix-left'>{eta_booking?.id || DATA_NULL}</TableCell>
                            <TableCell>{eta_booking?.booking_id || DATA_NULL}</TableCell>
                            <TableCell>{eta_booking?.booking_uid || DATA_NULL}</TableCell>
                            <TableCell>
                              {eta_booking?.estimated_earliest_arrival_ts ?
                              (`
                                ${displayDateTime(eta_booking?.estimated_earliest_arrival_ts)} (UTC)
                                ${displayDateTime(convertDatetimeUTC(eta_booking?.estimated_earliest_arrival_ts, 'VN'))} (+7)
                                ${displayDateTime(convertDatetimeUTC(eta_booking?.estimated_earliest_arrival_ts, 'JP'))} (+9)
                              `) :
                              DATA_NULL}
                            </TableCell>
                            <TableCell>
                              {eta_booking?.estimated_scheduled_ts ?
                              (`
                                ${displayDateTime(eta_booking?.estimated_scheduled_ts)} (UTC)
                                ${displayDateTime(convertDatetimeUTC(eta_booking?.estimated_scheduled_ts, 'VN'))} (+7)
                                ${displayDateTime(convertDatetimeUTC(eta_booking?.estimated_scheduled_ts, 'JP'))} (+9)
                              `) :
                              DATA_NULL}
                            </TableCell>
                            <TableCell>{eta_booking?.node_id || DATA_NULL}</TableCell>
                            <TableCell>{eta_booking?.node_uid || DATA_NULL}</TableCell>
                            <TableCell>{eta_booking?.polyline || DATA_NULL}</TableCell>
                            <TableCell>
                              {eta_booking?.scheduled_ts ?
                              (`
                                ${displayDateTime(eta_booking?.scheduled_ts)} (UTC)
                                ${displayDateTime(convertDatetimeUTC(eta_booking?.scheduled_ts, 'VN'))} (+7)
                                ${displayDateTime(convertDatetimeUTC(eta_booking?.scheduled_ts, 'JP'))} (+9)
                              `) :
                              DATA_NULL}
                            </TableCell>
                            <TableCell>{eta_booking?.vehicle_agent_id || DATA_NULL}</TableCell>
                            <TableCell>{eta_booking?.vehicle_id || DATA_NULL}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </Box>
              </Container>
            </Card>
          </Container>

          <Container maxWidth="xl" style={{marginTop: 25}}>
            <Card raised>
              <Container maxWidth="xl">
                <CardHeader
                  title={
                    <Typography gutterBottom variant="h5" component="h5">
                      Polyline for driver
                    </Typography>
                  }
                />
                <Box pb={3}>
                  <div className="scroll_area_500">
                    <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                      <TableHead>
                        <TableRow>
                          <TableCell className="width_50p ant-table-cell-fix-left">{t('common.id')}</TableCell>
                          <TableCell className="width_75p">Event</TableCell>
                          <TableCell className="width_75p">{t('sub.type')}</TableCell>
                          <TableCell className="width_75p">{t('waypoint.latitude')}</TableCell>
                          <TableCell className="width_75p">{t('waypoint.longitude')}</TableCell>
                          <TableCell className="width_75p">Created At</TableCell>
                          <TableCell className="width_75p">Updated At</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {reservation?.detailReservation?.polyline_for_driver?.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell className="ant-table-cell-fix-left">
                              {item?.id || DATA_NULL}
                            </TableCell>
                            <TableCell>
                              {item?.event || DATA_NULL}
                            </TableCell>
                            <TableCell>
                              {item?.type || DATA_NULL}
                            </TableCell>
                            <TableCell>
                              {item?.lat || DATA_NULL}
                            </TableCell>
                            <TableCell>
                              {item?.lon || DATA_NULL}
                            </TableCell>
                            <TableCell>
                              {item?.created_date ?
                                (`
                                  ${displayDateTime(item?.created_date)} (UTC)
                                  ${displayDateTime(convertDatetimeUTC(item?.created_date, 'VN'))} (+7)
                                  ${displayDateTime(convertDatetimeUTC(item?.created_date, 'JP'))} (+9)
                                `) :
                                DATA_NULL}
                            </TableCell>
                            <TableCell>
                              {item?.updated_date ?
                                (`
                                  ${displayDateTime(item?.updated_date)} (UTC)
                                  ${displayDateTime(convertDatetimeUTC(item?.updated_date, 'VN'))} (+7)
                                  ${displayDateTime(convertDatetimeUTC(item?.updated_date, 'JP'))} (+9)
                                `) :
                                DATA_NULL}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </Box>
              </Container>
            </Card>
          </Container>

          <Container maxWidth="xl" style={{marginTop: 25}}>
            <Card raised>
              <Container maxWidth="xl">
                <CardHeader
                  title={
                    <Typography gutterBottom variant="h5" component="h5">
                      Trip event data
                    </Typography>
                  }
                />
                <Box pb={3}>
                  <div className="scroll_area_500">
                    <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                      <TableHead>
                        <TableRow>
                          <TableCell className="width_75p">Reason</TableCell>
                          <TableCell className="width_75p">{t('driver.type')}</TableCell>
                          <TableCell className="width_150p ant-table-cell-fix-left">Created At (UTC)</TableCell>
                          <TableCell className="width_150p">Created At (+7)</TableCell>
                          <TableCell className="width_150p">Created At (+9)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {reservation?.detailReservation?.trip_event_data?.data?.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell className="ant-table-cell-fix-left">
                              {item?.reason || DATA_NULL}
                            </TableCell>
                            <TableCell>
                              {item?.type || DATA_NULL}
                            </TableCell>
                            <TableCell>
                              {item?.create_at ?
                                displayDateTime(item?.create_at) :
                                DATA_NULL}
                            </TableCell>
                            <TableCell className="ant-table-cell-fix-left">
                              {item?.create_at ?
                                displayDateTime(convertDatetimeUTC(item?.create_at, 'VN')) :
                                DATA_NULL}
                            </TableCell>
                            <TableCell className="ant-table-cell-fix-left">
                              {item?.create_at ?
                                displayDateTime(convertDatetimeUTC(item?.create_at, 'JP')) :
                                DATA_NULL}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </Box>
              </Container>
            </Card>
          </Container>

          <Container maxWidth='xl' style={{marginTop: 25}}>
            <Card raised>
              <Container maxWidth='xl'>
                <CardHeader
                  title={
                    <Typography gutterBottom variant='h5' component='h5'>
                      Trip info
                    </Typography>
                  }
                />

                <Box pb={3}>
                  <Table size='small'>
                    <TableBody>
                      <TableRow>
                        <TableCell className='width_30'>ID</TableCell>
                        <TableCell>
                          {reservation?.detailReservation?.trip_info?.id || DATA_NULL}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className='width_30'>App version</TableCell>
                        <TableCell>
                          {reservation?.detailReservation?.trip_info?.app_version || DATA_NULL}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className='width_30'>OS</TableCell>
                        <TableCell>
                          {reservation?.detailReservation?.trip_info?.os || DATA_NULL}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className='width_30'>Cost without Unitrand</TableCell>
                        <TableCell>
                          {
                            reservation?.detailReservation?.trip_info?.costWithOutUnitrand || reservation?.detailReservation?.trip_info?.costWithOutUnitrand === 0 ?
                            reservation?.detailReservation?.trip_info?.costWithOutUnitrand :
                            DATA_NULL
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className='width_30'>Estimate driving trip</TableCell>
                        <TableCell>
                          {
                            reservation?.detailReservation?.trip_info?.estimate_driving_trip || reservation?.detailReservation?.trip_info?.estimate_driving_trip === 0 ?
                            reservation?.detailReservation?.trip_info?.estimate_driving_trip :
                            DATA_NULL
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className='width_30'>Drop off location name</TableCell>
                        <TableCell>
                          {reservation?.detailReservation?.trip_info?.drop_off_location_name || DATA_NULL}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className='width_30'>Estimate arrival time</TableCell>
                        <TableCell>
                          {reservation?.detailReservation?.trip_info?.estimate_arrival_time ?
                          `${displayDateTime(reservation?.detailReservation?.trip_info?.estimate_arrival_time)} (UTC)` :
                          DATA_NULL
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className='width_30'>Estimate start time</TableCell>
                        <TableCell>
                          {reservation?.detailReservation?.trip_info?.estimate_start_time ?
                          `${displayDateTime(reservation?.detailReservation?.trip_info?.estimate_start_time)} (UTC)` :
                          DATA_NULL
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className='width_30'>Estimate total cost</TableCell>
                        <TableCell>
                          {reservation?.detailReservation?.trip_info?.estimate_total_cost || DATA_NULL}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className='width_30'>Pickup location name</TableCell>
                        <TableCell>
                          {reservation?.detailReservation?.trip_info?.pickup_location_name || DATA_NULL}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className='width_30'>Requested departure time</TableCell>
                        <TableCell>
                          {reservation?.detailReservation?.trip_info?.requested_departure_time ?
                          `${displayDateTime(reservation?.detailReservation?.trip_info?.requested_departure_time)} (UTC)` :
                          DATA_NULL
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className='width_30'>Simulation ID</TableCell>
                        <TableCell>
                          {reservation?.detailReservation?.trip_info?.simulation_id || DATA_NULL}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className='width_30'>Transfer count</TableCell>
                        <TableCell>
                          {reservation?.detailReservation?.trip_info?.transfer_count}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className='width_30'>Total cost</TableCell>
                        <TableCell>
                          {
                            reservation?.detailReservation?.trip_info?.totalCost || reservation?.detailReservation?.trip_info?.totalCost === 0 ?
                            reservation?.detailReservation?.trip_info?.totalCost :
                            DATA_NULL
                          }
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <div style={{padding: '20px 16px 10px'}}>Routes:</div>
                  <div className="scroll_area_500">
                    <Table size='small' aria-label='sticky table' stickyHeader className='layoutfix'>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className='width_150p'
                            style={{zIndex: 5}}
                          >
                            Type
                          </TableCell>
                          <TableCell className='width_150p'>Agency name</TableCell>
                          <TableCell className='width_150p'>Currency code</TableCell>
                          <TableCell className='width_150p'>Start location info</TableCell>
                          <TableCell className='width_200p'>Start location latitude</TableCell>
                          <TableCell className='width_200p'>Start location longitude</TableCell>
                          <TableCell className='width_250p'>Start location name</TableCell>
                          <TableCell className='width_150p'>End location info</TableCell>
                          <TableCell className='width_200p'>End location latitude</TableCell>
                          <TableCell className='width_200p'>End location longitude</TableCell>
                          <TableCell className='width_250p'>End location name</TableCell>
                          <TableCell className='width_150p'>Estimate adult cost</TableCell>
                          <TableCell className='width_150p'>Estimate child cost</TableCell>
                          <TableCell className='width_150p'>Estimate cost</TableCell>
                          <TableCell className='width_200p'>Estimate start time</TableCell>
                          <TableCell className='width_200p'>Estimate end time</TableCell>
                          <TableCell className='width_150p'>Estimate vehicle ID</TableCell>
                          <TableCell className='width_700p'>Partner coupon</TableCell>
                          <TableCell className='width_150p'>Simulation ID</TableCell>
                          <TableCell className='width_150p'>Subcribed member</TableCell>
                          <TableCell className='width_150p'>Vehicle type</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {reservation?.detailReservation?.trip_info?.routes?.map((route, index) => (
                          <TableRow key={index}>
                            <TableCell className='ant-table-cell-fix-left'>{route?.type || DATA_NULL}</TableCell>
                            <TableCell>{route?.agency_name || DATA_NULL}</TableCell>
                            <TableCell>{route?.currency_code || DATA_NULL}</TableCell>
                            <TableCell>{route?.start_location_info || DATA_NULL}</TableCell>
                            <TableCell>{route?.start_location_lat || DATA_NULL}</TableCell>
                            <TableCell>{route?.start_location_lon || DATA_NULL}</TableCell>
                            <TableCell>{route?.start_location_name || DATA_NULL}</TableCell>
                            <TableCell>{route?.end_location_info || DATA_NULL}</TableCell>
                            <TableCell>{route?.end_location_lat || DATA_NULL}</TableCell>
                            <TableCell>{route?.end_location_lon || DATA_NULL}</TableCell>
                            <TableCell>{route?.end_location_name || DATA_NULL}</TableCell>
                            <TableCell>{route?.estimate_adult_cost}</TableCell>
                            <TableCell>{route?.estimate_child_cost}</TableCell>
                            <TableCell>{route?.estimate_cost}</TableCell>
                            <TableCell>
                              {route?.estimate_start_time ?
                                (`
                                  ${displayDateTime(route?.estimate_start_time)} (UTC)
                                  ${displayDateTime(convertDatetimeUTC(route?.estimate_start_time, 'VN'))} (+7)
                                  ${displayDateTime(convertDatetimeUTC(route?.estimate_start_time, 'JP'))} (+9)
                                `) :
                                DATA_NULL}
                            </TableCell>
                            <TableCell>
                              {route?.estimate_end_time ?
                                (`
                                  ${displayDateTime(route?.estimate_end_time)} (UTC)
                                  ${displayDateTime(convertDatetimeUTC(route?.estimate_end_time, 'VN'))} (+7)
                                  ${displayDateTime(convertDatetimeUTC(route?.estimate_end_time, 'JP'))} (+9)
                                `) :
                                DATA_NULL}
                            </TableCell>
                            <TableCell>{route?.estimate_vehicle_id || DATA_NULL}</TableCell>
                            <TableCell>
                              <pre>
                                {route?.partner_coupon && JSON.stringify(route?.partner_coupon, null, 2)}
                              </pre>
                            </TableCell>
                            <TableCell>{route?.simulation_id || DATA_NULL}</TableCell>
                            <TableCell>{route?.subcribed_member || DATA_NULL}</TableCell>
                            <TableCell>{route?.vehicle_type || DATA_NULL}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </Box>
              </Container>
            </Card>
          </Container>

          <Container maxWidth="xl">
            <Box p={1}>
              <Grid container spacing={2}>
                <Grid container alignItems="center" justify="flex-end" item xs={12}>
                  <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.props.history.goBack}>
                    {t('common.btnReturn')}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reservation: state.reservation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getReservationDetailSummary: (bookingID) => dispatch(getReservationDetailSummary(bookingID)),
  };
};

export default withPermissionData(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
