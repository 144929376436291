import React, {Component} from 'react';

import {
  Button,
  Card,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  Select,
  TextField,
  Dialog,
  Modal,
  Backdrop,
  MenuItem,
  Box,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import CompareArrowsIcon from '@material-ui/icons/SwapHoriz';
import {ToggleButtonGroup} from '@material-ui/lab';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {parseISO} from 'date-fns';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import Carousel from 'react-material-ui-carousel';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {
  ROUTE,
  DISCOUNT_TYPE,
  LIST_PERCENT,
  PERMISSION_ACTIONS,
  LANGUAGE,
  CAMPAIGN_INFOR_DISPLAY,
  RESTRICT_TYPE,
  REPEAT_SETTING,
  DATE_FORMAT,
  DAYS,
  DAY_OF_MONTH,
  TIME_FORMAT,
  RESTRICT_SETTING,
  ROWS_PER_PAGE_OPTIONS,
  RESTRICT_WAYPOINT_DISPLAY_TYPE,
  CURRENCY,
} from '../../../common/constant';
import CancelBookingSuccessModal from '../../../components/cancelBookingSuccessModal';
import CustomTimePicker from '../../../components/CustomTimePicker';
import ImageUpload from '../../../components/imageSelect';
import MultiDate from '../../../components/multiDate';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {updateWaypointApi, getWaypointDetailApi, checkAffectedReservationsApi} from '../../../services/businessServices';
import {getAllGeofence} from '../../../stores/common/actions';
import {searchListImage} from '../../../stores/image/action';
import {setMessageModal} from '../../../stores/modal/actions';
import {onChangeTextField, backForwardRouter, onChangeNumberCurrency} from '../../../utils/common';
import {compareDateTimeRange, convertDateTimeToDate, isOverlapDateRelatively} from '../../../utils/datetime';
import {modalObj} from '../../../utils/modal';
import StyledToggle from '../partner/StyleDayOfWeekPicker';

import './style.css';

/**
 * Waypoint Form Component
 */
class WaypointForm extends Component {
  /**
   * constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      geofence_id: '',
      name_jp: '',
      name_vi: '',
      name_en: '',
      latitude: '',
      longitude: '',
      description_jp: '',
      description_vi: '',
      description_en: '',
      disabled: false,
      logo_url: '',
      image_master_id: '',
      product_images_id: [],
      business_time: '',

      display_name_jp: '',
      display_name_vi: '',
      display_name_en: '',
      street_name_jp: '',
      street_name_vi: '',
      street_name_en: '',
      transit_stop_swat_id: '',
      discount: 0,
      discount_type: '',
      currency: '',
      website: '',
      phone_number: '',

      isSubmitForm: false,
      showUpdateModal: false,
      openModal: false,
      openProductImagesModal: false,
      isCheckCampaign: false,
      affectedReservations: null,
      discountCommentType: null,
      campaignInforComment: '',
      restrict_type: [],
      exceptional_days: [],
      restrict_day: [],
      repeat_setting: 'DAILY',
      repeat_start_date: null,
      repeat_end_date: null,
      repeat_on_date_of_week: [],
      repeat_on_date_of_month: [],
      monthly_on_day: null,
      list_restrict_time: [{from: '', to: ''}],
      list_waypoint_restrict: [],
      restrict_description: '',
      restrict_description_ja: '',
      restrict_description_vi: '',
      restrict_description_en: '',
      overlapList: new Set(),
      restrict_photo_url: '',
      restrict_photo_id: '',
      restrict_setting: 'NO_RESTRICTED',
      waypoint_display_type: 'PARTNER_DISPLAY',
      clone_waypoint_display_type: '',
      partner_start_time: '',
      partner_end_time: '',
      bookingCancelIds: [],
      isSuccessBookingCancel: false,
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllGeofence({targetScreen: 'ENABLE_FOR_ADMIN_ALL'});
    if (this.props.match.params.id) {
      const queryParams = {
        name: 'restrict_waypoint_image',
        currentPage: 0,
        rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      };
      await this.props.searchListImage(queryParams).then((res) => {
        this.setState({
          restrict_photo_url: res?.content[0]?.url,
          restrict_photo_id: res?.content[0]?.id,
        });
      });
      await getWaypointDetailApi(this.props.match.params.id).then(
        (response) => {
          if (response?.status === 200) {
            const waypointDetail = response.result;
            const arrRestrictSetting = [];
            if (waypointDetail.restriction?.restriction_setting) {
              arrRestrictSetting.push('REPEAT');
            }
            if (waypointDetail.restriction?.specific_dates?.length > 0) {
              arrRestrictSetting.push('SPECIFIC_DAYS');
            }
            this.setState({
              id: this.props.match.params.id,
              geofence_id: waypointDetail?.geofence,
              name_jp: waypointDetail?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.name || '',
              name_vi: waypointDetail?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.name || '',
              name_en: waypointDetail?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.name || '',
              latitude: waypointDetail?.latitude,
              longitude: waypointDetail?.longitude,
              description_jp: waypointDetail?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.description || '',
              description_vi: waypointDetail?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.description || '',
              description_en: waypointDetail?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.description || '',
              disabled: waypointDetail?.disabled,
              logo_url: waypointDetail?.logo_url,
              product_images_id: waypointDetail?.product_images,
              image_master_id: waypointDetail?.image_master_id,
              display_name_jp: waypointDetail?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.display_name || '',
              display_name_vi: waypointDetail?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.display_name || '',
              display_name_en: waypointDetail?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.display_name || '',
              street_name_jp: waypointDetail?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.street_name || '',
              street_name_vi: waypointDetail?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.street_name || '',
              street_name_en: waypointDetail?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.street_name || '',
              transit_stop_swat_id: waypointDetail?.transit_stop_swat_id,
              discount: waypointDetail?.discount,
              discount_type: waypointDetail?.discount_type,
              currency: waypointDetail?.currency,
              website: waypointDetail?.website,
              phone_number: waypointDetail?.phone_number,
              business_time: waypointDetail?.business_time,
              isCheckCampaign: waypointDetail?.is_campaign_displayed || false,
              discountCommentType: waypointDetail?.campaign_display_type,
              campaignInforComment: waypointDetail?.comment === null ? '' : waypointDetail?.comment,
              restrict_setting: waypointDetail?.restriction_type,
              repeat_setting: waypointDetail?.restriction?.restriction_setting || 'DAILY',
              repeat_on_date_of_week: waypointDetail?.restriction?.repeated_dates?.filter((item) => DAYS?.map((item) => item.key)?.includes(item)) || [],
              repeat_on_date_of_month: waypointDetail?.restriction?.repeated_dates?.filter((item) => DAY_OF_MONTH?.includes(item)) || [],
              list_restrict_time:
                waypointDetail?.restriction?.restrict_times?.length > 0 ? this.formatDataHourToDate(waypointDetail?.restriction?.restrict_times) : [{from: '', to: ''}],
              restrict_days: waypointDetail?.restriction?.specific_dates || [],
              exceptional_days: waypointDetail?.restriction?.exclude_dates || [],
              repeat_start_date: waypointDetail?.restriction?.start_date || null,
              repeat_end_date: waypointDetail?.restriction?.end_date || null,
              restrict_type: arrRestrictSetting,
              restrict_description_ja:
                waypointDetail?.restriction?.restriction_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.description || '',
              restrict_description_vi:
                waypointDetail?.restriction?.restriction_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.description || '',
              restrict_description_en:
                waypointDetail?.restriction?.restriction_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.description || '',
              restrict_photo_url: waypointDetail?.restriction?.restriction_image_url ? waypointDetail?.restriction?.restriction_image_url : this.state.restrict_photo_url,
              restrict_photo_id: waypointDetail?.restriction?.restriction_image_id ? waypointDetail?.restriction?.restriction_image_id : this.state.restrict_photo_id,
              partner_start_time: waypointDetail?.partner_start_time || '',
              partner_end_time: waypointDetail?.partner_end_time || '',
              geofence: waypointDetail?.geofence,
              waypoint_display_type: waypointDetail?.waypoint_display_type || '',
              clone_waypoint_display_type: waypointDetail?.waypoint_display_type || '',
            });
          } else {
            this.props.setMessageModal(modalObj(true, response.message));
            if (response.message === 'permission.denied') {
              backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.HOME);
            }
          }
        },
        () => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.WAYPOINT),
      );
    }
  }

  /**
   * validateWaypointDetail
   */
  validateWaypointDetail = async () => {
    this.setState({isSubmitForm: true});
    const validOperatingTimeRanges = this.state.restrict_setting === 'SELF_RESTRICTED' ? await this.validateOperatingTimeRanges() : true;
    const checkDuplicatedDay = await this.checkDuplicatedDay();
    const validTimeRange = this.state.restrict_type?.includes('REPEAT') ? compareDateTimeRange(this.state.repeat_start_date, this.state.repeat_end_date, true) : true;
    const validDiscountNormalDisplay = await this.handleCheckDiscountNormalDisplay();
    const validTimeRestrict = this.state.restrict_setting === 'SELF_RESTRICTED' ? await this.checkTimeRange() : true;
    if (
      this.validator.allValid() &&
      (this.state.partner_start_time && this.state.repeat_start_date && this.state.restrict_type?.includes('REPEAT') ?
        this.validateTimeWithPartner(this.state.repeat_start_date) :
        true) &&
      (this.state.partner_end_time && this.state.repeat_end_date && this.state.restrict_type?.includes('REPEAT') ?
        this.validateTimeWithPartner(this.state.repeat_end_date) :
        true) &&
      validOperatingTimeRanges &&
      checkDuplicatedDay &&
      validTimeRange &&
      validDiscountNormalDisplay &&
      validTimeRestrict
    ) {
      this.openUpdateConfirmModal();
    }
  };

  /**
   * openUpdateConfirmModal
   */
  openUpdateConfirmModal = async () => {
    const affectedReservations = await this.checkAffectedReservations();
    if (affectedReservations) {
      this.setState({
        affectedReservations,
        showUpdateModal: true,
      });
    } else {
      this.setState({
        showUpdateModal: false,
      });
    }
  };

  /**
   * checkAffectedReservations
   * @return {Number} number of affected reservations
   */
  checkAffectedReservations = async () => {
    const {
      id,
      geofence_id,
      restrict_setting,
      repeat_setting,
      repeat_on_date_of_week,
      repeat_on_date_of_month,
      list_restrict_time,
      restrict_days,
      exceptional_days,
      repeat_start_date,
      repeat_end_date,
      waypoint_display_type,
      restrict_type,
    } = this.state;

    const new_restrict_days = restrict_days?.map((item) => {
      return item.replace(/\//g, '-');
    });
    const new_exceptional_days = exceptional_days?.map((item) => {
      return item.replace(/\//g, '-');
    });
    const repeated_dates = repeat_setting === 'WEEKLY' ? repeat_on_date_of_week : repeat_setting === 'MONTHLY' ? repeat_on_date_of_month : [];

    const restrict = {
      restriction_setting: restrict_type?.includes('REPEAT') ? repeat_setting : null,
      repeated_dates: restrict_type?.includes('REPEAT') ? repeated_dates : null,
      specific_dates: restrict_type?.includes('SPECIFIC_DAYS') ? new_restrict_days : null,
      exclude_dates: restrict_type?.includes('REPEAT') ? new_exceptional_days : null,
      start_date: restrict_type?.includes('REPEAT') ? convertDateTimeToDate(new Date(repeat_start_date)) : null,
      end_date: restrict_type?.includes('REPEAT') ? convertDateTimeToDate(new Date(repeat_end_date)) : null,
      restrict_times: this.formatDataDateToHour(list_restrict_time),
      waypoint_display_type: restrict_setting === 'SELF_RESTRICTED' ? waypoint_display_type : 'PARTNER_DISPLAY',
    };
    Object.keys(restrict).forEach((key) => {
      if (restrict[key] === null) {
        delete restrict[key];
      }
    });

    let affectedReservations;

    const payload = {
      waypoint_ids: [`${id}`],
      geofence_id,
      restriction: restrict_setting === 'SELF_RESTRICTED' ? restrict : null,
      restriction_type: restrict_setting,
      from_partner: false,
    };

    await checkAffectedReservationsApi(payload).then((response) => {
      if (response.status === 200) {
        affectedReservations = response.result;
        this.setState({bookingCancelIds: affectedReservations});
      } else {
        const message = response.message_code || response.message || 'error.500';
        this.props.setMessageModal(modalObj(true, message));
      }
    });

    return affectedReservations;
  };

  /**
   * update the
   */
  update = async () => {
    const {
      id,
      name_jp,
      name_vi,
      name_en,
      latitude,
      longitude,
      description_jp,
      description_vi,
      description_en,
      disabled,
      image_master_id,
      display_name_jp,
      display_name_vi,
      display_name_en,
      street_name_jp,
      street_name_vi,
      street_name_en,
      transit_stop_swat_id,
      discount,
      business_time,
      discount_type,
      currency,
      website,
      phone_number,
      isCheckCampaign,
      discountCommentType,
      campaignInforComment,
      restrict_setting,
      repeat_setting,
      repeat_on_date_of_week,
      repeat_on_date_of_month,
      list_restrict_time,
      restrict_days,
      exceptional_days,
      repeat_start_date,
      repeat_end_date,
      restrict_description_en,
      restrict_description_vi,
      restrict_description_ja,
      restrict_photo_id,
      restrict_photo_url,
      waypoint_display_type,
      restrict_type,
      affectedReservations,
    } = this.state;
    const new_restrict_days = restrict_days?.map((item) => {
      return item.replace(/\//g, '-');
    });
    const new_exceptional_days = exceptional_days?.map((item) => {
      return item.replace(/\//g, '-');
    });
    const restriction_languages = [];
    restrict_description_en &&
      restriction_languages.push({
        language_id: LANGUAGE.find((item) => item.code === 'en')?.id,
        description: restrict_description_en,
      });
    restrict_description_vi &&
      restriction_languages.push({
        language_id: LANGUAGE.find((item) => item.code === 'vi')?.id,
        description: restrict_description_vi,
      });
    restrict_description_ja &&
      restriction_languages.push({
        language_id: LANGUAGE.find((item) => item.code === 'ja')?.id,
        description: restrict_description_ja,
      });
    const repeated_dates = repeat_setting === 'WEEKLY' ? repeat_on_date_of_week : repeat_setting === 'MONTHLY' ? repeat_on_date_of_month : [];
    this.closeConfirmUpdateModal();
    const product_images_id =
      this.state.product_images_id.length > 0 ?
        this.state.product_images_id.map((img) => {
            return img.id;
          }) :
        [];
    const restrict = {
      restriction_setting: restrict_type?.includes('REPEAT') ? repeat_setting : null,
      repeated_dates: restrict_type?.includes('REPEAT') ? repeated_dates : null,
      specific_dates: restrict_type?.includes('SPECIFIC_DAYS') ? new_restrict_days : null,
      exclude_dates: restrict_type?.includes('REPEAT') ? new_exceptional_days : null,
      start_date: restrict_type?.includes('REPEAT') ? convertDateTimeToDate(new Date(repeat_start_date)) : null,
      end_date: restrict_type?.includes('REPEAT') ? convertDateTimeToDate(new Date(repeat_end_date)) : null,
      restriction_languages,
      restrict_times: this.formatDataDateToHour(list_restrict_time),
      restriction_image_url: restrict_photo_url,
      restriction_image_id: restrict_photo_id,
      waypoint_display_type: restrict_setting === 'SELF_RESTRICTED' ? waypoint_display_type : 'PARTNER_DISPLAY',
    };
    Object.keys(restrict).forEach((key) => {
      if (restrict[key] === null) {
        delete restrict[key];
      }
    });
    const waypoint_languages = [];
    name_jp &&
      waypoint_languages.push({
        language_id: LANGUAGE.find((item) => item.code === 'ja')?.id,
        name: name_jp,
        description: description_jp,
        display_name: display_name_jp,
        street_name: street_name_jp,
      });
    name_vi &&
      waypoint_languages.push({
        language_id: LANGUAGE.find((item) => item.code === 'vi')?.id,
        name: name_vi,
        description: description_vi,
        display_name: display_name_vi,
        street_name: street_name_vi,
      });
    name_en &&
      waypoint_languages.push({
        language_id: LANGUAGE.find((item) => item.code === 'en')?.id,
        name: name_en,
        description: description_en,
        display_name: display_name_en,
        street_name: street_name_en,
      });
    this.setState({showUpdateModal: false});
    const updatedWaypoint = await updateWaypointApi({
      id,
      waypoint_languages,
      latitude,
      longitude,
      disabled,
      image_master_id,
      name: name_jp,
      display_name: display_name_jp,
      street_name: street_name_jp,
      product_images_id,
      transit_stop_swat_id,
      discount: discount,
      discount_type,
      currency,
      website,
      phone_number,
      business_time,
      is_campaign_displayed: isCheckCampaign,
      campaign_display_type: discountCommentType,
      comment: campaignInforComment,
      waypoint_restriction: restrict_setting === 'SELF_RESTRICTED' ? restrict : null,
      restriction_type: restrict_setting,
      affected_booking_ids: affectedReservations?.length > 0 ? affectedReservations.map((reservation) => reservation.booking_id) : [],
    });
    if (updatedWaypoint?.status === 200) {
      // check display dialog success booking cancel
      if (this.state.bookingCancelIds?.length > 0) {
        this.setState({isSuccessBookingCancel: true});
      } else {
        this.props.setMessageModal(modalObj(true, 'waypointMessage.updated'));
      }
      this.setState({isSubmitForm: false});
      getWaypointDetailApi(this.props.match.params.id);
    } else this.notifyResponse('Api.fail');
  };

  /**
   * closeConfirmUpdateModal
   */
  closeConfirmUpdateModal = () => {
    this.setState({isSubmitForm: false});
    this.setState({showUpdateModal: false});
  };

  /**
   * handleChooseImage
   */
  handleChooseImage = () => {
    this.setState({
      openModal: true,
    });
  };

  /**
   * onChangeDiscountCommentType
   * @param {*} event
   */
  onChangeDiscountCommentType = (event) => {
    this.setState({
      discountCommentType: event.target.value,
      campaignInforComment: '',
    });
  };

  /**
   * handleChangeDiscount
   * @param {*} event
   */
  handleChangeDiscount = (event) => {
    if (this.state.discount_type === 'AMOUNT') {
      const country_id = this.props.geofences.find((geofence) => geofence.geofence_id === this.props.location.state.geofence_id)?.country_id;
      const currencyCode = CURRENCY.find((c) => c.countryId === country_id)?.id;
      const isValidInput = onChangeNumberCurrency(this, event, currencyCode, false);
      if (!isValidInput) return;
    }
    this.setState({
      discount: event.target.value,
    });
    if (!event.target.value && event.target.value !== 0) {
      this.setState({
        discountCommentType: null,
        campaignInforComment: '',
        isCheckCampaign: false,
      });
    }
  };

  /**
   * handleCheckCampaign
   * @param {*} event
   */
  handleCheckCampaign = (event) => {
    this.setState({
      isCheckCampaign: event.target.checked,
    });
    if (event.target.checked) {
      this.setState({
        discountCommentType: CAMPAIGN_INFOR_DISPLAY[0].id,
        campaignInforComment: '',
      });
    } else {
      this.setState({
        discountCommentType: null,
        campaignInforComment: '',
      });
    }
  };

  /**
   * changePlanType
   * @param {event} event
   * @param {String} id
   */
  handleChangeRestrictType = (event, id) => {
    let restrictTypeIds = this.state.restrict_type;
    if (event.target.checked) {
      restrictTypeIds.push(id);
    } else {
      restrictTypeIds = restrictTypeIds.filter((item) => item !== id);
    }
    this.setState({
      restrict_type: restrictTypeIds,
    });
  };

  /**
   * onChangeExceptionalDays
   * @param {*} days
   */
  onChangeExceptionalDays = (days) => {
    this.setState({exceptional_days: [...days]});
  };

  /**
   * onChangeRestrictDays
   * @param {*} days
   */
  onChangeRestrictDays = (days) => {
    this.setState({restrict_days: [...days]});
  };

  /**
   * checkDuplicatedDay
   * @return {boolean}
   */
  checkDuplicatedDay = () => {
    if (this.state.restrict_type?.includes('SPECIFIC_DAYS')) {
      const new_restrict_days = this.state.restrict_days?.map((item) => {
        return item.replace(/\//g, '-');
      });
      const new_exceptional_days = this.state.exceptional_days?.map((item) => {
        return item.replace(/\//g, '-');
      });
      const arrCheck = new_exceptional_days?.map((item) => new_restrict_days?.includes(item));
      return arrCheck?.every((item) => item === false);
    } else return true;
  };

  /**
   * onChangeDiscountType
   * @param {*} event
   */
  onChangeDiscountType = (event) => {
    this.setState({
      discount_type: event.target.value,
      discountCommentType: null,
      campaignInforComment: '',
      isCheckCampaign: false,
      discount: '',
    });
  };

  /**
   * openImageModal
   */
  openImageModal = () => {
    this.setState({
      openProductImagesModal: true,
    });
  };

  /**
   * openImageRestrictModal
   */
  openImageRestrictModal = () => {
    this.setState({
      openModalRestrictPhoto: true,
    });
  };

  /**
   * closeImageModal
   */
  closeImageModal = () => {
    this.setState({
      openModal: false,
      openProductImagesModal: false,
      openModalRestrictPhoto: false,
    });
  };

  /**
   * check number 0 -> 9 return '0{number}'
   * @param {*} time
   * @return {*}
   */
  convertTimeNumber = (time) => {
    if (time <= 9) {
      return '0' + time;
    }
    return time;
  };

  /**
   * Validate operating time ranges and return true if time ranges are all valid
   * @return {boolean}
   */
  validateOperatingTimeRanges = async () => {
    const {list_restrict_time} = this.state;
    const overlapList = new Set();
    for (let i = 0; i < list_restrict_time?.length - 1; i++) {
      const start1 = list_restrict_time[i].from ?
        this.convertTimeNumber(list_restrict_time[i]?.from?.getHours()) + ':' + this.convertTimeNumber(list_restrict_time[i]?.from?.getMinutes()) :
        '';
      const end1 = list_restrict_time[i].to ?
        this.convertTimeNumber(list_restrict_time[i]?.to.getHours()) + ':' + this.convertTimeNumber(list_restrict_time[i]?.to.getMinutes()) :
        '';
      for (let j = i + 1; j < list_restrict_time?.length; j++) {
        const start2 = list_restrict_time[j].from ?
          this.convertTimeNumber(list_restrict_time[j]?.from?.getHours()) + ':' + this.convertTimeNumber(list_restrict_time[j]?.from?.getMinutes()) :
          '';
        const end2 = list_restrict_time[j].to ?
          this.convertTimeNumber(list_restrict_time[j]?.to?.getHours()) + ':' + this.convertTimeNumber(list_restrict_time[j]?.to?.getMinutes()) :
          '';
        if (isOverlapDateRelatively(start1.toString(), end1.toString(), start2.toString(), end2.toString())) {
          overlapList.add(i);
          overlapList.add(j);
        }
      }
    }
    this.setState({overlapList});
    return overlapList.size === 0;
  };

  /**
   * checkTimeRange
   * @return {Boolean}
   */
  checkTimeRange = () => {
    return this.state.list_restrict_time?.every((item) => this.checkHoursUnit(item?.from, item?.to));
  };

  /**
   * Return true if time range is valid
   * @param {*} startTime
   * @param {*} endTime
   * @return {bool}
   */
  checkHoursUnit = (startTime, endTime) => {
    return (
      startTime &&
      endTime &&
      (endTime > startTime || startTime.getHours() < endTime.getHours() || (startTime.getHours() === endTime.getHours() && startTime.getMinutes() < endTime.getMinutes()))
    );
  };

  /**
   * addItem
   */
  addItem = async () => {
    this.setState({
      list_restrict_time: [...this.state.list_restrict_time, {}],
    });
  };

  /**
   * deleteRows
   * @param {Number} index
   */
  deleteRows = (index) => {
    const list_restrict_time_copy = this.state.list_restrict_time;
    list_restrict_time_copy.splice(index, 1);
    this.setState({list_restrict_time: list_restrict_time_copy});
  };

  /**
   * changeOperatingHour
   * @param {*} index
   * @param {*} value
   * @param {*} isStart
   */
  changeRestrictTime = async (index, value, isStart) => {
    const list_restrict_time_copy = this.state.list_restrict_time;
    isStart ? (list_restrict_time_copy[index].from = value || '') : (list_restrict_time_copy[index].to = value || '');
    this.setState({
      list_restrict_time: list_restrict_time_copy,
    });
    await this.validateOperatingTimeRanges();
  };

  /**
   * formatDataHourToDate
   * @param {*} listRestrictTime
   * @return {*}
   */
  formatDataHourToDate = (listRestrictTime) => {
    const arrNew = [];
    for (let i = 0; i < listRestrictTime?.length; i++) {
      const data = {
        from: this.formatHourMinuteToDate(listRestrictTime[i].from),
        to: this.formatHourMinuteToDate(listRestrictTime[i].to),
      };
      arrNew.push(data);
    }
    return arrNew;
  };

  /**
   * formatHourMinuteToLocal
   * @param {*} time
   * @return {*}
   */
  formatHourMinuteToDate = (time) => {
    const date = new Date();
    date.setHours(Number(time.substr(0, 2)));
    date.setMinutes(Number(time.substr(3, 2)));
    return parseISO(date.toISOString());
  };

  /**
   * formatDataDateToHour
   * @param {*} listRestrictTime
   * @return {*}
   */
  formatDataDateToHour = (listRestrictTime) => {
    const arrNew = [];
    for (let i = 0; i < listRestrictTime.length; i++) {
      if (listRestrictTime[i]?.from && listRestrictTime[i]?.to) {
        const data = {
          from: ('0' + listRestrictTime[i]?.from?.getHours())?.slice(-2) + ':' + ('0' + listRestrictTime[i]?.from?.getMinutes())?.slice(-2),
          to: ('0' + listRestrictTime[i]?.to?.getHours())?.slice(-2) + ':' + ('0' + listRestrictTime[i]?.to?.getMinutes())?.slice(-2),
        };
        arrNew.push(data);
      }
    }
    return arrNew;
  };

  /**
   * notifyResponse
   * @param {string} message
   */
  notifyResponse = (message) => {
    this.props.setMessageModal(modalObj(true, message));
  };

  /**
   * validateTimeWithPartner
   * @param {*} time
   * @return {boolean}
   */
  validateTimeWithPartner = (time) => {
    return (
      convertDateTimeToDate(new Date(time)) >= convertDateTimeToDate(new Date(this.state.partner_start_time)) &&
      convertDateTimeToDate(new Date(time)) <= convertDateTimeToDate(new Date(this.state.partner_end_time))
    );
  };

  /**
   * handleCheckDiscountNormalDisplay
   * @return {boolean}
   */
  handleCheckDiscountNormalDisplay = () => {
    if (
      (this.state.restrict_setting === 'SELF_RESTRICTED' && this.state.waypoint_display_type === 'WAYPOINT_DISPLAY') ||
      (this.state.restrict_setting === 'PARTNER_RESTRICTED' && this.state.clone_waypoint_display_type === 'WAYPOINT_DISPLAY')
    ) {
      return this.validator.check(this.state.discount, 'required') && Number(this.state.discount) === 0;
    } else return true;
  };

  /**
   * Render component
   * @return {*}
   */
  render() {
    const {t, actions} = this.props;
    const isEditForm = this.props.match.params.id;
    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
    };
    this.validator.purgeFields();
    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.waypointDetails')}</h3>
            </Grid>
          </Grid>
        </Container>
        <br></br>
        <Container maxWidth="xl">
          <LoadingOverlay active={false} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('route.waypointInformation')}
                    </Grid>
                  </Grid>

                  {/* Name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.name')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <TextField
                          label={<span>({t('common.ja')})</span>}
                          fullWidth
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('common.name')})}
                          variant="outlined"
                          onChange={(event) => this.setState({name_jp: event.target.value})}
                          value={this.state.name_jp || ''}
                        />
                        {this.validator.message('name_jp', this.state.name_jp.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.name_jp.trim(), 'required') && (
                          <FormHelperText id="name_jp" error>
                            {t('validation.required', {field: `${t('common.name')} (${t('common.ja')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon" style={{top: -2}}></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <TextField
                          label={<span>({t('common.vi')})</span>}
                          fullWidth
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('common.name')})}
                          variant="outlined"
                          onChange={(event) => this.setState({name_vi: event.target.value})}
                          value={this.state.name_vi || ''}
                        />
                        {this.validator.message('name_vi', this.state.name_vi.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.name_vi.trim(), 'required') && (
                          <FormHelperText id="name_vi" error>
                            {t('validation.required', {field: `${t('common.name')} (${t('common.ja')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon" style={{top: -2}}></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <TextField
                          label={<span>({t('common.en')})</span>}
                          fullWidth
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('common.name')})}
                          variant="outlined"
                          onChange={(event) => this.setState({name_en: event.target.value})}
                          value={this.state.name_en || ''}
                        />
                        {this.validator.message('name_en', this.state.name_en.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.name_en.trim(), 'required') && (
                          <FormHelperText id="name_en" error>
                            {t('validation.required', {field: `${t('common.name')} (${t('common.ja')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* display_name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('waypoint.display_name')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.ja')})</span>}
                            fullWidth
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('waypoint.display_name')})}
                            variant="outlined"
                            inputProps={{maxLength: 50}}
                            onChange={(event) => this.setState({display_name_jp: event.target.value})}
                            value={this.state.display_name_jp || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 50})}</div>
                        </Box>
                        {this.validator.message('display_name_jp', this.state.display_name_jp.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.display_name_jp.trim(), 'required') && (
                          <FormHelperText id="display_name_jp" error>
                            {t('validation.required', {field: `${t('waypoint.display_name')} (${t('common.ja')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.vi')})</span>}
                            fullWidth
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('waypoint.display_name')})}
                            variant="outlined"
                            inputProps={{maxLength: 50}}
                            onChange={(event) => this.setState({display_name_vi: event.target.value})}
                            value={this.state.display_name_vi || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 50})}</div>
                        </Box>
                        {this.validator.message('display_name_vi', this.state.display_name_vi.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.display_name_vi.trim(), 'required') && (
                          <FormHelperText id="display_name_vi" error>
                            {t('validation.required', {field: `${t('waypoint.display_name')} (${t('common.vi')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.en')})</span>}
                            fullWidth
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('waypoint.display_name')})}
                            variant="outlined"
                            inputProps={{maxLength: 50}}
                            onChange={(event) => this.setState({display_name_en: event.target.value})}
                            value={this.state.display_name_en || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 50})}</div>
                        </Box>
                        {this.validator.message('display_name_en', this.state.display_name_en.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.display_name_en.trim(), 'required') && (
                          <FormHelperText id="display_name_en" error>
                            {t('validation.required', {field: `${t('waypoint.display_name')} (${t('common.en')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* street_name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('waypoint.street_name')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.ja')})</span>}
                            fullWidth
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('waypoint.street_name')})}
                            rows={3}
                            multiline
                            variant="outlined"
                            inputProps={{maxLength: 255}}
                            onChange={(event) => this.setState({street_name_jp: event.target.value})}
                            value={this.state.street_name_jp || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 255})}</div>
                        </Box>
                        {this.validator.message('street_name_jp', this.state.street_name_jp.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.street_name_jp.trim(), 'required') && (
                          <FormHelperText id="street_name_jp" error>
                            {t('validation.required', {field: `${t('waypoint.street_name')} (${t('common.ja')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.vi')})</span>}
                            fullWidth
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('waypoint.street_name')})}
                            rows={3}
                            multiline
                            variant="outlined"
                            inputProps={{maxLength: 255}}
                            onChange={(event) => this.setState({street_name_vi: event.target.value})}
                            value={this.state.street_name_vi || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 255})}</div>
                        </Box>
                        {this.validator.message('street_name_vi', this.state.street_name_vi.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.street_name_vi.trim(), 'required') && (
                          <FormHelperText id="street_name_vi" error>
                            {t('validation.required', {field: `${t('waypoint.street_name')} (${t('common.vi')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.en')})</span>}
                            fullWidth
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('waypoint.street_name')})}
                            rows={3}
                            multiline
                            variant="outlined"
                            inputProps={{maxLength: 255}}
                            onChange={(event) => this.setState({street_name_en: event.target.value})}
                            value={this.state.street_name_en || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 255})}</div>
                        </Box>
                        {this.validator.message('street_name_en', this.state.street_name_en.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.street_name_en.trim(), 'required') && (
                          <FormHelperText id="street_name_en" error>
                            {t('validation.required', {field: `${t('waypoint.street_name')} (${t('common.en')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* latitude */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('waypoint.latitude')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl fullWidth>
                        <TextField
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          type="number"
                          inputProps={{
                            name: 'latitude',
                            min: 0,
                          }}
                          disabled={isEditForm ? true : false}
                          placeholder={t('validation.required', {field: t('waypoint.latitude')})}
                          variant="outlined"
                          onChange={(event) => onChangeTextField(this, event)}
                          value={this.state.latitude}
                        />
                        {this.validator.message('latitude', this.state.latitude, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.latitude, 'required') && (
                          <FormHelperText id="latitude" error>
                            {t('validation.required', {field: t('waypoint.latitude')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* longitude */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('waypoint.longitude')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl fullWidth>
                        <TextField
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          type="number"
                          disabled={isEditForm ? true : false}
                          inputProps={{
                            name: 'longitude',
                            min: 0,
                          }}
                          placeholder={t('validation.required', {field: t('waypoint.longitude')})}
                          value={this.state.longitude}
                          onChange={(event) => onChangeTextField(this, event)}
                          variant="outlined"
                        />
                        {this.validator.message('longitude', this.state.longitude, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.longitude, 'required') && (
                          <FormHelperText id="longitude" error>
                            {t('validation.required', {field: t('waypoint.longitude')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* description */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('waypoint.description')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.ja')})</span>}
                            fullWidth
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('waypoint.description')})}
                            variant="outlined"
                            multiline
                            rows={3}
                            inputProps={{maxLength: 100}}
                            onChange={(event) => this.setState({description_jp: event.target.value})}
                            value={this.state.description_jp || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                        </Box>
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.vi')})</span>}
                            fullWidth
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('waypoint.description')})}
                            variant="outlined"
                            multiline
                            rows={3}
                            inputProps={{maxLength: 100}}
                            onChange={(event) => this.setState({description_vi: event.target.value})}
                            value={this.state.description_vi || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                        </Box>
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.en')})</span>}
                            fullWidth
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('waypoint.description')})}
                            variant="outlined"
                            multiline
                            rows={3}
                            inputProps={{maxLength: 100}}
                            onChange={(event) => this.setState({description_en: event.target.value})}
                            value={this.state.description_en || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                        </Box>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* image */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <Button variant="contained" disabled={!permission.canUpdate} color="primary" component="span" onClick={() => this.handleChooseImage()}>
                        {t('common.photo')}
                      </Button>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <label htmlFor="icon-button-file">
                        <IconButton color="primary" aria-label="upload picture" component="span">
                          {this.state.logo_url ? (
                            <div className="box_image" style={{width: '100%', height: '200px'}}>
                              <img className="cropped_image" style={{width: 'inherit', height: 'inherit'}} src={this.state.logo_url} alt="cropped" />
                            </div>
                          ) : (
                            <PhotoCamera />
                          )}
                        </IconButton>
                      </label>
                    </Grid>
                  </Grid>

                  {/* product images */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <Button variant="contained" disabled={!permission.canUpdate} color="primary" component="span" onClick={this.openImageModal}>
                        {t('waypoint.product_photos')}
                      </Button>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      {this.state.product_images_id?.length > 0 ? (
                        <Carousel autoPlay={false} animation="slide" indicators={true}>
                          {this.state.product_images_id?.map((item, index) => (
                            <div className="box_image" key={index} style={{width: '100%', height: '200px'}}>
                              <img className="cropped_image" style={{width: 'inherit', height: 'inherit'}} src={item.url} alt="cropped" />
                            </div>
                          ))}
                        </Carousel>
                      ) : (
                        <label htmlFor="icon-button-file">
                          <IconButton color="primary" aria-label="upload picture" component="span">
                            <PhotoCamera />
                          </IconButton>
                        </label>
                      )}
                    </Grid>
                  </Grid>

                  {/* Website */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.website')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl fullWidth>
                        <div className="box-group-input">
                          <TextField
                            name="website"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('validation.required', {field: t('businessPartner.website')})}
                            value={this.state.website || ''}
                            onChange={(event) => onChangeTextField(this, event)}
                            inputProps={{maxLength: 255}}
                            variant="outlined"
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 255})}</div>
                        </div>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* phone_number */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.phoneNumber')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl fullWidth>
                        <div className="box-group-input">
                          <TextField
                            name="phone_number"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('validation.required', {field: t('common.phoneNumber')})}
                            value={this.state.phone_number || ''}
                            onChange={(event) => onChangeTextField(this, event)}
                            inputProps={{maxLength: 15}}
                            variant="outlined"
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 15})}</div>
                        </div>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* transit_stop_swat_id */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('waypoint.transit_stop_swat_id')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl fullWidth>
                        <div className="box-group-input">
                          <TextField
                            name="transit_stop_swat_id"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('validation.required', {field: t('waypoint.transit_stop_swat_id')})}
                            value={this.state.transit_stop_swat_id}
                            disabled={isEditForm ? true : false}
                            onChange={(event) => onChangeTextField(this, event)}
                            inputProps={{maxLength: 255}}
                            variant="outlined"
                          />
                          {!isEditForm && <div className="max-length-label">{t('validation.max.label', {value: 255})}</div>}
                        </div>
                        {this.validator.message('transit_stop_swat_id', this.state.transit_stop_swat_id, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.transit_stop_swat_id, 'required') && (
                          <FormHelperText id="transit_stop_swat_id" error>
                            {t('validation.required', {field: t('waypoint.transit_stop_swat_id')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* discount_type */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.discount_type')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'discount_type',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.discount_type ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('businessPartner.discount_type'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.discount_type}
                          onChange={(e) => this.onChangeDiscountType(e)}
                        >
                          {DISCOUNT_TYPE.map(({id, i18n}, idx) => (
                            <MenuItem value={id} key={idx}>
                              {t(`${i18n}`)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* discount */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.discount')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      {this.state.discount_type === 'AMOUNT' && (
                        <FormControl>
                          <TextField
                            name="discount"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('validation.required', {field: t('businessPartner.discount')})}
                            value={this.state.discount}
                            onChange={(event) => this.handleChangeDiscount(event)}
                            inputProps={{min: 0}}
                            variant="outlined"
                          />

                          {this.validator.message('discount', this.state.discount, 'min:0,num')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.discount, 'min:0,num') && (
                            <FormHelperText id="discount" error>
                              {t('validation.invalid.field', {field: t('businessPartner.discount')})}
                            </FormHelperText>
                          )}
                          {this.state.isSubmitForm && !this.handleCheckDiscountNormalDisplay() && (
                            <FormHelperText id="discount" error>
                              {t('waypoint.restrict.normal_display_error')}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}

                      {this.state.discount_type === 'PERCENT' && (
                        <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'discount',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.discount || this.state.discount === 0 ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('businessPartner.discount'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.discount}
                            onChange={(e) => this.handleChangeDiscount(e)}
                          >
                            {LIST_PERCENT.map((item, idx) => {
                              return (
                                <MenuItem value={item} key={idx}>
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {this.state.isSubmitForm && !this.handleCheckDiscountNormalDisplay() && (
                            <FormHelperText id="discount" error>
                              {t('waypoint.restrict.normal_display_error')}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    </Grid>
                  </Grid>

                  {/* Restrict Setting */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('waypoint.restrict.setting')}
                    </Grid>
                    <Grid>
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'restrict_setting',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.restrict_setting ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('waypoint.restrict.setting'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.restrict_setting}
                          onChange={(event) => {
                            this.setState({restrict_setting: event.target.value});
                          }}
                        >
                          {RESTRICT_SETTING.map(({value, i18n}, idx) => (
                            <MenuItem value={value} key={idx}>
                              {t(`${i18n}`)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Waypoint type display */}
                  {this.state.restrict_setting === 'SELF_RESTRICTED' && (
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('waypoint.type.display')}
                        <span className="font_color_red">＊</span>
                      </Grid>
                      <Grid alignItems="center" item xs={6} lg={4}>
                        <RadioGroup className="item_display_box" value={this.state.waypoint_display_type}>
                          {RESTRICT_WAYPOINT_DISPLAY_TYPE?.map((item, index) => (
                            <FormControlLabel
                              key={index}
                              value={item.value}
                              control={<Radio className="checkbox_radio" />}
                              label={t(item.i18n)}
                              onChange={(event) => {
                                this.setState({waypoint_display_type: event.target.value});
                              }}
                            />
                          ))}
                        </RadioGroup>
                        {this.validator.message('waypoint_display_type', this.state.waypoint_display_type, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.waypoint_display_type, 'required') && (
                          <FormHelperText id="waypoint_display_type" error>
                            {t('placeholder.required_select', {field: t('waypoint.type.display')})}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  )}

                  {this.state.restrict_setting === 'SELF_RESTRICTED' && (
                    <>
                      {/* Restrict Description */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('waypoint.restrict.description')}
                          <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={2}>
                          <FormControl fullWidth margin="dense">
                            <Box display="flex" flexDirection="column">
                              <TextField
                                label={<span>({t('common.ja')})</span>}
                                name="restrict_description_ja"
                                fullWidth
                                inputProps={{maxLength: 100}}
                                margin="dense"
                                value={this.state.restrict_description_ja || ''}
                                multiline
                                rows={2}
                                onChange={(event) => onChangeTextField(this, event)}
                                placeholder={t('placeholder.required', {field: t('waypoint.restrict.description')})}
                                variant="outlined"
                              />
                              <div style={{fontSize: 14}}>{t('validation.max.label', {value: 100})}</div>
                            </Box>
                            {this.validator.message('restrict_description_ja', this.state.restrict_description_ja.trim(), 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.restrict_description_ja.trim(), 'required') && (
                              <FormHelperText id="restrict_description_ja" error>
                                {t('validation.required', {field: t('waypoint.restrict.description')})} ({t('common.ja')})
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid container justify="center" alignItems="center" item xs={1}>
                          <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={2}>
                          <FormControl fullWidth margin="dense">
                            <Box display="flex" flexDirection="column">
                              <TextField
                                label={<span>({t('common.vi')})</span>}
                                name="restrict_description_vi"
                                fullWidth
                                inputProps={{maxLength: 100}}
                                margin="dense"
                                value={this.state.restrict_description_vi || ''}
                                multiline
                                rows={2}
                                onChange={(event) => onChangeTextField(this, event)}
                                placeholder={t('placeholder.required', {field: t('waypoint.restrict.description')})}
                                variant="outlined"
                              />
                              <div style={{fontSize: 14}}>{t('validation.max.label', {value: 100})}</div>
                            </Box>
                            {this.validator.message('restrict_description_vi', this.state.restrict_description_vi.trim(), 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.restrict_description_vi.trim(), 'required') && (
                              <FormHelperText id="restrict_description_vi" error>
                                {t('validation.required', {field: t('waypoint.restrict.description')})} ({t('common.vi')})
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid container justify="center" alignItems="center" item xs={1}>
                          <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={2}>
                          <FormControl fullWidth margin="dense">
                            <Box display="flex" flexDirection="column">
                              <TextField
                                label={<span>({t('common.en')})</span>}
                                name="restrict_description_en"
                                fullWidth
                                inputProps={{maxLength: 100}}
                                margin="dense"
                                value={this.state.restrict_description_en || ''}
                                multiline
                                rows={2}
                                onChange={(event) => onChangeTextField(this, event)}
                                placeholder={t('placeholder.required', {field: t('waypoint.restrict.description')})}
                                variant="outlined"
                              />
                              <div style={{fontSize: 14}}>{t('validation.max.label', {value: 100})}</div>
                            </Box>
                            {this.validator.message('restrict_description_en', this.state.restrict_description_en.trim(), 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.restrict_description_en.trim(), 'required') && (
                              <FormHelperText id="restrict_description_en" error>
                                {t('validation.required', {field: t('waypoint.restrict.description')})} ({t('common.en')})
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Restrict Photo */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          <Button variant="contained" disabled={!permission.canUpdate} color="primary" component="span" onClick={() => this.openImageRestrictModal()}>
                            {t('waypoint.restrict.photo')}
                          </Button>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4} className="grid_title_padding">
                          <label htmlFor="icon-button-file">
                            <IconButton color="primary" aria-label="upload picture" component="span">
                              {this.state.restrict_photo_url ? (
                                <div className="box_image" style={{width: '100%', height: '50px'}}>
                                  <img className="cropped_image" style={{width: 'inherit', height: 'inherit'}} src={this.state.restrict_photo_url} alt="cropped" />
                                </div>
                              ) : (
                                <PhotoCamera />
                              )}
                            </IconButton>
                          </label>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {this.state.restrict_setting === 'SELF_RESTRICTED' && (
                    <>
                      {/* Restrict Type */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('waypoint.restrict.type')}
                          <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid alignItems="center" item xs={6} lg={4}>
                          <FormControl className="item_display_box">
                            {RESTRICT_TYPE?.map((item) => (
                              <FormControlLabel
                                label={t(item.i18n)}
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={this.state.restrict_type?.includes(item.id)}
                                    onClick={(event) => this.handleChangeRestrictType(event, item.id)}
                                    label={t(item.i18n)}
                                  />
                                }
                              />
                            ))}
                          </FormControl>
                          <div>
                            {this.validator.message('restrict_type', this.state.restrict_type, 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.restrict_type, 'required') && (
                              <FormHelperText id="repeat_start_date" error>
                                {t('placeholder.required_select', {field: t('waypoint.restrict.type')})}
                              </FormHelperText>
                            )}
                          </div>
                        </Grid>
                      </Grid>

                      {/* Restrict Days */}
                      {this.state.restrict_type?.includes('SPECIFIC_DAYS') && (
                        <Grid container spacing={1} className="row_form_item">
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            {t('waypoint.restrict.day')}
                            <span className="font_color_red">＊</span>
                          </Grid>
                          <Grid alignItems="center" item xs={6} lg={8}>
                            <MultiDate
                              onChange={(event) => this.onChangeRestrictDays(event)}
                              dates={this.state.restrict_days}
                              placeholder={t('placeholder.required_select', {field: t('waypoint.restrict.day')})}
                            />
                            <br />
                            {this.validator.message('restrict_days', this.state.restrict_days, 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.restrict_days, 'required') && (
                              <FormHelperText id="repeat_start_date" error>
                                {t('placeholder.required_select', {field: t('waypoint.restrict.day')})}
                              </FormHelperText>
                            )}
                          </Grid>
                        </Grid>
                      )}

                      {/* Repeat Setting */}
                      {this.state.restrict_type?.includes('REPEAT') && (
                        <Grid container spacing={1} className="row_form_item">
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            {t('businessPartner.repeat_setting')}
                            <span className="font_color_red">＊</span>
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={4}>
                            <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                              <Select
                                margin="dense"
                                inputProps={{
                                  name: 'repeat_setting',
                                }}
                                displayEmpty
                                renderValue={this.state.repeat_setting ? undefined : () => <div className="font-12 color-disabled">{''}</div>}
                                value={this.state.repeat_setting}
                                onChange={(event) => this.setState({repeat_setting: event.target.value})}
                              >
                                {REPEAT_SETTING.map(({id, i18n}, idx) => (
                                  <MenuItem value={id} key={idx}>
                                    {t(i18n)}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}

                      {/* Repeat Start Date */}
                      {this.state.restrict_type?.includes('REPEAT') && (
                        <Grid container spacing={1} className="row_form_item">
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            {t('businessPartner.repeat_start_date')}
                            <span className="font_color_red">＊</span>
                          </Grid>
                          <Grid alignItems="center" item xs={6} lg={4}>
                            <FormControl error className="field_size_10 field_min_size_300">
                              <KeyboardDatePicker
                                KeyboardButtonProps={{
                                  'aria-label': 'change time',
                                }}
                                disableToolbar
                                variant="inline"
                                inputVariant="outlined"
                                autoOk
                                value={this.state.repeat_start_date}
                                onChange={(time) => this.setState({repeat_start_date: time})}
                                format={DATE_FORMAT}
                                invalidDateMessage={t('errorFields.invalidDateMessage')}
                                maxDateMessage={t('errorFields.maxDateMessage')}
                                minDateMessage={t('errorFields.minDateMessage')}
                                placeholder={t('placeholder.required_select', {field: t('businessPartner.repeat_start_date')})}
                              />
                              {this.validator.message('repeat_start_date', this.state.repeat_start_date, 'required')}
                              {this.state.isSubmitForm && !this.validator.check(this.state.repeat_start_date, 'required') && (
                                <FormHelperText id="repeat_start_date" error>
                                  {t('businessPartner.required_repeat_start_date')}
                                </FormHelperText>
                              )}
                              {this.state.isSubmitForm &&
                                this.validator.check(this.state.repeat_start_date, 'required') &&
                                this.state.partner_start_time &&
                                this.state.partner_end_time &&
                                !this.validateTimeWithPartner(this.state.repeat_start_date) && (
                                  <FormHelperText id="repeat_start_date" error>
                                    {t('businessPartner.error_repeat_start_date')}
                                  </FormHelperText>
                                )}
                              <div>
                                {this.state.isSubmitForm && !compareDateTimeRange(this.state.repeat_start_date, this.state.repeat_end_date, true) && (
                                  <FormHelperText id="start_time" error>
                                    {t('validation.invalid.timeRange')}
                                  </FormHelperText>
                                )}
                              </div>
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}

                      {/* Repeat Ends Date */}
                      {this.state.restrict_type?.includes('REPEAT') && (
                        <Grid container spacing={1} className="row_form_item">
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            {t('businessPartner.repeat_end_date')}
                            <span className="font_color_red">＊</span>
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={4}>
                            <FormControl error className="field_size_10 field_min_size_300">
                              <KeyboardDatePicker
                                KeyboardButtonProps={{
                                  'aria-label': 'change time',
                                }}
                                disableToolbar
                                variant="inline"
                                inputVariant="outlined"
                                autoOk
                                value={this.state.repeat_end_date}
                                onChange={(time) => this.setState({repeat_end_date: time})}
                                format={DATE_FORMAT}
                                invalidDateMessage={t('errorFields.invalidDateMessage')}
                                maxDateMessage={t('errorFields.maxDateMessage')}
                                minDateMessage={t('errorFields.minDateMessage')}
                                placeholder={t('placeholder.required_select', {field: t('businessPartner.repeat_end_date')})}
                              />
                              {this.validator.message('repeat_end_date', this.state.repeat_end_date, 'required')}
                              {this.state.isSubmitForm && !this.validator.check(this.state.repeat_end_date, 'required') && (
                                <FormHelperText id="repeat_end_date" error>
                                  {t('businessPartner.required_repeat_end_date')}
                                </FormHelperText>
                              )}
                              {this.state.isSubmitForm &&
                                this.validator.check(this.state.repeat_end_date, 'required') &&
                                this.state.partner_start_time &&
                                this.state.partner_end_time &&
                                !this.validateTimeWithPartner(this.state.repeat_end_date) && (
                                  <FormHelperText id="repeat_end_date" error>
                                    {t('businessPartner.error_repeat_end_date')}
                                  </FormHelperText>
                                )}
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}

                      {/* Repeat Weekly On */}
                      {this.state.restrict_type?.includes('REPEAT') && this.state.repeat_setting === REPEAT_SETTING[1].id && (
                        <Grid container spacing={1} className="row_form_item">
                          <Grid container item xs={6} lg={2} className="grid_title_padding" alignItems="center">
                            {t('businessPartner.repeat_weekly_on')}
                            <span className="font_color_red">＊</span>
                          </Grid>
                          <Grid alignItems="center" item xs={6} lg={10} spacing={1}>
                            <Grid container alignItems="center" item lg={10}>
                              <div className="box-group-input">
                                <ToggleButtonGroup
                                  size="small"
                                  value={this.state.repeat_on_date_of_week}
                                  onChange={(event, value) => this.setState({repeat_on_date_of_week: value})}
                                >
                                  {DAYS.map((day, index) => (
                                    <StyledToggle
                                      key={index}
                                      value={day.key}
                                      aria-label={day.key}
                                      style={{width: '35px', height: '35px', borderRadius: '50%', marginRight: '10px', borderColor: '#3f51b5'}}
                                    >
                                      {day.label}
                                    </StyledToggle>
                                  ))}
                                </ToggleButtonGroup>
                              </div>
                            </Grid>
                            {this.validator.message('repeat_on_date_of_week', this.state.repeat_on_date_of_week, 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.repeat_on_date_of_week, 'required') && (
                              <FormHelperText id="repeat_on_date_of_week" error>
                                {t('placeholder.required_select', {field: t('businessPartner.repeat_weekly_on')})}
                              </FormHelperText>
                            )}
                          </Grid>
                        </Grid>
                      )}

                      {/* Repeat Monthly On */}
                      {this.state.restrict_type?.includes('REPEAT') && this.state.repeat_setting === REPEAT_SETTING[2].id && (
                        <Grid container spacing={1} className="row_form_item">
                          <Grid container className="grid_title_padding" alignItems="center" item xs={6} lg={2}>
                            {t('businessPartner.repeat_monthly_on')}
                            <span className="font_color_red">＊</span>
                          </Grid>
                          <Grid alignItems="center" item xs={6} lg={2}>
                            <Grid container>
                              <div style={{border: '1px solid #000000de', borderRadius: '4px'}}>
                                <div>
                                  <div style={{textAlign: 'center', marginTop: '10px'}}>{t('waypoint.restrict.date_of_monthly')}</div>
                                </div>
                                <hr />
                                <ToggleButtonGroup
                                  size="small"
                                  value={this.state.repeat_on_date_of_month}
                                  onChange={(event, value) => {
                                    this.setState({repeat_on_date_of_month: value});
                                  }}
                                  style={{display: 'block', marginLeft: '7px'}}
                                >
                                  {DAY_OF_MONTH.map((day, index) => (
                                    <StyledToggle key={day} value={day} style={{width: '35px', height: '35px', borderRadius: '50%', border: 'none', margin: '2px'}}>
                                      {day}
                                    </StyledToggle>
                                  ))}
                                </ToggleButtonGroup>
                              </div>
                            </Grid>
                            {this.validator.message('repeat_on_date_of_month', this.state.repeat_on_date_of_month, 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.repeat_on_date_of_month, 'required') && (
                              <FormHelperText id="repeat_on_date_of_month" error>
                                {t('placeholder.required_select', {field: t('businessPartner.repeat_monthly_on')})}
                              </FormHelperText>
                            )}
                          </Grid>
                        </Grid>
                      )}

                      {/* Exceptional Days */}
                      {this.state.restrict_type?.includes('REPEAT') && (
                        <Grid container spacing={1} className="row_form_item">
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            {t('businessPartner.exceptional_days')}
                          </Grid>
                          <Grid alignItems="center" item xs={6} lg={8}>
                            <MultiDate
                              onChange={(event) => this.onChangeExceptionalDays(event)}
                              dates={this.state.exceptional_days}
                              repeatStartDate={this.state.repeat_start_date}
                              repeatEndDate={this.state.repeat_end_date}
                              isAll={false}
                              placeholder={t('placeholder.required_select', {field: t('businessPartner.exceptional_days')})}
                            />
                            <div>
                              {this.state.isSubmitForm && !this.checkDuplicatedDay() && (
                                <FormHelperText id="exceptional_days" error>
                                  {t('businessPartner.duplicated_days')}
                                </FormHelperText>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                      )}

                      {/* Restrict Time */}
                      {(this.state.restrict_type?.includes('REPEAT') || this.state.restrict_type?.includes('SPECIFIC_DAYS')) && (
                        <Grid container spacing={1} className="row_form_item">
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            {t('businessPartner.restrict_time')}
                            <span className="font_color_red">＊</span>
                          </Grid>
                          <Grid container alignItems="center" item xs={6}>
                            <TableContainer>
                              <Table>
                                <TableBody>
                                  {this.state.list_restrict_time?.map((item, index) => {
                                    const isOverlap = this.state.overlapList.has(index);
                                    return (
                                      <TableRow key={index}>
                                        <TableCell align="left" className="pl-0" style={{borderBottom: 'none'}}>
                                          <FormControl>
                                            <CustomTimePicker
                                              placeholder={t('common.from')}
                                              name="start_time"
                                              className="field_min_size_70 table_background_color_aliceblue"
                                              value={item.from || null}
                                              showSecond={false}
                                              allowEmpty
                                              onChange={(value) => this.changeRestrictTime(index, value, true)}
                                              format={TIME_FORMAT}
                                              use12Hours={false}
                                              autoComplete="off"
                                            />
                                            {this.validator.message('start_time', item.from, 'required')}
                                            {this.state.isSubmitForm &&
                                              ((!this.validator.check(item.from, 'required') && (
                                                <FormHelperText id="time" style={{color: 'red'}}>
                                                  {t('validation.required', {field: t('vehicles.from')})}
                                                </FormHelperText>
                                              )) ||
                                                (!!!this.checkHoursUnit(item.from, item.to) && (
                                                  <FormHelperText id={'time_range' + index} style={{color: 'red'}}>
                                                    {t('validation.invalid.timeRange')}
                                                  </FormHelperText>
                                                )) ||
                                                (isOverlap && (
                                                  <div>
                                                    <FormHelperText id="time" style={{color: 'red'}}>
                                                      {t('jit.overlap')}
                                                    </FormHelperText>
                                                  </div>
                                                )))}
                                          </FormControl>
                                        </TableCell>
                                        <TableCell align="left" style={{borderBottom: 'none'}}>
                                          <FormControl>
                                            <CustomTimePicker
                                              placeholder={t('common.to')}
                                              name="end_time"
                                              className="field_min_size_70 table_background_color_aliceblue"
                                              value={item.to || null}
                                              showSecond={false}
                                              allowEmpty
                                              onChange={(value) => this.changeRestrictTime(index, value, false)}
                                              format={TIME_FORMAT}
                                              use12Hours={false}
                                              autoComplete="off"
                                            />
                                            {this.validator.message('end_time', item.to, 'required')}
                                            {this.state.isSubmitForm &&
                                              ((!this.validator.check(item.to, 'required') && (
                                                <FormHelperText id="time" style={{color: 'red'}}>
                                                  {t('validation.required', {field: t('vehicles.to')})}
                                                </FormHelperText>
                                              )) ||
                                                (!!!this.checkHoursUnit(item.from, item.to) && (
                                                  <FormHelperText id="time" style={{color: 'red'}}>
                                                    {t('validation.invalid.timeRange')}
                                                  </FormHelperText>
                                                )))}
                                          </FormControl>
                                        </TableCell>
                                        {index === 0 && (
                                          <TableCell align="right" style={{borderBottom: 'none'}}>
                                            <Button disabled={this.state.list_restrict_time?.length > 4} color="primary" variant="contained" onClick={() => this.addItem()}>
                                              <AddIcon />
                                            </Button>
                                          </TableCell>
                                        )}
                                        {index !== 0 && (
                                          <TableCell align="right" style={{borderBottom: 'none'}}>
                                            <Button
                                              color="secondary"
                                              variant="contained"
                                              onClick={() => this.deleteRows(index)}
                                              disabled={this.state.list_restrict_time?.length === 1 || (isEditForm && !permission.canUpdate)}
                                            >
                                              <CancelIcon />
                                            </Button>
                                          </TableCell>
                                        )}
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}

                  <Grid container spacing={1}>
                    <Grid container alignItems="center" item xs={12} lg={12} className="grid_title_padding">
                      <i className="font_color_red font_bold">{t('businessPartner.noteCampaignInfor')}</i>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.campaignInfor')}
                    </Grid>
                    {this.state.discount_type && (
                      <FormControl>
                        <Checkbox
                          color="primary"
                          checked={this.state.isCheckCampaign}
                          disabled={!this.state.discount && this.state.discount !== 0}
                          onClick={(event) => this.handleCheckCampaign(event)}
                        ></Checkbox>
                      </FormControl>
                    )}
                  </Grid>
                  {this.state.isCheckCampaign && (
                    <>
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding"></Grid>
                        <Grid container alignItems="center" item xs={6} lg={2}>
                          <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'discountCommentType',
                              }}
                              displayEmpty
                              disabled={!this.state.discount && this.state.discount !== 0}
                              value={this.state.discountCommentType}
                              onChange={(event) => this.onChangeDiscountCommentType(event)}
                            >
                              {CAMPAIGN_INFOR_DISPLAY.map(({id, i18n}, idx) => (
                                <MenuItem value={id} key={idx}>
                                  {t(`${i18n}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      {this.state.discountCommentType === CAMPAIGN_INFOR_DISPLAY[1].id && (
                        <Grid container spacing={1} className="row_form_item">
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            {t('businessPartner.comment')}
                            <span className="font_color_red">＊</span>
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={2}>
                            <FormControl>
                              <TextField
                                name="campaignInforComment"
                                className="field_size_10 field_min_size_300"
                                fullWidth
                                inputProps={{maxLength: 30}}
                                margin="dense"
                                value={this.state.campaignInforComment || ''}
                                multiline
                                rows={2}
                                onChange={(event) => onChangeTextField(this, event)}
                                placeholder={t('placeholder.required', {field: t('businessPartner.comment')})}
                                variant="outlined"
                              />
                            </FormControl>
                            {this.validator.message(
                              'campaignInforComment',
                              this.state.campaignInforComment,
                              this.state.discountCommentType === CAMPAIGN_INFOR_DISPLAY[1].id ? 'required' : '',
                            )}
                            {this.state.isSubmitForm && !this.validator.check(this.state.campaignInforComment, 'required') && (
                              <FormHelperText id="discount" error>
                                {t('validation.required', {field: t('businessPartner.comment')})}
                              </FormHelperText>
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}

                  {/* Business time */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.business_time')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl fullWidth>
                        <div className="box-group-input">
                          <TextField
                            name="business_time"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('validation.required', {field: t('businessPartner.business_time')})}
                            value={this.state.business_time}
                            onChange={(event) => onChangeTextField(this, event)}
                            inputProps={{maxLength: 255}}
                            variant="outlined"
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 255})}</div>
                        </div>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Paper>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {permission.canUpdate && (
                      <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={() => this.validateWaypointDetail()}>
                        {t('common.btnUpdate')}
                      </Button>
                    )}
                    <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.props.history.goBack}>
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            </Card>
          </LoadingOverlay>
          <br></br>
        </Container>
        {/* modal confirm update */}
        <Dialog
          open={this.state.showUpdateModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <SelectModal
            onClickOk={this.update}
            onClickCancel={this.closeConfirmUpdateModal}
            message={this.state.affectedReservations?.length > 0 ? 'waypoint.confirm_update.reservations' : 'waypoint.confirm_update'}
            fields={this.state.affectedReservations?.length > 0 ? {noReservations: this.state.affectedReservations?.length} : {}}
            listBookingCancel={this.state.bookingCancelIds}
          />
        </Dialog>
        {/* modal success cancel booking */}
        <Dialog
          open={this.state.isSuccessBookingCancel}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <CancelBookingSuccessModal
            message={'waypointMessage.updated'}
            closeMessage={() => this.setState({isSuccessBookingCancel: false, bookingCancelIds: []})}
            listBookingCancel={this.state.bookingCancelIds}
          ></CancelBookingSuccessModal>
        </Dialog>
        {/* modal choose image */}
        <Container maxWidth="xl">
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.openModal}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1000',
            }}
          >
            <>
              <ImageUpload
                onlyLogo={true}
                onClickOk={this.closeImageModal}
                onClickClose={this.closeImageModal}
                selectImage={(value) => value.id && this.setState({logo_url: value.url, image_master_id: value.id})}
              ></ImageUpload>
            </>
          </Modal>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.openProductImagesModal}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1000',
            }}
          >
            <>
              <ImageUpload
                onClickOk={this.closeImageModal}
                onClickClose={this.closeImageModal}
                multipleImage={(value) => value.data.length > 0 && this.setState({product_images_id: value.data})}
                parent_selected={this.state.product_images_id}
                multipleSelect
              ></ImageUpload>
            </>
          </Modal>

          {/* Modal Select Restrict Photo */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.openModalRestrictPhoto}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1000',
            }}
          >
            <>
              <ImageUpload
                onlyLogo={true}
                onClickOk={this.closeImageModal}
                onClickClose={this.closeImageModal}
                selectImage={(value) => value.id && this.setState({restrict_photo_url: value.url, restrict_photo_id: value.id})}
              ></ImageUpload>
            </>
          </Modal>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    geofences: state.common.all_geofence,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    getAllGeofence: (payload) => dispatch(getAllGeofence(payload)),
    searchListImage: (params) => dispatch(searchListImage(params)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(WaypointForm)));
