export const ACTION_TYPES = {
  SEARCH_LIST_RIGHT_MENU_SETTING_START: 'SEARCH_LIST_RIGHT_MENU_SETTING_START',
  SEARCH_LIST_RIGHT_MENU_SETTING_SUCCESS: 'SEARCH_LIST_RIGHT_MENU_SETTING_SUCCESS',
  SEARCH_LIST_RIGHT_MENU_SETTING_FAIL: 'SEARCH_LIST_RIGHT_MENU_SETTING_FAIL',

  CREATE_RIGHT_MENU_SETTING_START: 'CREATE_RIGHT_MENU_SETTING_START',
  CREATE_RIGHT_MENU_SETTING_SUCCESS: 'CREATE_RIGHT_MENU_SETTING_SUCCESS',
  CREATE_RIGHT_MENU_SETTING_FAIL: 'CREATE_RIGHT_MENU_SETTING_FAIL',

  GET_RIGHT_MENU_SETTING_START: 'GET_RIGHT_MENU_SETTING_START',
  GET_RIGHT_MENU_SETTING_SUCCESS: 'GET_RIGHT_MENU_SETTING_SUCCESS',
  GET_RIGHT_MENU_SETTING_FAIL: 'GET_RIGHT_MENU_SETTING_FAIL',

  UPDATE_RIGHT_MENU_SETTING_START: 'UPDATE_RIGHT_MENU_SETTING_START',
  UPDATE_RIGHT_MENU_SETTING_SUCCESS: 'UPDATE_RIGHT_MENU_SETTING_SUCCESS',
  UPDATE_RIGHT_MENU_SETTING_FAIL: 'UPDATE_RIGHT_MENU_SETTING_FAIL',
};

const initialState = {
  isLoading: false,
  listRightMenuSetting: [],
  detailRightMenuSetting: {},
};

/**
 * function reduce right menu setting
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.SEARCH_LIST_RIGHT_MENU_SETTING_START:
    case ACTION_TYPES.CREATE_RIGHT_MENU_SETTING_START:
    case ACTION_TYPES.GET_RIGHT_MENU_SETTING_START:
    case ACTION_TYPES.UPDATE_RIGHT_MENU_SETTING_START:
    case ACTION_TYPES.DELETE_RIGHT_MENU_SETTING_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.SEARCH_LIST_RIGHT_MENU_SETTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listRightMenuSetting: action.listRightMenuSetting,
      };
    case ACTION_TYPES.GET_RIGHT_MENU_SETTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        detailRightMenuSetting: action.detailRightMenuSetting,
      };
    case ACTION_TYPES.CREATE_RIGHT_MENU_SETTING_SUCCESS:
    case ACTION_TYPES.UPDATE_RIGHT_MENU_SETTING_SUCCESS:
    case ACTION_TYPES.DELETE_RIGHT_MENU_SETTING_SUCCESS:
    case ACTION_TYPES.CREATE_RIGHT_MENU_SETTING_FAIL:
    case ACTION_TYPES.UPDATE_RIGHT_MENU_SETTING_FAIL:
    case ACTION_TYPES.DELETE_RIGHT_MENU_SETTING_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.SEARCH_LIST_RIGHT_MENU_SETTING_FAIL:
      return {
        ...state,
        isLoading: false,
        listRightMenuSetting: [],
      };
    case ACTION_TYPES.GET_RIGHT_MENU_SETTING_FAIL:
      return {
        ...state,
        isLoading: false,
        detailRightMenuSetting: {},
      };
    default:
      return state;
  }
}
