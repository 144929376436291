import React, {Component} from 'react';

import {
  Card,
  Container,
  Grid,
  Button,
  Box,
  Radio,
  FormControl,
  Select,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  FormHelperText,
  MenuItem,
  Checkbox,
  TextField,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import _ from 'lodash';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {DATA_NULL, ROUTE, TICKET_AMOUNT_TYPE, PAYMENT_METHODS, ODM_BOOKING_TIME_TYPES, CONFIG_NUMBER, SPECIAL_PERSON_TYPE, GEOFENCE_NAME_CONFIG} from '../../../../common/constant';
import {getJitPlanSubscriptionApi} from '../../../../services/reservationServices';
import {checkOwnerCard} from '../../../../stores/business/action';
import {updateBooking, getMemberSubscription, searchBookingUpdate} from '../../../../stores/reservation/actions';
import {customDisplayCurrency, onChangeSelect, onChangeTextField} from '../../../../utils/common';
import {getMinutes} from '../../../../utils/datetime';

/**
 * Passenger Detail page
 */
class PassengerDetail extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    const {odm_booking_time_type, advance_booking_offer} = props.searchBooking;
    const isBookingAdvance = odm_booking_time_type === ODM_BOOKING_TIME_TYPES[1].id && advance_booking_offer?.booking_type === ODM_BOOKING_TIME_TYPES[1].id;
    this.state = {
      isSubscription: false,
      selectedSubscriptionId: '',
      selectedSubscription: {},
      subscriptionMainUser: '',

      member_id: '',
      member_code: '',
      first_name: '',
      last_name: '',
      first_name_furigana: '',
      last_name_furigana: '',
      email: '',
      mobile: '',
      payment_methods: [],
      payment_method: '',
      isCheckCreditCard: true,
      no_adults: 0,
      no_children: 0,

      listSubscription: [],
      non_member_list: [],
      passenger_detail: [],

      listTicket: [],
      isLoading: false,
      isLoadingCheckingCredit: false,
      discount: {},
      numberDiscountAdult: 0,
      numberDiscountChild: 0,
      listTicketDiscount: [],
      timeNow: new Date().getHours() * 60 + new Date().getMinutes(),
      geofenceTime: {},
      listSubTicketTimeOverlap: [],
      listSubNormalTimeOverlap: [],
      isBookingAdvance,
      isSubInOperatingDateTime: false,
      isSubInOperatingDate: false,
      isSubInOperatingTime: false,
      estmatePickupDateTime: props.searchBooking?.advance_booking_offer?.travel_solution?.[0]?.estimated_pickup_time,
      listTicketNotInUse: [],
      selectedSubscriptionCache: {},
      geofenceStartTime: '',
      geofenceEndTime: '',
      infantList: [],
      special_prices: [],
      checkInfantByGeofenceValid: true,
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    this.setState({isLoading: true});
    try {
      const {booking, searchBooking} = this.props;
      if (booking?.trip_info?.zone_id) {
        const timeWithZone = moment.tz(booking?.trip_info?.zone_id)?.hours() * 60 + moment.tz(booking?.trip_info?.zone_id)?.minutes();
        this.setState({timeNow: timeWithZone});
      }
      if (_.isEmpty(booking) || _.isEmpty(searchBooking)) return this.props.history.push(ROUTE.LAYOUT + ROUTE.RESERVATION_CREATE);
      this.setState({
        member_id: booking?.member_id,
        member_code: booking?.member_code,
        first_name: booking?.first_name,
        last_name: booking?.last_name,
        first_name_furigana: booking?.first_name_furigana,
        last_name_furigana: booking?.last_name_furigana,
        email: booking?.email,
        mobile: booking?.mobile,
        payment_methods: searchBooking?.payment_methods,
        payment_method: searchBooking?.payment_methods?.[0]?.id || '',
        discount: booking?.discount || {},
        numberDiscountAdult: booking?.discount ? booking?.discount?.numberDiscountAdult : 0,
        numberDiscountChild: booking?.discount ? booking?.discount?.numberDiscountChild : 0,
        no_adults: booking?.demand?.no_adults || 0,
        no_children: booking?.demand?.no_children || 0,
        selectedSubscriptionCache: !_.isEmpty(searchBooking?.selectedSubscription) ? JSON.parse(JSON.stringify(searchBooking?.selectedSubscription)) : {},
        special_prices: booking?.oneTimeConfigInfo?.special_prices || [],
      });
      await this.handleSearchSubsciption();
      this.handlePayment();
    } finally {
      await this.loadingPassengerTable();
      this.setState({isLoading: false});
    }
  }

  /**
   * handleSearchSubsciption
   */
  handleSearchSubsciption = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const {isBookingAdvance, estmatePickupDateTime} = this.state;
        const {booking, searchBooking} = this.props;
        const paramsUrl = {
          user_id: booking?.member_id,
          geofence_id: booking?.geofence_id,
        };
        const queryParams = {
          serviceTypes: searchBooking.mobi_type,
          planTypes: 'PLAN,TICKET',
          shift_start_time: isBookingAdvance ? booking?.requested_departure_time : booking.estimate_start_time || new Date().toISOString(),
          for_booking_advanced: isBookingAdvance,
        };
        const res = await getJitPlanSubscriptionApi(paramsUrl, queryParams);
        if (res.status === 200 && res?.result?.length > 0) {
          const displayTickets = res.result?.filter((ticket) => (isBookingAdvance ? true : ticket.in_subscription_shift_time));
          if (displayTickets?.length > 0) {
            // Calculator timenow
            const pickupTime =
              booking?.estimate_start_time && searchBooking.mobi_type === 'SHUTTLE_BUS_JIT' ?
                booking?.estimate_start_time :
                isBookingAdvance ?
                estmatePickupDateTime :
                new Date().toISOString();
            const timeLocalByCountry = moment.tz(pickupTime, searchBooking.zone_id);
            const timeNow = timeLocalByCountry.hours() * 60 + timeLocalByCountry.minutes();
            this.setState({timeNow});
            // Calculation geofence time
            const geofence = res.result[0]?.geofence_list.find((geofence) => geofence.geofence_id === booking?.geofence_id);
            const geofenceStartTime = geofence?.local_operating_start_time;
            const geofenceEndTime = geofence?.local_operating_end_time;
            const geofenceTime = {start: getMinutes(geofenceStartTime), end: getMinutes(geofenceEndTime)};
            // Handle list Subscription
            const listSubscriptionNormal = displayTickets?.filter((item) => item.plan_type === 'PLAN');
            const listSubscriptionTicket = displayTickets
              ?.filter((item) => item.plan_type === 'TICKET' && item.ticket_data.ticket_type === 'ADULT')
              .concat(displayTickets?.filter((item) => item.plan_type === 'TICKET' && item.ticket_data.ticket_type === 'CHILDREN'));
            // Get list sub ticket can use
            const listTicketInUse = listSubscriptionTicket?.filter((ticket) =>
              isBookingAdvance ? this.checkSubTicketInOperatingHour(ticket, geofenceStartTime, geofenceEndTime) : true,
            );
            // Get list sub ticket can't use (apply for booking advance)
            const listTicketNotInUse = listSubscriptionTicket?.filter((ticket) => !listTicketInUse?.map((ticket_in_use) => ticket_in_use?.id)?.includes(ticket.id));
            const listTicketDiscount = listTicketInUse.length > 0 ? listTicketInUse.filter((ticket) => !_.isEmpty(ticket.discount)) : [];
            const listTiketNotDiscount = listTicketInUse.length > 0 ? listTicketInUse.filter((ticket) => !ticket.discount) : [];
            const listTicket = listTicketDiscount.concat(listTiketNotDiscount);
            // Get list overlap time for sub ticket
            if (isBookingAdvance ? false : timeNow < geofenceTime?.start || timeNow > geofenceTime?.end) {
              let listSubTicketTimeOverlap = [];
              listTicket.forEach((ticket) => {
                listSubTicketTimeOverlap = listSubTicketTimeOverlap.concat([
                  // eslint-disable-next-line array-callback-return
                  ticket.subscription_shifts.map((sub) => {
                    return this.getTimeOverlap(sub.local_start_time, sub.local_end_time, geofenceStartTime, geofenceEndTime);
                  }),
                ]);
              });
              this.setState({listSubTicketTimeOverlap});
            }
            const subscriptionSelected = _.isEmpty(this.state.selectedSubscriptionCache) ?
              listSubscriptionNormal?.length > 0 ?
                JSON.parse(JSON.stringify(listSubscriptionNormal[0])) :
                {} :
              JSON.parse(JSON.stringify(this.state.selectedSubscriptionCache));
            res.result && res?.result?.length > 0 ?
              this.setState(
                  {
                    listSubscription: listSubscriptionNormal,
                    isSubscription: true,
                    listTicket,
                    discount: listTicketDiscount.length > 0 ? listTicketDiscount[0].discount : {},
                    listTicketDiscount,
                    geofenceStartTime,
                    geofenceEndTime,
                    geofenceTime,
                    listTicketNotInUse,
                  },
                  () => listSubscriptionNormal && listSubscriptionNormal?.length > 0 && this.handleChangeSubscription(subscriptionSelected),
                ) :
              this.setState({listSubscription: [], isSubscription: false});
          } else {
            this.setState({listSubscription: [], isSubscription: false});
          }
        }
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * handlePayment
   * @return {Promise}
   */
  handlePayment = () => {
    const {booking, searchBooking} = this.props;
    if (booking?.payment_method) {
      this.setState({payment_method: booking?.payment_method, payment_methods: booking?.payment_methods});
    } else {
      if (booking?.member_type === 'MEMBER') {
        this.setState({isLoadingCheckingCredit: true});
        return this.props.checkOwnerCard(booking?.member_id).then((response) => {
          if (response) {
            let isCheckCreditCard = true;
            switch (searchBooking?.country_code) {
              case 'VN':
                isCheckCreditCard = response['alepay_cards']?.length > 0 ? true : false;
                break;
              case 'JP':
                isCheckCreditCard = response['veritrans_cards']?.length > 0 ? true : false;
                break;
              case 'SG':
                isCheckCreditCard = response['2c2p_cards']?.length > 0 ? true : false;
                break;
              case 'MY':
                isCheckCreditCard = response['2c2p_my_cards']?.length > 0 ? true : false;
                break;
              default:
                isCheckCreditCard = false;
            }
            // have credit card and have method credit card
            if (isCheckCreditCard && searchBooking?.payment_methods.find((payment) => payment.id === PAYMENT_METHODS[0].id)) {
              this.setState({payment_method: PAYMENT_METHODS[0].id});
            }
            // have not credit card and have method cash
            if (!isCheckCreditCard && searchBooking?.payment_methods.find((payment) => payment.id === PAYMENT_METHODS[1].id)) {
              this.setState({payment_method: PAYMENT_METHODS[1].id, payment_methods: [PAYMENT_METHODS[1]]});
            }
            // have not credit card and have not method cash
            if (!isCheckCreditCard && !searchBooking?.payment_methods.find((payment) => payment.id === PAYMENT_METHODS[1].id)) {
              this.setState({payment_methods: []});
            }
            this.setState({isCheckCreditCard, isLoadingCheckingCredit: false});
          }
        });
      } else {
        this.setState({
          isCheckCreditCard: false,
          payment_methods: searchBooking?.payment_methods.find((payment) => payment.id === PAYMENT_METHODS[1].id) ? [PAYMENT_METHODS[1]] : [],
          payment_method: searchBooking?.payment_methods.find((payment) => payment.id === PAYMENT_METHODS[1].id) ? PAYMENT_METHODS[1].id : '',
        });
      }
    }
  };
  /**
   * Get time overlap between subscription time and geofence time
   * @param {String} subscription_start_time HH:mm
   * @param {String} subscription_end_time HH:mm
   * @param {String} geofence_start_time HH:mm
   * @param {String} geofence_end_time HH:mm
   * @return {String} HH:mm ~ HH:mm
   */
  getTimeOverlap = (subscription_start_time, subscription_end_time, geofence_start_time, geofence_end_time) => {
    const maxStart = (subscription_start_time > geofence_start_time ? subscription_start_time : geofence_start_time)?.substring(0, 5);
    const minEnd = (subscription_end_time < geofence_end_time ? subscription_end_time : geofence_end_time)?.substring(0, 5);
    return maxStart < minEnd ? maxStart + ' ~ ' + minEnd : '';
  };

  /**
   * Check sub in operating hour
   */
  checkSubInOperatingHour = () => {
    const {searchBooking} = this.props;
    const {listSubNormalTimeOverlap, selectedSubscription, estmatePickupDateTime} = this.state;
    if (!this.state.isBookingAdvance || _.isEmpty(selectedSubscription)) return;
    const now = new Date();
    const subStart = new Date(selectedSubscription?.effective_start_time);
    const subEnd = new Date(selectedSubscription?.effective_end_time);
    const pickupDateTime = new Date(estmatePickupDateTime);

    // checkEstimatePickupDate: check estimate pickup date in range of sub's date
    const checkEstimatePickupDate = this.checkTimeInRange(pickupDateTime, subStart, subEnd);
    // checkEstimatePickupTime: check estimate pickup time in range of sub's operating hour
    const checkEstimatePickupTime = listSubNormalTimeOverlap?.[0]?.some((time_overlap) => {
      const overlapSubscriptionGeofence = time_overlap.split(' ~ ');
      return this.checkTimeInRange(moment.tz(pickupDateTime, searchBooking.zone_id).format('HH:mm'), overlapSubscriptionGeofence[0], overlapSubscriptionGeofence[1]);
    });
    let checkSubUsage = true;
    // if estimate pickup date (not) in range of sub's date then check (subStart < now < subEnd) or (pickup start date > subEnd)
    if (!checkEstimatePickupDate) {
      if (pickupDateTime < subStart || (now < subEnd && subEnd < pickupDateTime)) {
        checkSubUsage = false;
      }
    }
    // handle user can/can't use subscription
    const isSubInOperatingDateTime = checkEstimatePickupDate ? checkEstimatePickupTime : checkSubUsage;
    const isSubInOperatingDate = checkEstimatePickupDate;
    const isSubInOperatingTime = checkEstimatePickupTime;
    if (!searchBooking?.selectedSubscriptionId || searchBooking?.selectedSubscriptionId !== this.state.selectedSubscriptionId) {
      const subscription_users = selectedSubscription?.user_subscription?.users?.map((item) => {
        const data = item;
        data.using = item.id === this.state.member_id && isSubInOperatingDateTime ? true : false;
        return data;
      });
      selectedSubscription.user_subscription.users = subscription_users || '';
    }
    this.setState({selectedSubscription, isSubInOperatingDateTime, isSubInOperatingTime, isSubInOperatingDate}, () => this.loadingPassengerTable());
  };

  /**
   * Check a time value (datetime or HH:mm) in a period time
   * @param {*} value
   * @param {*} startTime
   * @param {*} endTime
   * @return {Boolean}
   */
  checkTimeInRange = (value, startTime, endTime) => {
    return value >= startTime && value <= endTime;
  };

  /**
   * checkSubTicketInOperatingHour
   * @param {*} ticket
   * @param {*} geofencStartTime
   * @param {*} geofenceEndTime
   * @return {Boolean}
   */
  checkSubTicketInOperatingHour = (ticket, geofencStartTime, geofenceEndTime) => {
    const now = new Date();
    const subStart = new Date(ticket?.effective_start_time);
    const subEnd = new Date(ticket?.effective_end_time);
    const pickupDateTime = new Date(this.state.estmatePickupDateTime);
    const listTimeOverlapSubAndGeo = ticket.subscription_shifts?.map((ticket) =>
      this.getTimeOverlap(ticket.local_start_time, ticket.local_end_time, geofencStartTime, geofenceEndTime),
    );
    // checkEstimatePickupDate: check estimate pickup date in range of sub's date
    const checkEstimatePickupDate = this.checkTimeInRange(pickupDateTime, subStart, subEnd);
    // checkEstimatePickupTime: check estimate pickup time in range of sub's operating hour
    const checkEstimatePickupTime = listTimeOverlapSubAndGeo?.some((time_overlap) => {
      const overlapSubscriptionGeofence = time_overlap.split(' ~ ');
      return this.checkTimeInRange(moment.tz(pickupDateTime, this.props.searchBooking.zone_id).format('HH:mm'), overlapSubscriptionGeofence[0], overlapSubscriptionGeofence[1]);
    });
    let checkSubUsage = true;
    // if estimate pickup date (not) in range of sub's date then check (subStart < now < subEnd) or (pickup start date > subEnd)
    if (!checkEstimatePickupDate) {
      if (pickupDateTime < subStart || (now < subEnd && subEnd < pickupDateTime)) {
        checkSubUsage = false;
      }
    }
    return checkEstimatePickupDate ? checkEstimatePickupTime : checkSubUsage;
  };

  /**
   * loadingPassengerTable
   * @return {Promise}
   */
  loadingPassengerTable = () => {
    return new Promise(async (resolve, reject) => {
      try {
        // get data from previous screen
        const passenger = this.props.booking?.demand?.passenger;
        const {booking, searchBooking} = this.props;
        const non_member_list_cache = booking?.non_member_list || [];

        const {isBookingAdvance} = this.state;
        const {listTicket} = this.state;
        let user_sub_normal = this.state.selectedSubscription?.user_subscription?.users?.filter((e) => e.using === true) || [];
        let non_member_list = [];
        const infantList = [];
        for (let i = 0; i < booking?.demand?.no_infants; i++) {
          infantList.push({status: 0});
        }
        this.setState({infantList: infantList});
        if (!isBookingAdvance ? this.state.timeNow < this.state.geofenceTime.start || this.state.timeNow > this.state.geofenceTime.end : false) {
          if (non_member_list.length === 0) {
            for (let i = 0; i < Number(passenger); i++) {
              non_member_list.push({status: 1});
              user_sub_normal = [];
            }
            this.setState({non_member_list, passenger_detail: user_sub_normal, isLoading: false});
            resolve();
            return;
          }
        } else {
          // Handle cache: check change between passenger/subcription current and passenger/subcription previous
          const user_sub_normal_ids_current = user_sub_normal.map((item) => item?.id);
          const user_sub_normal_ids_previous = !_.isEmpty(this.state.selectedSubscriptionCache) ?
            this.state.selectedSubscriptionCache?.user_subscription?.users.filter((e) => e.using === true)?.map((item) => item?.id || null) :
            [];
          const listUser = new Set([...user_sub_normal_ids_current, ...user_sub_normal_ids_previous]);
          const isKeepedPassenger = listUser.size === user_sub_normal_ids_current?.length && listUser.size === user_sub_normal_ids_previous?.length;
          const isKeepedSubscription = searchBooking?.selectedSubscriptionId === this.state.selectedSubscriptionId;
          if (isKeepedSubscription && isKeepedPassenger && non_member_list_cache?.length > 0) {
            this.setState({
              passenger_detail: user_sub_normal,
              non_member_list: non_member_list_cache,
              isLoading: false,
            });
            resolve();
            return;
          }
          let remain_non_member_list = passenger - user_sub_normal?.length - [...non_member_list].length;
          for (const [index, ticket] of Object.entries(listTicket)) {
            if (remain_non_member_list === 0) break;
            const remainRide = ticket?.ticket_data?.current_rides + ticket?.ticket_data?.prev_rides - ticket?.ticket_data?.times_used;
            const forLoop = remainRide >= remain_non_member_list ? remain_non_member_list : remainRide;
            for (let i = 0; i < forLoop; i++) {
              non_member_list.push({status: Number(index) + 3});
            }
            remain_non_member_list = remainRide >= remain_non_member_list ? 0 : remain_non_member_list - remainRide;
          }
          for (let i = 0; i < remain_non_member_list; i++) {
            non_member_list.push({status: 1});
          }
          non_member_list = [...non_member_list].filter((item) => item.status > 2).concat([...non_member_list].filter((item) => item.status <= 2));
          this.setState({non_member_list, passenger_detail: user_sub_normal, isLoading: false});
          resolve();
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  /**
   * handleChangeSubscription
   * @param {Object} subscriptionSelected
   */
  handleChangeSubscription = async (subscriptionSelected) => {
    const {booking, searchBooking} = this.props;
    const {timeNow, geofenceTime} = this.state;
    const checkTimeNowWithoutGeoTime = timeNow < geofenceTime?.start || timeNow > geofenceTime?.end;
    if (!this.state.isBookingAdvance && searchBooking?.selectedSubscriptionId !== subscriptionSelected?.id) {
      const subscription_users = subscriptionSelected?.user_subscription?.users?.map((item) => {
        const data = item;
        data.using = item.id === this.state.member_id && !checkTimeNowWithoutGeoTime ? true : false;
        return data;
      });
      subscriptionSelected.user_subscription.users = subscription_users || '';
    }
    let listSubNormalTimeOverlap = [];
    if (booking) {
      // get overlap time for ticket
      if (this.state.isBookingAdvance ? true : checkTimeNowWithoutGeoTime) {
        listSubNormalTimeOverlap = listSubNormalTimeOverlap.concat([
          // eslint-disable-next-line array-callback-return
          subscriptionSelected.subscription_shifts.map((sub) => {
            return this.getTimeOverlap(sub.local_start_time, sub.local_end_time, this.state.geofenceStartTime, this.state.geofenceEndTime);
          }),
        ]);
      }
    }
    this.setState(
      {
        selectedSubscriptionId: subscriptionSelected.id,
        selectedSubscription: JSON.parse(JSON.stringify(subscriptionSelected)),
        subscriptionMainUser: this.state.member_id,
        listSubNormalTimeOverlap,
      },
      () => this.checkSubInOperatingHour(),
    );
  };

  /**
   * handle select Main user
   * @param {*} event
   */
  handleSelectMainUser = (event) => {
    const main_user = this.state.selectedSubscription?.user_subscription?.users.find((e) => Number(e.id) === Number(event.target.value));
    this.setState({
      subscriptionMainUser: event.target.value,
      member_id: main_user?.id,
      first_name: main_user?.first_name,
      last_name: main_user?.last_name,
      first_name_furigana: main_user?.first_name_furigana,
      last_name_furigana: main_user?.last_name_furigana,
      email: main_user?.email,
      mobile: main_user?.mobile,
      member_code: main_user?.member_id,
    });
  };

  /**
   * check main user is using subscription
   * @return {Boolean}
   */
  checkMainUserAvailable = () => {
    return this.state.selectedSubscription?.user_subscription?.users?.find((member) => Number(member.id) === Number(this.state.subscriptionMainUser))?.using;
  };

  /**
   * handleSelectSubscriptionMember
   * @param {*} event
   * @param {*} id
   */
  handleSelectSubscriptionMember = (event, id) => {
    const {timeNow, geofenceTime} = this.state;
    const selectedSubscriptionCopy = JSON.parse(JSON.stringify(this.state.selectedSubscription));
    const checkTimeNowWithoutGeoTime = timeNow < geofenceTime?.start || timeNow > geofenceTime?.end;
    const subscription_users = selectedSubscriptionCopy?.user_subscription?.users?.map((member) => {
      const data = member;
      data.using = member.id === id && !checkTimeNowWithoutGeoTime ? event.target.checked : data.using;
      return data;
    });
    selectedSubscriptionCopy.user_subscription.users = subscription_users;
    this.setState(
      {
        selectedSubscription: selectedSubscriptionCopy,
        no_adults: 0,
        no_children: 0,
        checkInfantByGeofenceValid: true,
      },
      () => this.loadingPassengerTable(),
    );
  };

  /**
   * onChangeTicketPrice
   * @param {*} event
   * @param {number} index
   */
  onChangeTicketPrice = (event, index) => {
    const data = this.state.non_member_list;
    const {searchBooking, booking} = this.props;
    data[index].status = Number(event.target.value);
    if (GEOFENCE_NAME_CONFIG.includes(searchBooking?.geofenceName)) {
      // check all children selected but not use subscription
      const isInfantNumber = booking?.demand?.no_infants > 0;
      (data.every((item) => item.status === 2) && this.state.passenger_detail?.length === 0 && isInfantNumber) ?
        this.setState({checkInfantByGeofenceValid: false}) :
        this.setState({checkInfantByGeofenceValid: true});
    }
    this.setState({
      non_member_list: data,
    });
  };

  /**
   * toPageConfirm
   * @param {number} numberDiscountAdult
   * @param {number} numberDiscountChild
   * @param {number} fee_total
   */
  toPageConfirm(numberDiscountAdult, numberDiscountChild, fee_total) {
    let no_adults = 0;
    let no_children = 0;
    let no_all_adult = 0;
    let no_all_children = 0;
    const ticket_usage = [];
    const special_number = [];
    const non_member_list = this.state.non_member_list;
    for (let i = 0; i < non_member_list.length; i++) {
      if (Number(non_member_list[i].status) === 1) {
        no_adults++;
        no_all_adult++;
      } else if (Number(non_member_list[i].status) === 2) {
        no_children++;
        no_all_children++;
      } else if (Number(non_member_list[i].status) >= CONFIG_NUMBER) {
        const index = non_member_list[i].status - CONFIG_NUMBER;
        special_number.push(index);
      } else {
        const sub_ticket = this.state.listTicket[non_member_list[i].status - 3];
        let ticket_item = ticket_usage.find((item) => item.subscription === sub_ticket);
        if (!ticket_item) {
          ticket_usage.push({subscription: sub_ticket, number_of_use: 1});
        } else {
          const ticket_item_index = ticket_usage.indexOf(ticket_item);
          ticket_item = {...ticket_item, number_of_use: ticket_item.number_of_use + 1};
          ticket_usage[ticket_item_index] = ticket_item;
        }
        if (sub_ticket?.ticket_data?.ticket_type === 'ADULT') no_all_adult++;
        else if (sub_ticket?.ticket_data?.ticket_type === 'CHILDREN') no_all_children++;
      }
    }
    let plans = [];
    if (this.state.isSubscription) {
      plans = this.state.selectedSubscription?.user_subscription;
    }
    const total_user_ticket = this.state.non_member_list?.filter((member) => member.status >= 3)?.length || 0;
    const data = Object.assign({}, this.props.booking);
    const demand = Object.assign({}, this.props.booking?.demand);
    demand.no_adults = no_adults;
    demand.no_children = no_children;
    demand.no_one_time_adults = no_adults;
    demand.no_one_time_children = no_children;
    demand.no_all_adult = no_all_adult;
    demand.no_all_children = no_all_children;
    data.member_id = this.state.member_id;
    data.member_code = this.state.member_code;
    data.first_name = this.state.first_name;
    data.last_name = this.state.last_name;
    data.first_name_furigana = this.state.first_name_furigana;
    data.last_name_furigana = this.state.last_name_furigana;
    data.email = this.state.email;
    data.mobile = this.state.mobile;
    data.special_number = special_number || [];
    data.payment_method =
      fee_total === 0 && this.state.payment_methods.find((payment) => payment.id === PAYMENT_METHODS[1].id) ?
        PAYMENT_METHODS[1].id :
        this.state.payment_method || PAYMENT_METHODS[0].id;
    data.payment_methods = this.state.payment_methods;
    if (!_.isEmpty(this.state.discount) && total_user_ticket > 0) {
      data.discount = {
        adultNumber: numberDiscountAdult,
        childNumber: numberDiscountChild,
        adultAmount: this.state.discount.adult_amount,
        childAmount: this.state.discount.child_amount,
      };
    } else {
      data.discount = {};
    }
    const payload = {...data, demand, non_member_list, plans, ticket_usage};
    this.props.onUpdateBooking(payload);

    const search = Object.assign({}, this.props.searchBooking);
    this.props.searchBookingUpdate({
      ...search,
      selectedSubscription: JSON.parse(JSON.stringify(this.state.selectedSubscription)),
      selectedSubscriptionId: this.state.selectedSubscriptionId,
      subscriptionMainUser: this.state.subscriptionMainUser,
      ticket_usage,
    });

    this.props.history.push(ROUTE.LAYOUT + ROUTE.RESERVATION_CREATE_CONFIRM);
  }

  /**
   * displayTextErrorTimeInvalid
   * @param {*} startDate
   * @param {*} endDate
   * @return {String} (from) yyyy/MM/DD ~ (to) yyyy/MM/DD
   */
  displayTextErrorDateInvalid = (startDate, endDate) => {
    const zone_id = this.props.searchBooking.zone_id;
    const formatDate = 'yyyy/MM/DD';
    return moment.tz(startDate, zone_id).format(formatDate) + ' ~ ' + moment.tz(endDate, zone_id).format(formatDate);
  };

  /**
   * displayTextErrorTimeInvalid
   * @param {*} startTime HH:mm:ss
   * @param {*} endTime HH:mm:ss
   * @return {String} (from) HH:mm ~ (to) HH:mm
   */
  displayTextErrorTimeInvalid = (startTime, endTime) => {
    const formatHHmmss = 'HH:mm:ss';
    const formatHHmm = 'HH:mm';
    return moment(startTime, formatHHmmss).format(formatHHmm) + ' ~ ' + moment(endTime, formatHHmmss).format(formatHHmm);
  };

  /**
   * render component
   * @return {HTML}
   */
  render() {
    const {t, booking, searchBooking} = this.props;
    const {isBookingAdvance, checkInfantByGeofenceValid} = this.state;
    const total_user_sub = !_.isEmpty(this.state.selectedSubscription) ?
      this.state.selectedSubscription?.user_subscription?.users?.filter((user) => user.using === true)?.length :
      0;
    const user_ticket_discount = this.state.non_member_list?.filter((member) => member.status >= 3 && member.status <= this.state.listTicketDiscount.length - 1 + 3);
    const discountNumberList = user_ticket_discount
      .filter((item, index, self) => {
        return self.findIndex((item_2) => item.status === item_2.status) === index;
      })
      .map((discount) => ({
        index: discount.status - 3,
        number: user_ticket_discount.filter((ticket) => ticket.status === discount.status).length,
      }));
    let maxDiscountQuantity = 0;
    if (!_.isEmpty(this.state.discount)) {
      discountNumberList.forEach((item) => {
        maxDiscountQuantity += this.state.listTicketDiscount[item.index]?.discount?.number_of_user_with_discount * item.number;
      });
    }
    let numberDiscountAdult = this.state.numberDiscountAdult || 0;
    let numberDiscountChild = this.state.numberDiscountChild || 0;
    const total_adult = this.state.non_member_list.filter((item) => item.status === 1).length;
    const total_child = this.state.non_member_list.filter((item) => item.status === 2).length;
    if (!_.isEmpty(this.state.discount)) {
      if (
        this.props.searchBooking?.service_config?.adult_price - this.state.discount.adult_amount >
        this.props.searchBooking?.service_config?.child_price - this.state.discount.child_amount
      ) {
        numberDiscountAdult = maxDiscountQuantity >= total_adult ? total_adult : maxDiscountQuantity;
        numberDiscountChild = maxDiscountQuantity - numberDiscountAdult >= total_child ? total_child : maxDiscountQuantity - numberDiscountAdult;
      } else if (
        this.props.searchBooking?.service_config?.adult_price - this.state.discount.adult_amount <
        this.props.searchBooking?.service_config?.child_price - this.state.discount.child_amount
      ) {
        numberDiscountChild = maxDiscountQuantity >= total_child ? total_child : maxDiscountQuantity;
        numberDiscountAdult = maxDiscountQuantity - numberDiscountChild >= total_adult ? total_adult : maxDiscountQuantity - numberDiscountChild;
      } else {
        numberDiscountAdult = maxDiscountQuantity >= total_adult ? total_adult : maxDiscountQuantity;
        numberDiscountChild = maxDiscountQuantity - numberDiscountAdult >= total_child ? total_child : maxDiscountQuantity - numberDiscountAdult;
      }
    }
    const isCheckSub = this.state.selectedSubscription?.user_subscription?.users?.filter((item) => item.using === true)?.length > this.props.booking?.demand?.passenger;

    // advance booking fee
    const advance_booking_fee = searchBooking?.service_config?.advance_booking_prices;
    const advance_booking_fee_adult = advance_booking_fee?.length > 0 ? advance_booking_fee[0]?.additional_adult_price : 0;
    const advance_booking_fee_child = advance_booking_fee?.length > 0 ? advance_booking_fee[0]?.additional_child_price : 0;

    const adultSurcharge = booking?.oneTimeConfigInfo?.frame_adult_price;
    const childSurcharge = booking?.oneTimeConfigInfo?.frame_child_price;
    // calculator total fee
    let ticket_adult = 0;
    let ticket_child = 0;
    const non_member_for_ticket = this.state.non_member_list.filter((item) => item.status >= 3);
    for (let i = 0; i < non_member_for_ticket.length; i++) {
      const sub_ticket = this.state.listTicket[non_member_for_ticket[i].status - 3];
      if (sub_ticket?.ticket_data?.ticket_type === 'ADULT') ticket_adult++;
      else if (sub_ticket?.ticket_data?.ticket_type === 'CHILDREN') ticket_child++;
    }
    const surcharge_sub_normal = total_user_sub * (adultSurcharge > 0 ? adultSurcharge : 0);
    const surcharge_sub_ticket_adult = ticket_adult * (adultSurcharge > 0 ? adultSurcharge : 0);
    const surcharge_sub_ticket_child = ticket_child * (childSurcharge > 0 ? childSurcharge : 0);
    // cacuclate special price
    const used_special_price = booking?.oneTimeConfigInfo?.special_prices?.map((special) => ({...special, number: 0})) || [];
    let fee_special_person = 0;
    if (used_special_price?.length > 0) {
      for (let i = 0; i < this.state.non_member_list.length; i++) {
        if (Number(this.state.non_member_list[i].status) >= CONFIG_NUMBER) {
          const index = this.state.non_member_list[i].status - CONFIG_NUMBER;
          used_special_price[index].number++;
        }
      }
      const used_special_price_filter = used_special_price.filter((special_price) => special_price.number !== 0);
      used_special_price_filter.forEach((special_price) => {
        fee_special_person += special_price?.price * special_price.number;
      });
    }
    const fee_one_time_adult =
      (total_adult - numberDiscountAdult) *
      (adultSurcharge + (isBookingAdvance && searchBooking?.advance_without_partner ? advance_booking_fee_adult : 0) + booking?.oneTimeConfigInfo?.origin_adult_price);
    const fee_one_time_child =
      (total_child - numberDiscountChild) *
      (childSurcharge + (isBookingAdvance && searchBooking?.advance_without_partner ? advance_booking_fee_child : 0) + booking?.oneTimeConfigInfo?.origin_child_price);
    const fee_discount_adult = numberDiscountAdult * (this.state.discount.adult_amount || 0);
    const fee_discount_child = numberDiscountChild * (this.state.discount.child_amount || 0);
    const fee_total =
      surcharge_sub_normal +
      surcharge_sub_ticket_adult +
      surcharge_sub_ticket_child +
      fee_one_time_adult +
      fee_one_time_child +
      fee_discount_adult +
      fee_discount_child +
      fee_special_person;
    const language = localStorage.getItem('i18nextLng');
    return (
      <LoadingOverlay active={booking.isLoading || this.state.isLoading || this.state.isLoadingCheckingCredit} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('reservationManagement.passenger_detail')}</h3>
              </Grid>
              <Grid container alignItems="center" justifyContent="flex-end" item xs={6}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />} onClick={this.props.history.goBack}>
                  {t('common.btnReturn')}
                </Button>
              </Grid>
            </Grid>
          </Container>
          <Box p={1} m={1}>
            <Card raised>
              <Container maxWidth="xl">
                <Grid item xs={12}>
                  <h1 className="product_entry_table_header_color font_color_white font_size_mid title subscription-detail">
                    {t('reservationManagement.passenger_detail')} &nbsp;{' '}
                    <span className="font-12">
                      ({t('reservationManagement.number_passenger')}: {this.props.booking?.demand?.passenger + this.props.booking?.demand?.no_infants}{' '}
                      {t('reservationManagement.people')})
                    </span>
                  </h1>
                </Grid>
                <Grid container item alignItems="flex-start" justifyContent="center" xs={12} lg={12}>
                  <Grid container item alignItems="flex-start" justifyContent="center" xs={4}>
                    <Grid item xs={12} className="display_flex">
                      <Grid className="px_14" container alignItems="center" item xs={6} lg={6}>
                        {t('reservationManagement.name_info')}
                      </Grid>
                      <Grid className="px_14" container alignItems="center" item xs={6} lg={6}>
                        <span>{(this.state.last_name && this.state.last_name + ' ') + this.state.first_name}</span>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className="display_flex">
                      <Grid className="px_14" container alignItems="center" item xs={6} lg={6}>
                        {t('reservationManagement.name_furigana')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={6}>
                        <span>{(this.state.last_name_furigana && this.state.last_name_furigana + ' ') + this.state.first_name_furigana}</span>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className="display_flex">
                      <Grid className="px_14" container alignItems="center" item xs={6} lg={6}>
                        {t('common.email')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={6}>
                        <span>{this.state.email}</span>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className="display_flex">
                      <Grid container alignItems="center" item xs={6} lg={6}>
                        {t('common.phoneNumber')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={6}>
                        <FormControl className="width_100">
                          <TextField
                            className="width_100"
                            value={this.state.mobile}
                            margin="dense"
                            variant="outlined"
                            placeholder={t('placeholder.required', {field: t('common.phoneNumber')})}
                            onChange={(event) => onChangeTextField(this, event)}
                            name="mobile"
                          />
                          {this.validator.message('tel', this.state.mobile, 'required|phone')}
                          {!this.validator.check(this.state.mobile, 'required|phone') && <p className="error">{t('validation.required', {field: t('common.phoneNumber')})}</p> && (
                            <FormHelperText id="tel" error>
                              {t('validation.phone')}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                    {fee_total !== 0 && (
                      <Grid item xs={12} className="display_flex">
                        <Grid container alignItems="center" item xs={6} lg={6}>
                          {t('common.paymentMethod')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={6}>
                          <FormControl className="width_100">
                            <Select
                              variant="outlined"
                              margin="dense"
                              inputProps={{
                                name: 'payment_method',
                              }}
                              value={this.state.payment_method}
                              onChange={(event) => onChangeSelect(this, event)}
                              displayEmpty
                              renderValue={
                                this.state.payment_method ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('common.paymentMethod'),
                                        })}
                                      </div>
                                    )
                              }
                            >
                              {this.state.payment_methods?.map((item, index) => (
                                <MenuItem value={item.id} key={index}>
                                  {t(item.i18n)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}
                    <br></br>
                    {this.props.booking?.member_type === 'MEMBER' && this.state.isSubscription && (
                      <>
                        <Grid item xs={12} className="display_flex">
                          <Grid container alignItems="center" item xs={6} lg={6}>
                            {t('reservationManagement.subscriptions')}
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={6}>
                            <FormControl variant="outlined" margin="dense" className="width_100">
                              <Select
                                variant="outlined"
                                margin="dense"
                                inputProps={{
                                  name: 'selectedSubscriptionId',
                                }}
                                value={this.state.selectedSubscriptionId}
                                onChange={(event) => this.handleChangeSubscription(this.state.listSubscription?.find((item) => item.id === event.target.value))}
                                displayEmpty
                                renderValue={
                                  this.state.selectedSubscriptionId ?
                                    undefined :
                                    () => (
                                        <div className="font-12 color-disabled">
                                          {t('placeholder.required_select', {
                                            field: t('reservationManagement.subscriptions'),
                                          })}
                                        </div>
                                      )
                                }
                              >
                                {_.isEmpty(this.state.listSubscription) && (
                                  <MenuItem value={''}>
                                    <pre>{t('common.noData')}</pre>
                                  </MenuItem>
                                )}
                                {this.state.listSubscription?.map((item, index) => (
                                  <MenuItem value={item.id} key={index}>
                                    {item.plan_name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>

                        {!_.isEmpty(this.state.listSubscription) && (
                          <Grid container alignItems="flex-start">
                            <div className="scroll_area_400 width_100">
                              <Table size="small" aria-label="sticky table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell className="width_150p">{t('common.name')}</TableCell>
                                    <TableCell className="width_100p">{t('reservationManagement.subscriptions_using')}</TableCell>
                                    <TableCell className="width_100p">{t('reservationManagement.subscriptions_main_user')}</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {this.state.selectedSubscription?.user_subscription?.users?.map((element, index) => {
                                    const {isSubInOperatingDateTime} = this.state;
                                    return (
                                      <TableRow className="cursor_pointer" key={index}>
                                        <TableCell>{element.last_name + ' ' + element.first_name}</TableCell>
                                        <TableCell>
                                          <Checkbox
                                            checked={
                                              isBookingAdvance ?
                                                isSubInOperatingDateTime ?
                                                  element.using :
                                                  false :
                                                element.using && this.state.timeNow >= this.state.geofenceTime.start && this.state.timeNow <= this.state.geofenceTime.end
                                            }
                                            onChange={(event) => this.handleSelectSubscriptionMember(event, element.id)}
                                            inputProps={{'aria-label': 'primary checkbox'}}
                                            disabled={isBookingAdvance ? !isSubInOperatingDateTime : false}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <Radio
                                            checked={Number(element.id) === Number(this.state.subscriptionMainUser)}
                                            disabled={element.using === false || (isBookingAdvance ? !isSubInOperatingDateTime : false)}
                                            value={element.id}
                                            onChange={(event) => this.handleSelectMainUser(event)}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                              {isCheckSub && (
                                <>
                                  <FormHelperText id="subscription" error>
                                    {t('reservationManagement.select_member')}
                                  </FormHelperText>
                                  <br />
                                </>
                              )}
                              {this.state.selectedSubscription?.user_subscription?.users?.filter((item) => item.using === true)?.length === 0 && (
                                <>
                                  <FormHelperText id="subscription" error>
                                    {t('validation.required.select_array', {
                                      element: t('reservationManagement.member'),
                                    })}
                                  </FormHelperText>
                                  <br />
                                </>
                              )}
                              {!this.checkMainUserAvailable() && (
                                <>
                                  <FormHelperText id="subscription" error>
                                    {t('reservationManagement.select_main_user')}
                                  </FormHelperText>
                                  <br />
                                </>
                              )}
                            </div>
                          </Grid>
                        )}
                        {!_.isEmpty(this.state.discount) && (
                          <>
                            <Grid className="px_14" container alignItems="flex-start">
                              <Grid className="discount_box" container alignItems="flex-start">
                                <Grid className="background_logo_discount">
                                  <img className="discount_icon" alt="item_discount" src={process.env.PUBLIC_URL + '/images/reservation/discount_logo.svg'} />
                                </Grid>
                                <Grid className="discount_text">{t('reservationManagement.one_time_discount')}</Grid>
                              </Grid>
                              <Grid className="scroll_area_400 width_100 ">
                                <Table size="small" aria-label="sticky table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className="width_100p">{t('reservationManagement.discount_target')}</TableCell>
                                      <TableCell className="width_150p text_align_center">{t('reservationManagement.amount_after_discount')}</TableCell>
                                      <TableCell className="width_100p">{t('reservationManagement.applied_number')}</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow className="cursor_pointer" key="adult">
                                      <TableCell>{t('ticket.adult')}</TableCell>
                                      <TableCell>{customDisplayCurrency(this.state.discount.adult_amount, this.state.discount.currency_code)}</TableCell>
                                      <TableCell>{numberDiscountAdult}</TableCell>
                                    </TableRow>
                                    <TableRow className="cursor_pointer" key="child">
                                      <TableCell>{t('ticket.child')}</TableCell>
                                      <TableCell>{customDisplayCurrency(this.state.discount.child_amount, this.state.discount.currency_code)}</TableCell>
                                      <TableCell>{numberDiscountChild}</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </Grid>
                            </Grid>
                            <Grid xl={12} className="text_number_discount">
                              {t('reservationManagement.max_number_people')}: {maxDiscountQuantity}{' '}
                            </Grid>
                            <Grid xl={12} className="text_number_discount">
                              {t('reservationManagement.remaining_number_people')}: {maxDiscountQuantity - numberDiscountAdult - numberDiscountChild}{' '}
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid container item alignItems="center" justifyContent="center" xs={8}>
                    <div className="table-container-div" style={{overflow: 'auto', marginLeft: 25}}>
                      {booking?.applySurchargeForGeofence && (
                        <Grid item xs={12} className="pre_line">
                          <Box>
                            <b>
                              <i>{booking?.oneTimeConfigInfo?.frame_time_descriptions?.details}</i>
                            </b>
                          </Box>
                        </Grid>
                      )}
                      <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix passenger-table">
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" className="width_250p table_border padding-item-12 background-color-info subscription-detail list-content">
                              <p className="font-16 font-weight-600 item-content">{t('reservationManagement.passenger')}</p>
                            </TableCell>
                            <TableCell
                              align="center"
                              className={
                                'width_150p table_border padding-item-12 subscription-detail list-content ' +
                                (isBookingAdvance ?
                                  !this.state.isSubInOperatingDateTime ?
                                    ' background-color-secondary' :
                                    ' background-color-info' :
                                  this.state.timeNow < this.state.geofenceTime.start || this.state.timeNow > this.state.geofenceTime.end ?
                                  ' background-color-secondary' :
                                  ' background-color-info')
                              }
                            >
                              <p className="margin-0">
                                <span className="font-16 font-weight-600 item-content">{t('reservationManagement.subscriptions')}</span>
                                {adultSurcharge > 0 && <span className="item-content">({customDisplayCurrency(adultSurcharge, this.props.booking?.currency_code)})</span>}
                              </p>
                              {adultSurcharge > 0 && (
                                <p className="margin-0">
                                  <span className="item-content item-content-ticket">
                                    (
                                    {t('reservationManagement.including_time_range_surcharge', {
                                      surcharge_fee: customDisplayCurrency(adultSurcharge, booking?.currency_code),
                                    })}
                                    )
                                  </span>
                                </p>
                              )}
                            </TableCell>
                            {isBookingAdvance &&
                              this.state.listTicketNotInUse.map((ticket) => {
                                const remainRide = ticket?.ticket_data?.current_rides + ticket?.ticket_data?.prev_rides - ticket?.ticket_data?.times_used;
                                const surcharge_fee = ticket?.ticket_data.ticket_type === TICKET_AMOUNT_TYPE[0].value ? adultSurcharge : childSurcharge;
                                return (
                                  <TableCell align="center" className={'width_150p table_border padding-item-12 subscription-detail list-content background-color-secondary'}>
                                    <p className="margin-0">
                                      <span className="font-16 font-weight-600 item-content item-content-ticket">{ticket.plan_name}</span>
                                      <span className="item-content item-content-ticket">
                                        {t(TICKET_AMOUNT_TYPE.find((type) => type?.value === ticket?.ticket_data.ticket_type)?.i18n) || DATA_NULL}
                                        {surcharge_fee > 0 && <>{' - (' + customDisplayCurrency(surcharge_fee, this.props.booking?.currency_code) + ')'}</>}
                                      </span>
                                      <span className="item-content item-content-ticket">({t('ticket.available_ride', {count: remainRide})})</span>
                                    </p>
                                    {!_.isEmpty(ticket.discount) && (
                                      <>
                                        <p className="margin-0">
                                          <span className="item-content item-content-ticket">
                                            ({t('reservationManagement.discountable_per_tiket', {person: ticket.discount.number_of_user_with_discount})})
                                          </span>
                                        </p>
                                      </>
                                    )}
                                    {surcharge_fee > 0 && (
                                      <p className="margin-0">
                                        <span className="item-content item-content-ticket">
                                          (
                                          {t('reservationManagement.including_time_range_surcharge', {
                                            surcharge_fee: customDisplayCurrency(surcharge_fee, booking?.currency_code),
                                          })}
                                          )
                                        </span>
                                      </p>
                                    )}
                                  </TableCell>
                                );
                              })}

                            {this.state.listTicket?.map((item, index) => {
                              const remainRide = item?.ticket_data?.current_rides + item?.ticket_data?.prev_rides - item?.ticket_data?.times_used;
                              const surcharge_fee = item?.ticket_data.ticket_type === TICKET_AMOUNT_TYPE[0].value ? adultSurcharge : childSurcharge;
                              return (
                                <TableCell
                                  key={index}
                                  align="center"
                                  className={
                                    (!_.isEmpty(item.discount) ? 'discount_column_color width_250p' : 'width_150p') +
                                    ' table_border padding-item-12 subscription-detail list-content ' +
                                    (!isBookingAdvance && (this.state.timeNow < this.state.geofenceTime.start || this.state.timeNow > this.state.geofenceTime.end) ?
                                      'background-color-secondary' :
                                      'background-color-info')
                                  }
                                >
                                  <p className="margin-0">
                                    <span className="font-16 font-weight-600 item-content item-content-ticket">{item.plan_name}</span>
                                    <span className="item-content item-content-ticket">
                                      {t(TICKET_AMOUNT_TYPE.find((type) => type?.value === item?.ticket_data.ticket_type)?.i18n) || DATA_NULL}
                                      {surcharge_fee > 0 && <>{' - (' + customDisplayCurrency(surcharge_fee, this.props.booking?.currency_code) + ')'}</>}
                                    </span>
                                    <span className="item-content item-content-ticket">({t('ticket.available_ride', {count: remainRide})})</span>
                                  </p>
                                  {!_.isEmpty(item.discount) && (
                                    <p className="margin-0">
                                      <span className="item-content item-content-ticket">
                                        ({t('reservationManagement.discountable_per_tiket', {person: item.discount.number_of_user_with_discount})})
                                      </span>
                                    </p>
                                  )}
                                  {surcharge_fee > 0 && (
                                    <p className="margin-0">
                                      <span className="item-content item-content-ticket">
                                        (
                                        {t('reservationManagement.including_time_range_surcharge', {
                                          surcharge_fee: customDisplayCurrency(surcharge_fee, booking?.currency_code),
                                        })}
                                        )
                                      </span>
                                    </p>
                                  )}
                                </TableCell>
                              );
                            })}
                            <TableCell align="center" className="width_150p table_border padding-item-12 background-color-info subscription-detail list-content">
                              <p className="margin-0">
                                <span className="font-16 font-weight-600 item-content">{t('reservationManagement.adult')}</span>
                                <span className="item-content item-content-ticket">
                                  (
                                  {customDisplayCurrency(
                                    booking?.oneTimeConfigInfo?.origin_adult_price +
                                      (booking?.oneTimeConfigInfo?.frame_times?.length > 0 ? booking?.oneTimeConfigInfo?.frame_adult_price : 0) +
                                      (this.state.isBookingAdvance && searchBooking?.advance_without_partner ? advance_booking_fee_adult : 0),
                                    booking?.currency_code,
                                  )}
                                  )
                                </span>
                              </p>
                              {this.state.isBookingAdvance && searchBooking?.advance_without_partner && advance_booking_fee_adult !== 0 && (
                                <p className="margin-0">
                                  <span className="item-content item-content-ticket">
                                    (
                                    {t('reservationManagement.advance.one_time_fee', {
                                      fee: customDisplayCurrency(advance_booking_fee_adult, booking?.currency_code),
                                    })}
                                    )
                                  </span>
                                </p>
                              )}
                              {adultSurcharge !== 0 && (
                                <p className="margin-0">
                                  <span className="item-content item-content-ticket">
                                    (
                                    {t(adultSurcharge > 0 ? 'reservationManagement.including_time_range_surcharge' : 'reservationManagement.including_time_range_discount', {
                                      surcharge_fee: customDisplayCurrency(adultSurcharge, booking?.currency_code),
                                    })}
                                    )
                                  </span>
                                </p>
                              )}
                            </TableCell>
                            <TableCell align="center" className="width_150p table_border padding-item-12 background-color-info subscription-detail list-content">
                              <p className="margin-0">
                                <span className="font-16 font-weight-600 item-content">{t('reservationManagement.child')}</span>
                                <span className="item-content item-content-ticket">
                                  (
                                  {customDisplayCurrency(
                                    booking?.oneTimeConfigInfo?.origin_child_price +
                                      (booking?.oneTimeConfigInfo?.frame_times?.length > 0 ? booking?.oneTimeConfigInfo?.frame_child_price : 0) +
                                      (this.state.isBookingAdvance && searchBooking?.advance_without_partner ? advance_booking_fee_child : 0),
                                    booking?.currency_code,
                                  )}
                                  )
                                </span>
                              </p>
                              {this.state.isBookingAdvance && searchBooking?.advance_without_partner && advance_booking_fee_child !== 0 && (
                                <p className="margin-0">
                                  <span className="item-content item-content-ticket">
                                    (
                                    {t('reservationManagement.advance.one_time_fee', {
                                      fee: customDisplayCurrency(advance_booking_fee_child, booking?.currency_code),
                                    })}
                                    )
                                  </span>
                                </p>
                              )}
                              {childSurcharge !== 0 && (
                                <p className="margin-0">
                                  <span className="item-content item-content-ticket">
                                    (
                                    {t(childSurcharge > 0 ? 'reservationManagement.including_time_range_surcharge' : 'reservationManagement.including_time_range_discount', {
                                      surcharge_fee: customDisplayCurrency(childSurcharge, booking?.currency_code),
                                    })}
                                    )
                                  </span>
                                </p>
                              )}
                            </TableCell>
                            {this.props.searchBooking?.allowInfants === true && (
                              <TableCell align="center" className="width_150p table_border padding-item-12 background-color-info subscription-detail list-content">
                                <p className="margin-0">
                                  <span className="font-16 font-weight-600 item-content">{t('reservationManagement.infant')}</span>
                                  <span className="item-content">({customDisplayCurrency(0, this.props.booking?.currency_code)})</span>
                                </p>
                              </TableCell>
                            )}
                            {this.state.special_prices &&
                              this.state.special_prices.map((price) => {
                                let priceFinal = 0;
                                if (searchBooking?.advance_without_partner) {
                                  priceFinal = price?.price;
                                } else {
                                  if (price?.type === SPECIAL_PERSON_TYPE[0].value) {
                                    priceFinal = price?.origin_price + adultSurcharge;
                                  } else if (price?.type === SPECIAL_PERSON_TYPE[1].value) {
                                    priceFinal = price?.origin_price + childSurcharge;
                                  } else {
                                    priceFinal = price?.origin_price;
                                  }
                                }
                                return (
                                  <TableCell align="center" className="width_150p table_border padding-item-12 background-color-info subscription-detail list-content">
                                    <p className="margin-0">
                                      <span className="font-16 font-weight-600 item-content">{price?.name[language]}</span>
                                      <span className="item-content item-content-ticket">({customDisplayCurrency(priceFinal, this.props.booking?.currency_code)})</span>
                                    </p>
                                    {this.state.isBookingAdvance &&
                                      searchBooking?.advance_without_partner &&
                                      price?.type === SPECIAL_PERSON_TYPE[1].value &&
                                      advance_booking_fee_child !== 0 && (
                                        <>
                                          <p className="margin-0">
                                            <span className="item-content item-content-ticket">
                                              (
                                              {t('reservationManagement.advance.one_time_fee', {
                                                fee: customDisplayCurrency(advance_booking_fee_child, booking?.currency_code),
                                              })}
                                              )
                                            </span>
                                          </p>
                                        </>
                                      )}
                                    {childSurcharge !== 0 && price?.type === SPECIAL_PERSON_TYPE[1].value && (
                                      <p className="margin-0">
                                        <span className="item-content item-content-ticket">
                                          (
                                          {t(childSurcharge > 0 ? 'reservationManagement.including_time_range_surcharge' : 'reservationManagement.including_time_range_discount', {
                                            surcharge_fee: customDisplayCurrency(childSurcharge, booking?.currency_code),
                                          })}
                                          )
                                        </span>
                                      </p>
                                    )}
                                    {this.state.isBookingAdvance &&
                                      searchBooking?.advance_without_partner &&
                                      price?.type === SPECIAL_PERSON_TYPE[0].value &&
                                      advance_booking_fee_adult !== 0 && (
                                        <>
                                          <p className="margin-0">
                                            <span className="item-content item-content-ticket">
                                              (
                                              {t('reservationManagement.advance.one_time_fee', {
                                                fee: customDisplayCurrency(advance_booking_fee_adult, booking?.currency_code),
                                              })}
                                              )
                                            </span>
                                          </p>
                                        </>
                                      )}
                                    {adultSurcharge !== 0 && price?.type === SPECIAL_PERSON_TYPE[0].value && (
                                      <p className="margin-0">
                                        <span className="item-content item-content-ticket">
                                          (
                                          {t(adultSurcharge > 0 ? 'reservationManagement.including_time_range_surcharge' : 'reservationManagement.including_time_range_discount', {
                                            surcharge_fee: customDisplayCurrency(adultSurcharge, booking?.currency_code),
                                          })}
                                          )
                                        </span>
                                      </p>
                                    )}
                                  </TableCell>
                                );
                              })}
                          </TableRow>
                          {!this.state.isBookingAdvance && (this.state.timeNow < this.state.geofenceTime.start || this.state.timeNow > this.state.geofenceTime.end) && (
                            <TableRow>
                              <TableCell className="tablecell-no-border"></TableCell>
                              <TableCell className="tablecell-no-border">
                                {!_.isEmpty(this.state.selectedSubscription) && (
                                  <FormHelperText error>
                                    <Grid className="text_center white_space_nowrap">{t('reservationManagement.plan_out_of_service')}</Grid>
                                    {this.state.listSubNormalTimeOverlap[0]?.map((time) => (
                                      <Grid className="text_center">{time}</Grid>
                                    ))}
                                  </FormHelperText>
                                )}
                              </TableCell>
                              {this.state.listTicket?.map((item, idx) => (
                                <TableCell key={idx} className="tablecell-no-border">
                                  <FormHelperText error>
                                    <Grid className="text_center white_space_nowrap">{t('reservationManagement.plan_out_of_service')}</Grid>
                                    {this.state.listSubTicketTimeOverlap[idx]?.map((time) => (
                                      <Grid className="text_center">{time}</Grid>
                                    ))}
                                  </FormHelperText>
                                </TableCell>
                              ))}
                              <TableCell className="tablecell-no-border"></TableCell>
                              <TableCell className="tablecell-no-border"></TableCell>
                            </TableRow>
                          )}
                          {this.state.isBookingAdvance && (
                            <TableRow>
                              <TableCell className="tablecell-no-border"></TableCell>
                              <TableCell className="tablecell-no-border">
                                {!_.isEmpty(this.state.selectedSubscription) &&
                                  (this.state.isSubInOperatingDate ? (
                                    !this.state.isSubInOperatingTime && (
                                      <FormHelperText error>
                                        <Grid className="text_center white_space_nowrap">{t('reservationManagement.plan_out_of_service')}</Grid>
                                        {this.state.listSubNormalTimeOverlap[0]?.map((time) => (
                                          <Grid className="text_center">{time}</Grid>
                                        ))}
                                      </FormHelperText>
                                    )
                                  ) : (
                                    <FormHelperText error>
                                      <Grid className="text_center">
                                        {t('reservationManagement.advance.valid_operating_of_plan', {
                                          operating_hour: this.displayTextErrorDateInvalid(
                                            this.state.selectedSubscription?.effective_start_time,
                                            this.state.selectedSubscription?.effective_end_time,
                                          ),
                                        })}
                                        {this.state.selectedSubscription?.subscription_shifts?.map((shift) => (
                                          <Grid className="text_center">{this.displayTextErrorTimeInvalid(shift?.local_start_time, shift?.local_end_time)}</Grid>
                                        ))}
                                      </Grid>
                                    </FormHelperText>
                                  ))}
                              </TableCell>
                              {this.state.listTicketNotInUse?.map((item, idx) => (
                                <TableCell key={idx} className="tablecell-no-border">
                                  <FormHelperText error>
                                    {!this.checkTimeInRange(this.state.estmatePickupDateTime, item?.effective_start_time, item?.effective_end_time) ? (
                                      <>
                                        <Grid className="text_center">
                                          {t('reservationManagement.advance.valid_operating_of_plan', {
                                            operating_hour: this.displayTextErrorDateInvalid(item?.effective_start_time, item?.effective_end_time),
                                          })}
                                          {item?.subscription_shifts?.map((shift) => (
                                            <Grid className="text_center">{this.displayTextErrorTimeInvalid(shift?.local_start_time, shift?.local_end_time)}</Grid>
                                          ))}
                                        </Grid>
                                      </>
                                    ) : (
                                      <>
                                        <Grid className="text_center white_space_nowrap">{t('reservationManagement.plan_out_of_service')}</Grid>
                                        {item?.subscription_shifts.map((sub) => (
                                          <Grid className="text_center">
                                            {this.getTimeOverlap(sub?.local_start_time, sub?.local_end_time, this.state.geofenceStartTime, this.state.geofenceEndTime)}
                                          </Grid>
                                        ))}
                                      </>
                                    )}
                                  </FormHelperText>
                                </TableCell>
                              ))}
                              <TableCell className="tablecell-no-border"></TableCell>
                              <TableCell className="tablecell-no-border"></TableCell>
                            </TableRow>
                          )}
                          {this.state.passenger_detail?.map((element, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell align="center" className="padding-item-12 background-color-light tablecell-no-border">
                                  <span>{element.last_name + ' ' + element.first_name}</span>
                                </TableCell>
                                <TableCell align="center" className="padding-item-12 background-color-light tablecell-no-border">
                                  <Radio
                                    checked={
                                      isBookingAdvance ?
                                        element.using === true :
                                        element.using === true && this.state.timeNow >= this.state.geofenceTime.start && this.state.timeNow <= this.state.geofenceTime.end
                                    }
                                    disabled={true}
                                    value={element.id}
                                  />
                                </TableCell>
                                {isBookingAdvance &&
                                  this.state.listTicketNotInUse.map((ticket) => (
                                    <TableCell
                                      align="center"
                                      key={index}
                                      className={
                                        (!_.isEmpty(ticket.discount) ? 'discount_column_color width_250p' : 'background-color-light width_150p') +
                                        'discount_column_color padding-item-12 tablecell-no-border'
                                      }
                                    >
                                      <Radio disabled={true} />
                                    </TableCell>
                                  ))}
                                {this.state.listTicket?.map((item, index) => (
                                  <TableCell
                                    align="center"
                                    key={index}
                                    className={
                                      (!_.isEmpty(item.discount) ? 'discount_column_color width_250p' : 'background-color-light width_150p') +
                                      'discount_column_color padding-item-12 tablecell-no-border'
                                    }
                                  >
                                    <Radio disabled={element.using === true} />
                                  </TableCell>
                                ))}
                                <TableCell align="center" className="padding-item-12 background-color-light tablecell-no-border">
                                  <Radio
                                    disabled={element.using === true && this.state.timeNow >= this.state.geofenceTime.start && this.state.timeNow <= this.state.geofenceTime.end}
                                    value={1}
                                  />
                                </TableCell>
                                <TableCell align="center" className="padding-item-12 background-color-light tablecell-no-border">
                                  <Radio
                                    disabled={element.using === true && this.state.timeNow >= this.state.geofenceTime.start && this.state.timeNow <= this.state.geofenceTime.end}
                                    value={2}
                                  />
                                </TableCell>
                                {this.props.searchBooking?.allowInfants === true && (
                                  <TableCell align="center" className="padding-item-12 background-color-light tablecell-no-border">
                                    <Radio disabled={true} />
                                  </TableCell>
                                )}
                                {this.state.special_prices &&
                                  this.state.special_prices.map((price, idx) => {
                                    return (
                                      <TableCell align="center" className="padding-item-12 background-color-light tablecell-no-border">
                                        <Radio
                                          disabled={
                                            element.using === true && this.state.timeNow >= this.state.geofenceTime.start && this.state.timeNow <= this.state.geofenceTime.end
                                          }
                                          checked={Number(element.status) === CONFIG_NUMBER + idx}
                                          value={CONFIG_NUMBER + idx}
                                          onChange={(event) => this.onChangeTicketPrice(event, index)}
                                        />
                                      </TableCell>
                                    );
                                  })}
                              </TableRow>
                            );
                          })}
                          {this.state.non_member_list?.map((element, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell style={{minWidth: 'max-content'}} align="center" className="padding-item-12 background-color-light tablecell-no-border">
                                  <span>{`${t('reservationManagement.passenger')} ${index + 1}`}</span>
                                </TableCell>
                                <TableCell align="center" className="padding-item-12 background-color-light tablecell-no-border">
                                  <Radio disabled={true} value={0} />
                                </TableCell>
                                {isBookingAdvance &&
                                  this.state.listTicketNotInUse.map((ticket) => (
                                    <TableCell
                                      align="center"
                                      key={index}
                                      className={
                                        (!_.isEmpty(ticket.discount) ? 'discount_column_color width_250p' : 'background-color-light width_150p') +
                                        'discount_column_color padding-item-12 tablecell-no-border'
                                      }
                                    >
                                      <Radio disabled={true} />
                                    </TableCell>
                                  ))}
                                {this.state.listTicket?.map((item, idx) => {
                                  const remainRide = item?.ticket_data?.current_rides + item?.ticket_data?.prev_rides - item?.ticket_data?.times_used;
                                  return (
                                    <TableCell
                                      align="center"
                                      key={idx}
                                      className={
                                        (!_.isEmpty(item.discount) ? 'discount_column_color width_250p' : 'background-color-light width_150p') +
                                        ' padding-item-12 tablecell-no-border'
                                      }
                                    >
                                      <Radio
                                        checked={
                                          Number(element.status) === idx + 3 &&
                                          this.state.timeNow >= this.state.geofenceTime.start &&
                                          this.state.timeNow <= this.state.geofenceTime.end
                                        }
                                        disabled={
                                          (Number(element.status) !== idx + 3 && this.state.non_member_list.filter((el) => Number(el.status) === idx + 3).length >= remainRide) ||
                                          this.state.timeNow < this.state.geofenceTime.start ||
                                          this.state.timeNow > this.state.geofenceTime.end
                                        }
                                        value={idx + 3}
                                        onChange={(event) => this.onChangeTicketPrice(event, index)}
                                      />
                                    </TableCell>
                                  );
                                })}
                                <TableCell align="center" className="padding-item-12 background-color-light tablecell-no-border">
                                  <Radio
                                    disabled={Number(element.status) === 0}
                                    checked={Number(element.status) === 1}
                                    value={1}
                                    onChange={(event) => this.onChangeTicketPrice(event, index)}
                                  />
                                </TableCell>
                                <TableCell align="center" className="padding-item-12 background-color-light tablecell-no-border">
                                  <Radio
                                    disabled={Number(element.status) === 0}
                                    checked={Number(element.status) === 2}
                                    value={2}
                                    onChange={(event) => this.onChangeTicketPrice(event, index)}
                                  />
                                </TableCell>
                                {this.props.searchBooking?.allowInfants === true && (
                                  <TableCell align="center" className="padding-item-12 background-color-light tablecell-no-border">
                                    <Radio disabled={true} />
                                  </TableCell>
                                )}
                                {this.state.special_prices &&
                                  this.state.special_prices.map((price, idx) => {
                                    return (
                                      <TableCell align="center" className="padding-item-12 background-color-light tablecell-no-border">
                                        <Radio
                                          disabled={
                                            element.using === true && this.state.timeNow >= this.state.geofenceTime.start && this.state.timeNow <= this.state.geofenceTime.end
                                          }
                                          checked={Number(element.status) === CONFIG_NUMBER + idx}
                                          value={CONFIG_NUMBER + idx}
                                          onChange={(event) => this.onChangeTicketPrice(event, index)}
                                        />
                                      </TableCell>
                                    );
                                  })}
                              </TableRow>
                            );
                          })}
                          {this.state.infantList?.map((element, index) => (
                            <TableRow key={index + this.state.passenger_detail?.length + this.state.non_member_list?.length}>
                              <TableCell align="center" className="padding-item-12 background-color-light tablecell-no-border">
                                <span>{`${t('reservationManagement.passenger')} ${index + (this.state.passenger_detail?.length || 1) + this.state.non_member_list?.length}`}</span>
                              </TableCell>
                              <TableCell align="center" className="padding-item-12 background-color-light tablecell-no-border">
                                <Radio disabled={true} />
                              </TableCell>
                              {isBookingAdvance &&
                                this.state.listTicketNotInUse.map((ticket) => (
                                  <TableCell
                                    align="center"
                                    key={index}
                                    className={
                                      (!_.isEmpty(ticket.discount) ? 'discount_column_color width_250p' : 'background-color-light width_150p') +
                                      'discount_column_color padding-item-12 tablecell-no-border'
                                    }
                                  >
                                    <Radio disabled={true} />
                                  </TableCell>
                                ))}
                              {this.state.listTicket?.map((item, idx) => {
                                return (
                                  <TableCell
                                    align="center"
                                    key={idx}
                                    className={
                                      (!_.isEmpty(item.discount) ? 'discount_column_color width_250p' : 'background-color-light width_150p') +
                                      ' padding-item-12 tablecell-no-border'
                                    }
                                  >
                                    <Radio disabled={true} value={idx + 3} />
                                  </TableCell>
                                );
                              })}
                              <TableCell align="center" className="padding-item-12 background-color-light tablecell-no-border">
                                <Radio disabled={true} value={1} />
                              </TableCell>
                              <TableCell align="center" className="padding-item-12 background-color-light tablecell-no-border">
                                <Radio disabled={true} value={2} />
                              </TableCell>
                              <TableCell align="center" className="padding-item-12 background-color-light tablecell-no-border">
                                <Radio disabled={true} checked={Number(element.status) === 0} value={0} onChange={(event) => this.onChangeTicketPrice(event, index)} />
                              </TableCell>
                              {this.state.special_prices &&
                                this.state.special_prices.map((price, idx) => {
                                  return (
                                    <TableCell align="center" className="padding-item-12 background-color-light tablecell-no-border">
                                      <Radio disabled={true} value={CONFIG_NUMBER + idx} />
                                    </TableCell>
                                  );
                                })}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      {!checkInfantByGeofenceValid && (
                        <Grid container alignItems="center" item xs={12} lg={12} className="font_color_red">
                          {t('reservationManagement.infantInvalid')}
                        </Grid>
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Grid container item alignItems="center" justifyContent="center" xs={12} lg={12}>
                  <Grid container item alignItems="center" justifyContent="center" xs={12} style={{paddingTop: 20}}>
                    <Button
                      disabled={
                        !this.validator.allValid() ||
                        !checkInfantByGeofenceValid ||
                        isCheckSub ||
                        (this.state.payment_methods?.length === 0 && !this.state.isCheckCreditCard && fee_total !== 0)
                      }
                      color="primary"
                      variant="contained"
                      className="button_margin"
                      onClick={() => this.toPageConfirm(numberDiscountAdult, numberDiscountChild, fee_total)}
                    >
                      {t('common.btnConfirm')}
                    </Button>
                  </Grid>
                </Grid>
                <br></br>
              </Container>
            </Card>
          </Box>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    booking: state.reservation.bookingInfo,
    searchBooking: state.reservation.searchBooking,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateBooking: (bookingData) => {
      dispatch(updateBooking(bookingData));
    },
    getMemberSubscription: (user_id, geofence_id) => dispatch(getMemberSubscription(user_id, geofence_id)),
    searchBookingUpdate: (data) => dispatch(searchBookingUpdate(data)),
    checkOwnerCard: (id) => dispatch(checkOwnerCard(id)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(PassengerDetail));
