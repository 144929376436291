import {api} from '../services/api';

const getListFacilityUrl = `admin/suppliers/facilities/search`;
const crudFacilityUrl = 'admin/suppliers/facilities';

export const getListFacilityApi = (payload, queryParams) => {
  return api.search(getListFacilityUrl, queryParams, payload);
};

export const createFacilityApi = (payload) => {
  return api.post(crudFacilityUrl, payload);
};

export const getDetailFacilityApi = (id) => {
  return api.get(crudFacilityUrl + '/' + id);
};

export const updateFacilityApi = (payload) => {
  return api.put(crudFacilityUrl, payload);
};

export const deleteFacilityApi = (id) => {
  return api.delete(crudFacilityUrl + '/' + id);
};
