import React, {Component} from 'react';

import {
  Card,
  Container,
  Grid,
  Paper,
  Table,
  TableCell,
  TableRow,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  TableHead,
  TableBody,
  Checkbox,
  Modal,
  Backdrop,
  Dialog,
} from '@material-ui/core';
import {IconButton} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import DetailIcon from '@material-ui/icons/Description';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import SendIcon from '@material-ui/icons/Send';
import StorageIcon from '@material-ui/icons/Storage';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {ExportToCsv} from 'export-to-csv';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {ROUTE, ROWS_PER_PAGE_OPTIONS, EXPORT_LIMIT_NUMBER_ROWS, JIT_STATUS, DATA_NULL, MAX_SIZE_OUTPUT, PERMISSION_ACTIONS} from '../../../common/constant';
import CSVExporter from '../../../components/CSVExporter';
import CustomPagination from '../../../components/CustomPagination';
import ImportCSV from '../../../components/importCSV';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {
  searchListJitOrderApi,
  getJitShiftApi,
  deleteJitOrderlApi,
  bookingJitOrdersApi,
  getJitAddresstApi,
  getListSupplierByCountryApi,
  getJitOrderDetailApi,
  createJitOrderApi,
} from '../../../services/jitOrderServices.js';
import {getAllJitGeofence, getAllCountryCodeWithGeofence} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {onChangeSelect, onChangeTextField, changeUrlParams, getUrlParams, getQueryStringFromObject, onChangeListData} from '../../../utils/common';
import {formatUtc, parseDateTimeUTC} from '../../../utils/date_time_utils';
import {modalObj} from '../../../utils/modal';
import FormComponent from './FormComponent';
import MasterData from './MasterData';

/**
 * JIT information
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      idDelete: null,
      flagDelete: false,
      geofence_id: '',
      country_id: 1,
      customer_name: '',
      pickup_address: '',
      drop_off_address: '',
      pickup_date_from: null,
      pickup_date_to: null,
      drop_off_date_from: null,
      drop_off_date_to: null,
      phone: '',
      email: '',
      status: '',
      result: [],
      isSearch: false,
      index: '',
      created_date_from: null,
      created_date_to: null,
      listGeofenceAll: [],
      detailId: null,
      business_id: '',
      stop_points: [],
      all_pickup_address: [],
      all_drop_off_address: [],
      supplier_list: [],
      all_jit_time: [],
      time: '',
      date: null,
      isCompany: true,

      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,
      flagExport: false,
      latestSearchParams: null,
      flagForm: false,
      flagImport: false,
      flagSend: false,
      exportHeaders: [],
      isTemplate: false,
      flagMasterData: false,
      flagClone: false,
      idClone: null,
      checkedAll: false,
    };
    this.handleButtonOk = this.handleButtonOk.bind(this);
    this.handleButtonCancel = this.handleButtonCancel.bind(this);
    this.exportCSVRef = React.createRef();
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllCountryCodeWithGeofence();
    const supplier_list = await getListSupplierByCountryApi({country_id: null});
    const business_id = supplier_list.result.length === 1 ? supplier_list.result[0].id : this.state.business_id;
    this.setState({
      supplier_list: supplier_list.result,
      business_id,
      isCompany: supplier_list.result.length === 1,
    });
    await this.props.getAllJitGeofence({country_id: null}).then(() => {
      this.setState({
        listGeofenceAll: this.props.common?.all_jit_geofence,
      });
    });
    if (this.props.location?.state?.detailId && this.props.actions.includes(PERMISSION_ACTIONS.EDIT)) {
      this.openModalForm(this.props.location?.state?.detailId);
    }
    if (!this.props.location.search && this.state.geofence_id) {
      this.getAllPickUpAddress();
      this.getAllDropOffAddress();
    }
    if (this.props.location.search) {
      this.setState(getUrlParams(this.props.location.search), () => {
        this.getTimeBooking();
        this.handleSearch();
      });
    }
  }

  /**
   * handleButtonOk
   */
  handleButtonOk() {
    this.setState({
      flagDelete: false,
    });
    deleteJitOrderlApi(this.state.idDelete).then(() => {
      const result = this.state.result;
      result.splice(this.state.index, 1);
      this.setState({
        result,
      });
    });
  }

  /**
   * handleButtonCancel
   */
  handleButtonCancel() {
    this.setState({
      flagDelete: false,
    });
  }

  /**
   * handleButtonClose
   */
  handleButtonClose = () => {
    this.setState({
      flagForm: false,
    });
  };

  /**
   * handleDelete
   * @param {int} idDelete
   * @param {object} index
   */
  handleDelete = (idDelete, index) => {
    this.setState({
      flagDelete: true,
      message: 'jit.confirm.detete',
      idDelete,
      index,
    });
  };

  /**
   * handleClone
   * @param {*} idClone
   */
  handleClone = (idClone) => {
    this.setState({
      flagClone: true,
      message: 'jit.confirm.clone',
      idClone,
    });
  };

  /**
   * handleButtonCloneOk
   */
  handleButtonCloneOk = async () => {
    const data = await getJitOrderDetailApi(this.state.idClone);
    const {
      geofence_id,
      country_id,
      first_name,
      last_name,
      pickup_time,
      drop_off_time,
      phone,
      number_of_users,
      email,
      business_id,
      pickup_date,
      drop_off_date,
      pickup_address_id,
      drop_off_address_id,
    } = data.result;
    const payload = {
      geofence_id,
      country_id,
      first_name,
      last_name,
      pickup_address_id,
      drop_off_address_id,
      pickup_date,
      drop_off_date,
      pickup_time,
      drop_off_time,
      phone,
      number_of_users,
      email,
      business_id,
    };
    const result_clone = await createJitOrderApi(payload);
    if (result_clone.status === 200) {
      this.setState({
        flagClone: false,
      });
      this.handleSearch();
      this.props.setMessageModal(modalObj(true, 'api.clone.jit.order.success'));
    }
  };

  /**
   * handleCheckedAll
   * @param {*} checked
   */
  handleCheckedAll = (checked) => {
    this.setState(
      {
        checkedAll: checked,
      },
      () => {
        const data = this.state.result;
        for (let index = 0; index < data.length; index++) {
          const jit = data[index];
          if (jit.status !== 'SENT' && jit.import_errors.length === 0 && jit.tejimai_time && jit.tejimai_time > new Date().toISOString()) {
            jit['checked'] = checked;
          }
        }
        this.setState({
          result: data,
        });
      },
    );
  };

  /**
   * handleOnChangeCountry
   * @param {*} value
   */
  handleOnChangeCountry = async (value) => {
    this.setState({
      country_id: value,
      geofence_id: '',
      all_pickup_address: [],
      all_drop_off_address: [],
      pickup_address: '',
      drop_off_address: '',
      business_id: this.state.isCompany ? this.state.business_id : '',
      time: '',
      all_jit_time: [],
      supplier_id: '',
    });
  };

  /**
   * getPayloadSearch
   * @return {*}
   */
  getPayloadSearch = () => {
    const {
      customer_name,
      pickup_address,
      drop_off_address,
      pickup_date_from,
      pickup_date_to,
      drop_off_date_from,
      drop_off_date_to,
      phone,
      email,
      status,
      created_date_from,
      created_date_to,
      geofence_id,
      country_id,
      business_id,
      date,
      time,
    } = this.state;
    const payload = {
      customer_name: customer_name ? customer_name.trim() : null,
      pickup_address_id: pickup_address,
      drop_off_address_id: drop_off_address,
      pickup_date_from: parseDateTimeUTC(pickup_date_from),
      pickup_date_to: parseDateTimeUTC(pickup_date_to),
      drop_off_date_from: parseDateTimeUTC(drop_off_date_from),
      drop_off_date_to: parseDateTimeUTC(drop_off_date_to),
      phone: phone ? phone.trim() : null,
      email: email ? email.trim() : null,
      status: status || null,
      created_date_from: created_date_from ? new Date(created_date_from) : null,
      created_date_to: created_date_to ? new Date(created_date_to) : null,
      geofence_id,
      country_id,
      business_id,
      date: parseDateTimeUTC(date),
      time,
      zone_id: 'Asia/Saigon',
    };
    if (payload.created_date_from) payload.created_date_from = parseDateTimeUTC(payload.created_date_from) + 'T00:00:00.000Z';
    if (payload.created_date_to) payload.created_date_to = parseDateTimeUTC(payload.created_date_to) + 'T23:59:59.000Z';
    return payload;
  };

  /**
   * handleSearch
   * @param {bool} reset
   */
  handleSearch = (reset) => {
    if (!this.props.actions.includes(PERMISSION_ACTIONS.SEARCH)) return;
    this.setState({
      checkedAll: false,
    });
    const payload = this.getPayloadSearch();
    if (this.state.geofence_id) {
      this.getAllPickUpAddress();
      this.getAllDropOffAddress();
    }
    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const {currentPage, rowsPerPage} = this.state;
    let queryParams = reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage};
    queryParams = {
      ...payload,
      ...queryParams,
    };
    searchListJitOrderApi(queryParams).then((response) => {
      if (response && response.status === 200) {
        this.setState(
          {
            result: response?.result.content.map((item) => ({
              ...item,
              checked: false,
            })),
            totalRows: response?.result.totalSize,
            isSearch: true,
            latestSearchParams: payload,
          },
          () => {
            const {
              geofence_id,
              country_id,
              customer_name,
              pickup_address,
              drop_off_address,
              pickup_date_from,
              pickup_date_to,
              drop_off_date_from,
              drop_off_date_to,
              phone,
              email,
              created_date_from,
              created_date_to,
              status,
              business_id,
              currentPage,
              rowsPerPage,
              date,
              time,
            } = this.state;

            const queryParamsToChange = {
              geofence_id,
              country_id,
              customer_name,
              pickup_address,
              drop_off_address,
              pickup_date_from,
              pickup_date_to,
              drop_off_date_from,
              drop_off_date_to,
              phone,
              email,
              created_date_from,
              created_date_to,
              status,
              business_id,
              date,
              time,
              currentPage: String(currentPage),
              rowsPerPage,
            };
            changeUrlParams(queryParamsToChange);
            const newSearchParams = getQueryStringFromObject(queryParamsToChange);
            // Save search params into state in order to pass to next page
            this.setState({searchParams: newSearchParams});
          },
        );
      } else {
        this.props.setMessageModal(modalObj(true, response.message_code));
        this.setState({
          result: [],
        });
      }
    });
  };

  /**
   * getTimeBooking
   */
  getTimeBooking = () => {
    if (this.state.geofence_id && this.state.date) {
      this.setState({
        all_jit_time: [],
      });
      Promise.all([
        getJitShiftApi({
          geofence_id: this.state.geofence_id,
          booking_date: new Date(this.state.date).toISOString().substring(0, 10),
          vehicle_group_type: 'SHUTTLE_BUS_JIT_WORK_TO_HOME',
        }),
        getJitShiftApi({
          geofence_id: this.state.geofence_id,
          booking_date: new Date(this.state.date).toISOString().substring(0, 10),
          vehicle_group_type: 'SHUTTLE_BUS_JIT_HOME_TO_WORK',
        }),
      ]).then((res) => {
        const wth = res[0].result.map((item) => formatUtc(item['start_time'], 'HH:mm', item['zone_id']));
        const htw = res[0].result.map((item) => formatUtc(item['end_time'], 'HH:mm', item['zone_id']));
        const all_jit_time = Array.from(new Set(htw.concat(wth)));
        all_jit_time.sort();
        this.setState({
          all_jit_time,
        });
      });
    }
  };

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearch);
  };

  /**
   * getAllPickUpAddress
   */
  getAllPickUpAddress = () => {
    getJitAddresstApi({
      geofence_id: this.state.geofence_id,
      address_id: this.state.drop_off_address || null,
      pickup_address: true,
    }).then((res) =>
      this.setState({
        all_pickup_address: res.result || [],
      }),
    );
  };

  /**
   * getAllDropOffAddress
   */
  getAllDropOffAddress = () => {
    getJitAddresstApi({
      geofence_id: this.state.geofence_id,
      address_id: this.state.pickup_address || null,
      pickup_address: false,
    }).then((res) =>
      this.setState({
        all_drop_off_address: res.result || [],
      }),
    );
  };

  /**
   * onChangeSelectGeofence
   * @param {*} id
   */
  onChangeSelectGeofence = (id) => {
    this.setState(
      {
        geofence_id: id,
        pickup_address: '',
        drop_off_address: '',
        time: '',
        all_jit_time: [],
      },
      () => {
        this.getAllPickUpAddress();
        this.getAllDropOffAddress();
        this.getTimeBooking();
      },
    );
  };

  /**
   * reset
   */
  reset = () => {
    this.setState(
      {
        geofence_id: '',
        country_id: 1,
        customer_name: '',
        pickup_address: '',
        drop_off_address: '',
        pickup_date_from: null,
        pickup_date_to: null,
        drop_off_date_from: null,
        drop_off_date_to: null,
        phone: '',
        email: '',
        status: '',
        created_date_from: null,
        created_date_to: null,
        business_id: this.state.isCompany ? this.state.business_id : '',
        date: null,
        time: '',
        all_jit_time: [],
      },
      () => {
        this.getAllPickUpAddress();
        this.getAllDropOffAddress();
      },
    );
  };

  /**
   * Fetch data to export
   */
  fetchDataExport = async () => {
    const {t, common} = this.props;
    this.setState({
      exportHeaders: [
        {label: t('common.no'), key: 'no'},
        {label: t('jit.country.code'), key: 'country'},
        {label: t('jit.geofence.code'), key: 'geofence'},
        {label: t('businessDetail.id'), key: 'business_id'},
        {label: t('reservationManagement.memberName'), key: 'customer_name'},
        {label: t('jit.pickup_address.code'), key: 'pickup_address_code'},
        {label: t('jit.dropoff_address.code'), key: 'drop_off_address_code'},
        {label: t('reservationManagement.pickup_date'), key: 'pickup_date'},
        {label: t('reservationManagement.pickup_time'), key: 'pickup_time'},
        {label: t('reservationManagement.dropoff_date'), key: 'drop_off_date'},
        {label: t('reservationManagement.dropoff_time'), key: 'drop_off_time'},
        {label: t('reservationManagement.number_people'), key: 'number_of_users'},
        {label: t('common.phoneNumber'), key: 'phone'},
        {label: t('common.email'), key: 'email'},
      ],
    });
    let convertedData = [];
    const queryParams = {...this.getPayloadSearch(), page: 0, size: MAX_SIZE_OUTPUT};
    const dataExport = await searchListJitOrderApi(queryParams);
    convertedData = dataExport.result.content.map((item, idx) => {
      item['no'] = idx + 1;
      item['business_id'] = this.state.supplier_list?.find((row) => row.id + '' === item.business_id + '')?.supplier_id;
      item['country'] = common.country_code?.find((row) => row.id + '' === item['country_id'] + '')?.country_code;
      item['geofence'] = common.all_jit_geofence?.find((row) => row.geofence_id + '' === item['geofence_id'] + '')?.code;
      item['customer_name'] = item['last_name'] + ' ' + item['first_name'];
      return item;
    });
    return convertedData;
  };

  /**
   * alertExportCSV
   */
  alertExportCSV = () => {
    if (this.state.totalRows > EXPORT_LIMIT_NUMBER_ROWS) {
      this.props.setMessageModal(modalObj(true, 'messageCode.exportAlertLimit'));
    } else {
      this.setState({flagExport: true, message: 'messageCode.exportAlert'});
    }
  };

  /**
   * handleButtonExportOk
   * @param {*} isTemplate
   * @param {*} countryCode
   */
  handleButtonExportOk = (isTemplate = false, countryCode = 'VN') => {
    this.setState({flagExport: false, isTemplate}, () => {
      if (isTemplate) {
        const {t} = this.props;
        const data = [
          {
            no: 1,
            country: countryCode,
            geofence: '23',
            business_id: 'BBIF484416',
            last_name: 'A',
            first_name: 'A',
            pickup_address_code: '1146774',
            drop_off_address_code: '1146769',
            pickup_date: new Date().toISOString().substring(0, 10),
            pickup_time: '10:00',
            drop_off_date: '',
            drop_off_time: '',
            number_of_users: 1,
            phone: '+841234567879',
            email: 'xx@gmail.com',
          },
        ];
        const options = {
          fieldSeparator: ',',
          quoteStrings: '',
          decimalSeparator: '.',
          showLabels: true,
          filename: 'template',
          headers: [
            t('common.no'),
            t('jit.country.code'),
            t('jit.geofence.code'),
            t('businessDetail.id'),
            t('updateMember.lastName'),
            t('updateMember.firstName'),
            t('jit.pickup_address.code'),
            t('jit.dropoff_address.code'),
            t('reservationManagement.pickup_date'),
            t('reservationManagement.pickup_time'),
            t('reservationManagement.dropoff_date'),
            t('reservationManagement.dropoff_time'),
            t('reservationManagement.number_people'),
            t('common.phoneNumber'),
            t('common.email'),
          ],
        };
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data);
      } else {
        this.exportCSVRef.current.onClickExport();
      }
    });
  };

  /**
   * handleButtonExportCancel
   */
  handleButtonExportCancel = () => {
    this.setState({flagExport: false});
  };

  /**
   * openModalForm
   * @param {*} detailId
   */
  openModalForm = (detailId = null) => {
    this.setState({
      flagForm: true,
      detailId: detailId,
    });
  };

  /**
   * openModalSend
   */
  openModalSend = () => {
    this.setState({
      flagSend: true,
      message: 'jit.confirm.send',
    });
  };

  /**
   * sendJitOrder
   */
  sendJitOrder = async () => {
    const jit_order_ids = [];
    for (let index = 0; index < this.state.result.length; index++) {
      const jit = this.state.result[index];
      if (jit.checked && jit.status === 'DRAFT') {
        jit_order_ids.push(jit.id);
      }
    }
    const res = await bookingJitOrdersApi({jit_order_ids});
    const jit_errors = [];
    for (let index = 0; index < res.length; index++) {
      if (res[index].status !== 200) {
        jit_errors.push(this.state.result.findIndex((e) => e.id === res[index]['result']['jit_order_id']) + 1);
      }
    }
    jit_errors.sort();
    this.setState({
      flagSend: false,
    });
    this.handleSearch();
    if (jit_errors.length === 0) {
      this.props.setMessageModal(modalObj(true, 'api.send.jit.order.success'));
    } else {
      this.props.setMessageModal(modalObj(true, 'api.send.jit.order.faulty', {number: jit_errors.join(', '), noun: jit_errors.length === 1 ? 'is' : 'are'}));
    }
  };

  /**
   * checkColumnError
   * @param {*} row
   * @param {*} key1
   * @param {*} key2
   * @return {*}
   */
  checkColumnError = (row, key1, key2 = null) => {
    if (row.import_errors.includes(key1)) {
      return {
        color: 'red',
      };
    }
    if (key2 && row.import_errors.includes(key2)) {
      return {
        color: 'red',
      };
    }
    return {};
  };

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {t, common, actions} = this.props;
    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canCSVOutput: actions.includes(PERMISSION_ACTIONS.CSV_OUTPUT),
      canCSVImport: actions.includes(PERMISSION_ACTIONS.CSV_IMPORT),
      canRegister: actions.includes(PERMISSION_ACTIONS.REGISTER),
      canViewJitMasterData: actions.includes(PERMISSION_ACTIONS.JIT_MASTER_DATA),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
      canDelete: actions.includes(PERMISSION_ACTIONS.DELETE),
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
      canSend: actions.includes(PERMISSION_ACTIONS.SEND),
      canClone: actions.includes(PERMISSION_ACTIONS.CLONE),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container spacing={1} className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('jit.title')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.JIT_SETTING}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          <Card raised>
            <Container maxWidth="xl">
              {permission.canSearch && (
                <>
                  <br></br>
                  <Paper className="search_table">
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                        {t('businessInformation.searchTitle')}
                      </Grid>
                    </Grid>
                    {/* Country */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('common.country')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'country_id',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.country_id ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('common.country'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.country_id}
                            onChange={(event) => this.handleOnChangeCountry(event.target.value)}
                          >
                            {common?.country_code?.map((item, idx) => (
                              <MenuItem value={item.id} key={idx}>
                                {t(`${item.country_code}`)}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* Geofence */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('business_plan.geofence')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'geofence_id',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.geofence_id ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('business_plan.geofence'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.geofence_id}
                            onChange={(e) => this.onChangeSelectGeofence(e.target.value)}
                          >
                            {this.state.listGeofenceAll
                              .filter((item) => !this.state.country_id || item.country_id + '' === this.state.country_id + '')
                              ?.map((item, idx) => {
                                return (
                                  <MenuItem value={item.geofence_id} key={idx}>
                                    {t(`${item.name}`)}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* Business name */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('reservationManagement.business_name')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'business_id',
                            }}
                            disabled={this.state.isCompany}
                            displayEmpty
                            renderValue={
                              this.state.business_id ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('reservationManagement.business_name'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.business_id}
                            onChange={(e) => onChangeSelect(this, e)}
                          >
                            {this.state.supplier_list
                              .filter((item) => item.country_id + '' === this.state.country_id + '')
                              .map((item, idx) => {
                                return (
                                  <MenuItem value={item.id} key={idx}>
                                    {t(`${item.supplier_name}`)}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* Customer Name */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('reservationManagement.memberName')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <TextField
                          name="customer_name"
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('reservationManagement.memberName')})}
                          value={this.state.customer_name}
                          onChange={(event) => onChangeTextField(this, event)}
                          inputProps={{maxLength: 256}}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>

                    {/* Pickup Address */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('reservationManagement.pickup_address_search')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl variant="outlined" margin="dense">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'pickup_address',
                            }}
                            className="field_size_10 field_min_size_300"
                            displayEmpty
                            renderValue={
                              this.state.pickup_address ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('reservationManagement.pickup_address_search'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.pickup_address}
                            onChange={(e) => this.setState({pickup_address: e.target.value}, () => this.getAllDropOffAddress())}
                          >
                            {this.state.all_pickup_address?.map((item, idx) => {
                              return (
                                <MenuItem value={item.id} key={idx}>
                                  {t(`${item.name}`)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* Dropoff Address */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('reservationManagement.dropoff_address_search')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl variant="outlined" margin="dense">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'drop_off_address',
                            }}
                            className="field_size_10 field_min_size_300"
                            displayEmpty
                            renderValue={
                              this.state.drop_off_address ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('reservationManagement.dropoff_address_search'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.drop_off_address}
                            onChange={(e) => this.setState({drop_off_address: e.target.value}, () => this.getAllPickUpAddress())}
                          >
                            {this.state.all_drop_off_address?.map((item, idx) => {
                              return (
                                <MenuItem value={item.id} key={idx}>
                                  {t(`${item.name}`)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* Pickup date */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('reservationManagement.pickup_date')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={6}>
                        <KeyboardDatePicker
                          autoOk
                          inputVariant="outlined"
                          name="pickup_date_from"
                          disableToolbar
                          variant="inline"
                          className="field_size_10 field_min_size_300"
                          format="yyyy/MM/dd"
                          margin="dense"
                          label={t('common.from')}
                          value={this.state.pickup_date_from}
                          onChange={(time) =>
                            this.setState({
                              pickup_date_from: time,
                            })
                          }
                          maxDate={this.state.pickup_date_to || undefined}
                        />
                        <span style={{padding: '10px'}}></span>
                        <KeyboardDatePicker
                          autoOk
                          inputVariant="outlined"
                          name="pickup_date_to"
                          className="field_size_10 field_min_size_300"
                          disableToolbar
                          variant="inline"
                          format="yyyy/MM/dd"
                          margin="dense"
                          label={t('common.to')}
                          value={this.state.pickup_date_to}
                          onChange={(time) =>
                            this.setState({
                              pickup_date_to: time,
                            })
                          }
                          minDate={this.state.pickup_date_from || undefined}
                        />
                      </Grid>
                    </Grid>

                    {/* Dropoff date */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('reservationManagement.dropoff_date')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={6}>
                        <KeyboardDatePicker
                          autoOk
                          inputVariant="outlined"
                          name="drop_off_date_from"
                          className="field_size_10 field_min_size_300"
                          disableToolbar
                          variant="inline"
                          format="yyyy/MM/dd"
                          margin="dense"
                          label={t('common.from')}
                          value={this.state.drop_off_date_from}
                          onChange={(time) =>
                            this.setState({
                              drop_off_date_from: time,
                            })
                          }
                          maxDate={this.state.drop_off_date_to || undefined}
                        />
                        <span style={{padding: '10px'}}></span>
                        <KeyboardDatePicker
                          autoOk
                          className="field_size_10 field_min_size_300"
                          inputVariant="outlined"
                          name="drop_off_date_to"
                          disableToolbar
                          variant="inline"
                          format="yyyy/MM/dd"
                          margin="dense"
                          label={t('common.to')}
                          value={this.state.drop_off_date_to}
                          onChange={(time) =>
                            this.setState({
                              drop_off_date_to: time,
                            })
                          }
                          minDate={this.state.drop_off_date_from || undefined}
                        />
                      </Grid>
                    </Grid>

                    {/* phone */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('common.phoneNumber')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl fullWidth>
                          <TextField
                            name="phone"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('validation.required', {field: t('common.phoneNumber')})}
                            value={this.state.phone || ''}
                            onChange={(event) => onChangeTextField(this, event)}
                            inputProps={{maxLength: 256}}
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* Email */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('common.email')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl fullWidth>
                          <TextField
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('validation.required', {field: t('common.email')})}
                            inputProps={{maxLength: 256}}
                            variant="outlined"
                            value={this.state.email || ''}
                            name="email"
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* Created date */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('history.created_date')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={6}>
                        <KeyboardDatePicker
                          autoOk
                          inputVariant="outlined"
                          name="created_date_from"
                          disableToolbar
                          variant="inline"
                          format="yyyy/MM/dd"
                          margin="dense"
                          className="field_size_10 field_min_size_300"
                          label={t('common.from')}
                          value={this.state.created_date_from}
                          onChange={(time) =>
                            this.setState({
                              created_date_from: time,
                            })
                          }
                          maxDate={this.state.created_date_to || undefined}
                        />
                        <span style={{padding: '10px'}}></span>
                        <KeyboardDatePicker
                          autoOk
                          inputVariant="outlined"
                          name="created_date_to"
                          disableToolbar
                          className="field_size_10 field_min_size_300"
                          variant="inline"
                          format="yyyy/MM/dd"
                          margin="dense"
                          label={t('common.to')}
                          value={this.state.created_date_to}
                          onChange={(time) =>
                            this.setState({
                              created_date_to: time,
                            })
                          }
                          minDate={this.state.created_date_from || undefined}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      {/* Date time */}
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('jit.dateTime')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={6}>
                        <KeyboardDatePicker
                          autoOk
                          inputVariant="outlined"
                          name="date_time"
                          disableToolbar
                          className="field_size_10 field_min_size_300"
                          variant="inline"
                          format="yyyy/MM/dd"
                          margin="dense"
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <IconButton onClick={(e) => this.setState({time: '', date: null, all_jit_time: []})}>
                                <ClearIcon />
                              </IconButton>
                            ),
                            placeholder: t('jit.date'),
                          }}
                          InputAdornmentProps={{
                            position: 'start',
                          }}
                          value={this.state.date}
                          onChange={(date) => this.setState({date: date, time: '', all_jit_time: []}, () => this.getTimeBooking())}
                        />
                        <span style={{padding: '10px'}}></span>
                        <FormControl variant="outlined" margin="dense">
                          <Select
                            margin="dense"
                            className="field_size_10 field_min_size_300"
                            inputProps={{
                              name: 'time',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.time ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('jit.time'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.time}
                            onChange={(e) => onChangeSelect(this, e)}
                          >
                            {this.state.all_jit_time?.map((item, idx) => {
                              return (
                                <MenuItem value={item} key={idx}>
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* Operation Status */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('businessInformation.status')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl variant="outlined" margin="dense">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'status',
                            }}
                            className="field_size_10 field_min_size_300"
                            displayEmpty
                            renderValue={
                              this.state.status ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('businessInformation.status'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.status}
                            onChange={(event) => onChangeSelect(this, event)}
                          >
                            {JIT_STATUS.map((item, idx) => {
                              return (
                                <MenuItem value={item.id} key={idx}>
                                  {t(`${item.i18n}`)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Paper>
                </>
              )}
              <br></br>
              <Grid container spacing={1}>
                <Grid container alignItems="center" justify="flex-start" item xs={3}>
                  {permission.canSearch && (
                    <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.reset()} endIcon={<RotateLeftIcon />}>
                      {t('common.btnResetSearch')}
                    </Button>
                  )}
                </Grid>
                <Grid container alignItems="center" justify="flex-end" item xs={9}>
                  {permission.canViewJitMasterData && (
                    <Button color="primary" variant="contained" className="button_margin" endIcon={<StorageIcon />} onClick={() => this.setState({flagMasterData: true})}>
                      {t('jit.masterData')}
                    </Button>
                  )}
                  {permission.canRegister && (
                    <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={() => this.openModalForm()}>
                      {t('common.btnRegister')}
                    </Button>
                  )}
                  {permission.canCSVImport && (
                    <Button
                      color="primary"
                      variant="contained"
                      component="label"
                      className="button_margin"
                      endIcon={<PublishIcon className="csv-exporter-icon" />}
                      onClick={() => this.setState({flagImport: true})}
                    >
                      {t('jit.csv.import')}
                    </Button>
                  )}
                  <div className="hidden">
                    <CSVExporter
                      ref={this.exportCSVRef}
                      headers={this.state.exportHeaders}
                      fetchAction={this.fetchDataExport}
                      isFetchAsync={true}
                      screenName={t('businessInformation.searchTitle')}
                    />
                  </div>
                  {permission.canCSVOutput && (
                    <Button
                      onClick={this.alertExportCSV}
                      component="label"
                      color="primary"
                      variant="contained"
                      className="button_margin"
                      endIcon={<GetAppIcon className="csv-exporter-icon" />}
                    >
                      {t('jit.csv.export')}
                    </Button>
                  )}
                  {permission.canSearch && (
                    <Button color="primary" variant="contained" className="button_margin" onClick={() => this.handleSearch(true)} endIcon={<SearchIcon />}>
                      {t('common.btnSearch')}
                    </Button>
                  )}
                </Grid>
              </Grid>

              <br></br>
            </Container>
          </Card>
          <br></br>
          {this.state.isSearch ? (
            <Card raised>
              <Container maxWidth="xl">
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={6}>
                    <h3>
                      {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                    </h3>
                  </Grid>
                </Grid>
                <LoadingOverlay bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                  {this.state.result?.length > 0 ? (
                    <div className="scroll_area_700">
                      <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                        <TableHead>
                          <TableRow>
                            <TableCell className="width_50p ant-table-cell-fix-left">{t('common.no')}</TableCell>
                            <TableCell className="width_200p">{t('common.country')}</TableCell>
                            <TableCell className="width_200p">{t('business_plan.geofence')}</TableCell>
                            <TableCell className="width_200p">{t('reservationManagement.business_name')}</TableCell>
                            <TableCell className="width_200p">{t('reservationManagement.memberName')}</TableCell>
                            <TableCell className="width_200p">{t('newReservation.number')}</TableCell>
                            <TableCell className="width_200p">{t('reservationManagement.pickup_address_search')}</TableCell>
                            <TableCell className="width_200p">{t('reservationManagement.dropoff_address_search')}</TableCell>
                            <TableCell className="width_200p">{t('reservationManagement.pickup_date')}</TableCell>
                            <TableCell className="width_200p">{t('reservationManagement.pickup_time')}</TableCell>
                            <TableCell className="width_200p">{t('reservationManagement.dropoff_date')}</TableCell>
                            <TableCell className="width_200p">{t('reservationManagement.dropoff_time')}</TableCell>
                            <TableCell className="width_200p">{t('reservationManagement.number_people')}</TableCell>
                            <TableCell className="width_200p">{t('common.phoneNumber')}</TableCell>
                            <TableCell className="width_200p">{t('common.email')}</TableCell>
                            <TableCell className="width_200p">{t('history.created_date')}</TableCell>
                            <TableCell className="width_200p">{t('reservationManagement.status')}</TableCell>
                            {(permission.canEdit || permission.canDelete || permission.canSend || permission.canClone) && (
                              <TableCell className="width_300p" style={{position: 'sticky', right: '0'}}>
                                <Checkbox
                                  checked={this.state.checkedAll}
                                  onChange={(event) => this.handleCheckedAll(event.target.checked)}
                                  style={{
                                    color: this.state.checkedAll ? '#66cc00' : '',
                                  }}
                                />
                                <span style={{paddingLeft: '4px'}}>{t('common.action')}</span>
                              </TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.result.map((row, index) => (
                            <TableRow key={index} hover className="cursor_pointer" style={row.import_errors.length > 0 ? {background: 'yellow'} : {}}>
                              <TableCell className="cell_fixed_left">{index + 1 + Number(this.state.currentPage) * Number(this.state.rowsPerPage)}</TableCell>
                              <TableCell style={this.checkColumnError(row, 'country_id')}>
                                {row.country_id ? t(common.country_code?.find((item) => item.id + '' === row.country_id + '')?.country_code || row.country_id) : DATA_NULL}
                              </TableCell>
                              <TableCell style={this.checkColumnError(row, 'geofence_code')}>
                                {row.geofence_name || row.geofence_id || DATA_NULL}
                              </TableCell>
                              <TableCell style={this.checkColumnError(row, 'business_id')}>
                                {this.state.supplier_list?.find((item) => item.id + '' === row.business_id + '')?.supplier_name || row.business_id}
                              </TableCell>
                              <TableCell style={this.checkColumnError(row, 'last_name', 'first_name')}>{(row.last_name || '') + ' ' + (row.first_name || '')}</TableCell>
                              <TableCell>
                                {row.reservation_number && (
                                  <Link
                                    style={{textDecoration: 'none', color: '#3f51b5'}}
                                    to={{
                                      pathname: ROUTE.LAYOUT + ROUTE.RESERVATION_MANAGEMENT_DETAIL + `/SHUTTLE_BUS_JIT/${row.trip_booking_id}`,
                                    }}
                                  >
                                    {row.reservation_number}
                                  </Link>
                                )}
                                {!row.reservation_number && DATA_NULL}
                              </TableCell>
                              <TableCell style={this.checkColumnError(row, 'pickup_address_code')}>{row.pickup_address || row.pickup_address_id}</TableCell>
                              <TableCell style={this.checkColumnError(row, 'drop_off_address_code')}>{row.drop_off_address || row.drop_off_address_id}</TableCell>
                              <TableCell style={this.checkColumnError(row, 'pickup_date')}>{row.pickup_date}</TableCell>
                              <TableCell style={this.checkColumnError(row, 'pickup_time')}>{row.pickup_time}</TableCell>
                              <TableCell style={this.checkColumnError(row, 'drop_off_date')}>{row.drop_off_date}</TableCell>
                              <TableCell style={this.checkColumnError(row, 'drop_off_time')}>{row.drop_off_time}</TableCell>
                              <TableCell style={this.checkColumnError(row, 'number_of_users')}>{row.number_of_users}</TableCell>
                              <TableCell style={this.checkColumnError(row, 'phone')}>{row.phone}</TableCell>
                              <TableCell style={this.checkColumnError(row, 'email')}>{row.email}</TableCell>
                              <TableCell>{formatUtc(row.created_date, 'yyyy-MM-dd', row.zone_id)}</TableCell>
                              <TableCell>
                                {t(
                                  `${
                                    JIT_STATUS.filter((item) => {
                                      return item.id === row.status;
                                    })[0]?.i18n || DATA_NULL
                                  }`,
                                )}
                              </TableCell>
                              {(permission.canEdit || permission.canDelete || permission.canSend || permission.canClone) && (
                                <TableCell className="cell_fixed_right">
                                  {permission.canSend && (
                                    <Checkbox
                                      checked={row.status === 'SENT' || row.checked}
                                      onChange={(event) => onChangeListData(this, this.state.result, 'checked', index, event.target.checked)}
                                      style={{
                                        color: row.checked ? '#66cc00' : '',
                                      }}
                                      disabled={row.status === 'SENT' || row.import_errors.length > 0 || row.tejimai_time <= new Date().toISOString() || !row.tejimai_time}
                                    />
                                  )}
                                  {row.status !== 'SENT' && permission.canEdit && (
                                    <Button color="primary" variant="contained" onClick={() => this.openModalForm(row.id)} className="button_margin" endIcon={<DetailIcon />}>
                                      {t('common.btnEdit')}
                                    </Button>
                                  )}
                                  {row.status === 'SENT' && permission.canEdit && (
                                    <Button color="primary" variant="contained" onClick={() => this.openModalForm(row.id)} className="button_margin" endIcon={<VisibilityIcon />}>
                                      {t('common.btnDetail')}
                                    </Button>
                                  )}
                                  {row.status !== 'SENT' && permission.canDelete && (
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="button_margin button_color_red"
                                      onClick={() => this.handleDelete(row.id, index)}
                                      endIcon={<DeleteIcon />}
                                    >
                                      {t('common.btnDelete')}
                                    </Button>
                                  )}
                                  {row.status === 'SENT' && permission.canClone && (
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="button_margin button_color_blue"
                                      onClick={() => this.handleClone(row.id)}
                                      endIcon={<FileCopyIcon />}
                                    >
                                      {t('common.btnClone')}
                                    </Button>
                                  )}
                                </TableCell>
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  ) : (
                    false
                  )}
                  <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                  <br></br>
                  {this.state.result?.length > 0 && permission.canSend && (
                    <Grid container spacing={1}>
                      <Grid container alignItems="center" direction="row" justify="flex-end">
                        <Button
                          color="primary"
                          variant="contained"
                          className="button_margin button_color_green"
                          endIcon={<SendIcon />}
                          disabled={this.state.result.filter((item) => item.checked).length === 0}
                          onClick={this.openModalSend}
                        >
                          {t('common.btnSend')}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </LoadingOverlay>
                <br></br>
              </Container>
              <br></br>
            </Card>
          ) : (
            false
          )}
          <br></br>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.flagImport}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1000',
            }}
          >
            <>
              <ImportCSV
                onClickClose={() => this.setState({flagImport: false})}
                type="jit"
                reSearch={this.handleSearch}
                downloadTemplateCSV={(countryCode) => this.handleButtonExportOk(true, countryCode)}
              ></ImportCSV>
            </>
          </Modal>
          <Dialog
            open={this.state.flagDelete}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal onClickOk={this.handleButtonOk} onClickCancel={this.handleButtonCancel} message={this.state.message}></SelectModal>
          </Dialog>

          {/* Export alert modal */}
          <Dialog
            open={this.state.flagExport}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal
              onClickOk={this.handleButtonExportOk}
              onClickCancel={this.handleButtonExportCancel}
              okButtonText={t('common.btnYes')}
              cancelButtonText={t('common.btnNo')}
              message={this.state.message}
            ></SelectModal>
          </Dialog>

          {/* Send jit alert modal */}
          <Dialog
            open={this.state.flagSend}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal
              onClickOk={this.sendJitOrder}
              onClickCancel={() => this.setState({flagSend: false})}
              okButtonText={t('common.btnYes')}
              cancelButtonText={t('common.btnNo')}
              message={this.state.message}
            ></SelectModal>
          </Dialog>

          {/* Clone Jit Order dialog*/}
          <Dialog
            open={this.state.flagClone}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal onClickOk={this.handleButtonCloneOk} onClickCancel={() => this.setState({flagClone: false})} message={this.state.message}></SelectModal>
          </Dialog>

          {/* Open Modal Register/Update Jit */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.flagForm}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1000',
            }}
          >
            <>
              <FormComponent detailId={this.state.detailId} permission={permission} onClickClose={this.handleButtonClose} reSearch={this.handleSearch}></FormComponent>
            </>
          </Modal>

          {/* Open Modal Master data */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.flagMasterData}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1000',
            }}
          >
            <>
              <MasterData onClickClose={() => this.setState({flagMasterData: false})}></MasterData>
            </>
          </Modal>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    getAllJitGeofence: (payload) => dispatch(getAllJitGeofence(payload)),
    getAllCountryCodeWithGeofence: () => dispatch(getAllCountryCodeWithGeofence()),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
