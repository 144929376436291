import {homePageApi} from '../../services/authenServices';
import {ACTION_TYPES} from './reducer';

/**
 * search screen homepage
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const getScreenList = () => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_LIST_SCREEN_START});
    return homePageApi().then(
        (response) => {
          if (response && response.status === 200) {
            if (response.result.length > 0) {
              dispatch({
                type: ACTION_TYPES.GET_LIST_SCREEN_SUCCESS,
                listScreen: response.result,
              });
              return response.result;
            } else {
              dispatch({type: ACTION_TYPES.GET_LIST_SCREEN_FAIL});
            }
          } else {
            dispatch({type: ACTION_TYPES.GET_LIST_SCREEN_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.GET_LIST_SCREEN_FAIL}),
    );
  };
};
