import {ROUTE} from '../../common/constant';
import {
  getServiceGroupsApi,
  deleteServiceGroupApi,
  getServiceGroupDetailApi,
  updateServiceGroupApi,
} from '../../services/serviceGroupService';
import {modalObj} from '../../utils/modal';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

/**
 * getAllServiceGroups
 * @param {Object} payload
 * @return {Object}
 */
export const getAllServiceGroups = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_ALL_SERVICE_GROUP_START});
    return getServiceGroupsApi(payload).then(
        (response) => {
          if (response.status === 200 && response) {
            dispatch({
              type: ACTION_TYPES.GET_ALL_SERVICE_GROUP_SUCCESS,
              allServiceGroups: response.result,
            });
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
            dispatch({type: ACTION_TYPES.GET_ALL_SERVICE_GROUP_FAIL});
          }
        },
        () => {
          dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
          dispatch({type: ACTION_TYPES.GET_ALL_SERVICE_GROUP_FAIL});
        },
    );
  };
};

/**
 * deleteServiceGroup
 * @param {object} id
 * @return {Promise}
 */
export const deleteServiceGroup = (id) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.DELETE_SERVICE_GROUP_START});
    return deleteServiceGroupApi(id).then(
        (response) => {
          if (response.status === 200 && response) {
            dispatch({type: ACTION_TYPES.DELETE_SERVICE_GROUP_SUCCESS});
          } else {
            dispatch({type: ACTION_TYPES.DELETE_SERVICE_GROUP_FAIL});
          }
          dispatch(setMessageModal(modalObj(true, response.message_code)));
        },
        () => {
          dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
          dispatch({type: ACTION_TYPES.DELETE_SERVICE_GROUP_FAIL});
        },
    );
  };
};

/**
 * getServiceGroup
 * @param {Number} id
 * @return {Object}
 */
 export const getServiceGroup = (id) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_SERVICE_GROUP_START});
    return getServiceGroupDetailApi(id).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_SERVICE_GROUP_SUCCESS,
              serviceGroup: response.result,
            });
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
            dispatch({
              type: ACTION_TYPES.GET_SERVICE_GROUP_FAIL,
            });
          }
        },
        () => {
          dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
          dispatch({type: ACTION_TYPES.GET_SERVICE_GROUP_FAIL});
        },
    );
  };
};

/**
 * updateServiceGroup
 * @param {object} payload
 * @param {object} props
 * @return {object}
 */
export const updateServiceGroup = (payload, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.UPDATE_SERVICE_GROUP_START});
    return updateServiceGroupApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.UPDATE_SERVICE_GROUP_SUCCESS,
            });
            const prevPath = ROUTE.LAYOUT + ROUTE.SERVICE_GROUP;
            props.history.push(prevPath);
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.UPDATE_SERVICE_GROUP_FAIL});
          }
        },
        () => {
          dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
          dispatch({type: ACTION_TYPES.UPDATE_SERVICE_GROUP_FAIL});
        },
    );
  };
};
