import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {
  Card,
  Container,
  Grid,
  Button,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
/**
 * Reservation management view
 */
class Index extends Component {
  /**
   * render component
   * @return {component}
   */
  render() {
    const {t} = this.props;

    return (
      <div>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('route.reservationManagement')}</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}>
                <Button
                  color="primary"
                  variant="contained"
                  className="button_margin button_color"
                  endIcon={<ArrowBackIcon />}
                  onClick={this.props.history.goBack}
                >
                  {t('common.btnReturn')}
                </Button>
              </Grid>
            </Grid>
          </Container>
          <br></br>

          <Container maxWidth="xl">
            <Card raised>
              <Grid container className="page_header">
                <Grid container alignItems="center" item xs={12} className="margin_20">
                  <div className="border_complete text_center">
                    {t('newReservation.complete')}
                  </div>
                </Grid>
                <Grid container alignItems="center" item xs={5}>
                </Grid>
                <Grid container alignItems="center" item xs={7}>
                  <Typography gutterBottom variant="h4" component="h4" className="text_center">
                    300 WILLER 株式会社
                  </Typography>
                </Grid>
              </Grid>
              <br />
            </Card>
            <br />
          </Container>
        </Card>
      </div>
    );
  }
}

export default withTranslation('translations')(Index);
