export const ACTION_TYPES = {
  SEARCH_LIST_DELIVERY_START: 'SEARCH_LIST_DELIVERY_START',
  SEARCH_LIST_DELIVERY_SUCCESS: 'SEARCH_LIST_DELIVERY_SUCCESS',
  SEARCH_LIST_DELIVERY_FAIL: 'SEARCH_LIST_DELIVERY_FAIL',

  GET_DELIVERY_START: 'GET_DELIVERY_START',
  GET_DELIVERY_SUCCESS: 'GET_DELIVERY_SUCCESS',
  GET_DELIVERY_FAIL: 'GET_DELIVERY_FAIL',

  UPDATE_DELIVERY_START: 'UPDATE_DELIVERY_START',
  UPDATE_DELIVERY_SUCCESS: 'UPDATE_DELIVERY_SUCCESS',
  UPDATE_DELIVERY_FAIL: 'UPDATE_DELIVERY_FAIL',

  CREATE_DELIVERY_START: 'CREATE_DELIVERY_START',
  CREATE_DELIVERY_SUCCESS: 'CREATE_DELIVERY_SUCCESS',
  CREATE_DELIVERY_FAIL: 'CREATE_DELIVERY_FAIL',

  DELETE_DELIVERY_START: 'DELETE_DELIVERY_START',
  DELETE_DELIVERY_SUCCESS: 'DELETE_DELIVERY_SUCCESS',
  DELETE_DELIVERY_FAIL: 'DELETE_DELIVERY_FAIL',

  GET_LIST_PAYMENT_METHOD_START: 'GET_LIST_PAYMENT_METHOD_START',
  GET_LIST_PAYMENT_METHOD_SUCCESS: 'GET_LIST_PAYMENT_METHOD_SUCCESS',
  GET_LIST_PAYMENT_METHOD_FAIL: 'GET_LIST_PAYMENT_METHOD_FAIL',

  UPDATE_LIST_TIME_SETTING_START: 'UPDATE_LIST_TIME_SETTING_START',
  UPDATE_LIST_TIME_SETTING_SUCCESS: 'UPDATE_LIST_TIME_SETTING_SUCCESS',
  UPDATE_LIST_TIME_SETTING_FAIL: 'UPDATE_LIST_TIME_SETTING_FAIL',
};

const initialState = {
  isLoading: false,
  listDelivery: [],
  delivery: {},
  error: '',
  listPayment: [],
};

/**
 * function reduce change password
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_LIST_TIME_SETTING_START:
    case ACTION_TYPES.SEARCH_LIST_DELIVERY_START:
    case ACTION_TYPES.GET_DELIVERY_START:
    case ACTION_TYPES.UPDATE_DELIVERY_START:
    case ACTION_TYPES.CREATE_DELIVERY_START:
    case ACTION_TYPES.DELETE_DELIVERY_START:
    case ACTION_TYPES.GET_LIST_PAYMENT_METHOD_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.SEARCH_LIST_DELIVERY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listDelivery: action.listDelivery,
        error: '',
      };
    case ACTION_TYPES.GET_DELIVERY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        delivery: action.DELIVERY,
        error: '',
      };
    case ACTION_TYPES.UPDATE_LIST_TIME_SETTING_SUCCESS:
    case ACTION_TYPES.UPDATE_LIST_TIME_SETTING_FAIL:
    case ACTION_TYPES.UPDATE_DELIVERY_FAIL:
    case ACTION_TYPES.CREATE_DELIVERY_FAIL:
    case ACTION_TYPES.DELETE_DELIVERY_FAIL:
    case ACTION_TYPES.GET_LIST_PAYMENT_METHOD_FAIL:
    case ACTION_TYPES.UPDATE_DELIVERY_SUCCESS:
    case ACTION_TYPES.CREATE_DELIVERY_SUCCESS:
    case ACTION_TYPES.DELETE_DELIVERY_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.SEARCH_LIST_DELIVERY_FAIL:
      return {
        isLoading: false,
        error: action.error,
        listDelivery: [],
      };
    case ACTION_TYPES.GET_DELIVERY_FAIL:
      return {
        ...state,
        isLoading: false,
        delivery: {},
        error: '',
      };
    case ACTION_TYPES.GET_LIST_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listPayment: action.listPayment,
      };
    default:
      return state;
  }
}
