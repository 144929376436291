import React, {Component} from 'react';

import {Card, Container, Grid, Button, Table, TableCell, TableRow, Paper, Dialog, Checkbox} from '@material-ui/core';
import {TableBody} from '@material-ui/core';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';

import {ROUTE} from '../../../common/constant';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getVehicleShift, updateVehicleShift} from '../../../stores/business/action';
import {backForwardRouter} from '../../../utils/common';
import {convertTimeUTCToCalendarJapan} from '../../../utils/datetime';

/**
 * Stop Shift JIT component
 */
class Index extends Component {
  /**
   *
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      flag: false,
      list_result: [],

      checkHTW: false,
      checkWTH: false,
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    this.props.getVehicleShift(this.state.date.toISOString().substr(0, 10), this.props.match.params.id).then((response) => {
      if (response) {
        this.setState({
          list_result: response,
        });
      }
    }, () => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.VEHICLE_DETAIL + '/' + this.props.match.params.id));
  }

  /**
   * changeDate
   * @param {Date} date
   */
  changeDate = async (date) => {
    this.setState({date: date});
    await this.props.getVehicleShift(date.toISOString().substr(0, 10), this.props.match.params.id).then((response) => {
      if (response) {
        this.setState({
          list_result: response,
        });
      }
    });
  };

  /**
   * changeEnableData
   * @param {*} value
   * @param {*} id
   */
  changeEnableData = (value, id) => {
    const list_result = this.state.list_result;
    const index = list_result.findIndex((item) => item.id === id);
    list_result[index].enabled = value;

    this.setState({
      list_result: list_result,
    });
  };

  /**
   * changeAllEnableData
   * @param {*} value
   * @param {*} string
   */
  changeAllEnableData = (value, string) => {
    const list = this.state.list_result;
    if (value) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].group_type === string) {
          list[i].enabled = true;
        } else if (list[i].group_type === string) {
          list[i].enabled = true;
        }
      }
      this.setState({
        list_result: list,
      });
    } else {
      for (let i = 0; i < list.length; i++) {
        if (list[i].group_type === string) {
          list[i].enabled = false;
        } else if (list[i].group_type === string) {
          list[i].enabled = false;
        }
      }
      this.setState({
        list_result: list,
      });
    }
  };

  /**
   * handleUpdate
   */
  handleUpdate = () => {
    const arr = [];

    const list = this.state.list_result;
    for (let i = 0; i < list.length; i++) {
      const dataPayLoad = {
        start_time: list[i].start_time,
        end_time: list[i].end_time,
        enabled: list[i].enabled,
        group_type: list[i].group_type,
      };
      arr.push(dataPayLoad);
    }
    const payload = {
      vehicle_shift_data: arr,
      vehicle_id: parseInt(this.props.match.params.id),
    };
    this.props.updateVehicleShift(payload);
  };

  /**
   * checkData
   * @return {*}
   */
  checkData = () => {
    const isCheckHTW = this.state.list_result.find(({group_type}) => group_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK');
    return isCheckHTW;
  };

  /**
   * checkDataWTH
   * @return {*}
   */
  checkDataWTH = () => {
    const isCheckWTH = this.state.list_result.find(({group_type}) => group_type === 'SHUTTLE_BUS_JIT_WORK_TO_HOME');
    return isCheckWTH;
  };

  /**
   * isCheckedAll
   * @param {*} type
   * @return {*}
   */
  isCheckedAll = (type) => {
    const allItemsByType = this.state.list_result.filter(({group_type}) => group_type === type);
    for (const item of allItemsByType) {
      if (!item.enabled && item.group_type === type) {
        return false;
      }
    }
    return true;
  };

  /**
   * @return {HTMLElement}
   */
  render() {
    const {t} = this.props;

    const checkedAllHTW = this.isCheckedAll('SHUTTLE_BUS_JIT_HOME_TO_WORK');
    const checkedAllWTH = this.isCheckedAll('SHUTTLE_BUS_JIT_WORK_TO_HOME');

    return (
      <Card className="SearchAccountType main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('businessVehicle.title_shift')}</h3>
            </Grid>
          </Grid>
          <br></br>
          <LoadingOverlay active={false} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('businessVehicle.title_shift')}
                    </Grid>
                  </Grid>

                  {/* Date */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessVehicle.date')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <KeyboardDatePicker
                      autoOk
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      value={this.state.date}
                      onChange={this.changeDate}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </Grid>
                </Paper>
                <br></br>

                <Paper className="search_table">
                  {/* Time */}
                  <Grid container spacing={1} className="row_form_item scroll_area_700">
                    <Grid container alignItems="center">
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell align="left" className="fixed_cell" style={{width: '50px'}}>
                              {t('businessVehicle.mobiType.searchResult.SHUTTLE_BUS_JIT_HOME_TO_WORK')}
                              {this.checkData() && (
                                <>
                                  <br></br>
                                  {t('common.all')}
                                  <Checkbox
                                    checked={checkedAllHTW}
                                    onChange={() => this.changeAllEnableData(!checkedAllHTW, 'SHUTTLE_BUS_JIT_HOME_TO_WORK')}
                                    className="checkbox_radio"
                                  />
                                </>
                              )}
                            </TableCell>
                            {this.state.list_result
                                ?.filter((item) => item.group_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK')
                                .map((item, index) => {
                                  const checked = item.enabled;
                                  return (
                                    <TableCell align="left" className="fixed_cell">
                                      {convertTimeUTCToCalendarJapan(item.start_time)} ~ {convertTimeUTCToCalendarJapan(item.end_time)}
                                      <Checkbox checked={checked} onChange={(event) => this.changeEnableData(event.target.checked, item.id)} className="checkbox_radio" />
                                    </TableCell>
                                  );
                                })}
                          </TableRow>
                        </TableBody>
                      </Table>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell align="left" className="fixed_cell">
                              {t('businessVehicle.mobiType.searchResult.SHUTTLE_BUS_JIT_WORK_TO_HOME')}
                              {this.checkDataWTH() && (
                                <>
                                  <br></br>
                                  {t('common.all')}
                                  <Checkbox
                                    checked={checkedAllWTH}
                                    onChange={() => this.changeAllEnableData(!checkedAllWTH, 'SHUTTLE_BUS_JIT_WORK_TO_HOME')}
                                    className="checkbox_radio"
                                  />
                                </>
                              )}
                            </TableCell>
                            {this.state.list_result
                                ?.filter((item) => item.group_type === 'SHUTTLE_BUS_JIT_WORK_TO_HOME')
                                .map((item) => {
                                  const checked = item.enabled;
                                  return (
                                    <TableCell align="left" className="fixed_cell">
                                      {convertTimeUTCToCalendarJapan(item.start_time)} ~ {convertTimeUTCToCalendarJapan(item.end_time)}
                                      <Checkbox checked={checked} onChange={(event) => this.changeEnableData(event.target.checked, item.id)} className="checkbox_radio" />
                                    </TableCell>
                                  );
                                })}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                </Paper>
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-end" item xs={12}>
                    <Button color="primary" variant="contained" className="button_margin button_color_green" onClick={() => this.handleUpdate()}>
                      {t('common.btnConfirm')}
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      className="button_margin button_color"
                      onClick={() => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.VEHICLE_DETAIL + '/' + this.props.match.params.id)}
                    >
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                </Grid>

                <br></br>
              </Container>
            </Card>
          </LoadingOverlay>
          <br></br>

          <br></br>
          <Dialog
            open={this.state.flag}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal onClickOk={this.handleButtonOk} message={this.state.message}></SelectModal>
          </Dialog>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {common: state.common};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVehicleShift: (date, vehicle_id) => dispatch(getVehicleShift(date, vehicle_id)),
    updateVehicleShift: (payload) => dispatch(updateVehicleShift(payload)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
