import {api} from './api';

const searchOneTimePriceUrl = `/admin/one-time-price/search`;
const getOneTimePriceFunctionList = `admin/function`;
const createOneTimePriceUrl = `/admin/one-time-price`;
const getOneTimePriceDetailsUrl = `/admin/one-time-price`;
const updateOneTimePriceUrl = `/admin/one-time-price`;


export const searchOneTimePriceApi = (payload, queryParams) => {
  return api.search(searchOneTimePriceUrl, queryParams, payload);
};

export const getOneTimePriceFunctionListApi = () => {
  return api.get(getOneTimePriceFunctionList);
};

export const createOneTimePriceApi = (payload) => {
  return api.post(createOneTimePriceUrl, payload);
};

export const updateOneTimePriceApi = (payload) => {
  return api.post(updateOneTimePriceUrl, payload);
};

export const getOneTimePriceDetailsApi = (id) => {
  return api.get(getOneTimePriceDetailsUrl + '/' + id);
};
