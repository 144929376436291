import React, {Component} from 'react';

import {Button, Container, Grid, Paper, Box, Card, Checkbox, Dialog} from '@material-ui/core';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';

import SelectModal from '../../../../../components/selectModal';
import {getVehicleShift, deleteShift} from '../../../../../stores/business/action';
import {convertTimeUTCToCalendarLocal} from '../../../../../utils/date_time_utils';

/**
 * Config Display Schedule
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      listShift: [],
      openConfirmDeleteShiftModal: false,
    };
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    this.loadData();
  }

  /**
   * loadData
   */
  loadData = () => {
    this.props.getVehicleShift(this.props.props.date.toISOString().substr(0, 10), this.props.props.vehicleId).then((response) => {
      this.setState({listShift: response?.map((item) => {
        return {...item, delete: false};
      })});
    });
  }

  /**
   * deleteShift
   */
  deleteShift = () => {
    this.setState({openConfirmDeleteShiftModal: false});
    this.props.deleteShift(this.state.listShift?.filter((item) => item.delete)?.map((item) => item.id)).then(() => {
      this.loadData();
      this.props.reloadDetail();
    });
  }

  /**
   * Render
   * @return {*}
   */
  render() {
    const {t, association} = this.props.props;

    return (
      <LoadingOverlay active={false} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Paper className="field_min_size_700">
          <Container maxWidth="xl">
            <Box p={1} m={1}>
              <Card raised>
                <Container maxWidth="xl">
                  <Box p={2} m={2}>
                    <Grid container alignItems="flex-start">
                      <Grid item xs={12}>
                        <h1 className="product_entry_table_header_color font_color_white font_size_mid title">
                          {t('driverAssociation.delete_shift')}
                        </h1>
                        <Grid container alignItems="flex-start">
                          <Grid item xs={12}>
                            <Grid container alignItems="flex-start">
                              <Grid container alignItems="center" justify="flex-start" item xs={4} lg={2} md={2} className="padding-left-12 display_grid">
                                <span style={{margin: '15px 0'}}>{t('businessVehicle.mobiType.searchResult.SHUTTLE_BUS_JIT_HOME_TO_WORK')}</span>
                                {this.state.listShift?.filter((item) => item.group_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK').length > 0 && (
                                  <Box display="flex">
                                    <span style={{padding: '8px 0', marginRight: 5}}>{t('common.all')}</span>
                                    <Checkbox
                                      checked={this.state.listShift?.filter((item) => item.group_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' && item.delete).length === 0}
                                      onChange={(event) => {
                                        const {listShift} = this.state;
                                        for (const [index, shift] of Object.entries(listShift)) {
                                          (shift.group_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK') && (shift.delete = !event.target.checked);
                                          listShift[index] = shift;
                                        }
                                        this.setState({listShift});
                                      }}
                                      className="checkbox_radio"
                                    />
                                  </Box>
                                )}
                              </Grid>
                              <Grid container alignItems="center" item xs={8} lg={10} md={10}>
                                {this.state.listShift?.filter((item) => item.group_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK')?.map((item, index) => (
                                  <div
                                    className="display_flex table_background_color_aliceblue margin-item-12"
                                    key={index}
                                  >
                                    <Checkbox
                                      checked={!item.delete}
                                      inputProps={{'aria-label': ''}}
                                      onChange={(event) => {
                                        const {listShift} = this.state;
                                        const indexOfShift = listShift.map((item) => item.id)?.indexOf(item.id);
                                        item.delete = !event.target.checked;
                                        listShift[indexOfShift] = item;
                                        this.setState({listShift});
                                      }}
                                    ></Checkbox>
                                    <div className="display_grid">
                                      <span style={{minWidth: 100, padding: '12px 0'}}>
                                        {
                                          convertTimeUTCToCalendarLocal(item.start_time, association.vehicle?.find((itm) => itm.id === item.vehicle_id)?.zone_id)
                                        } ~ {
                                          convertTimeUTCToCalendarLocal(item.end_time, association.vehicle?.find((itm) => itm.id === item.vehicle_id)?.zone_id)
                                        }
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              </Grid>
                          </Grid>
                          <hr style={{margin: 10, opacity: 0.5}}/>
                          <Grid item xs={12}>
                            <Grid container alignItems="flex-start">
                              <Grid container alignItems="center" justify="flex-start" item xs={4} lg={2} md={2} className="padding-left-12 display_grid">
                                <span style={{margin: '15px 0'}}>{t('businessVehicle.mobiType.searchResult.SHUTTLE_BUS_JIT_WORK_TO_HOME')}</span>
                                  {this.state.listShift?.filter((item) => item.group_type === 'SHUTTLE_BUS_JIT_WORK_TO_HOME').length > 0 && (
                                    <Box display="flex">
                                      <span style={{padding: '8px 0', marginRight: 5}}>{t('common.all')}</span>
                                      <Checkbox
                                        checked={this.state.listShift?.filter((item) => item.group_type === 'SHUTTLE_BUS_JIT_WORK_TO_HOME' && item.delete).length === 0}
                                        onChange={(event) => {
                                          const {listShift} = this.state;
                                          for (const [index, shift] of Object.entries(listShift)) {
                                            (shift.group_type === 'SHUTTLE_BUS_JIT_WORK_TO_HOME') && (shift.delete = !event.target.checked);
                                            listShift[index] = shift;
                                          }
                                          this.setState({listShift});
                                        }}
                                        className="checkbox_radio"
                                      />
                                    </Box>
                                  )}
                                </Grid>
                                <Grid container alignItems="center" item xs={8} lg={10} md={10}>
                                  {this.state.listShift?.filter((item) => item.group_type === 'SHUTTLE_BUS_JIT_WORK_TO_HOME')?.map((item, index) => (
                                    <div
                                      className="display_flex table_background_color_aliceblue margin-item-12"
                                      key={index}
                                    >
                                      <Checkbox
                                        checked={!item.delete}
                                        inputProps={{'aria-label': ''}}
                                        onChange={(event) => {
                                          const {listShift} = this.state;
                                          const indexOfShift = listShift.map((item) => item.id)?.indexOf(item.id);
                                          item.delete = !event.target.checked;
                                          listShift[indexOfShift] = item;
                                          this.setState({listShift});
                                        }}
                                      ></Checkbox>
                                      <div className="display_grid">
                                        <span style={{minWidth: 100, padding: '12px 0'}}>
                                          {
                                            convertTimeUTCToCalendarLocal(item.start_time, association.vehicle?.find((itm) => itm.id === item.vehicle_id)?.zone_id)
                                          } ~ {
                                            convertTimeUTCToCalendarLocal(item.end_time, association.vehicle?.find((itm) => itm.id === item.vehicle_id)?.zone_id)
                                          }
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container alignItems="flex-start">
                          <Grid container justify="center" alignItems="center" item xs={12}>
                            <Button
                              variant="contained"
                              disabled={this.state.listShift?.filter((item) => item.delete).length === 0}
                              style={{marginTop: 10}}
                              color="primary"
                              onClick={() => this.setState({openConfirmDeleteShiftModal: true})}
                            >
                              {t('common.btnUpdate')}
                            </Button>
                            <Button variant="contained" className="button_color" style={{marginTop: 10, marginLeft: 10}} color="primary" onClick={() => this.props.onClickClose()}>
                              {t('common.btnClose')}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              </Card>
            </Box>
            <Dialog
              open={this.state.openConfirmDeleteShiftModal}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '1020',
              }}
            >
              <SelectModal
                onClickOk={() => this.deleteShift()}
                onClickCancel={() => this.setState({openConfirmDeleteShiftModal: false})}
                message={t('businessVehicle.confirm_delete_shift')}></SelectModal>
            </Dialog>
          </Container>
        </Paper>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {

  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVehicleShift: (date, vehicle_id) => dispatch(getVehicleShift(date, vehicle_id)),
    deleteShift: (payload) => dispatch(deleteShift(payload)),
  };
};

export default (connect(mapStateToProps, mapDispatchToProps)(Index));
