import {api} from '../services/api';

const searchAccountTypeUrl = `admin/accounts/role-permission/search`;
const getAccountTypeFunctionList = `admin/function/all`;
const createAccountTypeUrl = `admin/accounts/role-permission`;
const getAccountTypeDetailsUrl = `admin/accounts/role-permission/functions`;
const updateAccountTypeUrl = `/admin/accounts/role-permission/update`;
const deleteAccountTypeUrl = `/admin/accounts/role-permission`;

/**
 *
 * @param {object} payload
 * @param {object} queryParams
 * @return {Promise}
 */
export const searchAccountTypeApi = (payload, queryParams) => {
  return api.search(searchAccountTypeUrl, queryParams, payload);
};

/**
 * getAccountTypesFunctionList
 * @param {object} payload
 * @return {Promise}
 */
export const getAccountTypeFunctionListApi = () => {
  return api.get(getAccountTypeFunctionList);
};

/**
 * createAccountType
 * @param {object} payload
 * @return {Promise}
 */
export const createAccountTypeApi = (payload) => {
  return api.post(createAccountTypeUrl, payload);
};

/**
 * updateAccountType
 * @param {object} payload
 * @return {Promise}
 */
export const updateAccountTypeApi = (payload) => {
  return api.post(updateAccountTypeUrl, payload);
};

/**
 * getAccountTypeDetailsApi
 * @param {object} id
 * @return {Promise}
 */
export const getAccountTypeDetailsApi = (id) => {
  return api.get(getAccountTypeDetailsUrl + '/' + id);
};

/**
 * updateAccountType
 * @param {object} id
 * @return {Promise}
 */
export const deleteAccountTypeApi = (id) => {
  return api.delete(deleteAccountTypeUrl + '/' + id);
};
