import React from 'react';

import {connect} from 'react-redux';
import {Route} from 'react-router';

import {permissionDataApi} from '../../services/accountServices';
import NotFound from '../../views/notfound';

/**
 *
 * @param {React.Component} WrappedComponent
 * @param {string} path Screen relative path
 * @return {React.Component}
 */
const withPermissionData = (WrappedComponent) => {
  const mapDispatchToProps = (dispatch) => {
    return {};
  };

  return connect(mapDispatchToProps)(
    class extends React.Component {
      /**
       *
       * @param {object} props
       */
      constructor(props) {
        super(props);
        this.state = {
          allowed: null,
        };
      }

      /**
       * componentDidMount
       */
      componentDidMount = async () => {
        await permissionDataApi().then((response) => {
          if (response && response.status === 200) {
            this.setState({allowed: true});
          } else {
            this.setState({allowed: false});
          }
        });
      };

      /**
       * Render
       * @return {React.Component}
       */
      render() {
        return this.state.allowed ? <WrappedComponent {...this.props} /> : this.state.allowed === false ? <Route component={NotFound} /> : false;
      }
    },
  );
};

export default withPermissionData;
