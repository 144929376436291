import React, {Component} from 'react';

import {
  Card,
  Container,
  Grid,
  TextField,
  Button,
  FormControl,
  FormHelperText,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Box,
  Select,
  MenuItem,
  Dialog,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {PERMISSION_ACTIONS, ROUTE} from '../../../common/constant';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {createAccountType, getAccountTypeDetails, getAccountTypeFunctionList, updateAccountType} from '../../../stores/account_type/actions';
import {getListSupplier, getListFacilityBySupplierID} from '../../../stores/common/actions';
import {onChangeTextField, backForwardRouter} from '../../../utils/common';
import './styles.css';

// Account Type Management: 1008 - SEARCH, 1009 - REGISTER, 1010 - DETAIL, 1053 - DELETE, 1054 - UPDATE
// Menu App Management: 1118 - SEARCH, 1119 - DETAIL, 1120 - UPDATE
// Geofence Area Management: 1161 - SEARCH, 1162 - REGISTER, 1163 - DETAIL, 1164 - DELETE, 1165 - UPDATE
// News Management: 1171 - REGISTER_TEMPLATE
const FUNCTION_IDS_ROLE_BUSINESS_CANNOT_ACCESS = [
  1008, 1009, 1010, 1053, 1054, 1118, 1119, 1120, 1161, 1162, 1163, 1164, 1165, 1171, 1175, 1176, 1177, 1180, 1181, 1182, 1183, 1184, 1185,
];
/**
 * AccountType
 */
class AccountTypeForm extends Component {
  /**
   *
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      typeName: '',
      showedMajorItems: [],
      facility_ids: [],
      supplier_id: '',
      selectedFunctions: [],

      isSubmitForm: false,
      flg: false,
      message: '',
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    this.props.getListSupplier();

    // Get all account type function (role)
    this.props.getAccountTypeFunctionList().then((response) => {
      if (this.props.match.params.id) {
        this.props.getAccountTypeDetails(this.props.match.params.id).then((res) => {
          if (res) {
            const {function_id} = res;
            const majorList = this.props.account_type.majorList;
            let majorItems = function_id?.map((functionId) => this.getMajorItemFromFunctionId(functionId, majorList))?.filter((item) => item !== null);

            majorItems = [...new Set(majorItems)].sort((a, b) => a?.id - b?.id);
            this.props.getListFacilityBySupplierID(res?.supplier_id);
            this.setState({
              id: res?.id,
              typeName: res?.role_permission_name,
              showedMajorItems: majorItems,
              facility_ids: res?.facility_ids || [],
              supplier_id: res?.supplier_id,
              selectedFunctions: function_id,
            });
          }
        }, () => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.SEARCH_ACCOUNT_TYPE));
      } else {
        this.setState({showedMajorItems: response});
      }
    });
  }

  /**
   * getMajorItemIdFromFunctionId
   * @param {number} functionId
   * @param {object} majorItems
   * @return {number}
   */
  getMajorItemFromFunctionId = (functionId, majorItems) => {
    for (const majorItem of majorItems) {
      for (const mediumItem of majorItem.sub_functions) {
        if (mediumItem.sub_functions.find((action) => action.id === functionId)) {
          return majorItem;
        }
      }
    }
    return null;
  };

  /**
   * changeBusiness
   * @param {*} value
   */
  changeBusiness = (value) => {
    const nextBusiness = this.props.common?.supplier_list?.find((supplier) => supplier.id === value);
    const selectedFunctionsCopy = [...this.state.selectedFunctions];
    if (nextBusiness?.business_role_type === 'BUSINESS') {
      FUNCTION_IDS_ROLE_BUSINESS_CANNOT_ACCESS.forEach((functionId) => {
        const index = selectedFunctionsCopy.indexOf(functionId);
        (index >= 0) && selectedFunctionsCopy.splice(index, 1);
      });
    }
    this.setState({supplier_id: value, facility_ids: [], selectedFunctions: selectedFunctionsCopy}, () => {
      this.props.getListFacilityBySupplierID(this.state.supplier_id);
    });
  };

  /**
   * handleChangeFacility
   * @param {Boolean} checked
   * @param {Number} facilityId
   */
  handleChangeFacility = (checked, facilityId) => {
    const {facility_ids} = this.state;
    if (checked) {
      facility_ids.push(facilityId);
    } else {
      const index = facility_ids.findIndex((id) => id === facilityId);
      facility_ids.splice(index, 1);
    }

    this.setState({facility_ids});
  }

  /**
   *
   * @param {*} value
   * @param {*} item
   */
  changeFilterMajorItems = (value, item) => {
    const availableFilteredFunctionList = [];
    let showedMajorItemsCopy = [...this.state.showedMajorItems];
    const index = showedMajorItemsCopy.indexOf(item);
    value ? showedMajorItemsCopy.push(item) : showedMajorItemsCopy.splice(index, 1);

    showedMajorItemsCopy = showedMajorItemsCopy.sort((a, b) => a?.id - b?.id);

    for (const majorItem of showedMajorItemsCopy) {
      for (const mediumItem of majorItem.sub_functions) {
        for (const action of mediumItem.sub_functions) {
          availableFilteredFunctionList.push(action.id);
        }
      }
    }

    const selectedFunctionsCopy = this.state.selectedFunctions?.filter((funcId) => availableFilteredFunctionList.includes(funcId));
    this.setState({showedMajorItems: showedMajorItemsCopy, selectedFunctions: selectedFunctionsCopy});
  };

  /**
   *
   * @param {boolean} checked
   * @param {*} item
   */
  handleSelectFunction = (checked, item) => {
    const {selectedFunctions, showedMajorItems} = this.state;
    const selectedFunctionsCopy = selectedFunctions ? [...selectedFunctions] : [];
    const index = selectedFunctionsCopy.indexOf(item);

    if (checked) {
      selectedFunctionsCopy.push(item);

      for (const majorItem of showedMajorItems) {
        for (const mediumItem of majorItem.sub_functions) {
          const action = mediumItem.sub_functions.find((action) => action.id === item);
          if (action) {
            if (action.constraint_ids) {
              // Add all constraint ids of selected functions
              const constraint_id = Number(action.constraint_ids);
              selectedFunctionsCopy.indexOf(constraint_id) < 0 && selectedFunctionsCopy.push(constraint_id);

              // Add parent next depth
              const itemParent = mediumItem.sub_functions.find((action) => action.id === constraint_id);
              if (itemParent && itemParent.constraint_ids) {
                const constraint_id_2 = Number(itemParent.constraint_ids);
                selectedFunctionsCopy.indexOf(constraint_id_2) < 0 && selectedFunctionsCopy.push(constraint_id_2);
              }
            }
            break;
          }
        }
      }
    } else {
      selectedFunctionsCopy.splice(index, 1);

      for (const majorItem of showedMajorItems) {
        for (const mediumItem of majorItem.sub_functions) {
          const action = mediumItem.sub_functions.find((action) => action.id === item);
          if (action) {
            // Remove all constraint ids of selected functions
            const listConstraintAction = mediumItem.sub_functions?.filter((item) => Number(item.constraint_ids) === action.id);
            const listConstraintActionId = listConstraintAction.map((item) => Number(item.id));

            let listFullConstraintActionId = [...listConstraintActionId];
            for (const actionId of listConstraintActionId) {
              const listChildren = mediumItem.sub_functions?.filter((item) => Number(item.constraint_ids) === actionId);
              const listChildrenId = listChildren.map((item) => Number(item.id));
              listFullConstraintActionId = listFullConstraintActionId.concat(listChildrenId);
            }

            listFullConstraintActionId.forEach((actionId) => {
              const pos = selectedFunctionsCopy.indexOf(actionId);
              pos >= 0 && selectedFunctionsCopy.splice(pos, 1);
            });
            break;
          }
        }
      }
    }

    this.setState({
      selectedFunctions: selectedFunctionsCopy,
    });
  };

  /**
   * handleUpdate
   */
  handleUpdate = () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid()) {
      this.setState({
        flg: true,
        message: 'messageCode.updateAccountType',
      });
    }
  };

  /**
   * handleCreate
   */
  handleCreate = () => {
    this.setState({
      isSubmitForm: true,
    });

    const {typeName, supplier_id, facility_ids} = this.state;
    const isSupplierRoleBusiness = this.props.common?.supplier_list?.find(({id}) => id === supplier_id)?.business_role_type === 'BUSINESS';
    const facilityIdsValidated = isSupplierRoleBusiness ? facility_ids.length > 0 : true;

    if (this.validator.allValid() && facilityIdsValidated) {
      const function_ids = this.getAllIdFromFunctionIds();

      const payload = {
        permission_name: typeName.trim(),
        supplier_id,
        facility_ids,
        function_ids,
      };
      this.props.createAccountType(payload, this.props);
    }
  };

  /**
   * @return {array} function_ids
   */
  getAllIdFromFunctionIds = () => {
    const {showedMajorItems} = this.state;
    const {selectedFunctions} = this.state;

    // Check selectedFunction has changed?
    // selectedFunctions = selectedFunctions ? selectedFunctions : [];
    // const prevSelectedFunction = this.props.account_type.accountTypeDetail.function_id ? this.props.account_type.accountTypeDetail.function_id : [];
    // const notChanged =
    //   !this.props.match.params.id || (selectedFunctions.length === prevSelectedFunction.length && selectedFunctions.every((item) => prevSelectedFunction.includes(item)));
    // if (notChanged) {
    //   return selectedFunctions;
    // }

    const selectedFunctionWithConstraint = [...selectedFunctions];
    const function_ids = [];

    // Get selected function ids and its constraint id
    // selectedFunctions.forEach((functionId) => {
    //   for (const majorItem of showedMajorItems) {
    //     for (const mediumItem of majorItem.sub_functions) {
    //       const checkedAction = mediumItem.actions.find((func) => func.id === functionId);
    //       if (checkedAction && checkedAction.constraint_ids) {
    //         // Push all constraint ids into selected functions
    //         // constraint_ids has format: '1,10,120'
    //         const constraint_action_ids = checkedAction.constraint_ids.split(',').map((item) => +item);
    //         selectedFunctionWithConstraint.push(...constraint_action_ids);
    //         break;
    //       }
    //     }
    //   }
    // });

    // Get all majorItemId, mediumItemId, functionId
    selectedFunctionWithConstraint.forEach((functionId) => {
      for (const majorItem of showedMajorItems) {
        for (const mediumItem of majorItem.sub_functions) {
          const checkedAction = mediumItem.sub_functions.find((func) => func.id === functionId);
          if (checkedAction) {
            function_ids.push(...[majorItem.id, mediumItem.id, functionId]);
            break;
          }
        }
      }
    });
    return [...new Set(function_ids)];
  };

  /**
   * handleButtonUpdateCancel
   */
  handleButtonUpdateCancel = () => {
    this.setState({
      flg: false,
    });
  };

  /**
   * handleButtonUpdateOk
   */
  handleButtonUpdateOk = () => {
    this.setState({
      flg: false,
    });
    if (this.validator.allValid()) {
      const {typeName, supplier_id, facility_ids, id} = this.state;
      const function_ids = this.getAllIdFromFunctionIds();

      const payload = {
        id,
        permission_name: typeName.trim(),
        supplier_id,
        facility_ids: facility_ids,
        function_ids: function_ids,
      };
      this.props.updateAccountType(payload, this.props);
      this.props.getAccountTypeDetails(this.props.match.params.id);
    }
  };

  /**
   * Check if all functions of showedMajorItems is selected
   * @return {Boolean}
   */
  isAllFunctionsSelected = () => {
    const {showedMajorItems, selectedFunctions, supplier_id} = this.state;
    const {supplier_list} = this.props.common;
    const isSupplierRoleBusiness = supplier_id ? (supplier_list?.find((supplier) => supplier.id === supplier_id)?.business_role_type === 'BUSINESS') : false;
    const showedFunctionIds = [];

    showedMajorItems.forEach((majorItem) => {
      majorItem.sub_functions.forEach((mediumItem) => {
        mediumItem.sub_functions.forEach((func) => {
          if (!(isSupplierRoleBusiness && FUNCTION_IDS_ROLE_BUSINESS_CANNOT_ACCESS.includes(func.id))) {
            showedFunctionIds.push(func.id);
          }
        });
      });
    });

    return showedFunctionIds.every((id) => selectedFunctions.includes(id));
  }

  /**
   * Handle select all functions
   * @param {Boolean} checked
   */
  handleSelectAllFunctions = (checked) => {
    const {showedMajorItems, supplier_id} = this.state;
    const {supplier_list} = this.props.common;
    const isSupplierRoleBusiness = supplier_id ? (supplier_list?.find((supplier) => supplier.id === supplier_id)?.business_role_type === 'BUSINESS') : false;

    if (checked) {
      const selectedFunctions = [];
      showedMajorItems.forEach((majorItem) => {
        majorItem.sub_functions.forEach((mediumItem) => {
          mediumItem.sub_functions.forEach((func) => {
            const isFunctionDisabled = isSupplierRoleBusiness && FUNCTION_IDS_ROLE_BUSINESS_CANNOT_ACCESS.includes(func.id);
            if (!isFunctionDisabled) {
              selectedFunctions.push(func.id);
            };
          });
        });
      });
      this.setState({selectedFunctions});
    } else {
      this.setState({selectedFunctions: []});
    }
  }

  /**
   * @return {HTMLElement}
   */
  render() {
    const {t, common, account_type, actions} = this.props;
    const {showedMajorItems, supplier_id} = this.state;
    const {majorList} = account_type;
    const isSupplierRoleBusiness = common?.supplier_list?.find(({id}) => id === supplier_id)?.business_role_type === 'BUSINESS';

    const isEdit = !!this.props.match.params.id;

    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
    };

    return (
      <Card className="RegisterAccountType main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{isEdit ? t('accountType.detail.title') : t('accountType.register.title')}</h3>
            </Grid>
          </Grid>
          <br></br>
          <LoadingOverlay active={account_type?.isLoading || common?.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Box>
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      <h3>{isEdit ? t('accountType.detail.title') : t('accountType.register.title')}</h3>
                    </Grid>
                  </Grid>

                  {/* type name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('accountType.typeName')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <div className="box-group-input">
                          <TextField
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            name="typeName"
                            placeholder={t('accountType.typeName')}
                            variant="outlined"
                            onChange={(event) => onChangeTextField(this, event)}
                            value={this.state.typeName || ''}
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 50})}</div>
                        </div>
                        {this.validator.message('typeName', this.state.typeName.trim(), 'required|max:50')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.typeName, 'required') && (
                          <FormHelperText id="typeName" error>
                            {t('validation.required', {field: t('accountType.typeName')})}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm && !this.validator.check(this.state.typeName.trim(), 'max:50') && (
                          <FormHelperText id="name" error>
                            {t('validation.maxString', {value: 50})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* business person */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('accountType.supplierName')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'supplier_id',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.supplier_id ?
                              undefined :
                              () => (
                                <div className="font-12 color-disabled">
                                  {t('placeholder.required_select', {
                                    field: t('accountType.supplierName'),
                                  })}
                                </div>
                              )
                          }
                          disabled={isEdit}
                          value={this.state.supplier_id || ''}
                          onChange={(event) => this.changeBusiness(event.target.value)}
                        >
                          {common?.supplier_list.map((item, idx) => {
                            return (
                              <MenuItem value={item.id} key={idx}>
                                {item.supplier_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('supplier_id', this.state.supplier_id, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.supplier_id, 'required') && (
                          <FormHelperText id="typeName" error>
                            {t('validation.required', {field: t('accountType.supplierName')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Store name */}
                  {isSupplierRoleBusiness && (<Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('accountType.facilityName')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={6}>
                      <FormGroup row>
                        {this.state.supplier_id &&
                          common?.facility_list.map(({id, facility_name}) => {
                            const checked = this.state.facility_ids?.includes(id);
                            return (
                              <FormControlLabel
                                key={id}
                                labelPlacement="end"
                                control={
                                  <Checkbox
                                    checked={checked}
                                    className='checkbox_radio'
                                    onChange={(e) => this.handleChangeFacility(e.target.checked, id)}
                                  />
                                }
                                label={t(facility_name)}
                              />
                            );
                          })}
                      </FormGroup>
                      {/* add rule to validate */}
                      <Grid item xs={12} container alignItems='center'>
                        {this.state.isSubmitForm && isSupplierRoleBusiness && !this.validator.check(this.state.facility_ids, 'required') && (
                          <FormHelperText id="typeName" error>
                            {t('validation.required', {field: t('accountType.facilityName')})}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>)}

                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('accountType.majorItems')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormGroup row>
                        {majorList.map((item, index) => {
                          const checked = this.state.showedMajorItems.indexOf(item) > -1;
                          return (
                            <FormControlLabel
                              key={index}
                              labelPlacement="end"
                              control={
                                <Checkbox
                                  key={index}
                                  checked={checked}
                                  onChange={() => this.changeFilterMajorItems(!checked, item)}
                                  className="checkbox_radio"
                                />
                              }
                              label={t(item.function_name_code)}
                            />
                          );
                        })}
                      </FormGroup>
                    </Grid>
                  </Grid>
                </Box>

                <Box mt={2} className="scroll_area_700">
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('accountType.majorItems')}</TableCell>
                        <TableCell align="center">{t('accountType.mediumItem')}</TableCell>
                        <TableCell align="center">{t('accountType.function')}</TableCell>
                        <TableCell align="center">
                          <Checkbox
                            color='primary'
                            checked={this.isAllFunctionsSelected()}
                            onChange={(e) => this.handleSelectAllFunctions(e.target.checked)}
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {showedMajorItems.map((majorItem, i) => {
                        const {sub_functions} = majorItem;
                        const numRowSpan = majorItem.sub_functions.reduce((amu, curr) => {
                          const visibleActions = curr.sub_functions?.filter((action) => action.is_visible !== false);
                          return amu + visibleActions.length;
                        }, 0);
                        return (
                          <React.Fragment key={i}>
                            {sub_functions.map((mediumFunction, j) => {
                              const actions = mediumFunction.sub_functions;
                              const visibleActions = actions?.filter((action) => action.is_visible !== false);
                              return (
                                <React.Fragment key={j}>
                                  {visibleActions.map((func, k) => {
                                    const checked = this.state.selectedFunctions?.indexOf(func.id) >= 0;
                                    return (
                                      <TableRow className="table-row" key={k}>
                                        {j === 0 && k === 0 && <TableCell rowSpan={numRowSpan}>{t(majorItem.function_name_code)}</TableCell>}
                                        {k === 0 && (
                                          <TableCell align="left" rowSpan={visibleActions.length}>
                                            {t(mediumFunction.function_name_code)}
                                          </TableCell>
                                        )}
                                        <TableCell align="center">{func.action}</TableCell>
                                        <TableCell align="center">
                                          <Checkbox
                                            disabled={isSupplierRoleBusiness && FUNCTION_IDS_ROLE_BUSINESS_CANNOT_ACCESS.includes(func.id)}
                                            checked={checked}
                                            onChange={(event) => this.handleSelectFunction(event.target.checked, func.id)}
                                            color="primary"
                                            inputProps={{'aria-label': 'secondary checkbox'}}
                                          />
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </React.Fragment>
                              );
                            })}
                          </React.Fragment>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>

                <Grid container spacing={1} className="mt_20">
                  <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {!isEdit ? (
                      <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={this.handleCreate}>
                        {t('common.btnRegister')}
                      </Button>
                    ) : permission.canUpdate && (
                      <Button color="primary" variant="contained" className="button_margin" onClick={this.handleUpdate} endIcon={<CloudUploadIcon />}>
                        {t('common.btnUpdate')}
                      </Button>
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      className="button_margin button_color"
                      onClick={this.props.history.goBack}
                    >
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                </Grid>
                <Dialog
                  open={this.state.flg}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal onClickOk={this.handleButtonUpdateOk} onClickCancel={this.handleButtonUpdateCancel} message={this.state.message}></SelectModal>
                </Dialog>
                <br></br>
              </Container>
            </Card>
          </LoadingOverlay>
          <br></br>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    account_type: state.account_type,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListSupplier: () => dispatch(getListSupplier()),
    getListFacilityBySupplierID: (params) => dispatch(getListFacilityBySupplierID(params)),
    getAccountTypeFunctionList: () => dispatch(getAccountTypeFunctionList()),
    createAccountType: (payload, props) => dispatch(createAccountType(payload, props)),
    updateAccountType: (payload, props) => dispatch(updateAccountType(payload, props)),
    getAccountTypeDetails: (id) => dispatch(getAccountTypeDetails(id)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(AccountTypeForm)));
