/* eslint-disable max-len */
/* eslint-disable new-cap */
import React, {Component, Fragment} from 'react';

import {
  Card,
  Container,
  Grid,
  Button,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  CardHeader,
  Typography,
  Box,
  Select,
  MenuItem,
  Dialog,
  Modal,
  Backdrop,
  Paper,
  Checkbox,
  FormControl,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ChatIcon from '@material-ui/icons/Forum';
import InfoIcon from '@material-ui/icons/Info';
import StarRateIcon from '@material-ui/icons/StarRate';
import {format} from 'date-fns';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {
  ROUTE,
  SERVICE_TYPE,
  BOOKING_STATUS,
  DEPOSIT_STATUS,
  DELIVERY_STATUS_RESERVATION,
  DATA_NULL,
  PERMISSION_ACTIONS,
  PAYMENT_METHOD_SIMULATION_RESERVATION,
  PAYMENT_METHODS_ONETIME_PRICE,
  PARTNER_PAYMENT_STATUS,
  DEPOSIT_METHOD,
  ODM_BOOKING_TIME_TYPES,
  USER_TYPES,
  SPECIAL_PERSON_TYPE,
} from '../../../../common/constant';
import Memo from '../../../../components/newMemo';
import SelectModal from '../../../../components/selectModal';
import withPermissionGateway from '../../../../hoc/withPermissionGateway';
import {updateMemoApi} from '../../../../services/businessServices';
import {reservationGpsApi, polylineForBookingApi} from '../../../../services/reservationServices';
import {checkOwnerCard} from '../../../../stores/business/action';
import {getListGeofence} from '../../../../stores/common/actions';
import {searchListDriverVehicle} from '../../../../stores/driver_vehicle_association/action';
import {setMessageModal} from '../../../../stores/modal/actions';
import {
  changeNodeStatus,
  changeStatusPayment,
  detailReservation,
  retryPaymentCreditCard,
  updateReservation,
  adminChangePaymentMethod,
} from '../../../../stores/reservation/actions';
import {onChangeListData, onChangeSelect, convertStringNull, backForwardRouter, customDisplayCurrency} from '../../../../utils/common';
import {displayDateTime, displayDate, convertDatetimeUTC, roundedAndConvertTime} from '../../../../utils/datetime';
import {modalObj} from '../../../../utils/modal';
import SubscriptionDetail from '../CustomerBooking/fee_breakdown_detail';
import ChatHistory from './ChatHistory/index';
import MapWithPolylinePopup from './MapWithPolylinePopup';
import RatingDialog from './RatingDialog';
import './style.css';

/**
 * Reservation management detail view
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      booking_status_basic: '',
      deposit_status_basic: '',
      delivery_status: '',
      basic_information_response: {
        reservation_number: null,
        jit_order_id: '',
        jit_order_number: '',
        geofence_id: null,
        service_type: '',
        reservation_time: '',
        cancel_time: '',
        deposit_amount: null,
        city_code: '',
        trip_booking_status: '',
        driver_rating: {},
        requested_departure_time: '',
      },
      booking_response: {
        booking_status: '',
        drop_off_address: '',
        drop_off_time: null,
        id: null,
        number_of_user: null,
        picked_up_time: null,
        pickup_address: '',
        price: 0,
        product_name: null,
        scheduled_drop_off_time: '',
        scheduled_pickup_time: '',
        service_type: '',
        supplier_name: '',
        driver_code: '',
        user_subscription: [],
      },
      delivery_responses: [],
      payment_info: {},
      member_information_response: {
        birthday: '',
        current_age: 0,
        email: '',
        gender: '',
        id: null,
        member_name: '',
        member_ship_member: '',
        member_type: '',
        mobile: '',
      },
      reservationGps: {
        lat: '',
        lng: '',
      },
      driver_online_status: false,
      memos: [],
      note: '',
      data_response: null,

      listArea: [],
      confirmFlag: false,
      isUpdate: true,
      openChatHistoryModal: false,
      openMapWithPolylinePopup: false,
      openRatingDialog: false,
      hasPolyline: false,
      dataPolyline: {},
      countryCode: '',
      estimatedTime: {},
      payment_method: '',
      store_booking_status_basic: '',
      store_payment_method: '',
      confirmPaymentFlag: false,
      notification_booking_status_complete: true,
      temp_payment_method: '',
      temp_notification_booking_status_complete: true,
      selectPaymentFlag: false,
      confirmNotificationFlag: false,
      deposit_status: {},
      cardInfo: [],
      paymentRetry: '',
      visionRetryCreditPayment: false,
      paymentMethod: [],
      isBookingForPartner: false,
      flagSubscriptionDetail: false,
      flagSubscriptionDetailLatest: false,
      partner_coupon: '',
      old_booking_status_basic: '',
      flagMemo: false,
      old_memos: [],
      represent_user_information_response: {},
      assigment_booking_data: {},
      number_adult_special_origin: 0,
      number_child_special_origin: 0,
      number_adult_special_latest: 0,
      number_child_special_latest: 0,
    };
    this.handleButtonUpdateOk = this.handleButtonUpdateOk.bind(this);
    this.handleButtonUpdateCancel = this.handleButtonUpdateCancel.bind(this);
  }

  /**
   * convertDataDetails
   * @param {*} bookingStatus
   * @param {*} serviceType
   * @param {*} tripBookingStatus
   * @return {*}
   */
  convertDataDetails = (bookingStatus, serviceType, tripBookingStatus) => {
    if (serviceType === 'SHUTTLE_BUS_ON_DEMAND' && bookingStatus === 'SYSTEM_CANCEL') {
      return 'RESERVATION_NOT_ESTABLISHED';
    }
    if (serviceType === 'SHUTTLE_BUS_ON_DEMAND' && bookingStatus === 'TEMPORARY_ORDER_JIT_ONLY') {
      return 'NEW_ORDER';
    }
    if (bookingStatus === 'USER_ORDER' && tripBookingStatus === 'OFFER_ACCEPT_SUCCESS') {
      return 'USER_ORDER_NOT_PICK_UP';
    }
    if (bookingStatus === 'USER_ORDER' && tripBookingStatus === 'PICKUP') {
      return 'USER_ORDER_PICK_UP';
    }
    if (bookingStatus === 'OPERATOR_ORDER' && tripBookingStatus === 'OFFER_ACCEPT_SUCCESS') {
      return 'OPERATOR_ORDER_NOT_PICK_UP';
    }
    if (bookingStatus === 'OPERATOR_ORDER' && tripBookingStatus === 'PICKUP') {
      return 'OPERATOR_ORDER_PICK_UP';
    }
    return bookingStatus;
  };

  /**
   * componentDidMount
   */
  async componentDidMount() {
    window.scrollTo(0, 0);
    await this.props.getListGeofence({targetScreen: 'ENABLE_FOR_ADMIN_ALL'}).then((res) => {
      this.setState({
        listArea: res,
      });
    });
    if (this.props.match.path === ROUTE.LAYOUT + ROUTE.RESERVATION_MANAGEMENT_DETAIL + '/:service_type/:id') {
      await this.props.detailReservation(this.props.match.params.id, this.props.match.params.service_type).then(
        (response) => {
          const booking_status = response.basic_information_response?.booking_status;
          if (this.props.match.params.service_type !== SERVICE_TYPE[3].id && (booking_status === 'USER_ORDER' || booking_status === 'OPERATOR_ORDER')) {
            reservationGpsApi(this.props.match.params.id).then((response) => {
              if (response.status === 200) {
                const reservationGps = {
                  lat: response.result.lat,
                  lng: response.result.lon,
                };
                this.setState({reservationGps: reservationGps});
              }
            });
          }
          if (response?.member_information_response?.member_type === 'NON_MEMBER') {
            const paymentMethodSim = response?.booking_response?.payment_method_support;
            const paymentMethod = PAYMENT_METHODS_ONETIME_PRICE.filter((payment) => paymentMethodSim?.includes(payment.id) && payment.id !== 'CREDIT_CARD');
            this.setState({paymentMethod: paymentMethod || []});
          } else {
            const paymentMethodSim = response?.booking_response?.payment_method_support;
            const paymentMethod = PAYMENT_METHODS_ONETIME_PRICE.filter((payment) => paymentMethodSim?.includes(payment.id));
            this.setState({paymentMethod: paymentMethod || []});
            this.confirmCreditCard(response.member_information_response?.id);
          }
          this.setState({
            booking_status_basic: this.convertDataDetails(
              booking_status,
              response.basic_information_response?.service_type,
              response.basic_information_response?.trip_booking_status,
            ),
            old_booking_status_basic: this.convertDataDetails(
              booking_status,
              response.basic_information_response?.service_type,
              response.basic_information_response?.trip_booking_status,
            ),
            store_booking_status_basic: this.convertDataDetails(response.basic_information_response?.booking_status, response.basic_information_response?.service_type),
            notification_booking_status_complete: response?.booking_response?.enable_notification || true,
            temp_notification_booking_status_complete: response?.booking_response?.enable_notification || true,
            deposit_status_basic: response.basic_information_response?.deposit_status,
            basic_information_response: {
              reservation_number: response.basic_information_response?.reservation_number,
              geofence_id: response.basic_information_response?.geofence_id,
              service_type: response.basic_information_response?.service_type,
              reservation_time: response.basic_information_response?.reservation_time,
              cancel_time: response.basic_information_response?.cancel_time,
              deposit_amount: response.basic_information_response?.deposit_amount,
              city_code: response.basic_information_response?.city_code,
              trip_booking_status: response.basic_information_response?.trip_booking_status,
              driver_rating: response.basic_information_response?.driver_rating,
              requested_departure_time: response.basic_information_response?.requested_departure_time,
            },
            booking_response: {
              booking_status: response.booking_response?.booking_status,
              drop_off_address: response.booking_response?.drop_off_address,
              drop_off_time: response.booking_response?.drop_off_time,
              id: response.booking_response?.id,
              number_of_user: response.basic_information_response?.demand?.passenger,
              picked_up_time: response.booking_response?.picked_up_time,
              pickup_address: response.booking_response?.pickup_address,
              price: response.booking_response?.price,
              product_name: response.booking_response?.facility_name,
              scheduled_drop_off_time: response.booking_response?.scheduled_drop_off_time,
              scheduled_pickup_time: response.booking_response?.scheduled_pickup_time,
              service_type: response.booking_response?.service_type,
              supplier_name: response.booking_response?.supplier_name,
              driver_code: response.basic_information_response?.driver_code,
              pickup_stop: response.booking_response?.pickup_stop,
              dropoff_stop: response.booking_response?.dropoff_stop,
              user_subscription: response.booking_response?.user_subscription?.data || [],
              one_time_config: response?.booking_response?.one_time_config || {},
            },
            payment_method: response.booking_response.payment_method,
            store_payment_method: response.booking_response.payment_method,
            temp_payment_method: response.booking_response.payment_method,
            payment_info: response.booking_response?.payment_info,
            delivery_responses: response.delivery_responses,
            member_information_response: {
              birthday: response.member_information_response?.birthday,
              current_age: response.member_information_response?.current_age,
              email: response.member_information_response?.email,
              gender: response.member_information_response?.gender,
              id: response.member_information_response?.id,
              member_name: response.member_information_response?.member_name,
              member_ship_member: response.member_information_response?.member_ship_member,
              member_type: response.member_information_response?.member_type,
              mobile: response.member_information_response?.mobile,
              member_id: response.member_information_response?.member_id,
            },
            memos: response.memos,
            old_memos: JSON.parse(JSON.stringify(response.memos)),
            data_response: response,
            deposit_status: response.booking_response?.payment_info?.payment_status,
            represent_user_information_response: response?.represent_user_information_response,
          });
        },
        () => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.RESERVATION_MANAGEMENT_SEARCH),
      );
      const objArea = this.state.listArea?.find((e) => e.geofence_id === this.state.basic_information_response.geofence_id);
      if (objArea) this.setState({countryCode: objArea.country.country_code});
      if (this.props.match.params.service_type !== SERVICE_TYPE[3].id) {
        // Check Polyline
        await polylineForBookingApi(this.props.match.params.id).then(
          (response) => {
            if (response.status === 200) {
              if (response.result?.polyline_for_user) {
                this.setState({
                  hasPolyline: true,
                  dataPolyline: {
                    polyline: response.result?.polyline_for_user,
                    type: 'booking',
                    pickUpPoint: {
                      lat: response.result?.offer_pickup_location_lat,
                      lng: response.result?.offer_pickup_location_lon,
                    },
                    dropOffPoint: {
                      lat: response.result?.offer_dropoff_location_lat,
                      lng: response.result?.offer_dropoff_location_lon,
                    },
                  },
                });
              }
              this.setState({
                estimatedTime: {
                  dropoff: response.result?.estimated_dropoff_time,
                  pickup: response.result?.estimated_pickup_time,
                },
                isBookingForPartner: response.result?.partner_coupon !== null,
                partner_coupon: response.result?.partner_coupon,
                assigment_booking_data: response?.result,
              });
            }
          },
          () => this.props.setMessageModal(modalObj(true, this.props.t('Api.fail'))),
        );
      }

      // Check available to change booking status by driver status and booking status
      await this.getDriverOnlineStatus();
    }
  }

  /**
   * handleUpdate
   */
  handleUpdate = () => {
    if (this.state.booking_status_basic === 'COMPLETED' && this.state.store_booking_status_basic !== 'COMPLETED') {
      this.setState({
        selectPaymentFlag: true,
        temp_payment_method: this.state.store_payment_method,
        temp_notification_booking_status_complete: this.state.notification_booking_status_complete,
      });
    } else if (this.state.booking_status_basic === 'OPERATOR_CANCEL' && this.state.store_booking_status_basic !== 'OPERATOR_CANCEL') {
      this.setState({confirmNotificationFlag: true, temp_notification_booking_status_complete: this.state.notification_booking_status_complete});
    } else {
      this.setState({
        confirmFlag: true,
        isUpdate: true,
        message: 'business.question.confirm.update',
      });
    }
  };

  /**
   * findAreaById
   * @param {event} id
   * @return {string} name
   */
  findAreaById = (id) => {
    const objArea = this.state.listArea?.find((e) => e.geofence_id === id);
    return objArea?.name;
  };

  /**
   * handleUpdateMemo
   */
  handleUpdateMemo = () => {
    this.setState({
      flagMemo: true,
      message: 'reservationManagement.update_memo_modal',
    });
  };

  /**
   * handleButtonCancel
   */
  handleButtonUpdateCancel = () => {
    this.setState({
      confirmFlag: false,
      paymentRetry: '',
    });
  };

  /**
   * handleMemoButtonUpdateOk
   */
  handleMemoButtonUpdateOk = () => {
    this.setState({
      flagMemo: false,
    });
    // update memo field
    const memos =
      this.state.memos?.length > 0 ?
        this.state.memos.map((memo) => ({
            id: memo.id,
            content: memo.content,
          })) :
        [];
    // check add new memo
    if (this.state.note) {
      memos.push({id: null, content: this.state.note});
    }
    const payload_update = {
      target_id: parseInt(this.props.match.params.id),
      user_type: USER_TYPES[1],
      memos: memos,
    };
    updateMemoApi(payload_update, this.props).then((res) => {
      if (res && res.status === 200) {
        this.props.detailReservation(this.props.match.params.id, this.props.match.params.service_type).then((response) => {
          if (response) {
            this.setState({memos: response.memos, note: '', old_memos: JSON.parse(JSON.stringify(response.memos))});
          }
        });
      } else {
        this.props.setMessageModal(modalObj(true, 'error.common'));
      }
    });
  };

  /**
   * handleMemoButtonUpdateOk
   */
  handleMemoButtonUpdateCancel = () => {
    this.setState({
      flagMemo: false,
    });
  };

  /**
   * changePaymentMethod
   * @param {event} payload
   * @param {event} payload_update
   * @param {event} old_payload_payment_method
   */
  changePaymentMethod = async (payload, payload_update, old_payload_payment_method) => {
    this.props.adminChangePaymentMethod(payload).then((res) => {
      if (res && res?.status === 200) {
        this.props.updateReservation(payload_update, this.props).then((res) => {
          if (res?.status === 200) {
            this.getDetailReservation();
          } else {
            this.props.adminChangePaymentMethod(old_payload_payment_method);
            this.getDetailReservation();
            res?.status === 500 && this.state.payment_method === 'CREDIT_CARD' && this.setState({confirmPaymentFlag: true});
          }
        });
      } else {
        this.state.payment_method === 'CREDIT_CARD' && this.setState({confirmPaymentFlag: true});
      }
    });
  };

  /**
   * handleButtonUpdateOk
   */
  handleButtonUpdateOk = async () => {
    this.setState({
      confirmFlag: false,
      confirmNotificationFlag: false,
      selectPaymentFlag: false,
      note: '',
    });
    await this.setState({
      notification_booking_status_complete: this.state.temp_notification_booking_status_complete,
      payment_method: this.state.temp_payment_method,
    });
    if (this.state.paymentRetry === 'CREDIT_CARD') {
      this.handleRetryPaymentCreditCard();
      return;
    }
    if (this.state.paymentRetry === 'OTHER') {
      this.handleRetryPaymentOther();
      return;
    }
    if (this.state.isUpdate) {
      const payload_update = {
        id: parseInt(this.props.match.params.id),
        memos: !!this.state.old_memos ? this.state.old_memos : [],
        note: '',
        status: this.convertBookingStatus(),
        zone_id: Intl.DateTimeFormat().resolvedOptions().timeZone,
        enable_notification: this.state.notification_booking_status_complete,
      };
      if (this.state.store_payment_method !== this.state.payment_method) {
        const payload = {
          booking_id: parseInt(this.props.match.params.id),
          payment_method: this.state.payment_method,
          update_by_admin: true,
        };
        const old_payload_payment_method = {
          booking_id: parseInt(this.props.match.params.id),
          payment_method: this.state.store_payment_method,
          update_by_admin: true,
        };
        this.changePaymentMethod(payload, payload_update, old_payload_payment_method);
      } else {
        await this.props.updateReservation(payload_update, this.props).then((res) => {
          if (res?.status === 200) {
            this.getDetailReservation();
          } else {
            if (res?.result && res?.result === 'OPERATOR_CANCEL_OR_COMPLETED') {
              this.props.setMessageModal(modalObj(true, 'update.reservation.request.invalid.to.complete.or.operator'));
            } else if (res?.result && res?.result === 'OPERATOR_CANCEL') {
              this.props.setMessageModal(modalObj(true, 'update.reservation.request.invalid.to.complete.operator'));
            }
            res?.status === 500 && this.state.payment_method === 'CREDIT_CARD' && this.setState({confirmPaymentFlag: true});
          }
        });
        await this.props.detailReservation(this.props.match.params.id, this.props.match.params.service_type);
      }
    } else {
      const node_drop_off = this.state.data_response?.booking_response?.node_bookings?.find((e) => e.node_type === 'dropoff');
      const payload = {
        transportation_type: this.state.data_response?.basic_information_response?.transportation_type,
        simulation_id: node_drop_off?.simulation_id,
        booking_id: node_drop_off?.booking_id,
        booking_uid: node_drop_off?.booking_uid,
        node_type: node_drop_off?.node_type,
        node_id: node_drop_off?.id,
        node_uid: node_drop_off?.uid,
        vehicle_id: node_drop_off?.assigned_vehicle_id,
      };
      this.props.changeNodeStatus(payload).then((res) => {
        if (res.status === 200) {
          this.setState({booking_status_basic: BOOKING_STATUS[9].id});
        }
      });
    }
  };

  handleButtonRetryPaymentOk = () => {
    this.setState({
      confirmPaymentFlag: false,
      note: '',
    });
    const payload_update = {
      id: parseInt(this.props.match.params.id),
      memos: !!this.state.memos ? this.state.memos : [],
      note: '',
      status: this.convertBookingStatus(),
      zone_id: Intl.DateTimeFormat().resolvedOptions().timeZone,
      enable_notification: this.state.notification_booking_status_complete,
    };
    const payload = {
      booking_id: parseInt(this.props.match.params.id),
      payment_method: this.state.payment_method,
      update_by_admin: true,
    };
    const old_payload_payment_method = {
      booking_id: parseInt(this.props.match.params.id),
      payment_method: this.state.store_payment_method,
      update_by_admin: true,
    };
    this.changePaymentMethod(payload, payload_update, old_payload_payment_method);
  };

  handleButtonRetryPaymentCancel = () => {
    this.setState({
      confirmPaymentFlag: false,
    });
  };
  /**
   * renderReservationStatusWithServiceType
   * @return {String}
   */
  renderReservationStatusWithServiceType = () => {
    if (this.state.data_response?.basic_information_response.service_type === 'SHUTTLE_BUS_ON_DEMAND') {
      const bookingStatus = [...BOOKING_STATUS];
      delete bookingStatus[1];
      delete bookingStatus[10];
      return bookingStatus;
    }
    if (this.state.data_response?.basic_information_response.service_type === 'SHUTTLE_BUS_JIT') {
      const bookingStatus = [...BOOKING_STATUS];
      delete bookingStatus[0];
      delete bookingStatus[9];
      return bookingStatus;
    }
    return BOOKING_STATUS;
  };

  /**
   * getDetailReservation
   */
  getDetailReservation = () => {
    this.props.detailReservation(this.props.match.params.id, this.props.match.params.service_type).then((res) => {
      this.setState({
        ...this.state,
        booking_status_basic: this.convertDataDetails(
          res.basic_information_response?.booking_status,
          res.basic_information_response?.service_type,
          res.basic_information_response?.trip_booking_status,
        ),
        booking_response: {
          ...this.state.booking_response,
          drop_off_time: res.booking_response?.drop_off_time,
          picked_up_time: res.booking_response?.picked_up_time,
        },
        basic_information_response: res?.basic_information_response,
        store_booking_status_basic: this.convertDataDetails(res.basic_information_response?.booking_status, res.basic_information_response?.service_type),
        trip_booking_status: res.basic_information_response?.trip_booking_status,
        memos: res.memos,
        payment_method: res?.booking_response?.payment_method,
        temp_payment_method: res?.booking_response?.payment_info,
        store_payment_method: res?.booking_response?.payment_method,
        payment_info: res?.booking_response?.payment_info,
        notification_booking_status_complete: res?.booking_response?.enable_notification,
        temp_notification_booking_status_complete: res?.booking_response?.enable_notification,
        data_response: res,
      });
    });
  };

  /**
   * change Reservation status
   * @param {Event} e
   */
  onChangeReservationStatus = async (e) => {
    // check driver status
    await this.getDriverOnlineStatus();
    // check status booking
    if (e.target.value === BOOKING_STATUS[9].id) {
      if (this.state.basic_information_response?.trip_booking_status === 'PICKUP' && !this.state.driver_online_status) {
        this.setState({
          confirmFlag: true,
          isUpdate: false,
          message: 'messageCode.complete_reservation',
        });
      } else {
        this.props.setMessageModal(modalObj(true, this.props.t('messageCode.can_not_complete_reservation')));
      }
    } else {
      this.setState({booking_status_basic: e.target.value});
    }
  };

  /**
   * getDriverOnlineStatus
   * @return {Promise}
   */
  getDriverOnlineStatus = async () => {
    if (!this.state.data_response?.booking_response?.vehicle_id) {
      return Promise.resolve([]);
    }
    const driverPayload = {
      from_time: format(new Date(), 'yyyy-MM-dd'),
      geofence_id: this.state.basic_information_response?.geofence_id,
      vehicle_id: this.state.data_response?.booking_response?.vehicle_id,
    };
    // check driver status
    return this.props.searchListDriverVehicle(driverPayload).then((res) => {
      if (res) {
        this.setState({driver_online_status: res[0]?.available || false});
      }
    });
  };

  /**
   * linkGoogleMap
   * @param {Number} lat
   * @param {Number} lng
   * @return {String}
   */
  linkGoogleMap = (lat, lng) => {
    return `https://maps.google.com/?q=${lat},${lng}`;
  };

  /**
   * convertBookingStatus
   * @return {*}
   */
  convertBookingStatus = () => {
    if (this.state.data_response?.basic_information_response.service_type === 'SHUTTLE_BUS_ON_DEMAND' && this.state.booking_status_basic === 'NEW_ORDER') {
      return 'TEMPORARY_ORDER_JIT_ONLY';
    }
    if (this.state.data_response?.basic_information_response.service_type === 'SHUTTLE_BUS_ON_DEMAND' && this.state.booking_status_basic === 'RESERVATION_NOT_ESTABLISHED') {
      return 'SYSTEM_CANCEL';
    }
    if (this.state.booking_status_basic === 'RESERVATION_NOT_ESTABLISHED') {
      return 'SYSTEM_CANCEL';
    }
    if (this.state.booking_status_basic === 'NEW_ORDER') {
      return 'TEMPORARY_ORDER_JIT_ONLY';
    }
    if (this.state.booking_status_basic === 'USER_ORDER_NOT_PICK_UP' || this.state.booking_status_basic === 'USER_ORDER_PICK_UP') {
      return 'USER_ORDER';
    }
    if (this.state.booking_status_basic === 'OPERATOR_ORDER_NOT_PICK_UP' || this.state.booking_status_basic === 'OPERATOR_ORDER_PICK_UP') {
      return 'OPERATOR_ORDER';
    }
    return this.state.booking_status_basic;
  };

  /**
   * closeChatHistoryModal
   */
  closeChatHistoryModal = () => {
    this.setState({openChatHistoryModal: false});
  };

  /**
   * closeMapWithPolylinePopup
   */
  closeMapWithPolylinePopup = () => {
    this.setState({openMapWithPolylinePopup: false});
  };

  closeRatingDialog = () => {
    this.setState({openRatingDialog: false});
  };

  /**
   * confirmCreditCard
   * @param {Number} id
   */
  confirmCreditCard = async (id) => {
    this.props.checkOwnerCard(id).then((response) => {
      const cardInfo = [];
      for (const card in response) {
        if (response[card].length > 0) {
          const cardData = response[card]?.find((item) => Number(item.default_card) === 1);
          if (cardData) {
            cardInfo.push({
              ...cardData,
              cardType: card === 'veritrans_cards' ? 'VERITRAINS' : card === '2c2p_cards' ? '2C2P' : 'ALEPAY',
            });
          }
        }
      }
      this.setState({
        cardInfo,
      });
      if (
        (response['2c2p_cards']?.length > 0 && this.state.countryCode === 'SG') ||
        (response['veritrans_cards']?.length > 0 && this.state.countryCode === 'JP') ||
        (response['alepay_cards']?.length > 0 && this.state.countryCode === 'VN') ||
        (response['2c2p_my_cards']?.length > 0 && this.state.countryCode === 'MY')
      ) {
        const paymentMethod = this.state.paymentMethod;
        this.setState({
          visionRetryCreditPayment: true,
          paymentMethod,
        });
      } else {
        const paymentMethod = this.state.paymentMethod.filter((payment) => payment.id !== 'CREDIT_CARD');
        this.setState({
          paymentMethod,
        });
      }
    });
  };

  /**
   * handleRetryPaymentCreditCard
   */
  handleRetryPaymentCreditCard = async () => {
    const bookingId = this.state.data_response?.booking_response?.id;
    await this.props.retryPaymentCreditCard({booking_id: bookingId}).then((res) => {
      if (res?.status === 200) {
        this.getDetailReservation();
      } else {
        this.props.setMessageModal(modalObj(true, 'reservationManagement.message.creditCardError'));
      }
    });
  };

  /**
   * handleRetryPaymentOther
   */
  handleRetryPaymentOther = async () => {
    const bookingId = this.state.data_response?.booking_response?.id;
    await this.props.changeStatusPayment({booking_id: bookingId}).then((res) => {
      if (res?.status === 200) {
        this.getDetailReservation();
      }
    });
  };

  /**
   * onClickClose subscription modal
   */
  onClickClose() {
    this.setState({flagSubscriptionDetail: false});
  }

  /**
   * onClickClose subscription latest modal
   */
  onClickCloseLatest() {
    this.setState({flagSubscriptionDetailLatest: false});
  }

  /**
   * Calculate shop partner discount amount
   * @param {Number} price
   * @param {Number} percent
   * @param {String} currencyCode
   * @return {Number}
   */
  calculateShopPartnerPercentDiscount = (price, percent, currencyCode) => {
    if (!price || !percent || !currencyCode) return 0;
    let discount = (price * percent) / 100;
    if (currencyCode === 'SGD' || currencyCode === 'MYR') {
      if (discount < 0) {
        discount = (Math.round(-discount * 100) / 100).toFixed(2);
        return (Math.round(-discount * 100) / 100).toFixed(2);
      }
      return (Math.round(discount * 100) / 100).toFixed(2);
    } else {
      return Math.round(discount);
    }
  };

  /**
   * displayNumberUser
   * @param {number} allow_infants
   * @param {String} type_data
   * @return {String}
   */
  displayNumberUser = (allow_infants, type_data) => {
    const {data_response, booking_response, assigment_booking_data} = this.state;
    const {t} = this.props;
    if (_.isEmpty(assigment_booking_data)) {
      return;
    }
    if (data_response.basic_information_response.service_type !== 'SHUTTLE_BUS_JIT' && data_response.basic_information_response.service_type !== 'SHUTTLE_BUS_ON_DEMAND') {
      return '';
    } else if (data_response.basic_information_response.service_type === 'SHUTTLE_BUS_JIT') {
      return `( ${data_response.basic_information_response?.demand?.no_adults} ${t('reservationManagement.adult')}, 
      ${data_response.basic_information_response?.demand?.no_children} ${t('reservationManagement.child')}, ${
        allow_infants ? (data_response.basic_information_response?.demand?.no_infants || 0).toString() + ' ' + t('reservationManagement.infant') + ',' : ''
      } ${booking_response?.user_subscription?.length} ${t('reservationManagement.user_sub')})`;
    } else {
      // number of users origin with special passenger
      if (type_data === 'origin') {
        const special_adult_list = assigment_booking_data?.special_passengers_init?.filter((special) => special.type === SPECIAL_PERSON_TYPE[0].value);
        const special_child_list = assigment_booking_data?.special_passengers_init?.filter((special) => special.type === SPECIAL_PERSON_TYPE[1].value);
        const number_special_adult = _.sumBy(special_adult_list, 'number');
        const number_special_child = _.sumBy(special_child_list, 'number');
        const number_adult_one_time = assigment_booking_data?.demand_init?.no_adults - number_special_adult;
        const number_child_one_time = assigment_booking_data?.demand_init?.no_children - number_special_child;
        let special_infor = '';
        const language = localStorage.getItem('i18nextLng');
        for (const special of assigment_booking_data?.special_passengers_init) {
          special_infor += ', ' + special.number.toString() + ' ' + special?.name[language];
        }
        return `( ${number_adult_one_time} ${t('reservationManagement.adult')}, 
        ${number_child_one_time} ${t('reservationManagement.child')}, ${
          allow_infants ? (data_response.basic_information_response?.demand?.no_infants || 0).toString() + ' ' + t('reservationManagement.infant') + ',' : ''
        } ${booking_response?.user_subscription?.length} ${t('reservationManagement.user_sub')}${special_infor} )`;
      } else {
        // number of users latest with special passenger
        const special_adult_list = assigment_booking_data?.special_passengers?.filter((special) => special.type === SPECIAL_PERSON_TYPE[0].value);
        const special_child_list = assigment_booking_data?.special_passengers.filter((special) => special.type === SPECIAL_PERSON_TYPE[1].value);
        const number_special_adult = _.sumBy(special_adult_list, 'number');
        const number_special_child = _.sumBy(special_child_list, 'number');
        const number_adult_one_time = assigment_booking_data?.demand?.no_adults - number_special_adult;
        const number_child_one_time = assigment_booking_data?.demand?.no_children - number_special_child;
        let special_infor = '';
        const language = localStorage.getItem('i18nextLng');
        for (const special of assigment_booking_data?.special_passengers) {
          special_infor += ', ' + special.number.toString() + ' ' + special?.name[language];
        }
        return `( ${number_adult_one_time} ${t('reservationManagement.adult')}, 
        ${number_child_one_time} ${t('reservationManagement.child')}, ${
          allow_infants ? (data_response.basic_information_response?.demand?.no_infants || 0).toString() + ' ' + t('reservationManagement.infant') + ',' : ''
        } ${booking_response?.user_subscription?.length} ${t('reservationManagement.user_sub')}${special_infor} )`;
      }
    }
  };

  /**
   * Render component
   * @return {object}
   */
  render() {
    const {t, reservation, actions, association} = this.props;
    const {
      data_response,
      listArea,
      basic_information_response,
      booking_status_basic,
      booking_response,
      isBookingForPartner,
      deposit_status_basic,
      countryCode,
      member_information_response,
      payment_info,
      deposit_status,
      cardInfo,
      delivery_responses,
      estimatedTime,
      represent_user_information_response,
      assigment_booking_data,
    } = this.state;
    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
      canUpdateMemo: actions.includes(PERMISSION_ACTIONS.UPDATE_MEMO),
      canMemberInfor: actions.includes(PERMISSION_ACTIONS.MEMBER_INFOR),
    };
    const no_infants = data_response?.basic_information_response?.demand?.no_infants;
    const allow_infants = listArea.find((area) => area.geofence_id === basic_information_response?.geofence_id)?.allow_infants || no_infants > 0;
    let number_discount_adult = 0;
    let number_discount_child = 0;
    let number_discount_adult_origin = 0;
    let number_discount_child_origin = 0;
    number_discount_adult = data_response?.basic_information_response?.demand?.number_discount_adult || 0;
    number_discount_child = data_response?.basic_information_response?.demand?.number_discount_child || 0;

    if (data_response?.booking_response?.price_init || data_response?.booking_response?.price_init === 0) {
      number_discount_adult_origin = this.state.assigment_booking_data?.demand_init?.number_discount_adult || 0;
      number_discount_child_origin = this.state.assigment_booking_data?.demand_init?.number_discount_child || 0;
      number_discount_adult = data_response?.basic_information_response?.demand?.number_discount_adult || 0;
      number_discount_child = data_response?.basic_information_response?.demand?.number_discount_child || 0;
    } else {
      number_discount_adult = data_response?.basic_information_response?.demand?.number_discount_adult || 0;
      number_discount_child = data_response?.basic_information_response?.demand?.number_discount_child || 0;
    }

    const ticket_usage = data_response?.booking_response?.ticket_info?.find((ticket) => ticket.number_discount_adult > 0 || ticket.number_discount_child > 0);
    let amount_discount_adult = 0;
    let amount_discount_child = 0;
    let amount_discount_adult_origin = 0;
    let amount_discount_child_origin = 0;
    amount_discount_adult = ticket_usage?.adult_amount;
    amount_discount_child = ticket_usage?.child_amount;

    if (data_response?.booking_response?.price_init || data_response?.booking_response?.price_init === 0) {
      amount_discount_adult_origin = this.state.assigment_booking_data?.price_discount_adult || 0;
      amount_discount_child_origin = this.state.assigment_booking_data?.price_discount_child || 0;
      amount_discount_adult = ticket_usage?.adult_amount;
      amount_discount_child = ticket_usage?.child_amount;
    } else {
      amount_discount_adult = ticket_usage?.adult_amount;
      amount_discount_child = ticket_usage?.child_amount;
    }

    const total_price_origin = data_response?.booking_response?.price_init || 0;
    const total_price_latest = data_response?.booking_response?.price || 0;
    // Advance booking price
    const isBookingAdvanceWithoutPartner = data_response?.booking_response?.booking_type === ODM_BOOKING_TIME_TYPES[1].id && !isBookingForPartner;
    const adult_price = data_response?.booking_response?.one_time_config?.origin_adult_price;
    const child_price = data_response?.booking_response?.one_time_config?.origin_child_price;

    const currency_code = data_response?.booking_response?.currency_code || 'JPY';

    let listAdultTicketUsed = [];
    let listChildTicketUsed = [];
    if (data_response?.booking_response?.ticket_usage?.length > 0) {
      listAdultTicketUsed = data_response?.booking_response?.ticket_usage?.filter((item) => item.ticket_type === 'ADULT');
      listChildTicketUsed = data_response?.booking_response?.ticket_usage?.filter((item) => item.ticket_type === 'CHILDREN');
    }
    let numberTicketAdult = 0;
    let numberTicketChild = 0;
    if (data_response?.booking_response?.ticket_usage?.length > 0) {
      const numberTicket = data_response?.booking_response?.ticket_usage;
      for (const sub of numberTicket) {
        if (sub?.ticket_type === 'ADULT') numberTicketAdult = numberTicketAdult + sub.number_of_use;
        else if (sub?.ticket_type === 'CHILDREN') numberTicketChild = numberTicketChild + sub.number_of_use;
      }
    }
    let numberAdultWithoutTicketOrigin = 0;
    let numberChildWithoutTicketOrigin = 0;
    let numberAdultWithoutTicketLatest = 0;
    let numberChildWithoutTicketLatest = 0;
    if (assigment_booking_data?.special_passengers_init?.length > 0) {
      const special_adult_list_origin = assigment_booking_data?.special_passengers_init?.filter((special) => special.type === SPECIAL_PERSON_TYPE[0].value);
      const special_child_list_origin = assigment_booking_data?.special_passengers_init?.filter((special) => special.type === SPECIAL_PERSON_TYPE[1].value);
      const number_special_adult_origin = _.sumBy(special_adult_list_origin, 'number');
      const number_special_child_origin = _.sumBy(special_child_list_origin, 'number');
      numberAdultWithoutTicketOrigin = assigment_booking_data?.demand_init?.no_adults - numberTicketAdult - number_special_adult_origin;
      numberChildWithoutTicketOrigin = assigment_booking_data?.demand_init?.no_children - numberTicketChild - number_special_child_origin;
    } else {
      numberAdultWithoutTicketOrigin = assigment_booking_data?.demand_init?.no_adults - numberTicketAdult;
      numberChildWithoutTicketOrigin = assigment_booking_data?.demand_init?.no_children - numberTicketChild;
    }

    if (assigment_booking_data?.special_passengers?.length > 0) {
      const special_adult_list_latest = assigment_booking_data?.special_passengers?.filter((special) => special.type === SPECIAL_PERSON_TYPE[0].value);
      const special_child_list_latest = assigment_booking_data?.special_passengers?.filter((special) => special.type === SPECIAL_PERSON_TYPE[1].value);
      const number_special_adult_latest = _.sumBy(special_adult_list_latest, 'number');
      const number_special_child_latest = _.sumBy(special_child_list_latest, 'number');
      numberAdultWithoutTicketLatest = data_response?.basic_information_response?.demand?.no_adults - numberTicketAdult - number_special_adult_latest;
      numberChildWithoutTicketLatest = data_response?.basic_information_response?.demand?.no_children - numberTicketChild - number_special_child_latest;
    } else {
      numberAdultWithoutTicketLatest = data_response?.basic_information_response?.demand?.no_adults - numberTicketAdult;
      numberChildWithoutTicketLatest = data_response?.basic_information_response?.demand?.no_children - numberTicketChild;
    }

    return (
      <LoadingOverlay active={reservation.isLoading || association.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('reservationManagement.booking_detail')}</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}></Grid>
            </Grid>
          </Container>
          {this.props.match.params.service_type !== SERVICE_TYPE[3].id && (
            <Container maxWidth="xl">
              <Box m={2} p={2}>
                <Card raised>
                  <Container maxWidth="xl">
                    <CardHeader
                      title={
                        <Typography gutterBottom variant="h5" component="h5">
                          {t('reservationManagement.basic_information')}
                        </Typography>
                      }
                    />
                    <Box pt={3} pb={3}>
                      <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.reservation_number_search')}</TableCell>
                            <TableCell>
                              {basic_information_response.reservation_number}
                              <Link
                                style={{textDecoration: 'none', marginLeft: 20}}
                                to={{
                                  pathname: ROUTE.LAYOUT + ROUTE.RESERVATION_MANAGEMENT_POLYLINE_TRACKING + '/' + this.props.match.params.id,
                                  state: {
                                    pickUpPoint: {
                                      lat: this.state.data_response?.booking_response?.pickup_point?.lat,
                                      lng: this.state.data_response?.booking_response?.pickup_point?.lng,
                                    },
                                    dropOffPoint: {
                                      lat: this.state.data_response?.booking_response?.drop_off_point?.lat,
                                      lng: this.state.data_response?.booking_response?.drop_off_point?.lng,
                                    },
                                  },
                                }}
                              >
                                <Button color="primary" variant="outlined">
                                  {t('reservationManagement.polyline_tracking')}
                                </Button>
                              </Link>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('jit.order_number')}</TableCell>
                            <TableCell>
                              {basic_information_response.jit_order_id ? (
                                <Link
                                  style={{textDecoration: 'none', color: '#3f51b5'}}
                                  to={{
                                    pathname: ROUTE.LAYOUT + ROUTE.JIT,
                                    state: {detailId: basic_information_response.jit_order_id || ''},
                                  }}
                                >
                                  {basic_information_response.jit_order_number}
                                </Link>
                              ) : (
                                basic_information_response.jit_order_number || DATA_NULL
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('common.geofence')}</TableCell>
                            <TableCell>{this.findAreaById(basic_information_response.geofence_id)}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.service_type')}</TableCell>
                            <TableCell>{basic_information_response.service_type === SERVICE_TYPE[0].id ? SERVICE_TYPE[0].id : basic_information_response.service_type}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.booking_status')}</TableCell>
                            <TableCell>
                              <Select
                                inputProps={{
                                  name: 'booking_status_basic',
                                }}
                                onChange={(event) => onChangeSelect(this, event)}
                                margin="dense"
                                labelWidth={60}
                                value={booking_status_basic}
                              >
                                {this.renderReservationStatusWithServiceType().map(({id, i18n}) => (
                                  <MenuItem value={id} key={id}>{`${t(i18n)}`}</MenuItem>
                                ))}
                              </Select>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.deposit_status')}</TableCell>
                            <TableCell>
                              {!!deposit_status_basic ? (
                                <Select
                                  inputProps={{
                                    name: 'deposit_status_basic',
                                  }}
                                  margin="dense"
                                  labelWidth={60}
                                  value={this.state.deposit_status_basic}
                                  onChange={(event) => onChangeSelect(this, event)}
                                >
                                  {DEPOSIT_STATUS.map(({id, i18n}) => {
                                    return <MenuItem key={id} value={id}>{`${t(i18n)}`}</MenuItem>;
                                  })}
                                </Select>
                              ) : (
                                DATA_NULL
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.total_amount_paid')}</TableCell>
                            <TableCell>
                              {customDisplayCurrency(
                                this.state.data_response?.basic_information_response?.deposit_amount_paid || 0,
                                this.state.data_response?.booking_response?.currency_code || 'JPY',
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </Container>
                </Card>
              </Box>
            </Container>
          )}
          {/* Booker Information */}
          <Container maxWidth="xl">
            <Box m={2} p={2}>
              <Card raised>
                <Container maxWidth="xl">
                  <CardHeader
                    title={
                      <Typography gutterBottom variant="h5" component="h5">
                        {t('reservationManagement.booker_information')}
                      </Typography>
                    }
                  />
                  <Box pt={3} pb={3}>
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell className="width_30">{t('memberManagement.memberId')}</TableCell>
                          <TableCell>{member_information_response.member_id || DATA_NULL}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('reservationManagement.memberName')}</TableCell>
                          <TableCell>{convertStringNull(member_information_response.member_name)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('reservationManagement.member_type')}</TableCell>
                          <TableCell>{member_information_response.member_type}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('reservationManagement.sex')}</TableCell>
                          <TableCell>{!!member_information_response.gender ? member_information_response.gender : DATA_NULL}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('reservationManagement.birthday')}</TableCell>
                          <TableCell>{!!member_information_response.birthday ? displayDate(member_information_response.birthday) : DATA_NULL}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('reservationManagement.current_age')}</TableCell>
                          <TableCell>{!!member_information_response.current_age ? member_information_response.current_age : DATA_NULL}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('reservationManagement.change_mail_address')}</TableCell>
                          <TableCell>{!!member_information_response.email ? member_information_response.email : DATA_NULL}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('common.phoneNumber')}</TableCell>
                          <TableCell>{!!member_information_response.mobile ? member_information_response.mobile : DATA_NULL}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Container>
              </Card>
            </Box>
          </Container>

          {/* Represent customer */}
          {represent_user_information_response && (
            <Container maxWidth="xl">
              <Box m={2} p={2}>
                <Card raised>
                  <Container maxWidth="xl">
                    <CardHeader
                      title={
                        <Typography gutterBottom variant="h5" component="h5">
                          {t('reservationManagement.representative_infor')}
                        </Typography>
                      }
                    />
                    <Box pt={3} pb={3}>
                      <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.memberName')}</TableCell>
                            <TableCell>{convertStringNull(represent_user_information_response.member_name)}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.sex')}</TableCell>
                            <TableCell>{!!represent_user_information_response.gender ? represent_user_information_response.gender : DATA_NULL}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.birthday')}</TableCell>
                            <TableCell>{!!represent_user_information_response.birthday ? displayDate(represent_user_information_response.birthday) : DATA_NULL}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.current_age')}</TableCell>
                            <TableCell>{!!represent_user_information_response.current_age ? represent_user_information_response.current_age : DATA_NULL}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('common.phoneNumber')}</TableCell>
                            <TableCell>{!!represent_user_information_response.mobile ? represent_user_information_response.mobile : DATA_NULL}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </Container>
                </Card>
              </Box>
            </Container>
          )}

          {/* QR */}
          {this.props.match.params.service_type === SERVICE_TYPE[3].id && (
            <Container maxWidth="xl">
              <Box m={2} p={2}>
                <Card raised>
                  <Container maxWidth="xl">
                    <CardHeader
                      title={
                        <Typography gutterBottom variant="h5" component="h5">
                          {t('deliveryServiceType.qr')}
                        </Typography>
                      }
                    />
                    <Box pt={3} pb={3}>
                      <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.usage_status')}</TableCell>
                            <TableCell>{this.state.data_response?.status}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.use_start_date')}</TableCell>
                            <TableCell>
                              {this.state.data_response?.start_time ? displayDateTime(convertDatetimeUTC(this.state.data_response?.start_time, countryCode)) : DATA_NULL}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.use_end_date')}</TableCell>
                            <TableCell>
                              {this.state.data_response?.end_time ? displayDateTime(convertDatetimeUTC(this.state.data_response?.end_time, countryCode)) : DATA_NULL}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.get_on_agency_name')}</TableCell>
                            <TableCell>{this.state.data_response?.agency_up || DATA_NULL}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.get_off_agency_name')}</TableCell>
                            <TableCell>{this.state.data_response?.agency_down || DATA_NULL}</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </Container>
                </Card>
              </Box>
            </Container>
          )}

          {/* Delivery/collection/delivery service */}
          {basic_information_response.service_type === SERVICE_TYPE[2].id && (
            <Container maxWidth="xl">
              <Box m={2} p={2}>
                <Card raised>
                  <Container maxWidth="xl">
                    <CardHeader
                      title={
                        <Typography gutterBottom variant="h5" component="h5">
                          {t('reservationManagement.delivery_collection_delivery_service')}
                        </Typography>
                      }
                    />
                    <Box pt={3} pb={3}>
                      <div className="scroll_area_700">
                        <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                          <TableHead>
                            <TableRow>
                              <TableCell className="width_150p">{t('reservationManagement.delivery_status')}</TableCell>
                              <TableCell className="width_150p">{t('reservationManagement.product_name')}</TableCell>
                              <TableCell className="width_100p">{t('reservationManagement.number')}</TableCell>
                              <TableCell className="width_150p">
                                {t('reservationManagement.price')} {t('reservationManagement.per_price')}
                              </TableCell>
                              <TableCell className="width_150p">{t('reservationManagement.business_name')}</TableCell>
                              <TableCell className="width_200p">{t('route.facilityManagement')}</TableCell>
                              <TableCell className="width_300p">{t('reservationManagement.detail.1')}</TableCell>
                              <TableCell className="width_250p">
                                {t('reservationManagement.delivery_address')}
                                <br />
                                {t('reservationManagement.delivery_phone_number')}
                              </TableCell>
                              <TableCell className="width_200p">{t('reservationManagement.reservation_date_and_time')}</TableCell>
                              <TableCell className="width_200p">{t('reservationManagement.scheduled_pickup_time')}</TableCell>
                              <TableCell className="width_200p">{t('reservationManagement.pickup_time')}</TableCell>
                              <TableCell className="width_300p">{t('reservationManagement.delivery_completion_date_and_time')}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {delivery_responses.map((item, index) => (
                              <TableRow className="cursor_pointer" key={index}>
                                <TableCell>
                                  <Select
                                    inputProps={{
                                      name: 'delivery_status',
                                    }}
                                    margin="dense"
                                    labelWidth={60}
                                    value={item.status}
                                    onChange={(e) => onChangeSelect(this, e)}
                                  >
                                    {DELIVERY_STATUS_RESERVATION.map(({id, i18n}) => {
                                      return (
                                        <MenuItem key={id} value={id}>
                                          {i18n}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </TableCell>
                                <TableCell>{item.product_name}</TableCell>
                                <TableCell>{item.number}</TableCell>
                                <TableCell>{item.price}</TableCell>
                                <TableCell>{item.supplier_name}</TableCell>
                                <TableCell>{item.facility_name}</TableCell>
                                <TableCell>{item.pickup_address + ' ' + item.pickup_phone_number}</TableCell>
                                <TableCell>{item.delivery_country + item.delivery_province + item.delivery_district + `\n` + item.delivery_phone_number}</TableCell>
                                <TableCell>{displayDateTime(item.reservation_date)}</TableCell>
                                <TableCell>{displayDateTime(item.scheduled_pick_up_date)}</TableCell>
                                <TableCell>{!!item.pickup_date ? displayDateTime(item.pickup_date) : DATA_NULL}</TableCell>
                                <TableCell>{displayDateTime(item.scheduled_delivery_date)}</TableCell>
                                <TableCell>{displayDateTime(item.delivery_completion_date)}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    </Box>
                  </Container>
                </Card>
              </Box>
            </Container>
          )}

          {/* Taxi/on-demand service */}
          {(this.props.match.params.service_type === SERVICE_TYPE[0].type[0] ||
            this.props.match.params.service_type === SERVICE_TYPE[0].type[1] ||
            this.props.match.params.service_type === SERVICE_TYPE[1].id) && (
            <Container maxWidth="xl">
              <Box m={2} p={2}>
                <Card raised>
                  <Container maxWidth="xl">
                    <CardHeader
                      title={
                        <Typography gutterBottom variant="h5" component="h5">
                          {basic_information_response.service_type === SERVICE_TYPE[0].type[1] ?
                            t('reservationManagement.taxi_jit_service') :
                            t('reservationManagement.taxi_on_demand_service')}
                        </Typography>
                      }
                    />
                    <Box pt={3} pb={3}>
                      <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell className="width_30">{t('driver.code')}</TableCell>
                            <TableCell>
                              {!!this.state.data_response?.basic_information_response?.driver_code ? this.state.data_response?.basic_information_response?.driver_code : DATA_NULL}
                              {(this.convertBookingStatus() === 'USER_ORDER' || this.convertBookingStatus() === 'OPERATOR_ORDER') && (
                                <Button
                                  style={{marginLeft: 10}}
                                  target="_blank"
                                  variant="outlined"
                                  color="primary"
                                  href={this.linkGoogleMap(this.state.reservationGps.lat, this.state.reservationGps.lng)}
                                >
                                  {t('newReservation.linkMap2')}
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t('reservationManagement.driverNameDefault')}</TableCell>
                            <TableCell>{this.state.data_response?.basic_information_response?.driver_name_app || DATA_NULL}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t('reservationManagement.driverNameSelectApp')}</TableCell>
                            <TableCell>{this.state.data_response?.basic_information_response?.selected_driver_name_app || DATA_NULL}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('businessVehicle.code_id')}</TableCell>
                            <TableCell>{!!this.state.data_response?.booking_response?.vehicle_id ? this.state.data_response?.booking_response?.vehicle_id : DATA_NULL}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('businessVehicle.vehicleNumber')}</TableCell>
                            <TableCell>
                              {!!this.state.data_response?.booking_response?.vehicle_number ? this.state.data_response?.booking_response?.vehicle_number : DATA_NULL}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.service_type')}</TableCell>
                            <TableCell>
                              {this.props.match.params.service_type === SERVICE_TYPE[0].id ||
                              this.props.match.params.service_type === SERVICE_TYPE[0].type[0] ||
                              this.props.match.params.service_type === SERVICE_TYPE[0].type[1] ?
                                SERVICE_TYPE[0].id :
                                this.props.match.params.service_type}
                            </TableCell>
                          </TableRow>
                          {(data_response?.booking_response?.price_init || data_response?.booking_response?.price_init === 0) && (
                            <TableRow>
                              <TableCell className="width_30">{t('reservationManagement.number_of_users_origin')}</TableCell>
                              <TableCell>
                                {!!booking_response.number_of_user ? booking_response.number_of_user + ' ' + this.displayNumberUser(allow_infants, 'origin') : DATA_NULL}
                              </TableCell>
                            </TableRow>
                          )}
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.number_of_users_latest')}</TableCell>
                            <TableCell>
                              {!!booking_response.number_of_user ? booking_response.number_of_user + ' ' + this.displayNumberUser(allow_infants, 'latest') : DATA_NULL}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('ticket.use_ride_ticket')}</TableCell>
                            <TableCell>
                              <p style={{margin: 0}}>
                                {t('ticket.adult_ticket')}:{' '}
                                {listAdultTicketUsed.length === 0 ? (
                                  <span>0 {t('ticket.ride', {count: 0})}</span>
                                ) : (
                                  listAdultTicketUsed.map((item, index) => (
                                    <Fragment key={index}>
                                      <span>
                                        {item.number_of_use} {t('ticket.ride', {count: item.number_of_use})} ({item.names[this.props.i18n.language || 'en']})
                                      </span>
                                      {index !== listAdultTicketUsed.length - 1 && ', '}
                                    </Fragment>
                                  ))
                                )}
                              </p>
                              <p style={{margin: 0}}>
                                {t('ticket.child_ticket')}:{' '}
                                {listChildTicketUsed.length === 0 ? (
                                  <span>0 {t('ticket.ride', {count: 0})}</span>
                                ) : (
                                  listChildTicketUsed.map((item, index) => (
                                    <Fragment key={index}>
                                      <span>
                                        {item.number_of_use} {t('ticket.ride', {count: item.number_of_use})} ({item.names[this.props.i18n.language || 'en']})
                                      </span>
                                      {index !== listChildTicketUsed.length - 1 && ', '}
                                    </Fragment>
                                  ))
                                )}
                              </p>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.business_name')}</TableCell>
                            <TableCell>{!!booking_response.supplier_name ? booking_response.supplier_name : DATA_NULL}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.sale_office')}</TableCell>
                            <TableCell>{!!booking_response.product_name ? booking_response.product_name : DATA_NULL}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.pickup_address')}</TableCell>
                            <TableCell>
                              {booking_response?.pickup_stop?.name_translations && booking_response?.pickup_stop?.name_translations[this.props.i18n.language] ?
                                booking_response?.pickup_stop?.name_translations[this.props.i18n.language] :
                                booking_response.pickup_address || DATA_NULL}
                              <Button
                                style={{marginLeft: 10}}
                                target="_blank"
                                variant="outlined"
                                color="primary"
                                href={this.linkGoogleMap(data_response?.booking_response?.pickup_point?.lat, data_response?.booking_response?.pickup_point?.lng)}
                              >
                                {t('newReservation.linkMap2')}
                              </Button>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.drop_off_address')}</TableCell>
                            <TableCell>
                              {booking_response?.dropoff_stop?.name_translations && booking_response?.dropoff_stop?.name_translations[this.props.i18n.language] ?
                                booking_response?.dropoff_stop?.name_translations[this.props.i18n.language] :
                                booking_response.drop_off_address || DATA_NULL}
                              <Button
                                style={{marginLeft: 10}}
                                target="_blank"
                                variant="outlined"
                                color="primary"
                                href={this.linkGoogleMap(data_response?.booking_response?.drop_off_point?.lat, data_response?.booking_response?.drop_off_point?.lng)}
                              >
                                {t('newReservation.linkMap2')}
                              </Button>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.reservation_date_and_time')}</TableCell>
                            <TableCell>
                              {!!data_response?.basic_information_response?.request_reservation_time ?
                                roundedAndConvertTime(data_response?.basic_information_response?.request_reservation_time, countryCode) :
                                DATA_NULL}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.reservation_confirm_date_and_time')}</TableCell>
                            <TableCell>
                              {!!basic_information_response.reservation_time ? roundedAndConvertTime(basic_information_response.reservation_time, countryCode) : DATA_NULL}
                            </TableCell>
                          </TableRow>
                          {data_response?.booking_response?.batch_run_time && (
                            <TableRow>
                              <TableCell className="width_30">{t('reservationManagement.reservation_reception_deadline')}</TableCell>
                              <TableCell>{roundedAndConvertTime(data_response?.booking_response?.batch_run_time, countryCode)}</TableCell>
                            </TableRow>
                          )}
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.cancellation_date_and_time')}</TableCell>
                            <TableCell>
                              {!!basic_information_response.cancel_time ? roundedAndConvertTime(basic_information_response.cancel_time, countryCode) : DATA_NULL}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{t('reservationManagement.expectPickUpTime')}</TableCell>
                            <TableCell>
                              {!!basic_information_response.requested_departure_time ?
                                roundedAndConvertTime(basic_information_response.requested_departure_time, countryCode) :
                                DATA_NULL}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.estimated_pickup_time')}</TableCell>
                            <TableCell>{!!estimatedTime.pickup ? roundedAndConvertTime(estimatedTime.pickup, countryCode) : DATA_NULL}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.estimated_drop_off_time')}</TableCell>
                            <TableCell>{!!estimatedTime.dropoff ? roundedAndConvertTime(estimatedTime.dropoff, countryCode) : DATA_NULL}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.scheduled_pickup_time')}</TableCell>
                            <TableCell>
                              {!!booking_response.scheduled_pickup_time ? roundedAndConvertTime(booking_response.scheduled_pickup_time, countryCode) : DATA_NULL}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.scheduled_drop_off_time')}</TableCell>
                            <TableCell>
                              {!!booking_response.scheduled_drop_off_time ? roundedAndConvertTime(booking_response.scheduled_drop_off_time, countryCode) : DATA_NULL}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.pickup_time')}</TableCell>
                            <TableCell>{!!booking_response.picked_up_time ? roundedAndConvertTime(booking_response.picked_up_time, countryCode) : DATA_NULL}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.new_drop_off_time')}</TableCell>
                            <TableCell>{!!booking_response.drop_off_time ? roundedAndConvertTime(booking_response.drop_off_time, countryCode) : DATA_NULL}</TableCell>
                          </TableRow>
                          {(data_response?.booking_response?.price_init || data_response?.booking_response?.price_init === 0) && (
                            <TableRow>
                              <TableCell className="width_30">{t('reservationManagement.amount_origin')}</TableCell>
                              <TableCell>
                                {customDisplayCurrency(total_price_origin, currency_code)}
                                <div>
                                  <Button color="primary" variant="contained" className="button_margin" onClick={() => this.setState({flagSubscriptionDetail: true})}>
                                    {t('common.btnDetail')}
                                  </Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          )}
                          <TableRow>
                            <TableCell className="width_30">{t('reservationManagement.amount_latest')}</TableCell>
                            <TableCell>
                              {customDisplayCurrency(total_price_latest, currency_code)}
                              <div>
                                <Button color="primary" variant="contained" className="button_margin" onClick={() => this.setState({flagSubscriptionDetailLatest: true})}>
                                  {t('common.btnDetail')}
                                </Button>
                              </div>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="width_30">{t('common.paymentMethod')}</TableCell>
                            <TableCell>
                              {this.state.data_response?.booking_response?.payment_method ?
                                t(PAYMENT_METHOD_SIMULATION_RESERVATION.filter((item) => item.id === this.state.data_response?.booking_response?.payment_method)[0]?.i18n) :
                                DATA_NULL}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Box>
                  </Container>
                </Card>
              </Box>
            </Container>
          )}

          {/* Deposit information */}
          <Container maxWidth="xl">
            <Box m={2} p={2}>
              <Card raised>
                <Container maxWidth="xl">
                  <CardHeader
                    title={
                      <Typography gutterBottom variant="h5" component="h5">
                        {t('reservationManagement.deposit_information')}
                      </Typography>
                    }
                  />
                  <Box pt={3} pb={3}>
                    <div className="scroll_area_700">
                      <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                        <TableHead>
                          <TableRow>
                            <TableCell className="width_150p ant-table-cell-fix-left">{t('reservationManagement.payment_id')}</TableCell>
                            <TableCell className="width_150p">{t('reservationManagement.deposit_status')}</TableCell>
                            <TableCell className="width_150p">{t('reservationManagement.deposit_method')}</TableCell>
                            <TableCell className="width_150p">{t('reservationManagement.total_billed_amount')}</TableCell>
                            <TableCell className="width_150p"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow className="cursor_pointer">
                            <TableCell className="ant-table-cell-fix-left">{payment_info?.payment_id || DATA_NULL}</TableCell>
                            <TableCell>
                              {payment_info?.payment_method === 'COMPLETE_WITHOUT_PAYMENT' &&
                              payment_info?.payment_status === 'UNPAID' &&
                              this.state.old_booking_status_basic === 'COMPLETED' ? (
                                <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_200">
                                  <Select
                                    margin="dense"
                                    inputProps={{
                                      name: 'deposit_status',
                                    }}
                                    displayEmpty
                                    renderValue={
                                      this.state.deposit_status ?
                                        undefined :
                                        () => (
                                            <div className="font-12 color-disabled">
                                              {t('placeholder.required_select', {
                                                field: t('reservationManagement.deposit_status'),
                                              })}
                                            </div>
                                          )
                                    }
                                    value={this.state.deposit_status}
                                    onChange={(event) => onChangeSelect(this, event)}
                                  >
                                    {PARTNER_PAYMENT_STATUS?.map((item, idx) => (
                                      <MenuItem value={item.id} key={idx}>
                                        {t(`${item.i18n}`)}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              ) : payment_info?.payment_status ? (
                                t(DEPOSIT_STATUS.find((item) => item.id === payment_info.payment_status)?.i18n) || ''
                              ) : (
                                DATA_NULL
                              )}
                            </TableCell>
                            <TableCell>
                              {payment_info?.payment_method ? t(DEPOSIT_METHOD.filter((method) => method.id === payment_info?.payment_method)[0].i18n) || DATA_NULL : DATA_NULL}
                            </TableCell>
                            <TableCell>
                              {customDisplayCurrency(
                                data_response?.basic_information_response?.deposit_amount_paid || 0,
                                this.state.data_response?.booking_response?.currency_code || 'JPY',
                              )}
                            </TableCell>
                            <TableCell>
                              {payment_info?.payment_method === 'COMPLETE_WITHOUT_PAYMENT' &&
                                payment_info?.payment_status === 'UNPAID' &&
                                this.state.old_booking_status_basic === 'COMPLETED' &&
                                cardInfo?.length > 0 &&
                                this.state.visionRetryCreditPayment && (
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    className="button_margin"
                                    onClick={() =>
                                      this.setState({
                                        confirmFlag: true,
                                        paymentRetry: 'CREDIT_CARD',
                                        message: 'business.question.confirm.paymentRetry',
                                      })
                                    }
                                  >
                                    {t('reservationManagement.creditCardRetry')}
                                  </Button>
                                )}
                              {payment_info?.payment_method === 'COMPLETE_WITHOUT_PAYMENT' &&
                                payment_info?.payment_status === 'UNPAID' &&
                                this.state.old_booking_status_basic === 'COMPLETED' && (
                                  <Button
                                    disabled={deposit_status === 'UNPAID'}
                                    color="primary"
                                    variant="contained"
                                    className="button_margin"
                                    onClick={() =>
                                      this.setState({
                                        confirmFlag: true,
                                        paymentRetry: 'OTHER',
                                        message: 'business.question.confirm.paymentRetry',
                                      })
                                    }
                                  >
                                    {t('reservationManagement.otherPaymentRetry')}
                                  </Button>
                                )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  </Box>
                </Container>
              </Card>
            </Box>
          </Container>

          {/* Cancellation information/rules */}
          <Container maxWidth="xl">
            <Box m={2} p={2}>
              <Card raised>
                <Container maxWidth="xl">
                  <Grid container spacing={2} className="memo_reservation">
                    <Memo
                      memos={this.state.memos}
                      onChangeContent={(value) => onChangeListData(this, this.state.memos, value.field, value.index, value.newValue)}
                      note={this.state.note}
                      onChangeNote={(value) => this.setState({note: value})}
                      parentType="medium"
                      countryCode={this.state.countryCode}
                    />
                  </Grid>
                </Container>
              </Card>
            </Box>
          </Container>
          <Container maxWidth="xl">
            <Box p={1}>
              <Grid container spacing={2}>
                <Grid container alignItems="center" justify="flex-start" item xs={6} style={{paddingLeft: 25}}>
                  <Button
                    color="primary"
                    variant="contained"
                    className="button_margin button_color_info"
                    endIcon={<ChatIcon />}
                    onClick={() => this.setState({openChatHistoryModal: true})}
                  >
                    {t('reservationManagement.chat_history')}
                  </Button>
                  <Button
                    style={{marginLeft: 10}}
                    target="_blank"
                    variant="outlined"
                    color="primary"
                    endIcon={<StarRateIcon />}
                    onClick={() => this.setState({openRatingDialog: true})}
                  >
                    {t('reservationManagement.driver_rating')}
                  </Button>
                </Grid>
                <Grid container alignItems="center" justify="flex-end" item xs={6}>
                  <Box p={2}>
                    {permission?.canMemberInfor && (
                      <Link
                        target="_blank"
                        style={{textDecoration: 'none'}}
                        to={{
                          pathname:
                            ROUTE.LAYOUT +
                            ROUTE.MEMBER_MANAGEMENT +
                            `?currentPage=0&rowsPerPage=10&statusList[]=REGISTRATION_COMPLETED&statusList[]=INCOMPLETE_REGISTRATION&memberId=${
                              member_information_response.member_id || ''
                            }`,
                        }}
                      >
                        <Button color="primary" variant="contained" className="button_margin">
                          {t('common.btnMemberInfor')}
                        </Button>
                      </Link>
                    )}
                    {permission.canUpdate && (
                      <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={() => this.handleUpdate()}>
                        {t('common.btnUpdate')}
                      </Button>
                    )}
                    {permission?.canUpdateMemo && (
                      <Button color="primary" variant="contained" className="button_color_info button_magin" endIcon={<CloudUploadIcon />} onClick={this.handleUpdateMemo}>
                        {t('common.btnUpdateMemo')}
                      </Button>
                    )}
                    <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.props.history.goBack}>
                      {t('common.btnReturn')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
          {/* Confirm Dialog */}
          <Dialog
            open={this.state.confirmFlag}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal onClickOk={this.handleButtonUpdateOk} onClickCancel={this.handleButtonUpdateCancel} message={this.state.message}></SelectModal>
          </Dialog>

          {/* Confirm Memo Dialog */}
          <Dialog
            open={this.state.flagMemo}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal onClickOk={this.handleMemoButtonUpdateOk} onClickCancel={this.handleMemoButtonUpdateCancel} message={this.state.message}></SelectModal>
          </Dialog>

          {/* Confirm Retry Payment Method */}
          <Dialog
            open={this.state.confirmPaymentFlag}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal
              okButtonText={t('common.btnRetry')}
              cancelButtonText={t('common.btnClose')}
              onClickOk={this.handleButtonRetryPaymentOk}
              onClickCancel={this.handleButtonRetryPaymentCancel}
              message={t('reservationManagement.message_fail_change_payment')}
            ></SelectModal>
          </Dialog>
          {/* Modal Chat History */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.openChatHistoryModal}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1000',
            }}
          >
            <>
              <ChatHistory onClickClose={this.closeChatHistoryModal} bookingId={this.props.match.params.id} countryCode={this.state.countryCode}></ChatHistory>
            </>
          </Modal>

          {/* Complete Reservation */}
          <Dialog
            open={this.state.selectPaymentFlag}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <Paper className="modal_size_mid">
              <Container maxWidth="md">
                <Grid container alignItems="center">
                  <Grid container alignItems="center" justifyContent="center" item xs={12}>
                    <span className="font_size_big" style={{whiteSpace: 'pre-line'}}>
                      {t('reservationManagement.completeReservation')}
                    </span>
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid container alignItems="center" justifyContent="center" item xs={2}>
                    <InfoIcon className="errorinfo" fontSize="large" />
                  </Grid>
                  <Grid item xs={10}>
                    <p style={{whiteSpace: 'pre-line'}}>{t('reservationManagement.completeReservation.title')}</p>
                  </Grid>
                </Grid>
                <Grid container alignItems="center">
                  <Grid item xs={2}></Grid>
                  <Grid item xs={3}>
                    {t('common.paymentMethod')}
                  </Grid>
                  <Grid item xs={7}>
                    <Select
                      inputProps={{
                        name: 'temp_payment_method',
                      }}
                      margin="dense"
                      labelWidth={60}
                      value={this.state.temp_payment_method}
                      onChange={(e) => onChangeSelect(this, e)}
                    >
                      {this.state.paymentMethod?.map(({id, i18n}) => {
                        return (
                          <MenuItem key={id} value={id}>
                            {t(i18n)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                </Grid>
                <br />
                {this.state.paymentMethod?.length === 0 && (
                  <Grid container alignItems="center">
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10} className="error">
                      {t('reservationManagement.completeReservation.errorMessage')}
                    </Grid>
                  </Grid>
                )}
                <br />
                <Grid container>
                  <Grid container justifyContent="center" item xs={2}>
                    <Checkbox
                      checked={this.state.temp_notification_booking_status_complete}
                      onChange={() => this.setState({temp_notification_booking_status_complete: !this.state.temp_notification_booking_status_complete})}
                      inputProps={{'aria-label': 'primary'}}
                      disabled={this.state.paymentMethod?.length === 0}
                    />
                  </Grid>
                  <Grid container alignItems="center" justifyContent="flex-start" item xs={10}>
                    <p>{t('reservationManagement.notificationMethod.subTitle')}</p>
                  </Grid>
                </Grid>
                <br />
                <Grid container>
                  <Grid container justifyContent="center" alignItems="flex-start" item xs={6}>
                    <Button color="primary" variant="contained" className="button_margin" onClick={this.handleButtonUpdateOk} disabled={this.state.paymentMethod?.length === 0}>
                      {t('common.btnConfirm')}
                    </Button>
                  </Grid>
                  <Grid container justifyContent="center" alignItems="flex-start" item xs={6}>
                    <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.setState({selectPaymentFlag: false})}>
                      {t('common.btnCancel')}
                    </Button>
                  </Grid>
                </Grid>
                <br />
              </Container>
            </Paper>
          </Dialog>

          {/* Confirm Send Notification */}

          <Dialog
            open={this.state.confirmNotificationFlag}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <Paper className="field_min_size_400">
              <Container maxWidth="xl">
                <br />
                <Grid container alignItems="center">
                  <Grid container alignItems="center" justifyContent="center" item xs={12}>
                    <span className="font_size_big" style={{whiteSpace: 'pre-line'}}>
                      {t('reservationManagement.completeReservation')}
                    </span>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid container alignItems="center" justifyContent="flex-end" item xs={2}>
                    <InfoIcon className="errorinfo" fontSize="large" />
                  </Grid>
                  <Grid container alignItems="center" justifyContent="flex-start" item xs={10}>
                    <p>{t('reservationManagement.notificationMethod.operatorCancel')}</p>
                  </Grid>
                  <Grid container justifyContent="flex-end" item xs={2}>
                    <Checkbox
                      checked={this.state.temp_notification_booking_status_complete}
                      onChange={() => this.setState({temp_notification_booking_status_complete: !this.state.temp_notification_booking_status_complete})}
                      inputProps={{'aria-label': 'primary'}}
                    />
                  </Grid>
                  <Grid container alignItems="center" justifyContent="flex-start" item xs={10}>
                    <p>{t('reservationManagement.notificationMethod.subTitle')}</p>
                  </Grid>
                  <br></br>
                  <Grid container alignItems="center" justifyContent="center" item xs={6}>
                    <Button fullWidth variant="contained" color="primary" onClick={this.handleButtonUpdateOk}>
                      {t('common.btnOk')}
                    </Button>
                  </Grid>
                  <Grid container alignItems="center" justifyContent="center" item xs={6}>
                    <Button fullWidth variant="contained" className="button_color" color="primary" onClick={() => this.setState({confirmNotificationFlag: false})}>
                      {t('common.btnCancel')}
                    </Button>
                  </Grid>
                </Grid>
              </Container>
              <br />
            </Paper>
          </Dialog>

          {/* Modal Map with polyline */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.openMapWithPolylinePopup}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1000',
            }}
          >
            <>
              <MapWithPolylinePopup onClickClose={this.closeMapWithPolylinePopup} dataPolyline={this.state.dataPolyline}></MapWithPolylinePopup>
            </>
          </Modal>

          {/* Rating Dialog */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.openRatingDialog}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1000',
            }}
          >
            <>
              <RatingDialog
                onClickClose={this.closeRatingDialog}
                ratingData={this.state.data_response?.basic_information_response?.driver_rating?.rating_details?.find(
                  (e) => Number(e.trip_id) === Number(this.props.match.params.id),
                )}
                driverId={this.state.data_response?.basic_information_response?.driver_id}
              ></RatingDialog>
            </>
          </Modal>
          <Dialog
            open={this.state.flagSubscriptionDetail}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
            maxWidth="lg"
          >
            <SubscriptionDetail
              detailCost={{
                no_adults: numberAdultWithoutTicketOrigin,
                no_children: numberChildWithoutTicketOrigin,
                infants: allow_infants ? no_infants : 0,
                partner_coupon: this.state.partner_coupon,
                one_time_discount: {
                  adultNumber: number_discount_adult_origin,
                  childNumber: number_discount_child_origin,
                  adultAmount: amount_discount_adult_origin,
                  childAmount: amount_discount_child_origin,
                },
                origin_adult_price: adult_price,
                origin_child_price: child_price,
                ticket_usage: data_response?.booking_response?.ticket_usage || [],
                oneTimeSurcharge: data_response?.booking_response?.one_time_config,
                currency_code,
                subscribed_member: booking_response?.user_subscription?.length,
                isBookingAdvanceWithoutPartner: isBookingAdvanceWithoutPartner,
                advance_booking_prices: data_response?.booking_response?.one_time_config?.advance_booking_prices,
                isDetailBooking: true,
                used_special_price: this.state.assigment_booking_data?.special_passengers_init,
                odm_booking_time_type: data_response?.booking_response?.booking_type,
              }}
              totalCost={Number(total_price_origin)}
              calculateDiscount={this.calculateShopPartnerPercentDiscount}
              onClickConfirm={() => this.onClickClose()}
            />
          </Dialog>
          <Dialog
            open={this.state.flagSubscriptionDetailLatest}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
            maxWidth="lg"
          >
            <SubscriptionDetail
              detailCost={{
                no_adults: numberAdultWithoutTicketLatest,
                no_children: numberChildWithoutTicketLatest,
                infants: allow_infants ? no_infants : 0,
                partner_coupon: this.state.partner_coupon,
                one_time_discount: {adultNumber: number_discount_adult, childNumber: number_discount_child, adultAmount: amount_discount_adult, childAmount: amount_discount_child},
                origin_adult_price: adult_price,
                origin_child_price: child_price,
                ticket_usage: data_response?.booking_response?.ticket_usage || [],
                oneTimeSurcharge: data_response?.booking_response?.one_time_config,
                currency_code,
                subscribed_member: booking_response?.user_subscription?.length,
                isBookingAdvanceWithoutPartner: isBookingAdvanceWithoutPartner,
                advance_booking_prices: data_response?.booking_response?.one_time_config?.advance_booking_prices,
                isDetailBooking: true,
                used_special_price: this.state.assigment_booking_data?.special_passengers,
                odm_booking_time_type: data_response?.booking_response?.booking_type,
              }}
              totalCost={Number(total_price_latest)}
              calculateDiscount={this.calculateShopPartnerPercentDiscount}
              onClickConfirm={() => this.onClickCloseLatest()}
            />
          </Dialog>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reservation: state.reservation,
    association: state.driverVehicleAssociation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    detailReservation: (id, service_type) => dispatch(detailReservation(id, service_type)),
    getListGeofence: (payload) => dispatch(getListGeofence(payload)),
    updateReservation: (payload, props) => dispatch(updateReservation(payload, props)),
    changeNodeStatus: (payload) => dispatch(changeNodeStatus(payload)),
    searchListDriverVehicle: (payload) => dispatch(searchListDriverVehicle(payload)),
    setMessageModal: (params) => dispatch(setMessageModal(params)),
    adminChangePaymentMethod: (payload) => dispatch(adminChangePaymentMethod(payload)),
    checkOwnerCard: (userId) => dispatch(checkOwnerCard(userId)),
    retryPaymentCreditCard: (bookingId) => dispatch(retryPaymentCreditCard(bookingId)),
    changeStatusPayment: (bookingId) => dispatch(changeStatusPayment(bookingId)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
