import {ROUTE} from '../../common/constant';
import {searchJitByAreaIdApi} from '../../services/jitOrderServices';
import {
  detailReservationApi,
  listReservationSearchApi,
  searchRoutesBookingApi,
  updateReservationApi,
  getMemberSubscriptionApi,
  bookingHistoryApi,
  changeNodeStatusApi,
  removeSlotApi,
  searchReservationSummaryApi,
  getReservationDetailSummaryApi,
  changePaymentMethodApi,
  getMessageTemplateApi,
  chatHistoryApi,
  searchBookingJITApi,
  getJitRoutePolylineApi,
  bookingJitApi,
  findDurationApi,
  getCsvExportReservationUuidApi,
  adminChangePaymentMethodApi,
  changePaymentStatusApi,
  retryPaymentCreditCardApi,
  getCsvExportRatingUuidApi,
  getAdvanceBookingOffersApi,
  searchBusReservationApi,
  getBusReservationDetailApi,
} from '../../services/reservationServices';
import {backForwardRouter} from '../../utils/common';
import {modalObj} from '../../utils/modal.js';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

// get map's data
export const getDataMapsStart = () => {
  return {
    type: ACTION_TYPES.MAP_GET_DATA_START,
  };
};

export const getDataMapsDone = () => {
  return {
    type: ACTION_TYPES.MAP_GET_DATA_DONE,
  };
};

// keep search booking condition
export const searchBookingUpdate = (data) => {
  return {
    type: ACTION_TYPES.SEARCH_BOOKING_UPDATE,
    searchBooking: data,
  };
};

export const searchBookingReset = (data) => {
  return {
    type: ACTION_TYPES.SEARCH_BOOKING_RESET,
    searchBooking: {},
  };
};

export const updateBooking = (data) => {
  return {
    type: ACTION_TYPES.UPDATE_BOOKING,
    bookingInfo: data,
  };
};

/**
 * search list reservation
 * @param {object} payload
 * @param {object} queryParams
 * @param {object} props
 * @return {object}
 */
export const searchReservation = (payload, queryParams) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SEARCH_RESERVATION_START,
    });
    return listReservationSearchApi(payload, queryParams).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.SEARCH_RESERVATION_SUCCESS,
            listReservation: response.result.content,
          });
          return response.result;
        } else {
          dispatch({
            type: ACTION_TYPES.SEARCH_RESERVATION_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.SEARCH_RESERVATION_FAIL,
        }),
    );
  };
};

export const searchBookingJIT = (userId, payload) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SEARCH_BOOKING_JIT_START,
    });
    return searchBookingJITApi(userId, payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.SEARCH_BOOKING_JIT_SUCCESS,
            listBookingJIT: response.result,
          });
          return response.result;
        } else {
          dispatch({
            type: ACTION_TYPES.SEARCH_BOOKING_JIT_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.SEARCH_BOOKING_JIT_FAIL,
        }),
    );
  };
};

/**
 * searchReservationSummary
 * @param {object} payload
 * @return {object}
 */
export const searchReservationSummary = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.SEARCH_RESERVATION_START});
    return searchReservationSummaryApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.SEARCH_RESERVATION_SUCCESS,
            listReservation: response.result.content,
          });
          return response.result;
        } else dispatch({type: ACTION_TYPES.SEARCH_RESERVATION_FAIL});
      },
      () => dispatch({type: ACTION_TYPES.SEARCH_RESERVATION_FAIL}),
    );
  };
};

/**
 * getReservationDetailSummary
 * @param {Number} bookingID
 * @return {object}
 */
export const getReservationDetailSummary = (bookingID) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.DETAIL_RESERVATION_START});
    return getReservationDetailSummaryApi(bookingID).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.DETAIL_RESERVATION_SUCCESS,
            detailReservation: response.result,
          });
          return response.result;
        } else dispatch({type: ACTION_TYPES.DETAIL_RESERVATION_FAIL});
      },
      () => dispatch({type: ACTION_TYPES.DETAIL_RESERVATION_FAIL}),
    );
  };
};

/**
 * search route booking
 * @param {Object} payload
 * @return {Array}
 */
export const searchRoutesBooking = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_ROUTES_BOOKING_START,
    });
    return searchRoutesBookingApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_ROUTES_BOOKING_SUCCESS,
            listRoutes: response.result,
          });
          return response;
        } else {
          dispatch({
            type: ACTION_TYPES.GET_ROUTES_BOOKING_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_ROUTES_BOOKING_FAIL,
          listRoutes: [],
        }),
    );
  };
};

/**
 * detailReservation
 * @param {*} id
 * @param {*} service_type
 * @param {*} props
 * @return {*}
 */
export const detailReservation = (id, service_type, props) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.DETAIL_RESERVATION_START,
    });
    return detailReservationApi(id, service_type).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.DETAIL_RESERVATION_SUCCESS,
            detailReservation: response.result,
          });
          return response.result;
        } else {
          if (response.message === 'permission.denied') {
            dispatch(setMessageModal(modalObj(true, 'permission.reservation.denied')));
            backForwardRouter(props, ROUTE.LAYOUT + ROUTE.HOME);
          }
          dispatch({
            type: ACTION_TYPES.DETAIL_RESERVATION_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.DETAIL_RESERVATION_FAIL,
        }),
    );
  };
};

export const searchDuration = (route_type, driving_mode, routes) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.FIND_DURATION_START,
    });
    return findDurationApi(route_type, driving_mode, routes).then(
      (response) => {
        if (response && response.status === 200) {
          return response.json().then((res) => {
            dispatch({
              type: ACTION_TYPES.FIND_DURATION_SUCCESS,
              listDuration: res.routes,
            });
            return res.routes;
          });
        } else {
          dispatch({
            type: ACTION_TYPES.FIND_DURATION_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.FIND_DURATION_FAIL,
        }),
    );
  };
};

/**
 * update reservation
 * @param {object} payload
 * @param {object} props
 * @return {object}
 */
export const updateReservation = (payload, props) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_RESERVATION_START,
    });
    return updateReservationApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.UPDATE_RESERVATION_SUCCESS,
          });
          return response;
        } else if (response?.status === 500) {
          dispatch({
            type: ACTION_TYPES.UPDATE_RESERVATION_FAIL,
          });
          return response;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.UPDATE_RESERVATION_FAIL,
          });
          return response;
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.UPDATE_RESERVATION_FAIL,
        }),
    );
  };
};

/**
 * Export list reservation
 * @param {object} payload
 * @param {object} queryParams
 * @return {object}
 */
export const getCsvExportReservationUuid = (payload, queryParams) => {
  return (dispatch) => {
    return getCsvExportReservationUuidApi(payload, queryParams).then(
      (response) => {
        if (response && response.status === 200) {
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response?.message_code)));
        }
      },
      () => dispatch(setMessageModal(modalObj(true, 'Internal Server Error'))),
    );
  };
};

/**
 * get member's subscriptions
 * @param {Number} user_id
 * @param {String} geofence_id
 * @return {Array}
 */
export const getMemberSubscription = (user_id, geofence_id) => {
  return (dispatch) => {
    return getMemberSubscriptionApi(user_id, geofence_id).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_MEMBER_SUBSCRIPTION,
            listSubscription: response.result,
          });
          return response;
        } else {
          dispatch({
            type: ACTION_TYPES.GET_MEMBER_SUBSCRIPTION,
            listSubscription: [],
          });
          return response;
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_MEMBER_SUBSCRIPTION,
          listSubscription: [],
        }),
    );
  };
};

/**
 * get booking history
 * @param {Number} userId
 * @param {Object} queryParams
 * @return {Array}
 */
export const getBookingHistory = (userId, queryParams) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_BOOKING_HISTORY_START,
    });
    return bookingHistoryApi(userId, queryParams).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_BOOKING_HISTORY_SUCCESS,
            booking_history: response.result,
          });
          return response;
        } else {
          dispatch({
            type: ACTION_TYPES.GET_BOOKING_HISTORY_FAIL,
            booking_history: [],
          });
          return response;
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_BOOKING_HISTORY_FAIL,
          booking_history: [],
        }),
    );
  };
};

/**
 * change node booking status
 * @param {Object} payload
 * @return {*}
 */
export const changeNodeStatus = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.CHANGE_NODE_STATUS_START,
    });
    return changeNodeStatusApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.CHANGE_NODE_STATUS_SUCCESS,
          });
          return response;
        } else {
          dispatch({
            type: ACTION_TYPES.CHANGE_NODE_STATUS_FAIL,
          });
          return response;
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_BOOKING_HISTORY_FAIL,
        }),
    );
  };
};

/**
 * removeSlotSubs
 * @param {Object} payload
 * @return {*}
 */
export const removeSlotSubs = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.REMOVE_SLOT_START,
    });
    return removeSlotApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.REMOVE_SLOT_SUCCESS,
          });
        } else {
          dispatch({
            type: ACTION_TYPES.REMOVE_SLOT_FAIL,
          });
        }
        dispatch(setMessageModal(modalObj(true, response.message_code)));
      },
      () => {
        dispatch({
          type: ACTION_TYPES.REMOVE_SLOT_FAIL,
        });
        dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
      },
    );
  };
};

/**
 * changePaymentMethod
 * @param {Object} payload
 * @return {*}
 */
export const changePaymentMethod = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.CHANGE_PAYMENT_METHOD_START});
    return changePaymentMethodApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({type: ACTION_TYPES.CHANGE_PAYMENT_METHOD_SUCCESS});
        } else dispatch({type: ACTION_TYPES.CHANGE_PAYMENT_METHOD_FAIL});
        dispatch(setMessageModal(modalObj(true, response.message_code)));
      },
      () => {
        dispatch({type: ACTION_TYPES.CHANGE_PAYMENT_METHOD_FAIL});
        dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
      },
    );
  };
};

/**
 * getMessageTemplate
 * @return {*}
 */
export const getMessageTemplate = () => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_MESSAGE_TEMPLATE_START});
    return getMessageTemplateApi().then(
      (response) => {
        if (response) {
          dispatch({type: ACTION_TYPES.GET_MESSAGE_TEMPLATE_SUCCESS});
          return response;
        } else {
          dispatch({type: ACTION_TYPES.GET_MESSAGE_TEMPLATE_FAIL});
          dispatch(setMessageModal(modalObj(true, 'Api.fail')));
        }
      },
      () => {
        dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
        dispatch({type: ACTION_TYPES.GET_MESSAGE_TEMPLATE_FAIL});
      },
    );
  };
};

/**
 * getChatHistory
 * @param {Number} bookingId
 * @return {*}
 */
export const getChatHistory = (bookingId) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_CHAT_HISTORY_START});
    return chatHistoryApi(bookingId).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_CHAT_HISTORY_SUCCESS,
            chatHistory: response?.result,
          });
          return response?.result;
        } else {
          dispatch({type: ACTION_TYPES.GET_CHAT_HISTORY_FAIL});
          dispatch(setMessageModal(modalObj(true, response.message_code)));
        }
      },
      () => {
        dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
        dispatch({type: ACTION_TYPES.GET_CHAT_HISTORY_FAIL});
      },
    );
  };
};

/**
 * search route booking
 * @param {string} routeCode
 * @return {Array}
 */
export const searchJitByAreaId = (routeCode) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_ROUTES_BOOKING_JIT_START,
    });
    return searchJitByAreaIdApi(routeCode).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_ROUTES_BOOKING_JIT_SUCCESS,
            listJitRoutes: response.result,
          });
          return response.result;
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_ROUTES_BOOKING_JIT_FAIL,
          listRoutes: [],
        }),
    );
  };
};

/**
 * search route booking
 * @param {Object} payload
 * @return {Array}
 */
export const getJitRoutePolyLine = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_JIT_ROUTE_POLYLINE_START,
    });
    return getJitRoutePolylineApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_JIT_ROUTE_POLYLINE_SUCCESS,
            routePolyline: response.result,
          });
          return response.result;
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_JIT_ROUTE_POLYLINE_FAIL,
          listRoutes: [],
        }),
    );
  };
};

/**
 * booking jit
 * @param {Object} payload
 * @return {Array}
 */
export const bookingJit = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.BOOKING_JIT_START,
    });
    return bookingJitApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.BOOKING_JIT_SUCCESS,
            jitBookingInfor: response.result,
          });
          dispatch(setMessageModal(modalObj(true, 'successful_booking')));
          return response.result;
        } else {
          dispatch({type: ACTION_TYPES.BOOKING_JIT_FAIL});
          dispatch(setMessageModal(modalObj(true, response.message_code)));
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.BOOKING_JIT_FAIL,
          jitBookingInfor: {},
        }),
    );
  };
};

/**
 * adminChangePaymentMethod
 * @param {Object} payload
 * @return {Array}
 */
export const adminChangePaymentMethod = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.ADMIN_CHANGE_PAYMENT_METHOD_START});
    return adminChangePaymentMethodApi(payload).then(
      (response) => {
        if (response && response?.status === 200) {
          dispatch({
            type: ACTION_TYPES.ADMIN_CHANGE_PAYMENT_METHOD_SUCCESS,
          });
          return response;
        } else {
          dispatch({type: ACTION_TYPES.ADMIN_CHANGE_PAYMENT_METHOD_FAIL});
          return response;
        }
      },
      () => {
        dispatch({type: ACTION_TYPES.ADMIN_CHANGE_PAYMENT_METHOD_FAIL});
        dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
      },
    );
  };
};

/**
 * Fetch rating CSV data
 * @param {Object} payload
 * @return {Promise}
 */
export const getCsvExportRatingUuid = (payload) => {
  return (dispatch) => {
    return getCsvExportRatingUuidApi(payload).then(
      (response) => {
        if (response?.status === 200) {
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response?.message_code)));
        }
      },
      () => {
        dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
      },
    );
  };
};

/**
 * change node booking status
 * @param {Object} payload
 * @return {*}
 */
export const changeStatusPayment = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.CHANGE_PAYMENT_STATUS_START,
    });
    return changePaymentStatusApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.CHANGE_PAYMENT_STATUS_SUCCESS,
          });
          return response;
        } else {
          dispatch({
            type: ACTION_TYPES.CHANGE_PAYMENT_STATUS_FAIL,
          });
          return response;
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.CHANGE_PAYMENT_STATUS_FAIL,
        }),
    );
  };
};

/**
 * retryCreditCard
 * @param {Object} payload
 * @return {*}
 */
export const retryPaymentCreditCard = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.RETRY_PAYMENT_CREDIT_CARD_START,
    });
    return retryPaymentCreditCardApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.RETRY_PAYMENT_CREDIT_CARD_START_SUCCESS,
          });
          return response;
        } else {
          dispatch({
            type: ACTION_TYPES.RETRY_PAYMENT_CREDIT_CARD_START_FAIL,
          });
          return response;
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.RETRY_PAYMENT_CREDIT_CARD_START_FAIL,
        }),
    );
  };
};

/**
 * getWaypointAssociation
 * @param {*} data
 * @return {Object}
 */
export const getWaypointAssociation = (data) => {
  return {
    type: ACTION_TYPES.WAYPOINT_ASSCOCIATION,
    waypointAssociation: data,
  };
};

 /** Get advance booking offers
 * @param {Number} userId
 * @param {Object} payload
 * @return {Promise}
 */
export const getAdvanceBookingOffers = (userId, payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_ADVANCE_BOOKING_OFFERS_START});
    return getAdvanceBookingOffersApi(userId, payload).then(
      (response) => {
        if (response) {
          if (response?.message_code === 'mass.waypoint.not.associate') {
            dispatch(setMessageModal(modalObj(true, 'reservation.error_waypoint_not_associate')));
            dispatch({type: ACTION_TYPES.GET_ADVANCE_BOOKING_OFFERS_FAIL});
            return;
          }
          if (response.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_ADVANCE_BOOKING_OFFERS_SUCCESS,
            });
            return response.result;
          } else {
            let fields;
            if (response.message_code === 'mass.admin.booking.exceed.max.number.of.booking.per.day') {
              fields = {maxNumberOfBookings: response.result};
            }
            dispatch({type: ACTION_TYPES.GET_ADVANCE_BOOKING_OFFERS_FAIL});
            dispatch(setMessageModal(modalObj(true, response.message_code, fields)));
          }
        }
      },
      () => {
        dispatch({type: ACTION_TYPES.GET_ADVANCE_BOOKING_OFFERS_FAIL});
        dispatch(setMessageModal(modalObj(true, 'error.500')));
      },
    );
  };
};

/**
 * busReservationDetail
 * @param {*} id
 * @param {*} props
 * @return {*}
 */
export const getBusReservationDetail = (id, props) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.DETAIL_RESERVATION_START,
    });
    return getBusReservationDetailApi(id).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_BUS_RESERVATION_DETAIL_SUCCESS,
            busReservationDetail: response.result,
          });
          return response.result;
        } else {
          if (response.message === 'permission.denied') {
            dispatch(setMessageModal(modalObj(true, 'permission.reservation.denied')));
            backForwardRouter(props, ROUTE.LAYOUT + ROUTE.HOME);
          }
          dispatch({
            type: ACTION_TYPES.GET_BOO,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_BUS_RESERVATION_DETAIL_FAIL,
        }),
    );
  };
};

/**
 * search list reservation
 * @param {object} payload
 * @param {object} queryParams
 * @param {object} props
 * @return {object}
 */
export const searchBusReservation = (payload, queryParams) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SEARCH_BUS_RESERVATION_START,
    });
    return searchBusReservationApi(payload, queryParams).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.SEARCH_BUS_RESERVATION_SUCCESS,
            listBusReservation: response.result.content,
          });
          return response.result;
        } else {
          dispatch({
            type: ACTION_TYPES.SEARCH_BUS_RESERVATION_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.SEARCH_BUS_RESERVATION_FAIL,
        }),
    );
  };
};
