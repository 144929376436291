export const ACTION_TYPES = {
  SEARCH_PARTNER_START: 'SEARCH_PARTNER_START',
  SEARCH_PARTNER_SUCCESS: 'SEARCH_PARTNER_SUCCESS',
  SEARCH_PARTNER_FAIL: 'SEARCH_PARTNER_FAIL',

  CREATE_PARTNER_START: 'CREATE_PARTNER_START',
  CREATE_PARTNER_SUCCESS: 'CREATE_PARTNER_SUCCESS',
  CREATE_PARTNER_FAIL: 'CREATE_PARTNER_FAIL',

  UPDATE_PARTNER_START: 'UPDATE_PARTNER_START',
  UPDATE_PARTNER_SUCCESS: 'UPDATE_PARTNER_SUCCESS',
  UPDATE_PARTNER_FAIL: 'UPDATE_PARTNER_FAIL',

  GET_PARTNER_DETAILS_START: 'GET_PARTNER_DETAILS_START',
  GET_PARTNER_DETAILS_SUCCESS: 'GET_PARTNER_DETAILS_SUCCESS',
  GET_PARTNER_DETAILS_FAIL: 'GET_PARTNER_DETAILS_FAIL',

  DELETE_PARTNER_START: 'DELETE_PARTNER_START',
  DELETE_PARTNER_SUCCESS: 'DELETE_PARTNER_SUCCESS',
  DELETE_PARTNER_FAIL: 'DELETE_PARTNER_FAIL',
};

const initialState = {
  isLoading: false,
  partnerList: [],
  majorList: [],
  partnerDetail: {},
};

/**
 * function reduce
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.SEARCH_PARTNER_START:
    case ACTION_TYPES.CREATE_PARTNER_START:
    case ACTION_TYPES.UPDATE_PARTNER_START:
    case ACTION_TYPES.GET_FUNCTION_LIST_START:
    case ACTION_TYPES.GET_PARTNER_DETAILS_START:
    case ACTION_TYPES.DELETE_PARTNER_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.SEARCH_PARTNER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        partnerList: action.partnerList,
      };

    case ACTION_TYPES.GET_FUNCTION_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        majorList: action.payload,
      };
    case ACTION_TYPES.UPDATE_PARTNER_SUCCESS:
    case ACTION_TYPES.CREATE_PARTNER_SUCCESS:
    case ACTION_TYPES.SEARCH_PARTNER_FAIL:
    case ACTION_TYPES.CREATE_PARTNER_FAIL:
    case ACTION_TYPES.UPDATE_PARTNER_FAIL:
    case ACTION_TYPES.DELETE_PARTNER_SUCCESS:
    case ACTION_TYPES.DELETE_PARTNER_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.GET_PARTNER_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        partnerDetail: action.payload,
      };
    case ACTION_TYPES.GET_PARTNER_DETAILS_FAIL:
      return {
        ...state,
        isLoading: false,
        partnerDetail: {},
      };
    default:
      return state;
  }
}
