import {api} from '../services/api';

const crudUrl = `admin/coupons`;
const getListCouponUrl = `admin/coupons/search`;
const getDetailCouponUrl = `/admin/coupons/`;

export const getListCouponApi = (payload, queryParams) => {
  return api.search(getListCouponUrl, queryParams, payload);
};

export const createCouponApi = (payload) => {
  return api.post(crudUrl, payload);
};

export const updateCouponApi = (payload) => {
  return api.put(crudUrl, payload);
};

export const getDetailCouponApi = (id) => {
  return api.get(getDetailCouponUrl + id);
};

export const deleteCouponApi = (id) => {
  return api.delete(crudUrl + `/${id}`);
};

