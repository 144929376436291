import {searchCongestionStatusApi, getCongestionStatusApi, registrationCongestionApi} from '../../services/congestionService';
import {modalObj} from '../../utils/modal.js';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

/**
 * Search Congestion Status
 * @param {object} payload
 * @param {object} queryParams
 * @param {object} props
 * @return {object}
 */
export const searchCongestion = (payload, queryParams) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.SEARCH_CONGESTION_STATUS_START});
    return searchCongestionStatusApi(payload, queryParams).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.SEARCH_CONGESTION_STATUS_SUCCESS,
              congestionStatusList: response.result.content,
            });
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.SEARCH_CONGESTION_STATUS_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.SEARCH_CONGESTION_STATUS_FAIL}),
    );
  };
};
/**
 * Export list congestion
 * @param {object} payload
 * @param {object} queryParams
 * @return {object}
 */
export const exportCongestion = (payload, queryParams) => {
  return (dispatch) => {
    return searchCongestionStatusApi(payload, queryParams).then(
        (response) => {
          if (response && response.status === 200) {
            return response.result?.content;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
          }
        },
        () => dispatch(setMessageModal(modalObj(true, 'Internal Server Error'))),
    );
  };
};

export const getCongestion = (params) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.DETAIL_CONGESTION_STATUS_START});
    return getCongestionStatusApi(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.DETAIL_CONGESTION_STATUS_SUCCESS,
              detailCongestion: response.result,
            });
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
            dispatch({type: ACTION_TYPES.DETAIL_CONGESTION_STATUS_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.DETAIL_CONGESTION_STATUS_FAIL}),
    );
  };
};

export const registrationCongestion = (payload, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.REGISTRATION_CONGESTION_STATUS_START});
    return registrationCongestionApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.result.message_code)));
            dispatch({type: ACTION_TYPES.REGISTRATION_CONGESTION_STATUS_SUCCESS});
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
            dispatch({type: ACTION_TYPES.REGISTRATION_CONGESTION_STATUS_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.REGISTRATION_CONGESTION_STATUS_FAIL}),
    );
  };
};
