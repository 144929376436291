import React, {Component} from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Button,
  TextField,
  Paper,
  Box,
  Card,
  Container,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Dialog,
  Backdrop,
  Modal,
  DialogContent,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorIcon from '@material-ui/icons/Error';
import PublishIcon from '@material-ui/icons/Publish';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import {format} from 'date-fns';
import {ExportToCsv} from 'export-to-csv';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {ROUTE, USAGE_STATUS_COMPANY, PERMISSION_ACTIONS, DATA_NULL} from '../../../common/constant';
import ImportCSV from '../../../components/importCSV';
import SelectModal from '../../../components/selectModal';
import {updateUserListApi} from '../../../services/companyServices';
import {getAllCountryCode, getAllGeofence} from '../../../stores/common/actions';
import {createCompany, getDetailCompany, updateCompany} from '../../../stores/company/action';
import {setMessageModal} from '../../../stores/modal/actions';
import {onChangeSelect, onChangeTextField, backForwardRouter} from '../../../utils/common';
import {modalObj} from '../../../utils/modal';
import SelectUser from './add_users';
import UserNotification from './user_notification';

/**
 * Company Detail views
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      companyCode: '',
      companyId: null,
      companyName: '',
      areaList: [],
      listGeofenceAll: [],
      listGeofenceSelected: [],
      geofence_ids: [],
      geofenceUserList: [],
      geofenceId: '',
      geofence: {},
      message: '',
      flag: false,
      checkDetail: false,
      id: null,
      status: USAGE_STATUS_COMPANY[0].id,
      country_id: '',
      isSubmitForm: false,
      addUsersModal: false,
      role_name: '',
      userDelete: {},
      flagDelete: false,
      flagImport: false,
      errorUsersModal: false,
      errorUserList: [],
      successUserList: [],
      geofenceIdUpload: '',
    };
    this.handleButtonUpdateOk = this.handleButtonUpdateOk.bind(this);
    this.handleButtonUpdateCancel = this.handleButtonUpdateCancel.bind(this);
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllCountryCode();
    await this.props.getAllGeofence().then(() => this.setState({listGeofenceAll: this.props.common?.all_geofence}));
    this.getDetailCompany();
    if (localStorage.getItem('USER_ROLE')) {
      this.setState({
        role_name: localStorage.getItem('USER_ROLE'),
      });
    }
  }

  /**
   * getDetailCompany
   */
  getDetailCompany() {
    if (this.props.match.path === ROUTE.LAYOUT + ROUTE.COMPANY_DETAIL + '/:id') {
      this.props.getDetailCompany(this.props.match.params.id).then(
        (result) => {
          if (result) {
            this.setState({
              companyCode: result.code,
              companyId: result.id,
              companyName: result.name,
              country_id: result.country_id,
              geofenceUserList: result.geofence_users?.map((item) => ({
                geofence_id: item.geofence_id,
                geofence_name: item.geofence_name,
                selectedUserList: item.users,
                detail_flag: true,
              })),
              listGeofenceAll: this.props.common?.all_geofence?.filter((item) => item.country_id === result.country_id),
              status: result.show_on_mobile ? USAGE_STATUS_COMPANY[0].id : USAGE_STATUS_COMPANY[1].id,
              listGeofenceSelected: result.geofence_users?.map((item) => ({
                geofence_id: item.geofence_id,
                name: item.geofence_name,
              })),
              geofence_ids: result.geofence_users?.map((item) => item.geofence_id),
              checkDetail: true,
            });
          } else {
            this.props.history.push(ROUTE.LAYOUT + ROUTE.COMPANY_MANAGEMENT);
          }
        },
        () => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.COMPANY_MANAGEMENT),
      );
    }
  }

  /**
   * handleUpdate
   */
  handleUpdate = () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid()) {
      this.setState({
        flag: true,
        message: 'company.question.confirm.update',
      });
    }
  };

  /**
   * handleButtonCancel
   */
  handleButtonUpdateCancel() {
    this.setState({
      flag: false,
    });
  }

  /**
   * handleButtonOk
   */
  handleButtonUpdateOk() {
    this.setState({
      flag: false,
    });
    const state = this.state;
    const payload = {
      id: this.state.companyId,
      name: this.state.companyName.trim(),
      show_on_mobile: this.state.status === 'ENABLE' ? true : false,
      country_id: state.country_id,
      geofence_users: this.setGeofenceUsers(),
    };
    this.props.updateCompany(payload, this.props);
  }

  /**
   * setGeofenceUsers
   * @return {setGeofenceUsers}
   */
  setGeofenceUsers = () => {
    const state = this.state;
    let geofence_users = [];
    if (state.geofenceUserList?.length > 0) {
      geofence_users = state.geofenceUserList.map((item) => ({
        geofence_id: item.geofence_id,
        user_ids: item.selectedUserList.filter((data) => !data.delete_flag).map((user) => user.id),
      }));
      if (state.geofenceUserList?.length < state.listGeofenceSelected?.length) {
        const geofenceUserList = state.geofenceUserList.map((item) => item.geofence_id);
        state.listGeofenceSelected.forEach((geofence) => {
          if (!geofenceUserList.includes(geofence.geofence_id)) {
            geofence_users.push({
              geofence_id: geofence.geofence_id,
              user_ids: [],
            });
          }
        });
      }
    } else {
      geofence_users = state.listGeofenceSelected.map((item) => ({
        geofence_id: item.geofence_id,
        user_ids: [],
      }));
    }
    return geofence_users;
  };

  handleClickCreateButton = () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid()) {
      this.setState({flag: true, message: 'company.question.confirm.create'});
    }
  };

  /**
   * handleButtonExportOk
   * @param {boolean} isTemplate
   */
  handleButtonExportOk = (isTemplate = false) => {
    this.setState({flagExport: false, isTemplate}, () => {
      if (isTemplate) {
        const {t} = this.props;
        const data = [
          {
            memberCode: 'UGEW1000006',
            operationCode: 'ADD',
          },
          {
            memberCode: 'UGEW1000007',
            operationCode: 'DELETE',
          },
          {
            memberCode: 'UGEW1000008',
            operationCode: 'UNSUB_DELETE',
          },
        ];
        const options = {
          fieldSeparator: ',',
          quoteStrings: '',
          decimalSeparator: '.',
          showLabels: true,
          filename: 'member_list_' + format(new Date(), 'yyyyMMdd'),
          headers: [t('memberManagement.memberId'), t('company.type')],
        };
        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data);
      } else {
        this.exportCSVRef.current.onClickExport();
      }
    });
  };

  /**
   * handleCreate
   */
  handleCreate = () => {
    const state = this.state;
    const payload = {
      name: this.state.companyName.trim(),
      show_on_mobile: this.state.status === 'ENABLE' ? true : false,
      country_id: state.country_id,
      geofence_users: this.setGeofenceUsers(),
    };
    this.props.createCompany(payload, this.props);
  };

  /**
   * handleDelete
   * @param {string} userId
   * @param {string} geofenceId
   */
  handleDelete = (userId, geofenceId) => {
    this.setState({
      flagDelete: true,
      message: 'company.deleteConfirmMember',
      userDelete: {
        userId: userId,
        geofenceId: geofenceId,
      },
    });
  };

  /**
   * confirmDelete
   */
  confirmDelete = () => {
    const data = this.state.geofenceUserList.find((item) => item.geofence_id === this.state.userDelete.geofenceId);
    const geofenceUserList = this.state.geofenceUserList;
    const index = data.selectedUserList.findIndex((item) => item.id === this.state.userDelete.userId);
    data.selectedUserList[index]['delete_flag'] = true;
    this.setState({
      geofenceUserList,
      flagDelete: false,
      userDelete: {},
    });
  };

  /**
   * cancelDelete
   */
  cancelDelete = () => {
    this.setState({
      flagDelete: false,
    });
  };

  /**
   * resetFilter
   * @param {*} e
   */
  reset = () => {
    this.setState({
      geofenceId: '',
    });
  };

  /**
   * onChangeCountry
   * @param {*} e
   */
  onChangeCountry = (e) => {
    this.setState({
      country_id: e.target.value,
      listGeofenceAll: this.props.common?.all_geofence?.filter((item) => item.country_id === e.target.value),
      geofence_ids: [],
      geofenceUserList: [],
      listGeofenceSelected: [],
    });
  };

  /**
   * Geofence
   * @param {*} e
   */
  onChangeGeofence = (e) => {
    const geofence_id = e.target.value;
    let geofence_ids = this.state.geofence_ids;
    let geofenceUserList = this.state.geofenceUserList;
    if (e.target.checked) {
      geofence_ids.push(geofence_id);
    } else {
      geofence_ids = geofence_ids.filter((item) => item !== geofence_id);
    }
    this.setState({
      geofence_ids: geofence_ids,
      listGeofenceSelected: this.state.listGeofenceAll.filter((item) => geofence_ids.includes(item.geofence_id)),
    });
    geofenceUserList = geofenceUserList.filter((item) => geofence_ids.includes(item.geofence_id));
    this.setState({
      geofenceUserList,
    });
  };

  /**
   * handleSelectUserOk
   * @param {array} userList
   */
  handleSelectUserOk = async (userList) => {
    this.setState({
      addUsersModal: false,
    });
    userList = userList?.map((item) => ({
      id: item.id,
      email: item.email,
      mobile: item.mobile,
      member_name: item.first_name + ' ' + item.last_name,
      member_code: item.member_id,
      created_by: {
        id: null,
        role_name: this.state.role_name,
      },
    }));
    this.addGeofenceUserListHandle(userList);
  };

  /**
   * handleImportUserOk
   * @param {array} data
   */
  handleImportUserOk = async (data) => {
    this.setState({flagImport: false, errorUserList: [], successUserList: []});
    if (data.errors.length > 0 || data.delete_errors.length > 0) {
      this.setState({
        errorUserList: data.errors.concat(data.delete_errors.length > 0 ? data.delete_errors : []),
        geofenceIdUpload: this.state.geofence.geofence_id,
        errorUsersModal: true,
      });
      return;
    }
    const addUsers = data.add_users;
    const geofeceUsers = this.state.geofenceUserList.find((geofenceUser) => geofenceUser.geofence_id === this.state.geofence.geofence_id);
    if (addUsers.length > 0 && geofeceUsers && geofeceUsers?.selectedUserList.length > 0) {
      const memberCodes = geofeceUsers.selectedUserList.map((item) => item.member_code);
      const errorUserList = [];
      addUsers.forEach((user) => {
        if (memberCodes.includes(user.member_code)) {
          errorUserList.push({
            member_code: user.member_code,
            is_code_error: false,
            row_number: user.row_number,
            amount: 1,
            is_added: true,
          });
        }
      });
      if (errorUserList.length > 0) {
        this.setState({
          errorUserList: errorUserList,
          geofenceIdUpload: this.state.geofence.geofence_id,
          errorUsersModal: true,
        });
        return;
      }
    }
    this.setState({successUserList: addUsers.concat(data.delete_users), errorUsersModal: true});
  };

  /**
   * confirmImportUserOk
   * @param {array} userList
   */
  confirmImportUserOk = async (userList) => {
    if (this.state.checkDetail) {
      const payload = {
        company_id: this.state.companyId,
        geofence_id: this.state.geofence.geofence_id,
        user_requests: userList.map((user) => ({
          user_id: user.id,
          operation_type: user.operation_type,
        })),
      };
      this.setState({isLoading: true});
      await updateUserListApi(payload).then((result) => {
        if (result) {
          this.setState({isLoading: false});
          const deleteUsers = userList.filter((item) => item.operation_type === 'DELETE');
          if (deleteUsers.length > 0) {
            this.deleteUserList(deleteUsers);
          }
          this.props.setMessageModal(modalObj(true, 'company.import.success'));
        }
      });
    }
    let addUsers = userList.filter((item) => item.operation_type === 'ADD');
    addUsers = addUsers?.map((item) => ({
      id: item.id,
      email: item.email,
      mobile: item.mobile,
      member_name: item.member_name,
      member_code: item.member_code,
      created_by: {
        id: null,
        role_name: this.state.role_name,
      },
    }));
    this.addGeofenceUserListHandle(addUsers);
  };

  /**
   * deleteUserList
   * @param {array} userList
   */
  deleteUserList = (userList) => {
    const geofenceUser = this.state.geofenceUserList.find((item) => item.geofence_id === this.state.geofence.geofence_id);
    userList.forEach((item) => {
      const indexUser = geofenceUser.selectedUserList.findIndex((user) => item.member_code === user.member_code);
      if (indexUser > -1) {
        geofenceUser.selectedUserList.splice(indexUser, 1);
      }
    });
  };

  /**
   * checkDeleteAll
   * @param {string} geofence_id
   * @return {checkDeleteAll}
   */
  checkDeleteAll(geofence_id) {
    const deleteUsers = this.state.geofenceUserList
      .find((item) => item.geofence_id === geofence_id && item.detail_flag)
      ?.selectedUserList.filter((user) => user.delete_flag === true);
    const totalUser = this.state.geofenceUserList.find((item) => item.geofence_id === geofence_id && item.detail_flag)?.selectedUserList;
    if (deleteUsers?.length === totalUser?.length) {
      return true;
    }
    return false;
  }

  /**
   * setTotalItem
   * @return {setTotalItem}
   */
  setTotalItem() {
    let lengthUsers = 0;
    this.state.geofenceUserList
      .filter((geo) => (this.state.geofenceId ? geo.geofence_id === this.state.geofenceId : true))
      .forEach((item) => {
        lengthUsers += item.selectedUserList?.length;
      });
    return lengthUsers;
  }

  /**
   * addGeofenceUserList
   * @param {array} userList
   */
  addGeofenceUserListHandle = (userList) => {
    const geofenceUserList = this.state.geofenceUserList;
    const checkIndex = geofenceUserList.findIndex((item) => item.geofence_id === this.state.geofence.geofence_id);
    if (geofenceUserList.length === 0 || (geofenceUserList.length > 0 && checkIndex === -1)) {
      geofenceUserList.push({
        geofence_id: this.state.geofence.geofence_id,
        geofence_name: this.state.geofence.name,
        selectedUserList: userList,
      });
    } else {
      const geofenceUser = geofenceUserList.filter((item) => item.geofence_id === this.state.geofence.geofence_id)[0];
      geofenceUser.selectedUserList = geofenceUser.selectedUserList.concat(userList);
    }
    this.setState({
      geofenceUserList,
    });
  };

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {t, actions, common, company} = this.props;
    const {listGeofenceSelected, geofenceUserList, geofence, geofenceId, errorUserList, companyId, geofenceIdUpload, successUserList} = this.state;

    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
    };

    return (
      <LoadingOverlay active={company.isLoading || common.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container spacing={1} className="page_header">
              <Grid container alignItems="center" item xs={6}>
                {this.state.checkDetail ? <h3>{t('company.update')}</h3> : <h3>{t('company.registration')}</h3>}
              </Grid>
            </Grid>
          </Container>
          <br></br>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('company.form')}
                    </Grid>
                  </Grid>
                  {/* Company Code */}
                  {this.state.checkDetail && (
                    <Grid container spacing={1} className="row_form_item ">
                      <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                        {t('company.code')} <span className="font_color_red">＊</span>
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={3}>
                        <TextField
                          name="companyCode"
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          variant="outlined"
                          value={this.state.companyCode}
                          disabled={true}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {/* Company Name */}
                  <Grid container spacing={1} className="row_form_item ">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('company.name')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={8} lg={4}>
                      <div className="box-group-input">
                        <TextField
                          name="companyName"
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('company.name')})}
                          variant="outlined"
                          value={this.state.companyName}
                          onChange={(event) => onChangeTextField(this, event)}
                        />
                        <div className="max-length-label">{t('validation.max.label', {value: 50})}</div>
                      </div>
                      {this.validator.message('companyName', this.state.companyName, 'required|max:50')}
                      {this.state.isSubmitForm && !this.validator.check(this.state.companyName, 'required') && (
                        <FormHelperText id="companyName" error>
                          {t('validation.required', {field: t('company.name')})}
                        </FormHelperText>
                      )}
                      {this.state.isSubmitForm && this.validator.check(this.state.companyName, 'required') && !this.validator.check(this.state.companyName, 'max:50') && (
                        <FormHelperText id="companyName" error>
                          {t('errorFields.textLength', {value: 50})}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>

                  {/* Country */}
                  <Grid container spacing={1} className="row_form_item ">
                    <Grid container alignItems="center" item xs={3} lg={2} xl={2} className="grid_title_padding">
                      {t('common.country')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4} xl={4}>
                      <FormControl variant="outlined" margin="dense">
                        <div className="box-group-input">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'country_id',
                            }}
                            className="field_size_10 field_min_size_300"
                            displayEmpty
                            renderValue={
                              this.state.country_id ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('common.country'),
                                      })}
                                    </div>
                                  )
                            }
                            disabled={this.state.checkDetail}
                            value={this.state.country_id}
                            onChange={this.onChangeCountry}
                          >
                            {common.country_code?.map((item, idx) => {
                              return (
                                <MenuItem value={item.id} key={idx}>
                                  {t(`${item.country_code}`)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                        {this.validator.message('country_id', this.state.country_id, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.country_id, 'required') && (
                          <FormHelperText id="country_id" error>
                            {t('validation.required.choose', {field: t('common.country')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Geofence */}
                  <Grid container spacing={1} className="row_form_item ">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('business_plan.geofence')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={9} lg={10}>
                      {this.state.country_id && (
                        <FormControl error className="item_display_box">
                          {this.state.listGeofenceAll?.map((geofence, idx) => (
                            <FormControlLabel
                              labelPlacement="end"
                              className="geofence_ids"
                              key={idx}
                              control={
                                <Checkbox
                                  value={geofence.geofence_id}
                                  disabled={!geofence.enable || !this.checkDeleteAll(geofence.geofence_id)}
                                  checked={this.state.geofence_ids?.includes(geofence.geofence_id)}
                                  onChange={this.onChangeGeofence}
                                  className="checkbox_radio"
                                />
                              }
                              label={t(`${geofence.name}`)}
                            />
                          ))}
                          {this.validator.message('geofence_ids', this.state.geofence_ids, 'required')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.geofence_ids, 'required') && (
                            <FormHelperText id="geofence_ids" error>
                              {t('validation.required.choose', {field: t('common.geofence')})}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                      {listGeofenceSelected?.map((item, idx) => {
                        return (
                          <Grid
                            lg={12}
                            xs={12}
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              paddingTop: '5px',
                              paddingBottom: '5px',
                              alignItems: 'center',
                            }}
                            item
                            key={idx}
                          >
                            <Box>{item.name}</Box>
                            <Grid
                              lg={8}
                              xs={8}
                              style={{
                                display: 'flex',
                                justifyContent: 'start',
                                paddingTop: '5px',
                                paddingBottom: '5px',
                                alignItems: 'center',
                              }}
                              item
                            >
                              <Box>
                                <Button
                                  className="button_margin"
                                  color="primary"
                                  variant="contained"
                                  name="addMember"
                                  onClick={() => this.setState({addUsersModal: true, geofence: item, isUploadCsv: false})}
                                >
                                  {t('company.addMember')}
                                  <AddIcon />
                                </Button>
                              </Box>
                              <Box>
                                <Button
                                  className="button_margin"
                                  color="primary"
                                  name="importMember"
                                  variant="contained"
                                  onClick={() => this.setState({flagImport: true, geofence: item, isUploadCsv: false})}
                                >
                                  {t('company.memberCsvImport')}
                                  <PublishIcon className="csv-exporter-icon" />
                                </Button>
                              </Box>
                              {errorUserList?.length > 0 && item.geofence_id === geofenceIdUpload && (
                                <Box>
                                  <Button className="button_margin" color="secondary" variant="contained" onClick={() => this.setState({errorUsersModal: true})}>
                                    {t('company.errorUsers')}
                                    <ErrorIcon
                                      style={{
                                        marginLeft: '10px',
                                      }}
                                    />
                                  </Button>
                                </Box>
                              )}
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                  {/* Usage Status */}
                  <Grid container spacing={1} className="row_form_item ">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('company.usageStatus')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'status',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.status ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('company.usageStatus'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.status}
                          onChange={(event) => onChangeSelect(this, event)}
                        >
                          {USAGE_STATUS_COMPANY.map((item, idx) => {
                            return (
                              <MenuItem value={item.id} key={idx}>
                                {t(`${item.i18n}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid
                      container
                      spacing={1}
                      style={{
                        padding: '25px',
                      }}
                    >
                      <Paper className="search_table">
                        {geofenceUserList?.length > 0 && (
                          <Grid container alignItems="flex-start">
                            <Grid container className="product_entry_table_header_color font_color_white font_size_mid grid_title_padding">
                              <Grid container alignItems="center" item xs={6}>
                                <h3> {t('company.userList')}</h3>
                              </Grid>
                            </Grid>
                            {/* Geofence Filter */}
                            {geofenceUserList?.length > 0 && (
                              <Grid container spacing={1} className="row_form_item ">
                                <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                                  {t('business_plan.geofence')}
                                </Grid>
                                <Grid
                                  style={{
                                    justifyContent: 'flex-end',
                                  }}
                                  container
                                  alignItems="center"
                                  item
                                  xs={6}
                                  lg={2}
                                >
                                  <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                                    <Select
                                      margin="dense"
                                      inputProps={{
                                        name: 'geofenceId',
                                      }}
                                      displayEmpty
                                      renderValue={
                                        listGeofenceSelected.length > 0 ?
                                          undefined :
                                          () => (
                                              <div className="font-12 color-disabled">
                                                {t('placeholder.required_select', {
                                                  field: t('business_plan.geofence'),
                                                })}
                                              </div>
                                            )
                                      }
                                      value={geofenceId}
                                      onChange={(event) => onChangeSelect(this, event)}
                                    >
                                      {listGeofenceSelected.map((item, idx) => {
                                        return (
                                          <MenuItem value={item.geofence_id} key={idx}>
                                            {t(`${item.name}`)}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid className="grid_title_padding" container alignItems="center" justify="flex-start" item xs={3}>
                                  <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.reset()} endIcon={<RotateLeftIcon />}>
                                    {t('common.btnReset')}
                                  </Button>
                                </Grid>
                                <Grid
                                  container
                                  spacing={2}
                                  style={{
                                    justifyContent: 'end',
                                  }}
                                  alignItems="end"
                                  item
                                  xs={5}
                                >
                                  <h3>
                                    {this.setTotalItem()} {t('common.case')}
                                  </h3>
                                </Grid>
                              </Grid>
                            )}
                            <div className="scroll_area_400">
                              <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                                <TableHead>
                                  <TableRow>
                                    <TableCell className="width_75p">{t('memberManagement.memberId')}</TableCell>
                                    <TableCell className="width_100p">{t('common.email')}</TableCell>
                                    <TableCell className="width_150p">{t('common.name')}</TableCell>
                                    <TableCell className="width_100p">{t('common.phoneNumber')}</TableCell>
                                    <TableCell className="width_100p">{t('common.geofence')}</TableCell>
                                    <TableCell className="width_100p">{t('company.createdBy')}</TableCell>
                                    <TableCell className="width_100p">{t('common.action')}</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {geofenceUserList
                                    .filter((geo) => (geofenceId ? geo.geofence_id === geofenceId : true))
                                    .map((item) => {
                                      return item.selectedUserList.map((user, index) => (
                                        <TableRow key={index} className={`cursor-pointer ${user.delete_flag ? 'background_color_red' : ''}`}>
                                          <TableCell>{user.member_code}</TableCell>
                                          <TableCell>{user.email ? user.email : DATA_NULL}</TableCell>
                                          <TableCell>{user.member_name}</TableCell>
                                          <TableCell>{user.mobile ? user.mobile : DATA_NULL}</TableCell>
                                          <TableCell>{item.geofence_name ? item.geofence_name : DATA_NULL}</TableCell>
                                          <TableCell>{user?.created_by ? user.created_by.role_name : DATA_NULL}</TableCell>
                                          <TableCell>
                                            {!user.delete_flag && (
                                              <Button
                                                color="primary"
                                                variant="contained"
                                                className="button_margin button_color_red"
                                                endIcon={<DeleteIcon />}
                                                onClick={() => this.handleDelete(user.id, item.geofence_id)}
                                              >
                                                {t('common.btnDelete')}
                                              </Button>
                                            )}
                                          </TableCell>
                                        </TableRow>
                                      ));
                                    })}
                                </TableBody>
                              </Table>
                            </div>
                          </Grid>
                        )}
                      </Paper>
                    </Grid>
                  </Grid>
                </Paper>
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {this.state.checkDetail ? (
                      permission.canUpdate && (
                        <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={this.handleUpdate}>
                          {t('common.btnUpdate')}
                        </Button>
                      )
                    ) : (
                      <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={this.handleClickCreateButton}>
                        {t('common.btnRegister')}
                      </Button>
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      className="button_margin button_color"
                      onClick={() => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.COMPANY_MANAGEMENT)}
                    >
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                  <Dialog
                    open={this.state.flag}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      zIndex: '1020',
                    }}
                  >
                    <SelectModal
                      onClickOk={this.state.checkDetail ? this.handleButtonUpdateOk : this.handleCreate}
                      onClickCancel={this.handleButtonUpdateCancel}
                      message={this.state.message}
                    ></SelectModal>
                  </Dialog>
                </Grid>
              </Container>
              <Container>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={this.state.addUsersModal}
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1000',
                  }}
                >
                  <DialogContent>
                    <SelectUser
                      props={{
                        ...this.props,
                        list_family_id: geofenceUserList.find((item) => item.geofence_id === geofence.geofence_id)?.selectedUserList.map((item) => item.id),
                        number_of_users: this.state.number_of_users_available ? this.state.number_of_users_available : 0,
                      }}
                      onClickOk={(list_family_code) => this.handleSelectUserOk(list_family_code)}
                      onClickClose={() => this.setState({addUsersModal: false})}
                    />
                  </DialogContent>
                </Modal>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={this.state.errorUsersModal}
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1000',
                  }}
                >
                  <UserNotification
                    props={{
                      ...this.props,
                      errorUserList: errorUserList,
                      successUserList: successUserList,
                    }}
                    onClickClose={() => this.setState({errorUsersModal: false})}
                    onClickOk={(userList) => this.confirmImportUserOk(userList)}
                  />
                </Modal>
                <Dialog
                  open={this.state.flagDelete}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal onClickOk={this.confirmDelete} onClickCancel={this.cancelDelete} message={this.state.message}></SelectModal>
                </Dialog>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={this.state.flagImport}
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1000',
                  }}
                >
                  <>
                    <ImportCSV
                      props={{
                        companyId: this.state.checkDetail ? companyId : '',
                        geofenceId: this.state.checkDetail ? geofence.geofence_id : '',
                      }}
                      onClickClose={() => this.setState({flagImport: false})}
                      type="member-list"
                      onClickOk={(userList) => this.handleImportUserOk(userList)}
                      downloadTemplateCSV={(countryCode) => this.handleButtonExportOk(true, countryCode)}
                    ></ImportCSV>
                  </>
                </Modal>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>
          <br></br>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    company: state.company,
    country_code: state.common.country_code,
    actions: ['SEARCH', 'REGISTER', 'DETAIL', 'DELETE', 'CSV_OUTPUT', 'UPDATE'],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDetailCompany: (params, props) => dispatch(getDetailCompany(params, props)),
    updateCompany: (params, props) => dispatch(updateCompany(params, props)),
    createCompany: (payload, props) => dispatch(createCompany(payload, props)),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getAllGeofence: () => dispatch(getAllGeofence()),
    setMessageModal: (params) => dispatch(setMessageModal(params)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
