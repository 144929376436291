import React, {Component} from 'react';

import {Map, GoogleApiWrapper} from 'google-maps-react';
import mqtt from 'mqtt';
import {connect} from 'react-redux';

import {setMessageModal} from '../../stores/modal/actions';

/**
 * Tracking GPS Maps component
 */
class Index extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      centerPoint: {
        lat: 36.2048,
        lng: 138.2529,
      },
      listCarDataOnMap: [],
      listCarMarkerOnMap: [],
      client: {},
      trackingSelectedVehicleList: [],
      rotation: 0,
    };
    this.mqttInfo = {
      client_id: 'mqttjs_2712f5014a',
      username: 'mqtt-notification-backend-11',
      password: 'SKcdLJRm7RkHubX0',
    };
    this.dividePart = 10;
    this.delay = 3000;
  }

  /**
   * differentVehicles
   * @param {Array} trackingVehicleList
   * @return {Boolean}
   */
  differentVehicles = (trackingVehicleList) => {
    const {trackingSelectedVehicleList} = this.state;
    return !!trackingVehicleList.find((item) => !trackingSelectedVehicleList.includes(item)) || !!trackingSelectedVehicleList.find((item) => !trackingVehicleList.includes(item));
  };

  /**
   * componentDidUpdate
   */
  componentDidUpdate = () => {
    const {geofenceSelected} = this.props;
    const {geofenceId} = this.state;
    if (geofenceSelected?.geofence_id !== geofenceId) {
      this.renderGeofence();
      this.setState({geofenceId: geofenceSelected?.geofence_id});
    }
  };

  /**
   * componentWillReceiveProps
   * @param {Object} nextProps
   */
  UNSAFE_componentWillReceiveProps = async (nextProps) => {
    const {trackingVehicleList, trackingOneVehicle} = nextProps;
    if (this.differentVehicles(trackingVehicleList) || trackingOneVehicle) {
      await this.setState({trackingSelectedVehicleList: trackingVehicleList});
      this.changeTrackingVehicleList();
      await this.callMQTT();
    }
  };

  /**
   * changeTrackingVehicleList
   */
  changeTrackingVehicleList = () => {
    const {listCarDataOnMap, listCarMarkerOnMap, trackingSelectedVehicleList} = this.state;
    if (trackingSelectedVehicleList.length === 0) {
      for (const car of listCarMarkerOnMap) {
        car && car.setMap(null);
      }
      this.setState({listCarDataOnMap: [], listCarMarkerOnMap: []});
    } else {
      const listNewVehicles = trackingSelectedVehicleList.filter((item) => listCarDataOnMap.filter((itm) => itm.id === item.id).length === 0);
      const listRemoveVehicles = listCarDataOnMap.filter((item) => trackingSelectedVehicleList.filter((itm) => itm.id === item.id).length === 0);

      // Hiển thị xe mới
      for (const car of listNewVehicles) {
        const newCarMarker = this.renderMarkers(car);
        listCarMarkerOnMap.push(newCarMarker);
        listCarDataOnMap.push(car);
      }

      // Xóa xe không còn hoạt động
      for (const car of listRemoveVehicles) {
        const carData = listCarDataOnMap.find((item) => item.id === car.id);
        const index = listCarDataOnMap.indexOf(carData);
        listCarMarkerOnMap[index].setMap(null);
        listCarDataOnMap.splice(index, 1);
        listCarMarkerOnMap.splice(index, 1);
      }
      this.setState({listCarDataOnMap, listCarMarkerOnMap});
    }
  };

  /**
   * callMQTT
   */
  callMQTT = () => {
    const options = {
      clean: true,
      clientId: this.mqttInfo.client_id,
      username: this.mqttInfo.username,
      password: this.mqttInfo.password,
      // keepalive: 3000,
    };
    const {geofenceSelected} = this.props;
    if (!geofenceSelected) return;
    const simulationIds =
      [geofenceSelected.jit_home_to_work_sim_id, geofenceSelected.jit_work_to_home_sim_id, geofenceSelected.taxi_sim_cfg_id, geofenceSelected.on_demand_sim_id].filter(
        (item) => !!item,
      ) || [];
    let {client} = this.state;
    if (!client.connected) {
      client = mqtt.connect(process.env.REACT_APP_MQTT_BROKER_URL, options);
      this.setState({client});
    }
    const topic_name = '/gps/location/simulation/';
    // Lọc các simulations đã sub và chưa sub
    let listUnSubscribe = [];
    let listNewSubscribe = [];
    const listChannelSubscribing = [];
    const listObjectChannelSubscribing = client?.messageIdToTopic;
    if (listObjectChannelSubscribing) {
      for (const [key] of Object.entries(listObjectChannelSubscribing)) {
        listChannelSubscribing.push(listObjectChannelSubscribing[key][0]);
      }
    }

    if (listChannelSubscribing.length > 0) {
      const listSubscribing = listChannelSubscribing?.map((item) => {
        return Number(item.split('/')[item.split('/').length - 1]);
      });
      listUnSubscribe = listSubscribing.filter((item) => !simulationIds.includes(item));
      listNewSubscribe = simulationIds.filter((item) => !listSubscribing.includes(item));
    } else {
      listNewSubscribe = simulationIds;
    }

    for (const simulationId of listUnSubscribe) {
      client.unsubscribe(topic_name + simulationId);
    }

    for (const simulationId of listNewSubscribe) {
      client.subscribe(topic_name + simulationId);
    }

    // get message
    client.on('message', async (topic, message) => {
      // clearInterval(timer);
      const msg = JSON.parse(message);
      // Test To-do: Remove
      this.processMessage(msg);
    });
  };

  /**
   * processMessage
   * @param {Object} message
   */
  processMessage = async (message) => {
    const {trackingOneVehicle, vehicleList} = this.props;
    let {listCarDataOnMap} = this.state;
    let changedCar = 0;
    if (trackingOneVehicle) {
      if (Number(message.data?.vehicle_id) !== Number(trackingOneVehicle?.id)) return;
      if (listCarDataOnMap.length > 0) {
        const oldCoordinates = {lat: listCarDataOnMap[0].coordinates.lat, lng: listCarDataOnMap[0].coordinates.lng};
        const vehicle = listCarDataOnMap[0];
        listCarDataOnMap[0] = {
          ...vehicle,
          coordinates: {
            lat: message.data?.latitude,
            lng: message.data?.longitude,
          },
          oldCoordinates,
        };
      } else {
        const vehicleData = vehicleList.find((item) => item.id === message.data?.vehicle_id);
        listCarDataOnMap = [
          {
            id: vehicleData.id,
            vehicle_id: vehicleData.vehicle_id,
            registration_number: vehicleData.registration_number,
            coordinates: {
              lat: message.data?.latitude,
              lng: message.data?.longitude,
            },
          },
        ];
      }
    } else {
      const car = listCarDataOnMap.find((item) => item.id === message.data?.vehicle_id);
      if (car) {
        const index = listCarDataOnMap.indexOf(car);
        listCarDataOnMap[index] = {
          ...car,
          coordinates: {
            lat: message.data?.latitude,
            lng: message.data?.longitude,
          },
          oldCoordinates: {
            lat: car.coordinates.lat,
            lng: car.coordinates.lng,
          },
        };
        changedCar = index;
      } else {
        const vehicleData = vehicleList.find((item) => item.id === message.data?.vehicle_id);
        listCarDataOnMap.push({
          id: vehicleData.id,
          vehicle_id: vehicleData.vehicle_id,
          registration_number: vehicleData.registration_number,
          coordinates: {
            lat: message.data?.latitude,
            lng: message.data?.longitude,
          },
        });
      }
    }
    await this.setState({listCarDataOnMap});
    this.renderCarMarker(changedCar);
  };

  /**
   * renderCarMarker
   * @param {Number} changedCar
   */
  renderCarMarker = (changedCar) => {
    const {listCarDataOnMap, listCarMarkerOnMap} = this.state;
    if (listCarDataOnMap.length === 0) return;
    if (listCarDataOnMap.length > listCarMarkerOnMap.length) {
      const newCarMarker = this.renderMarkers(listCarDataOnMap[listCarDataOnMap.length - 1]);
      listCarMarkerOnMap.push(newCarMarker);
    } else {
      const carData = listCarDataOnMap[changedCar];
      const deltaLat = (carData.coordinates.lat - carData.oldCoordinates.lat) / this.dividePart;
      const deltaLng = (carData.coordinates.lng - carData.oldCoordinates.lng) / this.dividePart;
      (deltaLat !== 0 || deltaLng !== 0) && this.moveMarker(changedCar, 1, deltaLat, deltaLng);
    }
    this.setState({listCarMarkerOnMap});
  };

  /**
   * moveMarker
   * @param {Number} changedCar
   * @param {Number} i
   * @param {Number} deltaLat
   * @param {Number} deltaLng
   */
  moveMarker = (changedCar, i, deltaLat, deltaLng) => {
    const {listCarMarkerOnMap, listCarDataOnMap} = this.state;
    const carData = listCarDataOnMap[changedCar];
    const carMarker = listCarMarkerOnMap[changedCar];
    if (carData) {
      carData.recentCoordinates = {
        lat: i === 1 ? carData.oldCoordinates.lat : carData.coordinates.lat,
        lng: i === 1 ? carData.oldCoordinates.lng : carData.coordinates.lng,
      };
      if (i === 1) {
        carData.coordinates.lat = carData.oldCoordinates.lat + deltaLat;
        carData.coordinates.lng = carData.oldCoordinates.lng + deltaLng;
      } else {
        carData.coordinates.lat += deltaLat;
        carData.coordinates.lng += deltaLng;
      }
    }
    carMarker.setPosition(carData.coordinates);

    const bearing = this.caculateCornerRadius(carData);
    if (bearing < 361) {
      const icon = carMarker.getIcon();
      icon.rotation = bearing;
      carMarker.setIcon(icon);
    }

    listCarDataOnMap[changedCar] = carData;
    listCarMarkerOnMap[changedCar] = carMarker;
    this.setState({listCarMarkerOnMap, listCarDataOnMap});
    if (i !== this.dividePart) {
      i++;
      setTimeout(() => this.moveMarker(changedCar, i, deltaLat, deltaLng), this.delay);
    }
  };

  /**
   * renderGeofence
   */
  renderGeofence = () => {
    const {google} = this.props;
    const geofence = this.props.geofenceSelected;

    // remove all data added
    this.mapRef.map.data.forEach((feature) => {
      this.mapRef.map.data.remove(feature);
    });

    if (!geofence || Object.keys(geofence).length === 0) return;
    if (geofence.geometry.type === 'Polygon') {
      const jsonData = {
        type: 'Feature',
        properties: {
          id: geofence.id,
          geofence_id: geofence.geofence_id,
        },
        geometry: {
          type: geofence.geometry.type,
          coordinates: geofence.geometry.coordinates,
        },
      };
      this.mapRef.map.data.addGeoJson(jsonData);
    }
    if (geofence.geometry.type === 'MultiPolygon') {
      geofence.geometry.coordinates.forEach((value) => {
        const jsonData = {
          type: 'Feature',
          properties: {
            id: geofence.id,
            geofence_id: geofence.geofence_id,
          },
          geometry: {
            type: 'Polygon',
            coordinates: value,
          },
        };
        this.mapRef.map.data.addGeoJson(jsonData);
      });
    }
    // Set Map to center
    const bounds = new google.maps.LatLngBounds();
    this.mapRef.map.data.forEach((feature) => {
      feature.getGeometry().forEachLatLng(function(latlng) {
        bounds.extend(latlng);
      });
    });
    this.mapRef.map.fitBounds(bounds);
    // Set Zoom
    const zoomChangeBoundsListener = google.maps.event.addListenerOnce(this.mapRef.map, 'bounds_changed', function() {
      if (this.getZoom()) this.setZoom(13.4);
    });
    setTimeout(function() {
      google.maps.event.removeListener(zoomChangeBoundsListener);
    }, 2000);
    // set style to geofence
    this.mapRef.map.data.setStyle({
      strokeColor: '#99cecf',
      fillColor: '#99cecf',
      fillOpacity: 0.5,
      strokeWeight: 0.1,
    });
  };

  /**
   * rad
   * @param {number} x
   * @return {x}
   */
  rad = (x) => {
    return (x * Math.PI) / 180;
  };

  /**
   * caculateCornerRadius
   * @param {Object} point
   * @return {Number}
   */
  caculateCornerRadius = (point) => {
    const {google} = this.props;
    const newLat = point.coordinates.lat;
    const newLng = point.coordinates.lng;
    const oldLat = point.recentCoordinates.lat;
    const oldLng = point.recentCoordinates.lng;
    const heading = google.maps.geometry.spherical.computeHeading({lat: oldLat, lng: oldLng}, {lat: newLat, lng: newLng});
    return heading;
  };

  /**
   * renderMarkers
   * @param {Object} point
   * @return {*}
   */
  renderMarkers = (point) => {
    if (Object.keys(point).length === 0) return;
    const {google} = this.props;
    const map = this.mapRef.map;
    const marker = new google.maps.Marker({
      position: point.coordinates,
      optimized: false,
      icon: {
        path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        scale: 6,
        strokeColor: 'white',
        strokeWeight: 2,
        fillColor: '#045bcf',
        fillOpacity: 0.8,
        rotation: 0,
      },
      map: map,
    });
    marker.info = new google.maps.InfoWindow({
      content: `${String(point.vehicle_id)} - ${String(point.registration_number)}`,
    });
    google.maps.event.addListener(marker, 'click', () => {
      marker.info.open(this.mapRef.map, marker);
    });
    return marker;
  };

  /**
   * render
   * @return {HTMLElement}
   */
  render() {
    return (
      <div>
        <Map
          {...this.props}
          ref={(ref) => (this.mapRef = ref)}
          centerAroundCurrentLocation={false}
          initialCenter={this.state.centerPoint}
          containerStyle={{
            height: '500px',
            position: 'relative',
            width: '95%',
          }}
        ></Map>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setMessageModal: () => dispatch(setMessageModal),
  };
};

export default connect(
  null,
  mapDispatchToProps,
)(
  // eslint-disable-next-line new-cap
  GoogleApiWrapper((props) => ({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || 'AIzaSyACyapw83diO1bi_xiXbZRLLoano6eTwd0',
    language: props.localLanguage,
    libraries: ['geometry', 'drawing', 'places'],
  }))(Index),
);
