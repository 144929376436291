import React, {Component} from 'react';

import {Card, Container, Grid, Button, Typography, Box} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import Carts from '../../../../components/carts';
import withPermissionGateway from '../../../../hoc/withPermissionGateway';
import {getProductByStoreId, getSupplierFacilityById} from '../../../../services/reservationServices';
import {redirectRouter} from '../../../../utils/common';

/**
 * Reservation management view
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      supplier_id: props.match.params.id,
      supplier: '',
      products: [],
      isLoading: false,
    };
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    await this.getSupplierFacilityById();
    await this.getProductByStoreId();
    this.setState({
      isLoading: false,
    });
  }

  /**
   * getSupplierFacilityById
   */
  async getSupplierFacilityById() {
    const data = await getSupplierFacilityById(this.state.supplier_id);
    if (data.status === 200) {
      this.setState({
        supplier: data.result,
      });
    }
  }

  /**
   * getProductByStoreId
   */
  async getProductByStoreId() {
    const detail = await getProductByStoreId(this.state.supplier_id);
    if (detail.status === 200) {
      this.setState({
        products: detail.result,
      });
    }
  }

  /**
   * toProductDetail
   * @param {int} id
   */
  toProductDetail = (id) => {
    const path = '/maas/reservation/created/delivery/facilities/product/' + id;
    redirectRouter(this.props, path);
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t} = this.props;
    const {products} = this.state;

    return (
      <LoadingOverlay active={this.state.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('reservationManagement.restaurant_detail')}</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />} onClick={this.props.history.goBack}>
                  {t('common.btnReturn')}
                </Button>
              </Grid>
            </Grid>
          </Container>
          {/* Step 5 */}

          <Container maxWidth="xl">
            <Box p={2} m={2}>
              <Card raised>
                <Container maxWidth="xl">
                  <Box p={2} m={2}>
                    <Grid container spacing={3} alignItems="flex-start">
                      {this.state.supplier && (
                        <Grid item xs={12}>
                          <img
                            className="width_100"
                            src={
                              this.state.supplier.apperance_image_url.length > 0 ?
                                this.state.supplier.apperance_image_url[0] :
                                `${process.env.PUBLIC_URL}/images/reservation/facility.png`
                            }
                            alt=""
                          />
                          <Grid container spacing={3} alignItems="flex-start" className="height_250p">
                            <Grid item xs={12}>
                              <Typography gutterBottom variant="h3" component="h3">
                                {this.state.supplier.facility_name}
                              </Typography>
                              <Typography gutterBottom variant="h5" component="h5">
                                {this.state.supplier.description}
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Typography gutterBottom variant="h6" component="h6">
                                {t('reservationManagement.service_time')}:
                              </Typography>
                              <Typography gutterBottom variant="h6" component="h6">
                                {t('reservationManagement.density_rate')}:
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography gutterBottom variant="h6" component="h6">
                                {this.state.supplier.service_time}
                              </Typography>
                              <Typography gutterBottom variant="h6" component="h6">
                                {this.state.supplier.density_rate}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      <Grid item xs={12} className="bg_black">
                        <Typography gutterBottom variant="h5" component="h5">
                          {t('reservationManagement.product_information')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Tabs>
                          <TabList>
                            <Tab>
                              <img className="tab_img marin_auto item_center_div" src={process.env.PUBLIC_URL + '/images/reservation/vegetables.svg'} alt="" />
                              <Typography gutterBottom variant="h5" component="h1">
                                {t('reservationManagement.vegetables')}
                              </Typography>
                            </Tab>
                            <Tab>
                              <img className="tab_img marin_auto item_center_div" src={process.env.PUBLIC_URL + '/images/reservation/dragon-fruit.svg'} alt="" />
                              <Typography gutterBottom variant="h5" component="h1">
                                {t('reservationManagement.fruit')}
                              </Typography>
                            </Tab>
                            <Tab>
                              <img className="tab_img marin_auto item_center_div" src={process.env.PUBLIC_URL + '/images/reservation/meat.svg'} alt="" />
                              <Typography gutterBottom variant="h5" component="h1">
                                {t('reservationManagement.meat')}
                              </Typography>
                            </Tab>
                          </TabList>

                          <TabPanel>
                            <Box mt={2} mb={2}>
                              <Grid container spacing={3} alignItems="flex-start">
                                <Grid item xs={12}>
                                  <Box p={2} className="bg_grey">
                                    <Typography gutterBottom variant="h4" component="h4">
                                      {t('reservationManagement.items')}
                                    </Typography>
                                  </Box>
                                </Grid>
                                {products.map((item) => {
                                  return (
                                    <Grid item xs={12} className="cursor_pointer" onClick={() => this.toProductDetail(item.id)}>
                                      <Grid container spacing={3} alignItems="flex-start" className="border_bottom">
                                        <Grid item xs={2}>
                                          <img
                                            className="width_100"
                                            src={item.image_url.length > 0 ? item.image_url[0] : `${process.env.PUBLIC_URL}/images/reservation/no_image.png`}
                                            alt=""
                                          />
                                        </Grid>
                                        <Grid item xs={10}>
                                          <Typography gutterBottom variant="h4" component="h4">
                                            {item.product_name}
                                          </Typography>
                                          <Typography gutterBottom variant="h4" component="h4" className="font_color_red">
                                            ¥{item.price}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </Box>
                          </TabPanel>
                          <TabPanel>
                            <h2>Any content 2</h2>
                          </TabPanel>
                          <TabPanel>
                            <h2>Any content 3</h2>
                          </TabPanel>
                        </Tabs>
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              </Card>
            </Box>
            <Box mt={2} mb={2}>
              <Carts history={this.props.history}></Carts>
            </Box>
          </Container>
        </Card>
      </LoadingOverlay>
    );
  }
}

export default withPermissionGateway(withTranslation('translations')(Index));
