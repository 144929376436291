import {ROUTE} from '../../common/constant';
import {createRightMenuSettingApi, getRightMenuSettingApi, updateRightMenuSettingApi, searchListRightMenuSettingApi} from '../../services/commonSettingServices';
import {modalObj} from '../../utils/modal.js';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

/**
 * search Right Menu Setting
 * @param {object} params
 * @param {object} queryParams
 * @param {object} props
 * @return {object}
 */
export const searchListRightMenuSetting = (params, queryParams) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SEARCH_LIST_RIGHT_MENU_SETTING_START,
    });
    return searchListRightMenuSettingApi(params, queryParams).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.SEARCH_LIST_RIGHT_MENU_SETTING_SUCCESS,
              listRightMenuSetting: response.result,
            });
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
            dispatch({
              type: ACTION_TYPES.SEARCH_LIST_RIGHT_MENU_SETTING_FAIL,
            });
          }
        },
        () =>
          dispatch({
            type: ACTION_TYPES.SEARCH_LIST_RIGHT_MENU_SETTING_FAIL,
          }),
    );
  };
};

/**
 * get detail Right Menu Setting
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const getRightMenuSetting = (params) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_RIGHT_MENU_SETTING_START,
    });
    return getRightMenuSettingApi(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_RIGHT_MENU_SETTING_SUCCESS,
              detailRightMenuSetting: response.result,
            });
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
            dispatch({
              type: ACTION_TYPES.GET_RIGHT_MENU_SETTING_FAIL,
            });
          }
        },
        () =>
          dispatch({
            type: ACTION_TYPES.GET_RIGHT_MENU_SETTING_FAIL,
          }),
    );
  };
};

/**
 * create Right Menu Setting
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const createRightMenuSetting = (params, props) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.CREATE_RIGHT_MENU_SETTING_START,
    });
    return createRightMenuSettingApi(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.CREATE_RIGHT_MENU_SETTING_SUCCESS,
            });
            props.history.push(ROUTE.LAYOUT + ROUTE.RIGHT_MENU_MANAGEMENT);
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.CREATE_RIGHT_MENU_SETTING_FAIL,
            });
          }
        },
        () =>
          dispatch({
            type: ACTION_TYPES.CREATE_RIGHT_MENU_SETTING_FAIL,
          }),
    );
  };
};

/**
 * update shuttle setting
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const updateRightMenuSetting = (params, props) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_RIGHT_MENU_SETTING_START,
    });
    return updateRightMenuSettingApi(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.UPDATE_RIGHT_MENU_SETTING_SUCCESS,
            });
            // const prevPath = props.location?.state?.from ? props.location?.state?.from : ROUTE.LAYOUT + ROUTE.RIGHT_MENU_MANAGEMENT;
            // props.history.push(prevPath);
          } else if (response && response?.message_code) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.UPDATE_RIGHT_MENU_SETTING_FAIL,
            });
          }
        },
        () =>
          dispatch({
            type: ACTION_TYPES.UPDATE_RIGHT_MENU_SETTING_FAIL,
          }),
    );
  };
};
