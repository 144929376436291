import React, {Component} from 'react';

import {Card, Container, Grid, Button, Typography, Box} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import queryString from 'query-string';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import '../style.css';

import withPermissionGateway from '../../../../hoc/withPermissionGateway';
import {getListSupplierFacility} from '../../../../services/reservationServices';
import {redirectRouter, validateRouter} from '../../../../utils/common';

/**
 * Reservation management view
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      ...query,
      listSupplierFacility: [],
      isLoading: false,
      no_result: false,
    };
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    if (validateRouter(this.state.geofence_id, this.state.geofence_name)) {
      this.setState({
        isLoading: true,
      });
      const list = await getListSupplierFacility({
        geofenceIds: this.state.geofence_id,
        // supplier_id: this.state.supplier_id,
      });
      if (list.status === 200) {
        this.setState({
          listSupplierFacility: list.result,
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
          no_result: false,
        });
      }
    }
  }

  /**
   * go to Facility page
   * @param {Number} id
   */
  toFacility = (id) => {
    const path = '/maas/reservation/created/delivery/facilities/' + id;
    redirectRouter(this.props, path);
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t} = this.props;
    return (
      <LoadingOverlay active={this.state.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('reservationManagement.delivery')}</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />} onClick={this.props.history.goBack}>
                  {t('common.btnReturn')}
                </Button>
              </Grid>
            </Grid>
          </Container>
          {/* Step 4 */}
          <Container maxWidth="xl">
            <Box p={2} m={2}>
              <Card raised>
                <Container maxWidth="xl">
                  <Box p={2} m={2}>
                    <Grid container spacing={3} alignItems="flex-start">
                      <Grid item xs={12}>
                        <Box m={2}>
                          <Grid container spacing={3} alignItems="flex-start">
                            <Grid item xs={12} className="bg_black">
                              <Typography gutterBottom variant="h5" component="h5">
                                {t('common.geofence')}: {this.state.geofence_name}
                              </Typography>
                              {/* <Typography gutterBottom variant="h5" component="h5">
                                {t('reservationManagement.store_sales_office')}: {this.state.supplier_name}
                              </Typography> */}
                            </Grid>
                          </Grid>
                        </Box>
                        <Grid container spacing={3} alignItems="flex-start">
                          {this.state.listSupplierFacility.map((item) => {
                            return (
                              <Grid item xs={3}>
                                <img
                                  className="width_100 height_250p"
                                  src={item.apperance_image_url.length > 0 ? item.apperance_image_url[0] : `${process.env.PUBLIC_URL}/images/reservation/facility.png`}
                                  alt=""
                                />
                                <Grid container spacing={3} alignItems="flex-start" className="height_250p">
                                  <Grid item xs={12}>
                                    <Typography gutterBottom variant="h3" component="h3">
                                      {item.facility_name}
                                    </Typography>
                                    <Typography gutterBottom variant="h5" component="h5">
                                      {item.description}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={8}>
                                    <Typography gutterBottom variant="h6" component="h6">
                                      {t('reservationManagement.service_time')}:
                                    </Typography>
                                    <Typography gutterBottom variant="h6" component="h6">
                                      {t('reservationManagement.density_rate')}:
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Typography gutterBottom variant="h6" component="h6">
                                      {item.service_time}
                                    </Typography>
                                    <Typography gutterBottom variant="h6" component="h6">
                                      {item.density_rate}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={3} alignItems="flex-start">
                                  <Grid item xs={12}>
                                    <Button variant="contained" color="primary" component="span" onClick={() => this.toFacility(item.id)}>
                                      {t('reservationManagement.detail')}
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            );
                          })}
                          {this.state.no_result && <h5 className="text_grey">{t('reservationManagement.no_result')}</h5>}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              </Card>
            </Box>
          </Container>
        </Card>
      </LoadingOverlay>
    );
  }
}

export default withPermissionGateway(withTranslation('translations')(Index));
