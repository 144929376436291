import {toInteger} from 'lodash';
import queryString from 'query-string';

import {CONTENT_TYPE_CSV} from '../common/constant';
import store from '../stores/configureStore';
import {setMessageModal} from '../stores/modal/actions';
import {modalObj} from './modal.js';

export const convertViToEn = (str, toUpperCase = false) => {
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư

  return toUpperCase ? str.toUpperCase() : str;
};

export const validateInputEmpty = (input) => {
  if (input === null) {
    return true;
  }

  if (typeof input === 'string') {
    if (input === '' || !input.match(/\S/g)) {
      return true;
    }

    return false;
  }
};

export const isValidEmail = (email) => {
  /* eslint-disable */
  const regex =
    /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
  return regex.test(String(email).toLowerCase());
};

export const passwordLengthCheck = (password) => {
  return password.length < 6;
};

/**
 * redirect router in function (not in exact component)
 * @param {Component} props
 * @param {String} path
 */
export const redirectRouter = (props, path) => {
  props.history.push(path);
};

/**
 * back forward router with router params
 * @param {Component} props
 * @param {String} path
 */
export const backForwardRouter = (props, path) => {
  const prevPath = props.location?.state?.from ? props.location?.state?.from : path;
  props.history.push(prevPath);
};

/**
 * convertStringNull
 * @param {string} str
 * @return {String}
 */
export const convertStringNull = (str) => {
  if (!!str) {
    const result = str
      .split(' ')
      .filter((e) => e !== 'null')
      .join(' ');
    return result;
  }
  return str;
};

/**
 * handle onChange TextField's value in component
 * @param {Component} component
 * @param {event} event
 */
export const onChangeTextField = (component, event) => {
  let value = event.target.value;
  if (event.target.maxLength && event.target.maxLength !== -1 && value.length > event.target.maxLength) value = value.slice(0, event.target.maxLength);
  component.setState({
    ...component.state,
    [event.currentTarget.name]: value,
  });
};

/**
 * handle onChange TextField Number in component
 * @param {Component} component
 * @param {Event} event
 */
export const onChangeTextFieldNumber = (component, event, min = 0) => {
  let value = event.target.value;
  if (value) {
    const invalidChars = ['-', 'e', '.', '+', ',', 'E'];
    invalidChars.map((item) => {
      value = value.replaceAll(item, '');
    });
    value = value.replace(/[^\d.-]/g, '');
    if (min > 0) {
      while (value.length > 0 && value[0] === '0') {
        value = value.substr(1);
      }
    }
  }
  component.setState({
    ...component.state,
    [event.currentTarget.name]: value,
  });
};

/**
 * handle onChange TextField Positive Number in component
 * @param {Component} component
 * @param {Event} event
 */
export const onChangeTextFieldPositiveNumber = (component, event, min = 0) => {
  let value = event.target.value;
  if (value) {
    const invalidChars = ['-', 'e', '+', 'E'];
    invalidChars.map((item) => {
      value = value.replaceAll(item, '');
    });
    value = value.replace(/[^\d.-]/g, '');
    if (min > 0) {
      while (value.length > 0 && value[0] === '0') {
        value = value.substr(1);
      }
    }
  }
  component.setState({
    ...component.state,
    [event.currentTarget.name]: value,
  });
};

export const onChangeTelField = (component, event) => {
  let value = event.target.value;
  if (value) {
    if (value[0] === '+') {
      value = '+' + value.substring(1).replace(/[^\d.-]/g, '');
    } else {
      value = value.replace(/[^\d.-]/g, '');
    }
  }
  component.setState({
    ...component.state,
    [event.currentTarget.name]: value,
  });
};

/**
 * handle onChange TextArea with limit line in component
 * @param {Component} component
 * @param {Event} event
 * @param {Number} limit_line
 */
export const onChangeTextFieldLimitLine = (component, event, limit_line) => {
  let chars = event.target.value.split(/\n/);
  if (chars.length > limit_line) {
    let i = 0;
    let newChars = chars[i];
    do {
      i++;
      newChars += '\n' + chars[i];
    } while (i < limit_line - 1);
    event.target.value = newChars;
  }
  onChangeTextField(component, event);
};

/**
 * handel onChange CheckBox's value in component
 * @param {component} component
 * @param {event} event
 */
export const onChangeCheckBox = (component, event) => {
  component.setState({
    ...component.state,
    [event.currentTarget.name]: event.target.checked,
  });
};

/**
 * handle onChange SelectBox's value in component
 * @param {Component} component
 * @param {event} event
 */
export const onChangeSelect = (component, event) => {
  component.setState({
    ...component.state,
    [event.target.name]: event.target.value,
  });
};

/**
 * handle onChange field's value in component state (array)
 * @param {Component} component
 * @param {array} data
 * @param {string} fieldName
 * @param {int} index
 * @param {*} value
 */
export const onChangeListData = (component, data, fieldName, index, value) => {
  const copyData = data;
  copyData[index][fieldName] = value;
  component.setState({data: copyData});
};

export const dynamicSort = (property) => {
  var sortOrder = 1;
  return function (a, b) {
    var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

/**
 * getSubTime
 * @param {ISOString} to
 * @param {ISOSTring} from
 * @return {Number} time differences in minutes
 */
export const getSubTime = (to, from) => {
  const toTime = new Date(to);
  const fromTime = new Date(from);
  toTime.setMilliseconds(0);
  toTime.setSeconds(0);
  fromTime.setMilliseconds(0);
  fromTime.setSeconds(0);
  return Math.round((toTime.getTime() - fromTime.getTime()) / (1000 * 60));
};

export const dateToHS = (date) => {
  const time = new Date(date);
  return (time.getHours() < 10 ? '0' : '') + time.getHours() + ':' + (time.getMinutes() < 10 ? '0' : '') + time.getMinutes();
};

export const b64toBlob = (dataURI) => {
  var byteString = atob(dataURI.split(',')[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], {type: 'image/jpeg'});
};

export const convertToCurrency = (value) => {
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const isExist = (currentValue) => currentValue !== undefined;

export const validateRouter = (...params) => {
  if (params.every(isExist)) {
    return true;
  } else {
    store.dispatch(setMessageModal(modalObj(true, 'error.common')));
    return false;
  }
};

/**
 * generated CSV file export's name
 * @param {String} name
 * @return {String}
 */
export const fileNameCSV = (name) => {
  let filename = '';
  const date = new Date();
  filename = date.getFullYear() + '' + date.getMonth() + '' + date.getDate() + '_' + name + CONTENT_TYPE_CSV;
  return filename;
};

/**
 * changeUrlParams
 * @param {object} newParams
 */
export const changeUrlParams = (newParams) => {
  const newQueryString = getQueryStringFromObject(newParams);
  if (history.pushState) {
    const newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + newQueryString;
    window.history.pushState({path: newUrl}, '', newUrl);
  }
};

/**
 * getQueryStringFromObject
 * @param {object} params
 * @return {string}
 */
export const getQueryStringFromObject = (params) => {
  return queryString.stringify(params, {arrayFormat: 'bracket', skipEmptyString: true, skipNull: true});
};

/**
 * Parse params from URL
 * @param {string} paramsString this.props.location.search
 * @return {object}
 */
export const getUrlParams = (paramsString) => {
  let params = queryString.parse(paramsString, {parseNumbers: false, arrayFormat: 'bracket'});

  // Parse object value if available
  for (const key in params) {
    const value = params[key];
    if (isObjectString(value)) {
      params[key] = JSON.parse(value);
    }
  }

  // Convert current page and rowsPerPage into integer if existed
  if (params.currentPage != null) {
    params.currentPage = toInteger(params.currentPage);
  }
  if (params.rowsPerPage != null) {
    params.rowsPerPage = toInteger(params.rowsPerPage);
  }
  return params;
};

/**
 * Check if a string is a valid JSON string in JavaScript
 * @param {string} str
 * @return {boolean}
 */
const isObjectString = (str) => {
  try {
    const item = JSON.parse(str);
    if (typeof item === 'object' && item !== null) {
      return true;
    }
  } catch (e) {
    return false;
  }
  return false;
};

/**
 * Clear blank string value from object
 * @param {Object} obj
 * @returns {Object} new object with out blank string value
 */
export const clearBlankValue = (obj) => {
  const newObj = {};
  for (var propName in obj) {
    if (typeof obj[propName] !== 'string' || obj[propName].trim().length > 0) {
      newObj[propName] = obj[propName];
    }
  }
  return newObj;
};

/**
 * Check string is UUID
 * @param {string} value value to check
 * @return {boolean} true if value is UUID
 */
export const isUUID = (value) => {
  /* eslint-disable */
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(value);
};

/**
 * covert Object to json string
 * @param {Object} value
 * return {String}
 */
export const jsonToString = (value) => {
  return JSON.stringify(value, null, ' ');
};

/**
 * Convert Number to Currency
 * @param {*} currency
 * @param {*} number
 */
export const formatNumberCurrency = (type, currency, number) => {
  return new Intl.NumberFormat(type, {style: 'currency', currency}).format(number);
};

/**
 * Number input with no negative, decimal, or zero value values
 * handleKeyPress
 * @param {*} e
 */
export const handleKeyPress = (e) => {
  const characterCode = e.key;
  if (characterCode === 'Backspace') return;

  const characterNumber = Number(characterCode);
  if (characterNumber >= 0 && characterNumber <= 9) {
    if (e.currentTarget.value && e.currentTarget.value.length) {
      return;
    }
  } else {
    e.preventDefault();
  }
};

/**
 * preventInvalidChars
 * @param {event} e
 */
export const preventInvalidChars = (e) => {
  const numberOfUser = e.key;
  const invalidChars = ['-', 'e', '.', '+', ',', 'E'];
  if (invalidChars.includes(numberOfUser)) e.preventDefault();
};

/**
 * preventInvalidCharsPositive
 * @param {event} e
 */
export const preventNumberInvalidChars = (e) => {
  const numberOfUser = e.key;
  const invalidChars = ['-', 'e', '+', 'E'];
  if (invalidChars.includes(numberOfUser)) e.preventDefault();
};

/**
 * customDisplayCurrency
 * @param {String} value
 * @param {String} currency
 * @return {String}
 */
export const customDisplayCurrency = (value, currency) => {
  value = Number(value || 0);
  switch (currency) {
    case 'USD':
      return new Intl.NumberFormat('en-US').format(value.toFixed(2)) + '$';
    case 'SG':
    case 'SGD':
      return 'S$ ' + (value ? (Math.round(value * 100) / 100).toFixed(2) : '0.00');
    case 'VND':
    case 'VN':
      return new Intl.NumberFormat('vi-VN').format(value) + (localStorage.getItem('i18nextLng') === 'vi' ? ' ₫' : ' VND');
    case 'MYR':
    case 'MY':
      return 'RM ' + (value ? (Math.round(value * 100) / 100).toFixed(2) : '0.00');
    case 'JPY':
    case 'JP':
    default:
      return localStorage.getItem('i18nextLng') === 'ja' ? new Intl.NumberFormat('ja-JP').format(value) + '円' : '￥' + new Intl.NumberFormat('ja-JP').format(value);
  }
};

/**
 * handle onChangeListNumberCurrency value in component
 * @param {Component} component
 * @param {event} event
 */
export const onChangeListNumberCurrency = (component, event, currency, isUpdate, data, index) => {
  let value = event.target.value;
  const regexPositiveDecimal = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
  const regexPositiveNumber = /^\d+$/;
  if (event.target.value === '' && isUpdate === false) return true;
  if (currency === 'SGD' || currency === 'MYR') {
    if (!regexPositiveDecimal.test(value) && event.target.value !== '') {
      event.preventDefault();
      return false;
    }
    if (!isNaN(value) && value.includes('.')) {
      const number = value.split('.')[1].length;
      if (number > 2) {
        event.preventDefault();
        return false;
      }
    }
  } else {
    if (!regexPositiveNumber.test(value) && event.target.value !== '') {
      event.preventDefault();
      return false;
    }
  }
  if (isUpdate === false) return true;
  if (event.target.maxLength && event.target.maxLength !== -1 && value.length > event.target.maxLength) value = value.slice(0, event.target.maxLength);
  const copyData = data;
  copyData[index][event.currentTarget.name] = value;
  component.setState({data: copyData});
  return true;
};

/**
 * get raw phone number data
 * @param {String} phoneNumber
 * @param {Object} country
 * @return {String}
 */
export const getRawPhoneNumber = (phoneNumber, country) => {
  if (!phoneNumber.includes('+')) return phoneNumber;
  let phone = phoneNumber.slice(country?.dialCode.length + 1);
  if (Number(phone.charAt(0)) === 0) {
    phone = phone.slice(1);
  }
  return '+' + country?.dialCode + phone;
};

/**
 * Get dial code from phone number input
 * @param {*} mobile
 * @returns
 */
export const getDialCodePhoneNumber = (mobile) => {
  const phoneNumber = mobile?.includes('+') ? mobile?.slice(1) : mobile;
  const dialCode = mobile?.[1] === '1' ? '1' : phoneNumber?.substring(0, 2);
  return dialCode;
};

/**
 * convert to display phone number
 * @param {String} mobile
 * @param {String} raw_mobile
 * @return {String}
 */
export const displayPhoneNumber = (mobile, raw_mobile) => {
  if (!mobile) return '';
  let phoneNumber = '';
  const dialCode = getDialCodePhoneNumber(mobile);
  phoneNumber = '+' + dialCode + raw_mobile;
  phoneNumber = phoneNumber.replaceAll('-', '');
  return phoneNumber;
};

/**
 * handle onChangeNumberCurrency value in component
 * @param {Component} component
 * @param {event} event
 */
export const onChangeNumberCurrency = (component, event, currency, isUpdate) => {
  let value = event.target.value;
  const regexPositveDecimal = /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/;
  const regexPositveNumber = /^\d+$/;
  if (event.target.value === '' && isUpdate === false) return true;
  if (currency === 'SGD' || currency === 'MYR') {
    if (!regexPositveDecimal.test(value) && !(event.target.value === '')) {
      event.preventDefault();
      return false;
    }
    if (!isNaN(value) && value.includes('.')) {
      const number = value.split('.')[1].length;
      if (number > 2) {
        event.preventDefault();
        return false;
      }
    }
  } else {
    if (!regexPositveNumber.test(value) && !(event.target.value === '')) {
      event.preventDefault();
      return false;
    }
  }
  if (isUpdate === false) return true;
  if (event.target.maxLength && event.target.maxLength !== -1 && value.length > event.target.maxLength) value = value.slice(0, event.target.maxLength);
  component.setState({
    ...component.state,
    [event.currentTarget.name]: value,
  });
  return true;
};

/**
 *
 * @param {*} item
 * @returns
 */
export const validateInput = (item) => {
  const regex = /^[A-Z]\d{5}$/;
  return regex.test(String(item));
};

/**
 *
 * @param {*} nricNumber
 * @param {*} first
 * @param {*} last
 * @returns
 */
export const nricNumberFormat = (nricNumber, first, last) => {
  if (first < last) {
    return nricNumber ? nricNumber.substring(0, first) + '-' + nricNumber.substring(first, last) + '-' + nricNumber.substring(last, nricNumber.length) : '';
  } else {
    return nricNumber ? nricNumber.substring(0, first) + '-' + nricNumber.substring(first, nricNumber.length) : '';
  }
};

/*
 * Format the number to 2 decimals (e.g. 1 -> 1.00, 1.1 -> 1.10)
 * (working with SGD or MYR currency)
 * @param {*} value
 * @param {*} currencyCode
 * @returns
 */
export const formatNumber2DecimalDigits = (value, currencyCode) => {
  if (!value) return value;
  if (currencyCode === 'SGD' || currencyCode === 'MYR') {
    return parseFloat(value).toFixed(2);
  }
  return value;
};

/**
 * Get list all geofence for detail (include any geofence not in list all geofence)
 * @param {*} listAllGeofence
 * @param {*} geofenceNotInListAll
 * @returns
 */
export const getListAllGeofenceDetail = (listAllGeofence, geofenceNotInListAll) => {
  const listAllGeofenceIds = listAllGeofence?.map((item) => item?.geofence_id);
  if (geofenceNotInListAll?.length > 0) {
    geofenceNotInListAll?.map((item) => item?.geofence_id && !listAllGeofenceIds.includes(item?.geofence_id) && listAllGeofence?.push(item));
  }
  return listAllGeofence;
};

/**
 * Calculate shop partner discount amount for fee breakdown
 * @param {Number} price
 * @param {Number} percent
 * @param {String} currencyCode
 * @return {Number}
 */
export const calculatePercentDiscountForFeeBreakDown = (price, percent, currencyCode) => {
  if (!price || !percent || !currencyCode) return 0;
  let discount = (price * percent) / 100;
  if (currencyCode === 'SGD' || currencyCode === 'MYR') {
    if (discount < 0) {
      discount = (Math.round(-discount * 100) / 100).toFixed(2);
      return (Math.round(-discount * 100) / 100).toFixed(2);
    }
    return (Math.round(discount * 100) / 100).toFixed(2);
  } else {
    return Math.round(discount);
  }
};
