import React from 'react';

import {Chip, TextField, FormControl} from '@material-ui/core';
import DatePicker, {DateObject} from 'react-multi-date-picker';

import './style.css';
import {convertDateTimeToDate} from '../../utils/datetime';
const format = 'YYYY/MM/DD';
/**
 * MultiDate
 * @return {*}
 */
export default function MultiDate({onChange, dates = [], placeholder, repeatStartDate, repeatEndDate, isAll = true}) {
  const datesCopy = dates
    .map((date) => new Date(date))
    .map((date) => new DateObject({year: new Date(date).getFullYear(), month: new Date(date).getMonth() + 1, day: new Date(date).getDate()}));
  return (
    <DatePicker
      value={datesCopy}
      onChange={(arr) => onChange(arr.map((d) => d.format()))}
      multiple
      sort
      format={format}
      mapDays={({date}) => {
        if (isAll) return;
        const isDisabled = repeatEndDate ?
          (convertDateTimeToDate(new Date(date)) < convertDateTimeToDate(new Date(repeatStartDate)) ||
            convertDateTimeToDate(new Date(date)) > convertDateTimeToDate(new Date(repeatEndDate))) :
          convertDateTimeToDate(new Date(date)) < convertDateTimeToDate(new Date(repeatStartDate));
        if (isDisabled) {
          return {
            disabled: true,
            style: {color: '#ccc'},
          };
        }
      }}
      render={
        <CustomComponent
          onDelete={(deleted) => {
            onChange(datesCopy.map((date) => date.format()).filter((date) => deleted !== date));
          }}
          placeholder={placeholder}
        />
      }
    />
  );
}

/**
 * CustomComponent
 * @param {*} param
 * @return {*}
 */
function CustomComponent({value, openCalendar, onDelete, placeholder}) {
  return (
    <FormControl fullWidth>
    <TextField
      InputProps={{
        startAdornment: value.map((date, index) => <Chip size="small" className='mr-10 mb-10' key={index} label={date} onDelete={() => onDelete(date)} />),
        style: {display: 'flex', flexWrap: 'wrap'},
      }}
      value=""
      onFocus={openCalendar}
      variant="outlined"
      style={{minWidth: '300px', maxWidth: '650px'}}
      fullWidth
      multiline
      margin='dense'
      className="scroll_area_350"
      placeholder={value.length > 0 ? '' : placeholder}
    /></FormControl>
  );
}
