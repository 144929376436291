import React, {Component} from 'react';

import {Button, Card, Checkbox, Container, FormControl, Grid, TextField} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {ROUTE} from '../../../common/constant';
import {cloneSimulation} from '../../../stores/simulation/actions';
import {backForwardRouter, onChangeTextField} from '../../../utils/common';
import {compareDateTimeRange, convertTimeLocalToUTC} from '../../../utils/datetime';
import withPermissionData from '../../../withPermissionData/hoc';


/**
 * clone simulation component
 */
class Index extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      old_simulation_id: this.props.match.params.key,
      new_simulation_description: null,
      start_time: null,
      end_time: null,
      force_clone: true,
      result: {},
      isSubmitForm: false,
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * handleSubmit()
   */
  handleSubmit = () => {
    this.setState({
      ...this.state,
      isSubmitForm: true,
    });
    if (this.validator.allValid()) {
      const payload = {
        old_simulation_id: this.props.match.params.key,
        new_simulation_description: this.state.new_simulation_description,
        start_time: convertTimeLocalToUTC(this.state.start_time),
        end_time: convertTimeLocalToUTC(this.state.end_time),
        force_clone: this.state.force_clone,
      };
      const secretKey = process.env.REACT_APP_SECRET_KEY_SIMULATION;
      this.props.cloneSimulation(payload, secretKey).then(() => {
        const prevPath = this.props.location?.state?.from ? this.props.location?.state?.from : ROUTE.LAYOUT + ROUTE.SIMULATION_MANAGEMENT + '?currentPage=0&rowsPerPage=10';
        this.props.history.push(prevPath);
      });
    }
  };

  /**
   * onChangeForce
   * @param {Event} event
   */
  onChangeForce = (event) => {
    this.setState({
      ...this.state,
      force_clone: event.target.checked,
    });
  };

  /**
   * render component
   * @return {HTML}
   */
  render() {
    const {t} = this.props;
    const classes = makeStyles((theme) => ({
      container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
      },
    }));

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <br />
          <Grid container spacing={1}>
            <Grid container alignItems="center" className="page_header" item xs={12}>
              {<span className="font_bold font_size_big"> {t('route.simulationManagement_clone')}</span>}
            </Grid>
            {/* old simulation id */}
            <Grid container spacing={1} className="row_form_item">
              <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                {t('simulationManagement.old_simulation_id')}
              </Grid>
              <Grid container justify="center" alignItems="center" item xs={5}>
                <FormControl fullWidth margin="dense">
                  <div name="old_simulation_id">{this.state.old_simulation_id}</div>
                </FormControl>
              </Grid>
            </Grid>
            {/* name */}
            <Grid container spacing={1} className="row_form_item">
              <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                {t('common.name')}
                <span className="font_color_red">＊</span>
              </Grid>
              <Grid container justify="center" alignItems="center" item xs={5}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    name="new_simulation_description"
                    className="field_size_20 field_min_size_300"
                    placeholder={t('placeholder.required', {field: t('common.name')})}
                    inputProps={{maxLength: 256}}
                    variant="outlined"
                    margin="dense"
                    value={this.state.new_simulation_description}
                    onChange={(event) => onChangeTextField(this, event)}
                  />
                </FormControl>
                {/* add rule to validate */}
                {this.validator.message('new_simulation_description', this.state.new_simulation_description, 'required')}
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    {/* check isValid to show messages */}
                    {this.state.isSubmitForm && !this.validator.check(this.state.new_simulation_description, 'required') && (
                      <p className="error" style={{paddingLeft: '8px', fontSize: '0.75rem'}}>
                        {t('validation.required', {field: t('common.name')})}
                      </p>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* display time */}
            <Grid container spacing={1} className="row_form_item">
              <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                {t('simulationManagement.time')}
              </Grid>
              <Grid container item xs={6} lg={6}>
                <form className={classes.container} noValidate>
                  <TextField
                    name="start_time"
                    label="start time"
                    type="datetime-local"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) => onChangeTextField(this, event)}
                  />
                </form>
                <Grid container justify="center" alignItems="center" item xs={1}>
                  ～
                </Grid>
                <form className={classes.container} noValidate>
                  <TextField
                    name="end_time"
                    label="end time"
                    type="datetime-local"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(event) => onChangeTextField(this, event)}
                  />
                </form>
                {/* add rule to validate */}
                {this.validator.message('start_time', this.state.start_time, 'required')}
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    {/* check isValid to show messages */}
                    {this.state.isSubmitForm && !this.validator.check(this.state.start_time, 'required') && (
                      <p className="error" style={{fontSize: '0.75rem'}}>
                        {t('validation.required', {field: t('simulationManagement.start_time')})}
                      </p>
                    )}
                  </Grid>
                </Grid>
                {/* add rule to validate */}
                {this.validator.message('end_time', this.state.end_time, 'required')}
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    {/* check isValid to show messages */}
                    {this.state.isSubmitForm && !this.validator.check(this.state.end_time, 'required') && (
                      <p className="error" style={{fontSize: '0.75rem'}}>
                        {t('validation.required', {field: t('simulationManagement.end_time')})}
                      </p>
                    )}
                  </Grid>
                </Grid>
                <Grid container alignItems="center" item xs={6} lg={4}>
                  {/* check isValid to show messages */}
                  {this.state.isSubmitForm && !compareDateTimeRange(this.state.start_time, this.state.end_time, false) && (
                    <p className="error" style={{fontSize: '0.75rem'}}>
                      {t('Start time must be less than End time')}
                    </p>
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* force */}
            <Grid container spacing={1} className="row_form_item">
              <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                {t('simulationManagement.force')}
                <span className="font_color_red">＊</span>
              </Grid>
              <FormControl margin="dense">
                <Checkbox onChange={(event) => this.onChangeForce(event)} checked={this.state.force_clone} />
              </FormControl>
              {/* add rule to validate */}
              {this.validator.message('force_clone', this.state.force_clone, 'required')}
              <Grid container spacing={1}>
                <Grid container alignItems="center" item xs={6} lg={4}>
                  {/* check isValid to show messages */}
                  {this.state.isSubmitForm && !this.validator.check(this.state.force_clone, 'required') && (
                    <p className="error" style={{paddingLeft: '8px', fontSize: '0.75rem'}}>
                      {t('validation.required', {field: t('simulationManagement.force')})}
                    </p>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container justify="flex-end" alignItems="center" item xs={12}>
              <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={this.handleSubmit}>
                {t('common.btnClone')}
              </Button>
              <Button
                color="primary"
                variant="contained"
                className="button_margin button_color"
                onClick={() => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.SIMULATION_MANAGEMENT_DETAIL + '/' + this.props.match.params.key)}
              >
                {t('common.btnReturn')}
              </Button>
            </Grid>
          </Grid>
          <br></br>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    simulation: state.simulation,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    cloneSimulation: (payload, queryParams) => dispatch(cloneSimulation(payload, queryParams)),
  };
};

export default withPermissionData(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
