import {ROUTE} from '../../common/constant';
import {searchOneTimePriceApi, createOneTimePriceApi, updateOneTimePriceApi, getOneTimePriceDetailsApi} from '../../services/oneTimePriceServices';
import {modalObj} from '../../utils/modal.js';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

/**
 * Search account type action
 * @param {object} payload
 * @param {object} queryParams
 * @return {Promise}
 */
export const searchOneTimePriceList = (payload, queryParams) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.SEARCH_ONE_TIME_PRICE_START});
    return searchOneTimePriceApi(payload, queryParams).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({type: ACTION_TYPES.SEARCH_ONE_TIME_PRICE_SUCCESS, oneTimePriceList: response.result.content});
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.SEARCH_ONE_TIME_PRICE_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.SEARCH_ONE_TIME_PRICE_FAIL}),
    );
  };
};

/**
 * createOneTimePrice
 * @param {object} payload
 * @param {object} props
 * @return {Promise}
 */
export const createOneTimePrice = (payload, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.CREATE_ONE_TIME_PRICE_START});
    return createOneTimePriceApi(payload).then(
      (response) => {
        if (response) {
          if (response.status === 200) {
            dispatch({type: ACTION_TYPES.CREATE_ONE_TIME_PRICE_SUCCESS});
            const prevPath = props.location?.state?.from ? props.location?.state?.from : ROUTE.LAYOUT + ROUTE.ONE_TIME_PRICE;
            props.history.push(prevPath);
            dispatch(setMessageModal(modalObj(true, response.message_code)));
          } else {
            if (response.status === 400 && response.message_code === 'error.one.time.price.less.than.plan.discount') {
              dispatch({type: ACTION_TYPES.CREATE_ONE_TIME_PRICE_FAIL});
              return response;
            } else {
              dispatch(setMessageModal(modalObj(true, response.message_code)));
            }
            dispatch({type: ACTION_TYPES.CREATE_ONE_TIME_PRICE_FAIL});
          }
        }
      },
      () => {
        dispatch({type: ACTION_TYPES.CREATE_ONE_TIME_PRICE_FAIL});
        dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
      },
    );
  };
};

/**
 * updateOneTimePrice
 * @param {object} payload
 * @param {Object} props
 * @return {Promise}
 */
export const updateOneTimePrice = (payload, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.UPDATE_ONE_TIME_PRICE_START});
    return updateOneTimePriceApi(payload).then(
      (response) => {
        if (response) {
          if (response.status === 200) {
            dispatch({type: ACTION_TYPES.UPDATE_ONE_TIME_PRICE_SUCCESS});
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            return response;
          } else {
            dispatch({type: ACTION_TYPES.UPDATE_ONE_TIME_PRICE_FAIL});
            if (response.status === 400 && response.message_code === 'error.one.time.price.less.than.plan.discount') {
              return response;
            } else {
              dispatch(setMessageModal(modalObj(true, response.message_code)));
            }
          }
        }
      },
      () => {
        dispatch({type: ACTION_TYPES.UPDATE_ONE_TIME_PRICE_FAIL});
        dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
      },
    );
  };
};

/**
 * getOneTimePriceDetails
 * @param {object} id
 * @param {object} props
 * @return {Promise}
 */
export const getOneTimePriceDetails = (id) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_ONE_TIME_PRICE_DETAILS_START});
    return getOneTimePriceDetailsApi(id).then(
        (response) => {
          if (response.status === 200 && response) {
            dispatch({type: ACTION_TYPES.GET_ONE_TIME_PRICE_DETAILS_SUCCESS, payload: response?.result});
            return response?.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.GET_ONE_TIME_PRICE_DETAILS_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.GET_ONE_TIME_PRICE_DETAILS_FAIL}),
    );
  };
};

/**
 * Export list driver
 * @param {object} payload
 * @param {object} queryParams
 * @return {object}
 */
export const exportOneTimePrice = (payload, queryParams) => {
  return (dispatch) => {
    return searchOneTimePriceApi(payload, queryParams).then(
        (response) => {
          if (response && response.status === 200) {
            return response.result?.content;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
          }
        },
        () => dispatch(setMessageModal(modalObj(true, 'Internal Server Error'))),
    );
  };
};
