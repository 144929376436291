import React, {Component} from 'react';

import {Button, Card, Container, Grid, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DetailIcon from '@material-ui/icons/Description';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {DATA_NULL, ROUTE, PERMISSION_ACTIONS} from '../../../common/constant';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getAllBanks} from '../../../stores/bank/action';

/**
 * Bank Management View
 */
class Index extends Component {
  /**
   * constructor
   * @param {props} props
   */
  constructor(props) {
    super(props);
    this.state = {
      bankData: [],
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount = () => {
    this.props.getAllBanks();
  };

  /**
   * render
   * @return {component}
   */
  render() {
    const {t, actions, bank} = this.props;
    const permission = {
      canRegister: actions.includes(PERMISSION_ACTIONS.REGISTER),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.bankManagement')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          <Card raised>
            <Container maxWidth="xl">
              {bank?.allBanks && bank?.allBanks?.length > 0 ? (
                <>
                  <Grid container spacing={1}>
                    <Grid container alignItems="center" justify="flex-start" item xs={3}>
                      <h3>
                        {bank?.allBanks.length} {t('common.case')}
                      </h3>
                    </Grid>
                    <Grid container alignItems="center" justify="flex-end" item xs={9}>
                      {permission.canRegister && (
                        <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.BANK_ADD}}>
                          <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />}>
                            {t('common.btnRegister')}
                          </Button>
                        </Link>
                      )}
                    </Grid>
                  </Grid>
                  <div className="scroll_area_700">
                    <Table size="small" aria-label="sticky table" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>{t('bankManagement.code')}</TableCell>
                          <TableCell>{t('bankManagement.bankName')}</TableCell>
                          <TableCell>{t('bankManagement.branchName')}</TableCell>
                          <TableCell>{t('bankManagement.accountNumber')}</TableCell>
                          <TableCell>{t('bankManagement.accountName')}</TableCell>
                          <TableCell>{t('bankManagement.transferContent')}</TableCell>
                          {permission.canEdit && (
                            <TableCell colSpan={2}>{t('common.action')}</TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {bank?.allBanks?.map((item, index) => (
                          <TableRow key={index} hover className="cursor_pointer">
                            <TableCell scope="row">{item.code}</TableCell>
                            <TableCell>{item.bank_name ? item.bank_name : DATA_NULL}</TableCell>
                            <TableCell>{item.branch_name ? item.branch_name : DATA_NULL}</TableCell>
                            <TableCell>{item.account_number ? item.account_number : DATA_NULL}</TableCell>
                            <TableCell>{item.account_name ? item.account_name : DATA_NULL}</TableCell>
                            <TableCell>{item.transfer_content ? item.transfer_content : DATA_NULL}</TableCell>
                            {permission.canEdit && (
                              <TableCell>
                                <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.BANK_UPDATE + '/' + item.id}}>
                                  <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                    {t('common.btnEdit')}
                                  </Button>
                                </Link>
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                  <br></br>
                </>
              ) : (
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    <h5 className="text_grey">
                      {t('common.noResult')}
                    </h5>
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {permission.canRegister && (
                      <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.BANK_ADD}}>
                        <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />}>
                          {t('common.btnRegister')}
                        </Button>
                      </Link>
                    )}
                  </Grid>
                </Grid>
              )}
            </Container>
            <br></br>
          </Card>
          <br></br>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    bank: state.bank,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllBanks: () => dispatch(getAllBanks()),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
