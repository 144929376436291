import {getErrorQrPaymentApi, deleteErrorQrPaymentApi, detailErrorQrPaymentApi, searchErrorQrPaymentApi} from '../../services/qrPaymentService';
import {modalObj} from '../../utils/modal.js';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

const loading = {
  type: ACTION_TYPES.LOADING,
};

const loading_fail = {
  type: ACTION_TYPES.LOADING_FAIL,
};

const createErrorQrPayment = (data) => {
  return {
    type: ACTION_TYPES.LIST_QR_PAYMENT_SUCCESS,
    errorQrPaymentSet: new Set(data),
  };
};

/**
 * getErrorQrPayment
 * @return {*}
 */
export const getErrorQrPayment = () => {
  return (dispatch) => {
    dispatch(loading);
    return getErrorQrPaymentApi().then((response) => {
      if (response?.status === 200) {
        dispatch(createErrorQrPayment(response.result));
      } else {
        dispatch(loading_fail);
        dispatch(setMessageModal(modalObj(true, response.message_code)));
      }
      return response.result;
    });
  };
};

export const searchErrorQrPayment = (payload) => {
  return (dispatch) => {
    dispatch(loading);
    return searchErrorQrPaymentApi(payload).then((response) => {
      if (response?.status === 200) {
        dispatch({
          type: ACTION_TYPES.SEARCH_QR_PAYMENT_SUCCESS,
          errorQrPaymentSet: new Set(response.result),
        });
      } else {
        dispatch(loading_fail);
        dispatch(setMessageModal(modalObj(true, response.message_code)));
      }
      return response.result;
    });
  };
};

/**
 * deleteQrPayment
 * @param {Object} data
 * @return {*}
 */
export const deleteQrPayment = (data) => {
  return (dispatch) => {
    dispatch(loading);
    return deleteErrorQrPaymentApi(data).then((response) => {
      dispatch(setMessageModal(modalObj(true, response.message_code)));
      if (response?.status === 200) {
        dispatch(createErrorQrPayment(response.result));
      } else {
        dispatch(loading_fail);
      }
      return response.status;
    });
  };
};
/**
 * detailQrPayment
 * @param {Number} id
 * @return {*}
 */
export const detailQrPayment = (id) => {
  return (dispatch) => {
    dispatch(loading);
    return detailErrorQrPaymentApi(id).then((response) => {
      if (response?.status === 200) {
        dispatch({
          type: ACTION_TYPES.LIST_QR_PAYMENT_SUCCESS,
          errorQrPaymentSet: response.result,
        });
      } else {
        dispatch(loading_fail);
        dispatch(setMessageModal(modalObj(true, response.message_code)));
      }
      return response;
    });
  };
};
