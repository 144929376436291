import {ROUTE} from '../../common/constant';
import {searchPartnerApi, createPartnerApi, updatePartnerApi, getPartnerDetailsApi} from '../../services/partnerServices';
import {backForwardRouter} from '../../utils/common';
import {modalObj} from '../../utils/modal.js';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

/**
 * Search account type action
 * @param {object} queryParams
 * @return {Promise}
 */
export const searchPartnerList = (queryParams) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.SEARCH_PARTNER_START});
    return searchPartnerApi(queryParams).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({type: ACTION_TYPES.SEARCH_PARTNER_SUCCESS, partnerList: response.result.content});
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.SEARCH_PARTNER_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.SEARCH_PARTNER_FAIL}),
    );
  };
};

/**
 * createPartner
 * @param {object} payload
 * @param {object} props
 * @return {Promise}
 */
export const createPartner = (payload, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.CREATE_PARTNER_START});
    return createPartnerApi(payload).then(
        (response) => {
          if (response.status === 200 && response) {
            dispatch({type: ACTION_TYPES.CREATE_PARTNER_SUCCESS});
            const prevPath = props.location?.state?.from ? props.location?.state?.from : ROUTE.LAYOUT + ROUTE.PARTNER_INFORMATION;
            props.history.push(prevPath);
          } else {
            dispatch({type: ACTION_TYPES.CREATE_PARTNER_FAIL});
          }
          dispatch(setMessageModal(modalObj(true, response.message_code)));
        },
        () => dispatch({type: ACTION_TYPES.CREATE_PARTNER_FAIL}),
    );
  };
};

/**
 * updatePartner
 * @param {object} payload
 * @param {object} props
 * @return {Promise}
 */
export const updatePartner = (payload, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.UPDATE_PARTNER_START});
    return updatePartnerApi(payload).then(
        (response) => {
          if (response.status === 200 && response) {
            dispatch({type: ACTION_TYPES.UPDATE_PARTNER_SUCCESS});
            // const prevPath = props.location?.state?.from ? props.location?.state?.from : ROUTE.LAYOUT + ROUTE.PARTNER_INFORMATION;
            // props.history.push(prevPath);
            return response;
          } else {
            dispatch({type: ACTION_TYPES.UPDATE_PARTNER_FAIL});
            dispatch(setMessageModal(modalObj(true, response.message_code)));
          }
          if (response.message_code === 'partner.waypoint.exists') {
            const arrNameStreet = [];
            // eslint-disable-next-line array-callback-return
            response.result.filter((item) => {
              const nameExists = props.listWaypointView.find((wayponint) => wayponint.id === item).street;
              arrNameStreet.push(nameExists);
            });
            dispatch(
                setMessageModal(
                    modalObj(
                        true,
                        props.t(response.message_code) +
                  ' ' +
                  arrNameStreet
                      .map((item) => {
                        return item;
                      })
                      .join(' & '),
                    ),
                ),
            );
          }
        },
        () => dispatch({type: ACTION_TYPES.UPDATE_PARTNER_FAIL}),
    );
  };
};

/**
 * getPartnerDetails
 * @param {object} id
 * @param {object} props
 * @return {Promise}
 */
export const getPartnerDetails = (id, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_PARTNER_DETAILS_START});
    return getPartnerDetailsApi(id).then(
        (response) => {
          if (response.status === 200 && response) {
            dispatch({type: ACTION_TYPES.GET_PARTNER_DETAILS_SUCCESS, payload: response?.result});
            return response?.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response?.message_code)));
            dispatch({type: ACTION_TYPES.GET_PARTNER_DETAILS_FAIL});
            if (response?.message_code === 'permission.denied') {
              backForwardRouter(props, ROUTE.LAYOUT + ROUTE.HOME);
            }
          }
        },
        () => dispatch({type: ACTION_TYPES.GET_PARTNER_DETAILS_FAIL}),
    );
  };
};
