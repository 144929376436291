export const ACTION_TYPES = {
  SEARCH_LIST_DRIVER_START: 'SEARCH_LIST_DRIVER_START',
  SEARCH_LIST_DRIVER_SUCCESS: 'SEARCH_LIST_DRIVER_SUCCESS',
  SEARCH_LIST_DRIVER_FAIL: 'SEARCH_LIST_DRIVER_FAIL',

  CREATE_DRIVER_START: 'CREATE_DRIVER_START',
  CREATE_DRIVER_SUCCESS: 'CREATE_DRIVER_SUCCESS',
  CREATE_DRIVER_FAIL: 'CREATE_DRIVER_FAIL',

  GET_DETAIL_DRIVER_START: 'GET_DETAIL_DRIVER_START',
  GET_DETAIL_DRIVER_SUCCESS: 'GET_DETAIL_DRIVER_SUCCESS',
  GET_DETAIL_DRIVER_FAIL: 'GET_DETAIL_DRIVER_FAIL',

  UPDATE_DRIVER_START: 'UPDATE_DRIVER_START',
  UPDATE_DRIVER_SUCCESS: 'UPDATE_DRIVER_SUCCESS',
  UPDATE_DRIVER_FAIL: 'UPDATE_DRIVER_FAIL',

  DELETE_DRIVER_START: 'DELETE_DRIVER_START',
  DELETE_DRIVER_SUCCESS: 'DELETE_DRIVER_SUCCESS',
  DELETE_DRIVER_FAIL: 'DELETE_DRIVER_FAIL',

  IMPORT_DRIVER_CSV_START: 'IMPORT_DRIVER_CSV_START',
  IMPORT_DRIVER_CSV_SUCCESS: 'IMPORT_DRIVER_CSV_SUCCESS',
  IMPORT_DRIVER_CSV_FAIL: 'IMPORT_DRIVER_CSV_FAIL',

  GET_DRIVER_RATING_START: 'GET_DRIVER_RATING_START',
  GET_DRIVER_RATING_SUCCESS: 'GET_DRIVER_RATING_SUCCESS',
  GET_DRIVER_RATING_FAIL: 'GET_DRIVER_RATING_FAIL',
};

const initialState = {
  isLoading: false,
  listDriver: [],
  detailDriver: {},
  driverRating: {},
};

/**
 * function reduce driver registration
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.SEARCH_LIST_DRIVER_START:
    case ACTION_TYPES.CREATE_DRIVER_START:
    case ACTION_TYPES.GET_DETAIL_DRIVER_START:
    case ACTION_TYPES.UPDATE_DRIVER_START:
    case ACTION_TYPES.DELETE_DRIVER_START:
    case ACTION_TYPES.IMPORT_DRIVER_CSV_START:
    case ACTION_TYPES.GET_DRIVER_RATING_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.SEARCH_LIST_DRIVER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listDriver: action.listDriver.content,
      };
    case ACTION_TYPES.GET_DETAIL_DRIVER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        detailDriver: action.detailDriver,
      };
    case ACTION_TYPES.CREATE_DRIVER_SUCCESS:
    case ACTION_TYPES.UPDATE_DRIVER_SUCCESS:
    case ACTION_TYPES.DELETE_DRIVER_SUCCESS:
    case ACTION_TYPES.IMPORT_DRIVER_CSV_SUCCESS:
    case ACTION_TYPES.CREATE_DRIVER_FAIL:
    case ACTION_TYPES.UPDATE_DRIVER_FAIL:
    case ACTION_TYPES.DELETE_DRIVER_FAIL:
    case ACTION_TYPES.IMPORT_DRIVER_CSV_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.SEARCH_LIST_DRIVER_FAIL:
      return {
        ...state,
        isLoading: false,
        listDriver: [],
      };
    case ACTION_TYPES.GET_DETAIL_DRIVER_FAIL:
      return {
        ...state,
        isLoading: false,
        detailDriver: {},
      };
    case ACTION_TYPES.GET_DRIVER_RATING_SUCCESS:
    case ACTION_TYPES.GET_DRIVER_RATING_FAIL:
      return {
        ...state,
        isLoading: false,
        driverRating: action.driverRating,
      };
    default:
      return state;
  }
}
