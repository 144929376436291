import {api} from '../services/api';

const roleListUrl = `admin/accounts/roles`;
const rolePermissionUrl = `admin/accounts/role-permission`;
const rolePermissionBySupplierIdUrl = `admin/accounts/role-permission`;
const sendMailUrl = `admin/accounts/invite`;
const searchAccountUrl = `admin/accounts/search`;
const getAccountUrl = `admin/accounts/`;
const updateAccountUrl = `admin/accounts`;
const resendInvitationUrl = `admin/accounts/invite/re-send`;
const restartAccountsUrl = `admin/accounts/restart`;
const suspendAccountsUrl = `admin/accounts/suspend`;
const permissionDataUrl = `admin/function/data`;
const getAllAccountTypesUrl = `admin/accounts/role-permission/invite`;

export const getRoleListApi = () => {
  return api.get(roleListUrl);
};

export const getRolePermissionListApi = () => {
  return api.get(rolePermissionUrl);
};

export const getRoleListPermissionBySupplierIdApi = (supplierId) => {
  return api.get(rolePermissionBySupplierIdUrl + '/' + supplierId);
};

export const sendMailApi = (body) => {
  return api.post(sendMailUrl, body);
};

export const searchAccountApi = (input, queryParams) => {
  return api.search(searchAccountUrl, queryParams, input);
};

export const getAccountDetailApi = (id) => {
  return api.get(getAccountUrl + id);
};

export const updateAccountApi = (payload) => {
  return api.put(updateAccountUrl, payload);
};

export const resendInvitationApi = (payload) => {
  return api.post(resendInvitationUrl, payload);
};

export const restartAccountsApi = (payload) => {
  return api.post(restartAccountsUrl, payload);
};

export const suspendAccountsApi = (payload) => {
  return api.post(suspendAccountsUrl, payload);
};

export const permissionDataApi = () => {
  return api.get(permissionDataUrl);
};

export const getAllAccountTypesApi = () => {
  return api.get(getAllAccountTypesUrl);
};
