import React, {Component} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  Grid,
  Container,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  RadioGroup,
  Button,
  FormControlLabel,
  FormHelperText,
  Radio,
  TextField,
  Dialog,
  Table,
  TableFooter,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Input,
  InputAdornment,
  Box,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {TimePicker, DatePicker} from '@material-ui/pickers';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {MOBI_TYPES, MONTH_NAMES, MONTH} from '../../../common/constant';
import {AutocompleteCommon} from '../../../components/jit/autocomplete/index';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getDetailVehicle, createVehicleJit, getDetailVehicleJit, getVehicleJitCalendar, getTransitStop} from '../../../stores/business_vehicles/action';
import {getListGeofence} from '../../../stores/common/actions';
import {searchJitRoute} from '../../../stores/jit_setting/action';
import {setMessageModal} from '../../../stores/modal/actions';
import {getListAllGeofenceDetail, onChangeTextField} from '../../../utils/common';
import {convertDateUTCAndGeofence, convertTimeUTCToCalendarLocal, getTimeDefault} from '../../../utils/date_time_utils';
import {addDays, isDate, toSeconds, days_between, isOverlapDate, getClientTimezone, convertHMSToHM} from '../../../utils/datetime';
import {modalObj} from '../../../utils/modal';
import './styles.css';

const StyledTableRow = {
  padding: '0px!important',
  border: '1px solid rgb(121 104 104)',
  lineHeight: 1,
};
const StyledTableFirstRow = {
  padding: '0px!important',
  width: '16%',
  border: '1px solid rgb(121 104 104)',
  lineHeight: 1,
};
const styleTable = {
  backgroundColor: 'white',
  boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
};

/**
 * JIT setting
 */
class Index extends Component {
  /**
   * Constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      firstDay: 1,
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      totalDay: 0,
      mobiType: [],
      businessName: '',
      vehicleId: '',
      vehicleIdShow: '',
      vehicleName: '',
      saleOfficeName: '',
      vehicleNo: '',
      vehicleColor: '',
      geofenceId: '',
      lstBusStops: [],
      lstBusStopsHTW: [],
      lstBusStopsWTH: [],
      virtualBusStopId: '',
      virtualBusStopIdHTW: '',
      virtualBusStopIdWTH: '',
      isDisableVirtualBus: false,
      isDisableVirtualBusWTH: false,
      isDisableVirtualBusHTW: false,
      virtualBusWthLat: '',
      virtualBusWthLon: '',
      virtualBusStopWthId: '',
      virtualBusHtwLat: '',
      virtualBusHtwLon: '',
      virtualBusStopHtwId: '',
      stopLat: '',
      stopLong: '',
      virtual_bus_stop_name: '',
      publishStart: null,
      publishEnd: null,
      zone_id: '',

      departureTimeType: 'DAY',
      departureDayDay: '',
      departureDayHour: '',
      departureHourHour: '',
      departureHourMinute: '',
      departureSetTimeFrom: null,
      departureSetTimeTo: null,
      intervalDepartureTime: '',

      arrivalTimeType: 'DAY',
      arrivalDayDay: '',
      arrivalDayHour: '',
      arrivalHourHour: '',
      arrivalHourMinute: '',
      arrivalSetTimeFrom: null,
      arrivalSetTimeTo: null,
      intervalArrivalTime: '',

      bufferDepartureTime: 0,
      bufferArrivalTime: 0,

      departureBreakTime: [],
      departureBreakTimeList: [],
      arrivalBreakTime: [],
      arrivalBreakTimeList: [],
      listRoute: [],
      operatorStart: '',
      operatorEnd: '',
      flg: false,
      message: '',
      isValidateCalendar: true,
      isRegister: false,
      vehicle_start: '',
      vehicle_end: '',
      isValidPublicStart: true,
      isValidPublicEnd: true,
      just_in_time_calendars: [],

      intervalDepartureMaxRow: 1,
      intervalArrivalMaxRow: 1,
      selectedDate: '',

      isSubmitForm: false,
      isHavingShift: false,
      vehicleInfos: {},
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * listIntervalTime
   * @return {Array}
   */
  listIntervalTime = () => {
    const list = [];
    for (let i = 0; i < 36; i++) {
      list.push(5 * (i + 1));
    }
    return list;
  };

  /**
   * componentDidMount
   */
  async componentDidMount() {
    let {departureDayDay, departureDayHour, departureHourHour, departureHourMinute, arrivalDayDay, arrivalHourHour, arrivalDayHour, arrivalHourMinute} = this.state;
    // get detail vehicle
    await this.props.getDetailVehicle(this.props.match.params.id, this.props).then(async (result) => {
      if (result) {
        this.setState({
          vehicleIdShow: result.vehicle_id,
          vehicleId: result.id,
          businessName: result.supplier_name,
          mobiType: result.mobi_type,
          vehicleNo: result.registration_number,
          vehicleName: result.brand,
          saleOfficeName: result.supplier_facility_name,
          vehicleColor: result.vehicle_color,
          geofenceId: result.geofence_id,
          operatorStart: result.start_time?.slice(0, 5),
          operatorEnd: result.end_time?.slice(0, 5),
          vehicle_start: result.start_time,
          vehicle_end: result.end_time,
          vehicleInfos: result,
        });
      }
    });

    // get list geofence
    await this.props.getListGeofence({targetScreen: 'ENABLE_FOR_ADMIN_ALL'});
    const geofence = this.props.common?.geofence_list?.find((item) => item.geofence_id === this.state.geofenceId);
    this.setState({zone_id: geofence?.zone_id});

    // get list route for JIT
    await this.props.searchJitRoute(
      {geofence_id: this.props.businessVehicle?.detail?.geofence_id},
      {
        page: 0,
        size: 100,
        sort: 'last_modified_at,desc',
      },
    );
    const listRoute = this.props.jitSetting?.routeSearch?.content?.map((item) => {
      return {
        name: item.name,
        duration: item.turn_locations?.map((item) => item.estimate_driving_time).reduce((a, b) => a + b, 0),
      };
    });
    this.setState({listRoute});

    // get Detail JIT vehicle
    await this.props.getDetailVehicleJit({vehicle_id: this.props.match.params.id}).then((result) => {
      if (result) {
        if (result.departure_time_before) {
          if (result.departure_time_type === 'DAY' && result.departure_time_before) {
            departureDayDay = Math.floor(result.departure_time_before / 1440);
            departureDayHour = Math.floor((result.departure_time_before % 1440) / 60);
          } else {
            departureHourHour = Math.floor(result.departure_time_before / 60);
            departureHourMinute = result.departure_time_before % 60;
          }
        }

        if (result.arrival_time_before) {
          if (result.arrival_time_type === 'DAY') {
            arrivalDayDay = Math.floor(result.arrival_time_before / 1440);
            arrivalDayHour = Math.floor((result.arrival_time_before % 1440) / 60);
          } else {
            arrivalHourHour = Math.floor(result.arrival_time_before / 60);
            arrivalHourMinute = result.arrival_time_before % 60;
          }
        }

        this.setState({
          virtualBusStopIdHTW: result.virtual_bus_stop_id_htw ? result.virtual_bus_stop_id_htw : '',
          virtualBusStopIdWTH: result.virtual_bus_stop_id_wth ? result.virtual_bus_stop_id_wth : '',
          isDisableVirtualBus: result.virtual_bus_stop_id ? true : false,
          isDisableVirtualBusWth: result.virtual_bus_stop_wth_id,
          isDisableVirtualBusHtw: result.virtual_bus_stop_htw_id,
          stopLat: result.virtual_bus_stop_lat,
          stopLong: result.virtual_bus_stop_lon,
          virtual_bus_stop_name: result.virtual_bus_stop_name,
          virtualBusWthLat: result.virtual_bus_wth_lat,
          virtualBusWthLon: result.virtual_bus_wth_lon,
          virtualBusStopWthId: result.virtual_bus_stop_wth_id,
          virtualBusHtwLat: result.virtual_bus_htw_lat,
          virtualBusHtwLon: result.virtual_bus_htw_lon,
          virtualBusStopHtwId: result.virtual_bus_stop_htw_id,
          publishStart: result.publish_start ? convertDateUTCAndGeofence(result.publish_start, this.state.zone_id, true) : this.state.publishStart,
          publishEnd: result.publish_end ? convertDateUTCAndGeofence(result.publish_end, this.state.zone_id, true) : this.state.publishEnd,
          year: result.publish_end ? convertDateUTCAndGeofence(result.publish_end, this.state.zone_id, true).getFullYear() : new Date().getFullYear(),
          month: result.publish_end ? convertDateUTCAndGeofence(result.publish_end, this.state.zone_id, true).getMonth() + 1 : new Date().getMonth() + 1,
          departureTimeType: result.departure_time_type || 'DAY',

          departureDayDay: departureDayDay,
          departureDayHour: departureDayHour,
          departureHourHour: departureHourHour,
          departureHourMinute: departureHourMinute,

          arrivalDayDay: arrivalDayDay,
          arrivalDayHour: arrivalDayHour,
          arrivalHourHour: arrivalHourHour,
          arrivalHourMinute: arrivalHourMinute,
          arrivalTimeBefore: result.arrival_time_before,
          arrivalTimeType: result.arrival_time_type || 'DAY',

          isHavingShift: result.is_having_shift,
        });
      }
    });

    // get transit stop list
    if (geofence) {
      if (this.state.mobiType.includes('SHUTTLE_BUS_JIT_HOME_TO_WORK')) {
        await this.props.getTransitStop(
          geofence?.service_group_id,
          [geofence?.jit_home_to_work_sim_id || '', geofence?.jit_work_to_home_sim_id || ''],
          ['SHUTTLE_BUS_JIT_HOME_TO_WORK'],
        );
        this.setState({
          lstBusStopsHTW: this.props.businessVehicle?.transitStop?.filter((item) => item?.is_work === false),
          virtual_bus_stop_name: this.props.businessVehicle?.transitStop?.find((item) => item.id === this.state.virtualBusStopId)?.name,
        });
      }
      if (this.state.mobiType.includes('SHUTTLE_BUS_JIT_WORK_TO_HOME')) {
        await this.props.getTransitStop(
          geofence?.service_group_id,
          [geofence?.jit_home_to_work_sim_id || '', geofence?.jit_work_to_home_sim_id || ''],
          ['SHUTTLE_BUS_JIT_WORK_TO_HOME'],
        );
        this.setState({
          lstBusStopsWTH: this.props.businessVehicle?.transitStop?.filter((item) => item?.is_work === true),
          virtual_bus_stop_name: this.props.businessVehicle?.transitStop?.find((item) => item.id === this.state.virtualBusStopId)?.name,
        });
      }
    }

    // get calendar JIT
    this.getJitCalendarData(this.props.match.params.id, this.state.month, this.state.year);
  }

  /**
   * componentDidUpdate
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.state.publishEnd && this.state.publishStart && (prevState.publishEnd !== this.state.publishEnd || prevState.publishStart !== this.state.publishStart)) {
      this.setState({
        year: this.state.publishEnd.getFullYear(),
        month: this.state.publishEnd.getMonth() + 1,
      });
    }
    if (this.state.year !== prevState.year && this.state.month !== prevState.month) {
      const id = this.props.match.params.id;
      this.getJitCalendarData(id, this.state.month, this.state.year);
    }
  }

  /**
   * getJitCalendarData
   * @param {string} vehicle_id
   * @param {string} month
   * @param {string} year
   */
  getJitCalendarData(vehicle_id, month, year) {
    const payload = {
      vehicle_id: vehicle_id,
      month: '',
      year: year,
    };
    this.props.getVehicleJitCalendar(payload).then((res) => {
      this.handleInitCalendar(res || [], month, year);
    });
  }

  /**
   * isValidDepartureTime
   * @return {*}
   */
  isValidDepartureTime() {
    if (!this.state.departureSetTimeFrom || !this.state.departureSetTimeTo) {
      return true;
    }
    return (
      toSeconds(getTimeDefault(this.state.departureSetTimeFrom)) >= toSeconds(this.state.vehicle_start) &&
      toSeconds(getTimeDefault(this.state.departureSetTimeTo)) <= toSeconds(this.state.vehicle_end)
    );
  }

  /**
   * isValidArrivalTime
   * @return {*}
   */
  isValidArrivalTime() {
    if (!this.state.arrivalSetTimeFrom || !this.state.arrivalSetTimeTo) {
      return true;
    }
    return (
      toSeconds(getTimeDefault(this.state.arrivalSetTimeFrom)) >= toSeconds(this.state.vehicle_start) &&
      toSeconds(getTimeDefault(this.state.arrivalSetTimeTo)) <= toSeconds(this.state.vehicle_end)
    );
  }

  /**
   * changeGeofence
   * @param {*} geofence_id
   */
  async changeGeofence(geofence_id) {
    this.setState({
      geofenceId: geofence_id,
      virtualBusStopId: '',
      stopLat: '',
      stopLong: '',
      virtualBusWthLat: '',
      virtualBusWthLon: '',
      virtualBusStopWthId: '',
      virtualBusHtwLat: '',
      virtualBusHtwLon: '',
      virtualBusStopHtwId: '',
    });

    const geofence = this.props.common.geofence_list?.find((item) => item.geofence_id === geofence_id);
    if (geofence) {
      if (this.state.mobiType.includes('SHUTTLE_BUS_JIT_HOME_TO_WORK')) {
        await this.props.getTransitStop(
          geofence?.service_group_id,
          [geofence?.jit_home_to_work_sim_id || '', geofence?.jit_work_to_home_sim_id || ''],
          'SHUTTLE_BUS_JIT_HOME_TO_WORK',
        );
        this.setState({
          lstBusStopsHTW: this.props.businessVehicle?.transitStop?.filter((item) => item?.is_work === false),
          virtual_bus_stop_name: this.props.businessVehicle?.transitStop?.find((item) => item.id === this.state.virtualBusStopId)?.name,
        });
      }
      if (this.state.mobiType.includes('SHUTTLE_BUS_JIT_WORK_TO_HOME')) {
        await this.props.getTransitStop(
          geofence?.service_group_id,
          [geofence?.jit_home_to_work_sim_id || '', geofence?.jit_work_to_home_sim_id || ''],
          'SHUTTLE_BUS_JIT_WORK_TO_HOME',
        );
        this.setState({
          lstBusStopsWTH: this.props.businessVehicle?.transitStop?.filter((item) => item?.is_work === true),
          virtual_bus_stop_name: this.props.businessVehicle?.transitStop?.find((item) => item.id === this.state.virtualBusStopId)?.name,
        });
      }
    }
  }

  /**
   * onChangeBusStop
   * @param {*} bus_stop_id
   * @param {String} type
   */
  onChangeBusStop(bus_stop_id, type) {
    if (type === 'HTW') {
      this.setState({
        virtualBusStopIdHTW: bus_stop_id,
      });
    } else {
      this.setState({
        virtualBusStopIdWTH: bus_stop_id,
      });
    }

    const busStops = this.state.lstBusStops.find((item) => item.id === bus_stop_id);
    if (busStops) {
      this.setState({
        stopLat: busStops.point.coordinates[1],
        stopLong: busStops.point.coordinates[0],
        virtual_bus_stop_name: busStops.name,
      });
    }
  }

  /**
   * onChangeBusStopWTH
   * @param {Number} bus_stop_id
   */
  onChangeBusStopWTH(bus_stop_id) {
    this.setState({
      virtualBusStopWthId: bus_stop_id,
    });
    const busStops = this.state.lstBusStops.find((item) => item.id === bus_stop_id);
    if (busStops) {
      this.setState({
        virtualBusWthLat: busStops.point.coordinates[1],
        virtualBusWthLon: busStops.point.coordinates[0],
      });
    }
  }

  /**
   * onChangeBusStopHTW
   * @param {Number} bus_stop_id
   */
  onChangeBusStopHTW(bus_stop_id) {
    this.setState({
      virtualBusStopHtwId: bus_stop_id,
    });
    const busStops = this.state.lstBusStops.find((item) => item.id === bus_stop_id);
    if (busStops) {
      this.setState({
        virtualBusHtwLat: busStops.point.coordinates[1],
        virtualBusHtwLon: busStops.point.coordinates[0],
      });
    }
  }

  /**
   * subMoreMinutes
   * @param {Date} date
   * @param {Number} min
   * @return {Date}
   */
  subMoreMinutes(date, min) {
    if (!date) {
      return date;
    }
    return new Date(date.getTime() - min * 60 * 1000);
  }

  /**
   * addMoreMinutes
   * @param {Date} date
   * @param {Number} min
   * @return {Date}
   */
  addMoreMinutes(date, min) {
    if (!date) {
      return date;
    }
    return new Date(date.getTime() + min * 60 * 1000);
  }

  /**
   * handleInitCalendar
   * @param {string} rawCalendar
   * @param {string} month
   * @param {string} year
   */
  handleInitCalendar(rawCalendar, month, year) {
    const totalDay = new Date(year, month, 0).getDate();
    const firstDay = new Date(month + '-1-' + year).getDay() ? new Date(month + '-1-' + year).getDay() - 1 : 6;
    const just_in_time_calendars = [];
    // just_in_time_calendars
    rawCalendar.forEach((item) => {
      const is_overlap = isOverlapDate(
        new Date(item.departure_time_start),
        this.addMoreMinutes(new Date(item.departure_time_end), item.interval_departure_time),
        this.subMoreMinutes(new Date(item.arrival_time_start), item.interval_arrival_time),
        new Date(item.arrival_time_end),
      );
      const raw = {};
      raw.departure_only_time_start = convertDateUTCAndGeofence(item.departure_time_start, this.state.zone_id, true);
      raw.departure_time_start = item.departure_time_start;
      raw.departure_only_time_end = convertDateUTCAndGeofence(item.departure_time_end, this.state.zone_id, true);
      raw.departure_time_end = item.departure_time_end;
      raw.is_departure_validate =
        this.isEndDateGreaterThanStartDate(
          convertDateUTCAndGeofence(item.departure_time_end, this.state.zone_id, true),
          convertDateUTCAndGeofence(item.departure_time_start, this.state.zone_id, true),
        ) && !is_overlap;
      raw.arrival_only_time_start = convertDateUTCAndGeofence(item.arrival_time_start, this.state.zone_id, true);
      raw.arrival_time_start = item.arrival_time_start;
      raw.arrival_only_time_end = convertDateUTCAndGeofence(item.arrival_time_end, this.state.zone_id, true);
      raw.arrival_time_end = item.arrival_time_end;
      raw.is_arrival_validate =
        this.isEndDateGreaterThanStartDate(
          convertDateUTCAndGeofence(item.arrival_time_end, this.state.zone_id, true),
          convertDateUTCAndGeofence(item.arrival_time_start, this.state.zone_id, true),
        ) && !is_overlap;
      raw.interval_departure_time = item.interval_departure_time;
      raw.interval_arrival_time = item.interval_arrival_time;
      raw.is_active = item.is_active;
      raw.day = new Date(convertDateUTCAndGeofence(item.departure_time_start ? item.departure_time_start : item.arrival_time_end, this.state.zone_id, true)).getDate();
      raw.month = new Date(convertDateUTCAndGeofence(item.departure_time_start ? item.departure_time_start : item.arrival_time_end, this.state.zone_id, true)).getMonth() + 1;
      raw.year = new Date(convertDateUTCAndGeofence(item.departure_time_start ? item.departure_time_start : item.arrival_time_end, this.state.zone_id, true)).getFullYear();
      raw.departure_break_time = [];
      raw.arrival_break_time = [];
      raw.break_periods = item.just_in_time_break_periods;
      item.just_in_time_break_periods.forEach((breakTime) => {
        if (breakTime.group_type === 'SHUTTLE_BUS_JIT_WORK_TO_HOME') {
          raw.departure_break_time.push(
            convertTimeUTCToCalendarLocal(breakTime.break_time_start, this.state.zone_id) + ' - ' + convertTimeUTCToCalendarLocal(breakTime.break_time_end, this.state.zone_id),
          );
        }

        if (breakTime.group_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK') {
          raw.arrival_break_time.push(
            convertTimeUTCToCalendarLocal(breakTime.break_time_start, this.state.zone_id) + ' - ' + convertTimeUTCToCalendarLocal(breakTime.break_time_end, this.state.zone_id),
          );
        }
      });
      just_in_time_calendars.push(raw);
    });

    this.setState({
      firstDay: firstDay,
      totalDay: totalDay,
      just_in_time_calendars: just_in_time_calendars,
    });
  }

  /**
   * changeMonth
   * @param {Object} value
   */
  changeMonth = (value) => {
    const {year} = this.state;
    const firstDay = new Date(value + '-1-' + year).getDay() ? new Date(value + '-1-' + year).getDay() - 1 : 6;
    const totalDay = new Date(year, value, 0).getDate();
    this.setState({
      month: value,
      firstDay: firstDay,
      totalDay: totalDay,
    });
  };

  /**
   * changeYear
   * @param {Object} value
   */
  changeYear = (value) => {
    const {month} = this.state;
    const firstDay = new Date(month + '-1-' + value).getDay() ? new Date(month + '-1-' + value).getDay() - 1 : 6;
    const totalDay = new Date(value, month, 0).getDate();
    this.setState({
      year: value,
      firstDay: firstDay,
      totalDay,
    });
  };

  /**
   * handleChangeMonth
   * @param {string} type
   */
  handleChangeMonth(type) {
    let month = this.state.month;
    let year = this.state.year;
    if (type === 'next') {
      if (month === 12) {
        month = 1;
        year = year + 1;
      } else {
        month = month + 1;
      }
    } else {
      if (month === 1) {
        month = 12;
        year = year - 1;
      } else {
        month = month - 1;
      }
    }
    const firstDay = new Date(month + '-1-' + year).getDay() ? new Date(month + '-1-' + year).getDay() - 1 : 6;
    const totalDay = new Date(year, month, 0).getDate();
    this.setState({
      month,
      year,
      firstDay,
      totalDay,
    });
  }

  /**
   * daysInMonth
   * @param {String} month
   * @param {String} year
   * @return {String}
   */
  daysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  /**
   * onChangeCheckbox
   * @param {object} index
   */
  onChangeCheckbox = (index) => {
    const just_in_time_calendars = this.state.just_in_time_calendars;
    just_in_time_calendars[index].is_active = !this.state.just_in_time_calendars[index].is_active;
    this.setState({
      just_in_time_calendars: just_in_time_calendars,
    });
  };

  /**
   * handleChangeMobiType
   * @param {string} value
   */
  handleChangeMobiType(value) {
    this.setState({mobiType: value});
  }

  /**
   * checkTimeRange
   * @param {String} type
   * @return {Boolean}
   */
  checkTimeRange(type) {
    if (type === 'departure') {
      return this.state.departureSetTimeFrom && this.state.departureSetTimeTo && this.state.departureSetTimeFrom > this.state.departureSetTimeTo;
    } else {
      return this.state.arrivalSetTimeFrom && this.state.arrivalSetTimeTo && this.state.arrivalSetTimeFrom > this.state.arrivalSetTimeTo;
    }
  }

  /**
   * handleRegister
   */
  async handleRegister() {
    this.setState({
      isSubmitForm: true,
    });
    if (this.isValidate()) {
      const {
        departureSetTimeFrom,
        departureSetTimeTo,
        arrivalSetTimeFrom,
        arrivalSetTimeTo,
        intervalDepartureTime,
        intervalArrivalTime,
        departureBreakTime,
        arrivalBreakTime,
        publishStart,
        publishEnd,
      } = this.state;
      const just_in_time_calendars = [];
      // get total Date:
      const total_days = days_between(publishEnd, publishStart);
      const startDate = publishStart.getFullYear() + '-' + (publishStart.getMonth() + 1) + '-' + publishStart.getDate();
      for (let i = 0; i < total_days; i++) {
        if ((departureSetTimeFrom && departureSetTimeFrom) || (arrivalSetTimeFrom && arrivalSetTimeTo)) {
          const dateAdd = new Date(addDays(publishStart, i));
          const item = {
            departure_only_time_start: departureSetTimeFrom,
            departure_time_start: departureSetTimeFrom ?
              this.addDaysWithLocalTime(startDate + ' ' + convertTimeUTCToCalendarLocal(departureSetTimeFrom, this.state.zone_id) + ':00', i) :
              '',
            departure_only_time_end: departureSetTimeTo,
            departure_time_end: departureSetTimeTo ?
              this.addDaysWithLocalTime(startDate + ' ' + convertTimeUTCToCalendarLocal(departureSetTimeTo, this.state.zone_id) + ':00', i) :
              '',
            is_departure_validate: true,
            arrival_only_time_start: arrivalSetTimeFrom,
            arrival_time_start: arrivalSetTimeFrom ?
              this.addDaysWithLocalTime(startDate + ' ' + convertTimeUTCToCalendarLocal(arrivalSetTimeFrom, this.state.zone_id) + ':00', i) :
              '',
            arrival_only_time_end: arrivalSetTimeTo,
            arrival_time_end: arrivalSetTimeTo ? this.addDaysWithLocalTime(startDate + ' ' + convertTimeUTCToCalendarLocal(arrivalSetTimeTo, this.state.zone_id) + ':00', i) : '',
            is_arrival_validate: true,
            interval_departure_time: intervalDepartureTime,
            interval_arrival_time: intervalArrivalTime,
            is_active: true,
            day: dateAdd.getDate(),
            month: dateAdd.getMonth() + 1,
            year: dateAdd.getFullYear(),
            break_periods: [],
          };
          if (dateAdd >= publishStart && dateAdd <= publishEnd) {
            item.departure_break_time = departureBreakTime;
            item.arrival_break_time = arrivalBreakTime;
            departureBreakTime.forEach((breakData, idx) => {
              const departure_break = breakData.split('-');
              item.break_periods.push({
                break_time_start: this.addDaysWithLocalTime(startDate + ' ' + departure_break[0].trim() + ':00', i),
                break_time_end: this.addDaysWithLocalTime(startDate + ' ' + departure_break[1].trim() + ':00', i),
                group_type: 'SHUTTLE_BUS_JIT_WORK_TO_HOME',
              });
            });

            arrivalBreakTime.forEach((breakData, idx) => {
              const arrival_break = breakData.split('-');
              item.break_periods.push({
                break_time_start: this.addDaysWithLocalTime(startDate + ' ' + arrival_break[0].trim() + ':00', i),
                break_time_end: this.addDaysWithLocalTime(startDate + ' ' + arrival_break[1].trim() + ':00', i),
                group_type: 'SHUTTLE_BUS_JIT_HOME_TO_WORK',
              });
            });
          } else {
            item.departure_break_time = new Array(departureBreakTime.length).fill('');
            item.arrival_break_time = new Array(arrivalBreakTime.length).fill('');
            item.break_periods = [];
            this.setState({
              intervalDepartureMaxRow: 1,
              intervalArrivalMaxRow: 1,
            });
          }
          just_in_time_calendars.push(item);
        }
      }
      this.setState({
        just_in_time_calendars: just_in_time_calendars,
      });
    }
  }

  /**
   * addDaysWithLocalTime
   * @param {*} theDate
   * @param {*} days
   * @return {*}
   */
  addDaysWithLocalTime(theDate, days) {
    if (!theDate) return '';
    return convertDateUTCAndGeofence(new Date(theDate).getTime() + days * 24 * 60 * 60 * 1000, this.state.zone_id, false);
  }

  /**
   * onChangeTimeInterval
   * @param {string} value
   * @param {string} type
   */
  onChangeTimeInterval(value, type) {
    if (type === 'departure') {
      this.setState({
        intervalDepartureTime: value,
        departureBreakTime: [],
      });
    }
    if (type === 'arrival') {
      this.setState({
        intervalArrivalTime: value,
        arrivalBreakTime: [],
      });
    }
  }

  /**
   * isValidateMaxRange
   * @param {*} start
   * @param {*} end
   * @return {Boolean}
   */
  isValidateMaxRange(start, end) {
    if (!start || !end) {
      return true;
    }
    if (start.getFullYear() !== end.getFullYear()) {
      return false;
    }
    if (start.getMonth() !== end.getMonth()) {
      return false;
    }
    return true;
  }

  /**
   * onChangeTimeBuffer
   * @param {*} value
   * @param {*} type
   */
  onChangeTimeBuffer(value, type) {
    if (type === 'departure') {
      this.setState({
        bufferDepartureTime: value,
      });
    }
    if (type === 'arrival') {
      this.setState({
        bufferArrivalTime: value,
      });
    }
  }

  /**
   * handleRegisterSubmit
   */
  handleRegisterSubmit() {
    this.setState({
      isSubmitForm: true,
    });
    let checkEachItemValid = true;
    const now = new Date();
    const dateNow = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate();
    this.state.just_in_time_calendars.map((item, index) => {
      const currentDate = item.year + '-' + item.month + '-' + item.date;
      const isDisablePast = new Date(dateNow) > new Date(currentDate);
      if (!isDisablePast && (!item.is_departure_validate || !item.is_arrival_validate)) {
        checkEachItemValid = false;
      }
      return item;
    });

    if (this.isValidate() && checkEachItemValid && this.isValidIntervalTime()) {
      this.setState({
        flg: true,
        message: this.state.isHavingShift ? this.props.t('jit.updateSettingMsgHavingShift') : this.props.t('jit.updateSettingMsg'),
      });
    }
  }

  /**
   * onChangeCheckAll
   */
  onChangeCheckAll() {
    const just_in_time_calendars = this.state.just_in_time_calendars;
    for (let i = 0; i < just_in_time_calendars.length; i++) {
      just_in_time_calendars[i].is_active = !this.state.just_in_time_calendars[i].is_active;
    }
    this.setState({
      checkAll: !this.state.checkAll,
      just_in_time_calendars,
    });
  }

  /**
   * isValidate
   * @return {*}
   */
  isValidate() {
    // const date = this.state.publishStart;
    // const now = new Date();
    // if (date <= now) {
    //   this.props.setMessageModal(modalObj(true, this.props.t('jit.setPast')));
    //   return false;
    // }
    return (
      this.validator.allValid() &&
      !this.checkTimeRange('departure') &&
      !this.checkTimeRange('arrival') &&
      this.isValidArrivalTime() &&
      this.isValidDepartureTime() &&
      this.isValidateMaxRange(this.state.publishStart, this.state.publishEnd) &&
      !isOverlapDate(
        this.state.departureSetTimeFrom,
        this.addMoreMinutes(this.state.departureSetTimeTo, this.state.intervalDepartureTime),
        this.subMoreMinutes(this.state.arrivalSetTimeFrom, this.state.intervalArrivalTime),
        this.state.arrivalSetTimeTo,
      )
    );
  }

  /**
   * isValidIntervalTime
   * @return {Boolean}
   */
  isValidIntervalTime = () => {
    const {just_in_time_calendars} = this.state;
    const requireDepartureInterval = just_in_time_calendars.find((item) => !item.interval_departure_time);
    const requireArrivalInterval = just_in_time_calendars.find((item) => !item.interval_arrival_time);
    if (requireDepartureInterval && this.state.mobiType.includes('SHUTTLE_BUS_JIT_WORK_TO_HOME')) {
      this.props.setMessageModal(modalObj(true, 'error.select.interval_departure'));
      return false;
    }
    if (requireArrivalInterval && this.state.mobiType.includes('SHUTTLE_BUS_JIT_HOME_TO_WORK')) {
      this.props.setMessageModal(modalObj(true, 'error.select.interval_arrival'));
      return false;
    }
    return true;
  }

  /**
   * handleButtonOk
   * @param {object} formData
   */
  handleButtonUpdateOk = () => {
    this.setState({
      flg: false,
    });
    const {
      // calendar,
      vehicleId,
      virtualBusStopIdHTW,
      virtualBusStopIdWTH,
      publishStart,
      publishEnd,
      mobiType,
      departureTimeType,
      arrivalTimeType,

      departureDayDay,
      departureDayHour,
      departureHourHour,
      departureHourMinute,

      arrivalDayDay,
      arrivalDayHour,
      arrivalHourHour,
      arrivalHourMinute,
      zone_id,
    } = this.state;
    const departure_time_before =
      departureTimeType === 'DAY' ? parseInt(departureDayDay) * 1440 + parseInt(departureDayHour) * 60 : parseInt(departureHourHour) * 60 + parseInt(departureHourMinute);

    const arrival_time_before =
      arrivalTimeType === 'DAY' ? parseInt(arrivalDayDay) * 1440 + parseInt(arrivalDayHour) * 60 : parseInt(arrivalHourHour) * 60 + parseInt(arrivalHourMinute);

    const jit_calendars = [];

    this.state.just_in_time_calendars.map((item, index) => {
      if ((item.departure_time_start && item.departure_time_end) || (item.arrival_time_start && item.arrival_time_end)) {
        jit_calendars.push(item);
      }
      return item;
    });

    const payload = {
      vehicle_id: vehicleId,
      virtual_bus_stop_id_wth: virtualBusStopIdWTH,
      virtual_bus_stop_id_htw: virtualBusStopIdHTW,
      virtual_bus_stop_lat: this.state.stopLat,
      virtual_bus_stop_lon: this.state.stopLong,
      virtual_bus_stop_name: this.state.virtual_bus_stop_name,
      virtual_bus_wth_lat: this.state.virtualBusWthLat,
      virtual_bus_wth_lon: this.state.virtualBusWthLon,
      virtual_bus_wth_id: this.state.virtualBusWthId,
      virtual_bus_htw_lat: this.state.virtualBusHtwLat,
      virtual_bus_htw_lon: this.state.virtualBusHtwLon,
      virtual_bus_htw_id: this.state.virtualBusHtwId,
      publish_start: convertDateUTCAndGeofence(new Date(publishStart), this.state.zone_id, false),
      publish_end: convertDateUTCAndGeofence(new Date(publishEnd), this.state.zone_id, false),
      group_types: mobiType.split(','),
      just_in_time_calendars: jit_calendars,
      zone_id: zone_id || getClientTimezone(),
      buffer_departure_time: this.state.bufferDepartureTime,
      buffer_arrival_time: this.state.bufferArrivalTime,
    };
    if (mobiType.includes('SHUTTLE_BUS_JIT_WORK_TO_HOME')) {
      payload['departure_time_before'] = departure_time_before;
      payload['departure_time_type'] = departureTimeType === 'DAY' ? departureTimeType : 'MINUTE';
    }

    if (mobiType.includes('SHUTTLE_BUS_JIT_HOME_TO_WORK')) {
      payload['arrival_time_before'] = arrival_time_before;
      payload['arrival_time_type'] = arrivalTimeType === 'DAY' ? arrivalTimeType : 'MINUTE';
    }
    this.props.createVehicleJit(payload).then((response) => {
      if (response) {
        return this.props.history.goBack;
      }
    });
  };

  /**
   * handleButtonCancel
   */
  handleButtonUpdateCancel() {
    this.setState({
      flg: false,
    });
  }

  /**
   * changeTimeDepartureFrom
   * @param {Object} indexRaw
   * @param {Object} type
   * @param {Object} time
   */
  changeTimeCalendarFrom = (indexRaw, type, time) => {
    const just_in_time_calendars = [...this.state.just_in_time_calendars];
    const item = {...just_in_time_calendars[indexRaw]};
    let is_departure_greater = true;
    let is_arrival_greater = true;
    if (type === 'departure') {
      item.departure_only_time_start = time;
      const temp_departure_start = item.year + '-' + item.month + '-' + item.day + ' ' + convertTimeUTCToCalendarLocal(time, this.state.zone_id);
      if (isDate(temp_departure_start)) {
        item.departure_time_start = convertDateUTCAndGeofence(new Date(temp_departure_start), this.state.zone_id, false);
      }
      is_departure_greater = this.isEndDateGreaterThanStartDate(item.departure_only_time_end, item.departure_only_time_start);
    } else {
      item.arrival_only_time_start = time;
      const temp_arrival_start = item.year + '-' + item.month + '-' + item.day + ' ' + convertTimeUTCToCalendarLocal(time, this.state.zone_id);
      if (isDate(temp_arrival_start)) {
        item.arrival_time_start = convertDateUTCAndGeofence(new Date(temp_arrival_start), this.state.zone_id, false);
      }
      is_arrival_greater = this.isEndDateGreaterThanStartDate(item.arrival_only_time_end, item.arrival_only_time_start);
    }
    const is_overlap = isOverlapDate(
      item.departure_only_time_start,
      this.addMoreMinutes(item.departure_only_time_end, item.interval_departure_time),
      this.subMoreMinutes(item.arrival_only_time_start, item.interval_arrival_time),
      item.arrival_only_time_end,
    );
    const is_departure_overlap = !is_overlap;
    const is_arrival_overlap = !is_overlap;
    item.is_departure_validate = is_departure_overlap && is_departure_greater;
    item.is_arrival_validate = is_arrival_overlap && is_arrival_greater;
    just_in_time_calendars[indexRaw] = item;
    this.setState({just_in_time_calendars});
  };

  /**
   * onChangeEachTimeInterval
   * @param {*} indexRaw
   * @param {*} type
   * @param {*} value
   */
  onChangeEachTimeInterval(indexRaw, type, value) {
    const just_in_time_calendars = [...this.state.just_in_time_calendars];
    const item = {...just_in_time_calendars[indexRaw]};
    if (type === 'departure') {
      item.interval_departure_time = value;
      item.departure_break_time = [];
    } else {
      item.interval_arrival_time = value;
      item.arrival_break_time = [];
    }
    just_in_time_calendars[indexRaw] = item;
    this.setState({just_in_time_calendars: just_in_time_calendars});
  }

  /**
   * isEndDateGreaterThanStartDate
   * @param {*} end_date
   * @param {*} start_date
   * @return {Boolean}
   */
  isEndDateGreaterThanStartDate(end_date, start_date) {
    if (!start_date || !end_date) {
      return true;
    }
    const end_date_time = end_date.getHours() + ':' + end_date.getMinutes();
    const start_date_time = start_date.getHours() + ':' + start_date.getMinutes();
    if (toSeconds(this.state.vehicle_start) > toSeconds(start_date_time) || toSeconds(start_date_time) > toSeconds(this.state.vehicle_end)) {
      alert(this.props.t('jit.timeMustBe', {time_start: convertHMSToHM(this.state.vehicle_start), time_end: convertHMSToHM(this.state.vehicle_end)}));
      return false;
    }
    if (toSeconds(this.state.vehicle_start) > toSeconds(end_date_time) || toSeconds(end_date_time) > toSeconds(this.state.vehicle_end)) {
      alert(this.props.t('jit.timeMustBe', {time_start: convertHMSToHM(this.state.vehicle_start), time_end: convertHMSToHM(this.state.vehicle_end)}));
      return false;
    }
    return end_date.getTime() >= start_date.getTime();
  }

  /**
   * setEachBreakTime
   * @param {*} indexRaw
   * @param {*} type
   * @param {*} value
   */
  setEachBreakTime(indexRaw, type, value) {
    const just_in_time_calendars = [...this.state.just_in_time_calendars];
    const item = {...just_in_time_calendars[indexRaw]};
    if (type === 'departure') {
      item.departure_break_time = value;
    } else {
      item.arrival_break_time = value;
    }
    item.break_periods = [];
    item.departure_break_time.forEach((breakData, idx) => {
      const departure_break = breakData.split('-');
      item.break_periods.push({
        break_time_start: this.addDaysWithLocalTime(item.year + '-' + item.month + '-' + item.day + ' ' + departure_break[0].trim() + ':00', 0),
        break_time_end: this.addDaysWithLocalTime(item.year + '-' + item.month + '-' + item.day + ' ' + departure_break[1].trim() + ':00', 0),
        group_type: 'SHUTTLE_BUS_JIT_WORK_TO_HOME',
      });
    });
    item.arrival_break_time.forEach((breakData, idx) => {
      const arrival_break = breakData.split('-');
      item.break_periods.push({
        break_time_start: this.addDaysWithLocalTime(item.year + '-' + item.month + '-' + item.day + ' ' + arrival_break[0].trim() + ':00', 0),
        break_time_end: this.addDaysWithLocalTime(item.year + '-' + item.month + '-' + item.day + ' ' + arrival_break[1].trim() + ':00', 0),
        group_type: 'SHUTTLE_BUS_JIT_HOME_TO_WORK',
      });
    });
    just_in_time_calendars[indexRaw] = item;
    this.setState({just_in_time_calendars: just_in_time_calendars});

    // make break priod to send to server
  }

  /**
   * changeTimeDepartureFrom
   * @param {object} indexRaw
   * @param {object} type
   * @param {object} time
   */
  changeTimeCalendarTo = (indexRaw, type, time) => {
    const just_in_time_calendars = [...this.state.just_in_time_calendars];
    const item = {...just_in_time_calendars[indexRaw]};
    let is_departure_greater = true;
    let is_arrival_greater = true;
    if (type === 'departure') {
      item.departure_only_time_end = time;
      const temp_departure_end = item.year + '-' + item.month + '-' + item.day + ' ' + convertTimeUTCToCalendarLocal(time, this.state.zone_id);
      if (isDate(temp_departure_end)) {
        item.departure_time_end = convertDateUTCAndGeofence(new Date(temp_departure_end), this.state.zone_id, false);
      }
      is_departure_greater = this.isEndDateGreaterThanStartDate(item.departure_only_time_end, item.departure_only_time_start);
    } else {
      item.arrival_only_time_end = time;
      const temp_arrival_end = item.year + '-' + item.month + '-' + item.day + ' ' + convertTimeUTCToCalendarLocal(time, this.state.zone_id);
      if (isDate(temp_arrival_end)) {
        item.arrival_time_end = convertDateUTCAndGeofence(new Date(temp_arrival_end), this.state.zone_id, false);
      }
      is_arrival_greater = this.isEndDateGreaterThanStartDate(item.arrival_only_time_end, item.arrival_only_time_start);
    }
    const is_overlap = isOverlapDate(
      item.departure_only_time_start,
      this.addMoreMinutes(item.departure_only_time_end, item.interval_departure_time),
      this.subMoreMinutes(item.arrival_only_time_start, item.interval_arrival_time),
      item.arrival_only_time_end,
    );
    const is_departure_overlap = !is_overlap;
    const is_arrival_overlap = !is_overlap;
    item.is_departure_validate = is_departure_overlap && is_departure_greater;
    item.is_arrival_validate = is_arrival_overlap && is_arrival_greater;
    just_in_time_calendars[indexRaw] = item;
    this.setState({just_in_time_calendars});
  };

  /**
   * handleAddClick
   * @param {Object} type
   */
  handleAddClick = (type) => {
    const {departureBreakTime, arrivalBreakTime} = this.state;
    if (type === 'departure') {
      departureBreakTime.push('00:00-00:00');
    } else {
      arrivalBreakTime.push('00:00-00:00');
    }
    this.setState({
      departureBreakTime,
      arrivalBreakTime,
    });
  };

  /**
   *  handleRemoveClick
   * @param {Object} type
   */
  handleRemoveClick = (type) => {
    const {departureBreakTime, arrivalBreakTime} = this.state;
    if (type === 'departure') {
      departureBreakTime.pop();
    } else {
      arrivalBreakTime.pop();
    }
    this.setState({
      departureBreakTime,
      arrivalBreakTime,
    });
  };

  /**
   * changeBreakTimeFrom
   * @param {String} type
   * @param {String} index
   * @param {String} event
   */
  changeBreakTimeFrom(type, index, event) {
    const {departureBreakTime, arrivalBreakTime} = this.state;
    if (type === 'departure') {
      const currentEnd = departureBreakTime[index].split('-')[1];
      departureBreakTime[index] = event.target.value + '-' + currentEnd;
      this.setState({departureBreakTime});
    } else {
      const currentEnd = arrivalBreakTime[index].split('-')[1];
      arrivalBreakTime[index] = event.target.value + '-' + currentEnd;
      this.setState({arrivalBreakTime});
    }
  }
  /**
   * changeBreakTimeTo
   * @param {String} type
   * @param {String} index
   * @param {String} event
   */
  changeBreakTimeTo(type, index, event) {
    const {departureBreakTime, arrivalBreakTime} = this.state;
    if (type === 'departure') {
      const currentStart = departureBreakTime[index].split('-')[0];
      departureBreakTime[index] = currentStart + '-' + event.target.value;
      this.setState({departureBreakTime});
    } else {
      const currentStart = arrivalBreakTime[index].split('-')[0];
      arrivalBreakTime[index] = currentStart + '-' + event.target.value;
      this.setState({arrivalBreakTime});
    }
  }
  /**
   * changeStopTimeTo
   * @param {String} type
   * @param {String} index
   * @param {String} event
   */
  changeStopTimeTo(type, index, event) {
    const {departureStopTimes, arrivalStopTimes} = this.state;
    if (type === 'departure') {
      const currentStart = departureStopTimes[index].split('-')[0];
      departureStopTimes[index] = currentStart + '-' + event.target.value;
      this.setState({departureStopTimes});
    } else {
      const currentStart = arrivalStopTimes[index].split('-')[0];
      arrivalStopTimes[index] = currentStart + '-' + event.target.value;
      this.setState({arrivalStopTimes});
    }
  }

  /**
   * getJITSettingByDMY
   * @param {Number} day
   * @param {Number} month
   * @param {Number} year
   * @return {*}
   */
  getJITSettingByDMY = (day, month, year) => {
    return this.state.just_in_time_calendars.find((item) => item.day === day && item.month === month && item.year === year);
  };

  /**
   * getIndexOfJITRaw
   * @param {Number} day
   * @param {Number} month
   * @param {Number} year
   * @return {*}
   */
  getIndexOfJITRaw = (day, month, year) => {
    return this.state.just_in_time_calendars.findIndex((item) => {
      return item.day === day && item.month === month && item.year === year;
    });
  };

  /**
   * handleDateChange
   * @param {*} day
   */
  handleDateChange(day) {
    const {just_in_time_calendars} = this.state;
    const index = this.getIndexOfJITRaw(day, this.state.month, this.state.year);
    if (index <= 0) {
      const item = {
        departure_only_time_start: null,
        departure_time_start: '',
        departure_only_time_end: null,
        departure_time_end: '',
        is_departure_validate: true,
        arrival_only_time_start: null,
        arrival_time_start: '',
        arrival_only_time_end: null,
        arrival_time_end: '',
        is_arrival_validate: true,
        interval_departure_time: '',
        interval_arrival_time: '',
        is_active: true,
        day: day,
        month: this.state.month,
        year: this.state.year,
        break_periods: [],
        departure_break_time: [],
        arrival_break_time: [],
      };
      just_in_time_calendars.push(item);
      this.setState({
        just_in_time_calendars,
      });
    }
  }

  /**
   * processDuration
   * @param {Number} durationSecs
   * @return {String}
   */
  processDuration = (durationSecs) => {
    let result = '';
    const durationMins = Math.round(durationSecs / 60);
    if (durationMins < 60) result = `${durationMins} ${this.props.t('common.minute')}`;
    else {
      if (durationMins % 60 === 0) result = `${durationMins / 60} ${this.props.t('common.hour')}`;
      else {
        const durationHours = Math.ceil(durationMins / 60) - 1;
        result = `${durationHours} ${this.props.t('common.hour')} ${durationMins - durationHours * 60} ${this.props.t('common.minute')}`;
      }
    }
    return result;
  };

  /**
   * getListAllGeofence
   * @param {*} listGeofences
   * @return {Array}
   */
  getListAllGeofence = (listGeofences) => {
    const geofence_config_infos = [{geofence_id: this.state.vehicleInfos?.geofence_id, name: this.state.vehicleInfos?.geofence_name}];
    return getListAllGeofenceDetail(listGeofences, geofence_config_infos);
  }

  /**
   * render component
   * @return {component}
   */
  render() {
    this.validator.purgeFields();
    const {t, common, businessVehicle, jitSetting, i18n} = this.props;
    const {firstDay, just_in_time_calendars, totalDay, month, year} = this.state;
    const language = i18n.language;
    const row1 = [];
    const row = [];
    const now = new Date();
    const dateNow = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate();
    // if (just_in_time_calendars.length > 0) {
    // total number of days a week 1
    const totalRow1 = 7 - firstDay;
    // total week
    const totalRow = (totalDay - totalRow1) / 7;
    row1.push(
      <TableCell style={StyledTableFirstRow} key={''}>
        <br></br>
        {this.state.mobiType?.includes('SHUTTLE_BUS_JIT_WORK_TO_HOME') && (
          <div>
            <TextField fullWidth value={t('jit.tableDeparture')} />
            <TextField fullWidth value={t('jit.tableInterval')} />
            <TextField fullWidth value={t('jit.wthBreakTime')} />
            {Array.from(Array(4)).map((value, index) => (
              <Input disabled fullWidth key={index} />
            ))}
            <Autocomplete
              multiple
              className="field_min_size_150"
              options={[]}
              disabled={true}
              renderInput={(params) => <TextField {...params} variant="standard" margin="normal" multiline={true} />}
            />
          </div>
        )}
        {this.state.mobiType?.includes('SHUTTLE_BUS_JIT_HOME_TO_WORK') && (
          <div>
            <TextField fullWidth value={t('jit.tableArrival')} />
            <TextField fullWidth value={t('jit.tableInterval')} />
            <TextField fullWidth value={t('jit.htwBreakTime')} />
            {Array.from(Array(4)).map((value, index) => (
              <Input disabled fullWidth key={index} />
            ))}
            <Autocomplete
              multiple
              className="field_min_size_150"
              options={[]}
              disabled={true}
              renderInput={(params) => <TextField {...params} variant="standard" margin="normal" multiline={true} />}
            />
          </div>
        )}
        <TextField fullWidth value={t('jit.tableImplement')} />
      </TableCell>,
    );
    // last month => empty
    for (let i = 0; i < this.state.firstDay; i++) {
      row1.push(<TableCell align="center" style={StyledTableRow} key={i}></TableCell>);
    }
    let i = 0;
    // current month, first week
    for (let j = firstDay; j < 7; j++) {
      // const data = calendar[j - firstDay] || {};
      const indexRaw = this.getIndexOfJITRaw(i + 1, month, year);
      const temp = just_in_time_calendars[indexRaw];
      if (temp !== undefined) {
        const currentDate = year + '-' + month + '-' + i;
        const isDisablePast = new Date(dateNow) > new Date(currentDate);
        row1.push(
          <TableCell align="center" style={StyledTableRow} key={j} className="width_10">
            <strong>{i + 1}</strong>
            <br></br>
            {this.state.mobiType?.includes('SHUTTLE_BUS_JIT_WORK_TO_HOME') && (
              <div className={temp.is_departure_validate ? 'valid' : 'invalid'}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TimePicker
                    disabled={isDisablePast}
                    className={'field_size_20 field_min_size_50'}
                    value={temp.departure_only_time_start}
                    onChange={(time) => this.changeTimeCalendarFrom(indexRaw, 'departure', time)}
                    ampm={false}
                  />
                </MuiPickersUtilsProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <TimePicker
                    disabled={isDisablePast}
                    className={'field_size_20 field_min_size_50 space_left_S'}
                    value={temp.departure_only_time_end}
                    onChange={(time) => this.changeTimeCalendarTo(indexRaw, 'departure', time)}
                    ampm={false}
                  />
                </MuiPickersUtilsProvider>
                <br></br>
                <Select
                  disabled={isDisablePast}
                  margin="dense"
                  value={temp.interval_departure_time}
                  onChange={(event) => this.onChangeEachTimeInterval(indexRaw, 'departure', event.target.value)}
                >
                  {this.listIntervalTime().map((row, index) => {
                    return (
                      <MenuItem value={row} key={index}>
                        {row}
                      </MenuItem>
                    );
                  })}
                </Select>
                {Array.from(Array(5 - temp.departure_break_time.length)).map(() => (
                  <Input disabled fullWidth />
                ))}
                <AutocompleteCommon
                  disabled={isDisablePast}
                  type="DEPARTURE"
                  listIntervalBreak={(departureBreakTime) => this.setEachBreakTime(indexRaw, 'departure', departureBreakTime)}
                  breakValue={temp.departure_break_time}
                  from={convertTimeUTCToCalendarLocal(temp.departure_only_time_start, this.state.zone_id)}
                  to={convertTimeUTCToCalendarLocal(temp.departure_only_time_end, this.state.zone_id)}
                  interval={temp.interval_departure_time}
                ></AutocompleteCommon>
              </div>
            )}
            {this.state.mobiType?.includes('SHUTTLE_BUS_JIT_HOME_TO_WORK') && (
              <div className={temp.is_arrival_validate ? 'valid' : 'invalid'}>
                <>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker
                      disabled={isDisablePast}
                      className={'field_size_20 field_min_size_50'}
                      value={temp.arrival_only_time_start}
                      onChange={(time) => this.changeTimeCalendarFrom(indexRaw, 'arrival', time)}
                      ampm={false}
                    />
                  </MuiPickersUtilsProvider>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <TimePicker
                      disabled={isDisablePast}
                      className={'field_size_20 field_min_size_50 space_left_S'}
                      value={temp.arrival_only_time_end}
                      onChange={(time) => this.changeTimeCalendarTo(indexRaw, 'arrival', time)}
                      ampm={false}
                    />
                  </MuiPickersUtilsProvider>
                </>
                <br></br>
                <Select
                  disabled={isDisablePast}
                  margin="dense"
                  value={temp.interval_arrival_time}
                  onChange={(event) => this.onChangeEachTimeInterval(indexRaw, 'arrival', event.target.value)}
                >
                  {this.listIntervalTime().map((row, index) => {
                    return (
                      <MenuItem value={row} key={index}>
                        {row}
                      </MenuItem>
                    );
                  })}
                </Select>
                <br />
                {Array.from(Array(5 - temp.arrival_break_time.length)).map(() => (
                  <Input disabled fullWidth />
                ))}
                <AutocompleteCommon
                  disabled={isDisablePast}
                  type="ARRIVAL"
                  listIntervalBreak={(arrivalBreakTime) => this.setEachBreakTime(indexRaw, 'arrival', arrivalBreakTime)}
                  breakValue={temp.arrival_break_time}
                  from={convertTimeUTCToCalendarLocal(temp.arrival_only_time_start, this.state.zone_id)}
                  to={convertTimeUTCToCalendarLocal(temp.arrival_only_time_end, this.state.zone_id)}
                  interval={temp.interval_arrival_time}
                ></AutocompleteCommon>
              </div>
            )}
            <FormControlLabel
              disabled={isDisablePast}
              labelPlacement="end"
              control={<Checkbox checked={temp.is_active} color="primary" />}
              onChange={() => {
                this.onChangeCheckbox(indexRaw);
              }}
              label={temp.is_active + ''}
            />
          </TableCell>,
        );
      } else {
        row1.push(
          <TableCell align="center" style={StyledTableRow} key={j} className="width_10">
            <strong>{i + 1}</strong>
            <br></br>
          </TableCell>,
        );
      }
      i++;
    }
    // from 2th week
    for (let j = 0; j < totalRow; j++) {
      row[j] = [];
      row[j].push(
        <TableCell style={StyledTableFirstRow}>
          <br></br>
          {this.state.mobiType?.includes('SHUTTLE_BUS_JIT_WORK_TO_HOME') && (
            <div>
              <TextField fullWidth value={t('jit.tableDeparture')} />
              <TextField fullWidth value={t('jit.tableInterval')} />
              <TextField fullWidth value={t('jit.wthBreakTime')} />
              {Array.from(Array(4)).map((value, index) => (
                <Input disabled fullWidth key={index} />
              ))}
              <Autocomplete
                multiple
                className="field_min_size_150"
                options={[]}
                disabled={true}
                renderInput={(params) => <TextField {...params} variant="standard" margin="normal" multiline={true} />}
              />
            </div>
          )}
          {this.state.mobiType?.includes('SHUTTLE_BUS_JIT_HOME_TO_WORK') && (
            <div>
              <TextField fullWidth value={t('jit.tableArrival')} />
              <TextField fullWidth value={t('jit.tableInterval')} />
              <TextField fullWidth value={t('jit.htwBreakTime')} />
              {Array.from(Array(4)).map((value, index) => (
                <Input disabled fullWidth key={index} />
              ))}
              <Autocomplete
                multiple
                className="field_min_size_150"
                options={[]}
                disabled={true}
                renderInput={(params) => <TextField {...params} variant="standard" margin="normal" multiline={true} />}
              />
            </div>
          )}
          <TextField fullWidth value={t('jit.tableImplement')} />
        </TableCell>,
      );
      for (let d = 0; d < 7; d++) {
        if (i >= totalDay) {
          row[j].push(<TableCell align="center" style={StyledTableRow} key={i} className="width_10"></TableCell>);
        } else {
          // const dayStartRow = totalRow1 + 7 * j + d;
          const indexRaw = this.getIndexOfJITRaw(i + 1, month, year);
          const temp = just_in_time_calendars[indexRaw];
          if (temp !== undefined) {
            const currentDate = year + '-' + month + '-' + (i + 1);
            const isDisablePast = new Date(dateNow) > new Date(currentDate);
            row[j].push(
              <TableCell align="center" style={StyledTableRow} key={i} className="width_10">
                <strong>{i + 1}</strong>
                <br></br>
                {this.state.mobiType?.includes('SHUTTLE_BUS_JIT_WORK_TO_HOME') && (
                  <div className={temp.is_departure_validate ? 'valid' : 'invalid'}>
                    <>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <TimePicker
                          disabled={isDisablePast}
                          value={temp.departure_only_time_start}
                          className={'field_size_20 field_min_size_50'}
                          onChange={(datetime) => this.changeTimeCalendarFrom(indexRaw, 'departure', datetime)}
                          ampm={false}
                        />
                      </MuiPickersUtilsProvider>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <TimePicker
                          disabled={isDisablePast}
                          value={temp.departure_only_time_end}
                          className={'field_size_20 field_min_size_50 space_left_S'}
                          onChange={(datetime) => this.changeTimeCalendarTo(indexRaw, 'departure', datetime)}
                          ampm={false}
                        />
                      </MuiPickersUtilsProvider>
                    </>
                    <br></br>
                    <Select
                      disabled={isDisablePast}
                      margin="dense"
                      value={temp.interval_departure_time}
                      onChange={(event) => this.onChangeEachTimeInterval(indexRaw, 'departure', event.target.value)}
                    >
                      {this.listIntervalTime().map((row, index) => {
                        return (
                          <MenuItem value={row} key={index}>
                            {row}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {Array.from(Array(5 - temp.departure_break_time.length)).map((value, index) => (
                      <Input disabled fullWidth key={index} />
                    ))}
                    <AutocompleteCommon
                      disabled={isDisablePast}
                      type="DEPARTURE"
                      listIntervalBreak={(departureBreakTime) => this.setEachBreakTime(indexRaw, 'departure', departureBreakTime)}
                      breakValue={temp.departure_break_time}
                      from={convertTimeUTCToCalendarLocal(temp.departure_only_time_start, this.state.zone_id)}
                      to={convertTimeUTCToCalendarLocal(temp.departure_only_time_end, this.state.zone_id)}
                      interval={temp.interval_departure_time}
                    ></AutocompleteCommon>
                  </div>
                )}
                {this.state.mobiType?.includes('SHUTTLE_BUS_JIT_HOME_TO_WORK') && (
                  <div className={temp.is_arrival_validate ? 'valid' : 'invalid'}>
                    <>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <TimePicker
                          disabled={isDisablePast}
                          value={temp.arrival_only_time_start}
                          className={'field_size_20 field_min_size_50'}
                          onChange={(datetime) => this.changeTimeCalendarFrom(indexRaw, 'arrival', datetime)}
                          ampm={false}
                        />
                      </MuiPickersUtilsProvider>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <TimePicker
                          disabled={isDisablePast}
                          value={temp.arrival_only_time_end}
                          className={'field_size_20 field_min_size_50 space_left_S'}
                          onChange={(datetime) => this.changeTimeCalendarTo(indexRaw, 'arrival', datetime)}
                          ampm={false}
                        />
                      </MuiPickersUtilsProvider>
                    </>
                    <br></br>
                    <Select
                      disabled={isDisablePast}
                      margin="dense"
                      value={temp.interval_arrival_time}
                      onChange={(event) => this.onChangeEachTimeInterval(indexRaw, 'arrival', event.target.value)}
                    >
                      {this.listIntervalTime().map((row, index) => {
                        return (
                          <MenuItem value={row} key={index}>
                            {row}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {Array.from(Array(5 - temp.arrival_break_time.length)).map((value, index) => (
                      <Input disabled fullWidth key={index} />
                    ))}
                    <AutocompleteCommon
                      disabled={isDisablePast}
                      type="ARRIVAL"
                      listIntervalBreak={(arrivalBreakTime) => this.setEachBreakTime(indexRaw, 'arrival', arrivalBreakTime)}
                      breakValue={temp.arrival_break_time}
                      from={convertTimeUTCToCalendarLocal(temp.arrival_only_time_start, this.state.zone_id)}
                      to={convertTimeUTCToCalendarLocal(temp.arrival_only_time_end, this.state.zone_id)}
                      interval={temp.interval_arrival_time}
                    ></AutocompleteCommon>
                  </div>
                )}
                <FormControlLabel
                  disabled={isDisablePast}
                  labelPlacement="end"
                  control={<Checkbox checked={temp.is_active} color="primary" />}
                  onChange={() => {
                    this.onChangeCheckbox(indexRaw);
                  }}
                  label={temp.is_active + ''}
                />
              </TableCell>,
            );
          } else {
            row[j].push(
              <TableCell align="center" style={StyledTableRow} key={j} className="width_10">
                <strong>{i + 1}</strong>
              </TableCell>,
            );
          }
          i++;
        }
      }
    }

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('jit.setting')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />} onClick={this.props.history.goBack}>
                {t('common.btnReturn')}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <br></br>
        <LoadingOverlay bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner active={common.isLoading || businessVehicle.isLoading || jitSetting.isLoading}>
          <Container maxWidth="xl">
            <Card raised>
              <br></br>
              <Container maxWidth="xl">
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('jit.collect_setting')}
                    </Grid>
                  </Grid>
                  {/* None editable */}
                  <Grid container spacing={1} className="row_form_item grid_title_padding">
                    {/* Business name */}
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <TextField
                        label={t('businessVehicle.businessName')}
                        className="field_size_20 field_min_size_250"
                        disabled
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={this.state.businessName}
                      />
                    </Grid>
                    {/* Vehicle ID */}
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <TextField
                        label={t('businessVehicle.code_id')}
                        className="field_size_20 field_min_size_250"
                        disabled
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={this.state.vehicleIdShow}
                      />
                    </Grid>
                    {/* Brand / Vehicle name */}
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <TextField
                        label={t('jit.brandOrVehicleName')}
                        className="field_size_20 field_min_size_250"
                        disabled
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={this.state.vehicleName}
                      />
                    </Grid>
                    {/* Sale store name */}
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <TextField
                        label={t('jit.saleOfficeName')}
                        className="field_size_20 field_min_size_250"
                        disabled
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={this.state.saleOfficeName}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="row_form_item grid_title_padding">
                    {/* Vehicle NO */}
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <TextField
                        label={t('jit.vehicleNo')}
                        className="field_size_20 field_min_size_250"
                        disabled
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300,
                        }}
                        value={this.state.vehicleNo}
                      />
                    </Grid>
                    {/* Vehicle Color */}
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <TextField
                        label={t('jit.vehicleColor')}
                        disabled
                        className="field_size_20 field_min_size_250"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={this.state.vehicleColor}
                      />
                    </Grid>
                    {/* Mobi type */}
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <TextField
                        multiline
                        label={t('jit.mobiType')}
                        value={MOBI_TYPES.map((type) => (this.state.mobiType.includes(type.id) ? t(`${type.i18n}`) : '')).filter(String)}
                        disabled
                        className="field_size_20 field_min_size_500"
                        rows={2}
                        maxRows={4}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="row_form_item">
                    {/* JIT setting area */}
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('jit.serviceArea')}
                    </Grid>
                    <FormControl variant="outlined" margin="dense" disabled className="field_size_10 field_min_size_300">
                      <Select
                        margin="dense"
                        inputProps={{
                          name: 'geofenceId',
                        }}
                        displayEmpty
                        renderValue={
                          this.state.geofenceId ?
                            undefined :
                            () => (
                                <div className="font-12 color-disabled">
                                  {t('placeholder.required_select', {
                                    field: t('jit.serviceArea'),
                                  })}
                                </div>
                              )
                        }
                        value={this.state.geofenceId}
                        onChange={(event) => this.changeGeofence(event.target.value)}
                      >
                        {this.getListAllGeofence(this.props.common.geofence_list)?.map((row, index) => (
                          <MenuItem value={row.geofence_id} key={index}>
                            {row.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {/* Virtual bus stop name of the starting point */}
                    {(this.state.geofenceId !== 'UMEDA_ID' && this.state.mobiType.includes('SHUTTLE_BUS_JIT_WORK_TO_HOME')) && (
                      <>
                        <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                          {t('jit.virtualBusStopWth')}
                        </Grid>
                        <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_500">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'virtualBusStopIdWTH',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.virtualBusStopIdWTH ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('jit.virtualBusStopWth'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.virtualBusStopIdWTH}
                            onChange={(event) => this.onChangeBusStop(event.target.value, 'WTH')}
                          >
                            {this.state.lstBusStopsWTH.map((row, index) => {
                              return (
                                <MenuItem value={row.id} key={index}>
                                  {_.isEmpty(row.name_translations) ? row.display_name:
                                (language === 'ja' ? row.name_translations?.ja : language === 'en' ? row.name_translations?.en : row.name_translations?.vi)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {this.validator.message('virtualBusStopIdWTH', this.state.virtualBusStopIdWTH, 'required')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.virtualBusStopIdWTH, 'required') && (
                            <FormHelperText error>{t('validation.required', {field: t('jit.virtualBusStopWth')})}</FormHelperText>
                          )}
                        </FormControl>
                      </>
                    )}

                    {(this.state.geofenceId !== 'UMEDA_ID' && this.state.mobiType.includes('SHUTTLE_BUS_JIT_HOME_TO_WORK')) && (
                      <>
                        {this.state.mobiType.includes('SHUTTLE_BUS_JIT_WORK_TO_HOME') && (
                          <>
                            <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={2} className="field_min_size_300">
                            </Grid>
                          </>
                        )}
                       <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                          {t('jit.virtualBusStopHtw')}
                        </Grid>
                        <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_500">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'virtualBusStopIdHTW',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.virtualBusStopIdHTW ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('jit.virtualBusStopHtw'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.virtualBusStopIdHTW}
                            // disabled={this.state.isDisableVirtualBus}
                            onChange={(event) => this.onChangeBusStop(event.target.value, 'HTW')}
                          >
                            {this.state.lstBusStopsHTW.map((row, index) => {
                              return (
                                <MenuItem value={row.id} key={index}>
                                  {_.isEmpty(row.name_translations) ? row.display_name:
                                (language === 'ja' ? row.name_translations?.ja : language === 'en' ? row.name_translations?.en : row.name_translations?.vi)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {this.validator.message('virtualBusStopIdHTW', this.state.virtualBusStopIdHTW, 'required')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.virtualBusStopIdHTW, 'required') && (
                            <FormHelperText error>{t('validation.required', {field: t('jit.virtualBusStopHtw')})}</FormHelperText>
                          )}
                        </FormControl>
                      </>
                    )}
                    {/* UMEDA */}
                    {this.state.geofenceId === 'UMEDA_ID' && (
                      <>
                        <Grid container alignItems="center" item xs={2} lg={1} className="grid_title_padding">
                          {t('businessVehicle.mobiType.searchResult.SHUTTLE_BUS_JIT_HOME_TO_WORK')}
                        </Grid>
                        <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_500">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'virtualBusStopHtwId',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.virtualBusStopHtwId ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('businessVehicle.mobiType.searchResult.SHUTTLE_BUS_JIT_HOME_TO_WORK'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.virtualBusStopHtwId}
                            disabled={this.state.isDisableVirtualBusHTW}
                            onChange={(event) => this.onChangeBusStopHTW(event.target.value)}
                          >
                            {this.state.lstBusStops.map((row, index) => {
                              return (
                                <MenuItem value={row.id} key={index}>
                                  {row.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {this.validator.message('virtualBusStopHtwId', this.state.virtualBusStopHtwId, 'required')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.virtualBusStopHtwId, 'required') && (
                            <FormHelperText error>{t('validation.required', {field: t('jit.virtualBusStop')})}</FormHelperText>
                          )}
                        </FormControl>

                        <Grid container alignItems="center" item xs={2} lg={1} className="grid_title_padding">
                          {t('businessVehicle.mobiType.searchResult.SHUTTLE_BUS_JIT_WORK_TO_HOME')}
                        </Grid>
                        <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_500">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'virtualBusStopWthId',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.virtualBusStopWthId ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('businessVehicle.mobiType.searchResult.SHUTTLE_BUS_JIT_WORK_TO_HOME'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.virtualBusStopWthId}
                            disabled={this.state.isDisableVirtualBusWTH}
                            onChange={(event) => this.onChangeBusStopWTH(event.target.value)}
                          >
                            {this.state.lstBusStops.map((row, index) => {
                              return (
                                <MenuItem value={row.id} key={index}>
                                  {row.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {this.validator.message('virtualBusStopWthId', this.state.virtualBusStopWthId, 'required')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.virtualBusStopWthId, 'required') && (
                            <FormHelperText error>{t('validation.required', {field: t('jit.virtualBusStop')})}</FormHelperText>
                          )}
                        </FormControl>
                      </>
                    )}
                  </Grid>

                  {/* Route Duration */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('jit.route_duration')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={10}>
                      <Box display="flex" flexDirection="column" style={{width: '100%'}}>
                        {this.state.listRoute?.map((item, index) => (
                          <Grid container className="route_row_data" key={index}>
                            <Grid container alignItems="center" item xs={6} lg={5}>
                              <span style={{color: '#3f51b5', marginRight: 10}}>{index + 1}.</span>
                              {item.name}
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={7}>
                              {this.processDuration(item.duration)}
                            </Grid>
                          </Grid>
                        ))}
                      </Box>
                    </Grid>
                  </Grid>

                  {/* Specify Period (Apply time) */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('jit.specifyPeriod')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <Grid container alignItems="center" item xs={6} lg={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            autoOk={true}
                            format="yyyy/MM/dd"
                            label={t('jit.fromWhen')}
                            views={['year', 'month', 'date']}
                            disablePast
                            value={this.state.publishStart}
                            minDate={this.state.publishStart ? null : new Date(new Date().getDate() + 1)}
                            maxDate={this.state.publishEnd ? this.state.publishEnd : null}
                            onChange={(date) => {
                              this.setState({
                                publishStart: new Date(date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' 00:00:00'),
                              });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        {this.validator.message('publishStart', this.state.publishStart, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.publishStart, 'required') && (
                          <FormHelperText error>{t('validation.required', {field: t('jit.specifyPeriod')})}</FormHelperText>
                        )}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={6}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            autoOk={true}
                            format="yyyy/MM/dd"
                            label={t('jit.untilWhen')}
                            views={['year', 'month', 'date']}
                            minDate={this.state.publishStart ? this.state.publishStart : new Date(new Date().getTime() + 86400000)}
                            value={this.state.publishEnd}
                            onChange={(date) => {
                              this.setState({
                                publishEnd: new Date(date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' 23:59:59'),
                              });
                            }}
                          />
                        </MuiPickersUtilsProvider>
                        {this.validator.message('publishEnd', this.state.publishEnd, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.publishEnd, 'required') && (
                          <FormHelperText error>{t('validation.required', {field: t('jit.specifyPeriod')})}</FormHelperText>
                        )}
                      </Grid>
                      {!this.isValidateMaxRange(this.state.publishStart, this.state.publishEnd) && <FormHelperText style={{color: 'red'}}>{t('jit.only1month')}</FormHelperText>}
                    </Grid>
                  </Grid>

                  {/* Time setting */}
                  <Grid container spacing={1} className="row_form_item">
                    {this.state.mobiType?.includes('SHUTTLE_BUS_JIT_WORK_TO_HOME') && (
                      <Grid container spacing={1} className="row_form_item" item xs={12} lg={6}>
                        <Grid container alignItems="center" item xs={6} lg={4} className="grid_title_padding">
                          {t('jit.closeTimeDeparture')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={8}>
                          <RadioGroup onChange={(event) => this.setState({departureTimeType: event.target.value})} value={this.state.departureTimeType} name="closeTimeDeparture">
                            <div style={{display: 'inline'}}>
                              <FormControlLabel value="DAY" control={<Radio />} />
                              <FormControl className="max_input_adornment" error>
                                <div className="box-group-input">
                                  <TextField
                                    className="field_size_20 field_min_size_70"
                                    variant="outlined"
                                    name="departureDayDay"
                                    value={this.state.departureDayDay}
                                    onChange={(event) => onChangeTextField(this, event)}
                                    type="number"
                                    inputProps={{
                                      min: '0',
                                      step: '1',
                                    }}
                                  />
                                  <InputAdornment position="end">{t('jit.dayAgo')}</InputAdornment>
                                </div>
                                {this.state.departureTimeType === 'DAY' && this.validator.message('departureDayDay', this.state.departureDayDay, 'required')}
                                {this.state.isSubmitForm && this.state.departureTimeType === 'DAY' && !this.validator.check(this.state.departureDayDay, 'required') && (
                                  <FormHelperText error>{t('validation.required', {field: t('jit.dayAgo')})}</FormHelperText>
                                )}
                              </FormControl>
                              <FormControl className="max_input_adornment" error>
                                <div className="box-group-input">
                                  <TextField
                                    className="field_size_20 field_min_size_70"
                                    variant="outlined"
                                    name="departureDayHour"
                                    value={this.state.departureDayHour}
                                    onChange={(event) => onChangeTextField(this, event)}
                                    type="number"
                                    inputProps={{
                                      min: '0',
                                      max: '23',
                                      step: '1',
                                    }}
                                  />
                                  <InputAdornment position="end">{t('jit.untilTime')}</InputAdornment>
                                </div>
                                {this.state.departureTimeType === 'DAY' && this.validator.message('departureDayHour', this.state.departureDayHour, 'required')}
                                {this.state.isSubmitForm && this.state.departureTimeType === 'DAY' && !this.validator.check(this.state.departureDayHour, 'required') && (
                                  <FormHelperText error>{t('validation.required', {field: t('jit.untilTime')})}</FormHelperText>
                                )}
                              </FormControl>
                            </div>
                            <div style={{display: 'inline'}} disabled={this.state.arrivalTimeType === 'DAY' ? false : true}>
                              <FormControlLabel value="MINUTE" control={<Radio />} />
                              <FormControl className="max_input_adornment" error>
                                <div className="box-group-input">
                                  <TextField
                                    className="field_size_20 field_min_size_70"
                                    variant="outlined"
                                    name="departureHourHour"
                                    value={this.state.departureHourHour}
                                    onChange={(event) => onChangeTextField(this, event)}
                                    type="number"
                                    inputProps={{
                                      min: '0',
                                      step: '1',
                                      max: '23',
                                    }}
                                  />
                                  <InputAdornment position="end">{t('jit.hourAgo')}</InputAdornment>
                                </div>
                                {this.state.departureTimeType !== 'DAY' && this.validator.message('departureHourHour', this.state.departureHourHour, 'required')}
                                {this.state.isSubmitForm && this.state.departureTimeType !== 'DAY' && !this.validator.check(this.state.departureHourHour, 'required') && (
                                  <FormHelperText error>{t('validation.required', {field: t('jit.hourAgo')})}</FormHelperText>
                                )}
                              </FormControl>
                              <FormControl className="max_input_adornment" error>
                                <div className="box-group-input">
                                  <TextField
                                    className="field_size_20 field_min_size_70"
                                    variant="outlined"
                                    name="departureHourMinute"
                                    value={this.state.departureHourMinute}
                                    onChange={(event) => onChangeTextField(this, event)}
                                    type="number"
                                    inputProps={{
                                      min: '0',
                                      max: '59',
                                      step: '1',
                                    }}
                                  />
                                  <InputAdornment position="end">{t('jit.minuteAgo')}</InputAdornment>
                                </div>
                                {this.state.departureTimeType !== 'DAY' && Number(this.state.departureHourHour) === 0 ?
                                  this.validator.message('departureHourMinute', this.state.departureHourMinute, 'required|min:5,num') :
                                  this.validator.message('departureHourMinute', this.state.departureHourMinute, 'required')}
                                {this.state.isSubmitForm && this.state.departureTimeType !== 'DAY' && !this.validator.check(this.state.departureHourMinute, 'required') && (
                                  <FormHelperText error>{t('validation.required', {field: t('jit.minuteAgo')})}</FormHelperText>
                                )}
                                {this.state.isSubmitForm &&
                                  Number(this.state.departureHourHour) === 0 &&
                                  this.state.departureTimeType !== 'DAY' &&
                                  this.validator.check(this.state.departureHourMinute, 'required') &&
                                  !this.validator.check(this.state.departureHourMinute, 'min:5,num') && <FormHelperText error>{t('jit.time_minimum', {field: 5})}</FormHelperText>}
                              </FormControl>
                            </div>
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    )}
                    {this.state.mobiType?.includes('SHUTTLE_BUS_JIT_HOME_TO_WORK') && (
                      <Grid container spacing={1} className="row_form_item" item xs={12} lg={6}>
                        <Grid container alignItems="center" item xs={6} lg={4} className="grid_title_padding">
                          {t('jit.closeTimeArrival')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={8}>
                          <RadioGroup onChange={(event) => this.setState({arrivalTimeType: event.target.value})} value={this.state.arrivalTimeType} name="closeTimeArrival">
                            <div style={{display: 'inline'}}>
                              <FormControlLabel value="DAY" control={<Radio />} />
                              <FormControl className="max_input_adornment" error>
                                <div className="box-group-input">
                                  <TextField
                                    className="field_size_20 field_min_size_70"
                                    variant="outlined"
                                    name="arrivalDayDay"
                                    value={this.state.arrivalDayDay}
                                    onChange={(event) => onChangeTextField(this, event)}
                                    type="number"
                                    inputProps={{
                                      min: '0',
                                      step: '1',
                                    }}
                                  />
                                  <InputAdornment position="end">{t('jit.dayAgo')}</InputAdornment>
                                </div>
                                {this.state.arrivalTimeType === 'DAY' && this.validator.message('arrivalDayDay', this.state.arrivalDayDay, 'required')}
                                {this.state.isSubmitForm && this.state.arrivalTimeType === 'DAY' && !this.validator.check(this.state.arrivalDayDay, 'required') && (
                                  <FormHelperText error>{t('validation.required', {field: t('jit.dayAgo')})}</FormHelperText>
                                )}
                              </FormControl>
                              <FormControl className="max_input_adornment" error>
                                <div className="box-group-input">
                                  <TextField
                                    className="field_size_20 field_min_size_70"
                                    variant="outlined"
                                    name="arrivalDayHour"
                                    value={this.state.arrivalDayHour}
                                    onChange={(event) => onChangeTextField(this, event)}
                                    type="number"
                                    inputProps={{
                                      min: '0',
                                      step: '1',
                                    }}
                                  />
                                  <InputAdornment position="end">{t('jit.untilTime')}</InputAdornment>
                                </div>
                                {this.state.arrivalTimeType === 'DAY' && this.validator.message('arrivalDayHour', this.state.arrivalDayHour, 'required')}
                                {this.state.isSubmitForm && this.state.arrivalTimeType === 'DAY' && !this.validator.check(this.state.arrivalDayHour, 'required') && (
                                  <FormHelperText error>{t('validation.required', {field: t('jit.untilTime')})}</FormHelperText>
                                )}
                              </FormControl>
                            </div>
                            <div style={{display: 'inline'}}>
                              <FormControlLabel value="MINUTE" control={<Radio />} />
                              <FormControl className="max_input_adornment" error>
                                <div className="box-group-input">
                                  <TextField
                                    className="field_size_20 field_min_size_70"
                                    variant="outlined"
                                    name="arrivalHourHour"
                                    value={this.state.arrivalHourHour}
                                    onChange={(event) => onChangeTextField(this, event)}
                                    type="number"
                                    inputProps={{
                                      min: '0',
                                      step: '1',
                                    }}
                                  />
                                  <InputAdornment position="end">{t('jit.hourAgo')}</InputAdornment>
                                </div>
                                {this.state.arrivalTimeType !== 'DAY' && this.validator.message('arrivalHourHour', this.state.arrivalHourHour, 'required')}
                                {this.state.isSubmitForm && this.state.arrivalTimeType !== 'DAY' && !this.validator.check(this.state.arrivalHourHour, 'required') && (
                                  <FormHelperText error>{t('validation.required', {field: t('jit.hourAgo')})}</FormHelperText>
                                )}
                              </FormControl>
                              <FormControl className="max_input_adornment" error>
                                <div className="box-group-input">
                                  <TextField
                                    className="field_size_20 field_min_size_70"
                                    variant="outlined"
                                    name="arrivalHourMinute"
                                    value={this.state.arrivalHourMinute}
                                    onChange={(event) => onChangeTextField(this, event)}
                                    type="number"
                                    inputProps={{
                                      min: '0',
                                      step: '1',
                                    }}
                                  />
                                  <InputAdornment position="end">{t('jit.minuteAgo')}</InputAdornment>
                                </div>
                                {this.state.arrivalTimeType !== 'DAY' && this.validator.message('arrivalHourMinute', this.state.arrivalHourMinute, 'required')}
                                {this.state.isSubmitForm && this.state.arrivalTimeType !== 'DAY' && !this.validator.check(this.state.arrivalHourMinute, 'required') && (
                                  <FormHelperText error>{t('validation.required', {field: t('jit.minuteAgo')})}</FormHelperText>
                                )}
                              </FormControl>
                            </div>
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>

                  {/* Scheduled departure && Arrival on time */}
                  {this.state.mobiType?.includes('SHUTTLE_BUS_JIT_WORK_TO_HOME') && (
                    <div>
                      <Grid container spacing={2} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('jit.scheduledDeparture')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={1}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                              value={this.state.departureSetTimeFrom}
                              name="departureSetTimeFrom"
                              label={t('jit.firstTrain')}
                              onChange={(date) => this.setState({departureSetTimeFrom: date})}
                              ampm={false}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={1}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                              value={this.state.departureSetTimeTo}
                              name="departureSetTimeTo"
                              label={t('jit.lastTrain')}
                              onChange={(date) => this.setState({departureSetTimeTo: date})}
                              ampm={false}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={1}>
                          <InputLabel id="demo-simple-select-label">{t('jit.timeInterval')}</InputLabel>
                          <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_100">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'intervalDepartureTime',
                              }}
                              value={this.state.intervalDepartureTime}
                              onChange={(event) => this.onChangeTimeInterval(event.target.value, 'departure')}
                            >
                              {this.listIntervalTime().map((row, index) => {
                                return (
                                  <MenuItem value={row} key={index}>
                                    {row}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={1}>
                          <TextField
                            label={t('jit.bufferTime')}
                            className="field_size_10 field_min_size_100"
                            style={{margin: 10}}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="bufferDepartureTime"
                            value={this.state.bufferDepartureTime}
                            onChange={(event) => {
                              this.setState({
                                bufferDepartureTime: event.target.value,
                              });
                            }}
                            type="number"
                            inputProps={{
                              min: '0',
                              max: '999',
                              step: '1',
                            }}
                          />
                        </Grid>
                        <Grid container item xs={6} lg={2}>
                          {this.checkTimeRange('departure') && <FormHelperText style={{color: 'red'}}>{t('validation.invalid.timeRange')}</FormHelperText>}
                          {!this.isValidDepartureTime() && (
                            <FormHelperText style={{color: 'red'}}>
                              {t('jit.timeMustBe', {time_start: convertHMSToHM(this.state.vehicle_start), time_end: convertHMSToHM(this.state.vehicle_end)})}
                            </FormHelperText>
                          )}
                          {this.validator.message('bufferDepartureTime', this.state.bufferDepartureTime, 'integer|numeric|max:999,num|min:0,num')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.bufferDepartureTime, 'integer|numeric|min:0,num|max:999,num') && (
                            <p className="error">{t('validation.max', {value: 999})}</p>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {this.state.mobiType?.includes('SHUTTLE_BUS_JIT_HOME_TO_WORK') && (
                    <div>
                      <Grid container spacing={2} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('jit.arrivalOnTime')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={1}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                              value={this.state.arrivalSetTimeFrom}
                              name="arrivalSetTimeFrom"
                              label={t('jit.firstTrain')}
                              onChange={(date) => this.setState({arrivalSetTimeFrom: date})}
                              ampm={false}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={1}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <TimePicker
                              value={this.state.arrivalSetTimeTo}
                              minDate={this.state.arrivalSetTimeFrom}
                              name="arrivalSetTimeTo"
                              label={t('jit.lastTrain')}
                              onChange={(date) => this.setState({arrivalSetTimeTo: date})}
                              ampm={false}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={1}>
                          <InputLabel id="demo-simple-select-label">{t('jit.timeInterval')}</InputLabel>
                          <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_100">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'intervalArrivalTime',
                              }}
                              value={this.state.intervalArrivalTime}
                              onChange={(event) => this.onChangeTimeInterval(event.target.value, 'arrival')}
                            >
                              {this.listIntervalTime().map((row, index) => {
                                return (
                                  <MenuItem value={row} key={index}>
                                    {row}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={1}>
                          <TextField
                            label={t('jit.bufferTime')}
                            className="field_size_10 field_min_size_100"
                            style={{margin: 10}}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            name="bufferArrivalTime"
                            value={this.state.bufferArrivalTime}
                            onChange={(event) => {
                              this.setState({
                                bufferArrivalTime: event.target.value,
                              });
                            }}
                            type="number"
                            inputProps={{
                              step: 1,
                            }}
                          />
                        </Grid>
                        <Grid container item xs={6} lg={2}>
                          {this.checkTimeRange('arrival') && <FormHelperText style={{color: 'red'}}>{t('validation.invalid.timeRange')}</FormHelperText>}
                          {!this.isValidArrivalTime() && (
                            <FormHelperText style={{color: 'red'}}>
                              {t('jit.timeMustBe', {time_start: convertHMSToHM(this.state.vehicle_start), time_end: convertHMSToHM(this.state.vehicle_end)})}
                            </FormHelperText>
                          )}
                          {this.validator.message('bufferArrivalTime', this.state.bufferArrivalTime, 'integer|numeric|max:999,num|min:0,num')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.bufferArrivalTime, 'integer|numeric|min:0,num|max:999,num') && (
                            <p className="error">{t('validation.max', {value: 999})}</p>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  )}
                  {isOverlapDate(
                    this.state.departureSetTimeFrom,
                    this.addMoreMinutes(this.state.departureSetTimeTo, this.state.intervalDepartureTime),
                    this.subMoreMinutes(this.state.arrivalSetTimeFrom, this.state.intervalArrivalTime),
                    this.state.arrivalSetTimeTo,
                  ) && (
                    <Grid container spacing={2} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        <FormHelperText style={{color: 'red'}}>{t('jit.overlap')}</FormHelperText>
                      </Grid>
                    </Grid>
                  )}

                  {/* Suspended flights(departure on time) */}
                  {this.state.mobiType?.includes('SHUTTLE_BUS_JIT_WORK_TO_HOME') && (
                    <div>
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('jit.wthBreakTime')}
                        </Grid>
                        <Grid container item xs={6} lg={6}>
                          <AutocompleteCommon
                            type="DEPARTURE"
                            listIntervalBreak={(departureBreakTime) =>
                              this.setState({
                                departureBreakTime: departureBreakTime,
                              })
                            }
                            breakValue={this.state.departureBreakTime}
                            from={convertTimeUTCToCalendarLocal(this.state.departureSetTimeFrom, this.state.zone_id)}
                            to={convertTimeUTCToCalendarLocal(this.state.departureSetTimeTo, this.state.zone_id)}
                            interval={this.state.intervalDepartureTime}
                          ></AutocompleteCommon>
                        </Grid>
                      </Grid>
                    </div>
                  )}

                  {/* Suspended flights(arrival on time) */}
                  {this.state.mobiType?.includes('SHUTTLE_BUS_JIT_HOME_TO_WORK') && (
                    <div>
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('jit.htwBreakTime')}
                        </Grid>
                        <Grid container item xs={6} lg={6}>
                          <AutocompleteCommon
                            type="ARRIVAL"
                            listIntervalBreak={(arrivalBreakTime) =>
                              this.setState({
                                arrivalBreakTime: arrivalBreakTime,
                              })
                            }
                            breakValue={this.state.arrivalBreakTime}
                            from={convertTimeUTCToCalendarLocal(this.state.arrivalSetTimeFrom, this.state.zone_id)}
                            to={convertTimeUTCToCalendarLocal(this.state.arrivalSetTimeTo, this.state.zone_id)}
                            interval={this.state.intervalArrivalTime}
                          ></AutocompleteCommon>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </Paper>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3} />
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    <Button color="secondary" variant="contained" className="button_margin" onClick={() => this.handleRegister()}>
                      {t('common.btnApply')}
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            </Card>
            <br></br>
          </Container>

          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                    {t('jit.calendar_detail_setting')}
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={3} align="right">
                    <FormControlLabel
                      style={{paddingLeft: '15px', marginTop: '10px'}}
                      labelPlacement="end"
                      control={<Checkbox checked={this.state.checkAll} color="primary" />}
                      onChange={() => {
                        this.onChangeCheckAll();
                      }}
                      label={t('calendar.checkAll')}
                    />
                  </Grid>
                  <Grid item xs={1} align="right">
                    <br></br>
                    <ArrowBackIosIcon className="cursor_pointer" onClick={() => this.handleChangeMonth('previous')} />
                  </Grid>
                  <Grid item xs={4} align="center">
                    <Grid container spacing={3} className="font_color_white">
                      <Grid item xs={6} align="right">
                        <TextField
                          className="field_size_10 field_min_size_150"
                          margin="dense"
                          value={this.state.year}
                          onChange={(event) => this.changeYear(event.target.value)}
                          label={t('calendar.years')}
                        />
                      </Grid>
                      <Grid item xs={6} align="left">
                        <Autocomplete
                          margin="dense"
                          className="field_size_10 field_min_size_150"
                          options={MONTH}
                          value={this.state.month}
                          getOptionLabel={(option) => t(`${MONTH_NAMES[option - 1]}`)}
                          onChange={(event, month) => this.changeMonth(month)}
                          renderInput={(params) => <TextField {...params} name={'cMonth'} fullWidth margin="dense" label={t('calendar.month')} />}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4} align="left">
                    <br></br>
                    <ArrowForwardIosIcon className="cursor_pointer" onClick={() => this.handleChangeMonth('next')} />
                  </Grid>
                </Grid>
                <LoadingOverlay active={false} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                  <br></br>
                  <div>
                    <Table style={styleTable} aria-label="sticky table" className="layoutfix">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" style={StyledTableRow}></TableCell>
                          <TableCell align="center" style={StyledTableRow}>
                            {t('calendar.Monday')}
                          </TableCell>
                          <TableCell align="center" style={StyledTableRow}>
                            {t('calendar.Tuesday')}
                          </TableCell>
                          <TableCell align="center" style={StyledTableRow}>
                            {t('calendar.Wednesday')}
                          </TableCell>
                          <TableCell align="center" style={StyledTableRow}>
                            {t('calendar.Thursday')}
                          </TableCell>
                          <TableCell align="center" style={StyledTableRow}>
                            {t('calendar.Friday')}
                          </TableCell>
                          <TableCell align="center" style={StyledTableRow}>
                            {t('calendar.Saturday')}
                          </TableCell>
                          <TableCell align="center" style={StyledTableRow}>
                            {t('calendar.Sunday')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>{row1}</TableRow>
                        {row.map((rows, index) => (
                          <TableRow key={index}>
                            {rows.map((r, id) => (
                              <>{rows[id]}</>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter></TableFooter>
                    </Table>
                  </div>
                </LoadingOverlay>
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-end" item xs={12}>
                    <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={() => this.handleRegisterSubmit()}>
                      {t('common.btnRegister')}
                    </Button>
                  </Grid>
                </Grid>
                <Dialog
                  open={this.state.flg}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal onClickOk={this.handleButtonUpdateOk.bind(this)} onClickCancel={this.handleButtonUpdateCancel.bind(this)} message={this.state.message}></SelectModal>
                </Dialog>
                <br></br>
              </Container>
            </Card>
            <br></br>
          </Container>
        </LoadingOverlay>
        <br></br>
      </Card>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    businessVehicle: state.businessVehicle,
    common: state.common,
    jitSetting: state.jitSetting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListGeofence: (payload) => dispatch(getListGeofence(payload)),
    createVehicleJit: (params) => dispatch(createVehicleJit(params)),
    getDetailVehicleJit: (params) => dispatch(getDetailVehicleJit(params)),
    getVehicleJitCalendar: (params) => dispatch(getVehicleJitCalendar(params)),
    getDetailVehicle: (params, props) => dispatch(getDetailVehicle(params, props)),
    setMessageModal: (params) => dispatch(setMessageModal(params)),
    searchJitRoute: (payload, queryParams) => dispatch(searchJitRoute(payload, queryParams)),
    getTransitStop: (serviceGroupIds, simulationIds, groupTypes) => dispatch(getTransitStop(serviceGroupIds, simulationIds, groupTypes)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
