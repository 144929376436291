import React, {Component} from 'react';

import {Container, Card, CardActionArea, CardMedia, CardContent, Typography, Grid, Button} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

import {ROUTE} from '../../../common/constant';
import {redirectRouter} from '../../../utils/common';

/**
 * Business Management Controller
 */
class Index extends Component {
  /**
   * Render component
   * @return {component}
   */
  render() {
    const {t} = this.props;

    return (
      <div>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('route.businessManagement')}</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}>
                <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                  <Button
                    color="primary"
                    variant="contained"
                    className="button_margin button_color"
                    endIcon={<ArrowBackIcon />}
                  >
                    {t('common.btnReturn')}
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <br></br>
            <Container maxWidth="xl">
              <Grid container spacing={1}>
                <Grid container alignItems="center" item xs={12} md={6} lg={3}>
                  <Card raised className="card_size">
                    <CardActionArea
                      onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.BUSINESS_INFORMATION)}
                    >
                      <CardMedia
                        style={{borderBottom: '1px solid #f1ecec'}}
                        className="card_media_size"
                        image={`${process.env.PUBLIC_URL}/images/business_information.png`}
                        title={t('route.businessManagement.information')}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h3">
                          {t('route.businessManagement.information')}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          {t('route.businessManagement.information')}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid container alignItems="center" item xs={12} md={6} lg={3}>
                  <Card raised className="card_size">
                    <CardActionArea
                      onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.FACILITY_INFORMATION)}
                    >
                      <CardMedia
                        style={{borderBottom: '1px solid #f1ecec'}}
                        className="card_media_size"
                        image={`${process.env.PUBLIC_URL}/images/sale_office_store.jpg`}
                        title={t('route.businessManagement.information')}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h3">
                          {t('route.facilityManagement')}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          {t('route.facilityManagement')}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid container alignItems="center" item xs={12} md={6} lg={3}>
                  <Card raised className="card_size">
                    <CardActionArea onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.DRIVER_INFORMATION)}>
                      <CardMedia
                        style={{borderBottom: '1px solid #f1ecec'}}
                        className="card_media_size"
                        image={`${process.env.PUBLIC_URL}/images/driver_register.jpeg`}
                        title={t('route.businessManagement.information')}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h3">
                          {t('route.driverManagement')}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          {t('route.driverManagement')}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid container alignItems="center" item xs={12} md={6} lg={3}>
                  <Card raised className="card_size">
                    <CardActionArea
                      onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.VEHICLE_INFORMATION)}
                    >
                      <CardMedia
                        style={{borderBottom: '1px solid #f1ecec'}}
                        className="card_media_size"
                        image={`${process.env.PUBLIC_URL}/images/vehicle_register.jpg`}
                        title={t('route.businessManagement.information')}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h3">
                          {t('route.vehicleManagement')}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          {t('route.vehicleManagement')}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              </Grid>
              <br></br>
              <Grid container spacing={1}>
                <Grid container alignItems="center" item xs={12} md={6} lg={3}>
                  <Card raised className="card_size">
                    <CardActionArea
                      onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.ASSOCIATION)}
                    >
                      <CardMedia
                        style={{borderBottom: '1px solid #f1ecec'}}
                        className="card_media_size"
                        image={`${process.env.PUBLIC_URL}/images/driver_ass.jpg`}
                        title={t('route.businessManagement.information')}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h3">
                          {t('route.driverVehicleAssociation')}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          {t('route.driverVehicleAssociation')}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid container alignItems="center" item xs={12} md={6} lg={3}>
                  <Card raised className="card_size">
                    <CardActionArea
                      onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.IMAGE)}
                    >
                      <CardMedia
                        style={{borderBottom: '1px solid #f1ecec'}}
                        className="card_media_size"
                        image={`${process.env.PUBLIC_URL}/images/image_master.jpg`}
                        title={t('route.businessManagement.information')}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h3">
                          {t('route.imageMaster')}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          {t('route.imageMaster')}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid container alignItems="center" item xs={12} md={6} lg={3}>
                  <Card raised className="card_size">
                    <CardActionArea
                      onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.PRODUCT_NOTICE)}
                    >
                      <CardMedia
                        style={{borderBottom: '1px solid #f1ecec'}}
                        className="card_media_size"
                        image={`${process.env.PUBLIC_URL}/images/information.jpg`}
                        title={t('route.businessManagement.information')}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h3">
                          {t('route.caution')}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          {t('route.caution')}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              </Grid>
              <br></br>
            </Container>
          </Container>
          <br></br>
        </Card>
      </div>
    );
  }
}

export default withTranslation('translations')(Index);
