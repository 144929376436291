import {api} from '../services/api';

const clearCacheAuthUrl = `/auth/refresh-app-config`;
const clearCacheMassUrl = `/admin/clear-cache/mass`;
const clearCacheFunctionUrl = `/admin/function/clear-cache`;

export const clearCacheAuthApi = () => {
  return api.get(clearCacheAuthUrl);
};

export const clearCacheMassApi = () => {
  return api.post(clearCacheMassUrl, {});
};

export const clearCacheFunctionApi = () => {
  return api.get(clearCacheFunctionUrl, {});
};
