import React, {Component} from 'react';

import {Backdrop, Button, Card, Container, Dialog, Fade, Grid, Modal, Paper, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {ArrowBack as ArrowBackIcon, NotInterested as DisableIcon} from '@material-ui/icons';
import {withStyles} from '@material-ui/styles';
import clsx from 'clsx';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';

import {BOARDING_STATUS, DATA_NULL, TICKET_TYPE} from '../../../common/constant';
import SelectModal from '../../../components/selectModal';
import {getAllCountryCode} from '../../../stores/common/actions';
import {disableQRTicket, getQRTicketDetail, getTicketHistoryListByUserId} from '../../../stores/qr_ticket/action';
import {customDisplayCurrency} from '../../../utils/common';
import {convertDateUTCAndGeofence, formatUTCToHourMinute, getTimeZoneByCountryCode} from '../../../utils/date_time_utils';
import {convertDateTimeToDate} from '../../../utils/datetime';

const styles = () => ({
  borderBottomNone: {
    borderBottom: 'none',
  },
  width30: {
    width: '30% !important',
  },
});

/** QR Ticket Detail Component */
class Index extends Component {
  /**
   * Constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      detailModalOpened: false,
      disableModalOpened: false,
    };
  }

  /**
   * getCountryCodeById
   * @param {*} countryId
   * @return {number}
   */
  getCountryCodeById = (countryId) => {
    const {common} = this.props;
    const country = common?.country_code?.find((country) => country.id === countryId);
    return country.country_code;
  };

  /**
   * getStationNameById
   * @param {number} stationId
   * @param {object} stations
   * @return {string}
   */
  getStationNameById = (stationId, stations) => {
    const station = stations.find((station) => station.id === stationId);
    return station.name;
  };

  /**
   * handleOpenModel
   */
  handleOpenDetailModal = () => {
    this.setState({detailModalOpened: true});
    this.props.getTicketHistoryListByUserId(this.props.match.params.id);
  };

  /**
   * handleCloseModal
   */
  handleCloseDetailModal = () => {
    this.setState({detailModalOpened: false});
  };

  /**
   * handleOpenDisableModal
   */
  handleOpenDisableModal = () => {
    this.setState({disableModalOpened: true});
  };

  /**
   * handleCloseDisableModal
   */
  handleCloseDisableModal = () => {
    this.setState({disableModalOpened: false});
  };

  /**
   * getQRTicketDetail
   */
  getQRTicketDetail = async () => {
    const {id} = this.props.match.params;
    await this.props.getQRTicketDetail(id);
  };

  /**
   * disableTicket
   */
  disableTicket = async () => {
    const {id} = this.props.match.params;
    this.props.disableQRTicket(id).then((response) => {
      if (response && response.status === 200) {
        this.getQRTicketDetail();
      }
    });
    this.handleCloseDisableModal();
  };

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    await this.props.getAllCountryCode();
    this.getQRTicketDetail();
  };

  /**
   * Render component
   * @return {Component}
   */
  render() {
    const {t, classes, qrTicket} = this.props;
    const {qrTicketDetail, ticketHistoryList, isLoading} = qrTicket;

    return (
      <Container maxWidth="xl">
        <Grid container alignItems="center" className="page_header">
          <h3>{t('qrTicket.detailTitle')}</h3>
        </Grid>
        <br />
        <LoadingOverlay active={this.state.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
          <Card>
            <Grid container className="row_form_item">
              <Grid container item xs={12} alignItems="center" className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                {t('qrTicket.detailForm')}
              </Grid>
            </Grid>
            <Container maxWidth="xl">
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.width30}>{t('qrTicket.country')}</TableCell>
                    <TableCell>{qrTicketDetail?.country_id && t(this.getCountryCodeById(qrTicketDetail?.country_id))}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.width30}>{t('qrTicket.businessName')}</TableCell>
                    <TableCell>{qrTicketDetail?.business_name || DATA_NULL}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.width30}>{t('qrTicket.ticketType')}</TableCell>
                    <TableCell>{qrTicketDetail?.ticket?.ticket_type === TICKET_TYPE[0].id ? t(TICKET_TYPE[0].i18n) : t(TICKET_TYPE[1].i18n)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.width30}>
                      {qrTicketDetail?.ticket?.ticket_type === TICKET_TYPE[0].id ? t('qrTicket.commuterPassType') : t('qrTicket.couponTicketType')}
                    </TableCell>
                    <TableCell>{qrTicketDetail?.ticket?.name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.width30}>{t('qrTicket.fullName')}</TableCell>
                    <TableCell>
                      {qrTicketDetail?.user_info?.last_name || DATA_NULL} {qrTicketDetail?.user_info?.first_name || DATA_NULL}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.width30}>{t('qrTicket.phoneNumber')}</TableCell>
                    <TableCell>{qrTicketDetail?.user_info?.mobile || DATA_NULL}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.width30}>{t('qrTicket.mail')}</TableCell>
                    <TableCell>{qrTicketDetail?.user_info?.email || DATA_NULL}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.width30}>{t('qrTicket.userTicketId')}</TableCell>
                    <TableCell>{qrTicketDetail?.code || DATA_NULL}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.width30}>{t('qrTicket.departureStation')}</TableCell>
                    <TableCell>{qrTicketDetail?.departure_station?.name || DATA_NULL}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.width30}>{t('qrTicket.arrivalStation')}</TableCell>
                    <TableCell>{qrTicketDetail?.arrival_station?.name || DATA_NULL}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.width30}>{t('qrTicket.routeName')}</TableCell>
                    <TableCell>
                      {qrTicketDetail?.detail_paths?.details.map((detail, index) => (
                        <span key={index}>
                          {detail.route_detail?.name}
                          {index !== qrTicketDetail?.detail_paths?.details?.length - 1 ?
                            ` - ${this.getStationNameById(detail.stations[1], qrTicketDetail?.detail_paths?.stations)} - ` :
                            ''}
                        </span>
                      ))}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.width30}>{t('qrTicket.registrationDate')}</TableCell>
                    <TableCell>
                      {qrTicketDetail?.registration_date ?
                        convertDateTimeToDate(convertDateUTCAndGeofence(qrTicketDetail?.registration_date, getTimeZoneByCountryCode(qrTicketDetail?.country_id), true)) :
                        DATA_NULL}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.width30}>
                      {t('qrTicket.startDate')} ~ {t('qrTicket.endDate')}
                    </TableCell>
                    <TableCell>
                      {qrTicketDetail?.start_date_time ?
                        convertDateTimeToDate(convertDateUTCAndGeofence(qrTicketDetail.start_date_time, getTimeZoneByCountryCode(qrTicketDetail?.country_id), true)) :
                        DATA_NULL}{' '}
                      ~{' '}
                      {qrTicketDetail?.end_date_time ?
                        convertDateTimeToDate(convertDateUTCAndGeofence(qrTicketDetail.end_date_time, getTimeZoneByCountryCode(qrTicketDetail?.country_id), true)) :
                        DATA_NULL}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.width30}>{t('qrTicket.paymentMethod')}</TableCell>
                    <TableCell>{t('paymentMethod.CREDIT_CARD')}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.width30}>{t('qrTicket.paymentId')}</TableCell>
                    <TableCell>{qrTicketDetail?.payment_code || DATA_NULL}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.width30}>{t('qrTicket.amount')}</TableCell>
                    <TableCell>{customDisplayCurrency(qrTicketDetail?.amount) || DATA_NULL}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="width_30">{t('qrTicket.status')}</TableCell>
                    <TableCell>{qrTicketDetail?.is_active ? t('common.enable') : t('common.disable')}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <br />
              {/* Ride limit */}
              {qrTicketDetail?.ticket?.ticket_type === 'COUPON_TICKET' && (
                <>
                  <Grid container spacing={1} justify="center" alignItems="center">
                    <Grid container item xs={6}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell className={clsx('width_50p', classes.borderBottomNone)} align="center">
                              {t('qrTicket.rideLimit')}
                            </TableCell>
                            <TableCell className={clsx('width_50p', classes.borderBottomNone)} align="center">
                              {t('qrTicket.usedCount')}
                            </TableCell>
                            <TableCell className={clsx('width_50p', classes.borderBottomNone)} align="center">
                              {t('qrTicket.remainCount')}
                            </TableCell>
                            <TableCell className={clsx('width_50p', classes.borderBottomNone)} align="center">
                              {t('common.action')}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell className={classes.borderBottomNone} align="center">
                              {qrTicketDetail?.ticket?.rides_limit}
                            </TableCell>
                            <TableCell className={classes.borderBottomNone} align="center">
                              {qrTicketDetail?.used_rides}
                            </TableCell>
                            <TableCell className={classes.borderBottomNone} align="center">
                              {qrTicketDetail?.ticket?.rides_limit - qrTicketDetail?.used_rides}
                            </TableCell>
                            <TableCell className={classes.borderBottomNone} align="center">
                              {qrTicketDetail?.is_used && (
                                  <Button className="button_margin" color="primary" variant="contained" onClick={this.handleOpenDetailModal}>
                                    {t('common.btnDetail')}
                                  </Button>
                                )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                  <br />
                </>
              )}
              {/* Action buttons */}
              <Grid container spacing={1} alignItems="center" justify="flex-end" style={{paddingRight: 20}}>
                {qrTicketDetail?.is_active && (
                  <Button className="button_margin button_color_red" variant="contained" color="primary" endIcon={<DisableIcon />} onClick={this.handleOpenDisableModal}>
                    {t('qrTicket.disable')}
                  </Button>
                )}
                <Button className="button_margin button_color" variant="contained" color="primary" endIcon={<ArrowBackIcon />} onClick={this.props.history.goBack}>
                  {t('common.btnReturn')}
                </Button>
              </Grid>
              <br />
            </Container>
          </Card>
        </LoadingOverlay>
        {/* Detail modal */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.detailModalOpened}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1000,
          }}
        >
          <>
            <Fade in={true}>
              <Paper className="modal_size_big">
                <Container maxWidth="xl">
                  <Grid container className="page_header">
                    <Grid container item>
                      <h3>{t('qrTicket.detailModalTitle')}</h3>
                    </Grid>
                  </Grid>
                  <br />
                  <Paper style={{maxHeight: '50vh', overflow: 'auto'}}>
                    <LoadingOverlay active={isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                      <Table stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" className="width_50p">
                              {t('qrTicket.number')}
                            </TableCell>
                            <TableCell align="center" className="width_75p">
                              {t('qrTicket.departureStation')}
                            </TableCell>
                            <TableCell align="center" className="width_100p">
                              {t('qrTicket.departureDate')}
                            </TableCell>
                            <TableCell align="center" className="width_75p">
                              {t('qrTicket.arrivalStation')}
                            </TableCell>
                            <TableCell align="center" className="width_100p">
                              {t('qrTicket.arrivalDate')}
                            </TableCell>
                            <TableCell align="center" className="width_50p">
                              {t('qrTicket.onetimeAmount')}
                            </TableCell>
                            <TableCell align="center" className="width_75p">
                              {t('qrTicket.status')}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {ticketHistoryList?.map((ride, index) => (
                            <TableRow key={index}>
                              <TableCell align="center">{ticketHistoryList?.length - index || DATA_NULL}</TableCell>
                              <TableCell align="center">{ride?.departure_station ? ride?.departure_station.name : DATA_NULL}</TableCell>
                              <TableCell align="center">
                                {' '}
                                {ride?.departure_date_time ?
                                  convertDateTimeToDate(convertDateUTCAndGeofence(ride.departure_date_time, 'Asia/Tokyo', true)) +
                                    ' ' +
                                    formatUTCToHourMinute(ride?.departure_date_time, getTimeZoneByCountryCode('Asia/Tokyo')) :
                                  DATA_NULL}
                              </TableCell>
                              <TableCell align="center">{ride?.arrival_station ? ride?.arrival_station.name : DATA_NULL}</TableCell>
                              <TableCell align="center">
                                {' '}
                                {ride?.boarding_status === 'CANCELED' ? (
                                  ride?.cancel_date_time ?
                                    convertDateTimeToDate(convertDateUTCAndGeofence(ride.cancel_date_time, 'Asia/Tokyo', true)) +
                                      ' ' +
                                      formatUTCToHourMinute(ride?.cancel_date_time, getTimeZoneByCountryCode('Asia/Tokyo')) :
                                    DATA_NULL
                                ) : (
                                  ride?.arrival_date_time ?
                                    convertDateTimeToDate(convertDateUTCAndGeofence(ride.arrival_date_time, 'Asia/Tokyo', true)) +
                                      ' ' +
                                      formatUTCToHourMinute(ride?.arrival_date_time, getTimeZoneByCountryCode('Asia/Tokyo')) :
                                    DATA_NULL
                                )}
                              </TableCell>
                              <TableCell align="center">{customDisplayCurrency(ride?.amount) || DATA_NULL}</TableCell>
                              <TableCell align="center">
                                {ride?.boarding_status ? t(BOARDING_STATUS.find((status) => status.id === ride?.boarding_status).i18n) : DATA_NULL}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </LoadingOverlay>
                  </Paper>
                  <br />
                  <Grid container justify="center">
                    <Grid item>
                      <Button className="button_color" variant="contained" color="primary" onClick={this.handleCloseDetailModal}>
                        {t('common.btnClose')}
                      </Button>
                    </Grid>
                  </Grid>
                  <br />
                </Container>
              </Paper>
            </Fade>
          </>
        </Modal>
        {/* Disable confirmation modal */}
        <Dialog
          open={this.state.disableModalOpened}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '1020',
          }}
        >
          <SelectModal onClickOk={this.disableTicket} onClickCancel={this.handleCloseDisableModal} message={t('qrTicket.disableMessage')} />
        </Dialog>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common,
  qrTicket: state.qrTicket,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCountryCode: () => dispatch(getAllCountryCode()),
  getTicketHistoryListByUserId: (id) => dispatch(getTicketHistoryListByUserId(id)),
  getQRTicketDetail: (id) => dispatch(getQRTicketDetail(id)),
  disableQRTicket: (id) => dispatch(disableQRTicket(id)),
});

export default withTranslation('translations')(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Index)));
