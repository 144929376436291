import {api} from './api';

const API_GEOFENCE_URL = 'mass/v1/geofences';
const API_ADMIN_GEOFENCE_URL = 'admin/mass/v1/geofences';
const API_SERVICE_URL = 'mass/v1/servicegroup';
const API_ADMIN_SERVICE_URL = 'admin/mass/v1/servicegroup';
const API_SIMULATION_URL = 'mass/v1/simconfig';
const API_SWAT_GEOFENCE_URL = 'admin/mass/v1/geofences/{uuid}/sync';
const API_CHECK_GEOFENCE_TIME = 'admin/plans/check-geofence-time';
const API_GET_PROJECT = 'mass/v1/projects';
const API_GEOFENCE_TIME_BOOKING_AFFECT_URL = 'mass/v1/geofences/{geofenceId}/affected-bookings';

export const getAllGeofence = (pageable) => {
  return api.get(API_GEOFENCE_URL, pageable);
};

export const searchServiceGeofenceApi = (pageable) => {
  return api.get(API_SERVICE_URL, pageable);
};

export const getGeofence = (id) => {
  return api.get(`${API_GEOFENCE_URL}/${id}`);
};

export const updateGeofenceApi = (payload) => {
  return api.post(API_ADMIN_GEOFENCE_URL, payload);
};

export const syncGeofenceApi = (uuid) => {
  return api.post(API_SWAT_GEOFENCE_URL.replace('{uuid}', uuid), {});
};

export const detailServiceGeofenceApi = (id) => {
  return api.get(API_SERVICE_URL + '/' + id);
};

export const updateServiceGeofenceApi = (payload) => {
  return api.post(API_ADMIN_SERVICE_URL, payload);
};

export const getEnableSimulationApi = (payload) => {
  return api.get(API_SIMULATION_URL, payload);
};

export const checkGeofenceTimeApi = (payload) => {
  return api.get(API_CHECK_GEOFENCE_TIME, payload);
};

export const getProjectApi = (payload) => {
  return api.get(API_GET_PROJECT, payload);
};

export const getGeofenceServiceApi = (geofenceId, groupType) => {
  return api.get(`mass/v1/geofences/${geofenceId}/services/${groupType}/setting`);
};

export const updateGeofenceServiceApi = (payload) => {
  return api.post('mass/v1/geofences/services/setting', payload);
};

export const checkBookingGeofenceApi = (payload) => {
  return api.get(`mass/v1/geofences/${payload.geofence_id}/assigned-bookings`);
};

export const checkGeofenceTimeBookingAffectApi = (payload) => {
  const body = {
    start_time: payload.local_start_time, end_time: payload.local_end_time,
  };
  return api.post(API_GEOFENCE_TIME_BOOKING_AFFECT_URL.replace('{geofenceId}', payload.geofence_id), body);
};

