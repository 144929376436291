import {api} from '../services/api';

const getListDriverUrl = `/admin/drivers/search`;
const createDriverUrl = `admin/drivers/registration`;
const updateDriverUrl = `admin/drivers/update-profile`;
const getDetailDriverUrl = `/admin/drivers/`;
const deleteDriverUrl = `/admin/drivers/`;
const importDriverCSVUrl = `admin/driver-vehicle-config/insert`;
const importDriverCSVOnDemandUrl = `admin/driver-vehicle-config/insert-on-demand`;
const getDriverRatingUrl = 'admin/rating/driver';

export const getListDriverApi = (payload, queryParams) => {
  return api.search(getListDriverUrl, queryParams, payload);
};

export const createDriverApi = (payload) => {
  return api.post(createDriverUrl, payload);
};

export const updateDriverApi = (payload) => {
  return api.post(updateDriverUrl, payload);
};

export const getDetailDriverApi = (id) => {
  return api.get(getDetailDriverUrl + id);
};

export const deleteDriverApi = (id) => {
  return api.delete(deleteDriverUrl + id);
};

export const importDriverCSVApi = (payload) => {
  return api.post(importDriverCSVUrl, payload);
};

export const importDriverCSVOnDemandApi = (payload) => {
  return api.post(importDriverCSVOnDemandUrl, payload);
};

export const getDriverRatingApi = (id) => {
  return api.get(getDriverRatingUrl + `/${id}`);
};
