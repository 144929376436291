export const ACTION_TYPES = {
  INVITE_MAIL_START: 'INVITE_MAIL_START',
  INVITE_MAIL_SUCCESS: 'INVITE_MAIL_SUCCESS',
  INVITE_MAIL_FAIL: 'INVITE_MAIL_FAIL',

  ROLE_LIST_START: 'ROLE_LIST_START',
  ROLE_LIST_SUCCESS: 'ROLE_LIST_SUCCESS',
  ROLE_LIST_FAIL: 'ROLE_LIST_FAIL',

  ROLE_PERMISSION_LIST_START: 'ROLE_PERMISSION_LIST_START',
  ROLE_PERMISSION_LIST_SUCCESS: 'ROLE_PERMISSION_LIST_SUCCESS',
  ROLE_PERMISSION_LIST_FAIL: 'ROLE_PERMISSION_LIST_FAIL',

  ROLE_PERMISSION_LIST_BY_SUPPLIER_ID_START: 'ROLE_PERMISSION_LIST_BY_SUPPLIER_ID_START',
  ROLE_PERMISSION_LIST_BY_SUPPLIER_ID_SUCCESS: 'ROLE_PERMISSION_LIST_BY_SUPPLIER_ID_SUCCESS',
  ROLE_PERMISSION_LIST_BY_SUPPLIER_ID_FAIL: 'ROLE_PERMISSION_LIST_BY_SUPPLIER_ID_FAIL',

  SEARCH_ACCOUNT_START: 'SEARCH_ACCOUNT',
  SEARCH_ACCOUNT_SUCCESS: 'SEARCH_ACCOUNT_SUCCESS',
  SEARCH_ACCOUNT_FAIL: 'SEARCH_ACCOUNT_FAIL',

  DETAIL_ACCOUNT_START: 'DETAIL_ACCOUNT_START',
  DETAIL_ACCOUNT_SUCCESS: 'DETAIL_ACCOUNT_SUCCESS',
  DETAIL_ACCOUNT_FAIL: 'DETAIL_ACCOUNT_FAIL',

  UPDATE_ACCOUNT_START: 'UPDATE_ACCOUNT_START',
  UPDATE_ACCOUNT_SUCCESS: 'UPDATE_ACCOUNT_SUCCESS',
  UPDATE_ACCOUNT_FAIL: 'UPDATE_ACCOUNT_FAIL',

  RESEND_INVITATION_START: 'RESEND_INVITATION_START',
  RESEND_INVITATION_SUCCESS: 'RESEND_INVITATION_SUCCESS',
  RESEND_INVITATION_FAIL: 'RESEND_INVITATION_FAIL',

  RESTART_ACCOUNTS_START: 'RESTART_ACCOUNTS_START',
  RESTART_ACCOUNTS_SUCCESS: 'RESTART_ACCOUNTS_SUCCESS',
  RESTART_ACCOUNTS_FAIL: 'RESTART_ACCOUNTS_FAIL',

  SUSPEND_ACCOUNTS_START: 'SUSPEND_ACCOUNTS_START',
  SUSPEND_ACCOUNTS_SUCCESS: 'SUSPEND_ACCOUNTS_SUCCESS',
  SUSPEND_ACCOUNTS_FAIL: 'SUSPEND_ACCOUNTS_FAIL',

  GET_ALL_ACCOUNT_TYPES_START: 'GET_ALL_ACCOUNT_TYPES_START',
  GET_ALL_ACCOUNT_TYPES_SUCCESS: 'GET_ALL_ACCOUNT_TYPES_SUCCESS',
  GET_ALL_ACCOUNT_TYPES_FAIL: 'GET_ALL_ACCOUNT_TYPES_FAIL',
};

const initialState = {
  isLoading: false,
  isLoadingRole: false,
  objectMail: {},
  roleList: [],
  accountList: [],
  accountDetail: {},
  updateAccount: {},
  allAccountTypes: [],
};

/**
 * function reduce
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.INVITE_MAIL_START:
    case ACTION_TYPES.DETAIL_ACCOUNT_START:
    case ACTION_TYPES.SEARCH_ACCOUNT_START:
    case ACTION_TYPES.UPDATE_ACCOUNT_START:
    case ACTION_TYPES.RESEND_INVITATION_START:
    case ACTION_TYPES.RESTART_ACCOUNTS_START:
    case ACTION_TYPES.SUSPEND_ACCOUNTS_START:
    case ACTION_TYPES.ROLE_PERMISSION_LIST_BY_SUPPLIER_ID_START:
    case ACTION_TYPES.GET_ALL_ACCOUNT_TYPES_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.ROLE_LIST_START:
    case ACTION_TYPES.ROLE_PERMISSION_LIST_START:
      return {
        ...state,
        isLoadingRole: true,
      };
    case ACTION_TYPES.ROLE_LIST_SUCCESS:
      return {
        ...state,
        isLoadingRole: false,
        roleList: action.roleList,
      };
    case ACTION_TYPES.ROLE_PERMISSION_LIST_SUCCESS:
      return {
        ...state,
        isLoadingRole: false,
        roleListPermission: action.roleListPermission,
      };
    case ACTION_TYPES.SEARCH_ACCOUNT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        accountList: action.accountList,
      };
    }
    case ACTION_TYPES.DETAIL_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        accountDetail: action.accountDetail,
      };
    case ACTION_TYPES.UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        updateAccount: action.updateAccount,
      };
    case ACTION_TYPES.GET_ALL_ACCOUNT_TYPES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allAccountTypes: action.payload,
      };
    case ACTION_TYPES.SEARCH_ACCOUNT_FAIL: {
      return {
        isLoading: false,
      };
    }
    case ACTION_TYPES.INVITE_MAIL_SUCCESS:
    case ACTION_TYPES.INVITE_MAIL_FAIL:
    case ACTION_TYPES.DETAIL_ACCOUNT_FAIL:
    case ACTION_TYPES.UPDATE_ACCOUNT_FAIL:
    case ACTION_TYPES.RESEND_INVITATION_FAIL:
    case ACTION_TYPES.RESTART_ACCOUNTS_FAIL:
    case ACTION_TYPES.SUSPEND_ACCOUNTS_FAIL:
    case ACTION_TYPES.RESEND_INVITATION_SUCCESS:
    case ACTION_TYPES.RESTART_ACCOUNTS_SUCCESS:
    case ACTION_TYPES.SUSPEND_ACCOUNTS_SUCCESS:
    case ACTION_TYPES.ROLE_PERMISSION_LIST_BY_SUPPLIER_ID_SUCCESS:
    case ACTION_TYPES.ROLE_PERMISSION_LIST_BY_SUPPLIER_ID_FAIL:
    case ACTION_TYPES.GET_ALL_ACCOUNT_TYPES_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.ROLE_LIST_FAIL:
    case ACTION_TYPES.ROLE_PERMISSION_LIST_FAIL:
      return {
        ...state,
        isLoadingRole: false,
      };
    default:
      return state;
  }
}
