import {api} from '../services/api';

const getMailUrl = 'notification/mail-templates';
const updateMailUrl = 'notification/mail-templates';


export const getMailApi = () => {
  return api.get(getMailUrl);
};

export const updateMailApi = (payload) => {
  return api.put(updateMailUrl, payload);
};
