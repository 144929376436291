import React from 'react';

import {Grid} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';

/**
 * Cart component
 */
export class Index extends React.Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * handleClick
   */
  handleClick = () => {
    this.props.history.push('/maas/reservation/created/delivery/carts');
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t} = this.props;
    return (
      <div className="carts">
        <Grid container spacing={1}>
          <Grid container alignItems="center" item xs={2} className="view_cart">
            <Chip
              icon={<ShoppingCartIcon />}
              color="primary"
              size="large"
              label={`(${this.props.total_amount}) ${t('reservationManagement.view_cart')}`}
              onClick={() => this.handleClick()}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    total_amount: state.carts.total_amount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
