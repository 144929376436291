export const ACTION_TYPES = {
  MESSAGE_MODAL: 'MESSAGE_MODAL',
};

const initialState = {
  flgMessage: false,
  message: '',
  fields: null,
  forceReload: false,
};

/**
 * function reduce
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  let newState;
  if (action.type === ACTION_TYPES.MESSAGE_MODAL) {
    newState = Object.assign({}, state);
    newState.flgMessage = action.payload.flg;
    newState.message = action.payload.message;
    newState.fields = action.payload.fields;
    newState.forceReload = action.payload.forceReload;
    return newState;
  }
  return state;
}
