import React from 'react';
import TimePicker from 'rc-time-picker-date-fns';
import PropTypes from 'prop-types';
import 'rc-time-picker-date-fns/assets/index.css';
import './styles.css';

/**
 *
 * @param {CustomTimePicker.propTypes} props
 * @return {HTMLElement}
 */
const CustomTimePicker = (props) => {
  return <TimePicker {...props} />;
};

// Read more in https://www.npmjs.com/package/rc-time-picker-date-fns
CustomTimePicker.propTypes = {
  prefixCls: PropTypes.string,
  clearText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  defaultOpenValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  disabled: PropTypes.bool,
  allowEmpty: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  open: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  align: PropTypes.object,
  placement: PropTypes.any,
  transitionName: PropTypes.string,
  getPopupContainer: PropTypes.func,
  placeholder: PropTypes.string,
  format: PropTypes.string,
  showHour: PropTypes.bool,
  showMinute: PropTypes.bool,
  showSecond: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  popupClassName: PropTypes.string,
  disabledHours: PropTypes.func,
  disabledMinutes: PropTypes.func,
  disabledSeconds: PropTypes.func,
  hideDisabledOptions: PropTypes.bool,
  onChange: PropTypes.func,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  addon: PropTypes.func,
  name: PropTypes.string,
  autoComplete: PropTypes.string,
  use12Hours: PropTypes.bool,
  hourStep: PropTypes.number,
  minuteStep: PropTypes.number,
  secondStep: PropTypes.number,
  focusOnOpen: PropTypes.bool,
  onKeyDown: PropTypes.func,
  autoFocus: PropTypes.bool,
};

export default CustomTimePicker;
