
export const ACTION_TYPES = Object.freeze({

  GET_QR_TIKET_HISTORY_BY_USER_ID_START: 'GET_QR_TIKET_HISTORY_BY_USER_ID_START',
  GET_QR_TIKET_HISTORY_BY_USER_ID_SUCCESS: 'GET_QR_TIKET_HISTORY_BY_USER_ID_SUCCESS',
  GET_QR_TIKET_HISTORY_BY_USER_ID_FAILED: 'GET_QR_TIKET_HISTORY_BY_USER_ID_FAILED',

  SEARCH_QR_TICKET_START: 'SEARCH_QR_TICKET_START',
  SEARCH_QR_TICKET_SUCCESS: 'SEARCH_QR_TICKET_SUCCESS',
  SEARCH_QR_TICKET_FAILED: 'SEARCH_QR_TICKET_FAILED',

  GET_QR_TICKET_DETAIL_START: 'GET_QR_TICKET_DETAIL_START',
  GET_QR_TICKET_DETAIL_SUCCESS: 'GET_QR_TICKET_DETAIL_SUCCESS',
  GET_QR_TICKET_DETAIL_FAILED: 'GET_QR_TICKET_DETAIL_FAILED',

  DISABLE_QR_TICKET_START: 'DISABLE_QR_TICKET_START',
  DISABLE_QR_TICKET_SUCCESS: 'DISABLE_QR_TICKET_SUCCESS',
  DISABLE_QR_TICKET_FAILED: 'DISABLE_QR_TICKET_FAILED',
});

const initialState = {
  isLoading: false,
  ticketHistoryList: [],
  qrTicketsList: [],
  qrTicketDetail: {},
  totalRows: null,
};

/**
 * function qr_payment reducer
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.GET_QR_TIKET_HISTORY_BY_USER_ID_START:
    case ACTION_TYPES.SEARCH_QR_TICKET_START:
    case ACTION_TYPES.GET_QR_TICKET_DETAIL_START:
    case ACTION_TYPES.DISABLE_QR_TICKET_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.GET_QR_TIKET_HISTORY_BY_USER_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ticketHistoryList: action.ticketHistoryList,
      };
    case ACTION_TYPES.SEARCH_QR_TICKET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        qrTicketsList: action.payload.content,
        totalRows: action.payload.totalSize,
      };
    case ACTION_TYPES.GET_QR_TICKET_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        qrTicketDetail: action.qrTicketDetail,
      };
    case ACTION_TYPES.DISABLE_QR_TICKET_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.GET_QR_TIKET_HISTORY_BY_USER_ID_FAILED:
    case ACTION_TYPES.SEARCH_QR_TICKET_FAILED:
    case ACTION_TYPES.GET_QR_TICKET_DETAIL_FAILED:
    case ACTION_TYPES.DISABLE_QR_TICKET_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return {...state};
  }
}
