import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import Container from '@material-ui/core/Container';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import {Grid, TextField, Button} from '@material-ui/core';
import {validateInputEmpty, isValidEmail, redirectRouter} from '../../../../utils/common';

/**
 * Login view
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errorField: {
        emailMsg: false,
        passwordMsg: false,
        emailFormat: false,
      },
    };
  }

  /**
   * validate field
   * @return {boolean}
   */
  validateField = () => {
    let flgErr = false;
    const errorField = {
      emailMsg: false,
      passwordMsg: false,
      emailFormat: false,
    };
    if (validateInputEmpty(this.state.email)) {
      flgErr = true;
      errorField.emailMsg = true;
    }
    if (!isValidEmail(this.state.email)) {
      flgErr = true;
      errorField.emailFormat = true;
    }
    if (validateInputEmpty(this.state.password)) {
      flgErr = true;
      errorField.passwordMsg = true;
    }
    this.setState({...this.state, errorField: errorField});
    return flgErr;
  };

  /**
   * handleClickLogin
   * @param {e} e
   */
  handleClickLogin = (e) => {
    e.preventDefault();
    const {email, password} = this.state;
    if (!this.validateField()) {
      this.props.login(email, password, this.props);
    }
  };

  /**
   * Render view
   * @return {object}
   */
  render() {
    const {t} = this.props;

    return (
      <div>
        <LoadingOverlay bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
          <Container maxWidth="md" style={{height: '100vh'}}>
            <form>
              <Grid container spacing={4}>
                <Grid item xs={12} justify="center" alignItems="center" container>
                  <TextField
                    className="field_size_50"
                    label={t('common.email')}
                    name="email"
                    margin="dense"
                    variant="outlined"
                    value={this.state.email}
                    error={this.state.errorField.emailMsg || this.state.errorField.emailFormat}
                    helperText={
                      this.state.errorField.emailMsg ?
                        t('errorFields.emailRequired') :
                        this.state.errorField.emailFormat ?
                        t('errorFields.emailFormat') :
                        ''
                    }
                    onChange={(email) => this.setState({email: email.target.value})}
                    inputProps={{maxLength: 256}}
                  />
                </Grid>

                <Grid item xs={12} justify="center" alignItems="center" container>
                  <TextField
                    className="field_size_50"
                    label={t('common.password')}
                    name="password"
                    margin="dense"
                    variant="outlined"
                    type="password"
                    value={this.state.password}
                    error={this.state.errorField.passwordMsg}
                    helperText={this.state.errorField.passwordMsg ? t('errorFields.passwordRequired') : ''}
                    inputProps={{maxLength: 256}}
                  />
                </Grid>
              </Grid>
              <br></br>
              <br></br>
              <Grid container spacing={6}>
                <Grid item container justify="center" alignItems="center" xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    endIcon={<LockOpenOutlinedIcon />}
                    onClick={() => this.toProduct()}
                  >
                    {t('common.btnLogin')}
                  </Button>
                </Grid>
              </Grid>
            </form>
            <br></br>
          </Container>
        </LoadingOverlay>
      </div>
    );
  }

  /**
   * toProduct
   */
  toProduct() {
    const path = '/maas/reservation/payment/products';
    redirectRouter(this.props, path);
  }
}

export default withTranslation('translations')(Index);
