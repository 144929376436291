import React, {Component} from 'react';

import {Box, Button, Card, Container, Dialog, Grid, Paper, TextField} from '@material-ui/core';
import {AgGridReact} from 'ag-grid-react';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';

import {PERMISSION_ACTIONS} from '../../../../common/constant';
import SelectModal from '../../../../components/selectModal';
import withPermissionGateway from '../../../../hoc/withPermissionGateway';
import {getDetailFacility, getStoreProducts, updateStoreProductsIndex} from '../../../../stores/facility/action';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import './styles.css';

/**
 * StoreManagement
 */
class StoreManagement extends Component {
  /**
  constructor(props) {
   *
   * @param {object} props
   */
  constructor(props) {
    super(props);
    const {t} = props;
    this.state = {
      columnDefs: [
        {
          headerName: t('business.facility_delivery.product_id'),
          field: 'product_id',
          rowDrag: true,
          flex: 1,
          unSortIcon: true,
        },
        {headerName: t('business.facility_delivery.product_name'), field: 'product_name', flex: 2, unSortIcon: true},
        {
          headerName: t('business.facility_delivery.supplier_facility_name'),
          field: 'supplier_facility_name',
          flex: 1,
          unSortIcon: true,
        },
        {headerName: t('business.facility_delivery.supplier_id'), field: 'supplier_id', flex: 1, unSortIcon: true},
        {headerName: t('business.facility_delivery.sale_status'), field: 'sale_status', flex: 1, unSortIcon: true},
      ],
      defaultColDef: {
        width: 100,
        sortable: true,
        headerClass: 'table-header',
        cellClass: 'table-header-cell',
      },
      sortedData: [],
      isOpenConfirmUpdateModal: false,
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    this.props.getDetailFacility(this.props.match.params.id, this.props);
  }

  /**
   *
   * @param {object} params
   */
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.props.getStoreProducts(this.props.match.params.id, this.props).then(() => {
      this.setState({sortedData: this.props.facility.listProduct});
    });
  };

  /**
   * onUpdate
   */
  onUpClickUpdate = () => {
    this.setState({isOpenConfirmUpdateModal: true});
  };

  /**
   * onSortChange
   * @param {object} params
   */
  onSortChange = (params) => {
    const data = [];
    params.api.forEachNodeAfterFilterAndSort((node) => data.push(node.data));
    this.setState({sortedData: data});
  };

  /**
   *
   * @param {object} params
   */
  onDragEnd = (params) => {
    const data = [];
    params.api.forEachNode((node) => data.push(node.data));
    this.setState({sortedData: data});
  };

  onConfirmUpdate = () => {
    const {sortedData} = this.state;
    this.setState({isOpenConfirmUpdateModal: false});
    const params = sortedData.map((data, index) => ({id: data.id, order_display: index}));
    this.props.updateStoreProductsIndex(params, this.props).then(() => {
      this.gridApi.deselectAll();
    });
  };

  onCloseConfirmUpdateModal = () => {
    this.setState({isOpenConfirmUpdateModal: false});
  };

  /**
   * render
   * @return {HTMLElement}
   */
  render() {
    const {t, facility, actions} = this.props;
    const {detailFacility} = facility;
    const {columnDefs, defaultColDef} = this.state;
    const permission = {
      canUpdateManage: actions.includes(PERMISSION_ACTIONS.UPDATE_MANAGE),
    };

    return (
      <div className="StoreManagement">
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" justify="space-between" item>
                <h3>{t('business.facility_delivery.title')}</h3>
              </Grid>
            </Grid>
            <br></br>
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper>
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business.facility_delivery.store_id')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <TextField
                        disabled
                        className="field_size_10 field_min_size_300"
                        margin="dense"
                        variant="outlined"
                        name="productId"
                        value={detailFacility?.facility_id}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business.facility_delivery.store_name')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <TextField
                        disabled
                        className="field_size_10 field_min_size_300"
                        margin="dense"
                        variant="outlined"
                        name="product_name"
                        value={detailFacility?.facility_name}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Container>
              <br></br>
            </Card>
            <br></br>

            <LoadingOverlay active={facility?.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
              <Card raised>
                <Container maxWidth="xl">
                  <Grid container spacing={1}>
                    <Grid container alignItems="center" item xs={6}>
                      <h3>{t('business.facility_delivery.products_section_title')}</h3>
                    </Grid>
                    <div className="ag-theme-alpine store-grid">
                      <AgGridReact
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowDragManaged={true}
                        enableMultiRowDragging={true}
                        rowSelection={'multiple'}
                        animateRows={true}
                        onGridReady={this.onGridReady}
                        rowData={facility.listProduct}
                        onSortChanged={this.onSortChange}
                        onRowDragEnd={this.onDragEnd}
                      />
                    </div>
                  </Grid>
                </Container>
              </Card>
              <br></br>
              <Box display="flex" justifyContent="flex-end">
                {permission.canUpdateManage && (
                  <Button color="primary" variant="contained" className="button_margin button_color_green" onClick={this.onUpClickUpdate}>
                    {t('common.btnUpdate')}
                  </Button>
                )}
                <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.props.history.goBack}>
                  {t('common.btnReturn')}
                </Button>
              </Box>
              <br></br>
            </LoadingOverlay>
            <Dialog
              open={this.state.isOpenConfirmUpdateModal}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '1020',
              }}
            >
              <SelectModal
                onClickOk={this.onConfirmUpdate}
                onClickCancel={this.onCloseConfirmUpdateModal}
                message="business.facility_delivery.update_confirm_message"
              ></SelectModal>
            </Dialog>
          </Container>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    facility: state.facility,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStoreProducts: (id, props) => dispatch(getStoreProducts(id, props)),
    getDetailFacility: (id, props) => dispatch(getDetailFacility(id, props)),
    updateStoreProductsIndex: (params, props) => dispatch(updateStoreProductsIndex(params, props)),
  };
};
export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(StoreManagement)));
