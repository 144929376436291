import React, {Component} from 'react';

import {Map, GoogleApiWrapper} from 'google-maps-react';
import {connect} from 'react-redux';

/**
 * Map with polyline
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    const {google} = this.props;

    if (this.mapRef) {
      this.mapRef.map.setCenter(this.props.centerPoint);
      new google.maps.Marker({
        position: this.props.centerPoint,
        icon: {
          url: `${process.env.PUBLIC_URL}/images/ic_pick_destination.svg`,
          scale: 10,
          strokeWeight: 2,
          fillOpacity: 1,
        },
        map: this.mapRef.map,
      });
    }
  }

  /**
   * render
   * @return {HTMLElement}
   */
  render() {
    return (
      <div>
        <Map
          {...this.props}
          ref={(ref) => (this.mapRef = ref)}
          initialCenter={this.props.centerPoint}
          zoom={15}
          centerAroundCurrentLocation={false}
          containerStyle={{
            height: '400px',
            position: 'relative',
            width: '27.8vw',
          }}
        ></Map>
      </div>
    );
  }
}

// eslint-disable-next-line new-cap
export default connect(
  null,
  null,
)(
  // eslint-disable-next-line new-cap
  GoogleApiWrapper((props) => ({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || 'AIzaSyACyapw83diO1bi_xiXbZRLLoano6eTwd0',
    language: localStorage.getItem('i18nextLng') === 'ja' ? 'ja' : 'en',
    libraries: ['geometry'],
  }))(Index),
);
