import {ROUTE} from '../../common/constant';
import {
  createBusinessApi,
  getDetailBusinessApi,
  getListBusinessApi,
  updateBusinessApi,
  deleteBusinessApi,
  getVehicleShiftApi,
  updateVehicleShiftApi,
  searchListPlanApi,
  getDetailPlanApi,
  updatePlanApi,
  createPlanApi,
  copyPlanApi,
  searchListSubscriptionApi,
  getSubscriptionApi,
  updatePlansIndexApi,
  updateSubscriptionTransferredApi,
  registerSubscriptionApi,
  checkOwnerCardApi,
  addSlotApi,
  deleteShiftApi,
  getNumberSubByUserApi,
  deleteCreditCardApi,
  addTicketApi,
  getSendMailListApi,
  getRideAdditionalHistoryApi,
  addRidesApi,
  checkMemberCodeApi,
  additionNumberRideApi,
  getListPlanCodeApi,
  createBusinessPartnerApi,
  updateBusinessPartnerApi,
  getDetailBusinessPartnerApi,
  checkReferrerCodeApi,
  searchReferrersApi,
  getMembersApi,
  deleteBusinessPartnerApi,
  getTicketDistributionHistoryApi,
  getDataMemberTicketApi,
  deleteTicketDistributionHistoryApi,
  getCsvExportSubscriptionApi,
} from '../../services/businessServices';
import {backForwardRouter} from '../../utils/common';
import {modalObj} from '../../utils/modal.js';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

export const failSearchListBusiness = () => {
  return {
    type: ACTION_TYPES.SEARCH_LIST_BUSINESS_FAIL,
  };
};

export const listSortDataPlan = (data) => {
  return {
    type: ACTION_TYPES.LIST_DATA_SORT_PLANS,
    listSortPlans: data,
  };
};

/**
 * search list business
 * @param {object} payload
 * @param {object} queryParams
 * @param {object} props
 * @return {object}
 */
export const searchListBusiness = (payload, queryParams) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.SEARCH_LIST_BUSINESS_START});
    return getListBusinessApi(payload, queryParams).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.SEARCH_LIST_BUSINESS_SUCCESS,
              listBusiness: response.result.content,
            });
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
            dispatch(failSearchListBusiness(response.message));
          }
        },
        () => dispatch(failSearchListBusiness('Internal Server Error')),
    );
  };
};

/**
 * Export list business
 * @param {object} payload
 * @param {object} queryParams
 * @return {object}
 */
export const exportBusiness = (payload, queryParams) => {
  return (dispatch) => {
    return getListBusinessApi(payload, queryParams).then(
        (response) => {
          if (response && response.status === 200) {
            return response.result?.content;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
          }
        },
        () => dispatch(setMessageModal(modalObj(true, 'Internal Server Error'))),
    );
  };
};

/**
 * get detail business
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const getDetailBusiness = (params) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_DETAIL_BUSINESS_START});
    return getDetailBusinessApi(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_DETAIL_BUSINESS_SUCCESS,
              detailBusiness: response.result,
            });
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
            dispatch({type: ACTION_TYPES.GET_DETAIL_BUSINESS_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.GET_DETAIL_BUSINESS_FAIL}),
    );
  };
};

/**
 * create new business
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const createBusiness = (params, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.CREATE_BUSINESS_START});
    return createBusinessApi(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.CREATE_BUSINESS_SUCCESS});
            props.history.push(ROUTE.LAYOUT + ROUTE.BUSINESS_INFORMATION);
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.CREATE_BUSINESS_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.CREATE_BUSINESS_FAIL}),
    );
  };
};

/**
 * update new business
 * @param {object} payload
 * @param {object} props
 * @return {object}
 */
export const updateBusiness = (payload, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.CREATE_BUSINESS_START});
    return updateBusinessApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.CREATE_BUSINESS_SUCCESS});
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.CREATE_BUSINESS_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.CREATE_BUSINESS_FAIL}),
    );
  };
};

/**
 * delete new business
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const deleteBusiness = (params) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.CREATE_BUSINESS_START});
    return deleteBusinessApi(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.CREATE_BUSINESS_SUCCESS});
            return response;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.CREATE_BUSINESS_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.CREATE_BUSINESS_FAIL}),
    );
  };
};

/**
 * getVehicleShift
 * @param {*} date
 * @param {*} vehicle_id
 * @return {*}
 */
export const getVehicleShift = (date, vehicle_id) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_VEHICLE_SHIFT_START});
    return getVehicleShiftApi(date, vehicle_id).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_VEHICLE_SHIFT_SUCCESS,
              listVehicleShift: response.result,
            });
            return response.result;
          } else {
            dispatch({type: ACTION_TYPES.GET_DETAIL_AREA_COUNTRY_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.GET_DETAIL_AREA_COUNTRY_FAIL}),
    );
  };
};

/**
 * updateVehicleShift
 * @param {*} payload
 * @return {*}
 */
export const updateVehicleShift = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.UPDATE_VEHICLE_SHIFT_START});
    return updateVehicleShiftApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.UPDATE_VEHICLE_SHIFT_SUCCESS});
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.UPDATE_VEHICLE_SHIFT_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.UPDATE_VEHICLE_SHIFT_FAIL}),
    );
  };
};

/**
 * searchListPlan
 * @param {object} payload
 * @param {object} queryParams
 * @param {object} props
 * @return {object}
 */
export const searchListPlan = (payload, queryParams) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.SEARCH_LIST_PLAN_START});
    return searchListPlanApi(payload, queryParams).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.SEARCH_LIST_PLAN_SUCCESS,
              listPlan: response.result.content,
            });
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
            dispatch({type: ACTION_TYPES.SEARCH_LIST_PLAN_FAIL});
          }
        },
        () => dispatch(failSearchListBusiness('Internal Server Error')),
    );
  };
};

/**
 * get detail business
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const getDetailPlan = (params) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.DETAIL_PLAN_START});
    return getDetailPlanApi(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.DETAIL_PLAN_SUCCESS,
              detailPlan: response.result,
            });
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
            dispatch({type: ACTION_TYPES.DETAIL_PLAN_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.DETAIL_PLAN_FAIL}),
    );
  };
};

/**
 * update Plan
 * @param {object} payload
 * @param {object} props
 * @return {object}
 */
export const updatePlan = (payload, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.UPDATE_PLAN_START});
    return updatePlanApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.UPDATE_PLAN_SUCCESS});
            return response;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.UPDATE_PLAN_FAIL});
            return response;
          }
        },
        () => dispatch({type: ACTION_TYPES.UPDATE_PLAN_FAIL}),
    );
  };
};

/**
 * create Plan
 * @param {object} payload
 * @param {object} props
 * @return {object}
 */
export const createPlan = (payload, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.CREATE_BUSINESS_START});
    return createPlanApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.CREATE_PLAN_SUCCESS});
            const prevPath = props.location?.state?.from ? props.location?.state?.from : ROUTE.LAYOUT + ROUTE.BUSINESS_PLAN;
            props.history.push(prevPath);
            return response;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.CREATE_PLAN_FAIL});
            return response;
          }
        },
        () => dispatch({type: ACTION_TYPES.CREATE_PLAN_FAIL}),
    );
  };
};

/**
 * copyPlan
 * @param {*} payload
 * @param {*} component
 * @return {*}
 */
export const copyPlan = (payload, component) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.COPY_PLAN_START});
    return copyPlanApi(payload).then((response) => {
      if (response && response.status === 200) {
        dispatch(setMessageModal(modalObj(true, 'business_plan.copy')));
        dispatch({type: ACTION_TYPES.COPY_PLAN_SUCCESS});
        component.props.history.push({
          pathname: ROUTE.LAYOUT + ROUTE.BUSINESS_PLAN_DETAILS + '/' + response.result,
          state: {from: component.props.location.pathname + '?' + component.state.searchParams},
        });
        return response;
      } else {
        dispatch(setMessageModal(modalObj(true, response.message_code)));
        dispatch({type: ACTION_TYPES.COPY_PLAN_FAIL});
        return response;
      }
    });
  };
};

/**
 * searchListSubscription
 * @param {object} payload
 * @param {object} queryParams
 * @param {object} browserHistory
 * @return {object}
 */
export const searchListSubScription = (payload, queryParams, browserHistory) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.SEARCH_LIST_PLAN_START});
    return searchListSubscriptionApi(payload, queryParams).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.SEARCH_LIST_SUBSCRIPTION_SUCCESS,
              listSubscription: response.result.content,
            });
            return response.result;
          } else {
            dispatch({type: ACTION_TYPES.SEARCH_LIST_SUBSCRIPTION_FAIL});
            if (response?.status === 403) {
              dispatch(setMessageModal(modalObj(true, 'error.403')));
              browserHistory.replace(ROUTE.LAYOUT + ROUTE.HOME);
            } else {
              dispatch(setMessageModal(modalObj(true, response.message)));
            }
          }
        },
        () => dispatch(failSearchListBusiness('Internal Server Error')),
    );
  };
};

/**
 * Export list Plan
 * @param {object} payload
 * @return {object}
 */
export const getCsvExportSubscription = (payload) => {
  return (dispatch) => {
    return getCsvExportSubscriptionApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
          }
        },
        () => {
          dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
        },
    );
  };
};

/**
 * detailSubscription
 * @param {*} params
 * @param {*} browserHistory
 * @return {*}
 */
export const detailSubscription = (params, browserHistory) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.DETAIL_SUBSCRIPTION_START});
    return getSubscriptionApi(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.DETAIL_SUBSCRIPTION_SUCCESS,
              detailSubscription: response.result,
            });
            return response.result;
          } else {
            if (response?.status === 403) {
              dispatch(setMessageModal(modalObj(true, 'error.403')));
              browserHistory.replace(ROUTE.LAYOUT + ROUTE.RESERVATION_SUBSCRIPTION);
            } else {
              dispatch(setMessageModal(modalObj(true, response.message)));
            }
            dispatch({type: ACTION_TYPES.DETAIL_SUBSCRIPTION_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.DETAIL_SUBSCRIPTION_FAIL}),
    );
  };
};

/**
 * Export list Plan
 * @param {object} payload
 * @param {object} queryParams
 * @return {object}
 */
export const exportPlan = (payload, queryParams) => {
  return (dispatch) => {
    return searchListPlanApi(payload, queryParams).then(
        (response) => {
          if (response && response.status === 200) {
            return response.result?.content;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
          }
        },
        () => dispatch(setMessageModal(modalObj(true, 'Internal Server Error'))),
    );
  };
};

/**
 * updatePlansIndex
 * @param {[]} params
 * @param {object} props
 * @return {function}
 */
export const updatePlansIndex = (params, props) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_PLANS_INDEX_START,
    });
    return updatePlansIndexApi(params).then((response) => processUpdatePlansIndex(response, dispatch, props));
  };
};

/**
 *
 * @param {object} response
 * @param {function} dispatch
 * @param {object} props
 */
const processUpdatePlansIndex = (response, dispatch, props) => {
  if (response && response.status === 200) {
    dispatch(setMessageModal(modalObj(true, response.message_code)));
    dispatch({
      type: ACTION_TYPES.UPDATE_PLANS_INDEX_SUCCESS,
    });
  } else {
    dispatch(setMessageModal(modalObj(true, response?.message_code || response?.message || props.t('Api.fail'))));
    dispatch({
      type: ACTION_TYPES.UPDATE_PLANS_INDEX_FAIL,
    });
  }
};

/**
 * updateSubscriptionTransferred
 * @param {*} payload
 * @param {*} props
 * @return {Object}
 */
export const updateSubscriptionTransferred = (payload, props) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_SUB_TRANSFER_START,
    });
    return updateSubscriptionTransferredApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.UPDATE_SUB_TRANSFER_SUCCESS,
            });
            return response;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.UPDATE_SUB_TRANSFER_FAIL,
            });
          }
        },
        () => dispatch({type: ACTION_TYPES.UPDATE_SUBSCRIPTION_FAIL}),
    );
  };
};

/**
 * registerSubscription
 * @param {*} payload
 * @param {*} props
 * @return {Object}
 */
export const registerSubscription = (payload, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.REGISTER_SUBSCRIPTION_START});
    return registerSubscriptionApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, 'sub.register_success')));
            dispatch({
              type: ACTION_TYPES.REGISTER_SUBSCRIPTION_SUCCESS,
              subscriptionRegistered: response?.result,
            });
            props.history.push(ROUTE.LAYOUT + ROUTE.RESERVATION_SUBSCRIPTION_DETAILS + `/${response?.result.id}`);
            return response?.result;
          } else if (response && response.status === 400) {
            dispatch({type: ACTION_TYPES.REGISTER_SUBSCRIPTION_FAIL});
            return response;
          } else {
            dispatch({type: ACTION_TYPES.REGISTER_SUBSCRIPTION_FAIL});
            if (response.message.includes('must not be null')) {
              dispatch(setMessageModal(modalObj(true, 'api.not.null', {field: response.message.split(' ')[0]})));
              return;
            }
            dispatch(setMessageModal(modalObj(true, response.message_code)));
          }
        },
        () => {
          dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
          dispatch({type: ACTION_TYPES.REGISTER_SUBSCRIPTION_FAIL});
        },
    );
  };
};

/**
 * checkOwnerCard
 * @param {Number} userId
 * @return {*}
 */
export const checkOwnerCard = (userId) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.CHECK_USER_CARD_START});
    return checkOwnerCardApi(userId).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({type: ACTION_TYPES.CHECK_USER_CARD_SUCCESS});
          return response?.result;
        } else {
          dispatch({type: ACTION_TYPES.CHECK_USER_CARD_FAIL});
        }
      },
      () => {
        dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
        dispatch({type: ACTION_TYPES.CHECK_USER_CARD_FAIL});
      },
    );
  };
};

/**
 * addSlot
 * @param {Object} payload
 * @return {*}
 */
export const addSlot = (payload) => {
  return (dispatch) => {
    return addSlotApi(payload).then(
        (response) => {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
        },
        () => {
          dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
        },
    );
  };
};

/**
 * getNumberSubByUser
 * @param {Object} payload
 * @return {*}
 */
export const getNumberSubByUser = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_NUMBER_SUB_BY_USER_START});
    return getNumberSubByUserApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({type: ACTION_TYPES.GET_NUMBER_SUB_BY_USER_SUCCESS});
          return response?.result;
        } else {
          dispatch({type: ACTION_TYPES.GET_NUMBER_SUB_BY_USER_FAIL});
        }
      },
      () => {
        dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
        dispatch({type: ACTION_TYPES.GET_NUMBER_SUB_BY_USER_FAIL});
      },
    );
  };
};

/**
 * deleteShift
 * @param {object} payload
 * @return {*}
 */
export const deleteShift = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.DELETE_SHIFT_START});
    return deleteShiftApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({type: ACTION_TYPES.DELETE_SHIFT_SUCCESS});
            dispatch(setMessageModal(modalObj(true, response.message)));
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
            dispatch({type: ACTION_TYPES.DELETE_SHIFT_FAIL});
          }
        },
        () => {
          dispatch({type: ACTION_TYPES.DELETE_SHIFT_FAIL});
          dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
        },
    );
  };
};

/**
 * deleteCreditCard
 * @param {Number} cardId
 * @return {*}
 */
export const deleteCreditCard = (cardId) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.DELETE_CREDIT_CARD_START});
    return deleteCreditCardApi(cardId).then(
        (response) => {
          dispatch(setMessageModal(modalObj(true, response.message)));
          if (response && response.status === 200) {
            dispatch({type: ACTION_TYPES.DELETE_CREDIT_CARD_SUCCESS});
            return response?.result;
          } else {
            dispatch({type: ACTION_TYPES.DELETE_CREDIT_CARD_FAIL});
          }
        },
        () => {
          dispatch({type: ACTION_TYPES.DELETE_CREDIT_CARD_FAIL});
          dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
        },
    );
  };
};

/**
 * addTicket
 * @param {object} payload
 * @return {*}
 */
export const addTicket = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.ADD_TICKET_START});
    return addTicketApi(payload).then(
        (response) => {
          if (response) {
            dispatch({type: ACTION_TYPES.ADD_TICKET_SUCCESS});
            return response;
          } else {
            dispatch({type: ACTION_TYPES.ADD_TICKET_FAIL});
          }
        },
        () => {
          dispatch({type: ACTION_TYPES.ADD_TICKET_FAIL});
          dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
        },
    );
  };
};

/**
 * checkMemberCode
 * @param {object} payload
 * @return {*}
 */
export const checkMemberCode = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.CHECK_MEMBER_CODE_START});
    return checkMemberCodeApi(payload).then(
        (response) => {
          if (response) {
            dispatch({type: ACTION_TYPES.CHECK_MEMBER_CODE_SUCCESS});
            return response;
          } else {
            dispatch({type: ACTION_TYPES.CHECK_MEMBER_CODE_FAIL});
          }
        },
        () => {
          dispatch({type: ACTION_TYPES.CHECK_MEMBER_CODE_FAIL});
          dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
        },
    );
  };
};

/**
 * getSendMailList
 * @param {Number} id
 * @return {*}
 */
export const getSendMailList = (id) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_MAIL_LIST_START});
    return getSendMailListApi(id).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({type: ACTION_TYPES.GET_MAIL_LIST_SUCCESS});
            return response?.result;
          } else {
            dispatch({type: ACTION_TYPES.GET_MAIL_LIST_FAIL});
            dispatch(setMessageModal(modalObj(true, response.message)));
          }
        },
        () => {
          dispatch({type: ACTION_TYPES.GET_MAIL_LIST_FAIL});
          dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
        },
    );
  };
};

/**
 * getRideAdditionalHistory
 * @param {Number} id
 * @return {*}
 */
 export const getRideAdditionalHistory = (id) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_RIDE_ADDITIONAL_HISTORY_START});
    return getRideAdditionalHistoryApi(id).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_RIDE_ADDITIONAL_HISTORY_SUCCESS,
              ridesAdditionalHistory: response?.result,
            });
            return response?.result;
          } else {
            dispatch({type: ACTION_TYPES.GET_RIDE_ADDITIONAL_HISTORY_FAIL});
            dispatch(setMessageModal(modalObj(true, response.message_code)));
          }
        },
        () => {
          dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
          dispatch({type: ACTION_TYPES.GET_RIDE_ADDITIONAL_HISTORY_FAIL});
        },
    );
  };
};

/**
 * addRideAdditional
 * @param {Object} payload
 * @return {*}
 */
 export const addRideAdditional = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.ADD_RIDE_ADDITIONAL_START});
    return addRidesApi(payload).then(
        (response) => {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.ADD_RIDE_ADDITIONAL_SUCCESS,
            });
            return response?.result;
          } else {
            dispatch({type: ACTION_TYPES.ADD_RIDE_ADDITIONAL_FAIL});
          }
        },
        () => {
          dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
          dispatch({type: ACTION_TYPES.ADD_RIDE_ADDITIONAL_FAIL});
        },
    );
  };
};

/**
 * getAdditionNumberRide
 * @param {Object} payload
 * @return {*}
 */
export const getAdditionNumberRide = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_ADDITIONAL_NUMBER_RIDE_START});
    return additionNumberRideApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_ADDITIONAL_NUMBER_RIDE_SUCCESS,
            additionNumberRide: response.result,
          });
          return response?.result;
        } else {
          dispatch({type: ACTION_TYPES.GET_ADDITIONAL_NUMBER_RIDE_FAIL});
          dispatch(setMessageModal(modalObj(true, response.message_code)));
        }
      },
      () => {
        dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
        dispatch({type: ACTION_TYPES.GET_ADDITIONAL_NUMBER_RIDE_FAIL});
      },
    );
  };
};

/**
 * getTicketDistributionHistory
 * @param {Object} payload
 * @return {*}
 */
export const getTicketDistributionHistory = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_TICKET_DISTRIBUTION_HISTORY_START});
    return getTicketDistributionHistoryApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_TICKET_DISTRIBUTION_HISTORY_SUCCESS,
          });
          return response;
        } else {
          dispatch({type: ACTION_TYPES.GET_TICKET_DISTRIBUTION_HISTORY_FAIL});
          dispatch(setMessageModal(modalObj(true, response?.message_code)));
        }
      },
      () => {
        dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
        dispatch({type: ACTION_TYPES.GET_TICKET_DISTRIBUTION_HISTORY_FAIL});
      },
    );
  };
};

/**
 * getDataMemberTicket
 * @param {Object} payload
 * @return {*}
 */
export const deleteTicketDistributionHistory = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.DELETE_TICKET_DISTRIBUTION_HISTORY_START});
    return deleteTicketDistributionHistoryApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.DELETE_TICKET_DISTRIBUTION_HISTORY_SUCCESS,
          });
          dispatch(setMessageModal(modalObj(true, response?.message_code)));
          return response;
        } else {
          dispatch({type: ACTION_TYPES.DELETE_TICKET_DISTRIBUTION_HISTORY_FAIL});
          dispatch(setMessageModal(modalObj(true, response?.message_code)));
        }
      },
      () => {
        dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
        dispatch({type: ACTION_TYPES.DELETE_TICKET_DISTRIBUTION_HISTORY_FAIL});
      },
    );
  };
};

/**
 * getDataMemberTicket
 * @param {Object} payload
 * @return {*}
 */
export const getDataMemberTicket = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_MEMBER_TICKET_START});
    return getDataMemberTicketApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_MEMBER_TICKET_SUCCESS,
          });
          return response;
        } else {
          dispatch({type: ACTION_TYPES.GET_MEMBER_TICKET_FAIL});
          dispatch(setMessageModal(modalObj(true, response?.message_code)));
        }
      },
      () => {
        dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
        dispatch({type: ACTION_TYPES.GET_MEMBER_TICKET_FAIL});
      },
    );
  };
};

/**
 * getListPlanCode
 * @param {*} business_referral_id
 * @param {*} geofence_id
 * @return {Promise}
 */
export const getListPlanCode = (business_referral_id, geofence_id) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.LIST_PLAN_CODE_START,
    });
    return getListPlanCodeApi(business_referral_id, geofence_id).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.LIST_PLAN_CODE_SUCCESS,
            listPlanCode: response.result,
          });
          return response.result;
        } else {
          dispatch({
            type: ACTION_TYPES.LIST_PLAN_CODE_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.LIST_PLAN_CODE_FAIL,
        }),
    );
  };
};

/**
 * createBusinessPartner
 * @param {*} params
 * @param {*} props
 * @return {Promise}
 */
export const createBusinessPartner = (params, props) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.CREATE_BUSINESS_PARTNER_START,
    });
    return createBusinessPartnerApi(params).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch(setMessageModal(modalObj(true, 'Api.success')));
          dispatch({
            type: ACTION_TYPES.CREATE_BUSINESS_PARTNER_SUCCESS,
          });
          props.history.push(ROUTE.LAYOUT + ROUTE.BUSINESS_PARTNER);
        } else {
          dispatch({
            type: ACTION_TYPES.CREATE_BUSINESS_PARTNER_FAIL,
          });
        }
      },
      () =>
      dispatch({
        type: ACTION_TYPES.CREATE_BUSINESS_PARTNER_FAIL,
      }),
    );
  };
};

/*
 * searchReferrers
 * @param {Object} params
 * @return {Function}
 */
export const searchReferrers = (params) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.SEARCH_REFERRERS_START});
    return searchReferrersApi(params).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.SEARCH_REFERRERS_SUCCESS,
            listReferrers: response.result,
          });
          return response?.result;
        } else {
          dispatch({type: ACTION_TYPES.SEARCH_REFERRERS_FAIL});
          dispatch(setMessageModal(modalObj(true, response?.message_code)));
        }
      },
      () => {
        dispatch({type: ACTION_TYPES.SEARCH_REFERRERS_FAIL});
        dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
      },
    );
  };
};

/**
 * updateBusinessPartner
 * @param {*} params
 * @param {*} props
 * @return {Promise}
 */
export const updateBusinessPartner = (params, props) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_BUSINESS_PARTNER_START,
    });
    return updateBusinessPartnerApi(params).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch(setMessageModal(modalObj(true, 'Api.success')));
          dispatch({
            type: ACTION_TYPES.UPDATE_BUSINESS_PARTNER_SUCCESS,
          });
        } else if (response.message_code !== 'referral.code.already.exists') {
          dispatch({
            type: ACTION_TYPES.UPDATE_BUSINESS_PARTNER_FAIL,
          });
        }
      },
      () =>
      dispatch({
        type: ACTION_TYPES.UPDATE_BUSINESS_PARTNER_FAIL,
      }),
    );
  };
};

/**
 * getDetailBusinessPartner
 * @param {Number} id
 * @return {Promise}
 */
export const getDetailBusinessPartner = (id, props) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_DETAIL_BUSINESS_PARTNER_START,
    });
    return getDetailBusinessPartnerApi(id).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_DETAIL_BUSINESS_PARTNER_SUCCESS,
            listDetailBusinessPartner: response.result,
          });
          return response.result;
        } else {
          dispatch({
            type: ACTION_TYPES.GET_DETAIL_BUSINESS_PARTNER_FAIL,
          });
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          if (response.message_code === 'permission.denied') {
            backForwardRouter(props, ROUTE.LAYOUT + ROUTE.HOME);
          }
        }
      },
      () =>
      dispatch({
        type: ACTION_TYPES.GET_DETAIL_BUSINESS_PARTNER_FAIL,
      }),
    );
  };
};

/**
 * getDetailBusinessPartner
 * @param {Number} id
 * @return {Promise}
 */
 export const deleteBusinessPartner = (id) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.DELETE_BUSINESS_PARTNER_START,
    });
    return deleteBusinessPartnerApi(id).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch(setMessageModal(modalObj(true, 'Api.success')));
          dispatch({
            type: ACTION_TYPES.DELETE_BUSINESS_PARTNER_SUCCESS,
          });
        } else {
          dispatch({
            type: ACTION_TYPES.DELETE_BUSINESS_PARTNER_FAIL,
          });
        }
      },
      () =>
      dispatch({
        type: ACTION_TYPES.DELETE_BUSINESS_PARTNER_FAIL,
      }),
    );
  };
};

/**
 * checkReferrerCode
 * @param {*} id
 * @return {*}
 */
 export const checkReferrerCode = (id) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.CHECK_REFERRER_CODE_START});
    return checkReferrerCodeApi(id).then(
        (response) => {
          if (response) {
            dispatch({type: ACTION_TYPES.CHECK_REFERRER_CODE_SUCCESS});
          } else {
            dispatch({type: ACTION_TYPES.CHECK_REFERRER_CODE_FAIL});
          }
          return response;
        },
        () => {
          dispatch({type: ACTION_TYPES.CHECK_REFERRER_CODE_FAIL});
        },
    );
  };
};

/*
 * getMembers
 * @param {Number} referrerCode
 * @param {Object} params
 * @return {Function}
 */
export const getMembers = (referrerCode, params) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_MEMBERS_START});
    return getMembersApi(referrerCode, params).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_MEMBERS_SUCESS,
            listMembers: response.result,
          });
          return response.result;
        } else {
          dispatch({type: ACTION_TYPES.GET_MEMBERS_FAIL});
          dispatch(setMessageModal(modalObj(true, response.message_code)));
        }
      },
      () => {
        dispatch({type: ACTION_TYPES.GET_MEMBERS_FAIL});
        dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
      },
    );
  };
};
