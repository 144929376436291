export const ACTION_TYPES = {
  GET_MAIL_CATEGORY_START: 'GET_MAIL_CATEGORY_START',
  GET_MAIL_CATEGORY_SUCCESS: 'GET_MAIL_CATEGORY_SUCCESS',
  GET_MAIL_CATEGORY_FAIL: 'GET_MAIL_CATEGORY_FAIL',

  GET_MAIL_DEPARTMENT_START: 'GET_MAIL_DEPARTMENT_START',
  GET_MAIL_DEPARTMENT_SUCCESS: 'GET_MAIL_DEPARTMENT_SUCCESS',
  GET_MAIL_DEPARTMENT_FAIL: 'GET_MAIL_DEPARTMENT_FAIL',

  GET_MAIL_MAPPING_START: 'GET_MAIL_MAPPING_START',
  GET_MAIL_MAPPING_SUCCESS: 'GET_MAIL_MAPPING_SUCCESS',
  GET_MAIL_MAPPING_FAIL: 'GET_MAIL_MAPPING_FAIL',

  GET_LIST_MAIL_TEMPLATE_START: 'GET_LIST_MAIL_TEMPLATE_START',
  GET_LIST_MAIL_TEMPLATE_SUCCESS: 'GET_LIST_MAIL_TEMPLATE_SUCCESS',
  GET_LIST_MAIL_TEMPLATE_FAIL: 'GET_LIST_MAIL_TEMPLATE_FAIL',

  GET_DETAIL_MAIL_SETTING_START: 'GET_DETAIL_MAIL_SETTING_START',
  GET_DETAIL_MAIL_SETTING_SUCCESS: 'GET_DETAIL_MAIL_SETTING_SUCCESS',
  GET_DETAIL_MAIL_SETTING_FAIL: 'GET_DETAIL_MAIL_SETTING_FAIL',

  UPDATE_MAIL_SETTING_START: 'UPDATE_MAIL_SETTING_START',
  UPDATE_MAIL_SETTING_SUCCESS: 'UPDATE_MAIL_SETTING_SUCCESS',
  UPDATE_MAIL_SETTING_FAIL: 'UPDATE_MAIL_SETTING_FAIL',
};

const initialState = {
  isLoading: false,
  mailsCategoryList: [],
  mailsDepartmentList: [],
  mailsMappingList: [],
};

/**
 * function reduce
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.GET_LIST_MAIL_TEMPLATE_START:
    case ACTION_TYPES.GET_DETAIL_MAIL_SETTING_START:
    case ACTION_TYPES.UPDATE_MAIL_SETTING_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.GET_LIST_MAIL_TEMPLATE_SUCCESS:
    case ACTION_TYPES.GET_LIST_MAIL_TEMPLATE_FAIL:
    case ACTION_TYPES.GET_DETAIL_MAIL_SETTING_SUCCESS:
    case ACTION_TYPES.GET_DETAIL_MAIL_SETTING_FAIL:
    case ACTION_TYPES.UPDATE_MAIL_SETTING_SUCCESS:
    case ACTION_TYPES.UPDATE_MAIL_SETTING_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.GET_MAIL_CATEGORY_START:
    case ACTION_TYPES.GET_MAIL_DEPARTMENT_START:
    case ACTION_TYPES.GET_MAIL_MAPPING_START:
    case ACTION_TYPES.GET_MAIL_CATEGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        mailsCategoryList: action.mailsCategoryList,
      };
    case ACTION_TYPES.GET_MAIL_DEPARTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        mailsDepartmentList: action.mailsDepartmentList,
      };
    case ACTION_TYPES.GET_MAIL_MAPPING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        mailsMappingList: action.mailsMappingList,
      };
    case ACTION_TYPES.GET_MAIL_CATEGORY_FAIL:
      return {
        ...state,
        isLoading: false,
        mailsCategoryList: [],
      };
    case ACTION_TYPES.GET_MAIL_DEPARTMENT_FAIL:
      return {
        ...state,
        isLoading: false,
        mailsDepartmentList: [],
      };
    case ACTION_TYPES.GET_MAIL_MAPPING_FAIL:
      return {
        ...state,
        isLoading: false,
        mailsMappingList: [],
      };
    default:
      return state;
  }
}
