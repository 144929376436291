import React, {Component} from 'react';

import {
  Card,
  Container,
  Grid,
  FormGroup,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Checkbox,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Modal,
  Backdrop,
  Paper,
  Dialog,
} from '@material-ui/core';
import {NotInterested, Replay, Send} from '@material-ui/icons';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import GetAppIcon from '@material-ui/icons/GetApp';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {ACCOUNT_STATUS, ROWS_PER_PAGE_OPTIONS, MAX_SIZE_OUTPUT, EXPORT_LIMIT_NUMBER_ROWS, EXPORT_ALERT_NUMBER_ROWS, ROUTE, PERMISSION_ACTIONS} from '../../../common/constant';
import CSVExporter from '../../../components/CSVExporter';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getRoleList, searchAccountList, failSearchAccountListAction, resendInvitation, restartAccounts, suspendAccounts, exportAccount} from '../../../stores/account/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {onChangeTextField, changeUrlParams, getUrlParams, getQueryStringFromObject} from '../../../utils/common';
import {displayDateTime} from '../../../utils/datetime';
import {modalObj} from '../../../utils/modal';
import AccountUpdate from '../update/index';

/**
 * Account Management view
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.modalType = {
      NONE: -1,
      RESEND_INVITATION: 0,
      RESTART_ACCOUNTS: 1,
      SUSPEND_ACCOUNTS: 2,
    };
    this.state = {
      userStatus: ACCOUNT_STATUS[0].name,
      username: '',
      email: '',
      supplierName: '',
      companyName: '',
      mobile: '',
      openModal: false,
      detailId: null,
      webRoleIdList: [],
      flag: false,
      searchFailMsg: '',
      isSearch: false,
      result: [],
      selectedRows: [],
      modalType: this.modalType.NONE,
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,
      flagExport: false,
      confirmedExport: false,
      latestSearchParams: null,
    };
    this.handleButtonOk = this.handleButtonOk.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);

    this.exportCSVRef = React.createRef();
    const {t} = this.props;
    this.exportHeaders = [
      {label: t('accountManagement.contactName'), key: 'person_charge'},
      {label: t('common.email'), key: 'email'},
      {label: t('accountManagement.accountType'), key: 'willer_web_role_name'},
      {label: t('accountManagement.author'), key: 'authority'},
      {label: t('accountManagement.businessName'), key: 'supplier_name'},
      {label: t('common.phoneNumber'), key: 'mobile'},
      {label: t('accountManagement.status'), key: 'status'},
      {label: t('accountManagement.registrationDate'), key: 'created_date'},
    ];
  }

  /**
   * componentWillUnmount
   */
  componentWillUnmount() {
    this.props.resetAccountList();
  }

  /**
   * reset search condition
   */
  reset = () => {
    this.setState({
      userStatus: ACCOUNT_STATUS[0].name,
      username: '',
      email: '',
      supplierName: '',
      companyName: '',
      mobile: '',
      webRoleIdList: [],
    });
  };

  /**
   * handleButtonOk
   */
  handleButtonOk() {
    this.setState({
      flag: true,
    });
    this.props.onClickClose();
  }

  /**
   * handleClickClose
   */
  handleClickClose() {
    this.props.onClickClose();
  }

  /**
   * searchAccount
   * @param {bool} reset
   */
  searchAccount = (reset) => {
    const canSearch = this.props.actions.includes(PERMISSION_ACTIONS.SEARCH);
    if (!canSearch) return;
    this.setState({selectedRows: []});
    const {userStatus, username, email, supplierName, companyName, mobile, webRoleIdList} = this.state;
    const webRoleCodes = webRoleIdList.filter((role) => role !== undefined);

    const payload = {
      user_status: userStatus ? userStatus : null,
      username: username ? username.trim() : null,
      email: email ? email.trim() : null,
      supplier_name: supplierName ? supplierName.trim() : null,
      company_name: companyName ? companyName.trim() : null,
      mobile: mobile ? mobile.trim() : null,
      web_role_codes: webRoleCodes,
    };
    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const {currentPage, rowsPerPage} = this.state;
    const queryParams = reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage};

    this.props.searchAccountList(payload, queryParams, this.props).then((response) => {
      if (!response) {
        response = [];
      }
      this.setState(
          {
            isSearch: true,
            result: response.content,
            totalRows: response?.totalSize,
            latestSearchParams: payload,
          },
          () => {
            const {userStatus, username, email, supplierName, companyName, mobile, webRoleIdList, currentPage, rowsPerPage} = this.state;
            // Apply changed params into url
            const queryParamsToChange = {
              userStatus,
              username,
              email,
              supplierName: supplierName && JSON.stringify(supplierName),
              companyName,
              mobile,
              webRoleIdList,
              currentPage: String(currentPage),
              rowsPerPage,
            };
            changeUrlParams(queryParamsToChange);
            const newSearchParams = getQueryStringFromObject(queryParamsToChange);
            // Save search params into state in order to pass to next page
            this.setState({searchParams: newSearchParams});
          },
      );
    });
  };

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.searchAccount);
  };

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getRoleList();
    if (this.props.location.search) {
      this.setState(getUrlParams(this.props.location.search));
    }
  }

  /**
   * componentDidUpdate
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.props.actions !== prevProps.actions) {
      const canSearch = this.props.actions.includes(PERMISSION_ACTIONS.SEARCH);
      if (canSearch && this.props.location.search) {
        this.setState(getUrlParams(this.props.location.search), this.searchAccount);
      }
    }
  }

  /**
   * Change account type
   * @param {string} value,
   * @param {int} item
   */
  changeAccountType = (value, item) => {
    const webRoleIdsCopy = this.state.webRoleIdList;
    const index = webRoleIdsCopy.indexOf(item);
    value ? webRoleIdsCopy.push(item) : webRoleIdsCopy.splice(index, 1);

    this.setState({
      webRoleIdList: webRoleIdsCopy,
    });
  };

  /**
   * handle detail
   * @param {int} id
   */
  handleDetail = (id) => {
    this.setState({
      openModal: true,
      detailId: id,
    });
  };

  /**
   * handleButtonClose
   */
  handleButtonClose = () => {
    this.setState({
      openModal: false,
    });
  };

  /**
   *
   * closeModalAction
   */
  closeModalAction = () => {
    this.setState({flag: false});
  };

  /**
   * handleActionModal
   */
  handleActionModal = () => {
    this.setState({flag: false});
    switch (this.state.modalType) {
      case this.modalType.RESEND_INVITATION:
        this.handleResendInvitation();
        break;
      case this.modalType.RESTART_ACCOUNTS:
        this.handleRestartAccounts();
        break;
      case this.modalType.SUSPEND_ACCOUNTS:
        this.handleSuspendAccounts();
        break;
      default:
        break;
    }
  };

  /**
   * handleSelectAccount
   * @param {number} email
   * @param {bool} checked
   */
  handleSelectAccount = (email, checked) => {
    const selectedRows = this.state.selectedRows;
    const index = selectedRows.indexOf(email);

    checked ? index < 0 && selectedRows.push(email) : index >= 0 && selectedRows.splice(index, 1);

    this.setState({selectedRows});
  };

  /**
   * handleResendInvitation
   */
  handleClickButtonResendInvitation = () => {
    this.setState({
      flag: true,
      modalType: this.modalType.RESEND_INVITATION,
      message: 'accountManagement.resendInvitation',
    });
  };

  /**
   * handleClickButtonRestartAccounts
   */
  handleClickButtonRestartAccounts = () => {
    this.setState({
      flag: true,
      modalType: this.modalType.RESTART_ACCOUNTS,
      message: 'accountManagement.restartAccounts',
    });
  };

  /**
   * handleClickButtonStopAccounts
   */
  handleClickButtonSuspendAccounts = () => {
    this.setState({
      flag: true,
      modalType: this.modalType.SUSPEND_ACCOUNTS,
      message: 'accountManagement.suspendAccounts',
    });
  };

  /**
   * handleResendInvitation
   */
  handleResendInvitation = () => {
    const payload = this.state.selectedRows;
    this.props.resendInvitation(payload, this.props).then(this.searchAccount);
  };

  /**
   * handleRestartAccounts
   */
  handleRestartAccounts = () => {
    const payload = {email: this.state.selectedRows};
    this.props.restartAccounts(payload, this.props).then(this.searchAccount);
  };

  /**
   * handleStopAccounts
   */
  handleSuspendAccounts = () => {
    const payload = {email: this.state.selectedRows};
    this.props.suspendAccounts(payload, this.props).then(this.searchAccount);
  };
  /**
   * Fetch data to export
   */
  fetchDataExport = async () => {
    const queryParams = {page: 0, size: MAX_SIZE_OUTPUT};
    const dataExport = await this.props.exportAccount(this.state.latestSearchParams, queryParams);
    const convertedData = dataExport.map((row, index) => {
      row.created_date = displayDateTime(row.created_date);
      return row;
    });
    this.setState({confirmedExport: false});
    return convertedData;
  };

  /**
   * alertExportCSV
   */
  alertExportCSV = () => {
    const {t} = this.props;
    if (this.state.totalRows > EXPORT_LIMIT_NUMBER_ROWS) {
      this.props.setMessageModal(modalObj(true, t('messageCode.exportAlertLimit')));
    } else {
      const message = t('messageCode.exportAlert');
      this.setState({flagExport: true, message});
    }
  };

  /**
   * handleButtonExportOk
   */
  handleButtonExportOk = () => {
    this.setState({flagExport: false, confirmedExport: true}, () => {
      this.exportCSVRef.current.onClickExport();
    });
  };

  /**
   * handleButtonExportCancel
   */
  handleButtonExportCancel = () => {
    this.setState({flagExport: false});
  };

  /**
   * Render component
   * @return {object}
   */
  render() {
    const {account, t, actions} = this.props;
    const userStatus = this.state.result.length > 0 ? this.state.result[0].status : '';

    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canCSVOutput: actions.includes(PERMISSION_ACTIONS.CSV_OUTPUT),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
      canSuspendAccount: actions.includes(PERMISSION_ACTIONS.ACCOUNT_SUSPENSION),
      canRestartAccount: actions.includes(PERMISSION_ACTIONS.ACCOUNT_RESTART),
      canResendInvitation: actions.includes(PERMISSION_ACTIONS.RESEND_INVITATION_EMAIL),
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('accountManagement.title')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          <LoadingOverlay active={account.isLoading || account.isLoadingRole} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                {permission.canSearch && (
                  <>
                    <br></br>
                    <Paper className="search_table">
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                          {t('accountManagement.titleSearch')}
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('accountManagement.accountType')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={10}>
                          <FormGroup row>
                            {this.props.account?.roleList?.map((item, index) => {
                              const checked = this.state.webRoleIdList.indexOf(item.role_code) > -1;
                              return (
                                <FormControlLabel
                                  key={index}
                                  labelPlacement="end"
                                  control={
                                    <Checkbox
                                      key={index}
                                      checked={checked}
                                      onChange={(event) => this.changeAccountType(event.target.checked, item.role_code)}
                                      className="checkbox_radio"
                                    />
                                  }
                                  label={item.role_permission_name}
                                />
                              );
                            })}
                          </FormGroup>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('accountManagement.status')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <RadioGroup row aria-label="gender" name="gender1" value={this.state.userStatus}>
                            {ACCOUNT_STATUS.map((item, index) => {
                              return (
                                <FormControlLabel
                                  key={index}
                                  value={item.name}
                                  labelPlacement="end"
                                  onClick={() =>
                                    this.setState({
                                      userStatus: item.name,
                                    })
                                  }
                                  control={<Radio className="checkbox_radio" />}
                                  label={<span className="font_size_small_regular">{t(`${item.i18n}`)}</span>}
                                />
                              );
                            })}
                          </RadioGroup>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('accountManagement.contactName')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            className="field_size_10 field_min_size_350"
                            margin="dense"
                            placeholder={t('accountManagement.contactName')}
                            name="username"
                            inputProps={{maxLength: 256}}
                            variant="outlined"
                            value={this.state.username}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.email')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            className="field_size_20 field_min_size_350"
                            margin="dense"
                            placeholder={t('common.email')}
                            name="email"
                            inputProps={{maxLength: 256}}
                            variant="outlined"
                            onChange={(event) => onChangeTextField(this, event)}
                            value={this.state.email}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('accountManagement.businessName')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            className="field_size_20 field_min_size_350"
                            margin="dense"
                            placeholder={t('accountManagement.businessName')}
                            name="supplierName"
                            inputProps={{maxLength: 256}}
                            variant="outlined"
                            onChange={(event) => onChangeTextField(this, event)}
                            value={this.state.supplierName}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('accountManagement.companyName')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            className="field_size_20 field_min_size_350"
                            margin="dense"
                            placeholder={t('accountManagement.companyName')}
                            name="companyName"
                            inputProps={{maxLength: 256}}
                            variant="outlined"
                            onChange={(event) => onChangeTextField(this, event)}
                            value={this.state.companyName}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.phoneNumber')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="mobile"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('common.phoneNumber')}
                            inputProps={{maxLength: 256}}
                            variant="outlined"
                            value={this.state.mobile}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </>
                )}
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.reset()} endIcon={<RotateLeftIcon />}>
                        {t('common.btnResetSearch')}
                      </Button>
                    )}
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {permission.canCSVOutput && (
                      <>
                        <div className={this.state.totalRows <= EXPORT_ALERT_NUMBER_ROWS || this.state.confirmedExport ? '' : 'hidden'}>
                          <CSVExporter
                            ref={this.exportCSVRef}
                            disabled={this.state.totalRows <= 0 || !this.state.latestSearchParams}
                            headers={this.exportHeaders}
                            fetchAction={this.fetchDataExport}
                            isFetchAsync={true}
                            screenName={t('accountManagement.title')}
                          />
                        </div>
                        {this.state.totalRows >= EXPORT_ALERT_NUMBER_ROWS && !this.state.confirmedExport && (
                          <Button
                            onClick={this.alertExportCSV}
                            disabled={this.state.totalRows <= 0}
                            color="primary"
                            variant="contained"
                            className="button_margin"
                            endIcon={<GetAppIcon className="csv-exporter-icon" />}
                          >
                            {t('calendar.csv_output')}
                          </Button>
                        )}
                      </>
                    )}

                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin" onClick={this.searchAccount} endIcon={<SearchIcon />}>
                        {t('common.btnSearch')}
                      </Button>
                    )}
                  </Grid>
                </Grid>

                <br></br>
              </Container>
            </Card>
          </LoadingOverlay>
          <br></br>
          {this.state.isSearch && (
            <Card raised>
              <Container maxWidth="xl">
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={6}>
                    <h3>
                      {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                    </h3>
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={6}>
                    {permission.canSuspendAccount && userStatus === ACCOUNT_STATUS[0].name ? (
                      <Button
                        color="primary"
                        variant="contained"
                        style={{color: 'white'}}
                        className="button_margin button_color"
                        onClick={this.handleClickButtonSuspendAccounts}
                        endIcon={<NotInterested />}
                        disabled={this.state.selectedRows.length === 0}
                      >
                        {t('accountManagement.accountSuspension')}
                      </Button>
                    ) : permission.canRestartAccount && userStatus === ACCOUNT_STATUS[1].name ? (
                      <Button
                        color="primary"
                        variant="contained"
                        className="button_margin"
                        endIcon={<Replay />}
                        onClick={this.handleClickButtonRestartAccounts}
                        disabled={this.state.selectedRows.length === 0}
                      >
                        {t('accountManagement.accountRestart')}
                      </Button>
                    ) : permission.canResendInvitation && userStatus === ACCOUNT_STATUS[2].name ? (
                      <Button
                        color="primary"
                        variant="contained"
                        className="button_margin button_color_green"
                        onClick={this.handleClickButtonResendInvitation}
                        endIcon={<Send />}
                        disabled={this.state.selectedRows.length === 0}
                      >
                        {t('accountManagement.resendInviteEmail')}
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
                <LoadingOverlay active={account.isLoading || account.isLoadingRole} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                  {this.state.result.length > 0 && (
                    <div className="scroll_area_700">
                      <Table size="small" aria-label="table" stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell className="width_5"></TableCell>
                            <TableCell className="width_10">{t('accountManagement.contactName')}</TableCell>
                            <TableCell className="width_10">{t('common.email')}</TableCell>
                            <TableCell className="width_15">{t('accountManagement.accountType')}</TableCell>
                            <TableCell className="width_10">{t('accountManagement.author')}</TableCell>
                            <TableCell className="width_10">{t('accountManagement.businessName')}</TableCell>
                            <TableCell className="width_10">{t('common.phoneNumber')}</TableCell>
                            <TableCell className="width_10">{t('accountManagement.status')}</TableCell>
                            <TableCell className="width_20">{t('accountManagement.registrationDate')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.result.map((row, index) => {
                            const isSelected = this.state.selectedRows.indexOf(row.email) >= 0;
                            const handleDetail = permission.canEdit ? () => this.handleDetail(row.id) : () => {};
                            return (
                              <TableRow key={index} hover className="cursor_pointer">
                                <TableCell align="center">
                                  <Checkbox
                                    color="primary"
                                    className="checkbox_radio"
                                    checked={isSelected}
                                    onClick={() => this.handleSelectAccount(row.email, !isSelected)}
                                  ></Checkbox>
                                </TableCell>
                                <TableCell onClick={handleDetail} scope="row">
                                  {row.person_charge}
                                </TableCell>
                                <TableCell onClick={handleDetail}>{row.email}</TableCell>
                                <TableCell onClick={handleDetail}>{row.willer_web_role_name}</TableCell>
                                <TableCell onClick={handleDetail}>{row.authority}</TableCell>
                                <TableCell onClick={handleDetail}>{row.supplier_name}</TableCell>
                                <TableCell onClick={handleDetail}>{row.mobile}</TableCell>
                                <TableCell onClick={handleDetail}>{row.status}</TableCell>
                                <TableCell onClick={handleDetail}>{displayDateTime(row.created_date)}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </div>
                  )}
                  <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                </LoadingOverlay>
              </Container>
              <br></br>
            </Card>
          )}
          <br></br>
          <Dialog
            open={this.state.flag}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <>
              <SelectModal onClickOk={this.handleActionModal} onClickCancel={this.closeModalAction} message={t(this.state.message)}></SelectModal>
            </>
          </Dialog>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.openModal}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1000',
            }}
          >
            <><AccountUpdate
              canUpdate={permission.canUpdate}
              detailId={this.state.detailId}
              onClickClose={this.handleButtonClose}
              handleSearch={this.searchAccount}></AccountUpdate></>
          </Modal>
          {/* Export alert modal */}
          <Dialog
            open={this.state.flagExport}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <>
              <SelectModal
                onClickOk={this.handleButtonExportOk}
                onClickCancel={this.handleButtonExportCancel}
                okButtonText={t('common.btnYes')}
                cancelButtonText={t('common.btnNo')}
                message={this.state.message}
              ></SelectModal>
            </>
          </Dialog>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRoleList: () => dispatch(getRoleList()),
    searchAccountList: (input, queryParams, props) => dispatch(searchAccountList(input, queryParams, props)),
    exportAccount: (payload, queryParams, props) => dispatch(exportAccount(payload, queryParams)),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    resetAccountList: () => dispatch(failSearchAccountListAction()),
    resendInvitation: (payload, props) => dispatch(resendInvitation(payload, props)),
    restartAccounts: (payload, props) => dispatch(restartAccounts(payload, props)),
    suspendAccounts: (payload, props) => dispatch(suspendAccounts(payload, props)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
