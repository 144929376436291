import React, {Component} from 'react';

import {Grid, Button, TextField, Paper, Card, Container, FormControl, Dialog, FormHelperText, FormGroup, FormControlLabel} from '@material-ui/core';
import {Select} from '@material-ui/core';
import {MenuItem} from '@material-ui/core';
import {Checkbox} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {GEOFENCE_STATUS, ROUTE, ROWS_PER_PAGE_OPTIONS, PERMISSION_ACTIONS} from '../../../common/constant';
import Map from '../../../components/map/MapPoint';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getDetailAreaGeofenceApi, updateAreaGeofenceApi} from '../../../services/areaGeofenceServices';
import {getAllCountryCode, getAllGeofence} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {onChangeTextField, onChangeSelect, backForwardRouter, getListAllGeofenceDetail} from '../../../utils/common';
import {modalObj} from '../../../utils/modal';
import {isRoleGlobal} from '../../../utils/role';

/**
 * Area Geofence Form
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      country_id: !isRoleGlobal() ? props.principal.country_id : '',
      geofence_ids: [],
      status: true,
      listGeofenceAll: [],
      area: null,
      center_point_lat: '',
      center_point_lon: '',

      isSubmitForm: false,
      flg: false,
      message: '',
      openModal: false,
      isLoading: false,
      dataDetail: [],
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * Change geofences
   * @param {string} value,
   * @param {int} item
   */
  changeGeofences = (value, item) => {
    const geofence_ids = this.state.geofence_ids;
    const index = geofence_ids.indexOf(item);
    if (value) {
      geofence_ids.push(item);
    } else {
      geofence_ids.splice(index, 1);
    }
    this.setState({
      geofence_ids,
    });
  };

  /**
   * onChangeCountry
   * @param {*} value
   */
  onChangeCountry = (value) => {
    this.setState({
      country_id: value,
      geofence_ids: this.state.dataDetail?.country_id === value ? this.state.dataDetail?.geofences : [],
    });
  };

  /**
   * handleButtonModalOk
   */
  handleButtonModalOk = () => {
    this.setState({
      flg: false,
      isLoading: true,
    });
    const {country_id, geofence_ids, area, status} = this.state;
    const payload = {
      country_id,
      geofences: geofence_ids,
      name: area,
      enable: status === true,
    };
    if (this.props.match.params.id) {
      payload.id = this.props.match.params.id;
    }
    updateAreaGeofenceApi(payload)
      .then((res) => {
        if (res && res.status === 200) {
          this.props.setMessageModal(modalObj(true, this.props.match.params.id ? 'api.update.area_geofence.success' : 'api.create.area_geofence.success'));
          if (!this.props.match.params.id) {
            this.props.history.push(ROUTE.LAYOUT + ROUTE.AREA_GEOFENCES_MANAGEMENT + `?currentPage=0&rowsPerPage=${ROWS_PER_PAGE_OPTIONS[0]}`);
          }
        } else this.props.setMessageModal(modalObj(true, 'Api.fail'));
      })
      .then(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  /**
   * handleButtonCancel
   */
  handleButtonModalCancel() {
    this.setState({
      flg: false,
    });
  }

  /**
   * handleButtonOk
   */
  handleButtonOk = () => {
    this.setState({
      openModal: false,
    });
  };

  /**
   * handleButtonClose
   */
  handleButtonClose = () => {
    this.setState({
      openModal: false,
    });
  };

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllCountryCode();
    await this.props.getAllGeofence().then(
      (response) =>
        response &&
        this.setState({
          listGeofenceAll: !isRoleGlobal() ? response.filter((item) => item.country_id === this.state.country_id) : response,
        }),
    );
    if (this.props.match.url !== ROUTE.LAYOUT + ROUTE.AREA_GEOFENCES_CREATE) {
      getDetailAreaGeofenceApi(this.props.match.params.id).then((res) => {
        if (isRoleGlobal() ? true : Number(res?.result?.country_id) === Number(this.props.principal.country_id)) {
          this.setState({
            area: res.result?.name,
            geofence_ids: res.result?.geofences,
            country_id: res.result?.country_id,
            status: res.result?.enable === true,
            center_point_lat: res.result?.center_point_lat + '',
            center_point_lon: res.result?.center_point_lon + '',
            dataDetail: res.result,
          });
        } else {
          this.props.setMessageModal(modalObj(true, 'permission.denied'));
          backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.HOME);
        }
      });
    }
  }

  /**
   * handleCreate
   */
  handleCreate = () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid()) {
      this.setState({
        flg: true,
        message: 'area_geofence.question.confirm.create',
      });
    }
  };

  /**
   * handleCreate
   */
  handleUpdate = () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid()) {
      this.setState({
        flg: true,
        message: 'area_geofence.question.confirm.update',
      });
    }
  };

  /**
   * getListAllGeofence
   * @return {Array}
   */
  getListAllGeofence = () => {
    const listAllGeofence = this.state.listGeofenceAll.filter((item) => Number(item.country_id) === Number(this.state.country_id));
    return this.props.match.params.id && this.state.dataDetail?.country_id === this.state.country_id ?
      getListAllGeofenceDetail(listAllGeofence, this.state.dataDetail?.geofence_config_infos) :
      listAllGeofence;
  };

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {common, t, actions} = this.props;
    const isEditForm = this.props.match.params.id;
    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
    };

    return (
      <LoadingOverlay active={this.state.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container spacing={1} className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{isEditForm ? t('area_geofence.detailTitle') : t('area_geofence.createTitle')}</h3>
              </Grid>
            </Grid>
          </Container>
          <br></br>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('area_geofence.form')}
                    </Grid>
                  </Grid>

                  {/* Country */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.country')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_250">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'country_id',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.country_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.country'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.country_id}
                          onChange={(e) => this.onChangeCountry(e.target.value)}
                          disabled={!isRoleGlobal()}
                        >
                          {common?.country_code?.map((item, idx) => (
                            <MenuItem value={item.id} key={idx}>
                              {t(`${item.country_code}`)}
                            </MenuItem>
                          ))}
                        </Select>
                        {this.validator.message('country_id', this.state.country_id, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.country_id, 'required') && (
                          <FormHelperText id="country_id" error>
                            {t('validation.required.choose', {field: t('common.country')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Area */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('area_geofence.area')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined">
                        <div className="box-group-input">
                          <TextField
                            name="area"
                            className="field_size_20 field_min_size_250"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('area_geofence.area')})}
                            inputProps={{maxLength: 256}}
                            value={this.state.area}
                            onChange={(event) => onChangeTextField(this, event)}
                            variant="outlined"
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 50})}</div>
                        </div>
                        {this.validator.message('area', this.state.area, 'required|max:50')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.area, 'required') && (
                          <FormHelperText id="area" error>
                            {t('validation.required', {field: t('area_geofence.area')})}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm && this.validator.check(this.state.area, 'required') && !this.validator.check(this.state.area.trim(), 'max:50') && (
                          <FormHelperText id="area" error>
                            {t('validation.maxString', {value: 50})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Geofence */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('area_geofence.geofence')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined">
                        <FormGroup row>
                          {this.state.country_id &&
                            this.getListAllGeofence().map((item, index) => {
                              const checked = this.state.geofence_ids.indexOf(item.geofence_id) > -1;
                              return (
                                <FormControlLabel
                                  key={index}
                                  labelPlacement="end"
                                  control={
                                    <Checkbox
                                      disabled={!item.enable}
                                      key={index}
                                      checked={checked}
                                      onChange={(event) => this.changeGeofences(event.target.checked, item.geofence_id)}
                                      className="checkbox_radio"
                                    />
                                  }
                                  label={item.name}
                                />
                              );
                            })}
                        </FormGroup>
                        {this.validator.message('geofence_ids', this.state.geofence_ids, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.geofence_ids, 'required') && (
                          <FormHelperText id="geofence_ids" error>
                            {t('validation.required', {field: t('area_geofence.geofence')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* center point */}
                  {isEditForm && this.state.center_point_lat && (
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('area_geofence.center_point')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <Map centerPoint={{lat: parseFloat(this.state.center_point_lat), lng: parseFloat(this.state.center_point_lon)}}></Map>
                      </Grid>
                    </Grid>
                  )}

                  {/* Status */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.status')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_250">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'status',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.status || this.state.status === false ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.status'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.status}
                          onChange={(e) => onChangeSelect(this, e)}
                        >
                          {GEOFENCE_STATUS?.map((item, idx) => {
                            return (
                              <MenuItem value={item.id} key={idx}>
                                {t(`${item.i18n}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('status', this.state.status, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.status, 'required') && (
                          <FormHelperText id="status" error>
                            {t('validation.required.choose', {field: t('common.status')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Paper>
                <br></br>

                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {!isEditForm && (
                      <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={() => this.handleCreate()}>
                        {t('common.btnRegister')}
                      </Button>
                    )}
                    {isEditForm && permission.canUpdate && (
                      <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={() => this.handleUpdate()}>
                        {t('common.btnUpdate')}
                      </Button>
                    )}
                    <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.props.history.goBack}>
                      {t('common.btnCancel')}
                    </Button>
                  </Grid>
                </Grid>
                <Dialog
                  open={this.state.flg}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal onClickOk={this.handleButtonModalOk.bind(this)} onClickCancel={this.handleButtonModalCancel.bind(this)} message={this.state.message}></SelectModal>
                </Dialog>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>
          <br></br>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getAllGeofence: () => dispatch(getAllGeofence()),
    setMessageModal: (params) => dispatch(setMessageModal(params)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
