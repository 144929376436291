import React, {Component} from 'react';

import {Card, Container, Grid, Button, Select, MenuItem, FormControl, TextField, Paper, Dialog, IconButton, Modal, Backdrop, FormHelperText} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {ROUTE, IMAGE_TYPE, PERMISSION_ACTIONS} from '../../../common/constant';
import ImageResize from '../../../components/imageResize';
import Memo from '../../../components/memo';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getListSupplier} from '../../../stores/common/actions';
import {getDetailImage, createImageMasters, updateImageMasters} from '../../../stores/image/action';
import {onChangeSelect, onChangeTextField, onChangeListData, backForwardRouter} from '../../../utils/common';
import {isRoleBusiness} from '../../../utils/role';

/**
 * Image Master Form Component
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      business: '',
      imageId: '',
      id: '',
      type: '',
      imageName: '',
      imageSrc: '',
      image: '',
      memos: [],
      note: '',

      imageWidth: null,
      imageHeight: null,
      croppedImaged: '',
      croppedUrl: '',
      isImageDialog: false,
      flagUpdate: false,
      isSubmitForm: false,
      isLoading: false,
    };
    this.handleButtonUpdateOk = this.handleButtonUpdateOk.bind(this);
    this.handleButtonUpdateCancel = this.handleButtonUpdateCancel.bind(this);
    this.validator = new SimpleReactValidator();
  }

  /**
   * handleChangePhoto
   *  @param {props} event
   */
  handleChangePhoto = (event) => {
    if (event.target.files[0]) {
      const img = new Image();
      const file = event.target.files[0];
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        this.setState({imageWidth: img.width, imageHeight: img.height});
      };
      this.setState({
        image: file,
        imageSrc: URL.createObjectURL(file),
      });
    }
  };

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getListSupplier();
    if (isRoleBusiness()) {
      const business = this.props.common.supplier_list.find((item) => item.id === this.props.principal.supplier_id);
      this.setState({
        business,
      });
    }
    if (this.props.match.path === ROUTE.LAYOUT + ROUTE.IMAGE_FROM + '/:id') {
      await this.props.getDetailImage(this.props.match.params.id).then(
        (data) => {
          if (data) {
            this.setState({isLoading: true});
            const img = new Image();
            img.src = data.url;
            img.onload = () => {
              this.setState({imageWidth: img.width, imageHeight: img.height}, () => {
                this.setState({isLoading: false});
              });
            };
            this.setState({
              business: {
                id: data.supplier_id,
                supplier_id: data.supplier_code,
                supplier_name: data.supplier_name,
              },
              image: data.id,
              id: data.image_id,
              imageId: data.id,
              type: data.type,
              imageName: data.name,
              imageSrc: data.url,
              memos: data.memos,
            });
          }
        },
        () => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.IMAGE),
      );
    }
  }

  /**
   * changeBusiness
   * @param {string} value
   */
  changeBusiness = (value) => {
    this.setState({business: value});
  };

  /**
   * handleCreate
   */
  handleCreate = () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid() && this.isValidImage()) {
      const formData = new FormData();
      formData.append('name', this.state.imageName);
      formData.append('supplier_id', this.state.business.id);
      formData.append('type', this.state.type);
      formData.append('image', this.state.croppedUrl ? this.state.croppedImaged : this.state.image);
      this.props.createImageMasters(formData, this.props);
    }
  };

  handleUpdate = () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid() && this.isValidImage()) {
      this.setState({
        flagUpdate: true,
        message: 'business.question.confirm.update',
      });
    }
  };

  /**
   * handleButtonCancel
   */
  handleButtonUpdateCancel() {
    this.setState({
      flagUpdate: false,
    });
  }

  /**
   * handleButtonOk
   */
  handleButtonUpdateOk() {
    this.setState({
      flagUpdate: false,
    });
    const formData = new FormData();
    formData.append('id', this.state.imageId);
    formData.append('name', this.state.imageName);
    formData.append('supplier_id', this.state.business.id);
    formData.append('type', this.state.type);
    formData.append('note', this.state.note);
    formData.append('image', this.state.croppedUrl ? this.state.croppedImaged : this.state.image);
    formData.append('memos', JSON.stringify(this.state.memos));
    this.props.updateImageMasters(formData, this.props);
    if (this.props.match.path === ROUTE.LAYOUT + ROUTE.IMAGE_FROM + '/:id') {
      this.props.getDetailImage(this.props.match.params.id);
    }
  }

  openDialogImage = () => {
    this.setState({isImageDialog: true, croppedImaged: ''});
  };

  /**
   * handleButtonCloseModal
   */
  handleButtonCloseModal = () => {
    this.setState({
      isImageDialog: false,
    });
  };

  /**
   *
   * @return {boolean}
   */
  isValidImage = () => {
    if (this.state.type) {
      const currentImageType = IMAGE_TYPE.find((item) => item.name === this.state.type);
      const {imageWidth, imageHeight} = this.state;
      if (imageWidth > currentImageType.maxWidth || imageHeight > currentImageType.maxHeight) {
        return false;
      }
    }
    return true;
  };

  /**
   * onCropImage
   * @param {*} value
   */
  onCropImage = (value) => {
    const {crop, cropUrl} = value;
    this.setState({croppedImaged: crop, croppedUrl: cropUrl});

    const img = new Image();
    img.src = cropUrl;
    img.onload = () => {
      this.setState({imageWidth: img.width, imageHeight: img.height});
    };
  };

  /**
   * Render component
   * @return {object}
   */
  render() {
    const {t, common, actions, image} = this.props;
    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
    };
    const currentImageType = IMAGE_TYPE.find((item) => item.name === this.state.type);

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              {this.props.match.params.id ? <h3>{t('route.imageMasterDetail')}</h3> : <h3>{t('route.imageMasterAdd')}</h3>}
            </Grid>
          </Grid>
        </Container>
        <br></br>
        <Container maxWidth="xl">
          <LoadingOverlay active={image.isLoading || this.state.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('businessImage.formData')}
                    </Grid>
                  </Grid>
                  {/* business id */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessInformation.id')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl fullWidth>
                        <Autocomplete
                          margin="dense"
                          className="field_size_10 field_min_size_300"
                          options={common?.supplier_list}
                          value={this.state.business}
                          getOptionLabel={(option) => option.supplier_id || ''}
                          onChange={(event, business) => this.changeBusiness(business)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={'businessName'}
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              placeholder={t('placeholder.required', {field: t('businessInformation.id')})}
                            />
                          )}
                          disabled={isRoleBusiness()}
                        />
                        {this.validator.message('business', this.state.business, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.business, 'required') && (
                          <FormHelperText id="business" error>
                            {t('validation.required', {field: t('businessInformation.id')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Business name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('facility.business.name')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl fullWidth>
                        <Autocomplete
                          margin="dense"
                          className="field_size_10 field_min_size_300"
                          options={common?.supplier_list}
                          value={this.state.business}
                          getOptionLabel={(option) => option.supplier_name || ''}
                          onChange={(event, business) => this.changeBusiness(business)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={'businessName'}
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              placeholder={t('placeholder.required', {field: t('facility.business.name')})}
                            />
                          )}
                          disabled={isRoleBusiness()}
                        />
                        {this.validator.message('business', this.state.business, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.business, 'required') && (
                          <FormHelperText id="business" error>
                            {t('validation.required', {field: t('businessInformation.name')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.name')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl fullWidth>
                        <div className="box-group-input">
                          <TextField
                            name="imageName"
                            className="field_size_20 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('common.name')})}
                            variant="outlined"
                            value={this.state.imageName}
                            onChange={(event) => {
                              if (event.target.value.trim().length > 50) return;
                              onChangeTextField(this, event);
                            }}
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 50})}</div>
                        </div>
                        {this.validator.message('imageName', this.state.imageName, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.imageName, 'required') && (
                          <FormHelperText id="imageName" error>
                            {t('validation.required', {field: t('common.name')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* type */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessImage.type')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'type',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.type ?
                            undefined : () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('businessImage.type'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.type}
                          onChange={(event) => onChangeSelect(this, event)}
                        >
                          {IMAGE_TYPE.map(({name, i18n}, idx) => {
                            return (
                              <MenuItem value={name} key={idx}>
                                {t(`${i18n}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('type', this.state.type, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.type, 'required') && (
                          <FormHelperText id="type" error>
                            {t('validation.required', {field: t('businessInformation.type')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {this.props.match.params.id && (
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('businessImage.id')} <span className="font_color_red">＊</span>
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <TextField name="id" className="field_size_20 field_min_size_300" margin="dense" variant="outlined" value={this.state.id} disabled={true} />
                      </Grid>
                    </Grid>
                  )}

                  {/* Photo */}
                  <Grid container spacing={1} className="row_form_item ">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <input
                        style={{display: 'none'}}
                        accept="image/*"
                        className="input"
                        id="contained-button-file"
                        type="file"
                        name="image"
                        onChange={(event) => this.handleChangePhoto(event)}
                      />
                      {this.validator.message('image', this.state.image, 'required')}
                      <label htmlFor="contained-button-file">
                        <Button variant="contained" disabled={this.props.match.params.id && !permission.canUpdate} color="primary" component="span">
                          {t('businessImage.image')}
                        </Button>
                      </label>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={3}>
                      {this.state.imageSrc ? (
                        <div className="box_image" style={{width: '100%', height: '200px'}}>
                          <img
                            className="cropped_image"
                            style={{width: 'inherit', height: 'inherit'}}
                            src={this.state.croppedUrl ? `${this.state.croppedUrl}?${new Date().getTime()}` : this.state.imageSrc}
                            alt="cropped"
                            onClick={this.openDialogImage}
                          />
                        </div>
                      ) : (
                        <label htmlFor="icon-button-file" xs={6} lg={3}>
                          <IconButton color="primary" aria-label="upload picture" component="span" xs={6} lg={3}>
                            <PhotoCamera />
                          </IconButton>
                        </label>
                      )}
                      <Grid container spacing={1}>
                        {/* check isValid to show messages */}
                        {this.state.isSubmitForm && !this.validator.check(this.state.image, 'required') && (
                          <FormHelperText id="image" error>
                            {t('validation.required.choose', {field: t('common.photo')})}
                          </FormHelperText>
                        )}
                        {/* check isValid size */}
                        {this.state.isSubmitForm && !this.isValidImage() && (
                          <FormHelperText id="image" error>
                            {t('image.maxSize', {
                              imageWidth: this.state.imageWidth,
                              imageHeight: this.state.imageHeight,
                              maxWidth: currentImageType.maxWidth,
                              maxHeight: currentImageType.maxHeight,
                            })}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  {this.props.match.params.id && (
                    <Grid container alignItems="center" item xs={12} lg={9}>
                      <Memo
                        disabled={!permission.canUpdate}
                        memos={this.state.memos}
                        onChangeContent={(value) => onChangeListData(this, this.state.memos, value.field, value.index, value.newValue)}
                        note={this.state.note}
                        onChangeNote={(value) => this.setState({note: value})}
                        parentType="medium"
                      />
                    </Grid>
                  )}
                </Paper>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {!this.props.match.params.id ? (
                      <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={() => this.handleCreate()}>
                        {t('common.btnAdd')}
                      </Button>
                    ) : (
                      permission.canUpdate && (
                        <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={() => this.handleUpdate()}>
                          {t('common.btnUpload')}
                        </Button>
                      )
                    )}
                    <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.props.history.goBack}>
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                </Grid>
                <Dialog
                  open={this.state.flagUpdate}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    onClickOk={this.handleButtonUpdateOk}
                    onClickCancel={this.handleButtonUpdateCancel}
                    message={this.state.message}
                    okButtonText={t('common.btnYes')}
                    cancelButtonText={t('common.btnNo')}
                  ></SelectModal>
                </Dialog>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={this.state.isImageDialog}
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1000',
                  }}
                >
                  <>
                    <ImageResize
                      imageSrc={this.state.imageSrc}
                      onClickOk={this.handleButtonCloseModal}
                      onClickClose={this.handleButtonCloseModal}
                      selectedImage={this.onCropImage}
                    ></ImageResize>
                  </>
                </Modal>
                <br></br>
              </Container>
            </Card>
          </LoadingOverlay>
          <br></br>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    image: state.image,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDetailImage: (params, props) => dispatch(getDetailImage(params, props)),
    getListSupplier: () => dispatch(getListSupplier()),
    createImageMasters: (payload, props) => dispatch(createImageMasters(payload, props)),
    updateImageMasters: (payload, props) => dispatch(updateImageMasters(payload, props)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
