import React, {Component} from 'react';

import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {format, parse} from 'date-fns';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {ROUTE, TIME_FORMAT} from '../../../common/constant';
import CustomTimePicker from '../../../components/CustomTimePicker';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getDetailVehicle, getVehicleShiftSpecial, updateVehicleShiftSpecial, getTransitStop} from '../../../stores/business_vehicles/action';
import {getListGeofence} from '../../../stores/common/actions';
import {searchJitRoute} from '../../../stores/jit_setting/action';
import {setMessageModal} from '../../../stores/modal/actions';
import {backForwardRouter} from '../../../utils/common';
import {convertDateUTCAndGeofence} from '../../../utils/date_time_utils';
import {convertDateTimeToDate, convertDateToHourZero, compareDateTimeRange} from '../../../utils/datetime';

/**
 * jit special setting Page
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      totalRows: 0,
      rowsPerPage: 10,
      currentPage: 1,
      startDate: convertDateToHourZero(new Date()),
      endDate: convertDateToHourZero(new Date()),
      buffer_departure_time: 0,
      buffer_arrival_time: 0,
      message: '',
      flagSelectModal: false,
      lstGeofences: [],
      lstBusStops: [],
      lstBusStopsHTW: [],
      lstBusStopsWTH: [],
      geofenceId: '',
      virtualBusStopId: '',
      virtualBusStopIdHTW: '',
      virtualBusStopIdWTH: '',
      isDisableVirtualBusHTW: false,
      isDisableVirtualBusWTH: false,
      virtualBusStopLon: '',
      virtualBusStopLat: '',
      mobiType: '',
      zone_id: '',

      listRoute: [],
      isSubmitForm: false,
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getDetailVehicle(this.props.match.params.id, this.props).then(async (result) => {
      if (result) {
        this.setState({
          vehicleIdShow: result.vehicle_id,
          vehicleId: result.id,
          geofenceId: result.geofence_id,
          mobiType: result.mobi_type,
        });
        await this.props.getListGeofence({targetScreen: 'ENABLE_FOR_ADMIN_ALL'});
        const geofence = this.props.common?.geofence_list?.find((item) => item.geofence_id === this.state.geofenceId);
        if (geofence) {
          if (result.mobi_type.includes('SHUTTLE_BUS_JIT_HOME_TO_WORK')) {
            await this.props.getTransitStop(
              geofence?.service_group_id,
              [geofence?.jit_home_to_work_sim_id || '', geofence?.jit_work_to_home_sim_id || ''],
              ['SHUTTLE_BUS_JIT_HOME_TO_WORK'],
            );
            this.setState({
              lstBusStopsHTW: this.props.businessVehicle?.transitStop?.filter((item) => item?.is_work === false),
              virtual_bus_stop_name: this.props.businessVehicle?.transitStop?.find((item) => item.id === this.state.virtualBusStopId)?.name,
            });
          }
          if (result.mobi_type.includes('SHUTTLE_BUS_JIT_WORK_TO_HOME')) {
            await this.props.getTransitStop(
              geofence?.service_group_id,
              [geofence?.jit_home_to_work_sim_id || '', geofence?.jit_work_to_home_sim_id || ''],
              ['SHUTTLE_BUS_JIT_WORK_TO_HOME'],
            );
            this.setState({
              lstBusStopsWTH: this.props.businessVehicle?.transitStop?.filter((item) => item?.is_work === true),
              virtual_bus_stop_name: this.props.businessVehicle?.transitStop?.find((item) => item.id === this.state.virtualBusStopId)?.name,
            });
          }
        }
        this.setState({zone_id: geofence.zone_id});
      }
    });

    await this.props.searchJitRoute(
      {geofence_id: this.props.businessVehicle?.detail?.geofence_id},
      {
        page: 0,
        size: 100,
        sort: 'last_modified_at,desc',
      },
    );
    const listRoute = this.props.jitSetting?.routeSearch?.content?.map((item) => {
      return {
        name: item.name,
        duration: item.turn_locations?.map((item) => item.estimate_driving_time).reduce((a, b) => a + b, 0),
      };
    });
    this.setState({listRoute});

    await this.props
      .getVehicleShiftSpecial(
        convertDateTimeToDate(this.state.startDate),
        convertDateTimeToDate(this.state.endDate),
        this.props.match.params.id,
      )
      .then((response) => {
        if (response) {
          this.setState({
            shiftVehicles: response.vehicle_shift,
            buffer_departure_time: response.buffer_departure_time,
            buffer_arrival_time: response.buffer_arrival_time,
            virtualBusStopIdHTW: response.virtual_bus_stop_id_htw ? response.virtual_bus_stop_id_htw : '',
            virtualBusStopIdWTH: response.virtual_bus_stop_id_wth ? response.virtual_bus_stop_id_wth : '',
            isDisableVirtualBusHTW: !!response.virtual_bus_stop_id_htw,
            isDisableVirtualBusWTH: !!response.virtual_bus_stop_id_wth,
            virtualBusStopLon: response.virtual_bus_stop_lon,
            virtualBusStopLat: response.virtual_bus_stop_lat,
          });
        }
      });
  }

  /**
   * changeApplyDate
   * @param {Date} date
   * @param {Boolean} isStartDate
   */
  changeApplyDate = async (date, isStartDate) => {
    await this.props
      .getVehicleShiftSpecial(
        convertDateTimeToDate(isStartDate ? date : this.state.startDate),
        convertDateTimeToDate(isStartDate ? this.state.endDate : date),
        this.props.match.params.id,
      )
      .then((response) => {
        if (response) {
          this.setState({
            shiftVehicles: response.vehicle_shift,
            startDate: isStartDate ? date : this.state.startDate,
            endDate: isStartDate ? this.state.endDate : date,
            buffer_departure_time: response.buffer_departure_time,
            buffer_arrival_time: response.buffer_arrival_time,
          });
        }
      });
  };

  /**
   * addItem
   * @param {String} type_mobi
   */
  addItem = async (type_mobi) => {
    this.setState({
      shiftVehicles: [
        ...this.state.shiftVehicles,
        {
          id: -1,
          tejimai_minute_time: 0,
          group_type: type_mobi,
        },
      ],
    });
  };

  /**
   * changeStartTime
   * @param {*} _this
   * @param {*} index
   * @param {*} value
   */
  changeStartTime = async (_this, index, value) => {
    const shiftVehiclesTemp = [..._this.state.shiftVehicles];
    shiftVehiclesTemp[index].start_time = convertDateUTCAndGeofence(parse(format(value, TIME_FORMAT), TIME_FORMAT, this.state.startDate), this.state.zone_id, false);
    _this.setState({
      shiftVehicles: shiftVehiclesTemp,
    });
  };

  /**
   * changeEndTime
   * @param {*} _this
   * @param {*} index
   * @param {*} value
   */
  changeEndTime = async (_this, index, value) => {
    const shiftVehiclesTemp = [..._this.state.shiftVehicles];
    shiftVehiclesTemp[index].end_time = convertDateUTCAndGeofence(parse(format(value, TIME_FORMAT), TIME_FORMAT, this.state.startDate), this.state.zone_id, false);
    _this.setState({
      shiftVehicles: shiftVehiclesTemp,
    });
  };

  /**
   * changeTejimaiTime
   * @param {*} _this
   * @param {*} index
   * @param {*} event
   */
  changeTejimaiTime = async (_this, index, event) => {
    const value = event.target.value;
    if (value >= 0) {
      const shiftVehiclesTemp = [..._this.state.shiftVehicles];
      shiftVehiclesTemp[index].tejimai_minute_time = event.target.value;
      _this.setState({
        shiftVehicles: shiftVehiclesTemp,
      });
    }
  };

  /**
   * handleUpdate
   */
  handleUpdate = () => {
    let isValidField = true;
    this.setState({
      isSubmitForm: true,
    });
    const arr = [];

    const list = this.state.shiftVehicles;
    for (let i = 0; i < list.length; i++) {
      const dataPayLoad = {
        start_time: list[i].start_time,
        end_time: list[i].end_time,
        tejimai_minute_time: list[i].tejimai_minute_time,
        group_type: list[i].group_type,
      };
      if (
        !compareDateTimeRange(list[i].start_time, list[i].end_time, false) ||
        (list[i].group_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' && !this.checkTejimaiHTW(list[i].start_time, list[i].end_time, list[i].tejimai_minute_time))
      ) {
        isValidField = false;
      }
      arr.push(dataPayLoad);
    }
    const payload = {
      apply_from: convertDateTimeToDate(this.state.startDate),
      apply_to: convertDateTimeToDate(this.state.endDate),
      times: arr,
      vehicle_id: parseInt(this.props.match.params.id),
      buffer_departure_time: this.state.buffer_departure_time,
      buffer_arrival_time: this.state.buffer_arrival_time,
      virtual_bus_stop_id_htw: this.state.virtualBusStopIdHTW,
      virtual_bus_stop_id_wth: this.state.virtualBusStopIdWTH,
      virtual_bus_stop_lat: this.state.virtualBusStopLat,
      virtual_bus_stop_lon: this.state.virtualBusStopLon,
    };
    if (list.length === 0) {
      this.props.setMessageModal({flg: true, message: 'businessVehicle.modal.add.row'});
    }
    if (isValidField && list.length > 0 && this.validator.allValid()) {
      this.props.updateVehicleShiftSpecial(payload);
    }
  };

  /**
   * deleteRows
   * @param {Number} index
   */
  deleteRows = (index) => {
    const shiftVehiclesTemp = this.state.shiftVehicles;
    shiftVehiclesTemp.splice(index, 1);
    this.setState({shiftVehicles: shiftVehiclesTemp});
  };

  /**
   * changeBufferDepartureTime
   * @param {*} _this
   * @param {*} event
   */
  changeBufferDepartureTime = async (_this, event) => {
    const value = event.target.value;
    if (value >= 0) {
      _this.setState({
        buffer_departure_time: event.target.value,
      });
    }
  };

  /**
   * changeBufferArrivalTime
   * @param {*} _this
   * @param {*} event
   */
  changeBufferArrivalTime = async (_this, event) => {
    const value = event.target.value;
    if (value >= 0) {
      _this.setState({
        buffer_arrival_time: event.target.value,
      });
    }
  };

  /**
   * checkTejimaiHTW
   * @param {Date} start_time
   * @param {Date} end_time
   * @param {Date} tejimai_minute_time
   * @return {Boolean}
   */
  checkTejimaiHTW = (start_time, end_time, tejimai_minute_time) => {
    if (start_time && end_time) {
      const startTime = new Date(start_time);
      const endTime = new Date(end_time);
      const totalMilliSeconds1 = parseInt(startTime.getTime());
      const totalMilliSeconds2 = parseInt(endTime.getTime());
      const totalMilliSecondsTejimai = tejimai_minute_time * 60000;
      if (totalMilliSeconds2 - totalMilliSeconds1 < totalMilliSecondsTejimai) {
        return true;
      }
    }
    return false;
  };

  /**
   * handleButtonOk
   */
  handleButtonOk = () => {
    this.setState({
      flagSelectModal: false,
    });
    this.handleUpdate();
  };

  /**
   * handleButtonCancel
   */
  handleButtonCancel = () => {
    this.setState({
      flagSelectModal: false,
    });
  };

  /**
   * changeGeofence
   * @param {*} geofence_id
   */
  async changeGeofence(geofence_id) {
    this.setState({
      geofenceId: geofence_id,
      virtualBusStopId: '',
    });

    const geofence = this.state.lstGeofences.find((item) => item.geofence_id === geofence_id);
    if (geofence) {
      if (this.state.mobi_type.includes('SHUTTLE_BUS_JIT_HOME_TO_WORK')) {
        await this.props.getTransitStop(
          geofence?.service_group_id,
          [geofence?.jit_home_to_work_sim_id || '', geofence?.jit_work_to_home_sim_id || ''],
          ['SHUTTLE_BUS_JIT_HOME_TO_WORK'],
        );
        this.setState({
          lstBusStopsHTW: this.props.businessVehicle?.transitStop?.filter((item) => item?.is_work === false),
          virtual_bus_stop_name: this.props.businessVehicle?.transitStop?.find((item) => item.id === this.state.virtualBusStopId)?.name,
        });
      }
      if (this.state.mobi_type.includes('SHUTTLE_BUS_JIT_WORK_TO_HOME')) {
        await this.props.getTransitStop(
          geofence?.service_group_id,
          [geofence?.jit_home_to_work_sim_id || '', geofence?.jit_work_to_home_sim_id || ''],
          ['SHUTTLE_BUS_JIT_WORK_TO_HOME'],
        );
        this.setState({
          lstBusStopsWTH: this.props.businessVehicle?.transitStop?.filter((item) => item.is_work === true),
          virtual_bus_stop_name: this.props.businessVehicle?.transitStop?.find((item) => item.id === this.state.virtualBusStopId)?.name,
        });
      }
    }
  }

  /**
   * onChangeBusStop
   * @param {*} bus_stop_id
   */
  onChangeBusStop(bus_stop_id, type) {
    if (type === 'HTW') {
      this.setState({
        virtualBusStopIdHTW: bus_stop_id,
      });
    } else {
      this.setState({
        virtualBusStopIdWTH: bus_stop_id,
      });
    }

    const busStops = this.state.lstBusStops.find((item) => item.id === bus_stop_id);
    if (busStops) {
      this.setState({
        virtualBusStopLat: busStops.point.coordinates[1],
        virtualBusStopLon: busStops.point.coordinates[0],
      });
    }
  }

  /**
   * processDuration
   * @param {Number} durationSecs
   * @return {String}
   */
  processDuration = (durationSecs) => {
    let result = '';
    const durationMins = Math.round(durationSecs / 60);
    if (durationMins < 60) result = `${durationMins} ${this.props.t('common.minute')}`;
    else {
      if (durationMins % 60 === 0) result = `${durationMins / 60} ${this.props.t('common.hour')}`;
      else {
        const durationHours = Math.ceil(durationMins / 60) - 1;
        result = `${durationHours} ${this.props.t('common.hour')} ${durationMins - durationHours * 60} ${this.props.t('common.minute')}`;
      }
    }
    return result;
  };

  confirmUpdate = () => {
    this.setState({
      flagSelectModal: true,
      message: 'messageCode.common.updateConfirm',
    });
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t, businessVehicle, common, i18n} = this.props;
    const {shiftVehicles} = this.state;
    const language = i18n.language;

    return (
      <LoadingOverlay active={businessVehicle.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card raised>
          <Container maxWidth="xl">
            <br></br>
            <Paper className="search_table">
              <Grid container spacing={1} className="row_form_item">
                <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                  {t('businessVehicle.title_jit_special_setting')}
                </Grid>
              </Grid>

              {/* Date */}
              <Grid container spacing={1} className="row_form_item">
                <Grid container alignItems="center" item xs={12} lg={12} className="grid_title_padding">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('businessVehicle.start_date')}
                    <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    <KeyboardDatePicker
                      autoOk
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      value={this.state.startDate}
                      onChange={(date) => this.changeApplyDate(convertDateToHourZero(date), true)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('businessVehicle.end_date')}
                    <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    <KeyboardDatePicker
                      autoOk
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      value={this.state.endDate}
                      onChange={(date) => this.changeApplyDate(convertDateToHourZero(date), false)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container alignItems="center" item xs={12} lg={12} className="grid_title_padding">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding" />
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {!compareDateTimeRange(this.state.startDate, this.state.endDate, true) && (
                      <FormHelperText style={{color: 'red'}}>{t('validation.invalid.timeRange')}</FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {/* Route Duration */}
              <Grid container spacing={1} className="row_form_item">
                <Grid container alignItems="center" item xs={12} lg={12} className="grid_title_padding">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('jit.route_duration')}
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={10}>
                    <Box display="flex" flexDirection="column" style={{width: '100%'}}>
                      {this.state.listRoute?.map((item, index) => (
                        <Grid container spacing={2} className="route_row_data" key={index}>
                          <Grid container alignItems="center" item xs={6} lg={3}>
                            <span style={{color: '#3f51b5', marginRight: 10}}>{index + 1}.</span>
                            {item.name}
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={9}>
                            {this.processDuration(item.duration)}
                          </Grid>
                        </Grid>
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>

            <Paper className="search_table">
              {/* Time */}
              <Container maxWidth="xl">
                <Grid container spacing={1} className="row_form_item scroll_area_700">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('jit.serviceArea')}
                  </Grid>
                  <FormControl variant="outlined" margin="dense" disabled className="field_size_10 field_min_size_300">
                    <Select
                      margin="dense"
                      inputProps={{
                        name: 'geofenceId',
                      }}
                      displayEmpty
                      renderValue={
                        this.state.geofenceId ?
                          undefined :
                          () => (
                              <div className="font-12 color-disabled">
                                {t('placeholder.required_select', {
                                  field: t('jit.serviceArea'),
                                })}
                              </div>
                            )
                      }
                      value={this.state.geofenceId}
                      onChange={(event) => this.changeGeofence(event.target.value)}
                    >
                      {common?.geofence_list?.map((row, index) => {
                        return (
                          <MenuItem value={row.geofence_id} key={index}>
                            {row.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  {(this.state.geofenceId !== 'UMEDA_ID' && this.state.mobiType.includes('SHUTTLE_BUS_JIT_WORK_TO_HOME')) && (
                    <>
                      <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                        {t('jit.virtualBusStopWth')}
                        <span className="font_color_red">＊</span>
                      </Grid>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_500">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'virtualBusStopIdWTH',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.virtualBusStopIdWTH ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('jit.virtualBusStopWth'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.virtualBusStopIdWTH}
                          disabled={this.state.isDisableVirtualBusWTH}
                          onChange={(event) => this.onChangeBusStop(event.target.value, 'WTH')}
                          required
                        >
                          {this.state.lstBusStopsWTH.map((row, index) => {
                            return (
                              <MenuItem value={row.id} key={index}>
                                {_.isEmpty(row.name_translations) ? row.display_name:
                                (language === 'ja' ? row.name_translations?.ja : language === 'en' ? row.name_translations?.en : row.name_translations?.vi)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('virtualBusStopIdWTH', this.state.virtualBusStopIdWTH, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.virtualBusStopIdWTH, 'required') && (
                          <FormHelperText error>{t('validation.required', {field: t('jit.virtualBusStopWth')})}</FormHelperText>
                        )}
                      </FormControl>
                    </>
                  )}
                  {(this.state.geofenceId !== 'UMEDA_ID' && this.state.mobiType.includes('SHUTTLE_BUS_JIT_HOME_TO_WORK')) && (
                    <>
                      {this.state.mobiType.includes('SHUTTLE_BUS_JIT_WORK_TO_HOME') && (
                         <>
                           <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                           </Grid>
                           <Grid container alignItems="center" item xs={6} lg={2} className="field_min_size_300">
                           </Grid>
                         </>
                       )}
                      <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                        {t('jit.virtualBusStopHtw')}
                        <span className="font_color_red">＊</span>
                      </Grid>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_500">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'virtualBusStopIdHTW',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.virtualBusStopIdHTW ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('jit.virtualBusStopHtw'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.virtualBusStopIdHTW}
                          disabled={this.state.isDisableVirtualBusHTW}
                          onChange={(event) => this.onChangeBusStop(event.target.value, 'HTW')}
                          required
                        >
                          {this.state.lstBusStopsHTW.map((row, index) => {
                            return (
                              <MenuItem value={row.id} key={index}>
                                {_.isEmpty(row.name_translations) ? row.display_name:
                                (language === 'ja' ? row.name_translations?.ja : language === 'en' ? row.name_translations?.en : row.name_translations?.vi)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('virtualBusStopIdHTW', this.state.virtualBusStopIdHTW, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.virtualBusStopIdHTW, 'required') && (
                          <FormHelperText error>{t('validation.required', {field: t('jit.virtualBusStopHtw')})}</FormHelperText>
                        )}
                      </FormControl>
                    </>
                  )}
                  <Grid container alignItems="center">
                    {this.state.mobiType.includes('SHUTTLE_BUS_JIT_HOME_TO_WORK') && (
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell align="left" className="fixed_cell">
                              {t('jit.arrivalOnTime')} (HTW)
                            </TableCell>
                            <TableCell align="left" className="fixed_cell">
                              {t('vehicles.from')}
                            </TableCell>
                            <TableCell align="left" className="fixed_cell">
                              {t('vehicles.to')}
                            </TableCell>
                            <TableCell align="left" className="fixed_cell">
                              Tejimai
                            </TableCell>
                            <TableCell align="left" className="fixed_cell">
                              <div>
                                <span>
                                  {t('jit.bufferTime')}&nbsp;({t('jit.tableMinute')})&nbsp;
                                </span>
                              </div>
                              <TextField
                                align="left"
                                className="field_size_20 field_min_size_100 "
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  step: 300,
                                }}
                                value={this.state.buffer_arrival_time}
                                onChange={(event) => this.changeBufferArrivalTime(this, event)}
                              />
                            </TableCell>
                            <TableCell align="left" className="fixed_cell">
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() => this.addItem('SHUTTLE_BUS_JIT_HOME_TO_WORK')}
                                disabled={shiftVehicles?.filter((item) => item.group_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK').length >= 10}
                              >
                                <AddIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                          {shiftVehicles?.map(
                            (item, index) =>
                              item.group_type === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' && (
                                <TableRow>
                                  <TableCell align="left" className="fixed_cell"></TableCell>
                                  <TableCell className="fixed_cell">
                                    <FormControl>
                                      <CustomTimePicker
                                        name="start_time_htw"
                                        value={convertDateUTCAndGeofence(item.start_time, this.state.zone_id, true)}
                                        showSecond={false}
                                        allowEmpty
                                        onChange={(value) => this.changeStartTime(this, index, value)}
                                        format={TIME_FORMAT}
                                        use12Hours={false}
                                        autoComplete="off"
                                      />
                                      {this.validator.message('start_time_htw', item.start_time, 'required')}
                                      {this.state.isSubmitForm &&
                                        ((!this.validator.check(item.start_time, 'required') && (
                                          <FormHelperText id="time" style={{color: 'red'}}>
                                            {t('validation.required', {field: t('vehicles.from')})}
                                          </FormHelperText>
                                        )) ||
                                          !(
                                            compareDateTimeRange(item.start_time, item.end_time, false) && (
                                              <FormHelperText id="time" style={{color: 'red'}}>
                                                {t('validation.invalid.timeRange')}
                                              </FormHelperText>
                                            )
                                          ))}
                                    </FormControl>
                                  </TableCell>
                                  <TableCell className="fixed_cell">
                                    <FormControl>
                                      <CustomTimePicker
                                        name="end_time_htw"
                                        value={convertDateUTCAndGeofence(item.end_time, this.state.zone_id, true)}
                                        showSecond={false}
                                        allowEmpty
                                        onChange={(event) => this.changeEndTime(this, index, event)}
                                        format={TIME_FORMAT}
                                        use12Hours={false}
                                        autoComplete="off"
                                      />
                                      {this.validator.message('end_time_htw', item.end_time, 'required')}
                                      {this.state.isSubmitForm &&
                                        ((!this.validator.check(item.end_time, 'required') && (
                                          <FormHelperText id="time" style={{color: 'red'}}>
                                            {t('validation.required', {field: t('vehicles.to')})}
                                          </FormHelperText>
                                        )) ||
                                          !(
                                            compareDateTimeRange(item.start_time, item.end_time, false) && (
                                              <FormHelperText id="time" style={{color: 'red'}}>
                                                {t('validation.invalid.timeRange')}
                                              </FormHelperText>
                                            )
                                          ))}
                                    </FormControl>
                                    <br></br>
                                  </TableCell>
                                  <TableCell align="left" className="fixed_cell">
                                    <FormControl>
                                      <TextField
                                        type="number"
                                        className="field_size_20 field_min_size_100"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        inputProps={{
                                          step: 10,
                                          min: 0,
                                        }}
                                        value={item.tejimai_minute_time}
                                        onChange={(event) => this.changeTejimaiTime(this, index, event)}
                                      />
                                      {this.state.isSubmitForm && !this.checkTejimaiHTW(item.start_time, item.end_time, item.tejimai_minute_time) && (
                                        <FormHelperText id="time" style={{color: 'red'}}>
                                          {t('validation.invalid.tejimai.htw')}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </TableCell>
                                  <TableCell align="left" className="fixed_cell"></TableCell>
                                  <TableCell align="left" className="fixed_cell">
                                    <CancelIcon className="cursor_pointer" onClick={() => this.deleteRows(index)} />
                                  </TableCell>
                                </TableRow>
                              ),
                          )}
                        </TableBody>
                      </Table>
                    )}
                    {this.state.mobiType.includes('SHUTTLE_BUS_JIT_WORK_TO_HOME') && (
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell align="left" className="fixed_cell">
                              {t('jit.scheduledDeparture')} (WTH)
                            </TableCell>
                            <TableCell align="left" className="fixed_cell">
                              {t('vehicles.from')}
                            </TableCell>
                            <TableCell align="left" className="fixed_cell">
                              {t('vehicles.to')}
                            </TableCell>
                            <TableCell align="left" className="fixed_cell">
                              Tejimai
                            </TableCell>
                            <TableCell align="left" className="fixed_cell">
                              <div>
                                <span>
                                  {t('jit.bufferTime')}&nbsp;({t('jit.tableMinute')})&nbsp;
                                </span>
                              </div>
                              <TextField
                                align="left"
                                className="field_size_20 field_min_size_100 "
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  step: 10,
                                }}
                                value={this.state.buffer_departure_time}
                                onChange={(event) => this.changeBufferDepartureTime(this, event)}
                              />
                            </TableCell>
                            <TableCell align="left" className="fixed_cell">
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() => this.addItem('SHUTTLE_BUS_JIT_WORK_TO_HOME')}
                                disabled={shiftVehicles?.filter((item) => item.group_type === 'SHUTTLE_BUS_JIT_WORK_TO_HOME').length >= 10}
                              >
                                <AddIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                          {shiftVehicles?.map(
                            (item, index) =>
                              item.group_type === 'SHUTTLE_BUS_JIT_WORK_TO_HOME' && (
                                <TableRow>
                                  <TableCell align="left" className="fixed_cell"></TableCell>
                                  <TableCell className="fixed_cell">
                                    <FormControl>
                                      <CustomTimePicker
                                        name="start_time_wth"
                                        value={convertDateUTCAndGeofence(item.start_time, this.state.zone_id, true)}
                                        showSecond={false}
                                        allowEmpty
                                        onChange={(value) => this.changeStartTime(this, index, value)}
                                        format={TIME_FORMAT}
                                        use12Hours={false}
                                        autoComplete="off"
                                      />
                                      {this.validator.message('start_time_wth', item.start_time, 'required')}
                                      {this.state.isSubmitForm &&
                                        ((!this.validator.check(item.start_time, 'required') && (
                                          <FormHelperText id="time" style={{color: 'red'}}>
                                            {t('validation.required', {field: t('vehicles.from')})}
                                          </FormHelperText>
                                        )) ||
                                          !(
                                            compareDateTimeRange(item.start_time, item.end_time, false) && (
                                              <FormHelperText id="time" style={{color: 'red'}}>
                                                {t('validation.invalid.timeRange')}
                                              </FormHelperText>
                                            )
                                          ))}
                                    </FormControl>
                                  </TableCell>
                                  <TableCell className="fixed_cell">
                                    <FormControl>
                                      <CustomTimePicker
                                        name="end_time_wth"
                                        value={convertDateUTCAndGeofence(item.end_time, this.state.zone_id, true)}
                                        showSecond={false}
                                        allowEmpty
                                        onChange={(value) => this.changeEndTime(this, index, value)}
                                        format={TIME_FORMAT}
                                        use12Hours={false}
                                        autoComplete="off"
                                      />
                                      {this.validator.message('end_time_wth', item.end_time, 'required')}
                                      {this.state.isSubmitForm &&
                                        ((!this.validator.check(item.end_time, 'required') && (
                                          <FormHelperText id="time" style={{color: 'red'}}>
                                            {t('validation.required', {field: t('vehicles.to')})}
                                          </FormHelperText>
                                        )) ||
                                          !(
                                            compareDateTimeRange(item.start_time, item.end_time, false) && (
                                              <FormHelperText id="time" style={{color: 'red'}}>
                                                {t('validation.invalid.timeRange')}
                                              </FormHelperText>
                                            )
                                          ))}
                                    </FormControl>
                                    <br></br>
                                  </TableCell>
                                  <TableCell align="left" className="fixed_cell">
                                    <TextField
                                      type="number"
                                      className="field_size_20 field_min_size_100"
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      inputProps={{
                                        step: 10,
                                        min: 0,
                                      }}
                                      value={item.tejimai_minute_time}
                                      onChange={(event) => this.changeTejimaiTime(this, index, event)}
                                    />
                                  </TableCell>
                                  <TableCell align="left" className="fixed_cell"></TableCell>
                                  <TableCell align="left" className="fixed_cell">
                                    <CancelIcon className="cursor_pointer" onClick={() => this.deleteRows(index)} />
                                  </TableCell>
                                </TableRow>
                              ),
                          )}
                        </TableBody>
                      </Table>
                    )}
                  </Grid>
                </Grid>
              </Container>
            </Paper>
            <br></br>
            <Container>
              <Grid container spacing={1}>
                <Grid container alignItems="center" justify="flex-end" item xs={12}>
                  <Button color="primary" variant="contained" className="button_margin button_color_green" onClick={this.confirmUpdate}>
                    {t('newReservation.confirm')}
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    className="button_margin button_color"
                    onClick={() => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.VEHICLE_DETAIL + '/' + this.props.match.params.id)}
                  >
                    {t('newReservation.back')}
                  </Button>
                </Grid>
              </Grid>
            </Container>
            <Container>
              <Dialog
                open={this.state.flagSelectModal}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '1020',
                }}
              >
                <SelectModal onClickOk={this.handleButtonOk} onClickCancel={this.handleButtonCancel} message={t(this.state.message)}></SelectModal>
              </Dialog>
            </Container>
            <br></br>
          </Container>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    businessVehicle: state.businessVehicle,
    common: state.common,
    jitSetting: state.jitSetting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListGeofence: (payload) => dispatch(getListGeofence(payload)),
    getVehicleShiftSpecial: (startDate, endDate, vehicle_id) => dispatch(getVehicleShiftSpecial(startDate, endDate, vehicle_id)),
    updateVehicleShiftSpecial: (payload) => dispatch(updateVehicleShiftSpecial(payload)),
    setMessageModal: (params) => dispatch(setMessageModal(params)),
    getDetailVehicle: (params, props) => dispatch(getDetailVehicle(params, props)),
    getTransitStop: (serviceGroupIds, simulationIds, groupTypes) => dispatch(getTransitStop(serviceGroupIds, simulationIds, groupTypes)),
    searchJitRoute: (payload, queryParams) => dispatch(searchJitRoute(payload, queryParams)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
