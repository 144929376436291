import React, {Component} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {Button, Card, Container, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DetailIcon from '@material-ui/icons/Description';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import {Autocomplete} from '@material-ui/lab';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {ROUTE, DATA_NULL, ROWS_PER_PAGE_OPTIONS} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import {getListGeofence} from '../../../stores/common/actions';
import {getSubCosts} from '../../../stores/sub_cost/actions';
import {changeUrlParams, getQueryStringFromObject, getUrlParams} from '../../../utils/common';
import {displayDateTime, convertDatetimeUTC} from '../../../utils/datetime';
import withPermissionData from '../../../withPermissionData/hoc';

/**
 * Sub Cost Management
 */
class Index extends Component {
  /**
   * Render component
   * @return {component}
   */

  /**
   * constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      geofence_id: null,
      from_time: null,
      to_time: null,
      zone_id: '',
      searchParams: this.props.location.search,
      isSearch: false,
      result: [],
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      orderBy: null,
      order: 'asc',
      latestSearchParams: null,
    };
  }

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearchSubCost);
  };

  /**
   * handleSearchSubCost
   * @param {bool} reset
   */
  handleSearchSubCost = (reset) => {
    const {geofence_id, from_time, to_time, zone_id, currentPage, rowsPerPage, order, orderBy} = this.state;
    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0], orderBy: null, order: 'asc', isSearch: true});
    const queryParams = {
      geofence_id: geofence_id?.geofence_id ? geofence_id?.geofence_id : null,
      from_time: from_time ? from_time?.toISOString().substr(0, 10) : null,
      to_time: to_time ? to_time?.toISOString().substr(0, 10) : null,
      // eslint-disable-next-line new-cap
      zone_id: zone_id,
      ...(reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage}),
      ...(orderBy && {sort: `${orderBy},${order}`}),
    };
    this.props.getSubCosts(queryParams).then((response) => {
      if (response) {
        this.setState(
          {totalRows: response.totalSize},
          () => {
            changeUrlParams(queryParams);
            const newSearchParams = getQueryStringFromObject(queryParams);
            this.setState({searchParams: newSearchParams});
          },
        );
      }
    });
  };

  /**
   * reset
   */
  reset = () => {
    this.setState({
      geofence_id: null,
      from_time: null,
      to_time: null,
      isSearch: false,
    });
  };

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    await this.props.getListGeofence({targetScreen: 'ENABLE_FOR_ADMIN_ALL'});
    if (this.props.location.search) {
      const params = getUrlParams(this.props.location.search);
      if (params.from_time) {
        params.from_time = new Date(params.from_time);
      }
      if (params.to_time) {
        params.to_time = new Date(params.to_time);
      }
      this.setState({
        geofence_id: params.geofence_id,
        from_time: params.from_time,
        to_time: params.to_time,
      });
    }
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t, common, subCostList, isLoading} = this.props;

    return (
      <Card className="main_card_min_size ReservationManagement">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.subCost')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.DATA_MANAGEMENT}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          {/* main search */}
          <LoadingOverlay active={isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('accountManagement.titleSearch')}
                    </Grid>
                  </Grid>

                  {/* geofence */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('common.geofence')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={3}>
                      <Autocomplete
                        margin="dense"
                        variant="inline"
                        inputVariant="outlined"
                        className="field_size_10 field_min_size_300"
                        options={common?.geofence_list}
                        value={this.state.geofence_id}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, geofence_id) => this.setState({geofence_id: geofence_id, zone_id: geofence_id && geofence_id.zone_id ? geofence_id.zone_id : ''})}
                        renderInput={(params) => <TextField {...params} name={'geofence_id'} fullWidth variant="outlined" margin="dense" placeholder={t('common.geofence')} />}
                        renderOption={(option, state) => (
                          <option {...option} className={!option.enable && 'disable-option-custom'}>
                            {option.name}
                          </option>
                        )}
                      />
                    </Grid>
                    {this.state.geofence_id && (
                      <Grid container alignItems="center" item xs={12} lg={4}>
                        ({this.state.geofence_id.geofence_id})
                      </Grid>
                    )}
                  </Grid>

                  {/* Start time */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('Time')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          className="field_size_20 field_min_size_300"
                          margin="dense"
                          autoOk
                          disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          minDate="2000-01-01"
                          placeholder={t('From time')}
                          format="yyyy/MM/dd"
                          value={this.state.from_time}
                          minDateMessage="無効な日付です"
                          maxDateMessage="無効な日付です"
                          KeyboardButtonProps={{
                            'aria-label': 'change time',
                          }}
                          onChange={(time) =>
                            this.setState({
                              from_time: time,
                            })
                          }
                        />
                        <span style={{padding: '5px'}}></span>
                        <KeyboardDatePicker
                          className="field_size_20 field_min_size_300"
                          margin="dense"
                          autoOk
                          disableToolbar
                          variant="inline"
                          placeholder={t('To time')}
                          inputVariant="outlined"
                          minDate="2000-01-01"
                          format="yyyy/MM/dd"
                          KeyboardButtonProps={{
                            'aria-label': 'change time',
                          }}
                          value={this.state.to_time}
                          onChange={(time) => this.setState({to_time: time})}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                </Paper>
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.reset()} endIcon={<RotateLeftIcon />}>
                      {t('common.btnResetSearch')}
                    </Button>
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    <Button color="primary" variant="contained" className="button_margin" endIcon={<SearchIcon />} onClick={() => this.handleSearchSubCost(true)}>
                      {t('common.btnSearch')}
                    </Button>
                  </Grid>
                </Grid>
                <br></br>
              </Container>
            </Card>
          </LoadingOverlay>
          {/* end search */}
          <br></br>
          {this.state.isSearch && !isLoading && (
            <Card raised>
              <Container maxWidth="xl">
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={6}>
                    <h3>
                      {t('common.searchResult')} {subCostList.length} {t('common.case')}
                    </h3>
                  </Grid>
                </Grid>
                <LoadingOverlay active={isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                  {subCostList?.length > 0 && (
                    <div className="scroll_area_700">
                      <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                        <TableHead>
                          <TableRow>
                            <TableCell className="width_50p">{t('Booking_id')}</TableCell>
                            <TableCell className="width_150p">{t('Geofence_id')}</TableCell>
                            <TableCell className="width_100p">{t('Simulation_id')}</TableCell>
                            <TableCell className="width_50p">{t('User_id')}</TableCell>
                            <TableCell className="width_100p">{t('Sub_ids')}</TableCell>
                            <TableCell className="width_100p">{t('Cost')}</TableCell>
                            <TableCell className="width_100p">{t('Mobile_info')}</TableCell>
                            <TableCell className="width_150p">{t('Created_at')}</TableCell>
                            <TableCell className="width_150p">{t('Action')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {subCostList.map((row, index) => (
                            <TableRow className="cursor_pointer" hover key={index}>
                              <TableCell>{row.booking_id}</TableCell>
                              <TableCell>{row.geofence_id}</TableCell>
                              <TableCell>{row.simulation_id}</TableCell>
                              <TableCell>{row.user_id}</TableCell>
                              <TableCell>
                                {row.sub_ids.length > 0 ? (
                                  row.sub_ids.map((sub, index) => (
                                    <span key={index}>
                                      {sub}
                                      {index < row.sub_ids.length - 1 && <span>, </span>}
                                    </span>
                                  ))
                                ) : (
                                  <div>{DATA_NULL}</div>
                                )}
                              </TableCell>
                              <TableCell>{row.cost ? row.cost : DATA_NULL}</TableCell>
                              <TableCell>
                                {row.mobile_info.length > 0 ? (
                                  row.mobile_info.map((infor, index) => (
                                    <span>
                                      {infor}
                                      {index < row.mobile_info.length - 1 && <span>, </span>}
                                    </span>
                                  ))
                                ) : (
                                  <div>{DATA_NULL}</div>
                                )}
                              </TableCell>
                              <TableCell>{displayDateTime(convertDatetimeUTC(row.created_at))}</TableCell>
                              <TableCell>
                                <Link
                                  style={{textDecoration: 'none'}}
                                  to={{
                                    pathname: ROUTE.LAYOUT + ROUTE.SUB_COST + `/${row.booking_id}`,
                                    state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                  }}
                                >
                                  <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                    {t('common.btnEdit')}
                                  </Button>
                                </Link>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  )}
                </LoadingOverlay>

                <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
              </Container>
              <br></br>
            </Card>
          )}
          <br></br>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    subCostList: state.subCost.subCostList,
    isLoading: state.subCost.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListGeofence: (payload) => dispatch(getListGeofence(payload)),
    getSubCosts: (payload, queryParams) => dispatch(getSubCosts(payload, queryParams)),
  };
};

export default withPermissionData(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
