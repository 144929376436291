import {api} from '../services/api';

const searchListUrl = `admin/members/search`;
const getMemberDetailUrl = `admin/members/`;
const updateMemberUrl = `admin/members`;
const cancelMemberUrl = `admin/members/cancel/`;
const checkValidMobileEmailExistUrl = `admin/members/mobile`;
const forceLogoutUrl = `admin/members/force-logout`;
const checkUnsubscribeUrl = `admin/members/pre-unsubscribe`;
const crudFavoriteWaypointUrl = 'mass/v1/waypoint/favorite/admin';
const crudFavoriteWaypointAdminUrl = 'admin/waypoint/favorite/admin';
const getStationUrl = `qr/stations/`;
const getTicketsUrl = `qr/tickets`;
const getAgencyUrl = `qr/agency`;
const getMemberReferrerListUrl = 'admin/referrer-setting/logs-user';
const getCsvExportMemberUuidUrl = 'admin/csv-export/member';
const searchIdentityVerificationsUrl = 'admin/nric/search';
const getIdentityVerificationUrl = 'admin/nric/{id}';
const updateIdentityVerificationUrl = 'admin/nric/update';
const getAssciationWaypointUrl = 'mass/waypoint/association?geofenceIds=';

export const searchMemberApi = (payload, queryParams) => {
  return api.search(searchListUrl, queryParams, payload);
};

export const getMemberDetailApi = (params) => {
  return api.get(getMemberDetailUrl + params);
};

export const updateMemberApi = (payload) => {
  return api.put(updateMemberUrl, payload);
};

export const checkUnsubscribeApi = (userId) => {
  return api.get(`${checkUnsubscribeUrl}/${userId}`);
};

export const cancelMemberApi = (payload) => {
  return api.put(cancelMemberUrl, payload);
};

export const createMemberApi = (payload) => {
  return api.post(updateMemberUrl, payload);
};

export const checkValidMobileEmailExist = (params) => {
  return api.get(checkValidMobileEmailExistUrl, params);
};

export const forceLogoutApi = (userId) => {
  return api.put(`${forceLogoutUrl}/${userId}`, {});
};

// favorite waypoint
export const getListFavoriteWaypointApi = (user_id, geofence_id, location_tag, location_name, page, size) => {
  return api.get(`${crudFavoriteWaypointUrl}/search`, {
    user_id: user_id,
    geofence_id: geofence_id,
    location_tag: location_tag,
    location_name: location_name,
    page: page,
    size: size,
  });
};

// favorite waypoint for admin
export const getListFavoriteWaypointAdminApi = (params) => {
  return api.get(`${crudFavoriteWaypointAdminUrl}/search`, params);
};

export const getDetailFavoriteWaypointApi = (id) => {
  return api.get(crudFavoriteWaypointUrl + `/${id}`);
};

export const uploadFavoriteWaypointApi = (payload) => {
  return api.post(crudFavoriteWaypointUrl, payload);
};

export const deleteFavoriteWaypointApi = (id) => {
  return api.delete(crudFavoriteWaypointUrl + `/${id}`);
};

export const getStationsApi = (payload) => {
  return api.get(getStationUrl + 'search', payload);
};

export const getLineStationApi = (payload) => {
  return api.get(getStationUrl + 'paths', payload);
};

export const getTicketsApi = () => {
  return api.get(getTicketsUrl + '?for_admin');
};

export const buyTicketsApi = (userId, body) => {
  return api.post(getTicketsUrl + `/admin/buy?user_id=${userId}`, body);
};

export const getAgencyListApi = (payload) => {
  return api.get(getAgencyUrl, payload);
};

export const getTicketsByUserApi = (payload) => {
  return api.get(getTicketsUrl + '/user/all-for-admin', payload);
};

export const getMemberReferrerList = (memberId, params) => {
  return api.get(getMemberReferrerListUrl + `/${memberId}`, params);
};

export const getCsvExportMemberUuidApi = (payload) => {
  return api.search(getCsvExportMemberUuidUrl, {}, payload);
};

export const searchIdentityVerificationsApi = (params, payload) => {
  return api.search(searchIdentityVerificationsUrl, params, payload);
};

export const getIdentityVerificationApi = (id) => {
  return api.get(getIdentityVerificationUrl.replace('{id}', id));
};

export const updateIdentityVerificationApi = (payload) => {
  return api.put(updateIdentityVerificationUrl, payload);
};

export const getAssociationWaypointApi = (payload) => {
  return api.get(getAssciationWaypointUrl + payload);
};
