import React, {Component} from 'react';

import {Card, Container, Grid, TextField, Button, Table, TableBody, TableCell, TableHead, TableRow, Paper, Dialog, FormControl, Select, MenuItem} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import DetailIcon from '@material-ui/icons/Description';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import {Autocomplete} from '@material-ui/lab';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {DATA_NULL, PERMISSION_ACTIONS, ROUTE, ROWS_PER_PAGE_OPTIONS} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {deleteAccountType, searchAccountTypeList} from '../../../stores/account_type/actions';
import {getAllCountryCode, getListSupplier} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {changeUrlParams, getQueryStringFromObject, getUrlParams, onChangeTextField} from '../../../utils/common';
import {modalObj} from '../../../utils/modal';
import {isRoleCountry, isRoleGlobal} from '../../../utils/role';

/**
 * Account Type Management
 */
class SearchAccountType extends Component {
  /**
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      countryId: '',
      supplierId: '',
      typeName: '',
      openModal: false,
      detailId: null,

      flagDelete: false,
      isSearch: false,
      result: [],
      id: '',
      index: '',

      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,
    };
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllCountryCode();
    await this.props.getListSupplier();
    const {location, history, common, setMessageModal} = this.props;
    const defaultCountryId = isRoleCountry() ? common.principal.country_id : '';

    this.setState({countryId: defaultCountryId});

    if (location.search) {
      const urlParams = getUrlParams(location.search);
      if (defaultCountryId && defaultCountryId !== Number(urlParams?.countryId)) {
        setMessageModal(modalObj(true, 'error.403'));
        history.replace(ROUTE.LAYOUT + ROUTE.SEARCH_ACCOUNT_TYPE);
      } else {
        this.setState(urlParams, this.onSearch);
      }
    }
  }

  /**
   * Reset search condition
   */
  reset = () => {
    this.setState({
      countryId: isRoleGlobal() ? '' : this.state.countryId,
      supplierId: '',
      typeName: '',
      result: [],
      isSearch: false,
    });
  };

  /**
   * Handle search
   * @param {bool} reset
   */
  onSearch = (reset) => {
    if (!this.props.actions.includes(PERMISSION_ACTIONS.SEARCH)) {
      return;
    }
    const {typeName, countryId, supplierId} = this.state;
    const payload = {
      country_id: countryId,
      supplier_id: supplierId,
      name: typeName.trim(),
    };

    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const {currentPage, rowsPerPage} = this.state;
    const queryParams = reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage};
    this.props.searchAccountTypeList(payload, queryParams).then((response) => {
      if (!response) {
        response = [];
      }
      this.setState(
          {
            result: response.content,
            isSearch: true,
            totalRows: response.totalSize ? response.totalSize : 0,
          },
          () => {
            const {countryId, supplierId, typeName, currentPage, rowsPerPage} = this.state;
            // Apply changed params into url
            const queryParamsToChange = {
              countryId,
              supplierId,
              typeName,
              currentPage: String(currentPage),
              rowsPerPage,
            };
            changeUrlParams(queryParamsToChange);
            const newSearchParams = getQueryStringFromObject(queryParamsToChange);
            // Save search params into state in order to pass to next page
            this.setState({searchParams: newSearchParams});
          },
      );
    });
  };

  /**
   *
   * @param {number} id
   * @param {number} index
   */
  handleDelete = (id, index) => {
    this.setState({
      flagDelete: true,
      message: 'accountType.deleteConfirmAccountType',
      id,
      index,
    });
  };

  /**
   * confirmDelete
   */
  confirmDelete = () => {
    this.setState({
      flagDelete: false,
    });
    this.props.deleteAccountType(this.state.id).then(() => {
      this.onSearch(true);
    });
  };

  /**
   * cancelDelete
   */
  cancelDelete = () => {
    this.setState({
      flagDelete: false,
    });
  };

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.onSearch);
  };

  /**
   * @return {HTMLElement}
   */
  render() {
    const {t, account_type, actions, common} = this.props;
    const {countryId, supplierId, typeName, isSearch, result} = this.state;

    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canRegister: actions.includes(PERMISSION_ACTIONS.REGISTER),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
      canDelete: actions.includes(PERMISSION_ACTIONS.DELETE),
    };

    return (
      <Card className="SearchAccountType main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('accountType.management.title')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          <LoadingOverlay active={account_type.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                {permission.canSearch && (
                  <>
                    <br></br>
                    <Paper className="search_table">
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                          {t('accountManagement.titleSearch')}
                        </Grid>
                      </Grid>

                      {/* Country */}
                      <Grid container spacing={1} className='row_form_item table_background_color_aliceblue'>
                        <Grid container alignItems='center' item xs={6} lg={2} className='grid_title_padding'>
                          {t('common.country')}
                        </Grid>
                        <Grid container alignItems='center' item xs={6} lg={4}>
                          <FormControl variant='outlined' margin='dense' className='field_size_20 field_min_size_350'>
                            <Select
                              value={countryId}
                              onChange={(event) => {
                                this.setState({countryId: event.target.value, supplierId: ''});
                              }}
                              displayEmpty
                              renderValue={
                                countryId ? undefined :
                                () => (
                                  <div className='font-12 color-disabled'>
                                    {t('placeholder.required_select', {
                                      field: t('common.country'),
                                    })}
                                  </div>
                                )
                              }
                              disabled={isRoleCountry()}
                            >
                              {common.country_code?.map(({id, country_code}) => (
                                <MenuItem key={id} value={id}>{t(country_code)}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Business name */}
                      <Grid container spacing={1} className='row_form_item table_background_color_aliceblue'>
                        <Grid container alignItems='center' item xs={6} lg={2} className='grid_title_padding'>
                          {t('businessInformation.name')}
                        </Grid>
                        <Grid container alignItems='center' item xs={6} lg={4}>
                          <FormControl margin='dense' className='field_size_20 field_min_size_350'>
                            <Autocomplete
                              options={
                                (isRoleGlobal() && countryId) ?
                                  common.supplier_list?.filter(({country_id}) => country_id === Number(countryId)) :
                                  common.supplier_list
                              }
                              getOptionLabel={(option) => option.supplier_name}
                              getOptionSelected={(option, value) => option.supplier_id === value.supplier_id}
                              value={common.supplier_list?.find(({supplier_id}) => supplierId === supplier_id) || null}
                              onChange={(event, nextSupplier) => {
                                this.setState({supplierId: nextSupplier.supplier_id});
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={t('placeholder.required_select', {field: t('businessInformation.name')})}
                                  variant='outlined'
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Type name */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('accountType.typeName')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            className="field_size_20 field_min_size_350"
                            margin="dense"
                            placeholder={t('accountType.typeName')}
                            name="typeName"
                            inputProps={{maxLength: 256}}
                            variant="outlined"
                            onChange={(event) => onChangeTextField(this, event)}
                            value={typeName}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </>
                )}
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.reset} endIcon={<RotateLeftIcon />}>
                        {t('common.btnResetSearch')}
                      </Button>
                    )}
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {permission.canRegister && (
                      <Link style={{textDecoration: 'none'}} to={{
                        pathname: ROUTE.LAYOUT + ROUTE.REGISTER_ACCOUNT_TYPE,
                        state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                      }}>
                        <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />}>
                          {t('common.btnRegister')}
                        </Button>
                      </Link>
                    )}
                    {permission.canSearch && (
                      <Button
                        color="primary"
                        variant="contained"
                        className="button_margin"
                        onClick={() => {
                          this.onSearch(true);
                        }}
                        endIcon={<SearchIcon />}
                      >
                        {t('common.btnSearch')}
                      </Button>
                    )}
                  </Grid>
                </Grid>

                <br></br>
              </Container>
            </Card>
          </LoadingOverlay>
          <br></br>
          {permission.canSearch && (
            <LoadingOverlay active={account_type?.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
              {isSearch && (
                <Card raised>
                  <Container maxWidth="xl">
                    <Grid container spacing={1}>
                      <Grid container alignItems="center" item xs={6}>
                        <h3>
                          {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                        </h3>
                      </Grid>
                    </Grid>

                    <div className="scroll_area_700">
                      <Table size="small" aria-label="table" stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell className="width_100p">{t('accountType.typeId')}</TableCell>
                            <TableCell className="width_200p">{t('accountType.typeName')}</TableCell>
                            <TableCell className='width_100p'>{t('common.country')}</TableCell>
                            <TableCell className='width_200p'>{t('businessInformation.name')}</TableCell>
                            {(permission.canEdit || permission.canDelete) && (
                              <TableCell className="width_20">{t('common.action')}</TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {result.map((row, index) => (
                            <TableRow key={index} hover>
                              <TableCell scope="row">{row.role_code}</TableCell>
                              <TableCell>{row.role_permission_name}</TableCell>
                              <TableCell>{t(common.country_code?.find(({id}) => id === row.country_id)?.country_code || DATA_NULL)}</TableCell>
                              <TableCell>{row.supplier_name || DATA_NULL}</TableCell>
                              {(permission.canEdit || permission.canDelete) && (
                                <TableCell>
                                  {permission.canEdit && (
                                    <Link
                                      style={{textDecoration: 'none'}}
                                      to={{
                                        pathname: ROUTE.LAYOUT + ROUTE.ACCOUNT_TYPE_DETAIL + '/' + row.id,
                                        state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                      }}
                                    >
                                      <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                        {t('common.btnEdit')}
                                      </Button>
                                    </Link>
                                  )}

                                  {permission.canDelete && (
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="button_margin button_color_red"
                                      endIcon={<DeleteIcon />}
                                      onClick={() => this.handleDelete(row.id, index)}
                                    >
                                      {t('common.btnDelete')}
                                    </Button>
                                  )}
                                </TableCell>
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                    <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                  </Container>
                  <br></br>
                </Card>
              )}
            </LoadingOverlay>
          )}

          <br></br>
          <Dialog
            open={this.state.flagDelete}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal onClickOk={this.confirmDelete} onClickCancel={this.cancelDelete} message={this.state.message}></SelectModal>
          </Dialog>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account_type: state.account_type,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchAccountTypeList: (payload, queryParams) => dispatch(searchAccountTypeList(payload, queryParams)),
    deleteAccountType: (id) => dispatch(deleteAccountType(id)),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getListSupplier: () => dispatch(getListSupplier()),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(SearchAccountType)));
