import React, {Component} from 'react';

import {Card, Container, Grid, Button, Box} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withTranslation} from 'react-i18next';

import SectionPolyline from './sectionPolyline';

/**
 * Polyline Tracking
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * render
   * @return {HTMLElement}
   */
  render() {
    const {t} = this.props;

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3> {t('reservationManagement.polyline_tracking')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />} onClick={this.props.history.goBack}>
                {t('common.btnReturn')}
              </Button>
            </Grid>
          </Grid>

          <br></br>
          <Card raised>
            <Container maxWidth="xl">
              <br></br>
              <Box p={1} m={1}>
                <Grid container spacing={5} alignItems="flex-start">
                  <Grid item xs={6}>
                    <SectionPolyline
                      type="MOBILE"
                      id={this.props.match.params.id}
                      pickUpPoint={this.props.location?.state?.pickUpPoint || ''}
                      dropOffPoint={this.props.location?.state?.dropOffPoint || ''}
                    ></SectionPolyline>
                  </Grid>
                  <Grid item xs={6}>
                    <SectionPolyline
                      type="SWAT"
                      id={this.props.match.params.id}
                      pickUpPoint={this.props.location?.state?.pickUpPoint || ''}
                      dropOffPoint={this.props.location?.state?.dropOffPoint || ''}
                    ></SectionPolyline>
                  </Grid>
                </Grid>
              </Box>
            </Container>
            <br></br>
          </Card>
          <br></br>
        </Container>
      </Card>
    );
  }
}

export default withTranslation('translations')(Index);
