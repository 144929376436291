import React, {Component} from 'react';

import 'date-fns';
import {
  Card,
  Container,
  Grid,
  TextField,
  Button,
  Table,
  TableCell,
  TableRow,
  Paper,
  TableBody,
  TableHead,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DetailIcon from '@material-ui/icons/Description';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {
  ROUTE,
  DATA_NULL,
  ROWS_PER_PAGE_OPTIONS,
} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import {searchReservationSummary} from '../../../stores/reservation/actions';
import {onChangeTextField, changeUrlParams, getUrlParams, getQueryStringFromObject} from '../../../utils/common';
import {displayDateTime, convertDatetimeUTC} from '../../../utils/datetime';
import withPermissionData from '../../../withPermissionData/hoc';

/**
 * Reservation management view
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      reservation_code: '',

      isSearch: false,
      result: [],
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,
      latestSearchParams: null,
    };
  }

  /**
   * reset
   */
  reset = () => {
    this.setState({reservation_code: ''});
  };

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    if (this.props.location.search) {
      this.setState(getUrlParams(this.props.location.search), this.handleSearchReservation);
    }
  };

  /**
   * handleSearchReservation
   * @param {bool} reset
   */
  handleSearchReservation = (reset) => {
    const {reservation_code, currentPage, rowsPerPage} = this.state;
    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const payload = {
      reservation_code: reservation_code ? reservation_code.trim() : null,
      ...(reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage}),
    };
    this.props.searchReservationSummary(payload).then((response) => {
      if (!response) {
        response = [];
      }
      this.setState(
        {
          isSearch: true,
          result: response?.content,
          totalRows: response?.totalSize,
          latestSearchParams: payload,
        },
        () => {
          const queryParamsToChange = {
            reservation_code,
            currentPage: reset ? 0 : currentPage,
            rowsPerPage: reset ? ROWS_PER_PAGE_OPTIONS[0] : rowsPerPage,
          };
          changeUrlParams(queryParamsToChange);
          const newSearchParams = getQueryStringFromObject(queryParamsToChange);
          this.setState({searchParams: newSearchParams});
        },
      );
    });
  };

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearchReservation);
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t, reservation} = this.props;

    return (
      <Card className="main_card_min_size ReservationManagement">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.checking_booking_management')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.DATA_MANAGEMENT}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          <LoadingOverlay active={reservation.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('accountManagement.titleSearch')}
                    </Grid>
                  </Grid>

                  {/* reservation_number */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('reservationManagement.reservation_number_search')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <TextField
                        name="reservation_code"
                        className="field_size_10 field_min_size_300"
                        margin="dense"
                        placeholder={t('placeholder.required', {field: t('reservationManagement.reservation_number_search')})}
                        value={this.state.reservation_code}
                        onChange={(event) => onChangeTextField(this, event)}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Paper>
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.reset()} endIcon={<RotateLeftIcon />}>
                      {t('common.btnResetSearch')}
                    </Button>
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    <Button color="primary" variant="contained" className="button_margin" endIcon={<SearchIcon />} onClick={() => this.handleSearchReservation(true)}>
                      {t('common.btnSearch')}
                    </Button>
                  </Grid>
                </Grid>
                <br></br>
              </Container>
            </Card>
          </LoadingOverlay>
          <br></br>
          {this.state.isSearch && (
            <Card raised>
              <Container maxWidth="xl">
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={6}>
                    <h3>
                      {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                    </h3>
                  </Grid>
                </Grid>
                <LoadingOverlay active={reservation.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                  {this.state.result?.length > 0 && (
                    <div className="scroll_area_700">
                      <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                        <TableHead>
                          <TableRow>
                            <TableCell className="width_150p ant-table-cell-fix-left">{t('reservationManagement.reservation_number_search')}</TableCell>
                            <TableCell className="width_75p">Booking ID</TableCell>
                            <TableCell className="width_75p">External Booking ID</TableCell>
                            <TableCell className="width_75p">{t('driver.id')}</TableCell>
                            <TableCell className="width_75p">{t('driver.code')}</TableCell>
                            <TableCell className="width_75p">Driver name</TableCell>
                            <TableCell className="width_150p">{t('common.email')}</TableCell>
                            <TableCell className="width_50p">{t('businessVehicle.code')}</TableCell>
                            <TableCell className="width_150p">Estimated pickup time</TableCell>
                            <TableCell className="width_150p">Estimated dropoff time</TableCell>
                            <TableCell className="width_100p" style={{position: 'sticky', right: '0'}}>{t('common.action')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.result.map((row, index) => (
                            <TableRow className="cursor_pointer" hover key={index}>
                              <TableCell className="cell_fixed_left">{row.reservation_code || DATA_NULL}</TableCell>
                              <TableCell>{row.booking_id || DATA_NULL}</TableCell>
                              <TableCell>{row.external_booking_id || DATA_NULL}</TableCell>
                              <TableCell>{row.driver_id || DATA_NULL}</TableCell>
                              <TableCell>{row.driver_code || DATA_NULL}</TableCell>
                              <TableCell>{row.driver_name || DATA_NULL}</TableCell>
                              <TableCell>{row.email || DATA_NULL}</TableCell>
                              <TableCell>{row.vehicle_id || DATA_NULL}</TableCell>
                              <TableCell>{row.estimated_pickup_time ? displayDateTime(convertDatetimeUTC(row.estimated_pickup_time)) : DATA_NULL}</TableCell>
                              <TableCell>{row.estimated_dropoff_time ? displayDateTime(convertDatetimeUTC(row.estimated_dropoff_time)) : DATA_NULL}</TableCell>
                              <TableCell className="cell_fixed_right">
                                <Link
                                  style={{textDecoration: 'none'}}
                                  to={{
                                    pathname: ROUTE.LAYOUT + ROUTE.CHECK_BOOKING_DETAIL + `/${row.booking_id}`,
                                    state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                  }}
                                >
                                  <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                    {t('common.btnDetail')}
                                  </Button>
                                </Link>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  )}
                </LoadingOverlay>

                <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
              </Container>
              <br></br>
            </Card>
          )}
          <br></br>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reservation: state.reservation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchReservationSummary: (payload) => dispatch(searchReservationSummary(payload)),
  };
};

export default withPermissionData(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
