import {withStyles} from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';

const StyledToggle = withStyles({
  root: {
    'color': '#3f51b5',
    '&$selected': {
      color: 'white',
      background: '#3f51b5',
    },
    '&:hover': {
      borderColor: '3f51b5',
      color: 'white',
      background: '#3f51b5',
    },
    '&:hover$selected': {
      borderColor: '#3f51b5',
      background: '#3f51b5',
    },
    'textTransform': 'unset',
    'fontSize': '0.75rem',
  },
  selected: {},
})(ToggleButton);

export default StyledToggle;
