import React, {Component} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {Button, Card, Container, Grid, Paper, Slider, TextField, FormHelperText, FormControl, Select, MenuItem} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import {Autocomplete} from '@material-ui/lab';
import {MuiPickersUtilsProvider, KeyboardDateTimePicker} from '@material-ui/pickers';
import {format} from 'date-fns';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {DATE_FORMAT, TIME_FORMAT_WITH_SECONDS} from '../../../common/constant';
import HeatMaps from '../../../components/map/HeatMapGPS';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getDetailVehicle} from '../../../stores/business_vehicles/action';
import {getAllCountryCode} from '../../../stores/common/actions';
import {getAllGeofences} from '../../../stores/geofence/action';
import {setMessageModal} from '../../../stores/modal/actions';
import {getVehicleFrequencyHistory, getVehicleListByGeofence} from '../../../stores/vehicles/action';
import {isRoleCountry, isRoleGlobal} from '../../../utils/role';

/**
 * Vehicle Tracking History Component
 */
class Index extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      from: null,
      to: null,
      minutes: 0,
      dateChain: [],
      marks: [],
      polylineData: [],
      timeCalculate: 0,
      step: 0,
      maxData: 0,
      marker: [],
      openDialog: false,
      isCheckDateRangeInDay: true,
      isCheckFromTo: true,
      errorMessage: '',
      isSubmitForm: false,
      vehicleList: [],
      id: null,
      vehicleId: '',
      countryId: '',
      geofenceId: '',
      geofenceSelected: {},
      listGeofenceAll: [],
      vehicleSelected: {},
      zoneId: '',
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    await this.props.getAllCountryCode();
    await this.props.getAllGeofences({enable: true, page: 0, size: 100, targetScreen: this.props.match.params.id ? 'ENABLE_FOR_ADMIN_ALL' : 'ENABLE_FOR_ADMIN_HISTORY'})
      .then(() => this.setState({listGeofenceAll: this.props.geofence?.listGeofence?.content}));
    const roleCountryId = this.props.common.principal?.country_id;
    const allGeofences = this.props.geofence?.listGeofence?.content;
    const zoneId = this.props.common?.country_code?.find((item) => item.id === roleCountryId)?.zone_id;
    if (isRoleGlobal()) {
      this.setState({countryId: '', listGeofenceAll: allGeofences});
    } else if (isRoleCountry()) {
      this.setState({
        countryId: roleCountryId,
        listGeofenceAll: allGeofences?.filter((geo) => geo.country_id === roleCountryId),
        zoneId,
      });
    } else {
      const roleGeofenceIds = this.props.common.principal?.geofence_ids;
      this.setState({
        countryId: roleCountryId,
        listGeofenceAll: allGeofences?.filter((geo) => roleGeofenceIds?.includes(geo.geofence_id)),
        zoneId,
      });
    }

    if (this.props.match.params.id) {
      await this.props.getDetailVehicle(this.props.match.params.id).then((result) => {
        if (result) {
          this.setState({
            countryId: result.country_id,
            geofenceId: result.geofence_id,
            listGeofenceAll: this.state.listGeofenceAll?.filter((item) => item.country_id === result.country_id),
            zoneId: this.props.common?.country_code?.find((item) => item.id === result.country_id)?.zone_id,
          });
          if (result?.geofence_id) {
            this.props.getVehicleListByGeofence(result.geofence_id).then((response) => {
              this.setState({
                vehicleList: response,
                vehicleId: result.vehicle_id,
                id: result.id,
              });
            });
          }
        }
      });
    }
  };

  /**
   * onChangeCountry
   * @param {*} e
   */
  onChangeCountry = (e) => {
    this.setState({
      countryId: e.target.value,
      listGeofenceAll: this.props.geofence?.listGeofence?.content.filter((item) => item.country_id === e.target.value),
      geofenceId: '',
      geofenceSelected: {},
      vehicleList: [],
      vehicleId: '',
      zoneId: this.props.common?.country_code?.find((item) => item.id === e.target.value)?.zone_id,
    });
  };

  /**
   * onChangeGeofence
   * @param {*} event
   */
  onChangeGeofence = async (event) => {
    await this.setState({
      geofenceId: event.target.value,
      geofenceSelected: this.state.listGeofenceAll.find((item) => item.geofence_id === event.target.value),
    });
    if (this.state.geofenceId) {
      this.props.getVehicleListByGeofence(this.state.geofenceId).then((response) => {
        this.setState({
          vehicleList: response,
          vehicleId: !!response.find((item) => item.id === this.state.vehicleId) ? this.state.vehicleId : '',
        });
      });
    }
  };

  /**
   * handleClickSearch
   */
  handleClickSearch = async () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid() && this.state.isCheckDateRangeInDay && this.state.isCheckFromTo && moment(this.state.from).isValid() && moment(this.state.to).isValid()) {
      const {from, to, id, zoneId} = this.state;
      const fromByZoneId = moment(moment.tz(format(from, 'yyyy-MM-dd HH:mm'), zoneId)).toISOString();
      const toByZoneId = moment(moment.tz(format(to, 'yyyy-MM-dd HH:mm'), zoneId)).toISOString();
      const params = {
        from: fromByZoneId,
        to: toByZoneId,
        vehicleId: id,
      };
      if (id && from && to) {
        this.setState({maxData: 0, marker: []});
        this.props.getVehicleFrequencyHistory(params).then((result) => {
          // this.caculateOnTime(from, to);
          if (result) {
            this.getDataHistory(result[0]?.data || []);
          }
        });
      }
    }
  };

  /**
   * caculateOnTime
   * @param {string} from
   * @param {string} to
   */
  caculateOnTime = async (from, to) => {
    const datePeriod = new Date(to).getTime() - new Date(from).getTime();
    const days = Math.floor(datePeriod / 86400000);
    const secondTotal = Math.abs(datePeriod) / 1000;
    const hours = Math.floor(secondTotal / 3600) % 24;
    const minutes = Math.floor((secondTotal / 60) % 60) + hours * 60 + days * 60 * 24;
    const seperate = 60;
    const timeByHourStep = minutes % seperate ? Math.floor(minutes / seperate) : minutes / seperate;
    const dateChain = [];
    if (minutes > 60) {
      // set display by minutes
      const total = Math.trunc(minutes / timeByHourStep);
      if (minutes % seperate === 0 || minutes % timeByHourStep === 0) {
        for (let i = 0; i <= total; i++) {
          dateChain.push({
            value: i,
            label: format(new Date(new Date(from).getTime() + 60000 * timeByHourStep * i), DATE_FORMAT),
            labelText: format(new Date(new Date(from).getTime() + 60000 * timeByHourStep * i), TIME_FORMAT_WITH_SECONDS),
          });
        }
      } else {
        for (let i = 0; i <= total + 1; i++) {
          if (i === total + 1) {
            dateChain.push({
              value: i,
              label: format(new Date(new Date(to).getTime()), DATE_FORMAT),
              labelText: format(new Date(new Date(to).getTime()), TIME_FORMAT_WITH_SECONDS),
            });
          } else {
            dateChain.push({
              value: i,
              label: format(new Date(new Date(from).getTime() + 60000 * timeByHourStep * i), DATE_FORMAT),
              labelText: format(new Date(new Date(from).getTime() + 60000 * timeByHourStep * i), TIME_FORMAT_WITH_SECONDS),
            });
          }
        }
      }
    } else {
      // set display by second
      for (let i = 0; i <= minutes * 6; i++) {
        dateChain.push({
          value: i,
          label: format(new Date(new Date(from).getTime() + 10000 * i), DATE_FORMAT),
          labelText: format(new Date(new Date(from).getTime() + 10000 * i), TIME_FORMAT_WITH_SECONDS),
        });
      }
    }
    await this.setState({dateChain, minutes, step: timeByHourStep, maxData: dateChain.length - 1});
    this.setPeriodDateText(dateChain);
    this.getMarker(0);
  };

  /**
   * valueLabelFormat
   * @param {array} data
   */
  getDataHistory = async (data) => {
    const dateChain = [];
    if (data.length === 0) {
      return;
    }
    for (let i = 1; i < data.length; i++) {
      dateChain.push({
        value: i-1,
        label: moment.tz(moment.unix(data[i].timestamp), this.state.zoneId)?.format('yyyy/MM/DD'),
        labelText: moment.tz(moment.unix(data[i].timestamp), this.state.zoneId)?.format(TIME_FORMAT_WITH_SECONDS),
        timeStampSave: data[i].timestamp,
      });
    }
    await this.setState({dateChain, maxData: dateChain.length - 1});
    this.setPeriodDateText(dateChain);
    this.getMarker(0);
  };

  /**
   * valueLabelFormat
   * @param {number} value
   * @return {component}
   */
  valueLabelFormat = (value) => {
    if (this.state.dateChain.length > 0) {
      return this.state.dateChain.filter((mark) => mark.value === value)[0].labelText;
    }
    return;
  };

  /**
   * setPeriodDateText
   * @param {array} dateChain
   */
  setPeriodDateText = (dateChain) => {
    const marks = dateChain.filter((item, index) => index === 0 || index === dateChain.length - 1);
    this.setState({marks});
  };

  /**
   * handleDataPolyline
   * @param {object} event
   * @param {newValue} newValue
   */
  handleDataPolyline = (event, newValue) => {
    this.getMarker(newValue);
  };

  /**
   * handleChangeDate
   * @param {string} time
   * @param {string} label
   */
  handleChangeDate = async (time, label) => {
    if (label === 'from') {
      await this.setState({from: time});
    } else {
      await this.setState({to: time});
    }
    if (this.state.from && this.state.to) {
      const timeCalculate = new Date(this.state.to).getTime() - new Date(this.state.from).getTime();
      this.setState({
        isCheckDateRangeInDay: timeCalculate / (60000 * 60 * 24) > 1 ? false : true,
        isCheckFromTo: 0 >= timeCalculate ? false : true,
      });
    }
    if (this.state.from === null || this.state.to === null) {
      this.setState({
        isCheckDateRangeInDay: true,
        isCheckFromTo: true,
      });
    }
  };

  /**
   * getMarker
   * @param {newValue} newValue
   */
  getMarker = (newValue) => {
    const {mapData} = this.props;
    if (mapData?.length === 0) return;
    const timePickUp = this.state.dateChain.filter((item) => item.value === newValue);
    let markerList = [];
    markerList = mapData.find((item) => item.timestamp === timePickUp[0].timeStampSave);
    this.setState({
      marker: [
        {
          lat: markerList?.latitude,
          lng: markerList?.longitude,
        },
      ],
    });
  };

  /**
   * resetSearchCondition
   */
  resetSearchCondition = () => {
    this.setState({vehicleId: '', countryId: '', geofenceId: '', from: null, to: null, isSubmitForm: false, isCheckDateRangeInDay: true, isCheckFromTo: true, id: null});
  };

  /**
   * changeVehicle
   * @param {*} value
   */
  changeVehicle = (value) => {
    this.setState({vehicleId: value ? value.vehicle_id : null, id: value ? value.id : null});
  };

  /**
   * render component
   * @return {HTML}
   */
  render() {
    const {t, driver, mapData, isLoadingMap, common} = this.props;
    const {vehicleList} = this.state;
    let vehicleSelected = null;
    if (this.state.vehicleList.length > 0 && this.state.vehicleId) {
      vehicleSelected = this.state.vehicleList.filter((vehicle) => vehicle.vehicle_id === this.state.vehicleId)[0];
    }
    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('businessVehicle.trackingTitle')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />} onClick={this.props.history.goBack}>
                {t('common.btnReturn')}
              </Button>
            </Grid>
          </Grid>
          <br></br>
          <LoadingOverlay active={driver.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper>
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('businessVehicle.searchTitle')}
                    </Grid>
                  </Grid>

                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="flex-start" item sm={12} xs={4} lg={4} xl={4}>
                      <Grid container spacing={1}>
                        {/* Country */}
                        <Grid container alignItems="center" item xs={4} className="grid_title_padding">
                          {t('common.country')} <span className="font_color_red">*</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={8}>
                          <FormControl fullWidth variant="outlined" margin="dense">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'countryId',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.countryId ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('common.country'),
                                        })}
                                      </div>
                                    )
                              }
                              disabled={!isRoleGlobal()}
                              value={this.state.countryId}
                              onChange={this.onChangeCountry}
                            >
                              {common.country_code?.map((item, idx) => {
                                return (
                                  <MenuItem value={item.id} key={idx}>
                                    {t(`${item.country_code}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            {this.validator.message('country', this.state.countryId, 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.countryId, 'required') && (
                              <FormHelperText id="country" error>
                                {t('validation.required.choose', {field: t('common.country')})}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>

                        {/* Geofence */}
                        <Grid container alignItems="center" item xs={4} className="grid_title_padding">
                          {t('business_plan.geofence')} <span className="font_color_red">*</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={8}>
                          <FormControl fullWidth variant="outlined" margin="dense">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'geofenceId',
                              }}
                              displayEmpty
                              disabled={!this.state.countryId}
                              renderValue={
                                this.state.geofenceId ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('common.geofence'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.geofenceId}
                              onChange={this.onChangeGeofence}
                            >
                              {this.state.listGeofenceAll?.map((item, idx) => {
                                return (
                                  <MenuItem value={item.geofence_id} key={idx}>
                                    {t(`${item.name}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            {this.validator.message('geofenceId', this.state.geofenceId, 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.geofenceId, 'required') && (
                              <FormHelperText id="geofenceId" error>
                                {t('validation.required.choose', {field: t('business_plan.geofence')})}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>

                        {/* Vehicle ID */}
                        <Grid container alignItems="center" item xs={4} className="grid_title_padding">
                          {t('businessVehicle.code_id')} <span className="font_color_red">*</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={8}>
                          <FormControl fullWidth variant="outlined" margin="dense">
                            <Autocomplete
                              inputprops={{
                                name: 'vehicleId',
                              }}
                              margin="dense"
                              options={vehicleList}
                              value={vehicleSelected}
                              getOptionLabel={(option) => option?.vehicle_id + ' - ' + option?.registration_number || ''}
                              onChange={(event, vehicleId) => {
                                this.changeVehicle(vehicleId);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  placeholder={t('placeholder.required_select', {
                                    field: t('businessVehicle.code'),
                                  })}
                                  {...params}
                                  name={'vehicleId'}
                                  fullWidth
                                  variant="outlined"
                                  margin="dense"
                                />
                              )}
                            />
                            {this.validator.message('vehicleId', this.state.vehicleId, 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.vehicleId, 'required') && (
                              <FormHelperText id="vehicleId" error>
                                {t('validation.required.choose', {field: t('businessVehicle.code')})}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>

                        {/* History Date */}
                        <Grid container alignItems="center" item xs={4} lg={4} className="grid_title_padding">
                          {t('businessVehicle.pickup_date')}
                          <span className="font_color_red">*</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={8}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <FormControl fullWidth variant="outlined" margin="dense">
                              <KeyboardDateTimePicker
                                margin="dense"
                                ampm={false}
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                minDate={this.state.to ? new Date(new Date(this.state.to).getTime() - 24 * 60 * 60000) : undefined}
                                maxDate={this.state.to ? this.state.to : new Date()}
                                placeholder={t('reservationManagement.pickup_date_from')}
                                format="yyyy/MM/dd HH:mm"
                                value={this.state.from}
                                KeyboardButtonProps={{
                                  'aria-label': 'change time',
                                }}
                                inputProps={{
                                  name: 'from',
                                }}
                                onChange={(time) => this.handleChangeDate(time, 'from')}
                                helperText={
                                  !this.state.isCheckFromTo ?
                                    t('businessVehicle.validate.from_to') :
                                    !this.state.isCheckDateRangeInDay ?
                                    t('businessVehicle.validate.between_a_day') :
                                    ''
                                }
                                error={!this.state.isCheckDateRangeInDay || !this.state.isCheckFromTo}
                              />
                              {this.validator.message('from', moment(this.state.from), 'required')}
                              {this.state.isSubmitForm && !this.validator.check(this.state.from, 'required') && (
                                <FormHelperText id="from" error>
                                  {t('validation.required.choose', {field: t('businessVehicle.start_date')})}
                                </FormHelperText>
                              )}
                              {this.state.isSubmitForm && this.validator.check(this.state.from, 'required') && !moment(this.state.from).isValid() && (
                                <FormHelperText id="from" error>
                                  {t('validation.invalid.time')}
                                </FormHelperText>
                              )}
                            </FormControl>
                            <span style={{padding: '5px'}}></span>
                            <FormControl fullWidth variant="outlined" margin="dense">
                              <KeyboardDateTimePicker
                                margin="dense"
                                ampm={false}
                                autoOk
                                variant="inline"
                                placeholder={t('reservationManagement.pickup_date_to')}
                                inputVariant="outlined"
                                maxDate={this.state.from ? new Date(new Date(this.state.from).getTime() + 24 * 60 * 60000) : new Date()}
                                minDate={this.state.from}
                                format="yyyy/MM/dd HH:mm"
                                KeyboardButtonProps={{
                                  'aria-label': 'change time',
                                }}
                                inputProps={{
                                  name: 'to',
                                }}
                                value={this.state.to}
                                onChange={(time) => this.handleChangeDate(time, 'to')}
                                helperText={
                                  !this.state.isCheckFromTo ?
                                    t('businessVehicle.validate.from_to') :
                                    !this.state.isCheckDateRangeInDay ?
                                    t('businessVehicle.validate.between_a_day') :
                                    ''
                                }
                                error={!this.state.isCheckDateRangeInDay || !this.state.isCheckFromTo}
                              />
                              {this.validator.message('to', this.state.to, 'required')}
                              {this.state.isSubmitForm && !this.validator.check(this.state.to, 'required') && (
                                <FormHelperText id="to" error>
                                  {t('validation.required.choose', {field: t('businessVehicle.end_date')})}
                                </FormHelperText>
                              )}
                              {this.state.isSubmitForm && this.validator.check(this.state.to, 'required') && !moment(this.state.to).isValid() && (
                                <FormHelperText id="to" error>
                                  {t('validation.invalid.time')}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid container alignItems="center" justify="flex-end" item xs={12} className="grid_title_padding">
                          <Button color="primary" variant="contained" className="button_margin" endIcon={<SearchIcon />} onClick={this.handleClickSearch}>
                            {t('common.btnSearch')}
                          </Button>
                          <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.resetSearchCondition} endIcon={<RotateLeftIcon />}>
                            {t('common.btnReset')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* History location */}
                    <Grid className="map_display_inline" item sm={12} xs={8} xl={8} lg={8}>
                      {mapData?.length > 0 && this.state.marker?.length > 0 ? (
                        <LoadingOverlay active={isLoadingMap} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                          <HeatMaps className="" mapData={mapData} historyInfor={this.state.marker} />
                        </LoadingOverlay>
                      ) : (
                        <HeatMaps mapData={[]} historyInfor={this.state.marker} />
                      )}
                      <br></br>
                      {mapData?.length > 0 && this.state.maxData && (
                        <Grid item xs={11} xl={11} justifyContent="center" container className="slider_margin_top row_form_item">
                          <Slider
                            valueLabelFormat={this.valueLabelFormat}
                            defaultValue={0}
                            max={this.state.maxData}
                            min={0}
                            aria-label="Restricted values"
                            valueLabelDisplay="on"
                            marks={this.state.marks}
                            onChange={this.handleDataPolyline}
                            className="custom-label-display"
                          />
                          {/* <img src="../../../images/car_slider.svg" alt="car_icon" /> */}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Paper>
                <br></br>
              </Container>
            </Card>
          </LoadingOverlay>
          <br />
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    driver: state.driver,
    common: state.common,
    mapData: state.vehicles.mapData,
    isLoadingMap: state.vehicles.isLoading,
    vehicleList: state.vehicles.vehicleListByGeofence,
    geofence: state.geofence,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMessageModal: (params) => dispatch(setMessageModal(params)),
    getVehicleFrequencyHistory: (params) => dispatch(getVehicleFrequencyHistory(params)),
    getDetailVehicle: (id, props) => dispatch(getDetailVehicle(id, props)),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getAllGeofences: (payload) => dispatch(getAllGeofences(payload)),
    getVehicleListByGeofence: (params, props) => dispatch(getVehicleListByGeofence(params, props)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
