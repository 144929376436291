import React, {Component} from 'react';

import {Button, Container, Grid, Paper, Box, Card, Select, Dialog, MenuItem} from '@material-ui/core';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';

import {LANGUAGE, DATA_NULL} from '../../../../common/constant';
import SelectModal from '../../../../components/selectModal';
import {addSlot} from '../../../../stores/business/action';
import {onChangeSelect, customDisplayCurrency} from '../../../../utils/common';
import {displayDate} from '../../../../utils/datetime';

/**
 * Add slot
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      selectSlot: 0,
      openConfirmAddSlotModal: false,
      isLoading: false,
    };
  }

  /**
   * addSlot
   */
  addSlot = () => {
    this.setState({openConfirmAddSlotModal: false, isLoading: true});
    this.props.addSlot({
      subscription_id: Number(this.props.props.subscriptionId),
      user_id: this.props.props.userId,
      number_of_user: this.state.selectSlot + this.props.props.numberOfUser,
    }).then(() => {
      this.setState({isLoading: false});
      this.props.reload();
      this.props.onClickClose();
    }).catch(() => this.setState({isLoading: false}));
  }

  /**
   * Render
   * @return {*}
   */
  render() {
    const {t} = this.props.props;

    return (
      <LoadingOverlay active={this.state.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Paper className="width_700p" style={{margin: '0 auto'}}>
          <Container maxWidth="md">
            <Box p={1} m={1}>
              <Card raised>
                <Container maxWidth="md">
                  <Box p={2} m={2}>
                    <Grid container alignItems="flex-start">
                      <Grid item xs={12}>
                        <h1 className="product_entry_table_header_color font_color_white font_size_mid title">
                          {t('sub.add_slot')}
                        </h1>
                        <Grid container alignItems="flex-start">
                          <Grid item xs={12}>
                            {/* Plan name */}
                            <Grid container spacing={1} className="row_form_item" style={{paddingTop: 5, paddingBottom: 5}}>
                              <Grid container alignItems="center" item xs={4}>
                                {t('business_plan.package_name')}
                              </Grid>
                              <Grid container alignItems="center" item xs={8}>
                                {
                                  this.props.props.planName?.find(
                                    (item) => item.language_id === LANGUAGE.find((item2) => item2.code === this.props.props.i18n.language)?.id,
                                  )?.name || DATA_NULL
                                }
                              </Grid>
                            </Grid>
                            {/* Select slot */}
                            <Grid container spacing={1} className="row_form_item" style={{paddingTop: 5, paddingBottom: 5}}>
                              <Grid container alignItems="center" item xs={4}>
                                {t('sub.additional_slot')} <span className="font_color_red">＊</span>
                              </Grid>
                              <Grid container alignItems="center" item xs={8}>
                                <Select
                                  variant="outlined"
                                  style={{width: '100%'}}
                                  margin="dense"
                                  inputProps={{name: 'selectSlot'}}
                                  displayEmpty
                                  renderValue={
                                    this.state.selectSlot ?
                                      undefined :
                                      () => (
                                          <div className="font-12 color-disabled">
                                            {t('placeholder.required_select', {
                                              field: t('sub.additional_slot'),
                                            })}
                                          </div>
                                        )
                                  }
                                  value={this.state.selectSlot}
                                  onChange={(event) => onChangeSelect(this, event)}
                                >
                                  {new Array(this.props.props.maximumSlots).fill('').map((item, index) => (
                                    <MenuItem value={index + 1} key={index}>
                                      {t('sub.select_slot', {count: index + 1, number: index + 1 + this.props.props.numberOfUser})}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Grid>
                            </Grid>
                            {/* Extra fee */}
                            <Grid container spacing={1} className="row_form_item" style={{paddingTop: 5, paddingBottom: 5}}>
                              <Grid container alignItems="center" item xs={4}>
                                {t('business_plan.extra_fee')}
                              </Grid>
                              <Grid container alignItems="center" item xs={8}>
                                {customDisplayCurrency(this.props.props.extraFee, this.props.props.currency)}
                              </Grid>
                            </Grid>
                            {/* Total extra fee */}
                            <Grid container spacing={1} className="row_form_item" style={{paddingTop: 5, paddingBottom: 5}}>
                              <Grid container alignItems="center" item xs={4}>
                                {t('sub.total_extra_fee')}
                              </Grid>
                              <Grid container alignItems="center" item xs={8}>
                                {customDisplayCurrency(this.props.props.extraFee * this.state.selectSlot, this.props.props.currency)}
                              </Grid>
                            </Grid>
                            {/* Start date */}
                            <Grid container spacing={1} className="row_form_item" style={{paddingTop: 5, paddingBottom: 5}}>
                              <Grid container alignItems="center" item xs={4}>
                                {t('business_plan.start_date')}
                              </Grid>
                              <Grid container alignItems="center" item xs={8}>
                                {displayDate(this.props.props.startDate, true)}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container alignItems="flex-start">
                          <Grid container justify="center" alignItems="center" item xs={12} style={{paddingTop: 20}}>
                            <Button
                              variant="contained"
                              disabled={!this.state.selectSlot}
                              className="button_margin"
                              color="primary"
                              onClick={() => this.setState({openConfirmAddSlotModal: true})}
                            >
                              {t('common.btnOk')}
                            </Button>
                            <Button variant="contained" className="button_color button_magin" style={{marginLeft: 5}} color="primary" onClick={() => this.props.onClickClose()}>
                              {t('common.btnCancel')}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Dialog
                        open={this.state.openConfirmAddSlotModal}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          zIndex: '1020',
                        }}
                      >
                        <SelectModal
                          okButtonText={`${t('common.btnUpdate')}`}
                          cancelButtonText={`${t('common.btnReturn')}`}
                          onClickOk={() => this.addSlot()}
                          onClickCancel={() => this.setState({openConfirmAddSlotModal: false})}
                          message={t('sub.confirm_add_slot', {
                            count: this.state.selectSlot,
                            amount: customDisplayCurrency(this.props.props.extraFee * this.state.selectSlot, this.props.props.currency),
                          })}
                        ></SelectModal>
                      </Dialog>
                    </Grid>
                  </Box>
                </Container>
              </Card>
            </Box>
          </Container>
        </Paper>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    addSlot: (payload) => dispatch(addSlot(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
