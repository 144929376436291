import React, {Component} from 'react';

import {Button, Checkbox, Container, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import LoadingOverlay from 'react-loading-overlay';

import {getBankAccount} from '../../../../services/partnerServices';

/**
 * Select Modal Waypoint
 */
export default class extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      listBankAccount: [],
      listDataView: [],
      listBankAccountId: [],

      loading: true,
    };
  }

  /**
   * componentDidMount Call Api
   */
  componentDidMount() {
    setTimeout(
      () =>
        this.setState({
          loading: false,
        }),
      3000,
    );

    getBankAccount().then((response) => {
      this.setState({
        listBankAccount: response.result,
        loading: false,
        listBankAccountId: this.props.bankIds,
        listDataView: this.props.bankIds.map((item) => {
          return response.result?.find((bank) => bank.id === item);
        }),
      });
    });
  }

  /**
   * handleChecked
   * @param {*} e
   * @param {*} value
   */
  handleChecked = (e, value) => {
    const listBankAccountIdCop = this.state.listBankAccountId;
    const listDataViewCop = this.state.listDataView;
    const index = listBankAccountIdCop.indexOf(value.id);
    if (e && listBankAccountIdCop.length > 0) return;
    e ? listBankAccountIdCop.push(value.id) && listDataViewCop.push(value) : listBankAccountIdCop.splice(index, 1) && listDataViewCop.splice(index, 1);

    this.setState({
      listBankAccountId: listBankAccountIdCop,
      listDataView: listDataViewCop,
    });
  };

  /**
   * Render
   * @return {*}
   */
  render() {
    const {loading} = this.state;
    const {t} = this.props.props;
    return (
      <LoadingOverlay active={loading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Paper className="field_min_size_500">
          <Container maxWidth="md">
            <br />
            <Grid container spacing={3}>
              <Grid container alignItems="center" justify="flex-end" item xs={2}>
                <HelpIcon className="errorinfo" fontSize="large" />
              </Grid>
              <Grid container alignItems="center" justify="flex-start" item xs={10}>
                <span className="font_size_small_regular" style={{whiteSpace: 'pre-line'}}>
                  {t('choose.bank_account')}
                </span>
              </Grid>
              <br></br>
              <Container maxWidth="xl" className="scroll_area_500">
                <TableContainer component={Paper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="right"></TableCell>
                        <TableCell align="right">{t('bankManagement.code')}</TableCell>
                        {/* <TableCell align="right">{t('bank.account_name')}</TableCell> */}
                        <TableCell align="right">{t('bank.account_number')}</TableCell>
                        <TableCell align="right">{t('bank.bank_name')}</TableCell>
                        {/* <TableCell align="right">{t('bank.transfer_content')}</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.listBankAccount?.map((bankAccount, index) => {
                        const checked = this.state.listBankAccountId.indexOf(bankAccount.id) > -1;
                        return (
                          <TableRow key={index}>
                            <TableCell align="right">
                              <Checkbox
                                checked={checked}
                                color="primary"
                                onChange={(e) => this.handleChecked(e.target.checked, bankAccount)}
                                inputProps={{'aria-label': 'secondary checkbox'}}
                              />
                            </TableCell>
                            <TableCell align="right">{bankAccount?.code}</TableCell>
                            {/* <TableCell align="right">{bankAccount?.account_name}</TableCell> */}
                            <TableCell align="right">{bankAccount?.account_number}</TableCell>
                            <TableCell align="right">{bankAccount?.bank_name}</TableCell>
                            {/* <TableCell align="right">{bankAccount?.transfer_content}</TableCell> */}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>
              <Grid container alignItems="center" justify="center" item xs={6}>
                <div style={{width: '200px'}}>
                  <Button fullWidth variant="contained" className="" color="primary" onClick={() => this.props.onClickOk(this.state.listBankAccountId, this.state.listDataView)}>
                    {t('common.btnOk')}
                  </Button>
                </div>
              </Grid>
              <Grid container alignItems="center" justify="center" item xs={6}>
                <div style={{width: '200px'}}>
                  <Button fullWidth variant="contained" className="button_color" color="primary" onClick={() => this.props.onClickClose()}>
                    {t('common.btnCancel')}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Container>
          <br />
        </Paper>
      </LoadingOverlay>
    );
  }
}
