import React, {Component} from 'react';

import {Button, Container, Grid, Fade, Paper, TextField, FormControl} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import SyncIcon from '@material-ui/icons/Sync';
import {Autocomplete} from '@material-ui/lab';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';

import {getListStopSetApi, syncWillerStopApi} from '../../../services/stopMasterServices';
import {setMessageModal} from '../../../stores/modal/actions';
import {onChangeTextField} from '../../../utils/common';
import {modalObj} from '../../../utils/modal';
import withPermissionData from '../../../withPermissionData/hoc';

/**
 * Stop Set Management
 */
class StopSetManagement extends Component {
  /**
   * constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      stop_set_id_of_label: '',
      stop_set_id: '',
      data: [],

      isLoading: false,
      syncByLabel: true,
    };
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    getListStopSetApi().then((res) => {
      this.setState({
        data: res.result.content?.map((item) => {
          return {
            label: item.name,
            stop_set_id: item.swat_transit_stop_set_id,
          };
        }),
      });
    });
  }

  handleSync = () => {
    this.setState({isLoading: true});
    const stop_set_id = this.state.syncByLabel ? this.state.stop_set_id_of_label : this.state.stop_set_id;
    syncWillerStopApi(stop_set_id)
      .then((res) => {
        this.props.setMessageModal(modalObj(true, 'api.sync.stop_master.success'));
        if (window.location.search) {
          this.props.reSearch();
        }
      })
      .then(() => {
        this.setState({isLoading: false});
      });
  };

  /**
   * Render component
   * @return {*}
   */
  render() {
    const {t} = this.props;

    return (
      <Fade in={true}>
        <Paper className="modal_size_big" style={{maxWidth: '80%', width: '60%'}}>
          <LoadingOverlay active={this.state.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <br></br>
            <Container maxWidth="xl">
              <Grid container spacing={2}>
                <Grid container alignItems="flex-start" className="page_header" item xs={8}>
                  <span className="font_bold font_size_big">{t('stop_master.stopSetData')}</span>
                </Grid>
                <Grid container alignItems="flex-start" justify="flex-end" className="page_header" item xs={4}>
                  <CancelIcon onClick={() => this.props.onClickClose()} className="cursor_pointer" />
                </Grid>
              </Grid>
            </Container>
            <br />
            <Container maxWidth="xl" className="scroll_area_500">
              <form autoComplete="off">
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={5} lg={2} className="grid_title_padding">
                    {t('stop_master.stopSet')}
                  </Grid>
                  <Grid container alignItems="center" item xs={5} lg={8}>
                    <Autocomplete
                      margin="dense"
                      className="field_size_10 field_min_size_700"
                      options={this.state.data}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, item) => this.setState({stop_set_id_of_label: item.stop_set_id})}
                      onFocus={() => this.setState({syncByLabel: true})}
                      disableClearable
                      renderInput={(params) => <TextField {...params} fullWidth variant="outlined" margin="dense" />}
                    />
                  </Grid>
                  <Grid container alignItems="center" item xs={2} lg={2} className="grid_title_padding">
                    <Button
                      color="primary"
                      disabled={!this.state.stop_set_id_of_label || !this.state.syncByLabel}
                      variant="contained" endIcon={<SyncIcon />} onClick={this.handleSync}
                    >
                      {t('common.btnSync')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
              <form autoComplete="off">
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={5} lg={2} className="grid_title_padding">
                    {t('stop_master.stopSet')} ID
                  </Grid>
                  <Grid container alignItems="center" item xs={5} lg={8}>
                    <FormControl margin="dense" variant="outlined">
                      <TextField
                        className="field_size_10 field_min_size_700"
                        margin='dense'
                        variant='outlined'
                        type="number"
                        value={this.state.stop_set_id}
                        onChange={(e) => onChangeTextField(this, e)}
                        onFocus={() => this.setState({syncByLabel: false})}
                        inputProps={{name: 'stop_set_id'}}
                      />
                    </FormControl>
                  </Grid>
                  <Grid container alignItems="center" item xs={2} lg={2} className="grid_title_padding">
                    <Button color="primary" disabled={!this.state.stop_set_id || this.state.syncByLabel} variant="contained" endIcon={<SyncIcon />} onClick={this.handleSync}>
                      {t('common.btnSync')}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Container>
            <br />
            <Container maxWidth="xl">
              <Grid container>
                <Grid container justify="flex-start" alignItems="flex-start" item xs={4}></Grid>
                <Grid container justify="flex-end" alignItems="flex-start" item xs={8}>
                  <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.props.onClickClose()} endIcon={<CloseIcon />}>
                    {t('common.btnClose')}
                  </Button>
                </Grid>
              </Grid>
              <br></br>
            </Container>
          </LoadingOverlay>
        </Paper>
      </Fade>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
  };
};

export default withPermissionData(withTranslation('translations')(connect(null, mapDispatchToProps)(StopSetManagement)));
