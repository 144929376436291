import {ROUTE} from '../../common/constant';
import {
  searchListCancellationFeeApi,
  getCancellationFeeDetailApi,
  createCancellationFeeApi,
  updateCancellationFeeApi,
  deleteCancellationFeeApi,
} from '../../services/productServices';
import {modalObj} from '../../utils/modal.js';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

export const searchListCancellationFee = (payload, querryParams) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.SEARCH_LIST_CANCELATTION_FEE_START});
    return searchListCancellationFeeApi(payload, querryParams).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.SEARCH_LIST_CANCELATTION_FEE_SUCCESS,
              list: response.result.content,
            });
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.SEARCH_LIST_CANCELATTION_FEE_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.SEARCH_LIST_CANCELATTION_FEE_FAIL}),
    );
  };
};

export const getCancellationFeeDetail = (params) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_CANCELLATION_FEE_DETAIL_START});
    return getCancellationFeeDetailApi(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_CANCELLATION_FEE_DETAIL_SUCCESS,
              cancelFee: response.result.content,
            });
            return response.result.content;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.GET_CANCELLATION_FEE_DETAIL_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.GET_CANCELLATION_FEE_DETAIL_FAIL}),
    );
  };
};

export const createCancellationFee = (payload, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.CREATE_CANCELLATION_FEE_START});
    return createCancellationFeeApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.CREATE_CANCELLATION_FEE_SUCCESS});
            props.history.push(ROUTE.LAYOUT + ROUTE.PRODUCT_CANCELLATION_FEE);
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.CREATE_CANCELLATION_FEE_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.CREATE_CANCELLATION_FEE_FAIL}),
    );
  };
};

export const updateCancellationFee = (payload, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.UPDATE_CANCELLATION_FEE_START});
    return updateCancellationFeeApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.UPDATE_CANCELLATION_FEE_SUCCESS});
            props.history.push(ROUTE.LAYOUT + ROUTE.PRODUCT_CANCELLATION_FEE);
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.UPDATE_CANCELLATION_FEE_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.UPDATE_CANCELLATION_FEE_FAIL}),
    );
  };
};

export const deleteCancellationFee = (id) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.DELETE_CANCELLATION_FEE_START});
    return deleteCancellationFeeApi(id).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.DELETE_CANCELLATION_FEE_SUCCESS});
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.DELETE_CANCELLATION_FEE_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.DELETE_CANCELLATION_FEE_FAIL}),
    );
  };
};
