import _ from 'lodash';

import {ROUTE} from '../../common/constant';
import {createFacilityApi, getDetailFacilityApi, getListFacilityApi, updateFacilityApi, deleteFacilityApi} from '../../services/facilityServices';
import {getStoreProductsApi, updateStoreProductsIndexApi} from '../../services/productServices';
import {modalObj} from '../../utils/modal.js';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

/**
 * search list facility
 * @param {object} params
 * @param {object} queryParams
 * @param {object} props
 * @return {object}
 */
export const searchListFacility = (params, queryParams) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.SEARCH_LIST_FACILITY_START});
    return getListFacilityApi(params, queryParams).then(
        (response) => {
          if (response && response.status === 200) {
            if (response.result.content.length > 0) {
              dispatch({
                type: ACTION_TYPES.SEARCH_LIST_FACILITY_SUCCESS,
                listFacility: response.result.content,
              });
              return response.result;
            } else {
              dispatch({type: ACTION_TYPES.SEARCH_LIST_FACILITY_FAIL});
            }
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
            dispatch({type: ACTION_TYPES.SEARCH_LIST_FACILITY_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.SEARCH_LIST_FACILITY_FAIL}),
    );
  };
};

/**
 * Export list Facility
 * @param {object} payload
 * @param {object} queryParams
 * @return {object}
 */
export const exportFacility = (payload, queryParams) => {
  return (dispatch) => {
    return getListFacilityApi(payload, queryParams).then(
        (response) => {
          if (response && response.status === 200) {
            return response.result?.content;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
          }
        },
        () => dispatch(setMessageModal(modalObj(true, 'Internal Server Error'))),
    );
  };
};

/**
 * get detail facility
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const getDetailFacility = (params) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_DETAIL_FACILITY_START});
    return getDetailFacilityApi(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_DETAIL_FACILITY_SUCCESS,
              detailFacility: response.result,
            });
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
            dispatch({type: ACTION_TYPES.GET_DETAIL_FACILITY_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.GET_DETAIL_FACILITY_FAIL}),
    );
  };
};

/**
 * create new facility
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const createFacility = (params, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.CREATE_FACILITY_START});
    return createFacilityApi(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.CREATE_FACILITY_SUCCESS});
            props.history.push(ROUTE.LAYOUT + ROUTE.FACILITY_INFORMATION);
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.CREATE_FACILITY_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.CREATE_FACILITY_FAIL}),
    );
  };
};

/**
 * update new facility
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const updateFacility = (params, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.UPDATE_FACILITY_START});
    return updateFacilityApi(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message)));
            dispatch({type: ACTION_TYPES.UPDATE_FACILITY_SUCCESS});
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
            dispatch({type: ACTION_TYPES.UPDATE_FACILITY_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.UPDATE_FACILITY_FAIL}),
    );
  };
};

/**
 * delete new facility
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const deleteFacility = (params, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.DELETE_FACILITY_START});
    return deleteFacilityApi(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.DELETE_FACILITY_SUCCESS});
            props.history.push(ROUTE.LAYOUT + ROUTE.FACILITY_INFORMATION);
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.DELETE_FACILITY_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.DELETE_FACILITY_FAIL}),
    );
  };
};

/**
 * Get store's products
 * @param {number} facilityId
 * @param {object} props component props
 * @return {Promise}
 */
export const getStoreProducts = (facilityId, props) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_STORE_PRODUCTS_START,
    });
    return getStoreProductsApi(facilityId).then((response) => processGetStoreProducts(response, dispatch, props));
  };
};

/**
 *
 * @param {object} response
 * @param {function} dispatch
 * @param {object} props component props
 */
const processGetStoreProducts = (response, dispatch, props) => {
  if (response && response.status === 200) {
    const result = response.result;
    const data = _.sortBy(result, 'order_display');
    dispatch({
      type: ACTION_TYPES.GET_STORE_PRODUCTS_SUCCESS,
      listProduct: data,
    });
    return;
  } else {
    dispatch(setMessageModal(modalObj(true, response.message_code)));
    dispatch({
      type: ACTION_TYPES.GET_STORE_PRODUCTS_FAILED,
    });
  }
};

/**
 * updateStoreProductsIndex
 * @param {[]} params
 * @param {object} props
 * @return {function}
 */
export const updateStoreProductsIndex = (params, props) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_STORE_PRODUCTS_INDEX_START,
    });
    return updateStoreProductsIndexApi(params).then((response) =>
      processUpdateStoreProductsIndex(response, dispatch, props),
    );
  };
};

/**
 * processUpdateStoreProductsIndex
 * @param {object} response
 * @param {function} dispatch
 * @param {object} props
 */
const processUpdateStoreProductsIndex = (response, dispatch, props) => {
  if (response && response.status === 200) {
    dispatch(setMessageModal(modalObj(true, response.message_code)));
    dispatch({
      type: ACTION_TYPES.UPDATE_STORE_PRODUCTS_INDEX_SUCCESS,
    });
  } else {
    dispatch(setMessageModal(modalObj(true, response.message_code)));
    dispatch({
      type: ACTION_TYPES.UPDATE_STORE_PRODUCTS_INDEX_FAILED,
    });
  }
};
