import React, {Component} from 'react';

import {Button, Container, Fade, Grid, Paper} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import {withTranslation} from 'react-i18next';

import Map from '../../../../../components/map/MapWIthPolyline';

/**
 * Polyline for booking popup
 */
class Index extends Component {
  /**
   * handleClickClose
   */
  handleClickClose = () => {
    this.props.onClickClose();
  }

  /**
   * Render component
   * @return {object}
   */
  render() {
    const {t} = this.props;

    return (
      <Fade in={true}>
        <Paper className="modal_size_small">
          <br></br>
          <Container maxWidth="xl">
            <Grid container spacing={1}>
              <Grid container alignItems="flex-start" className="page_header" item xs={8}>
                <img src={`${process.env.PUBLIC_URL}/favicon.ico`} alt="Logo" width="35" height="33"></img>
                <span className="font_bold font_size_big header_title">{t('newReservation.polyline_for_booking')}</span>
              </Grid>
              <Grid container alignItems="flex-start" justify="flex-end" className="page_header" item xs={4}>
                <CancelIcon onClick={this.handleClickClose} className="cursor_pointer" />
              </Grid>
            </Grid>
          </Container>
          <br />

          <Container maxWidth="xl" className="scroll_area_500">
            <Grid container spacing={1}>
              <Map dataPolyline={this.props.dataPolyline}/>
            </Grid>
          </Container>

          <br />
          <Container maxWidth="xl">
            <Grid container>
              <Grid container justify="flex-start" alignItems="flex-start" item xs={4}></Grid>
              <Grid container justify="flex-end" alignItems="flex-start" item xs={8}>
                <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.handleClickClose} endIcon={<CloseIcon />}>
                  {t('common.btnClose')}
                </Button>
              </Grid>
            </Grid>

            <br></br>
          </Container>
        </Paper>
      </Fade>
    );
  }
}

export default withTranslation('translations')(Index);
