import {api} from '../services/api';

const serviceGroupUrl = 'mass/v1/servicegroup';

export const getServiceGroupsApi = (payload) => {
  return api.get(serviceGroupUrl, payload);
};

export const getServiceGroupDetailApi = (id) => {
  return api.get(`${serviceGroupUrl}/${id}`);
};

export const updateServiceGroupApi = (payload) => {
  return api.post(serviceGroupUrl, payload);
};

export const deleteServiceGroupApi = (id) => {
  return api.delete(`${serviceGroupUrl}/${id}`);
};
