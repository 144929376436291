import {getMailApi, updateMailApi} from '../../services/mailService';
import {modalObj} from '../../utils/modal';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

export const getMail = () => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_MAIL_START});
    return getMailApi().then(
      (response) => {
        if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_MAIL_SUCCESS,
              mailsList: response.result,
            });
            return response.result;
        } else {
          dispatch({type: ACTION_TYPES.GET_MAIL_FAIL});
        }
      },
      () => {
        dispatch({tpe: ACTION_TYPES.GET_MAIL_FAIL});
      },
    );
  };
};

export const updateMail = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.UPDATE_MAIL_START});
    return updateMailApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({type: ACTION_TYPES.UPDATE_MAIL_SUCCESS});
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            return response.result;
          } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({type: ACTION_TYPES.UPDATE_MAIL_FAIL});
        }
      },
      () => dispatch({type: ACTION_TYPES.UPDATE_MAIL_FAIL}),
    );
  };
};
