import React, {Component} from 'react';

import {Map, GoogleApiWrapper, Polyline} from 'google-maps-react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';

import {setMessageModal} from '../../stores/modal/actions';
import {modalObj} from '../../utils/modal';

/**
 * Map Setting Route
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      centerPoint: {
        lat: 36.2048,
        lng: 138.2529,
      },
      pickupLocation: {},
      dropoffLocation: {},
      listTransitStopsForRoute: [],
      listTransitStopsMarker: [],
      masterDataRoute: [],
      masterGoogleDuration: 0,
      mapType: '',
      mode: '',
      geofence: {},
      polylineEncodedString: '',
      polyline: '',
      callDirection: false,
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    const {dataRoute} = this.props;
    await this.setState({
      mapType: dataRoute.type,
      geofence: dataRoute.geofence,
      mode: dataRoute.mode,
      polylineEncodedString: dataRoute.routePolyline,
      callDirection: dataRoute.callDirection,
    });
    await this.parseData(dataRoute);
    this.renderElementsOnMap();
  }

  /**
   * componentWillReceiveProps
   * @param {Props} nextProps
   */
  UNSAFE_componentWillReceiveProps = async (nextProps) => {
    const {dataRoute} = nextProps;
    // Chặn việc load map vô hạn vì hàm không dừng
    if (dataRoute.type === 'baseMap') {
      let checkDiff = false;
      for (const [index, point] of Object.entries(dataRoute.listTransitStopsForRoute)) {
        if (point !== this.state.masterDataRoute[index]?.endPoint) {
          checkDiff = true;
          break;
        }
      }
      if (this.state.masterDataRoute.length - 1 !== dataRoute.listTransitStopsForRoute.length) checkDiff = true;
      if (!checkDiff && (dataRoute.routePolyline === this.state.polylineEncodedString)) return;
    }
    await this.setState({
      geofence: dataRoute.geofence,
      mode: dataRoute.mode,
      polylineEncodedString: dataRoute.routePolyline,
      callDirection: dataRoute.callDirection,
    });
    await this.parseData(dataRoute);
    this.renderElementsOnMap();
  }

  /**
   * parseData
   * @param {Object} dataRoute
   */
  parseData = (dataRoute) => {
    const {pickupLocation, dropoffLocation, listTransitStopsForRoute} = {...dataRoute};
    const pickupLocationData = {...pickupLocation};
    const dropoffLocationData = {...dropoffLocation};
    const listTransitStopsForRouteData = [...listTransitStopsForRoute];
    if (pickupLocation?.point && !pickupLocation?.point?.coordinates?.lat) {
      pickupLocationData.point.coordinates = {
        lat: pickupLocation.point.coordinates[1],
        lng: pickupLocation.point.coordinates[0],
      };
    }
    if (dropoffLocation?.point && !dropoffLocation?.point?.coordinates?.lat) {
      dropoffLocationData.point.coordinates = {
        lat: dropoffLocation.point.coordinates[1],
        lng: dropoffLocation.point.coordinates[0],
      };
    }
    for (const [index, transitStop] of Object.entries(listTransitStopsForRouteData)) {
      if (transitStop?.point?.coordinates.lat) continue;
      const newTransitStop = transitStop;
      newTransitStop.point.coordinates = {
        lat: transitStop.point.coordinates[1],
        lng: transitStop.point.coordinates[0],
      };
      listTransitStopsForRouteData[index] = newTransitStop;
    }
    this.setState({
      pickupLocation: pickupLocationData,
      dropoffLocation: dropoffLocationData,
      listTransitStopsForRoute: listTransitStopsForRouteData,
    });
  }

  /**
   * renderMarkers
   * @param {Object} point
   * @param {Boolean} isPickupPoint
   * @param {Boolean} isDropoffPoint
   * @return {*}
   */
  renderMarkers = (point, isPickupPoint = false, isDropoffPoint = false) => {
    if (Object.keys(point).length === 0) return;
    const {google} = this.props;
    const {mode} = this.state;
    const map = this.mapRef.map;
    const iconSize = !(isPickupPoint || isDropoffPoint) ? 45 : (
      ((isPickupPoint && mode === 'SHUTTLE_BUS_JIT_HOME_TO_WORK') ||
      (isDropoffPoint && mode === 'SHUTTLE_BUS_JIT_WORK_TO_HOME')) ? 60 : 35
    );

    let urlImage = '';
    if (isPickupPoint || isDropoffPoint) {
      if (point.is_work) urlImage = `${process.env.PUBLIC_URL}/images/reservation/work_icon.svg`;
      else urlImage = isPickupPoint ? `${process.env.PUBLIC_URL}/images/ic_pick_origin.svg` : `${process.env.PUBLIC_URL}/images/ic_pick_destination.svg`;
    } else {
      urlImage = ((mode === 'SHUTTLE_BUS_JIT_HOME_TO_WORK' && !point.is_work) || (mode === 'SHUTTLE_BUS_JIT_WORK_TO_HOME' && point.is_work)) ?
        `${process.env.PUBLIC_URL}/images/pick_up_point.svg` :
        `${process.env.PUBLIC_URL}/images/drop_off_point.svg`;
    }
    const marker = new google.maps.Marker({
      position: point.point.coordinates,
      icon: {
        url: urlImage,
        scaledSize: new google.maps.Size(iconSize, iconSize),
        strokeWeight: 2,
        fillOpacity: 1,
      },
      map: map,
    });
    marker.info = new google.maps.InfoWindow({
      content: point.displayName,
    });
    (this.state.mapType === 'drawRouteMap') && marker.info.open(this.mapRef.map, marker);
    google.maps.event.addListener(marker, 'click', () => {
      marker.info.open(this.mapRef.map, marker);
    });
    return marker;
  }

  /**
   * renderDirections
   * @param {Array} listWaypoint
   */
  renderDirections = (listWaypoint) => {
    const {google} = this.props;

    // Render new direction
    const directionsService = new google.maps.DirectionsService();

    // Detect origin/destination point
    if (this.state.pickupLocation.point && this.state.pickupLocation.point.coordinates.lat) {
      listWaypoint.splice(0, 0, this.state.pickupLocation);
      listWaypoint.push(this.state.dropoffLocation);
    }

    // Chia list waypoint ra để xử lý từng 27 (2 + 25) điểm vì giới hạn của api google
    // Tính thêm thời gian đi giữa mỗi đoạn nên là 26
    if (listWaypoint.length < 2 || listWaypoint.find((item) => Object.keys(item).length === 0)) return;
    const loop = Math.ceil((listWaypoint.length - 1) / 26);
    for (let i = 0; i < loop; i++) {
      const listWaypointSplit = (26 * (i + 1) + 1 < listWaypoint.length) ? listWaypoint.slice(26 * i, 26 * (i + 1) + 1) : listWaypoint.slice(26 * i, listWaypoint.length);
      directionsService.route({
        origin: listWaypointSplit[0].point?.coordinates,
        destination: listWaypointSplit[listWaypointSplit.length - 1].point?.coordinates,
        waypoints: listWaypointSplit.length > 2 ? listWaypointSplit?.slice(1, listWaypointSplit.length - 2)?.map((item) => {
          return {
            location: item.point.coordinates,
            stopover: true,
          };
        }) : [],
        optimizeWaypoints: false,
        travelMode: google.maps.TravelMode.DRIVING,
      }).then(async (response) => {
        if (this.state.mapType === 'baseMap') {
          let {masterDataRoute, masterGoogleDuration} = this.state;
          const listRoutes = response?.routes[0].legs;
          const listPoints = [...listWaypoint];
          this.state.pickupLocation.point && this.state.pickupLocation.point.coordinates.lat && listPoints.splice(0, 0, this.state.pickupLocation);
          this.state.dropoffLocation.point && this.state.dropoffLocation.point.coordinates.lat && listPoints.push(this.state.dropoffLocation);
          const dataRoute = await listRoutes.map((item, index) => {
            return {
              duration: item.duration.value,
              startPoint: listPoints[index],
              endPoint: listPoints[index + 1],
            };
          });
          if (!masterDataRoute.includes(dataRoute[0])) {
            masterDataRoute = (i > 0) ? await masterDataRoute.concat(dataRoute) : dataRoute;
            let googleDuration = 0;
            for (const route of dataRoute) {
              googleDuration += route.duration;
            }
            masterGoogleDuration = (i > 0) ? masterGoogleDuration + googleDuration : googleDuration;
            await this.setState({masterDataRoute, masterGoogleDuration});
          }
          this.props.getGoogleDuration(this.state.masterGoogleDuration);
        }
      }).catch(() => {
        this.props.setMessageModal(modalObj(true, this.props.t('Api.fail')));
      });
    }
  }

  /**
   * renderGeofence
   */
  renderGeofence = () => {
    const {geofence} = this.state;

    if (Object.keys(geofence).length === 0) return;
    // add new geojson (polygon and multi polygon)
    if (geofence.geometry.type === 'Polygon') {
      const jsonData = {
        type: 'Feature',
        properties: {
          id: geofence.id,
          geofence_id: geofence.geofence_id,
        },
        geometry: {
          type: geofence.geometry.type,
          coordinates: geofence.geometry.coordinates,
        },
      };
      this.mapRef.map.data.addGeoJson(jsonData);
    }
    if (geofence.geometry.type === 'MultiPolygon') {
      geofence.geometry.coordinates.forEach((value) => {
        const jsonData = {
          type: 'Feature',
          properties: {
            id: geofence.id,
            geofence_id: geofence.geofence_id,
          },
          geometry: {
            type: 'Polygon',
            coordinates: value,
          },
        };
        this.mapRef.map.data.addGeoJson(jsonData);
      });
    }
    // set style to geofence
    this.mapRef.map.data.setStyle({
      strokeColor: '#99cecf',
      fillColor: '#99cecf',
      fillOpacity: 0.5,
      strokeWeight: 0.1,
    });
  }

  /**
   * renderElementsOnMap
   */
  renderElementsOnMap = () => {
    if (!this.mapRef) return;
    const {google} = this.props;

    // remove all data added
    this.mapRef.map.data.forEach((feature) => {
      this.mapRef.map.data.remove(feature);
    });

    // Render transit stop
    const listTransitStopsMarker = [...this.state.listTransitStopsMarker];
    for (const transitStopsMarker of listTransitStopsMarker) {
      transitStopsMarker && transitStopsMarker.setMap(null);
    }
    for (const transitStops of this.state.listTransitStopsForRoute) {
      const newTransitStopsMarker = this.renderMarkers(transitStops);
      listTransitStopsMarker.push(newTransitStopsMarker);
    }

    // Render pickup/dropoff point
    if (this.state.dropoffLocation.point && this.state.dropoffLocation.point.coordinates.lat) {
      const dropoffMarker = this.renderMarkers(this.state.dropoffLocation, false, true);
      listTransitStopsMarker.push(dropoffMarker);
    }
    if (this.state.pickupLocation.point && this.state.pickupLocation.point.coordinates.lat) {
      const pickupMarker = this.renderMarkers(this.state.pickupLocation, true);
      listTransitStopsMarker.push(pickupMarker);
    }
    this.setState({listTransitStopsMarker});

    // Render Directions
    this.state.callDirection && this.renderDirections(this.state.listTransitStopsForRoute);

    // Render Geofence
    this.renderGeofence();

    // Set Map to center
    const bounds = new google.maps.LatLngBounds();
    this.mapRef.map.data.forEach((feature) => {
      feature.getGeometry().forEachLatLng(function(latlng) {
        bounds.extend(latlng);
      });
    });
    this.mapRef.map.fitBounds(bounds);
    const zoomChangeBoundsListener = google.maps.event.addListenerOnce(this.mapRef.map, 'bounds_changed', function() {
      if (this.getZoom()) this.setZoom(13);
    });
    setTimeout(function() {
      google.maps.event.removeListener(zoomChangeBoundsListener);
    }, 2000);

    // Render Polyline
    if (this.state.polylineEncodedString === '' || Object.keys(this.state.geofence).length === 0) {
      this.setState({polyline: ''});
      return;
    }
    const decodedPath = this.props.google.maps.geometry.encoding.decodePath(this.state.polylineEncodedString);
    const polylineData = decodedPath.map((item) => item = {
      lat: item.lat(),
      lng: item.lng(),
    });
    this.setState({
      polyline: (
        <Polyline
          path={polylineData}
          strokeColor="#3f51b5"
          strokeOpacity={0.8}
          strokeWeight={7}
        />
      ),
    });
  }

  /**
   * render
   * @return {HTMLElement}
   */
  render() {
    return (
      <div>
        <Map
          {...this.props}
          ref={(ref) => (this.mapRef = ref)}
          initialCenter={this.state.centerPoint}
          zoom={15}
          centerAroundCurrentLocation={false}
          containerStyle={{
            height: '500px',
            position: 'relative',
            width: this.state.mapType && this.state.mapType === 'baseMap' ? '52.5vw' : '48vw',
            maxWidth: '1000px',
          }}
        >
          {this.state.polyline}
        </Map>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
  };
};

// eslint-disable-next-line new-cap
export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper((props) => ({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || 'AIzaSyACyapw83diO1bi_xiXbZRLLoano6eTwd0',
  language: props.localLanguage,
  libraries: ['geometry'],
}))(Index)));
