import React, {Component} from 'react';

import {
  Grid,
  Button,
  TextField,
  Paper,
  Card,
  Container,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Dialog,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Box,
  RadioGroup,
  Radio,
  Switch,
} from '@material-ui/core';
import {ArrowBackIos, ArrowForward, ArrowForwardIos} from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import MoveUp from '@material-ui/icons/ExpandLess';
import MoveDown from '@material-ui/icons/ExpandMore';
import CompareArrowsIcon from '@material-ui/icons/SwapHoriz';
import {format} from 'date-fns';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {ROUTE, CURRENCY, PERMISSION_ACTIONS, PAYMENT_METHODS_ONETIME_PRICE, TIME_FORMAT, SPECIAL_PERSON_TYPE} from '../../../common/constant';
import CustomTimePicker from '../../../components/CustomTimePicker';
import Memo from '../../../components/memo/index';
import MessageContentModal from '../../../components/messageContentModal';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getListGeofence, getListGeofenceByCountry, getAllCountryCode} from '../../../stores/common/actions';
import {createOneTimePrice, getOneTimePriceDetails, updateOneTimePrice} from '../../../stores/one_time_price/actions';
import {onChangeSelect, onChangeListData, backForwardRouter, onChangeNumberCurrency} from '../../../utils/common';
import {displayTextErrorTimeInvalid, isOverlapDateRelatively} from '../../../utils/datetime';
import './style.css';

/**
 * One Time Price Form Component
 */
class OneTimeDetail extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.focusSurcharge = React.createRef();
    this.state = {
      id: '',
      country_id: '',
      geofence_id: '',
      currency_code: '',
      payment_methods: [],
      memos: [],
      note: '',
      child_price: '',
      adult_price: '',

      flgUpdate: false,
      message: '',
      isSubmitForm: false,
      flag: false,
      isErrorModalOpened: false,
      addition_fee_adult: '',
      addition_fee_child: '',
      one_time_price_id: null,
      openModalSurcharge: false,
      operatingTimeOfGeofence: {},
      frame_time_descriptions: {},
      time_ranges_charge: [],
      openPopupClear: false,
      messagePopupUpdate: '',
      messsagePopupClear: '',
      overlapList: new Set(),
      specialFeeList: [],
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    await this.props.getAllCountryCode();
    if (this.props.match.params.id) {
      await this.getOneTimeData(this.props.match.params.id);
    }
  };

  /**
   * Get data response one time price
   * @param {*} id
   */
  getOneTimeData = async (id) => {
    await this.props.getOneTimePriceDetails(id).then(async (response) => {
      const {
        country_id,
        geofence_id,
        currency_code,
        payment_methods,
        id,
        memos,
        adult_price,
        child_price,
        price,
        one_time_extend_responses,
        frame_time_prices,
        frame_time_descriptions,
        one_time_price_specials,
      } = response;
      const isOneTimeAdvanceFee = one_time_extend_responses?.length > 0;
      let time_ranges_charge_copy = [];
      if (frame_time_prices?.length > 0) {
        time_ranges_charge_copy = frame_time_prices.map((item) => {
          item.from_time = new Date(`1970-01-01T${item.from_time}`);
          item.to_time = new Date(`1970-01-01T${item.to_time}`);
          const newPropertyName = 'isHidden';
          for (const key in item.frame_time_descriptions) {
            if (item.frame_time_descriptions.hasOwnProperty(key)) {
              if (item.frame_time_descriptions[key] === null) {
                item.frame_time_descriptions[key] = {};
                item.frame_time_descriptions[key][newPropertyName] = true;
              } else {
                const newPropertyValue = !!!item.frame_time_descriptions[key]['en'];
                item.frame_time_descriptions[key][newPropertyName] = newPropertyValue;
              }
            }
          }
          return item;
        });
      }

      this.setState({
        country_id,
        geofence_id,
        currency_code,
        payment_methods,
        id,
        memos,
        adult_price,
        child_price,
        price,
        addition_fee_adult: isOneTimeAdvanceFee ? one_time_extend_responses[0]?.additional_adult_price : 0,
        addition_fee_child: isOneTimeAdvanceFee ? one_time_extend_responses[0]?.additional_child_price : 0,
        one_time_price_id: isOneTimeAdvanceFee ? one_time_extend_responses[0]?.id : null,
        time_ranges_charge: time_ranges_charge_copy?.length > 0 ? time_ranges_charge_copy : [],
        frame_time_descriptions: frame_time_descriptions,
        specialFeeList: one_time_price_specials || [],
      });
      await this.props.getListGeofenceByCountry(country_id, {targetScreen: 'ENABLE_FOR_ADMIN_ALL'});
      this.getOperatingTimeGeofence(geofence_id);
    });
  };
  /**
   * changeCountry
   * @param {*} event
   */
  changeCountry = async (event) => {
    const country_id = event.target.value;
    const specialFeeList = this.state.specialFeeList;
    if (specialFeeList?.length > 0) {
      for (const special_fee of specialFeeList) {
        if (special_fee.price !== 0) {
          special_fee.price = '';
        }
      }
      this.setState({specialFeeList});
    }
    this.setState({country_id, geofence_id: '', currency_code: '', adult_price: '', child_price: '', addition_fee_adult: '', addition_fee_child: ''});
    await this.props.getListGeofenceByCountry(country_id);
  };

  /**
   *
   * @param {boolean} value
   * @param {string} item
   */
  changePaymentMethods = (value, item) => {
    const payment_methods_copy = this.state.payment_methods;
    const index = payment_methods_copy.indexOf(item);
    value ? payment_methods_copy.push(item) : payment_methods_copy.splice(index, 1);

    this.setState({
      payment_methods: payment_methods_copy,
    });
  };

  /**
   * handleChangeTypeSpecialPerson
   * @param {Event} event
   * @param {number} index
   */
  handleChangeTypeSpecialPerson = (event, index) => {
    const specialFeeList = this.state.specialFeeList;
    specialFeeList[index].passenger_type = event.target.value;
    this.setState({specialFeeList});
  };

  /**
   * handleChangeDisplayUserApp
   * @param {Event} event
   * @param {number} index
   */
  handleChangeDisplayUserApp = (event, index) => {
    const specialFeeList = this.state.specialFeeList;
    specialFeeList[index].display_for_user = event.target.checked;
    if (event.target.checked === false) {
      specialFeeList[index].enable_popup = false;
      specialFeeList[index].enable_description = false;
    }
    this.setState({specialFeeList});
  };

  /**
   * handleChangeDisplayPopup
   * @param {Event} event
   * @param {number} index
   */
  handleChangeDisplayPopup = (event, index) => {
    const specialFeeList = this.state.specialFeeList;
    if (specialFeeList[index].display_for_user) {
      specialFeeList[index].enable_popup = event.target.checked;
    } else {
      return;
    }
    this.setState({specialFeeList});
  };

  /**
   * handleChangeDisplayNotification
   * @param {Event} event
   * @param {number} index
   */
  handleChangeDisplayNotification = (event, index) => {
    const specialFeeList = this.state.specialFeeList;
    if (specialFeeList[index].display_for_user) {
      specialFeeList[index].enable_description = event.target.checked;
    } else {
      return;
    }
    this.setState({specialFeeList});
  };

  /**
   * handleChangeDescriptionSpecialPassenger
   * @param {Event} event
   * @param {number} index
   * @param {string} type
   * @param {number} index2
   */
  handleChangeDescriptionSpecialPassenger = (event, index, type, index2) => {
    const specialFeeList = this.state.specialFeeList;
    if (specialFeeList?.length > 0) {
      specialFeeList[index].special_languages[index2][type] = event.target.value;
    }
    this.setState({specialFeeList});
  };

  /**
   * onChangePriceSpecialFee
   * @param {Event} event
   * @param {string} currency_code
   * @param {number} index
   */
  onChangePriceSpecialFee = (event, currency_code, index) => {
    const value = event.target.value;
    const specialFeeList = this.state.specialFeeList;

    const regexPositveDecimal = /^-?(?:\d+(\.\d*)?|\.\d+|)$/;
    const regexPositveNumber = /^-?\d*$/;

    // Validate
    if (value !== '') {
      if (currency_code === 'SGD' || currency_code === 'MYR') {
        if (!regexPositveDecimal.test(value)) return;
        if (!isNaN(value) && value.includes('.')) {
          const number = value.split('.')[1].length;
          if (number > 2) return;
        }
      } else {
        if (!regexPositveNumber.test(value)) return;
      }
    }

    specialFeeList[index].price = value;
    this.setState({specialFeeList});
  };

  /**
   * Handle select geofence
   * @param {Event} event
   */
  handleChangeGeofence = (event) => {
    const geofence_id = event.target.value;
    const simulations = this.props.common?.geofence_by_country?.find((geo) => geo.geofence_id === geofence_id)?.simulations;
    this.setState({
      geofence_id,
      currency_code: simulations[0]?.currency_code || '',
      adult_price: '',
      child_price: '',
      addition_fee_adult: '',
      addition_fee_child: '',
      time_ranges_charge: [
        {
          from_time: null,
          to_time: null,
          adult_price: '',
          child_price: '',
          frame_time_descriptions: {
            ticket: {
              en: '',
              ja: '',
              vi: '',
              isHidden: true,
            },
            details: {
              en: '',
              ja: '',
              vi: '',
              isHidden: true,
            },
            onetime: {
              en: '',
              ja: '',
              vi: '',
              isHidden: true,
            },
            subscription: {
              en: '',
              ja: '',
              vi: '',
              isHidden: true,
            },
          },
        },
      ],
    });
    this.getOperatingTimeGeofence(geofence_id);
  };

  /**
   * handleButtonCancel
   */
  handleButtonUpdateCancel = () => {
    this.setState({
      flag: false,
    });
  };

  /**
   * handleButtonOk
   */
  handleButtonUpdateOk = async () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid()) {
      const {
        id,
        geofence_id,
        currency_code,
        payment_methods,
        note,
        memos,
        adult_price,
        child_price,
        addition_fee_adult,
        addition_fee_child,
        one_time_price_id,
        time_ranges_charge,
        frame_time_descriptions,
        specialFeeList,
      } = this.state;
      let time_ranges_charge_copy = [];
      if (time_ranges_charge?.length > 0) {
        time_ranges_charge_copy = _.cloneDeep(time_ranges_charge).map((item) => {
          item.from_time = format(new Date(item.from_time), 'HH:mm');
          item.to_time = format(new Date(item.to_time), 'HH:mm');
          item.adult_price = item?.adult_price || 0;
          item.child_price = item?.child_price || 0;
          return item;
        });
      }
      const payload = {
        id,
        geofence_id,
        currency_code,
        payment_methods,
        note: note.trim(),
        memos,
        adult_price: Number(adult_price),
        child_price: Number(child_price),
        one_time_price_extend_requests:
          addition_fee_adult === '' && addition_fee_child === '' ?
            [] :
            [
                {
                  additional_adult_price: addition_fee_adult !== '' ? addition_fee_adult : 0,
                  additional_child_price: addition_fee_child !== '' ? addition_fee_child : 0,
                  booking_type: 'ADVANCE',
                  id: one_time_price_id,
                },
              ],
        frame_times: time_ranges_charge_copy,
        frame_time_descriptions: frame_time_descriptions,
        one_time_price_specials: specialFeeList.map((special, index) => ({...special, order_number: index + 1})),
      };
      await this.props.updateOneTimePrice(payload, this.props).then((response) => {
        if (response && response.status === 200) {
          this.getOneTimeData(this.props.match.params.id);
        }
        if (response && response.status === 400 && response.message_code === 'error.one.time.price.less.than.plan.discount') {
          this.setState({isErrorModalOpened: true, errorData: response.result});
        }
      });
      this.setState({flag: false, isSubmitForm: false});
    }
  };

  /**
   * handleClickCreateButton
   */
  handleClickCreateButton = async () => {
    this.setState({
      isSubmitForm: true,
    });
    let validateTimeRangeSurcharge = false;
    const checkTimeOverlap = await this.checkTimeRangesOverlap(this.state.time_ranges_charge);
    const validatePrice = this.state.time_ranges_charge?.findIndex((item) => !item.adult_price && !item.child_price) >= 0;
    const validateTimeRangeRequired = this.state.time_ranges_charge?.findIndex((item) => (!item.from_time && !!item.to_time) || (!!item.from_time && !item.to_time)) >= 0;
    const validateTimeRange = this.state.time_ranges_charge?.findIndex((item) => item.from_time >= item.to_time) >= 0;
    validateTimeRangeSurcharge = !validateTimeRange && !validateTimeRangeRequired && checkTimeOverlap && !validatePrice;
    const validateDescriptionValid = this.isValidDescription();
    const validateRangeCharge = this.validateCharge();
    const isValidPaymentMethod = this.state.payment_methods?.length === 1 && this.state.payment_methods?.includes(PAYMENT_METHODS_ONETIME_PRICE[2].id);
    const invalidSpecialFeeWithSurcharge = this.invalidSpecialFeeWithSurcharge();
    if (invalidSpecialFeeWithSurcharge || !validateRangeCharge) {
      this.focusSurcharge.current.scrollIntoView({behavior: 'smooth'});
    }
    if (
      this.validator.allValid() &&
      validateTimeRangeSurcharge &&
      validateDescriptionValid &&
      validateRangeCharge &&
      !isValidPaymentMethod &&
      !invalidSpecialFeeWithSurcharge
    ) {
      this.setState({flag: true, message: 'oneTimePrice.confirmRegister'});
    }
  };

  /**
   * handleUpdate
   */
  handleUpdate = async () => {
    this.setState({
      isSubmitForm: true,
    });
    let validateTimeRangeSurcharge = false;
    const checkTimeOverlap = await this.checkTimeRangesOverlap(this.state.time_ranges_charge);
    const validatePrice = this.state.time_ranges_charge?.findIndex((item) => !item.adult_price && !item.child_price) >= 0;
    const validateTimeRangeRequired = this.state.time_ranges_charge?.findIndex((item) => (!item.from_time && !!item.to_time) || (!!item.from_time && !item.to_time)) >= 0;
    const validateTimeRangeInvalid = this.state.time_ranges_charge?.findIndex((item) => item.from_time >= item.to_time) >= 0;
    validateTimeRangeSurcharge = !validateTimeRangeInvalid && !validateTimeRangeRequired && checkTimeOverlap && !validatePrice;
    const validateDescriptionValid = this.isValidDescription();
    const validateRangeCharge = this.validateCharge();
    const isValidPaymentMethod = this.state.payment_methods?.length === 1 && this.state.payment_methods?.includes(PAYMENT_METHODS_ONETIME_PRICE[2].id);
    const invalidSpecialFeeWithSurcharge = this.invalidSpecialFeeWithSurcharge();
    if (invalidSpecialFeeWithSurcharge || !validateRangeCharge) {
      this.focusSurcharge.current.scrollIntoView({behavior: 'smooth'});
    }
    if (
      this.validator.allValid() &&
      validateTimeRangeSurcharge &&
      validateDescriptionValid &&
      validateRangeCharge &&
      !isValidPaymentMethod && !invalidSpecialFeeWithSurcharge
    ) {
      this.setState({
        flag: true,
        message: 'oneTimePrice.confirmUpdate',
      });
    }
  };

  /**
   * handleCreate
   */
  handleCreate = async () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid()) {
      const {geofence_id, currency_code, payment_methods, memos, adult_price, child_price, addition_fee_adult, addition_fee_child, time_ranges_charge, specialFeeList} = this.state;
      let time_ranges_charge_copy = [];
      if (time_ranges_charge?.length > 0) {
        time_ranges_charge_copy = _.cloneDeep(time_ranges_charge).map((item) => {
          item.from_time = format(new Date(item.from_time), 'HH:mm');
          item.to_time = format(new Date(item.to_time), 'HH:mm');
          item.adult_price = item?.adult_price || 0;
          item.child_price = item?.child_price || 0;
          return item;
        });
      }
      const payload = {
        geofence_id,
        currency_code,
        payment_methods,
        memos,
        adult_price: Number(adult_price),
        child_price: Number(child_price),
        one_time_price_extend_requests:
          addition_fee_adult === '' && addition_fee_child === '' ?
            [] :
            [
                {
                  additional_adult_price: addition_fee_adult !== '' ? addition_fee_adult : 0,
                  additional_child_price: addition_fee_child !== '' ? addition_fee_child : 0,
                  booking_type: 'ADVANCE',
                },
              ],
        frame_times: time_ranges_charge_copy,
        one_time_price_specials: specialFeeList.map((special, index) => ({...special, order_number: index + 1})),
      };
      await this.props.createOneTimePrice(payload, this.props).then((response) => {
        if (response && response.status === 400 && response.message_code === 'error.one.time.price.less.than.plan.discount') {
          this.setState({isErrorModalOpened: true, errorData: response.result});
        }
      });
      this.setState({flag: false, isSubmitForm: false});
    }
  };

  /**
   * getOperatingTimeGeofence
   * @param {*} geofence_id
   */
  getOperatingTimeGeofence = (geofence_id) => {
    const geofence = this.props.common.geofence_by_country?.find((item) => item?.geofence_id === geofence_id);
    this.setState({
      operatingTimeOfGeofence: {startTime: geofence?.operating_start_time, endTime: geofence?.operating_end_time},
    });
  };

  /**
   * Add new record for list range
   * @param {*} rangeInfo infomation of range
   */
  addRange = (rangeInfo) => {
    const time_ranges_charge_copy = [...this.state.time_ranges_charge];
    const index = time_ranges_charge_copy.indexOf(rangeInfo);
    const record = {
      from_time: null,
      to_time: null,
      adult_price: '',
      child_price: '',
      frame_time_descriptions: {
        ticket: {
          en: '',
          ja: '',
          vi: '',
          isHidden: true,
        },
        details: {
          en: '',
          ja: '',
          vi: '',
          isHidden: true,
        },
        onetime: {
          en: '',
          ja: '',
          vi: '',
          isHidden: true,
        },
        subscription: {
          en: '',
          ja: '',
          vi: '',
          isHidden: true,
        },
      },
    };
    time_ranges_charge_copy.splice(index + 1, 0, record);
    this.setState({time_ranges_charge: time_ranges_charge_copy});
    this.checkTimeRangesOverlap(time_ranges_charge_copy);
  };

  /**
   * deleteRange
   * @param {*} frameDeleted
   */
  deleteRange = (frameDeleted) => {
    const time_ranges_charge_copy = [...this.state.time_ranges_charge];
    const indexDeleted = time_ranges_charge_copy.indexOf(frameDeleted);
    time_ranges_charge_copy.splice(indexDeleted, 1);
    this.setState({time_ranges_charge: time_ranges_charge_copy});
    this.checkTimeRangesOverlap(time_ranges_charge_copy);
  };

  /**
   * addSpecialFeeRow
   * @param {number} index
   */
  addSpecialFeeRow = (index) => {
    const specialFeeList = this.state.specialFeeList;
    const specialFee = {
      special_languages: [
        {
          language_id: 1,
          name: '',
          popup_title: '',
          popup_content: '',
          description: '',
        },
        {
          language_id: 2,
          name: '',
          popup_title: '',
          popup_content: '',
          description: '',
        },
        {
          language_id: 3,
          name: '',
          popup_title: '',
          popup_content: '',
          description: '',
        },
      ],
      passenger_type: SPECIAL_PERSON_TYPE[0].value,
      display_for_user: true,
      enable_popup: true,
      enable_description: true,
      price: '',
    };
    specialFeeList.push(specialFee);
    this.setState({specialFeeList});
  };

  /**
   * deleteSpecialFeeRow
   * @param {number} index
   */
  deleteSpecialFeeRow = (index) => {
    const specialFeeList = this.state.specialFeeList;
    specialFeeList.splice(index, 1);
    if (specialFeeList?.length === 0) {
      this.validator.purgeFields();
    }
    this.setState({specialFeeList});
  };

  /**
   * onClickMoveUpAndDownSpecialList
   *  @param {number} index
   * @param {string} moveType
   */
  onClickMoveUpAndDownSpecialList = (index, moveType) => {
    const specialFeeList = this.state.specialFeeList;
    if (moveType === 'UP') {
      if (index === 0) return; // If the object is already at the top, do nothing
      const temp = specialFeeList[index - 1]; // Swap the object with the one above it
      specialFeeList[index - 1] = specialFeeList[index];
      specialFeeList[index] = temp;
    } else {
      if (specialFeeList === specialFeeList.length - 1) return; // If the object is already at the bottom, do nothing
      const temp = specialFeeList[index + 1]; // Swap the object with the one below it
      specialFeeList[index + 1] = specialFeeList[index];
      specialFeeList[index] = temp;
    }
    this.setState({specialFeeList});
  };

  /**
   * checkTimeRangesOverlap
   * @param {*} time_ranges_charge
   * @return {*}
   */
  checkTimeRangesOverlap = async (time_ranges_charge) => {
    const overlapList = new Set();
    for (let i = 0; i < time_ranges_charge?.length - 1; i++) {
      const start1 = time_ranges_charge[i]?.from_time ? format(new Date(time_ranges_charge[i]?.from_time), 'HH:mm') : null;
      const end1 = time_ranges_charge[i]?.to_time ? format(new Date(time_ranges_charge[i]?.to_time), 'HH:mm') : null;
      for (let j = i + 1; j < time_ranges_charge?.length; j++) {
        const start2 = time_ranges_charge[j]?.from_time ? format(new Date(time_ranges_charge[j]?.from_time), 'HH:mm') : null;
        const end2 = time_ranges_charge[j]?.to_time ? format(new Date(time_ranges_charge[j]?.to_time), 'HH:mm') : null;
        if (isOverlapDateRelatively(start1, end1, start2, end2)) {
          overlapList.add(i);
          overlapList.add(j);
        }
      }
    }
    this.setState({overlapList});
    return overlapList.size === 0;
  };

  /**
   * onChangeRange
   * @param {*} value
   * @param {*} rangeInfo
   * @param {*} fieldName
   */
  onChangeRange = (value, rangeInfo, fieldName) => {
    const time_ranges_charge_copy = [...this.state.time_ranges_charge];
    const index = time_ranges_charge_copy.indexOf(rangeInfo);
    time_ranges_charge_copy[index][fieldName] = value;
    this.setState({time_ranges_charge: time_ranges_charge_copy});
    this.checkTimeRangesOverlap(time_ranges_charge_copy);
  };

  /**
   * checkValidRange
   * @param {*} item
   * @return {Boolean} return true if time range is valid
   */
  checkValidTimeRange = (item) => {
    if (item?.from_time && item?.to_time) {
      return item.from_time < item.to_time;
    } else return true;
  };

  /**
   * onChangePrice
   * @param {*} event
   * @param {*} rangeInfo
   */
  onChangePrice = (event, rangeInfo) => {
    const value = event.target.value;
    const {currency_code} = this.state;
    const time_ranges_copy = [...this.state.time_ranges_charge];
    const index = time_ranges_copy.indexOf(rangeInfo);
    const regexPositveDecimal = /^-?(?:\d+(\.\d*)?|\.\d+|)$/;
    const regexPositveNumber = /^-?\d*$/;

    // Validate
    if (value !== '') {
      if (currency_code === 'SGD' || currency_code === 'MYR') {
        if (!regexPositveDecimal.test(value)) return;
        if (!isNaN(value) && value.includes('.')) {
          const number = value.split('.')[1].length;
          if (number > 2) return;
        }
      } else {
        if (!regexPositveNumber.test(value)) return;
      }
    }

    time_ranges_copy[index][event.currentTarget.name] = value;
    this.setState({
      time_ranges_charge: time_ranges_copy,
    });
  };

  /**
   * onChangeDescription
   * @param {*} rangeInfo
   * @param {*} description_value
   * @param {*} description_title
   * @param {*} language
   */
  onChangeDescription = (rangeInfo, description_value, description_title, language) => {
    const time_ranges_charge_copy = [...this.state.time_ranges_charge];
    const index = time_ranges_charge_copy.indexOf(rangeInfo);
    const frameTimeDescriptions = time_ranges_charge_copy[index]['frame_time_descriptions'] || {};
    frameTimeDescriptions[description_title] = frameTimeDescriptions[description_title] || {};
    frameTimeDescriptions[description_title][language] = description_value;
    time_ranges_charge_copy[index]['frame_time_descriptions'] = frameTimeDescriptions;
    this.setState({
      time_ranges_charge: time_ranges_charge_copy,
    });
  };

  /**
   * handleHiddenDescription
   * @param {*} rangeInfo
   * @param {*} description_title
   */
  handleHiddenDescription = (rangeInfo, description_title) => {
    const time_ranges_charge_copy = [...this.state.time_ranges_charge];
    const index = time_ranges_charge_copy.indexOf(rangeInfo);
    const frameTimeDescriptions = time_ranges_charge_copy[index]['frame_time_descriptions'];
    const isHidden = frameTimeDescriptions[description_title]['isHidden'];
    frameTimeDescriptions[description_title].isHidden = !isHidden;
    this.setState({
      time_ranges_charge: time_ranges_charge_copy,
    });
  };

  /**
   * isInvalidDescription
   * @param {*} description_value
   * @param {*} description_multi_language
   * @param {*} language
   * @return {Boolean}
   */
  isInvalidDescription = (description_value, description_multi_language, language) => {
    if (!!!description_multi_language) return false;
    const description_multi_languge_copy = _.cloneDeep(description_multi_language);
    delete description_multi_languge_copy?.[language];
    const arr_value = Object.values(description_multi_languge_copy);
    if (description_value?.trim() === '') {
      const isEmpty = arr_value[0] !== '' || arr_value[1] !== '';
      return isEmpty;
    }
    return false;
  };

  /**
   * isValidDescription
   * @return {Boolean}
   */
  isValidDescription = () => {
    const frame_time_descriptions_copy = this.state.time_ranges_charge.map((item) => item.frame_time_descriptions);
    const isInvalidValidate = frame_time_descriptions_copy.some((frame_time_description) => {
      const frame_time_description_copy = Object.values(frame_time_description);
      return frame_time_description_copy.some((item) => {
        if (!!!item) return false;
        delete item.isHidden;
        const multi_language_values = Object.values(item);
        return !(multi_language_values.every((value) => value !== '') || multi_language_values.every((value) => value === ''));
      });
    });
    return !isInvalidValidate;
  };

  /**
   * validateCharge
   * @return {Boolean}
   */
  validateCharge = () => {
    const isInvalidValidate = this.state.time_ranges_charge.some((charge) => {
      return (
        (charge.adult_price && charge.adult_price < 0 && Math.abs(charge.adult_price) > Number(this.state.adult_price)) ||
        (charge.child_price && charge.child_price < 0 && Math.abs(charge.child_price) > Number(this.state.child_price)) ||
        (Number(charge.adult_price) === 0 && Number(charge.child_price) === 0)
      );
    });
    return !isInvalidValidate;
  };


  /**
   * validSpecialFeeWithSurcharge
   * @return {Boolean}
   */
  invalidSpecialFeeWithSurcharge = () => {
    const {specialFeeList} = this.state;
    const specialFeeAdultList = specialFeeList.filter((special) => special.passenger_type === SPECIAL_PERSON_TYPE[0].value);
    const specialFeeChildList = specialFeeList.filter((special) => special.passenger_type === SPECIAL_PERSON_TYPE[1].value);
    let isCheckSpecialAdult = false;
    let isCheckSpecialChild = false;
    if (specialFeeAdultList?.length > 0) {
      isCheckSpecialAdult = _.some(this.state.time_ranges_charge, (charge) =>
        _.some(specialFeeAdultList, (special) => Math.abs(charge.adult_price) > special.price && charge.adult_price < 0),
      );
    }
    if (specialFeeChildList?.length > 0) {
      isCheckSpecialChild = _.some(this.state.time_ranges_charge, (charge) =>
        _.some(specialFeeChildList, (special) => Math.abs(charge.child_price) > special.price && charge.child_price < 0),
      );
    }
    return isCheckSpecialAdult || isCheckSpecialChild;
  };

  /**
   * checkDiscountSpecialFee
   * @param {*} surchargeAdult
   * @param {*} surchargeChild
   * @return {Boolean}
   */
  checkDiscountSpecialFee = (surchargeAdult, surchargeChild) => {
    const {specialFeeList} = this.state;
    const specialFeeAdultList = specialFeeList.filter((special) => special.passenger_type === SPECIAL_PERSON_TYPE[0].value);
    const specialFeeChildList = specialFeeList.filter((special) => special.passenger_type === SPECIAL_PERSON_TYPE[1].value);
    let isCheckSpecialAdult = false;
    let isCheckSpecialChild = false;
    if (specialFeeAdultList?.length > 0) {
      isCheckSpecialAdult = specialFeeAdultList.some((special) => Math.abs(surchargeAdult) > special.price && surchargeAdult < 0);
    }
    if (specialFeeChildList?.length > 0) {
      isCheckSpecialChild = specialFeeChildList.some((special) => Math.abs(surchargeChild) > special.price && surchargeChild < 0);
    }
    return isCheckSpecialAdult || isCheckSpecialChild;
  };

  /**
   * Render component
   * @return {Component}
   */
  render() {
    const {one_time_price, t, common, actions} = this.props;
    const {geofence_by_country} = common;
    const {isSubmitForm, flag, message, isErrorModalOpened, operatingTimeOfGeofence} = this.state;
    const isDetails = !!this.props.match.params.id;

    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
    };

    return (
      <LoadingOverlay active={one_time_price.isLoading || common?.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                {isDetails ? <h3>{t('oneTimePrice.detailsTitle')}</h3> : <h3>{t('oneTimePrice.registrationTitle')}</h3>}
              </Grid>
            </Grid>
          </Container>
          <br></br>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('oneTimePrice.form')}
                    </Grid>
                  </Grid>
                  {/* Country */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.country')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_350">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'country_id',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.country_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.country'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.country_id}
                          onChange={this.changeCountry}
                          disabled={isDetails}
                        >
                          {common?.country_code?.map((item, idx) => {
                            return (
                              <MenuItem value={item.id} key={idx}>
                                {t(`${item.country_code}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('country_id', this.state.country_id, 'required')}
                        {isSubmitForm && !this.validator.check(this.state.country_id, 'required') && (
                          <FormHelperText id="country_id" error>
                            {t('validation.required.choose', {field: t('common.country')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Geofence */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('oneTimePrice.geofence')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_350">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'geofence_id',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.geofence_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('oneTimePrice.geofence'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.geofence_id}
                          onChange={(event) => this.handleChangeGeofence(event)}
                          disabled={!this.state.country_id || isDetails}
                        >
                          {geofence_by_country.map((item, idx) => {
                            return (
                              item.simulations?.length > 0 && (
                                <MenuItem value={item.geofence_id} key={idx}>
                                  {item.name}
                                </MenuItem>
                              )
                            );
                          })}
                        </Select>

                        {this.validator.message('geofence_id', this.state.geofence_id, 'required')}
                        {isSubmitForm && !this.validator.check(this.state.geofence_id, 'required') && (
                          <FormHelperText id="geofence_id" error>
                            {t('validation.required.choose', {field: t('oneTimePrice.geofence')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Currency */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('oneTimePrice.currency')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_350">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'currency_code',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.currency_code ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('oneTimePrice.geofence'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.currency_code}
                          onChange={(event) => onChangeSelect(this, event)}
                          disabled={true}
                        >
                          {CURRENCY.map(({id, i18n}, idx) => {
                            return (
                              <MenuItem value={id} key={idx}>
                                {t(i18n)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('currency_code', this.state.currency_code, 'required')}
                        {isSubmitForm && !this.validator.check(this.state.currency_code, 'required') && (
                          <FormHelperText id="currency_code" error>
                            {t('validation.required.choose', {field: t('oneTimePrice.currency')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* adult_price */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('oneTimePrice.adult_price')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl className="field_size_10 field_min_size_350">
                        <TextField
                          margin="dense"
                          placeholder={t('placeholder.required', {
                            field: t('oneTimePrice.adult_price'),
                          })}
                          name="adult_price"
                          variant="outlined"
                          onChange={(event) => onChangeNumberCurrency(this, event, this.state.currency_code, true)}
                          value={this.state.adult_price}
                        />
                        {this.validator.message('adult_price', this.state.adult_price, 'required|numeric|min:0,num')}
                        {isSubmitForm && !this.validator.check(this.state.adult_price, 'required') && (
                          <FormHelperText id="adult_price" error>
                            {t('validation.required', {field: t('oneTimePrice.adult_price')})}
                          </FormHelperText>
                        )}
                        {isSubmitForm && !this.validator.check(this.state.adult_price, 'numeric|min:0,num') && (
                          <FormHelperText id="adult_price" error>
                            {t('validation.number.positive')}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* child_price */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('oneTimePrice.child_price')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl className="field_size_10 field_min_size_350">
                        <TextField
                          margin="dense"
                          placeholder={t('placeholder.required', {
                            field: t('oneTimePrice.child_price'),
                          })}
                          name="child_price"
                          variant="outlined"
                          onChange={(event) => onChangeNumberCurrency(this, event, this.state.currency_code, true)}
                          value={this.state.child_price}
                        />
                        {this.validator.message('child_price', this.state.child_price, 'required|numeric|min:0,num')}
                        {isSubmitForm && !this.validator.check(this.state.child_price, 'required') && (
                          <FormHelperText id="child_price" error>
                            {t('validation.required', {field: t('oneTimePrice.child_price')})}
                          </FormHelperText>
                        )}
                        {isSubmitForm && !this.validator.check(this.state.child_price, 'numeric|min:0,num') && (
                          <FormHelperText id="child_price" error>
                            {t('validation.number.positive')}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Payment Method */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('oneTimePrice.paymentMethods')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={10}>
                      <FormControl variant="outlined" margin="dense" className="field_min_size_350">
                        <FormGroup row>
                          {PAYMENT_METHODS_ONETIME_PRICE.map((item) => {
                            const checked = this.state.payment_methods.indexOf(item.id) > -1;
                            return (
                              <FormControlLabel
                                key={item.id}
                                labelPlacement="end"
                                control={<Checkbox checked={checked} onChange={(event) => this.changePaymentMethods(event.target.checked, item.id)} className="checkbox_radio" />}
                                label={t(item.i18n)}
                              />
                            );
                          })}
                        </FormGroup>
                        {this.validator.message('payment_methods', this.state.payment_methods, 'required')}
                        {isSubmitForm && !this.validator.check(this.state.payment_methods, 'required') && (
                          <FormHelperText id="payment_methods" error>
                            {t('validation.required.choose', {field: t('oneTimePrice.paymentMethods')})}
                          </FormHelperText>
                        )}
                        {isSubmitForm && this.state.payment_methods?.length === 1 && this.state.payment_methods?.includes(PAYMENT_METHODS_ONETIME_PRICE[2].id) && (
                          <FormHelperText id="payment_methods" error>
                            {t('oneTimePrice.validatePaymentMethods')}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <br />
                  {/* Time range price */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('oneTimePrice.time_range_surcharge_discount_setting')}
                    </Grid>
                  </Grid>
                  <Container maxWidth="xl" id="root">
                    <br />
                    <b>
                      <i>
                        <div>
                          {t('oneTimePrice.operating_hour_geofence')}: {displayTextErrorTimeInvalid(operatingTimeOfGeofence?.startTime, operatingTimeOfGeofence?.endTime)}
                        </div>
                        <br />
                        <div>{t('oneTimePrice.setting_time_range_discount')}</div>
                        <br />
                        <div style={{color: 'red'}}>{t('oneTimePrice.all_time_ranges_discount_smaller_one_time_discounts')}</div>
                      </i>
                    </b>
                    <br />
                    <br />
                  </Container>
                  <Container maxWidth="xl">
                    <TableContainer component={Paper}>
                      {this.state.time_ranges_charge?.length === 0 && (
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell align="center" className="field_size_8">
                                {t('oneTimePrice.time_range')}
                              </TableCell>
                              <TableCell align="center" className="field_size_18">
                                {t('oneTimePrice.start_time')}
                              </TableCell>
                              <TableCell align="center" className="field_size_18">
                                {t('oneTimePrice.end_time')}
                              </TableCell>
                              <TableCell align="center" className="field_size_10">
                                {t('')}
                              </TableCell>
                              <TableCell className="width_400p">
                                <TableCell align="center" className="field_size_18 border_bottom_none">
                                  {t('oneTimePrice.adult_charge')}
                                </TableCell>
                                <TableCell align="center" className="field_size_18 border_bottom_none">
                                  {t('oneTimePrice.child_charge')}
                                </TableCell>
                              </TableCell>
                              <TableCell align="center" className="field_size_10">
                                <Button color="primary" variant="contained" onClick={() => this.addRange()}>
                                  <AddIcon />
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                      )}
                      {this.state.time_ranges_charge.map((item, index) => {
                        const isOverlap = this.state.overlapList.has(index);
                        return (
                          <>
                            <Table ref={this.focusSurcharge}>
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center" className="field_size_8">
                                    {t('oneTimePrice.time_range')}
                                  </TableCell>
                                  <TableCell align="center" className="field_size_18">
                                    {t('oneTimePrice.start_time')}
                                  </TableCell>
                                  <TableCell align="center" className="field_size_18">
                                    {t('oneTimePrice.end_time')}
                                  </TableCell>
                                  <TableCell align="center" className="field_size_10">
                                    {t('')}
                                  </TableCell>
                                  <TableCell className="width_400p">
                                    <TableCell align="center" className="field_size_18 border_bottom_none">
                                      {t('oneTimePrice.adult_charge')}
                                    </TableCell>
                                    <TableCell align="center" className="field_size_18 border_bottom_none">
                                      {t('oneTimePrice.child_charge')}
                                    </TableCell>
                                  </TableCell>
                                  <TableCell align="center" className="field_size_10"></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell align="center">
                                    <Grid style={{justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
                                      <div
                                        style={{
                                          width: '22px',
                                          height: '22px',
                                          border: '1px solid #000',
                                          borderRadius: '50%',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          fontSize: '11px',
                                          textAlign: 'center',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <span>{index + 1}</span>
                                      </div>
                                    </Grid>
                                  </TableCell>
                                  <TableCell align="center">
                                    <FormControl>
                                      <CustomTimePicker
                                        name="from_time"
                                        format={TIME_FORMAT}
                                        showSecond={false}
                                        use12Hours={false}
                                        autoComplete="off"
                                        allowEmpty
                                        minuteStep={1}
                                        placeholder={t('common.from')}
                                        value={item.from_time}
                                        onChange={(event) => this.onChangeRange(event, item, 'from_time')}
                                      />
                                      {this.state.isSubmitForm && !item.from_time && (
                                        <FormHelperText style={{display: 'flex'}} id="from_time" error>
                                          {t('validation.required', {field: t('oneTimePrice.start_time')})}
                                        </FormHelperText>
                                      )}
                                      {this.state.isSubmitForm && !this.checkValidTimeRange(item) && (
                                        <FormHelperText style={{display: 'flex'}} error>
                                          {t('validation.invalid.timeRange')}
                                        </FormHelperText>
                                      )}
                                      {isOverlap && (
                                        <div>
                                          <FormHelperText style={{display: 'flex'}} id="time" error>
                                            {t('oneTimePrice.overlap_time_range')}
                                          </FormHelperText>
                                        </div>
                                      )}
                                    </FormControl>
                                  </TableCell>
                                  <TableCell align="center">
                                    <FormControl>
                                      <CustomTimePicker
                                        name="to_time"
                                        format={TIME_FORMAT}
                                        showSecond={false}
                                        use12Hours={false}
                                        autoComplete="off"
                                        allowEmpty
                                        minuteStep={1}
                                        placeholder={t('common.to')}
                                        value={item.to_time}
                                        onChange={(event) => this.onChangeRange(event, item, 'to_time')}
                                      />
                                      {this.state.isSubmitForm && !item.to_time && (
                                        <FormHelperText style={{display: 'flex'}} id="to_time" error>
                                          {t('validation.required', {field: t('oneTimePrice.end_time')})}
                                        </FormHelperText>
                                      )}
                                      {this.state.isSubmitForm && !this.checkValidTimeRange(item) && (
                                        <FormHelperText style={{display: 'flex'}} error>
                                          {t('validation.invalid.timeRange')}
                                        </FormHelperText>
                                      )}
                                      {isOverlap && (
                                        <div>
                                          <FormHelperText style={{display: 'flex'}} id="time" error>
                                            {t('oneTimePrice.overlap_time_range')}
                                          </FormHelperText>
                                        </div>
                                      )}
                                    </FormControl>
                                  </TableCell>
                                  <TableCell align="center">
                                    <ArrowForward />
                                  </TableCell>
                                  <TableCell>
                                    <TableCell align="center" className="field_size_18 border_bottom_none">
                                      <FormControl>
                                        <TextField
                                          variant="outlined"
                                          name="adult_price"
                                          value={item.adult_price}
                                          onChange={(event) => this.onChangePrice(event, item)}
                                          placeholder={t('oneTimePrice.adult_charge')}
                                        ></TextField>
                                      </FormControl>
                                    </TableCell>
                                    <TableCell align="center" className="field_size_18 border_bottom_none">
                                      <FormControl>
                                        <TextField
                                          variant="outlined"
                                          name="child_price"
                                          value={item.child_price}
                                          onChange={(event) => this.onChangePrice(event, item)}
                                          placeholder={t('oneTimePrice.child_charge')}
                                        ></TextField>
                                      </FormControl>
                                    </TableCell>
                                    <Grid style={{paddingLeft: '40px'}}>
                                      {this.state.isSubmitForm && Number(item.adult_price) === 0 && Number(item.child_price) === 0 && (
                                        <FormHelperText id="adult_price" error>
                                          {t('oneTimePrice.require_charge')}
                                        </FormHelperText>
                                      )}
                                      {this.state.isSubmitForm &&
                                        ((item.adult_price && item.adult_price < 0 && Math.abs(item.adult_price) > Number(this.state.adult_price)) ||
                                          (item.child_price && item.child_price < 0 && Math.abs(item.child_price) > Number(this.state.child_price))) && (
                                          <FormHelperText id="adult_price" error>
                                            {t('oneTimePrice.time_range_discount_smaller_one_time_discount')}
                                          </FormHelperText>
                                        )}
                                      {this.state.isSubmitForm && this.checkDiscountSpecialFee(item.adult_price, item.child_price) && (
                                          <FormHelperText id="special_fee_" error>
                                            {t('oneTimePrice.time_range_discount_smaller_specical_fee')}
                                          </FormHelperText>
                                        )}
                                    </Grid>
                                  </TableCell>
                                  <TableCell align="center" className="border_bottom_none"></TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                            {/* Description */}
                            <Table>
                              <TableBody>
                                <TableCell className="field_size_90 none_padding_top_bottom">
                                  {/* Description (Detail price) */}
                                  <TableRow>
                                    <TableCell className="field_size_8"></TableCell>
                                    <TableCell className="field_size_12">
                                      <Grid className="white_space_nowrap">{t('oneTimePrice.description_detail_price')}</Grid>
                                    </TableCell>
                                    <TableCell className="field_size_2">
                                      <Button onClick={() => this.handleHiddenDescription(item, 'details')}>
                                        {item.frame_time_descriptions.details.isHidden ? <ArrowForwardIos className="font_size_big" /> : <ArrowBackIos className="font_size_big" />}
                                      </Button>
                                    </TableCell>
                                    <TableCell className="field_size_20">
                                      {!item.frame_time_descriptions.details.isHidden && (
                                        <>
                                          <TextField
                                            label={<span>({t('common.ja')})</span>}
                                            fullWidth
                                            margin="dense"
                                            placeholder={t('placeholder.required', {field: t('business_plan.description')})}
                                            minRows={1}
                                            multiline
                                            variant="outlined"
                                            inputProps={{maxLength: 100}}
                                            onChange={(event) => this.onChangeDescription(item, event.target.value, 'details', 'ja')}
                                            value={item?.frame_time_descriptions?.details?.ja || ''}
                                          />
                                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                                          {isSubmitForm && this.isInvalidDescription(item?.frame_time_descriptions?.details?.ja, item?.frame_time_descriptions?.details, 'ja') && (
                                            <FormHelperText id="ja" className="white_space_nowrap" error>
                                              {t('validation.required', {field: `${t('oneTimePrice.description_detail_price')} (${t('common.ja')})`})}
                                            </FormHelperText>
                                          )}
                                        </>
                                      )}
                                    </TableCell>
                                    <TableCell align="center" className="field_size_4">
                                      {!item.frame_time_descriptions.details.isHidden && (
                                        <>
                                          <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                                        </>
                                      )}
                                    </TableCell>
                                    <TableCell className="field_size_20">
                                      {!item.frame_time_descriptions.details.isHidden && (
                                        <>
                                          <TextField
                                            label={<span>({t('common.vi')})</span>}
                                            fullWidth
                                            margin="dense"
                                            placeholder={t('placeholder.required', {field: t('business_plan.description')})}
                                            minRows={1}
                                            multiline
                                            variant="outlined"
                                            inputProps={{maxLength: 100}}
                                            onChange={(event) => this.onChangeDescription(item, event.target.value, 'details', 'vi')}
                                            value={item?.frame_time_descriptions?.details?.vi || ''}
                                          />
                                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                                          {isSubmitForm && this.isInvalidDescription(item?.frame_time_descriptions?.details?.vi, item?.frame_time_descriptions?.details, 'vi') && (
                                            <FormHelperText id="vi" className="white_space_nowrap" error>
                                              {t('validation.required', {field: `${t('oneTimePrice.description_detail_price')} (${t('common.vi')})`})}
                                            </FormHelperText>
                                          )}
                                        </>
                                      )}
                                    </TableCell>
                                    <TableCell align="center" className="field_size_4">
                                      {!item.frame_time_descriptions.details.isHidden && (
                                        <>
                                          <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                                        </>
                                      )}
                                    </TableCell>
                                    <TableCell className="field_size_20">
                                      {!item.frame_time_descriptions.details.isHidden && (
                                        <>
                                          <TextField
                                            label={<span>({t('common.en')})</span>}
                                            fullWidth
                                            margin="dense"
                                            placeholder={t('placeholder.required', {field: t('business_plan.description')})}
                                            minRows={1}
                                            multiline
                                            variant="outlined"
                                            inputProps={{maxLength: 100}}
                                            onChange={(event) => this.onChangeDescription(item, event.target.value, 'details', 'en')}
                                            value={item?.frame_time_descriptions?.details?.en || ''}
                                          />
                                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                                          {isSubmitForm && this.isInvalidDescription(item?.frame_time_descriptions?.details?.en, item?.frame_time_descriptions?.details, 'en') && (
                                            <FormHelperText id="en" className="white_space_nowrap" error>
                                              {t('validation.required', {field: `${t('oneTimePrice.description_detail_price')} (${t('common.en')})`})}
                                            </FormHelperText>
                                          )}
                                        </>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                  {/* Description (Onetime banner) */}
                                  <TableRow>
                                    <TableCell className="field_size_8"></TableCell>
                                    <TableCell className="field_size_12">
                                      <Grid className="white_space_nowrap">{t('oneTimePrice.description_onetime_banner')}</Grid>
                                    </TableCell>
                                    <TableCell className="field_size_2">
                                      <Button onClick={() => this.handleHiddenDescription(item, 'onetime')}>
                                        {item.frame_time_descriptions.onetime.isHidden ? <ArrowForwardIos className="font_size_big" /> : <ArrowBackIos className="font_size_big" />}
                                      </Button>
                                    </TableCell>
                                    <TableCell className="field_size_20">
                                      {!item.frame_time_descriptions.onetime.isHidden && (
                                        <>
                                          <TextField
                                            label={<span>({t('common.ja')})</span>}
                                            fullWidth
                                            margin="dense"
                                            placeholder={t('placeholder.required', {field: t('business_plan.description')})}
                                            minRows={1}
                                            multiline
                                            variant="outlined"
                                            inputProps={{maxLength: 100}}
                                            onChange={(event) => this.onChangeDescription(item, event.target.value, 'onetime', 'ja')}
                                            value={item?.frame_time_descriptions?.onetime?.ja || ''}
                                          />
                                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                                          {isSubmitForm && this.isInvalidDescription(item?.frame_time_descriptions?.onetime?.ja, item?.frame_time_descriptions?.onetime, 'ja') && (
                                            <FormHelperText id="ja" className="white_space_nowrap" error>
                                              {t('validation.required', {field: `${t('oneTimePrice.description_onetime_banner')} (${t('common.ja')})`})}
                                            </FormHelperText>
                                          )}
                                        </>
                                      )}
                                    </TableCell>
                                    <TableCell align="center" className="field_size_4">
                                      {!item.frame_time_descriptions.onetime.isHidden && (
                                        <>
                                          <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                                        </>
                                      )}
                                    </TableCell>
                                    <TableCell className="field_size_20">
                                      {!item.frame_time_descriptions.onetime.isHidden && (
                                        <>
                                          <TextField
                                            label={<span>({t('common.vi')})</span>}
                                            fullWidth
                                            margin="dense"
                                            placeholder={t('placeholder.required', {field: t('business_plan.description')})}
                                            minRows={1}
                                            multiline
                                            variant="outlined"
                                            inputProps={{maxLength: 100}}
                                            onChange={(event) => this.onChangeDescription(item, event.target.value, 'onetime', 'vi')}
                                            value={item?.frame_time_descriptions?.onetime?.vi || ''}
                                          />
                                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                                          {isSubmitForm && this.isInvalidDescription(item?.frame_time_descriptions?.onetime?.vi, item?.frame_time_descriptions?.onetime, 'vi') && (
                                            <FormHelperText id="vi" className="white_space_nowrap" error>
                                              {t('validation.required', {field: `${t('oneTimePrice.description_onetime_banner')} (${t('common.vi')})`})}
                                            </FormHelperText>
                                          )}
                                        </>
                                      )}
                                    </TableCell>
                                    <TableCell align="center" className="field_size_4">
                                      {!item.frame_time_descriptions.onetime.isHidden && (
                                        <>
                                          <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                                        </>
                                      )}
                                    </TableCell>
                                    <TableCell className="field_size_20">
                                      {!item.frame_time_descriptions.onetime.isHidden && (
                                        <>
                                          <TextField
                                            label={<span>({t('common.en')})</span>}
                                            fullWidth
                                            margin="dense"
                                            placeholder={t('placeholder.required', {field: t('business_plan.description')})}
                                            minRows={1}
                                            multiline
                                            variant="outlined"
                                            inputProps={{maxLength: 100}}
                                            onChange={(event) => this.onChangeDescription(item, event.target.value, 'onetime', 'en')}
                                            value={item?.frame_time_descriptions?.onetime?.en || ''}
                                          />
                                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                                          {isSubmitForm && this.isInvalidDescription(item?.frame_time_descriptions?.onetime?.en, item?.frame_time_descriptions?.onetime, 'en') && (
                                            <FormHelperText id="en" className="white_space_nowrap" error>
                                              {t('validation.required', {field: `${t('oneTimePrice.description_onetime_banner')} (${t('common.en')})`})}
                                            </FormHelperText>
                                          )}
                                        </>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                  {/* Description (Subscription banner) */}
                                  <TableRow>
                                    <TableCell className="field_size_8"></TableCell>
                                    <TableCell className="field_size_12">
                                      <Grid className="white_space_nowrap">{t('oneTimePrice.description_subscription_banner')}</Grid>
                                    </TableCell>
                                    <TableCell className="field_size_2">
                                      <Button onClick={() => this.handleHiddenDescription(item, 'subscription')}>
                                        {item.frame_time_descriptions.subscription.isHidden ? (
                                          <ArrowForwardIos className="font_size_big" />
                                        ) : (
                                          <ArrowBackIos className="font_size_big" />
                                        )}
                                      </Button>
                                    </TableCell>
                                    <TableCell className="field_size_20">
                                      {!item.frame_time_descriptions.subscription.isHidden && (
                                        <>
                                          <TextField
                                            label={<span>({t('common.ja')})</span>}
                                            fullWidth
                                            margin="dense"
                                            placeholder={t('placeholder.required', {field: t('business_plan.description')})}
                                            minRows={1}
                                            multiline
                                            variant="outlined"
                                            inputProps={{maxLength: 100}}
                                            onChange={(event) => this.onChangeDescription(item, event.target.value, 'subscription', 'ja')}
                                            value={item?.frame_time_descriptions?.subscription?.ja || ''}
                                          />
                                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                                          {isSubmitForm &&
                                            this.isInvalidDescription(item?.frame_time_descriptions?.subscription?.ja, item?.frame_time_descriptions?.subscription, 'ja') && (
                                              <FormHelperText id="ja" className="white_space_nowrap" error>
                                                {t('validation.required', {field: `${t('oneTimePrice.description_subscription_banner')} (${t('common.ja')})`})}
                                              </FormHelperText>
                                            )}
                                        </>
                                      )}
                                    </TableCell>
                                    <TableCell align="center" className="field_size_4">
                                      {!item.frame_time_descriptions.subscription.isHidden && (
                                        <>
                                          <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                                        </>
                                      )}
                                    </TableCell>
                                    <TableCell className="field_size_20">
                                      {!item.frame_time_descriptions.subscription.isHidden && (
                                        <>
                                          <TextField
                                            label={<span>({t('common.vi')})</span>}
                                            fullWidth
                                            margin="dense"
                                            placeholder={t('placeholder.required', {field: t('business_plan.description')})}
                                            minRows={1}
                                            multiline
                                            variant="outlined"
                                            inputProps={{maxLength: 100}}
                                            onChange={(event) => this.onChangeDescription(item, event.target.value, 'subscription', 'vi')}
                                            value={item?.frame_time_descriptions?.subscription?.vi || ''}
                                          />
                                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                                          {isSubmitForm &&
                                            this.isInvalidDescription(item?.frame_time_descriptions?.subscription?.vi, item?.frame_time_descriptions?.subscription, 'vi') && (
                                              <FormHelperText id="vi" className="white_space_nowrap" error>
                                                {t('validation.required', {field: `${t('oneTimePrice.description_subscription_banner')} (${t('common.vi')})`})}
                                              </FormHelperText>
                                            )}
                                        </>
                                      )}
                                    </TableCell>
                                    <TableCell align="center" className="field_size_4">
                                      {!item.frame_time_descriptions.subscription.isHidden && (
                                        <>
                                          <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                                        </>
                                      )}
                                    </TableCell>
                                    <TableCell className="field_size_20">
                                      {!item.frame_time_descriptions.subscription.isHidden && (
                                        <>
                                          <TextField
                                            label={<span>({t('common.en')})</span>}
                                            fullWidth
                                            margin="dense"
                                            placeholder={t('placeholder.required', {field: t('business_plan.description')})}
                                            minRows={1}
                                            multiline
                                            variant="outlined"
                                            inputProps={{maxLength: 100}}
                                            onChange={(event) => this.onChangeDescription(item, event.target.value, 'subscription', 'en')}
                                            value={item?.frame_time_descriptions?.subscription?.en || ''}
                                          />
                                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                                          {isSubmitForm &&
                                            this.isInvalidDescription(item?.frame_time_descriptions?.subscription?.en, item?.frame_time_descriptions?.subscription, 'en') && (
                                              <FormHelperText id="en" className="white_space_nowrap" error>
                                                {t('validation.required', {field: `${t('oneTimePrice.description_subscription_banner')} (${t('common.en')})`})}
                                              </FormHelperText>
                                            )}
                                        </>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                  {/* Description (Ticket banner) */}
                                  <TableRow>
                                    <TableCell className="field_size_8 border_bottom_none"></TableCell>
                                    <TableCell className="field_size_12 border_bottom_none">
                                      <Grid className="white_space_nowrap">{t('oneTimePrice.description_ticket_banner')}</Grid>
                                    </TableCell>
                                    <TableCell className="field_size_2 border_bottom_none">
                                      <Button onClick={() => this.handleHiddenDescription(item, 'ticket')}>
                                        {item.frame_time_descriptions.ticket.isHidden ? <ArrowForwardIos className="font_size_big" /> : <ArrowBackIos className="font_size_big" />}
                                      </Button>
                                    </TableCell>
                                    <TableCell className="field_size_20 border_bottom_none">
                                      {!item.frame_time_descriptions.ticket.isHidden && (
                                        <>
                                          <TextField
                                            label={<span>({t('common.ja')})</span>}
                                            fullWidth
                                            margin="dense"
                                            placeholder={t('placeholder.required', {field: t('business_plan.description')})}
                                            minRows={1}
                                            multiline
                                            variant="outlined"
                                            inputProps={{maxLength: 100}}
                                            onChange={(event) => this.onChangeDescription(item, event.target.value, 'ticket', 'ja')}
                                            value={item?.frame_time_descriptions?.ticket?.ja || ''}
                                          />
                                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                                          {isSubmitForm && this.isInvalidDescription(item?.frame_time_descriptions?.ticket?.ja, item?.frame_time_descriptions?.ticket, 'ja') && (
                                            <FormHelperText id="ja" className="white_space_nowrap" error>
                                              {t('validation.required', {field: `${t('oneTimePrice.description_ticket_banner')} (${t('common.ja')})`})}
                                            </FormHelperText>
                                          )}
                                        </>
                                      )}
                                    </TableCell>
                                    <TableCell align="center" className="field_size_4 border_bottom_none">
                                      {!item.frame_time_descriptions.ticket.isHidden && (
                                        <>
                                          <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                                        </>
                                      )}
                                    </TableCell>
                                    <TableCell className="field_size_20 border_bottom_none">
                                      {!item.frame_time_descriptions.ticket.isHidden && (
                                        <>
                                          <TextField
                                            label={<span>({t('common.vi')})</span>}
                                            fullWidth
                                            margin="dense"
                                            placeholder={t('placeholder.required', {field: t('business_plan.description')})}
                                            minRows={1}
                                            multiline
                                            variant="outlined"
                                            inputProps={{maxLength: 100}}
                                            onChange={(event) => this.onChangeDescription(item, event.target.value, 'ticket', 'vi')}
                                            value={item?.frame_time_descriptions?.ticket?.vi || ''}
                                          />
                                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                                          {isSubmitForm && this.isInvalidDescription(item?.frame_time_descriptions?.ticket?.vi, item?.frame_time_descriptions?.ticket, 'vi') && (
                                            <FormHelperText id="vi" className="white_space_nowrap" error>
                                              {t('validation.required', {field: `${t('oneTimePrice.description_ticket_banner')} (${t('common.vi')})`})}
                                            </FormHelperText>
                                          )}
                                        </>
                                      )}
                                    </TableCell>
                                    <TableCell align="center" className="field_size_4 border_bottom_none">
                                      {!item.frame_time_descriptions.ticket.isHidden && (
                                        <>
                                          <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                                        </>
                                      )}
                                    </TableCell>
                                    <TableCell className="field_size_20 border_bottom_none">
                                      {!item.frame_time_descriptions.ticket.isHidden && (
                                        <>
                                          <TextField
                                            label={<span>({t('common.en')})</span>}
                                            fullWidth
                                            margin="dense"
                                            placeholder={t('placeholder.required', {field: t('business_plan.description')})}
                                            minRows={1}
                                            multiline
                                            variant="outlined"
                                            inputProps={{maxLength: 100}}
                                            onChange={(event) => this.onChangeDescription(item, event.target.value, 'ticket', 'en')}
                                            value={item?.frame_time_descriptions?.ticket?.en || ''}
                                          />
                                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                                          {isSubmitForm && this.isInvalidDescription(item?.frame_time_descriptions?.ticket?.en, item?.frame_time_descriptions?.ticket, 'en') && (
                                            <FormHelperText id="en" className="white_space_nowrap" error>
                                              {t('validation.required', {field: `${t('oneTimePrice.description_ticket_banner')} (${t('common.en')})`})}
                                            </FormHelperText>
                                          )}
                                        </>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                </TableCell>
                                <TableCell className="field_size_10 border_bottom_none">
                                  <div className="display_inline_grid margin-left-24">
                                    <Button color="primary" variant="contained" onClick={() => this.addRange(item)} disabled={this.state.time_ranges_charge?.length === 100}>
                                      <AddIcon />
                                    </Button>
                                    <Button style={{marginTop: '10px'}} color="secondary" variant="contained" onClick={() => this.deleteRange(item)}>
                                      <CancelIcon />
                                    </Button>
                                  </div>
                                </TableCell>
                              </TableBody>
                            </Table>
                          </>
                        );
                      })}
                    </TableContainer>
                  </Container>
                  <br />
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('oneTimePrice.additional_fee')}
                    </Grid>
                  </Grid>
                  {/* Additional fee adult */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('ticket.adult')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl className="field_size_10 field_min_size_350">
                        <TextField
                          margin="dense"
                          placeholder={t('placeholder.required', {
                            field: t('ticket.adult'),
                          })}
                          name="addition_fee_adult"
                          variant="outlined"
                          onChange={(event) => onChangeNumberCurrency(this, event, this.state.currency_code, true)}
                          value={this.state.addition_fee_adult}
                        />
                        {this.validator.message('addition_fee_adult', this.state.addition_fee_adult, 'numeric|min:0,num')}
                        {isSubmitForm && !this.validator.check(this.state.addition_fee_adult, 'numeric|min:0,num') && (
                          <FormHelperText id="addition_fee_adult" error>
                            {t('validation.number.positive')}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Additional fee child */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('ticket.child')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl className="field_size_10 field_min_size_350">
                        <TextField
                          margin="dense"
                          placeholder={t('placeholder.required', {
                            field: t('ticket.child'),
                          })}
                          name="addition_fee_child"
                          variant="outlined"
                          onChange={(event) => onChangeNumberCurrency(this, event, this.state.currency_code, true)}
                          value={this.state.addition_fee_child}
                        />
                        {this.validator.message('addition_fee_child', this.state.addition_fee_child, 'numeric|min:0,num')}
                        {isSubmitForm && !this.validator.check(this.state.addition_fee_child, 'numeric|min:0,num') && (
                          <FormHelperText id="addition_fee_child" error>
                            {t('validation.number.positive')}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Special passengers fee setting */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('oneTimePrice.special_passengers_fee_setting')}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="row_form_item">
                    <Table>
                      <TableHead>
                        <TableCell align="center">{t('oneTimePrice.setting')}</TableCell>
                        <TableCell align="center">{t('oneTimePrice.type')}</TableCell>
                        <TableCell align="center">{t('oneTimePrice.display')}</TableCell>
                        <TableCell className="width_150p" align="center">
                          {t('oneTimePrice.charge')}
                        </TableCell>
                        <TableCell align="center">
                          {this.state.specialFeeList?.length === 0 && (
                            <Button color="primary" variant="contained" onClick={() => this.addSpecialFeeRow()} disabled={this.state.time_ranges_charge?.length === 100}>
                              <AddIcon />
                            </Button>
                          )}
                        </TableCell>
                      </TableHead>
                      <TableBody>
                        {this.state.specialFeeList?.length > 0 &&
                          this.state.specialFeeList.map((item, index) => (
                            <TableRow>
                              <TableCell>
                                <Grid className="grid_title_padding" style={{fontWeight: 'bold'}}>
                                  {t('oneTimePrice.name')}
                                </Grid>
                                <Grid style={{display: 'flex'}} className="row_form_item">
                                  <Grid xs={12} container alignItems="center" item className="grid_title_padding">
                                    <FormControl fullWidth>
                                      <Box display="flex" flexDirection="column">
                                        <TextField
                                          label={<span>({t('common.ja')})</span>}
                                          fullWidth
                                          margin="dense"
                                          minRows={2}
                                          placeholder={t('placeholder.required', {field: t('oneTimePrice.name')})}
                                          multiline
                                          variant="outlined"
                                          inputProps={{maxLength: 100}}
                                          name="name_jp"
                                          onChange={(event) => this.handleChangeDescriptionSpecialPassenger(event, index, 'name', 0)}
                                          value={item.special_languages[0]?.name || ''}
                                        />
                                        <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                                      </Box>
                                      {this.validator.message('name_jp', item.special_languages[0]?.name.trim(), 'required')}
                                      {this.state.isSubmitForm && !this.validator.check(item.special_languages[0]?.name.trim(), 'required') && (
                                        <FormHelperText id="name_jp" error>
                                          {t('validation.required', {field: `${t('oneTimePrice.name')} (${t('common.ja')})`})}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </Grid>
                                  <Grid container justify="center" alignItems="center" item xs={1}>
                                    <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                                  </Grid>
                                  <Grid xs={12} container alignItems="center" item className="grid_title_padding">
                                    <FormControl fullWidth>
                                      <Box display="flex" flexDirection="column">
                                        <TextField
                                          label={<span>({t('common.vi')})</span>}
                                          fullWidth
                                          margin="dense"
                                          minRows={2}
                                          placeholder={t('placeholder.required', {field: t('oneTimePrice.name')})}
                                          multiline
                                          variant="outlined"
                                          inputProps={{maxLength: 100}}
                                          onChange={(event) => !event.target.value.includes('\n') && this.handleChangeDescriptionSpecialPassenger(event, index, 'name', 2)}
                                          value={item.special_languages[2]?.name || ''}
                                        />
                                        <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                                      </Box>
                                      {this.validator.message('name_vi', item.special_languages[2]?.name.trim(), 'required')}
                                      {this.state.isSubmitForm && !this.validator.check(item.special_languages[2]?.name.trim(), 'required') && (
                                        <FormHelperText id="name_vi" error>
                                          {t('validation.required', {field: `${t('oneTimePrice.name')} (${t('common.vi')})`})}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </Grid>
                                  <Grid container justify="center" alignItems="center" item xs={1}>
                                    <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                                  </Grid>
                                  <Grid xs={12} container alignItems="center" item className="grid_title_padding">
                                    <FormControl fullWidth>
                                      <Box display="flex" flexDirection="column">
                                        <TextField
                                          label={<span>({t('common.en')})</span>}
                                          fullWidth
                                          margin="dense"
                                          minRows={2}
                                          placeholder={t('placeholder.required', {field: t('oneTimePrice.name')})}
                                          multiline
                                          variant="outlined"
                                          inputProps={{maxLength: 100}}
                                          onChange={(event) => !event.target.value.includes('\n') && this.handleChangeDescriptionSpecialPassenger(event, index, 'name', 1)}
                                          value={item.special_languages[1]?.name || ''}
                                        />
                                        <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                                      </Box>
                                      {this.validator.message('name_en', item.special_languages[1]?.name.trim(), 'required')}
                                      {this.state.isSubmitForm && !this.validator.check(item.special_languages[1]?.name.trim(), 'required') && (
                                        <FormHelperText id="name_en" error>
                                          {t('validation.required', {field: `${t('oneTimePrice.name')} (${t('common.en')})`})}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </Grid>
                                </Grid>
                                <Grid className="grid_title_padding" style={{marginTop: '5px', fontWeight: 'bold'}}>
                                  {t('oneTimePrice.description')}
                                </Grid>
                                <Grid style={{display: 'flex'}} className="row_form_item">
                                  <Grid xs={12} container alignItems="center" item className="grid_title_padding">
                                    <FormControl fullWidth>
                                      <Box display="flex" flexDirection="column">
                                        <TextField
                                          label={<span>({t('common.ja')})</span>}
                                          fullWidth
                                          margin="dense"
                                          minRows={2}
                                          placeholder={t('placeholder.required', {field: t('oneTimePrice.description')})}
                                          multiline
                                          variant="outlined"
                                          inputProps={{maxLength: 1000}}
                                          onChange={(event) => !event.target.value.includes('\n') && this.handleChangeDescriptionSpecialPassenger(event, index, 'description', 0)}
                                          value={item.special_languages[0]?.description || ''}
                                        />
                                        <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 1000})}</div>
                                      </Box>
                                      {this.validator.message('description_jp', item.special_languages[0]?.description.trim(), 'required')}
                                      {this.state.isSubmitForm && !this.validator.check(item.special_languages[0]?.description.trim(), 'required') && (
                                        <FormHelperText id="description_jp" error>
                                          {t('validation.required', {field: `${t('oneTimePrice.description')} (${t('common.ja')})`})}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </Grid>
                                  <Grid container justify="center" alignItems="center" item xs={1}>
                                    <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                                  </Grid>
                                  <Grid xs={12} container alignItems="center" item className="grid_title_padding">
                                    <FormControl fullWidth>
                                      <Box display="flex" flexDirection="column">
                                        <TextField
                                          label={<span>({t('common.vi')})</span>}
                                          fullWidth
                                          margin="dense"
                                          minRows={2}
                                          placeholder={t('placeholder.required', {field: t('oneTimePrice.description')})}
                                          multiline
                                          variant="outlined"
                                          inputProps={{maxLength: 1000}}
                                          onChange={(event) => !event.target.value.includes('\n') && this.handleChangeDescriptionSpecialPassenger(event, index, 'description', 2)}
                                          value={item.special_languages[2]?.description || ''}
                                        />
                                        <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 1000})}</div>
                                      </Box>
                                      {this.validator.message('description_vi', item.special_languages[2]?.description.trim(), 'required')}
                                      {this.state.isSubmitForm && !this.validator.check(item.special_languages[2]?.description.trim(), 'required') && (
                                        <FormHelperText id="description_vi" error>
                                          {t('validation.required', {field: `${t('oneTimePrice.description')} (${t('common.vi')})`})}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </Grid>
                                  <Grid container justify="center" alignItems="center" item xs={1}>
                                    <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                                  </Grid>
                                  <Grid xs={12} container alignItems="center" item className="grid_title_padding">
                                    <FormControl fullWidth>
                                      <Box display="flex" flexDirection="column">
                                        <TextField
                                          label={<span>({t('common.en')})</span>}
                                          fullWidth
                                          margin="dense"
                                          minRows={2}
                                          placeholder={t('placeholder.required', {field: t('oneTimePrice.description')})}
                                          multiline
                                          variant="outlined"
                                          inputProps={{maxLength: 1000}}
                                          onChange={(event) => !event.target.value.includes('\n') && this.handleChangeDescriptionSpecialPassenger(event, index, 'description', 1)}
                                          value={item.special_languages[1]?.description || ''}
                                        />
                                        <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 1000})}</div>
                                      </Box>
                                      {this.validator.message('description_en', item.special_languages[1]?.description.trim(), 'required')}
                                      {this.state.isSubmitForm && !this.validator.check(item.special_languages[1]?.description.trim(), 'required') && (
                                        <FormHelperText id="description_en" error>
                                          {t('validation.required', {field: `${t('oneTimePrice.description')} (${t('common.en')})`})}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </Grid>
                                </Grid>
                                <Grid className="grid_title_padding" style={{marginTop: '5px', fontWeight: 'bold'}}>
                                  {t('oneTimePrice.popup_title')}
                                </Grid>
                                <Grid style={{display: 'flex'}} className="row_form_item">
                                  <Grid xs={12} container alignItems="center" item className="grid_title_padding">
                                    <FormControl fullWidth>
                                      <Box display="flex" flexDirection="column">
                                        <TextField
                                          label={<span>({t('common.ja')})</span>}
                                          fullWidth
                                          margin="dense"
                                          minRows={2}
                                          placeholder={t('placeholder.required', {field: t('oneTimePrice.popup_title')})}
                                          multiline
                                          variant="outlined"
                                          inputProps={{maxLength: 100}}
                                          onChange={(event) => !event.target.value.includes('\n') && this.handleChangeDescriptionSpecialPassenger(event, index, 'popup_title', 0)}
                                          value={item.special_languages[0]?.popup_title || ''}
                                        />
                                        <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                                      </Box>
                                      {this.validator.message('popup_title_jp', item.special_languages[0]?.popup_title.trim(), 'required')}
                                      {this.state.isSubmitForm && !this.validator.check(item.special_languages[0]?.popup_title.trim(), 'required') && (
                                        <FormHelperText id="popup_title_jp" error>
                                          {t('validation.required', {field: `${t('oneTimePrice.popup_title')} (${t('common.ja')})`})}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </Grid>
                                  <Grid container justify="center" alignItems="center" item xs={1}>
                                    <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                                  </Grid>
                                  <Grid xs={12} container alignItems="center" item className="grid_title_padding">
                                    <FormControl fullWidth>
                                      <Box display="flex" flexDirection="column">
                                        <TextField
                                          label={<span>({t('common.vi')})</span>}
                                          fullWidth
                                          margin="dense"
                                          minRows={2}
                                          placeholder={t('placeholder.required', {field: t('oneTimePrice.popup_title')})}
                                          multiline
                                          variant="outlined"
                                          inputProps={{maxLength: 100}}
                                          onChange={(event) => !event.target.value.includes('\n') && this.handleChangeDescriptionSpecialPassenger(event, index, 'popup_title', 2)}
                                          value={item.special_languages[2]?.popup_title || ''}
                                        />
                                        <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                                      </Box>
                                      {this.validator.message('popup_title_vi', item.special_languages[2]?.popup_title.trim(), 'required')}
                                      {this.state.isSubmitForm && !this.validator.check(item.special_languages[2]?.popup_title.trim(), 'required') && (
                                        <FormHelperText id="popup_title_vi" error>
                                          {t('validation.required', {field: `${t('oneTimePrice.popup_title')} (${t('common.vi')})`})}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </Grid>
                                  <Grid container justify="center" alignItems="center" item xs={1}>
                                    <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                                  </Grid>
                                  <Grid xs={12} container alignItems="center" item className="grid_title_padding">
                                    <FormControl fullWidth>
                                      <Box display="flex" flexDirection="column">
                                        <TextField
                                          label={<span>({t('common.en')})</span>}
                                          fullWidth
                                          margin="dense"
                                          minRows={2}
                                          placeholder={t('placeholder.required', {field: t('oneTimePrice.popup_title')})}
                                          multiline
                                          variant="outlined"
                                          inputProps={{maxLength: 100}}
                                          onChange={(event) => !event.target.value.includes('\n') && this.handleChangeDescriptionSpecialPassenger(event, index, 'popup_title', 1)}
                                          value={item.special_languages[1]?.popup_title || ''}
                                        />
                                        <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                                      </Box>
                                      {this.validator.message('popup_title_en', item.special_languages[1]?.popup_title.trim(), 'required')}
                                      {this.state.isSubmitForm && !this.validator.check(item.special_languages[1]?.popup_title.trim(), 'required') && (
                                        <FormHelperText id="popup_title_en" error>
                                          {t('validation.required', {field: `${t('oneTimePrice.popup_title')} (${t('common.en')})`})}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </Grid>
                                </Grid>
                                <Grid className="grid_title_padding" style={{marginTop: '5px', fontWeight: 'bold'}}>
                                  {t('oneTimePrice.popup_content')}
                                </Grid>
                                <Grid style={{display: 'flex'}} className="row_form_item">
                                  <Grid xs={12} container alignItems="center" item className="grid_title_padding">
                                    <FormControl fullWidth>
                                      <Box display="flex" flexDirection="column">
                                        <TextField
                                          label={<span>({t('common.ja')})</span>}
                                          fullWidth
                                          margin="dense"
                                          minRows={2}
                                          placeholder={t('placeholder.required', {field: t('oneTimePrice.popup_content')})}
                                          multiline
                                          variant="outlined"
                                          inputProps={{maxLength: 1000}}
                                          onChange={(event) => !event.target.value.includes('\n') && this.handleChangeDescriptionSpecialPassenger(event, index, 'popup_content', 0)}
                                          value={item.special_languages[0]?.popup_content || ''}
                                        />
                                        <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 1000})}</div>
                                      </Box>
                                      {this.validator.message('popup_content_jp', item.special_languages[0]?.popup_content.trim(), 'required')}
                                      {this.state.isSubmitForm && !this.validator.check(item.special_languages[0]?.popup_content.trim(), 'required') && (
                                        <FormHelperText id="popup_content_jp" error>
                                          {t('validation.required', {field: `${t('oneTimePrice.popup_content')} (${t('common.ja')})`})}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </Grid>
                                  <Grid container justify="center" alignItems="center" item xs={1}>
                                    <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                                  </Grid>
                                  <Grid xs={12} container alignItems="center" item className="grid_title_padding">
                                    <FormControl fullWidth>
                                      <Box display="flex" flexDirection="column">
                                        <TextField
                                          label={<span>({t('common.vi')})</span>}
                                          fullWidth
                                          margin="dense"
                                          minRows={2}
                                          placeholder={t('placeholder.required', {field: t('oneTimePrice.popup_content')})}
                                          multiline
                                          variant="outlined"
                                          inputProps={{maxLength: 1000}}
                                          onChange={(event) => !event.target.value.includes('\n') && this.handleChangeDescriptionSpecialPassenger(event, index, 'popup_content', 2)}
                                          value={item.special_languages[2]?.popup_content || ''}
                                        />
                                        <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 1000})}</div>
                                      </Box>
                                      {this.validator.message('popup_content_vi', item.special_languages[2]?.popup_content.trim(), 'required')}
                                      {this.state.isSubmitForm && !this.validator.check(item.special_languages[2]?.popup_content.trim(), 'required') && (
                                        <FormHelperText id="popup_content_vi" error>
                                          {t('validation.required', {field: `${t('oneTimePrice.popup_content')} (${t('common.vi')})`})}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </Grid>
                                  <Grid container justify="center" alignItems="center" item xs={1}>
                                    <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                                  </Grid>
                                  <Grid xs={12} container alignItems="center" item className="grid_title_padding">
                                    <FormControl fullWidth>
                                      <Box display="flex" flexDirection="column">
                                        <TextField
                                          label={<span>({t('common.en')})</span>}
                                          fullWidth
                                          margin="dense"
                                          minRows={2}
                                          placeholder={t('placeholder.required', {field: t('oneTimePrice.popup_content')})}
                                          multiline
                                          variant="outlined"
                                          inputProps={{maxLength: 1000}}
                                          onChange={(event) => !event.target.value.includes('\n') && this.handleChangeDescriptionSpecialPassenger(event, index, 'popup_content', 1)}
                                          value={item.special_languages[1]?.popup_content || ''}
                                        />
                                        <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 1000})}</div>
                                      </Box>
                                      {this.validator.message('popup_content_en', item.special_languages[1]?.popup_content.trim(), 'required')}
                                      {this.state.isSubmitForm && !this.validator.check(item.special_languages[1]?.popup_content.trim(), 'required') && (
                                        <FormHelperText id="popup_content_en" error>
                                          {t('validation.required', {field: `${t('oneTimePrice.popup_content')} (${t('common.en')})`})}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </Grid>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <FormControl component="fieldset">
                                  <RadioGroup aria-label="gender" name="gender" value={item.passenger_type} onChange={(event) => this.handleChangeTypeSpecialPerson(event, index)}>
                                    <FormControlLabel
                                      value={SPECIAL_PERSON_TYPE[0].value}
                                      control={<Radio />}
                                      label={<span className="font-14">{t('oneTimePrice.surcharge_discount_adult')}</span>}
                                    />
                                    <FormControlLabel
                                      value={SPECIAL_PERSON_TYPE[1].value}
                                      control={<Radio />}
                                      label={<span className="font-14">{t('oneTimePrice.surcharge_discount_child')}</span>}
                                    />
                                    <FormControlLabel
                                      value={SPECIAL_PERSON_TYPE[2].value}
                                      control={<Radio />}
                                      label={<span className="font-14">{t('oneTimePrice.no_surcharge_discount')}</span>}
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </TableCell>
                              <TableCell>
                                <Grid style={{marginTop: '5px', fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
                                  <Switch
                                    checked={item.display_for_user}
                                    onChange={(event) => this.handleChangeDisplayUserApp(event, index)}
                                    color="primary"
                                    name="display_for_user"
                                    inputProps={{'aria-label': 'primary checkbox'}}
                                  />
                                  <span className="font-14">{t('oneTimePrice.on_user_app')}</span>
                                </Grid>
                                <Grid style={{marginTop: '5px', fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
                                  <Switch
                                    checked={item.enable_description}
                                    onChange={(event) => this.handleChangeDisplayNotification(event, index)}
                                    color="primary"
                                    name="enable_description"
                                    inputProps={{'aria-label': 'primary checkbox'}}
                                  />
                                  <span className="font-14">{t('oneTimePrice.description')}</span>
                                </Grid>
                                <Grid style={{marginTop: '5px', fontWeight: 'bold', display: 'flex', alignItems: 'center'}}>
                                  <Switch
                                    checked={item.enable_popup}
                                    onChange={(event) => this.handleChangeDisplayPopup(event, index)}
                                    color="primary"
                                    name="enable_popup"
                                    inputProps={{'aria-label': 'primary checkbox'}}
                                  />
                                  <span className="font-14">{t('oneTimePrice.popup')}</span>
                                </Grid>
                              </TableCell>
                              <TableCell>
                                <FormControl className="field_min_size_100">
                                  <TextField
                                    margin="dense"
                                    placeholder={t('placeholder.required', {
                                      field: t('oneTimePrice.charge'),
                                    })}
                                    name="price"
                                    variant="outlined"
                                    onChange={(event) => this.onChangePriceSpecialFee(event, this.state.currency_code, index)}
                                    value={item.price}
                                  />
                                  {this.validator.message('price', item.price, 'required|numeric|min:0,num')}
                                  {isSubmitForm && !this.validator.check(item.price, 'numeric|min:0,num') && this.validator.check(item.price, 'required') && (
                                    <FormHelperText id="price" error>
                                      {t('validation.number.positive')}
                                    </FormHelperText>
                                  )}
                                  {isSubmitForm && !this.validator.check(item.price, 'required') && (
                                    <FormHelperText id="price" error>
                                      {t('validation.number.positive')}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </TableCell>
                              <TableCell>
                                <Grid className="display_inline_grid margin-left-24">
                                  {index !== 0 && (
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="button-move"
                                      startIcon={<MoveUp />}
                                      onClick={() => this.onClickMoveUpAndDownSpecialList(index, 'UP')}
                                    />
                                  )}
                                  {index !== this.state.specialFeeList?.length - 1 && (
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      style={{marginTop: '10px'}}
                                      startIcon={<MoveDown />}
                                      onClick={() => this.onClickMoveUpAndDownSpecialList(index, 'DOWN')}
                                      className="button-move"
                                    />
                                  )}

                                  <Button style={{marginTop: '10px'}} color="primary" variant="contained" onClick={() => this.addSpecialFeeRow()}>
                                    <AddIcon />
                                  </Button>
                                  <Button style={{marginTop: '10px'}} color="secondary" variant="contained" onClick={() => this.deleteSpecialFeeRow(index)}>
                                    <CancelIcon />
                                  </Button>
                                </Grid>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </Grid>

                  {isDetails && (
                    <Grid container alignItems="center" item xs={12} lg={9} style={{marginTop: 30}}>
                      <Memo
                        memos={this.state.memos}
                        onChangeContent={(value) => onChangeListData(this, this.state.memos, value.field, value.index, value.newValue)}
                        note={this.state.note}
                        onChangeNote={(value) => this.setState({note: value})}
                        parentType="medium"
                        countryCode={this.state.country_id}
                        isScroll={true}
                      />
                    </Grid>
                  )}
                </Paper>

                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {isDetails ? (
                      permission.canUpdate && (
                        <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={() => this.handleUpdate()}>
                          {t('common.btnUpdate')}
                        </Button>
                      )
                    ) : (
                      <>
                        <Button
                          color="primary"
                          variant="contained"
                          className="button_margin button_color_green"
                          endIcon={<AddIcon />}
                          onClick={() => this.handleClickCreateButton()}
                        >
                          {t('common.btnRegister')}
                        </Button>
                      </>
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      className="button_margin button_color"
                      onClick={() => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.ONE_TIME_PRICE)}
                    >
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                  <Dialog
                    open={flag}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      zIndex: '1020',
                    }}
                  >
                    <SelectModal
                      onClickOk={isDetails ? this.handleButtonUpdateOk : this.handleCreate}
                      onClickCancel={this.handleButtonUpdateCancel}
                      message={message}
                    ></SelectModal>
                  </Dialog>
                  <Dialog
                    open={isErrorModalOpened}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      zIndex: 1020,
                    }}
                    maxWidth="md"
                  >
                    <MessageContentModal
                      message="oneTimePrice.one_time_discount_error"
                      data={this.state.errorData}
                      messageData="oneTimePrice.plan_with_discount"
                      closeMessage={() => this.setState({isErrorModalOpened: false})}
                      isSub={false}
                    />
                  </Dialog>
                </Grid>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>
          <br></br>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    one_time_price: state.one_time_price,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getListGeofence: () => dispatch(getListGeofence()),
    createOneTimePrice: (payload, props) => dispatch(createOneTimePrice(payload, props)),
    updateOneTimePrice: (payload, props) => dispatch(updateOneTimePrice(payload, props)),
    getOneTimePriceDetails: (id) => dispatch(getOneTimePriceDetails(id)),
    getListGeofenceByCountry: (id, payload) => dispatch(getListGeofenceByCountry(id, payload)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(OneTimeDetail)));
