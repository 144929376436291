import React, {Component} from 'react';

import {
  Card,
  Container,
  Grid,
  TextField,
  Button,
  Paper,
  Dialog,
  Checkbox,
  Select,
  MenuItem,
  FormControl,
  Switch,
  FormControlLabel,
  FormGroup,
  DialogContent,
  Modal,
  Backdrop,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import {ArrowBack as ArrowBackIcon, RemoveCircle as RemoveCircleIcon, ListAlt as ListAltIcon, Lens as LensIcon, DeleteForever as DeleteIcon} from '@material-ui/icons';
import {Autocomplete} from '@material-ui/lab';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {format} from 'date-fns';
import {isEmpty} from 'lodash';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

import {
  ROUTE,
  VEHICLE_MOBI_TYPES_CONST,
  PERMISSION_ACTIONS,
  TIME_FORMAT,
  MOBI_TYPES,
  DATA_NULL,
  DATE_FORMAT,
  ODM_BOOKING_TYPE,
  ASSOCIATION_STATUS,
} from '../../../../common/constant';
import SelectModal from '../../../../components/selectModal';
import withPermissionGateway from '../../../../hoc/withPermissionGateway';
import {getListFacilityBySupplierID, getListSupplier} from '../../../../stores/common/actions';
import {
  configDriverVehicle,
  searchListDriverVehicle,
  applyTime,
  getDriversByFacility,
  getVehiclesByFacility,
  getVehicleActivatedTimes,
  getAutoConfigVehicleDriverAssociate,
  updateAutoConfigVehicleDriverAssociate,
} from '../../../../stores/driver_vehicle_association/action';
import {setMessageModal} from '../../../../stores/modal/actions';
import {clearBlankValue, getQueryStringFromObject, getUrlParams, backForwardRouter, onChangeListData} from '../../../../utils/common';
import {convertTimeUTCToCalendarLocal, convertDateUTCAndGeofence} from '../../../../utils/date_time_utils';
import {convertPrefixTime, isOverlapDateRelatively, getMaxDate} from '../../../../utils/datetime';
import './styles.css';
import {isRoleBusiness} from '../../../../utils/role';
import DeleteShift from './delete_shift';

/**
 * Driver&Vehicle Association Form
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      typeName: '',
      deleteShiftVehicleId: '',
      supplier: null,
      facility: null,
      driver: null,
      driver_code: null,
      driver_name: null,
      listDriverVehicles: [],
      listDriverOperations: [],
      date: new Date(),

      flag: false,
      isOpenDate: false,
      isShowUpdateConfirm: false,
      isShowApplyConfirm: false,
      openDeleteShiftModal: false,
      msgUpdate: '',
      activatedTimes: [],
      operation_sessions: [],
      sessions_odm_normal: [],
      sessions_odm_advance: [],
      vehicleSelected: {},
      isEditFrom: false,
      vehicleDefault: {},
      vehicleFilterBySearch: [],
      vehicleList: [],
      is_associate: null,
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    if (this.props.location.search) {
      this.loadDetail();
      this.setState({isEditFrom: true});
    } else {
      await this.props.getListSupplier();
      this.setState({isEditFrom: false});
      if (isRoleBusiness()) {
        const supplier_id = this.props.common?.principal?.supplier_id;
        await this.props.getListFacilityBySupplierID(supplier_id);
        this.setState({
          supplier: {
            id: supplier_id,
            supplier_name: this.props.common?.supplier_list.find((e) => e.id === +supplier_id)?.supplier_name,
          },
        });
      }
    }
  }

  /**
   * loadDetail
   */
  loadDetail = async () => {
    const {supplier_id, facility_id, driver_code, driver_name, time} = getUrlParams(this.props.location.search);
    await this.props.getListSupplier();
    const facilitiesResponse = await this.props.getListFacilityBySupplierID(supplier_id, this.props.history);
    if (!facilitiesResponse || facilitiesResponse?.status === 403) return;
    this.setState({
      supplier: {
        id: supplier_id,
        supplier_name: this.props.common?.supplier_list.find((e) => e.id === +supplier_id)?.supplier_name,
      },
      facility: {
        id: facility_id,
        facility_name: this.props.common?.facility_list.find((e) => e.id === +facility_id)?.facility_name,
      },
      driver: {
        driver_code,
        driver_name,
      },
      date: time ? new Date(time) : new Date(),
    });
    if (driver_code) {
      const driversResponse = await this.props.getDriversByFacility(facility_id, this.props.history);
      if (driversResponse?.status === 403 || facilitiesResponse?.length === 0 || facilitiesResponse === null) return;
      await this.props.getVehiclesByFacility(facility_id, time.substring(0, 10) || new Date().toISOString().substring(0, 10));
      await this.handleSearchListDriverVehicle(driver_code);
    }
  };

  /**
   * changeSupplier
   * @param {*} value
   */
  changeBusiness = (value) => {
    const payload = {
      supplier_id: value?.id,
      time: new Date(this.state.date).toISOString().substring(0, 10),
    };
    const queryStr = getQueryStringFromObject(clearBlankValue(payload));
    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: queryStr,
    });
    this.setState({supplier: value, facility: null, driver: null, listDriverVehicles: [], vehicleList: [], is_associate: null});
    this.props.getListFacilityBySupplierID(value?.id);
  };

  /**
   * changeFacility
   * @param {event} value
   */
  changeFacilityName = async (value) => {
    const payload = {
      supplier_id: this.state.supplier.id,
      facility_id: value?.id,
      time: new Date(this.state.date).toISOString().substring(0, 10),
    };
    const queryStr = getQueryStringFromObject(clearBlankValue(payload));
    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: queryStr,
    });
    this.setState({facility: value, driver: null, listDriverVehicles: [], vehicleList: [], is_associate: null});
    if (!isEmpty(value)) {
      this.props.getDriversByFacility(value?.id);
      await this.props.getVehiclesByFacility(value?.id, new Date(this.state.date).toISOString().substring(0, 10));
    }
  };

  /**
   * handleCancelUpdate
   */
  handleCancelUpdate() {
    this.setState({
      isShowUpdateConfirm: false,
    });
  }

  /**
   * getVehicleTime
   * @param {Number} vehicle_code
   * @return {*}
   */
  getVehicleTime = (vehicle_code) => {
    const vehicle_time = this.props.association.vehicle?.find((item) => item.vehicle_id === vehicle_code);
    return vehicle_time ? vehicle_time : {};
  };

  /**
   * mappingDriverVehicle
   * @param {*} vehicle_index
   * @param {*} shift_vehicle
   */
  mappingDriverVehicle(vehicle_index, shift_vehicle) {
    const listDriverVehicles = JSON.parse(JSON.stringify(this.state.listDriverVehicles));
    const index = this.findIndexOfVehicle(listDriverVehicles[vehicle_index].times, shift_vehicle);
    if (index >= 0) {
      listDriverVehicles[vehicle_index].times.splice(index, 1);
    } else {
      listDriverVehicles[vehicle_index].times.push({
        shift_setting_id: shift_vehicle.id,
        end_time: shift_vehicle.end_time,
        group_type: shift_vehicle.type,
        start_time: shift_vehicle.start_time,
      });
    }

    this.setState({
      listDriverVehicles: listDriverVehicles,
    });
  }

  /**
   * mappingDriverVehicleById
   * @param {*} isChecked
   */
  mappingDriverVehicleById(isChecked) {
    const listDriverVehicles = JSON.parse(JSON.stringify(this.state.listDriverVehicles));
    const indexVehicle = listDriverVehicles.findIndex((vehicle) => vehicle?.vehicle_code === this.state.vehicleSelected?.vehicle_id);
    if (isChecked === false) {
      listDriverVehicles[indexVehicle].times = [];
    } else {
      listDriverVehicles[indexVehicle].times = this.state.vehicleSelected?.vehicle_shifts;
    }
    this.setState({
      listDriverVehicles: listDriverVehicles,
    });
  }

  /**
   * isDisableVehicle
   * @param {Number} vehicle_code
   * @return {*}
   */
  isDisableVehicle(vehicle_code) {
    const listDriverVehicles = JSON.parse(JSON.stringify(this.state.listDriverVehicles));
    let check = false;
    listDriverVehicles.forEach((vehicle) => {
      if (vehicle.vehicle_code === vehicle_code) {
        check = true;
      }
    });
    return check;
  }

  /**
   * isDisabledShift
   * @param {String} vehicle_time
   * @param {String} vehicle_code
   * @return {Boolean}
   */
  isDisabledShift(vehicle_time, vehicle_code) {
    const listDriverVehicles = JSON.parse(JSON.stringify(this.state.listDriverVehicles));
    if (!this.state.driver) {
      return false;
    }
    if (vehicle_time.driver_code && vehicle_time.driver_code !== this.state.driver.driver_code) {
      return true;
    }
    let check_overlap = false;
    listDriverVehicles.forEach((vehicle) => {
      // check overlap thi se tat ca time cua xe nay voi time cua xe khac da ghep, neu ca 2 xe nhu nhau se bi overlap vo han
      if (vehicle.vehicle_code !== vehicle_code) {
        vehicle.times.forEach((item) => {
          const is_overlap = isOverlapDateRelatively(item.start_time, item.end_time, vehicle_time.start_time, vehicle_time.end_time);
          if (is_overlap) {
            check_overlap = true;
          }
        });
      }
    });
    return check_overlap;
  }

  /**
   * chooseDriver
   * @param {*} value
   */
  chooseDriver = async (value) => {
    if (value) {
      const payload = {
        supplier_id: this.state.supplier?.id,
        facility_id: this.state.facility?.id,
        driver_code: value.driver_code,
        driver_name: value.driver_name,
        time: new Date(this.state.date).toISOString().substring(0, 10),
      };
      const queryStr = getQueryStringFromObject(clearBlankValue(payload));
      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: queryStr,
      });
      await this.setState({driver: value, listDriverVehicles: []});
      await this.props.getVehiclesByFacility(this.state.facility.id, new Date(this.state.date).toISOString().substring(0, 10));
      await this.handleSearchListDriverVehicle(value.driver_code);
    }
  };

  /**
   * handleSearchListDriverVehicle
   * @param {Number} driver_id
   */
  handleSearchListDriverVehicle = async (driver_id) => {
    let listDriverVehicles = [];
    const listDriverOperations = [];
    const date = this.state.date;
    const payload = {driver_id: driver_id};
    if (typeof date === 'string' || date instanceof String) {
      payload.from_time = date.substr(0, 10);
    } else {
      payload.from_time = date.getFullYear() + '-' + convertPrefixTime(date.getMonth() + 1) + '-' + convertPrefixTime(date.getDate());
    }
    await this.props.searchListDriverVehicle(payload).then((response) => {
      const listDriverConfig = response.map((vehicle) => ({
        auto_config: vehicle.auto_config,
        vehicle_code: vehicle?.default_vehicle?.vehicle_id,
        vehicle_id: vehicle?.default_vehicle?.id,
        times: vehicle.shifts,
        registration_number: vehicle.registration_number,
        zone_id: vehicle.zone_id,
        mobi_type: vehicle.mobi_type,
      }));
      listDriverVehicles = listDriverConfig || [];
      let scheduleList = [];
      if (response[0]?.driver_operations?.length > 0) {
        const driver_operations = response[0]?.driver_operations.filter((operation) => new Date(operation?.start_time) <= new Date());
        // eslint-disable-next-line no-unused-expressions
        driver_operations?.forEach((operation) => {
          const count = listDriverConfig[0]?.times?.filter((time) => operation?.start_time === time?.start_time && operation?.end_time === time?.end_time)?.length;
          // time operation in schedule
          const operationSelectInTime = listDriverConfig[0]?.times?.filter(
            (time) =>
              (new Date(operation?.start_time) > new Date(time?.start_time) && new Date(operation?.start_time) < new Date(time?.end_time)) ||
              (new Date(operation?.end_time) > new Date(time?.start_time) && new Date(operation?.end_time) < new Date(time?.end_time)),
          );
          scheduleList = scheduleList.concat(listDriverConfig[0]?.times?.filter((time) => operation?.start_time === time?.start_time && operation?.end_time === time?.end_time));
          if (operationSelectInTime.length > 0) {
            listDriverOperations.push({
              ...operation,
              group_type: operationSelectInTime[0].group_type,
              geofence_name: operationSelectInTime[0].geofence_name,
              zone_id: listDriverConfig[0]?.zone_id,
              shift_end_time: operationSelectInTime[0]?.end_time,
              shift_start_time: operationSelectInTime[0]?.start_time,
            });
          } else {
            // time operation out schedule
            if (count === 0) {
              listDriverOperations.push({
                ...operation,
                group_type: DATA_NULL,
                geofence_name: DATA_NULL,
                zone_id: listDriverConfig[0]?.zone_id,
                shift_end_time: DATA_NULL,
                shift_start_time: DATA_NULL,
              });
            }
          }
        });
        scheduleList.sort((a, b) => {
          return new Date(a.start_time) < new Date(b.start_time) ? -1 : 1;
        });
        scheduleList.forEach((schedule) => {
          listDriverOperations.push({
            vehicle: schedule?.driver_operations[0]?.vehicle,
            group_type: schedule?.group_type,
            geofence_name: schedule?.geofence_name,
            start_time: schedule.start_time,
            end_time: schedule.end_time,
            zone_id: listDriverConfig[0]?.zone_id,
            shift_end_time: schedule.end_time,
            shift_start_time: schedule.start_time,
          });
        });
        listDriverOperations.sort((a, b) => {
          return new Date(a.start_time) < new Date(b.start_time) ? -1 : 1;
        });
        listDriverOperations.forEach((operation, index) => {
          if (index < listDriverOperations?.length - 1 && new Date(operation.end_time) < new Date(listDriverOperations[index + 1].start_time)) {
            const checkEndTime = listDriverConfig[0]?.times?.filter((time) => operation?.end_time === time?.end_time || operation?.end_time === time?.start_time)?.length === 1;
            const checkStartTime =
              listDriverConfig[0]?.times?.filter(
                (time) => listDriverOperations[index + 1].start_time === time?.start_time || listDriverOperations[index + 1].start_time === time?.end_time,
              )?.length === 1;
            if (checkStartTime && checkEndTime) {
              listDriverOperations.push({
                vehicle: operation?.vehicle,
                group_type: '',
                geofence_name: DATA_NULL,
                start_time: operation.end_time,
                end_time: listDriverOperations[index + 1].start_time,
                zone_id: listDriverConfig[0]?.zone_id,
                shift_end_time: DATA_NULL,
                shift_start_time: DATA_NULL,
              });
            }
          }
        });
        listDriverOperations.sort((a, b) => {
          return new Date(a.start_time) < new Date(b.start_time) ? -1 : 1;
        });
      }
      this.setState({vehicleDefault: response[0]?.default_vehicle});
    });
    listDriverVehicles &&
      listDriverVehicles.forEach(async (item) => {
        item.apply_start_date = null;
        item.apply_end_date = null;
        item.msgApply = '';
        item.isShowConfirm = false;
        item.auto_config = item.auto_config ? item.auto_config : false;
      });
      let vehicleList = this.props.association?.vehicle.filter((item) => listDriverVehicles[0]?.vehicle_code === item.vehicle_id);
      // filter vehicles by choosee driver
      let vehicleFilterBySearch = [];
      if (this.state.vehicleList?.length > 0) {
        vehicleFilterBySearch = this.state.vehicleList;
        vehicleList = this.state.vehicleList;
      } else {
        vehicleFilterBySearch = vehicleList?.length > 0 ? vehicleList : this.props.association?.vehicle;
      }
      const mobi_type = vehicleList[0]?.mobi_type;
    if (mobi_type === 'SHUTTLE_BUS_ON_DEMAND') {
      listDriverVehicles[0]['mobi_type'] = mobi_type;
      if (this.props.association) {
        const vehicleDefault = this.props?.association?.vehicle.find((vehicle) => vehicle.vehicle_id === listDriverVehicles[0].vehicle_code);
        const operation_sessions_odm_normal = vehicleDefault?.operation_sessions.filter((operation) => operation.operation_type === ODM_BOOKING_TYPE[0].id);
        const operation_sessions_odm_advance = vehicleDefault?.operation_sessions.filter((operation) => operation.operation_type === ODM_BOOKING_TYPE[1].id);
        this.setState({
          sessions_odm_normal: operation_sessions_odm_normal,
          sessions_odm_advance: operation_sessions_odm_advance,
          vehicleSelected: vehicleDefault,
        });
      }
    }
    // set default init data for detail vehicle association
    await this.setState({
      listDriverVehicles: listDriverVehicles,
      listDriverOperations,
      vehicleFilterBySearch: vehicleFilterBySearch,
      vehicleList: vehicleList ? vehicleList : [],
      is_associate: vehicleList[0]?.is_associate,
    });
  };

  /**
   * addDriverVehicle
   */
  addDriverVehicle = () => {
    if (this.state.driver) {
      const listDriverVehicles = JSON.parse(JSON.stringify(this.state.listDriverVehicles));
      listDriverVehicles.push({
        times: [],
        vehicle_code: '',
        vehicle_id: '',
        apply_start_date: null,
        apply_end_date: null,
        msgApply: '',
        isShowConfirm: false,
        registration_number: '',
        activated_times: [],
      });
      this.setState({
        listDriverVehicles: listDriverVehicles,
      });
    }
  };

  /**
   * onChangeVehicle
   * @param {Event} event
   * @param {Number} index
   */
  async onChangeVehicle(event, index) {
    const listDriverVehicles = JSON.parse(JSON.stringify(this.state.listDriverVehicles));
    const selectedVehicle = this.props.association.vehicle?.find((e) => e.vehicle_id === event.target.value);
    const driver = this.props.association?.driver?.find((e) => e.driver_code === this.state.driver?.driver_code);
    listDriverVehicles[index].vehicle_id = selectedVehicle?.id;
    listDriverVehicles[index].vehicle_code = event.target.value;
    listDriverVehicles[index].registration_number = selectedVehicle?.registration_number;
    listDriverVehicles[index].activated_times = [];
    listDriverVehicles[index].zone_id = selectedVehicle.zone_id;
    listDriverVehicles[index].auto_config = await this.props.getAutoConfigVehicleDriverAssociate(selectedVehicle?.id, driver?.id);
    listDriverVehicles[index].mobi_type = selectedVehicle?.mobi_type;
    if (this.props.association) {
      const operation_sessions = selectedVehicle?.operation_sessions;
      if (listDriverVehicles?.length === 1) {
        if ((!isEmpty(this.state.vehicleDefault) && this.state.vehicleDefault?.vehicle_id !== event.target.value) || isEmpty(this.state.vehicleDefault)) {
          operation_sessions.forEach((session) => (session.applied_shift = false));
        }
      }
      const operation_sessions_odm_normal = operation_sessions.filter((operation) => operation.operation_type === ODM_BOOKING_TYPE[0].id);
      const operation_sessions_odm_advance = operation_sessions.filter((operation) => operation.operation_type === ODM_BOOKING_TYPE[1].id);
      this.setState({
        sessions_odm_normal: operation_sessions_odm_normal,
        sessions_odm_advance: operation_sessions_odm_advance,
        vehicleSelected: selectedVehicle,
      });
    }
    this.setState({
      listDriverVehicles: listDriverVehicles,
    });
  }

  /**
   * changeAssociationAutoConfig
   * @param {*} value
   * @param {*} vehicle_id
   * @param {*} index
   */
  async changeAssociationAutoConfig(value, vehicle_id, index) {
    const driver = this.props.association?.driver?.find((e) => e.driver_code === this.state.driver?.driver_code);
    const payload = {
      vehicle_id: vehicle_id,
      driver_id: driver.id,
      is_auto_config: value,
    };
    this.props.updateAutoConfigVehicleDriverAssociate(payload);
    onChangeListData(this, this.state.listDriverVehicles, 'auto_config', index, value);
  }

  /**
   * changeDate
   * @param {Date} date
   */
  changeDate = async (date) => {
    await this.setState({date: date, listDriverVehicles: []});
    if (this.state.driver?.driver_code) {
      const payload = {
        supplier_id: this.state.supplier.id,
        facility_id: this.state.facility.id,
        driver_code: this.state.driver?.driver_code,
        driver_name: this.state.driver?.driver_name,
        time: date.toJSON(),
      };
      const queryStr = getQueryStringFromObject(clearBlankValue(payload));
      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: queryStr,
      });
      await this.props.getDriversByFacility(this.state.facility.id);
      await this.props.getVehiclesByFacility(this.state.facility.id, new Date(this.state.date).toISOString().substring(0, 10));
      await this.handleSearchListDriverVehicle(this.state.driver.driver_code);
    }
  };

  /**
   * removeItem
   * @param {Number} idx
   */
  removeItem(idx) {
    const listDriverVehicles = JSON.parse(JSON.stringify(this.state.listDriverVehicles));
    listDriverVehicles.splice(idx, 1);
    this.setState({
      listDriverVehicles: listDriverVehicles,
    });
  }

  /**
   * findIndexOfVehicle
   * @param {*} driver_vehicle_times
   * @param {*} vehicle_shift
   * @return {Number}
   */
  findIndexOfVehicle(driver_vehicle_times, vehicle_shift) {
    let index = -1;
    driver_vehicle_times.forEach((item, idx) => {
      if (item.shift_setting_id === vehicle_shift.id) {
        index = idx;
      }
    });
    return index;
  }

  /**
   * handleUpdate
   */
  handleUpdate() {
    if (!this.state.driver) {
      return;
    }

    this.setState({
      msgUpdate: this.props.t('messageCode.common.updateConfirm'),
      isShowUpdateConfirm: true,
    });
  }

  /**
   * updateVehicleConfig
   */
  async updateVehicleConfig() {
    this.setState({
      isShowUpdateConfirm: false,
    });
    const listDriverVehicles = this.state.listDriverVehicles;
    let jpDate = this.state.date;
    if (typeof this.state.date !== 'string') {
      jpDate = this.state.date.getFullYear() + '-' + convertPrefixTime(this.state.date.getMonth() + 1) + '-' + convertPrefixTime(this.state.date.getDate());
    } else {
      jpDate = this.state.date.substr(0, 10);
    }

    const shifts_id = [];
    listDriverVehicles.forEach((vehicle) => {
      vehicle.times.forEach((item) => {
        if (item.shift_setting_id) {
          shifts_id.push(item.shift_setting_id);
        }
      });
    });
    const payload = {
      date: jpDate,
      driver_code: this.state.driver.driver_code,
      shifts_id: shifts_id,
      on_demands: [],
    };
    await this.props.configDriverVehicle(payload);
  }

  /**
   * changeApplyDate
   * @param {Number} index
   * @param {Date} date
   * @param {Boolean} isStart
   */
  changeApplyDate = (index, date, isStart) => {
    const listDriverVehicles = JSON.parse(JSON.stringify(this.state.listDriverVehicles));
    isStart ? (listDriverVehicles[index].apply_start_date = date) : (listDriverVehicles[index].apply_end_date = date);
    this.setState({
      listDriverVehicles: listDriverVehicles,
    });
  };

  /**
   * handleApplyButton
   * @param {Number} index
   */
  handleApplyButton = (index) => {
    const listDriverVehicles = JSON.parse(JSON.stringify(this.state.listDriverVehicles));
    const start_date = new Date(listDriverVehicles[index].apply_start_date);
    const end_date = new Date(listDriverVehicles[index].apply_end_date);
    const days = Math.ceil((end_date.getTime() - start_date.getTime()) / (1000 * 60 * 60 * 24));
    const messageApply = days > 30 ? 'businessVehicle.applyFromToOver' : 'businessVehicle.applyFromTo';
    const diffMoth = end_date.getFullYear() * 12 + end_date.getMonth() - start_date.getFullYear() * 12 - start_date.getMonth();
    if (diffMoth <= 3 && diffMoth >= 0) {
      const start = start_date.getFullYear() + '-' + convertPrefixTime(start_date.getMonth() + 1) + '-' + convertPrefixTime(start_date.getDate());
      const end = end_date.getFullYear() + '-' + convertPrefixTime(end_date.getMonth() + 1) + '-' + convertPrefixTime(end_date.getDate());
      listDriverVehicles[index].isShowConfirm = true;
      listDriverVehicles[index].msgApply = {text: messageApply, start_date: start, end_date: end};
      this.setState({
        listDriverVehicles: listDriverVehicles,
      });
    }
  };

  /**
   * handleCancelApply
   * @param {Number} index
   */
  handleCancelApply = (index) => {
    const listDriverVehicles = JSON.parse(JSON.stringify(this.state.listDriverVehicles));
    listDriverVehicles[index].isShowConfirm = false;
    listDriverVehicles[index].msgApply = '';
    this.setState({
      listDriverVehicles: listDriverVehicles,
    });
  };

  /**
   * chooseVehicle
   * @param {*} vehicles
   */
  chooseVehicle = async (vehicles) => {
    const {is_associate, listDriverVehicles} = this.state;
    let vehicleFilterBySearch = vehicles;
    if (vehicles?.length > 0) {
      // update vehicle driver shift config
      const vehicleIds = vehicles.map((vehicle) => vehicle?.id);
      if (listDriverVehicles?.length > 0) {
        for (let index = 0; index < listDriverVehicles.length; index++) {
          if (!vehicleIds.includes(listDriverVehicles[index].vehicle_id)) {
            listDriverVehicles[index].times = [];
            listDriverVehicles[index].vehicle_code = '';
            listDriverVehicles[index].vehicle_id = '';
            listDriverVehicles[index].apply_start_date = null;
            listDriverVehicles[index].apply_end_date = null;
            listDriverVehicles[index].msgApply = '';
            listDriverVehicles[index].isShowConfirm = false;
            listDriverVehicles[index].registration_number = '';
            listDriverVehicles[index].activated_times = [];
            listDriverVehicles[index].auto_config = false;
            listDriverVehicles[index].mobi_type = '';
            listDriverVehicles[index].zone_id = '';
          }
        }
      }
      // check filter vehicle by associate status
      if (typeof(is_associate) === 'boolean') {
        vehicleFilterBySearch = vehicles.filter((vehicle) => vehicle?.is_associate === is_associate);
      }
      this.setState({vehicleFilterBySearch: vehicleFilterBySearch, vehicleList: vehicles, listDriverVehicles});
    } else {
      const newListVehicles = [];
      // clear data select driver shift config
      // eslint-disable-next-line no-unused-vars
      for (const item of listDriverVehicles) {
        newListVehicles.push({
          times: [],
          vehicle_code: '',
          vehicle_id: '',
          apply_start_date: null,
          apply_end_date: null,
          msgApply: '',
          isShowConfirm: false,
          registration_number: '',
          activated_times: [],
        });
      }
      this.setState({
        vehicleFilterBySearch:
          typeof is_associate === 'boolean' ? this.props?.association?.vehicle?.filter((vehicle) => vehicle?.is_associate === is_associate) : this.props?.association?.vehicle,
        vehicleList: [],
        listDriverVehicles: newListVehicles,
      });
    }
  };

  /**
   * changeAssociateStatus
   * @param {object} event
   */
  changeAssociateStatus = (event) => {
    const {listDriverVehicles, vehicleList} = this.state;
    const vehicles = vehicleList.filter((vehicle) => vehicle.is_associate === event.target.value);
    const vehicleIds = vehicles.map((vehicle) => vehicle.id);
    // update vehicle list shift config
    for (let index = 0; index < listDriverVehicles.length; index++) {
      if (!vehicleIds.includes(listDriverVehicles[index].vehicle_id)) {
        listDriverVehicles[index].times = [];
        listDriverVehicles[index].vehicle_code = '';
        listDriverVehicles[index].vehicle_id = '';
        listDriverVehicles[index].apply_start_date = null;
        listDriverVehicles[index].apply_end_date = null;
        listDriverVehicles[index].msgApply = '';
        listDriverVehicles[index].isShowConfirm = false;
        listDriverVehicles[index].registration_number = '';
        listDriverVehicles[index].activated_times = [];
        listDriverVehicles[index].auto_config = false;
        listDriverVehicles[index].mobi_type = '';
        listDriverVehicles[index].zone_id = '';
      }
    }
    if (vehicles?.length > 0) {
      this.setState({is_associate: event.target.value, vehicleFilterBySearch: vehicles, listDriverVehicles});
    } else {
      // filter vehicles shift config by association status
      const vehicleFilterBySearch = vehicleList?.length > 0 ? [] : this.props?.association?.vehicle?.filter((vehicle) => vehicle.is_associate === event.target.value);
      this.setState({is_associate: event.target.value, vehicleFilterBySearch});
    }
  };

  /**
   * applyMoreTime
   * @param {Number} index
   */
  applyMoreTime = async (index) => {
    const listDriverVehicles = JSON.parse(JSON.stringify(this.state.listDriverVehicles));
    listDriverVehicles.forEach((item) => {
      item.isShowConfirm = false;
      item.msgApply = '';
    });
    this.setState({
      listDriverVehicles: listDriverVehicles,
    });
    const data = this.state.listDriverVehicles[index];

    if (data.apply_start_date && data.apply_end_date) {
      const start_date = new Date(data.apply_start_date);
      const end_date = new Date(data.apply_end_date);
      const payload = {
        shifts: data.times,
        vehicle_id: data.vehicle_code,
        driver_code: this.state.driver.driver_code,
        apply_from: convertDateUTCAndGeofence(
          start_date.getFullYear() + '-' + convertPrefixTime(start_date.getMonth() + 1) + '-' + convertPrefixTime(start_date.getDate()) + ' 00:00:00',
          data.zone_id,
          false,
        ),
        apply_to: convertDateUTCAndGeofence(
          end_date.getFullYear() + '-' + convertPrefixTime(end_date.getMonth() + 1) + '-' + convertPrefixTime(end_date.getDate()) + ' 23:59:59',
          data.zone_id,
          false,
        ),
      };
      if (listDriverVehicles.length === 1 && listDriverVehicles[0]?.mobi_type === 'SHUTTLE_BUS_ON_DEMAND') {
        const vehicle_session = this.state.sessions_odm_advance.concat(this.state.sessions_odm_normal).filter((session) => session?.applied_shift === true);
        if (vehicle_session.length === 0) {
          payload['vehicle_session_ids'] = [];
          payload.shifts = [];
        } else {
          payload['vehicle_session_ids'] = vehicle_session.map((item) => item.id);
        }
      }
      this.props.applyTime(payload);
    }
  };

  /**
   * handleGetVehicleActivatedTimes
   * @param {Object} vehicle
   * @param {Number} index
   */
  handleGetVehicleActivatedTimes = async (vehicle, index) => {
    if (vehicle?.vehicle_id) {
      const listDriverVehicles = this.state.listDriverVehicles;
      const vehicle_id = vehicle.vehicle_id || this.props.association?.vehicle.find((e) => e.vehicle_id === vehicle.vehicle_code)?.id;
      await this.props.getVehicleActivatedTimes(vehicle_id).then((response) => {
        listDriverVehicles[index].activated_times = response;
        this.setState({listDriverVehicles: listDriverVehicles});
      });
    }
  };

  /**
   * viewShiftConfigLink
   * @param {String} vehicle_id
   * @return {Link}
   */
  viewShiftConfigLink = (vehicle_id) => {
    if (vehicle_id) {
      return <Link to={{pathname: ROUTE.LAYOUT + ROUTE.VEHICLE_DETAIL + '/' + vehicle_id}}>{this.props.t('businessVehicle.view_shift_config')}</Link>;
    } else {
      return;
    }
  };

  /**
   * onChangeOperationSession
   * @param {string} id
   */
  onChangeOperationSession = (id) => {
    const sessions = this.state.sessions_odm_advance.concat(this.state.sessions_odm_normal);
    const index = sessions.findIndex((session) => session.id === Number(id));
    if (sessions[index]?.applied_shift !== undefined) sessions[index].applied_shift = !sessions[index]?.applied_shift;
    const sessions_odm_normal = sessions.filter((session) => session.operation_type === 'BOOKING_NORMAL');
    const sessions_odm_advance = sessions.filter((session) => session.operation_type === 'BOOKING_ADVANCED');
    const isChecked = sessions.filter((session) => session.applied_shift === true)?.length > 0 ? true : false;
    this.mappingDriverVehicleById(isChecked);
    this.setState({sessions_odm_normal, sessions_odm_advance});
  };

  /**
   * @return {HTMLElement}
   */
  render() {
    const {t, common, association, actions} = this.props;
    const vehicleAssociation = association?.vehicle;
    const permission = {
      canApply: actions.includes(PERMISSION_ACTIONS.APPLY),
      canUsedTime: actions.includes(PERMISSION_ACTIONS.USED_TIME),
      canDeleteShift: actions.includes(PERMISSION_ACTIONS.DELETE_SHIFT),
    };
    return (
      <Card className="SearchAccountType main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('businessVehicle.driver_schedule_register')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Button
                color="primary"
                variant="contained"
                className="button_margin button_color"
                endIcon={<ArrowBackIcon />}
                onClick={() => {
                  if (this.props.location?.state?.driver_detail) this.props.history.goBack();
                  else backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.ASSOCIATION);
                }}
              >
                {t('common.btnReturn')}
              </Button>
            </Grid>
          </Grid>
          <br></br>
          <LoadingOverlay
            active={association.isLoading}
            text={association.isLoadingText ? t('businessVehicle.loadingText') : ''}
            bgColor="#f1f1f1"
            spinnerColor="#9ee5f8"
            textColor="#676767"
            spinner
          >
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('businessVehicle.driver_schedule_register_form')}
                    </Grid>
                  </Grid>
                  {/* Supplier Name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessVehicle.businessName')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <Autocomplete
                        margin="dense"
                        className="field_size_20 field_min_size_350"
                        options={common?.supplier_list}
                        value={this.state.supplier}
                        getOptionLabel={(option) => option.supplier_name || ''}
                        onChange={(event, business) => {
                          this.changeBusiness(business);
                        }}
                        renderInput={(params) => <TextField {...params} name={'businessName'} fullWidth variant="outlined" margin="dense" />}
                        disabled={isRoleBusiness()}
                      />
                    </Grid>
                  </Grid>
                  {/* Facility Name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessVehicle.sales_office_store_name')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <Autocomplete
                        margin="dense"
                        className="field_size_20 field_min_size_350"
                        options={common?.facility_list}
                        value={this.state.facility}
                        getOptionLabel={(option) => option.facility_name || ''}
                        onChange={(event, facility) => this.changeFacilityName(facility)}
                        renderInput={(params) => <TextField {...params} name={'businessName'} fullWidth variant="outlined" margin="dense" />}
                      />
                    </Grid>
                  </Grid>
                  {/* Driver Code */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('driver.code')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <Autocomplete
                        margin="dense"
                        className="field_size_20 field_min_size_350"
                        disabled={!this.state.facility}
                        options={association?.driver}
                        value={this.state.driver}
                        getOptionLabel={(option) => option.driver_code || ''}
                        onChange={(event, driver) => this.chooseDriver(driver)}
                        renderInput={(params) => <TextField {...params} name={'driver_code'} fullWidth variant="outlined" margin="dense" />}
                      />
                    </Grid>
                  </Grid>
                  {/* Driver Name (AD) */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('driver.name')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <Autocomplete
                        margin="dense"
                        className="field_size_20 field_min_size_350"
                        disabled={!this.state.facility}
                        options={association?.driver}
                        value={this.state.driver}
                        getOptionLabel={(option) => option.driver_name || ''}
                        onChange={(event, driver) => this.chooseDriver(driver)}
                        renderInput={(params) => <TextField {...params} name={'driver_name'} fullWidth variant="outlined" margin="dense" />}
                      />
                    </Grid>
                  </Grid>
                  {/* Vehicle number */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessVehicle.vehicleNumber')}
                    </Grid>
                    <Grid alignItems="center" item xs={6} lg={4}>
                      <Autocomplete
                        className="field_size_20 field_min_size_350"
                        size="medium"
                        multiple
                        disabled={!this.state.facility}
                        options={vehicleAssociation}
                        value={this.state.vehicleList}
                        getOptionLabel={(option) => (option?.registration_number || '')}
                        onChange={(event, vehicles) => this.chooseVehicle(vehicles)}
                        renderInput={(params) => <TextField {...params} variant="outlined" multiline={true} />}
                      />
                    </Grid>
                  </Grid>
                  {/* Association status */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessVehicle.associationStatus')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <Select
                        variant="outlined"
                        margin="dense"
                        inputProps={{
                          name: 'association_status',
                        }}
                        disabled= {!this.state.facility}
                        value={typeof(this.state.is_associate) === 'boolean' ? this.state.is_associate : ''}
                        onChange={(event) => this.changeAssociateStatus(event)}
                        className="field_size_20 field_min_size_350"
                      >
                        {ASSOCIATION_STATUS?.map((item, idx) => (
                          <MenuItem value={item.value} key={idx}>
                            {t(item.i18n)}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  </Grid>
                  {/* Date */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessVehicle.date')}
                      <span className="font_color_red">*</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl className="field_size_20 field_min_size_350">
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          inputVariant="outlined"
                          format="dd/MM/yyyy"
                          margin="dense"
                          value={this.state.date}
                          onChange={this.changeDate}
                          autoOk={true}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          open={this.state.isOpenDate}
                          onClick={() => this.setState({isOpenDate: true})}
                          onClose={() => this.setState({isOpenDate: false})}
                          inputProps={{
                            readOnly: true,
                          }}
                          minDate={new Date()}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Paper>
                <br></br>

                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('businessVehicle.driver_shift_config')}
                    </Grid>
                  </Grid>
                  {/* Time */}
                  <Grid container spacing={1} className="row_form_item" style={{overflowX: 'auto'}}>
                    {this.state.listDriverVehicles?.map((vehicle, index) => (
                      <Grid container item spacing={1} justify="center" alignItems="center" className="row_form_item" key={index}>
                        <Grid container alignItems="center" item xs={12} lg={7}>
                          <div className="display_flex padding-right-24">
                            <Select
                              variant="outlined"
                              margin="dense"
                              inputProps={{
                                name: 'status',
                              }}
                              value={vehicle.vehicle_code}
                              onChange={(event) => this.onChangeVehicle(event, index)}
                            >
                              {this.state.vehicleFilterBySearch?.map(
                                (item, idx) =>
                                  item?.vehicle_shifts && item?.vehicle_shifts.length > 0 && (
                                    <MenuItem disabled={this.isDisableVehicle(item.vehicle_id)} value={item.vehicle_id} key={idx}>
                                      {item.vehicle_id} - {item?.is_associate === true ? t('common.associated') : t('common.unassociated')} - {item?.registration_number}
                                    </MenuItem>
                                  ),
                              )}
                            </Select>
                            <Button color="primary" onClick={() => this.removeItem(index)} endIcon={<RemoveCircleIcon />}></Button>
                          </div>
                          <div className="display_flex">
                            <FormControl className="field_size_20 field_min_size_200">
                              <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                inputVariant="outlined"
                                format="dd/MM/yyyy"
                                margin="dense"
                                placeholder={t('sub.effective_date_from')}
                                value={this.state.listDriverVehicles[index].apply_start_date}
                                onChange={(date) => this.changeApplyDate(index, date, true)}
                                autoOk={true}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                                minDate={new Date()}
                              />
                            </FormControl>
                            <span style={{padding: 5, alignSelf: 'center'}}>~</span>
                            <FormControl className="field_size_20 field_min_size_200">
                              <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                inputVariant="outlined"
                                format="dd/MM/yyyy"
                                margin="dense"
                                placeholder={t('sub.effective_date_to')}
                                value={this.state.listDriverVehicles[index].apply_end_date}
                                onChange={(date) => this.changeApplyDate(index, date, false)}
                                autoOk={true}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                                minDate={
                                  this.state.listDriverVehicles[index].apply_start_date ?
                                    this.state.listDriverVehicles[index].apply_start_date :
                                    new Date(new Date().getTime() + 86400000)
                                }
                                maxDate={getMaxDate(this.state.listDriverVehicles[index].apply_start_date)}
                              />
                            </FormControl>
                          </div>
                          {permission.canApply && (
                            <Button
                              disabled={!this.state.listDriverVehicles[index].apply_start_date || !this.state.listDriverVehicles[index].apply_end_date}
                              variant="contained"
                              className="button_margin space_left_S"
                              color="secondary"
                              onClick={() => this.handleApplyButton(index)}
                            >
                              {t('common.btnApply')}
                            </Button>
                          )}
                          <Dialog
                            open={this.state.listDriverVehicles[index].isShowConfirm}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              zIndex: '1020',
                            }}
                          >
                            <SelectModal
                              onClickOk={() => this.applyMoreTime(index)}
                              onClickCancel={() => this.handleCancelApply(index)}
                              message={t(this.state.listDriverVehicles[index].msgApply?.text, {
                                start_date: this.state.listDriverVehicles[index].msgApply?.start_date,
                                end_date: this.state.listDriverVehicles[index].msgApply?.end_date,
                              })}
                            ></SelectModal>
                          </Dialog>
                        </Grid>
                        <Grid container alignItems="center" item xs={12} lg={5}>
                          <Grid container alignItems="center" item xs={4} lg={7}>
                            {permission.canUsedTime && (
                              <Button
                                variant="contained"
                                className="button_margin space_left_S"
                                color="primary"
                                endIcon={<ListAltIcon />}
                                onClick={() => this.handleGetVehicleActivatedTimes(vehicle, index)}
                              >
                                {t('driverAssociation.activated_times')}
                              </Button>
                            )}
                            {this.state.driver?.driver_code && permission.canDeleteShift && (
                              <Button
                                variant="contained"
                                className="button_margin space_left_S button_color_red"
                                color="primary"
                                endIcon={<DeleteIcon />}
                                onClick={() =>
                                  this.setState({
                                    openDeleteShiftModal: vehicle.vehicle_id ? true : false,
                                    deleteShiftVehicleId: vehicle.vehicle_id,
                                  })
                                }
                              >
                                {t('driverAssociation.delete_shift')}
                              </Button>
                            )}
                          </Grid>
                          <Grid container alignItems="center" item xs={8} lg={8} className="vehicle_drivers">
                            {vehicle?.activated_times?.map((item, idx) => (
                              <div className="display_contents" key={idx}>
                                <div className="display_grid">
                                  <span>{t('common.from')}:</span> <span>{t('common.to')}:</span>
                                </div>
                                <div className="display_grid padding-right-24 ">
                                  <span>{item?.from}</span> <span>{item?.to}</span>
                                </div>
                              </div>
                            ))}
                          </Grid>
                        </Grid>
                        <Grid container alignItems="center" item xs={12} lg={12}>
                          <Grid container alignItems="center" justify="flex-start" item xs={4} lg={2} md={2} className="padding-left-12 display_grid">
                            {this.viewShiftConfigLink(association?.vehicle?.find((e) => e.vehicle_id === vehicle.vehicle_code)?.id)}
                            <span className="font_size_small padding-top-12">
                              {t('businessVehicle.auto_config')}: <LensIcon style={{color: vehicle?.auto_config ? '#18c090' : '#ff483c', fontSize: '10px', marginLeft: 10}} />
                            </span>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch
                                    color="primary"
                                    checked={vehicle?.auto_config || false}
                                    onChange={(event, value) => this.changeAssociationAutoConfig(value, vehicle.vehicle_id, index)}
                                  />
                                }
                              />
                            </FormGroup>
                          </Grid>
                          {this.state.listDriverVehicles[0].mobi_type !== 'SHUTTLE_BUS_ON_DEMAND' && (
                            <Grid container alignItems="center" item xs={8} lg={10} md={10}>
                              {this.getVehicleTime(vehicle.vehicle_code)?.vehicle_shifts?.length > 0 &&
                                this.getVehicleTime(vehicle.vehicle_code)?.vehicle_shifts?.map((item, idx) => {
                                  const checkDisabled = this.isDisabledShift(item, vehicle.vehicle_code) || !item.start_time || !item.end_time;
                                  return (
                                    <div
                                      title={item.driver_code}
                                      className={
                                        checkDisabled ?
                                          'disabled_color display_flex table_background_color_aliceblue margin-item-12' :
                                          'display_flex table_background_color_aliceblue margin-item-12'
                                      }
                                      key={idx}
                                    >
                                      <Checkbox
                                        disabled={checkDisabled}
                                        checked={vehicle.times?.find((element) => element.shift_setting_id === item.id) ? true : false}
                                        inputProps={checkDisabled ? {'aria-label': 'disabled checkbox'} : {'aria-label': ''}}
                                        onClick={() => this.mappingDriverVehicle(index, item)}
                                      ></Checkbox>
                                      <div className="display_grid">
                                        <span>{VEHICLE_MOBI_TYPES_CONST[item.type]}</span>
                                        <span style={{minWidth: 100}}>
                                          {convertTimeUTCToCalendarLocal(item.start_time, this.getVehicleTime(vehicle.vehicle_code)?.zone_id) +
                                            '-' +
                                            convertTimeUTCToCalendarLocal(item.end_time, this.getVehicleTime(vehicle.vehicle_code)?.zone_id)}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                })}
                            </Grid>
                          )}
                          <Grid container alignItems="center" item xs={8} lg={8} md={10}>
                            {this.state.listDriverVehicles[0].mobi_type === 'SHUTTLE_BUS_ON_DEMAND' &&
                              this.state.vehicleFilterBySearch?.filter((item) => item.id === vehicle.vehicle_id)?.length > 0 && (
                                <>
                                  {this.state.sessions_odm_normal?.length > 0 && (
                                    <>
                                      <Grid item xs={2} lg={2} md={2} className="display_grid">
                                        {t('businessVehicle.booking_normal')}
                                      </Grid>
                                      <Grid container alignItems="center" item xs={6} lg={9} md={8}>
                                        {this.state.sessions_odm_normal &&
                                          this.state.sessions_odm_normal.map((operation, idx) => {
                                            const end_time_date = new Date('2023/01/01 ' + operation.end_time);
                                            return (
                                              <div title={''} className={'display_flex table_background_color_aliceblue margin-item-12'} key={idx}>
                                                <Checkbox
                                                  disabled={false}
                                                  checked={this.state.isEditFrom ? operation?.applied_shift : operation?.applied_shift || false}
                                                  value={operation.id}
                                                  onChange={(event) => this.onChangeOperationSession(event.target.value)}
                                                ></Checkbox>
                                                <div className="display_grid">
                                                  <span>{VEHICLE_MOBI_TYPES_CONST.SHUTTLE_BUS_ON_DEMAND}</span>
                                                  <span style={{minWidth: 100}}>
                                                    {format(new Date('2023/01/01 ' + operation.start_time), TIME_FORMAT)}
                                                    {'-'}
                                                    {format(end_time_date.setSeconds(end_time_date.getSeconds() + 1), TIME_FORMAT)}
                                                  </span>
                                                </div>
                                              </div>
                                            );
                                          })}
                                      </Grid>
                                    </>
                                  )}
                                  {this.state.sessions_odm_advance?.length > 0 && (
                                    <>
                                      <Grid item xs={2} lg={2} md={2} className="display_grid">
                                        {t('businessVehicle.booking_advanced')}
                                      </Grid>
                                      <Grid container alignItems="center" item xs={6} lg={9} md={8}>
                                        {this.state.sessions_odm_advance &&
                                          this.state.sessions_odm_advance.map((operation, idx) => {
                                            const end_time_date = new Date('2023/01/01 ' + operation.end_time);
                                            return (
                                              <div title={''} className={'display_flex table_background_color_aliceblue margin-item-12'} key={idx}>
                                                <Checkbox
                                                  disabled={false}
                                                  checked={operation?.applied_shift || false}
                                                  value={operation.id}
                                                  onChange={(event) => this.onChangeOperationSession(event.target.value)}
                                                ></Checkbox>
                                                <div className="display_grid">
                                                  <span>{VEHICLE_MOBI_TYPES_CONST.SHUTTLE_BUS_ON_DEMAND}</span>
                                                  <span style={{minWidth: 100}}>
                                                    {format(new Date('2023/01/01 ' + operation.start_time), TIME_FORMAT)}
                                                    {'-'}
                                                    {format(end_time_date.setSeconds(end_time_date.getSeconds() + 1), TIME_FORMAT)}
                                                  </span>
                                                </div>
                                              </div>
                                            );
                                          })}
                                      </Grid>
                                    </>
                                  )}
                                </>
                              )}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Paper>

                <br></br>
                <Grid container spacing={1}>
                  {permission.canApply && (
                    <Grid container alignItems="center" justify="flex-start" item xs={6}>
                      <Button color="primary" variant="contained" className="button_margin button_color_green" onClick={this.addDriverVehicle}>
                        +
                      </Button>
                    </Grid>
                  )}
                  <Grid container alignItems="center" justify="flex-end" item xs={6}>
                    {/* <Button color="primary" variant="contained" className="button_margin button_color_green" onClick={() => this.handleUpdate()}>
                      {t('common.btnConfirm')}
                    </Button> */}
                  </Grid>
                </Grid>
                <br></br>
                <Grid container className="row_form_item">
                  <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                    {t('businessVehicle.driver_vehicle_association')}
                  </Grid>
                </Grid>
                <Paper className="search_table">
                  <br></br>
                  <Grid className="grid_title_padding" xl={8}>
                    <Table aria-label="sticky table" stickyHeader className="layoutfix">
                      <TableHead>
                        <TableRow>
                          <TableCell className="width_250p">{t('common.geofence')}</TableCell>
                          <TableCell className="width_250p">{t('businessVehicle.mobiType')}</TableCell>
                          <TableCell className="width_150p">{t('businessVehicle.code')}</TableCell>
                          <TableCell className="width_150p">{t('businessVehicle.vehicle_selection_period')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.listDriverOperations?.length > 0 &&
                          this.state.listDriverOperations.map((operation, index) => {
                            const checkOffLast =
                              index === this.state.listDriverOperations?.length - 1 &&
                              this.state.listDriverVehicles[0]?.times?.filter((item, indexItem) =>
                                indexItem === 0 ? item.end_time === operation.end_time || item.start_time === operation.end_time : item.end_time === operation.end_time,
                              )?.length === 0;
                            const checkDisplayTime =
                              (index === this.state.listDriverOperations?.length - 1 && new Date(operation.end_time) < new Date()) ||
                              index !== this.state.listDriverOperations?.length - 1;
                            const start_time = moment(moment.tz(operation.start_time, operation.zone_id)).format(TIME_FORMAT);
                            const end_time = moment(moment.tz(operation.end_time, operation.zone_id)).format(TIME_FORMAT);
                            const dateSelect = format(this.state.date, DATE_FORMAT);
                            const currentDate = moment(moment.tz(new Date().toISOString(), this.state.listDriverVehicles[0]?.zone_id)).format('YYYY/MM/DD');
                            return (
                              <>
                                <TableRow key={index}>
                                  <TableCell>{operation.geofence_name}</TableCell>
                                  <TableCell>{t(MOBI_TYPES.find((mobiType) => mobiType.id === operation.group_type)?.i18n) || DATA_NULL}</TableCell>
                                  <TableCell>{operation.vehicle.vehicle_id}</TableCell>
                                  <TableCell>
                                    {start_time} - {dateSelect === currentDate && !checkDisplayTime ? '--:--' : end_time}
                                  </TableCell>
                                </TableRow>
                                {dateSelect === currentDate &&
                                  !checkOffLast &&
                                  this.state.listDriverOperations?.length - 1 === index &&
                                  new Date() > new Date(operation.end_time) && (
                                    <>
                                      <TableRow key={index + 1}>
                                        <TableCell>{DATA_NULL}</TableCell>
                                        <TableCell>{DATA_NULL}</TableCell>
                                        <TableCell>{operation.vehicle.vehicle_id}</TableCell>
                                        <TableCell>
                                          {end_time} - {'--:--'}
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  )}
                              </>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </Grid>
                </Paper>
                <Dialog
                  open={this.state.isShowUpdateConfirm}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal onClickOk={this.updateVehicleConfig.bind(this)} onClickCancel={this.handleCancelUpdate.bind(this)} message={this.state.msgUpdate}></SelectModal>
                </Dialog>
                <br></br>
              </Container>
            </Card>
          </LoadingOverlay>
          <br></br>

          <br></br>
          <Dialog
            open={this.state.flag}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal onClickOk={this.handleButtonOk} message={this.state.message}></SelectModal>
          </Dialog>
        </Container>
        <Container>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.openDeleteShiftModal}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1000',
            }}
          >
            <DialogContent>
              <DeleteShift
                props={{
                  ...this.props,
                  vehicleId: this.state.deleteShiftVehicleId,
                  date: new Date(this.state.date),
                }}
                reloadDetail={() => this.loadDetail()}
                onClickClose={() => this.setState({openDeleteShiftModal: false})}
              />
            </DialogContent>
          </Modal>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    association: state.driverVehicleAssociation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListSupplier: () => dispatch(getListSupplier()),
    getListFacilityBySupplierID: (params, browserHistory) => dispatch(getListFacilityBySupplierID(params, browserHistory)),
    setMessageModal: (params) => dispatch(setMessageModal(params)),
    searchListDriverVehicle: (payload) => dispatch(searchListDriverVehicle(payload)),
    configDriverVehicle: (payload) => dispatch(configDriverVehicle(payload)),
    applyTime: (payload) => dispatch(applyTime(payload)),
    getDriversByFacility: (id, browserHistory) => dispatch(getDriversByFacility(id, browserHistory)),
    getVehiclesByFacility: (id, date) => dispatch(getVehiclesByFacility(id, date)),
    getVehicleActivatedTimes: (id) => dispatch(getVehicleActivatedTimes(id)),
    getAutoConfigVehicleDriverAssociate: (vehicle_id, driver_id) => dispatch(getAutoConfigVehicleDriverAssociate(vehicle_id, driver_id)),
    updateAutoConfigVehicleDriverAssociate: (payload) => dispatch(updateAutoConfigVehicleDriverAssociate(payload)),
  };
};
export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
