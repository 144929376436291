import {Component, default as React} from 'react';

import {
  Backdrop,
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {
  PlaylistAddCheck as PlaylistAddCheckIcon,
  Add as AddIcon,
  CloudUpload as CloudUploadIcon,
} from '@material-ui/icons';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import SearchGeofenceModal from './modals/SearchGeofenceModal';
import SearchStopSetModal from './modals/SearchStopSetModal';
import {
  ROUTE,
  TRANSPORTATION_TYPES,
  VEHICLE_GROUP_TYPES,
  CURRENCY,
  PAYMENT_SUPPORT,
} from '../../../common/constant';
import {checkSimulationSwatApi, cloneSimulationV2Api} from '../../../services/simulationService';
import {getListVehicleMode} from '../../../stores/business_vehicles/action';
import {getListGeofence} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {applySimulation, enableSimulation, getSimulationDetail} from '../../../stores/simulation/actions';
import {
  onChangeSelect,
  onChangeTextField,
  onChangeCheckBox,
  backForwardRouter,
  onChangeTextFieldPositiveNumber,
  preventNumberInvalidChars,
} from '../../../utils/common';
import {formatUtc, FORMAT_PATTERN_DATE_PICKER, localToUtc} from '../../../utils/date_time_utils';
import {modalObj} from '../../../utils/modal';
import withPermissionData from '../../../withPermissionData/hoc';
import './style.css';

// default data simulation
const SIMULATION_ON_DEMAND_TEMPLATE= {
  vehicle_capacity: 20,
  max_additional_journey_time: 300,
  max_additional_journey_time_percent: 50,
  max_walking_distance: 500,
  pickup_service_time: 0,
  dropoff_service_time: 0,
  shrink_time_delta: 300,
  reverse_shrink_time_delta: 1,
  enable_curb: true,
  limit_user_booking: 9,
  acceptable_waiting_time: 1200,
  trip_cost: 1.1,
  waiting_time_cost_factor: 0.5,
  performance_tracker_enabled: true,
};

const SIMULATION_JIT_HOME_TO_WORK_TEMPLATE= {
  vehicle_capacity: 20,
  max_additional_journey_time: 3600,
  max_additional_journey_time_percent: 10,
  max_walking_distance: 500,
  pickup_service_time: 60,
  dropoff_service_time: 0,
  shrink_time_delta: 300,
  reverse_shrink_time_delta: 1,
  enable_curb: true,
  limit_user_booking: 10,
  acceptable_waiting_time: 1800,
  trip_cost: 1.1,
  waiting_time_cost_factor: null,
  performance_tracker_enabled: false,
};

const SIMULATION_JIT_WORK_TO_HOME_TEMPLATE= {
  vehicle_capacity: 20,
  max_additional_journey_time: 3600,
  max_additional_journey_time_percent: 10,
  max_walking_distance: 500,
  pickup_service_time: 0,
  dropoff_service_time: 60,
  shrink_time_delta: 300,
  reverse_shrink_time_delta: 1,
  enable_curb: true,
  limit_user_booking: 10,
  acceptable_waiting_time: 1800,
  trip_cost: 1.1,
  waiting_time_cost_factor: null,
  performance_tracker_enabled: false,
};

/**
 * Simulation detail
 */
class SimulationDetailComponent extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      simulation_id: '',
      transportation_type: '',
      vehicle_group_type: '',
      name: '',
      start_time: '',
      end_time: '',
      vehicle_capacity: 20,
      acceptable_waiting_time: 1800,
      waiting_time_cost_factor: 0.5,
      max_additional_journey_time: 3600,
      max_additional_journey_time_percent: 30,
      max_walking_distance: 500,
      pickup_service_time: 60,
      dropoff_service_time: 60,
      max_pool_size: 1,
      trip_cost: '',
      time_factor: '',
      shrink_time_delta: 300,
      reverse_shrink_time_delta: 1,
      offer_auto_cancellation_timeout: 120,
      start_time_end_time_limitations: true,
      nearest_distance: '',
      geofence_id: '',
      transit_stop_set_id: '',
      stop_set_id: '',
      enable: true,
      limit_user_booking: 40,
      manual_manage_transit_stop: true,
      door_to_door_mode: false,
      allow_vehicle_late: false,
      enable_waypoints_cache: true,
      enable_curb: true,
      currency_code: '',
      payment_method: [],
      payment_support: [],
      description: '',
      performance_tracker_enabled: false,
      driving_side: 'LEFT',
      send_vehicle_cancellation_updates: true,
      tracking_driver: true,
      vehicle_mode: '',
      show_search_walking_result: false,
      country_code: '',
      service_id: '',
      show_waypoint_in_map: true,
      use_path_equalizer: true,
      isSubmitForm: false,
      isSearchStopSetModalOpened: false,
      isSearchGeofenceModalOpened: false,
      selectedStopSet: {},
      selectedGeofence: {},
      // Simulation Swat
      simulationSwat: null,
      isSimulationSwatLoading: false,
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getListGeofence({targetScreen: 'ENABLE_FOR_ADMIN_ALL'});
    await this.props.getListVehicleMode();
    if (this.props.match.params.id) {
      this.props.getSimulationDetail(this.props.match.params.id).then(
        (response) => {
          const country_code = this.props.common?.geofence_list?.find((geofence) => geofence.geofence_id === response.geofence_id)?.country?.country_code;
          this.setState({
            ...response,
            start_time: formatUtc(response.start_time, FORMAT_PATTERN_DATE_PICKER),
            end_time: formatUtc(response.end_time, FORMAT_PATTERN_DATE_PICKER),
            driving_side: response?.driving_side || 'LEFT',
            payment_method: response?.payment_method || [],
            payment_support: response?.payment_support || [],
            tracking_driver: response?.tracking_driver !== null ? response?.tracking_driver : true,
            selectedGeofence: this.props.common?.geofence_list?.find((item) => item.geofence_id === response?.geofence_id),
            country_code,
          });
        },
        () => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.SIMULATION_MANAGEMENT),
      );
    }
  }

  /**
   * handleUpdate
   */
  handleUpdate = () => {
    this.setState({isSubmitForm: true});
    if (!this.validator.allValid()) return;
    if (this.state.start_time >= this.state.end_time) return;
    const payload = {
      id: this.state.id ? parseInt(this.state.id) : null,
      simulation_id: this.state.simulation_id ? parseInt(this.state.simulation_id) : null,
      transportation_type: this.state.transportation_type,
      vehicle_group_type: this.state.vehicle_group_type,
      name: this.state.name,
      start_time: this.state.start_time ? localToUtc(this.state.start_time) : null,
      end_time: this.state.end_time ? localToUtc(this.state.end_time) : null,
      vehicle_capacity: this.state.transportation_type === TRANSPORTATION_TYPES[0].id ? parseInt(this.state.vehicle_capacity) : null,
      max_additional_journey_time: this.state.transportation_type === TRANSPORTATION_TYPES[0].id ? parseFloat(this.state.max_additional_journey_time) : null,
      max_additional_journey_time_percent: this.state.transportation_type === TRANSPORTATION_TYPES[0].id ? parseFloat(this.state.max_additional_journey_time_percent) : null,
      max_walking_distance: this.state.transportation_type === TRANSPORTATION_TYPES[0].id ? parseFloat(this.state.max_walking_distance) : null,
      nearest_distance: this.state.transportation_type === TRANSPORTATION_TYPES[2].id ? parseFloat(this.state.nearest_distance) : 0,
      enable_waypoints_cache: this.state.transportation_type === TRANSPORTATION_TYPES[0].id ? this.state.enable_waypoints_cache : null,
      geofence_id: this.state.geofence_id,
      enable: this.state.enable,
      enable_curb: this.state.transportation_type === TRANSPORTATION_TYPES[0].id ? this.state.enable_curb : null,
      manual_manage_transit_stop: this.state.transportation_type === TRANSPORTATION_TYPES[2].id ? this.state.manual_manage_transit_stop : false,
      allow_vehicle_late: this.state.transportation_type !== TRANSPORTATION_TYPES[2].id ? this.state.allow_vehicle_late : null,
      door_to_door_mode: this.state.transportation_type === TRANSPORTATION_TYPES[0].id ? this.state.door_to_door_mode : null,
      limit_user_booking: parseInt(this.state.limit_user_booking),
      waypoint_mode: 'OFF',
      description: this.state.description,
      acceptable_waiting_time: this.state.transportation_type === TRANSPORTATION_TYPES[0].id ? parseFloat(this.state.acceptable_waiting_time) : null,
      waiting_time_cost_factor: this.state.transportation_type === TRANSPORTATION_TYPES[0].id ? parseFloat(this.state.waiting_time_cost_factor) : null,
      transit_stop_set_id: this.state.transportation_type === TRANSPORTATION_TYPES[0].id ? parseInt(this.state.transit_stop_set_id) : null,
      pickup_service_time: this.state.transportation_type === TRANSPORTATION_TYPES[0].id ? parseInt(this.state.pickup_service_time) : null,
      dropoff_service_time: this.state.transportation_type === TRANSPORTATION_TYPES[0].id ? parseInt(this.state.dropoff_service_time) : null,
      shrink_time_delta: this.state.transportation_type === TRANSPORTATION_TYPES[0].id ? parseInt(this.state.shrink_time_delta) : null,
      reverse_shrink_time_delta: this.state.transportation_type === TRANSPORTATION_TYPES[0].id ? parseInt(this.state.reverse_shrink_time_delta) : null,
      offer_auto_cancellation_timeout: this.state.transportation_type === TRANSPORTATION_TYPES[0].id ? parseInt(this.state.offer_auto_cancellation_timeout) : null,
      start_time_end_time_limitations: this.state.transportation_type === TRANSPORTATION_TYPES[0].id ? this.state.start_time_end_time_limitations : null,
      stop_set_id: this.state.transportation_type === TRANSPORTATION_TYPES[0].id ? parseInt(this.state.stop_set_id) : null,
      currency_code: this.state.currency_code,
      payment_method: this.state.payment_method,
      payment_support: this.state.payment_support,
      performance_tracker_enabled: this.state.performance_tracker_enabled,
      driving_side: this.state.driving_side,
      send_vehicle_cancellation_updates: this.state.send_vehicle_cancellation_updates,
      tracking_driver: this.state.tracking_driver,
      vehicle_mode: this.state.vehicle_mode,
      show_search_walking_result: this.state.show_search_walking_result,
      max_pool_size: this.state.max_pool_size,
      show_waypoint_in_map: this.state.show_waypoint_in_map,
      use_path_equalizer: this.state.use_path_equalizer,
      time_factor: this.state.time_factor ? parseFloat(this.state.time_factor) : null,
    };
    Object.keys(payload).forEach((key) => {
      if (payload[key] === null) {
        delete payload[key];
      }
    });
    this.props.enableSimulation(payload).then((res) => {
      if (this.props.location.pathname?.indexOf('add') !== -1 && res?.status === 200) {
        this.props.history.push(ROUTE.LAYOUT + ROUTE.SIMULATION_MANAGEMENT);
      }
    });
  };

  /**
   * handleClone
   */
  handleClone = () => {
    const ok = window.confirm('Clone this simulation?');
    if (ok) {
      const simulationId = this.state.simulation_id;
      cloneSimulationV2Api(simulationId).then(
        (payload) => {
          if (payload.status === 200) {
            this.setState({
              isLoading: false,
            });
            if (payload.result && payload.result > 0) {
              this.props.showMessage(payload.message_code);
              const newSimulationId = payload.result;
              this.props.history.push('./' + newSimulationId);
              this.componentDidMount();
            } else {
              this.props.showMessage('simulationManagement.clone.failedProcess');
            }
          } else {
            this.props.showMessage(payload.message_code);
            this.setState({isLoading: false});
          }
        },
        () => this.setState({isLoading: false}),
      );
    }
  };

  /**
   * changeArea
   * @param {event} value
   */
  changeArea = (value) => {
    const country_code = this.props.common?.geofence_list?.find((geofence) => geofence.geofence_id === value).country.country_code;
    this.setState({
      ...this.state,
      geofence_id: value,
      country_code,
    });
  };

  /**
   * onChangeTransportation
   * @param {*} e
   */
  onChangeTransportation = (e) => {
    this.setState({transportation_type: e.target.value, vehicle_group_type: ''});
  };

  /**
   * renderVehicleGroupType
   * @param {*} transportation_type
   * @return {*}
   */
  renderVehicleGroupType(transportation_type) {
    if (transportation_type === 'SHUTTLE_BUS') {
      return VEHICLE_GROUP_TYPES.filter((item) => item.id.includes('SHUTTLE_BUS'));
    }
    if (transportation_type === 'TAXI') {
      return VEHICLE_GROUP_TYPES.filter((item) => item.id === 'TAXI');
    }
    if (transportation_type === 'UNITRAND') {
      return VEHICLE_GROUP_TYPES.filter((item) => item.id === 'UNITRAND');
    }
    return VEHICLE_GROUP_TYPES;
  }

  /**
   * onChangeVehicleGroupType
   * @param {Event} event
   */
  onChangeVehicleGroupType = (event) => {
    if (this.props.location.pathname?.indexOf('add') === -1) {
      this.setState({
        vehicle_group_type: event.target.value,
      });
    } else {
      if (event.target.value === 'SHUTTLE_BUS_ON_DEMAND') {
        this.setState({
          ...this.state,
          vehicle_group_type: event.target.value,
          ...SIMULATION_ON_DEMAND_TEMPLATE,
        });
      } else if (event.target.value === 'SHUTTLE_BUS_JIT_HOME_TO_WORK') {
        this.setState({
          ...this.state,
          vehicle_group_type: event.target.value,
          ...SIMULATION_JIT_HOME_TO_WORK_TEMPLATE,
        });
      } else if (event.target.value === 'SHUTTLE_BUS_JIT_WORK_TO_HOME') {
        this.setState({
          ...this.state,
          vehicle_group_type: event.target.value,
          ...SIMULATION_JIT_WORK_TO_HOME_TEMPLATE,
        });
      } else {
        this.setState({
          vehicle_group_type: event.target.value,
        });
      }
    }
  }

  /**
   * handleOpenModal
   * @param {String} fieldName
   */
  handleOpenModal = (fieldName) => {
    this.setState({
      [fieldName]: true,
    });
  };

  /**
   * handleCloseModal
   * @param {String} fieldName
   */
  handleCloseModal = (fieldName) => {
    this.setState({
      [fieldName]: false,
    });
  };

  /**
   * handleCheckSimulationSwat
   */
  handleCheckSimulationSwat = async () => {
    this.setState({isSimulationSwatLoading: true});
    await checkSimulationSwatApi(this.state.simulation_id).then((response) => {
      if (response && response.status === 200) {
        this.setState({
          isSimulationSwatLoading: false,
          simulationSwat: response.result,
          pickup_service_time: response.result?.data?.calculation_parameters?.pickup_service_time,
          max_pool_size: response.result.data?.calculation_parameters?.max_pool_size,
          dropoff_service_time: response.result.data?.calculation_parameters?.dropoff_service_time,
          trip_cost: response.result?.data?.calculation_parameters?.trip_cost,
          shrink_time_delta: response.result?.data?.shrink_time_delta,
          reverse_shrink_time_delta: response.result?.data?.reverse_shrink_time_delta,
        });
      }
    });
  };

  /**
   * render
   * @return {HTML}
   */
  render() {
    const {t, simulation} = this.props;
    const classes = makeStyles((theme) => ({
      container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
      },
    }));

    return (
      <LoadingOverlay active={simulation.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('simulationManagement.detail')}</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}></Grid>
            </Grid>
          </Container>
          <Container maxWidth="xl">
            <Box m={2} p={2}>
              <Card raised>
                <Container maxWidth="xl">
                  <CardHeader
                    title={
                      <Typography gutterBottom variant="h5" component="h5">
                        {t('simulationManagement.information')}
                      </Typography>
                    }
                  />
                  <Box pt={3} pb={3}>
                    <Grid container>
                      <Grid container item xs={7} direction="column">
                        <Table size="small">
                          <TableBody>
                            {/* simulation id */}
                            {this.props.match.params.id && (
                              <TableRow>
                                <TableCell className="width_50">{t('simulationManagement.simulation_id')}</TableCell>
                                <TableCell>{this.state.simulation_id}</TableCell>
                              </TableRow>
                            )}

                            {/* transportation type */}
                            <TableRow>
                              <TableCell className="width_50">
                                {t('simulationManagement.transportation_type')} <span className="font_color_red">＊</span>
                              </TableCell>
                              <TableCell>
                                <FormControl variant="outlined" className="field_size_10 field_min_size_350">
                                  <Select
                                    inputProps={{
                                      name: 'transportation_type',
                                    }}
                                    displayEmpty
                                    renderValue={
                                      this.state.transportation_type ?
                                        undefined :
                                        () => (
                                            <div className="font-12 color-disabled">
                                              {t('placeholder.required_select', {
                                                field: t('simulationManagement.transportation_type'),
                                              })}
                                            </div>
                                          )
                                    }
                                    value={this.state.transportation_type}
                                    onChange={(event) => this.onChangeTransportation(event)}
                                  >
                                    {TRANSPORTATION_TYPES.map(({id, i18n}, index) => (
                                      <MenuItem value={id} key={index}>
                                        {t(`${i18n}`)}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  {this.validator.message('transportation_type', this.state.transportation_type, 'required')}
                                  {this.state.isSubmitForm && !this.validator.check(this.state.transportation_type, 'required') && (
                                    <FormHelperText id="transportation_type" error>
                                      {t('validation.required', {field: t('simulationManagement.transportation_type')})}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </TableCell>
                            </TableRow>

                            {/* vehicle group type */}
                            <TableRow>
                              <TableCell className="width_50">
                                {t('simulationManagement.vehicleGroupType')} <span className="font_color_red">＊</span>
                              </TableCell>
                              <TableCell>
                                <FormControl variant="outlined" className="field_size_10 field_min_size_350">
                                  <Select
                                    inputProps={{
                                      name: 'vehicle_group_type',
                                    }}
                                    displayEmpty
                                    renderValue={
                                      this.state.vehicle_group_type ?
                                        undefined :
                                        () => (
                                            <div className="font-12 color-disabled">
                                              {t('placeholder.required_select', {
                                                field: t('simulationManagement.vehicleGroupType'),
                                              })}
                                            </div>
                                          )
                                    }
                                    value={this.state.vehicle_group_type}
                                    onChange={(event) => this.onChangeVehicleGroupType(event)}
                                  >
                                    {this.renderVehicleGroupType(this.state.transportation_type)?.map(({id, i18n}, index) => (
                                      <MenuItem value={id} key={index}>
                                        {t(`${i18n}`)}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  {this.validator.message('vehicle_group_type', this.state.vehicle_group_type, 'required')}
                                  {this.state.isSubmitForm && !this.validator.check(this.state.vehicle_group_type, 'required') && (
                                    <FormHelperText id="vehicle_group_type" error>
                                      {t('validation.required', {field: t('service.vehicle_group_type')})}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </TableCell>
                            </TableRow>

                            {/* name */}
                            <TableRow>
                              <TableCell className="width_50">
                                {t('common.name')} <span className="font_color_red">＊</span>
                              </TableCell>
                              <TableCell>
                                <FormControl variant="outlined" className="field_size_10 field_min_size_350">
                                  <TextField
                                    name="name"
                                    className="width_100"
                                    variant="outlined"
                                    placeholder={t('placeholder.required', {field: t('common.name')})}
                                    onChange={(event) => onChangeTextField(this, event)}
                                    value={this.state.name}
                                  />
                                  {this.validator.message('name', this.state.name, 'required')}
                                  {this.state.isSubmitForm && !this.validator.check(this.state.name, 'required') && (
                                    <FormHelperText id="name" error>
                                      {t('validation.required', {field: t('common.name')})}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </TableCell>
                            </TableRow>

                            {/* start time - end time */}
                            <TableRow>
                              <TableCell className="width_50">
                                {t('simulationManagement.time')} <span className="font_color_red">＊</span>
                              </TableCell>
                              <TableCell>
                                <Grid container spacing={1} className="row_form_item">
                                  <Grid container item xs={12}>
                                    <form className={classes.container} noValidate>
                                      <TextField
                                        name="start_time"
                                        label={t('simulationManagement.start_time')}
                                        type="datetime-local"
                                        inputProps={{
                                          max: this.state.end_time,
                                        }}
                                        className={classes.textField}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        onChange={(event) => onChangeTextField(this, event)}
                                        value={this.state.start_time}
                                      />
                                    </form>
                                    <Grid container justify="center" alignItems="center" item xs={1}>
                                      ～
                                    </Grid>
                                    <form className={classes.container} noValidate>
                                      <TextField
                                        name="end_time"
                                        label={t('simulationManagement.end_time')}
                                        type="datetime-local"
                                        inputProps={{
                                          min: this.state.start_time,
                                        }}
                                        className={classes.textField}
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        onChange={(event) => onChangeTextField(this, event)}
                                        value={this.state.end_time}
                                      />
                                    </form>
                                    {this.validator.message('start_time', this.state.start_time, 'required')}
                                    {this.state.isSubmitForm && this.state.start_time && this.state.start_time >= this.state.end_time && (
                                      <FormHelperText id="end_time" error>
                                        {t('validation.time.more')}
                                      </FormHelperText>
                                    )}
                                    <Grid container spacing={1}>
                                      <Grid container alignItems="center" item xs={6}>
                                        {this.state.isSubmitForm && !this.validator.check(this.state.start_time, 'required') && (
                                          <p className="error" style={{fontSize: '0.75rem'}}>
                                            {t('validation.required', {field: t('simulationManagement.start_time')})}
                                          </p>
                                        )}
                                      </Grid>
                                      <Grid container alignItems="center" item xs={6}>
                                        {this.state.isSubmitForm && !this.validator.check(this.state.end_time, 'required') && (
                                          <p className="error" style={{fontSize: '0.75rem'}}>
                                            {t('validation.required', {field: t('simulationManagement.end_time')})}
                                          </p>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </TableCell>
                            </TableRow>

                            {/* service id */}
                            {this.props.match.params.id && (
                              <TableRow>
                                <TableCell className="width_50">{t('simulationManagement.service_id')}</TableCell>
                                <TableCell>{this.state.service_id}</TableCell>
                              </TableRow>
                            )}

                            {/* vehicle capacity */}
                            {this.state.transportation_type && this.state.transportation_type === TRANSPORTATION_TYPES[0].id && (
                              <TableRow>
                                <TableCell className="width_50">
                                  {t('simulationManagement.vehicle_capacity')} <span className="font_color_red">＊</span>
                                </TableCell>
                                <TableCell>
                                  <FormControl variant="outlined" className="field_size_10 field_min_size_350">
                                    <TextField
                                      name="vehicle_capacity"
                                      className="width_100"
                                      variant="outlined"
                                      type="number"
                                      placeholder={t('placeholder.required', {field: t('simulationManagement.vehicle_capacity')})}
                                      onKeyDown={preventNumberInvalidChars}
                                      onChange={(event) => onChangeTextFieldPositiveNumber(this, event)}
                                      value={this.state.vehicle_capacity || this.state.vehicle_capacity === 0 ? this.state.vehicle_capacity : ''}
                                    />
                                    {this.validator.message('vehicle_capacity', this.state.vehicle_capacity, 'required|min:0,num')}
                                    {this.state.isSubmitForm && !this.validator.check(this.state.vehicle_capacity, 'required') && (
                                      <FormHelperText id="max_walking_distance" error>
                                        {t('validation.required', {field: t('simulationManagement.vehicle_capacity')})}
                                      </FormHelperText>
                                    )}
                                    {this.state.isSubmitForm && !this.validator.check(this.state.vehicle_capacity, 'min:0,num') && (
                                      <FormHelperText id="vehicle_capacity" error>
                                        {t('validation.invalid.field', {field: t('simulationManagement.vehicle_capacity')})}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </TableCell>
                              </TableRow>
                            )}

                            {/* max additional journey time */}
                            {this.state.transportation_type && this.state.transportation_type === TRANSPORTATION_TYPES[0].id && (
                              <TableRow>
                                <TableCell className="width_50">
                                  {t('simulationManagement.max_additional_journey_time')}
                                  <span className="font_color_red">＊</span>
                                </TableCell>
                                <TableCell>
                                  <FormControl variant="outlined" className="field_size_10 field_min_size_350">
                                    <TextField
                                      name="max_additional_journey_time"
                                      className="width_100"
                                      variant="outlined"
                                      type="number"
                                      placeholder={t('placeholder.required', {field: t('simulationManagement.max_additional_journey_time')})}
                                      onKeyDown={preventNumberInvalidChars}
                                      onChange={(event) => onChangeTextFieldPositiveNumber(this, event)}
                                      value={this.state.max_additional_journey_time || this.state.max_additional_journey_time === 0 ? this.state.max_additional_journey_time : ''}
                                    />
                                    {this.validator.message('max_additional_journey_time', this.state.max_additional_journey_time, 'required|min:0,num')}
                                    {this.state.isSubmitForm && !this.validator.check(this.state.max_additional_journey_time, 'required') && (
                                      <FormHelperText id="max_additional_journey_time" error>
                                        {t('validation.required', {field: t('simulationManagement.max_additional_journey_time')})}
                                      </FormHelperText>
                                    )}
                                    {this.state.isSubmitForm && !this.validator.check(this.state.max_additional_journey_time, 'min:0,num') && (
                                      <FormHelperText id="max_additional_journey_time" error>
                                        {t('validation.invalid.field', {field: t('simulationManagement.max_additional_journey_time')})}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </TableCell>
                              </TableRow>
                            )}

                            {/* max additional journey time percent */}
                            {this.state.transportation_type && this.state.transportation_type === TRANSPORTATION_TYPES[0].id && (
                              <TableRow>
                                <TableCell className="width_50">
                                  {t('simulationManagement.max_additional_journey_time_percent')}
                                  <span className="font_color_red">＊</span>
                                </TableCell>
                                <TableCell>
                                  <FormControl variant="outlined" className="field_size_10 field_min_size_350">
                                    <TextField
                                      name="max_additional_journey_time_percent"
                                      className="width_100"
                                      variant="outlined"
                                      type="number"
                                      placeholder={t('placeholder.required', {field: t('simulationManagement.max_additional_journey_time_percent')})}
                                      onKeyDown={preventNumberInvalidChars}
                                      onChange={(event) => onChangeTextFieldPositiveNumber(this, event)}
                                      value={
                                        this.state.max_additional_journey_time_percent || this.state.max_additional_journey_time_percent === 0 ?
                                          this.state.max_additional_journey_time_percent :
                                          ''
                                      }
                                    />
                                    {this.validator.message('max_additional_journey_time_percent', this.state.max_additional_journey_time_percent, 'required|min:0,num')}
                                    {this.state.isSubmitForm && !this.validator.check(this.state.max_additional_journey_time_percent, 'required') && (
                                      <FormHelperText id="max_additional_journey_time_percent" error>
                                        {t('validation.required', {field: t('simulationManagement.max_additional_journey_time_percent')})}
                                      </FormHelperText>
                                    )}
                                    {this.state.isSubmitForm && !this.validator.check(this.state.max_additional_journey_time_percent, 'min:0,num') && (
                                      <FormHelperText id="max_additional_journey_time_percent" error>
                                        {t('validation.invalid.field', {field: t('simulationManagement.max_additional_journey_time_percent')})}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </TableCell>
                              </TableRow>
                            )}

                            {/* max walking distance */}
                            {this.state.transportation_type && this.state.transportation_type === TRANSPORTATION_TYPES[0].id && (
                              <TableRow>
                                <TableCell className="width_50">
                                  {t('simulationManagement.max_walking_distance')} <span className="font_color_red">＊</span>
                                </TableCell>
                                <TableCell>
                                  <FormControl variant="outlined" className="field_size_10 field_min_size_350">
                                    <TextField
                                      name="max_walking_distance"
                                      className="width_100"
                                      variant="outlined"
                                      type="number"
                                      placeholder={t('placeholder.required', {field: t('simulationManagement.max_walking_distance')})}
                                      onKeyDown={preventNumberInvalidChars}
                                      onChange={(event) => onChangeTextFieldPositiveNumber(this, event)}
                                      value={this.state.max_walking_distance || this.state.max_walking_distance === 0 ? this.state.max_walking_distance : ''}
                                    />
                                    {this.validator.message('max_walking_distance', this.state.max_walking_distance, 'required|min:0,num')}
                                    {this.state.isSubmitForm && !this.validator.check(this.state.max_walking_distance, 'required') && (
                                      <FormHelperText id="max_walking_distance" error>
                                        {t('validation.required', {field: t('simulationManagement.max_walking_distance')})}
                                      </FormHelperText>
                                    )}
                                    {this.state.isSubmitForm && !this.validator.check(this.state.max_walking_distance, 'min:0,num') && (
                                      <FormHelperText id="max_walking_distance" error>
                                        {t('validation.invalid.field', {field: t('simulationManagement.max_walking_distance')})}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </TableCell>
                              </TableRow>
                            )}

                            {/* data */}
                            <TableRow>
                              <TableCell className="width_50" colSpan={2} style={{padding: '15px 18px', fontWeight: 600}}>
                                Data:
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="width_50" colSpan={2} style={{padding: '15px 28px', fontWeight: 500}}>
                                Calculation parameters:
                              </TableCell>
                            </TableRow>

                            {this.state.transportation_type && this.state.transportation_type === TRANSPORTATION_TYPES[0].id && (
                              <>
                                {/* pickup service time */}
                                <TableRow>
                                  <TableCell className="width_50" style={{paddingLeft: 38}}>
                                    {t('simulationManagement.pickup_service_time')} <span className="font_color_red">＊</span>
                                  </TableCell>
                                  <TableCell>
                                    <FormControl variant="outlined" className="field_size_10 field_min_size_350">
                                      <TextField
                                        name="pickup_service_time"
                                        className="width_100"
                                        variant="outlined"
                                        type="number"
                                        placeholder={t('placeholder.required', {field: t('simulationManagement.pickup_service_time')})}
                                        onKeyDown={preventNumberInvalidChars}
                                        onChange={(event) => onChangeTextFieldPositiveNumber(this, event)}
                                        value={this.state.pickup_service_time || this.state.pickup_service_time === 0 ? this.state.pickup_service_time : ''}
                                      />
                                      {this.validator.message('pickup_service_time', this.state.pickup_service_time, 'required|min:0,num')}
                                      {this.state.isSubmitForm && !this.validator.check(this.state.pickup_service_time, 'required') && (
                                        <FormHelperText id="pickup_service_time" error>
                                          {t('validation.required', {field: t('simulationManagement.pickup_service_time')})}
                                        </FormHelperText>
                                      )}
                                      {this.state.isSubmitForm && !this.validator.check(this.state.pickup_service_time, 'min:0,num') && (
                                        <FormHelperText id="pickup_service_time" error>
                                          {t('validation.invalid.field', {field: t('simulationManagement.pickup_service_time')})}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </TableCell>
                                </TableRow>

                                {/* dropoff service time */}
                                <TableRow>
                                  <TableCell className="width_50" style={{paddingLeft: 38}}>
                                    {t('simulationManagement.dropoff_service_time')} <span className="font_color_red">＊</span>
                                  </TableCell>
                                  <TableCell>
                                    <FormControl variant="outlined" className="field_size_10 field_min_size_350">
                                      <TextField
                                        name="dropoff_service_time"
                                        className="width_100"
                                        variant="outlined"
                                        type="number"
                                        placeholder={t('placeholder.required', {field: t('simulationManagement.dropoff_service_time')})}
                                        onKeyDown={preventNumberInvalidChars}
                                        onChange={(event) => onChangeTextFieldPositiveNumber(this, event)}
                                        value={this.state.dropoff_service_time || this.state.dropoff_service_time === 0 ? this.state.dropoff_service_time : ''}
                                      />
                                      {this.validator.message('dropoff_service_time', this.state.dropoff_service_time, 'required|min:0,num')}
                                      {this.state.isSubmitForm && !this.validator.check(this.state.dropoff_service_time, 'required') && (
                                        <FormHelperText id="dropoff_service_time" error>
                                          {t('validation.required', {field: t('simulationManagement.dropoff_service_time')})}
                                        </FormHelperText>
                                      )}
                                      {this.state.isSubmitForm && !this.validator.check(this.state.dropoff_service_time, 'min:0,num') && (
                                        <FormHelperText id="dropoff_service_time" error>
                                          {t('validation.invalid.field', {field: t('simulationManagement.dropoff_service_time')})}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </TableCell>
                                </TableRow>

                                {/* max pool size */}
                                <TableRow>
                                  <TableCell className="width_50" style={{paddingLeft: 38}}>
                                    {t('simulationManagement.max_pool_size')}
                                  </TableCell>
                                  <TableCell>
                                    <FormControl variant="outlined" className="field_size_10 field_min_size_350">
                                      <TextField
                                        name="max_pool_size"
                                        className="width_100"
                                        variant="outlined"
                                        placeholder={t('placeholder.required', {field: t('simulationManagement.max_pool_size')})}
                                        onChange={(event) => onChangeTextField(this, event)}
                                        value={this.state.max_pool_size || this.state.max_pool_size === 0 ? this.state.max_pool_size : ''}
                                      />
                                    </FormControl>
                                  </TableCell>
                                </TableRow>

                                {/* trip cost */}
                                <TableRow>
                                  <TableCell className="width_50" style={{paddingLeft: 38}}>
                                    {t('simulationManagement.trip_cost')}
                                  </TableCell>
                                  <TableCell>
                                    <FormControl variant="outlined" className="field_size_10 field_min_size_350">
                                      <TextField
                                        name="trip_cost"
                                        className="width_100"
                                        variant="outlined"
                                        placeholder={t('placeholder.required', {field: t('simulationManagement.trip_cost')})}
                                        onChange={(event) => onChangeTextField(this, event)}
                                        value={this.state.trip_cost || this.state.trip_cost === 0 ? this.state.trip_cost : ''}
                                      />
                                    </FormControl>
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell style={{paddingLeft: 38}}>
                                    {t('simulationManagement.time_factor')}
                                  </TableCell>
                                  <TableCell>
                                    <FormControl className='field_size_10 field_min_size_350'>
                                      <TextField
                                        variant='outlined'
                                        name='time_factor'
                                        placeholder={t('placeholder.required', {field: t('simulationManagement.time_factor')})}
                                        value={this.state.time_factor}
                                        onChange={(e) => onChangeTextField(this, e)}
                                      />
                                      {this.validator.check(this.state.time_factor, 'required') &&
                                        this.validator.message('time_factor', this.state.time_factor, 'numeric|min:0,num')}
                                      {this.state.isSubmitForm && this.validator.check(this.state.time_factor, 'required') &&
                                        !this.validator.check(this.state.time_factor, 'numeric|min:0,num') && (
                                        <FormHelperText id='time_factor' error>
                                          {t('validation.invalid.field', {field: t('simulationManagement.time_factor')})}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </TableCell>
                                </TableRow>

                                {/* use_path_equalizer */}
                                <TableRow>
                                  <TableCell className="width_50" style={{paddingLeft: 38}}>{t('simulationManagement.use_path_equalizer')}</TableCell>
                                  <TableCell>
                                    <Checkbox
                                      name='use_path_equalizer'
                                      checked={this.state.use_path_equalizer}
                                      onChange={(event) => onChangeCheckBox(this, event)}
                                    />
                                  </TableCell>
                                </TableRow>

                                {/* waiting time cost factor */}
                                <TableRow>
                                  <TableCell className="width_50" style={{paddingLeft: 38}}>
                                    {t('simulationManagement.waiting_time_cost_factor')} <span className="font_color_red">＊</span>
                                  </TableCell>
                                  <TableCell>
                                    <FormControl variant="outlined" className="field_size_10 field_min_size_350">
                                      <TextField
                                        name="waiting_time_cost_factor"
                                        className="width_100"
                                        variant="outlined"
                                        placeholder={t('placeholder.required', {field: t('simulationManagement.waiting_time_cost_factor')})}
                                        onKeyDown={preventNumberInvalidChars}
                                        onChange={(event) => onChangeTextFieldPositiveNumber(this, event)}
                                        value={this.state.waiting_time_cost_factor || this.state.waiting_time_cost_factor === 0 ? this.state.waiting_time_cost_factor : ''}
                                      />
                                      {this.validator.message('waiting_time_cost_factor', this.state.waiting_time_cost_factor, 'required')}
                                      {this.state.isSubmitForm && !this.validator.check(this.state.waiting_time_cost_factor, 'required') && (
                                        <FormHelperText id="waiting_time_cost_factor" error>
                                          {t('validation.required', {field: t('simulationManagement.waiting_time_cost_factor')})}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </TableCell>
                                </TableRow>

                                {/* shrink time delta */}
                                <TableRow>
                                  <TableCell className="width_50" style={{paddingLeft: 28, fontWeight: 500}}>
                                    {t('simulationManagement.shrink_time_delta')} <span className="font_color_red">＊</span>
                                  </TableCell>
                                  <TableCell>
                                    <FormControl variant="outlined" className="field_size_10 field_min_size_350">
                                      <TextField
                                        name="shrink_time_delta"
                                        className="width_100"
                                        variant="outlined"
                                        type="number"
                                        placeholder={t('placeholder.required', {field: t('simulationManagement.shrink_time_delta')})}
                                        onKeyDown={preventNumberInvalidChars}
                                        onChange={(event) => onChangeTextFieldPositiveNumber(this, event)}
                                        value={this.state.shrink_time_delta || this.state.shrink_time_delta === 0 ? this.state.shrink_time_delta : ''}
                                      />
                                      {this.validator.message('shrink_time_delta', this.state.shrink_time_delta, 'required|min:0,num')}
                                      {this.state.isSubmitForm && !this.validator.check(this.state.shrink_time_delta, 'required') && (
                                        <FormHelperText id="shrink_time_delta" error>
                                          {t('validation.required', {field: t('simulationManagement.shrink_time_delta')})}
                                        </FormHelperText>
                                      )}
                                      {this.state.isSubmitForm && !this.validator.check(this.state.shrink_time_delta, 'min:0,num') && (
                                        <FormHelperText id="shrink_time_delta" error>
                                          {t('validation.invalid.field', {field: t('simulationManagement.shrink_time_delta')})}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </TableCell>
                                </TableRow>

                                {/* reverse shrink time delta */}
                                <TableRow>
                                  <TableCell className="width_50" style={{paddingLeft: 28, fontWeight: 500}}>
                                    {t('simulationManagement.reverse_shrink_time_delta')} <span className="font_color_red">＊</span>
                                  </TableCell>
                                  <TableCell>
                                    <FormControl variant="outlined" className="field_size_10 field_min_size_350">
                                      <TextField
                                        name="reverse_shrink_time_delta"
                                        className="width_100"
                                        variant="outlined"
                                        type="number"
                                        placeholder={t('placeholder.required', {field: t('simulationManagement.reverse_shrink_time_delta')})}
                                        onKeyDown={preventNumberInvalidChars}
                                        onChange={(event) => onChangeTextFieldPositiveNumber(this, event)}
                                        value={this.state.reverse_shrink_time_delta || this.state.reverse_shrink_time_delta === 0 ? this.state.reverse_shrink_time_delta : ''}
                                      />
                                      {this.validator.message('reverse_shrink_time_delta', this.state.reverse_shrink_time_delta, 'required|min:0,num')}
                                      {this.state.isSubmitForm && !this.validator.check(this.state.reverse_shrink_time_delta, 'required') && (
                                        <FormHelperText id="reverse_shrink_time_delta" error>
                                          {t('validation.required', {field: t('simulationManagement.reverse_shrink_time_delta')})}
                                        </FormHelperText>
                                      )}
                                      {this.state.isSubmitForm && !this.validator.check(this.state.reverse_shrink_time_delta, 'min:0,num') && (
                                        <FormHelperText id="reverse_shrink_time_delta" error>
                                          {t('validation.invalid.field', {field: t('simulationManagement.reverse_shrink_time_delta')})}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </TableCell>
                                </TableRow>

                                {/* offer auto cancellation timeout */}
                                <TableRow>
                                  <TableCell className="width_50" style={{paddingLeft: 28, fontWeight: 500}}>
                                    {t('simulationManagement.offer_auto_cancellation_timeout')} <span className="font_color_red">＊</span>
                                  </TableCell>
                                  <TableCell>
                                    <FormControl variant="outlined" className="field_size_10 field_min_size_350">
                                      <TextField
                                        name="offer_auto_cancellation_timeout"
                                        className="width_100"
                                        variant="outlined"
                                        type="number"
                                        placeholder={t('placeholder.required', {field: t('simulationManagement.offer_auto_cancellation_timeout')})}
                                        onKeyDown={preventNumberInvalidChars}
                                        onChange={(event) => onChangeTextFieldPositiveNumber(this, event)}
                                        value={this.state.offer_auto_cancellation_timeout || ''}
                                      />
                                      {this.validator.message('offer_auto_cancellation_timeout', this.state.offer_auto_cancellation_timeout, 'required|min:0,num')}
                                      {this.state.isSubmitForm && !this.validator.check(this.state.offer_auto_cancellation_timeout, 'required') && (
                                        <FormHelperText id="offer_auto_cancellation_timeout" error>
                                          {t('validation.required', {field: t('simulationManagement.offer_auto_cancellation_timeout')})}
                                        </FormHelperText>
                                      )}
                                      {this.state.isSubmitForm && !this.validator.check(this.state.offer_auto_cancellation_timeout, 'min:0,num') && (
                                        <FormHelperText id="offer_auto_cancellation_timeout" error>
                                          {t('validation.invalid.field', {field: t('simulationManagement.offer_auto_cancellation_timeout')})}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </TableCell>
                                </TableRow>
                              </>
                            )}

                            {/* nearest distance */}
                            {this.state.transportation_type && this.state.transportation_type === TRANSPORTATION_TYPES[2].id && (
                              <TableRow>
                                <TableCell className="width_50">
                                  {t('simulationManagement.nearest_distance')} <span className="font_color_red">＊</span>
                                </TableCell>
                                <TableCell>
                                  <FormControl variant="outlined" className="field_size_10 field_min_size_350">
                                    <TextField
                                      name="nearest_distance"
                                      className="width_100"
                                      variant="outlined"
                                      placeholder={t('placeholder.required', {field: t('simulationManagement.nearest_distance')})}
                                      onChange={(event) => onChangeTextField(this, event)}
                                      value={this.state.nearest_distance || this.state.nearest_distance === 0 ? this.state.nearest_distance : ''}
                                    />
                                    {this.validator.message('nearest_distance', this.state.nearest_distance, 'required|min:0,num')}
                                    {this.state.isSubmitForm && !this.validator.check(this.state.nearest_distance, 'required') && (
                                      <FormHelperText id="nearest_distance" error>
                                        {t('validation.required', {field: t('simulationManagement.nearest_distance')})}
                                      </FormHelperText>
                                    )}
                                    {this.state.isSubmitForm && !this.validator.check(this.state.nearest_distance, 'min:0,num') && (
                                      <FormHelperText id="nearest_distance" error>
                                        {t('validation.invalid.field', {field: t('simulationManagement.nearest_distance')})}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </TableCell>
                              </TableRow>
                            )}

                            {/* geofence id */}
                            {this.state.transportation_type && (
                              <TableRow>
                                <TableCell className="width_50">
                                  {t('common.geofence')} <span className="font_color_red">＊</span>
                                </TableCell>
                                <TableCell>
                                  <TextField
                                    className="field_size_10 field_min_size_350"
                                    variant="outlined"
                                    margin='dense'
                                    placeholder={t('placeholder.required', {field: t('common.geofence')})}
                                    value={this.state.selectedGeofence?.name || ''}
                                    onClick={() => this.handleOpenModal('isSearchGeofenceModalOpened')}
                                  />
                                  {this.validator.message('geofence_id', this.state.geofence_id, 'required')}
                                  {this.state.isSubmitForm && !this.validator.check(this.state.geofence_id, 'required') && (
                                    <FormHelperText id="geofence_id" error>
                                      {t('validation.required', {field: t('simulationManagement.geofence_id')})}
                                    </FormHelperText>
                                  )}
                                </TableCell>
                              </TableRow>
                            )}

                            {/* stop set id */}
                            {this.state.transportation_type && this.state.transportation_type === TRANSPORTATION_TYPES[0].id && (
                              <TableRow>
                                <TableCell className="width_50">
                                  {t('simulationManagement.stop_set_id')} <span className="font_color_red">＊</span>
                                </TableCell>
                                <TableCell>
                                  <FormControl variant="outlined" className="field_size_10 field_min_size_350">
                                    <TextField
                                      name="stop_set_id"
                                      className="width_100"
                                      variant="outlined"
                                      placeholder={t('placeholder.required', {field: t('simulationManagement.stop_set_id')})}
                                      value={this.state.stop_set_id || this.state.stop_set_id === 0 ? this.state.stop_set_id : ''}
                                      onClick={() => this.handleOpenModal('isSearchStopSetModalOpened')}
                                    />
                                    {this.validator.message('stop_set_id', this.state.stop_set_id, 'required')}
                                    {this.state.isSubmitForm && !this.validator.check(this.state.stop_set_id, 'required') && (
                                      <FormHelperText id="stop_set_id" error>
                                        {t('validation.required', {field: t('simulationManagement.stop_set_id')})}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </TableCell>
                              </TableRow>
                            )}

                            {/* transit stop set id */}
                            {this.state.transportation_type && this.state.transportation_type === TRANSPORTATION_TYPES[0].id && (
                              <TableRow>
                                <TableCell className="width_50">{t('service.transit_stop_set_id')}</TableCell>
                                <TableCell>
                                  <FormControl variant="outlined" className="field_size_10 field_min_size_350">
                                    <TextField
                                      name="transit_stop_set_id"
                                      className="field_size_15 field_min_size_350"
                                      variant="outlined"
                                      type="number"
                                      placeholder={t('placeholder.required', {field: t('service.transit_stop_set_id')})}
                                      value={this.state.transit_stop_set_id || this.state.transit_stop_set_id === 0 ? this.state.transit_stop_set_id : ''}
                                      onClick={() => this.handleOpenModal('isSearchStopSetModalOpened')}
                                    />
                                    {this.validator.message('transit_stop_set_id', this.state.transit_stop_set_id, 'min:0,num')}
                                    {this.state.isSubmitForm && !this.validator.check(this.state.transit_stop_set_id, 'min:0,num') && (
                                      <FormHelperText id="transit_stop_set_id" error>
                                        {t('validation.invalid.field', {field: t('service.transit_stop_set_id')})}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </TableCell>
                              </TableRow>
                            )}

                            {/* start time/end time limitations */}
                            {this.state.transportation_type && this.state.transportation_type === TRANSPORTATION_TYPES[0].id && (
                              <TableRow>
                                <TableCell className="width_50">{t('simulationManagement.start_time_end_time_limitations')}</TableCell>
                                <TableCell>
                                  <Checkbox
                                    name="start_time_end_time_limitations"
                                    value={this.state.start_time_end_time_limitations}
                                    onChange={(event) => onChangeCheckBox(this, event)}
                                    checked={this.state.start_time_end_time_limitations}
                                  />
                                </TableCell>
                              </TableRow>
                            )}

                            {/* enable waypoints cache */}
                            {this.state.transportation_type && this.state.transportation_type === TRANSPORTATION_TYPES[0].id && (
                              <TableRow>
                                <TableCell className="width_50">{t('simulationManagement.enable_waypoints_cache')}</TableCell>
                                <TableCell>
                                  <Checkbox
                                    name="enable_waypoints_cache"
                                    value={this.state.enable_waypoints_cache}
                                    onChange={(event) => onChangeCheckBox(this, event)}
                                    checked={this.state.enable_waypoints_cache}
                                  />
                                </TableCell>
                              </TableRow>
                            )}

                            {/* enable */}
                            <TableRow>
                              <TableCell className="width_50">{t('common.enable')}</TableCell>
                              <TableCell>
                                <Checkbox name="enable" value={this.state.enable} onChange={(event) => onChangeCheckBox(this, event)} checked={this.state.enable} />
                              </TableCell>
                            </TableRow>

                            {/* enable curb */}
                            {this.state.transportation_type && this.state.transportation_type === TRANSPORTATION_TYPES[0].id && (
                              <TableRow>
                                <TableCell className="width_50">{t('simulationManagement.enable_curb')}</TableCell>
                                <TableCell>
                                  <Checkbox
                                    name="enable_curb"
                                    value={this.state.enable_curb}
                                    onChange={(event) => onChangeCheckBox(this, event)}
                                    checked={this.state.enable_curb}
                                  />
                                </TableCell>
                              </TableRow>
                            )}

                            {/* manual manage transit stop */}
                            {this.state.transportation_type && this.state.transportation_type === TRANSPORTATION_TYPES[2].id && (
                              <TableRow>
                                <TableCell className="width_50">{t('simulationManagement.manual_manage_transit_stop')}</TableCell>
                                <TableCell>
                                  <Checkbox
                                    name="manual_manage_transit_stop"
                                    value={this.state.manual_manage_transit_stop}
                                    onChange={(event) => onChangeCheckBox(this, event)}
                                    checked={this.state.manual_manage_transit_stop}
                                  />
                                </TableCell>
                              </TableRow>
                            )}

                            {/* allow vehicle late */}
                            {this.state.transportation_type && this.state.transportation_type !== TRANSPORTATION_TYPES[2].id && (
                              <TableRow>
                                <TableCell className="width_50">{t('simulationManagement.allow_vehicle_late')}</TableCell>
                                <TableCell>
                                  <Checkbox
                                    name="allow_vehicle_late"
                                    value={this.state.allow_vehicle_late}
                                    onChange={(event) => onChangeCheckBox(this, event)}
                                    checked={this.state.allow_vehicle_late}
                                  />
                                </TableCell>
                              </TableRow>
                            )}

                            {/* door to door mode */}
                            {this.state.transportation_type && this.state.transportation_type === TRANSPORTATION_TYPES[0].id && (
                              <TableRow>
                                <TableCell className="width_50">{t('simulationManagement.door_to_door_mode')}</TableCell>
                                <TableCell>
                                  <Checkbox
                                    name="door_to_door_mode"
                                    value={this.state.door_to_door_mode}
                                    onChange={(event) => onChangeCheckBox(this, event)}
                                    checked={this.state.door_to_door_mode}
                                  />
                                </TableCell>
                              </TableRow>
                            )}

                            {/* limit user booking */}
                            <TableRow>
                              <TableCell className="width_50">
                                {t('simulationManagement.limit_user_booking')} <span className="font_color_red">＊</span>
                              </TableCell>
                              <TableCell>
                                <FormControl variant="outlined" className="field_size_10 field_min_size_350">
                                  <TextField
                                    name="limit_user_booking"
                                    className="width_100"
                                    variant="outlined"
                                    type="number"
                                    placeholder={t('placeholder.required', {field: t('simulationManagement.limit_user_booking')})}
                                    onKeyDown={preventNumberInvalidChars}
                                    onChange={(event) => onChangeTextFieldPositiveNumber(this, event)}
                                    value={this.state.limit_user_booking || this.state.limit_user_booking === 0 ? this.state.limit_user_booking : ''}
                                  />
                                  {this.validator.message('limit_user_booking', this.state.limit_user_booking, 'required|min:0,num')}
                                  {this.state.isSubmitForm && !this.validator.check(this.state.limit_user_booking, 'required') && (
                                    <FormHelperText id="limit_user_booking" error>
                                      {t('validation.required', {field: t('simulationManagement.limit_user_booking')})}
                                    </FormHelperText>
                                  )}
                                  {this.state.isSubmitForm && !this.validator.check(this.state.limit_user_booking, 'min:0,num') && (
                                    <FormHelperText id="limit_user_booking" error>
                                      {t('validation.invalid.field', {field: t('simulationManagement.limit_user_booking')})}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </TableCell>
                            </TableRow>

                            {/* currency code */}
                            <TableRow>
                              <TableCell className="width_50">
                                {t('simulationManagement.currency_code')} <span className="font_color_red">＊</span>
                              </TableCell>
                              <TableCell>
                                <FormControl variant="outlined" className="field_size_10 field_min_size_350">
                                  <Select
                                    inputProps={{
                                      name: 'currency_code',
                                    }}
                                    displayEmpty
                                    renderValue={
                                      this.state.currency_code ?
                                        undefined :
                                        () => (
                                            <div className="font-12 color-disabled">
                                              {t('placeholder.required_select', {
                                                field: t('simulationManagement.currency_code'),
                                              })}
                                            </div>
                                          )
                                    }
                                    value={this.state.currency_code}
                                    onChange={(event) => onChangeSelect(this, event)}
                                  >
                                    {CURRENCY.map((item, idx) => (
                                      <MenuItem value={item.id} key={idx}>
                                        {t(item.i18n)}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  {this.validator.message('currency_code', this.state.currency_code, 'required')}
                                  {this.state.isSubmitForm && !this.validator.check(this.state.currency_code, 'required') && (
                                    <FormHelperText id="currency_code" error>
                                      {t('validation.required', {field: t('simulationManagement.currency_code')})}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </TableCell>
                            </TableRow>

                            {/* payment support */}
                            <TableRow>
                              <TableCell className="width_50">
                                {t('simulationManagement.payment_support')} <span className="font_color_red">＊</span>
                              </TableCell>
                              <TableCell>
                                <FormControl variant="outlined" className="field_size_10 field_min_size_350">
                                  <Select
                                    inputProps={{
                                      name: 'payment_support',
                                    }}
                                    displayEmpty
                                    multiple
                                    renderValue={
                                      this.state.payment_support ?
                                        undefined :
                                        () => (
                                            <div className="font-12 color-disabled">
                                              {t('placeholder.required_select', {
                                                field: t('simulationManagement.payment_support'),
                                              })}
                                            </div>
                                          )
                                    }
                                    value={this.state.payment_support}
                                    onChange={(event) => onChangeSelect(this, event)}
                                  >
                                    {PAYMENT_SUPPORT?.map((item, idx) => (
                                      <MenuItem value={item} key={idx}>
                                        {t(`${item}`)}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                  {this.validator.message('payment_support', this.state.payment_support, 'required')}
                                  {this.state.isSubmitForm && !this.validator.check(this.state.payment_support, 'required') && (
                                    <FormHelperText id="payment_support" error>
                                      {t('validation.required', {field: t('simulationManagement.payment_support')})}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </TableCell>
                            </TableRow>

                            {/* description */}
                            <TableRow>
                              <TableCell className="width_50">
                                {t('simulationManagement.description')}
                                <span className="font_color_red">＊</span>
                              </TableCell>
                              <TableCell>
                                <FormControl variant="outlined" className="field_size_10 field_min_size_350">
                                  <TextField
                                    multiline
                                    name="description"
                                    className="field_size_15 field_min_size_350"
                                    variant="outlined"
                                    placeholder={t('placeholder.required', {field: t('simulationManagement.description')})}
                                    onChange={(event) => onChangeTextField(this, event)}
                                    value={this.state.description}
                                  />
                                  {this.validator.message('description', this.state.description, 'required')}
                                  {this.state.isSubmitForm && !this.validator.check(this.state.description, 'required') && (
                                    <FormHelperText id="description" error>
                                      {t('validation.required', {field: t('simulationManagement.description')})}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </TableCell>
                            </TableRow>

                            {/* acceptable waiting time */}
                            {this.state.transportation_type && this.state.transportation_type === TRANSPORTATION_TYPES[0].id && (
                              <TableRow>
                                <TableCell className="width_50">
                                  {t('simulationManagement.acceptable_waiting_time')} <span className="font_color_red">＊</span>
                                </TableCell>
                                <TableCell>
                                  <FormControl variant="outlined" className="field_size_10 field_min_size_350">
                                    <TextField
                                      name="acceptable_waiting_time"
                                      className="width_100"
                                      variant="outlined"
                                      placeholder={t('placeholder.required', {field: t('simulationManagement.acceptable_waiting_time')})}
                                      onChange={(event) => onChangeTextField(this, event)}
                                      value={this.state.acceptable_waiting_time || this.state.acceptable_waiting_time === 0 ? this.state.acceptable_waiting_time : ''}
                                    />
                                    {this.validator.message('acceptable_waiting_time', this.state.acceptable_waiting_time, 'required|min:0,num')}
                                    {this.state.isSubmitForm && !this.validator.check(this.state.acceptable_waiting_time, 'required') && (
                                      <FormHelperText id="acceptable_waiting_time" error>
                                        {t('validation.required', {field: t('simulationManagement.acceptable_waiting_time')})}
                                      </FormHelperText>
                                    )}
                                    {this.state.isSubmitForm && !this.validator.check(this.state.acceptable_waiting_time, 'min:0,num') && (
                                      <FormHelperText id="acceptable_waiting_time" error>
                                        {t('validation.invalid.field', {field: t('simulationManagement.acceptable_waiting_time')})}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </TableCell>
                              </TableRow>
                            )}

                            {/* performance tracker enabled */}
                            {this.state.transportation_type && this.state.transportation_type === TRANSPORTATION_TYPES[0].id && (
                              <TableRow>
                                <TableCell className="width_50">{t('simulationManagement.performance_tracker_enabled')}</TableCell>
                                <TableCell>
                                  <Checkbox
                                    name="performance_tracker_enabled"
                                    value={this.state.performance_tracker_enabled}
                                    onChange={(event) => onChangeCheckBox(this, event)}
                                    checked={this.state.performance_tracker_enabled}
                                  />
                                </TableCell>
                              </TableRow>
                            )}

                            {/* Send Vehicle Cancellation Updates */}
                            <TableRow>
                              <TableCell className="width_50">Send Vehicle Cancellation Updates</TableCell>
                              <TableCell>
                                <Checkbox
                                  name="send_vehicle_cancellation_updates"
                                  checked={this.state.send_vehicle_cancellation_updates}
                                  onChange={(event) => onChangeCheckBox(this, event)}
                                />
                              </TableCell>
                            </TableRow>

                            {/* Tracking Driver */}
                            <TableRow>
                              <TableCell className="width_50">Tracking Driver</TableCell>
                              <TableCell>
                                <Checkbox
                                  name='tracking_driver'
                                  checked={this.state.tracking_driver}
                                  onChange={(event) => onChangeCheckBox(this, event)}
                                />
                              </TableCell>
                            </TableRow>

                            {/* Show search walking result */}
                            <TableRow>
                              <TableCell className="width_50">{t('simulationManagement.show_walking_result')}</TableCell>
                              <TableCell>
                                <Checkbox
                                  name='show_search_walking_result'
                                  checked={this.state.show_search_walking_result}
                                  onChange={(event) => onChangeCheckBox(this, event)}
                                />
                              </TableCell>
                            </TableRow>

                            {/* show_waypoint_in_map */}
                            <TableRow>
                              <TableCell className="width_50">{t('simulationManagement.show_waypoint_in_map')}</TableCell>
                              <TableCell>
                                <Checkbox
                                  name='show_waypoint_in_map'
                                  checked={this.state.show_waypoint_in_map}
                                  onChange={(event) => onChangeCheckBox(this, event)}
                                />
                              </TableCell>
                            </TableRow>

                            {/* Vehicle mode */}
                            <TableRow>
                              <TableCell className="width_50">
                                {t('simulationManagement.vehicle_mode')}
                                {this.state.transportation_type === TRANSPORTATION_TYPES[0].id && <span className="font_color_red">＊</span>}
                              </TableCell>
                              <TableCell>
                                <FormControl variant="outlined" className="field_size_10 field_min_size_350">
                                  <Select
                                    inputProps={{
                                      name: 'vehicle_mode',
                                    }}
                                    displayEmpty
                                    renderValue={
                                      this.state.vehicle_mode ?
                                        undefined :
                                        () => (
                                            <div className="font-12 color-disabled">
                                              {t('placeholder.required_select', {
                                                field: t('simulationManagement.vehicle_mode'),
                                              })}
                                            </div>
                                          )
                                    }
                                    value={this.state.vehicle_mode}
                                    onChange={(event) => onChangeSelect(this, event)}
                                  >
                                    {this.props.vehicle_modes?.map((vehicle_mode, idx) => (
                                      <MenuItem value={vehicle_mode.mode} key={idx}>{vehicle_mode.mode}</MenuItem>
                                    ))}
                                  </Select>
                                  {
                                    this.state.transportation_type === TRANSPORTATION_TYPES[0].id && (
                                      this.validator.message('vehicle_mode', this.state.vehicle_mode, 'required')
                                    )
                                  }
                                  {this.state.isSubmitForm &&
                                    this.state.transportation_type === TRANSPORTATION_TYPES[0].id &&
                                    !this.validator.check(this.state.vehicle_mode, 'required') && (
                                      <FormHelperText id="vehicle_mode" error>
                                        {t('validation.required', {field: t('simulationManagement.vehicle_mode')})}
                                      </FormHelperText>
                                    )}
                                </FormControl>
                              </TableCell>
                            </TableRow>

                            {/* Driving side */}
                            <TableRow>
                              <TableCell className="width_50">Driving Side</TableCell>
                              <TableCell>
                                <FormControl variant="outlined" className="field_size_10 field_min_size_350">
                                  <Select
                                    inputProps={{
                                      name: 'driving_side',
                                    }}
                                    value={this.state.driving_side}
                                    onChange={(event) => onChangeSelect(this, event)}
                                  >
                                    <MenuItem value={'LEFT'}>LEFT</MenuItem>
                                    <MenuItem value={'RIGHT'}>RIGHT</MenuItem>
                                  </Select>
                                </FormControl>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>
                      <Grid container item xs={5} spacing={2} alignItems="flex-start">
                        <Grid container item alignItems="flex-start" justify="center">
                          <Button color="primary" variant="contained" onClick={this.handleCheckSimulationSwat}>
                            {t('simulationManagement.check_simulation_swat')}
                          </Button>
                        </Grid>
                        <Grid container item justify="center">
                          <Box pl={2}>
                            <LoadingOverlay active={this.state.isSimulationSwatLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                              {this.state.simulationSwat && (
                                <div style={{border: '1px solid black', padding: 5}}>
                                  <pre style={{whiteSpace: 'pre-wrap'}}>
                                    <p>{JSON.stringify(this.state.simulationSwat, null, 2)}</p>
                                  </pre>
                                </div>
                              )}
                            </LoadingOverlay>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              </Card>
            </Box>
          </Container>
          <Container maxWidth="xl">
            <Box p={2}>
              <Grid container spacing={2}>
                <Grid container alignItems="center" justify="flex-end" item xs={12}>
                  <Box p={2}>
                    {this.props.match.params.id ? (
                      <>
                        <Button
                          color='primary'
                          variant='contained'
                          className='button_margin button_color_red'
                          endIcon={<PlaylistAddCheckIcon />}
                          onClick={() => this.props.applySimulation(this.state.id)}
                        >
                          {t('common.btnApply')}
                        </Button>
                        <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={() => this.handleClone()}>
                          {t('common.btnClone')}
                        </Button>
                        <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={() => this.handleUpdate()}>
                          {t('common.btnUpdate')}
                        </Button>
                      </>
                    ) : (
                      <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={() => this.handleUpdate()}>
                        {t('common.btnRegister')}
                      </Button>
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      className="button_margin button_color"
                      onClick={() => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.SIMULATION_MANAGEMENT)}
                    >
                      {t('common.btnReturn')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.isSearchGeofenceModalOpened}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1000',
            }}
          >
            <SearchGeofenceModal
              parentData={this.state.selectedGeofence}
              onClickOk={() => this.handleCloseModal('isSearchGeofenceModalOpened')}
              onClickClose={() => this.handleCloseModal('isSearchGeofenceModalOpened')}
              onSelect={(geofence) => this.setState({geofence_id: geofence.geofence_id, selectedGeofence: geofence})}
            />
          </Modal>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={this.state.isSearchStopSetModalOpened}
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1000',
            }}
          >
            <SearchStopSetModal
              parentData={this.state.selectedStopSet}
              onClickOk={() => this.handleCloseModal('isSearchStopSetModalOpened')}
              onClickClose={() => this.handleCloseModal('isSearchStopSetModalOpened')}
              selected={(value) => this.setState({stop_set_id: value.id, transit_stop_set_id: value.swat_transit_stop_set_id, selectedStopSet: value})}
            />
          </Modal>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    simulation: state.simulation,
    common: state.common,
    vehicle_modes: state.businessVehicle.listVehicleMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSimulationDetail: (params, props) => dispatch(getSimulationDetail(params, props)),
    enableSimulation: (payload) => dispatch(enableSimulation(payload)),
    applySimulation: (id) => dispatch(applySimulation(id)),
    getListGeofence: (payload) => dispatch(getListGeofence(payload)),
    showMessage: (msgCode) => dispatch(setMessageModal(modalObj(true, msgCode))),
    getListVehicleMode: () => dispatch(getListVehicleMode()),
  };
};

export default withPermissionData(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(SimulationDetailComponent)));
