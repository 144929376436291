import React, {Component} from 'react';

import {Container, Fade, Paper, Grid, Button, Dialog, FormControl, Select, MenuItem, FormHelperText, Box, TextField} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import Maps from '../../../components/map/index';
import SelectModal from '../../../components/selectModal';
import {getListGeofence} from '../../../stores/common/actions';
import {getWaypointStops} from '../../../stores/jit_setting/action';
import {getDetailFavoriteWaypoint, getListFavoriteWaypoint, uploadFavoriteWaypoint} from '../../../stores/member/action';
import {onChangeTextField} from '../../../utils/common';

/**
 * Member Update view
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      user_id: '',
      operator_id: localStorage.getItem('ADMIN_ID'),
      geofence_id: '',
      position: null,
      location_tag: '',

      openConfirmModal: false,
      isSubmitForm: false,
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    if (this.props.favoriteId !== -1) {
      await this.props.getListGeofence({targetScreen: 'ENABLE_FOR_ADMIN_ALL'});
      await this.props.getDetailFavoriteWaypoint(this.props.favoriteId);
      const {waypointDetail} = this.props;
      const position = {
        latLng: {lat: waypointDetail?.location_lat, lng: waypointDetail?.location_lon},
        place_name: waypointDetail?.location_name,
        id: undefined,
        transit_stop_swat_id: waypointDetail?.transit_stop_swat_id,
        geofence_id: waypointDetail?.geofence_id,
      };
      const geofence = this.props.common?.geofence_list?.find((area) => area.geofence_id === waypointDetail?.geofence_id);
      this.setState({
        id: waypointDetail.id,
        user_id: waypointDetail?.user_id,
        geofence_id: waypointDetail?.geofence_id,
        operator_id: waypointDetail?.operator_id,
        position: position,
        location_tag: waypointDetail?.location_tag,
      });

      const payload = {
        serviceGroupIds: geofence?.service_group_id,
        simulationIds: geofence?.on_demand_sim_id,
        groupTypes: ['SHUTTLE_BUS_ON_DEMAND'],
        size: 300,
      };
      await this.props.getWaypointStops(payload);
    } else {
      await this.props.getListGeofence();
      this.setState({
        id: this.props.favoriteId,
        user_id: this.props.userId,
      });
    }
  }

  /**
   * getGeometry
   * @param {*} geofence_id
   * @return {*}
   */
  getGeometry = (geofence_id) => {
    const data = this.props.common?.geofence_list?.filter((area) => {
      return area.geofence_id === geofence_id;
    });

    return data;
  };

  /**
   * getPosition
   * @param {*} position
   */
  getPosition(position) {
    this.setState({
      position: position,
    });
  }

  /**
   * handelUpdateButton
   */
  handelUpdateButton = () => {
    this.setState({isSubmitForm: true});
    if (this.validator.allValid()) this.setState({openConfirmModal: true});
  };

  /**
   * handleButtonOk
   */
  handleModalButtonOk = async () => {
    await this.handleModalButtonCancel();
    const payload = {
      id: this.props.favoriteId === -1 ? null : Number(this.state.id),
      user_id: Number(this.state.user_id),
      transit_stop_swat_id: this.state.position.transit_stop_swat_id,
      geofence_id: this.state.geofence_id,
      operator_id: localStorage.getItem('ADMIN_ID'),
      location_name: this.state.position.place_name,
      location_tag: this.state.location_tag,
      location_lat: this.state.position.latLng?.lat(),
      location_lon: this.state.position.latLng?.lng(),
    };
    await this.props.uploadFavoriteWaypoint(payload);
    await this.props.onClickClose();
    await this.props.getListFavoriteWaypoint(this.state.user_id, '', '', '', 0, 10);
  };

  /**
   * handleButtonCancel
   */
  handleModalButtonCancel = () => {
    this.setState({
      openConfirmModal: false,
    });
  };

  /**
   * handleClickClose
   */
  handleClickClose = () => {
    this.props.onClickClose();
  };

  /**
   * handleSelectArea
   * @param {*} geofence_id
   */
  handleSelectArea = async (geofence_id) => {
    const geofence = this.props.common?.geofence_list?.find((area) => area.geofence_id === geofence_id);
    const payload = {
      serviceGroupIds: geofence?.service_group_id,
      simulationIds: geofence?.on_demand_sim_id,
      groupTypes: ['SHUTTLE_BUS_ON_DEMAND'],
      size: 300,
    };
    this.setState({geofence_id, position: null, location_tag: ''});
    await this.props.getWaypointStops(payload);
  };

  /**
   * Render component
   * @return {object}
   */
  render() {
    const {t, isLoading, common, waypointDetail} = this.props;

    return (
      <Fade in={true}>
        <Paper className="modal_size_mid">
          <LoadingOverlay active={isLoading || common.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <br></br>
            <Container maxWidth="xl">
              <Grid container spacing={2}>
                <Grid container alignItems="center" className="page_header" item xs={8}>
                  <img src={`${process.env.PUBLIC_URL}/favicon.ico`} alt="Logo" width="35" height="33"></img>
                  <span className="font_bold font_size_big">{t('favoriteWaypoint.form')}</span>
                </Grid>
                <Grid container alignItems="center" justify="flex-end" className="page_header" item xs={4}>
                  <CancelIcon onClick={this.handleClickClose} className="cursor_pointer" />
                </Grid>
              </Grid>
            </Container>
            <br />

            <Container maxWidth="xl" className="scroll_area_700">
              <form autoComplete="off">
                <Grid container spacing={1}>
                  {/* geofence */}
                  <Grid container item xs={12}>
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.geofence')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense">
                        <Select
                          variant="outlined"
                          className="field_size_20 field_min_size_300"
                          margin="dense"
                          inputProps={{
                            name: 'geofence_id',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.geofence_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.geofence'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.geofence_id}
                          onChange={(event) => this.handleSelectArea(event.target.value)}
                          disabled={waypointDetail?.mark_home === true}
                        >
                          {common?.geofence_list
                            ?.filter((item) => item.on_demand_sim_id)
                            ?.map((item, idx) => (
                              <MenuItem value={item.geofence_id} key={idx}>
                                {item.name}
                              </MenuItem>
                            ))}
                        </Select>
                        {this.validator.message('geofence_id', this.state.geofence_id, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.geofence_id, 'required') && (
                          <FormHelperText id="geofence_id" error>
                            {t('validation.required.choose', {field: t('common.geofence')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Location tag */}
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('favoriteWaypoint.favorite_tag')}
                        <span className="font_color_red">＊</span>
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={6}>
                        <FormControl>
                          <div className="box-group-input">
                            <TextField
                              name="location_tag"
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              placeholder={t('placeholder.required', {field: t('favoriteWaypoint.favorite_tag')})}
                              value={this.state.location_tag}
                              onChange={(event) => onChangeTextField(this, event)}
                              variant="outlined"
                              disabled={waypointDetail?.mark_home === true}
                            />
                            <div className="max-length-label">{t('validation.max.label', {value: 200})}</div>
                          </div>
                          {this.validator.message('pickup_location_tag', this.state.location_tag, 'required|max:200')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.location_tag, 'required') && (
                            <FormHelperText id="pickup_location_tag" error>
                              {t('validation.required', {field: t('favoriteWaypoint.favorite_tag')})}
                            </FormHelperText>
                          )}
                          {this.state.isSubmitForm && this.validator.check(this.state.location_tag, 'required') && !this.validator.check(this.state.location_tag, 'max:200') && (
                            <FormHelperText id="pickup_location_tag" error>
                              {t('errorFields.textLength', {value: 200})}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Maps */}
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="flex-start">
                      <Grid item xs={12}>
                        <Box pl={2} pr={2}>
                          <Maps
                            type="DEPARTURE"
                            transportationType={this.state.service_type === 'TAXI' ? this.state.service_type : 'SHUTTLE_BUS'}
                            position={(pos) => this.getPosition(pos)}
                            searchBoxText={t('favoriteWaypoint.location')}
                            geofences={this.getGeometry(this.state.geofence_id)}
                            parentData={this.state.position}
                            localLanguage={localStorage.getItem('i18nextLng') === 'jp' ? 'ja' : 'en'}
                            currency_code={this.state.currency_code}
                            swapLocation={this.state.swapLocation}
                            getNearestData={true}
                            listWaypointStops={this.props.listWaypointStops}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Container>

            <br />
            <Container maxWidth="xl">
              <Grid container>
                <Grid container justify="center" alignItems="center" item xs={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={waypointDetail?.mark_home === true}
                    className="button_margin"
                    endIcon={<CloudUploadIcon />}
                    onClick={this.handelUpdateButton}
                  >
                    {t('common.btnUpload')}
                  </Button>
                  <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<CloseIcon />} onClick={this.handleClickClose}>
                    {t('common.btnClose')}
                  </Button>
                </Grid>
              </Grid>

              <br></br>
            </Container>
          </LoadingOverlay>

          {/* Modal confirm update */}
          <Dialog
            open={this.state.openConfirmModal}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal onClickOk={this.handleModalButtonOk} onClickCancel={this.handleModalButtonCancel} message={t('favoriteWaypoint.uploadModal')}></SelectModal>
          </Dialog>
        </Paper>
      </Fade>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    waypointDetail: state.member.waypointDetail,
    isLoading: state.member.isLoading,
    listWaypointStops: state.jitSetting.listWaypointStops,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListGeofence: (payload) => dispatch(getListGeofence(payload)),
    getDetailFavoriteWaypoint: (id) => dispatch(getDetailFavoriteWaypoint(id)),
    uploadFavoriteWaypoint: (payload) => dispatch(uploadFavoriteWaypoint(payload)),
    getListFavoriteWaypoint: (user_id, geofence_id, location_tag, location_name, page, size) =>
      dispatch(getListFavoriteWaypoint(user_id, geofence_id, location_tag, location_name, page, size)),
    getWaypointStops: (serviceGroupIds, groupTypes, limit) => dispatch(getWaypointStops(serviceGroupIds, groupTypes, limit)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
