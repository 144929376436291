import {getSubCostsApi, getSubCostDetailApi} from '../../services/subCostServices';
import {ACTION_TYPES} from './reducer';

export const getSubCosts = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_SUB_COSTS_START});
    return getSubCostsApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_SUB_COSTS_SUCCESS,
              subCostList: response.result.content,
            });
            return response.result;
        } else {
          dispatch({type: ACTION_TYPES.GET_SUB_COSTS_FAIL});
        }
      },
      () => {
        dispatch({type: ACTION_TYPES.GET_SUB_COSTS_FAIL});
      },
    );
  };
};

export const getSubCostDetail = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_DETAIL_SUB_COST_START});
    return getSubCostDetailApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_DETAIL_SUB_COST_SUCCESS,
              subCost: response.result,
            });
            return response.result;
        } else {
          dispatch({type: ACTION_TYPES.GET_DETAIL_SUB_COST_FAIL});
        }
      },
      () => {
        dispatch({type: ACTION_TYPES.GET_DETAIL_SUB_COST_FAIL});
      },
    );
  };
};
