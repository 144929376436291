import React, {Component} from 'react';

import {Button, Card, Radio, Container, Divider, Grid, Paper, TextField, Box} from '@material-ui/core';
import {ArrowBack, ArrowForward} from '@material-ui/icons';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';

import {DATA_NULL, ROUTE, TIME_FORMAT, TRANSPORTATION_TYPES} from '../../../../common/constant';
import {setMessageModal} from '../../../../stores/modal/actions';
import {getAdvanceBookingOffers, searchBookingUpdate, updateBooking} from '../../../../stores/reservation/actions';
import {customDisplayCurrency} from '../../../../utils/common';
import {roundedAndConvertTimeByZoneId} from '../../../../utils/datetime';

/**
 * Select Flight Component
 * @return {JSXElement}
 */
class ListOffers extends Component {
  /**
   * Component constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      offerExpectedTime: '',
      selectedOffer: false,
      bookingAdvanceWithoutPartner: false,
    };
  }

  /**
   * Component did mount
   */
  componentDidMount() {
    const {advance_booking_offer, advance_booking_offers} = this.props.searchBooking;
    const indexOfferPartner = advance_booking_offers?.findIndex((offer) => !!offer?.travel_solution?.[0]?.partner_offer) > -1;
    this.setState({
      offerExpectedTime: advance_booking_offer?.expected_time,
      selectedOffer: advance_booking_offers?.some((item) => item?.expected_time === advance_booking_offer?.expected_time),
      bookingAdvanceWithoutPartner: !!!indexOfferPartner,
    });
  };

  /**
   * countBookableOffers
   * @param {Array} offers
   * @return {Number}
   */
  countBookableOffers = (offers) => {
    return offers.reduce((currentNumber, nextOffer) => currentNumber + (nextOffer.bookable ? 1 : 0), 0);
  };

  /**
   * toPageDetail
   */
  toPageDetail = () => {
    const {bookingInfo, searchBooking} = this.props;
    const offer = searchBooking.advance_booking_offers?.find(({expected_time}) => expected_time === this.state.offerExpectedTime);
    const departureTransitStop = offer?.travel_solution?.find(({type}) => type === 'SHUTTLE_BUS');

    const typeIds = TRANSPORTATION_TYPES.map(({id}) => id);
    const routeItemsCount = offer?.travel_solution?.reduce((count, routeItem) => (
      typeIds.includes(routeItem.type) ? count + 1 : count
    ), 0);

    this.props.onUpdateBooking({
      ...bookingInfo,
      advance_booking_pickup_uid: departureTransitStop?.pickup_uid,
      currency_code: departureTransitStop?.service?.currency_code,
      partner_coupon: departureTransitStop?.partner_offer ? {
        discount: departureTransitStop.partner_offer.discount,
        discount_type: departureTransitStop.partner_offer.discount_type,
      } : null,
      estimate_total_cost: offer.estimate_total_cost,
      estimate_start_time: departureTransitStop?.estimated_pickup_time,
      estimate_arrival_time: departureTransitStop?.estimated_dropoff_time,
      transfer_count: routeItemsCount - 1,
    });

    this.props.searchBookingUpdate({
      ...searchBooking,
      advance_booking_offer: offer,
      advance_without_partner: !!!departureTransitStop?.partner_offer,
    });

    this.props.history.push(ROUTE.LAYOUT + ROUTE.RESERVATION_ADVANCE_OFFER_DETAIL);
  }

  /**
   * Render component
   * @return {JSX}
   */
  render() {
    const {t, history, isLoading, searchBooking} = this.props;
    const {zone_id, advance_booking_offers, service_config} = searchBooking;
    const {offerExpectedTime, bookingAdvanceWithoutPartner} = this.state;
    const numberOffersAvailable = this.countBookableOffers(advance_booking_offers);
    const disableButtonNext = numberOffersAvailable === 0;
    // advance booking fee
    const advance_booking_fee = searchBooking?.service_config?.advance_booking_prices;
    const advance_booking_fee_adult = advance_booking_fee?.[0]?.additional_adult_price || 0;
    const advance_booking_fee_child = advance_booking_fee?.[0]?.additional_child_price || 0;

    return (
      <LoadingOverlay active={isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Container maxWidth="xl">
          <Grid container alignItems="center" justifyContent="space-between" className="page_header">
            <Grid item>
              <h3>{t('reservationManagement.advance.select.ride')}</h3>
            </Grid>
            <Grid item>
              <Button
                className="button_margin button_color"
                color="primary"
                variant="contained"
                endIcon={<ArrowBack />}
                onClick={() => {
                  history.push(ROUTE.LAYOUT + ROUTE.RESERVATION_CREATE_INFO);
                }}
              >
                {t('common.btnReturn')}
              </Button>
            </Grid>
          </Grid>
          <br />
          <Card>
            <Container maxWidth="xl">
              <br />
              <Paper className="search_table">
                <Grid container className="product_entry_table_header_color font_color_white search_condition_title">
                  {t('reservationManagement.advance.route.information')}
                </Grid>
                <br />
                <Container maxWidth="xl">
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} md={6}>
                      <TextField className="width_100" variant="outlined" value={searchBooking?.pickup_location_name} label={t('reservationManagement.pickup_location')} disabled />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        className="width_100"
                        variant="outlined"
                        value={searchBooking?.dropoff_location_name}
                        label={t('reservationManagement.dropoff_location')}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={8} md={4}>
                      <TextField
                        className="width_100"
                        variant="outlined"
                        value={moment(searchBooking.pickup_time).tz(zone_id).format('yyyy-MM-DD')}
                        label={t('reservationManagement.advance.pickup.date')}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={4} md={2}>
                      <TextField
                        className="width_100"
                        variant="outlined"
                        value={moment(searchBooking.pickup_time).tz(zone_id).format('HH:mm')}
                        label={t('reservationManagement.advance.pickup.time')}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Container>
                <br />
              </Paper>
              <br />
              <Paper>
                <Grid container className="product_entry_table_header_color font_color_white search_condition_title">
                  {t('reservationManagement.advance.ride.options')}
                </Grid>
                <br />
                {bookingAdvanceWithoutPartner && numberOffersAvailable !== 0 && (advance_booking_fee_adult !== 0 || advance_booking_fee_child !== 0) && (
                  <Grid>
                    <Box pl={3} pb={2}>
                      <b>
                        <i>
                          {t('reservationManagement.advance.one_time_fee_adult_child', {
                            fee_adult: customDisplayCurrency(advance_booking_fee_adult, service_config?.currency_code),
                            fee_child: customDisplayCurrency(advance_booking_fee_child, service_config?.currency_code),
                          })}
                        </i>
                      </b>
                    </Box>
                  </Grid>
                )}
                <br />
                {numberOffersAvailable === 0 ? (
                  <Box pl={3} pb={2}>
                    <b>
                      <i>{t('reservationManagement.advance.no.offer')}</i>
                    </b>
                  </Box>
                ) : (
                  <Container maxWidth="lg">
                    <Grid container alignItems="center">
                      <Grid item xs={2} />
                      <Grid item xs={5} style={{textAlign: 'center'}}>
                        <b>
                          <i>{t('reservationManagement.advance.estimated.pickup.time')}</i>
                        </b>
                      </Grid>
                      <Grid item xs={5} style={{textAlign: 'center'}}>
                        <b>
                          <i>{t('reservationManagement.advance.estimated.dropoff.time')}</i>
                        </b>
                      </Grid>
                    </Grid>
                    <br />
                    <Divider />
                    {advance_booking_offers
                      .filter(({prefer_solution, bookable}) => prefer_solution || bookable)
                      .map((offer) => {
                        const {bookable, expected_time, travel_solution} = offer;
                        const transitStop = bookable ? travel_solution?.find(({type}) => type === 'SHUTTLE_BUS') : null;
                        const estimatedPickupTime = bookable ? transitStop.estimated_pickup_time : expected_time;
                        const estimatedDropoffTime = bookable ? transitStop?.estimated_dropoff_time : null;
                        return (
                          <Grid key={expected_time} container alignItems="center">
                            <Grid item xs={2} style={{textAlign: 'center'}}>
                              <Radio
                                checked={expected_time === offerExpectedTime && offer?.bookable}
                                input-props={{'aria-label': 'flight_option'}}
                                value={expected_time}
                                onChange={(event) => {
                                  this.setState({offerExpectedTime: event.target.value, selectedOffer: event.target.checked});
                                }}
                                disabled={!bookable}
                              />
                            </Grid>
                            <Grid item xs={5} style={{textAlign: 'center'}}>
                              <b className={bookable ? '' : 'disabled_checkbox_radio'}>{roundedAndConvertTimeByZoneId(estimatedPickupTime, zone_id, TIME_FORMAT)}</b>
                            </Grid>
                            <Grid item xs={5} style={{textAlign: 'center'}}>
                              <b className={bookable ? '' : 'disabled_checkbox_radio'}>{
                                bookable ? roundedAndConvertTimeByZoneId(estimatedDropoffTime, zone_id, TIME_FORMAT) : DATA_NULL
                              }</b>
                            </Grid>
                          </Grid>
                        );
                      })}
                    <br />
                  </Container>
                )}
              </Paper>
              <br />
              <Grid container justifyContent="center">
                <Grid item>
                  <Button
                    className="btn_margin"
                    variant="contained"
                    color="primary"
                    disabled={!!!offerExpectedTime || disableButtonNext || !this.state.selectedOffer}
                    endIcon={<ArrowForward />}
                    onClick={() => {
                      this.toPageDetail();
                    }}
                  >
                    {t('reservationManagement.next')}
                  </Button>
                </Grid>
              </Grid>
              <br />
            </Container>
          </Card>
        </Container>
        <br />
      </LoadingOverlay>
    );
  };
};

const mapStateToProps = (state) => ({
  bookingInfo: state.reservation.bookingInfo,
  searchBooking: state.reservation.searchBooking,
  advanceBookingOffers: state.reservation.advanceBookingOffers,
  isLoading: state.reservation.isLoading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    onUpdateBooking: (bookingData) => dispatch(updateBooking(bookingData)),
    searchBookingUpdate: (payload) => dispatch(searchBookingUpdate(payload)),
    getAdvanceBookingOffers: (payload) => dispatch(getAdvanceBookingOffers(payload)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(ListOffers));
