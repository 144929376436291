import React, {Component} from 'react';

import {Paper, Container, Button, Grid} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';

import {setMessageModal} from '../../stores/modal/actions';


/**
 * Modal component
 */
class Index extends Component {
  /**
   * Render component
   * @return {component}
   */
  render() {
    const closeMessage = {
      flg: false,
      message: this.props.modal.message,
      forceReload: false,
    };
    const {t} = this.props;
    return (
      <Paper className="field_min_size_400">
        <Container maxWidth="xl">
          <br />
          <Grid container spacing={6}>
            <Grid container alignItems="center" justifyContent="flex-end" item xs={2}>
              <InfoIcon className="errorinfo" fontSize="large" />
            </Grid>
            <Grid container alignItems="center" justifyContent="flex-start" item xs={10}>
              <span className="font_size_small_regular" style={{whiteSpace: 'pre-line'}}>
                {t(`${this.props.modal.message}`, this.props.modal.fields)}
              </span>
            </Grid>
            <Grid container alignItems="center" justifyContent="center" item xs={12}>
              <Button
                fullWidth
                variant="contained"
                className=""
                color="primary"
                onClick={() => {
                  const {forceReload} = this.props.modal;
                  this.props.setMessageModal(closeMessage);
                  if (forceReload) window.history.go(0);
                }}
              >
                {t('common.btnAgree')}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <br />
      </Paper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
