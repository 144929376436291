import {api} from '../services/api';

const searchListDeliveryUrl = `admin/delivery/search`;
const crudDeliveryUrl = `admin/delivery`;

const searchVehicleConfigUrl = `admin/driver-vehicle-config/management`;
const detailsVehicleConfigUrl = `admin/driver-vehicle-config/management/`;
const updateVehicleConfigUrl = `admin/driver-vehicle-config/management/update`;

const searchListVehiclesUrl = ``;
const getVehicleUrl = ``;

const searchListCancellationFeeUrl = `admin/cancelation-fee`;
const crudCancellationFeeUrl = '';

const searchListCautionUrl = `admin/cautions/search`;
const crudCautionUrl = `admin/cautions`;

const listPriceCalendarUrl = `admin/product/calendar`;
const createPriceCalendarUrl = 'admin/product/calendar ';
const updateListTimeSettingProductUrl = `admin/delivery/sale-times`;

// product delivery API
export const searchListDeliveryApi = (params, queryParams) => {
  return api.search(searchListDeliveryUrl, queryParams, params);
};

export const getDeliveryApi = (id) => {
  return api.get(crudDeliveryUrl + '/' + id);
};

export const updateDeliveryAPI = (payload) => {
  return api.put(crudDeliveryUrl, payload);
};

export const createDeliveryAPI = (payload) => {
  return api.post(crudDeliveryUrl, payload);
};

export const deleteDeliveryAPI = (id) => {
  return api.delete(crudDeliveryUrl + '/' + id);
};

export const getStoreProductsApi = (payload) => {
  return api.get(`admin/delivery/product`, {supplier_facility_id: payload});
};

export const updateStoreProductsIndexApi = (payload) => {
  return api.post(`admin/delivery/product-order`, payload);
};

// vehicle product API
export const searchListVehiclesApi = (params) => {
  return api.get(searchListVehiclesUrl, params);
};

export const getVehicleApi = (id) => {
  return api.get(getVehicleUrl + id);
};

// cancellation fee setting API
export const searchListCancellationFeeApi = (params) => {
  return api.put(searchListCancellationFeeUrl, params);
};

export const getCancellationFeeDetailApi = (id) => {
  return api.get(crudCancellationFeeUrl + id);
};

export const createCancellationFeeApi = (payload) => {
  return api.post(crudCancellationFeeUrl, payload);
};

export const updateCancellationFeeApi = (payload) => {
  return api.post(crudCancellationFeeUrl, payload);
};

export const deleteCancellationFeeApi = (id) => {
  return api.delete(crudCancellationFeeUrl + id);
};

// cautions master API
export const searchListCautionApi = (params, queryParams) => {
  return api.search(searchListCautionUrl, queryParams, params);
};

export const getCautionApi = (id) => {
  return api.get(crudCautionUrl + '/' + id);
};

export const deleteCautionApi = (id) => {
  return api.delete(crudCautionUrl + '/' + id);
};

export const upsertCautionApi = (params) => {
  return api.post(crudCautionUrl + '/upsert', params);
};

// price sale calendar API
export const listPriceCalendarAPI = (params) => {
  return api.get(listPriceCalendarUrl, params);
};

export const createPriceCalendarApi = (params) => {
  return api.post(createPriceCalendarUrl, params);
};

// vehicle config API
export const searchVehicleConfigApi = (payload, queryParams) => {
  return api.search(searchVehicleConfigUrl, queryParams, payload);
};

export const detailsVehicleConfigApi = (params) => {
  return api.get(detailsVehicleConfigUrl + params);
};

export const updateVehicleConfigApi = (payload) => {
  return api.put(updateVehicleConfigUrl, payload);
};

export const updateListTimeSettingProductApi = (payload) => {
  return api.put(updateListTimeSettingProductUrl, payload);
};
