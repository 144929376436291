export const ACTION_TYPES = {
  SEARCH_JIT_ROUTE_START: 'SEARCH_JIT_ROUTE_START',
  SEARCH_JIT_ROUTE_SUCCESS: 'SEARCH_JIT_ROUTE_SUCCESS',
  SEARCH_JIT_ROUTE_FAIL: 'SEARCH_JIT_ROUTE_FAIL',

  GET_JIT_ROUTE_START: 'GET_JIT_ROUTE_START',
  GET_JIT_ROUTE_SUCCESS: 'GET_JIT_ROUTE_SUCCESS',
  GET_JIT_ROUTE_FAIL: 'GET_JIT_ROUTE_FAIL',

  CONFIG_JIT_ROUTE_START: 'CONFIG_JIT_ROUTE_START',
  CONFIG_JIT_ROUTE_SUCCESS: 'CONFIG_JIT_ROUTE_SUCCESS',
  CONFIG_JIT_ROUTE_FAIL: 'CONFIG_JIT_ROUTE_FAIL',

  DELETE_JIT_ROUTE_START: 'DELETE_JIT_ROUTE_START',
  DELETE_JIT_ROUTE_SUCCESS: 'DELETE_JIT_ROUTE_SUCCESS',
  DELETE_JIT_ROUTE_FAIL: 'DELETE_JIT_ROUTE_FAIL',

  GET_WAYPOINT_STOP_START: 'GET_WAYPOINT_STOP_START',
  GET_WAYPOINT_STOP_SUCCESS: 'GET_WAYPOINT_STOP_SUCCESS',
  GET_WAYPOINT_STOP_FAIL: 'GET_WAYPOINT_STOP_FAIL',
};

const initialState = {
  isLoading: false,
  routeDetail: {},
  routeSearch: [],
  listWaypointStops: [],
};

/**
 * function reduce
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.SEARCH_JIT_ROUTE_START:
    case ACTION_TYPES.GET_JIT_ROUTE_START:
    case ACTION_TYPES.CONFIG_JIT_ROUTE_START:
    case ACTION_TYPES.DELETE_JIT_ROUTE_START:
    case ACTION_TYPES.GET_WAYPOINT_STOP_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.SEARCH_JIT_ROUTE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        routeSearch: action.routeSearch,
      };
    case ACTION_TYPES.GET_JIT_ROUTE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        routeDetail: action.routeDetail,
      };
    case ACTION_TYPES.GET_WAYPOINT_STOP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listWaypointStops: action.listWaypointStops,
      };
    case ACTION_TYPES.SEARCH_JIT_ROUTE_FAIL:
      return {
        ...state,
        isLoading: false,
        routeSearch: [],
      };
    case ACTION_TYPES.GET_JIT_ROUTE_FAIL:
      return {
        ...state,
        isLoading: false,
        routeDetail: {},
      };
    case ACTION_TYPES.GET_WAYPOINT_STOP_FAIL:
      return {
        ...state,
        isLoading: false,
        listWaypointStops: [],
      };
    case ACTION_TYPES.CONFIG_JIT_ROUTE_SUCCESS:
    case ACTION_TYPES.CONFIG_JIT_ROUTE_FAIL:
    case ACTION_TYPES.DELETE_JIT_ROUTE_SUCCESS:
    case ACTION_TYPES.DELETE_JIT_ROUTE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
