import React, {Component} from 'react';

import {Grid, Button, Paper, Container, Fade, FormControl, TextField, Select, MenuItem, Dialog, FormHelperText} from '@material-ui/core';
import {IconButton} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {COUNTRY} from '../../../common/constant';
import MuiPhoneNumberInput from '../../../components/mui-phone-number-input/index.jsx';
import SelectModal from '../../../components/selectModal';
import {getAllJitGeofenceApi} from '../../../services/commonServices.js';
import {getJitOrderDetailApi, getJitShiftApi, getJitAddresstApi, createJitOrderApi, updateJitOrderApi, getListSupplierByCountryApi} from '../../../services/jitOrderServices.js';
import {getAllCountryCodeWithGeofence, getAllJitGeofence} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {getListAllGeofenceDetail, onChangeSelect, onChangeTextField, onChangeTextFieldNumber, preventInvalidChars} from '../../../utils/common';
import {formatUtc} from '../../../utils/date_time_utils';
import {modalObj} from '../../../utils/modal';

/**
 * JIT Detail views
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      geofence_id: '',
      country_id: '',
      first_name: '',
      last_name: '',
      pickup_address: '',
      drop_off_address: '',
      pickup_date: null,
      pickup_time: '',
      drop_off_date: null,
      drop_off_time: '',
      phone: '',
      number_of_users: 1,
      email: '',
      result: [],
      isSearch: false,
      index: '',
      listGeofenceAll: [],
      geofence_name: '',
      business_id: '',
      isSubmitForm: false,
      flag: false,
      message: '',
      all_pickup_time: [],
      all_drop_off_time: [],
      all_pickup_address: [],
      all_drop_off_address: [],
      loadingFullPage: false,
      status: null,
      onlyView: true,
      supplier_list: [],
      errorLimitUser: false,
      import_errors: [],
      value_import_errors: {},
      isCompany: true,
      dataDetail: [],
    };
    this.validator = new SimpleReactValidator();
  }

  static defaultProps = {
    detailId: null,
  };

  /**
   * componentDidMount
   */
  async componentDidMount() {
    const supplier_list = await getListSupplierByCountryApi({country_id: null});
    const business_id = supplier_list.result.length === 1 ? supplier_list.result[0].id : this.state.business_id;
    this.setState({
      supplier_list: supplier_list.result,
      business_id,
      isCompany: supplier_list.result.length === 1,
    });
    this.props.getAllCountryCodeWithGeofence();
    if (this.props.detailId !== null) {
      this.setState({loadingFullPage: true});
      getJitOrderDetailApi(this.props.detailId).then(async (res) => {
        this.setState({onlyView: res.result.status === 'SENT'});
        this.setState({
          business_id: res.result.business_id,
          country_id: Number(res.result.country_id),
          first_name: res.result.first_name,
          last_name: res.result.last_name,
          email: res.result.email,
          number_of_users: res.result.number_of_users,
          phone: res.result.phone,
          status: res.result.status,
          import_errors: res.result.import_errors,
          geofence_name: res.result.geofence_name,
          dataDetail: res.result,
        });
        await this.handleOnChangeCountry(res.result.country_id);
        this.setState(
          {
            geofence_id: res.result.geofence_id,
            drop_off_date: res.result.drop_off_date,
            pickup_date: res.result.pickup_date,
          },
          async () => {
            this.getTimeBooking();
            this.getPickupAddress();
            this.getDropOffAddress();
            this.setState(
              {
                drop_off_address: res.result.drop_off_address_id,
                drop_off_time: res.result.drop_off_time ? res.result.drop_off_time.substring(0, 5) : '',
                pickup_address: res.result.pickup_address_id,
                pickup_time: res.result.pickup_time ? res.result.pickup_time.substring(0, 5) : '',
                loadingFullPage: false,
              },
              () => {
                this.getImportError(res.result.import_errors);
              },
            );
          },
        );
      });
    } else {
      this.setState({onlyView: false});
    }
  }

  /**
   * handleOnChangeCountry
   * @param {*} value
   */
  handleOnChangeCountry = async (value) => {
    this.clearImportError('country_id');
    this.setState({
      country_id: value,
      geofence_id: Number(this.state.dataDetail.country_id) === value ? this.state.dataDetail?.geofence_id : '',
      pickup_address: '',
      all_pickup_address: [],
      drop_off_address: '',
      all_drop_off_address: [],
      supplier_id: '',
    });
    const res = await getAllJitGeofenceApi({country_id: value});
    this.resetTime();
    this.setState({
      listGeofenceAll: res.result,
    });
  };

  /**
   * getImportError
   * @param {*} errors
   */
  getImportError = (errors) => {
    for (let index = 0; index < errors.length; index++) {
      const value_import_errors = this.state.value_import_errors;
      value_import_errors[errors[index]] = this.state[errors[index]];
      this.setState({
        value_import_errors: value_import_errors,
        [errors[index]]: ['pickup_date', 'drop_off_date'].includes(errors[index]) ? null : '',
      });
    }
  };

  /**
   * clearImportError
   * @param {*} key
   */
  clearImportError = (key) => {
    const import_errors = this.state.import_errors;
    if (import_errors.indexOf(key) >= 0) {
      import_errors.splice(import_errors.indexOf(key), 1);
      this.setState({
        import_errors: import_errors,
      });
    }
  };

  /**
   * handleOnChangeGeofence
   * @param {*} value
   */
  handleOnChangeGeofence = async (value) => {
    this.clearImportError('geofence_code');
    this.setState(
      {
        geofence_id: value,
        all_pickup_address: [],
        all_drop_off_address: [],
        pickup_address: '',
        drop_off_address: '',
      },
      () => {
        this.getTimeBooking();
        this.getPickupAddress();
        this.getDropOffAddress();
      },
    );
  };

  /**
   * resetTime
   */
  resetTime = () => {
    this.setState({
      pickup_time: '',
      drop_off_time: '',
      all_pickup_time: [],
      all_drop_off_time: [],
    });
  };

  /**
   * getTimeBooking
   */
  getTimeBooking = () => {
    this.resetTime();
    if (this.state.geofence_id) {
      let booking_date = new Date(this.state.pickup_date);
      if (this.state.pickup_date && booking_date instanceof Date && !isNaN(booking_date)) {
        getJitShiftApi({
          geofence_id: this.state.geofence_id,
          booking_date: booking_date.toISOString().substring(0, 10),
          vehicle_group_type: 'SHUTTLE_BUS_JIT_WORK_TO_HOME',
        }).then((res) => {
          this.setState({
            all_pickup_time: res.result
              ?.filter((item, index) => {
                return res.result.findIndex((element) => element.start_time === item.start_time) === index;
              })
              .map((item) => {
                item.time = formatUtc(item['start_time'], 'HH:mm', item['zone_id']);
                return item;
              }),
          });
        });
      }
      booking_date = new Date(this.state.drop_off_date);
      if (this.state.drop_off_date && booking_date instanceof Date && !isNaN(booking_date)) {
        getJitShiftApi({
          geofence_id: this.state.geofence_id,
          booking_date: booking_date.toISOString().substring(0, 10),
          vehicle_group_type: 'SHUTTLE_BUS_JIT_HOME_TO_WORK',
        }).then((res) => {
          this.setState({
            all_drop_off_time: res.result
              ?.filter((item, index) => {
                return res.result.findIndex((element) => element.end_time === item.end_time) === index;
              })
              .map((item) => {
                item.time = formatUtc(item['end_time'], 'HH:mm', item['zone_id']);
                return item;
              }),
          });
        });
      }
    }
  };

  /**
   * getPickupAddress
   */
  getPickupAddress = () => {
    if (!this.state.geofence_id) return;
    getJitAddresstApi({
      geofence_id: this.state.geofence_id,
      address_id: this.state.drop_off_address || null,
      pickup_address: true,
    }).then((res) => {
      this.setState({
        all_pickup_address: res.result,
        pickup_address: res.result ? (res.result.find((item) => item.id + '' === this.state.pickup_address + '') ? this.state.pickup_address : '') : '',
      });
    });
  };

  /**
   * getDropOffAddress
   */
  getDropOffAddress = () => {
    if (!this.state.geofence_id) return;
    getJitAddresstApi({
      geofence_id: this.state.geofence_id,
      address_id: this.state.pickup_address || null,
      pickup_address: false,
    }).then((res) => {
      this.setState({
        all_drop_off_address: res.result,
        drop_off_address: res.result ? (res.result.find((item) => item.id + '' === this.state.drop_off_address + '') ? this.state.drop_off_address : '') : '',
      });
    });
  };

  /**
   * handleCreate
   */
  handleCreate = () => {
    this.setState({
      isSubmitForm: true,
    });
    if (!this.validator.allValid()) return;
    if (!this.props.detailId) {
      this.setState({
        flag: true,
        message: 'jit.confirm.create',
      });
    } else {
      const import_errors = JSON.parse(JSON.stringify(this.state.import_errors));
      if (!import_errors.includes(['pickup_date', 'pickup_time'])) {
        if (import_errors.indexOf('drop_off_date') >= 0) {
          import_errors.splice(import_errors.indexOf('drop_off_date'), 1);
        }
        if (import_errors.indexOf('drop_off_time') >= 0) {
          import_errors.splice(import_errors.indexOf('drop_off_time'), 1);
        }
      }
      if (!import_errors.includes(['drop_off_date', 'drop_off_time'])) {
        if (import_errors.indexOf('pickup_date') >= 0) {
          import_errors.splice(import_errors.indexOf('pickup_date'), 1);
        }
        if (import_errors.indexOf('pickup_time') >= 0) {
          import_errors.splice(import_errors.indexOf('pickup_time'), 1);
        }
      }
      if (this.validator.allValid() && import_errors.length === 0) {
        this.setState({
          flag: true,
          message: 'jit.confirm.update',
        });
      }
    }
  };

  /**
   * handleButtonCancel
   */
  handleButtonCancel = () => {
    this.setState({
      flag: false,
    });
  };

  /**
   * handleClickClose
   */
  handleClickClose = () => {
    this.props.onClickClose();
  };

  /**
   * handleButtonOk
   */
  handleButtonOk = () => {
    this.setState({
      flag: false,
      errorLimitUser: false,
      loadingFullPage: true,
    });
    const {geofence_id, country_id, first_name, last_name, pickup_address, drop_off_address, pickup_time, drop_off_time, phone, number_of_users, email, business_id} = this.state;
    const pickup_date = this.state.pickup_date ? new Date(this.state.pickup_date).toISOString().substr(0, 10) : '';
    const drop_off_date = this.state.drop_off_date ? new Date(this.state.drop_off_date).toISOString().substr(0, 10) : '';
    const payload = {
      geofence_id,
      country_id,
      first_name,
      last_name,
      pickup_address_id: pickup_address,
      drop_off_address_id: drop_off_address,
      pickup_date,
      drop_off_date,
      pickup_time,
      drop_off_time,
      phone,
      number_of_users,
      email,
      business_id,
    };
    if (this.props.detailId) {
      payload.id = this.props.detailId;
      payload.status = this.state.status;
      this.setState({
        flag: false,
      });
      updateJitOrderApi(payload)
        .then((response) => {
          if (response.message_code === 'booking.users.out.of.limit') {
            this.setState({
              errorLimitUser: true,
            });
          } else {
            this.props.setMessageModal(modalObj(true, response.message_code));
          }
          if (response.status === 200) {
            this.props.reSearch();
          }
        })
        .catch((error) => {
          this.props.setMessageModal(modalObj(true, 'Internal Server Error'));
        })
        .then(() => {
          this.setState({loadingFullPage: false});
        });
    } else {
      createJitOrderApi(payload)
        .then((response) => {
          if (response.message_code === 'booking.users.out.of.limit') {
            this.setState({
              errorLimitUser: true,
            });
          } else {
            this.props.setMessageModal(modalObj(true, response.message_code));
          }
          if (response.status === 200) {
            this.props.reSearch();
            this.handleClickClose();
          }
        })
        .catch((error) => {
          this.props.setMessageModal(modalObj(true, 'Internal Server Error'));
        })
        .then(() => {
          this.setState({loadingFullPage: false});
        });
    }
  };

  /**
   * getListAllGeofence
   * @return {Array}
   */
  getListAllGeofence = () => {
    const geofenceDetailInfos = [{geofence_id: this.state.geofence_id, name: this.state.geofence_name}];
    return this.props.detailId && Number(this.state.dataDetail?.country_id) === Number(this.state.country_id) ?
      getListAllGeofenceDetail(this.state.listGeofenceAll, geofenceDetailInfos) :
      this.state.listGeofenceAll;
  }

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {t, common, permission} = this.props;

    return (
      <Fade in={true}>
        <Paper className="modal_size_big" style={{maxWidth: '90%', width: '90%'}}>
          <LoadingOverlay active={this.state.loadingFullPage} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <br></br>
            <Container maxWidth="xl">
              <Grid container spacing={2}>
                <Grid container alignItems="flex-start" className="page_header" item xs={8}>
                  <span className="font_bold font_size_big">{this.props.detailId === null ? t('jit.title.create') : t('jit.title.update')}</span>
                </Grid>
                <Grid container alignItems="flex-start" justify="flex-end" className="page_header" item xs={4}>
                  <CancelIcon onClick={this.handleClickClose} className="cursor_pointer" />
                </Grid>
              </Grid>
            </Container>
            <br />
            <Container maxWidth="xl" className="scroll_area_500">
              <form autoComplete="off">
                <Grid container spacing={1} className="row_form_item">
                  {/* Country */}
                  <Grid container spacing={1} item xs={12} lg={6}>
                    <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                      {t('common.country')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={6}>
                      <FormControl variant="outlined" margin="dense">
                        <Select
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          inputProps={{
                            name: 'country_id',
                          }}
                          disabled={this.state.onlyView}
                          displayEmpty
                          renderValue={
                            this.state.country_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.country'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.country_id}
                          onChange={(event) => this.handleOnChangeCountry(event.target.value)}
                        >
                          {common?.country_code?.map((item, idx) => (
                            <MenuItem value={item.id} key={idx}>
                              {t(`${item.country_code}`)}
                            </MenuItem>
                          ))}
                        </Select>
                        <div className="list-error">
                          {this.validator.message('country_id', this.state.country_id, 'required')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.country_id, 'required') && (
                            <FormHelperText id="country_id" error>
                              {t('validation.required.choose', {field: t('common.country')})}
                            </FormHelperText>
                          )}
                          {this.state.import_errors.includes('country_id') && (
                            <FormHelperText id="country_id" error>
                              {t('validation.invalid.value', {field: t('common.country'), value: this.state.value_import_errors['country_id']})}
                            </FormHelperText>
                          )}
                        </div>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} item xs={12} lg={6}>
                    {/* Geofence */}
                    <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                      {t('business_plan.geofence')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={6}>
                      <FormControl variant="outlined" margin="dense">
                        <Select
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          inputProps={{
                            name: 'geofence_id',
                          }}
                          disabled={this.state.onlyView}
                          displayEmpty
                          renderValue={
                            this.state.geofence_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('business_plan.geofence'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.geofence_id}
                          onChange={(event) => this.handleOnChangeGeofence(event.target.value)}
                        >
                          {this.getListAllGeofence()?.map((item, idx) => {
                            return (
                              <MenuItem value={item.geofence_id} key={idx}>
                                {t(`${item.name}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <div className="list-error">
                          {this.validator.message('geofence_id', this.state.geofence_id, 'required')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.geofence_id, 'required') && (
                            <FormHelperText id="geofence_id" error>
                              {t('validation.required.choose', {field: t('business_plan.geofence')})}
                            </FormHelperText>
                          )}
                          {this.state.import_errors.includes('geofence_code') && (
                            <FormHelperText id="geofence_id" error>
                              {t('validation.invalid.value', {field: t('business_plan.geofence'), value: this.state.value_import_errors['geofence_code']})}
                            </FormHelperText>
                          )}
                        </div>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                {!this.state.isCompany && (
                  <Grid container spacing={1} className="row_form_item">
                    {/* Business name */}
                    <Grid container spacing={1} item xs={12} lg={6}>
                      <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                        {t('reservationManagement.business_name')}
                        <span className="font_color_red">＊</span>
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={6}>
                        <FormControl variant="outlined" margin="dense">
                          <Select
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            inputProps={{
                              name: 'business_id',
                            }}
                            disabled={this.state.onlyView}
                            displayEmpty
                            renderValue={
                              this.state.business_id ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('reservationManagement.business_name'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.business_id}
                            onChange={(e) => {
                              onChangeSelect(this, e);
                              this.clearImportError('business_id');
                            }}
                          >
                            {this.state.supplier_list
                              .filter((item) => !this.state.country_id || item.country_id + '' === this.state.country_id + '')
                              ?.map((item, idx) => {
                                return (
                                  <MenuItem value={item.id} key={idx}>
                                    {t(`${item.supplier_name}`)}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          <div className="list-error">
                            {this.validator.message('business_name', this.state.business_id, 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.business_id, 'required') && (
                              <FormHelperText id="business_name" error>
                                {t('validation.required.choose', {field: t('reservationManagement.business_name')})}
                              </FormHelperText>
                            )}
                            {this.state.import_errors.includes('business_id') && (
                              <FormHelperText id="business_name" error>
                                {t('validation.invalid.value', {
                                  field: t('reservationManagement.business_name'),
                                  value:
                                    this.state.supplier_list?.find((item) => item.id + '' === this.state.value_import_errors['business_id'] + '')?.supplier_name ||
                                    this.state.value_import_errors['business_id'],
                                })}
                              </FormHelperText>
                            )}
                          </div>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid container spacing={1} className="row_form_item">
                  {/* last name */}
                  <Grid container spacing={1} item xs={12} lg={6}>
                    <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                      {t('updateMember.lastName')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={8}>
                      <FormControl variant="outlined" margin="dense">
                        <div className="box-group-input">
                          <TextField
                            className="field_size_10 field_min_size_300"
                            label={
                              <span>
                                {t('updateMember.lastName')}
                                <span className="font_color_red">＊</span>
                              </span>
                            }
                            disabled={this.state.onlyView}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            inputProps={{maxLength: 30}}
                            name="last_name"
                            value={this.state.last_name}
                            onChange={(event) => {
                              onChangeTextField(this, event);
                              this.clearImportError('last_name');
                            }}
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 30})}</div>
                        </div>
                        <div className="list-error">
                          {this.validator.message('last_name', this.state.last_name, 'required')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.last_name, 'required') && (
                            <FormHelperText id="last_name" error>
                              {t('validation.required', {field: t('updateMember.lastName')})}
                            </FormHelperText>
                          )}
                          {this.state.import_errors.includes('last_name') && (
                            <FormHelperText id="last_name" error>
                              {t('validation.invalid.value', {field: t('updateMember.lastName'), value: this.state.value_import_errors['last_name']})}
                            </FormHelperText>
                          )}
                        </div>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* first name */}
                  <Grid container spacing={1} item xs={12} lg={6}>
                    <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                      {t('updateMember.firstName')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={8}>
                      <FormControl variant="outlined" margin="dense">
                        <div className="box-group-input">
                          <TextField
                            className="field_size_10 field_min_size_300"
                            label={
                              <span>
                                {t('updateMember.firstName')}
                                <span className="font_color_red">＊</span>
                              </span>
                            }
                            disabled={this.state.onlyView}
                            inputProps={{maxLength: 30}}
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            name="first_name"
                            value={this.state.first_name}
                            onChange={(event) => {
                              onChangeTextField(this, event);
                              this.clearImportError('first_name');
                            }}
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 30})}</div>
                        </div>
                        <div className="list-error">
                          {this.validator.message('first_name', this.state.first_name, 'required')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.first_name, 'required') && (
                            <FormHelperText id="first_name" error>
                              {t('validation.required', {field: t('updateMember.firstName')})}
                            </FormHelperText>
                          )}
                          {this.state.import_errors.includes('first_name') && (
                            <FormHelperText id="first_name" error>
                              {t('validation.invalid.value', {field: t('updateMember.firstName'), value: this.state.value_import_errors['first_name']})}
                            </FormHelperText>
                          )}
                        </div>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={1} className="row_form_item">
                  {/* Pickup Address */}
                  <Grid container spacing={1} item xs={12} lg={6}>
                    <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                      {t('reservationManagement.pickup_address_search')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={6}>
                      <FormControl variant="outlined" margin="dense">
                        <div className="box-group-input">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'pickup_address',
                            }}
                            className="field_size_10 field_min_size_300"
                            disabled={this.state.onlyView || !this.state.geofence_id}
                            displayEmpty
                            renderValue={
                              this.state.pickup_address ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('reservationManagement.pickup_address_search'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.pickup_address}
                            onChange={(e) => {
                              this.setState({pickup_address: e.target.value}, () => this.getDropOffAddress());
                              this.clearImportError('pickup_address_code');
                            }}
                          >
                            {this.state.all_pickup_address?.map(
                              (item, idx) =>
                                item.id !== this.state.drop_off_address && (
                                  <MenuItem value={item.id} key={idx}>
                                    {item.name}
                                  </MenuItem>
                                ),
                            )}
                          </Select>
                          <IconButton
                            aria-label="delete"
                            onClick={() =>
                              this.setState({pickup_address: ''}, () => {
                                this.getDropOffAddress();
                                this.getPickupAddress();
                              })
                            }
                          >
                            <HighlightOffIcon />
                          </IconButton>
                        </div>
                        <div className="list-error">
                          {this.validator.message('pickup_address', this.state.pickup_address, 'required')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.pickup_address, 'required') && (
                            <FormHelperText id="pickup_address" error>
                              {t('validation.required.choose', {field: t('reservationManagement.pickup_address')})}
                            </FormHelperText>
                          )}
                          {this.state.import_errors.includes('pickup_address_code') && (
                            <FormHelperText id="pickup_address" error>
                              {t('validation.invalid.value', {field: t('reservationManagement.pickup_address'), value: this.state.value_import_errors['pickup_address']})}
                            </FormHelperText>
                          )}
                        </div>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} item xs={12} lg={6}>
                    {/* Dropoff Address */}
                    <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                      {t('reservationManagement.dropoff_address_search')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={6}>
                      <FormControl variant="outlined" margin="dense">
                        <div className="box-group-input">
                          <Select
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            inputProps={{
                              name: 'drop_off_address',
                            }}
                            displayEmpty
                            disabled={this.state.onlyView || !this.state.geofence_id}
                            renderValue={
                              this.state.drop_off_address ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('reservationManagement.dropoff_address_search'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.drop_off_address}
                            onChange={(e) => {
                              this.setState({drop_off_address: e.target.value}, () => this.getPickupAddress());
                              this.clearImportError('drop_off_address_code');
                            }}
                          >
                            {this.state.all_drop_off_address?.map(
                              (item, idx) =>
                                item.id !== this.state.pickup_address && (
                                  <MenuItem value={item.id} key={idx}>
                                    {item.name}
                                  </MenuItem>
                                ),
                            )}
                          </Select>
                          <IconButton
                            aria-label="delete"
                            onClick={() =>
                              this.setState({drop_off_address: ''}, () => {
                                this.getDropOffAddress();
                                this.getPickupAddress();
                              })
                            }
                          >
                            <HighlightOffIcon />
                          </IconButton>
                        </div>
                        <div className="list-error">
                          {this.validator.message('drop_off_address', this.state.drop_off_address, 'required')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.drop_off_address, 'required') && (
                            <FormHelperText id="drop_off_address" error>
                              {t('validation.required.choose', {field: t('reservationManagement.drop_off_address')})}
                            </FormHelperText>
                          )}
                          {this.state.import_errors.includes('drop_off_address_code') && (
                            <FormHelperText id="drop_off_address" error>
                              {t('validation.invalid.value', {field: t('reservationManagement.drop_off_address'), value: this.state.value_import_errors['drop_off_address']})}
                            </FormHelperText>
                          )}
                        </div>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={1} className="row_form_item">
                  {/* Pickup date */}
                  <Grid container spacing={1} item xs={12} lg={6}>
                    <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                      {t('reservationManagement.pickup_date')}
                      {!this.state.drop_off_date && <span className="font_color_red">＊</span>}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={6}>
                      <FormControl>
                        <KeyboardDatePicker
                          autoOk
                          inputVariant="outlined"
                          name="pickup_date"
                          disableToolbar
                          className="field_size_10 field_min_size_300"
                          variant="inline"
                          format="yyyy/MM/dd"
                          margin="dense"
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <IconButton
                                disabled={this.state.onlyView || this.state.drop_off_date}
                                onClick={(e) =>
                                  this.setState({pickup_date: null}, () => {
                                    this.resetTime();
                                  })
                                }
                              >
                                <ClearIcon />
                              </IconButton>
                            ),
                            placeholder: t('reservationManagement.pickup_date'),
                          }}
                          InputAdornmentProps={{
                            position: 'start',
                          }}
                          value={this.state.pickup_date}
                          onChange={(time) => {
                            this.setState({pickup_date: time}, () => this.getTimeBooking());
                            this.clearImportError('pickup_date');
                            this.clearImportError('drop_off_date');
                            this.clearImportError('drop_off_time');
                          }}
                          disabled={this.state.onlyView || this.state.drop_off_date}
                          minDate={this.state.onlyView ? undefined : new Date()}
                        />
                        <div className="list-error">
                          {this.validator.message('pickup_date', this.state.pickup_date, !this.state.drop_off_date ? 'required' : '')}
                          {!this.state.drop_off_date && this.state.isSubmitForm && !this.validator.check(this.state.pickup_date, 'required') && (
                            <FormHelperText id="pickup_date" error>
                              {t('validation.required', {field: t('reservationManagement.pickup_date')})}
                            </FormHelperText>
                          )}
                          {!this.state.drop_off_date && this.state.import_errors.includes('pickup_date') && (
                            <FormHelperText id="pickup_date" error>
                              {t('validation.invalid.value', {field: t('reservationManagement.pickup_date'), value: this.state.value_import_errors['pickup_date']})}
                            </FormHelperText>
                          )}
                        </div>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Pickup time */}
                  <Grid container spacing={1} item xs={12} lg={6}>
                    <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                      {t('reservationManagement.pickup_time')}
                      {!this.state.drop_off_date && <span className="font_color_red">＊</span>}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={6}>
                      <FormControl variant="outlined" margin="dense">
                        <Select
                          margin="dense"
                          className="field_size_10 field_min_size_300"
                          inputProps={{
                            name: 'pickup_time',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.pickup_time ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('reservationManagement.pickup_time'),
                                    })}
                                  </div>
                                )
                          }
                          disabled={this.state.onlyView || this.state.drop_off_date}
                          value={this.state.pickup_time}
                          onChange={(e) => {
                            onChangeSelect(this, e);
                            this.clearImportError('pickup_time');
                          }}
                        >
                          {this.state.all_pickup_time?.map((item, idx) => {
                            return (
                              <MenuItem value={item.time} key={idx} disabled={!item.tejimai_time || item.tejimai_time < new Date().toISOString()}>
                                {item.time}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <div className="list-error">
                          {this.validator.message('pickup_time', this.state.pickup_time, !this.state.drop_off_date ? 'required' : '')}
                          {!this.state.drop_off_date && this.state.isSubmitForm && !this.validator.check(this.state.pickup_time, 'required') && (
                            <FormHelperText id="pickup_time" error>
                              {t('validation.required.choose', {field: t('reservationManagement.pickup_time')})}
                            </FormHelperText>
                          )}
                          {!this.state.drop_off_date && this.state.import_errors.includes('pickup_time') && (
                            <FormHelperText id="pickup_time" error>
                              {t('validation.invalid.value', {field: t('reservationManagement.pickup_time'), value: this.state.value_import_errors['pickup_time']})}
                            </FormHelperText>
                          )}
                        </div>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={1} className="row_form_item">
                  {/* Dropoff date */}
                  <Grid container spacing={1} item xs={12} lg={6}>
                    <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                      {t('reservationManagement.dropoff_date')}
                      {!this.state.pickup_date && <span className="font_color_red">＊</span>}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={6}>
                      <FormControl>
                        <KeyboardDatePicker
                          autoOk
                          inputVariant="outlined"
                          name="drop_off_date"
                          disableToolbar
                          variant="inline"
                          className="field_size_10 field_min_size_300"
                          format="yyyy/MM/dd"
                          margin="dense"
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <IconButton
                                disabled={this.state.onlyView || this.state.pickup_date}
                                onClick={(e) =>
                                  this.setState({drop_off_date: null}, () => {
                                    this.resetTime();
                                  })
                                }
                              >
                                <ClearIcon />
                              </IconButton>
                            ),
                            placeholder: t('reservationManagement.dropoff_date'),
                          }}
                          InputAdornmentProps={{
                            position: 'start',
                          }}
                          value={this.state.drop_off_date}
                          onChange={(time) => {
                            this.setState({drop_off_date: time}, () => this.getTimeBooking());
                            this.clearImportError('drop_off_date');
                            this.clearImportError('pickup_date');
                            this.clearImportError('pickup_time');
                          }}
                          minDate={this.state.onlyView ? undefined : new Date()}
                          disabled={this.state.onlyView || this.state.pickup_date}
                        />
                        <div className="list-error">
                          {this.validator.message('drop_off_date', this.state.drop_off_date, !this.state.pickup_date ? 'required' : '')}
                          {!this.state.pickup_date && this.state.isSubmitForm && !this.validator.check(this.state.drop_off_date, 'required') && (
                            <FormHelperText id="drop_off_date" error>
                              {t('validation.required', {field: t('reservationManagement.dropoff_date')})}
                            </FormHelperText>
                          )}
                          {!this.state.pickup_date && this.state.import_errors.includes('drop_off_date') && (
                            <FormHelperText id="drop_off_date" error>
                              {t('validation.invalid.value', {field: t('reservationManagement.dropoff_date'), value: this.state.value_import_errors['drop_off_date']})}
                            </FormHelperText>
                          )}
                        </div>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} item xs={12} lg={6}>
                    {/* Dropoff time */}
                    <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                      {t('reservationManagement.drop_off_time')}
                      {!this.state.pickup_date && <span className="font_color_red">＊</span>}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={6}>
                      <FormControl variant="outlined" margin="dense">
                        <Select
                          margin="dense"
                          className="field_size_10 field_min_size_300"
                          inputProps={{
                            name: 'drop_off_time',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.drop_off_time ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('reservationManagement.drop_off_time'),
                                    })}
                                  </div>
                                )
                          }
                          disabled={this.state.onlyView || this.state.pickup_date}
                          value={this.state.drop_off_time}
                          onChange={(e) => {
                            onChangeSelect(this, e);
                            this.clearImportError('drop_off_time');
                          }}
                        >
                          {this.state.all_drop_off_time?.map((item, idx) => {
                            return (
                              <MenuItem value={item.time} key={idx} disabled={!item.tejimai_time || item.tejimai_time < new Date().toISOString()}>
                                {item.time}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <div className="list-error">
                          {this.validator.message('drop_off_time', this.state.drop_off_time, !this.state.pickup_date ? 'required' : '')}
                          {!this.state.pickup_date && this.state.isSubmitForm && !this.validator.check(this.state.drop_off_time, 'required') && (
                            <FormHelperText id="drop_off_time" error>
                              {t('validation.required.choose', {field: t('reservationManagement.drop_off_time')})}
                            </FormHelperText>
                          )}
                          {!this.state.pickup_date && this.state.import_errors.includes('drop_off_time') && (
                            <FormHelperText id="drop_off_time" error>
                              {t('validation.invalid.value', {field: t('reservationManagement.drop_off_time'), value: this.state.value_import_errors['drop_off_time']})}
                            </FormHelperText>
                          )}
                        </div>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                {/* phone */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container spacing={1} item xs={12} lg={6}>
                    <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                      {t('common.phoneNumber')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={8}>
                      <FormControl fullWidth>
                        <div className="box-group-input">
                          <MuiPhoneNumberInput
                            defaultCountry={'vn'}
                            onlyCountries={COUNTRY.map((c) => c.countryCode)}
                            label={
                              <span>
                                {t('common.phoneNumber')}
                                <span className="font_color_red">＊</span>
                              </span>
                            }
                            countryCodeEditable={false}
                            enableLongNumbers={true}
                            autoFormat={false}
                            margin="dense"
                            variant="outlined"
                            name="mobile"
                            value={this.state.phone}
                            onChange={(value) => {
                              this.setState({phone: value});
                              this.clearImportError('phone');
                            }}
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 13})}</div>
                        </div>
                        <div className="list-error">
                          {this.validator.message('phone', this.state.phone, 'required|phone|min:10')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.phone, 'required') && (
                            <FormHelperText id="phone" error>
                              {t('validation.required', {field: t('common.phoneNumber')})}
                            </FormHelperText>
                          )}
                          {this.state.isSubmitForm && this.validator.check(this.state.phone, 'required') && !this.validator.check(this.state.phone.trim(), 'phone') && (
                            <FormHelperText id="phone" error>
                              {t('validation.phone')}
                            </FormHelperText>
                          )}
                          {this.state.isSubmitForm && this.validator.check(this.state.phone, 'required') && !this.validator.check(this.state.phone.trim(), 'min:11') && (
                            <FormHelperText id="phone" error>
                              {t('validation.phone.max')}
                            </FormHelperText>
                          )}
                          {this.state.import_errors.includes('phone') && (
                            <FormHelperText id="phone" error>
                              {t('validation.invalid.value', {field: t('common.phoneNumber'), value: this.state.value_import_errors['phone']})}
                            </FormHelperText>
                          )}
                        </div>
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Email */}
                  <Grid container spacing={1} item xs={12} lg={6}>
                    <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                      {t('common.email')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={8}>
                      <FormControl fullWidth>
                        <div className="box-group-input">
                          <TextField
                            disabled={this.state.onlyView}
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('errorFields.emailRequired')}
                            inputProps={{maxLength: 64}}
                            variant="outlined"
                            value={this.state.email || ''}
                            name="email"
                            onChange={(event) => {
                              onChangeTextField(this, event);
                              this.clearImportError('email');
                            }}
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 64})}</div>
                        </div>
                        <div className="list-error">
                          {this.validator.message('email', this.state.email.trim(), 'required|email')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.email, 'required') && (
                            <FormHelperText id="email" error>
                              {t('validation.required', {field: t('common.email')})}
                            </FormHelperText>
                          )}
                          {this.state.isSubmitForm && this.validator.check(this.state.email, 'required') && !this.validator.check(this.state.email.trim(), 'email') && (
                            <FormHelperText id="email" error>
                              {t('validation.email')}
                            </FormHelperText>
                          )}
                          {this.state.import_errors.includes('email') && (
                            <FormHelperText id="email" error>
                              {t('validation.invalid.value', {field: t('common.email'), value: this.state.value_import_errors['email']})}
                            </FormHelperText>
                          )}
                        </div>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                {/* Number of users */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container spacing={1} item xs={12} lg={6}>
                    <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                      {t('reservationManagement.number_of_users')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={6}>
                      <TextField
                        disabled={this.state.onlyView}
                        name="number_of_users"
                        className="field_size_10 field_min_size_300"
                        margin="dense"
                        type="number"
                        onKeyDown={preventInvalidChars}
                        placeholder={t('placeholder.required', {field: t('reservationManagement.number_of_users')})}
                        value={this.state.number_of_users}
                        onChange={(event) => {
                          onChangeTextFieldNumber(this, event, 1);
                          this.clearImportError('number_of_users');
                        }}
                        inputProps={{min: 1}}
                        variant="outlined"
                      />
                      <div className="list-error">
                        {this.validator.message('number_of_users', this.state.number_of_users, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.number_of_users, 'required') && (
                          <FormHelperText id="number_of_users" error>
                            {t('validation.required', {field: t('reservationManagement.number_of_users')})}
                          </FormHelperText>
                        )}
                        {this.state.errorLimitUser && <FormHelperText error>{t('booking.users.out.of.limit')}</FormHelperText>}
                        {this.state.import_errors.includes('number_of_users') && (
                          <FormHelperText id="number_of_users" error>
                            {t('validation.invalid.value', {field: t('reservationManagement.number_of_users'), value: this.state.value_import_errors['number_of_users']})}
                          </FormHelperText>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Container>
            <br />
            <Container maxWidth="xl">
              <Grid container>
                <Grid container justify="flex-start" alignItems="flex-start" item xs={4}></Grid>
                <Grid container justify="flex-end" alignItems="flex-start" item xs={8}>
                  {!this.props.detailId && (
                    <Button color="primary" variant="contained" className="button_margin button_color_green" onClick={() => this.handleCreate()} endIcon={<AddIcon />}>
                      {t('common.btnAdd')}
                    </Button>
                  )}
                  {this.props.detailId && !this.state.onlyView && permission.canUpdate && (
                    <Button color="primary" variant="contained" className="button_margin" onClick={() => this.handleCreate()} endIcon={<CloudUploadIcon />}>
                      {t('common.btnUpdate')}
                    </Button>
                  )}
                  <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.handleClickClose} endIcon={<CloseIcon />}>
                    {t('common.btnClose')}
                  </Button>
                </Grid>
              </Grid>

              <br></br>
            </Container>
            <Dialog
              open={this.state.flag}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '1020',
              }}
            >
              <SelectModal onClickOk={this.handleButtonOk} onClickCancel={this.handleButtonCancel} message={this.state.message}></SelectModal>
            </Dialog>
          </LoadingOverlay>
        </Paper>
      </Fade>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCodeWithGeofence: () => dispatch(getAllCountryCodeWithGeofence()),
    getAllJitGeofence: (payload) => dispatch(getAllJitGeofence(payload)),
    setMessageModal: (params) => dispatch(setMessageModal(params)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
