import {ROUTE} from '../../common/constant';
import {createAutoBusBookingApi} from '../../services/autoBusServices';
import {modalObj} from '../../utils/modal';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

/**
 * createAutoBusBooking
 * @param {object} payload
 * @param {object} props
 * @return {object}
 */
export const createAutoBusBooking = (payload, props) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.CREATE_AUTO_BUS_BOOKING_START,
    });
    return createAutoBusBookingApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.CREATE_AUTO_BUS_BOOKING_SUCCESS,
          });
          const prevPath = ROUTE.LAYOUT + ROUTE.BOOKING_AUTO_BUS_MANAGEMENT;
          props.history.push(prevPath);
        } else {
          if (response.message_code === 'driver.was.assigned') {
            dispatch(setMessageModal(modalObj(true, 'auto_bus_booking.overlapedTime')));
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
          }
          dispatch({type: ACTION_TYPES.CREATE_AUTO_BUS_BOOKING_FAIL});
        }
      },
      () => {
        dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
        dispatch({type: ACTION_TYPES.CREATE_AUTO_BUS_BOOKING_FAIL});
      },
    );
  };
};
