import React from 'react';

import {Box, Button, FormControl, FormHelperText, Grid, IconButton, Paper, TextField} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Brightness3Icon from '@material-ui/icons/Brightness3';
import DeleteIcon from '@material-ui/icons/Delete';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {withTranslation} from 'react-i18next';

import {TIME_FORMAT, MAX_FEE_BLOCK} from '../../common/constant';
import CustomTimePicker from '../CustomTimePicker';
import './styles.css';

const FEE_FIELD = {
  TO: 'to',
  DISTANCE_UNIT: 'distanceUnit',
  FEE: 'fee',
};

/**
 * SettingFee Section
 */
class SettingFee extends React.Component {
  /**
   *
   * @param {*} props
   */
  constructor(props) {
    super(props);
    const {feeList} = props;
    this.state = {
      feeList: feeList || [
        {to: undefined, distanceUnit: undefined, fee: undefined},
        {to: undefined, distanceUnit: undefined, fee: undefined},
      ],
    };
  }
  /**
   *
   * checkTimeRange
   * @return {bool}
   */
  checkTimeRange() {
    const {shiftTimeEnd, shiftTimeStart} = this.props;
    return shiftTimeStart && shiftTimeEnd && shiftTimeEnd > shiftTimeStart;
  }

  /**
   * addContinuingFeeBlock
   */
  addContinuingFeeBlock = () => {
    const feeList = [...this.state.feeList];
    feeList.push({to: undefined, distanceUnit: undefined, fee: undefined});
    this.setState({feeList: feeList}, () => this.onChangeSectionData(this.state.feeList, this.props.dispatchFee, this.props.shiftTimeStart, this.props.shiftTimeEnd));
  };

  /**
   * removeContinuingFeeBlock
   * @param {number} index
   */
  removeContinuingFeeBlock = (index) => {
    const feeList = [...this.state.feeList];
    feeList.splice(index, 1);
    this.setState({feeList: feeList}, () => this.onChangeSectionData(this.state.feeList, this.props.dispatchFee, this.props.shiftTimeStart, this.props.shiftTimeEnd));
  };

  /**
   *
   * @param {number} index
   * @param {string} field
   * @param {*} value
   * @param {boolean} isValid
   */
  onChangeFeeBlockData = (index, field, value) => {
    const feeList = [...this.state.feeList];
    const fee = feeList[index];
    fee[field] = value;
    this.setState({feeList}, () => this.onChangeSectionData(this.state.feeList, this.props.dispatchFee, this.props.shiftTimeStart, this.props.shiftTimeEnd));
  };

  /**
   *
   * @param {array} feeList
   * @param {number} dispatchFee
   * @param {Date} shiftTimeStart
   * @param {Date} shiftTimeEnd
   */
  onChangeSectionData = (feeList, dispatchFee, shiftTimeStart, shiftTimeEnd) => {
    let data = {
      feeList,
      dispatchFee,
    };

    const {isNightShift, shiftTimeIndex} = this.props;

    if (isNightShift) {
      data = {...data, shiftTimeStart, shiftTimeEnd};
    }

    this.props.onChangeFeeSectionData(data, isNightShift, shiftTimeIndex);
  };

  /**
   *
   * @param {Date} value
   */
  onChangeShiftTimeStart = (value) => {
    this.onChangeSectionData(this.state.feeList, this.props.dispatchFee, value, this.props.shiftTimeEnd);
  };

  /**
   *
   * @param {Date} value
   */
  onChangeShiftTimeEnd = (value) => {
    this.onChangeSectionData(this.state.feeList, this.props.dispatchFee, this.props.shiftTimeStart, value);
  };

  /**
   *
   * @param {Event} event
   */
  onChangeDispatchFee = (event) => {
    this.onChangeSectionData(this.state.feeList, +event.target.value, this.props.shiftTimeStart, this.props.shiftTimeEnd);
  };

  /**
   * Render
   * @return {HTMLElement}
   */
  render() {
    const {
      t,
      shiftTimeIndex,
      validator,
      isSubmitForm,
      shiftTimeStart,
      shiftTimeEnd,
      isNightShift,
      uniqueValidationField,
      removeNightShiftSection,
      dispatchFee,
      isOverlapTimeShift,
    } = this.props;
    const {feeList} = this.state;
    return (
      <Box my={2}>
        <Paper className="search_table">
          <Grid container spacing={1} className="row_form_item">
            <Grid container alignItems="center" item xs={12} lg={12} className="background_dark font_size_mid search_condition_title">
              {isNightShift ? (
                <Box display="flex" justifyContent="space-between" alignItems="center" width={1}>
                  <div>
                    <Brightness3Icon />
                    {t('settingFeeTaxiForm.nightShift')}
                    <span className="number-circle">{shiftTimeIndex + 1}</span>
                  </div>
                  {shiftTimeIndex > 0 && (
                    <IconButton onClick={() => removeNightShiftSection(shiftTimeIndex)} variant="outlined" component="span">
                      <DeleteIcon className="font_color_red" />
                    </IconButton>
                  )}
                </Box>
              ) : (
                t('settingFeeTaxiForm.general')
              )}
              {isSubmitForm && isOverlapTimeShift && <FormHelperText error>{t('jit.overlap')}</FormHelperText>}
            </Grid>
          </Grid>
          {/* Shift time */}
          {isNightShift && (
            <Grid container spacing={1}>
              <Grid container item xs={2} lg={2} spacing={1}>
                <Box className="height_100per" mx={2} display="flex" alignItems="center">
                  {t('settingFeeTaxiForm.shiftTime')}
                </Box>
              </Grid>
              <Grid container item xs={5} lg={5} spacing={1}>
                <Box className="height_100per" mx={2} display="flex" alignItems="center">
                  <span className="unit_span">{t('common.from')}</span>
                  <FormControl className="field_with_100px">
                    <CustomTimePicker value={shiftTimeStart || null} showSecond={false} allowEmpty onChange={this.onChangeShiftTimeStart} format={TIME_FORMAT} use12Hours={false} />
                    {isSubmitForm &&
                      ((!validator.check(shiftTimeStart, 'required') && <FormHelperText error>{t('validation.required', {field: t('vehicles.from')})}</FormHelperText>) ||
                        (!this.checkTimeRange() && <FormHelperText error>{t('validation.invalid.timeRange')}</FormHelperText>))}
                  </FormControl>
                </Box>
              </Grid>
              <Grid container item xs={5} lg={5} spacing={1}>
                <Box className="height_100per" mx={2} display="flex" alignItems="center">
                  <span className="unit_span">{t('common.to')}</span>
                  <FormControl className="field_with_100px">
                    <CustomTimePicker value={shiftTimeEnd || null} showSecond={false} allowEmpty onChange={this.onChangeShiftTimeEnd} format={TIME_FORMAT} use12Hours={false} />
                    {isSubmitForm && !validator.check(shiftTimeEnd, 'required') && <FormHelperText error>{t('validation.required', {field: t('vehicles.to')})}</FormHelperText>}
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          )}
          <div className="fee-blocks-container">
            <div className="fee-blocks-wrapper">
              {feeList.map((feeBlock, index) => {
                const isStartingFee = index === 0;
                const from = isStartingFee ? 0 : feeList[index - 1].to;
                return (
                  <div className="fee-block" key={index}>
                    <Box mx={2}>
                      <h3 className="block-title">
                        {isStartingFee ? (
                          t('settingFeeTaxiForm.startingFee')
                        ) : (
                          <>
                            {t('settingFeeTaxiForm.continuingFee')}
                            <span className="number-circle">{index}</span>
                            {index > 1 && (
                              <IconButton
                                onClick={() => this.removeContinuingFeeBlock(index)}
                                variant="outlined"
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                                xs={6}
                                lg={3}
                              >
                                <HighlightOffIcon />
                              </IconButton>
                            )}
                          </>
                        )}
                      </h3>
                    </Box>
                    <Box mx={2} display="flex" alignItems="center">
                      <span className="unit_span">{t('common.from')}</span>
                      <FormControl>
                        <TextField
                          type="number"
                          onChange={(event) => this.onChangeFeeBlockData(index - 1, FEE_FIELD.TO, +event.target.value)}
                          className="field_with_100px"
                          value={from}
                          variant="outlined"
                          margin="dense"
                          disabled={isStartingFee}
                        />
                      </FormControl>
                      <span className="unit_span">{t('common.to')}</span>

                      <FormControl>
                        <TextField
                          type="number"
                          value={feeBlock.to}
                          onChange={(event) => this.onChangeFeeBlockData(index, FEE_FIELD.TO, +event.target.value)}
                          InputProps={{inputProps: {min: isStartingFee ? 1 : (feeList[index - 1].to || 0) + 1}}}
                          className="field_with_100px"
                          variant="outlined"
                          margin="dense"
                        />
                        {validator.message(uniqueValidationField + '_to_' + index, feeBlock.to, `required|min:${isStartingFee ? 1 : feeList[index - 1].to + 1},num`)}
                        {isSubmitForm && !validator.check(feeBlock.to, 'required') && <FormHelperText error>{t('validation.required', {field: t('common.to')})}</FormHelperText>}
                        {isSubmitForm && !validator.check(feeBlock.to, `min:${isStartingFee ? 1 : feeList[index - 1].to + 1},num`) && (
                          <FormHelperText error>{t('validation.invalid.field', {field: t('common.to')})}</FormHelperText>
                        )}
                      </FormControl>
                    </Box>
                    <Box mx={2} display="flex" alignItems="center">
                      <Box className="height_100per" display="flex" alignItems="center">
                        <FormControl>
                          <TextField
                            type="number"
                            value={feeBlock.distanceUnit}
                            onChange={(event) => this.onChangeFeeBlockData(index, FEE_FIELD.DISTANCE_UNIT, +event.target.value)}
                            InputProps={{inputProps: {min: 1}}}
                            className="field_with_100px margin_left_field"
                            variant="outlined"
                            margin="dense"
                          />
                          {validator.message(uniqueValidationField + '_distanceUnit_' + index, feeBlock.distanceUnit, `required|min:0,num`)}
                          {isSubmitForm && !validator.check(feeBlock.distanceUnit, 'required') && (
                            <FormHelperText error>{t('validation.required', {field: t('settingFeeTaxiForm.distanceUnit')})}</FormHelperText>
                          )}
                          {isSubmitForm && !validator.check(feeBlock.distanceUnit, `min:0,num`) && (
                            <FormHelperText error>{t('validation.invalid.field', {field: t('settingFeeTaxiForm.distanceUnit')})}</FormHelperText>
                          )}
                        </FormControl>
                      </Box>

                      <span className="unit_span">{t('settingFeeTaxiForm.meter')}</span>
                      <Box className="height_100per" display="flex" alignItems="center">
                        <FormControl>
                          <TextField
                            type="number"
                            value={feeBlock.fee}
                            onChange={(event) => this.onChangeFeeBlockData(index, FEE_FIELD.FEE, +event.target.value)}
                            InputProps={{inputProps: {min: 0}}}
                            className="field_with_100px"
                            variant="outlined"
                            margin="dense"
                          />
                          {validator.message(uniqueValidationField + '_fee_' + index, feeBlock.fee, `required|min:0,num`)}
                          {isSubmitForm && !validator.check(feeBlock.fee, 'required') && (
                            <FormHelperText error>{t('validation.required', {field: t('settingFeeTaxiForm.fee')})}</FormHelperText>
                          )}
                          {isSubmitForm && !validator.check(feeBlock.fee, `min:0,num`) && (
                            <FormHelperText error>{t('validation.invalid.field', {field: t('settingFeeTaxiForm.fee')})}</FormHelperText>
                          )}
                        </FormControl>
                      </Box>

                      <span className="unit_span">{t('settingFeeTaxiForm.yen')}</span>
                    </Box>
                  </div>
                );
              })}
            </div>
            {feeList.length < MAX_FEE_BLOCK && (
              <div className="add-button-wrapper">
                <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={this.addContinuingFeeBlock}>
                  {t('common.btnAdd')}
                </Button>
              </div>
            )}
          </div>

          {/* Dispatch fee */}
          <Grid container spacing={1}>
            <Grid container item xs={2} lg={2} spacing={1}>
              <Box className="height_100per" mx={2} pb={2} display="flex" alignItems="center">
                {t('settingFeeTaxiForm.openingFee')}
              </Box>
            </Grid>
            <Grid container item xs={10} lg={10} spacing={1}>
              <Box className="height_100per" pb={2} display="flex" alignItems="center">
                <FormControl>
                  <TextField
                    type="number"
                    onChange={this.onChangeDispatchFee}
                    name="dispatchFee"
                    InputProps={{inputProps: {min: 0}}}
                    className="field_with_100px"
                    value={dispatchFee}
                    variant="outlined"
                    margin="dense"
                  />
                  {validator.message(uniqueValidationField + '_dispatchFee', dispatchFee, `required|min:0,num`)}
                  {isSubmitForm && !validator.check(dispatchFee, 'required') && (
                    <FormHelperText error>{t('validation.required', {field: t('settingFeeTaxiForm.openingFee')})}</FormHelperText>
                  )}
                  {isSubmitForm && !validator.check(dispatchFee, `min:0,num`) && (
                    <FormHelperText error>{t('validation.invalid.field', {field: t('settingFeeTaxiForm.openingFee')})}</FormHelperText>
                  )}
                </FormControl>
                <span className="unit_span"> {t('settingFeeTaxiForm.yen')}</span>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    );
  }
}

export default withTranslation('translations')(SettingFee);
