/* eslint-disable array-callback-return */
import React, {Component} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {Container, Fade, Paper, Grid, Dialog, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Button} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';

import SelectModal from '../../../../components/selectModal';
import {updateListTimeSetting} from '../../../../stores/delivery/action';

/**
 * Account Management view
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      sale_time_from: null,
      sale_time_to: null,

      listSettingProduct: this.props.listCheckedProduct,
    };
    this.handleButtonOk = this.handleButtonOk.bind(this);
    this.handleButtonCancel = this.handleButtonCancel.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
  }

  /**
   * handleButtonCancel
   */
  handleButtonCancel() {
    this.setState({
      flag: false,
    });
  }

  /**
   * handleClickClose
   */
  handleClickClose() {
    this.props.onClickClose();
  }

  /**
   * handleButtonOk
   */
  handleButtonOk() {
    this.setState({
      flag: false,
    });
    this.props.onClickClose();
  }

  /**
   * onChangeTimeFrom
   * @param {*} time
   * @param {*} id
   */
  onChangeTimeFrom = (time, id) => {
    const listSettingProduct = this.state.listSettingProduct;
    const settingProduct = listSettingProduct.find((item) => item.id === id);
    settingProduct.sale_time_from = new Date(time).toISOString();
    this.setState({
      listSettingProduct,
    });
  };

  /**
   * handleUpdate
   */
  handleUpdate = async () => {
    const payloads = {sale_times_data: []};
    const {listSettingProduct} = this.state;
    listSettingProduct.map((item) => {
      const itemSettingProduct = {
        product_id: item.id,
        sale_time_from: item.sale_time_from,
        sale_time_to: item.sale_time_to,
      };
      payloads.sale_times_data.push(itemSettingProduct);
    });
    this.props.onClickClose();
    await this.props.updateListTimeSetting(payloads, this.props);
    await this.props.handleSearchDelivery();
  }

  /**
   * onChangeTimeTo
   * @param {*} time
   * @param {*} id
   */
  onChangeTimeTo = (time, id) => {
    const listSettingProduct = this.state.listSettingProduct;
    const settingProduct = listSettingProduct.find((item) => item.id === id);
    settingProduct.sale_time_to = new Date(time).toISOString();
    this.setState({
      listSettingProduct,
    });
  };

  /**
   * Render component
   * @return {object}
   */
  render() {
    const {t, delivery} = this.props;
    return (
      <LoadingOverlay active={delivery.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Fade in={true}>
          <Paper className="modal_size_mid_setting">
            <br></br>
            <Container maxWidth="xl">
              <Grid container spacing={2}>
                <Grid container alignItems="center" className="page_header" item xs={8}>
                  <img src={`${process.env.PUBLIC_URL}/favicon.ico`} alt="Logo" width="35" height="33"></img>
                  <span className="font_bold font_size_big">{t('delivery.settingProductListTime')}</span>
                </Grid>
                <Grid container alignItems="center" justify="flex-end" className="page_header" item xs={4}>
                  <CancelIcon onClick={this.handleClickClose} className="cursor_pointer" />
                </Grid>
              </Grid>
            </Container>
            <br />
            <Container maxWidth="xl">
              <TableContainer className="scroll_area_500">
                <Table size="small" aria-label="sticky table" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className="width_10">{t('delivery.sale_period_start')}</TableCell>
                      <TableCell className="width_10">{t('delivery.sale_period_end')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.listSettingProduct.map((row) => {
                      return (
                        <TableRow hover className="cursor_pointer">
                          <TableCell>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                className="field_size_20 field_min_size_300"
                                margin="dense"
                                autoOk
                                disableToolbar
                                variant="inline"
                                inputVariant="outlined"
                                minDate="2000-01-01"
                                placeholder={t('delivery.sale_period_start')}
                                format="yyyy/MM/dd"
                                value={row.sale_time_from}
                                minDateMessage="無効な日付です"
                                maxDateMessage="無効な日付です"
                                KeyboardButtonProps={{
                                  'aria-label': 'change time',
                                }}
                                onChange={(time) => this.onChangeTimeFrom(time, row.id)}
                              />
                            </MuiPickersUtilsProvider>
                          </TableCell>
                          <TableCell>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                className="field_size_20 field_min_size_300"
                                margin="dense"
                                autoOk
                                disableToolbar
                                variant="inline"
                                placeholder={t('delivery.sale_period_end')}
                                inputVariant="outlined"
                                minDate="2000-01-01"
                                format="yyyy/MM/dd"
                                KeyboardButtonProps={{
                                  'aria-label': 'change time',
                                }}
                                value={row.sale_time_to}
                                onChange={(time) => this.onChangeTimeTo(time, row.id)}
                              />
                            </MuiPickersUtilsProvider>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <br></br>
              <Grid container alignItems="center" justify="flex-start" item xs={3}>
                <Button color="primary" variant="contained" className="button_margin" onClick={() => this.handleUpdate()} >
                  {t('common.btnUpdate')}
                </Button>
              </Grid>
              <br></br>
            </Container>
            <Dialog
              open={this.state.flag}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '1020',
              }}
            >
              <SelectModal onClickOk={this.handleButtonOk} onClickCancel={this.handleButtonCancel} message={this.state.message}></SelectModal>
            </Dialog>
          </Paper>
        </Fade>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    delivery: state.delivery,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateListTimeSetting: (payload, props) => dispatch(updateListTimeSetting(payload, props)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
