import React, {Component} from 'react';

import {Container, Paper, Grid, Button, FormControl, Card, FormControlLabel, Box, Typography, RadioGroup, Radio} from '@material-ui/core';
import TrainIcon from '@material-ui/icons/Train';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {ROUTE} from '../../../common/constant';
import {checkOwnerCard} from '../../../stores/business/action';
import {getAllCountryCode, getListSupplier} from '../../../stores/common/actions';
import {getMemberDetail, updateDetailTicket} from '../../../stores/member/action';
import './style.css';
import {customDisplayCurrency} from '../../../utils/common';

/**
 * Route Ticket
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      detailTicket: {},
      route: '',
      routeData: [],
      ticketInfor: {},
      linePickUp: {},
      cacheData: {},
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    const data = this.props.history?.location?.state;
    if (data) {
      this.setState({routeData: data?.lines, ticketInfor: data?.ticketInfor, cacheData: data?.cacheData});
    } else {
      this.props.history.push({
        pathname: ROUTE.LAYOUT + ROUTE.MEMBER_MANAGEMENT,
      });
    }
  }

  /**
   * getRouteDetail
   * @param {*} item
   * @return {HTML}
   */
  getRouteDetail(item) {
    let stationIds = [];
    item.details.forEach((line) => {
      stationIds = stationIds.concat(line.stations);
    });
    const stations = item.stations.filter((station) => stationIds.includes(station.id));
    return (
      <>
        <Grid container spacing={3} alignItems="flex-start" style={{minWidth: 'max-content'}}>
          {stations.map((station, index) => (
            <>
              <Grid className="space_M" key={station.id}>
                <div className="item_black border_black_2p relative background-color-dark">
                  <div className="route_tag">
                    <h3 className="route_name">{station.name}</h3>
                  </div>
                  {index < stations?.length - 1 && <div className="middle_line_right" />}
                </div>
              </Grid>
              {item.details.map((line) => (
                <>
                  {index < stations?.length - 1 && line.stations.includes(station.id) && line.stations.includes(stations[index + 1].id) && (
                    <Grid className="space_M" key={line.id}>
                      <div style={{backgroundColor: line.route_detail.color}} className="item_black border_black_2p relative">
                        <div className="route_tag">
                          <h3 className="route_name">
                            <div style={{display: 'flex'}}>
                              <TrainIcon />
                              {line.route_detail.name}
                            </div>
                          </h3>
                        </div>
                        <div className="middle_line_right" />
                      </div>
                    </Grid>
                  )}
                </>
              ))}
            </>
          ))}
        </Grid>
      </>
    );
  }

  /**
   * selectRoute
   * @param {*} event
   */
  selectRoute = (event) => {
    this.setState({route: Number(event.target.value), linePickUp: this.state.routeData[event.target.value]});
  };

  /**
   * handleNextPage
   */
  handleNextPage = () => {
    const body = {
      ticket_id: this.state.ticketInfor.ticket_id,
      paths_response: this.state.linePickUp,
    };
    this.props.history.push({
      pathname: ROUTE.LAYOUT + ROUTE.MEMBER_CONFIRM_TICKET + `/${this.props.match.params.userId}`,
      state: {
        ticketInfor: this.state.ticketInfor,
        body,
        cacheData: this.state.cacheData,
        lines: this.state.routeData,
      },
    });
  };

  /**
   * handleBackPage
   */
  handleBackPage = () => {
    this.props.history.push({
      pathname: ROUTE.LAYOUT + ROUTE.MEMBER_SEARCH_TICKET + `/${this.props.match.params.userId}`,
      state: {
        cacheData: this.state.cacheData,
      },
    });
  };

  /**
   * Render component
   * @return {object}
   */
  render() {
    const {t, common, isLoading} = this.props;
    return (
      <LoadingOverlay active={isLoading || common.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size route_ticket">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('route.register_railway_ticket')}</h3>
              </Grid>
            </Grid>
          </Container>
          <br></br>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('ticket.route_information')}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={12} lg={12}>
                      <FormControl>
                        <RadioGroup name="customized-radios" value={this.state.route} onChange={this.selectRoute}>
                          {this.state.routeData.length > 0 &&
                            this.state.routeData?.map((item, index) => (
                              <Grid container item spacing={1} xs={12} lg={12}>
                                <Grid xs={12}>
                                  <Typography className="ml_set_42" variant="h6" component="h6">
                                    {t('ticket.route')} {index + 1} ({t('ticket.fare')}: {customDisplayCurrency(item.total_amount)})
                                  </Typography>
                                </Grid>
                                <Grid xs={12} className="ol_auto">
                                  <FormControlLabel
                                    key={item.agency_id}
                                    value={index}
                                    control={<Radio />}
                                    className="ml_unset"
                                    label={
                                      <Box pt={2} pb={2} className="width_100">
                                        {this.getRouteDetail(item)}
                                      </Box>
                                    }
                                  />
                                </Grid>
                              </Grid>
                            ))}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Paper>
                <br></br>

                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="center" item xs={12}>
                    <Button disabled={_.isEmpty(this.state.linePickUp)} color="primary" variant="contained" className="button_magin" onClick={this.handleNextPage}>
                      {t('common.next')}
                    </Button>
                    <Button color="primary" variant="contained" className="button_magin button_color" style={{marginLeft: 5}} onClick={this.handleBackPage}>
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                </Grid>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>
          <br></br>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    business: state.business,
    isLoading: state.member.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getListSupplier: () => dispatch(getListSupplier()),
    getMemberDetail: (id) => dispatch(getMemberDetail(id)),
    updateDetailTicket: (payload) => dispatch(updateDetailTicket(payload)),
    checkOwnerCard: (userId) => dispatch(checkOwnerCard(userId)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
