import {
  createJitRouteApi,
  getJitRouteDetailApi,
  deleteJitRouteApi,
  searchJitRouteApi,
  getWaypointStopApi,
} from '../../services/jitOrderServices';
import {modalObj} from '../../utils/modal.js';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';


/**
 * searchJitRoute
 * @param {Object} payload
 * @param {Object} queryParams
 * @return {Object}
 */
export const searchJitRoute = (payload, queryParams) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.SEARCH_JIT_ROUTE_START});
    return searchJitRouteApi(payload, queryParams).then(
        (response) => {
          if (response.status === 200 && response) {
            dispatch({
              type: ACTION_TYPES.SEARCH_JIT_ROUTE_SUCCESS,
              routeSearch: response.result,
            });
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
            dispatch({type: ACTION_TYPES.SEARCH_JIT_ROUTE_FAIL});
          }
        },
        () => {
          dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
          dispatch({type: ACTION_TYPES.SEARCH_JIT_ROUTE_FAIL});
        },
    );
  };
};

/**
 * Config Jit Route
 * @param {object} payload
 * @param {object} props
 * @return {object}
 */
export const configJitRoute = (payload, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.CONFIG_JIT_ROUTE_START});
    return createJitRouteApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.CONFIG_JIT_ROUTE_SUCCESS});
            // const prevPath = props.location?.state?.from2 ? props.location?.state?.from2 : ROUTE.LAYOUT + ROUTE.JIT_ROUTES_MANAGEMENT;
            // props.history.push(prevPath);
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.CONFIG_JIT_ROUTE_FAIL});
          }
        },
        () => {
          dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
          dispatch({type: ACTION_TYPES.CONFIG_JIT_ROUTE_FAIL});
        },
    );
  };
};

/**
 * getJitRouteDetail
 * @param {Number} id
 * @return {Object}
 */
export const getJitRouteDetail = (id) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_JIT_ROUTE_START});
    return getJitRouteDetailApi(id).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_JIT_ROUTE_SUCCESS,
              routeDetail: response.result,
            });
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
            dispatch({type: ACTION_TYPES.GET_JIT_ROUTE_FAIL});
          }
        },
        () => {
          dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
          dispatch({type: ACTION_TYPES.GET_JIT_ROUTE_FAIL});
        },
    );
  };
};

/**
 * deleteJitRoute
 * @param {String} code
 * @param {Number} id
 * @return {Promise}
 */
 export const deleteJitRoute = (code, id) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.DELETE_JIT_ROUTE_START});
    return deleteJitRouteApi(code, id).then(
        (response) => {
          if (response.status === 200 && response) {
            dispatch({type: ACTION_TYPES.DELETE_JIT_ROUTE_SUCCESS});
          } else {
            dispatch({type: ACTION_TYPES.DELETE_JIT_ROUTE_FAIL});
          }
          dispatch(setMessageModal(modalObj(true, response.message_code)));
        },
        () => {
          dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
          dispatch({type: ACTION_TYPES.DELETE_JIT_ROUTE_FAIL});
        },
    );
  };
};

/**
 * getWaypointStops
 * @param {Object} payload
 * @return {Object}
 */
export const getWaypointStops = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_WAYPOINT_STOP_START});
    return getWaypointStopApi(payload).then(
        (response) => {
          if (response.status === 200 && response) {
            dispatch({
              type: ACTION_TYPES.GET_WAYPOINT_STOP_SUCCESS,
              listWaypointStops: response.result,
            });
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.GET_WAYPOINT_STOP_FAIL});
          }
        },
        () => {
          dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
          dispatch({type: ACTION_TYPES.GET_WAYPOINT_STOP_FAIL});
        },
    );
  };
};
