import React, {Component} from 'react';

import {Grid, Button, Paper, Container, Fade, FormControl, Select, MenuItem, Table, TableCell, TableRow, TableHead, TableBody, FormHelperText} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';

import {getAllJitGeofence} from '../../../stores/common/actions';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {getJitShiftApi, getAllJitAddressByGeofenceIdApi} from '../../../services/jitOrderServices.js';
import {getAllJitGeofenceApi} from '../../../services/commonServices.js';
import {formatUtc, parseDateTimeUTC} from '../../../utils/date_time_utils';
import LoadingOverlay from 'react-loading-overlay';
import {setMessageModal} from '../../../stores/modal/actions';
import SearchIcon from '@material-ui/icons/Search';
import SimpleReactValidator from 'simple-react-validator';

/**
  * JIT Detail views
  */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      geofence_id: '',
      country_id: '',
      waypoint: '',
      listGeofenceAll: [],
      date: new Date(),
      waypoints: [],
      htw_time: [],
      wth_time: [],
      isSubmitForm: false,
    };
    this.validator = new SimpleReactValidator();
  }

  /**
 * componentDidMount
 */
  async componentDidMount() {
    getAllJitGeofenceApi({country_id: null})
      .then(res => this.setState({
        listGeofenceAll: res.result,
      }))
  }

  handleSearch = () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid()) {
      getAllJitAddressByGeofenceIdApi(this.state.geofence_id).then(res => {
        this.setState({
          waypoints: res.result || []
        })
      })
      let booking_date = parseDateTimeUTC(this.state.date)
      getJitShiftApi({
        geofence_id: this.state.geofence_id,
        booking_date: booking_date,
        vehicle_group_type: 'SHUTTLE_BUS_JIT_WORK_TO_HOME'
      }).then((res) => {
        this.setState({
          wth_time: res.result.map(item => formatUtc(item['start_time'], 'HH:mm', item['zone_id']))
        })
      })
      getJitShiftApi({
        geofence_id: this.state.geofence_id,
        booking_date: booking_date,
        vehicle_group_type: 'SHUTTLE_BUS_JIT_HOME_TO_WORK'
      }).then((res) => {
        this.setState({
          htw_time: res.result.map(item => formatUtc(item['end_time'], 'HH:mm', item['zone_id']))
        })
      })
    }
  }

  /**
   * handleClickClose
   */
  handleClickClose = () => {
    this.props.onClickClose();
  }

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {t} = this.props;
    return (
      <Fade in={true}>
        <Paper className="modal_size_big" style={{maxWidth: '80%', width: '80%'}}>
          <LoadingOverlay active={this.state.loadingFullPage} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <br></br>
            <Container maxWidth="xl">
              <Grid container spacing={2}>
                <Grid container alignItems="flex-start" className="page_header" item xs={8}>
                  <span className="font_bold font_size_big">{t('jit.masterData')}</span>
                </Grid>
                <Grid container alignItems="flex-start" justify="flex-end" className="page_header" item xs={4}>
                  <CancelIcon onClick={this.handleClickClose} className="cursor_pointer" />
                </Grid>
              </Grid>
            </Container>
            <br />
            <Container maxWidth="xl" className="scroll_area_500">
              <form autoComplete="off">

                <Grid container spacing={1} className="row_form_item">
                  {/* Country */}
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('jit.date')}
                    <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl>
                      <KeyboardDatePicker
                        autoOk
                        inputVariant="outlined"
                        name="date"
                        disableToolbar
                        className="field_size_10 field_min_size_300"
                        variant="inline"
                        format="yyyy/MM/dd"
                        margin="dense"
                        label={t('jit.date')}
                        value={this.state.date}
                        onChange={(date) =>
                          this.setState({
                            date: date,
                          })
                        }
                      />
                      {this.validator.message('date', this.state.date, 'required')}
                      {this.state.isSubmitForm && !this.validator.check(this.state.date, 'required') && (
                        <FormHelperText id="date" error>
                          {t('validation.required', {field: t('jit.date')})}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={1} className="row_form_item">
                  {/* Geofence */}
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('business_plan.geofence')}
                    <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={10}>
                    <FormControl variant="outlined" margin="dense">
                      <Select
                        className="field_size_10 field_min_size_300"
                        margin="dense"
                        inputProps={{
                          name: 'geofence_id',
                        }}
                        disabled={this.state.onlyView}
                        displayEmpty
                        renderValue={
                          this.state.geofence_id
                            ? undefined
                            : () => (
                              <div className="font-12 color-disabled">
                                {t('placeholder.required_select', {
                                  field: t('business_plan.geofence'),
                                })}
                              </div>
                            )
                        }
                        value={this.state.geofence_id}
                        onChange={(e) => this.setState({geofence_id: e.target.value})}
                      >
                        {this.state.listGeofenceAll?.map((item, idx) => {
                          return (
                            <MenuItem value={item.geofence_id} key={idx}>
                              {t(`${item.name}`)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {this.validator.message('geofence_id', this.state.geofence_id, 'required')}
                      {this.state.isSubmitForm && !this.validator.check(this.state.geofence_id, 'required') && (
                        <FormHelperText id="geofence_id" error>
                          {t('validation.required', {field: t('business_plan.geofence')})}
                        </FormHelperText>
                      )}
                    </FormControl>
                    {this.state.geofence_id && (
                      <span style={{marginLeft: '10px'}}>
                        {t('jit.code')}: {this.state.listGeofenceAll.filter(item => item.geofence_id === this.state.geofence_id)[0].code}
                      </span>
                    )}
                  </Grid>
                </Grid>

                <Grid container spacing={1} className="row_form_item">
                  <Grid container item xs={6} lg={2} className="grid_title_padding">
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={10}>
                    <Button color="primary" variant="contained" endIcon={<SearchIcon />} onClick={this.handleSearch}>
                      {t('common.btnSearch')}
                    </Button>
                  </Grid>
                </Grid>

                {this.state.waypoints.length > 0 && (
                  <Grid container spacing={1} className="row_form_item">
                    {/* waypoint */}
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.waypoint')}
                    </Grid>
                    <Grid container item xs={6} lg={6}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('jit.waypoint.name')}</TableCell>
                            <TableCell>{t('jit.waypoint.code')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.waypoints.map((row, idx) => (
                            <TableRow key={idx}>
                              <TableCell>{row.display_name}</TableCell>
                              <TableCell>{row.code}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>
                )}

                {this.state.htw_time.length > 0 && (
                  <Grid container spacing={1} className="row_form_item">
                    {/* htw */}
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('jit.time.htw')}
                    </Grid>
                    <Grid container item xs={6} lg={6} style={{paddingLeft: '16px'}}>
                      <div>
                        {this.state.htw_time.map((item) => <p>{item}</p>)}
                      </div>
                    </Grid>
                  </Grid>
                )}

                {this.state.wth_time.length > 0 && (
                  <Grid container spacing={1} className="row_form_item">
                    {/* wth */}
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('jit.time.wth')}
                    </Grid>
                    <Grid container item xs={6} lg={6} style={{paddingLeft: '16px'}}>
                      <div>
                        {this.state.wth_time.map((item) => <p>{item}</p>)}
                      </div>
                    </Grid>
                  </Grid>
                )}

              </form>
            </Container>
            <br />
            <Container maxWidth="xl">
              <Grid container>
                <Grid container justify="flex-start" alignItems="flex-start" item xs={4}></Grid>
                <Grid container justify="flex-end" alignItems="flex-start" item xs={8}>
                  <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.handleClickClose} endIcon={<CloseIcon />}>
                    {t('common.btnClose')}
                  </Button>
                </Grid>
              </Grid>
              <br></br>
            </Container>
          </LoadingOverlay>
        </Paper>
      </Fade>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllJitGeofence: (payload) => dispatch(getAllJitGeofence(payload)),
    setMessageModal: (params) => dispatch(setMessageModal(params)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
