import React, {Component} from 'react';

import {Button, Card, Checkbox, Container, FormControl, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DetailIcon from '@material-ui/icons/Description';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {DEFAULT_PAGEABLE} from '../../../../common/constant';
import CustomPagination from '../../../../components/CustomPagination';
import {searchServiceGeofence, updateServiceGeofence} from '../../../../stores/geofence/action';
import {clearBlankValue, getQueryStringFromObject} from '../../../../utils/common';
import withPermissionData from '../../../../withPermissionData/hoc';

/**
 * Service Geofences
 */
class ServiceGeofences extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      searchPayload: {
        ...DEFAULT_PAGEABLE,
      },
    };
  }

  componentDidMount = () => {
    this.props.searchServiceGeofence(this.state.searchPayload);
  };

  /**
   * call search geofence
   */
  searchServiceGeofence = () => {
    const search = clearBlankValue(this.state.searchPayload);
    this.props.history.replace({
      pathname: '',
      search: getQueryStringFromObject(search),
    });
    this.props.searchServiceGeofence(search);
  };

  /**
   * handle page change
   * @param {number} page
   * @param {number} size
   */
  handlePageChange = (page, size) => {
    this.setState(
        (state) => ({
          ...state,
          searchPayload: {
            ...state.searchPayload,
            page,
            size,
          },
        }),
        this.searchServiceGeofence,
    );
  };

  /**
   * handleSubmitSearch
   * @param {Event} evt
   */
  handleSubmitSearch = (evt) => {
    evt.preventDefault();
  };

  /**
   * handleChangeStatus
   * @param {*} service
   * @param {*} enable
   */
  handleChangeStatus = async (service, enable) => {
    const res = await this.props.updateServiceGeofence({...service, enable});
    res && (service.enable = enable);
    this.setState({...this.state});
  };

  /**
   * render component
   * @return {HTML}
   */
  render() {
    const {t, geofence} = this.props;
    const {listService, isLoading} = geofence;
    const {page_number, totalSize, size, content} = listService || {};

    return (
      <>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('service.title')}</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}>
                <Link style={{textDecoration: 'none'}} to="/maas/home">
                  <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                    {t('common.btnReturn')}
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <br></br>

            <LoadingOverlay active={isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
              {/* --------------------- SEARCH -------------------- */}
              <form onSubmit={this.handleSubmitSearch} autoComplete="off">
                <Card raised>
                  <Container maxWidth="xl">
                    <br></br>
                    <Paper className="search_table">
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                          {t('businessVehicle.searchTitle')}
                        </Grid>
                      </Grid>

                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('services.xxxx')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_250">
                            <TextField
                              name="xxxx"
                              className="field_size_20 field_min_size_250"
                              margin="dense"
                              placeholder={t('services.xxxx')}
                              inputProps={{maxLength: 256}}
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('services.xxxx')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_250">
                            <TextField
                              name="xxxx"
                              className="field_size_20 field_min_size_250"
                              margin="dense"
                              placeholder={t('services.xxxx')}
                              inputProps={{maxLength: 256}}
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Paper>
                    <Grid container spacing={1}>
                      <Grid container alignItems="center" justify="flex-start" item xs={3}>
                        <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.resetSearchCondition} endIcon={<RotateLeftIcon />}>
                          {t('common.btnResetSearch')}
                        </Button>
                      </Grid>
                      <Grid container alignItems="center" justify="flex-end" item xs={9}>
                        <Link style={{textDecoration: 'none'}} to="/maas/service/add">
                          <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />}>
                            {t('common.btnRegister')}
                          </Button>
                        </Link>
                        <Button color="primary" variant="contained" className="button_margin" type="submit" endIcon={<SearchIcon />}>
                          {t('common.btnSearch')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Container>
                </Card>
              </form>
              {/* --------------------- END SEARCH -------------------- */}
              <br></br>
              {/* --------------------- RESULT LIST -------------------- */}

              {content?.length ? (
                <Card raised>
                  <Container maxWidth="xl">
                    <Grid container spacing={1}>
                      <Grid container alignItems="center" item xs={12}>
                        <h3>
                          {t('common.searchResult')} {totalSize} {t('common.case')}
                        </h3>
                      </Grid>
                    </Grid>
                    <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                      <TableHead>
                        <TableRow>
                          <TableCell className="width_50p">{t('common.enable')}</TableCell>
                          <TableCell className="width_100p">{t('service.id')}</TableCell>
                          <TableCell>{t('service.dataset_id')}</TableCell>
                          <TableCell>{t('service.routing_profile_id')}</TableCell>
                          <TableCell>{t('service.transit_stop_set_id')}</TableCell>
                          <TableCell>{t('service.walking_profile_id')}</TableCell>
                          <TableCell>{t('geofence.description')}</TableCell>
                          <TableCell className="width_100p"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {content?.map((row, id) => (
                          <TableRow scope="row" key={id} hover>
                            <TableCell>
                              <Checkbox
                                checked={!!row.enable}
                                onChange={(evt) => this.handleChangeStatus(row, evt.target.checked)}
                                inputProps={{'aria-label': 'primary checkbox'}}
                              />
                            </TableCell>
                            <TableCell>{row.id}</TableCell>
                            <TableCell>{row.dataset_id}</TableCell>
                            <TableCell>{row.routing_profile_id}</TableCell>
                            <TableCell>{row.transit_stop_set_id}</TableCell>
                            <TableCell>{row.walking_profile_id}</TableCell>
                            <TableCell>{row.description}</TableCell>
                            <TableCell>
                              <Link to={'/maas/service/' + row.id} style={{textDecoration: 'none'}}>
                                <Button color="primary" size="small" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                  {t('common.btnEdit')}
                                </Button>
                              </Link>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <CustomPagination onChange={this.handlePageChange} rows={totalSize} rowsPerPage={size} currentPage={page_number}></CustomPagination>
                  </Container>
                </Card>
              ) : (
                <></>
              )}
              {/* --------------------- RESULT LIST END -------------------- */}
            </LoadingOverlay>
          </Container>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    geofence: state.geofence,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    searchServiceGeofence: (searchPayload) => dispatch(searchServiceGeofence(searchPayload)),
    updateServiceGeofence: (payload) => dispatch(updateServiceGeofence(payload)),
  };
};

export default withPermissionData(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(ServiceGeofences)));
