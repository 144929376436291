import {
  searchMemberApi,
  getMemberDetailApi,
  updateMemberApi,
  cancelMemberApi,
  createMemberApi,
  checkValidMobileEmailExist,
  getListFavoriteWaypointApi,
  getListFavoriteWaypointAdminApi,
  getDetailFavoriteWaypointApi,
  uploadFavoriteWaypointApi,
  deleteFavoriteWaypointApi,
  getStationsApi,
  getTicketsApi,
  buyTicketsApi,
  getAgencyListApi,
  getTicketsByUserApi,
  getCsvExportMemberUuidApi,
  searchIdentityVerificationsApi,
  getIdentityVerificationApi,
  updateIdentityVerificationApi,
} from '../../services/memberServices';
import {modalObj} from '../../utils/modal.js';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

export const failSearchMemberAction = () => {
  return {
    type: ACTION_TYPES.SEARCH_MEMBER_FAIL,
  };
};

export const updateDetailTicket = (payload) => {
  return {
    type: ACTION_TYPES.UPDATE_DETAIL_TICKET,
    user_ticket: payload,
  };
};

export const getStations = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_STATIONS_START});
    return getStationsApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_STATIONS_SUCCESS,
            stations: response.result.content,
          });
          return response.result;
        } else {
          dispatch({type: ACTION_TYPES.GET_STATIONS_FAILED});
        }
      },
      () => dispatch({type: ACTION_TYPES.GET_STATIONS_FAILED}),
    );
  };
};

export const getTickets = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_TICKETS_START});
    return getTicketsApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_TICKETS_SUCCESS,
            tickets: response.result,
          });
          return response.result;
        } else {
          dispatch({type: ACTION_TYPES.GET_AGENCY_LIST_FAILED});
        }
      },
      () => dispatch({type: ACTION_TYPES.GET_AGENCY_LIST_FAILED}),
    );
  };
};

export const buyTicket = (userId, payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.BUY_TICKET_START});
    return buyTicketsApi(userId, payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.BUY_TICKET_SUCCESS,
          });
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({type: ACTION_TYPES.BUY_TICKET_FAILED});
        }
      },
      () => dispatch({type: ACTION_TYPES.BUY_TICKET_FAILED}),
    );
  };
};

export const getAgencyList = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_AGENCY_LIST_START});
    return getAgencyListApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_AGENCY_LIST_SUCCESS,
            agencyList: response.result,
          });
          return response.result;
        } else {
          dispatch({type: ACTION_TYPES.GET_AGENCY_LIST_FAILED});
        }
      },
      () => dispatch({type: ACTION_TYPES.GET_AGENCY_LIST_FAILED}),
    );
  };
};

export const getTicketsByUser = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_TICKETS_BY_USER_START});
    return getTicketsByUserApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_TICKETS_BY_USER_SUCCESS,
            ticketsByUser: response.result,
          });
          return response.result;
        } else {
          dispatch({type: ACTION_TYPES.GET_TICKETS_BY_USER_FAILED});
        }
      },
      () => dispatch({type: ACTION_TYPES.GET_TICKETS_BY_USER_FAILED}),
    );
  };
};

/**
 * Search member
 * @param {object} payload
 * @param {object} queryParams
 * @param {object} props
 * @return {object}
 */
export const searchMemberList = (payload, queryParams) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.SEARCH_MEMBER_START});
    return searchMemberApi(payload, queryParams).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.SEARCH_MEMBER_SUCCESS,
            memberList: response.result.content,
          });
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch(failSearchMemberAction());
        }
      },
      () => dispatch(failSearchMemberAction()),
    );
  };
};

/**
 * Get CSV export uuid
 * @param {object} payload
 * @return {object}
 */
export const getCsvExportMemberUuid = (payload) => {
  return (dispatch) => {
    return getCsvExportMemberUuidApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message)));
        }
      },
      () => {
        dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
      },
    );
  };
};

export const getMemberDetail = (params) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_DETAIL_MEMBER_START});
    return getMemberDetailApi(params).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_DETAIL_MEMBER_SUCCESS,
            memberDetail: response.result,
          });
          return response.result;
        } else {
          dispatch({type: ACTION_TYPES.GET_DETAIL_MEMBER_FAIL});
        }
      },
      () => dispatch({type: ACTION_TYPES.GET_DETAIL_MEMBER_FAIL}),
    );
  };
};

export const updateMember = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.UPDATE_MEMBER_START});
    return updateMemberApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.UPDATE_MEMBER_SUCCESS,
            updateMember: response.result,
          });
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({type: ACTION_TYPES.UPDATE_MEMBER_FAIL});
        }
      },
      () => dispatch({type: ACTION_TYPES.UPDATE_MEMBER_FAIL}),
    );
  };
};

export const cancelMember = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.CANCEL_MEMBER_START});
    return cancelMemberApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({type: ACTION_TYPES.CANCEL_MEMBER_SUCCESS});
          dispatch(setMessageModal(modalObj(true, response.message_code)));
        } else {
          dispatch({type: ACTION_TYPES.CANCEL_MEMBER_FAIL});
          dispatch(setMessageModal(modalObj(true, response.message_code)));
        }
      },
      () => dispatch({type: ACTION_TYPES.CANCEL_MEMBER_FAIL}),
    );
  };
};

export const createMember = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.UPDATE_MEMBER_START});
    return createMemberApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.UPDATE_MEMBER_SUCCESS,
            updateMember: response.result,
          });
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({type: ACTION_TYPES.UPDATE_MEMBER_FAIL});
        }
      },
      () => dispatch({type: ACTION_TYPES.UPDATE_MEMBER_FAIL}),
    );
  };
};

export const checkValidMobileMemberExist = (params) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.CHECK_VALID_MOBILE_MEMBER_START});
    return checkValidMobileEmailExist(params).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({type: ACTION_TYPES.CHECK_VALID_MOBILE_MEMBER_SUCCESS});
          return false;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({type: ACTION_TYPES.CHECK_VALID_MOBILE_MEMBER_FAIL});
          return true;
        }
      },
      () => dispatch({type: ACTION_TYPES.CHECK_VALID_MOBILE_MEMBER_FAIL}),
    );
  };
};

// favorite waypoint
export const getListFavoriteWaypoint = (user_id, geofence_id, location_tag, location_name, page, size) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_LIST_FAVORITE_WAYPOINT_START});
    return getListFavoriteWaypointApi(user_id, geofence_id, location_tag, location_name, page, size).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_LIST_FAVORITE_WAYPOINT_DONE,
            waypointList: response.result,
          });
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.GET_LIST_FAVORITE_WAYPOINT_DONE,
            waypointList: [],
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_LIST_FAVORITE_WAYPOINT_DONE,
          waypointList: [],
        }),
    );
  };
};

// favorite waypoint for admin
export const getListFavoriteWaypointAdmin = (params) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_LIST_FAVORITE_WAYPOINT_ADMIN_START});
    return getListFavoriteWaypointAdminApi(params).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_LIST_FAVORITE_WAYPOINT_ADMIN_DONE,
            waypointAdminList: response.result,
          });
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response?.message_code)));
          dispatch({
            type: ACTION_TYPES.GET_LIST_FAVORITE_WAYPOINT_ADMIN_DONE,
            waypointAdminList: [],
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_LIST_FAVORITE_WAYPOINT_ADMIN_DONE,
          waypointAdminList: [],
        }),
    );
  };
};

export const getDetailFavoriteWaypoint = (id) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_DETAIL_FAVORITE_WAYPOINT_START});
    return getDetailFavoriteWaypointApi(id).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_DETAIL_FAVORITE_WAYPOINT_DONE,
            waypointDetail: response.result,
          });
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.GET_DETAIL_FAVORITE_WAYPOINT_DONE,
            waypointDetail: {},
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_DETAIL_FAVORITE_WAYPOINT_DONE,
          waypointDetail: {},
        }),
    );
  };
};

export const uploadFavoriteWaypoint = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.CHANGE_FAVORITE_WAYPOINT_START});
    return uploadFavoriteWaypointApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.CHANGE_FAVORITE_WAYPOINT_DONE,
          });
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.CHANGE_FAVORITE_WAYPOINT_DONE,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.CHANGE_FAVORITE_WAYPOINT_DONE,
        }),
    );
  };
};

export const deleteFavoriteWaypoint = (id) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.CHANGE_FAVORITE_WAYPOINT_START});
    return deleteFavoriteWaypointApi(id).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.CHANGE_FAVORITE_WAYPOINT_DONE,
          });
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.CHANGE_FAVORITE_WAYPOINT_DONE,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.CHANGE_FAVORITE_WAYPOINT_DONE,
        }),
    );
  };
};

export const searchIdentityVerifications = (params, payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.SEARCH_IDENTITY_VERIFICATIONS_START});
    return searchIdentityVerificationsApi(params, payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({type: ACTION_TYPES.SEARCH_IDENTITY_VERIFICATIONS_SUCCESS});
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, 'error.500')));
          dispatch({type: ACTION_TYPES.SEARCH_IDENTITY_VERIFICATIONS_FAIL});
        }
      },
      () => dispatch({type: ACTION_TYPES.SEARCH_IDENTITY_VERIFICATIONS_FAIL}),
    );
  };
};

export const getIdentityVerification = (id) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_IDENTITY_VERIFICATION_START});
    return getIdentityVerificationApi(id).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({type: ACTION_TYPES.GET_IDENTITY_VERIFICATION_SUCCESS});
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, 'error.500')));
          dispatch({type: ACTION_TYPES.GET_IDENTITY_VERIFICATION_FAIL});
        }
      },
      () => dispatch({type: ACTION_TYPES.GET_IDENTITY_VERIFICATION_FAIL}),
    );
  };
};

export const updateIdentityVerification = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.UPDATE_IDENTITY_VERIFICATION_START});
    return updateIdentityVerificationApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({type: ACTION_TYPES.UPDATE_IDENTITY_VERIFICATION_SUCCESS});
          return response;
        } else {
          dispatch(setMessageModal(modalObj(true, 'error.500')));
          dispatch({type: ACTION_TYPES.UPDATE_IDENTITY_VERIFICATION_FAIL});
        }
      },
      () => dispatch({type: ACTION_TYPES.UPDATE_IDENTITY_VERIFICATION_FAIL}),
    );
  };
};
