import React, {Component} from 'react';

import {Grid, FormControl, Select, MenuItem, InputLabel, Button} from '@material-ui/core';
import {Search as SearchIcon, Replay as ReloadIcon} from '@material-ui/icons';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import 'date-fns';
import LoadingOverlay from 'react-loading-overlay';

import MapWithPolyline from '../../../../../components/map/MapWIthPolyline';
import {getReservationIdTrackingApi, polylineTrackingApi} from '../../../../../services/reservationServices';
import {roundedAndConvertTime} from '../../../../../utils/datetime';

/**
 * Section Polyline
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      result: [],
      selected_data: [],
      showMap: false,
      mapWithPolyline: '',
      isLoading: false,
      existNodes: [],
      reservationCodes: [],
      indexValue: '',
    };
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    this.reload();
  }

  /**
   * handleChangeDateTime
   * @param {Number} index
   */
  handleChangeDateTime = async (index) => {
    this.setState({selected_data: this.state.result[index], indexValue: index});
    if (this.state.result[index]?.created_date) {
      const assignNodes = this.state.result[index].nodes.filter((item) => item.status === 'assigned');
      this.setState({existNodes: assignNodes});
    }
  };

  /**
   * reload
   */
  reload = () => {
    this.setState({isLoading: true});
    polylineTrackingApi(this.props.id, {type: this.props.type}).then((res) => {
      if (res?.result?.length > 0 && this.props.type === 'MOBILE') {
        let bookingIds = res?.result[0]?.nodes?.map((item) => item.booking_uid);
        for (let i = 1; i < res?.result?.length; i++) {
          const newBookingIds = res?.result[i]?.nodes?.map((item) => item.booking_uid);
          bookingIds = bookingIds.concat(newBookingIds);
        }
        bookingIds = bookingIds.filter((value, index, self) => self.indexOf(value) === index);
        getReservationIdTrackingApi({booking_ids: bookingIds}).then((response) => {
          if (response.result) {
            this.setState({reservationCodes: response.result, isLoading: false});
          }
        });
      } else {
        this.setState({isLoading: false});
      }
      this.setState({
        result: res.result.map((item) => {
          item['created_date'] = moment(new Date(item['created_date'])).format('YYYY-MM-DD HH:mm:ss.SSS');
          return item;
        }),
        selected_data: [],
      });
    });
  };

  /**
   * render
   * @return {HTMLElement}
   */
  render() {
    const {t} = this.props;
    return (
      <LoadingOverlay active={this.state.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <h1 className="product_entry_table_header_color font_color_white font_size_mid title">{this.props.type}</h1>
          </Grid>
          <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
            <FormControl variant="outlined" className="field_size_20 field_min_size_350" margin="dense">
              <InputLabel>
                {t('newReservation.dateTime')}
                <span className="font_color_red">＊</span>
              </InputLabel>
              <Select
                value={this.state.indexValue}
                onChange={(event) => this.handleChangeDateTime(event.target.value)}
                label={t('newReservation.dateTime')}
                inputProps={{
                  name: t('newReservation.dateTime'),
                }}
              >
                {this.state.result.map((item, idx) => (
                  <MenuItem value={idx} key={idx}>
                    {roundedAndConvertTime(item.created_date, item?.country_id)} {item.event ? ` - ${item.event}` : ''} {item.reservation_number ? `- ${item.reservation_number}` : ''}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="primary"
              disabled={this.state.selected_data.length === 0}
              variant="contained"
              className="button_margin"
              endIcon={<SearchIcon />}
              onClick={() =>
                this.state.selected_data.polyline5 ?
                  this.setState({
                      showMap: true,
                      mapWithPolyline: (
                        <MapWithPolyline
                          dataPolyline={{
                            polyline: this.state.selected_data.polyline5,
                            type: this.props.type === 'MOBILE' ? 'tracking' : '',
                            pickUpPoint: {lat: this.state.selected_data.nodes[0].lat, lng: this.state.selected_data.nodes[0].lon},
                            dropOffPoint: {
                              lat: this.state.selected_data.nodes[this.state.selected_data.nodes.length - 1].lat,
                              lng: this.state.selected_data.nodes[this.state.selected_data.nodes.length - 1].lon,
                            },
                            booking_id: this.state.selected_data.booking_id,
                            nextPointPosition: {lat: this.state.existNodes[0].lat, lng: this.state.existNodes[0].lon},
                            driverPosition: {
                              lat: this.state.selected_data.lat,
                              lng: this.state.selected_data.lon,
                            },
                            nodes: this.state.existNodes,
                            reservationCodes: this.state.reservationCodes,
                            edges: this.state.selected_data?.edges || [],
                          }}
                        />
                      ),
                    }) :
                  this.setState({showMap: false})
              }
            >
              {t('reservationManagement.search')}
            </Button>
            <Button color="primary" variant="contained" className="button_margin button_color_info" endIcon={<ReloadIcon />} onClick={this.reload}>
              {t('common.reload')}
            </Button>
          </Grid>
          <Grid item xs={12}>
            {!(this.state.selected_data.length === 0) && !this.state.selected_data.polyline5 && !this.state.showMap && (
              <div style={{marginLeft: '5px'}}>{t('reservationManagement.no_polyline_data')}</div>
            )}
            {this.state.showMap && this.state.mapWithPolyline}
          </Grid>
        </Grid>
      </LoadingOverlay>
    );
  }
}

export default withTranslation('translations')(Index);
