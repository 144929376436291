import {searchListCautionApi, getCautionApi, deleteCautionApi, upsertCautionApi} from '../../services/productServices';
import {modalObj} from '../../utils/modal.js';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

/**
 * search list notice
 * @param {object} params
 * @param {object} queryParams
 * @param {object} props
 * @return {object}
 */
export const searchListCaution = (params, queryParams = {}, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.SEARCH_LIST_NOTICE_START});
    return searchListCautionApi(params, queryParams).then(
        (data) => {
          if (data && data.status === 200) {
            dispatch({
              type: ACTION_TYPES.SEARCH_LIST_NOTICE_SUCCESS,
              noticeList: data.result,
            });
            return data.result;
          } else {
            dispatch({
              type: ACTION_TYPES.SEARCH_LIST_NOTICE_FAIL,
              error: data.message,
            });
          }
        },
        () => dispatch({
          type: ACTION_TYPES.SEARCH_LIST_NOTICE_FAIL,
          error: 'Internal Server Error',
        }),
    );
  };
};

/**
 * Export list caution
 * @param {object} payload
 * @param {object} queryParams
 * @return {object}
 */
export const exportCaution = (payload, queryParams) => {
  return (dispatch) => {
    return searchListCautionApi(payload, queryParams).then(
        (response) => {
          if (response && response.status === 200) {
            return response.result?.content;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
          }
        },
        () => dispatch(setMessageModal(modalObj(true, 'Internal Server Error'))),
    );
  };
};
/**
 * get detail notice
 * @param {object} params
 * @return {object}
 */
export const getCaution = (params) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_NOTICE_START});
    return getCautionApi(params).then(
        (data) => {
          if (data && data.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_NOTICE_SUCCESS,
              noticeDetail: data.result,
            });
            return data.result;
          } else {
            dispatch({
              type: ACTION_TYPES.GET_NOTICE_FAIL,
              error: data.message,
            });
          }
        },
        () => dispatch({
          type: ACTION_TYPES.GET_NOTICE_FAIL,
          error: 'Internal Server Error',
        }),
    );
  };
};

/**
 * delete caution
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const deleteCaution = (params, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.UPSERT_NOTICE_START});
    return deleteCautionApi(params).then(
        (response) => {
          if (response?.result && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.result.message_code)));
            dispatch({type: ACTION_TYPES.UPSERT_NOTICE_SUCCESS});
          } else {
            dispatch({type: ACTION_TYPES.UPSERT_NOTICE_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.UPSERT_NOTICE_FAIL}),
    );
  };
};

/**
 * search list notice
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const upsertCaution = (params, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.UPSERT_NOTICE_START});
    return upsertCautionApi(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.result.message_code)));
            dispatch({type: ACTION_TYPES.UPSERT_NOTICE_SUCCESS});
            return response.result;
          } else {
            dispatch({type: ACTION_TYPES.UPSERT_NOTICE_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.UPSERT_NOTICE_FAIL}),
    );
  };
};
