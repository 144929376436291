export const ACTION_TYPES = {
  SEARCH_LIST_BUSINESS_IMAGE_START: 'SEARCH_LIST_BUSINESS_IMAGE_START',
  SEARCH_LIST_BUSINESS_IMAGE_SUCCESS: 'SEARCH_LIST_BUSINESS_IMAGE_SUCCESS',
  SEARCH_LIST_BUSINESS_IMAGE_FAIL: 'SEARCH_LIST_BUSINESS_IMAGE_FAIL',

  CREATE_BUSINESS_IMAGE_START: 'CREATE_BUSINESS_IMAGE_START',
  CREATE_BUSINESS_IMAGE_SUCCESS: 'CREATE_BUSINESS_IMAGE_SUCCESS',
  CREATE_BUSINESS_IMAGE_FAIL: 'CREATE_BUSINESS_IMAGE_FAIL',

  GET_DETAIL_BUSINESS_IMAGE_START: 'GET_DETAIL_BUSINESS_IMAGE_START',
  GET_DETAIL_BUSINESS_IMAGE_SUCCESS: 'GET_DETAIL_BUSINESS_IMAGE_SUCCESS',
  GET_DETAIL_BUSINESS_IMAGE_FAIL: 'GET_DETAIL_BUSINESS_IMAGE_FAIL',

  UPDATE_IMAGE_MASTERS_START: 'UPDATE_IMAGE_MASTERS_START',
  UPDATE_IMAGE_MASTERS_SUCCESS: 'UPDATE_IMAGE_MASTERS_SUCCESS',
  UPDATE_IMAGE_MASTERS_FAIL: 'UPDATE_IMAGE_MASTERS_FAIL',

  DELETE_IMAGE_MASTERS_START: 'DELETE_IMAGE_MASTERS_START',
  DELETE_IMAGE_MASTERS_SUCCESS: 'DELETE_IMAGE_MASTERS_SUCCESS',
  DELETE_IMAGE_MASTERS_FAIL: 'DELETE_IMAGE_MASTERS_FAIL',
};

const initialState = {
  isLoading: false,
  listImage: [],
  detailImage: {},
  updateImageMasters: {},
};

/**
 * function reduce image
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.SEARCH_LIST_BUSINESS_IMAGE_START:
    case ACTION_TYPES.GET_DETAIL_BUSINESS_IMAGE_START:
    case ACTION_TYPES.CREATE_BUSINESS_IMAGE_START:
    case ACTION_TYPES.UPDATE_IMAGE_MASTERS_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.SEARCH_LIST_BUSINESS_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listImage: action.listImage,
      };
    case ACTION_TYPES.GET_DETAIL_BUSINESS_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        detailImage: action.detailImage,
      };
    case ACTION_TYPES.CREATE_BUSINESS_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.UPDATE_IMAGE_MASTERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        updateImageMasters: action.updateImageMasters,
      };
    case ACTION_TYPES.SEARCH_LIST_BUSINESS_IMAGE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        listImage: [],
      };
    case ACTION_TYPES.GET_DETAIL_BUSINESS_IMAGE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        listImage: {},
      };
    case ACTION_TYPES.CREATE_BUSINESS_IMAGE_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case ACTION_TYPES.UPDATE_IMAGE_MASTERS_FAIL:
      return {
        isLoading: false,
      };
    default:
      return state;
  }
}
