import React, {Component} from 'react';

import {
  Card,
  Container,
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  TableHead,
  Paper,
  Dialog,
  FormHelperText,
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import GetAppIcon from '@material-ui/icons/GetApp';
import MailIcon from '@material-ui/icons/Mail';
import CompareArrowsIcon from '@material-ui/icons/SwapHoriz';
import {KeyboardDatePicker, KeyboardDateTimePicker} from '@material-ui/pickers';
import {format} from 'date-fns';
import {add} from 'date-fns/esm';
import {ExportToCsv} from 'export-to-csv';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {DATE_FORMAT, LANGUAGE, PLAN_CATEGORIES, TICKET_AMOUNT_TYPE, TIME_FORMAT, CONTENT_TYPE_CSV, DATE_TIME_FORMAT, DATA_NULL} from '../../../common/constant';
import CustomTimePicker from '../../../components/CustomTimePicker';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {addTicket, searchListPlan, getDetailPlan, checkMemberCode} from '../../../stores/business/action';
import {getAllSubscriptionGeofence, getAllCountryCode} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {customDisplayCurrency} from '../../../utils/common';
import {formatHourMinuteToLocal} from '../../../utils/date_time_utils';
// import {localToUtc} from '../../../utils/date_time_utils';
import {modalObj} from '../../../utils/modal';
import {isRoleBusiness, isRoleGlobal} from '../../../utils/role';

/**
 * Register subscription for users
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      countryId: '',
      geofenceId: '',
      planId: '',
      plan: {},

      listPlan: [],
      listGeofences: [],
      isSubmitForm: false,

      start_date: '',
      // distribution_date: localToUtc(new Date()).substring(0, 16),
      distribution_date: '',
      fileCSV: '',
      callApi: false,
      importSuccess: false,
      dataImport: [],
      openConfirmAddTicketModal: false,
      totalMemberRecord: null,
      errorMemberCode: '',
    };
    this.validator = new SimpleReactValidator();
    this.exportCSVRef = React.createRef();
  }

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    await this.props.getAllCountryCode();
    await this.props.getAllSubscriptionGeofence();

    this.setState({
      countryId: isRoleGlobal() ? '' : this.props.common.principal.country_id,
      listGeofences: this.props.common?.all_subscription_geofence,
    });
  };

  /**
   * onChangeCountry
   * @param {event} event
   */
  onChangeCountry = (event) => {
    this.setState({
      countryId: event.target.value,
      geofenceId: '',
      planId: '',
      listPlan: [],
      plan: {},
      listGeofences: this.props.common?.all_subscription_geofence.filter((geofence) => geofence.country_id === event.target.value),
      callApi: false,
      importSuccess: false,
      dataImport: [],
    });
  };

  /**
   * onChangeGeofence
   * @param {event} event
   */
  onChangeGeofence = (event) => {
    this.setState({
      geofenceId: event.target.value,
      planId: '',
      plan: {},
    });
    this.props
      .searchListPlan({
        country_id: this.state.countryId,
        geofence_id: event.target.value,
        plan_type: PLAN_CATEGORIES[1].value,
      })
      .then((response) => this.setState({listPlan: this.filterListPlans(response?.content)}));
  };

  /**
   * onChangePlan
   * @param {event} event
   */
  onChangePlan = (event) => {
    this.props.getDetailPlan(event.target.value).then((response) => this.setState({plan: response, planId: event.target.value}));
  };

  /**
   * filterListPlan
   * @param {Array} listPlans
   * @return {Array}
   */
  filterListPlans = (listPlans) => {
    const plansIsGift = listPlans?.filter((plan) => plan.is_gift);
    if (isRoleBusiness()) {
      const principalGeofenceIds = this.props.common.principal?.geofence_ids;
      return plansIsGift?.filter((plan) => plan.geofence_ids.every((geofenceId) => principalGeofenceIds?.includes(geofenceId)));
    } else {
      return plansIsGift;
    }
  };

  /**
   * formatDataHourToDate
   * @param {*} listPlanTime
   * @param {*} zone_id
   * @return {*}
   */
  formatDataHourToDate = (listPlanTime, zone_id) => {
    const arrNew = [];
    for (let i = 0; i < listPlanTime.length; i++) {
      const data = {
        start_time: formatHourMinuteToLocal(listPlanTime[i]?.start_time, zone_id),
        end_time: formatHourMinuteToLocal(listPlanTime[i]?.end_time, zone_id),
      };
      arrNew.push(data);
    }
    return arrNew;
  };

  /**
   * formatDataDetailsDisplayTime
   * @param {*} dates
   * @return {Object}
   */
  formatDataDetailsDisplayTime = (dates) => {
    // Convert start date
    const startDateString = dates.start_time;
    const startDateDay = startDateString.substr(8, 2);
    const startDateMonth = startDateString.substr(5, 2);
    const startDateYear = startDateString.substr(0, 4);

    // Month is subtract to 1 because month in Date start with 0
    let startDate = new Date(startDateYear, startDateMonth - 1, startDateDay, 0, 0, 0, 0);
    startDate = add(startDate, {days: 1});

    // Convert end date
    const endDateString = dates.end_time;
    const endDateDay = endDateString.substr(8, 2);
    const endDateMonth = endDateString.substr(5, 2);
    const endDateYear = endDateString.substr(0, 4);

    const endDate = new Date(endDateYear, endDateMonth - 1, endDateDay, 23, 59, 59, 0);
    return [
      {
        start_time: startDate,
        end_time: endDate,
      },
    ];
  };

  /**
   * formatDataDetailsUseTime
   * @param {*} dates
   * @return {Object}
   */
  formatDataDetailsUseTime = (dates) => {
    // Convert start date
    const startDateString = dates.start_time;
    const startDateDay = startDateString.substr(8, 2);
    const startDateMonth = startDateString.substr(5, 2);
    const startDateYear = startDateString.substr(0, 4);

    // Month is subtract to 1 because month in Date start with 0
    let startDate = new Date(startDateYear, startDateMonth - 1, startDateDay, 0, 0, 0, 0);
    startDate = add(startDate, {days: 1});

    // Convert end date
    const endDateString = dates.end_time;
    const endDateDay = endDateString.substr(8, 2);
    const endDateMonth = endDateString.substr(5, 2);
    const endDateYear = endDateString.substr(0, 4);

    const endDate = new Date(endDateYear, endDateMonth - 1, endDateDay, 23, 59, 59, 0);
    return {
      start_time: startDate,
      end_time: endDate,
    };
  };

  /**
   * validDistributionDate
   * @return {Boolean}
   */
  validDistributionDate = () => {
    if (Object.keys(this.state.plan).length === 0 || !this.checkMinStartDate()) return true;
    const {distribution_date} = this.state;
    if (String(distribution_date) === 'Invalid Date') return true;
    const use_time =
      // eslint-disable-next-line max-len
      this.state.plan?.use_time && this.state.plan?.use_time?.start_time && this.state.plan?.use_time?.end_time ? this.formatDataDetailsUseTime(this.state.plan.use_time) : {start_time: '', end_time: ''};
    const startTime = use_time.start_time;
    const endTime = use_time.end_time;
    return !distribution_date || (parseInt(distribution_date.getTime()) <= parseInt(endTime.getTime()) && parseInt(distribution_date.getTime()) >= parseInt(startTime.getTime()));
  };

  /**
   * validStartDate
   * @return {Boolean}
   */
  validStartDate = () => {
    if (Object.keys(this.state.plan).length === 0 || !this.checkMinStartDate()) return true;
    const {start_date} = this.state;
    if (String(start_date) === 'Invalid Date') return true;
    const use_time =
      this.state.plan?.use_time && this.state.plan?.use_time?.start_time && this.state.plan?.use_time?.end_time ?
      this.formatDataDetailsUseTime(this.state.plan.use_time) : {start_time: '', end_time: ''};
    const startTime = use_time.start_time;
    const endTime = use_time.end_time;
    return !start_date || (parseInt(start_date.getTime()) <= parseInt(endTime.getTime()) && parseInt(start_date.getTime()) >= parseInt(startTime.getTime()));
  };

  /**
   * checkMinStartDate
   * @return {Boolean}
   */
  checkMinStartDate = () => {
    const {start_date} = this.state;
    if (String(start_date) === 'Invalid Date' || !start_date) return true;
    if (
      parseInt(start_date.getTime()) > parseInt(new Date().getTime()) ||
      (start_date.getDate() === new Date().getDate() && start_date.getMonth() === new Date().getMonth() && start_date.getFullYear() === new Date().getFullYear())
    ) {
      return true;
    };
    return false;
  };

  /**
   * Disallow operator to setting ticket distribution time between 23:30 and 00:30
   * @return {Boolean}
   */
  checkDistributionTime = () => {
    const {distribution_date} = this.state;
    if (String(distribution_date) === 'Invalid Date' || !distribution_date) return true;
    const utcTime = new Date(distribution_date);
    const localTime = new Date(utcTime - utcTime?.getTimezoneOffset() * 60000)?.toISOString().slice(11, 16);
    return localTime > '00:30' && localTime < '23:30';
  }

  /**
   * confirmAddTicket
   */
  confirmAddTicket = () => {
    this.setState({isSubmitForm: true});
    if (
      this.validator.allValid() &&
      this.validStartDate() &&
      String(this.state.start_date) !== 'Invalid Date' &&
      this.checkMinStartDate() &&
      this.validDistributionDate() &&
      this.checkDistributionTime()
    ) {
      this.checkMemberCode();
    }
  };

  /**
   * addTicket
   */
  addTicket = () => {
    this.setState({openConfirmAddTicketModal: false});
    const {fileCSV, start_date, distribution_date, planId} = this.state;
    const formData = new FormData();
    const zoneId = this.props.common?.country_code.find((item) => item.id === this.state.countryId)?.zone_id;
    formData.append('members', fileCSV, fileCSV.name);
    formData.append('plan_id', planId);
    formData.append('start_date_of_use', this.formatStartDateAndTime(start_date));
    formData.append('distribute_time', moment(moment.tz(format(distribution_date, 'yyyy-MM-dd HH:mm'), zoneId ? zoneId : 'Asia/Tokyo')).toISOString());
    formData.append('geofence_id', this.state.geofenceId);
    this.props.addTicket(formData).then((response) => {
      if (response && response?.status === 200) {
        this.props.setMessageModal(modalObj(true, 'ticket.add_csv_success', {count: this.state.totalMemberRecord}));
        this.setState({
          countryId: isRoleGlobal() ? '' : this.props.common?.principal?.country_id,
          geofenceId: '',
          planId: '',
          plan: {},
          listPlan: [],
          listGeofences: isRoleGlobal() ? [] : this.props.common?.all_subscription_geofence,
          isSubmitForm: false,
          start_date: '',
          distribution_date: '',
          fileCSV: '',
          totalMemberRecord: null,
        });
      } else this.props.setMessageModal(modalObj(true, response.message_code));
    });
  };

  /**
   * checkMemberCode
   */
  checkMemberCode = () => {
    const {fileCSV, start_date, distribution_date, planId} = this.state;
    const formData = new FormData();
    const zoneId = this.props.common?.country_code.find((item) => item.id === this.state.countryId)?.zone_id;
    formData.append('members', fileCSV, fileCSV.name);
    formData.append('plan_id', planId);
    formData.append('start_date_of_use', moment(moment.tz(format(start_date, 'yyyy-MM-dd 00:00'), zoneId ? zoneId : 'Asia/Tokyo')).toISOString());
    formData.append('distribute_time', moment(moment.tz(format(distribution_date, 'yyyy-MM-dd HH:mm'), zoneId ? zoneId : 'Asia/Tokyo')).toISOString());
    this.props.checkMemberCode(formData).then((response) => {
      this.setState({
        callApi: true,
        dataImport: response?.result,
        importSuccess: response?.status === 200,
      });
      if (response?.status === 200) {
        this.setState({openConfirmAddTicketModal: true, totalMemberRecord: response?.result?.length});
      } else {
        if (response?.message_code === 'error.date.validation.failed' || response?.message_code === 'error.date.not.in.past') {
          this.setState({
            callApi: false,
          });
          this.props.setMessageModal(modalObj(true, response.message_code));
        }
      }
    });
  };

  /**
   * formatStartDateAndTime
   * @param {*} startDate
   * @return {*}
   */
  formatStartDateAndTime = (startDate) => {
    startDate = add(startDate, {days: -1});
    return startDate.getFullYear() + '-' + this.convertTimeNumber(startDate.getMonth() + 1) + '-' + this.convertTimeNumber(startDate.getDate()) + this.checkCountryTimeZone(true);
  };

  /**
   * checkCountryTimeZone
   * @param {Boolean} isStart
   * @return {String}
   */
  checkCountryTimeZone = (isStart) => {
    const zone_id = this.state.plan?.zone_id || '';
    switch (zone_id) {
      case 'Asia/Saigon':
        return isStart ? 'T17:00:00.000Z' : 'T16:59:59.000Z';
      case 'Asia/Singapore':
      case 'Asia/Kuala_Lumpur':
        return isStart ? 'T16:00:00.000Z' : 'T15:59:59.000Z';
      case 'Pacific/Guam':
        return isStart ? 'T14:00:00.000Z' : 'T13:59:59.000Z';
      case 'Asia/Tokyo':
      default:
        return isStart ? 'T15:00:00.000Z' : 'T14:59:59.000Z';
    }
  };

  /**
   * check number 0 -> 9 return '0{number}'
   * @param {*} time
   * @return {*}
   */
  convertTimeNumber = (time) => {
    if (time <= 9) {
      return '0' + time;
    }
    return time;
  };

  /**
   * downloadTemplateCSV
   */
  downloadTemplateCSV = () => {
    const {t} = this.props;
    const data = [
      {
        member_code: 'ABCD1234',
      },
    ];
    const options = {
      fieldSeparator: ',',
      quoteStrings: '',
      decimalSeparator: '.',
      showLabels: true,
      filename: 'template',
      headers: [t('reservationManagement.member_id')],
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  };

  /**
   * handleCsvInput
   * @param {event} event
   */
  handleCsvInput = async (event) => {
    if (event.target.files[0]) {
      if (event.target.files[0].name.slice(-4) === CONTENT_TYPE_CSV) {
        const files = event.target.files[0];
        this.setState({fileCSV: files, callApi: false, dataImport: [], importSuccess: false});
      } else {
        this.setState({fileCSV: '', callApi: false, dataImport: [], importSuccess: false});
        this.props.setMessageModal(modalObj(true, 'errorFields.csvFile'));
      }
    } else {
      this.setState({
        fileCSV: '',
        callApi: false,
        dataImport: [],
        importSuccess: false,
      });
    }
  };

  /**
   * Render ticket distributions' notes
   * @return {HTML}
   */
  renderNotes = () => {
    const {dataImport} = this.state;
    const {t} = this.props;
    return Object.keys(dataImport || {})?.map((memberCode, index) => (
      <TableRow key={memberCode}>
        <TableCell align="center">{index + 1}</TableCell>
        <TableCell align="center">{memberCode}</TableCell>
        <TableCell align="center">
          {Object.keys(dataImport[memberCode] || {})?.map((reasonCode, idx, reasonCodes) => {
            const ordinalNum = reasonCodes?.length > 1 ? `${idx + 1}. ` : '';
            switch (reasonCode) {
              case 'api.csv.upload.member.code.duplicate':
                return <div>{`${ordinalNum}${t('api.csv.upload.member.code.duplicate')}`}</div>;
              case 'api.csv.upload.member.code.failed':
                return <div>{`${ordinalNum}${t('ticket.member_code_incorrect')}`}</div>;
              case 'reason.distribution.fail.geofence.price.duplicate':
                return (
                  <>
                    {`${ordinalNum}${t('ticket.different_discount_amount_header')}`}
                    {dataImport[memberCode][reasonCode]?.map((errorData, idx) => (
                      <Box mt={1} key={idx}>
                        {
                          t('ticket.different_discount_amount_end', {
                            Geofence: errorData.data.geofence_name,
                            Subscription_ID: errorData.data.subscription_code,
                            Adult: customDisplayCurrency(errorData.data?.adult_amount, errorData.data?.currency_code),
                            Child: customDisplayCurrency(errorData.data?.child_amount, errorData.data?.currency_code),
                          })
                        }
                      </Box>
                    ))}
                  </>
                );
              default:
                return DATA_NULL;
            };
          }, '')}
          </TableCell>
      </TableRow>
    ));
  };

  /**
   * render
   * @return {HTMLElement}
   */
  render() {
    const {t, common, business} = this.props;
    const listPlanTime =
      this.state.plan?.plan_shifts?.length > 0 ? this.formatDataHourToDate(this.state.plan?.plan_shifts, this.state.plan?.zone_id) : [{start_time: '', end_time: ''}];
    const display_times =
     this.state.plan?.display_times && this.state.plan?.display_times.length > 0 ?
     this.formatDataDetailsDisplayTime(this.state.plan.display_times[0]) : [{start_time: '', end_time: ''}];
    const use_time =
      this.state.plan?.use_time && this.state.plan?.use_time?.start_time && this.state.plan?.use_time?.end_time ?
      this.formatDataDetailsUseTime(this.state.plan.use_time) : {start_time: '', end_time: ''};
    return (
      <LoadingOverlay active={common?.isLoading || business?.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('route.business_plan_add_ticket')}</h3>
              </Grid>
            </Grid>
          </Container>
          <br></br>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('route.business_plan_add_ticket')}
                    </Grid>
                  </Grid>

                  {/* Template CSV */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={12} style={{padding: 10, paddingLeft: 15}}>
                      <Button color="primary" variant="contained" className="button_margin" endIcon={<GetAppIcon />} onClick={() => this.downloadTemplateCSV()}>
                        {t('jit.template.csv')}
                      </Button>
                    </Grid>
                  </Grid>

                  {/* Country */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.country')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'countryId',
                          }}
                          displayEmpty
                          value={this.state.countryId || ''}
                          onChange={this.onChangeCountry}
                          renderValue={
                            this.state.countryId ?
                            undefined : () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.country'),
                                    })}
                                  </div>
                                )
                          }
                          disabled={!isRoleGlobal()}
                        >
                          {common?.country_code?.map((item, index) => (
                            <MenuItem value={item.id} key={index}>
                              {t(`${item.country_code}`)}
                            </MenuItem>
                          ))}
                        </Select>
                        {this.validator.message('countryId', this.state.countryId, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.countryId, 'required') && (
                          <FormHelperText id="countryId" error>
                            {t('validation.required.choose', {field: t('common.country')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Geofence */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.geofence')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'geofenceId',
                          }}
                          displayEmpty
                          value={this.state.geofenceId || ''}
                          onChange={this.onChangeGeofence}
                          disabled={!this.state.countryId}
                          renderValue={
                            this.state.geofenceId ?
                              undefined : () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('business_plan.geofence'),
                                    })}
                                  </div>
                                )
                          }
                        >
                          {this.state.listGeofences.map((item, index) => (
                            <MenuItem value={item.geofence_id} key={index} className={!item.enable ? 'disable-option-custom' : ''}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {this.validator.message('geofenceId', this.state.geofenceId, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.geofenceId, 'required') && (
                          <FormHelperText id="geofenceId" error>
                            {t('validation.required.choose', {field: t('business_plan.geofence')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Ticket for gifts name */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('ticket.ticket_name_gift')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl variant="outlined" margin="dense" fullWidth>
                        <Box display="flex" flexDirection="column">
                          <div style={{fontSize: 13, marginBottom: 8, color: '#3f51b5'}}>({t('common.ja')})</div>
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'planId',
                            }}
                            disabled={!this.state.geofenceId}
                            displayEmpty
                            value={this.state.planId || ''}
                            onChange={this.onChangePlan}
                            renderValue={
                              this.state.planId ?
                              undefined: () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('ticket.name'),
                                      })}
                                    </div>
                                  )
                            }
                          >
                            {this.state.listPlan?.map((item, index) => (
                              <MenuItem value={item.id} key={index}>
                                {item?.plan_languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon" style={{top: 10}}></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl variant="outlined" margin="dense" fullWidth>
                        <Box display="flex" flexDirection="column">
                          <div style={{fontSize: 13, marginBottom: 8, color: '#3f51b5'}}>({t('common.vi')})</div>
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'planId',
                            }}
                            disabled={!this.state.geofenceId}
                            displayEmpty
                            value={this.state.planId || ''}
                            onChange={this.onChangePlan}
                            renderValue={
                              this.state.planId ?
                              undefined: () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('ticket.name'),
                                      })}
                                    </div>
                                  )
                            }
                          >
                            {this.state.listPlan?.map((item, index) => (
                              <MenuItem value={item.id} key={index}>
                                {item?.plan_languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon" style={{top: 10}}></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl variant="outlined" margin="dense" fullWidth>
                        <Box display="flex" flexDirection="column">
                          <div style={{fontSize: 13, marginBottom: 8, color: '#3f51b5'}}>({t('common.en')})</div>
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'planId',
                            }}
                            disabled={!this.state.geofenceId}
                            displayEmpty
                            value={this.state.planId || ''}
                            onChange={this.onChangePlan}
                            renderValue={
                              this.state.planId ? undefined : () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('ticket.name'),
                                      })}
                                    </div>
                                  )
                            }
                          >
                            {this.state.listPlan?.map((item, index) => (
                              <MenuItem value={item.id} key={index}>
                                {item?.plan_languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </Box>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6} lg={2}></Grid>
                    <Grid item xs={6} lg={2}></Grid>
                    <Grid item xs={6} lg={9} className="grid_title_padding">
                      {this.validator.message('planId', this.state.planId, 'required')}
                      {this.state.isSubmitForm && !this.validator.check(this.state.planId, 'required') && (
                        <FormHelperText id="planId" error>
                          {t('validation.required.choose', {field: t('ticket.name')})}
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>

                  {/* Description */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.description')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <TextField
                          label={<span>({t('common.ja')})</span>}
                          fullWidth
                          margin="dense"
                          disabled={true}
                          placeholder={t('placeholder.required', {field: t('business_plan.description')})}
                          rows={7}
                          multiline
                          variant="outlined"
                          value={this.state.plan?.plan_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.description || ''}
                        />
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <TextField
                          label={<span>({t('common.vi')})</span>}
                          fullWidth
                          margin="dense"
                          disabled={true}
                          placeholder={t('placeholder.required', {field: t('business_plan.description')})}
                          rows={7}
                          multiline
                          variant="outlined"
                          value={this.state.plan?.plan_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.description || ''}
                        />
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <TextField
                          label={<span>({t('common.en')})</span>}
                          fullWidth
                          margin="dense"
                          disabled={true}
                          placeholder={t('placeholder.required', {field: t('business_plan.description')})}
                          rows={7}
                          multiline
                          variant="outlined"
                          value={this.state.plan?.plan_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.description || ''}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Target */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('ticket.amount_type')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense">
                        <Select
                          variant="outlined"
                          className="field_size_20 field_min_size_300"
                          margin="dense"
                          disabled={true}
                          displayEmpty
                          renderValue={
                            this.state.plan?.ticket_data?.ticket_type ?
                            undefined : () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('ticket.amount_type'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.plan?.ticket_data?.ticket_type || ''}
                        >
                          {TICKET_AMOUNT_TYPE.map((item, idx) => {
                            return (
                              <MenuItem value={item.value} key={idx}>
                                {t(item.i18n)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Number of days */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.number_day')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <TextField
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          disabled={true}
                          placeholder={t('placeholder.required', {field: t('business_plan.number_day')})}
                          variant="outlined"
                          value={this.state.plan?.number_of_unit || this.state.plan?.number_of_unit === 0 ? this.state.plan?.number_of_unit : ''}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Ride limit */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('ticket.ride_limit')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl className="field_min_size_300">
                        <TextField
                          margin="dense"
                          type="number"
                          placeholder={t('placeholder.required', {field: t('ticket.ride_limit')})}
                          variant="outlined"
                          disabled={true}
                          value={this.state.plan?.ticket_data?.max_number_of_rides || ''}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Operating hours */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.operation_time')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={6}>
                      <TableContainer>
                        <Table>
                          <TableBody>
                            {listPlanTime?.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell style={{border: 0}}>
                                  <p style={{lineHeight: '6px'}}>{t('common.from')}</p>
                                </TableCell>
                                <TableCell align="left" style={{border: 0}}>
                                  <FormControl>
                                    <CustomTimePicker
                                      name="start_time"
                                      className="field_size_10 field_min_size_250"
                                      value={item.start_time || null}
                                      showSecond={false}
                                      disabled={true}
                                      placeholder={TIME_FORMAT}
                                      allowEmpty
                                      format={TIME_FORMAT}
                                      use12Hours={false}
                                      autoComplete="off"
                                    />
                                  </FormControl>
                                </TableCell>
                                <TableCell style={{border: 0}}>
                                  <p style={{lineHeight: '6px'}}>{t('common.to')}</p>
                                </TableCell>
                                <TableCell align="left" style={{border: 0}}>
                                  <FormControl>
                                    <CustomTimePicker
                                      name="start_time"
                                      className="field_size_10 field_min_size_250"
                                      value={item.end_time || null}
                                      showSecond={false}
                                      placeholder={TIME_FORMAT}
                                      disabled={true}
                                      allowEmpty
                                      format={TIME_FORMAT}
                                      use12Hours={false}
                                      autoComplete="off"
                                    />
                                  </FormControl>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>

                  {/* Display Time */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue ">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.display_times')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={6}>
                      <TableContainer>
                        <Table>
                          <TableBody>
                            {display_times?.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell style={{border: 0}}>
                                  <p style={{lineHeight: '6px'}}>{t('common.from')}</p>
                                </TableCell>
                                <TableCell align="left" style={{border: 0}}>
                                  <FormControl>
                                    <KeyboardDatePicker
                                      KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                      }}
                                      disableToolbar
                                      variant="inline"
                                      inputVariant="outlined"
                                      placeholder={DATE_FORMAT}
                                      autoOk
                                      disabled={true}
                                      value={item.start_time || null}
                                      format={DATE_FORMAT}
                                    />
                                  </FormControl>
                                </TableCell>
                                <TableCell style={{border: 0}}>
                                  <p style={{lineHeight: '6px'}}>{t('common.to')}</p>
                                </TableCell>
                                <TableCell align="left" style={{border: 0}}>
                                  <FormControl>
                                    <KeyboardDatePicker
                                      KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                      }}
                                      disableToolbar
                                      variant="inline"
                                      inputVariant="outlined"
                                      placeholder={DATE_FORMAT}
                                      autoOk
                                      disabled={true}
                                      value={item.end_time || null}
                                      format={DATE_FORMAT}
                                    />
                                  </FormControl>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>

                  {/* Use Time */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.use_time')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={6}>
                      <TableContainer>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell style={{border: 0}}>
                                <p style={{lineHeight: '6px'}}>{t('common.from')}</p>
                              </TableCell>
                              <TableCell align="left" style={{border: 0}}>
                                <FormControl>
                                  <KeyboardDatePicker
                                    KeyboardButtonProps={{
                                      'aria-label': 'change time',
                                    }}
                                    disableToolbar
                                    variant="inline"
                                    inputVariant="outlined"
                                    placeholder={DATE_FORMAT}
                                    autoOk
                                    ampm={false}
                                    value={use_time.start_time || null}
                                    disabled={true}
                                    format={DATE_FORMAT}
                                  />
                                </FormControl>
                              </TableCell>
                              <TableCell style={{border: 0}}>
                                <p style={{lineHeight: '6px'}}>{t('common.to')}</p>
                              </TableCell>
                              <TableCell align="left" style={{border: 0}}>
                                <FormControl>
                                  <KeyboardDatePicker
                                    KeyboardButtonProps={{
                                      'aria-label': 'change time',
                                    }}
                                    disableToolbar
                                    variant="inline"
                                    autoOk
                                    inputVariant="outlined"
                                    placeholder={DATE_FORMAT}
                                    ampm={false}
                                    value={use_time.end_time || null}
                                    disabled={true}
                                    format={DATE_FORMAT}
                                  />
                                </FormControl>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>

                  {/* Start date of use */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue" style={{paddingTop: 10, paddingBottom: 10}}>
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('ticket.start_date_time')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={10}>
                      <FormControl>
                        <KeyboardDatePicker
                          KeyboardButtonProps={{
                            'aria-label': 'change time',
                          }}
                          disableToolbar
                          minDate={new Date()}
                          variant="inline"
                          inputVariant="outlined"
                          disabled={!this.state.planId}
                          autoOk
                          value={this.state.start_date || null}
                          onChange={(start_date) => this.setState({start_date})}
                          format={DATE_FORMAT}
                          placeholder={t('placeholder.required', {field: t('ticket.start_date_time')})}
                          minDateMessage={t('errorFields.minDateMessage')}
                          invalidDateMessage={t('errorFields.invalidDateMessage')}
                        />
                        {this.validator.message('start_date', this.state.start_date, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.start_date, 'required') && (
                          <FormHelperText id="time" style={{color: 'red'}}>
                            {t('validation.required', {field: t('ticket.start_date_time')})}
                          </FormHelperText>
                        )}
                        {!this.validStartDate() && (
                          <FormHelperText id="validate_start_date_time" style={{color: 'red'}}>
                            {t('ticket.validate_start_date_time')}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Ticket distribution date */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue" style={{paddingTop: 10, paddingBottom: 10}}>
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('ticket.distribution_date')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="stretch" item xs={6} lg={10} spacing={2}>
                      <Grid item>
                        <FormControl>
                          <KeyboardDateTimePicker
                            minDate={new Date()}
                            inputVariant="outlined"
                            disabled={!this.state.planId}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            format={DATE_TIME_FORMAT}
                            name="distribution_date"
                            label={t('ticket.distribution_date')}
                            placeholder={t('placeholder.required', {field: t('ticket.distribution_date')})}
                            onChange={(distribution_date) => this.setState({distribution_date})}
                            value={this.state.distribution_date || null}
                            minDateMessage={t('errorFields.minDateMessage')}
                            invalidDateMessage={t('errorFields.invalidDateMessage')}
                          />
                          {this.validator.message('distribution_date', this.state.distribution_date, 'required')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.distribution_date, 'required') && (
                            <FormHelperText id="time" style={{color: 'red'}}>
                              {t('validation.required', {field: t('ticket.distribution_date')})}
                            </FormHelperText>
                          )}
                          {!this.validDistributionDate() && (
                            <FormHelperText id="validate_ticket_distribution_date" style={{color: 'red'}}>
                              {t('ticket.validate_ticket_distribution_date')}
                            </FormHelperText>
                          )}
                          {this.state.isSubmitForm && !this.checkDistributionTime() && (
                            <FormHelperText id="validate_ticket_distribution_time" style={{color: 'red'}}>
                              {t('ticket.ticket_distribution_date_note_time_range')}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item className="padding-top-14">
                        <span className="font-weight-600">＊{t('ticket.ticket_distribution_date_note')}</span>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Member */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue" style={{paddingTop: 10, paddingBottom: 10}}>
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('qr_code.member_list')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={6}>
                      <Grid container item xs={6} style={{alignItems: 'center'}}>
                        <Button disabled={!this.state.planId} onClick={() => this.refs.upload.click()} color="primary" variant="contained">
                          {t('common.chooseFile')}
                        </Button>
                        <span style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginLeft: '10px', width: '50%'}}>
                          {this.state.fileCSV ? this.state.fileCSV?.name : t('common.noFilesSelected')}
                        </span>
                        <input
                          disabled={!this.state.planId}
                          ref="upload"
                          className="input"
                          id="contained-button-file"
                          type="file"
                          hidden
                          accept=".csv"
                          onChange={(event) => {
                            this.handleCsvInput(event);
                            event.target.value = null;
                          }}
                        />
                        {this.state.fileCSV ? (
                          <></>
                        ) : (
                          <label htmlFor="contained-button-file">
                            <AttachFileIcon></AttachFileIcon>
                          </label>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Member list */}
                  {this.state.callApi && (
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue" style={{paddingTop: 10, paddingBottom: 10}}>
                      <Grid container alignItems="center" item xs={12} lg={12} className="grid_title_padding">
                        {!this.state.importSuccess && <p style={{color: '#dc3545'}}>{t('ticket.csv_failed')}</p>}
                      </Grid>
                      {!this.state.importSuccess && (
                        <>
                          <Grid
                            container
                            alignItems="center"
                            item
                            xs={12}
                            lg={12}
                            className="product_entry_table_header_color font_color_white font_size_small_regular search_condition_title"
                          >
                            {t('ticket.member_list_title')}
                          </Grid>
                          <Grid container alignItems="center" item xs={12} lg={12}>
                            <Table size="small" aria-label="sticky table" stickyHeader>
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center" className='width_300p'>{t('common.no')}</TableCell>
                                  <TableCell align="center" className='width_300p'>{t('reservationManagement.member_id')}</TableCell>
                                  <TableCell align="center" className='width_700p'>{t('company.note')}</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.renderNotes()}
                              </TableBody>
                            </Table>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  )}
                </Paper>
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    <Button
                      color="primary"
                      variant="contained"
                      className="button_magin"
                      endIcon={<MailIcon />}
                      disabled={!this.state.fileCSV || (this.state.callApi && !this.state.importSuccess)}
                      onClick={() => this.confirmAddTicket()}
                    >
                      {t('ticket.add')}
                    </Button>
                    <Button color="primary" variant="contained" className="button_magin button_color" style={{marginLeft: 5}} onClick={() => this.props.history.goBack()}>
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                </Grid>
                <Dialog
                  open={this.state.openConfirmAddTicketModal}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    okButtonText={`${t('common.btnConfirm')}`}
                    cancelButtonText={`${t('common.btnReturn')}`}
                    onClickOk={this.addTicket}
                    onClickCancel={() => this.setState({openConfirmAddTicketModal: false})}
                    message={t('ticket.confirm_add_ticket', {count: this.state.dataImport?.length})}
                  ></SelectModal>
                </Dialog>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>
          <br></br>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    business: state.business,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getAllSubscriptionGeofence: () => dispatch(getAllSubscriptionGeofence()),
    searchListPlan: (payload) => dispatch(searchListPlan(payload, {})),
    getDetailPlan: (id) => dispatch(getDetailPlan(id)),
    addTicket: (payload) => dispatch(addTicket(payload)),
    setMessageModal: (params) => dispatch(setMessageModal(params)),
    checkMemberCode: (payload) => dispatch(checkMemberCode(payload)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
