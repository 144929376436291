import React, {Component} from 'react';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {Card, Container, Grid, FormControl, Select, MenuItem, TextField, Button, Table, TableCell, TableRow, Paper, TableBody, TableHead} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DetailIcon from '@material-ui/icons/Description';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {ROUTE, MEMBER_TYPE_RESERVATION, DATA_NULL, ROWS_PER_PAGE_OPTIONS, BUS_RESERVATION_STATUS, PERMISSION_ACTIONS} from '../../../../common/constant';
import CustomPagination from '../../../../components/CustomPagination';
import withPermissionGateway from '../../../../hoc/withPermissionGateway';
import {getAllCountryCode, getListGeofence} from '../../../../stores/common/actions';
import {searchBusReservation} from '../../../../stores/reservation/actions';
import {changeUrlParams, getQueryStringFromObject, getUrlParams, onChangeSelect, onChangeTextField} from '../../../../utils/common';
import {formatyyyyMMDD} from '../../../../utils/date_time_utils';
import {convertDatetimeUTC, displayDateTime} from '../../../../utils/datetime';

/**
 * Reservation management view
 */
class BusReservation extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      country_id: '',
      geofence_id: '',
      company_name: '',
      route_name: '',
      reservation_date_from: null,
      reservation_date_to: null,
      pickup_date_from: null,
      pickup_date_to: null,
      drop_off_date_from: null,
      drop_off_date_to: null,
      pickup_address: '',
      drop_off_address: '',
      reservation_status: null,
      member_code: '',
      member_name: '',
      phone_number: '',
      email: '',
      reservation_number: '',

      listAllGeofence: [],
      listBusReservation: [],
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,
      orderBy: null,
      order: 'asc',
    };
  }

  /**
   * reset
   */
  reset = () => {
    this.setState({
      country_id: '',
      geofence_id: '',
      listAllGeofence: this.state.listAllGeofence,
      company_name: '',
      route_name: '',
      reservation_date_from: null,
      reservation_date_to: null,
      pickup_date_from: null,
      pickup_date_to: null,
      drop_off_date_from: null,
      drop_off_date_to: null,
      pickup_address: '',
      drop_off_address: '',
      reservation_status: null,
      member_code: '',
      member_name: '',
      phone_number: '',
      email: '',
      reservation_number: '',
      orderBy: null,
      order: 'asc',
    });
  };

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    await this.props.getAllCountryCode();
    const allGeofence = await this.props.getListGeofence({targetScreen: 'ENABLE_FOR_ADMIN_SETTING'});
    this.setState({
      listAllGeofence: allGeofence,
    });
    if (this.props.location.search) {
      const params = getUrlParams(this.props.location.search);
      if (params.reservation_date_from) {
        params.reservation_date_from = new Date(params.reservation_date_from);
      }
      if (params.reservation_date_to) {
        params.reservation_date_to = new Date(params.reservation_date_to);
      }
      if (params.pickup_date_from) {
        params.pickup_date_from = new Date(params.pickup_date_from);
      }
      if (params.pickup_date_to) {
        params.pickup_date_to = new Date(params.pickup_date_to);
      }
      if (params.drop_off_date_from) {
        params.drop_off_date_from = new Date(params.drop_off_date_from);
      }
      if (params.drop_off_date_to) {
        params.drop_off_date_to = new Date(params.drop_off_date_to);
      }
      this.setState(params);
      this.handleSearchReservation();
    }
  };

  /**
   * handleSearchReservation
   * @param {*} reset
   */
  handleSearchReservation = (reset) => {
    const {
      country_id,
      geofence_id,
      company_name,
      route_name,
      reservation_date_from,
      reservation_date_to,
      pickup_date_from,
      pickup_date_to,
      drop_off_date_from,
      drop_off_date_to,
      pickup_address,
      drop_off_address,
      reservation_status,
      member_code,
      member_name,
      phone_number,
      email,
      reservation_number,
    } = this.state;
    const params = {
      country_id,
      geofence_id: geofence_id?.geofence_id,
      company_name: company_name?.trim(),
      route_name: route_name?.trim(),
      reservation_from: reservation_date_from ? formatyyyyMMDD(reservation_date_from) : '',
      reservation_to: reservation_date_to ? formatyyyyMMDD(reservation_date_to) : '',
      pickup_from: pickup_date_from ? formatyyyyMMDD(pickup_date_from) : '',
      pickup_to: pickup_date_to ? formatyyyyMMDD(pickup_date_to) : '',
      drop_off_from: drop_off_date_from ? formatyyyyMMDD(drop_off_date_from) : '',
      drop_off_to: drop_off_date_to ? formatyyyyMMDD(drop_off_date_to) : '',
      pickup_address: pickup_address.trim(),
      drop_off_address: drop_off_address.trim(),
      status: reservation_status || null,
      member_code: member_code?.trim(),
      member_name: member_name?.trim(),
      phone_number: phone_number?.trim(),
      email: email?.trim(),
      reservation_number: reservation_number?.trim(),
    };
    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0], orderBy: null, order: 'asc'});
    const {currentPage, rowsPerPage, order, orderBy} = this.state;
    const queryParams = {...(reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage}), ...(orderBy && {sort: `${orderBy},${order}`})};
    this.props.searchBusReservation(params, queryParams).then((response) => {
      this.setState({
        listBusReservation: response?.content,
        totalRows: response?.totalSize,
      });
      const queryParamsToChange = {
        country_id,
        geofence_id: geofence_id && JSON.stringify(geofence_id),
        company_name: company_name?.trim(),
        route_name: route_name?.trim(),
        reservation_date_from: reservation_date_from ? formatyyyyMMDD(reservation_date_from) : '',
        reservation_date_to: reservation_date_to ? formatyyyyMMDD(reservation_date_to) : '',
        pickup_date_from: pickup_date_from ? formatyyyyMMDD(pickup_date_from) : '',
        pickup_date_to: pickup_date_to ? formatyyyyMMDD(pickup_date_to) : '',
        drop_off_date_from: drop_off_date_from ? formatyyyyMMDD(drop_off_date_from) : '',
        drop_off_date_to: drop_off_date_to ? formatyyyyMMDD(drop_off_date_to) : '',
        pickup_address: pickup_address.trim(),
        drop_off_address: drop_off_address.trim(),
        reservation_status,
        member_code: member_code?.trim(),
        member_name: member_name?.trim(),
        phone_number: phone_number?.trim(),
        email: email?.trim(),
        reservation_number: reservation_number?.trim(),
        currentPage: String(currentPage),
        rowsPerPage,
        order,
        orderBy,
      };
      changeUrlParams(queryParamsToChange);
      const newSearchParams = getQueryStringFromObject(queryParamsToChange);
      // Save search params into state in order to pass to next page
      this.setState({searchParams: newSearchParams});
    });
  };

  /**
   * onChangeCountry
   * @param {*} country_id
   */
  onChangeCountry = (country_id) => {
    this.setState({country_id, geofence_id: ''});
  };

  /**
   * onChangePagination
   * @param {*} currentPage
   * @param {*} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearchReservation);
  };

  /**
   * render component
   * @return {Component}
   */
  render() {
    const {t, common, actions, reservation} = this.props;
    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
    };

    return (
      <Card className="main_card_min_size ReservationManagement">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.busReservationSearch')}</h3>
            </Grid>
            <Grid container alignItems="center" justifyContent="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          {/* main search */}
          <LoadingOverlay active={reservation.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                {permission.canSearch && (
                  <>
                    <br></br>
                    <Paper className="search_table">
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                          {t('accountManagement.titleSearch')}
                        </Grid>
                      </Grid>

                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.country')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'country_id',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.country_id ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('common.country'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.country_id || ''}
                              onChange={(event) => this.onChangeCountry(event.target.value)}
                            >
                              {common?.country_code?.map((item, idx) => (
                                <MenuItem value={item.id} key={idx}>
                                  {t(`${item.country_code}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* geofence */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.geofence')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl fullWidth variant="outlined" margin="dense">
                            <Autocomplete
                              className="field_size_10 field_min_size_300"
                              options={
                                this.state.country_id ?
                                  this.state.listAllGeofence?.filter((item) => Number(item.country.id) === Number(this.state.country_id)) :
                                  this.state.listAllGeofence
                              }
                              value={this.state.geofence_id}
                              getOptionLabel={(option) => option.name || ''}
                              onChange={(event, geofence_id) => this.setState({geofence_id: geofence_id})}
                              renderInput={(params) => (
                                <TextField
                                  variant="outlined"
                                  margin="dense"
                                  {...params}
                                  name={'geofence_id'}
                                  fullWidth
                                  placeholder={t('placeholder.required_select', {
                                    field: t('common.geofence'),
                                  })}
                                />
                              )}
                              getOptionDisabled={(option) => !option.enable}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Company name */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('reservationManagement.companyName')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="company_name"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('reservationManagement.companyName')})}
                            value={this.state.company_name}
                            onChange={(event) => onChangeTextField(this, event)}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      {/* Route name */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('reservationManagement.routeName')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="route_name"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('reservationManagement.routeName')})}
                            value={this.state.route_name}
                            onChange={(event) => onChangeTextField(this, event)}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>

                      {/* Reservation date */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('delivery.reservation_date')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={6}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              autoOk
                              disableToolbar
                              variant="inline"
                              inputVariant="outlined"
                              minDate="2000-01-01"
                              placeholder={t('reservationManagement.reservation_date_from')}
                              format="yyyy/MM/dd"
                              value={this.state.reservation_date_from}
                              minDateMessage="無効な日付です"
                              maxDateMessage="無効な日付です"
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              onChange={(time) =>
                                this.setState({
                                  reservation_date_from: time,
                                })
                              }
                              invalidDateMessage={t('errorFields.invalidDateMessage')}
                            />
                            <span style={{padding: '5px'}}></span>
                            <KeyboardDatePicker
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              autoOk
                              disableToolbar
                              variant="inline"
                              placeholder={t('reservationManagement.reservation_date_to')}
                              inputVariant="outlined"
                              minDate="2000-01-01"
                              format="yyyy/MM/dd"
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              value={this.state.reservation_date_to}
                              onChange={(time) => this.setState({reservation_date_to: time})}
                              invalidDateMessage={t('errorFields.invalidDateMessage')}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Grid>

                      {/* Pickup Date */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('reservationManagement.pickup_date')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={6}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              autoOk
                              disableToolbar
                              variant="inline"
                              inputVariant="outlined"
                              minDate="2000-01-01"
                              placeholder={t('reservationManagement.pickup_date_from')}
                              format="yyyy/MM/dd"
                              value={this.state.pickup_date_from}
                              minDateMessage="無効な日付です"
                              maxDateMessage="無効な日付です"
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              onChange={(time) =>
                                this.setState({
                                  pickup_date_from: time,
                                })
                              }
                              invalidDateMessage={t('errorFields.invalidDateMessage')}
                            />
                            <span style={{padding: '5px'}}></span>
                            <KeyboardDatePicker
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              autoOk
                              disableToolbar
                              variant="inline"
                              placeholder={t('reservationManagement.pickup_date_to')}
                              inputVariant="outlined"
                              minDate="2000-01-01"
                              format="yyyy/MM/dd"
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              value={this.state.pickup_date_to}
                              onChange={(time) => this.setState({pickup_date_to: time})}
                              invalidDateMessage={t('errorFields.invalidDateMessage')}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Grid>

                      {/* Drop-off date */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('reservationManagement.dropoff_date')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={6}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              autoOk
                              disableToolbar
                              variant="inline"
                              inputVariant="outlined"
                              minDate="2000-01-01"
                              placeholder={t('reservationManagement.dropoff_date_from')}
                              format="yyyy/MM/dd"
                              value={this.state.drop_off_date_from}
                              minDateMessage="無効な日付です"
                              maxDateMessage="無効な日付です"
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              onChange={(time) =>
                                this.setState({
                                  drop_off_date_from: time,
                                })
                              }
                              invalidDateMessage={t('errorFields.invalidDateMessage')}
                            />
                            <span style={{padding: '5px'}}></span>
                            <KeyboardDatePicker
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              autoOk
                              disableToolbar
                              variant="inline"
                              placeholder={t('reservationManagement.dropoff_date_to')}
                              inputVariant="outlined"
                              minDate="2000-01-01"
                              format="yyyy/MM/dd"
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              value={this.state.drop_off_date_to}
                              onChange={(time) => this.setState({drop_off_date_to: time})}
                              invalidDateMessage={t('errorFields.invalidDateMessage')}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Grid>

                      {/* pick up address */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('reservationManagement.pickup_address_search')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="pickup_address"
                            className="field_size_10 field_min_size_500"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('reservationManagement.pickup_address_search')})}
                            variant="outlined"
                            value={this.state.pickup_address}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                        </Grid>
                      </Grid>

                      {/* drop-off address */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('reservationManagement.dropoff_address_search')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="drop_off_address"
                            className="field_size_10 field_min_size_500"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('reservationManagement.dropoff_address_search')})}
                            variant="outlined"
                            value={this.state.drop_off_address}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                        </Grid>
                      </Grid>
                      {/* Reservation status */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('reservationManagement.booking_status')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'reservation_status',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.reservation_status ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('reservationManagement.booking_status'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.reservation_status || ''}
                              onChange={(event) => onChangeSelect(this, event)}
                            >
                              {BUS_RESERVATION_STATUS.map(({id, i18n}) => (
                                <MenuItem value={id} key={id}>
                                  {t(`${i18n}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* member code - member name*/}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('business_plan.user_code')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="member_code"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('business_plan.user_code')})}
                            variant="outlined"
                            value={this.state.member_code}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('reservationManagement.memberName')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="member_name"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('reservationManagement.memberName')})}
                            variant="outlined"
                            value={this.state.member_name}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                        </Grid>
                      </Grid>

                      {/* phone_number - email*/}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.phoneNumber')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="phone_number"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('common.phoneNumber')})}
                            variant="outlined"
                            value={this.state.phone_number}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.email')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="email"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('common.email')})}
                            variant="outlined"
                            value={this.state.email}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                        </Grid>
                      </Grid>

                      {/* reservation_number */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('reservationManagement.reservation_number_search')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="reservation_number"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('reservationManagement.reservation_number_search')})}
                            value={this.state.reservation_number}
                            onChange={(event) => onChangeTextField(this, event)}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </>
                )}
                <br></br>
                <Grid container spacing={1}>
                  {permission.canSearch && (
                    <>
                      <Grid container alignItems="center" justifyContent="flex-start" item xs={3}>
                        <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.reset()} endIcon={<RotateLeftIcon />}>
                          {t('common.btnResetSearch')}
                        </Button>
                      </Grid>
                      <Grid container alignItems="center" justifyContent="flex-end" item xs={9}>
                        <Button color="primary" variant="contained" className="button_margin" endIcon={<SearchIcon />} onClick={() => this.handleSearchReservation(true)}>
                          {t('common.btnSearch')}
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
                <br></br>
              </Container>
            </Card>
          </LoadingOverlay>
          {/* end search */}
          <br></br>
          {permission.canSearch && (
            <Card raised>
              <Container maxWidth="xl">
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={6}>
                    <h3>
                      {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                    </h3>
                  </Grid>
                </Grid>
                <LoadingOverlay active={reservation.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                  {this.state.listBusReservation?.length > 0 ? (
                    <div className="scroll_area_700">
                      <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                        <TableHead>
                          <TableRow>
                            <TableCell className="width_150p ant-table-cell-fix-left white_space_nowrap">{t('reservationManagement.reservation_number_search')}</TableCell>
                            <TableCell className="width_150p">{t('business_plan.user_code')}</TableCell>
                            <TableCell className="width_150p">{t('reservationManagement.memberName')}</TableCell>
                            <TableCell className="width_150p">{t('reservationManagement.member_type')}</TableCell>
                            <TableCell className="width_150p">{t('common.geofence')}</TableCell>
                            <TableCell className="width_150p white_space_nowrap">{t('reservationManagement.reservationTime')}</TableCell>
                            <TableCell className="width_150p">{t('reservationManagement.booking_status')}</TableCell>
                            <TableCell className="width_150p white_space_nowrap">{t('reservationManagement.number_of_users')}</TableCell>
                            <TableCell className="width_150p">{t('reservationManagement.pickupTime')}</TableCell>
                            <TableCell className="width_150p">{t('reservationManagement.dropOffTime')}</TableCell>
                            {permission.canEdit && <TableCell className="width_100p" style={{position: 'sticky', right: '0'}}></TableCell>}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.listBusReservation.map((row, index) => (
                            <TableRow className="cursor_pointer" hover key={index}>
                              <TableCell className="cell_fixed_left">{!!row.reservation_number ? row.reservation_number : DATA_NULL}</TableCell>
                              <TableCell>{!!row.member_code ? row.member_code : DATA_NULL}</TableCell>
                              <TableCell>{!!row.member_name ? row.member_name : DATA_NULL}</TableCell>
                              <TableCell>
                                {row.member_type ?
                                  row.member_type === MEMBER_TYPE_RESERVATION[0] ?
                                    MEMBER_TYPE_RESERVATION[0] :
                                    MEMBER_TYPE_RESERVATION[1] :
                                  MEMBER_TYPE_RESERVATION[0]}
                              </TableCell>
                              <TableCell>{row.geofence_name || DATA_NULL}</TableCell>
                              <TableCell>{displayDateTime(convertDatetimeUTC(row?.reservation_date, row?.country_id)) || DATA_NULL}</TableCell>
                              <TableCell>{t(BUS_RESERVATION_STATUS?.find((item) => item.id === row?.status)?.i18n) || DATA_NULL}</TableCell>
                              <TableCell>{row?.number_of_users || DATA_NULL}</TableCell>
                              <TableCell>{displayDateTime(convertDatetimeUTC(row?.pickup_time, row?.country_id)) || DATA_NULL}</TableCell>
                              <TableCell>{displayDateTime(convertDatetimeUTC(row?.drop_off_time, row?.country_id)) || DATA_NULL}</TableCell>
                              {permission.canEdit && (
                                <TableCell className="cell_fixed_right">
                                  <Link
                                    style={{textDecoration: 'none'}}
                                    to={{
                                      pathname: ROUTE.LAYOUT + ROUTE.BUS_RESERVATION_DETAIL + `/${row.id}`,
                                      state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                    }}
                                  >
                                    <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                      {t('common.btnDetail')}
                                    </Button>
                                  </Link>
                                </TableCell>
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  ) : (
                    false
                  )}
                </LoadingOverlay>

                <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
              </Container>
              <br></br>
            </Card>
          )}
          <br></br>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    reservation: state.reservation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchBusReservation: (payload, queryParams) => dispatch(searchBusReservation(payload, queryParams)),
    getListGeofence: (payload) => dispatch(getListGeofence(payload)),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(BusReservation)));
