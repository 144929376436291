import React, {Component} from 'react';

import {Button, Container, Grid, Box, Card, TableContainer, Table, TableHead, TableRow, TableCell, TableBody} from '@material-ui/core';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';

import {DATA_NULL, SUCCESS_STATE, ROLES} from '../../../../common/constant';
import {getRideAdditionalHistory} from '../../../../stores/business/action';
import {customDisplayCurrency} from '../../../../utils/common';
import {displayDateTime, convertDatetimeUTC} from '../../../../utils/datetime';

/**
 * Add slot
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      isSearch: false,
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount = () => {
    this.props.getRideAdditionalHistory(this.props.subscriptionId).then(() => this.setState({isSearch: true}));
  }

  /**
   * Render
   * @return {*}
   */
  render() {
    const {t} = this.props.props;
    const {business} = this.props;
    const {ridesAdditionalHistory, isLoading} = business;

    return (
      <LoadingOverlay active={isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Container maxWidth="xl">
          <Box p={1} m={1}>
            <Card raised>
              <Container maxWidth="xl">
                <Box p={2} m={2}>
                  <Grid container alignItems="flex-start">
                    <Grid item xs={12}>
                      <h1 className="product_entry_table_header_color font_color_white font_size_mid title">
                        {t('ticket.history_additional_purchase')}
                      </h1>
                      <Grid container alignItems="flex-start" className="scroll_area_500">
                        {this.state.isSearch && (
                          <Grid item xs={12}>
                            {ridesAdditionalHistory?.length > 0 ? (
                              <TableContainer style={{paddingTop: 10}}>
                                <Table style={{width: '100%'}}>
                                  <TableHead>
                                    <TableRow>
                                      <TableCell align="center">{t('ticket.payment_code')}</TableCell>
                                      <TableCell align="center">{t('ticket.additional_purchase')}</TableCell>
                                      <TableCell align="center">{t('business_plan.price')}</TableCell>
                                      <TableCell align="center">{t('ticket.state')}</TableCell>
                                      <TableCell align="center">{t('ticket.created_date')}</TableCell>
                                      <TableCell align="center">{t('ticket.created_by')}</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {ridesAdditionalHistory?.map((item, index) => (
                                      <TableRow key={index}>
                                        <TableCell align="center">{item.payment_code || DATA_NULL}</TableCell>
                                        <TableCell align="center">
                                          {
                                            item.additional_rides || item.additional_rides === 0 ?
                                            item.additional_rides + ' ' + t('ticket.ride', {count: item.additional_rides}) :
                                            DATA_NULL
                                          }
                                        </TableCell>
                                        <TableCell align="center">
                                          {item.amount || item.amount === 0 ? customDisplayCurrency(item.amount, item.currency_code) : DATA_NULL}
                                        </TableCell>
                                        <TableCell align="center">
                                          {item.state ? t(SUCCESS_STATE.find((itm) => itm.value === item.state)?.i18n) : DATA_NULL}
                                        </TableCell>
                                        <TableCell align="center">
                                          {item.created_date ? displayDateTime(convertDatetimeUTC(item.created_date, item?.country_id)) : DATA_NULL}
                                        </TableCell>
                                        <TableCell align="center">{item.username || DATA_NULL} - {item.role ? t(ROLES.find((role) => role.id === item.role)?.i18n) : DATA_NULL}</TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            ) : <span>{t('common.noData')}</span>}
                          </Grid>
                        )}
                      </Grid>
                      <Grid container alignItems="flex-start">
                        <Grid container justify="center" alignItems="center" item xs={12} style={{paddingTop: 20}}>
                          <Button variant="contained" className="button_color button_magin" color="primary" onClick={() => this.props.onClickClose()}>
                            {t('common.btnClose')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Container>
            </Card>
          </Box>
        </Container>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    business: state.business,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRideAdditionalHistory: (id) => dispatch(getRideAdditionalHistory(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
