import React, {Component} from 'react';

import {
  Card,
  Container,
  Grid,
  Paper,
  Table,
  TableCell,
  TableRow,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  TableHead,
  TableBody,
  Dialog,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import DeleteIcon from '@material-ui/icons/Delete';
import DetailIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {ROUTE, USAGE_STATUS_COMPANY, ROWS_PER_PAGE_OPTIONS, EXPORT_LIMIT_NUMBER_ROWS, PERMISSION_ACTIONS, DATA_NULL} from '../../../common/constant';
import CSVExporter from '../../../components/CSVExporter';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import {getAllCountryCode, getListGeofenceByCountry, getAllGeofence} from '../../../stores/common/actions';
import {searchListCompany, deleteCompany} from '../../../stores/company/action';
import {setMessageModal} from '../../../stores/modal/actions';
import {onChangeSelect, onChangeTextField, changeUrlParams, getUrlParams, getQueryStringFromObject} from '../../../utils/common';
import {modalObj} from '../../../utils/modal';

/**
 * Business information
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      companyCode: '',
      companyName: '',
      areaList: [],
      listGeofenceAll: [],
      geofence_ids: [],
      country_id: '',
      id: null,
      flagDelete: false,
      message: '',
      flgDelete: false,
      status: null,
      country: '',
      result: [],
      isSearch: false,
      index: '',
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,
      flagExport: false,
      confirmedExport: false,
      latestSearchParams: null,
    };
    this.handleButtonOk = this.handleButtonOk.bind(this);
    this.handleButtonCancel = this.handleButtonCancel.bind(this);
    this.exportCSVRef = React.createRef();
    this.exportHeaders = [];
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllCountryCode();
    await this.props.getAllGeofence().then(() => this.setState({listGeofenceAll: this.props.common?.all_geofence}));
    if (this.props.location.search) {
      this.setState(getUrlParams(this.props.location.search), () => {
        this.state.country_id && this.props.getListGeofenceByCountry(this.state.country_id);
        if (this.state.country_id) {
          this.setState({
            listGeofenceAll: this.props.common?.all_geofence?.filter((e) => e.country_id === Number(this.state.country_id)),
          });
        }
        this.handleSearch();
      });
    }
    if (this.props.location.search) {
      this.setState(getUrlParams(this.props.location.search));
    }
  }
  /**
   * handleButtonOk
   */
  handleButtonOk() {
    this.setState({
      flagDelete: false,
    });
    this.props.deleteCompany(this.state.id, this.props).then(() => {
      const result = this.state.result;
      result.splice(this.state.index, 1);
    });
  }

  /**
   * handleButtonCancel
   */
  handleButtonCancel() {
    this.setState({
      flagDelete: false,
    });
  }

  /**
   * handleSearch
   * @param {bool} reset
   */
  handleSearch = (reset) => {
    const canSearch = this.props.actions.includes(PERMISSION_ACTIONS.SEARCH);
    if (!canSearch) return;
    const {status, companyName, companyCode, geofence_ids, country_id} = this.state;
    const payload = {
      show_on_mobile: status ? (status === 'ENABLE' ? true : false) : null,
      code: companyCode ? companyCode.trim() : '',
      name: companyName ? companyName.trim() : '',
      country_id: country_id ? country_id : '',
      geofence_ids: geofence_ids,
    };
    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const {currentPage, rowsPerPage} = this.state;
    const queryParams = reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage};
    this.props.searchListCompany(payload, queryParams).then((response) => {
      this.setState(
        {
          result: response?.content,
          totalRows: response?.totalSize ? response?.totalSize : 0,
          isSearch: true,
          latestSearchParams: queryParams,
        },
        () => {
          // Apply changed params into url
          const queryParamsToChange = {
            show_on_mobile: status ? (status === 'ENABLE' ? true : false) : null,
            companyCode: companyCode ? companyCode.trim() : '',
            companyName: companyName ? companyName.trim() : '',
            country_id: country_id ? country_id : null,
            geofence_ids: geofence_ids,
            currentPage: String(currentPage),
            rowsPerPage,
          };
          changeUrlParams(queryParamsToChange);
          const newSearchParams = getQueryStringFromObject(queryParamsToChange);
          // Save search params into state in order to pass to next page
          this.setState({searchParams: newSearchParams});
        },
      );
    });
  };

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearch);
  };

  /**
   * reset
   */
  reset = () => {
    this.setState({
      companyCode: '',
      status: '',
      companyName: '',
      areaList: [],
      geofence_ids: [],
      country_id: '',
      listGeofenceAll: this.props.common?.all_geofence,
      country: '',
      id: '',
      index: '',
    });
  };
  /**
   * Fetch data to export
   */
  fetchDataExport = async () => {};

  /**
   * alertExportCSV
   */
  alertExportCSV = () => {
    const {t} = this.props;
    if (this.state.totalRows > EXPORT_LIMIT_NUMBER_ROWS) {
      this.props.setMessageModal(modalObj(true, t('messageCode.exportAlertLimit')));
    } else {
      const message = t('messageCode.exportAlert');
      this.setState({flagExport: true, message});
    }
  };

  /**
   * handleButtonExportOk
   */
  handleButtonExportOk = () => {
    this.setState({flagExport: false, confirmedExport: true}, () => {
      this.exportCSVRef.current.onClickExport();
    });
  };

  /**
   * handleButtonExportCancel
   */
  handleButtonExportCancel = () => {
    this.setState({flagExport: false});
  };

  /**
   * onChangeCountry
   * @param {*} e
   */
  onChangeCountry = (e) => {
    this.setState({
      country_id: e.target.value,
      listGeofenceAll: this.props.common?.all_geofence?.filter((item) => item.country_id === e.target.value),
      geofence_ids: [],
    });
  };

  /**
   * Geofence
   * @param {*} e
   */
   onChangeGeofence = (e) => {
    const geofence_id = e.target.value;
    let geofence_ids = this.state.geofence_ids;
    if (e.target.checked) {
      geofence_ids.push(geofence_id);
    } else {
      geofence_ids = geofence_ids.filter((item) => item !== geofence_id);
    }
    this.setState({
      geofence_ids: geofence_ids,
    });
  };

  /**
   * convertGeofences
   * @param {*} list
   * @return {*}
   */
  convertGeofences = (list) => {
    return list?.map((item, index) => {
      return item ? <div key={index}>{item.geofence_name}</div> : <strike key={index}>{item.geofence_name}</strike>;
    });
  };
  /**
   * Render component
   * @return {component}
   */
  render() {
    const {t, common, actions, company} = this.props;
    const {result} = this.state;
    const permission = {
      canSearch: actions?.includes(PERMISSION_ACTIONS.SEARCH),
      canCSVOutput: actions?.includes(PERMISSION_ACTIONS.CSV_OUTPUT),
      canEdit: actions?.includes(PERMISSION_ACTIONS.EDIT),
      canDelete: actions?.includes(PERMISSION_ACTIONS.DELETE),
      canRegister: actions?.includes(PERMISSION_ACTIONS.REGISTER),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container spacing={1} className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.companyManagement')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          <Card raised>
            <Container maxWidth="xl">
              {permission.canSearch && (
                <>
                  <br></br>
                  <Paper className="search_table">
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                        {t('businessInformation.searchTitle')}
                      </Grid>
                    </Grid>
                    {/* Company Code */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('company.code')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <TextField
                          name="companyCode"
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('company.code')})}
                          variant="outlined"
                          value={this.state.companyCode}
                          onChange={(event) => onChangeTextField(this, event)}
                        />
                      </Grid>
                    </Grid>
                    {/* Company Name */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('company.name')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <TextField
                          name="companyName"
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('company.name')})}
                          variant="outlined"
                          value={this.state.companyName}
                          onChange={(event) => onChangeTextField(this, event)}
                        />
                      </Grid>
                    </Grid>

                    {/* Country */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('common.country')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'country_id',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.country_id ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('common.country'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.country_id}
                            onChange={this.onChangeCountry}
                          >
                            {common.country_code?.map((item, idx) => {
                              return (
                                <MenuItem value={item.id} key={idx}>
                                  {t(`${item.country_code}`)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* Geofence */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('business_plan.geofence')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={10}>
                        {this.state.listGeofenceAll?.map((geofence) => (
                          <FormControlLabel
                            labelPlacement="end"
                            className="geofence_ids"
                            control={
                              <Checkbox
                                value={geofence.geofence_id}
                                disabled={!geofence.enable}
                                checked={this.state.geofence_ids?.includes(geofence.geofence_id)}
                                onChange={this.onChangeGeofence}
                                className="checkbox_radio"
                              />
                            }
                            label={t(`${geofence.name}`)}
                          />
                        ))}
                      </Grid>
                    </Grid>
                    {/* Usage Status */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('company.usageStatus')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'status',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.status ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('company.usageStatus'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.status}
                            onChange={(event) => onChangeSelect(this, event)}
                          >
                            {USAGE_STATUS_COMPANY.map((item, idx) => {
                              return (
                                <MenuItem value={item.id} key={idx}>
                                  {t(`${item.i18n}`)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Paper>
                </>
              )}
              <br></br>
              <Grid container spacing={1}>
                <Grid container alignItems="center" justify="flex-start" item xs={3}>
                  {permission.canSearch && (
                    <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.reset()} endIcon={<RotateLeftIcon />}>
                      {t('common.btnResetSearch')}
                    </Button>
                  )}
                </Grid>
                <Grid container alignItems="center" justify="flex-end" item xs={9}>
                  {permission.canRegister && (
                    <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.COMPANY_ADD}}>
                      <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />}>
                        {t('common.btnRegister')}
                      </Button>
                    </Link>
                  )}
                  {permission.canCSVOutput && permission.canSearch && (
                    <>
                      <div className={this.state.totalRows <= 1000 || this.state.confirmedExport ? '' : 'hidden'}>
                        <CSVExporter
                          ref={this.exportCSVRef}
                          disabled={this.state.totalRows <= 0 || !this.state.latestSearchParams}
                          headers={this.exportHeaders}
                          fetchAction={this.fetchDataExport}
                          isFetchAsync={true}
                          screenName={t('businessInformation.searchTitle')}
                        />
                      </div>
                      {this.state.totalRows >= 1000 && !this.state.confirmedExport && (
                        <Button
                          onClick={this.alertExportCSV}
                          disabled={this.state.totalRows <= 0}
                          color="primary"
                          variant="contained"
                          className="button_margin"
                          endIcon={<GetAppIcon className="csv-exporter-icon" />}
                        >
                          {t('calendar.csv_output')}
                        </Button>
                      )}
                    </>
                  )}

                  {permission.canSearch && (
                    <Button color="primary" variant="contained" className="button_margin" onClick={() => this.handleSearch(true)} endIcon={<SearchIcon />}>
                      {t('common.btnSearch')}
                    </Button>
                  )}
                </Grid>
              </Grid>

              <br></br>
            </Container>
          </Card>
          <br></br>
          {this.state.isSearch && (
            <Card raised>
              <Container maxWidth="xl">
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={6}>
                    <h3>
                      {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                    </h3>
                  </Grid>
                  <LoadingOverlay active={company.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                    {result?.length > 0 && (
                      <div className="scroll_area_700">
                        <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                          <TableHead>
                            <TableRow>
                              <TableCell className="width_75p">{t('company.id')}</TableCell>
                              <TableCell>{t('company.code')}</TableCell>
                              <TableCell>{t('company.name')}</TableCell>
                              <TableCell>{t('common.country')}</TableCell>
                              <TableCell className="width_250p">{t('common.geofence')}</TableCell>
                              <TableCell>{t('common.status')}</TableCell>
                              {(permission.canEdit || permission.canDelete) && <TableCell>{t('common.action')}</TableCell>}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {result?.map((row, index) => {
                              const geofences = row?.geofence_users?.length > 0 ? this.convertGeofences(row?.geofence_users) : DATA_NULL;
                              return (
                                <TableRow key={index} hover className="cursor_pointer">
                                  <TableCell scope="row">{row.id}</TableCell>
                                  <TableCell>{row.code}</TableCell>
                                  <TableCell>{row.name}</TableCell>
                                  <TableCell>{t(common.country_code?.find((country) => country.id === row.country_id).country_code)}</TableCell>
                                  <TableCell>{geofences}</TableCell>
                                  <TableCell>{row.show_on_mobile ? t(USAGE_STATUS_COMPANY[0].i18n) : t(USAGE_STATUS_COMPANY[1].i18n)}</TableCell>
                                  {(permission.canEdit || permission.canDelete) && (
                                    <TableCell>
                                      {permission.canEdit && (
                                        <Link
                                          style={{textDecoration: 'none'}}
                                          to={{
                                            pathname: ROUTE.LAYOUT + ROUTE.COMPANY_DETAIL + '/' + row.id,
                                            state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                          }}
                                        >
                                          <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                            {t('common.btnEdit')}
                                          </Button>
                                        </Link>
                                      )}
                                    </TableCell>
                                  )}
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </div>
                    )}
                    <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                  </LoadingOverlay>
                </Grid>
                <br></br>
              </Container>
              <br></br>
            </Card>
          )}
          <br></br>
          <Dialog
            open={this.state.flagDelete}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal onClickOk={this.handleButtonOk} onClickCancel={this.handleButtonCancel} message={this.state.message}></SelectModal>
          </Dialog>

          {/* Export alert modal */}
          <Dialog
            open={this.state.flagExport}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal
              onClickOk={this.handleButtonExportOk}
              onClickCancel={this.handleButtonExportCancel}
              okButtonText={t('common.btnYes')}
              cancelButtonText={t('common.btnNo')}
              message={this.state.message}
            ></SelectModal>
          </Dialog>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    common: state.common,
    actions: ['SEARCH', 'REGISTER', 'DETAIL', 'DELETE', 'CSV_OUTPUT', 'UPDATE'],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchListCompany: (payload, queryParams) => dispatch(searchListCompany(payload, queryParams)),
    getListGeofenceByCountry: (id) => dispatch(getListGeofenceByCountry(id)),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    deleteCompany: (id, props) => dispatch(deleteCompany(id, props)),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getAllGeofence: () => dispatch(getAllGeofence()),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
