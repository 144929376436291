export const ACTION_TYPES = {
  SEARCH_AREA_GEOFENCE_START: 'SEARCH_AREA_GEOFENCE_START',
  SEARCH_AREA_GEOFENCE_SUCCESS: 'SEARCH_AREA_GEOFENCE_SUCCESS',
  SEARCH_AREA_GEOFENCE_FAIL: 'SEARCH_AREA_GEOFENCE_FAIL',

  GET_DETAIL_AREA_GEOFENEC_START: 'GET_DETAIL_AREA_GEOFENEC_START',
  GET_DETAIL_AREA_GEOFENEC_SUCCESS: 'GET_DETAIL_AREA_GEOFENEC_SUCCESS',
  GET_DETAIL_AREA_GEOFENEC_FAIL: 'GET_DETAIL_AREA_GEOFENEC_FAIL',
};

const initialState = {
  isLoading: false,
  listAreaGeofence: [],
  detailAreaGeofence: {},
};

/**
 * function reduce
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.SEARCH_AREA_GEOFENCE_START:
    case ACTION_TYPES.GET_DETAIL_AREA_GEOFENEC_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.SEARCH_AREA_GEOFENCE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listAreaGeofence: action.listAreaGeofence,
      };
    case ACTION_TYPES.GET_DETAIL_AREA_GEOFENEC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        detailAreaGeofence: action.detailAreaGeofence,
      };
    case ACTION_TYPES.SEARCH_AREA_GEOFENCE_FAIL:
      return {
        ...state,
        isLoading: false,
        listAreaGeofence: [],
      };
    case ACTION_TYPES.GET_DETAIL_AREA_GEOFENEC_FAIL:
      return {
        ...state,
        isLoading: false,
        detailAreaGeofence: {},
      };
    default:
      return state;
  }
}
