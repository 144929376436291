export const ACTION_TYPES = {
  SEARCH_LIST_FACILITY_START: 'SEARCH_LIST_FACILITY_START',
  SEARCH_LIST_FACILITY_SUCCESS: 'SEARCH_LIST_FACILITY_SUCCESS',
  SEARCH_LIST_FACILITY_FAIL: 'SEARCH_LIST_FACILITY_FAIL',

  CREATE_FACILITY_START: 'CREATE_FACILITY_START',
  CREATE_FACILITY_SUCCESS: 'CREATE_FACILITY_SUCCESS',
  CREATE_FACILITY_FAIL: 'CREATE_FACILITY_FAIL',

  GET_DETAIL_FACILITY_START: 'GET_DETAIL_FACILITY_START',
  GET_DETAIL_FACILITY_SUCCESS: 'GET_DETAIL_FACILITY_SUCCESS',
  GET_DETAIL_FACILITY_FAIL: 'GET_DETAIL_FACILITY_FAIL',

  UPDATE_FACILITY_START: 'UPDATE_FACILITY_START',
  UPDATE_FACILITY_SUCCESS: 'UPDATE_FACILITY_SUCCESS',
  UPDATE_FACILITY_FAIL: 'UPDATE_FACILITY_FAIL',

  DELETE_FACILITY_START: 'DELETE_FACILITY_START',
  DELETE_FACILITY_SUCCESS: 'DELETE_FACILITY_SUCCESS',
  DELETE_FACILITY_FAIL: 'DELETE_FACILITY_FAIL',

  GET_STORE_PRODUCTS_START: 'GET_STORE_PRODUCTS_START',
  GET_STORE_PRODUCTS_SUCCESS: 'GET_STORE_PRODUCTS_SUCCESS',
  GET_STORE_PRODUCTS_FAILED: 'GET_STORE_PRODUCTS_FAILED',

  UPDATE_STORE_PRODUCTS_INDEX_START: 'UPDATE_STORE_PRODUCTS_INDEX_START',
  UPDATE_STORE_PRODUCTS_INDEX_SUCCESS: 'UPDATE_STORE_PRODUCTS_INDEX_SUCCESS',
  UPDATE_STORE_PRODUCTS_INDEX_FAILED: 'UPDATE_STORE_PRODUCTS_INDEX_FAILED',
};

const initialState = {
  isLoading: false,
  listFacility: [],
  detailFacility: {},
  listProduct: [],
};

/**
 * function reduce facility
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.SEARCH_LIST_FACILITY_START:
    case ACTION_TYPES.CREATE_FACILITY_START:
    case ACTION_TYPES.GET_DETAIL_FACILITY_START:
    case ACTION_TYPES.UPDATE_FACILITY_START:
    case ACTION_TYPES.DELETE_FACILITY_START:
    case ACTION_TYPES.GET_STORE_PRODUCTS_START:
    case ACTION_TYPES.UPDATE_STORE_PRODUCTS_INDEX_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.SEARCH_LIST_FACILITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listFacility: action.listFacility,
      };
    case ACTION_TYPES.GET_DETAIL_FACILITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        detailFacility: action.detailFacility,
      };
    case ACTION_TYPES.CREATE_FACILITY_SUCCESS:
    case ACTION_TYPES.UPDATE_FACILITY_SUCCESS:
    case ACTION_TYPES.DELETE_FACILITY_SUCCESS:
    case ACTION_TYPES.CREATE_FACILITY_FAIL:
    case ACTION_TYPES.UPDATE_FACILITY_FAIL:
    case ACTION_TYPES.DELETE_FACILITY_FAIL:
    case ACTION_TYPES.SEARCH_LIST_FACILITY_FAIL:
      return {
        ...state,
        isLoading: false,
        listFacility: [],
      };
    case ACTION_TYPES.GET_DETAIL_FACILITY_FAIL:
      return {
        ...state,
        isLoading: false,
        detailFacility: {},
      };
    case ACTION_TYPES.GET_STORE_PRODUCTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listProduct: action.listProduct,
      };
    case ACTION_TYPES.GET_STORE_PRODUCTS_FAILED:
    case ACTION_TYPES.UPDATE_STORE_PRODUCTS_INDEX_SUCCESS:
    case ACTION_TYPES.UPDATE_STORE_PRODUCTS_INDEX_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
