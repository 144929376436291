import React, {Component} from 'react';

import {
  Grid,
  Button,
  TextField,
  Paper,
  Card,
  Container,
  FormControl,
  Select,
  MenuItem,
  Backdrop,
  Modal,
  FormHelperText,
  Dialog,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Box,
  Radio,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {Cancel as CancelIcon} from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import CompareArrowsIcon from '@material-ui/icons/SwapHoriz';
import {format, parse} from 'date-fns';
import {GoogleApiWrapper} from 'google-maps-react';
import {isEmpty} from 'lodash';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

import {
  ROUTE,
  VEHICLE_STATUS,
  USAGE_PATTERN,
  VEHICLE_COLOR,
  MOBI_TYPES,
  TIME_FORMAT,
  TIME_FORMAT_WITH_SECONDS,
  PERMISSION_ACTIONS,
  ODM_BOOKING_TYPE,
} from '../../../common/constant';
import CancelBookingSuccessModal from '../../../components/cancelBookingSuccessModal';
import CustomTimePicker from '../../../components/CustomTimePicker';
import ImageUpload from '../../../components/imageSelect';
import PinMap from '../../../components/map/PinMap';
import Memo from '../../../components/memo';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {checkBookingByVehicleApi} from '../../../services/businessVehicleServices';
import {getGeofence} from '../../../services/geofences';
import {
  getDetailVehicle,
  createVehicle,
  updateVehicle,
  getVehicleMobiTypes,
  getListVehicleMode,
  getVehicleModeByGeofence,
  getVehicleGeofenceAddition,
} from '../../../stores/business_vehicles/action';
import {getListSupplier, getListFacilityBySupplierID, getListGeofence} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {onChangeSelect, onChangeTextField, onChangeListData, backForwardRouter} from '../../../utils/common';
import {compareDateTimeRange, isOverlapDateRelatively} from '../../../utils/datetime';
import './styles.css';
import {modalObj} from '../../../utils/modal';
import {isRoleBusiness, isRoleCountry} from '../../../utils/role';

/**
 * Business Detail views
 */
// eslint-disable-next-line require-jsdoc
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      code: '',
      name: '',
      registration_number: '',
      status: '',
      supplier_id: '',
      supplier_facility_id: '',
      geofence_id: '',
      brand: '',
      type: '',
      vehicle_color: '',
      usage_pattern: '',
      mobi_types: [],
      usage_pattern_selections: [],
      number_of_seats: '',
      information: '',
      image_master_id: null,
      vehicle_mode: '',
      memos: [],
      listVehicleMode: [],
      startTime: null,
      endTime: null,
      lat: 0,
      lon: 0,

      isSimulationTaxi: false,
      isSubmitForm: false,
      flg: false,
      message: '',
      imageSrc: '',
      openModal: false,
      country_id: '',
      isTickedVehicleAddition: false,
      listVehicleInformation: [{title_jp: '', title_vi: '', title_en: '', description_jp: '', description_vi: '', description_en: ''}],
      isShowVehicleAddition: false,
      additionalInfos: [],
      flagDelete: false,
      indexDelete: null,
      booking_type: ODM_BOOKING_TYPE[0].id,
      prev_booking_type: '',
      bookingByVehicle: [],
      operatingHours: [
        {
          id: null,
          start_time: '',
          end_time: '',
          operation_type: 'BOOKING_NORMAL',
          default_location: false,
          default_latitude: null,
          default_longitude: null,
        },
      ],
      isTimeOverLap: false,
      overlapList: new Set(),
      centerPoint: {},
      defaultMarker: [{lat: '', lng: ''}],
      odmBookingTypes: ODM_BOOKING_TYPE,
      bookingCancelNumber: null,
      mapInfor: {},
      validLocationInGeofence: [true],
      point: {},
      indexOnChange: null,
      isValidLatLng: true,
      showWarningDialog: false,
      bookingCancelIds: [],
      isSuccessBookingCancel: false,
    };
    this.validator = new SimpleReactValidator({
      validators: {
        // Custom validation for mobi_types
        mobi_types: {
          message: '',
          rule: (val, params, validator) => {
            return (
              (this.state.usage_pattern === USAGE_PATTERN[0].id || (val !== '' && this.state.usage_pattern === USAGE_PATTERN[1].id && this.state.mobi_types.length > 0)) &&
              params.indexOf(val) === -1
            );
          },
          required: true,
        },
        latitude_type: {
          message: '',
          rule: (val) => {
            const latitudeRegex = /^-?([1-8]?[0-9]\.{1}\d{1,6}$|90\.{1}0{1,6}$)/;
            return latitudeRegex.test(val);
          },
        },
        longitude_type: {
          message: '',
          rule: (val) => {
            const longitudeRegex = /^-?((([1]?[0-7][0-9]|[1-9]?[0-9])\.{1}\d{1,6}$)|[1]?[1-8][0]\.{1}0{1,6}$)/;
            return longitudeRegex.test(val);
          },
        },
      },
    });
  }

  /**
   * Fetch detail vehicle
   * @param {Number} vehicleId
   */
  fetchDetailVehicle = (vehicleId) => {
    this.props.getDetailVehicle(vehicleId).then(
      async (result) => {
        if (!result) return;
        const areaDetail = this.props.common.geofence_list.find((e) => e.geofence_id === result.geofence_id);
        this.setState({
          id: result.id,
          code: result.vehicle_id,
          name: result.name,
          registration_number: result.registration_number,
          status: result.status,
          supplier_id: result.supplier_id ? result.supplier_id : '',
          supplier_facility_id: result.supplier_facility_id,
          geofence_id: result.geofence_id,
          brand: result.brand,
          type: result.type,
          number_of_seats: result.number_of_seats,
          information: result.information,
          image_master_id: result.image_master_id,
          imageSrc: result.url,
          memos: result.memos,
          vehicle_color: result.vehicle_color,
          usage_pattern: result.usage_pattern,
          vehicle_mode: result?.mode,
          isSimulationTaxi: areaDetail?.simulation_taxi ? true : false,
          country_id: result.country_id,
          isShowVehicleAddition: result?.show_additional_info !== null,
          isTickedVehicleAddition: result?.show_additional_info !== null ? result?.show_additional_info : false,
          additionalInfos: result?.additional_infos || [],
        });
        if (result.mobi_type?.split(',').includes('SHUTTLE_BUS_ON_DEMAND')) {
          const odmBookingTypes = areaDetail?.odm_config && areaDetail?.odm_config?.advance_booking_supported === true ? ODM_BOOKING_TYPE : [ODM_BOOKING_TYPE[0]];
          await getGeofence(result.geofence_id).then((response) => {
            if (response?.result) {
              const opacityHex = response?.result?.bg_color.slice(1, 3);
              const opacityHexBorder = response?.result?.border_color.slice(1, 3);
              const rawOpacity = Math.round((parseInt(opacityHex, 16) / 255) * 100) || 0;
              const rawOpacityBorder = Math.round((parseInt(opacityHexBorder, 16) / 255) * 100) || 0;
              this.setState({
                mapInfor: {
                  bgColor: `#${response?.result?.bg_color?.slice(-6)}`,
                  borderColor: `#${response?.result?.border_color?.slice(-6)}`,
                  opacity: Number(rawOpacity) || 0,
                  geometry: response?.result?.geometry,
                  strokeOpacity: Number(rawOpacityBorder) || 0,
                },
              });
            }
          });
          this.setState({
            validLocationInGeofence: result.operation_sessions.map(() => true),
            operatingHours: result.operation_sessions.map((session) => ({
              id: session.id,
              start_time: parse(session.start_time, TIME_FORMAT_WITH_SECONDS, new Date()),
              end_time: parse(session.end_time, TIME_FORMAT_WITH_SECONDS, new Date()),
              operation_type: session.operation_type,
              default_latitude: session?.default_latitude ? session?.default_latitude.toFixed(6) : null,
              default_longitude: session?.default_longitude ? session?.default_longitude.toFixed(6) : null,
              default_location: session.default_location,
            })),
            centerPoint: {lat: areaDetail?.centroid?.coordinates[1], lng: areaDetail?.centroid?.coordinates[0]},
            defaultMarker: result.operation_sessions.map((session) => ({lat: Number(session.default_latitude), lng: Number(session.default_longitude)})),
            odmBookingTypes,
            mobi_types: result.mobi_type ? result.mobi_type?.split(',') : [],
          });
        } else {
          this.setState({
            startTime: parse(result.start_time, TIME_FORMAT_WITH_SECONDS, new Date()),
            endTime: parse(result.end_time, TIME_FORMAT_WITH_SECONDS, new Date()),
            mobi_types: result.mobi_type ? result.mobi_type?.split(',') : [],
            odmBookingTypes: [],
          });
        }

        // handle Vehicle Additional Information
        result.additional_infos.length > 0 && this.setState({listVehicleInformation: this.mappingDataAdditional(result.additional_infos)});

        this.getUsagePatternSelections(result.geofence_id).then((usage_pattern_selections) => {
          this.setState({usage_pattern_selections});
        });
        this.props.getListFacilityBySupplierID(result.supplier_id);
        this.props.getVehicleModeByGeofence(result.geofence_id).then((response) => this.setState({listVehicleMode: response}));
      },
      () => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.VEHICLE_INFORMATION),
    );
  };

  /**
   * componentDidMount
   */
  componentDidMount() {
    const {principal} = this.props;
    this.props.getListSupplier();
    this.props.getListGeofence(this.props.match.params.id && {targetScreen: 'ENABLE_FOR_ADMIN_ALL'}).then((response) => {
      if (isRoleBusiness()) {
        this.setState({listGeofences: response.filter((item) => principal.geofence_ids.includes(item.geofence_id))});
      } else {
        this.setState({listGeofences: isRoleCountry() ? response.filter((item) => item.country.id === principal.country_id) : response});
      }
      if (this.props.match.params.id) {
        this.fetchDetailVehicle(this.props.match.params.id);
      }
    });
    this.props.getListVehicleMode();
    if (isRoleBusiness()) {
      this.setState(
        {
          supplier_id: principal.supplier_id,
        },
        () => {
          this.props.getListFacilityBySupplierID(this.state.supplier_id);
        },
      );
    }
  }

  /**
   * changeSupplier
   * @param {event} event
   */
  changeBusiness = (event) => {
    this.setState(
      {
        supplier_id: event.target.value,
        supplier_facility_id: '',
        geofence_id: '',
        listGeofences: [],
      },
      () => {
        this.props.getListFacilityBySupplierID(this.state.supplier_id);
      },
    );
  };

  /**
   * handle detail
   * @param {int} id
   */
  handleDetail = (id) => {
    this.setState({
      openModal: true,
    });
  };

  /**
   * handleButtonCloseModal
   */
  handleButtonCloseModal = () => {
    this.setState({
      openModal: false,
    });
  };

  /**
   * checkBooking
   */
  checkBooking = async () => {
    const payload = {
      vehicle_id: this.state.id,
      vehicle_operation_sessions: this.state.operatingHours.map((session) => ({
        id: session.id,
        start_time: format(session.start_time, TIME_FORMAT),
        end_time: format(session.end_time, TIME_FORMAT),
        operation_type: session.operation_type,
      })),
    };
    const response = await checkBookingByVehicleApi(payload);
    if (response) {
      if (response?.result?.length > 0) {
        const bookingCancelIds = response?.result;
        this.setState({showWarningDialog: true, bookingCancelNumber: response?.result?.length, bookingCancelIds});
      } else {
        this.setState({
          flg: true,
          message: 'messageCode.updateConfirmVehicle',
          bookingCancelNumber: null,
          bookingCancelIds: [],
          isSuccessBookingCancel: false,
        });
      }
    }
  };

  /**
   * handleButtonOk
   * @param {object} formData
   */
  handleButtonUpdateOk = () => {
    this.setState({
      flg: false,
      showWarningDialog: false,
    });
    const {listVehicleInformation, isShowVehicleAddition, isTickedVehicleAddition} = this.state;
    const additional_infos = [];
    listVehicleInformation.forEach((item) => {
      const languages = [
        {
          language_id: 1,
          title: item.title_jp.trim(),
          description: item.description_jp.trim(),
        },
        {
          language_id: 3,
          title: item.title_vi.trim(),
          description: item.description_vi.trim(),
        },
        {
          language_id: 2,
          title: item.title_en.trim(),
          description: item.description_en.trim(),
        },
      ];
      additional_infos.push({
        languages: languages,
        id: item?.id || null,
      });
    });
    if (this.props.match.params.id) {
      const payload = {
        id: this.state.id,
        supplier_facility_id: this.state.supplier_facility_id,
        geofence_id: this.state.geofence_id,
        status: this.state.status,
        brand: this.state.brand.trim(),
        name: this.state.name.trim(),
        information: this.state.information.trim(),
        registration_number: this.state.registration_number.trim(),
        number_of_seats: this.state.number_of_seats,
        image_master_id: this.state.image_master_id,
        memos: this.state.memos,
        note: this.state.note?.trim(),
        vehicle_color: this.state.vehicle_color,
        vehicle_mode: this.state.vehicle_mode ? this.state.vehicle_mode : null,
        show_additional_info: isShowVehicleAddition ? isTickedVehicleAddition : null,
        additional_infos: isShowVehicleAddition && isTickedVehicleAddition ? additional_infos : [],
      };
      if (this.state.mobi_types.includes('SHUTTLE_BUS_ON_DEMAND')) {
        payload['vehicle_operation_sessions'] = this.state.operatingHours.map((session) => ({
          id: session.id,
          start_time: format(session.start_time, TIME_FORMAT),
          end_time: format(session.end_time, TIME_FORMAT),
          operation_type: session.operation_type,
          default_latitude: session.default_latitude,
          default_longitude: session.default_longitude,
          default_location: session.default_location,
        }));
        payload['any_assigned_bookings'] = this.state.bookingCancelNumber ? true : false;
      } else {
        payload['start_time'] = format(this.state.startTime, TIME_FORMAT);
        payload['end_time'] = format(this.state.endTime, TIME_FORMAT);
      }
      this.props.updateVehicle(payload, this.props).then((response) => {
        if (response?.status === 200) {
          // check display dialog success booking cancel
          if (this.state.bookingCancelIds?.length > 0) {
            this.setState({isSuccessBookingCancel: true});
          } else {
            this.props.setMessageModal(modalObj(true, response?.message_code));
          }
          this.fetchDetailVehicle(this.props.match.params.id);
        }
      });
    } else {
      const {
        supplier_facility_id,
        geofence_id,
        status,
        brand,
        name,
        information,
        registration_number,
        number_of_seats,
        image_master_id,
        vehicle_color,
        usage_pattern,
        mobi_types,
        vehicle_mode,
        startTime,
        endTime,
        lat,
        lon,
      } = this.state;
      if (this.validator.allValid() && compareDateTimeRange(this.state.startTime, this.state.endTime, false)) {
        const payload = {
          supplier_facility_id,
          geofence_id: geofence_id,
          status,
          brand: brand.trim(),
          name: name.trim(),
          information: information.trim(),
          registration_number: registration_number.trim(),
          number_of_seats,
          image_master_id,
          vehicle_color,
          usage_pattern,
          mobi_types,
          vehicle_mode,
          lat: lat,
          lon: lon,
          show_additional_info: isShowVehicleAddition && isTickedVehicleAddition,
          additional_infos: isShowVehicleAddition && isTickedVehicleAddition ? additional_infos : [],
        };
        if (this.state.mobi_types.includes('SHUTTLE_BUS_ON_DEMAND')) {
          payload['vehicle_operation_sessions'] = this.state.operatingHours.map((session) => ({
            id: session.id,
            start_time: format(session.start_time, TIME_FORMAT),
            end_time: format(session.end_time, TIME_FORMAT),
            operation_type: session.operation_type,
            default_latitude: session.default_latitude,
            default_longitude: session.default_longitude,
            default_location: session.default_location,
          }));
        } else {
          payload['start_time'] = format(startTime, TIME_FORMAT);
          payload['end_time'] = format(endTime, TIME_FORMAT);
        }
        this.props.createVehicle(payload, this.props);
      }
    }
  };

  /**
   * handleButtonCancel
   */
  handleButtonUpdateCancel() {
    this.setState({
      flg: false,
    });
  }

  /**
   * handleCreateVehicle
   */
  handleCreateVehicle = () => {
    this.setState({
      isSubmitForm: true,
    });
    const isCheckLatLongDefault = this.state.operatingHours.every((operating) => {
      return (operating.default_latitude && operating.default_longitude) || (!operating.default_latitude && !operating.default_longitude);
    });

    const isCheckOperatingHours = this.state.mobi_types.includes('SHUTTLE_BUS_ON_DEMAND') ?
      isCheckLatLongDefault &&
        this.state.isValidLatLng &&
        this.validateOperatingTimeRanges() &&
        (this.state.overlapList.size > 0 ? false : true) &&
        this.checkTimeRange() &&
        !this.state.validLocationInGeofence.includes(false) :
      true;
    if (
      this.validator.allValid() &&
      isCheckOperatingHours &&
      compareDateTimeRange(this.state.startTime, this.state.endTime, false) &&
      (this.state.isShowVehicleAddition && this.state.isTickedVehicleAddition ? this.validateVehicleAdditional() : true)
    ) {
      this.setState({
        flg: true,
        message: 'messageCode.createConfirmVehicle',
      });
    }
  };

  /**
   * validateVehicleAdditional
   * @return {*}
   */
  validateVehicleAdditional = () => {
    const index = this.state.listVehicleInformation.findIndex((item) => {
      return !!!(item.title_jp.trim() && item.title_vi.trim() && item.title_en.trim() && item.description_jp.trim() && item.description_vi.trim() && item.description_en.trim());
    });
    return !(index > -1);
  };

  /**
   * handleUpdateVehicle
   */
  handleUpdateVehicle = () => {
    this.setState({
      isSubmitForm: true,
    });
    const isCheckLatLongDefault = this.state.operatingHours.every((operating) => {
      return (operating.default_latitude && operating.default_longitude) || (!operating.default_latitude && !operating.default_longitude);
    });
    const isCheckOperatingHours = this.state.mobi_types.includes('SHUTTLE_BUS_ON_DEMAND') ?
      isCheckLatLongDefault &&
        this.state.isValidLatLng &&
        this.validateOperatingTimeRanges() &&
        (this.state.overlapList.size > 0 ? false : true) &&
        this.checkTimeRange() &&
        !this.state.validLocationInGeofence.includes(false) :
      true;
    if (
      this.validator.allValid() &&
      isCheckOperatingHours &&
      compareDateTimeRange(this.state.startTime, this.state.endTime, false) &&
      (this.state.isShowVehicleAddition && this.state.isTickedVehicleAddition ? this.validateVehicleAdditional() : true)
    ) {
      if (this.state.odmBookingTypes?.length > 1) {
        this.checkBooking();
      } else {
        this.setState({
          flg: true,
          message: 'messageCode.updateConfirmVehicle',
        });
      }
    }
  };

  /**
   *
   * @param {boolean} value
   * @param {string} item
   */
  handleSelectMobiTypes = async (value, item) => {
    const mobi_types_copy = this.state.mobi_types;
    const index = mobi_types_copy.indexOf(item);
    value ? mobi_types_copy.push(item) : mobi_types_copy.splice(index, 1);
    const operatingHours = this.state.operatingHours;
    if (mobi_types_copy.includes('SHUTTLE_BUS_ON_DEMAND')) {
      await getGeofence(this.state.geofence_id).then((response) => {
        if (response?.result) {
          const opacityHex = response?.result?.bg_color.slice(1, 3);
          const opacityHexBorder = response?.result?.border_color.slice(1, 3);
          const rawOpacity = Math.round((parseInt(opacityHex, 16) / 255) * 100) || 0;
          const rawOpacityBorder = Math.round((parseInt(opacityHexBorder, 16) / 255) * 100) || 0;
          this.setState({
            mapInfor: {
              bgColor: `#${response?.result?.bg_color?.slice(-6)}`,
              borderColor: `#${response?.result?.border_color?.slice(-6)}`,
              opacity: Number(rawOpacity) || 0,
              geometry: response?.result?.geometry,
              strokeOpacity: Number(rawOpacityBorder) || 0,
            },
          });
        }
      });
      this.setState({
        operatingHours: operatingHours.map((operating) => ({
          id: null,
          start_time: operating.start_time,
          end_time: operating.end_time,
          default_latitude: '',
          default_longitude: '',
          default_location: false,
          operation_type: ODM_BOOKING_TYPE[0].id,
        })),
        validLocationInGeofence: operatingHours.map(() => true),
      });
    }
    this.setState({
      mobi_types: mobi_types_copy,
    });
  };

  /**
   * getUsagePatternSelections
   * @param {*} geofence_id
   */
  getUsagePatternSelections = async (geofence_id) => {
    const param = {geofenceId: geofence_id};
    return this.props.getVehicleMobiTypes(param, this.props).then((response) => {
      const usage_pattern_selections = [];
      const hasTaxi = response.includes(USAGE_PATTERN[0].id);
      // Check has TAXI
      if (hasTaxi) {
        usage_pattern_selections.push(USAGE_PATTERN[0]);
      }
      // Check has MOBI
      if ((hasTaxi && response?.length >= 2) || (!hasTaxi && response?.length > 0)) {
        usage_pattern_selections.push(USAGE_PATTERN[1]);
      }
      return usage_pattern_selections;
    });
  };

  /**
   * onChangeFacilityName
   * @param {*} facilityId
   */
  onChangeFacilityName = (facilityId) => {
    const {common} = this.props;
    const listGeofences = common.facility_list.find((item) => item.id === facilityId)?.geofence_ids;
    this.setState({
      listGeofences: common.geofence_list.filter((item) => listGeofences?.includes(item.geofence_id)),
      geofence_id: '',
      supplier_facility_id: facilityId,
    });
  };

  /**
   * handle get center polygon
   * @param {event} event
   */
  handleGetCenterPolygon = (event) => {
    const geofenceId = event.target.value;
    const areaDetail = this.props.common.geofence_list.find((e) => e.geofence_id === geofenceId);

    this.setState({geofence_id: geofenceId}, () => {
      this.getUsagePatternSelections(this.state.geofence_id).then((usage_pattern_selections) => {
        this.setState({mobi_types: []});
        this.setState({usage_pattern_selections, usage_pattern: ''});
      });
    });

    onChangeSelect(this, event);
    // const {google} = this.props;
    // const target_geofence = this.props.common.geofence_list?.find((e) => e.geofence_id === geofenceId).geometry;
    // if (target_geofence.type === 'MultiPolygon') {
    //   target_geofence.coordinates.forEach((element) => {
    //     const pathPolygon = element[0].map((item) => new google.maps.LatLng(item[1], item[0]));
    //     google.maps.Polygon.prototype.my_getBounds = function() {
    //       const bounds = new google.maps.LatLngBounds();
    //       pathPolygon.forEach(function(element) {
    //         bounds.extend(element);
    //       });
    //       return bounds;
    //     };
    //   });
    // } else {
    //   const pathPolygon = target_geofence.coordinates[0].map((item) => new google.maps.LatLng(item[1], item[0]));
    //   google.maps.Polygon.prototype.my_getBounds = function() {
    //     const bounds = new google.maps.LatLngBounds();
    //     pathPolygon.forEach(function(element) {
    //       bounds.extend(element);
    //     });
    //     return bounds;
    //   };
    // }
    const odmBookingTypes = areaDetail?.odm_config && areaDetail?.odm_config?.advance_booking_supported === true ? ODM_BOOKING_TYPE : [ODM_BOOKING_TYPE[0]];
    this.setState({
      lat: areaDetail?.centroid?.coordinates[1],
      lon: areaDetail?.centroid?.coordinates[0],
      centerPoint: {lat: areaDetail?.centroid?.coordinates[1], lng: areaDetail?.centroid?.coordinates[0]},
      odmBookingTypes,
    });
    this.setState({isSimulationTaxi: !!areaDetail?.simulation_taxi});

    this.props.getVehicleModeByGeofence(geofenceId).then((response) => this.setState({listVehicleMode: response}));
    this.props.getVehicleGeofenceAddition(geofenceId).then((response) => this.setState({isShowVehicleAddition: response, isTickedVehicleAddition: false}));
  };

  /**
   * onChangeListVehicleData
   * @param {*} fieldName
   * @param {*} value
   * @param {*} index
   */
  onChangeListVehicleData = (fieldName, value, index) => {
    const listVehicleInformationCopy = [...this.state.listVehicleInformation];
    listVehicleInformationCopy[index][fieldName] = value;
    this.setState({listVehicleInformation: listVehicleInformationCopy});
  };

  /**
   * addBoxPlan
   * @param {*} index
   */
  addBoxPlan = (index) => {
    const listVehicleInformationCopy = [...this.state.listVehicleInformation];
    const item = {title_jp: '', title_vi: '', title_en: '', description_jp: '', description_vi: '', description_en: ''};
    listVehicleInformationCopy.splice(index + 1, 0, item);
    this.setState({listVehicleInformation: listVehicleInformationCopy});
  };

  /**
   * deleteBoxPlan
   * @param {*} index
   */
  deleteBoxPlan = (index) => {
    const listVehicleInformationCopy = [...this.state.listVehicleInformation];
    listVehicleInformationCopy.splice(index, 1);
    this.setState({listVehicleInformation: listVehicleInformationCopy, flagDelete: false});
  };

  /**
   * onClickVehicleAddition
   * @param {*} event
   */
  onClickVehicleAddition = (event) => {
    this.setState({isTickedVehicleAddition: event.target.checked});
    if (event.target.checked === true) {
      const listAdditionalInfos = [{title_jp: '', title_vi: '', title_en: '', description_jp: '', description_vi: '', description_en: ''}];
      const listVehicleInformation = this.props.match.params.id ?
        this.state.additionalInfos.length > 0 ?
          this.mappingDataAdditional(this.state.additionalInfos) :
          listAdditionalInfos :
        listAdditionalInfos;
      this.setState({
        listVehicleInformation,
      });
    }
  };

  /**
   * mappingDataAdditional
   * @param {*} additional_infos
   * @return {*}
   */
  mappingDataAdditional = (additional_infos) => {
    const listVehicleInformation = [];
    additional_infos.forEach((item) => {
      const listLanguage = item.languages;
      const language_jp = listLanguage.find((item) => item.language_id === 1);
      const language_vi = listLanguage.find((item) => item.language_id === 3);
      const language_en = listLanguage.find((item) => item.language_id === 2);
      listVehicleInformation.push({
        title_jp: language_jp?.title || '',
        title_vi: language_vi?.title || '',
        title_en: language_en?.title || '',
        description_jp: language_jp?.description || '',
        description_vi: language_vi?.description || '',
        description_en: language_en?.description || '',
        id: item.id,
      });
    });
    return listVehicleInformation;
  };

  /**
   * handleDelete
   * @param {*} index
   */
  handleDelete = (index) => {
    const additional = {...this.state.listVehicleInformation[index]};
    const checkDelete = !!(
      additional.title_jp.trim() ||
      additional.title_vi.trim() ||
      additional.title_en.trim() ||
      additional.description_jp.trim() ||
      additional.description_vi.trim() ||
      additional.description_en.trim()
    );
    checkDelete ?
      this.setState({
          flagDelete: true,
          message: 'businessVehicle.delete_additional',
          indexDelete: index,
        }) :
      this.deleteBoxPlan(index);
  };

  /**
   * handleDeleteCancel
   */
  handleDeleteCancel = () => {
    this.setState({flagDelete: false});
  };

  /**
   * addItem
   */
  addItem = async () => {
    if (this.state.operatingHours?.length > 4) {
      return;
    }
    this.setState({
      defaultMarker: [
        ...this.state.defaultMarker,
        {
          lat: '',
          lng: '',
        },
      ],
      validLocationInGeofence: [...this.state.validLocationInGeofence, true],
      operatingHours: [
        ...this.state.operatingHours,
        {
          id: null,
          start_time: '',
          end_time: '',
          operation_type: 'BOOKING_NORMAL',
          default_location: false,
          default_latitude: null,
          default_longitude: null,
        },
      ],
    });
  };

  /**
   * changeOperatingHour
   * @param {*} index
   * @param {*} value
   * @param {*} isStart
   */
  changeOperatingHour = async (index, value, isStart) => {
    const operatingHours = this.state.operatingHours;
    isStart ? (operatingHours[index].start_time = value || '') : (operatingHours[index].end_time = value || '');
    this.setState({
      operatingHours,
    });
    this.validateOperatingTimeRanges();
  };

  /**
   * onChangeDefaultLatLng
   * @param {*} index
   * @param {*} value
   * @param {*} isLatitude
   */
  onChangeDefaultLatLng = async (index, value, isLatitude) => {
    const operatingHours = this.state.operatingHours;
    isLatitude ? (operatingHours[index].default_latitude = value || '') : (operatingHours[index].default_longitude = value || '');
    this.setState({
      operatingHours,
    });
    const isValid =
      this.validator.check(isLatitude ? value : operatingHours[index].default_latitude, 'latitude_type') &&
      this.validator.check(isLatitude ? operatingHours[index].default_longitude : value, 'longitude_type');
    if (isValid) {
      const point = this.state.point;
      point.lat = this.state.operatingHours[index].default_latitude;
      point.lng = this.state.operatingHours[index].default_longitude;
      point.index = index;
      this.setState((prevState) => ({point: {...prevState.point}, isValidLatLng: true}));
    } else {
      this.setState({isValidLatLng: false});
    }
  };

  /**
   * handleDisplayDefaultLocation
   * @param {boolean} isCheck
   * @param {Number} index
   */
  handleDisplayDefaultLocation(isCheck, index) {
    const operatingHours = this.state.operatingHours;
    operatingHours[index].default_location = isCheck;
    if (!isCheck) {
      this.handleClearLocation(index);
    }
    this.setState({operatingHours});
  }

  /**
   * deleteRows
   * @param {Number} index
   */
  deleteRows = (index) => {
    const operatingHours = this.state.operatingHours;
    const validLocationInGeofence = this.state.validLocationInGeofence;
    operatingHours.splice(index, 1);
    validLocationInGeofence.splice(index, 1);
    this.setState({operatingHours, validLocationInGeofence});
  };

  /**
   * onChangeBookingType
   * @param {*} value
   * @param {Number} index
   */
  onChangeBookingType = (value, index) => {
    const operatingHours = this.state.operatingHours;
    if (value) {
      operatingHours[index].operation_type = value;
    }
    if (value === ODM_BOOKING_TYPE[0].id) {
      const operatingHours = this.state.operatingHours;
      operatingHours[index].default_latitude = '';
      operatingHours[index].default_longitude = '';
      operatingHours[index].default_location = false;
      this.setState({
        operatingHours,
        defaultMarker: [{lat: '', lng: ''}],
        point: {lat: '', lng: ''},
        validLocationInGeofence: operatingHours.map(() => true),
      });
    }
    this.setState({operatingHours});
  };

  /**
   * check number 0 -> 9 return '0{number}'
   * @param {*} time
   * @return {*}
   */
  convertTimeNumber = (time) => {
    if (time <= 9) {
      return '0' + time;
    }
    return time;
  };

  /**
   * Validate operating time ranges and return true if time ranges are all valid
   * @return {boolean}
   */
  validateOperatingTimeRanges = () => {
    const {operatingHours} = this.state;
    const overlapList = new Set();
    for (let i = 0; i < operatingHours?.length - 1; i++) {
      const start1 = operatingHours[i]?.start_time ?
        this.convertTimeNumber(operatingHours[i]?.start_time?.getHours()) + ':' + this.convertTimeNumber(operatingHours[i]?.start_time?.getMinutes()) :
        '';
      const end1 = operatingHours[i]?.end_time ?
        this.convertTimeNumber(operatingHours[i]?.end_time?.getHours()) + ':' + this.convertTimeNumber(operatingHours[i]?.end_time?.getMinutes()) :
        '';
      for (let j = i + 1; j < operatingHours?.length; j++) {
        const start2 = operatingHours[j]?.start_time ?
          this.convertTimeNumber(operatingHours[j]?.start_time?.getHours()) + ':' + this.convertTimeNumber(operatingHours[j]?.start_time?.getMinutes()) :
          '';
        const end2 = operatingHours[j]?.end_time ?
          this.convertTimeNumber(operatingHours[j]?.end_time?.getHours()) + ':' + this.convertTimeNumber(operatingHours[j]?.end_time?.getMinutes()) :
          '';
        if (isOverlapDateRelatively(start1.toString(), end1.toString(), start2.toString(), end2.toString())) {
          overlapList.add(i);
          overlapList.add(j);
        }
        // if (new Date('2023/01/01 ' + end1.toString()).getTime() === new Date('2023/01/01 ' + start2.toString()).getTime() && end2 && start1) {
        //   overlapList.add(i);
        //   overlapList.add(j);
        // }
      }
    }
    this.setState({overlapList});
    return overlapList.size === 0;
  };

  /**
   * checkTimeRange
   * @return {Boolean}
   */
  checkTimeRange = () => {
    return (
      this.state.operatingHours.every((item) => compareDateTimeRange(item.start_time, item.end_time, true)) &&
      !this.state.operatingHours.some((item) => {
        return format(item.start_time || new Date(), TIME_FORMAT) === format(item.end_time || new Date(), TIME_FORMAT);
      })
    );
  };

  /**
   * getLatLngData
   * @param {object} coordinate
   * @param {number} index
   */
  getLatLngData = (coordinate, index) => {
    if (coordinate) {
      const operatingHours = this.state.operatingHours;
      operatingHours[index].default_latitude = coordinate.lat.toFixed(6);
      operatingHours[index].default_longitude = coordinate.lng.toFixed(6);
      this.setState({operatingHours, point: {lat: Number(coordinate.lat.toFixed(6)), lng: Number(coordinate.lng.toFixed(6)), index: index}, isValidLatLng: true});
    }
  };

  /**
   * getLatLngData
   * @param {boolean} isError
   * @param {number} index
   */
  handleMessageError = (isError, index) => {
    const validLocationInGeofence = this.state.validLocationInGeofence;
    // check maximum update depth exceeded
    if (validLocationInGeofence[index] !== isError) {
      validLocationInGeofence[index] = isError;
      this.setState({validLocationInGeofence});
    }
  };

  /**
   * handleSearchLocation
   * @param {number} index
   */
  handleClearLocation = (index) => {
    const operatingHours = this.state.operatingHours;
    operatingHours[index].default_latitude = '';
    operatingHours[index].default_longitude = '';
    this.setState({
      operatingHours,
      defaultMarker: [{lat: '', lng: ''}],
      point: {lat: '', lng: '', index: index},
      validLocationInGeofence: operatingHours.map(() => true),
    });
  };

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {common, businessVehicle, t, actions} = this.props;
    const payload = this.state;
    const isEditForm = !!this.props.match.params.id;

    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
      canJitDetailSetting: actions.includes(PERMISSION_ACTIONS.JIT_DETAIL_SETTING),
      canStopShiftJit: actions.includes(PERMISSION_ACTIONS.STOP_SHIFT_JIT),
      canVirtualVehicle: actions.includes(PERMISSION_ACTIONS.VIRTUAL_VEHICLE),
      canJitSpecialSetting: actions.includes(PERMISSION_ACTIONS.JIT_SPECIAL_SETTING),
    };

    return (
      <LoadingOverlay active={businessVehicle.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={this.state.openModal}
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '1000',
              }}
            >
              <ImageUpload
                onClickOk={this.handleButtonCloseModal}
                onClickClose={this.handleButtonCloseModal}
                selectImage={(value) => this.setState({image_master_id: value.id, imageSrc: value.url})}
              ></ImageUpload>
            </Modal>
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{this.props.match.params.id ? t('businessVehicle.detail') : t('businessVehicle.add')}</h3>
              </Grid>
            </Grid>
          </Container>
          <br></br>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('businessVehicle.form')}
                    </Grid>
                  </Grid>
                  {/* Vehicle Code */}
                  {this.props.match.params.id && (
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('businessVehicle.code_id')}
                      </Grid>
                      <Grid container alignItems="center" item xs={4} lg={4}>
                        <TextField
                          name="code"
                          className="field_size_10 field_min_size_225"
                          margin="dense"
                          inputProps={{maxLength: 20, readOnly: true}}
                          disabled
                          value={payload.code}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  )}
                  {/* Vehicle Status */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessVehicle.status')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense">
                        <Select
                          variant="outlined"
                          className="field_size_20 field_min_size_300"
                          margin="dense"
                          inputProps={{
                            name: 'status',
                          }}
                          displayEmpty
                          renderValue={
                            payload.status ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('businessVehicle.status'),
                                    })}
                                  </div>
                                )
                          }
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                          value={payload.status}
                          onChange={(event) => onChangeSelect(this, event)}
                        >
                          {VEHICLE_STATUS.map((item, idx) => {
                            return (
                              <MenuItem value={item.code} key={idx}>
                                {t(`${item.display}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('status', payload.status, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(payload.status, 'required') && (
                          <FormHelperText id="status" error>
                            {t('validation.required.choose', {field: t('businessVehicle.status')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Vehicle Supplier Name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessVehicle.businessName')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense">
                        <Select
                          variant="outlined"
                          className="field_size_20 field_min_size_300"
                          margin="dense"
                          inputProps={{
                            name: 'supplier_id',
                            readOnly: isEditForm ? true : false,
                          }}
                          displayEmpty
                          renderValue={
                            this.state.supplier_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('businessVehicle.businessName'),
                                    })}
                                  </div>
                                )
                          }
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                          value={this.state.supplier_id}
                          onChange={(event) => this.changeBusiness(event)}
                          disabled={isRoleBusiness()}
                        >
                          {common?.supplier_list?.length > 0 ? (
                            common?.supplier_list.map((item, idx) => {
                              return (
                                <MenuItem value={item.id} key={idx}>
                                  {item.supplier_name}
                                </MenuItem>
                              );
                            })
                          ) : (
                            <MenuItem value="">
                              <em>{t('error.no.record')}</em>
                            </MenuItem>
                          )}
                        </Select>
                        {this.validator.message('supplier_id', payload.supplier_id, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(payload.supplier_id, 'required') && (
                          <FormHelperText id="supplier_id" error>
                            {t('validation.required.choose', {field: t('businessVehicle.businessName')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Vehicle Facility Name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessVehicle.sales_office_store_name')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense">
                        <Select
                          variant="outlined"
                          className="field_size_20 field_min_size_300"
                          margin="dense"
                          inputProps={{
                            name: 'supplier_facility_id',
                            readOnly: isEditForm ? true : false,
                          }}
                          displayEmpty
                          renderValue={
                            this.state.supplier_facility_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('businessVehicle.sales_office_store_name'),
                                    })}
                                  </div>
                                )
                          }
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                          value={this.state.supplier_facility_id}
                          onChange={(event) => this.onChangeFacilityName(event.target.value)}
                        >
                          {common?.facility_list?.length > 0 ? (
                            common?.facility_list.map((item, idx) => {
                              return (
                                <MenuItem value={item.id} key={idx}>
                                  {item.facility_name}
                                </MenuItem>
                              );
                            })
                          ) : (
                            <MenuItem value="">
                              <em>{t('error.no.record')}</em>
                            </MenuItem>
                          )}
                        </Select>
                        {this.validator.message('supplier_facility_id', payload.supplier_facility_id, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(payload.supplier_facility_id, 'required') && (
                          <FormHelperText id="supplier_facility_id" error>
                            {t('validation.required.choose', {field: t('businessVehicle.sales_office_store_name')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Vehicle geofence */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('area_geofence.geofence')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense">
                        <Select
                          variant="outlined"
                          className="field_size_20 field_min_size_300"
                          margin="dense"
                          inputProps={{
                            name: 'geofence_id',
                            readOnly: isEditForm ? true : false,
                          }}
                          displayEmpty
                          renderValue={
                            payload.geofence_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('area_geofence.geofence'),
                                    })}
                                  </div>
                                )
                          }
                          value={payload.geofence_id}
                          onChange={(event) => this.handleGetCenterPolygon(event)}
                        >
                          {this.state.listGeofences?.map((item, idx) => {
                            return (
                              <MenuItem value={item.geofence_id} key={idx}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('geofence_id', payload.geofence_id, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(payload.geofence_id, 'required') && (
                          <FormHelperText id="geofence_id" error>
                            {t('validation.required.choose', {field: t('area_geofence.geofence')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Vehicle mode */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessVehicle.vehicle_mode')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense">
                        <div className="box-group-input">
                          <Select
                            variant="outlined"
                            className="field_size_20 field_min_size_300"
                            margin="dense"
                            inputProps={{
                              name: 'vehicle_mode',
                              readOnly: isEditForm ? true : false,
                            }}
                            displayEmpty
                            renderValue={
                              payload.vehicle_mode ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('businessVehicle.vehicle_mode'),
                                      })}
                                    </div>
                                  )
                            }
                            value={payload.vehicle_mode}
                            onChange={(event) => onChangeSelect(this, event)}
                          >
                            {this.state.listVehicleMode?.map((item, idx) => {
                              return (
                                <MenuItem value={item.mode} key={idx}>
                                  {item.mode}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <div className="max-length-label">{this.state.listVehicleMode?.find((item) => item.mode === payload.vehicle_mode)?.description}</div>
                        </div>
                        {this.validator.message('vehicle_mode', payload.vehicle_mode, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(payload.vehicle_mode, 'required') && (
                          <FormHelperText id="vehicle_mode" error>
                            {t('validation.required.choose', {field: t('businessVehicle.vehicle_mode')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Vehicle Brand */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessVehicle.brand')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl>
                        <div className="box-group-input">
                          <TextField
                            name="brand"
                            className="field_size_20 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('businessVehicle.brand')})}
                            // inputProps={{maxLength: 15}}
                            value={payload.brand}
                            onChange={(event) => onChangeTextField(this, event)}
                            variant="outlined"
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 15})}</div>
                        </div>
                        {this.validator.message('brand', payload.brand, 'required|max:15')}
                        {(this.state.isSubmitForm && !this.validator.check(payload.brand, 'required') && (
                          <FormHelperText id="brand" error>
                            {t('validation.required', {field: t('businessVehicle.brand')})}
                          </FormHelperText>
                        )) ||
                          (!this.validator.check(payload.brand, 'max:15') && (
                            <FormHelperText id="brand" error>
                              {t('validation.maxString', {value: 15})}
                            </FormHelperText>
                          ))}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Vehicle Name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessVehicle.vehicleName')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <div className="box-group-input">
                          <TextField
                            name="name"
                            className="field_size_20 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('businessVehicle.vehicleName')})}
                            inputProps={{maxLength: 255}}
                            variant="outlined"
                            value={payload.name}
                            onChange={(event) => onChangeTextField(this, event)}
                            aria-describedby="name"
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 255})}</div>
                        </div>
                        {this.validator.message('name', payload.name, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(payload.name, 'required') && (
                          <FormHelperText id="name">{t('validation.required', {field: t('businessVehicle.vehicleName')})}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Vehicle Number */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessVehicle.vehicleNumber')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <div className="box-group-input">
                          <TextField
                            name="registration_number"
                            className="field_size_20 field_min_size_225"
                            margin="dense"
                            inputProps={{maxLength: 20}}
                            placeholder={t('placeholder.required', {field: t('businessVehicle.vehicleNumber')})}
                            value={payload.registration_number}
                            onChange={(event) => onChangeTextField(this, event)}
                            variant="outlined"
                            aria-describedby="registration_number"
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 20})}</div>
                        </div>
                        {this.validator.message('registration_number', payload.registration_number, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(payload.registration_number, 'required') && (
                          <FormHelperText id="registration_number">{t('validation.required', {field: t('businessVehicle.vehicleNumber')})}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Vehicle Color */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessVehicle.color')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense">
                        <Select
                          variant="outlined"
                          className="field_size_20 field_min_size_300"
                          margin="dense"
                          inputProps={{
                            name: 'vehicle_color',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.vehicle_color ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('businessVehicle.color'),
                                    })}
                                  </div>
                                )
                          }
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                          value={this.state.vehicle_color}
                          onChange={(event) => onChangeSelect(this, event)}
                        >
                          {VEHICLE_COLOR.map((item, idx) => {
                            return (
                              <MenuItem value={item.id} key={idx}>
                                {t(item.i18n)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('vehicle_color', payload.vehicle_color, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(payload.vehicle_color, 'required') && (
                          <FormHelperText id="vehicle_color" error>
                            {t('validation.required.choose', {field: t('businessVehicle.color')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Vehicle Number of seat */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessVehicle.numberOfSeats')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl>
                        <TextField
                          name="number_of_seats"
                          className="field_size_20 field_min_size_225"
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('businessVehicle.numberOfSeats')})}
                          inputProps={{readOnly: isEditForm ? true : false, maxLength: 10, type: 'number', min: 1}}
                          value={payload.number_of_seats}
                          onChange={(event) => onChangeTextField(this, event)}
                          variant="outlined"
                        />
                        {this.validator.message('number_of_seats', payload.number_of_seats, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(payload.number_of_seats, 'required') && (
                          <FormHelperText id="number_of_seats" error>
                            {t('validation.required', {field: t('businessVehicle.sheetType')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Photo */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <Button variant="contained" color="primary" disabled={isEditForm && !permission.canUpdate} component="span" onClick={() => this.handleDetail()}>
                        {t('common.photo')}
                      </Button>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <label htmlFor="icon-button-file">
                        <IconButton color="primary" aria-label="upload picture" component="span">
                          {payload.imageSrc ? (
                            <div className="box_image" style={{width: '100%', height: '200px'}}>
                              <img className="cropped_image" style={{width: 'inherit', height: 'inherit'}} src={payload.imageSrc} alt="cropped" />
                            </div>
                          ) : (
                            <PhotoCamera />
                          )}
                        </IconButton>
                      </label>
                    </Grid>
                  </Grid>
                  {/* Usage Pattern */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessVehicle.usagePattern')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense">
                        <Select
                          variant="outlined"
                          className="field_size_20 field_min_size_300"
                          margin="dense"
                          inputProps={{
                            name: 'usage_pattern',
                            readOnly: isEditForm ? true : false,
                          }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                          displayEmpty
                          renderValue={
                            this.state.usage_pattern ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('businessVehicle.usagePattern'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.usage_pattern}
                          onChange={(event) => onChangeSelect(this, event)}
                          disabled={!this.state.geofence_id}
                          // disabled={!!isEditForm || !this.state.geofence_id}
                        >
                          {this.state.usage_pattern_selections.map((item, idx) => {
                            return (
                              <MenuItem value={item.id} key={idx}>
                                {t(item.i18n)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('usage_pattern', payload.usage_pattern, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(payload.usage_pattern, 'required') && (
                          <FormHelperText id="usage_pattern" error>
                            {t('validation.required.choose', {field: t('businessVehicle.usagePattern')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Mobi Type */}
                  {/* Only display if usage pattern is MOBI */}
                  {this.state.usage_pattern === USAGE_PATTERN[1].id && (
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('businessVehicle.mobiType')}
                        <span className="font_color_red">＊</span>
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl error>
                          <FormGroup>
                            {this.props.businessVehicle?.mobiTypes?.map((item, index) => {
                              const checked = this.state.mobi_types.indexOf(item) > -1;
                              return (
                                <FormControlLabel
                                  className="checkbox_radio_types"
                                  key={index}
                                  labelPlacement="end"
                                  control={
                                    <Checkbox
                                      className="checkbox_radio"
                                      checked={checked}
                                      disabled={isEditForm}
                                      onChange={(event) => this.handleSelectMobiTypes(event.target.checked, item)}
                                    />
                                  }
                                  label={t('businessVehicle.mobiType.' + item)}
                                />
                              );
                            })}
                            {this.validator.message('mobi_types', payload.mobi_types, 'required')}
                            {this.state.isSubmitForm && !this.validator.check(payload.mobi_types, 'mobi_types') && (
                              <FormHelperText id="mobi_types" error>
                                {t('validation.required.choose', {field: t('businessVehicle.mobiType')})}
                              </FormHelperText>
                            )}
                          </FormGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}
                  {/* Vehicle Information */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessVehicle.vehicleInformation')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={6}>
                      <div className="box-group-input">
                        <TextField
                          name="information"
                          className="field_size_20 field_min_size_600"
                          margin="dense"
                          multiline
                          placeholder={t('placeholder.required', {field: t('businessVehicle.vehicleInformation')})}
                          inputProps={{maxLength: 255}}
                          value={payload.information}
                          onChange={(event) => onChangeTextField(this, event)}
                          variant="outlined"
                        />
                        <div className="max-length-label">{t('validation.max.label', {value: 255})}</div>
                      </div>
                    </Grid>
                  </Grid>
                  {this.state.mobi_types?.length > 0 && !this.state.mobi_types.includes('SHUTTLE_BUS_ON_DEMAND') && (
                    <>
                      {/* Vehicle operating hours */}
                      <Grid container spacing={1} className="row_form_item" justify="flex-start">
                        <Grid container alignItems="center" item className="grid_title_padding" xs={6} lg={2}>
                          {t('businessVehicle.operatingHours')}
                          <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="space-between" flex={1}>
                            <Box mr={1}>
                              <CustomTimePicker
                                value={this.state.startTime}
                                showSecond={false}
                                allowEmpty
                                onChange={(value) => {
                                  this.setState({startTime: value});
                                }}
                                format={TIME_FORMAT}
                                use12Hours={false}
                              />
                              {this.validator.message('startTime', this.state.startTime, 'required')}
                            </Box>
                            <Box>
                              <CustomTimePicker
                                value={this.state.endTime}
                                showSecond={false}
                                allowEmpty
                                onChange={(value) => {
                                  this.setState({endTime: value});
                                }}
                                format={TIME_FORMAT}
                                use12Hours={false}
                              />
                              {this.validator.message('endTime', this.state.endTime, 'required')}
                            </Box>
                          </Box>
                          <Grid container spacing={1}>
                            <Grid container item alignItems="center">
                              {this.state.isSubmitForm &&
                                ((!this.validator.check(this.state.startTime, 'required') && (
                                  <FormHelperText id="time" style={{color: 'red'}}>
                                    {t('validation.required', {field: t('vehicles.from')})}
                                  </FormHelperText>
                                )) ||
                                  (!this.validator.check(this.state.endTime, 'required') && (
                                    <FormHelperText id="time" style={{color: 'red'}}>
                                      {t('validation.required', {field: t('vehicles.to')})}
                                    </FormHelperText>
                                  )) ||
                                  (!compareDateTimeRange(this.state.startTime, this.state.endTime, false) && (
                                    <FormHelperText id="time" style={{color: 'red'}}>
                                      {t('validation.invalid.timeRange')}
                                    </FormHelperText>
                                  )))}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {/* Vehicle operating hours ODM */}
                  {this.state.mobi_types.includes('SHUTTLE_BUS_ON_DEMAND') && (
                    <>
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container item className="grid_title_padding padding-top-14" xs={6} lg={2}>
                          {t('businessVehicle.operatingHours')}
                          <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid container className="" item xs={12} lg={8}>
                          {this.state.operatingHours?.map((operation, index) => {
                            const isOverlap = this.state.overlapList.has(index);
                            return (
                              <>
                                <Grid container className="row padding-top-14">
                                  <Grid xs={2} lg={2}>
                                    <FormControl>
                                      <CustomTimePicker
                                        name="start_time"
                                        className="field_size_75"
                                        value={operation.start_time || null}
                                        showSecond={false}
                                        allowEmpty
                                        placeholder={t('common.from')}
                                        onChange={(value) => this.changeOperatingHour(index, value, true)}
                                        format={TIME_FORMAT}
                                        use12Hours={false}
                                        autoComplete="off"
                                      />
                                      {this.validator.message('start_time_odm', operation.start_time, 'required')}
                                      {this.state.isSubmitForm && !this.validator.check(operation.start_time, 'required') && (
                                        <FormHelperText id="time" style={{color: 'red'}}>
                                          {t('validation.required', {field: t('vehicles.from')})}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </Grid>
                                  <Grid xs={2} lg={2}>
                                    <FormControl>
                                      <CustomTimePicker
                                        name="end_time"
                                        className="field_size_75"
                                        value={operation.end_time || null}
                                        showSecond={false}
                                        allowEmpty
                                        placeholder={t('common.to')}
                                        onChange={(value) => this.changeOperatingHour(index, value, false)}
                                        format={TIME_FORMAT}
                                        use12Hours={false}
                                        autoComplete="off"
                                      />
                                      {this.validator.message('end_time_odm', operation.end_time, 'required')}
                                      {this.state.isSubmitForm && !this.validator.check(operation.end_time, 'required') && (
                                        <FormHelperText id="time" style={{color: 'red'}}>
                                          {t('validation.required', {field: t('vehicles.to')})}
                                        </FormHelperText>
                                      )}
                                    </FormControl>
                                  </Grid>
                                  <Grid container className="row" xs={8} lg={8}>
                                    <Grid container xs={8} lg={8} justifyContent="space-between" className="row">
                                      {this.state.odmBookingTypes.map((type, idx) => (
                                        <FormControlLabel
                                          key={idx}
                                          value={type.id}
                                          labelPlacement="end"
                                          onClick={(event) => this.onChangeBookingType(event.target.value, index)}
                                          checked={operation.operation_type === type.id}
                                          control={<Radio className="checkbox_radio" />}
                                          label={<span className="font_size_small_regular">{t(`${type.i18n}`)}</span>}
                                        />
                                      ))}
                                    </Grid>
                                    <Grid container xs={4} lg={3} justifyContent="space-between" className="row">
                                      <Grid>
                                        <Button color="secondary" variant="contained" onClick={() => this.deleteRows(index)} disabled={this.state.operatingHours?.length === 1}>
                                          <CancelIcon />
                                        </Button>
                                      </Grid>
                                      <Grid>
                                        {index === 0 && (
                                          <Button color="primary" variant="contained" onClick={() => this.addItem()}>
                                            <AddIcon />
                                          </Button>
                                        )}
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={1}>
                                  <Grid container item alignItems="center">
                                    {(this.state.isSubmitForm && !compareDateTimeRange(operation.start_time, operation.end_time, false) && (
                                      <FormHelperText id="time" style={{color: 'red'}}>
                                        {t('validation.invalid.timeRange')}
                                      </FormHelperText>
                                    )) ||
                                      (isOverlap && (
                                        <div>
                                          <FormHelperText id="time" style={{color: 'red'}}>
                                            {t('jit.overlap')}
                                          </FormHelperText>
                                        </div>
                                      ))}
                                  </Grid>
                                </Grid>

                                {/* Default location */}
                                {operation.operation_type === ODM_BOOKING_TYPE[1].id && (
                                  <>
                                    <Grid container spacing={1} className="row_form_item">
                                      <Grid container alignItems="center" item xs={4} lg={2} className="row_form_item">
                                        <FormControlLabel
                                          className=""
                                          labelPlacement="end"
                                          control={
                                            <Checkbox
                                              className="checkbox_radio"
                                              checked={operation.default_location}
                                              value={operation.default_location}
                                              onChange={(event) => this.handleDisplayDefaultLocation(event.target.checked, index)}
                                            />
                                          }
                                        />
                                        {t('businessVehicle.defaultLocation')}
                                      </Grid>
                                      {operation.default_location && (
                                        <>
                                          <Grid container alignItems="center" item xs={6} lg={8} justifyContent="space-between">
                                            <Grid className="box-group-input" xs={6} lg={6}>
                                              <TextField
                                                name="default_latitude"
                                                className="field_size_20 field_min_size_300"
                                                margin="dense"
                                                placeholder={t('placeholder.required', {field: t('businessVehicle.latitude')})}
                                                value={operation.default_latitude}
                                                onChange={(event) => this.onChangeDefaultLatLng(index, event.target.value, true)}
                                                variant="outlined"
                                              />
                                              {this.validator.message('default_latitude', operation.default_latitude, 'latitude_type')}
                                            </Grid>
                                            <Grid className="box-group-input" xs={6} lg={6}>
                                              <TextField
                                                name="default_longitude"
                                                className="field_size_20 field_min_size_300"
                                                margin="dense"
                                                placeholder={t('placeholder.required', {field: t('businessVehicle.longtitude')})}
                                                value={operation.default_longitude}
                                                onChange={(event) => this.onChangeDefaultLatLng(index, event.target.value, false)}
                                                variant="outlined"
                                              />
                                              {this.validator.message('default_longitude', operation.default_longitude, 'longitude_type')}
                                            </Grid>
                                          </Grid>
                                          <Grid item xs={2} lg={2}>
                                            <Button color="primary" variant="contained" className="button_margin" onClick={() => this.handleClearLocation(index)}>
                                              {t('common.btnClear')}
                                            </Button>
                                          </Grid>
                                        </>
                                      )}
                                    </Grid>
                                    {operation.default_location && operation.operation_type === ODM_BOOKING_TYPE[1].id && (
                                      <>
                                        <Grid container spacing={1} className="box-group-input">
                                          <Grid container alignItems="center" item xs={4} lg={2} className="font_size_small" style={{color: 'red'}}>
                                            {t('businessVehicle.locationOfDriver')}
                                          </Grid>
                                          <Grid container alignItems="center" item xs={6} lg={8}>
                                            <Grid item xs={6} lg={6}>
                                              {!this.validator.check(operation.default_latitude, 'latitude_type') && (
                                                <FormHelperText id="default_latitude" error>
                                                  {t('validation.latitude', {field: t('businessVehicle.latitude_type')})}
                                                </FormHelperText>
                                              )}
                                              {!operation.default_latitude && operation.default_longitude && (
                                                <FormHelperText id="default_latitude" error>
                                                  {t('validation.required', {field: t('businessVehicle.latitude')})}
                                                </FormHelperText>
                                              )}
                                            </Grid>
                                            <Grid item xs={6} lg={6}>
                                              {!this.validator.check(operation.default_longitude, 'longitude_type') && (
                                                <FormHelperText id="default_longitude" error>
                                                  {t('validation.longitude', {field: t('businessVehicle.longitude_type')})}
                                                </FormHelperText>
                                              )}
                                              {operation.default_latitude && !operation.default_longitude && (
                                                <FormHelperText id="default_latitude" error>
                                                  {t('validation.required', {field: t('businessVehicle.longtitude')})}
                                                </FormHelperText>
                                              )}
                                            </Grid>
                                            {!payload.validLocationInGeofence[index] && (
                                              <Grid item xs={12} lg={12}>
                                                <FormHelperText id="defautLocaion" error>
                                                  {t('error.vehicle.session.default.location.invalid')}
                                                </FormHelperText>
                                              </Grid>
                                            )}
                                          </Grid>
                                        </Grid>
                                        <Grid container spacing={1} className="row_form_item">
                                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding"></Grid>
                                          <Grid container alignItems="center" item xs={6} lg={6}>
                                            {!isEmpty(payload.mapInfor) && (
                                              <PinMap
                                                messageErrorHandle={this.handleMessageError}
                                                mapInfor={payload.mapInfor}
                                                defaultMarker={[payload.defaultMarker[index]]}
                                                centerPoint={this.state.centerPoint}
                                                point={this.state.point}
                                                getLatLng={this.getLatLngData}
                                                indexMap={index}
                                                numberLocation={payload.operatingHours?.length}
                                                indexOnChange={payload.indexOnChange}
                                                isValidLatLng={payload.isValidLatLng}
                                              ></PinMap>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {/* Vehicle Additional Information */}
                  {this.state.isShowVehicleAddition && (
                    <>
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('businessVehicle.vehicleAddition')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl error>
                            <FormGroup>
                              <FormControlLabel
                                className="checkbox_radio_types"
                                labelPlacement="end"
                                control={
                                  <Checkbox
                                    className={this.state.listVehicleInformation.length > 1 ? 'disabled_checkbox_radio' : 'checkbox_radio'}
                                    checked={this.state.isTickedVehicleAddition}
                                    onChange={(event) => this.onClickVehicleAddition(event)}
                                    disabled={this.state.listVehicleInformation.length > 1}
                                  />
                                }
                              />
                            </FormGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {this.state.isShowVehicleAddition &&
                    this.state.isTickedVehicleAddition &&
                    this.state.listVehicleInformation.map((item, indexAddition) => {
                      const listTitle = Object.entries(item).filter((title) => title[0].includes('title'));
                      const listDescription = Object.entries(item).filter((description) => description[0].includes('description'));
                      return (
                        <>
                          <Card raised>
                            <Grid container alignItems="center" item xs={12} lg={12} className="table_color_header font_color_white font_size_mid table_title_header">
                              {t('businessVehicle.field')} {indexAddition + 1}
                            </Grid>
                            <Grid container alignItems="center" item xs={12} lg={12}>
                              <Grid container alignItems="center" item xs={10} lg={11}>
                                {/* Title */}
                                <Grid container spacing={1} className="row_form_item">
                                  <Grid container alignItems="center" item className="grid_title_padding field_size_10">
                                    <Grid>
                                      {t('businessVehicle.title_name')} <span className="font_color_red">＊</span>
                                      <div>
                                        <i className="font_size_small">{t('businessVehicle.line_break')}</i>
                                      </div>
                                    </Grid>
                                  </Grid>
                                  {listTitle.map((title, indexTitle) => (
                                    <>
                                      <Grid container alignItems="center" item xs={6} lg={3}>
                                        <FormControl fullWidth>
                                          <Box display="flex" flexDirection="column">
                                            <TextField
                                              label={<span>{title[0].includes('jp') ? t('common.ja') : title[0].includes('vi') ? t('common.vi') : t('common.en')}</span>}
                                              fullWidth
                                              margin="dense"
                                              placeholder={t('placeholder.required', {field: t('businessVehicle.title_name')})}
                                              minRows={1}
                                              maxRows={5}
                                              multiline
                                              variant="outlined"
                                              inputProps={{maxLength: 1000}}
                                              onChange={(event) => this.onChangeListVehicleData(title[0], event.target.value, indexAddition)}
                                              value={title[1] || ''}
                                            />
                                            <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 1000})}</div>
                                          </Box>
                                          {this.state.isSubmitForm && !!!title[1].trim() && (
                                            <FormHelperText id={title[0]} error>
                                              {t('businessVehicle.title_notice')}
                                            </FormHelperText>
                                          )}
                                        </FormControl>
                                      </Grid>
                                      {indexTitle !== title.length && (
                                        <Grid container justify="center" alignItems="center" item style={{width: '6%'}}>
                                          <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                                        </Grid>
                                      )}
                                    </>
                                  ))}
                                </Grid>
                                {/* Description */}
                                <Grid container spacing={1} className="row_form_item">
                                  <Grid container alignItems="center" item className="grid_title_padding field_size_10">
                                    <div>
                                      {t('businessVehicle.description_name')} <span className="font_color_red">＊</span>
                                      <div>
                                        <i className="font_size_small">{t('businessVehicle.line_break')}</i>
                                      </div>
                                    </div>
                                  </Grid>
                                  {listDescription.map((description, indexDescription) => (
                                    <>
                                      <Grid container alignItems="center" item xs={6} lg={3}>
                                        <FormControl fullWidth>
                                          <Box display="flex" flexDirection="column">
                                            <TextField
                                              label={
                                                <span>{description[0].includes('jp') ? t('common.ja') : description[0].includes('vi') ? t('common.vi') : t('common.en')}</span>
                                              }
                                              fullWidth
                                              margin="dense"
                                              placeholder={t('placeholder.required', {field: t('businessVehicle.description_name')})}
                                              minRows={1}
                                              maxRows={5}
                                              multiline
                                              variant="outlined"
                                              inputProps={{maxLength: 1000}}
                                              onChange={(event) => this.onChangeListVehicleData(description[0], event.target.value, indexAddition)}
                                              value={description[1] || ''}
                                            />
                                            <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 1000})}</div>
                                          </Box>
                                          {this.state.isSubmitForm && !!!description[1].trim() && (
                                            <FormHelperText id={description[0]} error>
                                              {t('businessVehicle.description_notice')}
                                            </FormHelperText>
                                          )}
                                        </FormControl>
                                      </Grid>
                                      {indexDescription !== description.length && (
                                        <Grid container justify="center" alignItems="center" item style={{width: '6%'}}>
                                          <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                                        </Grid>
                                      )}
                                    </>
                                  ))}
                                </Grid>
                              </Grid>
                              <Grid item xs={1} lg={1}>
                                <Grid style={{textAlign: 'center'}}>
                                  <Button
                                    style={{marginBottom: '10px'}}
                                    color="primary"
                                    variant="contained"
                                    onClick={() => this.addBoxPlan(indexAddition)}
                                    disabled={this.state.listVehicleInformation.length === 20}
                                  >
                                    <AddIcon />
                                  </Button>
                                  <br></br>
                                  <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => this.handleDelete(indexAddition)}
                                    disabled={this.state.listVehicleInformation.length === 1}
                                  >
                                    <CancelIcon />
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Card>
                        </>
                      );
                    })}
                  <br></br>
                  {/* JIT button */}
                  {
                    // Check if is edit form and usage pattern is MOBI
                    // and mobi types contant SHUTTLE_BUS_JIT_HOME_TO_WORK or SHUTTLE_BUS_JIT_WORK_TO_HOME
                    isEditForm && (this.state.mobi_types?.indexOf(MOBI_TYPES[1].id) >= 0 || this.state.mobi_types?.indexOf(MOBI_TYPES[2].id) >= 0) && (
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={2} lg={2} className="grid_title_padding">
                          {permission.canJitDetailSetting && (
                            <Link
                              style={{textDecoration: 'none'}}
                              to={{
                                pathname: ROUTE.LAYOUT + ROUTE.BUSINESS_VEHICLE_JIT + '/' + this.props.match.params.id,
                              }}
                            >
                              <Button color="primary" variant="contained" className="button_margin">
                                {t('businessVehicle.jit')}
                              </Button>
                            </Link>
                          )}
                        </Grid>
                        <Grid container alignItems="center" item xs={2} lg={2} className="grid_title_padding">
                          {permission.canStopShiftJit && (
                            <Link
                              style={{textDecoration: 'none'}}
                              to={{
                                pathname: ROUTE.LAYOUT + ROUTE.STOP_SHIFT_JIT + '/' + this.props.match.params.id,
                              }}
                            >
                              <Button color="primary" variant="contained" className="button_margin">
                                {t('businessVehicle.title_shift')}
                              </Button>
                            </Link>
                          )}
                        </Grid>
                        <Grid container alignItems="center" item xs={2} lg={2} className="grid_title_padding">
                          {permission.canVirtualVehicle && (
                            <Link
                              style={{textDecoration: 'none'}}
                              to={{
                                pathname: ROUTE.LAYOUT + ROUTE.VIRTUAL_VEHICLE + '/' + this.props.match.params.id,
                              }}
                            >
                              <Button color="primary" variant="contained" className="button_margin">
                                {t('businessVehicle.vir')}
                              </Button>
                            </Link>
                          )}
                        </Grid>
                        <Grid container alignItems="center" item xs={2} lg={2} className="grid_title_padding">
                          {permission.canJitSpecialSetting && (
                            <Link
                              style={{textDecoration: 'none'}}
                              to={{
                                pathname: ROUTE.LAYOUT + ROUTE.JIT_SPECIAL_SETTING + '/' + this.props.match.params.id,
                              }}
                            >
                              <Button color="primary" variant="contained" className="button_margin">
                                JIT SPECIAL SETTING
                              </Button>
                            </Link>
                          )}
                        </Grid>
                      </Grid>
                    )
                  }
                </Paper>
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                  {!this.props.match.params.id && (
                    <Grid container alignItems="center" justify="flex-end" item xs={9}>
                      <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={this.handleCreateVehicle}>
                        {t('common.btnRegister')}
                      </Button>
                      <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.VEHICLE_INFORMATION}}>
                        <Button color="primary" variant="contained" className="button_margin button_color">
                          {t('common.btnReturn')}
                        </Button>
                      </Link>
                    </Grid>
                  )}
                </Grid>
              </Container>
              <br></br>
            </Card>
          </Container>
          {this.props.match.params.id && (
            <>
              <br></br>
              <Container maxWidth="xl">
                <Card raised>
                  <Container maxWidth="xl">
                    <br></br>
                    <Grid container alignItems="center" item xs={12} lg={9}>
                      <Memo
                        memos={this.state.memos}
                        onChangeContent={(value) => onChangeListData(this, this.state.memos, value.field, value.index, value.newValue)}
                        note={this.state.note}
                        onChangeNote={(value) => this.setState({note: value})}
                        parentType="medium"
                        countryCode={this.state.country_id}
                      />
                    </Grid>
                    <br></br>
                    <Grid container spacing={1}>
                      <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                      <Grid container alignItems="center" justify="flex-end" item xs={9}>
                        {permission.canUpdate && (
                          <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={this.handleUpdateVehicle}>
                            {t('common.btnUpdate')}
                          </Button>
                        )}
                        <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.props.history.goBack}>
                          {t('common.btnReturn')}
                        </Button>
                      </Grid>
                    </Grid>
                    <br></br>
                  </Container>
                </Card>
              </Container>
            </>
          )}
          <Dialog
            open={this.state.flg}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal onClickOk={this.handleButtonUpdateOk.bind(this)} onClickCancel={this.handleButtonUpdateCancel.bind(this)} message={this.state.message}></SelectModal>
          </Dialog>
          <Dialog
            open={this.state.flagDelete}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal onClickOk={() => this.deleteBoxPlan(this.state.indexDelete)} onClickCancel={() => this.handleDeleteCancel()} message={this.state.message}></SelectModal>
          </Dialog>
          <Dialog
            open={this.state.showWarningDialog}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal
              onClickOk={this.handleButtonUpdateOk.bind(this)}
              onClickCancel={() => this.setState({showWarningDialog: false})}
              message={'businessVehicle.cancel_all_booking'}
              fields={{field: this.state.bookingCancelNumber}}
              listBookingCancel={this.state.bookingCancelIds}
            ></SelectModal>
          </Dialog>
          <Dialog
          open={this.state.isSuccessBookingCancel}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
          >
            <CancelBookingSuccessModal
              message={'api.updated.vehicle.success'}
              closeMessage = {() => this.setState({isSuccessBookingCancel: false, bookingCancelIds:[]})}
              listBookingCancel={this.state.bookingCancelIds}
            ></CancelBookingSuccessModal>
          </Dialog>
          <br></br>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    businessVehicle: state.businessVehicle,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDetailVehicle: (params, props) => dispatch(getDetailVehicle(params, props)),
    createVehicle: (payload, props) => dispatch(createVehicle(payload, props)),
    updateVehicle: (payload, props) => dispatch(updateVehicle(payload, props)),
    getVehicleMobiTypes: (payload, props) => dispatch(getVehicleMobiTypes(payload, props)),
    getListSupplier: () => dispatch(getListSupplier()),
    getListFacilityBySupplierID: (params) => dispatch(getListFacilityBySupplierID(params)),
    getListGeofence: (payload) => dispatch(getListGeofence(payload)),
    getListVehicleMode: () => dispatch(getListVehicleMode()),
    getVehicleModeByGeofence: (geofenceId) => dispatch(getVehicleModeByGeofence(geofenceId)),
    getVehicleGeofenceAddition: (geofenceId) => dispatch(getVehicleGeofenceAddition(geofenceId)),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
  };
};

export default withPermissionGateway(
  // eslint-disable-next-line new-cap
  GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || 'AIzaSyACyapw83diO1bi_xiXbZRLLoano6eTwd0',
    language: localStorage.getItem('i18nextLng') === 'ja' ? 'ja' : 'en',
  })(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index))),
);
