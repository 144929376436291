import {
  disableQRTicketApi,
  getBoardingTicketApi,
  getQRTicketDetailApi,
  searchQRTicketsApi,
} from '../../services/qrTicketServices';
import {modalObj} from '../../utils/modal';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

/**
 * getTicketHistoryListByUserId
 * @param {object} queryParams
 * @param {object} props
 * @return {object}
 */
export const getTicketHistoryListByUserId = (queryParams) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_QR_TIKET_HISTORY_BY_USER_ID_START,
    });
    return getBoardingTicketApi(queryParams).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_QR_TIKET_HISTORY_BY_USER_ID_SUCCESS,
            ticketHistoryList: response.result,
          });
          return response.result;
        } else {
          dispatch({
            type: ACTION_TYPES.GET_QR_TIKET_HISTORY_BY_USER_ID_FAILED,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_QR_TIKET_HISTORY_BY_USER_ID_FAILED,
        }),
    );
  };
};

/**
 * searchQRTickets
 * @param {object} payload
 * @param {object} queryParams
 * @return {object}
 */
export const searchQRTickets = (payload, queryParams) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SEARCH_QR_TICKET_START,
    });
    return searchQRTicketsApi(payload, queryParams).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.SEARCH_QR_TICKET_SUCCESS,
            payload: response.result,
          });
          return response.result;
        } else {
          dispatch({
            type: ACTION_TYPES.SEARCH_QR_TICKET_FAILED,
          });
        }
      },
      () => {
        dispatch({
          type: ACTION_TYPES.SEARCH_QR_TICKET_FAILED,
        });
      },
    );
  };
};

/**
 * getQRTicketDetail
 * @param {number} id
 * @return {object}
 */
export const getQRTicketDetail = (id) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_QR_TICKET_DETAIL_START,
    });
    return getQRTicketDetailApi(id).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_QR_TICKET_DETAIL_SUCCESS,
            qrTicketDetail: response.result,
          });
          return response.result;
        } else {
          dispatch({
            type: ACTION_TYPES.GET_QR_TICKET_DETAIL_FAILED,
          });
        }
      },
      () => {
        dispatch({
          type: ACTION_TYPES.GET_QR_TICKET_DETAIL_FAILED,
        });
      },
    );
  };
};

/**
 * disableQRTicket
 * @param {number} id
 * @return {object}
 */
export const disableQRTicket = (id) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.DISABLE_QR_TICKET_START,
    });
    return disableQRTicketApi(id).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.DISABLE_QR_TICKET_SUCCESS,
          });
          return response;
        } else {
          dispatch({
            type: ACTION_TYPES.DISABLE_QR_TICKET_FAILED,
          });
          dispatch(setMessageModal(modalObj(true, response.message_code)));
        }
      },
      () => {
        dispatch({
          type: ACTION_TYPES.DISABLE_QR_TICKET_FAILED,
        });
      },
    );
  };
};

/**
 * exportQRTicket
 * @param {object} payload
 * @param {object} queryParams
 * @return {object}
 */
export const exportQRTickets = (payload, queryParams) => {
  return (dispatch) => {
    return searchQRTicketsApi(payload, queryParams).then(
      (response) => {
        if (response && response.status === 200) {
          return response.result?.content;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message)));
        }
      },
      () => {
        dispatch(setMessageModal(modalObj(true, 'Internal Server Error!')));
      },
    );
  };
};
