import React, {Component} from 'react';

import {
  Button,
  Card,
  Container,
  Dialog,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import DetailIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import {Autocomplete} from '@material-ui/lab';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {
  DRIVER_GENDER,
  DRIVER_STATUS,
  EXPORT_ALERT_NUMBER_ROWS,
  EXPORT_LIMIT_NUMBER_ROWS,
  MAX_SIZE_OUTPUT,
  ROUTE,
  ROWS_PER_PAGE_OPTIONS,
  PERMISSION_ACTIONS,
  DRIVER_LANGUAGE,
} from '../../../common/constant';
import CSVExporter from '../../../components/CSVExporter';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {searchListDriverVehicleApi} from '../../../services/businessServices';
import {getAllFacilityList, getListSupplier} from '../../../stores/common/actions';
import {deleteDriver, exportDriver, searchListDriver} from '../../../stores/driver/action';
import {setMessageModal} from '../../../stores/modal/actions';
import {changeUrlParams, getQueryStringFromObject, getUrlParams, onChangeSelect, onChangeTextField} from '../../../utils/common';
import {modalObj} from '../../../utils/modal';
import {isRoleBusiness} from '../../../utils/role';

/**
 * Driver Search component
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      status: '',
      facility_code: '',
      facility_name: null,
      supplier_name: null,
      supplier_code: '',
      driver_name: '',
      driver_code: '',
      gender: null,
      lang: '',
      dataCheckDelete: [],
      flagDelete: false,
      index: null,
      id: null,
      result: [],
      isSearch: false,
      message: '',
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,
      flagExport: false,
      confirmedExport: false,
      latestSearchParams: null,
    };
    this.handleButtonOk = this.handleButtonOk.bind(this);
    this.handleButtonCancel = this.handleButtonCancel.bind(this);
    this.facilities = [];
    this.exportCSVRef = React.createRef();
  }

  /**
   * componentDitMount
   *
   */
   componentDidMount = async () => {
    await this.props.getAllFacilityList();
    await this.props.getListSupplier();
    if (isRoleBusiness()) {
      const {principal, common} = this.props;
      const supplier = common.supplier_list.find((item) => item.id === principal.supplier_id);
      this.setState({
        supplier_name: supplier,
        supplier_code: supplier.supplier_id,
      });
    };
    if (this.props.location.search) {
      this.setState(getUrlParams(this.props.location.search), this.handleSearch);
    }
  }

  /**
   * componentDidUpdate
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.props.actions !== prevProps.actions) {
      const canSearch = this.props.actions.includes(PERMISSION_ACTIONS.SEARCH);
      if (canSearch && this.props.location.search) {
        this.setState(getUrlParams(this.props.location.search), this.handleSearchCaution);
      }
    }
  }
  /**
   * changeSupplier
   * @param {*} value
   */
  changeSupplier = (value) => {
    this.setState({
      supplier_name: value,
    });
  };

  /**
   * handleSearch
   * @param {bool} reset
   */
  handleSearch(reset) {
    const {status, facility_code, facility_name, driver_name, driver_code, gender, supplier_code, supplier_name, lang, currentPage, rowsPerPage} = this.state;
    const payload = {
      driver_status: status ? status : null,
      facility_code: facility_code ? facility_code.trim() : null,
      facility_name: facility_name ? facility_name?.facility_name : null,
      supplier_code: supplier_code ? supplier_code.trim() : null,
      supplier_name: supplier_name ? supplier_name?.supplier_name : null,
      driver_name: driver_name ? driver_name.trim() : null,
      driver_code: driver_code ? driver_code.trim() : null,
      gender: gender,
      lang: lang,
    };
    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const queryParams = reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage};

    searchListDriverVehicleApi({from_time: new Date().toISOString()}).then((dataCheckDelete) => {
      this.setState({
        dataCheckDelete: dataCheckDelete?.result,
      });
    });
    this.props.searchListDriver(payload, queryParams, this.props).then((response) => {
      if (!response) {
        response = [];
      }
      this.setState(
          {
            result: response?.content,
            totalRows: response?.totalSize,
            isSearch: true,
            latestSearchParams: payload,
          },
          () => {
            const {status, facility_code, facility_name, driver_name, driver_code, gender, supplier_code, supplier_name, lang, currentPage, rowsPerPage} = this.state;
            // Apply changed params into url
            const queryParamsToChange = {
              status,
              facility_code,
              facility_name: facility_name && JSON.stringify(facility_name),
              driver_name,
              driver_code,
              gender,
              supplier_code,
              supplier_name: supplier_name && JSON.stringify(supplier_name),
              lang,
              currentPage: String(currentPage),
              rowsPerPage,
            };
            changeUrlParams(queryParamsToChange);
            const newSearchParams = getQueryStringFromObject(queryParamsToChange);
            // Save search params into state in order to pass to next page
            this.setState({searchParams: newSearchParams});
          },
      );
    });
  }

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearch);
  };

  /**
   * reset
   */
  reset = () => {
    const {principal, common} = this.props;
    const supplier = common.supplier_list.find((item) => item.id === principal.supplier_id);
    this.setState({
      status: null,
      facility_code: '',
      facility_name: '',
      supplier_code: isRoleBusiness() ? supplier.supplier_id : '',
      supplier_name: isRoleBusiness() ? supplier : '',
      driver_name: '',
      driver_code: '',
      lang: null,
      gender: null,
      index: null,
      id: null,
    });
    this.facilities = [];
  };

  /**
   * handleButtonCancel
   */
  handleButtonCancel() {
    this.setState({
      flagDelete: false,
    });
  }

  /**
   * handleButtonOk
   */
  handleButtonOk() {
    if (this.state.message === 'messageCode.isCheckNotDriverDelete') {
      this.setState({
        flagDelete: false,
      });
    } else {
      this.setState({
        flagDelete: false,
      });
      this.props.deleteDriver(this.state.id, this.props).then(() => {
        this.handleSearch(true);
      });
    }
  }

  /**
   * handleDelete
   * @param {int} id
   * @param {object} index
   */
  handleDelete = (id, index) => {
    if (typeof this.isCheckVehicleExits(id, this.state.dataCheckDelete) === 'object' && this.isCheckVehicleExits(id, this.state.dataCheckDelete) !== null) {
      this.props.setMessageModal(modalObj(true, this.props.t('messageCode.isCheckNotDriverDelete')));
    } else {
      this.setState({
        flagDelete: true,
        message: 'messageCode.deleteConfirmDriver',
        id,
        index,
      });
    }
  };

  /**
   * Check Id exits
   * @param {*} id
   * @param {*} dataCheck
   * @return {*}
   */
  isCheckVehicleExits = (id, dataCheck) => {
    return dataCheck.find((item) => item.driver_id === id);
  };

  /**
   *
   * @param {*} value
   */
  changeFacility = (value) => {
    this.setState({
      facility_name: value,
    });
  };
  /**
   * Fetch data to export
   */
  fetchDataExport = async () => {
    const {t} = this.props;
    this.exportHeaders = [
      {label: t('driver.id'), key: 'driver_code'},
      {label: t('driver.name_app'), key: 'driver_name_app'},
      {label: t('driver.name'), key: 'driver_name'},
      {label: t('driver.supplier.name'), key: 'supplier_name'},
      {label: t('driver.facility.name'), key: 'supplier_facility_name'},
      {label: t('driver.status'), key: 'status'},
    ];
    const queryParams = {page: 0, size: MAX_SIZE_OUTPUT};
    const dataExport = await this.props.exportDriver(this.state.latestSearchParams, queryParams);
    const convertedData = dataExport.map((row, index) => {
      row.status = t(`${DRIVER_STATUS.find(({id}) => (row.status === id)).i18n}`);
      return row;
    });
    this.setState({confirmedExport: false});
    return convertedData;
  };

  /**
   * alertExportCSV
   */
  alertExportCSV = () => {
    const {t} = this.props;
    if (this.state.totalRows > EXPORT_LIMIT_NUMBER_ROWS) {
      this.props.setMessageModal(modalObj(true, t('messageCode.exportAlertLimit')));
    } else {
      const message = t('messageCode.exportAlert');
      this.setState({flagExport: true, message});
    }
  };

  /**
   * handleButtonExportOk
   */
  handleButtonExportOk = () => {
    this.setState({flagExport: false, confirmedExport: true}, () => {
      this.exportCSVRef.current.onClickExport();
    });
  };

  /**
   * handleButtonExportCancel
   */
  handleButtonExportCancel = () => {
    this.setState({flagExport: false});
  };

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {common, driver, t, actions} = this.props;
    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canCSVOutput: actions.includes(PERMISSION_ACTIONS.CSV_OUTPUT),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
      canDelete: actions.includes(PERMISSION_ACTIONS.DELETE),
      canRegister: actions.includes(PERMISSION_ACTIONS.REGISTER),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('driver.title')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          <LoadingOverlay active={driver.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                  <>
                    <Paper>
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                          {t('driver.searchTitle')}
                        </Grid>
                      </Grid>

                      {/* Status */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('driver.status')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'status',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.status ?
                                  undefined :
                                  () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('driver.status'),
                                      })}
                                    </div>
                                  )
                              }
                              value={this.state.status}
                              onChange={(event) => onChangeSelect(this, event)}
                            >
                              {DRIVER_STATUS.map(({id, i18n}, idx) => {
                                return (
                                  <MenuItem value={id} key={idx}>
                                    {t(`${i18n}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Business Code */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('driver.supplier.code')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            className="field_size_10 field_min_size_150"
                            margin="dense"
                            name="supplier_code"
                            placeholder={t('driver.supplier.code')}
                            inputProps={{maxLength: 256}}
                            variant="outlined"
                            value={this.state.supplier_code}
                            onChange={(event) => onChangeTextField(this, event)}
                            disabled={isRoleBusiness()}
                          />
                        </Grid>
                      </Grid>

                      {/* Business Name */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('driver.supplier.name')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <Autocomplete
                            margin="dense"
                            className="field_size_20 field_min_size_300"
                            options={common?.supplier_list}
                            value={this.state.supplier_name}
                            getOptionLabel={(option) => option.supplier_name}
                            onChange={(event, supplier) => {
                              this.changeSupplier(supplier);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name={'supplierName'}
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                placeholder={t('placeholder.required', {field: t('driver.supplier.name')})}
                              />
                            )}
                            disabled={isRoleBusiness()}
                          />
                        </Grid>
                      </Grid>

                      {/* Facility Id */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('driver.facility.code')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            className="field_size_10 field_min_size_150"
                            margin="dense"
                            name="facility_code"
                            placeholder={t('driver.facility.code')}
                            inputProps={{maxLength: 256}}
                            variant="outlined"
                            value={this.state.facility_code}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                        </Grid>
                      </Grid>

                      {/* Facility name */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('driver.facility.name')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <Autocomplete
                            margin="dense"
                            className="field_size_20 field_min_size_300"
                            options={common?.facility_list_all}
                            value={this.state.facility_name}
                            getOptionLabel={(option) => option.facility_name}
                            onChange={(event, facility) => {
                              this.changeFacility(facility);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name={'facilityName'}
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                placeholder={t('placeholder.required', {field: t('driver.facility.name')})}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>

                      {/* Driver Code */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('driver.code')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            className="field_size_10 field_min_size_150"
                            margin="dense"
                            name="driver_code"
                            placeholder={t('driver.code')}
                            inputProps={{maxLength: 256}}
                            variant="outlined"
                            value={this.state.driver_code}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                        </Grid>
                      </Grid>

                      {/* Driver name */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('driver.name_search')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            className="field_size_20 field_min_size_300"
                            margin="dense"
                            name="driver_name"
                            placeholder={t('driver.name_search')}
                            inputProps={{maxLength: 256}}
                            variant="outlined"
                            value={this.state.driver_name}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                        </Grid>
                      </Grid>

                      {/* Gender */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('driver.gender')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <RadioGroup row aria-label="gender" name="gender">
                            {DRIVER_GENDER.map(({name, i18n}, idx) => {
                              return (
                                <FormControlLabel
                                  value={name}
                                  key={idx}
                                  onChange={(event) => onChangeSelect(this, event)}
                                  control={<Radio className="checkbox_radio" checked={this.state.gender === name} />}
                                  label={<span className="font_size_small_regular">{t(`${i18n}`)}</span>}
                                />
                              );
                            })}
                          </RadioGroup>
                        </Grid>
                      </Grid>
                      {/* Language */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('driver.language')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'lang',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.lang ?
                                  undefined :
                                  () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.language')}
                                    </div>
                                  )
                              }
                              value={this.state.lang}
                              onChange={(event) => onChangeSelect(this, event)}
                            >
                              {DRIVER_LANGUAGE.map(({id, i18n}, idx) => {
                                return (
                                  <MenuItem value={id} key={idx}>
                                    {t(`${i18n}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Paper>
                  </>
                <br></br>

                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.reset()} endIcon={<RotateLeftIcon />}>
                        {t('common.btnResetSearch')}
                      </Button>
                    )}
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {permission.canRegister && (
                      <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.DRIVER_ADD}}>
                        <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />}>
                          {t('common.btnRegister')}
                        </Button>
                      </Link>
                    )}
                    {permission.canCSVOutput && permission.canSearch && (
                      <>
                        <div className={this.state.totalRows <= EXPORT_ALERT_NUMBER_ROWS || this.state.confirmedExport ? '' : 'hidden'}>
                          <CSVExporter
                            ref={this.exportCSVRef}
                            disabled={this.state.totalRows <= 0 || !this.state.latestSearchParams}
                            headers={this.exportHeaders}
                            fetchAction={this.fetchDataExport}
                            isFetchAsync={true}
                            screenName={t('facility.title')}
                          />
                        </div>
                        {this.state.totalRows >= EXPORT_ALERT_NUMBER_ROWS && !this.state.confirmedExport && (
                          <Button
                            onClick={this.alertExportCSV}
                            disabled={this.state.totalRows <= 0}
                            color="primary"
                            variant="contained"
                            className="button_margin"
                            endIcon={<GetAppIcon className="csv-exporter-icon" />}
                          >
                            {t('calendar.csv_output')}
                          </Button>
                        )}
                      </>
                    )}

                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin" onClick={() => this.handleSearch(true)} endIcon={<SearchIcon />}>
                        {t('common.btnSearch')}
                      </Button>
                    )}
                  </Grid>
                </Grid>

                <br></br>
              </Container>
            </Card>
          </LoadingOverlay>
          <br></br>
          {permission.canSearch && this.state.isSearch && (
            <Card raised>
              <Container maxWidth="xl">
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={6}>
                    <h3>
                      {t('common.searchResult')} {this.state.totalRows ? this.state.totalRows : 0} {t('common.case')}
                    </h3>
                  </Grid>
                </Grid>
                <LoadingOverlay active={driver.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                  {driver.listDriver.length > 0 && (
                    <div className="scroll_area_700">
                      <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                        <TableHead>
                          <TableRow>
                            <TableCell className="width_150p ant-table-cell-fix-left">{t('driver.code')}</TableCell>
                            <TableCell className="width_200p">{t('driver.name_app')}</TableCell>
                            <TableCell className="width_250p">{t('driver.name')}</TableCell>
                            <TableCell className="width_125p">{t('driver.supplier.code')}</TableCell>
                            <TableCell className="width_200p">{t('driver.supplier.name')}</TableCell>
                            <TableCell className="width_250p">{t('driver.facility.code')}</TableCell>
                            <TableCell className="width_250p">{t('driver.facility.name')}</TableCell>
                            <TableCell className="width_100p">{t('driver.status')}</TableCell>
                            <TableCell className="width_250p">{t('driver.language')}</TableCell>
                            {(permission.canEdit || permission.canDelete) && (
                              <TableCell className="width_250p" style={{position: 'sticky', right: '0'}} colSpan={3}>{t('common.action')}</TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {driver.listDriver.map((row, index) => (
                            <TableRow key={index} hover className="cursor_pointer">
                              <TableCell className="cell_fixed_left">{row.driver_code}</TableCell>
                              <TableCell>{row.driver_name_app}</TableCell>
                              <TableCell>{row.driver_name}</TableCell>
                              <TableCell>{row.supplier_code}</TableCell>
                              <TableCell>{row.supplier_name}</TableCell>
                              <TableCell>{row.facility_code}</TableCell>
                              <TableCell>{row.supplier_facility_name}</TableCell>
                              <TableCell>{DRIVER_STATUS.map(({id, i18n}) => (`${row.status}` === id ? t(`${i18n}`) : ''))}</TableCell>
                              <TableCell>{DRIVER_LANGUAGE.map(({id, i18n}) => (row.lang === id ? t(i18n) : ''))}</TableCell>
                              {(permission.canEdit || permission.canDelete) && (
                                <TableCell className="cell_fixed_right" colSpan={3}>
                                  {permission.canEdit && (
                                    <Link
                                      style={{textDecoration: 'none'}}
                                      to={{
                                        pathname: ROUTE.LAYOUT + ROUTE.DRIVER_DETAIL + '/' + row.id,
                                        state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                      }}
                                    >
                                      <Button color="primary" variant="contained" className="button_margin " endIcon={<DetailIcon />}>
                                        {t('common.btnEdit')}
                                      </Button>
                                    </Link>
                                  )}

                                  {permission.canDelete && (
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="button_margin button_color_red"
                                      endIcon={<DeleteIcon />}
                                      onClick={() => this.handleDelete(row.id, index)}
                                    >
                                      {t('common.btnDelete')}
                                    </Button>
                                  )}
                                </TableCell>
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  )}
                  <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                </LoadingOverlay>

                <br></br>
              </Container>
              <br></br>
            </Card>
          )}
          <br></br>
          <Dialog
            open={this.state.flagDelete}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal
              onClickOk={this.handleButtonOk}
              onClickCancel={this.handleButtonCancel}
              message={this.state.message}
              okButtonText={t('common.btnYes')}
              cancelButtonText={t('common.btnNo')}
            ></SelectModal>
          </Dialog>{' '}
          {/* Export alert modal */}
          <Dialog
            open={this.state.flagExport}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal
              onClickOk={this.handleButtonExportOk}
              onClickCancel={this.handleButtonExportCancel}
              okButtonText={t('common.btnYes')}
              cancelButtonText={t('common.btnNo')}
              message={this.state.message}
            ></SelectModal>
          </Dialog>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    driver: state.driver,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchListDriver: (params, queryParams, props) => dispatch(searchListDriver(params, queryParams, props)),
    getListSupplier: () => dispatch(getListSupplier()),
    exportDriver: (payload, queryParams) => dispatch(exportDriver(payload, queryParams)),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    deleteDriver: (params, props) => dispatch(deleteDriver(params, props)),
    getAllFacilityList: () => dispatch(getAllFacilityList()),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
