import React, {Component} from 'react';

import {Card, Container, Grid, Button, Table, TableCell, TableRow, TableBody, TableHead, TableFooter} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DetailIcon from '@material-ui/icons/Description';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {PERMISSION_ACTIONS, ROUTE} from '../../common/constant';
import withPermissionGateway from '../../hoc/withPermissionGateway';
import {getListArea} from '../../stores/delivery_setting/action';

/**
 * Congestion status master
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {};
  }
  /**
   * displayAcceptTime
   * @param {string} time
   * @return {string}
   */
  displayAcceptTime(time) {
    switch (time) {
      case '':
        return this.props.t('deliverySetting.status.unregister');
      case 'CUSTOMIZE':
        return this.props.t('deliverySetting.status.customize');
      default:
        return time;
    }
  }
  /**
   * componentDidMount
   */
  componentDidMount() {
    this.props.getListArea();
  }
  /**
   * render component
   * @return {component}
   */
  render() {
    const {common, deliverySetting, t, actions} = this.props;
    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('deliverySetting.list_delivery')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Container>
        <br></br>
        <br></br>
        <LoadingOverlay active={common.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
          {permission.canSearch && (
            <Container maxWidth="xl">
              <Card raised>
                <Container maxWidth="xl">
                  <br></br>
                  <div className="scroll_area_700">
                    <Table size="small" aria-label="sticky table" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>{t('common.geofence')}</TableCell>
                          <TableCell>{t('deliverySetting.acceptance_time')}</TableCell>
                          {permission.canEdit && (
                            <TableCell colSpan={2}>{t('common.action')}</TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {deliverySetting?.listDeliveryArea?.map((row, index) => (
                          <TableRow key={index} hover className="cursor_pointer">
                            <TableCell scope="row">{row.name}</TableCell>
                            <TableCell>{this.displayAcceptTime(row.delivery_accept_time)}</TableCell>
                            {permission.canEdit && (
                              <TableCell>
                                <Link
                                  style={{textDecoration: 'none'}}
                                  to={{pathname: ROUTE.LAYOUT + ROUTE.DELIVERY_TIME_SETTING_INFORMATION + '/' + row.geofence_id}}
                                >
                                  <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                    {t('common.btnEdit')}
                                  </Button>
                                </Link>
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter></TableFooter>
                    </Table>
                  </div>
                  <br></br>
                </Container>
                <br></br>
              </Card>
            </Container>
          )}
        </LoadingOverlay>
        <br></br>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    deliverySetting: state.deliverySetting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListArea: () => dispatch(getListArea()),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
