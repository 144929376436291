import React, {Component} from 'react';

import {Grid, Button, TextField, Paper, Card, Container, FormControl, Dialog, Modal, Backdrop, FormHelperText} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {ROUTE, PERMISSION_ACTIONS} from '../../../common/constant';
import ImageUpload from '../../../components/imageSelect';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getRightMenuSetting, createRightMenuSetting, updateRightMenuSetting} from '../../../stores/right_menu/action';
import {onChangeTextField, backForwardRouter} from '../../../utils/common';

/**
 * Right Menu Setting's Form
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      shuttleId: '',
      shuttleTitle: '',
      shuttleHeader: '',
      shuttleImage: '',
      description: '',
      image_master_id: '',

      isSubmitForm: false,
      flg: false,
      message: '',
      formData: new FormData(),
      openModal: false,
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * handleButtonModalOk
   */
  handleButtonModalOk = () => {
    this.setState({
      flg: false,
    });
    const {shuttleId, shuttleTitle, shuttleHeader, description, image_master_id} = this.state;
    if (this.props.match.params.id) {
      const payload = {
        id: shuttleId,
        title: shuttleTitle,
        header: shuttleHeader,
        description,
        image_master_id,
      };
      this.props.updateRightMenuSetting(payload, this.props);
      this.props.getRightMenuSetting(this.props.match.params.id);
    } else {
      const payload = {
        title: shuttleTitle,
        header: shuttleHeader,
        description,
        image_master_id,
      };
      this.props.createRightMenuSetting(payload, this.props);
    }
  };

  /**
   * handleButtonCancel
   */
  handleButtonModalCancel() {
    this.setState({
      flg: false,
    });
  }

  /**
   * handleButtonOk
   */
  handleButtonOk = () => {
    this.setState({
      openModal: false,
    });
  };

  /**
   * handleButtonClose
   */
  handleButtonClose = () => {
    this.setState({
      openModal: false,
    });
  };
  /**
   * handleChooseImage
   */
  handleChooseImage = () => {
    this.setState({
      openModal: true,
    });
  };

  /**
   * componentDidMount
   */
  async componentDidMount() {
    if (this.props.match.url !== ROUTE.LAYOUT + ROUTE.RIGHT_MENU_MANAGEMENT_CREATE) {
      this.props.getRightMenuSetting(this.props.match.params.id).then((result) => {
        this.setState({
          shuttleId: result?.id,
          shuttleTitle: result?.title,
          shuttleHeader: result?.header,
          shuttleDescription: result?.description,
          shuttleImage: result?.image_master_url,
          description: result?.description,
          image_master_id: result?.image_master_id,
        });
        this.forceUpdate();
      }, () => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.RIGHT_MENU_MANAGEMENT));
    }
  }

  /**
   * handleCreate
   */
  handleCreate = () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid()) {
      this.setState({
        flg: true,
        message: 'shuttleSetting.question.confirm.create',
      });
    }
  };

  /**
   * handleCreate
   */
  handleUpdate = () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid()) {
      this.setState({
        flg: true,
        message: 'shuttleSetting.question.confirm.update',
      });
    }
  };

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {rightMenu, t, actions} = this.props;
    const isEditForm = this.props.match.params.id;
    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
    };

    return (
      <LoadingOverlay active={rightMenu.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{isEditForm ? t('shuttleSetting.detailTitle') : t('shuttleSetting.createTitle')}</h3>
              </Grid>
            </Grid>
          </Container>
          <br></br>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={this.state.openModal}
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1000',
                  }}
                >
                  <ImageUpload
                    onClickOk={this.handleButtonOk}
                    onClickClose={this.handleButtonClose}
                    selectImage={(value) => this.setState({image_master_id: value.id, shuttleImage: value.url})}
                  ></ImageUpload>
                </Modal>
              </Container>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('shuttleSetting.formTitle')}
                    </Grid>
                  </Grid>
                  {isEditForm && (
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('common.id')}
                        <span className="font_color_red">＊</span>
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <TextField
                          className="field_size_20 field_min_size_250"
                          margin="dense"
                          placeholder={t('common.id')}
                          variant="outlined"
                          value={this.state.shuttleId}
                          disabled="true"
                        />
                      </Grid>
                    </Grid>
                  )}

                  {/* title */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('shuttleSetting.title')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl className="width_100 padding-item-0">
                        <TextField
                          name="shuttleTitle"
                          className="field_size_20 field_min_size_250"
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('shuttleSetting.title')})}
                          variant="outlined"
                          value={this.state.shuttleTitle}
                          onChange={(event) => onChangeTextField(this, event)}
                        />
                        {/* add rule to validate */}
                        {this.validator.message('shuttleTitle', this.state.shuttleTitle, 'required')}
                        {/* check isValid to show messages */}
                        {this.state.isSubmitForm && !this.validator.check(this.state.shuttleTitle, 'required') && (
                          <FormHelperText id="shuttleTitle" error>
                            {t('validation.required', {field: t('shuttleSetting.title')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* header */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('shuttleSetting.header')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl className="width_100 padding-item-0">
                        <TextField
                          name="shuttleHeader"
                          className="field_size_20 field_min_size_250"
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('shuttleSetting.header')})}
                          variant="outlined"
                          value={this.state.shuttleHeader}
                          onChange={(event) => onChangeTextField(this, event)}
                        />
                        {/* add rule to validate */}
                        {this.validator.message('shuttleTitle', this.state.shuttleHeader, 'required')}
                        {/* check isValid to show messages */}
                        {this.state.isSubmitForm && !this.validator.check(this.state.shuttleHeader, 'required') && (
                          <FormHelperText id="shuttleTitle" error>
                            {t('validation.required', {field: t('shuttleSetting.header')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* description */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding ">
                      {t('popup_recommend.description')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl fullWidth>
                        <TextField
                          name="description"
                          className="field_size_10 field_min_size_300 "
                          margin="dense"
                          placeholder={t('validation.required', {field: t('popup_recommend.description')})}
                          value={this.state.description}
                          onChange={(event) => onChangeTextField(this, event)}
                          inputProps={{maxLength: 256}}
                          variant="outlined"
                        />
                        {this.validator.message('description', this.state.description, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.description, 'required') && (
                          <FormHelperText id="description" error>
                            {t('validation.required', {field: t('popup_recommend.description')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Photo */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <Button variant="contained" disabled={isEditForm && !permission.canUpdate} color="primary" component="span" onClick={() => this.handleChooseImage()}>
                        {t('common.photo')}
                      </Button>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <label htmlFor="icon-button-file">
                        <IconButton color="primary" aria-label="upload picture" component="span">
                          {this.state.shuttleImage ? (
                            <div className="box_image" style={{width: '100%', height: '200px'}}>
                              <img className="cropped_image" style={{width: 'inherit', height: 'inherit'}} src={this.state.shuttleImage} alt="cropped" />
                            </div>
                          ) : (
                            <PhotoCamera />
                          )}
                        </IconButton>
                      </label>
                      <Grid container spacing={1}>
                        {this.validator.message('shuttleImage', this.state.shuttleImage, 'required')}
                        {/* check isValid to show messages */}
                        {this.state.isSubmitForm && !this.validator.check(this.state.shuttleImage, 'required') && (
                          <FormHelperText id="shuttleImage" error>
                            {t('validation.required.choose', {field: t('common.photo')})}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
                <br></br>

                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {!isEditForm && (
                      <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={() => this.handleCreate()}>
                        {t('common.btnRegister')}
                      </Button>
                    )}
                    {isEditForm && permission.canUpdate && (
                      <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={() => this.handleUpdate()}>
                        {t('common.btnUpdate')}
                      </Button>
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      className="button_margin button_color"
                      onClick={this.props.history.goBack}
                    >
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                </Grid>
                <Dialog
                  open={this.state.flg}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal onClickOk={this.handleButtonModalOk.bind(this)} onClickCancel={this.handleButtonModalCancel.bind(this)} message={this.state.message}></SelectModal>
                </Dialog>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>
          <br></br>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rightMenu: state.rightMenu,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRightMenuSetting: (params) => dispatch(getRightMenuSetting(params)),
    createRightMenuSetting: (formData, props) => dispatch(createRightMenuSetting(formData, props)),
    updateRightMenuSetting: (formData, props) => dispatch(updateRightMenuSetting(formData, props)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
