import React, {Component} from 'react';

import {Card, Container, Grid, TextField, Button, Paper, Dialog, FormControl, FormHelperText} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {PERMISSION_ACTIONS} from '../../common/constant';
import SelectModal from '../../components/selectModal';
import withPermissionGateway from '../../hoc/withPermissionGateway';
import {changePassword} from '../../stores/authen/actions';
import {onChangeTextField} from '../../utils/common';

/**
 * Account Management view
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      isSubmitForm: false,
      flag: false,
      message: '',
    };
    this.handleButtonOk = this.handleButtonOk.bind(this);
    this.handleButtonCancel = this.handleButtonCancel.bind(this);
    this.validator = new SimpleReactValidator();
  }

  /**
   * changePassword
   * @param {props} t
   */
  changePassword = (t) => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid()) {
      this.setState({
        flag: true,
        message: 'messageCode.changePasswordConfirm',
      });
    }
  };

  /**
   * handleButtonOk
   */
  handleButtonOk() {
    this.setState({flag: false});
    const payload = {
      currentPassword: this.state.currentPassword,
      newPassword: this.state.newPassword,
    };
    this.props.changePassword(payload, this.props);
  }

  /**
   * handleButtonCancel
   */
  handleButtonCancel() {
    this.setState({
      flag: false,
    });
  }

  /**
   * Render component
   * @return {object}
   */
  render() {
    const {authen, t, actions} = this.props;
    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
    };

    return (
      <LoadingOverlay active={authen.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('changePassword.title')}</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}>
                <Button
                  color="primary"
                  variant="contained"
                  className="button_margin button_color"
                  endIcon={<ArrowBackIcon />}
                  onClick={this.props.history.goBack}
                >
                  {t('common.btnReturn')}
                </Button>
              </Grid>
            </Grid>
            <br></br>
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Grid container spacing={2}>
                  <Grid container alignItems="center" item xs={12}>
                    <span className="font_size_small_regular font_color_mediumblue font_bold">
                      {t('changePassword.msgExpired')}
                    </span>
                  </Grid>
                </Grid>
                <br />
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('changePassword.currentPassword')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl className="width_100 padding-item-0">
                        <TextField
                          placeholder={t('changePassword.labelCurrentPassword')}
                          inputProps={{maxLength: 256}}
                          margin="dense"
                          variant="outlined"
                          type="password"
                          value={this.state.currentPassword}
                          name="currentPassword"
                          onChange={(event) => onChangeTextField(this, event)}
                          className="field_size_22 field_min_size_300"
                        />
                        {this.validator.message('currentPassword', this.state.currentPassword, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.currentPassword, 'required') && (
                          <FormHelperText id="currentPassword" error>
                            {t('validation.required', {field: t('changePassword.currentPassword')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('changePassword.newPassword')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl className="width_100 padding-item-0">
                        <TextField
                          placeholder={t('placeholder.required', {field: t('changePassword.newPassword')})}
                          margin="dense"
                          variant="outlined"
                          type="password"
                          name="newPassword"
                          onChange={(event) => onChangeTextField(this, event)}
                          className="field_size_22 field_min_size_300"
                        />
                        {this.validator.message('newPassword', this.state.newPassword, 'required|min:8,string')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.newPassword, 'required') && (
                          <FormHelperText id="newPassword" error>
                            {t('validation.required', {field: t('changePassword.newPassword')})}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm &&
                          this.validator.check(this.state.newPassword, 'required') &&
                          !this.validator.check(this.state.newPassword, 'min:8,string') && (
                          <FormHelperText id="newPassword" error>
                            {t('changePassword.labelNewPassword')}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('changePassword.confirmPassword')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl className="width_100 padding-item-0">
                        <TextField
                          placeholder={t('errorFields.confirmPasswordRequired')}
                          margin="dense"
                          variant="outlined"
                          type="password"
                          name="confirmPassword"
                          onChange={(event) => onChangeTextField(this, event)}
                          className="field_size_22 field_min_size_300"
                        />
                        {this.validator.message(
                            'confirmPassword',
                            this.state.confirmPassword,
                            `required|min:8,string|in:${this.state.newPassword}`,
                        )}
                        {this.state.isSubmitForm && !this.validator.check(this.state.confirmPassword, 'required') && (
                          <FormHelperText id="confirmPassword" error>
                            {t('errorFields.confirmPasswordRequired')}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm &&
                          this.validator.check(this.state.confirmPassword, 'required') &&
                          !this.validator.check(this.state.confirmPassword, 'min:8,string') && (
                          <FormHelperText id="confirmPassword" error>
                            {t('errorFields.passwordMin')}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm &&
                          this.validator.check(this.state.confirmPassword, 'required') &&
                          this.validator.check(this.state.confirmPassword, 'min:8,string') &&
                          !this.validator.check(this.state.confirmPassword, `in:${this.state.newPassword}`) && (
                          <FormHelperText id="confirmPassword" error>
                            {t('errorFields.confirmPasswordMatch')}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Paper>
                <br></br>
                <Grid container spacing={2}>
                  <Grid container justify="flex-end" alignItems="center" item xs={12}>
                    {permission.canUpdate && (
                      <Button
                        color="primary"
                        variant="contained"
                        className="button_margin"
                        onClick={() => this.changePassword(t)}
                        endIcon={<CloudUploadIcon />}
                      >
                        {t('common.btnUpdate')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <br></br>
                <Dialog
                  open={this.state.flag}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    onClickOk={this.handleButtonOk}
                    onClickCancel={this.handleButtonCancel}
                    message={this.state.message}
                    okButtonText={t('common.btnUpdate')}
                    cancelButtonText={t('common.btnClose')}
                  ></SelectModal>
                </Dialog>
              </Container>
            </Card>
            <br></br>
          </Container>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authen: state.authen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: (currentPassword, newPassword, props) =>
      dispatch(changePassword(currentPassword, newPassword, props)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
