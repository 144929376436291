export const ACTION_TYPES = {
  GET_LIST_SCREEN_START: 'GET_LIST_SCREEN_START',
  GET_LIST_SCREEN_SUCCESS: 'GET_LIST_SCREEN_SUCCESS',
  GET_LIST_SCREEN_FAIL: 'GET_LIST_SCREEN_FAIL',
};

const initialState = {
  isLoading: false,
  listScreen: [],
};

/**
 * function reduce homepage
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.GET_LIST_SCREEN_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.GET_LIST_SCREEN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listScreen: action.listScreen,
      };
    case ACTION_TYPES.GET_LIST_SCREEN_FAIL:
      return {
        ...state,
        isLoading: false,
        listScreen: [],
      };
    default:
      return state;
  }
}
