import React, {Component} from 'react';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  Card,
  Container,
  Grid,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  TextField,
  Button,
  Backdrop,
  Paper,
  Modal,
  Dialog,
  FormHelperText,
  FormGroup,
  Checkbox,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import DetailIcon from '@material-ui/icons/Update';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import Carousel from 'react-material-ui-carousel';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

import {ROUTE, DELIVERY_STATUS, LANGUAGE, INDUSTRY_DELIVERY, ARRANGEMENT_METHOD, PERMISSION_ACTIONS} from '../../../common/constant';
import ImageUpload from '../../../components/imageSelect';
import Memo from '../../../components/memo';
import NoteSelect from '../../../components/noteSelect';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getListSupplier, getListFacilityBySupplierID, getListCategory, getAllCountryCode} from '../../../stores/common/actions';
import {getDelivery, updateDelivery, createDelivery, getPaymentMethod} from '../../../stores/delivery/action';
import {redirectRouter, onChangeSelect, onChangeTextField, onChangeListData, backForwardRouter} from '../../../utils/common';

/**
 * Delivery Form
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      supplier_id: '',
      supplier_name: {},
      supplier_facility: '',
      industry: '',
      country: '',
      product_id: '',
      sale_status: '',
      language: '',
      supplier_facility_contact: '',
      payment_method_ids: [],
      payment_method: '',
      arrangement_methods: [],
      sale_time_from: null,
      sale_time_to: null,
      product_type: '',
      product_name: '',
      description: '',
      image_master_ids: [],
      memos: [],
      category_id: null,
      category_medium_id: null,
      caution_id: null,
      caution_content: '',
      note: '',
      sales_date_specified: '',

      listCategory: [],
      listCategoryMedium: [],
      isOpenModal: false,
      modalType: '',
      checkArea: false,
      payload: {},
      flg: false,
      uptime_status_from: null,
      uptime_status_to: null,
      isSubmitForm: false,
      flgSaleCalendar: false,
      messageSaleCalendar: '',
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentWillUpdate
   */
  componentDidMount() {
    this.props.getPaymentMethod();
    this.props.getAllCountryCode();
    this.props.getListSupplier();
    this.props.getListCategory('').then((result) => {
      this.setState({
        listCategoryMedium: result,
      });
    });
    if (this.props.match.params.id) {
      const id = this.props.match.params.id;
      this.props.getDelivery(id).then(
        (result) => {
          if (result) {
            this.setState({
              id: result.id,
              supplier_id: result.supplier_code,
              sale_status: result.sale_status,
              country: result.country_id,
              language: result.language,
              supplier_name: {
                id: result.supplier_facility_id,
                supplier_id: result.supplier_name,
                supplier_name: result.supplier_name,
              },
              supplier_facility: {
                id: result.supplier_facility_id,
                facility_id: result.supplier_facility_code,
                facility_name: result.supplier_facility_name,
              },
              product_id: result.product_code,
              industry: result.industry,
              supplier_facility_contact: result.supplier_facility_contact,
              payment_method_ids: result.payment_method_ids,
              payment_method: result.payment_method_ids,
              arrangement_methods: result.arrangement_methods === null ? [] : result.arrangement_methods,
              sale_time_from: result.sale_time_from,
              sale_time_to: result.sale_time_to,
              category_id: result.category_id,
              category_medium_id: result.category_medium_id,
              description: result.description,
              image_master_ids: result.images,
              memos: result.memos,
              uptime_status_from: result.uptime_status_from,
              uptime_status_to: result.uptime_status_to,
              caution_id: result.caution_id,
              caution_content: result.caution_content,
              product_name: result.product_name,
              checkArea: true,
            });
            this.props.getListCategory(result.category_medium_id).then((result) => {
              this.setState({
                listCategory: result,
              });
            });
          }
        },
        () => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.PRODUCT_DELIVERY),
      );
    }
    if (this.state.supplier_id) {
      this.props.getListFacilityBySupplierID(this.state.supplier_id);
    }
  }

  /**
   * openImageModal
   */
  openImageModal = () => {
    this.setState({
      isOpenModal: true,
      modalType: 'image',
    });
  };

  openCautionModal = () => {
    this.setState({
      isOpenModal: true,
      modalType: 'caution',
    });
  };

  /**
   * handleButtonCloseModal
   */
  handleButtonCloseModal = () => {
    this.setState({
      isOpenModal: false,
      modalType: '',
    });
  };

  /**
   * changeFacility
   * @param {event} value
   */
  changeFacilityName = (value) => {
    this.setState({supplier_facility: value});
  };

  /**
   *
   * @param {*} event
   */
  onChangeSelectPayment = (event) => {
    this.setState({
      payment_method: event.target.value,
    });
    const paymentMethod = this.state.payment_method_ids;
    if (event.target.value) {
      paymentMethod.push(parseInt(event.target.value));
    } else {
      delete paymentMethod[event.target.value];
    }
    this.setState({
      payment_method_ids: paymentMethod,
    });
  };

  /**
   * changeBusiness
   * @param {event} value
   */
  changeBusiness = (value) => {
    this.setState(
      {
        supplier_name: value,
        supplier_facility: '',
        supplier_id: value.supplier_id,
      },
      () => {
        return this.state.supplier_name ? this.props.getListFacilityBySupplierID(this.state.supplier_name.supplier_id) : null;
      },
    );
  };

  /**
   * onChangeCategory
   * @param {object} event
   */
  onChangeCategory = (event) => {
    const category_medium_id = event.target.value;
    this.props.getListCategory(category_medium_id).then((result) => {
      this.setState({
        listCategory: result,
        category_medium_id: category_medium_id,
      });
    });
  };

  /**
   * handleUpdate
   */
  handleUpdate = () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid()) {
      this.setState({
        flg: true,
        message: 'messageCode.updateConfirmDelivery',
      });
    }
  };

  /**
   * handleButtonUpdateProduct
   * @param {object} formData
   */
  handleButtonUpdateOk = () => {
    this.setState({
      flg: false,
    });
    const payload = {
      id: this.state.id,
      sale_status: this.state.sale_status,
      country_id: this.state.country,
      language: this.state.language,
      supplier_facility_name: this.state.supplier_facility ? this.state.supplier_facility.facility_name : '',
      supplier_facility_id: this.state.supplier_facility ? this.state.supplier_facility.id : 0,
      industry: this.state.industry,
      supplier_facility_contact: this.state.supplier_facility_contact,
      payment_method_ids: this.state.payment_method_ids.filter(Number),
      arrangement_methods: this.state.arrangement_methods.filter(String),
      sale_time_from: this.state.sale_time_from,
      sale_time_to: this.state.sale_time_to,
      category_id: this.state.category_id,
      product_name: this.state.product_name.trim(),
      description: this.state.description.trim(),
      image_master_ids: this.state.image_master_ids ?
        this.state.image_master_ids.map((img) => {
            return img.id;
          }) :
        [],
      caution_id: this.state.caution_id,
      note: this.state.note?.trim(),
      memos: this.state.memos,
    };
    this.props.updateDelivery(payload, this.props);
    this.props.getDelivery(this.props.match.params.id);
  };

  /**
   * changePaymentBy
   * @param {*} value
   * @param {*} item
   */
  changePaymentBy = (value, item) => {
    const arrangement_methodsCopy = this.state.arrangement_methods;
    const index = arrangement_methodsCopy.indexOf(item);
    value ? arrangement_methodsCopy.push(item) : arrangement_methodsCopy.splice(index, 1);

    this.setState({
      arrangement_methods: arrangement_methodsCopy,
    });
  };

  /**
   * handleSaleCalendarOk
   */
  handleSaleCalendarOk() {
    redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.PRODUCT_PRICE_SALES_CALENDAR + '/' + this.state.id);
  }

  /**
   * handleSaleCalendarCancel
   */
  handleSaleCalendarCancel() {
    redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.PRODUCT_DELIVERY);
  }

  /**
   * handleButtonCancel
   */
  handleButtonUpdateCancel() {
    this.setState({
      flg: false,
    });
  }

  /**
   * handleCreate
   */
  handleCreate = () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid()) {
      const payload = {
        supplier_facility_id: this.state.supplier_facility ? this.state.supplier_facility.id : 0,
        industry: this.state.industry,
        sale_status: this.state.sale_status,
        language: this.state.language,
        area_id: this.state.area_id,
        supplier_facility_contact: this.state.supplier_facility_contact,
        category_id: this.state.category_id,
        product_name: this.state.product_name,
        arrangement_methods: this.state.arrangement_methods.filter(String),
        payment_method_ids: this.state.payment_method_ids.filter(Number),
        description: this.state.description,
        image_master_ids: this.state.image_master_ids ?
          this.state.image_master_ids.map((img) => {
              return img.id;
            }) :
          [],
        sale_time_from: this.state.sale_time_from,
        sale_time_to: this.state.sale_time_to,
        country_id: this.state.country,
        caution_id: this.state.caution_id,

        supplier_facility_name: this.state.supplier_facility ? this.state.supplier_facility.facility_name : '',
      };

      this.props.createDelivery(payload, this.props).then((result) => {
        if (result) {
          this.setState({
            id: result.id,
            flgSaleCalendar: true,
            messageSaleCalendar: this.props.t(`${'messageCode.direct.saleCalendar'}`),
          });
        }
      });
    }
  };

  /**
   * Render component
   * @return {object}
   */
  render() {
    const {t, common, delivery, actions} = this.props;

    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
      canUpdateSaleCalendar: actions.includes(PERMISSION_ACTIONS.DESIGNATED_SALES_DATE),
    };

    return (
      <LoadingOverlay active={delivery.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                {this.props.match.params.id ? <h3>{t('route.delivery_detail')}</h3> : <h3>{t('route.delivery_add')}</h3>}
              </Grid>
            </Grid>
          </Container>
          <br></br>

          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper>
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('delivery.basic_inform')}
                    </Grid>
                  </Grid>
                  {/* business person */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessInformation.id')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <TextField
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          variant="outlined"
                          name="supplier_id"
                          InputProps={{
                            readOnly: true,
                          }}
                          disabled={true}
                          onChange={(event) => onChangeTextField(this, event)}
                          value={this.state.supplier_id}
                        />
                        {this.validator.message('supplier_id', this.state.supplier_id, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.supplier_id, 'required') && (
                          <FormHelperText id="supplier_id" error>
                            {t('validation.required', {field: t('businessInformation.id')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessInformation.name')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                          <Autocomplete
                            margin="dense"
                            className="field_size_10 field_min_size_300"
                            options={common?.supplier_list}
                            value={this.state.supplier_name}
                            getOptionLabel={(option) => option.supplier_name || ''}
                            onChange={(event, business) => this.changeBusiness(business)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name={'supplierName'}
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                placeholder={t('placeholder.required', {field: t('businessInformation.name')})}
                              />
                            )}
                          />
                        </FormControl>
                        {this.validator.message('supplier_name', this.state.supplier_id, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.supplier_id, 'required') && (
                          <FormHelperText id="supplier_name" error>
                            {t('validation.required.choose', {field: t('delivery.business_person')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* sales store id*/}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('delivery.storeCode')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <TextField
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          variant="outlined"
                          disabled={true}
                          onChange={(event) => onChangeTextField(this, event)}
                          value={this.state.supplier_facility?.facility_id}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* sales store */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('delivery.storeName')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <Autocomplete
                          margin="dense"
                          className="field_size_10 field_min_size_300"
                          options={common?.facility_list}
                          value={this.state.supplier_facility}
                          getOptionLabel={(option) => option.facility_name || ''}
                          onChange={(event, facility) => this.changeFacilityName(facility)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={'supplierName'}
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              placeholder={t('placeholder.required', {field: t('delivery.storeName')})}
                            />
                          )}
                        />
                        {this.validator.message('supplier_facility', this.state.supplier_facility, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.supplier_facility, 'required') && (
                          <FormHelperText id="supplier_facility" error>
                            {t('validation.required.choose', {field: t('delivery.sales_store')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* industry */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('facility.industry')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'industry',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.industry ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('facility.industry'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.industry}
                            onChange={(event) => onChangeSelect(this, event)}
                          >
                            {INDUSTRY_DELIVERY.map((item, idx) => {
                              return (
                                <MenuItem value={item.id} key={idx}>
                                  {t(`${item.i18n}`)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        {this.validator.message('industry', this.state.industry, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.industry, 'required') && (
                          <FormHelperText id="industry" error>
                            {t('validation.required.choose', {field: t('facility.industry')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* product id */}
                  {this.props.match.params.id ? (
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('delivery.product_id')} <span className="font_color_red">＊</span>
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl error>
                          <TextField
                            className="field_size_10 field_min_size_200"
                            margin="dense"
                            variant="outlined"
                            onChange={(event) =>
                              this.setState({
                                product_id: event.target.value,
                              })
                            }
                            InputProps={{
                              readOnly: true,
                            }}
                            value={this.state.product_id}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  ) : (
                    <></>
                  )}
                  {/* sale status*/}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('delivery.sale_status')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'sale_status',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.sale_status ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('delivery.sale_status'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.sale_status}
                            onChange={(event) => onChangeSelect(this, event)}
                          >
                            {DELIVERY_STATUS.map((item, idx) => {
                              return (
                                <MenuItem value={item.id} key={idx}>
                                  {t(`${item.i18n}`)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        {this.validator.message('sale_status', this.state.sale_status, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.sale_status, 'required') && (
                          <FormHelperText id="sale_status" error>
                            {t('validation.required.choose', {field: t('delivery.sale_status')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* country */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.country')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'country',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.country ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('common.country'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.country}
                            onChange={(e) => onChangeSelect(this, e)}
                          >
                            {common?.country_code.map((item, idx) => (
                              <MenuItem value={item.id} key={idx}>
                                {t(`${item.country_code}`)}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.validator.message('country', this.state.country, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.country, 'required') && (
                          <FormHelperText id="country" error>
                            {t('validation.required.choose', {field: t('common.country')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* language */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('delivery.language')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'language',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.language ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('delivery.language'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.language}
                            onChange={(event) => onChangeSelect(this, event)}
                          >
                            {LANGUAGE.map(({language, i18n}, idx) => {
                              return (
                                <MenuItem value={language} key={idx}>
                                  {t(`${i18n}`)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        {this.validator.message('language', this.state.language, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.language, 'required') && (
                          <FormHelperText id="language" error>
                            {t('validation.required.choose', {field: t('delivery.language')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Paper>
                <br></br>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper>
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('route.product_detail')}
                    </Grid>
                  </Grid>
                  {/* product type */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('delivery.product_type')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'category_medium_id',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.category_medium_id ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('delivery.product_type'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.category_medium_id}
                            onChange={(event) => this.onChangeCategory(event)}
                          >
                            {this.state.listCategoryMedium?.map((item, idx) => {
                              return (
                                <MenuItem value={item.id} key={idx}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        {this.validator.message('category_medium_id', this.state.category_medium_id, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.category_medium_id, 'required') && (
                          <FormHelperText id="category_medium_id" error>
                            {t('validation.required.choose', {field: t('delivery.product_type')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* product type small */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('delivery.product_type_small')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'category_id',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.category_id ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('delivery.product_type_small'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.category_id}
                            onChange={(event) => onChangeSelect(this, event)}
                          >
                            {this.state.listCategory?.map((item, idx) => {
                              return (
                                <MenuItem value={item.id} key={idx}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        {this.validator.message('product_type_small', this.state.category_id, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.category_id, 'required') && (
                          <FormHelperText id="product_type_small" error>
                            {t('validation.required.choose', {field: t('delivery.product_type_small')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* product name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('delivery.product_name')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <TextField
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('delivery.product_name')})}
                          variant="outlined"
                          onChange={(event) =>
                            this.setState({
                              product_name: event.target.value,
                            })
                          }
                          value={this.state.product_name}
                        />
                        {this.validator.message('product_name', this.state.product_name, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.product_name, 'required') && (
                          <FormHelperText id="product_name" error>
                            {t('validation.required', {field: t('delivery.product_name')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* arrangement method */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('delivery.arrangement_method')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <FormGroup row>
                          {ARRANGEMENT_METHOD.map((item) => {
                            const checked = this.state.arrangement_methods?.indexOf(item.id) > -1;
                            return (
                              <FormControlLabel
                                labelPlacement="end"
                                control={<Checkbox checked={checked} onClick={(event) => this.changePaymentBy(event.target.checked, item.id)} className="checkbox_radio" />}
                                label={item.i18n}
                              />
                            );
                          })}
                        </FormGroup>
                        {this.validator.message('arrangement_method', this.state.arrangement_methods, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.arrangement_methods, 'required') && (
                          <FormHelperText id="arrangement_method" error>
                            {t('validation.required', {field: t('delivery.arrangement_method')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* payment method */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.paymentMethod')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'payment_method',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.payment_method ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('common.paymentMethod'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.payment_method}
                            onChange={(event) => this.onChangeSelectPayment(event)}
                          >
                            {delivery.listPayment?.map(({id, code}) => {
                              return (
                                <MenuItem value={id} key={id}>
                                  {t(`${code}`)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        {this.state.isSubmitForm && this.state.payment_method_ids.length === 0 && (
                          <FormHelperText id="payment_method_ids" error>
                            {t('validation.required', {field: t('common.paymentMethod')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* description */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('delivery.description')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <TextField
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('delivery.description')})}
                          variant="outlined"
                          onChange={(event) =>
                            this.setState({
                              description: event.target.value,
                            })
                          }
                          value={this.state.description}
                        />
                        {this.validator.message('description', this.state.description, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.description, 'required') && (
                          <FormHelperText id="description" error>
                            {t('validation.required', {field: t('delivery.description')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Product photo data */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <Button variant="contained" disabled={this.props.match.params.id && !permission.canUpdate} color="primary" component="span" onClick={this.openImageModal}>
                        {t('common.photo')}
                      </Button>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      {this.state.image_master_ids.length > 0 ? (
                        <Carousel autoPlay={false} animation="slide" indicators={true}>
                          {this.state.image_master_ids?.map((item, index) => (
                            <div className="box_image" style={{width: '100%', height: '200px'}}>
                              <img className="cropped_image" style={{width: 'inherit', height: 'inherit'}} src={item.url} alt="cropped" />
                            </div>
                          ))}
                        </Carousel>
                      ) : (
                        <label htmlFor="icon-button-file">
                          <IconButton color="primary" aria-label="upload picture" component="span">
                            <PhotoCamera />
                          </IconButton>
                        </label>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('delivery.sale_period')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container item xs={6} lg={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <FormControl error>
                          <KeyboardDatePicker
                            className="field_size_20 field_min_size_300"
                            margin="dense"
                            autoOk
                            disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            minDate="2000-01-01"
                            placeholder={t('delivery.sale_period_start')}
                            format="yyyy/MM/dd"
                            value={this.state.sale_time_from}
                            minDateMessage="無効な日付です"
                            maxDateMessage="無効な日付です"
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            onChange={(time) =>
                              this.setState({
                                sale_time_from: time,
                              })
                            }
                          />
                          {this.validator.message('sale_period_start', this.state.sale_time_from, 'required')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.sale_time_from, 'required') && (
                            <FormHelperText id="sale_period_start" error>
                              {t('validation.required', {field: t('delivery.sale_period_start')})}
                            </FormHelperText>
                          )}
                          {this.state.isSubmitForm && this.state.sale_time_from && this.state.sale_time_from >= this.state.sale_time_to && (
                            <FormHelperText id="operating_time_start" error>
                              {t('validation.time.more')}
                            </FormHelperText>
                          )}
                        </FormControl>
                        <span style={{padding: '5px'}}></span>
                        <FormControl error>
                          <KeyboardDatePicker
                            className="field_size_20 field_min_size_300"
                            margin="dense"
                            autoOk
                            disableToolbar
                            variant="inline"
                            placeholder={t('delivery.sale_period_end')}
                            inputVariant="outlined"
                            format="yyyy/MM/dd"
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            value={this.state.sale_time_to}
                            onChange={(time) => this.setState({sale_time_to: time})}
                          />
                          {this.validator.message('sale_period_end', this.state.sale_time_to, 'required')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.sale_time_to, 'required') && (
                            <FormHelperText id="sale_period_end" error>
                              {t('validation.required', {field: t('delivery.sale_period_end')})}
                            </FormHelperText>
                          )}
                          {this.state.isSubmitForm && this.state.sale_time_to && this.state.sale_time_from >= this.state.sale_time_to && (
                            <FormHelperText id="operating_time_start" error>
                              {t('validation.time.more')}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                  {this.props.match.params.id && (
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('delivery.operating_time')}
                      </Grid>
                      <Grid container item xs={6} lg={6}>
                        <FormControl error>
                          <TextField
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('delivery.operating_time_start')}
                            variant="outlined"
                            value={this.state.uptime_status_from}
                            InputLabelProps={{shrink: true}}
                            disabled
                          />
                        </FormControl>
                        <span style={{padding: '5px'}}></span>
                        <FormControl error>
                          <TextField
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('delivery.operating_time_end')}
                            variant="outlined"
                            value={this.state.uptime_status_to}
                            InputLabelProps={{shrink: true}}
                            disabled
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}
                  {/* cancellation */}
                  <Grid container spacing={1} className="row_form_item" style={{display: 'none'}}>
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('route.cancellation_fee')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.PRODUCT_DELIVERY_DETAIL + '/' + this.state.id}}>
                        <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<DetailIcon />}>
                          {t('common.btnUpdate')}
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                  {/* Caution Information */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('route.caution')}
                    </Grid>
                    <Grid container alignItems="center" item xs={5} lg={3}>
                      <TextField
                        placeholder={t('messageCode.selectNote')}
                        className="width_100"
                        margin="dense"
                        variant="outlined"
                        InputProps={{
                          readOnly: true,
                        }}
                        value={this.state.caution_content}
                      />
                    </Grid>
                    <Grid container alignItems="center" item xs={1} lg={1}>
                      <Button variant="contained" disabled={this.props.match.params.id && !permission.canUpdate} color="primary" component="span" onClick={this.openCautionModal}>
                        {t('common.btnSelect')}
                      </Button>
                    </Grid>
                  </Grid>
                  {this.props.match.params.id && (
                    <>
                      {/* designated_sales_date_calendar */}
                      {permission.canUpdateSaleCalendar && (
                        <Grid container spacing={1} className="row_form_item">
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            {t('delivery.designated_sales_date_calendar')}
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={4}>
                            <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.PRODUCT_PRICE_SALES_CALENDAR + '/' + this.state.id}}>
                              <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                {t('common.btnUpdate')}
                              </Button>
                            </Link>
                          </Grid>
                        </Grid>
                      )}
                      <Grid container alignItems="center" item xs={12} lg={9}>
                        <Memo
                          memos={this.state.memos}
                          onChangeContent={(value) => onChangeListData(this, this.state.memos, value.field, value.index, value.newValue)}
                          note={this.state.note}
                          onChangeNote={(value) => this.setState({note: value})}
                          parentType="medium"
                        />
                      </Grid>
                    </>
                  )}
                </Paper>
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {this.props.match.params.id ? (
                      permission.canUpdate && (
                        <>
                          <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={this.handleUpdate}>
                            {t('common.btnUpdate')}
                          </Button>
                        </>
                      )
                    ) : (
                      <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={this.handleCreate}>
                        {t('common.btnRegister')}
                      </Button>
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      className="button_margin button_color"
                      // onClick={() => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.PRODUCT_DELIVERY)}
                      onClick={this.props.history.goBack}
                    >
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                </Grid>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={this.state.isOpenModal}
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1000',
                  }}
                >
                  {this.state.modalType === 'image' ? (
                    <ImageUpload
                      onClickOk={this.handleButtonCloseModal}
                      onClickClose={this.handleButtonCloseModal}
                      multipleImage={(value) => this.setState({image_master_ids: value.data})}
                      parent_selected={this.state.image_master_ids}
                      multipleSelect
                    ></ImageUpload>
                  ) : (
                    <NoteSelect
                      caution={(value) => this.setState({caution_id: value.id, caution_content: value.caution_content})}
                      onClickOk={this.handleButtonCloseModal}
                      onClickClose={this.handleButtonCloseModal}
                    />
                  )}
                </Modal>
                <Dialog
                  open={this.state.flg}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal onClickOk={this.handleButtonUpdateOk.bind(this)} onClickCancel={this.handleButtonUpdateCancel.bind(this)} message={this.state.message}></SelectModal>
                </Dialog>
                <Dialog
                  open={this.state.flgSaleCalendar}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    onClickOk={this.handleSaleCalendarOk.bind(this)}
                    onClickCancel={this.handleSaleCalendarCancel.bind(this)}
                    message={this.state.messageSaleCalendar}
                  ></SelectModal>
                </Dialog>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    delivery: state.delivery,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDelivery: (params) => dispatch(getDelivery(params)),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getListSupplier: () => dispatch(getListSupplier()),
    getListFacilityBySupplierID: (params) => dispatch(getListFacilityBySupplierID(params)),
    getListCategory: (params) => dispatch(getListCategory(params)),
    updateDelivery: (params, props) => dispatch(updateDelivery(params, props)),
    createDelivery: (params, props) => dispatch(createDelivery(params, props)),
    getPaymentMethod: () => dispatch(getPaymentMethod()),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
