import React, {Component} from 'react';

import {Paper, Container, Button, Grid, Table, TableHead, TableRow, TableCell, TableBody, TextField, Select, MenuItem, FormControl, FormHelperText} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {getListGeofence} from '../../stores/common/actions';
import {searchOneTimePriceList} from '../../stores/one_time_price/actions';
import {customDisplayCurrency, onChangeListData, onChangeListNumberCurrency} from '../../utils/common';

/**
 * One Time Discount Modal
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      isSubmitForm: false,
    };
    this.handleClickOk = this.handleClickOk.bind(this);
    this.handleClickCancel = this.handleClickCancel.bind(this);
    this.validator = new SimpleReactValidator();
  }

  static defaultProps = {
    isReadOnly: false,
    flgDiscountErrorRef: false,
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    if (this.props.dataDiscount?.length > 0) {
      const geofence_ids = this.props.dataDiscount?.map((item) => item.geofence_id);
      this.props.getListGeofence({targetScreen: 'ENABLE_FOR_ADMIN_ALL'}).then((res) => {
        if (res) {
          const tempOneTimeDiscountData = res?.filter((item) => geofence_ids?.includes(item.geofence_id) && this.props.geofence_ids?.includes(item.geofence_id));
          const propsDataOneTime = tempOneTimeDiscountData?.map((item) => {
            const currencyCode = item?.odm_config ? item?.odm_config?.currency_code : item?.jit_h2w_config?.currency_code;
            return {
              geofence_name: item?.name,
              child_price: item?.odm_config ? item?.odm_config?.child_price : item?.jit_h2w_config?.child_price,
              adult_price: item?.odm_config ? item?.odm_config?.adult_price : item?.jit_h2w_config?.adult_price,
              adult_amount:
                currencyCode === 'SGD' ?
                  parseFloat(this.props.dataDiscount?.find((i) => i?.geofence_id === item.geofence_id)?.adult_amount)?.toFixed(2) || '0' :
                  this.props.dataDiscount?.find((i) => i?.geofence_id === item.geofence_id)?.adult_amount || '0',
              child_amount:
                currencyCode === 'SGD' ?
                  parseFloat(this.props.dataDiscount?.find((i) => i?.geofence_id === item.geofence_id)?.child_amount)?.toFixed(2) || '0' :
                  this.props.dataDiscount?.find((i) => i?.geofence_id === item.geofence_id)?.child_amount || '0',
              number_of_user_with_discount: this.props.dataDiscount?.find((i) => i?.geofence_id === item.geofence_id)?.number_of_user_with_discount || '0',
              geofence_id: item?.geofence_id,
              currency_code: item?.odm_config ? item?.odm_config?.currency_code : item?.jit_h2w_config?.currency_code,
              booking_user_limit: item?.odm_config ? item?.odm_config?.booking_user_limit : item?.jit_h2w_config?.booking_user_limit,
            };
          });
          const blankOneTimeDiscountData = res?.filter((item) => this.props.geofence_ids?.includes(item.geofence_id) && !geofence_ids?.includes(item.geofence_id));
          const blankDataOneTime = blankOneTimeDiscountData?.map((item) => ({
            geofence_name: item?.name,
            child_price: item?.odm_config ? item?.odm_config?.child_price : item?.jit_h2w_config?.child_price,
            adult_price: item?.odm_config ? item?.odm_config?.adult_price : item?.jit_h2w_config?.adult_price,
            adult_amount: '0',
            child_amount: '0',
            number_of_user_with_discount: null,
            geofence_id: item?.geofence_id,
            currency_code: item?.odm_config ? item?.odm_config?.currency_code : item?.jit_h2w_config?.currency_code,
            booking_user_limit: item?.odm_config ? item?.odm_config?.booking_user_limit : item?.jit_h2w_config?.booking_user_limit,
          }));
          const dataOneTime = propsDataOneTime?.concat(blankDataOneTime);
          this.setState({
            oneTimeDiscountData: dataOneTime,
          });
        }
      });
    } else {
      this.props.getListGeofence({targetScreen: 'ENABLE_FOR_ADMIN_ALL'}).then((res) => {
        if (res) {
          const tempOneTimeDiscountData = res?.filter((item) => this.props.geofence_ids?.includes(item.geofence_id));
          const dataOneTime = tempOneTimeDiscountData?.map((item) => ({
            geofence_name: item?.name,
            child_price: item?.odm_config ? item?.odm_config?.child_price : item?.jit_h2w_config?.child_price,
            adult_price: item?.odm_config ? item?.odm_config?.adult_price : item?.jit_h2w_config?.adult_price,
            adult_amount: '0',
            child_amount: '0',
            number_of_user_with_discount: null,
            geofence_id: item?.geofence_id,
            currency_code: item?.odm_config ? item?.odm_config?.currency_code : item?.jit_h2w_config?.currency_code,
            booking_user_limit: item?.odm_config ? item?.odm_config?.booking_user_limit : item?.jit_h2w_config?.booking_user_limit,
          }));
          this.setState({
            oneTimeDiscountData: dataOneTime,
          });
        }
      });
    }
    if (this.props.flgDiscountErrorRef) {
      this.setState({
        isSubmitForm: true,
      });
    }
  }

  /**
   * handle click button
   */
  handleClickOk() {
    this.setState({isSubmitForm: true});
    if (this.validator.allValid() && this.validateAmountDiscount()) {
      this.props.onClickOk(this.state.oneTimeDiscountData);
    }
  }

  /**
   * handle click button
   */
  handleClickCancel() {
    this.props.onClickCancel();
  }

  /**
   * convertDataApplicableUser
   * @param {Number} limit_user
   */
  convertDataApplicableUser = (limit_user) => {
    const data = [];
    for (let i = 0; i <= limit_user - 1; i++) {
      data.push(i);
    }
    return data;
  }

  /**
   * handleResetData
   */
  handleResetData = () => {
    const clearData = this.state.oneTimeDiscountData?.map((item) => ({
      currency_code: item?.currency_code,
      booking_user_limit: item?.booking_user_limit,
      geofence_name: item?.geofence_name,
      child_price: item?.child_price,
      adult_price: item?.adult_price,
      adult_amount: '0',
      child_amount: '0',
      number_of_user_with_discount: null,
      geofence_id: item?.geofence_id,
    }));
    this.setState({
      oneTimeDiscountData: clearData,
    });
  }

  /**
   * validateAmountDiscount
   */
  validateAmountDiscount = () => {
    const arrayCheck = [];
    for (let i = 0; i < this.state.oneTimeDiscountData?.length; i++) {
      if (this.state.oneTimeDiscountData[i]?.number_of_user_with_discount > 0) {
        Number(this.state.oneTimeDiscountData[i]?.adult_amount) === Number(this.state.oneTimeDiscountData[i]?.adult_price) &&
          Number(this.state.oneTimeDiscountData[i]?.child_amount) === Number(this.state.oneTimeDiscountData[i]?.child_price) &&
          arrayCheck.push(i);
      }
    }
    return arrayCheck?.length === 0;
  }

  /**
   * validateItemAmountDiscount
   * @param {*} data
   * @return {*}
   */
  validateItemAmountDiscount = (data) => {
    const arrayCheck = [];
    if (data?.number_of_user_with_discount > 0) {
      Number(data?.adult_amount) === Number(data?.adult_price) && Number(data?.child_amount) === Number(data?.child_price) && arrayCheck.push(data);
    }
    return arrayCheck?.length === 0;
  }

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {t} = this.props;
    return (
      <Paper className="field_min_size_900">
        <Container maxWidth="xl">
          <br />
          <div className="scroll_area_600">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" rowSpan={2}>
                    {t('common.geofence')}
                  </TableCell>
                  <TableCell align="center" rowSpan={2} dangerouslySetInnerHTML={{__html: t('sub.applicable_user')?.replaceAll('\n', '<br/>')}}></TableCell>
                  <TableCell align="center" colSpan={2}>
                    {t('sub.amount_discount')}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center">{t('ticket.adult')}</TableCell>
                  <TableCell align="center">{t('ticket.child')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.oneTimeDiscountData?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">
                      {item?.geofence_name}
                      <br />
                      {`(${t('ticket.adult')}: ${customDisplayCurrency(item?.adult_price || 0, item?.currency_code)}, ${t('ticket.child')}: ${customDisplayCurrency(
                        item?.child_price || 0,
                        item?.currency_code,
                      )})`}
                    </TableCell>
                    <TableCell style={{verticalAlign: 'top'}}>
                      <FormControl variant="outlined" margin="dense" fullWidth>
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'number_of_user_with_discount',
                          }}
                          value={this.state.oneTimeDiscountData[index].number_of_user_with_discount}
                          onChange={(event) => onChangeListData(this, this.state.oneTimeDiscountData, 'number_of_user_with_discount', index, event.target.value)}
                          disabled={this.props.isReadOnly}
                        >
                          {this.convertDataApplicableUser(item?.booking_user_limit)?.map((item, idx) => {
                            return (
                              <MenuItem value={item} key={idx}>
                                {item}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message(`number_of_user_with_discount${index}`, this.state.oneTimeDiscountData[index].number_of_user_with_discount, 'required|min:0,num')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.oneTimeDiscountData[index].number_of_user_with_discount, 'required') && (
                          <FormHelperText id="number_of_user_with_discount" error>
                            {t('placeholder.required_select', {field: t('sub.applicable_users')})}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm &&
                          this.validator.check(this.state.oneTimeDiscountData[index].number_of_user_with_discount, 'required') &&
                          !this.validator.check(this.state.oneTimeDiscountData[index].number_of_user_with_discount, 'min:0,num') && (
                            <FormHelperText id="number_of_user_with_discount" error>
                              {t('placeholder.required_select', {field: t('sub.applicable_users')})}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </TableCell>
                    <TableCell align="center" style={{verticalAlign: 'top'}}>
                      <FormControl variant="outlined" margin="dense" className="field_min_size_300 field_max_size_300" fullWidth>
                        <TextField
                          name="adult_amount"
                          inputProps={{min: 0}}
                          variant="outlined"
                          value={this.state.oneTimeDiscountData[index]?.adult_amount || ''}
                          onChange={(event) => onChangeListNumberCurrency(this, event, item?.currency_code, true, this.state.oneTimeDiscountData, index)}
                          disabled={this.props.isReadOnly}
                        />
                        {this.validator.message(
                          `adult_amount${index}`,
                          this.state.oneTimeDiscountData[index]?.adult_amount,
                          `required|numeric|max:${item.adult_price},num|min:0,num`,
                        )}
                        {this.state.isSubmitForm && !this.validator.check(this.state.oneTimeDiscountData[index]?.adult_amount, 'required') && (
                          <FormHelperText id="adult_amount" error>
                            {t('validation.required', {field: t('ticket.adult')})}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm && !this.validator.check(this.state.oneTimeDiscountData[index]?.adult_amount, 'numeric|min:0,num') && (
                          <FormHelperText id="adult_amount" error>
                            {t('validation.number.positive')}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm && !this.validator.check(this.state.oneTimeDiscountData[index]?.adult_amount, `numeric|max:${item.adult_price},num`) && (
                          <FormHelperText id="adult_amount" error>
                            {t('sub.validate.max_adult')}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm &&
                          this.validator.check(this.state.oneTimeDiscountData[index]?.adult_amount, 'required') &&
                          !this.validateItemAmountDiscount(this.state.oneTimeDiscountData[index]) && (
                            <FormHelperText id="adult_amount" error>
                              {t('sub.validate.compare_max_child_adult')}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </TableCell>
                    <TableCell align="center" style={{verticalAlign: 'top'}}>
                      <FormControl variant="outlined" margin="dense" className="field_min_size_300 field_max_size_300" fullWidth>
                        <TextField
                          name="child_amount"
                          inputProps={{min: 0}}
                          variant="outlined"
                          value={this.state.oneTimeDiscountData[index]?.child_amount || ''}
                          onChange={(event) => onChangeListNumberCurrency(this, event, item?.currency_code, true, this.state.oneTimeDiscountData, index)}
                          disabled={this.props.isReadOnly}
                        />
                        {this.validator.message(
                          `child_amount${index}`,
                          this.state.oneTimeDiscountData[index]?.child_amount,
                          `required|numeric|max:${item.child_price},num|min:0,num`,
                        )}
                        {this.state.isSubmitForm && !this.validator.check(this.state.oneTimeDiscountData[index]?.child_amount, 'required') && (
                          <FormHelperText id="child_amount" error>
                            {t('validation.required', {field: t('ticket.child')})}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm && !this.validator.check(this.state.oneTimeDiscountData[index]?.child_amount, 'numeric|min:0,num') && (
                          <FormHelperText id="child_amount" error>
                            {t('validation.number.positive')}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm && !this.validator.check(this.state.oneTimeDiscountData[index]?.child_amount, `numeric|max:${item.child_price},num`) && (
                          <FormHelperText id="child_amount" error>
                            {t('sub.validate.max_child')}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm &&
                          this.validator.check(this.state.oneTimeDiscountData[index]?.adult_amount, 'required') &&
                          !this.validateItemAmountDiscount(this.state.oneTimeDiscountData[index]) && (
                            <FormHelperText id="child_amount" error>
                              {t('sub.validate.max_child')}
                            </FormHelperText>
                          )}
                      </FormControl>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <br></br>
          <Grid container spacing={3}>
            <br></br>
            <Grid container alignItems="center" justify="center" item xs={12}>
              {!this.props.isReadOnly && (
                <Button variant="contained" className="margin-right-24" color="primary" onClick={this.handleClickOk}>
                  {t('common.btnUpdate')}
                </Button>
              )}
              {!this.props.isReadOnly && (
                <Button variant="contained" className="margin-right-24" color="primary" onClick={this.handleResetData}>
                  {t('common.btnClear')}
                </Button>
              )}
              <Button variant="contained" className="margin-right-24 button_color" color="primary" onClick={this.handleClickCancel}>
                {t('common.btnClose')}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <br />
      </Paper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    one_time_price: state.one_time_price,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchOneTimePriceList: (params, queryParams) => dispatch(searchOneTimePriceList(params, queryParams)),
    getListGeofence: (payload) => dispatch(getListGeofence(payload)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
