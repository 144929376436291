import React, {Component} from 'react';

import {Box, Button, Card, Container, FormControl, FormHelperText, Grid, InputLabel, MenuItem,
  Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Dialog, Modal, Paper, Backdrop} from '@material-ui/core';
import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  Update as UpdateIcon,
} from '@material-ui/icons';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

import {DATA_NULL, ROUTE} from '../../../common/constant';
import SelectModal from '../../../components/selectModal';
import {
  configureWillerServiceApi,
  createWillerServiceApi,
  deleteWillerServiceApi,
  disableWillerServiceApi,
  enableWillerServiceApi,
  searchWillerServicesApi,
  getServiceLanguageApi,
  updateServiceLanguageApi,
  getAllLanguageApi,
} from '../../../services/dataManagementServices';
import {getAllCountryCode} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {onChangeListData, onChangeSelect, onChangeTextField} from '../../../utils/common';
import {modalObj} from '../../../utils/modal';
import withPermissionData from '../../../withPermissionData/hoc';


/**
 * Willer Services Management
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      listWillerServices: [],
      id: null,
      serviceType: '',
      enabled: 'true',
      countries: [],

      noResult: false,
      tableRowSelected: false,
      buttonType: '',
      formDisplayed: false,
      formValidating: false,
      confirmModalOpened: false,
      confirmMessage: '',
      addLanguageModalOpened: false,
      countriesDataIndex: [],
      countriesIndex: null,
      listDataLanguage: [],
    };
    this.validator = new SimpleReactValidator();
  };

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    await this.props.getAllCountryCode();
    await getAllLanguageApi().then((res) => {
      if (res?.status === 200) {
        this.setState({
          listDataLanguage: res?.result,
        });
      }
    });
  }

  /**
   * searchWillerServices
   */
  searchWillerServices = async () => {
    const {searchServiceType, searchEnabled} = this.state;
    const willerServices = await searchWillerServicesApi({
      service_type: searchServiceType ? searchServiceType : null,
      enabled: searchEnabled ? searchEnabled : true,
    });
    if (willerServices.status === 200) {
      const processingWillerServices = JSON.parse(JSON.stringify(willerServices.result.content));
      const listWillerServices = processingWillerServices?.map((willerService) => {
        willerService.enabledCountries = willerService.countries.filter((country) => country.enabled);
        return {
          ...willerService,
          onFocus: false,
        };
      });
      this.setState({
        listWillerServices,
        noResult: willerServices.result.content.length <= 0,
      });
    }
  }

  /**
   * createWillerService
   */
   createWillerService = async () => {
    this.closeConfirmModal();
    const response = await createWillerServiceApi({
      service_type: this.state.serviceType,
    });
    if (response.status === 200) {
      this.notifyResponse('Created Successfully!');
      this.searchWillerServices();
      this.setState({
        formDisplayed: false,
        formValidating: false,
      });
    } else this.notifyResponse('Create Failed!');
  }

  /**
   * updateWillerService
   */
  updateWillerService = async () => {
    this.closeConfirmModal();
    let isSuccessful;
    if (this.state.enabled === 'true') {
      const updateResponse = await enableWillerServiceApi(this.state.id);

      const countries = [];
      for (const country of this.state.countries) {
        if (country.country_id !== '') {
          countries.push({
            country_id: country.country_id,
            link: country.link,
            order_number: country.order_number,
          });
        }
      }

      const configureResponse = await configureWillerServiceApi({
        service_id: this.state.id,
        countries,
      });

      isSuccessful = updateResponse.status === 200 && configureResponse.status === 200;
    } else {
      const response = await disableWillerServiceApi(this.state.id);
      isSuccessful = response.status === 200;
    }

    if (isSuccessful) {
      this.notifyResponse('Updated Successfully!');
      this.searchWillerServices();
      this.setState({
        tableRowSelected: false,
        formDisplayed: false,
        formValidating: false,
      });
    } else this.notifyResponse('Update Failed!');
  }

  /**
   * deleteWillerService
   */
  deleteWillerService = async () => {
    this.closeConfirmModal();
    const response = await deleteWillerServiceApi(this.state.id);
    if (response.status === 200) {
      this.notifyResponse('Deleted Successfully!');
      this.searchWillerServices();
      this.setState({
        formDisplayed: false,
        tableRowSelected: false,
      });
    } else {
      this.notifyResponse('Delete Failed!');
    }
  }

  /**
   * onClickTableRow
   * @param {number} index
   */
  onClickTableRow = (index) => {
    if (this.state.formDisplayed) {
      return;
    } else {
      const listServices = this.state.listWillerServices.map((service) => ({
        ...service,
        onFocus: false,
      }));
      listServices[index].onFocus = true;
      this.setState({
        listWillerServices: listServices,
        id: listServices[index].id,
        serviceType: listServices[index].service_type,
        enabled: listServices[index].enabled ? 'true' : 'false',
        countries: JSON.parse(JSON.stringify(listServices[index].enabledCountries)),
        tableRowSelected: true,
        formDisplayed: false,
      });
    }
  };

  /**
   * onClickCreateBtn
   */
  onClickCreateBtn = () => {
    const {tableRowSelected} = this.state;
    if (tableRowSelected) {
      const listServices = this.state.listWillerServices.map((service) => ({
        ...service,
        onFocus: false,
      }));
      this.setState({
        listWillerServices: listServices,
        serviceType: '',
        enabled: 'true',
        formDisplayed: true,
        tableRowSelected: false,
      });
    } else {
      this.setState({
        serviceType: '',
        formValidating: false,
        formDisplayed: true,
      });
    }
  };

  /**
   * onClickUpdateBtn
   */
  onClickUpdateBtn = () => {
    this.setState({formDisplayed: true});
  }

  /**
   * onAddCountryBtn
   */
  onAddCountryBtn = () => {
    this.setState({
      countries: [
        ...this.state.countries,
        {
          country_id: '',
          link: '',
          enabled: true,
        },
      ],
    });
  };

  /**
   * onAddCountryBtn
   */
   onAddLanguageCountryBtn = () => {
    this.setState({
      countriesDataIndex: [
        ...this.state.countriesDataIndex,
        {
          language_id: '',
          content: '',
          delete_flag: false,
          enable: true,
        },
      ],
    });
  };

  /**
   * onDeleteCountryBtn
   * @param {number} index
   */
  onDeleteCountryBtn = (index) => {
    const countries = [
      ...this.state.countries,
    ];
    countries.splice(index, 1);
    this.setState({
      countries,
    });
  }

  /**
   * confirmByButtonType
   */
  confirmByButtonType = () => {
    if (this.state.buttonType === 'create') {
      this.createWillerService();
    } else if (this.state.buttonType === 'update') {
      this.updateWillerService();
    } else if (this.state.buttonType === 'delete') {
      this.deleteWillerService();
    } else {
      return;
    }
  }

  /**
   * onClickCancelBtn
   */
  onClickCancelBtn = () => {
    if (this.state.tableRowSelected) {
      const listServices = this.state.listWillerServices.map((service) => ({
        ...service,
        onFocus: false,
      }));
      this.setState({
        listWillerServices: listServices,
        formDisplayed: false,
        tableRowSelected: false,
      });
    } else this.setState({formDisplayed: false});
  }

  /**
   * openConfirmModal
   * @param {string} buttonType
   */
  openConfirmModal = (buttonType) => {
    this.setState({formValidating: true});
    if (!this.validator.allValid()) return;
    let message = '';
    if (buttonType !== undefined) {
      message = 'Are you sure to delete this Willer Service?';
    } else if (this.state.tableRowSelected) {
      buttonType = 'update';
      message = 'Are you sure to update this Willer Service\'s information?';
    } else {
      buttonType = 'create';
      message = 'Are you sure to create this Willer Service?';
    }
    this.setState({
      buttonType,
      confirmMessage: message,
      confirmModalOpened: true,
    });
  }

  /**
   * closeConfirmModal
   */
  closeConfirmModal = () => {
    this.setState({
      formValidating: false,
      confirmModalOpened: false,
    });
  }

  /**
   * handleOpenAddLanguageModal
   * @param {Number} id
   */
  handleOpenAddLanguageModal = async (id) => {
    const response = await getServiceLanguageApi(id);
    if (response.status === 200) {
      this.setState({
        addLanguageModalOpened: true,
        countriesIndex: id,
        countriesDataIndex: response.result,
      });
    }
  }

  /**
   * handleAddLanguage
   * @param {Number} serviceId
   */
  handleAddLanguage = async (serviceId) => {
    const languages = this.state.countriesDataIndex?.map((item) =>({
      is_enable: item?.enable,
      language_id: item?.language_id,
      content: item?.content,
    }));
    const payload = {
      willer_service_id: serviceId,
      languages: languages,
    };
    const response = await updateServiceLanguageApi(payload);
    if (response.status === 200) {
      this.notifyResponse('Updated Successfully!');
      this.setState({
        addLanguageModalOpened: false,
      });
    } else {
      this.notifyResponse('Updated Fail!');
      this.setState({
        addLanguageModalOpened: false,
      });
    }
  }

  /**
   * closeAddLanguageModal
   */
  closeAddLanguageModal = () => {
    this.setState({
      addLanguageModalOpened: false,
    });
  }

  /**
   * notifyResponse
   * @param {string} message
   */
  notifyResponse = (message) => {
    this.props.setMessageModal(modalObj(true, message));
  }

  /**
   * Render Component
   * @return {component}
   */
  render() {
    const {t, common} = this.props;

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid item xs={6} container alignItems="center">
              <h3>{t('service.willerManagement')}</h3>
            </Grid>
            <Grid item xs={6} container alignItems="center" justifyContent="flex-end">
              <Link
                style={{textDecoration: 'none'}}
                to={{pathname: ROUTE.LAYOUT + ROUTE.DATA_MANAGEMENT}}
              >
                <Button
                  color="primary"
                  variant="contained"
                  className="button_margin button_color"
                  endIcon={<ArrowBackIcon />}
                >
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="xl">
          <Box p={1} m={1}>
            <Card raised>
              <Container maxWidth="xl">
                <Box p={2} m={2}>
                  <Grid container alignItems="flex-start">
                    <Grid item xs={12}>
                      <h1 className="product_entry_table_header_color font_size_mid font_color_white title">
                        {t('service.willerManagement')}
                      </h1>
                      <Grid container spacing={1} alignItems="flex-start">
                        <Grid item xs={12} container spacing={1} alignItems="flex-start">
                          {/* Search Sevice Type */}
                          <Grid item xs={3}>
                            <TextField
                              className="width_100"
                              name="searchServiceType"
                              label="Service Type"
                              variant="outlined"
                              margin="dense"
                              onChange={(e) => onChangeTextField(this, e)}
                            />
                          </Grid>
                          {/* Search Enabled */}
                          <Grid item xs={3}>
                            <FormControl variant="outlined" margin="dense" className="width_100">
                              <InputLabel htmlFor="searchEnabled">{t('service.enable')}</InputLabel>
                              <Select
                                label="Enabled"
                                margin="dense"
                                native
                                inputProps={{
                                  name: 'searchEnabled',
                                  id: 'searchEnabled',
                                }}
                                onChange={(e) => onChangeSelect(this, e)}
                              >
                                <option value='true'>TRUE</option>
                                <option value='false'>FALSE</option>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={2} container alignItems="center">
                            <Button
                              color="primary"
                              variant="contained"
                              disabled={this.state.formDisplayed}
                              className="button_margin"
                              endIcon={<SearchIcon />}
                              onClick={() => this.searchWillerServices()}
                            >
                              {t('common.btnSearch')}
                            </Button>
                          </Grid>
                        </Grid>
                        {this.state.listWillerServices.length > 0 && (
                          <Grid item xs={12} container alignItems="flex-start">
                            <div className="scroll_area_800">
                              <Table aria-label="sticky table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell className="width_300p">
                                      {t('service.type')}
                                    </TableCell>
                                    <TableCell className="width_300p">
                                      {t('service.enable')}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {this.state.listWillerServices.map((service, index) => (
                                    <TableRow
                                      className={service.onFocus ? 'cursor_pointer background_pink' : 'cursor_pointer'}
                                      key={index}
                                      onClick={() => this.onClickTableRow(index)}
                                    >
                                      <TableCell>
                                        {service.service_type ? service.service_type : DATA_NULL}
                                      </TableCell>
                                      <TableCell>
                                        {String(service.enabled).toUpperCase()}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </div>
                          </Grid>
                        )}
                        {this.state.noResult && (
                          <h5 className="tex_grey">{t('service.notFound')}</h5>
                        )}
                      </Grid>
                      <Grid item xs={12} container spacing={1} alignItems="flex-start">
                        <Grid item>
                          <Button
                            color="primary"
                            variant="contained"
                            disabled={this.state.formDisplayed && this.state.tableRowSelected}
                            className="button_margin button_color_green"
                            endIcon={<AddIcon />}
                            onClick={() => this.onClickCreateBtn()}
                          >
                            {t('common.btnRegister')}
                          </Button>
                        </Grid>
                        {this.state.tableRowSelected && (
                          <>
                            <Grid item>
                              <Button
                                color="primary"
                                variant="contained"
                                className="button_margin button-color-update"
                                endIcon={<UpdateIcon />}
                                onClick={() => this.onClickUpdateBtn()}
                              >
                                {t('common.btnUpdate')}
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                color="primary"
                                variant="contained"
                                className="button_margin button_color_red"
                                endIcon={<DeleteIcon />}
                                onClick={() => this.openConfirmModal('delete')}
                              >
                                {t('common.btnDelete')}
                              </Button>
                            </Grid>
                          </>
                        )}
                      </Grid>
                      {this.state.formDisplayed && (
                        <Grid container spacing={1} alignItems="flex-start">
                          {/* Service Type */}
                          <Grid item xs={12}>
                            <FormControl className="field_size_20 field_min_size_250">
                              <TextField
                                disabled={this.state.tableRowSelected ? true : false}
                                margin="dense"
                                variant="outlined"
                                InputLabelProps={{shrink: true}}
                                name="serviceType"
                                value={this.state.serviceType}
                                label={(
                                  <span>
                                    {t('service.type')} <span className="font_color_red">*</span>
                                  </span>
                                )}
                                onChange={(e) => onChangeTextField(this, e)}
                              />
                              {this.validator.message('serviceType', this.state.serviceType, 'required')}
                              {this.state.formValidating && !this.validator.check(this.state.serviceType, 'required') && (
                                <FormHelperText id="serviceType" error>
                                  {t('validation.required', {field: 'Service Type'})}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          {/* Enabled */}
                          <Grid item xs={12}>
                            <FormControl
                              variant="outlined"
                              margin="dense"
                              className="field_size_20 field_min_size_250"
                            >
                              <InputLabel htmlFor="enabled">{t('service.enable')}</InputLabel>
                              <Select
                                disabled={this.state.tableRowSelected ? false : true}
                                margin="dense"
                                native
                                label="Enabled"
                                inputProps={{
                                  name: 'enabled',
                                  id: 'enabled',
                                }}
                                value={this.state.enabled}
                                onChange={(e) => onChangeTextField(this, e)}
                              >
                                <option value='true'>TRUE</option>
                                <option value='false'>FALSE</option>
                              </Select>
                            </FormControl>
                          </Grid>
                          {this.state.tableRowSelected && this.state.enabled === 'true' && (
                            <Grid item xs={12}>
                              <Table aria-label='caption table'>
                                {this.state.countries.length < Object.keys(common.country_code).length && (
                                  <caption>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      endIcon={<AddIcon />}
                                      className="button_margin button_color_green"
                                      onClick={() => this.onAddCountryBtn()}
                                    >
                                      {t('common.btnAdd')}
                                    </Button>
                                  </caption>
                                )}
                                <TableHead>
                                  <TableRow>
                                    <TableCell>{t('common.country')}</TableCell>
                                    <TableCell>{t('menu_app.link')}</TableCell>
                                    <TableCell></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {this.state.countries.map((country, index) => (
                                    <TableRow hover key={index} className="cursor_pointer">
                                      {/* Country Code */}
                                      <TableCell>
                                        <FormControl variant="outlined" margin="dense">
                                          <Select
                                            variant="outlined"
                                            displayEmpty
                                            onChange={(e) => onChangeListData(this, this.state.countries, 'country_id', index, e.target.value)}
                                            value={this.state.countries[index].country_id}
                                          >
                                            {common.country_code?.map((code, idx) => {
                                              return (
                                                <MenuItem
                                                  value={code.id}
                                                  key={idx}
                                                  disabled={
                                                    code.id !== this.state.countries[index].country_id &&
                                                    this.state.countries.filter((item) => code.id === item.country_id).length > 0
                                                  }
                                                >
                                                  {t(`${code.country_code}`)}
                                                </MenuItem>
                                              );
                                            })}
                                          </Select>
                                        </FormControl>
                                      </TableCell>
                                      {/* Country Link */}
                                      <TableCell>
                                        <FormControl className="width_100">
                                          <TextField
                                            variant="outlined"
                                            margin="dense"
                                            label="Link"
                                            value={this.state.countries[index].link}
                                            onChange={(e) => onChangeListData(this, this.state.countries, 'link', index, e.target.value)}
                                          />
                                        </FormControl>
                                      </TableCell>
                                      <TableCell>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          endIcon={<AddIcon />}
                                          className="button_margin button_color_green"
                                          onClick={() => this.handleOpenAddLanguageModal(this.state.countries[index].id, index)}
                                        >
                                          {t('common.btnAdd')}
                                        </Button>
                                        {this.state.countries[index].enabled ? (
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            endIcon={<NotInterestedIcon />}
                                            className="button_margin button_color"
                                            onClick={() => onChangeListData(this, this.state.countries, 'enabled', index, false)}
                                          >
                                            {t('common.disable')}
                                          </Button>
                                        ) : (
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            endIcon={<NotInterestedIcon />}
                                            className="button_margin button_color"
                                            onClick={() => onChangeListData(this, this.state.countries, 'enabled', index, true)}
                                          >
                                            {t('common.enable')}
                                          </Button>
                                        )}
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          endIcon={<DeleteIcon />}
                                          className="button_margin button_color_red"
                                          onClick={() => this.onDeleteCountryBtn(index)}
                                        >
                                          {t('common.btnDelete')}
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </Grid>
                          )}
                          <Grid item xs={12} container alignItems="flex-start" spacing={1}>
                            <Grid item>
                              <Button
                                color="primary"
                                variant="contained"
                                className="button_margin"
                                onClick={() => this.openConfirmModal()}
                              >
                                {t('common.btnConfirm')}
                              </Button>
                            </Grid>
                            <Grid item>
                              <Button
                                color="primary"
                                variant="contained"
                                className="button_margin button_color_red"
                                onClick={() => this.onClickCancelBtn()}
                              >
                                {t('common.btnCancel')}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Dialog
                      open={this.state.confirmModalOpened}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: '1',
                      }}
                    >
                      <SelectModal
                        onClickOk={() => this.confirmByButtonType()}
                        onClickCancel={() => this.closeConfirmModal()}
                        message={this.state.confirmMessage}
                      ></SelectModal>
                    </Dialog>
                    <Container>
                      <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={this.state.addLanguageModalOpened}
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                          timeout: 500,
                        }}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          zIndex: '1000',
                        }}
                      >
                        <Paper className="field_min_size_700">
                          <Paper className="field_min_size_700">
                            <Container maxWidth="xl">
                              <br />
                              <Grid container spacing={3}>
                                <Grid container alignItems="center" justify="flex-start" item xs={10}>
                                  <span className="font_size_small_regular" style={{whiteSpace: 'pre-line'}}>
                                    {t('service.addLanguage')}
                                  </span>
                                </Grid>
                                <br></br>
                                <Container>
                                  <Table size="small">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell align="left">{t('service.language')}</TableCell>
                                        <TableCell align="center">{t('service.note')}</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {this.state.countriesDataIndex?.map((item, index) => (
                                        <TableRow hover key={index} className="cursor_pointer">
                                          <TableCell>
                                            <Select
                                              variant="outlined"
                                              displayEmpty
                                              onChange={(e) => onChangeListData(this, this.state.countriesDataIndex, 'language_id', index, e.target.value)}
                                              value={this.state.countriesDataIndex[index].language_id}
                                            >
                                              {this.state.listDataLanguage?.map((code, idx) => {
                                                return (
                                                  <MenuItem
                                                    value={code.id}
                                                    key={idx}
                                                    disabled={
                                                      code.id !== this.state.countriesDataIndex[index].language_id &&
                                                      this.state.countriesDataIndex.filter((item) => code.id === item.language_id).length > 0
                                                    }
                                                  >
                                                    {t(`${code.code}`)}
                                                  </MenuItem>
                                                );
                                              })}
                                            </Select>
                                          </TableCell>
                                          <TableCell>
                                            <FormControl className="width_100">
                                              <TextField
                                                variant="outlined"
                                                margin="dense"
                                                label="Note"
                                                value={item.content}
                                                onChange={(e) => onChangeListData(this, this.state.countriesDataIndex, 'content', index, e.target.value)}
                                              />
                                            </FormControl>
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                      <TableRow>
                                        <TableCell>
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            endIcon={<AddIcon />}
                                            className="button_margin button_color_green"
                                            onClick={() => this.onAddLanguageCountryBtn()}
                                            disabled={this.state.countriesDataIndex?.length >= Object.keys(this.state.listDataLanguage).length }
                                          >
                                            {t('common.btnAdd')}
                                          </Button>
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </Container>
                                <Grid container alignItems="center" justify="center" item xs={6}>
                                  <div style={{width: '200px'}}>
                                    <Button
                                      fullWidth
                                      variant="contained"
                                      color="primary"
                                      onClick={() => this.handleAddLanguage(this.state.countriesIndex)}
                                    >
                                      {t('common.btnOk')}
                                    </Button>
                                  </div>
                                </Grid>
                                <Grid container alignItems="center" justify="center" item xs={6}>
                                  <div style={{width: '200px'}}>
                                    <Button fullWidth variant="contained" className="button_color" color="primary" onClick={() => this.closeAddLanguageModal()}>
                                      {t('common.btnCancel')}
                                    </Button>
                                  </div>
                                </Grid>
                              </Grid>
                            </Container>
                            <br />
                          </Paper>
                        </Paper>
                      </Modal>
                    </Container>
                  </Grid>
                </Box>
              </Container>
            </Card>
          </Box>
        </Container>
      </Card>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
  };
};

export default withPermissionData(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
