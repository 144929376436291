import React, {Component} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {Grid, Button, TextField, Paper, Card, Container, FormControl, Select, MenuItem, Dialog, FormHelperText} from '@material-ui/core';
import {Cancel as CancelIcon} from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import {Autocomplete} from '@material-ui/lab';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {format} from 'date-fns';
import {enGB, ja} from 'date-fns/locale';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {ROUTE, PERMISSION_ACTIONS, DATE_FORMAT} from '../../../common/constant';
import SelectModal from '../../../components/selectModal';
// import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getRouteAutoBusByGeofenceApi, getWayPointAutoBusByRouteApi} from '../../../services/autoBusServices';
import {createAutoBusBooking} from '../../../stores/autobus/action';
import {getAllCountryCode, getListGeofence, getListGeofenceByCountry} from '../../../stores/common/actions';
import {searchListFacility} from '../../../stores/facility/action';
// eslint-disable-next-line import/order
import {searchListDriver} from '../../../stores/driver/action';
// import {getVehiclesByFacility} from '../../../stores/driver_vehicle_association/action';

import './style.css';

/**
 * Automation Bus Booking Component
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      listGeofenceAll: [],
      facilities: [],
      drivers: [],
      users: [],
      waypoints: [],
      routes: [],
      bookings: [
        {
          country_id: '',
          facility_id: '',
          driver_id: '',
          geofence_id: '',
          operation_date: null,
          route_id: null,
          bookings_infor: [
            {
              user_code: '',
              pickup_waypoint_id: null,
              dropoff_waypoint_id: null,
            },
          ],
        },
      ],
      isLoading: false,
      is_check_waypoint: false,
      isSubmitForm: false,
      flg: false,
      message: '',
      openModal: false,
    };
    this.type = this.props.match.url !== ROUTE.LAYOUT + ROUTE.BOOKING_AUTO_BUS_REGISTER ? 'UPDATE' : 'CREATE';
    this.validator = new SimpleReactValidator();
  }

  /**
   * handleButtonClose
   */
  handleButtonClose = () => {
    this.setState({
      openModal: false,
    });
  };

  /**
   * handle Show Dialog confirm call api
   * @param {object} payload
   */
  handleShowDialog = () => {
    const message = this.type === 'CREATE' ? `messageCode.createConfirmAutoBusBooking` : 'messageCode.createConfirmAutoBusBooking';
    this.setState({
      flg: true,
      message: message,
    });
  };

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllCountryCode();
    await this.props.getListGeofence();
  }

  /**
   * handleButtonOk
   * @param {object} formData
   */
  handleButtonModalOk = () => {
    this.setState({
      flg: false,
      isLoading: true,
    });
    if (this.type === 'CREATE') {
      const payload = {
        geofence_id: this.state.bookings[0].geofence_id,
        driver_id: this.state.bookings[0].driver_id,
        route_id: this.state.bookings[0].route_id,
        date: format(this.state.bookings[0].operation_date, 'yyyy-MM-dd') || null,
        bookings: this.state.bookings[0].bookings_infor.map((booking) => {
          return {
            user_code: booking.user_code.trim(),
            pickup_waypoint_id: booking.pickup_waypoint_id,
            dropoff_waypoint_id: booking.dropoff_waypoint_id,
          };
        }),
      };
      this.props.createAutoBusBooking(payload, this.props).then((response) => {
        if (response) {
          this.setState({
            isLoading: false,
          });
        }
      });
    }
  };

  /**
   * handleButtonCancel
   */
  handleButtonModalCancel() {
    this.setState({
      flg: false,
    });
  }

  /**
   * handleButtonOk
   */
  handleButtonOk = () => {
    this.setState({
      openModal: false,
    });
  };

  /**
   * onChangeCountry
   * @param {*} event
   * @param {object} booking
   */
  onChangeCountry = (event, booking) => {
    if (event.target.value) {
      booking.country_id = event.target.value;
      const listGeofenceAll = this.props.common.geofence_list.filter((item) => item.country?.id === event.target.value && item.unitrand_sim_id);
      const bookings = this.state.bookings;
      bookings[0].facility_id = '';
      bookings[0].driver_id = '';
      bookings[0].geofence_id = '';
      bookings[0].route_id = null;
      bookings[0].bookings_infor = this.state.bookings[0].bookings_infor.map((item) => {
        return {
          user_code: item.user_code,
          pickup_waypoint_id: null,
          dropoff_waypoint_id: null,
        };
      });
      bookings[0].route_id = null;
      this.setState({listGeofenceAll, drivers: [], facilities: [], bookings, routes: [], waypoints: []});
    }
  };

  /**
   * onChangeGeofence
   * @param {number} event
   *  @param {number} index
   */
  onChangeGeofence = async (event, index) => {
    if (event.target.value) {
      const payLoad = {
        geofence_id: event.target.value,
        facility_status: '',
        facility_type: '',
        supplier_id: '',
        supplier_name: '',
        facility_id: '',
        facility_name: '',
      };
      const queryParams = {
        page: 0,
        size: 9999,
      };
      const bookings = this.state.bookings;
      bookings[index].facility_id = '';
      bookings[index].driver_id = '';
      bookings[index].geofence_id = event.target.value;
      bookings[index].bookings_infor = this.state.bookings[0].bookings_infor.map((item) => {
        return {
          user_code: item.user_code,
          pickup_waypoint_id: null,
          dropoff_waypoint_id: null,
        };
      });
      bookings[index].route_id = null;
      this.setState({bookings});
      await this.props.searchListFacility(payLoad, queryParams).then((response) => {
        if (response) {
          this.setState({listFacility: response?.content});
        }
      });
      await getRouteAutoBusByGeofenceApi(event.target.value).then((response) => {
        if (response) {
          this.setState({routes: response.result});
        }
      });
    }
  };

  /**
   * changeSupplierFacility
   * @param {event} event
   * @param {number} index
   */
  changeSupplierFacility = async (event, index) => {
    if (event.target.value) {
      const facility = this.state.listFacility.find((item) => item.id === event.target.value);
      const bookings = this.state.bookings;
      bookings[index].facility_id = event.target.value;
      this.setState({bookings});
      const payload = {
        driver_status: null,
        facility_code: facility.facility_id,
        facility_name: null,
        supplier_code: null,
        supplier_name: null,
        driver_name: null,
        driver_code: null,
        gender: null,
        lang: '',
      };
      const queryParams = {
        page: 0,
        size: 9999,
      };
      await this.props.searchListDriver(payload, queryParams, this.props).then((response) => {
        if (response) {
          const drivers = response?.content;
          this.setState({drivers});
        }
      });
    }
  };

  /**
   * onChangeDriver
   * @param {event} event
   * @param {object} value
   * @param {number} index
   */
  onChangeDriver = (event, value, index) => {
    const bookings = this.state.bookings;
    bookings[index].driver_id = value?.id;
    this.setState({bookings});
  };

  /**
   * onChangeUser
   * @param {event} event
   * @param {number} index
   * @param {number} indexBookingInfor
   */
  onChangeUser = (event, index, indexBookingInfor) => {
    const bookings = this.state.bookings;
    bookings[index].bookings_infor[indexBookingInfor].user_code = event.target.value;
    this.setState({bookings});
  };

  /**
   * changeDate
   * @param {Date} date
   * @param {number} index
   */
  onChangeDate = (date, index) => {
    const bookings = this.state.bookings;
    bookings[index].operation_date = date;
    this.setState({bookings});
  };

  /**
   * onChangeRoute
   * @param {object} event
   * @param {number} value
   * @param {number} index
   *
   */
  onChangeRoute = (event, value, index) => {
    const bookings = this.state.bookings;
    bookings[index].route_id = value?.id;
    bookings[index].bookings_infor = this.state.bookings[0].bookings_infor.map((item) => {
      return {
        user_code: item.user_code,
        pickup_waypoint_id: null,
        dropoff_waypoint_id: null,
      };
    });
    if (value) {
      getWayPointAutoBusByRouteApi(value?.id).then((response) => {
        if (response) {
          this.setState({waypoints: response.result});
        }
      });
    }
    this.setState({bookings});
  };

  /**
   * displayOperationHours
   * @return {string}
   */
  displayOperationHours = () => {
    const waypoints = this.state.waypoints;
    if (waypoints.length === 0) {
      return '';
    } else {
      const startTime = waypoints[0]?.bus_stop_time.substring(0, 5);
      const endTime = waypoints[waypoints.length - 1]?.bus_stop_time.substring(0, 5);
      return `${startTime} ~ ${endTime}` || '';
    }
  };

  // /**
  //  * changeOperatingHour
  //  * @param {*} index
  //  * @param {*} indexBookingInfor
  //  * @param {*} value
  //  * @param {*} isStart
  //  */
  // changeTimePickupAndDropOff = async (index, indexBookingInfor, value, isStart) => {
  //   const bookings = this.state.bookings;
  //   isStart ? (bookings[index].bookings_infor[indexBookingInfor].pick_up_time = value || '') : (bookings[index].bookings_infor[indexBookingInfor].drop_off_time = value || '');
  //   this.setState({bookings});
  //   // this.validateOperatingTimeRanges();
  // };

  // /**
  //  * check number 0 -> 9 return '0{number}'
  //  * @param {*} time
  //  * @return {*}
  //  */
  // convertTimeNumber = (time) => {
  //   if (time <= 9) {
  //     return '0' + time;
  //   }
  //   return time;
  // };

  /**
   * handleChangeWayPoint
   * @param {object} value
   * @param {string} type
   * @param {number} index
   * @param {number} indexBookingInfor
   */
  handleChangeWayPoint = (value, type, index, indexBookingInfor) => {
    const bookings = this.state.bookings;
    if (type === 'pick_up') {
      bookings[index].bookings_infor[indexBookingInfor].pickup_waypoint_id = value?.id;
    } else {
      bookings[index].bookings_infor[indexBookingInfor].dropoff_waypoint_id = value?.id;
    }
    if (bookings[index].bookings_infor[indexBookingInfor].pickup_waypoint_id === bookings[index].bookings_infor[indexBookingInfor].dropoff_waypoint_id) {
      this.setState({is_check_waypoint: true});
    } else {
      this.setState({is_check_waypoint: false});
    }
    this.setState({bookings});
  };

  /**
   * addBookingInfor
   * @param {Number} index
   * @param {Number} indexBookingInfor
   */
  addBookingInfor = async (index, indexBookingInfor) => {
    const bookings = this.state.bookings;
    const payload = {
      user_code: '',
      pickup_waypoint_id: '',
      dropoff_waypoint_id: '',
    };
    bookings[index].bookings_infor.splice(indexBookingInfor, 0, payload);
    this.setState({bookings});
  };

  /**
   * deleteBookingInfor
   * @param {Number} index
   * @param {Number} indexBookingInfor
   */
  deleteBookingInfor = (index, indexBookingInfor) => {
    const bookings = this.state.bookings;
    bookings[index].bookings_infor.splice(indexBookingInfor, 1);
    this.setState({bookings});
  };

  // /**
  //  * handleChangeOperationDate
  //  * @param {Date} time
  //  * @param {object} booking_date
  //  */
  // handleChangeOperationDate = (time, booking_date) => {
  //   booking_date = time;
  // };

  /**
   * submitForm
   */
  submitForm = async () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid() && !this.state.is_check_waypoint) {
      this.handleShowDialog();
    }
  };

  /**
   * Render component
   * @return {Component}
   */
  render() {
    const {t, common} = this.props;
    const {isSubmitForm} = this.state;
    const permission = {
      canUpdate: PERMISSION_ACTIONS.UPDATE,
      canRegister: PERMISSION_ACTIONS.REGISTER,
    };
    this.validator.purgeFields();

    return (
      <LoadingOverlay active={common.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{this.type === 'UPDATE' ? t('auto_bus_booking.form') : t('auto_bus_booking.register')}</h3>
              </Grid>
            </Grid>
          </Container>
          <br></br>
          <Container maxWidth="xl">
            <Card raised>
              {this.state.bookings.map((booking, index) => {
                return (
                  <>
                    <br></br>
                    <Grid container spacing={1} justifyContent="center">
                      <Grid container alignItems="center" item xs={8} lg={8} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                        {t('auto_bus_booking.form')}
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container xs={8} lg={8} className="booking-box">
                        {/* Country */}
                        <Grid container spacing={1} className="row_form_item">
                          <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                            {t('common.country')}
                            <span className="font_color_red">＊</span>
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={8}>
                            <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_350">
                              <Select
                                margin="dense"
                                inputProps={{
                                  name: 'country_id',
                                }}
                                displayEmpty
                                renderValue={
                                  booking.country_id
                                    ? undefined
                                    : () => (
                                        <div className="font-12 color-disabled">
                                          {t('placeholder.required_select', {
                                            field: t('common.country'),
                                          })}
                                        </div>
                                      )
                                }
                                value={booking.country_id}
                                onChange={(event) => this.onChangeCountry(event, booking)}
                              >
                                {common.country_code?.map((item, idx) => {
                                  return (
                                    <MenuItem value={item.id} key={idx}>
                                      {t(`${item.country_code}`)}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                              {this.validator.message(`country_id_${index}`, booking.country_id, 'required')}
                              {isSubmitForm && !this.validator.check(booking.country_id, 'required') && (
                                <FormHelperText id={`country_id_${index}`} error>
                                  {t('validation.required.choose', {field: t('common.country')})}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>
                        {/* Geofence */}
                        <Grid container spacing={1} className="row_form_item">
                          <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                            {t('common.geofence')}
                            <span className="font_color_red">＊</span>
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={8}>
                            <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_350">
                              <Select
                                margin="dense"
                                inputProps={{
                                  name: 'geofence_id',
                                }}
                                displayEmpty
                                renderValue={
                                  booking.geofence_id
                                    ? undefined
                                    : () => (
                                        <div className="font-12 color-disabled">
                                          {t('placeholder.required_select', {
                                            field: t('common.geofence'),
                                          })}
                                        </div>
                                      )
                                }
                                value={booking.geofence_id}
                                onChange={(event) => this.onChangeGeofence(event, index)}
                              >
                                {this.state.listGeofenceAll?.map((item, idx) => {
                                  return (
                                    <MenuItem value={item.geofence_id} key={idx} className={!item.enable ? 'disable-option-custom' : ''}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                              {this.validator.message('geofence_id', booking.geofence_id, 'required')}
                              {isSubmitForm && !this.validator.check(booking.geofence_id, 'required') && (
                                <FormHelperText id="geofence_id" error>
                                  {t('validation.required.choose', {field: t('common.geofence')})}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>
                        {/* Facility */}
                        <Grid container spacing={1} className="row_form_item">
                          <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                            {t('auto_bus_booking.saleOffice')}
                            <span className="font_color_red">＊</span>
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={8}>
                            <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_350">
                              <Select
                                margin="dense"
                                inputProps={{
                                  name: 'facility_id',
                                }}
                                displayEmpty
                                renderValue={
                                  booking.facility_id
                                    ? undefined
                                    : () => (
                                        <div className="font-12 color-disabled">
                                          {t('placeholder.required_select', {
                                            field: t('auto_bus_booking.saleOffice'),
                                          })}
                                        </div>
                                      )
                                }
                                value={booking.facility_id}
                                onChange={(event) => this.changeSupplierFacility(event, index)}
                              >
                                {this.state.listFacility?.map((item, idx) => {
                                  return (
                                    <MenuItem value={item.id} key={idx}>
                                      {t(`${item.facility_name}`)}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                              {this.validator.message(`facility_id_${index}`, booking.facility_id, 'required')}
                              {isSubmitForm && !this.validator.check(booking.facility_id, 'required') && (
                                <FormHelperText id={`facility_id_${index}`} error>
                                  {t('validation.required.choose', {field: t('auto_bus_booking.saleOffice')})}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>
                        {/* Driver code */}
                        <Grid container spacing={1} className="row_form_item">
                          <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                            {t('auto_bus_booking.driverCodeAndName')}
                            <span className="font_color_red">＊</span>
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={8}>
                            <FormControl margin="dense">
                              <>
                                <Autocomplete
                                  margin="dense"
                                  className="field_size_10 field_min_size_350"
                                  options={this.state.drivers}
                                  value={this.state.drivers.find((driver) => driver?.id === booking.driver_id) || null}
                                  getOptionLabel={(option) => `${option.driver_code} - ${option.driver_name}` || null}
                                  onChange={(event, value) => this.onChangeDriver(event, value, index)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      name={'driver_code'}
                                      fullWidth
                                      variant="outlined"
                                      margin="dense"
                                      placeholder={t('placeholder.required', {field: t('auto_bus_booking.driverCodeAndName')})}
                                    />
                                  )}
                                />
                                {this.validator.message(`driver_id_${index}`, booking?.driver_id, 'required')}
                                {isSubmitForm && !this.validator.check(booking?.driver_id, 'required') && (
                                  <FormHelperText id={`driver_id_${index}`} error>
                                    {t('validation.required.choose', {field: t('auto_bus_booking.driverCodeAndName')})}
                                  </FormHelperText>
                                )}
                              </>
                            </FormControl>
                          </Grid>
                        </Grid>
                        {/* Route name */}
                        <Grid container spacing={1} className="row_form_item">
                          <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                            {t('auto_bus_booking.routeName')}
                            <span className="font_color_red">＊</span>
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={8}>
                            <FormControl className="padding-item-0" variant="outlined" margin="dense">
                              <Autocomplete
                                margin="dense"
                                className="field_size_10 field_min_size_350"
                                options={this.state.routes}
                                value={this.state.routes.find((route) => route?.id === booking.route_id) || null}
                                getOptionLabel={(option) => `${option.route_name}` || null}
                                onChange={(event, value) => this.onChangeRoute(event, value, index)}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    name={'route_id'}
                                    fullWidth
                                    variant="outlined"
                                    margin="dense"
                                    placeholder={t('placeholder.required', {field: t('auto_bus_booking.routeName')})}
                                  />
                                )}
                              />
                              {this.validator.message(`route_id_${index}`, booking.route_id, 'required')}
                              {isSubmitForm && !this.validator.check(booking.route_id, 'required') && (
                                <FormHelperText id={`route_id_${index}`} error>
                                  {t('validation.required', {field: t('auto_bus_booking.routeName')})}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>
                        {/* Operation date */}
                        <Grid container spacing={1} className="row_form_item">
                          <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                            {t('auto_bus_booking.operationDate')}
                            <span className="font_color_red">＊</span>
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={8}>
                            <FormControl>
                              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localStorage.getItem('i18nextLng') === 'ja' ? ja : enGB}>
                                <KeyboardDatePicker
                                  className="field_size_20 field_min_size_350"
                                  margin="dense"
                                  autoOk
                                  disableToolbar
                                  variant="inline"
                                  inputVariant="outlined"
                                  placeholder={t('auto_bus_booking.operationDate')}
                                  format={DATE_FORMAT}
                                  value={booking?.operation_date}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                  }}
                                  onChange={(time) => this.onChangeDate(time, index)}
                                  minDate={new Date()}
                                />
                              </MuiPickersUtilsProvider>
                              {this.validator.message(`operation_date_${index}`, booking?.operation_date, 'required')}
                              {isSubmitForm && !this.validator.check(booking?.operation_date, 'required') && (
                                <FormHelperText id={`operation_date_${index}`} style={{color: 'red'}}>
                                  {t('validation.required.choose', {field: t('auto_bus_booking.operationDate')})}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                        </Grid>
                        {/* Operation hours */}
                        {this.state.waypoints.length > 0 && (
                          <Grid container spacing={1} className="row_form_item" style={{padding: '18px 0px'}}>
                            <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                              {t('auto_bus_booking.operationHours')}
                            </Grid>
                            <Grid container alignItems="center" pad item xs={6} lg={8} className="font-weight-600 font-18">
                              {this.displayOperationHours()}
                            </Grid>
                          </Grid>
                        )}
                        <Paper className="search_table">
                          <Grid container spacing={1} className="row_form_item">
                            <Grid
                              container
                              alignItems="center"
                              item
                              xs={12}
                              lg={12}
                              className="product_entry_table_header_color font_color_white font_size_mid search_condition_title"
                            >
                              {t('auto_bus_booking.bookingInformation')}
                            </Grid>
                          </Grid>
                          {booking.bookings_infor.map((booking_infor, indexBookingInfor) => {
                            return (
                              <>
                                <br></br>
                                <Grid container>
                                  <Grid xs={3} lg={1} style={{textAlign: 'center'}}>
                                    <Grid>
                                      <Button color="primary" variant="contained" onClick={() => this.addBookingInfor(index, indexBookingInfor)}>
                                        <AddIcon />
                                      </Button>
                                    </Grid>
                                    <Grid className="margin-top-5">
                                      <Button
                                        color="secondary"
                                        variant="contained"
                                        disabled={booking.bookings_infor?.length === 1}
                                        onClick={() => this.deleteBookingInfor(index, indexBookingInfor)}
                                      >
                                        <CancelIcon />
                                      </Button>
                                    </Grid>
                                  </Grid>
                                  {/* User code */}
                                  <Grid container xs={12} lg={10} className="ticket-box">
                                    <Grid container spacing={1} className="">
                                      <Grid container alignItems="center" item xs={5} lg={3} className="grid_title_padding">
                                        {t('auto_bus_booking.userCode')}
                                        <span className="font_color_red">＊</span>
                                      </Grid>
                                      <Grid container alignItems="center" item xs={5} lg={8}>
                                        <FormControl variant="outlined" margin="dense">
                                          <div className="box-group-input">
                                            <TextField
                                              name="user_code"
                                              className="field_size_20 field_min_size_350"
                                              margin="dense"
                                              placeholder={t('placeholder.required', {field: t('auto_bus_booking.userCode')})}
                                              variant="outlined"
                                              value={booking_infor.user_code}
                                              onChange={(event) => this.onChangeUser(event, index, indexBookingInfor)}
                                            />
                                          </div>
                                          {this.validator.message(`user_code_${indexBookingInfor}`, booking_infor.user_code, 'required')}
                                          {isSubmitForm && !this.validator.check(booking_infor.user_code, 'required') && (
                                            <FormHelperText id={`user_code_${indexBookingInfor}`} error>
                                              {t('validation.required', {field: t('auto_bus_booking.userCode')})}
                                            </FormHelperText>
                                          )}
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                    {/* Pick up */}
                                    <Grid container spacing={1} className="">
                                      <Grid container alignItems="center" item xs={5} lg={3} className="grid_title_padding">
                                        {t('auto_bus_booking.pickUpWaypoint')}
                                        <span className="font_color_red">＊</span>
                                      </Grid>
                                      <Grid container alignItems="center" item xs={6} lg={8}>
                                        <FormControl margin="dense">
                                          <>
                                            <Autocomplete
                                              margin="dense"
                                              className="field_size_10 field_min_size_350"
                                              options={this.state.waypoints}
                                              value={this.state.waypoints.find((point) => point?.id === booking_infor.pickup_waypoint_id) || null}
                                              getOptionLabel={(option) => option.name || ''}
                                              onChange={(event, value) => this.handleChangeWayPoint(value, 'pick_up', index, indexBookingInfor)}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  name={'pick_up'}
                                                  fullWidth
                                                  variant="outlined"
                                                  margin="dense"
                                                  placeholder={t('placeholder.required', {field: t('auto_bus_booking.pickUpWaypoint')})}
                                                />
                                              )}
                                            />
                                            {this.validator.message(`pick_up_${indexBookingInfor}`, booking_infor?.pickup_waypoint_id, 'required')}
                                            {isSubmitForm && !this.validator.check(booking_infor?.pickup_waypoint_id, 'required') && (
                                              <FormHelperText id={`pick_up_${indexBookingInfor}`} error>
                                                {t('validation.required.choose', {field: t('auto_bus_booking.pickUpWaypoint')})}
                                              </FormHelperText>
                                            )}
                                            {isSubmitForm && this.validator.check(booking_infor?.pickup_waypoint_id, 'required') && this.state.is_check_waypoint && (
                                              <FormHelperText id={`pick_up_duplicated_${indexBookingInfor}`} error>
                                                {t('auto_bus_booking.pickupDropoffDuplicated')}
                                              </FormHelperText>
                                            )}
                                          </>
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                    {/* Drop off */}
                                    <Grid container spacing={1} className="">
                                      <Grid container alignItems="center" item xs={5} lg={3} className="grid_title_padding">
                                        {t('auto_bus_booking.dropOffWaypoint')}
                                        <span className="font_color_red">＊</span>
                                      </Grid>
                                      <Grid container alignItems="center" item xs={6} lg={8}>
                                        <FormControl margin="dense">
                                          <>
                                            <Autocomplete
                                              margin="dense"
                                              className="field_size_10 field_min_size_350"
                                              options={this.state.waypoints}
                                              value={this.state.waypoints.find((point) => point?.id === booking_infor.dropoff_waypoint_id) || null}
                                              getOptionLabel={(option) => option.name || ''}
                                              onChange={(event, value) => this.handleChangeWayPoint(value, 'drop_off', index, indexBookingInfor)}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  name={'drop_off'}
                                                  fullWidth
                                                  variant="outlined"
                                                  margin="dense"
                                                  placeholder={t('placeholder.required', {field: t('auto_bus_booking.dropOffWaypoint')})}
                                                />
                                              )}
                                            />
                                            {this.validator.message(`drop_off_${indexBookingInfor}`, booking_infor?.dropoff_waypoint_id, 'required')}
                                            {isSubmitForm && !this.validator.check(booking_infor?.dropoff_waypoint_id, 'required') && (
                                              <FormHelperText id={`drop_off_${indexBookingInfor}`} error>
                                                {t('validation.required.choose', {field: t('auto_bus_booking.dropOffWaypoint')})}
                                              </FormHelperText>
                                            )}
                                          </>
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                    {/* Pickup time */}
                                    {/* <Grid container spacing={1} className="">
                                          <Grid container alignItems="center" item xs={6} lg={4} className="grid_title_padding">
                                            {t('Pick up time')}
                                            <span className="font_color_red">＊</span>
                                          </Grid>
                                          <Grid container alignItems="center" item xs={6} lg={8}>
                                            <FormControl>
                                              <CustomTimePicker
                                                name="pickup_time"
                                                className="field_min_size_350 field_size_20"
                                                value={booking_infor.pick_up_time || null}
                                                showSecond={false}
                                                allowEmpty
                                                placeholder={t('Pick up time')}
                                                onChange={(value) => this.changeTimePickupAndDropOff(index, indexBookingInfor, value, true)}
                                                format={TIME_FORMAT}
                                                use12Hours={false}
                                                autoComplete="off"
                                              />
                                              {this.validator.message(`pick_up_time_${index}`, booking_infor.pick_up_time, 'required')}
                                              {this.state.isSubmitForm && !this.validator.check(booking_infor.pick_up_time, 'required') && (
                                                <FormHelperText id={`pick_up_time_${index}`} style={{color: 'red'}}>
                                                  {t('validation.required', {field: t('vehicles.from')})}
                                                </FormHelperText>
                                              )}
                                            </FormControl>
                                            {this.validator.message(`pick_up_time_${index}`, booking_infor.pick_up_time, 'required')}
                                            {this.state.isSubmitSearch && !this.validator.check(booking_infor.pick_up_time, 'required') && (
                                              <FormHelperText id={`pick_up_time_${index}`} style={{color: 'red'}}>
                                                {t('validation.required.choose', {field: t('Pick up time')})}
                                              </FormHelperText>
                                            )}
                                          </Grid>
                                        </Grid> */}
                                    {/* Dropoff time */}
                                    {/* <Grid container spacing={1} className="">
                                          <Grid container alignItems="center" item xs={6} lg={4} className="grid_title_padding">
                                            {t('Drop off time')}
                                            <span className="font_color_red">＊</span>
                                          </Grid>
                                          <Grid container alignItems="center" item xs={6} lg={8}>
                                            <FormControl>
                                              <CustomTimePicker
                                                name="drop_off_time"
                                                className="field_min_size_350 field_size_20"
                                                value={booking_infor.drop_off_time || null}
                                                showSecond={false}
                                                allowEmpty
                                                placeholder={t('Drop off time')}
                                                onChange={(value) => this.changeTimePickupAndDropOff(index, indexBookingInfor, value, false)}
                                                format={TIME_FORMAT}
                                                use12Hours={false}
                                                autoComplete="off"
                                              />
                                              {this.validator.message(`drop_off_time_${index}`, booking_infor.drop_off_time, 'required')}
                                              {this.state.isSubmitForm && !this.validator.check(booking_infor.drop_off_time, 'required') && (
                                                <FormHelperText id={`drop_off_time_${index}`} style={{color: 'red'}}>
                                                  {t('validation.required', {field: t('vehicles.from')})}
                                                </FormHelperText>
                                              )}
                                            </FormControl>
                                            {this.validator.message(`drop_off_time_${index}`, booking_infor.drop_off_time, 'required')}
                                            {this.state.isSubmitSearch && !this.validator.check(booking_infor.drop_off_time, 'required') && (
                                              <FormHelperText id={`drop_off_time_${index}`} style={{color: 'red'}}>
                                                {t('validation.required.choose', {field: t('Drop off time')})}
                                              </FormHelperText>
                                            )}
                                          </Grid>
                                        </Grid> */}
                                  </Grid>
                                </Grid>
                              </>
                            );
                          })}
                          <br></br>
                        </Paper>
                      </Grid>
                    </Grid>
                  </>
                );
              })}
              <br></br>
              <Grid container spacing={1}>
                <Grid item xs={3}></Grid>
                <Grid item xs={9} container justifyContent="flex-end" style={{paddingRight: '30px'}}>
                  {this.type === 'CREATE' && permission.canRegister && (
                    <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={this.submitForm}>
                      {t('common.btnRegister')}
                    </Button>
                  )}
                  <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.props.history.goBack}>
                    {t('common.btnReturn')}
                  </Button>
                </Grid>
              </Grid>
              <Dialog
                open={this.state.flg}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '1020',
                }}
              >
                <SelectModal
                  okButtonText={t('common.btnYes')}
                  cancelButtonText={t('common.btnCancel')}
                  onClickOk={this.handleButtonModalOk.bind(this)}
                  onClickCancel={this.handleButtonModalCancel.bind(this)}
                  message={this.state.message}
                ></SelectModal>
              </Dialog>
              <br></br>
            </Card>
            <br></br>
          </Container>
          <br></br>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getListGeofence: () => dispatch(getListGeofence()),
    getListGeofenceByCountry: (id) => dispatch(getListGeofenceByCountry(id)),
    searchListFacility: (payload, params) => dispatch(searchListFacility(payload, params)),
    searchListDriver: (payload, params, props) => dispatch(searchListDriver(payload, params, props)),
    createAutoBusBooking: (payload, props) => dispatch(createAutoBusBooking(payload, props)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
