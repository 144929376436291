export const ACTION_TYPES = {
  SEARCH_SIMULATION_START: 'SEARCH_SIMULATION_START',
  SEARCH_SIMULATION_SUCCESS: 'SEARCH_SIMULATION_SUCCESS',
  SEARCH_SIMULATION_FAIL: 'SEARCH_SIMULATION_FAIL',

  GET_DETAIL_SIMULATION_START: 'GET_DETAIL_SIMULATION_START',
  GET_DETAIL_SIMULATION_SUCCESS: 'GET_DETAIL_SIMULATION_SUCCESS',
  GET_DETAIL_SIMULATION_FAIL: 'GET_DETAIL_SIMULATION_FAIL',

  UPDATE_SIMULATION_START: 'UPDATE_SIMULATION_START',
  UPDATE_SIMULATION_SUCCESS: 'UPDATE_SIMULATION_SUCCESS',
  UPDATE_SIMULATION_FAIL: 'UPDATE_SIMULATION_FAIL',

  CLONE_SIMULATION_START: 'CLONE_SIMULATION_START',
  CLONE_SIMULATION_SUCCESS: 'CLONE_SIMULATION_SUCCESS',
  CLONE_SIMULATION_FAIL: 'CLONE_SIMULATION_FAIL',

  GET_SERVICE_GROUP_START: 'GET_SERVICE_GROUP_START',
  GET_SERVICE_GROUP_SUCCESS: 'GET_SERVICE_GROUP_SUCCESS',
  GET_SERVICE_GROUP_FAIL: 'GET_SERVICE_GROUP_FAIL',

  DELETE_SIMULATION_START: 'DELETE_SIMULATION_START',
  DELETE_SIMULATION_SUCCESS: 'DELETE_SIMULATION_SUCCESS',
  DELETE_SIMULATION_FAIL: 'DELETE_SIMULATION_FAIL',

  SEARCH_WILLER_STOP_SET_START: 'SEARCH_WILLER_STOP_SET_START',
  SEARCH_WILLER_STOP_SET_SUCCESS: 'SEARCH_WILLER_STOP_SET_SUCCESS',
  SEARCH_WILLER_STOP_SET_FAIL: 'SEARCH_WILLER_STOP_SET_FAIL',

  VALIDATE_STOP_SET_START: 'VALIDATE_STOP_SET_START',
  VALIDATE_STOP_SET_SUCCESS: 'VALIDATE_STOP_SET_SUCCESS',
  VALIDATE_STOP_SET_FAIL: 'VALIDATE_STOP_SET_FAIL',

  APPLY_SIMULATION_START: 'APPLY_SIMULATION_START',
  APPLY_SIMULATION_SUCCESS: 'APPLY_SIMULATION_SUCCESS',
  APPLY_SIMULATION_FAIL: 'APPLY_SIMULATION_FAIL',
};

const initialState = {
  isLoading: false,
  simulationList: [],
  simulationDetail: {},
  enableSimulation: {},
  cloneSimulation: {},
  serviceGroupList: [],
  stop_set_data: [],
};

/**
 * function simulations
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.SEARCH_SIMULATION_START:
    case ACTION_TYPES.GET_DETAIL_SIMULATION_START:
    case ACTION_TYPES.UPDATE_SIMULATION_START:
    case ACTION_TYPES.CLONE_SIMULATION_START:
    case ACTION_TYPES.GET_SERVICE_GROUP_START:
    case ACTION_TYPES.DELETE_SIMULATION_START:
    case ACTION_TYPES.SEARCH_WILLER_STOP_SET_START:
    case ACTION_TYPES.VALIDATE_STOP_SET_START:
    case ACTION_TYPES.APPLY_SIMULATION_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.SEARCH_SIMULATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        simulationList: action.simulationList,
      };
    case ACTION_TYPES.GET_DETAIL_SIMULATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        simulationDetail: action.simulationDetail,
      };
    case ACTION_TYPES.UPDATE_SIMULATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        enableSimulation: action.enableSimulation,
      };
    case ACTION_TYPES.CLONE_SIMULATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cloneSimulation: action.cloneSimulation,
      };
    case ACTION_TYPES.GET_SERVICE_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        serviceGroupList: action.serviceGroupList,
      };
    case ACTION_TYPES.SEARCH_WILLER_STOP_SET_SUCCESS:
    case ACTION_TYPES.SEARCH_WILLER_STOP_SET_FAIL:
      return {
        ...state,
        isLoading: false,
        stop_set_data: action.stop_set_data,
      };
    case ACTION_TYPES.SEARCH_SIMULATION_FAIL:
    case ACTION_TYPES.GET_DETAIL_SIMULATION_FAIL:
    case ACTION_TYPES.UPDATE_SIMULATION_FAIL:
    case ACTION_TYPES.CLONE_SIMULATION_FAIL:
    case ACTION_TYPES.GET_SERVICE_GROUP_FAIL:
    case ACTION_TYPES.DELETE_SIMULATION_SUCCESS:
    case ACTION_TYPES.DELETE_SIMULATION_FAIL:
    case ACTION_TYPES.VALIDATE_STOP_SET_SUCCESS:
    case ACTION_TYPES.VALIDATE_STOP_SET_FAIL:
    case ACTION_TYPES.APPLY_SIMULATION_SUCCESS:
    case ACTION_TYPES.APPLY_SIMULATION_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
