import {api} from '../services/api';

const getDeliverySettingUrl = `admin/delivery-time`;
const updateDeliverySettingUrl = `admin/delivery-time`;
const getListAreaUrl = `admin/delivery-time/geo-fence`;
const getListPaymentMethodUrl = 'admin/payment-method';

export const getDeliverySettingApi = (payload) => {
  return api.get(getDeliverySettingUrl, payload);
};

export const updateDeliverySettingApi = (payload) => {
  return api.post(updateDeliverySettingUrl, payload);
};

export const getListAreaApi = () => {
  return api.get(getListAreaUrl);
};

export const getListPaymentMethodApi = () => {
  return api.get(getListPaymentMethodUrl);
};
