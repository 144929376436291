import React, {Component} from 'react';

import {Button, Card, Container, Dialog, Grid, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import {
  Description as DetailIcon,
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {DATA_NULL, PERMISSION_ACTIONS, ROUTE} from '../../../common/constant';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {deleteNewsTemplate, getNewsTemplates} from '../../../stores/news_template/action';
import {isRoleBusiness} from '../../../utils/role';

/**
 * Template Setting Search Component
 */
export class SearchNewsTemplate extends Component {
  /**
   * Constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      deleteModalOpened: false,
      idToBeDeleted: null,
    };
  }

  /**
   * Component did mount
   */
  componentDidMount = async () => {
    await this.props.getNewsTemplates();
  }

  /**
   * Handle delete News Template
   */
  handleDeleteTemplate = async () => {
    await this.props.deleteNewsTemplate(this.state.idToBeDeleted);
    this.setState({deleteModalOpened: false, idToBeDeleted: null}, this.props.getNewsTemplates);
  }

  /**
   * Dislay template content in Vietnamese/Japanese/English
   * @param {Object} templateContent
   * @param {Number} languageId
   * @param {String} contentType
   * @return {String}
   */
  dislayTemplateInLanguage = (templateContent, languageId, contentType) => {
    const content = templateContent.find((content) => content.language_id === languageId);
    return content ? content[contentType] : DATA_NULL;
  };

  /**
   * Render Component
   * @return {HTML}
   */
  render() {
    const {t, templates} = this.props;
    const permissions = {
      canDetail: this.props.actions.includes(PERMISSION_ACTIONS.DETAIL_TEMPLATE),
      canRegister: this.props.actions.includes(PERMISSION_ACTIONS.REGISTER_TEMPLATE),
      canDelete: this.props.actions.includes(PERMISSION_ACTIONS.DELETE_TEMPLATE),
      canCreateNews: this.props.actions.includes(PERMISSION_ACTIONS.CREATE_NEWS),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.templateSettingSearch')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.NEWS_MANAGEMENT}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Container>
        <br />
        {permissions.canRegister && (
          <Container maxWidth="xl">
            <Grid container spacing={1}>
              <Grid container item alignItems="center" justify="flex-end">
                <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.TEMPLATE_SETTING_CREATE}}>
                  <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />}>
                    {t('common.btnRegister')}
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Container>
        )}
        <br />
        <Container maxWidth="xl">
          <div className='scroll_area_683'>
            <Table aria-label="sticky table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className='width_200p'>{t('template_management.name')} (JP)</TableCell>
                  <TableCell className='width_200p'>{t('template_management.name')} (VN)</TableCell>
                  <TableCell className='width_200p'>{t('template_management.name')} (EN)</TableCell>
                  <TableCell className='width_200p'>{t('template_management.title')} (JP)</TableCell>
                  <TableCell className='width_200p'>{t('template_management.title')} (VN)</TableCell>
                  <TableCell className='width_200p'>{t('template_management.title')} (EN)</TableCell>
                  <TableCell align='right' className='width_400p'>{t('common.action')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {templates?.content?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{this.dislayTemplateInLanguage(item.lang_contents, 1, 'name')}</TableCell>
                    <TableCell>{this.dislayTemplateInLanguage(item.lang_contents, 2, 'name')}</TableCell>
                    <TableCell>{this.dislayTemplateInLanguage(item.lang_contents, 3, 'name')}</TableCell>
                    <TableCell>{this.dislayTemplateInLanguage(item.lang_contents, 1, 'title')}</TableCell>
                    <TableCell>{this.dislayTemplateInLanguage(item.lang_contents, 2, 'title')}</TableCell>
                    <TableCell>{this.dislayTemplateInLanguage(item.lang_contents, 3, 'title')}</TableCell>
                    <TableCell align='right'>
                      {permissions.canCreateNews && (
                        <Link
                          style={{textDecoration: 'none'}}
                          to={{
                            pathname: ROUTE.LAYOUT + ROUTE.NEWS_REGISTER,
                            state: {template_id: item.id},
                          }}
                        >
                          <Button color='primary' variant='contained' className='button_margin'>
                            {t('route.createNews')}
                          </Button>
                        </Link>
                      )}
                      {permissions.canDetail && (
                        <Link
                          style={{textDecoration: 'none'}}
                          to={{pathname: ROUTE.LAYOUT + ROUTE.TEMPLATE_SETTING_DETAIL + `/${item.id}`}}
                        >
                          <Button color='primary' variant='contained' className='button_margin' endIcon={<DetailIcon />}>
                            {t('common.btnEdit')}
                          </Button>
                        </Link>
                      )}
                      {permissions.canDelete && !isRoleBusiness() && (
                        <Button
                          color='primary'
                          variant='contained'
                          className='button_margin button_color_red'
                          endIcon={<DeleteIcon />}
                          onClick={() => this.setState({deleteModalOpened: true, idToBeDeleted: item.id})}
                        >
                          {t('common.btnDelete')}
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Container>
        <br />
        <Dialog
          open={this.state.deleteModalOpened}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <SelectModal
            onClickOk={this.handleDeleteTemplate}
            onClickCancel={() => this.setState({deleteModalOpened: false})}
            message={t('messageCode.confirm_delete_news_template')}
          />
        </Dialog>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    templates: state.news_templates.templates,
    isLoading: state.news_templates.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNewsTemplates: () => dispatch(getNewsTemplates()),
    deleteNewsTemplate: (templateId) => dispatch(deleteNewsTemplate(templateId)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(SearchNewsTemplate)));
