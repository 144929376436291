export const ACTION_TYPES = {
  SEARCH_LIST_DRIVER_VEHICLE_ASSOCIATION_START: 'SEARCH_LIST_DRIVER_VEHICLE_ASSOCIATION_START',
  SEARCH_LIST_DRIVER_VEHICLE_ASSOCIATION_SUCCESS: 'SEARCH_LIST_DRIVER_VEHICLE_ASSOCIATION_SUCCESS',
  SEARCH_LIST_DRIVER_VEHICLE_ASSOCIATION_FAIL: 'SEARCH_LIST_DRIVER_VEHICLE_ASSOCIATION_FAIL',

  REGISTER_VEHICLE: 'REGISTER_VEHICLE',

  CONFIG_DRIVER_VEHICLE_START: 'CONFIG_DRIVER_VEHICLE_START',
  CONFIG_DRIVER_VEHICLE_SUCCESS: 'CONFIG_DRIVER_VEHICLE_SUCCESS',
  CONFIG_DRIVER_VEHICLE_FAIL: 'CONFIG_DRIVER_VEHICLE_FAIL',

  GET_DRIVER_BY_FACILITY_START: 'GET_DRIVER_BY_FACILITY_START',
  GET_DRIVER_BY_FACILITY_SUCCESS: 'GET_DRIVER_BY_FACILITY_SUCCESS',
  GET_DRIVER_BY_FACILITY_FAIL: 'GET_DRIVER_BY_FACILITY_FAIL',

  GET_VEHICLE_BY_FACILITY_START: 'GET_VEHICLE_BY_FACILITY_START',
  GET_VEHICLE_BY_FACILITY_SUCCESS: 'GET_VEHICLE_BY_FACILITY_SUCCESS',
  GET_VEHICLE_BY_FACILITY_FAIL: 'GET_VEHICLE_BY_FACILITY_FAIL',

  GET_VEHICLE_ACTIVATED_TIMES_START: 'GET_VEHICLE_ACTIVATED_TIMES_START',
  GET_VEHICLE_ACTIVATED_TIMES_SUCCESS: 'GET_VEHICLE_ACTIVATED_TIMES_SUCCESS',
  GET_VEHICLE_ACTIVATED_TIMES_FAIL: 'GET_VEHICLE_ACTIVATED_TIMES_FAIL',

  ASSOCIATION_AUTO_CONFIG_START: 'GET_ASSOCIATION_AUTO_CONFIG_START',
  ASSOCIATION_AUTO_CONFIG_SUCCESS: 'GET_ASSOCIATION_AUTO_CONFIG_SUCCESS',
  ASSOCIATION_AUTO_CONFIG_FAIL: 'GET_ASSOCIATION_AUTO_CONFIG_FAIL',
};

const initialState = {
  isLoading: false,
  listDriverVehicle: {},
  registerVehicle: {},
  driver: [],
  vehicle: [],
  activated_times: [],
  auto_config: false,
};

/**
 * function reduce driver vehicle association
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.CONFIG_DRIVER_VEHICLE_START:
      return {
        ...state,
        isLoading: true,
        isLoadingText: true,
      };
    case ACTION_TYPES.SEARCH_LIST_DRIVER_VEHICLE_ASSOCIATION_START:
    case ACTION_TYPES.GET_DRIVER_BY_FACILITY_START:
    case ACTION_TYPES.GET_VEHICLE_BY_FACILITY_START:
    case ACTION_TYPES.GET_VEHICLE_ACTIVATED_TIMES_START:
    case ACTION_TYPES.ASSOCIATION_AUTO_CONFIG_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.SEARCH_LIST_DRIVER_VEHICLE_ASSOCIATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listDriverVehicle: action.listDriverVehicle,
      };
    case ACTION_TYPES.SEARCH_LIST_DRIVER_VEHICLE_ASSOCIATION_FAIL:
      return {
        ...state,
        isLoading: false,
        listDriverVehicle: [],
      };
    case ACTION_TYPES.REGISTER_VEHICLE:
      return {
        ...state,
        registerVehicle: action.registerVehicle,
      };
    case ACTION_TYPES.CONFIG_DRIVER_VEHICLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingText: false,
      };
    case ACTION_TYPES.CONFIG_DRIVER_VEHICLE_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoadingText: false,
      };
    case ACTION_TYPES.GET_DRIVER_BY_FACILITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        driver: action.driver,
      };
    case ACTION_TYPES.GET_DRIVER_BY_FACILITY_FAIL:
      return {
        ...state,
        isLoading: false,
        driver: [],
      };
    case ACTION_TYPES.GET_VEHICLE_BY_FACILITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        vehicle: action.vehicle,
      };
    case ACTION_TYPES.GET_VEHICLE_BY_FACILITY_FAIL:
      return {
        ...state,
        isLoading: false,
        vehicle: [],
      };
    case ACTION_TYPES.GET_VEHICLE_ACTIVATED_TIMES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        activated_times: action.activated_times,
      };
    case ACTION_TYPES.GET_VEHICLE_ACTIVATED_TIMES_FAIL:
      return {
        ...state,
        isLoading: false,
        activated_times: [],
      };
    case ACTION_TYPES.ASSOCIATION_AUTO_CONFIG_SUCCESS:
    case ACTION_TYPES.ASSOCIATION_AUTO_CONFIG_FAIL:
      return {
        ...state,
        isLoading: false,
        auto_config: action.auto_config,
      };
    default:
      return state;
  }
}
