import React, {Component} from 'react';

import {Box, Button, Card, Container, Dialog, Grid} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {AgGridReact} from 'ag-grid-react/lib/agGridReact';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';

import {CURRENCY, PERMISSION_ACTIONS} from '../../../../common/constant';
import SelectModal from '../../../../components/selectModal';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import withPermissionGateway from '../../../../hoc/withPermissionGateway';
import {searchPlanOrderApi, updatePlanOrderApi} from '../../../../services/businessServices';
import {getListGeofence, getAllCountryCode} from '../../../../stores/common/actions';
import {setMessageModal} from '../../../../stores/modal/actions';
import {modalObj} from '../../../../utils/modal';

/**
 *  Business Plan Management
 */
class BusinessPlanManagement extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    const {t} = props;
    this.state = {
      columnDefs: [
        {
          headerName: t('business_plan.id'),
          field: 'id',
          rowDrag: true,
          flex: 1,
          unSortIcon: true,
        },
        {
          headerName: t('common.country'),
          field: 'country_id',
          flex: 1,
          unSortIcon: true,
          valueFormatter: (params) => {
            return t((this.props.common?.country_code?.find((country) => country.id === params.value)).country_code);
          },
        },
        {
          headerName: t('business_plan.geofence'),
          field: 'geofence_ids',
          flex: 2,
          unSortIcon: true,
          valueFormatter: (params) => {
            return params.value?.map((item) => this.props.common?.geofence_list.find((geofence) => geofence.geofence_id === item)?.name);
          },
        },
        {headerName: t('business_plan.package_name'), field: 'name', flex: 2, unSortIcon: true},
        {
          headerName: t('business_plan.price'),
          field: 'amount',
          valueFormatter: (params) => {
            return CURRENCY.find((currency) => currency.id === params?.data?.currency_code).getLabel(params.value);
          },
          flex: 1,
          unSortIcon: true,
        },
        {headerName: t('business_plan.unit'), field: 'currency_code', flex: 1, unSortIcon: true},
        {headerName: t('business_plan.number_of_people'), field: 'limit_family_user', flex: 1, unSortIcon: true},
      ],
      defaultColDef: {
        width: 100,
        sortable: true,
        headerClass: 'table-header',
        cellClass: 'table-header-cell',
      },
      dataOrder: [],
      sortedData: [],
      isOpenConfirmUpdateModal: false,
    };
  }

  /**
   * onGridReady
   * @param {object} params
   */
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  /**
   * onClickUpdate
   */
  onClickUpdate = () => {
    this.setState({isOpenConfirmUpdateModal: true});
  };

  /**
   * onSortChange
   * @param {object} params
   */
  onSortChange = (params) => {
    const data = [];
    params.api.forEachNodeAfterFilterAndSort((node) => data.push(node.data));
    this.setState({sortedData: data});
  };

  /**
   *
   * @param {object} params
   */
  onDragEnd = (params) => {
    const data = [];
    params.api.forEachNode((node) => data.push(node.data));
    this.setState({sortedData: data});
  };

  /**
   * onConfirmUpdate
   */
  onConfirmUpdate = async () => {
    this.onCloseConfirmUpdateModal();
    const dataParams = [];
    for (let i = 0; i < this.state.sortedData.length; i++) {
      dataParams.push(this.state.sortedData[i].id);
    }
    await updatePlanOrderApi({
      geofence_id: this.props.location.search.split('=')[1],
      ordered_plan_ids: dataParams,
    }).then((response) => {
      if (response.status === 200) this.gridApi.deselectAll();
      this.props.setMessageModal(modalObj(true, response.message_code));
    }, () => this.props.setMessageModal(modalObj(true, this.props.t('Api.fail'))));
  };

  /**
   * onCloseConfirmUpdateModal
   */
  onCloseConfirmUpdateModal = () => {
    this.setState({isOpenConfirmUpdateModal: false});
  };

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getListGeofence();
    await this.props.getAllCountryCode();
    searchPlanOrderApi(this.props.location.search.split('=')[1]).then((response) => {
      this.setState({
        dataOrder: response?.result,
      });
    }, () => this.props.history.goBack);
  }

  /**
   * Render
   * @return {HTMLElement}
   */
  render() {
    const {t, actions} = this.props;
    const {columnDefs, defaultColDef} = this.state;
    const permission = {
      canUpdateManage: actions.includes(PERMISSION_ACTIONS.UPDATE_MANAGE),
    };

    return (
      <div className="StoreManagement">
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" justify="space-between" item>
                <h3>{t('business.facility_delivery.title')}</h3>
              </Grid>
            </Grid>
            <br></br>
            <LoadingOverlay active={this.state.dataOrder.length < 0 || false} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
              <Card raised>
                <Container maxWidth="xl">
                  <Grid container spacing={1}>
                    <Grid container alignItems="center" item xs={6}>
                      <h3>{t('business.facility_delivery.products_section_title')}</h3>
                    </Grid>
                    <div className="ag-theme-alpine store-grid">
                      <AgGridReact
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        rowDragManaged={true}
                        enableMultiRowDragging={true}
                        rowSelection={'multiple'}
                        animateRows={true}
                        onGridReady={this.onGridReady}
                        rowData={this.state.dataOrder}
                        onSortChanged={this.onSortChange}
                        onRowDragEnd={this.onDragEnd}
                      />
                    </div>
                  </Grid>
                </Container>
              </Card>
              <br></br>
              <Box marginTop={1} display="flex" justifyContent="flex-end">
                {permission.canUpdateManage && (
                  <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={this.onClickUpdate}>
                    {t('common.btnUpdate')}
                  </Button>
                )}
                <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.props.history.goBack}>
                  {t('common.btnReturn')}
                </Button>
              </Box>
              <br></br>
            </LoadingOverlay>
            <Dialog
              open={this.state.isOpenConfirmUpdateModal}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '1020',
              }}
            >
              <SelectModal
                onClickOk={this.onConfirmUpdate}
                onClickCancel={this.onCloseConfirmUpdateModal}
                message="business.facility_delivery.update_confirm_message"
              ></SelectModal>
            </Dialog>
          </Container>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getListGeofence: () => dispatch(getListGeofence()),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(BusinessPlanManagement)));
