export const ACTION_TYPES = {
  GET_LINE_STATION_START: 'GET_LINE_STATION_START',
  GET_LINE_STATION_SUCCESS: 'GET_LINE_STATION_SUCCESS',
  GET_LINE_STATION_FAILED: 'GET_LINE_STATION_FAILED',
};

const initialState = {
  isLoading: false,
  lineStation: [],
};

/**
 * function reduce
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.GET_LINE_STATION_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.GET_LINE_STATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        lineStation: action.lineStation,
      };
    case ACTION_TYPES.GET_LINE_STATION_FAILED:
      return {
        isLoading: false,
      };
    default:
      return state;
  }
}
