import React, {Component} from 'react';
import {createRef} from 'react';

import {Container, Grid, TextField, FormControl, Select, MenuItem, InputLabel, Button, Dialog, FormHelperText} from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ForwardIcon from '@material-ui/icons/Forward';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {ACCOUNT_STATUS, COUNTRY} from '../../../common/constant';
import Memo from '../../../components/memo';
import MuiPhoneNumberInput from '../../../components/mui-phone-number-input/index.jsx';
import SelectModal from '../../../components/selectModal';
import {getAccountDetail, updateAccount, getRoleListPermission} from '../../../stores/account/actions';
import {getAllFacilityList} from '../../../stores/common/actions';
import {onChangeListData, onChangeSelect, onChangeTextField} from '../../../utils/common';
import {isRoleBusiness} from '../../../utils/role';

/**
 * Account Update
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      personCharge: '',
      rolePermissionId: '',
      userStatus: '',
      mobile: '',
      note: '',
      supplier_name: '',
      memos: [],
      facilityIds: [],
      facilityOptions: [],

      dataCountry: {},
      isSubmitForm: false,
      updatePersonChargeErr: '',
      flag: false,
      message: '',
      loading: true,
    };
    this.handleButtonOk = this.handleButtonOk.bind(this);
    this.handleButtonCancel = this.handleButtonCancel.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.validator = new SimpleReactValidator();
    this.mobileRef = createRef();
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getRoleListPermission();
    await this.props.getAllFacilityList();

    const {detailId, common} = this.props;
    this.setState({supplier_name: this.props.account?.accountList?.content?.find((item) => item.id === detailId).supplier_name || ''});
    this.props.getAccountDetail(detailId).then((data) => {
      if (data) {
        this.setState({
          id: data.id,
          personCharge: data.person_charge,
          rolePermissionId: data.role_permission_id,
          memos: data.memos,
          mobile: data.mobile,
          userStatus: data.user_status,
          facilityIds: data.facility_ids,
          facilityOptions: isRoleBusiness() ? data.facility_infos : common.facility_list_all,
        });
      }
    });
  }

  /**
   * onChangeMemo
   * @param {int} index
   * @param {string} value
   */
  onChangeMemo = (index, value) => {
    const memoCopy = this.state.memos;
    memoCopy[index].content = value.trim();
    this.setState({rows: memoCopy});
  };

  /**
   * componentWillUpdate
   * @param {props} nextProps
   */
  componentWillUpdate(nextProps) {
    if (this.props.account.accountDetail !== nextProps.account.accountDetail) {
      this.setState({
        loading: false,
      });
    }
  }

  /**
   * handleChangeUpdate
   */
  handleChangeUpdate() {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid()) {
      this.setState({
        flag: true,
        message: 'updateAccount.updateModal',
      });
    }
  }

  /**
   * handleButtonOk
   */
  handleButtonOk() {
    this.setState({
      flag: false,
      updatePersonChargeErr: '',
    });
    this.updateAccount();
  }

  /**
   * updateAccount
   */
  updateAccount() {
    const {id, personCharge, rolePermissionId, userStatus, memos, mobile, note} = this.state;

    const payload = {
      id,
      person_charge: personCharge?.trim(),
      role_permission_id: rolePermissionId,
      role_permission_names: this.props.account.roleListPermission.find((role) => role.id === rolePermissionId).role_permission_name,
      status: userStatus,
      memos: memos,
      mobile: mobile?.replace(/\s/g, ''),
      note: note.trim(),
    };
    this.props.updateAccount(payload, this.props).then(() => {
      this.props.handleSearch();
    });
  }

  /**
   * handleButtonCancel
   */
  handleButtonCancel() {
    this.setState({
      flag: false,
    });
  }

  /**
   * handleClickClose
   */
  handleClickClose() {
    this.props.onClickClose();
  }

  /**
   * Render component
   * @return {object}
   */
  render() {
    const {account, t} = this.props;
    if (this.state.loading) {
      return <LoadingOverlay active={account.loading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner></LoadingOverlay>;
    }

    return (
      <Fade in={true} style={{overflow: 'auto'}}>
        <Paper className="modal_size_mid">
          <Container maxWidth="xl">
            <br />
            <Grid container spacing={1}>
              <Grid container alignItems="center" className="page_header" item xs={10}>
                <img src={`${process.env.PUBLIC_URL}/favicon.ico`} alt="Logo" width="35" height="33"></img>
                <span className="font_bold font_size_big">{t('route.updateAccount')}</span>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" className="page_header" item xs={2}>
                <CancelIcon onClick={this.handleClickClose} className="cursor_pointer" />
              </Grid>

              {/* header title */}
              <Grid container justify="center" alignItems="flex-start" item xs={5} className="border_bottom font_bold">
                {t('updateAccount.before')}
              </Grid>
              <Grid container justify="center" alignItems="flex-start" item xs={2}></Grid>
              <Grid container justify="center" alignItems="flex-start" item xs={5} className="border_bottom font_bold">
                {t('updateAccount.after')}
              </Grid>

              {/* contact name */}
              <Grid container justify="center" alignItems="center" item xs={5}>
                <TextField
                  margin="dense"
                  label={t('accountManagement.contactName')}
                  fullWidth
                  defaultValue={account?.accountDetail?.person_charge}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid container justify="center" alignItems="center" item xs={2}></Grid>
              <Grid container justify="center" alignItems="center" item xs={5}>
                <FormControl className="width_100">
                  <TextField
                    name="personCharge"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    inputProps={{maxLength: 128}}
                    label={
                      <span>
                        {t('accountManagement.contactName')} {t('accountInvite.checkInputMsg')}
                        <span className="font_color_red">＊</span>
                      </span>
                    }
                    value={this.state.personCharge}
                    onChange={(event) => onChangeTextField(this, event)}
                  />
                  {this.validator.message('personCharge', this.state.personCharge, 'required')}
                  {this.state.isSubmitForm && !this.validator.check(this.state.personCharge.trim(), 'required') && (
                    <FormHelperText id="personCharge" error>
                      {t('validation.required', {field: t('accountManagement.contactName')})}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {/* Supplier name */}
              <Grid container justify="center" alignItems="center" item xs={5}>
                <TextField
                  margin="dense"
                  label={t('accountManagement.businessName')}
                  fullWidth
                  defaultValue={this.state.supplier_name || ''}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid container justify="center" alignItems="center" item xs={2}></Grid>
              <Grid container justify="center" alignItems="center" item xs={5}>
                <FormControl className="width_100">
                  <TextField
                    name="personCharge"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    inputProps={{maxLength: 128}}
                    label={
                      <span>
                        {t('accountManagement.businessName')}
                        <span className="font_color_red">＊</span>
                      </span>
                    }
                    value={this.state.supplier_name || ''}
                    disabled={true}
                  />
                </FormControl>
              </Grid>

              {/* Store */}
              <Grid container justify="center" alignItems="center" item xs={5}>
                <FormControl fullWidth>
                  <InputLabel id='facilities_before'>{t('accountType.facilityName')}</InputLabel>
                  <Select
                    labelId='facilities_before'
                    margin='dense'
                    value={this.state.facilityIds}
                    multiple
                  >
                    {(this.state.facilityIds.length > 0) && this.state.facilityOptions?.map(({id, facility_name}) => {
                      return (this.state.facilityIds.includes(id)) && (
                        <MenuItem disabled key={id} value={id}>
                          {facility_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid container justify="center" alignItems="center" item xs={2}></Grid>
              <Grid container justify="center" alignItems="center" item xs={5}>
                <FormControl fullWidth>
                  <InputLabel id='facilities_after'>{t('accountType.facilityName')}</InputLabel>
                  <Select
                    labelId='facilities_after'
                    margin='dense'
                    value={this.state.facilityIds}
                    multiple
                    className='color-disabled font-weight-300'
                  >
                    {(this.state.facilityIds.length > 0) && this.state.facilityOptions?.map(({id, facility_name}) => {
                      return (this.state.facilityIds.includes(id)) && (
                        <MenuItem disabled key={id} value={id}>
                          {facility_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              {/* account type */}
              <Grid container justify="center" alignItems="center" item xs={5}>
                <TextField
                  margin="dense"
                  label={t('accountManagement.accountType')}
                  fullWidth
                  defaultValue={account?.accountDetail?.role_permission_name}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid container justify="center" alignItems="flex-end" item xs={2}>
                <ForwardIcon className="forward_icon"></ForwardIcon>
              </Grid>
              <Grid container justify="center" alignItems="center" item xs={5}>
                <FormControl variant="outlined" fullWidth margin="dense">
                  <InputLabel>
                    {t('accountInvite.accountType')}
                    <span className="font_color_red">＊</span>
                  </InputLabel>
                  <Select
                    labelWidth={165}
                    margin="dense"
                    value={this.state.rolePermissionId}
                    onChange={(event) => onChangeSelect(this, event)}
                    inputProps={{
                      name: 'rolePermissionId',
                    }}
                  >
                    {account?.roleListPermission?.map((item, index) => {
                      return (
                        <MenuItem value={item.id} key={index}>
                          {item.role_permission_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              {/* status */}
              <Grid container justify="center" alignItems="center" item xs={5}>
                <FormControl fullWidth margin="dense">
                  <InputLabel>{t('accountManagement.status')}</InputLabel>
                  <Select
                    labelWidth={165}
                    value={account?.accountDetail?.user_status}
                    inputProps={{
                      name: 'type',
                      readOnly: true,
                    }}
                  >
                    {ACCOUNT_STATUS.map((item, index) => {
                      return (
                        <MenuItem value={item.name} key={index}>
                          {t(`${item.i18n}`)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid container justify="center" alignItems="center" item xs={2}></Grid>
              <Grid container justify="center" alignItems="center" item xs={5}>
                <FormControl variant="outlined" fullWidth margin="dense">
                  <InputLabel>{t('accountManagement.status')}</InputLabel>
                  <Select
                    labelWidth={165}
                    margin="dense"
                    value={this.state.userStatus}
                    onChange={(event) => onChangeSelect(this, event)}
                    inputProps={{
                      name: 'userStatus',
                    }}
                  >
                    {ACCOUNT_STATUS.map((item, index) => {
                      return (
                        <MenuItem value={item.name} key={index}>
                          {t(`${item.i18n}`)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              {/* status */}
              <Grid container justify="center" alignItems="center" item xs={5}>
                <TextField
                  margin="dense"
                  label={t('common.email')}
                  defaultValue={account?.accountDetail?.email}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid container justify="center" alignItems="center" item xs={2}></Grid>
              <Grid container justify="center" alignItems="center" item xs={5}>
                <TextField
                  margin="dense"
                  label={t('common.email')}
                  value={account?.accountDetail?.email}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>

              {/* mobile */}
              <Grid container justify="center" alignItems="center" item xs={5}>
                <TextField
                  label={
                    <span>
                      {t('common.phoneNumber')}
                      <span className="font_color_red">＊</span>
                    </span>
                  }
                  margin="dense"
                  name="mobile"
                  value={account?.accountDetail?.mobile}
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid container justify="center" alignItems="center" item xs={2}></Grid>
              <Grid container justify="center" alignItems="center" item xs={5}>
                <FormControl className="width_100">
                  <MuiPhoneNumberInput
                    ref={this.mobileRef}
                    defaultCountry={'jp'}
                    onlyCountries={COUNTRY.map((c) => c.countryCode)}
                    label={
                      <span>
                        {t('common.phoneNumber')}
                        <span className="font_color_red">＊</span>
                      </span>
                    }
                    countryCodeEditable={false}
                    enableLongNumbers={true}
                    autoFormat={false}
                    margin="dense"
                    variant="outlined"
                    name="mobile"
                    value={this.state.mobile}
                    onChange={(value, country) => {
                      this.setState({mobile: value, dataCountry: country});
                    }}
                  />
                  {this.validator.message(
                    'mobile',
                    this.state.mobile?.trim() && this.state.mobile.slice(this.mobileRef.current.state.selectedCountry.dialCode.length + 1).trim(),
                    'required',
                  )}
                  {this.state.isSubmitForm &&
                    !this.validator.check(
                      this.state.mobile?.trim() && this.state.mobile.slice(this.mobileRef.current.state.selectedCountry.dialCode.length + 1).trim(),
                      'required',
                    ) && (
                    <FormHelperText id="mobile" error>
                      {t('validation.required', {field: t('common.phoneNumber')})}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {/* memo */}
              <Grid container justify="center" alignItems="center" item xs={12}>
                <Container maxWidth="xl">
                  <br></br>
                  <Memo
                    memos={this.state.memos}
                    onChangeContent={(value) => onChangeListData(this, this.state.memos, value.field, value.index, value.newValue)}
                    note={this.state.note}
                    onChangeNote={(value) => this.setState({note: value})}
                    parentType="medium"
                  />
                </Container>
              </Grid>

              <Grid container justify="flex-end" alignItems="center" item xs={12}>
                {this.props.canUpdate && (
                  <Button color="primary" variant="contained" className="button_margin" onClick={() => this.handleChangeUpdate()} endIcon={<CloudUploadIcon />}>
                    {t('common.btnUpdate')}
                  </Button>
                )}
                <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.handleClickClose} endIcon={<CloseIcon />}>
                  {t('common.btnCancel')}
                </Button>
              </Grid>
            </Grid>
            <br></br>
            <Dialog
              open={this.state.flag}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '1020',
              }}
            >
              <>
                <SelectModal
                  onClickOk={this.handleButtonOk}
                  onClickCancel={this.handleButtonCancel}
                  message={this.state.message}
                  okButtonText={t('common.btnUpdate')}
                  cancelButtonText={t('common.btnClose')}
                ></SelectModal>
              </>
            </Dialog>
          </Container>
        </Paper>
      </Fade>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    account: state.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRoleListPermission: () => dispatch(getRoleListPermission()),
    getAccountDetail: (id) => dispatch(getAccountDetail(id)),
    updateAccount: (payload, props) => dispatch(updateAccount(payload, props)),
    getAllFacilityList: () => dispatch(getAllFacilityList()),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
