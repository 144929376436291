import React, {useState, useRef} from 'react';

import {Popover, ListItem} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  popoverContent: {
    pointerEvents: 'auto',
  },
}));

const MyComponent = (props) => {
  const [openedPopover, setOpenedPopover] = useState(false);
  const popoverAnchor = useRef(null);

  const classes = useStyles();

  const popoverEnter = ({currentTarget}) => {
    setOpenedPopover(true);
  };

  const popoverLeave = ({currentTarget}) => {
    setOpenedPopover(false);
  };

  return (
    <div>
      <ListItem
        ref={popoverAnchor}
        id={props.route.pathActive}
        aria-owns={props.pathActive}
        aria-haspopup="true"
        onMouseEnter={popoverEnter}
        onMouseLeave={popoverLeave}
        onClick={props.onClickOwner}
        className="cursor_pointer nav_item"
      >
        {props.content}
      </ListItem>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.popoverContent,
        }}
        open={openedPopover}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{onMouseEnter: popoverEnter, onMouseLeave: popoverLeave, onClick: popoverLeave}}
      >
        {props.popover}
      </Popover>
    </div>
  );
};

export default MyComponent;
