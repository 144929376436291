import React, {Component} from 'react';

import {Container, Card, CardActionArea, CardMedia, CardContent, Typography, Grid, Button} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

import {ROUTE} from '../../../common/constant';
import {redirectRouter} from '../../../utils/common';

/**
 * Common Setting Controller
 */
class Index extends Component {
  /**
   * Render component
   * @return {HTML}
   */
  render() {
    const {t} = this.props;

    return (
      <div>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('route.commonSetting')}</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}>
                <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                  <Button
                    color="primary"
                    variant="contained"
                    className="button_margin button_color"
                    endIcon={<ArrowBackIcon />}
                  >
                    {t('common.btnReturn')}
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <br></br>
            <Container maxWidth="xl">
              <Grid container spacing={1}>
                <Grid container alignItems="center" item xs={12} md={6} lg={3}>
                  <Card raised className="card_size">
                    <CardActionArea
                      onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.RECOMMEND_MANAGEMENT)}
                    >
                      <CardMedia
                        style={{borderBottom: '1px solid #f1ecec'}}
                        className="card_media_size"
                        image={`${process.env.PUBLIC_URL}/images/`} // Todo
                        title={t('route.recommend')}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h3">
                          {t('route.recommend')}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          {t('route.recommend')}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid container alignItems="center" item xs={12} md={6} lg={3}>
                  <Card raised className="card_size">
                    <CardActionArea
                      onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.BANK_MANAGEMENT)}
                    >
                      <CardMedia
                        style={{borderBottom: '1px solid #f1ecec'}}
                        className="card_media_size"
                        image={`${process.env.PUBLIC_URL}/images/`} // Todo
                        title={t('route.bankManagement')}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h3">
                          {t('route.bankManagement')}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          {t('route.bankManagement')}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid container alignItems="center" item xs={12} md={6} lg={3}>
                  <Card raised className="card_size">
                    <CardActionArea onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.RIGHT_MENU_MANAGEMENT)}>
                      <CardMedia
                        style={{borderBottom: '1px solid #f1ecec'}}
                        className="card_media_size"
                        image={`${process.env.PUBLIC_URL}/images/`} // Todo
                        title={t('route.shuttleManagement')}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h3">
                          {t('route.shuttleManagement')}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          {t('route.shuttleManagement')}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid container alignItems="center" item xs={12} md={6} lg={3}>
                  <Card raised className="card_size">
                    <CardActionArea onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.MENU_APP_MANAGEMENT)}>
                      <CardMedia
                        style={{borderBottom: '1px solid #f1ecec'}}
                        className="card_media_size"
                        image={`${process.env.PUBLIC_URL}/images/`} // Todo
                        title={t('route.menuAppManagement')}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h3">
                          {t('route.menuAppManagement')}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          {t('route.menuAppManagement')}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
                <Grid container alignItems="center" item xs={12} md={6} lg={3}>
                  <Card raised className="card_size">
                    <CardActionArea onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.NEWS_MANAGEMENT)}>
                      <CardMedia
                        style={{borderBottom: '1px solid #f1ecec'}}
                        className="card_media_size"
                        image={`${process.env.PUBLIC_URL}/images/`} // Todo
                        title={t('route.newsManagement')}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="h3">
                          {t('route.newsManagement')}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          {t('route.newsManagement')}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              </Grid>
              <br></br>
            </Container>
          </Container>
          <br></br>
        </Card>
      </div>
    );
  }
}

export default withTranslation('translations')(Index);
