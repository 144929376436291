import React, {Component} from 'react';

import {Button, Card, CardActionArea, CardContent, CardMedia, Container, Grid, Typography} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

import {ROUTE} from '../../common/constant';
import {redirectRouter} from '../../utils/common';

/**
 * Product Management view
 */
class Index extends Component {
  /**
   * Render component
   * @return {object}
   */
  render() {
    const {t} = this.props;
    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.product')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          <Container maxWidth="xl">
            <Grid container spacing={1}>
              {/* <Grid container alignItems="center" item xs={6} lg={2}>
                <Card raised className="card_size ">
                  <CardActionArea onClick={() => redirectRouter(this.props, '/maas/vehicles')}>
                    <CardMedia
                      style={{borderBottom: '1px solid #f1ecec'}}
                      className="card_media_size"
                      image={`${process.env.PUBLIC_URL}/images/vehicles.jpg`}
                      title="アクティビティ"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h3">
                        {t('route.vehicles')}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        アクティビティの登録、更新ができます。
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid> */}
              <Grid container alignItems="center" item xs={6} lg={3}>
                <Card raised className="card_size">
                  <CardActionArea onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.PRODUCT_DELIVERY)}>
                    <CardMedia
                      style={{borderBottom: '1px solid #f1ecec'}}
                      className="card_media_size"
                      image={`${process.env.PUBLIC_URL}/images/deliveries.jpeg`}
                      title="アクティビティ"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h3">
                        {t('route.delivery')}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        {t('route.delivery')}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid container alignItems="center" item xs={6} lg={3}>
                <Card raised className="card_size">
                  <CardActionArea onClick={() => redirectRouter(this.props, '/maas/cancellation-fees')}>
                    <CardMedia style={{borderBottom: '1px solid #f1ecec'}} className="card_media_size" image={`${process.env.PUBLIC_URL}/images/fee.png`} title="アクティビティ" />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h3">
                        {t('route.cancellation_fee')}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        アクティビティの登録、更新ができます。
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid container alignItems="center" item xs={6} lg={3}>
                <Card raised className="card_size ">
                  <CardActionArea onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.PRODUCT_NOTICE)}>
                    <CardMedia
                      style={{borderBottom: '1px solid #f1ecec'}}
                      className="card_media_size"
                      image={`${process.env.PUBLIC_URL}/images/information.jpg`}
                      title="アクティビティ"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h3">
                        {t('route.caution')}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        {t('route.caution')}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid container alignItems="center" item xs={6} lg={3}>
                <Card raised className="card_size">
                  <CardActionArea onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.PRODUCT_CONGESTION_STATUS)}>
                    <CardMedia
                      style={{borderBottom: '1px solid #f1ecec'}}
                      className="card_media_size"
                      image={`${process.env.PUBLIC_URL}/images/congestion.jpg`}
                      title="アクティビティ"
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h3">
                        {t('route.congestionStatus')}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        {t('route.congestionStatus')}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Container>
        <br></br>
      </Card>
    );
  }
}
export default withTranslation('translations')(Index);
