import React, {Component} from 'react';

import {Container, Card, CardActionArea, CardContent, Typography, Grid} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {ROUTE} from '../../common/constant';
import {getScreenList} from '../../stores/home/actions';

import './style.css';

/**
 * Home view
 */
class Index extends Component {
  /**
   * componentDidMount
   */
  componentDidMount() {
    this.props.getScreenList();
  }

  /**
   * Render view
   * @return {object}
   */
  render() {
    const {t, home} = this.props;

    return (
      <LoadingOverlay active={home.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={12}>
                <h2>{t('route.home')}</h2>
              </Grid>
            </Grid>
            <br></br>
            <Card raised>
              <Container maxWidth="xl">
                <br />
                {home?.listScreen.map((screen, index) => (
                  <Grid container alignItems="center" item xs={12} lg={12} key={index}>
                    <Grid container alignItems="center" item xs={12} lg={12}>
                      <div className="font_size_mid font_bold title">{t(`${screen.function_name_code}`)}</div>
                    </Grid>
                    <Grid container className="card_header_item" spacing={1}>
                      {screen.sub_functions.length !== 0 ? (
                        screen.sub_functions.map(
                          (sub, i) =>
                            sub.router_key === 'SUB_MENU_ACTIVE' && (
                              <Grid container alignItems="center" item xs={6} lg={2} key={i}>
                                <Link to={{pathname: ROUTE.LAYOUT + sub.path}} className="link_card">
                                  <Card className="home_card_size">
                                    <CardActionArea>
                                      <CardContent>
                                        <div className="font_size_small_regular font_bold">{t(`${sub.function_name_code}`)}</div>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                          {t(`${sub.function_name_code}`)}
                                        </Typography>
                                      </CardContent>
                                    </CardActionArea>
                                  </Card>
                                </Link>
                              </Grid>
                            ),
                        )
                      ) : (
                        <Grid container alignItems="center" item xs={6} lg={2}>
                          <Link to={{pathname: ROUTE.LAYOUT + screen.path}} className="link_card">
                            <Card className="home_card_size">
                              <CardActionArea>
                                <CardContent>
                                  <div className="font_size_small_regular font_bold">{t(`${screen.function_name_code}`)}</div>
                                  <Typography variant="body2" color="textSecondary" component="p">
                                    {t(`${screen.function_name_code}`)}
                                  </Typography>
                                </CardContent>
                              </CardActionArea>
                            </Card>
                          </Link>
                        </Grid>
                      )}
                    </Grid>
                    <br></br>
                  </Grid>
                ))}
              </Container>
              <br />
            </Card>
          </Container>
          <br></br>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getScreenList: () => dispatch(getScreenList()),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
