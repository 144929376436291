import {api} from '../services/api';

const getListBusinessUrl = `admin/suppliers/search`;
const crudBusinessUrl = `admin/suppliers`;

const getListImageUrl = `admin/image-masters/search`;
const crudImageMasterUrl = `admin/image-masters`;

const listDriverVehicleUrl = 'admin/driver-vehicle-config/search';
const getVehicleShiftUrl = 'admin/driver-vehicle-config/shift';
const updateVehicleShiftUrl = 'admin/driver-vehicle-config/shift';
const getVehicleActivatedTimesUrl = 'admin/vehicles/config/';
const getDriverByFacilityUrl = 'admin/drivers/by-facility/';
const getVehicleByFacilityUrl = 'admin/vehicles/by-facility';
const configDriverShiftsUrl = 'admin/driver-vehicle-config/driver-shifts';
const applyTimeShiftsUrl = 'admin/driver-vehicle-config/apply-shifts';
const getVirtualVehiclesUrl = 'admin/vehicles/virtual';
const autoConfigVehicleDriverAssociateUrl = 'admin/driver-vehicle-config/auto-config';

const searchPlanUrl = 'admin/plans/search';
const searchPlanOrderUrl = 'admin/plans/order';
const getListPlanPartnersUrl = 'admin/plans/partners';
const updatePlansIndexUrl = 'admin/plans/order';
const crudPlanUrl = 'admin/plans';
const getCompaniesByCountryUrl = 'admin/companies/countryId/companies';
const addTicketUrl = 'admin/ticket/schedule-csv-import';
const checkMemberCodeUrl = 'admin/ticket/csv-import/check';

const searchListSubscriptionUrl = 'admin/subscriptions/search';
const subscriptionRegisterUrl = 'admin/subscriptions/create-subscription';
const getSubscriptionUrl = 'admin/subscriptions/';
const subscriptionAddFamilyUrl = 'admin/subscriptions';
const subscriptionEnableUrl = 'admin/subscriptions/{subscriptionId}/enable';
const subscriptionDisableUrl = 'admin/subscriptions/{subscriptionId}/disable';
const subscriptionEnableUserUrl = 'admin/subscriptions/enableSubscriptionUser/{subscriptionUserId}';
const subscriptionDisableUserUrl = 'admin/subscriptions/disableSubscriptionUser/{subscriptionUserId}';
const subscriptionRenewableUrl = 'admin/subscriptions/renewable';
const resendMailUrl = 'subscription/users/resend-active';
const checkOwnerCardUrl = '/admin/card/list';
const trialAgainUrl = '/admin/subscriptions/trial-again/';
const addSlotUrl = 'admin/subscriptions/add-slot';
const getNumberSubByUserUrl = 'admin/subscriptions/number-sub-by-user';
const getSendMailListUrl = 'admin/subscriptions/sent-mails/';
const getRideAdditionalHistoryUrl = 'admin/subscriptions/rides-additional-history/';
const addRidesUrl = 'admin/subscriptions/add-rides';

const searchWaypointUrl = 'admin/waypoint/search';
const crudWaypointUrl = 'admin/waypoint';
const checkAffectedReservationsUrl = 'admin/partner/affected-bookings';

const deleteShiftUrl = 'admin/driver-vehicle-config';

const additionNumberRideUrl = 'subscription/tickets/addition-number-ride';

const getListPlanCodeUrl = 'admin/referrer-setting/plans/details';
const createBusinessPartnerUrl = 'admin/referrer-setting';
const updateBusinessPartnerUrl = 'admin/referrer-setting';
const getDetailBusinessPartnerUrl = 'admin/referrer-setting';
const checkReferrerCodeUrl = 'auth/referral-code/check';
const searchReferrersUrl = 'admin/referrer-setting';
const getMembersUrl = 'admin/referrer-setting/logs/{referrerCode}';
const getTicketDistributionHistoryUrl = '/admin/ticket/schedule-csv';
const deleteTicketDistributionHistoryUrl = '/admin/ticket/schedule-csv';
const getDataMemberTicketUrl = 'users/user/search';
const getCsvExportSubscriptionUrl = 'admin/csv-export/subscription';

const updateMemoUrl = 'admin/memo';

// Business (Supplier) API
export const getListBusinessApi = (payload, queryParams) => {
  return api.search(getListBusinessUrl, queryParams, payload);
};

export const createBusinessApi = (payload) => {
  return api.post(crudBusinessUrl, payload);
};

export const getDetailBusinessApi = (id) => {
  try {
    return api.get(crudBusinessUrl + '/' + id);
  } catch {
    return [];
  }
};

export const updateBusinessApi = (payload) => {
  return api.put(crudBusinessUrl, payload);
};

export const deleteBusinessApi = (id) => {
  return api.delete(crudBusinessUrl + '/' + id);
};

// Image master API
export const getListImageApi = (queryParams) => {
  return api.get(getListImageUrl, queryParams);
};

export const getImageApi = (params) => {
  return api.get(crudImageMasterUrl + '/' + params);
};

export const updateImageApi = (payload) => {
  return api.put(crudImageMasterUrl + '/', payload);
};

export const createImageApi = (payload) => {
  return api.postMultiplePart(crudImageMasterUrl, payload);
};

export const deleteImageMasterApi = (params) => {
  return api.delete(crudImageMasterUrl + '/' + params);
};

// Driver & Vehicle config API
export const searchListDriverVehicleApi = (payload) => {
  return api.put(listDriverVehicleUrl, payload);
};

export const getShiftCSV = (month, year) => {
  return api.get(`/admin/jit/shift-csv?month=${month}&year=${year}`);
};

export const getDriversByFacilityApi = (facility_id) => {
  return api.get(getDriverByFacilityUrl + facility_id);
};

export const getVehiclesByFacilityApi = (vehicle_id, date) => {
  return api.get(`${getVehicleByFacilityUrl}/${vehicle_id}?date=${date}`);
};

export const configDriverShiftsApi = (payload) => {
  return api.put(configDriverShiftsUrl, payload);
};

export const getVehicleShiftApi = (date, vehicle_id) => {
  return api.get(getVehicleShiftUrl + `?date=${date}&vehicle_id=${vehicle_id}`);
};

export const updateVehicleShiftApi = (payload) => {
  return api.put(updateVehicleShiftUrl, payload);
};

export const applyTimeShiftsApi = (payload) => {
  return api.post(applyTimeShiftsUrl, payload);
};

export const getVirtualVehiclesApi = (payload) => {
  return api.get(getVirtualVehiclesUrl, payload);
};

export const getVehicleActivatedTimesApi = (vehicleId) => {
  return api.get(getVehicleActivatedTimesUrl + vehicleId);
};

export const getAutoConfigVehicleDriverAssociateApi = (vehicle_id, driver_id) => {
  return api.get(autoConfigVehicleDriverAssociateUrl, {vehicle_id, driver_id});
};

export const updateAutoConfigVehicleDriverAssociateApi = (payload) => {
  return api.put(autoConfigVehicleDriverAssociateUrl, payload);
};

// Plans API
export const searchListPlanApi = (payload, queryParams) => {
  return api.search(searchPlanUrl, queryParams, payload);
};

export const searchPlanOrderApi = (geofenceId) => {
  return api.get(searchPlanOrderUrl, {geofence_id: geofenceId});
};

export const updatePlanOrderApi = (payload) => {
  return api.post(searchPlanOrderUrl, payload);
};

export const getListPlanPartnersApi = () => {
  return api.get(getListPlanPartnersUrl);
};

export const createPlanApi = (payload) => {
  return api.post(crudPlanUrl, payload);
};

export const updatePlanApi = (payload) => {
  return api.post(crudPlanUrl, payload);
};

export const getDetailPlanApi = (params) => {
  return api.get(crudPlanUrl + '/' + params);
};

export const copyPlanApi = (payload) => {
  return api.post(crudPlanUrl, payload);
};

export const updatePlansIndexApi = (payload) => {
  return api.post(updatePlansIndexUrl, payload);
};

export const getCompaniesByCountryApi = (countryId) => {
  return api.get(getCompaniesByCountryUrl.replace('countryId', countryId));
};

// Subscription API
export const searchListSubscriptionApi = (payload, queryParams) => {
  return api.search(searchListSubscriptionUrl, queryParams, payload);
};

export const getSubscriptionApi = (params) => {
  return api.get(getSubscriptionUrl + params);
};

export const registerSubscriptionApi = (payload) => {
  return api.post(subscriptionRegisterUrl, payload);
};

export const addFamilySubscriptionApi = (payload) => {
  return api.post(subscriptionAddFamilyUrl, payload);
};

export const updateSubscriptionTransferredApi = (payload) => {
  return api.put(`admin/subscriptions/update-transfer-status`, payload);
};

export const historyPaymentApi = (id) => {
  return api.get(`admin/subscriptions/{id}/payment-history`.replace('{id}', id));
};

export const subscriptionEnableApi = (subscriptionId) => {
  return api.put(subscriptionEnableUrl.replace('{subscriptionId}', subscriptionId), {});
};

export const subscriptionDisableApi = (subscriptionId) => {
  return api.put(subscriptionDisableUrl.replace('{subscriptionId}', subscriptionId), {});
};

export const subscriptionEnableUserApi = (subscriptionUserId) => {
  return api.put(subscriptionEnableUserUrl.replace('{subscriptionUserId}', subscriptionUserId), {});
};

export const subscriptionDisableUserApi = (subscriptionUserId) => {
  return api.put(subscriptionDisableUserUrl.replace('{subscriptionUserId}', subscriptionUserId), {});
};

export const subscriptionRenewableApi = (payload) => {
  return api.post(subscriptionRenewableUrl, payload);
};

export const resendMailApi = (payload) => {
  return api.post(resendMailUrl, payload);
};

export const checkOwnerCardApi = (userId) => {
  return api.get(checkOwnerCardUrl, {userId: userId});
};

export const trialAgainApi = (userId) => {
  return api.post(trialAgainUrl + userId, {});
};

export const getSendMailListApi = (id) => {
  return api.get(getSendMailListUrl + id);
};

export const getRideAdditionalHistoryApi = (id) => {
  return api.get(getRideAdditionalHistoryUrl + id);
};

// Waypoint API
export const searchWaypointApi = (country_code, geofence_id, waypoint_name, partner_name, page, size, waypoint_restrict) => {
  return api.get(searchWaypointUrl, {
    country_code: country_code,
    geofence_id: geofence_id,
    waypoint_name: waypoint_name,
    partner_name: partner_name,
    page: page,
    size: size,
    waypoint_restrict: waypoint_restrict,
  });
};

export const createWaypointApi = (payload) => {
  return api.post(crudWaypointUrl + '/create-waypoint', payload);
};

export const updateWaypointApi = (payload) => {
  return api.put(crudWaypointUrl, payload);
};

export const deleteWaypointApi = (id) => {
  return api.delete(crudWaypointUrl + '/' + id);
};

export const getWaypointDetailApi = (id) => {
  return api.get(crudWaypointUrl + '/' + id);
};

export const checkAffectedReservationsApi = (payload) => {
  return api.put(checkAffectedReservationsUrl, payload);
};

export const addSlotApi = (payload) => {
  return api.post(addSlotUrl, payload);
};

export const getNumberSubByUserApi = (payload) => {
  return api.get(getNumberSubByUserUrl, payload);
};

export const deleteShiftApi = (payload) => {
  return api.delete(deleteShiftUrl, payload);
};

export const deleteCreditCardApi = (cardId) => {
  return {};
};

export const addTicketApi = (payload) => {
  return api.post(addTicketUrl, payload);
};

export const checkMemberCodeApi = (payload) => {
  return api.post(checkMemberCodeUrl, payload);
};

export const addRidesApi = (payload) => {
  return api.put(addRidesUrl, payload);
};

export const additionNumberRideApi = (payload) => {
  return api.get(additionNumberRideUrl, payload);
};

export const getListPlanCodeApi = (business_referral_id, geofence_ids) => {
  return api.get(getListPlanCodeUrl, {
    business_referral_id,
    geofence_ids,
  });
};

export const createBusinessPartnerApi = (payload) => {
  return api.post(createBusinessPartnerUrl, payload);
};

export const updateBusinessPartnerApi = (payload) => {
  return api.post(updateBusinessPartnerUrl, payload);
};

export const getDetailBusinessPartnerApi = (id) => {
  return api.get(getDetailBusinessPartnerUrl + '/' + id);
};

export const deleteBusinessPartnerApi = (id) => {
  return api.delete(getDetailBusinessPartnerUrl + '/' + id);
};

export const checkReferrerCodeApi = (id) => {
  return api.get(checkReferrerCodeUrl + '/' + id);
};

export const searchReferrersApi = (params) => {
  return api.get(searchReferrersUrl, params);
};

export const getMembersApi = (referrerCode, params) => {
  return api.get(getMembersUrl.replace('{referrerCode}', referrerCode), params);
};
export const getTicketDistributionHistoryApi = (payload) => {
  return api.get(getTicketDistributionHistoryUrl, payload);
};

export const deleteTicketDistributionHistoryApi = (payload) => {
  return api.delete(deleteTicketDistributionHistoryUrl, payload);
};

export const getDataMemberTicketApi = (payload) => {
  return api.post(getDataMemberTicketUrl, payload);
};

export const getCsvExportSubscriptionApi = (payload) => {
  return api.search(getCsvExportSubscriptionUrl, {}, payload);
};

export const updateMemoApi = (payload) => {
  return api.post(updateMemoUrl, payload);
};
