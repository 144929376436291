import React, {Component} from 'react';

import {
  Card,
  Container,
  Grid,
  Button,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  TableFooter,
  TextField,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Paper,
  FormGroup,
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {ALL_DAY_WEEK, MONTH_NAMES, MONTH} from '../../../common/constant';
import {setMessageModal} from '../../../stores/modal/actions';
import {listPriceCalendar, createPriceCalendar} from '../../../stores/price_calendar/action';
import {modalObj} from '../../../utils/modal.js';
import './style.css';

const StyledTableRow = {
  padding: '0px!important',
  border: '1px solid rgb(121 104 104)',
  lineHeight: 1,
};
const styleTable = {
  backgroundColor: 'white',
  boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
};

/**
 * Index Product Calendar
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      productId: 0,
      product_name: '',
      price_days: [],
      firstDay: 0,
      charges_batch: '',
      charges_tax: '',
      time_from_batch: '',
      time_to_batch: '',
      all_day: [0, 1, 2, 3, 4, 5, 6],
      cmonth: new Date().getMonth(),
      cyear: new Date().getFullYear(),
      checked_all_day: [true, true, true, true, true, true, true],

      checkAll: false,
      flgModal: false,
      message: '',
      checkCreate: true,
      isSubmitRegister: false,
      isSubmitUpdate: false,
    };
    this.validator = new SimpleReactValidator();
    this.handleButtonOk = this.handleButtonOk.bind(this.props);
    this.handleButtonCancel = this.handleButtonCancel.bind(this.props);
  }

  /**
   *   handleNextMonth() {
   */
  handleNextMonth() {
    let cmonth = this.state.cmonth;
    let cyear = this.state.cyear;
    if (cmonth === 12) {
      cmonth = 1;
      cyear = cyear + 1;
    } else {
      cmonth = cmonth + 1;
    }
    const payload = {
      cMonth: cmonth,
      cYear: cyear,
      productId: this.state.productId,
    };
    this.props.listPriceCalendar(payload, this.props).then((result) => {
      let price_days = [];
      if (result.price_days) {
        price_days = result.price_days;
      }
      this.handleInitPriceDays(price_days, cmonth, cyear);
      this.setState({
        charges_batch: '',
        charges_tax: '',
        time_from_batch: '',
        time_to_batch: '',
        cmonth: cmonth,
        cyear: cyear,
        isSubmitUpdate: false,
        isSubmitRegister: false,
        all_day: [0, 1, 2, 3, 4, 5, 6],
        checked_all_day: [true, true, true, true, true, true, true],
      });
    });
  }

  /**
   * changeMonth
   * @param {Object} event
   */
  onChangeBatchField = (event) => {
    let charges_batch = event.target.value;
    if (event.target.value === '0') {
      charges_batch = '';
    }
    this.setState({
      charges_batch: charges_batch,
    });
  };

  /**
   * @param {Object} event
   */
  onChangeBatchFieldTax = (event) => {
    let charges_tax = event.target.value;
    if (event.target.value === '0') {
      charges_tax = '';
    }
    this.setState({
      charges_tax: charges_tax,
    });
  };

  /**
   * changeMonth
   * @param {Object} value
   */
  changeMonth = (value) => {
    this.setState({
      cmonth: value,
    });
  };

  /**
   * handlePreviousMonth
   */
  handlePreviousMonth() {
    let cmonth = this.state.cmonth;
    let cyear = this.state.cyear;
    if (cmonth === 1) {
      cmonth = 12;
      cyear = cyear - 1;
    } else {
      cmonth = cmonth - 1;
    }
    const payload = {
      cMonth: cmonth,
      cYear: cyear,
      productId: this.state.productId,
    };
    this.props.listPriceCalendar(payload, this.props).then((result) => {
      let price_days = [];
      if (result.price_days) {
        price_days = result.price_days;
      }
      this.handleInitPriceDays(price_days, cmonth, cyear);
      this.setState({
        charges_batch: '',
        charges_tax: '',
        time_from_batch: '',
        time_to_batch: '',
        cmonth: cmonth,
        cyear: cyear,
        isSubmitUpdate: false,
        isSubmitRegister: false,
        all_day: [0, 1, 2, 3, 4, 5, 6],
        checked_all_day: [true, true, true, true, true, true, true],
      });
    });
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    const cmonth = new Date().getMonth() + 1;
    const cyear = new Date().getFullYear();
    const productId = this.props.match.params.id;
    const payload = {
      cMonth: cmonth,
      cYear: cyear,
      productId: productId,
    };
    this.props.listPriceCalendar(payload, this.props).then((result) => {
      let price_days = [];
      if (result) {
        price_days = result.price_days;
      }
      this.handleInitPriceDays(price_days, cmonth, cyear);
      this.setState({
        cmonth: cmonth,
        cyear: cyear,
        productId: productId,
        product_name: result.product_name,
      });
    });
  }

  /**
   * handleSearch
   */
  handleSearch() {
    const cmonth = this.state.cmonth;
    const cyear = this.state.cyear;
    const payload = {
      cMonth: cmonth,
      cYear: cyear,
    };
    this.props.listPriceCalendar(payload, this.props).then((result) => {
      let price_days = [];
      if (result) {
        price_days = result.price_days;
      }
      this.handleInitPriceDays(price_days, cmonth, cyear);
      this.setState({
        cmonth: cmonth,
        cyear: cyear,
        all_day: [0, 1, 2, 3, 4, 5, 6],
        checked_all_day: [true, true, true, true, true, true, true],
        isSubmitUpdate: false,
        isSubmitRegister: false,
      });
    });
  }

  /**
   * handleInitPriceDays
   * @param {object} price_days
   *  @param {object} cmonth
   *  @param {object} cyear
   */
  handleInitPriceDays(price_days, cmonth, cyear) {
    const lengthPriceDays = price_days.length;
    const totalDateMonth = new Date(cyear, cmonth, 0).getDate();
    let price_days_new = [];
    let check_create = true;
    if (lengthPriceDays > 0) {
      price_days_new = price_days;
      check_create = false;
    } else {
      for (let i = 0; i < totalDateMonth; i++) {
        price_days_new.push({
          cday: i + 1,
          cprice: '',
          ctax: '',
          time_from: '',
          time_to: '',
          is_active: true,
        });
      }
    }

    const firstDay = new Date(cmonth + '-1-' + cyear).getDay() ? new Date(cmonth + '-1-' + cyear).getDay() - 1 : 6;
    this.setState({
      price_days: price_days_new,
      firstDay: firstDay,
      check_create: check_create,
    });
  }

  /**
   * onChangePrice
   * @param {object} index
   * @param {object} value
   */
  onChangePrice(index, value) {
    const price_days = this.state.price_days;
    price_days[index].cprice = value;
    this.setState({
      price_days: price_days,
    });
  }

  /**
   * onChangeTax
   * @param {object} index
   * @param {object} value
   */
  onChangeTax(index, value) {
    const price_days = this.state.price_days;
    price_days[index].ctax = value;
    this.setState({
      price_days: price_days,
    });
  }

  /**
   * onChangeTimeFrom
   * @param {object} index
   * @param {object} value
   */
  onChangeTimeFrom(index, value) {
    const price = this.state.price_days;
    price[index].time_from = value;
    this.setState({
      price_days: price,
    });
  }

  /**
   * onChangeTimeTo
   * @param {object} index
   * @param {object} value
   */
  onChangeTimeTo = (index, value) => {
    const price = this.state.price_days;
    price[index].time_to = value;
    this.setState({
      price_days: price,
    });
  };

  /**
   * handleBatchRegister
   */
  handleBatchRegister() {
    this.setState({
      isSubmitRegister: true,
    });
    if (this.validator.allValid()) {
      const price = this.state.price_days;
      const chargesBatch = this.state.charges_batch;
      const chargesTax = this.state.charges_tax;
      if (chargesBatch > 0) {
        const timeFromBatch = this.state.time_from_batch ? this.state.time_from_batch : '';
        const timeToBatch = this.state.time_to_batch ? this.state.time_to_batch : '';
        const allDay = this.state.all_day;
        const cmonth = this.state.cmonth;
        for (let i = 0; i < price.length; i++) {
          const day = new Date(cmonth + '-' + price[i].cday + '-' + this.state.cyear).getDay();
          if (allDay.indexOf(day ? day - 1 : 6) >= 0) {
            price[i].time_from = timeFromBatch;
            price[i].time_to = timeToBatch;
            price[i].cprice = chargesBatch;
            price[i].ctax = chargesTax;
          }
        }
        this.setState({
          price_days: price,
          isSubmitRegister: false,
        });
      }
    }
  }

  /**
   * onChangeTimeFromBatch
   * @param {object} value
   */
  onChangeTimeFromBatch = (value) => {
    const time_to_batch = this.state.time_to_batch;
    if (time_to_batch < value) {
      this.setState({
        time_from_batch: value,
      });
    } else {
      this.setState({
        time_from_batch: value,
      });
    }
  };

  /**
   * onChangeTimeFromToBatch
   * @param {object} value
   */
  onChangeTimeFromToBatch = (value) => {
    this.setState({
      time_to_batch: value,
    });
  };

  /**
   * onChange Checkbox Date
   * @param {object} index
   */
  onChangeCheckbox = (index) => {
    const price = this.state.price_days;
    price[index].is_active = !this.state.price_days[index].is_active;
    this.setState({
      price_days: price,
    });
  };

  /**
   * handleCheckDay
   * @param {object} event
   * @param {object} day
   */
  handleCheckDay = (event, day) => {
    const allDay = this.state.all_day;
    const checked_all_day = this.state.checked_all_day;
    checked_all_day[day] = !checked_all_day[day];
    if (event.target.checked) {
      allDay.push(day);
    } else {
      allDay.splice(day);
    }
    this.setState({
      all_day: allDay,
      checked_all_day: checked_all_day,
    });
  };

  /**
   * handleButtonOk
   */
  handleButtonOk() {
    this.setState({
      flgModal: false,
    });
  }

  /**
   * handleButtonCancel
   */
  handleButtonCancel = () => {
    this.setState({
      flgModal: false,
    });
  };

  /**
   * handleCsvInput
   * @param {object} index
   */
  handleCsvInput() {
    this.setState({flgModal: true});
  }

  /**
   * onChangeCheckAll
   */
  onChangeCheckAll() {
    const price = this.state.price_days;
    for (let i = 0; i < price.length; i++) {
      price[i].is_active = !this.state.checkAll;
    }
    this.setState({
      checkAll: !this.state.checkAll,
      price_days: price,
    });
  }

  /**
   * onChangePriceAll
   * @param {object} event
   */
  onChangePriceAll = (event) => {
    const price = this.state.price_days;
    for (let i = 0; i < price.length; i++) {
      price[i].price = event.target.value;
    }
    this.setState({
      price_days: price,
    });
  };

  /**
   * handleCreateCalendar
   */
  handleCreateCalendar = () => {
    this.setState({
      isSubmitUpdate: true,
    });
    if (this.checkValidate()) {
      const payload = {
        cmonth: this.state.cmonth,
        cyear: this.state.cyear,
        price_day_requests: this.state.price_days,
        product_id: this.state.productId,
      };
      this.props.createPriceCalendar(payload, this.props).then((result) => {
        this.setState({
          check_create: false,
        });
      });
    } else {
      this.props.setMessageModal(modalObj(true, 'error.check.value.empty'));
    }
  };

  /**
   * checkValidate
   * @return {boolean} true/false
   */
  checkValidate() {
    const price_days = this.state.price_days;
    for (let i = 0; i < price_days.length; i++) {
      if (price_days[i].is_active === true) {
        if (
          price_days[i].cprice === '' ||
          price_days[i].cprice === '0' ||
          price_days[i].ctax === '' ||
          price_days[i].ctax === '0' ||
          price_days[i].time_from === '' ||
          price_days[i].time_to === '' ||
          price_days[i].time_to <= price_days[i].time_from
        ) {
          return false;
        }
      }
    }
    return true;
  }

  /**
   * Render component
   * @return {object}
   */
  render() {
    const {priceCalendar, t} = this.props;
    const {firstDay, price_days} = this.state;
    const row1 = [];
    const row = [];
    if (price_days) {
      // the total number of days in the month
      const totalDay = new Date(this.state.cyear, this.state.cmonth, 0).getDate();
      // total number of days a week 1
      const totalRow1 = 7 - firstDay;
      // total week
      const totalRow = (totalDay - totalRow1) / 7;
      for (let i = 0; i < this.state.firstDay; i++) {
        row1.push(<TableCell align="center" style={StyledTableRow} key={i}></TableCell>);
      }
      let i = 0;
      for (let j = firstDay; j < 7; j++) {
        row1.push(
            <TableCell align="center" style={StyledTableRow} key={j}>
              <strong>{i + 1}</strong>
              <br></br>
              <TextField
                className="field_size_10 field_min_size_150"
                margin="dense"
                variant="outlined"
                value={price_days[j - firstDay] ? price_days[j - firstDay].cprice : 0}
                onChange={(event) => {
                  this.onChangePrice(j - firstDay, event.target.value);
                }}
                defaultValue="0"
                type="number"
                placeholder="Price"
                InputProps={{
                  endAdornment: <InputAdornment position="end">{t('calendar.en')}</InputAdornment>,
                  inputProps: {
                    min: 0,
                  },
                }}
              />
              {/* isSubmitUpdate */}
              {price_days[j - firstDay] ? (
              <Grid container spacing={1}>
                {/* check isValid to show messages */}
                <Grid container alignItems="center" item xs={2} lg={2}></Grid>
                <Grid container alignItems="center" item xs={10} lg={10}>
                  {this.state.isSubmitUpdate && price_days[j - firstDay].is_active && !this.validator.check(price_days[j - firstDay].cprice, 'required') && (
                    <p className="error font_size_small">{t('validation.required', {field: t('calendar.price')})}</p>
                  )}
                  {this.state.isSubmitUpdate && price_days[j - firstDay].cprice === '0' && price_days[j - firstDay].is_active ? (
                    <p className="error font_size_small">{t('validation.required', {field: t('calendar.price')})}</p>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
              <br></br>
              <TextField
                className="field_size_10 field_min_size_150"
                margin="dense"
                variant="outlined"
                value={price_days[j - firstDay] ? price_days[j - firstDay].ctax : 0}
                onChange={(event) => {
                  this.onChangeTax(j - firstDay, event.target.value);
                }}
                defaultValue="0"
                type="number"
                placeholder="Tax"
                InputProps={{
                  endAdornment: <InputAdornment position="end">{t('calendar.en')}</InputAdornment>,
                  inputProps: {
                    min: 0,
                  },
                }}
              />
              {/* isSubmitUpdate */}
              {price_days[j - firstDay] ? (
              <Grid container spacing={1}>
                {/* check isValid to show messages */}
                <Grid container alignItems="center" item xs={2} lg={2}></Grid>
                <Grid container alignItems="center" item xs={10} lg={10}>
                  {this.state.isSubmitUpdate && price_days[j - firstDay].is_active && !this.validator.check(price_days[j - firstDay].ctax, 'required') && (
                    <p className="error font_size_small">{t('validation.required', {field: t('calendar.tax')})}</p>
                  )}
                  {this.state.isSubmitUpdate && price_days[j - firstDay].ctax === '0' && price_days[j - firstDay].is_active ? (
                    <p className="error font_size_small">{t('validation.required', {field: t('calendar.tax')})}</p>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
              <br></br>
              <TextField
                id="time"
                label={t('vehicles.from')}
                type="time"
                defaultValue="07:30"
                className="field_size_20 field_min_size_150"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300,
                }}
                value={price_days[j - firstDay] ? price_days[j - firstDay].time_from : '00:00'}
                onChange={(event) => this.onChangeTimeFrom(j - firstDay, event.target.value)}
              />
              {/* isSubmitUpdate */}
              {price_days[j - firstDay] ? (
              <Grid container spacing={1}>
                <Grid container alignItems="center" item xs={2} lg={1}></Grid>
                <Grid container alignItems="center" item xs={10} lg={11}>
                  {/* check isValid to show messages */}
                  {this.state.isSubmitUpdate && price_days[j - firstDay].is_active && !this.validator.check(price_days[j - firstDay].time_from, 'required') && (
                    <p className="error font_size_small">{t('validation.required', {field: t('vehicles.from')})}</p>
                  )}
                  {this.state.isSubmitUpdate && price_days[j - firstDay].time_to !== '' && price_days[j - firstDay].time_to <= price_days[j - firstDay].time_from ? (
                    <p className="error font_size_small">{t('error.time.from.not.after.time.to')}</p>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
              <br></br>
              <TextField
                id="time"
                label={t('vehicles.to')}
                type="time"
                defaultValue="07:30"
                className="field_size_20 field_min_size_150"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300,
                }}
                value={price_days[j - firstDay] ? price_days[j - firstDay].time_to : '00:00'}
                onChange={(event) => this.onChangeTimeTo(j - firstDay, event.target.value)}
              />
              <br></br>
              {/* isSubmitUpdate */}
              {price_days[j - firstDay] ? (
              <Grid container spacing={1}>
                <Grid container alignItems="center" item xs={2} lg={1}></Grid>
                <Grid container alignItems="center" item xs={10} lg={11}>
                  {/* check isValid to show messages */}
                  {this.state.isSubmitUpdate &&
                    price_days[j - firstDay].is_active &&
                    !this.validator.check(price_days[j - firstDay].time_to, 'required') && (
                    <p className="error font_size_small">{t('validation.required', {field: t('vehicles.to')})}</p>
                  ) && <p className="error font_size_small">{t('error.time.from.not.after.time.to')}</p>}
                  {this.state.isSubmitUpdate && price_days[j - firstDay].time_from !== '' && price_days[j - firstDay].time_to <= price_days[j - firstDay].time_from ? (
                    <p className="error font_size_small">{t('error.time.from.not.after.time.to')}</p>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
              <FormControlLabel
                labelPlacement="end"
                control={<Checkbox checked={price_days[j - firstDay] ? price_days[j - firstDay].is_active : false} color="primary" />}
                onChange={() => {
                  this.onChangeCheckbox(j - firstDay);
                }}
                label={price_days[j - firstDay] ? price_days[j - firstDay].is_active + '' : false}
              />
            </TableCell>,
        );
        i++;
      }
      for (let j = 0; j < totalRow; j++) {
        row[j] = [];
        for (let d = 0; d < 7; d++) {
          if (i >= totalDay) {
            row[j].push(<TableCell align="center" style={StyledTableRow} key={i}></TableCell>);
          } else {
            const dayStartRow = totalRow1 + 7 * j + d;
            row[j].push(
                <TableCell align="center" style={StyledTableRow} key={i}>
                  <strong>{i + 1}</strong>
                  <br></br>
                  <TextField
                    type="number"
                    className="field_size_10 field_min_size_150"
                    margin="dense"
                    variant="outlined"
                    value={price_days[dayStartRow] ? price_days[dayStartRow].cprice : 0}
                    onChange={(event) => this.onChangePrice(dayStartRow, event.target.value)}
                    placeholder="Price"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{t('calendar.en')}</InputAdornment>,
                      inputProps: {
                        min: 0,
                      },
                    }}
                  />
                  {/* isSubmitUpdate */}
                  {price_days[dayStartRow] ? (
                  <Grid container spacing={1}>
                    {/* check isValid to show messages */}
                    <Grid container alignItems="center" item xs={2} lg={2}></Grid>
                    <Grid container alignItems="center" item xs={10} lg={10}>
                      {this.state.isSubmitUpdate && price_days[dayStartRow].is_active && !this.validator.check(price_days[dayStartRow].cprice, 'required') && (
                        <p className="error font_size_small">{t('validation.required', {field: t('calendar.price')})}</p>
                      )}
                      {this.state.isSubmitUpdate && price_days[dayStartRow].cprice === '0' && price_days[dayStartRow].is_active ? (
                        <p className="error font_size_small">{t('validation.required', {field: t('calendar.price')})}</p>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <></>
                )}
                  <br></br>
                  <TextField
                    type="number"
                    className="field_size_10 field_min_size_150"
                    margin="dense"
                    variant="outlined"
                    value={price_days[dayStartRow] ? price_days[dayStartRow].ctax : 0}
                    onChange={(event) => this.onChangeTax(dayStartRow, event.target.value)}
                    placeholder="Tax"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">{t('calendar.en')}</InputAdornment>,
                      inputProps: {
                        min: 0,
                      },
                    }}
                  />
                  {/* isSubmitUpdate */}
                  {price_days[dayStartRow] ? (
                  <Grid container spacing={1}>
                    {/* check isValid to show messages */}
                    <Grid container alignItems="center" item xs={2} lg={2}></Grid>
                    <Grid container alignItems="center" item xs={10} lg={10}>
                      {this.state.isSubmitUpdate && price_days[dayStartRow].is_active && !this.validator.check(price_days[dayStartRow].ctax, 'required') && (
                        <p className="error font_size_small">{t('validation.required', {field: t('calendar.tax')})}</p>
                      )}
                      {this.state.isSubmitUpdate && price_days[dayStartRow].ctax === '0' && price_days[dayStartRow].is_active ? (
                        <p className="error font_size_small">{t('validation.required', {field: t('calendar.tax')})}</p>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <></>
                )}
                  <br></br>
                  <TextField
                    id="time"
                    label={t('vehicles.from')}
                    type="time"
                    defaultValue="07:30"
                    className="field_size_20 field_min_size_150"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300,
                    }}
                    value={price_days[dayStartRow] ? price_days[dayStartRow].time_from : '00:00'}
                    onChange={(event) => this.onChangeTimeFrom(dayStartRow, event.target.value)}
                  />
                  {/* isSubmitUpdate */}
                  {price_days[dayStartRow] ? (
                  <Grid container spacing={1}>
                    <Grid container alignItems="center" item xs={2} lg={2}></Grid>
                    <Grid container alignItems="center" item xs={10} lg={10}>
                      {/* check isValid to show messages */}
                      {this.state.isSubmitUpdate &&
                        price_days[dayStartRow].is_active &&
                        !this.validator.check(price_days[dayStartRow].time_from, 'required') && (
                        <p className="error font_size_small">{t('validation.required', {field: t('vehicles.from')})}</p>
                      ) && <p className="error font_size_small">{t('error.time.from.not.after.time.to')}</p>}
                      {this.state.isSubmitUpdate && price_days[dayStartRow].time_to !== '' && price_days[dayStartRow].time_to <= price_days[dayStartRow].time_from ? (
                        <p className="error font_size_small">{t('error.time.from.not.after.time.to')}</p>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <></>
                )}
                  <br></br>
                  <TextField
                    id="time"
                    label={t('vehicles.to')}
                    type="time"
                    defaultValue="07:30"
                    className="field_size_20 field_min_size_150"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300,
                    }}
                    value={price_days[dayStartRow] ? price_days[dayStartRow].time_to : '00:00'}
                    onChange={(event) => this.onChangeTimeTo(dayStartRow, event.target.value)}
                  />
                  {/* isSubmitUpdate */}
                  {price_days[dayStartRow] ? (
                  <Grid container spacing={1}>
                    <Grid container alignItems="center" item xs={2} lg={2}></Grid>
                    <Grid container alignItems="center" item xs={10} lg={10}>
                      {/* check isValid to show messages */}
                      {this.state.isSubmitUpdate &&
                        price_days[dayStartRow].is_active &&
                        !this.validator.check(price_days[dayStartRow].time_to, 'required') && (
                        <p className="error font_size_small">{t('validation.required', {field: t('vehicles.from')})}</p>
                      ) && <p className="error font_size_small">{t('error.time.from.not.after.time.to')}</p>}
                      {this.state.isSubmitUpdate && price_days[dayStartRow].time_from !== '' && price_days[dayStartRow].time_to <= price_days[dayStartRow].time_from ? (
                        <p className="error font_size_small">{t('error.time.from.not.after.time.to')}</p>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <></>
                )}
                  <br></br>
                  <FormControlLabel
                    labelPlacement="end"
                    control={<Checkbox checked={price_days[dayStartRow] ? price_days[dayStartRow].is_active : false} color="primary" />}
                    onChange={() => this.onChangeCheckbox(dayStartRow)}
                    label={price_days[dayStartRow] ? price_days[dayStartRow].is_active + '' : false}
                  />
                </TableCell>,
            );
          }
          i++;
        }
      }
    }

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.price_sales_calendar')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Button
                color="primary"
                variant="contained"
                className="button_margin button_color"
                endIcon={<ArrowBackIcon />}
                onClick={this.props.history.goBack}
              >
                {t('common.btnReturn')}
              </Button>
            </Grid>
          </Grid>
          <br></br>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper>
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('route.delivery_detail')}
                    </Grid>
                  </Grid>
                  {/* productId */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('delivery.product_id')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <TextField
                        className="field_size_10 field_min_size_300"
                        margin="dense"
                        label={<span>{t('delivery.productId')}</span>}
                        variant="outlined"
                        name="productId"
                        value={this.state.productId}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                  {/* product_name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('delivery.product_name')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <TextField
                        className="field_size_10 field_min_size_300"
                        margin="dense"
                        label={<span>{t('delivery.product_name')}</span>}
                        variant="outlined"
                        name="product_name"
                        value={this.state.product_name}
                        inputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
                <br></br>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <LoadingOverlay active={priceCalendar.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                  <Grid container spacing={3} className="product_entry_table_header_color font_color_white">
                    <Grid item xs={4} align="right">
                      <br></br>
                      <ArrowBackIosIcon className="cursor_pointer" onClick={() => this.handlePreviousMonth()} />
                    </Grid>
                    <Grid item xs={4} align="center">
                      <Grid container spacing={3} className="product_entry_table_header_color font_color_white">
                        <Grid item xs={6} align="right">
                          <Autocomplete
                            margin="dense"
                            className="field_size_10 field_min_size_150"
                            options={MONTH}
                            value={this.state.cmonth}
                            getOptionLabel={(option) => t(`${MONTH_NAMES[option - 1]}`)}
                            onChange={(event, month) => this.changeMonth(month)}
                            renderInput={(params) => <TextField {...params} name={'cmonth'} fullWidth margin="dense" label={t('calendar.month')} />}
                          />
                        </Grid>
                        <Grid item xs={6} align="left">
                          <TextField
                            className="field_size_10 field_min_size_150"
                            margin="dense"
                            value={this.state.cyear}
                            onChange={(event) => {
                              this.setState({cyear: event.target.value});
                            }}
                            label={t('calendar.years')}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4} align="left">
                      <br></br>
                      <ArrowForwardIosIcon className="cursor_pointer" onClick={() => this.handleNextMonth()} />
                    </Grid>
                  </Grid>
                  <br></br>
                  <Paper className="search_table">
                    <br></br>
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('calendar.batch_register_all')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={6}>
                        <TextField
                          name="charges_batch"
                          className="field_size_20 field_min_size_250"
                          margin="dense"
                          inputProps={{maxLength: 256}}
                          value={this.state.charges_batch}
                          onChange={(event) => this.onChangeBatchField(event)}
                          variant="outlined"
                          type="number"
                          placeholder="Price"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{t('calendar.en')}</InputAdornment>,
                            inputProps: {
                              min: 0,
                            },
                          }}
                        />
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid container alignItems="center" item xs={6} lg={2}></Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          {/* check isValid to show messages */}
                          {this.state.isSubmitRegister && !this.validator.check(this.state.charges_batch, 'required') && (
                            <p className="error">{t('validation.required', {field: t('calendar.batch_register_all')})}</p>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('calendar.batch_register_all_tax')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={6}>
                        <TextField
                          name="charges_tax"
                          className="field_size_20 field_min_size_250"
                          margin="dense"
                          inputProps={{maxLength: 256}}
                          value={this.state.charges_tax}
                          onChange={(event) => this.onChangeBatchFieldTax(event)}
                          variant="outlined"
                          type="number"
                          placeholder="Tax"
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{t('calendar.en')}</InputAdornment>,
                            inputProps: {
                              min: 0,
                            },
                          }}
                        />
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid container alignItems="center" item xs={6} lg={2}></Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          {/* check isValid to show messages */}
                          {this.state.isSubmitRegister && !this.validator.check(this.state.charges_tax, 'required') && (
                            <p className="error">{t('validation.required', {field: t('calendar.batch_register_all_tax')})}</p>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('calendar.time_from')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <TextField
                          id="time"
                          label={t('vehicles.from')}
                          type="time"
                          defaultValue="07:30"
                          className="field_size_20 field_min_size_250"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 300,
                          }}
                          value={this.state.time_from_batch}
                          onChange={(event) => this.onChangeTimeFromBatch(event.target.value)}
                        />
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid container alignItems="center" item xs={6} lg={2}></Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          {/* check isValid to show messages */}
                          {this.state.isSubmitRegister && !this.validator.check(this.state.time_from_batch, 'required') && (
                            <p className="error">{t('validation.required', {field: t('calendar.time_from')})}</p>
                          )}
                          {this.state.isSubmitRegister && this.state.time_to_batch !== '' && this.state.time_from_batch >= this.state.time_to_batch ? (
                            <p className="error font_size_small">{t('error.time.from.not.after.time.to')}</p>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('calendar.time_to')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <TextField
                          id="time"
                          label={t('vehicles.to')}
                          type="time"
                          defaultValue="07:30"
                          className="field_size_20 field_min_size_250"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 300,
                          }}
                          value={this.state.time_to_batch}
                          onChange={(event) => this.onChangeTimeFromToBatch(event.target.value)}
                        />
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid container alignItems="center" item xs={6} lg={2}></Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          {/* check isValid to show messages */}
                          {this.state.isSubmitRegister && !this.validator.check(this.state.time_to_batch, 'required') && (
                            <p className="error">{t('validation.required', {field: t('calendar.time_to')})}</p>
                          )}
                          {this.state.isSubmitRegister && this.state.time_from_batch !== '' && this.state.time_from_batch >= this.state.time_to_batch ? (
                            <p className="error font_size_small">{t('error.time.from.not.after.time.to')}</p>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('calendar.all_day')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={6}>
                        <FormGroup row>
                          {ALL_DAY_WEEK.map(({day, i18n}, index) => (
                            <FormControlLabel
                              key={index}
                              labelPlacement="end"
                              control={<Checkbox key={index} color="primary" checked={this.state.checked_all_day[day]} onChange={(event) => this.handleCheckDay(event, day)} />}
                              label={t(`${i18n}`)}
                            />
                          ))}
                        </FormGroup>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                      <Grid container alignItems="center" justify="flex-end" item xs={12}>
                        <FormControlLabel
                          style={{paddingLeft: '15px'}}
                          labelPlacement="end"
                          control={<Checkbox checked={this.state.checkAll} color="primary" />}
                          onChange={() => {
                            this.onChangeCheckAll();
                          }}
                          label={t('calendar.checkAll')}
                        />
                        <Button color="secondary" variant="contained" className="button_margin" endIcon={<AutorenewIcon />} onClick={() => this.handleBatchRegister()}>
                          {t('common.btnBulkRegistration')}
                        </Button>
                        <Button color="primary" variant="contained" className="button_margin" onClick={() => this.handleSearch()} endIcon={<SearchIcon />}>
                          {t('common.btnSearch')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                  <br></br>
                  <div align="center">
                    <Table size="small" aria-label="a dense table" style={styleTable} className="">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center" style={StyledTableRow}>
                            {t('calendar.Monday')}
                          </TableCell>
                          <TableCell align="center" style={StyledTableRow}>
                            {t('calendar.Tuesday')}
                          </TableCell>
                          <TableCell align="center" style={StyledTableRow}>
                            {t('calendar.Wednesday')}
                          </TableCell>
                          <TableCell align="center" style={StyledTableRow}>
                            {t('calendar.Thursday')}
                          </TableCell>
                          <TableCell align="center" style={StyledTableRow}>
                            {t('calendar.Friday')}
                          </TableCell>
                          <TableCell align="center" style={StyledTableRow}>
                            {t('calendar.Saturday')}
                          </TableCell>
                          <TableCell align="center" style={StyledTableRow}>
                            {t('calendar.Sunday')}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>{row1}</TableRow>
                        {row.map((rows, index) => (
                          <TableRow>
                            {row[index].map((r, id) => (
                              <>{row[index][id]}</>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter></TableFooter>
                    </Table>
                  </div>
                </LoadingOverlay>
                <br></br>
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {/* <Button
                      color="primary"
                      variant="contained"
                      className="button_margin"
                      endIcon={<UploadIcon style={{transform: 'rotate(180deg)'}} />}
                    >
                      {t('calendar.csv_output')}
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => this.handleCsvInput()}
                      endIcon={<UploadIcon/>}
                    >
                      {t('calendar.csv_input')}
                    </Button> */}
                    {this.state.check_create ? (
                      <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<CreateIcon />} onClick={() => this.handleCreateCalendar()}>
                        {t('common.btnRegister')}
                      </Button>
                    ) : (
                      <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={() => this.handleCreateCalendar()}>
                        {t('common.btnUpdate')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <br></br>
              </Container>
            </Card>
            <br></br>
          </Container>
        </Container>
        <br></br>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    priceCalendar: state.priceCalendar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    listPriceCalendar: (params, props) => dispatch(listPriceCalendar(params, props)),
    createPriceCalendar: (params, props) => dispatch(createPriceCalendar(params, props)),
    setMessageModal: (params) => dispatch(setMessageModal(params)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
