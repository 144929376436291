import {
  getDeliverySettingApi,
  updateDeliverySettingApi,
  getListAreaApi,
} from '../../services/deliverySettingService';
import {modalObj} from '../../utils/modal.js';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

/**
 * getDeliverySetting
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const getDeliverySetting = (params) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_DELIVERY_SETTING_START});
    return getDeliverySettingApi(params).then(
        (data) => {
          if (data && data.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_DELIVERY_SETTING_SUCCESS,
              deliverySetting: data.result,
            });
            return data.result;
          } else {
            dispatch(setMessageModal(modalObj(true, data.message)));
            dispatch({
              type: ACTION_TYPES.GET_DELIVERY_SETTING_FAIL,
              error: data.message,
            });
          }
        },
        () => dispatch({
          type: ACTION_TYPES.GET_DELIVERY_SETTING_FAIL,
          error: 'Internal Server Error',
        }),
    );
  };
};

/**
 * getDeliverySetting
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const updateDeliverySetting = (params) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.UPDATE_DELIVERY_SETTING_START});
    return updateDeliverySettingApi(params).then(
        (data) => {
          if (data && data.status === 200) {
            dispatch({type: ACTION_TYPES.UPDATE_DELIVERY_SETTING_SUCCESS});
            dispatch(setMessageModal(modalObj(true, data.message_code)));
            return data.result;
          } else {
            dispatch(setMessageModal(modalObj(true, data.message)));
            dispatch({
              type: ACTION_TYPES.UPDATE_DELIVERY_SETTING_FAIL,
              error: data.message,
            });
          }
        },
        () => dispatch({
          type: ACTION_TYPES.UPDATE_DELIVERY_SETTING_FAIL,
          error: 'Internal Server Error',
        }),
    );
  };
};
/**
 * get list area
 * @param {object} params
 * @return {object}
 */
export const getListArea = () => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_LIST_AREA_START});
    return getListAreaApi().then(
        (data) => {
          if (data && data.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_LIST_AREA_SUCCESS,
              listDeliveryArea: data.result,
            });
            return data.result;
          } else {
            dispatch(setMessageModal(modalObj(true, data.message)));
            dispatch({
              type: ACTION_TYPES.GET_LIST_AREA_FAIL,
              error: data.message,
            });
          }
        },
        () => dispatch({
          type: ACTION_TYPES.GET_LIST_AREA_FAIL,
          error: 'Internal Server Error',
        }),
    );
  };
};

