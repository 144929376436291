export const ACTION_TYPES = {
  SEARCH_MEMBER_START: 'SEARCH_MEMBER_START',
  SEARCH_MEMBER_SUCCESS: 'SEARCH_MEMBER_SUCCESS',
  SEARCH_MEMBER_FAIL: 'SEARCH_MEMBER_FAIL',

  GET_DETAIL_MEMBER_START: 'GET_DETAIL_MEMBER_START',
  GET_DETAIL_MEMBER_SUCCESS: 'GET_DETAIL_MEMBER_SUCCESS',
  GET_DETAIL_MEMBER_FAIL: 'GET_DETAIL_MEMBER_FAIL',

  UPDATE_MEMBER_START: 'UPDATE_MEMBER_START',
  UPDATE_MEMBER_SUCCESS: 'UPDATE_MEMBER_SUCCESS',
  UPDATE_MEMBER_FAIL: 'UPDATE_MEMBER_FAIL',

  CANCEL_MEMBER_START: 'CANCEL_MEMBER_START',
  CANCEL_MEMBER_SUCCESS: 'CANCEL_MEMBER_SUCCESS',
  CANCEL_MEMBER_FAIL: 'CANCEL_MEMBER_FAIL',

  CHECK_VALID_MOBILE_MEMBER_START: 'CHECK_VALID_MOBILE_MEMBER_START',
  CHECK_VALID_MOBILE_MEMBER_SUCCESS: 'CHECK_VALID_MOBILE_MEMBER_SUCCESS',
  CHECK_VALID_MOBILE_MEMBER_FAIL: 'CHECK_VALID_MOBILE_MEMBER_FAIL',

  GET_LIST_FAVORITE_WAYPOINT_START: 'GET_LIST_FAVORITE_WAYPOINT_START',
  GET_LIST_FAVORITE_WAYPOINT_DONE: 'GET_LIST_FAVORITE_WAYPOINT_DONE',

  GET_LIST_FAVORITE_WAYPOINT_ADMIN_START: 'GET_LIST_FAVORITE_WAYPOINT_ADMIN_START',
  GET_LIST_FAVORITE_WAYPOINT_ADMIN_DONE: 'GET_LIST_FAVORITE_WAYPOINT_ADMIN_DONE',

  GET_DETAIL_FAVORITE_WAYPOINT_START: 'GET_DETAIL_FAVORITE_WAYPOINT_START',
  GET_DETAIL_FAVORITE_WAYPOINT_DONE: 'GET_DETAIL_FAVORITE_WAYPOINT_DONE',

  CHANGE_FAVORITE_WAYPOINT_START: 'CHANGE_FAVORITE_WAYPOINT_START',
  CHANGE_FAVORITE_WAYPOINT_DONE: 'CHANGE_FAVORITE_WAYPOINT_DONE',

  UPDATE_DETAIL_TICKET: 'UPDATE_DETAIL_TICKET',

  GET_STATIONS_START: 'GET_STATIONS_START',
  GET_STATIONS_SUCCESS: 'GET_STATIONS_SUCCESS',
  GET_STATIONS_FAILED: 'GET_STATIONS_FAILED',

  GET_TICKETS_START: 'GET_TICKETS_START',
  GET_TICKETS_SUCCESS: 'GET_TICKETS_SUCCESS',
  GET_TICKETS_FAILED: 'GET_TICKETS_FAILED',

  BUY_TICKET_START: 'BUY_TICKET_START',
  BUY_TICKET_SUCCESS: 'BUY_TICKET_SUCCESS',
  BUY_TICKET_FAILED: 'BUY_TICKET_FAILED',

  GET_AGENCY_LIST_START: 'GET_AGENCY_LIST_START',
  GET_AGENCY_LIST_SUCCESS: 'GET_AGENCY_LIST_SUCCESS',
  GET_AGENCY_LIST_FAILED: 'GET_AGENCY_LIST_FAILED',

  GET_TICKETS_BY_USER_START: 'GET_TICKETS_BY_USER_START',
  GET_TICKETS_BY_USER_SUCCESS: 'GET_TICKETS_BY_USER_SUCCESS',
  GET_TICKETS_BY_USER_FAILED: 'GET_TICKETS_BY_USER_FAILED',

  SEARCH_IDENTITY_VERIFICATIONS_START: 'SEARCH_IDENTITY_VERIFICATIONS_START',
  SEARCH_IDENTITY_VERIFICATIONS_SUCCESS: 'SEARCH_IDENTITY_VERIFICATIONS_SUCCESS',
  SEARCH_IDENTITY_VERIFICATIONS_FAIL: 'SEARCH_IDENTITY_VERIFICATIONS_FAIL',

  GET_IDENTITY_VERIFICATION_START: 'GET_IDENTITY_VERIFICATION_START',
  GET_IDENTITY_VERIFICATION_SUCCESS: 'GET_IDENTITY_VERIFICATION_SUCCESS',
  GET_IDENTITY_VERIFICATION_FAIL: 'GET_IDENTITY_VERIFICATION_FAIL',

  UPDATE_IDENTITY_VERIFICATION_START: 'UPDATE_IDENTITY_VERIFICATION_START',
  UPDATE_IDENTITY_VERIFICATION_SUCCESS: 'UPDATE_IDENTITY_VERIFICATION_SUCCESS',
  UPDATE_IDENTITY_VERIFICATION_FAIL: 'UPDATE_IDENTITY_VERIFICATION_FAIL',
};

const initialState = {
  isLoading: false,
  memberList: [],
  memberDetail: {},
  updateMember: {},
  waypointList: [],
  waypointDetail: {},
  user_ticket: {},
  stations: [],
  tickets: [],
  agencyList: [],
  ticketByUser: [],
  waypointAdminList: [],
};

/**
 * function reduce
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.SEARCH_MEMBER_START:
    case ACTION_TYPES.GET_DETAIL_MEMBER_START:
    case ACTION_TYPES.UPDATE_MEMBER_START:
    case ACTION_TYPES.CANCEL_MEMBER_START:
    case ACTION_TYPES.GET_LIST_FAVORITE_WAYPOINT_START:
    case ACTION_TYPES.GET_LIST_FAVORITE_WAYPOINT_ADMIN_START:
    case ACTION_TYPES.GET_DETAIL_FAVORITE_WAYPOINT_START:
    case ACTION_TYPES.CHANGE_FAVORITE_WAYPOINT_START:
    case ACTION_TYPES.GET_STATIONS_START:
    case ACTION_TYPES.GET_TICKETS_START:
    case ACTION_TYPES.BUY_TICKET_START:
    case ACTION_TYPES.GET_AGENCY_LIST_START:
    case ACTION_TYPES.GET_TICKETS_BY_USER_START:
    case ACTION_TYPES.SEARCH_IDENTITY_VERIFICATIONS_START:
    case ACTION_TYPES.GET_IDENTITY_VERIFICATION_START:
    case ACTION_TYPES.UPDATE_IDENTITY_VERIFICATION_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.SEARCH_MEMBER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        memberList: action.memberList,
      };
    case ACTION_TYPES.GET_DETAIL_MEMBER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        memberDetail: action.memberDetail,
      };
    case ACTION_TYPES.UPDATE_MEMBER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        updateMember: action.updateMember,
      };
    case ACTION_TYPES.CANCEL_MEMBER_SUCCESS:
    case ACTION_TYPES.CHANGE_FAVORITE_WAYPOINT_DONE:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.GET_STATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        stations: action.stations,
      };
    case ACTION_TYPES.GET_TICKETS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tickets: action.tickets,
      };
    case ACTION_TYPES.BUY_TICKET_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.GET_AGENCY_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        agencyList: action.agencyList,
      };
    case ACTION_TYPES.GET_TICKETS_BY_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ticketsByUser: action.ticketsByUser,
      };

    case ACTION_TYPES.SEARCH_IDENTITY_VERIFICATIONS_SUCCESS:
    case ACTION_TYPES.GET_IDENTITY_VERIFICATION_SUCCESS:
    case ACTION_TYPES.UPDATE_IDENTITY_VERIFICATION_SUCCESS:
    case ACTION_TYPES.SEARCH_IDENTITY_VERIFICATIONS_FAIL:
    case ACTION_TYPES.GET_IDENTITY_VERIFICATION_FAIL:
    case ACTION_TYPES.UPDATE_IDENTITY_VERIFICATION_FAIL:
    case ACTION_TYPES.CANCEL_MEMBER_FAIL:
    case ACTION_TYPES.GET_DETAIL_MEMBER_FAIL:
    case ACTION_TYPES.SEARCH_MEMBER_FAIL:
    case ACTION_TYPES.UPDATE_MEMBER_FAIL:
    case ACTION_TYPES.GET_STATIONS_FAILED:
    case ACTION_TYPES.BUY_TICKET_FAILED:
    case ACTION_TYPES.GET_AGENCY_LIST_FAILED:
    case ACTION_TYPES.GET_TICKETS_BY_USER_FAILED:
      return {
        isLoading: false,
      };
    case ACTION_TYPES.GET_LIST_FAVORITE_WAYPOINT_DONE:
      return {
        ...state,
        isLoading: false,
        waypointList: action.waypointList,
      };
      case ACTION_TYPES.GET_LIST_FAVORITE_WAYPOINT_ADMIN_DONE:
        return {
          ...state,
          isLoading: false,
          waypointAdminList: action.waypointAdminList,
        };
    case ACTION_TYPES.GET_DETAIL_FAVORITE_WAYPOINT_DONE:
      return {
        ...state,
        isLoading: false,
        waypointDetail: action.waypointDetail,
      };
    case ACTION_TYPES.UPDATE_DETAIL_TICKET:
      return {
        ...state,
        user_ticket: action.user_ticket,
      };
    default:
      return state;
  }
}
