import React from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import ReactDOM from 'react-dom';
import {I18nextProvider} from 'react-i18next';
import {Provider} from 'react-redux';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';
import {PersistGate} from 'redux-persist/lib/integration/react';

import AdminLayout from './layouts/index';
import store, {persistor} from './stores/configureStore';
import './assets/css/site.css';
import i18n from './translations/i18n';

const token = localStorage.getItem('TOKEN_KEY');

ReactDOM.render(
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <PersistGate loading={<div />} persistor={persistor}>
          <BrowserRouter>
            <I18nextProvider i18n={i18n}>
              <Switch>
                <Route path="/maas" render={(props) => <AdminLayout {...props} />} />
                <Redirect from="/" to={token ? '/maas/home' : '/maas/login'} />
              </Switch>
            </I18nextProvider>
          </BrowserRouter>
        </PersistGate>
      </MuiPickersUtilsProvider>
    </Provider>,
    document.getElementById('root'),
);
