import {api} from '../services/api';

const searchCongestionStatusUrl = 'admin/congestion/search';
const getCongestionStatusUrl = 'admin/congestion/';
const registrationCongestionUrl = 'admin/congestion/registration';

export const searchCongestionStatusApi = (payload, queryParams) => {
  return api.search(searchCongestionStatusUrl, queryParams, payload);
};

export const getCongestionStatusApi = (params) => {
  return api.get(getCongestionStatusUrl + params);
};

export const registrationCongestionApi = (payload) => {
  return api.post(registrationCongestionUrl, payload);
};
