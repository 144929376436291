import React, {Component} from 'react';

import {Card, Container, Grid, FormControl, Select, TextField, InputLabel, Button, Box,
  Table, TableCell, TableRow, TableBody, TableHead, FormHelperText, Dialog, MenuItem} from '@material-ui/core';
import {
  ArrowBack as ArrowBackIcon,
  Search as SearchIcon,
  Add as AddIcon,
  Update as UpdateIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

import {ROUTE} from '../../../common/constant';
import {DATA_NULL} from '../../../common/constant';
import SelectModal from '../../../components/selectModal';
import {
  searchMenuAppApi,
  createMenuAppApi,
  updateMenuAppApi,
  configureMenuAppApi,
} from '../../../services/dataManagementServices';
import {getAllCountryCode} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {onChangeTextField, onChangeSelect, onChangeListData} from '../../../utils/common';
import {modalObj} from '../../../utils/modal';
import withPermissionData from '../../../withPermissionData/hoc';

/**
 * Management data menu-app
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      listMenuApp: [],
      menuApp_id: '',
      menuApp_enabled: 'true',
      menuApp_menuCode: '',
      menuApp_name: '',
      menuApp_roleType: 'USER',
      menuApp_showType: 'MOIBLE_APP',
      menuApp_countries: [],

      isSubmitForm: false,
      confirmModal: false,
      confirmMessage: '',
      action: '',
      noResult: false,
      btnAction: false,
      formDisplay: false,
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * clickBtnCreate
   */
  clickBtnCreate = () => {
    const formDisplay = this.state.formDisplay;
    const btnAction = this.state.btnAction;
    if (btnAction) {
      const listMenuApp = this.state.listMenuApp.map((menuApp) => {
        return {
          ...menuApp,
          onFocus: false,
        };
      });
      this.setState({
        btnAction: false,
        menuApp_enabled: 'true',
        menuApp_menuCode: '',
        menuApp_name: '',
        menuApp_roleType: 'USER',
        menuApp_showType: 'MOBILE_APP',
        listMenuApp: listMenuApp,
        formDisplay: true,
      });
    } else this.setState({formDisplay: !formDisplay});
  }

  /**
   * searchMenuAppInfo
   */
  async searchMenuAppInfo() {
    const {
      search_menuApp_id,
      search_menuApp_enabled,
      search_menuApp_menuCode,
      search_menuApp_Name,
      search_menuApp_roleType,
      search_menuApp_showType,
    } = this.state;
    const menuApp = await searchMenuAppApi({
      id: search_menuApp_id ? search_menuApp_id : null,
      enabled: search_menuApp_enabled ? search_menuApp_enabled : true,
      menu_code: search_menuApp_menuCode ? search_menuApp_menuCode : null,
      name: search_menuApp_Name ? search_menuApp_Name : null,
      role_type: search_menuApp_roleType ? search_menuApp_roleType : null,
      show_type: search_menuApp_showType ? search_menuApp_showType : null,
    });
    if (menuApp.status === 200) {
      const processedMenuApp = JSON.parse(JSON.stringify(menuApp.result.content));
      const listMenuApp = processedMenuApp?.map((menuApp) => {
        menuApp.enabledCountries = menuApp.countries.filter((country) => country.enabled);
        return {
          ...menuApp,
          onFocus: false,
        };
      });
      this.setState({
        listMenuApp: listMenuApp,
        noResult: menuApp.result.content.length <= 0,
      });
    }
  }

  /**
   * confirmContentMenuApp
   */
  async confirmContentMenuApp() {
    this.closeConfirmModal();
    if (!this.state.btnAction) {
      const newMenuApp = await createMenuAppApi({
        enabled: this.state.menuApp_enabled,
        menu_code: this.state.menuApp_menuCode,
        name: this.state.menuApp_name,
        role_type: this.state.menuApp_roleType,
        // show_type: this.state.menuApp_showType,
      });
      if (newMenuApp.status === 200) {
        this.notifyResponse('Created Successfully!');
        this.searchMenuAppInfo();
        this.setState({
          formDisplay: false,
          menuApp_enabled: 'true',
          menuApp_menuCode: '',
          menuApp_name: '',
          menuApp_roleType: 'USER',
          menuApp_showType: 'MOBILE_APP',
          isSubmitForm: false,
        });
      } else this.notifyResponse('Create Failed!');
    } else {
      let isSuccessful;

      const newMenuApp = await updateMenuAppApi({
        id: this.state.menuApp_id,
        enabled: this.state.menuApp_enabled,
        menu_code: this.state.menuApp_menuCode,
        name: this.state.menuApp_name,
        role_type: this.state.menuApp_roleType,
        show_type: this.state.menuApp_showType,
      });

      if (this.state.menuApp_enabled === 'true') {
        const countries = [];
        for (const country of this.state.menuApp_countries) {
          if (country.country_id !== '') {
            countries.push({
              country_id: country.country_id,
              link: country.link,
              order_number: country.order_number,
            });
          }
        }
        const newCountries = await configureMenuAppApi({
          menu_app_id: this.state.menuApp_id,
          countries,
        });
        isSuccessful = newMenuApp.status === 200 && newCountries.status === 200;
      } else isSuccessful = newMenuApp.status === 200;

      if (isSuccessful) {
        this.notifyResponse('Updated Successfully!');
        this.searchMenuAppInfo();
        this.setState({isSubmitForm: false});
      } else this.notifyResponse('Update Failed!');
    }
  }

  /**
   * openConfirmModal
   * @param {*} action
   */
  openConfirmModal(action) {
    this.setState({isSubmitForm: true});
    if (!this.validator.allValid()) return;
    let message = '';
    if (action !== '') {
      message = 'Are you sure to delete this Menu App?';
    } else if (this.state.btnAction) {
      action = 'update';
      message = 'Are you sure to update Menu App\'s information?';
    } else {
      action = 'create';
      message = 'Are you sure to create this Menu App?';
    }
    this.setState({
      action,
      confirmMessage: message,
      confirmModal: true,
    });
  }

  /**
   * detectOkAction
   */
  detectOkAction() {
    const {action} = this.state;
    if (action === 'create' || action === 'update') {
      this.confirmContentMenuApp();
    } else if (action === 'delete') {
      // TO DO
      this.closeConfirmModal();
    };
  }

  /**
   * closeConfirmModal
   */
  closeConfirmModal() {
    this.setState({confirmModal: false});
  }

  /**
   * clickBtnUpdate
   */
  async clickBtnUpdate() {
    this.setState({
      formDisplay: true,
    });
  }

  /**
   * clickTableLine
   * @param {number} index
   */
  clickTableLine = (index) => {
    const listMenuApp = this.state.listMenuApp;
    const listNewMenuApp = listMenuApp.map((menuApp) => {
      return {
        ...menuApp,
        onFocus: false,
      };
    });
    listNewMenuApp[index].onFocus = true;
    this.setState({
      listMenuApp: listNewMenuApp,
      menuApp_id: listNewMenuApp[index].id,
      menuApp_enabled: listNewMenuApp[index].enabled ? 'true' : 'false',
      menuApp_menuCode: listNewMenuApp[index].menu_code,
      menuApp_name: listNewMenuApp[index].name,
      menuApp_roleType: listNewMenuApp[index].role_type,
      menuApp_showType: listNewMenuApp[index].show_type,
      btnAction: true,
      formDisplay: false,
      menuApp_countries: JSON.parse(JSON.stringify(listNewMenuApp[index].enabledCountries)),
    });
  }

  /**
   * notifyResponse
   * @param {string} message
   */
  notifyResponse = (message) => {
    this.props.setMessageModal(modalObj(true, message));
  }

  /**
   * addMenuAppCountryRow
   */
  addMenuAppCountryRow = () => {
    this.setState({
      menuApp_countries: [
        ...this.state.menuApp_countries,
        {
          country_id: '',
          link: '',
        },
      ],
    });
  }

  /**
   * deleteMenuAppCountryRow
   * @param {*} index
   */
  deleteMenuAppCountryRow = (index) => {
    const menuApp_countries = [
      ...this.state.menuApp_countries,
    ];
    menuApp_countries.splice(index, 1);
    this.setState({
      menuApp_countries,
    });
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllCountryCode();
  }

  /**
   * Render Component
   * @return {component}
   */
  render() {
    const {t, common} = this.props;

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>Menu App Management</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.DATA_MANAGEMENT}}>
                <Button
                  color="primary"
                  variant="contained"
                  className="button_margin button_color"
                  endIcon={<ArrowBackIcon />}
                >
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="xl">
          <Box p={1} m={1}>
            <Card raised>
              <Container maxWidth="xl">
                <Box p={2} m={2}>
                  <Grid container alignItems="flex-start">
                    <Grid item xs={12}>
                      <h1 className="product_entry_table_header_color font_color_white font_size_mid title">Menu App</h1>
                      <Grid container alignItems="flex-start">
                        <Grid item xs={12}>
                          <Grid container spacing={1} alignItems="flex-start">
                            {/* Search ID */}
                            <Grid item xs={1}>
                              <TextField
                                className="width_100"
                                name="search_menuApp_id"
                                margin="dense"
                                label="ID"
                                variant="outlined"
                                onChange={(event) => onChangeTextField(this, event)}
                              />
                            </Grid>
                            {/* Search Enabled */}
                            <Grid item xs={2}>
                              <FormControl variant="outlined" margin="dense" className="width_100">
                                <InputLabel htmlFor="search_menuApp_enabled">Enabled</InputLabel>
                                <Select
                                  margin="dense"
                                  native
                                  label="Enabled"
                                  inputProps={{
                                    name: 'search_menuApp_enabled',
                                    id: 'search_menuApp_enabled',
                                  }}
                                  onChange={(event) => onChangeSelect(this, event)}
                                >
                                  <option value="true">TRUE</option>
                                  <option value="false">FALSE</option>
                                </Select>
                              </FormControl>
                            </Grid>
                            {/* Search Menu Code */}
                            <Grid item xs={2}>
                              <TextField
                                className="width_100"
                                name="search_menuApp_menuCode"
                                margin="dense"
                                label="Menu Code"
                                variant="outlined"
                                onChange={(event) => onChangeTextField(this, event)}
                              />
                            </Grid>
                            {/* Search Name */}
                            <Grid item xs={2}>
                              <TextField
                                className="width_100"
                                name="search_menuApp_name"
                                margin="dense"
                                label="Name"
                                variant="outlined"
                                onChange={(event) => onChangeTextField(this, event)}
                              />
                            </Grid>
                            {/* Search Role Type */}
                            <Grid item xs={2}>
                              <FormControl variant="outlined" margin="dense" className="width_100">
                                <InputLabel htmlFor="search_menuApp_roleType">Role Type</InputLabel>
                                <Select
                                  margin="dense"
                                  native
                                  label="Role Type"
                                  inputProps={{
                                    name: 'search_menuApp_roleType',
                                    id: 'search_menuApp_roleType',
                                  }}
                                  onChange={(event) => onChangeSelect(this, event)}
                                >
                                  <option value=""></option>
                                  <option value="USER">USER</option>
                                  <option value="DRIVER">DRIVER</option>
                                  <option value="ADMIN">ADMIN</option>
                                </Select>
                              </FormControl>
                            </Grid>
                            {/* Search Show Type */}
                            <Grid item xs={2}>
                              <FormControl variant="outlined" margin="dense" className="width_100">
                                <InputLabel htmlFor="search_menuApp_showType">Show Type</InputLabel>
                                <Select
                                  margin="dense"
                                  native
                                  label="Show Type"
                                  inputProps={{
                                    name: 'search_menuApp_showType',
                                    id: 'search_menuApp_showType',
                                  }}
                                  onChange={(event) => onChangeSelect(this, event)}
                                >
                                  <option value="MOBILE_APP">MOBILE_APP</option>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={1}>
                              <Button
                                color="primary"
                                variant="contained"
                                className="button_margin button_color"
                                endIcon={<SearchIcon />}
                                onClick={() => this.searchMenuAppInfo()}
                              >
                                {t('common.btnSearch')}
                              </Button>
                            </Grid>
                          </Grid>
                          {this.state.listMenuApp.length > 0 && (
                            <Grid container alignItems="flex-start">
                              <div className="scroll_area_800">
                                <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className="width_50p">ID</TableCell>
                                      <TableCell className="width_50p">Enabled</TableCell>
                                      <TableCell className="width_100p">Menu Code</TableCell>
                                      <TableCell className="width_100p">Name</TableCell>
                                      <TableCell className="width_50p">Role Type</TableCell>
                                      <TableCell className="width_50p">Show Type</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {this.state.listMenuApp.map((item, index) => (
                                      <TableRow
                                        className={item.onFocus ? 'cursor_pointer background_pink' : 'cursor_pointer'}
                                        key={index}
                                        onClick={() => this.clickTableLine(index)}
                                      >
                                        <TableCell>{item.id}</TableCell>
                                        <TableCell>{String(item.enabled).toUpperCase()}</TableCell>
                                        <TableCell>{item.menu_code ? item.menu_code : DATA_NULL}</TableCell>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>{item.role_type ? item.role_type : DATA_NULL}</TableCell>
                                        <TableCell>{item.show_type ? item.show_type : DATA_NULL}</TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </div>
                            </Grid>
                          )}
                          {this.state.noResult && <h5 className="text_grey">No menu app found</h5>}
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container spacing={1} alignItems="flex-start">
                            <Grid item>
                              <Button
                                color="primary"
                                variant="contained"
                                className="button_margin
                                button_color_green"
                                endIcon={<AddIcon />}
                                onClick={() => this.clickBtnCreate()}
                              >
                                {t('common.btnRegister')}
                              </Button>
                            </Grid>
                            {this.state.btnAction && (
                              <>
                                <Grid item>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    className="button_margin button-color-update"
                                    endIcon={<UpdateIcon />}
                                    onClick={() => this.clickBtnUpdate()}
                                  >
                                    {t('common.btnUpdate')}
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    className="button_margin button_color_red"
                                    endIcon={<DeleteIcon />}
                                    onClick={() => this.openConfirmModal('delete')}
                                  >
                                    {t('common.btnDelete')}
                                  </Button>
                                </Grid>
                              </>
                            )}
                          </Grid>
                          {this.state.formDisplay && (
                            <Grid container spacing={1} alignItems="flex-start">
                              {/* Enabled */}
                              <Grid item xs={12}>
                                <FormControl variant="outlined" className="field_size_20 field_min_size_250" margin="dense">
                                  <InputLabel htmlFor="data_enabled">Enabled</InputLabel>
                                  <Select
                                    name="menuApp_enabled"
                                    margin="dense"
                                    native
                                    label="Enabled"
                                    inputProps={{
                                      id: 'data_enabled',
                                    }}
                                    value={this.state.menuApp_enabled}
                                    onChange={(event) => onChangeSelect(this, event)}
                                  >
                                    <option value="true">TRUE</option>
                                    <option value="false">FALSE</option>
                                  </Select>
                                </FormControl>
                              </Grid>
                              {/* Menu Code */}
                              <Grid item xs={12}>
                                <FormControl>
                                  <TextField
                                    name="menuApp_menuCode"
                                    className="field_size_20 field_min_size_250"
                                    value={this.state.menuApp_menuCode}
                                    margin="dense"
                                    label={
                                      <span>
                                        Menu Code
                                        <span className="font_color_red">＊</span>
                                      </span>
                                    }
                                    onChange={(event) => onChangeTextField(this, event)}
                                    variant="outlined"
                                    InputLabelProps={{shrink: true}}
                                  />
                                  {this.validator.message('menu_code', this.state.menuApp_menuCode, 'required')}
                                  {this.state.isSubmitForm && !this.validator.check(this.state.menuApp_menuCode, 'required') && (
                                    <FormHelperText id="menu_code" error>
                                      {t('validation.required', {field: 'Menu Code'})}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                              {/* Name */}
                              <Grid item xs={12}>
                                <FormControl>
                                  <TextField
                                    name="menuApp_name"
                                    className="field_size_20 field_min_size_250"
                                    value={this.state.menuApp_name}
                                    margin="dense"
                                    label={
                                      <span>
                                        Name
                                        <span className="font_color_red">＊</span>
                                      </span>
                                    }
                                    onChange={(event) => onChangeTextField(this, event)}
                                    variant="outlined"
                                    InputLabelProps={{shrink: true}}
                                  />
                                  {this.validator.message('name', this.state.menuApp_name, 'required')}
                                  {this.state.isSubmitForm && !this.validator.check(this.state.menuApp_name, 'required') && (
                                    <FormHelperText id="name" error>
                                      {t('validation.required', {field: 'Name'})}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                              {/* Role Type */}
                              <Grid item xs={12}>
                                <FormControl variant="outlined" className="field_size_20 field_min_size_250" margin="dense">
                                  <InputLabel htmlFor="data_role_type">Role Type</InputLabel>
                                  <Select
                                    name="menuApp_roleType"
                                    margin="dense"
                                    native
                                    label="Role Type"
                                    inputProps={{
                                      id: 'data_role_type',
                                    }}
                                    value={this.state.menuApp_roleType}
                                    onChange={(event) => onChangeSelect(this, event)}
                                  >
                                    <option value="USER">USER</option>
                                    <option value="DRIVER">DRIVER</option>
                                    <option value="ADMIN">ADMIN</option>
                                  </Select>
                                </FormControl>
                              </Grid>
                              {/* Show Type */}
                              <Grid item xs={12}>
                                <FormControl variant="outlined" className="field_size_20 field_min_size_250" margin="dense">
                                  <InputLabel htmlFor="data_show_type">Show Type</InputLabel>
                                  <Select
                                    name="menuApp_showType"
                                    margin="dense"
                                    native
                                    label="Show Type"
                                    inputProps={{
                                      id: 'data_show_type',
                                    }}
                                    value={this.state.menuApp_showType}
                                    onChange={(event) => onChangeSelect(this, event)}
                                  >
                                    <option value="MOBILE_APP">MOBILE_APP</option>
                                  </Select>
                                </FormControl>
                              </Grid>
                              {this.state.btnAction && this.state.menuApp_enabled === 'true' && (
                                <Grid item xs={12}>
                                  <Table aria-label="caption table">
                                    {this.state.menuApp_countries.length < Object.keys(common.country_code).length && (
                                      <caption>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="button_magin button_color_green"
                                          endIcon={<AddIcon />}
                                          onClick={() => this.addMenuAppCountryRow()}
                                        >
                                          {t('common.btnAdd')}
                                        </Button>
                                      </caption>
                                    )}
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>{t('common.country')}</TableCell>
                                        <TableCell>{t('menu_app.link')}</TableCell>
                                        <TableCell></TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {this.state.menuApp_countries.map((row, index) => (
                                        <TableRow key={index} hover className="cursor_pointer">
                                          {/* Country Code */}
                                          <TableCell>
                                            <FormControl variant="outlined" margin="dense">
                                              <Select
                                                margin="dense"
                                                displayEmpty
                                                onChange={(event) => onChangeListData(this, this.state.menuApp_countries, 'country_id', index, event.target.value)}
                                                value={this.state.menuApp_countries[index].country_id}
                                              >
                                                {common.country_code?.map((item, idx) => {
                                                  return (
                                                    <MenuItem
                                                      value={item.id}
                                                      key={idx}
                                                      disabled={
                                                        item.id !== this.state.menuApp_countries[index].country_id &&
                                                        this.state.menuApp_countries.filter((country) => Number(country.country_id) === Number(item.id)).length > 0
                                                      }
                                                    >
                                                      {t(`${item.country_code}`)}
                                                    </MenuItem>
                                                  );
                                                })}
                                              </Select>
                                            </FormControl>
                                          </TableCell>
                                          {/* Country Link */}
                                          <TableCell>
                                            <FormControl className="width_100">
                                              <TextField
                                                value={this.state.menuApp_countries[index].link}
                                                margin="dense"
                                                label="Link"
                                                onChange={(event) => onChangeListData(this, this.state.menuApp_countries, 'link', index, event.target.value)}
                                                variant="outlined"
                                              />
                                            </FormControl>
                                          </TableCell>
                                          <TableCell>
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="button_margin button_color_red"
                                              onClick={() => this.deleteMenuAppCountryRow(index)}
                                              endIcon={<DeleteIcon />}
                                            >
                                              {t(`common.btnDelete`)}
                                            </Button>
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </Grid>
                              )}
                              <Grid item xs={12}>
                                <Button color="primary" variant="contained" className="button_margin" onClick={() => this.openConfirmModal('')}>
                                  {t('common.btnConfirm')}
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Dialog
                        open={this.state.confirmModal}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          zIndex: '1020',
                        }}
                      >
                        <SelectModal
                          onClickOk={() => this.detectOkAction()}
                          onClickCancel={() => this.closeConfirmModal()}
                          message={this.state.confirmMessage}
                        ></SelectModal>
                      </Dialog>
                    </Grid>
                  </Grid>
                </Box>
              </Container>
            </Card>
          </Box>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
  };
};

export default withPermissionData(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
