import React, {Component} from 'react';

import {Map, GoogleApiWrapper, Polyline} from 'google-maps-react';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';

import {setMessageModal} from '../../stores/modal/actions';

/**
 * Map Booking Jit
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      centerPoint: {
        lat: 36.2048,
        lng: 138.2529,
      },
      pickUpPoint: [],
      dropOffPoint: [],
      polyline: '',
      selectPickUpPoint: {},
      selectDropOffPoint: {},
      pickUpPointMarker: [],
      dropOffPointMarker: [],
      oldPickUpPointMarker: null,
      oldDropOffPointMarker: null,
      routeId: null,
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    const {dataPolyline} = this.props;
    const pickUpPoint = dataPolyline.pickUpPoint.map((pickUp) => ({lat: pickUp.from.latitude, lng: pickUp.from.longitude}));
    const dropOffPoint = dataPolyline.dropOffPoint.map((dropOff) => ({lat: dropOff.to.latitude, lng: dropOff.to.longitude}));
    this.setState({
      pickUpPoint: pickUpPoint,
      dropOffPoint: dropOffPoint,
      selectPickUpPoint: dataPolyline.selectPickUpPoint,
      selectDropOffPoint: dataPolyline.selectDropOffPoint,
    });
    await this.renderMap(dataPolyline, pickUpPoint, dropOffPoint);
    if (!_.isEmpty(dataPolyline.selectPickUpPoint) && !_.isEmpty(dataPolyline.selectDropOffPoint) && !this.state.routeId && dataPolyline.routeId) {
      const pickUpPointMarker = this.state.pickUpPointMarker;
      const dropOffPointMarker = this.state.dropOffPointMarker;
      const indexPickUpPoint = dataPolyline.pickUpPoint.findIndex((item) => item.from_stop_id === dataPolyline.selectPickUpPoint.from_stop_id);
      pickUpPointMarker[indexPickUpPoint].setIcon(`${process.env.PUBLIC_URL}/images/ic_pick_origin.svg`);

      const indexDropOffPoint = dataPolyline.dropOffPoint.findIndex((item) => item.to_stop_id === dataPolyline.selectDropOffPoint.to_stop_id);
      dropOffPointMarker[indexDropOffPoint].setIcon(`${process.env.PUBLIC_URL}/images/ic_pick_destination.svg`);
      this.setState({oldPickUpPointMarker: pickUpPointMarker[indexPickUpPoint], oldDropOffPointMarker: dropOffPointMarker[indexDropOffPoint]});
    }
    this.setState({routeId: dataPolyline.routeId});
  }

  /**
   * componentWillReceiveProps
   * @param {Props} nextProps
   */
  UNSAFE_componentWillReceiveProps = async (nextProps) => {
    const pickUpPoint = nextProps?.dataPolyline.pickUpPoint.map((pickUp) => ({lat: pickUp.from.latitude, lng: pickUp.from.longitude}));
    const dropOffPoint = nextProps?.dataPolyline.dropOffPoint.map((dropOff) => ({lat: dropOff.to.latitude, lng: dropOff.to.longitude}));
    this.setState({
      routeId: this.props.dataPolyline.routeId,
      pickUpPoint: pickUpPoint,
      dropOffPoint: dropOffPoint,
      selectPickUpPoint: nextProps?.dataPolyline.selectPickUpPoint,
      selectDropOffPoint: nextProps?.dataPolyline.selectDropOffPoint,
    });
    if (
      pickUpPoint?.length !== this.state.pickUpPoint?.length ||
      dropOffPoint?.length !== this.state.dropOffPoint?.length ||
      (pickUpPoint?.length === this.state.pickUpPoint?.length && dropOffPoint?.length === this.state.dropOffPoint?.length && this.state.routeId !== nextProps.dataPolyline?.routeId)
    ) {
      if (!_.isEmpty(this.state.oldDropOffPointMarker) && !_.isEmpty(this.state.oldPickUpPointMarker)) {
        this.setState({oldDropOffPointMarker: null, oldPickUpPointMarker: null});
      }
      this.state.pickUpPointMarker.forEach((pickUpPointMarker) => {
        pickUpPointMarker.setMap(null);
      });
      this.state.dropOffPointMarker.forEach((dropOffPointMarker) => {
        dropOffPointMarker.setMap(null);
      });
      this.renderMap(nextProps.dataPolyline, pickUpPoint, dropOffPoint);
    } else {
      this.updateMarkerIcon(nextProps);
    }
  };

  /**
   * updateMarkerIcon
   * @param {Props} nextProps
   */
  updateMarkerIcon = (nextProps) => {
    const {dataPolyline} = this.props;
    const selectPickUpPoint = nextProps.dataPolyline.selectPickUpPoint;
    const selectDropOffPoint = nextProps.dataPolyline.selectDropOffPoint;
    const pickUpPointMarker = this.state.pickUpPointMarker;
    const dropOffPointMarker = this.state.dropOffPointMarker;
    const oldPickUpPointMarker = this.state.oldPickUpPointMarker;
    const oldDropOffPointMarker = this.state.oldDropOffPointMarker;
    // set default pickup marker
    if (dataPolyline.selectDropOffPoint === selectDropOffPoint) {
      if (oldPickUpPointMarker) {
        oldPickUpPointMarker.setIcon(`${process.env.PUBLIC_URL}/images/pick_up_point.svg`);
        this.setState({oldPickUpPointMarker: null});
      }
      // set select pickup marker
      if (!_.isEmpty(selectPickUpPoint)) {
        const indexPickUpPoint = dataPolyline.pickUpPoint.findIndex((item) => item.from_stop_id === selectPickUpPoint.from_stop_id);
        pickUpPointMarker[indexPickUpPoint].setIcon(`${process.env.PUBLIC_URL}/images/ic_pick_origin.svg`);
        this.setState({oldPickUpPointMarker: pickUpPointMarker[indexPickUpPoint]});
      }
    }
    if (dataPolyline.selectPickUpPoint === selectPickUpPoint) {
      // set default dropoff marker
      if (oldDropOffPointMarker) {
        oldDropOffPointMarker.setIcon(`${process.env.PUBLIC_URL}/images/drop_off_point.svg`);
        this.setState({oldDropOffPointMarker: null});
      }
      // set select dropoff marker
      if (!_.isEmpty(selectDropOffPoint)) {
        const indexDropOffPoint = dataPolyline.dropOffPoint.findIndex((item) => item.to_stop_id === selectDropOffPoint.to_stop_id);
        dropOffPointMarker[indexDropOffPoint].setIcon(`${process.env.PUBLIC_URL}/images/ic_pick_destination.svg`);
        this.setState({oldDropOffPointMarker: dropOffPointMarker[indexDropOffPoint]});
      }
    }
  };

  /**
   * findCenter
   * @param {Array} coordinates
   * @return {Object}
   */
  findCenter = (coordinates) => {
    if (coordinates.length === 0) return;
    const latList = coordinates.map((coordinate) => coordinate.lat);
    const lngList = coordinates.map((coordinate) => coordinate.lng);
    const minLat = Math.min(...latList);
    const maxLat = Math.max(...latList);
    const minLng = Math.min(...lngList);
    const maxLng = Math.max(...lngList);
    return {
      lat: (minLat + maxLat) / 2,
      lng: (minLng + maxLng) / 2,
    };
  };

  /**
   * renderMap
   * @param {String} dataPolyline
   * @param {String} pickUpPoint
   * @param {String} dropOffPoint
   */
  renderMap = (dataPolyline, pickUpPoint, dropOffPoint) => {
    if (dataPolyline?.polyline === '') return;
    const {google} = this.props;
    // Process polyline data
    const decodedPath = google.maps.geometry.encoding.decodePath(dataPolyline.polyline);
    const decodedCoordinates = decodedPath.map(
      (item) =>
        (item = {
          lat: item.lat(),
          lng: item.lng(),
        }),
    );
    const polylineForBooking = decodedCoordinates;
    const centerPoint = this.findCenter(polylineForBooking);
    // Print elements to map
    if (this.mapRef) {
      // Set pickup and dropoff point to map
      this.mapRef.map.setCenter(centerPoint);
      if (pickUpPoint.length > 0) {
        const pickUpPointMarker = [];
        pickUpPoint.forEach((pickUp, index) => {
          const marker = new google.maps.Marker({
            position: pickUp,
            icon: {
              url: `${process.env.PUBLIC_URL}/images/pick_up_point.svg`,
              scale: 10,
              strokeWeight: 2,
              fillOpacity: 1,
            },
            id: dataPolyline.pickUpPoint[index].from_stop_id,
            map: this.mapRef.map,
          });
          google.maps.event.addListener(marker, 'click', () => {
            const oldPickUpPointMarker = this.state.oldPickUpPointMarker;
            if (oldPickUpPointMarker?.id === marker?.id) {
              return;
            }
            if (oldPickUpPointMarker) {
              oldPickUpPointMarker.setIcon(`${process.env.PUBLIC_URL}/images/pick_up_point.svg`);
            }
            // set default pickup marker
            marker.setIcon(`${process.env.PUBLIC_URL}/images/ic_pick_origin.svg`);
            this.props.selectMarker(dataPolyline.pickUpPoint[index], 'PICK_UP');
            this.setState({oldPickUpPointMarker: marker});
          });
          pickUpPointMarker.push(marker);
        });
        this.setState({pickUpPointMarker});
      }
      if (dropOffPoint.length > 0) {
        const dropOffPointMarker = [];
        dropOffPoint.forEach((dropOff, index) => {
          const marker = new google.maps.Marker({
            position: dropOff,
            icon: {
              url: `${process.env.PUBLIC_URL}/images/drop_off_point.svg`,
              scale: 10,
              strokeWeight: 2,
              fillOpacity: 1,
            },
            id: dataPolyline.dropOffPoint[index].to_stop_id,
            map: this.mapRef.map,
          });
          google.maps.event.addListener(marker, 'click', () => {
            const oldDropOffPointMarker = this.state.oldDropOffPointMarker;
            if (oldDropOffPointMarker?.id === marker?.id) {
              return;
            }
            if (oldDropOffPointMarker) {
              oldDropOffPointMarker.setIcon(`${process.env.PUBLIC_URL}/images/drop_off_point.svg`);
            }
            marker.setIcon(`${process.env.PUBLIC_URL}/images/ic_pick_destination.svg`);
            this.props.selectMarker(dataPolyline.dropOffPoint[index], 'DROP_OFF');
            this.setState({oldDropOffPointMarker: marker});
          });
          dropOffPointMarker.push(marker);
        });
        this.setState({dropOffPointMarker});
      }
    }
    // Set polyline to map
    this.setState({
      polyline: <Polyline path={polylineForBooking} strokeColor="#3f51b5" strokeOpacity={1} strokeWeight={4} />,
    });
    return;
  };

  /**
   * render
   * @return {HTMLElement}
   */
  render() {
    return (
      <div>
        <Map
          {...this.props}
          ref={(ref) => (this.mapRef = ref)}
          initialCenter={this.state.centerPoint}
          zoom={14}
          centerAroundCurrentLocation={false}
          containerStyle={{
            height: '650px',
            position: 'relative',
            width: this.props.dataPolyline.type === 'booking' && '27.8vw',
          }}
        >
          {this.state.polyline}
        </Map>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMessageModal: () => dispatch(setMessageModal),
  };
};

// eslint-disable-next-line new-cap
export default withTranslation('translations')(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    // eslint-disable-next-line new-cap
    GoogleApiWrapper((props) => ({
      apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || 'AIzaSyACyapw83diO1bi_xiXbZRLLoano6eTwd0',
      language: props.localLanguage,
      libraries: ['geometry'],
    }))(Index),
  ),
);
