import React, {Component} from 'react';

import {Card, Container, Grid, Button, Box, Dialog} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {ROUTE} from '../../../common/constant';
import SelectModal from '../../../components/selectModal';
import {clearCacheAuthApi, clearCacheMassApi, clearCacheFunctionApi} from '../../../services/cachingServices';
import {setMessageModal} from '../../../stores/modal/actions';
import {modalObj} from '../../../utils/modal';
import withPermissionData from '../../../withPermissionData/hoc';

/**
 * Caching Management view
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      secretKeyModal: false,
      isSubmitForm: false,
      isLoading: false,
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    this.setState({
      data: [
        {
          'link': '/api/auth/refresh-app-config',
          'action': this.clearCacheAuth,
          'open': false,
        },
        {
          'link': '/api/admin/clear-cache/mass',
          'action': this.clearCacheMass,
          'open': false,
        },
        {
          'link': '/api/admin/function/clear-cache',
          'action': this.clearCacheFunction,
          'open': false,
        },
      ],
    });
  }

  /**
   * clearCacheAuth
   * @return {object}
   */
  clearCacheAuth = () => {
    this.setState({isLoading: true});
    this.handleDisplayModal(0, false);

    return clearCacheAuthApi().then(
        (response) => {
          if (response && response.status === 200) {
            this.notifyResponse('Successfully!');
          } else this.notifyResponse('Failed!');
          this.setState({isLoading: false});
        },
        () => {
          this.notifyResponse('Failed!');
          this.setState({isLoading: false});
        },
    );
  }

  /**
   * clearCacheMass
   * @return {object}
   */
  clearCacheMass = () => {
    this.setState({isLoading: true});
    this.handleDisplayModal(1, false);

    return clearCacheMassApi().then(
        (response) => {
          if (response && response.status === 200) {
            this.notifyResponse('Successfully!');
          } else this.notifyResponse('Failed!');
          this.setState({isLoading: false});
        },
        () => {
          this.notifyResponse('Failed!');
          this.setState({isLoading: false});
        },
    );
  }

  /**
   * clearCacheFunction
   * @return {object}
   */
   clearCacheFunction = () => {
    this.setState({isLoading: true});
    this.handleDisplayModal(1, false);

    return clearCacheFunctionApi().then(
        (response) => {
          if (response && response.status === 200) {
            this.notifyResponse('Successfully!');
          } else this.notifyResponse('Failed!');
          this.setState({isLoading: false});
        },
        () => {
          this.notifyResponse('Failed!');
          this.setState({isLoading: false});
        },
    );
  }

  /**
   * notifyResponse
   * @param {string} message
   */
  notifyResponse = (message) => {
    this.props.setMessageModal(modalObj(true, message));
  }

  /**
   * handleDisplayModal
   * @param {Number} index
   * @param {Boolean} open
   */
  handleDisplayModal = (index, open) => {
    if (index === 1 && this.state.secretKeyModal) {
      return;
    }
    const data = this.state.data;
    data[index].open = open;
    this.setState({
      data: data,
    });
  }

  /**
   * closeSecretKeyModal
   */
  closeSecretKeyModal = () => {
    this.setState({secretKeyModal: false});
  }

  /**
   * Render component
   * @return {object}
   */
  render() {
    const {t} = this.props;

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>Caching management</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.DATA_MANAGEMENT}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br/>
          <Box>
            <LoadingOverlay active={this.state.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
              <Card raised>
                <Container maxWidth="xl">
                  <br></br>
                  {this.state.data?.map((element, index) => {
                    return (
                      <Grid key={index} container item alignItems="center" justify="center" xs={12} lg={12}>
                        <Grid container item alignItems="center" justify="center" xs={2} lg={2} className="padding-item-15">
                          <span className="font-18 font-weight-600 item-content">Link</span>
                        </Grid>
                        <Grid container item alignItems="center" justify="center" xs={5} lg={5} className="padding-item-15">
                          <span>{element.link}</span>
                        </Grid>
                        <Grid container item alignItems="center" justify="center" xs={3} lg={2} className="padding-item-15">
                          <Button
                            color="primary"
                            size="small"
                            variant="contained"
                            className="button_margin"
                            onClick={() => this.handleDisplayModal(index, true)}
                          >
                            {t('common.btnSend')}
                          </Button>
                        </Grid>
                        <Dialog
                          open={element.open}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: '1020',
                          }}
                        >
                          <SelectModal
                            onClickOk={element.action}
                            onClickCancel={() => this.handleDisplayModal(index, false)}
                            message="Are you sure to process this action?"
                          ></SelectModal>
                        </Dialog>
                      </Grid>
                    );
                  })}
                  <br/>
                </Container>
              </Card>
            </LoadingOverlay>
            <br/>
          </Box>
        </Container>
      </Card>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
  };
};

export default withPermissionData(withTranslation('translations')(connect(null, mapDispatchToProps)(Index)));
