export const ACTION_TYPES = {
  LOGIN_START: 'LOGIN_START',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',

  PASSWORD_REISSUE_START: 'PASSWORD_REISSUE_START',
  PASSWORD_REISSUE_SUCCESS: 'PASSWORD_REISSUE_SUCCESS',
  PASSWORD_REISSUE_FAIL: 'PASSWORD_REISSUE_FAIL',

  PASSWORD_REISSUE_CONFIRM_START: 'PASSWORD_REISSUE_CONFIRM_START',
  PASSWORD_REISSUE_CONFIRM_SUCCESS: 'PASSWORD_REISSUE_CONFIRM_SUCCESS',
  PASSWORD_REISSUE_CONFIRM_FAIL: 'PASSWORD_REISSUE_CONFIRM_FAIL',

  NEW_PASSWORD_REISSUE_START: 'NEW_PASSWORD_REISSUE_START',
  NEW_PASSWORD_REISSUE_SUCCESS: 'NEW_PASSWORD_REISSUE_SUCCESS',
  NEW_PASSWORD_REISSUE_FAIL: 'NEW_PASSWORD_REISSUE_FAIL',

  USER_DETAIL_START: 'USER_DETAIL_START',
  USER_DETAIL_SUCCESS: 'USER_DETAIL_SUCCESS',
  USER_DETAIL_FAIL: 'USER_DETAIL_FAIL',

  REGISTER_START: 'REGISTER_START',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAIL: 'REGISTER_FAIL',

  CHANGE_PASSWORD_START: 'CHANGE_PASSWORD_START',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAIL: 'CHANGE_PASSWORD_FAIL',
};

const initialState = {
  isLoading: false,
  isLoadingVerifyPasswordReissue: false,
  isLoadingNewPasswordReissue: false,
  isLoadingRegister: false,
  objectUser: {},
  userDetail: {},
  passwordUpdate: {},
  error: '',
};

/**
 * function reduce
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.LOGIN_START:
    case ACTION_TYPES.USER_DETAIL_START:
    case ACTION_TYPES.PASSWORD_REISSUE_START:
    case ACTION_TYPES.CHANGE_PASSWORD_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.REGISTER_START:
      return {
        ...state,
        isLoadingRegister: true,
      };
    case ACTION_TYPES.PASSWORD_REISSUE_CONFIRM_START:
      return {
        ...state,
        isLoadingVerifyPasswordReissue: true,
      };
    case ACTION_TYPES.NEW_PASSWORD_REISSUE_START:
      return {
        ...state,
        isLoadingNewPasswordReissue: true,
      };
    case ACTION_TYPES.PASSWORD_REISSUE_SUCCESS:
    case ACTION_TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        objectUser: action.objectUser,
      };
    case ACTION_TYPES.USER_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userDetail: action.userDetail,
      };
    case ACTION_TYPES.PASSWORD_REISSUE_CONFIRM_SUCCESS:
      return {
        ...state,
        isLoadingVerifyPasswordReissue: false,
        objectUser: action.objectUser,
      };
    case ACTION_TYPES.NEW_PASSWORD_REISSUE_SUCCESS:
      return {
        ...state,
        isLoadingNewPasswordReissue: false,
        objectUser: action.objectUser,
      };
    case ACTION_TYPES.REGISTER_SUCCESS:
      return {
        ...state,
        isLoadingRegister: false,
      };
    case ACTION_TYPES.LOGIN_FAIL:
    case ACTION_TYPES.USER_DETAIL_FAIL:
      return {
        isLoading: false,
        error: action.error,
      };
    case ACTION_TYPES.REGISTER_FAIL:
      return {
        isLoadingRegister: false,
      };
    case ACTION_TYPES.PASSWORD_REISSUE_FAIL:
      return {
        isLoading: false,
        error: action.error,
        objectUser: {},
      };
    case ACTION_TYPES.PASSWORD_REISSUE_CONFIRM_FAIL:
      return {
        isLoadingVerifyPasswordReissue: true,
        error: action.error,
        objectUser: {},
      };
    case ACTION_TYPES.NEW_PASSWORD_REISSUE_FAIL:
      return {
        isLoadingNewPasswordReissue: false,
        error: action.error,
        objectUser: {},
      };
    case ACTION_TYPES.CHANGE_PASSWORD_SUCCESS:
    case ACTION_TYPES.CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
