import {Component, default as React} from 'react';

import {
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  InputLabel,
  TableHead,
  Box,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import UploadIcon from '@material-ui/icons/Publish';
import SearchIcon from '@material-ui/icons/Search';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {ROUTE, GEOFENCE_STATUS, DATA_NULL, ROWS_PER_PAGE_OPTIONS} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import {getWillerStopSet, validateStopSet} from '../../../stores/simulation/actions';
import {onChangeSelect, onChangeTextField} from '../../../utils/common';
import withPermissionData from '../../../withPermissionData/hoc';

/**
 * Simulation detail
 */
class Index extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      display_name: '',
      description: '',
      enable: '',
      selectData: {},
      selectedFile: null,

      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      isLoading: false,
    };
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.handleSearch();
  }

  /**
   * handleSearch
   * @param {Boolean} reset
   */
  handleSearch = (reset = false) => {
    const {name, display_name, description, enable, currentPage, rowsPerPage} = this.state;
    const queryParams = {
      name,
      display_name,
      description,
      page: reset ? 0 : currentPage,
      size: reset ? ROWS_PER_PAGE_OPTIONS[0] : rowsPerPage,
    };
    if (enable !== '') {
      queryParams.enable = enable === true;
    }
    this.props.getWillerStopSet(queryParams);
    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
  };

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = async (currentPage, rowsPerPage) => {
    await this.setState({currentPage, rowsPerPage});
    await this.handleSearch();
  };

  /**
   * selectStopSet
   * @param {Object} item
   */
  selectStopSet = async (item) => {
    await this.setState({
      selectData: item,
      selectedFile: null,
      inputComponent: '',
    });
    this.setState({
      inputComponent: (<input
        type="file"
        accept=".xlsx"
        onChange={(event) => this.setState({selectedFile: event.target.files[0]})}
      />),
    });
  };

  /**
   * uploadFile
   */
  uploadFile = () => {
    const formData = new FormData();
    formData.append(
      'fileUpload',
      this.state.selectedFile,
      this.state.selectedFile.name,
    );
    this.props.validateStopSet(this.state.selectData.id, formData)
    .then((response) => {
      const blob = new Blob([response], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `stop_master_result_${this.state.selectedFile.name}`);
      document.body.appendChild(link);
      link.click();
    });
  }

  /**
   * render
   * @return {HTML}
   */
  render() {
    const {t, simulation} = this.props;

    return (
      <LoadingOverlay active={simulation.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>File Upload</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}>
                <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.DATA_MANAGEMENT}}>
                  <Button
                    color="primary"
                    variant="contained"
                    className="button_margin button_color"
                    endIcon={<ArrowBackIcon />}
                  >
                    {t('common.btnReturn')}
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <br/>
            <Card raised>
              <Container maxWidth="xl">
                <Grid item xs={12}>
                  <h1 className="product_entry_table_header_color font_color_white font_size_mid title" style={{marginTop: 15}}>
                    {t('simulationManagement.willer_stop_set')}
                  </h1>
                  <Grid container alignItems="flex-start">
                    <Grid item xs={12}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={2}>
                          <TextField
                            className="width_100"
                            name="name"
                            margin="dense"
                            label={t('common.name')}
                            variant="outlined"
                            value={this.state.name}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            className="width_100"
                            name="display_name"
                            margin="dense"
                            label={t('simulationManagement.display_name')}
                            variant="outlined"
                            value={this.state.display_name}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            className="width_100"
                            name="description"
                            margin="dense"
                            label={t('simulationManagement.description')}
                            variant="outlined"
                            value={this.state.description}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                        </Grid>
                        <Grid item xs={2} alignItems="center" justify="center">
                          <FormControl variant="outlined" fullWidth margin="dense">
                            <InputLabel>{t('common.enable')}</InputLabel>
                            <Select
                              labelWidth={165}
                              margin="dense"
                              value={this.state.enable}
                              onChange={(event) => onChangeSelect(this, event)}
                              inputProps={{
                                name: 'enable',
                              }}
                            >
                              {GEOFENCE_STATUS.map((item, index) => {
                                return (
                                  <MenuItem value={item.id} key={index}>
                                    {t(`${item.i18n}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                          <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<SearchIcon />} onClick={() => this.handleSearch(true)}>
                            {t('common.btnSearch')}
                          </Button>
                        </Grid>
                      </Grid>
                      {simulation?.stop_set_data?.content?.length > 0 && (
                        <Grid container alignItems="flex-start">
                          <div>
                            <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                              <TableHead>
                                <TableRow>
                                  <TableCell className="width_50p">{t('common.id')}</TableCell>
                                  <TableCell className="width_75p">{t('simulationManagement.swat_transit_stop_set_id')}</TableCell>
                                  <TableCell className="width_100p">{t('common.name')}</TableCell>
                                  <TableCell className="width_100p">{t('simulationManagement.display_name')}</TableCell>
                                  <TableCell className="width_150p">{t('simulationManagement.description')}</TableCell>
                                  <TableCell className="width_75p">{t('common.enable')}</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {simulation?.stop_set_data?.content?.map((item, index) => {
                                  return (
                                    <TableRow
                                      key={index}
                                      onClick={() => this.selectStopSet(item)}
                                      className={this.state.selectData?.id === item.id ? 'row_form_item select_modal_item cursor_pointer' : 'row_form_item cursor_pointer'}
                                    >
                                      <TableCell>{item.id}</TableCell>
                                      <TableCell>{item.swat_transit_stop_set_id ? item.swat_transit_stop_set_id : DATA_NULL}</TableCell>
                                      <TableCell>{item.name ? item.name : DATA_NULL}</TableCell>
                                      <TableCell>{item.display_name ? item.display_name : DATA_NULL}</TableCell>
                                      <TableCell>{item.description ? item.description : DATA_NULL}</TableCell>
                                      <TableCell>{item.enable ? t(GEOFENCE_STATUS[0].i18n) : t(GEOFENCE_STATUS[0].i18n)}</TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                            <CustomPagination
                              onChange={this.onChangePagination}
                              rows={simulation?.stop_set_data?.totalSize}
                              rowsPerPage={this.state.rowsPerPage}
                              currentPage={this.state.currentPage}
                            />
                          </div>
                        </Grid>
                      )}
                      {simulation?.stop_set_data?.content?.length < 0 && <h5 className="text_grey">{t('reservationManagement.no_result')}</h5>}
                    </Grid>
                    {Object.keys(this.state.selectData).length > 0 && (
                      <Grid item xs={12}>
                        <Box display="flex" flexDirection="row">
                          <Box display="flex" flexDirection="column">
                            {this.state.inputComponent}
                            <span style={{fontSize: 13, color: '#3f51b5', marginTop: 8, marginLeft: 3}}>Only accept .xlsx file type</span>
                          </Box>
                          <Button
                            color="primary"
                            variant="contained"
                            disabled={!this.state.selectedFile}
                            className={!this.state.selectedFile ? '' : 'button_color_info'}
                            endIcon={<UploadIcon />}
                            style={{marginLeft: 30}}
                            onClick={() => this.uploadFile()}
                          >
                            Upload File
                          </Button>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                  <br />
                </Grid>
              </Container>
            </Card>
            <br/>
          </Container>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    simulation: state.simulation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getWillerStopSet: (queryParams) => dispatch(getWillerStopSet(queryParams)),
    validateStopSet: (stopSetId, file) => dispatch(validateStopSet(stopSetId, file)),
  };
};

export default withPermissionData(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
