import React, {Component} from 'react';

import {Map, GoogleApiWrapper, Polyline} from 'google-maps-react';
import {connect} from 'react-redux';

import {setMessageModal} from '../../stores/modal/actions';

/**
 * Map with polyline
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      centerPoint: {
        lat: 36.2048,
        lng: 138.2529,
      },
      pickUpPoint: {
        lat: '',
        lng: '',
      },
      dropOffPoint: {
        lat: '',
        lng: '',
      },
      nextPointPosition: {
        lat: '',
        lng: '',
      },
      polyline: '',
      driverMarkers: [],
      polylineDriver: '',
      nodes: [],
    };
  }

  /**
   * findNearestPoint
   * @param {Array} dataPoint
   * @param {Array} decodedCoordinates
   * @param {Number} distance
   * @return {Array}
   */
  findNearestPoint = (dataPoint, decodedCoordinates, distance) => {
    let pickUpPoint = dataPoint[0];
    let dropOffPoint = dataPoint[1];
    for (const [index, coordinate] of decodedCoordinates.entries()) {
      if (pickUpPoint === '' && Math.abs(coordinate.lat - this.state.pickUpPoint.lat) <= distance && Math.abs(coordinate.lng - this.state.pickUpPoint.lng) <= distance) {
        pickUpPoint = index;
      } else if (dropOffPoint === '' && Math.abs(coordinate.lat - this.state.dropOffPoint.lat) <= distance && Math.abs(coordinate.lng - this.state.dropOffPoint.lng) <= distance) {
        dropOffPoint = index;
      }
      if (pickUpPoint !== '' && dropOffPoint !== '') break;
    }
    return [pickUpPoint, dropOffPoint];
  };

  /**
   * findPolylineForBooking
   * @param {Array} decodedCoordinates
   * @return {Array}
   */
  findPolylineForBooking = (decodedCoordinates) => {
    let distance = 0.0001;
    let dataPoint = ['', ''];
    dataPoint = this.findNearestPoint(dataPoint, decodedCoordinates, distance);
    while (dataPoint.includes('')) {
      distance += 0.00005;
      dataPoint = this.findNearestPoint(dataPoint, decodedCoordinates, distance);
    }
    const startPoint = dataPoint[0] <= dataPoint[1] ? dataPoint[0] : dataPoint[1];
    const endPoint = dataPoint[0] <= dataPoint[1] ? dataPoint[1] : dataPoint[0];
    return decodedCoordinates.slice(startPoint, endPoint + 1);
  };

  /**
   * findCenter
   * @param {Array} polylineForBooking
   * @return {Object}
   */
  findCenter = (polylineForBooking) => {
    let biggestLat = polylineForBooking[0].lat;
    let smallestLat = polylineForBooking[0].lat;
    let biggestLng = polylineForBooking[0].lng;
    let smallestLng = polylineForBooking[0].lng;
    for (const point of polylineForBooking) {
      point.lat > biggestLat && (biggestLat = point.lat);
      point.lat < smallestLat && (smallestLat = point.lat);
      point.lng > biggestLng && (biggestLng = point.lng);
      point.lng < smallestLng && (smallestLng = point.lng);
    }
    return {
      lat: (biggestLat + smallestLat) / 2,
      lng: (biggestLng + smallestLng) / 2,
    };
  };

  /**
   * componentDidMount
   */
  async componentDidMount() {
    const {dataPolyline} = this.props;

    dataPolyline.pickUpPoint &&
      (await this.setState({
        pickUpPoint: {
          lat: dataPolyline.pickUpPoint.lat,
          lng: dataPolyline.pickUpPoint.lng,
        },
      }));
    dataPolyline.dropOffPoint &&
      (await this.setState({
        dropOffPoint: {
          lat: dataPolyline.dropOffPoint.lat,
          lng: dataPolyline.dropOffPoint.lng,
        },
      }));
    dataPolyline.nextPointPosition &&
      (await this.setState({
        nextPointPosition: {
          lat: dataPolyline.nextPointPosition.lat,
          lng: dataPolyline.nextPointPosition.lng,
        },
      }));
    if (this.props.dataPolyline?.type === 'tracking') {
      this.renderMapTracking(dataPolyline.polyline, dataPolyline);
    } else {
      this.renderMap(dataPolyline.polyline, dataPolyline.edges);
    }
  }

  /**
   * componentWillReceiveProps
   * @param {Props} nextProps
   */
  UNSAFE_componentWillReceiveProps = async (nextProps) => {
    if (nextProps.dataPolyline?.type === 'tracking') {
      nextProps.dataPolyline.nextPointPosition &&
        (await this.setState({
          nextPointPosition: {
            lat: nextProps.dataPolyline.nextPointPosition.lat,
            lng: nextProps.dataPolyline.nextPointPosition.lng,
          },
        }));
      this.renderMapTracking(nextProps.dataPolyline.polyline, nextProps.dataPolyline);
    } else {
      this.renderMap(nextProps.dataPolyline.polyline, nextProps.dataPolyline.edges);
    }
  };

  /**
   * renderMap
   * @param {String} polyline
   * @param {Array} edges
   */
  renderMap = (polyline, edges) => {
    if (polyline === '') return;
    const {google} = this.props;

    // Process polyline data
    const decodedPath = this.props.google.maps.geometry.encoding.decodePath(polyline);
    const decodedPathDriver = edges?.length > 0 ? this.props.google.maps.geometry.encoding.decodePath(edges[0]?.polyline5) : [];
    const decodedCoordinates = decodedPath.map(
      (item) =>
        (item = {
          lat: item.lat(),
          lng: item.lng(),
        }),
    );
    const polylineDriver = decodedPathDriver.map(
      (item) =>
        (item = {
          lat: item.lat(),
          lng: item.lng(),
        }),
    );
    const polylineForBooking = this.props.dataPolyline.type === 'booking' ? this.findPolylineForBooking(decodedCoordinates) : decodedCoordinates;
    const centerPoint = this.findCenter(polylineForBooking);

    // Print elements to map
    if (this.mapRef) {
      this.mapRef.map.setCenter(centerPoint);
      // Set pickup and dropoff point to map
      this.state.dropOffPoint &&
        this.state.dropOffPoint.lat &&
        new google.maps.Marker({
          position: this.state.dropOffPoint,
          icon: {
            url: `${process.env.PUBLIC_URL}/images/ic_pick_destination.svg`,
            scale: 10,
            strokeWeight: 2,
            fillOpacity: 1,
          },
          map: this.mapRef.map,
        });
      this.state.pickUpPoint &&
        this.state.pickUpPoint.lat &&
        new google.maps.Marker({
          position: this.state.pickUpPoint,
          icon: {
            url: `${process.env.PUBLIC_URL}/images/ic_pick_origin.svg`,
            scale: 10,
            strokeWeight: 2,
            fillOpacity: 1,
          },
          map: this.mapRef.map,
        });
      // Set driver to map
      if (polylineDriver.length > 0) {
        const {driverMarkers} = this.state;
        for (const driverMarker of driverMarkers) {
          driverMarker.setMap(null);
        }
        const newDriverMarker = new google.maps.Marker({
          position: polylineDriver[0],
          icon: {
            url: `${process.env.PUBLIC_URL}/images/reservation/car-l.svg`,
            scaledSize: new google.maps.Size(50, 50),
            strokeWeight: 2,
            fillOpacity: 1,
          },
          map: this.mapRef.map,
        });
        driverMarkers.push(newDriverMarker);
        this.setState({driverMarkers});
      }
    }
    // Set polyline to map
    this.setState({
      centerPoint,
      polyline: <Polyline path={polylineForBooking} strokeColor="#c8c8c8" strokeOpacity={1} strokeWeight={4} />,
      polylineDriver: <Polyline path={polylineDriver} strokeColor="#18C090" strokeOpacity={1} strokeWeight={4} />,
    });
  };

  /**
   * renderMapTracking
   * @param {String} polyline
   * @param {Object} dataPolyline
   */
  renderMapTracking = (polyline, dataPolyline) => {
    if (polyline === '') return;
    const {google} = this.props;
    const infoWindow = new google.maps.InfoWindow();

    // Process polyline data
    const decodedPath = this.props.google.maps.geometry.encoding.decodePath(polyline);
    const decodedCoordinates = decodedPath.map(
      (item) =>
        (item = {
          lat: item.lat(),
          lng: item.lng(),
        }),
    );
    const driverCoordinates = [
      {
        lat: dataPolyline.driverPosition.lat,
        lng: dataPolyline.driverPosition.lng,
      },
    ];
    const distanceList = decodedCoordinates.map((coordinate) => this.getDistance(coordinate, this.state.nextPointPosition));
    const indexPickUp = distanceList.findIndex((item) => item === Math.min(...distanceList));
    for (let i = 0; i <= indexPickUp; i++) {
      driverCoordinates.push({
        lat: decodedCoordinates[i].lat,
        lng: decodedCoordinates[i].lng,
      });
    }

    const polylineForDriver = driverCoordinates;
    const polylineForBooking = decodedCoordinates.filter((item, index) => index > indexPickUp);
    const centerPoint = this.findCenter(polylineForBooking);

    // Print elements to map
    if (this.mapRef) {
      this.mapRef.map.setCenter(centerPoint);
      // Set pickup and dropoff point to map
      if (dataPolyline.nodes?.length > 0) {
        const nodes = [];
        const boxList = [];
        const reservationCodes = dataPolyline.reservationCodes;
        this.deleteMarkers();
        dataPolyline.nodes.forEach((node, index) => {
          const marker = new google.maps.Marker({
            position: {lat: node.lat, lng: node.lon},
            icon: {
              url:
                node.booking_uid === dataPolyline.booking_id.toString() ?
                  node.node_type === 'pickup' ?
                    `${process.env.PUBLIC_URL}/images/reservation/user-pickup.svg` :
                    `${process.env.PUBLIC_URL}/images/reservation/user-dropoff.svg` :
                  node.node_type === 'pickup' ?
                  `${process.env.PUBLIC_URL}/images/reservation/user-pickup-point.svg` :
                  `${process.env.PUBLIC_URL}/images/reservation/user-dropoff-point.svg`,
              scale: 10,
              strokeWeight: 2,
              fillOpacity: 1,
            },
            map: this.mapRef.map,
          });
          // create textbox for infoWindow
          const boxText = document.createElement('img');
          const reservation_code = reservationCodes.find((item) => item.booking_id.toString() === node?.booking_uid)?.reservation_code;
          boxText.setAttribute('src', `${process.env.PUBLIC_URL}/images/copy_icon.svg`);
          boxText.setAttribute('alt', 'copy-item');
          boxText.setAttribute('style', 'width: 20px; cursor: pointer;');
          boxText.id = 'img_' + index;
          boxText.className = 'copyIcon';
          boxList.push(boxText);
          marker.addListener('mouseover', () => {
            infoWindow.setContent(
              `<div style='display: flex; align-items: flex-end;'><div style='font-weight: bold; font-size: 14px; padding-right: 2px;'>
              ${reservation_code} </div>${boxList[index].outerHTML}</div>`,
            );
            infoWindow.open(marker.getMap(), marker);
          });

          google.maps.event.addListener(infoWindow, 'domready', () => {
            const imgId = document.getElementById('img_' + index);
            if (imgId) {
              imgId.onclick = function() {
                navigator.clipboard.writeText(reservation_code);
                infoWindow.close();
              };
            }
          });
          nodes.push(marker);
        });
        this.setState({nodes});
      }
      // Set driver to map
      if (dataPolyline.driverPosition && dataPolyline.driverPosition.lat) {
        const {driverMarkers} = this.state;
        for (const driverMarker of driverMarkers) {
          driverMarker.setMap(null);
        }
        const newDriverMarker = new google.maps.Marker({
          position: dataPolyline.driverPosition,
          icon: {
            url: `${process.env.PUBLIC_URL}/images/reservation/car-l.svg`,
            scaledSize: new google.maps.Size(50, 50),
            strokeWeight: 2,
            fillOpacity: 1,
          },
          map: this.mapRef.map,
        });
        driverMarkers.push(newDriverMarker);
        this.setState({driverMarkers});
      }
    }
    // Set polyline to map
    this.setState({
      centerPoint,
      polyline: <Polyline path={polylineForBooking} strokeColor="#c8c8c8" strokeOpacity={1} strokeWeight={4} />,
      polylineDriver: <Polyline path={polylineForDriver} strokeColor="#18C090" strokeOpacity={1} strokeWeight={4} />,
    });
  };

  /**
   * deleteMarkers
   */
  deleteMarkers = () => {
    const nodes = this.state.nodes;
    for (let i = 0; i < nodes.length; i++) {
      nodes[i].setMap(null);
    }
    this.setState({nodes});
  };

  /**
   * rad
   * @param {number} x
   * @return {number}
   */
  rad = (x) => {
    // caculate radius
    return (x * Math.PI) / 180;
  };

  /**
   * getDistance
   * @param {number} p1
   * @param {number} p2
   * @return {number}
   */
  getDistance = (p1, p2) => {
    // caculate distance between two points
    const R = 6378137; // Earth’s mean radius in meter
    const dLat = this.rad(p2.lat - p1.lat);
    const dLong = this.rad(p2.lng - p1.lng);
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.rad(p1.lat)) * Math.cos(this.rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d; // returns the distance in meter
  };

  /**
   * render
   * @return {HTMLElement}
   */
  render() {
    return (
      <div>
        <Map
          {...this.props}
          ref={(ref) => (this.mapRef = ref)}
          initialCenter={this.state.centerPoint}
          zoom={this.props.dataPolyline.type === 'tracking' ? 14 : 15}
          centerAroundCurrentLocation={false}
          containerStyle={{
            height: this.props.dataPolyline.type === 'tracking' ? '500px' : '500px',
            position: 'relative',
            width: this.props.dataPolyline.type === 'booking' && '27.8vw',
          }}
        >
          {this.state.polyline}
          {this.state.polylineDriver}
        </Map>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMessageModal: () => dispatch(setMessageModal),
  };
};

// eslint-disable-next-line new-cap
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  // eslint-disable-next-line new-cap
  GoogleApiWrapper((props) => ({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || 'AIzaSyACyapw83diO1bi_xiXbZRLLoano6eTwd0',
    language: localStorage.getItem('i18nextLng') === 'ja' ? 'ja' : 'en',
    libraries: ['geometry'],
  }))(Index),
);
