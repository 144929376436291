import React, {Component} from 'react';

import {Box, FormControl, Grid, MenuItem, Select} from '@material-ui/core';
import {Pagination} from '@material-ui/lab';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';

import {ROWS_PER_PAGE_OPTIONS} from '../../common/constant';
import './styles.css';
/**
 * CustomPagination component
 */
class CustomPagination extends Component {
  /**
   *
   * @param {CustomPagination.propsTypes} props
   */
  constructor(props) {
    super(props);
    const {currentPage, rowsPerPage} = props;
    this.state = {
      rowsPerPage: rowsPerPage ? rowsPerPage : ROWS_PER_PAGE_OPTIONS[0],
      currentPage: currentPage != null ? currentPage : 0,
    };
  }

  /**
   *
   * @param {object} prevProps
   * @param {object} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.props.currentPage !== prevProps.currentPage) {
      this.setState({currentPage: this.props.currentPage});
    }
    if (this.props.rowsPerPage !== prevProps.rowsPerPage) {
      this.setState({rowsPerPage: this.props.rowsPerPage});
    }
  }

  /**
   * Handle on change rows per page
   * @param {InputEvent} event
   */
  onChangeRowsPerPage = (event) => {
    this.setState({rowsPerPage: event.target.value, currentPage: 0}, this.handleChangePageOrRowsPerPage);
  };

  /**
   * Handle when the page is changed.
   *
   * @param {object} event The event source of the callback.
   * @param {number} page The page selected.
   */
  onChangePage = (event, page) => {
    if (this.state.currentPage !== page - 1) {
      this.setState({currentPage: page - 1}, this.handleChangePageOrRowsPerPage);
    }
  };

  /**
   * Handle change page or rows per page
   */
  handleChangePageOrRowsPerPage = () => {
    const {rowsPerPage, currentPage} = this.state;
    // Backend: pagination starts at 0
    this.props.onChange(currentPage, rowsPerPage);
  };

  /**
   * @return {HTMLElement}
   */
  render() {
    const {t, rows, rowPerPageOptions} = this.props;
    const {rowsPerPage} = this.state;
    const currentPage = this.state.currentPage + 1;
    const numberOfPages = Math.floor((rows + rowsPerPage - 1) / rowsPerPage);
    const start = rowsPerPage * (currentPage - 1) + 1;
    const end = rowsPerPage * currentPage > rows ? rows : rowsPerPage * currentPage;
    return (
      <Box className="CustomPagination" my={2}>
        {rows > 0 && (
          <Grid container justifyContent="space-between">
            <Box display="flex" alignItems="center" justifyContent="center">
              {!this.props.isDisabledRowPerPage && (
                <>
                  <Box mr={2}>
                    <span>{t('customPagination.rowsPerPage')}</span>
                  </Box>
                  <FormControl className="rows-per-page-select" variant="outlined">
                    <Select displayEmpty value={rowsPerPage} onChange={this.onChangeRowsPerPage}>
                      {rowPerPageOptions.map((option, i) => (
                        <MenuItem key={i} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center">
              <span className="start-end-rows">{t('customPagination.startEndRows', {start, end, rows})}</span>
              <Pagination count={numberOfPages} page={currentPage} boundaryCount={1} onChange={this.onChangePage} color="primary" />
            </Box>
          </Grid>
        )}
      </Box>
    );
  }
}

CustomPagination.propsTypes = {
  // Number of rows
  rows: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowPerPageOptions: PropTypes.array,

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChange: function(currentPage, rowsPerPage) {},
};

CustomPagination.defaultProps = {
  rowPerPageOptions: ROWS_PER_PAGE_OPTIONS,
  currentPage: 1,
};
export default withTranslation('translations')(CustomPagination);
