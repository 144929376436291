import React, {Component} from 'react';

import {
  Card,
  Container,
  Grid,
  Button,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withTranslation} from 'react-i18next';

import {redirectRouter} from '../../../../utils/common';

/**
 * Reservation management view
 */
class Index extends Component {
  /**
   * render component
   * @return {component}
   */
  render() {
    const {t} = this.props;

    return (
      <div>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('route.reservationManagement')}</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}>
                <Button
                  color="primary"
                  variant="contained"
                  className="button_margin button_color"
                  endIcon={<ArrowBackIcon />}
                  onClick={this.props.history.goBack}
                >
                  {t('common.btnReturn')}
                </Button>
              </Grid>
            </Grid>
          </Container>
          <br></br>

          <Container maxWidth="xl">
            <Card raised>
              <Grid container className="page_header">
                <Grid container alignItems="center" item xs={4}/>
                <Grid container alignItems="center" item xs={8}>
                  <Typography gutterBottom variant="h4" component="h4" className="text_center">
                    300 WILLER 株式会社
                  </Typography>
                </Grid>
                <Grid container alignItems="center" item xs={12}>
                  <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                    <TableBody>
                      <TableRow className="cursor_pointer"
                        onClick={() => this.toCard()}
                      >
                        <TableCell>
                          <Typography variant="h6" component="h6" className="text_center">
                            {t('newReservation.number')} : 11091992
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h6" component="h6" className="text_center">
                            {t('newReservation.totalFee')}: 3900円
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h6" component="h6" className="text_center">
                            {t('newReservation.orderer')}: イ・ミンホ
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h6" component="h6" className="text_center">
                            {t('newReservation.dateTime')}: 2019年2月17日 11:20
                          </Typography>
                        </TableCell>
                      </TableRow>
                      <TableRow className="cursor_pointer"
                        onClick={() => this.toCard()}
                      >
                        <TableCell>
                          <Typography variant="h6" component="h6" className="text_center">
                            {t('newReservation.number')} : 09112981
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h6" component="h6" className="text_center">
                            {t('newReservation.totalFee')}: 8900円
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h6" component="h6" className="text_center">
                            {t('newReservation.orderer')}: ホーチミン
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h6" component="h6" className="text_center">
                            {t('newReservation.dateTime')}: 2018年4月12日 07:20
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
              <br />
            </Card>
            <br />
          </Container>
        </Card>
      </div>
    );
  }

  toCard = () => {
    const path = '/maas/reservation/payment/card';
    redirectRouter(this.props, path);
  };
}

export default withTranslation('translations')(Index);
