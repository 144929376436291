import React, {Component} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Card,
  Container,
  Dialog,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import {ArrowBack as ArrowBackIcon, Description as DetailsIcon, GetApp as GetAppIcon, RotateLeft as ResetIcon, Search as SearchIcon} from '@material-ui/icons';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {format} from 'date-fns';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

import {DATA_NULL, EXPORT_ALERT_NUMBER_ROWS, MAX_SIZE_OUTPUT, ROUTE, ROWS_PER_PAGE_OPTIONS, TICKET_TYPE, PERMISSION_ACTIONS} from '../../../common/constant';
import EXPORT_LIMIT_NUMBER_ROWS from '../../../common/constant';
import CSVExporter from '../../../components/CSVExporter';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getAllCountryCode} from '../../../stores/common/actions';
import {getAgencyList, getTickets} from '../../../stores/member/action';
import {setMessageModal} from '../../../stores/modal/actions';
import {exportQRTickets, searchQRTickets} from '../../../stores/qr_ticket/action';
import {changeUrlParams, customDisplayCurrency, getQueryStringFromObject, getUrlParams, onChangeSelect, onChangeTextField} from '../../../utils/common';
import {convertDateUTCAndGeofence, formatUTCToHourMinute, getTimeZoneByCountryCode} from '../../../utils/date_time_utils';
import {convertDateTimeToDate} from '../../../utils/datetime';
import {modalObj} from '../../../utils/modal';

/** QR Ticket Component */
class Index extends Component {
  /**
   * Constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      // Search params
      country_id: '',
      business_id: '',
      member_code: '',
      user_name: '',
      mobile: '',
      email: '',
      ticket_type: '',
      commuter_pass_types: [],
      coupon_ticket_types: [],
      user_ticket_id: '',
      amount: '',
      rides_limit: '',
      used_count: '',
      payment_methods: [],
      payment_code: '',
      register_date_from: null,
      register_date_to: null,
      start_date_from: null,
      start_date_to: null,
      end_date_from: null,
      end_date_to: null,
      status: 'Enable',
      // To validate search params
      searchValidating: false,
      // Search result
      searchParams: this.props.location.search,
      latestSearchParams: null,
      // UI
      isLoading: false,
      isSearching: false,
      // CSV export
      flagExport: false,
      confirmedExport: false,
      message: '',
      // Pagination
      totalRows: 3,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
    };
    this.validator = new SimpleReactValidator();
    this.exportCSVRef = React.createRef();
    this.exportHeaders = [];
  }

  /**
   * handleSearchQrTicket
   */
  handleSearchQrTicket = async () => {
    this.setState({
      isLoading: true,
      searchValidating: true,
      isSearching: true,
    });
    if (this.validator.allValid()) {
      const {
        country_id,
        business_id,
        member_code,
        user_name,
        mobile,
        email,
        ticket_type,
        commuter_pass_types,
        coupon_ticket_types,
        user_ticket_id,
        amount,
        rides_limit,
        used_count,
        payment_methods,
        payment_code,
        register_date_from,
        register_date_to,
        start_date_from,
        start_date_to,
        end_date_from,
        end_date_to,
        status,
        currentPage,
        rowsPerPage,
      } = this.state;
      const payload = {
        country_id: country_id,
        business_id: business_id ? business_id : null,
        member_code: member_code ? member_code.trim() : null,
        username: user_name ? user_name.trim() : null,
        mobile: mobile ? mobile.trim() : null,
        email: email ? email.trim() : null,
        ticket_type: ticket_type ? ticket_type : null,
        train_ticket_ids: commuter_pass_types.length > 0 ? commuter_pass_types : coupon_ticket_types.length > 0 ? coupon_ticket_types : [],
        ticket_id: user_ticket_id ? user_ticket_id.trim() : null,
        amount: amount ? Number(amount) : null,
        rides_limit: rides_limit ? rides_limit.trim() : null,
        used_count: used_count ? used_count.trim() : null,
        payment_methods,
        payment_code: payment_code ? payment_code.trim() : null,
        registration_date_from: register_date_from ? format(new Date(register_date_from), 'yyyy-MM-dd') : null,
        registration_date_to: register_date_to ? format(new Date(register_date_to), 'yyyy-MM-dd') : null,
        start_date_from: start_date_from ? format(new Date(start_date_from), 'yyyy-MM-dd') : null,
        start_date_to: start_date_to ? format(new Date(start_date_to), 'yyyy-MM-dd') : null,
        end_date_from: end_date_from ? format(new Date(end_date_from), 'yyyy-MM-dd') : null,
        end_date_to: end_date_to ? format(new Date(end_date_to), 'yyyy-MM-dd') : null,
        status: status === 'Enable' ? true : false,
        zone_id: 'Asia/Tokyo',
      };
      const queryParams = {
        page: currentPage,
        size: rowsPerPage,
      };
      await this.props.searchQRTickets(payload, queryParams);
      const queryParamsToChange = {
        country_id,
        business_id: business_id ? business_id : null,
        member_code,
        user_name,
        email,
        mobile,
        ticket_type,
        commuter_pass_types,
        coupon_ticket_types,
        user_ticket_id,
        amount,
        rides_limit,
        used_count,
        payment_methods,
        payment_code,
        registration_date_from: register_date_from ? format(new Date(register_date_from), 'yyyy-MM-dd') : null,
        registration_date_to: register_date_to ? format(new Date(register_date_to), 'yyyy-MM-dd') : null,
        start_date_from: start_date_from ? format(new Date(start_date_from), 'yyyy-MM-dd') : null,
        start_date_to: start_date_to ? format(new Date(start_date_to), 'yyyy-MM-dd') : null,
        end_date_from: end_date_from ? format(new Date(end_date_from), 'yyyy-MM-dd') : null,
        end_date_to: end_date_to ? format(new Date(end_date_to), 'yyyy-MM-dd') : null,
        status,
      };
      changeUrlParams(queryParamsToChange);
      const searchParams = getQueryStringFromObject(queryParamsToChange);
      this.setState({
        searchParams,
        latestSearchParams: payload,
      });
    }
  };

  /**
   * handleChangeTicketType
   * @param {*} e
   */
  handleChangeTicketType = (e) => {
    this.setState(
      {
        commuter_pass_types: [],
        coupon_ticket_types: [],
      },
      () => onChangeSelect(this, e),
    );
  };

  /**
   * resetSearchCondition
   */
  resetSearchCondition = () => {
    this.setState({
      business_id: '',
      member_code: '',
      user_name: '',
      mobile: '',
      email: '',
      ticket_type: '',
      commuter_pass_types: [],
      coupon_ticket_types: [],
      user_ticket_id: '',
      amount: '',
      rides_limit: '',
      used_count: '',
      payment_methods: [],
      payment_code: '',
      register_date_from: null,
      register_date_to: null,
      start_date_from: null,
      start_date_to: null,
      end_date_from: null,
      end_date_to: null,
      status: 'Enable',
    });
  };

  /**
   * handle change current page & rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  handleChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearchQrTicket);
  };

  /**
   * getCountryCodeById
   * @param {*} countryId
   * @return {number}
   */
  getCountryCodeById = (countryId) => {
    const {common} = this.props;
    const country = common?.country_code?.find((country) => country.id === countryId);
    return country.country_code;
  };

  /**
   * onChangeCountry
   * @param {object} event
   */
  onChangeCountry = async (event) => {
    this.setState({country_id: event.target.value});
  };

  /**
   * fetchDataExport
   */
  fetchDataExport = async () => {
    const {t} = this.props;
    const queryParams = {page: 0, size: MAX_SIZE_OUTPUT};
    const dataExport = await this.props.exportQRTickets(this.state.latestSearchParams, queryParams);
    const convertedData = dataExport.map((row) => ({
      user_ticket_id: row.code,
      member_code: row.user_info?.member_id,
      full_name: `${row.user_info?.last_name} ${row.user_info?.first_name}`,
      country: row.country_id ? t(this.getCountryCodeById(row.country_id)) : DATA_NULL,
      business_name: row.business_name || DATA_NULL,
      ticket_type: row.ticket?.ticket_type === TICKET_TYPE[0].id ? t(TICKET_TYPE[0].i18n) : (row.ticket?.ticket_type === TICKET_TYPE[1].id ? t(TICKET_TYPE[1].i18n) : DATA_NULL),
      commuter_pass_types: row.ticket?.ticket_type === TICKET_TYPE[0].id ? row.ticket?.name : DATA_NULL,
      coupon_ticket_types: row.ticket?.ticket_type === TICKET_TYPE[1].id ? row.ticket?.name : DATA_NULL,
      payment_method: row.payment_method ? t('paymentMethod.CREDIT_CARD') : DATA_NULL,
      payment_code: row.payment_code || DATA_NULL,
      currency: row.currency_code || DATA_NULL,
      amount: customDisplayCurrency(row.amount, row.currency_code) || DATA_NULL,
      rides_limit: row.ticket?.rides_limit || DATA_NULL,
      used_rides: row.used_rides,
      remaining_rides: row.ticket?.rides_limit - row.used_rides || DATA_NULL,
      departure_station: row.departure_station?.name || DATA_NULL,
      arrival_station: row.arrival_station?.name || DATA_NULL,
      start_date: row.start_date_time && (
        convertDateTimeToDate(convertDateUTCAndGeofence(row.start_date_time, getTimeZoneByCountryCode(row.country_id), true)) +
        ' ' +
        formatUTCToHourMinute(row.start_date_time, getTimeZoneByCountryCode(row.country_id)) || DATA_NULL
      ),
      end_date: row.end_date_time && (
        convertDateTimeToDate(convertDateUTCAndGeofence(row.end_date_time, getTimeZoneByCountryCode(row.country_id), true)) +
        ' ' +
        formatUTCToHourMinute(row.end_date_time, getTimeZoneByCountryCode(row.country_id)) || DATA_NULL
      ),
      registration_date: row.registration_date && (
        convertDateTimeToDate(convertDateUTCAndGeofence(row.registration_date, getTimeZoneByCountryCode(row.country_id), true)) +
        ' ' +
        formatUTCToHourMinute(row.registration_date, getTimeZoneByCountryCode(row.country_id)) || DATA_NULL
      ),
      status: row.is_active ? t('common.enable') : t('common.disable'),
    }));
    this.exportHeaders = [
      {label: t('qrTicket.userTicketId'), key: 'user_ticket_id'},
      {label: t('qrTicket.memberCode'), key: 'member_code'},
      {label: t('qrTicket.fullName'), key: 'full_name'},
      {label: t('qrTicket.country'), key: 'country'},
      {label: t('qrTicket.businessName'), key: 'business_name'},
      {label: t('qrTicket.ticketType'), key: 'ticket_type'},
      {label: t('qrTicket.commuterPassType'), key: 'commuter_pass_types'},
      {label: t('qrTicket.couponTicketType'), key: 'coupon_ticket_types'},
      {label: t('qrTicket.paymentMethod'), key: 'payment_method'},
      {label: t('qrTicket.paymentId'), key: 'payment_code'},
      {label: t('qrTicket.currency'), key: 'currency'},
      {label: t('qrTicket.amount'), key: 'amount'},
      {label: t('qrTicket.rideLimit'), key: 'rides_limit'},
      {label: t('qrTicket.usedCount'), key: 'used_rides'},
      {label: t('qrTicket.remainCount'), key: 'remaining_rides'},
      {label: t('qrTicket.departureStation'), key: 'departure_station'},
      {label: t('qrTicket.arrivalStation'), key: 'arrival_station'},
      {label: t('qrTicket.startDate'), key: 'start_date'},
      {label: t('qrTicket.endDate'), key: 'end_date'},
      {label: t('qrTicket.registrationDate'), key: 'registration_date'},
      {label: t('qrTicket.status'), key: 'status'},
    ];
    this.setState({confirmedExport: false});
    return convertedData;
  }

  /**
   * alertExportCSV
   */
  alertExportCSV = () => {
    const {t, qrTicket} = this.props;
    if (qrTicket?.totalRows > EXPORT_LIMIT_NUMBER_ROWS) {
      this.props.setMessageModal(modalObj(true, t('messageCode.exportAlertLimit')));
    } else {
      const message = t('messageCode.exportAlert');
      this.setState({flagExport: true, message});
    }
  }

  /**
   * handleClickExportOK
   */
  handleClickExportOK = () => {
    this.setState({flagExport: false, confirmedExport: true}, () => {
      this.exportCSVRef.current.onClickExport();
    });
  }

  /**
   * handleClickExportCancel
   */
  handleClickExportCancel = () => {
    this.setState({flagExport: false});
  }

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    await this.props.getAllCountryCode().then((response) => {
      if (response) {
        this.setState({country_id: this.state.country_id || response.find((country) => country.country_code === 'JP')?.id});
      }
    });
    const countryCode = this.props.common?.country_code?.filter((item) => item.id === this.state.country_id)[0]?.country_code;
    await this.props.getAgencyList({country_code: countryCode ? countryCode?.toLowerCase() : 'jp'}).then((res) => {
      if (res) {
        const defaultSupplier = res.filter((item) => item.agency_id === 'WLTR');
        this.setState({business_id: defaultSupplier?.length > 0 ? defaultSupplier[0]?.agency_id : null});
      }
    });
    this.props.getTickets();
    if (this.props.location.search) {
      this.setState(getUrlParams(this.props.location.search), this.handleSearchQrTicket);
    }
  };

  /**
   * Render component
   * @return {Component}
   */
  render() {
    const {t, common, agencyList, qrTicket, actions} = this.props;
    const {qrTicketsList, totalRows, isLoading} = qrTicket;
    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canCSVOutput: actions.includes(PERMISSION_ACTIONS.CSV_OUTPUT),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
    };

    return (
      <Container maxWidth="xl">
        <Grid container className="page_header">
          <Grid container item xs={6} alignItems="center">
            <h3>{t('qrTicket.title')}</h3>
          </Grid>
          <Grid container item xs={6} alignItems="center" justify="flex-end">
            <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
              <Button variant="contained" color="primary" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                {t('common.btnReturn')}
              </Button>
            </Link>
          </Grid>
        </Grid>
        <br />
        {/* Search Condition */}
        <Card>
          <Container maxWidth="xl">
            <br />
            <Paper className="search_table">
              <Grid container className="row_form_item product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                {t('qrTicket.searchCondition')}
              </Grid>

              {/* Country */}
              <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                <Grid container item xs={6} lg={2} alignItems="center" className="grid_title_padding">
                  {t('qrTicket.country')}
                </Grid>
                <Grid container item xs={6} lg={4} alignItems="center">
                  <FormControl margin="dense" variant="outlined" className="field_size_10 field_min_size_300">
                    <Select
                      margin="dense"
                      inputProps={{
                        name: 'country_id',
                      }}
                      displayEmpty
                      disabled = {true}
                      renderValue={
                        this.state.country_id ?
                          undefined :
                          () => (
                              <div className="font-12 color-disabled">
                                {t('placeholder.required_select', {
                                  field: t('qrTicket.country'),
                                })}
                              </div>
                            )
                      }
                      value={this.state.country_id}
                      onChange={(e) => this.onChangeCountry(e)}
                    >
                      {common?.country_code?.map((country) => (
                        <MenuItem key={country.id} value={country.id}>
                          {t(country.country_code)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {/* Business name */}
              <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                <Grid container item xs={6} lg={2} alignItems="center" className="grid_title_padding">
                  {t('qrTicket.businessName')}
                </Grid>
                <Grid container item xs={6} lg={4} alignItems="center">
                  <FormControl margin="dense" variant="outlined" className="field_size_10 field_min_size_300">
                    <Select
                      margin="dense"
                      inputProps={{
                        name: 'business_id',
                      }}
                      displayEmpty
                      renderValue={
                        this.state.business_id ?
                          undefined :
                          () => (
                              <div className="font-12 color-disabled">
                                {t('placeholder.required_select', {
                                  field: t('qrTicket.businessName'),
                                })}
                              </div>
                            )
                      }
                      value={this.state.business_id}
                      onChange={(e) => onChangeSelect(this, e)}
                    >
                      {agencyList?.map((item, idx) => {
                        return (
                          <MenuItem value={item.agency_id} key={idx}>
                            {item.names?.ja}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {/* Member code */}
              <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                <Grid container item xs={6} lg={2} alignItems="center" className="grid_title_padding">
                  {t('qrTicket.memberCode')}
                </Grid>
                <Grid container item xs={6} lg={4} alignItems="center">
                  <TextField
                    className="field_size_10 field_min_size_300"
                    variant="outlined"
                    margin="dense"
                    placeholder={t('placeholder.required', {
                      field: t('qrTicket.memberCode'),
                    })}
                    name="member_code"
                    value={this.state.member_code}
                    onChange={(e) => onChangeTextField(this, e)}
                  />
                </Grid>
              </Grid>
              {/* User name */}
              <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                <Grid container item xs={6} lg={2} alignItems="center" className="grid_title_padding">
                  {t('qrTicket.fullName')}
                </Grid>
                <Grid container item xs={6} lg={4} alignItems="center">
                  <TextField
                    className="field_size_10 field_min_size_300"
                    variant="outlined"
                    margin="dense"
                    placeholder={t('placeholder.required', {
                      field: t('qrTicket.fullName'),
                    })}
                    name="user_name"
                    value={this.state.user_name}
                    onChange={(e) => onChangeTextField(this, e)}
                  />
                </Grid>
              </Grid>
              {/* Phone number */}
              <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                <Grid container item xs={6} lg={2} alignItems="center" className="grid_title_padding">
                  {t('qrTicket.phoneNumber')}
                </Grid>
                <Grid container item xs={6} lg={4} alignItems="center">
                  <TextField
                    className="field_size_10 field_min_size_300"
                    variant="outlined"
                    margin="dense"
                    placeholder={t('placeholder.required', {
                      field: t('qrTicket.phoneNumber'),
                    })}
                    name="mobile"
                    value={this.state.mobile}
                    onChange={(e) => onChangeTextField(this, e)}
                  />
                </Grid>
              </Grid>
              {/* Mail */}
              <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                <Grid container item xs={6} lg={2} alignItems="center" className="grid_title_padding">
                  {t('common.email')}
                </Grid>
                <Grid container item xs={6} lg={4} alignItems="center">
                  <TextField
                    className="field_size_10 field_min_size_300"
                    variant="outlined"
                    margin="dense"
                    placeholder={t('placeholder.required', {
                      field: t('common.email'),
                    })}
                    name="email"
                    value={this.state.email}
                    onChange={(e) => onChangeTextField(this, e)}
                  />
                </Grid>
              </Grid>
              {/* Ticket type */}
              <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                <Grid container item xs={6} lg={2} alignItems="center" className="grid_title_padding">
                  {t('qrTicket.ticketType')}
                </Grid>
                <Grid container item xs={6} lg={4} alignItems="center">
                  <FormControl margin="dense" variant="outlined" className="field_size_10 field_min_size_300">
                    <Select
                      margin="dense"
                      inputProps={{
                        name: 'ticket_type',
                      }}
                      displayEmpty
                      renderValue={
                        this.state.ticket_type ?
                          undefined :
                          () => (
                              <div className="font-12 color-disabled">
                                {t('placeholder.required_select', {
                                  field: t('qrTicket.ticketType'),
                                })}
                              </div>
                            )
                      }
                      value={this.state.ticket_type}
                      onChange={(e) => this.handleChangeTicketType(e)}
                    >
                      {TICKET_TYPE?.map((item, idx) => (
                        <MenuItem value={item.id} key={idx}>
                          {t(`${item.i18n}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {/* Coupon ticket type */}
              {this.state.ticket_type === 'COUPON_TICKET' && (
                <Grid container spacing={2} className="row_form_item table_background_color_aliceblue">
                  <Grid container item xs={6} lg={2} alignItems="center" className="grid_title_padding">
                    {t('qrTicket.couponTicketType')}
                  </Grid>
                  <Grid container item xs={6} lg={5} alignItems="center">
                    <FormControl margin="dense" variant="outlined" className="field_size_10 field_min_size_300">
                      <Select
                        margin="dense"
                        variant="outlined"
                        multiple
                        displayEmpty
                        renderValue={
                          this.state.coupon_ticket_types.length > 0 ?
                            undefined :
                            () => (
                                <div className="font-12 color-disabled">
                                  {t('placeholder.required_select', {
                                    field: t('qrTicket.couponTicketType'),
                                  })}
                                </div>
                              )
                        }
                        inputProps={{
                          name: 'coupon_ticket_types',
                        }}
                        value={this.state.coupon_ticket_types}
                        onChange={(e) => onChangeSelect(this, e)}
                      >
                        {this.props.tickets
                          ?.filter((ticket) => ticket.ticket_type === this.state.ticket_type)
                          ?.map((item, idx) => (
                            <MenuItem value={item.id} key={idx}>
                              {t(`${item.name}`)}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              {/* Commuter pass type */}
              {this.state.ticket_type === 'COMMUTER_PASS' && (
                <Grid container spacing={2} className="row_form_item table_background_color_aliceblue">
                  <Grid container item xs={6} lg={2} alignItems="center" className="grid_title_padding">
                    {t('qrTicket.commuterPassType')}
                  </Grid>
                  <Grid container item xs={6} lg={5} alignItems="center">
                    <FormControl margin="dense" variant="outlined" className="field_size_10 field_min_size_300">
                      <Select
                        margin="dense"
                        variant="outlined"
                        multiple
                        displayEmpty
                        renderValue={
                          this.state.commuter_pass_types.length > 0 ?
                            undefined :
                            () => (
                                <div className="font-12 color-disabled">
                                  {t('placeholder.required_select', {
                                    field: t('qrTicket.commuterPassType'),
                                  })}
                                </div>
                              )
                        }
                        inputProps={{
                          name: 'commuter_pass_types',
                        }}
                        value={this.state.commuter_pass_types}
                        onChange={(e) => onChangeSelect(this, e)}
                      >
                        {this.props.tickets
                          ?.filter((ticket) => ticket.ticket_type === this.state.ticket_type)
                          ?.map((item, idx) => (
                            <MenuItem value={item.id} key={idx}>
                              {t(`${item.name}`)}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              {/* User Ticket ID */}
              <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                <Grid container item xs={6} lg={2} alignItems="center" className="grid_title_padding">
                  {t('qrTicket.userTicketId')}
                </Grid>
                <Grid container item xs={6} lg={4} alignItems="center">
                  <TextField
                    className="field_size_10 field_min_size_300"
                    variant="outlined"
                    margin="dense"
                    placeholder={t('placeholder.required', {
                      field: t('qrTicket.userTicketId'),
                    })}
                    name="user_ticket_id"
                    value={this.state.user_ticket_id}
                    onChange={(e) => onChangeTextField(this, e)}
                  />
                </Grid>
              </Grid>
              {/* Amount */}
              <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                <Grid container item xs={6} lg={2} alignItems="center" className="grid_title_padding">
                  {t('qrTicket.amount')}
                </Grid>
                <Grid container item xs={6} lg={4} alignItems="center">
                  <TextField
                    className="field_size_10 field_min_size_300"
                    variant="outlined"
                    margin="dense"
                    placeholder={t('placeholder.required', {
                      field: t('qrTicket.amount'),
                    })}
                    type="number"
                    name="amount"
                    value={this.state.amount}
                    onChange={(e) => onChangeTextField(this, e)}
                  />
                </Grid>
              </Grid>
              {/* Payment ID */}
              <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                <Grid container item xs={6} lg={2} alignItems="center" className="grid_title_padding">
                  {t('qrTicket.paymentId')}
                </Grid>
                <Grid container item xs={6} lg={4} alignItems="center">
                  <TextField
                    className="field_size_10 field_min_size_300"
                    variant="outlined"
                    margin="dense"
                    placeholder={t('placeholder.required', {
                      field: t('qrTicket.paymentId'),
                    })}
                    name="payment_code"
                    value={this.state.payment_code}
                    onChange={(e) => onChangeTextField(this, e)}
                  />
                </Grid>
              </Grid>
              {/* Register application date */}
              <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                <Grid container item xs={6} lg={2} alignItems="center" className="grid_title_padding">
                  {t('qrTicket.registrationDate')}
                </Grid>
                <Grid container item xs={6} lg={6} alignItems="center">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className="field_size_20 field_min_size_200"
                      margin="dense"
                      autoOk
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      placeholder={t('common.from')}
                      format="yyyy/MM/dd"
                      invalidDateMessage={t('errorFields.invalidDateMessage')}
                      value={this.state.register_date_from}
                      onChange={(time) =>
                        this.setState({
                          register_date_from: time,
                        })
                      }
                    />
                    <span style={{padding: '0 20px', fontSize: 30, fontWeight: 600, color: 'rgba(0, 0, 0, 0.7)'}}>~</span>
                    <KeyboardDatePicker
                      className="field_size_20 field_min_size_200"
                      margin="dense"
                      autoOk
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      placeholder={t('common.to')}
                      format="yyyy/MM/dd"
                      invalidDateMessage={t('errorFields.invalidDateMessage')}
                      value={this.state.register_date_to}
                      onChange={(time) =>
                        this.setState({
                          register_date_to: time,
                        })
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              {/* Start date */}
              <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                <Grid container item xs={6} lg={2} alignItems="center" className="grid_title_padding">
                  {t('qrTicket.startDate')}
                </Grid>
                <Grid container item xs={6} lg={6} alignItems="center">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className="field_size_20 field_min_size_200"
                      margin="dense"
                      autoOk
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      placeholder={t('common.from')}
                      format="yyyy/MM/dd"
                      invalidDateMessage={t('errorFields.invalidDateMessage')}
                      value={this.state.start_date_from}
                      onChange={(time) =>
                        this.setState({
                          start_date_from: time,
                        })
                      }
                    />
                    <span style={{padding: '0 20px', fontSize: 30, fontWeight: 600, color: 'rgba(0, 0, 0, 0.7)'}}>~</span>
                    <KeyboardDatePicker
                      className="field_size_20 field_min_size_200"
                      margin="dense"
                      autoOk
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      placeholder={t('common.to')}
                      format="yyyy/MM/dd"
                      invalidDateMessage={t('errorFields.invalidDateMessage')}
                      value={this.state.start_date_to}
                      onChange={(time) =>
                        this.setState({
                          start_date_to: time,
                        })
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              {/* End date */}
              <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                <Grid container item xs={6} lg={2} alignItems="center" className="grid_title_padding">
                  {t('qrTicket.endDate')}
                </Grid>
                <Grid container item xs={6} lg={6} alignItems="center">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className="field_size_20 field_min_size_200"
                      margin="dense"
                      autoOk
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      placeholder={t('common.from')}
                      format="yyyy/MM/dd"
                      invalidDateMessage={t('errorFields.invalidDateMessage')}
                      value={this.state.end_date_from}
                      onChange={(time) =>
                        this.setState({
                          end_date_from: time,
                        })
                      }
                    />
                    <span style={{padding: '0 20px', fontSize: 30, fontWeight: 600, color: 'rgba(0, 0, 0, 0.7)'}}>~</span>
                    <KeyboardDatePicker
                      className="field_size_20 field_min_size_200"
                      margin="dense"
                      autoOk
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      placeholder={t('common.to')}
                      format="yyyy/MM/dd"
                      invalidDateMessage={t('errorFields.invalidDateMessage')}
                      value={this.state.end_date_to}
                      onChange={(time) =>
                        this.setState({
                          end_date_to: time,
                        })
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              {/* Status */}
              <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                <Grid container item xs={6} lg={2} alignItems="center" className="grid_title_padding">
                  {t('qrTicket.status')}
                </Grid>
                <Grid container item xs={6} lg={4} alignItems="center">
                  <FormControl margin="dense" variant="outlined" className="field_size_10 field_min_size_300">
                    <Select
                      margin="dense"
                      inputProps={{
                        name: 'status',
                      }}
                      displayEmpty
                      renderValue={
                        this.state.status ?
                          undefined :
                          () => (
                              <div className="font-12 color-disabled">
                                {t('placeholder.required_select', {
                                  field: t('qrTicket.status'),
                                })}
                              </div>
                            )
                      }
                      value={this.state.status}
                      onChange={(e) => onChangeSelect(this, e)}
                    >
                      {['Enable', 'Disable'].map((status, index) => (
                        <MenuItem key={index} value={status}>
                          {t(`common.${status.toLowerCase()}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Paper>
            <br />
            {/* Action buttons */}
            <Grid container spacing={1}>
              <Grid container item xs={6} alignItems="center">
                <Button className="button_margin button_color" variant="contained" color="primary" onClick={this.resetSearchCondition} endIcon={<ResetIcon />}>
                  {t('common.btnResetSearch')}
                </Button>
              </Grid>
              <Grid container item xs={6} alignItems="center" justify="flex-end">
                {permission.canCSVOutput && (
                  <>
                    <div className={totalRows <= EXPORT_ALERT_NUMBER_ROWS || this.state.confirmedExport ? '' : 'hidden'}>
                      <CSVExporter
                        ref={this.exportCSVRef}
                        disabled={totalRows <= 0 || !this.state.latestSearchParams}
                        headers={this.exportHeaders}
                        fetchAction={this.fetchDataExport}
                        isFetchAsync={true}
                        screenName={t('qrTicket.title')}
                      />
                    </div>
                    {this.state.totalRows >= EXPORT_ALERT_NUMBER_ROWS && !this.state.confirmedExport && (
                      <Button
                        className="button_margin"
                        variant="contained"
                        color="primary"
                        endIcon={<GetAppIcon className="csv-exporter-icon" />}
                        onClick={this.alertExportCSV}
                        disabled={totalRows <= 0}
                      >
                        {t('common.btnUploadCsv')}
                      </Button>
                    )}
                  </>
                )}
                {permission.canSearch && (
                  <Button className="button_margin" variant="contained" color="primary" onClick={this.handleSearchQrTicket} endIcon={<SearchIcon />}>
                    {t('common.btnSearch')}
                  </Button>
                )}
              </Grid>
            </Grid>
            <br />
          </Container>
        </Card>
        <br />
        {/* Search result */}
        {this.state.isSearching && (
          <Card>
            <Container maxWidth="xl">
              <Grid container alignItems="center">
                <h3>
                  {t('common.searchResult')} {totalRows} {t('common.case')}
                </h3>
              </Grid>
              {qrTicketsList?.length > 0 && (
                <>
                  <LoadingOverlay active={isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                    <div className="scroll_area_500">
                      <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                        <TableHead>
                          <TableRow>
                            <TableCell className="width_150p ant-table-cell-fix-left">{t('qrTicket.userTicketId')}</TableCell>
                            <TableCell className="width_150p">{t('qrTicket.memberCode')}</TableCell>
                            <TableCell className="width_150p">{t('qrTicket.fullName')}</TableCell>
                            <TableCell className="width_150p">{t('qrTicket.country')}</TableCell>
                            <TableCell className="width_200p">{t('qrTicket.businessName')}</TableCell>
                            <TableCell className="width_150p">{t('qrTicket.ticketType')}</TableCell>
                            <TableCell className="width_200p">{t('qrTicket.commuterPassType')}</TableCell>
                            <TableCell className="width_200p">{t('qrTicket.couponTicketType')}</TableCell>
                            <TableCell className="width_200p">{t('qrTicket.paymentMethod')}</TableCell>
                            <TableCell className="width_150p">{t('qrTicket.paymentId')}</TableCell>
                            <TableCell className="width_150p">{t('qrTicket.currency')}</TableCell>
                            <TableCell className="width_100p">{t('qrTicket.amount')}</TableCell>
                            <TableCell className="width_100p">{t('qrTicket.rideLimit')}</TableCell>
                            <TableCell className="width_100p">{t('qrTicket.usedCount')}</TableCell>
                            <TableCell className="width_100p">{t('qrTicket.remainCount')}</TableCell>
                            <TableCell className="width_200p">{t('qrTicket.departureStation')}</TableCell>
                            <TableCell className="width_200p">{t('qrTicket.arrivalStation')}</TableCell>
                            <TableCell className="width_150p">{t('qrTicket.startDate')}</TableCell>
                            <TableCell className="width_150p">{t('qrTicket.endDate')}</TableCell>
                            <TableCell className="width_150p">{t('qrTicket.registrationDate')}</TableCell>
                            <TableCell className="width_150p">{t('qrTicket.status')}</TableCell>
                            {permission.canEdit && (
                              <TableCell className="width_100p" style={{position: 'sticky', right: 0}}>
                                {t('common.action')}
                              </TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {qrTicketsList &&
                            qrTicketsList.map((ticket, index) => (
                              <TableRow key={index}>
                                <TableCell className="cell_fixed_left">{ticket?.code || DATA_NULL}</TableCell>
                                <TableCell>{ticket?.user_info.member_id || DATA_NULL}</TableCell>
                                <TableCell>
                                  {ticket?.user_info.last_name || DATA_NULL} {ticket?.user_info.first_name || DATA_NULL}
                                </TableCell>
                                <TableCell>{ticket?.country_id ? t(this.getCountryCodeById(ticket?.country_id)) : DATA_NULL}</TableCell>
                                <TableCell>{ticket?.business_name || DATA_NULL}</TableCell>
                                <TableCell>{ticket?.ticket.ticket_type === TICKET_TYPE[0].id ? t(TICKET_TYPE[0].i18n) : t(TICKET_TYPE[1].i18n)}</TableCell>
                                <TableCell>{ticket?.ticket.ticket_type === 'COMMUTER_PASS' ? ticket?.ticket.name || DATA_NULL : DATA_NULL}</TableCell>
                                <TableCell>{ticket?.ticket.ticket_type === 'COUPON_TICKET' ? ticket?.ticket.name || DATA_NULL : DATA_NULL}</TableCell>
                                <TableCell>{ticket?.payment_method ? t('paymentMethod.CREDIT_CARD') : DATA_NULL}</TableCell>
                                <TableCell>{ticket?.payment_code || DATA_NULL}</TableCell>
                                <TableCell>{ticket?.currency_code || DATA_NULL}</TableCell>
                                <TableCell>{customDisplayCurrency(ticket?.amount, ticket?.currency_code) || DATA_NULL}</TableCell>
                                <TableCell>{ticket?.ticket?.rides_limit || DATA_NULL}</TableCell>
                                <TableCell>{ticket?.used_rides}</TableCell>
                                <TableCell>{ticket?.ticket?.rides_limit - ticket?.used_rides || DATA_NULL}</TableCell>
                                <TableCell>{ticket?.departure_station?.name || DATA_NULL}</TableCell>
                                <TableCell>{ticket?.arrival_station?.name || DATA_NULL}</TableCell>
                                <TableCell>
                                  {(ticket?.start_date_time &&
                                    convertDateTimeToDate(convertDateUTCAndGeofence(ticket.start_date_time, getTimeZoneByCountryCode(ticket?.country_id), true))) +
                                    ' ' +
                                    formatUTCToHourMinute(ticket.start_date_time, getTimeZoneByCountryCode(ticket?.country_id)) || DATA_NULL}
                                </TableCell>
                                <TableCell>
                                  {(ticket?.end_date_time &&
                                    convertDateTimeToDate(convertDateUTCAndGeofence(ticket.end_date_time, getTimeZoneByCountryCode(ticket?.country_id), true))) +
                                    ' ' +
                                    formatUTCToHourMinute(ticket.end_date_time, getTimeZoneByCountryCode(ticket?.country_id)) || DATA_NULL}
                                </TableCell>
                                <TableCell>
                                  {(ticket?.registration_date &&
                                    convertDateTimeToDate(convertDateUTCAndGeofence(ticket.registration_date, getTimeZoneByCountryCode(ticket?.country_id), true))) +
                                    ' ' +
                                    formatUTCToHourMinute(ticket.registration_date, getTimeZoneByCountryCode(ticket?.country_id)) || DATA_NULL}
                                </TableCell>
                                <TableCell>{ticket?.is_active ? t('common.enable') : t('common.disable')}</TableCell>
                                {permission.canEdit && (
                                  <TableCell className="cell_fixed_right">
                                    <Link
                                      style={{textDecoration: 'none'}}
                                      to={{
                                        pathname: ROUTE.LAYOUT + ROUTE.RESERVATION_MANAGEMENT_QR_TICKET_DETAIL + `/${ticket?.id}`,
                                        state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                      }}
                                    >
                                      <Button className="button_margin" color="primary" variant="contained" endIcon={<DetailsIcon />}>
                                        {t('common.btnDetail')}
                                      </Button>
                                    </Link>
                                  </TableCell>
                                )}
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </div>
                  </LoadingOverlay>
                  <CustomPagination onChange={this.handleChangePagination} rows={totalRows} currentPage={this.state.currentPage} rowsPerPage={this.state.rowsPerPage} />
                </>
              )}
            </Container>
          </Card>
        )}
        {/* Export alert modal */}
        <Dialog
          open={this.state.flagExport}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <>
            <SelectModal
              onClickOk={this.handleClickExportOK}
              onClickCancel={this.handleClickExportCancel}
              okButtonText={t('common.btnYes')}
              cancelButtonText={t('common.btnNo')}
              message={this.state.message}
            />
          </>
        </Dialog>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  common: state.common,
  agencyList: state.member.agencyList,
  tickets: state.member.tickets,
  qrTicket: state.qrTicket,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCountryCode: () => dispatch(getAllCountryCode()),
  getAgencyList: (payload) => dispatch(getAgencyList(payload)),
  getTickets: () => dispatch(getTickets()),
  searchQRTickets: (payload, queryParams) => dispatch(searchQRTickets(payload, queryParams)),
  setMessageModal: (message) => dispatch(setMessageModal(message)),
  exportQRTickets: (payload, queryParams) => dispatch(exportQRTickets(payload, queryParams)),
});

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
