import {ROUTE} from '../../common/constant';
import {createCouponApi, deleteCouponApi, getDetailCouponApi, getListCouponApi, updateCouponApi} from '../../services/couponServices.js';
import {backForwardRouter} from '../../utils/common.js';
import {modalObj} from '../../utils/modal.js';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

/**
 * search list company
 * @param {object} params
 * @param {object} queryParams
 * @param {object} props
 * @return {object}
 */
export const searchListCoupon = (params, queryParams) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SEARCH_LIST_COUPON_START,
    });
    return getListCouponApi(params, queryParams).then(
        (response) => {
          if (response && response.status === 200) {
            if (response.result) {
              dispatch({
                type: ACTION_TYPES.SEARCH_LIST_COUPON_SUCCESS,
                couponList: response.result,
              });
              return response.result;
            } else {
              dispatch({
                type: ACTION_TYPES.SEARCH_LIST_COUPON_FAIL,
              });
            }
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
            dispatch({
              type: ACTION_TYPES.SEARCH_LIST_COUPON_FAIL,
            });
          }
        },
        () =>
          dispatch({
            type: ACTION_TYPES.SEARCH_LIST_COUPON_FAIL,
          }),
    );
  };
};

/**
 * create coupon
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const createCoupon = (params, props) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.CREATE_COUPON_START,
    });
    return createCouponApi(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.CREATE_COUPON_SUCCESS,
            });
            props.history.push(ROUTE.LAYOUT + ROUTE.COUPON_MANAGEMENT);
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.CREATE_COUPON_FAIL,
            });
          }
        },
        () =>
          dispatch({
            type: ACTION_TYPES.CREATE_COUPON_FAIL,
          }),
    );
  };
};

/**
 * update coupon
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const updateCoupon = (params, props) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_COUPON_START,
    });
    return updateCouponApi(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.UPDATE_COUPON_SUCCESS,
            });
            return response.result;
          } else if (response && response?.message_code) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.UPDATE_COUPON_FAIL,
            });
          }
        },
        () =>
          dispatch({
            type: ACTION_TYPES.UPDATE_COUPON_FAIL,
          }),
    );
  };
};

/**
 * delete new company
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const deleteCoupon = (params) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.DELETE_COUPON_START,
    });
    return deleteCouponApi(params).then(
        (response) => {
          if (response?.result && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.DELETE_COUPON_SUCCESS,
            });
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.DELETE_COUPON_FAIL,
            });
          }
        },
        () =>
          dispatch({
            type: ACTION_TYPES.DELETE_COUPON_FAIL,
          }),
    );
  };
};

/**
 * get detail coupon
 * @param {number} id
 * @param {object} props
 * @return {object}
 */
export const getDetailCoupon = (id, props) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_DETAIL_COUPON_START,
    });
    return getDetailCouponApi(id).then(
        (response) => {
          if (response?.result && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_DETAIL_COUPON_SUCCESS,
              couponDetail: response.result,
            });
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.GET_DETAIL_COUPON_FAIL,
            });
            if (response?.message_code === 'permission.denied') {
              backForwardRouter(props, ROUTE.LAYOUT + ROUTE.HOME);
            }
          }
        },
        () =>
          dispatch({
            type: ACTION_TYPES.GET_DETAIL_COUPON_FAIL,
          }),
    );
  };
};

