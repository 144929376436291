import {api} from '../services/api';

const areaGeofenceUrl = `mass/v1/geofence-area`;

export const searchAreaGeofenceApi = (params) => {
  return api.get(areaGeofenceUrl, params);
};

export const getDetailAreaGeofenceApi = (id) => {
  return api.get(areaGeofenceUrl + '/' + id);
};

export const updateAreaGeofenceApi = (payload) => {
  return api.post(areaGeofenceUrl, payload);
};

export const deleteAreaGeofenceApi = (id) => {
  return api.delete(areaGeofenceUrl + '/' + id);
};
