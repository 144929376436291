import React, {Component} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {Container, Paper, Grid, Button, FormControl, Select, MenuItem, FormHelperText, TextField, Card} from '@material-ui/core';
import ForwardIcon from '@material-ui/icons/TrendingFlat';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {format} from 'date-fns-tz/esm';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {COUNTRY, ROUTE, TICKET_TYPE} from '../../../common/constant';
import MuiPhoneNumberInput from '../../../components/mui-phone-number-input';
import {checkOwnerCard} from '../../../stores/business/action';
import {getAllCountryCode, getListSupplier} from '../../../stores/common/actions';
import {getAgencyList, getMemberDetail, getStations, getTickets, getTicketsByUser, updateDetailTicket} from '../../../stores/member/action';
import {getLineStations} from '../../../stores/qr_ticket_booking/action';
import {backForwardRouter, onChangeTextField, onChangeSelect, getDialCodePhoneNumber} from '../../../utils/common';
import {isDate} from '../../../utils/datetime';

/**
 * Search Ticket
 */
class SearchTicket extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      firstName: '',
      lastName: '',
      last_name_furigana: '',
      first_name_furigana: '',
      mobile: '',
      email: '',
      birthday: null,
      countryCode: 'JP',
      country_id: '',
      supplier: '',
      arrival_station: '',
      departure_station: '',
      ticket_type: TICKET_TYPE[0].id,
      payment_method: 'ticket.credit_card',
      ticket_use_type: '',
      ticket_use_type_id: '',
      station: {},
      dataCountry: {
        countryCode: 'jp',
        dialCode: '81',
        name: 'Japan',
      },
      cardInfo: [],
      isSearch: false,
      start_date: null,
      end_date: '',
      ticketTypeByUser: '',
      minStartDate: new Date(),
      maxStartDate: '',
      notification: '',
      warningNotification: '',
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    const data = this.props.history?.location?.state;
    if (data) {
      this.setState({
        supplier: data?.cacheData.supplier,
        start_date: data?.cacheData.start_date,
        departure_station: data?.cacheData.departure_station,
        arrival_station: data?.cacheData.arrival_station,
        ticket_use_type: data?.cacheData.ticket_use_type,
        ticket_type: data?.cacheData.ticket_type,
        ticket_use_type_id: data?.cacheData.ticket_use_type.id,
      });
    }
    this.props.getAllCountryCode();
    await this.props.getAgencyList({country_code: this.state.countryCode.toLowerCase()}).then((response) => {
      if (response) {
        const defaultSupplier = response.filter((item) => item.agency_id === 'WLTR');
        this.setState({supplier: defaultSupplier?.length > 0 ? defaultSupplier[0] : response[0]});
      }
    });
    if (this.props.match.params.userId) {
      await this.props.getTicketsByUser({user_id: this.props.match.params.userId}).then((response) => {
        if (response && response?.length > 0) {
          if (response[0].ticket?.ticket_type === TICKET_TYPE[0].id) {
            const end_date_max = Math.max(...response.map((o) => new Date(o.end_date_time)));
            const end_date = new Date(end_date_max);
            this.setState({end_date: format(end_date, 'yyyy-MM-dd')});
          }
          this.setState({ticketTypeByUser: response[0].ticket?.ticket_type});
        } else {
          this.setState({start_date: format(new Date(), 'yyyy-MM-dd')});
        }
      });
      await this.props.getMemberDetail(this.props.match.params.userId).then(async (data) => {
        if (data) {
          if (data.mobile?.includes('+')) {
            this.setState({
              dataCountry: COUNTRY.find((item) => item.dialCode === getDialCodePhoneNumber(data.mobile)),
            });
          }
          this.setState({
            id: data.id,
            birthday: data.birthday,
            mobile: data.mobile?.includes('+') ? data.mobile : '+' + this.state.dataCountry?.dialCode + data.raw_mobile,
            email: data.email,
            firstName: data.first_name,
            lastName: data.last_name,
            last_name_furigana: data.last_name_furigana,
            first_name_furigana: data.first_name_furigana,
          });
          await this.confirmCreditCard();
        }
      });
    }
    const payload = {operator_id: this.state.supplier.agency_id};
    this.props.getStations(payload);
    this.props.getTickets();
    this.notificationTiketBooking();
  };

  /**
   * notificationTiketBooking
   */
  notificationTiketBooking = () => {
    const maxStartDate = new Date().setDate(new Date().getDate() + 14);
    this.setState({maxStartDate});
    if (this.state.ticketTypeByUser === TICKET_TYPE[1].id) {
      this.setState({notification: 'ticket.notification_1'});
      return;
    } else {
      if (new Date(this.state.end_date) >= maxStartDate) {
        this.setState({notification: 'ticket.notification_2'});
        return;
      }
      if (maxStartDate > new Date(this.state.end_date) && format(new Date(this.state.end_date), 'yyyyMMdd') >= format(new Date(), 'yyyyMMdd')) {
        const minStartDate = new Date(this.state.end_date).setDate(new Date(this.state.end_date).getDate() + 1);
        this.setState({warningNotification: 'ticket.notification_3', minStartDate});
      }
    }
  };

  /**
   * confirmCreditCard
   */
  async confirmCreditCard() {
    this.props.checkOwnerCard(this.state.id).then((response) => {
      const cardInfo = [];
      for (const card in response) {
        if (response[card].length > 0) {
          const cardData = response[card]?.find((item) => Number(item.default_card) === 1);
          if (cardData && card === 'veritrans_cards') {
            cardInfo.push({
              ...cardData,
              cardType: card === 'veritrans_cards' ? 'Veritrans Card' : '2c2p Card',
            });
          }
        }
      }
      this.setState({
        cardInfo,
      });
    });
  }

  /**
   * handleSearchRoute
   * @param {object} event
   */
  onChangeSupplier = async (event) => {
    this.setState({supplier: event.target.value});
    const payload = {operator_id: event.target.value?.agency_id || ''};
    this.props.getStations(payload);
  };

  /**
   * handleSearchRoute
   */
  handleSearchRoute = () => {
    this.setState({
      isSearch: true,
    });
    const fieldValidCouponType =
      this.validator.fieldValid('supplier') &&
      this.validator.fieldValid('country') &&
      this.validator.fieldValid('ticket_use_type_id') &&
      this.validator.fieldValid('arrival_station') &&
      this.validator.fieldValid('departure_station');
    const fieldValidCommuterPassType = this.validator.allValid();
    if (this.state.ticket_type === TICKET_TYPE[0].id && fieldValidCommuterPassType && isDate(this.state.start_date)) {
      if (this.state.maxStartDate && this.state.minStartDate) {
        const isInvalidStartDate =
          format(new Date(this.state.maxStartDate), 'yyyyMMdd') < format(new Date(this.state.start_date), 'yyyyMMdd') ||
          format(new Date(this.state.minStartDate), 'yyyyMMdd') > format(new Date(this.state.start_date), 'yyyyMMdd');
        if (isInvalidStartDate) {
          return;
        }
        this.setParamsAndCacheSearch();
      }
    }
    if (this.state.ticket_type === TICKET_TYPE[1].id && fieldValidCouponType) {
      this.setParamsAndCacheSearch();
    }
  };

  /**
   * setParamsAndCacheSearch
   */
  setParamsAndCacheSearch = () => {
    const params = {
      agency_id: null,
      ticket_id: null,
      departure_station: '',
      arrive_station: '',
      start_date: '',
    };
    const ticketInfor = {
      countryCode: '',
      businessName: '',
      lastName: '',
      firstName: '',
      lastNameFurigana: '',
      firstNameFurigana: '',
      birthday: '',
      phoneNumber: '',
      email: '',
      departureStationName: '',
      arrivalStationName: '',
      ticketTypeName: '',
      ticketUseTypeName: '',
      paymenMethod: '',
      startDate: '',
      validityPeriod: '',
      unit: '',
      ticket_id: '',
      user_id: this.state.id,
      dataCountry: {},
      mobile: '',
    };
    const cacheData = {
      supplier: this.state.supplier,
      start_date: this.state.start_date,
      departure_station: this.state.departure_station,
      arrival_station: this.state.arrival_station,
      ticket_use_type: this.state.ticket_use_type,
      ticket_type: this.state.ticket_type,
    };
    // set infor ticket
    ticketInfor.countryCode = this.state.countryCode;
    ticketInfor.businessName = this.state.supplier.names?.ja || '';
    ticketInfor.lastName = this.state.lastName;
    ticketInfor.firstName = this.state.firstName;
    ticketInfor.lastNameFurigana = this.state.last_name_furigana;
    ticketInfor.firstNameFurigana = this.state.first_name_furigana;
    ticketInfor.birthday = this.state.birthday || '';
    ticketInfor.phoneNumber = this.state.mobile || '';
    ticketInfor.email = this.state.email;
    ticketInfor.departureStationName = this.state.departure_station.name || '';
    ticketInfor.arrivalStationName = this.state.arrival_station.name || '';
    ticketInfor.ticketTypeName = this.state.ticket_type;
    ticketInfor.ticketUseTypeName = this.state.ticket_use_type.name || '';
    ticketInfor.paymenMethod = this.state.payment_method;
    ticketInfor.validityPeriod = this.state.ticket_use_type?.validity_period || '';
    ticketInfor.startDate = this.state.start_date;
    ticketInfor.unit = this.state.ticket_use_type?.unit || '';
    ticketInfor.ticket_id = this.state.ticket_use_type?.id || '';
    ticketInfor.dataCountry = this.state.dataCountry;
    ticketInfor.mobile = this.state.mobile;
    // set params
    params.agency_id = this.state.supplier?.agency_id || '';
    params.ticket_id = this.state.ticket_use_type?.id;
    params.arrive_station = this.state.arrival_station?.id;
    params.departure_station = this.state.departure_station?.id;
    params.start_date = format(new Date(this.state.start_date), 'yyyy-MM-dd') || format(new Date(), 'yyyy-MM-dd');
    this.props.getLineStations(params).then((response) => {
      if (response) {
        this.props.history.push({
          pathname: ROUTE.LAYOUT + ROUTE.MEMBER_ROUTE_LIST + `/${this.props.match.params.userId}`,
          state: {
            ticketInfor,
            lines: response,
            cacheData,
          },
        });
      }
    });
  };

  /**
   * Render component
   * @return {object}
   */
  render() {
    const {t, common, stations, isLoading, tickets, agencyList} = this.props;
    return (
      <LoadingOverlay active={isLoading || common.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size plan_form">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('route.register_railway_ticket')}</h3>
              </Grid>
            </Grid>
          </Container>
          <br></br>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('ticket.titleSearch')}
                    </Grid>
                  </Grid>
                  {this.state.notification && (
                    <Grid container alignItems="center" item xs={12} lg={12} className="table_background_color_aliceblue font_color_red grid_title_padding px_14">
                      * {t(this.state.notification)}
                    </Grid>
                  )}
                  {this.state.warningNotification && (
                    <Grid container alignItems="center" item xs={12} lg={12} className="table_background_color_aliceblue font_color_red grid_title_padding px_14">
                      * {t(this.state.warningNotification, {date: this.state.end_date})}
                    </Grid>
                  )}
                  {/* Country */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('common.country')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'countryCode',
                          }}
                          displayEmpty
                          disabled
                          value={this.state.countryCode}
                          onChange={(event) => onChangeSelect(this, event)}
                          renderValue={
                            this.state.countryCode ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.country'),
                                    })}
                                  </div>
                                )
                          }
                        >
                          {common?.country_code?.map((item, idx) => (
                            <MenuItem value={item.country_code} key={idx}>
                              {t(`${item.country_code}`)}
                            </MenuItem>
                          ))}
                        </Select>
                        {this.validator.message('country', this.state.countryCode, 'required')}
                        {this.state.isSearch && !this.validator.check(this.state.countryCode, 'required') && (
                          <FormHelperText id="country" error>
                            {t('validation.required.choose', {field: t('common.country')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Business Name */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('driver.supplier.name')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'supplier',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.supplier ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('driver.supplier.name'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.supplier}
                          onChange={(event) => this.onChangeSupplier(event)}
                        >
                          {agencyList?.map((item, idx) => {
                            return (
                              <MenuItem value={item} key={idx}>
                                {item.names?.ja}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('supplier', this.state.supplier, 'required')}
                        {this.state.isSearch && !this.validator.check(this.state.supplier, 'required') && (
                          <FormHelperText id="supplier" error>
                            {t('validation.required.choose', {field: t('driver.supplier.name')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* last name - first name */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('updateMember.lastName')}
                    </Grid>
                    <Grid container alignItems="center" item xs={9} lg={3} className="grid_title_padding">
                      <FormControl className="field_size_10 field_min_size_300">
                        <TextField
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          value={this.state.lastName || ''}
                          name="lastName"
                          disabled
                          onChange={(event) => onChangeTextField(this, event)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('updateMember.firstName')}
                    </Grid>
                    <Grid container alignItems="center" item xs={9} lg={3} className="grid_title_padding">
                      <FormControl className="field_size_10 field_min_size_300">
                        <TextField
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          value={this.state.firstName || ''}
                          name="firstName"
                          disabled
                          onChange={(event) => onChangeTextField(this, event)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* last name furigana - first name furigana */}
                  <Grid xs={12} container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('updateMember.lastName_furigana')}
                    </Grid>
                    <Grid container alignItems="center" item xs={9} lg={3} className="grid_title_padding">
                      <FormControl className="field_size_10 field_min_size_300">
                        <TextField
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          value={this.state.last_name_furigana || ''}
                          name="last_name_furigana"
                          disabled
                          onChange={(event) => onChangeTextField(this, event)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('updateMember.firstName_furigana')}
                    </Grid>
                    <Grid container alignItems="center" item xs={9} lg={2} className="grid_title_padding">
                      <FormControl className="field_size_10 field_min_size_300">
                        <TextField
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          value={this.state.first_name_furigana || ''}
                          name="first_name_furigana"
                          disabled
                          onChange={(event) => onChangeTextField(this, event)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* birthday - mobile */}
                  <Grid container xs={12} spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('memberManagement.birthDay')}
                    </Grid>
                    <Grid container alignItems="center" item xs={9} lg={3} className="grid_title_padding">
                      <FormControl className="field_size_10 field_min_size_300">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            variant="inline"
                            views={['year', 'month', 'date']}
                            margin="dense"
                            autoOk
                            inputVariant="outlined"
                            minDate="1900-01-01"
                            maxDate={new Date()}
                            format="yyyy-MM-dd"
                            value={this.state.birthday || null}
                            onChange={(birthday) => this.setState({birthday: birthday})}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            disabled
                            invalidDateMessage={t('errorFields.invalidDateMessage')}
                            maxDateMessage={t('errorFields.maxDateMessage')}
                            minDateMessage={t('errorFields.minDateMessage')}
                          />
                        </MuiPickersUtilsProvider>
                      </FormControl>
                    </Grid>
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('common.phoneNumber')}
                    </Grid>
                    <Grid container alignItems="center" item xs={9} lg={2} className="grid_title_padding">
                      <FormControl className="field_size_10 field_min_size_300">
                        <MuiPhoneNumberInput
                          ref="mobile"
                          defaultCountry={this.state.dataCountry?.countryCode}
                          onlyCountries={COUNTRY.map((c) => c.countryCode)}
                          countryCodeEditable={false}
                          enableLongNumbers={true}
                          autoFormat={false}
                          margin="dense"
                          variant="outlined"
                          name="mobile"
                          value={this.state.mobile}
                          disabled
                          onChange={(value, country) => {
                            this.setState({dataCountry: country}, () => {
                              this.setState({mobile: value});
                            });
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* email */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('common.email')}
                    </Grid>
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      <FormControl className="field_size_10 field_min_size_300">
                        <TextField
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          name="email"
                          inputProps={{maxLength: 64}}
                          value={this.state.email || ''}
                          disabled
                          onChange={(event) => onChangeTextField(this, event)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Departure Station */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('ticket.departure_station')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Autocomplete
                          name="departure_station"
                          margin="dense"
                          options={stations || []}
                          value={this.state.departure_station || ''}
                          getOptionLabel={(option) => option.name || ''}
                          onChange={(event, value) => this.setState({departure_station: value})}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth variant="outlined" margin="dense" placeholder={t('placeholder.required', {field: t('ticket.departure_station')})} />
                          )}
                          getOptionSelected={(option, value) => option.id === value.id}
                        />
                        {this.validator.message('departure_station', this.state.departure_station, 'required')}
                        {this.state.isSearch && !this.validator.check(this.state.departure_station, 'required') && (
                          <FormHelperText id="departure_station" error>
                            {t('validation.required.choose', {field: t('ticket.departure_station')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Arrival Station */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('ticket.arrival_station')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Autocomplete
                          name="arrival_station"
                          margin="dense"
                          options={stations || []}
                          value={this.state.arrival_station || ''}
                          getOptionLabel={(option) => option.name || ''}
                          onChange={(event, value) => this.setState({arrival_station: value})}
                          renderInput={(params) => (
                            <TextField {...params} fullWidth variant="outlined" margin="dense" placeholder={t('placeholder.required', {field: t('ticket.arrival_station')})} />
                          )}
                          getOptionSelected={(option, value) => option.id === value.id}
                        />
                        {this.validator.message('arrival_station', this.state.arrival_station, 'required')}
                        {this.state.isSearch && !this.validator.check(this.state.arrival_station, 'required') && (
                          <FormHelperText id="arrival_station" error>
                            {t('validation.required.choose', {field: t('ticket.arrival_station')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Ticket type */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('ticket.ticket_type')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'ticket_type',
                          }}
                          displayEmpty
                          value={this.state.ticket_type}
                          onChange={(event) => onChangeSelect(this, event)}
                          renderValue={
                            this.state.ticket_type ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('ticket.ticket_type'),
                                    })}
                                  </div>
                                )
                          }
                        >
                          {TICKET_TYPE?.map((item, idx) => (
                            <MenuItem value={item.id} key={idx}>
                              {t(`${item.i18n}`)}
                            </MenuItem>
                          ))}
                        </Select>
                        {this.validator.message('ticket_type', this.state.ticket_type, 'required')}
                        {this.state.isSearch && !this.validator.check(this.state.ticket_type, 'required') && (
                          <FormHelperText id="ticket_type" error>
                            {t('validation.required.choose', {field: t('ticket.ticket_type')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Commuter pass type - Coupon ticket type */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {this.state.ticket_type === TICKET_TYPE[0].id ? t('ticket.commuter_pass_type') : t('ticket.coupon_ticket_type')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'ticket_use_type_id',
                          }}
                          displayEmpty
                          disabled={!this.state.ticket_type}
                          value={this.state.ticket_use_type_id}
                          onChange={(event) =>
                            this.setState({
                              ticket_use_type_id: event.target.value,
                              ticket_use_type: tickets?.find((ticket) => ticket.id === event.target.value),
                            })
                          }
                          renderValue={
                            this.state.ticket_use_type_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: this.state.ticket_type === TICKET_TYPE[0].id ? t('ticket.commuter_pass_type') : t('ticket.coupon_ticket_type'),
                                    })}
                                  </div>
                                )
                          }
                        >
                          {tickets
                            ?.filter((ticket) => ticket.ticket_type === this.state.ticket_type && ticket?.app_display === false)
                            ?.map((item, idx) => (
                              <MenuItem value={item.id} key={idx}>
                                {t(`${item.name}`)}
                              </MenuItem>
                            ))}
                        </Select>
                        {this.validator.message('ticket_use_type_id', this.state.ticket_use_type_id, 'required')}
                        {this.state.isSearch && !this.validator.check(this.state.ticket_use_type_id, 'required') && (
                          <FormHelperText id="ticket_use_type_id" error>
                            {t('validation.required.choose', {field: t('ticket.ticket_use_type')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* end_time */}
                  {this.state.ticket_type === TICKET_TYPE[0].id && (
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                        {t('ticket.start_date_of_use')} <span className="font_color_red">＊</span>
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4} className="grid_title_padding">
                        <FormControl>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              autoOk
                              disableToolbar
                              variant="inline"
                              placeholder={t('ticket.start_date_of_use')}
                              inputVariant="outlined"
                              minDate={this.state.minStartDate}
                              maxDate={this.state.maxStartDate}
                              maxDateMessage={t('errorFields.maxDateMessage')}
                              minDateMessage={t('errorFields.minDateMessage')}
                              invalidDateMessage={t('errorFields.invalidDateMessage')}
                              format="yyyy/MM/dd"
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              value={this.state.start_date}
                              onChange={(time) => this.setState({start_date: time})}
                            />
                          </MuiPickersUtilsProvider>
                          {this.validator.message('start_date', this.state.start_date, 'required')}
                          {this.state.isSearch && !this.validator.check(this.state.start_date, 'required') && (
                            <FormHelperText id="start_date" error>
                              {t('validation.required.choose', {field: t('ticket.start_date_of_use')})}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}

                  {/* Payment method */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('ticket.payment_method')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <FormControl className="field_size_10 field_min_size_300">
                        <TextField
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          value={t(this.state.payment_method)}
                          name="payment_method"
                          disabled
                          onChange={(event) => onChangeTextField(this, event)}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Credit card information */}
                  {this.state.payment_method && (
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding"></Grid>
                      <Grid container alignItems="center" item xs={8} lg={6} className="grid_title_padding">
                        {this.state.cardInfo.length > 0 ? (
                          this.state.cardInfo?.map((item, index) => (
                            <React.Fragment key={index}>
                              <Grid container justify="flex-start" alignItems="flex-start" item xs={2}>
                                {'JP'}
                              </Grid>
                              <Grid container justify="flex-start" alignItems="flex-start" item xs={1}>
                                <ForwardIcon
                                  style={{
                                    position: 'relative',
                                    fontSize: 30,
                                    top: -6,
                                    color: '#3f51b5',
                                  }}
                                ></ForwardIcon>
                              </Grid>
                              <Grid container justify="flex-start" alignItems="flex-start" item xs={4}>
                                {t('memberManagement.cardNumber')}:{' '}
                                <span className="font_color_blue" style={{marginLeft: '10px'}}>
                                  {item?.card_number}
                                </span>
                              </Grid>
                              <Grid container justify="flex-start" alignItems="flex-start" item xs={4}>
                                {t('memberManagement.cardExpire')}:{' '}
                                <span className="font_color_blue" style={{marginLeft: '10px'}}>
                                  {item?.card_expire}
                                </span>
                              </Grid>
                            </React.Fragment>
                          ))
                        ) : (
                          <>
                            <Grid container justify="flex-start" alignItems="flex-start" item xs={12}>
                              <span className="font_color_red">{t('memberManagement.cantGetCardInfo')}</span>
                            </Grid>
                            <Grid container justify="flex-start" alignItems="flex-start" item xs={12}></Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Paper>
                <br></br>

                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="center" item xs={12}>
                    <Button
                      color="primary"
                      variant="contained"
                      disabled={this.state.notification || (this.state.warningNotification && this.state.ticket_type === TICKET_TYPE[1].id)}
                      className="button_magin"
                      onClick={this.handleSearchRoute}
                    >
                      {t('ticket.search_route')}
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      className="button_magin button_color"
                      style={{marginLeft: 5}}
                      onClick={() => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.MEMBER_MANAGEMENT)}
                    >
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                </Grid>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>
          <br></br>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    business: state.business,
    isLoading: state.member.isLoading,
    stations: state.member.stations,
    tickets: state.member.tickets,
    lineStation: state.qrTicketBooking.lineStation,
    agencyList: state.member.agencyList,
    ticketsByUser: state.member.ticketsByUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getListSupplier: () => dispatch(getListSupplier()),
    getMemberDetail: (id) => dispatch(getMemberDetail(id)),
    updateDetailTicket: (payload) => dispatch(updateDetailTicket(payload)),
    checkOwnerCard: (userId) => dispatch(checkOwnerCard(userId)),
    getStations: (payload) => dispatch(getStations(payload)),
    getTickets: () => dispatch(getTickets()),
    getLineStations: (payload) => dispatch(getLineStations(payload)),
    getAgencyList: (payload) => dispatch(getAgencyList(payload)),
    getTicketsByUser: (payload) => dispatch(getTicketsByUser(payload)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(SearchTicket));
