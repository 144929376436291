import React, {Component} from 'react';

import {Paper, Container, Button, Grid, Box} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import {withTranslation} from 'react-i18next';

import {customDisplayCurrency} from '../../utils/common';


/**
 * Modal component
 */
class Index extends Component {
  static defaultProps = {
    isSub: false,
  }
  /**
   * Render component
   * @return {component}
   */
  render() {
    const {t} = this.props;
    return (
      <Paper className="field_min_size_800">
        <Container maxWidth="xl">
          <br />
          <Grid container spacing={6}>
            <Grid container alignItems="center" justify="flex-end" item xs={2}>
              <InfoIcon className="errorinfo" fontSize="large" />
            </Grid>
            <Grid container alignItems="center" justify="flex-start" item xs={10}>
              <span className="font_size_small_regular" style={{whiteSpace: 'pre-line'}}>
                {t(`${this.props.message}`, this.props.fields)}
              </span>
              <div className="font_size_small_regular scroll_area_280" style={{whiteSpace: 'pre-line', width: '100%'}}>
                {this.props?.data?.map((item) => (
                  <Box my={1}>
                    {t(`${this.props.messageData}`, {
                      GeofenceName: item?.geofence_name,
                      Id: this.props.isSub ? item?.subscription_code : item?.plan_code,
                      Adult: customDisplayCurrency(item?.adult_amount || 0, item?.currency_code),
                      Child: customDisplayCurrency(item?.child_amount || 0, item?.currency_code),
                    })}
                  </Box>
                ))}
              </div>
            </Grid>
            <Grid container alignItems="center" justify="center" item xs={12}>
              <Button
                fullWidth
                variant="contained"
                className=""
                color="primary"
                onClick={() => {
                  this.props.closeMessage();
                }}
              >
                {t('common.btnAgree')}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <br />
      </Paper>
    );
  }
}

export default withTranslation('translations')(Index);
