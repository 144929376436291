import React, {Component} from 'react';

import {Map, GoogleApiWrapper} from 'google-maps-react';
import {isEmpty} from 'lodash';
import {connect} from 'react-redux';

/**
 * Map with polyline
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      pinMarker: [],
      isLocationValid: true,
      checkInsideGeofence: true,
    };
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    const centerPoint = this.props.centerPoint || {lat: 21.0321246, lng: 105.7729};
    const {google, defaultMarker, indexMap, numberLocation, mapInfor} = this.props;
    let pinMarker = this.state.pinMarker;
    if (this.mapRef) {
      const map = this.mapRef.map;
      map.setCenter(centerPoint);
      const jsonData = {
        type: 'Feature',
        geometry: {
          type: mapInfor?.geometry?.type,
          coordinates: mapInfor?.geometry?.coordinates,
        },
      };
      map.data.addGeoJson(jsonData);
      map.data.setStyle({
        strokeColor: mapInfor.borderColor,
        fillColor: mapInfor.bgColor,
        fillOpacity: 0,
        strokeWeight: 1.5,
        strokeOpacity: mapInfor.strokeOpacity / 100,
        clickable: false,
      });
      for (let i = 0; i < numberLocation; i++) {
        pinMarker.push({});
      }
      if (defaultMarker[0]?.lat && defaultMarker[0]?.lng) {
        const pinMarker = this.state.pinMarker;
        pinMarker[indexMap] = new google.maps.Marker({
          position: defaultMarker[0],
          map: map,
          draggable: true,
        });
        // drag marker
        await pinMarker[indexMap].addListener('dragend', (event) => {
          this.props.getLatLng(event.latLng.toJSON(), indexMap);
          this.handleInsidePolygon(mapInfor, google, event, false);
          this.setState({pinMarker});
        });
      }
      await map.addListener('click', (mapsMouseEvent) => {
        pinMarker = this.state.pinMarker;
        if (!isEmpty(pinMarker[indexMap])) pinMarker[indexMap].setMap(null);
        pinMarker[indexMap] = new google.maps.Marker({
          position: mapsMouseEvent.latLng,
          map: map,
          draggable: true,
        });
        this.props.getLatLng(mapsMouseEvent.latLng.toJSON(), indexMap, true);
        this.handleInsidePolygon(mapInfor, google, mapsMouseEvent, false);
        // drag marker when click
        pinMarker[indexMap].addListener('dragend', (event) => {
          this.props.getLatLng(event.latLng.toJSON(), indexMap, true);
          this.setState({pinMarker});
        });
        this.setState({pinMarker});
      });
    }
  }

  /**
   * handleInsidePolygon
   * @param {object} mapInfor
   * @param {*} google
   * @param {*} event
   * @param {*} isInput
   */
  handleInsidePolygon = async (mapInfor, google, event, isInput) => {
    const {indexMap} = this.props;
    const Polygon = new google.maps.Polygon({
      paths: mapInfor?.geometry?.coordinates[0].map((coordinate) => ({lat: coordinate[1], lng: coordinate[0]})),
    });
    const checkInsideGeofence = google.maps.geometry.poly.containsLocation(isInput ? new google.maps.LatLng(event) : event.latLng, Polygon, true);
    await this.props.messageErrorHandle(checkInsideGeofence, indexMap);
  };

  /**
   * UNSAFE_componentWillReceiveProps
   * @param {*} nextProps
   */
  UNSAFE_componentWillReceiveProps = async (nextProps) => {
    const {google, indexMap, point, mapInfor, isValidLatLng} = nextProps;
    const pinMarker = this.state.pinMarker;
    const map = this.mapRef.map;
    if (isValidLatLng === false) {
      this.props.messageErrorHandle(!isValidLatLng, indexMap);
      return;
    }
    if (point?.lat && point?.lng) {
      if (!isEmpty(pinMarker[point.index]) && indexMap === point.index) {
        pinMarker[point.index].setMap(null);
      }
      if (indexMap === point.index) {
        const position = {lat: Number(point?.lat), lng: Number(point?.lng)};
        pinMarker[point.index] = new google.maps.Marker({
          position: position,
          map: map,
          draggable: true,
        });
        await this.handleInsidePolygon(mapInfor, google, position, true);
        await pinMarker[indexMap].addListener('dragend', (event) => {
          this.props.getLatLng(event.latLng.toJSON(), indexMap);
          this.handleInsidePolygon(mapInfor, google, event, false);
          this.setState({pinMarker});
        });
        this.setState({pinMarker});
      }
    } else {
      if (!isEmpty(pinMarker[point.index]) && indexMap === point.index) {
        pinMarker[point.index].setMap(null);
      }
    }
  };

  /**
   * render
   * @return {HTMLElement}
   */
  render() {
    return (
      <div>
        {!this.state.isLocationValid && <div>Default Location not valid</div>}
        <Map
          {...this.props}
          ref={(ref) => (this.mapRef = ref)}
          zoom={12}
          centerAroundCurrentLocation={false}
          containerStyle={{
            height: '400px',
            position: 'relative',
            width: '27.8vw',
          }}
        ></Map>
      </div>
    );
  }
}

// eslint-disable-next-line new-cap
export default connect(
  null,
  null,
)(
  // eslint-disable-next-line new-cap
  GoogleApiWrapper((props) => ({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || 'AIzaSyACyapw83diO1bi_xiXbZRLLoano6eTwd0',
    language: localStorage.getItem('i18nextLng') === 'ja' ? 'ja' : 'en',
    libraries: ['geometry'],
  }))(Index),
);
