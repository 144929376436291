import React, {Component} from 'react';

import {Card, Container, Grid, Button, Table, TableCell, TableRow, TableBody, TableHead, TableFooter, Dialog, TextField, Paper} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import DetailIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {ROUTE, ROWS_PER_PAGE_OPTIONS, MAX_SIZE_OUTPUT, EXPORT_LIMIT_NUMBER_ROWS, EXPORT_ALERT_NUMBER_ROWS, PERMISSION_ACTIONS} from '../../../common/constant';
import CSVExporter from '../../../components/CSVExporter';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {setMessageModal} from '../../../stores/modal/actions';
import {searchListCaution, deleteCaution, exportCaution} from '../../../stores/notice/actions';
import {redirectRouter} from '../../../utils/common';
import {onChangeTextField, changeUrlParams, getUrlParams, getQueryStringFromObject} from '../../../utils/common';
import {modalObj} from '../../../utils/modal';

/**
 * Product Management view
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      cautionId: '',
      cautionName: '',
      flagDelete: false,
      isSearch: false,
      id: '',
      count: 0,
      result: [],
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,
      flagExport: false,
      confirmedExport: false,
      latestSearchParams: null,
    };
    this.handleButtonOk = this.handleButtonOk.bind(this);
    this.handleButtonCancel = this.handleButtonCancel.bind(this);

    this.exportCSVRef = React.createRef();
    const {t} = this.props;
    this.exportHeaders = [
      {label: t('caution.id'), key: 'caution_id'},
      {label: t('caution.name'), key: 'caution_name'},
    ];
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    if (this.props.location.search) {
      this.setState(getUrlParams(this.props.location.search));
    }
  }

  /**
   * componentDidUpdate
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.props.actions !== prevProps.actions) {
      const canSearch = this.props.actions.includes(PERMISSION_ACTIONS.SEARCH);
      if (canSearch && this.props.location.search) {
        this.setState(getUrlParams(this.props.location.search), this.handleSearchCaution);
      }
    }
  }
  /**
   * handleDelete
   * @param {int} id
   * @param {object} index
   */
  handleDelete(id, index) {
    const {t} = this.props;
    this.setState({
      flagDelete: true,
      message: `${t('messageCode.deleteConfirm', {field: t('caution.item')})}`,
      id,
      index,
    });
  }
  /**
   * handleSearchCaution
   * @param {bool} reset
   */
  handleSearchCaution(reset) {
    const canSearch = this.props.actions.includes(PERMISSION_ACTIONS.SEARCH);
    if (!canSearch) return;
    const {cautionId, cautionName} = this.state;
    const payload = {
      caution_id: cautionId,
      name: cautionName,
    };
    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const {currentPage, rowsPerPage} = this.state;
    const queryParams = reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage};

    this.props.searchListCaution(payload, queryParams, this.props).then((res) => {
      this.setState(
          {
            result: res?.content,
            totalRows: res?.totalSize,
            count: res.totalSize,
            isSearch: true,
            latestSearchParams: payload,
          },
          () => {
            const {cautionId, cautionName, currentPage, rowsPerPage} = this.state;
            // Apply changed params into url
            const queryParamsToChange = {cautionId, cautionName, currentPage: String(currentPage), rowsPerPage};
            changeUrlParams(queryParamsToChange);
            const newSearchParams = getQueryStringFromObject(queryParamsToChange);
            // Save search params into state in order to pass to next page
            this.setState({searchParams: newSearchParams});
          },
      );
    });
  }
  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearchCaution);
  };
  /**
   * handleButtonOk
   */
  handleButtonOk() {
    this.setState({
      flagDelete: false,
    });
    this.props.deleteCaution(this.state.id, this.props).then(() => {
      this.handleSearchCaution();
    });
  }
  /**
   * handleButtonCancel
   */
  handleButtonCancel() {
    this.setState({
      flagDelete: false,
    });
  }

  /**
   * handle reset button
   */
  reset = () => {
    this.setState({
      cautionId: '',
      cautionName: '',
    });
  };

  /**
   * Fetch data to export
   */
  fetchDataExport = async () => {
    const queryParams = {page: 0, size: MAX_SIZE_OUTPUT};
    const dataExport = await this.props.exportCaution(this.state.latestSearchParams, queryParams);
    const convertedData = dataExport.map((row, index) => {
      return row;
    });
    this.setState({confirmedExport: false});
    return convertedData;
  };

  /**
   * alertExportCSV
   */
  alertExportCSV = () => {
    const {t} = this.props;
    if (this.state.totalRows > EXPORT_LIMIT_NUMBER_ROWS) {
      this.props.setMessageModal(modalObj(true, t('messageCode.exportAlertLimit')));
    } else {
      const message = t('messageCode.exportAlert');
      this.setState({flagExport: true, message});
    }
  };

  /**
   * handleButtonExportOk
   */
  handleButtonExportOk = () => {
    this.setState({flagExport: false, confirmedExport: true}, () => {
      this.exportCSVRef.current.onClickExport();
    });
  };

  /**
   * handleButtonExportCancel
   */
  handleButtonExportCancel = () => {
    this.setState({flagExport: false});
  };

  /**
   * Render component
   * @return {object}
   */
  render() {
    const {t, notice, actions} = this.props;

    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canCSVOutput: actions.includes(PERMISSION_ACTIONS.CSV_OUTPUT),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
      canDelete: actions.includes(PERMISSION_ACTIONS.DELETE),
      canRegister: actions.includes(PERMISSION_ACTIONS.REGISTER),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.caution')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Container>
        <LoadingOverlay active={notice.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                {permission.canSearch && (
                  <>
                    <br></br>
                    <Paper>
                      <Table size="small">
                        <TableHead></TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell component="th" className="product_entry_table_header_color font_color_white font_size_mid" colSpan={6}>
                              {t('caution.searchTitle')}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="table_background_color_aliceblue field_min_size_250">{t('caution.id')}</TableCell>
                            <TableCell className="table_background_color_aliceblue" colSpan={2}>
                              <TextField
                                className="field_size_10 field_min_size_150"
                                value={this.state.cautionId}
                                name="cautionId"
                                margin="dense"
                                placeholder={t('caution.id')}
                                inputProps={{maxLength: 256}}
                                onChange={(event) => onChangeTextField(this, event)}
                                variant="outlined"
                              />
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="table_background_color_aliceblue field_size_10 field_min_size_250">{t('caution.name')}</TableCell>
                            <TableCell className="table_background_color_aliceblue" colSpan={3}>
                              <TextField
                                className="field_size_10 field_min_size_350"
                                margin="dense"
                                name="cautionName"
                                value={this.state.cautionName}
                                placeholder={t('caution.name')}
                                onChange={(event) => onChangeTextField(this, event)}
                                inputProps={{maxLength: 256}}
                                variant="outlined"
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                        <TableFooter></TableFooter>
                      </Table>
                    </Paper>
                  </>
                )}
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.reset()} endIcon={<RotateLeftIcon />}>
                        {t('common.btnResetSearch')}
                      </Button>
                    )}
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {permission.canRegister && (
                      <Button
                        color="primary"
                        variant="contained"
                        className="button_margin button_color_green"
                        endIcon={<AddIcon />}
                        onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.PRODUCT_NOTICE_CREATE)}
                      >
                        {t('common.btnRegister')}
                      </Button>
                    )}
                    {permission.canCSVOutput && permission.canSearch && (
                      <>
                        <div className={this.state.totalRows <= EXPORT_ALERT_NUMBER_ROWS || this.state.confirmedExport ? '' : 'hidden'}>
                          <CSVExporter
                            ref={this.exportCSVRef}
                            disabled={this.state.totalRows <= 0 || !this.state.latestSearchParams}
                            headers={this.exportHeaders}
                            fetchAction={this.fetchDataExport}
                            isFetchAsync={true}
                            screenName={t('route.caution')}
                          />
                        </div>
                        {this.state.totalRows >= EXPORT_ALERT_NUMBER_ROWS && !this.state.confirmedExport && (
                          <Button
                            onClick={this.alertExportCSV}
                            disabled={this.state.totalRows <= 0}
                            color="primary"
                            variant="contained"
                            className="button_margin"
                            endIcon={<GetAppIcon className="csv-exporter-icon" />}
                          >
                            {t('calendar.csv_output')}
                          </Button>
                        )}
                      </>
                    )}
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin" endIcon={<SearchIcon />} onClick={() => this.handleSearchCaution(true)}>
                        {t('common.btnSearch')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>

          <br></br>
          {this.state.isSearch ? (
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    <h3>
                      {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                    </h3>
                  </Grid>
                </Grid>
                <LoadingOverlay active={notice.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                  {notice.noticeList.length > 0 ? (
                    <div className="scroll_area_700">
                      <Table size="small" aria-label="sticky table" stickyHeader className="infomation_fee layoutfix">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('caution.id')}</TableCell>
                            <TableCell>{t('caution.name')}</TableCell>
                            {(permission.canEdit || permission.canDelete) && (
                              <TableCell>{t('common.action')}</TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {notice?.noticeList?.map((row, index) => (
                            <TableRow key={index} hover className="cursor_pointer">
                              <TableCell>{row.caution_id}</TableCell>
                              <TableCell>{row.caution_name}</TableCell>
                              {(permission.canEdit || permission.canDelete) && (
                                <TableCell>
                                  {permission.canEdit && (
                                    <Link
                                      style={{textDecoration: 'none'}}
                                      to={{
                                        pathname: ROUTE.LAYOUT + ROUTE.PRODUCT_NOTICE_DETAIL + '/' + row.id,
                                        state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                      }}
                                    >
                                      <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                        {t('common.btnEdit')}
                                      </Button>
                                    </Link>
                                  )}
                                  {permission.canDelete && (
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="button_margin button_color_red"
                                      endIcon={<DeleteIcon />}
                                      onClick={() => this.handleDelete(row.id, index)}
                                    >
                                      {t('common.btnDelete')}
                                    </Button>
                                  )}
                                </TableCell>
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                        <TableFooter></TableFooter>
                      </Table>
                    </div>
                  ) : (
                    false
                  )}
                  {this.state.result.length > 0 && (
                    <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                  )}
                </LoadingOverlay>
              </Container>
              <br></br>
              <Dialog
                open={this.state.flagDelete}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '1020',
                }}
              >
                <SelectModal onClickOk={this.handleButtonOk} onClickCancel={this.handleButtonCancel} message={this.state.message}></SelectModal>
              </Dialog>
              {/* Export alert modal */}
              <Dialog
                open={this.state.flagExport}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '1020',
                }}
              >
                <SelectModal
                  onClickOk={this.handleButtonExportOk}
                  onClickCancel={this.handleButtonExportCancel}
                  okButtonText={t('common.btnYes')}
                  cancelButtonText={t('common.btnNo')}
                  message={this.state.message}
                ></SelectModal>
              </Dialog>
            </Card>
          ) : (
            false
          )}
        </LoadingOverlay>
        <br></br>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notice: state.notice,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchListCaution: (input, queryParams, props) => dispatch(searchListCaution(input, queryParams, props)),
    exportCaution: (payload, queryParams) => dispatch(exportCaution(payload, queryParams)),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    deleteCaution: (params, props) => dispatch(deleteCaution(params, props)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
