import React, {Component} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Card,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Select,
  MenuItem,
  Dialog,
  FormControl,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import DetailIcon from '@material-ui/icons/Description';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {DateTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {DATA_NULL, VEHICLE_GROUP_TYPES, ROUTE, ROWS_PER_PAGE_OPTIONS, TRANSPORTATION_TYPES} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import {getAllGeofences} from '../../../stores/geofence/action';
import {searchSimulationList, deleteSimulation} from '../../../stores/simulation/actions';
import {changeUrlParams, getQueryStringFromObject, getUrlParams, onChangeTextField, onChangeSelect} from '../../../utils/common';
import {displayDateTime} from '../../../utils/datetime';
import withPermissionData from '../../../withPermissionData/hoc';

/**
 * Simulation management
 */
class Index extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      enableFlag: false,
      flag: false,
      geofence_id: null,
      groupTypes: '',
      name: '',
      transportationType: '',
      fromTime: null,
      toTime: null,
      active: '',
      description: '',
      result: [],
      isSearch: false,
      enable: true,

      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,
      flagExport: false,
      confirmedExport: false,
      latestSearchParams: null,
      showDeleteModal: false,
      deleteId: '',
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    await this.props.getAllGeofences({targetScreen: 'ENABLE_FOR_ADMIN_ALL', page: 0, size: 99999}).then((res) => {
      if (this.props.location.search) {
        const params = getUrlParams(this.props.location.search);
        if (params.fromTime) {
          params.fromTime = new Date(params.fromTime);
        }
        if (params.toTime) {
          params.toTime = new Date(params.toTime);
        };
        this.setState(params, this.handleSearch);
      }
    });
  };

  /**
   * findGeofenceById
   * @param {*} id
   * @return {*}
   */
  findGeofenceById = (id) => {
    const objArea = this.props.listGeofences?.content.find((e) => e.geofence_id === id);
    return objArea?.name;
  };

  /**
   * reset
   */
  reset = () => {
    this.setState({
      message: '',
      enableFlag: false,
      flag: false,
      detailId: null,
      geofence_id: null,
      groupTypes: '',
      name: '',
      result: [],
      isSearch: false,
      transportationType: '',
      enable: '',
      fromTime: null,
      toTime: null,
      active: '',
      description: '',
    });
  };

  /**
   * handleDelete
   */
  handleDelete = () => {
    this.closeDeleteModal();
    this.props.deleteSimulation(this.state.deleteId).then((res) => {
      if (res?.status === 200) {
        this.handleSearch();
      }
    });
  };

  /**
   * closeDeleteModal
   */
  closeDeleteModal = () => {
    this.setState({showDeleteModal: false});
  };

  /**
   * handleSearch
   * @param {*} reset
   */
  handleSearch = (reset) => {
    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const {currentPage, rowsPerPage} = this.state;
    const {geofence_id, name, description, groupTypes, transportationType, fromTime, toTime, active, enable} = this.state;
    const payload = {
      page: currentPage,
      size: rowsPerPage,
      geofenceId: geofence_id?.geofence_id || null,
      name: name.trim() || null,
      description: description.trim() || null,
      vehicleGroupType: groupTypes || null,
      transportationType: transportationType || null,
      fromTime: fromTime ? new Date(fromTime)?.toISOString() : null,
      toTime: toTime ? new Date(toTime)?.toISOString() : null,
      active: active === '' ? null : active,
      enable,
    };
    Object.keys(payload).forEach((prop) => {
      if (payload[prop] === null) {
        delete payload[prop];
      }
    });
    this.props.searchSimulationList(payload).then((response) => {
      if (!response) {
        response = [];
      }
      this.setState(
        {
          result: response?.content,
          isSearch: true,
          totalRows: response.totalSize ? response.totalSize : 0,
          latestSearchParams: payload,
        },
        () => {
          const {geofence_id, name, description, groupTypes, transportationType, fromTime, toTime, active, enable} = this.state;
          const queryParamsToChange = {
            geofence_id: geofence_id?.geofence_id,
            name,
            description,
            groupTypes,
            transportationType,
            fromTime,
            toTime,
            enable,
            active,
            currentPage: String(currentPage),
            rowsPerPage,
          };
          changeUrlParams(queryParamsToChange);
          const newSearchParams = getQueryStringFromObject(queryParamsToChange);
          // Save search params into state in order to pass to next page
          this.setState({searchParams: newSearchParams});
        },
      );
    });
  };

  /**
   * onChangePagination
   * @param {*} currentPage
   * @param {*} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearch);
  };

  /**
   * render component
   * @return {HTML}
   */
  render() {
    const {t, simulation, listGeofences, isLoading} = this.props;

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.simulationManagement')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.DATA_MANAGEMENT}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          <Card raised>
            <Container maxWidth="xl">
              <br></br>
              <LoadingOverlay active={isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                <Paper>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell component="th" className="product_entry_table_header_color font_color_white font_size_mid" colSpan={3}>
                          {t('simulationManagement.titleSearch')}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* Transportation type */}
                      <TableRow>
                        <TableCell className="table_background_color_aliceblue field_size_20 field_min_size_100">{t('simulationManagement.transportation_type')}</TableCell>
                        <TableCell className="table_background_color_aliceblue" colSpan={3}>
                          <FormControl margin='dense' variant='outlined'>
                            <Select
                              name="transportationType"
                              variant="outlined"
                              margin="dense"
                              className="field_size_15 field_min_size_400"
                              value={this.state.transportationType}
                              onChange={(event) => onChangeSelect(this, event)}
                              displayEmpty
                              renderValue={
                                this.state.transportationType ?
                                  undefined :
                                  () => (
                                    <div className='font-12 color-disabled'>
                                      {t('placeholder.required_select', {field: t('simulationManagement.transportation_type')})}
                                    </div>
                                  )
                              }
                            >
                              {TRANSPORTATION_TYPES.map(({id, i18n}) => (
                                <MenuItem value={id} key={id}>
                                  {id !== '' ? t(i18n) : <em>{t(i18n)}</em>}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                      </TableRow>

                      {/* Vehicle group type*/}
                      <TableRow>
                        <TableCell className="table_background_color_aliceblue field_size_10 field_min_size_125">{t('simulationManagement.vehicleGroupType')}</TableCell>
                        <TableCell colSpan={2} className="table_background_color_aliceblue">
                          <FormControl margin='dense' variant='outlined' className='field_size_10 field_min_size_400'>
                            <Select
                              margin='dense'
                              variant='outlined'
                              displayEmpty
                              renderValue={
                                this.state.groupTypes ?
                                  undefined :
                                  () => (
                                    <div className='font-12 color-disabled'>
                                      {t('placeholder.required_select', {field: t('simulationManagement.vehicleGroupType')})}
                                    </div>
                                  )
                              }
                              inputProps={{name: 'groupTypes'}}
                              value={this.state.groupTypes}
                              onChange={(event) => onChangeSelect(this, event)}
                            >
                              {VEHICLE_GROUP_TYPES.map(({id, i18n}) => (
                                <MenuItem key={id} value={id}>{t(i18n)}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                      </TableRow>

                      {/* Geofence */}
                      <TableRow>
                        <TableCell className="table_background_color_aliceblue field_size_20 field_min_size_100">{t('common.geofence')}</TableCell>
                        <TableCell colSpan={3} className="table_background_color_aliceblue">
                          <Autocomplete
                            margin="dense"
                            className="field_size_10 field_min_size_400"
                            options={listGeofences?.content}
                            value={this.state.geofence_id}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, geofence_id) => this.setState({geofence_id: geofence_id})}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name={'geofence_id'}
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                placeholder={t('placeholder.required', {field: t('common.geofence')})}
                              />
                            )}
                          />
                        </TableCell>
                      </TableRow>

                      {/* Name */}
                      <TableRow>
                        <TableCell className="table_background_color_aliceblue field_size_20 field_min_size_100">{t('common.name')}</TableCell>
                        <TableCell className="table_background_color_aliceblue" colSpan={3}>
                          <TextField
                            name="name"
                            className="field_size_15 field_min_size_400"
                            placeholder={t('placeholder.required', {field: t('common.name')})}
                            margin="dense"
                            variant="outlined"
                            onChange={(event) => onChangeTextField(this, event)}
                            value={this.state.name}
                          />
                        </TableCell>
                      </TableRow>

                      {/* Description */}
                      <TableRow>
                        <TableCell className='table_background_color_aliceblue field_size_20 field_min_size_100'>{t('simulationManagement.description')}</TableCell>
                        <TableCell className='table_background_color_aliceblue' colSpan={3}>
                          <TextField
                            margin='dense'
                            variant='outlined'
                            className='field_size_15 field_min_size_400'
                            placeholder={t('placeholder.required', {field: t('simulationManagement.description')})}
                            name='description'
                            value={this.state.description}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                        </TableCell>
                      </TableRow>

                      {/* Time */}
                      <TableRow>
                        <TableCell className='table_background_color_aliceblue field_size_20 field_min_size_100'>{t('common.from')} ~ {t('common.to')}</TableCell>
                        <TableCell className='table_background_color_aliceblue' colSpan={3}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container spacing={2} alignItems='center'>
                                <Grid item>
                                  <DateTimePicker
                                    className='field_size_20 field_min_size_350'
                                    ampm={false}
                                    margin='dense'
                                    variant='inline'
                                    inputVariant='outlined'
                                    autoOk
                                    disableToolbar
                                    format='yyyy/MM/dd HH:mm'
                                    placeholder={t('common.from')}
                                    value={this.state.fromTime}
                                    onChange={(dateTime) => this.setState({fromTime: dateTime})}
                                  />
                                </Grid>
                                <Grid item style={{fontSize: 30, fontWeight: 600}}>~</Grid>
                                <Grid item>
                                  <DateTimePicker
                                    className='field_size_20 field_min_size_350'
                                    ampm={false}
                                    margin='dense'
                                    variant='inline'
                                    inputVariant='outlined'
                                    autoOk
                                    disableToolbar
                                    format='yyyy/MM/dd HH:mm'
                                    placeholder={t('common.to')}
                                    value={this.state.toTime}
                                    onChange={(dateTime) => this.setState({toTime: dateTime})}
                                  />
                              </Grid>
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </TableCell>
                      </TableRow>

                      {/* Active */}
                      <TableRow>
                        <TableCell className="table_background_color_aliceblue field_size_20 field_min_size_100">{t('common.active')}</TableCell>
                        <TableCell className="table_background_color_aliceblue" colSpan={3}>
                          <FormControl margin='dense' variant='outlined' className='field_size_15 field_min_size_400'>
                            <Select
                              margin='dense'
                              variant='outlined'
                              displayEmpty
                              renderValue={
                                this.state.active !== '' ?
                                  undefined :
                                  () => <div className='font-12 color-disabled'>{t('placeholder.required_select', {field: t('common.active')})}</div>
                              }
                              inputProps={{name: 'active'}}
                              value={this.state.active}
                              onChange={(event) => onChangeSelect(this, event)}
                            >
                              <MenuItem value={''} style={{fontStyle: 'italic'}}>{t('common.none')}</MenuItem>
                              <MenuItem value={true}>{t('common.true')}</MenuItem>
                              <MenuItem value={false}>{t('common.false')}</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                      </TableRow>

                      {/* Enable */}
                      <TableRow>
                        <TableCell className="table_background_color_aliceblue field_size_20 field_min_size_100">{t('common.enable')}</TableCell>
                        <TableCell className="table_background_color_aliceblue" colSpan={3}>
                          <FormControl margin='dense' variant='outlined'>
                            <Select
                              name="enable"
                              variant="outlined"
                              margin="dense"
                              className="field_size_15 field_min_size_400"
                              value={this.state.enable}
                              onChange={(event) => onChangeSelect(this, event)}
                              displayEmpty
                              renderValue={
                                this.state.enable !== '' ?
                                  undefined :
                                  () => (
                                    <div className='font-12 color-disabled'>
                                      {t('placeholder.required_select', {field: t('common.enable')})}
                                    </div>
                                  )
                              }
                            >
                              <MenuItem value={''} style={{fontStyle: 'italic'}}>{t('common.none')}</MenuItem>
                              <MenuItem value={true}>{t('common.true')}</MenuItem>
                              <MenuItem value={false}>{t('common.false')}</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<RotateLeftIcon />} onClick={this.reset}>
                      {t('common.btnResetSearch')}
                    </Button>
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.SIMULATION_MANAGEMENT_CREATE}}>
                      <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />}>
                        {t('common.btnRegister')}
                      </Button>
                    </Link>
                    <Button
                      color="primary"
                      variant="contained"
                      className="button_margin"
                      onClick={() => {
                        this.handleSearch(true);
                      }}
                      endIcon={<SearchIcon />}
                    >
                      {t('common.btnSearch')}
                    </Button>
                  </Grid>
                </Grid>
              </LoadingOverlay>
            </Container>
            <br></br>
          </Card>
          <br></br>
          {this.state.isSearch ? (
            <Card raised>
              <Container maxWidth="xl">
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={6}>
                    <h3>
                      {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                    </h3>
                  </Grid>
                </Grid>
                <LoadingOverlay active={simulation.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                  {this.state.result?.length > 0 ? (
                    <div className="scroll_area_700">
                      <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                        <TableHead>
                          <TableRow>
                            <TableCell className='width_150p ant-table-cell-fix-left'>{t('simulationManagement.simulation_id')}</TableCell>
                            <TableCell className='width_300p'>{t('common.name')}</TableCell>
                            <TableCell className='width_300p'>{t('common.description')}</TableCell>
                            <TableCell className='width_300p'>{t('simulationManagement.vehicleGroupType')}</TableCell>
                            <TableCell className='width_300p'>{t('common.geofence')}</TableCell>
                            <TableCell className='width_300p'>{t('simulationManagement.start_time')}</TableCell>
                            <TableCell className='width_300p'>{t('simulationManagement.end_time')}</TableCell>
                            <TableCell className='width_150p'>{t('common.enable')}</TableCell>
                            <TableCell className='width_150p' style={{position: 'sticky', right: '0'}}>{t('common.btnUpdate')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.result?.map((row, index) => (
                            <TableRow key={index} hover className="cursor_pointer">
                              <TableCell scope="row" className="cell_fixed_left">{row.simulation_id}</TableCell>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>{row.description}</TableCell>
                              <TableCell>
                                {row.vehicle_group_type ? VEHICLE_GROUP_TYPES.map(({id, i18n}) => (`${row.vehicle_group_type}` === id ? t(`${i18n}`) : '')) : DATA_NULL}
                              </TableCell>
                              <TableCell>
                                {!!row.geofence_id && this.findGeofenceById(row.geofence_id) ? (
                                  <Link to={ROUTE.LAYOUT + ROUTE.GEOFENCE_MANAGEMENT_DETAIL + `/${row.geofence_id}`} style={{textDecoration: 'none'}}>
                                    {this.findGeofenceById(row.geofence_id)}
                                  </Link>
                                ) : DATA_NULL}
                              </TableCell>
                              <TableCell>{row.start_time ? displayDateTime(row.start_time) : DATA_NULL}</TableCell>
                              <TableCell>{row.end_time ? displayDateTime(row.end_time) : DATA_NULL}</TableCell>
                              <TableCell>{row.enable.toString().toUpperCase()}</TableCell>
                              <TableCell className="cell_fixed_right">
                                <Link
                                  style={{textDecoration: 'none'}}
                                  to={{
                                    pathname: ROUTE.LAYOUT + ROUTE.SIMULATION_MANAGEMENT_DETAIL + '/' + row.id,
                                    state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                  }}
                                >
                                  <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                    {t('common.btnEdit')}
                                  </Button>
                                </Link>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  className="button_margin button_color_red"
                                  endIcon={<DeleteIcon />}
                                  onClick={() => {
                                    this.setState({
                                      deleteId: row.id,
                                      showDeleteModal: true,
                                    });
                                  }}
                                >
                                  {t('common.btnDelete')}
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  ) : (
                    false
                  )}
                  {this.state.result?.length > 0 ? (
                    <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                  ) : (
                    <></>
                  )}
                </LoadingOverlay>
                <br></br>
              </Container>
              <br></br>
            </Card>
          ) : (
            false
          )}
          <br></br>
        </Container>
        {/* modal confirm delete */}
        <Dialog
          open={this.state.showDeleteModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <SelectModal onClickOk={this.handleDelete} onClickCancel={this.closeDeleteModal} message={t('simulationManagement.confirm_delete')}></SelectModal>
        </Dialog>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    listGeofences: state.geofence.listGeofence,
    isLoading: state.geofence.isLoading,
    simulation: state.simulation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchSimulationList: (payload, props) => dispatch(searchSimulationList(payload, props)),
    deleteSimulation: (id) => dispatch(deleteSimulation(id)),
    getAllGeofences: (payload) => dispatch(getAllGeofences(payload)),
  };
};
export default withPermissionData(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
