import React, {Component} from 'react';

import {Box, Button, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';

import {DATA_NULL, ROWS_PER_PAGE_OPTIONS} from '../../../../common/constant';
import CustomPagination from '../../../../components/CustomPagination';
import {getAllCountryCode} from '../../../../stores/common/actions';
import {getAllGeofences} from '../../../../stores/geofence/action';
import {onChangeSelect, onChangeTextField} from '../../../../utils/common';

/**
 * Search Stop set Dialog
 */
class SearchGeofenceModal extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      description: '',
      countryId: '',

      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      selectData: {},
    };
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllCountryCode();
    await this.handleSearch();
    const {parentData, geofence} = this.props;
    const selectData = await geofence?.listGeofence?.content?.find((item) => item.geofence_id === parentData?.geofence_id);
    this.setState({
      selectData,
    });
  }

  /**
   * handleSearch
   * @param {Boolean} reset
   */
  handleSearch = (reset = false) => {
    const {name, description, countryId, currentPage, rowsPerPage} = this.state;
    const queryParams = {
      name,
      description,
      countryId,
      page: reset ? 0 : currentPage,
      size: reset ? ROWS_PER_PAGE_OPTIONS[0] : rowsPerPage,
      targetScreen: 'ENABLE_FOR_ADMIN_ALL',
    };
    for (const key in queryParams) {
      if (!!!queryParams[key]) delete queryParams[key];
    }
    this.props.getAllGeofences(queryParams);
    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
  };

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = async (currentPage, rowsPerPage) => {
    await this.setState({currentPage, rowsPerPage});
    await this.handleSearch();
  };

  /**
   * selectGeofence
   * @param {Object} item
   */
  selectGeofence = (item) => {
    this.setState({selectData: item});
  };

  /**
   * handleClickOk
   */
  handleClickOk = () => {
    this.props.onSelect(this.state.selectData);
    this.props.onClickOk();
  }

  /**
   * handleClickClose
   */
  handleClickClose = () => {
    this.props.onClickClose();
  }

  /**
   * render component
   * @return {HTML}
   */
  render() {
    const {t, geofence, countries} = this.props;
    const {isLoading, listGeofence} = geofence;

    return (
      <div className='field_size_90'>
        <LoadingOverlay active={isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
          <Paper>
            <Container maxWidth="xl">
              <Box p={1} m={1}>
                <Container maxWidth="xl">
                  <Box p={2} m={2}>
                    <Grid container alignItems="flex-start">
                      <Grid item xs={12}>
                        <h1 className="product_entry_table_header_color font_color_white font_size_mid title">{t('common.geofence')}</h1>
                        <Grid container alignItems="flex-start">
                          <Grid item xs={12}>
                            <Grid container spacing={1} alignItems="center">
                              <Grid item xs={2}>
                                <TextField
                                  className="width_100"
                                  name="name"
                                  margin="dense"
                                  label={t('common.name')}
                                  variant="outlined"
                                  value={this.state.name}
                                  onChange={(event) => onChangeTextField(this, event)}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  className="width_100"
                                  name="description"
                                  margin="dense"
                                  label={t('simulationManagement.description')}
                                  variant="outlined"
                                  value={this.state.description}
                                  onChange={(event) => onChangeTextField(this, event)}
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <FormControl variant="outlined" fullWidth margin="dense">
                                  <InputLabel>{t('common.country')}</InputLabel>
                                  <Select
                                    labelWidth={165}
                                    margin="dense"
                                    value={this.state.countryId}
                                    onChange={(event) => onChangeSelect(this, event)}
                                    inputProps={{
                                      name: 'countryId',
                                    }}
                                  >
                                    {countries.map((item, index) => {
                                      return (
                                        <MenuItem value={item.id} key={index}>
                                          {t(item.country_code)}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={2}>
                                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<SearchIcon />} onClick={() => this.handleSearch(true)}>
                                  {t('common.btnSearch')}
                                </Button>
                              </Grid>
                            </Grid>
                            {listGeofence?.content?.length > 0 && (
                              <Grid container alignItems="flex-start">
                                <div className="scroll_area_400">
                                  <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell className="width_100p">{t('geofence.geofence_id')}</TableCell>
                                        <TableCell className="width_50p">{t('common.country')}</TableCell>
                                        <TableCell className="width_100p">{t('common.name')}</TableCell>
                                        <TableCell className="width_150p">{t('geofence.description')}</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {listGeofence?.content?.map((item, index) => {
                                        return (
                                          <TableRow
                                            key={index}
                                            onClick={() => this.selectGeofence(item)}
                                            className={this.state.selectData?.geofence_id === item.geofence_id ?
                                              'row_form_item select_modal_item cursor_pointer' : 'row_form_item cursor_pointer'
                                            }
                                          >
                                            <TableCell>{item.geofence_id}</TableCell>
                                            <TableCell>{t(item.country?.country_code)}</TableCell>
                                            <TableCell>{item.name ? item.name : DATA_NULL}</TableCell>
                                            <TableCell>{item.description ? item.description : DATA_NULL}</TableCell>
                                          </TableRow>
                                        );
                                      })}
                                    </TableBody>
                                  </Table>
                                </div>
                                <CustomPagination
                                  onChange={this.onChangePagination}
                                  rows={listGeofence?.totalSize}
                                  rowsPerPage={this.state.rowsPerPage}
                                  currentPage={this.state.currentPage}
                                />
                              </Grid>
                            )}
                            {listGeofence?.content?.length < 0 && <h5 className="text_grey">{t('reservationManagement.no_result')}</h5>}
                          </Grid>
                        </Grid>
                        <br />
                        <Grid container alignItems="flex-start">
                          <Grid container justify="center" alignItems="center" item xs={12}>
                            <Button variant="contained" className="button_margin" color="primary" onClick={this.handleClickOk}>
                              {t('common.btnOk')}
                            </Button>
                            <Button variant="contained" className="button_color button_magin" style={{marginLeft: 5}} color="primary" onClick={this.handleClickClose}>
                              {t('common.btnCancel')}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              </Box>
            </Container>
          </Paper>
        </LoadingOverlay>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    geofence: state.geofence,
    countries: state.common.country_code,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllGeofences: (queryParams) => dispatch(getAllGeofences(queryParams)),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(SearchGeofenceModal));
