import React from 'react';

import {Button, CircularProgress} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import format from 'date-fns/format';
import {CSVLink} from 'react-csv';
import {withTranslation} from 'react-i18next';
import './styles.css';

/**
 * CSVExporter
 */
class CSVExporter extends React.Component {
  /**
   *
   * @param {object} props
   * @param {object[] | [][]} props.data Data to export
   * @param {{label: string, key: string}[]} props.headers Replace field name
   * @param {string} props.screenName Screen name. Example: "Delivery List"
   * @param {boolean} props.disabled Set if button is disabled
   * @param {boolean} props.isFetchAsync Need to call api to get data?
   * @param {Promise<[]>} props.fetchAction Need to call api to get data? Return data
   */
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      csvData: [],
    };
    this.csvInstance = React.createRef();
  }

  /**
   * Handle click export
   * @param {Event} event
   */
  onClickExport = (event) => {
    if (this.props.disabled) return;
    // If not isFetchAsync or disabled
    if (!this.props.isFetchAsync || this.props.disabled) {
      return;
    } else {
      this.setState({loading: true});
      this.props.fetchAction().then((data) => {
        data &&
          this.setState({csvData: data}, () => {
            // Wait for applying new data
            setTimeout(() => {
              this.state.loading && this.csvInstance.current.link.click();
              this.setState({loading: false, csvData: []});
            }, 100);
          });
      });
    }
  };

  /**
   * render
   * @return {HTMLElement}
   */
  render() {
    const {data, headers, disabled, screenName, t, isFetchAsync} = this.props;
    const {loading, csvData} = this.state;
    return (
      <div className={disabled || loading ? 'link-disabled' : ''} onClick={this.onClickExport}>
        <CSVLink
          ref={this.csvInstance}
          onClick={(event) => {
            if (isFetchAsync && csvData?.length === 0) {
              return false;
            } else {
              event.stopPropagation();
            }
          }}
          className={`csv-exporter-link ${(disabled || loading) && 'link-disabled'}`}
          data={isFetchAsync ? csvData : data}
          headers={headers}
          filename={`${format(new Date(), 'yyyyMMdd')}_[${screenName}].csv`}
        >
          <Button
            color="primary"
            variant="contained"
            className="button_margin"
            endIcon={loading ? <CircularProgress color="inherit" size={16} /> : <GetAppIcon className="csv-exporter-icon" />}
            disabled={disabled || loading}
          >
            {t('calendar.csv_output')}
          </Button>
        </CSVLink>
      </div>
    );
  }
}

export default withTranslation('translations', {withRef: true})(CSVExporter);
