import {ROUTE} from '../../common/constant';
import {
  createDriverApi,
  getDetailDriverApi,
  getListDriverApi,
  updateDriverApi,
  deleteDriverApi,
  importDriverCSVApi,
  getDriverRatingApi,
} from '../../services/driverServices';
import {backForwardRouter} from '../../utils/common';
import {modalObj} from '../../utils/modal.js';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

/**
 * search list driver
 * @param {object} params
 * @param {object} queryParams
 * @param {object} props
 * @return {object}
 */
export const searchListDriver = (params, queryParams) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SEARCH_LIST_DRIVER_START,
    });
    return getListDriverApi(params, queryParams).then(
      (response) => {
        if (response && response.status === 200) {
          if (response.result.content.length > 0) {
            dispatch({
              type: ACTION_TYPES.SEARCH_LIST_DRIVER_SUCCESS,
              listDriver: response.result,
            });
            return response.result;
          } else {
            dispatch({
              type: ACTION_TYPES.SEARCH_LIST_DRIVER_FAIL,
            });
          }
        } else {
          dispatch(setMessageModal(modalObj(true, response.message)));
          dispatch({
            type: ACTION_TYPES.SEARCH_LIST_DRIVER_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.SEARCH_LIST_DRIVER_FAIL,
        }),
    );
  };
};

/**
 * Export list driver
 * @param {object} payload
 * @param {object} queryParams
 * @return {object}
 */
export const exportDriver = (payload, queryParams) => {
  return (dispatch) => {
    return getListDriverApi(payload, queryParams).then(
      (response) => {
        if (response && response.status === 200) {
          return response.result?.content;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message)));
        }
      },
      () => dispatch(setMessageModal(modalObj(true, 'Internal Server Error'))),
    );
  };
};

/**
 * get detail driver
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const getDetailDriver = (params, props) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_DETAIL_DRIVER_START,
    });
    return getDetailDriverApi(params).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_DETAIL_DRIVER_SUCCESS,
            detailDriver: response.result,
          });
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message)));
          dispatch({
            type: ACTION_TYPES.GET_DETAIL_DRIVER_FAIL,
          });
          if (response.message_code === 'permission.denied') {
            backForwardRouter(props, ROUTE.LAYOUT + ROUTE.HOME);
          }
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_DETAIL_DRIVER_FAIL,
        }),
    );
  };
};

/**
 * create new driver
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const createDriver = (params, props) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.CREATE_DRIVER_START,
    });
    return createDriverApi(params).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch(setMessageModal(modalObj(true, response.result.message_code)));
          dispatch({
            type: ACTION_TYPES.CREATE_DRIVER_SUCCESS,
          });
          props.history.push(ROUTE.LAYOUT + ROUTE.DRIVER_INFORMATION);
        } else {
          dispatch(setMessageModal(modalObj(true, response.result.message_code)));
          dispatch({
            type: ACTION_TYPES.CREATE_DRIVER_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.CREATE_DRIVER_FAIL,
        }),
    );
  };
};

/**
 * update new driver
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const updateDriver = (params, props) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_DRIVER_START,
    });
    return updateDriverApi(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.result.message_code)));
            dispatch({
              type: ACTION_TYPES.UPDATE_DRIVER_SUCCESS,
            });
          } else if (response && response?.message_code) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.UPDATE_DRIVER_FAIL,
            });
          }
        },
        () =>
          dispatch({
            type: ACTION_TYPES.UPDATE_DRIVER_FAIL,
          }),
    );
  };
};

/**
 * delete new driver
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const deleteDriver = (params) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.DELETE_DRIVER_START,
    });
    return deleteDriverApi(params).then(
      (response) => {
        if (response?.result && response.status === 200) {
          dispatch(setMessageModal(modalObj(true, response.result.message_code)));
          dispatch({
            type: ACTION_TYPES.DELETE_DRIVER_SUCCESS,
          });
        } else {
          dispatch(setMessageModal(modalObj(true, response.result.message_code)));
          dispatch({
            type: ACTION_TYPES.DELETE_DRIVER_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.DELETE_DRIVER_FAIL,
        }),
    );
  };
};

/**
 * import csv
 * @param {object} payload
 * @param {object} props
 * @return {object}
 */
export const importDriverCSV = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.IMPORT_DRIVER_CSV_START,
    });
    return new Promise((resolve, reject) => {
      importDriverCSVApi(payload).then(
        (response) => {
          if (response?.result && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.IMPORT_DRIVER_CSV_SUCCESS,
            });
          } else {
            dispatch({
              type: ACTION_TYPES.IMPORT_DRIVER_CSV_FAIL,
            });
          }
          window.setTimeout(function() {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            return resolve(response);
          }, 30000);
        },
        (error) => {
          dispatch({
            type: ACTION_TYPES.IMPORT_DRIVER_CSV_FAIL,
          });
          return reject(error);
        },
      );
    });
  };
};

/**
 * get driver rating
 * @param {Number} id
 * @return {object}
 */
export const getDriverRating = (id) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_DRIVER_RATING_START,
    });
    return getDriverRatingApi(id).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_DRIVER_RATING_SUCCESS,
            driverRating: response.result,
          });
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message)));
          dispatch({
            type: ACTION_TYPES.GET_DRIVER_RATING_FAIL,
            driverRating: {},
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_DRIVER_RATING_FAIL,
          driverRating: {},
        }),
    );
  };
};
