import React, {Component} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Card,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Dialog,
  FormHelperText,
} from '@material-ui/core';
import {Search as SearchIcon, Description as DetailIcon, ArrowBack as ArrowBackIcon, RotateLeft as RotateLeftIcon} from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {format} from 'date-fns';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {ROUTE, DATA_NULL, ROWS_PER_PAGE_OPTIONS, PERMISSION_ACTIONS, DATE_FORMAT, DISPLAY_COUPON_STATUS, COUPON_TYPE} from '../../../common/constant';
import CSVExporter from '../../../components/CSVExporter/NewCSVExporter';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getAllCountryCode, getAllGeofence} from '../../../stores/common/actions';
import {deleteCoupon, searchListCoupon} from '../../../stores/coupon/action';
import {setMessageModal} from '../../../stores/modal/actions';
import {onChangeSelect, onChangeTextField, getQueryStringFromObject, getUrlParams, changeUrlParams, backForwardRouter} from '../../../utils/common';
import {compareDateRange, roundedAndConvertTime, roundedAndConvertTimeByZoneId} from '../../../utils/datetime';
import {modalObj} from '../../../utils/modal';
import './style.css';
import {isRoleGlobal} from '../../../utils/role';

/**
 * Coupon Search Component
 */
class Index extends Component {
  /**
   * constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      country_code: '',
      geofence_id: '',
      coupon_name: '',
      shop_name: '',
      start_date: null,
      end_date: null,
      waypoint_nearest: '',
      is_active: null,
      type: null,
      zone_id: null,
      country_id: null,
      flagDelete: false,
      coupon_id_del: null,

      searchResult: [],
      isSearch: false,
      loading: false,
      searchParams: this.props.location.search,
      latestSearchParams: null,
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    await this.props.getAllCountryCode();
    await this.props.getAllGeofence().then(() => this.setState({listGeofenceAll: this.props.common?.all_geofence}));
    const {common, principal} = this.props;
    if (!isRoleGlobal() && principal) {
      const country = common.country_code.find((item) => item.id === principal?.country_id);
      this.setState({
        country_code: country?.country_code,
        country_id: country?.id,
      });
    }
    if (this.props.location.search && principal) {
      const params = getUrlParams(this.props.location.search);
      if (isRoleGlobal() ? true : Number(params.country_id) === Number(principal.country_id)) {
        this.setState(params, () => {
          if (this.state.country_code) {
            const country_id = common?.country_code?.find((e) => e.country_code === this.state.country_code)?.id;
            this.setState({
              listGeofenceAll: common?.all_geofence?.filter((e) => e.country_id === Number(country_id)),
            });
          }
          this.handleSearch();
        });
      } else backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.HOME);
    }
  };

  /**
   * handleOnChangeCountry
   * @param {*} value
   */
  handleOnChangeCountry = (value) => {
    const country_code = this.props.common?.country_code?.find((e) => e.country_code === value)?.country_code;
    this.setState({
      country_id: value,
      country_code: country_code,
      geofence_id: '',
      listGeofenceAll: this.props.common?.all_geofence?.filter((e) => e.country_id === value),
    });
  };

  /**
   * resetSearch
   */
  resetSearch() {
    this.setState({
      country_code: '',
      country_id: !isRoleGlobal() ? this.props.principal.country_id : '',
      geofence_id: '',
      shop_name: '',
      coupon_name: '',
      type: null,
      start_date: null,
      end_date: null,
      waypoint_nearest: '',
      is_active: null,
      listGeofenceAll: this.props.common?.all_geofence,
    });
  }

  /**
   * onChangePagination
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearch);
  };

  /**
   * handleSearch
   * @param {*} reset
   */
  async handleSearch(reset) {
    this.setState({loading: true});
    const {country_id, geofence_id, shop_name, coupon_name, type, start_date, end_date, waypoint_nearest, is_active, currentPage, rowsPerPage} = this.state;
    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const payload = {
      country_id,
      geofence_id,
      shop_name: shop_name.trim(),
      coupon_name: coupon_name.trim(),
      type,
      start_date: start_date ? format(new Date(start_date), 'yyyy-MM-dd') : null,
      end_date: end_date ? format(new Date(end_date), 'yyyy-MM-dd') : null,
      waypoint_nearest: waypoint_nearest.trim(),
      is_active,
      // eslint-disable-next-line new-cap
      zone_id: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    const queryParams = reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage};
    const listCoupon = await this.props.searchListCoupon(payload, queryParams);
    if (listCoupon) {
      this.setState(
        {
          searchResult: listCoupon.content,
          totalRows: listCoupon.totalSize,
          isSearch: true,
          latestSearchParams: payload,
        },
        () => {
          const {country_id, geofence_id, shop_name, coupon_name, type, start_date, end_date, waypoint_nearest, is_active, currentPage, rowsPerPage} = this.state;
          // Apply changed params into url
          const queryParamsToChange = {
            country_id,
            geofence_id,
            shop_name,
            coupon_name,
            type,
            start_date,
            end_date,
            waypoint_nearest,
            is_active,
            currentPage: String(currentPage),
            rowsPerPage,
          };
          changeUrlParams(queryParamsToChange);
          const newSearchParams = getQueryStringFromObject(queryParamsToChange);
          // Save search params into state in order to pass to next page
          this.setState({searchParams: newSearchParams});
        },
      );
    }
    this.setState({loading: false});
  }

  /**
   * handleDelete
   * @param {string} id
   */
  handleDelete = (id) => {
    this.setState({
      flagDelete: true,
      message: 'coupon.question.confirm.delete',
      coupon_id_del: id,
    });
  };

  /**
   * handleButtonCancel
   */
  handleButtonCancel = () => {
    this.setState({
      flagDelete: false,
    });
  };

  /**
   * handleButtonOk
   */
  handleButtonOk = () => {
    if (this.state.message === '') {
      this.setState({
        flagDelete: false,
      });
    } else {
      this.setState({
        flagDelete: false,
      });
      this.props.deleteCoupon(this.state.coupon_id_del, this.props).then(() => {
        this.handleSearch(true);
      });
    }
  };
  /**
   * notifyResponse
   * @param {string} message
   */
  notifyResponse = (message) => {
    this.props.setMessageModal(modalObj(true, message));
  };

  /**
   * Render Component
   * @return {HTML}
   */
  render() {
    const {t, common, actions} = this.props;
    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canCSVOutput: actions.includes(PERMISSION_ACTIONS.CSV_OUTPUT),
      canRegister: actions.includes(PERMISSION_ACTIONS.REGISTER),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
      canDelete: actions.includes(PERMISSION_ACTIONS.DELETE),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.couponManagement')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Container>
        <br />
        <LoadingOverlay active={this.state.loading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                {permission.canSearch && (
                  <>
                    <br />
                    <Paper className="search_table">
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                          {t('businessPartner.searchTitle')}
                        </Grid>
                      </Grid>
                      {/* Country */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                          {t('common.country')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'country_id',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.country_id ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('common.country'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.country_id}
                              onChange={(event) => this.handleOnChangeCountry(event.target.value)}
                              disabled={!isRoleGlobal()}
                            >
                              {common?.country_code?.map((item, idx) => (
                                <MenuItem value={item.id} key={idx}>
                                  {t(`${item.country_code}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Geofence */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                          {t('common.geofence')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'geofence_id',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.geofence_id ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('common.geofence'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.geofence_id}
                              onChange={(e) => onChangeSelect(this, e)}
                            >
                              {this.state.listGeofenceAll?.map((item, idx) => (
                                <MenuItem value={item.geofence_id} key={idx} className={!item.enable ? 'disable-option-custom' : ''}>
                                  {t(`${item.name}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      {/* Shop Name */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                          {t('coupon.shopName')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="shop_name"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            multiline
                            placeholder={t('placeholder.required', {field: t('coupon.shopName')})}
                            value={this.state.shop_name}
                            inputProps={{maxLength: 30}}
                            onChange={(event) => onChangeTextField(this, event)}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>

                      {/* Coupon Name */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                          {t('coupon.couponName')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="coupon_name"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            multiline
                            placeholder={t('placeholder.required', {field: t('coupon.couponName')})}
                            value={this.state.coupon_name}
                            onChange={(event) => onChangeTextField(this, event)}
                            inputProps={{maxLength: 30}}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      {/* Coupon Type */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                          {t('coupon.couponType')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'type',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.type ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('coupon.couponType'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.type || ''}
                              onChange={(event) => onChangeSelect(this, event)}
                            >
                              {COUPON_TYPE.map((item, idx) => {
                                return (
                                  <MenuItem value={item.value} key={idx}>
                                    {t(`${item.i18n}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Use Time */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                          {t('coupon.useTime')}
                        </Grid>
                        <Grid alignItems="center" item xs={6} lg={6}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              autoOk
                              disableToolbar
                              variant="inline"
                              inputVariant="outlined"
                              placeholder={t('coupon.useTimeStart')}
                              format={DATE_FORMAT}
                              value={this.state.start_date}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              onChange={(time) =>
                                this.setState({
                                  start_date: time,
                                })
                              }
                            />
                            <span style={{padding: '5px'}}></span>
                            <KeyboardDatePicker
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              autoOk
                              disableToolbar
                              variant="inline"
                              placeholder={t('coupon.useTimeEnd')}
                              inputVariant="outlined"
                              format={DATE_FORMAT}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              value={this.state.end_date}
                              onChange={(time) => this.setState({end_date: time})}
                            />
                          </MuiPickersUtilsProvider>
                          <Grid>
                            {!compareDateRange(this.state.start_date, this.state.end_date) && (
                              <FormHelperText id="start_time" error>
                                {t('validation.invalid.timeRange')}
                              </FormHelperText>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* Nearest Waypoint */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                          {t('coupon.nearestWaypoint')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="waypoint_nearest"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            multiline
                            placeholder={t('placeholder.required', {field: t('coupon.nearestWaypoint')})}
                            value={this.state.waypoint_nearest}
                            onChange={(event) => onChangeTextField(this, event)}
                            inputProps={{maxLength: 100}}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      {/* Display on app */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                          {t('coupon.displayOnApp')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'is_active',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.is_active || this.state.is_active === false ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('coupon.displayOnApp'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.is_active}
                              onChange={(e) => onChangeSelect(this, e)}
                            >
                              {DISPLAY_COUPON_STATUS?.map((item, idx) => (
                                <MenuItem value={item.id} key={idx}>
                                  {t(item.i18n)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Paper>
                  </>
                )}
                <br />
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.resetSearch()} endIcon={<RotateLeftIcon />}>
                        {t('common.btnResetSearch')}
                      </Button>
                    )}
                  </Grid>
                  <Grid container alignItems="center" justifyContent="flex-end" item xs={9}>
                    {permission.canSearch && permission.canCSVOutput && (
                      <>
                        <CSVExporter
                          url={'admin/csv-export/coupon-usage'}
                          params={this.state.latestSearchParams}
                          disabled={this.state.totalRows === 0}
                          buttonText={'coupon.csvExportCouponUsage'}
                        />
                        <CSVExporter
                          url={'admin/csv-export/coupon'}
                          params={this.state.latestSearchParams}
                          disabled={this.state.totalRows === 0}
                          buttonText={'coupon.csvExportCoupon'}
                        />
                      </>
                    )}
                    {permission.canRegister && (
                      <Button
                        color="primary"
                        variant="contained"
                        endIcon={<AddIcon />}
                        onClick={() => this.props.history.push(ROUTE.LAYOUT + ROUTE.COUPON_ADD)}
                        className="button_margin button_color_green"
                      >
                        {t('common.btnRegister')}
                      </Button>
                    )}
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin" endIcon={<SearchIcon />} onClick={() => this.handleSearch(true)}>
                        {t('common.btnSearch')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Container>
              <br />
            </Card>
            <br />
          </Container>
          <Container maxWidth="xl">
            {permission.canSearch && this.state.isSearch && (
              <Card raised>
                <Container maxWidth="xl">
                  <br />
                  <Grid container spacing={1}>
                    <Grid container alignItems="center" justify="flex-start" item xs={3}>
                      <h3>
                        {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                      </h3>
                    </Grid>
                  </Grid>

                  <div className="scroll_area_700">
                    {this.state.totalRows > 0 && (
                      <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                        <TableHead>
                          <TableRow>
                            <TableCell className="ant-coupon-table-cell-fix-left width_150p">{t('coupon.couponCode')}</TableCell>
                            <TableCell className="width_125p">{t('common.country')}</TableCell>
                            <TableCell className="width_150p">{t('common.geofence')}</TableCell>
                            <TableCell className="width_150p">{t('coupon.shopNameJp')}</TableCell>
                            <TableCell className="width_150p">{t('coupon.shopNameVn')}</TableCell>
                            <TableCell className="width_150p">{t('coupon.shopNameEn')}</TableCell>
                            <TableCell className="width_150p">{t('coupon.couponNameJp')}</TableCell>
                            <TableCell className="width_150p">{t('coupon.couponNameVn')}</TableCell>
                            <TableCell className="width_150p">{t('coupon.couponNameEn')}</TableCell>
                            <TableCell className="width_150p">{t('coupon.couponType')}</TableCell>
                            <TableCell className="width_150p">{t('coupon.createdDate')}</TableCell>
                            <TableCell className="width_150p">{t('coupon.useTimeStart')}</TableCell>
                            <TableCell className="width_150p">{t('coupon.useTimeEnd')}</TableCell>
                            <TableCell className="width_150p">{t('coupon.nearestWaypointJp')}</TableCell>
                            <TableCell className="width_150p">{t('coupon.nearestWaypointVn')}</TableCell>
                            <TableCell className="width_150p">{t('coupon.nearestWaypointEn')}</TableCell>
                            <TableCell className="width_125p">{t('coupon.displayStatus')}</TableCell>
                            {(permission.canEdit || permission.canDelete) && (
                              <TableCell className="width_225p" style={{position: 'sticky', right: '0'}}>
                                {t('common.action')}
                              </TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.searchResult.map((row, index) => {
                            const coupon_languages = row?.coupon_languages?.sort((a, b) => a.language_id - b.language_id);
                            return (
                              <TableRow key={index} hover className="cursor_pointer">
                                <TableCell className="cell_fixed_left">{row?.coupon_code || DATA_NULL}</TableCell>
                                <TableCell>{t(common.country_code?.find((item) => item.id === row.country_id)?.country_code) || DATA_NULL}</TableCell>
                                <TableCell>{row?.geofence_name || DATA_NULL}</TableCell>
                                <TableCell>{coupon_languages[0]?.shop_name}</TableCell>
                                <TableCell>{coupon_languages[2]?.shop_name}</TableCell>
                                <TableCell>{coupon_languages[1]?.shop_name}</TableCell>
                                <TableCell className="limit_line">{coupon_languages[0]?.coupon_name || DATA_NULL}</TableCell>
                                <TableCell className="limit_line">{coupon_languages[2]?.coupon_name || DATA_NULL}</TableCell>
                                <TableCell className="limit_line">{coupon_languages[1]?.coupon_name || DATA_NULL}</TableCell>
                                <TableCell className="limit_line">{COUPON_TYPE[0].value === row?.type ? t(COUPON_TYPE[0].i18n) : t(COUPON_TYPE[1].i18n)}</TableCell>
                                <TableCell className="limit_line">{roundedAndConvertTime(row?.created_date, row?.country_id) || DATA_NULL}</TableCell>
                                <TableCell className="limit_line">{roundedAndConvertTimeByZoneId(row?.start_date, row?.zone_id, 'yyyy-MM-DD') || DATA_NULL}</TableCell>
                                <TableCell className="limit_line">{roundedAndConvertTimeByZoneId(row?.end_date, row?.zone_id, 'yyyy-MM-DD') || DATA_NULL}</TableCell>
                                <TableCell className="limit_line">{coupon_languages[0]?.waypoint_nearest || DATA_NULL}</TableCell>
                                <TableCell className="limit_line">{coupon_languages[2]?.waypoint_nearest || DATA_NULL}</TableCell>
                                <TableCell className="limit_line">{coupon_languages[1]?.waypoint_nearest || DATA_NULL}</TableCell>
                                <TableCell className="limit_line">{row?.is_active ? t(DISPLAY_COUPON_STATUS[0].i18n) : t(DISPLAY_COUPON_STATUS[1].i18n)}</TableCell>
                                <TableCell className="cell_fixed_right">
                                  <Grid style={{display: 'flex'}}>
                                    {permission.canEdit && (
                                      <Link
                                        style={{textDecoration: 'none'}}
                                        to={{
                                          pathname: ROUTE.LAYOUT + ROUTE.COUPON_DETAIL + '/' + row.id,
                                          state: {
                                            from: this.props.location.pathname + '?' + this.state.searchParams,
                                            coupon_id: row?.id,
                                          },
                                        }}
                                      >
                                        <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                          {t('common.btnDetail')}
                                        </Button>
                                      </Link>
                                    )}
                                    {permission.canDelete && (
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        className="button_margin button_color_red"
                                        endIcon={<DeleteIcon />}
                                        onClick={() => this.handleDelete(row.id)}
                                      >
                                        {t('common.btnDelete')}
                                      </Button>
                                    )}
                                  </Grid>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    )}
                  </div>
                  {this.state.totalRows > 0 && (
                    <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                  )}
                </Container>
                <br />
              </Card>
            )}
            <br />
          </Container>
          <Dialog
            open={this.state.flagDelete}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal
              onClickOk={this.handleButtonOk}
              onClickCancel={this.handleButtonCancel}
              message={this.state.message}
              okButtonText={t('common.btnYes')}
              cancelButtonText={t('common.btnNo')}
            ></SelectModal>
          </Dialog>
        </LoadingOverlay>
        <br />
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    couponList: state.coupon,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getAllGeofence: () => dispatch(getAllGeofence()),
    searchListCoupon: (payload, queryParams) => dispatch(searchListCoupon(payload, queryParams)),
    deleteCoupon: (id, props) => dispatch(deleteCoupon(id, props)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
