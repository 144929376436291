import {ROUTE} from '../../common/constant';
import {
  loginApi,
  userDetailApi,
  registrationApi,
  passwordReissueApi,
  verifyPasswordReissueApi,
  newPasswordReissueApi,
  changePasswordAPI,
} from '../../services/authenServices';
import {getUrlParams} from '../../utils/common';
import {modalObj} from '../../utils/modal.js';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

/**
 * Login
 * @param {string} email
 * @param {string} password
 * @param {object} props
 * @return {object}
 */
export const login = (email, password, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.LOGIN_START});
    const body = {
      email: email,
      password: password,
    };
    loginApi(body).then(
        (response) => {
          if (response && response.status === 200) {
            localStorage.setItem('TOKEN_KEY', response.result.token_type + ' ' + response.result.access_token);
            localStorage.setItem('USER_NAME', response.result.person_charge);
            localStorage.setItem('ADMIN_ID', response.result.username);
            localStorage.setItem('USER_ROLE', response.result.permission_name);
            localStorage.setItem('refresh_token', response.result.refresh_token);
            localStorage.setItem('permission_id', response.result.permission_id);

            dispatch({
              type: ACTION_TYPES.LOGIN_SUCCESS,
              objectUser: response.result,
            });
            const queryParams = getUrlParams(props.location.search);
            if (queryParams.callbackUrl) {
              props.history.push(atob(queryParams.callbackUrl));
            } else {
              props.history.push(ROUTE.LAYOUT + ROUTE.HOME);
            }
          } else {
            dispatch({type: ACTION_TYPES.LOGIN_FAIL});
            dispatch(setMessageModal(modalObj(true, response.message_code)));
          }
        },
        () => {
          dispatch({type: ACTION_TYPES.LOGIN_FAIL});
        },
    );
  };
};

/**
 * User Detail
 * @param {string} uUId
 * @return {object}
 */
export const userDetail = (uUId) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.USER_DETAIL_START});
    return userDetailApi(uUId).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.USER_DETAIL_SUCCESS,
              userDetail: response.result,
            });
            return response.result;
          } else {
            dispatch({
              type: ACTION_TYPES.USER_DETAIL_FAIL,
              error: response?.message,
            });
          }
        },
        () => {
          dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
          dispatch({
            type: ACTION_TYPES.USER_DETAIL_FAIL,
            error: 'Internal Server Error',
          });
        },
    );
  };
};

/**
 * Registration
 * @param {object} payload
 * @param {object} props
 * @return {object}
 */
export const registration = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.REGISTER_START});
    registrationApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.REGISTER_SUCCESS});
            window.location.replace(ROUTE.LAYOUT + ROUTE.LOGIN);
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.REGISTER_FAIL});
          }
        },
        (error) => {
          dispatch(setMessageModal(modalObj(true, error.message)));
          dispatch({type: ACTION_TYPES.REGISTER_FAIL});
        },
    );
  };
};

/**
 * sendMailPasswordReissue
 * @param {string} email
 * @param {object} props
 * @return {object}
 */
export const sendMailPasswordReissue = (email) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.PASSWORD_REISSUE_START});
    passwordReissueApi(email).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.PASSWORD_REISSUE_SUCCESS,
              objectUser: response.result,
            });
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
            type: ACTION_TYPES.PASSWORD_REISSUE_FAIL,
            error: 'Internal Server Error',
          });
          }
        },
        (error) => {
          dispatch(setMessageModal(modalObj(true, error.message)));
          dispatch({
            type: ACTION_TYPES.PASSWORD_REISSUE_FAIL,
            error: 'Internal Server Error',
          });
        },
    );
  };
};

/**
 * verifyReissuePassword
 * @param {string} reissueToken
 * @param {object} props
 * @return {object}
 */
export const verifyReissuePassword = (reissueToken) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.PASSWORD_REISSUE_CONFIRM_START});
    return verifyPasswordReissueApi(reissueToken).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.PASSWORD_REISSUE_CONFIRM_SUCCESS,
              objectUser: response.result,
            });
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.PASSWORD_REISSUE_CONFIRM_FAIL,
              error: 'Internal Server Error',
            });
          }
        },
        (error) => {
          dispatch(setMessageModal(modalObj(true, error.message_code)));
          dispatch({
            type: ACTION_TYPES.PASSWORD_REISSUE_CONFIRM_FAIL,
            error: 'Internal Server Error',
          });
        },
    );
  };
};

/**
 * newPasswordReissue
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const newPasswordReissue = (params) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.NEW_PASSWORD_REISSUE_START});
    return newPasswordReissueApi(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.NEW_PASSWORD_REISSUE_SUCCESS,
              objectUser: response.result,
            });
            window.location.replace(ROUTE.LAYOUT + ROUTE.LOGIN);
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.NEW_PASSWORD_REISSUE_FAIL,
              error: 'Internal Server Error',
            });
          }
        },
        (error) => {
          dispatch(setMessageModal(modalObj(true, error.message_code)));
          dispatch({
            type: ACTION_TYPES.NEW_PASSWORD_REISSUE_FAIL,
            error: 'Internal Server Error',
          });
        },
    );
  };
};

/**
 * change password
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const changePassword = (params) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.CHANGE_PASSWORD_START});
    return changePasswordAPI(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({type: ACTION_TYPES.CHANGE_PASSWORD_SUCCESS});
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            return response.result;
          } else {
            dispatch({type: ACTION_TYPES.CHANGE_PASSWORD_FAIL});
            dispatch(setMessageModal(modalObj(true, response.message_code)));
          }
        },
        () => dispatch({type: ACTION_TYPES.CHANGE_PASSWORD_FAIL}),
    );
  };
};

