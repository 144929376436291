import React, {Component} from 'react';

import {Card, Container, Grid, Box, Button, Table, TableCell, TableRow, TableBody, TableHead, TableFooter, Dialog} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import _ from 'lodash';
import mqtt from 'mqtt';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';

import {
  BOOKING_ADVANCE_STATUS,
  BOOKING_MOBI_TYPES,
  BOOKING_MSG_TYPE,
  BOOKING_STATUS_CHECK,
  CONFIG_TIME,
  DATA_NULL,
  DATE_TIME_SECOND_FORMAT_MOMENT,
  DISCOUNT_TYPE,
  GROUP_TYPES_BOOKING_JIT,
  ODM_BOOKING_TIME_TYPES,
  PAYMENT_METHODS,
  ROUTE,
  SERVICE_TYPE,
  SPECIAL_PERSON_TYPE,
} from '../../../../common/constant';
import SelectModal from '../../../../components/selectModal';
import {
  booking,
  manageBooking,
  cancelBooking,
  listUserOrder,
  addToCart,
  deliveryAddress,
  bookingDelivery,
  // sendMailBooking,
  estimateCostJitForUserApi,
  checkingWayPointSuspensionApi,
  advanceBooking,
  bookingHistoryAdvance,
  getStatusBookingApi,
} from '../../../../services/reservationServices';
import {removeAllItems} from '../../../../stores/carts/actions';
import {setMessageModal} from '../../../../stores/modal/actions';
import {bookingJit, updateBooking} from '../../../../stores/reservation/actions';
import {calculatePercentDiscountForFeeBreakDown, customDisplayCurrency, redirectRouter} from '../../../../utils/common';
import {convertPrefixTime, roundedAndConvertTimeByZoneId} from '../../../../utils/datetime';
import {modalObj} from '../../../../utils/modal.js';
import SubscriptionDetail from './subscription_detail';
import '../style.css';

/**
 * Reservation Confirm Booking page
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    const {odm_booking_time_type, advance_booking_offer} = props.searchBooking;
    const isBookingAdvance = odm_booking_time_type === ODM_BOOKING_TIME_TYPES[1].id && advance_booking_offer?.booking_type === ODM_BOOKING_TIME_TYPES[1].id;
    this.state = {
      isLoading: false,
      flagSelectModal: false,
      flagSubscriptionDetail: false,
      confirmMessage: '',
      estimateCost: {},
      booking_id: '',
      isBookingAdvance,
      numberOfUserTicket:
        props.bookingInfo?.ticket_usage?.length > 0 ? props.bookingInfo?.ticket_usage?.reduce((prevUsage, currUsage) => prevUsage + currUsage.number_of_use, 0) : 0,
      special_passengers: [],
      no_others: 0,
      no_special_adult: 0,
      no_special_child: 0,
      used_special_price: [],
    };
  }

  /**
   * componentWillUnmount
   * @return {*}
   */
  componentWillUnmount() {
    if (_.isEmpty(this.props.bookingInfo)) return this.props.history.push(ROUTE.LAYOUT + ROUTE.RESERVATION_CREATE);
  }

  /**
   * componentDidMount
   * @return {*}
   */
  componentDidMount() {
    const {bookingInfo, searchBooking} = this.props;
    if (_.isEmpty(this.props.bookingInfo) || _.isEmpty(this.props.searchBooking)) return this.props.history.push(ROUTE.LAYOUT + ROUTE.RESERVATION_CREATE);
    // setting specail price
    let used_special_price = bookingInfo?.oneTimeConfigInfo?.special_prices.map((special) => ({...special, number: 0}));
    // eslint-disable-next-line no-unused-expressions
    bookingInfo?.special_number.forEach((special) => {
      used_special_price[special].number++;
    });
    used_special_price = used_special_price.filter((special) => special?.number !== 0);
    const special_passengers = used_special_price.map((spec) => ({special_id: spec.special_id, number: spec.number, type: spec?.type}));
    const no_others_list = used_special_price.filter((spec) => spec?.type === SPECIAL_PERSON_TYPE[2].value);
    const special_adult_list = used_special_price.filter((spec) => spec?.type === SPECIAL_PERSON_TYPE[0].value);
    const special_child_list = used_special_price.filter((spec) => spec?.type === SPECIAL_PERSON_TYPE[1].value);
    const no_special_adult = special_adult_list.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.number;
    }, 0);
    const no_special_child = special_child_list.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.number;
    }, 0);
    const no_others_number = no_others_list.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.number;
    }, 0);
    let payload = {
      user_subscriptions: bookingInfo?.plans?.users
        ?.filter((item) => item.using === true)
        .map((element) => ({
          user_id: element.id,
          subscription_id: bookingInfo?.plans?.subscription_id,
        })),
      simulation_id: bookingInfo?.simulation_id,
      transportation_type: bookingInfo?.transportation_type,
    };
    if (searchBooking?.mobi_type === BOOKING_MOBI_TYPES[0].id) {
      payload = {
        ...payload,
        requested_departure_time: this.state.isBookingAdvance ? searchBooking?.requested_departure_time : new Date().toISOString(),
      };
    } else {
      payload = {
        ...payload,
        requested_departure_time: bookingInfo?.group_type === GROUP_TYPES_BOOKING_JIT[0] ? bookingInfo?.estimate_start_time : null,
        requested_destination_time: bookingInfo?.group_type === GROUP_TYPES_BOOKING_JIT[0] ? null : bookingInfo?.estimate_arrival_time,
      };
    }
    payload.user_subscriptions && estimateCostJitForUserApi(payload).then((response) => this.setState({estimateCost: response.result}));
    this.setState({special_passengers, no_others_number, no_special_adult, no_special_child, used_special_price});
  }

  /**
   * handle confirm booking
   */
  handleBooking = () => {
    const {isBookingAdvance} = this.state;
    if (this.props.bookingInfo && this.props.bookingInfo.member_id) {
      this.setState({isLoading: true});
      if (this.props.searchBooking.mobi_type === 'SHUTTLE_BUS_JIT') {
        this.jitBooking();
        return;
      }
      if (this.props.bookingInfo.service_type === 'DELIVERY') {
        this.onBookingDelivery();
      } else {
        const pickUp = this.props.searchBooking?.positionFrom;
        const dropOFf = this.props.searchBooking?.positionTo;
        const pickUpId = pickUp.transit_stop_swat_id;
        const dropOffId = dropOFf.transit_stop_swat_id;
        const list_id = [];
        if (pickUpId) {
          list_id.push(pickUpId);
        }
        if (dropOffId) {
          list_id.push(dropOffId);
        }
        const body = {
          list_id: list_id,
          geofence_id: this.props.bookingInfo?.geofence_id || '',
        };
        if (list_id.length > 0) {
          if (isBookingAdvance) {
            this.onAdvanceBooking();
          } else {
            checkingWayPointSuspensionApi(body).then((response) => {
              if (response.status === 200 && response.result) {
                const wayPointDisableList = response.result.filter((waypoint) => waypoint.status === true);
                if (wayPointDisableList?.length > 0) {
                  const pickUpName = wayPointDisableList.map((waypoint) => waypoint.waypoint_id)?.includes(pickUpId) ? pickUp.place_name : '';
                  const dropOffName = wayPointDisableList.map((waypoint) => waypoint.waypoint_id)?.includes(dropOffId) ? dropOFf.place_name : '';
                  this.props.setMessageModal(modalObj(true, 'error.restrict.waypoint', {fields: pickUpName + (dropOffName && pickUpName ? ', ' : '') + dropOffName}));
                  redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.RESERVATION_CREATE);
                } else {
                  this.onBooking();
                }
              } else {
                this.setState({isLoading: false});
                this.props.setMessageModal(modalObj(true, 'error.restrict.waypoint'));
              }
            });
          }
        } else {
          this.setState({isLoading: true});
          isBookingAdvance ? this.onAdvanceBooking() : this.onBooking();
        }
      }
    } else {
      this.toPaymentLogin();
    }
  };

  /**
   * toListReservation
   */
  toListReservation() {
    const path = ROUTE.LAYOUT + ROUTE.RESERVATION_MANAGEMENT_SEARCH;
    redirectRouter(this.props, path);
  }

  /**
   * toPaymentLogin
   */
  toPaymentLogin() {
    const path = '/maas/reservation/payment/login';
    redirectRouter(this.props, path);
  }

  /**
   * onBookingDelivery
   */
  async onBookingDelivery() {
    const {carts, bookingInfo} = this.props;
    const lat = bookingInfo.delivery.lat;
    const lng = bookingInfo.delivery.lng;
    this.setState({
      isLoading: true,
    });
    carts.items.forEach(async (item) => {
      const payload = {
        user_id: bookingInfo.member_id,
        product_id: item.id,
        quantity: item.quantity,
        description: item.description,
      };
      await addToCart(payload);
    });
    const delivery = {
      user_id: bookingInfo.member_id,
      zip_code: '10000',
      chome: 'string',
      name: bookingInfo.first_name + bookingInfo.last_name,
      name_furigana: 'string',
      phone_number: bookingInfo.mobile,
      lat: lat,
      lon: lng,
      address_type: 'HOME',
      apartment_no: 'string',
      email_address: bookingInfo.email,
    };
    const auth = await deliveryAddress(delivery);
    let user_address_id = '';
    if (Number(auth.status) === 200) {
      user_address_id = auth.result.id;
    }

    const payload_order = {
      admin_id: parseInt(localStorage.getItem('ADMIN_ID')),
      user_id: bookingInfo.member_id,
      user_address_id: user_address_id,
      expected_receive_time: new Date().toISOString(),
    };

    const orders = await bookingDelivery(payload_order);
    if (orders.status === 200) {
      this.props.setMessageModal(modalObj(true, 'Booking is successfully!'));
      this.props.onUpdateBooking({});
      this.props.removeAllItems();
      this.toPageCreate();
    } else {
      this.props.setMessageModal(modalObj(true, 'Booking is Failed!'));
    }
    this.setState({
      isLoading: false,
    });
  }

  /**
   * jitBooking
   */
  jitBooking = () => {
    const {bookingInfo, searchBooking} = this.props;
    const payload = {
      trip_booking: {
        transportation_type: 'SHUTTLE_BUS',
        pickup_location_lat: bookingInfo.pickup_location_lat,
        pickup_location_lon: bookingInfo.pickup_location_lon,
        pickup_location_name: bookingInfo.pickup_location_name,
        preferred_pickup_stop_id: bookingInfo.preferred_pickup_stop_id,
        dropoff_location_lat: bookingInfo.dropoff_location_lat,
        dropoff_location_lon: bookingInfo.dropoff_location_lon,
        dropoff_location_name: bookingInfo.dropoff_location_name,
        preferred_dropoff_stop_id: bookingInfo.preferred_dropoff_stop_id,
        demand: {
          normal_passengers: 0,
          passenger: bookingInfo?.demand?.passenger + bookingInfo?.demand?.no_infants || 0,
          special_category: 0,
          no_adults: bookingInfo.demand.no_all_adult,
          no_children: bookingInfo.demand.no_all_children,
          no_infants: bookingInfo.demand.no_infants || 0,
          number_discount_adult: bookingInfo.discount?.adultNumber || 0,
          number_discount_child: bookingInfo.discount?.childNumber || 0,
        },
        user_subscriptions: bookingInfo?.plans?.users
          ?.filter((item) => item.using === true)
          .map((element) => ({
            user_id: element.id,
            subscription_id: bookingInfo?.plans?.subscription_id,
          })),
        ticket_usage: bookingInfo.ticket_usage.map((item) => {
          return {
            number_of_use: item.number_of_use,
            subscription_id: item?.subscription?.ticket_data.subscription_id,
          };
        }),
        requested_departure_time: bookingInfo.group_type === GROUP_TYPES_BOOKING_JIT[0] ? bookingInfo.estimate_start_time : null,
        requested_destination_time: bookingInfo.group_type === GROUP_TYPES_BOOKING_JIT[0] ? null : bookingInfo.estimate_arrival_time,
        simulation_id: searchBooking.simulationId,
        service_type: searchBooking.mobi_type,
        payment_method: bookingInfo.payment_method,
        route_code: searchBooking?.route_code,
        route_id: searchBooking?.route.route_id,
      },
      user_id: bookingInfo.member_id,
      first_name: bookingInfo.first_name || searchBooking.search_member.first_name,
      last_name: bookingInfo.last_name || searchBooking.search_member.last_name,
      email: bookingInfo.email || '',
      mobile: searchBooking.search_member.mobile,
      first_name_furigana: searchBooking.search_member.first_name_furigana,
      last_name_furigana: searchBooking.search_member.last_name_furigana,
    };
    this.props.bookingJit(payload).then((response) => {
      if (response) {
        redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.RESERVATION_MANAGEMENT_DETAIL + `/${payload.trip_booking.service_type}/${response.booking_id}`);
      }
    });
  };

  /**
   * onBooking
   */
  async onBooking() {
    const {bookingInfo, searchBooking} = this.props;
    const payload = {
      trip_booking: {
        transportation_type: bookingInfo.transportation_type,
        pickup_location_lat: bookingInfo.pickup_location_lat,
        pickup_location_lon: bookingInfo.pickup_location_lon,
        pickup_location_name: bookingInfo.pickup_location_name,
        dropoff_location_lat: bookingInfo.dropoff_location_lat,
        dropoff_location_lon: bookingInfo.dropoff_location_lon,
        dropoff_location_name: bookingInfo.dropoff_location_name,
        preferred_pickup_stop_id: searchBooking?.positionFrom?.transit_stop_swat_id,
        preferred_dropoff_stop_id: searchBooking?.positionTo?.transit_stop_swat_id,
        demand: {
          ...bookingInfo.demand,
          no_adults: bookingInfo.demand.no_all_adult + (this.state.no_special_adult || 0),
          no_children: bookingInfo.demand.no_all_children + (this.state.no_special_child || 0),
          no_infants: bookingInfo?.demand?.no_infants || 0,
          number_discount_adult: bookingInfo.discount?.adultNumber || 0,
          number_discount_child: bookingInfo.discount?.childNumber || 0,
          passenger: bookingInfo?.demand?.passenger + bookingInfo?.demand?.no_infants || 0,
          no_others: this.state.no_others_number || 0,
        },
        requested_departure_time: bookingInfo.requested_departure_time,
        billing_time: searchBooking?.billing_time,
        // requested_destination_time: new Date().toISOString(),
        trip_info: bookingInfo.trip_info,
        simulation_id: bookingInfo.simulation_id,
        service_type: bookingInfo.service_type === 'MOBI' ? searchBooking.mobi_type : bookingInfo.service_type,
        user_subscriptions: bookingInfo?.plans?.users
          ?.filter((item) => item.using === true)
          .map((element) => ({
            user_id: element.id,
            subscription_id: bookingInfo?.plans?.subscription_id,
          })),
        ticket_usage: bookingInfo.ticket_usage.map((item) => {
          return {
            number_of_use: item.number_of_use,
            subscription_id: item?.subscription?.ticket_data.subscription_id,
          };
        }),
        special_passengers: this.state.special_passengers || [],
        payment_method: bookingInfo.payment_method,
      },
      user_id: bookingInfo.member_id,
      first_name: bookingInfo.first_name,
      last_name: bookingInfo.last_name,
      email: bookingInfo.email || '',
      mobile: bookingInfo.mobile,
      first_name_furigana: bookingInfo.first_name_furigana,
      last_name_furigana: bookingInfo.last_name_furigana,
    };
    try {
      const book = await booking(payload);
      if (book?.message_code === 'mass.waypoint.not.associate') {
        this.toPageCreateMap();
        this.props.setMessageModal(modalObj(true, 'reservation.error_waypoint_not_associate'));
        return;
      }
      if (!book || book.status !== 200) {
        let fields = {};
        if (book.message_code === 'mass.admin.booking.exceed.max.number.of.booking.per.day') {
          fields = {maxNumberOfBookings: book.result};
        }
        this.props.setMessageModal(modalObj(true, book.message_code, fields));
        this.toPageCreate();
        return;
      }
      if (!book.result?.booking_id) {
        this.props.setMessageModal(modalObj(true, 'booking_failed'));
        this.toPageCreate();
        return;
      }

      this.setState({booking_id: book.result?.booking_id});
      await this.callMQTT(book.result?.mqtt_account, this.props.bookingInfo.member_id, book.result?.booking_id);
    } finally {
      this.setState({isLoading: false});
    }
  }

  /**
   * onAdvanceBooking
   */
  onAdvanceBooking = async () => {
    const {bookingInfo, searchBooking} = this.props;
    const ticket_usage = [];
    if (searchBooking?.ticket_usage?.length > 0) {
      for (const ticket of searchBooking?.ticket_usage) {
        ticket_usage.push({number_of_use: ticket?.number_of_use, subscription_id: ticket?.subscription?.id});
      }
    }
    const user_subscriptions = bookingInfo?.plans?.users
      ?.filter((item) => item.using === true)
      .map((element) => ({
        user_id: element.id,
        subscription_id: bookingInfo?.plans?.subscription_id,
      }));
    const payload = {
      adv_booking_id: bookingInfo.adv_booking_id,
      user_subscriptions,
      ticket_usage,
      payment_method: bookingInfo.payment_method,
      accepted: [
        {
          booking_number: bookingInfo.booking_number,
          pickup_uid: bookingInfo.advance_booking_pickup_uid,
          trip_info: {
            demand_info: {
              passenger: bookingInfo.demand.passenger + bookingInfo.demand.no_infants || 0,
              no_adult: bookingInfo.demand.no_all_adult,
              no_child: bookingInfo.demand.no_all_children,
              no_infants: bookingInfo.demand.no_infants,
              number_discount_adult: bookingInfo.discount.adultNumber || 0,
              number_discount_child: bookingInfo.discount.childNumber || 0,
              number_of_use_ticket_adult: bookingInfo.demand.no_all_adult - bookingInfo.demand.no_one_time_adults,
              number_of_use_ticket_child: bookingInfo.discount.childNumber - bookingInfo.demand.no_one_time_children,
              number_onetime_adult: bookingInfo.demand.no_one_time_adults,
              number_onetime_child: bookingInfo.demand.no_one_time_children,
            },
            requested_departure_time: bookingInfo.requested_departure_time,
            pickup_location_name: bookingInfo.pickup_location_name,
            dropoff_location_name: bookingInfo.dropoff_location_name,
            estimate_start_time: bookingInfo.estimate_start_time,
            estimate_arrival_time: bookingInfo.estimate_arrival_time,
            estimate_total_cost: bookingInfo.estimate_total_cost,
            id: bookingInfo.advance_booking_pickup_uid,
            is_booking_history: false,
            numberUsers: bookingInfo.demand.passenger + bookingInfo.demand.no_infants || 0,
            offer_estimation_timeout: 0,
            transfer_count: bookingInfo.transfer_count,
            routes: [],
          },
        },
      ],
      cancelled: [],
      user_info: {
        user_id: bookingInfo.member_id,
        first_name: bookingInfo.first_name || searchBooking.search_member.first_name,
        last_name: bookingInfo.last_name || searchBooking.search_member.last_name,
        email: bookingInfo.email || '',
        mobile: bookingInfo?.mobile || searchBooking.search_member.mobile,
        first_name_furigana: bookingInfo?.first_name_furigana || searchBooking.search_member.first_name_furigana,
        last_name_furigana: bookingInfo?.last_name_furigana || searchBooking.search_member.last_name_furigana,
      },
      special_passengers: this.state.special_passengers,
      demand: {
        normal_passengers: 0,
        passenger: bookingInfo.demand.passenger + bookingInfo.demand.no_infants || 0,
        special_category: 0,
        no_adults: bookingInfo.demand.no_all_adult + (this.state.no_special_adult || 0),
        no_children: bookingInfo.demand.no_all_children + (this.state.no_special_child || 0),
        no_infants: bookingInfo.demand.no_infants || 0,
        number_discount_adult: bookingInfo.discount.adultNumber || 0,
        number_discount_child: bookingInfo.discount.childNumber || 0,
        no_others: this.state.no_others_number || 0,
      },
    };
    const response = await advanceBooking(payload);
    if (response) {
      if (response?.message_code === 'mass.waypoint.not.associate') {
        this.setState({isLoading: false});
        this.toPageCreateMap();
        this.props.setMessageModal(modalObj(true, 'reservation.error_waypoint_not_associate'));
        return;
      }
      if (response.status === 200 && response.result?.booking_id) {
        this.setState({booking_id: response.result.booking_id});
        await this.callMQTT(response.result.mqtt_account, this.props.bookingInfo.member_id, response.result.booking_id);
      } else {
        let fields = {};
        if (response.message_code === 'mass.admin.booking.exceed.max.number.of.booking.per.day') {
          fields = {maxNumberOfBookings: response.result};
        }
        this.setState({isLoading: false});
        this.props.setMessageModal(modalObj(true, response.message_code, fields));
        this.toPageCreate();
      }
    }
  };

  toPageCreate = () => {
    const path = ROUTE.LAYOUT + ROUTE.RESERVATION_CREATE;
    redirectRouter(this.props, path);
  };

  /**
   * toPageCreateMap
   */
  toPageCreateMap = () => {
    const path = ROUTE.LAYOUT + ROUTE.RESERVATION_CREATE_INFO;
    redirectRouter(this.props, path);
  };

  /**
   * callMQTT
   * @param {*} mqttInfo
   * @param {*} user_id
   * @param {*} booking_id
   */
  async callMQTT(mqttInfo, user_id, booking_id) {
    // const {bookingInfo} = this.props;
    const options = {
      clean: true,
      clientId: mqttInfo.client_id,
      username: mqttInfo.username,
      password: mqttInfo.password,
      keepalive: 60,
    };
    const client = mqtt.connect(process.env.REACT_APP_MQTT_BROKER_URL, options);
    const topic_name = `/notification-mqtt/user/${user_id}`;

    client.stream.on('error', (err) => {
      this.setState({
        isLoading: true,
      });
    });

    const self = this;

    client.on('connect', function() {
      client.subscribe(topic_name, (err, granted) => {
        self.setState({
          isLoading: true,
        });
      });
    });

    let i = 1;
    let check_time = 0;

    // check booking status in 60s
    const timerStatus = setInterval(async () => {
      check_time+=5;
      getStatusBookingApi(booking_id).then((response) => {
        if (response.status === 200 && response.result) {
          if (response.result.trip_booking_status === BOOKING_STATUS_CHECK[0]) {
            // clear MQTT and api check status
            client.end();
            if (timer) {
              clearInterval(timer);
            }
            clearInterval(timerStatus);
            this.props.setMessageModal(modalObj(true, 'successful_booking'));
            redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.RESERVATION_MANAGEMENT_DETAIL + '/' + this.props.bookingInfo.service_type + '/' + this.state.booking_id);
          } else {
            if (check_time >= CONFIG_TIME) {
              clearInterval(timerStatus);
            }
          }
        }
      });
    }, 5000);

    const timer = setInterval(async () => {
      i++;
      if (i === 60) {
        clearInterval(timer);
        // wait 60s, no msg => do nothing
        client.end();
        let canBooking = false;
        if (this.state.isBookingAdvance) {
          const bookingHistoryAdvanceInfo = await bookingHistoryAdvance(booking_id);
          canBooking = !_.isEmpty(bookingHistoryAdvanceInfo) && bookingHistoryAdvanceInfo?.result?.status === BOOKING_ADVANCE_STATUS.WAS_ASSIGNED;
        } else {
          const list_orders = await listUserOrder(user_id);
          canBooking = list_orders && list_orders.status === 200 && list_orders.result.length > 0 && this.checkBooking(list_orders.result);
        }
        if (canBooking) {
          if (timerStatus) {
            clearInterval(timerStatus);
          }
          this.props.setMessageModal(modalObj(true, 'successful_booking'));
          redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.RESERVATION_MANAGEMENT_DETAIL + '/' + this.props.bookingInfo.service_type + '/' + this.state.booking_id);
        } else {
          await cancelBooking({
            cancel_booking_request: {
              booking_id: booking_id,
              reason: 'TIMEOUT',
            },
            user_id: user_id,
          });
          if (timerStatus) {
            clearInterval(timerStatus);
          }
          this.props.setMessageModal(modalObj(true, 'booking_failed'));
        }
        this.setState({
          isLoading: false,
        });
      }
    }, 1000);

    // get message
    client.on('message', async (topic, message) => {
      clearInterval(timer);
      const msg = JSON.parse(message);
      if (msg && Number(msg.data.booking_id) === Number(booking_id)) {
        if (msg?.data?.reason === 'notification.waypoint.restrict.content') {
          await this.props.setMessageModal(modalObj(true, 'notification.waypoint.restrict.content'));
          client.end();
          redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.RESERVATION_CREATE);
          return;
        }
        // track booking
        await manageBooking({
          status: msg.message_type,
          booking_id: booking_id,
        });
        // huỷ khi booking_id không phải lần ấn button
        client.unsubscribe(topic_name, () => {
          client.end();
        });
        if (msg.message_type === BOOKING_MSG_TYPE.DRIVER_RECEIVE_BOOKING) {
          // call api to track
          this.props.setMessageModal(modalObj(true, 'successful_booking'));
          // await this.sendMail(payloadSendMail);
          redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.RESERVATION_MANAGEMENT_DETAIL + '/' + this.props.bookingInfo.service_type + '/' + this.state.booking_id);
          // this.toListReservation();
        }
        if (msg.message_type === BOOKING_MSG_TYPE.NO_OFFER) {
          this.setState({
            confirmMessage: this.state.isBookingAdvance ? 'mass.booking.no.available.seat' : 'booking.retry',
            flagSelectModal: true,
          });
        }
        this.setState({
          isLoading: false,
        });
      }
    });
  }

  /**
   * checkBooking
   * @param {*} booking_list
   * @param {*} booking_id
   * @return {*}
   */
  checkBooking(booking_list, booking_id) {
    const booking = booking_list.filter((item) => item.assignment.booking_id === booking_id);
    return booking.length;
  }

  /**
   * handleButtonOk
   */
  handleButtonOk() {
    this.setState({flagSelectModal: false});
    this.state.isBookingAdvance ? this.onAdvanceBooking() : this.onBooking();
  }

  /**
   * covertToDateTime
   * @param {Date} datetime
   * @return {Date}
   */
  covertToDateTime(datetime) {
    const date = new Date(datetime);
    return (
      date.getFullYear() +
      '-' +
      convertPrefixTime(date.getMonth() + 1) +
      '-' +
      convertPrefixTime(date.getDate()) +
      ' ' +
      convertPrefixTime(date.getHours()) +
      ':' +
      convertPrefixTime(date.getMinutes()) +
      ':' +
      convertPrefixTime(date.getSeconds())
    );
  }

  /**
   * handleButtonCancel
   */
  handleButtonCancel() {
    this.setState({flagSelectModal: false});
    this.toListReservation();
  }

  /**
   * onClickClose subscription modal
   */
  onClickClose() {
    this.setState({flagSubscriptionDetail: false});
  }

  /**
   * Calculate shop partner discount amount
   * @param {Number} price
   * @param {Number} percent
   * @param {String} currencyCode
   * @return {Number}
   */
  calculateShopPartnerPercentDiscount = (price, percent, currencyCode) => {
    if (!price || !percent || !currencyCode) return 0;
    let discount = (price * percent) / 100;
    if (currencyCode === 'SGD' || currencyCode === 'MYR') {
      if (discount < 0) {
        discount = (Math.round(-discount * 100) / 100).toFixed(2);
        return (Math.round(-discount * 100) / 100).toFixed(2);
      }
      return (Math.round(discount * 100) / 100).toFixed(2);
    } else {
      return discount;
    }
  };

  /**
   * Calculate total amount
   * @return {Number}
   */
  calculateTotalAmount = () => {
    const {bookingInfo, searchBooking} = this.props;
    const {estimateCost, numberOfUserTicket, isBookingAdvance} = this.state;
    const {currency_code} = bookingInfo;
    // Number of people
    let onetimeAdults;
    let onetimeChildren;
    const onetimeDiscountAdults = bookingInfo?.discount?.adultNumber || 0;
    const onetimeDiscountChildren = bookingInfo?.discount?.childNumber || 0;
    // Unit price
    let onetimeAdultPrice;
    let onetimeChildPrice;
    let onetimeDiscountAdultPrice = bookingInfo?.discount?.adultAmount || 0;
    let onetimeDiscountChildPrice = bookingInfo?.discount?.childAmount || 0;
    // Shop partner discount
    const shopPartnerDiscountType = bookingInfo?.partner_coupon?.discount_type;
    const shopPartnerDiscount = bookingInfo?.partner_coupon?.discount || 0;
    // Advance booking price
    const advanceBookingAdultPrice = searchBooking?.service_config?.advance_booking_prices?.[0]?.additional_adult_price || 0;
    const advanceBookingChildPrice = searchBooking?.service_config?.advance_booking_prices?.[0]?.additional_child_price || 0;

    // surcharge price
    const adultSurcharge = bookingInfo?.oneTimeConfigInfo?.frame_adult_price;
    const childSurcharge = bookingInfo?.oneTimeConfigInfo?.frame_child_price;
    const isSurcharge = bookingInfo?.oneTimeConfigInfo?.frame_times?.length > 0;
    // caculate total price of special person with discount partner
    const used_special_price = this.state.used_special_price || [];
    let total_other_special_price = 0;
    let total_no_other_special_price = 0;
    let total_discount_special_price = 0;
    let final_total_special_price = 0;
    let discount_by_specify = 0;
    if (used_special_price?.length > 0) {
      used_special_price.forEach((special_price) => {
        if (special_price?.type === SPECIAL_PERSON_TYPE[2].value) {
          total_other_special_price += special_price?.price * special_price?.number;
        } else {
          let amount = 0;
          let discount = 0;
          if (!shopPartnerDiscountType && this.state.isBookingAdvance) {
            if (special_price.type === SPECIAL_PERSON_TYPE[0].value) {
              amount = (special_price?.origin_price + advanceBookingAdultPrice + (isSurcharge ? adultSurcharge : 0)) * special_price?.number;
              if (currency_code === 'SGD' || currency_code === 'MYR') {
                discount =
                  shopPartnerDiscountType === DISCOUNT_TYPE[0].id ?
                    Math.round(((special_price?.origin_price + advanceBookingAdultPrice + (isSurcharge ? adultSurcharge : 0)) * shopPartnerDiscount) / 100).toFixed(2) *
                      special_price?.number :
                    0;
              } else {
                discount =
                  shopPartnerDiscountType === DISCOUNT_TYPE[0].id ?
                    Math.round((special_price?.origin_price + advanceBookingAdultPrice + (isSurcharge ? adultSurcharge : 0)) * (shopPartnerDiscount / 100)) *
                      special_price?.number :
                    0;
              }
            } else {
              amount = (special_price?.origin_price + advanceBookingChildPrice + (isSurcharge ? childSurcharge : 0)) * special_price?.number;
              if (currency_code === 'SGD' || currency_code === 'MYR') {
                discount =
                  shopPartnerDiscountType === DISCOUNT_TYPE[0].id ?
                    Math.round(((special_price?.origin_price + advanceBookingChildPrice + (isSurcharge ? childSurcharge : 0)) * shopPartnerDiscount) / 100).toFixed(2) *
                      special_price?.number :
                    0;
              } else {
                discount =
                  shopPartnerDiscountType === DISCOUNT_TYPE[0].id ?
                    Math.round((special_price?.origin_price + advanceBookingChildPrice + (isSurcharge ? childSurcharge : 0)) * (shopPartnerDiscount / 100)) *
                      special_price?.number :
                    0;
              }
            }
          } else {
            if (special_price.type === SPECIAL_PERSON_TYPE[0].value) {
              amount = (special_price?.origin_price + (isSurcharge ? adultSurcharge : 0)) * special_price?.number;
              if (currency_code === 'SGD' || currency_code === 'MYR') {
                discount =
                  shopPartnerDiscountType === DISCOUNT_TYPE[0].id ?
                    (Math.round((special_price?.origin_price + (isSurcharge ? adultSurcharge : 0)) * shopPartnerDiscount) / 100).toFixed(2) * special_price?.number :
                    0;
              } else {
                discount =
                  shopPartnerDiscountType === DISCOUNT_TYPE[0].id ?
                    Math.round((special_price?.origin_price + (isSurcharge ? adultSurcharge : 0)) * (shopPartnerDiscount / 100)) * special_price?.number :
                    0;
              }
            } else {
              amount = (special_price?.origin_price + (isSurcharge ? childSurcharge : 0)) * special_price?.number;
              if (currency_code === 'SGD' || currency_code === 'MYR') {
                discount =
                  shopPartnerDiscountType === DISCOUNT_TYPE[0].id ?
                    (Math.round((special_price?.origin_price + (isSurcharge ? childSurcharge : 0)) * shopPartnerDiscount) / 100).toFixed(2) * special_price?.number :
                    0;
              } else {
                discount =
                  shopPartnerDiscountType === DISCOUNT_TYPE[0].id ?
                    Math.round((special_price?.origin_price + (isSurcharge ? childSurcharge : 0)) * (shopPartnerDiscount / 100)) * special_price?.number :
                    0;
              }
            }
          }
          total_no_other_special_price += amount;
          discount_by_specify += discount;
        }
      });
      if (shopPartnerDiscountType === DISCOUNT_TYPE[0].id) {
        // check currency for round price discount by percent setting
        total_discount_special_price = discount_by_specify;
      } else if (shopPartnerDiscountType === DISCOUNT_TYPE[1].id) {
        // calculate partner by amout setting
        for (const special_price of used_special_price) {
          if (special_price?.type === SPECIAL_PERSON_TYPE[0].value) {
            total_discount_special_price +=
              shopPartnerDiscount > special_price?.origin_price + (isSurcharge ? adultSurcharge : 0) ?
                (special_price?.origin_price + (isSurcharge ? adultSurcharge : 0)) * special_price?.number :
                shopPartnerDiscount * special_price?.number;
          }
          if (special_price?.type === SPECIAL_PERSON_TYPE[1].value) {
            total_discount_special_price +=
              shopPartnerDiscount > special_price?.origin_price + (isSurcharge ? childSurcharge : 0) ?
                (special_price?.origin_price + (isSurcharge ? childSurcharge : 0)) * special_price?.number :
                shopPartnerDiscount * special_price?.number;
          }
        }
      } else {
        total_discount_special_price = 0;
      }
      final_total_special_price = total_other_special_price - total_discount_special_price + total_no_other_special_price;
    }
    if (_.isEmpty(estimateCost)) {
      onetimeAdults = bookingInfo?.demand?.no_adults - onetimeDiscountAdults || 0;
      onetimeChildren = bookingInfo?.demand?.no_children - onetimeDiscountChildren || 0;
      onetimeAdultPrice = bookingInfo?.oneTimeConfigInfo?.origin_adult_price || 0;
      onetimeChildPrice = bookingInfo?.oneTimeConfigInfo?.origin_child_price || 0;
    } else {
      onetimeAdults =
        bookingInfo?.demand?.no_adults > 0 ?
          bookingInfo?.demand?.passenger -
              this.state.no_special_adult -
              this.state.no_special_child -
              this.state.no_others_number -
              estimateCost?.subscribed_member -
              numberOfUserTicket -
              bookingInfo?.demand?.no_children -
              onetimeDiscountAdults || 0 :
          0;
      onetimeChildren = bookingInfo?.demand?.no_children > 0 ? bookingInfo?.demand?.no_children - onetimeDiscountChildren || 0 : 0;
      onetimeAdultPrice = estimateCost?.origin_adult_price || 0;
      onetimeChildPrice = estimateCost?.origin_child_price || 0;
    }

    if (shopPartnerDiscountType === DISCOUNT_TYPE[0].id) {
      onetimeAdultPrice = onetimeAdultPrice - this.calculateShopPartnerPercentDiscount(onetimeAdultPrice, shopPartnerDiscount, currency_code);
      onetimeChildPrice = onetimeChildPrice - this.calculateShopPartnerPercentDiscount(onetimeChildPrice, shopPartnerDiscount, currency_code);
      onetimeDiscountAdultPrice = onetimeDiscountAdultPrice - this.calculateShopPartnerPercentDiscount(onetimeDiscountAdultPrice, shopPartnerDiscount, currency_code);
      onetimeDiscountChildPrice = onetimeDiscountChildPrice - this.calculateShopPartnerPercentDiscount(onetimeDiscountChildPrice, shopPartnerDiscount, currency_code);
    } else if (shopPartnerDiscountType === DISCOUNT_TYPE[1].id) {
      onetimeAdultPrice = onetimeAdultPrice > shopPartnerDiscount ? onetimeAdultPrice - shopPartnerDiscount : 0;
      onetimeChildPrice = onetimeChildPrice > shopPartnerDiscount ? onetimeChildPrice - shopPartnerDiscount : 0;
      onetimeDiscountAdultPrice = onetimeDiscountAdultPrice > shopPartnerDiscount ? onetimeDiscountAdultPrice - shopPartnerDiscount : 0;
      onetimeDiscountChildPrice = onetimeDiscountChildPrice > shopPartnerDiscount ? onetimeDiscountChildPrice - shopPartnerDiscount : 0;
    }

    const onetimeAdultsAmount = onetimeAdults * (onetimeAdultPrice + (isBookingAdvance && searchBooking?.advance_without_partner ? advanceBookingAdultPrice : 0));
    const onetimeChildrenAmount = onetimeChildren * (onetimeChildPrice + (isBookingAdvance && searchBooking?.advance_without_partner ? advanceBookingChildPrice : 0));
    let onetimeDiscountAdultsAmount = onetimeDiscountAdults * onetimeDiscountAdultPrice;
    onetimeDiscountAdultsAmount = Math.round(onetimeDiscountAdultsAmount);
    let onetimeDiscountChildrenAmount = onetimeDiscountChildren * onetimeDiscountChildPrice;
    onetimeDiscountChildrenAmount = Math.round(onetimeDiscountChildrenAmount);
    // time range surcharge
    let adultSurchargeDiscount;
    let childSurchargeDiscount;
    if (shopPartnerDiscountType === DISCOUNT_TYPE[0].id) {
      adultSurchargeDiscount = this.calculateShopPartnerPercentDiscount(adultSurcharge, shopPartnerDiscount, currency_code);
      childSurchargeDiscount = this.calculateShopPartnerPercentDiscount(childSurcharge, shopPartnerDiscount, currency_code);
    } else if (shopPartnerDiscountType === DISCOUNT_TYPE[1].id) {
      adultSurchargeDiscount =
        shopPartnerDiscount >= bookingInfo?.oneTimeConfigInfo?.origin_adult_price ?
          shopPartnerDiscount - bookingInfo?.oneTimeConfigInfo?.origin_adult_price >= adultSurcharge ?
            adultSurcharge :
            shopPartnerDiscount - bookingInfo?.oneTimeConfigInfo?.origin_adult_price :
          0;
      childSurchargeDiscount =
        shopPartnerDiscount >= bookingInfo?.oneTimeConfigInfo?.origin_child_price ?
          shopPartnerDiscount - bookingInfo?.oneTimeConfigInfo?.origin_child_price >= childSurcharge ?
            childSurcharge :
            shopPartnerDiscount - bookingInfo?.oneTimeConfigInfo?.origin_child_price :
          0;
    } else {
      adultSurchargeDiscount = 0;
      childSurchargeDiscount = 0;
    }
    const dataTicket = {adult: 0, child: 0};
    if (bookingInfo?.ticket_usage) {
      for (const sub of bookingInfo?.ticket_usage) {
        if (sub.subscription?.ticket_data?.ticket_type === 'ADULT') dataTicket.adult = dataTicket.adult + sub.number_of_use;
        else if (sub.subscription?.ticket_data?.ticket_type === 'CHILDREN') dataTicket.child = dataTicket.child + sub.number_of_use;
      }
    }
    const number_of_user_sub_normal = estimateCost?.subscribed_member || 0;
    const totalSurcharge =
      (adultSurcharge > 0 ? adultSurcharge * (number_of_user_sub_normal + dataTicket.adult) : 0) +
      (childSurcharge > 0 ? childSurcharge * dataTicket.child : 0) +
      (adultSurcharge * onetimeAdults + childSurcharge * onetimeChildren);
    const totalDiscountSurcharge = adultSurchargeDiscount * onetimeAdults + childSurchargeDiscount * onetimeChildren;
    let finalTotal =
      onetimeAdultsAmount +
      onetimeChildrenAmount +
      onetimeDiscountAdultsAmount +
      onetimeDiscountChildrenAmount +
      totalSurcharge -
      totalDiscountSurcharge +
      final_total_special_price;
    if (shopPartnerDiscountType === DISCOUNT_TYPE[0].id && currency_code !== 'SGD' && currency_code !== 'MYR') {
      finalTotal = Math.round(finalTotal);
    }
    return finalTotal;
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t, bookingInfo, searchBooking, carts} = this.props;
    const {numberOfUserTicket} = this.state;
    const zone_id = bookingInfo?.trip_info?.zone_id || searchBooking?.zone_id;
    const date_and_time_of_use =
      searchBooking.mobi_type === 'SHUTTLE_BUS_JIT' ?
        bookingInfo.tejimai_time ?
          roundedAndConvertTimeByZoneId(bookingInfo.tejimai_time, zone_id, DATE_TIME_SECOND_FORMAT_MOMENT) :
          DATA_NULL :
        bookingInfo.estimate_start_time ?
        roundedAndConvertTimeByZoneId(bookingInfo.estimate_start_time, zone_id, DATE_TIME_SECOND_FORMAT_MOMENT) :
        DATA_NULL;

    return (
      <LoadingOverlay active={this.state.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('reservationManagement.customer_confirm')}</h3>
              </Grid>
              <Grid container alignItems="center" justifyContent="flex-end" item xs={6}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />} onClick={this.props.history.goBack}>
                  {t('common.btnReturn')}
                </Button>
              </Grid>
            </Grid>
          </Container>
          {/* Step 1 */}
          <Container maxWidth="xl">
            <Box p={1} m={1}>
              <Card raised>
                <Container maxWidth="xl">
                  <Box p={2} m={2}>
                    <Grid container spacing={3} alignItems="flex-start">
                      <Grid item xs={12}>
                        <Table size="small">
                          <TableHead></TableHead>
                          <TableBody>
                            {/* Customer Information */}
                            <TableRow>
                              <TableCell component="th" className="product_entry_table_header_color font_color_white font_size_mid" colSpan={2}>
                                {t('reservationManagement.customer_information')}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="table_background_color_aliceblue width_30">{t('reservationManagement.member_type')}</TableCell>
                              <TableCell className="table_background_color_aliceblue">{bookingInfo.member_type || 'MEMBER'}</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell className="table_background_color_aliceblue width_30">{t('memberManagement.memberId')}</TableCell>
                              <TableCell className="table_background_color_aliceblue">{bookingInfo?.member_code || DATA_NULL}</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell className="table_background_color_aliceblue width_30">{t('sub.last_name')}</TableCell>
                              <TableCell className="table_background_color_aliceblue">{bookingInfo.last_name}</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell className="table_background_color_aliceblue width_30">{t('sub.first_name')}</TableCell>
                              <TableCell className="table_background_color_aliceblue">{bookingInfo.first_name}</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell className="table_background_color_aliceblue width_30">{t('sub.lastname_furigana')}</TableCell>
                              <TableCell className="table_background_color_aliceblue">{bookingInfo.last_name_furigana}</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell className="table_background_color_aliceblue width_30">{t('sub.firstname_furigana')}</TableCell>
                              <TableCell className="table_background_color_aliceblue">{bookingInfo.first_name_furigana}</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell className="table_background_color_aliceblue width_30">{t('common.email')}</TableCell>
                              <TableCell className="table_background_color_aliceblue">{bookingInfo.email}</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell className="table_background_color_aliceblue width_30">{t('common.phoneNumber')}</TableCell>
                              <TableCell className="table_background_color_aliceblue">{bookingInfo.mobile}</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell className="table_background_color_aliceblue width_30">{t('common.paymentMethod')}</TableCell>
                              <TableCell className="table_background_color_aliceblue">
                                {t(PAYMENT_METHODS.find((payment) => payment.id === bookingInfo.payment_method)?.i18n || DATA_NULL)}
                              </TableCell>
                            </TableRow>

                            {/* Product Information */}
                            <TableRow>
                              <TableCell component="th" className="product_entry_table_header_color font_color_white font_size_mid" colSpan={2}>
                                {t('reservationManagement.product_information')}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell className="table_background_color_aliceblue width_30">{t('reservationManagement.service_type')}</TableCell>
                              <TableCell className="table_background_color_aliceblue">
                                {bookingInfo.service_type === BOOKING_MOBI_TYPES[0].id ?
                                  t(SERVICE_TYPE[0].i18n) :
                                  t(SERVICE_TYPE.find((service) => service.id === bookingInfo.service_type)?.i18n)}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="table_background_color_aliceblue width_30">{t('reservationManagement.mobi_type')}</TableCell>
                              <TableCell className="table_background_color_aliceblue">
                                {BOOKING_MOBI_TYPES[0].id === searchBooking.mobi_type ? t(BOOKING_MOBI_TYPES[0].i18n) : t(BOOKING_MOBI_TYPES[1]?.i18n)}
                              </TableCell>
                            </TableRow>
                            {bookingInfo.service_type !== 'DELIVERY' && (
                              <>
                                <TableRow>
                                  <TableCell className="table_background_color_aliceblue width_30">
                                    {searchBooking.mobi_type === 'SHUTTLE_BUS_JIT' ?
                                      t('reservationManagement.estimate_contact_time') :
                                      t('reservationManagement.date_and_time_of_use')}
                                  </TableCell>
                                  <TableCell className="table_background_color_aliceblue">{date_and_time_of_use}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell className="table_background_color_aliceblue width_30">{t('reservationManagement.pickup_location')}</TableCell>
                                  <TableCell className="table_background_color_aliceblue">{bookingInfo.pickup_location_name || DATA_NULL}</TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell className="table_background_color_aliceblue width_30">
                                    {bookingInfo.group_type === GROUP_TYPES_BOOKING_JIT[0] ?
                                      t('reservationManagement.pick_up_time') :
                                      t('reservationManagement.pickup_date_scheduled')}
                                  </TableCell>
                                  <TableCell className="table_background_color_aliceblue">
                                    {bookingInfo.estimate_start_time ?
                                      roundedAndConvertTimeByZoneId(bookingInfo.estimate_start_time, zone_id, DATE_TIME_SECOND_FORMAT_MOMENT) :
                                      DATA_NULL}
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell className="table_background_color_aliceblue width_30">{t('reservationManagement.dropoff_location')}</TableCell>
                                  <TableCell className="table_background_color_aliceblue">{bookingInfo.dropoff_location_name || DATA_NULL}</TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell className="table_background_color_aliceblue width_30">
                                    {bookingInfo.group_type === GROUP_TYPES_BOOKING_JIT[1] ?
                                      t('reservationManagement.booking_flight_dropoff_time') :
                                      t('reservationManagement.dropoff_date_scheduled')}
                                  </TableCell>
                                  <TableCell className="table_background_color_aliceblue">
                                    {bookingInfo.estimate_arrival_time ?
                                      roundedAndConvertTimeByZoneId(bookingInfo.estimate_arrival_time, zone_id, DATE_TIME_SECOND_FORMAT_MOMENT) :
                                      DATA_NULL}
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell className="table_background_color_aliceblue width_30">{t('reservationManagement.passenger')}</TableCell>
                                  <TableCell className="table_background_color_aliceblue">
                                    {Number(bookingInfo.demand?.passenger || bookingInfo.passenger) + (bookingInfo.demand?.no_infants || 0)}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell className="table_background_color_aliceblue width_30">{t('reservationManagement.amount_of_money')}</TableCell>
                                  <TableCell className="table_background_color_aliceblue">
                                    <span>{customDisplayCurrency(this.calculateTotalAmount(), bookingInfo?.currency_code)}</span>
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="margin-item-0 padding-item-0"
                                      onClick={() => this.setState({flagSubscriptionDetail: true})}
                                    >
                                      {t('common.btnDetail')}
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              </>
                            )}

                            {bookingInfo.service_type === 'DELIVERY' && (
                              <>
                                <TableRow>
                                  <TableCell className="table_background_color_aliceblue width_30">Delivery to</TableCell>
                                  <TableCell className="table_background_color_aliceblue">{bookingInfo.delivery.place_name}</TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell className="table_background_color_aliceblue width_30">Total Amount</TableCell>
                                  <TableCell className="table_background_color_aliceblue">{customDisplayCurrency(carts.total_amount, bookingInfo?.currency_code)}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell className="table_background_color_aliceblue width_30">Items</TableCell>
                                  <TableCell className="table_background_color_aliceblue">
                                    {carts.items.map((item) => {
                                      return (
                                        <div>
                                          {item.product_name} - QTY ({item.quantity})
                                        </div>
                                      );
                                    })}
                                  </TableCell>
                                </TableRow>
                              </>
                            )}
                          </TableBody>
                          <TableFooter></TableFooter>
                        </Table>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box p={2} m={2}>
                    <Grid container spacing={3} alignItems="flex-start">
                      <Grid item xs={12}>
                        <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.handleBooking()}>
                          {t('reservationManagement.book')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              </Card>
            </Box>
          </Container>
        </Card>
        <Dialog
          open={this.state.flagSelectModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <SelectModal onClickOk={() => this.handleButtonOk()} onClickCancel={() => this.handleButtonCancel()} message={this.state.confirmMessage}></SelectModal>
        </Dialog>
        <Dialog
          open={this.state.flagSubscriptionDetail}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
          maxWidth="lg"
        >
          <SubscriptionDetail
            detailCost={
              _.isEmpty(this.state.estimateCost) ?
                {
                    ...searchBooking,
                    subscribed_member: 0,
                    no_adults: bookingInfo?.demand?.no_adults,
                    no_children: bookingInfo?.demand?.no_children,
                    infants: bookingInfo?.demand?.no_infants,
                    ...searchBooking?.service_config,
                    partner_coupon: bookingInfo?.partner_coupon,
                    ticket_usage: bookingInfo?.ticket_usage,
                    one_time_discount: bookingInfo?.discount,
                    oneTimeSurcharge: bookingInfo?.oneTimeConfigInfo,
                    used_special_price: this.state.used_special_price,
                  } :
                {
                    ...searchBooking,
                    ...this.state.estimateCost,
                    no_adults:
                      bookingInfo?.demand?.passenger -
                      this.state.estimateCost?.subscribed_member -
                      bookingInfo?.demand?.no_children -
                      numberOfUserTicket -
                      this.state.no_special_adult -
                      this.state.no_special_child -
                      this.state.no_others_number,
                    no_children: bookingInfo?.demand?.no_children,
                    infants: bookingInfo?.demand?.no_infants,
                    partner_coupon: bookingInfo?.partner_coupon,
                    ticket_usage: bookingInfo?.ticket_usage,
                    one_time_discount: bookingInfo?.discount,
                    oneTimeSurcharge: bookingInfo?.oneTimeConfigInfo,
                    used_special_price: this.state.used_special_price,
                  }
            }
            totalCost={this.calculateTotalAmount()}
            mobiType={searchBooking.mobi_type}
            totalPassenger={this.props.bookingInfo?.demand?.passenger}
            calculateDiscount={calculatePercentDiscountForFeeBreakDown}
            onClickConfirm={() => this.onClickClose()}
          />
        </Dialog>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bookingInfo: state.reservation.bookingInfo,
    searchBooking: state.reservation.searchBooking,
    carts: state.carts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMessageModal: (params) => dispatch(setMessageModal(params)),
    onUpdateBooking: (bookingData) => {
      dispatch(updateBooking(bookingData));
    },
    removeAllItems: () => dispatch(removeAllItems()),
    bookingJit: (payload) => dispatch(bookingJit(payload)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
