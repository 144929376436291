import React, {Component} from 'react';

import {
  Card,
  Container,
  Grid,
  Box,
  Button,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  TableFooter,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withTranslation} from 'react-i18next';

import {redirectRouter} from '../../../../utils/common';

/**
 * Reservation management view
 */
class Index extends Component {
  /**
   * render component
   * @return {component}
   */
  render() {
    const {t} = this.props;

    return (
      <div>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('reservationManagement.confirmation')}</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}>
                <Button
                  color="primary"
                  variant="contained"
                  className="button_margin button_color"
                  endIcon={<ArrowBackIcon />}
                  onClick={this.props.history.goBack}
                >
                  {t('common.btnReturn')}
                </Button>
              </Grid>
            </Grid>
          </Container>
          {/* Step 1 */}
          <Container maxWidth="xl">
            <Box p={1} m={1}>
              <Card raised>
                <Container maxWidth="xl">
                  <Box p={2} m={2}>
                    <Grid container spacing={3} alignItems="flex-start">
                      <Grid item xs={12}>
                        <Table size="small">
                          <TableHead></TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell className="width_30">
                                {t('newReservation.dateTime')}
                              </TableCell>
                              <TableCell>2019年2月17日 11:2</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="width_30">
                                {t('newReservation.number')}
                              </TableCell>
                              <TableCell>11091992</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="width_30">
                                {t('newReservation.orderer')}
                              </TableCell>
                              <TableCell>イ・ミンホ</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell className="width_30">
                                {t('newReservation.totalFee')}
                              </TableCell>
                              <TableCell>3900円</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell className="width_30">
                                {t('common.paymentStatus')}
                              </TableCell>
                              <TableCell>成功</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell className="width_30">
                                {t('newReservation.registerer')}
                              </TableCell>
                              <TableCell>グエンヴァンナム</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="width_30">
                                {t('newReservation.expiredDate')}
                              </TableCell>
                              <TableCell>02/22</TableCell>
                            </TableRow>

                          </TableBody>
                          <TableFooter></TableFooter>
                        </Table>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box p={2} m={2}>
                    <Grid container spacing={3} alignItems="flex-start">
                      <Grid item xs={12}>
                        <Button
                          color="primary"
                          variant="contained"
                          className="button_margin button_color"
                          onClick={this.props.history.goBack}
                        >
                          {t('common.btnReturn')}
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          className="button_margin button_color right"
                          onClick={() => this.toComplete()}
                        >
                          {t('reservationManagement.next')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              </Card>
            </Box>
          </Container>
        </Card>
      </div>
    );
  }

  toComplete = () => {
    const path = '/maas/reservation/payment/complete';
    redirectRouter(this.props, path);
  };
}

export default withTranslation('translations')(Index);
