import React, {Component} from 'react';

import {Button, Container, Dialog, FormControl, FormHelperText, Grid, Paper, Switch, TextField} from '@material-ui/core';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {TIME_FORMAT_WITH_SECONDS} from '../../../common/constant';
import CancelBookingSuccessModal from '../../../components/cancelBookingSuccessModal';
import CustomTimePicker from '../../../components/CustomTimePicker';
import SelectModal from '../../../components/selectModal';
import {checkBookingGeofenceApi, getGeofenceServiceApi, updateGeofenceServiceApi} from '../../../services/geofences';
import {setMessageModal} from '../../../stores/modal/actions';
import {preventInvalidChars} from '../../../utils/common';
import {modalObj} from '../../../utils/modal';

/**
 * createDateFromTime
 * @param {string} time
 * @return {date}
 */
function createDateFromTime(time) {
  const now = moment();
  const timeString = now.format('YYYY-MM-DD') + ' ' + time;
  return moment(timeString).toDate();
}

/**
 * ODMSettingModal
 * @param {object} props
 * @return {JSX}
 */
class ODMSettingModal extends Component {
  /**
   * @param {object} props
   * Component constructor
   */
  constructor(props) {
    super(props);
    this.state = {
      // Config input
      startTime: null,
      endTime: null,
      simDayAhead: '',
      simLifeCycle: '',
      advanceSupported: false,
      maxBookingsPerDay: '',
      maxPreBookingDays: '',
      offerTimeoutDuration: '',
      timeDisplayCallChat: '',
      timeNotifyDriver: '',
      advanceSupportedDetail: false,

      // UI
      loading: false,
      updateClicked: false,
      confirmModalOpen: false,
      bookingCancelNumber: null,
      minBookingGapTime: null,
      tejimaiBufferTime: null,
      bookingCancelIds: [],
      showWarningDialog: false,
      isSuccessBookingCancel: false,
    };
    this.validator = new SimpleReactValidator();
    this.validatorAdvance = new SimpleReactValidator();
  }

  /**
   * Component did mount
   */
  componentDidMount() {
    const {geofenceId, groupType = 'SHUTTLE_BUS_ON_DEMAND', setMessageModal} = this.props;

    const getGeofenceService = async () => {
      const geofenceService = await getGeofenceServiceApi(geofenceId, groupType).then((response) => {
        if (response) {
          if (response.status === 200) {
            return response.result;
          } else {
            const message = response.message_code || response.message || 'error.500';
            setMessageModal(modalObj(true, message));
            return;
          }
        }
      });

      if (geofenceService) {
        this.setState({
          startTime: createDateFromTime(geofenceService.start_time),
          endTime: createDateFromTime(geofenceService.end_time),
          simDayAhead: geofenceService.simulation_day_ahead,
          simLifeCycle: geofenceService.simulation_life_cycle,
          advanceSupported: geofenceService.advance_booking_supported,
          maxBookingsPerDay: geofenceService.max_no_booking_per_day,
          maxPreBookingDays: geofenceService.max_pre_booking_days,
          offerTimeoutDuration: geofenceService.offer_timeout_duration / 60 || null,
          timeDisplayCallChat: geofenceService.time_display_call_chat,
          timeNotifyDriver: geofenceService.time_notify_booking_adv_to_driver,
          tejimaiBufferTime: geofenceService.tejimai_buffer_time,
          loading: false,
          advanceSupportedDetail: geofenceService.advance_booking_supported,
          minBookingGapTime: geofenceService.min_booking_gap_time,
        });
      }
    };

    this.setState({loading: true}, getGeofenceService);
  }

  /**
   * validateFields
   */
  validateFields = () => {
    this.setState({updateClicked: true}, () => {
      if (this.validator.allValid() && this.validatorAdvance.allValid() && this.state.startTime < this.state.endTime) {
        if (this.state.advanceSupportedDetail === true && this.state.advanceSupported === false) {
          this.checkBooking();
        } else {
          this.setState({confirmModalOpen: true});
        }
      }
    });
  };

  /**
   * checkBooking
   */
  checkBooking = async () => {
    const payload = {
      geofence_id: this.props.geofenceId,
    };
    const response = await checkBookingGeofenceApi(payload);
    if (response) {
      if (response?.result?.length > 0) {
        const bookingCancelIds = response?.result;
        this.setState({showWarningDialog: true, bookingCancelNumber: response?.result?.length, bookingCancelIds});
      } else {
        this.setState({confirmModalOpen: true, bookingCancelNumber: null, bookingCancelIds: [], showWarningDialog: false});
      }
    }
  };

  /**
   * handleUpdateGeofenceService
   */
  handleUpdateGeofenceService = async () => {
    this.setState(
      {
        loading: true,
        confirmModalOpen: false,
        showWarningDialog: false,
      },
      () => {
        updateGeofenceService();
      },
    );

    const updateGeofenceService = async () => {
      const {geofenceId, groupType = 'SHUTTLE_BUS_ON_DEMAND', setMessageModal} = this.props;
      const {
        startTime,
        endTime,
        simDayAhead,
        simLifeCycle,
        advanceSupported,
        maxBookingsPerDay,
        maxPreBookingDays,
        offerTimeoutDuration,
        timeDisplayCallChat,
        timeNotifyDriver,
        tejimaiBufferTime,
        minBookingGapTime,
      } = this.state;
      const anyAssignedBookings = this.state.bookingCancelNumber ? true : false;
      const advanceBookingConfig = advanceSupported ?
        {
            max_no_booking_per_day: +maxBookingsPerDay,
            max_pre_booking_days: +maxPreBookingDays,
            offer_timeout_duration: +offerTimeoutDuration * 60,
            time_display_call_chat: +timeDisplayCallChat,
            time_notify_booking_adv_to_driver: +timeNotifyDriver,
            tejimai_buffer_time: +tejimaiBufferTime,
            min_booking_gap_time: +minBookingGapTime,
          } :
        {};

      const payload = {
        geofence_id: geofenceId,
        group_type: groupType,
        start_time: moment(startTime).format('HH:mm:ss'),
        end_time: moment(endTime).format('HH:mm:ss'),
        simulation_day_ahead: +simDayAhead,
        simulation_life_cycle: +simLifeCycle,
        advance_booking_supported: advanceSupported,
        ...advanceBookingConfig,
        any_assigned_bookings: anyAssignedBookings,
      };
      try {
        const response = await updateGeofenceServiceApi(payload);
        if (response.status === 200) {
          // check display dialog success booking cancel
          if (this.state.bookingCancelIds?.length > 0) {
            this.setState({isSuccessBookingCancel: true});
          } else {
            setMessageModal(modalObj(true, 'Update Geofence service successfully!'));
          }
        } else {
          const message = response.message_code || response.message || 'error.500';
          setMessageModal(modalObj(true, message));
        }
      } finally {
        this.setState({loading: false});
      }
    };
  };

  /**
   * Render component
   * @return {JSX}
   */
  render() {
    const {t, onCloseModal} = this.props;
    const {
      startTime,
      endTime,
      simDayAhead,
      simLifeCycle,
      advanceSupported,
      maxBookingsPerDay,
      maxPreBookingDays,
      offerTimeoutDuration,
      timeDisplayCallChat,
      timeNotifyDriver,
      tejimaiBufferTime,
      loading,
      updateClicked,
      minBookingGapTime,
    } = this.state;
    return (
      <Paper className="field_min_size_800 odm_modal">
        <Container maxWidth="xl">
          <br />
          <LoadingOverlay active={loading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <h1 className="product_entry_table_header_color font_color_white font_size_mid title padding-y-16">{'On-demand Settings'}</h1>
            <div style={{maxHeight: 700, overflowY: 'auto', overflowX: 'hidden'}}>
              <Grid container spacing={1} justify-content="center" alignItems="center">
                <Grid item xs={7} className="grid_title_padding">
                  Start time
                  <span className="font_color_red">＊</span>
                </Grid>
                <Grid item>
                  <CustomTimePicker
                    className="field_size_40 field_min_size_250"
                    format={TIME_FORMAT_WITH_SECONDS}
                    use12Hours={false}
                    autoComplete="off"
                    allowEmpty
                    placeholder={'00:00:00, 23:59:59'}
                    value={startTime}
                    onChange={(startTime) => {
                      this.setState({startTime});
                    }}
                  />
                  <div>
                    {updateClicked && this.validator.message('startTime', this.state.startTime, 'required')}
                    {updateClicked && !this.validator.check(this.state.startTime, 'required') && (
                      <FormHelperText id="startTime" error>
                        Please enter the Start time
                      </FormHelperText>
                    )}
                    {updateClicked &&
                      this.validator.check(this.state.startTime, 'required') &&
                      this.validator.check(this.state.endTime, 'required') &&
                      this.state.startTime >= this.state.endTime && (
                        <FormHelperText id="startTime" error>
                          {t('validation.invalid.timeRange')}
                        </FormHelperText>
                      )}
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={1} justify-content="center" alignItems="center">
                <Grid item xs={7} className="grid_title_padding">
                  End time
                  <span className="font_color_red">＊</span>
                </Grid>
                <Grid item>
                  <CustomTimePicker
                    className="field_size_40 field_min_size_250"
                    format={TIME_FORMAT_WITH_SECONDS}
                    use12Hours={false}
                    autoComplete="off"
                    allowEmpty
                    placeholder={'00:00:00, 23:59:59'}
                    value={endTime}
                    onChange={(endTime) => {
                      this.setState({endTime});
                    }}
                  />
                  <div>
                    {updateClicked && this.validator.message('endTime', this.state.endTime, 'required')}
                    {updateClicked && !this.validator.check(this.state.endTime, 'required') && (
                      <FormHelperText id="endTime" error>
                        Please enter the End time
                      </FormHelperText>
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={1} justify-content="center" alignItems="center">
                <Grid item xs={7} className="grid_title_padding">
                  Simulation day ahead (day)
                  <span className="font_color_red">＊</span>
                </Grid>
                <Grid item>
                  <FormControl>
                    <TextField
                      className="field_size_40 field_min_size_250"
                      variant="outlined"
                      margin="dense"
                      type="number"
                      inputProps={{min: 1, max: 99}}
                      placeholder={'1-99'}
                      onKeyDown={preventInvalidChars}
                      value={simDayAhead}
                      onChange={(e) => {
                        this.setState({simDayAhead: e.target.value});
                      }}
                    />
                    {updateClicked && this.validator.message('simDayAhead', +simDayAhead, 'required|integer|min:1,num|max:99,num')}
                    {updateClicked && !this.validator.check(+simDayAhead, 'required|integer|min:1,num|max:99,num') && (
                      <FormHelperText id="simDayAhead" error>
                        Invalid simulation day ahead
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={1} justify-content="center" alignItems="center">
                <Grid item xs={7} className="grid_title_padding">
                  Simulation life cycle (day)
                  <span className="font_color_red">＊</span>
                </Grid>
                <Grid item>
                  <FormControl>
                    <TextField
                      className="field_size_40 field_min_size_250"
                      variant="outlined"
                      margin="dense"
                      type="number"
                      inputProps={{min: 1, max: 99}}
                      placeholder={'1-99'}
                      onKeyDown={preventInvalidChars}
                      value={simLifeCycle}
                      onChange={(e) => {
                        this.setState({simLifeCycle: e.target.value});
                      }}
                    />
                    {updateClicked && this.validator.message('simLifeCycle', +simLifeCycle, 'required|integer|min:1,num|max:99,num')}
                    {updateClicked && !this.validator.check(+simLifeCycle, 'required|integer|min:1,num|max:99,num') && (
                      <FormHelperText id="simLifeCycle" error>
                        Invalid simulation life cycle
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={1} justify-content="center" alignItems="center">
                <Grid item xs={7} className="grid_title_padding">
                  Advance booking supported
                  <span className="font_color_red">＊</span>
                </Grid>
                <Grid item>
                  <Switch
                    checked={advanceSupported}
                    onChange={(e) => {
                      this.setState({advanceSupported: e.target.checked});
                      this.validatorAdvance.purgeFields();
                    }}
                  />
                </Grid>
              </Grid>
              {advanceSupported && (
                <>
                  <Grid container spacing={1} justify-content="center" alignItems="center">
                    <Grid item xs={7} className="grid_title_padding">
                      Max number of booking per day
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid item>
                      <FormControl>
                        <TextField
                          className="field_size_40 field_min_size_250"
                          variant="outlined"
                          margin="dense"
                          type="number"
                          inputProps={{min: 1, max: 99}}
                          placeholder={'1-99'}
                          onKeyDown={preventInvalidChars}
                          value={maxBookingsPerDay}
                          onChange={(e) => {
                            this.setState({maxBookingsPerDay: e.target.value});
                          }}
                        />
                        {updateClicked && this.validatorAdvance.message('maxBookingsPerDay', +maxBookingsPerDay, 'required|integer|min:1,num|max:99,num')}
                        {updateClicked && !this.validatorAdvance.check(+maxBookingsPerDay, 'required|integer|min:1,num|max:99,num') && (
                          <FormHelperText id="maxBookingsPerDay" error>
                            Invalid max number of booking per day
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} justify-content="center" alignItems="center">
                    <Grid item xs={7} className="grid_title_padding">
                      Max pre-booking days
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid item>
                      <FormControl>
                        <TextField
                          className="field_size_40 field_min_size_250"
                          variant="outlined"
                          margin="dense"
                          type="number"
                          inputProps={{min: 1, max: 99}}
                          placeholder={'1-99'}
                          onKeyDown={preventInvalidChars}
                          value={maxPreBookingDays}
                          onChange={(e) => {
                            this.setState({maxPreBookingDays: e.target.value});
                          }}
                        />
                        {updateClicked && this.validatorAdvance.message('maxPreBookingDays', +maxPreBookingDays, 'required|integer|min:1,num|max:99,num')}
                        {updateClicked && !this.validatorAdvance.check(+maxPreBookingDays, 'required|integer|min:1,num|max:99,num') && (
                          <FormHelperText id="maxPreBookingDays" error>
                            Invalid max pre-booking days
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} justify-content="center" alignItems="center">
                    <Grid item xs={7} className="grid_title_padding">
                      Offer timeout duration (minute)
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid item>
                      <FormControl>
                        <TextField
                          className="field_size_40 field_min_size_250"
                          variant="outlined"
                          margin="dense"
                          type="number"
                          inputProps={{min: 1, max: 60}}
                          placeholder={'1-60'}
                          onKeyDown={preventInvalidChars}
                          value={offerTimeoutDuration}
                          onChange={(e) => {
                            this.setState({offerTimeoutDuration: e.target.value});
                          }}
                        />
                        {updateClicked && this.validatorAdvance.message('offerTimeoutDuration', +offerTimeoutDuration, 'required|integer|min:1,num|max:60,num')}
                        {updateClicked && !this.validatorAdvance.check(+offerTimeoutDuration, 'required|integer|min:1,num|max:60,num') && (
                          <FormHelperText id="offerTimeoutDuration" error>
                            Invalid offer timeout duration
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} justify-content="center" alignItems="center">
                    <Grid item xs={7} className="grid_title_padding">
                      Time display call chat (second)
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid item>
                      <FormControl>
                        <TextField
                          className="field_size_40 field_min_size_250"
                          variant="outlined"
                          margin="dense"
                          type="number"
                          inputProps={{min: 1, max: 86400}}
                          placeholder={'1-86400'}
                          onKeyDown={preventInvalidChars}
                          value={timeDisplayCallChat}
                          onChange={(e) => {
                            this.setState({timeDisplayCallChat: e.target.value});
                          }}
                        />
                        {updateClicked && this.validatorAdvance.message('timeDisplayCallChat', +timeDisplayCallChat, 'required|integer|min:1,num|max:86400,num')}
                        {updateClicked && !this.validatorAdvance.check(+timeDisplayCallChat, 'required|integer|min:1,num|max:86400,num') && (
                          <FormHelperText id="timeDisplayCallChat" error>
                            Invalid time display call chat
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} justify-content="center" alignItems="center">
                    <Grid item xs={7} className="grid_title_padding">
                      Time notify advance booking to driver (second)
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid item>
                      <FormControl>
                        <TextField
                          className="field_size_40 field_min_size_250"
                          variant="outlined"
                          margin="dense"
                          type="number"
                          inputProps={{min: 1, max: 86400}}
                          placeholder={'1-86400'}
                          onKeyDown={preventInvalidChars}
                          value={timeNotifyDriver}
                          onChange={(e) => {
                            this.setState({timeNotifyDriver: e.target.value});
                          }}
                        />
                        {updateClicked && this.validatorAdvance.message('timeNotifyDriver', +timeNotifyDriver, 'required|integer|min:1,num|max:86400,num')}
                        {updateClicked && !this.validatorAdvance.check(+timeNotifyDriver, 'required|integer|min:1,num|max:86400,num') && (
                          <FormHelperText id="timeNotifyDriver" error>
                            Invalid time notify advance booking to driver
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} justify-content="center" alignItems="center">
                    <Grid item xs={7} className="grid_title_padding">
                      Tejimai buffer for advanced (minutes)
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid item>
                      <FormControl>
                        <TextField
                          className="field_size_40 field_min_size_250"
                          variant="outlined"
                          margin="dense"
                          type="number"
                          inputProps={{min: 0, max: 10080}}
                          placeholder={'0-10080'}
                          onKeyDown={preventInvalidChars}
                          value={tejimaiBufferTime}
                          onChange={(e) => {
                            this.setState({tejimaiBufferTime: e.target.value !== '' ? e.target.value : null});
                          }}
                        />
                        {updateClicked && this.validatorAdvance.message('tejimaiBufferTime', tejimaiBufferTime, 'required|integer|min:0,num|max:10080,num')}
                        {updateClicked && (!this.validatorAdvance.check(tejimaiBufferTime, 'required|integer|min:0,num|max:10080,num')) && (
                          <FormHelperText id="tejimaiBufferTime" error>
                            Invalid time tejimai buffer time
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} justify-content="center" alignItems="center">
                    <Grid item xs={7} className="grid_title_padding">
                      Min booking gap time (minutes)
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid item>
                      <FormControl>
                        <TextField
                          className="field_size_40 field_min_size_250"
                          variant="outlined"
                          margin="dense"
                          type="number"
                          inputProps={{min: 0, max: 1440}}
                          placeholder={'0-1440'}
                          onKeyDown={preventInvalidChars}
                          value={minBookingGapTime}
                          onChange={(e) => {
                            this.setState({minBookingGapTime: e.target.value !== '' ? e.target.value : null});
                          }}
                        />
                        {updateClicked && this.validatorAdvance.message('minBookingGapTime', minBookingGapTime, 'required|integer|min:0,num|max:1440,num')}
                        {updateClicked && (!this.validatorAdvance.check(minBookingGapTime, 'required|integer|min:0,num|max:1440,num')) && (
                          <FormHelperText id="minBookingGapTime" error>
                            Invalid min booking gap time
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </>
              )}
            </div>
            <br />
            <Grid container spacing={1} justifyContent="center" alignItems="center">
              <Grid item>
                <Button className="button_margin" color="primary" variant="contained" onClick={this.validateFields}>
                  Update
                </Button>
                <Button className="button_margin button_color" color="primary" variant="contained" onClick={onCloseModal}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </LoadingOverlay>
          <br />
        </Container>
        <Dialog
          open={this.state.confirmModalOpen}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <SelectModal
            onClickOk={this.handleUpdateGeofenceService}
            onClickCancel={() => {
              this.setState({confirmModalOpen: false});
            }}
            message={'Are you sure to update this geofence service?'}
          />
        </Dialog>
        <Dialog
          open={this.state.showWarningDialog}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
          maxWidth="md"
        >
          <SelectModal
            onClickOk={this.handleUpdateGeofenceService}
            onClickCancel={() => this.setState({showWarningDialog: false})}
            message={'businessVehicle.cancel_all_booking'}
            fields={{field: this.state.bookingCancelNumber}}
            listBookingCancel={this.state.bookingCancelIds}
          ></SelectModal>
        </Dialog>
        {/* modal success cancel booking */}
        <Dialog
          open={this.state.isSuccessBookingCancel}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <CancelBookingSuccessModal
            message={'Api.success'}
            closeMessage = {() => this.setState({isSuccessBookingCancel: false, bookingCancelIds: []})}
            listBookingCancel={this.state.bookingCancelIds}
          ></CancelBookingSuccessModal>
        </Dialog>
      </Paper>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setMessageModal: (payload) => {
    dispatch(setMessageModal(payload));
  },
});

export default withTranslation('translations')(connect(null, mapDispatchToProps)(ODMSettingModal));
