export const ACTION_TYPES = {
  SEARCH_CONGESTION_STATUS_START: 'SEARCH_CONGESTION_STATUS_START',
  SEARCH_CONGESTION_STATUS_SUCCESS: 'SEARCH_CONGESTION_STATUS_SUCCESS',
  SEARCH_CONGESTION_STATUS_FAIL: 'SEARCH_CONGESTION_STATUS_FAIL',

  DETAIL_CONGESTION_STATUS_START: 'DETAIL_CONGESTION_STATUS_START',
  DETAIL_CONGESTION_STATUS_SUCCESS: 'DETAIL_CONGESTION_STATUS_SUCCESS',
  DETAIL_CONGESTION_STATUS_FAIL: 'DETAIL_CONGESTION_STATUS_FAIL',

  REGISTRATION_CONGESTION_STATUS_START: 'REGISTRATION_CONGESTION_STATUS_START',
  REGISTRATION_CONGESTION_STATUS_SUCCESS: 'REGISTRATION_CONGESTION_STATUS_SUCCESS',
  REGISTRATION_CONGESTION_STATUS_FAIL: 'REGISTRATION_CONGESTION_STATUS_FAIL',
};

const initialState = {
  isLoading: false,
  congestionStatusList: [],
  detailCongestion: {},
};

/**
 * function reduce
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.REGISTRATION_CONGESTION_STATUS_START:
    case ACTION_TYPES.SEARCH_CONGESTION_STATUS_START:
    case ACTION_TYPES.DETAIL_CONGESTION_STATUS_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.SEARCH_CONGESTION_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        congestionStatusList: action.congestionStatusList,
      };

    case ACTION_TYPES.DETAIL_CONGESTION_STATUS_SUCCESS:
      return {
        isLoading: false,
        detailCongestion: action.detailCongestion,
      };
    case ACTION_TYPES.REGISTRATION_CONGESTION_STATUS_SUCCESS:
    case ACTION_TYPES.REGISTRATION_CONGESTION_STATUS_FAIL:
    case ACTION_TYPES.DETAIL_CONGESTION_STATUS_FAIL:
    case ACTION_TYPES.SEARCH_CONGESTION_STATUS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
