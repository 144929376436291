import React, {Component} from 'react';

import {
  Grid,
  Button,
  TextField,
  FormControlLabel,
  Paper,
  Card,
  Container,
  FormControl,
  Select,
  MenuItem,
  RadioGroup,
  Radio,
  Dialog,
  Modal,
  Backdrop,
  FormHelperText,
  Tooltip,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import {Rating} from '@material-ui/lab';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {ROUTE, DRIVER_STATUS, DRIVER_GENDER, PERMISSION_ACTIONS, DRIVER_LANGUAGE} from '../../../common/constant';
import ImageUpload from '../../../components/imageSelect';
import Memo from '../../../components/memo';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getListFacilityBySupplierIdApi} from '../../../services/commonServices';
import {getListSupplier} from '../../../stores/common/actions';
import {getDetailDriver, createDriver, updateDriver, getDriverRating} from '../../../stores/driver/action';
// import {getVehiclesByFacility} from '../../../stores/driver_vehicle_association/action';
import {onChangeSelect, onChangeTextField, onChangeListData, getQueryStringFromObject, clearBlankValue, backForwardRouter} from '../../../utils/common';
import {isRoleBusiness} from '../../../utils/role';

/**
 * Driver Form Component
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      driverCode: '',
      driverName: '',
      driverNameApp: '',
      type: '',
      supplierId: isRoleBusiness() ? props.principal.supplier_id : '',
      supplierFacilityId: '',
      gender: '',
      status: '',
      avatar: '',
      imageSrc: '',
      memos: [],
      note: '',
      isSubmitForm: false,
      flg: false,
      message: '',
      formData: new FormData(),
      openModal: false,
      image_master_id: '',
      user_name: '',
      statusDetail: '',
      lang: '',
      countryId: '',
      vehicleId: null,
      facilities: [],
    };
    this.type = this.props.match.url !== ROUTE.LAYOUT + ROUTE.DRIVER_ADD ? 'UPDATE' : 'CREATE';
    this.validator = new SimpleReactValidator();
    this.email = '';
  }

  /**
   * handleButtonOk
   * @param {object} formData
   */
  handleButtonModalOk = () => {
    this.setState({
      flg: false,
    });
    const formData = this.state.formData;
    if (this.type === 'CREATE') {
      formData['send_facility_email'] = true;
      this.props.createDriver(formData, this.props);
    } else {
      this.props.updateDriver(formData, this.props);
      this.props.getDetailDriver(this.props.match.params.id);
    }
  };

  /**
   * handleClickOkNotSendMail
   */
  handleClickOkNotSendMail = () => {
    this.setState({
      flg: false,
    });
    const formData = this.state.formData;
    if (this.type === 'CREATE') {
      formData['send_facility_email'] = false;
      this.props.createDriver(formData, this.props);
    }
  };

  /**
   * handleButtonCancel
   */
  handleButtonModalCancel() {
    this.setState({
      flg: false,
    });
  }

  /**
   * handleButtonOk
   */
  handleButtonOk = () => {
    this.setState({
      openModal: false,
    });
  };

  /**
   * handleButtonClose
   */
  handleButtonClose = () => {
    this.setState({
      openModal: false,
    });
  };

  /**
   * handle Show Dialog confirm call api
   * @param {object} formData
   */
  handleShowDialog = (formData) => {
    const message = this.type === 'CREATE' ? `messageCode.createConfirmDriver` : 'messageCode.updateConfirmDriver';
    this.setState({
      flg: true,
      message: message,
      formData: formData,
    });
  };

  /**
   * handleChooseImage
   */
  handleChooseImage = () => {
    this.setState({
      openModal: true,
    });
  };

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getListSupplier();
    if (isRoleBusiness() && this.state.supplierId) {
      await this.getFacilitiesBySupplierId(this.state.supplierId);
    }
    if (this.props.match.url !== ROUTE.LAYOUT + ROUTE.DRIVER_ADD) {
      await this.props.getDetailDriver(this.props.match.params.id).then(
        async (result) => {
          this.setState({
            id: result?.id,
            driverCode: result?.driver_code,
            driverName: result?.driver_name,
            driverNameApp: result?.driver_name_app,
            supplierId: result?.supplier_id,
            supplierFacilityId: result?.supplier_facility_id,
            gender: result?.gender,
            status: result?.status,
            statusDetail: result?.status,
            imageSrc: result?.avatar,
            memos: result?.memo,
            type: result?.type,
            user_name: result?.user_name,
            lang: result?.lang,
            countryId: result?.country_id,
            vehicleId: result?.default_vehicle?.id || null,
          });
          await this.getFacilitiesBySupplierId(result?.supplier_id);
          this.forceUpdate();
        },
        () => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.DRIVER_INFORMATION),
      );
      await this.props.getDriverRating(this.props.match.params.id);
    }
  }

  /**
   * changeSupplier
   * @param {event} event
   */
  async changeSupplier(event) {
    onChangeSelect(this, event);
    await this.getFacilitiesBySupplierId(event.target.value);
    this.setState({
      supplierFacilityId: '',
      vehicleId: null,
    });
    this.forceUpdate();
  }

  /**
   * get facility by supplierId
   * @param {int} id
   */
  async getFacilitiesBySupplierId(id) {
    const response = await getListFacilityBySupplierIdApi(id);
    this.setState({facilities: response.status === 200 && response.result.length > 0 ? response.result : [{facility_name: this.props.t('error.no.record'), id: null}]});
  }

  /**
   * changeSupplierFacility
   * @param {event} event
   */
  changeSupplierFacility = async (event) => {
    if (event.target.value) {
      // await this.props.getVehiclesByFacility(event.target.value, new Date().toISOString().substring(0, 10));
      this.setState({
        supplierFacilityId: event.target.value,
        vehicleId: null,
      });
    }
  };

  submitForm = async () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid()) {
      const payload = {
        id: this.state.id,
        driver_name: this.state.driverName,
        supplier_id: this.state.supplierId,
        supplier_facility_id: this.state.supplierFacilityId,
        gender: this.state.gender,
        status: this.state.status,
        type: this.state.type,
        note: this.state.note,
        image_master_id: this.state.image_master_id,
        driver_name_app: this.state.driverNameApp,
        memos: this.state.memos,
        lang: this.state.lang,
        // vehicle_id: this.state.vehicleId,
      };
      this.email = this.state.facilities.filter((item) => item.id === this.state.supplierFacilityId)[0]?.facility_email;
      this.handleShowDialog(payload);
    }
  };

  /**
   * Convert Params to Url
   * @return {*}
   */
  queryStr = () => {
    return getQueryStringFromObject(
      clearBlankValue({
        supplier_id: this.state.supplierId,
        facility_id: this.state.supplierFacilityId,
        driver_code: this.state.driverCode,
        driver_name: this.state.driverName,
        time: new Date().toISOString().substring(0, 10),
      }),
    );
  };

  /**
   * redirectDriverRegister
   */
  redirectDriverRegister() {
    this.props.history.push(ROUTE.LAYOUT + ROUTE.DRIVER_SCHEDULER_REGISTRATION + '?' + this.queryStr());
    this.setState({driver_detail: true});
  }

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {driver, t, common, actions} = this.props;
    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
      canDriverScheduleRegister: actions.includes(PERMISSION_ACTIONS.DRIVER_SCHEDULE_REGISTER),
    };
    return (
      <LoadingOverlay active={driver.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{this.type === 'UPDATE' ? t('driver.detail.title') : t('driver.create.title')}</h3>
              </Grid>
            </Grid>
          </Container>
          <br></br>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={this.state.openModal}
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1000',
                  }}
                >
                  <ImageUpload
                    onClickOk={this.handleButtonOk}
                    onClickClose={this.handleButtonClose}
                    selectImage={(value) => this.setState({image_master_id: value.id, imageSrc: value.url})}
                  ></ImageUpload>
                </Modal>
              </Container>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('driver.title.detail')}
                    </Grid>
                  </Grid>
                  {this.type === 'UPDATE' && (
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('driver.code')}
                        <span className="font_color_red">＊</span>
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <TextField
                          name="driverCode"
                          className="field_size_20 field_min_size_250"
                          margin="dense"
                          placeholder={t('driver.code')}
                          variant="outlined"
                          value={this.state.driverCode}
                          disabled={true}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('driver.name')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl className="width_100 padding-item-0">
                        <TextField
                          name="driverName"
                          className="field_size_20 field_min_size_250"
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('driver.name')})}
                          variant="outlined"
                          value={this.state.driverName}
                          onChange={(event) => onChangeTextField(this, event)}
                        />
                        {this.validator.message('driverName', this.state.driverName, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.driverName, 'required') && (
                          <FormHelperText id="driverName" error>
                            {t('validation.required', {field: t('driver.name')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('driver.name_app')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl className="padding-item-0">
                        <div className="box-group-input">
                          <TextField
                            name="driverNameApp"
                            className="field_size_20 field_min_size_250"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('driver.name_app')})}
                            variant="outlined"
                            value={this.state.driverNameApp}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 15})}</div>
                        </div>
                        {this.validator.message('driverNameApp', this.state.driverNameApp, 'required|max:15')}
                        {(this.state.isSubmitForm && !this.validator.check(this.state.driverNameApp, 'required') && (
                          <FormHelperText id="driverNameApp" error>
                            {t('validation.required', {field: t('driver.name_app')})}
                          </FormHelperText>
                        )) ||
                          (!this.validator.check(this.state.driverNameApp, 'max:15') && (
                            <FormHelperText id="driverNameApp" error>
                              {t('validation.maxString', {value: 15})}
                            </FormHelperText>
                          ))}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Business Name */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('driver.supplier.name')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_250">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'supplierId',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.supplierId ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('driver.supplier.name'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.supplierId}
                          onChange={(event) => this.changeSupplier(event)}
                          disabled={isRoleBusiness()}
                        >
                          {common?.supplier_list.map((item, idx) => {
                            return (
                              <MenuItem value={item.id} key={idx}>
                                {item.supplier_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('supplierId', this.state.supplierId, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.supplierId, 'required') && (
                          <FormHelperText id="supplierId" error>
                            {t('validation.required', {field: t('driver.supplier.name')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Store name */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('driver.facility.name')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_250">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'supplierFacilityId',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.supplierFacilityId ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('driver.facility.name'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.supplierFacilityId}
                          onChange={(event) => this.changeSupplierFacility(event)}
                        >
                          {this.state.facilities.map((item, idx) => {
                            return (
                              <MenuItem value={item.id} key={idx}>
                                {item.facility_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('supplierFacilityId', this.state.supplierFacilityId, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.supplierFacilityId, 'required') && (
                          <FormHelperText id="supplierFacilityId" error>
                            {t('validation.required', {field: t('driver.facility.name')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Default Vehicle */}
                  {/* <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('driver.defaultVehicle')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={4} lg={2}>
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_250">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'vehicleId',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.vehicleId ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('businessVehicle.code'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.vehicleId}
                          onChange={(event) => onChangeSelect(this, event)}
                        >
                          {association?.vehicle?.map((vehicle, idx) => {
                            return (
                              <MenuItem value={vehicle.id} key={idx}>
                                {vehicle.vehicle_id}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('vehicleId', this.state.vehicleId, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.vehicleId, 'required') && (
                          <FormHelperText id="vehicleId" error>
                            {t('validation.required', {field: t('businessVehicle.code')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    {this.type === 'UPDATE' && (
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.VEHICLE_DETAIL + '/' + this.state.vehicleId)}
                      >
                        {t('common.btnDetail')}
                      </Button>
                    )}
                  </Grid> */}

                  {/* Gender */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('driver.gender')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_250">
                        <RadioGroup row aria-label="gender" name="gender">
                          {DRIVER_GENDER.map(({name, i18n}, idx) => {
                            return (
                              <FormControlLabel
                                value={name}
                                key={idx}
                                onChange={(event) => onChangeSelect(this, event)}
                                control={<Radio className="checkbox_radio" checked={this.state.gender === name} />}
                                label={<span className="font_size_small_regular">{t(`${i18n}`)}</span>}
                              />
                            );
                          })}
                        </RadioGroup>
                        {this.validator.message('gender', this.state.gender, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.gender, 'required') && (
                          <FormHelperText id="gender" error>
                            {t('validation.required', {field: t('driver.gender')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Status */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('driver.status')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_250">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'status',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.status ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('driver.status'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.status}
                          onChange={(event) => onChangeSelect(this, event)}
                        >
                          {DRIVER_STATUS.map(({id, i18n}, idx) => {
                            return (
                              <MenuItem value={id} key={idx}>
                                {t(`${i18n}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('status', this.state.status, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.status, 'required') && (
                          <FormHelperText id="status" error>
                            {t('validation.required', {field: t('driver.status')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Language */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('driver.language')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'lang',
                          }}
                          displayEmpty
                          renderValue={this.state.lang ? undefined : () => <div className="font-12 color-disabled">{t('placeholder.language')}</div>}
                          value={this.state.lang}
                          onChange={(event) => onChangeSelect(this, event)}
                        >
                          {DRIVER_LANGUAGE.map(({id, i18n}, idx) => {
                            return (
                              <MenuItem value={id} key={idx}>
                                {t(`${i18n}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('status', this.state.lang, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.lang, 'required') && (
                          <FormHelperText id="status" error>
                            {t('validation.required', {field: t('driver.lang')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Photo */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <Button
                        disabled={this.type === 'UPDATE' && !permission.canUpdate}
                        variant="contained"
                        color="primary"
                        component="span"
                        onClick={() => this.handleChooseImage()}
                      >
                        {t('common.photo')}
                      </Button>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <label htmlFor="icon-button-file">
                        <IconButton color="primary" aria-label="upload picture" component="span">
                          {this.state.imageSrc ? (
                            <div className="box_image" style={{width: '100%', height: '200px'}}>
                              <img className="cropped_image" style={{width: 'inherit', height: 'inherit'}} src={this.state.imageSrc} alt="cropped" />
                            </div>
                          ) : (
                            <PhotoCamera />
                          )}
                        </IconButton>
                      </label>
                    </Grid>
                  </Grid>

                  {/* Vehicle binding */}
                  {this.type === 'UPDATE' && permission.canDriverScheduleRegister && (
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('driver.vehicle_binding')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <Button
                          onClick={() => this.redirectDriverRegister()}
                          color="primary"
                          variant="contained"
                          className="button_margin"
                          disabled={this.state.statusDetail === DRIVER_STATUS[1].id || this.state.statusDetail === DRIVER_STATUS[2].id}
                        >
                          {t('driver.reference')}
                        </Button>
                      </Grid>
                    </Grid>
                  )}

                  {/* Vehicle binding */}
                  {this.type === 'UPDATE' && permission.canDriverScheduleRegister && (
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('reservationManagement.driver_rating')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <Tooltip disableFocusListener disableTouchListener placement="right" title={Number(driver.driverRating?.avg_rating)}>
                          <span>
                            <Rating style={{verticalAlign: 'middle'}} name="read-only" value={Number(driver.driverRating?.avg_rating)} precision={0.1} readOnly size="large" />
                          </span>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  )}

                  {this.type === 'UPDATE' && (
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={12} lg={9} className="grid_title_padding">
                        <Memo
                          disabled={!permission.canUpdate}
                          memos={this.state.memos}
                          onChangeContent={(value) => onChangeListData(this, this.state.memos, value.field, value.index, value.newValue)}
                          note={this.state.note}
                          onChangeNote={(value) => this.setState({note: value})}
                          parentType="medium"
                          countryCode={this.state.countryId}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Paper>
                <br></br>

                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {this.type === 'CREATE' && (
                      <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={this.submitForm}>
                        {t('common.btnRegister')}
                      </Button>
                    )}
                    {this.type === 'UPDATE' && permission.canUpdate && (
                      <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={this.submitForm}>
                        {t('common.btnUpdate')}
                      </Button>
                    )}
                    <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.props.history.goBack}>
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                </Grid>
                <Dialog
                  open={this.state.flg}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    okButtonText={this.type === 'CREATE' ? `${t('common.btnSend')}` : null}
                    cancelButtonText={this.type === 'CREATE' ? `${t('common.btnClose')}` : null}
                    okButtonTextSendMail={t('common.btnDoNotSend')}
                    onClickOk={this.handleButtonModalOk.bind(this)}
                    onClickOkNotSentMail={this.handleClickOkNotSendMail.bind(this)}
                    onClickCancel={this.handleButtonModalCancel.bind(this)}
                    message={this.state.message}
                    sub_message={this.type === 'UPDATE' ? null : this.email}
                    isButtonSendMail={this.type === 'CREATE' ? true : false}
                  ></SelectModal>
                </Dialog>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>
          <br></br>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    driver: state.driver,
    common: state.common,
    association: state.driverVehicleAssociation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListSupplier: () => dispatch(getListSupplier()),
    getDetailDriver: (params, props) => dispatch(getDetailDriver(params, props)),
    createDriver: (formData, props) => dispatch(createDriver(formData, props)),
    updateDriver: (formData, props) => dispatch(updateDriver(formData, props)),
    getDriverRating: (id) => dispatch(getDriverRating(id)),
    // getVehiclesByFacility: (id, date) => dispatch(getVehiclesByFacility(id, date)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
