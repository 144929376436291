import React, {Component} from 'react';

import {Button, Checkbox, Container, Grid, Paper, Table, TableBody, TableCell, Box, Card,
  TableHead, TableRow, TextField, Dialog} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import {ROWS_PER_PAGE_OPTIONS, DATA_NULL} from '../../../../common/constant';
import CustomPagination from '../../../../components/CustomPagination';
import SelectModal from '../../../../components/selectModal';
import {searchMemberApi} from '../../../../services/memberServices';
import {onChangeTextField} from '../../../../utils/common';

/**
 * Select Users
 */
export default class extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      list_family_id: [],
      list_family_code: [],
      number_of_users: '',
      listMembers: [],
      page: 0,
      total_size: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      no_result: false,
      search_member_id: '',
      search_member_email: '',
      search_member_name: '',
      search_member_mobile: '',
      confirmModal: false,
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    this.setState({
      list_family_id: this.props.props.list_family_id,
      number_of_users: this.props.props.number_of_users,
    });
  }

  /**
   * handleChecked
   * @param {*} e
   * @param {*} value
   */
  handleChecked = (e, value) => {
    const list_family_code = this.state.list_family_code;
    const index = list_family_code.indexOf(value.member_id);
    if (e && list_family_code.length >= Number(this.state.number_of_users)) return;
    e ? list_family_code.push(value.member_id) : list_family_code.splice(index, 1);
    this.setState({list_family_code});
  };

  /**
   * searchMemberInfo
   * @param {number} page
   * @param {number} rowsPerPage
   */
  async searchMemberInfo(page, rowsPerPage) {
    const {search_member_id, search_member_name, search_member_email, search_member_mobile} = this.state;
    const members = await searchMemberApi({
      statuses: ['REGISTRATION_COMPLETED', 'INCOMPLETE_REGISTRATION'],
      member_id: search_member_id.trim() ? search_member_id.trim() : null,
      member_name: search_member_name.trim() ? search_member_name.trim() : null,
      email: search_member_email.trim() ? search_member_email.trim() : null,
      mobile: search_member_mobile.trim() ? search_member_mobile.trim() : null,
    }, {
      page: page,
      size: rowsPerPage,
    });
    if (members.status === 200) {
      this.setState({
        listMembers: JSON.parse(JSON.stringify(members.result.content)),
        no_result: members.result.content.length <= 0,
        total_size: members.result.totalSize,
        page: page,
      });
    }
  }

  /**
   * handleChangePage
   * @param {String} currentPage
   * @param {*} rowsPerPage
   */
  handleChangePage = (currentPage, rowsPerPage) => {
    this.setState({listMembers: [], page: currentPage, rowsPerPage: rowsPerPage});
    this.searchMemberInfo(currentPage, rowsPerPage);
  };

  /**
   * Render
   * @return {*}
   */
  render() {
    const {t} = this.props.props;

    return (
      <Paper className="field_min_size_700">
        <Container maxWidth="xl">
          <Box p={1} m={1}>
            <Card raised>
              <Container maxWidth="xl">
                <Box p={2} m={2}>
                  <Grid container alignItems="flex-start">
                    <Grid item xs={12}>
                      <h1 className="product_entry_table_header_color font_color_white font_size_mid title">
                        {t('reservationManagement.customer_info')} &nbsp;{' '}
                        <span className="font-12">
                          ({t('sub.number_member')}: {Number(this.state.number_of_users) - this.state.list_family_code.length} {t('reservationManagement.people')})
                        </span>
                      </h1>
                      <Grid container alignItems="flex-start">
                        <Grid item xs={12}>
                          <Grid container spacing={1} alignItems="flex-start">
                            <Grid item xs={2}>
                              <TextField
                                className="width_100"
                                name="search_member_id"
                                margin="dense"
                                label={t('memberManagement.memberId')}
                                variant="outlined"
                                value={this.state.search_member_id}
                                onChange={(event) => onChangeTextField(this, event)}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <TextField
                                className="width_100"
                                name="search_member_email"
                                margin="dense"
                                label={t('common.email')}
                                variant="outlined"
                                value={this.state.search_member_email}
                                onChange={(event) => onChangeTextField(this, event)}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <TextField
                                className="width_100"
                                name="search_member_name"
                                margin="dense"
                                label={t('reservationManagement.name_info')}
                                variant="outlined"
                                value={this.state.search_member_name}
                                onChange={(event) => onChangeTextField(this, event)}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <TextField
                                className="width_100"
                                name="search_member_mobile"
                                margin="dense"
                                label={t('common.phoneNumber')}
                                variant="outlined"
                                value={this.state.search_member_mobile}
                                onChange={(event) => onChangeTextField(this, event)}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <Button
                                color="primary"
                                variant="contained"
                                className="button_margin button_color"
                                endIcon={<SearchIcon />}
                                onClick={() => this.searchMemberInfo(0, this.state.rowsPerPage)}
                              >
                                {t('common.btnSearch')}
                              </Button>
                            </Grid>
                          </Grid>
                          {this.state.listMembers?.length > 0 && (
                            <Grid container alignItems="flex-start">
                              <div className="scroll_area_400">
                                <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className="width_75p">{t('memberManagement.in_sub')}</TableCell>
                                      <TableCell className="width_75p">{t('memberManagement.memberId')}</TableCell>
                                      <TableCell className="width_100p">{t('common.email')}</TableCell>
                                      <TableCell className="width_150p">{t('common.name')}</TableCell>
                                      <TableCell className="width_100p">{t('common.phoneNumber')}</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {this.state.listMembers.map((item, index) => {
                                      const checked = this.state.list_family_id.indexOf(item.id) > -1 || this.state.list_family_code.indexOf(item.member_id) > -1;
                                      const disabled = this.state.list_family_id.indexOf(item.id) > -1;
                                      return (
                                        <TableRow className="cursor_pointer" key={index}>
                                          <TableCell>
                                            <Checkbox
                                              checked={checked}
                                              disabled={disabled}
                                              color="primary"
                                              onChange={(e) => this.handleChecked(e.target.checked, item)}
                                              inputProps={{'aria-label': 'secondary checkbox'}}
                                            />
                                          </TableCell>
                                          <TableCell>{item.member_id}</TableCell>
                                          <TableCell>{item.email ? item.email : DATA_NULL}</TableCell>
                                          <TableCell>{
                                            !item.last_name && !item.first_name ?
                                            DATA_NULL :
                                            (item.last_name || '') + (item.first_name ? (item.last_name ? ' ' : '') + item.first_name : '')
                                          }</TableCell>
                                          <TableCell>{item.mobile ? item.mobile : DATA_NULL}</TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </div>
                            </Grid>
                          )}
                          {this.state.listMembers.length > 0 && (
                            <CustomPagination
                              isDisabledRowPerPage={false}
                              onChange={this.handleChangePage}
                              rows={this.state.total_size}
                              rowsPerPage={this.state.rowsPerPage}
                              currentPage={this.state.page}
                            />
                          )}
                          {this.state.no_result && <h5 className="text_grey">{t('reservationManagement.no_result')}</h5>}
                        </Grid>
                      </Grid>
                      <Grid container alignItems="flex-start">
                        <Grid container justify="center" alignItems="center" item xs={12}>
                          {this.state.list_family_code.length !== 0 && (
                            <Button variant="contained" className="button_margin" color="primary" onClick={() => this.setState({confirmModal: true})}>
                              {t('common.btnOk')}
                            </Button>
                          )}
                          <Button variant="contained" className="button_color button_magin" style={{marginLeft: 5}} color="primary" onClick={() => this.props.onClickClose()}>
                            {t('common.btnCancel')}
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Dialog
                      open={this.state.confirmModal}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: '1020',
                      }}
                    >
                      <SelectModal
                        okButtonText={`${t('common.btnSend')}`}
                        cancelButtonText={`${t('common.btnReturn')}`}
                        onClickOk={() => {
                          this.setState({confirmModal: false});
                          this.props.onClickOk(this.state.list_family_code);
                        }}
                        onClickCancel={() => this.setState({confirmModal: false})}
                        message={t('sub.add_family_confirm')}
                      ></SelectModal>
                    </Dialog>
                  </Grid>
                </Box>
              </Container>
            </Card>
          </Box>
        </Container>
      </Paper>
    );
  }
}
