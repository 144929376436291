import qs from 'qs';

import store from '../stores/configureStore';
import {setMessageModal} from '../stores/modal/actions';
import {logout} from '../utils/authenticator';
import {modalObj} from '../utils/modal.js';

const axios = require('axios');

const LOGGER = require('../utils/logger');

const validateStatus = (status) => {
  return status === 200 || status === 400 || status === 401 || status === 500;
};

// common base instance
const BASE_URL = process.env.REACT_APP_HOST + 'api/';
const HEADERS_MULTIPLE_PART = {
  'Content-Type': 'multipart/form-data; boundary=something',
};

const instance = axios.create({
  'baseURL': BASE_URL,
  'contentType': 'application/json',
  'accept': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'paramsSerializer': (params) => {
    return qs.stringify(params, {arrayFormat: 'repeat'});
  },
});

// Add a request interceptor
instance.interceptors.request.use(
  function(config) {
    if (localStorage.getItem('TOKEN_KEY')) {
      config.headers['Authorization'] = localStorage.getItem('TOKEN_KEY');
      config.headers['x-auth-token'] = localStorage.getItem('TOKEN_KEY');
      config.headers['Accept-Language'] = localStorage.getItem('i18nextLng');
    }
    // Do something before request is sent
    LOGGER.log('******Header=', JSON.parse(JSON.stringify(config)));
    return config;
  },
  function(error) {
    // Các trường hợp lỗi 5xx, 4xx, network xử lý ở đây
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
instance.interceptors.response.use(
  async function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    const originalRequest = response.config;
    if (response.status === 200) {
      return response;
    } else if (response.status === 500) {
      store.dispatch(setMessageModal(modalObj(true, 'error.500')));
    } else if (response.status === 401) {
      originalRequest._retry = true;
      const refresh = await refreshAccessToken();
      if (refresh.status === 200) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + refresh.result.access_token;
        localStorage.setItem('TOKEN_KEY', 'Bearer ' + refresh.result.access_token);
        localStorage.setItem('refresh_token', refresh.result.refresh_token);
        return instance(originalRequest);
      } else {
        // unauthorize
        store.dispatch(setMessageModal(modalObj(true, 'error.401')));
        logout(true);
      }
    } else {
      store.dispatch(setMessageModal(modalObj(true, 'error.common')));
    }
  },
  function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error?.response?.status === 408 || !error?.response || error?.code === 'ECONNABORTED') {
      store.dispatch(setMessageModal(modalObj(true, 'error.timeout')));
    } else {
      store.dispatch(setMessageModal(modalObj(true, 'error.common')));
    }
    return Promise.reject(error);
  },
);

const api = {
  post: (endpoint, params) => {
    LOGGER.log('******Post=', JSON.parse(JSON.stringify(endpoint)));
    LOGGER.log('******Params=', JSON.parse(JSON.stringify(params)));
    return instance
      .post(endpoint, params, {
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          LOGGER.log('******response', JSON.parse(JSON.stringify(response)));
          return response.data;
        },
        (err) => {
          LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
          return err.response || err;
        },
      )
      .catch(
        (response) => {
          LOGGER.error('******response', JSON.parse(JSON.stringify(response)));
          return response.data;
        },
        (err) => {
          LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
          return err.response || err;
        },
      );
  },

  postMultiplePart: (endpoint, params) => {
    LOGGER.log('******postMultiplePart=', JSON.parse(JSON.stringify(endpoint)));
    LOGGER.log('******Params=', JSON.parse(JSON.stringify(params)));
    return instance
      .post(endpoint, params, {
        headers: HEADERS_MULTIPLE_PART,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          LOGGER.log('******response', JSON.parse(JSON.stringify(response)));
          return response.data;
        },
        (err) => {
          LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
          return err.response || err;
        },
      )
      .catch(
        (response) => {
          LOGGER.error('******response', JSON.parse(JSON.stringify(response)));
          return response.data;
        },
        (err) => {
          LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
          return err.response || err;
        },
      );
  },

  postDownloadAttachment: (endpoint, params) => {
    LOGGER.log('******postMultiplePart=', JSON.parse(JSON.stringify(endpoint)));
    LOGGER.log('******Params=', JSON.parse(JSON.stringify(params)));
    return instance
      .post(endpoint, params, {
        responseType: 'arraybuffer',
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          LOGGER.log('******response', JSON.parse(JSON.stringify(response)));
          return response.data;
        },
        (err) => {
          LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
          return err.response || err;
        },
      )
      .catch(
        (response) => {
          LOGGER.error('******response', JSON.parse(JSON.stringify(response)));
          return response.data;
        },
        (err) => {
          LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
          return err.response || err;
        },
      );
  },

  get: (endpoint, params = {}) => {
    LOGGER.log('******Get=', JSON.parse(JSON.stringify(endpoint)));
    LOGGER.log('******Params=', JSON.parse(JSON.stringify(params)));
    return instance
      .get(endpoint, {
        params: params,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          LOGGER.log('******response', JSON.parse(JSON.stringify(response)));
          return response.data;
        },
        (err) => {
          LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
          return err.response || err;
        },
      )
      .catch(
        (response) => {
          LOGGER.error('******response', JSON.parse(JSON.stringify(response)));
          return response.data;
        },
        (err) => {
          LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
          return err.response || err;
        },
      );
  },

  put: (endpoint, params) => {
    LOGGER.log('******Put=', JSON.parse(JSON.stringify(endpoint)));
    LOGGER.log('******Params=', JSON.parse(JSON.stringify(params)));
    return instance
      .put(endpoint, params, {
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          LOGGER.log('******response', JSON.parse(JSON.stringify(response)));
          return response.data;
        },
        (err) => {
          LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
          return err.response || err;
        },
      )
      .catch(
        (response) => {
          LOGGER.error('******response', JSON.parse(JSON.stringify(response)));
          return response.data;
        },
        (err) => {
          LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
          return err.response || err;
        },
      );
  },

  search: (endpoint, params, payload) => {
    LOGGER.log('******Put=', JSON.parse(JSON.stringify(endpoint)));
    LOGGER.log('******Params=', JSON.parse(JSON.stringify(params)));
    LOGGER.log('******Payload=', JSON.parse(JSON.stringify(payload)));
    return instance
      .put(endpoint, payload, {
        params: params,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          LOGGER.log('******response', JSON.parse(JSON.stringify(response)));
          return response.data;
        },
        (err) => {
          LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
          return err.response || err;
        },
      )
      .catch(
        (response) => {
          LOGGER.error('******response', JSON.parse(JSON.stringify(response)));
          return response.data;
        },
        (err) => {
          LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
          return err.response || err;
        },
      );
  },

  delete: (endpoint, params) => {
    LOGGER.log('******Delete=', JSON.parse(JSON.stringify(endpoint)));
    return instance
      .delete(endpoint, {
        data: params,
        validateStatus: (status) => validateStatus(status),
      })
      .then(
        (response) => {
          LOGGER.log('******response', JSON.parse(JSON.stringify(response)));
          return response.data;
        },
        (err) => {
          LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
          return err.response || err;
        },
      )
      .catch(
        (response) => {
          LOGGER.error('******response', JSON.parse(JSON.stringify(response)));
          return response.data;
        },
        (err) => {
          LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
          return err.response || err;
        },
      );
  },
};

const refreshAccessToken = () => {
  const params = new URLSearchParams();
  params.append('grant_type', 'refresh_token');
  params.append('refresh_token', localStorage.getItem('refresh_token'));
  return api.post('auth/login/refresh-token', params);
};

export {api};
