export const ACTION_TYPES = {
  GET_ALL_SERVICE_GROUP_START: 'GET_ALL_SERVICE_GROUP_START',
  GET_ALL_SERVICE_GROUP_SUCCESS: 'GET_ALL_SERVICE_GROUP_SUCCESS',
  GET_ALL_SERVICE_GROUP_FAIL: 'GET_ALL_SERVICE_GROUP_FAIL',

  GET_SERVICE_GROUP_START: 'GET_SERVICE_GROUP_START',
  GET_SERVICE_GROUP_SUCCESS: 'GET_SERVICE_GROUP_SUCCESS',
  GET_SERVICE_GROUP_FAIL: 'GET_SERVICE_GROUP_FAIL',

  UPDATE_SERVICE_GROUP_START: 'UPDATE_SERVICE_GROUP_START',
  UPDATE_SERVICE_GROUP_SUCCESS: 'UPDATE_SERVICE_GROUP_SUCCESS',
  UPDATE_SERVICE_GROUP_FAIL: 'UPDATE_SERVICE_GROUP_FAIL',

  DELETE_SERVICE_GROUP_START: 'DELETE_SERVICE_GROUP_START',
  DELETE_SERVICE_GROUP_SUCCESS: 'DELETE_SERVICE_GROUP_SUCCESS',
  DELETE_SERVICE_GROUP_FAIL: 'DELETE_SERVICE_GROUP_FAIL',
};

const initialState = {
  isLoading: false,
  allServiceGroups: [],
  serviceGroup: {},
};

/**
 * function reduce
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.GET_ALL_SERVICE_GROUP_START:
    case ACTION_TYPES.GET_SERVICE_GROUP_START:
    case ACTION_TYPES.UPDATE_SERVICE_GROUP_START:
    case ACTION_TYPES.DELETE_SERVICE_GROUP_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.GET_ALL_SERVICE_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allServiceGroups: action.allServiceGroups,
      };
    case ACTION_TYPES.GET_SERVICE_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        serviceGroup: action.serviceGroup,
      };
    case ACTION_TYPES.UPDATE_SERVICE_GROUP_SUCCESS:
    case ACTION_TYPES.DELETE_SERVICE_GROUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.GET_ALL_SERVICE_GROUP_FAIL:
      return {
        ...state,
        isLoading: false,
        allServiceGroups: [],
      };
    case ACTION_TYPES.GET_SERVICE_GROUP_FAIL:
      return {
        ...state,
        isLoading: false,
        serviceGroup: {},
      };
    case ACTION_TYPES.UPDATE_SERVICE_GROUP_FAIL:
    case ACTION_TYPES.DELETE_SERVICE_GROUP_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
