import React, {Component} from 'react';

import {Button, Card, Container, Dialog, FormControl, FormHelperText, Grid, MenuItem, Select, TextField} from '@material-ui/core';
import {ArrowBack, CloudUpload} from '@material-ui/icons';
import {Skeleton} from '@material-ui/lab';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {NEWS_SETTING_LANGUAGE, IDENTITY_TYPES, IDENTITY_VERIFICATION_STATUS, PERMISSION_ACTIONS} from '../../../common/constant';
import Memo from '../../../components/memo';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getAllCountryCode} from '../../../stores/common/actions';
import {getIdentityVerification, updateIdentityVerification} from '../../../stores/member/action';
import {onChangeListData, onChangeSelect, onChangeTextField} from '../../../utils/common';
import {convertDatetimeUTC, displayDateTime} from '../../../utils/datetime';

/**
 * IdentityVerificationDetail
 */
class IdentityVerificationDetail extends Component {
  /**
   * Component constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      verificationDetail: null,
      status: '',
      memos: [],
      memoNote: '',
      mailLanguageId: '',
      reason: '',
      hasNewAvatar: null,

      validating: false,
      profileImageLoading: true,
      identityImageLoading: true,
      avatarImageLoading: true,
      isConfirmModalOpen: false,
    };
    this.validator = new SimpleReactValidator();
  };

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    await this.props.getAllCountryCode();
    await this.getVerificationDetail();
  }

  /**
   * getVerificationDetail
   */
  getVerificationDetail = async () => {
    if (this.props.match.params.id) {
      const response = await this.props.getIdentityVerfication(this.props.match.params.id);
      if (response) {
        this.setState({
          verificationDetail: response,
          status: response.status,
          mailLanguageId: response.mail_language_id || response.language_id,
          reason: response.reason || '',
          memos: response.memos,
          hasNewAvatar: !!response.photo_avatar_url,
        });
        this.loadImage(
          response.photo_profile_url,
          'member_profile_image',
          'profileImageLoading',
        );
        this.loadImage(
          response.photo_nric_url,
          'member_identity_image',
          'identityImageLoading',
        );
        if (response.photo_avatar_url) {
          this.loadImage(
            response.photo_avatar_url,
            'member_avatar_image',
            'avatarImageLoading',
          );
        }
      };
    };
  }

  /**
   * loadImage
   * @param {*} url
   * @param {String} imgElementId
   * @param {String} imgTypeLoading
   */
  loadImage = (url, imgElementId, imgTypeLoading) => {
    const img = new Image();
    img.onload = () => {
      this.setState({[imgTypeLoading]: false}, () => {
        const imgElement = document.getElementById(imgElementId);
        imgElement.src = img.src;
      });
    };
    img.src = url;
  }

  /**
   * handleChangeMemos
   * @param {Array} newMemo
   */
  handleChangeMemos = (newMemo) => {
    const {memos} = this.state;
    const {index, field, newValue} = newMemo;
    onChangeListData(this, memos, field, index, newValue);
  };

  /**
   * handleClickUpdate
   */
  onClickUpdate = () => {
    this.setState({validating: true}, () => {
      if (this.validator.allValid()) {
        this.setState({isConfirmModalOpen: true});
      }
    });
  }

  /**
   * handleClickConfirmUpdate
   */
  handleClickConfirmUpdate = async () => {
    this.setState({
      isConfirmModalOpen: false,
      validating: false,
    });
    const updateResponse = await this.updateVerification();
    if (updateResponse.status === 200) await this.getVerificationDetail();
  }

  /**
   * updateVerification
   */
  updateVerification = async () => {
    const {
      verificationDetail,
      status,
      mailLanguageId,
      reason,
      memos,
      memoNote,
    } = this.state;
    const payload = {
      nric_id: verificationDetail.nric_id,
      status,
      mail_language_id: mailLanguageId,
      reason,
      memos,
      note: memoNote,
    };

    return await this.props.updateIdentityVerification(payload);
  };

  /**
   * Render component
   * @return {JSX}
   */
  render() {
    const {t, common, actions, isLoading} = this.props;
    const {
      verificationDetail,
      status,
      memos,
      memoNote,
      mailLanguageId,
      reason,
      hasNewAvatar,
      validating,
      profileImageLoading,
      identityImageLoading,
      avatarImageLoading,
      isConfirmModalOpen,
    } = this.state;
    const permisions = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE_VERIFICATION_RESULT),
    };

    const isStatusUnverified = verificationDetail?.status === IDENTITY_VERIFICATION_STATUS[0].id;

    return (
      <>
        <Container maxWidth="xl">
          <Grid container alignItems="center" className="page_header">
            <Grid item>
              <h3>{t('memberManagement.identityVerificationManagement')}</h3>
            </Grid>
          </Grid>
          <br />
          {/* -------------------- Search conditions -------------------- */}
          <Card raised>
            <Container maxWidth="xl">
              <LoadingOverlay active={isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                <br />
                <Grid className="search_table">
                  <Grid container className="row_form_item">
                    <Grid container alignItems="center" className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('memberManagement.identityVerificationDetail')}
                    </Grid>
                  </Grid>
                </Grid>

                {/* Country */}
                <Grid container spacing={1} alignItems="center" className="row_form_item table_background_color_aliceblue">
                  <Grid item xs={6} lg={2} className="grid_title_padding">
                    {t('common.country')}
                  </Grid>
                  <Grid item xs={6} lg={4}>
                    <FormControl margin="dense" variant="outlined" className="field_size_20 field_min_size_350">
                      <Select
                        disabled
                        name='countryId'
                        value={verificationDetail?.country_id || ''}
                      >
                        {common?.country_code?.map(({id, country_code}) => (
                          <MenuItem key={id} value={id}>
                            {t(country_code)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                {/* User code */}
                <Grid container spacing={1} alignItems="center" className="row_form_item table_background_color_aliceblue">
                  <Grid item xs={6} lg={2} className="grid_title_padding">
                    {t('memberManagement.userCode')}
                  </Grid>
                  <Grid item xs={6} lg={4}>
                    <TextField
                      disabled
                      className="field_size_20 field_min_size_350"
                      margin="dense"
                      variant="outlined"
                      name='userCode'
                      value={verificationDetail?.member_id || ''}
                    />
                  </Grid>
                </Grid>

                {/* Registration time */}
                <Grid container spacing={1} alignItems="center" className="row_form_item table_background_color_aliceblue">
                  <Grid item xs={6} lg={2} className="grid_title_padding">
                    {t('memberManagement.registrationTime')}
                  </Grid>
                  <Grid item xs={6} lg={4}>
                    <TextField
                      disabled
                      className="field_size_20 field_min_size_350"
                      margin="dense"
                      variant="outlined"
                      name='registrationDate'
                      value={verificationDetail?.registration_date ?
                        displayDateTime(convertDatetimeUTC(verificationDetail.registration_date, verificationDetail.country_id)) :
                        ''
                      }
                    />
                  </Grid>
                </Grid>

                {/* Application documents */}
                <Grid container spacing={1} alignItems="center" className="row_form_item table_background_color_aliceblue">
                  <Grid item xs={6} lg={2} className="grid_title_padding">
                    {t('memberManagement.applicationDocument')}
                  </Grid>
                  <Grid item xs={6} lg={4}>
                    <FormControl margin="dense" variant="outlined" className="field_size_20 field_min_size_350">
                      <Select
                        disabled
                        name='identityType'
                        value={verificationDetail?.image_type || ''}
                      >
                        {IDENTITY_TYPES.map(({id, i18n}) => (
                          <MenuItem key={id} value={id}>{t(i18n)}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                {/* NRIC/Passport Number */}
                <Grid container spacing={1} alignItems="center" className="row_form_item table_background_color_aliceblue">
                  <Grid item xs={6} lg={2} className="grid_title_padding">
                    {t('memberManagement.identityNumber')}
                  </Grid>
                  <Grid item xs={6} lg={4}>
                    <TextField
                      disabled
                      className="field_size_20 field_min_size_350"
                      margin="dense"
                      variant="outlined"
                      name='identityNumber'
                      value={verificationDetail?.number || ''}
                    />
                  </Grid>
                </Grid>

                {/* Application status */}
                <Grid container spacing={1} alignItems="center" className="row_form_item table_background_color_aliceblue">
                  <Grid item xs={6} lg={2} className="grid_title_padding">
                    {t('memberManagement.applicationStatus')}
                  </Grid>
                  <Grid item xs={6} lg={4}>
                    <FormControl margin="dense" variant="outlined" className="field_size_20 field_min_size_350">
                      <Select
                        disabled={!isStatusUnverified}
                        name='status'
                        value={status || ''}
                        onChange={(e) => onChangeSelect(this, e)}
                      >
                        {IDENTITY_VERIFICATION_STATUS.map(({id, i18n, canSelect}) => (
                          <MenuItem key={id} value={id} style={canSelect ? {} : {display: 'none'}}>
                            {t(i18n)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                {/* Application language */}
                <Grid container spacing={1} alignItems="center" className="row_form_item table_background_color_aliceblue">
                  <Grid item xs={6} lg={2} className="grid_title_padding">
                    {t('memberManagement.applicationLanguage')}
                  </Grid>
                  <Grid item xs={6} lg={4}>
                    <TextField
                      disabled
                      className="field_size_20 field_min_size_350"
                      margin="dense"
                      variant="outlined"
                      name='applicationLanguage'
                      value={t(NEWS_SETTING_LANGUAGE.find(({id}) => id === verificationDetail?.language_id)?.i18n || '')}
                    />
                  </Grid>
                </Grid>

                {/* Mail language */}
                <Grid container spacing={1} alignItems="center" className="row_form_item table_background_color_aliceblue">
                  <Grid item xs={6} lg={2} className="grid_title_padding">
                    {t('memberManagement.mailLanguage')}
                    <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid item xs={6} lg={4}>
                    <FormControl margin="dense" variant="outlined" className="field_size_20 field_min_size_350">
                      <Select
                        displayEmpty
                        renderValue={mailLanguageId ? undefined :
                          () => (
                            <div className="font-12 color-disabled">
                              {t('placeholder.required_select', {field: t('memberManagement.mailLanguage')})}
                            </div>
                          )
                        }
                        disabled={!isStatusUnverified}
                        name='mailLanguageId'
                        value={mailLanguageId || ''}
                        onChange={(e) => onChangeSelect(this, e)}
                      >
                        {NEWS_SETTING_LANGUAGE.map(({id, i18n}) => (
                          <MenuItem key={id} value={id}>
                            {t(i18n)}
                          </MenuItem>
                        ))}
                      </Select>
                      {validating && this.validator.message('mail_language_id', mailLanguageId, 'required')}
                      {validating && !this.validator.check(mailLanguageId, 'required') && (
                        <FormHelperText error id='mail_language_id'>
                          {t('placeholder.required_select', {field: t('memberManagement.mailLanguage')})}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                {/* Reason/Note */}
                <Grid container alignItems='center' spacing={1} className='row_form_item table_background_color_aliceblue'>
                  <Grid item xs={6} lg={2} className='grid_title_padding'>
                    {t('memberManagement.reasonNote')}
                    <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      className='field_min_size_350'
                      margin='dense'
                      variant='outlined'
                      minRows={3}
                      multiline
                      inputProps={{maxLength: 3000}}
                      fullWidth
                      disabled={!isStatusUnverified}
                      name='reason'
                      placeholder={t('placeholder.required', {field: t('memberManagement.reasonNote')})}
                      value={reason || ''}
                      onChange={(e) => onChangeTextField(this, e)}
                    />
                    <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 3000})}</div>
                    {validating && this.validator.message('reason_note', reason, 'required')}
                    {validating && !this.validator.check(reason, 'required') && (
                      <FormHelperText error id='reason_note'>
                        {t('placeholder.required', {field: t('memberManagement.reasonNote')})}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
                <br />
                {hasNewAvatar && (
                  <>
                    <Card>
                      <Container maxWidth='xl'>
                        <Grid container spacing={6} alignItems='center'>
                          <Grid item>
                            <b><i>{t('memberManagement.hasNewAvatar')}</i></b>
                          </Grid>
                          <Grid item>
                            {avatarImageLoading ? (
                              <Skeleton variant="rect" width={100} height={100} />
                            ) : (
                              <img
                                alt='avatar_image'
                                id='member_avatar_image'
                                style={{height: '150px'}}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Container>
                    </Card>
                    <br />
                  </>
                )}
                <Grid container alignItems='center' spacing={2}>
                  <Grid item sm={12} md={6}>
                    <Card>
                      <Grid container alignItems='center' justifyContent='space-evenly' spacing={2}>
                        <Grid item>
                          {t('memberManagement.profileImage')}
                        </Grid>
                        <Grid item>
                          {profileImageLoading ? (
                            <Skeleton variant='rect' width={300} height={300} />
                          ) : (
                            <img
                              alt='profile_image'
                              id='member_profile_image'
                              style={{height: '300px'}}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <Card>
                      <Grid container alignItems='center' justifyContent='space-evenly' spacing={2}>
                        <Grid item>
                          {verificationDetail?.image_type === IDENTITY_TYPES[0] ? t('memberManagement.nricImage') : t('memberManagement.passportImage')}
                        </Grid>
                        <Grid item>
                          {identityImageLoading ? (
                            <Skeleton variant='rect' width={400} height={300} />
                          ) : (
                            <img
                              alt='nric_image'
                              id='member_identity_image'
                              style={{height: '300px', maxWidth: 'inherit'}}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
                <br />
                <Memo
                  memos={memos}
                  note={memoNote}
                  onChangeContent={this.handleChangeMemos}
                  onChangeNote={(value) => {
                    this.setState({memoNote: value});
                  }}
                  parentType='medium'
                  title='memberManagement.memoField'
                />
                <br />
                <Grid container justifyContent='flex-end' spacing={1}>
                  {(permisions.canUpdate && (
                    <Grid item>
                      <Button
                        className='button_margin'
                        variant='contained'
                        color='primary'
                        endIcon={(<CloudUpload />)}
                        onClick={this.onClickUpdate}
                      >
                        {t('memberManagement.btnUpdateVerificationResult')}
                      </Button>
                    </Grid>
                  ))}
                  <Grid item>
                    <Button
                      color="primary"
                      className="button_margin button_color"
                      variant="contained"
                      endIcon={<ArrowBack />}
                      onClick={this.props.history.goBack}
                    >
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                </Grid>
                <br />
                </LoadingOverlay>
            </Container>
          </Card>
          <br />
        </Container>
        <Dialog
          open={isConfirmModalOpen}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1020,
          }}
        >
          <SelectModal
            onClickOk={this.handleClickConfirmUpdate}
            onClickCancel={() => this.setState({isConfirmModalOpen: false})}
            message={t('messageCode.updateConfirm', {field: t('memberManagement.identityVerificationDetail')})}
          />
        </Dialog>
      </>
    );
  };
};

const mapStateToProps = (state) => {
  return {
    common: state.common,
    isLoading: state.member.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getIdentityVerfication: (id) => dispatch(getIdentityVerification(id)),
    updateIdentityVerification: (payload) => dispatch(updateIdentityVerification(payload)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(IdentityVerificationDetail)));
