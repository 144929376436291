import React, {Component} from 'react';

import {Card, Container, Grid, Table, TableCell, TableRow, Button, TableHead, TableBody} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DetailIcon from '@material-ui/icons/Description';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {ROUTE, PERMISSION_ACTIONS, DATA_NULL} from '../../../common/constant';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getAllCountryCode} from '../../../stores/common/actions';
import {isRoleGlobal} from '../../../utils/role';

/**
 * Menu App Search Component
 */
class Index extends Component {
  /**
   * componentDidMount
   */
  componentDidMount() {
    this.props.getAllCountryCode();
  }

  /**
   * render component
   * @return {HTML}
   */
  render() {
    const {t, common, actions, principal} = this.props;
    const listCountry = !isRoleGlobal() ? common?.country_code.filter((item) => item.id === principal.country_id) : common?.country_code;
    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('menu_app.titleManagement')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          {permission.canSearch && (
            <Container maxWidth="md">
              <LoadingOverlay active={common.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                <div className="scroll_area_700">
                  <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                    <TableHead>
                      <TableRow>
                        <TableCell className="width_150p" align="center">
                          {t('common.country')}
                        </TableCell>
                        {permission.canEdit && (
                          <TableCell className="width_150p" align="center">
                            {t('common.action')}
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listCountry?.map((row, index) => (
                        <TableRow key={index} hover className="cursor_pointer">
                          <TableCell>{t(row.country_code) || DATA_NULL}</TableCell>
                          {permission.canEdit && (
                            <TableCell align="center">
                              <Link
                                style={{textDecoration: 'none'}}
                                to={{
                                  pathname: ROUTE.LAYOUT + ROUTE.MENU_APP_DETAIL + '/' + row.id,
                                  state: {from: this.props.location.pathname},
                                }}
                              >
                                <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                  {t('menu_app.country_setting')}
                                </Button>
                              </Link>
                              <Link
                                style={{textDecoration: 'none'}}
                                to={{
                                  pathname: ROUTE.LAYOUT + ROUTE.MENU_APP_GEOFENCE + `/${row.id}`,
                                  state: {from: this.props.location.pathname},
                                }}
                              >
                                <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                  {t('menu_app.geofence_setting')}
                                </Button>
                              </Link>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <br></br>
                </div>
              </LoadingOverlay>
            </Container>
          )}
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
