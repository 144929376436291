import React, {Component} from 'react';

import {Dialog} from '@material-ui/core';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';

import {ROUTE, ROUTER_KEY} from '../common/constant';
import MessageModal from '../components/messageModal';
import NavMenu from '../components/navMenu/index';
import routes from '../router.js';
import {searchBookingReset, updateBooking} from '../stores/reservation/actions';
import {checkAuth, isAuth} from '../utils/authenticator';
import NotFound from '../views/notfound';

/**
 * Main Layout component
 */
class Index extends Component {
  /**
   * getSubMenuActive
   * @param {object} routerApi
   * @return {object}
   */
  getSubMenuActive = (routerApi) => {
    const listSubMenu = [];
    for (const item of routerApi) {
      for (const subMenu of item.sub_functions) {
        subMenu.path && listSubMenu.push(subMenu.path);
      }
    }
    return listSubMenu;
  };

  /**
   * get list router for MavMenu
   * @param {object} routerApi
   * @param {object} routerBase
   * @return {object}
   */
  getRouterNavMenu = (routerApi, routerBase) => {
    const routerNavMenu = routerBase.filter((route) => route.key === ROUTER_KEY.PAGE_LAYOUT);
    return routerNavMenu.filter((id1) => routerApi.some((id2) => id1.path === id2.path));
  };

  /**
   * get routes
   * @param {routes} r
   * @return {Route} Route
   */
  getRoutes = (r) => {
    return r.map((prop, key) => {
      if (prop.layout === ROUTE.LAYOUT) {
        const currentRoute = this.getCurrentRoute();
        if (_.isEmpty(currentRoute)) return null;
        if (!prop.subMenu || !currentRoute) {
          return <Route exact path={prop.layout + prop.path} render={(props) => <prop.component {...props} />} key={key} />;
        } else {
          return <Route exact path={currentRoute.layout + currentRoute.path} render={(props) => <currentRoute.component {...props} />} key={key} />;
        }
      }
      return null;
    });
  };

  /**
   * getCurrentRoute
   * @return {String}
   */
  getCurrentRoute = () => {
    for (let i = 0; i < routes.length; i++) {
      const route = routes[i];
      if (route?.subMenu) {
        for (let j = 0; j < route.subMenu.length; j++) {
          if (this.props.location.pathname.includes(route.subMenu[j].layout + route.subMenu[j].pathActive)) {
            return route.subMenu[j];
          }
        }
      } else {
        if (this.props.location.pathname.includes(route.layout + route.pathActive)) {
          return route;
        }
      }
    }
    return [];
  };

  /**
   * componentDidMount
   */
  componentDidMount() {
    const currentRoute = this.getCurrentRoute();
    if (!this.props.i18n.languages.includes(localStorage.getItem('i18nextLng'))) {
      this.props.i18n.changeLanguage('ja');
    }
    if (currentRoute.name !== 'route.login') {
      if (currentRoute.key !== ROUTER_KEY.PAGE_VIEW && !localStorage.getItem('TOKEN_KEY')) {
        checkAuth();
      }
    } else {
      if (isAuth()) {
        this.props.history.push('/');
      }
    }
  }

  /**
   * componentDidUpdate
   */
  componentDidUpdate() {
    const currentRoute = this.getCurrentRoute();
    if (
      currentRoute.pathActive === ROUTE.RESERVATION_MANAGEMENT ||
      currentRoute.pathActive === ROUTE.RESERVATION_MANAGEMENT_SEARCH ||
      currentRoute.pathActive === ROUTE.RESERVATION_MANAGEMENT_DETAIL ||
      !currentRoute.pathActive.includes('/reservation')
    ) {
      this.props.searchBookingReset();
      this.props.updateBooking({});
    }
  }

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {home} = this.props;
    const currentRoute = this.getCurrentRoute();
    return (
      <>
        {this.getCurrentRoute().key === ROUTER_KEY.PAGE_VIEW ? (
          <div>
            <Switch currentRoute={currentRoute}>
              {this.getRoutes(routes)}
              <Route component={NotFound} />
            </Switch>
            <Dialog
              open={this.props.modal.flgMessage}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '1020',
              }}
            >
              <MessageModal></MessageModal>
            </Dialog>
          </div>
        ) : (
          <div>
            <NavMenu
              routes={this.getRouterNavMenu(home.listScreen, routes)}
              subMenuActive={this.getSubMenuActive(home.listScreen)}
              mainPage={
                <Switch currentRoute={currentRoute}>
                  {this.getRoutes(routes)}
                  <Route component={NotFound} />
                </Switch>
              }
            />
            <Dialog
              open={this.props.modal.flgMessage}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '1020',
              }}
            >
              <MessageModal></MessageModal>
            </Dialog>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
    home: state.home,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchBookingReset: () => dispatch(searchBookingReset()),
    updateBooking: (payload) => dispatch(updateBooking(payload)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
