import {api} from '../services/api';
const loginUrl = `auth/login/admin/email`;
const userDetailUrl = `admin/accounts/registration?uUId=`;
const registrationUrl = `admin/accounts/active`;
const passwordReissueUrl = `admin/accounts/reissue-password?email=`;
const verifyPasswordReissueUrl = `admin/accounts/verify-reissue-password?`;
const newPasswordReissueUrl = `admin/accounts/reissue-password`;
const changePasswordUrl = `auth/user/admin/change-password`;
const homePageUrl = `admin/function`;

export const loginApi = (params) => {
  return api.post(loginUrl, params);
};

export const userDetailApi = (params) => {
  return api.get(userDetailUrl + params);
};

export const registrationApi = (payload) => {
  return api.post(registrationUrl, payload);
};

export const passwordReissueApi = (email) => {
  return api.get(passwordReissueUrl + email);
};

export const verifyPasswordReissueApi = (reissueToken) => {
  return api.get(verifyPasswordReissueUrl + reissueToken);
};

export const newPasswordReissueApi = (params) => {
  return api.post(newPasswordReissueUrl, params);
};

export const changePasswordAPI = (params) => {
  return api.put(changePasswordUrl, params);
};

export const homePageApi = () => {
  return api.get(homePageUrl);
};
