export const ACTION_TYPES = {
  GET_DELIVERY_SETTING_START: 'GET_DELIVERY_SETTING_START',
  GET_DELIVERY_SETTING_SUCCESS: 'GET_DELIVERY_SETTING_SUCCESS',
  GET_DELIVERY_SETTING_FAIL: 'GET_DELIVERY_SETTING_FAIL',

  UPDATE_DELIVERY_SETTING_START: 'UPDATE_DELIVERY_SETTING_START',
  UPDATE_DELIVERY_SETTING_SUCCESS: 'UPDATE_DELIVERY_SETTING_SUCCESS',
  UPDATE_DELIVERY_SETTING_FAIL: 'UPDATE_DELIVERY_SETTING_FAIL',

  GET_LIST_AREA_START: 'GET_LIST_AREA_START',
  GET_LIST_AREA_SUCCESS: 'GET_LIST_AREA_SUCCESS',
  GET_LIST_AREA_FAIL: 'GET_LIST_AREA_FAIL',
};

const initialState = {
  isLoading: false,
  listDeliveryArea: [],
  deliverySetting: {},
  error: '',
};

/**
 * function reduce change password
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.GET_LIST_AREA_START:
    case ACTION_TYPES.UPDATE_LIST_DELIVERY_AREA_START:
    case ACTION_TYPES.GET_DELIVERY_SETTING_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.GET_LIST_AREA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listDeliveryArea: action.listDeliveryArea,
        error: '',
      };
    case ACTION_TYPES.GET_DELIVERY_SETTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deliverySetting: action.deliverySetting,
        error: '',
      };
    case ACTION_TYPES.GET_LIST_AREA_FAIL:
      return {
        isLoading: false,
        error: action.error,
        listDeliveryArea: [],
      };
    case ACTION_TYPES.GET_DELIVERY_SETTING_FAIL:
      return {
        ...state,
        isLoading: false,
        deliverySetting: {},
        error: '',
      };
    case ACTION_TYPES.UPDATE_LIST_DELIVERY_AREA_SUCCESS:
    case ACTION_TYPES.UPDATE_DELIVERY_SETTING_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
