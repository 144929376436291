import React, {Component} from 'react';

import {Grid, TextField, Button} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SendIcon from '@material-ui/icons/Send';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';

import SelectMultiLang from '../../../components/SelectMultiLang/index';
import {sendMailPasswordReissue} from '../../../stores/authen/actions';
import {validateInputEmpty, isValidEmail, onChangeTextField} from '../../../utils/common';
import {redirectRouter} from '../../../utils/common';

/**
 * Login view
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errorFields: {
        emailRequired: false,
        emailFormat: false,
      },
      flag: false,
      message: '',
    };
  }

  /**
   * send mail
   */
  sendMail() {
    if (!this.validateEmail()) {
      this.setState({
        flag: true,
        message: 'abc',
      });
      this.props.sendMailPasswordReissue(this.state.email, this.props);
    }
  }

  /**
   * validate email
   * @return {boolean}
   */
  validateEmail = () => {
    let flgErr = false;
    const errorField = {
      emailRequired: false,
      emailFormat: false,
    };
    if (validateInputEmpty(this.state.email)) {
      flgErr = true;
      errorField.emailRequired = true;
    }
    if (!isValidEmail(this.state.email)) {
      flgErr = true;
      errorField.emailFormat = true;
    }
    this.setState({...this.state, errorFields: errorField});
    return flgErr;
  }

  /**
   * Render view
   * @return {object}
   */
  render() {
    const {t, authen} = this.props;
    return (
      <LoadingOverlay
        active={authen.isLoading}
        bgColor='#f1f1f1'
        spinnerColor='#9ee5f8'
        textColor='#676767'
        spinner
      >
        <div style={{margin: 10}}>
          <Container maxWidth="md">
            <Grid container spacing={2}>
              <Grid item xs={11} justify="center" alignItems="center" container>
                <br></br>
                <br></br>
              </Grid>
              <Grid item xs={1} justify="flex-end" alignItems="center" container>
                <SelectMultiLang />
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item container xs={12} alignItems="center" justify="center">
                <img
                  src={`${process.env.PUBLIC_URL}/logoheader.png`}
                  alt="Logo"
                ></img>
              </Grid>
              <Grid
                item
                container
                xs={1}
                alignItems="center"
                justify="center"
                className="border_bottom"
              >
                <Button
                  variant="contained"
                  color="primary"
                  className="button_color"
                  onClick={() => redirectRouter(this.props, '/maas/login')}
                >
                  <ArrowBackIcon />
                </Button>
              </Grid>
              <Grid
                item
                container
                xs={10}
                alignItems="center"
                justify="center"
                className="border_bottom"
              >
                <h3>{t('passwordReissue.title')}</h3>
              </Grid>
              <Grid
                item
                container
                xs={1}
                alignItems="center"
                justify="center"
                className="border_bottom"
              ></Grid>
              <Grid item container xs={12} alignItems="center" justify="center">
                <br />
              </Grid>
              <Grid
                item
                container
                xs={12}
                alignItems="center"
                justify="center"
                className="font_size_small_regular"
              >
                {t('passwordReissue.msg')} <br></br>{t('passwordReissue.msg_child')}
              </Grid>

              <Grid item container xs={12} alignItems="center" justify="center">
                <br />
              </Grid>

              <Grid item container xs={12} alignItems="center" justify="center">
                <TextField
                  label={t('common.email')}
                  margin="dense"
                  className="field_size_50"
                  variant="outlined"
                  value={this.state.email}
                  inputProps={{maxLength: 256}}
                  name="email"
                  onChange={(event) => onChangeTextField(this, event)}
                  error={this.state.errorFields.emailRequired || this.state.errorFields.emailFormat}
                  helperText={
                  this.state.errorFields.emailRequired ? (
                    t('errorFields.emailRequired')
                  ) : (
                    this.state.errorFields.emailFormat ?
                    (t('errorFields.emailFormat')) :
                    ('')
                  )
                  }
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item container xs={12} alignItems="center" justify="center">
                <br />
              </Grid>
              <Grid item container xs={12} alignItems="center" justify="center">
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<SendIcon />}
                  onClick={() => this.sendMail()}
                >
                  {t('common.btnSend')}
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item container xs={12} alignItems="center" justify="center">
                <br />
              </Grid>
              <Grid
                item
                container
                xs={12}
                alignItems="center"
                justify="center"
                className="font_size_small_regular font_color_blue"
              >
                {t('passwordReissue.msgFooter')}
              </Grid>
            </Grid>
          </Container>
        </div>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authen: state.authen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendMailPasswordReissue: (email, props) => dispatch(sendMailPasswordReissue(email, props)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
