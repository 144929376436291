import {ROUTE} from '../../common/constant';
import {
  getBanksApi,
  createBankApi,
  updateBankApi,
  getBankDetailApi,
} from '../../services/commonSettingServices';
import {modalObj} from '../../utils/modal';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

/**
 * getAllBanks
 * @return {Object}
 */
 export const getAllBanks = () => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_ALL_BANKS_START,
    });
    return getBanksApi().then(
        (response) => {
          if (response.status === 200 && response) {
            dispatch({
              type: ACTION_TYPES.GET_ALL_BANKS_SUCCESS,
              allBanks: response.result,
            });
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
            dispatch({type: ACTION_TYPES.GET_ALL_BANKS_FAIL});
          }
        },
        () => {
          dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
          dispatch({type: ACTION_TYPES.GET_ALL_BANKS_FAIL});
        },
    );
  };
};

/**
 * getBank
 * @param {Number} id
 * @return {Object}
 */
 export const getBank = (id) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_BANK_START,
    });
    return getBankDetailApi(id).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_BANK_SUCCESS,
              bank: response.result,
            });
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
            dispatch({type: ACTION_TYPES.GET_BANK_FAIL});
          }
        },
        () => {
          dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
          dispatch({type: ACTION_TYPES.GET_BANK_FAIL});
        },
    );
  };
};

/**
 * createBank
 * @param {object} payload
 * @param {object} props
 * @return {object}
 */
 export const createBank = (payload, props) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.CREATE_BANK_START,
    });
    return createBankApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.CREATE_BANK_SUCCESS,
            });
            const prevPath = ROUTE.LAYOUT + ROUTE.BANK_MANAGEMENT;
            props.history.push(prevPath);
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.CREATE_BANK_FAIL});
          }
        },
        () => {
          dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
          dispatch({type: ACTION_TYPES.CREATE_BANK_FAIL});
        },
    );
  };
};

/**
 * updateBank
 * @param {object} payload
 * @param {object} props
 * @return {object}
 */
 export const updateBank = (payload, props) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_BANK_START,
    });
    return updateBankApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.UPDATE_BANK_SUCCESS,
            });
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.UPDATE_BANK_FAIL});
          }
        },
        () => {
          dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
          dispatch({type: ACTION_TYPES.UPDATE_BANK_FAIL});
        },
    );
  };
};
