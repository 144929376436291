import {ROUTE} from '../../common/constant';
import {getListPaymentMethodApi} from '../../services/deliverySettingService';
import {searchListDeliveryApi, getDeliveryApi, updateDeliveryAPI, createDeliveryAPI, deleteDeliveryAPI, updateListTimeSettingProductApi} from '../../services/productServices';
import {modalObj} from '../../utils/modal.js';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

/**
 * searchListDelivery
 * @param {object} params
 * @param {object} queryParams
 * @return {object}
 */
export const searchListDelivery = (params, queryParams) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.SEARCH_LIST_DELIVERY_START});
    return searchListDeliveryApi(params, queryParams).then(
        (data) => {
          if (data && data.status === 200) {
            dispatch({
              type: ACTION_TYPES.SEARCH_LIST_DELIVERY_SUCCESS,
              listDelivery: data.result,
            });
            return data.result;
          } else {
            dispatch(setMessageModal(modalObj(true, data.message_code)));
            dispatch({
              type: ACTION_TYPES.SEARCH_LIST_DELIVERY_FAIL,
              error: data.message,
            });
          }
        },
        () => dispatch({
          type: ACTION_TYPES.SEARCH_LIST_DELIVERY_FAIL,
          error: 'Internal Server Error',
        }),
    );
  };
};

/**
 * Export list delivery
 * @param {object} payload
 * @param {object} queryParams
 * @return {object}
 */
export const exportDelivery = (payload, queryParams) => {
  return (dispatch) => {
    return searchListDeliveryApi(payload, queryParams).then(
        (response) => {
          if (response && response.status === 200) {
            return response.result?.content;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
          }
        },
        () => dispatch(setMessageModal(modalObj(true, 'Internal Server Error'))),
    );
  };
};

/**
 * get detail delivery
 * @param {object} params
 * @return {object}
 */
export const getDelivery = (params) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_DELIVERY_START});
    return getDeliveryApi(params).then(
        (data) => {
          if (data && data.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_DELIVERY_SUCCESS,
              delivery: data.result,
            });
            return data.result;
          } else {
            dispatch(setMessageModal(modalObj(true, data.message)));
            dispatch({
              type: ACTION_TYPES.GET_DELIVERY_FAIL,
              error: data.message,
            });
          }
        },
        () => dispatch({
          type: ACTION_TYPES.GET_DELIVERY_FAIL,
          error: 'Internal Server Error',
        }),
    );
  };
};

/**
 * updateDelivery
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const updateDelivery = (params, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.UPDATE_DELIVERY_START});
    return updateDeliveryAPI(params).then(
        (data) => {
          if (data && data.status === 200) {
            dispatch(setMessageModal(modalObj(true, data.message_code)));
            dispatch({type: ACTION_TYPES.UPDATE_DELIVERY_SUCCESS});
          } else {
            dispatch(setMessageModal(modalObj(true, data.message)));
            dispatch({type: ACTION_TYPES.UPDATE_DELIVERY_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.UPDATE_DELIVERY_FAIL}),
    );
  };
};

/**
 * createDelivery
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const createDelivery = (params) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.CREATE_DELIVERY_START});
    return createDeliveryAPI(params).then(
        (data) => {
          if (data && data.status === 200) {
            dispatch({type: ACTION_TYPES.CREATE_DELIVERY_SUCCESS});
            return data.result;
          } else {
            dispatch(setMessageModal(modalObj(true, data.message)));
            dispatch({type: ACTION_TYPES.CREATE_DELIVERY_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.CREATE_DELIVERY_FAIL}),
    );
  };
};

/**
 * deleteDelivery
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const deleteDelivery = (params, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.DELETE_DELIVERY_START});
    return deleteDeliveryAPI(params).then(
        (data) => {
          if (data && data.status === 200) {
            dispatch(setMessageModal(modalObj(true, data.message_code)));
            dispatch({type: ACTION_TYPES.DELETE_DELIVERY_SUCCESS});
            props.history.push(ROUTE.LAYOUT + ROUTE.PRODUCT_DELIVERY);
          } else {
            dispatch(setMessageModal(modalObj(true, data.message)));
            dispatch({type: ACTION_TYPES.DELETE_DELIVERY_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.DELETE_DELIVERY_FAIL}),
    );
  };
};

/**
 * deleteDelivery
 * @return {object}
 */
export const getPaymentMethod = () => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_LIST_PAYMENT_METHOD_START});
    return getListPaymentMethodApi().then(
        (data) => {
          if (data && data.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_LIST_PAYMENT_METHOD_SUCCESS,
              listPayment: data.result,
            });
            return data.result;
          } else {
            dispatch({type: ACTION_TYPES.GET_LIST_PAYMENT_METHOD_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.GET_LIST_PAYMENT_METHOD_FAIL}),
    );
  };
};

/**
 * updateListTimeSetting
 * @param {*} payload
 * @param {*} props
 * @return {*}
 */
export const updateListTimeSetting = (payload, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.UPDATE_LIST_TIME_SETTING_START});
    return updateListTimeSettingProductApi(payload).then(
        (data) => {
          if (data && data.status === 200) {
            dispatch(setMessageModal(modalObj(true, data.message_code)));
            dispatch({type: ACTION_TYPES.UPDATE_LIST_TIME_SETTING_SUCCESS});
          } else {
            dispatch(setMessageModal(modalObj(true, data.message)));
            dispatch({type: ACTION_TYPES.UPDATE_LIST_TIME_SETTING_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.UPDATE_LIST_TIME_SETTING_FAIL}),
    );
  };
};
