import {ROUTE} from '../../common/constant';
import {
  searchAccountTypeApi,
  getAccountTypeFunctionListApi,
  createAccountTypeApi,
  updateAccountTypeApi,
  getAccountTypeDetailsApi,
  deleteAccountTypeApi,
} from '../../services/accountTypeServices';
import {modalObj} from '../../utils/modal.js';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

/**
 * Search account type action
 * @param {object} payload
 * @param {object} queryParams
 * @return {Promise}
 */
export const searchAccountTypeList = (payload, queryParams) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.SEARCH_ACCOUNT_TYPE_START});
    return searchAccountTypeApi(payload, queryParams).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({type: ACTION_TYPES.SEARCH_ACCOUNT_TYPE_SUCCESS, accountTypeList: response.result.content});
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.SEARCH_ACCOUNT_TYPE_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.SEARCH_ACCOUNT_TYPE_FAIL}),
    );
  };
};

/**
 * getAccountTypeFunctionList
 * @return {Promise}
 */
export const getAccountTypeFunctionList = () => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_FUNCTION_LIST_START});
    return getAccountTypeFunctionListApi().then(
        (response) => {
          if (response.status === 200 && response) {
            dispatch({type: ACTION_TYPES.GET_FUNCTION_LIST_SUCCESS, payload: response.result});
            return response.result;
          } else {
            dispatch({type: ACTION_TYPES.GET_FUNCTION_LIST_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.GET_FUNCTION_LIST_FAIL}),
    );
  };
};

/**
 * createAccountType
 * @param {object} payload
 * @param {object} props
 * @return {Promise}
 */
export const createAccountType = (payload, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.CREATE_ACCOUNT_TYPE_START});
    return createAccountTypeApi(payload).then(
        (response) => {
          if (response.status === 200 && response) {
            dispatch({type: ACTION_TYPES.CREATE_ACCOUNT_TYPE_SUCCESS});
            const prevPath = props.location?.state?.from ? props.location?.state?.from : ROUTE.LAYOUT + ROUTE.SEARCH_ACCOUNT_TYPE;
            props.history.push(prevPath);
          } else {
            dispatch({type: ACTION_TYPES.CREATE_ACCOUNT_TYPE_FAIL});
          }
          dispatch(setMessageModal(modalObj(true, response.message_code)));
        },
        () => dispatch({type: ACTION_TYPES.CREATE_ACCOUNT_TYPE_FAIL}),
    );
  };
};

/**
 * updateAccountType
 * @param {object} payload
 * @param {object} props
 * @return {Promise}
 */
export const updateAccountType = (payload, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.UPDATE_ACCOUNT_TYPE_START});
    return updateAccountTypeApi(payload).then(
        (response) => {
          if (response.status === 200 && response) {
            dispatch({type: ACTION_TYPES.UPDATE_ACCOUNT_TYPE_SUCCESS});
          } else {
            dispatch({type: ACTION_TYPES.UPDATE_ACCOUNT_TYPE_FAIL});
          }
          dispatch(setMessageModal(modalObj(true, response.message_code)));
        },
        () => dispatch({type: ACTION_TYPES.UPDATE_ACCOUNT_TYPE_FAIL}),
    );
  };
};

/**
 * getAccountTypeDetails
 * @param {object} id
 * @param {object} props
 * @return {Promise}
 */
export const getAccountTypeDetails = (id) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_ACCOUNT_TYPE_DETAILS_START});
    return getAccountTypeDetailsApi(id).then(
        (response) => {
          if (response.status === 200 && response) {
            dispatch({type: ACTION_TYPES.GET_ACCOUNT_TYPE_DETAILS_SUCCESS, payload: response?.result});
            return response?.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.GET_ACCOUNT_TYPE_DETAILS_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.GET_ACCOUNT_TYPE_DETAILS_FAIL}),
    );
  };
};

/**
 * updateAccountType
 * @param {object} id
 * @return {Promise}
 */
export const deleteAccountType = (id) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.DELETE_ACCOUNT_TYPE_START});
    return deleteAccountTypeApi(id).then(
        (response) => {
          if (response.status === 200 && response) {
            dispatch({type: ACTION_TYPES.DELETE_ACCOUNT_TYPE_SUCCESS});
          } else {
            dispatch({type: ACTION_TYPES.DELETE_ACCOUNT_TYPE_FAIL});
          }
          dispatch(setMessageModal(modalObj(true, response.message_code)));
        },
        () => dispatch({type: ACTION_TYPES.DELETE_ACCOUNT_TYPE_FAIL}),
    );
  };
};
