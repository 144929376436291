import React, {Component} from 'react';

import {Container, Card, Grid, Button, TextField, Modal, Backdrop, FormControl, Select, MenuItem, Box, FormHelperText} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LensIcon from '@material-ui/icons/Lens';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import {format} from 'date-fns';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import 'date-fns';
import SimpleReactValidator from 'simple-react-validator';

import {DATA_NULL, ROUTE, PERMISSION_ACTIONS, ODM_BOOKING_TYPE, VEHICLE_MOBI_TYPES} from '../../../common/constant';
import ImportCSV from '../../../components/importCSV';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getAllCountryCode, getAllGeofence} from '../../../stores/common/actions';
import {importDriverCSV} from '../../../stores/driver/action';
import {registerVehicle, searchListDriverVehicle} from '../../../stores/driver_vehicle_association/action';
import {clearBlankValue, getQueryStringFromObject, changeUrlParams, getUrlParams, onChangeTextField, onChangeSelect} from '../../../utils/common';
import {convertDateUTCAndGeofence} from '../../../utils/date_time_utils';
import {convertPrefixTime} from '../../../utils/datetime';
import './style.css';
import {isRoleGlobal} from '../../../utils/role';

const classNameCustomNotScroll = 'ant-table-cell-fix-left-last ant-table-cell-fix-left';

/**
 * Driver&Vehicle Association Search Component
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      date: format(new Date(), 'yyyy-MM-dd'),
      country_id: '',
      geofence_id: '',
      driver_id: '',
      driver_name_ad: '',
      driver_name_app: '',
      result: [],
      first_column_width: 0,
      classNameCustom: classNameCustomNotScroll,
      vehicle_id: '',
      isSubmitForm: false,

      isOpenModal: false,
      listGeofenceAll: [],
      search: false,
      arrDates: [],
      checkResult: false,
      dataDownload: [],
      background_color: {
        SHUTTLE_BUS_JIT_HOME_TO_WORK: 'rgb(176, 53, 50)',
        SHUTTLE_BUS_ON_DEMAND: '#33a8a5',
        SHUTTLE_BUS_JIT_WORK_TO_HOME: 'rgb(106, 71, 143)',
        TAXI: '#da6f2b',
      },
      searchParams: this.props.location.search,
      dateSearch: format(new Date(), 'yyyy-MM-dd'),
      isLoading: false,
      hourList: ['00:00', '02:00', '04:00', '06:00', '08:00', '10:00', '12:00', '14:00', '16:00', '18:00', '20:00', '22:00', '24:00'],
    };
    this.validator = new SimpleReactValidator();
    this.focusGeofenceField = React.createRef();
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllCountryCode();
    await this.props.getAllGeofence().then(() => this.setState({listGeofenceAll: this.props.common?.all_geofence}));

    if (!isRoleGlobal()) {
      this.setState({country_id: this.props.common.principal?.country_id});
    }

    if (this.props.location.search) {
      this.setState(getUrlParams(this.props.location.search), () => {
        this.state.country_id &&
          isRoleGlobal() &&
          this.setState({
            listGeofenceAll: this.props.common?.all_geofence?.filter((e) => e.country_id === Number(this.state.country_id)),
          });
        this.handleSearch();
      });
    }
  }

  /**
   * handleOnChangeCountry
   * @param {*} value
   */
  handleOnChangeCountry = (value) => {
    this.setState({
      country_id: value,
      geofence_id: '',
      listGeofenceAll: this.props.common?.all_geofence?.filter((e) => e.country_id === value),
    });
  };

  /**
   * getStateFromModal
   * @param {*} status
   */
  getStateFromModal(status) {
    this.setState({
      isOpenModal: false,
    });
    if (status === 'PENDING') {
      this.setState({
        isLoading: true,
      });
    }
    if (status === 'SUCCESS') {
      this.setState({
        isLoading: false,
      });
    }
  }

  /**
   * handleSearch
   */
  handleSearch() {
    const canSearch = this.props.actions.includes(PERMISSION_ACTIONS.SEARCH);
    if (!canSearch) return;
    this.setState({isSubmitForm: true});
    if (this.validator.allValid()) {
      const {driver_id, driver_name_ad, driver_name_app, geofence_id, country_id, vehicle_id} = this.state;
      this.setState({
        dateSearch: this.state.date,
      });
      const payload = {
        from_time: this.state.date,
        driver_id: driver_id?.trim(),
        driver_name: driver_name_ad?.trim(),
        driver_name_app: driver_name_app?.trim(),
        country_id,
        geofence_id,
        vehicle_id,
      };

      this.props.searchListDriverVehicle(payload, this.props.history).then((res) => {
        if (res) {
          this.setState(
            {
              result: res,
              checkResult: true,
            },
            () => {
              const {driver_id, driver_name_ad, driver_name_app, geofence_id, country_id, date} = this.state;
              // Apply changed params into url
              const queryParamsToChange = {
                country_id,
                geofence_id,
                driver_id,
                driver_name_ad,
                driver_name_app,
                from_time: date,
                vehicle_id,
              };
              changeUrlParams(queryParamsToChange);
              const newSearchParams = getQueryStringFromObject(queryParamsToChange);
              // Save search params into state in order to pass to next page
              this.setState({searchParams: newSearchParams});
            },
          );
        }
      });
      this.setState({
        search: true,
      });
    } else {
      if (!this.validator.fieldValid('geofence_id')) {
        this.focusGeofenceField.current.scrollIntoView({behavior: 'smooth'});
      }
    }
  }

  /**
   * resetSearch
   */
  resetSearch = () => {
    this.setState({
      searchResult: [],
      totalRows: 0,
      country_id: isRoleGlobal() ? '' : this.state.country_id,
      geofence_id: '',
      driver_id: '',
      driver_name_ad: '',
      driver_name_app: '',
      vehicle_id: '',

      listGeofenceAll: this.props.common?.all_geofence,
      isSearch: false,
    });
  };

  handleOpenModal = () => {
    this.setState({
      isOpenModal: true,
    });
  };

  handleCloseModal = () => {
    this.setState({
      isOpenModal: false,
    });
  };

  /**
   * dynamicSort
   * @param {*} property
   * @return {*}
   */
  dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function(a, b) {
      /* next line works with strings and numbers,
       * and you may want to customize it to your needs
       */
      const result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }

  /**
   * checkTitleName
   * @param {*} item
   * @param {*} operation_type
   * @return {*}
   */
  checkTitleName = (item, operation_type) => {
    const {t} = this.props;
    switch (item) {
      case 'SHUTTLE_BUS_JIT_HOME_TO_WORK':
        return t('businessVehicle.mobiType.SHUTTLE_BUS_JIT_HOME_TO_WORK');
      case 'SHUTTLE_BUS_ON_DEMAND':
        if (operation_type === ODM_BOOKING_TYPE[0].id) {
          return t('driverAssociation.onDemandRealTime');
        } else if (operation_type === ODM_BOOKING_TYPE[1].id) {
          return t('driverAssociation.onDemandAdvance');
        } else {
          return t('businessVehicle.mobiType.SHUTTLE_BUS_ON_DEMAND');
        }
      case 'SHUTTLE_BUS_JIT_WORK_TO_HOME':
        return t('businessVehicle.mobiType.SHUTTLE_BUS_JIT_WORK_TO_HOME');
      default:
        return t('businessVehicle.mobiType.TAXI');
    }
  };

  /**
   * initialChart
   * @param {*} drivers
   * @return {*}
   */
  initialChart(drivers) {
    const date = this.state.dateSearch;
    const first_hour = date + ' 00:00:00';
    const last_hour = date + ' 23:59:59';
    const li = document.querySelectorAll('.chart-values li');
    let offset_driver_id_li = 0;
    let offset_os_version = 0;
    let offset_driver_app_li = 0;
    let offset_driver_admin_li = 0;
    let offset_number_register_li = 0;

    // all chart width
    let chartWidth = 0;
    li.forEach((el, index) => {
      if (index === 0) {
        offset_driver_id_li = el.offsetWidth;
      }
      if (index === 1) {
        offset_os_version = el.offsetWidth;
      }
      if (index === 2) {
        offset_driver_app_li = el.offsetWidth;
      }
      if (index === 3) {
        offset_driver_admin_li = el.offsetWidth;
      }
      if (index === 4) {
        offset_number_register_li = el.offsetWidth;
      }
      if (index > 4) {
        chartWidth += el.offsetWidth;
      }
    });
    const eachItemForHour = chartWidth / 24; // pixel per hour

    const data = [];
    drivers.forEach((driver) => {
      const each_driver = {}; // {driver_name: '', times: [item1,...,item n]}
      each_driver.driver_code = driver.driver_code;
      each_driver.last_device_info = driver.last_device_info;
      each_driver.driver_name_app = driver.driver_name_app;
      each_driver.driver_name = driver.driver_name;
      each_driver.registration_number = driver.registration_number;
      each_driver.offset_driver_id_li = offset_driver_id_li;
      each_driver.offset_os_version = offset_os_version;
      each_driver.offset_driver_app_li = offset_driver_app_li;
      each_driver.offset_driver_admin_li = offset_driver_admin_li;
      each_driver.offset_number_register_li = offset_number_register_li;
      each_driver.available = driver.available;
      each_driver.times = [];
      each_driver.merge_times = [];
      const time = [];
      driver.shifts.forEach((shift) => {
        const shift_start_time = convertDateUTCAndGeofence(shift.start_time, driver.zone_id, true);
        const shift_end_time = convertDateUTCAndGeofence(shift.end_time, driver.zone_id, true);
        time.push({start_time: shift_start_time, end_time: shift_end_time});
        if (shift.driver_operations.length > 0) {
          // get operation time of driver
          const sort_times = shift.driver_operations.sort(this.dynamicSort('start_time'));
          for (let i = 0; i <= sort_times.length - 1; i++) {
            const sort_start_time = convertDateUTCAndGeofence(sort_times[i].start_time, driver.zone_id, true);
            const sort_end_time = convertDateUTCAndGeofence(sort_times[i].end_time, driver.zone_id, true);
            let temp_start = '';
            let temp_end = '';
            // start time
            if (Date.parse(sort_start_time) < Date.parse(first_hour)) {
              temp_start = new Date(first_hour);
            } else {
              if (Date.parse(sort_start_time) > Date.parse(last_hour)) {
                temp_start = new Date(last_hour);
              } else {
                temp_start = sort_start_time;
              }
            }
            // time end
            if (Date.parse(sort_end_time) < Date.parse(first_hour)) {
              temp_end = new Date(first_hour);
            } else {
              if (Date.parse(sort_end_time) > Date.parse(last_hour)) {
                temp_end = new Date(last_hour);
              } else {
                temp_end = sort_end_time;
              }
            }
            // display first time in operation
            if (new Date(sort_start_time) !== new Date(shift_start_time) && i === 0) {
              each_driver.merge_times.push({
                vehicle_code: '',
                temp_start: shift_start_time,
                group_type: shift.group_type,
                temp_end: sort_start_time,
                operation_type: shift.operation_type,
              });
            }
            each_driver.merge_times.push({
              vehicle_code: sort_times[i]?.vehicle?.vehicle_id,
              registration_number: sort_times[i]?.vehicle?.registration_number,
              temp_start: temp_start,
              group_type: shift.group_type,
              temp_end: temp_end || shift_end_time,
              operation_type: shift.operation_type,
            });
            // display off vehicle in operation
            if (i < sort_times.length - 1) {
              each_driver.merge_times.push({
                vehicle_code: '',
                temp_start: sort_end_time,
                group_type: shift.group_type,
                temp_end: convertDateUTCAndGeofence(sort_times[i + 1].start_time, driver.zone_id, true),
                operation_type: shift.operation_type,
              });
            }
            // display last time in operation
            if (new Date(sort_end_time) !== new Date(shift_end_time) && i === sort_times.length - 1) {
              each_driver.merge_times.push({
                vehicle_code: '',
                temp_start: sort_end_time,
                group_type: shift.group_type,
                temp_end: shift_end_time,
                operation_type: shift.operation_type,
              });
            }
          }
        } else {
          // get shift time of driver
          each_driver.merge_times.push({
            vehicle_code: '',
            temp_start: shift_start_time,
            group_type: shift.group_type,
            temp_end: shift_end_time,
          });
        }
      });
      each_driver.merge_times.forEach((item) => {
        const sub_time_end_start = (Date.parse(item.temp_end) - Date.parse(item.temp_start)) / (60 * 60 * 1000);
        const sub_time_start_first_hour = (Date.parse(item.temp_start) - Date.parse(first_hour)) / (60 * 60 * 1000);
        if (sub_time_end_start > 0) {
          const widthHour = sub_time_end_start * eachItemForHour;
          const startHour =
            // eslint-disable-next-line max-len
            offset_driver_id_li + offset_os_version + offset_driver_app_li + offset_driver_admin_li + offset_number_register_li + 40 + sub_time_start_first_hour * eachItemForHour; // 40 is ul margin
          const payload = {
            supplier_id: driver.supplier_id,
            facility_id: driver.facility_id,
            driver_code: driver.driver_code,
            driver_name: driver.driver_name,
            time: this.state.date,
          };
          const end_time =
            item.group_type === VEHICLE_MOBI_TYPES.SHUTTLE_BUS_ON_DEMAND ? new Date(new Date(item.temp_end).setSeconds(new Date(item.temp_end).getSeconds() + 1)) : item.temp_end;
          const queryStr = getQueryStringFromObject(clearBlankValue(payload));
          each_driver.times.push(
            <Link
              to={{
                pathname: this.props.actions.includes(PERMISSION_ACTIONS.EDIT) ? ROUTE.LAYOUT + ROUTE.DRIVER_SCHEDULER_REGISTRATION : ROUTE.LAYOUT + ROUTE.ASSOCIATION,
                search: this.props.actions.includes(PERMISSION_ACTIONS.EDIT) ? `?${queryStr}` : this.props.location.search,
                state: this.props.actions.includes(PERMISSION_ACTIONS.EDIT) ? {from: this.props.location.pathname + '?' + this.state.searchParams} : {},
              }}
              style={{color: 'inherit', textDecoration: 'inherit', marginTop: -22}}
            >
              <span
                className={`${widthHour < 50 ? 'text_hidden' : ''} time_data`}
                title={
                  this.checkTitleName(item.group_type, item.operation_type || '') +
                  ' - ' +
                  item.vehicle_code +
                  ' (' +
                  convertPrefixTime(item.temp_start.getHours()) +
                  ':' +
                  convertPrefixTime(item.temp_start.getMinutes()) +
                  ' - ' +
                  convertPrefixTime(end_time.getHours()) +
                  ':' +
                  convertPrefixTime(end_time.getMinutes()) +
                  ')'
                }
                style={{
                  opacity: 1,
                  backgroundColor: this.state.background_color[item.group_type],
                  left: `${startHour}px`,
                  width: `${widthHour}px`,
                  height: '13px',
                  textAlign: 'center',
                  color: 'white',
                  boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                }}
              >
                {item.vehicle_code}
              </span>
            </Link>,
          );
        }
      });
      data.push(each_driver);
    });

    return data;
  }

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t, common, driverVehicleAssociation, actions} = this.props;

    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canRegister: actions.includes(PERMISSION_ACTIONS.REGISTER),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
    };

    return (
      <Container maxWidth="xl">
        <Grid container className="page_header">
          <Grid container alignItems="center" item xs={6}>
            <h3>{t('route.driverVehicleAssociation')}</h3>
          </Grid>
          <Grid container alignItems="center" justify="flex-end" item xs={6}>
            <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
              <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                {t('common.btnReturn')}
              </Button>
            </Link>
          </Grid>
        </Grid>
        <br></br>
        <LoadingOverlay active={driverVehicleAssociation.isLoading || this.state.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
          <Card raised>
            <Container maxWidth="xl">
              {permission.canSearch && (
                <>
                  <br></br>
                  <Grid container className="product_entry_table_header_color font_color_white">
                    <Grid item xs={12} align="center">
                      <Box p={2}>
                        <TextField
                          name="date"
                          type="date"
                          value={this.state.date}
                          className="responsive_min_size"
                          margin="dense"
                          format="YY-mm-dd"
                          inputProps={{maxLength: 256}}
                          onChange={(event) => this.setState({date: event.target.value})}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
              <LoadingOverlay active={false} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                {/* <Paper className="search_table"> */}
                {permission.canSearch && (
                  <>
                    {/* Country */}
                    <Grid container spacing={3} alignItems="center" className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={12} sm={4} md={2} className="grid_title_padding">
                        {t('common.country')}
                      </Grid>
                      <Grid container alignItems="center" item xs={12} sm={8} md={10}>
                        <FormControl variant="outlined" margin="dense" className="responsive_min_size">
                          <Select
                            margin="dense"
                            name="country_id"
                            displayEmpty
                            renderValue={
                              this.state.country_id ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('common.country'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.country_id}
                            onChange={(event) => this.handleOnChangeCountry(event.target.value)}
                            disabled={!isRoleGlobal()}
                          >
                            {common?.country_code?.map((item, idx) => {
                              return (
                                <MenuItem value={item.id} key={idx}>
                                  {t(`${item.country_code}`)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* Geofence */}
                    <Grid container spacing={3} alignItems="center" className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={12} sm={4} md={2} className="grid_title_padding">
                        {t('common.geofence')}<span className="font_color_red">＊</span>
                      </Grid>
                      <Grid container alignItems="center" item xs={12} sm={8} md={10} ref={this.focusGeofenceField}>
                        <FormControl variant="outlined" margin="dense" className="responsive_min_size">
                          <Select
                            margin="dense"
                            name="geofence_id"
                            displayEmpty
                            renderValue={
                              this.state.geofence_id ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('common.geofence'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.geofence_id}
                            onChange={(e) => onChangeSelect(this, e)}
                          >
                            {this.state.listGeofenceAll?.map((item, idx) => {
                              return (
                                <MenuItem value={item.geofence_id} key={idx} className={!item.enable ? 'disable-option-custom' : ''}>
                                  {t(`${item.name}`)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {this.validator.message('geofence_id', this.state.geofence_id, 'required')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.geofence_id, 'required') && (
                            <FormHelperText id="geofence_id" error>
                              {t('validation.required.choose', {field: t('common.geofence')})}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* Driver ID */}
                    <Grid container spacing={3} alignItems="center" className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={12} sm={4} md={2} className="grid_title_padding">
                        {t('driverAssociation.id')}
                      </Grid>
                      <Grid container alignItems="center" item xs={12} sm={8} md={10}>
                        <TextField
                          name="driver_id"
                          value={this.state.driver_id}
                          className="responsive_min_size"
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('driverAssociation.id')})}
                          inputProps={{maxLength: 256}}
                          onChange={(event) => onChangeTextField(this, event)}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>

                    {/* Driver name (App) */}
                    <Grid container spacing={3} alignItems="center" className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={12} sm={4} md={2} className="grid_title_padding">
                        {t('driverAssociation.name.app')}
                      </Grid>
                      <Grid container alignItems="center" item xs={12} sm={8} md={10}>
                        <TextField
                          name="driver_name_app"
                          value={this.state.driver_name_app}
                          className="responsive_min_size"
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('driverAssociation.name.app')})}
                          inputProps={{maxLength: 256}}
                          onChange={(event) => onChangeTextField(this, event)}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>

                    {/* Driver name (admin) */}
                    <Grid container spacing={3} alignItems="center" className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={12} sm={4} md={2} className="grid_title_padding">
                        {t('driverAssociation.name.ad')}
                      </Grid>
                      <Grid container alignItems="center" item xs={12} sm={8} md={10}>
                        <TextField
                          name="driver_name_ad"
                          value={this.state.driver_name_ad}
                          className="responsive_min_size"
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('driverAssociation.name.ad')})}
                          inputProps={{maxLength: 256}}
                          onChange={(event) => onChangeTextField(this, event)}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>

                    {/* Vehicle id (admin) */}
                    <Grid container spacing={3} alignItems="center" className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={12} sm={4} md={2} className="grid_title_padding">
                        {t('businessVehicle.code')}
                      </Grid>
                      <Grid container alignItems="center" item xs={12} sm={8} md={10}>
                        <TextField
                          name="vehicle_id"
                          value={this.state.vehicle_id}
                          className="responsive_min_size"
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('businessVehicle.code')})}
                          inputProps={{maxLength: 256}}
                          onChange={(event) => onChangeTextField(this, event)}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
                <br />
                {/* </Paper> */}
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.resetSearch} endIcon={<RotateLeftIcon />}>
                        <span className="responsive_button_text">{t('common.btnResetSearch')}</span>
                      </Button>
                    )}
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {permission.canRegister && (
                      <Link
                        style={{textDecoration: 'none'}}
                        to={{pathname: ROUTE.LAYOUT + ROUTE.DRIVER_SCHEDULER_REGISTRATION, state: {from: this.props.location.pathname + '?' + this.state.searchParams}}}
                      >
                        <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />}>
                          <span className="responsive_button_text">{t('common.btnRegister')}</span>
                        </Button>
                      </Link>
                    )}
                    {permission.canSearch && (
                      <Button
                        color="primary"
                        variant="contained"
                        className="button_margin"
                        onClick={() => this.handleSearch()}
                        endIcon={<SearchIcon />}
                      >
                        <span className="responsive_button_text">{t('common.btnSearch')}</span>
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <br></br>
              </LoadingOverlay>
            </Container>
          </Card>
          <br></br>
          {this.state.search && (
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={12}>
                    <h3>
                      {t('common.searchResult')} {this.state.result?.length} {t('common.case')}
                    </h3>
                  </Grid>
                </Grid>
                {this.state.result.length > 0 && (
                  <div className="chart-wrapper" style={{overflow: 'auto'}}>
                    <div style={{display: 'table'}}>
                      <ul className="chart-values" id="chart_driver_name">
                        <li>{t('driverAssociation.id')}</li>
                        <li>{t('driverAssociation.last_device_info')}</li>
                        <li>{t('driverAssociation.name.app')}</li>
                        <li>{t('driverAssociation.name.ad')}</li>
                        <li>{t('driverAssociation.vehicle')}</li>
                        {this.state.hourList.map((hour, index) => {
                          return (
                            index < this.state.hourList.length - 1 && (
                              <li>
                                {index < this.state.hourList.length - 1 && <div className="time_header">{hour}</div>}
                                {index + 1 === this.state.hourList.length - 1 && <div className="time_header_right">{this.state.hourList[index + 1]}</div>}
                              </li>
                            )
                          );
                        })}
                      </ul>
                      <ul className="chart-bars">
                        {this.initialChart(this.state.result).map((row, index) => {
                          return (
                            row.times.length > 0 && (
                              <li key={index}>
                                <span className="border_right" style={{left: 40, width: row.offset_driver_id_li, display: 'flex'}}>
                                  <div style={{marginLeft: 3, width: 60}}>{row.driver_code}</div>
                                  <LensIcon style={{color: row.available ? '#18c090' : '#ff483c', fontSize: '10px', marginLeft: 10}} />
                                </span>
                                <span
                                  className="border_right"
                                  style={{
                                    left: row.offset_driver_id_li + 40,
                                    width: row.offset_os_version,
                                  }}
                                >
                                  <div style={{marginLeft: 3}}>
                                    <div>{(row.last_device_info?.os || DATA_NULL) + ': ' + (row.last_device_info?.app_version || DATA_NULL)}</div>
                                  </div>
                                </span>
                                <span className="border_right" style={{left: row.offset_driver_id_li + row.offset_os_version + 40, width: row.offset_driver_app_li}}>
                                  <div style={{marginLeft: 3}}>{row.driver_name_app}</div>
                                </span>
                                <span
                                  className="border_right"
                                  style={{left: row.offset_driver_id_li + row.offset_os_version + row.offset_driver_app_li + 40, width: row.offset_driver_admin_li}}
                                >
                                  <div style={{marginLeft: 3}}>{row.driver_name}</div>
                                </span>
                                <span
                                  className="border_right"
                                  style={{
                                    left: row.offset_driver_id_li + row.offset_os_version + row.offset_driver_app_li + row.offset_number_register_li + 40,
                                    width: row.offset_number_register_li,
                                  }}
                                >
                                  <div style={{marginLeft: 3}}>{row?.registration_number}</div>
                                </span>
                                {row.times.map((span_row, index) => (
                                  <React.Fragment key={index}>{span_row}</React.Fragment>
                                ))}
                              </li>
                            )
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                )}
              </Container>
              <br></br>
            </Card>
          )}
        </LoadingOverlay>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.isOpenModal}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1000',
          }}
        >
          <>
            <ImportCSV uploadCsv={(status) => this.getStateFromModal(status)} onClickClose={this.handleCloseModal}></ImportCSV>
          </>
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    driverVehicleAssociation: state.driverVehicleAssociation,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getAllGeofence: () => dispatch(getAllGeofence()),
    searchListDriverVehicle: (payload, browserHistory) => dispatch(searchListDriverVehicle(payload, browserHistory)),
    importDriverCSV: (payload, props) => dispatch(importDriverCSV(payload, props)),
    registerVehicle: (registerData) => {
      dispatch(registerVehicle(registerData));
    },
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
