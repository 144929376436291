import React, {Component} from 'react';

import {Map, GoogleApiWrapper, Marker} from 'google-maps-react';
import _ from 'lodash';
import {connect} from 'react-redux';

import {setMessageModal} from '../../stores/modal/actions';

/**
 * Heat Maps Tracking GPS component
 */
class Index extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      centerPoint: {
        lat: 21.0321246,
        lng: 105.7729,
      },
      mapData: [],
      position: {},
      historyInfor: [],
      marker: {},
    };
    this.myMarker = React.createRef();
  }

  /**
   * componentDidMount
   */
  componentDidMount = () => {
    this.initMap();
    if (this.props.historyInfor?.length > 0) this.renderMarker(this.props.historyInfor[0]);
  };

  /**
   * handleSearchGpsHeatMap
   */
  async handleSearchGpsHeatMap() {}

  /**
   * componentWillReceiveProps
   * @param {Props} nextProps
   */
  UNSAFE_componentWillReceiveProps = async (nextProps) => {
    const {mapData, historyInfor} = nextProps;
    if (historyInfor?.length > 0) this.renderMarker(historyInfor[0]);

    if (mapData === this.state.mapData) return;
    this.setState({mapData});
    // await this.initMap();
  };

  /**
   * initMap
   * @return {*}
   */

  initMap = () => {
    const {google, mapData} = this.props;
    if (this.mapRef) {
      this.mapRef.map.data.forEach((feature) => {
        this.mapRef.map.data.remove(feature);
      });
      if (mapData?.length > 0) {
        this.setMapCenter();
        const map = this.mapRef.map;
        const geoJson = {
          type: 'FeatureCollection',
          features: mapData.map((item) => ({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [item.longitude, item.latitude],
            },
            properties: {},
          })),
        };
        map.data.addGeoJson(geoJson);
        map.data.setStyle({
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 3,
            strokeColor: '#0358ca',
            strokeWeight: 2,
            fillColor: '#0358ca',
            fillOpacity: 0.5,
          },
        });
      }
    }
  };

  /**
   * getPoints
   * @return {*}
   */
  getPoints = () => {
    const {google, mapData} = this.props;
    if (mapData?.length > 0) {
      const data = mapData.filter((item, index) => index > 0).map((item, index) => new google.maps.LatLng(item.latitude, item.longitude));
      return data;
    }
    return [];
  };

  /**
   * componentDidUpdate
   */
  componentDidUpdate = async () => {};

  /**
   * render marker
   * @param {Object} item
   * @return {*}
   */
  renderMarker = (item) => {
    const {google} = this.props;

    // display render
    if (!_.isEmpty(this.state.marker)) {
      const marker = this.state.marker;
      const position = new google.maps.LatLng(item.lat, item.lng);
      marker.setPosition(position);
      this.setState({marker});
      return marker;
    }
    const marker = new google.maps.Marker({
      position: {lat: item.lat, lng: item.lng},
      icon: {
        url: `${process.env.PUBLIC_URL}/images/car-tracking.png`,
        strokeWeight: 1,
        strokeColor: this.props.markerColor,
        fillColor: this.props.markerColor,
        fillOpacity: 1,
        scaledSize: new google.maps.Size(20, 30),
      },
      map: this.mapRef.map,
    });
    marker.set('name', 'car');
    marker.info = new google.maps.InfoWindow({
      content: 'history location',
    });
    this.setState({marker});
    return marker;
  };

  /**
   * setMapCenter
   */
  setMapCenter = () => {
    const {mapData} = this.props;
    const coordinates = {
      lat: mapData[1].latitude,
      lng: mapData[1].longitude,
    };
    this.mapRef.map.setCenter(coordinates);
  };

  /**
   * render component
   * @return {HTML}
   */
  render() {
    return (
      <div>
        <Map
          {...this.props}
          ref={(ref) => (this.mapRef = ref)}
          // centerAroundCurrentLocation={false}
          initialCenter={this.state.centerPoint}
          containerStyle={{
            width: '95%',
            height: '500px',
            position: 'relative',
          }}
          // zoom={15}
        >
          {this.props.historyInfor?.length > 0 && (
            <Marker
              icon={{
                id: 'car_position',
                url: `${process.env.PUBLIC_URL}/images/car-tracking.png`,
                scaledSize: new this.props.google.maps.Size(20, 30),
                anchor: {x: 10, y: 10},
              }}
              position={this.state.position}
              ref={this.myMarker}
            />
          )}
        </Map>
      </div>
    );
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    setMessageModal: () => dispatch(setMessageModal),
  };
};

export default connect(
  mapDispatchToProps,
)(
  // eslint-disable-next-line new-cap
  GoogleApiWrapper((props) => ({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || 'AIzaSyACyapw83diO1bi_xiXbZRLLoano6eTwd0',
    language: localStorage.getItem('i18nextLng') === 'ja' ? 'ja' : 'en',
    libraries: ['geometry', 'drawing', 'places', 'visualization'],
  }))(Index),
);
