import React, {Component} from 'react';

import {Grid, Button, Paper, Card, Container, FormControl, Select, MenuItem, Table, TableHead, TableRow, TableCell, TableBody} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';

import {ROUTE, CONGESTION, DAY_OF_WEEK, ALL_TIME, CONGESTION_SETTING, PERMISSION_ACTIONS} from '../../../../common/constant';
import withPermissionGateway from '../../../../hoc/withPermissionGateway';
import {getCongestion, registrationCongestion} from '../../../../stores/congestion_status/action';
import {onChangeSelect, onChangeListData, backForwardRouter} from '../../../../utils/common';

/**
 * Congestion status detail master
 * @return {HTMLElement}
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      settingDate: 1,
      advanced: [
        {
          day: '',
          time_setting: [{time: '', status: ''}],
        },
      ],
      all_day: '',
      all_time: [
        {
          times: '',
          status: '',
          id: '',
        },
      ],
      allTime: [],
      day_of_week: [
        {
          name: '',
          status: '',
        },
      ],
      dayOfWeeks: [],
      facility_id: null,
      advanced_monday: [{time: '', status: ''}],
      advanced_tuesday: [{time: '', status: ''}],
      advanced_wednesday: [{time: '', status: ''}],
      advanced_thursday: [{time: '', status: ''}],
      advanced_friday: [{time: '', status: ''}],
      advanced_saturday: [{time: '', status: ''}],
      advanced_sunday: [{time: '', status: ''}],
      advanced_holiday: [{time: '', status: ''}],
      dataAdvanced: [],
    };
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    if (this.props.match.path === ROUTE.LAYOUT + ROUTE.PRODUCT_CONGESTION_STATUS_DETAIL + '/:id') {
      await this.props.getCongestion(this.props.match.params.id).then(
        (result) => {
          const day_of_weeks = DAY_OF_WEEK.map(({days, i18n}) => {
            const {status = null} = result.day_of_week !== null ? result.day_of_week.find(({day}) => days === day) || {} : {};
            return {days, status, i18n};
          });
          const all_times = ALL_TIME.map(({time_cost, times}) => {
            const {status = null} = result.all_time !== null ? result.all_time.find(({time}) => time_cost === time) || {} : {};
            return {time_cost, status, times};
          });
          const avd =
            result.advanced === null ? DAY_OF_WEEK.map(({days}) => {
                  const {status = null, time = null} = result.advanced !== null ? result.advanced.find(({day}) => days === day) : {};
                  const items = ALL_TIME.map(({time_cost}) => (time_cost === time ? {time: time_cost, status} : {time: time_cost, status: null}));
                  return {day: days, time_setting: items};
                }) : result.advanced.map(({day, time_setting: timeSetting}) => {
                  if (!timeSetting) {
                    return {day, time_setting: ALL_TIME.map((item) => ({...item, status: null}))};
                  }
                  const time_setting = ALL_TIME.map(({time_cost}) => {
                    const {status = null} = timeSetting.find(({time, status}) => time === time_cost) || {};
                    return {time: time_cost, status};
                  });
                  return {day, time_setting: time_setting};
                });
          const arrTime = avd.map(({time_setting}) => [...time_setting]);

          if (result) {
            this.setState({
              facility_id: parseInt(this.props.match.params.id),
              advanced: result.advanced === null ? avd : result.advanced,
              all_day: result.all_day,
              all_time: all_times,
              day_of_week: day_of_weeks,
              advanced_monday: arrTime[0],
              advanced_tuesday: arrTime[1],
              advanced_wednesday: arrTime[2],
              advanced_thursday: arrTime[3],
              advanced_friday: arrTime[4],
              advanced_saturday: arrTime[5],
              advanced_sunday: arrTime[6],
              advanced_holiday: arrTime[7],
            });
          }
        },
        () => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.PRODUCT_CONGESTION_STATUS),
      );
    }
  }

  /**
   * handleCreate
   */
  async handleCreate() {
    const {day_of_week, all_time, advanced_monday, advanced_tuesday, advanced_wednesday, advanced_sunday, advanced_saturday, advanced_holiday, advanced_friday, advanced_thursday} =
      this.state;

    for (let i = 0; i < day_of_week.length; i++) {
      if (day_of_week[i].status !== null && day_of_week[i].status !== '') {
        const data = {
          day: day_of_week[i].days,
          status: day_of_week[i].status,
        };
        this.state.dayOfWeeks.push(data);
      }
    }

    for (let i = 0; i < all_time.length; i++) {
      if (all_time[i].status !== null && all_time[i].status !== '') {
        const data = {
          time: all_time[i].time_cost,
          status: all_time[i].status,
        };
        this.state.allTime.push(data);
      }
    }

    const dataMonday = {day: 'MONDAY', time_setting: []};
    for (let i = 0; i < advanced_monday.length; i++) {
      if (advanced_monday[i].status !== null && advanced_monday[i].status !== '') {
        dataMonday.time_setting.push({time: advanced_monday[i].time, status: advanced_monday[i].status});
      }
    }
    if (dataMonday.time_setting.length) {
      this.state.dataAdvanced.push(dataMonday);
    }

    const dataTuesday = {day: 'TUESDAY', time_setting: []};
    for (let i = 0; i < advanced_tuesday.length; i++) {
      if (advanced_tuesday[i].status !== null && advanced_tuesday[i].status !== '') {
        dataTuesday.time_setting.push({time: advanced_tuesday[i].time, status: advanced_tuesday[i].status});
      }
    }
    if (dataTuesday.time_setting.length) {
      this.state.dataAdvanced.push(dataTuesday);
    }

    const dataWednesday = {day: 'WEDNESDAY', time_setting: []};
    for (let i = 0; i < advanced_wednesday.length; i++) {
      if (advanced_wednesday[i].status !== null && advanced_wednesday[i].status !== '') {
        dataWednesday.time_setting.push({time: advanced_wednesday[i].time, status: advanced_wednesday[i].status});
      }
    }
    if (dataWednesday.time_setting.length) {
      this.state.dataAdvanced.push(dataWednesday);
    }

    const dataThursday = {day: 'THURSDAY', time_setting: []};
    for (let i = 0; i < advanced_thursday.length; i++) {
      if (advanced_thursday[i].status !== null && advanced_thursday[i].status !== '') {
        dataThursday.time_setting.push({time: advanced_thursday[i].time, status: advanced_thursday[i].status});
      }
    }
    if (dataThursday.time_setting.length) {
      this.state.dataAdvanced.push(dataThursday);
    }

    const dataFriday = {day: 'FRIDAY', time_setting: []};
    for (let i = 0; i < advanced_friday.length; i++) {
      if (advanced_friday[i].status !== null && advanced_friday[i].status !== '') {
        dataFriday.time_setting.push({time: advanced_friday[i].time, status: advanced_friday[i].status});
      }
    }
    if (dataFriday.time_setting.length) {
      this.state.dataAdvanced.push(dataFriday);
    }

    const dataSaturday = {day: 'SATURDAY', time_setting: []};
    for (let i = 0; i < advanced_saturday.length; i++) {
      if (advanced_saturday[i].status !== null && advanced_saturday[i].status !== '') {
        dataSaturday.time_setting.push({time: advanced_saturday[i].time, status: advanced_saturday[i].status});
      }
    }
    if (dataSaturday.time_setting.length) {
      this.state.dataAdvanced.push(dataSaturday);
    }

    const dataSunday = {day: 'SUNDAY', time_setting: []};
    for (let i = 0; i < advanced_sunday.length; i++) {
      if (advanced_sunday[i].status !== null && advanced_sunday[i].status !== '') {
        dataSunday.time_setting.push({time: advanced_sunday[i].time, status: advanced_sunday[i].status});
      }
    }
    if (dataSunday.time_setting.length) {
      this.state.dataAdvanced.push(dataSunday);
    }

    const dataHoliday = {day: 'HOLIDAY', time_setting: []};
    for (let i = 0; i < advanced_holiday.length; i++) {
      if (advanced_holiday[i].status !== null && advanced_holiday[i].status !== '') {
        dataHoliday.time_setting.push({time: advanced_holiday[i].time, status: advanced_holiday[i].status});
      }
    }
    if (dataHoliday.time_setting.length) {
      this.state.dataAdvanced.push(dataHoliday);
    }
    const payload = {
      all_day: this.state.all_day,
      advanced: this.state.dataAdvanced.length > 0 ? this.state.dataAdvanced : null,
      all_time: this.state.allTime.length > 0 ? this.state.allTime : null,
      day_of_week: this.state.dayOfWeeks.length > 0 ? this.state.dayOfWeeks : null,
      facility_id: this.state.facility_id,
    };
    await this.props.registrationCongestion(payload, this.props);
    this.setState({
      allTime: [],
      dayOfWeeks: [],
      dataAdvanced: [],
    });
    await this.props.getCongestion(this.props.match.params.id);
  }

  /**
   *
   * @param {*} component
   * @param {*} event
   */
  onChangeSelectAllDay = (component, event) => {
    component.setState({
      ...component.state,
      [event.target.name]: event.target.value,
    });
    const avd_time_copy = DAY_OF_WEEK.map(({days}) => {
      const {status = event.target.value, time = null} = this.state.advanced !== null ? this.state.advanced.find(({day}) => days === day) || {} : {};
      const items = ALL_TIME.map(({time_cost}) => (time_cost === time ? {time: time_cost, status} : {time: time_cost, status: event.target.value}));
      return {day: days, time_setting: items};
    });
    const day_of_weeks = DAY_OF_WEEK.map(({days, i18n}) => {
      const {status = event.target.value} = this.state.day_of_week !== null ? this.state.day_of_week.find(({day}) => days === day) || {} : {};
      return {days, status, i18n};
    });
    const all_times = ALL_TIME.map(({time_cost, times}) => {
      const {status = event.target.value} = this.state.all_time !== null ? this.state.all_time.find(({time}) => time_cost === time) || {} : {};
      return {time_cost, status, times};
    });
    const arrTimeCopy = avd_time_copy.map(({time_setting}) => [...time_setting]);
    this.setState({
      advanced_monday: arrTimeCopy[0],
      advanced_tuesday: arrTimeCopy[1],
      advanced_wednesday: arrTimeCopy[2],
      advanced_thursday: arrTimeCopy[3],
      advanced_friday: arrTimeCopy[4],
      advanced_saturday: arrTimeCopy[5],
      advanced_sunday: arrTimeCopy[6],
      advanced_holiday: arrTimeCopy[7],
      day_of_week: day_of_weeks,
      all_time: all_times,
    });
  };

  /**
   *
   * @param {*} index
   * @param {*} name
   * @param {*} value
   */
  onChangeListDayOfWeek = (index, name, value) => {
    switch (index) {
      case 0:
        for (let i = 0; i < this.state.advanced_monday.length; i++) {
          onChangeListData(this, this.state.advanced_monday, name, i, value);
          onChangeListData(this, this.state.advanced_monday, 'time', i, i);
        }
        break;
      case 1:
        for (let i = 0; i < this.state.advanced_tuesday.length; i++) {
          onChangeListData(this, this.state.advanced_tuesday, name, i, value);
          onChangeListData(this, this.state.advanced_tuesday, 'time', i, i);
        }
        break;
      case 2:
        for (let i = 0; i < this.state.advanced_wednesday.length; i++) {
          onChangeListData(this, this.state.advanced_wednesday, name, i, value);
          onChangeListData(this, this.state.advanced_wednesday, 'time', i, i);
        }
        break;
      case 3:
        for (let i = 0; i < this.state.advanced_thursday.length; i++) {
          onChangeListData(this, this.state.advanced_thursday, name, i, value);
          onChangeListData(this, this.state.advanced_thursday, 'time', i, i);
        }
        break;
      case 4:
        for (let i = 0; i < this.state.advanced_friday.length; i++) {
          onChangeListData(this, this.state.advanced_friday, name, i, value);
          onChangeListData(this, this.state.advanced_friday, 'time', i, i);
        }
        break;
      case 5:
        for (let i = 0; i < this.state.advanced_saturday.length; i++) {
          onChangeListData(this, this.state.advanced_saturday, name, i, value);
          onChangeListData(this, this.state.advanced_saturday, 'time', i, i);
        }
        break;
      case 6:
        for (let i = 0; i < this.state.advanced_sunday.length; i++) {
          onChangeListData(this, this.state.advanced_sunday, name, i, value);
          onChangeListData(this, this.state.advanced_sunday, 'time', i, i);
        }
        break;
      case 7:
        for (let i = 0; i < this.state.advanced_holiday.length; i++) {
          onChangeListData(this, this.state.advanced_holiday, name, i, value);
          onChangeListData(this, this.state.advanced_holiday, 'time', i, i);
        }
        break;
      default:
    }
  };

  /**
   *
   * @param {*} index
   * @param {*} name
   * @param {*} value
   */
  onChangeListTime = (index, name, value) => {
    onChangeListData(this, this.state.advanced_monday, name, index, value);
    onChangeListData(this, this.state.advanced_monday, 'time', index, index);
    onChangeListData(this, this.state.advanced_tuesday, name, index, value);
    onChangeListData(this, this.state.advanced_tuesday, 'time', index, index);
    onChangeListData(this, this.state.advanced_wednesday, name, index, value);
    onChangeListData(this, this.state.advanced_wednesday, 'time', index, index);
    onChangeListData(this, this.state.advanced_thursday, name, index, value);
    onChangeListData(this, this.state.advanced_thursday, 'time', index, index);
    onChangeListData(this, this.state.advanced_friday, name, index, value);
    onChangeListData(this, this.state.advanced_friday, 'time', index, index);
    onChangeListData(this, this.state.advanced_saturday, name, index, value);
    onChangeListData(this, this.state.advanced_saturday, 'time', index, index);
    onChangeListData(this, this.state.advanced_sunday, name, index, value);
    onChangeListData(this, this.state.advanced_sunday, 'time', index, index);
    onChangeListData(this, this.state.advanced_holiday, name, index, value);
    onChangeListData(this, this.state.advanced_holiday, 'time', index, index);
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t, actions} = this.props;
    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
    };

    return (
      <LoadingOverlay bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('route.congestionStatusDetail')}</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}></Grid>
            </Grid>
          </Container>
          <br></br>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('route.congestionStatusDetail')}
                    </Grid>
                  </Grid>
                </Paper>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>
          <br></br>
          <Container maxWidth="xl">
            <Card>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table" style={{position: 'relative', paddingBottom: `${this.state.settingDate === 1 ? '20rem' : '0rem'}`}}>
                  <Table style={{width: '30%', position: 'absolute', top: '0', right: '0'}}>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('congestion')}</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {CONGESTION.map(({value, i18n}, index) => (
                        <TableRow key={index} hover className="cursor_pointer">
                          <TableCell scope="row">{t(`${i18n}`)}</TableCell>
                          <TableCell scope="row">{t(`${value}`)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Grid container spacing={1} className="width_60" style={{margin: '0'}}>
                    <Grid container alignItems="center" item xs={4} lg={4} className="grid_title_padding">
                      {t('congestion.setting')}
                    </Grid>
                    <Grid container alignItems="center" item xs={4} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_200">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'settingDate',
                          }}
                          value={this.state.settingDate}
                          onChange={(event) => onChangeSelect(this, event)}
                        >
                          {CONGESTION_SETTING.map(({id, i18n}, index) => {
                            return (
                              <MenuItem value={id} key={index}>
                                {t(`${i18n}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid container alignItems="center" item xs={4} lg={4}>
                      {t('congestion')}
                    </Grid>

                    {this.state.settingDate === 1 && (
                      <>
                        <Grid container alignItems="center" item xs={4} lg={4} className="grid_title_padding"></Grid>
                        <Grid container alignItems="center" item xs={4} lg={4}>
                          {t(`${CONGESTION_SETTING[0].i18n}`)}
                        </Grid>
                        <Grid container alignItems="center" item xs={4} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_200">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'all_day',
                              }}
                              value={this.state.all_day || ''}
                              onChange={(event) => this.onChangeSelectAllDay(this, event)}
                            >
                              {CONGESTION.map(({name, i18n}, index) => {
                                return (
                                  <MenuItem value={name} key={index}>
                                    {t(`${i18n}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </>
                    )}

                    {this.state.settingDate === 2 && (
                      <>
                        {this.state.day_of_week.map((row, index) => (
                          <>
                            <Grid container alignItems="center" item xs={4} lg={4} className="grid_title_padding"></Grid>
                            <Grid container alignItems="center" item xs={4} lg={4}>
                              {t(`${row.i18n}`)}
                            </Grid>
                            <Grid container alignItems="center" item xs={4} lg={4}>
                              <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_200">
                                <Select
                                  margin="dense"
                                  inputProps={{
                                    name: 'status',
                                  }}
                                  value={this.state.day_of_week[index].status}
                                  onChange={(event) => {
                                    onChangeListData(this, this.state.day_of_week, event.target.name, index, event.target.value);
                                    this.onChangeListDayOfWeek(index, event.target.name, event.target.value);
                                  }}
                                >
                                  {CONGESTION.map(({name, i18n}, index) => {
                                    return (
                                      <MenuItem value={name} key={index}>
                                        {t(`${i18n}`)}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        ))}
                      </>
                    )}

                    {this.state.settingDate === 3 && (
                      <>
                        {this.state.all_time.map((row, index) => (
                          <>
                            <Grid container alignItems="center" item xs={4} lg={4} className="grid_title_padding"></Grid>
                            <Grid container alignItems="center" item xs={4} lg={4}>
                              {row.times}
                            </Grid>
                            <Grid container alignItems="center" item xs={4} lg={4}>
                              <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_200">
                                <Select
                                  margin="dense"
                                  inputProps={{
                                    name: 'status',
                                  }}
                                  value={this.state.all_time[index].status}
                                  onChange={(event) => {
                                    onChangeListData(this, this.state.all_time, event.target.name, index, event.target.value);
                                    this.onChangeListTime(index, event.target.name, event.target.value);
                                  }}
                                >
                                  {CONGESTION.map(({name, i18n}, index) => {
                                    return (
                                      <MenuItem value={name} key={index}>
                                        {t(`${i18n}`)}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>
                          </>
                        ))}
                      </>
                    )}

                    <>
                      <Card className="main_card_min_size" style={{marginTop: '4rem'}}>
                        <Container maxWidth="xl">
                          <Grid container spacing={1} className="day_of_week_container">
                            <Grid container direction="column" item xs={1}>
                              <Paper className="day_of_week">{t('congestion.detail')}</Paper>
                            </Grid>
                            {DAY_OF_WEEK.map(({i18n}, index) => (
                              <Grid container direction="column" item xs={1} key={index}>
                                <Paper className="day_of_week">{t(`${i18n}`)}</Paper>
                              </Grid>
                            ))}
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid container direction="column" justify="center" alignItems="center" item xs={1}>
                              {ALL_TIME.map(({times}, index) => (
                                <Paper className="field_times box_shadow" key={index}>
                                  {times}
                                </Paper>
                              ))}
                            </Grid>
                            <Grid container direction="column" justify="center" alignItems="center" item xs={1}>
                              {this.state.advanced_monday?.map((_, index) => (
                                <FormControl key={index} variant="outlined" margin="dense">
                                  <Select
                                    margin="dense"
                                    inputProps={{
                                      name: 'status',
                                    }}
                                    value={this.state.advanced_monday[index].status || ''}
                                    onChange={(event) => {
                                      onChangeListData(this, this.state.advanced_monday, event.target.name, index, event.target.value);
                                      onChangeListData(this, this.state.advanced_monday, 'time', index, index);
                                    }}
                                  >
                                    {CONGESTION.map(({name, i18n}, index) => {
                                      return (
                                        <MenuItem value={name} key={index}>
                                          {t(`${i18n}`)}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              ))}
                            </Grid>

                            <Grid container direction="column" justify="center" alignItems="center" item xs={1}>
                              {this.state.advanced_tuesday?.map((_, index) => (
                                <FormControl key={index} variant="outlined" margin="dense">
                                  <Select
                                    margin="dense"
                                    inputProps={{
                                      name: 'status',
                                    }}
                                    value={this.state.advanced_tuesday[index].status || ''}
                                    onChange={(event) => {
                                      onChangeListData(this, this.state.advanced_tuesday, event.target.name, index, event.target.value);
                                      onChangeListData(this, this.state.advanced_tuesday, 'time', index, index);
                                    }}
                                  >
                                    {CONGESTION.map(({name, i18n}, index) => {
                                      return (
                                        <MenuItem value={name} key={index}>
                                          {t(`${i18n}`)}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              ))}
                            </Grid>

                            <Grid container direction="column" justify="center" alignItems="center" item xs={1}>
                              {this.state.advanced_wednesday?.map((_, index) => (
                                <FormControl key={index} variant="outlined" margin="dense">
                                  <Select
                                    margin="dense"
                                    inputProps={{
                                      name: 'status',
                                    }}
                                    value={this.state.advanced_wednesday[index].status || ''}
                                    onChange={(event) => {
                                      onChangeListData(this, this.state.advanced_wednesday, event.target.name, index, event.target.value);
                                      onChangeListData(this, this.state.advanced_wednesday, 'time', index, index);
                                    }}
                                  >
                                    {CONGESTION.map(({name, i18n}, index) => {
                                      return (
                                        <MenuItem value={name} key={index}>
                                          {t(`${i18n}`)}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              ))}
                            </Grid>

                            <Grid container direction="column" justify="center" alignItems="center" item xs={1}>
                              {this.state.advanced_thursday?.map((_, index) => (
                                <FormControl key={index} variant="outlined" margin="dense">
                                  <Select
                                    margin="dense"
                                    inputProps={{
                                      name: 'status',
                                    }}
                                    value={this.state.advanced_thursday[index].status || ''}
                                    onChange={(event) => {
                                      onChangeListData(this, this.state.advanced_thursday, event.target.name, index, event.target.value);
                                      onChangeListData(this, this.state.advanced_thursday, 'time', index, index);
                                    }}
                                  >
                                    {CONGESTION.map(({name, i18n}, index) => {
                                      return (
                                        <MenuItem value={name} key={index}>
                                          {t(`${i18n}`)}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              ))}
                            </Grid>

                            <Grid container direction="column" justify="center" alignItems="center" item xs={1}>
                              {this.state.advanced_friday?.map((_, index) => (
                                <FormControl key={index} variant="outlined" margin="dense">
                                  <Select
                                    margin="dense"
                                    inputProps={{
                                      name: 'status',
                                    }}
                                    value={this.state.advanced_friday[index].status || ''}
                                    onChange={(event) => {
                                      onChangeListData(this, this.state.advanced_friday, event.target.name, index, event.target.value);
                                      onChangeListData(this, this.state.advanced_friday, 'time', index, index);
                                    }}
                                  >
                                    {CONGESTION.map(({name, i18n}, index) => {
                                      return (
                                        <MenuItem value={name} key={index}>
                                          {t(`${i18n}`)}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              ))}
                            </Grid>

                            <Grid container direction="column" justify="center" alignItems="center" item xs={1}>
                              {this.state.advanced_saturday?.map((_, index) => (
                                <FormControl key={index} variant="outlined" margin="dense">
                                  <Select
                                    margin="dense"
                                    inputProps={{
                                      name: 'status',
                                    }}
                                    value={this.state.advanced_saturday[index].status || ''}
                                    onChange={(event) => {
                                      onChangeListData(this, this.state.advanced_saturday, event.target.name, index, event.target.value);
                                      onChangeListData(this, this.state.advanced_saturday, 'time', index, index);
                                    }}
                                  >
                                    {CONGESTION.map(({name, i18n}, index) => {
                                      return (
                                        <MenuItem value={name} key={index}>
                                          {t(`${i18n}`)}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              ))}
                            </Grid>

                            <Grid container direction="column" justify="center" alignItems="center" item xs={1}>
                              {this.state.advanced_sunday?.map((_, index) => (
                                <FormControl key={index} variant="outlined" margin="dense">
                                  <Select
                                    margin="dense"
                                    inputProps={{
                                      name: 'status',
                                    }}
                                    value={this.state.advanced_sunday[index].status || ''}
                                    onChange={(event) => {
                                      onChangeListData(this, this.state.advanced_sunday, event.target.name, index, event.target.value);
                                      onChangeListData(this, this.state.advanced_sunday, 'time', index, index);
                                    }}
                                  >
                                    {CONGESTION.map(({name, i18n}, index) => {
                                      return (
                                        <MenuItem value={name} key={index}>
                                          {t(`${i18n}`)}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              ))}
                            </Grid>

                            <Grid container direction="column" justify="center" alignItems="center" item xs={1}>
                              {this.state.advanced_holiday?.map((_, index) => (
                                <FormControl key={index} variant="outlined" margin="dense">
                                  <Select
                                    margin="dense"
                                    inputProps={{
                                      name: 'status',
                                    }}
                                    value={this.state.advanced_holiday[index].status || ''}
                                    onChange={(event) => {
                                      onChangeListData(this, this.state.advanced_holiday, event.target.name, index, event.target.value);
                                      onChangeListData(this, this.state.advanced_holiday, 'time', index, index);
                                    }}
                                  >
                                    {CONGESTION.map(({name, i18n}, index) => {
                                      return (
                                        <MenuItem value={name} key={index}>
                                          {t(`${i18n}`)}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              ))}
                            </Grid>
                          </Grid>
                        </Container>
                      </Card>
                    </>
                  </Grid>
                </Paper>
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {permission.canUpdate && (
                      <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={() => this.handleCreate()}>
                        {t('common.btnUpdate')}
                      </Button>
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      className="button_margin button_color"
                      onClick={this.props.history.goBack}
                    >
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            </Card>
            <br></br>
          </Container>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    congestion: state.congestion,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCongestion: (params) => dispatch(getCongestion(params)),
    registrationCongestion: (payload, props) => dispatch(registrationCongestion(payload, props)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
