import React, {Component} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {Grid, Button, TextField, Paper, Card, Container, FormControl, Select, MenuItem, FormHelperText, Dialog} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {Autocomplete} from '@material-ui/lab';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {ROUTE, VEHICLE_TYPE, MAX_NIGHT_SHIFT_SECTION} from '../../../common/constant';
import SelectModal from '../../../components/selectModal';
import SettingFee from '../../../components/SettingFee';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getListSupplier, getListGeofence, getListFacilityBySupplierID} from '../../../stores/common/actions';
import {onChangeSelect, backForwardRouter} from '../../../utils/common';
import {isOverlapDateRelatively, compareDateTimeRange} from '../../../utils/datetime';
import './styles.css';

/**
 * Setting Taxi's Fee Form Component
 */
class SettingFeeTaxiForm extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      supplier: '',
      facilityId: '',
      geofence_id: '',
      vehicleType: '',
      startTime: '',
      endTime: '',
      nightShiftList: [this.generateDefaultNightShiftSection()],

      general: {
        feeList: [
          {to: null, distanceUnit: null, fee: null},
          {to: null, distanceUnit: null, fee: null},
        ],
        dispatchFee: '',
      },

      overlapList: new Set(),

      flgUpdate: false,
      message: '',
      isSubmitForm: false,
      flag: false,
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    this.props.getListSupplier();
    this.props.getListGeofence();
  }

  /**
   * change business
   * @param {string} value
   */
  changeBusiness = (value) => {
    this.setState({supplier: value, facilityId: ''});
    this.props.getListFacilityBySupplierID(value?.id);
  };

  /**
   * Generate an empty night shift section
   * @return {object}
   */
  generateDefaultNightShiftSection = () => {
    return {
      shiftTimeStart: '',
      shiftTimeEnd: '',
      feeList: [
        {to: null, distanceUnit: null, fee: null},
        {to: null, distanceUnit: null, fee: null},
      ],
      dispatchFee: '',
    };
  };

  /**
   * onChangeStartTime
   * @param {string} value
   */
  onChangeStartTime = (value) => {
    this.setState({startTime: value});
  };

  /**
   * onChangeEndTime
   * @param {string} value
   */
  onChangeEndTime = (value) => {
    this.setState({endTime: value});
  };

  /**
   * handleButtonCancel
   */
  handleButtonUpdateCancel() {
    this.setState({
      flag: false,
    });
  }

  /**
   * handleButtonOk
   */
  handleButtonUpdateOk() {
    this.setState({
      flag: false,
    });
    // Call api update
  }

  /**
   * handleClickCreateButton
   */
  handleClickCreateButton = () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid() && compareDateTimeRange(this.state.shiftTimeStart, this.state.shiftTimeEnd, false)() && this.validateNightShiftTimeRanges()) {
      this.setState({flag: true, message: this.props.t('business.question.confirm.create')});
    }
  };

  /**
   * handleUpdate
   */
  handleUpdate = () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid() && compareDateTimeRange(this.state.shiftTimeStart, this.state.shiftTimeEnd, false) && this.validateNightShiftTimeRanges()) {
      this.setState({
        flag: true,
        message: 'business.question.confirm.update',
      });
    }
  };

  /**
   * handleCreate
   */
  handleCreate = () => {
    // Call api create TODO
  };

  /**
   * Add an empty night shift section
   */
  addNightShiftSection = () => {
    const nightShiftList = [...this.state.nightShiftList];
    nightShiftList.push(this.generateDefaultNightShiftSection());
    this.setState({nightShiftList});
  };

  /**
   * Remove specified night shift section by index
   * @param {number} index
   */
  removeNightShiftSection = (index) => {
    const nightShiftList = [...this.state.nightShiftList];
    nightShiftList.splice(index, 1);
    this.setState({nightShiftList}, this.validateNightShiftTimeRanges);
  };

  /**
   *
   * @param {{
   * feeList: array,
   * dispatchFee: number,
   * shiftTimeStart: Date,
   * shiftTimeEnd: Date
   * }} data
   * @param {boolean} isNightShift
   * @param {number} index
   */
  onChangeFeeSectionData = (data, isNightShift, index) => {
    if (!isNightShift) {
      this.setState({general: data});
    } else {
      const nightShiftList = [...this.state.nightShiftList];
      nightShiftList[index] = data;
      this.setState({nightShiftList}, this.validateNightShiftTimeRanges);
    }
  };

  /**
   * Validate night shift time ranges and return true if time ranges are all valid
   * @return {boolean}
   */
  validateNightShiftTimeRanges = () => {
    const {nightShiftList} = this.state;
    const overlapList = new Set();
    for (let i = 0; i < nightShiftList.length - 1; i++) {
      const start1 = nightShiftList[i]?.shiftTimeStart;
      const end1 = nightShiftList[i]?.shiftTimeEnd;
      for (let j = i + 1; j < nightShiftList.length; j++) {
        const start2 = nightShiftList[j]?.shiftTimeStart;
        const end2 = nightShiftList[j]?.shiftTimeEnd;
        if (isOverlapDateRelatively(start1, end1, start2, end2)) {
          overlapList.add(i);
          overlapList.add(j);
        }
      }
    }
    this.setState({overlapList});
    return overlapList.size === 0;
  };

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {business, t, common} = this.props;
    const {supplier, geofence_id, vehicleType, facilityId, startTime, endTime, isSubmitForm, general, flag, nightShiftList, message, overlapList} = this.state;

    const isDetails = !!this.props.match.params.id;

    return (
      <LoadingOverlay active={business.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                {isDetails ? <h3>{t('settingFeeTaxiForm.titleDetails')}</h3> : <h3>{t('settingFeeTaxiForm.titleRegister')}</h3>}
              </Grid>
            </Grid>
          </Container>
          <br></br>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('settingFeeTaxiForm.form')}
                    </Grid>
                  </Grid>

                  {/* business name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('settingFeeTaxiForm.businessName')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl>
                        <Autocomplete
                          margin="dense"
                          className="field_size_10 field_min_size_300"
                          options={common?.supplier_list}
                          value={supplier || null}
                          getOptionLabel={(option) => option.supplier_name}
                          onChange={(event, business) => this.changeBusiness(business)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={'businessName'}
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              placeholder={t('placeholder.required', {field: t('settingFeeTaxiForm.businessName')})}
                            />
                          )}
                        />
                        {this.validator.message('supplier', supplier, 'required')}
                        {isSubmitForm && !this.validator.check(supplier, 'required') && (
                          <FormHelperText id="supplier" error>
                            {t('validation.required.choose', {field: t('settingFeeTaxiForm.businessName')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Vehicle Facility Name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('settingFeeTaxiForm.facilityName')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense">
                        <Select
                          variant="outlined"
                          className="field_size_20 field_min_size_300"
                          margin="dense"
                          inputProps={{
                            name: 'facilityId',
                          }}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          }}
                          displayEmpty
                          renderValue={
                            facilityId ?
                              undefined :
                              () => (
                                <div className="font-12 color-disabled">
                                  {t('placeholder.required_select', {
                                    field: t('settingFeeTaxiForm.facilityName'),
                                  })}
                                </div>
                              )
                          }
                          value={facilityId}
                          onChange={(event) => onChangeSelect(this, event)}
                          disabled={!supplier}
                        >
                          {common?.facility_list?.length > 0 ? (
                            common?.facility_list.map((item, idx) => {
                              return (
                                <MenuItem value={item.id} key={idx}>
                                  {item.facility_name}
                                </MenuItem>
                              );
                            })
                          ) : (
                            <MenuItem value="">
                              <em>{t('error.no.record')}</em>
                            </MenuItem>
                          )}
                        </Select>
                        {this.validator.message('facilityId', facilityId, 'required')}
                        {isSubmitForm && !this.validator.check(facilityId, 'required') && (
                          <FormHelperText id="facilityId" error>
                            {t('validation.required.choose', {field: t('settingFeeTaxiForm.facilityName')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* geofence */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.geofence')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'geofence_id',
                          }}
                          displayEmpty
                          renderValue={
                            geofence_id ?
                              undefined :
                              () => (
                                <div className="font-12 color-disabled">
                                  {t('placeholder.required_select', {
                                    field: t('common.geofence'),
                                  })}
                                </div>
                              )
                          }
                          value={geofence_id}
                          onChange={(event) => onChangeSelect(this, event)}
                        >
                          {common?.geofence_list?.map((item, idx) => {
                            return (
                              <MenuItem value={item.geofence_id} key={idx}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('geofence', geofence_id, 'required')}
                        {isSubmitForm && !this.validator.check(geofence_id, 'required') && (
                          <FormHelperText id="geofence" error>
                            {t('validation.required.choose', {field: t('common.geofence')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Vehicle type */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('settingFeeTaxiForm.vehicleType')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'vehicleType',
                          }}
                          displayEmpty
                          renderValue={
                            vehicleType ?
                              undefined :
                              () => (
                                <div className="font-12 color-disabled">
                                  {t('placeholder.required_select', {
                                    field: t('settingFeeTaxiForm.vehicleType'),
                                  })}
                                </div>
                              )
                          }
                          value={vehicleType}
                          onChange={(event) => onChangeSelect(this, event)}
                        >
                          {VEHICLE_TYPE.map((item, idx) => {
                            return (
                              <MenuItem value={item.code} key={idx}>
                                {t(item.display)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('vehicleType', vehicleType, 'required')}
                        {isSubmitForm && !this.validator.check(vehicleType, 'required') && (
                          <FormHelperText id="geofence" error>
                            {t('validation.required.choose', {field: t('settingFeeTaxiForm.vehicleType')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* Period */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('settingFeeTaxiForm.period')}
                    </Grid>
                    <Grid container item xs={6} lg={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <FormControl error>
                          <KeyboardDatePicker
                            className="field_size_20 field_min_size_200"
                            margin="dense"
                            autoOk
                            disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            minDate="2000-01-01"
                            placeholder={t('settingFeeTaxiForm.periodStart')}
                            format="yyyy/MM/dd"
                            value={startTime || null}
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            onChange={(time) =>
                              this.setState({
                                startTime: time,
                              })
                            }
                          />
                          {this.validator.message('sale_period_start', startTime, 'required')}
                          {this.state.isSubmitForm && !this.validator.check(startTime, 'required') && (
                            <FormHelperText id="startTime" error>
                              {t('validation.required', {field: t('settingFeeTaxiForm.periodStart')})}
                            </FormHelperText>
                          )}
                          {this.state.isSubmitForm && startTime && startTime >= endTime && (
                            <FormHelperText id="operating_time_start" error>
                              {t('validation.time.more')}
                            </FormHelperText>
                          )}
                        </FormControl>
                        <span style={{padding: '5px'}}></span>
                        <FormControl error>
                          <KeyboardDatePicker
                            className="field_size_20 field_min_size_200"
                            margin="dense"
                            autoOk
                            disableToolbar
                            variant="inline"
                            placeholder={t('settingFeeTaxiForm.periodEnd')}
                            inputVariant="outlined"
                            format="yyyy/MM/dd"
                            KeyboardButtonProps={{
                              'aria-label': 'change time',
                            }}
                            value={endTime || null}
                            onChange={(time) => this.setState({endTime: time})}
                          />
                          {this.validator.message('endTime', endTime, 'required')}
                          {this.state.isSubmitForm && !this.validator.check(endTime, 'required') && (
                            <FormHelperText error>{t('validation.required', {field: t('settingFeeTaxiForm.periodEnd')})}</FormHelperText>
                          )}
                          {this.state.isSubmitForm && endTime && startTime >= endTime && <FormHelperText error>{t('validation.time.more')}</FormHelperText>}
                        </FormControl>
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                </Paper>
                {/* General */}
                <SettingFee
                  validator={this.validator}
                  isNightShift={false}
                  isSubmitForm={isSubmitForm}
                  onChangeFeeSectionData={this.onChangeFeeSectionData}
                  dispatchFee={general.dispatchFee}
                  uniqueValidationField="normal"
                />
                {/* Night shift */}
                {nightShiftList.map((nightShiftItem, index) => {
                  return (
                    <SettingFee
                      validator={this.validator}
                      isNightShift={true}
                      isSubmitForm={isSubmitForm}
                      shiftTimeStart={nightShiftItem.shiftTimeStart}
                      shiftTimeEnd={nightShiftItem.shiftTimeEnd}
                      onChangeFeeSectionData={this.onChangeFeeSectionData}
                      uniqueValidationField={'nightShift_' + index + '_'}
                      shiftTimeIndex={index}
                      removeNightShiftSection={this.removeNightShiftSection}
                      dispatchFee={nightShiftItem.dispatchFee}
                      isOverlapTimeShift={overlapList.has(index)}
                      key={index}
                    />
                  );
                })}
                {nightShiftList.length < MAX_NIGHT_SHIFT_SECTION && (
                  <Button color="primary" variant="contained" className="width_100 button_margin button_color_green" endIcon={<AddIcon />} onClick={this.addNightShiftSection}>
                    {t('common.btnAdd')}
                  </Button>
                )}

                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {isDetails ? (
                      <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={this.handleUpdate}>
                        {t('common.btnUpdate')}
                      </Button>
                    ) : (
                      <>
                        <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={this.handleClickCreateButton}>
                          {t('common.btnRegister')}
                        </Button>
                      </>
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      className="button_margin button_color"
                      onClick={() => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.SETTING_FEE_TAXI)}
                    >
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                  <Dialog
                    open={flag}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      zIndex: '1020',
                    }}
                  >
                    <SelectModal
                      onClickOk={isDetails ? this.handleButtonUpdateOk : this.handleCreate}
                      onClickCancel={this.handleButtonUpdateCancel}
                      message={message}
                    ></SelectModal>
                  </Dialog>
                </Grid>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>
          <br></br>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    business: state.business,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListSupplier: () => dispatch(getListSupplier()),
    getListGeofence: () => dispatch(getListGeofence()),
    getListFacilityBySupplierID: (params) => dispatch(getListFacilityBySupplierID(params)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(SettingFeeTaxiForm)));
