import {getLineStationApi} from '../../services/memberServices.js';
import {modalObj} from '../../utils/modal.js';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

export const getLineStations = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_LINE_STATION_START});
    return getLineStationApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_LINE_STATION_SUCCESS,
            lineStation: response.result.content,
          });
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({type: ACTION_TYPES.GET_LINE_STATION_FAILED});
        }
      },
      () => dispatch({type: ACTION_TYPES.GET_LINE_STATION_FAILED}),
    );
  };
};
