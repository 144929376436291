import React, {Component} from 'react';

import {Card, Container, Grid, Paper, Table, TableCell, TableRow, TextField, Button, TableHead, TableBody, Dialog} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import DetailIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {ROUTE, ROWS_PER_PAGE_OPTIONS, EXPORT_ALERT_NUMBER_ROWS, PERMISSION_ACTIONS} from '../../../common/constant';
import CSVExporter from '../../../components/CSVExporter';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {deleteShuttleApi} from '../../../services/commonSettingServices';
import {searchListRightMenuSetting} from '../../../stores/right_menu/action';
import {onChangeTextField, getUrlParams, clearBlankValue, changeUrlParams, getQueryStringFromObject} from '../../../utils/common';

/**
 * Right Menu Setting's Search
 */
class RightMenu extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      header: '',
      title: '',

      index: null,
      isSearch: false,
      result: [],
      deleteId: null,
      flgDelete: false,
      flagExport: false,
      flag: false,
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,
    };

    this.handleButtonOk = this.handleButtonOk.bind(this);
    this.handleButtonCancel = this.handleButtonCancel.bind(this);
  }

  /**
   * componentDidMount
   */
  componentDidMount = () => {
    if (this.props.location.search) {
      this.setState(getUrlParams(this.props.location.search), this.handleSearch);
    }
  };
  /**
   * Reset Search
   */
  resetSearchCondition = () => {
    this.setState({
      header: '',
      title: '',
    });
  };

  /**
   * handleDelete
   * @param {Number} deleteId
   * @param {Number} index
   */
  handleDelete(deleteId, index) {
    this.setState({
      deleteId,
      index,
      flag: true,
      message: 'messageCode.deleteConfirmVehicle',
    });
  }

  /**
   * handleButtonOk
   */
  handleButtonOk() {
    this.setState({
      flag: false,
    });
    _.remove(this.state.result, (item) => {
      return item.id === this.state.deleteId;
    });
    deleteShuttleApi(this.state.deleteId);
  }

  /**
   * handleButtonCancel
   */
  handleButtonCancel() {
    this.setState({
      flag: false,
    });
  }

  /**
   * handleSearch
   * @param {bool} reset
   */
  async handleSearch(reset) {
    const {header, title, currentPage, rowsPerPage} = this.state;
    const payload = {
      header: header,
      title: title,
      ...(reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage}),
    };
    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    await this.props.searchListRightMenuSetting(clearBlankValue(payload)).then((response) => {
      this.setState(
        {
          result: response?.content,
          isSearch: true,
          totalRows: response?.totalSize,
        },
        () => {
          const {header, title, currentPage, rowsPerPage} = this.state;
          const queryParamsToChange = {
            header: header,
            title: title,
            currentPage,
            rowsPerPage,
          };
          changeUrlParams(queryParamsToChange);
          const searchParamsString = getQueryStringFromObject(payload);
          this.setState({searchParams: searchParamsString});
        },
      );
    });
  };

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearch);
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t, rightMenu, actions} = this.props;
    const {result} = this.state;
    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canRegister: actions.includes(PERMISSION_ACTIONS.REGISTER),
      canCSVOutput: actions.includes(PERMISSION_ACTIONS.CSV_OUTPUT),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
      canDelete: actions.includes(PERMISSION_ACTIONS.DELETE),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('shuttleSetting.headerTitle')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          <br></br>
          <LoadingOverlay active={rightMenu.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                {permission.canSearch && (
                  <>
                    <br></br>
                    <Paper className="search_table">
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                          {t('shuttleSetting.searchTitle')}
                        </Grid>
                      </Grid>
                      {/* rightMenu header */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('shuttleSetting.header')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="header"
                            className="field_size_20 field_min_size_250"
                            margin="dense"
                            placeholder={t('shuttleSetting.header')}
                            inputProps={{maxLength: 256}}
                            value={this.state.header}
                            onChange={(event) => onChangeTextField(this, event)}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>

                      {/* rightMenu title */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('shuttleSetting.title')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="title"
                            className="field_size_20 field_min_size_250"
                            margin="dense"
                            placeholder={t('shuttleSetting.title')}
                            inputProps={{maxLength: 256}}
                            value={this.state.title}
                            onChange={(event) => onChangeTextField(this, event)}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </>
                )}
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.resetSearchCondition} endIcon={<RotateLeftIcon />}>
                        {t('common.btnResetSearch')}
                      </Button>
                    )}
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {permission.canRegister && (
                      <Link
                        style={{textDecoration: 'none'}}
                        to={{pathname: ROUTE.LAYOUT + ROUTE.RIGHT_MENU_MANAGEMENT_CREATE, state: {from: this.props.location.pathname + '?' + this.state.searchParams}}}
                      >
                        <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />}>
                          {t('common.btnRegister')}
                        </Button>
                      </Link>
                    )}
                    {permission.canCSVOutput && permission.canSearch && (
                      <div className={this.state.totalRows <= EXPORT_ALERT_NUMBER_ROWS || this.state.confirmedExport ? '' : 'hidden'}>
                        <CSVExporter
                          ref={this.exportCSVRef}
                          disabled={this.state.totalRows <= 0 || !this.state.latestSearchParams}
                          headers={this.exportHeaders}
                          fetchAction={this.fetchDataExport}
                          isFetchAsync={true}
                          screenName={t('businessVehicle.title')}
                        />
                      </div>
                    )}
                    {this.state.totalRows >= EXPORT_ALERT_NUMBER_ROWS && !this.state.confirmedExport && permission.canCSVOutput && permission.canSearch && (
                      <Button
                        onClick={this.alertExportCSV}
                        disabled={this.state.totalRows <= 0}
                        color="primary"
                        variant="contained"
                        className="button_margin"
                        endIcon={<GetAppIcon className="csv-exporter-icon" />}
                      >
                        {t('calendar.csv_output')}
                      </Button>
                    )}
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin" onClick={() => this.handleSearch(true)} endIcon={<SearchIcon />}>
                        {t('common.btnSearch')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <br></br>
                <Dialog
                  open={this.state.flag}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    onClickOk={this.handleButtonOk}
                    onClickCancel={this.handleButtonCancel}
                    message={this.state.message}
                    okButtonText={t('common.btnYes')}
                    cancelButtonText={t('common.btnCancel')}
                  ></SelectModal>
                </Dialog>
              </Container>
            </Card>
          </LoadingOverlay>
          <br></br>
          {this.state.isSearch ? (
            <Card raised>
              <Container maxWidth="xl">
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={6}>
                    <h3>
                      {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                    </h3>
                  </Grid>
                </Grid>
                <LoadingOverlay active={rightMenu.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                  {result?.length > 0 && (
                    <div className="scroll_area_700">
                      <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                        <TableHead>
                          <TableRow>
                            <TableCell className="width_150p ant-table-cell-fix-left">{t('common.id')}</TableCell>
                            <TableCell className="width_150p">{t('shuttleSetting.header')}</TableCell>
                            <TableCell className="width_150p">{t('shuttleSetting.title')}</TableCell>
                            <TableCell className="width_400p">{t('shuttleSetting.description')}</TableCell>
                            {(permission.canEdit || permission.canDelete) && (
                              <TableCell className="width_250p" style={{position: 'sticky', right: '0'}}>
                                {t('common.action')}
                              </TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {result?.map((row, index) => {
                            return (
                              <TableRow key={index} hover className="cursor_pointer">
                                <TableCell scope="row" className="ant-table-cell-fix-left">
                                  {row.id}
                                </TableCell>
                                <TableCell>{row.header}</TableCell>
                                <TableCell>{row.title}</TableCell>
                                <TableCell>{row.description}</TableCell>
                                {(permission.canEdit || permission.canDelete) && (
                                  <TableCell>
                                    {permission.canEdit && (
                                      <>
                                        <Link
                                          style={{textDecoration: 'none'}}
                                          to={{
                                            pathname: ROUTE.LAYOUT + ROUTE.RIGHT_MENU_MANAGEMENT_DETAIL + '/' + row.id,
                                            state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                          }}
                                        >
                                          <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                            {t('common.btnEdit')}
                                          </Button>
                                        </Link>
                                        &nbsp;
                                      </>
                                    )}
                                    {permission.canDelete && (
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        className="button_margin button_color_red"
                                        endIcon={<DeleteIcon />}
                                        onClick={() => this.handleDelete(row.id, index)}
                                      >
                                        {t('common.btnDelete')}
                                      </Button>
                                    )}
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                      <br></br>
                    </div>
                  )}
                  <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                </LoadingOverlay>
              </Container>
            </Card>
          ) : (
            ''
          )}

          <br></br>
          {/* Export alert modal */}
          <Dialog
            open={this.state.flagExport}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal
              onClickOk={this.handleButtonExportOk}
              onClickCancel={this.handleButtonExportCancel}
              okButtonText={t('common.btnYes')}
              cancelButtonText={t('common.btnNo')}
              message={this.state.message}
            ></SelectModal>
          </Dialog>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rightMenu: state.rightMenu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchListRightMenuSetting: (params, props) => dispatch(searchListRightMenuSetting(params, props)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(RightMenu)));
