import React, {Component} from 'react';

import {TextField} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import {Autocomplete} from '@material-ui/lab';
import {Map, GoogleApiWrapper, Marker} from 'google-maps-react';
import _ from 'lodash';
import {matchSorter} from 'match-sorter';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';

import {getTransitStopNearestApi} from '../../services/reservationServices';
import {setMessageModal} from '../../stores/modal/actions';
import {getDataMapsStart, getDataMapsDone} from '../../stores/reservation/actions';
import {customDisplayCurrency} from '../../utils/common';
import {modalObj} from '../../utils/modal.js';

/**
 * Google maps component
 */
class MapsRestrict extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      position: '',
      activeMarker: '',
      bus_stops: [],
      allMarkers: [],
      nearestPartnerList: [],
      waypointData: '',
    };
  }

  /**
   * setting position
   * @param {pos} pos
   */
  setPosition(pos) {
    if (pos?.geofence_id) {
      const geofence = this.props.geofences.find((item) => item.id === pos.geofence_id);
      if (geofence) {
        pos.geofence_name = geofence.name;
      }
    }
    if (this.props.listWaypointStops && pos) {
      const position = this.props.listWaypointStops?.transit_stops?.find(
        (item) => item?.id === pos?.transit_stop_swat_id || item?.swat_transit_stop_id === pos?.transit_stop_swat_id,
      );
      pos.external_id = position ? position?.external_id : null;
    }
    this.props.position(pos);
  }

  /**
   * componentDidUpdate
   * @param {Object} prevProps
   */
  async componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.geofences) !== JSON.stringify(this.props.geofences) ||
      JSON.stringify(prevProps.listWaypointStops) !== JSON.stringify(this.props.listWaypointStops) ||
      prevProps.parentData?.selectHistoryId !== this.props.parentData?.selectHistoryId ||
      prevProps.swapLocation !== this.props.swapLocation ||
      prevProps.parentData?.transit_stop_swat_id !== this.props.parentData?.transit_stop_swat_id ||
      JSON.stringify(prevProps?.availableWaypoints) !== JSON.stringify(this.props.availableWaypoints) ||
      prevProps.requestedDepartureTime !== this.props.requestedDepartureTime
    ) {
      await this.removeMarkers();
      await this.loadGeoJson(this.props.geofences);
      await this.renderAutoComplete();
    }
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    this.props.geofences && this.loadGeoJson(this.props.geofences);
  }

  /**
   * registerCheckPolygonContainPoint
   * @param {*} google
   */
  registerCheckPolygonContainPoint(google) {
    google.maps.Polygon.prototype.Contains = function(point) {
      let crossings = 0;
      const path = this.getPath();

      // for each edge
      for (let i = 0; i < path.getLength(); i++) {
        const a = path.getAt(i);
        let j = i + 1;
        if (j >= path.getLength()) {
          j = 0;
        }
        const b = path.getAt(j);
        if (rayCrossesSegment(point, a, b)) {
          crossings++;
        }
      }

      // odd number of crossings?
      return crossings % 2 === 1;

      /**
       * rayCrossesSegment
       * @param {*} point
       * @param {*} a
       * @param {*} b
       * @return {*}
       */
      function rayCrossesSegment(point, a, b) {
        let px = point.lng();
        let py = point.lat();
        let ax = a.lng();
        let ay = a.lat();
        let bx = b.lng();
        let by = b.lat();
        if (ay > by) {
          ax = b.lng();
          ay = b.lat();
          bx = a.lng();
          by = a.lat();
        }
        // alter longitude to cater for 180 degree crossings
        if (px < 0) {
          px += 360;
        }
        if (ax < 0) {
          ax += 360;
        }
        if (bx < 0) {
          bx += 360;
        }

        if (py === ay || py === by) py += 0.00000001;
        if (py > by || py < ay || px > Math.max(ax, bx)) return false;
        if (px < Math.min(ax, bx)) return true;

        const red = ax !== bx ? (by - ay) / (bx - ax) : Infinity;
        const blue = ax !== px ? (py - ay) / (px - ax) : Infinity;
        return blue >= red;
      }
    };
  }

  /**
   * getTransitStopNearest
   * @param {*} latLng
   */
  async getTransitStopNearest(latLng) {
    this.props.getDataMapsStart();
    if (this.props.type !== 'DELIVERY') {
      const payload = {
        latitude: latLng.lat(),
        longitude: latLng.lng(),
        radius: 10000,
        simulationIds: this.props.geofences[0].on_demand_sim_id,
        page: 0,
        size: 500,
      };
      const transitStop = await getTransitStopNearestApi(payload);
      if (transitStop?.status === 200) {
        this.removeMarkers();
        const bus_stops = transitStop?.result?.transit_stops?.filter?.((e) => e.simulation_id === this.props.geofences[0].on_demand_sim_id);
        this.setState({bus_stops});
        if (!_.isEmpty(bus_stops)) {
          const listWaypointInfo = bus_stops.map((item) => {
            const partner = this.props.listWaypointPartner?.partner_discounts?.find((partner) => partner.waypoint_id === item?.swat_transit_stop_id);
            const waypoint_restrict = this.props.listWaypointRestrict?.find((waypoint_restrict) => waypoint_restrict?.waypoint_id === item?.swat_transit_stop_id);
            if (partner) {
              item = {
                ...item,
                partner,
              };
            }
            if (waypoint_restrict) {
              item = {
                ...item,
                waypoint_restrict,
              };
            }
            return item;
          });
          this.setState({nearestPartnerList: listWaypointInfo});
          listWaypointInfo.map((item) => this.renderMarker(item));
        }
      }
    }
    this.props.getDataMapsDone();
  }

  /**
   * Remove all marker objects on the Google Maps
   */
  removeMarkers = () => {
    const {google} = this.props;
    for (let i = 0; i < this.state.allMarkers.length; i++) {
      // delete all event listeners registered for the marker object at location i
      google.maps.event.clearInstanceListeners(this.state.allMarkers[i]);
      // remove the marker object at location i from the map
      this.state.allMarkers[i].setMap(null);
    }
    this.setState({
      allMarkers: [],
    });
  };

  /**
   * load GeoJson
   * @param {object} geofences
   */
  loadGeoJson(geofences) {
    const {google} = this.props;
    this.registerCheckPolygonContainPoint(google);

    // remove all data added
    this.mapRef.map.data.forEach((feature) => {
      this.mapRef.map.data.remove(feature);
    });

    geofences.forEach((geofence) => {
      // add new geojson (polygon and multi polygon)
      if (geofence.geometry.type === 'Polygon') {
        const jsonData = {
          type: 'Feature',
          properties: {
            id: geofence.id,
            geofence_id: geofence.geofence_id,
          },
          geometry: {
            type: geofence.geometry.type,
            coordinates: geofence.geometry.coordinates,
          },
        };
        this.mapRef.map.data.addGeoJson(jsonData);
      }
      if (geofence.geometry.type === 'MultiPolygon') {
        geofence.geometry.coordinates.forEach((value) => {
          const jsonData = {
            type: 'Feature',
            properties: {
              id: geofence.id,
              geofence_id: geofence.geofence_id,
            },
            geometry: {
              type: 'Polygon',
              coordinates: value,
            },
          };
          this.mapRef.map.data.addGeoJson(jsonData);
        });
      }
    });
    // set style to geofence
    this.mapRef.map.data.setStyle({
      strokeColor: '#99cecf',
      fillColor: '#99cecf',
      fillOpacity: 0.5,
      strokeWeight: 0.1,
    });

    // focus to center geofence
    const bounds = new google.maps.LatLngBounds();
    this.mapRef.map.data.forEach((feature) => {
      feature.getGeometry().forEachLatLng(function(latlng, i) {
        bounds.extend(latlng);
      });

      if (!_.isEmpty(this.props.parentData)) {
        const lat = !isNaN(this.props.parentData?.latLng?.lat) ? this.props.parentData?.latLng?.lat : this.props.parentData?.latLng?.lat();
        const lng = !isNaN(this.props.parentData?.latLng?.lng) ? this.props.parentData?.latLng?.lng : this.props.parentData?.latLng?.lng();
        const check = this.checkPolygonContainsPoint(new google.maps.LatLng(lat, lng));
        const geocoder = new google.maps.Geocoder();

        geocoder.geocode({latLng: bounds.getCenter()}, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            const waypointSwat = this.props.listWaypointStops?.transit_stops?.find(
              (e) =>
                e.swat_transit_stop_id === this.props.parentData?.transit_stop_swat_id ||
                e.swat_transit_stop_id === this.props.parentData?.external_id ||
                e.id === this.props.parentData?.transit_stop_swat_id,
            );
            this.setState({
              position: new google.maps.LatLng(lat, lng),
              activeMarker: new google.maps.LatLng(lat, lng),
              waypointData: waypointSwat,
            });
            this.setPosition({
              latLng: new google.maps.LatLng(lat, lng),
              place_name: this.props.parentData?.place_name,
              id: this.props.parentData?.id,
              transit_stop_swat_id: this.props.parentData?.transit_stop_swat_id,
              geofence_id: this.props.parentData?.geofence_id,
              name: this.props.parentData?.name,
              external_id: this.props.parentData?.external_id,
            });
            this.props.getNearestData && check && this.getTransitStopNearest(new google.maps.LatLng(lat, lng));
          }
        });
      } else {
        const check = this.checkPolygonContainsPoint(bounds.getCenter());
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({latLng: bounds.getCenter()}, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK) {
            this.setState({
              position: '',
              activeMarker: '',
              waypointData: '',
            });
            this.setPosition(null);
          }
        });
        this.props.getNearestData && check && this.getTransitStopNearest(bounds.getCenter());
      }
    });
    this.mapRef.map.fitBounds(bounds);
  }

  /**
   * renderAutoComplete
   */
  renderAutoComplete = () => {
    const {google} = this.props;
    // Determine the limits of the map
    const bounds = new google.maps.LatLngBounds();
    this.mapRef.map.data.forEach((feature) => {
      feature.getGeometry().forEachLatLng(function(latlng, i) {
        // create a limit for map
        bounds.extend(latlng);
      });
    });

    const option = {
      bounds: bounds,
      strictBounds: true,
    };

    const autocomplete = new google.maps.places.Autocomplete(this.autocomplete, option);
    google.maps.event.addListener(this.mapRef, 'bounds_changed', function() {
      autocomplete.bindTo(this.mapRef, 'bounds');
    });

    autocomplete.addListener('place_changed', async () => {
      const place = autocomplete.getPlace();
      if (!place.geometry) return;

      if (place.geometry.viewport) {
        this.mapRef.map.fitBounds(place.geometry.viewport);
      } else {
        this.mapRef.map.setCenter(place.geometry.location);
        this.mapRef.map.setZoom(20);
      }
      const check = this.checkPolygonContainsPoint(place.geometry.location);
      this.setState({
        bounds: bounds,
        position: place.geometry.location,
        activeMarker: place.geometry.location,
        place_name: place.formatted_address,
      });
      this.setPosition({
        latLng: place.geometry.location,
        place_name: place.formatted_address,
        id: check.id,
        geofence_id: check.geofence_id,
        name: check.name,
      });

      if (!check) {
        this.props.setMessageModal(modalObj(true, this.props.t('reservationManagement.out_geofence')));
        this.removeMarkers();
      }
      this.props.getNearestData && check && this.getTransitStopNearest(place.geometry.location);
    });
  };

  /**
   * checkPolygonContainsPoint
   * @param {object} point
   * @return {boolean}
   */
  checkPolygonContainsPoint(point) {
    const {google} = this.props;
    let result = false;
    if (point && this.props.geofences.length > 0) {
      this.mapRef.map.data.forEach((feature) => {
        if (feature.getGeometry().getType() === 'Polygon') {
          const polyPath = feature.getGeometry().getAt(0).getArray();
          const poly = new google.maps.Polygon({
            paths: polyPath,
          });
          // eslint-disable-next-line new-cap
          if (poly.Contains(point)) {
            result = {
              id: feature.getProperty('id'),
              geofence_id: feature.getProperty('geofence_id'),
              name: feature.getProperty('name'),
            };
          }
        }
        return false;
      });
      return result;
    }
    return false;
  }

  /**
   * isPartnerDiscount
   * @param {*} partnerInfo
   * @return {Boolean}
   */
  isPartnerDiscount = (partnerInfo) => {
    return (
      partnerInfo?.discount > 0 &&
      new Date() >= new Date(partnerInfo?.effect_start_time) &&
      new Date() <= new Date(partnerInfo?.effect_end_time) &&
      partnerInfo?.payment_status === 'PAID'
    );
  };

  /**
   * render marker
   * @param {Object} item
   * @param {Boolean} isPartner
   */
  renderMarker(item) {
    const isPartner = !!item?.partner;
    const {google, t, availableWaypoints} = this.props;
    // display render
    const isDisable = !!item?.waypoint_restrict;
    const listWaypointSwatId = availableWaypoints?.map((item) => item.swat_transit_stop_id);
    const availableMarker = listWaypointSwatId?.includes(item?.external_id);
    const url = availableMarker ?
      isPartner && !isDisable ?
          item.partner.partner_logo_url ||
          (this.isPartnerDiscount(item?.partner) ?
            `${process.env.PUBLIC_URL}/images/reservation/stop_bus_discount.svg` :
            `${process.env.PUBLIC_URL}/images/reservation/stop_bus.svg`) :
        isDisable ?
        item?.waypoint_restrict?.restriction_image_url || `${process.env.PUBLIC_URL}/images/reservation/disable-waypoint-icon.svg` :
        `${process.env.PUBLIC_URL}/images/reservation/stop_bus.svg` :
      `${process.env.PUBLIC_URL}/images/reservation/disable-waypoint-icon.svg`;
    const marker = new google.maps.Marker({
      position: {lat: item.point.coordinates[1], lng: item.point.coordinates[0]},
      icon: {
        url,
        strokeWeight: 1,
        strokeColor: this.props.markerColor,
        fillColor: this.props.markerColor,
        fillOpacity: 1,
        scaledSize: new google.maps.Size(34, 34),
      },
      map: this.mapRef.map,
    });
    marker.set('name', isPartner && !isDisable ? 'PARTNER' : isDisable ? 'DISABLE' : 'BUS_STOP');
    marker.set('id', isPartner ? item.partner?.waypoint_id : item.id);
    marker.info = new google.maps.InfoWindow({
      content:
        isPartner && !isDisable ?
          (!_.isEmpty(item.name_translations) ? item.name_translations[localStorage.getItem('i18nextLng')] : item?.name) +
            '<br />' +
            (item?.partner?.discount_type === 'PERCENT' ?
              `${t('reservationManagement.discount_percent')}: ${item?.partner?.discount}%` :
              `${t('reservationManagement.discount_price')}: ${customDisplayCurrency(item?.partner?.discount, this.props.currency_code)}`) :
          (!_.isEmpty(item.name_translations) ? item.name_translations[localStorage.getItem('i18nextLng')] : item?.name) +
            `<br /> ${isDisable ? item?.waypoint_restrict?.description : ''}`,
    });

    // open marker informations
    if (this.state.position && this.state.position?.equals(marker.position)) {
      marker.info.open(this.mapRef.map, marker);
      if (marker.name !== 'DISABLE') {
        marker.setIcon(`${process.env.PUBLIC_URL}/images/reservation/stop_bus_nearest.svg`);
      }
    }
    this.state.allMarkers.push(marker);
    // point click display info
    google.maps.event.addListener(marker, 'click', () => {
      this.state.allMarkers.forEach((mark) => {
        if (mark.name === 'PARTNER') {
          const partnerInfo = this.state.nearestPartnerList?.find((e) => Number(e.swat_transit_stop_id) === Number(mark.id));
          const checkDisablePartner = !!partnerInfo?.waypoint_restrict;
          const url = !checkDisablePartner ?
            partnerInfo?.partner?.partner_logo_url ||
            (this.isPartnerDiscount(partnerInfo?.partner) ?
              `${process.env.PUBLIC_URL}/images/reservation/stop_bus_discount.svg` :
              `${process.env.PUBLIC_URL}/images/reservation/stop_bus.svg`) :
              partnerInfo?.waypoint_restrict?.restriction_image_url || `${process.env.PUBLIC_URL}/images/reservation/disable-waypoint-icon.svg`;
          mark.setIcon({
            url,
            strokeWeight: 1,
            strokeColor: this.props.markerColor,
            fillColor: this.props.markerColor,
            fillOpacity: 1,
            scaledSize: new google.maps.Size(34, 34),
          });
        } else if (mark.name === 'BUS_STOP') {
          mark.setIcon(`${process.env.PUBLIC_URL}/images/reservation/stop_bus.svg`);
        }
        mark.info.close();
      });
      if (marker.name !== 'DISABLE') {
        marker.setIcon(`${process.env.PUBLIC_URL}/images/reservation/stop_bus_nearest.svg`);
      }
      marker.info.open(this.mapRef.map, marker);
      const waypointSwat = this.props.listWaypointStops?.transit_stops?.find(
        (e) => e.swat_transit_stop_id === item.swat_transit_stop_id || e.swat_transit_stop_id === item.transit_stop_swat_id,
      );
      this.onChangeAutoCompleteWaypoint(waypointSwat);
    });
  }

  /**
   * onChangeAutoCompleteWaypoint
   * @param {*} waypointInfo
   */
  onChangeAutoCompleteWaypoint = async (waypointInfo) => {
    if (waypointInfo === null) return;
    const {google} = this.props;
    if (waypointInfo) {
      const locationLatLng = new google.maps.LatLng(waypointInfo?.point?.coordinates[1], waypointInfo?.point?.coordinates[0]);
      const check = this.checkPolygonContainsPoint(locationLatLng);
      this.setState({
        position: new google.maps.LatLng(locationLatLng),
        activeMarker: new google.maps.LatLng(locationLatLng),
        waypointData: waypointInfo,
      });
      const checkWaypointRestrict = this.props.listWaypointRestrict?.map((item) => item?.waypoint_id)?.includes(waypointInfo?.swat_transit_stop_id);
      this.setPosition({
        latLng: new google.maps.LatLng(locationLatLng),
        place_name: waypointInfo.display_name,
        id: waypointInfo?.id,
        transit_stop_swat_id: waypointInfo.swat_transit_stop_id,
        geofence_id: waypointInfo.geofence_id,
        name: checkWaypointRestrict ? 'DISABLE' : waypointInfo.type,
      });
      this.props.getNearestData && check && this.getTransitStopNearest(new google.maps.LatLng(locationLatLng));
    }
  };

  /**
   * handleClearAutocomplete
   */
  handleClearAutocomplete = async () => {
    const {google} = this.props;
    const bounds = new google.maps.LatLngBounds();
    this.mapRef.map.data.forEach((feature) => {
      feature.getGeometry().forEachLatLng(function(latlng, i) {
        bounds.extend(latlng);
      });
    });
    this.setPosition(null);
    const check = this.checkPolygonContainsPoint(bounds.getCenter());
    this.setState({waypointData: '', position: '', activeMarker: ''});
    this.props.getNearestData && check && this.getTransitStopNearest(bounds.getCenter());
    this.props.resetWaypoint(this.props.type === 'DEPARTURE' ? 'PICK_UP' : 'DROP_OFF');
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t, availableWaypoints} = this.props;
    return (
      <div>
        {this.props.getWaypointData && (
          <Autocomplete
            margin="dense"
            className="width_100"
            options={
              _.sortBy(availableWaypoints, [
                function(option) {
                  return option?.name_translations?.[localStorage.getItem('i18nextLng')] || option?.name;
                },
              ]) || []
            }
            filterOptions={(options, {inputValue}) =>
              matchSorter(options, inputValue, {
                keys: [(item) => item.name_translations?.[localStorage.getItem('i18nextLng')] || item.name],
              })
            }
            value={this.state.waypointData || null}
            getOptionLabel={(option) => option && (option?.name_translations?.[localStorage.getItem('i18nextLng')] || option?.name)}
            getOptionSelected={(option, value) => option.name === value.name}
            onChange={(event, value) => this.onChangeAutoCompleteWaypoint(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  <span>
                    {this.props.pickUp ? t('favoriteWaypoint.pick_up_name') : t('favoriteWaypoint.drop_off_name')} <span className="font_color_red">＊</span>
                  </span>
                }
                name={'businessName'}
                fullWidth
                variant="outlined"
                margin="dense"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>{this.state.waypointData && <ClearIcon style={{cursor: 'pointer', marginRight: '5px', width: '18px'}} onClick={() => this.handleClearAutocomplete()} />}</>
                  ),
                }}
              />
            )}
            ref={(ref) => (this.autocomplete = ref)}
          />
        )}
        <LoadingOverlay active={this.props.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
          <Map
            {...this.props}
            ref={(ref) => (this.mapRef = ref)}
            initialCenter={{
              lat: 36.2048,
              lng: 138.2529,
            }}
            zoom={5}
            centerAroundCurrentLocation={false}
            s
            containerStyle={{
              height: '400px',
              position: 'relative',
              width: '100%',
            }}
            onReady={this.renderAutoComplete.bind(this)}
          >
            {this.state.activeMarker && (
              <Marker
                position={this.state.activeMarker}
                draggable={false}
                icon={{
                  url: this.checkPolygonContainsPoint(this.state.activeMarker) ?
                    this.props.type === 'DEPARTURE' ?
                      `${process.env.PUBLIC_URL}/images/ic_pick_origin.svg` :
                      `${process.env.PUBLIC_URL}/images/ic_pick_destination.svg` :
                    `${process.env.PUBLIC_URL}/images/position_out_goefence.svg`,
                  scale: 10,
                  strokeWeight: 2,
                  strokeColor: this.props.markerColor,
                  fillColor: this.props.markerColor,
                  fillOpacity: 1,
                }}
              ></Marker>
            )}
          </Map>
        </LoadingOverlay>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.reservation.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMessageModal: (params) => dispatch(setMessageModal(params)),
    getDataMapsStart: () => dispatch(getDataMapsStart()),
    getDataMapsDone: () => dispatch(getDataMapsDone()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  // eslint-disable-next-line new-cap
  GoogleApiWrapper((props) => ({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || 'AIzaSyACyapw83diO1bi_xiXbZRLLoano6eTwd0',
    language: localStorage.getItem('i18nextLng') === 'ja' ? 'ja' : 'en',
  }))(withTranslation('translations')(MapsRestrict)),
);
