export const ACTION_TYPES = {
  GET_MAIL_START: 'GET_MAIL_START',
  GET_MAIL_SUCCESS: 'GET_MAIL_SUCCESS',
  GET_MAIL_FAIL: 'GET_MAIL_FAIL',

  UPDATE_MAIL_START: 'UPDATE_MAIL_START',
  UPDATE_MAIL_SUCCESS: 'UPDATE_MAIL_SUCCESS',
  UPDATE_MAIL_FAIL: 'UPDATE_MAIL_FAIL',
};

const initialState = {
  isLoading: false,
  mailsList: [],
};

/**
 * function reduce
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.GET_MAIL_START:
    case ACTION_TYPES.UPDATE_MAIL_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.GET_MAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        mailsList: action.mailsList,
      };
    case ACTION_TYPES.UPDATE_MAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.GET_MAIL_FAIL:
      return {
        ...state,
        isLoading: false,
        mailsList: [],
      };
    case ACTION_TYPES.UPDATE_MAIL_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
