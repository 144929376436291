import React, {Component} from 'react';

import DateFnsUtils from '@date-io/date-fns';
// eslint-disable-next-line max-len
import {
  Card,
  Container,
  Grid,
  Button,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Dialog,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DetailIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {format} from 'date-fns';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {
  DATA_NULL,
  PARTNER_PAYMENT_STATUS,
  ROUTE,
  ROWS_PER_PAGE_OPTIONS,
  EXPORT_LIMIT_NUMBER_ROWS,
  EXPORT_ALERT_NUMBER_ROWS,
  DISCOUNT_TYPE,
  GEOFENCE_STATUS,
  PAYMENT_METHODS,
  PERMISSION_ACTIONS,
  LANGUAGE,
  COMMON_STATUS,
  WAYPOINT_STATUS,
} from '../../../common/constant';
import CSVExporter from '../../../components/CSVExporter';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getListGeofenceByCountry, getAllCountryCode, getAllGeofence} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {searchPartnerList} from '../../../stores/partner/actions';
import {onChangeTextField, changeUrlParams, getUrlParams, getQueryStringFromObject, onChangeSelect, clearBlankValue, customDisplayCurrency} from '../../../utils/common';
import {backForwardRouter} from '../../../utils/common';
import {convertDatetimeUTC} from '../../../utils/datetime';
import {modalObj} from '../../../utils/modal';
import {isRoleGlobal} from '../../../utils/role';

/**
 * Partner (Shop Subscription) Search Component
 */
class SearchShopSubscription extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      country: !isRoleGlobal() ? props.principal.country_id : '',
      country_code: '',
      geofence_id: '',
      phone_number: '',
      address: '',
      qr_code: '',
      payment_status: '',
      effect_start_time: null,
      effect_end_time: null,
      discount_type: '',
      usage_status: '',

      listGeofenceAll: [],

      id: '',
      index: '',

      result: [],
      isSearch: false,
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,
      flagExport: false,
      confirmedExport: false,
      latestSearchParams: null,
      waypoint_restrict: null,
      visible_flag: true,
      waypoint_name: '',
    };

    this.exportCSVRef = React.createRef();
    const {t} = this.props;
    this.exportHeaders = [
      {label: t('common.name'), key: 'name'},
      {label: t('businessPartner.code'), key: 'code'},
      {label: t('businessPartner.description'), key: 'description'},
      {label: t('businessPartner.discount'), key: 'discount'},
    ];
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllGeofence().then(() => this.setState({listGeofenceAll: this.props.common?.all_geofence}));
    await this.props.getAllCountryCode();
    const {common, principal} = this.props;
    if (!isRoleGlobal()) {
      this.setState({
        country_code: common.country_code.find((item) => item.id === principal.country_id)?.country_code,
      });
    }
    if (this.props.location.search) {
      const params = getUrlParams(this.props.location.search);
      if (isRoleGlobal() ? true : common?.country_code?.find((e) => e.country_code === params?.country_code)?.id === principal.country_id) {
        if (params.effect_start_time) {
          params.effect_start_time = new Date(params.effect_start_time);
        }
        if (params.effect_end_time) {
          params.effect_end_time = new Date(params.effect_end_time);
        }
        if (params.country_code) {
          const country_id = common?.country_code?.find((e) => e.country_code === params.country_code)?.id;
          this.setState({
            listGeofenceAll: common?.all_geofence?.filter((e) => e.country_id === Number(country_id)),
          });
        }
        this.setState(params, this.handleSearch);
      } else backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.HOME);
    }
  }

  /**
   * reset
   */
  reset = () => {
    const {principal, common} = this.props;
    this.setState({
      name: '',
      country: !isRoleGlobal() ? principal.country_id : '',
      country_code: !isRoleGlobal() ? common.country_code.find((item) => item.id === principal.country_id)?.country_code : '',
      geofence_id: '',
      phone_number: '',
      address: '',
      qr_code: '',
      payment_status: '',
      effect_start_time: null,
      effect_end_time: null,
      discount_type: '',
      usage_status: '',
      listGeofenceAll: common?.all_geofence,
      waypoint_restrict: '',
      waypoint_name: '',
      visible_flag: true,
    });
  };

  /**
   * handleSearch
   * @param {bool} reset
   *
   */
  handleSearch = (reset) => {
    if (!this.props.actions.includes(PERMISSION_ACTIONS.SEARCH)) return;
    if ((this.state.effect_start_time || this.state.effect_end_time) && !this.state.country_code) return;
    const {
      name,
      country_code,
      geofence_id,
      phone_number,
      address,
      qr_code,
      payment_status,
      effect_start_time,
      effect_end_time,
      discount_type,
      usage_status,
      visible_flag,
      waypoint_name,
    } = this.state;
    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const {currentPage, rowsPerPage} = this.state;
    const queryParams = {
      name: name.trim(),
      country_code,
      geofence_id,
      phone_number: phone_number.trim(),
      address: address.trim(),
      qr_code: qr_code.trim(),
      payment_status,
      effect_start_time: effect_start_time ? format(new Date(effect_start_time), 'yyyy-MM-dd') : null,
      effect_end_time: effect_end_time ? format(new Date(effect_end_time), 'yyyy-MM-dd') : null,
      discount_type,
      disabled: usage_status === '' ? usage_status : !usage_status,
      // eslint-disable-next-line new-cap
      zoneid: Intl.DateTimeFormat().resolvedOptions().timeZone,
      waypoint_restrict: this.state.waypoint_restrict ? this.state.waypoint_restrict === 'ENABLE' : '',
      waypoint_name: waypoint_name.trim(),
      visible_flag: visible_flag,

      ...(reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage}),
    };

    this.props.searchPartnerList(clearBlankValue(queryParams), this.props).then((response) => {
      if (!response) {
        response.content = [];
      }
      this.setState(
        {
          result: this.handleSearchResult(response?.content || []),
          totalRows: response?.totalSize,
          isSearch: true,
          latestSearchParams: queryParams,
        },
        () => {
          const {
            name,
            country_code,
            geofence_id,
            phone_number,
            address,
            qr_code,
            payment_status,
            effect_start_time,
            effect_end_time,
            discount_type,
            currentPage,
            rowsPerPage,
            usage_status,
            waypoint_restrict,
            waypoint_name,
            visible_flag,
          } = this.state;
          // Apply changed params into url
          const queryParamsToChange = {
            name,
            country_code,
            geofence_id,
            phone_number,
            address,
            qr_code,
            payment_status,
            effect_start_time,
            effect_end_time,
            discount_type,
            disabled: usage_status === '' ? usage_status : !usage_status,
            waypoint_restrict,
            currentPage: String(currentPage),
            rowsPerPage,
            waypoint_name,
            visible_flag,
          };
          changeUrlParams(queryParamsToChange);
          const newSearchParams = getQueryStringFromObject(queryParamsToChange);
          // Save search params into state in order to pass to next page
          this.setState({searchParams: newSearchParams});
        },
      );
    });
  };

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearch);
  };

  /**
   * Fetch data to export
   */
  fetchDataExport = async () => {
    // const queryParams = {page: 0, size: MAX_SIZE_OUTPUT};
    // const dataExport = await this.props.exportImage(this.state.latestSearchParams, queryParams);
    // const convertedData = dataExport.map((row, index) => {
    //   return row;
    // });
    // this.setState({confirmedExport: false});
    // return convertedData;
  };

  /**
   * alertExportCSV
   */
  alertExportCSV = () => {
    const {t} = this.props;
    if (this.state.totalRows > EXPORT_LIMIT_NUMBER_ROWS) {
      this.props.setMessageModal(modalObj(true, t('messageCode.exportAlertLimit')));
    } else {
      const message = t('messageCode.exportAlert');
      this.setState({flagExport: true, message});
    }
  };

  /**
   * handleButtonExportOk
   */
  handleButtonExportOk = () => {
    this.setState({flagExport: false, confirmedExport: true}, () => {
      this.exportCSVRef.current.onClickExport();
    });
  };

  /**
   * handleButtonExportCancel
   */
  handleButtonExportCancel = () => {
    this.setState({flagExport: false});
  };

  /**
   * changeCountry
   * @param {*} e
   */
  changeCountry = (e) => {
    const country_id = this.props.common?.country_code?.find((item) => item.country_code === e.target.value)?.id;
    this.setState({
      country: this.props.common?.country_code?.find((item) => item.country_code === e.target.value).id,
      country_code: e.target.value,
      listGeofenceAll: this.props.common?.all_geofence?.filter((item) => item.country_id === country_id),
      geofence_id: '',
    });
  };

  /**
   * changeGeofence
   * @param {*} event
   */
  changeGeofence = (event) => {
    const geofence_id = event.target.value;
    this.setState({
      geofence_id,
    });
  };

  /**
   * Handle data Search and Convert list geofence
   * @param {Array} searchDatas
   * @return {Array}
   */
  handleSearchResult = (searchDatas) => {
    return searchDatas.map((searchData) => ({
      ...searchData,
      geofencesName: this.getGeofencesNameFromIds(searchData.geofence_id || []),
    }));
  };

  /**
   * Convert data Geofence to Array
   * @param {String} geofence_ids
   * @return {Object}
   */
  getGeofencesNameFromIds = (geofence_ids) => {
    const {listGeofenceAll} = this.state;
    const geofences_details = listGeofenceAll.find((item) => item.geofence_id === geofence_ids);
    return {name: geofences_details?.name, enable: geofences_details?.enable};
  };

  /**
   * convertGeofenceIdToName
   * @param {String} geofencesName
   * @return {HTMLElement}
   */
  convertGeofenceIdToName = (geofencesName) => {
    return geofencesName?.enable ? <div>{geofencesName?.name}</div> : <strike>{geofencesName?.name}</strike>;
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t, common, actions} = this.props;
    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canCSVOutput: actions.includes(PERMISSION_ACTIONS.CSV_OUTPUT),
      canRegister: actions.includes(PERMISSION_ACTIONS.REGISTER),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.partnerInformation')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Container>
        <br></br>
        <LoadingOverlay active={false} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                {permission.canSearch && (
                  <>
                    <br></br>
                    <Paper className="search_table">
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                          {t('businessPartner.searchTitle')}
                        </Grid>
                      </Grid>
                      {/* Partner Name */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                          {t('common.name')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="name"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('validation.required', {field: t('common.name')})}
                            value={this.state.name}
                            onChange={(event) => onChangeTextField(this, event)}
                            inputProps={{maxLength: 256}}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>

                      {/* Partner Country Code */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                          {t('common.country')} {(this.state.effect_start_time || this.state.effect_end_time) && <span className="font_color_red">＊</span>}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'country_code',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.country_code ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('common.country'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.country_code}
                              onChange={this.changeCountry}
                              disabled={!isRoleGlobal()}
                            >
                              {common?.country_code?.map((item, idx) => (
                                <MenuItem value={item.country_code} key={idx}>
                                  {t(`${item.country_code}`)}
                                </MenuItem>
                              ))}
                            </Select>
                            {(this.state.effect_start_time || this.state.effect_end_time) && !this.state.country_code && (
                              <FormHelperText id="country_id" error>
                                {t('validation.required.choose', {field: t('common.country')})}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Partner Geofence */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                          {t('common.geofence')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'geofence_id',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.geofence_id ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('common.geofence'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.geofence_id}
                              onChange={this.changeGeofence}
                            >
                              {this.state.listGeofenceAll?.map((item, idx) => (
                                <MenuItem value={item.geofence_id} key={idx} className={!item.enable ? 'disable-option-custom' : ''}>
                                  {t(`${item.name}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Partner Phone number */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                          {t('common.phoneNumber')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            className="field_size_20 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {
                              field: t('common.phoneNumber'),
                            })}
                            name="phone_number"
                            variant="outlined"
                            onChange={(event) => onChangeTextField(this, event)}
                            value={this.state.phone_number}
                          />
                        </Grid>
                      </Grid>

                      {/* Partner Address */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                          {t('common.address')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            className="field_size_20 field_min_size_500"
                            margin="dense"
                            placeholder={t('placeholder.required', {
                              field: t('common.address'),
                            })}
                            name="address"
                            variant="outlined"
                            onChange={(event) => onChangeTextField(this, event)}
                            value={this.state.address}
                          />
                        </Grid>
                      </Grid>

                      {/* Partner QR Code */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                          {t('businessPartner.qr_code')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            className="field_size_20 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {
                              field: t('businessPartner.qr_code'),
                            })}
                            name="qr_code"
                            variant="outlined"
                            onChange={(event) => onChangeTextField(this, event)}
                            value={this.state.qr_code}
                          />
                        </Grid>
                      </Grid>

                      {/* Payment Status */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                          {t('common.paymentStatus')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'payment_status',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.payment_status ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('common.paymentStatus'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.payment_status}
                              onChange={(e) => onChangeSelect(this, e)}
                            >
                              {PARTNER_PAYMENT_STATUS.map(({id, i18n}, idx) => (
                                <MenuItem value={id} key={idx}>
                                  {t(`${i18n}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* start_time */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                          {t('business_plan.start_time')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              autoOk
                              disableToolbar
                              variant="inline"
                              inputVariant="outlined"
                              minDate="2000-01-01"
                              placeholder={t('business_plan.start_time')}
                              format="yyyy/MM/dd"
                              value={this.state.effect_start_time}
                              minDateMessage="無効な日付です"
                              maxDateMessage="無効な日付です"
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              onChange={(time) =>
                                this.setState({
                                  effect_start_time: time,
                                })
                              }
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Grid>

                      {/* end_time */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                          {t('business_plan.end_time')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              autoOk
                              disableToolbar
                              variant="inline"
                              placeholder={t('business_plan.end_time')}
                              inputVariant="outlined"
                              minDate="2000-01-01"
                              format="yyyy/MM/dd"
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              value={this.state.effect_end_time}
                              onChange={(time) => this.setState({effect_end_time: time})}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </Grid>

                      {/* Discount type */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                          {t('businessPartner.discount_type')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'discount_type',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.discount_type ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('businessPartner.discount_type'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.discount_type}
                              onChange={(e) => onChangeSelect(this, e)}
                            >
                              {DISCOUNT_TYPE.map(({id, i18n}, idx) => (
                                <MenuItem value={id} key={idx}>
                                  {t(`${i18n}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Usage Status */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                          {t('businessPartner.usage_status')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'usage_status',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.usage_status || this.state.usage_status === false ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('businessPartner.usage_status'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.usage_status}
                              onChange={(e) => onChangeSelect(this, e)}
                            >
                              {GEOFENCE_STATUS.map(({id, i18n}, idx) => (
                                <MenuItem value={id} key={idx}>
                                  {t(`${i18n}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Waypoint Restrict */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                          {t('businessPartner.waypoint_restrict')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'waypoint_restrict',
                              }}
                              displayEmpty
                              value={this.state.waypoint_restrict}
                              onChange={(e) => onChangeSelect(this, e)}
                              renderValue={
                                this.state.waypoint_restrict ? undefined : () => <div className="font-12 color-disabled">{t('businessPartner.place_holder_waypoint_restrict')}</div>
                              }
                            >
                              {COMMON_STATUS.map(({id, i18n}, idx) => (
                                <MenuItem value={id} key={idx}>
                                  {t(`${i18n}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      {/* Visible flag */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                          {t('businessPartner.displayRecord')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'visible_flag',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.visible_flag || this.state.visible_flag === false ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('businessPartner.displayRecord'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.visible_flag}
                              onChange={(e) => onChangeSelect(this, e)}
                            >
                              {WAYPOINT_STATUS.map(({id, i18n}, idx) => (
                                <MenuItem value={id} key={idx}>
                                  {t(`${i18n}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Waypoint name */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                          {t('businessPartner.waypointName')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            className="field_size_20 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {
                              field: t('businessPartner.waypointName'),
                            })}
                            name="waypoint_name"
                            variant="outlined"
                            onChange={(event) => onChangeTextField(this, event)}
                            value={this.state.waypoint_name}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </>
                )}
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.reset} endIcon={<RotateLeftIcon />}>
                        {t('common.btnResetSearch')}
                      </Button>
                    )}
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {permission.canRegister && (
                      <Link
                        style={{textDecoration: 'none'}}
                        to={{pathname: ROUTE.LAYOUT + ROUTE.PARTNER_ADD, state: {from: this.props.location.pathname + '?' + this.state.searchParams}}}
                      >
                        <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />}>
                          {t('common.btnRegister')}
                        </Button>
                      </Link>
                    )}
                    {permission.canSearch && permission.canCSVOutput && (
                      <>
                        <div className={this.state.totalRows <= EXPORT_ALERT_NUMBER_ROWS || this.state.confirmedExport ? '' : 'hidden'}>
                          <CSVExporter
                            ref={this.exportCSVRef}
                            disabled={this.state.totalRows <= 0 || !this.state.latestSearchParams}
                            headers={this.exportHeaders}
                            fetchAction={this.fetchDataExport}
                            isFetchAsync={true}
                            screenName={t('route.partnerInformation')}
                          />
                        </div>
                        {this.state.totalRows >= EXPORT_ALERT_NUMBER_ROWS && !this.state.confirmedExport && (
                          <Button
                            onClick={this.alertExportCSV()}
                            disabled={this.state.totalRows <= 0}
                            color="primary"
                            variant="contained"
                            className="button_margin"
                            endIcon={<GetAppIcon className="csv-exporter-icon" />}
                          >
                            {t('calendar.csv_output')}
                          </Button>
                        )}
                      </>
                    )}
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin" endIcon={<SearchIcon />} onClick={() => this.handleSearch(true)}>
                        {t('common.btnSearch')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>
          <Container maxWidth="xl">
            {this.state.isSearch && (
              <Card raised>
                <Container maxWidth="xl">
                  <br></br>
                  <Grid container spacing={1}>
                    <Grid container alignItems="center" justify="flex-start" item xs={3}>
                      <h3>
                        {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                      </h3>
                    </Grid>
                  </Grid>

                  <div className="scroll_area_700">
                    {this.state.result.length > 0 && (
                      <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                        <TableHead>
                          <TableRow>
                            <TableCell className="width_50p">{t('business_plan.id')}</TableCell>
                            <TableCell className="width_125p">{t('businessPartner.code')}</TableCell>
                            <TableCell className="width_150p">{t('common.name')} (JP)</TableCell>
                            <TableCell className="width_150p">{t('common.name')} (VN)</TableCell>
                            <TableCell className="width_150p">{t('common.name')} (EN)</TableCell>
                            <TableCell className="width_100p">{t('common.country')}</TableCell>
                            <TableCell className="width_150p">{t('common.geofence')}</TableCell>
                            <TableCell className="width_200p">{t('businessPartner.number_of_waypoint')}</TableCell>
                            <TableCell className="width_200p">{t('businessPartner.waypoint_restrict')}</TableCell>
                            <TableCell className="width_150p">{t('common.phoneNumber')}</TableCell>
                            <TableCell className="width_150p">{t('common.paymentStatus')}</TableCell>
                            <TableCell className="width_200p">{t('common.paymentMethod')}</TableCell>
                            <TableCell className="width_150p">{t('businessPartner.usage_status')}</TableCell>
                            <TableCell className="width_200p">{t('common.address')} (JP)</TableCell>
                            <TableCell className="width_200p">{t('common.address')} (VI)</TableCell>
                            <TableCell className="width_200p">{t('common.address')} (EN)</TableCell>
                            <TableCell className="width_100p">{t('businessPartner.discount')}</TableCell>
                            <TableCell className="width_150p">{t('business_plan.start_time')}</TableCell>
                            <TableCell className="width_150p">{t('business_plan.end_time')}</TableCell>
                            {permission.canEdit && (
                              <TableCell className="width_100p" style={{position: 'sticky', right: '0'}}>
                                {t('common.action')}
                              </TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.result?.map((row, index) => {
                            return (
                              <TableRow key={index} hover className="cursor_pointer">
                                <TableCell>{row.id || DATA_NULL}</TableCell>
                                <TableCell>{row.code || DATA_NULL}</TableCell>
                                <TableCell>
                                  {row?.partner_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.name || DATA_NULL}
                                </TableCell>
                                <TableCell>
                                  {row?.partner_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.name || DATA_NULL}
                                </TableCell>
                                <TableCell>
                                  {row?.partner_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.name || DATA_NULL}
                                </TableCell>
                                <TableCell>{t(`${row.country_code}`) || DATA_NULL}</TableCell>
                                <TableCell>{row?.geofence_name}</TableCell>
                                <TableCell>{row.number_of_waypoint}</TableCell>
                                <TableCell>{row.waypoint_restrict ? t('common.enable') : t('common.disable')}</TableCell>
                                <TableCell>{row.phone_number}</TableCell>
                                <TableCell>{t(`${PARTNER_PAYMENT_STATUS.find(({id}) => id === row.payment_status)?.i18n}`) || DATA_NULL}</TableCell>
                                <TableCell>{t(`${PAYMENT_METHODS.find(({id}) => id === row.payment_method).i18n}`) || DATA_NULL}</TableCell>
                                <TableCell>{row.disabled ? 'DISABLE' : 'ENABLE'}</TableCell>
                                <TableCell>
                                  {row?.partner_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.address || DATA_NULL}
                                </TableCell>
                                <TableCell>
                                  {row?.partner_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.address || DATA_NULL}
                                </TableCell>
                                <TableCell>
                                  {row?.partner_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.address || DATA_NULL}
                                </TableCell>
                                <TableCell>{row.discount ? customDisplayCurrency(row.discount, row.country_code) : row.discount === 0 ? '0.00' : DATA_NULL}</TableCell>
                                <TableCell>{convertDatetimeUTC(row.effect_start_time, row.country_code).substr(0, 10) || DATA_NULL}</TableCell>
                                <TableCell>{convertDatetimeUTC(row.effect_end_time, row.country_code).substr(0, 10) || DATA_NULL}</TableCell>
                                {permission.canEdit && (
                                  <TableCell className="cell_fixed_right">
                                    <Link
                                      style={{textDecoration: 'none'}}
                                      to={{
                                        pathname: ROUTE.LAYOUT + ROUTE.PARTNER_DETAILS + '/' + row.id,
                                        state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                      }}
                                    >
                                      <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                        {t('common.btnEdit')}
                                      </Button>
                                    </Link>
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    )}
                  </div>
                  <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                </Container>
                <br></br>
                {/* Export alert modal */}
                <Dialog
                  open={this.state.flagExport}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    onClickOk={this.handleButtonExportOk}
                    onClickCancel={this.handleButtonExportCancel}
                    okButtonText={t('common.btnYes')}
                    cancelButtonText={t('common.btnNo')}
                    message={this.state.message}
                  ></SelectModal>
                </Dialog>
              </Card>
            )}
            <br></br>
          </Container>
        </LoadingOverlay>
        <br></br>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchPartnerList: (queryParams) => dispatch(searchPartnerList(queryParams)),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    getListGeofenceByCountry: (id) => dispatch(getListGeofenceByCountry(id)),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getAllGeofence: () => dispatch(getAllGeofence()),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(SearchShopSubscription)));
