import React, {Component} from 'react';

import {
  Card,
  Container,
  Grid,
  Paper,
  Table,
  TableCell,
  TableRow,
  TextField,
  Button,
  TableHead,
  TableBody,
  Dialog,
  FormControl,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import DetailIcon from '@material-ui/icons/Description';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {ROUTE, ROWS_PER_PAGE_OPTIONS, GEOFENCE_STATUS, PERMISSION_ACTIONS} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {searchAreaGeofenceApi, deleteAreaGeofenceApi} from '../../../services/areaGeofenceServices';
import {getAllCountryCode, getAllGeofence} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {onChangeTextField, getUrlParams, onChangeSelect, changeUrlParams, backForwardRouter} from '../../../utils/common';
import {modalObj} from '../../../utils/modal';
import {isRoleGlobal} from '../../../utils/role';

/**
 * Area Geofence Management
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      countryId: !isRoleGlobal() ? props.principal.country_id: '',
      name: null,
      enable: '',
      listGeofenceAll: [],
      geofenceId: null,

      index: null,
      isSearch: false,
      result: [],
      deleteId: null,
      flgDelete: false,
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,
      isLoading: false,
    };

    this.handleButtonOk = this.handleButtonOk.bind(this);
    this.handleButtonCancel = this.handleButtonCancel.bind(this);
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllCountryCode();
    await this.props.getAllGeofence().then((response) =>
      response && this.setState({
        listGeofenceAll: !isRoleGlobal ? response.filter((item) => item.country_id === this.state.countryId) : response,
      }),
    );
    if (this.props.location.search) {
      const urlParams = getUrlParams(this.props.location.search);
      if (isRoleGlobal() ? true : Number(urlParams?.countryId) === Number(this.props.principal.country_id)) {
        this.setState(urlParams, this.handleSearch);
      } else {
        if (urlParams.countryId && Number(urlParams?.countryId) !== Number(this.props.principal.country_id)) {
          backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.HOME);
        }
      }
    }
  }
  /**
   * Reset Search
   */
  resetSearchCondition = () => {
    this.setState({
      countryId: !isRoleGlobal() ? this.props.principal.country_id: '',
      name: '',
      enable: '',
      geofenceId: null,
    });
  };

  /**
   * handleDelete
   * @param {Number} deleteId
   * @param {Number} index
   */
  handleDelete(deleteId, index) {
    this.setState({
      deleteId,
      index,
      flag: true,
      message: 'area_geofence.question.confirm.delete',
    });
  }

  /**
   * handleButtonOk
   */
  handleButtonOk() {
    this.setState({
      flag: false,
      isLoading: true,
    });
    deleteAreaGeofenceApi(this.state.deleteId)
      .then((res) => {
        _.remove(this.state.result, (item) => {
          return item.id === this.state.deleteId;
        });
        this.props.setMessageModal(modalObj(true, 'api.delete.area_geofence.success'));
      })
      .then(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  /**
   * handleButtonCancel
   */
  handleButtonCancel() {
    this.setState({
      flag: false,
    });
  }

  /**
   * handleSearch
   * @param {bool} reset
   */
  handleSearch(reset) {
    if (!this.props.actions.includes(PERMISSION_ACTIONS.SEARCH)) return;
    const {countryId, name, enable, geofenceId} = this.state;
    const payload = {
      countryId,
      name,
      geofenceId,
      targetScreen: 'ENABLE_FOR_ADMIN_ALL',
    };
    if (enable !== '') {
      payload.enable = enable === true;
    }
    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const {currentPage, rowsPerPage} = this.state;
    const queryParams = reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage};
    this.setState({
      isLoading: true,
    });
    searchAreaGeofenceApi({...payload, ...queryParams})
      .then((response) => {
        this.setState(
          {
            result: response?.result?.content,
            isSearch: true,
            totalRows: response?.result?.totalSize,
          },
          () => {
            const queryParamsToChange = {
              ...payload,
              currentPage: String(currentPage),
              rowsPerPage,
            };
            changeUrlParams(queryParamsToChange);
          },
        );
      })
      .then(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearch);
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t, common, actions} = this.props;
    const {result, isLoading} = this.state;
    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canRegister: actions.includes(PERMISSION_ACTIONS.REGISTER),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
      canDelete: actions.includes(PERMISSION_ACTIONS.DELETE),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container spacing={1} className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.areaGeofenceManagement')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          <br></br>
          <LoadingOverlay active={isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                <>
                  <br></br>
                  <Paper className="search_table">
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                        {t('shuttleSetting.searchTitle')}
                      </Grid>
                    </Grid>
                    {/* Country */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('common.country')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_250">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'countryId',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.countryId ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('common.country'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.countryId}
                            onChange={(e) => {
                              onChangeSelect(this, e);
                              this.setState({geofenceId: null});
                            }}
                            disabled={!isRoleGlobal()}
                          >
                            {common?.country_code?.map((item, idx) => (
                              <MenuItem value={item.id} key={idx}>
                                {t(`${item.country_code}`)}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* Area */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('area_geofence.area')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl variant="outlined" className="field_size_20 field_min_size_250">
                          <TextField
                            name="name"
                            className="field_size_20 field_min_size_250"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('area_geofence.area')})}
                            inputProps={{maxLength: 256}}
                            value={this.state.name}
                            onChange={(event) => onChangeTextField(this, event)}
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* Geofence */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('area_geofence.geofence')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormGroup row>
                          {this.state.listGeofenceAll
                            .filter((item) => !this.state.countryId || item.country_id + '' === this.state.countryId + '')
                            .map((item, index) => {
                              return (
                                <FormControlLabel
                                  key={index}
                                  labelPlacement="end"
                                  classes={{label: item.enable ? '' : 'opacity-disable'}}
                                  control={
                                    <Checkbox
                                      key={index}
                                      checked={this.state.geofenceId === item.geofence_id}
                                      onChange={(event) =>
                                        this.setState({
                                          geofenceId: event.target.checked ? item.geofence_id : null,
                                        })
                                      }
                                      className="checkbox_radio"
                                    />
                                  }
                                  label={item.name}
                                />
                              );
                            })}
                        </FormGroup>
                      </Grid>
                    </Grid>

                    {/* Status */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('common.status')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_250">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'enable',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.enable || this.state.enable === false ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('common.status'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.enable}
                            onChange={(e) => onChangeSelect(this, e)}
                          >
                            {GEOFENCE_STATUS?.map((item, idx) => {
                              return (
                                <MenuItem value={item.id} key={idx}>
                                  {t(`${item.i18n}`)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Paper>
                </>
                <br></br>
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.resetSearchCondition} endIcon={<RotateLeftIcon />}>
                        {t('common.btnResetSearch')}
                      </Button>
                    )}
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {permission.canRegister && (
                      <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.AREA_GEOFENCES_CREATE}}>
                        <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />}>
                          {t('common.btnRegister')}
                        </Button>
                      </Link>
                    )}
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin" onClick={() => this.handleSearch(true)} endIcon={<SearchIcon />}>
                        {t('common.btnSearch')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <br></br>
                <br></br>
                <Dialog
                  open={this.state.flag}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    onClickOk={this.handleButtonOk}
                    onClickCancel={this.handleButtonCancel}
                    message={this.state.message}
                    okButtonText={t('common.btnYes')}
                    cancelButtonText={t('common.btnCancel')}
                  ></SelectModal>
                </Dialog>
              </Container>
            </Card>
          </LoadingOverlay>
          <br></br>
          {this.state.isSearch ? (
            <Card raised>
              <Container maxWidth="xl">
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={6}>
                    <h3>
                      {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                    </h3>
                  </Grid>
                </Grid>
                <LoadingOverlay active={isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                  {result?.length > 0 && (
                    <div className="scroll_area_700">
                      <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                        <TableHead>
                          <TableRow>
                            <TableCell className="width_150p ant-table-cell-fix-left">{t('common.id')}</TableCell>
                            <TableCell className="width_150p">{t('common.country')}</TableCell>
                            <TableCell className="width_150p">{t('area_geofence.area')}</TableCell>
                            <TableCell className="width_400p">{t('area_geofence.geofence')}</TableCell>
                            <TableCell className="width_150p">{t('common.status')}</TableCell>
                            {(permission.canEdit || permission.canDelete) && (
                              <TableCell className="width_250p" style={{position: 'sticky', right: '0'}}>
                                {t('common.action')}
                              </TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {result?.map((row, index) => {
                            return (
                              <TableRow key={index} hover className="cursor_pointer">
                                <TableCell scope="row" className="ant-table-cell-fix-left">
                                  {row.id}
                                </TableCell>
                                <TableCell>{t(common.country_code?.find((item) => item.id === row.country_id)?.country_code)}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row?.geofence_config_infos?.map((item) => item?.name).join(', ')}</TableCell>
                                <TableCell>{row.enable ? t('common.enable') : t('common.disable')}</TableCell>
                                {(permission.canEdit || permission.canDelete) && (
                                  <TableCell>
                                    {permission.canEdit && (
                                      <Link
                                        style={{textDecoration: 'none'}}
                                        to={{
                                          pathname: ROUTE.LAYOUT + ROUTE.AREA_GEOFENCES_DETAIL + '/' + row.id,
                                          state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                        }}
                                      >
                                        <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                          {t('common.btnEdit')}
                                        </Button>
                                      </Link>
                                    )}
                                    &nbsp;
                                    {permission.canDelete && (
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        className="button_margin button_color_red"
                                        endIcon={<DeleteIcon />}
                                        onClick={() => this.handleDelete(row.id, index)}
                                      >
                                        {t('common.btnDelete')}
                                      </Button>
                                    )}
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                      <br></br>
                    </div>
                  )}
                  <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                </LoadingOverlay>
              </Container>
            </Card>
          ) : (
            ''
          )}

          <br></br>
          {/* Export alert modal */}
          <Dialog
            open={this.state.flagExport}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal
              onClickOk={this.handleButtonExportOk}
              onClickCancel={this.handleButtonExportCancel}
              okButtonText={t('common.btnYes')}
              cancelButtonText={t('common.btnNo')}
              message={this.state.message}
            ></SelectModal>
          </Dialog>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getAllGeofence: () => dispatch(getAllGeofence()),
    setMessageModal: (params) => dispatch(setMessageModal(params)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
