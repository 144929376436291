export const ACTION_TYPES = {
  SEARCH_ONE_TIME_PRICE_START: 'SEARCH_ONE_TIME_PRICE_START',
  SEARCH_ONE_TIME_PRICE_SUCCESS: 'SEARCH_ONE_TIME_PRICE_SUCCESS',
  SEARCH_ONE_TIME_PRICE_FAIL: 'SEARCH_ONE_TIME_PRICE_FAIL',

  CREATE_ONE_TIME_PRICE_START: 'CREATE_ONE_TIME_PRICE_START',
  CREATE_ONE_TIME_PRICE_SUCCESS: 'CREATE_ONE_TIME_PRICE_SUCCESS',
  CREATE_ONE_TIME_PRICE_FAIL: 'CREATE_ONE_TIME_PRICE_FAIL',

  UPDATE_ONE_TIME_PRICE_START: 'UPDATE_ONE_TIME_PRICE_START',
  UPDATE_ONE_TIME_PRICE_SUCCESS: 'UPDATE_ONE_TIME_PRICE_SUCCESS',
  UPDATE_ONE_TIME_PRICE_FAIL: 'UPDATE_ONE_TIME_PRICE_FAIL',

  GET_ONE_TIME_PRICE_DETAILS_START: 'GET_ONE_TIME_PRICE_DETAILS_START',
  GET_ONE_TIME_PRICE_DETAILS_SUCCESS: 'GET_ONE_TIME_PRICE_DETAILS_SUCCESS',
  GET_ONE_TIME_PRICE_DETAILS_FAIL: 'GET_ONE_TIME_PRICE_DETAILS_FAIL',

  DELETE_ONE_TIME_PRICE_START: 'DELETE_ONE_TIME_PRICE_START',
  DELETE_ONE_TIME_PRICE_SUCCESS: 'DELETE_ONE_TIME_PRICE_SUCCESS',
  DELETE_ONE_TIME_PRICE_FAIL: 'DELETE_ONE_TIME_PRICE_FAIL',
};

const initialState = {
  isLoading: false,
  accountTypeList: [],
  majorList: [],
  accountTypeDetail: {},
};

/**
 * function reduce
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.SEARCH_ONE_TIME_PRICE_START:
    case ACTION_TYPES.CREATE_ONE_TIME_PRICE_START:
    case ACTION_TYPES.UPDATE_ONE_TIME_PRICE_START:
    case ACTION_TYPES.GET_FUNCTION_LIST_START:
    case ACTION_TYPES.GET_ONE_TIME_PRICE_DETAILS_START:
    case ACTION_TYPES.DELETE_ONE_TIME_PRICE_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.SEARCH_ONE_TIME_PRICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        accountTypeList: action.accountTypeList,
      };

    case ACTION_TYPES.GET_FUNCTION_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        majorList: action.payload,
      };
    case ACTION_TYPES.UPDATE_ONE_TIME_PRICE_SUCCESS:
    case ACTION_TYPES.CREATE_ONE_TIME_PRICE_SUCCESS:
    case ACTION_TYPES.SEARCH_ONE_TIME_PRICE_FAIL:
    case ACTION_TYPES.CREATE_ONE_TIME_PRICE_FAIL:
    case ACTION_TYPES.UPDATE_ONE_TIME_PRICE_FAIL:
    case ACTION_TYPES.DELETE_ONE_TIME_PRICE_SUCCESS:
    case ACTION_TYPES.DELETE_ONE_TIME_PRICE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.GET_ONE_TIME_PRICE_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        accountTypeDetail: action.payload,
      };
    case ACTION_TYPES.GET_ONE_TIME_PRICE_DETAILS_FAIL:
      return {
        ...state,
        isLoading: false,
        accountTypeDetail: {},
      };
    default:
      return state;
  }
}
