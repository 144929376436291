import React, {Component} from 'react';

import {Card, Container, Grid, Button, Table, TableCell, TableRow, TableBody, CardHeader, Typography, Box, TextField, FormHelperText, FormControl} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {ROUTE, GROUP_TYPES} from '../../../../common/constant';
import {detailVehicleConfig, updateVehicleConfig} from '../../../../stores/vehicles/action';
import {onChangeTextField, backForwardRouter} from '../../../../utils/common';
import {displayDateTime, convertDatetimeUTC} from '../../../../utils/datetime';
import withPermissionData from '../../../../withPermissionData/hoc';

/**
 * Account Management view
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      batch_runtime_minutes: null,

      isSubmitForm: false,
    };

    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    if (this.props.match.path === ROUTE.LAYOUT + ROUTE.VEHICLE_CONFIG_DETAILS + '/:id') {
      await this.props.detailVehicleConfig(this.props.match.params.id).then((response) => {
        if (response) {
          this.setState({
            data: response,
            batch_runtime_minutes: response?.batch_runtime_minutes,
          });
        }
      }, () => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.VEHICLE_CONFIG));
    }
  }

  /**
   * handleUpdate
   */
  handleUpdate = () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid()) {
      const payload = {
        vehicle_config_id: Number(this.props.match.params.id),
        batch_runtime_minutes: Number(this.state.batch_runtime_minutes),
      };
      this.props.updateVehicleConfig(payload, this.props);
    }
  };

  /**
   * Render component
   * @return {object}
   */
  render() {
    const {t, vehicles} = this.props;
    const {data, batch_runtime_minutes} = this.state;

    return (
      <LoadingOverlay active={vehicles.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('route.driverVehicleConfigDetails')}</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}></Grid>
            </Grid>
          </Container>

          <Container maxWidth="xl">
            <Box m={2} p={2}>
              <Card raised>
                <Container maxWidth="xl">
                  <CardHeader
                    title={
                      <Typography gutterBottom variant="h5" component="h5">
                        {t('route.driverVehicleConfigDetails')}
                      </Typography>
                    }
                  />
                  <Box pt={3} pb={3}>
                    <Table size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell className="width_30">{t('group_type')}</TableCell>
                          <TableCell>{GROUP_TYPES.map(({id, i18n}) => (`${data.group_type}` === id ? t(`${i18n}`) : ''))}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('batch_runtime_minutes')}</TableCell>
                          <TableCell>
                            <FormControl variant="outlined" margin="dense" fullWidth>
                              <TextField
                                type="number"
                                name="batch_runtime_minutes"
                                className="field_size_10 field_min_size_300"
                                margin="dense"
                                placeholder={t('batch_run_time')}
                                value={batch_runtime_minutes}
                                onChange={(event) => onChangeTextField(this, event)}
                                variant="outlined"
                              />
                              {this.validator.message('batch_runtime_minutes', this.state.batch_runtime_minutes, 'required')}
                              {/* check isValid to show messages */}
                              {this.state.isSubmitForm && !this.validator.check(this.state.batch_runtime_minutes, 'required') && (
                                <FormHelperText id="imageName" error>
                                  {t('validation.required', {field: t('batch_runtime_minutes')})}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('start_time')}</TableCell>
                          <TableCell>{displayDateTime(convertDatetimeUTC(data.start_time))}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('end_time')}</TableCell>
                          <TableCell>{displayDateTime(convertDatetimeUTC(data.end_time))}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('driver_id')}</TableCell>
                          <TableCell>{data.driver_id}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('external_id')}</TableCell>
                          <TableCell>{data.external_id}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className="width_30">{t('simulation_id')}</TableCell>
                          <TableCell>{data.simulation_id}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                </Container>
              </Card>
            </Box>
          </Container>
          <Container maxWidth="xl">
            <Box p={2}>
              <Grid container spacing={2}>
                <Grid container alignItems="center" justify="flex-end" item xs={12}>
                  <Box p={2}>
                    <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={() => this.handleUpdate()}>
                      {t('common.btnUpdate')}
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      className="button_margin button_color"
                      onClick={() => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.VEHICLE_CONFIG)}
                    >
                      {t('common.btnReturn')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    vehicles: state.vehicles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    detailVehicleConfig: (params) => dispatch(detailVehicleConfig(params)),
    updateVehicleConfig: (payload, props) => dispatch(updateVehicleConfig(payload, props)),
  };
};

export default withPermissionData(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
