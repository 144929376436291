import React, {Component} from 'react';

import {Box, Button, Card, CardContent, Container, Dialog, Grid, Table, TableBody, TableCell, TableRow} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {ROUTE, PERMISSION_ACTIONS} from '../../../common/constant';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {deleteQrPayment, detailQrPayment} from '../../../stores/qr_payment/action';
import {convertDatetimeUTC, displayDateTime} from '../../../utils/datetime';

/**
 * Detail error QrPayment view
 */
class QrPaymentDetail extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      detailQr: {},
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    const detailQr = await this.props.detailQrPayment(this.props.match.params.id);
    if (detailQr.status === 200) {
      this.setState({
        detailQr: detailQr.result,
      });
    } else {
      this.props.history.replace('/maas/error-qr-payments');
    }
  };

  /**
   * handle delete click
   */
  handleDeleteClick = () => {
    this.setState((state) => ({
      ...state,
      showDialog: true,
    }));
  };

  /**
   * handle delete qr request
   */
  handleDelete = async () => {
    await this.props.deleteQrPayment([+this.props.match.params.id]);
    this.closeDialog();
    this.props.history.replace('/maas/error-qr-payments');
  };

  /**
   * close dialog
   */
  closeDialog = () => {
    this.setState((state) => ({
      ...state,
      showDialog: false,
    }));
  };

  /**
   * render component
   * @return {HTML}
   */
  render() {
    const {t, qr_payment, actions} = this.props;
    const {detailQr} = this.state;
    const permission = {
      canDelete: actions.includes(PERMISSION_ACTIONS.DELETE),
    };

    return (
      <>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('qrPayment.details_error_qr_payment')}</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}>
                <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.QR_PAYMENT}}>
                  <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                    {t('common.btnReturn')}
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Container>
          <br></br>
          <br></br>
          <LoadingOverlay active={qr_payment.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Container maxWidth="xl">
              <Card>
                <CardContent>
                  <Box pt={3} pb={3}>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell className="width_30">{t('qrPayment.qr_pair_key')}</TableCell>
                          <TableCell>{detailQr.get_onoff_pair_key}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('qrPayment.use_email')}</TableCell>
                          <TableCell>{detailQr.user_email}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('qrPayment.user_name')}</TableCell>
                          <TableCell>{detailQr.user_full_name || '--'}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('qrPayment.user_uuid')}</TableCell>
                          <TableCell>{detailQr.user_uuid || '--'}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('qrPayment.use_date')}</TableCell>
                          <TableCell>{displayDateTime(convertDatetimeUTC(detailQr.get_onoff_use_date))}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('qrPayment.on_of_lag')}</TableCell>
                          <TableCell>{detailQr.get_onoff_flag || '--'}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('qrPayment.payg_id')}</TableCell>
                          <TableCell>{detailQr.qr_payg_id}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('qrPayment.stop_name')}</TableCell>
                          <TableCell>{detailQr.stop_name}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                  <br></br>
                  {permission.canDelete && (
                    <Button endIcon={<DeleteIcon />} className="button_margin button_color_red" variant="contained" color="primary" onClick={this.handleDeleteClick}>
                      {t('common.btnDelete')}
                    </Button>
                  )}
                </CardContent>
              </Card>
            </Container>
          </LoadingOverlay>
          <br></br>

          <Dialog
            open={this.state.showDialog}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal onClickOk={this.handleDelete} onClickCancel={this.closeDialog} message={t('qr_payment.confirm_delete_item')}></SelectModal>
          </Dialog>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  qr_payment: state.qr_payment,
});

const mapDispatchToProps = (dispatch) => ({
  deleteQrPayment: (data) => dispatch(deleteQrPayment(data)),
  detailQrPayment: (id) => dispatch(detailQrPayment(id)),
});

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(QrPaymentDetail)));
