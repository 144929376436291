import React, {Component} from 'react';

import {Button, Card, Container, FormControl, FormGroup, Grid, Paper, Select, MenuItem, FormControlLabel, Checkbox} from '@material-ui/core';
import {Table} from '@material-ui/core';
import {TableHead} from '@material-ui/core';
import {TableRow} from '@material-ui/core';
import {TableCell} from '@material-ui/core';
import {TableBody} from '@material-ui/core';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';

import {OWNER_TYPE, QR_CODE_CATEGORY} from '../../../common/constant';
import {getAllCountryCode, getAllGeofence} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import withPermissionData from '../../../withPermissionData/hoc';

/**
 * History QR Code
 */
class HistoryForm extends Component {
  /**
   * constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      name: '',
      country_id: '',
      geofence_id: '',
      owner: '',
      partner: '',
      listGeofenceAll: [],
      status: '',
      start_date: null,
      end_date: null,
      waypoints: [],
      listOwner: [],
      listWaypoint: [],
      list_member: [],
      category: '',
      description: '',
      operation_time: [
        {
          from: '',
          to: '',
        },
      ],
      all_member: false,
      members: [],
      quantity: '',
      usage_limit: '',
      discount_type: '',
      discount_amount: '',
      total_quantity: 600,
      total_usage_count: 450,
      total_remain_count: 150,
      dataTable: [],
    };
  }

  /**
   * handleUpdate
   */
  handleUpdate = async () => {};

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllCountryCode();
    await this.props.getAllGeofence();
  }

  /**
   * Render component
   * @return {*}
   */
  render() {
    const {t} = this.props;

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container spacing={1} className="page_header">
            <Grid container alignItems="center" item xs={6}>
              {<h3>{t('qr_code.historyTitle')}</h3>}
            </Grid>
          </Grid>
        </Container>
        <br></br>
        <Container maxWidth="xl">
          <Card raised>
            <Container maxWidth="xl">
              <br></br>
              <Paper className="search_table">
                {/* Country */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('common.id')}: {this.state.id}
                  </Grid>
                </Grid>

                {/* Country */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('common.country')}
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl error>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'country',
                          }}
                          disabled
                          displayEmpty
                          value={this.state.country_id || ''}
                          renderValue={
                            this.state.country_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.country'),
                                    })}
                                  </div>
                                )
                          }
                        >
                          {this.props.common?.country_code?.map((item, idx) => (
                            <MenuItem value={item.id} key={idx}>
                              {t(`${item.country_code}`)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </FormControl>
                  </Grid>
                </Grid>

                {/* geofence */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('common.geofence')}
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                      <Select
                        margin="dense"
                        inputProps={{
                          name: 'geofence_id',
                        }}
                        displayEmpty
                        disabled
                        renderValue={
                          this.state.geofence_id ?
                            undefined :
                            () => (
                                <div className="font-12 color-disabled">
                                  {t('placeholder.required_select', {
                                    field: t('common.geofence'),
                                  })}
                                </div>
                              )
                        }
                        value={this.state.geofence_id || ''}
                      >
                        {this.state.listGeofenceAll.map((item, idx) => {
                          return (
                            <MenuItem value={item.geofence_id} key={idx}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                {/* Owner */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('qr_code.owner')}
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                      <Select
                        margin="dense"
                        inputProps={{
                          name: 'owner',
                        }}
                        disabled
                        displayEmpty
                        renderValue={
                          this.state.owner ?
                            undefined :
                            () => (
                                <div className="font-12 color-disabled">
                                  {t('placeholder.required_select', {
                                    field: t('qr_code.owner'),
                                  })}
                                </div>
                              )
                        }
                        value={this.state.owner || ''}
                      >
                        {OWNER_TYPE.map((item, idx) => {
                          return (
                            <MenuItem value={item.id} key={idx}>
                              {t(item.i18n)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                {/* partner/company name */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('qr_code.partner')}
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                      <Select
                        disabled
                        margin="dense"
                        inputProps={{
                          name: 'partner',
                        }}
                        displayEmpty
                        renderValue={
                          this.state.partner ?
                            undefined :
                            () => (
                                <div className="font-12 color-disabled">
                                  {t('placeholder.required_select', {
                                    field: t('qr_code.partner'),
                                  })}
                                </div>
                              )
                        }
                        value={this.state.partner || ''}
                      >
                        {this.state.listOwner.map((item, idx) => {
                          return (
                            <MenuItem value={item.id} key={idx}>
                              {item.value}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                {/* waypoint */}
                {this.state.owner !== 'Company' && this.state.owner !== '' && (
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.waypoint')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined">
                        <FormGroup row>
                          {this.state.listWaypoint.map((item, index) => {
                            const checked = this.state.waypoints.indexOf(item) > -1;
                            return (
                              <FormControlLabel
                                disabled
                                key={index}
                                labelPlacement="end"
                                control={<Checkbox key={index} checked={checked} className="checkbox_radio" />}
                                label={item.name}
                              />
                            );
                          })}
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}

                {/* Category */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('qr_code.category')}
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                      <Select
                        margin="dense"
                        inputProps={{
                          name: 'category',
                        }}
                        disabled
                        displayEmpty
                        renderValue={
                          this.state.category ?
                            undefined :
                            () => (
                                <div className="font-12 color-disabled">
                                  {t('placeholder.required_select', {
                                    field: t('qr_code.category'),
                                  })}
                                </div>
                              )
                        }
                        value={this.state.category || ''}
                      >
                        {QR_CODE_CATEGORY.map((item, idx) => {
                          return (
                            <MenuItem value={item} key={idx}>
                              {item}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                {/* Total */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={12} className="grid_title_padding font-weight-600 font-20" justify="space-around" style={{margin: '30px'}}>
                    <span>
                      <span>{t('qr_code.total_quantity')}</span>
                      <span className="font_color_red margin-left-24">{this.state.total_quantity}</span>
                    </span>
                    <span>
                      <span>{t('qr_code.total_usage_count')}</span>
                      <span className="font_color_red margin-left-24">{this.state.total_usage_count}</span>
                    </span>
                    <span>
                      <span>{t('qr_code.total_remain_count')}</span>
                      <span className="font_color_red margin-left-24">{this.state.total_remain_count}</span>
                    </span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={12} className="grid_title_padding">
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">{t('common.no')}</TableCell>
                          <TableCell align="center">{t('common.name')}</TableCell>
                          <TableCell align="center">{t('common.email')}</TableCell>
                          <TableCell align="center">{t('qr_code.place')}</TableCell>
                          <TableCell align="center">{t('qr_code.usage_count')}</TableCell>
                          <TableCell align="center">{t('qr_code.remain_count')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.dataTable.map((row, idx) => (
                          <TableRow key={idx}>
                            <TableCell align="center">{t('common.no')}</TableCell>
                            <TableCell align="center">{t('common.name')}</TableCell>
                            <TableCell align="center">{t('common.email')}</TableCell>
                            <TableCell align="center">{t('qr_code.place')}</TableCell>
                            <TableCell align="center">{t('qr_code.usage_count')}</TableCell>
                            <TableCell align="center">{t('qr_code.remain_count')}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </Paper>
              <Grid container spacing={1}>
                <Grid container alignItems="center" justify="center" item xs={12} style={{margin: '10px'}}>
                  <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.props.history.goBack}>
                    {t('common.btnReturn')}
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Card>
          <br></br>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getAllGeofence: () => dispatch(getAllGeofence()),
  };
};

export default withPermissionData(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(HistoryForm)));
