import React, {Component} from 'react';

import {Button, Container, Grid, Paper, Table, TableBody, TableCell, Box, TableHead, TableRow} from '@material-ui/core';
import LoadingOverlay from 'react-loading-overlay';

/**
 * Select Users
 */
export default class extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      no_result: false,
      errorUserList: [],
      successUserList: [],
    };
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    this.setState({
      successUserList: this.props.props.successUserList,
    });
    let errorUserList = this.props.props.errorUserList;
    errorUserList = errorUserList.reduce((unique, o) => {
      if (!unique.some((obj) => obj.member_code === o.member_code && !o.is_code_error)) {
        unique.push(o);
      }
      return unique;
    }, []);
    this.setState({
      errorUserList: errorUserList,
    });
  }

  /**
   * Render
   * @return {*}
   */
  render() {
    const {t} = this.props.props;
    return (
      <Paper className="modal_size_mid">
        <Container maxWidth="lg">
          <Box p={1} m={1}>
            <Container maxWidth="xl">
              <Box>
                <Grid container alignItems="flex-start">
                  <Grid item xs={12}>
                    <h1 className="product_entry_table_header_color font_color_white font_size_mid title">
                      {this.state.errorUserList?.length > 0 && t('company.errorMemberList')}
                      {this.state.successUserList?.length > 0 && t('company.successMemberList')} &nbsp;
                    </h1>
                    <Grid container alignItems="flex-start">
                      <Grid item xs={12}>
                        {(this.state.errorUserList?.length > 0 || this.state.successUserList?.length > 0) && (
                          <LoadingOverlay
                            active={(this.state.errorUserList?.length === 0 || this.state.successUserList?.length === 0) && this.state.no_result}
                            bgColor="#f1f1f1"
                            spinnerColor="#9ee5f8"
                            textColor="#676767"
                            spinner
                          >
                            {this.state.successUserList?.length > 0 && (
                              <Grid container alignItems="center" item xs={12}>
                                <h3>
                                  {t('company.message.total')}:&nbsp;
                                  {this.state.successUserList?.length} {t('common.case')}
                                </h3>
                                <h3
                                  style={{
                                    margin: '10px',
                                  }}
                                >
                                  {t('company.message.add')}:&nbsp;
                                  {this.state.successUserList.filter((item) => item.operation_type === 'ADD').length} {t('common.case')}
                                </h3>
                                <h3>
                                  {t('company.message.delete')}:&nbsp;
                                  {this.state.successUserList.filter((item) => item.operation_type === 'DELETE').length} {t('common.case')}
                                </h3>
                              </Grid>
                            )}
                            <Grid container alignItems="flex-start">
                              <div className="scroll_area_400">
                                <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className="width_150p">{t('memberManagement.memberId')}</TableCell>
                                      <TableCell>
                                        {this.state.successUserList?.length > 0 && t('company.type')} {this.state.errorUserList?.length > 0 && t('company.note')}
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {this.state.errorUserList.map((item, index) => {
                                      return (
                                        <TableRow className="cursor_pointer" key={index}>
                                          <TableCell>{item.member_code}</TableCell>
                                          <TableCell>
                                            {item.is_code_error && t('company.message.errorImport')} {item.amount > 1 && t('company.message.duplicate', {field: item.amount})}
                                            {item.is_added && t('company.message.errorAdded')}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                    {this.state.successUserList.map((item, index) => {
                                      return (
                                        <TableRow className="cursor_pointer" key={index}>
                                          <TableCell>{item.member_code}</TableCell>
                                          <TableCell>{item.operation_type}</TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </div>
                            </Grid>
                          </LoadingOverlay>
                        )}
                        {this.state.no_result && <h5 className="text_grey">{t('reservationManagement.no_result')}</h5>}
                      </Grid>
                    </Grid>
                    <Grid container alignItems="flex-start">
                      <Grid container justify="center" alignItems="center" item xs={12} style={{marginTop: '5px'}}>
                        {this.state.successUserList.length > 0 && (
                          <Button
                            variant="contained"
                            className="button_margin"
                            color="primary"
                            onClick={() => {
                              this.props.onClickClose();
                              this.props.onClickOk(this.state.successUserList);
                            }}
                          >
                            {t('common.btnOk')}
                          </Button>
                        )}
                        <Button variant="contained" className="button_color button_magin"color="primary" onClick={() => this.props.onClickClose()}>
                          {t('common.btnCancel')}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Container>
      </Paper>
    );
  }
}
