import {api} from './api';

const autoBusBookingUrl = '/admin/auto-bus/bookings';
const getRouteAutoBusUrl = '/admin/auto-bus/route';
const getWayPointAutoBusByRouteUrl = '/admin/auto-bus/waypoints';

export const getListAutoBusBookingApi = (payload, queryParams) => {
  return api.search(autoBusBookingUrl, queryParams, payload);
};

export const createAutoBusBookingApi = (payload) => {
  return api.put(autoBusBookingUrl, payload);
};

export const getRouteAutoBusByGeofenceApi = (id) => {
  return api.get(getRouteAutoBusUrl + `/${id}`);
};

export const getWayPointAutoBusByRouteApi = (id) => {
  return api.get(getWayPointAutoBusByRouteUrl + `/${id}`);
};
