import React, {Component} from 'react';

import {Button, Card, Container, FormControl, FormHelperText, Grid, Paper, TextField, Dialog, Select, MenuItem} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import Map from '../../../components/map/MapPoint';
import SelectModal from '../../../components/selectModal';
import {getListVehicleMode} from '../../../stores/business_vehicles/action';
import {getServiceGroup, updateServiceGroup} from '../../../stores/service_group/actions';
import {onChangeTextField, onChangeSelect} from '../../../utils/common';
import withPermissionData from '../../../withPermissionData/hoc';

/**
 * Service Group Details
 */
class ServiceGroupForm extends Component {
  /**
   * constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      enable: true,
      dataset_id: '',
      routing_profile_id: '',
      walking_profile_id: '',
      transit_stop_set_id: '',
      route_network: '',
      name: '',
      display_name: '',
      description: '',
      isSubmitForm: false,
      showModal: false,
      modalMessage: '',
      central_point: [],
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * handleUpdate
   */
  handleUpdate = async () => {
    this.closeModal();
    const {id, enable, dataset_id, routing_profile_id, walking_profile_id, transit_stop_set_id, route_network, name, display_name, description} = this.state;
    const payload = {
      id: parseInt(id),
      enable: Boolean(enable),
      dataset_id: parseInt(dataset_id),
      routing_profile_id: parseInt(routing_profile_id),
      walking_profile_id: parseInt(walking_profile_id),
      transit_stop_set_id: parseInt(transit_stop_set_id),
      route_network: route_network,
      road_network: route_network,
      name: name.trim(),
      display_name: display_name.trim(),
      description: description.trim(),
    };
    this.props.updateServiceGroup(payload, this.props);
  };

  /**
   * openModal
   */
  openModal = () => {
    this.setState({isSubmitForm: true});
    if (!this.validator.allValid()) return;
    this.setState({showModal: true});
  };

  /**
   * closeModal
   */
  closeModal = () => {
    this.setState({isSubmitForm: false});
    this.setState({showModal: false});
  };

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getListVehicleMode();
    if (this.props.match.params.id) {
      const serviceGroupId = this.props.match.params.id;
      this.props.getServiceGroup(serviceGroupId).then((response) => {
        this.setState({
          id: serviceGroupId,
          enable: response.enable,
          dataset_id: response.dataset_id,
          routing_profile_id: response.routing_profile_id,
          walking_profile_id: response.walking_profile_id,
          transit_stop_set_id: response.transit_stop_set_id,
          route_network: response.route_network,
          name: response.name,
          display_name: response.display_name,
          description: response.description,
          central_point: response.central_point?.coordinates || [],
        });
      });
    }
  }

  /**
   * Render component
   * @return {*}
   */
  render() {
    const {t} = this.props;
    const serviceGroupId = this.props.match.params.id;

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              {serviceGroupId ? <h3>{t('route.serviceGroupUpdate')}</h3> : <h3>{t('route.serviceGroupAdd')}</h3>}
            </Grid>
          </Grid>
        </Container>
        <br></br>
        <Container maxWidth="xl">
          <Card raised>
            <Container maxWidth="xl">
              <br></br>
              <Paper className="search_table">
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                    {t('route.serviceGroupManagement')}
                  </Grid>
                </Grid>
                {/* id */}
                {serviceGroupId && (
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.id')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" fullWidth>
                        <TextField name="code" className="field_size_10 field_min_size_300" margin="dense" value={this.state.id} variant="outlined" disabled={true} />
                      </FormControl>
                    </Grid>
                  </Grid>
                )}

                {/* enable */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('common.enable')} <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_100">
                      <Select
                        margin="dense"
                        inputProps={{
                          name: 'enable',
                        }}
                        value={this.state.enable}
                        onChange={(e) => onChangeSelect(this, e)}
                      >
                        <MenuItem value={true}>TRUE</MenuItem>
                        <MenuItem value={false}>FALSE</MenuItem>
                      </Select>
                      {this.validator.message('enable', this.state.enable, 'required')}
                      {this.state.isSubmitForm && !this.validator.check(this.state.enable, 'required') && (
                        <FormHelperText id="enable" error>
                          {t('validation.required.choose', {field: t('common.enable')})}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                {/* name */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('common.name')} <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl variant="outlined" margin="dense" fullWidth>
                      <TextField
                        name="name"
                        className="field_size_10 field_min_size_300"
                        margin="dense"
                        placeholder={t('validation.required', {field: t('common.name')})}
                        value={this.state.name}
                        onChange={(event) => onChangeTextField(this, event)}
                        inputProps={{maxLength: 256}}
                        variant="outlined"
                      />
                      {this.validator.message('name', this.state.name, 'required')}
                      {this.state.isSubmitForm && !this.validator.check(this.state.name, 'required') && (
                        <FormHelperText id="name" error>
                          {t('validation.required', {field: t('common.name')})}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                {/* Display name */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('waypoint.display_name')} <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl variant="outlined" margin="dense" fullWidth>
                      <TextField
                        name="display_name"
                        className="field_size_10 field_min_size_300"
                        margin="dense"
                        placeholder={t('validation.required', {field: t('waypoint.display_name')})}
                        value={this.state.display_name}
                        onChange={(event) => onChangeTextField(this, event)}
                        inputProps={{maxLength: 256}}
                        variant="outlined"
                      />
                      {this.validator.message('display_name', this.state.display_name, 'required')}
                      {this.state.isSubmitForm && !this.validator.check(this.state.display_name, 'required') && (
                        <FormHelperText id="display_name" error>
                          {t('validation.required', {field: t('waypoint.display_name')})}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                {/* description */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('serviceGroup.description')} <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl variant="outlined" margin="dense" fullWidth>
                      <TextField
                        name="description"
                        className="field_size_10 field_min_size_300"
                        margin="dense"
                        placeholder={t('validation.required', {field: t('serviceGroup.description')})}
                        value={this.state.description}
                        onChange={(event) => onChangeTextField(this, event)}
                        inputProps={{maxLength: 256}}
                        variant="outlined"
                      />
                      {this.validator.message('description', this.state.description, 'required')}
                      {this.state.isSubmitForm && !this.validator.check(this.state.description, 'required') && (
                        <FormHelperText id="name" error>
                          {t('validation.required', {field: t('serviceGroup.description')})}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                {/* route network */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('serviceGroup.route_network')} <span className="font_color_red">＊</span>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                      <Select
                        margin="dense"
                        inputProps={{
                          name: 'route_network',
                        }}
                        value={this.state.route_network}
                        onChange={(e) => onChangeSelect(this, e)}
                        displayEmpty
                        renderValue={
                          this.state.route_network ?
                            undefined :
                            () => (
                              <div className="font-12 color-disabled">
                                {t('placeholder.required_select', {
                                  field: t('serviceGroup.route_network'),
                                })}
                              </div>
                            )
                        }
                      >
                        {this.props.listVehicleMode.map((vehicleMode, idx) => (
                          <MenuItem value={vehicleMode.mode} key={idx}>{vehicleMode.mode}</MenuItem>
                        ))}
                      </Select>
                      {this.validator.message('route_network', this.state.route_network, 'required')}
                      {this.state.isSubmitForm && !this.validator.check(this.state.route_network, 'required') && (
                        <FormHelperText id="route_network" error>
                          {t('validation.required.choose', {field: t('serviceGroup.route_network')})}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                {/* dataset id */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('serviceGroup.dataset_id')}
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl variant="outlined" margin="dense" fullWidth>
                      <TextField
                        name="dataset_id"
                        className="field_size_10 field_min_size_300"
                        margin="dense"
                        placeholder={t('validation.required', {field: t('serviceGroup.dataset_id')})}
                        value={this.state.dataset_id}
                        onChange={(event) => onChangeTextField(this, event)}
                        inputProps={{maxLength: 256}}
                        variant="outlined"
                      />
                      {this.validator.message('dataset_id', this.state.dataset_id, 'min:0,num')}
                      {this.state.isSubmitForm && !this.validator.check(this.state.dataset_id, 'min:0,num') && (
                        <FormHelperText id="dataset_id" error>
                          {t('validation.invalid.field', {field: t('serviceGroup.dataset_id')})}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                {/* routing profile id */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('serviceGroup.routing_profile_id')}
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl variant="outlined" margin="dense" fullWidth>
                      <TextField
                        name="routing_profile_id"
                        className="field_size_10 field_min_size_300"
                        margin="dense"
                        placeholder={t('validation.required', {field: t('serviceGroup.routing_profile_id')})}
                        value={this.state.routing_profile_id}
                        onChange={(event) => onChangeTextField(this, event)}
                        inputProps={{maxLength: 256}}
                        variant="outlined"
                      />
                      {this.validator.message('routing_profile_id', this.state.routing_profile_id, 'min:0,num')}
                      {this.state.isSubmitForm && !this.validator.check(this.state.routing_profile_id, 'min:0,num') && (
                        <FormHelperText id="routing_profile_id" error>
                          {t('validation.invalid.field', {field: t('serviceGroup.routing_profile_id')})}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                {/* walking profile id */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('serviceGroup.walking_profile_id')}
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl variant="outlined" margin="dense" fullWidth>
                      <TextField
                        name="walking_profile_id"
                        className="field_size_10 field_min_size_300"
                        margin="dense"
                        placeholder={t('validation.required', {field: t('serviceGroup.walking_profile_id')})}
                        value={this.state.walking_profile_id}
                        onChange={(event) => onChangeTextField(this, event)}
                        inputProps={{maxLength: 256}}
                        variant="outlined"
                      />
                      {this.validator.message('walking_profile_id', this.state.walking_profile_id, 'min:0,num')}
                      {this.state.isSubmitForm && !this.validator.check(this.state.walking_profile_id, 'min:0,num') && (
                        <FormHelperText id="walking_profile_id" error>
                          {t('validation.invalid.field', {field: t('serviceGroup.walking_profile_id')})}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                {/* walking profile id */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                    {t('serviceGroup.transit_stop_set_id')}
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    <FormControl variant="outlined" margin="dense" fullWidth>
                      <TextField
                        name="transit_stop_set_id"
                        className="field_size_10 field_min_size_300"
                        margin="dense"
                        placeholder={t('validation.required', {field: t('serviceGroup.transit_stop_set_id')})}
                        value={this.state.transit_stop_set_id}
                        onChange={(event) => onChangeTextField(this, event)}
                        inputProps={{maxLength: 256}}
                        variant="outlined"
                      />
                      {this.validator.message('transit_stop_set_id', this.state.transit_stop_set_id, 'min:0,num')}
                      {this.state.isSubmitForm && !this.validator.check(this.state.transit_stop_set_id, 'min:0,num') && (
                        <FormHelperText id="transit_stop_set_id" error>
                          {t('validation.invalid.field', {field: t('serviceGroup.transit_stop_set_id')})}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                {/* center point */}
                {this.props.match.params.id && this.state.central_point.length > 0 && (
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('area_geofence.center_point')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <Map centerPoint={{lat: parseFloat(this.state.central_point[1]), lng: parseFloat(this.state.central_point[0])}}></Map>
                    </Grid>
                  </Grid>
                )}
              </Paper>
              <Grid container spacing={1}>
                <Grid container alignItems="center" justifyContent="flex-start" item xs={3}></Grid>
                <Grid container alignItems="center" justifyContent="flex-end" item xs={9}>
                  {serviceGroupId ? (
                    <Button
                      color="primary"
                      variant="contained"
                      className="button_margin"
                      endIcon={<CloudUploadIcon />}
                      onClick={() => {
                        this.setState({modalMessage: t('serviceGroup.confirm_update')});
                        this.openModal();
                      }}
                    >
                      {t('common.btnUpdate')}
                    </Button>
                  ) : (
                    <>
                      <Button
                        color="primary"
                        variant="contained"
                        className="button_margin button_color_green"
                        endIcon={<AddIcon />}
                        onClick={() => {
                          this.setState({modalMessage: t('serviceGroup.confirm_create')});
                          this.openModal();
                        }}
                      >
                        {t('common.btnRegister')}
                      </Button>
                    </>
                  )}
                  <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.props.history.goBack}>
                    {t('common.btnCancel')}
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Card>
          <br></br>
        </Container>
        <Dialog
          open={this.state.showModal}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <SelectModal onClickOk={this.handleUpdate} onClickCancel={this.closeModal} message={this.state.modalMessage}></SelectModal>
        </Dialog>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    serviceGroup: state.serviceGroup,
    listVehicleMode: state.businessVehicle.listVehicleMode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getServiceGroup: (payload) => dispatch(getServiceGroup(payload)),
    updateServiceGroup: (payload, props) => dispatch(updateServiceGroup(payload, props)),
    getListVehicleMode: () => dispatch(getListVehicleMode()),
  };
};

export default withPermissionData(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(ServiceGroupForm)));
