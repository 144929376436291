import {
  cloneSimulationApi,
  enableSimulationApi,
  getSimulationDetailApi,
  searchSimulationApi,
  getServiceGroupApi,
  deleteSimulationApi,
  getWillerStopSetApi,
  validateStopSetApi,
  applySimulationApi,
} from '../../services/simulationService';
import {modalObj} from '../../utils/modal.js';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

export const searchSimulationList = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SEARCH_SIMULATION_START,
    });
    return searchSimulationApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.SEARCH_SIMULATION_SUCCESS,
            simulationList: response.result.content,
          });
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.SEARCH_SIMULATION_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.SEARCH_SIMULATION_FAIL,
        }),
    );
  };
};

export const deleteSimulation = (id) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.DELETE_SIMULATION_START,
    });
    return deleteSimulationApi(id).then(
      (response) => {
        if (response) {
          dispatch({
            type: ACTION_TYPES.DELETE_SIMULATION_SUCCESS,
          });
          return response;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.DELETE_SIMULATION_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.DELETE_SIMULATION_FAIL,
        }),
    );
  };
};

export const getSimulationDetail = (params) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_DETAIL_SIMULATION_START,
    });
    return getSimulationDetailApi(params).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_DETAIL_SIMULATION_SUCCESS,
            simulationDetail: response.result,
          });
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.GET_DETAIL_SIMULATION_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_DETAIL_SIMULATION_FAIL,
        }),
    );
  };
};

export const enableSimulation = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_SIMULATION_START,
    });
    return enableSimulationApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.UPDATE_SIMULATION_SUCCESS,
            simulationList: response.result,
          });
          dispatch(setMessageModal(modalObj(true, response.message_code, null, true)));
          return response;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.UPDATE_SIMULATION_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.UPDATE_SIMULATION_FAIL,
        }),
    );
  };
};

export const cloneSimulation = (payload, key) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.CLONE_SIMULATION_START,
    });
    return cloneSimulationApi(payload, key).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.CLONE_SIMULATION_SUCCESS,
            cloneSimulation: response.result,
          });
          dispatch(setMessageModal(modalObj(true, `The new simulation with ID = ${response.result}`)));
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.CLONE_SIMULATION_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.CLONE_SIMULATION_FAIL,
        }),
    );
  };
};

export const getServiceGroupList = (pageable) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_SERVICE_GROUP_START,
    });
    return getServiceGroupApi(pageable).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_SERVICE_GROUP_SUCCESS,
            serviceGroupList: response.result.content,
          });
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.GET_SERVICE_GROUP_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_SERVICE_GROUP_FAIL,
        }),
    );
  };
};

export const getWillerStopSet = (queryParams) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SEARCH_WILLER_STOP_SET_START,
    });
    return getWillerStopSetApi(queryParams).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.SEARCH_WILLER_STOP_SET_SUCCESS,
            stop_set_data: response.result,
          });
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.SEARCH_WILLER_STOP_SET_FAIL,
            stop_set_data: [],
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.SEARCH_WILLER_STOP_SET_FAIL,
          stop_set_data: [],
        }),
    );
  };
};

export const validateStopSet = (stopSetId, file) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.VALIDATE_STOP_SET_START});
    return validateStopSetApi(stopSetId, file).then(
      (response) => {
        if (response) {
          dispatch({type: ACTION_TYPES.VALIDATE_STOP_SET_SUCCESS});
          dispatch(setMessageModal(modalObj(true, 'Api.success')));
          return response;
        } else {
          dispatch({type: ACTION_TYPES.VALIDATE_STOP_SET_FAIL});
          dispatch(setMessageModal(modalObj(true, 'Api.fail')));
        }
      },
      () => {
        dispatch({type: ACTION_TYPES.VALIDATE_STOP_SET_FAIL});
        dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
      },
    );
  };
};

/**
 * Apply simulation
 * @param {Number} id
 * @return {Promise}
 */
export const applySimulation = (id) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.APPLY_SIMULATION_START});
    return applySimulationApi(id).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({type: ACTION_TYPES.APPLY_SIMULATION_SUCCESS});
          dispatch(setMessageModal(modalObj(true, response.message_code, null, true)));
        } else {
          dispatch({type: ACTION_TYPES.APPLY_SIMULATION_FAIL});
          dispatch(setMessageModal(modalObj(true, response.message_code)));
        }
      },
      () => {
        dispatch({type: ACTION_TYPES.APPLY_SIMULATION_FAIL});
        dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
      },
    );
  };
};
