import React, {Component} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {
  ArrowBack as ArrowBackIcon,
  Description as DetailIcon,
  RotateLeft as RotateLeftIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {Link} from 'react-router-dom';

import {ROUTE, ROWS_PER_PAGE_OPTIONS} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import {getPaymentsApi} from '../../../services/paymentService';
import {changeUrlParams, getQueryStringFromObject, onChangeSelect, getUrlParams} from '../../../utils/common';
import {convertDatetimeUTC, displayDateTime} from '../../../utils/datetime';
import withPermissionData from '../../../withPermissionData/hoc';

/**
 * Index
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      // Data
      payments: null,
      totalSize: null,
      // Search params
      dateFrom: null,
      dateTo: null,
      currency: '',
      status: '',
      size: ROWS_PER_PAGE_OPTIONS[0],
      page: 0,
      // UI
      isLoading: false,
      // Url
      searchParams: this.props.location.search,
    };
  }

  /**
   * handlePaymentSearch
   */
  handlePaymentSearch = () => {
    this.setState({isLoading: true});
    const {status, dateFrom, dateTo, currency, page, size} = this.state;
    const params = {
      status,
      from: dateFrom ? new Date(dateFrom).toISOString().substring(0, 10) : null,
      to: dateTo ? new Date(dateTo).toISOString().substring(0, 10) : null,
      currency,
      page,
      size,
    };
    getPaymentsApi(params).then(
      (response) => {
        if (response.status === 200) {
          this.setState({
            payments: response.result.content,
            totalSize: response.result.totalSize,
            size: response.result.size,
            page: response.result.page_number,
            isLoading: false,
          },
          () => {
            const {dateTo, dateFrom, currency, status, page, size} = this.state;
            const queryParamsToChange = {
              dateFrom: dateFrom ? new Date(dateFrom).toISOString().substring(0, 10) : null,
              dateTo: dateTo ? new Date(dateTo).toISOString().substring(0, 10) : null,
              currency,
              status,
              page,
              size,
            };
            changeUrlParams(queryParamsToChange);
            const newSearchParams = getQueryStringFromObject(queryParamsToChange);
            this.setState({searchParams: newSearchParams});
          },
          );
        }
      },
    );
  }

  /**
   * handleResetSearchInfo
   */
  handleResetSearchInfo = () => {
    this.setState({
      dateFrom: null,
      dateTo: null,
      currency: null,
      status: null,
      page: 0,
      size: ROWS_PER_PAGE_OPTIONS[0],
    });
  }

  /**
   * handlePageChange
   * @param {number} page
   * @param {number} size
   */
  handlePageChange = (page, size) => {
    this.setState({page, size}, this.handlePaymentSearch);
  }

  /**
   * componentDidMount
   */
  componentDidMount = () => {
    if (this.props.location.search) {
      const params = getUrlParams(this.props.location.search);
      this.setState({...params}, this.handlePaymentSearch);
    }
  }

  /**
   * render
   * @return {Component}
   */
  render() {
    const {t} = this.props;
    const {
      payments,
      totalSize,
      dateFrom,
      dateTo,
      currency,
      status,
      size,
      page,
      isLoading,
  } = this.state;

    return (
      <LoadingOverlay active={isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Container maxWidth='xl'>
          {/* HEADER */}
          <Grid container className='page_header'>
            <Grid container alignItems='center' item xs={6}>
              <h3>Payment Management</h3>
            </Grid>
            <Grid container alignItems='center' justifyContent='flex-end' item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.DATA_MANAGEMENT}}>
                <Button
                  variant='contained'
                  color='primary'
                  className='button_margin button_color'
                  endIcon={<ArrowBackIcon />}
                >
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br/>
          {/* SEARCH FORM */}
          <Card raised>
            <Container maxWidth='xl'>
              <br/>
              <Paper className='search_table'>
                <Grid container className='row_form_item'>
                  <Grid
                    container
                    alignItems='center'
                    item xs={12}
                    className='product_entry_table_header_color font_color_white font_size_mid search_condition_title'
                  >
                    Search Condition
                  </Grid>
                </Grid>
                {/* Payment Date */}
                <Grid container spacing={1} className='row_form_item table_background_color_aliceblue'>
                  <Grid container item xs={3} alignItems='center' className='grid_title_padding'>
                    Date
                  </Grid>
                  <Grid container item xs={6} alignItems='center'>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className='field_size_20 field_min_size_300'
                        margin='dense'
                        inputVariant='outlined'
                        variant='inline'
                        disableToolbar
                        autoOk
                        format='yyyy/MM/dd'
                        placeholder='Date From'
                        value={dateFrom}
                        onChange={(time) => this.setState({dateFrom: time})}
                      />
                    </MuiPickersUtilsProvider>
                    <span style={{padding: 5}} />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className='field_size_20 field_min_size_300'
                        margin='dense'
                        inputVariant='outlined'
                        variant='inline'
                        disableToolbar
                        autoOk
                        format='yyyy/MM/dd'
                        placeholder='Date To'
                        value={dateTo}
                        onChange={(time) => this.setState({dateTo: time})}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
                {/* Payment Currency */}
                <Grid container spacing={1} className='row_form_item table_background_color_aliceblue'>
                  <Grid container item xs={3} alignItems='center' className='grid_title_padding'>
                    Currency
                  </Grid>
                  <Grid container item xs={6} alignItems='center'>
                    <FormControl
                      margin='dense'
                      variant='outlined'
                      className='field_size_20 field_min_size_300'
                    >
                      <Select
                        margin='dense'
                        variant='outlined'
                        displayEmpty
                        renderValue={
                          currency ?
                            undefined :
                            () => <div className='font-12 color-disabled'>Currency</div>
                        }
                        name='currency'
                        value={currency}
                        onChange={(e) => onChangeSelect(this, e)}
                      >
                        {['VND', 'USD', 'JPY', 'SGD', 'TWD', 'MYR'].map((currency, index) => (
                          <MenuItem value={currency} key={index}>{currency}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                {/* Payment Status */}
                <Grid container spacing={1} className='row_form_item table_background_color_aliceblue'>
                  <Grid container alignItems='center' item xs={3} className='grid_title_padding'>
                    Status
                  </Grid>
                  <Grid container alignItems="center" item xs={6}>
                    <FormControl margin="dense" variant='outlined' className="field_size_20 field_min_size_300">
                      <Select
                        margin='dense'
                        variant='outlined'
                        displayEmpty
                        renderValue={
                          status ?
                            undefined :
                            () => <div className="font-12 color-disabled">Status</div>
                        }
                        name='status'
                        value={status}
                        onChange={(e) => onChangeSelect(this, e)}
                      >
                        {['SUCCESS', 'FAILED'].map((status, index) => (
                          <MenuItem key={index} value={status}>{status}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
              <br/>
              {/* Buttons */}
              <Grid container alignItems='center' justifyContent='space-between'>
                <Button
                  color='primary'
                  variant='contained'
                  className='button_margin button_color'
                  onClick={this.handleResetSearchInfo}
                  endIcon={<RotateLeftIcon />}
                >
                  {t('common.btnResetSearch')}
                </Button>
                <Button
                  color="primary"
                  variant='contained'
                  className='button_margin'
                  onClick={this.handlePaymentSearch}
                  endIcon={<SearchIcon />}
                >
                  {t('common.btnSearch')}
                </Button>
              </Grid>
              <br/>
            </Container>
          </Card>
          <br/>

          {/* SEARCH RESULT */}
          {payments && (
            <LoadingOverlay active={isLoading} bgColor='#f1f1f1' spinnerColor='#9ee5f8' textColor='#676767' spinner>
              <Card raised>
                <Container maxWidth="xl">
                  <Grid container>
                    <Grid container alignItems='center' item xs={12}>
                      <h3>{t('common.searchResult')} {payments.length} {t('common.case')}</h3>
                    </Grid>
                  </Grid>
                  {payments.length > 0 && (
                    <Table size='small' aria-label='sticky table' stickyHeader className="layoutfix">
                      <TableHead>
                        <TableRow>
                          <TableCell className="width_50p">ID</TableCell>
                          <TableCell className="width_50p">User ID</TableCell>
                          <TableCell className="width_125p">Order ID</TableCell>
                          <TableCell className="width_125p">Payment At</TableCell>
                          <TableCell className="width_125p">Payment Method</TableCell>
                          <TableCell className="width_125p">Payment Code</TableCell>
                          <TableCell className="width_50p">Currency</TableCell>
                          <TableCell className="width_50p">Amount</TableCell>
                          <TableCell className="width_75p">Status</TableCell>
                          <TableCell className="width_75p"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {payments.map((payment, index) => (
                          <TableRow hover key={index}>
                            <TableCell>{payment.id}</TableCell>
                            <TableCell>{payment.user_id || '---'}</TableCell>
                            <TableCell>{payment.order_id || '---'}</TableCell>
                            <TableCell>{displayDateTime(convertDatetimeUTC(payment.payment_at))}</TableCell>
                            <TableCell>{payment.payment_method || '---'}</TableCell>
                            <TableCell>{payment.payment_code || '---'}</TableCell>
                            <TableCell>{payment.currency || '---'}</TableCell>
                            <TableCell>{payment.amount || '---'}</TableCell>
                            <TableCell>{payment.status}</TableCell>
                            <TableCell align="center">
                              <Link
                                style={{textDecoration: 'none'}}
                                to={{
                                  pathname: ROUTE.LAYOUT + ROUTE.PAYMENT_MANAGEMENT + `/${payment.id}`,
                                  state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                }}
                              >
                                <Button
                                  className="button-margin"
                                  color="primary"
                                  variant="contained"
                                  endIcon={<DetailIcon />}
                                >
                                  Details
                                </Button>
                              </Link>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                  <CustomPagination onChange={this.handlePageChange} rows={totalSize} rowsPerPage={size} currentPage={page} />
                </Container>
              </Card>
              <br />
            </LoadingOverlay>
          )}
        </Container>
      </LoadingOverlay>
    );
  }
}

export default withPermissionData(withTranslation('translations')(Index));
