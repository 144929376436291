import {api} from '../services/api';

const getNearestPartnerUrl = '/admin/waypoint/nearest';
const getVehicleInfoUrl = '/mass/v1/vehicles/info';
const getVehicleFrequencyHistoryUrl = '/gps/location/detail';

export const getNearestPartnerApi = (latitude, longitude, radius, limit, offset, service_group_id) => {
  return api.get(getNearestPartnerUrl, {latitude: latitude, longitude: longitude, radius: radius, limit: limit, offset: offset, service_group_id: service_group_id});
};

export const getVehicleInfo = (payload) => {
  return api.post(getVehicleInfoUrl, payload);
};

export const getVehicleFrequencyHistoryApi = (params) => {
  return api.get(getVehicleFrequencyHistoryUrl, params);
};
