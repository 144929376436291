export const ACTION_TYPES = {
  SEARCH_LIST_NOTICE_START: 'SEARCH_LIST_NOTICE_START',
  SEARCH_LIST_NOTICE_SUCCESS: 'SEARCH_LIST_NOTICE_SUCCESS',
  SEARCH_LIST_NOTICE_FAIL: 'SEARCH_LIST_NOTICE_FAIL',

  GET_NOTICE_START: 'GET_NOTICE_START',
  GET_NOTICE_SUCCESS: 'GET_NOTICE_SUCCESS',
  GET_NOTICE_FAIL: 'GET_NOTICE_FAIL',

  DELETE_NOTICE_START: 'DELETE_NOTICE_START',
  DELETE_NOTICE_SUCCESS: 'DELETE_NOTICE_SUCCESS',
  DELETE_NOTICE_FAIL: 'DELETE_NOTICE_FAIL',

  UPSERT_NOTICE_START: 'UPSERT_NOTICE_START',
  UPSERT_NOTICE_SUCCESS: 'UPSERT_NOTICE_SUCCESS',
  UPSERT_NOTICE_FAIL: 'UPSERT_NOTICE_FAIL',
};

const initialState = {
  isLoading: false,
  noticeList: [],
  noticeDetail: {},
  error: '',
};

/**
 * function reduce notice
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.UPSERT_NOTICE_START:
    case ACTION_TYPES.DELETE_NOTICE_START:
    case ACTION_TYPES.SEARCH_LIST_NOTICE_START:
    case ACTION_TYPES.GET_NOTICE_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.DELETE_NOTICE_SUCCESS:
    case ACTION_TYPES.UPSERT_NOTICE_SUCCESS:
    case ACTION_TYPES.UPSERT_NOTICE_FAIL:
    case ACTION_TYPES.DELETE_NOTICE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.SEARCH_LIST_NOTICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        noticeList: action.noticeList.content,
        error: '',
      };
    case ACTION_TYPES.GET_NOTICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        noticeDetail: action.noticeDetail,
        error: '',
      };
    case ACTION_TYPES.SEARCH_LIST_NOTICE_FAIL:
      return {
        isLoading: false,
        error: action.error,
        noticeList: [],
      };
    case ACTION_TYPES.GET_NOTICE_FAIL:
      return {
        ...state,
        isLoading: false,
        noticeDetail: {},
        error: '',
      };
    default:
      return state;
  }
}
