export const ACTION_TYPES = {
  ADD_AN_ITEM: 'ADD_AN_ITEM',
  ADD_ANY_ITEMS: 'ADD_ANY_ITEMS',
  SUB_AN_ITEM: 'SUB_AN_ITEM',
  REMOVE_ITEMS: 'REMOVE_TIMES',
  REMOVE_ALL_ITEMS: 'REMOVE_ALL_ITEMS',
};

const initialState = {
  total_amount: 0,
  items: [],
};

/**
 * function reservation
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    // click button plus
    case ACTION_TYPES.ADD_AN_ITEM:
      const addedItem = state.items.find((item) => item.id === action.product.id);
      // check if the action id exists in the addedItems
      if (addedItem) {
        if (addedItem.quantity <= 8) {
          addedItem.quantity += 1;
          return {
            ...state,
            total_amount: state.total_amount + addedItem.price,
          };
        } else {
          return {
            ...state,
          };
        }
      } else {
        action.product.quantity = 1;
        // calculating the total
        const newTotal = state.total_amount + action.product.price;
        return {
          ...state,
          items: [...state.items, action.product],
          total_amount: newTotal,
        };
      }
    // input to quantity
    case ACTION_TYPES.ADD_ANY_ITEMS:
      const changedItem = state.items.find((item) => item.id === action.product.id);
      if (changedItem) {
        changedItem.quantity = action.product.quantity;
        const newTotal = state.total_amount - changedItem.price * changedItem.quantity + action.product.price * action.product.quantity;
        return {
          ...state,
          total_amount: newTotal,
        };
      } else {
        // calculating the total
        const newTotal = state.total_amount + action.product.price * action.product.quantity;
        return {
          ...state,
          items: [...state.items, action.product],
          total_amount: newTotal,
        };
      }
    // click button sub
    case ACTION_TYPES.SUB_AN_ITEM:
      const itemToSub = state.items.find((item) => action.product.id === item.id);
      // calculating the total
      const newTotal = state.total_amount - action.product.price;
      if (itemToSub.quantity <= 1) {
        const new_items = state.items.filter((item) => action.product.id !== item.id);
        return {
          ...state,
          items: new_items,
          total_amount: newTotal,
        };
      } else {
        itemToSub.quantity--;
        return {
          ...state,
          total_amount: newTotal,
        };
      }

    // remove an item
    case ACTION_TYPES.REMOVE_ITEMS:
      const itemToRemove = state.items.find((item) => action.product.id === item.id);
      const new_items = state.items.filter((item) => action.product.id !== item.id);

      // calculating the total
      const newTotalRemove = state.total_amount - itemToRemove.price;
      return {
        ...state,
        items: new_items,
        total_amount: newTotalRemove,
      };
    case ACTION_TYPES.REMOVE_ALL_ITEMS:
      return {
        ...state,
        items: [],
        total_amount: 0,
      };
    default:
      return state;
  }
}
