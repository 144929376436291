import React, {Component} from 'react';

import {Box, Button, Card, Container, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';

import {DATA_NULL, ROWS_PER_PAGE_OPTIONS} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import {getAllServiceGroups} from '../../../stores/service_group/actions';
import {onChangeTextField} from '../../../utils/common';

/**
 * Search Stop set Dialog
 */
class SearchServiceGroupModal extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      display_name: '',
      description: '',

      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      selectedData: {},
    };
    this.handleClickClose = this.handleClickClose.bind(this);
    this.handleClickOk = this.handleClickOk.bind(this);
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.handleSearch();
    const {parentData, serviceGroup} = this.props;
    const selectedData = await serviceGroup?.allServiceGroups?.content?.find((item) => item.id === parentData?.id);
    this.setState({selectedData});
  }

  /**
   * handleSearch
   * @param {Boolean} reset
   */
  handleSearch = (reset = false) => {
    const {name, display_name, description, currentPage, rowsPerPage} = this.state;
    const queryParams = {
      name,
      display_name,
      description,
      page: reset ? 0 : currentPage,
      size: reset ? ROWS_PER_PAGE_OPTIONS[0] : rowsPerPage,
    };
    this.props.getAllServiceGroups(queryParams);
    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
  };

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = async (currentPage, rowsPerPage) => {
    await this.setState({currentPage, rowsPerPage});
    await this.handleSearch();
  };

  /**
   * handleSelect
   * @param {Object} item
   */
  handleSelect = (item) => {
    this.setState({selectedData: item});
  };

  /**
   * handleClickOk
   */
  handleClickOk() {
    this.props.onSelect(this.state.selectedData);
    this.props.onClickOk();
  }

  /**
   * handleClickClose
   */
  handleClickClose() {
    this.props.onClickClose();
  }

  /**
   * render component
   * @return {HTML}
   */
  render() {
    const {t, serviceGroup} = this.props;
    const {isLoading, allServiceGroups} = serviceGroup;

    return (
      <div className="field_size_90">
        <LoadingOverlay active={isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
          <Container maxWidth="xl">
            <Box p={1} m={1}>
              <Card raised>
                <Container maxWidth="xl">
                  <Box p={2} m={2}>
                    <Grid container alignItems="flex-start">
                      <Grid item xs={12}>
                        <h1 className="product_entry_table_header_color font_color_white font_size_mid title">{t('geofence.service_group')}</h1>
                        <Grid container alignItems="flex-start">
                          <Grid item xs={12}>
                            <Grid container spacing={1} alignItems="center">
                              <Grid item xs={2}>
                                <TextField
                                  className="width_100"
                                  name="name"
                                  margin="dense"
                                  label={t('common.name')}
                                  variant="outlined"
                                  value={this.state.name}
                                  onChange={(event) => onChangeTextField(this, event)}
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <TextField
                                  className="width_100"
                                  name="display_name"
                                  margin="dense"
                                  label={t('simulationManagement.display_name')}
                                  variant="outlined"
                                  value={this.state.display_name}
                                  onChange={(event) => onChangeTextField(this, event)}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  className="width_100"
                                  name="description"
                                  margin="dense"
                                  label={t('simulationManagement.description')}
                                  variant="outlined"
                                  value={this.state.description}
                                  onChange={(event) => onChangeTextField(this, event)}
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<SearchIcon />} onClick={() => this.handleSearch(true)}>
                                  {t('common.btnSearch')}
                                </Button>
                              </Grid>
                            </Grid>
                            {allServiceGroups?.content?.length > 0 && (
                              <Grid container alignItems="flex-start">
                                <div className="scroll_area_400">
                                  <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                                    <TableHead>
                                      <TableRow>
                                        <TableCell className="width_50p">{t('common.id')}</TableCell>
                                        <TableCell className="width_100p">{t('common.name')}</TableCell>
                                        <TableCell className="width_100p">{t('simulationManagement.display_name')}</TableCell>
                                        <TableCell className="width_150p">{t('simulationManagement.description')}</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {allServiceGroups?.content?.map((item, index) => {
                                        return (
                                          <TableRow
                                            key={index}
                                            onClick={() => this.handleSelect(item)}
                                            className={this.state.selectedData?.id === item.id ? 'row_form_item select_modal_item cursor_pointer' : 'row_form_item cursor_pointer'}
                                          >
                                            <TableCell>{item.id}</TableCell>
                                            <TableCell>{item.name ? item.name : DATA_NULL}</TableCell>
                                            <TableCell>{item.display_name ? item.display_name : DATA_NULL}</TableCell>
                                            <TableCell>{item.description ? item.description : DATA_NULL}</TableCell>
                                          </TableRow>
                                        );
                                      })}
                                    </TableBody>
                                  </Table>
                                </div>
                                <CustomPagination
                                  onChange={this.onChangePagination}
                                  rows={allServiceGroups?.totalSize}
                                  rowsPerPage={this.state.rowsPerPage}
                                  currentPage={this.state.currentPage}
                                />
                              </Grid>
                            )}
                            {allServiceGroups?.content?.length < 0 && <h5 className="text_grey">{t('reservationManagement.no_result')}</h5>}
                          </Grid>
                        </Grid>
                        <br />
                        <Grid container alignItems="flex-start">
                          <Grid container justify="center" alignItems="center" item xs={12}>
                            <Button variant="contained" className="button_margin" color="primary" onClick={this.handleClickOk}>
                              {t('common.btnOk')}
                            </Button>
                            <Button variant="contained" className="button_color button_magin" style={{marginLeft: 5}} color="primary" onClick={this.handleClickClose}>
                              {t('common.btnCancel')}
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              </Card>
            </Box>
          </Container>
        </LoadingOverlay>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    serviceGroup: state.serviceGroup,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllServiceGroups: (payload) => dispatch(getAllServiceGroups(payload)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(SearchServiceGroupModal));
