import React, {Component} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  Card,
  Container,
  Chip,
  Checkbox,
  Grid,
  Button,
  Dialog,
  Paper,
  TextField,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormGroup,
  Select,
  MenuItem,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DetailIcon from '@material-ui/icons/Description';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
// import DeleteIcon from '@material-ui/icons/Delete';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

import {ROUTE, ROWS_PER_PAGE_OPTIONS, DATA_NULL, PERMISSION_ACTIONS, LANGUAGE, NEWS_DISPLAY_METHOD} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getAllCountryCode, getAllGeofence} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {deleteNews, searchNews} from '../../../stores/news_template/action';
import {onChangeTextField, changeUrlParams, getQueryStringFromObject, onChangeSelect, getUrlParams} from '../../../utils/common';
import {displayDateTime, displayDate, compareDateTimeRange, convertStartEndTime, convertDateTimeToDate, convertDatetimeUTC} from '../../../utils/datetime';
import {modalObj} from '../../../utils/modal';
import {isRoleGlobal} from '../../../utils/role';

/**
 * News Management Search Component
 */
class Index extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      country_id: '',
      geofence_ids: [],
      display_on: ['USER_APP', 'DRIVER_APP', 'USER_APP & DRIVER_APP'],
      keyword: '',
      created_from: null,
      created_to: null,
      display_from: null,
      display_to: null,
      is_active: '',
      display_method: '',
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],

      result: [],
      totalRows: 0,
      id: '',
      isLoading: false,
      searchParams: this.props.location.search,
      latestSearchParams: null,
      listGeofenceAll: [],
      isSearch: false,
      flagDelete: false,
      flagExport: false,
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllCountryCode();
    await this.props.getAllGeofence().then(() => this.setState({listGeofenceAll: this.props.common?.all_geofence}));
    if (!isRoleGlobal()) {
      this.setState({country_id: this.props.common.principal?.country_id});
    }
    if (this.props.location.search) {
      const params = getUrlParams(this.props.location.search);
      if (params.created_from) {
        params.created_from = new Date(params.created_from);
      }
      if (params.created_to) {
        params.created_to = new Date(params.created_to);
      }
      if (params.display_from) {
        params.display_from = new Date(params.display_from);
      }
      if (params.display_to) {
        params.display_to = new Date(params.display_to);
      }
      this.setState(params, () => {
        this.state.country_id && isRoleGlobal() &&
          this.setState({
            listGeofenceAll: this.props.common?.all_geofence?.filter((e) => e.country_id === Number(this.state.country_id)),
          });
        this.handleSearch();
      });
    }
  }

  /**
   * handleOnChangeCountry
   * @param {*} value
   */
  handleOnChangeCountry = (value) => {
    this.setState({
      country_id: value,
      geofence_ids: [],
      listGeofenceAll: this.props.common?.all_geofence?.filter((e) => e.country_id === value),
    });
  };

  /**
   * handleSelectGeofence
   * @param {Object} value
   * @param {Number} item
   */
  handleSelectGeofence = (value, item) => {
    const geofence_ids_copy = this.state.geofence_ids;
    const index = geofence_ids_copy.indexOf(item);
    value ? geofence_ids_copy.push(item) : geofence_ids_copy.splice(index, 1);

    this.setState({
      geofence_ids: geofence_ids_copy,
    });
  };

  /**
   * handleChangeDisplayOn
   * @param {Object} event
   * @param {String} displayOn
   */
  handleChangeDisplayOn = (event, displayOn) => {
    const display_on_copy = [...this.state.display_on];
    const index = display_on_copy.indexOf(displayOn);
    event.target.checked ? display_on_copy.push(displayOn) : display_on_copy.splice(index, 1);
    this.setState({display_on: display_on_copy});
  }

  /**
   * handleSearch
   * @param {*} reset
   */
  handleSearch = (reset) => {
    if (!this.props.actions.includes(PERMISSION_ACTIONS.SEARCH)) return;
    if (
      ((this.state.created_from || this.state.created_to || this.state.display_from || this.state.display_to) && !this.state.country_id) ||
      !compareDateTimeRange(this.state.display_from, this.state.display_to, true) ||
      !compareDateTimeRange(this.state.created_from, this.state.created_to, true)
    ) {
      return;
    }
    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0], isLoading: true});
    const {currentPage, rowsPerPage, country_id, geofence_ids, display_on, keyword, created_from, created_to, display_from, display_to, display_method, is_active} = this.state;
    const display_on_selections = display_on.map((item) => {
      // eslint-disable-next-line comma-spacing
      if (item === 'USER_APP & DRIVER_APP') return ['USER_APP','DRIVER_APP'];
      return [item];
    });
    const payload = {
      country_id: country_id || null,
      geofence_ids: geofence_ids || null,
      display_on: display_on_selections,
      keyword: keyword.trim() || null,
      created_from: created_from ? convertDateTimeToDate(created_from) : null,
      created_to: created_to ? convertDateTimeToDate(created_to) : null,
      display_from: display_from ? convertDateTimeToDate(display_from) : null,
      display_to: display_to ? convertDateTimeToDate(display_to) : null,
      display_method: display_method || null,
      is_active: is_active !== '' ? is_active : null,
    };
    display_on_selections.length === 0 && delete payload.display_on;
    const queryParams = reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0], sort: 'created_date,desc'} : {page: currentPage, size: rowsPerPage, sort: 'created_date,desc'};
    this.props.searchNews(payload, queryParams, this.props.history).then(
      (response) => {
        this.setState(
          {
            result: response?.result?.content,
            totalRows: response?.result?.totalSize,
            isSearch: true,
            isLoading: false,
            latestSearchParams: payload,
          },
          () => {
            const {currentPage, rowsPerPage, country_id, geofence_ids, keyword, created_from, created_to, display_from, display_to, is_active} = this.state;
            // Apply changed params into url
            const queryParamsToChange = {
              country_id,
              geofence_ids,
              display_on,
              keyword,
              created_from,
              created_to,
              display_from,
              display_to,
              display_method,
              is_active,
              currentPage: String(currentPage),
              rowsPerPage,
            };
            changeUrlParams(queryParamsToChange);
            const newSearchParams = getQueryStringFromObject(queryParamsToChange);
            // Save search params into state in order to pass to next page
            this.setState({searchParams: newSearchParams});
          },
        );
      },
      () => {
        this.setState({isLoading: false});
      },
    );
  };

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearch);
  };

  /**
   * reset
   */
  reset = () => {
    this.setState({
      country_id: isRoleGlobal() ? '' : this.state.country_id,
      geofence_ids: [],
      display_on: ['USER_APP', 'DRIVER_APP', 'USER_APP & DRIVER_APP'],
      keyword: '',
      created_from: null,
      created_to: null,
      display_from: null,
      display_to: null,
      is_active: '',
      display_method: '',
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      listGeofenceAll: this.props.common?.all_geofence,
    });
  };

  /**
   * handleDelete
   * @param {int} id
   */
  openDeleteModal = (id) => {
    this.setState({
      flagDelete: true,
      message: this.props.t('messageCode.deleteConfirm', {field: this.props.t('news_management.title')}),
      id,
    });
  };

  /**
   * handleButtonOk
   */
  handleButtonOk = async () => {
    this.setState({
      flagDelete: false,
      isLoading: true,
    });
    await this.props.deleteNews(this.state.id).then(
      (response) => {
        if (response && response.status === 200) {
          this.setState({isLoading: false});
          this.props.setMessageModal(modalObj(true, response?.message_code));
          this.handleSearch();
        } else {
          this.setState({isLoading: false});
        }
      },
      () => {
        this.setState({isLoading: false});
      },
    );
  };

  /**
   * handleButtonCancel
   */
  handleButtonCancel = () => {
    this.setState({
      flagDelete: false,
    });
  };

  /**
   * renderListGeofence
   * @param {Array} geofences
   * @return {*}
   */
  renderListGeofence(geofences) {
    return geofences?.map((item, index) => <li key={index}>{item.name}</li>);
  }

  /**
   * Render Component
   * @return {HTML}
   */
  render() {
    const {t, common, actions} = this.props;
    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canRegister: actions.includes(PERMISSION_ACTIONS.REGISTER),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
      canTemplateSetting: actions.includes(PERMISSION_ACTIONS.TEMPLATE_SETTING),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.newsManagement')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.COMMON_SETTING_MANAGEMENT}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Container>
        <br></br>
        <LoadingOverlay active={common.isLoading || this.state.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                {permission.canSearch && (
                  <>
                    <Paper className="search_table">
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                          {t('businessVehicle.searchTitle')}
                        </Grid>
                      </Grid>
                      {/* Country */}
                      <Grid container spacing={1} className="row_form_item ">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.country')}
                          {(this.state.created_from || this.state.created_to || this.state.display_from || this.state.display_to) && <span className="font_color_red">＊</span>}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'country_id',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.country_id ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('common.country'),
                                        })}
                                      </div>
                                    )
                              }
                              disabled={!isRoleGlobal()}
                              value={this.state.country_id}
                              onChange={(event) => this.handleOnChangeCountry(event.target.value)}
                            >
                              {common?.country_code?.map((item, idx) => (
                                <MenuItem value={item.id} key={idx}>
                                  {t(`${item.country_code}`)}
                                </MenuItem>
                              ))}
                            </Select>
                            {(this.state.created_from || this.state.created_to || this.state.display_from || this.state.display_to) && !this.state.country_id && (
                              <FormHelperText id="country_id" error>
                                {t('validation.required.choose', {field: t('common.country')})}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Geofence */}
                      <Grid container spacing={1} className="row_form_item ">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('business_plan.geofence')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={10}>
                          <FormControl error>
                            <FormGroup row>
                              {this.state.listGeofenceAll?.map((item, index) => {
                                const checked = this.state.geofence_ids?.indexOf(item.geofence_id) > -1;
                                return (
                                  <FormControlLabel
                                    key={index}
                                    labelPlacement="end"
                                    control={
                                      <Checkbox
                                        disabled={!item.enable || !item.enable_for_admin}
                                        checked={checked}
                                        onClick={(event) => this.handleSelectGeofence(event.target.checked, item.geofence_id)}
                                        className="checkbox_radio"
                                      />
                                    }
                                    label={item.name}
                                  />
                                );
                              })}
                            </FormGroup>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Display on */}
                      <Grid container alignItems='center' spacing={1} className='row_form_item'>
                        <Grid container alignItems='center' item xs={6} lg={2} className='grid_title_padding'>
                          <Box py={2}>
                            {t('news_management.display_on')}
                          </Box>
                        </Grid>
                        <Grid container alignItems='center' item xs={6} lg={6}>
                          <FormGroup row>
                            {['USER_APP', 'DRIVER_APP', 'USER_APP & DRIVER_APP'].map((item, idx) => {
                              const checked = this.state.display_on.includes(item);
                              return (
                                <FormControlLabel
                                  key={idx}
                                  label={item}
                                  labelPlacement='end'
                                  control={
                                    <Checkbox
                                      className='checkbox_radio'
                                      checked={checked}
                                      onChange={(event) => this.handleChangeDisplayOn(event, item)}
                                    />
                                  }
                                />
                              );
                            })}
                          </FormGroup>
                        </Grid>
                      </Grid>

                      {/* key-word */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('news_management.key_word')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="keyword"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('validation.required', {field: t('news_management.key_word')})}
                            value={this.state.keyword}
                            onChange={(event) => onChangeTextField(this, event)}
                            inputProps={{maxLength: 256}}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>

                      {/* display-time */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('news_management.display_time')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={6}>
                          <FormControl>
                            <Grid container alignItems="center" item xs={12} lg={12}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <FormControl className="field_size_10 field_min_size_250">
                                  <KeyboardDatePicker
                                    className="field_size_20 field_min_size_250"
                                    margin="dense"
                                    autoOk
                                    disableToolbar
                                    variant="inline"
                                    inputVariant="outlined"
                                    minDate="2000-01-01"
                                    placeholder={t('sub.effective_date_from')}
                                    format="yyyy/MM/dd"
                                    value={this.state.display_from}
                                    invalidDateMessage={t('errorFields.invalidDateMessage')}
                                    maxDateMessage={t('errorFields.maxDateMessage')}
                                    minDateMessage={t('errorFields.minDateMessage')}
                                    KeyboardButtonProps={{
                                      'aria-label': 'change time',
                                    }}
                                    onChange={(time) =>
                                      this.setState({
                                        display_from: time,
                                      })
                                    }
                                  />
                                </FormControl>
                                <span style={{padding: '5px'}}>~</span>
                                <FormControl className="field_size_10 field_min_size_250">
                                  <KeyboardDatePicker
                                    className="field_size_20 field_min_size_250"
                                    margin="dense"
                                    autoOk
                                    disableToolbar
                                    variant="inline"
                                    placeholder={t('sub.effective_date_to')}
                                    inputVariant="outlined"
                                    minDate="2000-01-01"
                                    format="yyyy/MM/dd"
                                    KeyboardButtonProps={{
                                      'aria-label': 'change time',
                                    }}
                                    value={this.state.display_to}
                                    onChange={(time) => this.setState({display_to: time})}
                                    invalidDateMessage={t('errorFields.invalidDateMessage')}
                                    maxDateMessage={t('errorFields.maxDateMessage')}
                                    minDateMessage={t('errorFields.minDateMessage')}
                                  />
                                </FormControl>
                              </MuiPickersUtilsProvider>
                            </Grid>
                            {!compareDateTimeRange(this.state.display_from, this.state.display_to, true) && (
                              <FormHelperText id="display_time" error>
                                {t('validation.invalid.timeRange')}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* create-time */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('news_management.create_time')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={6}>
                          <FormControl>
                            <Grid container alignItems="center" item xs={12} lg={12}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <FormControl className="field_size_10 field_min_size_250">
                                  <KeyboardDatePicker
                                    className="field_size_20 field_min_size_250"
                                    margin="dense"
                                    autoOk
                                    disableToolbar
                                    variant="inline"
                                    inputVariant="outlined"
                                    minDate="2000-01-01"
                                    placeholder={t('sub.effective_date_from')}
                                    format="yyyy/MM/dd"
                                    value={this.state.created_from}
                                    invalidDateMessage={t('errorFields.invalidDateMessage')}
                                    maxDateMessage={t('errorFields.maxDateMessage')}
                                    minDateMessage={t('errorFields.minDateMessage')}
                                    KeyboardButtonProps={{
                                      'aria-label': 'change time',
                                    }}
                                    onChange={(time) =>
                                      this.setState({
                                        created_from: time,
                                      })
                                    }
                                  />
                                </FormControl>
                                <span style={{padding: '5px'}}>~</span>
                                <FormControl className="field_size_10 field_min_size_250">
                                  <KeyboardDatePicker
                                    className="field_size_20 field_min_size_250"
                                    margin="dense"
                                    autoOk
                                    disableToolbar
                                    variant="inline"
                                    placeholder={t('sub.effective_date_to')}
                                    inputVariant="outlined"
                                    minDate="2000-01-01"
                                    format="yyyy/MM/dd"
                                    invalidDateMessage={t('errorFields.invalidDateMessage')}
                                    maxDateMessage={t('errorFields.maxDateMessage')}
                                    minDateMessage={t('errorFields.minDateMessage')}
                                    KeyboardButtonProps={{
                                      'aria-label': 'change time',
                                    }}
                                    value={this.state.created_to}
                                    onChange={(time) => this.setState({created_to: time})}
                                  />
                                </FormControl>
                              </MuiPickersUtilsProvider>
                            </Grid>
                            {!compareDateTimeRange(this.state.created_from, this.state.created_to, true) && (
                              <FormHelperText id="display_time" error>
                                {t('validation.invalid.timeRange')}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Display Method */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('news_management.display_method')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'display_method',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.display_method ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('news_management.display_method'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.display_method}
                              onChange={(e) => onChangeSelect(this, e)}
                            >
                              {NEWS_DISPLAY_METHOD?.map((item, index) => (
                                <MenuItem key={index} value={item.value}>{t(`${item.i18n}`)}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Status */}
                      <Grid container spacing={1} className="row_form_item ">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.status')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'is_active',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.is_active || this.state.is_active === false ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('common.status'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.is_active}
                              onChange={(e) => onChangeSelect(this, e)}
                            >
                              <MenuItem value={true}>{t(`news_management.active`)}</MenuItem>
                              <MenuItem value={false}>{t(`news_management.inactive`)}</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Paper>
                    <br></br>
                  </>
                )}
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.reset} endIcon={<RotateLeftIcon />}>
                        {t('common.btnResetSearch')}
                      </Button>
                    )}
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {permission.canRegister && (
                      <Link
                        style={{textDecoration: 'none'}}
                        to={{pathname: ROUTE.LAYOUT + ROUTE.NEWS_REGISTER, state: {from: this.props.location.pathname + '?' + this.state.searchParams}}}
                      >
                        <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />}>
                          {t('common.btnRegister')}
                        </Button>
                      </Link>
                    )}
                    {permission.canTemplateSetting && (
                      <Link
                        style={{textDecoration: 'none'}}
                        to={{pathname: ROUTE.LAYOUT + ROUTE.TEMPLATE_SETTING_MANAGEMENT, state: {from: this.props.location.pathname + '?' + this.state.searchParams}}}
                      >
                        <Button color="primary" variant="contained" className="button_margin">
                          {t('news_management.template_list')}
                        </Button>
                      </Link>
                    )}
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin" endIcon={<SearchIcon />} onClick={() => this.handleSearch(true)}>
                        {t('common.btnSearch')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Container>
              <br></br>
            </Card>
          </Container>
          <br />
          <Container maxWidth="xl">
            {this.state.isSearch && (
              <Card raised>
                <Container maxWidth="xl">
                  <Grid container spacing={1}>
                    <Grid container alignItems="center" justify="flex-start" item xs={3}>
                      <h3>
                        {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                      </h3>
                    </Grid>
                  </Grid>

                  <div className="scroll_area_700">
                    {this.state.result?.length > 0 && (
                      <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix news">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('common.country')}</TableCell>
                            <TableCell>{t('business_plan.geofence')}</TableCell>
                            <TableCell>{t('popup_recommend.title')} (JP)</TableCell>
                            <TableCell>{t('popup_recommend.title')} (VN)</TableCell>
                            <TableCell>{t('popup_recommend.title')} (EN)</TableCell>
                            <TableCell>{t('news_management.display_time')}</TableCell>
                            <TableCell>{t('news_management.create_time')}</TableCell>
                            <TableCell>{t('news_management.display_method')}</TableCell>
                            <TableCell>{t('news_management.display_on')}</TableCell>
                            <TableCell>{t('common.status')}</TableCell>
                            {permission.canEdit && <TableCell>{t('common.action')}</TableCell>}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.result.map((row, index) => {
                            const country = common?.country_code?.find((item) => Number(row.country_id) === item.id);
                            return (
                              <TableRow key={index} hover className="cursor_pointer">
                                <TableCell>{country?.country_code || DATA_NULL}</TableCell>
                                <TableCell>
                                  <ul>{this.renderListGeofence(row?.geofence_infos) || DATA_NULL}</ul>
                                </TableCell>
                                <TableCell>
                                  {row.news_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.title || DATA_NULL}
                                </TableCell>
                                <TableCell>
                                  {row.news_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.title || DATA_NULL}
                                </TableCell>
                                <TableCell>
                                  {row.news_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.title || DATA_NULL}
                                </TableCell>
                                <TableCell>
                                  <div style={{display: 'grid'}}>
                                    <span style={{paddingLeft: 16}}>{displayDate(row?.display_from)}</span> <span>~ {displayDate(row?.display_to)}</span>
                                  </div>
                                </TableCell>
                                <TableCell>{displayDateTime(convertDatetimeUTC(row?.created_date, row?.country_code)) || DATA_NULL}</TableCell>
                                <TableCell>
                                  {this.props.i18n.language === 'ja' ?
                                    row?.display_method_language?.ja || DATA_NULL :
                                    this.props.i18n.language === 'en' ?
                                    row?.display_method_language?.en || DATA_NULL :
                                    row?.display_method_language?.vi || DATA_NULL}
                                </TableCell>
                                <TableCell>
                                  {row.display_on?.map((item, idx) => (<div key={idx}>{item}</div>))}
                                </TableCell>
                                <TableCell>
                                  <Chip
                                    className={row?.is_active ? 'background-color-info' : ''}
                                    label={row?.is_active ? t(`news_management.active`) : t(`news_management.inactive`)}
                                  />
                                  {!compareDateTimeRange(new Date(), convertStartEndTime(row?.display_to, false), true) && (
                                    <Chip className="background-color-danger" label={t(`news_management.time_out`)} />
                                  )}
                                </TableCell>
                                {permission.canEdit && (
                                  <TableCell>
                                    <Link
                                      style={{textDecoration: 'none'}}
                                      to={{
                                        pathname: ROUTE.LAYOUT + ROUTE.NEWS_DETAIL + '/' + row.id,
                                        state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                      }}
                                    >
                                      <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                        {t('common.btnEdit')}
                                      </Button>
                                    </Link>
                                    {/* <Button
                                    color="primary"
                                    variant="contained"
                                    className="button_margin button_color_red"
                                    endIcon={<DeleteIcon />}
                                    onClick={() => this.openDeleteModal(row.id)}
                                  >
                                    {t('common.btnDelete')}
                                  </Button> */}
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    )}
                  </div>
                  <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                </Container>
                <br></br>
                <Dialog
                  open={this.state.flagDelete}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    onClickOk={this.handleButtonOk}
                    onClickCancel={this.handleButtonCancel}
                    message={this.state.message}
                    okButtonText={t('common.btnYes')}
                    cancelButtonText={t('common.btnNo')}
                  ></SelectModal>
                </Dialog>
                {/* Export alert modal */}
                <Dialog
                  open={this.state.flagExport}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    onClickOk={this.handleButtonExportOk}
                    onClickCancel={this.handleButtonExportCancel}
                    okButtonText={t('common.btnYes')}
                    cancelButtonText={t('common.btnNo')}
                    message={this.state.message}
                  ></SelectModal>
                </Dialog>
              </Card>
            )}
            <br></br>
          </Container>
        </LoadingOverlay>
        <br></br>
      </Card>
    );
  }
}

const mapPropsToState = (state) => {
  return {
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllGeofence: () => dispatch(getAllGeofence()),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    searchNews: (payload, params, browserHistory) => dispatch(searchNews(payload, params, browserHistory)),
    deleteNews: (id) => dispatch(deleteNews(id)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapPropsToState, mapDispatchToProps)(Index)));
