import React, {Component} from 'react';

import {
  Card,
  Container,
  Grid,
  Button,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Dialog,
  Paper,
  TextField,
  FormControl,
  Select,
  MenuItem,
  CardMedia,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import DetailIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import {Autocomplete} from '@material-ui/lab';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {ROUTE, IMAGE_TYPE, ROWS_PER_PAGE_OPTIONS, MAX_SIZE_OUTPUT, EXPORT_LIMIT_NUMBER_ROWS, EXPORT_ALERT_NUMBER_ROWS, PERMISSION_ACTIONS} from '../../../common/constant';
import CSVExporter from '../../../components/CSVExporter';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getListSupplier} from '../../../stores/common/actions';
import {searchListImage, deleteImageMasters, exportImage} from '../../../stores/image/action';
import {setMessageModal} from '../../../stores/modal/actions';
import {onChangeSelect, onChangeTextField, changeUrlParams, getUrlParams, getQueryStringFromObject} from '../../../utils/common';
import {modalObj} from '../../../utils/modal';
import {isRoleBusiness} from '../../../utils/role';

const styleImage = {
  width: '100px',
  height: '100px',
  borderBottom: '1px solid #f1ecec',
  boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
};

/**
 * Image Master Search Component
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      businessId: '',
      businessName: '',
      name: '',
      imageId: '',
      type: '',
      message: '',
      listCheckbox: [],
      result: [],
      index: '',
      isSearch: false,
      flagDelete: false,
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,
      flagExport: false,
      confirmedExport: false,
      latestSearchParams: null,
    };
    this.handleButtonOk = this.handleButtonOk.bind(this);
    this.handleButtonCancel = this.handleButtonCancel.bind(this);
    this.exportCSVRef = React.createRef();
    const {t} = this.props;
    this.exportHeaders = [
      {label: t('businessInformation.id'), key: 'supplier_code'},
      {label: t('businessInformation.id'), key: 'supplier_name'},
      {label: t('businessImage.id'), key: 'image_id'},
      {label: t('businessImage.type'), key: 'type'},
      {label: t('common.name'), key: 'name'},
      {label: t('businessImage.image'), key: 'url'},
    ];
  }

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    await this.props.getListSupplier();
    if (isRoleBusiness()) {
      const supplier = this.props.common.supplier_list.find((item) => item.id === this.props.principal.supplier_id);
      this.setState({
        businessName: supplier.supplier_name,
      });
    }
    if (this.props.location.search) {
      this.setState(getUrlParams(this.props.location.search));
    }
  }

  /**
   * componentDidUpdate
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.props.actions !== prevProps.actions) {
      const canSearch = this.props.actions.includes(PERMISSION_ACTIONS.SEARCH);
      if (canSearch && this.props.location.search) {
        this.setState(getUrlParams(this.props.location.search), this.handleSearchImage);
      }
    }
  }

  /**
   * handleButtonCancel
   * @param {event} value
   */
  changeBusiness = (value) => {
    this.setState({businessName: value});
  };
  /**
   * handleDelete
   * @param {int} id
   * @param {object} index
   */
  handleDelete = (id, index) => {
    this.setState({
      flagDelete: true,
      message: 'messageCode.deleteConfirmImageMaster',
      id,
      index,
    });
  };

  /**
   * handleButtonOk
   */
  handleButtonOk() {
    this.setState({
      flagDelete: false,
    });
    this.props.deleteImageMasters(this.state.id, this.props).then(() => {
      const result = this.state.result;
      result.splice(this.state.index, 1);
    });
  }

  /**
   * handleButtonCancel
   */
  handleButtonCancel() {
    this.setState({
      flagDelete: false,
    });
  }

  /**
   * reset
   */
  reset = () => {
    this.setState({
      imageId: '',
      type: '',
      businessId: '',
      businessName: isRoleBusiness() ? this.props.common.supplier_list.find((item) => item.id === this.props.principal.supplier_id).supplier_name : '',
      name: '',
    });
  };

  /**
   * handleSearchImage
   * @param {bool} reset
   *
   */
  handleSearchImage(reset) {
    const canSearch = this.props.actions.includes(PERMISSION_ACTIONS.SEARCH);
    if (!canSearch) return;

    const {imageId, type, businessId, name, businessName} = this.state;
    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const {currentPage, rowsPerPage} = this.state;
    const queryParams = {
      image_id: imageId ? imageId.trim() : '',
      type: type ? type : '',
      supplier_id: businessId ? businessId.trim() : '',
      supplier_name: businessName ? businessName : '',
      name: name ? name.trim() : '',
      ...(reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage}),
    };

    this.props.searchListImage(queryParams).then((response) => {
      if (!response) {
        response = [];
      }
      this.setState(
        {
          result: response?.content,
          totalRows: response?.totalSize,
          isSearch: true,
          latestSearchParams: queryParams,
        },
        () => {
          const {imageId, type, businessId, name, businessName, currentPage, rowsPerPage} = this.state;
          // Apply changed params into url
          const queryParamsToChange = {
            imageId,
            type,
            businessId,
            name,
            businessName,
            currentPage: String(currentPage),
            rowsPerPage,
          };
          changeUrlParams(queryParamsToChange);
          const newSearchParams = getQueryStringFromObject(queryParamsToChange);
          // Save search params into state in order to pass to next page
          this.setState({searchParams: newSearchParams});
        },
      );
    });
  }

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearchImage);
  };

  /**
   * Fetch data to export
   */
  fetchDataExport = async () => {
    const {t} = this.props;
    const dataExport = await this.props.exportImage({
      ...this.state.latestSearchParams,
      page: 0,
      size: MAX_SIZE_OUTPUT,
  });
    const convertedData = dataExport.map((row, index) => {
      row.type = t(IMAGE_TYPE.find(({name, i18n}) => `${row.type}` === name)?.name);
      return row;
    });
    this.setState({confirmedExport: false});
    return convertedData;
  };

  /**
   * alertExportCSV
   */
  alertExportCSV = () => {
    const {t} = this.props;
    if (this.state.totalRows > EXPORT_LIMIT_NUMBER_ROWS) {
      this.props.setMessageModal(modalObj(true, t('messageCode.exportAlertLimit')));
    } else {
      const message = t('messageCode.exportAlert');
      this.setState({flagExport: true, message});
    }
  };

  /**
   * handleButtonExportOk
   */
  handleButtonExportOk = () => {
    this.setState({flagExport: false, confirmedExport: true}, () => {
      this.exportCSVRef.current.onClickExport();
    });
  };

  /**
   * handleButtonExportCancel
   */
  handleButtonExportCancel = () => {
    this.setState({flagExport: false});
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t, image, common, actions} = this.props;
    const businessNameList = common?.supplier_list?.map((item) => item?.supplier_name);
    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canCSVOutput: actions.includes(PERMISSION_ACTIONS.CSV_OUTPUT),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
      canDelete: actions.includes(PERMISSION_ACTIONS.DELETE),
      canRegister: actions.includes(PERMISSION_ACTIONS.REGISTER),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.imageMaster')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
        </Container>
        <br></br>
        <LoadingOverlay active={image.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                {permission.canSearch && (
                  <>
                    <br></br>
                    <Paper className="search_table">
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                          {t('businessVehicle.searchTitle')}
                        </Grid>
                      </Grid>

                      {/* Business Id */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('businessInformation.id')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="businessId"
                            className="field_size_10 field_min_size_250"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('businessInformation.id')})}
                            value={this.state.businessId}
                            onChange={(event) => onChangeTextField(this, event)}
                            inputProps={{maxLength: 256}}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>

                      {/* Business name */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('businessVehicle.businessName')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <Autocomplete
                            margin="dense"
                            className="field_size_20 field_min_size_250"
                            options={businessNameList}
                            value={this.state.businessName}
                            getOptionLabel={(option) => option}
                            onChange={(event, business) => {
                              this.changeBusiness(business);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                name={'businessName'}
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                placeholder={t('placeholder.required', {field: t('businessVehicle.businessName')})}
                              />
                            )}
                            disabled={isRoleBusiness()}
                          />
                        </Grid>
                      </Grid>

                      {/* Image Id */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('businessImage.id')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="imageId"
                            className="field_size_10 field_min_size_250"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('businessImage.id')})}
                            value={this.state.imageId}
                            onChange={(event) => onChangeTextField(this, event)}
                            inputProps={{maxLength: 256}}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>

                      {/* Type */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('businessImage.type')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_250">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'type',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.type ?
                                undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('businessImage.type'),
                                        })}
                                      </div>
                                  )
                              }
                              value={this.state.type}
                              onChange={(event) => onChangeSelect(this, event)}
                            >
                              {IMAGE_TYPE.map(({name, i18n}, idx) => {
                                return (
                                  <MenuItem value={name} key={idx}>
                                    {t(`${i18n}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Name */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.name')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="name"
                            className="field_size_20 field_min_size_250"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('common.name')})}
                            value={this.state.name}
                            onChange={(event) => onChangeTextField(this, event)}
                            inputProps={{maxLength: 256}}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </>
                )}
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.reset} endIcon={<RotateLeftIcon />}>
                        {t('common.btnResetSearch')}
                      </Button>
                    )}
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {permission.canRegister && (
                      <Link
                        style={{textDecoration: 'none'}}
                        to={{pathname: ROUTE.LAYOUT + ROUTE.IMAGE_ADD, state: {from: this.props.location.pathname + '?' + this.state.searchParams}}}
                      >
                        <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />}>
                          {t('common.btnRegister')}
                        </Button>
                      </Link>
                    )}
                    {permission.canCSVOutput && permission.canSearch && (
                      <>
                        <div className={this.state.totalRows <= EXPORT_ALERT_NUMBER_ROWS || this.state.confirmedExport ? '' : 'hidden'}>
                          <CSVExporter
                            ref={this.exportCSVRef}
                            disabled={this.state.totalRows <= 0 || !this.state.latestSearchParams}
                            headers={this.exportHeaders}
                            fetchAction={this.fetchDataExport}
                            isFetchAsync={true}
                            screenName={t('accountManagement.title')}
                          />
                        </div>
                        {this.state.totalRows >= EXPORT_ALERT_NUMBER_ROWS && !this.state.confirmedExport && (
                          <Button
                            onClick={this.alertExportCSV}
                            disabled={this.state.totalRows <= 0}
                            color="primary"
                            variant="contained"
                            className="button_margin"
                            endIcon={<GetAppIcon className="csv-exporter-icon" />}
                          >
                            {t('calendar.csv_output')}
                          </Button>
                        )}
                      </>
                    )}
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin" endIcon={<SearchIcon />} onClick={() => this.handleSearchImage(true)}>
                        {t('common.btnSearch')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>
          <Container maxWidth="xl">
            {this.state.isSearch && (
              <Card raised>
                <Container maxWidth="xl">
                  <br></br>
                  <Grid container spacing={1}>
                    <Grid container alignItems="center" justify="flex-start" item xs={3}>
                      <h3>
                        {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                      </h3>
                    </Grid>
                  </Grid>

                  <div className="scroll_area_700">
                    {this.state.result.length > 0 && (
                      <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('businessInformation.id')}</TableCell>
                            <TableCell>{t('businessVehicle.businessName')}</TableCell>
                            <TableCell>{t('businessImage.id')}</TableCell>
                            <TableCell>{t('businessImage.type')}</TableCell>
                            <TableCell>{t('common.name')}</TableCell>
                            <TableCell>{t('businessImage.image')}</TableCell>
                            {(permission.canEdit || permission.canEdit) && (
                              <TableCell>{t('common.action')}</TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.result.map((row, index) => (
                            <TableRow key={index} hover className="cursor_pointer">
                              <TableCell>{row.supplier_code}</TableCell>
                              <TableCell>{row.supplier_name}</TableCell>
                              <TableCell>{row.image_id}</TableCell>
                              <TableCell>{t(IMAGE_TYPE.find(({name, i18n}) => `${row.type}` === name)?.name)}</TableCell>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>
                                <CardMedia style={styleImage} className="card_media_size" image={`${row.url}?${(new Date()).getTime()}`} title="アクティビティ" />
                              </TableCell>
                              {(permission.canEdit || permission.canDelete) && (
                                <TableCell>
                                  {permission.canEdit && (
                                    <Link
                                      style={{textDecoration: 'none'}}
                                      to={{pathname: ROUTE.LAYOUT + ROUTE.IMAGE_FROM + '/' + row.id, state: {from: this.props.location.pathname + '?' + this.state.searchParams}}}
                                    >
                                      <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                        {t('common.btnEdit')}
                                      </Button>
                                    </Link>
                                  )}
                                  {permission.canDelete && (
                                    <Button
                                      color="primary"
                                      variant="contained"
                                      className="button_margin button_color_red"
                                      endIcon={<DeleteIcon />}
                                      onClick={() => this.handleDelete(row.id, index)}
                                    >
                                      {t('common.btnDelete')}
                                    </Button>
                                  )}
                                </TableCell>
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    )}
                  </div>
                  <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                </Container>
                <br></br>
                <Dialog
                  open={this.state.flagDelete}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    onClickOk={this.handleButtonOk}
                    onClickCancel={this.handleButtonCancel}
                    message={this.state.message}
                    okButtonText={t('common.btnYes')}
                    cancelButtonText={t('common.btnNo')}
                  ></SelectModal>
                </Dialog>
                {/* Export alert modal */}
                <Dialog
                  open={this.state.flagExport}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    onClickOk={this.handleButtonExportOk}
                    onClickCancel={this.handleButtonExportCancel}
                    okButtonText={t('common.btnYes')}
                    cancelButtonText={t('common.btnNo')}
                    message={this.state.message}
                  ></SelectModal>
                </Dialog>
              </Card>
            )}
            <br></br>
          </Container>
        </LoadingOverlay>
        <br></br>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    image: state.image,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchListImage: (params) => dispatch(searchListImage(params)),
    exportImage: (params) => dispatch(exportImage(params)),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    deleteImageMasters: (id, props) => dispatch(deleteImageMasters(id, props)),
    getListSupplier: () => dispatch(getListSupplier()),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
