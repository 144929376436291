import {
  sendMailApi,
  getRoleListApi,
  searchAccountApi,
  getAccountDetailApi,
  updateAccountApi,
  getRolePermissionListApi,
  resendInvitationApi,
  restartAccountsApi,
  suspendAccountsApi,
  getRoleListPermissionBySupplierIdApi,
  getAllAccountTypesApi,
} from '../../services/accountServices';
import {modalObj} from '../../utils/modal.js';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

export const failSearchAccountListAction = () => {
  return {
    type: ACTION_TYPES.SEARCH_ACCOUNT_FAIL,
  };
};

/**
 * Send Invite Mail
 * @param {object} body
 * @param {object} props
 * @return {object}
 */
export const sendMail = (body) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.INVITE_MAIL_START});
    return sendMailApi(body).then(
        (response) => {
          if (response && response.statusCode === 200) {
            dispatch({type: ACTION_TYPES.INVITE_MAIL_SUCCESS});
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.INVITE_MAIL_FAIL});
            return response.result;
          }
        },
        (error) => {
          dispatch({type: ACTION_TYPES.INVITE_MAIL_FAIL});
          dispatch(setMessageModal(modalObj(true, error.message_code)));
        },
    );
  };
};

export const getRoleList = () => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.ROLE_LIST_START});
    return getRoleListApi().then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.ROLE_LIST_SUCCESS,
              roleList: response.result,
            });
            return response.result;
          } else {
            dispatch({type: ACTION_TYPES.INVITE_MAIL_FAIL});
          }
        },
        (error) => dispatch({type: ACTION_TYPES.INVITE_MAIL_FAIL}),
    );
  };
};

export const getRoleListPermission = () => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.ROLE_PERMISSION_LIST_START});
    return getRolePermissionListApi().then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.ROLE_PERMISSION_LIST_SUCCESS,
              roleListPermission: response.result,
            });
            return response.result;
          } else {
            dispatch({type: ACTION_TYPES.ROLE_PERMISSION_LIST_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.ROLE_PERMISSION_LIST_FAIL}),
    );
  };
};

/**
 * getRoleListPermissionBySupplierId
 * @param {number} supplierId
 * @return {Promise}
 */
export const getRoleListPermissionBySupplierId = (supplierId) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.ROLE_PERMISSION_LIST_BY_SUPPLIER_ID_START});
    return getRoleListPermissionBySupplierIdApi(supplierId).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({type: ACTION_TYPES.ROLE_PERMISSION_LIST_BY_SUPPLIER_ID_SUCCESS});
            return response.result;
          } else {
            dispatch({type: ACTION_TYPES.ROLE_PERMISSION_LIST_BY_SUPPLIER_ID_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.ROLE_PERMISSION_LIST_FAIL}),
    );
  };
};

/**
 *
 * @param {*} input
 * @param {*} queryParams
 * @param {*} props
 * @return {Promise}
 */
export const searchAccountList = (input, queryParams) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.SEARCH_ACCOUNT_START});
    return searchAccountApi(input, queryParams).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.SEARCH_ACCOUNT_SUCCESS,
              accountList: response.result,
            });
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch(failSearchAccountListAction());
          }
        },
        () => dispatch(failSearchAccountListAction()),
    );
  };
};

/**
 * Export list account
 * @param {object} payload
 * @param {object} queryParams
 * @return {object}
 */
export const exportAccount = (payload, queryParams) => {
  return (dispatch) => {
    return searchAccountApi(payload, queryParams).then(
        (response) => {
          if (response && response.status === 200) {
            return response.result?.content;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
          }
        },
        () => dispatch(setMessageModal(modalObj(true, 'Internal Server Error'))),
    );
  };
};

export const getAccountDetail = (id) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.DETAIL_ACCOUNT_START});
    return getAccountDetailApi(id).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.DETAIL_ACCOUNT_SUCCESS,
              accountDetail: response.result,
            });
            return response.result;
          } else {
            dispatch({type: ACTION_TYPES.DETAIL_ACCOUNT_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.DETAIL_ACCOUNT_FAIL}),
    );
  };
};

export const updateAccount = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.UPDATE_ACCOUNT_START});
    return updateAccountApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.UPDATE_ACCOUNT_SUCCESS,
              updateAccount: response.result,
            });
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.UPDATE_ACCOUNT_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.UPDATE_ACCOUNT_FAIL}),
    );
  };
};

/**
 *
 * @param {object} payload
 * @param {object} props
 * @return {Promise}
 */
export const resendInvitation = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.RESEND_INVITATION_START});
    return resendInvitationApi(payload).then((response) => {
      if (response && response.status === 200) {
        dispatch({type: ACTION_TYPES.RESEND_INVITATION_SUCCESS});
        dispatch(setMessageModal(modalObj(true, response.message_code)));
      } else {
        dispatch(setMessageModal(modalObj(true, response.message_code)));
        dispatch({type: ACTION_TYPES.RESEND_INVITATION_FAIL});
      }
    });
  };
};

/**
 *
 * @param {object} payload
 * @param {object} props
 * @return {Promise}
 */
export const restartAccounts = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.RESTART_ACCOUNTS_START});
    return restartAccountsApi(payload).then((response) => {
      if (response && response.status === 200) {
        dispatch({type: ACTION_TYPES.RESTART_ACCOUNTS_SUCCESS});
        dispatch(setMessageModal(modalObj(true, response.message_code)));
      } else {
        dispatch(setMessageModal(modalObj(true, response.message_code)));
        dispatch({type: ACTION_TYPES.RESTART_ACCOUNTS_FAIL});
      }
    });
  };
};

/**
 *
 * @param {object} payload
 * @param {object} props
 * @return {Promise}
 */
export const suspendAccounts = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.SUSPEND_ACCOUNTS_START});
    return suspendAccountsApi(payload).then((response) => {
      if (response && response.status === 200) {
        dispatch({type: ACTION_TYPES.SUSPEND_ACCOUNTS_SUCCESS});
        dispatch(setMessageModal(modalObj(true, response.message_code)));
      } else {
        dispatch(setMessageModal(modalObj(true, response.message_code)));
        dispatch({type: ACTION_TYPES.SUSPEND_ACCOUNTS_FAIL});
      }
    });
  };
};

/**
 * Get all account types belong to current business
 * @return {Promise}
 */
export const getAllAccountTypes = () => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_ALL_ACCOUNT_TYPES_START});
    return getAllAccountTypesApi().then(
      (response) => {
        if (response?.status === 200) {
          dispatch({type: ACTION_TYPES.GET_ALL_ACCOUNT_TYPES_SUCCESS, payload: response.result});
        } else {
          dispatch({type: ACTION_TYPES.GET_ALL_ACCOUNT_TYPES_FAIL});
          dispatch(setMessageModal(modalObj(true, response ? response.message_code : 'error.500')));
        }
      },
      () => {
        dispatch({type: ACTION_TYPES.GET_ALL_ACCOUNT_TYPES_FAIL});
        dispatch(setMessageModal(modalObj(true, 'error.501')));
      },
    );
  };
};
