import {ROUTE} from '../../common/constant';
import {getListImageApi, getImageApi, createImageApi, updateImageApi, deleteImageMasterApi} from '../../services/businessServices';
import {backForwardRouter} from '../../utils/common';
import {modalObj} from '../../utils/modal.js';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

/**
 * search list business
 * @param {object} queryParams
 * @return {object}
 */
export const searchListImage = (queryParams) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.SEARCH_LIST_BUSINESS_IMAGE_START});
    return getListImageApi(queryParams).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.SEARCH_LIST_BUSINESS_IMAGE_SUCCESS,
              listImage: response.result.content,
            });
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.SEARCH_LIST_BUSINESS_IMAGE_FAIL,
              error: response.message,
            });
          }
        },
        () => dispatch({
          type: ACTION_TYPES.SEARCH_LIST_BUSINESS_IMAGE_FAIL,
          error: 'Internal Server Error',
        }),
    );
  };
};

/**
 * Export CSV list image
 * @param {object} queryParams
 * @return {object}
 */
export const exportImage = (queryParams) => {
  return (dispatch) => {
    return getListImageApi(queryParams).then(
        (response) => {
          if (response && response.status === 200) {
            return response.result?.content;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
          }
        },
        () => dispatch(setMessageModal(modalObj(true, 'Internal Server Error'))),
    );
  };
};

/**
 * get detail business
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const getDetailImage = (params, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_DETAIL_BUSINESS_IMAGE_START});
    return getImageApi(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_DETAIL_BUSINESS_IMAGE_SUCCESS,
              detailImage: response.result,
            });
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
            dispatch({
              type: ACTION_TYPES.GET_DETAIL_BUSINESS_IMAGE_FAIL,
              error: response.message,
            });
            if (response.message === 'permission.denied') {
              backForwardRouter(props, ROUTE.LAYOUT + ROUTE.HOME);
            }
          }
        },
        () => dispatch({
          type: ACTION_TYPES.GET_DETAIL_BUSINESS_IMAGE_FAIL,
          error: 'Internal Server Error',
        }),
    );
  };
};

/**
 * create new business image master
 * @param {object} payload
 * @param {object} props
 * @return {object}
 */
export const createImageMasters = (payload, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.CREATE_BUSINESS_IMAGE_START});
    return createImageApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.CREATE_BUSINESS_IMAGE_SUCCESS});
            props.history.push(ROUTE.LAYOUT + ROUTE.IMAGE);
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.CREATE_BUSINESS_IMAGE_FAIL,
              error: response.message,
            });
          }
        },
        () => dispatch({
          type: ACTION_TYPES.CREATE_BUSINESS_IMAGE_FAIL,
          error: 'Internal Server Error',
        }),
    );
  };
};

/**
 * update business image master
 * @param {object} payload
 * @param {object} props
 * @return {object}
 */
export const updateImageMasters = (payload, props) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.UPDATE_IMAGE_MASTERS_START});
    return updateImageApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.UPDATE_IMAGE_MASTERS_SUCCESS});
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.UPDATE_IMAGE_MASTERS_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.UPDATE_IMAGE_MASTERS_FAIL}),
    );
  };
};

/**
 * Delete business image master
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const deleteImageMasters = (params) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.DELETE_IMAGE_MASTERS_START});
    return deleteImageMasterApi(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.DELETE_IMAGE_MASTERS_SUCCESS});
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({type: ACTION_TYPES.DELETE_IMAGE_MASTERS_FAIL});
          }
        },
        () => dispatch({type: ACTION_TYPES.DELETE_IMAGE_MASTERS_FAIL}),
    );
  };
};
