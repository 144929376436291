import {api} from './api';

const searchMenuAppUrl = `admin/menu-app/search-with_country`;
const createUpdateMenuAppUrl = `admin/menu-app`;
// const deleteMenuAppUrl = `admin/menu-app/`;
const configureMenuAppUrl = `admin/menu-app/configure`;

const searchMobileFunctionUrl = `admin/mobile-function/search`;
const searchMobileFunctionWithCountryUrl = `admin/mobile-function/search-with-country`;
const createUpdateMobileFunctionUrl = `admin/mobile-function`;
const deleteMobileFunctionUrl = `admin/mobile-function/`;
const configureMobileFunctionUrl = `admin/mobile-function/configure`;

const searchWillerServicesUrl = `admin/willer-services/search-with-country`;
const getServiceLanguageUrl = `admin/service-languages/willer-services/{serviceId}`;
const updateServiceLanguageUrl = `/admin/service-languages`;
const creatWillerServiceUrl = `admin/willer-services`;
const enableWillerServiceUrl = `admin/willer-services/{serviceId}/enable`;
const disableWillerServiceUrl = `admin/willer-services/{serviceId}/disable`;
const deleteWillerServiceUrl = `admin/willer-services/`;
const configureWillerServiceUrl = `admin/willer-services/configure`;
const getAllLanguageUrl = `admin/language`;

export const searchMenuAppApi = (payload) => {
  return api.get(searchMenuAppUrl, payload);
};

export const createMenuAppApi = (payload) => {
  return api.post(createUpdateMenuAppUrl, payload);
};

export const updateMenuAppApi = (payload) => {
  return api.put(createUpdateMenuAppUrl, payload);
};

// export const deleteMenuAppApi = (menuAppId) => {
//   return api.delete(deleteMenuAppUrl + menuAppId);
// };

export const configureMenuAppApi = (payload) => {
  return api.put(configureMenuAppUrl, payload);
};

export const searchMobileFunctionApi = (payload) => {
  return api.put(searchMobileFunctionUrl, payload);
};

export const createMobileFunctionApi = (payload) => {
  return api.post(createUpdateMobileFunctionUrl, payload);
};

export const updateMobileFunctionApi = (payload) => {
  return api.put(createUpdateMobileFunctionUrl, payload);
};

export const deleteMobileFunctionApi = (id) => {
  return api.delete(deleteMobileFunctionUrl + id);
};

export const configureMobileFunctionApi = (payload) => {
  return api.put(configureMobileFunctionUrl, payload);
};

export const searchMobileFunctionWithCountryApi = (payload) => {
  return api.get(searchMobileFunctionWithCountryUrl, payload);
};

export const searchWillerServicesApi = (payload) => {
  return api.get(searchWillerServicesUrl, payload);
};

export const getServiceLanguageApi = (serviceId) => {
  return api.get(getServiceLanguageUrl.replace('{serviceId}', serviceId), {});
};

export const updateServiceLanguageApi = (payload) => {
  return api.post(updateServiceLanguageUrl, payload);
};

export const createWillerServiceApi = (payload) => {
  return api.post(creatWillerServiceUrl, payload);
};

export const enableWillerServiceApi = (serviceId) => {
  return api.put(enableWillerServiceUrl.replace('{serviceId}', serviceId), {});
};

export const disableWillerServiceApi = (serviceId) => {
  return api.put(disableWillerServiceUrl.replace('{serviceId}', serviceId), {});
};

export const deleteWillerServiceApi = (serviceId) => {
  return api.delete(deleteWillerServiceUrl + serviceId);
};

export const configureWillerServiceApi = (payload) => {
  return api.put(configureWillerServiceUrl, payload);
};

export const getAllLanguageApi = () => {
  return api.get(getAllLanguageUrl);
};
