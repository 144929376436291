import React, {Component} from 'react';

import {FormGroup, FormControlLabel, Checkbox, Grid, Button, TextField, Paper, Card, Container, FormControl, Select, MenuItem, FormHelperText, Dialog} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {ROUTE, BUSINESS_STATUS, BUSINESS_TYPE_OLD, LANGUAGE, PERMISSION_ACTIONS, BUSINESS_ROLE} from '../../../common/constant';
import Memo from '../../../components/memo';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getDetailBusiness, updateBusiness, createBusiness} from '../../../stores/business/action';
import {getListGeofenceByCountry, getAllCountryCode} from '../../../stores/common/actions';
import {onChangeSelect, onChangeTextField, onChangeListData, backForwardRouter, getListAllGeofenceDetail} from '../../../utils/common';
import {isRoleBusiness, isRoleCountry, isRoleGlobal} from '../../../utils/role';

/**
 * Business Detail views
 */
class FormBusinessInformation extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      flag: false,
      checkDetail: false,
      id: null,
      businessId: '',
      status: '',
      type: '',
      businessCode: '',
      businessName: '',
      roleOfBusiness: '',
      geofenceOfBusiness: [],
      checkDisabledRole: false,
      business_role_type: !isRoleGlobal() ? ( props.match.params.id ? '' : 'BUSINESS') : '',
      country: !isRoleGlobal() ? props.principal.country_id : '',
      list_area_id: [],
      address: '',
      mobile: '',
      language: '',
      email: '',
      memos: [],
      note: '',
      isSubmitForm: false,
      isDisableButtonCreate: false,
      dataDetail: [],
    };
    this.handleButtonUpdateOk = this.handleButtonUpdateOk.bind(this);
    this.handleButtonUpdateCancel = this.handleButtonUpdateCancel.bind(this);
    this.validator = new SimpleReactValidator();
  }

  /**
   * onChangeMemo
   * @param {int} index
   * @param {string} value
   */
  onChangeMemo = (index, value) => {
    const memoCopy = this.state.memos;
    memoCopy[index].content = value.trim();
    this.setState({rows: memoCopy});
  };

  /**
   * onChangeBusiness
   * @param {string} value
   */
  onChangeBusiness = (value) => {
    this.setState({
      businessName: value,
    });
  };

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllCountryCode();
    this.state.country && this.props.getListGeofenceByCountry(this.state.country);
    if (this.props.match.path === ROUTE.LAYOUT + ROUTE.BUSINESS_DETAIL + '/:id') {
      await this.props.getDetailBusiness(this.props.match.params.id).then((result) => {
        if (result) {
          this.setState({
            id: result.id,
            businessId: result.id,
            status: result.supplier_status,
            type: result.supplier_type,
            businessCode: result.supplier_id,
            businessName: result.supplier_name,
            business_role_type: result.business_role_type,
            country: result?.country?.id,
            list_area_id: result.geofence_ids ? result.geofence_ids : [],
            address: result.supplier_address,
            mobile: result.supplier_tel,
            language: result.supplier_language,
            email: result.supplier_email,
            memos: result.memos,
            checkDetail: true,
            checkDisabledRole: this.props.match.params.id && result.business_role_type !== 'GLOBAL',
            roleOfBusiness: result.business_role_type,
            geofenceOfBusiness: result.geofence_ids,
            dataDetail: result,
          });
          result?.country_ids && this.props.getListGeofenceByCountry(result?.country_ids[0]);
        } else {
          this.props.history.push(ROUTE.LAYOUT + ROUTE.BUSINESS_INFORMATION);
        }
      }, () => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.BUSINESS_INFORMATION));
    }
  }

  /**
   * componentDidUpdate
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.state.country && prevState.country !== this.state.country) {
      this.props.getListGeofenceByCountry(this.state.country);
    }
  }

  /**
   * onChangeCountry
   * @param {*} e
   */
  onChangeCountry = async (e) => {
    this.setState({
      country: e.target.value,
      list_area_id: [],
    });
    await this.props.getListGeofenceByCountry(e.target.value);
  }

  /**
   * changeAreaBy
   * @param {*} value
   * @param {*} item
   */
  changeAreaBy = (value, item) => {
    const list_area_id_copy = [...this.state.list_area_id];
    const index = list_area_id_copy.indexOf(item);
    value ? list_area_id_copy.push(item) : list_area_id_copy.splice(index, 1);
    this.setState({
      list_area_id: list_area_id_copy,
    });
  };

  /**
   * this is common process checkbox for country & language
   * @param {*} id
   * @param {*} name
   */
  onChangeCheckBox(id, name) {
    let newValue = [];
    if (this.state[name].includes(id)) {
      newValue = this.state[name].filter((value) => value !== id);
    } else {
      newValue = [...this.state[name], id];
    }
    this.setState({[name]: newValue});
  }

  /**
   * handleUpdate
   */
  handleUpdate = () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid() && this.checkCanRegister(this.state.business_role_type)) {
      this.setState({
        flag: true,
        message: 'business.question.confirm.update',
      });
    }
  };

  /**
   * handleButtonCancel
   */
  handleButtonUpdateCancel() {
    this.setState({
      flag: false,
    });
  }

  /**
   * handleButtonOk
   */
  handleButtonUpdateOk() {
    this.setState({
      flag: false,
      isDisableButtonCreate: true,
    });
    const state = this.state;
    const payload = {
      id: state.id,
      supplier_name: state.businessName.trim(),
      business_role_type: state.business_role_type,
      country_id: state.business_role_type !== 'GLOBAL' ? state.country : '',
      geofence_ids: state.business_role_type === 'BUSINESS' ? state.list_area_id : [],
      supplier_status: state.status,
      supplier_address: state.address.trim(),
      supplier_tel: state.mobile.trim(),
      supplier_email: state.email.trim(),
      supplier_id: state.businessCode,
      supplier_language: state.language,
      memos: state.memos,
      note: state.note.trim(),
    };
    this.props.updateBusiness(payload, this.props).then(() => {
      this.setState({
        isDisableButtonCreate: false,
      });
      this.props.getDetailBusiness(this.props.match.params.id).then((result) => {
        if (result) {
          this.setState({
            memos: result.memos,
          });
        }
      });
    });
    this.props.getDetailBusiness(this.props.match.params.id);
  }

  handleClickCreateButton = () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid() && this.checkCanRegister(this.state.business_role_type)) {
      this.setState({flag: true, message: this.props.t('business.question.confirm.create')});
    }
  };

  /**
   * checkCanRegister
   * @param {*} role
   * @return {*}
   */
  checkCanRegister = (role) => {
    if (role === 'GLOBAL') return true;
    return role === 'COUNTRY' ? !!this.state.country : (!!this.state.country && this.state.list_area_id.length > 0);
  }

  /**
   * handleCreate
   */
  handleCreate = () => {
    const state = this.state;
    this.setState({isDisableButtonCreate: true});
    const payload = {
      business_role_type: state.business_role_type,
      country_id: state.business_role_type !== 'GLOBAL' ? state.country : '',
      geofence_ids: state.business_role_type === 'BUSINESS' ? state.list_area_id : [],
      supplier_name: state.businessName.trim(),
      supplier_tel: state.mobile.trim(),
      supplier_address: state.address.trim(),
      supplier_type: state.type ? state.type : null,
      supplier_language: state.language,
      supplier_email: state.email.trim(),
      supplier_status: state.status ? state.status : null,
    };
    this.props.createBusiness(payload, this.props).then((response) => {
      this.setState({isDisableButtonCreate: false});
    });
  };

  /**
   * getListGeofence
   * @return {Array}
   */
  getListGeofence = () => {
    const {common} = this.props;
    const listGeofences =
      this.props.match.params.id && this.state.dataDetail?.country?.id === this.state.country ?
        getListAllGeofenceDetail(common?.geofence_by_country, this.state.dataDetail?.geofence_config_infos) :
        common?.geofence_by_country;
    return listGeofences;
  }

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {business, t, country_code, actions} = this.props;
    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
    };
    const listGeofences = this.getListGeofence();
    return (
      <LoadingOverlay active={business.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                {this.state.checkDetail ? <h3>{t('route.businessManagement.detail')}</h3> : <h3>{t('route.businessManagement.add')}</h3>}
              </Grid>
            </Grid>
          </Container>
          <br></br>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('businessDetail.form')}
                    </Grid>
                  </Grid>
                  {this.state.checkDetail && (
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('businessInformation.code')} <span className="font_color_red">＊</span>
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <TextField
                          name="businessCode"
                          className="field_size_10 field_min_size_150"
                          margin="dense"
                          variant="outlined"
                          value={this.state.businessCode}
                          onChange={(event) => onChangeTextField(this, event)}
                          disabled={this.state.checkDetail}
                        />
                      </Grid>
                    </Grid>
                  )}

                  {/* Business Type */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessInformation.type')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'type',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.type ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('businessInformation.type'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.type}
                          onChange={(event) => onChangeSelect(this, event)}
                        >
                          {BUSINESS_TYPE_OLD.map(({id, i18n}, idx) => {
                            return (
                              <MenuItem value={id} key={idx}>
                                {t(`${i18n}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('type', this.state.type, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.type, 'required') && (
                          <FormHelperText id="type" error>
                            {t('validation.required.choose', {field: t('businessInformation.type')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Business Status */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessInformation.status')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'status',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.status ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('businessInformation.status'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.status}
                          onChange={(event) => onChangeSelect(this, event)}
                        >
                          {BUSINESS_STATUS.map(({id, i18n}, idx) => {
                            return (
                              <MenuItem value={id} key={idx}>
                                {t(`${i18n}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('status', this.state.status, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.status, 'required') && (
                          <FormHelperText id="status" error>
                            {t('validation.required.choose', {field: t('businessInformation.status')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Business name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessInformation.name')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl>
                        <div className="box-group-input">
                          <TextField
                            name="businessName"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('businessInformation.name')})}
                            variant="outlined"
                            value={this.state.businessName}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 255})}</div>
                        </div>
                        {this.validator.message('businessName', this.state.businessName, 'required|max:255')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.businessName, 'required') && (
                          <FormHelperText id="businessName" error>
                            {t('validation.required', {field: t('businessInformation.name')})}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm && this.validator.check(this.state.businessName, 'required') && !this.validator.check(this.state.businessName, 'max:255') && (
                          <FormHelperText id="businessName" error>
                            {t('errorFields.textLength', {value: 255})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Business Role */}
                  <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('businessInformation.role')} <span className="font_color_red">＊</span>
                      </Grid>
                      <Grid container alignItems="center" item xs={6}>
                        <FormControl variant="outlined" margin="dense">
                          <Select
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            inputProps={{
                              name: 'business_role_type',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.business_role_type ?
                                undefined :
                                () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('businessInformation.role'),
                                    })}
                                  </div>
                                )
                            }
                            value={this.state.business_role_type}
                            onChange={(event) => onChangeSelect(this, event)}
                            disabled={this.props.match.params.id || (!isRoleGlobal())}
                          >
                            {(isRoleCountry() ? BUSINESS_ROLE.filter((item) => item.id !== 'GLOBAL') : BUSINESS_ROLE).map((item, idx) => {
                              return (
                                <MenuItem value={item.id} key={idx}>
                                  {t(item.i18n)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {this.state.business_role_type && this.state.business_role_type !== 'BUSINESS' &&
                            <FormHelperText id="business_role_type">
                              <b style={{color: 'black', fontSize: '14px', marginTop: '10px'}}><i>{t('businessInformation.cannotRegisterSaleOffice')}</i></b>
                            </FormHelperText>
                          }
                          {this.validator.message('business_role_type', this.state.business_role_type, 'required')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.business_role_type, 'required') && (
                            <FormHelperText id="business_role_type" error>
                              {t('validation.required.choose', {field: t('businessInformation.role')})}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                    </Grid>

                  {/* Business country */}
                  {(this.state.business_role_type === 'COUNTRY' || this.state.business_role_type === 'BUSINESS') &&
                    <>
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.country')} <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'country',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.country ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('common.country'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.country}
                              onChange={(e) => this.onChangeCountry(e)}
                              disabled={!isRoleGlobal() ||
                              (this.props.match.params.id && isRoleGlobal() && this.state.roleOfBusiness !== 'GLOBAL')
                              }
                            >
                              {country_code?.map((item, idx) => {
                                return (
                                  <MenuItem value={item.id} key={idx}>
                                    {t(item.country_code)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            {this.state.isSubmitForm && !this.state.country && (
                              <FormHelperText id="country" error>
                                {t('validation.required.choose', {field: t('common.country')})}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </>
                  }

                  {/* Geofence */}
                  {this.state.business_role_type === 'BUSINESS' &&
                    <>
                      <Grid container spacing={1} className="row_form_item" style={{paddingBottom: 12}}>
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('business_plan.geofence')} <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={6} className="grid_title_padding">
                          <FormControl error>
                            <FormGroup row>
                              {this.state.country !== '' &&
                                listGeofences.map((item, index) => {
                                  const checked = this.state.list_area_id?.indexOf(item.geofence_id) > -1;
                                  return (
                                    <FormControlLabel
                                      key={index}
                                      labelPlacement="end"
                                      control={
                                        <Checkbox
                                          disabled={item.enable === false || item.enable_for_admin === false || (isRoleBusiness()) ||
                                          (this.props.match.params.id && !isRoleBusiness() && this.state.roleOfBusiness === 'BUSINESS' &&
                                            this.state.geofenceOfBusiness.includes(item.geofence_id))
                                        }
                                          checked={checked}
                                          onClick={(event) => this.changeAreaBy(event.target.checked, item.geofence_id)}
                                          className="checkbox_radio"
                                        />
                                      }
                                      label={item.name}
                                    />
                                  );
                                })}
                            </FormGroup>
                            {this.state.isSubmitForm && this.state.list_area_id.length <= 0 && (
                              <FormHelperText id="list_area_id" error>
                                {t('validation.required.choose', {field: t('business_plan.geofence')})}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </>
                  }

                  {/* Business address */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.address')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={6}>
                      <FormControl>
                        <div className="box-group-input">
                          <TextField
                            name="address"
                            className="field_size_10 field_min_size_600"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('common.address')})}
                            variant="outlined"
                            value={this.state.address}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 255})}</div>
                        </div>
                        {this.validator.message('address', this.state.address, 'required|max:255')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.address, 'required') && (
                          <FormHelperText id="address" error>
                            {t('validation.required', {field: t('common.address')})}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm && this.validator.check(this.state.address, 'required') && !this.validator.check(this.state.address, 'max:255') && (
                          <FormHelperText id="address" error>
                            {t('errorFields.textLength', {value: 255})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Business phone number */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.phoneNumber')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl>
                        <div className="box-group-input">
                          <TextField
                            name="mobile"
                            className="field_size_20 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('common.phoneNumber')})}
                            variant="outlined"
                            value={this.state.mobile}
                            onChange={(event) => onChangeTextField(this, event)}
                            inputProps={{maxLength: 11}}
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 11})}</div>
                        </div>
                        {this.validator.message('mobile', this.state.mobile, 'required|numeric|between:10,11,string')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.mobile, 'required') && (
                          <FormHelperText id="mobile" error>
                            {t('validation.required', {field: t('common.phoneNumber')})}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm && this.validator.check(this.state.mobile, 'required') && !this.validator.check(this.state.mobile, 'numeric') && (
                          <FormHelperText id="mobile" error>
                            {t('validation.phone')}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm &&
                          this.validator.check(this.state.mobile, 'required') &&
                          this.validator.check(this.state.mobile, 'numeric') &&
                          !this.validator.check(this.state.mobile, 'between:10,11,string') && (
                            <FormHelperText id="mobile" error>
                              電話番号は10桁から11桁で入力してください
                            </FormHelperText>
                          )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Business language */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessDetail.languages')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'language',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.language ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('businessDetail.languages'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.language}
                          onChange={(event) => onChangeSelect(this, event)}
                        >
                          {LANGUAGE.map((item, idx) => {
                            return (
                              <MenuItem value={item.language} key={idx}>
                                {t(`${item.i18n}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('language', this.state.language, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.language, 'required') && (
                          <FormHelperText id="language" error>
                            {t('validation.required.choose', {field: t('businessDetail.languages')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Business email */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.email')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl>
                        <div className="box-group-input">
                          <TextField
                            name="email"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('common.email')})}
                            variant="outlined"
                            value={this.state.email}
                            inputProps={{maxLength: 64}}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                          <div className="max-length-label">{t('validation.max.label', {value: 64})}</div>
                        </div>
                        {this.validator.message('email', this.state.email, 'required|email')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.email, 'required') && (
                          <FormHelperText id="email" error>
                            {t('errorFields.emailRequired')}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitForm && this.validator.check(this.state.email, 'required') && !this.validator.check(this.state.email, 'email') && (
                          <FormHelperText id="email" error>
                            {t('errorFields.emailFormat')}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {this.state.checkDetail ? (
                    <Grid container alignItems="center" xs={12} lg={9}>
                      <Memo
                        disabled={!permission.canUpdate}
                        memos={this.state.memos}
                        onChangeContent={(value) => onChangeListData(this, this.state.memos, value.field, value.index, value.newValue)}
                        note={this.state.note}
                        onChangeNote={(value) => this.setState({note: value})}
                        parentType="medium"
                        countryCode={this.state.country}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                </Paper>
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {this.state.checkDetail ? permission.canUpdate && (
                      <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={this.handleUpdate}>
                        {t('common.btnUpdate')}
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        variant="contained"
                        className="button_margin button_color_green"
                        endIcon={<AddIcon />}
                        onClick={this.handleClickCreateButton}
                        disabled={this.props.match.params.id && this.state.business_role_type === 'GLOBAL'}
                      >
                        {t('common.btnRegister')}
                      </Button>
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      className="button_margin button_color"
                      onClick={this.props.history.goBack}
                    >
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                  <Dialog
                    open={this.state.flag}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      zIndex: '1020',
                    }}
                  >
                    <SelectModal
                      onClickOk={this.state.checkDetail ? this.handleButtonUpdateOk : this.handleCreate}
                      onClickCancel={this.handleButtonUpdateCancel}
                      message={this.state.message}
                      isDisableCreate={this.state.isDisableButtonCreate}
                    ></SelectModal>
                  </Dialog>
                </Grid>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>
          <br></br>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    business: state.business,
    country_code: state.common.country_code,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDetailBusiness: (params, props) => dispatch(getDetailBusiness(params, props)),
    updateBusiness: (params, props) => dispatch(updateBusiness(params, props)),
    createBusiness: (payload, props) => dispatch(createBusiness(payload, props)),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getListGeofenceByCountry: (id) => dispatch(getListGeofenceByCountry(id)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(FormBusinessInformation)));
