import React, {Component} from 'react';

import {Button, AppBar, Toolbar, Typography, IconButton, Grid, ListItem, ListItemIcon, ListItemText, List, Drawer, Divider} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {
  Menu,
  Panorama,
  AccountBalanceOutlined as BankIcon,
  AssessmentOutlined as AssessmentIcon,
  AirportShuttleOutlined as DriverVehicleConfigIcon,
  AttachMoney as TaxiFeeIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  CollectionsBookmark as ReservationIcon,
  Commute as VehicleIcon,
  ContactsOutlined as CautionIcon,
  DateRange as Calendar,
  Domain as BusinessIcon,
  EmojiTransportation as DriverVehicleAssociationIcon,
  FaceOutlined as DriverIcon,
  InfoOutlined as RecommendIcon,
  LocalOfferOutlined as PriceIcon,
  Home as HomeIcon,
  LocalShipping as DeliveryIcon,
  Lock as LockIcon,
  LockOutlined as LockOutlinedIcon,
  MailOutline as MailOutlineIcon,
  People as PeopleIcon,
  PeopleTwoTone as MemberIcon,
  PhotoLibraryOutlined as PhotoIcon,
  RemoveShoppingCart as CancelFeeIcon,
  RoomOutlined as WaypointIcon,
  Settings as SettingsIcon,
  StorageOutlined as ProductIcon,
  Storefront as PartnerIcon,
  StoreOutlined as StoreIcon,
  SupervisedUserCircleOutlined as SupervisedUserIcon,
  GroupAdd as GroupAddIcon,
  FilterFrames as NewsIcon,
  CallSplit as RoutesIcon,
  AvTimer as AvTimerIcon,
  LocationCity as LocationCityIcon,
} from '@material-ui/icons';
import clsx from 'clsx';
import {withTranslation} from 'react-i18next';
import {Link, withRouter} from 'react-router-dom';

import {ROUTER_ICON, ROUTER_KEY} from '../../common/constant';
import {logout} from '../../utils/authenticator';
import {redirectRouter} from '../../utils/common';
import ItemPopover from '../popover';
import SelectMultiLang from '../SelectMultiLang/index';
import './style.css';

const drawerWidth = 270;
const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    minHeight: '67px !important',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverContent: {
    pointerEvents: 'auto',
    padding: theme.spacing(1),
  },
});

/**
 * NavMenu Component
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      collapse: false,
      env: process.env.REACT_APP_ENV,
    };
  }

  handleDrawerOpen = () => {
    this.setState({open: true});
  };

  handleDrawerClose = () => {
    this.setState({open: false});
  };

  /**
   * render icon menu
   * @param {number} icon
   * @return {tag}
   */
  renderIconMenu = (icon) => {
    switch (icon) {
      case ROUTER_ICON.HOME:
        return <HomeIcon />;
      case ROUTER_ICON.ACCOUNT:
        return <PeopleIcon />;
      case ROUTER_ICON.MAIL:
        return <MailOutlineIcon />;
      case ROUTER_ICON.LOCK:
        return <LockIcon />;
      case ROUTER_ICON.BUSINESS:
        return <BusinessIcon />;
      case ROUTER_ICON.MEMBER:
        return <MemberIcon />;
      case ROUTER_ICON.PRODUCT:
        return <ProductIcon />;
      case ROUTER_ICON.RESERVATION:
        return <ReservationIcon />;
      case ROUTER_ICON.DELIVERY:
        return <DeliveryIcon />;
      case ROUTER_ICON.CAUTION:
        return <CautionIcon />;
      case ROUTER_ICON.CANCEL_FEE:
        return <CancelFeeIcon />;
      case ROUTER_ICON.CALENDAR:
        return <Calendar />;
      case ROUTER_ICON.STORE:
        return <StoreIcon />;
      case ROUTER_ICON.VEHICLE:
        return <VehicleIcon />;
      case ROUTER_ICON.DRIVER:
        return <DriverIcon />;
      case ROUTER_ICON.PHOTO:
        return <PhotoIcon />;
      case ROUTER_ICON.PLAN:
        return <AssessmentIcon />;
      case ROUTER_ICON.SUPERVISED_USER:
        return <SupervisedUserIcon />;
      case ROUTER_ICON.TAXI_FEE:
        return <TaxiFeeIcon />;
      case ROUTER_ICON.DRIVER_VEHICLE_ASSOCIATION:
        return <DriverVehicleAssociationIcon />;
      case ROUTER_ICON.DRIVER_VEHICLE_CONFIG:
        return <DriverVehicleConfigIcon />;
      case ROUTER_ICON.PARTNER:
        return <PartnerIcon />;
      case ROUTER_ICON.PRICE:
        return <PriceIcon />;
      case ROUTER_ICON.BANK:
        return <BankIcon />;
      case ROUTER_ICON.WAYPOINT:
        return <WaypointIcon />;
      case ROUTER_ICON.RECOMMEND:
        return <RecommendIcon />;
      case ROUTER_ICON.SETTING:
        return <SettingsIcon />;
      case ROUTER_ICON.MENU_APP:
        return <Panorama />;
      case ROUTER_ICON.ROLE:
        return <GroupAddIcon />;
      case ROUTER_ICON.NEWS:
        return <NewsIcon />;
      case ROUTER_ICON.AREA_GEOFENCES:
        return <LocationCityIcon />;
      case ROUTER_ICON.JIT:
        return <AvTimerIcon />;
      case ROUTER_ICON.ROUTES:
        return <RoutesIcon />;
      default:
        return <HomeIcon />;
    }
  };

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {t, routes, classes, theme, subMenuActive} = this.props;
    const {open} = this.state;

    const sideList = (side) => (
      <div>
        <div className={classes.drawerHeader}>
          <img src={`${process.env.PUBLIC_URL}/logo-title.png`} alt="Logo" width="140" height="35" style={{paddingRight: 25}}></img>
          <IconButton onClick={this.handleDrawerClose}>{theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}</IconButton>
        </div>
        <Divider />

        <List>
          {routes.map((route, index) => {
            return route.subMenu ? (
              <ItemPopover
                data={this.props}
                key={index}
                route={route}
                content={
                  <>
                    <ListItemIcon>{this.renderIconMenu(route.icon)}</ListItemIcon>
                    <ListItemText primary={t(route.name)} />
                  </>
                }
                onClickOwner={() => {
                  this.handleDrawerClose();
                  redirectRouter(this.props, route.layout + route.path);
                }}
                popover={
                  <List component="div" disablePadding>
                    {route.subMenu
                      .filter((item) => subMenuActive.includes(item.path))
                      .map((sub, i) => {
                        return sub.key === ROUTER_KEY.SUB_MENU_ACTIVE ? (
                          <ListItem
                            key={i}
                            onClick={() => {
                              redirectRouter(this.props, sub.layout + sub.path);
                              this.setState({open: false});
                            }}
                            className="cursor_pointer nav_item"
                          >
                            <ListItemIcon>{this.renderIconMenu(sub.icon)}</ListItemIcon>
                            <ListItemText primary={t(sub.name)} />
                          </ListItem>
                        ) : (
                          ''
                        );
                      })}
                  </List>
                }
              />
            ) : (
              <ListItem
                key={index}
                onClick={() => {
                  redirectRouter(this.props, route.layout + route.path);
                  this.setState({open: false});
                }}
                className="cursor_pointer nav_item"
              >
                <ListItemIcon>{this.renderIconMenu(route.icon)}</ListItemIcon>
                <ListItemText primary={t(route.name)} />
              </ListItem>
            );
          })}
        </List>
      </div>
    );

    return (
      <>
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar variant="dense" className={`header_color ${(this.state.env === 'development' || this.state.env === 'staging') ? 'background-env' : ''} `}>
            <Grid container spacing={1}>
              <Grid item xs={3} justifyContent="flex-start" alignItems="center" container>
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={this.handleDrawerOpen}>
                  <Menu />
                </IconButton>

                <img src={`${process.env.PUBLIC_URL}/logo-title.png`} alt="Logo" className="logo_header"></img>
                <Link to="/maas/home">
                  <IconButton>
                    <HomeIcon fontSize="large" />
                  </IconButton>
                </Link>
                {(this.state.env === 'development' || this.state.env === 'staging') && (
                  <Grid className={this.state.env === 'development' ? 'box-env-dev' : 'box-env-stg'}>
                    {this.state.env === 'development' ? 'Develop' : 'Staging'}
                  </Grid>
                )}
              </Grid>
              <Grid item xs={6} justifyContent="flex-end" alignItems="center" container>
                <Typography variant="h6">
                  {localStorage.getItem('USER_NAME')} [ {localStorage.getItem('USER_ROLE')} ]
                </Typography>
              </Grid>
              <Grid item xs={1} justifyContent="flex-end" alignItems="center" container>
                <SelectMultiLang />
              </Grid>
              <Grid item xs={2} justifyContent="flex-end" alignItems="center" container>
                <Button variant="contained" className="button_color" color="primary" onClick={logout} endIcon={<LockOutlinedIcon />}>
                  {t('common.btnLogout')}
                </Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>

        <Drawer
          variant="persistent"
          anchor="left"
          open={open}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {sideList('left')}
        </Drawer>
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: open,
          })}
        >
          <div className={classes.drawerHeader} />
          {this.props.mainPage}
        </main>
      </>
    );
  }
}

export default withTranslation('translations')(withRouter(withStyles(styles, {withTheme: true})(Index)));
