import React, {useEffect, useState} from 'react';

import {Button, Card, Checkbox, Container, Dialog, Grid, Paper, TextField} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {isInteger} from 'lodash';
import {useTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {useStore} from 'react-redux';
import {useHistory, useParams} from 'react-router';

import SelectModal from '../../../../../components/selectModal';
import {detailServiceGeofence, updateServiceGeofence} from '../../../../../stores/geofence/action';

const validate = (form) => {
  const err = {};
  if (new Date(form.end_time) < new Date(form.start_time)) {
    err['start_time'] = 'service.msg_invalid_time';
    err['end_time'] = 'service.msg_invalid_time';
  }
  return err;
};

/**
 * @param {Onject} props
 * @return {ReactDOM}
 */
function ServiceDetail({isLoading}) {
  const store = useStore();
  const history = useHistory();
  const {t} = useTranslation('translations');
  const {id} = useParams();
  const [detailForm, setDetailForm] = useState({enable: false, dataset_id: '', routing_profile_id: '', transit_stop_set_id: '', walking_profile_id: ''});
  const [showDialog, setShowDialog] = useState(false);
  const [errors, setErrors] = useState({});
  const isAdd = String(id).startsWith('add');

  /**
   * initial state
   */
  useEffect(() => {
    if (!isAdd && isInteger(+id)) {
      store.dispatch(detailServiceGeofence(id)).then(setDetailForm);
    }
  }, [store, id, isAdd]);

  /**
   * handle Change Form
   * @param {Event} evt
   */
  const handleChangeForm = (evt) => {
    const key = evt.target.name;
    const val = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
    setDetailForm({
      ...detailForm,
      [key]: val,
    });
  };

  /**
   * handle update service
   */
  const handleUpdateService = async () => {
    await store.dispatch(updateServiceGeofence(detailForm));
    setShowDialog(false);
  };

  /**
   * submitForm
   * @param {Event} evt
   */
  const submitForm = (evt) => {
    evt.preventDefault();
    const err = validate(detailForm);
    setErrors(err);
    if (Object.keys(err).length === 0) {
      setShowDialog(true);
    }
  };

  return (
    <>
      <Container maxWidth="xl">
        <Grid container className="page_header">
          <Grid container alignItems="center" item xs={6}>
            <h3>{t('service.detail_title')}</h3>
          </Grid>
          <Grid container alignItems="center" justify="flex-end" item xs={6}>
            <Button color="primary" variant="contained" onClick={() => history.goBack()} className="button_margin button_color" endIcon={<ArrowBackIcon />}>
              {t('common.btnReturn')}
            </Button>
          </Grid>
        </Grid>
        <br></br>
        <LoadingOverlay active={isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
          <Card raised>
            <br></br>
            <form onSubmit={submitForm} autoComplete="off">
              <Container maxWidth="xl">
                <Card raised>
                  <Paper className="search_table">
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                        {t('service.detail_title')}
                      </Grid>
                    </Grid>

                    {/* enable */}
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('common.enable')}
                        <span className="font_color_red">＊</span>
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <Checkbox checked={detailForm.enable} name="enable" onChange={handleChangeForm} />
                      </Grid>
                    </Grid>

                    {/* dataset_id */}
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('service.dataset_id')}
                        <span className="font_color_red">＊</span>
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <TextField
                          required
                          className="field_size_10 field_min_size_150"
                          name="dataset_id"
                          value={detailForm.dataset_id}
                          margin="dense"
                          variant="outlined"
                          onChange={handleChangeForm}
                          helperText={t(errors['dataset_id'])}
                          error={!!errors['dataset_id']}
                        />
                      </Grid>
                    </Grid>

                    {/* routing_profile_id */}
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('service.routing_profile_id')}
                        <span className="font_color_red">＊</span>
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <TextField
                          required
                          className="field_size_10 field_min_size_150"
                          name="routing_profile_id"
                          value={detailForm.routing_profile_id}
                          margin="dense"
                          variant="outlined"
                          onChange={handleChangeForm}
                          helperText={t(errors['routing_profile_id'])}
                          error={!!errors['routing_profile_id']}
                        />
                      </Grid>
                    </Grid>

                    {/* transit_stop_set_id */}
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('service.transit_stop_set_id')}
                        <span className="font_color_red">＊</span>
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <TextField
                          required
                          className="field_size_10 field_min_size_150"
                          name="transit_stop_set_id"
                          value={detailForm.transit_stop_set_id}
                          margin="dense"
                          variant="outlined"
                          onChange={handleChangeForm}
                          helperText={t(errors['transit_stop_set_id'])}
                          error={!!errors['transit_stop_set_id']}
                        />
                      </Grid>
                    </Grid>

                    {/* Service ID */}
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('service.walking_profile_id')}
                        <span className="font_color_red">＊</span>
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <TextField
                          required
                          className="field_size_10 field_min_size_150"
                          name="walking_profile_id"
                          value={detailForm.walking_profile_id}
                          margin="dense"
                          variant="outlined"
                          onChange={handleChangeForm}
                          helperText={t(errors['walking_profile_id'])}
                          error={!!errors['walking_profile_id']}
                        />
                      </Grid>
                    </Grid>

                    {/* description */}
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('geofence.description')}
                        <span className="font_color_red">＊</span>
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <TextField
                          className="field_size_10 field_min_size_350"
                          multiline
                          rows={2}
                          rowsMax={8}
                          name="description"
                          value={detailForm.description || ''}
                          margin="dense"
                          variant="outlined"
                          onChange={handleChangeForm}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Card>
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    <Button color="primary" type="submit" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />}>
                      {t('common.btnUpdate')}
                    </Button>
                    <Button color="primary" variant="contained" onClick={() => history.goBack()} className="button_margin button_color">
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                </Grid>
                <br></br>
              </Container>
            </form>
          </Card>
        </LoadingOverlay>
      </Container>
      <Dialog
        open={showDialog}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: '1020',
        }}
      >
        <SelectModal onClickOk={handleUpdateService} onClickCancel={() => setShowDialog(false)} message={t('service.confirm_update')}></SelectModal>
      </Dialog>
    </>
  );
}

export default ServiceDetail;
