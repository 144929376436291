import React, {Component} from 'react';

import {TextField, Select, FormControl, MenuItem, Card, Grid, Container, Dialog, FormHelperText, Button, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CancelIcon from '@material-ui/icons/Cancel';
import SendIcon from '@material-ui/icons/Send';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

import {ROUTE, PERMISSION_ACTIONS} from '../../../common/constant';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {sendMail, getAllAccountTypes} from '../../../stores/account/actions';
import {getListSupplier} from '../../../stores/common/actions';
import {onChangeListData} from '../../../utils/common';
import {isRoleBusiness} from '../../../utils/role';
import './style.css';

/**
 * Account Invite view
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      rows: [
        {
          email: '',
          personCharge: '',
          business: null,
          rolePermissionId: '',
          listRolePermission: [],
        },
      ],
      errorFields: [
        {
          emailUsed: '',
        },
      ],
      flagSelectModal: false,
      message: '',
      isSubmitForm: false,
      isUsed: false,
      isDisableCreate: false,
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * delete rows
   * @param {int} index
   */
  deleteRows = (index) => {
    const rowsCopy = this.state.rows;
    const errorFieldsCopy = this.state.errorFields;
    rowsCopy.splice(index, 1);
    errorFieldsCopy.splice(index, 1);
    this.setState({rows: rowsCopy, errorFields: errorFieldsCopy});
  };

  /**
   * add row
   */
  addRows = () => {
    const rowsCopy = this.state.rows;
    const errorFieldsCopy = this.state.errorFields;
    const {common, account} = this.props;
    const {supplier_list, principal} = common;
    const tab = {
      email: '',
      personCharge: '',
      business: isRoleBusiness() ? supplier_list?.find((supplier) => supplier.id === principal?.supplier_id) : null,
      rolePermissionId: '',
      listRolePermission: isRoleBusiness() ? account?.allAccountTypes : [],
    };
    const error = {
      emailUsed: '',
    };
    rowsCopy.push(tab);
    errorFieldsCopy.push(error);
    this.setState({rows: rowsCopy, errorFields: errorFieldsCopy});
  };

  /**
   * changeBusiness
   * @param {Number} index
   * @param {Object} business
   */
  changeBusiness = (index, business) => {
    const rowsCopy = this.state.rows;
    rowsCopy[index].listRolePermission = business ? this.props.account?.allAccountTypes?.filter((item) => item.supplier_id === business?.id) : [];
    rowsCopy[index].rolePermissionId = '';
    this.setState({rows: rowsCopy});
  };

  /**
   * validation used email
   * @param {object} rows
   * @param {object} emails
   */
  indexOfEmailUsed = (rows, emails) => {
    const {t} = this.props;
    let error = {
      emailUsed: '',
    };
    const errorFields = [];
    for (let i = 0; i < rows.length; i++) {
      if (emails.find((e) => e === rows[i].email)) {
        error.emailUsed = t('error.already.exist.or.has.been.invited');
      }
      errorFields.push(error);
      error = {
        emailUsed: '',
      };
    }
    this.setState({...this.state, errorFields: errorFields});
  };

  /**
   * resetErrorField
   * @param {Number} index
   */
  resetErrorField = (index) => {
    const {errorFields} = this.state;
    errorFields[index].emailUsed = '';
    this.setState({errorFields});
  }

  /**
   * handleButtonOk
   */
  handleButtonOk = () => {
    const payload = [];
    const {rows} = this.state;
    for (const i in rows) {
      if (this.state.rows.length !== 0) {
        const item = {
          role_permission_id: rows[i].rolePermissionId,
          person_charge: rows[i].personCharge,
          email: rows[i].email,
          business_id: rows[i].business.id,
        };
        payload.push(item);
      }
    }
    this.setState({flagSelectModal: false, isDisableCreate: true});
    this.props.sendMail(payload, this.props).then((response) => {
      if (response) {
        this.indexOfEmailUsed(rows, response.emails);
      }
    })
    .finally(() => {
      this.setState({isDisableCreate: false});
    });
  };

  /**
   * handleButtonCancel
   */
  handleButtonCancel = () => {
    this.setState({
      flagSelectModal: false,
    });
  };

  /**
   * handle send invite mail
   */
  handleSendMail = () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.checkAllFieldValid(this.state.rows)) {
      this.setState({
        flagSelectModal: true,
        message: 'messageCode.accountInviteConfirm',
      });
    } else {
      this.setState({flagSelectModal: false});
    }
  };

  /**
   checkAllFieldValid
   @param {Array} data
   @return {Boolean}
  */
  checkAllFieldValid = (data) => {
    return data
      .map(
        (item) =>
          this.validator.check(item.business, 'required') &&
          this.validator.check(item.rolePermissionId, 'required') &&
          this.validator.check(item.personCharge, 'required') &&
          this.validator.check(item.email, 'required|email'),
      )
      .every((e) => e);
  };

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getListSupplier();
    await this.props.getAllAccountTypes();

    if (isRoleBusiness()) {
      const {rows} = this.state;
      const {supplier_list, principal} = this.props.common;
      rows[0].business = supplier_list?.find((supplier) => supplier.id === principal?.supplier_id) || null;
      rows[0].listRolePermission = this.props.account?.allAccountTypes;
      this.setState({rows});
    };
  }

  /**
   * Render view
   * @return {object}
   */
  render() {
    const {account, common, t, actions} = this.props;
    const {rows, errorFields} = this.state;

    const permission = {
      canSend: actions.includes(PERMISSION_ACTIONS.SEND),
    };

    return (
      <LoadingOverlay active={account.isLoading || account.isLoadingRole || common.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size account-invite">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('accountInvite.title')}</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}>
                <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                  <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                    {t('common.btnReturn')}
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <br></br>
            <div>
              {permission.canSend && (
                <div className="text_align_right">
                  <Button color="primary" variant="contained" className="button_margin" endIcon={<SendIcon />} onClick={() => this.handleSendMail()}>
                    {t('common.btnSend')}
                  </Button>
                </div>
              )}

              <div className="scroll_area_700">
                <br></br>
                <Table stickyHeader size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{width: '20%'}}>
                        {t('accountInvite.businessName')}
                        {Number(localStorage.getItem('permission_id')) !== 1 && <span className="font_color_red">＊</span>}
                      </TableCell>
                      <TableCell style={{width: '25%'}}>
                        {t('accountInvite.accountType')}
                        <span className="font_color_red">＊</span>
                      </TableCell>

                      <TableCell style={{width: '20%'}}>
                        {t('accountInvite.contactName')}
                        <span className="font_color_red">＊</span>
                      </TableCell>
                      <TableCell style={{width: '25%'}}>
                        {t('common.email')} <span className="font_color_red">＊</span>
                      </TableCell>
                      <TableCell align="center" style={{width: '5%'}}>
                        <Button color="primary" variant="contained" onClick={() => this.addRows()} disabled={rows.length >= 10}>
                          <AddIcon />
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row, index) => (
                      <TableRow key={index} hover>
                        <TableCell>
                          <FormControl variant="outlined" fullWidth>
                            <Autocomplete
                              name="business"
                              margin="dense"
                              options={common?.supplier_list}
                              value={row.business}
                              getOptionLabel={(option) => option.supplier_name || ''}
                              onChange={(event, business) => {
                                this.changeBusiness(index, business);
                                onChangeListData(this, this.state.rows, 'business', index, business);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  name={String(index)}
                                  fullWidth
                                  variant="outlined"
                                  margin="dense"
                                  placeholder={t('placeholder.required', {field: t('accountInvite.businessName')})}
                                />
                              )}
                            />
                            {this.validator.message('business', row.business, 'required')}
                            {this.state.isSubmitForm && !this.validator.check(row.business, 'required') && (
                              <FormHelperText id="business" error>
                                {t('validation.required', {field: t('accountInvite.businessName')})}
                              </FormHelperText>
                            )}
                            {row.business && row.listRolePermission?.length === 0 && <FormHelperText style={{color: 'navy'}}>{t('accountInvite.noAccountType')}</FormHelperText>}
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <FormControl variant="outlined" margin="dense" fullWidth>
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'rolePermissionId',
                              }}
                              displayEmpty
                              disabled={row.listRolePermission?.length === 0}
                              renderValue={
                                row.rolePermissionId ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('accountInvite.accountType'),
                                        })}
                                      </div>
                                    )
                              }
                              value={row.rolePermissionId}
                              onChange={(event) => onChangeListData(this, this.state.rows, event.target.name, index, event.target.value)}
                            >
                              {row?.listRolePermission?.map((item, idx) => {
                                return (
                                  <MenuItem value={item.id} key={idx}>
                                    {item.role_permission_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            {this.validator.message('rolePermissionId', row.rolePermissionId, 'required')}
                            {this.state.isSubmitForm && !this.validator.check(row.rolePermissionId, 'required') && (
                              <FormHelperText id="rolePermissionId" error>
                                {t('validation.required', {field: t('accountInvite.accountType')})}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <FormControl variant="outlined" fullWidth>
                            <TextField
                              name="personCharge"
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              placeholder={t('placeholder.required', {field: t('accountInvite.contactName')})}
                              onChange={(event) => onChangeListData(this, this.state.rows, event.target.name, index, event.target.value)}
                              inputProps={{maxLength: 128}}
                              value={row.personCharge}
                            />
                            {this.validator.message('personCharge', row.personCharge, 'required')}
                            {this.state.isSubmitForm && !this.validator.check(row.personCharge, 'required') && (
                              <FormHelperText id="personCharge" error>
                                {t('validation.required', {field: t('accountInvite.contactName')})}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <FormControl variant="outlined" fullWidth>
                            <TextField
                              name="email"
                              fullWidth
                              placeholder={t('placeholder.required', {field: t('common.email')})}
                              variant="outlined"
                              margin="dense"
                              error={errorFields[index].emailUsed !== ''}
                              helperText={errorFields[index].emailUsed ? <span className="font_bold">{errorFields[index].emailUsed}</span> : ''}
                              onChange={(event) => {
                                this.resetErrorField(index);
                                onChangeListData(this, this.state.rows, event.target.name, index, event.target.value);
                              }}
                              inputProps={{maxLength: 128}}
                              value={row.email}
                            />
                            {this.validator.message('email', row.email, 'required|email')}
                            {this.state.isSubmitForm && !this.validator.check(row.email, 'required') && (
                              <FormHelperText id="email" error>
                                {t('validation.required', {field: t('common.email')})}
                              </FormHelperText>
                            )}
                            {this.state.isSubmitForm && this.validator.check(row.email, 'required') && !this.validator.check(row.email, 'email') && (
                              <FormHelperText id="email" error>
                                {t('errorFields.emailFormat')}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </TableCell>
                        <TableCell align="center">{index !== 0 && <CancelIcon className="cursor_pointer" onClick={() => this.deleteRows(index)} />}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
            <br />
            <Dialog
              open={this.state.flagSelectModal}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '1020',
              }}
            >
              <SelectModal
                onClickOk={this.handleButtonOk}
                onClickCancel={this.handleButtonCancel}
                message={this.state.message}
                isDisableCreate={this.state.isDisableCreate}
              />
            </Dialog>
          </Container>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.account,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendMail: (body, props) => dispatch(sendMail(body, props)),
    getListSupplier: () => dispatch(getListSupplier()),
    getAllAccountTypes: () => dispatch(getAllAccountTypes()),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
