import React, {Component} from 'react';

import {
  // AppBar,
  Backdrop,
  Badge,
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  MenuItem,
  Modal,
  Paper,
  Select,
  Switch,
  // Tab,
  // Tabs,
  TextField,
  RadioGroup,
  Radio,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import {KeyboardDatePicker} from '@material-ui/pickers';
import JoditEditor from 'jodit-react';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import SimpleReactValidator from 'simple-react-validator';
import 'date-fns';

import {
  DATE_FORMAT,
  ROUTE,
  PERMISSION_ACTIONS,
  NEWS_DISPLAY_METHOD,
  NEWS_DISPLAY_ON,
  NEWS_PUSH_NOTIFICATION,
  LANGUAGE,
  DATA_NULL,
  NEWS_SETTING_LANGUAGE,
} from '../../../common/constant';
import ImageUpload from '../../../components/imageSelect';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {createNewsApi} from '../../../services/commonSettingServices';
import {getAllGeofence, getAllCountryCode} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {getNewsDetail, getNewsTemplates} from '../../../stores/news_template/action';
import {backForwardRouter, getListAllGeofenceDetail, onChangeListData, onChangeSelect} from '../../../utils/common';
import {getMaxDate, compareDateTimeRange, convertStartEndTime, convertDateTimeToDate} from '../../../utils/datetime';
import {modalObj} from '../../../utils/modal';
import {isRoleBusiness, isRoleGlobal} from '../../../utils/role';
import './style.css';

/**
 * News Management Form Component
 */
class Index extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      title: '',
      content: '',
      display_from: null,
      display_to: null,
      note: '',
      country_id: '',
      geofence_ids: [],
      is_active: false,
      deleted: false,
      all_geofence: false,
      display_method: '',
      display_on_ids: [],
      push_notification: true,
      setting_language_ids: [1],
      news_languages: [
        {
          language_id: 1,
          title: '',
          content: '',
          note: '',
        },
        {
          language_id: 2,
          title: '',
          content: '',
          note: '',
        },
        {
          language_id: 3,
          title: '',
          content: '',
          note: '',
        },
      ],

      tab: 1,
      image_master_id: '',
      imageSrc: '',
      listGeofenceAll: [],
      isSubmitForm: false,
      openModal: false,
      flagUpdate: false,
      flgTemplate: false,
      flgRegister: false,
      isLoading: false,
      isDisableCreate: false,
      tempTemplateId: '',
      listGeofencesNotInFacility: [],
      dataDetail: [],
    };
    this.validator = new SimpleReactValidator({
      validators: {
        not_base64: {
          message: 'The :attribute must be a valid base64 string',
          rule: (val) => {
            // Base64 regex pattern
            const base64Pattern = /src="(data:image\/[^;]+;base64[^"]+)"/i;
            return !base64Pattern.test(val);
          },
        },
      },
    });
    this.config = {
      controls: {
        style: {
          font: 'Roboto, Helvetica, Arial, sans-serif',
        },
        font: {
          list: {
            '': 'Default',
            'Roboto, Helvetica, Arial, sans-serif': 'Roboto',
            'Helvetica,sans-serif': 'Helvetica',
            'Arial,Helvetica,sans-serif': 'Arial',
            'Georgia,serif': 'Georgia',
            'Impact,Charcoal,sans-serif': 'Impact',
            'Tahoma,Geneva,sans-serif': 'Tahoma',
            // eslint-disable-next-line quotes
            "'Times New Roman',Times,serif": 'Times New Roman',
            'Verdana,Geneva,sans-serif': 'Verdana',
          },
        },
      },
      buttons: [
        'source',
        '|',
        'bold',
        'italic',
        '|',
        'ul',
        'ol',
        '|',
        'font',
        'fontsize',
        'brush',
        'paragraph',
        '|',
        'image',
        'video',
        'table',
        'link',
        '|',
        'left',
        'center',
        'right',
        'justify',
        '|',
        'undo',
        'redo',
        '|',
        'hr',
        'eraser',
        'fullsize',
      ],
      readonly: false,
      removeButtons: ['table'],
    };
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllCountryCode();
    await this.props.getAllGeofence().then(() => this.setState({listGeofenceAll: this.props.common?.all_geofence}));
    await this.props.getNewsTemplates();
    if (this.props.match.params.id) {
      this.setNewsDetail();
    } else {
      if (!isRoleGlobal()) {
        this.setState({
          country_id: this.props.common.principal?.country_id,
          listGeofenceAll: this.props.common?.all_geofence,
        });
      }
      if (this.props.location.state?.template_id) {
        await this.setState({templateId: this.props.location.state.template_id, tempTemplateId: this.props.location.state.template_id}, () => this.handleSelectTemplate());
        const item = this.props?.news_templates?.templates?.content?.find((item) => item.id === this.state.templateId);
        this.setState({setting_language_ids: item?.lang_contents?.map((item) => item.language_id)});
      } else {
        this.setState({
          news_languages: [
            {
              language_id: 1,
              title: '',
              content: '',
              note: '',
            },
            {
              language_id: 2,
              title: '',
              content: '',
              note: '',
            },
            {
              language_id: 3,
              title: '',
              content: '',
              note: '',
            },
          ],
        });
      }
    }
  }

  /**
   * Get news detail from API and set as initial state
   */
  setNewsDetail = async () => {
    const newsDetail = await this.props.getNewsDetail(this.props.match.params.id, this.props.history);
    if (newsDetail?.status === 200) {
      const geofenceInFacilityIds = this.props.common.principal?.geofence_ids || [];
      const listGeofencesNotInFacility = isRoleBusiness() ? newsDetail?.result?.geofence_infos?.filter((geofence) => !geofenceInFacilityIds.includes(geofence.geofence_id)) : [];

      this.setState({
        id: newsDetail?.result.id,
        title: newsDetail?.result.title,
        note: newsDetail?.result.note,
        country_id: Number(newsDetail?.result.country_id),
        geofence_ids: newsDetail?.result?.geofence_infos?.map((item) => item.geofence_id),
        content: newsDetail?.result.content,
        display_from: new Date(newsDetail?.result.display_from),
        display_to: new Date(newsDetail?.result.display_to),
        is_active: compareDateTimeRange(new Date(), convertStartEndTime(newsDetail?.result.display_to, false), true) ? newsDetail?.result.is_active : false,
        deleted: false,
        listGeofenceAll: isRoleGlobal() ? this.props.common?.all_geofence?.filter((e) => e.country_id === Number(newsDetail?.result.country_id)) : this.state.listGeofenceAll,
        all_geofence: newsDetail?.result?.all_geofence,
        news_languages: newsDetail?.result?.news_languages,
        display_method: newsDetail?.result?.display_method,
        display_on_ids: newsDetail?.result?.display_on,
        push_notification: newsDetail?.result?.is_pushed,
        setting_language_ids: newsDetail?.result?.news_languages?.map((item) => item.language_id),
        listGeofencesNotInFacility,
        dataDetail: newsDetail?.result,
      });
    }
  };

  /**
   * handleOnChangeCountry
   * @param {*} value
   */
  handleOnChangeCountry = (value) => {
    const geofenceIdsPrev = this.state.dataDetail?.geofence_infos?.map((item) => item?.geofence_id);
    this.setState({
      country_id: value,
      geofence_ids: Number(this.state.dataDetail?.country_id) === value ? [...geofenceIdsPrev] : [],
      listGeofenceAll: this.props.common?.all_geofence?.filter((e) => e.country_id === value),
      all_geofence: false,
    });
  };

  /**
   * checkAllGeofence
   * @param {*} event
   */
  checkAllGeofence = (event) => {
    const data = this.state.listGeofenceAll.filter((item) => item.enable && item.enable_for_admin).map((e) => e.geofence_id);
    this.setState({
      all_geofence: event,
      geofence_ids: event === true ? data : [],
    });
  };

  /**
   * handleSelectGeofence
   * @param {Object} value
   * @param {Number} item
   */
  handleSelectGeofence = (value, item) => {
    const data = this.state.listGeofenceAll.filter((item) => item.enable && item.enable_for_admin).map((e) => e.geofence_id);
    const geofence_ids_copy = this.state.geofence_ids;
    const index = geofence_ids_copy?.indexOf(item);
    value ? geofence_ids_copy.push(item) : geofence_ids_copy.splice(index, 1);

    this.setState({
      geofence_ids: geofence_ids_copy,
      all_geofence: data.length > geofence_ids_copy.length ? false : true,
    });
  };

  /**
   * handleSelectGeofence
   * @param {Object} value
   * @param {*} item
   */
  handleSelectDisplayOn = (value, item) => {
    const display_on_ids_copy = this.state.display_on_ids;
    const index = display_on_ids_copy.indexOf(item);
    value ? display_on_ids_copy.push(item) : display_on_ids_copy.splice(index, 1);

    this.setState({
      display_on_ids: display_on_ids_copy,
    });
  };

  /**
   * handleSelectLanguage
   * @param {Object} value
   * @param {*} item
   */
  handleSelectLanguage = (value, item) => {
    const setting_language_ids_copy = this.state.setting_language_ids;
    const index = setting_language_ids_copy.indexOf(item);
    value ? setting_language_ids_copy.push(item) : setting_language_ids_copy.splice(index, 1);

    this.setState({
      setting_language_ids: setting_language_ids_copy,
    });
  };

  /**
   * onChangeDisplayTime
   * @param {*} time
   * @param {Boolean} isStart
   */
  onChangeDisplayTime = (time, isStart) => {
    const fieldName = isStart ? 'display_from' : 'display_to';
    this.setState({
      [fieldName]: time,
    });
    compareDateTimeRange(this.state.display_from, this.state.display_to, true);
    if (isStart && convertDateTimeToDate(time) > convertDateTimeToDate(new Date())) {
      this.setState({
        push_notification: false,
      });
    }
  };

  /**
   * countTabsValidate
   * @param {Object} data
   * @param {Boolean} isSubmitForm
   * @return {Number}
   */
  countTabsValidate = (data, isSubmitForm) => {
    const array = [
      this.validator.check(data?.title, 'required') === false ? this.validator.check(data?.title, 'required') : this.validator.check(data?.title.trim(), 'max:50'),
      this.validator.check(data?.note, 'required') === false ? this.validator.check(data?.note, 'required') : this.validator.check(data?.note.trim(), 'max:20'),
      this.validator.check(data?.content, 'required'), this.validator.check(data?.content, 'not_base64'),
    ];
    return isSubmitForm ? array?.filter((e) => e === false).length : 0;
  };

  /**
   * tabPanel
   * @param {*} data
   * @param {*} langId
   * @return {*}
   */
  tabPanel = (data, langId) => {
    const {t} = this.props;
    if (!data) {
      const dataBlank = {language_id: langId, content: '', note: '', title: ''};
      const newData = this.state.news_languages?.concat(dataBlank);
      this.setState({
        news_languages: newData,
      });
    }
    return (
      <TabPanel key={data?.language_id}>
        <Box p={1}>
          <Grid container spacing={1} alignItems="flex-start">
            <Grid container spacing={1} item xs={6} lg={8}>
              {/* Title */}
              <Grid container spacing={1}>
                <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                  {t('popup_recommend.title')}
                  <span className="font_color_red">＊</span>
                </Grid>
                <Grid container alignItems="center" item xs={6} lg={8}>
                  <FormControl variant="outlined" margin="dense">
                    <div className="box-group-input">
                      <TextField
                        name="title"
                        className="field_size_10 field_min_size_300"
                        margin="dense"
                        placeholder={t('validation.required', {field: t('popup_recommend.title')})}
                        value={data?.title}
                        multiline
                        inputProps={{maxLength: 50}}
                        rows={3}
                        onChange={(event) =>
                          onChangeListData(
                            this,
                            this.state.news_languages,
                            'title',
                            this.state.news_languages?.findIndex((e) => e.language_id === data.language_id),
                            event.target.value,
                          )
                        }
                        variant="outlined"
                      />
                      <div className="max-length-label">{t('validation.max.label', {value: 50})}</div>
                    </div>
                    {this.validator.message('title', data?.title, 'required|max:50')}
                    {this.state.isSubmitForm && !this.validator.check(data?.title, 'required') && (
                      <FormHelperText id="title" error>
                        {t('validation.required', {field: t('popup_recommend.title')})}
                      </FormHelperText>
                    )}
                    {this.state.isSubmitForm && this.validator.check(data?.title, 'required') && !this.validator.check(data?.title.trim(), 'max:50') && (
                      <FormHelperText id="title" error>
                        {t('validation.maxString', {value: 50})}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              {/* note */}
              <Grid container spacing={1}>
                <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                  {t('news_management.note')} <span className="font_color_red">＊</span>
                </Grid>
                <Grid container alignItems="center" item xs={6} lg={9}>
                  <FormControl variant="outlined" margin="dense">
                    <div className="box-group-input">
                      <TextField
                        name="note"
                        className="field_size_10 field_min_size_300"
                        margin="dense"
                        placeholder={t('validation.required', {field: t('news_management.note')})}
                        value={data?.note || ''}
                        onChange={(event) =>
                          onChangeListData(
                            this,
                            this.state.news_languages,
                            'note',
                            this.state.news_languages?.findIndex((e) => e.language_id === data.language_id),
                            event.target.value,
                          )
                        }
                        inputProps={{maxLength: 20}}
                        variant="outlined"
                      />
                      <div className="max-length-label">{t('validation.max.label', {value: 20})}</div>
                    </div>
                    {this.validator.message('note', data?.note, 'required|max:20')}
                    {this.state.isSubmitForm && !this.validator.check(data?.note, 'required') && (
                      <FormHelperText id="note" error>
                        {t('validation.required', {field: t('news_management.note')})}
                      </FormHelperText>
                    )}
                    {this.state.isSubmitForm && this.validator.check(data?.note, 'required') && !this.validator.check(data?.note.trim(), 'max:20') && (
                      <FormHelperText id="title" error>
                        {t('validation.maxString', {value: 20})}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              {/* Content */}
              <Grid container spacing={1}>
                <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                  {t('vehicles.content')} <span className="font_color_red">＊</span>
                </Grid>
                <Grid container alignItems="center" item xs={6} lg={9}>
                  <FormControl variant="outlined" margin="dense">
                    <JoditEditor
                      value={data?.content}
                      config={this.config}
                      tabIndex={1}
                      onChange={(newContent) =>
                        onChangeListData(
                          this,
                          this.state.news_languages,
                          'content',
                          this.state.news_languages?.findIndex((e) => e.language_id === data.language_id),
                          newContent,
                        )
                      }
                    />
                    {this.validator.message('content', data?.content, 'required|not_base64')}
                    {this.state.isSubmitForm && !this.validator.check(data?.content, 'required') && (
                      <FormHelperText id="content" error>
                        {t('validation.required', {field: t('vehicles.content')})}
                      </FormHelperText>
                    )}
                    {!this.validator.check(data?.content, 'not_base64') && (
                      <FormHelperText id="base64_content" error>
                        {t('validation.base64_first_content')}
                        <br />
                        {t('validation.base64_second_content')}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={6} lg={4}>
              <div className="iphone-wrapper">
                <div className="iphone-x">
                  <div className="header" />
                  <div className="inner-view" dangerouslySetInnerHTML={{__html: data?.content}} />
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </TabPanel>
    );
  };

  /**
   * handleButtonCloseModal
   */
  handleButtonCloseModal = () => {
    this.setState({
      openModal: false,
    });
  };

  /**
   * handleChooseImage
   */
  handleChooseImage = () => {
    this.setState({
      openModal: true,
    });
  };

  /**
   * handleSubmitForm
   */
  handleSubmitForm = async () => {
    await this.setState({
      isSubmitForm: true,
      isLoading: true,
      isDisableCreate: true,
    });
    const validTabsLanguages =
      this.state.setting_language_ids
        ?.map((item) =>
          this.countTabsValidate(
            this.state.news_languages?.find((i) => i.language_id === item),
            true,
          ),
        )
        ?.reduce((a, b) => a + b, 0) === 0;
    if (
      compareDateTimeRange(this.state.display_from, this.state.display_to, true) &&
      validTabsLanguages &&
      this.validator.check(this.state.country_id, 'required') &&
      this.validator.check(this.state.geofence_ids, 'required') &&
      this.validator.check(this.state.display_from, 'required') &&
      this.validator.check(this.state.display_to, 'required') &&
      this.validator.check(this.state.setting_language_ids, 'required') &&
      this.validator.check(this.state.display_method, 'required') &&
      this.validator.check(this.state.display_on_ids, 'required')
    ) {
      const convert_news_languages = this.state.news_languages
        ?.filter((item) => this.state.setting_language_ids?.includes(item.language_id))
        ?.map((item) => ({
          language_id: item?.language_id || '',
          title: item?.title || '',
          content: item?.content || '',
          note: item?.note || '',
          html_content: this.createWebViewString(item.content),
          short_content:
            item?.content?.replace(/<[^>]+>/g, '')?.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&lt;|&gt;/g, ' ')?.length > 50 ?
              item?.content
                  ?.replace(/<[^>]+>/g, '')
                  ?.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&lt;|&gt;/g, ' ')
                  ?.slice(0, 50)
                  ?.concat('...') || '' :
              item?.content
                  ?.replace(/<[^>]+>/g, '')
                  ?.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&lt;|&gt;/g, ' ')
                  ?.slice(0, 50) || '',
        }));
      const payload = {
        display_from: convertDateTimeToDate(this.state.display_from),
        display_to: convertDateTimeToDate(this.state.display_to),
        country_id: this.state.country_id,
        geofence_ids: this.state.geofence_ids,
        is_active: this.state.is_active,
        deleted: false,
        all_geofence: this.state.all_geofence,
        news_languages: convert_news_languages,
        display_method: this.state.display_method,
        display_on: this.state.display_on_ids,
        is_pushed: this.state.push_notification,
      };
      if (this.state.id) payload.id = this.state.id;
      await createNewsApi(payload, this.props).then(
        (response) => {
          if (response && response.status === 200) {
            this.setState({isLoading: false, isDisableCreate: false});
            this.props.setMessageModal(modalObj(true, response.message_code));
            if (this.props.match.params.id) {
              this.setNewsDetail();
            } else backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.NEWS_MANAGEMENT);
          } else {
            this.setState({isLoading: false, isDisableCreate: false});
          }
          this.setState({flagUpdate: false});
        },
        () => this.props.setMessageModal(modalObj(true, 'Api.fail')),
      );
    }
  };

  createWebViewString = (htmlContent) => {
    return (
      '<!DOCTYPE html>\n' +
      '<html lang="en">\n' +
      '<head>\n' +
      '    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">\n' +
      '    <meta name="viewport" content="width=device-width, initial-scale=1.0">\n' +
      '</head>\n' +
      '<body>\n' +
      htmlContent +
      '</body>\n' +
      '</html>'
    );
  };

  /**
   * openUpdateModal
   */
  openUpdateModal = () => {
    this.setState({
      isSubmitForm: true,
    });
    const validTabsLanguages =
      this.state.setting_language_ids
        ?.map((item) =>
          this.countTabsValidate(
            this.state.news_languages?.find((i) => i.language_id === item),
            true,
          ),
        )
        ?.reduce((a, b) => a + b, 0) === 0;
    if (
      compareDateTimeRange(this.state.display_from, this.state.display_to, true) &&
      validTabsLanguages &&
      this.validator.check(this.state.country_id, 'required') &&
      this.validator.check(this.state.geofence_ids, 'required') &&
      this.validator.check(this.state.display_from, 'required') &&
      this.validator.check(this.state.display_to, 'required') &&
      this.validator.check(this.state.setting_language_ids, 'required') &&
      this.validator.check(this.state.display_method, 'required') &&
      this.validator.check(this.state.display_on_ids, 'required')
    ) {
      this.setState({
        flagUpdate: true,
        message: 'news_management.messageCode.updateConfirm',
      });
    }
  };

  /**
   * openRegisterModal
   */
  openRegisterModal = () => {
    this.setState({
      isSubmitForm: true,
    });
    const validTabsLanguages =
      this.state.setting_language_ids
        ?.map((item) =>
          this.countTabsValidate(
            this.state.news_languages?.find((i) => i.language_id === item),
            true,
          ),
        )
        ?.reduce((a, b) => a + b, 0) === 0;
    if (
      compareDateTimeRange(this.state.display_from, this.state.display_to, true) &&
      validTabsLanguages &&
      this.validator.check(this.state.country_id, 'required') &&
      this.validator.check(this.state.geofence_ids, 'required') &&
      this.validator.check(this.state.display_from, 'required') &&
      this.validator.check(this.state.display_to, 'required') &&
      this.validator.check(this.state.setting_language_ids, 'required') &&
      this.validator.check(this.state.display_method, 'required') &&
      this.validator.check(this.state.display_on_ids, 'required')
    ) {
      this.setState({
        flgRegister: true,
        message: 'news_management.messageCode.createConfirm',
      });
    }
  };

  /**
   * checkEnoughData
   * @return {Boolean}
   */
  checkEnoughData = () => {
    for (const language of this.state.news_languages) {
      for (const field in language) {
        if (!language[field]) return false;
      }
    }
    return true;
  };

  /**
   * closeUpdateModal
   */
  closeUpdateModal = () => {
    this.setState({flagUpdate: false});
  };

  /**
   * closeRegisterModal
   */
  closeRegisterModal = () => {
    this.setState({flgRegister: false});
  };

  /**
   * handleSelectTemplate
   */
  handleSelectTemplate = async () => {
    await this.setState({templateId: this.state.tempTemplateId});
    if (this.state.templateId) {
      const item = this.props?.news_templates?.templates?.content?.find((item) => item.id === this.state.templateId);
      const newData = this.state.news_languages?.map((item2) => ({
        ...item2,
        content: item.lang_contents?.find((i) => i.language_id === item2.language_id)?.content,
        title: item.lang_contents?.find((i) => i.language_id === item2.language_id)?.title,
      }));
      this.setState({
        flgTemplate: false,
        news_languages: newData,
      });
    }
  };

  /**
   * onChangeDataNotification
   * @param {*} event
   */
  onChangeDataNotification = (event) => {
    this.setState({
      ...this.state,
      push_notification: event.target.value === 'true',
    });
  };

  /**
   * getListAllGeofence
   * @return {Array}
   */
  getListAllGeofence = () => {
    const listGeofence = [
      ...this.state.listGeofenceAll.filter((geofence) => Number(geofence.country_id) === Number(this.state.country_id)),
      ...this.state.listGeofencesNotInFacility,
    ];
    return !!this.props.match.params.id && Number(this.state.dataDetail?.country_id) === Number(this.state.country_id) ?
      getListAllGeofenceDetail(listGeofence, this.state.dataDetail?.geofence_infos) :
      listGeofence;
  };

  /**
   * Render Component
   * @return {HTML}
   */
  render() {
    const {t, common, actions, news_templates} = this.props;
    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
    };
    const isAllGeofencesInFacility = this.state.listGeofencesNotInFacility.length === 0;
    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{this.props.match.params.id ? t('route.newsDetail') : t('route.createNews')}</h3>
            </Grid>
          </Grid>
          <br></br>
          <Container maxWidth="xl">
            <LoadingOverlay active={this.state.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
              <Card raised>
                <Container maxWidth="xl">
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.openModal}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      zIndex: '1000',
                    }}
                  >
                    <>
                      <ImageUpload
                        onClickOk={this.handleButtonCloseModal}
                        onClickClose={this.handleButtonCloseModal}
                        selectImage={(value) => this.setState({image_master_id: value.id, imageSrc: value.url})}
                      ></ImageUpload>
                    </>
                  </Modal>
                </Container>
                <Container maxWidth="xl">
                  <br></br>
                  <Paper className="search_table">
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                        {t('news_management.form_title')}
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container spacing={1} item xs={12}>
                        <Grid container spacing={1} item xs={6} lg={8}>
                          {/* Country */}
                          <Grid container spacing={1} className="row_form_item ">
                            <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                              {t('common.country')}
                              <span className="font_color_red">＊</span>
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={8}>
                              <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                                <Select
                                  margin="dense"
                                  inputProps={{
                                    name: 'country_id',
                                  }}
                                  displayEmpty
                                  renderValue={
                                    this.state.country_id ?
                                      undefined :
                                      () => (
                                          <div className="font-12 color-disabled">
                                            {t('placeholder.required_select', {
                                              field: t('common.country'),
                                            })}
                                          </div>
                                        )
                                  }
                                  disabled={!isRoleGlobal()}
                                  value={this.state.country_id}
                                  onChange={(event) => this.handleOnChangeCountry(event.target.value)}
                                >
                                  {common?.country_code?.map((item, idx) => (
                                    <MenuItem value={item.id} key={idx}>
                                      {t(`${item.country_code}`)}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {this.validator.message('country_id', this.state.country_id, 'required')}
                                {this.state.isSubmitForm && !this.validator.check(this.state.country_id, 'required') && (
                                  <FormHelperText id="country_id" error>
                                    {t('validation.required.choose', {field: t('common.country')})}
                                  </FormHelperText>
                                )}
                              </FormControl>
                              {this.state.country_id && (
                                <FormControlLabel
                                  className="padding-left-24"
                                  labelPlacement="end"
                                  control={
                                    <Checkbox checked={this.state.all_geofence} onClick={(event) => this.checkAllGeofence(event.target.checked)} className="checkbox_radio" />
                                  }
                                  label={t('news_management.all_geofence')}
                                />
                              )}
                            </Grid>
                          </Grid>

                          {/* Geofence*/}
                          <Grid container spacing={1} className="row_form_item">
                            <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                              {t('business_plan.geofence')} <span className="font_color_red">＊</span>
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={8}>
                              <FormControl error>
                                <FormGroup row>
                                  {this.state.country_id !== '' ? (
                                    this.getListAllGeofence().map((item, index) => {
                                      const checked = this.state.geofence_ids?.indexOf(item.geofence_id) > -1;
                                      const isGeofenceInFacility = this.props.common.principal?.geofence_ids?.includes(item.geofence_id);
                                      return (
                                        <FormControlLabel
                                          key={index}
                                          labelPlacement="end"
                                          control={
                                            <Checkbox
                                              disabled={!item.enable || !item.enable_for_admin || (isRoleBusiness() && !isGeofenceInFacility)}
                                              checked={checked}
                                              onClick={(event) => this.handleSelectGeofence(event.target.checked, item.geofence_id)}
                                              className="checkbox_radio"
                                            />
                                          }
                                          label={item.name}
                                        />
                                      );
                                    })
                                  ) : (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('common.country'),
                                      })}
                                    </div>
                                  )}
                                </FormGroup>
                                {this.validator.message('geofence_ids', this.state.geofence_ids, 'required')}
                                {this.state.isSubmitForm && !this.validator.check(this.state.geofence_ids, 'required') && (
                                  <FormHelperText id="geofence_ids" error>
                                    {t('validation.required.choose', {field: t('business_plan.geofence')})}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                          </Grid>

                          {/* display time */}
                          <Grid container spacing={1} className="row_form_item">
                            <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                              {t('news_management.display_time')}
                              <span className="font_color_red">＊</span>
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={8}>
                              <FormControl>
                                <Grid container alignItems="stretch" item xs={12} lg={12}>
                                  <FormControl margin="dense" className="field_size_10 field_min_size_250">
                                    <KeyboardDatePicker
                                      KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                      }}
                                      disableToolbar
                                      placeholder={t('sub.effective_date_from')}
                                      variant="inline"
                                      inputVariant="outlined"
                                      autoOk
                                      value={this.state.display_from}
                                      onChange={(time) => this.onChangeDisplayTime(time, true)}
                                      format={DATE_FORMAT}
                                      minDate={new Date()}
                                      invalidDateMessage={t('errorFields.invalidDateMessage')}
                                      maxDateMessage={t('errorFields.maxDateMessage')}
                                      minDateMessage={t('errorFields.minDateMessage')}
                                    />
                                    {this.validator.message('start_time', this.state.display_from, 'required')}
                                    {this.state.isSubmitForm && !this.validator.check(this.state.display_from, 'required') && (
                                      <FormHelperText id="start_time" error>
                                        {t('validation.required', {field: t('business_plan.start_time')})}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                  <span style={{padding: '5px'}} />
                                  <FormControl margin="dense" className="field_size_10 field_min_size_250">
                                    <KeyboardDatePicker
                                      KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                      }}
                                      disableToolbar
                                      placeholder={t('sub.effective_date_to')}
                                      variant="inline"
                                      inputVariant="outlined"
                                      autoOk
                                      value={this.state.display_to}
                                      onChange={(time) => this.onChangeDisplayTime(time, false)}
                                      format={DATE_FORMAT}
                                      minDate={this.state.display_from ? this.state.display_from : new Date(new Date().getTime() + 86400000)}
                                      maxDate={getMaxDate(this.state.display_from)}
                                      invalidDateMessage={t('errorFields.invalidDateMessage')}
                                      maxDateMessage={t('errorFields.maxDateMessage')}
                                      minDateMessage={t('errorFields.minDateMessage')}
                                    />
                                    {this.validator.message('end_time', this.state.display_to, 'required')}
                                    {this.state.isSubmitForm && !this.validator.check(this.state.display_to, 'required') && (
                                      <FormHelperText id="end_time" error>
                                        {t('validation.required', {field: t('business_plan.end_time')})}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                                {this.state.isSubmitForm && !compareDateTimeRange(this.state.display_from, this.state.display_to, true) && (
                                  <FormHelperText id="start_time" error>
                                    {t('validation.invalid.timeRange')}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                          </Grid>

                          {/* Display Method */}
                          <Grid container spacing={1} className="row_form_item">
                            <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                              {t('news_management.display_method')}
                              <span className="font_color_red">＊</span>
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={8}>
                              <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                                <Select
                                  margin="dense"
                                  inputProps={{
                                    name: 'display_method',
                                  }}
                                  displayEmpty
                                  renderValue={
                                    this.state.display_method ?
                                      undefined :
                                      () => (
                                          <div className="font-12 color-disabled">
                                            {t('placeholder.required_select', {
                                              field: t('news_management.display_method'),
                                            })}
                                          </div>
                                        )
                                  }
                                  value={this.state.display_method}
                                  onChange={(e) => onChangeSelect(this, e)}
                                >
                                  {NEWS_DISPLAY_METHOD?.map((item, index) => (
                                    <MenuItem key={index} value={item.value}>
                                      {t(`${item.i18n}`)}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {this.validator.message('display_method', this.state.display_method, 'required')}
                                {this.state.isSubmitForm && !this.validator.check(this.state.display_method, 'required') && (
                                  <FormHelperText id="display_method" error>
                                    {t('validation.required.choose', {field: t('news_management.display_method')})}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                          </Grid>

                          {/* Display On */}
                          <Grid container spacing={1} className="row_form_item">
                            <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                              {t('news_management.display_on')}
                              <span className="font_color_red">＊</span>
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={8}>
                              <FormControl>
                                <FormGroup row>
                                  {NEWS_DISPLAY_ON?.map((item, index) => {
                                    const checked = this.state.display_on_ids?.indexOf(item.value) > -1;
                                    return (
                                      <FormControlLabel
                                        key={index}
                                        labelPlacement="end"
                                        control={
                                          <Checkbox
                                            checked={checked}
                                            onClick={(event) => this.handleSelectDisplayOn(event.target.checked, item.value)}
                                            className="checkbox_radio"
                                          />
                                        }
                                        label={item.value}
                                      />
                                    );
                                  })}
                                </FormGroup>
                                {this.validator.message('display_on_ids', this.state.display_on_ids, 'required')}
                                {this.state.isSubmitForm && !this.validator.check(this.state.display_on_ids, 'required') && (
                                  <FormHelperText id="display_on_ids" error>
                                    {t('validation.required.choose', {field: t('news_management.display_on')})}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                          </Grid>

                          {/* Language Setting */}
                          <Grid container spacing={1} className="row_form_item">
                            <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                              {t('service.language')}
                              <span className="font_color_red">＊</span>
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={8}>
                              <FormControl>
                                <FormGroup row>
                                  {NEWS_SETTING_LANGUAGE?.map((item, index) => {
                                    const checked = this.state.setting_language_ids?.indexOf(item.id) > -1;
                                    return (
                                      <FormControlLabel
                                        key={index}
                                        labelPlacement="end"
                                        control={
                                          <Checkbox checked={checked} onClick={(event) => this.handleSelectLanguage(event.target.checked, item.id)} className="checkbox_radio" />
                                        }
                                        label={t(`${item.i18n}`)}
                                      />
                                    );
                                  })}
                                </FormGroup>
                                {this.validator.message('setting_language_ids', this.state.setting_language_ids, 'required')}
                                {this.state.isSubmitForm && !this.validator.check(this.state.setting_language_ids, 'required') && (
                                  <FormHelperText id="setting_language_ids" error>
                                    {t('validation.required.choose', {field: t('service.language')})}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </Grid>
                          </Grid>

                          {/* Push notification */}
                          <Grid container spacing={1} className="row_form_item">
                            <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                              {t('news_management.push_notification')}
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={8}>
                              <FormControl>
                                <RadioGroup row name="push_notification">
                                  {NEWS_PUSH_NOTIFICATION?.map(({value, i18n}, index) => {
                                    return (
                                      <FormControlLabel
                                        value={value}
                                        key={index}
                                        onChange={(event) => this.onChangeDataNotification(event)}
                                        control={
                                          <Radio
                                            className="checkbox_radio"
                                            checked={this.state.push_notification === value}
                                            disabled={this.state.display_from ? convertDateTimeToDate(this.state.display_from) !== convertDateTimeToDate(new Date()) : true}
                                          />
                                        }
                                        label={<span className="font_size_small_regular">{t(`${i18n}`)}</span>}
                                      />
                                    );
                                  })}
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <p className="grid_title_padding">
                              <i>{t('news_management.push_notification_description')}</i>
                            </p>
                          </Grid>

                          {/* Using Template */}
                          <Grid container spacing={1} className="row_form_item">
                            <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                              {t('news_management.using_template')}
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={8}>
                              <Button
                                color="primary"
                                variant="contained"
                                className="button_margin"
                                onClick={() => this.setState({flgTemplate: true, tempTemplateId: this.state.templateId})}
                              >
                                {t('template_management.list')}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* multiple languages tabs */}
                        <Grid container spacing={1} className="row_form_item">
                          <Grid container item alignItems="center" xs={12}>
                            <Tabs>
                              <TabList>
                                {this.state.setting_language_ids?.includes(1) && (
                                  <Tab onClick={(event) => this.setState({tab: 1})}>
                                    <Badge
                                      color="secondary"
                                      badgeContent={this.countTabsValidate(
                                        this.state.news_languages?.find((e) => e.language_id === 1),
                                        this.state.isSubmitForm,
                                      )}
                                    >
                                      <div style={{padding: '6px 12px'}}>
                                        <span>{t('common.ja')}</span>
                                      </div>
                                    </Badge>
                                  </Tab>
                                )}
                                {this.state.setting_language_ids?.includes(2) && (
                                  <Tab onClick={(event) => this.setState({tab: 2})}>
                                    <Badge
                                      color="secondary"
                                      badgeContent={this.countTabsValidate(
                                        this.state.news_languages?.find((e) => e.language_id === 2),
                                        this.state.isSubmitForm,
                                      )}
                                    >
                                      <div style={{padding: '6px 12px'}}>
                                        <span>{t('common.en')}</span>
                                      </div>
                                    </Badge>
                                  </Tab>
                                )}
                                {this.state.setting_language_ids?.includes(3) && (
                                  <Tab onClick={(event) => this.setState({tab: 3})}>
                                    <Badge
                                      color="secondary"
                                      badgeContent={this.countTabsValidate(
                                        this.state.news_languages?.find((e) => e.language_id === 3),
                                        this.state.isSubmitForm,
                                      )}
                                    >
                                      <div style={{padding: '6px 12px'}}>
                                        <span>{t('common.vi')}</span>
                                      </div>
                                    </Badge>
                                  </Tab>
                                )}
                              </TabList>
                              {this.state.setting_language_ids?.includes(1) &&
                                this.tabPanel(
                                  this.state.news_languages?.find((e) => e.language_id === 1),
                                  1,
                                )}
                              {this.state.setting_language_ids?.includes(2) &&
                                this.tabPanel(
                                  this.state.news_languages?.find((e) => e.language_id === 2),
                                  2,
                                )}
                              {this.state.setting_language_ids?.includes(3) &&
                                this.tabPanel(
                                  this.state.news_languages?.find((e) => e.language_id === 3),
                                  3,
                                )}
                            </Tabs>
                            {this.state.isSubmitForm &&
                              !(
                                this.countTabsValidate(
                                  this.state.news_languages?.find((e) => e.language_id === 1),
                                  true,
                                ) === 0 ||
                                this.countTabsValidate(
                                  this.state.news_languages?.find((e) => e.language_id === 2),
                                  true,
                                ) === 0 ||
                                this.countTabsValidate(
                                  this.state.news_languages?.find((e) => e.language_id === 3),
                                  true,
                                ) === 0
                              ) && (
                                <FormHelperText id="start_time" error>
                                  {t('news_management.valid_multiple_languages')}
                                </FormHelperText>
                              )}
                          </Grid>
                        </Grid>
                        <Grid container spacing={1} item xs={6} lg={8}>
                          {/* Image link */}
                          <Grid container spacing={1} className="row_form_item">
                            <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                              <Button variant="contained" color="primary" component="span" onClick={() => this.handleChooseImage()}>
                                {t('common.photo')}
                              </Button>
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={9} className="display_grid">
                              <label htmlFor="icon-button-file">
                                <IconButton color="primary" aria-label="upload picture" component="span">
                                  {this.state.imageSrc ? (
                                    <div className="box_image" style={{width: '100%', height: '200px'}}>
                                      <img className="cropped_image" style={{width: 'inherit', height: 'inherit'}} src={this.state.imageSrc} alt="cropped" />
                                    </div>
                                  ) : (
                                    <PhotoCamera />
                                  )}
                                </IconButton>
                              </label>
                              <span className="font_size_small">{this.state.imageSrc}</span>
                            </Grid>
                          </Grid>

                          {/* Status */}
                          <Grid container spacing={1} className="row_form_item">
                            <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                              {t('common.status')}
                            </Grid>
                            <Grid container alignItems="center" item xs={6} lg={8}>
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      color="primary"
                                      disabled={
                                        !compareDateTimeRange(new Date(), convertStartEndTime(this.state.display_to, false), true) ||
                                        !(
                                          this.countTabsValidate(this.state.news_languages[0], true) === 0 ||
                                          this.countTabsValidate(this.state.news_languages[1], true) === 0 ||
                                          this.countTabsValidate(this.state.news_languages[2], true) === 0
                                        )
                                      }
                                      checked={this.state.is_active}
                                      onChange={() => this.setState({is_active: !this.state.is_active})}
                                    />
                                  }
                                />
                              </FormGroup>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* Select Modal */}
                    <Dialog
                      open={this.state.flagUpdate}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: '1020',
                      }}
                    >
                      <SelectModal
                        onClickOk={this.handleSubmitForm}
                        onClickCancel={this.closeUpdateModal}
                        message={this.state.message}
                        okButtonText={t('common.btnYes')}
                        cancelButtonText={t('common.btnNo')}
                        isDisableCreate={this.state.isDisableCreate}
                      ></SelectModal>
                    </Dialog>
                    {/* Select Modal */}
                    <Dialog
                      open={this.state.flgRegister}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: '1020',
                      }}
                    >
                      <SelectModal
                        onClickOk={this.handleSubmitForm}
                        onClickCancel={this.closeRegisterModal}
                        message={this.state.message}
                        okButtonText={t('common.btnYes')}
                        cancelButtonText={t('common.btnNo')}
                        isDisableCreate={this.state.isDisableCreate}
                      ></SelectModal>
                    </Dialog>
                    {/* Select Template List */}
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={this.state.flgTemplate}
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 500,
                      }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: '1000',
                      }}
                    >
                      <Container>
                        <Card>
                          <Container>
                            <Grid container spacing={1} alignItems="center" item xs={12}>
                              <h3>{t('template_management.list')}</h3>
                            </Grid>
                            <div className="scroll_area_500">
                              <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                                <TableHead>
                                  <TableRow>
                                    <TableCell className="width_50p"></TableCell>
                                    <TableCell className="width_200p">{t('template_management.name')} (JP)</TableCell>
                                    <TableCell className="width_200p">{t('template_management.name')} (VN)</TableCell>
                                    <TableCell className="width_200p">{t('template_management.name')} (EN)</TableCell>
                                    <TableCell className="width_200p">{t('template_management.title')} (JP)</TableCell>
                                    <TableCell className="width_200p">{t('template_management.title')} (VN)</TableCell>
                                    <TableCell className="width_200p">{t('template_management.title')} (EN)</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {news_templates?.templates?.content?.map((item, index) => (
                                    <TableRow key={index}>
                                      <TableCell className="width_50p">
                                        <Radio
                                          className="checkbox_radio"
                                          checked={this.state.tempTemplateId === item.id}
                                          onClick={() => this.setState({tempTemplateId: item.id})}
                                        />
                                      </TableCell>
                                      <TableCell className="width_200p">
                                        {item.lang_contents?.find((i) => i.language_id === LANGUAGE.find((i2) => i2.code === 'ja')?.id)?.name || DATA_NULL}
                                      </TableCell>
                                      <TableCell className="width_200p">
                                        {item.lang_contents?.find((i) => i.language_id === LANGUAGE.find((i2) => i2.code === 'vi')?.id)?.name || DATA_NULL}
                                      </TableCell>
                                      <TableCell className="width_200p">
                                        {item.lang_contents?.find((i) => i.language_id === LANGUAGE.find((i2) => i2.code === 'en')?.id)?.name || DATA_NULL}
                                      </TableCell>
                                      <TableCell className="width_200p">
                                        {item.lang_contents?.find((i) => i.language_id === LANGUAGE.find((i2) => i2.code === 'ja')?.id)?.title || DATA_NULL}
                                      </TableCell>
                                      <TableCell className="width_200p">
                                        {item.lang_contents?.find((i) => i.language_id === LANGUAGE.find((i2) => i2.code === 'vi')?.id)?.title || DATA_NULL}
                                      </TableCell>
                                      <TableCell className="width_200p">
                                        {item.lang_contents?.find((i) => i.language_id === LANGUAGE.find((i2) => i2.code === 'en')?.id)?.title || DATA_NULL}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </div>
                            <br />
                            <Grid container spacing={1} alignItems="center" justify="center" item xs={12}>
                              <Button color="primary" variant="contained" className="button_margin" onClick={() => this.handleSelectTemplate()}>
                                {t('common.btnOk')}
                              </Button>
                              <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.setState({flgTemplate: false})}>
                                {t('common.btnCancel')}
                              </Button>
                            </Grid>
                            <br />
                          </Container>
                        </Card>
                      </Container>
                    </Modal>
                  </Paper>
                  <Grid container spacing={1}>
                    <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                    <Grid container alignItems="center" justify="flex-end" item xs={9}>
                      {!this.props.match.params.id ? (
                        <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={() => this.openRegisterModal()}>
                          {t('common.btnRegister')}
                        </Button>
                      ) : (
                        permission.canUpdate &&
                        isAllGeofencesInFacility && (
                          <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={() => this.openUpdateModal()}>
                            {t('common.btnUpdate')}
                          </Button>
                        )
                      )}
                      <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.props.history.goBack}>
                        {t('common.btnReturn')}
                      </Button>
                    </Grid>
                  </Grid>
                </Container>
              </Card>
            </LoadingOverlay>
          </Container>
        </Container>
      </Card>
    );
  }
}

const mapPropsToState = (state) => {
  return {
    common: state.common,
    news_templates: state.news_templates,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getAllGeofence: () => dispatch(getAllGeofence()),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    getNewsTemplates: () => dispatch(getNewsTemplates()),
    getNewsDetail: (id, browserHistory) => dispatch(getNewsDetail(id, browserHistory)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapPropsToState, mapDispatchToProps)(Index)));
