import React, {Component} from 'react';

import {
  Backdrop,
  Badge,
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  Dialog,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  Modal,
  TextField,
} from '@material-ui/core';
import {Add as AddIcon, CloudUpload as CloudUploadIcon, PhotoCamera} from '@material-ui/icons';
import JoditEditor from 'jodit-react';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import SimpleReactValidator from 'simple-react-validator';

import {NEWS_SETTING_LANGUAGE, PERMISSION_ACTIONS, ROUTE} from '../../../common/constant';
import ImageUpload from '../../../components/imageSelect';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getNewsTemplateById, upsertNewsTemplate} from '../../../stores/news_template/action';
import {backForwardRouter, onChangeListData} from '../../../utils/common';
import {isRoleBusiness} from '../../../utils/role';

const JODIT_CONFIG = {
  controls: {
    style: {
      font: 'Roboto, Helvetica, Arial, sans-serif',
    },
    font: {
      list: {
        '': 'Default',
        'Roboto, Helvetica, Arial, sans-serif': 'Roboto',
        'Helvetica,sans-serif': 'Helvetica',
        'Arial,Helvetica,sans-serif': 'Arial',
        'Georgia,serif': 'Georgia',
        'Impact,Charcoal,sans-serif': 'Impact',
        'Tahoma,Geneva,sans-serif': 'Tahoma',
        // eslint-disable-next-line quotes
        "'Times New Roman',Times,serif": 'Times New Roman',
        'Verdana,Geneva,sans-serif': 'Verdana',
      },
    },
  },
  buttons: [
    'source',
    '|',
    'bold',
    'italic',
    '|',
    'ul',
    'ol',
    '|',
    'font',
    'fontsize',
    'brush',
    'paragraph',
    '|',
    'image',
    'video',
    'table',
    'link',
    '|',
    'left',
    'center',
    'right',
    'justify',
    '|',
    'undo',
    'redo',
    '|',
    'hr',
    'eraser',
    'fullsize',
  ],
  readonly: false,
  removeButtons: ['table'],
};

const TYPES = {
  CREATE: 1,
  UPDATE: 2,
};

/**
 * Template Setting Create/Update Component
 */
class UpsertNewsTemplate extends Component {
  /**
   * Constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      languageIds: [1],
      languageContents: NEWS_SETTING_LANGUAGE.map((language) => ({
        language_id: language.id,
        name: '',
        title: '',
        content: '',
      })),

      isSubmitForm: false,
      isLangCheckedMin: false,
      updateModalOpened: false,
      openModal: false,
    };
    this.validator = new SimpleReactValidator({
      validators: {
        not_base64: {
          message: 'The :attribute must be a valid base64 string',
          rule: (val) => {
            // Base64 regex pattern
            const base64Pattern = /src="(data:image\/[^;]+;base64[^"]+)"/i;
            return !base64Pattern.test(val);
          },
        },
      },
    });
    this.config = JODIT_CONFIG;
    this.type = this.props.match.url.includes('add') ? TYPES.CREATE : TYPES.UPDATE;
  }

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    if (this.props.match.params.id) {
      await this.props.getNewsTemplateById(this.props.match.params.id).then((result) => {
        const sorted_lang_contents = result.lang_contents.sort((first_lang, second_lang) => Number(first_lang.language_id) - Number(second_lang.language_id));
        const languageIds = sorted_lang_contents.map((content) => content.language_id);
        const languageContents = NEWS_SETTING_LANGUAGE.map((language) => {
          if (languageIds.includes(language.id)) {
            const content = sorted_lang_contents.find((content) => content.language_id === language.id);
            return {
              language_id: language.id,
              name: content.name,
              title: content.title,
              content: content.content,
            };
          } else {
            return {
              language_id: language.id,
              name: '',
              title: '',
              content: '',
            };
          }
        });
        this.setState({
          languageIds,
          languageContents,
        });
      });
    }
  };

  /**
   * countValidationBadge
   * @param {Object} data
   * @param {Boolean} isSubmitForm
   * @return {Number}
   */
  countValidationBadge = (data, isSubmitForm) => {
    const array = [
      this.validator.check(data?.name, 'required|max:50'),
      this.validator.check(data?.title, 'required|max:50'),
      this.validator.check(data?.content, 'required'),
      this.validator.check(data?.content, 'not_base64'),
    ];
    return isSubmitForm ? array?.filter((e) => e === false).length : 0;
  };

  /**
   * Handle create/update news template
   */
  handleUpsertNewsTemplate = async () => {
    const {languageContents, languageIds} = this.state;
    const lang_contents = languageContents.filter((content) => languageIds.includes(content.language_id));
    const payload =
      this.type === TYPES.UPDATE ?
        {
            id: Number(this.props.match.params.id),
            isActive: true,
            lang_contents,
          } :
        {
            isActive: true,
            lang_contents,
          };
    await this.props.upsertNewsTemplate(payload);
    if (this.type === TYPES.UPDATE) {
      await this.props.getNewsTemplateById(this.props.match.params.id);
    } else {
      backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.TEMPLATE_SETTING_MANAGEMENT);
    }
    this.setState({updateModalOpened: false});
  };

  /**
   * Handle click create/update button
   */
  handleClickUpsertButton = () => {
    this.setState({isSubmitForm: true}, () => {
      const v = this.validator;
      const {languageContents, languageIds} = this.state;
      for (const content of languageContents) {
        if (
          languageIds.includes(content.language_id) &&
          (!v.check(content.name, 'required|max:50') || !v.check(content.title, 'required|max:50') || !v.check(content.content, 'required') || !v.check(content.content, 'not_base64'))
        ) {
          return;
        }
      }
      this.setState({updateModalOpened: true});
    });
  };

  /**
   * Handle check/uncheck language checkbox
   * @param {Boolean} checked
   * @param {Number} languageId
   */
  handleCheckLanguage = (checked, languageId) => {
    const {languageIds} = this.state;
    if (languageIds.length === 1 && checked === false) {
      this.setState({isLangCheckedMin: true});
      return;
    }
    const languageIdsCopy = [...languageIds];
    const idx = languageIds.indexOf(languageId);
    checked ? languageIdsCopy.push(languageId) : languageIdsCopy.splice(idx, 1);
    this.setState({
      isLangCheckedMin: false,
      languageIds: languageIdsCopy,
    });
  };

  /**
   * handleChooseImage
   */
  handleChooseImage = () => {
    this.setState({
      openModal: true,
    });
  };

  /**
   * handleButtonCloseModal
   */
  handleButtonCloseModal = () => {
    this.setState({
      openModal: false,
    });
  };

  /**
   * Render Component
   * @return {HTML}
   */
  render() {
    const {t, isCreateDisabled, actions} = this.props;
    const {languageIds, languageContents, isSubmitForm, isLangCheckedMin} = this.state;
    const permissions = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE_TEMPLATE),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.templateSettingDetail')}</h3>
            </Grid>
          </Grid>
        </Container>
        <br></br>
        <Box mx={7}>
          <Card raised>
            <br />
            <Container maxWidth="xl">
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={this.state.openModal}
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '1000',
                }}
              >
                <>
                  <ImageUpload
                    onClickOk={this.handleButtonCloseModal}
                    onClickClose={this.handleButtonCloseModal}
                    selectImage={(value) => this.setState({image_master_id: value.id, imageSrc: value.url})}
                  ></ImageUpload>
                </>
              </Modal>
            </Container>
            <Container maxWidth="xl">
              <Grid container className="row_form_item product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                {t('template_management.template_form')}
              </Grid>
              <Grid container className="row_form_item">
                <Grid container item xs={6} lg={2} alignItems="center" className="grid_title_padding">
                  <Box py={2}>
                    {t('template_management.language')}
                    <span className="font_color_red">＊</span>
                  </Box>
                </Grid>
                <Grid container item xs={6} alignItems="center">
                  <FormControl>
                    <Box pl={1}>
                      <FormGroup row>
                        {NEWS_SETTING_LANGUAGE.map((language) => {
                          const checked = languageIds.includes(language.id);
                          return (
                            <FormControlLabel
                              key={language.id}
                              label={t(language.i18n)}
                              labelPlacement="end"
                              control={<Checkbox checked={checked} onClick={(event) => this.handleCheckLanguage(event.target.checked, language.id)} className="checkbox_radio" />}
                            />
                          );
                        })}
                      </FormGroup>
                    </Box>
                    {isLangCheckedMin && (
                      <FormHelperText error>
                        {t('validation.required.select_at_least_one', {
                          field: t('template_management.lowercase_language'),
                        })}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <br />
              <Grid container spacing={2} className="row_form_item" alignItems="flex-start">
                {/* Language Content Tabs */}
                <Tabs>
                  <TabList>
                    {NEWS_SETTING_LANGUAGE.map((language) => {
                      return (
                        languageIds.includes(language.id) && (
                          <Tab key={language.id}>
                            <Badge
                              color="secondary"
                              badgeContent={this.countValidationBadge(
                                languageContents.find((content) => content.language_id === language.id),
                                isSubmitForm,
                              )}
                            >
                              <div style={{padding: '6px 12px'}}>
                                <span>{t(language.i18n)}</span>
                              </div>
                            </Badge>
                          </Tab>
                        )
                      );
                    })}
                  </TabList>
                  {NEWS_SETTING_LANGUAGE.map((language) => {
                    const content = languageContents.find((content) => content.language_id === language.id);
                    return (
                      languageIds.includes(language.id) && (
                        <TabPanel key={language.id}>
                          <Grid container alignItems="flex-start">
                            <Grid container item xs={6} lg={8} alignItems="flex-start">
                              {/* Name */}
                              <Grid container item spacing={1}>
                                <Grid container alignItems="center" item xs={3} className="grid_title_padding">
                                  {t('template_management.name')}
                                  <span className="font_color_red">＊</span>
                                </Grid>
                                <Grid container alignItems="center" item xs={9}>
                                  <FormControl variant="outlined" margin="dense">
                                    <div className="box-group-input">
                                      <TextField
                                        className="field_size_10 field_min_size_300"
                                        margin="dense"
                                        variant="outlined"
                                        placeholder={t('validation.required', {field: t('template_management.name')})}
                                        value={content?.name}
                                        onChange={(event) =>
                                          onChangeListData(
                                            this,
                                            languageContents,
                                            'name',
                                            languageContents.findIndex((langContent) => langContent.language_id === language.id),
                                            event.target.value,
                                          )
                                        }
                                      />
                                      <div className="max-length-label">{t('validation.max.label', {value: 50})}</div>
                                    </div>
                                    {this.validator.message('name', content?.name, 'required|max:50')}
                                    {isSubmitForm && !this.validator.check(content?.name, 'required') && (
                                      <FormHelperText id="name" error>
                                        {t('validation.required', {field: t('template_management.name')})}
                                      </FormHelperText>
                                    )}
                                    {isSubmitForm && this.validator.check(content?.name, 'required') && !this.validator.check(content.name.trim(), 'max:50') && (
                                      <FormHelperText id="name" error>
                                        {t('validation.maxString', {value: 50})}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                              </Grid>
                              {/* Title */}
                              <Grid container item spacing={1}>
                                <Grid container alignItems="center" item xs={3} className="grid_title_padding">
                                  {t('popup_recommend.title')}
                                  <span className="font_color_red">＊</span>
                                </Grid>
                                <Grid container alignItems="center" item xs={9}>
                                  <FormControl variant="outlined" margin="dense">
                                    <div className="box-group-input">
                                      <TextField
                                        className="field_size_10 field_min_size_300"
                                        margin="dense"
                                        variant="outlined"
                                        placeholder={t('validation.required', {field: t('popup_recommend.title')})}
                                        value={content?.title}
                                        multiline
                                        rows={3}
                                        onChange={(event) =>
                                          onChangeListData(
                                            this,
                                            languageContents,
                                            'title',
                                            languageContents.findIndex((langContent) => langContent.language_id === language.id),
                                            event.target.value,
                                          )
                                        }
                                      />
                                      <div className="max-length-label">{t('validation.max.label', {value: 50})}</div>
                                    </div>
                                    {this.validator.message('title', content?.title, 'required|max:50')}
                                    {isSubmitForm && !this.validator.check(content?.title, 'required') && (
                                      <FormHelperText id="title" error>
                                        {t('validation.required', {field: t('popup_recommend.title')})}
                                      </FormHelperText>
                                    )}
                                    {isSubmitForm && this.validator.check(content?.title, 'required') && !this.validator.check(content.title.trim(), 'max:50') && (
                                      <FormHelperText id="title" error>
                                        {t('validation.maxString', {value: 50})}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                              </Grid>
                              {/* Content */}
                              <Grid container spacing={1}>
                                <Grid container alignItems="center" item xs={3} className="grid_title_padding">
                                  {t('vehicles.content')} <span className="font_color_red">＊</span>
                                </Grid>
                                <Grid container alignItems="center" item xs={9}>
                                  <FormControl variant="outlined" margin="dense">
                                    <JoditEditor
                                      value={content?.content}
                                      config={this.config}
                                      tabIndex={1}
                                      onChange={(value) =>
                                        onChangeListData(
                                          this,
                                          this.state.languageContents,
                                          'content',
                                          this.state.languageContents.findIndex((langContent) => langContent.language_id === language.id),
                                          value,
                                        )
                                      }
                                    />
                                    {this.validator.message('content', content?.content, 'required')}
                                    {isSubmitForm && !this.validator.check(content?.content, 'required') && (
                                      <FormHelperText id="content" error>
                                        {t('validation.required', {field: t('vehicles.content')})}
                                      </FormHelperText>
                                    )}
                                    {!this.validator.check(content?.content, 'not_base64') && (
                                      <FormHelperText id="base64_content" error>
                                        {t('validation.base64_first_content')}
                                        <br />
                                        {t('validation.base64_second_content')}
                                      </FormHelperText>
                                    )}
                                  </FormControl>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid container spacing={1} item xs={6} lg={4}>
                              <div className="iphone-wrapper">
                                <div className="iphone-x">
                                  <div className="header" />
                                  <div className="inner-view" dangerouslySetInnerHTML={{__html: content?.content}} />
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </TabPanel>
                      )
                    );
                  })}
                </Tabs>
              </Grid>
              <br />
              <Grid container spacing={1} item xs={6} lg={8}>
                {/* Image link */}
                <Grid container spacing={1} className="row_form_item">
                  <Grid container alignItems="center" item xs={6} lg={3} className="grid_title_padding">
                    <Button variant="contained" color="primary" component="span" onClick={() => this.handleChooseImage()}>
                      {t('common.photo')}
                    </Button>
                  </Grid>
                  <Grid container alignItems="center" item xs={6} lg={9} className="display_grid">
                    <label htmlFor="icon-button-file">
                      <IconButton color="primary" aria-label="upload picture" component="span">
                        {this.state.imageSrc ? (
                          <div className="box_image" style={{width: '100%', height: '200px'}}>
                            <img className="cropped_image" style={{width: 'inherit', height: 'inherit'}} src={this.state.imageSrc} alt="cropped" />
                          </div>
                        ) : (
                          <PhotoCamera />
                        )}
                      </IconButton>
                    </label>
                    <span className="font_size_small">{this.state.imageSrc}</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid container item alignItems="center" justify="flex-end">
                  {this.type === TYPES.CREATE ? (
                    <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={this.handleClickUpsertButton}>
                      {t('common.btnRegister')}
                    </Button>
                  ) : (
                    permissions.canUpdate &&
                    !isRoleBusiness() && (
                      <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={this.handleClickUpsertButton}>
                        {t('common.btnUpdate')}
                      </Button>
                    )
                  )}
                  <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.TEMPLATE_SETTING_MANAGEMENT}}>
                    <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.handleClickClose}>
                      {t('common.btnCancel')}
                    </Button>
                  </Link>
                </Grid>
              </Grid>
              <br />
            </Container>
          </Card>
          <br />
        </Box>
        <Dialog
          open={this.state.updateModalOpened}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
        >
          <>
            <SelectModal
              onClickOk={this.handleUpsertNewsTemplate}
              onClickCancel={() => this.setState({updateModalOpened: false})}
              okButtonText={t('common.btnYes')}
              cancelButtonText={t('common.btnNo')}
              message={this.type === TYPES.CREATE ? t('template_management.registerConfirm') : t('messageCode.updateConfirm', {field: t('template_management.template')})}
              isDisableCreate={isCreateDisabled}
            />
          </>
        </Dialog>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isCreateDisabled: state.news_templates.isCreateDisabled,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNewsTemplateById: (templateId) => dispatch(getNewsTemplateById(templateId)),
    upsertNewsTemplate: (payload, props) => dispatch(upsertNewsTemplate(payload, props)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(UpsertNewsTemplate)));
