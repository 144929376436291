import React, {Component} from 'react';

import {
  Card,
  Container,
  Grid,
  Paper,
  Table,
  TableCell,
  TableRow,
  Button,
  Select,
  MenuItem,
  FormControl,
  TableHead,
  TableBody,
  Dialog,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DetailIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {ROUTE, ROWS_PER_PAGE_OPTIONS, MAX_SIZE_OUTPUT, EXPORT_LIMIT_NUMBER_ROWS, CURRENCY,
 EXPORT_ALERT_NUMBER_ROWS, PERMISSION_ACTIONS, DATA_NULL, PAYMENT_METHODS_ONETIME_PRICE} from '../../../common/constant';
import CSVExporter from '../../../components/CSVExporter';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getListGeofence, getListGeofenceByCountry, getAllCountryCode, getAllGeofence} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {searchOneTimePriceList, exportOneTimePrice} from '../../../stores/one_time_price/actions';
import {changeUrlParams, getUrlParams, getQueryStringFromObject, customDisplayCurrency, onChangeSelect} from '../../../utils/common';
import {modalObj} from '../../../utils/modal';

/**
 * One Time Price Search Component
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      country_id: '',
      geofence_id: '',
      tax_rate: '',
      currency_code: '',
      payment_methods: [],
      listGeofenceAll: [],
      listCheckbox: [],
      checkbox: [],
      flag: false,
      message: '',
      result: [],
      isSearch: false,
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,
      flagExport: false,
      confirmedExport: false,
      latestSearchParams: null,
    };
    this.exportCSVRef = React.createRef();
    const {t} = this.props;
    this.exportHeaders = [
      {label: t('oneTimePrice.no'), key: 'id'},
      {label: t('common.country'), key: 'country_name'},
      {label: t('oneTimePrice.geofence'), key: 'geofence_name'},
      {label: t('oneTimePrice.adult_price'), key: 'adult_price'},
      {label: t('oneTimePrice.child_price'), key: 'child_price'},
      {label: t('oneTimePrice.paymentMethods'), key: 'payment_methods'},
    ];
  }

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    await this.props.getAllCountryCode();
    await this.props.getAllGeofence().then(() => this.setState({listGeofenceAll: this.props.common?.all_geofence}));
    if (this.props.location.search) {
      this.setState(getUrlParams(this.props.location.search), () => {
        this.state.country_id && this.props.getListGeofenceByCountry(this.state.country_id);
        if (this.state.country_id) {
          this.setState({
            listGeofenceAll: this.props.common?.all_geofence?.filter((e) => e.country_id === Number(this.state.country_id)),
          });
        }
        this.handleSearch();
      });
    }
  };

  /**
   * reset
   */
  reset = async () => {
    await this.props.getAllGeofence().then(() => this.setState({listGeofenceAll: this.props.common?.all_geofence}));
    this.setState({
      country_id: '',
      geofence_id: '',
      tax_rate: '',
      currency_code: '',
      payment_methods: [],
    });
  };

  /**
   * changeCountry
   * @param {*} event
   */
  changeCountry = (event) => {
    this.setState({
      country_id: event.target.value,
      listGeofenceAll: this.props.common?.all_geofence?.filter((item) => item.country_id === event.target.value),
      geofence_id: '',
    });
  };

  /**
   * handleButtonCancel
   * @param {event} value
   */
  changeBusiness = (value) => {
    this.setState({supplierName: value});
  };

  /**
   * handleSearch
   * @param {bool} reset
   */
  handleSearch = (reset) => {
    if (!this.props.actions.includes(PERMISSION_ACTIONS.SEARCH)) return;
    const payload = {
      country_id: this.state.country_id || null,
      geofence_id: this.state.geofence_id || null,
      payment_methods: this.state.payment_methods || [],
    };

    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const {currentPage, rowsPerPage} = this.state;
    const queryParams = reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage};
    this.props.searchOneTimePriceList(payload, queryParams, this.props).then((response) => {
      if (!response) {
        response = [];
      }
      this.setState(
        {
          result: response?.content,
          isSearch: true,
          totalRows: response.totalSize ? response.totalSize : 0,
          latestSearchParams: payload,
        },
        () => {
          const {country_id, geofence_id, payment_methods, currentPage, rowsPerPage} = this.state;
          // Apply changed params into url
          const queryParamsToChange = {
            country_id,
            geofence_id,
            payment_methods,
            currentPage: String(currentPage),
            rowsPerPage,
          };
          changeUrlParams(queryParamsToChange);
          const newSearchParams = getQueryStringFromObject(queryParamsToChange);
          // Save search params into state in order to pass to next page
          this.setState({searchParams: newSearchParams});
        },
      );
    });
  };

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearch);
  };

  /**
   * Fetch data to export
   */
  fetchDataExport = async () => {
    const queryParams = {page: 0, size: MAX_SIZE_OUTPUT};
    const dataExport = await this.props.exportFacility(this.state.latestSearchParams, queryParams);
    const convertedData = dataExport.map((row, index) => {
      const currency = CURRENCY.find((currency) => currency.id === row?.currency_code);
      row.payment_methods = row?.payment_methods?.join('\n');
      row.total = currency.getLabel((row?.price * (100 + row?.tax_rate)) / 100);
      row.price = currency.getLabel(row.price);
      row.tax_rate = row.tax_rate + '%';

      return row;
    });
    this.setState({confirmedExport: false});
    return convertedData;
  };

  /**
   * alertExportCSV
   */
  alertExportCSV = () => {
    const {t} = this.props;
    if (this.state.totalRows > EXPORT_LIMIT_NUMBER_ROWS) {
      this.props.setMessageModal(modalObj(true, t('messageCode.exportAlertLimit')));
    } else {
      const message = t('messageCode.exportAlert');
      this.setState({flagExport: true, message});
    }
  };

  /**
   * handleButtonExportOk
   */
  handleButtonExportOk = () => {
    this.setState({flagExport: false, confirmedExport: true}, () => {
      this.exportCSVRef.current.onClickExport();
    });
  };

  /**
   * handleButtonExportCancel
   */
  handleButtonExportCancel = () => {
    this.setState({flagExport: false});
  };

  /**
   *
   * @param {boolean} value
   * @param {string} item
   */
  changePaymentMethods = (value, item) => {
    const payment_methods_copy = this.state.payment_methods;
    const index = payment_methods_copy.indexOf(item);
    value ? payment_methods_copy.push(item) : payment_methods_copy.splice(index, 1);

    this.setState({
      payment_methods: payment_methods_copy,
    });
  };

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {one_time_price, t, common, actions} = this.props;

    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canRegister: actions.includes(PERMISSION_ACTIONS.REGISTER),
      canCSVOutput: actions.includes(PERMISSION_ACTIONS.CSV_OUTPUT),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('oneTimePrice.informationTitle')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          <LoadingOverlay active={one_time_price.isLoading || common?.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('facility.searchTitle')}
                    </Grid>
                  </Grid>
                  {/* Country */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.country')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_350">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'country_id',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.country_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.country'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.country_id}
                          onChange={this.changeCountry}
                        >
                          {common.country_code?.map((item, idx) => {
                            return (
                              <MenuItem value={item.id} key={idx}>
                                {t(`${item.country_code}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Geofence */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('oneTimePrice.geofence')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_350">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'geofence_id',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.geofence_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('oneTimePrice.geofence'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.geofence_id}
                          onChange={(event) => onChangeSelect(this, event)}
                        >
                          {this.state.listGeofenceAll?.map((item, idx) => {
                            return (
                              <MenuItem value={item.geofence_id} key={idx} className={!item.enable ? 'disable-option-custom' : ''}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Payment Method */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('oneTimePrice.paymentMethods')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={10}>
                      <Box py={1}>
                        <FormGroup row>
                          {PAYMENT_METHODS_ONETIME_PRICE.map((item, index) => {
                            const checked = this.state.payment_methods.indexOf(item.id) > -1;
                            return (
                              <FormControlLabel
                                key={index}
                                labelPlacement="end"
                                control={
                                  <Checkbox
                                    key={index}
                                    checked={checked}
                                    onChange={(event) => this.changePaymentMethods(event.target.checked, item.id)}
                                    className="checkbox_radio"
                                  />
                                }
                                label={t(item.i18n)}
                              />
                            );
                          })}
                        </FormGroup>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.reset()} endIcon={<RotateLeftIcon />}>
                        {t('common.btnResetSearch')}
                      </Button>
                    )}
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {permission.canRegister && (
                      <Link
                        style={{textDecoration: 'none'}}
                        to={{pathname: ROUTE.LAYOUT + ROUTE.ONE_TIME_PRICE_CREATE, state: {from: this.props.location.pathname + '?' + this.state.searchParams}}}
                      >
                        <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />}>
                          {t('common.btnRegister')}
                        </Button>
                      </Link>
                    )}
                    {permission.canCSVOutput && permission.canSearch && (
                      <>
                        <div className={this.state.totalRows <= EXPORT_ALERT_NUMBER_ROWS || this.state.confirmedExport ? '' : 'hidden'}>
                          <CSVExporter
                            ref={this.exportCSVRef}
                            disabled={this.state.totalRows <= 0 || !this.state.latestSearchParams}
                            headers={this.exportHeaders}
                            fetchAction={this.fetchDataExport}
                            isFetchAsync={true}
                            screenName={t('oneTimePrice.informationTitle')}
                          />
                        </div>
                        {this.state.totalRows >= EXPORT_ALERT_NUMBER_ROWS && !this.state.confirmedExport && (
                          <Button
                            onClick={this.alertExportCSV}
                            disabled={this.state.totalRows <= 0}
                            color="primary"
                            variant="contained"
                            className="button_margin"
                            endIcon={<GetAppIcon className="csv-exporter-icon" />}
                          >
                            {t('calendar.csv_output')}
                          </Button>
                        )}
                      </>
                    )}
                    {permission.canSearch && (
                      <Button
                        color="primary"
                        variant="contained"
                        className="button_margin"
                        onClick={() => {
                          this.handleSearch(true);
                        }}
                        endIcon={<SearchIcon />}
                      >
                        {t('common.btnSearch')}
                      </Button>
                    )}
                  </Grid>
                </Grid>

                <br></br>
              </Container>
            </Card>
          </LoadingOverlay>
          <br></br>
          {this.state.isSearch ? (
            <Card raised>
              <Container maxWidth="xl">
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={6}>
                    <h3>
                      {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                    </h3>
                  </Grid>
                </Grid>
                <LoadingOverlay active={one_time_price.isLoading || common?.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                  {this.state.result?.length > 0 ? (
                    <div className="scroll_area_700">
                      <Table size="small" aria-label="sticky table" stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('oneTimePrice.no')}</TableCell>
                            <TableCell>{t('common.country')}</TableCell>
                            <TableCell>{t('oneTimePrice.geofence')}</TableCell>
                            <TableCell>{t('oneTimePrice.adult_price')}</TableCell>
                            <TableCell>{t('oneTimePrice.child_price')}</TableCell>
                            <TableCell>{t('oneTimePrice.paymentMethods')}</TableCell>
                            {permission.canEdit && (
                              <TableCell colSpan={2}>{t('common.action')}</TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.result?.map((row, index) => {
                            const payment_method = row?.payment_methods
                              .map((item) => PAYMENT_METHODS_ONETIME_PRICE.find((payment) => payment.id === item)?.i18n)
                              .map((item) => this.props.t(`${item}`))
                              .join('</br>');
                            const country = common?.country_code?.find((country) => country.id === row?.country_id)?.country_code;
                            return (
                              <TableRow key={index} hover className="cursor_pointer">
                                <TableCell scope="row">{row?.id}</TableCell>
                                <TableCell>{t(`${country}`)}</TableCell>
                                <TableCell>{row.geofence_name}</TableCell>
                                <TableCell>
                                  {(row.adult_price !== null && row.adult_price !== undefined) ? customDisplayCurrency(row.adult_price || 0, row?.currency_code) : DATA_NULL}
                                </TableCell>
                                <TableCell>
                                  {(row.child_price !== null && row.child_price !== undefined) ? customDisplayCurrency(row.child_price || 0, row?.currency_code) : DATA_NULL}
                                </TableCell>
                                <TableCell dangerouslySetInnerHTML={{__html: payment_method}}></TableCell>
                                {permission.canEdit && (
                                  <TableCell colSpan={2}>
                                    <Link
                                      style={{textDecoration: 'none'}}
                                      to={{
                                        pathname: ROUTE.LAYOUT + ROUTE.ONE_TIME_PRICE_DETAILS + '/' + row.id,
                                        state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                      }}
                                    >
                                      <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                        {t('common.btnEdit')}
                                      </Button>
                                    </Link>
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </div>
                  ) : (
                    false
                  )}
                  {this.state.result?.length > 0 ? (
                    <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                  ) : (
                    <></>
                  )}
                </LoadingOverlay>
                <br></br>
                {/* Export alert modal */}
                <Dialog
                  open={this.state.flagExport}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    onClickOk={this.handleButtonExportOk}
                    onClickCancel={this.handleButtonExportCancel}
                    okButtonText={t('common.btnYes')}
                    cancelButtonText={t('common.btnNo')}
                    message={this.state.message}
                  ></SelectModal>
                </Dialog>
              </Container>
              <br></br>
            </Card>
          ) : (
            false
          )}
          <br></br>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    one_time_price: state.one_time_price,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getListGeofence: () => dispatch(getListGeofence()),
    getListGeofenceByCountry: (id) => dispatch(getListGeofenceByCountry(id)),
    getAllGeofence: () => dispatch(getAllGeofence()),
    searchOneTimePriceList: (params, queryParams) => dispatch(searchOneTimePriceList(params, queryParams)),
    exportFacility: (payload, queryParams) => dispatch(exportOneTimePrice(payload, queryParams)),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
