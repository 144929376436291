import React, {Component} from 'react';

import {Button, Card, Container, Grid, Table, TableBody, TableCell, TableHead, TableRow, Dialog} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import DetailIcon from '@material-ui/icons/Description';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {DATA_NULL, ROUTE, ROWS_PER_PAGE_OPTIONS} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import {getAllServiceGroups, deleteServiceGroup} from '../../../stores/service_group/actions';
import withPermissionData from '../../../withPermissionData/hoc';

/**
 * Service Group management
 */
class Index extends Component {
  /**
   * constructor
   * @param {props} props
   */
  constructor(props) {
    super(props);
    this.state = {
      serviceGroupData: [],
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      showModal: false,
      deleteId: '',
    };
  }

  /**
   * deleteServiceGroup
   * @param {number} id
   */
  deleteServiceGroup = async () => {
    this.closeModal();
    this.props.deleteServiceGroup(this.state.deleteId).then(() => {
      this.props.getAllServiceGroups({
        page: this.state.currentPage,
        size: this.state.rowsPerPage,
      });
    });
  };

  /**
   * openModal
   */
  openModal = () => {
    this.setState({showModal: true});
  };

  /**
   * closeModal
   */
  closeModal = () => {
    this.setState({showModal: false});
  };

  /**
   * componentDidMount
   */
  componentDidMount = () => {
    this.props.getAllServiceGroups({
      page: this.state.currentPage,
      size: this.state.rowsPerPage,
    });
  };

  /**
   * onChangePagination
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = async (currentPage, rowsPerPage) => {
    await this.setState({currentPage, rowsPerPage});
    this.props.getAllServiceGroups({
      page: this.state.currentPage,
      size: this.state.rowsPerPage,
    });
  };

  /**
   * render
   * @return {component}
   */
  render() {
    const {t, serviceGroup} = this.props;

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.serviceGroupManagement')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.DATA_MANAGEMENT}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          <Card raised>
            <Container maxWidth="xl">
              {serviceGroup?.allServiceGroups?.content && serviceGroup?.allServiceGroups?.content.length > 0 ? (
                <>
                  <Grid container spacing={1}>
                    <Grid container alignItems="center" justify="flex-start" item xs={3}>
                      <h3>
                        {serviceGroup?.allServiceGroups?.totalSize} {t('common.case')}
                      </h3>
                    </Grid>
                    <Grid container alignItems="center" justify="flex-end" item xs={9}>
                      <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.SERVICE_GROUP_ADD}}>
                        <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />}>
                          {t('common.btnRegister')}
                        </Button>
                      </Link>
                    </Grid>
                  </Grid>
                  <div className="scroll_area_700">
                    <Table size="small" aria-label="sticky table" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>{t('common.id')}</TableCell>
                          <TableCell>{t('common.name')}</TableCell>
                          <TableCell>{t('waypoint.display_name')}</TableCell>
                          <TableCell>{t('serviceGroup.description')}</TableCell>
                          <TableCell>{t('common.enable')}</TableCell>
                          <TableCell>{t('serviceGroup.route_network')}</TableCell>
                          <TableCell>{t('serviceGroup.dataset_id')}</TableCell>
                          <TableCell colSpan={2}>{t('common.action')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {serviceGroup?.allServiceGroups?.content?.map((item, index) => (
                          <TableRow key={index} hover className="cursor_pointer">
                            <TableCell scope="row">{item.id}</TableCell>
                            <TableCell>{item.name ? item.name : DATA_NULL}</TableCell>
                            <TableCell>{item.display_name ? item.display_name : DATA_NULL}</TableCell>
                            <TableCell>{item.description ? item.description : DATA_NULL}</TableCell>
                            <TableCell>{String(item.enable).toUpperCase()}</TableCell>
                            <TableCell>{item.route_network ? item.route_network : DATA_NULL}</TableCell>
                            <TableCell>{item.dataset_id ? item.dataset_id : DATA_NULL}</TableCell>
                            <TableCell>
                              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.SERVICE_GROUP_UPDATE + '/' + item.id}}>
                                <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                  {t('common.btnEdit')}
                                </Button>
                              </Link>
                              <Button
                                color="primary"
                                variant="contained"
                                className="button_margin button_color_red"
                                endIcon={<DeleteIcon />}
                                onClick={() => {
                                  this.setState({deleteId: item.id});
                                  this.openModal();
                                }}
                              >
                                {t('common.btnDelete')}
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    {serviceGroup?.allServiceGroups?.totalSize > 0 ? (
                      <CustomPagination onChange={this.onChangePagination} rows={serviceGroup?.allServiceGroups?.totalSize}
                        rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                    ) : (
                      <></>
                    )}
                  </div>
                  <br></br>
                </>
              ) : (
                <h5 className="text_grey">{t('common.noResult')}</h5>
              )}
            </Container>
            <br></br>
            <Dialog
              open={this.state.showModal}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: '1020',
              }}
            >
              <SelectModal onClickOk={this.deleteServiceGroup} onClickCancel={this.closeModal} message={t('serviceGroup.confirm_delete')}></SelectModal>
            </Dialog>
          </Card>
          <br></br>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    serviceGroup: state.serviceGroup,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllServiceGroups: (payload) => dispatch(getAllServiceGroups(payload)),
    deleteServiceGroup: (payload) => dispatch(deleteServiceGroup(payload)),
  };
};

export default withPermissionData(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
