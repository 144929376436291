import React, {Component} from 'react';

import {Button, Container, Grid, Box, Card, Dialog, RadioGroup, Radio, FormControl, FormControlLabel} from '@material-ui/core';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';

import SelectModal from '../../../../components/selectModal';
import {addRideAdditional, getAdditionNumberRide} from '../../../../stores/business/action';
import {customDisplayCurrency} from '../../../../utils/common';

/**
 * Add slot
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      openConfirmAddModal: false,
      isLoaded: false,
      additionalRide: {},
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    if (this.props.planId && this.props.hasCreditCard) {
      this.props.getAdditionNumberRide({subscriptionId: this.props.subscriptionId}).then(() => this.setState({isLoaded: true}));
    }
  }

  /**
   * addRideAdditional
   */
  addRideAdditional = () => {
    this.setState({openConfirmAddModal: false});
    const {subscriptionId, userId} = this.props;
    const {additionalRide} = this.state;
    this.props
      .addRideAdditional({
        user_id: userId,
        subscription_id: subscriptionId,
        ticket_addition_price_id: additionalRide.id,
      })
      .then((response) => {
        if (response) this.props.additionalRideComplete();
      });
  };

  /**
   * Render
   * @return {*}
   */
  render() {
    const {t} = this.props.props;
    const {business} = this.props;
    const {additionNumberRide, isLoading} = business;

    return (
      <LoadingOverlay active={isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Container maxWidth="md">
          <Box p={1} m={1}>
            <Card raised>
              <Container maxWidth="md">
                <Box p={2} m={2}>
                  <Grid container alignItems="flex-start">
                    <Grid item xs={12}>
                      <h1 className="product_entry_table_header_color font_color_white font_size_mid title">{t('ticket.additional_purchase')}</h1>
                      {this.state.isLoaded && (
                        <Grid container alignItems="center" justifyContent="center">
                          {additionNumberRide.length > 0 ? (
                            <>
                              <Grid item xs={12}>
                                <p style={{fontWeight: 550, textAlign: 'center', marginBottom: 0}}>{t('ticket.select_additional_purchase')}</p>
                              </Grid>
                              <Grid item xs={12}>
                                <FormControl component="fieldset" fullWidth>
                                  <RadioGroup
                                    aria-label="gender"
                                    name="gender1"
                                    value={this.state.additionalRide?.id || {}}
                                    onChange={(event) => {
                                      this.setState({
                                        additionalRide: additionNumberRide?.find((item) => item.id === Number(event.target.value)) || {},
                                      });
                                    }}
                                  >
                                    {additionNumberRide?.map((item, index) => (
                                      <Grid container alignItems="center" justifyContent="center">
                                        <Grid item xs={6} style={{paddingLeft: '12%'}}>
                                          <FormControlLabel
                                            key={index}
                                            value={item.id}
                                            control={<Radio />}
                                            label={item.additional_rides + ' ' + t('ticket.ride', {count: item.additional_rides})}
                                          />
                                        </Grid>
                                        <Grid item xs={5}>
                                          <Box display="flex" justifyContent="center">
                                            <span>{customDisplayCurrency(item.additional_price, item.currency_code)}</span>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                    ))}
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                            </>
                          ) : (
                            <span>{t('ticket.no_additional_purchase')}</span>
                          )}
                        </Grid>
                      )}
                      {!this.props.hasCreditCard && (
                        <Grid container alignItems="center" justifyContent="center">
                          <span>{t('ticket.add_credit_card')}</span>
                        </Grid>
                      )}
                      <Grid container alignItems="flex-start">
                        <Grid container justify="center" alignItems="center" item xs={12} style={{paddingTop: 20}}>
                          <Button variant="contained" className="button_color button_magin" color="primary" style={{marginRight: 8}} onClick={() => this.props.onClickClose()}>
                            {t('common.btnCancel')}
                          </Button>
                          {Object.keys(this.state.additionalRide).length > 0 && this.props.hasCreditCard && this.props.canAdditional && (
                            <Button
                              variant="contained"
                              className="button_magin"
                              color="primary"
                              onClick={() => this.setState({openConfirmAddModal: true})}
                            >
                              {t('ticket.additional')}
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Dialog
                      open={this.state.openConfirmAddModal}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: '1020',
                      }}
                    >
                      <SelectModal
                        okButtonText={`${t('common.btnYes')}`}
                        cancelButtonText={`${t('common.btnNo')}`}
                        onClickOk={this.addRideAdditional}
                        onClickCancel={() => this.setState({openConfirmAddModal: false})}
                        message={t('ticket.confirm_select_additional_purchase', {
                          count: this.state.additionalRide.additional_rides,
                          amount: customDisplayCurrency(this.state.additionalRide.additional_price, this.state.additionalRide.currency_code),
                        })}
                      ></SelectModal>
                    </Dialog>
                  </Grid>
                </Box>
              </Container>
            </Card>
          </Box>
        </Container>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    business: state.business,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addRideAdditional: (payload) => dispatch(addRideAdditional(payload)),
    getAdditionNumberRide: (id) => dispatch(getAdditionNumberRide(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
