import React, {Component} from 'react';

import {
  Button,
  Card,
  Checkbox,
  Container,
  Dialog,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  FormControlLabel,
  Modal,
  Backdrop,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DeleteIcon from '@material-ui/icons/Delete';
import DetailIcon from '@material-ui/icons/Description';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import SyncIcon from '@material-ui/icons/Sync';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {DATA_NULL, ROUTE, ROWS_PER_PAGE_OPTIONS, STOP_MASTER_TYPE} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import {searchStopMasterApi, deleteStopMasterApi} from '../../../services/stopMasterServices';
import {setMessageModal} from '../../../stores/modal/actions';
import {onChangeTextField, onChangeSelect, changeUrlParams, getQueryStringFromObject, getUrlParams} from '../../../utils/common';
import {modalObj} from '../../../utils/modal';
import withPermissionData from '../../../withPermissionData/hoc';
import StopSet from './StopSetComponent';

/**
 * Stop Master Management component
 */
class StopMasterManagement extends Component {
  /**
   * constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
      street_name: '',
      is_work: '',
      code: '',
      description: '',
      type: '',
      name: '',
      result: [],
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,

      flagDelete: false,
      flagSync: false,
      id: null,
      index: null,
      isLoading: false,
      isSearch: false,
    };
  }

  /**
   * initial state
   */
  componentDidMount = () => {
    if (this.props.location.search) {
      const params = getUrlParams(this.props.location.search);
      if (params.is_work && params.is_work !== '') {
        if (params.is_work === 'true') {
          params.is_work = true;
        }
        if (params.is_work === 'false') {
          params.is_work = false;
        }
      }
      this.setState(params, () => {
        this.handleSearch();
      });
    }
  };

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  handlePageChange = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearch);
  };

  /**
   * handleSearch
   * @param {bool} reset
   */
  handleSearch = (reset) => {
    const {code, is_work, description, type, name, street_name} = this.state;
    const payLoad = {
      code,
      description,
      name,
      street_name,
    };
    if (is_work !== '') {
      payLoad.is_work = is_work;
    }
    if (type !== '') {
      payLoad.type = type;
    }
    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const {currentPage, rowsPerPage} = this.state;
    const queryParams = reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage};
    this.setState({
      isLoading: true,
    });
    searchStopMasterApi(queryParams, payLoad)
      .then((response) => {
        this.setState(
          {
            isSearch: true,
            result: response?.result?.content ? response?.result?.content?.map((item) => {
              return {
                ...item,
                displayName: item.name_translations[this.props.i18n.language] || item.display_name || item.street_translations[this.props.i18n.language] || item.name,
              };
            }) : [],
            totalRows: response?.result?.totalSize !== 0 ? response?.result?.totalSize : 0,
          },
          () => {
            const {code, is_work, description, type, name, street_name} = this.state;
            // Apply changed params into url
            const queryParamsToChange = {
              code,
              description,
              type,
              name,
              street_name,
              currentPage: String(currentPage),
              rowsPerPage,
            };
            if (is_work !== '') {
              queryParamsToChange.is_work = is_work;
            }
            changeUrlParams(queryParamsToChange);
            const newSearchParams = getQueryStringFromObject(queryParamsToChange);
            this.setState({searchParams: newSearchParams});
          },
        );
      })
      .then(() => {
        this.setState({
          isLoading: false,
        });
      });
  };

  /**
   * handleDelete
   * @param {int} id
   * @param {object} index
   */
  handleDelete = (id, index) => {
    this.setState({
      flagDelete: true,
      id,
      index,
    });
  };

  /**
   * handleButtonOk
   */
  handleButtonOk = () => {
    this.setState({
      flagDelete: false,
    });
    deleteStopMasterApi(this.state.id).then(() => {
      const result = this.state.result;
      result.splice(this.state.index, 1);
      this.setState({
        result,
      });
      this.props.setMessageModal(modalObj(true, 'api.delete.stop_master.success'));
    });
  };

  /**
   * reset search condition
   */
  resetSearchCondition = () => {
    this.setState({
      code: '',
      is_work: '',
      street_name: '',
      description: '',
      type: '',
      name: '',
    });
  };

  /**
   * Render Component
   * @return {component}
   */
  render() {
    const {t} = this.props;

    return (
      <>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container spacing={1} className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('stop_master.managementTitle')}</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}>
                <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.DATA_MANAGEMENT}}>
                  <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                    {t('common.btnReturn')}
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <br></br>
            <LoadingOverlay active={this.state.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
              {/* --------------------- SEARCH -------------------- */}
              <Card raised>
                <Container maxWidth="xl">
                  <br></br>
                  <Paper className="search_table">
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                        {t('businessVehicle.searchTitle')}
                      </Grid>
                    </Grid>

                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('stop_master.code')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl variant="outlined" margin="dense" fullWidth>
                          <TextField
                            name="code"
                            onChange={(event) => onChangeTextField(this, event)}
                            placeholder={t('validation.required', {field: t('stop_master.code')})}
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            value={this.state.code}
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('common.name')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl variant="outlined" margin="dense" fullWidth>
                          <TextField
                            name="name"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('validation.required', {field: t('common.name')})}
                            value={this.state.name}
                            onChange={(event) => onChangeTextField(this, event)}
                            inputProps={{maxLength: 256}}
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('stop_master.street_name')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl variant="outlined" margin="dense" fullWidth>
                          <TextField
                            name="street_name"
                            onChange={(event) => onChangeTextField(this, event)}
                            placeholder={t('validation.required', {field: t('stop_master.street_name')})}
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            value={this.state.street_name}
                            variant="outlined"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('stop_master.type')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'type',
                            }}
                            displayEmpty
                            value={this.state.type}
                            onChange={(e) => onChangeSelect(this, e)}
                            renderValue={
                              this.state.type ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('stop_master.type'),
                                      })}
                                    </div>
                                  )
                            }
                          >
                            {STOP_MASTER_TYPE.map((item, idx) => {
                              return (
                                <MenuItem value={item} key={idx}>
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* Work */}
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('stop_master.work')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControlLabel
                          style={{marginRight: '30px', marginLeft: '-6px'}}
                          labelPlacement="end"
                          control={
                            <Checkbox
                              checked={this.state.is_work === true}
                              onChange={() => this.setState({is_work: this.state.is_work === true ? '' : true})}
                              className="checkbox_radio"
                            />
                          }
                          label={t('common.true')}
                        />
                        <FormControlLabel
                          labelPlacement="end"
                          control={
                            <Checkbox
                              checked={this.state.is_work === false}
                              onChange={() => this.setState({is_work: this.state.is_work === false ? '' : false})}
                              className="checkbox_radio"
                            />
                          }
                          label={t('common.false')}
                        />
                      </Grid>
                    </Grid>
                  </Paper>

                  <Grid container spacing={1} style={{padding: '15px 0px'}}>
                    <Grid container alignItems="center" justify="flex-start" item xs={3}>
                      <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.resetSearchCondition} endIcon={<RotateLeftIcon />}>
                        {t('common.btnResetSearch')}
                      </Button>
                    </Grid>
                    <Grid container alignItems="center" justify="flex-end" item xs={9}>
                      <Button color="primary" variant="contained" className="button_margin" type="submit" endIcon={<SyncIcon />} onClick={() => this.setState({flagSync: true})}>
                        {t('stop_master.stopSetData')}
                      </Button>
                      <Link to={ROUTE.LAYOUT + ROUTE.DATA_STOP_MASTER_CREATE} style={{textDecoration: 'none'}}>
                        <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />}>
                          {t('common.btnRegister')}
                        </Button>
                      </Link>
                      <Button color="primary" variant="contained" className="button_margin" type="submit" endIcon={<SearchIcon />} onClick={this.handleSearch}>
                        {t('common.btnSearch')}
                      </Button>
                    </Grid>
                  </Grid>
                </Container>
              </Card>
              {/* --------------------- END SEARCH -------------------- */}
              <br></br>
              {this.state.isSearch && (
                <Card raised>
                  <Container maxWidth="xl">
                    <Grid container spacing={1}>
                      <Grid container alignItems="center" item xs={12}>
                        <h3>
                          {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                        </h3>
                      </Grid>
                    </Grid>
                    {this.state.result?.length > 0 && (
                      <>
                        <div className="scroll_area_700">
                          <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                            <TableHead>
                              <TableRow>
                                <TableCell className="width_150p">{t('stop_master.code')}</TableCell>
                                <TableCell className="width_200p">{t('common.name')}</TableCell>
                                <TableCell className="width_200p">{t('waypoint.display_name')}</TableCell>
                                <TableCell className="width_200p">{t('stop_master.street_name')}</TableCell>
                                <TableCell className="width_200p">{t('stop_master.type')}</TableCell>
                                <TableCell className="width_100p">{t('stop_master.work')}</TableCell>
                                <TableCell className="width_300p">{t('stop_master.stop_sets')}</TableCell>
                                <TableCell className="width_200p">{t('stop_master.description')}</TableCell>
                                <TableCell className="width_200p">{t('stop_master.hotline')}</TableCell>
                                <TableCell className="width_200p">{t('stop_master.ref_link')}</TableCell>
                                <TableCell className="width_250p" style={{position: 'sticky', right: '0'}}>
                                  {t('common.action')}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.result?.map((row, index) => {
                                return (
                                  <TableRow key={index} hover className="cursor_pointer">
                                    <TableCell>{row.code || DATA_NULL}</TableCell>
                                    <TableCell>{row.name || DATA_NULL}</TableCell>
                                    <TableCell>{row.displayName || DATA_NULL}</TableCell>
                                    <TableCell>{row.street_name || DATA_NULL}</TableCell>
                                    <TableCell>{row.type || DATA_NULL}</TableCell>
                                    <TableCell>{row.is_work ? t('common.true') : t('common.false')}</TableCell>
                                    <TableCell>
                                      {row?.stop_sets && row?.stop_sets.length > 0 ? row?.stop_sets
                                        ?.map((stop_set) => JSON.stringify(stop_set))
                                        .reduce((prev, curr) => (
                                          <>
                                            {prev}
                                            <br/>
                                            {curr}
                                          </>
                                        )) : DATA_NULL}
                                    </TableCell>
                                    <TableCell>{row.description || DATA_NULL}</TableCell>
                                    <TableCell>{row.hotline || DATA_NULL}</TableCell>
                                    <TableCell>{row.ref_link || DATA_NULL}</TableCell>
                                    <TableCell className="cell_fixed_right">
                                      <Link
                                        style={{textDecoration: 'none'}}
                                        to={{
                                          pathname: ROUTE.LAYOUT + ROUTE.DATA_STOP_MASTER_DETAIL + '/' + row.id,
                                          state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                        }}
                                      >
                                        <Button color="primary" variant="contained" className="button_magin" endIcon={<DetailIcon />}>
                                          {t('common.btnEdit')}
                                        </Button>
                                      </Link>
                                      &nbsp; &nbsp;
                                      {!row.swat_transit_stop_id && (
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="button_margin button_color_red"
                                          endIcon={<DeleteIcon />}
                                          onClick={() => this.handleDelete(row.id, index)}
                                        >
                                          {t('common.btnDelete')}
                                        </Button>
                                      )}
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </div>
                        <CustomPagination onChange={this.handlePageChange} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                      </>
                    )}
                  </Container>
                </Card>
              )}
              <br />
            </LoadingOverlay>
          </Container>
        </Card>
        <Dialog
          open={this.state.flagDelete}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <SelectModal onClickOk={this.handleButtonOk} onClickCancel={() => this.setState({flagDelete: false})} message="stop_master.question.confirm.delete"></SelectModal>
        </Dialog>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.flagSync}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1000',
          }}
        >
          <>
            <StopSet reSearch={this.handleSearch} onClickClose={() => this.setState({flagSync: false})}></StopSet>
          </>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
  };
};

export default withPermissionData(withTranslation('translations')(connect(null, mapDispatchToProps)(StopMasterManagement)));
