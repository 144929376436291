import React, {Component} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {Button, Container, Dialog, Fade, Grid, Paper, TextField, Select, MenuItem, InputLabel, FormControl, FormHelperText} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {COUNTRY, MEMBER_GENDER} from '../../../common/constant';
import MuiPhoneNumberInput from '../../../components/mui-phone-number-input';
import SelectModal from '../../../components/selectModal';
import {checkValidMobileMemberExist, createMember} from '../../../stores/member/action';
import {onChangeSelect, onChangeTextField, getRawPhoneNumber, isValidEmail, nricNumberFormat} from '../../../utils/common';
import './styles.css';

/**
 * Account Management view
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      id: '',
      birthday: new Date(),
      gender: 'NONE',
      memberId: '',
      mobile: '',
      email: '',
      first_name: '',
      last_name: '',
      last_name_furigana: '',
      first_name_furigana: '',
      address: '',
      province: '',
      district: '',
      nricNumber: null,
      passportNumber: null,
      keyCode: null,

      dataCountry: {},
      flag: false,
      isSubmitForm: false,
      isExisted: false,
    };
    this.handleButtonOk = this.handleButtonOk.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
    this.validator = new SimpleReactValidator({
      validators: {
        nric: {
          message: '',
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^\d{6}-\d{2}-\d{4}$/) && params.indexOf(val) === -1;
          },
          messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),
        },
        passportNumber: {
          message: '',
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^(?!^0+$)[a-zA-Z0-9]{3,20}$/) && params.indexOf(val) === -1;
          },
          messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),
        },
      },
    });
  }

  /**
   * handleClickClose
   */
  handleClickClose() {
    this.props.onClickClose();
  }

  /**
   * handleChangeCreate
   */
  handleChangeCreate() {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid() && (!this.state.email.trim() || isValidEmail(this.state.email.trim()))) {
      this.setState({
        flag: true,
        message: 'updateMember.createModal',
      });
    }
  }

  /**
   * handleButtonOk
   */
  handleButtonOk() {
    this.setState({
      flag: false,
    });
    if (this.state.message === 'updateMember.createModal') {
      this.createMember();
    } else if (this.state.message === 'updateMember.cancelNotifyEmail' || this.state.message === 'updateMember.cancelModal') {
      this.cancelMember();
    }
    this.props.onClickClose();
  }

  /**
   * handleButtonOkCancel
   */
  handleButtonOkCancel = () => {
    this.setState({
      flag: false,
    });
  };

  /**
   * create new member
   */
  createMember() {
    const {first_name, last_name, birthday, first_name_furigana, last_name_furigana, mobile, email, gender, address, province, district, nricNumber, passportNumber} = this.state;
    let date = null;
    if (birthday) {
      date = new Date(birthday);
      date = new Date(date.setDate(date.getDate() + 1)).setHours(0, 0, 0, 0);
      date = new Date(date).toISOString().slice(0, 10);
    }
    let nric_number = '';
    if (nricNumber) {
      const nricNumberArray = nricNumber.split('-');
      nricNumberArray.forEach((nric) => {
        nric_number += nric;
      });
    }
    const payload = {
      first_name: first_name?.trim(),
      last_name: last_name?.trim(),
      birthday: date,
      first_name_furigana: first_name_furigana?.trim(),
      last_name_furigana: last_name_furigana?.trim(),
      mobile: getRawPhoneNumber(mobile, this.state.dataCountry),
      raw_mobile: mobile.includes('+') ? mobile.replace(/\s/g, '').slice(this.state.dataCountry?.dialCode.length + 1) : mobile,
      gender: gender === null ? 'NONE' : gender,
      email: email?.trim(),
      address: address?.trim(),
      province: province?.trim(),
      district: district?.trim(),
      nric_number: nric_number || null,
      passport: passportNumber || null,
    };
    this.props.createMember(payload, this.props);
  }

  /**
   * changeNricNumber
   * @param {object} event
   */
  onChangeNricNumber = (event) => {
    let value = event.target.value;
    if (value?.length > 14) {
      return;
    } else {
      if ((value.length === 6 || value.length === 9) && ((this.state.keyCode >= 48 && this.state.keyCode <= 57) || (this.state.keyCode >= 96 && this.state.keyCode <= 105))) {
        value = value + '-';
      }
      if (value.length > 6 && value.length < 9 && !value.includes('-')) {
        value = nricNumberFormat(value, 6, 0);
      }
      if (value.length > 9 && value.split('-').length === 1) {
        value = nricNumberFormat(value, 6, 8);
      }
      this.setState({nricNumber: value.trim()});
    }
  };

  /**
   * onChangePassportNumber
   * @param {object} event
   */
  onChangePassportNumber = (event) => {
    const value = event.target.value;
    if (value?.length > 20) {
      this.setState({passportNumber: value?.trim().toUpperCase().substring(0, 20)});
      return;
    } else {
      this.setState({passportNumber: value?.trim().toUpperCase()});
      return;
    }
  };

  /**
   * keyPressNricNumber
   * @param {object} event
   */
  keyPressNricNumber = (event) => {
    this.setState({keyCode: event.keyCode});
  };

  /**
   * Render component
   * @return {object}
   */
  render() {
    const {t} = this.props;

    return (
      <Fade in={true}>
        <Paper className="modal_size_mid">
          <br></br>
          <Container maxWidth="xl">
            <Grid container spacing={2}>
              <Grid container alignItems="flex-start" className="page_header" item xs={8}>
                <img src={`${process.env.PUBLIC_URL}/favicon.ico`} alt="Logo" width="35" height="33"></img>
                <span className="font_bold font_size_big">{t('route.memberManagementCreate')}</span>
              </Grid>
              <Grid container alignItems="flex-start" justify="flex-end" className="page_header" item xs={4}>
                <CancelIcon onClick={this.handleClickClose} className="cursor_pointer" />
              </Grid>
            </Grid>
          </Container>
          <br />

          <Container maxWidth="xl" className="scroll_area_500">
            <form autoComplete="off">
              <Grid container spacing={1}>
                {/* last name */}
                <Grid container justify="center" alignItems="flex-start" item xs={6}>
                  <FormControl className="width_100">
                    <TextField
                      label={
                        <span>
                          {t('updateMember.lastName')}
                          <span className="font_color_red">＊</span>
                        </span>
                      }
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      name="last_name"
                      value={this.state.last_name}
                      onChange={(event) => onChangeTextField(this, event)}
                    />
                    <div>{t('validation.max.label', {value: 20})}</div>
                    {this.validator.message('last_name', this.state.last_name.trim(), 'required|max:20')}
                    <Grid container spacing={1}>
                      {this.state.isSubmitForm && !this.validator.check(this.state.last_name.trim(), 'required') && (
                        <Grid container justify="flex-start" alignItems="flex-start" item xs={12}>
                          <FormHelperText id="last_name" error>
                            {t('validation.required', {field: t('updateMember.lastName')})}
                          </FormHelperText>
                        </Grid>
                      )}
                      {this.state.isSubmitForm && !this.validator.check(this.state.last_name.trim(), 'max:20') && (
                        <Grid container justify="flex-start" alignItems="flex-start" item xs={12}>
                          <FormHelperText error>{t('validation.maxString', {value: 20})}</FormHelperText>
                        </Grid>
                      )}
                    </Grid>
                  </FormControl>
                </Grid>

                {/* first name */}
                <Grid container justify="center" alignItems="flex-start" item xs={6}>
                  <FormControl className="width_100">
                    <TextField
                      label={
                        <span>
                          {t('updateMember.firstName')}
                          <span className="font_color_red">＊</span>
                        </span>
                      }
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      name="first_name"
                      value={this.state.first_name}
                      onChange={(event) => onChangeTextField(this, event)}
                    />
                    <div>{t('validation.max.label', {value: 20})}</div>
                    {this.validator.message('first_name', this.state.first_name.trim(), 'required|max:20')}
                    <Grid container spacing={1}>
                      {this.state.isSubmitForm && !this.validator.check(this.state.first_name.trim(), 'required') && (
                        <Grid container justify="flex-start" alignItems="flex-start" item xs={12}>
                          <FormHelperText id="first_name" error>
                            {t('validation.required', {field: t('updateMember.firstName')})}
                          </FormHelperText>
                        </Grid>
                      )}
                      {this.state.isSubmitForm && !this.validator.check(this.state.first_name.trim(), 'max:20') && (
                        <Grid container justify="flex-start" alignItems="flex-start" item xs={12}>
                          <FormHelperText error>{t('validation.maxString', {value: 20})}</FormHelperText>
                        </Grid>
                      )}
                    </Grid>
                  </FormControl>
                </Grid>

                {/* last name furigana */}
                <Grid container justify="center" alignItems="flex-start" item xs={6}>
                  <FormControl className="width_100">
                    <TextField
                      label={
                        <span>
                          {t('updateMember.lastName_furigana')}
                          <span className="font_color_red">＊</span>
                        </span>
                      }
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      name="last_name_furigana"
                      value={this.state.last_name_furigana}
                      onChange={(event) => onChangeTextField(this, event)}
                    />
                    <div>{t('validation.max.label', {value: 20})}</div>
                    {this.validator.message('last_name_furigana', this.state.last_name_furigana.trim(), 'required|max:20')}
                    <Grid container spacing={1}>
                      {this.state.isSubmitForm && !this.validator.check(this.state.last_name_furigana.trim(), 'required') && (
                        <Grid container justify="flex-start" alignItems="flex-start" item xs={12}>
                          <FormHelperText id="last_name_furigana" error>
                            {t('validation.required', {field: t('updateMember.lastName_furigana')})}
                          </FormHelperText>
                        </Grid>
                      )}
                      {this.state.isSubmitForm && !this.validator.check(this.state.last_name_furigana.trim(), 'max:20') && (
                        <Grid container justify="flex-start" alignItems="flex-start" item xs={12}>
                          <FormHelperText error>{t('validation.maxString', {value: 20})}</FormHelperText>
                        </Grid>
                      )}
                    </Grid>
                  </FormControl>
                </Grid>

                {/* first name furigana */}
                <Grid container justify="center" alignItems="flex-start" item xs={6}>
                  <FormControl className="width_100">
                    <TextField
                      label={
                        <span>
                          {t('updateMember.firstName_furigana')}
                          <span className="font_color_red">＊</span>
                        </span>
                      }
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      name="first_name_furigana"
                      value={this.state.first_name_furigana}
                      onChange={(event) => onChangeTextField(this, event)}
                    />
                    <div>{t('validation.max.label', {value: 20})}</div>
                    {this.validator.message('first_name_furigana', this.state.first_name_furigana.trim(), 'required|max:20')}
                    <Grid container spacing={1}>
                      {this.state.isSubmitForm && !this.validator.check(this.state.first_name_furigana.trim(), 'required') && (
                        <Grid container justify="flex-start" alignItems="flex-start" item xs={12}>
                          <FormHelperText id="firstName_furigana" error>
                            {t('validation.required', {field: t('updateMember.firstName_furigana')})}
                          </FormHelperText>
                        </Grid>
                      )}
                      {this.state.isSubmitForm && !this.validator.check(this.state.first_name_furigana.trim(), 'max:20') && (
                        <Grid container justify="flex-start" alignItems="flex-start" item xs={12}>
                          <FormHelperText error>{t('validation.maxString', {value: 20})}</FormHelperText>
                        </Grid>
                      )}
                    </Grid>
                  </FormControl>
                </Grid>

                {/* email */}
                <Grid container justify="center" alignItems="flex-start" item xs={6}>
                  <FormControl className="width_100">
                    <TextField
                      label={<span>{t('common.email')}</span>}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      name="email"
                      inputProps={{maxLength: 64}}
                      value={this.state.email}
                      onChange={(email) => {
                        this.setState({email: email.target.value});
                        if (email.target.value.length >= 9) {
                          this.props.checkValidMobileMemberExist({email: email.target.value}).then((isExisted) => {
                            this.setState({isExisted: isExisted});
                          });
                        }
                      }}
                    />
                    <div>{t('validation.max.label', {value: 64})}</div>
                    {this.state.isSubmitForm && this.state.email && !isValidEmail(this.state.email.trim()) && <FormHelperText error>{t('validation.email')}</FormHelperText>}
                  </FormControl>
                </Grid>

                {/* mobile */}
                <Grid container justify="center" alignItems="flex-start" item xs={6}>
                  <FormControl className="width_100">
                    <MuiPhoneNumberInput
                      defaultCountry={'jp'}
                      onlyCountries={COUNTRY.map((c) => c.countryCode)}
                      label={
                        <span>
                          {t('common.phoneNumber')}
                          <span className="font_color_red">＊</span>
                        </span>
                      }
                      countryCodeEditable={false}
                      enableLongNumbers={true}
                      autoFormat={false}
                      margin="dense"
                      variant="outlined"
                      name="mobile"
                      value={this.state.mobile}
                      onChange={(value, country) => {
                        this.setState({mobile: value, dataCountry: country});
                      }}
                    />
                    <div>{t('validation.max.label', {value: 13})}</div>
                    {this.validator.message('mobile', this.state.mobile?.slice(this.state?.dataCountry?.dialCode?.length + 1)?.trim(), 'required')}
                    <Grid container spacing={1}>
                      {this.state.isSubmitForm &&
                        !this.validator.check(
                          this.state.mobile?.trim() ? this.state.mobile?.slice(this.state?.dataCountry?.dialCode.length + 1).trim() : this.state.mobile?.trim(),
                          'required',
                        ) && (
                          <Grid container justify="flex-start" alignItems="flex-start" item xs={12}>
                            <FormHelperText id="mobile" error>
                              {t('validation.required', {field: t('common.phoneNumber')})}
                            </FormHelperText>
                          </Grid>
                        )}
                    </Grid>
                  </FormControl>
                </Grid>

                {/* birthday */}
                <Grid container alignItems="flex-start" item xs={6}>
                  <FormControl className="width_100">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        label={<span>{t('memberManagement.birthDay')}</span>}
                        variant="inline"
                        className="width_100"
                        margin="dense"
                        autoOk
                        inputVariant="outlined"
                        views={['year', 'month', 'date']}
                        minDate="1900-01-01"
                        maxDate={new Date()}
                        format="yyyy-MM-dd"
                        value={this.state.birthday || null}
                        onChange={(birthday) => this.setState({birthday: birthday})}
                        KeyboardButtonProps={{
                          'aria-label': 'change time',
                        }}
                        invalidDateMessage={t('errorFields.invalidDateMessage')}
                        maxDateMessage={t('errorFields.maxDateMessage')}
                        minDateMessage={t('errorFields.minDateMessage')}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                </Grid>

                {/* Gender */}
                <Grid container justify="center" alignItems="flex-start" item xs={4}>
                  <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel>{t('updateMember.gender')}</InputLabel>
                    <Select
                      labelWidth={165}
                      margin="dense"
                      inputProps={{
                        name: 'gender',
                      }}
                      label={t('updateMember.gender')}
                      value={this.state.gender || 'NONE'}
                      onChange={(event) => onChangeSelect(this, event)}
                    >
                      {MEMBER_GENDER.map(({name, i18n}, index) => {
                        return (
                          <MenuItem value={name} key={index}>
                            {t(`${i18n}`)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>

                {/* Address */}
                <Grid container justify="center" alignItems="flex-start" item xs={10}>
                  <FormControl className="width_100">
                    <TextField
                      label={<span>{t('common.address')}</span>}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      name="address"
                      value={this.state.address}
                      onChange={(event) => this.setState({address: event.target.value})}
                    />
                    <div>{t('validation.max.label', {value: 80})}</div>
                    {this.validator.message('address', this.state.address.trim(), 'max:80')}
                    {this.state.isSubmitForm && !this.validator.check(this.state.address.trim(), 'max:80') && (
                      <FormHelperText error>{t('validation.maxString', {value: 80})}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {/* Province */}
                <Grid container justify="center" alignItems="flex-start" item xs={6}>
                  <FormControl className="width_100">
                    <TextField
                      label={<span>{t('updateMember.province')}</span>}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      name="province"
                      value={this.state.province}
                      onChange={(event) => this.setState({province: event.target.value})}
                    />
                    <div>{t('validation.max.label', {value: 20})}</div>
                    {this.validator.message('province', this.state.province.trim(), 'max:20')}
                    {this.state.isSubmitForm && !this.validator.check(this.state.province.trim(), 'max:20') && (
                      <FormHelperText id="province" error>
                        {t('validation.maxString', {value: 20})}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {/* District */}
                <Grid container justify="center" alignItems="flex-start" item xs={6}>
                  <FormControl className="width_100">
                    <TextField
                      label={<span>{t('updateMember.district')}</span>}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      name="district"
                      value={this.state.district}
                      onChange={(event) => this.setState({district: event.target.value})}
                    />
                    <div>{t('validation.max.label', {value: 20})}</div>
                    {this.validator.message('district', this.state.district.trim(), 'max:20')}
                    {this.state.isSubmitForm && !this.validator.check(this.state.district.trim(), 'max:20') && (
                      <FormHelperText id="district" error>
                        {t('validation.maxString', {value: 20})}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {/* NRIC Number */}
                <Grid container justify="center" alignItems="flex-start" item xs={6}>
                  <FormControl className="width_100 letter-spacing-2">
                    <TextField
                      label={<span>{t('memberManagement.nricNumber')}</span>}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      name="nricNumber"
                      value={this.state.nricNumber}
                      onKeyDown={(event) => this.keyPressNricNumber(event)}
                      onChange={(event) => this.onChangeNricNumber(event)}
                    />
                    <div>{t('validation.max.label', {value: 12})}</div>
                    {this.validator.message('nricNumber', this.state.nricNumber, 'nric')}
                    {this.state.isSubmitForm && !this.validator.check(this.state.nricNumber, 'nric') && (
                      <FormHelperText id="nricNumber" error>
                        {t('memberManagement.nircNumbericValidation')}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {/* Passport Number */}
                <Grid container justify="center" alignItems="flex-start" item xs={6}>
                  <FormControl className="width_100 letter-spacing-2">
                    <TextField
                      label={<span>{t('memberManagement.passportNumber')}</span>}
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      name="passportNumber"
                      value={this.state.passportNumber}
                      onChange={(event) => this.onChangePassportNumber(event)}
                    />
                    <div>{t('validation.max.label', {value: '20'})}</div>
                    {this.validator.message('passportNumber', this.state.passportNumber, 'passportNumber')}
                    {this.state.isSubmitForm && !this.validator.check(this.state.passportNumber, 'passportNumber') && (
                      <FormHelperText id="passportNumber" error>
                        {t('memberManagement.passportNummberValidation')}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </Container>

          <br />
          <Container maxWidth="xl">
            <Grid container>
              <Grid container justify="flex-start" alignItems="flex-start" item xs={4}></Grid>
              <Grid container justify="flex-end" alignItems="flex-start" item xs={8}>
                <Button
                  color="primary"
                  variant="contained"
                  className="button_margin button_color_green"
                  onClick={() => this.handleChangeCreate()}
                  endIcon={<AddIcon />}
                  disabled={this.state.isExisted}
                >
                  {t('common.btnRegister')}
                </Button>
                <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.handleClickClose} endIcon={<CloseIcon />}>
                  {t('common.btnClose')}
                </Button>
              </Grid>
            </Grid>

            <br></br>
          </Container>
          <Dialog
            open={this.state.flag}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal onClickOk={this.handleButtonOk} onClickCancel={this.handleButtonOkCancel} message={this.state.message}></SelectModal>
          </Dialog>
        </Paper>
      </Fade>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    createMember: (payload, props) => dispatch(createMember(payload, props)),
    checkValidMobileMemberExist: (params, props) => dispatch(checkValidMobileMemberExist(params, props)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
