import React, {Component} from 'react';

import {
  Card,
  Container,
  Grid,
  FormControl,
  Select,
  TextField,
  InputLabel,
  Button,
  Box,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  MenuItem,
  FormHelperText,
  Dialog,
} from '@material-ui/core';
import {ArrowBack as ArrowBackIcon, Search as SearchIcon, Add as AddIcon, Update as UpdateIcon, Delete as DeleteIcon} from '@material-ui/icons';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

import {DATA_NULL} from '../../../common/constant';
import {ROUTE} from '../../../common/constant';
import SelectModal from '../../../components/selectModal';
import {
  createMobileFunctionApi,
  updateMobileFunctionApi,
  deleteMobileFunctionApi,
  configureMobileFunctionApi,
  searchMobileFunctionWithCountryApi,
} from '../../../services/dataManagementServices';
import {getAllCountryCode} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {onChangeTextField, onChangeSelect, onChangeListData} from '../../../utils/common';
import {modalObj} from '../../../utils/modal';
import withPermissionData from '../../../withPermissionData/hoc';

/**
 * Management data mobile-function
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      listMobileFunction: [],
      noResult: false,
      formDisplay: false,
      btnAction: false,
      mobileFunction_id: '',
      mobileFunction_functionCode: '',
      mobileFunction_functionName: '',
      mobileFunction_note: '',
      mobileFunction_role: '',
      mobileFunction_show_type: '',
      isSubmitForm: false,
      confirmModal: false,
      confirmMessage: '',
      action: '',
      service_countries: [],
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllCountryCode();
  }

  /**
   * clickBtnCreate
   */
  clickBtnCreate = () => {
    const formDisplay = this.state.formDisplay;
    const btnAction = this.state.btnAction;
    if (btnAction) {
      const listMobileFunction = this.state.listMobileFunction.map((mobileFunction) => {
        return {
          ...mobileFunction,
          onFocus: false,
        };
      });
      this.setState({
        btnAction: false,
        mobileFunction_functionCode: '',
        mobileFunction_functionName: '',
        mobileFunction_note: '',
        mobileFunction_role: '',
        listMobileFunction: listMobileFunction,
        formDisplay: true,
      });
    } else this.setState({formDisplay: !formDisplay});
  };

  /**
   * onChangeTextFunctionCode
   * @param {event} event
   */
  onChangeTextFunctionCode = (event) => {
    this.setState({mobileFunction_functionCode: event.target.value});
  };

  /**
   * onChangeTextFunctionName
   * @param {event} event
   */
  onChangeTextFunctionName = (event) => {
    this.setState({mobileFunction_functionName: event.target.value});
  };

  /**
   * onChangeTextNote
   * @param {event} event
   */
  onChangeTextNote = (event) => {
    this.setState({mobileFunction_note: event.target.value});
  };

  /**
   * onChangeSelectRole
   * @param {event} event
   */
  onChangeSelectRole = (event) => {
    this.setState({mobileFunction_role: event.target.value});
  };

  /**
   * addRowSericeCountry
   * @param {event} event
   */
  addRowSericeCountry = () => {
    this.setState({
      service_countries: [
        ...this.state.service_countries,
        {
          country_id: '',
          link: '',
        },
      ],
    });
  };

  /**
   * handleRemoveRow
   * @param {number} index
   */
  handleRemoveRow = (index) => {
    this.state.service_countries.splice(index, 1);
    this.setState({
      service_countries: this.state.service_countries,
    });
  };

  /**
   * searchMobileFunctionInfo
   * @param {object} page
   * @param {*} rowsPerPage
   */
  async searchMobileFunctionInfo() {
    const {search_mobileFunction_functionCode, search_mobileFunction_functionName, search_mobileFunction_role} = this.state;
    const mobileFunction = await searchMobileFunctionWithCountryApi({
      function_code: search_mobileFunction_functionCode ? search_mobileFunction_functionCode : null,
      function_name: search_mobileFunction_functionName ? search_mobileFunction_functionName : null,
      role: search_mobileFunction_role ? search_mobileFunction_role : null,
    });
    if (mobileFunction.status === 200) {
      const processedMobileFunction = JSON.parse(JSON.stringify(mobileFunction.result.content));
      const listMobileFunction = processedMobileFunction?.map((mobileFunction) => {
        mobileFunction.service_countries = mobileFunction.countries.filter((item) => item.enabled);
        return {
          ...mobileFunction,
          onFocus: false,
        };
      });
      this.setState({
        listMobileFunction: listMobileFunction,
        noResult: mobileFunction.result.content.length <= 0,
      });
    }
  }

  /**
   * confirmContentMobileFunction
   */
  async confirmContentMobileFunction() {
    this.closeConfirmModal();
    if (!this.state.btnAction) {
      const newMobileFunction = await createMobileFunctionApi({
        function_code: this.state.mobileFunction_functionCode,
        function_name: this.state.mobileFunction_functionName,
        note: this.state.mobileFunction_note,
        role: this.state.mobileFunction_role,
      });
      if (newMobileFunction.status === 200) {
        this.notifyResponse('Created Successfully!');
        this.searchMobileFunctionInfo();
        this.setState({
          formDisplay: false,
          mobileFunction_functionCode: '',
          mobileFunction_functionName: '',
          mobileFunction_note: '',
          mobileFunction_role: '',
          isSubmitForm: false,
        });
      } else this.notifyResponse('Create Failed!');
    } else {
      const newMobileFunction = await updateMobileFunctionApi({
        id: this.state.mobileFunction_id,
        function_code: this.state.mobileFunction_functionCode,
        function_name: this.state.mobileFunction_functionName,
        role: this.state.mobileFunction_role,
        show_type: this.state.mobileFunction_show_type,
      });

      const countries = [];
      for (let index = 0; index < this.state.service_countries.length; index++) {
        const element = this.state.service_countries[index];
        if (element['country_id'] !== '') {
          countries.push({
            country_id: this.state.service_countries[index]['country_id'],
            // order_number: this.state.mobileFunction_order_number,
            link: this.state.service_countries[index]['link'],
          });
        }
      }
      const configureMobileFunction = await configureMobileFunctionApi({
        mobile_function_id: this.state.mobileFunction_id,
        countries: countries,
      });

      if (newMobileFunction.status === 200 && configureMobileFunction.status === 200) {
        this.notifyResponse('Updated Successfully!');
        this.searchMobileFunctionInfo();
        this.setState({isSubmitForm: false});
      } else this.notifyResponse('Update Failed!');
    }
  }

  /**
   * openConfirmModal
   * @param {string} action
   */
  openConfirmModal(action) {
    this.setState({isSubmitForm: true});
    if (!this.validator.allValid()) return;
    let message = '';
    if (action !== '') {
      message = 'Are you sure to delete this Mobile Function?';
    } else if (this.state.btnAction) {
      action = 'update';
      message = 'Are you sure to update Mobile Function\'s information?';
    } else {
      action = 'create';
      message = 'Are you sure to create this Mobile Function?';
    }
    this.setState({});
    this.setState({
      action: action,
      confirmMessage: message,
      confirmModal: true,
    });
  }

  /**
   * detechOkAction
   */
  detechOkAction() {
    if (this.state.action === 'create' || this.state.action === 'update') {
      this.confirmContentMobileFunction();
    } else if (this.state.action === 'delete') {
      this.deleteMobileFunction();
    } else return;
  }

  /**
   * closeConfirmModal
   */
  closeConfirmModal() {
    this.setState({confirmModal: false});
  }

  /**
   * deleteMobileFunction
   */
  async deleteMobileFunction() {
    const deleteMobileFunction = await deleteMobileFunctionApi(this.state.mobileFunction_id);
    if (deleteMobileFunction.status === 200) {
      this.notifyResponse('Deleted Successfully!');
      this.searchMobileFunctionInfo();
      this.setState({
        formDisplay: false,
        btnAction: false,
      });
      this.closeConfirmModal();
    } else this.notifyResponse('Delete Failed!');
  }

  /**
   * clickBtnUpdate
   */
  async clickBtnUpdate() {
    this.setState({
      formDisplay: true,
    });
  }

  /**
   * clickTableLine
   * @param {number} index
   */
  clickTableLine = (index) => {
    const listMobileFunction = this.state.listMobileFunction;
    const listNewMobileFunction = listMobileFunction.map((mobileFunction) => {
      return {
        ...mobileFunction,
        onFocus: false,
      };
    });
    listNewMobileFunction[index].onFocus = true;
    this.setState({
      listMobileFunction: listNewMobileFunction,
      mobileFunction_id: listNewMobileFunction[index].id,
      mobileFunction_functionCode: listNewMobileFunction[index].function_code,
      mobileFunction_functionName: listNewMobileFunction[index].function_name,
      mobileFunction_role: listNewMobileFunction[index].role,
      mobileFunction_show_type: listNewMobileFunction[index].show_type,
      btnAction: true,
      formDisplay: false,
      service_countries: JSON.parse(JSON.stringify(listNewMobileFunction[index].service_countries)),
    });
  };

  /**
   * notifyResponse
   * @param {string} message
   */
  notifyResponse = (message) => {
    this.props.setMessageModal(modalObj(true, message));
  };

  /**
   * Render Component
   * @return {component}
   */
  render() {
    const {t, common} = this.props;

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>Mobile Function Management</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.DATA_MANAGEMENT}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="xl">
          <Box p={1} m={1}>
            <Card raised>
              <Container maxWidth="xl">
                <Box p={2} m={2}>
                  <Grid container alignItems="flex-start">
                    <Grid item xs={12}>
                      <h1 className="product_entry_table_header_color font_color_white font_size_mid title">Mobile Function</h1>
                      <Grid container alignItems="flex-start">
                        <Grid item xs={12}>
                          <Grid container spacing={1} alignItems="flex-start">
                            <Grid item xs={3}>
                              <TextField
                                className="width_100"
                                name="search_mobileFunction_functionCode"
                                margin="dense"
                                label="Function Code"
                                variant="outlined"
                                onChange={(event) => onChangeTextField(this, event)}
                              />
                            </Grid>
                            <Grid item xs={3}>
                              <TextField
                                className="width_100"
                                name="search_mobileFunction_functionName"
                                margin="dense"
                                label="Function Name"
                                variant="outlined"
                                onChange={(event) => onChangeTextField(this, event)}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <FormControl variant="outlined" margin="dense" className="width_100">
                                <InputLabel htmlFor="search_mobileFunction_role">Role</InputLabel>
                                <Select
                                  margin="dense"
                                  native
                                  label="Role"
                                  inputProps={{
                                    name: 'search_mobileFunction_role',
                                    id: 'search_mobileFunction_role',
                                  }}
                                  onChange={(event) => onChangeSelect(this, event)}
                                >
                                  <option value=""></option>
                                  <option value="USER">USER</option>
                                  <option value="DRIVER">DRIVER</option>
                                  <option value="ADMIN">ADMIN</option>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={2}>
                              <Button
                                color="primary"
                                variant="contained"
                                className="button_margin button_color"
                                endIcon={<SearchIcon />}
                                onClick={() => this.searchMobileFunctionInfo()}
                              >
                                {t('common.btnSearch')}
                              </Button>
                            </Grid>
                          </Grid>
                          {this.state.listMobileFunction.length > 0 && (
                            <Grid container alignItems="flex-start">
                              <div className="scroll_area_800">
                                <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className="width_50p">Function Code</TableCell>
                                      <TableCell className="width_100p">Function Name</TableCell>
                                      <TableCell className="width_100p">Note</TableCell>
                                      <TableCell className="width_50p">Role</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {this.state.listMobileFunction.map((item, index) => (
                                      <TableRow
                                        className={item.onFocus ? 'cursor_pointer background_pink' : 'cursor_pointer'}
                                        key={index}
                                        onClick={() => this.clickTableLine(index)}
                                      >
                                        <TableCell>{item.function_code ? item.function_code : DATA_NULL}</TableCell>
                                        <TableCell>{item.function_name ? item.function_name : DATA_NULL}</TableCell>
                                        <TableCell>{item.note ? item.note : DATA_NULL}</TableCell>
                                        <TableCell>{item.role ? item.role : DATA_NULL}</TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </div>
                            </Grid>
                          )}
                          {this.state.noResult && <h5 className="text_grey">No mobile function found</h5>}
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container alignItems="flex-start">
                            <Grid item xs={1}>
                              <Button
                                color="primary"
                                variant="contained"
                                className="button_margin
                                button_color_green"
                                endIcon={<AddIcon />}
                                onClick={() => this.clickBtnCreate()}
                              >
                                {t('common.btnRegister')}
                              </Button>
                            </Grid>
                            {this.state.btnAction && (
                              <>
                                <Grid item xs={1}>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    className="button_margin button-color-update"
                                    endIcon={<UpdateIcon />}
                                    onClick={() => this.clickBtnUpdate()}
                                  >
                                    {t('common.btnUpdate')}
                                  </Button>
                                </Grid>
                                <Grid item xs={1}>
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    className="button_margin button_color_red"
                                    endIcon={<DeleteIcon />}
                                    onClick={() => this.openConfirmModal('delete')}
                                  >
                                    {t('common.btnDelete')}
                                  </Button>
                                </Grid>
                              </>
                            )}
                          </Grid>
                          {this.state.formDisplay && (
                            <Grid container spacing={1} alignItems="flex-start">
                              <Grid item xs={12}>
                                <FormControl>
                                  <TextField
                                    className="field_size_20 field_min_size_250"
                                    value={this.state.mobileFunction_functionCode}
                                    margin="dense"
                                    label={
                                      <span>
                                        Function Code
                                        <span className="font_color_red">＊</span>
                                      </span>
                                    }
                                    onChange={(event) => this.onChangeTextFunctionCode(event)}
                                    variant="outlined"
                                    InputLabelProps={{shrink: true}}
                                  />
                                  {this.validator.message('function_code', this.state.mobileFunction_functionCode, 'required')}
                                  {this.state.isSubmitForm && !this.validator.check(this.state.mobileFunction_functionCode, 'required') && (
                                    <FormHelperText id="function_code" error>
                                      {t('validation.required', {field: 'Function Code'})}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                              <Grid item xs={12}>
                                <FormControl>
                                  <TextField
                                    className="field_size_20 field_min_size_250"
                                    value={this.state.mobileFunction_functionName}
                                    margin="dense"
                                    label={
                                      <span>
                                        Function Name
                                        <span className="font_color_red">＊</span>
                                      </span>
                                    }
                                    onChange={(event) => this.onChangeTextFunctionName(event)}
                                    variant="outlined"
                                    InputLabelProps={{shrink: true}}
                                  />
                                  {this.validator.message('function_name', this.state.mobileFunction_functionName, 'required')}
                                  {this.state.isSubmitForm && !this.validator.check(this.state.mobileFunction_functionName, 'required') && (
                                    <FormHelperText id="function_name" error>
                                      {t('validation.required', {field: 'Function Name'})}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                              {!this.state.btnAction && (
                                <Grid item xs={12}>
                                  <FormControl>
                                    <TextField
                                      className="field_size_20 field_min_size_250"
                                      value={this.state.mobileFunction_note}
                                      margin="dense"
                                      label="Note"
                                      onChange={(event) => this.onChangeTextNote(event)}
                                      variant="outlined"
                                      InputLabelProps={{shrink: true}}
                                    />
                                  </FormControl>
                                </Grid>
                              )}
                              <Grid item xs={12}>
                                <FormControl variant="outlined" className="field_size_20 field_min_size_250" margin="dense">
                                  <InputLabel htmlFor="data_role">Role</InputLabel>
                                  <Select
                                    margin="dense"
                                    native
                                    label="Role"
                                    inputProps={{
                                      id: 'data_role',
                                    }}
                                    value={this.state.mobileFunction_role}
                                    onChange={(event) => this.onChangeSelectRole(event)}
                                  >
                                    <option value=""></option>
                                    <option value="USER">USER</option>
                                    <option value="DRIVER">DRIVER</option>
                                    <option value="ADMIN">ADMIN</option>
                                  </Select>
                                  {this.validator.message('role', this.state.mobileFunction_role, 'required')}
                                  {this.state.isSubmitForm && !this.validator.check(this.state.mobileFunction_role, 'required') && (
                                    <FormHelperText id="role" error>
                                      {t('validation.required', {field: 'Role'})}
                                    </FormHelperText>
                                  )}
                                </FormControl>
                              </Grid>
                              {this.state.btnAction && (
                                <Grid item xs={12}>
                                  <Table aria-label="caption table">
                                    {this.state.service_countries.length < Object.keys(common.country_code).length && (
                                      <caption>
                                        <Button
                                          color="primary"
                                          variant="contained"
                                          className="button_magin button_color_green"
                                          endIcon={<AddIcon />}
                                          onClick={() => this.addRowSericeCountry()}
                                        >
                                          {t('common.btnAdd')}
                                        </Button>
                                      </caption>
                                    )}
                                    <TableHead>
                                      <TableRow>
                                        <TableCell>{t('common.country')}</TableCell>
                                        <TableCell>{t('menu_app.link')}</TableCell>
                                        <TableCell></TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {this.state.service_countries.map((row, index) => (
                                        <TableRow key={index} hover className="cursor_pointer">
                                          <TableCell>
                                            <FormControl variant="outlined" margin="dense">
                                              <Select
                                                margin="dense"
                                                displayEmpty
                                                onChange={(event) => onChangeListData(this, this.state.service_countries, 'country_id', index, event.target.value)}
                                                value={this.state.service_countries[index]['country_id']}
                                              >
                                                {common.country_code?.map((item, idx) => {
                                                  return (
                                                    <MenuItem
                                                      value={item.id}
                                                      key={idx}
                                                      disabled={
                                                        item.id !== this.state.service_countries[index]['country_id'] &&
                                                        this.state.service_countries.filter((item1) => Number(item1.country_id) === Number(item.id)).length > 0
                                                      }
                                                    >
                                                      {t(`${item.country_code}`)}
                                                    </MenuItem>
                                                  );
                                                })}
                                              </Select>
                                            </FormControl>
                                          </TableCell>
                                          <TableCell>
                                            <FormControl className="width_100">
                                              <TextField
                                                value={this.state.service_countries[index]['link']}
                                                margin="dense"
                                                label="Link"
                                                onChange={(event) => onChangeListData(this, this.state.service_countries, 'link', index, event.target.value)}
                                                variant="outlined"
                                              />
                                            </FormControl>
                                          </TableCell>
                                          <TableCell>
                                            <Button
                                              color="primary"
                                              variant="contained"
                                              className="button_margin button_color_red"
                                              onClick={() => this.handleRemoveRow(index)}
                                              endIcon={<DeleteIcon />}
                                            >
                                              {t('common.btnDelete')}
                                            </Button>
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </Grid>
                              )}
                              <Grid item xs={12}>
                                <Button color="primary" variant="contained" className="button_margin" onClick={() => this.openConfirmModal('')}>
                                  {t('common.btnConfirm')}
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Dialog
                        open={this.state.confirmModal}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          zIndex: '1020',
                        }}
                      >
                        <SelectModal onClickOk={() => this.detechOkAction()} onClickCancel={() => this.closeConfirmModal()} message={this.state.confirmMessage}></SelectModal>
                      </Dialog>
                    </Grid>
                  </Grid>
                </Box>
              </Container>
            </Card>
          </Box>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
  };
};

export default withPermissionData(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
