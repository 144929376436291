
export const ACTION_TYPES = Object.freeze({
  LOADING: 'LOADING',
  LOADING_FAIL: 'LOADING_FAIL',
  LIST_QR_PAYMENT_SUCCESS: 'LIST_QR_PAYMENT_SUCCESS',
  LIST_QR_PAYMENT_FAIL: 'LIST_QR_PAYMENT_SUCCESS',

  DETAIL_QR_PAYMENT_START: 'DETAIL_QR_PAYMENT_START',
  DETAIL_QR_PAYMENT_SUCCESS: 'DETAIL_QR_PAYMENT_SUCCESS',
  DETAIL_QR_PAYMENT_FAIL: 'DETAIL_QR_PAYMENT_FAIL',

  DELETE_QR_PAYMENT_START: 'DELETE_QR_PAYMENT_START',
  DELETE_QR_PAYMENT_SUCCESS: 'DELETE_QR_PAYMENT_SUCCESS',
  DELETE_QR_PAYMENT_FAIL: 'DELETE_QR_PAYMENT_FAIL',
  SEARCH_QR_PAYMENT_SUCCESS: 'SEARCH_QR_PAYMENT_SUCCESS',
});

const initialState = {
  isLoading: false,
  errorQrPaymentSet: [],
  detailQrPayment: {},
};

/**
 * function qr_payment reducer
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.LOADING:
    case ACTION_TYPES.DETAIL_QR_PAYMENT_START:
    case ACTION_TYPES.DELETE_QR_PAYMENT_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.SEARCH_QR_PAYMENT_SUCCESS:
    case ACTION_TYPES.LIST_QR_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorQrPaymentSet: action.errorQrPaymentSet,
      };
    case ACTION_TYPES.DETAIL_QR_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        detailQrPayment: action.detailQrPayment,
      };
    case ACTION_TYPES.LOADING_FAIL:
    case ACTION_TYPES.DELETE_QR_PAYMENT_SUCCESS:
    case ACTION_TYPES.DETAIL_QR_PAYMENT_FAIL:
    case ACTION_TYPES.DELETE_QR_PAYMENT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return {...state};
  }
}
