import {api} from '../services/api';

const subCostUrl = 'admin/subscriptions/sub-cost';

export const getSubCostsApi = (payload) => {
  return api.get(subCostUrl, payload);
};

export const getSubCostDetailApi = (id) => {
  return api.get(`${subCostUrl}/${id}`);
};
