import {ROUTE} from '../../common/constant';
import {createCompanyApi, getDetailCompanyApi, getListCompanyApi, updateCompanyApi, deleteCompanyApi, importCompanyMemberListCSVApi} from '../../services/companyServices';
import {modalObj} from '../../utils/modal.js';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

/**
 * search list company
 * @param {object} params
 * @param {object} queryParams
 * @param {object} props
 * @return {object}
 */
export const searchListCompany = (params, queryParams) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SEARCH_LIST_COMPANY_START,
    });
    return getListCompanyApi(params, queryParams).then(
        (response) => {
          if (response && response.status === 200) {
            if (response.result.content.length > 0) {
              dispatch({
                type: ACTION_TYPES.SEARCH_LIST_COMPANY_SUCCESS,
                listCompany: response.result,
              });
              return response.result;
            } else {
              dispatch({
                type: ACTION_TYPES.SEARCH_LIST_COMPANY_FAIL,
              });
            }
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
            dispatch({
              type: ACTION_TYPES.SEARCH_LIST_COMPANY_FAIL,
            });
          }
        },
        () =>
          dispatch({
            type: ACTION_TYPES.SEARCH_LIST_COMPANY_FAIL,
          }),
    );
  };
};

/**
 * Export list company
 * @param {object} payload
 * @param {object} queryParams
 * @return {object}
 */
export const exportCompany = (payload, queryParams) => {
  return (dispatch) => {
    return getListCompanyApi(payload, queryParams).then(
        (response) => {
          if (response && response.status === 200) {
            return response.result?.content;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
          }
        },
        () => dispatch(setMessageModal(modalObj(true, 'Internal Server Error'))),
    );
  };
};

/**
 * get detail company
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const getDetailCompany = (params) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_DETAIL_COMPANY_START,
    });
    return getDetailCompanyApi(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_DETAIL_COMPANY_SUCCESS,
              detailCompany: response.result,
            });
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
            dispatch({
              type: ACTION_TYPES.GET_DETAIL_COMPANY_FAIL,
            });
          }
        },
        () =>
          dispatch({
            type: ACTION_TYPES.GET_DETAIL_COMPANY_FAIL,
          }),
    );
  };
};

/**
 * create new company
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const createCompany = (params, props) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.CREATE_COMPANY_START,
    });
    return createCompanyApi(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.CREATE_COMPANY_SUCCESS,
            });
            props.history.push(ROUTE.LAYOUT + ROUTE.COMPANY_MANAGEMENT);
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.CREATE_COMPANY_FAIL,
            });
          }
        },
        () =>
          dispatch({
            type: ACTION_TYPES.CREATE_COMPANY_FAIL,
          }),
    );
  };
};

/**
 * update new company
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const updateCompany = (params, props) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.UPDATE_COMPANY_START,
    });
    return updateCompanyApi(params).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.UPDATE_COMPANY_SUCCESS,
            });
            const prevPath = props.location?.state?.from ? props.location?.state?.from : ROUTE.LAYOUT + ROUTE.COMPANY_MANAGEMENT;
            props.history.push(prevPath);
          } else if (response && response?.message_code) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.UPDATE_COMPANY_FAIL,
            });
          }
        },
        () =>
          dispatch({
            type: ACTION_TYPES.UPDATE_COMPANY_FAIL,
          }),
    );
  };
};

/**
 * delete new company
 * @param {object} params
 * @param {object} props
 * @return {object}
 */
export const deleteCompany = (params) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.DELETE_COMPANY_START,
    });
    return deleteCompanyApi(params).then(
        (response) => {
          if (response?.result && response.status === 200) {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.DELETE_COMPANY_SUCCESS,
            });
          } else {
            dispatch(setMessageModal(modalObj(true, response.message_code)));
            dispatch({
              type: ACTION_TYPES.DELETE_COMPANY_FAIL,
            });
          }
        },
        () =>
          dispatch({
            type: ACTION_TYPES.DELETE_COMPANY_FAIL,
          }),
    );
  };
};

/**
 * import csv
 * @param {object} params
 * @param {object} payload
 * @param {object} props
 * @return {object}
 */
export const importCompanyMemberListCSV = (params, payload) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.IMPORT_COMPANY_MEMBER_LIST_CSV_START,
    });
    return new Promise((resolve, reject) => {
      importCompanyMemberListCSVApi(params, payload).then(
          (response) => {
            if (response?.result && response.status === 200) {
              dispatch({
                type: ACTION_TYPES.IMPORT_COMPANY_MEMBER_LIST_CSV_SUCCESS,
                result: response.result,
              });
            } else {
              dispatch({
                type: ACTION_TYPES.IMPORT_COMPANY_MEMBER_LIST_CSV_FAIL,
              });
              window.setTimeout(function() {
                dispatch(setMessageModal(modalObj(true, response.message_code)));
                return resolve(response);
              }, 30000);
            }
          },
          (error) => {
            dispatch({
              type: ACTION_TYPES.IMPORT_COMPANY_MEMBER_LIST_CSV_FAIL,
            });
            return reject(error);
          },
      );
    });
  };
};
