import React, {Component} from 'react';

import {Button, Card, Container, Grid, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, FormControl, Select, MenuItem} from '@material-ui/core';
import {Search as SearchIcon, Description as DetailIcon, ArrowBack as ArrowBackIcon, RotateLeft as RotateLeftIcon} from '@material-ui/icons';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {ROUTE, DATA_NULL, ROWS_PER_PAGE_OPTIONS, PERMISSION_ACTIONS, LANGUAGE, COMMON_STATUS} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {searchWaypointApi} from '../../../services/businessServices';
import {getAllCountryCode, getAllGeofence} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {onChangeSelect, onChangeTextField, getQueryStringFromObject, getUrlParams, changeUrlParams, backForwardRouter} from '../../../utils/common';
import {modalObj} from '../../../utils/modal';
import './style.css';
import {isRoleGlobal} from '../../../utils/role';

/**
 * Waypoint Search Component
 */
class Index extends Component {
  /**
   * constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      country_code: '',
      geofence_id: '',
      partner_name: '',
      waypoint_name: '',

      searchResult: [],
      isSearch: false,
      waypointId: 0,
      loading: false,
      searchParams: this.props.location.search,
      latestSearchParams: null,
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      waypoint_restrict: '',
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    await this.props.getAllCountryCode();
    await this.props.getAllGeofence().then(() => this.setState({listGeofenceAll: this.props.common?.all_geofence}));
    const {common, principal} = this.props;
    if (!isRoleGlobal()) {
      this.setState({
        country_code: common.country_code.find((item) => item.id === principal.country_id)?.country_code,
      });
    };
    if (this.props.location.search) {
      const params = getUrlParams(this.props.location.search);
      if (isRoleGlobal() ? true : common?.country_code?.find((e) => e.country_code === params?.country_code)?.id === principal.country_id) {
        this.setState(params, () => {
          if (this.state.country_code) {
            const country_id = common?.country_code?.find((e) => e.country_code === this.state.country_code)?.id;
            this.setState({
              listGeofenceAll: common?.all_geofence?.filter((e) => e.country_id === Number(country_id)),
            });
          }
          this.handleSearch();
        });
      } else backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.HOME);
    }
  };

  /**
   * handleOnChangeCountry
   * @param {*} value
   */
  handleOnChangeCountry = (value) => {
    const country_id = this.props.common?.country_code?.find((e) => e.country_code === value)?.id;
    this.setState({
      country_code: value,
      geofence_id: '',
      listGeofenceAll: this.props.common?.all_geofence?.filter((e) => e.country_id === country_id),
    });
  };

  /**
   * resetSearch
   */
  resetSearch() {
    const {common, principal} = this.props;
    this.setState({
      searchResult: [],
      totalRows: 0,
      country_code: !isRoleGlobal() ? common.country_code.find((item) => item.id === principal.country_id)?.country_code : '',
      geofence_id: '',
      partner_name: '',
      waypoint_name: '',
      listGeofenceAll: common?.all_geofence,
      isSearch: false,
      waypoint_restrict: '',
    });
  }

  /**
   * onChangePagination
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearch);
  };

  /**
   * handleSearch
   * @param {*} reset
   */
  async handleSearch(reset) {
    this.setState({loading: true});
    const {country_code, geofence_id, waypoint_name, partner_name, currentPage, rowsPerPage} = this.state;
    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const queryParams = {
      country_code,
      geofence_id,
      waypoint_name: waypoint_name.trim(),
      partner_name: partner_name.trim(),
      waypoint_restrict: this.state.waypoint_restrict ? this.state.waypoint_restrict === 'ENABLE' : null,
      ...(reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage}),
    };

    queryParams.waypoint_restrict === null && delete queryParams.waypoint_restrict;

    const listWaypoint = await searchWaypointApi(
      queryParams.country_code,
      queryParams.geofence_id,
      queryParams.waypoint_name,
      queryParams.partner_name,
      queryParams.page,
      queryParams.size,
      queryParams.waypoint_restrict,
    );
    if (listWaypoint.status === 200) {
      this.setState(
        {
          searchResult: listWaypoint.result.content,
          totalRows: listWaypoint.result.totalSize,
          isSearch: true,
        },
        () => {
          const {country_code, geofence_id, waypoint_name, partner_name, currentPage, rowsPerPage, waypoint_restrict} = this.state;
          // Apply changed params into url
          const queryParamsToChange = {
            country_code,
            geofence_id,
            waypoint_name,
            partner_name,
            currentPage: String(currentPage),
            rowsPerPage,
            waypoint_restrict,
          };
          changeUrlParams(queryParamsToChange);
          const newSearchParams = getQueryStringFromObject(queryParamsToChange);
          // Save search params into state in order to pass to next page
          this.setState({searchParams: newSearchParams});
        },
      );
    }
    this.setState({loading: false});
  }

  /**
   * notifyResponse
   * @param {string} message
   */
  notifyResponse = (message) => {
    this.props.setMessageModal(modalObj(true, message));
  };

  /**
   * Render Component
   * @return {HTML}
   */
  render() {
    const {t, common, actions} = this.props;
    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.waypointInformation')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Container>
        <br />
        <LoadingOverlay active={this.state.loading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                {permission.canSearch && (
                  <>
                    <br />
                    <Paper className="search_table">
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                          {t('businessPartner.searchTitle')}
                        </Grid>
                      </Grid>
                      {/* Country */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.country')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'country_code',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.country_code ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('common.country'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.country_code}
                              onChange={(event) => this.handleOnChangeCountry(event.target.value)}
                              disabled={!isRoleGlobal()}
                            >
                              {common?.country_code?.map((item, idx) => (
                                <MenuItem value={item.country_code} key={idx}>
                                  {t(`${item.country_code}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* Geofence */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.geofence')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'geofence_id',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.geofence_id ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('common.geofence'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.geofence_id}
                              onChange={(e) => onChangeSelect(this, e)}
                            >
                              {this.state.listGeofenceAll?.map((item, idx) => (
                                <MenuItem value={item.geofence_id} key={idx} className={!item.enable ? 'disable-option-custom' : ''}>
                                  {t(`${item.name}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      {/* Waypoint Name */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                          {t('common.name')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="waypoint_name"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('validation.required', {field: t('common.name')})}
                            value={this.state.waypoint_name}
                            onChange={(event) => onChangeTextField(this, event)}
                            inputProps={{maxLength: 256}}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      {/* Partner Name */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                          {t('businessPartner.name')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            name="partner_name"
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            placeholder={t('validation.required', {field: t('businessPartner.name')})}
                            value={this.state.partner_name}
                            onChange={(event) => onChangeTextField(this, event)}
                            inputProps={{maxLength: 256}}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      {/* Waypoint Restrict */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                          {t('waypoint.waypoint_restrict')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                        <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'waypoint_restrict',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.waypoint_restrict ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('waypoint.waypoint_restrict'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.waypoint_restrict}
                            onChange={(e) => onChangeSelect(this, e)}
                          >
                            {COMMON_STATUS?.map((item, idx) => (
                              <MenuItem value={item.id} key={idx}>
                                {t(`${item.i18n}`)}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        </Grid>
                      </Grid>
                    </Paper>
                  </>
                )}
                <br />
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.resetSearch()} endIcon={<RotateLeftIcon />}>
                        {t('common.btnResetSearch')}
                      </Button>
                    )}
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin" endIcon={<SearchIcon />} onClick={() => this.handleSearch(true)}>
                        {t('common.btnSearch')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Container>
              <br />
            </Card>
            <br />
          </Container>
          <Container maxWidth="xl">
            {permission.canSearch && this.state.isSearch && (
              <Card raised>
                <Container maxWidth="xl">
                  <br />
                  <Grid container spacing={1}>
                    <Grid container alignItems="center" justify="flex-start" item xs={3}>
                      <h3>
                        {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                      </h3>
                    </Grid>
                  </Grid>

                  <div className="scroll_area_700">
                    {this.state.totalRows > 0 && (
                      <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                        <TableHead>
                          <TableRow>
                            <TableCell className="width_150p">{t('common.name')} (JP)</TableCell>
                            <TableCell className="width_150p">{t('common.name')} (VN)</TableCell>
                            <TableCell className="width_150p">{t('common.name')} (EN)</TableCell>
                            <TableCell className="width_150p">{t('waypoint.waypoint_restrict')}</TableCell>
                            <TableCell className="width_250p">{t('waypoint.description')} (JP)</TableCell>
                            <TableCell className="width_250p">{t('waypoint.description')} (VN)</TableCell>
                            <TableCell className="width_250p">{t('waypoint.description')} (EN)</TableCell>
                            <TableCell className="width_125p">{t('businessPartner.name')} (JP)</TableCell>
                            <TableCell className="width_125p">{t('businessPartner.name')} (VN)</TableCell>
                            <TableCell className="width_125p">{t('businessPartner.name')} (EN)</TableCell>
                            {permission.canEdit && <TableCell className="width_75p" style={{position: 'sticky', right: '0'}}>{t('common.action')}</TableCell>}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.searchResult.map((item, index) => {
                            const partnerJp = item?.partner_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id);
                            const partnerVn = item?.partner_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id);
                            const partnerEn = item?.partner_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id);
                            return (
                              <TableRow key={index} hover className="cursor_pointer">
                                <TableCell>
                                  {item?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.name || DATA_NULL}
                                </TableCell>
                                <TableCell>
                                  {item?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.name || DATA_NULL}
                                </TableCell>
                                <TableCell>
                                  {item?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.name || DATA_NULL}
                                </TableCell>
                                <TableCell>{item?.waypoint_restrict ? t('common.enable') : t('common.disable')}</TableCell>
                                <TableCell className="limit_line">
                                  {item?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.description || DATA_NULL}
                                </TableCell>
                                <TableCell className="limit_line">
                                  {item?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.description || DATA_NULL}
                                </TableCell>
                                <TableCell className="limit_line">
                                  {item?.waypoint_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.description || DATA_NULL}
                                </TableCell>
                                <TableCell>
                                  {partnerJp ? (
                                    <Link
                                      style={{textDecoration: 'none'}}
                                      to={{pathname: ROUTE.LAYOUT + ROUTE.PARTNER_INFORMATION, search: getQueryStringFromObject({name: partnerJp.name})}}
                                    >
                                      {partnerJp.name}
                                    </Link>
                                  ) : (
                                    DATA_NULL
                                  )}
                                </TableCell>
                                <TableCell>
                                  {partnerVn ? (
                                    <Link
                                      style={{textDecoration: 'none'}}
                                      to={{pathname: ROUTE.LAYOUT + ROUTE.PARTNER_INFORMATION, search: getQueryStringFromObject({name: partnerVn.name})}}
                                    >
                                      {partnerVn.name}
                                    </Link>
                                  ) : (
                                    DATA_NULL
                                  )}
                                </TableCell>
                                <TableCell>
                                  {partnerEn ? (
                                    <Link
                                      style={{textDecoration: 'none'}}
                                      to={{pathname: ROUTE.LAYOUT + ROUTE.PARTNER_INFORMATION, search: getQueryStringFromObject({name: partnerEn.name})}}
                                    >
                                      {partnerEn.name}
                                    </Link>
                                  ) : (
                                    DATA_NULL
                                  )}
                                </TableCell>
                                <TableCell className="cell_fixed_right">
                                  {permission.canEdit && (
                                    <Link
                                      style={{textDecoration: 'none'}}
                                      to={{
                                        pathname: ROUTE.LAYOUT + ROUTE.WAYPOINT_DETAILS + '/' + item.id,
                                        state: {
                                          from: this.props.location.pathname + '?' + this.state.searchParams,
                                          geofence_id: item?.geofence_id,
                                        },
                                      }}
                                    >
                                      <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                        {t('common.btnEdit')}
                                      </Button>
                                    </Link>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    )}
                  </div>
                  {this.state.totalRows > 0 && (
                    <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                  )}
                </Container>
                <br />
              </Card>
            )}
            <br />
          </Container>
        </LoadingOverlay>
        <br />
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getAllGeofence: () => dispatch(getAllGeofence()),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
