import React, {Component} from 'react';

import {Card, Container, Grid, Button, Typography, Box, TextField} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import RemoveIcon from '@material-ui/icons/Remove';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';

import Carts from '../../../../components/carts';
import withPermissionGateway from '../../../../hoc/withPermissionGateway';
import {getProduct, getProductByStoreId} from '../../../../services/reservationServices';
import {addAnItem, addAnyItems, subAnItem, removeItems} from '../../../../stores/carts/actions';
import {redirectRouter} from '../../../../utils/common';

/**
 * Reservation management view
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      product_id: props.match.params.id,
      product: '',
      products: [],
    };
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    await this.getProduct();
    await this.getProductByStoreId();
    this.setState({
      isLoading: false,
    });
  }


  /**
   * componentWillReceiveProps
   * @param {*} nextProps
   */
  async componentWillReceiveProps(nextProps) {
    const nextId = nextProps.match.params.id;
    const currentId = this.props.match.params.id;
    if (currentId !== nextId) {
      this.setState({
        isLoading: true,
        product_id: nextProps.match.params.id,
      });
      await this.getProduct();
      await this.getProductByStoreId();
      this.setState({
        isLoading: false,
      });
    }
  }

  /**
   * getProduct
   */
  async getProduct() {
    const product = await getProduct(this.state.product_id);
    if (product.status === 200) {
      this.setState({
        product: product.result,
      });
    }
  }

  /**
   * getProductByStoreId
   */
  async getProductByStoreId() {
    const detail = await getProductByStoreId(this.state.product.category.id);
    if (detail.status === 200) {
      this.setState({
        products: detail.result,
      });
    }
  }

  /**
   * addAnItemToCart
   */
  addAnItemToCart() {
    this.props.addAnItem(this.state.product);
  }

  /**
  * subAnItemFromCart
  */
  subAnItemFromCart() {
    if (this.props.current_quantity > 0) this.props.subAnItem(this.state.product);
  }

  /**
   * toProductDetail
   * @param {int} id
   */
  toProductDetail = (id) => {
    const path = '/maas/reservation/created/delivery/facilities/product/' + id;
    redirectRouter(this.props, path);
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t} = this.props;
    return (
      <LoadingOverlay
        active={this.state.isLoading}
        bgColor="#f1f1f1"
        spinnerColor="#9ee5f8"
        textColor="#676767"
        spinner
      >
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('reservationManagement.restaurant_detail')}</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}>
                <Button
                  color="primary"
                  variant="contained"
                  className="button_margin button_color"
                  endIcon={<ArrowBackIcon />}
                  onClick={this.props.history.goBack}
                >
                  {t('common.btnReturn')}
                </Button>
              </Grid>
            </Grid>
          </Container>
          {/* Step 5 */}

          <Container maxWidth="xl">
            <Box p={2} m={2}>
              <Card raised>
                <Container maxWidth="xl">
                  <Box mt={2} mb={2}>
                    <Grid container spacing={3} alignItems="flex-start">
                      {this.state.product && (
                        <Grid item xs={12}>
                          <img
                            className="width_100 height_250p"
                            src={
                              this.state.product.image_url.length > 0 ?
                                this.state.product.image_url[0] :
                                `${process.env.PUBLIC_URL}/images/reservation/no_image.png`
                            }
                            alt=""
                          />
                          <Grid container spacing={3} alignItems="flex-start" className="height_250p">
                            <Grid item xs={12}>
                              <Typography gutterBottom variant="h4" component="h4">
                                {this.state.product.product_name}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography gutterBottom variant="h6" component="h6">
                                {this.state.product.description}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                  {this.state.product && (
                    <Box mt={2} mb={2}>
                      <Grid container spacing={3} alignItems="flex-start">
                        <Grid item xs={12}>
                          <Box p={2} className="bg_grey">
                            <Typography gutterBottom variant="h4" component="h4">
                              {t('reservationManagement.quantity')}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <IconButton color="secondary" onClick={() => this.subAnItemFromCart()}>
                            <RemoveIcon />
                          </IconButton>
                          <TextField
                            id="standard-number"
                            label={t('reservationManagement.items')}
                            type="number"
                            InputLabelProps={{
                              shrink: true,
                            }}
                            value={this.props.current_quantity}
                          />
                          <IconButton color="secondary" onClick={() => this.addAnItemToCart()}>
                            <AddCircleIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  <Box mt={2} mb={2}>
                    <Grid container spacing={3} alignItems="flex-start">
                      <Grid item xs={12}>
                        <Box p={2} className="bg_grey">
                          <Typography gutterBottom variant="h4" component="h4">
                            {t('reservationManagement.items')}
                          </Typography>
                        </Box>
                      </Grid>
                      {this.state.products.map((item) => {
                        return (
                          <Grid item xs={12} className="cursor_pointer" onClick={() => this.toProductDetail(item.id)}>
                            <Grid container spacing={3} alignItems="flex-start" className="border_bottom">
                              <Grid item xs={2}>
                                <img
                                  className="width_100"
                                  src={
                                    item.image_url.length > 0 ?
                                      item.image_url[0] :
                                      `${process.env.PUBLIC_URL}/images/reservation/no_image.png`
                                  }
                                  alt=""
                                />
                              </Grid>
                              <Grid item xs={10}>
                                <Typography gutterBottom variant="h4" component="h4">
                                  {item.product_name}
                                </Typography>
                                <Typography gutterBottom variant="h4" component="h4" className="font_color_red">
                                  ¥{item.price}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                </Container>
              </Card>
            </Box>
          </Container>
        </Card>
        <Box mt={2} mb={2}>
          <Carts history={this.props.history}></Carts>
        </Box>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const product_id = ownProps.match.params.id;
  const current_item = state.carts.items.find((item) => Number(product_id) === Number(item.id));
  return {
    current_quantity: current_item ? current_item.quantity : 0,
    current_item: current_item,
    cart_items: state.carts.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addAnItem: (params, props) => dispatch(addAnItem(params, props)),
    addAnyItems: (params, props) => dispatch(addAnyItems(params, props)),
    subAnItem: (params, props) => dispatch(subAnItem(params, props)),
    removeItems: (params, props) => dispatch(removeItems(params, props)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
