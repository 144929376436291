import React, {Component} from 'react';

import {Card, Container, Grid, Button, Typography, Box} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';

import withPermissionGateway from '../../../../hoc/withPermissionGateway';
import {removeAllItems} from '../../../../stores/carts/actions';
import {redirectRouter, convertToCurrency} from '../../../../utils/common';

/**
 * Reservation management view
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * toProductDetail
   * @param {int} id
   */
  toProductDetail = (id) => {
    const path = '/maas/reservation/created/delivery/facilities/product/' + id;
    redirectRouter(this.props, path);
  };

  /**
   * toCustomerPage
   */
  toCustomerPage() {
    if (this.props.cart_items.length > 0) {
      const path = '/maas/reservation/created/info';
      redirectRouter(this.props, path);
    }
  }

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t} = this.props;
    return (
      <div>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('reservationManagement.restaurant_detail')}</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />} onClick={this.props.history.goBack}>
                  {t('common.btnReturn')}
                </Button>
              </Grid>
            </Grid>
          </Container>
          {/* Step 5 */}

          <Container maxWidth="xl">
            <Box p={2} m={2}>
              <Card raised>
                <Container maxWidth="xl">
                  <Box p={2} m={2}>
                    <Grid container spacing={3} alignItems="flex-start" className="bg_black">
                      {/* <Typography gutterBottom variant="h5" component="h5">
                        {t('reservationManagement.order_details')}
                      </Typography> */}
                      <Typography gutterBottom variant="h6" component="h6">
                        {t('reservationManagement.change_order')}
                      </Typography>
                    </Grid>
                  </Box>
                  <Box p={2} m={2}>
                    <Grid container spacing={3} alignItems="flex-start">
                      <Grid item xs={12}>
                        <Box mt={2} mb={2}>
                          <Grid container spacing={3} alignItems="flex-start">
                            {this.props.cart_items.map((item) => {
                              return (
                                <Grid item xs={12} className="cursor_pointer" onClick={() => this.toProductDetail(item.id)}>
                                  <Grid container spacing={3} alignItems="flex-start" className="border_bottom">
                                    <Grid item xs={2} className="relative">
                                      <img
                                        className="width_100"
                                        src={item.image_url.length > 0 ? item.image_url[0] : `${process.env.PUBLIC_URL}/images/reservation/no_image.png`}
                                        alt=""
                                      />
                                      <div className="quantity">{item.quantity}</div>
                                    </Grid>
                                    <Grid item xs={10}>
                                      <Typography gutterBottom variant="h4" component="h4">
                                        {item.product_name}
                                      </Typography>
                                      <Typography gutterBottom variant="h4" component="h4" className="font_color_red">
                                        {convertToCurrency(item.price)}円
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} alignItems="flex-start">
                      <Grid item xs={12}>
                        <Button color="primary" variant="contained" className="button_margin" onClick={() => this.toCustomerPage()}>
                          Next
                        </Button>
                        <Button color="secondary" variant="contained" className="button_margin" onClick={() => this.props.removeAllItems()}>
                          Remove All
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Container>
              </Card>
            </Box>
          </Container>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    cart_items: state.carts.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeAllItems: (props) => dispatch(removeAllItems(props)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
