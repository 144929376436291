import {api} from './api';

const searchQRTicketsUrl = 'qr/tickets/user/search';
const getQRTicketDetailUrl = 'qr/tickets/user/{id}';
const getBoardingTicketUrl = 'qr/boarding-by-ticket/{ticketUserId}';
const disableQRTicketUrl = 'qr/tickets/user/{id}/disable';

export const searchQRTicketsApi = (payload, queryParam) => {
  return api.search(searchQRTicketsUrl, queryParam, payload);
};

export const getQRTicketDetailApi = (id) => {
  return api.get(getQRTicketDetailUrl.replace('{id}', id));
};

export const getBoardingTicketApi = (id) => {
  return api.get(getBoardingTicketUrl.replace('{ticketUserId}', id));
};

export const disableQRTicketApi = (id) => {
  return api.put(disableQRTicketUrl.replace('{id}', id), {});
};
