import React, {Component} from 'react';

import {Paper, Container, Button, Grid} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import {withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom/cjs/react-router-dom';

import {MOBI_TYPES, ROUTE} from '../../common/constant';

/**
 * Modal component
 */
class Index extends Component {
  /**
   * Render component
   * @return {component}
   */
  render() {
    const {t} = this.props;
    return (
      <Paper className="field_min_size_400">
        <Container maxWidth="xl">
          <br />
          <Grid container spacing={6}>
            <Grid container alignItems="center" justifyContent="flex-end" item xs={2}>
              <InfoIcon className="errorinfo" fontSize="large" />
            </Grid>
            <Grid container alignItems="center" justifyContent="flex-start" item xs={10}>
              <span className="font_size_small_regular" style={{whiteSpace: 'pre-line'}}>
                {t(`${this.props?.message}`, this.props?.fields)}
              </span>
            </Grid>
            {this.props?.listBookingCancel?.length > 0 && (
              <>
                <Grid container alignItems="center" item justifyContent="flex-start" xs={2} style={{paddingTop: '0px'}}></Grid>
                <Grid xs={10} item style={{paddingTop: '0px'}}>
                  <strong>{t('reservationManagement.reservation_number_search')}: </strong>
                  <br></br>
                  {this.props?.listBookingCancel.map((booking, index) => {
                    return (
                      <>
                        <Link
                          target="_blank"
                          style={{textDecoration: 'none', color: '#0000EE'}}
                          to={{
                            pathname: ROUTE.LAYOUT + ROUTE.RESERVATION_MANAGEMENT_DETAIL + `/${MOBI_TYPES[0].id}/${booking.booking_id}`,
                          }}
                        >
                          {booking.reservation_number}
                          {this.props?.listBookingCancel?.length - 1 > index ? ', ' : ''}
                        </Link>
                      </>
                    );
                  })}
                </Grid>
              </>
            )}
            <Grid container alignItems="center" justifyContent="center" item xs={12}>
              <Button
                fullWidth
                variant="contained"
                className=""
                color="primary"
                onClick={() => {
                  this.props.closeMessage();
                }}
              >
                {t('common.btnAgree')}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <br />
      </Paper>
    );
  }
}

export default withTranslation('translations')(Index);
