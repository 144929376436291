import React, {Component} from 'react';

import {Card, Container, Grid, Table, TableCell, TableRow, TableHead, TableBody, Button} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';

import {VEHICLE_MOBI_TYPES_CONST} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getVirtualVehiclesApi} from '../../../services/businessServices';
import {convertDatetimeUTC, displayDateTime} from '../../../utils/datetime';

/**
 * Vehicle Registration Page
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      virtualVehicles: [],
      isLoading: false,
      totalRows: 0,
      rowsPerPage: 10,
      currentPage: 1,
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.getVirtualVehicles(this.props.match.params.id);
    this.setState({
      isLoading: false,
    });
  }

  /**
   * getVirtualVehicles
   * @param {Number} id
   */
  getVirtualVehicles = async (id) => {
    const payload = {
      vehicleId: id,
    };
    const data = await getVirtualVehiclesApi(payload);
    if (Number(data.status) === 200) {
      this.setState({
        virtualVehicles: data.result,
      });
    }
  };

  /**
   * onChangePagination
   * @param {Number} currentPage
   * @param {Number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.getVirtualVehicles(this.props.match.params.id));
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t} = this.props;
    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('businessVehicle.title')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />} onClick={this.props.history.goBack}>
                {t('common.btnReturn')}
              </Button>
            </Grid>
          </Grid>
          <br></br>
          <Card className="main_card_min_size">
            <Container maxWidth="xl">
              <Card raised>
                <Container maxWidth="xl">
                  <LoadingOverlay active={this.state.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                    {this.state.virtualVehicles?.length > 0 ? (
                      <div className="scroll_area_700">
                        <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                          <TableHead>
                            <TableRow>
                              <TableCell className="width_100p">{t('external_id')}</TableCell>
                              <TableCell className="width_100p">{t('simulation_id')}</TableCell>
                              <TableCell className="width_100p">{t('operation_status')}</TableCell>
                              <TableCell className="width_150p">{t('businessVehicle.status')}</TableCell>
                              <TableCell className="width_150p">{t('group_type')}</TableCell>
                              <TableCell className="width_200p">{t('start_time')}</TableCell>
                              <TableCell className="width_200p">{t('start_date_time')}</TableCell>
                              <TableCell className="width_200p">{t('updated_date')}</TableCell>
                              <TableCell className="width_100p">{t('vehicle_id')}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.virtualVehicles?.map((row, index) => {
                              return (
                                <TableRow key={index} hover className="cursor_pointer">
                                  <TableCell>{row.external_id}</TableCell>
                                  <TableCell>{row.simulation_id}</TableCell>
                                  <TableCell>{row.operation_status}</TableCell>
                                  <TableCell>{row.status}</TableCell>
                                  <TableCell>{VEHICLE_MOBI_TYPES_CONST[row.group_type]}</TableCell>
                                  <TableCell>{displayDateTime(convertDatetimeUTC(row?.start_time, row?.country_id))}</TableCell>
                                  <TableCell>{displayDateTime(convertDatetimeUTC(row?.start_date_time, row?.country_id))}</TableCell>
                                  <TableCell>{displayDateTime(convertDatetimeUTC(row?.updated_date, row?.country_id))}</TableCell>
                                  <TableCell>{row.vehicle_id}</TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                        <br></br>
                      </div>
                    ) : (
                      ''
                    )}
                    <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                  </LoadingOverlay>
                </Container>
              </Card>
              <br></br>
            </Container>
          </Card>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
