import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {Card, Container, Grid, Paper, Table, TableCell, TableRow, TextField, Button, Select, MenuItem, FormControl, TableHead, TableBody, Dialog} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import DetailIcon from '@material-ui/icons/Description';
import {BUSINESS_TYPE, BUSINESS_STATUS, ROUTE, ROWS_PER_PAGE_OPTIONS, MAX_SIZE_OUTPUT, EXPORT_LIMIT_NUMBER_ROWS, VEHICLE_TYPE} from '../../../common/constant';
import SelectModal from '../../../components/selectModal';
import CustomPagination from '../../../components/CustomPagination';
import {setMessageModal} from '../../../stores/modal/actions';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {searchListFacility, deleteFacility, exportFacility} from '../../../stores/facility/action';
import {getAllFacilityList, getListGeofence, getListSupplier} from '../../../stores/common/actions';
import {modalObj} from '../../../utils/modal';
import {onChangeSelect, changeUrlParams, getUrlParams, getQueryStringFromObject} from '../../../utils/common';

/**
 * Setting Taxi's Fee Search Component
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      geofence_id: '',
      facilityStatus: null,
      vehicle_type: '',
      supplierCode: '',
      supplierName: '',
      facilityName: '',

      flgDelete: false,
      listCheckbox: [],
      checkbox: [],
      listRowDelete: [],
      flag: false,
      message: '',
      result: [],
      isSearch: false,
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,
      flagExport: false,
      confirmedExport: false,
      latestSearchParams: null,
    };
    this.handleButtonOk = this.handleButtonOk.bind(this);
    this.handleButtonCancel = this.handleButtonCancel.bind(this);
    this.exportCSVRef = React.createRef();
    const {t} = this.props;
    this.exportHeaders = [
      {label: t('facility.id'), key: 'facility_id'},
      {label: t('facility.status'), key: 'facility_status'},
      {label: t('facility.type'), key: 'facility_type'},
      {label: t('facility.business.name'), key: 'supplier_name'},
      {label: t('facility.name'), key: 'facility_name'},
    ];
  }

  /**
   * reset
   */
  reset = () => {
    this.setState({
      geofence_id: '',
      facilityStatus: null,
      vehicle_type: '',
      supplierCode: '',
      supplierName: '',
      facilityName: '',
      flgDelete: false,
      flag: false,
      message: '',
      result: [],
      id: '',
      index: '',
    });
  };

  /**
   * handleDelete
   * @param {object} id
   * @param {object} index
   */
  handleDelete = (id, index) => {
    this.setState({
      flgDelete: true,
      message: 'facility.question.confirm.delete',
      id: id,
      index: index,
    });
  };
  /**
   * handleButtonOk
   */
  handleButtonOk() {
    this.setState({
      flgDelete: false,
    });
    this.props.deleteFacility(this.state.id, this.props).then(() => {
      const result = this.state.result;
      result.splice(this.state.index, 1);
      this.setState({
        result: result,
      });
    });
  }

  /**
   * handleButtonCancel
   */
  handleButtonCancel() {
    this.setState({
      flgDelete: false,
    });
  }

  /**
   * handleButtonCancel
   * @param {event} value
   */
  changeBusiness = (value) => {
    this.setState({supplierName: value});
  };

  /**
   * handleSearch
   * @param {bool} reset
   */
  handleSearch = (reset) => {
    const payLoad = {
      geofence_id: this.state.geofence_id?.geofence_id ? this.state.geofence_id?.geofence_id : null,
      facility_status: this.state.facilityStatus,
      facility_type: this.state.vehicle_type ? this.state.vehicle_type : null,
      supplier_id: this.state.supplierCode.trim(),
      supplier_name: this.state.supplierName ? this.state.supplierName.supplier_name : '',
      facility_name: this.state.facilityName ? this.state.facilityName.facility_name : null,
    };

    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const {currentPage, rowsPerPage} = this.state;
    const queryParams = reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage};
    this.props.searchListFacility(payLoad, queryParams, this.props).then((response) => {
      if (!response) {
        response = [];
      }
      this.setState(
        {
          result: response?.content,
          isSearch: true,
          totalRows: response.totalSize ? response.totalSize : 0,
          latestSearchParams: payLoad,
        },
        () => {
          const {geofence_id, facilityStatus, vehicle_type, supplierCode, supplierName, facilityName, currentPage, rowsPerPage} = this.state;
          // Apply changed params into url
          const queryParamsToChange = {
            geofence_id: geofence_id && JSON.stringify(geofence_id),
            facilityStatus,
            vehicle_type,
            supplierCode,
            supplierName: supplierName && JSON.stringify(supplierName),
            facilityName,
            currentPage: String(currentPage),
            rowsPerPage,
          };
          changeUrlParams(queryParamsToChange);
          const newSearchParams = getQueryStringFromObject(queryParamsToChange);
          // Save search params into state in order to pass to next page
          this.setState({searchParams: newSearchParams});
        }
      );
    });
  };

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearch);
  };

  /**
   * componentDidMount
   */
  componentDidMount = async () => {
    this.props.getAllFacilityList();
    this.props.getListSupplier();
    await this.props.getListGeofence().then((res) => {
      this.setState({
        listArea: res,
      });
      if (this.props.location.search) {
        this.setState(getUrlParams(this.props.location.search), this.handleSearch);
      }
    });
  };

  /**
   * Fetch data to export
   */
  fetchDataExport = async () => {
    const {t} = this.props;
    const queryParams = {page: 0, size: MAX_SIZE_OUTPUT};
    const dataExport = await this.props.exportFacility(this.state.latestSearchParams, queryParams);
    const convertedData = dataExport.map((row, index) => {
      row.facility_status = BUSINESS_STATUS.map(({id, i18n}) => (`${row.facility_status}` === id ? t(`${i18n}`) : ''));
      row.facility_type = BUSINESS_TYPE.map(({id, i18n}) => (`${row?.facility_type}` === id ? t(`${i18n}`) : ''));
      return row;
    });
    this.setState({confirmedExport: false});
    return convertedData;
  };

  /**
   * alertExportCSV
   */
  alertExportCSV = () => {
    const {t} = this.props;
    if (this.state.totalRows > EXPORT_LIMIT_NUMBER_ROWS) {
      this.props.setMessageModal(modalObj(true, t('messageCode.exportAlertLimit')));
    } else {
      const message = t('messageCode.exportAlert');
      this.setState({flagExport: true, message});
    }
  };

  /**
   * handleButtonExportOk
   */
  handleButtonExportOk = () => {
    this.setState({flagExport: false, confirmedExport: true}, () => {
      this.exportCSVRef.current.onClickExport();
    });
  };

  /**
   * handleButtonExportCancel
   */
  handleButtonExportCancel = () => {
    this.setState({flagExport: false});
  };

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {facility, t, common} = this.props;
    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.setting_fee_taxi')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          <LoadingOverlay active={facility.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('facility.searchTitle')}
                    </Grid>
                  </Grid>
                  {/* Vehicle type */}
                  <Grid container spacing={1} className="table_background_color_aliceblue row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessVehicle.type')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_250">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'vehicle_type',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.vehicle_type
                              ? undefined
                              : () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('businessVehicle.type'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.vehicle_type}
                          onChange={(event) => onChangeSelect(this, event)}
                        >
                          {VEHICLE_TYPE.map((item, idx) => {
                            return (
                              <MenuItem value={item.code} key={idx}>
                                {t(`${item.display}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* geofence */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.geofence')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <Autocomplete
                        margin="dense"
                        className="field_size_20 field_min_size_250"
                        options={common?.geofence_list}
                        value={this.state.geofence_id}
                        getOptionLabel={(option) => option.name || ''}
                        onChange={(event, geofence_id) => this.setState({geofence_id: geofence_id})}
                        renderInput={(params) => (
                          <TextField {...params} name={'geofence_id'} fullWidth variant="outlined" margin="dense" placeholder={t('placeholder.required', {field: t('common.geofence')})} />
                        )}
                        renderOption={(option, state) => (
                          <option {...option} className={!option.enable && 'disable-option-custom'}>{option.name}</option>
                        )}
                      />
                    </Grid>
                  </Grid>

                  {/* business name */}
                  <Grid container spacing={1} className="table_background_color_aliceblue row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('facility.business.name')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <Autocomplete
                        margin="dense"
                        className="field_size_20 field_min_size_250"
                        options={common?.supplier_list}
                        value={this.state.supplierName}
                        getOptionLabel={(option) => option.supplier_name || ''}
                        onChange={(event, business) => this.changeBusiness(business)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name={'businessName'}
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('facility.business.name')})}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  {/* facility name */}
                  <Grid container spacing={1} className="table_background_color_aliceblue row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('facility.name')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <Autocomplete
                        margin="dense"
                        className="field_size_20 field_min_size_250"
                        options={common?.facility_list_all}
                        value={this.state.facilityName}
                        getOptionLabel={(option) => option.facility_name || ''}
                        onChange={(event, facility) => this.setState({facilityName: facility})}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name={'facilityName'}
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            placeholder={t('placeholder.required', {field: t('facility.name')})}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Paper>
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.reset()} endIcon={<RotateLeftIcon />}>
                      {t('common.btnResetSearch')}
                    </Button>
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.SETTING_FEE_TAXI_CREATE}}>
                      <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />}>
                        {t('common.btnRegister')}
                      </Button>
                    </Link>
                    {/* <div className={this.state.totalRows <= EXPORT_ALERT_NUMBER_ROWS || this.state.confirmedExport ? '' : 'hidden'}>
                      <CSVExporter
                        ref={this.exportCSVRef}
                        disabled={this.state.totalRows <= 0 || !this.state.latestSearchParams}
                        headers={this.exportHeaders}
                        fetchAction={this.fetchDataExport}
                        isFetchAsync={true}
                        screenName={t('facility.title')}
                      />
                    </div>
                    {this.state.totalRows >= EXPORT_ALERT_NUMBER_ROWS && !this.state.confirmedExport && (
                      <Button onClick={this.alertExportCSV} disabled={this.state.totalRows <= 0} color="primary" variant="contained" className="button_margin" endIcon={<GetAppIcon className="csv-exporter-icon" />}>
                        {t('calendar.csv_output')}
                      </Button>
                    )} */}
                    <Button
                      color="primary"
                      variant="contained"
                      className="button_margin"
                      onClick={() => {
                        this.handleSearch(true);
                      }}
                      endIcon={<SearchIcon />}
                    >
                      {t('common.btnSearch')}
                    </Button>
                  </Grid>
                </Grid>

                <br></br>
              </Container>
            </Card>
          </LoadingOverlay>
          <br></br>
          {this.state.isSearch && (
            <Card raised>
              <Container maxWidth="xl">
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={6}>
                    <h3>
                      {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                    </h3>
                  </Grid>
                </Grid>
                <LoadingOverlay active={facility.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                  {this.state.result?.length > 0 && (
                    <div className="scroll_area_700">
                      <Table size="small" aria-label="sticky table" stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('facility.id')}</TableCell>
                            <TableCell>{t('facility.status')}</TableCell>
                            <TableCell>{t('facility.type')}</TableCell>
                            <TableCell>{t('facility.business.name')}</TableCell>
                            <TableCell>{t('facility.name')}</TableCell>
                            <TableCell colSpan={2}>{t('common.action')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.result?.map((row, index) => (
                            <TableRow key={index} hover className="cursor_pointer">
                              <TableCell scope="row">{row.facility_id}</TableCell>
                              <TableCell>{BUSINESS_STATUS.map(({id, i18n}) => (`${row.facility_status}` === id ? t(`${i18n}`) : ''))}</TableCell>
                              <TableCell>{BUSINESS_TYPE.map(({id, i18n}) => (`${row?.facility_type}` === id ? t(`${i18n}`) : ''))}</TableCell>
                              <TableCell>{row.supplier_name}</TableCell>
                              <TableCell>{row.facility_name}</TableCell>
                              <TableCell colSpan={2}>
                                <Link
                                  style={{textDecoration: 'none'}}
                                  to={{pathname: ROUTE.LAYOUT + ROUTE.SETTING_FEE_TAXI_DETAILS + '/' + row.id, state: {from: this.props.location.pathname + '?' + this.state.searchParams}}}
                                >
                                  <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                    {t('common.btnEdit')}
                                  </Button>
                                </Link>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  className="button_margin button_color_red"
                                  endIcon={<DeleteIcon />}
                                  onClick={() => this.handleDelete(row.id, index)}
                                >
                                  {t('common.btnDelete')}
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </div>
                  )}
                  {this.state.result?.length > 0 && (
                    <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                  )}
                </LoadingOverlay>
                <br></br>

                <Dialog
                  open={this.state.flgDelete}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal onClickOk={this.handleButtonOk} onClickCancel={this.handleButtonCancel} message={this.state.message}></SelectModal>
                </Dialog>
                {/* Export alert modal */}
                <Dialog
                  open={this.state.flagExport}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    onClickOk={this.handleButtonExportOk}
                    onClickCancel={this.handleButtonExportCancel}
                    okButtonText={t('common.btnYes')}
                    cancelButtonText={t('common.btnNo')}
                    message={this.state.message}
                  ></SelectModal>
                </Dialog>
              </Container>
              <br></br>
            </Card>
          )}
          <br></br>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    facility: state.facility,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllFacilityList: () => dispatch(getAllFacilityList()),
    getListGeofence: () => dispatch(getListGeofence()),
    getListSupplier: () => dispatch(getListSupplier()),
    searchListFacility: (params, queryParams) => dispatch(searchListFacility(params, queryParams)),
    exportFacility: (payload, queryParams) => dispatch(exportFacility(payload, queryParams)),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    deleteFacility: (params, props) => dispatch(deleteFacility(params, props)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
