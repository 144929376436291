import {api} from './api';

const CrudRightMenuSettingUrl = `admin/recommend-shuttles`;
const getDetailMenuAppByGeofenceUrl = 'admin/slides/admin-search';
const updateMenuAppByGeofenceUrl = 'admin/slides';
const popupRecommendCreateUrl = 'admin/recommends';
const popupRecommendSearchUrl = 'admin/recommends/search-admin';
const bankUrl = 'admin/bank-accounts';
const newsManagementUrl = 'admin/news';
const newsTemplateUrl = '/admin/news-template';
const searchMenuAppGeofenceRegisteredUrl = 'admin/slides/search-registered-geofence';

// Menu App Management API
export const getDetailMenuAppByGeofenceApi = (payload) => {
  return api.get(getDetailMenuAppByGeofenceUrl, payload);
};

export const updateMenuAppByGeofenceApi = (payload) => {
  return api.put(updateMenuAppByGeofenceUrl, payload);
};

export const searchMenuAppGeofenceRegisteredApi = (payload) => {
  return api.get(searchMenuAppGeofenceRegisteredUrl, payload);
};

// Right Menu Setting API
export const createRightMenuSettingApi = (payload) => {
  return api.post(CrudRightMenuSettingUrl, payload);
};

export const updateRightMenuSettingApi = (payload) => {
  return api.put(CrudRightMenuSettingUrl, payload);
};

export const getRightMenuSettingApi = (id) => {
  return api.get(CrudRightMenuSettingUrl + '/' + id);
};

export const searchListRightMenuSettingApi = (payload) => {
  return api.get(CrudRightMenuSettingUrl + '/search', payload);
};

export const deleteShuttleApi = (id) => {
  return api.delete(CrudRightMenuSettingUrl + '/' + id);
};

// Popup Recommend API
export const popupRecommendSearchApi = (params) => {
  return api.get(popupRecommendSearchUrl, params);
};

export const popupRecommendDetailsApi = (id) => {
  return api.get(popupRecommendCreateUrl + '/' + id);
};

export const popupRecommendUpdateApi = (payload) => {
  return api.put(popupRecommendCreateUrl, payload);
};

export const popupRecommendCreateApi = (payload) => {
  return api.post(popupRecommendCreateUrl, payload);
};

export const popupRecommendDeleteApi = (id) => {
  return api.delete(popupRecommendCreateUrl + '/' + id);
};

// Bank Management API
export const getBanksApi = () => {
  return api.get(bankUrl);
};

export const getBankDetailApi = (id) => {
  return api.get(`${bankUrl}/${id}`);
};

export const createBankApi = (payload) => {
  return api.post(bankUrl, payload);
};

export const updateBankApi = (payload) => {
  return api.put(bankUrl, payload);
};

// News management API
export const searchNewsApi = (payload, queryParams) => {
  return api.search(`${newsManagementUrl}/admin/search`, queryParams, payload);
};

export const createNewsApi = (payload) => {
  return api.post(newsManagementUrl, payload);
};

export const getDetailNewsApi = (newsId) => {
  return api.get(newsManagementUrl, {newsId: newsId});
};

export const deleteNewsApi = (newsId) => {
  return api.delete(`${newsManagementUrl}/${newsId}`);
};

export const getNewsTemplatesApi = () => {
  return api.get(newsTemplateUrl);
};

export const getNewsTemplateByIdApi = (newsTemplateId) => {
  return api.get(newsTemplateUrl + `/${newsTemplateId}`);
};

export const upsertNewsTemplateApi = (payload) => {
  return api.post(newsTemplateUrl, payload);
};

export const deleteNewsTemplateApi = (newsTemplateId) => {
  return api.delete(newsTemplateUrl + `/${newsTemplateId}`);
};
