import React, {Component} from 'react';

import {Container, Grid, Button, Card, Paper, TextField, TableBody, TableCell, TableRow, TableFooter, TableHead, Table, Dialog} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DetailIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {
  ROUTE,
  CONGESTION_STATUS,
  ROWS_PER_PAGE_OPTIONS,
  MAX_SIZE_OUTPUT,
  EXPORT_LIMIT_NUMBER_ROWS,
  EXPORT_ALERT_NUMBER_ROWS,
  PERMISSION_ACTIONS,
} from '../../../../common/constant';
import CSVExporter from '../../../../components/CSVExporter';
import CustomPagination from '../../../../components/CustomPagination';
import SelectModal from '../../../../components/selectModal';
import withPermissionGateway from '../../../../hoc/withPermissionGateway';
import {getListSupplier, getAllFacilityList} from '../../../../stores/common/actions';
import {searchCongestion, exportCongestion} from '../../../../stores/congestion_status/action';
import {setMessageModal} from '../../../../stores/modal/actions';
import {onChangeTextField, changeUrlParams, getUrlParams, getQueryStringFromObject} from '../../../../utils/common';
import {modalObj} from '../../../../utils/modal';

/**
 * Congestion status master
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      supplierId: '',
      facilityId: '',
      supplierName: null,
      facilityName: null,
      result: [],
      isSearch: false,
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,
      flagExport: false,
      confirmedExport: false,
      latestSearchParams: null,
    };

    this.exportCSVRef = React.createRef();
    const {t} = this.props;
    this.exportHeaders = [
      {label: t('businessInformation.id'), key: 'supplier_id'},
      {label: t('businessInformation.name'), key: 'supplier_name'},
      {label: t('facility.id'), key: 'facility_id'},
      {label: t('facility.name'), key: 'facility_name'},
      {label: t('congestion.status'), key: 'status'},
    ];
  }

  /**
   * reset
   */
  reset = () => {
    this.setState({
      supplierId: '',
      facilityId: '',
      supplierName: null,
      facilityName: null,
    });
  };

  /**
   * handleSearch
   * @param {bool} reset
   */
  handleSearch = (reset) => {
    const canSearch = this.props.actions.includes(PERMISSION_ACTIONS.SEARCH);
    if (!canSearch) return;
    const {supplierId, facilityId, supplierName, facilityName} = this.state;
    const payload = {
      supplier_name: supplierName ? supplierName.supplier_name.trim() : '',
      facility_id: facilityId ? facilityId.trim() : '',
      supplier_id: supplierId ? supplierId.trim() : '',
      facility_name: facilityName ? facilityName.facility_name.trim() : '',
    };
    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const {currentPage, rowsPerPage} = this.state;
    const queryParams = reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage};

    this.props.searchCongestion(payload, queryParams, this.props).then((response) => {
      if (!response) {
        response = [];
      }
      this.setState(
          {
            result: response?.content,
            totalRows: response?.totalSize,
            isSearch: true,
            latestSearchParams: payload,
          },
          () => {
            const {supplierId, facilityId, supplierName, facilityName, currentPage, rowsPerPage} = this.state;
            // Apply changed params into url
            const queryParamsToChange = {
              supplierId,
              facilityId,
              supplierName: supplierName && JSON.stringify(supplierName),
              facilityName: facilityName && JSON.stringify(facilityName),
              currentPage,
              rowsPerPage,
            };
            changeUrlParams(queryParamsToChange);

            const newSearchParams = getQueryStringFromObject(queryParamsToChange);
            // Save search params into state in order to pass to next page
            this.setState({searchParams: newSearchParams});
          },
      );
    });
  };
  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearch);
  };
  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getListSupplier();
    await this.props.getAllFacilityList();
    if (this.props.location.search) {
      this.setState(getUrlParams(this.props.location.search));
    }
  }

  /**
   * componentDidUpdate
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.props.actions !== prevProps.actions) {
      const canSearch = this.props.actions.includes(PERMISSION_ACTIONS.SEARCH);
      if (canSearch && this.props.location.search) {
        this.setState(getUrlParams(this.props.location.search), this.handleSearch);
      }
    }
  }

  /**
   * changeBusiness
   * @param {string} value
   */
  changeBusiness = (value) => {
    this.setState({supplierName: value});
  };

  /**
   * changeFacility
   * @param {string} value
   */
  changeFacility = (value) => {
    this.setState({facilityName: value});
  };
  /**
   * Fetch data to export
   */
  fetchDataExport = async () => {
    const {t} = this.props;
    const queryParams = {page: 0, size: MAX_SIZE_OUTPUT};
    const dataExport = await this.props.exportCongestion(this.state.latestSearchParams, queryParams);
    const convertedData = dataExport.map((row, index) => {
      row.status = CONGESTION_STATUS.filter(({id, i18n}) => `${row.status}` === id).map(({id, i18n}) => t(i18n));
      return row;
    });
    this.setState({confirmedExport: false});
    return convertedData;
  };

  /**
   * alertExportCSV
   */
  alertExportCSV = () => {
    const {t} = this.props;
    if (this.state.totalRows > EXPORT_LIMIT_NUMBER_ROWS) {
      this.props.setMessageModal(modalObj(true, t('messageCode.exportAlertLimit')));
    } else {
      const message = t('messageCode.exportAlert');
      this.setState({flagExport: true, message});
    }
  };

  /**
   * handleButtonExportOk
   */
  handleButtonExportOk = () => {
    this.setState({flagExport: false, confirmedExport: true}, () => {
      this.exportCSVRef.current.onClickExport();
    });
  };

  /**
   * handleButtonExportCancel
   */
  handleButtonExportCancel = () => {
    this.setState({flagExport: false});
  };
  /**
   * render component
   * @return {component}
   */
  render() {
    const {t, congestion, common, actions} = this.props;
    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canCSVOutput: actions.includes(PERMISSION_ACTIONS.CSV_OUTPUT),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.congestionStatus')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          <LoadingOverlay bgColor="#f1f1f1" active={congestion.isLoading} spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                {permission.canSearch && (
                  <>
                    <br></br>
                    <Paper>
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                          {t('businessVehicle.searchTitle')}
                        </Grid>
                      </Grid>

                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('businessInformation.name')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <Autocomplete
                            margin="dense"
                            className="field_size_10 field_min_size_300"
                            options={common?.supplier_list}
                            value={this.state.supplierName}
                            getOptionLabel={(option) => option.supplier_name}
                            onChange={(event, business) => this.changeBusiness(business)}
                            renderInput={(params) => (
                              <TextField {...params} name={'businessName'} fullWidth variant="outlined" margin="dense" placeholder={t('businessInformation.name')} />
                            )}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('businessInformation.id')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            className="field_size_10 field_min_size_150"
                            margin="dense"
                            placeholder={t('businessInformation.id')}
                            variant="outlined"
                            name="supplierId"
                            value={this.state.supplierId}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('facility.id')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <TextField
                            className="field_size_10 field_min_size_150"
                            margin="dense"
                            placeholder={t('facility.id')}
                            variant="outlined"
                            name="facilityId"
                            value={this.state.facilityId}
                            onChange={(event) => onChangeTextField(this, event)}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('businessVehicle.sales_office_store_name')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <Autocomplete
                            margin="dense"
                            className="field_size_10 field_min_size_300"
                            options={common?.facility_list_all}
                            value={this.state.facilityName}
                            getOptionLabel={(option) => option.facility_name}
                            onChange={(event, facility) => this.changeFacility(facility)}
                            renderInput={(params) => (
                              <TextField {...params} name={'facilityName'} fullWidth variant="outlined" margin="dense" placeholder={t('businessVehicle.sales_office_store_name')} />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </>
                )}
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.reset()} endIcon={<RotateLeftIcon />}>
                        {t('common.btnResetSearch')}
                      </Button>
                    )}
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {permission.canCSVOutput && permission.canSearch && (
                      <>
                        <div className={this.state.totalRows <= EXPORT_ALERT_NUMBER_ROWS || this.state.confirmedExport ? '' : 'hidden'}>
                          <CSVExporter
                            ref={this.exportCSVRef}
                            disabled={this.state.totalRows <= 0 || !this.state.latestSearchParams}
                            headers={this.exportHeaders}
                            fetchAction={this.fetchDataExport}
                            isFetchAsync={true}
                            screenName={t('route.congestionStatus')}
                          />
                        </div>
                        {this.state.totalRows >= EXPORT_ALERT_NUMBER_ROWS && !this.state.confirmedExport && (
                          <Button
                            onClick={this.alertExportCSV}
                            disabled={this.state.totalRows <= 0}
                            color="primary"
                            variant="contained"
                            className="button_margin"
                            endIcon={<GetAppIcon className="csv-exporter-icon" />}
                          >
                            {t('calendar.csv_output')}
                          </Button>
                        )}
                      </>
                    )}
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin" endIcon={<SearchIcon />} onClick={() => this.handleSearch(true)}>
                        {t('common.btnSearch')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <br></br>
              </Container>
            </Card>
          </LoadingOverlay>
          <br></br>
          <br></br>
          {this.state.isSearch ? (
            <Card raised>
              <Container maxWidth="xl">
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={6}>
                    <h3>
                      {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                    </h3>
                  </Grid>
                  <LoadingOverlay bgColor="#f1f1f1" active={congestion.isLoading} spinnerColor="#9ee5f8" textColor="#676767" spinner>
                    {this.state.result.length > 0 ? (
                      <div className="scroll_area_700">
                        <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                          <TableHead>
                            <TableRow>
                              <TableCell className="width_100p">{t('businessInformation.id')}</TableCell>
                              <TableCell>{t('businessInformation.name')}</TableCell>
                              <TableCell className="width_200p">{t('facility.id')}</TableCell>
                              <TableCell>{t('facility.name')}</TableCell>
                              <TableCell className="width_300p">{t('congestion.status')}</TableCell>
                              {permission.canEdit && (
                                <TableCell>{t('common.action')}</TableCell>
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.result.map((row, index) => (
                              <TableRow key={index} hover className="cursor_pointer">
                                <TableCell scope="row">{row.supplier_id}</TableCell>
                                <TableCell>{row.supplier_name}</TableCell>
                                <TableCell>{row.facility_id}</TableCell>
                                <TableCell>{row.facility_name}</TableCell>
                                <TableCell>{CONGESTION_STATUS.map(({id, i18n}) => (`${row.status}` === id ? t(`${i18n}`) : ''))}</TableCell>
                                {permission.canEdit && (
                                  <TableCell>
                                    <Link
                                      style={{textDecoration: 'none'}}
                                      to={{
                                        pathname: ROUTE.LAYOUT + ROUTE.PRODUCT_CONGESTION_STATUS_DETAIL + '/' + row.id,
                                        state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                      }}
                                    >
                                      <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                        {t('common.btnEdit')}
                                      </Button>
                                    </Link>
                                  </TableCell>
                                )}
                              </TableRow>
                            ))}
                          </TableBody>
                          <TableFooter></TableFooter>
                        </Table>
                      </div>
                    ) : (
                      false
                    )}
                    <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                  </LoadingOverlay>
                </Grid>
                <br></br>
                {/* Export alert modal */}
                <Dialog
                  open={this.state.flagExport}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    onClickOk={this.handleButtonExportOk}
                    onClickCancel={this.handleButtonExportCancel}
                    okButtonText={t('common.btnYes')}
                    cancelButtonText={t('common.btnNo')}
                    message={this.state.message}
                  ></SelectModal>
                </Dialog>
              </Container>
              <br></br>
            </Card>
          ) : (
            false
          )}
          <br></br>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    congestion: state.congestion,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchCongestion: (payload, queryParams, props) => dispatch(searchCongestion(payload, queryParams, props)),
    exportCongestion: (payload, queryParams, props) => dispatch(exportCongestion(payload, queryParams)),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    getListSupplier: () => dispatch(getListSupplier()),
    getAllFacilityList: () => dispatch(getAllFacilityList()),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
