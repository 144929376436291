import React, {Component} from 'react';

import {
  Card,
  Container,
  Grid,
  FormControl,
  Select,
  MenuItem,
  FormControlLabel,
  TextField,
  Button,
  Radio,
  RadioGroup,
  Table,
  TableCell,
  TableRow,
  Paper,
  TableBody,
  TableHead,
  TableContainer,
  FormHelperText,
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {ROUTE, PERIOD_TYPE_FROM, PERIOD_TYPE_TO, CANCELLATION_FEE_RATE_TYPE} from '../../../common/constant';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {createCancellationFee, getCancellationFeeDetail, updateCancellationFee} from '../../../stores/cancellation_fee/action';
import {getAllFacilityList} from '../../../stores/common/actions';
import {onChangeTextField, onChangeSelect, redirectRouter} from '../../../utils/common';

import './style.css';


/**
 * Cancellation Fee Form component
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      facility_id: '',
      facility_name: '',
      fee_id: '',
      fee_name: '',
      facility: '',

      fee_from: '',
      fee_to: '',
      periodType_from: '',
      periodType_to: '',
      rateType: '%',
      cancelNumber: '',

      fee: [],

      disable_fee_from: true,
      disable_fee_to: true,
      isSubmitAdd: false,
      isSubmitForm: false,
    };

    this.validator = new SimpleReactValidator();
  }

  /**
   * Get list facility
   */
  async componentDidMount() {
    await this.props.getAllFacilityList();

    if (this.props.match.params.id) {
      await this.props.getCancellationFeeDetail(this.props.match.params.id).then((res) => {
        if (res) {
          this.setState({
            facility: {
              facility_id: res.facility_id,
              facility_name: res.facility_name,
            },
            fee_id: res.fee_id,
            fee_name: res.fee_name,
            fee: res.fee,
            facility_name: this.props.common.facility_list_all?.find((e) => e.facility_id === res.facility_id),
          });
        }
      });
    }
  }

  /**
   * changeFacility
   * @param {String} facility
   */
  changeFacilityName = (facility) => {
    this.setState({
      facility: facility,
      facility_name: facility,
    });
  };

  /**
   * Handle change period type
   * @param {object} event
   * @param {String} type
   */
  onChangePeriodType(event, type) {
    onChangeSelect(this, event);
    const disable_type = type === 'FROM' ? 'disable_fee_from' : 'disable_fee_to';
    const fee = type === 'FROM' ? 'fee_from' : 'fee_to';
    if (event.target.value === PERIOD_TYPE_FROM[1].id || event.target.value === PERIOD_TYPE_FROM[2].id) {
      this.setState({
        [disable_type]: false,
      });
    } else {
      this.setState({
        [disable_type]: true,
        [fee]: '',
      });
    }
  }

  /**
   * validate
   * @return {*}
   */
  validate() {
    const regex = /^[0-9]$|^[1-9][0-9]$|^(100)$/;
    if (this.state.disable_fee_from && this.state.disable_fee_to) {
      return true;
    } else {
      if (!this.state.disable_fee_from) {
        if (this.state.fee_from != null && this.state.fee_from !== '' && regex.test(this.state.fee_from)) {
          return true;
        } else {
          return false;
        }
      }

      if (!this.state.disable_fee_to) {
        if (this.state.fee_to != null && this.state.fee_to !== '' && regex.test(this.state.fee_to)) {
          return true;
        } else {
          return false;
        }
      }
    }
  }

  /**
   * Handle add cancellation Fee
   */
  handleAdd = () => {
    this.setState({
      isSubmitAdd: true,
    });

    if (this.validate() && this.validator.allValid()) {
      const payload = {
        id: this.state.fee[this.state.fee?.length - 1]?.id + 1,
        fee_from: this.state.fee_from,
        periodType_from: this.state.periodType_from,
        fee_to: this.state.fee_to,
        periodType_to: this.state.periodType_to,
        rateType: this.state.rateType,
        cancelNumber: this.state.cancelNumber,
      };

      this.state.fee.push(payload);

      this.setState({
        isSubmitAdd: false,
        fee_from: '',
        periodType_from: '',
        fee_to: '',
        periodType_to: '',
        rateType: '%',
        cancelNumber: '',
      });
    }
  };

  /**
   * Handle update cancellation fee
   */
  handleUpdate = () => {
    this.setState({
      isSubmitForm: true,
      isSubmitAdd: false,
    });

    if (this.validator.allValid() && this.state.fee?.length > 0) {
      const payload = {
        facility_id: this.state.facility.facility_id,
        facility_name: this.state.facility.facility_namw,
        fee_id: this.state.fee_id,
        fee_name: this.state.fee_name,
        fee: this.state.fee,
      };

      this.props.updateCancellationFee(payload);
    }
  };

  /**
   * Handle delete cancellation fee
   * @param {number} id
   */
  handleDelete(id) {
    const fee = this.state.fee;
    const index = fee.map((item) => item.id).indexOf(id);
    fee.splice(index, 1);
    this.setState({
      fee: fee,
    });
  }

  /**
   * Handle Create
   */
  handleCreate = () => {
    this.setState({
      isSubmitForm: true,
      isSubmitAdd: false,
    });

    if (this.validator.allValid() && this.state.fee?.length > 0) {
      const payload = {
        facility_id: this.state.facility.facility_id,
        facility_name: this.state.facility.facility_namw,
        fee_id: this.state.fee_id,
        fee_name: this.state.fee_name,
        fee: this.state.fee,
      };

      this.props.createCancellationFee(payload);
    }
  };

  /**
   * render HTML
   * @return {component}
   */
  render() {
    const {t, common} = this.props;
    const index = CANCELLATION_FEE_RATE_TYPE.findIndex((e) => e.id === this.state.rateType);
    const rateToDisplay = CANCELLATION_FEE_RATE_TYPE[index].i18n;
    return (
      <LoadingOverlay active={false} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                {this.props.match.params.id ? <h3>{t('route.cancellation_fee_update')}</h3> : <h3>{t('route.cancellation_fee_add')}</h3>}
              </Grid>
            </Grid>
          </Container>
          <br></br>
          <Container maxWidth="xl">
            <Card raised>
              <br></br>
              <Container maxWidth="xl">
                <Paper>
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('cancellation_fee.basic_info')}
                    </Grid>
                  </Grid>
                  {/* facility id*/}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('cancellation_fee.facility.id')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={6}>
                      <FormControl error>
                        <TextField
                          className="field_size_10 field_min_size_150"
                          margin="dense"
                          variant="outlined"
                          name="facility_id"
                          disabled={true}
                          onChange={(event) => onChangeTextField(this, event)}
                          value={this.state.facility ? this.state.facility.facility_id : ''}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* facility name*/}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('cancellation_fee.facility.name')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={6}>
                      <FormControl error>
                        <Autocomplete
                          margin="dense"
                          className="field_size_10 field_min_size_300"
                          options={common?.facility_list_all}
                          value={this.state.facility_name}
                          getOptionLabel={(option) => option.facility_name}
                          onChange={(event, facility) => this.changeFacilityName(facility)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name={'facility_name'}
                              fullWidth
                              variant="outlined"
                              margin="dense"
                              placeholder={t('placeholder.required', {
                                field: t('cancellation_fee.facility.name'),
                              })}
                            />
                          )}
                        />
                        {this.state.isSubmitForm && this.validator.message('facility', this.state.facility, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.facility, 'required') && (
                          <FormHelperText id="facility" error>
                            {t('validation.required.choose', {field: t('cancellation_fee.facility.name')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* id */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('cancellation_fee.id')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <TextField
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          name="fee_id"
                          placeholder={t('placeholder.required', {
                            field: t('cancellation_fee.id'),
                          })}
                          variant="outlined"
                          onChange={(event) => onChangeTextField(this, event)}
                          value={this.state.fee_id}
                        />
                        {this.state.isSubmitForm && this.validator.message('fee_id', this.state.facility, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.fee_id, 'required') && (
                          <FormHelperText id="fee_id" error>
                            {t('validation.required', {field: t('cancellation_fee.id')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  {/* name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('cancellation_fee.name')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <TextField
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          name="fee_name"
                          placeholder={t('placeholder.required', {
                            field: t('cancellation_fee.name'),
                          })}
                          variant="outlined"
                          onChange={(event) => onChangeTextField(this, event)}
                          value={this.state.fee_name}
                        />
                        {this.state.isSubmitForm && this.validator.message('fee_name', this.state.fee_name, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.fee_name, 'required') && (
                          <FormHelperText id="fee_name" error>
                            {t('validation.required', {field: t('cancellation_fee.name')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Paper>
              </Container>
              <br></br>
            </Card>
          </Container>
          <br></br>
          <Container maxWidth="xl">
            <Card raised>
              <br></br>
              <Container maxWidth="xl">
                <Grid container className="row_form_item">
                  <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                    {t('cancellation_fee.detailTitle')}
                  </Grid>
                </Grid>
              </Container>
              <Container maxWidth="lg">
                <br></br>
                <Paper>
                  <Grid container className="table_bottom">
                    <Grid container alignItems="center" justify="center" item xs={7} lg={7} className="grid_padding6_border_fontbold">
                      {t('cancellation_fee.period')}
                    </Grid>
                    <Grid container alignItems="center" justify="center" item xs={2} lg={2} className="grid_padding6_border_fontbold">
                      {t('cancellation_fee.rate')}
                    </Grid>
                    <Grid container alignItems="center" justify="center" item xs={2} lg={2} className="grid_padding6_border_fontbold">
                      {t('cancellation_fee.number')}
                    </Grid>
                    <Grid container alignItems="center" justify="center" item xs={1} lg={1} className="grid_padding6_border_fontbold">
                      {t('cancellation_fee.add')}
                    </Grid>
                  </Grid>
                  <Grid container className="table_bottom">
                    <Grid container alignItems="center" justify="center" item xs={7} lg={7} className="grid_padding6_border">
                      <Grid container alignItems="center" justify="center" direction="column" item xs={6} lg={6} className="grid_padding6">
                        <Grid container alignItems="center" className="grid_padding6">
                          {t('cancellation_fee.from')} <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid container alignItems="center" className="grid_padding6">
                          {t('cancellation_fee.to')} <span className="font_color_red">＊</span>
                        </Grid>
                      </Grid>
                      <Grid container alignItems="center" justify="center" item xs={2} lg={2} className="grid_padding6">
                        {/* Cancellation period from (number) */}
                        <Grid container alignItems="center">
                          <FormControl>
                            <TextField
                              className="field_size_10 field_min_size_50"
                              margin="dense"
                              variant="outlined"
                              name="fee_from"
                              onChange={(event) => onChangeTextField(this, event)}
                              value={this.state.fee_from}
                              disabled={this.state.disable_fee_from}
                              required={!this.state.disable_fee_from}
                            />
                          </FormControl>
                        </Grid>
                        {/* Cancellation period to (number) */}
                        <Grid container alignItems="center">
                          <FormControl>
                            <TextField
                              className="field_size_10 field_min_size_50"
                              margin="dense"
                              variant="outlined"
                              name="fee_to"
                              onChange={(event) => onChangeTextField(this, event)}
                              value={this.state.fee_to}
                              disabled={this.state.disable_fee_to}
                              required={!this.state.disable_fee_to}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container alignItems="center" justify="center" item xs={7} lg={4} className="grid_padding6">
                        {/* Cancellation period from (type) */}
                        <FormControl>
                          <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_200">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'periodType_from',
                              }}
                              value={this.state.periodType_from}
                              onChange={(event) => this.onChangePeriodType(event, 'FROM')}
                            >
                              {PERIOD_TYPE_FROM.map((item, idx) => {
                                return (
                                  <MenuItem value={item.id} key={idx}>
                                    {t(`${item.i18n}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </FormControl>
                        {/* Cancellation period to (type) */}
                        <FormControl>
                          <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_200">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'periodType_to',
                              }}
                              value={this.state.periodType_to}
                              onChange={(event) => this.onChangePeriodType(event, 'TO')}
                            >
                              {PERIOD_TYPE_TO.map(({id, i18n}, idx) => {
                                return (
                                  <MenuItem value={id} key={idx}>
                                    {t(`${i18n}`)}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </FormControl>
                      </Grid>
                    </Grid>
                    {/* Cancellation rate (type) */}
                    <Grid container alignItems="center" justify="center" direction="row" item xs={2} lg={2} className="grid_padding6_border">
                      <FormControl>
                        <RadioGroup row aria-label="rateType" name="rateType">
                          {CANCELLATION_FEE_RATE_TYPE.map((item, idx) => {
                            return (
                              <FormControlLabel
                                value={item.id}
                                key={idx}
                                onChange={(event) => onChangeSelect(this, event)}
                                control={<Radio className="checkbox_radio" checked={this.state.rateType === item.id} />}
                                label={<span className="font_size_small_regular">{t(`${item.i18n}`)}</span>}
                              />
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    {/* Cancellation rate (number) */}
                    <Grid container alignItems="center" justify="center" item xs={2} lg={2} className="grid_padding6_border">
                      <FormControl className="form_control_item">
                        <TextField
                          className="field_size_10 field_min_size_100"
                          margin="dense"
                          variant="outlined"
                          name="cancelNumber"
                          onChange={(event) => onChangeTextField(this, event)}
                          value={this.state.cancelNumber}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{this.state.rateType ? t(`${rateToDisplay}`) : '%'}</InputAdornment>,
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid container alignItems="center" justify="center" item xs={1} lg={1} className="grid_padding6_border">
                      <Button variant="contained" color="primary" component="span" onClick={this.handleAdd}>
                        {t('cancellation_fee.add')}
                      </Button>
                    </Grid>
                  </Grid>

                  {/* Display error */}
                  {
                    <ul>
                      <li>
                        {this.state.isSubmitAdd && !this.state.disable_fee_from && !this.validator.check(this.state.fee_from, 'required') && (
                          <FormHelperText id="fee_from" error>
                            <WarningIcon fontSize="small" className="margin_right" /> {t('validation.required', {field: t('cancellation_fee.from')})}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitAdd && !this.state.disable_fee_from && !this.validator.check(this.state.fee_from, 'numeric') && (
                          <FormHelperText id="fee_from" error>
                            <WarningIcon fontSize="small" className="margin_right" /> {t('validation.number')}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitAdd &&
                          !this.state.disable_fee_from &&
                          this.validator.check(this.state.fee_from, 'required') &&
                          this.validator.check(this.state.fee_from, 'numeric') &&
                          !this.validator.check(this.state.fee_from, 'max:2,string') && (
                          <FormHelperText id="fee_from" error>
                            <WarningIcon fontSize="small" className="margin_right" /> {t('validation.maxString', {value: 2})}
                          </FormHelperText>
                        )}
                      </li>
                      <li>
                        {this.state.isSubmitAdd && !this.state.disable_fee_to && !this.validator.check(this.state.fee_to, 'required') && (
                          <FormHelperText id="fee_to" error>
                            <WarningIcon fontSize="small" className="margin_right" /> {t('validation.required', {field: t('cancellation_fee.to')})}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitAdd && !this.state.disable_fee_to && !this.validator.check(this.state.fee_to, 'numeric') && (
                          <FormHelperText id="fee_to" error>
                            <WarningIcon fontSize="small" className="margin_right" /> {t('validation.number')}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitAdd &&
                          !this.state.disable_fee_to &&
                          this.validator.check(this.state.fee_to, 'required') &&
                          this.validator.check(this.state.fee_to, 'numeric') &&
                          !this.validator.check(this.state.fee_to, 'max:2,string') && (
                          <FormHelperText id="fee_to" error>
                            <WarningIcon fontSize="small" className="margin_right" /> {t('validation.maxString', {value: 2})}
                          </FormHelperText>
                        )}
                      </li>
                      <li>
                        {this.validator.message('periodType_from', this.state.periodType_from, 'required')}
                        {this.state.isSubmitAdd && !this.validator.check(this.state.periodType_from, 'required') && (
                          <FormHelperText id="periodType_from" error>
                            <WarningIcon fontSize="small" className="margin_right" /> {t('validation.required.choose', {field: t('cancellation_fee.from')})}
                          </FormHelperText>
                        )}
                      </li>
                      <li>
                        {this.validator.message('periodType_to', this.state.periodType_to, 'required')}
                        {this.state.isSubmitAdd && !this.validator.check(this.state.periodType_to, 'required') && (
                          <FormHelperText id="periodType_to" error>
                            <WarningIcon fontSize="small" className="margin_right" /> {t('validation.required.choose', {field: t('cancellation_fee.to')})}
                          </FormHelperText>
                        )}
                      </li>
                      <li>
                        {this.validator.message('cancelNumber', this.state.cancelNumber, 'required|numeric')}
                        {this.state.isSubmitAdd && !this.validator.check(this.state.cancelNumber, 'required') && (
                          <FormHelperText id="cancelNumber" error>
                            <WarningIcon fontSize="small" className="margin_right" /> {t('validation.required', {field: t('cancellation_fee.rate')})}
                          </FormHelperText>
                        )}
                        {this.state.isSubmitAdd && this.validator.check(this.state.cancelNumber, 'required') && !this.validator.check(this.state.cancelNumber, 'numeric') && (
                          <FormHelperText id="cancelNumber" error>
                            <WarningIcon fontSize="small" className="margin_right" /> {t('validation.number')}
                          </FormHelperText>
                        )}
                      </li>
                    </ul>
                  }
                  <ul>
                    <li>
                      {this.state.isSubmitForm && !this.state.fee.length > 0 && (
                        <FormHelperText id="feeData" error>
                          <WarningIcon fontSize="small" className="margin_right" />
                          取消料は最低1レコードが必要です。
                        </FormHelperText>
                      )}
                    </li>
                  </ul>
                  <br></br>
                  {this.state.fee.length > 0 ? (
                    <>
                      <br></br>
                      <TableContainer className="scroll_area_500" name="feeData">
                        <Table size="small" aria-label="sticky table" stickyHeader style={{borderCollapse: 'collapse'}}>
                          <TableHead>
                            <TableRow>
                              <TableCell className="width_10 border_05px" align="center" rowSpan={2}>
                                {t('cancellation_fee.period')}
                              </TableCell>
                              <TableCell className="width_5 border_05px" align="center" colSpan={2}>
                                {t('cancellation_fee.rate')}
                              </TableCell>
                              <TableCell className="width_5 border_05px" align="center" rowSpan={2}>
                                {t('cancellation_fee.delete')}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="width_5 border_05px" align="center" rowSpan={2}>
                                {t('cancellation_fee.number')}
                              </TableCell>
                              <TableCell className="width_5 border_05px" align="center" rowSpan={2}>
                                {t('cancellation_fee.rateType.yen')}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.fee.map((row, index) => {
                              const index_from = PERIOD_TYPE_FROM.findIndex((e) => e.id === row.periodType_from);
                              const periodType_from_display = PERIOD_TYPE_FROM[index_from]?.i18n;
                              const index_to = PERIOD_TYPE_TO.findIndex((e) => e.id === row.periodType_to);
                              const periodType_to_display = PERIOD_TYPE_TO[index_to]?.i18n;
                              return (
                                <TableRow key={index} hover className="cursor_pointer border_05px" align="center">
                                  <TableCell className="border_05px" align="center">
                                    {row.fee_from} {t(`${periodType_from_display}`)} ~ {row.fee_to} {t(`${periodType_to_display}`)}
                                  </TableCell>
                                  <TableCell className="border_05px" align="center">
                                    {row.rateType === '%' ? row.cancelNumber : ''}
                                  </TableCell>
                                  <TableCell className="border_05px" align="center">
                                    {row.rateType === '%' ? '' : row.cancelNumber}
                                  </TableCell>
                                  <TableCell className="border_05px" align="center">
                                    <Button color="secondary" variant="contained" className="button_margin" endIcon={<DeleteIcon />} onClick={() => this.handleDelete(row.id)}>
                                      {t('common.btnDelete')}
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  ) : (
                    <></>
                  )}
                </Paper>
              </Container>
              <br></br>
            </Card>
            <br></br>
            <Grid container spacing={1}>
              <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={9}>
                {this.props.match.params.id ? (
                  <>
                    <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={this.handleUpdate}>
                      {t('common.btnUpdate')}
                    </Button>
                  </>
                ) : (
                  <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={this.handleCreate}>
                    {t('common.btnRegister')}
                  </Button>
                )}
                <Button
                  color="primary"
                  variant="contained"
                  className="button_margin button_color"
                  onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.PRODUCT_CANCELLATION_FEE)}
                >
                  {t('common.btnReturn')}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    cancellation_fee: state.cancellation_fee,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllFacilityList: () => dispatch(getAllFacilityList()),
    createCancellationFee: (payload) => dispatch(createCancellationFee(payload)),
    getCancellationFeeDetail: (id) => dispatch(getCancellationFeeDetail(id)),
    updateCancellationFee: (payload) => dispatch(updateCancellationFee(payload)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
