import React, {Component} from 'react';

import {
  Backdrop,
  Button,
  Card,
  Container,
  Dialog,
  FormControl,
  Grid,
  MenuItem,
  Modal,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import {Home as HomeIcon, Add as AddIcon, ArrowBack as ArrowBackIcon, Delete as DeleteIcon, Description as DetailIcon} from '@material-ui/icons';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';

import {DATA_NULL, ROWS_PER_PAGE_OPTIONS} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getListGeofence} from '../../../stores/common/actions';
import {getListFavoriteWaypoint, deleteFavoriteWaypoint, getMemberDetail} from '../../../stores/member/action';
import {onChangeTextField} from '../../../utils/common';
import Form from '../../../views/member/waypoint_favorite/FormComponent';

/**
 * Member Favorites Waypoint Component
 */
class Search extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      geofence_id: '',
      location_tag: '',
      location_name: '',

      deleteId: '',
      favoriteId: '',
      formType: '',
      openForm: false,
      deleteDialog: false,
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      listGeofenceConfig: [],
      listGeofenceAll: [],
    };
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    const listGeofenceConfig = await this.props.getListGeofence();
    const listGeofenceAll= await this.props.getListGeofence({targetScreen: 'ENABLE_FOR_ADMIN_ALL'});
    this.setState({listGeofenceConfig, listGeofenceAll});
    this.props.getMemberDetail(this.props.match.params.userId);
    this.props.getListFavoriteWaypoint(
      this.props.match.params.userId,
      this.state.geofence_id,
      this.state.location_tag.trim(),
      this.state.location_name.trim(),
      this.state.currentPage,
      this.state.rowsPerPage,
    );
  }

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage});
    this.props.getListFavoriteWaypoint(
      this.props.match.params.userId,
      this.state.geofence_id,
      this.state.location_tag.trim(),
      this.state.location_name.trim(),
      currentPage,
      rowsPerPage,
    );
  };

  /**
   * onChangeGeofence
   * @param {*} event
   */
  onChangeGeofence = (event) => {
    this.setState({geofence_id: event.target.value, currentPage: 0});
    this.props.getListFavoriteWaypoint(
      this.props.match.params.userId,
      event.target.value,
      this.state.location_tag.trim(),
      this.state.location_name.trim(),
      0,
      this.state.rowsPerPage,
    );
  };

  /**
   * onSubmitFormSearch
   * @param {*} event
   */
  onSubmitFormSearch = (event) => {
    if ((event.key && event.key === 'Enter') || event.key === undefined) {
      this.setState({currentPage: 0});
      this.props.getListFavoriteWaypoint(
        this.props.match.params.userId,
        this.state.geofence_id,
        this.state.location_tag.trim(),
        this.state.location_name.trim(),
        0,
        this.state.rowsPerPage,
      );
    }
  };

  /**
   * openFormModal
   * @param {String} type
   * @param {Number} favoriteId
   */
  openFormModal = (type, favoriteId) => {
    this.setState({
      openForm: true,
      formType: type,
      favoriteId: favoriteId,
    });
  };

  /**
   * handleButtonCloseModal
   */
  handleButtonCloseModal = () => {
    this.setState({
      openForm: false,
      formType: '',
    });
  };

  /**
   * delete Dialog open
   * @param {Number} id
   */
  handleDelete = (id) => {
    this.setState({
      deleteDialog: true,
      deleteId: id,
    });
  };

  /**
   * handleDialogButtonOk
   */
  handleDialogButtonOk = () => {
    this.setState(
      {
        deleteDialog: false,
      },
      async () => {
        await this.props.deleteFavoriteWaypoint(this.state.deleteId);
        await this.props.getListFavoriteWaypoint(
          this.props.match.params.userId,
          this.state.geofence_id,
          this.state.location_tag.trim(),
          this.state.location_name.trim(),
          0,
          ROWS_PER_PAGE_OPTIONS[0],
        );
        this.setState({
          currentPage: 0,
          rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
        });
      },
    );
  };

  /**
   * componentDidUpdate
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.waypointList?.totalSize !== this.props.waypointList?.totalSize) {
      this.setState({
        currentPage: 0,
        rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      });
    }
  }

  /**
   * handleDialogButtonCancel
   */
  handleDialogButtonCancel = () => {
    this.setState({deleteDialog: false});
  };

  /**
   * render component
   * @return {HTML}
   */
  render() {
    const {t, common, waypointList, memberDetail, isLoading} = this.props;

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.memberFavoriteWaypoint')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />} onClick={this.props.history.goBack}>
                {t('common.btnReturn')}
              </Button>
            </Grid>
          </Grid>
          <Container maxWidth="xl">
            <Grid container>
              <Grid container spacing={1} className="row_form_item">
                <Grid container alignItems="center" justify="flex-start" item xs={2} className="grid_title_padding">
                  {t('memberManagement.memberId')}
                </Grid>
                <Grid container alignItems="center" justify="flex-start" item xs={4}>
                  {memberDetail?.member_id}
                </Grid>
              </Grid>
              <Grid container spacing={1} className="row_form_item">
                <Grid container alignItems="center" justify="flex-start" item xs={2} className="grid_title_padding">
                  {t('memberManagement.memberName')}
                </Grid>
                <Grid container alignItems="center" justify="flex-start" item xs={4}>
                  {memberDetail?.first_name || memberDetail?.last_name ? (memberDetail?.first_name || '') + ' ' + (memberDetail?.last_name || '') : DATA_NULL}
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid container alignItems="center" justify="flex-end" item xs={3}></Grid>
                <Grid container alignItems="center" justify="flex-end" item xs={9}>
                  <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={() => this.openFormModal('ADD', -1)}>
                    {t('common.btnAdd')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <br></br>
            <Grid container>
              <Grid container spacing={1} className="row_form_item">
                <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                  {t('accountManagement.titleSearch')}
                </Grid>
              </Grid>
              <Grid container spacing={1} className="row_form_item">
                {/* geofence */}
                <Grid container alignItems="center" justify="flex-start" item xs={6} lg={2} className="grid_title_padding">
                  {t('common.geofence')}
                </Grid>
                <Grid container alignItems="center" item xs={6} lg={4}>
                  <FormControl variant="outlined" margin="dense">
                    <Select
                      variant="outlined"
                      className="field_size_20 field_min_size_300"
                      margin="dense"
                      inputProps={{
                        name: 'geofence_id',
                      }}
                      displayEmpty
                      renderValue={
                        this.state.geofence_id ?
                          undefined :
                          () => (
                              <div className="font-12 color-disabled">
                                {t('placeholder.required_select', {
                                  field: t('common.geofence'),
                                })}
                              </div>
                            )
                      }
                      value={this.state.geofence_id}
                      onChange={(event) => this.onChangeGeofence(event)}
                    >
                      <MenuItem value={''}>
                        <em>{t('geofence.none')}</em>
                      </MenuItem>
                      {this.state.listGeofenceConfig
                        ?.filter((item) => item.on_demand_sim_id)
                        ?.map((item, idx) => (
                          <MenuItem value={item.geofence_id} key={idx}>
                            {item.name}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* location tag */}
                <Grid container alignItems="center" justify="flex-start" item xs={6} lg={2} className="grid_title_padding">
                  {t('favoriteWaypoint.favorite_tag')}
                </Grid>
                <Grid container alignItems="center" item xs={6} lg={4}>
                  <FormControl>
                    <TextField
                      name="location_tag"
                      className="field_size_20 field_min_size_300"
                      margin="dense"
                      placeholder={t('placeholder.required', {field: t('favoriteWaypoint.favorite_tag')})}
                      value={this.state.location_tag}
                      onChange={(event) => onChangeTextField(this, event)}
                      variant="outlined"
                      onKeyPress={(event) => this.onSubmitFormSearch(event)}
                      onBlur={(event) => this.onSubmitFormSearch(event)}
                    />
                  </FormControl>
                </Grid>
                {/* location name */}
                <Grid container alignItems="center" justify="flex-start" item xs={6} lg={2} className="grid_title_padding">
                  {t('favoriteWaypoint.location')}
                </Grid>
                <Grid container alignItems="center" item xs={6} lg={4}>
                  <FormControl>
                    <TextField
                      name="location_name"
                      className="field_size_20 field_min_size_300"
                      margin="dense"
                      placeholder={t('placeholder.required', {field: t('favoriteWaypoint.location')})}
                      value={this.state.location_name}
                      onChange={(event) => onChangeTextField(this, event)}
                      variant="outlined"
                      onKeyPress={(event) => this.onSubmitFormSearch(event)}
                      onBlur={(event) => this.onSubmitFormSearch(event)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Container>
          <br></br>
          <Container maxWidth="xl">
            {/* geofence */}
            <LoadingOverlay active={common.isLoading || isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
              <div className="scroll_area_700">
                <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                  <TableHead>
                    <TableRow>
                      <TableCell className="width_100p" align="flex-start">
                        {t('common.geofence')}
                      </TableCell>
                      <TableCell className="width_150p" align="flex-start">
                        {t('favoriteWaypoint.location')}
                      </TableCell>
                      <TableCell className="width_100p" align="flex-start">
                        {t('favoriteWaypoint.favorite_tag')}
                      </TableCell>
                      <TableCell className="width_50p" align="flex-start">
                        {t('favoriteWaypoint.home')}
                      </TableCell>
                      <TableCell className="width_50p" align="flex-start">
                        {t('favoriteWaypoint.create_by')}
                      </TableCell>
                      <TableCell className="width_100p" align="flex-start">
                        {t('common.action')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {waypointList?.content?.map((row, index) => {
                      return (
                        <TableRow key={index} hover className="cursor_pointer">
                          <TableCell>{this.state.listGeofenceAll?.find((e) => e.geofence_id === row.geofence_id)?.name}</TableCell>
                          <TableCell>
                            {row.name_translations === null || _.isEmpty(row.name_translations) ? row.location_name : row.name_translations[this.props.i18n.language]}
                          </TableCell>
                          <TableCell>{row.location_tag}</TableCell>
                          <TableCell>{row.mark_home && <HomeIcon />}</TableCell>
                          <TableCell>{row.type === 'ADMIN' ? t('common.admin') : t('common.user')}</TableCell>
                          <TableCell>
                            <Button color="primary" variant="contained" className="button_margin" endIcon={<DetailIcon />} onClick={() => this.openFormModal('UPDATE', row.id)}>
                              {!row.mark_home ? t('common.btnEdit') : t('common.btnDetail')}
                            </Button>
                            {!row.mark_home && (
                              <Button
                                color="primary"
                                variant="contained"
                                className="button_margin button_color_red"
                                endIcon={<DeleteIcon />}
                                onClick={() => this.handleDelete(row.id)}
                              >
                                {t('common.btnDelete')}
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <br></br>
              </div>
              <CustomPagination onChange={this.onChangePagination} rows={waypointList?.totalSize} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
            </LoadingOverlay>
          </Container>
        </Container>
        {/* Modal Form component */}
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.openForm}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1000',
          }}
        >
          <>
            <Form justify="center" onClickClose={this.handleButtonCloseModal} userId={this.props.match.params.userId} favoriteId={this.state.favoriteId}></Form>
          </>
        </Modal>

        {/* Delete Dialog */}
        <Dialog
          open={this.state.deleteDialog}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <SelectModal onClickOk={this.handleDialogButtonOk} onClickCancel={this.handleDialogButtonCancel} message={t('favoriteWaypoint.deleteModal')}></SelectModal>
        </Dialog>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    isLoading: state.member.isLoading,
    waypointList: state.member.waypointList,
    memberDetail: state.member.memberDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMemberDetail: (id) => dispatch(getMemberDetail(id)),
    getListGeofence: (payload) => dispatch(getListGeofence(payload)),
    getListFavoriteWaypoint: (user_id, geofence_id, location_tag, location_name, page, size) =>
      dispatch(getListFavoriteWaypoint(user_id, geofence_id, location_tag, location_name, page, size)),
    deleteFavoriteWaypoint: (id) => dispatch(deleteFavoriteWaypoint(id)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Search)));
