import {ROUTE} from '../../common/constant';
import {
  configDriverShiftsApi,
  searchListDriverVehicleApi,
  getDriversByFacilityApi,
  getVehiclesByFacilityApi,
  applyTimeShiftsApi,
  getVehicleActivatedTimesApi,
  getAutoConfigVehicleDriverAssociateApi,
  updateAutoConfigVehicleDriverAssociateApi,
} from '../../services/businessServices';
import {modalObj} from '../../utils/modal';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

export const registerVehicle = (data) => {
  return {
    type: ACTION_TYPES.REGISTER_VEHICLE,
    registerVehicle: data,
  };
};

/**
 * search list driver vehicle association
 * @param {object} payload
 * @param {object} browserHistory
 * @return {object}
 */
export const searchListDriverVehicle = (payload, browserHistory) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.SEARCH_LIST_DRIVER_VEHICLE_ASSOCIATION_START,
    });
    return searchListDriverVehicleApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.SEARCH_LIST_DRIVER_VEHICLE_ASSOCIATION_SUCCESS,
            listDriverVehicle: response.result,
          });
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.SEARCH_LIST_DRIVER_VEHICLE_ASSOCIATION_FAIL,
          });
          if (response?.status === 403) {
            browserHistory.replace(ROUTE.LAYOUT + ROUTE.HOME);
          }
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.SEARCH_LIST_DRIVER_VEHICLE_ASSOCIATION_FAIL,
        }),
    );
  };
};

/**
 * configDriverVehicle
 * @param {*} payload
 * @return {*}
 */
export const configDriverVehicle = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.CONFIG_DRIVER_VEHICLE_START,
    });
    return configDriverShiftsApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.CONFIG_DRIVER_VEHICLE_SUCCESS,
          });
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code, {
            username: response.message_code === 'the.process.is.being.performed.by.another.user' ? (response.result || '') : '',
          })));
          dispatch({
            type: ACTION_TYPES.CONFIG_DRIVER_VEHICLE_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.CONFIG_DRIVER_VEHICLE_FAIL,
        }),
    );
  };
};

/**
 * apply time shifts
 * @param {*} payload
 * @return {*}
 */
export const applyTime = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.CONFIG_DRIVER_VEHICLE_START,
    });
    return applyTimeShiftsApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.CONFIG_DRIVER_VEHICLE_SUCCESS,
          });
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          return response.result;
        } else {
          if (response?.message_code) {
            dispatch(setMessageModal(modalObj(true, response.message_code, {
              username: response.message_code === 'the.process.is.being.performed.by.another.user' ? (response.result || '') : '',
            })));
          }
          dispatch({
            type: ACTION_TYPES.CONFIG_DRIVER_VEHICLE_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.CONFIG_DRIVER_VEHICLE_FAIL,
        }),
    );
  };
};

/**
 * get driver by facility ID
 * @param {Number} id
 * @param {Object} browserHistory
 * @return {Object}
 */
export const getDriversByFacility = (id, browserHistory) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_DRIVER_BY_FACILITY_START,
    });
    return getDriversByFacilityApi(id).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_DRIVER_BY_FACILITY_SUCCESS,
            driver: response.result,
          });
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response?.message_code)));
          dispatch({
            type: ACTION_TYPES.GET_DRIVER_BY_FACILITY_FAIL,
          });
          if (response.status === 403) {
            browserHistory && browserHistory.replace(ROUTE.HOME + ROUTE.ASSOCIATION);
            return response;
          }
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_DRIVER_BY_FACILITY_FAIL,
        }),
    );
  };
};

/**
 * get vehicle by facility ID
 * @param {Number} id
 * @param {Date} date
 * @return {Object}
 */
export const getVehiclesByFacility = (id, date) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_VEHICLE_BY_FACILITY_START,
    });
    return getVehiclesByFacilityApi(id, date).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_VEHICLE_BY_FACILITY_SUCCESS,
            vehicle: response.result,
          });
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response?.message_code)));
          dispatch({
            type: ACTION_TYPES.GET_VEHICLE_BY_FACILITY_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_VEHICLE_BY_FACILITY_FAIL,
        }),
    );
  };
};

/**
 * get vehicle activated times
 * @param {Number} id
 * @return {Object}
 */
export const getVehicleActivatedTimes = (id) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.GET_VEHICLE_ACTIVATED_TIMES_START,
    });
    return getVehicleActivatedTimesApi(id).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.GET_VEHICLE_ACTIVATED_TIMES_SUCCESS,
            activated_times: response.result,
          });
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.GET_VEHICLE_ACTIVATED_TIMES_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.GET_VEHICLE_ACTIVATED_TIMES_FAIL,
        }),
    );
  };
};

/**
 * getAutoConfigVehicleDriverAssociate
 * @param {Number} vehicle_id
 * @param {Number} driver_id
 * @return {Object}
 */
export const getAutoConfigVehicleDriverAssociate = (vehicle_id, driver_id) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ASSOCIATION_AUTO_CONFIG_START,
    });
    return getAutoConfigVehicleDriverAssociateApi(vehicle_id, driver_id).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.ASSOCIATION_AUTO_CONFIG_SUCCESS,
            auto_config: response.result,
          });
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.ASSOCIATION_AUTO_CONFIG_FAIL,
            auto_config: false,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.ASSOCIATION_AUTO_CONFIG_FAIL,
          auto_config: false,
        }),
    );
  };
};

/**
 * updateAutoConfigVehicleDriverAssociate
 * @param {Object} payload
 * @return {Object}
 */
export const updateAutoConfigVehicleDriverAssociate = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ACTION_TYPES.ASSOCIATION_AUTO_CONFIG_START,
    });
    return updateAutoConfigVehicleDriverAssociateApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({
            type: ACTION_TYPES.ASSOCIATION_AUTO_CONFIG_SUCCESS,
          });
          return response.result;
        } else {
          dispatch(setMessageModal(modalObj(true, response.message_code)));
          dispatch({
            type: ACTION_TYPES.ASSOCIATION_AUTO_CONFIG_FAIL,
          });
        }
      },
      () =>
        dispatch({
          type: ACTION_TYPES.ASSOCIATION_AUTO_CONFIG_FAIL,
        }),
    );
  };
};
