import React, {Component} from 'react';

import {Container, Grid, Button, Fade, Paper, FormControl, Select, MenuItem} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import GetAppIcon from '@material-ui/icons/GetApp';
import Papa from 'papaparse';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';

import {CONTENT_TYPE_CSV} from '../../common/constant';
import {importCompanyMemberListCSVApi} from '../../services/companyServices';
import {importJitCSVApi} from '../../services/jitOrderServices.js';
import {importDriverCSV} from '../../stores/driver/action';
import {setMessageModal} from '../../stores/modal/actions';
import {isDate, convertJSTToUTC, convertPrefixTime} from '../../utils/datetime';
import {modalObj} from '../../utils/modal';

/**
 * Import CSV Component
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      countryCode: 'VN',
      fileCSV: '',
      fileSrc: null,

      dataCSVJIT: [],
      dataCSVOnDemand: [],
      isLoading: false,
    };
    this.handleButtonOk = this.handleButtonOk.bind(this);
    this.handleButtonCancel = this.handleButtonCancel.bind(this);
    this.handleClickClose = this.handleClickClose.bind(this);
  }

  static defaultProps = {
    type: null,
  };

  /**
   * handleButtonOk
   */
  handleButtonOk() {
    this.props.onClickClose();
  }

  /**
   * handleButtonCancel
   */
  handleButtonCancel() {
    this.props.onClickClose();
  }
  /**
   * handleClickClose
   */
  handleClickClose() {
    this.props.onClickClose();
  }

  /**
   * onChangePrice
   * @param {*} index
   * @param {*} value
   */
  onChangePrice = (index, value) => {
    const price = this.state.price_sales;
    price[index].price = value;
    this.setState({
      price_sales: price,
    });
  };

  /**
   * handleCsvInput
   * @param {event} event
   */
  handleCsvInput = (event) => {
    if (event.target.files[0]) {
      if (event.target.files[0].name.slice(-4) === CONTENT_TYPE_CSV) {
        const files = event.target.files[0];
        this.setState({
          fileCSV: files,
          fileSrc: URL.createObjectURL(event.target.files[0]),
        });
        if (!this.props.type) {
          Papa.parse(files, {
            header: true,
            download: true,
            delimiter: ',',
            newline: '',
            beforeFirstChunk: function(chunk) {
              const rows = chunk.split(/\r\n|\r|\n/);
              const headings = rows[0].toLowerCase();
              rows[0] = headings;
              return rows.join('\r\n');
            },
            complete: this.getDataFirst,
          });
        }
      } else {
        this.setState({
          fileCSV: '',
        });
        this.props.setMessageModal(modalObj(true, 'errorFields.csvFile'));
      }
    } else {
      this.setState({
        fileCSV: '',
      });
    }
  };

  /**
   * getDataFirst
   * @param {*} rs
   */
  getDataFirst = (rs) => {
    const {t} = this.props;
    const data = JSON.parse(JSON.stringify(rs.data));
    const dataCSVJIT = [];
    const dataCSVOnDemand = [];
    data.map((item) => {
      if (item.driver_code && item.vehicle_id) {
        const start_time = item.start_date + ' ' + item.start_time;
        const end_time = item.end_date + ' ' + item.end_time;
        if (!isDate(start_time) || !isDate(end_time)) {
          this.props.setMessageModal(modalObj(true, t('validation.invalid.time') + '(DRIVER_CODE: ' + item.driver_code + ')'));
        }
        if (new Date(start_time) > new Date(end_time)) {
          this.props.setMessageModal(modalObj(true, t('validation.invalid.timeRange') + '(DRIVER_CODE: ' + item.driver_code + ')'));
        }
        const start_time_utc = new Date(convertJSTToUTC(start_time));
        const end_time_utc = new Date(convertJSTToUTC(end_time));

        const temp = {
          shift_id: item.shift_id,
          driver_code: item.driver_code,
          vehicle_id: item.vehicle_id,
          start_date: convertPrefixTime(start_time_utc.getMonth() + 1) + '/' + convertPrefixTime(start_time_utc.getDate()) + '/' + start_time_utc.getFullYear(),
          start_time: convertJSTToUTC(start_time),
          end_date: convertPrefixTime(end_time_utc.getMonth() + 1) + '/' + convertPrefixTime(end_time_utc.getDate()) + '/' + end_time_utc.getFullYear(),
          end_time: convertJSTToUTC(end_time),
          driver_name_app: item.driver_name_app,
          driver_name_ad: item.driver_name_ad,
        };
        if (item.shift_id) {
          dataCSVJIT.push(temp);
        } else {
          dataCSVOnDemand.push(temp);
        }

        // const sub_date = days_between(new Date(item.end_date), new Date(item.start_date));
        // for (let i = 0; i < sub_date + 1; i++) {
        //   const temp = {
        //     driver_code: item.driver_code,
        //     vehicle_id: item.vehicle_id,
        //     start_time: convertJSTToUTC(addDays(item.start_date + ' ' + item.start_time, i)),
        //     end_time: convertJSTToUTC(addDays(item.start_date + ' ' + item.end_time, i)),
        //     driver_name_app: item.driver_name_app,
        //     driver_name_ad: item.driver_name_ad,
        //   };
        //   dataCSV.push(temp);
        // }
      }
      return item;
    });
    this.setState({
      dataCSVJIT: dataCSVJIT,
      dataCSVOnDemand: dataCSVOnDemand,
    });
  };

  /**
   * handleClickUpload
   */
  handleClickUpload = async () => {
    if (!this.props.type) {
      this.props.uploadCsv('PENDING');
      const dataCSVJIT = this.state.dataCSVJIT;
      const csvJIT = await this.props.importDriverCSV(dataCSVJIT, this.props);
      if (csvJIT) {
        this.props.uploadCsv('SUCCESS');
      }
    }
    if (this.props.type === 'jit' && this.state.fileCSV) {
      this.setState({
        isLoading: true,
      });
      const formData = new FormData();
      formData.append('orders', this.state.fileCSV);
      const csv = await importJitCSVApi(formData);
      this.setState({
        isLoading: false,
      });
      this.props.setMessageModal(modalObj(true, csv.messageCode));
      this.props.reSearch();
      this.props.onClickClose();
    }
    if (this.props.type === 'member-list') {
      if (this.state.fileCSV) {
        this.setState({
          isLoading: true,
        });
        const {companyId, geofenceId} = this.props.props;
        const formData = new FormData();
        formData.append('member_list', this.state.fileCSV);
        formData.append('company_id', companyId ? companyId : '');
        formData.append('geofence_id', geofenceId ? geofenceId : '');
        const csv = await importCompanyMemberListCSVApi(formData);
        this.setState({
          isLoading: false,
        });
        this.props.onClickClose();
        if (csv.result) {
          this.props.onClickOk(csv.result);
        } else {
          this.props.setMessageModal(modalObj(true, csv.message_code));
        }
      } else {
        this.props.setMessageModal(modalObj(true, 'errorFields.csvFile'));
      }
    }
  };

  /**
   * Render component
   * @return {object}
   */
  render() {
    const {t} = this.props;
    return (
      <Fade in={true} style={{overflow: 'auto'}}>
        <Paper className="modal_size_mid">
          <LoadingOverlay active={this.state.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Container maxWidth="xl">
              <br />
              <Grid container spacing={2}>
                <Grid container alignItems="center" className="page_header" item xs={10}>
                  <img src={`${process.env.PUBLIC_URL}/favicon.ico`} alt="Logo" width="35" height="33"></img>
                  <span className="font_bold font_size_big">{t('calendar.csv_input')}</span>
                </Grid>
                <Grid container alignItems="center" justify="flex-end" className="page_header" item xs={2}>
                  <CancelIcon onClick={this.handleButtonCancel} className="cursor_pointer" />
                </Grid>
                {!this.props.type && (
                  <Grid container item xs={12}>
                    <label className="font_color_red">{t('calendar.csv_title')}</label>
                  </Grid>
                )}
                <Grid container item xs={6} style={{alignItems: 'center'}}>
                  <Button onClick={() => this.refs.upload.click()} color="primary" variant="contained">
                    {t('common.chooseFile')}
                  </Button>
                  <span style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginLeft: '10px', width: '50%'}}>
                    {this.state.fileCSV ? this.state.fileCSV?.name : t('common.noFilesSelected')}
                  </span>
                  <input ref="upload" className="input" id="contained-button-file" type="file" hidden accept=".csv" onChange={(event) => this.handleCsvInput(event)} />
                  {this.state.fileCSV ? (
                    <></>
                  ) : (
                    <label htmlFor="contained-button-file">
                      <AttachFileIcon></AttachFileIcon>
                    </label>
                  )}
                </Grid>
                {this.props.type && (
                  <Grid container item xs={6} style={{justifyContent: 'flex-end'}}>
                    {this.props.type === 'jit' && (
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_150">
                        <Select margin="dense" value={this.state.countryCode} onChange={(event) => this.setState({countryCode: event.target.value})}>
                          <MenuItem value="VN">{t('VN')}</MenuItem>
                          <MenuItem value="JP">{t('JP')}</MenuItem>
                        </Select>
                      </FormControl>
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      className="button_margin"
                      endIcon={<GetAppIcon />}
                      onClick={() => this.props.downloadTemplateCSV(this.state.countryCode)}
                    >
                      {t('jit.template.csv')}
                    </Button>
                  </Grid>
                )}
                <Grid container justify="flex-end" alignItems="center" item xs={12}>
                  <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={() => this.handleClickUpload()}>
                    {t('common.btnUpdate')}
                  </Button>
                  <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.handleClickClose} endIcon={<CloseIcon />}>
                    {t('common.btnCancel')}
                  </Button>
                </Grid>
              </Grid>
              <br></br>
            </Container>
          </LoadingOverlay>
        </Paper>
      </Fade>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    driverVehicleAssociation: state.driverVehicleAssociation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    importDriverCSV: (payload, props) => dispatch(importDriverCSV(payload, props)),
    setMessageModal: (params) => dispatch(setMessageModal(params)),
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
