import {
  getAllGeofence,
  getGeofence,
  syncGeofenceApi,
  updateGeofenceApi,
  searchServiceGeofenceApi,
  detailServiceGeofenceApi,
  updateServiceGeofenceApi,
  getEnableSimulationApi,
  checkGeofenceTimeApi,
  getProjectApi,
} from '../../services/geofences';
import {modalObj} from '../../utils/modal';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

const ACTION_LOADING = {
  type: ACTION_TYPES.LOADING,
};

const ACTION_LOADING_FAIL = {
  type: ACTION_TYPES.LOADING_FAIL,
};

const getGeofenceSuccesAction = (data) => {
  return {
    type: ACTION_TYPES.GET_GEOFENCE_SUCCESS,
    geofenceDetail: data,
  };
};

const ACTION_SYNC_START = {
  type: ACTION_TYPES.SYNC_START,
};

const ACTION_SYNC_FAIL = {
  type: ACTION_TYPES.SYNC_FAIL,
};

export const getAllGeofences = (pageable, payload) => {
  return (dispatch) => {
    dispatch(ACTION_LOADING);
    return getAllGeofence(pageable, payload)
        .then((response) => {
          if (response.status === 200 && response) {
            dispatch({
              type: ACTION_TYPES.GET_LIST_GEOFENCE_SUCCESS,
              listGeofence: response.result,
            });
            return response.result;
          } else {
            dispatch(ACTION_LOADING_FAIL);
          }
        })
        .catch(() => () => dispatch(ACTION_LOADING_FAIL));
  };
};

export const updateGeofence = (updatePayload) => {
  return (dispatch) => {
    dispatch(ACTION_LOADING);
    return updateGeofenceApi(updatePayload)
        .then((response) => {
          if (response && response.status === 200) {
            dispatch(getGeofenceSuccesAction(response.result));
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, 'error.common')));
            dispatch(ACTION_LOADING_FAIL);
            return null;
          }
        },
        () => dispatch(ACTION_LOADING_FAIL),
        );
  };
};

export const getDetailGeofence = (id) => {
  return (dispatch) => {
    dispatch(ACTION_LOADING);
    return getGeofence(id)
        .then((response) => {
          if (response.status === 200 && response) {
            dispatch(getGeofenceSuccesAction(response.result));
            return response.result;
          } else {
            dispatch(ACTION_LOADING_FAIL);
          }
        })
        .catch(() => () => dispatch(ACTION_LOADING_FAIL));
  };
};

export const syncGeofence = (uuid) => {
  return (dispatch) => {
    dispatch(ACTION_SYNC_START);
    return syncGeofenceApi(uuid)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ACTION_TYPES.SYNC_SUCCESS,
              geofenceDetail: response.result,
            });
            return response.result;
          } else {
            dispatch(ACTION_SYNC_FAIL);
          }
        })
        .catch(() => () => dispatch(ACTION_SYNC_FAIL));
  };
};

export const searchServiceGeofence = (payload) => {
  return (dispatch) => {
    dispatch(ACTION_LOADING);
    return searchServiceGeofenceApi(payload)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_SERVICE_GEOFENCE_SUCCESS,
              listService: response.result,
            });
            return response.result;
          } else {
            dispatch(ACTION_LOADING_FAIL);
          }
        })
        .catch(() => () => dispatch(ACTION_LOADING_FAIL));
  };
};

export const detailServiceGeofence = (id) => {
  return (dispatch) => {
    dispatch(ACTION_LOADING);
    return detailServiceGeofenceApi(id)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ACTION_TYPES.DETAIL_SERVICE_GEOFENCE_SUCCESS,
              serviceDetail: response.result,
            });
            return response.result;
          } else {
            dispatch(ACTION_LOADING_FAIL);
          }
        })
        .catch(() => () => dispatch(ACTION_LOADING_FAIL));
  };
};

export const updateServiceGeofence = (payload) => {
  return (dispatch) => {
    dispatch(ACTION_LOADING);
    return updateServiceGeofenceApi(payload)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ACTION_TYPES.DETAIL_SERVICE_GEOFENCE_SUCCESS,
              serviceDetail: response.result,
            });
            return response.result;
          } else {
            dispatch(ACTION_LOADING_FAIL);
          }
        })
        .catch(() => () => dispatch(ACTION_LOADING_FAIL));
  };
};

export const getEnableSimulation = (payload) => {
  return (dispatch) => {
    return getEnableSimulationApi(payload)
        .then((response) => {
          if (response.status === 200) {
            dispatch({
              type: ACTION_TYPES.AVAILABLE_SIMULATION_SUCCESS,
              simulation: response.result,
            });
            return response.result;
          }
        })
        .catch(() => () => dispatch(ACTION_LOADING_FAIL));
  };
};

/**
 * Check if geofence start-end time overlaps any plans' times
 * @param {Object} payload
 * @return {Promise}
 */
export const checkGeofenceTime = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.CHECK_GEOFENCE_TIME_START});
    return checkGeofenceTimeApi(payload).then(
      (response) => {
        if (response && response.status === 200) {
          dispatch({type: ACTION_TYPES.CHECK_GEOFENCE_TIME_SUCCESS});
        } else {
          dispatch({type: ACTION_TYPES.CHECK_GEOFENCE_TIME_FAIL});
        }
        return response.result;
      },
      () => dispatch({type: ACTION_TYPES.CHECK_GEOFENCE_TIME_FAIL}),
    );
  };
};

export const getProject = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_PROJECT_START});
    return getProjectApi(payload).then((response) => {
        if (response?.status === 200) {
          dispatch({type: ACTION_TYPES.GET_PROJECT_SUCCESS});
        } else {
          dispatch({type: ACTION_TYPES.GET_PROJECT_FAIL});
        }
        return response;
    },
    () => dispatch({type: ACTION_TYPES.GET_PROJECT_FAIL}));
  };
};
