import React, {Component} from 'react';

import {
  Card,
  Container,
  Grid,
  Button,
  FormControl,
  TextField,
  Paper,
  Dialog,
  IconButton,
  Modal,
  Backdrop,
  FormHelperText,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import CompareArrowsIcon from '@material-ui/icons/SwapHoriz';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {ROUTE, RECOMMEND_TYPE, PERMISSION_ACTIONS, RESERVATION_MOBI_TYPE, LANGUAGE} from '../../../common/constant';
import ImageUpload from '../../../components/imageSelect';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {popupRecommendCreateApi, popupRecommendDetailsApi, popupRecommendUpdateApi} from '../../../services/commonSettingServices';
import {getAllCountryCode, getAllGeofence} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {onChangeSelect, onChangeTextFieldLimitLine, backForwardRouter, getListAllGeofenceDetail} from '../../../utils/common';
import {modalObj} from '../../../utils/modal';

/**
 * Popup Recommend Management view
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      header_jp: '',
      header_vi: '',
      header_en: '',
      title_jp: '',
      title_vi: '',
      title_en: '',
      description_jp: '',
      description_vi: '',
      description_en: '',
      imageName: '',
      imageSrc: '',
      image: '',
      memos: [],
      note: '',
      recommend_logo_url: null,
      service_type: '',
      recommend_type: '',
      country: '',
      country_code: '',
      geofence_ids: [],

      openModal: false,
      imageWidth: null,
      imageHeight: null,
      croppedImaged: '',
      croppedUrl: '',
      isImageDialog: false,
      flagUpdate: false,
      isSubmitForm: false,
      isLoading: false,
      recommend_type_data: RECOMMEND_TYPE,
      listGeofenceAll: [],
      dataDetail: [],
    };
    this.handleButtonUpdateOk = this.handleButtonUpdateOk.bind(this);
    this.handleButtonUpdateCancel = this.handleButtonUpdateCancel.bind(this);
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllCountryCode();
    await this.props.getAllGeofence().then(() => this.setState({listGeofenceAll: this.props.common?.all_geofence}));
    if (this.props.match.path === ROUTE.LAYOUT + ROUTE.RECOMMEND_DETAILS + '/:id') {
      popupRecommendDetailsApi(this.props.match.params.id).then(
        (response) => {
          this.setState({
            country: response?.result?.country_id,
            description_jp: response?.result?.languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.description || '',
            description_vi: response?.result?.languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.description || '',
            description_en: response?.result?.languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.description || '',
            recommend_logo_url: response?.result?.image_master_url,
            header_jp: response?.result?.languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.name || '',
            header_vi: response?.result?.languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.name || '',
            header_en: response?.result?.languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.name || '',
            service_type: response?.result?.service_types[0],
            recommend_type: response?.result?.recommend_type,
            title_jp: response?.result?.languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.title || '',
            title_vi: response?.result?.languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.title || '',
            title_en: response?.result?.languages.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.title || '',
            geofence_ids: response?.result?.geofence_config_infos.map((geofence) => geofence.geofence_id),
            image_master_id: response?.result?.image_master_id,
            dataDetail: response?.result,
          });
          const listGeofenceAll = this.props.common?.all_geofence?.filter((e) => e.country_id === Number(response?.result?.country_id));
          this.setState({listGeofenceAll: getListAllGeofenceDetail(listGeofenceAll, response?.result?.geofence_config_infos)});
        },
        () => this.props.history.goBack(),
      );
    }
  }

  /**
   * handleCreate
   */
  handleCreate = () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid()) {
      this.setState({
        flagUpdate: true,
        message: 'recommend.question.confirm.create',
      });
    }
  };

  /**
   * handleUpdate
   */
  handleUpdate = () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid()) {
      this.setState({
        flagUpdate: true,
        message: 'recommend.question.confirm.update',
      });
    }
  };

  /**
   * handleButtonCancel
   */
  handleButtonUpdateCancel() {
    this.setState({
      flagUpdate: false,
    });
  }

  /**
   * handleButtonOk
   */
  handleButtonUpdateOk() {
    const {t} = this.props;
    const {
      geofence_ids,
      image_master_id,
      service_type,
      recommend_type,
      country,
      header_jp,
      header_vi,
      header_en,
      title_jp,
      title_vi,
      title_en,
      description_jp,
      description_vi,
      description_en,
    } = this.state;
    this.setState({
      flagUpdate: false,
    });
    const languages = [];
    header_jp &&
      languages.push({
        language_id: LANGUAGE.find((item) => item.code === 'ja')?.id,
        name: header_jp,
        title: title_jp,
        description: description_jp,
      });
    header_vi &&
      languages.push({
        language_id: LANGUAGE.find((item) => item.code === 'vi')?.id,
        name: header_vi,
        title: title_vi,
        description: description_vi,
      });
    header_en &&
      languages.push({
        language_id: LANGUAGE.find((item) => item.code === 'en')?.id,
        name: header_en,
        title: title_en,
        description: description_en,
      });
    const payload = {
      id: this.props.match.params.id,
      service_types: [service_type],
      country_id: country,
      geofence_ids,
      languages,
      image_master_id,
      recommend_type,
    };
    if (this.props.match.params.id) {
      popupRecommendUpdateApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            this.props.setMessageModal(modalObj(true, t(`${response.message_code}`)));
          } else {
            this.props.setMessageModal(modalObj(true, t(`${response.message_code}`)));
          }
        },
        (error) => {
          this.props.setMessageModal(modalObj(true, t(`${error.message_code}`)));
        },
      );
      popupRecommendDetailsApi(this.props.match.params.id);
    } else {
      popupRecommendCreateApi(payload).then(
        (response) => {
          if (response && response.status === 200) {
            this.props.setMessageModal(modalObj(true, t(`${response.message_code}`)));
            backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.RECOMMEND_MANAGEMENT);
          } else {
            this.props.setMessageModal(modalObj(true, t(`${response.message_code}`)));
          }
        },
        (error) => {
          this.props.setMessageModal(modalObj(true, t(`${error.message_code}`)));
        },
      );
    }
  }

  /**
   * handleChooseImage
   */
  handleChooseImage = () => {
    this.setState({
      openModal: true,
    });
  };

  /**
   * handleButtonOk
   */
  handleButtonOk = () => {
    this.setState({
      openModal: false,
    });
  };

  /**
   * handleButtonClose
   */
  handleButtonClose = () => {
    this.setState({
      openModal: false,
    });
  };

  /**
   * onChangeCountry
   * @param {*} e
   */
  onChangeCountry = (e) => {
    const geofenceIdsPrev = this.state.dataDetail?.geofence_config_infos?.map((geofence) => geofence?.geofence_id);
    this.setState({
      country_code: this.props.common?.country_code?.find((item) => item.id === e.target.value)?.country_code,
      country: e.target.value,
      listGeofenceAll: this.props.common?.all_geofence?.filter((item) => item.country_id === e.target.value),
      geofence_ids: Number(this.state.dataDetail?.country_id) === e.target.value ? [...geofenceIdsPrev] : [],
    });
  };

  /**
   * handleSelectGeofence
   * @param {Object} value
   * @param {Number} item
   */
  handleSelectGeofence = (value, item) => {
    const data = this.state.listGeofenceAll.filter((item) => item.enable && item.enable_for_admin).map((e) => e.geofence_id);
    const geofence_ids_copy = this.state.geofence_ids;
    const index = geofence_ids_copy?.indexOf(item);
    value ? geofence_ids_copy.push(item) : geofence_ids_copy.splice(index, 1);

    this.setState({
      geofence_ids: geofence_ids_copy,
      all_geofence: data.length > geofence_ids_copy.length ? false : true,
    });
  };

  /**
   * onChangeServiceType
   * @param {*} e
   */
  onChangeServiceType = (e) => {
    const data = e.target.value === RESERVATION_MOBI_TYPE[1].id ? RECOMMEND_TYPE.filter((e) => e.id === 'NOT_USED') : RECOMMEND_TYPE;
    this.setState({
      service_type: e.target.value,
      recommend_type_data: data,
      recommend_type: '',
    });
  };

  /**
   * Render component
   * @return {object}
   */
  render() {
    const {t, common, actions} = this.props;
    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              {this.props.match.params.id ? <h3>{t('route.recommendDetails')}</h3> : <h3>{t('route.recommendCreate')}</h3>}
            </Grid>
          </Grid>
        </Container>
        <br></br>
        <Container maxWidth="xl">
          <LoadingOverlay active={false} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('route.recommendInformation')}
                    </Grid>
                  </Grid>

                  {/* Country */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.country')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'country',
                            }}
                            displayEmpty
                            value={this.state.country || ''}
                            onChange={this.onChangeCountry}
                            renderValue={
                              this.state.country ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('common.country'),
                                      })}
                                    </div>
                                  )
                            }
                          >
                            {common?.country_code?.map((item, idx) => (
                              <MenuItem value={item.id} key={idx}>
                                {t(`${item.country_code}`)}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.validator.message('country', this.state.country, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.country, 'required') && (
                          <FormHelperText id="country" error>
                            {t('validation.required.choose', {field: t('common.country')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* geofence */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.geofence')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <FormGroup row>
                          {this.state.country !== '' ? (
                            this.state.listGeofenceAll?.map((item, index) => {
                              const checked = this.state.geofence_ids?.indexOf(item.geofence_id) > -1;
                              return (
                                <FormControlLabel
                                  key={index}
                                  labelPlacement="end"
                                  control={
                                    <Checkbox
                                      disabled={!item.enable || !item.enable_for_admin}
                                      checked={checked}
                                      onClick={(event) => this.handleSelectGeofence(event.target.checked, item.geofence_id)}
                                      className="checkbox_radio"
                                    />
                                  }
                                  label={item.name}
                                />
                              );
                            })
                          ) : (
                            <div className="font-12 color-disabled">
                              {t('placeholder.required_select', {
                                field: t('common.country'),
                              })}
                            </div>
                          )}
                        </FormGroup>
                        {this.validator.message('geofence_ids', this.state.geofence_ids, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.geofence_ids, 'required') && (
                          <FormHelperText id="geofence_ids" error>
                            {t('validation.required.choose', {field: t('business_plan.geofence')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Service type */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding" align="center">
                      {t('popup_recommend.service_type')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'service_type',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.service_type ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('popup_recommend.service_type'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.service_type || ''}
                          onChange={(e) => this.onChangeServiceType(e)}
                        >
                          {RESERVATION_MOBI_TYPE.map(({id, i18n}, idx) => (
                            <MenuItem value={id} key={idx}>
                              {t(`${i18n}`)}
                            </MenuItem>
                          ))}
                        </Select>
                        {this.validator.message('service_type', this.state.service_type, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.service_type, 'required') && (
                          <FormHelperText id="service_type" error>
                            {t('validation.required.choose', {field: t('popup_recommend.service_type')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Recommend Type */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding" align="center">
                      {t('popup_recommend.recommend_type')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'recommend_type',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.recommend_type ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('popup_recommend.recommend_type'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.recommend_type || ''}
                          onChange={(e) => onChangeSelect(this, e)}
                        >
                          {this.state.recommend_type_data?.map(({id, i18n}, idx) => (
                            <MenuItem value={id} key={idx}>
                              {t(`${i18n}`)}
                            </MenuItem>
                          ))}
                        </Select>
                        {this.validator.message('recommend_type', this.state.recommend_type, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.recommend_type, 'required') && (
                          <FormHelperText id="recommend_type" error>
                            {t('validation.required.choose', {field: t('popup_recommend.recommend_type')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Header */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('popup_recommend.header')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.ja')})</span>}
                            name="header_jp"
                            fullWidth
                            inputProps={{maxLength: 15}}
                            margin="dense"
                            value={this.state.header_jp || ''}
                            onChange={(event) => onChangeTextFieldLimitLine(this, event, 1)}
                            placeholder={t('placeholder.required', {field: t('popup_recommend.header')})}
                            variant="outlined"
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 15})}</div>
                        </Box>
                        {this.validator.message('header_jp', this.state.header_jp.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.header_jp.trim(), 'required') && (
                          <FormHelperText id="header_jp" error>
                            {t('validation.required', {field: `${t('popup_recommend.header')} (${t('common.ja')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.vi')})</span>}
                            name="header_vi"
                            fullWidth
                            inputProps={{maxLength: 15}}
                            margin="dense"
                            value={this.state.header_vi || ''}
                            onChange={(event) => onChangeTextFieldLimitLine(this, event, 1)}
                            placeholder={t('placeholder.required', {field: t('popup_recommend.header')})}
                            variant="outlined"
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 15})}</div>
                        </Box>
                        {this.validator.message('header_vi', this.state.header_vi.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.header_vi.trim(), 'required') && (
                          <FormHelperText id="header_vi" error>
                            {t('validation.required', {field: `${t('popup_recommend.header')} (${t('common.vi')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.en')})</span>}
                            name="header_en"
                            fullWidth
                            inputProps={{maxLength: 15}}
                            margin="dense"
                            value={this.state.header_en || ''}
                            onChange={(event) => onChangeTextFieldLimitLine(this, event, 1)}
                            placeholder={t('placeholder.required', {field: t('popup_recommend.header')})}
                            variant="outlined"
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 15})}</div>
                        </Box>
                        {this.validator.message('header_en', this.state.header_en.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.header_en.trim(), 'required') && (
                          <FormHelperText id="header_en" error>
                            {t('validation.required', {field: `${t('popup_recommend.header')} (${t('common.en')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Title */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('popup_recommend.title')} {this.state.recommend_type !== 'ONE_TIME' && <span className="font_color_red">＊</span>}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.ja')})</span>}
                            name="title_jp"
                            fullWidth
                            inputProps={{maxLength: 15}}
                            margin="dense"
                            value={this.state.title_jp || ''}
                            onChange={(event) => onChangeTextFieldLimitLine(this, event, 1)}
                            placeholder={t('placeholder.required', {field: t('popup_recommend.title')})}
                            variant="outlined"
                          />
                          <div style={{fontSize: 14}}>{t('validation.max.label', {value: 15})}</div>
                        </Box>
                        {this.validator.message('title_jp', this.state.title_jp.trim(), this.state.recommend_type !== 'ONE_TIME' ? 'required' : '')}
                        {this.state.isSubmitForm && this.state.recommend_type !== 'ONE_TIME' && !this.validator.check(this.state.title_jp.trim(), 'required') && (
                          <FormHelperText id="title_jp" error>
                            {t('validation.required', {field: `${t('popup_recommend.header')} (${t('common.ja')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.vi')})</span>}
                            name="title_vi"
                            fullWidth
                            inputProps={{maxLength: 15}}
                            margin="dense"
                            value={this.state.title_vi || ''}
                            onChange={(event) => onChangeTextFieldLimitLine(this, event, 1)}
                            placeholder={t('placeholder.required', {field: t('popup_recommend.title')})}
                            variant="outlined"
                          />
                          <div style={{fontSize: 14}}>{t('validation.max.label', {value: 15})}</div>
                        </Box>
                        {this.validator.message('title_vi', this.state.title_vi.trim(), this.state.recommend_type !== 'ONE_TIME' ? 'required' : '')}
                        {this.state.isSubmitForm && this.state.recommend_type !== 'ONE_TIME' && !this.validator.check(this.state.title_vi.trim(), 'required') && (
                          <FormHelperText id="title_vi" error>
                            {t('validation.required', {field: `${t('popup_recommend.header')} (${t('common.vi')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.en')})</span>}
                            name="title_en"
                            fullWidth
                            inputProps={{maxLength: 15}}
                            margin="dense"
                            value={this.state.title_en || ''}
                            onChange={(event) => onChangeTextFieldLimitLine(this, event, 1)}
                            placeholder={t('placeholder.required', {field: t('popup_recommend.title')})}
                            variant="outlined"
                          />
                          <div style={{fontSize: 14}}>{t('validation.max.label', {value: 15})}</div>
                        </Box>
                        {this.validator.message('title_en', this.state.title_en.trim(), this.state.recommend_type !== 'ONE_TIME' ? 'required' : '')}
                        {this.state.isSubmitForm && this.state.recommend_type !== 'ONE_TIME' && !this.validator.check(this.state.title_en.trim(), 'required') && (
                          <FormHelperText id="title_en" error>
                            {t('validation.required', {field: `${t('popup_recommend.header')} (${t('common.en')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Description */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('popup_recommend.description')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.ja')})</span>}
                            name="description_jp"
                            fullWidth
                            inputProps={{maxLength: 40}}
                            margin="dense"
                            value={this.state.description_jp || ''}
                            multiline
                            rows={2}
                            onChange={(event) => onChangeTextFieldLimitLine(this, event, 2)}
                            placeholder={t('placeholder.required', {field: t('popup_recommend.description')})}
                            variant="outlined"
                          />
                          <div style={{fontSize: 14}}>{t('validation.max.label', {value: 40})}</div>
                        </Box>
                        {this.validator.message('description_jp', this.state.description_jp.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.description_jp.trim(), 'required') && (
                          <FormHelperText id="description_jp" error>
                            {t('validation.required', {field: `${t('popup_recommend.description')} (${t('common.ja')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.vi')})</span>}
                            name="description_vi"
                            fullWidth
                            inputProps={{maxLength: 40}}
                            margin="dense"
                            value={this.state.description_vi || ''}
                            multiline
                            rows={2}
                            onChange={(event) => onChangeTextFieldLimitLine(this, event, 2)}
                            placeholder={t('placeholder.required', {field: t('popup_recommend.description')})}
                            variant="outlined"
                          />
                          <div style={{fontSize: 14}}>{t('validation.max.label', {value: 40})}</div>
                        </Box>
                        {this.validator.message('description_vi', this.state.description_vi.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.description_vi.trim(), 'required') && (
                          <FormHelperText id="description_vi" error>
                            {t('validation.required', {field: `${t('popup_recommend.description')} (${t('common.vi')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.en')})</span>}
                            name="description_en"
                            fullWidth
                            inputProps={{maxLength: 40}}
                            margin="dense"
                            value={this.state.description_en || ''}
                            multiline
                            rows={2}
                            onChange={(event) => onChangeTextFieldLimitLine(this, event, 2)}
                            placeholder={t('placeholder.required', {field: t('popup_recommend.description')})}
                            variant="outlined"
                          />
                          <div style={{fontSize: 14}}>{t('validation.max.label', {value: 40})}</div>
                        </Box>
                        {this.validator.message('description_en', this.state.description_en.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.description_en.trim(), 'required') && (
                          <FormHelperText id="description_en" error>
                            {t('validation.required', {field: `${t('popup_recommend.description')} (${t('common.en')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Recommend Logo Url */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      <Button
                        variant="contained"
                        disabled={this.props.match.params.id && !permission.canUpdate}
                        color="primary"
                        component="span"
                        onClick={() => this.handleChooseImage()}
                      >
                        {t('common.photo')}
                      </Button>{' '}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <label htmlFor="icon-button-file">
                        <IconButton color="primary" aria-label="upload picture" component="span">
                          {this.state.recommend_logo_url ? (
                            <div className="box_image" style={{width: '100%', height: '200px'}}>
                              <img className="cropped_image" style={{width: 'inherit', height: 'inherit'}} src={this.state.recommend_logo_url} alt="cropped" />
                            </div>
                          ) : (
                            <PhotoCamera />
                          )}
                        </IconButton>
                      </label>
                      <Grid container spacing={1}>
                        {this.validator.message('recommend_logo_url', this.state.recommend_logo_url, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.recommend_logo_url, 'required') && (
                          <FormHelperText id="recommend_logo_url" error>
                            {t('validation.required.choose', {field: t('common.photo')})}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {!this.props.match.params.id ? (
                      <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={() => this.handleCreate()}>
                        {t('common.btnRegister')}
                      </Button>
                    ) : (
                      permission.canUpdate && (
                        <Button color="primary" variant="contained" className="button_margin" endIcon={<CloudUploadIcon />} onClick={() => this.handleUpdate()}>
                          {t('common.btnUpdate')}
                        </Button>
                      )
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      className="button_margin button_color"
                      onClick={this.props.history.goBack}
                    >
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                </Grid>
                <Dialog
                  open={this.state.flagUpdate}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    onClickOk={this.handleButtonUpdateOk}
                    onClickCancel={this.handleButtonUpdateCancel}
                    message={this.state.message}
                    okButtonText={t('common.btnYes')}
                    cancelButtonText={t('common.btnNo')}
                  ></SelectModal>
                </Dialog>
                <Container maxWidth="xl">
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.openModal}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      zIndex: '1000',
                    }}
                  >
                    <>
                      <ImageUpload
                        onClickOk={this.handleButtonOk}
                        onClickClose={this.handleButtonClose}
                        selectImage={(value) => this.setState({recommend_logo_url: value.url, image_master_id: value.id})}
                      ></ImageUpload>
                    </>
                  </Modal>
                </Container>
                <br></br>
              </Container>
            </Card>
          </LoadingOverlay>
          <br></br>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllGeofence: () => dispatch(getAllGeofence()),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
