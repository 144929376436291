import React, {Component} from 'react';

import {
  Card,
  Container,
  Grid,
  Paper,
  Table,
  TableCell,
  TableRow,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  TableHead,
  TableBody,
  Dialog,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import {ListAlt} from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DetailIcon from '@material-ui/icons/Description';
import GetAppIcon from '@material-ui/icons/GetApp';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import StopIcon from '@material-ui/icons/Stop';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {
  ROUTE,
  ROWS_PER_PAGE_OPTIONS,
  DATA_NULL,
  EXPORT_ALERT_NUMBER_ROWS,
  EXPORT_LIMIT_NUMBER_ROWS,
  MAX_SIZE_OUTPUT,
  GEOFENCE_STATUS,
  HAS_FAMILY_OPTIONS,
  PERMISSION_ACTIONS,
  RESERVATION_MOBI_TYPE,
  LANGUAGE,
  PLAN_CATEGORIES,
  TICKET_AMOUNT_TYPE,
  SIMULATION_STATUS,
  HAS_ONE_TIME_DISCOUNT,
  PLAN_GIFT_STATUS,
} from '../../../common/constant';
import CSVExporter from '../../../components/CSVExporter';
import CustomPagination from '../../../components/CustomPagination';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getDetailPlanApi} from '../../../services/businessServices';
import {copyPlan, searchListPlan, exportPlan, listSortDataPlan} from '../../../stores/business/action';
import {getAllFacilityList, getListGeofenceByCountry, getAllCountryCode, getAllGeofence, getServiceTypes} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {
  changeUrlParams,
  getQueryStringFromObject,
  getUrlParams,
  onChangeSelect,
  onChangeTextField,
  redirectRouter,
  customDisplayCurrency,
  onChangeTextFieldNumber,
  preventInvalidChars,
  backForwardRouter,
} from '../../../utils/common';
import {modalObj} from '../../../utils/modal';
import {isRoleBusiness, isRoleGlobal} from '../../../utils/role';

/**
 * SearchListPlan
 */
class SearchListPlan extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      listCheckbox: [],
      checkbox: [],
      listRowDelete: [],
      flag: false,
      message: '',
      result: [],
      isSearch: false,
      totalRows: 0,
      currentPage: 0,
      rowsPerPage: ROWS_PER_PAGE_OPTIONS[0],
      searchParams: this.props.location.search,
      flagExport: false,
      confirmedExport: false,
      latestSearchParams: null,
      flagClone: false,
      id: '',
      serviceTypeIds: [],
      code: '',

      listGeofenceAll: [],
      country_id: !isRoleGlobal() ? props.principal.country_id : '',
      geofence_id: '',
      name: '',
      is_show_for_app: '',

      plan_category: '',
      number_of_unit: '',
      ticket_type: '',
      has_discount: null,
      isDisableCreate: false,
      is_gift: null,
    };
    this.exportCSVRef = React.createRef();
  }

  /**
   * reset
   */
  reset = () => {
    this.setState({
      country_id: !isRoleGlobal() ? this.props.principal.country_id : '',
      geofence_id: null,
      code: '',
      name: '',
      is_show_for_app: '',
      listGeofenceAll: this.props.common?.all_geofence,
      serviceTypeIds: [],

      plan_category: '',
      number_of_unit: '',
      ticket_type: '',
      has_discount: '',
      is_gift: null,
    });
  };

  /**
   * changePlanType
   * @param {event} event
   * @param {Number} id
   */
  changePlanType = (event, id) => {
    let planTypeIds = this.state.serviceTypeIds;
    if (event.target.checked) {
      planTypeIds.push(id);
    } else {
      planTypeIds = planTypeIds.filter((item) => item !== id);
    }
    this.setState({
      serviceTypeIds: planTypeIds,
    });
  };

  /**
   * handleSearch
   * @param {bool} reset
   */
  handleSearch = (reset) => {
    if (!this.props.actions.includes(PERMISSION_ACTIONS.SEARCH)) return;
    const {plan_category, country_id, geofence_id, code, name, is_show_for_app, serviceTypeIds, ticket_type, number_of_unit, has_discount, is_gift} = this.state;
    const payLoad = {
      country_id: Number(country_id) || null,
      geofence_id: geofence_id || null,
      plan_type: plan_category || null,
      code: code.trim() || '',
      name: name.trim() || null,
      is_show_for_app: is_show_for_app !== '' ? (is_show_for_app === 'true' ? true : false) : null,
      service_type_ids: serviceTypeIds,
      number_of_unit,
      ticket_type: ticket_type || null,
      has_discount: has_discount || null,
      is_gift: is_gift,
    };

    reset && this.setState({currentPage: 0, rowsPerPage: ROWS_PER_PAGE_OPTIONS[0]});
    const {currentPage, rowsPerPage} = this.state;
    const queryParams = reset ? {page: 0, size: ROWS_PER_PAGE_OPTIONS[0]} : {page: currentPage, size: rowsPerPage};
    this.props.searchListPlan(payLoad, queryParams, this.props).then((response) => {
      if (!response) {
        response = [];
      }
      this.setState(
        {
          result: this.handleSearchResult(response?.content || []),
          isSearch: true,
          totalRows: response.totalSize ? response.totalSize : 0,
          latestSearchParams: payLoad,
        },
        () => {
          const {currentPage, rowsPerPage} = this.state;
          // Apply changed params into url
          const queryParamsToChange = {
            ...payLoad,
            currentPage: String(currentPage),
            rowsPerPage,
          };
          changeUrlParams(queryParamsToChange);
          const newSearchParams = getQueryStringFromObject(queryParamsToChange);
          // Save search params into state in order to pass to next page
          this.setState({searchParams: newSearchParams});
        },
      );
    });
  };

  /**
   * Handle change page or rows per page
   * @param {number} currentPage
   * @param {number} rowsPerPage
   */
  onChangePagination = (currentPage, rowsPerPage) => {
    this.setState({currentPage, rowsPerPage}, this.handleSearch);
  };

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllCountryCode();
    await this.props.getServiceTypes();
    await this.props.getAllGeofence().then((response) => this.setState({listGeofenceAll: response}));
    if (this.props.location.search) {
      const urlParams = getUrlParams(this.props.location.search);
      if (isRoleGlobal() ? true : Number(urlParams.country_id) === Number(this.props.principal.country_id)) {
        urlParams.plan_category = urlParams.plan_type;
        delete urlParams.plan_type;
        this.setState(urlParams, () => {
          this.state.country_id && this.props.getListGeofenceByCountry(this.state.country_id);
          if (this.state.country_id) {
            this.setState({
              listGeofenceAll: this.props.common?.all_geofence?.filter((e) => e.country_id === Number(this.state.country_id)),
            });
          }
          this.handleSearch();
        });
      } else backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.HOME);
    }
  }

  /**
   * convertPlanType
   * @param {Array} list
   * @return {Element}
   */
  convertPlanType = (list) => {
    const {t} = this.props;
    return list?.map((item, index) => {
      return item ? (
        <div key={index}>{item === RESERVATION_MOBI_TYPE[0].id ? t(RESERVATION_MOBI_TYPE[0].i18n) : t(RESERVATION_MOBI_TYPE[1].i18n)}</div>
      ) : (
        <strike key={index}>{item === RESERVATION_MOBI_TYPE[0].id ? t(RESERVATION_MOBI_TYPE[0].i18n) : t(RESERVATION_MOBI_TYPE[1].i18n)}</strike>
      );
    });
  };

  /**
   * handleCopy
   * @param {*} id
   */
  handleCopy = (id) => {
    this.setState({
      flagClone: true,
      message: 'business_plan.message_clone',
      id,
    });
  };

  /**
   * handleButtonCloneOk
   */
  handleButtonCloneOk = async () => {
    this.setState({
      flagClone: false,
      isDisableCreate: true,
    });
    const {id} = this.state;
    const response = await getDetailPlanApi(id);
    const planData = response?.result;
    delete planData.plan_id;
    delete planData.code;
    if (this.state.plan_category === 'TICKET') {
      delete planData.limit_family_user;
      delete planData.trial_time;
      delete planData.company_id;
      delete planData.main_plan;
      planData.ticket_data.auto_renew = planData?.ticket_data?.auto_renew || false;
    } else if (this.state.plan_category === 'PLAN') {
      delete planData.ticket_data;
    }
    planData.name = planData.name + ' (Copy)';
    planData.plan_languages = planData.plan_languages.map((item) => {
      return {
        ...item,
        name: item.name + ' (Copy)',
      };
    });
    planData.memos = [];
    planData.bank_ids = planData?.banks[0]?.id ? [planData?.banks[0]?.id] : null;
    await this.props.copyPlan(planData, this).then((res) => {
      if (res) {
        this.setState({
          isDisableCreate: false,
        });
      }
    });
  };

  /**
   * handleButtonCloneCancel
   */
  handleButtonCloneCancel = () => {
    this.setState({flagClone: false});
  };

  /**
   * Fetch data to export
   */
  fetchDataExport = async () => {
    const {t} = this.props;
    const queryParams = {page: 0, size: MAX_SIZE_OUTPUT};

    this.exportHeaders = [
      {label: t('business_plan.id'), key: 'id'},
      {label: t('business_plan.geofence'), key: 'geofence_ids'},
      {label: t('business_plan.package_name') + '(JP)', key: 'name_jp'},
      {label: t('business_plan.package_name') + '(VI)', key: 'name_vi'},
      {label: t('business_plan.package_name') + '(EN)', key: 'name_en'},
      {label: t('business_plan.price'), key: 'amount'},
      {label: t('business_plan.unit'), key: 'currency_code'},
      {label: t('business_plan.one_time_discount'), key: 'has_discount'},
    ];
    let dataExport = await this.props.exportPlan(this.state.latestSearchParams, queryParams);
    dataExport = dataExport?.map((item) => {
      return {
        ...item,
        geofence_ids: item.geofence_infos?.map((item) => item.geofence_name),
        name_jp: item.plan_languages?.find((itm) => itm.language_id === LANGUAGE.find((itm2) => itm2.code === 'ja')?.id)?.name,
        name_en: item.plan_languages?.find((itm) => itm.language_id === LANGUAGE.find((itm2) => itm2.code === 'en')?.id)?.name,
        name_vi: item.plan_languages?.find((itm) => itm.language_id === LANGUAGE.find((itm2) => itm2.code === 'vi')?.id)?.name,
        amount: item.plan_prices[0]?.amount,
        currency_code: item.plan_prices[0]?.currency_code,
        has_discount: item?.has_discount ? t('common.enable') : t('common.disable'),
      };
    });
    this.setState({confirmedExport: false});
    return dataExport;
  };

  /**
   * alertExportCSV
   */
  alertExportCSV = () => {
    const {t} = this.props;
    if (this.state.totalRows > EXPORT_LIMIT_NUMBER_ROWS) {
      this.props.setMessageModal(modalObj(true, t('messageCode.exportAlertLimit')));
    } else {
      const message = t('messageCode.exportAlert');
      this.setState({flagExport: true, message});
    }
  };

  /**
   * onChangeCountry
   * @param {*} e
   */
  onChangeCountry = (e) => {
    this.setState({
      country_id: e.target.value,
      listGeofenceAll: this.props.common?.all_geofence?.filter((item) => item.country_id === e.target.value),
      geofence_id: '',
    });
  };

  /**
   * handleSearchResult
   * @param {Array} searchDatas
   * @return {Object}
   */
  handleSearchResult = (searchDatas) => {
    return searchDatas.map((searchData) => ({
      ...searchData,
      geofencesName: this.getGeofencesNameFromIds(searchData.geofence_ids || []),
    }));
  };

  /**
   * getGeofencesNameFromIds
   * @param {Array} geofence_ids
   * @return {Object}
   */
  getGeofencesNameFromIds = (geofence_ids) => {
    const {listGeofenceAll} = this.state;
    return geofence_ids.map((id) => {
      const geofences_details = listGeofenceAll.find((item) => item.geofence_id === id);
      return {name: geofences_details?.name, enable: geofences_details?.enable};
    });
  };

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {t, common, business, actions} = this.props;
    const {serviceTypeIds} = this.state;
    const permission = {
      canSearch: actions.includes(PERMISSION_ACTIONS.SEARCH),
      canCSVOutput: actions.includes(PERMISSION_ACTIONS.CSV_OUTPUT),
      canClone: actions.includes(PERMISSION_ACTIONS.CLONE),
      canRegister: actions.includes(PERMISSION_ACTIONS.REGISTER),
      canEdit: actions.includes(PERMISSION_ACTIONS.EDIT),
      canManage: actions.includes(PERMISSION_ACTIONS.MANAGE),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.business_plan')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.HOME}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          <LoadingOverlay active={business.isLoading || common.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('facility.searchTitle')}
                    </Grid>
                  </Grid>

                  {/* Country */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.country')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_250">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'country_id',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.country_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.country'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.country_id}
                          onChange={this.onChangeCountry}
                          disabled={!isRoleGlobal()}
                        >
                          {common.country_code?.map((item, idx) => {
                            return (
                              <MenuItem value={item.id} key={idx}>
                                {t(`${item.country_code}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Geofence */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.geofence')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_250">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'geofence_id',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.geofence_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.geofence'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.geofence_id}
                          onChange={(e) => onChangeSelect(this, e)}
                        >
                          {this.state.listGeofenceAll?.map((item, idx) => {
                            return (
                              <MenuItem value={item.geofence_id} key={idx} className={!item.enable ? 'disable-option-custom' : ''}>
                                {t(`${item.name}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Plan Category */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.category')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={6}>
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_250">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'plan_category',
                          }}
                          displayEmpty
                          value={this.state.plan_category}
                          onChange={(event) => onChangeSelect(this, event)}
                          renderValue={
                            this.state.plan_category ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('business_plan.category'),
                                    })}
                                  </div>
                                )
                          }
                        >
                          {PLAN_CATEGORIES.map((item, index) => (
                            <MenuItem value={item.value} key={index}>
                              {t(`${item.i18n}`)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Plan Code */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.code')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl>
                        <TextField
                          name="code"
                          className="field_size_20 field_min_size_250"
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('business_plan.code')})}
                          inputProps={{maxLength: 10}}
                          value={this.state.code}
                          onChange={(event) => onChangeTextField(this, event)}
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Plan Name */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.package_name')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl>
                        <TextField
                          name="name"
                          className="field_size_20 field_min_size_250"
                          margin="dense"
                          placeholder={t('placeholder.required', {field: t('business_plan.package_name')})}
                          inputProps={{maxLength: 256}}
                          value={this.state.name}
                          onChange={(event) => onChangeTextField(this, event)}
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Plan Type */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.planType')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error className="item_display_box">
                        {common?.planTypes?.map(
                          (planType) =>
                            (planType.service_type === RESERVATION_MOBI_TYPE[0].id || planType.service_type === RESERVATION_MOBI_TYPE[1].id) && (
                              <FormControlLabel
                                labelPlacement="end"
                                control={
                                  <Checkbox
                                    checked={serviceTypeIds?.indexOf(planType.id) > -1}
                                    onChange={(event) => this.changePlanType(event, planType.id)}
                                    className="checkbox_radio"
                                  />
                                }
                                label={planType.service_type === RESERVATION_MOBI_TYPE[0].id ? t(RESERVATION_MOBI_TYPE[0].i18n) : t(RESERVATION_MOBI_TYPE[1].i18n)}
                              />
                            ),
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Days */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.number_day')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl error>
                        <TextField
                          className="field_size_10 field_min_size_250"
                          margin="dense"
                          type="number"
                          inputProps={{
                            name: 'number_of_unit',
                            min: 0,
                          }}
                          placeholder={t('placeholder.required', {field: t('business_plan.number_day')})}
                          variant="outlined"
                          onChange={(event) => onChangeTextFieldNumber(this, event)}
                          onKeyDown={preventInvalidChars}
                          value={this.state.number_of_unit}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* One Time Discount */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.one_time_discount')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl className="field_min_size_250">
                        <Select
                          name="has_discount"
                          renderValue={
                            this.state.has_discount ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('business_plan.one_time_discount'),
                                    })}
                                  </div>
                                )
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          displayEmpty
                          value={this.state.has_discount}
                          onChange={(event) => onChangeSelect(this, event)}
                        >
                          {HAS_ONE_TIME_DISCOUNT.map(({id, i18n}) => (
                            <MenuItem value={id} key={id}>
                              {t(i18n)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Amount type */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('ticket.amount_type')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={10}>
                      <FormControl className="field_min_size_250">
                        <Select
                          name="ticket_type"
                          renderValue={
                            this.state.ticket_type ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('ticket.amount_type'),
                                    })}
                                  </div>
                                )
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          displayEmpty
                          value={this.state.ticket_type}
                          onChange={(event) => onChangeSelect(this, event)}
                        >
                          {TICKET_AMOUNT_TYPE.map((item, index) => (
                            <MenuItem value={item.value} key={index}>
                              {t(item.i18n)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* For gift only*/}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('ticket.for_gift_only')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={3}>
                      <FormControl className="field_min_size_250">
                        <Select
                          name="is_gift"
                          renderValue={typeof (this.state.is_gift) === 'boolean' ? undefined : () => (
                            <div className="font-12 color-disabled">
                              {t('placeholder.required_select', {
                                field: t('ticket.for_gift_only'),
                              })}
                            </div>
                          )}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                          displayEmpty
                          value={this.state.is_gift}
                          onChange={(event) => onChangeSelect(this, event)}
                        >
                          {PLAN_GIFT_STATUS.map((item, index) => (
                            <MenuItem value={item.id} key={index}>
                              {t(item.i18n)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Show for app */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.enable_for_landing_page')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_250">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'is_show_for_app',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.is_show_for_app ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('business_plan.enable_for_landing_page'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.is_show_for_app}
                          onChange={(e) => this.setState({is_show_for_app: e.target.value})}
                        >
                          {HAS_FAMILY_OPTIONS.map((item, index) => {
                            return (
                              <MenuItem value={item.id} key={index}>
                                {t(item.i18n)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Paper>
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_magin button_color" onClick={() => this.reset()} endIcon={<RotateLeftIcon />}>
                        {t('common.btnResetSearch')}
                      </Button>
                    )}
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {this.state.result?.length > 0 && permission.canManage && this.state.plan_category === 'PLAN' && (
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={!this.state.geofence_id}
                        className="button_margin"
                        onClick={() => redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.BUSINESS_PLAN_SORT + `?geofenceId=${this.state.geofence_id}`)}
                        endIcon={<ListAlt />}
                      >
                        {t('common.btnManage')}
                      </Button>
                    )}
                    {permission.canRegister && (
                      <Link
                        style={{textDecoration: 'none', marginRight: 5}}
                        to={{pathname: ROUTE.LAYOUT + ROUTE.BUSINESS_PLAN_CREATE, state: {from: this.props.location.pathname + '?' + this.state.searchParams}}}
                      >
                        <Button color="primary" variant="contained" className="button_magin button_color_green" endIcon={<AddIcon />}>
                          {t('common.btnRegister')}
                        </Button>
                      </Link>
                    )}
                    {permission.canCSVOutput && permission.canSearch && (
                      <>
                        <div className={this.state.totalRows <= EXPORT_ALERT_NUMBER_ROWS || this.state.confirmedExport ? '' : 'hidden'}>
                          <CSVExporter
                            ref={this.exportCSVRef}
                            disabled={this.state.totalRows <= 0 || !this.state.latestSearchParams}
                            headers={this.exportHeaders}
                            fetchAction={this.fetchDataExport}
                            isFetchAsync={true}
                            screenName={t('route.business_plans')}
                          />
                        </div>
                        {this.state.totalRows >= EXPORT_ALERT_NUMBER_ROWS && !this.state.confirmedExport && (
                          <Button
                            onClick={() => this.alertExportCSV()}
                            disabled={this.state.totalRows <= 0}
                            color="primary"
                            variant="contained"
                            className="button_margin"
                            endIcon={<GetAppIcon className="csv-exporter-icon" />}
                          >
                            {t('calendar.csv_output')}
                          </Button>
                        )}
                      </>
                    )}
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_magin" onClick={() => this.handleSearch(true)} endIcon={<SearchIcon />}>
                        {t('common.btnSearch')}
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <br></br>
              </Container>
            </Card>
          </LoadingOverlay>
          <br></br>
          {this.state.isSearch && (
            <Card raised>
              <Container maxWidth="xl">
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={6}>
                    <h3>
                      {t('common.searchResult')} {this.state.totalRows} {t('common.case')}
                    </h3>
                  </Grid>
                </Grid>
                <LoadingOverlay active={business.isLoading || common.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                  {/* SEARCH */}
                  {this.state.result?.length > 0 && (
                    <div className="scroll_area_700">
                      <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                        <TableHead>
                          <TableRow>
                            <TableCell className="width_150p ant-table-cell-fix-left">{t('business_plan.code')}</TableCell>
                            <TableCell className="width_150p">{t('common.country')}</TableCell>
                            <TableCell className="width_300p">{t('common.geofence')}</TableCell>
                            <TableCell className="width_150p">{t('Plan Category')}</TableCell>
                            <TableCell className="width_125p">{t('ticket.for_gift_only')}</TableCell>
                            <TableCell className="width_150p">{t('business_plan.package_name')} (JP)</TableCell>
                            <TableCell className="width_150p">{t('business_plan.package_name')} (VN)</TableCell>
                            <TableCell className="width_150p">{t('business_plan.package_name')} (EN)</TableCell>
                            <TableCell className="width_250p">{t('common.planType')}</TableCell>
                            <TableCell className="width_75p">{t('ticket.days')}</TableCell>
                            <TableCell className="width_150p">{t('business_plan.unit')}</TableCell>
                            <TableCell className="width_200p">{t('business_plan.number_of_people')}</TableCell>
                            <TableCell className="width_200p">{t('business_plan.one_time_discount')}</TableCell>
                            <TableCell className="width_100p">{t('ticket.amount_type')}</TableCell>
                            <TableCell className="width_150p">{t('business_plan.price')}</TableCell>
                            <TableCell className="width_200p">{t('business_plan.enable_for_landing_page')}</TableCell>
                            <TableCell className="width_150p">{t('ticket.automatic_update')}</TableCell>
                            <TableCell className="width_250p" style={{position: 'sticky', right: '0'}} colSpan={3}>
                              {t('common.action')}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.result?.map((row, index) => {
                            const serviceTypes = row?.service_types?.length > 0 ? this.convertPlanType(row?.service_types) : DATA_NULL;
                            let checkDisable = false;
                            if (isRoleBusiness()) {
                              const setGeofences = new Set();
                              row.geofence_ids.forEach((item) => {
                                this.props.principal.geofence_ids.includes(item) && setGeofences.add(item);
                              });
                              if (setGeofences.size === row.geofence_ids.length) {
                                checkDisable = false;
                              } else checkDisable = true;
                            }
                            return (
                              <TableRow key={index} hover className="cursor_pointer">
                                <TableCell className="cell_fixed_left">{row.code}</TableCell>
                                <TableCell>{t(common.country_code?.find((item) => item.id === row.country_id)?.country_code)}</TableCell>
                                <TableCell>
                                  {row?.geofence_infos.map((item) => (
                                    <div>{item?.geofence_name}</div>
                                  ))}
                                </TableCell>
                                <TableCell>{t(PLAN_CATEGORIES.find((item) => item.value === row?.plan_type)?.i18n) || DATA_NULL}</TableCell>
                                <TableCell>
                                  <FormControl className="item_display_box">
                                    <FormControlLabel disabled={true} control={<Checkbox checked={row.is_gift} className="checkbox_radio" />} />
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  {row.plan_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.name || DATA_NULL}
                                </TableCell>
                                <TableCell>
                                  {row.plan_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.name || DATA_NULL}
                                </TableCell>
                                <TableCell>
                                  {row.plan_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.name || DATA_NULL}
                                </TableCell>
                                <TableCell>{serviceTypes || DATA_NULL}</TableCell>
                                <TableCell>{row?.number_of_unit || DATA_NULL}</TableCell>
                                <TableCell>{row?.plan_prices[0]?.currency_code || DATA_NULL}</TableCell>
                                <TableCell>{row?.limit_family_user || DATA_NULL}</TableCell>
                                <TableCell>{row?.has_discount ? t('common.enable') : t('common.disable')}</TableCell>
                                <TableCell>{row?.ticket_type ? t(TICKET_AMOUNT_TYPE.find((item) => item.value === row.ticket_type)?.i18n) : DATA_NULL}</TableCell>
                                <TableCell>{row.plan_prices[0] ? customDisplayCurrency(row.plan_prices[0].amount, row?.plan_prices[0]?.currency_code) : DATA_NULL}</TableCell>
                                <TableCell>{t(GEOFENCE_STATUS.find((item) => item.id === row.is_show_for_app).i18n)}</TableCell>
                                <TableCell>
                                  {row?.auto_renew || row?.auto_renew === false ? t(SIMULATION_STATUS.find((item) => item.id === row?.auto_renew).i18n) : DATA_NULL}
                                </TableCell>
                                {(permission.canEdit || permission.canClone) && (
                                  <TableCell colSpan={3} className="cell_fixed_right">
                                    {permission.canEdit && (
                                      <Link
                                        style={{textDecoration: 'none'}}
                                        to={{
                                          pathname: ROUTE.LAYOUT + ROUTE.BUSINESS_PLAN_DETAILS + '/' + row.id,
                                          state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                        }}
                                      >
                                        <Button color="primary" variant="contained" className="button_magin" endIcon={<DetailIcon />}>
                                          {t('common.btnEdit')}
                                        </Button>
                                      </Link>
                                    )}
                                    {permission.canClone && !(isRoleBusiness() && checkDisable) && (
                                      <Button
                                        color="primary"
                                        variant="contained"
                                        className="button_magin button_color_red"
                                        style={{marginLeft: 5}}
                                        endIcon={<StopIcon />}
                                        onClick={() => this.handleCopy(row.id)}
                                      >
                                        {t('common.btnClone')}
                                      </Button>
                                    )}
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </div>
                  )}
                  {this.state.result?.length > 0 && (
                    <CustomPagination onChange={this.onChangePagination} rows={this.state.totalRows} rowsPerPage={this.state.rowsPerPage} currentPage={this.state.currentPage} />
                  )}
                </LoadingOverlay>
                <br></br>

                {/* Clone alert modal */}
                <Dialog
                  open={this.state.flagClone}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    onClickOk={this.handleButtonCloneOk}
                    onClickCancel={this.handleButtonCloneCancel}
                    okButtonText={t('common.btnYes')}
                    cancelButtonText={t('common.btnNo')}
                    message={this.state.message}
                    isDisableCreate={this.state.isDisableCreate}
                  ></SelectModal>
                </Dialog>
              </Container>
              <br></br>
            </Card>
          )}
          <br></br>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    business: state.business,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllFacilityList: () => dispatch(getAllFacilityList()),
    searchListPlan: (payload, queryParams) => dispatch(searchListPlan(payload, queryParams)),
    copyPlan: (payload, props) => dispatch(copyPlan(payload, props)),
    getListGeofenceByCountry: (id) => dispatch(getListGeofenceByCountry(id)),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    exportPlan: (payload, queryParams) => dispatch(exportPlan(payload, queryParams)),
    listSortDataPlan: (data) => dispatch(listSortDataPlan(data)),
    getAllGeofence: () => dispatch(getAllGeofence()),
    getServiceTypes: () => dispatch(getServiceTypes()),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(SearchListPlan)));
