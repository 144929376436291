import React from 'react';

import {connect} from 'react-redux';
import {Redirect} from 'react-router';

import {ROUTE} from '../../common/constant';
import {getPrincipal} from '../../stores/common/actions';
import {getScreenList} from '../../stores/home/actions';

/**
 *
 * @param {React.Component} WrappedComponent
 * @param {string} path Screen relative path
 * @return {React.Component}
 */
const withPermissionGateway = (WrappedComponent) => {
  const mapStateToProps = (state) => {
    return {
      listScreen: state.home.listScreen,
      principal: state.common.principal,
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      getScreenList: () => dispatch(getScreenList()),
      getPrincipal: () => dispatch(getPrincipal()),
    };
  };

  return connect(
      mapStateToProps,
      mapDispatchToProps,
  )(
      class extends React.Component {
      /**
       *
       * @param {object} props
       */
        constructor(props) {
          super(props);
          this.state = {
            actions: [],
            allowed: false,
            checked: false,
          };
          this.path = props.location.pathname?.replace(ROUTE.LAYOUT, '');
        }

        /**
       * componentDidMount
       */
        async componentDidMount() {
          await this.props.getScreenList();
          await this.props.getPrincipal();
          const allowedPaths = this.getAllAllowedPaths();
          const allowed = this.isAllowedPath(allowedPaths);
          this.setState({allowed: allowed, checked: true});
          allowed && this.setState({actions: this.getAllAllowedActionsOfScreen()});
        }

      /**
       *
       * @param {array} allowedPaths
       * @return {boolean}
       */
      isAllowedPath = (allowedPaths) => {
        return !!allowedPaths.find((path) => this.path.includes(path));
      };

      /**
       *
       * @param {array} listScreen
       * @return {array} Extracted paths
       */
      getAllAllowedPaths = () => {
        const {listScreen} = this.props;
        const paths = new Set();
        for (const majorItem of listScreen) {
          // majorItem.path && paths.push(majorItem.path);
          for (const subFunction of majorItem.sub_functions) {
            subFunction.path && paths.add(subFunction.path);
            for (const subFunction2 of subFunction.sub_functions) {
              subFunction2.path && (subFunction2.path.includes(' ') ? subFunction2.path.split(' ').forEach((item) => paths.add(item)) : paths.add(subFunction2.path));
            }
          }
        }
        return [...paths];
      };

      /**
       * getAllAllowedActionsOfScreen
       * @param {array} listScreen
       * @return {array}
       */
      getAllAllowedActionsOfScreen = () => {
        const {listScreen} = this.props;
        const actions = [];
        for (const majorItem of listScreen) {
          for (const subFunction of majorItem.sub_functions) {
            for (const subFunction2 of subFunction.sub_functions) {
              const subFunction2path = subFunction2.path.includes(' ') ? subFunction2.path.split(' ')[0] : subFunction2.path;
              if (this.path.includes(subFunction2path)) subFunction2.action && actions.push(subFunction2.action);
            }
          }
        }
        return actions;
      };

      /**
       * Render
       * @return {React.Component}
       */
      render() {
        return (
          this.state.checked &&
          (this.state.allowed ? (
            <WrappedComponent {...this.props} checkedPermission={this.state.checked} actions={this.state.actions} />
          ) : (
            <Redirect push to={ROUTE.LAYOUT + ROUTE.HOME} />
          ))
        );
      }
      },
  );
};

export default withPermissionGateway;
