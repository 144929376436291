import {ACTION_TYPES} from './reducer';

export const addAnItem = (data) => {
  return {
    type: ACTION_TYPES.ADD_AN_ITEM,
    product: data,
  };
};

export const addAnyItems = (data) => {
  return {
    type: ACTION_TYPES.ADD_ANY_ITEMS,
    product: data,
  };
};

export const subAnItem = (data) => {
  return {
    type: ACTION_TYPES.SUB_AN_ITEM,
    product: data,
  };
};

export const removeItems = (data) => {
  return {
    type: ACTION_TYPES.REMOVE_ITEMS,
    product: data,
  };
};

export const removeAllItems = () => {
  return {
    type: ACTION_TYPES.REMOVE_ALL_ITEMS,
  };
};
