import React, {Component} from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {Grid, Button, TextField, Paper, Card, Container, FormControl, Select, MenuItem, Dialog, Modal, Backdrop, FormHelperText, Switch, Box} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CompareArrowsIcon from '@material-ui/icons/SwapHoriz';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {format} from 'date-fns';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {PERMISSION_ACTIONS, COUPON_TYPE, DATE_FORMAT} from '../../../common/constant';
import ImageSelect from '../../../components/imageSelect';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {getAllCountryCode, getAllGeofence} from '../../../stores/common/actions';
import {createCoupon, getDetailCoupon, updateCoupon} from '../../../stores/coupon/action';
import {onChangeSelect} from '../../../utils/common';
import {convertDateUTCAndGeofence} from '../../../utils/date_time_utils';
import {compareDateRange} from '../../../utils/datetime';
import {isRoleGlobal} from '../../../utils/role';
import './style.css';

/**
 * CouponForm From Component
 */
class CouponForm extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      type: null,
      country: !isRoleGlobal() ? props?.principal?.country_id : null,
      country_code: '',
      country_id: null,
      list_area_id: [],
      listGeofences: [],
      listSupplier: [],
      geofence_id: '',
      coupon_id: null,
      zone_id: '',
      coupon_languages: [
        {
          language_id: 1,
          shop_name: '',
          coupon_name: '',
          content: '',
          description: '',
          waypoint_nearest: '',
        },
        {
          language_id: 2,
          shop_name: '',
          coupon_name: '',
          content: '',
          description: '',
          waypoint_nearest: '',
        },
        {
          language_id: 3,
          shop_name: '',
          coupon_name: '',
          content: '',
          description: '',
          waypoint_nearest: '',
        },
      ],
      publishedDate: null,
      startDate: null,
      endDate: null,
      listGeofenceAll: [],
      couponCode: '',
      is_active: true,
      menuData: {
        image_master_id: null,
        image_master_url: '',
      },

      flgUpdate: false,
      isOpenModal: false,
      message: '',
      checkDetail: false,
      isSubmitForm: false,
      isDisableButtonCreate: false,
    };
    this.handleButtonUpdateOk = this.handleButtonUpdateOk.bind(this);
    this.handleButtonUpdateCancel = this.handleButtonUpdateCancel.bind(this);
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    await this.props.getAllCountryCode();
    await this.props.getAllGeofence();
    const coupon_id = this.props.match.params.id || null;
    const {common, principal} = this.props;
    if (!isRoleGlobal() && principal && !coupon_id) {
      const country = common.country_code.find((item) => item.id === principal?.country_id);
      this.setState({
        country_id: country?.id,
        country_code: country?.country_code,
        listGeofenceAll: this.props.common?.all_geofence?.filter((e) => e.country_id === country?.id),
        zone_id: country?.zone_id || 'Asia/Tokyo',
      });
    }
    if (coupon_id) {
      await this.props.getDetailCoupon(coupon_id, this.props).then((response) => {
        if (response) {
          this.setState({
            coupon_id: response?.id,
            country_id: response?.country_id,
            geofence_id: response?.geofence_id,
            couponCode: response?.coupon_code,
            type: response?.type,
            is_active: response?.is_active,
            publishedDate: response?.publish_date,
            startDate: new Date(moment.tz(response?.start_date, response.zone_id || 'Asia/Tokyo').format('yyyy-MM-DD')),
            endDate: new Date(moment.tz(response?.end_date, response.zone_id || 'Asia/Tokyo').format('yyyy-MM-DD')),
            menuData: {
              image_master_id: response?.image_master_id,
              image_master_url: response?.image_url,
            },
            coupon_languages: response?.coupon_languages?.sort((a, b) => a.language_id - b.language_id),
            zone_id: response?.zone_id || 'Asia/Tokyo',
            country_code: this.props.common?.country_code?.find((e) => e.id === response?.country_id)?.country_code,
            listGeofenceAll: this.props.common?.all_geofence?.filter((e) => e.country_id === response?.country_id),
          });
        }
      });
      this.setState({checkDetail: true});
    }
  }

  /**
   * handleCreate
   */
  handleCreate = () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid() && compareDateRange(this.state.startDate, this.state.endDate) && this.state.menuData.image_master_id) {
      this.setState({
        flgUpdate: true,
        message: 'coupon.question.confirm.create',
      });
    }
  };

  /**
   * handleUpdate
   */
  handleUpdate = () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid() && compareDateRange(this.state.startDate, this.state.endDate) && this.state.menuData.image_master_id) {
      this.setState({
        flgUpdate: true,
        message: 'coupon.question.confirm.update',
      });
    }
  };

  /**
   * openImageModal
   */
  openImageModal = () => {
    this.setState({isOpenModal: true});
  };

  /**
   * handleButtonOkImageModal
   */
  handleButtonOkImageModal = () => {
    this.setState({
      isOpenModal: false,
    });
  };

  /**
   * handleButtonCloseImageModal
   */
  handleButtonCloseImageModal = () => {
    this.setState({
      isOpenModal: false,
    });
  };

  /**
   * onClickMoveUpAndDownData
   * @param {Object} value
   * @param {Number} index
   */
  handleSelectImage = (value) => {
    const data = this.state.menuData;
    data.image_master_id = value.id;
    data.image_master_url = value.url;
    this.setState({
      menuData: data,
    });
  };

  /**
   * handleOnChangeCountry
   * @param {*} value
   */
  handleOnChangeCountry = (value) => {
    const country = this.props.common?.country_code?.find((e) => e.country_code === value);
    const country_id = country?.id;
    const zone_id = country?.zone_id;
    this.setState({
      country_id,
      zone_id,
      country_code: value,
      geofence_id: '',
      listGeofenceAll: this.props.common?.all_geofence?.filter((e) => e.country_id === country_id),
    });
  };

  /**
   * onChangeCouponName
   * @param {Object} couponName
   * @param {number} index
   */
  onChangeCouponName = (couponName, index) => {
    const {coupon_languages} = this.state;
    coupon_languages[index].coupon_name = couponName;
    this.setState({coupon_languages});
  };

  /**
   * onChangeDiscountContent
   * @param {Object} discounContent
   * @param {number} index
   */
  onChangeDiscountContent = (discounContent, index) => {
    const {coupon_languages} = this.state;
    coupon_languages[index].content = discounContent;
    this.setState({coupon_languages});
  };

  /**
   * onChangeDescription
   * @param {Object} description
   * @param {number} index
   */
  onChangeDescription = (description, index) => {
    const {coupon_languages} = this.state;
    coupon_languages[index].description = description;
    this.setState({coupon_languages});
  };

  /**
   * onChangeNearestWaypoint
   * @param {Object} waypoint
   * @param {number} index
   */
  onChangeNearestWaypoint = (waypoint, index) => {
    const {coupon_languages} = this.state;
    coupon_languages[index].waypoint_nearest = waypoint;
    this.setState({coupon_languages});
  };
  /**
   * onChangeShopName
   * @param {Object} shopName
   * @param {number} index
   */
  onChangeShopName = (shopName, index) => {
    const {coupon_languages} = this.state;
    coupon_languages[index].shop_name = shopName;
    this.setState({coupon_languages});
  };

  /**
   * handleButtonOk
   */
  handleButtonUpdateOk() {
    this.setState({
      flgUpdate: false,
      isDisableButtonCreate: true,
    });
    if (this.state.checkDetail) {
      const start_date = convertDateUTCAndGeofence(new Date(format(this.state.startDate, 'yyyy-MM-dd') + ' 00:00:00'), this.state.zone_id);
      const end_date = convertDateUTCAndGeofence(new Date(format(this.state.endDate, 'yyyy-MM-dd') + ' 23:59:59'), this.state.zone_id);
      const payload = {
        id: this.state.coupon_id,
        geofence_id: this.state.geofence_id,
        country_id: this.state.country_id,
        publish_date: this.state.publishedDate,
        start_date,
        end_date,
        is_active: this.state.is_active,
        image_master_id: this.state.menuData?.image_master_id,
        type: this.state.type,
        coupon_languages: this.state.coupon_languages,
        zone_id: this.state.zone_id,
      };
      this.props.updateCoupon(payload, this.props).then(() => {
        this.setState({
          isDisableButtonCreate: false,
        });
      });
    } else {
      const start_date = convertDateUTCAndGeofence(new Date(format(this.state.startDate, 'yyyy-MM-dd') + ' 00:00:00'), this.state.zone_id);
      const end_date = convertDateUTCAndGeofence(new Date(format(this.state.endDate, 'yyyy-MM-dd') + ' 23:59:59'), this.state.zone_id);
      const payload = {
        id: this.state.coupon_id,
        geofence_id: this.state.geofence_id,
        country_id: this.state.country_id,
        publish_date: this.state.publishedDate,
        start_date,
        end_date,
        is_active: this.state.is_active,
        image_master_id: this.state.menuData?.image_master_id,
        type: this.state.type,
        coupon_languages: this.state.coupon_languages,
        zone_id: this.state.zone_id,
      };
      this.props.createCoupon(payload, this.props).then(() => {
        this.setState({isDisableButtonCreate: false});
      });
    }
  }

  /**
   * onChangeTime
   * @param {*} time
   * @param {*} fieldName
   */
  onChangeTime = (time, fieldName) => {
    this.setState({
      [fieldName]: time,
    });
    compareDateRange(this.state.startDate, this.state.endDate);
  };

  /**
   * handleButtonCancel
   */
  handleButtonUpdateCancel() {
    this.setState({
      flgUpdate: false,
    });
  }

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {t, common, actions} = this.props;
    const {coupon_languages} = this.state;
    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
    };

    return (
      <LoadingOverlay active={common.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                {this.state.checkDetail ? <h3>{t('coupon.couponDetail')}</h3> : <h3>{t('coupon.couponRegister')}</h3>}
              </Grid>
            </Grid>
          </Container>
          <br></br>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {this.state.checkDetail ? t('coupon.couponDetail') : t('coupon.couponRegister')}
                    </Grid>
                  </Grid>

                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('common.country')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4} className="grid_title_padding">
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_275">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'country_code',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.country_code ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.country'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.country_code}
                          disabled={!isRoleGlobal()}
                          onChange={(event) => this.handleOnChangeCountry(event.target.value)}
                        >
                          {common?.country_code?.map((item, idx) => (
                            <MenuItem value={item.country_code} key={idx}>
                              {t(`${item.country_code}`)}
                            </MenuItem>
                          ))}
                        </Select>
                        {this.validator.message('country_code', this.state.country_code, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.country_code, 'required') && (
                          <FormHelperText id="country_code" error>
                            {t('validation.required.choose', {field: t('common.country')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Geofence */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('common.geofence')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4} className="grid_title_padding">
                      <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_275">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'geofence_id',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.geofence_id ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('common.geofence'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.geofence_id}
                          onChange={(e) => onChangeSelect(this, e)}
                        >
                          {this.state.listGeofenceAll?.map((item, idx) => (
                            <MenuItem value={item.geofence_id} key={idx} className={!item.enable ? 'disable-option-custom' : ''}>
                              {t(`${item.name}`)}
                            </MenuItem>
                          ))}
                        </Select>
                        {this.validator.message('geofence_id', this.state.geofence_id, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.geofence_id, 'required') && (
                          <FormHelperText id="geofence_id" error>
                            {t('validation.required.choose', {field: t('common.geofence')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Shop name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('coupon.shopName')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid xs={6} lg={2} container alignItems="center" item className="grid_title_padding">
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.ja')})</span>}
                            fullWidth
                            margin="dense"
                            minRows={3}
                            placeholder={t('placeholder.required', {field: t('coupon.shopName')})}
                            multiline
                            variant="outlined"
                            inputProps={{maxLength: 30}}
                            onChange={(event) => this.onChangeShopName(event.target.value, 0)}
                            value={coupon_languages[0]?.shop_name || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 30})}</div>
                        </Box>
                        {this.validator.message('shopNameJa', coupon_languages[0]?.shop_name.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(coupon_languages[0]?.shop_name.trim(), 'required') && (
                          <FormHelperText id="shopNameJa" error>
                            {t('validation.required', {field: `${t('coupon.shopName')} (${t('common.ja')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid xs={6} lg={2} container alignItems="center" item className="grid_title_padding">
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.vi')})</span>}
                            fullWidth
                            margin="dense"
                            minRows={3}
                            placeholder={t('placeholder.required', {field: t('coupon.shopName')})}
                            multiline
                            variant="outlined"
                            inputProps={{maxLength: 30}}
                            onChange={(event) => this.onChangeShopName(event.target.value, 2)}
                            value={coupon_languages[2]?.shop_name || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 30})}</div>
                        </Box>
                        {this.validator.message('shopNameVi', coupon_languages[2]?.shop_name.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(coupon_languages[2]?.shop_name.trim(), 'required') && (
                          <FormHelperText id="shopNameVi" error>
                            {t('validation.required', {field: `${t('coupon.shopName')} (${t('common.vi')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid xs={6} lg={2} container alignItems="center" item className="grid_title_padding">
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.en')})</span>}
                            fullWidth
                            margin="dense"
                            minRows={3}
                            placeholder={t('placeholder.required', {field: t('coupon.shopName')})}
                            multiline
                            variant="outlined"
                            inputProps={{maxLength: 30}}
                            onChange={(event) => this.onChangeShopName(event.target.value, 1)}
                            value={coupon_languages[1]?.shop_name || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 30})}</div>
                        </Box>
                        {this.validator.message('shopNameEn', coupon_languages[1]?.shop_name.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(coupon_languages[1]?.shop_name.trim(), 'required') && (
                          <FormHelperText id="shopName" error>
                            {t('validation.required', {field: `${t('coupon.shopName')} (${t('common.en')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Coupon code */}
                  {this.state.couponCode && (
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                        {t('coupon.couponCode')} <span className="font_color_red">＊</span>
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4} className="grid_title_padding">
                        <FormControl>
                          <TextField
                            name="coupon_code"
                            className="field_size_10 field_min_size_275"
                            margin="dense"
                            placeholder={t('validation.required', {field: t('coupon.couponCode')})}
                            value={this.state.couponCode}
                            inputProps={{maxLength: 256}}
                            variant="outlined"
                            disabled
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}

                  {/* Coupon Name */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('coupon.couponName')} <span className="font_color_red">＊</span>
                    </Grid>

                    <Grid xs={6} lg={2} container alignItems="center" item className="grid_title_padding">
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.ja')})</span>}
                            fullWidth
                            margin="dense"
                            minRows={3}
                            placeholder={t('placeholder.required', {field: t('coupon.couponName')})}
                            multiline
                            variant="outlined"
                            inputProps={{maxLength: 30}}
                            onChange={(event) => this.onChangeCouponName(event.target.value, 0)}
                            value={coupon_languages[0]?.coupon_name || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 30})}</div>
                        </Box>
                        {this.validator.message('couponNameJa', coupon_languages[0]?.coupon_name.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(coupon_languages[0]?.coupon_name.trim(), 'required') && (
                          <FormHelperText id="couponNameJa" error>
                            {t('validation.required', {field: `${t('coupon.couponName')} (${t('common.ja')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid xs={6} lg={2} container alignItems="center" item className="grid_title_padding">
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.vi')})</span>}
                            fullWidth
                            margin="dense"
                            minRows={3}
                            placeholder={t('placeholder.required', {field: t('coupon.couponName')})}
                            multiline
                            variant="outlined"
                            inputProps={{maxLength: 30}}
                            onChange={(event) => this.onChangeCouponName(event.target.value, 2)}
                            value={coupon_languages[2]?.coupon_name || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 30})}</div>
                        </Box>
                        {this.validator.message('couponNameVi', coupon_languages[2]?.coupon_name.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(coupon_languages[2]?.coupon_name.trim(), 'required') && (
                          <FormHelperText id="couponNameVi" error>
                            {t('validation.required', {field: `${t('coupon.couponName')} (${t('common.vi')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid xs={6} lg={2} container alignItems="center" item className="grid_title_padding">
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.en')})</span>}
                            fullWidth
                            margin="dense"
                            minRows={3}
                            placeholder={t('placeholder.required', {field: t('coupon.couponName')})}
                            multiline
                            variant="outlined"
                            inputProps={{maxLength: 30}}
                            onChange={(event) => this.onChangeCouponName(event.target.value, 1)}
                            value={coupon_languages[1]?.coupon_name || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 30})}</div>
                        </Box>
                        {this.validator.message('couponNameEn', coupon_languages[1]?.coupon_name.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(coupon_languages[1]?.coupon_name.trim(), 'required') && (
                          <FormHelperText id="couponNameEn" error>
                            {t('validation.required', {field: `${t('coupon.couponName')} (${t('common.en')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Coupon Type */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('coupon.couponType')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4} className="grid_title_padding">
                      <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_275">
                        <Select
                          margin="dense"
                          inputProps={{
                            name: 'type',
                          }}
                          displayEmpty
                          renderValue={
                            this.state.type ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('coupon.couponType'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.type || ''}
                          onChange={(event) => onChangeSelect(this, event)}
                        >
                          {COUPON_TYPE.map((item, idx) => {
                            return (
                              <MenuItem value={item.value} key={idx}>
                                {t(`${item.i18n}`)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('type', this.state.type, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.type, 'required') && (
                          <FormHelperText id="type" error>
                            {t('validation.required.choose', {field: t('coupon.couponType')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Coupon Image */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      <Grid>
                        {t('coupon.image')} <span className="font_color_red">＊</span>
                        <Grid><i className="font_size_small font_color_red ">({t('coupon.imageNotice')})</i></Grid>
                      </Grid>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={6} className="grid_title_padding">
                      {this.state.menuData.image_master_url && (
                        <div className="box_image" style={{width: 'auto', height: '150px'}}>
                          <img className="cropped_image" style={{width: 'inherit', height: 'inherit'}} src={this.state.menuData.image_master_url} alt="cropped" />
                        </div>
                      )}
                      <Button color="primary" variant="contained" className="button_margin" onClick={this.openImageModal} endIcon={<CloudUploadIcon />}>
                        {t('common.photo')}
                      </Button>
                      <Grid>
                        {this.state.isSubmitForm && !this.state.menuData.image_master_id && (
                          <FormHelperText id="couponImage" error>
                            {t('validation.required.choose', {field: `${t('coupon.image')}`})}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Discount Content */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('coupon.discountContent')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid xs={6} lg={2} container alignItems="center" item className="grid_title_padding">
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.ja')})</span>}
                            fullWidth
                            margin="dense"
                            minRows={3}
                            placeholder={t('placeholder.required', {field: t('coupon.discountContent')})}
                            multiline
                            variant="outlined"
                            inputProps={{maxLength: 30}}
                            onChange={(event) => this.onChangeDiscountContent(event.target.value, 0)}
                            value={coupon_languages[0]?.content || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 30})}</div>
                        </Box>
                        {this.validator.message('discountContentJa', coupon_languages[0]?.content.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(coupon_languages[0]?.content.trim(), 'required') && (
                          <FormHelperText id="discountContentJa" error>
                            {t('validation.required', {field: `${t('coupon.discountContent')} (${t('common.ja')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid xs={6} lg={2} container alignItems="center" item className="grid_title_padding">
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.vi')})</span>}
                            fullWidth
                            margin="dense"
                            minRows={3}
                            placeholder={t('placeholder.required', {field: t('coupon.discountContent')})}
                            multiline
                            variant="outlined"
                            inputProps={{maxLength: 30}}
                            onChange={(event) => this.onChangeDiscountContent(event.target.value, 2)}
                            value={coupon_languages[2]?.content || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 30})}</div>
                        </Box>
                        {this.validator.message('discountContentVi', coupon_languages[2]?.content.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(coupon_languages[2]?.content.trim(), 'required') && (
                          <FormHelperText id="discountContentVi" error>
                            {t('validation.required', {field: `${t('coupon.discountContent')} (${t('common.vi')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid xs={6} lg={2} container alignItems="center" item className="grid_title_padding">
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.en')})</span>}
                            fullWidth
                            margin="dense"
                            minRows={3}
                            placeholder={t('placeholder.required', {field: t('coupon.discountContent')})}
                            multiline
                            variant="outlined"
                            inputProps={{maxLength: 30}}
                            onChange={(event) => this.onChangeDiscountContent(event.target.value, 1)}
                            value={coupon_languages[1]?.content || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 30})}</div>
                        </Box>
                        {this.validator.message('discountContentEn', coupon_languages[1]?.content.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(coupon_languages[1]?.content.trim(), 'required') && (
                          <FormHelperText id="discountContentEn" error>
                            {t('validation.required', {field: `${t('coupon.discountContent')} (${t('common.en')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Description */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('coupon.description')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid xs={6} lg={2} container alignItems="center" item className="grid_title_padding">
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.ja')})</span>}
                            fullWidth
                            margin="dense"
                            minRows={3}
                            placeholder={t('placeholder.required', {field: t('coupon.description')})}
                            multiline
                            variant="outlined"
                            inputProps={{maxLength: 100}}
                            onChange={(event) => this.onChangeDescription(event.target.value, 0)}
                            value={coupon_languages[0]?.description || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                        </Box>
                        {this.validator.message('descriptionJa', coupon_languages[0]?.description.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(coupon_languages[0]?.description.trim(), 'required') && (
                          <FormHelperText id="descriptionJa" error>
                            {t('validation.required', {field: `${t('coupon.description')} (${t('common.ja')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid xs={6} lg={2} container alignItems="center" item className="grid_title_padding">
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.vi')})</span>}
                            fullWidth
                            margin="dense"
                            minRows={3}
                            placeholder={t('placeholder.required', {field: t('coupon.description')})}
                            multiline
                            variant="outlined"
                            inputProps={{maxLength: 100}}
                            onChange={(event) => this.onChangeDescription(event.target.value, 2)}
                            value={coupon_languages[2]?.description || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                        </Box>
                        {this.validator.message('descriptionVi', coupon_languages[2]?.description.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(coupon_languages[2]?.description.trim(), 'required') && (
                          <FormHelperText id="descriptionVi" error>
                            {t('validation.required', {field: `${t('coupon.description')} (${t('common.vi')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid xs={6} lg={2} container alignItems="center" item className="grid_title_padding">
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.en')})</span>}
                            fullWidth
                            margin="dense"
                            minRows={3}
                            placeholder={t('placeholder.required', {field: t('coupon.description')})}
                            multiline
                            variant="outlined"
                            inputProps={{maxLength: 100}}
                            onChange={(event) => this.onChangeDescription(event.target.value, 1)}
                            value={coupon_languages[1]?.description || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                        </Box>
                        {this.validator.message('descriptionEn', coupon_languages[1]?.description.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(coupon_languages[1]?.description.trim(), 'required') && (
                          <FormHelperText id="descriptionEn" error>
                            {t('validation.required', {field: `${t('coupon.description')} (${t('common.en')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Use time (start~end) */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('coupon.useTime')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid alignItems="center" item xs={6} lg={10} className="grid_title_padding">
                      <Grid style={{display: 'flex'}}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid style={{display: 'grid'}}>
                            <KeyboardDatePicker
                              className="field_size_20 field_min_size_275"
                              margin="dense"
                              autoOk
                              disableToolbar
                              variant="inline"
                              inputVariant="outlined"
                              placeholder={t('coupon.useTimeStart')}
                              onChange={(value) => this.onChangeTime(value, 'startDate')}
                              format={DATE_FORMAT}
                              value={this.state.startDate}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                            />
                            {this.validator.message('startDate', this.state.startDate, 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.startDate, 'required') && (
                              <FormHelperText id="startDate" error>
                                {t('validation.required', {field: t('business_plan.start_time')})}
                              </FormHelperText>
                            )}
                          </Grid>
                          <span style={{padding: '5px'}}></span>
                          <Grid style={{display: 'grid'}}>
                            <KeyboardDatePicker
                              className="field_size_20 field_min_size_275"
                              margin="dense"
                              autoOk
                              disableToolbar
                              variant="inline"
                              placeholder={t('coupon.useTimeEnd')}
                              inputVariant="outlined"
                              format={DATE_FORMAT}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                              value={this.state.endDate}
                              onChange={(time) => this.onChangeTime(time, 'endDate')}
                            />
                            {this.validator.message('endDate', this.state.endDate, 'required')}
                            {this.state.isSubmitForm && !this.validator.check(this.state.endDate, 'required') && (
                              <FormHelperText id="endDate" error>
                                {t('validation.required', {field: t('business_plan.end_time')})}
                              </FormHelperText>
                            )}
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid>
                        {this.state.isSubmitForm && !compareDateRange(this.state.startDate, this.state.endDate) && (
                          <FormHelperText id="start_time" error>
                            {t('validation.invalid.timeRange')}
                          </FormHelperText>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Nearest Waypoint */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('coupon.nearestWaypoint')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid xs={6} lg={2} container alignItems="center" item className="grid_title_padding">
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.ja')})</span>}
                            fullWidth
                            margin="dense"
                            minRows={3}
                            placeholder={t('placeholder.required', {field: t('coupon.nearestWaypoint')})}
                            multiline
                            variant="outlined"
                            inputProps={{maxLength: 100}}
                            onChange={(event) => this.onChangeNearestWaypoint(event.target.value, 0)}
                            value={coupon_languages[0]?.waypoint_nearest || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                        </Box>
                        {this.validator.message('nearestWaypointJa', coupon_languages[0]?.waypoint_nearest.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(coupon_languages[0]?.waypoint_nearest.trim(), 'required') && (
                          <FormHelperText id="nearestWaypointJa" error>
                            {t('validation.required', {field: `${t('coupon.nearestWaypoint')} (${t('common.ja')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid xs={6} lg={2} container alignItems="center" item className="grid_title_padding">
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.vi')})</span>}
                            fullWidth
                            margin="dense"
                            minRows={3}
                            placeholder={t('placeholder.required', {field: t('coupon.nearestWaypoint')})}
                            multiline
                            variant="outlined"
                            inputProps={{maxLength: 100}}
                            onChange={(event) => this.onChangeNearestWaypoint(event.target.value, 2)}
                            value={coupon_languages[2]?.waypoint_nearest || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                        </Box>
                        {this.validator.message('nearestWaypointVi', coupon_languages[2]?.waypoint_nearest.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(coupon_languages[2]?.waypoint_nearest.trim(), 'required') && (
                          <FormHelperText id="nearestWaypointVi" error>
                            {t('validation.required', {field: `${t('coupon.nearestWaypoint')} (${t('common.vi')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid container alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon"></CompareArrowsIcon>
                    </Grid>
                    <Grid xs={6} lg={2} container alignItems="center" item className="grid_title_padding">
                      <FormControl fullWidth>
                        <Box display="flex" flexDirection="column">
                          <TextField
                            label={<span>({t('common.en')})</span>}
                            fullWidth
                            margin="dense"
                            minRows={3}
                            placeholder={t('placeholder.required', {field: t('coupon.nearestWaypoint')})}
                            multiline
                            variant="outlined"
                            inputProps={{maxLength: 100}}
                            onChange={(event) => this.onChangeNearestWaypoint(event.target.value, 1)}
                            value={coupon_languages[1]?.waypoint_nearest || ''}
                          />
                          <div style={{fontSize: 14, marginBottom: 5}}>{t('validation.max.label', {value: 100})}</div>
                        </Box>
                        {this.validator.message('nearestWaypointEn', coupon_languages[1]?.waypoint_nearest.trim(), 'required')}
                        {this.state.isSubmitForm && !this.validator.check(coupon_languages[1]?.waypoint_nearest.trim(), 'required') && (
                          <FormHelperText id="nearestWaypointEn" error>
                            {t('validation.required', {field: `${t('coupon.nearestWaypoint')} (${t('common.en')})`})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Display on app */}
                  <Grid container spacing={1} className="row_form_item">
                    <Grid container alignItems="center" item xs={3} lg={2} className="grid_title_padding">
                      {t('coupon.displayOnApp')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4} className="grid_title_padding">
                      <Switch
                        checked={this.state.is_active}
                        onChange={(event) => this.setState({is_active: event.target.checked})}
                        name="displayOnApp"
                        inputProps={{'aria-label': 'secondary checkbox'}}
                      />
                    </Grid>
                  </Grid>
                </Paper>
                <br></br>
                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {!this.state.checkDetail ? (
                      <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={() => this.handleCreate()}>
                        {t('common.btnRegister')}
                      </Button>
                    ) : (
                      permission.canUpdate && (
                        <Button color="primary" variant="contained" className="button_margin" onClick={this.handleUpdate} endIcon={<CloudUploadIcon />}>
                          {t('common.btnUpdate')}
                        </Button>
                      )
                    )}
                    <Button color="primary" variant="contained" className="button_magin button_color" style={{marginLeft: 5}} onClick={this.props.history.goBack}>
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                </Grid>
                <Container maxWidth="xl">
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.isOpenModal}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      zIndex: '1000',
                    }}
                  >
                    <>
                      <ImageSelect
                        onClickOk={this.handleButtonOkImageModal}
                        onClickClose={this.handleButtonCloseImageModal}
                        selectImage={(value) => this.handleSelectImage(value)}
                      ></ImageSelect>
                    </>
                  </Modal>
                </Container>
                <Dialog
                  open={this.state.flgUpdate}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    okButtonText={t('common.btnOk')}
                    onClickOk={this.handleButtonUpdateOk}
                    onClickCancel={this.handleButtonUpdateCancel}
                    message={this.state.message}
                    isDisableCreate={this.state.isDisableButtonCreate}
                  ></SelectModal>
                </Dialog>
              </Container>
              <br></br>
            </Card>
            <br></br>
          </Container>
          <br></br>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getAllGeofence: () => dispatch(getAllGeofence()),
    createCoupon: (payload, props) => dispatch(createCoupon(payload, props)),
    updateCoupon: (payload, props) => dispatch(updateCoupon(payload, props)),
    getDetailCoupon: (id, props) => dispatch(getDetailCoupon(id, props)),
  };
};
export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(CouponForm)));
