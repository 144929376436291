export const ACTION_TYPES = {
  SEARCH_LIST_COMPANY_START: 'SEARCH_LIST_COMPANY_START',
  SEARCH_LIST_COMPANY_SUCCESS: 'SEARCH_LIST_COMPANY_SUCCESS',
  SEARCH_LIST_COMPANY_FAIL: 'SEARCH_LIST_COMPANY_FAIL',

  CREATE_COMPANY_START: 'CREATE_COMPANY_START',
  CREATE_COMPANY_SUCCESS: 'CREATE_COMPANY_SUCCESS',
  CREATE_COMPANY_FAIL: 'CREATE_COMPANY_FAIL',

  GET_DETAIL_COMPANY_START: 'GET_DETAIL_COMPANY_START',
  GET_DETAIL_COMPANY_SUCCESS: 'GET_DETAIL_COMPANY_SUCCESS',
  GET_DETAIL_COMPANY_FAIL: 'GET_DETAIL_COMPANY_FAIL',

  UPDATE_COMPANY_START: 'UPDATE_COMPANY_START',
  UPDATE_COMPANY_SUCCESS: 'UPDATE_COMPANY_SUCCESS',
  UPDATE_COMPANY_FAIL: 'UPDATE_COMPANY_FAIL',

  DELETE_COMPANY_START: 'DELETE_COMPANY_START',
  DELETE_COMPANY_SUCCESS: 'DELETE_COMPANY_SUCCESS',
  DELETE_COMPANY_FAIL: 'DELETE_COMPANY_FAIL',

  IMPORT_COMPANY_MEMBER_LIST_CSV_START: 'IMPORT_COMPANY_MEMBER_LIST_CSV_START',
  IMPORT_COMPANY_MEMBER_LIST_CSV_SUCCESS: 'IMPORT_COMPANY_MEMBER_LIST_CSV_SUCCESS',
  IMPORT_COMPANY_MEMBER_LIST_CSV_FAIL: 'IMPORT_COMPANY_MEMBER_LIST_CSV_FAIL',
};

const initialState = {
  isLoading: false,
  listCompany: [],
  detailCompany: {},
  memberList: [],
};

/**
 * function reduce change password
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.SEARCH_LIST_COMPANY_START:
    case ACTION_TYPES.CREATE_COMPANY_START:
    case ACTION_TYPES.GET_DETAIL_COMPANY_START:
    case ACTION_TYPES.UPDATE_COMPANY_START:
    case ACTION_TYPES.DELETE_COMPANY_START:
    case ACTION_TYPES.IMPORT_COMPANY_MEMBER_LIST_CSV_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.SEARCH_LIST_COMPANY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listCompany: action.listCompany,
      };
    case ACTION_TYPES.GET_DETAIL_COMPANY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        detailCompany: action.detailCompany,
      };
    case ACTION_TYPES.IMPORT_COMPANY_MEMBER_LIST_CSV_SUCCESS:
      return {
        ...state,
        isLoading: false,
        memberList: action.result,
      };
    case ACTION_TYPES.SEARCH_LIST_COMPANY_FAIL:
    case ACTION_TYPES.CREATE_COMPANY_FAIL:
    case ACTION_TYPES.GET_DETAIL_COMPANY_FAIL:
    case ACTION_TYPES.UPDATE_COMPANY_FAIL:
    case ACTION_TYPES.DELETE_COMPANY_FAIL:
    case ACTION_TYPES.IMPORT_COMPANY_MEMBER_LIST_CSV_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
