import React, {Component} from 'react';

import 'cropperjs/dist/cropper.css';
import {Fade, Paper, Container, Grid, Button} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Cropper from 'react-cropper';
import {withTranslation} from 'react-i18next';

import {b64toBlob} from '../../utils/common';
import './style.css';

/**
 * Image resize component
 */
class Index extends Component {
  /**
   * constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      cropFile: null,
      cropUrl: null,
    };
    this.handleClickClose = this.handleClickClose.bind(this);
    this.handleClickOk = this.handleClickOk.bind(this);
  }

  /**
   * cropImage
   */
  cropImage = () => {
    if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
      return;
    }
    this.setState({
      cropUrl: this.cropper.getCroppedCanvas().toDataURL(),
      cropFile: b64toBlob(this.cropper.getCroppedCanvas().toDataURL()),
    });
  };

  /**
   * init ref for cropper component
   * @param {ref} cropper
   */
  onCropperInit(cropper) {
    this.cropper = cropper;
  }

  /**
   * handleClickOk
   */
  handleClickOk() {
    this.props.selectedImage({crop: this.state.cropFile, cropUrl: this.state.cropUrl});
    this.props.onClickOk();
  }

  /**
   * handleClickClose
   */
  handleClickClose() {
    this.props.onClickClose();
  }

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t} = this.props;

    return (
      <Fade in={true} style={{overflow: 'auto'}}>
        <Paper className="modal_size_mid">
          <Container maxWidth="xl">
            <br></br>
            <Grid container alignItems="center" spacing={1}>
              <Grid container alignItems="center" className="page_header" item xs={12}>
                <span className="font_bold font_size_big">{t('businessImage.titleCrop')}</span>
              </Grid>
              <Grid container alignItems="center" spacing={1}>
                <Grid container alignItems="center" item xs={6} style={{paddingTop: 10}}>
                  <span className="font_bold font_size_big">{t('businessImage.crop.upload')}</span>
                </Grid>
                <Grid container alignItems="center" item xs={6} style={{paddingTop: 10}}>
                  <span className="font_bold font_size_big">{t('businessImage.crop.preview')}</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justify="center" alignItems="center" spacing={1}>
              <Grid container justify="center" alignItems="center" item xs={12} lg={6}>
                <Cropper
                  style={{height: 300, border: '1px solid #f1ecec'}}
                  initialAspectRatio={1}
                  zoomTo={0.5}
                  guides={true}
                  src={this.props.imageSrc}
                  onInitialized={this.onCropperInit.bind(this)}
                  viewMode={1}
                  dragMode="move"
                  checkCrossOrigin={false}
                />
              </Grid>
              <Grid container justify="center" alignItems="center" item xs={12} lg={6}>
                <div className="box-image" style={{width: '100%', height: '300px'}}>
                  {this.state.cropUrl && (
                    <img
                      className="cropped-image"
                      style={{width: 'inherit', height: 'inherit'}}
                      src={this.state.cropUrl}
                      alt="cropped"
                    />
                  )}
                </div>
              </Grid>
            </Grid>
            <Grid container justify="center" alignItems="center" spacing={1}>
              <Grid container justify="center" alignItems="center" item xs={4}>
                <Button color="primary" variant="contained" className="button_margin" onClick={this.cropImage}>
                  {t('businessImage.crop.button')}
                </Button>
              </Grid>
            </Grid>
            <Grid container justify="center" alignItems="center" spacing={1}>
              <Grid container justify="flex-end" alignItems="center" item xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  className="button_margin"
                  onClick={this.handleClickOk}
                  endIcon={<CheckIcon />}
                >
                  {t('common.btnUpload')}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  className="button_margin button_color"
                  onClick={this.handleClickClose}
                  endIcon={<CloseIcon />}
                >
                  {t('common.btnCancel')}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Paper>
      </Fade>
    );
  }
}

export default withTranslation('translations')(Index);
