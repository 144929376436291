import {api} from '../services/api';

const simulationUrl = 'mass/v1/simconfig';
const simulationAdminUrl = 'admin/mass/v1/simconfig';
const cloneSimulationUrl = 'mass/public/clone-simulation/';
const cloneSimulationV2Url = 'mass/v1/simulation/clone/';
const getServiceGroupUrl = 'mass/v1/servicegroup';
const getWillerStopSetUrl = 'mass/v1/stopset';
const validateStopSetUrl = 'admin/waypoint/validate/';
const checkSimulationSwatUrl = 'mass/swat/simulation/{id}';

export const searchSimulationApi = (payload) => {
  return api.get(simulationUrl, payload);
};

export const getSimulationDetailApi = (id) => {
  return api.get(`${simulationUrl}/${id}`);
};

export const enableSimulationApi = (payload) => {
  return api.post(simulationAdminUrl, payload);
};

export const cloneSimulationApi = (payload, key) => {
  return api.post(cloneSimulationUrl + key, payload);
};

/**
 * @param {Number} simulationId
 * @return {Promise}
 */
export const cloneSimulationV2Api = (simulationId) => {
  return api.post(`${cloneSimulationV2Url}${simulationId}`, {});
};

export const getServiceGroupApi = (pageable) => {
  return api.get(getServiceGroupUrl, pageable);
};

export const deleteSimulationApi = (id) => {
  return api.delete(`${simulationAdminUrl}/${id}`, {});
};

export const getWillerStopSetApi = (queryParams) => {
  return api.get(getWillerStopSetUrl, queryParams);
};

export const validateStopSetApi = (stopSetId, file) => {
  return api.postDownloadAttachment(validateStopSetUrl + stopSetId, file);
};

export const checkSimulationSwatApi = (id) => {
  return api.get(checkSimulationSwatUrl.replace('{id}', id));
};

export const applySimulationApi = (id) => {
  return api.post(simulationUrl + `/${id}/apply`, {});
};
