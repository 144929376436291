import React, {Component} from 'react';

import {Button, Card, Container, FormControl, Grid, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DetailIcon from '@material-ui/icons/Description';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {DISPLAY_MENU_APP, ROUTE} from '../../../../common/constant';
import withPermissionGateway from '../../../../hoc/withPermissionGateway';
import {searchMenuAppGeofenceRegisteredApi} from '../../../../services/commonSettingServices';
import {getAllCountryCode} from '../../../../stores/common/actions';
import {changeUrlParams, getQueryStringFromObject, getUrlParams, onChangeSelect} from '../../../../utils/common';

/**
 * Driver Search component
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      country_id: '',
      status: '',
      geofence_id: '',
      isSearch: false,
      geofenceMenuAppList: [],
      geofenceSearchList: [],
      listGeofenceAll: [],
      message: '',
      searchParams: this.props.location.search,
      latestSearchParams: null,
    };
  }

  /**
   * componentDitMount
   *
   */
  componentDidMount = async () => {
    const countryId = this.props.match.params.id;
    this.props.getAllCountryCode();
    if (countryId) {
      const payload = {
        countryId,
        isRegistered: true,
      };
      searchMenuAppGeofenceRegisteredApi(payload).then((response) => {
        if (response) {
          const geofenceSearchList = response?.result;
          this.setState({country_id: countryId, geofenceSearchList});
        }
      });
      if (this.state.searchParams) {
        this.setState(getUrlParams(this.state.searchParams), () => {
          this.handleSearch(countryId);
        });
      }
    }
  };

  /**
   * handleSearch
   * @param {number} countryId
   */
  handleSearch(countryId) {
    const {geofence_id, status} = this.state;
    const payload = {
      geofenceId: geofence_id,
      countryId,
      isDisplayed: status ? (status === 'DISPLAY' ? true : false) : status,
      isRegistered: true,
      targetScreen: 'ENABLE_FOR_ADMIN_ALL',
    };
    Object.keys(payload).forEach((prop) => {
      if (!payload[prop] && payload[prop] !== false) {
        delete payload[prop];
      }
    });
    searchMenuAppGeofenceRegisteredApi(payload).then((response) => {
      if (response) {
        const geofenceMenuAppList = response?.result;
        this.setState(
          {
            geofenceMenuAppList,
            isSearch: true,
            latestSearchParams: payload,
          },
          () => {
            // Apply changed params into url
            const queryParamsToChange = {
              status,
              geofence_id,
              isRegistered: true,
            };
            changeUrlParams(queryParamsToChange);
            const newSearchParams = getQueryStringFromObject(queryParamsToChange);
            // Save search params into state in order to pass to next page
            this.setState({searchParams: newSearchParams});
          },
        );
      }
    });
  }

  /**
   * reset
   */
  reset = () => {
    this.setState({
      geofence_id: '',
      status: '',
    });
  };

  /**
   * Render component
   * @return {component}
   */
  render() {
    const {common, t} = this.props;
    const {geofenceMenuAppList, country_id, geofence_id, geofenceSearchList} = this.state;
    const permission = {
      canSearch: 'SEARCH',
      canEdit: 'EDIT',
      canRegister: 'REGISTER',
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <Grid container className="page_header">
            <Grid container alignItems="center" item xs={6}>
              <h3>{t('route.menuAppDetail')}</h3>
            </Grid>
            <Grid container alignItems="center" justify="flex-end" item xs={6}>
              <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.MENU_APP_MANAGEMENT}}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                  {t('common.btnReturn')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
          <LoadingOverlay active={false} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <Card raised>
              <Container maxWidth="xl">
                <>
                  <Paper>
                    <Grid container spacing={1} className="row_form_item">
                      <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                        {t('businessInformation.searchTitle')}
                      </Grid>
                    </Grid>

                    {/* Country */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('common.country')}
                      </Grid>
                      <Grid container alignItems="center" item xs={12} sm={8} md={10}>
                        <FormControl variant="outlined" margin="dense" className="responsive_min_size">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'country_id',
                            }}
                            displayEmpty
                            renderValue={
                              country_id ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('common.country'),
                                      })}
                                    </div>
                                  )
                            }
                            value={country_id}
                            disabled={true}
                          >
                            {common?.country_code?.map((item, idx) => {
                              return (
                                <MenuItem value={item.id} key={idx}>
                                  {t(`${item.country_code}`)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* Geofence */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('common.geofence')}
                      </Grid>
                      <Grid container alignItems="center" item xs={12} sm={8} md={10}>
                        <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_250">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'geofence_id',
                            }}
                            displayEmpty
                            renderValue={
                              geofence_id ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('common.geofence'),
                                      })}
                                    </div>
                                  )
                            }
                            value={geofence_id}
                            onChange={(e) => onChangeSelect(this, e)}
                          >
                            {geofenceSearchList?.map((item, idx) => {
                              return (
                                <MenuItem value={item.geofence_id} key={idx}>
                                  {t(`${item.geofence_name}`)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>

                    {/* Status */}
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('common.status')}
                      </Grid>
                      <Grid container alignItems="center" item xs={12} sm={8} md={10}>
                        <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_250">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'status',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.status ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('common.status'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.status}
                            onChange={(e) => onChangeSelect(this, e)}
                          >
                            {DISPLAY_MENU_APP?.map((item, idx) => {
                              return (
                                <MenuItem value={item.id} key={idx}>
                                  {t(`${item.i18n}`)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Paper>
                </>
                <br></br>

                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}>
                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.reset()} endIcon={<RotateLeftIcon />}>
                        {t('common.btnResetSearch')}
                      </Button>
                    )}
                  </Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {permission.canRegister && (
                      <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.MENU_APP_GEOFENCE_CREATE + '/' + country_id}}>
                        <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />}>
                          {t('common.btnRegister')}
                        </Button>
                      </Link>
                    )}

                    {permission.canSearch && (
                      <Button color="primary" variant="contained" className="button_margin" onClick={() => this.handleSearch(country_id)} endIcon={<SearchIcon />}>
                        {t('common.btnSearch')}
                      </Button>
                    )}
                  </Grid>
                </Grid>

                <br></br>
              </Container>
            </Card>
          </LoadingOverlay>
          <br></br>
          {permission.canSearch && this.state.isSearch && (
            <>
              <Card raised>
                <Container maxWidth="xl">
                  <Grid container spacing={1}>
                    <Grid container alignItems="center" item xs={6}>
                      <h3>
                        {t('common.searchResult')} {geofenceMenuAppList?.length ? geofenceMenuAppList?.length : 0} {t('common.case')}
                      </h3>
                    </Grid>
                  </Grid>
                  <LoadingOverlay active={false} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                    {geofenceMenuAppList?.length > 0 && (
                      <div className="scroll_area_700">
                        <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                          <TableHead>
                            <TableRow>
                              <TableCell className="width_200p">{t('common.geofence')}</TableCell>
                              <TableCell className="width_200p">{t('common.status')}</TableCell>
                              {permission.canEdit && (
                                <TableCell className="width_200p" style={{position: 'sticky', right: '0'}} colSpan={3}>
                                  {t('common.action')}
                                </TableCell>
                              )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {geofenceMenuAppList.map((row, index) => (
                              <TableRow key={index} hover className="cursor_pointer">
                                <TableCell className="cell_fixed_left">{row.geofence_name}</TableCell>
                                <TableCell>{t(DISPLAY_MENU_APP.find((item) => item.id === (row.displayed ? 'DISPLAY' : 'NOT_DISPLAY'))?.i18n)}</TableCell>
                                {permission.canEdit && (
                                  <TableCell className="cell_fixed_right" colSpan={3}>
                                    {permission.canEdit && (
                                      <Link
                                        style={{textDecoration: 'none'}}
                                        to={{
                                          pathname: ROUTE.LAYOUT + ROUTE.MENU_APP_GEOFENCE_DETAIL + `/${country_id}/${row.geofence_id}`,
                                          state: {from: this.props.location.pathname + '?' + this.state.searchParams},
                                        }}
                                      >
                                        <Button color="primary" variant="contained" className="button_margin " endIcon={<DetailIcon />}>
                                          {t('common.btnEdit')}
                                        </Button>
                                      </Link>
                                    )}
                                  </TableCell>
                                )}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    )}
                  </LoadingOverlay>

                  <br></br>
                </Container>
              </Card>
              <br></br>
            </>
          )}
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
