import React, {Component} from 'react';

import {
  Button,
  Card,
  Checkbox,
  Container,
  Dialog,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DetailIcon from '@material-ui/icons/Description';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import SearchIcon from '@material-ui/icons/Search';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {ROUTE, GEOFENCE_STATUS, ROWS_PER_PAGE_OPTIONS, DATA_NULL, MAX_SIZE_OUTPUT} from '../../../common/constant';
import CustomPagination from '../../../components/CustomPagination';
import DisplayMap from '../../../components/map/DisplayMap';
import {getAllCountryCode} from '../../../stores/common/actions';
import {getAllGeofences, updateGeofence} from '../../../stores/geofence/action';
import {getServiceGroupList} from '../../../stores/simulation/actions';
import {clearBlankValue, getQueryStringFromObject, getUrlParams, onChangeSelect, onChangeTextField} from '../../../utils/common';
import withPermissionData from '../../../withPermissionData/hoc';

const DEFAULT_MAP_COLOR = 'red';

/**
 * GeofenceManagement component
 */
class GeofenceManagement extends Component {
  /**
   * constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      countryId: '',
      simulationId: '',
      name: '',
      description: '',
      serviceGroupId: '',
      enable: '',
      page: 0,
      size: ROWS_PER_PAGE_OPTIONS[0],

      showDialog: false,
      currentGeofence: null,
    };
  }

  /**
   * initial state
   */
  async componentDidMount() {
    await this.props.getAllCountryCode();
    await this.props.getServiceGroupList({size: MAX_SIZE_OUTPUT});
    if (this.props.location.search) {
      let queryObject = getUrlParams(this.props.location.search);
      queryObject = {
        ...queryObject,
        targetScreen: 'ENABLE_FOR_ADMIN_ALL',
      };
      this.props.getAllGeofences(queryObject);
      this.setState({...queryObject});
    }
  }

  /**
   * handlePageChange
   * @param {number} page
   * @param {number} size
   */
  handlePageChange = (page, size) => {
    this.setState({page, size}, this.fetchGeofence);
  };

  /**
   * call api fetch geofence
   * @param {Boolean} reset
   */
  fetchGeofence = (reset) => {
    const {countryId, simulationId, name, description, serviceGroupId, enable, page, size} = this.state;
    let search = clearBlankValue({countryId, simulationId, name, description, serviceGroupId, enable, page, size});
    reset && (search = {
      ...search,
      size: ROWS_PER_PAGE_OPTIONS[0],
      page: 0,
    });
    search = {
      ...search,
      targetScreen: 'ENABLE_FOR_ADMIN_ALL',
    };
    this.props.history.replace({
      pathname: '',
      search: getQueryStringFromObject(search),
    });
    this.props.getAllGeofences(search);
  };

  /**
   * open confirm dialog
   * @param {Object} geofence
   */
  openDialog = (geofence) => {
    if (geofence?.bg_color?.length === 9) {
      geofence.bg_color = '#' + geofence.bg_color.substr(3);
    }
    this.setState({
      showDialog: true,
      currentGeofence: geofence,
    });
  };

  /**
   * handleEnable
   * @param {*} value
   * @param {*} geofence
   */
  handleEnable = (value, geofence) => {
    const updatePayload = {...geofence};
    updatePayload.enable = value;
    updatePayload.country_id = geofence.country?.id;
    this.props.updateGeofence(updatePayload).then((response) => {
      if (response) {
        geofence.enable = value;
        this.setState((state) => ({...state}));
      }
    });
  };

  /**
   * handle search
   * @param {Event} evt
   * @param {Boolean} reset
   */
  handleSearch = (evt, reset = false) => {
    evt.preventDefault();
    this.fetchGeofence(reset);
  };

  /**
   * reset search condition
   */
  resetSearchCondition = () => {
    this.setState({
      countryId: '',
      simulationId: '',
      name: '',
      description: '',
      serviceGroupId: '',
      enable: '',
      page: 0,
      size: 10,
    });
  };

  /**
   * render component
   * @return {HTML}
   */
  render() {
    const {t, common} = this.props;
    const {listGeofence, isLoading} = this.props.geofence;
    const {page_number, totalSize, size, content} = listGeofence || {};

    return (
      <>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('geofence.title')}</h3>
              </Grid>
              <Grid container alignItems="center" justify="flex-end" item xs={6}>
                <Link style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.DATA_MANAGEMENT}}>
                  <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />}>
                    {t('common.btnReturn')}
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <br></br>
            <LoadingOverlay active={isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
              {/* --------------------- SEARCH -------------------- */}
              <form onSubmit={(evt) => this.handleSearch(evt, true)} autoComplete="off">
                <Card raised>
                  <Container maxWidth="xl">
                    <br></br>
                    <Paper className="search_table">
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                          {t('businessVehicle.searchTitle')}
                        </Grid>
                      </Grid>

                      {/* Country */}
                      <Grid container spacing={1} className="row_form_item ">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.country')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              margin="dense"
                              inputProps={{
                                name: 'countryId',
                              }}
                              displayEmpty
                              renderValue={
                                this.state.countryId ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('common.country'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.countryId}
                              onChange={(event) => onChangeSelect(this, event)}
                            >
                              {common?.country_code?.map((item, idx) => (
                                <MenuItem value={item.id} key={idx}>
                                  {t(`${item.country_code}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* simulation id */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('geofence.simulation_id')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <TextField
                              name="simulationId"
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              placeholder={t('geofence.simulation_id')}
                              inputProps={{maxLength: 256}}
                              value={this.state.simulationId}
                              onChange={(event) => onChangeTextField(this, event)}
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* services group id */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('geofence.service_group_id')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              margin='dense'
                              variant='outlined'
                              displayEmpty
                              renderValue={
                                this.state.serviceGroupId ?
                                  undefined :
                                  () => <div className='font-12 color-disabled'>{t('placeholder.required', {field: t('geofence.service_group_id')})}</div>
                              }
                              name='serviceGroupId'
                              value={this.state.serviceGroupId}
                              onChange={(event) => onChangeSelect(this, event)}
                            >
                              {this.props.serviceGroupList?.map((item) => (
                                <MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* name */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('geofence.name')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <TextField
                              name="name"
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              placeholder={t('placeholder.required', {field: t('geofence.name')})}
                              inputProps={{maxLength: 256}}
                              value={this.state.name}
                              onChange={(event) => onChangeTextField(this, event)}
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* description */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('geofence.description')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <TextField
                              name="description"
                              className="field_size_20 field_min_size_300"
                              margin="dense"
                              placeholder={t('placeholder.required', {field: t('geofence.description')})}
                              inputProps={{maxLength: 256}}
                              value={this.state.description}
                              onChange={(event) => onChangeTextField(this, event)}
                              variant="outlined"
                            />
                          </FormControl>
                        </Grid>
                      </Grid>

                      {/* enabled */}
                      <Grid container spacing={1} className="row_form_item">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('geofence.status')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl variant="outlined" margin="dense" className="field_size_20 field_min_size_300">
                            <Select
                              name="enable"
                              variant="outlined"
                              margin="dense"
                              className="field_size_20 field_min_size_300"
                              displayEmpty
                              renderValue={
                                this.state.enable || this.state.enable === false ?
                                  undefined :
                                  () => (
                                      <div className="font-12 color-disabled">
                                        {t('placeholder.required_select', {
                                          field: t('geofence.status'),
                                        })}
                                      </div>
                                    )
                              }
                              value={this.state.enable}
                              onChange={(event) => onChangeSelect(this, event)}
                            >
                              {GEOFENCE_STATUS.map(({id, i18n}) => (
                                <MenuItem value={id} key={id}>
                                  {id !== '' ? t(i18n) : <em>{t(i18n)}</em>}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Paper>
                    <br />

                    <Grid container spacing={1}>
                      <Grid container alignItems="center" justify="flex-start" item xs={3}>
                        <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.resetSearchCondition} endIcon={<RotateLeftIcon />}>
                          {t('common.btnResetSearch')}
                        </Button>
                      </Grid>
                      <Grid container alignItems="center" justify="flex-end" item xs={9}>
                        <Link to={ROUTE.LAYOUT + ROUTE.GEOFENCE_CREATE} style={{textDecoration: 'none'}}>
                          <Button color="primary" variant="contained" className="button_margin button_color_green" endIcon={<AddIcon />} onClick={this.openDialog}>
                            {t('common.btnRegister')}
                          </Button>
                        </Link>
                        <Button color="primary" variant="contained" className="button_margin" type="submit" endIcon={<SearchIcon />}>
                          {t('common.btnSearch')}
                        </Button>
                      </Grid>
                    </Grid>
                    <br />
                  </Container>
                </Card>
              </form>
              {/* --------------------- END SEARCH -------------------- */}

              {content && (
                <>
                  <br></br>
                  <Card raised>
                    <Container maxWidth="xl">
                      <Grid container spacing={1}>
                        <Grid container alignItems="center" item xs={12}>
                          <h3>
                            {t('common.searchResult')} {totalSize} {t('common.case')}
                          </h3>
                        </Grid>
                      </Grid>
                      {content?.length > 0 && (
                        <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
                          <TableHead>
                            <TableRow>
                              <TableCell className="width_50p">{t('common.enable')}</TableCell>
                              <TableCell className="width_250p ant-table-cell-fix-left">{t('geofence.geofence_id')}</TableCell>
                              <TableCell className="width_200p">{t('geofence.name')}</TableCell>
                              <TableCell className="width_100p">{t('geofence.jit_home_to_work_sim_id')}</TableCell>
                              <TableCell className="width_100p">{t('geofence.jit_work_to_home_sim_id')}</TableCell>
                              <TableCell className="width_100p">{t('geofence.on_demand_sim_id')}</TableCell>
                              <TableCell className="width_100p">{t('geofence.simulation_taxi')}</TableCell>
                              <TableCell className="width_100p">{t('geofence.unitrand_sim_id')}</TableCell>
                              <TableCell className="width_100p">{t('geofence.service_group_id')}</TableCell>
                              <TableCell className="width_100p">{t('serviceGroup.project_id')}</TableCell>
                              <TableCell className="width_50p">{t('common.action')}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {content?.map((row, id) => (
                              <TableRow scope="row" key={id} hover>
                                <TableCell>
                                  <Checkbox checked={row.enable} onChange={(evt) => this.handleEnable(evt.target.checked, row)} />
                                </TableCell>
                                <TableCell className="cursor_pointer" onClick={() => this.openDialog(row)}>
                                  {row.geofence_id}
                                </TableCell>
                                <TableCell className="cursor_pointer" onClick={() => this.openDialog(row)}>
                                  {row.name || DATA_NULL}
                                </TableCell>
                                <TableCell>
                                  {row.jit_home_to_work_sim_id ? (<Link to={`/maas/simulation/detail/${row.jit_h2w_sim_cfg_id}`}>{row.jit_home_to_work_sim_id}</Link>) : DATA_NULL}
                                </TableCell>
                                <TableCell>
                                  {row.jit_work_to_home_sim_id ? (<Link to={`/maas/simulation/detail/${row.jit_w2h_sim_cfg_id}`}>{row.jit_work_to_home_sim_id}</Link>) : DATA_NULL}
                                </TableCell>
                                <TableCell>
                                  {row.on_demand_sim_id ? (<Link to={`/maas/simulation/detail/${row.odm_sim_cfg_id}`}>{row.on_demand_sim_id}</Link>) : DATA_NULL}
                                </TableCell>
                                <TableCell>
                                  {row.simulation_taxi ? (<Link to={`/maas/simulation/detail/${row.taxi_sim_cfg_id}`}>{row.simulation_taxi}</Link>) : DATA_NULL}
                                </TableCell>
                                <TableCell>
                                  {row.unitrand_sim_id ? (<Link to={`/maas/simulation/detail/${row.unitrand_sim_cfg_id}`}>{row.unitrand_sim_id}</Link>) : DATA_NULL}
                                </TableCell>
                                <TableCell>
                                  <Link to={ROUTE.LAYOUT + ROUTE.SERVICE_GROUP_UPDATE + `/${row.service_group_id}`}>
                                    {row.service_group_id || DATA_NULL}
                                  </Link>
                                </TableCell>
                                <TableCell>
                                  {row?.project_id || DATA_NULL}
                                </TableCell>
                                <TableCell>
                                  <Link to={ROUTE.LAYOUT + ROUTE.GEOFENCE_MANAGEMENT_DETAIL + `/${row.geofence_id}`} style={{textDecoration: 'none'}}>
                                    <Button color="primary" size="small" variant="contained" className="button_margin" endIcon={<DetailIcon />}>
                                      {t('common.btnEdit')}
                                    </Button>
                                  </Link>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      )}
                      <CustomPagination onChange={this.handlePageChange} rows={totalSize} rowsPerPage={size} currentPage={page_number}></CustomPagination>
                    </Container>
                  </Card>
                </>
              )}
              <br />
            </LoadingOverlay>
          </Container>
        </Card>
        <Dialog
          onClose={() => {
            this.setState({showDialog: false});
          }}
          open={this.state.showDialog}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <DisplayMap
            geometry={this.state.currentGeofence?.geometry}
            color={this.state.currentGeofence?.bg_color || DEFAULT_MAP_COLOR}
            centroid={this.state.currentGeofence?.centroid}
            height={600}
          />
        </Dialog>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    geofence: state.geofence,
    common: state.common,
    serviceGroupList: state.simulation.serviceGroupList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllGeofences: (pageable) => dispatch(getAllGeofences(pageable)),
    updateGeofence: (updatePayload) => dispatch(updateGeofence(updatePayload)),
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    getServiceGroupList: (pageable) => dispatch(getServiceGroupList(pageable)),
  };
};

export default withPermissionData(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(GeofenceManagement)));
