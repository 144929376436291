import {api} from '../services/api';

const crudUrl = `/admin/companies/`;
const getListCompanyUrl = `/admin/companies/search`;
const getDetailCompanyUrl = `/admin/companies/`;
const importCompanyCSVUrl = `/admin/companies/csv-import`;
const updateUserListUrl = `/admin/companies/update-from-csv`;

export const getListCompanyApi = (payload, queryParams) => {
  return api.search(getListCompanyUrl, queryParams, payload);
};

export const createCompanyApi = (payload) => {
  return api.post(crudUrl, payload);
};

export const updateCompanyApi = (payload) => {
  return api.put(crudUrl, payload);
};

export const getDetailCompanyApi = (id) => {
  return api.get(getDetailCompanyUrl + id);
};

export const deleteCompanyApi = (id) => {
  return api.delete(crudUrl + id);
};

export const importCompanyCSVApi = (payload) => {
  return api.post(importCompanyCSVUrl, payload);
};

export const importCompanyMemberListCSVApi = (payload) => {
  return api.postMultiplePart(importCompanyCSVUrl, payload);
};

export const updateUserListApi = (payload) => {
  return api.put(updateUserListUrl, payload);
};
