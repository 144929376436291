export const ACTION_TYPES = {
  SEARCH_LIST_BUSINESS_VEHICLE_START: 'SEARCH_LIST_BUSINESS_VEHICLE_START',
  SEARCH_LIST_BUSINESS_VEHICLE_SUCCESS: 'SEARCH_LIST_BUSINESS_VEHICLE_SUCCESS',
  SEARCH_LIST_BUSINESS_VEHICLE_FAIL: 'SEARCH_LIST_BUSINESS_VEHICLE_FAIL',

  GET_DETAIL_BUSINESS_VEHICLE_START: 'GET_DETAIL_BUSINESS_VEHICLE_START',
  GET_DETAIL_BUSINESS_VEHICLE_SUCCESS: 'GET_DETAIL_BUSINESS_VEHICLE_SUCCESS',
  GET_DETAIL_BUSINESS_VEHICLE_FAIL: 'GET_DETAIL_BUSINESS_VEHICLE_FAIL',

  CRUD_BUSINESS_VEHICLE_START: 'CRUD_BUSINESS_VEHICLE_START',
  CRUD_BUSINESS_VEHICLE_SUCCESS: 'CRUD_BUSINESS_VEHICLE_SUCCESS',
  CRUD_BUSINESS_VEHICLE_FAIL: 'CRUD_BUSINESS_VEHICLE_FAIL',

  GET_VEHICLE_TYPE_START: 'GET_VEHICLE_TYPE_START',
  GET_VEHICLE_TYPE_SUCCESS: 'GET_VEHICLE_TYPE_SUCCESS',
  GET_VEHICLE_TYPE_FAIL: 'GET_VEHICLE_TYPE_FAIL',

  GET_VEHICLE_MOBI_TYPES_START: 'GET_VEHICLE_MOBI_TYPES_START',
  GET_VEHICLE_MOBI_TYPES_SUCCESS: 'GET_VEHICLE_MOBI_TYPES_SUCCESS',
  GET_VEHICLE_MOBI_TYPES_FAIL: 'GET_VEHICLE_MOBI_TYPES_FAIL',

  GET_LIST_VEHICLE_MODE_START: 'GET_LIST_VEHICLE_MODE_START',
  GET_LIST_VEHICLE_MODE_SUCCESS: 'GET_LIST_VEHICLE_MODE_SUCCESS',
  GET_LIST_VEHICLE_MODE_FAIL: 'GET_LIST_VEHICLE_MODE_FAIL',

  GET_DETAIL_VEHICLE_JIT_START: 'GET_DETAIL_VEHICLE_JIT_START',
  GET_DETAIL_VEHICLE_JIT_SUCCESS: 'GET_DETAIL_VEHICLE_JIT_SUCCESS',
  GET_DETAIL_VEHICLE_JIT_FAIL: 'GET_DETAIL_VEHICLE_JIT_FAIL',

  CREATE_VEHICLE_JIT_START: 'CREATE_VEHICLE_JIT_START',
  CREATE_VEHICLE_JIT_SUCCESS: 'CREATE_VEHICLE_JIT_SUCCESS',
  CREATE_VEHICLE_JIT_FAIL: 'CREATE_VEHICLE_JIT_FAIL',

  GET_DETAIL_VEHICLE_JIT_CALENDAR_START: 'GET_DETAIL_VEHICLE_JIT_CALENDAR_START',
  GET_DETAIL_VEHICLE_JIT_CALENDAR_SUCCESS: 'GET_DETAIL_VEHICLE_JIT_CALENDAR_SUCCESS',
  GET_DETAIL_VEHICLE_JIT_CALENDAR_FAIL: 'GET_DETAIL_VEHICLE_JIT_CALENDAR_FAIL',

  GET_VEHICLE_SHIFT_SPECIAL_START: 'GET_VEHICLE_SHIFT_SPECIAL_START',
  GET_VEHICLE_SHIFT_SPECIAL_SUCCESS: 'GET_VEHICLE_SHIFT_SPECIAL_SUCCESS',
  GET_VEHICLE_SHIFT_SPECIAL_FAIL: 'GET_VEHICLE_SHIFT_SPECIAL_FAIL',

  UPDATE_VEHICLE_SHIFT_SPECIAL_START: 'UPDATE_VEHICLE_SHIFT_SPECIAL_START',
  UPDATE_VEHICLE_SHIFT_SPECIAL_SUCCESS: 'UPDATE_VEHICLE_SHIFT_SPECIAL_SUCCESS',
  UPDATE_VEHICLE_SHIFT_SPECIAL_FAIL: 'UPDATE_VEHICLE_SHIFT_SPECIAL_FAIL',

  GET_TRANSIT_STOP_START: 'GET_TRANSIT_STOP_START',
  GET_TRANSIT_STOP_SUCCESS: 'GET_TRANSIT_STOP_SUCCESS',
  GET_TRANSIT_STOP_FAIL: 'GET_TRANSIT_STOP_FAIL',

  GET_VEHICLES_MODE_BY_GEOFENCE_START: 'GET_VEHICLES_MODE_BY_GEOFENCE_START',
  GET_VEHICLES_MODE_BY_GEOFENCE_SUCCESS: 'GET_VEHICLES_MODE_BY_GEOFENCE_SUCCESS',
  GET_VEHICLES_MODE_BY_GEOFENCE_FAIL: 'GET_VEHICLES_MODE_BY_GEOFENCE_FAIL',

  GET_GPS_LOCATION_START: 'GET_GPS_LOCATION_START',
  GET_GPS_LOCATION_SUCCESS: 'GET_GPS_LOCATION_SUCCESS',
  GET_GPS_LOCATION_FAIL: 'GET_GPS_LOCATION_FAIL',

  GET_VEHICLE_GEOFENCE_ADDITION_START: 'GET_VEHICLE_GEOFENCE_ADDITION_START',
  GET_VEHICLE_GEOFENCE_ADDITION_SUCCESS: 'GET_VEHICLE_GEOFENCE_ADDITION_SUCCESS',
  GET_VEHICLE_GEOFENCE_ADDITION_FAIL: 'GET_VEHICLE_GEOFENCE_ADDITION_FAIL',
};

const initialState = {
  isLoading: false,
  list: [],
  detail: {},
  vehicleType: [],
  createId: {},
  mobiTypes: undefined,
  listVehicleMode: [],
  vehicleJit: {},
  vehicleJitCalendar: {},
  listVehicleShiftSpecial: [],
  transitStop: [],
  gps_location: [],
};

/**
 * function reduce change password
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.SEARCH_LIST_BUSINESS_VEHICLE_START:
    case ACTION_TYPES.GET_DETAIL_BUSINESS_VEHICLE_START:
    case ACTION_TYPES.GET_VEHICLE_TYPE_START:
    case ACTION_TYPES.CRUD_BUSINESS_VEHICLE_START:
    case ACTION_TYPES.GET_LIST_VEHICLE_MODE_START:
    case ACTION_TYPES.GET_DETAIL_VEHICLE_JIT_START:
    case ACTION_TYPES.GET_DETAIL_VEHICLE_JIT_CALENDAR_START:
    case ACTION_TYPES.CREATE_VEHICLE_JIT_START:
    case ACTION_TYPES.GET_VEHICLE_SHIFT_SPECIAL_START:
    case ACTION_TYPES.UPDATE_VEHICLE_SHIFT_SPECIAL_START:
    case ACTION_TYPES.GET_TRANSIT_STOP_START:
    case ACTION_TYPES.GET_VEHICLES_MODE_BY_GEOFENCE_START:
    case ACTION_TYPES.GET_VEHICLE_GEOFENCE_ADDITION_START:
    case ACTION_TYPES.GET_GPS_LOCATION_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.SEARCH_LIST_BUSINESS_VEHICLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: action.list,
      };
    case ACTION_TYPES.GET_DETAIL_BUSINESS_VEHICLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        detail: action.detail,
      };
    case ACTION_TYPES.GET_VEHICLE_TYPE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        vehicleType: action.vehicleType,
      };
    case ACTION_TYPES.SEARCH_LIST_BUSINESS_VEHICLE_FAIL:
      return {
        ...state,
        isLoading: false,
        list: [],
      };
    case ACTION_TYPES.GET_DETAIL_BUSINESS_VEHICLE_FAIL:
      return {
        ...state,
        isLoading: false,
        detail: {},
      };
    case ACTION_TYPES.CRUD_BUSINESS_VEHICLE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        createId: action.createId,
      };
    case ACTION_TYPES.CREATE_VEHICLE_JIT_SUCCESS:
    case ACTION_TYPES.UPDATE_VEHICLE_SHIFT_SPECIAL_SUCCESS:
    case ACTION_TYPES.CREATE_VEHICLE_JIT_FAIL:
    case ACTION_TYPES.CRUD_BUSINESS_VEHICLE_FAIL:
    case ACTION_TYPES.UPDATE_VEHICLE_SHIFT_SPECIAL_FAIL:
    case ACTION_TYPES.GET_VEHICLES_MODE_BY_GEOFENCE_SUCCESS:
    case ACTION_TYPES.GET_VEHICLES_MODE_BY_GEOFENCE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case ACTION_TYPES.GET_VEHICLE_TYPE_FAIL:
      return {
        ...state,
        isLoading: false,
        vehicleType: [],
      };
    case ACTION_TYPES.GET_VEHICLE_MOBI_TYPES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        mobiTypes: action.mobiTypes,
      };
    case ACTION_TYPES.GET_VEHICLE_MOBI_TYPES_FAIL:
      return {
        ...state,
        isLoading: false,
        mobiTypes: undefined,
      };
    case ACTION_TYPES.GET_LIST_VEHICLE_MODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listVehicleMode: action.listVehicleMode,
      };
    case ACTION_TYPES.GET_LIST_VEHICLE_MODE_FAIL:
      return {
        ...state,
        isLoading: false,
        listVehicleMode: [],
      };
    case ACTION_TYPES.GET_DETAIL_VEHICLE_JIT_CALENDAR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        vehicleJitCalendar: action.vehicleJitCalendar,
      };
    case ACTION_TYPES.GET_DETAIL_VEHICLE_JIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        vehicleJit: action.vehicleJit,
      };
    case ACTION_TYPES.GET_DETAIL_VEHICLE_JIT_CALENDAR_FAIL:
      return {
        ...state,
        isLoading: false,
        vehicleJitCalendar: {},
      };
    case ACTION_TYPES.GET_DETAIL_VEHICLE_JIT_FAIL:
      return {
        ...state,
        isLoading: false,
        vehicleJit: {},
      };
    case ACTION_TYPES.GET_VEHICLE_SHIFT_SPECIAL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listVehicleShiftSpecial: action.listVehicleShiftSpecial,
      };
    case ACTION_TYPES.GET_VEHICLE_SHIFT_SPECIAL_FAIL:
      return {
        ...state,
        isLoading: false,
        listVehicleShiftSpecial: [],
      };
    case ACTION_TYPES.GET_TRANSIT_STOP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        transitStop: action.transitStop,
      };
    case ACTION_TYPES.GET_TRANSIT_STOP_FAIL:
      return {
        ...state,
        isLoading: false,
        transitStop: [],
      };
    case ACTION_TYPES.GET_GPS_LOCATION_SUCCESS:
    case ACTION_TYPES.GET_GPS_LOCATION_FAIL:
      return {
        ...state,
        isLoading: false,
        gps_location: action.gps_location,
      };
    case ACTION_TYPES.GET_VEHICLE_GEOFENCE_ADDITION_SUCCESS:
    case ACTION_TYPES.GET_VEHICLE_GEOFENCE_ADDITION_FAIL:
    default:
      return state;
  }
}
