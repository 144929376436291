import React, {Component} from 'react';

import {Button, Chip, Container, Fade, Grid, Paper} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import Rating from '@material-ui/lab/Rating';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {Link} from 'react-router-dom';

import {ROUTE} from '../../../../common/constant';

/**
 * Reservation Chat History
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  /**
   * handleClickClose
   */
  handleClickClose = () => {
    this.props.onClickClose();
  };

  /**
   * Render component
   * @return {object}
   */
  render() {
    const {t, ratingData} = this.props;

    return (
      <Fade in={true}>
        <Paper className="modal_size_small">
          <LoadingOverlay active={this.state.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
            <br></br>
            <Container maxWidth="xl">
              <Grid container spacing={1}>
                <Grid container alignItems="flex-start" className="page_header" item xs={8}>
                  <img src={`${process.env.PUBLIC_URL}/favicon.ico`} alt="Logo" width="35" height="33"></img>
                  <span className="font_bold font_size_big header_title">{t('reservationManagement.driver_rating')}</span>
                </Grid>
                <Grid container alignItems="flex-start" justify="flex-end" className="page_header" item xs={4}>
                  <CancelIcon onClick={this.handleClickClose} className="cursor_pointer" />
                </Grid>
              </Grid>
            </Container>
            <br />
            <Container maxWidth="xl">
              {!_.isEmpty(ratingData) ? (
                <Grid container spacing={1}>
                  <Grid container alignItems="flex-start" item xs={12}>
                    <Rating name="read-only" value={Number(ratingData?.star_rating)} readOnly />
                  </Grid>
                  <Grid container alignItems="flex-start" item xs={12}>
                    {ratingData?.details?.map((item, index) => (
                      <Chip key={index} label={item.languages[localStorage.getItem('i18nextLng')]} color="primary" variant="outlined" />
                    ))}
                  </Grid>
                  <Grid container alignItems="flex-start" item xs={12}>
                    <span>{ratingData?.description}</span>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={1}>
                  <span>{t('reservationManagement.no_rating')}</span>
                </Grid>
              )}
            </Container>
            <Container maxWidth="xl">
              <Grid container>
                <Grid container justify="flex-start" alignItems="flex-start" item xs={4}></Grid>
                <Grid container justify="flex-end" alignItems="flex-start" item xs={8}>
                  <Link
                    style={{textDecoration: 'none'}}
                    to={{
                      pathname: ROUTE.LAYOUT + ROUTE.DRIVER_DETAIL + '/' + this.props.driverId,
                    }}
                  >
                    <Button color="primary" variant="outlined" className="button_margin">
                      {t('reservationManagement.driver_average_rating')}
                    </Button>
                  </Link>
                  <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.handleClickClose} endIcon={<CloseIcon />}>
                    {t('common.btnClose')}
                  </Button>
                </Grid>
              </Grid>

              <br></br>
            </Container>
          </LoadingOverlay>
        </Paper>
      </Fade>
    );
  }
}

export default withTranslation('translations')(Index);
