export const ACTION_TYPES = {
  SEARCH_LIST_COUPON_START: 'SEARCH_LIST_COUPON_START',
  SEARCH_LIST_COUPON_SUCCESS: 'SEARCH_LIST_COUPON_SUCCESS',
  SEARCH_LIST_COUPON_FAIL: 'SEARCH_LIST_COUPON_FAIL',

  CREATE_COUPON_START: 'CREATE_COUPON_START',
  CREATE_COUPON_SUCCESS: 'CREATE_COUPON_SUCCESS',
  CREATE_COUPON_FAIL: 'CREATE_COUPON_FAIL',

  GET_DETAIL_COUPON_START: 'GET_DETAIL_COUPON_START',
  GET_DETAIL_COUPON_SUCCESS: 'GET_DETAIL_COUPON_SUCCESS',
  GET_DETAIL_COUPON_FAIL: 'GET_DETAIL_COUPON_FAIL',

  UPDATE_COUPON_START: 'UPDATE_COUPON_START',
  UPDATE_COUPON_SUCCESS: 'UPDATE_COUPON_SUCCESS',
  UPDATE_COUPON_FAIL: 'UPDATE_COUPON_FAIL',

  DELETE_COUPON_START: 'DELETE_COUPON_START',
  DELETE_COUPON_SUCCESS: 'DELETE_COUPON_SUCCESS',
  DELETE_COUPON_FAIL: 'DELETE_COUPON_FAIL',

};

const initialState = {
  isLoading: false,
  couponList: [],
  couponDetail: {},
};

/**
 * function reduce change password
 * @param {object} state
 * @param {action} action
 * @return {object}
 */
export default function reduce(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.SEARCH_LIST_COUPON_START:
    case ACTION_TYPES.CREATE_COUPON_START:
    case ACTION_TYPES.GET_DETAIL_COUPON_START:
    case ACTION_TYPES.UPDATE_COUPON_START:
    case ACTION_TYPES.DELETE_COUPON_START:
      return {
        ...state,
        isLoading: true,
      };
    case ACTION_TYPES.SEARCH_LIST_COUPON_SUCCESS:
      return {
        ...state,
        isLoading: false,
        couponList: action.couponList,
      };
    case ACTION_TYPES.GET_DETAIL_COUPON_SUCCESS:
      return {
        ...state,
        isLoading: false,
        couponDetail: action.couponDetail,
      };
    case ACTION_TYPES.SEARCH_LIST_COUPON_FAIL:
    case ACTION_TYPES.CREATE_COUPON_FAIL:
    case ACTION_TYPES.GET_DETAIL_COUPON_FAIL:
    case ACTION_TYPES.UPDATE_COUPON_FAIL:
    case ACTION_TYPES.DELETE_COUPON_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
