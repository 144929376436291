import React, {Component} from 'react';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {Container, Grid, TextField, FormControl, Button, Card, Dialog} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {PERMISSION_ACTIONS, ROUTE} from '../../../../common/constant';
import SelectModal from '../../../../components/selectModal';
import withPermissionGateway from '../../../../hoc/withPermissionGateway';
import {getCaution, upsertCaution} from '../../../../stores/notice/actions';
import {redirectRouter, onChangeTextField, backForwardRouter} from '../../../../utils/common';

/**
 * Product Management view
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      caution_name: '',
      caution_id: '',
      caution_content: '',
      fromTime: null,
      toTime: null,
      isSubmitForm: false,
      flag: false,
      payload: {},
    };
    this.type = this.props.match.params.id ? 'UPDATE' : 'CREATE';
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  async componentDidMount() {
    if (this.type === 'UPDATE') {
      await this.props.getCaution(this.props.match.params.id).then((data) => {
        this.setState({
          id: data?.id,
          caution_name: data?.caution_name,
          caution_id: data?.caution_id,
          fromTime: data?.from_time,
          toTime: data?.to_time,
          caution_content: data?.caution_content,
        });
      }, () => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.PRODUCT_NOTICE));
    }
  }

  /**
   * handleButtonOk
   * @param {object} formData
   */
  handleButtonUpdateOk = () => {
    this.setState({
      flag: false,
    });
    this.props.upsertCaution(this.state.payload, this.props).then(() => {
      this.props.getCaution(this.props.match.params.id);
    });
  };

  /**
   * handleButtonCancel
   */
  handleButtonUpdateCancel() {
    this.setState({
      flag: false,
    });
  }

  /**
   * handleConfirmUpdate
   * @param {object} payload
   */
  handleUpdate = (payload) => {
    const {t} = this.props;
    this.setState({
      flag: true,
      message: `${t('messageCode.updateConfirm', {field: t('caution.item')})}`,
      payload: payload,
    });
  };

  /**
   * handle submit
   */
  handleSubmit() {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid()) {
      const {id, caution_name, caution_content, fromTime, toTime} = this.state;
      const payload = {
        name: caution_name,
        from: fromTime,
        to: toTime,
        content: caution_content,
      };
      if (this.type === 'UPDATE') {
        payload['id'] = id;
      }
      this.type === 'CREATE' ?
        this.props.upsertCaution(payload, this.props).then(() => {
          redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.PRODUCT_NOTICE);
        }) :
        this.handleUpdate(payload);
    }
  }

  /**
   * Render component
   * @return {object}
   */
  render() {
    const {t, actions} = this.props;
    const permission = {
      canUpdate: this.type !== 'UPDATE' || (this.type === 'UPDATE' && actions.includes(PERMISSION_ACTIONS.UPDATE)),
    };

    return (
      <Card className="main_card_min_size">
        <Container maxWidth="xl">
          <br />
          <Grid container spacing={1}>
            <Grid container alignItems="center" className="page_header" item xs={12}>
              {this.type === 'UPDATE' ? (
                <span className="font_bold font_size_big"> {t('caution.update')}</span>
              ) : (
                <span className="font_bold font_size_big"> {t('caution.create')}</span>
              )}
            </Grid>
            {/* name */}
            <Grid container spacing={1} className="row_form_item">
              <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                {t('caution.name')}
                <span className="font_color_red">＊</span>
              </Grid>
              <Grid container justify="center" alignItems="center" item xs={5}>
                <FormControl fullWidth margin="dense">
                  <TextField
                    name="caution_name"
                    className="field_size_20 field_min_size_300"
                    placeholder={t('placeholder.required', {field: t('caution.name')})}
                    inputProps={{maxLength: 256}}
                    variant="outlined"
                    margin="dense"
                    value={this.state.caution_name}
                    onChange={(event) => onChangeTextField(this, event)}
                  />
                </FormControl>
                {/* add rule to validate */}
                {this.validator.message('caution_name', this.state.caution_name, 'required')}
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    {/* check isValid to show messages */}
                    {this.state.isSubmitForm && !this.validator.check(this.state.caution_name, 'required') && (
                      <p className="error" style={{paddingLeft: '8px', fontSize: '0.75rem'}}>
                        {t('validation.required', {field: t('caution.name')})}
                      </p>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* display time */}
            <Grid container spacing={1} className="row_form_item">
              <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                {t('caution.display_time')}
              </Grid>
              <Grid container item xs={6} lg={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className="field_size_20 field_min_size_300"
                    margin="dense"
                    autoOk
                    disableToolbar
                    variant="inline"
                    inputVariant="outlined"
                    maxDate={this.state.toTime || undefined}
                    placeholder={t('caution.from_time')}
                    format="yyyy/MM/dd"
                    value={this.state.fromTime}
                    maxDateMessage={t('validation.invalid.time')}
                    onChange={(time) =>
                      this.setState({
                        fromTime: time,
                      })
                    }
                  />
                  <Grid container justify="center" alignItems="center" item xs={1}>
                    ～
                  </Grid>

                  <KeyboardDatePicker
                    className="field_size_20 field_min_size_300"
                    margin="dense"
                    autoOk
                    fullWidth
                    variant="inline"
                    minDate={this.state.fromTime || undefined}
                    disableToolbar
                    name="toTime"
                    inputVariant="outlined"
                    placeholder={t('caution.to_time')}
                    format="yyyy/MM/dd"
                    value={this.state.toTime}
                    minDateMessage={t('validation.invalid.time')}
                    onChange={(time) =>
                      this.setState({
                        toTime: time,
                      })
                    }
                  />
                  {/* add rule to validate */}
                  {this.validator.message('from_time', this.state.fromTime, 'required')}
                  <Grid container spacing={1}>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      {/* check isValid to show messages */}
                      {this.state.isSubmitForm && !this.validator.check(this.state.fromTime, 'required') && (
                        <p className="error" style={{fontSize: '0.75rem'}}>
                          {t('validation.required', {field: t('caution.from_time')})}
                        </p>
                      )}
                    </Grid>
                  </Grid>
                  {/* add rule to validate */}
                  {this.validator.message('to_time', this.state.toTime, 'required')}
                  <Grid container spacing={1}>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      {/* check isValid to show messages */}
                      {this.state.isSubmitForm && !this.validator.check(this.state.toTime, 'required') && (
                        <p className="error" style={{fontSize: '0.75rem'}}>
                          {t('validation.required', {field: t('caution.to_time')})}
                        </p>
                      )}
                    </Grid>
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>

            {/* Content */}
            <Grid container spacing={1} className="row_form_item">
              <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                {t('caution.content')}
              </Grid>
              <Grid container justify="center" alignItems="center" item xs={6} lg={4}>
                <TextField
                  placeholder={t('placeholder.required', {field: t('caution.content')})}
                  multiline
                  fullWidth
                  rows="10"
                  inputProps={{maxLength: 2000}}
                  name="caution_content"
                  onChange={(event) => onChangeTextField(this, event)}
                  value={this.state.caution_content}
                  variant="outlined"
                />
                {/* add rule to validate */}
                {this.validator.message('caution_content', this.state.caution_content, 'required')}
                <Grid container spacing={1}>
                  <Grid container alignItems="center" item xs={6} lg={4}>
                    {/* check isValid to show messages */}
                    {this.state.isSubmitForm && !this.validator.check(this.state.caution_content, 'required') && (
                      <p className="error" style={{paddingLeft: '8px', fontSize: '0.75rem'}}>
                        {t('validation.required', {field: t('caution.content')})}
                      </p>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container justify="flex-end" alignItems="center" item xs={12}>
              {permission.canUpdate && (
                <Button color="primary" variant="contained" className="button_margin button_color_blue" endIcon={<CloudUploadIcon />} onClick={() => this.handleSubmit()}>
                  {t('common.btnUpdate')}
                </Button>
              )}
              <Button color="primary" variant="contained" className="button_margin button_color" onClick={this.props.history.goBack}>
                {t('common.btnReturn')}
              </Button>
            </Grid>
          </Grid>

          <Dialog
            open={this.state.flag}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: '1020',
            }}
          >
            <SelectModal onClickOk={this.handleButtonUpdateOk.bind(this)} onClickCancel={this.handleButtonUpdateCancel.bind(this)} message={this.state.message}></SelectModal>
          </Dialog>
          <br></br>
        </Container>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notice: state.notice,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCaution: (id) => dispatch(getCaution(id)),
    upsertCaution: (params, props) => dispatch(upsertCaution(params, props)),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
