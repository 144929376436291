// import {ROUTE} from '../../common/constant';
import {
  searchAreaGeofenceApi,
  getDetailAreaGeofenceApi,
} from '../../services/areaGeofenceServices';
import {modalObj} from '../../utils/modal.js';
import {setMessageModal} from '../modal/actions';
import {ACTION_TYPES} from './reducer';

/**
 * searchAreaGeofence
 * @param {object} payload
 * @return {object}
 */
 export const searchAreaGeofence = (payload) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.SEARCH_AREA_GEOFENCE_START});
    return searchAreaGeofenceApi(payload).then(
        (response) => {
          if (response.status === 200 && response) {
            dispatch({
              type: ACTION_TYPES.SEARCH_AREA_GEOFENCE_SUCCESS,
              listAreaGeofence: response.result,
            });
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
            dispatch({type: ACTION_TYPES.SEARCH_AREA_GEOFENCE_FAIL});
          }
        },
        () => {
          dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
          dispatch({type: ACTION_TYPES.SEARCH_AREA_GEOFENCE_FAIL});
        },
    );
  };
};

/**
 * getAreaGeofenceDetail
 * @param {String} uuid
 * @return {Object}
 */
export const getAreaGeofenceDetail = (uuid) => {
  return (dispatch) => {
    dispatch({type: ACTION_TYPES.GET_DETAIL_AREA_GEOFENEC_START});
    return getDetailAreaGeofenceApi(uuid).then(
        (response) => {
          if (response && response.status === 200) {
            dispatch({
              type: ACTION_TYPES.GET_DETAIL_AREA_GEOFENEC_SUCCESS,
              detailAreaGeofence: response.result,
            });
            return response.result;
          } else {
            dispatch(setMessageModal(modalObj(true, response.message)));
            dispatch({type: ACTION_TYPES.GET_DETAIL_AREA_GEOFENEC_FAIL});
          }
        },
        () => {
          dispatch(setMessageModal(modalObj(true, 'Internal Server Error')));
          dispatch({type: ACTION_TYPES.GET_DETAIL_AREA_GEOFENEC_FAIL});
        },
    );
  };
};
