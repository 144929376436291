import React, {Component} from 'react';

import 'date-fns';
import {
  Card,
  Container,
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Button,
  Paper,
  Dialog,
  FormHelperText,
  FormGroup,
  Checkbox,
  FormControlLabel,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  Backdrop,
  DialogContent,
  Box,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CompareArrowsIcon from '@material-ui/icons/SwapHoriz';
import {KeyboardDatePicker} from '@material-ui/pickers';
import {withTranslation} from 'react-i18next';
import LoadingOverlay from 'react-loading-overlay';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import SimpleReactValidator from 'simple-react-validator';

import {
  ROUTE,
  CURRENCY,
  DATA_NULL,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  PAYMENT_METHOD_TRANSFER_SUB,
  PAYMENT_TRANSFER_STATUS,
  CANCEL_BY_OPTIONS,
  SIMULATION_STATUS,
  USER_SUBSCRIPTION_STATUS,
  PERMISSION_ACTIONS,
  RESERVATION_MOBI_TYPE,
  LANGUAGE,
  PLAN_CATEGORIES,
  TICKET_AMOUNT_TYPE,
  USER_TYPES,
} from '../../../common/constant';
import Memo from '../../../components/newMemo';
import OneTimeDiscount from '../../../components/OneTimeDiscount';
import SelectModal from '../../../components/selectModal';
import withPermissionGateway from '../../../hoc/withPermissionGateway';
import {
  subscriptionDisableApi,
  subscriptionDisableUserApi,
  subscriptionEnableApi,
  subscriptionEnableUserApi,
  addFamilySubscriptionApi,
  subscriptionRenewableApi,
  resendMailApi,
  updateMemoApi,
} from '../../../services/businessServices';
import {detailSubscription, updateSubscriptionTransferred, checkOwnerCard, getSendMailList, getDetailPlan} from '../../../stores/business/action';
import {getAllCountryCode, getServiceTypes, getAllSubscriptionGeofence} from '../../../stores/common/actions';
import {setMessageModal} from '../../../stores/modal/actions';
import {removeSlotSubs, changePaymentMethod} from '../../../stores/reservation/actions';
import {onChangeSelect, onChangeTextField, redirectRouter, backForwardRouter, formatNumber2DecimalDigits, getListAllGeofenceDetail, onChangeListData} from '../../../utils/common';
import {formatUtc} from '../../../utils/date_time_utils';
import {compareDateTimeRange} from '../../../utils/datetime';
import {modalObj} from '../../../utils/modal';
import {isRoleBusiness} from '../../../utils/role';
import AddSlot from './add_slots';
import SelectUser from './add_users';
import AdditionalPurchase from './additional_purchase';
import History from './history';
import HistoryAdditional from './history_additional';

/**
 * User Subscription Form Component
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      subscription_id: '',
      user_id: '',
      number_of_users_available: '',
      country: '',
      list_area_id: [],
      name: '',
      number_of_unit: '',
      max_user: '',
      freeStartingTime: '',
      partner_id: '',
      start_time: null,
      end_time: null,
      memos: [],
      oldMemos: '',
      note: '',
      currency: '',
      total_amount: '',
      fullname: '',
      email: '',
      mobile: '',
      list_family_user: [],
      list_family_user_full: [],
      changeStatus: {active: null, id: null},
      payment_method: '',
      payment_method_default: '',
      payment_transfer_status: '',
      payment_transfer_status_default: '',
      transfer_deadline: '',
      plan_code: '',
      subscription_code: '',
      is_active: null,
      removeUserId: null,
      status: '',
      canceled_by: '',
      zone_id: '',
      resendUserId: '',
      serviceTypeIds: [],
      sent_mails: [],
      subscription_languages: [],
      trial_time: 0,
      number_of_user: 0,
      limit_family_user: 0,
      extra_fee: 0,
      plan_id: '',

      plan_category: '',
      amount_type: '',
      total_ride_limit: 0,
      used_count: 0,
      remain_count: 0,
      openHistoryAdditionalModal: false,
      openAdditionalPurchaseModal: false,
      plan_auto_renew: false,
      plan_is_gift: false,
      isSubExtended: false,

      flag: false,
      confirmRenewFlag: false,
      isModalHistory: false,
      canAddUsers: false,
      addUsersModal: false,
      auto_renewable: false,
      isMainPlan: false,
      mainUserBanned: false,
      openModalConfirmResend: false,
      sentMailModal: false,
      sentMailLoading: false,
      openConfirmRemoveSlotModal: false,
      openAddSlotsModal: false,
      hasCreditCard: false,
      openConfirmChangePaymentMethodModal: false,
      flagListDiscount: false,
      dataDiscount: [],
      listGeofencesNotInFacility: [],
      renewals_from_subscription_code: '',
      renewals_from_subscription_id: null,
      geofenceDetailInfos: [],
      memberCode: '',
    };
    this.validator = new SimpleReactValidator();
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    this.props.getAllCountryCode();
    this.props.getServiceTypes();
    this.props.getAllSubscriptionGeofence();
    if (this.props.match.params.id) {
      this.getUserSubscriptionDetail();
    }
  }

  /**
   * get user subscription detail
   * @param {Number} subscriptionId
   */
  getUserSubscriptionDetail = (subscriptionId = this.props.match.params.id) => {
    this.props.detailSubscription(subscriptionId, this.props.history).then(async (result) => {
      if (!result) return;
      const {common} = this.props;
      const timeZone = common?.country_code?.find((country_code) => country_code.id === result.country_id)?.zone_id;
      const listGeofencesNotInFacility = isRoleBusiness() ? result.geofence_infos?.filter(({geofence_id}) => !common.principal?.geofence_ids?.includes(geofence_id)) : [];

      this.setState({
        subscription_id: this.props.match.params.id,
        user_id: result?.user_id,
        country: result?.country_id,
        list_area_id: result?.geofence_ids?.map((item) => item),
        plan_code: result?.plan_code,
        subscription_code: result?.subscription_code,
        name: result?.plan_name,
        number_of_unit: result?.number_of_unit,
        currency: result?.currency_code,
        total_amount: formatNumber2DecimalDigits(result?.total_amount, result?.currency_code),
        max_user: result?.max_user,
        freeStartingTime: result?.free_unit,
        partner_id: result?.partner?.id,
        memos: result?.memos,
        oldMemos: JSON.stringify(result?.memos),
        start_time: formatUtc(result?.effective_start_time, DATE_TIME_FORMAT, timeZone) || DATA_NULL,
        end_time: formatUtc(result?.effective_end_time, DATE_TIME_FORMAT, timeZone) || DATA_NULL,
        fullname:
          result?.subscription_users?.find((user) => user.willer_user_id === result?.user_id)?.first_name +
          ' ' +
          result?.subscription_users?.find((user) => user.willer_user_id === result?.user_id)?.last_name,
        first_name: result?.subscription_users?.find((user) => user.willer_user_id === result?.user_id)?.first_name,
        last_name: result?.subscription_users?.find((user) => user.willer_user_id === result?.user_id)?.last_name,
        first_name_furigana: result?.subscription_users?.find((user) => user.willer_user_id === result?.user_id)?.first_name_furigana,
        last_name_furigana: result?.subscription_users?.find((user) => user.willer_user_id === result?.user_id)?.last_name_furigana,
        email: result?.subscription_users?.find((user) => user.willer_user_id === result?.user_id)?.email || DATA_NULL,
        mobile: result?.subscription_users?.find((user) => user.willer_user_id === result?.user_id)?.mobile || DATA_NULL,
        list_family_user: result?.subscription_users.filter((user) => user.willer_user_id !== result?.user_id),
        list_family_user_full: result?.subscription_users,
        payment_method: result?.payment_method,
        payment_method_default: result?.payment_method,
        is_active: result?.is_active,
        canAddUsers:
          result.subscription_users?.filter((item) => item.delete_flag === false).length <= result.number_of_user - 1 &&
          new Date(result?.effective_end_time) > new Date() &&
          result?.is_active,
        number_of_users_available: result.number_of_user - result.subscription_users?.length,
        auto_renewable: result?.auto_renewable,
        transfer_deadline: result?.transfer_deadline ? this.getDateFromDatetimeData(result?.transfer_deadline) : '',
        status: result?.status,
        canceled_by: result?.canceled_by,
        canceled_time: result?.canceled_time,
        zone_id: result?.zone_id,
        mainUserBanned: result?.subscription_users.length > 0 ? result?.subscription_users[0].delete_flag : false,
        serviceTypeIds: result?.service_type_ids,
        subscription_languages: result?.subscription_languages,
        trial_time: result?.trial_time,
        number_of_user: result?.number_of_user,
        limit_family_user: result?.limit_family_user,
        extra_fee: result?.extra_fee || 0,

        plan_category: result?.plan_type,
        amount_type: result?.ticket_subscription_data?.ticket_type,
        total_ride_limit: result?.ticket_subscription_data?.current_rides + result?.ticket_subscription_data?.prev_rides,
        used_count: result?.ticket_subscription_data?.times_used,
        remain_count: result?.ticket_subscription_data?.current_rides + result?.ticket_subscription_data?.prev_rides - result?.ticket_subscription_data?.times_used,
        plan_id: result?.plan_id,
        dataDiscount: result?.subscription_discount_details,
        isSubExtended: result?.ticket_subscription_data?.ticket_rides === 0,
        listGeofencesNotInFacility,
        renewals_from_subscription_code: result?.renewals_from_subscription_code,
        renewals_from_subscription_id: result?.renewals_from_subscription_id,
        geofenceDetailInfos: result?.geofence_infos,
        memberCode: result?.subscription_users?.find((user) => user.willer_user_id === result?.user_id)?.member_id || '',
      });
      this.props.getDetailPlan(result?.plan_id).then((response) => this.setState({plan_auto_renew: response?.auto_renew, plan_is_gift: response?.is_gift || false}));
      this.checkOwnerCard();
      if (!this.state.payment_transfer_status) {
        this.setState({
          payment_transfer_status: result?.payment_transfer_status,
          payment_transfer_status_default: result?.payment_transfer_status,
        });
      }
    });
  };

  /**
   * getDateFromDatetimeData
   * @param {*} time
   * @return {String}
   */
  getDateFromDatetimeData(time) {
    const datetime = new Date(time);
    return datetime.getUTCFullYear() + '/' + ('0' + (datetime.getUTCMonth() + 1)).slice(-2) + '/' + ('0' + datetime.getUTCDate()).slice(-2);
  }

  /**
   * handleCreate
   */
  handleCreate = () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid()) {
      this.setState({
        flag: true,
        message: 'subscription.create_modal',
      });
    }
  };

  /**
   * handleUpdate
   */
  handleUpdate = () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid() && compareDateTimeRange(this.state.start_time, this.state.end_time, false)) {
      this.setState({
        flag: true,
        message: 'subscription.update_modal',
      });
    }
  };

  /**
   * handleUpdateMemo
   */
  handleUpdateMemo = () => {
    this.setState({
      isSubmitForm: true,
    });
    if (this.validator.allValid() && compareDateTimeRange(this.state.start_time, this.state.end_time, false)) {
      this.setState({
        flagMemo: true,
        message: 'subscription.update_memo_modal',
      });
    }
  };

  /**
   * handleMemoButtonUpdateOk
   */
  handleMemoButtonUpdateOk = async () => {
    this.setState({
      flagMemo: false,
    });
    // update memo field
    const memos =
      this.state.memos?.length > 0 ?
        this.state.memos.map((memo) => ({
            id: memo.id,
            content: memo.content,
          })) :
        [];
    // check add new memo
    if (this.state.note) {
      memos.push({id: null, content: this.state.note});
    }
    const payload_update = {
      target_id: parseInt(this.props.match.params.id),
      user_type: USER_TYPES[0],
      memos: memos,
    };
    updateMemoApi(payload_update, this.props).then((res) => {
      if (res && res.status === 200) {
        this.props.detailSubscription(this.state.subscription_id, this.props.history).then(async (result) => {
          if (!result) return;
          this.setState({memos: result.memos, note: '', oldMemos: JSON.stringify(result.memos)});
        });
      } else {
        this.props.setMessageModal(modalObj(true, 'error.common'));
      }
    });
  };

  /**
   * handleMemoModalCancel
   */
  handleMemoModalCancel = () => {
    this.setState({flagMemo: false});
  };

  /**
   * handleButtonModalOk
   */
  handleButtonModalOk = async () => {
    this.setState({
      flag: false,
      note: '',
      memos: JSON.parse(this.state.oldMemos),
    });
    if (this.props.match.params.id) {
      if (this.state.message === 'sub.enable_main' || this.state.message === 'sub.disable_main' || this.state.message === 'sub.delete_family_user') {
        if (!this.state.isMainPlan) {
          if (!this.state.changeStatus.active) {
            await subscriptionDisableUserApi(this.state.changeStatus.id);
            this.handleDetails();
          } else {
            await subscriptionEnableUserApi(this.state.changeStatus.id);
            this.handleDetails();
          }
        } else {
          if (this.state.changeStatus.active) {
            await subscriptionDisableApi(this.state.changeStatus.id);
            this.handleDetails();
          } else {
            await subscriptionEnableApi(this.state.changeStatus.id);
            this.handleDetails();
          }
        }
      } else {
        const result = await this.props.updateSubscriptionTransferred({id: this.props.match.params.id, payment_transfer_status: this.state.payment_transfer_status}, this.props);
        if (result && result.status === 200) {
          this.setState({payment_transfer_status_default: this.state.payment_transfer_status});
        }
      }
    } else {
      // Todo
    }
  };

  /**
   * handleButtonModalCancel
   */
  handleButtonModalCancel = () => {
    this.setState({flag: false});
  };

  /**
   * handleDetails
   */
  handleDetails = () => {
    redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.RESERVATION_SUBSCRIPTION);
    redirectRouter(this.props, ROUTE.LAYOUT + ROUTE.RESERVATION_SUBSCRIPTION_DETAILS + '/' + this.props.match.params.id);
  };

  /**
   * changeAreaBy
   * @param {*} value
   * @param {*} item
   */
  changeAreaBy = (value, item) => {
    const list_area_id_copy = this.state.list_area_id;
    const index = list_area_id_copy.indexOf(item);
    value ? list_area_id_copy.push(item) : list_area_id_copy.splice(index, 1);

    this.setState({
      list_area_id: list_area_id_copy,
    });
  };

  /**
   * onChangeTime
   * @param {*} time
   * @param {Boolean} isStart
   */
  onChangeTime = (time, isStart) => {
    const fieldName = isStart ? 'start_time' : 'end_time';
    this.setState({
      [fieldName]: time,
    });
    compareDateTimeRange(this.state.start_time, this.state.end_time, false);
  };

  /**
   * handleDisableOrEnable
   * @param {*} active
   * @param {*} id
   * @param {*} isMainPlan
   */
  handleDisableOrEnable = (active, id, isMainPlan) => {
    this.setState({
      flag: true,
      message: active ? 'sub.enable_main' : 'sub.disable_main',
      changeStatus: {active, id},
      isMainPlan,
    });
  };

  /**
   * handleDisableOrEnableChild
   * @param {*} active
   * @param {*} id
   * @param {*} isMainPlan
   */
  handleDisableOrEnableChild = (active, id, isMainPlan) => {
    this.setState({
      flag: true,
      message: 'sub.delete_family_user',
      changeStatus: {active, id},
      isMainPlan,
    });
  };

  /**
   * handleSelectUserOk
   * @param {array} list_family_code
   */
  handleSelectUserOk = async (list_family_code) => {
    this.setState({addUsersModal: false});
    const addFamilyUser = await addFamilySubscriptionApi({
      user_id: this.state.user_id,
      subscription_id: this.state.subscription_id,
      member_ids: list_family_code,
      type: 'MEMBER_ID',
    });
    if (addFamilyUser.status === 200) {
      this.getUserSubscriptionDetail();
    } else this.props.setMessageModal(modalObj(true, addFamilyUser.message));
  };

  /**
   * renewSubcription
   * @param {*} checkedSendMail
   */
  renewSubcription = async (checkedSendMail) => {
    const auto_renewable = this.state.auto_renewable;
    this.setState({confirmRenewFlag: false});
    const response = await subscriptionRenewableApi({
      subscription_id: Number(this.state.subscription_id),
      user_id: this.state.user_id,
      renewable: !auto_renewable,
      send_mail: checkedSendMail,
    });
    if (response.status === 200) this.setState({auto_renewable: response.result?.auto_renewable});
    this.props.setMessageModal(modalObj(true, response.message_code));
  };

  /**
   * closeConfirmRenewModal
   */
  closeConfirmRenewModal = () => {
    this.setState({confirmRenewFlag: false});
  };

  /**
   * confirmResendMail
   */
  confirmResendMail = async () => {
    this.closeModalConfirmResend();
    await resendMailApi({
      subscription_id: Number(this.state.subscription_id),
      user_id: this.state.resendUserId,
    }).then(
      (response) => {
        this.props.setMessageModal(modalObj(true, response.message));
      },
      () => this.props.setMessageModal(modalObj(true, this.props.t('Api.fail'))),
    );
  };

  /**
   * removeSlotSubs
   */
  removeSlotSubs = () => {
    this.setState({openConfirmRemoveSlotModal: false});
    this.props.removeSlotSubs({user_id: this.state.removeUserId, sub_id: Number(this.state.subscription_id)}).then(() => {
      this.getUserSubscriptionDetail();
    });
  };

  /**
   * changePaymentMethod
   */
  changePaymentMethod = () => {
    this.setState({openConfirmChangePaymentMethodModal: false});
    this.props
      .changePaymentMethod({
        user_id: this.state.user_id,
        subscription_id: this.state.subscription_id,
        payment_method: this.state.payment_method,
      })
      .then(() => this.getUserSubscriptionDetail());
  };

  /**
   * closeModalConfirmResend
   */
  closeModalConfirmResend = () => {
    this.setState({openModalConfirmResend: false});
  };

  /**
   * checkOnTrial
   * @return {Boolean}
   */
  checkOnTrial = () => {
    const endTrialTime = new Date(this.state.start_time);
    endTrialTime.setDate(endTrialTime.getDate() + this.state.trial_time);
    return new Date(endTrialTime) - new Date().getTime() > 0;
  };

  /**
   * checkOwnerCard
   */
  checkOwnerCard = async () => {
    this.props.checkOwnerCard(this.state.user_id).then((response) => {
      let hasCreditCard = false;
      if (response['2c2p_cards']?.length > 0) {
        this.state.country === 4 && (hasCreditCard = true); // Thẻ 2c2p chỉ dùng ở Sing
      }
      if (response['2c2p_my_cards']?.length > 0) {
        this.state.country === 5 && (hasCreditCard = true); // Thẻ 2c2p dùng ở Malaysia
      }
      if (response['veritrans_cards']?.length > 0) {
        this.state.country === 2 && (hasCreditCard = true); // Thẻ veritrans chỉ dùng ở Nhật
      }
      if (response['alepay_cards']?.length > 0) {
        this.state.country === 1 && (hasCreditCard = true); // Thẻ alepay chỉ dùng ở Việt Nam
      }
      this.setState({hasCreditCard});
    });
  };

  /**
   * getListAllGeofence
   * @param {*} all_subscription_geofence
   * @return {Array}
   */
  getListAllGeofence = (all_subscription_geofence) => {
    const listGeofence = [...all_subscription_geofence.filter((geofence) => geofence.country_id === this.state.country), ...this.state.listGeofencesNotInFacility];
    return this.props.match.params.id ? getListAllGeofenceDetail(listGeofence, this.state.geofenceDetailInfos) : listGeofence;
  };

  /**
   * closeListDiscountModal
   */
  closeListDiscountModal = () => {
    this.setState({flagListDiscount: false});
  };

  /**
   * Render
   * @return {HTMLElement}
   */
  render() {
    const {t, common, business, actions, reservation} = this.props;
    const {serviceTypeIds, plan_category} = this.state;
    const isEditForm = !!this.props.match.params.id;
    const isAllGeofencesInFacility = this.state.listGeofencesNotInFacility.length === 0;
    const listEmptyUser = [];
    const permission = {
      canUpdate: actions.includes(PERMISSION_ACTIONS.UPDATE),
      canAddFamilyOption: actions.includes(PERMISSION_ACTIONS.ADD_FAMILY_OPTION),
      canDeleteFamilyOption: actions.includes(PERMISSION_ACTIONS.DELETE_FAMILY_OPTION),
      canAutomaticUpdate: actions.includes(PERMISSION_ACTIONS.AUTOMATIC_UPDATES),
      canDisable: actions.includes(PERMISSION_ACTIONS.DISABLE),
      canResend: actions.includes(PERMISSION_ACTIONS.RESEND),
      canRemoveSlot: actions.includes(PERMISSION_ACTIONS.REMOVE_SLOT),
      canAdditionalPurchaseRideTicket: actions.includes(PERMISSION_ACTIONS.ADDITIONAL_PURCHASE_OF_RIDE_TICKET),
      canHistoryAdditional: actions.includes(PERMISSION_ACTIONS.HISTORY_ADDITIONAL),
      canUpdateMemo: actions.includes(PERMISSION_ACTIONS.UPDATE_MEMO),
      canMemberInfor: actions.includes(PERMISSION_ACTIONS.MEMBER_INFOR),
    };
    if (plan_category === 'PLAN') {
      for (let i = 0; i < this.state.number_of_users_available; i++) {
        listEmptyUser.push(
          <TableRow key={i}>
            <TableCell align="left">{DATA_NULL}</TableCell>
            <TableCell align="left">{DATA_NULL}</TableCell>
            <TableCell align="left">{DATA_NULL}</TableCell>
            <TableCell align="left">{DATA_NULL}</TableCell>
            <TableCell align="left">{DATA_NULL}</TableCell>
            <TableCell align="left">{DATA_NULL}</TableCell>
            <TableCell align="left">{DATA_NULL}</TableCell>
            <TableCell align="left">{DATA_NULL}</TableCell>
            <TableCell align="left">{DATA_NULL}</TableCell>
            <TableCell align="left">{DATA_NULL}</TableCell>
            {this.state.is_active && permission.canResend && <TableCell align="right"></TableCell>}
            <TableCell align="left">
              {permission.canAddFamilyOption && !this.state.mainUserBanned && isAllGeofencesInFacility && (
                <Button color="primary" variant="contained" style={{marginRight: 10}} endIcon={<AddIcon />} onClick={() => this.setState({addUsersModal: true})}>
                  {t('sub.add_member')}
                </Button>
              )}
              {permission.canRemoveSlot && isAllGeofencesInFacility && (
                <Button style={{marginTop: 5}} color="secondary" variant="contained" onClick={() => this.setState({openConfirmRemoveSlotModal: true})}>
                  {t('sub.remove_slot')}
                </Button>
              )}
            </TableCell>
          </TableRow>,
        );
      }
    }

    return (
      <LoadingOverlay active={business.isLoading || common.isLoading || reservation.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{isEditForm ? t('route.business_subscription_details') : t('route.business_subscription_create')}</h3>
              </Grid>
            </Grid>
          </Container>
          <br></br>
          <Container maxWidth="xl">
            <Card raised>
              <Container maxWidth="xl">
                <br></br>
                <Paper className="search_table">
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={12} lg={12} className="product_entry_table_header_color font_color_white font_size_mid search_condition_title">
                      {t('business_plan.form')}
                    </Grid>
                  </Grid>

                  {/* Country */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.country')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <FormControl variant="outlined" margin="dense" className="field_size_10 field_min_size_300">
                          <Select
                            margin="dense"
                            inputProps={{
                              name: 'country',
                            }}
                            displayEmpty
                            disabled={true}
                            value={this.state.country}
                            renderValue={
                              this.state.country ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('common.country'),
                                      })}
                                    </div>
                                  )
                            }
                          >
                            {common?.country_code?.map((item, idx) => (
                              <MenuItem value={item.id} key={idx}>
                                {t(`${item.country_code}`)}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {this.validator.message('country', this.state.country, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.country, 'required') && (
                          <FormHelperText id="country" error>
                            {t('validation.required.choose', {field: t('common.country')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Geofence */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.geofence')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <FormGroup row>
                          {this.getListAllGeofence(common.all_subscription_geofence).map((item, idx) => {
                            const checked = this.state.list_area_id?.indexOf(item.geofence_id) > -1;
                            return (
                              <FormControlLabel
                                key={idx}
                                labelPlacement="end"
                                disabled={true}
                                control={<Checkbox checked={checked} onClick={(event) => this.changeAreaBy(event.target.checked, item.geofence_id)} className="checkbox_radio" />}
                                label={item.name}
                              />
                            );
                          })}
                        </FormGroup>
                        {this.validator.message('list_area_id', this.state.list_area_id, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.list_area_id, 'required') && (
                          <FormHelperText id="list_area_id" error>
                            {t('validation.required', {field: t('business_plan.geofence')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Plan category */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.category')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" className="field_size_10 field_min_size_300">
                        <Select margin="dense" disabled={true} value={this.state.plan_category}>
                          {PLAN_CATEGORIES.map((item, index) => (
                            <MenuItem value={item.value} key={index}>
                              {t(`${item.i18n}`)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Plan Name */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.package_name')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <TextField
                          label={<span>({t('common.ja')})</span>}
                          rows={2}
                          multiline
                          fullWidth
                          margin="dense"
                          disabled={true}
                          variant="outlined"
                          value={this.state?.subscription_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'ja')?.id)?.name || ''}
                        />
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon" style={{top: 0}}></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <TextField
                          label={<span>({t('common.vi')})</span>}
                          rows={2}
                          multiline
                          fullWidth
                          margin="dense"
                          disabled={true}
                          variant="outlined"
                          value={this.state?.subscription_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'vi')?.id)?.name || ''}
                        />
                      </FormControl>
                    </Grid>
                    <Grid container justify="center" alignItems="center" item xs={1}>
                      <CompareArrowsIcon className="compare_arrow_icon" style={{top: 0}}></CompareArrowsIcon>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2}>
                      <FormControl fullWidth>
                        <TextField
                          label={<span>({t('common.en')})</span>}
                          rows={2}
                          multiline
                          fullWidth
                          margin="dense"
                          disabled={true}
                          variant="outlined"
                          value={this.state?.subscription_languages?.find((item) => item.language_id === LANGUAGE.find((item2) => item2.code === 'en')?.id)?.name || ''}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Plan type */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('common.planType')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error className="item_display_box">
                        {common?.planTypes?.map(
                          (planType) =>
                            (planType.service_type === RESERVATION_MOBI_TYPE[0].id || planType.service_type === RESERVATION_MOBI_TYPE[1].id) && (
                              <FormControlLabel
                                labelPlacement="end"
                                control={<Checkbox checked={serviceTypeIds?.indexOf(planType.id) > -1} disabled={true} className="checkbox_radio" />}
                                label={planType.service_type === RESERVATION_MOBI_TYPE[0].id ? t(RESERVATION_MOBI_TYPE[0].i18n) : t(RESERVATION_MOBI_TYPE[1].i18n)}
                              />
                            ),
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* User Name */}
                  {isEditForm && (
                    <>
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('sub.last_name')} <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={3}>
                          <FormControl error>
                            <TextField
                              className="field_size_10 field_min_size_300"
                              margin="dense"
                              placeholder={t('placeholder.required', {field: t('sub.last_name')})}
                              variant="outlined"
                              value={this.state.last_name || ''}
                              disabled={true}
                            />
                          </FormControl>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('sub.first_name')} <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl error>
                            <TextField
                              className="field_size_10 field_min_size_300"
                              margin="dense"
                              placeholder={t('placeholder.required', {field: t('sub.first_name')})}
                              variant="outlined"
                              value={this.state.first_name || ''}
                              disabled={true}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('sub.lastname_furigana')} <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={3}>
                          <FormControl error>
                            <TextField
                              className="field_size_10 field_min_size_300"
                              margin="dense"
                              placeholder={t('placeholder.required', {field: t('sub.lastname_furigana')})}
                              variant="outlined"
                              value={this.state.last_name_furigana || ''}
                              disabled={true}
                            />
                          </FormControl>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('sub.firstname_furigana')} <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl error>
                            <TextField
                              className="field_size_10 field_min_size_300"
                              margin="dense"
                              placeholder={t('placeholder.required', {field: t('sub.firstname_furigana')})}
                              variant="outlined"
                              value={this.state.first_name_furigana || ''}
                              disabled={true}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      {/* Email */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.email')}
                          <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl error>
                            <TextField className="field_size_10 field_min_size_300" margin="dense" disabled={true} variant="outlined" value={this.state.email} />
                          </FormControl>
                        </Grid>
                      </Grid>
                      {/* phone */}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.phoneNumber')}
                          <span className="font_color_red">＊</span>
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl error>
                            <TextField className="field_size_10 field_min_size_300" margin="dense" disabled={true} variant="outlined" value={this.state.mobile} />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {/* Plan Code */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.plan_code')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={3}>
                      <FormControl error>
                        <TextField
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          inputProps={{
                            name: 'plan_code',
                          }}
                          disabled={true}
                          variant="outlined"
                          onChange={(event) => onChangeTextField(this, event)}
                          value={this.state.plan_code}
                        />
                      </FormControl>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('sub.active_status')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <TextField
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          disabled={true}
                          variant="outlined"
                          value={this.state.is_active ? t('common.enable') : t('common.disable')}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Subscription Code */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('businessPartner.subscription_code')} <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={3}>
                      <FormControl error>
                        <TextField
                          className="field_size_10 field_min_size_300"
                          margin="dense"
                          inputProps={{
                            name: 'subscription_code',
                          }}
                          disabled={true}
                          variant="outlined"
                          onChange={(event) => onChangeTextField(this, event)}
                          value={this.state.subscription_code}
                        />
                        {this.validator.message('subscription_code', this.state.subscription_code, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.subscription_code, 'required') && (
                          <FormHelperText id="subscription_code" error>
                            {t('validation.required', {field: t('businessPartner.subscription_code')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    {!this.state.is_active && (
                      <>
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('sub.canceled_time')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={4}>
                          <FormControl error>
                            <TextField
                              className="field_size_10 field_min_size_300"
                              margin="dense"
                              disabled={true}
                              variant="outlined"
                              value={this.state.canceled_time ? formatUtc(this.state.canceled_time, DATE_FORMAT, this.state.zone_id) : DATA_NULL}
                            />
                          </FormControl>
                        </Grid>
                      </>
                    )}
                  </Grid>

                  {/* Payment method */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    {!this.state.plan_is_gift && !this.state.isSubExtended && (
                      <>
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('common.paymentMethod')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={3}>
                          <FormControl variant="outlined" margin="dense">
                            <div className="box-group-input">
                              <Select
                                variant="outlined"
                                className="field_size_20 field_min_size_300"
                                margin="dense"
                                inputProps={{
                                  name: 'payment_method',
                                }}
                                disabled={
                                  (isRoleBusiness() && !isAllGeofencesInFacility) ||
                                  !this.checkOnTrial() ||
                                  !this.state.hasCreditCard ||
                                  this.state.payment_transfer_status === 'TRANSFERRED'
                                }
                                displayEmpty
                                renderValue={
                                  this.state.payment_method ?
                                    undefined :
                                    () => (
                                        <div className="font-12 color-disabled">
                                          {t('placeholder.required_select', {
                                            field: t('common.paymentMethod'),
                                          })}
                                        </div>
                                      )
                                }
                                value={this.state.payment_method}
                                onChange={(event) => {
                                  this.setState({
                                    payment_method: event.target.value,
                                    payment_transfer_status:
                                      this.state.payment_method_default === PAYMENT_METHOD_TRANSFER_SUB[0].id && event.target.value === PAYMENT_METHOD_TRANSFER_SUB[1].id ?
                                        PAYMENT_TRANSFER_STATUS[0].id :
                                        this.state.payment_transfer_status,
                                  });
                                }}
                              >
                                {PAYMENT_METHOD_TRANSFER_SUB.filter((item) => item.id !== 'CASH').map((item, idx) => {
                                  return (
                                    <MenuItem value={item.id} key={idx}>
                                      {t(item.i18n)}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                              {!(isRoleBusiness() && !isAllGeofencesInFacility) &&
                                this.checkOnTrial() &&
                                this.state.hasCreditCard &&
                                (!this.state.payment_transfer_status_default || this.state.payment_transfer_status_default === 'NOT_TRANSFER') && (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    className="margin-left-24"
                                    component="span"
                                    onClick={() => this.setState({openConfirmChangePaymentMethodModal: true})}
                                  >
                                    {t('common.btnApply')}
                                  </Button>
                                )}
                            </div>
                          </FormControl>
                        </Grid>
                      </>
                    )}
                    {((!this.state.is_active && this.state.canceled_by === CANCEL_BY_OPTIONS[2].id) || this.state.isSubExtended) && (
                      <>
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('sub.status')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={3}>
                          <FormControl error>
                            <TextField
                              className="field_size_10 field_min_size_300"
                              margin="dense"
                              disabled={true}
                              variant="outlined"
                              value={t(`${USER_SUBSCRIPTION_STATUS.find((e) => e.id === this.state.status)?.i18n}`) || ''}
                            />
                          </FormControl>
                        </Grid>
                      </>
                    )}
                  </Grid>

                  {/* payment status transfer */}
                  {this.state.payment_method === 'TRANSFER' && !this.state.plan_is_gift && !this.state.isSubExtended && (
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('businessPartner.payment_transfer_status')}
                        <span className="font_color_red">＊</span>
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={3}>
                        <FormControl variant="outlined" margin="dense">
                          <Select
                            variant="outlined"
                            className="field_size_20 field_min_size_300"
                            margin="dense"
                            inputProps={{
                              name: 'payment_transfer_status',
                            }}
                            displayEmpty
                            renderValue={
                              this.state.payment_transfer_status ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('businessPartner.payment_transfer_status'),
                                      })}
                                    </div>
                                  )
                            }
                            disabled={!isAllGeofencesInFacility}
                            value={this.state.payment_transfer_status}
                            onChange={(event) => onChangeSelect(this, event)}
                          >
                            {PAYMENT_TRANSFER_STATUS.map((item, idx) => {
                              if (PAYMENT_TRANSFER_STATUS.findIndex((element) => element.id === this.state.payment_transfer_status_default) > idx) {
                                return (
                                  <MenuItem value={item.id} key={idx} disabled>
                                    {t(item.i18n)}
                                  </MenuItem>
                                );
                              } else {
                                return (
                                  <MenuItem value={item.id} key={idx}>
                                    {t(item.i18n)}
                                  </MenuItem>
                                );
                              }
                            })}
                          </Select>
                          {this.validator.message('payment_transfer_status', this.state.payment_transfer_status, 'required')}
                          {this.state.isSubmitForm && !this.validator.check(this.state.payment_transfer_status, 'required') && (
                            <FormHelperText id="payment_transfer_status" error>
                              {t('validation.required.choose', {field: t('businessPartner.payment_transfer_status')})}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      {!(this.state.isSubExtended && this.state.transfer_deadline) && (
                        <>
                          <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                            {t('businessPartner.transfer_deadline_day')} <span className="font_color_red">＊</span>
                          </Grid>
                          <Grid container alignItems="center" item xs={6} lg={3}>
                            <FormControl variant="outlined" margin="dense">
                              <TextField
                                className="field_size_10 field_min_size_300"
                                margin="dense"
                                disabled={true}
                                placeholder={t('placeholder.required', {field: t('businessPartner.payment_transfer_status')})}
                                variant="outlined"
                                value={this.state.transfer_deadline || ''}
                              />
                            </FormControl>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  )}

                  {/* Currency */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.unit')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={3}>
                      <FormControl variant="outlined" margin="dense">
                        <Select
                          variant="outlined"
                          className="field_size_20 field_min_size_300"
                          margin="dense"
                          inputProps={{
                            name: 'currency',
                          }}
                          disabled={true}
                          displayEmpty
                          renderValue={
                            this.state.currency ?
                              undefined :
                              () => (
                                  <div className="font-12 color-disabled">
                                    {t('placeholder.required_select', {
                                      field: t('business_plan.unit'),
                                    })}
                                  </div>
                                )
                          }
                          value={this.state.currency}
                          onChange={(event) => onChangeSelect(this, event)}
                        >
                          {CURRENCY.map((item, idx) => {
                            return (
                              <MenuItem value={item.id} key={idx}>
                                {t(item.i18n)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {this.validator.message('currency', this.state.currency, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.currency, 'required') && (
                          <FormHelperText id="currency" error>
                            {t('validation.required.choose', {field: t('business_plan.unit')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    {!this.state.is_active && (
                      <>
                        <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                          {t('sub.canceled_by')}
                        </Grid>
                        <Grid container alignItems="center" item xs={6} lg={3}>
                          <FormControl error>
                            <TextField className="field_size_10 field_min_size_300" margin="dense" disabled={true} variant="outlined" value={this.state.canceled_by || ''} />
                          </FormControl>
                        </Grid>
                      </>
                    )}
                  </Grid>

                  {/* Amount type */}
                  {plan_category === 'TICKET' && (
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('ticket.amount_type')} <span className="font_color_red">＊</span>
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={10}>
                        <FormControl variant="outlined" margin="dense">
                          <Select
                            variant="outlined"
                            className="field_size_20 field_min_size_300"
                            margin="dense"
                            disabled={true}
                            displayEmpty
                            renderValue={
                              this.state.amount_type ?
                                undefined :
                                () => (
                                    <div className="font-12 color-disabled">
                                      {t('placeholder.required_select', {
                                        field: t('ticket.amount_type'),
                                      })}
                                    </div>
                                  )
                            }
                            value={this.state.amount_type || ''}
                            onChange={(event) => onChangeSelect(this, event)}
                          >
                            {TICKET_AMOUNT_TYPE.map((item, idx) => {
                              return (
                                <MenuItem value={item.value} key={idx}>
                                  {t(item.i18n)}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  )}

                  {/* Amount */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.price')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={6}>
                      <FormControl error>
                        <div className="box-group-input">
                          <TextField
                            className="field_size_10 field_min_size_300"
                            margin="dense"
                            inputProps={{
                              name: 'total_amount',
                            }}
                            disabled={true}
                            placeholder={t('placeholder.required', {field: t('business_plan.price')})}
                            variant="outlined"
                            onChange={(event) => onChangeTextField(this, event)}
                            value={this.state.total_amount}
                          />
                          {((this.state.payment_method_default === PAYMENT_METHOD_TRANSFER_SUB[0].id && this.state.payment_method === PAYMENT_METHOD_TRANSFER_SUB[0].id) ||
                            (this.state.transfer_deadline && this.state.isSubExtended)) && (
                            <Button
                              variant="contained"
                              color="primary"
                              className="margin-left-24"
                              component="span"
                              onClick={() =>
                                this.setState({
                                  isModalHistory: true,
                                })
                              }
                            >
                              {t('common.historyPayment')}
                            </Button>
                          )}
                        </div>
                        {this.validator.message('total_amount', this.state.total_amount, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.total_amount, 'required') && (
                          <FormHelperText id="total_amount" error>
                            {t('validation.required', {field: t('business_plan.price')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* start date and time */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.start_time')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <KeyboardDatePicker
                          variant="inline"
                          disabled={true}
                          autoOk
                          ampm={false}
                          label={t('business_plan.start_time')}
                          value={this.state.start_time}
                          onChange={(time) => this.onChangeTime(time, true)}
                          format={DATE_FORMAT}
                        />
                        {this.validator.message('start_time', this.state.start_time, 'required')}
                        {(this.state.isSubmitForm && !this.validator.check(this.state.start_time, 'required') && (
                          <FormHelperText id="start_time" error>
                            {t('validation.required', {field: t('business_plan.start_time')})}
                          </FormHelperText>
                        )) ||
                          (this.state.isSubmitForm && !compareDateTimeRange(this.state.start_time, this.state.end_time, false) && (
                            <FormHelperText id="start_time" error>
                              {t('validation.invalid.timeRange')}
                            </FormHelperText>
                          ))}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* end date and time */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('business_plan.end_time')}
                      <span className="font_color_red">＊</span>
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl error>
                        <KeyboardDatePicker
                          variant="inline"
                          disabled={true}
                          autoOk
                          ampm={false}
                          label={t('business_plan.end_time')}
                          value={this.state.end_time}
                          onChange={(time) => this.onChangeTime(time, false)}
                          format={DATE_FORMAT}
                        />
                        {this.validator.message('end_time', this.state.end_time, 'required')}
                        {this.state.isSubmitForm && !this.validator.check(this.state.end_time, 'required') && (
                          <FormHelperText id="end_time" error>
                            {t('validation.required', {field: t('business_plan.end_time')})}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Automatic updating */}
                  <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                    <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                      {t('sub.automatic_update')}
                    </Grid>
                    <Grid container alignItems="center" item xs={6} lg={4}>
                      <FormControl variant="outlined" margin="dense">
                        <Select variant="outlined" className="field_size_20 field_min_size_300" margin="dense" disabled={true} value={this.state.auto_renewable}>
                          {SIMULATION_STATUS.map((item, idx) => (
                            <MenuItem value={item.id} key={idx}>
                              {t(item.i18n)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/* Has One Time Discount */}
                  {this.state.dataDiscount?.length > 0 && (
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={6} lg={2} className="grid_title_padding">
                        {t('business_plan.one_time_discount')}
                      </Grid>
                      <Grid container alignItems="center" item xs={6} lg={4}>
                        <Button color="primary" variant="contained" onClick={() => this.setState({flagListDiscount: true})}>
                          {t('common.btnDetail')}
                        </Button>
                      </Grid>
                    </Grid>
                  )}

                  {/* Ride limit */}
                  {plan_category === 'TICKET' && (
                    <>
                      {!this.state.isSubExtended && (
                        <Grid container spacing={1} className="row_form_item table_background_color_aliceblue" style={{border: 'none', paddingRight: 20, paddingTop: 10}}>
                          <Grid container alignItems="center" justify="flex-end" item xs={12}>
                            {permission.canHistoryAdditional && (
                              <Button
                                color="primary"
                                variant="contained"
                                className="button_magin button_color_red"
                                style={{marginRight: 8}}
                                onClick={() => this.setState({openHistoryAdditionalModal: true})}
                              >
                                {t('ticket.history_additional')}
                              </Button>
                            )}
                            {permission.canAdditionalPurchaseRideTicket && (
                              <Button
                                color="primary"
                                variant="contained"
                                className="button_magin button_color_green"
                                onClick={() => this.setState({openAdditionalPurchaseModal: true})}
                              >
                                {t('ticket.additional_purchase_ride_ticket')}
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      )}
                      {this.state.isSubExtended && (
                        <Grid container spacing={1} alignItems="center" className="row_form_item table_background_color_aliceblue">
                          <Box p={2}>
                            <h4>
                              <b>
                                <i>{t('sub.extended_sub_notification')}</i>
                              </b>
                              <Link
                                style={{textDecoration: 'none'}}
                                to={{pathname: ROUTE.LAYOUT + ROUTE.RESERVATION_SUBSCRIPTION_DETAILS + '/' + this.state.renewals_from_subscription_id}}
                                onClick={() => {
                                  this.getUserSubscriptionDetail(this.state.renewals_from_subscription_id);
                                }}
                              >
                                {this.state.renewals_from_subscription_code}
                              </Link>
                              )
                            </h4>
                          </Box>
                        </Grid>
                      )}
                      <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                        <Grid container alignItems="center" item xs={12}>
                          <TableContainer style={{padding: 20, paddingTop: 10}}>
                            <Table style={{width: '100%'}}>
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center" className="width_150p product_entry_table_header_color" style={{color: '#fff'}}>
                                    {t('ticket.total_ride_limit')}
                                  </TableCell>
                                  <TableCell align="center" className="width_150p product_entry_table_header_color" style={{color: '#fff'}}>
                                    {t('ticket.used_count')}
                                  </TableCell>
                                  <TableCell align="center" className="width_150p product_entry_table_header_color" style={{color: '#fff'}}>
                                    {t('ticket.remain_count')}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell align="center">{this.state.total_ride_limit || 0}</TableCell>
                                  <TableCell align="center">{this.state.used_count || 0}</TableCell>
                                  <TableCell align="center">{this.state.remain_count || 0}</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {/* Family Option */}
                  {isEditForm && plan_category === 'PLAN' && (
                    <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                      <Grid container alignItems="center" item xs={2} className="grid_title_padding" style={{paddingTop: '10px'}}>
                        {t('business_plan.family_option')}
                        <span className="font_color_red">＊</span>
                      </Grid>
                      {/* <Grid container alignItems="center" item xs={10} style={{paddingTop: '10px'}}>
                        <Button
                          color="primary"
                          variant="contained"
                          disabled={this.state.limit_family_user - this.state.number_of_user === 0 || !this.state.is_active}
                          className={this.state.limit_family_user - this.state.number_of_user === 0 || !this.state.is_active ? '' : 'button_color_blue'}
                          onClick={() => this.setState({openAddSlotsModal: true})}
                        >
                          {t('sub.add_slot')}
                        </Button>
                      </Grid> */}
                      {/* {this.state.limit_family_user - this.state.number_of_user === 0 && (
                        <>
                          <Grid xs={2} style={{paddingTop: '10px'}}></Grid>
                          <Grid container alignItems="center" item xs={10} style={{paddingTop: '10px'}}>
                            <span style={{color: '#3f51b5', fontSize: 14}}>{t('sub.maximum_slot')}</span>
                          </Grid>
                        </>
                      )} */}
                      <Grid container alignItems="center" item xs={12}>
                        <TableContainer style={{paddingLeft: '20px'}}>
                          <Table style={{width: '100%'}}>
                            <TableHead>
                              <TableRow>
                                <TableCell className="width_125p">{t('memberManagement.memberId')}</TableCell>
                                <TableCell className="width_100p">{t('sub.last_name')}</TableCell>
                                <TableCell className="width_100p">{t('sub.first_name')}</TableCell>
                                <TableCell className="width_125p">{t('sub.lastname_furigana')}</TableCell>
                                <TableCell className="width_125p">{t('sub.firstname_furigana')}</TableCell>
                                <TableCell className="width_150p">{t('business_plan.mail')}</TableCell>
                                <TableCell className="width_125p">{t('common.phoneNumber')}</TableCell>
                                <TableCell className="width_100p">{t('business_plan.invite_date')}</TableCell>
                                <TableCell className="width_75p">{t('memberManagement.verified')}</TableCell>
                                <TableCell className="width_50p">{t('business_plan.status')}</TableCell>
                                {this.state.is_active && permission.canResend && <TableCell align="right" className="width_100p"></TableCell>}
                                <TableCell align="left" className="width_250p">{t('common.action')}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.list_family_user?.map((item, index) => {
                                const timeZone = common?.country_code?.find((country_code) => country_code.id === this.state.country)?.zone_id;
                                return (
                                  <TableRow key={index}>
                                    <TableCell align="left">{item?.member_id || DATA_NULL}</TableCell>
                                    <TableCell align="left">{item?.last_name || DATA_NULL}</TableCell>
                                    <TableCell align="left">{item?.first_name || DATA_NULL}</TableCell>
                                    <TableCell align="left">{item?.last_name_furigana || DATA_NULL}</TableCell>
                                    <TableCell align="left">{item?.first_name_furigana || DATA_NULL}</TableCell>
                                    <TableCell align="left">{item?.email || DATA_NULL}</TableCell>
                                    <TableCell align="left">{item?.mobile || DATA_NULL}</TableCell>
                                    <TableCell align="left">{formatUtc(item.invited_date, DATE_TIME_FORMAT, timeZone) || DATA_NULL}</TableCell>
                                    <TableCell align="left">{item?.is_active ? t('sub.true') : t('sub.false')}</TableCell>
                                    <TableCell align="left">{item.delete_flag ? t('common.disable') : t('common.enable')}</TableCell>
                                    {this.state.is_active && permission.canResend && (
                                      <TableCell align="right">
                                        {!item?.is_active && !item.delete_flag && (
                                          <Button
                                            color="primary"
                                            variant="contained"
                                            className="button_color_blue"
                                            onClick={() =>
                                              this.setState({
                                                openModalConfirmResend: true,
                                                resendUserId: item.willer_user_id,
                                              })
                                            }
                                          >
                                            {t('common.btnResend')}
                                          </Button>
                                        )}
                                      </TableCell>
                                    )}
                                    <TableCell align="left">
                                      <Grid className="width_100">
                                        {permission?.canMemberInfor && (
                                          <Link
                                            target="_blank"
                                            style={{textDecoration: 'none'}}
                                            to={{
                                              pathname:
                                                ROUTE.LAYOUT +
                                                ROUTE.MEMBER_MANAGEMENT +
                                                // eslint-disable-next-line max-len
                                                `?currentPage=0&rowsPerPage=10&statusList[]=REGISTRATION_COMPLETED&statusList[]=INCOMPLETE_REGISTRATION&memberId=${item.member_id}`,
                                            }}
                                          >
                                            <Button color="primary" variant="contained">
                                              {t('common.btnMemberInfor')}
                                            </Button>
                                          </Link>
                                        )}
                                      </Grid>
                                      <Grid className="width_100">
                                        {!item.delete_flag && !this.state.mainUserBanned && permission.canDeleteFamilyOption && isAllGeofencesInFacility && (
                                          <Button
                                            color="secondary"
                                            variant="contained"
                                            style={{marginTop: 5}}
                                            onClick={() => this.handleDisableOrEnableChild(item?.delete_flag, item?.subscription_user_id, false)}
                                          >
                                            {t('common.btnDelete')}
                                          </Button>
                                        )}
                                      </Grid>
                                      <Grid className="width_100">
                                        {!item.is_active && permission.canRemoveSlot && isAllGeofencesInFacility && (
                                          <Button
                                            style={{marginTop: 5}}
                                            color="secondary"
                                            variant="contained"
                                            onClick={() => this.setState({removeUserId: item?.willer_user_id, openConfirmRemoveSlotModal: true})}
                                          >
                                            {t('sub.remove_slot')}
                                          </Button>
                                        )}
                                      </Grid>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                            <TableBody>{listEmptyUser}</TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                    </Grid>
                  )}
                  {/* Memo field */}
                  <br></br>
                  <Grid container alignItems="center" item xs={12} lg={9} style={{marginLeft: 20}}>
                    <Memo
                      memos={this.state.memos}
                      onChangeContent={(value) => onChangeListData(this, this.state.memos, value.field, value.index, value.newValue)}
                      note={this.state.note}
                      onChangeNote={(value) => this.setState({note: value})}
                      parentType="medium"
                      countryCode={this.state.country}
                    />
                  </Grid>
                  <br></br>
                </Paper>
                <br></br>

                <Grid container spacing={1}>
                  <Grid container alignItems="center" justify="flex-start" item xs={3}></Grid>
                  <Grid container alignItems="center" justify="flex-end" item xs={9}>
                    {!isEditForm && !this.state.mainUserBanned && (
                      <Button color="primary" variant="contained" className="button_magin button_color_green" endIcon={<AddIcon />} onClick={this.handleCreate}>
                        {t('common.btnRegister')}
                      </Button>
                    )}
                    {isEditForm && !this.state.isSubExtended && (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{marginRight: 5}}
                        onClick={() => {
                          this.props.getSendMailList(this.props.match.params.id).then((response) =>
                            this.setState({
                              sent_mails: response,
                              sentMailModal: true,
                            }),
                          );
                        }}
                      >
                        {t('sub.sent_mails')}
                      </Button>
                    )}
                    {permission?.canMemberInfor && (
                      <Link target="_blank" style={{textDecoration: 'none'}} to={{pathname: ROUTE.LAYOUT + ROUTE.MEMBER_MANAGEMENT +
                        `?currentPage=0&rowsPerPage=10&statusList[]=REGISTRATION_COMPLETED&statusList[]=INCOMPLETE_REGISTRATION&memberId=${this.state.memberCode}`}}>
                        <Button color="primary" variant="contained" className="button_margin">
                          {t('common.btnMemberInfor')}
                        </Button>
                      </Link>
                    )}
                    {isEditForm && !['RENEWAL_FAILED', 'EXPIRED'].includes(this.state.status) && !this.state.mainUserBanned && (
                      <>
                        {this.state.is_active &&
                          permission.canAutomaticUpdate &&
                          (this.state.plan_auto_renew || this.state.auto_renewable) &&
                          isAllGeofencesInFacility &&
                          !(this.state.status === 'EXTENDED' && this.state.isSubExtended) && (
                            <Button
                              style={{marginRight: 5}}
                              color={this.state.auto_renewable ? 'secondary' : 'primary'}
                              variant="contained"
                              onClick={() => this.setState({confirmRenewFlag: true})}
                            >
                              {this.state.auto_renewable ? t('sub.disable_auto_update') : t('sub.enable_auto_update')}
                            </Button>
                          )}
                        {this.state.canceled_by !== 'USER' &&
                          !['CANCEL', 'REFUNDED'].includes(this.state.payment_transfer_status) &&
                          this.state.is_active &&
                          permission.canDisable && (
                            <Button
                              style={{marginRight: 5}}
                              color={this.state.is_active ? 'secondary' : 'default'}
                              variant="contained"
                              onClick={() => this.handleDisableOrEnable(this.state?.is_active, this.props.match.params.id, true)}
                            >
                              {t('common.disable')}
                            </Button>
                          )}
                        {this.state.is_active && permission.canUpdate && isAllGeofencesInFacility && this.state.payment_method === 'TRANSFER' && (
                          <Button style={{marginRight: 5}} color="primary" variant="contained" className="button_magin" endIcon={<CloudUploadIcon />} onClick={this.handleUpdate}>
                            {t('common.btnUpdate')}
                          </Button>
                        )}
                      </>
                    )}
                    {isEditForm && permission?.canUpdateMemo && (
                      <Button
                        style={{marginRight: 5}}
                        color="primary"
                        variant="contained"
                        className="button_magin button_color_info"
                        endIcon={<CloudUploadIcon />}
                        onClick={this.handleUpdateMemo}
                      >
                        {t('common.btnUpdateMemo')}
                      </Button>
                    )}
                    <Button
                      color="primary"
                      variant="contained"
                      className="button_magin button_color"
                      onClick={() => backForwardRouter(this.props, ROUTE.LAYOUT + ROUTE.RESERVATION_SUBSCRIPTION)}
                    >
                      {t('common.btnReturn')}
                    </Button>
                  </Grid>
                </Grid>
                <Dialog
                  open={this.state.flag || this.state.flagMemo}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    okButtonText={`${t('common.btnOk')}`}
                    cancelButtonText={`${t('common.btnReturn')}`}
                    onClickOk={this.state.flag ? this.handleButtonModalOk : this.handleMemoButtonUpdateOk}
                    onClickCancel={this.state.flag ? this.handleButtonModalCancel : this.handleMemoModalCancel}
                    message={this.state.message}
                  ></SelectModal>
                </Dialog>
                <Dialog
                  open={this.state.openModalConfirmResend}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    okButtonText={`${t('common.btnOk')}`}
                    cancelButtonText={`${t('common.btnReturn')}`}
                    onClickOk={this.confirmResendMail}
                    onClickCancel={this.closeModalConfirmResend}
                    message={t('sub.confirm_resend_mail')}
                  ></SelectModal>
                </Dialog>
                <Dialog
                  open={this.state.openConfirmRemoveSlotModal}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    okButtonText={`${t('common.btnOk')}`}
                    cancelButtonText={`${t('common.btnReturn')}`}
                    onClickOk={() => this.removeSlotSubs()}
                    onClickCancel={() => this.setState({openConfirmRemoveSlotModal: false})}
                    message={t('sub.confirm_remove_slot')}
                  ></SelectModal>
                </Dialog>
                <Dialog
                  open={this.state.openConfirmChangePaymentMethodModal}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    okButtonText={`${t('common.btnOk')}`}
                    cancelButtonText={`${t('common.btnReturn')}`}
                    onClickOk={() => this.changePaymentMethod()}
                    onClickCancel={() => this.setState({openConfirmChangePaymentMethodModal: false})}
                    message={t('sub.confirm_change_payment_method')}
                  ></SelectModal>
                </Dialog>
                <Dialog
                  open={this.state.confirmRenewFlag}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1020',
                  }}
                >
                  <SelectModal
                    isCheckBoxSendMail={this.state.auto_renewable ? true : false}
                    okButtonText={`${t('common.btnSend')}`}
                    cancelButtonText={`${t('common.btnReturn')}`}
                    onClickOk={this.renewSubcription}
                    onClickCancel={this.closeConfirmRenewModal}
                    message={this.state.auto_renewable ? t('sub.disable_renewable_confirm') : t('sub.enable_renewable_confirm')}
                  ></SelectModal>
                </Dialog>
              </Container>
              <Container>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={this.state.isModalHistory}
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1000',
                  }}
                >
                  <History props={this.props} currency={this.state.currency} onClickClose={() => this.setState({isModalHistory: false})} />
                </Modal>
              </Container>
              <Container>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={this.state.addUsersModal}
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1000',
                  }}
                >
                  <DialogContent>
                    <SelectUser
                      props={{
                        ...this.props,
                        list_family_id: this.state.list_family_user_full.map((item) => item.willer_user_id),
                        number_of_users: this.state.number_of_users_available,
                      }}
                      onClickOk={(list_family_code) => this.handleSelectUserOk(list_family_code)}
                      onClickClose={() => this.setState({addUsersModal: false})}
                    />
                  </DialogContent>
                </Modal>
              </Container>
              <Container>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={this.state.openAddSlotsModal}
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1000',
                  }}
                >
                  <DialogContent>
                    <AddSlot
                      props={{
                        ...this.props,
                        maximumSlots: this.state.limit_family_user - this.state.number_of_user,
                        planCode: this.state.plan_code,
                        extraFee: this.state.extra_fee,
                        planName: this.state.subscription_languages,
                        startDate: this.state.start_time,
                        currency: this.state.currency,
                        userId: this.state.user_id,
                        subscriptionId: this.state.subscription_id,
                        numberOfUser: this.state.number_of_user,
                      }}
                      reload={() => this.getUserSubscriptionDetail()}
                      onClickClose={() => this.setState({openAddSlotsModal: false})}
                    />
                  </DialogContent>
                </Modal>
              </Container>
              <Container>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={this.state.openHistoryAdditionalModal}
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1000',
                  }}
                >
                  <HistoryAdditional
                    props={{
                      ...this.props,
                    }}
                    subscriptionId={this.props.match.params.id}
                    onClickClose={() => this.setState({openHistoryAdditionalModal: false})}
                  />
                </Modal>
              </Container>
              <Container>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={this.state.openAdditionalPurchaseModal}
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    zIndex: '1000',
                  }}
                >
                  <AdditionalPurchase
                    props={{
                      ...this.props,
                    }}
                    planId={this.state.plan_id}
                    hasCreditCard={this.state.hasCreditCard}
                    subscriptionId={this.state.subscription_id}
                    userId={this.state.user_id}
                    additionalRideComplete={() => {
                      this.setState({openAdditionalPurchaseModal: false});
                      this.getUserSubscriptionDetail();
                    }}
                    canAdditional={isAllGeofencesInFacility}
                    onClickClose={() => this.setState({openAdditionalPurchaseModal: false})}
                  />
                </Modal>
              </Container>
              {/* Modal List Discount */}
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={this.state.flagListDiscount}
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: '1000',
                }}
              >
                <OneTimeDiscount
                  onClickCancel={this.closeListDiscountModal}
                  dataDiscount={this.state.dataDiscount}
                  isReadOnly={true}
                  geofence_ids={this.state.list_area_id}
                ></OneTimeDiscount>
              </Modal>
              {this.state.sentMailModal && (
                <Container>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.sentMailModal}
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      zIndex: '1000',
                      overflowWrap: 'break-word',
                    }}
                  >
                    <DialogContent>
                      <LoadingOverlay active={business?.isLoading} bgColor="#f1f1f1" spinnerColor="#9ee5f8" textColor="#676767" spinner>
                        <Card raised>
                          <Box p={2} m={2}>
                            <Grid container spacing={1} className="row_form_item table_background_color_aliceblue">
                              <Grid container alignItems="center" item xs={12} className="grid_title_padding" style={{paddingTop: '10px'}}>
                                <h3>{t('sub.sent_mails')}</h3>
                              </Grid>
                              <Grid container alignItems="center">
                                <div style={{paddingLeft: '20px'}} className="scroll_area_600">
                                  <Table size="small" aria-label="sticky table" stickyHeader style={{tableLayout: this.state.sent_mails.length > 0 ? 'fixed' : 'auto'}}>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell className="width_100p">{t('common.id')}</TableCell>
                                        <TableCell className="width_200p">{t('common.email')}</TableCell>
                                        <TableCell className="width_300p">{t('sub.title')}</TableCell>
                                        <TableCell className="width_300p">{t('sub.type')}</TableCell>
                                        <TableCell className="width_800p">{t('sub.content')}</TableCell>
                                        <TableCell className="width_200p">{t('facility.language')}</TableCell>
                                        <TableCell className="width_200p">{t('history.created_date')}</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {this.state.sent_mails?.map((item, index) => {
                                        return (
                                          <TableRow key={index} hover>
                                            <TableCell align="left">{item?.id || DATA_NULL}</TableCell>
                                            <TableCell align="left">{item?.send_to || DATA_NULL}</TableCell>
                                            <TableCell align="left">{item?.title || DATA_NULL}</TableCell>
                                            <TableCell align="left">{item?.type || DATA_NULL}</TableCell>
                                            <TableCell align="left">
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html: item?.content || DATA_NULL,
                                                }}
                                              ></div>
                                            </TableCell>
                                            <TableCell align="left">{item?.lang || DATA_NULL}</TableCell>
                                            <TableCell align="left">{formatUtc(item?.created_date, DATE_TIME_FORMAT) || DATA_NULL}</TableCell>
                                          </TableRow>
                                        );
                                      })}
                                    </TableBody>
                                  </Table>
                                </div>
                              </Grid>
                            </Grid>
                            <Grid container alignItems="flex-start" className="mt_10">
                              <Grid container justify="flex-end" alignItems="center" item xs={12}>
                                <Button
                                  variant="contained"
                                  className="button_color button_magin"
                                  style={{marginLeft: 5}}
                                  color="primary"
                                  onClick={() => this.setState({sentMailModal: false})}
                                >
                                  {t('common.btnClose')}
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        </Card>
                      </LoadingOverlay>
                    </DialogContent>
                  </Modal>
                </Container>
              )}
              <br></br>
            </Card>
            <br></br>
          </Container>
          <br></br>
        </Card>
      </LoadingOverlay>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    business: state.business,
    common: state.common,
    reservation: state.reservation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCountryCode: () => dispatch(getAllCountryCode()),
    detailSubscription: (params, browserHistory) => dispatch(detailSubscription(params, browserHistory)),
    updateSubscriptionTransferred: (payload, props) => dispatch(updateSubscriptionTransferred(payload, props)),
    setMessageModal: (payload) => dispatch(setMessageModal(payload)),
    getServiceTypes: () => dispatch(getServiceTypes()),
    removeSlotSubs: (payload) => dispatch(removeSlotSubs(payload)),
    changePaymentMethod: (payload) => dispatch(changePaymentMethod(payload)),
    checkOwnerCard: (userId) => dispatch(checkOwnerCard(userId)),
    getSendMailList: (id) => dispatch(getSendMailList(id)),
    getDetailPlan: (id) => dispatch(getDetailPlan(id)),
    getAllSubscriptionGeofence: () => dispatch(getAllSubscriptionGeofence()),
  };
};

export default withPermissionGateway(withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index)));
