import React, {Component} from 'react';

import {Card, Container, Grid, Button, CardContent, Typography, Box} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import _ from 'lodash';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';

import {DATE_TIME_FORMAT_MOMENT, ROUTE} from '../../../../common/constant';
import {getPercentShuttlePassenger} from '../../../../services/reservationServices';
import {updateBooking} from '../../../../stores/reservation/actions';
import {getSubTime, customDisplayCurrency} from '../../../../utils/common';
import {roundedAndConvertTimeByZoneId} from '../../../../utils/datetime';

/**
 * Reservation management view
 */
class Index extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      shuttle_bus_estimate_vehicle_id: '',
      percent_estimate: '',
      number_of_user: '',
      seats: '',
    };
  }

  /**
   * componentDidMount
   */
  componentDidMount() {
    if (_.isEmpty(this.props.bookingInfo) || !this.props.match.params.id) this.props.history.push(ROUTE.LAYOUT + ROUTE.RESERVATION_CREATE);
  }

  /**
   * getPercentShuttlePassenger
   * @param {*} vehicle_id
   */
  async getPercentShuttlePassenger(vehicle_id) {
    const percent = await getPercentShuttlePassenger(vehicle_id);
    if (percent.status === 200) {
      const per = ((percent.result.seats - percent.result.number_of_user) / percent.result.seats) * 100;
      this.setState({
        percent_estimate: per.toFixed(2),
        number_of_user: percent.result.number_of_user,
        seats: percent.result.seats,
      });
    }
  }

  /**
   * getDepartureTime
   * @return {*}
   */
  getDepartureTime() {
    return this.props.routeItem.routes.find((item) => {
      return item.type === 'SHUTTLE_BUS' || item.type === 'TAXI';
    });
  }

  /**
   * toPageInfo
   */
  toPageInfo = () => {
    const departureObj = this.getDepartureTime();
    const trip_info = this.props.routeItem;
    const simulation_id = departureObj ? departureObj.simulation_id : '';
    const booking = Object.assign({}, this.props.bookingInfo);
    booking.trip_info = trip_info;
    booking.simulation_id = simulation_id;
    // eslint-disable-next-line no-self-assign
    booking.transportation_type = booking.transportation_type;
    booking.estimate_total_cost = this.props.routeItem.estimate_total_cost;
    booking.requested_departure_time = departureObj ? departureObj.estimate_start_time : new Date().toISOString();
    booking.estimate_start_time = this.props.routeItem.routes?.find((item) => item.type === 'SHUTTLE_BUS')?.estimate_start_time;
    booking.estimate_arrival_time = this.props.routeItem.routes?.find((item) => item.type === 'SHUTTLE_BUS')?.estimate_end_time;
    if (trip_info) {
      this.props.onUpdateBooking(booking);
    }
    // thiếu requested_destination_time
    this.props.history.push(ROUTE.LAYOUT + ROUTE.RESERVATION_MANAGEMENT_PASSENGER_DETAIL);
  };

  /**
   * linkGoogleMap
   * @param {number} latitude
   * @param {number} longitude
   */
  linkGoogleMap = (latitude, longitude) => {
    window.open(`https://maps.google.com/?q=${latitude},${longitude}`, '_blank');
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t} = this.props;

    return (
      <div>
        <Card className="main_card_min_size">
          <Container maxWidth="xl">
            <Grid container className="page_header">
              <Grid container alignItems="center" item xs={6}>
                <h3>{t('reservationManagement.route')}</h3>
              </Grid>
              <Grid container alignItems="center" justifyContent="flex-end" item xs={6}>
                <Button color="primary" variant="contained" className="button_margin button_color" endIcon={<ArrowBackIcon />} onClick={this.props.history.goBack}>
                  {t('common.btnReturn')}
                </Button>
              </Grid>
            </Grid>
          </Container>

          {/* step 3 */}
          {this.props.routeItem && (
            <Container maxWidth="xl">
              <Box p={2} m={2}>
                <Grid container spacing={3} alignItems="flex-start">
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Box mt={2} mb={2}>
                          <Grid container spacing={3} alignItems="flex-start">
                            <Grid item xs={12}>
                              <Typography variant="h6" component="h6">
                                {t('reservationManagement.charge_forecast')}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography display="inline" variant="h3" component="h3">
                                {getSubTime(this.props.routeItem.estimate_arrival_time, this.props.routeItem.estimate_start_time)}
                              </Typography>
                              <Typography display="inline" variant="h6" component="h6">
                                {t('common.minute')}&nbsp;&nbsp;&nbsp;
                              </Typography>
                              <Typography display="inline" variant="h6" component="h6">
                                {t('reservationManagement.charge_forecast')}：
                                {customDisplayCurrency(this.props.routeItem.estimate_total_cost, this.props.bookingInfo?.currency_code)}&nbsp;&nbsp;&nbsp;
                              </Typography>
                              <Typography display="inline" variant="h6" component="h6">
                                {t('reservationManagement.number_transfer')}:{this.props.routeItem.transfer_count}
                                {t('reservationManagement.counts')}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                        <Grid container spacing={3} alignItems="flex-start">
                          <Grid item xs={2}>
                            <Typography gutterBottom variant="h4" component="h4">
                              <div className="item_booking item_booking_bg_black">発</div>
                            </Typography>
                          </Grid>
                          <Grid item xs={7}>
                            <Typography gutterBottom variant="h4" component="h4">
                              {this.props.routeItem.pickup_location_name}
                            </Typography>
                            <Button
                              target="_blank"
                              variant="outlined"
                              color="primary"
                              href=""
                              onClick={() => this.linkGoogleMap(this.props.routeItem.routes[0].start_location_lat, this.props.routeItem.routes[0].start_location_lon)}
                            >
                              {t('newReservation.linkMap2')}
                            </Button>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography gutterBottom variant="h4" component="h4" className="text_right text_black">
                              {roundedAndConvertTimeByZoneId(this.props.routeItem.requested_departure_time, this.props.routeItem?.zone_id, DATE_TIME_FORMAT_MOMENT)}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3} alignItems="flex-start">
                          <Grid item className="width_5 break_bottom_icon">
                            <MoreVertIcon />
                          </Grid>
                          <Grid item className="width_80 break_bottom_line"></Grid>
                          <Grid item className="width_15 break_bottom_text text_right text_grey">
                            {t('reservationManagement.waiting_time')}：{getSubTime(this.props.routeItem.estimate_start_time, this.props.routeItem.requested_departure_time)}
                            {t('common.minute')}
                          </Grid>
                        </Grid>
                        {/* loop */}
                        {this.props.routeItem.routes.map((item, index) => {
                          if (item.type.includes('WALKING')) {
                            return (
                              <div key={index}>
                                <Grid container spacing={3} alignItems="flex-start">
                                  <Grid item xs={2}>
                                    <div className="item_booking item_booking_bg_grey">
                                      <img className="width_100" src={process.env.PUBLIC_URL + '/images/reservation/walking.svg'} alt="" />
                                    </div>
                                  </Grid>
                                  <Grid item xs={7}>
                                    <Typography gutterBottom variant="h4" component="h4">
                                      {this.props.routeItem.routes.length - 1 === index ? item.start_location_name : item.end_location_name}
                                    </Typography>
                                    <Button
                                      target="_blank"
                                      variant="outlined"
                                      color="primary"
                                      href=""
                                      onClick={() =>
                                        this.linkGoogleMap(
                                          this.props.routeItem.routes.length - 1 === index ? item.start_location_lat : item.end_location_lat,
                                          this.props.routeItem.routes.length - 1 === index ? item.start_location_lon : item.end_location_lon,
                                        )
                                      }
                                    >
                                      {t('newReservation.linkMap2')}
                                    </Button>
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Typography gutterBottom variant="h4" component="h4" className="text_right">
                                      {t('reservationManagement.arrival_time')}
                                    </Typography>
                                    <Typography gutterBottom variant="h4" component="h4" className="text_right text_black">
                                      {roundedAndConvertTimeByZoneId(this.props.routeItem.estimate_start_time, this.props.routeItem?.zone_id, DATE_TIME_FORMAT_MOMENT)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={3} alignItems="flex-start">
                                  <Grid item className="width_5 break_bottom_icon">
                                    <MoreVertIcon className="font_color_red" />
                                  </Grid>
                                  <Grid item className="width_80 break_bottom_line"></Grid>
                                  <Grid item className="width_15 break_bottom_text text_right text_grey">
                                    {t('reservationManagement.waiting_time')}：{getSubTime(item.estimate_end_time, item.estimate_start_time)}
                                    {t('common.minute')}
                                  </Grid>
                                </Grid>
                              </div>
                            );
                          }
                          if (item.type === 'TAXI') {
                            return (
                              <div key={index}>
                                <Grid container spacing={3} alignItems="flex-start">
                                  <Grid item xs={2}>
                                    <div className="item_booking item_booking_bg_cyan">
                                      <img className="width_100" src={process.env.PUBLIC_URL + '/images/reservation/taxi.svg'} alt="" />
                                    </div>
                                  </Grid>
                                  <Grid item xs={7}>
                                    <Typography gutterBottom variant="h4" component="h4">
                                      {item.start_location_name}
                                    </Typography>
                                    {/* <Typography gutterBottom variant="h5" component="h5" className="text_grey">
                                        {t('reservationManagement.pick_up_time_varies')}
                                      </Typography> */}
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Typography gutterBottom variant="h4" component="h4" className="text_right">
                                      {t('reservationManagement.pick_up_time')}
                                    </Typography>
                                    <Typography gutterBottom variant="h4" component="h4" className="text_right text_black">
                                      {roundedAndConvertTimeByZoneId(item.estimate_start_time, this.props.routeItem?.zone_id, DATE_TIME_FORMAT_MOMENT)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={3} alignItems="flex-start">
                                  <Grid item className="width_5 break_bottom_icon">
                                    <MoreVertIcon className="font_color_red" />
                                  </Grid>
                                  <Grid item className="width_80 break_bottom_line"></Grid>
                                  <Grid item className="width_15 break_bottom_text text_right text_grey">
                                    {t('reservationManagement.waiting_time')}：{getSubTime(item.estimate_end_time, item.estimate_start_time)}
                                    {t('common.minute')}
                                  </Grid>
                                </Grid>
                              </div>
                            );
                          }
                          if (item.type.includes('SHUTTLE_BUS')) {
                            !this.state.percent_estimate && this.getPercentShuttlePassenger(item.estimate_vehicle_id);
                            return (
                              <div key={index}>
                                <Grid container spacing={3} alignItems="flex-start">
                                  <Grid item xs={2}>
                                    <div className="item_booking item_booking_bg_orange">
                                      <img className="width_100" src={process.env.PUBLIC_URL + '/images/reservation/taxi.svg'} alt="" />
                                    </div>
                                  </Grid>
                                  <Grid item xs={7}>
                                    <Typography gutterBottom variant="h4" component="h4">
                                      <img alt="" className="width_15" src={process.env.PUBLIC_URL + '/images/reservation/mobi.svg'} />
                                    </Typography>
                                    {this.state.percent_estimate && (
                                      <Typography gutterBottom variant="h5" component="h5" className="font_color_orange">
                                        {t('reservationManagement.estimate_on_board')}: {this.state.number_of_user}{t('reservationManagement.seats')}/ {this.state.seats}{t('reservationManagement.seats')}
                                      </Typography>
                                    )}
                                  </Grid>
                                  <Grid item xs={3}>
                                    <Typography gutterBottom variant="h4" component="h4" className="text_right">
                                      {t('reservationManagement.pick_up_time')}
                                    </Typography>
                                    <Typography gutterBottom variant="h4" component="h4" className="text_right text_black">
                                      {roundedAndConvertTimeByZoneId(item.estimate_start_time, this.props.routeItem?.zone_id, DATE_TIME_FORMAT_MOMENT)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={3} alignItems="flex-start">
                                  <Grid item className="width_5 break_bottom_icon">
                                    <MoreVertIcon className="font_color_red" />
                                  </Grid>
                                  <Grid item className="width_80 break_bottom_line"></Grid>
                                  <Grid item className="width_15 break_bottom_text text_right text_grey">
                                    {t('reservationManagement.waiting_time')}：{getSubTime(item.estimate_end_time, item.estimate_start_time)}
                                    {t('common.minute')}
                                  </Grid>
                                </Grid>
                              </div>
                            );
                          }
                          return null;
                        })}
                        <Grid container spacing={3} alignItems="flex-start">
                          <Grid item xs={2}>
                            <Typography gutterBottom variant="h4" component="h4">
                              <div className="item_booking item_booking_bg_black">着</div>
                            </Typography>
                          </Grid>
                          <Grid item xs={7}>
                            <Typography gutterBottom variant="h4" component="h4">
                              {this.props.routeItem.dropoff_location_name}
                            </Typography>
                            <Button
                              target="_blank"
                              variant="outlined"
                              color="primary"
                              href=""
                              onClick={() =>
                                this.linkGoogleMap(
                                    this.props.routeItem.routes[this.props.routeItem.routes.length - 1].end_location_lat,
                                    this.props.routeItem.routes[this.props.routeItem.routes.length - 1].end_location_lon,
                                )
                              }
                            >
                              {t('newReservation.linkMap2')}
                            </Button>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography gutterBottom variant="h4" component="h4" className="text_right">
                              {t('reservationManagement.arrival_time')}
                            </Typography>
                            <Typography gutterBottom variant="h4" component="h4" className="text_right text_black">
                              {roundedAndConvertTimeByZoneId(this.props.routeItem.estimate_arrival_time, this.props.routeItem?.zone_id, DATE_TIME_FORMAT_MOMENT)}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Box mt={2} mb={2}>
                          <Grid container spacing={3} alignItems="flex-start">
                            <Grid item xs={12} className="item_center_div">
                              <Button color="primary" variant="contained" className="button_margin button_color" onClick={() => this.toPageInfo()}>
                                {t('reservationManagement.next')}
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          )}
        </Card>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateBooking: (bookingData) => {
      dispatch(updateBooking(bookingData));
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  const itemId = ownProps.match.params.id;
  const data = state.reservation.listRoutes.find((item) => item.id === itemId);
  return {
    routeItem: data,
    booking: state.reservation.bookingData,
    bookingInfo: state.reservation.bookingInfo,
  };
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
